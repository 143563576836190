import React from 'react'
import './Empty.scss'
import AlertWarning from 'src/assets/alert-warning.svg'
import Button from 'src/components/buttons/Button'

export const Empty = ({
  icon,
  title,
  subtitle,
  subtitleSecond,
  hideButton = false,
  className,
  loading = false,
  onClick,
  buttonTitle,
}) => {
  return (
    <div className={`card-white-thin card-empty ${className}`}>
      <img className="empty-image" src={icon || AlertWarning} alt={'empty'} />
      <div className="empty-title">{title || '¡Lo sentimos!'}</div>
      <div className="empty-subtitle">
        {subtitle ?? 'No hemos encontrado ningún elemento.'}
      </div>
      <div className="empty-subtitle">
        {subtitleSecond ?? 'Haz click en “Recargar” para actualizar los elementos'}
      </div>
      {!hideButton && !loading && (
        <>
          <Button
            className="btn-empty"
            variant={'info'}
            onClick={() => {
              if (onClick) onClick()
            }}>
            {buttonTitle || 'Recargar'}
          </Button>
        </>
      )}
    </div>
  )
}
