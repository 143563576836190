import { $http } from './http'

class ContractsServices {
  getContractedCompanies = async contractTypeId => {
    const response = await $http.get(`/companies/contracts/contracted/${contractTypeId}`)
    return response.data
  }

  getContractedByCompanies = async contractTypeId => {
    const response = await $http.get(
      `/companies/contracts/contracted/by/${contractTypeId}`,
    )
    return response.data
  }

  toggleProducerShipperContract = async shipperId => {
    const response = await $http.post(
      `/companies/contracts/producer/shipper/toggle/${shipperId}`,
    )
    return response.data
  }

  getProductsByProducerContract = async producer => {
    const response = await $http.get(`/producers/contract/products/${producer}`)
    return response.data
  }
}

export default new ContractsServices()
