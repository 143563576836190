import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Alert } from 'react-bootstrap'
import { Icon } from '..'

import { Media } from 'react-breakpoints'
import { hideNotice } from 'src/actions/user.actions'
import { selectHideNav } from 'src/selectors/utilities.selector'
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { history } from 'src/App'
import { selectSetCurrentCompany } from 'src/selectors/company.selector'
import { actionTypes as companyActions } from 'src/actions/company.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import { selectActivePlans } from 'src/selectors/plans.selector'
import { formatDaysFromMillis } from '../../utils/formatters'
import { haveAnyValue } from 'src/utils/utilitiesV2'

const PlanEndedNotice = ({ isSignedIn, isOwner }) => {
  const dispatch = useDispatch()

  const [show, setShow] = useState(true)

  const current = useSelector(selectSetCurrentCompany)
  const plan = useSelector(selectActivePlans)
  const loading = useSelector(state =>
    loadingSelector([companyActions.CURRENT_COMPANY])(state),
  )
  const hide = useSelector(selectHideNav)

  const redirect = () => {
    setShow(false)
    if (isOwner) {
      history.push('perfil/cuenta')
    }
  }

  if (!isSignedIn || hide || loading) return null

  const daysLeft = formatDaysFromMillis(plan?.endDate - new Date().valueOf(), true)

  const see =
    show &&
    current &&
    !current.defaulter &&
    haveAnyValue(plan?.endDate) &&
    daysLeft >= -15 &&
    daysLeft <= 5

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        const tablet = breakpoints[currentBreakpoint] > breakpoints.tablet

        return (
          <div
            style={{
              position: 'fixed',
              width: '92%',
              zIndex: 2,
              color: 'white',
            }}>
            <Alert
              show={see}
              style={{ backgroundColor: '#B35796', height: tablet ? 42 : 65 }}>
              <div className={'d-flex space-between'}>
                <div
                  className={'b-user-name white d-flex'}
                  style={{
                    cursor: 'pointer',
                    fontWeight: 500,
                    textDecoration: 'underline',
                    color: 'white',
                  }}
                  onClick={redirect}>
                  <div className={'mr-1'}>
                    <Icon
                      icon={faWindowClose}
                      color={'white'}
                      tooltip={'cerrar'}
                      onClick={ev => {
                        ev.stopPropagation()
                        dispatch(hideNotice())
                        setShow(false)
                      }}
                    />
                  </div>
                  <div>
                    {daysLeft >= 0
                      ? 'Tienes facturas pendientes pago'
                      : `El plan ${plan.name} expira en ${15 + daysLeft} días`}
                  </div>
                </div>
              </div>
            </Alert>
          </div>
        )
      }}
    </Media>
  )
}

export default PlanEndedNotice
