import './MapPolygon.scss'
import React, {Component} from 'react';
import {connect} from "react-redux";

import {Col, Modal, Row, OverlayTrigger, Tooltip} from "react-bootstrap";
import {Td, Tr} from "react-super-responsive-table";

import {faWaze} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";
import {faHistory, faPencilAlt, faSave} from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert-react";



import MapCard from "../../../components/maps/MapCard";
import Button from "../../../components/buttons/Button";
import Card from "../../../components/cards/Card";
import Switch from "../../../components/inputs/Switch/Switch";
import CustomSelect from "../../../components/inputs/Select/CustomSelect";
import IconButton from "../../../components/buttons/IconButton";

import {
    getAllClients,
    getAllDeliverers,
    getAllSellers,
    updateMultipleClients
} from "../../../actions/clients.actions";
import {
    selectAllClients,
    selectDeliverers, selectDeliverersToSelect,
    selectGenericResponse,
    selectSellers
} from "../../../selectors/clients.selector";
import {selectModuleId} from "../../../selectors/modules.selector";
import { TableV2 } from '../../../components';

const latitude = 14.589465440976774;
const longitude = -90.51898866891861;

class MapPolygon extends Component {

    state = {
        allClients : [],
        selectedClients : [],
        height:0,
        polygonCoords : [],
        showMarkers : false,
        showAlertEmpty : false,
        miniSelectedClients : [],
        updateSeller : false,
        updateDeliverer : false,
    };

    componentDidMount() {
        this.props.getDeliverers();
        this.props.getSellers();
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.props.getAllClients();
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions= () => {
        let height = window.innerHeight - 400;
        this.setState({height});
    };

    onGoogleApiLoaded = (map, maps) => {
        let {mapS,mapsS, manualPolygon} = this.state;
        if (mapS === undefined && map !== undefined)
        {
            mapS = map;
            mapsS = maps;
            this.setState({mapS,mapsS})
        }
        if (manualPolygon !== undefined)
        {
            manualPolygon.setMap(null);
        }
        let {polygonCoords} = this.state;
        manualPolygon = new mapsS.Polygon({
            paths: polygonCoords,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35
        });
        manualPolygon.setMap(mapS);
        this.setState({manualPolygon});
    };

    onChildClickCallback = (value,item) => {
        let {selectedClients} = this.state;
        for (let i = 0, len = selectedClients.length; i < len; i++)
        {
            if (Number(selectedClients[i].latitude) === item.lat && Number(selectedClients[i].longitude) === item.lng)
            {
                this.rowClicked(null,selectedClients[i]);
            }
        }
    };

    addPointToPolygon = ({x, y, lat, lng, event,map,maps}) => {
        let {polygonCoords} = this.state;
        if (polygonCoords.length === 0 )
        {
            polygonCoords.push({ lat: lat, lng: lng });
            polygonCoords.push({ lat: lat, lng: lng });
        }
        else
        {
            polygonCoords.splice(1,0,{ lat: lat, lng: lng })
        }
        this.setState({polygonCoords});
        this.onGoogleApiLoaded(map,maps);
    };

    showClients = () => {
        let {manualPolygon,mapsS, selectedClients,polygonCoords} = this.state;
        if (selectedClients.length === 0)
        {
            if (polygonCoords.length > 3)
            {
                const {allClients} = this.props;
                let tmpCoordinate;
                for (let i = 0, len = allClients.length; i < len; i++)
                {
                    tmpCoordinate = new mapsS.LatLng(allClients[i].latitude,allClients[i].longitude);
                    if (mapsS.geometry.poly.containsLocation(tmpCoordinate,manualPolygon))
                    {
                        selectedClients.push(allClients[i]);
                    }
                }
                this.setState({selectedClients});
                this.setState({showMarkers: true});
            }
            else
            {
                this.setState({showAlertEmpty: true});
            }

        }
        else
        {
            manualPolygon.setMap(null);
            this.setState({selectedClients:[]});
            this.setState({polygonCoords:[]});
            this.setState({manualPolygon});

        }

    };

    handleSwitchChange = (event) => {
        let {id, value} = event.target;
        value = !(value === 'true');
        console.log(id + "," + value);
        this.setState({[id]: value});
        console.log(this.state.updateDeliverer);
    };

    changeSeller = (data) => {
        this.setState({seller: data});
    };
    changeDeliverer = (data) => {
        this.setState({deliverer: data});
    };

    changeSellerFilter = (data) => {
        this.setState({sellerFilter: data});
    };
    changeDelivererFilter = (data) => {
        this.setState({delivererFilter: data});
    };

    saveMultiple = () =>{
        let request = {};
        let {updateSeller,updateDeliverer,selectedClients,seller,deliverer} = this.state;
        request.updateDeliverer = updateDeliverer;
        request.updateSeller = updateSeller;
        request.clientList = selectedClients.map(item => (item.id));
        if (updateSeller)       request.seller = seller.value;
        if (updateDeliverer)    request.deliverer = deliverer.value;
        this.setState({showConfirmMultiple: false});
        this.setState({showSuccessMultiple: true});
        return this.props.updateMassive(request)
    };

    rowClicked = (e,data) => {
        let {selectedClients,miniSelectedClients} = this.state;
        for (let i = 0, len = selectedClients.length; i < len; i++)
        {
            if (selectedClients[i].id === data.id)
            {
                selectedClients[i].selected = !selectedClients[i].selected;
                let found = false;
                for (let j = 0, len2 = miniSelectedClients.length; j < len2 && !found; j++) {
                    if (miniSelectedClients[j].id === data.id)
                    {
                        miniSelectedClients.splice(j,1);
                        found = true;
                    }
                }
                if (!found){
                    miniSelectedClients.push(data);
                }
            }
        }
        this.setState({miniSelectedClients});
    };


  renderRow = (item, index) => {
    const { module } = this.props
    return (
      <Tr
        className={`data ${item.selected ? 'active' : ''}`}
        key={`${index}`}
        data-item={item}
        onClick={e => this.rowClicked(e, item)}
      >
        <Td className={'name'}>{item.code}</Td>
        <Td className={'name'}>{item.companyName}</Td>
        <Td className={'short center'}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
            }}
          >
            <IconButton
              tooltip={'Editar'}
              icon={faPencilAlt}
              onClick={() => this.goTo(`/editar/${item.id}`)}
            />
            <IconButton
              tooltip={'Historial'}
              icon={faHistory}
              onClick={() => this.goTo(`/historial/${item.id}`)}
            />
            {
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Abrir en Waze</Tooltip>}
              >
                <a
                  href={`https://www.waze.com/ul?ll=${item.latitude}%2C${item.longitude}&navigate=yes`}
                  target={'_none'}
                >
                  <FontAwesomeIcon className={'name'} size="lg" icon={faWaze} />
                </a>
              </OverlayTrigger>
            }
          </div>
        </Td>
      </Tr>
    )
  }

    goTo = (route) => {
        const {match} = this.props;
        this.props.history.push(match.url + route);
    };

    render() {
        const {height, selectedClients,showMarkers,filterTerm,showAlertEmpty,updateSeller, updateDeliverer} = this.state;
        const {sellers,deliverers} = this.props;
        return (
            <Row>
                <Col md={6}>
                    <div style={{paddingBottom: 50}}>
                        <MapCard
                            address={'Dibuja un polígono haciendo clic'}
                            height={height}
                            latitude={latitude}
                            longitude={longitude}
                            markerList={selectedClients}
                            showMarker={true}
                            onClick={this.addPointToPolygon}
                            onMarkerClick={()=>console.log("clicked")}
                            yesIWantToUseGoogleMapApiInternals
                            onChildClick={(value,item) => this.onChildClickCallback(value,item)}
                            onGoogleApiLoaded={({ map, maps }) => this.onGoogleApiLoaded(map, maps)}
                        />
                        <Button className={'big-button'} onClick={this.showClients}> {selectedClients.length === 0 ? 'Mostrar clientes dentro de la región':'Redibujar polígono'}</Button>
                        <SweetAlert
                            show={showAlertEmpty}
                            title={"¡Uh-Oh!"}
                            text={"Debe dibujar un polígono haciendo clic para definir al menos 3 vértices"}
                            type={"error"}
                            onConfirm={() => this.setState({showAlertEmpty: false})}
                        />
                        <Button className={'big-button'} onClick={() => this.setState({showNew: true})}> Edición multiple</Button>
                        {/*
                        <Button className={'big-button'} onClick={() => this.goTo('/admin')}> {'Administrar Polígonos'}</Button>
                        */}
                        <Modal show={this.state.showNew} size="lg" centered onHide={() => this.setState({showNew: false})}
                          >  <Modal.Header closeButton>
                                <Modal.Title>
                                    Edición de clientes dentro de polígono seleccionado
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col md={4}>
                                        <label className={'checkbox-label'}>Editar Vendedor</label>
                                        <Switch
                                            checked={updateSeller}
                                            id={"updateSeller"}
                                            onChange={this.handleSwitchChange}/>
                                    </Col>
                                    <Col>
                                        <CustomSelect value={this.state.seller}
                                                      onChange={this.changeSeller} options={sellers}
                                                      label={"Vendedor"}
                                                      placeholder={"Seleccionar "}
                                                      name={"seller"}
                                                      style={!updateSeller ? {pointerEvents: "none", opacity: "0.4"} : {}}
                                                      menuPortalTarget={document.querySelector('body')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <label className={'checkbox-label'}>Editar Repartidor</label>
                                        <Switch
                                            checked={updateDeliverer}
                                            id={"updateDeliverer"}
                                            onChange={this.handleSwitchChange}/>
                                    </Col>
                                    <Col>
                                        <CustomSelect value={this.state.deliverer}
                                                      onChange={this.changeDeliverer} options={deliverers}
                                                      label={"Repartidor"}
                                                      placeholder={"Seleccionar "}
                                                      name={"deliverer"}
                                                      style={!updateDeliverer ? {pointerEvents: "none", opacity: "0.4"} : {}}
                                                      menuPortalTarget={document.querySelector('body')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Button className={'align-bottom'} onClick={() => {this.setState({showConfirmMultiple: true})}}> <FontAwesomeIcon icon={faSave}/> Guardar</Button>
                                </Row>
                            </Modal.Body>
                        </Modal>
                        <SweetAlert
                            show={this.state.showConfirmMultiple}
                            title="Confirmar"
                            text={"Hay " + selectedClients.length + " clientes dentro del polígono ¿desea realizar edición de los campos seleccionados para todos ellos?"}
                            type={"warning"}
                            showCancelButton
                            onConfirm={() => {
                                this.saveMultiple()
                            }}
                            onCancel={() => {
                                this.setState({showConfirmMultiple: false});
                            }}
                        />
                        <SweetAlert
                            show={this.state.showSuccessMultiple}
                            title="Edición realizada con éxito"
                            text={""}
                            type={"success"}
                            onConfirm={() => {
                                this.setState({showSuccessMultiple: false});
                            }}
                        />
                    </div>
                </Col>
                <Col>
                    <Card title={'Clientes dentro de la región seleccionada'}>
                        <TableV2
                            headers={[
                                {label: 'Código', show: true, value: ['code'], type: 'text' ,  className:'mini'},
                                {label: 'Nombre', show: true, value: ['companyName'], type: 'text' ,  className:'mini'},
                                {
                                    config: true, show: true, label: '', className: 'mini center',
                                    custom: (item) => <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                        <IconButton tooltip={'Editar'} icon={faPencilAlt} onClick={() => this.goTo(`/editar/${item.id}`)} />
                                        <IconButton tooltip={'Historial'} icon={faHistory} onClick={() => this.goTo(`/historial/${item.id}`)} />
                                        {
                                            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Abrir en Waze</Tooltip>}>
                                                <a href={`https://www.waze.com/ul?ll=${item.latitude}%2C${item.longitude}&navigate=yes`} target={'_none'}>
                                                    <FontAwesomeIcon className={'name'} size="lg" icon={faWaze} />
                                                </a>
                                            </OverlayTrigger>
                                        }
                                    </div>
                                }
                            ]}
                            items={selectedClients}
                            filterTerm={filterTerm}
                            mobileAuto
                            onClickTr={(e,item)=> this.rowClicked(e, item)}
                            isSelected={(item)=> item.selected}
                            storageKey={`clientsRegion`}
                        />
                    </Card>
                </Col>
            </Row>

        );
    }
}

MapPolygon.defaultProps = {
    allClients: [],
    selectedClients: []
};

const mapStateToProps = (state) => ({
    allClients: selectAllClients(state),
    module: selectModuleId(state),

    deliverers : selectDeliverersToSelect(state),
    sellers : selectSellers(state),

    genericResponse : selectGenericResponse(state),
});

const mapDispatchToProps = dispatch => ({
    getAllClients: () => dispatch(getAllClients()),
    getDeliverers: () => dispatch(getAllDeliverers()),
    getSellers: () => dispatch(getAllSellers()),
    updateMassive : (request) => dispatch(updateMultipleClients(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapPolygon);
