import { $http } from './http'

class TokensService {
  generateToken = async type => {
    const response = await $http.post('/tokens' + (type ? `?type=${type}` : ''))
    return response.data
  }
}

export default new TokensService()
