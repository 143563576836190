import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Col, Modal, Row } from 'react-bootstrap'
import { CustomReference, Icon, Switch, TableV2 } from '../index'
import { faBook } from '@fortawesome/free-solid-svg-icons'

import { actionTypes as type, getInventoryHistory } from 'src/actions/warehouse.actions'
import { selectHistorical } from 'src/selectors/warehouse.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import ModalTransferDetail from 'src/content/Transfer/DetailTransfers/ModalTransferDetail'
import { formatDateFromMillis, formatNumberWithCommas } from 'src/utils/formatters'
import { ListVariations } from 'src/content/Production/Categorization/Variation/ListVariations'
import { selectCompanyFieldById } from 'src/selectors/company.selector'
import { SubItemInventory } from 'src/content/Warehouse/SubItemInventory'

const defaultFilters = {
  search: '',
  skip: 0,
  size: 10,
}

const InventoryHistory = ({ warehouse, product, close, productName }) => {
  const dispatch = useDispatch()

  const loadingHistorical = useSelector(state =>
    loadingSelector([type.GET_HISTORICAL_INVENTORY])(state),
  )
  const historical = useSelector(selectHistorical)

  const [warehouseId, setWarehouseId] = useState(null)
  const [ref, setRef] = useState({ show: false })
  const [transfer, setTransfer] = useState({ id: null, title: null })
  const [filters, setFilters] = useState(defaultFilters)
  const [filterText, setFilterText] = useState(null)

  const [seeBase, setSeeBase] = useState(false)
  const seeWarehouseLocations = useSelector(state => selectCompanyFieldById(state, 124))

  useEffect(() => {
    if (!warehouse) return

    setWarehouseId(warehouse)
    const presentationFactor = localStorage.getItem('presentation-factor-historical')
    setSeeBase(presentationFactor === 'true')
  }, [warehouse])

  useEffect(() => {
    if (!warehouseId) return

    setFilters({ ...defaultFilters })
  }, [warehouseId])

  useEffect(() => {
    if (!warehouseId || (filterText != null && JSON.stringify(filters) === filterText))
      return

    setFilterText(JSON.stringify(filters))
  }, [filters])

  useEffect(() => {
    if (!warehouseId || JSON.stringify(filters) !== filterText) return

    setUp(filters.start, filters.end)
  }, [filterText])

  const setUp = (start, end) => {
    if (start != null) start = start.setHours(0, 0, 0, 0).valueOf()
    if (end != null) end = end.setHours(59, 59, 59, 59).valueOf()
    const params = {
      start,
      end,
      productId: product,
      skip: filters.skip,
      size: filters.size,
      search: filters.search,
    }

    dispatch(getInventoryHistory(warehouse, params))
  }

  const getSubItems = (item, type, defaultValue, negate) => {
    return (
      <SubItemInventory
        item={item}
        type={type}
        defaultValue={defaultValue}
        negate={negate}
        seeBase={seeBase}
      />
    )
  }

  const headers = [
    {
      label: 'Fecha',
      show: true,
      value: ['createdAt'],
      type: 'date',
      className: 'mini',
    },
    {
      label: 'Documento',
      show: true,
      value: ['typeValue'],
      type: 'text',
      className: 'medium',
    },
    {
      label: 'Código',
      show: true,
      value: ['documentCode'],
      type: 'text',
      className: 'mini',
    },
    {
      label: 'Descripción',
      show: true,
      value: ['description'],
      type: 'text',
      className: 'medium',
    },
    {
      label: 'SKU / Código',
      show: true,
      type: 'text',
      value: ['productCode'],
      className: 'medium',
      style: { minWidth: 140 },
      custom: item => {
        return getSubItems(item, 1, item.productCode)
      },
    },
    {
      label: 'Producto',
      show: true,
      type: 'text',
      value: ['productName'],
      className: 'medium',
      style: { minWidth: 280 },
      custom: item => {
        return (
          <>
            {getSubItems(item, 2, item.productName)}
            {item.listCategorization != null && (
              <ListVariations selected={item.listCategorization} />
            )}
            {item.listLocations != null && seeWarehouseLocations.value === 'true' && (
              <ListVariations selected={item.listLocations} groupName={'Ubicacion'} />
            )}
          </>
        )
      },
    },
    {
      label: 'Anterior',
      show: true,
      value: ['prevQuantity'],
      type: 'number',
      className: 'mini',
      style: { minWidth: 100 },
      custom: item => getSubItems(item, 4, formatNumberWithCommas(item.prevQuantity)),
    },

    {
      label: 'Ajuste',
      show: true,
      value: ['quantity'],
      type: 'number',
      className: 'mini',
      style: { minWidth: 100 },
      custom: item => {
        const negate = item.nowQuantity < item.prevQuantity

        return getSubItems(item, 6, formatNumberWithCommas(item.quantity), negate)
      },
    },

    {
      label: 'Nuevo',
      show: true,
      value: ['nowQuantity'],
      type: 'number',
      className: 'mini',
      style: { minWidth: 100 },
      custom: item => getSubItems(item, 5, formatNumberWithCommas(item.nowQuantity)),
    },
    {
      label: 'Por',
      show: true,
      type: 'text',
      value: ['por'],
      className: 'medium',
      custom: item =>
        (item.por = item.createdData ? item.createdData.name : 'Desconocido'),
    },
    {
      config: true,
      show: true,
      label: '',
      className: 'mini',
      custom: item => (
        <Icon
          icon={faBook}
          tooltip={'Ver referencia'}
          onClick={() => {
            if (item.type === 6 || item.type === 7)
              setTransfer({ id: item.documentId, title: item.description })
            else setRef({ show: true, ...item })
          }}
        />
      ),
    },
  ]

  const getItemValue = (label, value) => (
    <div
      className={'d-flex'}
      style={{
        justifyContent: 'space-between',
        paddingLeft: 10,
        paddingRight: 20,
      }}>
      <div className={'b-user-email'} style={{ minWidth: 100 }}>
        <b>{label}</b>
      </div>
      <div className={'b-user-email text-right'}>{value}</div>
    </div>
  )

  const onHide = () => {
    setWarehouseId(null)
    close()
    setFilters({
      start: null,
      end: null,
      search: '',
      skip: 0,
      size: 10,
    })
    setFilterText(null)
  }

  return (
    <div>
      <Modal
        show={warehouseId != null && !ref.show && !transfer.id}
        size={'xl'}
        centered
        onHide={() => onHide()}>
        <Modal.Header closeButton>
          <Modal.Title>
            Historial de inventario
            {productName ? `: ${productName}` : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableV2
            loading={loadingHistorical}
            customClass={'scrollX'}
            headers={headers}
            storageKey={`inv-historical`}
            items={historical.list ? historical.list : []}
            dateFilter
            nullDate
            mobileAuto
            customFilter={
              <Row className={'mb-2'}>
                <Col xl={5} md={6} sm={6} xs={12}>
                  <Switch
                    mt={3}
                    topLabel
                    label={'Ver cantidad respecto al ítem base'}
                    info={
                      'Se visualizaran las cantidades de los ítems en unidades del ítem base'
                    }
                    checked={seeBase}
                    changeValue={value => {
                      localStorage.setItem('presentation-factor-historical', value)
                      setSeeBase(value)
                    }}
                  />
                </Col>
              </Row>
            }
            total={historical.total}
            getPagination={(skip, size, search) =>
              setFilters({ ...filters, skip, size, search })
            }
            handleChange={search =>
              setTimeout(() => {
                setFilters({ ...filters, search })
              }, 1000)
            }
            onDateChange={(start, end, search) =>
              setFilters({ ...filters, start, end, search })
            }
            mobileSlide={({ item }) => (
              <div className={'column'}>
                {getItemValue('SKU', item.productCode)}
                {getItemValue('Nombre', item.productName)}
                {getItemValue('Fecha', formatDateFromMillis(item.createdAt))}
                {getItemValue('Documento', item.typeValue)}
                {getItemValue('Código', item.documentCode)}
                {getItemValue('desc.', item.description)}
                {getItemValue('Anterior', formatNumberWithCommas(item.prevQuantity))}
                {getItemValue('Ajuste', formatNumberWithCommas(item.quantity))}
                {getItemValue('Nuevo', formatNumberWithCommas(item.nowQuantity))}

                {item.subItems && item.subItems.length > 0 && (
                  <div>
                    <div className={'b-user-name mb-1 mt-2 ml-2'}>Sub ítems</div>
                    {item.subItems.map(subItem => (
                      <div key={subItem.id} className={'column'}>
                        {getItemValue(
                          subItem.code,
                          formatNumberWithCommas(subItem.available),
                        )}
                        {getItemValue(
                          subItem.name,
                          formatNumberWithCommas(subItem.existence),
                        )}
                        {getItemValue('total', formatNumberWithCommas(subItem.reserved))}
                        <div>
                          <hr className={'m-0'} />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          />
        </Modal.Body>
      </Modal>

      <CustomReference
        show={ref.show}
        documentModule={6}
        documentType={ref.type}
        documentId={ref.documentId}
        onHide={() => setRef({ ...ref, show: false })}
      />

      <ModalTransferDetail
        show={transfer.id !== null}
        id={transfer.id}
        title={transfer.title}
        onHide={() => setTransfer({ ...transfer, id: null })}
      />
    </div>
  )
}
export default InventoryHistory
