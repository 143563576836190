import React, { Component } from 'react'
import { connect } from 'react-redux'
import './Redirect.scss'

import { setMenu } from 'src/actions/dashboard.actions'
import {
  setModuleId,
  getSubmodules,
  getCurrentUserRolesAction,
} from 'src/actions/modules.actions'
import {
  actionTypes as userAction,
  confirmEmail,
  saveCurrentModule,
} from 'src/actions/user.actions'

import { history } from '../../App'
import { saveCurrentCompany } from 'src/actions/user.actions'
import { selectSetCurrentCompany } from 'src/selectors/company.selector'
import IconButton from 'src/components/buttons/IconButton'
import { actionTypes as orderTypes, getPackage } from 'src/actions/orders.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  handlerSuccess,
  hasErrors,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'
import { Col, Modal, Row } from 'react-bootstrap'
import Select from 'src/components/inputs/Select/CustomSelect'
import Button from 'src/components/buttons/Button'
import { companies } from 'src/selectors/contracts.selector'
import {
  selectAllWarehousesForSelect,
  selectExternalActiveWarehousesForSelect,
} from 'src/selectors/warehouse.selector'
import {
  actionTypes as warehouseAction,
  createTransferByGroup,
  getExternalActiveWarehouses,
  getWarehouses,
} from 'src/actions/warehouse.actions'
import { types as typesOauth } from 'src/actions/authorization.actions'

import { actionTypes as typeP, getPDFDatasheet } from 'src/actions/products.actions'

import { getContractedCompanies } from 'src/actions/contracts.actions'
import Alert from 'sweetalert-react'
import * as queryString from 'query-string'
import { onSetQueryInfo } from 'src/actions/report.actions'
import { types, createAndStoreCredential } from 'src/actions/authorization.actions'
import {
  actionTypes as companyTypes,
  activateFreeAccount,
} from 'src/actions/company.actions'

class Redirect extends Component {
  state = {
    alert: {
      show: false,
      title: 'default',
      text: 'Default',
      type: 'info',
    },
    showTransfer: false,
    external: false,
    internal: true,
    selected: {},
  }

  componentDidMount() {
    const { company, id, type, module } = this.props.match.params
    const { saveCurrentCompany, getPDFDatasheet, activateFreeAccount } = this.props
    const { url } = this.props.match

    let params = queryString.parse(this.props.location.search)

    const isOAuth = this.props.location.pathname.includes('oauth')
    if (isOAuth) {
      this.props.createAndStoreCredential(params.state, params.code)
    } else if (params.mode === 'verifyEmail' && params.oobCode) {
      this.props.verifyEmail(params.oobCode)
    } else if (url.includes('activation')) {
      activateFreeAccount(params.token)
    } else if (type === 'datasheet') {
      getPDFDatasheet(id)
    } else if (type === 'report') {
      saveCurrentCompany(Number(id)).then(() => {
        let queries = {
          filters: params.categories ? params.categories.split(',') : [],
          segmentations: params.segmentations ? params.segmentations.split(',') : [],
          pos: params.pos ? params.pos.split(',') : [],
          users: params.users ? params.users.split(',') : [],
          products: params.products ? params.products.split(',') : [],
          clients: params.clients ? params.clients.split(',') : [],
          typeSegmentation: Number(params.typeSegmentation),
          params: {
            end: Number(params.end),
            start: Number(params.start),
            type: Number(params.type),
          },
        }

        queries = {
          filters: params.categoriesName
            ? params.categoriesName.split(',').map((d, index) => ({
                id: queries.filters[index],
                value: queries.filters[index],
                label: d,
                name: d,
              }))
            : [...queries.filters],
          segmentations: params.segmentationsName
            ? params.segmentationsName.split(',').map((d, index) => ({
                id: queries.segmentations[index],
                value: queries.segmentations[index],
                label: d,
                name: d,
              }))
            : [...queries.segmentations],
          pos: params.posName
            ? params.posName.split(',').map((d, index) => ({
                id: queries.pos[index],
                value: queries.pos[index],
                label: d,
                name: d,
              }))
            : [...queries.pos],
          users: params.usersName
            ? params.usersName.split(',').map((d, index) => ({
                id: queries.users[index],
                value: queries.users[index],
                label: d,
                name: d,
              }))
            : [...queries.users],
          products: params.productsName
            ? params.productsName.split(',').map((d, index) => ({
                id: queries.products[index],
                value: queries.products[index],
                label: d,
                name: d,
              }))
            : [...queries.products],
          clients: params.clientsName
            ? params.clientsName.split(',').map((d, index) => ({
                id: queries.clients[index],
                value: queries.clients[index],
                label: d,
                name: d,
              }))
            : [...queries.clients],
          typeSegmentation: Number(params.typeSegmentation),
          params: {
            end: Number(params.end),
            start: Number(params.start),
            type: Number(params.type),
          },
        }

        this.props.setQueryInfo(queries)
        setTimeout(() => {
          history.push('/distribucion/reportes/segmentacion')
        }, 1000)
      })
    } else if (type === 'turn') {
      saveCurrentCompany(Number(company)).then(() => {
        history.push(
          `/distribucion/reportes/productos?start=${params.start}&end=${params.end}&userId=${params.userId}`,
        )
      })
    } else if (type === 'expense') {
      let queries = {
        params: {
          end: Number(params.end),
          start: Number(params.start),
          userId: Number(params.userId),
        },
      }
      this.props.setQueryInfo(queries)
      setTimeout(() => {
        history.push('/distribucion/reportes/gastos')
      }, 1000)
    } else if (type === 'perfil')
      saveCurrentCompany(Number(company)).then(() => {
        history.push(`/distribucion/perfil/cuenta`)
      })
    else {
      saveCurrentCompany(Number(company)).then(() => {
        if (Number(module) === 1000)
          this.props.setModuleId({
            id: 1000,
            title: `Transporte - ${company.name || ''}`,
          })
        else if (module)
          this.props.setModuleId({
            id: 2000,
            title: `Producción - ${company.name || ''}`,
          })

        if (!module) this.props.saveCurrentModule()

        const { packageId } = this.props.match.params
        if (Number(module) === 1000) {
          if (type === 'order') history.push(`/distribucion/ordenes/false/detalle/${id}`)
          else if (type === 'group') this.props.getPackage(packageId)
          else if (type === 'transfer') {
            history.push(`/distribucion/transferencias/${id}`)
          } else if (type === 'warehouse') {
            history.push(`/distribucion/bodegas/detalle/${id}`)
          } else if (type === 'link') {
            history.push(`/distribucion/ofertas/detalles/${id}`)
          }
        } else {
          if (type === 'order') history.push(`/produccion/ordenes/false/detalle/${id}`)
          else if (type === 'group') this.props.getPackage(packageId)
          else if (type === 'transfer') {
            history.push(`/produccion/transferencias/${id}`)
          } else if (type === 'warehouse') {
            history.push(`/produccion/bodegas/detalle/${id}`)
          }
        }
      })
    }
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { id } = this.props.match.params
    const {
      packageIsLoading,
      packageHasError,
      module,
      loadingWarehouses,
      transferIsLoading,
      transferHasError,
      verifyIsLoading,
      verifyHasError,
      loadingDatasheet,
      handlerError,
      handlerSuccess,
      loadingFreeAccount,
      freeAccountHasError,
      registerAccountGoogleLoading,
      registerAccountGoogleHasError
    } = this.props
    const { packageError, transferError } = next
    let { alert } = this.state

    if (verifyIsLoading && !next.verifyIsLoading) {
      let newAlert
      if (!verifyHasError && next.verifyHasError) {
        newAlert = handlerError(next.verifyHasError.message)
      } else {
        newAlert = { ...handlerSuccess('Correo electrónico verificado') }
      }
      newAlert.onConfirm = () => {
        this.setState({ alert: { ...alert, show: false } })
        history.replace('/')
      }

      this.setState({ alert: newAlert })
    }

    if (registerAccountGoogleLoading && !next.registerAccountGoogleLoading) {
      let newAlert
      if (!registerAccountGoogleHasError && next.registerAccountGoogleHasError) {
        newAlert = handlerError(next.registerAccountGoogleHasError.message)
      } else {
        newAlert = { ...handlerSuccess('Cuenta asociada con éxito') }
      }
      newAlert.onConfirm = () => {
        this.setState({ alert: { ...alert, show: false } })
        history.push('/distribucion/configuraciones')
      }

      this.setState({ alert: newAlert })
    }

    if (packageIsLoading && !next.packageIsLoading) {
      if (!packageHasError && next.packageHasError) {
        alert = handlerError(
          packageError.response
            ? packageError.response.data.message
            : 'Ha ocurrido un error inesperado.',
        )
        this.setState({ alert })
      } else {
        if (module === 1000) history.push(`/distribucion/ordenes/false/detalle/${id}`)
        else history.push(`/produccion/ordenes/false/detalle/${id}`)
      }
    }

    /*
    if (loadingWarehouses && !next.loadingWarehouses) {
      this.setState({ showTransfer: true })
    } */

    if (transferIsLoading && !next.transferIsLoading) {
      if (!transferHasError && next.transferHasError) {
        alert = handlerError(
          transferError.message
            ? transferError.message
            : 'Ha ocurrido un error inesperado.',
        )
      } else {
        if (module === 1000) history.push(`/distribucion/transferencias`)
        else history.push(`/produccion/transferencias`)
      }
      this.setState({ alert })
    }

    if (loadingDatasheet && !next.loadingDatasheet) {
      history.replace('/')
    }

    if (loadingFreeAccount && !next.loadingFreeAccount) {
      if (!freeAccountHasError && next.freeAccountHasError) {
        alert = handlerError(
          transferError.message
            ? transferError.message
            : 'Ha ocurrido un error al activar su cuenta, por favor contacte a un asesor para mayor información.',
        )
      } else
        alert = handlerSuccess(
          'Cuenta activada exitosamente, por favor cierre esta ventana, las credenciales fueron enviadas a su correo',
        )
      alert.showConfirmButton = false
      this.setState({ alert })
    }
  }

  handleChangeRadio = event => {
    const { name, id } = event.target
    this.setState({ [name]: true, [id]: false })
  }

  createTransfer = () => {
    const { selected } = this.state
    if (selected.toWarehouse) {
      let request = {}
      request.toWarehouse = selected.toWarehouse.value
      if (selected.groupId) this.props.createTransferByGroup(selected.groupId, request)
    }
  }

  render() {
    const { showTransfer, external, internal, selected, alert } = this.state
    const { module } = this.props.match.params
    const { toWarehouses, contractedCompanies, warehouses } = this.props
    return (
      <div className={'redirect'} style={{ height: window.innerHeight }}>
        <div style={{ textAlign: 'center' }}>
          <IconButton size={'8x'} spin />
          <br />
          <h3>Espere mientras es redirigido</h3>
        </div>

        <Modal
          show={showTransfer}
          size={'md'}
          centered
          //onHide ={() => this.setState({showTransfer: false})}
        >
          <Modal.Header>
            <Modal.Title>{'Transferencia de Orden'}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {Number(module) === 2000 && (
              <Row>
                <Col>
                  <label className={'checkbox-label'}>
                    <input
                      onChange={this.handleChangeRadio}
                      checked={internal}
                      type="radio"
                      name={'internal'}
                      id={'external'}
                    />{' '}
                    Transferencia Interna
                  </label>
                </Col>
                <Col>
                  <label className={'checkbox-label'}>
                    <input
                      onChange={this.handleChangeRadio}
                      checked={external}
                      type="radio"
                      name={'external'}
                      id={'internal'}
                    />{' '}
                    Transferencia externa
                  </label>
                </Col>
              </Row>
            )}
            <div className={'column'}>
              {external && (
                <Col xs={12} sm={12} md={12} xl={12}>
                  <Select
                    label={'Compañía'}
                    value={selected.toCompany}
                    options={contractedCompanies}
                    onChange={w => {
                      this.setState({
                        selected: { ...selected, toCompany: w },
                      })
                      this.props.getExternalActiveWarehouses(w.value)
                    }}
                  />
                </Col>
              )}
              <Col xs={12} sm={12} md={12} xl={12}>
                <Select
                  label={'Bodega de entrada'}
                  value={selected.toWarehouse}
                  options={external ? toWarehouses : warehouses}
                  onChange={w => {
                    this.setState({
                      selected: { ...selected, toWarehouse: w },
                    })
                  }}
                  required
                />
              </Col>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div style={{ textAlign: 'right' }}>
              <Button
                disabled={!selected.toWarehouse}
                onClick={() => {
                  this.createTransfer()
                }}>
                Crear transferencia
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        <Alert
          {...alert}
          onConfirm={() => {
            if (alert.onConfirm) alert.onConfirm()
            this.setState({ alert: { ...alert, show: false } })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  company: selectSetCurrentCompany(state),
  //packages
  packageIsLoading: loadingSelector([orderTypes.GET_PACKAGE])(state),
  packageHasError: hasErrorsSelector([orderTypes.GET_PACKAGE])(state),
  packageError: singleErrorSelector([orderTypes.GET_PACKAGE])(state),
  //transfer
  contractedCompanies: companies(state),
  warehouses: selectAllWarehousesForSelect(state),
  loadingWarehouses: loadingSelector([warehouseAction.GET_ALL_WAREHOUSES])(state),
  toWarehouses: selectExternalActiveWarehousesForSelect(state),
  transferIsLoading: loadingSelector([warehouseAction.CREATE_TRANSFER])(state),
  transferHasError: hasErrorsSelector([warehouseAction.CREATE_TRANSFER])(state),
  transferError: singleErrorSelector([warehouseAction.CREATE_TRANSFER])(state),
  verifyIsLoading: loadingSelector([userAction.CONFIRM_EMAIL])(state),
  verifyHasError: hasErrors([userAction.CONFIRM_EMAIL])(state),
  loadingDatasheet: loadingSelector([typeP.GET_PDF_DATASHEET])(state),
  loadingFreeAccount: loadingSelector([companyTypes.ACTIVATE_FREE_ACCOUNT])(state),
  freeAccountHasError: hasErrors([companyTypes.ACTIVATE_FREE_ACCOUNT])(state),
  registerAccountGoogleLoading: loadingSelector([typesOauth.CREATE_AND_STORE_CREDENTIAL])(state),
  registerAccountGoogleHasError: hasErrors([typesOauth.CREATE_AND_STORE_CREDENTIAL])(state),
  handlerError: message => handlerError(message),
  handlerSuccess: message => handlerSuccess(message),
})

const mapDispatchToProps = dispatch => ({
  setQueryInfo: queries => dispatch(onSetQueryInfo(queries)),
  setMenu: routes => dispatch(setMenu(routes)),
  setModuleId: module => dispatch(setModuleId(module)),
  getSubmodules: module => dispatch(getSubmodules(module)),
  saveCurrentModule: () => dispatch(saveCurrentModule(1000)),
  rolesAction: () => dispatch(getCurrentUserRolesAction(1000)),
  saveCurrentCompany: company => dispatch(saveCurrentCompany(company)),
  //packages
  getPackage: id => dispatch(getPackage(id)),
  //transfer
  getWarehouses: () => dispatch(getWarehouses(false, false)),
  getExternalActiveWarehouses: companyId =>
    dispatch(getExternalActiveWarehouses(companyId)),
  getContractedCompanies: () => dispatch(getContractedCompanies(10)),
  createTransferByGroup: (groupId, request) =>
    dispatch(createTransferByGroup(groupId, request)),
  verifyEmail: oobCode => dispatch(confirmEmail(oobCode)),
  getPDFDatasheet: id => dispatch(getPDFDatasheet(id)),
  createAndStoreCredential: (state, code) => dispatch(createAndStoreCredential(state, code)),
  activateFreeAccount: token => dispatch(activateFreeAccount(token)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Redirect)
