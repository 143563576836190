import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Col, Modal, Row } from 'react-bootstrap'
import { Icon, TableV2, Button } from 'src/components'
import { faPhone, faFileInvoice } from '@fortawesome/free-solid-svg-icons'

import { formatDateFromMillis } from 'src/utils/formatters'

import { actionTypes as types } from 'src/actions/orders.actions'
import { selectPaymentsOrder } from 'src/selectors/orders.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { actionTypes as typeR } from 'src/actions/resolutions.actions'

export const ModalNullifyOrder = ({ show, order, onHide, nullifyOrder }) => {
  const payments = useSelector(selectPaymentsOrder)
  const loading = useSelector(state =>
    loadingSelector([types.GET_PAYMENTS_BY_CPP])(state),
  )
  const loadingNullifyOrder = useSelector(state =>
    loadingSelector([typeR.CANCEL_BILL])(state),
  )
  const [showModal, setShow] = useState(false)

  useEffect(() => {
    if (show) setShow(true)
  }, [show])

  const onHideModal = () => {
    setShow(false)
    onHide()
    //dispatch(onSetModalOrder(null))
    //setAlert({ ...alert, show: false })
  }
  const creditStatus = item => {
    if (item.creditStatus === 1) return 'Cobro pendiente'
    if (item.creditStatus === 2) return 'Acreditacion pendiente'
    if (item.creditStatus === 3) return 'Confirmada'
  }

  const getHeadersOrdenes = () => {
    return [
      {
        show: true,
        label: 'No. Recibo',
        value: ['number'],
        type: 'text',
        className: 'mini',
      },
      {
        show: true,
        label: 'Estado',
        value: ['statusC'],
        type: 'text',
        className: 'mini',
        custom: item =>
          (item.statusC = item.creditStatus
            ? creditStatus(item)
            : item.statusData
            ? item.statusData.name
            : 'Desconocido'),
      },
      {
        show: true,
        label: 'Pago',
        value: ['paymentTypeData', 'name'],
        type: 'text',
        className: 'mini',
        custom: item =>
          item.paymentTypeData ? item.paymentTypeData.name : 'Desconocido',
      },
      {
        show: true,
        label: `Fecha de Creación `,
        value: ['createdAt'],
        type: 'date',
        className: 'mini',
      },
      {
        show: true,
        label: `Fecha de Confirmación `,
        value: ['date'],
        type: 'date',
        className: 'mini',
        custom: item => (item.date ? formatDateFromMillis(item.date) : 'Sin confirmar'),
      },
      {
        show: true,
        label: 'Cliente',
        value: ['clientData', 'companyName'],
        type: 'text',
        className: 'medium',
        custom: item => (item.clientData ? item.clientData.companyName : 'Desconocido'),
      },
      {
        show: true,
        label: 'Responsable',
        value: ['createdData', 'name'],
        type: 'text',
        className: 'medium',
        custom: item => (item.createdData ? item.createdData.name : 'Desconocido'),
      },
      {
        show: true,
        label: `Monto`,
        value: ['balance'],
        type: 'currency',
        className: 'mini',
      },
      { show: true, label: '', className: 'mini', config: true },
    ]
  }

  return [
    order !== null && (
      <>
        <Modal
          show={showModal}
          centered
          onHide={() => onHideModal()}
          size={'xl'}
          aria-labelledby={'contained-modal-title-vcenter'}>
          <Modal.Header closeButton>
            <Modal.Title>Anulacion de la Orden #{order.number}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xl={12} md={12} sm={12} className={'invoice'}>
                <Row>
                  <Col xl={12} md={12} sm={12} className={'space-between'}>
                    <h4>
                      <b>Cliente: </b>
                      {order.client ? order.client.name : 'Desconocido'}
                    </h4>
                    <a
                      style={{
                        marginLeft: 10,
                        marginTop: 5,
                        backgroundColor: 'rgba(80,170,70,0.8)',
                        borderRadius: 10,
                      }}
                      className={'pp-phone'}
                      href={
                        order.client && order.client.phone && `tel:${order.client.phone}`
                      }>
                      <Icon
                        icon={faPhone}
                        color={'white'}
                        size={'2x'}
                        style={{ margin: 10, alignSelf: 'center' }}
                      />
                    </a>
                  </Col>
                  <Col xl={12} md={12} sm={12}>
                    <Row>
                      <Col xl={3} md={3} sm={6} xs={6} className={'column'}>
                        <b>Bodega: </b>
                        {order.warehouse ? order.warehouse.name : 'Desconocido'}
                      </Col>
                      <Col xl={3} md={3} sm={6} xs={6} className={'column'}>
                        <b>Fecha: </b>
                        {formatDateFromMillis(order.enabledAt || order.createdAt)}
                      </Col>
                      <Col xl={3} md={3} sm={6} xs={6} className={'column'}>
                        <b>Dirección: </b>
                        {order.client
                          ? order.client.secondAddress || 'Desconocido'
                          : 'Desconocido'}
                      </Col>
                      <Col xl={3} md={3} sm={6} xs={6} className={'column'}>
                        <b>Referencia: </b>
                        {order.client
                          ? order.client.reference || 'Sin referencia'
                          : 'Sin referencia'}
                      </Col>
                      <Col xl={3} md={3} xs={3} className={'column'}>
                        <b>Tipo de pago: </b>
                        {order.paymentTypeData
                          ? order.paymentTypeData.name
                          : 'Desconocido'}
                      </Col>
                      <Col xl={9} md={9} sm={9} className={'column'}>
                        <b>Descripción: </b>
                        {order.description || 'Sin descripción'}
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={12} md={12}>
                    <br />
                  </Col>
                  <Col xl={12} md={12} sm={12} className={'text-center'}>
                    <b className={order.deleted ? 'red-text' : 'green-text'}>
                      {order.deleted
                        ? `Orden Rechazada: ${order.description}`
                        : `Orden ${
                            order.statusData ? order.statusData.name : 'Desconocido'
                          }`}
                    </b>
                  </Col>
                  <Col xl={12} md={12}>
                    <hr />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <TableV2
                  customClass={'scroll-x-without-height'}
                  loading={loading}
                  headers={getHeadersOrdenes()}
                  items={payments}
                  mobileAuto
                  storageKey={`modalNullify`}
                />
              </Col>
            </Row>
            <Col xl={12} md={12} sm={12}>
              <hr />
              <Row className={'container-buttons'}>
                <Button
                  icon={faFileInvoice}
                  right
                  color={'secondary'}
                  loading={loadingNullifyOrder}
                  onClick={() => {
                    let object = {
                      amount: order.total,
                      returnType: order.returnCash ? 1 : order.returnBank ? 2 : null,
                    }
                    nullifyOrder({
                      n: 1,
                      id: order.id,
                      status: true,
                      saber: null,
                      otro: true,
                      credit: false,
                      val: false,
                      object,
                    })
                  }}>
                  Anular Orden
                </Button>
              </Row>
            </Col>
          </Modal.Body>
        </Modal>
      </>
    ),
  ]
}
