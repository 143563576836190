import React from 'react'

import { Modal } from 'react-bootstrap'
import { TableV2 } from 'src/components'

interface IItemPriceError {
  name: string
  price: number
  defaultPrice: number
}

interface IOrderItemsPriceWithErrorProps {
  show: boolean
  items: IItemPriceError[]
  onHide: () => void
}

const headers: ITableHeader[] = [
  {
    show: true,
    label: 'Ítem',
    type: 'text',
    value: ['name'],
  },
  {
    show: true,
    label: 'Precio asignado',
    type: 'currency',
    value: ['price'],
  },
  {
    show: true,
    label: 'Precio esperado',
    type: 'currency',
    value: ['defaultPrice'],
  },
]

const OrderItemsPriceWithError = ({
  show,
  items,
  onHide,
}: IOrderItemsPriceWithErrorProps) => {
  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Se detectaron ítems con precios no autorizados</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TableV2
          // @ts-ignore
          headers={headers}
          items={items}
        />
      </Modal.Body>
    </Modal>
  )
}
export default OrderItemsPriceWithError
