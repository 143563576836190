import React, { Component } from 'react'
import { connect } from 'react-redux'

import Card from 'src/components/cards/Card'

import { Row, Col } from 'react-bootstrap'
import StepZilla from 'react-stepzilla'

import TransportPaymentPolygon from './TransportPaymentPolygon'
import TransportPaymentPayments from './TransportPaymentPayments'
import TransportPaymentResume from './TransportPaymentResume'

import { getAllPolygons } from 'src/actions/polygons.actions'

import {
  actionTypes as typesP,
  getSingleProduct,
  onSetTransportPayment,
} from 'src/actions/products.actions'
import { selectTransportPayment } from 'src/selectors/products.selector'
import { selectSingleProduct } from 'src/selectors/products.selector'

import { loadingSelector } from 'src/selectors/loading.selector'

class TransportPayment extends Component {
  state = {
    steps: [],
  }

  componentDidMount() {
    const { match, getPolygons, getProduct } = this.props
    const { id, paymentId } = match.params
    if (!paymentId) {
      getPolygons()
      getProduct(id)
      let steps = [
        { name: 'Zona de transporte', component: <TransportPaymentPolygon /> },
        {
          name: 'Pagos de transporte',
          component: <TransportPaymentPayments />,
        },
        {
          name: 'Resumen',
          component: <TransportPaymentResume {...this.props} />,
        },
      ]
      this.setState({ steps })
    }
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { loading, transportPayment, setInfo } = this.props
    const { product } = next

    if (loading && !next.loading) {
      setInfo({ ...transportPayment, product })
    }
  }

  render() {
    const { steps } = this.state

    return steps.length ? (
      <Card>
        <Row>
          <Col>
            <div className={'step-progress'}>
              <StepZilla
                steps={steps}
                stepsNavigation={false}
                dontValidate={true}
                showNavigation={false}
              />
            </div>
          </Col>
        </Row>
      </Card>
    ) : (
      <div />
    )
  }
}

const mapStateToProps = state => ({
  product: selectSingleProduct(state),
  loading: loadingSelector([typesP.GET_SINGLE_PRODUCT])(state),
  transportPayment: selectTransportPayment(state),
})
const mapDispatchToProps = dispatch => ({
  getPolygons: () => dispatch(getAllPolygons()),
  getProduct: id => dispatch(getSingleProduct(id)),
  setInfo: info => dispatch(onSetTransportPayment(info)),
})
export default connect(mapStateToProps, mapDispatchToProps)(TransportPayment)
