import { successState } from 'src/actions/global.actions'
import { actions } from 'src/actions/books.actions'

const initialState: IBooksState = {
  purchaseBook: [],
}

const BooksReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case successState(actions.GET_PURCHASE_BOOK):
      return { ...state, purchaseBook: payload }
    default:
      return state
  }
}
export default BooksReducer
