import React, { Component } from 'react'
import './FormTextField.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'
import { faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import CurrencyInput from 'react-currency-input'
import IconButton from '../../buttons/IconButton'
import FormText from './FormText'

export default class FormTextField extends Component {
  render() {
    const {
      mt,
      label,
      type,
      id,
      placeholder,
      name,
      value,
      onChange,
      error,
      required,
      info,
      currency,
      className,
      disabled,
      inputClassName,
      prepend,
      prependMoneySymbol,
      append,
      appendMoneySymbol,
      parentStyle,
    } = this.props
    const { onIconClick, icon } = this.props
    return (
      <div
        className={`input-wrapper has-feedback ${mt >= 0 ? 'mt-'.concat(mt) : 'mt-3'} ${
          className ? className : ''
        }`}
        style={{ ...parentStyle }}>
        {currency ? (
          <>
            {(label || required) && (
              <label className="ftf-form-label left" htmlFor={id}>
                {label} &nbsp;
                {info ? (
                  <label>
                    {' '}
                    <IconButton
                      color={'rgba(34, 96, 149, 0.75)'}
                      tooltip={info}
                      icon={faInfoCircle}
                      size={'sm'}
                    />{' '}
                  </label>
                ) : (
                  <label> &nbsp;</label>
                )}
                {required && (
                  <span style={{ color: 'red', marginRight: 2 }}>&nbsp;* </span>
                )}
              </label>
            )}
            <div className={'mb-3'}>
              <CurrencyInput
                aria-describedby="inputGroup-sizing-default"
                id={id}
                name={name}
                className={`${inputClassName || ''} form-control`}
                placeholder={placeholder}
                value={value}
                onChangeEvent={onChange}
                //onFocus={(event)=> event.target.select()}
                prefix={'Q. '}
                disabled={disabled}
              />
              {icon && (
                <IconButton
                  className={'custom-icon-input'}
                  tooltip={'info'}
                  icon={icon}
                  onClick={onIconClick}
                />
              )}
            </div>
            <span className="input-focus" />
            {error ? (
              <div className="input-error">
                <FontAwesomeIcon icon={faExclamationCircle} /> {error}
              </div>
            ) : (
              <div />
            )}
          </>
        ) : (
          <div>
            <FormText
              id={id}
              type={type}
              name={name}
              //className={inputClassName?'form-input '.concat(inputClassName):'form-input'}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              append={append}
              // appendMoneySymbol={appendMoneySymbol}
              prepend={prepend}
              // prependMoneySymbol={prependMoneySymbol}
              required={required}
              info={info}
              {...this.props}
            />
            {icon && (
              <IconButton
                className={'custom-icon-input'}
                icon={icon}
                onClick={onIconClick}
              />
            )}
          </div>
        )}
      </div>
    )
  }
}
