import React from 'react'
import { useSelector } from 'react-redux'
import { selectTransactions } from 'src/selectors/clients.selector'

import { Row, Col } from 'react-bootstrap'
import { toMoney } from 'src/utils/utilities'
import { CustomSummary, Card, Paragraph } from 'src/components'

interface IClientTransactionsSummaryProps {
  modeSummary?: boolean
}

const ClientTransactionsSummary = ({
  modeSummary = false,
}: IClientTransactionsSummaryProps) => {
  const data: IClientTransactionData = useSelector(selectTransactions)

  const summaries: ISelect[] = [
    { label: 'Total de ventas', value: toMoney(data.sell) },
    { label: 'Pagos recibidos', value: toMoney(data.payments) },
    { label: 'Cuenta por cobrar', value: toMoney(data.balance) },
  ]

  const renderSummary = (
    <CustomSummary
      items={summaries.map(summary => ({
        title: summary.label,
        value: String(summary.value),
      }))}
    />
  )

  const renderCardSummary = (
    <Card>
      <Row>
        {summaries.map(summary => (
          <Col xl={12} key={summary.label}>
            <Paragraph>{summary.label}</Paragraph>
            <Paragraph size={'big'} bold>
              {summary.value}
            </Paragraph>
          </Col>
        ))}
      </Row>
    </Card>
  )

  return modeSummary ? renderSummary : renderCardSummary
}
export default ClientTransactionsSummary
