import React, { useState, useEffect } from 'react'

import { Col, Modal, Row } from 'react-bootstrap'
import { Button, FormText, Icon } from 'src/components'

import {
  faCheck,
  faMinus,
  faPlus,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons'
import { toMoney } from 'src/utils/utilities'

interface ICreditNoteItemSelectedProps {
  item: ICreditNoteItem
  onHide: () => void
  onChange: (item: ICreditNoteItem) => void
}

const CreditNoteItemSelected = ({
  item,
  onHide,
  onChange,
}: ICreditNoteItemSelectedProps) => {
  const show = item !== undefined && item?.detailId !== undefined

  useEffect(() => {
    if (show) setItemSelected(item)
    else setItemSelected(undefined)
  }, [show])

  const [itemSelected, setItemSelected] = useState<ICreditNoteItem>(undefined)

  const getQuantityAvailable = (item: ILineItem): number => {
    const baseQuantity: number = itemSelected.itemData.baseExistence
    const availableQuantity: number = baseQuantity - getQuantityUsed(itemSelected)
    const factor: number = !item.presentationFactor ? 1 : item.presentationFactor
    return availableQuantity / factor
  }

  const changeQuantity = (item: ILineItem, quantity: number) => {
    const customItem: ILineItem = Object.assign({}, item)
    if (!quantity || quantity < 0) quantity = 0

    const decimals = item.productData.decimals
    if (decimals !== undefined) {
      const valueAsString = quantity.toString()
      const decimalIndex = valueAsString.indexOf('.')
      if (decimalIndex !== -1 && valueAsString.length - decimalIndex - 1 > decimals) {
        quantity = parseFloat(quantity.toFixed(decimals))
      }
    }

    customItem.quantity = quantity

    const customItems: ILineItem[] = Object.assign([], itemSelected.itemData.line)
    const index: number = customItems.findIndex(ci => ci.productId === item.productId)

    customItems[index] = customItem
    setItemSelected({
      ...itemSelected,
      itemData: { ...itemSelected.itemData, line: customItems },
    })
  }

  const getQuantityUsed = (item: ICreditNoteItem): number =>
    item?.itemData?.line.reduce(
      (used, item) =>
        used + (!item.presentationFactor ? 1 : item.presentationFactor) * item.quantity,
      0,
    )

  return (
    <Modal show={show} centered size={'lg'}>
      <Modal.Header>
        <Modal.Title>{itemSelected?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'custom-modal-body'}>
        <Row>
          {itemSelected?.itemData?.line?.map(item => {
            const available = getQuantityAvailable(item)

            return (
              <Col xl={12} key={item.id}>
                <FormText
                  lStyle={{ width: '100%' }}
                  label={
                    <>
                      <div style={{ float: 'left' }}>{item?.productData?.name}</div>
                      <div style={{ float: 'right' }}>
                        Disponible: {available + ' u.'}
                      </div>
                    </>
                  }
                  value={item.quantity || 0}
                  type={'number'}
                  changeValue={(quantity, e) => {
                    if (quantity > available + 1) quantity = available + 1
                    e.preventDefault()
                    e.stopPropagation()
                    changeQuantity(item, Number(quantity))
                  }}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  prepend={
                    <Icon
                      icon={faMinus}
                      onClick={(e: Event) => {
                        e.preventDefault()
                        e.stopPropagation()
                        changeQuantity(item, (item.quantity || 0) - 1)
                      }}
                    />
                  }
                  append={
                    <Icon
                      icon={faPlus}
                      disabled={available <= 0}
                      onClick={(e: Event) => {
                        e.preventDefault()
                        e.stopPropagation()
                        changeQuantity(item, (item.quantity || 0) + 1)
                      }}
                    />
                  }
                />
              </Col>
            )
          })}

          <Col xl={12}>
            <FormText
              lStyle={{ width: '100%' }}
              label={
                <>
                  <div style={{ float: 'left' }}>Monto</div>
                  <div style={{ float: 'right' }}>
                    {toMoney(itemSelected?.amount)} / {toMoney(itemSelected?.total)}
                  </div>
                </>
              }
              value={itemSelected?.amount || 0}
              type={'number'}
              changeValue={(amount, e) => {
                e.preventDefault()
                e.stopPropagation()
                if (!amount || amount < 0) amount = 0
                else if (amount > itemSelected.total) amount = itemSelected.total

                setItemSelected({ ...itemSelected, amount })
              }}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className={'container-buttons'}>
          <Button icon={faWindowClose} color={'secondary'} onClick={() => onHide()}>
            Cancelar
          </Button>
          <Button
            disabled={
              getQuantityUsed(itemSelected) <= 0 ||
              !itemSelected?.amount ||
              itemSelected?.amount <= 0
            }
            icon={faCheck}
            onClick={() => onChange({ ...itemSelected, selected: true })}>
            Continuar
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}
export default CreditNoteItemSelected
