import { actionTypes } from '../actions/deposits.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  deposits: [],
  totalDeposits: 0,
  adminDeposits: [],
  deposit: {},
  associated: [],
  receipt: null,
  reference: null,
  depositData: null,
}

const DepositReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_DEPOSITS):
      return {
        ...state,
        deposits: action.deposits ? action.deposits.list : [],
        totalDeposits: action.deposits ? action.deposits.total : 0,
      }
    case successState(actionTypes.GET_ASSOCIATED):
      return {
        ...state,
        associated: action.deposits ? action.deposits : [],
      }
    case successState(actionTypes.GET_DEPOSITS_ADMIN):
      return {
        ...state,
        adminDeposits: extract(action.deposits, []),
      }
    case successState(actionTypes.CREATE_DEPOSIT):
      return {
        ...state,
        deposit: extract(action.deposit, {}),
      }
    case successState(actionTypes.UPDATE_DEPOSIT):
      return {
        ...state,
        deposit: extract(action.deposit, {}),
      }
    case successState(actionTypes.GET_RECEIPT):
      return {
        ...state,
        receipt: extract(action.response, false),
      }
    case successState(actionTypes.GET_REFERENCE):
      return {
        ...state,
        reference: extract(action.response, false),
      }
    case successState(actionTypes.GET_DEPOSIT):
      return {
        ...state,
        depositData: extract(action.response, {}),
      }
    default:
      return state ? state : initialState
  }
}

export default DepositReducer
