import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Alert from 'sweetalert-react'
import { Col, Modal, ProgressBar, Row } from 'react-bootstrap'
import { Button, Card, FormText, Geocoding, Icon, Select } from 'src/components'
import {
  faAddressCard,
  faSave,
  faSignOutAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes as typesWarehouse,
  createSingleWarehouse,
  getSingleWarehouse,
  getWarehousesTypes,
  updateSingleWarehouse,
} from 'src/actions/warehouse.actions'
import { selectSingleWarehouse } from 'src/selectors/warehouse.selector'

import { getAllPOS } from 'src/actions/restaurant.actions'
import { selectAllPOS } from 'src/selectors/restaurant.selector'

import { getAllLocalities } from 'src/actions/address.actions'

import { getUsersChildrenByModule } from 'src/actions/modules.actions'
import { isAllowed, selectUsersToSelect } from 'src/selectors/modules.selector'

import { selectCurrentModule } from 'src/selectors/user.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  errorsSelector,
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
} from 'src/selectors/error.selector'
//import CSVLoader, { CATEGORY_UPLOAD } from 'src/components/CSVLoader/CSVLoader'

import CSVLoaderV2, { CATEGORY_UPLOAD } from 'src/components/CSVLoader/CSVLoaderV2'
import { Country } from 'src/enums/countries'

const WarehouseComponent = ({ modal, id, show, onHide }) => {
  const dispatch = useDispatch()

  const module = useSelector(selectCurrentModule)
  const singleWarehouse = useSelector(selectSingleWarehouse)
  const users = useSelector(selectUsersToSelect)
  const allPOS = useSelector(selectAllPOS)

  const loadingCreate = useSelector(state =>
    loadingSelector([typesWarehouse.CREATE_WAREHOUSE])(state),
  )
  const hasErrorCreate = useSelector(state =>
    hasErrorsSelector([typesWarehouse.CREATE_WAREHOUSE])(state),
  )
  const errorCreate = useSelector(state =>
    errorsSelector([typesWarehouse.CREATE_WAREHOUSE])(state),
  )

  const loadingUpdate = useSelector(state =>
    loadingSelector([typesWarehouse.UPDATE_WAREHOUSE])(state),
  )
  const hasErrorUpdate = useSelector(state =>
    hasErrorsSelector([typesWarehouse.UPDATE_WAREHOUSE])(state),
  )
  const errorUpdate = useSelector(state =>
    errorsSelector([typesWarehouse.UPDATE_WAREHOUSE])(state),
  )

  const loading = useSelector(state =>
    loadingSelector([typesWarehouse.GET_SINGLE_WAREHOUSE])(state),
  )

  const editWarehouse = useSelector(state => isAllowed(state, [1153, 2103]) && !!id)
  const createWarehouse = useSelector(state => isAllowed(state, [1152, 2102]) && !id)

  const [actions, setActions] = useState({
    get: false,
    add: false,
    update: false,
  })
  const [warehouse, setWarehouse] = useState({})
  const [attendants, setAttendants] = useState([])
  const [errors, setErrors] = useState({})
  const [alert, setAlert] = useState({})

  useEffect(() => {
    if (!show) return
    dispatch(getWarehousesTypes())
    dispatch(getAllLocalities())
    dispatch(getUsersChildrenByModule(module, true))
    dispatch(getAllPOS())

    // dispatch(getWarehouses(false))
    setWarehouse({})
    setAttendants([])

    if (id) dispatch(getSingleWarehouse(id))
  }, [show])

  useEffect(() => {
    if (!id || singleWarehouse === {}) {
      setWarehouse({})
    }
  }, [show, singleWarehouse])

  useEffect(() => {
    if (loadingCreate) setActions({ ...actions, add: true })
    else if (actions.add) {
      setActions({ ...actions, add: false })
      if (hasErrorCreate) {
        setAlert({
          ...handlerError(
            (errorCreate.length && errorCreate[0].message) || 'Error al crear bodega',
          ),
          onConfirm: () => setAlert({ show: false }),
        })
      } else {
        setAlert({
          ...handlerSuccess('Bodega creada'),
          onConfirm: () => {
            setAlert({ show: false })
            onHide(true)
          },
        })
      }
    }
  }, [loadingCreate])

  useEffect(() => {
    if (loadingUpdate) setActions({ ...actions, update: true })
    else if (actions.update) {
      setActions({ ...actions, add: false })
      if (hasErrorUpdate) {
        setAlert({
          ...handlerError(errorUpdate.message || 'Error al actualizar bodega'),
          onConfirm: () => setAlert({ show: false }),
        })
      } else {
        setAlert({
          ...handlerSuccess('Bodega actualizada'),
          onConfirm: () => {
            setAlert({ show: false })
            onHide(true)
          },
        })
      }
    }
  }, [loadingUpdate])

  useEffect(() => {
    if (loading) setActions({ ...actions, get: true })
    else if (actions.get) {
      setActions({ ...actions, get: false })
      if (!singleWarehouse) {
        setWarehouse({})
        return
      }
      const {
        typeData,
        localityData,
        attendant,
        attendantData,
        accessible,
        attendantsIds,
        attendantsInfo,
        pos,
      } = singleWarehouse
      const item = {}
      item.attendantIds = []
      item.attendantInfo = []
      const warehousesAccessible = []

      if (pos) item.pos = { ...pos, value: pos.id, label: pos.name }
      if (typeData) item.type = { value: typeData.id, label: typeData.name }
      if (localityData) item.town = { value: localityData.id, label: localityData.name }
      if (accessible && accessible.length > 0)
        accessible.map(a => warehousesAccessible.push(a))
      if (attendantsIds.length > 0) {
        item.attendantIds = attendantsIds
        item.attendantInfo = attendantsInfo
      } else if (attendant) {
        item.attendantIds.push(attendant)
        item.attendantInfo.push({ ...attendantData, principal: true })
      } else {
        item.attendantIds = []
        item.attendantInfo = []
      }
      if (attendantData)
        item.attendant = { value: attendantData.id, label: attendantData.name }
      if (item.attendantInfo) {
        setAttendants(
          Object.assign(
            [],
            item.attendantInfo.map(i => ({
              ...i,
              value: i.id,
              label: i.name,
            })),
          ),
        )
      }
      setWarehouse({ ...singleWarehouse, ...item })
    }
  }, [loading])

  const save = () => {
    const { name, pos } = warehouse
    const errors = {}
    if (!name) errors.name = 'Es requerido'
    if (!attendants || attendants.length === 0) errors.attendant = 'Es requerido'

    const ids = attendants.map(d => d.id)

    if (Object.keys(errors).length === 0) {
      const request = Object.assign({}, warehouse)
      request.type = 1
      request.town = Country.GT
      request.attendant = warehouse.attendant?.value
      request.listAttendants = ids
      request.restricted = false
      if (pos) request.posId = pos.value
      const principal = attendants.find(a => a.principal)
      if (principal) request.principal = principal.value

      if (id) update(request)
      else create(request)
    }
    setErrors(errors)
  }

  const create = warehouse => {
    // let accessible = [];
    // if (warehouse.restricted) {
    //   accessible = warehousesAccessible.filter((w) => !w.deleted).map((d) => ({id: null, warehouseId: d.id, deleted: false}));
    // }
    warehouse.accessible = []
    warehouse.kolo = module === 15000
    dispatch(createSingleWarehouse(warehouse))
  }

  const update = request => {
    request.accessible = []
    dispatch(updateSingleWarehouse(id, request))
  }

  const render = () => {
    return (
      <>
        <Row>
          <Col xl={12} md={12}>
            {loading && (
              <ProgressBar label="Cargando" animated style={{ marginTop: 5 }} now={100} />
            )}
            {module !== 15000 && !id && (
              <Card>
                <CSVLoaderV2
                  category={CATEGORY_UPLOAD.WAREHOUSES}
                  title={'Carga CSV Bodegas'}
                  onSuccess={() => onHide(true)}
                />
              </Card>
            )}
          </Col>
          <Col xl={4} lg={4} md={6} sm={6} xs={12}>
            <FormText
              prepend={<Icon icon={faAddressCard} tooltip={'Nombre de la bodega'} />}
              name={'name'}
              label={'Nombre de la bodega'}
              placeholder={'pj. bodega central'}
              value={warehouse.name}
              onChange={({ target }) =>
                setWarehouse({ ...warehouse, [target.name]: target.value })
              }
              error={errors.name}
              required
              disabled={!editWarehouse && !createWarehouse}
            />
          </Col>
          <Col xl={4} lg={4} md={6} sm={6} xs={12}>
            <Select
              label={'Punto de venta'}
              options={allPOS}
              value={warehouse.pos}
              disabled={!editWarehouse && !createWarehouse}
              onChange={pos => setWarehouse({ ...warehouse, pos })}
            />
          </Col>
          <Col xl={4} lg={4} md={6} sm={6} xs={12}>
            <Select
              label={'Encargados de bodega'}
              value={{ value: null, label: 'Encargado de bodega' }}
              options={users.filter(
                s => attendants.filter(f => f.value === s.value).length === 0,
              )}
              error={errors.attendant}
              disabled={!editWarehouse && !createWarehouse}
              onChange={selectedUser => {
                if (attendants.length === 0) selectedUser.principal = true
                attendants.push(selectedUser)
                setAttendants([...attendants])
              }}
            />
          </Col>
          <Col xl={12} lg={12} md={12}>
            <Row className={'pl-1 mt-2'}>
              {attendants?.map((p, i) => (
                <div className={'user-tag  ml-2'} key={i}>
                  <label className={'label-user-tag'}>{p.label}</label>
                  {(editWarehouse || createWarehouse) && (
                    <Icon
                      className={'delete-user-tag'}
                      icon={faTimes}
                      tooltip={'Quitar'}
                      color={'white'}
                      onClick={() =>
                        setAttendants([...attendants.filter(d => p.id !== d.value)])
                      }
                    />
                  )}
                </div>
              ))}
            </Row>
          </Col>
          <Col xl={12} lg={12} md={12}>
            {attendants && attendants.length > 0 && (
              <Select
                disabled={!editWarehouse && !createWarehouse}
                info={
                  'Cuando una orden kolo es creada, el usuario encargado de la orden seran el encargado principal de la bodega'
                }
                options={attendants}
                value={attendants.find(a => a.principal)}
                label={'Asignar encargado principal'}
                onChange={a => {
                  const atts = attendants.map(at => {
                    at.principal = a.value === at.value
                    return at
                  })
                  setAttendants([...atts])
                  setWarehouse({ ...warehouse, attendant: a })
                }}
              />
            )}
          </Col>
          {warehouse.type && warehouse.type.value === 1 && (
            <Col xl={12} md={12} className={'mt-2'}>
              <Geocoding
                lat={14.55}
                lng={-90.55}
                auto
                editable={editWarehouse && createWarehouse}
                getData={data => {
                  const { latLng } = data
                  setWarehouse({
                    ...warehouse,
                    latitude: latLng.lat,
                    longitude: latLng.lng,
                  })
                }}
              />
            </Col>
          )}
        </Row>

        <Alert {...alert} />
      </>
    )
  }

  const buttons = () => {
    return (
      <Button
        loading={loadingCreate || loadingUpdate}
        onClick={() => save()}
        icon={id ? faSignOutAlt : faSave}>
        {id ? 'Actualizar' : 'Crear'}
      </Button>
    )
  }

  return modal ? (
    <Modal show={show} size={'xl'} centered onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>
          {id && singleWarehouse ? singleWarehouse.name : 'Crear nueva bodega'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{render()}</Modal.Body>
      <Modal.Footer>
        {((editWarehouse && id) || (createWarehouse && !id)) && buttons()}
      </Modal.Footer>
    </Modal>
  ) : (
    <div />
  )
}

export default WarehouseComponent
