import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Alert } from 'react-bootstrap'
import { Icon } from '..'

import { Media } from 'react-breakpoints'
import { hideNotice } from 'src/actions/user.actions'
import { selectHideNav } from 'src/selectors/utilities.selector'
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { selectSetCurrentCompany } from 'src/selectors/company.selector'
import { actionTypes as companyActions } from 'src/actions/company.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import { formatDaysFromMillis } from '../../utils/formatters'

const DemoNotice = ({ isSignedIn }) => {
  const dispatch = useDispatch()

  const [show, setShow] = useState(true)

  const current = useSelector(selectSetCurrentCompany)
  const loading = useSelector(state =>
    loadingSelector([companyActions.CURRENT_COMPANY])(state),
  )
  const hide = useSelector(selectHideNav)

  const redirect = () => {
    setShow(false)
    window.open('https://info.kolo-app.com/planes/', '_blank')
  }
  const daysLeft = formatDaysFromMillis(current.activationDate - new Date().valueOf())

  if (!isSignedIn || hide || loading) return null

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        const tablet = breakpoints[currentBreakpoint] > breakpoints.tablet
        const see =
          (show &&
            current &&
            ((current?.test && !current?.defaulter) || current?.onRenewal)) ||
          false
        return (
          <div
            style={{
              position: 'fixed',
              width: '92%',
              zIndex: 2,
              color: 'white',
            }}>
            <Alert
              show={see}
              style={{ backgroundColor: '#B35796', height: tablet ? 42 : 65 }}>
              <div className={'d-flex space-between'}>
                <div
                  className={'b-user-name white d-flex'}
                  style={{
                    cursor: 'pointer',
                    fontWeight: 500,
                    textDecoration: 'underline',
                    color: 'white',
                  }}
                  onClick={redirect}>
                  <div className={'mr-1'}>
                    <Icon
                      icon={faWindowClose}
                      color={'white'}
                      tooltip={'cerrar'}
                      onClick={ev => {
                        ev.stopPropagation()
                        dispatch(hideNotice())
                        setShow(false)
                      }}
                    />
                  </div>
                  <div>
                    {current.onRenewal
                      ? `Su suscripción ha vencido, posee ${daysLeft} días para activar su renovación`
                      : `La prueba gratuita vence en ${daysLeft} días`}
                  </div>
                </div>
              </div>
            </Alert>
          </div>
        )
      }}
    </Media>
  )
}

export default DemoNotice
