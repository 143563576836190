import { $http } from './http'
import { saveAs } from 'file-saver'
import { getTurnItemsDeletedSegmentation } from 'src/actions/turn.actions'

class TurnService {
  getTurns = async params => {
    const response = await $http.get(`/turn`, { params })
    return response.data
  }

  getActiveTurn = async params => {
    const response = await $http.get(`/turn/active`, { params })
    return response.data
  }

  createTurn = async params => {
    const response = await $http.post(`/turn`, {}, { params })
    return response.data
  }

  createTurnManually = async request => {
    const response = await $http.post(`/turn/manual`, request)
    console.log(response.data)
    return response.data
  }

  getDetailsByMultipleTurn = async id => {
    const response = await $http.get(`/turn/` + id + `/sons`)
    return response.data
  }

  finishTurn = async (id, params) => {
    const response = await $http.put(`/turn/${id}`, {}, { params })
    return response.data
  }

  getTurnPdf = async id => {
    const response = await $http.get(`/turn/pdf/${id}`, {
      responseType: 'blob',
    })

    const file = new Blob([response.data], { type: 'application/pdf' })
    const fileName = 'Detalles del turno.pdf'
    saveAs(file, fileName)
  }

  sendTurnDetails = async (id, params) => {
    const response = await $http.post(`/turn/email/${id}`, {}, { params })
    return response.data
  }

  getTurnSegmentation = async id => {
    const response = await $http.get(`/turn/${id}/segment`)
    return response.data
  }

  getTurnItemsDeletedSegmentation = async id => {
    const response = await $http.get(`/turn/${id}/items-deleted/segment`)
    return response.data
  }

  getTurnReport = async id => {
    const response = await $http.get(`/turn/report/${id}`)
    return response.data
  }
}

export default new TurnService()
