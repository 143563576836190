import CashBoxesService from '../services/cashboxes.service'
import { executeAction } from './global.actions'
import { getFreeResolutions } from './resolutions.actions'

export const actionTypes = {
  GET_CASH_BOXES: 'GET_CASH_BOXES',
  GET_CASH_BOX: 'GET_CASH_BOX',
  GET_ASSIGNED_CASH_BOXES: 'GET_ASSIGNED_CASH_BOXES',
  GET_ASSIGNED_CASH_BOX: 'GET_ASSIGNED_CASH_BOX',
  CREATE_CASH_BOX: 'CREATE_CASH_BOX',
  EDIT_CASH_BOX: 'EDIT_CASH_BOXES',
  ASSIGN_RESOLUTION_TO_CASH_BOX: 'ASSIGN_RESOLUTION_TO_CASH_BOX',
  REMOVE_RESOLUTION_FROM_CASH_BOX: 'REMOVE_RESOLUTION_FROM_CASH_BOX',
  ASSIGN_USER_TO_CASH_BOX: 'ASSIGN_USER_TO_CASH_BOX',
  REMOVE_USER_FROM_CASH_BOX: 'REMOVE_USER_FROM_CASH_BOX',
  ASSIGN_COMPANY_TO_CASH_BOX: 'ASSIGN_COMPANY_TO_CASH_BOX',
  REMOVE_COMPANY_FROM_CASH_BOX: 'REMOVE_COMPANY_FROM_CASH_BOX',
  INVOICE_CONCILIATION: 'INVOICE_CONCILIATION',
}

export const getCashBoxes = () => dispatch => {
  const process = async () => ({
    cashBoxes: await CashBoxesService.getCompanyCashBoxes(),
  })
  dispatch(executeAction(actionTypes.GET_CASH_BOXES, process))
}

export const getAssignedCashBoxes = () => dispatch => {
  const process = async () => ({
    assignedCashBoxes: await CashBoxesService.getAssignedCashBoxes(),
  })
  dispatch(executeAction(actionTypes.GET_ASSIGNED_CASH_BOXES, process))
}

export const getCashBox = cashBox => dispatch => {
  const process = async () => ({
    cashBox: await CashBoxesService.getCashBox(cashBox),
  })
  dispatch(executeAction(actionTypes.GET_CASH_BOX, process))
}

export const getAssignedCashBox = cashBox => dispatch => {
  const process = async () => ({
    cashBox: await CashBoxesService.getAssignedCashBox(cashBox),
  })
  dispatch(executeAction(actionTypes.GET_ASSIGNED_CASH_BOX, process))
}

export const createCashBox = (name, resolution) => dispatch => {
  const process = async () => {
    const cashBox = await CashBoxesService.createCashBox(name, resolution)
    dispatch(getCashBoxes())
    return { cashBox }
  }
  dispatch(executeAction(actionTypes.CREATE_CASH_BOX, process))
}

export const editCashBox = (cashBoxId, name) => dispatch => {
  const process = async () => {
    const cashBox = await CashBoxesService.editCashBox(cashBoxId, name)
    dispatch(getCashBoxes())
    return { cashBox }
  }
  dispatch(executeAction(actionTypes.EDIT_CASH_BOX, process))
}

export const assignResolutionToCashBox = (cashBoxid, resolution) => dispatch => {
  const process = async () => {
    const cashBox = await CashBoxesService.assignResolution(cashBoxid, resolution)
    dispatch(getCashBoxes())
    dispatch(getCashBox(cashBoxid))
    dispatch(getFreeResolutions())
    return { cashBox }
  }
  dispatch(executeAction(actionTypes.ASSIGN_RESOLUTION_TO_CASH_BOX, process))
}

export const removeResolutionFromCashBox = cashBoxid => dispatch => {
  const process = async () => {
    const cashBox = await CashBoxesService.removeResolution(cashBoxid)
    dispatch(getCashBoxes())
    dispatch(getCashBox(cashBoxid))
    dispatch(getFreeResolutions())
    return { cashBox }
  }
  dispatch(executeAction(actionTypes.REMOVE_RESOLUTION_FROM_CASH_BOX, process))
}

export const assignUserToCashBox = (cashBoxId, user) => dispatch => {
  const process = async () => {
    const cashBox = await CashBoxesService.assignUser(cashBoxId, user)
    dispatch(getAssignedCashBoxes())
    dispatch(getAssignedCashBox(cashBoxId))
    return { cashBox }
  }
  dispatch(executeAction(actionTypes.ASSIGN_USER_TO_CASH_BOX, process))
}

export const removeUserFromCashBox = cashBoxId => dispatch => {
  const process = async () => {
    const cashBox = await CashBoxesService.removeUser(cashBoxId)
    dispatch(getAssignedCashBoxes())
    dispatch(getAssignedCashBox(cashBoxId))
    return { cashBox }
  }
  dispatch(executeAction(actionTypes.REMOVE_USER_FROM_CASH_BOX, process))
}

export const assignCompanyToCashBox = (cashBoxid, company) => dispatch => {
  const process = async () => {
    const cashBox = await CashBoxesService.assignCompany(cashBoxid, company)
    dispatch(getCashBoxes())
    return { cashBox }
  }
  dispatch(executeAction(actionTypes.ASSIGN_COMPANY_TO_CASH_BOX, process))
}

export const removeCompanyFromCashBox = cashBoxid => dispatch => {
  const process = async () => {
    const cashBox = await CashBoxesService.removeCompany(cashBoxid)
    dispatch(getCashBoxes())
    return { cashBox }
  }
  dispatch(executeAction(actionTypes.REMOVE_COMPANY_FROM_CASH_BOX, process))
}

export const invoiceConciliation = (data, currentIndex, onSuccess) => async dispatch => {
  const response = await CashBoxesService.invoiceConciliation(data, currentIndex)
  const process = async () => ({ response })
  dispatch(executeAction(actionTypes.INVOICE_CONCILIATION, process))
  onSuccess(response, false)
}

export const invoiceConciliationNullify =
  (data, currentIndex, onSuccess) => async dispatch => {
    const response = await CashBoxesService.invoiceConciliationNullify(data, currentIndex)
    const process = async () => ({ response })
    dispatch(executeAction(actionTypes.INVOICE_CONCILIATION, process))
    onSuccess(response, false)
  }

export const invoiceConciliationCertify =
  (data, currentIndex, onSuccess) => async dispatch => {
    const response = await CashBoxesService.invoiceConciliationCertify(data, currentIndex)
    const process = async () => ({ response })
    dispatch(executeAction(actionTypes.INVOICE_CONCILIATION, process))
    onSuccess(response, false)
  }
