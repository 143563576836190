import React from 'react'

import { haveAnyValue } from 'src/utils/utilitiesV2'

interface ICustomSummaryItem {
  show?: boolean
  title: string
  value?: string | number
  values?: string[] | number[]
}

interface ICustomSummaryProps {
  title?: string
  leftTitle?: boolean
  dataCy?: string | number
  items: ICustomSummaryItem[]
}

const CustomSummary = ({
  title,
  leftTitle = false,
  dataCy,
  items,
}: ICustomSummaryProps) => {
  return (
    <>
      {haveAnyValue(title) && (
        <div className={'key-title'} style={leftTitle ? {} : { textAlign: 'center' }}>
          {title}
        </div>
      )}
      <div className={'d-flex row'} data-cy={dataCy} data-testid={dataCy}>
        {items
          ?.filter(s => s.show === undefined || s.show || s.show === null)
          .map((i, index) => (
            <div key={`${index}`} className={'key-row'}>
              <div className={'key-title'}>{i.title}</div>
              {i.values ? (
                <div>
                  {i.values.map((value, index) => (
                    <>
                      <div key={`${index}`} className={'key-value'}>
                        {value}
                      </div>
                      <br />
                    </>
                  ))}
                </div>
              ) : (
                <div className={'key-value'}>{i.value}</div>
              )}
            </div>
          ))}
      </div>
    </>
  )
}
export default CustomSummary
