import React, { Component } from 'react'

import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { Td, Tr } from 'react-super-responsive-table'
import { faTrashAlt, faEye } from '@fortawesome/free-solid-svg-icons'
import Alert from 'sweetalert-react'

import { Title, Action, Card, Icon as IconButton } from 'src/components'

import { selectRoots } from 'src/selectors/categorizations.selector'
import {
  deleteCategorization,
  actionTypes,
  getAllRootCategorizations,
} from 'src/actions/categorization.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
} from 'src/selectors/error.selector'
import Folder from 'src/components/folders/Folder'

class Categorization extends Component {
  state = {
    alert: {
      show: false,
      title: 'default',
      text: 'default',
      type: 'info',
      onConfirm: () => {},
    },
    showConfirm: false,
    confirmTitle: '',
    confirmAction: null,
    deleteAlert: {
      title: 'Eliminar categorización',
      text: `¿Desea eliminar la categorización seleccionada?`,
      type: 'warning',
      showCancelButton: true,
    },
    isPublic: true,
    own: true,
  }

  UNSAFE_componentWillReceiveProps(next) {
    let { alert } = this.state
    const {
      deleteCategorizationIsLoading,
      categorizationsLoading,
      deleteCategorizationHasError,
      handlerError,
      handlerSuccess,
    } = this.props

    if (deleteCategorizationIsLoading && !next.deleteCategorizationIsLoading) {
      if (!deleteCategorizationHasError && next.deleteCategorizationHasError) {
        alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
        alert = handlerError(
          'Error en los datos trasmitidos, por favor revise e intente de nuevo.',
        )
      } else {
        alert = handlerSuccess('Categorización eliminada exitosamente.')
        alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
      }
    }
    if (categorizationsLoading && !next.categorizationsLoading)
      this.setState({ categorizations: next.categorizations || [] })

    this.setState({ alert })
  }

  componentDidMount() {
    this.props.getCategorizations(true)
  }

  renderRow = (item, index) => {
    return (
      <Tr
        className="data"
        key={index}
        style={item.public ? { backgroundColor: 'rgba(150,146,76,0.6)' } : {}}>
        <Td>{item.public ? <span>Público</span> : <span>Propio</span>}</Td>
        <Td>{item.parent ? item.parent : ''}</Td>
        <Td>{item.name}</Td>
        <Td>{item.description}</Td>
        <Td>
          <Row>
            <Col>
              <IconButton
                tooltip={'Detalles'}
                icon={faEye}
                onClick={() => this.goTo('/detalle/' + item.id)}
              />
            </Col>
            <Col>
              <IconButton
                tooltip={'Eliminar'}
                icon={faTrashAlt}
                onClick={() => this.handleDelete(item.id)}></IconButton>
            </Col>
          </Row>
        </Td>
      </Tr>
    )
  }

  handleCheckBoxChange = event => {
    const { name, checked } = event.target
    this.setState({ [name]: checked })
  }

  handleDelete = categorizationId => {
    const { deleteAlert } = this.state
    const action = () => {
      this.props.deleteCategorization(categorizationId)
      deleteAlert.show = false
      this.setState({ deleteAlert })
    }
    deleteAlert.onConfirm = action
    deleteAlert.onCancel = () => {
      this.setState({ deleteAlert: { ...deleteAlert, show: false } })
    }
    deleteAlert.show = true
    this.setState({ deleteAlert })
  }

  filter = item => {
    const { own, isPublic } = this.state
    return (isPublic && item.public) || (own && !item.public)
  }

  goTo = route => {
    const { history, match } = this.props
    const url = match.url + route
    history.push(url)
  }

  render() {
    const { categorization, categorizationLoading } = this.props
    const { alert, deleteAlert } = this.state
    return (
      <div>
        <Title
          title={'Categorizaciones'}
          action
          to={'nuevo'}
          actionTitle={'Agregar Categorización'}
        />
        <Row>
          <Col>
            <Card title={'Categorizaciones'} white>
              <Action action actionTitle={'Agregar Categorización'} to={'nuevo'} />
              <Card>
                <Folder
                  see
                  data1={
                    categorization && categorization.children
                      ? categorization.children[0]
                      : {}
                  }
                  data2={
                    categorization && categorization.children
                      ? categorization.children[1]
                      : {}
                  }
                  loading={categorizationLoading}
                  onDelete={id => this.handleDelete(id)}
                />
              </Card>
            </Card>
          </Col>
        </Row>
        <Alert {...deleteAlert} />
        <Alert {...alert} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  categorization: selectRoots(state),
  categorizationsLoading: loadingSelector([actionTypes.GET_ALL_ROOTS])(state),
  deleteCategorizationIsLoading: loadingSelector([actionTypes.DELETE_CATEGORIZATION])(
    state,
  ),
  deleteCategorizationHasError: hasErrorsSelector([actionTypes.DELETE_CATEGORIZATION])(
    state,
  ),
})
const mapDispatchToProps = dispatch => ({
  getCategorizations: () => dispatch(getAllRootCategorizations()),
  deleteCategorization: categorizationid =>
    dispatch(deleteCategorization(categorizationid)),
  handlerError: message => handlerError(message),
  handlerSuccess: message => handlerSuccess(message),
})
export default connect(mapStateToProps, mapDispatchToProps)(Categorization)
