import './TableOrderPage.scss'
import 'src/components/StatusStep/StatusStep.scss'

import uuid from 'uuid-random'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import TableOrderItems from './TableOrderItems'
import TableOrderCategories from './TableOrderCategories'
import TableOrderBill from './TableOrderBill'
import TableOrderItemModal from './TableOrderItemModal'
import TableItemActions from './TableItemActions'

import ReactNotification, { store } from 'react-notifications-component'
import Alert from 'sweetalert-react'
import { Modal } from 'react-bootstrap'
import { Button, Select } from 'src/components'
import { faShare } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes as restaurantActions,
  getCategoriesByTablePOS,
  getTableOrder,
  replaceItems,
  setShowItemDetail,
  onUpdateBillItems,
  onSetSeePreBill,
  getTipsAvailable,
  onSetCategorySelected,
  onSetBillItemsSelected,
  onSetTagSelected,
  getTables,
  transferTableAction,
  onSaveDescription,
  getAllPOSByUser,
  resetPOSEmptyTickets,
  setTabSelected,
  setLastTerminalId,
  setLastPosId,
  setClearValue,
  getPosConfigurationByUser,
  getPosInvoiceConcepts,
  setDiscountSelected,
  setPOSSearch,
  clearPreBillCorrelative,
} from 'src/actions/restaurant.actions'

import {
  selectAllPOSUser,
  selectBillItems,
  selectDiscounts,
  selectGetAllTables,
  selectGetTableOrder,
  selectLastTerminalId,
  selectPosConfiguration,
  selectPosSelected,
  selectPOSTabSelected,
  selectSeePreBill,
  selectTips,
} from 'src/selectors/restaurant.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { getClientsSimple } from 'src/actions/clients.actions'
import { selectModalOrderId } from 'src/selectors/utilities.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import { selectCurrentUser } from 'src/selectors/user.selector'
import { clearProductLine } from 'src/actions/products.actions'
import TableOrderSummary from 'src/content/Restaurant/TableOrderPage/TableOrderSummary'
import {
  clearTableOrder,
  setSelectedPendingPayment,
} from 'src/actions/restaurant.actions'
import { simpleClients } from 'src/selectors/clients.selector'
import { onSetTransactionUuId } from 'src/actions/transaction.action'

import CreateProduct from 'src/content/Products/ItemCRUD'
import { itemTypes } from 'src/components/Items/ItemsToSelect'
import { getAllCategorizations } from 'src/actions/categorization.actions'
import { categoryType } from 'src/components/folders/Folder'
import { selectCompanyCountry } from 'src/selectors/company.selector'

import { getAll, setGeneralData } from 'src/actions/printer.actions'
import { selectAllPrinters } from 'src/selectors/printer.selector'
import TableOrderClear from './TableOrderClear'
import { connectWebSocket } from 'src/utils/connectToWebsocket'
import { haveAnyValue } from 'src/utils/utilitiesV2'
import { showAlert } from 'src/actions/alert.actions'

const TableOrderPage = ({ match, history }) => {
  const dispatch = useDispatch()

  const lastTerminalId = useSelector(selectLastTerminalId)

  const configuration = useSelector(selectPosConfiguration)

  const user = useSelector(selectCurrentUser)
  const posUser = useSelector(selectAllPOSUser)
  const clients = useSelector(simpleClients)
  const country = useSelector(selectCompanyCountry)
  const prints = useSelector(selectAllPrinters)

  const { id, posId } = match.params
  const tables = useSelector(selectGetAllTables)
  const orderId = useSelector(selectModalOrderId)
  const tableOrder = useSelector(selectGetTableOrder)
  const seePreBill = useSelector(selectSeePreBill)
  const selectedTab = useSelector(selectPOSTabSelected)
  const posSelected = useSelector(selectPosSelected)

  const pos = posUser.find(pos => pos.id === tableOrder.posId)

  const generate = useSelector(state => state.restaurant.generate)
  const clearValues = useSelector(state => state.restaurant.clearValues)

  const loading = useSelector(state =>
    loadingSelector([
      restaurantActions.GET_TABLE_ORDER,
      restaurantActions.SET_PRODUCT_STATUS,
    ])(state),
  )
  const hasError = useSelector(state =>
    hasErrors([restaurantActions.GET_TABLE_ORDER, restaurantActions.SET_PRODUCT_STATUS])(
      state,
    ),
  )

  const loadingD = useSelector(state =>
    loadingSelector([restaurantActions.DELETE_TABLE_ORDER])(state),
  )
  const hasErrorD = useSelector(state =>
    hasErrors([restaurantActions.DELETE_TABLE_ORDER])(state),
  )

  const loadingTransfer = useSelector(state =>
    loadingSelector([restaurantActions.TRANSFER_TABLE])(state),
  )
  const hasErrorTransfer = useSelector(state =>
    hasErrors([restaurantActions.TRANSFER_TABLE])(state),
  )

  const billItems = useSelector(selectBillItems)
  const tips = useSelector(selectTips)
  const discounts = useSelector(selectDiscounts)

  const [actions, setActions] = useState({
    get: false,
    getItems: false,
    seeInvoice: false,
    delete: false,
    transfer: false,
  })
  const [initLoading, setLoading] = useState(true)
  const [alert, setAlert] = useState({ title: '' })
  const [transferTable, setTransferTable] = useState({ show: false, newTable: {} })
  const [modalQR, setModalQR] = useState(false)
  const [modalParking, setModalParking] = useState(false)
  const [directlyDeliveryItems, setDirectlyDeliveryItems] = useState(false)

  const [showCreate, setShowCreate] = useState(false)

  const minimum = 200
  const step = 50

  useEffect(() => {
    if (prints && prints.length <= 0) dispatch(getAll(id))
    cleanState(true)
    dispatch(onSetTransactionUuId(uuid()))
    dispatch(onSaveDescription(''))
    dispatch(setLastTerminalId(id))
    dispatch(getTableOrder(id, posId))
    dispatch(setDiscountSelected({ value: null, tipValue: null, id: null, tipId: null }))
    dispatch(setTabSelected(0))
    dispatch(setPOSSearch(null))
    dispatch(getAllCategorizations(categoryType.CLIENTS.id))
    dispatch(clearPreBillCorrelative())

    if (!clients.length) dispatch(getClientsSimple())
    if (!posUser.length) dispatch(getAllPOSByUser(user.id))

    if (lastTerminalId === id && !posSelected.seeExistence) {
      setLoading(false)
      return
    }

    dispatch(getCategoriesByTablePOS(id, posId))

    dispatch(
      replaceItems(id, posId, {
        start: 0,
        end: minimum,
      }),
    )

    connectWebSocket(`pos/${id}`, (response = '') => {
      if (response === 'deleted') history.push('/distribucion/mesas')
      else dispatch(getTableOrder(id, posId))
    })

    return () => {
      dispatch(onSetCategorySelected({}))
      dispatch(resetPOSEmptyTickets())
      dispatch(setTabSelected(0))
    }
  }, [])

  useEffect(() => {
    if (loading) setActions({ ...actions, get: true })
    else if (actions.get) {
      setActions({ ...actions, get: false })
      setLoading(false)
      if (!hasError) {
        if (tips.length === 0 && discounts.length === 0)
          dispatch(getTipsAvailable(tableOrder.posId))

        if (tableOrder.posId && configuration.posId !== tableOrder.posId)
          dispatch(getPosConfigurationByUser(tableOrder.posId, user.id))

        if (tableOrder.posId) dispatch(getPosInvoiceConcepts(tableOrder.posId))

        dispatch(setLastPosId(tableOrder.posId))

        if (tableOrder.id) {
          setNewItemsBill()
          dispatch(onSaveDescription(tableOrder.description))
        }

        dispatch(
          setGeneralData({
            pos,
            tableId: id,
          }),
        )
      }
    }
  }, [loading])

  useEffect(() => {
    if (orderId) setActions({ ...actions, seeInvoice: true })
    else if (actions.seeInvoice) {
      setActions({ ...actions, seeInvoice: false })
      dispatch(setClearValue(true))
    }
  }, [orderId])

  useEffect(() => {
    if (clearValues) {
      dispatch(setClearValue(false))

      const { tableOrderStatus } = generate
      cleanState(tableOrderStatus === 2)
      if (tableOrderStatus === 2) {
        dispatch(clearTableOrder())
        dispatch(setSelectedPendingPayment(null))
        history.push('/distribucion/mesas')
      } else {
        dispatch(getTableOrder(id, posId))
        dispatch(onSetTransactionUuId(uuid()))
      }
    }
  }, [clearValues])

  useEffect(() => {
    if (loadingD) setActions({ ...actions, delete: true })
    else if (actions.delete) {
      setActions({ ...actions, delete: false })
      if (hasErrorD) {
        setAlert({
          ...handlerError(hasErrorD.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      } else {
        cleanState(true)
      }
    }
  }, [loadingD])

  useEffect(() => {
    if (loadingTransfer) setActions({ ...actions, transfer: true })
    else if (actions.transfer) {
      setActions({ ...actions, transfer: false })
      if (hasErrorTransfer) {
        setAlert({
          ...handlerError(hasErrorTransfer.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      } else {
        setAlert({
          ...handlerSuccess('Transferencia satisfactoria'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            const newPath = match.path.replace(':id', transferTable.newTable.value)
            history.replace(newPath)
            setTimeout(() => {
              window.location.reload()
            }, 200)
          },
        })
        setTransferTable({ show: false, newTable: {} })
      }
    }
  }, [loadingTransfer])

  const cleanState = clearBillItems => {
    dispatch(onSetCategorySelected({}))
    dispatch(onSetBillItemsSelected([]))
    dispatch(onSetTagSelected([]))
    dispatch(onSetSeePreBill(false))
    if (clearBillItems) dispatch(onUpdateBillItems([]))
  }

  const setNewItemsBill = () => {
    const custom =
      tableOrder.detail?.map(d => {
        let suppliesText = ''
        if (d.supplies) {
          d.supplies.forEach((s, i) => {
            const sign = s.quantity < 0 ? '' : '+'
            const separate = i + 1 === d.supplies.length ? '' : ','
            suppliesText += `${sign}${s.quantity} ${s.productName}${separate} `
          })
        }
        return {
          ...d,
          quantity: d.quantity || 0,
          payed: d.payed,
          status: d.status,
          tag: d.tag,
          extra: d.extra,
          price: d.price,
          subtotal: (d.price + (d.extra || 0)) * (d.quantity || 0),
          suppliesText,
        }
      }) || []

    dispatch(onUpdateBillItems(custom))
  }

  const onTransferTable = () => {
    dispatch(getTables({ posId: tableOrder.posId }))
    setTransferTable({ show: true, newTable: {} })
  }

  const onAddItem = (item, notify) => {
    const billItemsCopy = [...billItems]

    const key = haveAnyValue(item.productId) ? item.productId : item.id
    const index = billItemsCopy.findIndex(i => i.productId === key)
    if (index > -1) {
      billItemsCopy[index].quantity += 1
      billItemsCopy[index].subtotal =
        billItemsCopy[index].quantity * billItemsCopy[index].iPrice
    } else
      billItemsCopy.push({
        ...item,
        groupValue: new Date().valueOf(),
        quantity: 1,
        subtotal: item.price,
        iPrice: item.price,
        discount: 0,
        extra: 0,
        supplies: [],
        suppliesText: '',
        productId: key,
      })
    dispatch(onUpdateBillItems(billItemsCopy))
    if (notify) onSendNotification()
  }

  const onSendNotification = () => {
    store.addNotification({
      title: 'Articulo añadido',
      message: 'El articulo fue añadido a la pre cuenta',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'bounceIn'],
      animationOut: ['animated', 'bounceOut'],
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    })
  }

  const validateExistence = (recipeId, existence, quantity) => {
    const validateExistence = !haveAnyValue(recipeId) && tableOrder.validateExistence
    if (!validateExistence) return true
    else {
      const withExistence = quantity <= existence
      if (!withExistence)
        dispatch(
          showAlert(
            handlerError('Sin existencia suficiente para realizar está operación'),
          ),
        )

      return withExistence
    }
  }

  return (
    <div>
      <div className={'pos-page'}>
        <div className={'pos-main'}>
          <TableOrderItems
            id={id}
            posId={posId}
            minimum={minimum}
            step={step}
            onTransferTable={onTransferTable}
            history={history}
            initLoading={initLoading}
            onClick={(item, totalExistence) => {
              if (item === 1) {
                setShowCreate(true)
              } else if (tableOrder.credit) {
                setAlert({
                  show: true,
                  type: 'warning',
                  title: 'Cuenta congelada',
                  text: 'No es posible realizar esta acción ya que la cuenta fue congelada',
                  confirmButtonText: 'Entendido',
                  confirmButtonColor: '#12A8BB',
                  onConfirm: () => setAlert({ ...alert, show: false }),
                })
              } else if (tableOrder.posType === 2 && item.type === 5) {
                setAlert({
                  show: true,
                  type: 'warning',
                  title: 'Agregar Multa',
                  text: '¿Desea agregar el item de multa? Esta acción agrega el ítem a la cuenta y sumará su valor al total de la factura.',
                  confirmButtonText: 'Agregar',
                  confirmButtonColor: '#12A8BB',
                  showCancelButton: true,
                  cancelButtonText: 'Atrás',
                  onCancel: () => setAlert({ ...alert, show: false }),
                  onConfirm: () => {
                    setAlert({ ...alert, show: false })
                    onAddItem(item, false)
                    setTimeout(() => {
                      setDirectlyDeliveryItems(true)
                    }, 200)
                  },
                })
              } else {
                if (validateExistence(item.recipeId, totalExistence, 1)) {
                  onAddItem(item, true)
                  if (tableOrder.posType === 2) {
                    setModalParking(true)
                    setTimeout(() => {
                      setModalParking(false)
                    }, 200)
                  }
                }
              }
            }}
          />
          {tableOrder.credit ? (
            <TableOrderSummary />
          ) : (
            selectedTab.value === 0 && <TableOrderCategories />
          )}
        </div>
        <div className={'pos-side'}>
          <div
            className={`pos-bill a-in-opacity a-in-right ${
              tableOrder.credit ? 'full' : ''
            }`}>
            {!seePreBill && (
              <TableOrderBill
                onShowQR={() => setModalQR(true)}
                match={match}
                history={history}
                dontShow={seePreBill}
                initClient={country.id}
              />
            )}
          </div>
          {!tableOrder.credit && (
            <div className={'pos-buttons-container'}>
              <TableItemActions
                history={history}
                parkingModal={modalParking}
                directlyDeliveryItems={directlyDeliveryItems}
                onShowQR={() => setModalQR(true)}
                tableId={id}
              />
            </div>
          )}
        </div>

        <ReactNotification className={'pos-notifications'} />
      </div>

      <TableOrderItemModal
        noViewRecipeInModalItem={tableOrder.noViewRecipeInModalItem}
        onClose={() => {
          dispatch(setShowItemDetail({}))
          dispatch(clearProductLine())
        }}
        onChange={request => {
          const existence = request[0].existence
          const recipeId = request[0].recipeId
          const quantity = request.reduce((acc, item) => acc + item.quantity, 0)

          if (validateExistence(recipeId, existence, quantity)) {
            dispatch(setShowItemDetail({}))
            dispatch(onUpdateBillItems([...billItems, ...request]))
          }
        }}
      />

      <CreateProduct
        show={showCreate}
        onHide={(created, itemCreated) => {
          setShowCreate(false)
          if (created) {
            dispatch(
              replaceItems(itemCreated.id, posId, {
                start: 0,
                end: minimum,
              }),
            )
          }
        }}
        createdByTransaction={itemTypes.SELL}
      />

      <Modal
        show={seePreBill}
        size={'xl'}
        centered
        onHide={() => {
          dispatch(onSetSeePreBill(false))
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Cuenta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableOrderBill
            onShowQR={() => setModalQR(true)}
            match={match}
            history={history}
            modal
            initClient={seePreBill}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={transferTable.show}
        centered
        size={'md'}
        onHide={() => setTransferTable({ ...transferTable, show: false })}>
        <Modal.Header closeButton={!loadingTransfer}>
          <Modal.Title>Transferir terminal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Únicamente es posible realizar una transferencia de terminal a otra terminal
            libre.
          </p>
          <Select
            disabled={loadingTransfer}
            label={'Seleccione la nueva terminal'}
            options={tables.filter(t => t.free)}
            value={transferTable.newTable}
            onChange={value => setTransferTable({ ...transferTable, newTable: value })}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className={'container-buttons'}>
            <Button
              loading={loadingTransfer}
              disabled={!transferTable.newTable.value}
              icon={faShare}
              onClick={() => {
                dispatch(transferTableAction(id, transferTable.newTable.value))
              }}>
              Transferir
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={modalQR} size={'md'} centered onHide={() => setModalQR(false)}>
        <Modal.Header closeButton>
          <Modal.Title>QR de la terminal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            alt={'terminal qr'}
            style={{ width: '100%', height: '100%' }}
            src={`data:image/bmp;base64,${tableOrder.qr}`}
          />
        </Modal.Body>
      </Modal>

      <TableOrderClear history={history} />

      <div style={{ display: 'none' }}>
        <canvas id={'canvas'} width={300} height={175} />
        {pos?.ticketLogo && (
          <img
            id={'ticket-logo'}
            alt={'logo'}
            src={`data:image/bmp;base64,${pos.ticketLogo}`}
          />
        )}
      </div>

      <Alert {...alert} />
    </div>
  )
}
export default TableOrderPage
