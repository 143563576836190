export function getUniversalItem(extraFields: IItemToSelect = {}): IItemToSelect {
  const universalItem: IItemToSelect = {
    ...extraFields,
    id: 1,
    value: 1,
    code: 'UNIX',
    label: 'Universal',
    name: 'Universal',
    felItemTypeId: 1,
    list: [],
    line: [
      {
        currentQuantity: extraFields.quantity || 0,
        quantity: extraFields.quantity || 0,
        discount: extraFields.discount || 0,
        id: 1,
        productId: 1,
        productData: {
          active: true,
          code: 'UNIX',
          id: 1,
          productId: 1,
          discountLimit: false,
          discountLimitedNumber: null,
          price: 0,
        },
      },
    ],
    key: Math.random() * 1000000,
    activeUpdatable: true,
  }

  return universalItem
}
