import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {Row, Col} from 'react-bootstrap'

import {getOAuthURL, types} from 'src/actions/authorization.actions'
import {Button} from "src/components";
import {loadingSelector} from "src/selectors/loading.selector";
import {selectUrlOauth} from "src/selectors/authorization.selector";
import { faGooglePlusG} from "@fortawesome/free-brands-svg-icons";

const GoogleOAuth = () => {
    const dispatch = useDispatch()

    const url = useSelector(selectUrlOauth)
    const registeredAccount = url === 'Exist'

    const loading = useSelector(state =>
        loadingSelector([types.GET_OAUTH_URL])(state)
    )
    
    useEffect(() => {
        dispatch(getOAuthURL())
    }, [])

    return (<div className={'mt-3'}>
            <Row>
                <Col xl={12}>
                    <Button
                        disabled={ registeredAccount || loading}
                        loading={loading}
                        style={{ backgroundColor: registeredAccount ? '#4285F4' : '#dd4b39', color: 'white'}}
                        icon={faGooglePlusG}
                        color={' '}
                        onClick={() => window.open(url)}>
                        {registeredAccount  ? 'Cuenta Google conectada' : 'Autenticarse con su cuenta de Google'}
                    </Button>
                </Col>
            </Row>
        </div>)
}
export default GoogleOAuth
