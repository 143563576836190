import React, {Component} from 'react';
import {connect} from "react-redux"

import {actionTypes as types, getAllSellers} from "../../../actions/clients.actions";
import {selectGetSellers} from "../../../selectors/clients.selector";

import {loadingSelector} from "../../../selectors/loading.selector";

import Card from "../../../components/cards/Card";

import {Row, Col} from "react-bootstrap";
import { TableV2 } from '../../../components';

class Sellers extends Component {

    componentDidMount() {
        this.props.getDealers();
    };

    

    render() {
        const {dealers, loading} = this.props;
        return (
            <div>
                <h1 className={'dashboard-welcome'}>Vendedores</h1>
                <Row>
                    <Col md={4}>
                        <Card title={'Filtros'}>
                        </Card>
                    </Col>
                    <Col>
                        <Card title={'Vendedores'}>
                            <TableV2
                                headers={[

                                    { label: 'ID', show: true, value: ['id'], type: 'text', className: 'mini' },
                                    { label: 'Nombre', show: true, value: ['name'], type: 'text', className: 'mini' },
                                    { label: 'Email', show: true, value: ['email'], type: 'text', className: 'mini' },
                                    { config: true, show: true, label: '', className: 'mini center' }
                                ]}
                                items={dealers}
                                loading={loading}
                                mobileAuto
                                storageKey={`sellers2`}
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    dealers: selectGetSellers(state),
    loading: loadingSelector([types.GET_ALL_SELLERS])(state)
});
const mapDispatchToProps = dispatch => ({
    getDealers: () => dispatch(getAllSellers())
});
export default connect(mapStateToProps, mapDispatchToProps)(Sellers);
