import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import { setMenu } from 'src/actions/dashboard.actions'

import { actionTypes as typesC } from 'src/actions/company.actions'
import { selectSetCurrentCompany } from 'src/selectors/company.selector'

import {
  getCurrentUserRolesAction,
  getSubmodules,
  setModuleId,
} from 'src/actions/modules.actions'
import { selectSubModules } from 'src/selectors/modules.selector'

import { addItems } from 'src/actions/shopping.actions'

import Dashboard from '../Dashboard/Dashboard'
import { saveCurrentModule } from 'src/actions/user.actions'

import { onSetDataToAlert } from 'src/actions/utilities.actions'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  singleErrorSelector,
  handlerError,
  hasErrorsSelector,
} from 'src/selectors/error.selector'

import StoreIcon from 'src/assets/images/svg/store.svg'
import LoadingIcon from 'src/assets/images/svg/loading.svg'

// SUB MODULE 'USUARIOS Y PERMISOS'
import Permissions from '../Permissions/Dashboard/Permissions'
import UpdatePermissions from '../Permissions/UpdatePermissions/UpdatePermissions'
import GranularPermission from '../Permissions/Granular/GranularPermission'

// SUB MODULE 'PRODUCTOS'
import Products from './Products/ProductsByPosition'
import ShoppingCar from './Products/ShoppingCar/ShoppingCar'

import ProductsV2 from './Products/ProductsByPositionV2'
import ProductsCompany from './Products/ProductsByCompany'
import ProductProfile from './Products/ProductProfile/ProductProfile'

// SUB MODULE 'ORDENES'
import OrdersByClient from './OrdersByClient/OrdersByClient'
import OrdersByClientDetail from './OrdersByClient/OrdersByClientDetail/OrdersByClientDetail'
import AllOrders from './OrdersByClient/AllOrders/AllOrders'

// SUB MODULE 'PERFIL'
import Profile from './Profile/Profile'

// SUB MODULE 'BODEGAS'
import Warehouse from '../Warehouse/WarehouseV2'
import CreateWarehouse from '../Warehouse/CreateWarehouse/CreateWarehouse'

// SUB MODULE 'SOLICITUDES DE INFORMACIÓN'
import InfoRequest from './InfoRequest/InfoRequest'
import Subscriptions from './Subscriptions/Subscriptions'
import SubscriptionClientDetail from './Subscriptions/SubscriptionClientDetail/SubscriptionClientDetail'
import ShoppingCartV2 from './Products/ShoppingCar/ShoppingCartV2'

class ClientRoutes extends Component {
  componentDidMount() {
    this.props.setModuleId({ id: 3000, title: 'Cliente' })
    this.props.getSubmodules(3000)
    this.props.saveCurrentModule()
    this.props.rolesAction()

    let shopping = window.localStorage.getItem('ADD_ITEM')
    if (shopping) {
      shopping = JSON.parse(shopping)
      this.props.addItems(shopping)
    } else this.props.addItems([])
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { submodules, setMenu, match } = this.props
    if (submodules !== next.submodules) {
      let menu = [
        {
          id: 3000,
          text: 'Menú Clientes',
          icon: StoreIcon,
          link: `${match.url}/`,
        },
      ]
      next.submodules.forEach(subModule => {
        menu.push({
          id: subModule.id,
          text: subModule.name,
          icon: subModule.alterIcon || LoadingIcon,
          link: subModule.route,
          show: subModule.showIcon,
        })
      })
      setMenu(menu)
    }

    const { loadingC, hasErrorC, handlerError, history, setAlert } = this.props
    const { errorC, company } = next
    if (loadingC && !next.loadingC) {
      if (!hasErrorC && next.hasErrorC) {
        // TODO
      } else {
        this.props.setModuleId({
          id: 3000,
          title: `Cliente - ${company.name || ''}`,
        })
        if (!company.clientId) {
          let alert = handlerError(
            `La empresa ${company.name} no posee un perfil de punto de venta.`,
          )
          alert.onConfirm = () => {
            history.replace('/perfil')
            setAlert({ ...alert, show: false })
          }
          setAlert(alert)
        }
      }
    }
  }

  render() {
    const { match } = this.props
    return (
      <Switch>
        <Route
          path={`${match.url}/permisos/:module/:user/:name`}
          component={UpdatePermissions}
        />
        <Route
          path={`${match.url}/permisos/:module/matriz`}
          component={GranularPermission}
        />
        <Route path={`${match.url}/permisos/:module`} component={Permissions} />

        <Route
          path={`${match.url}/productos/detail/:product`}
          component={ProductProfile}
        />
        <Route
          path={`${match.url}/productos/company/:company`}
          component={ProductsCompany}
        />
        <Route path={`${match.url}/productos/compra`} component={ShoppingCartV2} />
        <Route path={`${match.url}/productos`} component={ProductsV2} />

        <Route
          path={`${match.url}/solicitudes/detalle/:id`}
          component={OrdersByClientDetail}
        />
        <Route path={`${match.url}/solicitudes/all`} component={AllOrders} />
        <Route path={`${match.url}/solicitudes`} component={OrdersByClient} />

        <Route path={`${match.url}/perfil`} component={Profile} />

        <Route path={`${match.url}/bodegas/crear`} component={CreateWarehouse} />
        <Route path={`${match.url}/bodegas/editar/:id`} component={CreateWarehouse} />
        <Route path={`${match.url}/bodegas`} component={Warehouse} />

        <Route path={`${match.url}/request`} component={InfoRequest} />

        <Route
          path={`${match.url}/subscriptions/detail`}
          component={SubscriptionClientDetail}
        />
        <Route path={`${match.url}/subscriptions`} component={Subscriptions} />

        <Route path={``} component={Dashboard} />
      </Switch>
    )
  }
}
const mapStateToProps = state => ({
  submodules: selectSubModules(state),
  company: selectSetCurrentCompany(state),
  loadingC: loadingSelector([typesC.CURRENT_COMPANY])(state),
  hasErrorC: hasErrorsSelector([typesC.CURRENT_COMPANY])(state),
  errorC: singleErrorSelector([typesC.CURRENT_COMPANY])(state),
  handlerError: message => handlerError(message),
})
const mapDispatchToProps = dispatch => ({
  setMenu: routes => dispatch(setMenu(routes)),
  setModuleId: module => dispatch(setModuleId(module)),
  getSubmodules: module => dispatch(getSubmodules(module)),
  saveCurrentModule: () => dispatch(saveCurrentModule(3000)),
  rolesAction: () => dispatch(getCurrentUserRolesAction(3000)),
  addItems: shopping => dispatch(addItems(shopping)),
  setAlert: alert => dispatch(onSetDataToAlert(alert)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ClientRoutes)
