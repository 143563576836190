import React, { useEffect, useState } from 'react'

import { Button, Icon, FormText } from 'src/components'

import { Row, Col } from 'react-bootstrap'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'

const QuoteConfig = ({ description, onChange, initial }) => {
  const [listDescription, setListDescription] = useState([])

  useEffect(() => {
    if (!initial) return
    if (description && description.value) {
      setListDescription(
        description.value.split('&&').map(v => ({
          value: v,
        })),
      )
    }
  }, [initial])

  const onChangeDescription = list => {
    onChange({
      description: list
        .filter(l => l.value)
        .map(l => l.value)
        .join('&&'),
    })
  }

  return (
    <Row className={'mt-2'}>
      <Col xl={12}>
        <div className={'d-flex mb-1'}>
          <div className={'mr-1'}>
            Da click al botón &quot;+&quot; para añadir una descripción
          </div>
          <Button
            onClick={() => setListDescription([...listDescription, { value: null }])}>
            <Icon icon={faPlus} tooltip={'Añadir descripción'} />
          </Button>
        </div>

        <div>
          {listDescription.map((d, i) => (
            <div key={i}>
              <FormText
                value={d.value}
                changeValue={v => {
                  listDescription[i].value = v
                  setListDescription([...listDescription])
                  onChangeDescription(listDescription)
                }}
                atext
                append={
                  <Button
                    color={'secondary'}
                    onClick={() => {
                      listDescription.splice(i, 1)
                      setListDescription([...listDescription])
                      onChangeDescription(listDescription)
                    }}>
                    <Icon icon={faTrash} />
                  </Button>
                }
              />
            </div>
          ))}
        </div>

        <div>
          <hr />
        </div>
      </Col>
    </Row>
  )
}
export default QuoteConfig
