import React from 'react'
import { Link } from 'react-router-dom'
import IconClose from 'src/assets/icon-close.svg'
import LogoKolo from 'src/assets/logo-kolo-white.svg'
import Button from 'src/components/buttons/Button'
import './Notifications.scss'

interface IProps {
  onClose?: () => void
  title: string
  description: string
  showButtons?: boolean
  customButtons?: JSX.Element[]
}

/**
 * Template to show a notification en the app
 * @param onClose function to close the notification
 * @param title title of the notification
 * @param description description of the notification
 */
export const TemplateNotification = ({
  onClose,
  title,
  description,
  showButtons,
  customButtons,
}: IProps) => {
  return (
    <div className={'notification'} data-testid={'notification'}>
      <div
        className={'notification-card'}
        onClick={e => {
          e.stopPropagation()
        }}>
        <div className="space-between" data-testid={'notification-header'}>
          <div>
            <img className="mw-logo" src={LogoKolo} alt={'KOLO'} />
          </div>
          {onClose && (
            <div className="pp-btn-close">
              <img
                className="invert"
                width={20}
                height={20}
                src={IconClose}
                alt={'x'}
                data-testid={'close-button'}
                style={{ cursor: 'pointer' }}
                onClick={() => onClose()}
              />
            </div>
          )}
        </div>
        <div className="notification-container" data-testid={'notification-container'}>
          <div className="notification-title ">{title}</div> <br />
          <div className="notification-message" data-testid="notification-message">
            {description}
          </div>
        </div>
        {(showButtons || customButtons) && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px',
              }}>
              {customButtons && customButtons.length > 0 ? (
                customButtons.map((button, index) => button)
              ) : (
                <>
                  <a href="https://wa.me/+50245252182" target="_blank" rel="noreferrer">
                    <Button color="accent">Contactar a un asesor</Button>
                  </a>
                  <Link to="/perfil">
                    <Button color="secondary">Ir a Perfil</Button>
                  </Link>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
