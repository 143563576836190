const compareArrays = (arr1, arr2) => {
  const map1 = new Map(arr1.map(item => [item.id, item.value]))
  const map2 = new Map(arr2.map(item => [item.id, item.value]))

  const changes = []

  map1.forEach((value, id) => {
    if (map2.has(id) && map2.get(id) !== value) {
      changes.push({ id, oldValue: value, newValue: map2.get(id) })
    }
  })

  return changes
}
const compareChanges = (item: DynamicField, fieldData: DynamicField) => {
  return (
    item.name === fieldData.name &&
    item.required == fieldData.required &&
    item.dataType === fieldData.dataType &&
    item.label === fieldData.label &&
    item.entityType === fieldData.entityType &&
    item.description === fieldData.description
  )
}

export { compareChanges, compareArrays }
