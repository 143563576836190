import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Row, Col, Modal } from 'react-bootstrap'
import { faSave } from '@fortawesome/free-solid-svg-icons'

import Alert from 'sweetalert-react'

import Card from 'src/components/cards/Card'
import Button from 'src/components/buttons/Button'
import Select from 'src/components/inputs/Select/CustomSelect'

import {
  actionTypes as typesI,
  getInvoiceProfileOPtions,
  getInvoiceProfile,
  onUpdateInvoiceProfile,
} from 'src/actions/resolutions.actions'
import { selectProfile, selectProfileOptions } from 'src/selectors/resolutions.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  singleErrorSelector,
  hasErrorsSelector,
  handlerSuccess,
  handlerError,
} from 'src/selectors/error.selector'
import { Title } from '../../components'

class InvoiceProfile extends Component {
  state = {
    profile: {},
    alert: { title: '' },
  }

  componentDidMount() {
    const { getOptions, getProfile } = this.props
    getOptions()
    getProfile()
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const { loadingP, loadingU, hasErrorU, getProfile } = this.props
    const { profile, errorU } = nextProps

    if (loadingP && !nextProps.loadingP) {
      if (profile && profile != this.props.profile)
        this.setState({
          profile: {
            ...profile,
            value: profile.id,
            label: profile.profile.name,
            description: profile.profile.description,
          },
        })
    }

    if (loadingU && !nextProps.loadingU) {
      let alert = { title: '' }
      if (!hasErrorU && nextProps.hasErrorU) {
        alert = handlerError(errorU.message || 'Ha ocurrido un error inesperado')
        alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
      } else {
        getProfile()
        alert = handlerSuccess('Perfil de faturación ha sido actualizado')
        alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
      }
      this.setState({ alert })
    }
  }

  validate = () => {
    const { onUpdate } = this.props
    const { profile } = this.state
    onUpdate(profile.profileId, profile.sellOwnProduct)
  }

  render() {
    const { options, loadingU } = this.props
    const { profile, alert } = this.state

    return (
      <div>
        <Title title={'Perfil de Facturación'} />
        <Row>
          <Col xl={4} lg={4} md={4} sm={12} xs={12}>
            <br />
            <Card>
              <div className={'justify-center column'}>
                <h4>
                  <strong>
                    {this.props.profile.id
                      ? this.props.profile.profile.name
                      : 'Desconocido'}
                  </strong>
                </h4>
                <span>
                  {this.props.profile.id
                    ? this.props.profile.profile.description
                    : 'Desconocido'}
                </span>
              </div>
            </Card>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <Card title={'Configurar el Perfil de facturación'} white>
              <Row>
                <Col md={4}>
                  <Select
                    label={'Modelos de facturación FEL'}
                    options={options}
                    value={profile}
                    onChange={p =>
                      this.setState({
                        profile: { ...profile, ...p, profileId: p.id },
                      })
                    }
                    required
                  />
                </Col>
                <Col md={8}>
                  <br />
                  <span>{profile.description}</span>
                </Col>
                <Col>
                  <label className={'checkbox-label'}>
                    <br />
                    <h6 style={{ color: '#3394CE' }}>
                      Facturar producto propio: (
                      {profile.sellOwnProduct ? 'Activo' : 'Inactivo'})
                    </h6>
                    <input
                      type={'checkbox'}
                      name={'sellOwnProduct'}
                      checked={profile.sellOwnProduct}
                      onChange={({ target }) =>
                        this.setState({
                          profile: {
                            ...profile,
                            sellOwnProduct: target.checked,
                          },
                        })
                      }
                    />{' '}
                    Active la opción para que el producto de la empresa sea facturado con
                    el prefil seleccionado.
                  </label>
                </Col>
              </Row>

              <Row className={'container-buttons'}>
                <Button
                  loading={loadingU}
                  variant={'info'}
                  icon={faSave}
                  right
                  onClick={() => this.validate()}
                >
                  Actualizar{' '}
                </Button>
              </Row>
            </Card>
          </Col>
        </Row>

        <Alert {...alert} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  profile: selectProfile(state),
  options: selectProfileOptions(state),
  loadingP: loadingSelector([typesI.GET_INVOICE_PROFILE])(state),
  loadingU: loadingSelector([typesI.UPDATE_INVOICE_PROFILE])(state),
  hasErrorU: hasErrorsSelector([typesI.UPDATE_INVOICE_PROFILE])(state),
  errorU: singleErrorSelector([typesI.UPDATE_INVOICE_PROFILE])(state),
})
const mapDispatchToProps = dispatch => ({
  getOptions: () => dispatch(getInvoiceProfileOPtions()),
  getProfile: () => dispatch(getInvoiceProfile()),
  onUpdate: (profileId, sellOwnProduct) =>
    dispatch(onUpdateInvoiceProfile(profileId, sellOwnProduct)),
})
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceProfile)
