import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Code } from 'react-content-loader'
import { Row, Col } from 'react-bootstrap'
import StepZilla from 'react-stepzilla'

import Card from 'src/components/cards/Card'

import { actionTypes as type, getSingleLink } from 'src/actions/offers.actions'
import { getAccounts } from 'src/actions/banks.actions'

import { getProducts } from 'src/actions/products.actions'

import { loadingSelector } from 'src/selectors/loading.selector'

import ContractPayments from './ContractPayments'
import ContractProducts from './ContractProducts'
import ContractResume from './ContractResume'

const CreateContractKolo = props => {
  const dispatch = useDispatch()

  const loading = useSelector(state => loadingSelector([type.GET_LINK_SINGLE])(state))

  const [steps, setSteps] = useState([])
  const [zoneType, setType] = useState(2)

  useEffect(() => {
    const { type, zone } = props.match.params
    let typeZone = type === '1' || type === '2' ? 1 : 2
    const isKolo = type !== '3'
    dispatch(getSingleLink(zone, typeZone))
    dispatch(getProducts())
    steps.push({
      value: 0,
      name: 'Listado de pagos y zona de transporte',
      component: <ContractPayments isKolo={isKolo} type={type} zoneType={typeZone} />,
    })
    steps.push({
      value: 1,
      name: 'Listado de productos',
      component: <ContractProducts isKolo={isKolo} zoneType={typeZone} />,
    })
    steps.push({
      value: 2,
      name: 'Resumen',
      component: (
        <ContractResume {...props} isKolo={isKolo} zoneType={typeZone} resume={true} />
      ),
    })
    setSteps(steps)
    dispatch(getAccounts())
  }, [])

  return (
    <div className={'step-progress'}>
      <Card title={`${zoneType === 1 ? 'Actualización' : 'Creación'} de enlace`}>
        {loading || steps.length <= 0 ? (
          <Code />
        ) : (
          <Row>
            <Col>
              <StepZilla
                steps={steps}
                stepsNavigation={false}
                dontValidate={true}
                showNavigation={false}
              />
            </Col>
          </Row>
        )}
      </Card>
    </div>
  )
}
export default CreateContractKolo
