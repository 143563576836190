import { $http } from './http'

class CurrenciesServices {
  getCountries = async () => {
    const response = await $http.get('/countries')
    return response.data
  }

  getEconomicActivity = async country => {
    const response = await $http.get('/countries/economic/activity', {
      params: { country },
    })
    return response.data
  }

  getAllCurrencies = async () => {
    const response = await $http.get('/currencies')
    return response.data
  }

  getCurrentCurrency = async () => {
    const response = await $http.get(`/currencies/current`)
    return response.data
  }

  getFiscalPrecinct = async () => {
    const response = await $http.get('/countries/fiscal/precinct')
    return response.data
  }

  getRegimen = async () => {
    const response = await $http.get('/countries/regime')
    return response.data
  }

  getIncoterm = async () => {
    const response = await $http.get('/countries/incoterm')
    return response.data
  }

  getExportationCountries = async () => {
    const response = await $http.get('/countries/exportation')
    return response.data
  }
}

export default new CurrenciesServices()
