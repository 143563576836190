import React, {Component} from 'react';
import {connect} from "react-redux";

import {Row, Col} from "react-bootstrap";
import {Td, Tr} from "react-super-responsive-table";
import {faLongArrowAltLeft, faSave} from "@fortawesome/free-solid-svg-icons";

import Alert from "sweetalert-react";

import MapCard from "../../../../components/maps/MapCard";
import Button from "../../../../components/buttons/Button";
import Card from "../../../../components/cards/Card";


import {formatNumberWithCommas} from "../../../../utils/formatters";

import {actionTypes as typesP, onCreateTransportationPayment} from "../../../../actions/products.actions";
import {selectTransportPayment, selectTransportPaymentC} from "../../../../selectors/products.selector";

import {loadingSelector} from "../../../../selectors/loading.selector";
import {hasErrorsSelector, singleErrorSelector, handlerSuccess, handlerError} from "../../../../selectors/error.selector";
import { selectCurrentCurrency } from '../../../../selectors/currencies.selector';
import { TableV2 } from '../../../../components';

const latitude = 14.589465440976774;
const longitude = -90.51898866891861;

class TransportPaymentResume extends Component {

    state = {
        mapS: null,
        mapsS: null,
        manualPolygon: null,
        alert: {title: 'title'}
    };

    componentWillReceiveProps(next) {
        const {loadingC, hasErrorC, history, match} = this.props;
        const {errorC, responseC} = next;

        if (loadingC && !next.loadingC) {
            let alert = {title: 'title'};
            if (!hasErrorC && next.hasErrorC) {
                alert = handlerError(errorC.message || 'Ha ocurrido un error inesperado.');
                alert.onConfirm = () => this.setState({alert: {...alert, show: false}});
            } else {
                alert = handlerSuccess('Pago de transporte creada satisfactoriamente');
                alert.onConfirm = () => {
                    this.setState({alert: {...alert, show: false}});
                    history.goBack();
                };
            }
            this.setState({alert});
        }
    };

    onGoogleApiLoaded = (map, maps) => {
        setTimeout(() => {
            const {transportPayment} = this.props;
            this.setState({mapS: map, mapsS: maps});
            if (transportPayment.polygon && transportPayment.polygon.value) {
                this.setPolygon(transportPayment.polygon);
            }
        }, 1000);
    };

    setPolygon = (polygon) => {
        if (polygon.value) {
            let {mapS, mapsS, manualPolygon} = this.state;
            if (manualPolygon) manualPolygon.setMap(null);
            if (mapS, mapsS) {
                const points = polygon.points.map((p) => ({lat: Number(p.latitude), lng: Number(p.longitude)}));
                manualPolygon = new mapsS.Polygon({
                    path: points,
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#FF0000",
                    fillOpacity: 0.35
                });

                const center = this.getCenter(points);
                if (center.latLng.lat > 0) mapS.panTo(center.latLng);
                manualPolygon.setMap(mapS);
                this.setState({manualPolygon});
            }
        };
    };

    getCenter = (paths) => {
        const center = paths.map(p => {
            return [p.lat, p.lng]
        }).reduce((x, y) => {
            return [x[0] + y[0] / paths.length, x[1] + y[1] / paths.length]
        }, [0, 0])
        return {latLng: {lat: center[0], lng: center[1]}};
    };

    validate = () => {
        const {transportPayment, create} = this.props;
        let item = {
            polygonId: transportPayment.polygon.id,
            productId: transportPayment.product.id,
            amounts: transportPayment.amounts
        };
        create(item);
    };

    render() {
        const {transportPayment, currentCurrency} = this.props;
        const {alert} = this.state;

        return (
            <div>
                <Row>
                    <Col xl={7} lg={7} md={7} xm={12} xs={12}>
                        <MapCard
                            title={transportPayment.polygon.label}
                            latitude={latitude}
                            longitude={longitude}
                            height={500}
                            startCollapsed={false}
                            markerList={[]}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => this.onGoogleApiLoaded(map, maps)}
                        />
                    </Col>
                    <Col xl={5} lg={5} md={5} xm={12} xs={12}>
                        <Card title={'Pagos de transporte'}>
                            <TableV2
                                items={transportPayment.amounts}
                                mobileAuto
                                storageKey={`transportPayment`}
                                headers={[

                                    { label: 'Minima', show: true, value: ['minimum'], type: 'measure', className: 'mini' },
                                    { label: 'Maxima', show: true, value: ['maximum'], type: 'measure', className: 'mini' },
                                    { label: 'Pago', show: true, value: ['amount'], type: 'currency', className: 'mini' },
                                    { config: true, show: true, label: '', className: 'mini center' }
                                ]}

                            />
                        </Card>
                    </Col>
                </Row>

                <Row className={'container-buttons'}>
                    <Button
                        onClick={() => this.props.jumpToStep(1)} icon={faLongArrowAltLeft}>
                        Atras
                    </Button>
                    <Button
                        onClick={() => this.validate()} icon={faSave}>
                        Guardar
                    </Button>
                </Row>

                <Alert {...alert}/>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    transportPayment: selectTransportPayment(state),
    loadingC: loadingSelector([typesP.TRANSPORT_PAYMENT_CREATE])(state),
    hasErrorC: hasErrorsSelector([typesP.TRANSPORT_PAYMENT_CREATE])(state),
    errorC: singleErrorSelector([typesP.TRANSPORT_PAYMENT_CREATE])(state),
    responseC: selectTransportPaymentC(state),
    currentCurrency: selectCurrentCurrency(state)
});

const mapDispatchToProps = dispatch => ({
    create: (request) => dispatch(onCreateTransportationPayment(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(TransportPaymentResume);
