import React, {Component} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CollapsibleCard from "../../components/cards/collapsible-card/CollapsibleCard";
import Button from "../../components/buttons/Button";
import Card from "../../components/cards/Card";
import {Td, Tr} from "react-super-responsive-table";
import {formatDateFromMillis, formatNumberWithCommas} from "../../utils/formatters";
import {actionTypes} from "../../actions/deposits.actions";
import {createDeposit, getDeposits} from "../../actions/deposits.actions";
import {connect} from "react-redux"
import {loadingSelector} from "../../selectors/loading.selector";
import {selectGetCreatedDeposit, selectGetDeposits} from "../../selectors/deposits.selector";
import {Modal} from "react-bootstrap";
import FormTextField from "../../components/inputs/FormTextField/FormTextField";
import CustomSelect from "../../components/inputs/Select/CustomSelect";
import SweetAlert from "sweetalert-react";
import Gallery, {imageTypes} from "../../components/gallery/Gallery";
import {getAccountHistory, getAccounts, getBanks} from "../../actions/banks.actions";
import {
    selectGetAccounts,
    selectGetAccountsForSelect,
    selectGetBanks,
    selectGetHistory
} from "../../selectors/banks.selector";
import {selectCurrentModule, selectCurrentUser} from "../../selectors/user.selector";
import {hasErrorsSelector, singleErrorSelector} from "../../selectors/error.selector";
import Alert from "react-bootstrap/Alert";
import {selectSetCurrentCompany} from "../../selectors/company.selector";
import {getCurrentUser} from "../../actions/user.actions";
import { selectCurrentCurrency } from '../../selectors/currencies.selector';
import { TableV2 } from '../../components';

class Deposits extends Component {

    state = {
        showNew:false,
        own: false,
        number:'',
        amount:'',
        banks:[
            {value:1,label:"Banco Industrial"},
            {value:2,label:"Banrural"},
            {value:3,label:"BAM"},
            {value:4,label:"G&T"},
            ],
        bank:{value:null,label:"--Seleccione una cuenta--"},
        ownBank:{value:null,label:"--Seleccione una cuenta--"},
        alert: {
            show: false,
            title: 'Default',
            text: 'default',
            type: 'info'
        },
        approved:true,
        submitted:true,
        rejected:true,
        showGallery:false,
        bankAccount: 0,
        fieldsError:false,
        startDate: new Date(),
        endDate: new Date(),
        kolo: false
    };

    componentDidMount(){
        const {getDeposits, getCurrentUser, getAccounts, getBanks, match} = this.props;
        getDeposits();
        getCurrentUser();
        getAccounts();
        getBanks();

        if (match) {
            const {params} = match;
            if (params) {
                const {type} = params;
                if (type === "true")
                    this.setState({kolo: true});
            }
        }
    };

    filter = item =>{
        const {approved, submitted, rejected} = this.state;
        const today = new Date().getTime();
        return ( (approved && item.status==101) || (submitted && item.status==100) || (rejected && item.status==102))
            && !item.own;
    };

    companyFilter = item =>{
        const {approved, submitted, rejected, startDate, endDate} = this.state;
        let start = startDate.setHours(0,0,0).valueOf(),
            end = endDate.setHours(23,59,59).valueOf()
        return ((approved && item.status==101) || (submitted && item.status==100) || (rejected && item.status==102))
        && item.createdAt >= start && item.createdAt <= end && (item.own ? this.depositFilter(item) : false);
    }

    depositFilter = (item) =>{
        const {accounts} = this.props
        return accounts.filter(this.bankFilter).find(f => f.id===item.bank)
    }

    bankFilter = (item) =>{
        const {module} = this.props
        // return (module === 8000 || module === item.module)
        return item;
    }

    renderRow = (item, index) => {
        return (
            <Tr className={'data'} key={`${index}`}>
                <Td className={'mini'}>{formatDateFromMillis(item.createdAt)}</Td>
                <Td className={'mini'}>{item.own ? this.getBankName(item.bank) : this.getBank(item.bank)}</Td>
                <Td className={'mini'}>{item.number}</Td>
                <Td>{formatNumberWithCommas(item.amount)}</Td>
                <Td className={'mini'}>{this.getState(item.status)}</Td>
                <Td className={'mini'}>{item.user?item.user:'--'}</Td>
                <Td className={'mini'}>{item.auth?item.auth:'--'}</Td>
                <Td className={'medium'}>{item.message}</Td>
            </Tr>
        )
    };

    getHeaders = () => {
        return [
            
            {label: 'Fecha', show: true, value: ['createdAt'], type: 'date' ,  className:'mini'},
            {label: 'Banco', show: true, value: ['bancoC'], type: 'text' ,  className:'mini',
            custom:(item)=> item.bancoC = item.own ? this.getBankName(item.bank) : this.getBank(item.bank)},
            {label: 'Numero', show: true, value: ['number'], type: 'text' ,  className:'mini'},
            {label: 'Cantidad', show: true, value: ['amount'], type: 'currency' ,  className:'mini'},
            {label: 'Estado', show: true, value: ['statusC'], type: 'text' ,  className:'mini', custom:(item)=> item.statusC =this.getState(item.status)},
            {label: 'Creado por', show: true, value: ['user'], type: 'text' ,  className:'mini'},
            {label: 'Autorizado por', show: true, value: ['auth'], type: 'text' ,  className:'mini'},
            {label: 'Mensaje', show: true, value: ['message'], type: 'text' ,  className:'medium'},
            {config:true, show: true, label:'', className:'mini center'}
        ]
    }

    getBankName = (id) =>{
        const {accounts} = this.props
        let found = accounts.find(f => f.id === id)
        return found ? found.name:'----'
    };

    getBankType = (id) => {
        const {globalBanks} = this.props
        let found = globalBanks.find(f=>f.id===id);
        return found?found.name:'';
    };

    getState(state)
    {
        switch (state) {
            case 100:
                return "Ingresada";
            case 101:
                return "Aprobada";
            case 102:
                return "Rechazada";
            default:
                return "-----";
        }
    }

    getBank(bank){
        let found = '-----';
        const{banks} = this.state;
        banks.map(element => {
            if(element.value===bank)
            {
                found = element.label;
                //console.log(element,bank)
            }
        } );
        return found;
    }

    handlerError  = (message) => {
        return {show: true, title: 'Uh-Oh..', text: message, type: 'warning'};
    };

    handlerSuccess = (message) => {
        return {show: true, title: 'Yeah!', text: message, type: 'success'};
    };

    componentWillReceiveProps(next) {
        const {createIsLoading,deposit, createHasError} = this.props;
        const {createError} = next;
        let { alert } = this.state;
        if (createIsLoading && !next.createIsLoading) {
            if(!createHasError && next.createHasError){
                alert = this.handlerError(createError.response ? createError.response.data.message : 'Ha ocurrido un error inesperado.');
            }
            else{
                alert = this.handlerSuccess('Deposito Registrado');
            }
            this.props.getDeposits();
            this.setState({alert});
            this.setState({fieldsError: false});
        }
    }

    handleInputChange = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: value});
    };

    handleCurrencyInputChange = (event, maskValue, value) => {
        const {name} = event.target;
        this.setState({[name]: value});
    };

    ContractedCompaniesChanged = value => {
        const {own} = this.state
        if(own) this.setState({ownBank: value});
        else this.setState({bank: value});
    }

    saveDeposit = () => {
        const{amount,bank, ownBank,number, own} = this.state;
        if(!amount || amount === 0 || !bank || !ownBank || !number || number.length<=0){
            this.setState({fieldsError: true});
            return;
        }
        if(own) this.props.createDeposit(amount,ownBank.value,number, true);
        else this.props.createDeposit(amount,bank.value,number, false);
        this.setState({showNew:false,number:'',amount:''});
    };

    handleCheckBoxChange = (event) => {
        const {name, checked} = event.target;
        this.setState({[name]: checked, page: 1});
    };

    onDateChange = (from, to) => {
        this.setState({startDate: from, endDate: to});
    };



    render() {
        const {deposits,depositsIsLoading,company,deposit,currentUser, accounts, currentCompany, currentCurrency} = this.props;
        const {showNew,amount,banks,bank,approved,submitted,rejected,showGallery, own, ownBank, alert,fieldsError, kolo} = this.state;
        return (
            <div>
                <Row>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                        <CollapsibleCard title={'Filtros'} startCollapsed={true}>

                            <Row>
                                <Col>
                                    <label className={'checkbox-label'}><input type="checkbox" name="approved" checked={approved} onChange={this.handleCheckBoxChange}/> Aprobadas</label>
                                </Col>
                                <Col>
                                    <label className={'checkbox-label'}><input type="checkbox" name="submitted" checked={submitted} onChange={this.handleCheckBoxChange}/> Ingresadas </label>
                                </Col>
                                <Col>
                                    <label className={'checkbox-label'}><input type="checkbox" name="rejected" checked={rejected} onChange={this.handleCheckBoxChange}/> Rechazadas</label>
                                </Col>
                            </Row>

                        </CollapsibleCard>

                        {kolo ?
                            <Card title={'Cuentas de Banco Cotizap'} bodyStyle={{padding: 10}}>
                                <Row >
                                    <Col className={'text-center'}>
                                        <br/>
                                        <div className={'center'}>Banco Industrial: 3230071106</div>
                                        <br/>
                                        <div className={'center'}>Banco GyT Continental: 66-6635-3</div>
                                        <br/>
                                        <div className={'center'}>BAM: 3040247257</div>
                                        <br/>
                                        <div className={'center'}>Banrural: 03103200085138</div>
                                        <br/>
                                        <div className={'center'}>Nombre: COTIZAP, SOCIEDAD ANONIMA</div>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row><Col className={'text-center'} >
                                    <div className={'center'}>Puedes Realizar depositos desde BI-Banking con el codigo <br/>
                                        <b>{currentCompany.alphanumericId}{currentUser.alphanumericId}</b></div>
                                </Col></Row>
                                <hr/>
                                <Button
                                    onClick={() => this.setState({showNew:true, own: false})} className={'big-button'}>
                                    Ingresar depósito a Cotizap
                                </Button>
                            </Card>
                            :
                            <Card title={'Cuentas de la empresa'} bodyStyle={{padding: 10}}>
                                <TableV2
                                    headers={[
                                        { label: 'Nombre', show: true, value: ['name'], type: 'text', className: 'mini' },
                                        { label: 'No. cuenta', show: true, value: ['accountNumber'], type: 'text', className: 'mini' },
                                        { label: 'Banco', show: true, value: ['bankTypeC'], type: 'text', className: 'mini', custom:(item)=> item.bankTypeC = this.getBankType(item.bank) },
                                        { config: true, show: true, label: '', className: 'mini center' }
                                    ]}
                                    items={accounts.filter(this.bankFilter)}
                                    mobileAuto
                                    onClickTr={(e,item)=> {this.setState({bankAccount: item})}}
                                    storageKey={`companyAccounts`}
                                />
                                <Button
                                    onClick={() => this.setState({showNew:true, own: true})} className={'big-button'}>
                                    Ingresar depósito a cuenta de la empresa
                                </Button>
                            </Card>
                        }
                    </Col>

                    <Col xl={8} lg={8} md={8} sm={12} xs={12}>{kolo ?
                        <Card title={'Depositos ingresados a Cotizap'}>
                            <TableV2
                                headers={this.getHeaders()}
                                items={deposits.filter(this.filter)}
                                noItemsLegend={'Sin depositos registrados'}
                                tableDivStyle={{overflowX:"scroll",minHeight:"450px",maxHeight:"600px"}}
                                loading={depositsIsLoading}
                                mobileAuto
                                storageKey={`deposits`}

                            />
                        </Card>
                        :
                        <Card title={'Depositos ingresados a cuentas propias'}>
                            <TableV2
                                headers={this.getHeaders()}
                                dateFilter
                                onDateChange={this.onDateChange}
                                items={deposits ? deposits.filter(this.companyFilter) : []}
                                mobileAuto
                                storageKey={`deposits`}
                                loading={depositsIsLoading}
                                noItemsLegend={'Sin depositos registrados'}
                                tableDivStyle={{overflowX:"scroll",minHeight:"450px",maxHeight:"600px"}}

                            />
                           
                        </Card>
                    }</Col>
                </Row>

                <Modal
                    show={showNew}
                    size={'lg'}
                    centered
                    onHide={() => {this.setState({showNew:false}); this.setState({fieldsError: true});}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Nuevo Deposito</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Row>
                                <Col>
                                    <FormTextField
                                        label={"Numero"}
                                        type={"text"}
                                        id={"number"}
                                        placeholder={"Numero"}
                                        name={"number"}
                                        value={this.state.number}
                                        onChange={this.handleInputChange}
                                        error={""}
                                        required
                                    />
                                </Col>
                                <Col>
                                    <FormTextField
                                        label={"Cantidad"}
                                        type={"text"}
                                        id={"amount"}
                                        placeholder={"Cantidad"}
                                        name={"amount"}
                                        value={amount}
                                        onChange={this.handleCurrencyInputChange}
                                        currency
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <CustomSelect
                                        label={'Banco'}
                                        isSearchable={false}
                                        options={own ? accounts.filter(this.bankFilter) : [...banks]}
                                        value={own ? ownBank : bank}
                                        onChange={this.ContractedCompaniesChanged}
                                    />
                                </Col>
                            </Row>
                            {
                                fieldsError&&<Row><Col><p className={"red"}>*Revise los campos requeridos</p></Col></Row>
                            }
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Row className={'container-buttons'}>
                            <Button onClick={() => this.setState({showNew:false,number:'',amount:''})}>Cerrar</Button>
                            <Button disabled={own ? !ownBank.value : !bank.value} onClick={() => this.saveDeposit()}>Guardar</Button>
                        </Row>
                    </Modal.Footer>
                </Modal>
                {/*extra modal*/}
                <Modal
                    show={showGallery}
                    size={'lg'}
                    centered
                    onHide={() => this.setState({showNew:false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Nuevo Deposito</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Gallery
                              imageType={imageTypes.DEPOSITS}
                              company={company && company.id}
                              entityId={deposit && deposit.id}
                              showUploader={true}
                              />
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Row className={'container-buttons'}>
                            <Button
                                onClick={() => this.setState({showNew:false,number:'',amount:''})}>
                                Cerrar
                            </Button>
                            <Button
                                onClick={() => this.saveDeposit()}
                            >
                                Guardar
                            </Button>
                        </Row>
                    </Modal.Footer>

                </Modal>
                <Alert {...alert} onConfirm={() => this.setState({alert: {...alert, show: false}})}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    depositsIsLoading:loadingSelector([actionTypes.GET_DEPOSITS])(state),
    createIsLoading: loadingSelector([actionTypes.CREATE_DEPOSIT])(state),
    createHasError: hasErrorsSelector([actionTypes.CREATE_DEPOSIT])(state),
    createError: singleErrorSelector([actionTypes.CREATE_DEPOSIT])(state),
    deposits:selectGetDeposits(state),
    deposit:selectGetCreatedDeposit(state),
    accounts: selectGetAccountsForSelect(state),
    history: selectGetHistory(state),
    module: selectCurrentModule(state),
    globalBanks: selectGetBanks(state),
    currentCompany: selectSetCurrentCompany(state),
    currentUser: selectCurrentUser(state),
    currentCurrency: selectCurrentCurrency(state)
});

const mapDispatchToProps = dispatch => ({
    getDeposits: ()=> dispatch(getDeposits()),
    createDeposit: (amount,bank,number, own)=> dispatch(createDeposit(amount,bank,number, own)),
    getAccountHistory: (id) => dispatch(getAccountHistory(id)),
    getAccounts: () => dispatch(getAccounts()),
    getBanks: () => dispatch(getBanks()),
    getCurrentUser: () => dispatch(getCurrentUser()),
});

export default connect(mapStateToProps,mapDispatchToProps) (Deposits);
