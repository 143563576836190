import { Country } from 'src/enums/countries'

enum CertifierName {
  EDX = 1,
  G4S = 2,
  TEKRA = 3,
  INFILE = 4,
  COFIDI = 5,
  INFILE_SV = 6,
  EXTERNO = 7,
}

const certifiers: IDictionary<Certifier> = {
  [CertifierName.EDX]: {
    label: 'EDX',
    value: CertifierName.EDX,
    fields: [
      { label: 'Area', value: 'area' },
      { label: 'Contraseña', value: 'password' },
    ],
    enableSAT: true,
  },
  [CertifierName.G4S]: {
    label: 'G4S',
    value: CertifierName.G4S,
    fields: [
      { label: 'Requestor', value: 'requestor' },
      { label: 'Usuario', value: 'user' },
    ],
    enableSAT: true,
  },
  [CertifierName.TEKRA]: {
    label: 'TEKRA',
    value: CertifierName.TEKRA,
    fields: [
      { label: 'Usuario', value: 'user' },
      { label: 'Clave', value: 'password' },
      { label: 'Cliente', value: 'client' },
      { label: 'Contrato', value: 'contract' },
    ],
    enableSAT: true,
  },
  [CertifierName.INFILE]: {
    label: 'INFILE',
    value: CertifierName.INFILE,
    fields: [
      { label: 'Usuario', value: 'user' },
      { label: 'TOKEN SIGNER', value: 'password' },
      { label: 'LLAVE WS', value: 'apiKey' },
    ],
    enableSAT: true,
  },
  [CertifierName.COFIDI]: {
    label: 'COFIDI',
    value: CertifierName.COFIDI,
    fields: [
      { label: 'Requestor', value: 'requestor' },
      { label: 'Usuario', value: 'user' },
    ],
    enableSAT: true,
  },
  [CertifierName.INFILE_SV]: {
    label: 'INFILE',
    value: CertifierName.INFILE_SV,
    fields: [
      { label: 'Usuario', value: 'user' },
      { label: 'Llave', value: 'password' },
    ],
    enableSAT: false,
  },
  [CertifierName.EXTERNO]: {
    label: 'Externo',
    value: CertifierName.EXTERNO,
    fields: [],
    enableSAT: false,
  },
}

const {
  [CertifierName.INFILE_SV]: infileSV,
  [CertifierName.EXTERNO]: ext,
  ...rest
} = certifiers

const certifiersByCountry: Record<Country, IDictionary<Certifier>> = {
  [Country.GT]: { ...rest },
  [Country.SV]: { infileSV },
  [Country.HN]: { ext },
  [Country.UNI]: {},
}

function getCertifiers(country: Country): Certifier[] {
  const certs = certifiersByCountry[country]
  return certs !== undefined ? Object.values(certs) : []
}

export { getCertifiers }
