import { faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { showAlert } from 'src/actions/alert.actions'
import {
  deleteUserInPlan,
  actions as planActions,
  getActivePlan,
  getCompanyActivePlan,
  deleteCompanyUserInPlan,
} from 'src/actions/plans.actions'
import { setModalAddUser, setModalUsers } from 'src/actions/utilities.actions'

import { Button, Icon, Paragraph, TableV2 } from 'src/components'
import IconButton from 'src/components/buttons/IconButton'
import { selectGetSingleCompany } from 'src/selectors/company.selector'
import {
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { selectActivePlans, selectCompanyActivePlans } from 'src/selectors/plans.selector'
import { selectModalAddUser, selectModalUsers } from 'src/selectors/utilities.selector'
import { formatNumberWithCommas, getItemValue } from 'src/utils/formatters'

const headers = [
  { label: 'Nombre', show: true, value: ['name'], type: 'text' },
  { label: 'Correo', show: true, value: ['email'], type: 'text' },
  { label: 'Tipo', show: true, value: [''], type: 'boolean' },
  { label: '', show: true },
]

const ModalUsers = () => {
  const dispatch = useDispatch()

  const [action, setAction] = useState({
    delete: false,
  })

  const modalUsers = useSelector(selectModalUsers)
  const { show, companyId } = modalUsers

  const modalAddUser = useSelector(selectModalAddUser)
  const company = useSelector(selectGetSingleCompany)
  const plan = useSelector(companyId ? selectCompanyActivePlans : selectActivePlans)
  const users = plan.userList?.concat(plan.extraUsers) || []

  const deleteLoading = useSelector(state =>
    loadingSelector([planActions.DELETE_USER])(state),
  )
  const deleteHasError = useSelector(state =>
    hasErrorsSelector([planActions.DELETE_USER])(state),
  )
  const deleteError = useSelector(state =>
    singleErrorSelector([planActions.DELETE_USER])(state),
  )

  useEffect(() => {
    if (!show) return
    if (companyId) dispatch(getCompanyActivePlan(companyId))
  }, [show])

  useEffect(() => {
    if (deleteLoading) setAction({ ...action, delete: true })
    else if (action.delete) {
      setAction({ ...action, delete: false })
      if (deleteHasError) {
        dispatch(
          showAlert({
            ...handlerError(deleteError.message || 'No se ha podido eliminar el usuario'),
          }),
        )
      } else {
        if (companyId) dispatch(getCompanyActivePlan(companyId))
        else dispatch(getActivePlan())
        dispatch(
          showAlert({
            ...handlerSuccess('Usuario eliminado con éxito'),
          }),
        )
      }
    }
  }, [deleteLoading])

  const getItemsMobile = () => {
    let array = []
    let store = {
      title: { value: ['name'], type: 'text' },
      subtitle: { value: ['email'], type: 'text' },
    }

    users.forEach(item => {
      let object = {
        id: item.id,
        title: getItemValue(item, store.title.value, store.title.type),
        subtitle: getItemValue(item, store.subtitle.value, store.subtitle.type),
        complement1: item.principal ? 'Acceso Completo' : 'Limitado',
        complement2: '',
        key: `us-${item.id}`,
        action:
          company.owner !== item.id ? (
            !deleteLoading ? (
              <IconButton
                icon={faTrash}
                size={'lg'}
                tooltip={'Eliminar'}
                color={'secondary'}
                onClick={() => {
                  if (companyId) dispatch(deleteCompanyUserInPlan(companyId, item.id))
                  else dispatch(deleteUserInPlan(item.id))
                }}
              />
            ) : (
              <div>
                <Icon icon={faSpinner} spin={deleteLoading} size={'1_5x'} />
              </div>
            )
          ) : (
            <div>
              <Icon
                icon={faSpinner}
                spin={deleteLoading}
                size={'1_5x'}
                style={{ visibility: 'hidden' }}
              />
            </div>
          ),
      }
      array.push(object)
    })
    return array
  }

  const totalUsers = (plan.users || 0) + (plan.userSpaces || 0)

  return (
    <div style={{ position: 'fixed' }}>
      <Modal
        show={modalUsers.show && !modalAddUser.show}
        centered
        size={'lg'}
        onHide={() => {
          dispatch(setModalUsers({ show: false, users: [] }))
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Usuarios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={'column'}>
            <Paragraph size={'big'}>
              {users?.length}/{totalUsers} usuario(s) contratados
            </Paragraph>
            <TableV2
              items={users}
              headers={headers}
              itemsMobile={getItemsMobile()}
              hideConfig
              renderRow={(item, index) => (
                <tr className={'data'} key={`${index}`}>
                  <td className={'text-left'}>{item.name}</td>
                  <td className={'text-left'}>{item.email}</td>
                  <td className={'text-left'}>
                    {item.extra ? 'Usuario adicional' : 'Incluido con el plan'}
                  </td>
                  <td className={'text-center'}>
                    {company.owner !== item.id ? (
                      !deleteLoading ? (
                        <IconButton
                          icon={faTrash}
                          size={'lg'}
                          tooltip={'Eliminar'}
                          color={'secondary'}
                          onClick={() => {
                            if (companyId)
                              dispatch(deleteCompanyUserInPlan(companyId, item.id))
                            else dispatch(deleteUserInPlan(item.id))
                          }}
                        />
                      ) : (
                        <div>
                          <Icon icon={faSpinner} spin={deleteLoading} size={'1_5x'} />
                        </div>
                      )
                    ) : (
                      <div />
                    )}
                  </td>
                </tr>
              )}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              plan.users + plan.userSpaces <=
              plan.userList?.length + plan.extraUsers?.length
            }
            onClick={() =>
              dispatch(
                setModalAddUser({
                  new: true,
                  show: true,
                  id: plan.id,
                  list: plan.list,
                  modules: [],
                  type:
                    plan.modules !== 'all'
                      ? { value: 0, label: 'Límite 5 aplicaciones' }
                      : { value: 0, label: 'Todas las aplicaciones' },
                  extra: plan.userList && plan.userList.length >= plan.users,
                  companyId,
                }),
              )
            }>
            Añadir usuario nuevo
          </Button>
          <Button
            disabled={
              plan.users + plan.userSpaces <=
              plan.userList?.length + plan.extraUsers?.length
            }
            onClick={() =>
              dispatch(
                setModalAddUser({
                  show: true,
                  id: plan.id,
                  list: plan.list,
                  modules: [],
                  type:
                    plan.modules !== 'all'
                      ? { value: 0, label: 'Límite 5 aplicaciones' }
                      : { value: 0, label: 'Todas las aplicaciones' },
                  extra: plan.userList && plan.userList.length >= plan.users,
                  companyId,
                }),
              )
            }>
            Añadir usuario existente
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default ModalUsers
