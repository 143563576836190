import React, { Component } from 'react'
import './Switch.scss'
import { faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconButton from '../../buttons/IconButton'

export default class Switch extends Component {
  getLabel = (label, info, isLeft, placement) => {
    return (
      <label className={'checkbox-label '.concat(isLeft ? 'left' : '')}>
        {`${label}`}
        {info && (
          <label>
            {' '}
            <IconButton
              placement={placement ? placement : 'right'}
              iconStyle={{ marginLeft: 22 }}
              tooltip={info}
              color={'rgba(34, 96, 149, 0.75)'}
              icon={faInfoCircle}
              size={'sm'}
            />{' '}
          </label>
        )}
      </label>
    )
  }

  render() {
    const {
      mt,
      placement,
      checked,
      onChange,
      id,
      disabled,
      style,
      info,
      label,
      loading,
      color,
      topLabel,
      name,
      toggle,
      cStyle,
      left,
      dataCy,
      changeValue,
    } = this.props

    return (
      <div
        data-testid={dataCy}
        className={`${topLabel ? '' : 'd-flex'} ${mt ? 'mt-'.concat(mt) : ''} `.concat(
          left ? ' left' : '',
        )}
        style={{ ...cStyle }}>
        {topLabel && label && this.getLabel(label, info, left, placement)}

        {loading ? (
          <FontAwesomeIcon
            spin
            size={'2x'}
            className={'name'}
            icon={faSpinner}
            style={{ color: color || 'rgb(108,175,199)' }}
          />
        ) : (
          <label className={'switch'} style={{ ...style }}>
            <input
              disabled={disabled}
              type="checkbox"
              checked={checked}
              onChange={e => {
                if (onChange) onChange(e)
                if (changeValue) {
                  const { checked } = e.target
                  changeValue(checked)
                }
              }}
              id={id}
              data-testid={id}
              value={checked}
              name={name}
            />
            <span
              className={'slider'}
              style={{ backgroundColor: toggle && 'rgb(179,87,150)' }}
            />
            {this.props.children}
          </label>
        )}
        {!topLabel && label && this.getLabel(label, info, null, placement)}
      </div>
    )
  }
}
