import * as React from 'react';
import {
    Col,
    ListGroup,
    Modal,
    Row,
    Tab,
    Jumbotron,
    Container,
    Carousel,
    Button,
    InputGroup,
    Tooltip, OverlayTrigger, FormControl, ToggleButtonGroup, ToggleButton
} from "react-bootstrap";

import {connect} from "react-redux";
import Card from "../../../../components/cards/Card";
import CustomSelect from "../../../../components/inputs/Select/CustomSelect";
import {selectAllClients} from "../../../../selectors/clients.selector";
import {getAllClients} from "../../../../actions/clients.actions";
import ClientIcon from "../../../../assets/images/svg/client.svg";

import '../Subscriptions.scss'
import {handlerError} from "../../../../selectors/error.selector";
import MapCard from "../../../../components/maps/MapCard";
import {faLongArrowAltLeft, faLongArrowAltRight, faTrash} from "@fortawesome/free-solid-svg-icons";
import Alert from "sweetalert-react";
import {selectSubscriptionsPolygons} from "../../../../selectors/polygons.selector";
import {getAllPolygons, getSubscriptionsPolygons} from "../../../../actions/polygons.actions";

import Select from "../../../../components/inputs/Select/CustomSelect";
import {Td, Tr} from "react-super-responsive-table";
import {formatNumberWithCommas} from "../../../../utils/formatters";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";
import {loadingSelector} from "../../../../selectors/loading.selector";
import {actionTypes} from "../../../../actions/polygons.actions";
import GoogleMapReact from "google-map-react";
import {MAPS_API_KEY} from "../../../../settings/app.settings";
import MarkerIcon from "../../../../assets/images/svg/marker.svg";
import CollapsibleCard from "../../../../components/cards/collapsible-card/CollapsibleCard";
import Money from '../../../../components/Money/Money';
import { toMoney } from '../../../../utils/utilities';
import { TableV2 } from '../../../../components';


const Marker = () => <img src={MarkerIcon} alt={'position'} width={40}/>;

const MarkerActive = () => <img src={MarkerIcon} alt={'position'} width={60}/>;

const days = [
    {id: 0, title: 'Domingo'},
    {id: 1, title: 'Lunes'},
    {id: 2, title: 'Martes'},
    {id: 3, title: 'Miercoles'},
    {id: 4, title: 'Jueves'},
    {id: 5, title: 'Viernes'},
    {id: 6, title: 'Sabado'},
]

const times = [
    {id: 0, title: '1:00'},
    {id: 1, title: '2:00'},
    {id: 2, title: '3:00'},
    {id: 3, title: '4:00'},
    {id: 4, title: '5:00'},
    {id: 5, title: '6:00'},
    {id: 6, title: '7:00'},
    {id: 7, title: '8:00'},
    {id: 8, title: '9:00'},
    {id: 9, title: '10:00'},
    {id: 10, title: '11:00'},
    {id: 11, title: '12:00'},
    {id: 12, title: '13:00'},
    {id: 13, title: '14:00'},
    {id: 14, title: '15:00'},
    {id: 15, title: '16:00'},
    {id: 16, title: '17:00'},
    {id: 17, title: '18:00'},
    {id: 18, title: '19:00'},
    {id: 19, title: '20:00'},
    {id: 20, title: '21:00'},
    {id: 21, title: '22:00'},
    {id: 22, title: '23:00'},
    {id: 23, title: '24:00'},
]

class ModalSelectPolygons extends React.Component{

    state = {
        mapS: null,
        mapsS: null,
        manualPolygon: null,
        alert: {title: 'title'},

        polygon: {},

        polygons: [],

        price: 0,

        currentPickUpPoint: {},
        pickUpPointsList: [],

        showReturnable: false,

        showOptions: false,

        pickUpDays: [],
        pickUpTimes: [],
        returnable: null
    };

    componentDidMount() {
        this.props.getSubscriptionsPolygons();
    }

    componentWillReceiveProps(next) {
        const {loadingP} = this.props;
        const {subscriptionsPolygons} = next;

        if (loadingP && !next.loadingP) {
            let items = subscriptionsPolygons.map((i) => {
                return {
                    ...i,
                    checked: false,
                };
            });
            this.setState({polygons: items})
        }
    };

    onGoogleApiLoaded = (map, maps) => {
        setTimeout(() => {
            const {polygon} = this.props;
            this.setState({mapS: map, mapsS: maps});
            if (polygon && polygon.value) {
                this.setPolygon(polygon);
            }
        }, 1000);
    };

    setPolygon = (polygon) => {
        if (polygon.value) {
            let {mapS, mapsS, manualPolygon} = this.state;
            if (manualPolygon) manualPolygon.setMap(null);
            if (mapS, mapsS) {
                const points = polygon.points.map((p) => ({lat: Number(p.latitude), lng: Number(p.longitude)}));
                manualPolygon = new mapsS.Polygon({
                    path: points,
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#FF0000",
                    fillOpacity: 0.35
                });

                const center = this.getCenter(points);
                if (center.latLng.lat > 0) mapS.panTo(center.latLng);
                manualPolygon.setMap(mapS);
                this.setState({manualPolygon});
            }
        };
    };

    getCenter = (paths) => {
        const center = paths.map(p => {
            return [p.lat, p.lng]
        }).reduce((x, y) => {
            return [x[0] + y[0] / paths.length, x[1] + y[1] / paths.length]
        }, [0, 0])
        return {latLng: {lat: center[0], lng: center[1]}};
    };

    addPolygon = () => {
        const {polygons, polygon, price, pickUpPointsList, currentPickUpPoint, returnable, pickUpDays, pickUpTimes} = this.state;

        polygons.map((i) => {
            if (i.id === polygon.id) {
                i.checked = true;
                i.price = price
            }
        });

        const pickUpPoint = Object.assign({}, currentPickUpPoint);

        pickUpPoint.pickUpDays = pickUpDays.toString();
        pickUpPoint.pickUpTimes = pickUpTimes.toString();

        const polygonComplete = {
            id: polygon.id,
            code: polygon.code,
            name: polygon.name,
            companyId: polygon.companyId,
            returnable,
            discount: 0,
            total: 0,
            pickUpPoint
        }

        console.log('POLYGON COMPLETE:::', polygonComplete)

        pickUpPointsList.push(polygonComplete);

        this.setState({
            polygons,
            polygon: {},
            price: 0,
            pickUpPointsList,
            currentPickUpPoint: {},
            showReturnable: false,
            pickUpDays: [],
            pickUpTimes: [],
            returnable: null,
            showOptions: false
        })
    }

    removePolygon = (item) => {
        const {polygons, polygon, pickUpPointsList} = this.state;

        polygons.map((i) => {
            if (i.id === item.id) {
                i.checked = false;
            }
        });

        for(let i = pickUpPointsList.length - 1; i >= 0; i--) {
            if(pickUpPointsList[i].id === item.id) {
                pickUpPointsList.splice(i, 1);
            }
        }

        this.setState({polygons, polygon: {}, pickUpPointsList})
    }

    addPolygons = () => {
        this.props.onSelectPolygons(this.state.pickUpPointsList)
    }

    onChangePrice = (value) => {
        if (value >= 0)
            this.setState({price: value})
    };

    renderRow = (item, index) => {

        // let priceNonReturnable = 0;
        // let priceReturnable = 0;
        //
        // item.pickUpPoints.map((pickUpPoint) => {
        //     priceNonReturnable += pickUpPoint.priceNonReturnable;
        //     priceReturnable += pickUpPoint.priceReturnable;
        // });

        return (
            <Tr className={'data'} key={`${index}`}>
                <Td className={'short'}>{item.code}</Td>
                <Td className={'medium'}>{item.name}</Td>
                <Money component={Td} className={'short'}>{item.returnable ? item.pickUpPoint.priceReturnable : item.pickUpPoint.priceNonReturnable}</Money>
                {/*<Money component={Td} className={'short'}>{item.pickUpPoint.priceNonReturnable}</Money>*/}
                <Td className={'short'}>{item.returnable ? 'Si' : 'No'}</Td>
                <Td className={'medium'}>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip">Remover</Tooltip>}>
                        <Button size={'sm'} variant={'outline-secondary'} onClick={()=> {this.removePolygon(item)}}>
                            <FontAwesomeIcon className={'name'} icon={faTrash}/>
                        </Button>
                    </OverlayTrigger>
                </Td>
            </Tr>
        );
    };

    getHeaders = () => {
        return [
            {label: 'Code', show: true, value: ['code'], type: 'text' ,  className:'short'},
            {label: 'Nombre', show: true, value: ['name'], type: 'text' ,  className:'medium'},
            {label: 'Precio Transporte', show: true, value: ['priceTrans'], type: 'text' ,  className:'short',
            custom:(item)=> item.priceTrans =  toMoney(item.returnable ? item.pickUpPoint.priceReturnable : item.pickUpPoint.priceNonReturnable)},
            {label: 'Retornar Cajilla', show: true, value: ['returnable'], type: 'yesOrNo' ,  className:'short'},
            {config:true, show: true, label:'', className:'mini center',
                custom: (item) => <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip">Remover</Tooltip>}>
                    <Button size={'sm'} variant={'outline-secondary'} onClick={() => { this.removePolygon(item) }}>
                        <FontAwesomeIcon className={'name'} icon={faTrash} />
                    </Button>
                </OverlayTrigger>
            }

        ]
    }

    renderRowPickUpPoint = (item, index) => {
        const {currentPickUpPoint} = this.state;

        const d = item.pickUpDays.split(',');
        d.sort();

        const pd = d.map((item, index)=> {
            return days[parseInt(item)].title
        })

        const pickUpTimesArray = item.pickUpTimes.split(',').map((item, index)=> {
            return times[parseInt(item)].title
        })

        return (
            <Tr className={`data ${item.id == currentPickUpPoint.id ? 'active' : ''}`} key={`${index}`} onClick={((e) => this.rowClicked(item))}>
                <Td className={'mini'}>{index+1}</Td>
                <Td className={'short'}>{parseFloat(item.latitude).toFixed(4)+' , '+ parseFloat(item.longitude).toFixed(4)}</Td>
                <Money component={Td} className={'short'}>{item.priceNonReturnable}</Money>
                <Money component={Td} className={'short'}>{item.priceReturnable}</Money>
                <Td className={'short'}>{pd.toString().replace(/,/g,", ")}</Td>
                <Td className={'short'}>{pickUpTimesArray.toString().replace(/,/g,", ")}</Td>
                <Td className={'short'}>{item.deliveryDays} dias</Td>
            </Tr>
        );
    };

    getHeadersPickUpPoint = ()=> {
        return[

            {label: 'No', show: true, index:true, select:true,  className:'mini'},
            {label: 'Latitud y Longitud', show: true, value: ['latC'], type: 'text' ,  className:'mini',
            custom:(item)=> item.latC =  parseFloat(item.latitude).toFixed(4)+' , '+ parseFloat(item.longitude).toFixed(4)},
            {label: 'No retornable', show: true, value: ['priceNonReturnable'], type: 'currency' ,  className:'mini'},
            {label: 'Retornable', show: true, value: ['priceReturnable'], type: 'currency' ,  className:'mini'},
            {label: 'Días de recolección', show: true, value: ['diasRecolec'], type: 'text' ,  className:'mini', 
                custom: (item) => {
                    const d = item.pickUpDays.split(',');
                    d.sort();

                    const pd = d.map((item, index) => {
                        return days[parseInt(item)].title
                    })
                    item.diasRecolec = pd.toString().replace(/,/g, ", ")
                    return (item.diasRecolec)
                }
            },
            {label: 'Horario', show: true, value: ['pickUpC'], type: 'text' ,  className:'mini',
            custom:(item)=> {
                const pickUpTimesArray = item.pickUpTimes.split(',').map((item, index)=> {
                    return times[parseInt(item)].title
                })
                item.pickUpC = pickUpTimesArray.toString().replace(/,/g,", ")
                return(
                    item.pickUpC
                )
            }},
            {label: 'Tiempo de entrega (dias)', show: true, value: ['deliveryDays'], type: 'text' ,  className:'mini'},
            {config:true, show: true, label:'', className:'mini center'}

        ]
    }

    rowClicked = (item) => {
        this.setState({currentPickUpPoint: item})
    }

    onChildClickCallback = (value, item) =>{
        this.setState({currentPickUpPoint: item.data})
    }

    onHide = () => {
        if (this.state.showReturnable){
            this.setState({showReturnable: false});
        } else {
            this.props.onHide();
        }
    }

    handlePickUpDays = (value) => {
        this.setState({pickUpDays: value})
    }

    handlePickUpTimes = (value) => {
        this.setState({pickUpTimes: value})
    }

    handleReturnable = (value) => {
        this.setState({returnable: value})
    }

    render(){
        const {polygons, price, showOptions, pickUpDays, pickUpTimes, returnable} = this.state;
        const {polygon, currentPickUpPoint, pickUpPointsList} = this.state;

        const {loadingP} = this.props;

        const pickUpPoints = polygon.pickUpPoints ? polygon.pickUpPoints : [];

        const latitude = 14.589465440976774;
        const longitude = -90.51898866891861;


        let d = [];
        if (currentPickUpPoint.pickUpDays){
            d = currentPickUpPoint.pickUpDays.split(',');
            d.sort();
        }

        let t = [];
        if (currentPickUpPoint.pickUpTimes){
            t = currentPickUpPoint.pickUpTimes.split(',');
        }

        return (
            <div>

            <Modal className={'sub-style'} dialogClassName="my-modal-subscription-products" centered show={this.props.show} onHide={this.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Selección de polígonos</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col xl={4} lg={6}>
                            <CollapsibleCard className={'sub-style'} style={{marginTop: 32, minHeight: 500}} header={{backgroundColor: '#1b4060'}} title={'Seleccione polígono'}>

                            <div className={'column'}>
                                <Row>

                                    <Col>
                                        <Select
                                            loading={loadingP}
                                            label={'Poligonos'}
                                            options={polygons.filter((d) => !d.checked)}
                                            value={polygon}
                                            onChange={(polygon) => {

                                                if (polygon.pickUpPoints.length === 1){
                                                    this.setState({currentPickUpPoint: polygon.pickUpPoints[0]})
                                                }

                                                this.setState({polygon})
                                                this.setPolygon(polygon);
                                            }}
                                            required
                                        />
                                    </Col>
                                    {/*<Col style={{alignSelf: 'flex-end'}}>*/}
                                    {/*    <Button variant={'info'} block*/}
                                    {/*        onClick={() => this.addPolygon()}>*/}
                                    {/*        Añadir*/}
                                    {/*    </Button>*/}
                                    {/*</Col>*/}
                                </Row>



                                {/*<MapCard*/}
                                {/*    title={polygon ? polygon.label : ''}*/}
                                {/*    header={{backgroundColor: '#1b4060'}}*/}
                                {/*    latitude={latitude}*/}
                                {/*    longitude={longitude}*/}
                                {/*    height={500}*/}
                                {/*    startCollapsed={false}*/}
                                {/*    markerList={[]}*/}
                                {/*    yesIWantToUseGoogleMapApiInternals*/}
                                {/*    onGoogleApiLoaded={({ map, maps }) => this.onGoogleApiLoaded(map, maps)}*/}
                                {/*/>*/}

                                <div style={{width:'100%', height:500}}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{key: MAPS_API_KEY}}
                                        defaultCenter={{lat: latitude, lng: longitude}}
                                        defaultZoom={14}
                                        // onClick={typeMap == 1 ? this.addPointToPolygon : this.detailPickUpPoint}
                                        yesIWantToUseGoogleMapApiInternals
                                        onChildClick={(value,item) => this.onChildClickCallback(value,item)}
                                        onGoogleApiLoaded={({ map, maps }) => this.onGoogleApiLoaded(map, maps)}
                                    >
                                        {pickUpPoints.length > 0 &&
                                        pickUpPoints.map((item, index) => (
                                            item.id === currentPickUpPoint.id ?
                                                    <div key={`${index}`}
                                                         data={item}
                                                         lat={Number(item.latitude)}
                                                         lng={Number(item.longitude)}
                                                         style={{position: 'absolute', left: -30, top: -60}}>
                                                        <MarkerActive/>
                                                    </div>
                                                    :
                                                    <div key={`${index}`}
                                                         data={item}
                                                         lat={Number(item.latitude)}
                                                         lng={Number(item.longitude)}
                                                         style={{position: 'absolute', left: -20, top: -40}}>
                                                        <Marker/>
                                                    </div>


                                        ))
                                        }
                                    </GoogleMapReact>
                                </div>

                                {/*<Row className={'container-buttons'}>*/}
                                {/*    <InputGroup style={{width: 240, marginTop: 4}}>*/}
                                {/*        <InputGroup.Prepend>*/}
                                {/*            <InputGroup.Text>Oferta</InputGroup.Text>*/}
                                {/*        </InputGroup.Prepend>*/}
                                {/*        <FormControl*/}
                                {/*            aria-label="Default"*/}
                                {/*            style={{textAlign: 'center'}}*/}
                                {/*            type="number"*/}
                                {/*            onFocus={(event)=>event.target.select()}*/}
                                {/*            value={price}*/}
                                {/*            step={0.5}*/}
                                {/*            aria-describedby="inputGroup-sizing-default"*/}
                                {/*            onChange={({target}) => this.onChangePrice(target.value)}*/}
                                {/*        />*/}
                                {/*    </InputGroup>*/}
                                {/*    <Button style={{marginLeft: 10}} variant={'outline-info'}*/}
                                {/*        onClick={() => this.addPolygon()}>*/}
                                {/*        Añadir*/}
                                {/*    </Button>*/}
                                {/*</Row>*/}

                                {/*<Row>*/}
                                {/*    <CollapsibleCard className={'sub-style'} header={{backgroundColor: '#1b4060'}} title={'Puntos de recolección'}>*/}
                                {/*        <Table*/}
                                {/*            headers={['No', 'Latitud y Longitud', 'Retornable', 'No retornable', 'Días de recolección', 'Horario']}*/}
                                {/*            items={pickUpPoints}*/}
                                {/*            renderRow={this.renderRowPickUpPoint}/>*/}
                                {/*    </CollapsibleCard>*/}
                                {/*</Row>*/}


                                <Alert {...this.state.alert}/>
                            </div>
                            </CollapsibleCard>
                        </Col>

                        <Col xl={4} lg={6}>
                            <CollapsibleCard className={'sub-style'} style={{marginTop: 32, minHeight: 652}} header={{backgroundColor: '#1b4060'}} title={'Seleccione Puntos de recolección'}>
                                
                                <TableV2
                                    mobileAuto
                                    storageKey={``}
                                    items={pickUpPoints}
                                    headers={this.getHeadersPickUpPoint()}
                                    isSelected={(item)=> item.id == currentPickUpPoint.id}
                                    onClickTr ={(e,item)=> {this.rowClicked(item)}}
                                    getItems={(item)=> {
                                        if(item == null){
                                            this.rowClicked({})
                                        }else{
                                            this.rowClicked(item)
                                        }
                                    }}
                                />
                                    <Button disabled={!currentPickUpPoint.id} variant={'info'} block
                                        // onClick={() => this.addPolygon()}
                                            onClick={() => this.setState({showOptions: true})}
                                    >
                                        Añadir
                                    </Button>
                            </CollapsibleCard>
                            {/*<Table*/}
                            {/*    headers={['Code', 'Nombre', 'Precio', 'Acciones']}*/}
                            {/*    items={polygons.filter((d) => d.checked)}*/}
                            {/*    renderRow={this.renderRow}*/}
                            {/*/>*/}
                        </Col>

                        <Col xl={4} lg={6}>
                            <CollapsibleCard className={'sub-style'} style={{marginTop: 32, minHeight: 652}} header={{backgroundColor: '#1b4060'}} title={'Poligonos para la suscripción'}>
                                <TableV2
                                    headers= {this.getHeaders()}
                                    mobileAuto
                                    storageKey={`modalSelectPolygons`}
                                    items={pickUpPointsList}
                                />
                            </CollapsibleCard>
                        </Col>
                    </Row>

                    {/*<Row>*/}
                    {/*    <Col>*/}
                    {/*        <CollapsibleCard className={'sub-style'} style={{marginTop: 10}} header={{backgroundColor: '#1b4060'}} title={'Poligonos para la suscrición'}>*/}
                    {/*            <Table*/}
                    {/*                    headers={['Code', 'Nombre', 'Precio Cajilla Retornable', 'Precio Cajilla No retornable', 'Acciones']}*/}
                    {/*                    // items={polygons.filter((d) => d.checked)}*/}
                    {/*                    items={pickUpPointsList}*/}
                    {/*                    renderRow={this.renderRow}*/}
                    {/*                />*/}
                    {/*        </CollapsibleCard>*/}
                    {/*    </Col>*/}

                    {/*</Row>*/}

                    <Row className={'container-buttons'}>
                        <Button variant={'danger'} onClick={()=>this.addPolygons()}>Agregar polygonos a la suscripción</Button>
                    </Row>

                </Modal.Body>

                <Alert
                    show={this.state.showReturnable}
                    title="Información"
                    text={"¿Retornar al punto de recolección?"}
                    type={"warning"}
                    showCancelButton
                    reverseButtons
                    confirmButtonText={currentPickUpPoint.priceReturnable ? `Si ${toMoney(currentPickUpPoint.priceReturnable)}` : 'Si'}
                    cancelButtonText={currentPickUpPoint.priceNonReturnable ? `No ${toMoney(currentPickUpPoint.priceNonReturnable)}`: 'No'}
                    onConfirm={() => {
                        this.addPolygon(true);
                    }}
                    onCancel={() => {
                        this.addPolygon(false);
                    }}
                />

            </Modal>

                <Modal className={'sub-style'} show={showOptions} onHide={()=>this.setState({showOptions: false})} size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Seleccione los detalles</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row style={{justifyContent: 'flex-start', marginLeft: 16, marginRight: 16}}>
                            <Col>
                                <div>
                                    <h6>Tipo:</h6>
                                    <ToggleButtonGroup type="radio" name="options" value={returnable} onChange={this.handleReturnable}>
                                        <ToggleButton className="Btn-active" variant={'secondary'} value={0}>
                                            <div style={{width: 120}}>
                                                <>No Retornable</>
                                                <p/>
                                                <>{toMoney(currentPickUpPoint.priceNonReturnable ? currentPickUpPoint.priceNonReturnable : 0)}</>
                                            </div>
                                        </ToggleButton>
                                        <ToggleButton className="Btn-active" variant={'secondary'} value={1}>
                                            <div style={{width: 120}}>
                                                <>Retornable</>
                                                <p/>
                                                <>{toMoney(currentPickUpPoint.priceReturnable ? currentPickUpPoint.priceReturnable : 0)}</>
                                            </div>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                            </Col>
                        </Row>
                        <br/>
                        <Row style={{justifyContent: 'flex-start', marginLeft: 16, marginRight: 16}}>
                            <Col>
                                <div>
                                    <h6>Días de recolección</h6>
                                    <ToggleButtonGroup type="checkbox" value={pickUpDays} onChange={this.handlePickUpDays}>
                                        {
                                            d.map((item, index) => (
                                                <ToggleButton className="Btn-active" variant={'secondary'} value={item}>{days[parseInt(item)].title}</ToggleButton>
                                            ))
                                        }
                                    </ToggleButtonGroup>
                                </div>
                            </Col>
                        </Row>
                        <br/>
                        <Row style={{justifyContent: 'flex-start', marginLeft: 16, marginRight: 16}}>
                            <Col>
                                <div>
                                    <h6>Horarios de recolección</h6>
                                    <ToggleButtonGroup type="checkbox" value={pickUpTimes} onChange={this.handlePickUpTimes}>
                                        {
                                            t.map((item, index) => (
                                                <ToggleButton className="Btn-active" variant={'secondary'} value={item}>{times[parseInt(item)].title}</ToggleButton>
                                            ))
                                        }
                                    </ToggleButtonGroup>
                                </div>
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>this.setState({showOptions: false})}>
                            Cancelar
                        </Button>
                        <Button variant="danger" onClick={()=>this.addPolygon()}>
                            Añadir
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>


        );
    }
}

const mapStateToProps = (state) => ({
    subscriptionsPolygons: selectSubscriptionsPolygons(state),
    loadingP: loadingSelector([actionTypes.GET_SUBSCRIPTIONS_POLYGONS])(state),
});
const mapDispatchToProps = dispatch => ({
    getSubscriptionsPolygons: () => dispatch(getSubscriptionsPolygons())
});

export default connect(mapStateToProps, mapDispatchToProps) (ModalSelectPolygons);
