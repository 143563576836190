import { actions } from 'src/actions/alert.actions'
import { successState } from 'src/actions/global.actions'

const initialState: IAlert = {
  show: false,
  title: 'default',
  text: 'default',
  type: 'info',
  onConfirm: null,
  onCancel: null,
  showCancelButton: false,
  confirmButtonText: 'Continuar',
  cancelButtonText: 'Cancelar',
  confirmButtonColor: '#226095',
}

const AlertReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case successState(actions.SHOW_ALERT):
      return {
        show: true,
        ...payload,
      }
    case successState(actions.HIDE_ALERT):
      return initialState
    default:
      return state
  }
}
export default AlertReducer
