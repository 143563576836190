import { actionTypes as companyActionTypes } from 'src/actions/company.actions'
import { actionTypes } from 'src/actions/currencies.actions'
import { extract, successState } from 'src/actions/global.actions'

const initialState = {
  allCurrencies: [],
  currentCurrency: {},
  countries: [],
  economicActivities: [],
  fiscalPrecinct: [],
  regimen: [],
  incoterm: [],
  exportationCountries: [],
}

const CurrenciesReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_COUNTRIES):
      return { ...state, countries: extract(action.countries, []) }
    case successState(companyActionTypes.GET_COMPANY_DATA):
      return {
        ...state,
        currentCurrency: extract(action.payload.currency, {}),
      }
    case successState(actionTypes.GET_CURRENCIES):
      return {
        ...state,
        allCurrencies: extract(action.currencies, []),
      }
    case successState(actionTypes.GET_CURRENT_CURRENCY):
      return {
        ...state,
        currentCurrency: extract(action.currency, {}),
      }
    case successState(actionTypes.SET_CURRENT_CURRENCY):
      return {
        ...state,
        currentCurrency: extract(action.currency, {}),
      }
    case successState(actionTypes.GET_ECONOMIC_ACTIVITY):
      return {
        ...state,
        economicActivities: extract(action.economicActivity, []),
      }
    case successState(actionTypes.GET_FISCAL_PRECINCT):
      return {
        ...state,
        fiscalPrecinct: extract(action.fiscalPrecinct, []),
      }
    case successState(actionTypes.GET_REGIMEN):
      return {
        ...state,
        regimen: extract(action.regimen, []),
      }
    case successState(actionTypes.GET_INCOTERM):
      return {
        ...state,
        incoterm: extract(action.incoterm, []),
      }
    case successState(actionTypes.GET_EXPORTATION_COUNTRIES):
      return {
        ...state,
        exportationCountries: extract(action.exportationCountries, []),
      }
    default:
      return state ? state : initialState
  }
}

export default CurrenciesReducer
