import React, {Component} from 'react';
import {connect} from "react-redux"

import Button from "../../../../components/buttons/Button";
import Card from "../../../../components/cards/Card";
import IconButton from "../../../../components/buttons/IconButton";
import CollapsibleCard from "../../../../components/cards/collapsible-card/CollapsibleCard";
import MapCard from "../../../../components/maps/MapCard";

import {formatDateFromMillis, formatNumberWithCommas} from "../../../../utils/formatters";
import {TooltipRail, Handle, Track} from "../../../../components/slider/SliderComponents";

import {Col, Row, Modal, Spinner} from "react-bootstrap";
import {Slider, Rail, Handles, Tracks} from "react-compound-slider";

import {
    actionTypes as types,
    getSingleOffer,
    acceptOffer
} from "../../../../actions/subscriptions.actions";
import {selectOffer} from "../../../../selectors/offers.selector";

import {loadingSelector} from "../../../../selectors/loading.selector";

import {handlerError, hasErrorsSelector, singleErrorSelector} from "../../../../selectors/error.selector";

import {faFileAlt} from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert-react";
import FormTextField
    from "../../../../components/inputs/FormTextField/FormTextField";
import SubscriptionsOffer from "../SubscriptionsOffer";

import {getSubscriptionPolygon, assignShipper} from "../../../../actions/subscriptions.actions"
import {selectSubscriptionPolygon} from "../../../../selectors/subscriptions.selector";

import "../SubscriptionsOffer.scss"
import {selectSetCurrentCompany} from "../../../../selectors/company.selector";
import GeocodingPicker from "../../../Production/Subscriptions/SubscriptionCreate/GeocodingPicker/GeocodingPicker";
import Money from '../../../../components/Money/Money';
import { TableV2 } from '../../../../components';


const sliderStyle = {
    position: 'relative',
    width: '100%',
}

const latitude = 14.589465440976774;
const longitude = -90.51898866891861;

const intervals = [
    {value: 0, label: 'Diariamente', singular: 'Día', plural: 'días'},
    {value: 1, label: 'Semanalmente', singular: 'Semana', plural: 'semanas'},
    {value: 2, label: 'Mensualmente', singular: 'Mes', plural: 'meses'},
    {value: 3, label: 'Anualmente', singular: 'Año', plural: 'años'},
]

class SubscriptionOfferDetail extends Component {

    state = {
        productDetails: {
            show: false,
            productPriceDetail: []
        },
        alert: {
            title: 'default',
            text: 'default',
            type: 'info',
            show: false,
            onConfirm: () => console.log('default')
        },
        domain: [1, 2],
        values: [0],
        update: [0],
        disabled: false,
        price: {minimum: 0, maximum: 0, price: 0},
        polygonCoords: [],

        mapS: null,
        mapsS: null,
        manualPolygon: null,

        showAssignSuccess: false,
        showConfirmation: false
    };

    componentDidMount() {
        const {polygonId} = this.props.history.location.state;
        this.props.getSubscriptionPolygon(polygonId);
    };

    componentWillReceiveProps(next) {
        const {loading, loadingAS, hasErrorAS, handlerError} = this.props;
        const {subscriptionPolygon, errorAS} = next;

        if (loading && !next.loading) {
            if (subscriptionPolygon.polygon)
            this.setPolygon(subscriptionPolygon.polygon)
        }

        if (loadingAS && !next.loadingAS){
            if (!hasErrorAS && next.hasErrorAS) {
                alert = handlerError(errorAS.message || 'Ha ocurrido un error inesperado.');
            } else {
                this.setState({showAssignSuccess: true});
            }
        }

    };

    

    onGoogleApiLoaded = (map, maps) => {
        setTimeout(() => {
            const {subscriptionPolygon} = this.props;
            this.setState({mapS: map, mapsS: maps});
            if (subscriptionPolygon.polygon && subscriptionPolygon.polygon.id) {
                this.setPolygon(subscriptionPolygon.polygon);
            }
        }, 1000);
    };

    setPolygon = (polygon) => {
        let {mapS, mapsS, manualPolygon} = this.state;
        if (manualPolygon) manualPolygon.setMap(null);
        if (mapS, mapsS) {
            polygon.pointList.sort(function(a, b){
                if(a.index < b.index) return -1;
                if(a.index > b.index) return 1;
                return 0;
            });
            const points = polygon.pointList.map((p) => ({lat: Number(p.latitude), lng: Number(p.longitude)}));

            manualPolygon = new mapsS.Polygon({
                path: points,
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35
            });

            const center = this.getCenter(points);
            if (center.latLng.lat > 0) mapS.panTo(center.latLng);
            manualPolygon.setMap(mapS);
            this.setState({manualPolygon});
        }
    };

    getCenter = (paths) => {
        const center = paths.map(p => {
            return [p.lat, p.lng]
        }).reduce((x, y) => {
            return [x[0] + y[0] / paths.length, x[1] + y[1] / paths.length]
        }, [0, 0])
        return {latLng: {lat: center[0], lng: center[1]}};
    };

    acceptSubscription = () => {
        this.props.assignShipper(this.props.subscriptionPolygon.id)

        this.setState({
            showConfirmation: false
        })
    }

    getData = (data) => {

    };

    render() {
        const {subscriptionPolygon, loading, loadingAS, currentCompany} = this.props;
        const {productDetails, alert, domain, values, disabled, price, polygonCoords, showAssignSuccess} = this.state;

        console.log('SUBSCRIPTION POLIGON::::::', subscriptionPolygon)

        const {subscription} = subscriptionPolygon;
        const {polygon} = subscriptionPolygon;

        return (
            <div style={{marginBottom: 50}}>
                <Row>
                    <Col md={4}>
                        <Row>
                            <Col md={12}>
                                {/*<Card title={'Detalles'}>*/}
                                    <div className={'offer-subscription-card sub-style'}>
                                        <div style={{height: 100, backgroundColor: '#1b4060', borderTopLeftRadius: '5px', borderTopRightRadius: '5px', textAlign: 'center', paddingTop: 10}}>
                                            <h1 style={{color: 'white'}}>{subscription ? subscription.title : 'Suscripción'}</h1>
                                            <h6 style={{color: 'white'}}>{subscription ? subscription.description : '-'}</h6>
                                        </div>

                                        <Row style={{margin: 10}}>
                                            <Col>
                                                <h2>Entregas cada</h2>
                                                <h6>{subscription ? (subscription.step > 1 ? subscription.step + ' ' + intervals[subscription.intervalType].plural : intervals[subscription.intervalType].singular ) : '-'}</h6>
                                                <br/>
                                                <h2>Cantidad de entregas</h2>
                                                <h6>{subscription ? (subscription.times + (subscription.times > 1 ? ' veces':' vez')) : '-'}</h6>
                                                <br/>
                                                <h2>Pago por entrega</h2>
                                                <Money component='h6'>{parseInt(subscriptionPolygon.price)}</Money>
                                                <br/>
                                                <h2>Peso aproximado</h2>
                                                <h6>{subscription ? subscription.weight + ' lbs' : 'No definido'}</h6>
                                                <br/>
                                                <h2>Cantidad de Cajillas</h2>
                                                <h6>{subscription ? subscription.drawers : 'No definido'}</h6>
                                                {/*<br/>*/}
                                                {/*<h2>Dimensiones</h2>*/}
                                                {/*<p><i>(Alto x Ancho x Largo)</i></p>*/}
                                                {/*<h6>{subscription ? subscription.volume.replace(/,/g,"cms x ") + "cms" : 'No especificado'}</h6>*/}
                                            </Col>
                                        </Row>

                                        <Row style={{position: 'absolute', bottom:37, right: 30}}>
                                            {!loading &&
                                            <Button disabled={loadingAS || currentCompany.id == subscriptionPolygon.shipperId} style={{alignContent: 'center'}} variant="danger" onClick={() => this.setState({showConfirmation: true}) }>
                                                {loadingAS &&
                                                <Spinner
                                                    style={{marginRight: 10, marginTop: 4}}
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />}
                                                {currentCompany.id == subscriptionPolygon.shipperId ? 'Aceptado' : 'Aceptar suscripción'}
                                            </Button>
                                            }
                                        </Row>

                                    </div>
                                {/*</Card>*/}
                            </Col>

                        </Row>

                        {
                            subscriptionPolygon && subscriptionPolygon.latitude &&
                            <Row>
                                <Col className={'sub-style'}>
                                    <GeocodingPicker
                                        lat={subscriptionPolygon.latitude}
                                        lng={subscriptionPolygon.longitude}
                                        auto={true}
                                        getData={this.getData}
                                    />
                                </Col>
                            </Row>
                        }

                    </Col>
                    <Col>
                        <Row>

                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>

                                <MapCard
                                    title={polygon ? polygon.name : 'Vista previa'}
                                    header={{backgroundColor: '#1b4060'}}
                                    latitude={latitude}
                                    longitude={longitude}
                                    height={521}
                                    startCollapsed={false}
                                    markerList={[]}
                                    yesIWantToUseGoogleMapApiInternals
                                    onGoogleApiLoaded={({ map, maps }) => this.onGoogleApiLoaded(map, maps)}
                                />

                            </Col>

                            <Col md={12}>
                                <CollapsibleCard className={'sub-style'} header={{backgroundColor: '#1b4060'}} title={'Productos en la suscripciòn'}>
                                    <TableV2
                                        headers={[
                                            { label: 'Cantidad', show: true, value: ['quantity'], type: 'wholeNumber', className: 'mini' },
                                            { label: 'Nombre', show: true, value: ['productData','name'], type: 'text', className: 'mini' },
                                            { config: true, show: true, label: '', className: 'mini center' }
                                        ]}
                                        items={subscription ? subscription.subscriptionDetail : []}
                                        loading={loading}
                                        mobileAuto
                                        storageKey={`subcriptionsOfferDetail`}
                                    />
                                   
                                </CollapsibleCard>
                            </Col>

                        </Row>

                    </Col>
                </Row>


                <SweetAlert
                    {...alert}
                />

                <SweetAlert
                    show={this.state.showConfirmation}
                    title="Información"
                    text={this.props.subscriptionPolygon.subscription ? "¿Desea aceptar la entrega de la suscripción " + this.props.subscriptionPolygon.subscription.title + "?" : ""}
                    type={"warning"}
                    showCancelButton
                    confirmButtonText="Aceptar"
                    cancelButtonText="Cancelar"
                    onConfirm={() => {
                        this.acceptSubscription()
                    }}
                    onCancel={() => {
                        this.setState({showConfirmation: false});
                    }}
                />

                <SweetAlert
                    show={showAssignSuccess}
                    title={"!Exito!"}
                    text={this.props.subscriptionPolygon.subscription ? "Has aceptado la suscripción " + this.props.subscriptionPolygon.subscription.title + " exitosamente." : ""}
                    type={"success"}
                    onConfirm={() =>
                        this.setState({showAssignSuccess: false}, ()=> window.history.back())}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    // offer: selectOffer(state),
    // loading: loadingSelector([types.GET_SINGLE_OFFER])(state),
    // loadingAccept: loadingSelector([types.ON_ACCEPT_OFFER])(state),
    // hasErrorAccept: hasErrorsSelector([types.ON_ACCEPT_OFFER])(state),
    // errorAccept: singleErrorSelector([types.ON_ACCEPT_OFFER])(state),

    currentCompany: selectSetCurrentCompany(state),

    subscriptionPolygon: selectSubscriptionPolygon(state),
    loading: loadingSelector([types.GET_SUBSCRIPTION_POLYGON])(state),

    loadingAS: loadingSelector([types.ASSIGN_SHIPPER])(state),
    hasErrorAS: hasErrorsSelector([types.ASSIGN_SHIPPER])(state),
    errorAS: singleErrorSelector([types.ASSIGN_SHIPPER])(state),

    handlerError: (message) => handlerError(message)
});
const mapDispatchToProps = dispatch => ({
    // getOffer: (id) => dispatch(getSingleOffer(id)),
    // accept: (id, loadEstimateAt) => dispatch(acceptOffer(id, loadEstimateAt)),
    getSubscriptionPolygon: (id) => dispatch(getSubscriptionPolygon(id)),
    assignShipper: (id) => dispatch(assignShipper(id))
});
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionOfferDetail);
