export const selectAllRegions = state => state.address.allRegions
export const selectSingleRegion = state => state.address.singleRegion
export const selectRegionLocalities = state => state.address.regionLocalities
export const selectAllLocalities = state => state.address.allLocalities
export const selectSingleLocality = state => state.address.singleLocality

export const selectAllRegionsForSelect = state =>
  state.address.allRegions?.map(region => ({
    value: region.id,
    label: region.name,
  }))
export const selectAllLocalitiesForSelect = state =>
  state.address.allLocalities.map(locality => ({
    ...locality,
    value: locality.id,
    label: locality.name,
  }))
export const selectRegionLocalitiesForSelect = state =>
  state.address.regionLocalities.map(locality => ({
    ...locality,
    value: locality.id,
    label: locality.name,
  }))
