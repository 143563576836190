import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Modal, Row, Col, ModalTitle, ModalBody } from 'react-bootstrap'
import {
  faCheck,
  faCheckDouble,
  faEdit,
  faFileInvoice,
  faGift,
  faPhone,
  faPrint,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import Alert from 'sweetalert-react'

import {
  FormText,
  Icon,
  Button,
  CustomTabs,
  ProductItems,
  ProductItem,
  Checkbox,
  TableV2,
  Paragraph,
} from '../../index'

import {
  actionTypes as typeO,
  confirmVisitNoSale,
  deleteSingleOrder,
  payWithVoucher,
  onPrintInvoice,
} from 'src/actions/orders.actions'

import {
  types,
  onSetModalOrder,
  getModalOrder,
  onRefresh,
  setModalConfirm,
} from 'src/actions/utilities.actions'
import {
  selectModalOrderId,
  selectModalOrderCurrency,
  selectModalOrder,
  selectModalOrderPreSale,
  selectModalConfirm,
} from 'src/selectors/utilities.selector'

import { selectCurrentModule } from 'src/selectors/user.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  hasErrorsSelector,
  singleErrorSelector,
  handlerError,
  handlerSuccess,
} from 'src/selectors/error.selector'

import { isAllowed } from 'src/selectors/modules.selector'

import {
  formatDateFromMillis,
  formatNumberWithCommas,
  formatUtcDateFromMillis,
} from 'src/utils/formatters'

import {
  selectCompanyCountry,
  selectCompanyFieldById,
  selectCompanyFieldValue,
  selectSetCurrentCompany,
} from 'src/selectors/company.selector'

import ConfirmOrder from './ModalOrderConfirm'
import ModalOrderProcess from './ModalOrderProcess'
import ModalOrderTransfer from './ModalOrderTransfer'
import { history } from '../../../App'
import { printGiftTicket } from '../../../utils/utilities'
import { faReceipt } from '@fortawesome/free-solid-svg-icons/faReceipt'
import ModalHeader from 'react-bootstrap/ModalHeader'
import { selectVouchers } from '../../../selectors/clients.selector'
import {
  actionTypes as type1,
  getVouchersByCustomers,
} from '../../../actions/clients.actions'
import Card from '../../cards/Card'
import { selectCurrentCurrency } from '../../../selectors/currencies.selector'
import { toMoney } from '../../../utils/utilities'
import { useLocation } from 'react-router-dom'
import { ListVariations } from 'src/content/Production/Categorization/Variation/ListVariations'
import ModalOrderPayments from 'src/components/modal/Orders/ModalOrderPayments'
import { orderDispatchStatus } from 'src/enums/orderDispatchStatus'
import { ModalOrderAdjust } from 'src/components/modal/Orders/ModalOrderAdjust'
import { Country, getTaxLabel } from 'src/enums/countries'
import felDocumentTypes from 'src/enums/felDocumentTypes'
import companyFields from 'src/enums/companyFields'
import { onSetPrintData } from 'src/actions/restaurant.actions'
import { orderTicket } from 'src/components/Printer/TicketEpson'
import { selectPrintInvoice } from 'src/selectors/orders.selector'
import {
  actionTypes as actionPDF,
  downloadPrintableInvoiceByOrder,
} from 'src/actions/resolutions.actions'
import { showAlert } from 'src/actions/alert.actions'
import { orderStatusEnum } from 'src/enums/order'
import { haveAnyValue, isSafariNavigator } from '../../../utils/utilitiesV2'
import taxes from 'src/content/Taxes/Taxes'

const ModalOrders = () => {
  const dispatch = useDispatch()

  const vouchers = useSelector(selectVouchers)
  const orderId = useSelector(selectModalOrderId)
  const presale = useSelector(selectModalOrderPreSale)
  const module = useSelector(selectCurrentModule)
  const company = useSelector(selectSetCurrentCompany)
  const prints = useSelector(state => state.printers.all)
  const printInvoice = useSelector(selectPrintInvoice)
  const country = useSelector(selectCompanyCountry)
  const currency = useSelector(selectModalOrderCurrency)
  const currentCurrency = useSelector(selectCurrentCurrency)
  const symbol = currency || currentCurrency.symbol

  const order = useSelector(selectModalOrder)
  const modalConfirm = useSelector(selectModalConfirm)
  const loading = useSelector(state => loadingSelector([types.GET_MODAL_ORDER])(state))
  const hasError = useSelector(state => hasErrorsSelector([types.GET_MODAL_ORDER])(state))
  const error = useSelector(state => singleErrorSelector([types.GET_MODAL_ORDER])(state))

  const cardSurcharge = useSelector(state =>
    selectCompanyFieldValue(state, companyFields.cardSurcharge),
  )

  const loadingD = useSelector(state =>
    loadingSelector([typeO.DELETE_SINGLE_ORDER])(state),
  )
  const hasErrorD = useSelector(state =>
    hasErrorsSelector([typeO.DELETE_SINGLE_ORDER])(state),
  )
  const errorD = useSelector(state =>
    singleErrorSelector([typeO.DELETE_SINGLE_ORDER])(state),
  )

  const loadingVisit = useSelector(state =>
    loadingSelector([typeO.CONFIRM_VISIT_ORDER_NO_SALE])(state),
  )
  const hasErrorVisit = useSelector(state =>
    hasErrorsSelector([typeO.CONFIRM_VISIT_ORDER_NO_SALE])(state),
  )
  const errorVisit = useSelector(state =>
    singleErrorSelector([typeO.CONFIRM_VISIT_ORDER_NO_SALE])(state),
  )

  const loadingVouchers = useSelector(state =>
    loadingSelector([type1.GET_VOUCHERS_CLIENT])(state),
  )

  const loadingPay = useSelector(state =>
    loadingSelector([typeO.PAY_WITH_VOUCHER])(state),
  )
  const hasErrorPay = useSelector(state =>
    hasErrorsSelector([typeO.PAY_WITH_VOUCHER])(state),
  )

  const loadingPDF = useSelector(state =>
    loadingSelector([actionPDF.DOWNLOAD_PRINTABLE_INVOICE_BY_ORDER])(state),
  )

  const taxLabel = getTaxLabel(country?.id)

  /*Permissions*/

  const confirm = useSelector(state => isAllowed(state, [1104, 2806, 11654]))
  const confirmC = useSelector(state => isAllowed(state, [6502, 6452]))
  const del = useSelector(state => isAllowed(state, [2805, 1106, 11656]))
  const cSeeBalances = useSelector(state => isAllowed(state, [16203, 16253]))
  const tSeeBalances = useSelector(state => isAllowed(state, [1758, 2708]))
  const clSeeMoneyBalances = useSelector(state => isAllowed(state, [1067, 2167]))
  const canAdjustVisit = useSelector(state => isAllowed(state, [1612]))

  /*Permissions visits*/
  const canConfirmVisitWithoutSell = useSelector(state => isAllowed(state, [1604]))
  const canConfirmVisit = useSelector(state => isAllowed(state, [1609, 1610]))

  const [fromCard] = useState(useLocation().pathname.includes('/cobros'))
  const [fromTransfer] = useState(useLocation().pathname.includes('/transferencias'))
  const [fromClients] = useState(useLocation().pathname.includes('/clientes'))

  const [actions, setActions] = useState({ get: false, delete: false, visit: false })
  const [alert, setAlert] = useState({ title: '' })
  const [copied, setCopied] = useState(false)
  const [show, setShow] = useState(false)
  const [modalVoucher, setVoucher] = useState({ show: false, client: null })
  const [choosen, setChoosen] = useState({ id: null })
  const [all, setAll] = useState(false)
  const [selected, setSelected] = useState([])
  const [adjust, setAdjust] = useState({ edit: false })

  const [nameLocations, setNameLocations] = useState(['Pasillo', 'Estante', 'Nivel'])
  const locationCompany = useSelector(state => selectCompanyFieldById(state, 123))
  const seeWarehouseLocations = useSelector(state => selectCompanyFieldById(state, 125))
  const [locationQuantity, setLocationQuantity] = useState({ value: 3 })

  const [showAlertCancel, setShowAlertCancel] = useState({ show: false, text: '' })
  const [modalOptions, setModalOptions] = useState({ show: false })
  const [totalOrder, setOrderTotal] = useState(0)

  useEffect(() => {
    if (JSON.stringify(locationCompany) !== '{}') {
      const locationsN = locationCompany.value.split(',')
      setNameLocations(locationsN)
      setLocationQuantity({
        value: locationsN.length,
      })
    }
  }, [locationCompany])

  useEffect(() => {
    if (orderId && !show) dispatch(getModalOrder(orderId))
  }, [orderId])

  useEffect(() => {
    if (loading) setActions({ ...actions, get: true })
    else if (actions.get) {
      setActions({ ...actions, get: false })
      if (hasError)
        setAlert({ ...handlerError(error.message), onConfirm: () => onHide() })
    }
  }, [loading])

  useEffect(() => {
    if (Object.keys(order).length !== 0) {
      if (!presale) setShow(true)
      else dispatch(setModalConfirm({ ...order, show: true, presale: true }))
    }
  }, [order])

  useEffect(() => {
    if (loadingVisit) setActions({ ...actions, visit: true })
    else if (actions.visit) {
      setActions({ ...actions, visit: false })
      if (hasErrorVisit)
        setAlert({
          ...handlerError(errorVisit.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Visita marcada como completada'),
          onConfirm: () => onHideSuccess(false),
        })
    }
  }, [loadingVisit])

  useEffect(() => {
    if (loadingD && show) setActions({ ...actions, delete: true })
    else if (actions.delete) {
      setActions({ ...actions, delete: false })
      if (hasErrorD)
        setAlert({
          ...handlerError(errorD.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Orden eliminada satisfactoriamente'),
          onConfirm: () => onHideSuccess(false),
        })
    }
  }, [loadingD])

  useEffect(() => {
    if (loadingPay) setActions({ ...actions, use: true })
    else if (actions.use) {
      setActions({ ...actions, use: false })
      if (hasErrorPay)
        setAlert({
          ...handlerError('No se pudo utilizar el vale'),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Vale utilizado correctamente'),
          onConfirm: () => {
            onHideSuccess(false)
            setChoosen({ id: null })
            setVoucher({ modalVoucher: { show: false, client: null, order: null } })
            onHide()
          },
        })
    }
  }, [loadingPay])

  useEffect(() => {
    if (!printInvoice.active) return

    const { orderId, defaultName, module, documentType, isCreditNote, print } =
      printInvoice

    printInvoices(orderId, defaultName, module, documentType, isCreditNote, !print)

    dispatch(
      onPrintInvoice({
        orderId: null,
        active: false,
        print: false,
        defaultName: '',
        module: 1000,
        documentType: null,
        isCreditNote: false,
      }),
    )
  }, [printInvoice])

  const onHide = () => {
    setShow(false)
    setModalOptions({ show: false })
    setSelected([])
    dispatch(onSetModalOrder(null))
    setAlert({ ...alert, show: false })
  }

  const onHideSuccess = invoice => {
    dispatch(setModalConfirm({ show: false }))
    onHide()
    dispatch(onRefresh(true))
    if (invoice) printInvoices(orderId, null, null, 1, false, true)
  }

  const validate = () => {
    var valor = parseFloat(modalVoucher.amount)
    if (valor > modalVoucher.order.total) return false

    // LA CANTIDAD A UTILIZAR ES MENOR A LA DEL VALE
    if (valor < choosen.amount) {
      // SI ESTAN SELECCIONADAS LAS OPCIONES
      return !!(
        modalVoucher.returnCash ||
        modalVoucher.returnBank ||
        modalVoucher.returnVoucher
      )
    } else if (valor === choosen.amount) {
      return true
    } else if (valor > choosen.amount) {
      return false
    }
  }

  const getTotalWithoutTaxes = amountParameter => {
    const items = order?.details?.filter(d => !d.viewInSummary) || []

    return items.reduce((total, item) => total + (item[amountParameter] || 0), 0) || 0
  }

  const getTotalTax = () => {
    return order.details?.reduce((total, item) => total + (item.tax || 0), 0) || 0
  }

  const getTotalTaxPressBell = () => {
    return (
      order.details?.reduce((total, item) => total + (item.taxPressBell || 0), 0) || 0
    )
  }

  const getHeaders = quantityBalance => {
    const seeSubtotalWithoutTaxes = order.taxDetailed && !order.taxIncluded

    const headers = [
      { checked: all, show: order.type === 1, select: true },
      {
        label: 'SKU / Código',
        show: true,
        value: ['skuC'],
        type: 'text',
        className: 'product',
        custom: item =>
          (item.skuC = item.productData
            ? item.productData.code
            : item.product
            ? item.product.code
            : 'Desconocido'),
      },
      {
        label: 'Producto',
        show: true,
        value: ['product', 'name'],
        type: 'text',
        className: 'large',
        style: { minWidth: 150 },
        custom: item => (
          <div>
            <label
              style={{
                display: 'flex',
                marginBottom: '0px',
              }}>
              {item.productData
                ? item.productData.name
                : item.product
                ? item.product.name
                : 'Desconocido'}
            </label>

            {item.categories && <ListVariations selected={item.categories} />}
            <div>{item.commentary}</div>
          </div>
        ),
      },
      {
        label: nameLocations[0],
        show: seeWarehouseLocations.value === 'true',
        value: ['listLocations', 0, 'name'],
        type: 'text',
        className: 'medium',
      },
      {
        label:
          locationQuantity.value === 2 || locationQuantity.value === 3
            ? nameLocations[1]
            : '',
        show:
          seeWarehouseLocations.value === 'true' &&
          (locationQuantity.value === 2 || locationQuantity.value === 3),
        value: ['listLocations', 1, 'name'],
        type: 'text',
        className: 'medium',
      },
      {
        label: locationQuantity.value === 3 ? nameLocations[2] : '',
        show: seeWarehouseLocations.value === 'true' && locationQuantity.value === 3,
        value: ['listLocations', 2, 'name'],
        type: 'text',
        className: 'medium',
      },
    ]
    if (quantityBalance) {
      headers.push(
        ...[
          {
            label: 'Cantidad recibida',
            show: true,
            value: ['quantityBalance'],
            type: 'text',
            className: 'medium',
          },
          {
            label: 'Precio',
            show: fromCard
              ? cSeeBalances
              : fromTransfer
              ? tSeeBalances
              : fromClients
              ? clSeeMoneyBalances
              : true,
            value: ['precioC'],
            type: 'currency',
            className: 'mini',
            custom: item => {
              item.precioC = item.subtotal ? item.subtotal / item.quantity : 0
              return toMoney(item.precioC)
            },
          },
          {
            label: 'Total',
            show: true,
            value: ['totalC'],
            type: 'currency',
            className: 'mini',
            custom: item => {
              item.totalC = item.subtotal
                ? (item.subtotal * item.quantityBalance) / item.quantity
                : 0
              return toMoney(item.totalC)
            },
          },
        ],
      )
    } else {
      headers.push(
        ...[
          {
            label: 'Cantidad',
            show: true,
            value: ['quantity'],
            type: 'text',
            className: 'mini',
          },
          {
            label: 'Precio',
            show:
              order.type !== 2 &&
              (fromCard
                ? cSeeBalances
                : fromTransfer
                ? tSeeBalances
                : fromClients
                ? clSeeMoneyBalances
                : true),
            type: 'currency',
            className: 'mini',
            value: ['price'],
            // custom: item => {
            //   return toMoney(
            //     item.price !== null
            //       ? country.id === Country.SV && order.felDocumentType === 1
            //         ? item.priceWithTax
            //         : item.price
            //       : item.subtotal
            //       ? item.subtotal / item.quantity
            //       : 0,
            //   )
            // },
          },
        ],
      )

      /* const seeIVA =
        country.id === Country.HN ||
        (order.felDocumentType === 3 &&
          order.type !== 2 &&
          (fromCard
            ? cSeeBalances
            : fromTransfer
            ? tSeeBalances
            : fromClients
            ? clSeeMoneyBalances
            : true))*/

      /*const seeSubTotalWithDiscount =
        country.id === Country.HN ||
        (order.felDocumentType === 3 &&
          order.type !== 2 &&
          (fromCard
            ? cSeeBalances
            : fromTransfer
            ? tSeeBalances
            : fromClients
            ? clSeeMoneyBalances
            : true))*/

      headers.push(
        ...[
          {
            label: 'Subtotal',
            show:
              order.type !== 2 &&
              (fromCard
                ? cSeeBalances
                : fromTransfer
                ? tSeeBalances
                : fromClients
                ? clSeeMoneyBalances
                : true),
            value: ['subtotal'],
            type: 'currency',
            className: 'mini',
            // custom: item =>
            //   toMoney(
            //     order.felDocumentType !== 3
            //       ? item.subtotal
            //       : item.subtotal + item.discount,
            //   ),
          },
          {
            label: 'Descuento',
            show:
              order.type !== 2 &&
              (fromCard
                ? cSeeBalances
                : fromTransfer
                ? tSeeBalances
                : fromClients
                ? clSeeMoneyBalances
                : true),
            value: ['discount'],
            // value: [
            //   country.id === Country.SV && order.felDocumentType === 1
            //     ? 'discountWithTax'
            //     : 'discount',
            // ],
            type: 'currency',
            className: 'mini',
          },
          {
            label: 'Sub total',
            show: seeSubtotalWithoutTaxes,
            value: ['totalWithoutTaxes'],
            type: 'currency',
          },
          {
            label: taxLabel,
            show: false,
            value: ['tax'],
            type: 'currency',
          },
        ],
      )

      order.orderVoucher !== null &&
        headers.push({
          label: 'Descuento especial',
          show: order.type !== 2,
          value: ['discountSpecial'],
          type: 'currency',
          className: 'mini',
        })

      headers.push(
        ...[
          {
            label: 'Precio U. con descuento',
            // show:
            //   order.type !== 2 &&
            //   (fromCard
            //     ? cSeeBalances
            //     : fromTransfer
            //     ? tSeeBalances
            //     : fromClients
            //     ? clSeeMoneyBalances
            //     : true),
            show: false,
            value: ['precioU'],
            type: 'currency',
            className: 'mini',
            custom: item => {
              item.precioU = item.total / item.quantity
              return toMoney(item.precioU)
            },
          },
          {
            label: 'Total',
            show:
              !seeSubtotalWithoutTaxes &&
              order.type !== 2 &&
              (fromCard
                ? cSeeBalances
                : fromTransfer
                ? tSeeBalances
                : fromClients
                ? clSeeMoneyBalances
                : true),
            type: 'currency',
            className: 'mini',
            custom: item =>
              toMoney(
                item.total +
                  (order.retention !== null && country.id === Country.SV
                    ? item.retention
                    : 0),
              ),
          },
        ],
      )
    }
    headers.push({ config: true, show: true, label: '', className: 'mini center' })
    return headers
  }

  const confirmButtons = () => {
    let buttons = [
      {
        show: canConfirmVisitWithoutSell,
        label: order.paymentType === 1 ? 'Confirmar sin Despacho' : 'Confirmar sin Venta',
        description:
          order.paymentType === 1
            ? 'Confirma la orden de visita sin realizar el despacho de productos'
            : 'Confirma unícamente la orden de visita',
        loading: loadingVisit,
        onClick: () => dispatch(confirmVisitNoSale(order.orderId)),
      },
      {
        show: canConfirmVisit,
        label: order.paymentType === 1 ? 'Confirmar Despacho' : 'Confirmar con Venta',
        loading: loadingVisit,
        description:
          order.paymentType === 1
            ? 'Confirma la orden de visita y realiza el despacho de los productos'
            : 'Confirma la orden de visita y realiza la venta de productos',
        onClick: () => {
          if (!validateLockedClient()) return
          if (order.paymentType === 1) {
            dispatch(confirmVisitNoSale(order.orderId, { dispatch: true }))
          } else {
            history.push(`/${getModule()}/ordenes/visita/detalle/${order.orderId}/nueva`)
            onHide()
          }
        },
      },
    ]
    return buttons
  }
  const isSelected = item => {
    return selected.find(s => item.id === s.id) !== undefined
  }

  const addOrRemoveSelected = item => {
    const cs = Object.assign([], selected)
    if (isSelected(item)) {
      //remove
      setSelected(cs.filter(s => s.id !== item.id))
    } else {
      //add
      cs.push(item)
      setSelected(cs)
    }
  }

  const getNewSubtotal = () => {
    const taxException = order.taxException

    const taxDetailed = taxException || order.taxDetailed
    const taxIncluded = order.taxIncluded

    const amountParameter = taxIncluded ? 'total' : 'totalWithoutTaxes'

    const prevTotal = getTotalWithoutTaxes(amountParameter) // 1
    const summaries =
      (order && order.details && order.details.filter(d => d.viewInSummary)) || [] // 2

    const totalSummary = summaries.reduce(
      (total, item) => total + (item[amountParameter] || 0),
      0,
    )
    const totalWithSummary = prevTotal + totalSummary // 3

    const taxes = order.taxes || [] // 4
    const taxPressBell = getTotalTaxPressBell() // 5
    let grandTotal = order.total // 6

    const subtotals = []

    if (taxDetailed || totalSummary > 0)
      subtotals.push({
        label: 'Sub total',
        value: prevTotal,
      })

    summaries.forEach(summary =>
      subtotals.push({ label: summary.product.name, value: summary.totalWithoutTaxes }),
    )

    if (totalSummary > 0 && taxDetailed)
      subtotals.push({ label: 'Total', value: totalWithSummary })

    if (taxDetailed) {
      const multiply = taxException ? -1 : 1

      taxes.forEach(tax => {
        const taxName = tax.name + (taxException ? ' (Exento)' : '')
        subtotals.push({ label: taxName, value: tax.amount * multiply })
      })

      if (taxException) grandTotal -= taxes.reduce((total, tax) => total + tax.amount, 0)

      if (taxPressBell > 0) {
        subtotals.push({ label: 'Timbre de prensa (0.5%)', value: taxPressBell })
      }
    }
    subtotals.push({ label: 'Gran total', value: grandTotal })

    return (
      <div>
        {subtotals.map(subtotal => (
          <h2 key={subtotal.name}>
            {subtotal.label}: {toMoney(subtotal.value, false, 0, true)}
          </h2>
        ))}
      </div>
    )
  }

  const renderTable = () => {
    let quantityBalance =
      order.type === 1 && order.details.filter(d => d.quantityBalance > 0).length > 0

    const items =
      (order &&
        order.details &&
        order.details.filter(d => d.product?.code !== 'P-CMSNGNC' && !d.viewInSummary)) ||
      []

    const commissionItems =
      (order &&
        order.details &&
        order.details.filter(d => d.product?.code === 'P-CMSNGNC')) ||
      []

    const totalCommission =
      commissionItems.reduce((total, item) => total + (item.subtotal || 0), 0) || 0

    const summaries =
      (order && order.details && order.details.filter(d => d.viewInSummary)) || []

    return (
      <>
        <TableV2
          headers={getHeaders(quantityBalance)}
          mobileAuto
          storageKey={`modalOrder`}
          items={items}
          onCheck={
            order.type === 1
              ? value => {
                  setAll(value)
                  setSelected([])
                }
              : false
          }
          isSelected={
            order.type === 1
              ? item => (isSelected(item) && all ? false : all || isSelected(item))
              : undefined
          }
          onClickTr={
            order.type === 1
              ? (e, item) => {
                  addOrRemoveSelected(item)
                }
              : undefined
          }
          getItems={
            order.type === 1
              ? item => {
                  if (item !== null) {
                    setSelected([...selected, item.item])
                  } else {
                    setSelected([])
                  }
                }
              : false
          }
          footerText={
            (fromCard
              ? cSeeBalances
              : fromTransfer
              ? tSeeBalances
              : fromClients
              ? clSeeMoneyBalances
              : true) && (
              <div className={'column'}>
                {getTotalTax() > 0 || getTotalTaxPressBell() > 0 ? (
                  getNewSubtotal()
                ) : (
                  <div>
                    {order.linked && order.linkType !== 3 && order.linkType !== 2 && (
                      <>
                        <h2>
                          Subtotal: {symbol}.
                          {formatNumberWithCommas(order.total - (order.shipping || 0))}
                        </h2>
                        <h2 style={{ color: 'rgba(203,64,96,0.99)' }}>
                          Costo de envió: {order.shipping}
                        </h2>
                      </>
                    )}
                    {order.retention !== null &&
                      order.retention > 0 &&
                      country.id === Country.SV && (
                        <>
                          <h2>{`SubTotal: ${toMoney(order.total + order.retention)}`}</h2>
                          <h2> {`(-) IVA retenido: ${toMoney(order.retention)}`}</h2>
                        </>
                      )}
                    {order.surcharge > 0 && (
                      <h2>
                        Recargo por tarjeta ({cardSurcharge}%): {toMoney(order.surcharge)}
                      </h2>
                    )}
                    {summaries.length > 0 && (
                      <>
                        <h2>{`SubTotal: ${toMoney(
                          order.total -
                            summaries.reduce(
                              (total, item) => total + (item.total || 0),
                              0,
                            ) || 0,
                        )}`}</h2>
                        {summaries.map((summary, key) => (
                          <h2 key={key}>{`${summary.product.name}: ${toMoney(
                            summary.total,
                          )}`}</h2>
                        ))}
                      </>
                    )}
                    {!quantityBalance && <h2>Total: {toMoney(order.total)}</h2>}
                  </div>
                )}

                {order.orderVoucher !== null && order.voucherData && (
                  <Card
                    style={{
                      fontSize: 15,
                      textAlign: 'center',
                      color: '#a21e8c',
                      marginTop: 5,
                    }}>
                    {' '}
                    Se ha utilizado un Vale con el número {order.voucherData.number} en la
                    fecha {formatDateFromMillis(order.voucherData.date)} con el monto de
                    {symbol}.{formatNumberWithCommas(order.voucherData.amount)} creado por
                    el usuario
                    {order.voucherData.responsable
                      ? order.voucherData.responsable.name
                      : 'Desconocido '}
                    y utilizado por el usuario
                    {order.voucherData.user
                      ? order.voucherData.user.name
                      : 'Desconocido '}
                    .
                  </Card>
                )}
              </div>
            )
          }
        />
      </>
    )
  }

  const getItems = () => {
    const response = []

    if (order.parcels) {
      order.parcels.forEach((p, index) => {
        if (p.products) {
          p.products.forEach(pp => {
            response.push({
              ...pp,
              companyId: `${p.id} - ${index + 1}`,
              companyName: `${p.packageName}`,
            })
          })
        }
      })
    }
    return response
  }

  const setLinkAlert = type => {
    setAlert({
      show: true,
      type: 'info',
      title: type === 1 ? 'Copiar link' : 'Abrir link en un navegador',
      text: `El cliente pagara el ${cardSurcharge}% de recargo`,
      confirmButtonText: type === 1 ? 'Copiar' : 'Abrir link',
      confirmButtonColor: '#226095',
      onConfirm: () => {
        setAlert({ ...alert, show: false })
        if (type === 1) {
          let aux = document.createElement('input')
          aux.setAttribute('value', `https://kolo-app.com/orders/${orderId}`)
          document.body.appendChild(aux)
          aux.select()
          document.execCommand('copy')
          document.body.removeChild(aux)
          setCopied(true)
        } else window.open(`https://kolo-app.com/orders/${orderId}`)
      },
    })
  }

  const getModule = () => {
    switch (module) {
      case 1000:
        return 'distribucion'
      case 2000:
        return 'produccion'
      default:
        return ''
    }
  }

  const rowClicked = (e, data) => {
    vouchers.vouchers.forEach(c => {
      if (c.id === choosen.id) {
        setChoosen({ id: null, amount: null })
        setVoucher({ ...modalVoucher, amount: null })
      } else if (c.id === data.id) {
        setChoosen({ id: data.id, amount: data.total })
        setVoucher({ ...modalVoucher, amount: data.total })
      }
    })
  }

  const payVoucher = (voucherId, order) => {
    let object = {
      amount: modalVoucher.amount,
      returnType:
        modalVoucher.amount < choosen.amount
          ? modalVoucher.returnCash
            ? 1
            : modalVoucher.returnBank
            ? 2
            : modalVoucher.returnVoucher
            ? 3
            : null
          : null,
    }
    dispatch(payWithVoucher(order.orderId, voucherId.id, object))
  }

  const goToInvoice = (id, number) => {
    setTimeout(() => {
      let url = module === 1000 ? '/distribucion' : '/produccion'
      url += '/ordenes/false/invoices/' + id + `/${number}`
      window.open(url, '_blank')
    }, 500)
  }

  const paymentDateDescription = () => {
    if (order?.paymentType === 2 && order?.paymentType !== 7) {
      return formatDateFromMillis(order?.paymentDate)
    } else if (order?.paymentType === 7) {
      const paymentDateForMultiple = order?.multiplePayments?.find(
        payment => payment?.paymentType === 2,
      )?.paymentDate
      if (paymentDateForMultiple) {
        return formatDateFromMillis(paymentDateForMultiple)
      } else {
        return null
      }
    } else {
      return 'Sin descripción'
    }
  }

  const printInvoices = useCallback(
    async (orderId, defaultName, module, documentType, isCreditNote, newPage = false) => {
      const isSafari = isSafariNavigator()
      if (isSafari && newPage) {
        goToInvoice(orderId, defaultName)
      } else {
        const getPDF = dispatch(
          downloadPrintableInvoiceByOrder(
            orderId,
            defaultName,
            module,
            documentType,
            isCreditNote,
            false,
          ),
        )

        getPDF
          .then(file => {
            if (!file) throw new Error('Ocurrió un error al generar el PDF')
            if (newPage) {
              const blobURL = URL.createObjectURL(file)
              window.open(blobURL, '_blank')
            } else
              dispatch(
                onSetPrintData({
                  show: true,
                  pdfBlob: file,
                }),
              )
          })
          .catch(() => {
            dispatch(showAlert({ ...handlerError('Ocurrió un error al generar el PDF') }))
          })
      }
    },
  )

  const validateLockedClient = () => {
    if (order?.client?.locked) {
      dispatch(
        showAlert(
          handlerError(
            'El cliente de la orden actualmente se encuentra bloqueado, por favor comunique se con su administrador.',
          ),
        ),
      )
      return false
    }
    return true
  }

  return (
    <div style={{ position: 'fixed' }}>
      <Modal
        show={show && !modalConfirm.show}
        size={'lg'}
        centered
        onHide={() => onHide()}>
        <Modal.Header closeButton>
          <Modal.Title>
            {order.details && !order.details.filter(d => d.quantityBalance > 0).length > 0
              ? 'Detalle '
              : 'Devolución '}
            de la Orden #{order.number}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12} md={12} sm={12} className={'invoice'}>
              <Row>
                <Col xl={12} md={12} sm={12} className={'space-between'}>
                  <h4>
                    <b>Cliente: </b>
                    {order.client ? order.client.companyName : 'Desconocido'}
                  </h4>
                  <a
                    style={{
                      marginLeft: 10,
                      marginTop: 5,
                      backgroundColor: 'rgba(80,170,70,0.8)',
                      borderRadius: 10,
                    }}
                    className={'pp-phone'}
                    href={
                      order.client && order.client.phone && `tel:${order.client.phone}`
                    }>
                    <Icon
                      icon={faPhone}
                      color={'white'}
                      size={'2x'}
                      style={{ margin: 10, alignSelf: 'center' }}
                    />
                  </a>
                </Col>
                <Col xl={12} md={12} sm={12}>
                  <Row>
                    {order.contactName && (
                      <Col xl={3} md={3} sm={6} xs={6} className={'column'}>
                        <b>Contacto: </b>
                        {order.contactName}
                      </Col>
                    )}
                    <Col xl={3} md={3} sm={6} xs={6} className={'column'}>
                      <b>Bodega: </b>
                      {order.warehouse ? order.warehouse.name : 'Desconocido'}
                    </Col>
                    <Col xl={3} md={3} sm={6} xs={6} className={'column'}>
                      <b>Fecha: </b>
                      {formatDateFromMillis(order.enabledAt || order.createdAt)}
                    </Col>
                    <Col xl={3} md={3} sm={6} xs={6} className={'column'}>
                      <b>Dirección: </b>
                      {order.client
                        ? order.client.address || 'Desconocido'
                        : 'Desconocido'}
                    </Col>
                    <Col xl={3} md={3} sm={6} xs={6} className={'column'}>
                      <b>Referencia: </b>
                      {order.client
                        ? order.client.reference || 'Sin referencia'
                        : 'Sin referencia'}
                    </Col>
                    <Col xl={3} md={3} xs={3} className={'column'}>
                      <b>Tipo de pago: </b>
                      {order.paymentTypeData ? order.paymentTypeData.name : 'Desconocido'}
                    </Col>
                    <Col xl={3} md={3} sm={3} className={'column'}>
                      <b>Descripción: </b>
                      {order.description || 'Sin descripción'}
                    </Col>
                    <Col xl={3} md={3} sm={3} className={'column'}>
                      <b>Estado de despacho: </b>
                      {orderDispatchStatus[order.dispatchStatus] || '--'}
                    </Col>
                    {order.dispatchDate !== null && (
                      <Col xl={3} md={3} sm={3} className={'column'}>
                        <b>Fecha de despacho: </b>
                        {formatDateFromMillis(order.dispatchDate, false, true)}
                      </Col>
                    )}
                    {order.felDocumentType !== null && (
                      <Col xl={3} md={3} sm={3} className={'column'}>
                        <b>Tipo de documento: </b>
                        {
                          felDocumentTypes.find(d => d.value === order.felDocumentType)
                            ?.label
                        }
                      </Col>
                    )}
                    {order.status === orderStatusEnum.ANNULLED && (
                      <Col xl={3} md={3} sm={3} className={'column'}>
                        <b>Anulada por: </b>
                        {order?.updatedBy?.name}
                      </Col>
                    )}
                    <Col xl={3} md={3} sm={3} className={'column'}>
                      <b>Fecha de Pago en Crédito: </b>
                      {paymentDateDescription()}
                    </Col>
                    {haveAnyValue(order?.creditNoteAuth) && (
                      <Col xl={3} md={3} sm={3} className={'column'}>
                        <b>Nota de crédito / vale: </b>
                        {order.creditNoteAuth}
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col xl={12} md={12}>
                  <br />
                </Col>
                <Col xl={12} md={12} sm={12} className={'text-center'}>
                  <b className={order.deleted ? 'red-text' : 'green-text'}>
                    {order.deleted
                      ? `Orden Rechazada: ${order.description}`
                      : `Orden ${
                          order.statusData ? order.statusData.name : 'Desconocido'
                        }`}
                  </b>
                </Col>

                {order.type === 2 && order?.details?.length === 0 ? (
                  <div />
                ) : (
                  <Col xl={12}>
                    <Row>
                      <Col xl={12} md={12}>
                        <hr />
                      </Col>
                      <Col xl={12} md={12} sm={12}>
                        {order.groupId ? (
                          <CustomTabs
                            items={[
                              { title: 'Productos', component: renderTable() },
                              {
                                title: 'Contenedores',
                                component: (
                                  <ProductItems
                                    style={{ maxHeight: '400px' }}
                                    hideFilter
                                    noItems={'No se encontraron contenedores'}
                                    customItems={getItems()}
                                    filter={() => {
                                      return true
                                    }}
                                    renderItem={(item, index) => (
                                      <ProductItem
                                        eventKey={index}
                                        title={item.productName}
                                        titleSecond={`${item.quantity} u.`}
                                        subTitle={item.productCode}
                                        subTitleSecond={item.warehouseName}
                                      />
                                    )}
                                  />
                                ),
                              },
                            ]}
                          />
                        ) : (
                          renderTable()
                        )}
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </Col>

            {order.type === 1 && (
              <Col xl={12} md={12} sm={12}>
                <div style={{ display: 'none' }}>
                  <div id={'ticket-order'}>
                    <p className="center">
                      <br />
                      {company.id === 791
                        ? 'AE'
                        : company.id === 860
                        ? 'IM'
                        : company.commercialName || company.name}
                      <br />
                      {company.id !== 791 &&
                        (company.id === 860 ? 'Ciudad' : company.address)}
                      <br />
                      Orden NO. {order.number}
                      {' - '}
                      {formatUtcDateFromMillis(order.enabledAt || order.createdAt)}
                    </p>

                    <p className={'center'}>
                      <b>Nombre: </b>
                      {order.client ? order.client.companyName : 'Desconocido'} <br />
                      <b>NIT: </b> {order.client ? order.client.nit : 'Desconocido'}
                      <br />
                      <b>Dirección: </b>
                      {order.client ? order.client.address : 'Desconocido'} <br />
                      <b>Referencia: </b>
                      {order.client?.reference || 'Sin referencia'}
                      <br />
                      <b>Telefono: </b>
                      {order.client ? order.client.phone : 'Desconocido'} <br />
                      <b>Descripción: </b>
                      {order.description || 'Sin descripción'} <br />
                      <b>Forma de pago: </b>
                      {order.paymentTypeData ? order.paymentTypeData.name : 'Desconocido'}
                      <br />
                      <b>Comentario: </b> {order.commentary || ''} <br />
                      <b>Repartidor: </b>
                      {order.responsibleUser
                        ? order.responsibleUser.name
                        : 'Sin descripción'}
                      <br />
                      {order.updatedAt && (
                        <>
                          <b>Última actualización:</b>
                          {formatUtcDateFromMillis(order.updatedAt)}
                          <br />
                        </>
                      )}
                    </p>

                    <table className={'center'}>
                      <thead>
                        <th>Cant</th>
                        <th>Detalle</th>
                        <th>Total</th>
                      </thead>
                      <tbody>
                        {order.details &&
                          order.details
                            .filter(
                              d =>
                                d.product?.code !== 'P-CMSNGNC' &&
                                (country.id !== Country.GT || !d.viewInSummary),
                            )
                            .map((item, index) => (
                              <tr key={index}>
                                <td className={'center'} style={{ width: 50 }}>
                                  {item.quantity}
                                </td>
                                <td className={'center'} style={{ width: 125 }}>
                                  {item.productData
                                    ? item.productData.name
                                    : item.product
                                    ? item.product.name
                                    : 'Desconocido'}
                                  <br />
                                  {item.categories &&
                                    item.categories.map(cat => cat.name + ' ')}
                                </td>
                                <td className={'center'} style={{ width: 100 }}>
                                  {formatNumberWithCommas(item.total)}
                                </td>
                              </tr>
                            ))}
                        {order.surcharge > 0 && (
                          <tr className="total-row">
                            <td className={'center'} style={{ width: 50 }}>
                              Recargo
                            </td>
                            <td className={'center'} style={{ width: 125 }} />
                            <td className={'center'} style={{ width: 100 }}>
                              {symbol}.{formatNumberWithCommas(order.surcharge)}
                            </td>
                          </tr>
                        )}
                        {order.details &&
                          order.details
                            .filter(d => d.viewInSummary)
                            .map((item, index) => (
                              <tr key={index}>
                                <td className={'center'} style={{ width: 50 }}>
                                  {item.product.name}
                                </td>
                                <td className={'center'} style={{ width: 125 }} />
                                <td className={'center'} style={{ width: 100 }}>
                                  {symbol}.{formatNumberWithCommas(item.total)}
                                </td>
                              </tr>
                            ))}
                        <tr className="total-row">
                          <td className={'center'} style={{ width: 50 }}>
                            Total
                          </td>
                          <td className={'center'} style={{ width: 125 }} />
                          <td className={'center'} style={{ width: 100 }}>
                            {symbol}.{formatNumberWithCommas(totalOrder)}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    {order.paymentTypeData && order.paymentTypeData.id === 2 && (
                      <div className={'center'}>
                        <b>Abonos: </b>
                        {symbol}.{formatNumberWithCommas(order.total - order.balance)}
                      </div>
                    )}

                    <p className={'center'}>
                      <b>ESTE DOCUMENTO NO ES UNA FACTURA ELECTRÓNICA</b>
                    </p>
                  </div>

                  <div id={'ticket-gift'}>
                    <p className="center">
                      <b>{company.name}</b> <br />
                      {company.address} <br />
                      TICKET DE REGALO
                    </p>

                    <p className={'left'}>
                      <b>Código: </b> {order.number} <br />
                      <b>Fecha: </b>
                      {formatDateFromMillis(order.enabledAt || order.createdAt)} <br />
                    </p>

                    <table>
                      <thead>
                        <th>Cant.</th>
                        <th>Detalle</th>
                      </thead>
                      <tbody>
                        {(all ? (order.details ? order.details : []) : selected).map(
                          (item, index) => (
                            <tr key={index}>
                              <td className={'center'} style={{ width: 50 }}>
                                {item.quantity}
                              </td>
                              <td className={'center'} style={{ width: 300 }}>
                                {item.productData
                                  ? item.productData.name
                                  : item.product
                                  ? item.product.name
                                  : 'Desconocido'}
                                <br />
                                {item.categories &&
                                  item.categories.map(cat => cat.name + ' ')}
                              </td>
                            </tr>
                          ),
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
            )}

            <Col xl={12} md={12} sm={12}>
              <hr />
              {!order.deleted && company.id === (order.company && order.company.id) && (
                <Row className={'container-buttons'}>
                  {((del && order.status === 1) || order.status === 14) && (
                    <Button
                      loading={loadingD}
                      color={'secondary'}
                      icon={faTimes}
                      left
                      onClick={() => {
                        setShowAlertCancel({ show: true })
                      }}>
                      Rechazar
                    </Button>
                  )}
                  {order.type !== 2 &&
                    ((confirm && !order.quote) || (confirmC && order.quote)) &&
                    (order.linked
                      ? order.status === 8
                      : order.status === 1 ||
                        order.status === 8 ||
                        order.status === 14) &&
                    !order.deleted && (
                      <Button
                        disabled={loadingD}
                        color={'primary'}
                        icon={faCheck}
                        right
                        onClick={() => {
                          if (!validateLockedClient()) return

                          if (order.quote) {
                            const paidBalance = order.total - order.balance
                            const pending =
                              (order.total * order.advance) / 100 - paidBalance

                            if (pending > 0)
                              return setAlert({
                                ...handlerError(
                                  `Se debe abonar el anticipo de la cotización (${toMoney(
                                    pending,
                                  )}) para poder confirmar la orden de venta.`,
                                ),
                                onConfirm: () => setAlert({ ...alert, show: false }),
                              })
                          }

                          dispatch(
                            setModalConfirm({
                              ...order,
                              show: true,
                              confirmBy: null,
                              commentary: null,
                              paymentDate: haveAnyValue(order?.paymentDate)
                                ? new Date(order?.paymentDate)
                                : null,
                              multiplePayments: haveAnyValue(order?.multiplePayments)
                                ? order?.multiplePayments
                                : null,
                              retention: null,
                              gratification: null,
                              cash: null,
                              dontCertificate: false,
                            }),
                          )
                        }}>
                        Confirmar
                      </Button>
                    )}
                  {order.type === 2 && (order.status === 1 || order.status === 14) && (
                    <Button
                      color={'primary'}
                      icon={faCheckDouble}
                      loading={loadingVisit}
                      onClick={() => setModalOptions({ show: true })}>
                      Confirmar
                    </Button>
                  )}

                  {canAdjustVisit &&
                    order.type === 2 &&
                    (order.status === 1 || order.status === 14) &&
                    order.paymentType === 1 && (
                      <Button
                        color={'accent'}
                        onClick={() => {
                          setAdjust({ edit: !adjust.edit })
                          setShow(!show)
                        }}
                        tooltip={'Ajustar'}
                        icon={faEdit}></Button>
                    )}

                  {(order.status === 3 ||
                    order.status === 15 ||
                    order.status === 13 ||
                    order.status === 5 ||
                    order.status === 17 ||
                    order.status === 18) &&
                    order.type !== 2 &&
                    (fromCard
                      ? cSeeBalances
                      : fromTransfer
                      ? tSeeBalances
                      : fromClients
                      ? clSeeMoneyBalances
                      : true) && (
                      <Button
                        icon={faFileInvoice}
                        right
                        color={'primary'}
                        loading={loadingPDF}
                        onClick={() =>
                          printInvoices(orderId, null, 1000, 1, false, true)
                        }>
                        Ver facturas
                      </Button>
                    )}
                  {order.status === 1 &&
                    order.orderVoucher === null &&
                    order.type !== 2 && (
                      <Button
                        color={'accent'}
                        onClick={() => {
                          setVoucher({
                            show: true,
                            client: order.client,
                            order: order,
                            amount: order.total,
                          })
                          dispatch(
                            getVouchersByCustomers({
                              clientId: order.client.id,
                              use: 1,
                              isByClient: true,
                            }),
                          )
                        }}>
                        <Icon icon={faReceipt} tooltip={'Utilizar un vale'} />
                      </Button>
                    )}
                  {order.type === 1 &&
                    !order.details.filter(d => d.quantityBalance > 0).length > 0 &&
                    (fromCard
                      ? cSeeBalances
                      : fromTransfer
                      ? tSeeBalances
                      : fromClients
                      ? clSeeMoneyBalances
                      : true) && (
                      <Button
                        color={'accent'}
                        onClick={() => {
                          setOrderTotal(
                            order?.details.reduce((increment, detail) => {
                              return increment + detail.total
                            }, 0) || 0,
                          )
                          dispatch(
                            onSetPrintData({
                              show: true,
                              element: 'ticket-order',
                              logo: order.ticketLogo,
                              customData: orderTicket(order, company),
                            }),
                          )
                        }}>
                        <Icon icon={faPrint} tooltip={'Imprimir ticket'} />
                      </Button>
                    )}
                  {order.type === 1 && (
                    <Button
                      onClick={() => {
                        const container = document.getElementById('ticket-gift').innerHTML
                        let customData = {}
                        if (prints.length > 0) {
                          const list = all
                            ? order.details
                              ? order.details
                              : []
                            : selected
                          list.map((item, index) => (
                            <tr key={index}>
                              <td className={'center'} style={{ width: 50 }}>
                                {item.quantity}
                              </td>
                              <td className={'center'} style={{ width: 225 }}>
                                {item.productData
                                  ? item.productData.name
                                  : item.product
                                  ? item.product.name
                                  : 'Desconocido'}
                                {item.categories &&
                                  item.categories.map(cat => cat.name + ' ')}
                              </td>
                            </tr>
                          ))
                          customData = printGiftTicket({
                            name: company.name,
                            address: company.address,
                            reference: order.number,
                            date: order.enabledAt || order.createdAt,
                            items: list.map(item => ({
                              quantity: Number.parseInt(item.quantity),
                              description: item.productData
                                ? item.productData.name
                                : item.product
                                ? item.product.name
                                : 'Desconocido',
                            })),
                            dontCut: true,
                          })
                        }
                        dispatch(
                          onSetPrintData({
                            customData,
                            show: true,
                            content: container,
                            logo: order.ticketLogo,
                          }),
                        )
                      }}>
                      <Icon icon={faGift} tooltip={'Imprimir ticket de regalo'} />
                    </Button>
                  )}
                </Row>
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <ConfirmOrder
        show={modalConfirm.show}
        order={modalConfirm}
        creditOrder={order}
        onHide={() => {
          dispatch(setModalConfirm({ show: false }))
        }}
        onHideSuccess={invoice => {
          onHideSuccess(invoice)
        }}
      />

      <ModalOrderPayments />

      <ModalOrderProcess />
      <ModalOrderTransfer />

      <Modal
        show={modalVoucher.show}
        size={'lg'}
        centered
        aria-labelledby={'contained-modal-title-vcenter'}
        onHide={() =>
          setVoucher({ modalVoucher: { show: false, client: null, order: null } })
        }>
        `{'>'}`
        <ModalHeader closeButton>
          <ModalTitle>Vales disponibles</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <TableV2
            loading={loadingVouchers}
            noItemsLegend={`El cliente no tiene vales disponibles`}
            items={vouchers && vouchers.vouchers}
            mobileAuto
            storageKey={`modalOrdervales`}
            onClickTr={(e, item) => rowClicked(e, item)}
            headers={[
              {
                label: '',
                show: true,
                simpleChecked: true,
                select: true,
                custom: (item, index) => (
                  <input
                    type={'checkbox'}
                    name={index}
                    checked={item.id === choosen.id}
                    onChange={e => rowClicked(e, item)}
                  />
                ),
                className: 'mini',
              },
              {
                label: 'Fecha',
                show: true,
                value: ['createdAt'],
                type: 'date',
                className: 'mini',
              },
              {
                label: 'Numero',
                show: true,
                value: ['number'],
                type: 'text',
                className: 'mini',
              },
              {
                label: 'Total',
                show: true,
                value: ['total'],
                type: 'currency',
                className: 'mini',
              },
              {
                label: 'Encargado',
                show: true,
                value: ['responsableC'],
                type: 'text',
                className: 'medium',
                custom: item =>
                  (item.responsableC = item.responsibleId
                    ? item.responsibleData.name
                    : 'Desconocido'),
              },
              { config: true, show: true, label: '', className: 'mini center' },
            ]}
          />
          {/* <Table
            loading={loadingVouchers}
            headers={['', 'Fecha', 'Numero', `Total (${symbol})`, 'Encargado']}
            items={vouchers && vouchers.vouchers}
            noItemsLegend={`El cliente no tiene vales disponibles`}
            renderRow={(item, index) => (
              <Tr className={'data'} key={index} onClick={e => rowClicked(e, item)}>
                <Td className={'mini'}>

                  <input
                    type={'checkbox'}
                    name={index}
                    checked={item.id === choosen.id}
                  />
                </Td>
                <Td className={'mini'}>{formatDateFromMillis(item.createdAt)}</Td>
                <Td className={'mini'}>{item.number}</Td>
                <Td>{formatNumberWithCommas(item.total)}</Td>
                <Td className={'medium'}>
                  {item.responsibleId ? item.responsibleData.name : 'Desconocido'}
                </Td>
              </Tr>
            )}
          /> */}
          <FormText
            id={'amount'}
            label={'Monto de la nota de crédito'}
            placeholder={`pj. (${symbol}.100.00)`}
            type={'number'}
            name={'amount'}
            value={modalVoucher.amount}
            onChange={({ target }) => {
              let { value } = target
              if (value <= 0) setVoucher({ ...modalVoucher, amount: 0 })
              else setVoucher({ ...modalVoucher, amount: value })
            }}
            error={
              modalVoucher.amount > (choosen && choosen.amount)
                ? 'El monto no puede ser superior al monto de la nota de credito'
                : modalVoucher.amount > (modalVoucher.order && modalVoucher.order.total)
                ? 'El monto no puede ser superior al monto de la venta total.'
                : modalVoucher.amount === 0
                ? 'El monto no puede ser menor o igual a 0'
                : null
            }
            required
            prependMoneySymbol
          />
          <br />
          {(((choosen && choosen.amount) <=
            (modalVoucher.order && modalVoucher.order.total) &&
            modalVoucher.amount < (choosen && choosen.amount)) ||
            ((choosen && choosen.amount) >
              (modalVoucher.order && modalVoucher.order.total) &&
              modalVoucher.amount <= (modalVoucher.order && modalVoucher.order.total))) &&
            modalVoucher.amount > 0 && (
              <Row className={'pl-1'}>
                <Col md={12} lg={12} xl={12}>
                  <label>
                    La venta tiene un valor total de {symbol}.
                    {formatNumberWithCommas(modalVoucher.order.total)}. Se debe de cubrir
                    el monto restante de la nota de credito con una de las siguientes
                    opciones:
                  </label>
                </Col>
                <Col md={4} lg={4} xl={4}>
                  <Checkbox
                    style={{ paddingLeft: '5px' }}
                    onChange={({ target }) =>
                      setVoucher({
                        ...modalVoucher,
                        returnCash: target.checked,
                        returnBank: target.checked ? false : null,
                        returnVoucher: target.checked ? false : null,
                      })
                    }
                    checked={modalVoucher.returnCash}
                    label={'Retornar efectivo al cliente'}
                  />
                </Col>
                <Col md={4} lg={4} xl={4}>
                  <Checkbox
                    style={{ paddingLeft: '5px' }}
                    onChange={({ target }) =>
                      setVoucher({
                        ...modalVoucher,
                        returnBank: target.checked,
                        returnCash: target.checked ? false : null,
                        returnVoucher: target.checked ? false : null,
                      })
                    }
                    checked={modalVoucher.returnBank}
                    label={'Movimiento bancario'}
                  />
                </Col>
                <Col md={4} lg={4} xl={4}>
                  <Checkbox
                    style={{ paddingLeft: '5px' }}
                    onChange={({ target }) =>
                      setVoucher({
                        ...modalVoucher,
                        returnVoucher: target.checked,
                        returnBank: target.checked ? false : null,
                        returnCash: target.checked ? false : null,
                      })
                    }
                    checked={modalVoucher.returnVoucher}
                    label={'Crear un vale'}
                  />
                </Col>
              </Row>
            )}
        </ModalBody>
        <Modal.Footer>
          <Button
            loading={loadingPay}
            color={'primary'}
            disabled={
              !choosen.id ||
              !modalVoucher.amount ||
              modalVoucher.amount === 0 ||
              !validate()
            }
            onClick={() => {
              payVoucher(choosen, modalVoucher.order ? modalVoucher.order : null)
            }}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalOptions.show}
        centered
        size={'md'}
        onHide={() => setModalOptions({ show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Opciones de Despacho</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ padding: '15px 0', gap: '15px 0' }}>
            {confirmButtons(true).map((b, i) => (
              <Col
                key={i}
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}>
                <div>
                  <h2>{b.label}</h2>
                  <Paragraph>{b.description}</Paragraph>
                </div>

                <Button
                  disabled={b.disabled}
                  style={{ width: '100%', margin: '10px auto' }}
                  loading={b.loading}
                  icon={b.icon}
                  onClick={() => {
                    b.onClick()
                  }}>
                  {b.label}
                </Button>
              </Col>
            ))}
          </Row>
        </Modal.Body>
      </Modal>

      {/*<ModalOrderTotalSummary/>*/}

      <Alert {...alert} />

      <Modal
        show={showAlertCancel.show}
        size={'xs'}
        centered
        aria-labelledby={'contained-modal-title-vcenter'}
        onHide={() => setShowAlertCancel({})}>
        <ModalHeader closeButton>
          <ModalTitle></ModalTitle>
        </ModalHeader>
        <ModalBody>
          {' '}
          <div style={{ textAlign: 'center' }}>
            <h4>¿Desea rechazar la orden No.{order.number}?</h4>
            <br />
            <h5>Esta acción no podrá ser revertida</h5>
          </div>
          <FormText
            id={'description'}
            label={'Descripción'}
            placeholder={`Motivo del rechazo`}
            type={'text'}
            name={'description'}
            value={showAlertCancel.text}
            onChange={({ target }) => {
              let { value } = target
              setShowAlertCancel({ ...showAlertCancel, text: value })
            }}
          />
        </ModalBody>
        <Modal.Footer>
          <Button
            loading={loadingPay}
            color={'secondary'}
            onClick={() => {
              setShow(true)
              setShowAlertCancel({ ...showAlertCancel, show: false })
            }}>
            Cancelar
          </Button>
          <Button
            loading={loadingPay}
            color={'primary'}
            onClick={() => {
              setShowAlertCancel({ ...showAlertCancel, show: false })
              dispatch(deleteSingleOrder(orderId, showAlertCancel.text))
            }}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>

      <ModalOrderAdjust
        show={adjust.edit}
        onHide={exit => {
          setAdjust({ edit: false })
          setShow(true)
          if (exit) {
            onHideSuccess()
          }
        }}
        items={
          order && order.details
            ? order.details.filter(d => d.product?.code !== 'P-CMSNGNC')
            : []
        }
        locations={seeWarehouseLocations.value === 'true'}
        orderId={orderId}
      />
    </div>
  )
}
export const ModalOrder = React.memo(ModalOrders)
