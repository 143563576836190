import 'src/content/Products/ProductList.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Col, Row } from 'react-bootstrap'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'

import { Card, Select, Title, Dropdown } from 'src/components'

import {
  actionTypes,
  getDetailedExcelByWarehouse,
  getExcelByWarehouse,
  getWarehouseLite,
} from 'src/actions/warehouse.actions'
import {
  selectOwnWarehouseLite,
  selectWarehouseLite,
} from 'src/selectors/warehouse.selector'

import { loadingSelector } from 'src/selectors/loading.selector'

import { isAllowed, selectCurrentUserRoles } from 'src/selectors/modules.selector'

import { onSetLocalCategories } from 'src/actions/categorization.actions'

import WarehouseInventory from 'src/content/Warehouse/WarehouseInventory'
import { inventoriesPermissions } from 'src/enums/permissions'

const defaultValue = { label: '- No seleccionado -', value: null }

class Inventory extends Component {
  state = {
    warehouse: defaultValue,
  }

  componentDidMount() {
    const { resetCategories, roles, all } = this.props
    resetCategories()
    if (roles.length > 0) this.loadData(all)
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { loadingWarehouses, roles } = this.props
    const { allWarehouses, ownWarehouses, all } = next

    if (roles?.length === 0 && next?.roles?.length > 0) this.loadData(all)

    if (loadingWarehouses && !next.loadingWarehouses) {
      let ownWarehouse
      if (ownWarehouses.length > 0) ownWarehouse = ownWarehouses[0]
      else ownWarehouse = allWarehouses[0]
      if (ownWarehouse) this.setState({ warehouse: ownWarehouse })
    }
  }

  loadData = all => {
    const { getWarehouseLite } = this.props
    getWarehouseLite(all)
  }

  getButtons = () => {
    const {
      canDownloadReport,
      loadingReport,
      getExcelByWarehouse,
      getDetailedExcelByWarehouse,
    } = this.props
    const { warehouse } = this.state

    return (
      <Row className={'container-buttons'} style={{ alignItems: 'center' }}>
        {canDownloadReport && (
          <Dropdown
            style={{ textTransform: 'none' }}
            tooltip={'Exportar'}
            loading={loadingReport}
            items={[
              {
                title: 'Exportar inventario',
                tooltip: 'Exporta un excel con el inventario',
                action: () => getExcelByWarehouse(warehouse.value),
                icon: faFileExcel,
                iconColor: 'green',
              },
              {
                title: 'Exportar inventario desglosado por producto',
                tooltip: 'Exporta un excel con el inventario desglosado por producto',
                action: () => getDetailedExcelByWarehouse(warehouse.value),
                icon: faFileExcel,
                iconColor: 'green',
              },
            ]}
          />
        )}
      </Row>
    )
  }

  render() {
    const { allWarehouses, loadingWarehouses } = this.props
    const { warehouse } = this.state

    return (
      <div>
        <Title title={'Inventario'} />
        <Row>
          <Col>
            <Card title={'Listado de inventario'} white button={this.getButtons()}>
              {allWarehouses.length === 0 && (
                <h3 style={{ margin: '0 auto' }}>No tiene ninguna bodega asignada</h3>
              )}
              <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Select
                    label={'Bodegas'}
                    loading={loadingWarehouses}
                    options={allWarehouses}
                    value={warehouse}
                    onChange={data => this.setState({ warehouse: data })}
                  />
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <WarehouseInventory id={warehouse.value} isInventory />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  allWarehouses: selectWarehouseLite(state),
  ownWarehouses: selectOwnWarehouseLite(state),

  loadingWarehouses: loadingSelector([actionTypes.GET_WAREHOUSE_LITE])(state),
  roles: selectCurrentUserRoles(state),
  all: isAllowed(state, [inventoriesPermissions.seeAll]),
  loadingReport: loadingSelector([
    actionTypes.GET_EXCEL_BY_WAREHOUSE,
    actionTypes.GET_DETAILED_EXCEL_BY_WAREHOUSE,
  ])(state),
  canDownloadReport: isAllowed(state, [inventoriesPermissions.downloadReport]),
})

const mapDispatchToProps = dispatch => ({
  getWarehouseLite: all => dispatch(getWarehouseLite({ all }, true)),
  resetCategories: () => dispatch(onSetLocalCategories([])),
  getExcelByWarehouse: id => dispatch(getExcelByWarehouse(id)),
  getDetailedExcelByWarehouse: id => dispatch(getDetailedExcelByWarehouse(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Inventory)
