const clientsOptions = [
  { id: 1, label: 'Clientes' },
  { id: 2, label: 'Items' },
  { id: 3, label: 'Ventas' },
  { id: 4, label: 'Proveedores' },
]

const dataTypeOptions = [
  { id: 1, label: 'Texto' },

  { id: 2, label: 'Selector' },

  { id: 3, label: 'Switch' },

  { id: 4, label: 'Numero' },
]

export enum dynamicFieldsPermissions {
  viewDynamicFields = 2151,
  createDynamicFields = 2152,
  editDynamicFields = 2153,
  deleteDynamicFields = 2154,
  createDynamicFieldsValue,
  editDynamicFieldsValue,
  deleteDynamicFieldsValue,
  DynamicFieldsValue,
}

export enum dynamicEntityType {
  CLIENTS = 1,
  ITEMS = 2,
  SALES = 3,
  PROVIDERS = 4,
}

export { clientsOptions, dataTypeOptions }
