import { $http } from './http'
import axios from 'axios'

class PrinterService {
  getAll = async tableId => {
    const response = await $http.get('/prints', { params: { tableId } })
    return response.data
  }

  createPrint = async print => {
    const response = await $http.post('/prints', print)
    return response.data
  }

  updatePrint = async print => {
    const response = await $http.put('/prints', print)
    return response.data
  }

  onUpdateCorrelativePrint = async () => {
    const response = await $http.put('/prints/correlative')
    return response.data
  }

  getPrint = async print => {
    const response = await $http.get('/prints/'.concat(print))
    return response.data
  }

  assignTablesPrint = async (printId, tables) => {
    const response = await $http.put('/prints/assign/table/'.concat(printId), tables)
    return response.data
  }

  deletePrinter = async printId => {
    const response = await $http.delete(`/prints/${printId}`)
    return response.data
  }

  sendPrintToLocalhost = async (request, host) => {
    const $http_localhost = axios.create({
      baseURL: `https://${host}:6744`,
      headers: { 'content-type': 'application/json' },
    })

    const response = await $http_localhost.post('/print', request)
    return response.data
  }
}
export default new PrinterService()
