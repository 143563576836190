import React, {Component} from 'react';
import {connect} from "react-redux"

import {loadingSelector} from "../../../selectors/loading.selector";

import Card from "../../../components/cards/Card";

import {Row, Col} from "react-bootstrap";
import {Td, Tr} from "react-super-responsive-table";
import {actionTypes as types,getDeliverReport} from "../../../actions/orders.actions";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import {selectDelivererReport} from "../../../selectors/orders.selector";
import moment from "moment";
import { selectCurrentCurrency } from '../../../selectors/currencies.selector';
import { TableV2 } from '../../../components';
import { toMoney } from '../../../utils/utilities';

class Deliverers extends Component {

    state ={
        startDate: new Date(),
        endDate: new Date()
    }

    componentDidMount() {
        const {startDate, endDate} = this.state
        this.getDeliverers(startDate, endDate)
    };

    getDeliverers(startDate, endDate){
        let sDate = moment.utc(startDate).hour(0).minute(0).hour(0).valueOf();
        let eDate = moment.utc(endDate).hour(23).minute(59).hour(59).valueOf();
        this.props.getDeliverers(sDate, eDate);
    }


    getHeaders = ()=> {
        return [
            
            {label: 'ID', show: true, value: ['id'], type: 'text' ,  className:'mini'},
            {label: 'Nombre', show: true, value: ['name'], type: 'text' ,  className:'mini'},
            {label: 'Email', show: true, value: ['detail','length'], type: 'wholeNumber' ,  className:'mini'},
            {label: 'Ordenes Entregadas', show: true, value: ['amount'], type: 'text' ,  className:'mini'},
            {label: 'Cobro por transporte', show: true, value: ['amount'], type: 'text' ,  className:'mini',
            custom:(item)=> item.cobroC =item.detail ? toMoney(Number(this.getTotalRate(item.detail))) : toMoney(0) },
            {label: 'Gastos', show: true, value: ['gastosC'], type: 'text' ,  className:'mini',
            custom:(item)=> item.gastosC = item.expenses ? toMoney(Number(this.getTotalExpenses(item.expenses))) : toMoney(0)},
            {label: 'Depósitos', show: true, value: ['depositoC'], type: 'text' ,  className:'mini',
            custom:(item)=> item.depositoC = item.payments ? toMoney(Number(this.getTotalPayments(item.payments))) : toMoney(0)},
            {config:true, show: true, label:'', className:'mini center'}
        ]
    }

    getTotalRate = (details) =>{
        let total = 0;
        details.map(d =>{total += d.rate;})
        return total;
    }

    getTotalExpenses = (expenses) =>{
        let total =0;
        expenses.map(e =>{total += e.total})
        return total
    }

    getTotalPayments = (payments) =>{
        let total =0;
        payments.map(p =>{total += p.paymentTotal;})
        return total
    }

    onDateFromChange = date => {
        const {endDate} = this.state
        if(date){
            this.setState({startDate: date});
            this.getDeliverers(date, endDate)
        }
    };

    onDateToChange = date => {
        const {startDate} = this.state
        if(date){
            this.setState({endDate: date});
            this.getDeliverers(startDate, date)
        }
    };

    render() {
        const {deliverers, loading, currentCurrency} = this.props;
        const {startDate, endDate} = this.state
        return (
            <div>
                <h1 className={'dashboard-welcome'}>Repartidores</h1>
                <Row>
                    <Col md={3}>
                        <Card title={'Filtros'}>
                            <Row>
                                <Col xs={12} sm={12}>
                                    <CustomDatePicker
                                        disabled={false}
                                        label={'Desde:'}
                                        value={startDate}
                                        onDayChange={this.onDateFromChange}
                                    />
                                </Col>
                                <Col xs={12} sm={12}>
                                    <CustomDatePicker
                                        disabled={false}
                                        label={'Hasta:'}
                                        value={endDate}
                                        onDayChange={this.onDateToChange}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col md={9}>
                        <Card title={'Repartidores'}>
                            <TableV2
                                items={deliverers}
                                loading={loading}
                                headers={this.getHeaders()}
                                mobileAuto
                                storageKey={`deliverers`}

                            />
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    deliverers: selectDelivererReport(state),
    loading: loadingSelector([types.GET_DELIVERER_REPORT])(state),
    currentCurrency: selectCurrentCurrency(state)
});

const mapDispatchToProps = dispatch => ({
    getDeliverers: (sDate, eDate) => dispatch(getDeliverReport(sDate, eDate))
});

export default connect(mapStateToProps, mapDispatchToProps)(Deliverers);
