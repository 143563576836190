import React, {Component} from 'react';
import {connect} from "react-redux"

import {
    actionTypes as types,
    getContraOffersByProducer,
    getSingleOffer,
    onAcceptProducerContraOffer
} from "../../../../actions/offers.actions";
import {
    selectContraOffers,
    selectOffer
} from "../../../../selectors/offers.selector";

import {loadingSelector} from "../../../../selectors/loading.selector";
import {hasErrorsSelector, singleErrorSelector} from "../../../../selectors/error.selector";

import Card from "../../../../components/cards/Card";
import IconButton from "../../../../components/buttons/IconButton";
import Button from "../../../../components/buttons/Button";

import {formatNumberWithCommas} from "../../../../utils/formatters";

import {Row, Col, Modal} from "react-bootstrap";
import {Td, Tr} from "react-super-responsive-table";
import {faFileAlt} from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert-react";
import Money from '../../../../components/Money/Money';
import { TableV2 } from '../../../../components';

class ContraOfferDetails extends Component {

    state = {
        productDetails: {
            show: false,
            productPriceDetail: []
        },
        alert: {
            title: 'default',
            text: 'default',
            type: 'info',
            show: false
        }
    };

    componentWillReceiveProps(props) {
        const {loadingAccept, hasError} = this.props;
        const {error} = props;
        if (loadingAccept && !props.loadingAccept) {
            let {alert} = this.state;
            if (!hasError && props.hasError) {
                alert = this.handlerError(error.response ? error.response.data.message : 'Ha ocurrido un error inesperado.');
                alert.onConfirm = () => this.setState({alert: {...alert, show: false}});
            } else {
                alert = this.handlerSuccess('La oferta ha sido aceptada satisfactoriamente');
                alert.onConfirm = () => {
                    this.setState({alert: {...alert, show: false}});
                    window.history.back();
                };
            }
            this.setState({alert});
        }
    };

    handlerError  = (message) => {
        return {show: true, title: 'Uh-Oh..', text: message, type: 'warning'};
    };

    handlerSuccess = (message) => {
        return {show: true, title: 'Yeah!', text: message, type: 'success'};
    };

    componentDidMount() {
        const {id} = this.props.match.params;
        const {getOffer, getContraOffers} = this.props;
        getOffer(id);
        getContraOffers(id);
    };

    getProductList = (prices, products) => {
        if (prices.length > 0 && products.length > 0) {
            // offerProductId
            const productList = []
            products.map((p) => {
                let product = {id: p.id, name: p.productName, productPriceDetail: []};
                const productPriceDetail = prices.filter((l) => l.offerProductId === p.id)
                product.productPriceDetail = productPriceDetail;
                productList.push(product);
            });
            return  productList;
        } else return [];
    };

    getContraOffer = () => {
        const {contraOffers, offer} = this.props;
        const id = this.props.match.params.contraOffer;
        const contraOffer = contraOffers.find((c) => c.id === parseInt(id));
        if (contraOffer) {
            contraOffer.productList = this.getProductList(contraOffer.priceList, offer.productList);
            return contraOffer;
        }
        else return {productList: []};
    };

    seePricesDetails = (item) => {
        const productDetails = Object.assign({}, {...item, show: true});
        this.setState({productDetails})
    };

    renderRow = (item, index) => {
        return (
            <Tr className={'data'} key={index}>
                <Td className={'medium'}>
                    {item.name}
                </Td>
                <Td className={'short center'}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                        <IconButton onClick={() => this.seePricesDetails(item)} tooltip={'Ver precios'} icon={faFileAlt}/>
                    </div>
                </Td>
            </Tr>
        );
    };

    render() {
        const {loading, loadingContra, loadingAccept} = this.props;
        const {productDetails, alert} = this.state;
        const contraOffer = this.getContraOffer();
        return (
            <div style={{marginBottom: 50}}>
                <Row>
                    <Col md={3}>
                        <Row>
                            <Col md={12}>
                                <Card title={'Detalles'}>
                                    <div>
                                        <Row style={{justifyContent: 'center'}}>
                                            <span className={'client-name'}>Contra oferta</span>
                                        </Row>
                                        <Row className={'flex-container space-between'}>
                                            <Col className={'label'}>Solicitado por:</Col>
                                            <Col className={'description'}>{contraOffer.shipperName || 'Desconocido'}</Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                            <Col md={12}>
                                <Row>
                                    <Col>
                                        <Button loading={loadingAccept} className={'big-button'} onClick={() => this.props.accept(contraOffer.id)}> Aceptar</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Card title={'Productos'}>
                            <TableV2
                                headers={[
                                    { label: 'Producto', show: true, value: ['name'], type: 'text', className: 'medium' },
                                    {
                                        show: true, label: 'Acciones', className: 'short center', select:true,
                                        custom: (item) => <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                            <IconButton onClick={() => this.seePricesDetails(item)} tooltip={'Ver precios'} icon={faFileAlt} />
                                        </div>
                                    },
                                    {config:true, show: true, label:'', className:'mini'}

                                ]}
                                loading={loadingContra || loading}
                                items={contraOffer.productList}
                                mobileAuto
                                storageKey={'contraOfferDetail'}
                            />
                        </Card>
                    </Col>
                </Row>

                <Modal
                    show={productDetails.show}
                    size={'lg'}
                    centered
                    onHide={() => this.setState({productDetails: {...productDetails, show: false}})}>

                    <Modal.Header closeButton>
                        <Modal.Title>Precios de {productDetails.productName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {
                                productDetails.productPriceDetail.map((p) =>
                                    <Col md={4} key={p.id}>
                                        <div>
                                            <Row style={{justifyContent: 'center'}}>
                                                <Money component='span' className={'client-name'}>{p.price}</Money>
                                            </Row>
                                            <hr style={{margin: 0}}/>
                                            <Row className={'flex-container space-between'}>
                                                <Col className={'label'}>Uniddes minimas:</Col>
                                                <Col className={'description'} style={{textAlign: 'right'}}>{p.minimum}</Col>
                                            </Row>
                                            <Row className={'flex-container space-between'}>
                                                <Col className={'label'}>Uniddes maximas</Col>
                                                <Col className={'description'} style={{textAlign: 'right'}}>{p.maximum}</Col>
                                            </Row>
                                        </div>
                                    </Col>
                                )
                            }
                        </Row>
                    </Modal.Body>
                </Modal>

                <SweetAlert {...alert}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    offer: selectOffer(state),
    contraOffers: selectContraOffers(state),
    loading: loadingSelector([types.GET_SINGLE_OFFER])(state),
    loadingContra: loadingSelector([types.GET_ALL_CONTRA_OFFER])(state),
    loadingAccept: loadingSelector([types.ON_ACCEPT_PRODUCER_CONTRA_OFFER])(state),
    hasError: hasErrorsSelector([types.ON_ACCEPT_PRODUCER_CONTRA_OFFER])(state),
    error: singleErrorSelector([types.ON_ACCEPT_PRODUCER_CONTRA_OFFER])(state)
});
const mapDispatchToProps = dispatch => ({
    getOffer: (id) => dispatch(getSingleOffer(id)),
    getContraOffers: (id) => dispatch(getContraOffersByProducer(id)),
    accept: (id) => dispatch(onAcceptProducerContraOffer(id))
});
export default connect(mapStateToProps, mapDispatchToProps)(ContraOfferDetails);
