import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SweatAlert from 'sweetalert-react'

import { hideAlert } from 'src/actions/alert.actions'

/**
 * Componente para renderizar una Alert global
 * @component
 * */
const Alert = () => {
  const dispatch = useDispatch()

  const {
    show,
    title,
    text,
    type,
    onConfirm,
    onCancel,
    showCancelButton,
    cancelButtonText,
    showConfirmButton,
    confirmButtonText,
    confirmButtonColor,
    inputPlaceholder,
    // @ts-ignore
  } = useSelector(state => state.alert)

  const onClose = () => {
    dispatch(hideAlert())
  }

  const handleAccept = inputValue => {
    if (onConfirm) onConfirm(inputValue)
    onClose()
  }

  const handleCancel = () => {
    if (onCancel) onCancel()
    onClose()
  }

  return (
    <div>
      <SweatAlert
        show={show}
        title={title}
        type={type}
        text={text}
        showCancelButton={showCancelButton}
        cancelButtonText={cancelButtonText}
        showConfirmButton={showConfirmButton}
        confirmButtonText={confirmButtonText}
        confirmButtonColor={confirmButtonColor}
        inputPlaceholder={inputPlaceholder}
        onConfirm={inputValue => handleAccept(inputValue)}
        onCancel={showCancelButton ? () => handleCancel() : undefined}
      />
    </div>
  )
}
export default Alert
