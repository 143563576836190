import { $http } from './http'

class LogsService {
  getLogProducts = async () => {
    const response = await $http.get(`/logs/products`)
    return response.data
  }

  getLogCompanies = async () => {
    const response = await $http.get(`/logs/companies`)
    return response.data
  }

  getLogWarehouses = async () => {
    const response = await $http.get(`/logs/warehouses`)
    return response.data
  }

  getLogShopping = async () => {
    const response = await $http.get(`/logs/shopping`)
    return response.data
  }
}
export default new LogsService()
