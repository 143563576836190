import React, { Component } from 'react'
import './FilterInput.scss'
import FormTextField from '../FormTextField/FormTextField'

class FilterInput extends Component {
  state = {
    value: '',
  }

  handleChange = e => {
    const value = e.target.value
    this.setState({ value })
    this.props.onChange(value)
  }

  render() {
    return (
      <div>
        <label className={'form-label'} htmlFor="filter">
          Buscar{' '}
        </label>
        <FormTextField
          type="text"
          id="filter"
          placeholder={'Buscar por correo'}
          value={this.state.value}
          onChange={this.handleChange}
        />
      </div>
    )
  }
}
export default FilterInput
