import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import { CustomFilter, Button, Paragraph, Icon } from 'src/components'

import { selectProductsSimple } from 'src/selectors/products.selector'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { haveAnyValue } from '../../../utils/utilitiesV2'

interface Props {
  show: boolean
  title: string
  onHide: () => void
  selected: IRecipeItem[]
  addItem: (item: IRecipeItem) => void
}
/**
 * Shows a list of items available to assign to the recipe
 * @param {Object} props - Component props
 * @param {boolean} props.show - Open modal component
 * @param {string} props.title - Title of section to select items
 * @param {function} props.onHide - Close modal component
 * @param {IRecipe[]} props.selected - List of items selected used to filter items
 * @param {function} props.addItem - Callback with item selected
 * */

const RecipeItemsSelection = ({ show, title, selected, onHide, addItem }: Props) => {
  const items: IItem[] = useSelector(selectProductsSimple)

  const [search, setSearch] = useState<string>(undefined)

  useEffect(() => {
    if (!show) return
    setSearch(undefined)
  }, [show])

  const filterItem = (item: IItem): boolean => {
    const index = selected.findIndex(i => i.product === item.id)
    if (index > -1) return false
    if (!haveAnyValue(search)) return true
    return Object.values({ code: item.code, name: item.name }).some(value => {
      return value.toLowerCase().includes(search.toLowerCase())
    })
  }

  return (
    <Modal show={show} centered onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Selección de ítems para {title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'custom-modal-body'}>
        <Row>
          <Col xl={12}>
            <CustomFilter
              search={search}
              placeHolder={'Buscar'}
              onChange={({ target }) => setSearch(target.value)}
            />
          </Col>
          <Col xl={12}>
            <Row>
              {items.filter(filterItem).map(item => (
                <Col xl={12} key={item.id} className={'b-item'}>
                  <div className={'d-flex'}>
                    <div className={'column'} style={{ width: '93%' }}>
                      <Paragraph dim>{item.code}</Paragraph>
                      <Paragraph>{item.name}</Paragraph>
                    </div>
                    <div
                      style={{
                        width: '7%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Icon
                        tooltip={'Seleccionar'}
                        icon={faPlusCircle}
                        size={'1_5x'}
                        onClick={() =>
                          addItem({
                            product: item.id,
                            name: item.name,
                            code: item.code,
                            decimals: item.decimals,
                            quantity: 0,
                          })
                        }
                      />
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className={'container-buttons'}>
          <Button color={'secondary'} onClick={() => onHide()}>
            Cerrar
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}
export default RecipeItemsSelection
