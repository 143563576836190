import React from 'react'

import { TableV2, Icon } from 'src/components'
import { faBook } from '@fortawesome/free-solid-svg-icons'
import { haveAnyValue } from 'src/utils/utilitiesV2'

interface IOrderDetailItemsProps {
  items: IOrderDetailsItem[]
  viewDetail?: (item: IOrderDetailsItem) => void
}

const OrderDetailItems = ({ items = [], viewDetail }: IOrderDetailItemsProps) => {
  const headers: ITableHeader[] = [
    { label: 'Código', show: true, value: ['code'], type: 'text' },
    { label: 'Estado', show: true, value: ['name'], type: 'text' },
    { label: 'Fecha', show: true, value: ['commentary'], type: 'text' },
    { label: 'Monto abonado', show: true, value: ['total'], type: 'currency' },
    {
      config: true,
      label: '',
      show: haveAnyValue(viewDetail),
      value: [],
      type: 'text',
      custom: item => (
        <Icon
          tooltip={'Ver detalle'}
          icon={faBook}
          size={'lg'}
          onClick={() => viewDetail(item)}
        />
      ),
    },
  ]

  return (
    <TableV2
      // @ts-ignore
      items={items}
      headers={headers}
      mobileAuto
    />
  )
}
export default OrderDetailItems
