import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import { Button, FormText } from 'src/components'

import {
  actionTypes,
  createInvoiceConcept,
  getOrderInvoiceConcepts,
  getPosInvoiceConcepts,
  updateInvoiceConcept,
} from 'src/actions/restaurant.actions'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { showAlert } from 'src/actions/alert.actions'
import CustomSelect from 'src/components/inputs/Select/CustomSelect'
import { selectFelItemTypes } from 'src/selectors/company.selector'
import { selectModalCreateConcepts } from 'src/selectors/utilities.selector'
import { setModalCreateConcepts } from 'src/actions/utilities.actions'

interface IActions {
  create?: boolean
  update?: boolean
}

const defaultConcept: IInvoiceConcept = {
  name: '',
  felItemType: null,
}

export interface IModalCreateConcept {
  show: boolean
  update?: boolean
  concept: IInvoiceConcept
}

const defaultModalCreate: IModalCreateConcept = {
  show: false,
  update: false,
  concept: { name: '', felItemType: null, posId: null },
}

/**
 * @component ModalCreateConcept
 * @description Component to create POS invoice concepts via modal
 */
const ModalCreateConcept = () => {
  const dispatch = useDispatch()

  const {
    show,
    update,
    concept = {
      name: null,
      felItemType: null,
    },
  }: IModalCreateConcept = useSelector(selectModalCreateConcepts)
  const felItemTypes = useSelector(selectFelItemTypes)

  const loadingCreate = useSelector(state =>
    loadingSelector([actionTypes.CREATE_INVOICE_CONCEPT])(state),
  )
  const hasErrorCreate = useSelector(state =>
    hasErrors([actionTypes.CREATE_INVOICE_CONCEPT])(state),
  )

  const loadingUpdate = useSelector(state =>
    loadingSelector([actionTypes.UPDATE_INVOICE_CONCEPT])(state),
  )
  const hasErrorUpdate = useSelector(state =>
    hasErrors([actionTypes.UPDATE_INVOICE_CONCEPT])(state),
  )

  const [actions, setActions] = useState<IActions>({})
  const [newConcept, setNewConcept] = useState<IInvoiceConcept>(defaultConcept)

  useEffect(() => {
    setNewConcept(concept)
  }, [show])

  useEffect(() => {
    if (loadingCreate) setActions({ ...actions, create: true })
    else if (actions.create) {
      setActions({ ...actions, create: false })

      if (hasErrorCreate)
        dispatch(
          showAlert({
            ...handlerError('No se pudo crear el concepto de facturación'),
          }),
        )
      else {
        dispatch(
          showAlert({
            ...handlerSuccess('Concepto de facturación creado con éxito'),
            confirmButtonText: 'Cerrar',
            onConfirm: () => {
              dispatch(setModalCreateConcepts(defaultModalCreate))
              getConcepts()
            },
          }),
        )
      }
    }
  }, [loadingCreate])

  useEffect(() => {
    if (loadingUpdate) setActions({ ...actions, update: true })
    else if (actions.update) {
      setActions({ ...actions, update: false })

      if (hasErrorUpdate)
        dispatch(
          showAlert({
            ...handlerError('No se pudo actualizar el concepto de facturación'),
          }),
        )
      else {
        dispatch(
          showAlert({
            ...handlerSuccess('Concepto de facturación actualizado con éxito'),
            confirmButtonText: 'Cerrar',
            onConfirm: () => {
              dispatch(setModalCreateConcepts(defaultModalCreate))
              getConcepts()
            },
          }),
        )
      }
    }
  }, [loadingUpdate])

  const getConcepts = () =>
    dispatch(
      concept.posId
        ? getPosInvoiceConcepts(concept.posId, true)
        : getOrderInvoiceConcepts(true),
    )

  return (
    <Modal
      show={show}
      centered
      onHide={() => dispatch(setModalCreateConcepts(defaultModalCreate))}>
      <Modal.Header closeButton>
        <Modal.Title>{update ? 'Actualizar' : 'Crear'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <FormText
              label={'Concepto de facturación'}
              type={'text'}
              placeholder={
                concept.posId ? 'Por consumo de alimentos' : 'Por venta de suministros'
              }
              name={'concept'}
              value={newConcept.name}
              onChange={e => setNewConcept({ ...newConcept, name: e.target.value })}
              required
            />
          </Col>
          <Col xs={12}>
            <CustomSelect
              label={'Bien o Servicio'}
              info={'La propiedad "Bien o Servicio" se usará para la factura electrónica'}
              options={felItemTypes}
              value={felItemTypes.find(fel => fel.value === newConcept.felItemType)}
              onChange={item => setNewConcept({ ...newConcept, felItemType: item.value })}
              required
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className={'container-buttons'}>
          <Button
            loading={loadingCreate || loadingUpdate}
            icon={faSave}
            onClick={() => {
              const action = update ? updateInvoiceConcept : createInvoiceConcept
              dispatch(action(newConcept))
            }}
            dataCy={'concept-create-button'}>
            {update ? 'Actualizar' : 'Crear'}
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalCreateConcept
