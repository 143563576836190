import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  actionTypes as actOrders,
  getOrderBalanceByCustomers,
} from '../../../actions/orders.actions'
import {
  selectGetOrdersWithBalance,
  selectGetAllOrderStatus,
} from '../../../selectors/orders.selector'

import { loadingSelector } from '../../../selectors/loading.selector'

import { formatNumberWithCommas } from '../../../utils/formatters'

import { Col, Row } from 'react-bootstrap'
import { Td, Tr } from 'react-super-responsive-table'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'

import Card from '../../../components/cards/Card'
import IconButton from '../../../components/buttons/IconButton'
import Money from '../../../components/Money/Money'
import { selectCurrentCurrency } from '../../../selectors/currencies.selector'
import { TableV2 } from '../../../components'

class CPCDashboard extends Component {
  componentDidMount() {
    const { id } = this.props.match.params
    this.props.getOrders(id)
  }

  goTo = item => {
    const { match } = this.props
    this.props.history.push(`${match.url}/orden/detalle/${item.orderId}`)
  }

  getStatus = id => {
    const { listStatus } = this.props
    let status = 'cargando...'
    listStatus.filter(s => s.id === id).map(s => (status = s.name))
    return status
  }
  renderRow = (item, index) => {
    return (
      <Tr className={'data'} key={index}>
        <Td>{item.number}</Td>
        <Td className={'short currency'}>{formatNumberWithCommas(item.total)}</Td>
        <Td className={'short currency'}>{formatNumberWithCommas(item.balance)}</Td>
        <Td>{this.getStatus(item.status)}</Td>
        <Td className={'short center'}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
            }}>
            {' '}
            <IconButton
              tooltip={'Detalles'}
              icon={faFileAlt}
              onClick={() => this.goTo(item)}
            />
          </div>
        </Td>
      </Tr>
    )
  }

  render() {
    const { ordersWithBalance, balanceIsLoading, currentCurrency } = this.props

    return (
      <div>
        <h1 className={'dashboard-welcome'}>Cuentas por cobrar</h1>
        <Row>
          <Col md={4}>
            <Row>
              <Col md={12}>
                <Card title={'Información'}>
                  <div className={'flex-container space-between'}>
                    <span>
                      <strong>Balance</strong>
                    </span>
                    <Money component="span">{ordersWithBalance.totalBalance}</Money>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col>
            <Card title={'Ordenes pendientes'}>
              <TableV2
                headers={[
                  {
                    label: 'Código',
                    show: true,
                    value: ['number'],
                    type: 'text',
                    className: 'mini',
                  },
                  {
                    label: 'Total',
                    show: true,
                    value: ['total'],
                    type: 'currency',
                    className: 'mini',
                  },
                  {
                    label: 'Pendiente',
                    show: true,
                    value: ['balance'],
                    type: 'currency',
                    className: 'mini',
                  },
                  {
                    label: 'Estado',
                    show: true,
                    value: ['estadoC'],
                    type: 'text',
                    className: 'mini',
                    custom: item => (item.estadoC = this.getStatus(item.status)),
                  },
                  {
                    config: true,
                    show: true,
                    label: '',
                    className: 'mini center',
                    custom: item => (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-evenly',
                        }}>
                        <IconButton
                          tooltip={'Detalles'}
                          icon={faFileAlt}
                          onClick={() => this.goTo(item)}
                        />
                      </div>
                    ),
                  },
                ]}
                items={ordersWithBalance.orders}
                loading={balanceIsLoading}
                noItemsLegend={'No hay ordenes pendientes de pago'}
                mobileAuto
                storageKey={`cpcDash`}
              />
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  listStatus: selectGetAllOrderStatus(state),
  ordersWithBalance: selectGetOrdersWithBalance(state),
  balanceIsLoading: loadingSelector([actOrders.GET_ORDER_BALANCE])(state),
  currentCurrency: selectCurrentCurrency(state),
})

const mapDispatchToProps = dispatch => ({
  getOrders: id => dispatch(getOrderBalanceByCustomers(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CPCDashboard)
