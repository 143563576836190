import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Row, Col } from 'react-bootstrap'
import { Title, Card, TableV2, Button, Icon } from 'src/components'
import { faStar, faTrash } from '@fortawesome/free-solid-svg-icons'
import Alert from 'sweetalert-react'

import {
  actions as typeA,
  getCards,
  createCard,
  deleteCard,
  favoriteCard,
} from 'src/actions/account.actions'
import { actionTypes as typeI, getInvoices } from 'src/actions/subscriptions.actions'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerSuccess,
  handlerError,
  singleErrorSelector,
  hasErrorsSelector,
} from 'src/selectors/error.selector'

import { selectSetCurrentCompany } from 'src/selectors/company.selector'

import ModalCreateCard from './ModalCreateCard'
import Subscription from '../../Subscription/Subscription'

import { formatDateFromMillis, formatNumberWithCommas } from 'src/utils/formatters'

const Account = ({ match }) => {
  const dispatch = useDispatch()

  const company = useSelector(selectSetCurrentCompany)

  const invoices = useSelector(state => state.subscriptions.invoices)
  const loadingI = useSelector(state => loadingSelector([typeI.GET_INVOICES])(state))

  const cards = useSelector(state => state.account.cards)
  const loading = useSelector(state => loadingSelector([typeA.GET_CARDS])(state))

  const loadingCC = useSelector(state => loadingSelector([typeA.CREATE_CARD])(state))
  const hasErrorCC = useSelector(state => hasErrorsSelector([typeA.CREATE_CARD])(state))
  const errorCC = useSelector(state => singleErrorSelector([typeA.CREATE_CARD])(state))

  const loadingCD = useSelector(state => loadingSelector([typeA.DELETE_CARD])(state))
  const hasErrorCD = useSelector(state => hasErrorsSelector([typeA.DELETE_CARD])(state))
  const errorCD = useSelector(state => singleErrorSelector([typeA.DELETE_CARD])(state))

  const loadingCF = useSelector(state => loadingSelector([typeA.FAVORITE_CARD])(state))
  const hasErrorCF = useSelector(state => hasErrorsSelector([typeA.FAVORITE_CARD])(state))
  const errorCF = useSelector(state => singleErrorSelector([typeA.FAVORITE_CARD])(state))

  const [actions, setActions] = useState({
    cardCreate: false,
    cardDelete: false,
    cardFavorite: false,
  })
  const [showCardCreate, setCardCreate] = useState(false)
  const [alert, setAlert] = useState({ title: '' })
  const [cId, setCId] = useState(null)

  useEffect(() => {
    dispatch(getCards())
    dispatch(getInvoices())
  }, [])

  useEffect(() => {
    if (loadingCC) setActions({ ...actions, cardCreate: true })
    else if (actions.cardCreate) {
      setActions({ ...actions, cardCreate: false })
      if (hasErrorCC)
        setAlert({
          ...handlerError(errorCC.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Tarjeta de crédito registrada satisfactoriamente'),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
    }
  }, [loadingCC])

  useEffect(() => {
    if (loadingCD) setActions({ ...actions, cardDelete: true })
    else if (actions.cardDelete) {
      setActions({ ...actions, cardDelete: false })
      setCId(null)
      if (hasErrorCD)
        setAlert({
          ...handlerError(errorCD.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Tarjeta de crédito eliminada satisfactoriamente'),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
    }
  }, [loadingCD])

  useEffect(() => {
    if (loadingCF) setActions({ ...actions, cardFavorite: true })
    else if (actions.cardFavorite) {
      setActions({ ...actions, cardFavorite: false })
      setCId(null)
      if (hasErrorCF)
        setActions({
          ...handlerError(errorCF.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
    }
  }, [loadingCF])

  const deleteCardAction = card => {
    if (loadingCD) return
    setAlert({
      show: true,
      type: 'warning',
      title: `¿Desea eliminar la tarjeta ${card.number}? Está acción no podra ser revertida.`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Borrar',
      confirmButtonColor: '#B35796',
      onCancel: () => setAlert({ ...alert, show: false }),
      onConfirm: () => {
        setCId(card.id)
        setAlert({ ...alert, show: false })
        dispatch(deleteCard(card.id))
      },
    })
  }

  const favoriteCardAction = card => {
    if (loadingCF) return
    setCId(card.id)
    dispatch(favoriteCard(card.id))
  }

  return (
    <div>
      <Title title={'Estado de cuenta'} />
      <Row>
        <Col xl={4} lg={4} md={5} sm={12}>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12}>
              <Card title={'Mis tarjetas'} white>
                <Row>
                  <Col xl={12} lg={12} md={12}>
                    <h6>Gestiona las tarjetas disponibles para la cuenta</h6>
                  </Col>
                  <Col xl={12} lg={12} md={12}>
                    <TableV2
                      loading={loading}
                      items={cards}
                      noItemsLegend={'No hay tarjetas registradas'}
                      hideFilter
                      renderRow={(item, index) => (
                        <div className={'b-user-item'} key={index}>
                          <div
                            className={
                              'justify-content-start align-items-start bu-en left'
                            }>
                            <div className={'b-user-name'}>{item.alias}</div>
                            <div className={'b-user-email'}>{item.number}</div>
                            <div className={'b-user-balance-m'}>
                              <Icon
                                spin={loadingCD && cId === item.id}
                                tooltip={'Eliminar'}
                                icon={faTrash}
                                onClick={() => deleteCardAction(item)}
                              />
                            </div>
                          </div>
                          <div
                            className={'d-flex row align-items-center mr-2 bu-b right'}>
                            <Icon
                              spin={cId === item.id && loadingCF}
                              disabled={loadingCF && item.id !== cId}
                              icon={faStar}
                              color={item.favorite ? 'red' : null}
                              tooltip={
                                item.favorite
                                  ? 'Tarjeta de crédito predeterminada para pagos.'
                                  : 'Marcar esta tarjeta de crédito como predeterminada para pagos'
                              }
                              onClick={() => favoriteCardAction(item)}
                            />
                            <div className={'b-user-balance ml-2'}>
                              <Icon
                                disabled={loadingCF}
                                spin={loadingCD && cId === item.id}
                                tooltip={'Eliminar'}
                                icon={faTrash}
                                onClick={() => deleteCardAction(item)}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </Col>
                  <Col xl={12} lg={12} md={12}>
                    <Row className={'container-buttons'}>
                      <Button
                        disabled={loadingCD}
                        loading={loadingCC}
                        color={'primary'}
                        onClick={() => setCardCreate(true)}>
                        Nueva tarjeta
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12}>
              <Card title={'Facturas recientes'} white>
                <TableV2
                  customClass={'scroll-x-without-heigth'}
                  pSize={0}
                  items={invoices}
                  loading={loadingI}
                  noItemsLegend={'No hay facturas emitidas'}
                  renderRow={(item, index) => (
                    <div
                      className={'b-user-item'}
                      key={index}
                      onClick={() => {
                        const url = match.url + '/factura/' + item.order + '/0001'
                        window.open(url, '_blank')
                      }}>
                      <div
                        className={'justify-content-start align-items-start bu-en left'}>
                        <div className={'b-user-name'}>
                          {formatDateFromMillis(item.date)}
                        </div>
                        <div className={'b-user-email'}>
                          {item.authSeries ||
                            'Este documento no es una factura electrónica.'}
                        </div>
                        <div className={'b-user-balance-m'}>
                          Q.{formatNumberWithCommas(item.total)}
                        </div>
                      </div>
                      <div className={'d-flex row align-items-center mr-2 bu-b right'}>
                        <div className={'b-user-balance mr-2'}>
                          Q{formatNumberWithCommas(item.total)}
                        </div>
                      </div>
                    </div>
                  )}
                />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xl={8} lg={8} md={7} sm={12}>
          <Card title={'Suscripciones'} white>
            <Subscription mini queryParams={{ type: 2, id: company.clientId }} own />
          </Card>
        </Col>
      </Row>

      <ModalCreateCard
        create={showCardCreate}
        onHide={() => setCardCreate(false)}
        onCreate={({ cardNumber, cardName, ccv, expirationMM, expirationAA, alias }) => {
          const request = {
            name: cardName,
            number: cardNumber,
            cvv: ccv,
            expirationDate: `${expirationMM}/${expirationAA}`,
            alias,
          }
          dispatch(createCard(request))
        }}
      />

      <Alert {...alert} />
    </div>
  )
}
export default Account
