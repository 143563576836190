import React from 'react'
import { Line as ChartLine } from 'react-chartjs-2'
import { getLineChartConfig } from 'src/utils/getChartConfig'

interface Props {
  data: IDataGraph
  tooltips?: ITooltipGraph
  height?: number
}

/**
 * Line Chart component
 * @component
 * @param {IDataGraph} data List of items to display
 * @param {ITooltipGraph} tooltips Tooltip configuration
 * @param {number} height Height of the graph
 */
export const Line = ({ data, tooltips = {}, height = 150 }: Props) => {
  const chartOptions = getLineChartConfig()

  return <ChartLine data={data} height={height} options={{ ...chartOptions, tooltips }} />
}
