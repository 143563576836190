import React, { useEffect, useState } from 'react'

import { Row, Col } from 'react-bootstrap'
import { Icon, Button, CustomDatePickerWithHour, Select } from 'src/components'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const options = [
  { value: -1, label: 'Sin fecha' },
  { value: 0, label: 'Hoy' },
  { value: 1, label: 'Ayer' },
  { value: 2, label: 'Últimos 7 días' },
  { value: 3, label: 'Este mes' },
  { value: 4, label: 'El mes pasado' },
  { value: 5, label: 'Últimos 2 meses' },
  { value: 6, label: 'Últimos 3 meses' },
]

const SelectedDatesWithHour = ({
  show,
  start,
  end,
  onDateChange,
  initialDate,
  onApplyFilters,
}) => {
  const [dates, setDates] = useState({})
  const [customDate, setCustomDate] = useState({ value: -1, label: 'Sin fecha' })

  useEffect(() => {
    if (initialDate) onDateChange(start, end)
    setDates({ start, end })
  }, [show])

  const onChange = (s, e) => {
    let newStart = null
    let newEnd = null
    if (s) newStart = s.valueOf()
    if (e) {
      newEnd = end ? e.valueOf() : new Date(e).setHours(23, 59, 59, 59).valueOf()
    }
    setDates({ start: newStart, end: newEnd })
    setCustomDate({ value: -1, label: 'Sin fecha' })
  }

  const onChangeCustomDate = customDate => {
    let start = new Date()
    let end = new Date()

    switch (customDate.value) {
      case -1:
        start = null
        end = null
        break
      case 1:
        start.setDate(start.getDate() - 1)
        end.setDate(end.getDate() - 1)
        break
      case 2:
        start.setDate(start.getDate() - 7)
        break
      case 3:
        start = new Date(start.getFullYear(), start.getMonth(), 1)
        break
      case 4:
        start = new Date(start.getFullYear(), start.getMonth() - 1, 1)
        end = new Date(end.getFullYear(), end.getMonth(), 0)
        break
      case 5:
        start.setMonth(start.getMonth() - 2)
        break
      case 6:
        start.setMonth(start.getMonth() - 3)
        break
      default:
    }
    if (start) start = start.setHours(0, 0, 0, 0).valueOf()
    if (end) end = end.setHours(23, 59, 59, 59).valueOf()

    onChange(start, end)
    setCustomDate(customDate)
  }

  return (
    <div className={'mb-3'}>
      <Row>
        <Col xl={4} lg={4} md={6} sm={6} xs={12}>
          <CustomDatePickerWithHour
            label={'Desde'}
            value={dates.start}
            onChange={value => onChange(value, dates.end)}
          />
        </Col>
        <Col xl={4} lg={4} md={6} sm={6} xs={12}>
          <CustomDatePickerWithHour
            label={'Hasta'}
            value={dates.end}
            onChange={value => onChange(dates.start, value)}
          />
        </Col>
        <Col xl={4} lg={4} md={12} sm={12} xs={12} className={'d-flex'}>
          <Select
            value={customDate}
            label={'O'}
            options={options}
            onChange={value => onChangeCustomDate(value)}
            style={{ paddingRight: 20 }}
          />
          <div style={{ paddingTop: 47 }}>
            <Button onClick={() => onApplyFilters(dates.start, dates.end)}>
              <Icon icon={faSearch} tooltip={'Aplicar filtro de fecha'} />
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  )
}
export default SelectedDatesWithHour
