import React from 'react'

import { Col, Row } from 'react-bootstrap'
import { Icon, Paragraph } from 'src/components/index'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

import { haveAnyValue } from 'src/utils/utilitiesV2'

interface ISimpleListProps {
  items: ISimpleList[]
  onChange?: (item: ISimpleList) => void
  onDelete?: (item: ISimpleList) => void
  disabled?: boolean
}

const SimpleList = ({
  items,
  onChange,
  onDelete,
  disabled = false,
}: ISimpleListProps) => {
  const canUpdate: boolean = haveAnyValue(onChange)
  const canDelete: boolean = haveAnyValue(onDelete)
  const haveActions: boolean = canUpdate || canDelete

  return (
    <Row>
      {items.map(item => (
        <Col key={item.id} xl={4} lg={4} md={6} sm={12} xs={12} className={'mt-1'}>
          <div className={'b-item-content'}>
            <div style={{ display: 'flex' }} className={'b-item-content-size'}>
              <div className={'column'} style={{ width: haveActions ? '80%' : '100%' }}>
                <Paragraph>{item.title}</Paragraph>
                <Paragraph>{item.subtitle}</Paragraph>
              </div>
              {haveActions && (
                <div className={'column justify-content-end'} style={{ width: '20%' }}>
                  {canUpdate && (
                    <Icon
                      icon={faEdit}
                      color={'primary'}
                      tooltip={'Editar'}
                      onClick={() => onChange(item)}
                      disabled={disabled}
                    />
                  )}
                  {canDelete && (
                    <Icon
                      icon={faTrash}
                      color={'secondary'}
                      tooltip={'Eliminar'}
                      onClick={() => onDelete(item)}
                      disabled={disabled}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </Col>
      ))}
    </Row>
  )
}
export default SimpleList
