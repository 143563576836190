import VersionsService from '../services/versions.services'
import { executeAction } from './global.actions'

export const actionTypes = {
  GET_VERSIONS: 'GET_VERSIONS',
  GET_VERSION_DETAILS: 'GET_VERSION_DETAILS',
  GET_LAST_VERSION: 'GET_LAST_VERSION',
  SAVE_NEW_VERSION: 'SAVE_NEW_VERSION',
}

export const getAllVersions = () => dispatch => {
  const process = async () => ({
    allVersions: await VersionsService.getVersions(),
  })
  dispatch(executeAction(actionTypes.GET_VERSIONS, process))
}

export const getVersionDetails = versionId => dispatch => {
  const process = async () => ({
    id: versionId,
    versionDetails: await VersionsService.getVersionDetails(versionId),
  })
  dispatch(executeAction(actionTypes.GET_VERSION_DETAILS, process))
}

export const getLastVersion = () => dispatch => {
  const process = async () => ({
    lastVersion: await VersionsService.getLastVersion(),
  })
  dispatch(executeAction(actionTypes.GET_LAST_VERSION, process))
}

export const saveNewVersion = version => dispatch => {
  const process = async () => {
    const versionResponse = await VersionsService.saveNewVersion(version)
    dispatch(getAllVersions())
    dispatch(getLastVersion())
    return { versionResponse }
  }
  dispatch(executeAction(actionTypes.SAVE_NEW_VERSION, process))
}
