import React from 'react'
import './Input.scss'

const SIZES = ['sm', 'md', 'lg']
const DEFAULT_SIZE = 'md'

const Input = ({
  id,
  name,
  type,
  placeholder,
  value,
  onChange,
  disabled = false,
  className = '',
  style,
  containerStyle,
  size = DEFAULT_SIZE,
  maxLength,
  min,
  max,
  error,
  autoComplete,
  block = false,
  valid,
  pattern,
}) => {
  const checkSize = SIZES.includes(size) ? size : DEFAULT_SIZE

  return (
    <div
      className={`d-flex flex-column ${block ? 'flex-fill' : ''}`}
      style={containerStyle}>
      <input
        id={id}
        name={name}
        type={type}
        placeholder={type === 'search' && !placeholder ? 'Buscar' : placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        autoComplete={autoComplete}
        pattern={pattern}
        className={`input ${
          type === 'search' ? 'search-input' : ''
        } ${checkSize} ${className}`}
        maxLength={maxLength}
        min={min}
        max={max}
        style={style}
      />
      {(valid || error) && (
        <div className={`${valid && 'input-valid'} ${error && 'input-error'} `}>
          {valid || error}
        </div>
      )}
    </div>
  )
}
export default Input
