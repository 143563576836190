import { extract, successState } from '../actions/global.actions'
import { actions } from '../actions/account.actions'

interface IState {
  cards: ICreditCard[]
  card: ICreditCard | Record<string, never>
}

const initialState: IState = {
  cards: [],
  card: {},
}

const AccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actions.GET_CARDS):
      return { ...state, cards: extract(action.cards, []) }
    case successState(actions.GET_FAVORITE_CARD):
      return { ...state, card: extract(action.response, {}) }
    default:
      return state ? state : initialState
  }
}

export default AccountReducer
