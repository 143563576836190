import PlansService from '../services/plans.service'
import { executeAction, successState } from './global.actions'

export const actions = {
  GET_PLANS: 'GET_PLANS',
  GET_ACTIVE_PLAN: 'GET_ACTIVE_PLAN',
  GET_COMPANY_ACTIVE_PLAN: 'GET_COMPANY_ACTIVE_PLAN',
  ADD_USER: 'ADD_USER',
  DELETE_USER: 'DELETE_USER',
  GET_USER_PLAN: 'GET_USER_PLAN',
  UPDATE_PLAN: 'UPDATE_PLAN',
  UPDATE_PLAN_REQUEST: 'UPDATE_PLAN_REQUEST',
  ADD_DLC: 'ADD_DLC',
  GET_HISTORY: 'GET_HISTORY_PLAN_INVOICE',
  BUY_USER_SPACE: 'BUY_USER_SPACE',
  BUY_USER_SPACE_REQUEST: 'BUY_USER_SPACE_REQUEST',
  REMOVE_USER_SPACE: 'REMOVE_USER_SPACE',
  REMOVE_USER_SPACE_REQUEST: 'REMOVE_USER_SPACE_REQUEST',
  PLAN_PENDING_PAYMENT: 'PLAN_PENDING_PAYMENT',
}

export const getPlans = includeDeleted => dispatch => {
  const process = async () => ({
    response: await PlansService.getPlans({ includeDeleted }),
  })
  dispatch(executeAction(actions.GET_PLANS, process))
}

export const getActivePlan = () => dispatch => {
  const process = async () => ({
    response: await PlansService.getActivePlan(),
  })
  dispatch(executeAction(actions.GET_ACTIVE_PLAN, process))
}

export const getCompanyActivePlan = companyId => dispatch => {
  const process = async () => ({
    response: await PlansService.getCompanyActivePlan(companyId),
  })
  dispatch(executeAction(actions.GET_COMPANY_ACTIVE_PLAN, process))
}

export const setPlans = plans => dispatch => {
  dispatch({ type: successState(actions.GET_PLANS), response: plans })
}

export const setActivePlans = plan => dispatch => {
  dispatch({ type: successState(actions.GET_ACTIVE_PLAN), response: plan })
}

export const updatePlan = (id, request) => dispatch => {
  const process = async () => ({ response: await PlansService.updatePlan(id, request) })
  dispatch(executeAction(actions.UPDATE_PLAN, process))
}

export const updatePlanRequest =
  (id, request = {}) =>
  dispatch => {
    const process = async () => ({ response: await PlansService.updatePlan(id, request) })
    dispatch(executeAction(actions.UPDATE_PLAN_REQUEST, process))
  }

export const updateCompanyPlan = (id, companyId, request) => dispatch => {
  const process = async () => ({
    response: await PlansService.updateCompanyPlan(id, companyId, request),
  })
  dispatch(executeAction(actions.UPDATE_PLAN, process))
}

export const addUserInPlan = request => dispatch => {
  const process = async () => ({
    response: await PlansService.addUser(request),
  })
  dispatch(executeAction(actions.ADD_USER, process))
}

export const addCompanyUserInPlan = (companyId, request) => dispatch => {
  const process = async () => ({
    response: await PlansService.addCompanyUser(companyId, request),
  })
  dispatch(executeAction(actions.ADD_USER, process))
}

export const deleteUserInPlan = userId => dispatch => {
  const process = async () => ({ response: await PlansService.deleteUser(userId) })
  dispatch(executeAction(actions.DELETE_USER, process))
}

export const deleteCompanyUserInPlan = (companyId, userId) => dispatch => {
  const process = async () => ({
    response: await PlansService.deleteCompanyUser(companyId, userId),
  })
  dispatch(executeAction(actions.DELETE_USER, process))
}

export const getUserPlan = id => dispatch => {
  const process = async () => ({
    response: await PlansService.getUserPlan(id),
  })
  dispatch(executeAction(actions.GET_USER_PLAN, process))
}

export const assignDLC = id => dispatch => {
  const process = async () => ({ response: await PlansService.assignDLC(id) })
  dispatch(executeAction(actions.ADD_DLC, process))
}

export const deleteDLC = id => dispatch => {
  const process = async () => ({ response: await PlansService.deleteDLC(id) })
  dispatch(executeAction(actions.ADD_DLC, process))
}

/**
 * @deprecated since version 1.68.0
 * */
export const getHistory = () => dispatch => {
  const process = async () => ({ response: await PlansService.getHistory() })
  dispatch(executeAction(actions.GET_HISTORY, process))
}

export const buyUserSpace = request => dispatch => {
  const process = async () => ({
    payload: await PlansService.buyUserSpace(request),
  })
  dispatch(executeAction(actions.BUY_USER_SPACE, process))
}

export const buyUserSpaceRequest = request => dispatch => {
  const process = async () => ({
    payload: await PlansService.buyUserSpace(request),
  })
  dispatch(executeAction(actions.BUY_USER_SPACE_REQUEST, process))
}

export const buyCompanyUserSpace = (companyId, request) => dispatch => {
  const process = async () => ({
    payload: await PlansService.buyCompanyUserSpace(companyId, request),
  })
  dispatch(executeAction(actions.BUY_USER_SPACE, process))
}

export const removeUserSpace = () => dispatch => {
  const process = async () => ({
    payload: await PlansService.removeUserSpace(),
  })
  dispatch(executeAction(actions.REMOVE_USER_SPACE, process))
}

export const removeUserSpaceRequest = () => dispatch => {
  const process = async () => ({
    payload: await PlansService.removeUserSpace(),
  })
  dispatch(executeAction(actions.REMOVE_USER_SPACE_REQUEST, process))
}

export const removeCompanyUserSpace = companyId => dispatch => {
  const process = async () => ({
    payload: await PlansService.removeCompanyUserSpace(companyId),
  })
  dispatch(executeAction(actions.REMOVE_USER_SPACE, process))
}

export const planPendingPayment = () => dispatch => {
  const process = async () => ({
    response: await PlansService.planPendingPayment(),
  })
  dispatch(executeAction(actions.PLAN_PENDING_PAYMENT, process))
}
