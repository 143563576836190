import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import { setMenu } from 'src/actions/dashboard.actions'

import { selectSetCurrentCompany } from 'src/selectors/company.selector'

import {
  getCurrentUserRolesAction,
  getGroups,
  getSubmodules,
  setModuleId,
} from 'src/actions/modules.actions'
import { selectModuleGroups, selectSubModules } from 'src/selectors/modules.selector'

import Dashboard from '../Dashboard/Dashboard'
import Permissions from '../Permissions/Dashboard/Permissions'
import UpdatePermissions from '../Permissions/UpdatePermissions/UpdatePermissions'

import ProductsList from '../Products/Items'
import ProductDetails from '../Products/ProductDetails'
import Transport from '../Products/Transport/Transport'
import TransportPayment from '../Products/Transport/TransportPayment/TransportPayment'
import TransportationPaymentDetail from '../Products/Transport/TransportationPaymentDetail/TransportationPaymentDetail'
import PublicCategorization from '../Products/Categorization/Categorization'

import Clients from '../Clients/Clients'
import EditClient from '../Clients/Edit/EditClient'
import CreateClient from '../Clients/Create/CreateClientIndex'
import ClientsMap from '../Clients/Map/ClientsMap'
import ClientOrderHistory from '../Clients/ClientOrderHistory/ClientOrderHistory'
import CPCDashboard from '../Clients/CPC/CPCDashboard'
import MapPolygon from '../Clients/MapPolygon/MapPolygon'

import { saveCurrentModule } from 'src/actions/user.actions'

import WareHouse from '../Warehouse/WarehouseV2'
import CreateWarehouse from '../Warehouse/CreateWarehouse/CreateWarehouse'

import Transfers from '../Transfer/Transfers'
import TransfersForm from '../Transfer/CreateTransfers/CreateTransfer'
import TransferDetail from '../Transfer/DetailTransfers/DetailTransfers'
import ExternalTransfer from '../Transfer/ExternalTransfer/ExternalTransfer'

import Shipper from './Shipper/Shipper'

import Resolutions from './Resolutions/Resolutions'
import Bills from './Resolutions/BillsByResolution/BillsByResolutions'

import EditPolygon from '../Polygons/NewPolygon/NewPolygon2'
import NewPolygon from '../Polygons/NewPolygon/NewPolygon2'
import Polygons from '../Polygons/Polygons'

import OfferUpdate from './Offers/OfferUpdate/OfferUpdate'
import ContraOffersDetails from './Offers/ContraOfferDetails/ContraOfferDetails'
import OfferCreate from './Offers/OfferCreate/OfferCreate'

import RequestDocuments from './Documents/RequestDocuments'
import CreateDocumentRequest from './Documents/CreateDocumentRequest'
import DocumentRequestDetails from './Documents/DocumentRequestDetails'
import CSVLoader from '../CSVLoader/CSVLoader'

import BalanceDashboard from '../Balance/Dashboard'

import Orders from '../Orders/OrdersPage'
import SaleDetail from '../Orders/OrderDetail/OrdersDetails'

import InventoryProductList from '../Inventory/Inventory'
import Inventory from './Inventory/Inventory'
import GranularPermission from '../Permissions/Granular/GranularPermission'
import RecipesList from '../Inventory/RecipesList'

import Expenses from '../Expenses/Expenses'

import Categorization from './Categorization/Categorization'
import CategorizationDetail from './Categorization/Detail/CategorizationDetail'
import CategorizationCreate from './Categorization/Create/CategorizationCreate'
import Profile from '../Client/Profile/Profile'

import ReportsDashboard from '../Reports/Dashboard/ReportsDashboard'
import InventoryHistory from '../Reports/InventoryHistory/InventoryHistory'

import Purchase from '../Purchase/Purchase'
import NewPurchase from '../Purchase/Create/NewPurchase'

import Subscriptions from './Subscriptions/Subscriptions'
import SubscriptionCreate from './Subscriptions/SubscriptionCreate/SubscriptionCreate'
import SubscriptionClientDetail from '../Client/Subscriptions/SubscriptionClientDetail/SubscriptionClientDetail'
import Users from '../Reports/Users/Users'
import BanksV2 from '../Company/Banks/BanksV2'
import DepositsAdmin from '../Admin/Payments/Deposits/DepositsAdmin'

import InvoiceProfile from '../InvoiceProfile/InvoiceProfile'
import Redirect from '../Redirect/Redirect'
import BalanceEntries from '../Balance/BalanceEntries'
import BalanceDisbursements from '../Balance/new/BalanceCompany'
import KoloProfile from '../Company/Profile/Kolo'
import ClientPayments from '../Clients/CPC/Payment/Payments'
import DashboardAdmin from '../Balance/DashboardAdmin'
import PatrimonialReport from '../Reports/Patrimonial/PatrimonialReport'
import AccountingBook from '../AccountingBook/AccountingBook'

import KoloLink from '../KoloLink/KoloLink'
import KoloLinkProfile from '../KoloLink/Profile/KoloLinkProfile'
import CreateContractKolo from '../KoloLink/CreateContract/CreateContractKolo'
import KoloLInkProfileSingle from '../KoloLink/CreateContract/ContractResume'
import SaleReport from '../Reports/Sale/Sale'

import Waste from '../Waste/Waste'

import DepositPage from '../Deposits/DepositPage'
import ExpensesAndWaste from '../Reports/ExpensesAndWaste/ExpensesAndWaste'
import DashboardKolo from '../Kolo/DashboardKolo'
import Parcel from '../Parcel/Parcel'
import ClientReport from '../Reports/Client/ClientReport'
import Invoices from '../Orders/Invoices/Invoices'
import ClientBalance from '../Reports/Client/ClientBalance'
import Cpp from '../Purchase/CPP/CppPage'
import ClientRequest from '../Clients/ClientRequest/ClientRequest'
import OrderDetailPage from '../Orders/OrderDetail/OrderDetailPage'
import ProductsV2 from '../Reports/Products/ProductsV2'
import ProductsHistory from '../Reports/Products/ProductsHistory'
import Providers from '../Providers/PurchaseProvider'
import CardCharges from '../cardCharges/CardCharges'

import Subscription from '../Subscription/Subscription'
import RecipeDetail from 'src/components/custom/RecipeDetail/RecipeDetail'
import CreateRecipes from '../Inventory/CreateRecipes/CreateRecipes'

import ModalLog from 'src/components/modal/ModalLog'
import CreateOrder from '../Orders/OrderCreate/CreateOrder'

import Vouchers from '../Voucher/Vouchers'
import WasteRequestV2 from '../Waste/Create/WasteRequestV2'

class ProductionRoutes extends Component {
  componentDidMount() {
    this.props.setModuleId({ id: 2000, title: 'Produccion' })
    this.props.getSubmodules(2000)
    this.props.getGroups(2000)
    this.props.saveCurrentModule()
    this.props.rolesAction()
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { company } = this.props

    // if(groups !== next.groups){
    //   let menu = [{id: 2000, text: 'Menú Productores', icon: FactoryIcon, modules: []}];
    //   next.groups.map(group => {
    //     let modules = [];
    //     group.modules.map(subModule => {
    //       subModule.name = subModule.name.replace("\*",company.name || "")
    //       subModule.icon = subModule.logo || subModule.alterIcon
    //       modules.push({id: subModule.id, text: subModule.name, icon: subModule.icon, link: subModule.route, show: subModule.showIcon})
    //     })
    //     menu.push({id: group.id, text: group.name, icon: group.icon, modules, show: true});
    //   });
    //   setMenu(menu);
    // }

    // if (submodules !== next.submodules) {
    //     let menu = [{id: 2000, text: 'Menú Productores', icon: FactoryIcon, link: `${match.url}/`}];
    //     next.submodules.forEach(subModule => {
    //         subModule.name = subModule.name.replace("\*",company.name || "")
    //         subModule.icon = subModule.logo || subModule.alterIcon
    //         menu.push({id: subModule.id, text: subModule.name, icon: subModule.icon, link: subModule.route, show: subModule.showIcon});
    //     });
    //     setMenu(menu);
    // }
    this.props.setModuleId({
      id: 2000,
      title: `Productor - ${company.name || ''}`,
    })
  }

  render() {
    const { match } = this.props
    return (
      <Switch>
        <Route path={`${match.url}/proveedores`} component={Providers} />

        <Route path={`${match.url}/cpp`} component={Cpp} />

        <Route
          path={`${match.url}/documentos/detalles/:id`}
          component={DocumentRequestDetails}
        />
        <Route
          path={`${match.url}/documentos/solicitar`}
          component={CreateDocumentRequest}
        />
        <Route path={`${match.url}/documentos`} component={RequestDocuments} />

        <Route
          path={`${match.url}/productos/detalles/:id/pagos`}
          component={TransportPayment}
        />
        <Route
          path={`${match.url}/productos/transporte/detalle/:paymentId`}
          component={TransportationPaymentDetail}
        />
        <Route
          path={`${match.url}/productos/transporte/:id/detalle/:paymentId`}
          component={TransportationPaymentDetail}
        />
        <Route
          path={`${match.url}/productos/transporte/:id/pagos/:paymentId`}
          component={TransportPayment}
        />
        <Route
          path={`${match.url}/productos/transporte/:id/pagos`}
          component={TransportPayment}
        />
        <Route path={`${match.url}/productos/transporte/:id`} component={Transport} />

        <Route
          path={`${match.url}/productos/categorizaciones`}
          component={PublicCategorization}
        />
        <Route path={`${match.url}/productos/detalles/:id`} component={ProductDetails} />
        <Route path={`${match.url}/productos`} component={ProductsList} />

        <Route
          path={`${match.url}/transferencias/kolo/crear`}
          component={ExternalTransfer}
        />
        <Route
          path={`${match.url}/transferencias/crear/:val`}
          component={TransfersForm}
        />
        <Route path={`${match.url}/transferencias/crear`} component={TransfersForm} />
        <Route path={`${match.url}/transferencias/kolo`} component={Transfers} />
        <Route path={`${match.url}/transferencias/:id`} component={TransferDetail} />
        <Route path={`${match.url}/transferencias`} component={Transfers} />
        <Route path={`${match.url}/bodegas/crear`} component={CreateWarehouse} />
        <Route path={`${match.url}/bodegas/editar/:id`} component={CreateWarehouse} />
        <Route path={`${match.url}/bodegas`} component={WareHouse} />

        <Route
          path={`${match.url}/clientes/cpc/:id/orden/detalle/:id`}
          component={SaleDetail}
        />
        <Route path={`${match.url}/clientes/cpc/:id`} component={CPCDashboard} />

        <Route path={`${match.url}/clientes/editar/:client`} component={EditClient} />
        <Route path={`${match.url}/clientes/nuevo`} component={CreateClient} />
        <Route
          path={`${match.url}/clientes/historial/:client`}
          component={ClientOrderHistory}
        />

        <Route
          path={`${match.url}/clientes/mapa/poligono/admin/editar/:polygon`}
          component={EditPolygon}
        />
        <Route
          path={`${match.url}/clientes/mapa/poligono/admin/nuevo`}
          component={NewPolygon}
        />
        <Route path={`${match.url}/clientes/mapa/poligono/admin`} component={Polygons} />
        <Route
          path={`${match.url}/clientes/mapa/poligono/historial/:client`}
          component={ClientOrderHistory}
        />
        <Route
          path={`${match.url}/clientes/mapa/poligono/editar/:client`}
          component={EditClient}
        />
        <Route path={`${match.url}/clientes/mapa/poligono`} component={MapPolygon} />
        <Route path={`${match.url}/clientes/mapa`} component={ClientsMap} />

        <Route path={`${match.url}/clientes/perfil/:id`} component={Profile} />
        <Route path={`${match.url}/clientes/pagos/:client`} component={ClientPayments} />
        <Route path={`${match.url}/clientes/pagos`} component={ClientPayments} />
        <Route path={`${match.url}/clientes/solicitudes`} component={ClientRequest} />

        <Route
          path={`${match.url}/clientes/suscripciones/detalle`}
          component={Subscription}
        />

        <Route path={`${match.url}/clientes`} component={Clients} />

        <Route
          path={`${match.url}/permisos/:module/:user/:name`}
          component={UpdatePermissions}
        />
        <Route
          path={`${match.url}/permisos/:module/matriz`}
          component={GranularPermission}
        />
        <Route path={`${match.url}/permisos/:module`} component={Permissions} />

        <Route path={`${match.url}/ofertas/nueva`} component={OfferCreate} />
        <Route
          path={`${match.url}/ofertas/detalles/:id/response/:contraOffer`}
          component={ContraOffersDetails}
        />
        <Route
          path={`${match.url}/ofertas/detalles/:id/actualizar`}
          component={OfferUpdate}
        />

        <Route
          path={`${match.url}/ofertas/perfil/:id/:type/nuevo/:zone`}
          component={CreateContractKolo}
        />
        <Route
          path={`${match.url}/ofertas/perfil/:id/:type/editar/:zone`}
          component={CreateContractKolo}
        />
        <Route
          path={`${match.url}/ofertas/perfil/:id/:type/detalle/:id`}
          component={KoloLInkProfileSingle}
        />
        <Route
          path={`${match.url}/ofertas/perfil/:id/:type`}
          component={KoloLinkProfile}
        />
        <Route
          path={`${match.url}/ofertas/detalles/:id`}
          component={KoloLInkProfileSingle}
        />
        <Route path={`${match.url}/ofertas`} component={KoloLink} />

        <Route path={`${match.url}/transportistas`} component={Shipper} />

        <Route path={`${match.url}/resoluciones/:id`} component={Bills} />
        <Route path={`${match.url}/resoluciones`} component={Resolutions} />

        <Route path={`${match.url}/csv`} component={CSVLoader} />

        <Route path={`${match.url}/balance/propios`} component={BalanceDisbursements} />
        <Route path={`${match.url}/balance/kolo`} component={BalanceDisbursements} />
        <Route path={`${match.url}/desembolsos`} component={DashboardAdmin} />

        <Route path={`${match.url}/cuadres/propio`} component={BalanceEntries} />
        <Route path={`${match.url}/cuadres/kolo`} component={BalanceEntries} />

        <Route path={`${match.url}/balance/general`} component={BalanceEntries} />
        <Route path={`${match.url}/balance`} component={BalanceDashboard} />

        <Route path={`${match.url}/patrimonio`} component={AccountingBook} />

        <Route path={`${match.url}/conversiones`} component={Transfers} />

        <Route path={`${match.url}/ordenes/visita`} component={Orders} />

        <Route
          path={`${match.url}/ordenes/:linked/detalle/:id`}
          component={OrderDetailPage}
        />
        <Route path={`${match.url}/ordenes/:linked/nueva`} component={CreateOrder} />
        <Route
          path={`${match.url}/ordenes/:linked/editar/:id/:sale`}
          component={CreateOrder}
        />
        <Route
          path={`${match.url}/ordenes/:linked/invoices/:orderId/:number`}
          component={Invoices}
        />
        <Route
          path={`${match.url}/ordenes/:linked/invoices/:orderId`}
          component={Invoices}
        />
        <Route path={`${match.url}/ordenes/:linked`} component={Orders} />

        <Route path={`${match.url}/cotizaciones/:linked/nueva`} component={CreateOrder} />
        <Route
          path={`${match.url}/cotizaciones/:linked/editar/:id/:sale`}
          component={CreateOrder}
        />
        <Route
          path={`${match.url}/cotizaciones/:linked/invoices/:orderId/:number`}
          component={Invoices}
        />
        <Route
          path={`${match.url}/cotizaciones/:linked/invoices/:orderId`}
          component={Invoices}
        />
        <Route path={`${match.url}/cotizaciones/:linked`} component={Orders} />

        <Route path={`${match.url}/gastos/nueva`} component={NewPurchase} />
        <Route path={`${match.url}/gastos`} component={Expenses} />

        {/*<Route path={`${match.url}/ventas/balance`} component={SaleBalance}/>*/}
        <Route path={`${match.url}/kolo`} component={DashboardKolo} />

        <Route path={`${match.url}/poligonos/editar/:polygon`} component={EditPolygon} />
        <Route path={`${match.url}/poligonos/nuevo`} component={NewPolygon} />
        <Route path={`${match.url}/poligonos`} component={Polygons} />

        <Route path={`${match.url}/inventario/historial`} component={Inventory} />
        <Route path={`${match.url}/inventario/recetas/:id`} component={RecipeDetail} />
        <Route path={`${match.url}/inventario/recetas`} component={RecipesList} />
        <Route path={`${match.url}/inventario`} component={InventoryProductList} />

        <Route
          path={`${match.url}/categorizaciones/nuevo`}
          component={CategorizationCreate}
        />
        <Route
          path={`${match.url}/categorizaciones/detalle/:id`}
          component={CategorizationDetail}
        />
        <Route path={`${match.url}/categorizaciones`} component={Categorization} />

        <Route path={`${match.url}/reportes/ventas/totales`} component={SaleReport} />
        <Route path={`${match.url}/reportes/ventas`} component={SaleReport} />
        <Route path={`${match.url}/reportes/balance`} component={ClientBalance} />
        <Route path={`${match.url}/reportes/patrimonial`} component={PatrimonialReport} />
        <Route path={`${match.url}/reportes/inventario`} component={InventoryHistory} />
        <Route
          path={`${match.url}/reportes/productos/historial`}
          component={ProductsHistory}
        />
        <Route path={`${match.url}/reportes/productos`} component={ProductsV2} />
        <Route path={`${match.url}/reportes/usuarios`} component={Users} />
        <Route path={`${match.url}/reportes/gastos`} component={ExpensesAndWaste} />
        <Route path={`${match.url}/reportes/compras`} component={ExpensesAndWaste} />
        <Route path={`${match.url}/reportes/merma`} component={ExpensesAndWaste} />
        <Route path={`${match.url}/reportes/logs`} component={ModalLog} />
        <Route path={`${match.url}/reportes/clientes`} component={ClientReport} />
        {
          //<Route path={`${match.url}/reportes/suscripcion`} component={Subscription}/>
        }
        <Route path={`${match.url}/reportes`} component={ReportsDashboard} />

        <Route path={`${match.url}/poligonos`} component={Polygons} />

        <Route path={`${match.url}/compras/invoices/:purchaseId`} component={Invoices} />
        <Route
          path={`${match.url}/compras/importaciones/nueva`}
          component={NewPurchase}
        />
        <Route path={`${match.url}/compras/nueva`} component={NewPurchase} />
        <Route path={`${match.url}/compras`} component={Purchase} />

        <Route
          path={`${match.url}/subscriptions/detail`}
          component={SubscriptionClientDetail}
        />
        <Route path={`${match.url}/subscriptions/new`} component={SubscriptionCreate} />
        <Route path={`${match.url}/subscriptions`} component={Subscriptions} />

        <Route path={`${match.url}/pagos/:type`} component={DepositPage} />
        <Route path={`${match.url}/bancos`} component={BanksV2} />

        <Route path={`${match.url}/facturacion`} component={InvoiceProfile} />
        <Route path={`${match.url}/depositos`} component={DepositsAdmin} />

        <Route
          path={`${match.url}/:module/redirect/:type/:id/:company/:packageId`}
          component={Redirect}
        />
        <Route
          path={`${match.url}/:module/redirect/:type/:id/:company`}
          component={Redirect}
        />
        <Route path={`${match.url}/:module/redirect/:type/:id`} component={Redirect} />

        <Route path={`${match.url}/perfil/kolo`} component={KoloProfile} />

        <Route path={`${match.url}/desechos/nuevo`} component={WasteRequestV2} />
        <Route path={`${match.url}/desechos`} component={Waste} />

        <Route path={`${match.url}/producciones`} component={Transfers} />

        <Route path={`${match.url}/cxc`} component={ClientPayments} />

        <Route
          path={`${match.url}/vales/:creditNote/:orderId/:number`}
          component={Invoices}
        />
        <Route path={`${match.url}/vales`} component={Vouchers} />

        <Route path={`${match.url}/paqueteria`} component={Parcel} />

        <Route path={`${match.url}/cobros`} component={CardCharges} />

        <Route path={`${match.url}/recetas/crear`} component={CreateRecipes} />
        <Route path={`${match.url}/recetas`} component={RecipeDetail} />

        <Route path={``} component={Dashboard} />
      </Switch>
    )
  }
}

const mapStateToProps = state => ({
  submodules: selectSubModules(state),
  groups: selectModuleGroups(state),
  company: selectSetCurrentCompany(state),
})

const mapDispatchToProps = dispatch => ({
  setMenu: routes => dispatch(setMenu(routes)),
  setModuleId: module => dispatch(setModuleId(module)),
  getGroups: module => dispatch(getGroups(module)),
  getSubmodules: module => dispatch(getSubmodules(module)),
  saveCurrentModule: () => dispatch(saveCurrentModule(2000)),
  rolesAction: () => dispatch(getCurrentUserRolesAction(2000)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductionRoutes)
