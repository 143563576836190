import './CSVLoader.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'

// import { FilePicker } from 'react-file-picker'

import {
  faCloudDownloadAlt,
  faCloudUploadAlt,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'
import { Td, Tr } from 'react-super-responsive-table'
import SweetAlert from 'sweetalert-react'

import { Button, Icon as IconButton } from '../../components'

import { selectCurrentCompany } from '../../selectors/user.selector'

import { getItemValue } from '../../utils/formatters'

import CSVLoaderError from './CSVLoaderError'
import { FilePicker } from 'react-file-picker'

class CSVLoader extends Component {
  state = {
    showError: false,
    showSuccess: false,
    showMessageList: false,
    global: false,
    errorText: '',
    fullResponse: {},
    categoryUpload: CATEGORY_UPLOAD.INVENTORY,
    isLoading: false,
    showInfo: false,
    info: { title: '', content: '' },
    transportationModal: false,
    polygon: { value: null, label: 'sin seleccionar' },
  }

  onSuccessLoadFile = (response, isError) => {
    this.setState({ isLoading: false })
    if (!isError) {
      this.showInfo(response)
    } else {
      this.setState({
        showSuccess: false,
        showError: true,
        errorText: 'Error al subir el archivo',
        fullResponse: {
          errorColumns: ['Linea', 'Columna', 'Error', 'Descripcion'],
          errors: [],
          items: 0,
          message: 'Error al subir el archivo',
          ok: false,
        },
      })
    }
  }

  showInfo = response => {
    if (response.ok === false) {
      this.setState({
        showSuccess: false,
        showError: true,
        errorText: response.message,
        fullResponse: response,
      })
    } else {
      this.setState({ showSuccess: true, showError: false, fullResponse: response })
    }
  }

  renderLoadDetails = (item, index) => {
    return (
      <Tr className={`data`} key={`${index}`} data-item={item}>
        <Td className={'name'}>{item.line}</Td>
        <Td className={'name'}>{item.column}</Td>
        <Td className={'name'}>{item.error}</Td>
        <Td className={'name'}>{item.message}</Td>
      </Tr>
    )
  }

  showInfoDownload = () => {
    const { category, title, info } = this.props
    switch (category) {
      case CATEGORY_UPLOAD.TRANSPORTATION:
        this.setState({
          info: {
            title: 'Pago de transporte',
            content:
              'Los nombres de las columnas deben ser escritos en minúscula y sin tildes.\n' +
              'Las columnas para las cuales no hay información se dejan en blanco\n' +
              'Los valores de las columnas de rangos son (unidades maxima/porcentaje)',
          },
        })
        break
      case CATEGORY_UPLOAD.PRODUCTS:
        this.setState({
          info: {
            title: 'Productos',
            content:
              'Los nombres de las columnas deben ser escritos en minúscula y sin tildes.\n' +
              'Las columnas para las cuales no hay información se dejan en blanco\n' +
              "Los valores desde la columna 'Transformación automática' en adelante pueden ser Si o No",
          },
        })
        break
      case CATEGORY_UPLOAD.UPDATE_PRODUCTS:
        this.setState({
          info: {
            title: 'Productos',
            content:
              'El SKU es el código del producto\n' +
              "Los valores desde la columna 'Transformación automática' en adelante pueden ser Si o No",
          },
        })
        break
      case CATEGORY_UPLOAD.CLIENTS:
        this.setState({
          info: {
            title: 'Clientes',
            content:
              'Los nombres de las columnas deben ser escritos en minúscula y sin tildes.\n' +
              'Las columnas para las cuales no hay información se dejan en blanco',
          },
        })
        break
      case CATEGORY_UPLOAD.RECIPES:
        this.setState({
          info: {
            title: 'Recetas',
            content:
              'Los nombres de las columnas deben ser escritos en minúscula y sin tildes.\n' +
              'Para la columna de composicion se ingresaran los productos separados por el simbolo "/" \n' +
              'Se escribe cada producto con su cantidad, separados por el simbolo ";" por ejemplo: \n' +
              'P-00001;1/P-00002;2/P-12345;3',
          },
        })
        break
      case CATEGORY_UPLOAD.PURCHASE:
        this.setState({
          info: {
            title: 'Compras/Gastos',
            content:
              'Los campos obligatorios son SKU, cantidad y subtotal, los demás campos pueden quedar vacíos',
          },
        })
        break
      case CATEGORY_UPLOAD.PURCHASE_SIMPLE:
        this.setState({
          info: {
            title: 'Compras/Gastos',
            content:
              'Los campos obligatorios son NIT, Nombre y Total, el campo CPP significa Cuenta Pendiente de Pago y se llena con un SI o se deja vacio, los demas campos pueden quedar vacíos.',
          },
        })
        break
      default:
        this.setState({
          info: {
            title: title,
            content: info || 'No hay información',
          },
        })
        break
    }
    this.setState({ showInfo: true })
  }

  handleCheckBoxChange = event => {
    const { name, checked } = event.target
    this.setState({ [name]: checked })
  }

  onUpload = (tar, selected) => {
    const { global } = this.state
    this.setState({ isLoading: true })

    let file = tar

    let formData = new FormData()
    formData.append('file', file)

    let reader = new FileReader()
    reader.onload = e => {
      let lines = this.parseCSV(e.target.result)
    }
    reader.readAsBinaryString(file)
    this.props.onUpload(file, this.onSuccessLoadFile, global, selected)
  }

  getItemsMobile = () => {
    const { fullResponse } = this.state

    let array = []
    let store = {
      title: { value: ['line'], type: 'text' },
      subtitle: { value: ['column2'], type: 'text' },
      complement1: { value: ['error'], type: 'text' },
      complement2: { value: ['message'], type: 'text' },
    }

    if (fullResponse.errors) {
      fullResponse.errors.map((item, index) => {
        const { line, column, error, message } = item
        const more = [
          { title: 'Línea', info: line },
          { title: 'Columna', info: column },
          { title: 'Error', info: error },
          { title: 'Mensaje', info: message },
        ]

        const object = {
          id: index,
          title: getItemValue(item, store.title.value, store.title.type),
          subtitle: getItemValue(item, store.subtitle.value, store.subtitle.type),
          complement1: getItemValue(
            item,
            store.complement1.value,
            store.complement1.type,
          ),
          complement2: getItemValue(
            item,
            store.complement2.value,
            store.complement2.type,
          ),
          key: `prod-${index}`,
          more,
        }

        array.push(object)
      })
    }
    return array
  }
  parseCSV(text) {
    // Obtenemos las lineas del texto
    let lines = text.replace(/\r/g, '').split('\n')
    return lines.map(line => {
      // Por cada linea obtenemos los valores
      let values = line.split(',')
      return values
    })
  }
  render() {
    const { showError, errorText, showSuccess, fullResponse, showMessageList } =
      this.state
    const { category, title, getCsv, loading, info } = this.props
    const { isLoading } = this.state

    return (
      <div style={{ alignItems: 'center', marginBottom: 15 }}>
        {info && (
          <div className={'csv-text-m mb-2'} style={{ textTransform: 'uppercase' }}>
            INGRESE LA INFORMACIÓN DE FORMA MANUAL O
          </div>
        )}
        <div className={'d-flex justify-content-center'}>
          {info && (
            <div
              className={'mt-2 mr-2 csv-text-w'}
              style={{ textTransform: 'uppercase' }}
            >
              INGRESE LA INFORMACIÓN DE FORMA MANUAL O
            </div>
          )}
          <div className={'mr-2'}>
            <FilePicker
              maxSize={5}
              extensions={['csv']}
              onChange={this.onUpload}
              onError={errMsg => {
                this.setState({ showError: true, errorText: errMsg })
              }}
            >
              <div>
                <Button
                  iStyle={{ alignSelf: 'center', marginRight: 5 }}
                  icon={faCloudUploadAlt}
                  loading={loading !== undefined ? loading : isLoading}
                  disabled={loading !== undefined ? loading : isLoading}
                  className={'big-button no-bottom-margin'}
                  style={{ textTransform: 'uppercase' }}
                >
                  {title}
                </Button>
              </div>
            </FilePicker>
          </div>

          {/*
          <div className={'mr-2'}>
            <div className={'picker_file_csv_container'}>
              <input
                style={{ visibility: 'hidden' }}
                disabled={loading !== undefined ? loading : isLoading}
                type={'file'}
                accept={['.csv']}
                name={'file'}
                id={'file'}
                value={null}
                onChange={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  if (e.target.files.length !== 0) {
                     this.onUpload(e.target)
                  }
                  e.target.value = null
                }}
              />
              <label htmlFor={'file'} style={{ position: 'absolute' }}>
                <div
                  className={`picker_file_csv ${
                    (loading !== undefined ? loading : isLoading) ? 'disabled' : ''
                  }`}>
                  <IconButton
                    icon={faCloudUploadAlt}
                    tooltip={title}
                    spin={loading !== undefined ? loading : isLoading}
                    style={{ marginRight: 5 }}
                    color={'white'}
                  />
                  <div>{title}</div>
                </div>
              </label>
            </div>
          </div>
          */}

          <div style={{ alignSelf: 'center' }}>
            {!getCsv ? (
              <a download href={category.url} target={'_blank'} rel={'noreferrer'}>
                <IconButton
                  tooltip={'Descargar Plantilla'}
                  icon={faCloudDownloadAlt}
                  size={'2x'}
                />
              </a>
            ) : (
              <IconButton
                tooltip={'Descargar Plantilla'}
                icon={faCloudDownloadAlt}
                size={'2x'}
                onClick={() => this.props.getCsv()}
              />
            )}
          </div>
          <div style={{ alignSelf: 'center' }}>
            <IconButton
              tooltip={'Información'}
              icon={faInfoCircle}
              size={'2x'}
              onClick={() => this.showInfoDownload(category)}
            />
          </div>
        </div>

        <SweetAlert
          show={showError}
          title="Error"
          text={errorText}
          type={'warning'}
          onConfirm={() => {
            if (fullResponse.errors !== undefined) {
              if (fullResponse.errors.length > 0) {
                this.setState({ showMessageList: true, showError: false })
              }
            }
            this.setState({ showError: false })
          }}
        />
        <SweetAlert
          show={showSuccess}
          title="Cargado con exito"
          text={'Archivo cargado con exito'}
          type={'success'}
          onConfirm={() => {
            if (fullResponse.errors !== undefined) {
              if (fullResponse.errors.length > 0) {
                this.setState({ showMessageList: true })
              }
            }
            this.setState({ showSuccess: false })
            if (this.props.onSuccess && fullResponse.ok)
              this.props.onSuccess(fullResponse)
          }}
        />
        <SweetAlert
          show={this.state.showInfo}
          type={'info'}
          title={this.state.info.title}
          text={this.state.info.content}
          onConfirm={() =>
            this.setState({ showInfo: false, info: { title: '', content: '' } })
          }
        />

        <CSVLoaderError
          show={showMessageList}
          onHide={() => this.setState({ showMessageList: false })}
          items={fullResponse.errors}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  company: selectCurrentCompany(state),
})

const mapDispatchToProps = () => undefined

export const CATEGORY_UPLOAD = {
  CLIENTS: {
    type: 'CLIENTS',
    name: 'up_client.csv',
    url: 'https://firebasestorage.googleapis.com/v0/b/cotizap-prod.appspot.com/o/csv%2Fup_clients.csv?alt=media&token=cea65e43-a557-4ca1-b7bc-612844ed6e8a',
  },
  PURCHASE: {
    type: 'PURCHASE',
    name: 'up_purchase.csv',
    url: 'https://firebasestorage.googleapis.com/v0/b/cotizap-prod.appspot.com/o/purchase-file.csv?alt=media&token=eb0e0fd6-eba4-4ef4-b9f1-c142209a45b5',
  },
  PRODUCTS: {
    type: 'PRODUCTS',
    name: 'up_items.csv',
    url: 'https://firebasestorage.googleapis.com/v0/b/cotizap-prod.appspot.com/o/csv%2Fup-items.csv?alt=media&token=b12e9aff-dc86-4f00-8552-e9cc1cccbd0e',
  },
  UPDATE_PRODUCTS: {
    type: 'UPDATE_PRODUCTS',
    name: 'update_items.csv',
    url: 'https://firebasestorage.googleapis.com/v0/b/cotizap-prod.appspot.com/o/file_update_products.csv?alt=media&token=dad76e26-5886-4acd-b5ef-1c6a5ffed217',
  },
  TRANSPORTATION: {
    type: 'TRANSPORTATION',
    name: 'up_recipe.csv',
    url: 'https://firebasestorage.googleapis.com/v0/b/distribution-app-dev.appspot.com/o/file_transport_products.csv?alt=media&token=04867189-a8b1-49c4-90bf-446c070ba5f4',
  },
  WAREHOUSES: {
    type: 'WAREHOUSES',
    name: 'up_warehouses.csv',
    url: 'https://firebasestorage.googleapis.com/v0/b/distribution-app-prod.appspot.com/o/files%2Fwarehouse.csv?alt=media&token=d7072e60-476a-449f-8ed8-498d274d4fb2',
  },
  INVENTORY: {
    type: 'INVENTORY',
    name: 'up_inventory.csv',
    url: 'https://firebasestorage.googleapis.com/v0/b/cotizap-prod.appspot.com/o/csv%2Finventory.csv?alt=media&token=dc93a1d8-9aae-435f-985f-4619535c8e03',
  },
  RECIPES: {
    type: 'RECIPES',
    name: 'up_recipe.csv',
    url: 'https://firebasestorage.googleapis.com/v0/b/cotizap-prod.appspot.com/o/csv%2Ffile_recipes_create.csv?alt=media&token=77f5d39b-8b50-4a01-80c2-bdcf7ab5874f',
  },
  PURCHASE_SIMPLE: {
    type: 'PURCHASE_SIMPLE',
    name: 'up_purchase_simple.csv',
    url: 'https://firebasestorage.googleapis.com/v0/b/cotizap-prod.appspot.com/o/csv%2Fgastos_abreviados.csv?alt=media&token=44c2e128-3044-45d6-99b3-df71fd4798bd',
  },
}

export default connect(mapStateToProps, mapDispatchToProps)(CSVLoader)
