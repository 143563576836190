import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Alert from 'sweetalert-react'
import { Row, Col, Modal } from 'react-bootstrap'
import ImageInput, { imageTypes } from 'src/components/inputs/ImageInput/ImageInput'
import { Icon, Title, FormText, Card, FAB, Select as CustomSelect } from 'src/components'

import { selectCurrentCompany } from 'src/selectors/user.selector'

import { getFiles } from 'src/actions/uploads.actions'
import {
  selectFilesForGallery,
  selectFilesForGallery2,
} from 'src/selectors/uploads.selector'

import { valEmail } from 'src/utils/utilities'
import {
  faCopy,
  faEdit,
  faGlobe,
  faQrcode,
  faSave,
} from '@fortawesome/free-solid-svg-icons'

import { loadingSelector } from 'src/selectors/loading.selector'

import {
  actionTypes,
  getAllPOS,
  getPosProfile,
  updatePosProfile,
} from 'src/actions/restaurant.actions'
import { selectAllPOS } from 'src/selectors/restaurant.selector'

const ProfilePOS = () => {
  const dispatch = useDispatch()

  const companyId = useSelector(selectCurrentCompany)
  const logo = useSelector(selectFilesForGallery)
  const imgList = useSelector(selectFilesForGallery2)

  const allPOS = useSelector(selectAllPOS)
  const loadingAllPOS = useSelector(state =>
    loadingSelector([actionTypes.GET_ALL_POS])(state),
  )

  const fields = useSelector(state => state.restaurant.posProfile)
  const loading = useSelector(state =>
    loadingSelector([actionTypes.GET_POS_PROFILE])(state),
  )

  const [actions, setActions] = useState({ get: false })
  const [posSelected, setSelected] = useState({
    value: null,
    label: 'Sin seleccionar',
  })

  const [seeQR, setQR] = useState(false)
  const [alert, setAlert] = useState({ title: '' })

  const [item, setItem] = useState({
    comercialAddress: null,
    description: null,
    contactEmail: null,
    phone: '',
    whatsapp: '',
    webSite: null,
    facebook: null,
    instagram: null,
    history: null,
  })

  const [errors] = useState({})
  const [edit, setEdit] = useState(false)

  const {
    qr,
    url,
    comercialName,
    comercialAddress,
    description,
    contactEmail,
    phone,
    whatsapp,
    webSite,
    facebook,
    instagram,
    history,
  } = item

  useEffect(() => {
    // dispatch(getFiles(imageTypes.LOGO, companyId, companyId));
    // dispatch(getFiles(imageTypes.COMPANY, companyId, companyId, 2));
    // dispatch(getEditableFields());
    dispatch(getAllPOS())
  }, [])

  useEffect(() => {
    if (loading) setActions({ ...actions, get: true })
    else if (actions.get) {
      setActions({ ...actions, get: false })
      setItem({ ...fields })
    }
  }, [loading])

  const getPosProfileValues = ({ value }) => {
    dispatch(getFiles(imageTypes.POS_LOGO, value, companyId))
    dispatch(getFiles(imageTypes.POS_OUTSTANDING, value, companyId, 2))
    dispatch(getPosProfile(value))
  }

  const setLinkAlert = type => {
    if (type === 2) window.open(url, '_blank')
    else {
      let aux = document.createElement('input')
      aux.setAttribute('value', url)
      document.body.appendChild(aux)
      aux.select()
      document.execCommand('copy')
      document.body.removeChild(aux)

      setAlert({
        title: 'Link copiado',
        show: true,
        type: 'success',
        text: 'El link fue copiado y guardado en el porta papeles.',
        confirmButtonText: 'Cerrar',
        confirmButtonColor: '#226095',
        onConfirm: () => {
          setAlert({ ...alert, show: false })
        },
      })
    }
  }

  return (
    <div>
      <div className={'pos-title'}>
        <Title title={'Perfil del punto de venta'} />
        <div className={'pos-select'}>
          <CustomSelect
            label={'Punto de Venta'}
            options={allPOS}
            value={posSelected}
            loading={loadingAllPOS}
            onChange={s => {
              setSelected(s)
              getPosProfileValues(s)
            }}
          />
        </div>
      </div>

      <Card
        title={'DETALLES DEL PUNTO DE VENTA'}
        button={
          qr ? (
            <Icon
              icon={faQrcode}
              tooltip={'QR del Punto de venta'}
              onClick={() => setQR(true)}
            />
          ) : null
        }>
        <Row>
          <Col xl={4} lg={4} md={6} sm={12}>
            <Row>
              <Col xl={12}>
                <div>
                  <div className={'b-user-name'}>Logo del Punto de venta</div>
                  <ImageInput
                    imageType={imageTypes.POS_LOGO}
                    entityId={posSelected.value}
                    src={
                      logo && logo.length > 0
                        ? { ...logo[0], url: logo[0].original }
                        : null
                    }
                    onSuccessFile={() => undefined}
                    disabled={!edit}
                  />
                </div>
              </Col>
              <Col xl={12}>
                <FormText
                  disabled={!edit}
                  label={'Nombre del establecimiento'}
                  value={comercialName}
                  changeValue={v => setItem({ ...item, comercialName: v })}
                />
              </Col>
              <Col xl={12}>
                <FormText
                  disabled={!edit}
                  label={'Dirección del establecimiento'}
                  value={comercialAddress}
                  changeValue={v => setItem({ ...item, comercialAddress: v })}
                />
              </Col>
              <Col xl={12}>
                <FormText
                  disabled={!edit}
                  label={'Email de contacto'}
                  value={contactEmail}
                  changeValue={v => setItem({ ...item, contactEmail: v })}
                  error={
                    !contactEmail || contactEmail === ''
                      ? errors.contactEmail
                      : !valEmail(contactEmail)
                      ? 'Formato de correo electrónico incorrecto'
                      : null
                  }
                />
              </Col>
              <Col xl={12}>
                <FormText
                  disabled={!edit}
                  label={'Telefono de contacto'}
                  value={phone}
                  changeValue={v => {
                    if (!isNaN(v)) {
                      setItem({ ...item, phone: v })
                    }
                  }}
                  type={'tel'}
                />
              </Col>
              <Col xl={12}>
                <FormText
                  disabled={!edit}
                  label={'Whatsapp'}
                  value={whatsapp}
                  changeValue={v => {
                    if (!isNaN(v)) {
                      setItem({ ...item, whatsapp: v })
                    }
                  }}
                  type={'tel'}
                />
              </Col>
              <Col xl={12}>
                <FormText
                  disabled={!edit}
                  label={'Sitio web'}
                  value={webSite}
                  changeValue={v => {
                    setItem({ ...item, webSite: v })
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={8} lg={8} md={6} sm={12}>
            <Row>
              <Col xl={12}>
                <div className={'b-user-name'}>Imagenes destacadas</div>
                <div className={'d-flex pb-3'} style={{ overflowX: 'auto' }}>
                  {imgList &&
                    imgList.map((img, index) => {
                      return (
                        <div key={index} className={'mr-3'}>
                          <ImageInput
                            disabled={!edit}
                            imageType={imageTypes.POS_OUTSTANDING}
                            entityId={posSelected.value}
                            src={{ ...img, url: img.original }}
                            onSuccessFile={() => undefined}
                            gallery={2}
                          />
                        </div>
                      )
                    })}

                  <ImageInput
                    disabled={!edit}
                    imageType={imageTypes.POS_OUTSTANDING}
                    entityId={posSelected.value}
                    gallery={2}
                  />
                </div>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12}>
                <FormText
                  disabled={!edit}
                  label={'Facebook'}
                  value={facebook}
                  changeValue={v => {
                    setItem({ ...item, facebook: v })
                  }}
                />
              </Col>
              <Col xl={6} lg={6} md={12} sm={12}>
                <FormText
                  disabled={!edit}
                  label={'Instagram'}
                  value={instagram}
                  changeValue={v => {
                    setItem({ ...item, instagram: v })
                  }}
                />
              </Col>
              <Col xl={12}>
                <FormText
                  disabled={!edit}
                  label={'Acerca de la empresa'}
                  value={description}
                  as={'textarea'}
                  rows={'6'}
                  changeValue={v => {
                    setItem({ ...item, description: v })
                  }}
                />
              </Col>
              <Col xl={12}>
                <FormText
                  disabled={!edit}
                  label={'Historia de la empresa'}
                  value={history}
                  as={'textarea'}
                  rows={'6'}
                  changeValue={v => {
                    setItem({ ...item, history: v })
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>

      <Modal show={seeQR} size={'md'} centered onHide={() => setQR(false)}>
        <Modal.Header closeButton>
          <Modal.Title>QR del Punto de venta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <FormText
                value={url}
                disabled
                prepend={
                  <Icon
                    icon={faCopy}
                    tooltip={'Copiar'}
                    onClick={() => setLinkAlert(1)}
                  />
                }
                append={
                  <Icon
                    icon={faGlobe}
                    onClick={() => setLinkAlert(2)}
                    tooltip={'Abrir en navegador'}
                  />
                }
              />
            </Col>
            <Col xl={12}>
              <img
                alt={'terminal qr'}
                style={{ width: '100%', height: '100%' }}
                src={`data:image/bmp;base64,${qr}`}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <FAB
        show={posSelected.value}
        icon={edit ? faSave : faEdit}
        onClick={() => {
          if (edit) {
            dispatch(
              updatePosProfile(posSelected.value, {
                ...item,
              }),
            )
          }
          setEdit(!edit)
        }}
        dontToggle
        loading={loading}
      />

      <Alert {...alert} />
    </div>
  )
}
export default ProfilePOS
