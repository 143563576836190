import React, { useState, useEffect } from 'react'

import { Modal, Row } from 'react-bootstrap'
import { Button, FormText, Switch, Icon, Select, Card } from '../../components'
import {
  faCalendarCheck,
  faKeyboard,
  faSave,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { actionTypes, createSubscription } from 'src/actions/report.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerSuccess } from 'src/selectors/error.selector'
import Alert from 'sweetalert-react'

const days = [
  { value: 0, label: 'Domingo' },
  { value: 1, label: 'Lunes' },
  { value: 2, label: 'Martes' },
  { value: 3, label: 'Miercoles' },
  { value: 4, label: 'Jueves' },
  { value: 5, label: 'Viernes' },
  { value: 6, label: 'Sabado' },
]

const types = [
  { value: 1, label: 'Díario' },
  { value: 2, label: 'Semanal' },
  { value: 3, label: 'Mensual' },
]

const graphType = [
  { value: 0, label: 'Barra' },
  { value: 1, label: 'Lineal' },
]

// type: 1 create, 2 see
const CreateSubscription = ({
  show,
  modal,
  amount,
  type,
  onCreate,
  onHide,
  data,
  subscriptionType,
}) => {
  const dispatch = useDispatch()

  const [showModal, setModal] = useState(false)
  const [subscription, setSubscription] = useState({})
  const [action, setAction] = useState({ create: false })
  const [alert, setAlert] = useState({ show: false })

  const loading = useSelector(state =>
    loadingSelector([actionTypes.CREATE_SUBSCRIPTION])(state),
  )

  const { alias, recurrence, automatic, payed } = subscription

  useEffect(() => {
    if (show) {
      if (modal) setModal(true)
      if (type === 1) setSubscription({})
      else if (type === 2) setSubscription(data)
    }
  }, [show])

  useEffect(() => {
    if (loading) setAction({ create: true })
    else if (action.create) {
      setAction({ create: false })
      setAlert({
        ...handlerSuccess('Subscripción creada'),
        onConfirm: () => {
          hide()
          setAlert({ show: false })
        },
      })
    }
  }, [loading])

  useEffect(() => {
    if (subscriptionType && data) {
      setSubscription({
        ...data,
        config: types[data.config],
        date: days[new Date(data.nextDate).getDay()],
        type: types[data.type],
        graphType: graphType[data.graphType],
      })
    }
  }, [data])

  const getRender = () => {
    return (
      <Row style={{ margin: '0px 5px' }}>
        <FormText
          value={alias}
          label={'Alias'}
          required
          disabled={data}
          onChange={({ target }) =>
            setSubscription({ ...subscription, alias: target.value })
          }
          prepend={<Icon icon={faKeyboard} />}
          max={50}
        />
        {subscriptionType === 1 && (
          <>
            {/*                    <Col xl={12} md={12} sm={12}><FormText
                      value={alias} label={'Alias'} required
                      onChange={({target}) => setSubscription({...subscription, alias: target.value})}
                      prepend={<Icon icon={faKeyboard}/>} max={50}
                    /></Col>*/}
            <FormText value={amount} disabled label={'Importe'} prependMoneySymbol />
            {/*<CustomDate
                      disabledDays={{before: new Date()}}
                      disabled={type !== 1}
                      label={'Selecciona fecha de finalizacion'} value={subscription.endDate} required prepend
                      onDayChange={(endDate) => setSubscription({...subscription, endDate})}
                    />*/}
            {/*<Col xl={12} md={12}><h6>Definir periodicidad</h6><div className={'column mt-4'}>
                        <label>Cada <input
                          type={'number'} placeholder={'días'} value={paymentDay} style={{margin: '0px 6px', width: 40}}
                          onChange={(event) => {
                              let {value} = event.target;
                              if (!value || value < 0) value = 0;
                              setSubscription({...subscription, paymentDay: value});
                          }}
                        />días se hara el cobro.</label>
                    </div></Col>*/}
            <FormText
              type={'number'}
              label={'Definir un número de veces la recurrencia'}
              required
              prepend={<Icon icon={faCalendarCheck} />}
              value={recurrence}
              onChange={event => {
                let { value } = event.target
                if (!value || value < 0) value = 0

                if (type === 2 && value < payed) value = payed

                setSubscription({ ...subscription, recurrence: value })
              }}
              sub={type === 2 ? 'Valor minimo: ' + payed : null}
            />
            <Switch
              topLabel
              label={'Cobro automatico'}
              checked={automatic}
              onChange={event =>
                setSubscription({
                  ...subscription,
                  automatic: event.target.checked,
                })
              }
            />
          </>
        )}
        {subscriptionType && (
          <>
            <Select
              label={'Tipo de suscripción'}
              options={types}
              disabled={data}
              value={subscription.config}
              onChange={value => {
                setSubscription({ ...subscription, config: value, type: null })
              }}
              required
            />
            {subscription.config ? (
              subscription.config.value === 1 ? (
                <Card
                  style={{
                    fontSize: 15,
                    textAlign: 'center',
                    color: '#a21e8c',
                    marginTop: 5,
                  }}>
                  {subscriptionType === 2
                    ? 'Se le enviará un correo diario en el cual recibirá un reporte al final de cada día'
                    : 'Se creará diariamente una orden de venta con la información de la orden actual'}
                </Card>
              ) : subscription.config.value === 2 ? (
                <>
                  <Select
                    label={'Día de suscripción'}
                    info={
                      'Esta suscripción dará inicio la siguiente fecha según la configuración seleccionada'
                    }
                    options={days}
                    value={subscription.date}
                    disabled={data}
                    onChange={value => {
                      setSubscription({ ...subscription, date: value })
                    }}
                  />
                  <Card
                    style={{
                      fontSize: 15,
                      textAlign: 'center',
                      color: '#a21e8c',
                      marginTop: 5,
                    }}>
                    {subscriptionType === 2
                      ? 'Se le enviará un correo semanalmente en el cual recibirá un reporte el día seleccionado'
                      : 'Se creará semanalmente una orden de venta con la información de la orden actual, el día seleccionado'}
                  </Card>
                </>
              ) : (
                <Card
                  style={{
                    fontSize: 15,
                    textAlign: 'center',
                    color: '#a21e8c',
                    marginTop: 5,
                  }}>
                  {subscriptionType === 2
                    ? 'Se le enviará un correo mensualmente en el cual recibirá un reporte al finalizar el mes'
                    : 'Se creará mensualmente una orden de venta con la información de la orden actual, al finalizar el mes'}
                </Card>
              )
            ) : (
              ''
            )}
            {subscriptionType === 2 &&
              subscription.config &&
              subscription.config.value > 1 && (
                <Select
                  label={'Vista de la gráfica'}
                  info={'Esta opción define como se desglosará la gráfica'}
                  options={subscription.config.value === 1 ? [types[1], types[2]] : types}
                  value={subscription.type}
                  disabled={data}
                  onChange={value => {
                    setSubscription({ ...subscription, type: value })
                  }}
                />
              )}
            {subscriptionType === 2 && (
              <Select
                label={'Tipo de gráfica'}
                options={graphType}
                value={subscription.graphType}
                disabled={data}
                onChange={value => {
                  setSubscription({ ...subscription, graphType: value })
                }}
                required
              />
            )}
          </>
        )}
        <Alert {...alert} />
      </Row>
    )
  }

  const hide = (create, request) => {
    setModal(false)
    if (!create) onHide()
    else onCreate(request || subscription)
  }

  return (
    <div>
      {modal ? (
        <Modal show={showModal} centered>
          <Modal.Header>
            <Modal.Title>
              {type === 1 ? 'Crear suscripción' : 'Editar suscripción'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{getRender()}</Modal.Body>
          <Modal.Footer>
            <Row className={'container-buttons'}>
              {!subscriptionType ? (
                <>
                  <Button
                    color={'secondary'}
                    icon={faWindowClose}
                    left
                    onClick={() => {
                      hide()
                    }}>
                    Cerrar
                  </Button>
                  <Button
                    color={'primary'}
                    icon={faSave}
                    left
                    onClick={() => {
                      hide(true)
                    }}
                    disabled={
                      !subscription.alias ||
                      !subscription.startDate ||
                      !subscription.paymentDay ||
                      subscription.paymentDay <= 0 ||
                      !subscription.recurrence ||
                      subscription.recurrence <= 0
                    }>
                    Guardar
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    color={'secondary'}
                    icon={faWindowClose}
                    left
                    onClick={() => {
                      hide()
                    }}>
                    Cerrar
                  </Button>
                  {!data && (
                    <Button
                      disabled={
                        !subscription.alias ||
                        !subscription.config ||
                        (subscriptionType === 1 && !subscription.recurrence) ||
                        (subscriptionType === 2 && !subscription.graphType) ||
                        loading
                      }
                      color={'primary'}
                      icon={faSave}
                      left
                      loading={loading}
                      onClick={() => {
                        let date = new Date()
                        let day = date.getDate() - date.getDay()
                        if (subscription.date) day = day + subscription.date.value
                        let request = {
                          alias: subscription.alias,
                          config: subscription.config.value,
                          day,
                          type:
                            subscriptionType === 2
                              ? subscription.config.value === 1
                                ? 1
                                : subscription.type.value
                              : 1,
                          graphType: subscription.graphType
                            ? subscription.graphType.value
                            : 0,
                          startDate: new Date().valueOf(),
                          recurrence:
                            subscriptionType === 1 ? subscription.recurrence : 0,
                        }
                        if (subscriptionType === 1) hide(true, request)
                        else dispatch(createSubscription(request))
                      }}>
                      Guardar
                    </Button>
                  )}
                </>
              )}
            </Row>
          </Modal.Footer>
        </Modal>
      ) : (
        getRender()
      )}
    </div>
  )
}
export default CreateSubscription
