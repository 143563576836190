import { $http } from './http'

class CashBoxesService {
  getCompanyCashBoxes = async () => {
    const response = await $http.get(`/cashbox`)
    return response.data
  }

  getCashBox = async cashBox => {
    const response = await $http.get(`/cashbox/${cashBox}`)
    return response.data
  }

  getAssignedCashBoxes = async () => {
    const response = await $http.get(`/cashbox/assigned`)
    return response.data
  }

  getAssignedCashBox = async cashBox => {
    const response = await $http.get(`/cashbox/assigned/${cashBox}`)
    return response.data
  }

  createCashBox = async (name, resolution = null) => {
    const response = await $http.post(`/cashbox`, { name, resolution })
    return response.data
  }

  editCashBox = async (cashBox, name) => {
    const response = await $http.put(`/cashbox/${cashBox}`, name)
    return response.data
  }

  assignResolution = async (cashBox, resolution) => {
    const response = await $http.put(`/cashbox/${cashBox}/resolution`, resolution)
    return response.data
  }

  removeResolution = async cashBox => {
    const response = await $http.delete(`/cashbox/${cashBox}/resolution`)
    return response.data
  }

  assignUser = async (cashBox, user) => {
    const response = await $http.put(`/cashbox/${cashBox}/user`, user)
    return response.data
  }

  removeUser = async cashBox => {
    const response = await $http.delete(`/cashbox/${cashBox}/user`)
    return response.data
  }

  assignCompany = async (cashBox, company) => {
    const response = await $http.put(`/cashbox/${cashBox}/company`, company)
    return response.data
  }

  removeCompany = async cashBox => {
    const response = await $http.delete(`/cashbox/${cashBox}/company`)
    return response.data
  }

  invoiceConciliation = async (request, index) => {
    const response = await $http.put('/invoices/conciliation', request, {
      params: { index },
    })
    return response.data
  }

  invoiceConciliationNullify = async (request, index) => {
    const response = await $http.put('/invoices/massive/nullify', request, {
      params: { index },
    })
    return response.data
  }

  invoiceConciliationCertify = async (request, index) => {
    const response = await $http.put('/invoices/massive/certify', request, {
      params: { index },
    })
    return response.data
  }
}

export default new CashBoxesService()
