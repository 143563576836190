import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  Row,
  Col,
  Modal,
  Container,
  InputGroup,
  Button,
  FormControl,
} from 'react-bootstrap'
import { Td, Tr } from 'react-super-responsive-table'
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import Alert from 'sweetalert-react'
import Recaptcha from 'react-recaptcha'

import ImageCarousels from '../../../../components/ImageCarousels/ImageCarousels'
import Card from '../../../../components/cards/Card'
import Icon from '../../../../components/buttons/IconButton'
import ButtonC from '../../../../components/buttons/Button'

import Food from '../../../../assets/images/svg/food.svg'

import {
  formatDateFromMillis,
  formatNumberWithCommas,
} from '../../../../utils/formatters'

import {
  actionTypes as typesS,
  addItems,
  onCreateOrder,
} from '../../../../actions/shopping.actions'
import {
  selectShopping,
  selectShoppingResponse,
} from '../../../../selectors/shopping.selector'

import {
  handlerError,
  handlerSuccess,
  singleErrorSelector,
  hasErrorsSelector,
} from '../../../../selectors/error.selector'
import { loadingSelector } from '../../../../selectors/loading.selector'
import moment from 'moment'
import { getCaptchaResponse } from '../../../../actions/clients.actions'
import FormTextField from '../../../../components/inputs/FormTextField/FormTextField'
import Money from '../../../../components/Money/Money'
import { selectCurrentCurrency } from '../../../../selectors/currencies.selector'
import { TableV2 } from '../../../../components'

class ShoppingCar extends Component {
  state = {
    modal: { show: false, item: {}, index: -1 },
    alert: { title: 'default' },
    showPayment: false,
    card: 0,
    recaptcha: '',
    month: 0,
    year: 0,
    cvv: 0,
  }

  componentWillReceiveProps(next) {
    const { loadingS, hasErrorS, handlerError, handlerSuccess, addItems, history } =
      this.props
    const { errorS, response } = next
    let alert = { title: 'default' }

    if (loadingS && !next.loadingS) {
      if (!hasErrorS && next.hasErrorS) {
        alert = handlerError(errorS.message || 'Ha ocurrido un error inesperado.')
        alert.onConfirm = () =>
          this.setState({
            alert: {
              ...alert,
              show: false,
            },
          })
      } else {
        alert = handlerSuccess(
          `La orden número: ${response.number}, ha sido creada satisfactoriamente.`,
        )
        alert.onConfirm = () => {
          this.setState({ alert: { ...alert, show: false } })
          addItems([])
          history.replace(`/cliente/solicitudes/detalle/${response.id}`)
        }
      }
    }

    this.setState({ alert })
  }

  setDataToModal = (show, item, index) => {
    this.setState({ modal: { show, item, index } })
  }

  onDeleteItem = index => {
    const { shopping, addItems, handlerError } = this.props
    let alert = handlerError('¿Está seguro de descartar este producto?.')
    alert.showCancelButton = true

    alert.onConfirm = () => {
      shopping.splice(index, 1)
      addItems(shopping)
      this.setState({ alert: { ...alert, show: false } })
    }

    alert.onCancel = () => this.setState({ alert: { ...alert, show: false } })
    this.setState({ alert })
  }

  handleInputChange = event => {
    const { name, value } = event.target
    if (name === 'year') this.setState({ year: 2000 + Number(value) })
    else this.setState({ [name]: Number(value) })
  }

  onKeyPress = event => {
    let e = event.key
    if (e.charCodeAt(0) >= 48 && e.charCodeAt(0) <= 57) return true
    event.preventDefault()
  }

  onChangeInput = value => {
    let { modal } = this.state
    let { show, item, index } = modal

    item.quantity = value
    if (item.quantity >= 0) item.subTotal = parseInt(value) * item.price
    else item.subTotal = 0
    this.setDataToModal(show, item, index)
  }

  addItem = () => {
    let { shopping, addItems } = this.props
    const { index, item } = this.state.modal

    shopping[index] = item
    addItems(shopping)
    this.setDataToModal(false, {}, -1)
  }

  getTotal = () => {
    const { shopping } = this.props
    let total = 0
    shopping.map(s => (total += parseInt(s.subTotal)))
    return formatNumberWithCommas(total)
  }

  createOrder = () => {
    const { shopping } = this.props
    let groups = []
    let indexS = []
    shopping.map(s => {
      let index = indexS.indexOf(s.offerId)
      if (index === -1) {
        indexS.push(s.offerId)
        let deliveryDate = null
        let polygonRoute = null
        const products = shopping
          .filter(sp => sp.offerId === s.offerId)
          .map(sp => {
            if (sp.deliveryDate)
              deliveryDate = moment(sp.deliveryDate, 'DD/MM/YYYY').valueOf()
            if (sp.polygonRoute) polygonRoute = sp.polygonRoute.id

            return {
              productId: sp.id,
              offerId: sp.offerId,
              quantity: sp.quantity,
              discount: 0,
            }
          })
        groups.push({ offerId: s.offerId, products, deliveryDate, polygonRoute })
      }
    })

    this.props.onCreateOrder({ groups })
  }

  callback = () => {
    console.log('Done!!!!')
  }

  verifyCallback = response => {
    this.setState({ recaptcha: response })
  }

  expiredCallback = () => {
    this.setState({ recaptcha: '' })
  }

  luhnAlgorithm() {
    const { card } = this.state
    let test = card.toString().split(''),
      aux,
      result = 0
    for (let i = test.length - 1; i >= 0; i--) {
      if ((i + 1) % 2 === 1) {
        aux = (Number(test[i]) * 2).toString().split('')
        if (aux.length === 2) test[i] = (Number(aux[0]) + Number(aux[1])).toString()
        else test[i] = aux[0]
      }
      result += Number(test[i])
    }
    if (result % 10 === 0) {
      return true
    }
    return false
  }

  compareDate = () => {
    const { month, year } = this.state
    let date = new Date()
    if (date.getFullYear() < year) return true
    if (date.getFullYear() === year) if (date.getMonth() + 1 < month) return true
    return false
  }

  validateCard = () => {
    const { recaptcha } = this.state
    if (this.luhnAlgorithm() && recaptcha != '' && this.compareDate()) {
      console.log('tarjeta valida')
    }
  }

  getHeaders = () => {
    return [
      {
        label: '',
        show: true,
        custom: item => (
          <img width={75} height={75} src={item.thumbnail || Food} alt={item.name} />
        ),
        className: 'mini',
      },
      {
        label: 'Productor',
        show: true,
        value: ['companyName'],
        type: 'text',
        className: 'medium',
      },
      {
        label: 'Producto',
        show: true,
        value: ['name'],
        type: 'text',
        className: 'medium',
      },
      {
        label: 'Descripción',
        show: true,
        value: ['description'],
        type: 'text',
        className: 'short',
      },
      {
        label: 'Cantidad',
        show: true,
        value: ['quantity'],
        type: 'number',
        className: 'mini',
      },
      {
        label: 'Precio',
        show: true,
        value: ['price'],
        type: 'currency',
        className: 'mini',
      },
      {
        label: 'Sub total',
        show: true,
        value: ['subTotal'],
        type: 'currency',
        className: 'mini',
      },
      {
        label: 'Entrega estimada',
        show: true,
        value: ['deliveryDate'],
        type: 'text',
        className: 'mini',
      },
      {
        config: true,
        show: true,
        label: '',
        className: 'mini center',
        custom: (item, index) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
            }}
          >
            <Icon
              tooltip={'Modificar'}
              icon={faPencilAlt}
              onClick={() => this.setDataToModal(true, item, index)}
            />
            <Icon
              tooltip={'Descartar'}
              icon={faTrashAlt}
              onClick={() => this.onDeleteItem(index)}
            />
          </div>
        ),
      },
    ]
  }

  render() {
    const { shopping, loadingS, currentCurrency } = this.props
    const { alert, modal, showPayment, recaptcha } = this.state

    const { show, item } = modal

    //console.log(shopping)

    return (
      <div style={{ marginBottom: 5 }}>
        <h1 className={'dashboard-welcome'}>Carrito de compras</h1>

        <Row>
          <Col xl={2} lg={2} md={2} sm={12} xs={12} />
          <Col xl={8} lg={8} md={12} sm={12} xs={12}>
            <Card bodyStyle={{ padding: '0px 8px' }} title={'Detalles de tu pedido'}>
              <div className={'column'}>
                <TableV2 items={shopping} headers={this.getHeaders()} mobileAuto />

                <br />

                <Money component="span" className={'detail-text short'}>
                  <b>Total:</b> {this.getTotal()}
                </Money>
                <br />

                <ButtonC
                  loading={loadingS}
                  disabled={shopping.length <= 0}
                  onClick={() => this.createOrder()}
                >
                  {' '}
                  Crear solicitud de compra.
                </ButtonC>
                {/*
                                    <ButtonC disabled={shopping.length <= 0}
                            onClick={() => this.setState({showPayment: true})}>Pagar con tarjeta</ButtonC>
                                */}
              </div>
            </Card>
          </Col>
          <Col xl={2} lg={2} md={2} sm={12} xs={12} />
        </Row>

        <Modal
          show={show}
          size={'md'}
          centered
          onHide={() => this.setDataToModal(false, {}, -1)}
        >
          <Modal.Header>
            {show && item.images.length > 0 ? (
              <ImageCarousels images={item.images} />
            ) : (
              <Modal.Title>{item.name}</Modal.Title>
            )}
          </Modal.Header>

          <Modal.Body>
            <Container>
              <div className={'column'}>
                {show && item.images.length > 0 && (
                  <div align={'center'}>
                    <h3>{item.name}</h3>
                  </div>
                )}
                <div align={'center'}>
                  <h5>{item.description}</h5>
                </div>

                <span>
                  <strong>Productor: </strong>
                  {item.companyName}
                </span>
                <Money component="span">
                  <strong>Precio: </strong>
                  {item.price}
                </Money>
                <Money component="span">
                  <strong>Subtotal: </strong>
                  {item.subTotal}
                </Money>
                {item.deliveryDate && (
                  <span>
                    <strong>Entrega estimada: </strong>
                    {item.deliveryDate}
                  </span>
                )}
              </div>
              <br />

              <div>
                <InputGroup className={'mb-3'}>
                  <InputGroup.Prepend>
                    <Button
                      onClick={() =>
                        this.onChangeInput(
                          (item.quantity ? parseInt(item.quantity) : 0) - 1,
                        )
                      }
                      variant={'outline-secondary'}
                    >
                      -
                    </Button>
                  </InputGroup.Prepend>
                  <FormControl
                    aria-describedby={'basic-addon1'}
                    value={item.quantity}
                    type={'number'}
                    name={'quantity'}
                    onChange={event => this.onChangeInput(event.target.value)}
                  />
                  <InputGroup.Append>
                    <Button
                      onClick={() =>
                        this.onChangeInput(
                          (item.quantity ? parseInt(item.quantity) : 0) + 1,
                        )
                      }
                      variant={'outline-secondary'}
                    >
                      +
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </Container>
          </Modal.Body>

          <Modal.Footer>
            <div style={{ textAlign: 'right' }}>
              <Button
                onClick={() => this.addItem()}
                disabled={item.quantity <= 0 || !item.quantity}
              >
                Agregar al carrito
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showPayment}
          size={'sm'}
          centered
          onHide={() => this.setState({ showPayment: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Pago con tarjeta</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Container>
              <Row>
                <Col>
                  <FormTextField
                    label={'Tarjeta de crédito'}
                    type={'number'}
                    placeholder={'Tarjeta de Crédito'}
                    name={'card'}
                    onChange={this.handleInputChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={7} md={7} xl={7} style={{ marginLeft: 8 }}>
                  <label className={'form-label'}>Fecha de vencimiento</label>
                </Col>
                <Col>
                  <label className={'form-label'}>CVV</label>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={7}
                  md={7}
                  xl={7}
                  style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: 8 }}
                >
                  <input
                    type={'text'}
                    name={'month'}
                    className={'form-input'}
                    style={{ height: 30, width: '40%' }}
                    onChange={this.handleInputChange}
                    placeholder={'MM'}
                    autoComplete={false}
                    maxLength={2}
                    onKeyPress={this.onKeyPress}
                  />
                  <span style={{ fontSize: 25 }}>/</span>
                  <input
                    type={'text'}
                    name={'year'}
                    style={{ height: 30, width: '40%' }}
                    className={'form-input'}
                    onChange={this.handleInputChange}
                    placeholder={'YY'}
                    autoComplete={false}
                    maxLength={2}
                    onKeyPress={this.onKeyPress}
                  />
                </Col>
                <Col>
                  <input
                    type={'text'}
                    name={'cvv'}
                    style={{ height: 30, marginRight: 15 }}
                    className={'form-input'}
                    placeholder={'CVV'}
                    onChange={this.handleInputChange}
                    autoComplete={false}
                    maxLength={4}
                    onKeyPress={this.onKeyPress}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                  <Recaptcha
                    sitekey="6Lf5PuQUAAAAALBsE2od18sDl7yUQNGdv_OebMKW"
                    render="explicit"
                    verifyCallback={this.verifyCallback}
                    onloadCallback={this.callback}
                    expiredCallback={this.expiredCallback}
                    size={'compact'}
                    theme={'dark'}
                  />
                </Col>
              </Row>
            </Container>
          </Modal.Body>

          <Modal.Footer>
            <div style={{ textAlign: 'right' }}>
              <Button onClick={() => this.validateCard()} disabled={recaptcha === ''}>
                Pagar
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        <Alert {...alert} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  shopping: selectShopping(state),
  handlerError: message => handlerError(message),
  handlerSuccess: message => handlerSuccess(message),
  loadingS: loadingSelector([typesS.ON_CREATE_ORDER])(state),
  hasErrorS: hasErrorsSelector([typesS.ON_CREATE_ORDER])(state),
  errorS: singleErrorSelector([typesS.ON_CREATE_ORDER])(state),
  response: selectShoppingResponse(state),
  currentCurrency: selectCurrentCurrency(state),
})
const mapDispatchToProps = dispatch => ({
  addItems: shopping => dispatch(addItems(shopping, true)),
  onCreateOrder: products => dispatch(onCreateOrder(products)),
  getCaptchaResponse: secretKey => dispatch(getCaptchaResponse(secretKey)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCar)
