import './OrderDetail.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getProducts } from '../../../actions/products.actions'
import { selectAllProducts } from '../../../selectors/products.selector'
import { getAssignedCashBoxes } from '../../../actions/cashboxes.actions'
import { selectAssignedCashBoxes } from '../../../selectors/cashBoxes.selector'

import {
  actionTypes as warehouseAction,
  createTransferByGroup,
  createTransferByOrder,
  getExternalActiveWarehouses,
  getWarehouses,
} from '../../../actions/warehouse.actions'
import {
  selectAllWarehousesForSelect,
  selectExternalActiveWarehousesForSelect,
} from '../../../selectors/warehouse.selector'

import { formatDateFromMillis, formatNumberWithCommas } from '../../../utils/formatters'

import {
  actionTypes as orderTypes,
  confirmSingleOrder,
  onConfirmOrderPayment,
  deleteSingleOrder,
  getAllOrderTypes,
  getSingleOrder,
  convertPresale,
  getGroup,
  changeStatus,
  processOrder,
  actionTypes as typesOder,
  getProductExistenceByOrder,
  getGroupByPackages,
  reGroupOrder,
  changeStatusByPackage,
  savePackage,
} from '../../../actions/orders.actions'
import {
  selectConfirmSingleOrder,
  selectGetAllOrderStatus,
  selectGetAllOrderTypes,
  selectGetSingleGroup,
  selectGetSingleGroupByPackages,
  selectGetSingleOrder,
  selectProductExistence,
  selectSinglePackage,
} from '../../../selectors/orders.selector'

import { getSingleWarehouse } from '../../../actions/warehouse.actions'
import { selectSingleWarehouse } from '../../../selectors/warehouse.selector'

import { hasErrorsSelector, singleErrorSelector } from '../../../selectors/error.selector'
import { loadingSelector } from '../../../selectors/loading.selector'

import { getDiffBetweenPoints } from '../../../selectors/geolocation.selecteor'

import Card from '../../../components/cards/Card'
import IconButton from '../../../components/buttons/IconButton'
import MapCard from '../../../components/maps/MapCard'
import Button from '../../../components/buttons/Button'
import Select from '../../../components/inputs/Select/CustomSelect'

import {
  faTrashAlt,
  faClipboardCheck,
  faMapMarkedAlt,
  faReceipt,
  faCheckCircle,
  faBox,
  faBook,
  faPlus,
  faSave,
  faTimesCircle,
  faTruck,
} from '@fortawesome/free-solid-svg-icons'
import { Row, Col, Modal, Container, Tab } from 'react-bootstrap'
import { Decimal } from 'decimal.js'
import { geolocated } from 'react-geolocated'
import SweetAlert from 'sweetalert-react'
import moment from 'moment'

import { selectCurrentModule } from '../../../selectors/user.selector'

import { selectGetSingleCompany } from '../../../selectors/company.selector'

import {
  downloadPrintableInvoice,
  downloadPrintableInvoiceByOrder,
  sendInvoiceEmail,
} from '../../../actions/resolutions.actions'
import CustomDatePicker from '../../../components/CustomDatePicker/CustomDatePicker'
import {
  getAllDeliverers,
  getAllDeliverersByProducer,
} from '../../../actions/clients.actions'
import { selectDeliverersToSelect } from '../../../selectors/clients.selector'
import { sendNotifications } from '../../../actions/notifications.actions'
import { selectPlayerId } from '../../../selectors/notifications.selector'
import Tabs from 'react-bootstrap/Tabs'
import TextField from '../../../components/inputs/FormTextField/FormText'
import Icon from '../../../components/buttons/IconButton'
import { getContractedCompanies } from '../../../actions/contracts.actions'
import { companies } from '../../../selectors/contracts.selector'
import { TableV2, Title } from '../../../components'
import Money from '../../../components/Money/Money'
import { selectCurrentCurrency } from '../../../selectors/currencies.selector'
import { toMoney } from '../../../utils/utilities'

class OrdersDetails extends Component {
  state = {
    showCashBox: false,
    showInvoice: false,
    showPresale: false,
    showAlert: false,
    showConfirm: false,
    showDelete: false,
    selectedCashBox: {},
    remove: false,
    payed: false,
    alert: {
      show: false,
      title: 'default',
      text: 'Default',
      type: 'info',
    },
    warehouseS: { value: null, label: '- ninguna -' },
    errors: {},
    deliverer: { value: null, label: '- no seleccionado -' },
    isPreSale: true,
    showEmail: false,
    key: 1,
    isGroup: false,
    process: false,
    packages: [],
    group: {
      show: false,
      from: 0,
      warehouseDisabled: false,
      deliverer: 0,
      orders: [],
      groups: [],
      quantity: 0,
      description: '',
    },
    details: [],
    selectedPackage: {},
    showPackage: false,
    showTransfer: false,
    internal: true,
    external: false,
    selected: {},
    orderPresentation: true,
    packagesPresentation: false,
    showPackageExistence: false,
  }

  componentDidMount() {
    if (this.props.id) {
      this.props.getSingleOrder(this.props.id)
    } else if (this.props.match.params.id) {
      this.props.getProducts()
      this.props.getAssignedCashBoxes()
      this.props.getSingleOrder(this.props.match.params.id)
      this.props.getWarehouses()
      this.props.getDeliverers(this.props.currentModule)
    }
    //this.props.getAllOrderTypes();
  }

  componentWillReceiveProps(next) {
    const {
      paymentIsLoading,
      paymentHasError,
      confirmIsLoading,
      confirmHasError,
      deleteIsLoading,
      deleteHasError,
      presaleIsLoading,
      presaleHasError,
      statusIsLoading,
      statusHasError,
      loadingGroup,
      loadingHasError,
      transferIsLoading,
      transferHasError,
      loadingExistence,
      singlePackage,
    } = this.props
    const {
      paymentError,
      confirmError,
      deleteError,
      presaleError,
      order,
      statusError,
      loadingError,
      transferError,
      existence,
    } = next
    let { alert, isGroup, group, details } = this.state
    if (deleteIsLoading && !next.deleteIsLoading) {
      if (!deleteHasError && next.deleteHasError) {
        alert = this.handlerError(
          deleteError.response
            ? deleteError.response.data.message
            : 'Ha ocurrido un error inesperado.',
        )
      } else {
        alert = this.handlerSuccess(
          `La orden de ${
            order.orderTypeData && order.orderTypeData.name
          } fue eliminada satisfactoriamente.`,
        )
        this.props.getSingleOrder(this.props.match.params.id)
      }
      this.setState({ alert })
    }
    if (confirmIsLoading && !next.confirmIsLoading) {
      if (!confirmHasError && next.confirmHasError) {
        alert = this.handlerError(
          confirmError.response
            ? confirmError.response.data.message
            : 'Ha ocurrido un error inesperado.',
        )
      } else {
        this.setState({ showInvoice: true })
        //alert = this.handlerSuccess(`La orden de ${this.findOrderType(order.type)} fue confirmada satisfactoriamente.`);
        this.sendNotification(
          this.props.order.number,
          1,
          Number(this.props.match.params.id),
        )
      }
      this.setState({ alert })
    }
    if (paymentIsLoading && !next.paymentIsLoading) {
      if (!paymentHasError && next.paymentHasError) {
        alert = this.handlerError(
          paymentError.response
            ? paymentError.response.data.message
            : 'Ha ocurrido un error inesperado.',
        )
      } else {
        alert = this.handlerSuccess(
          `La orden de ${
            order.orderTypeData && order.orderTypeData.name
          } fue confirmada satisfactoriamente.`,
        )
        this.props.getSingleOrder(this.props.match.params.id)
      }
      this.setState({ alert })
    }
    if (presaleIsLoading && !next.presaleIsLoading) {
      if (!presaleHasError && next.paymentHasError) {
        alert = this.handlerError(
          presaleError.response
            ? presaleError.response.data.message
            : 'Ha ocurrido un error inesperado.',
        )
      } else {
        alert = this.handlerSuccess(`La orden se asignó como preventa.`)
        this.props.getSingleOrder(this.props.order.orderId)
      }
      this.setState({ alert })
    }

    const { loading } = this.props

    if (loading && !next.loading) {
      let deliverer = { value: null, label: '- no seleccionado -' }

      if (order.client && order.client.delivererData) {
        deliverer = {
          value: order.client.delivererData.id,
          label: order.client.delivererData.name,
        }
      }
      order.details.map(d => {
        d.existence = false
      })
      this.setState({ deliverer })

      if (order.groupId) {
        if (singlePackage.id)
          this.props.getProductExistenceByOrder(order.warehouse.id, [order.orderId])
        this.setState({ isGroup: true })
        this.props.getGroup(order.orderId)
        this.props.getGroupByPackage(order.orderId)
      }
    }

    if (statusIsLoading && !next.statusIsLoading) {
      if (!statusHasError && next.statusHasError) {
        alert = this.handlerError(
          statusError.response
            ? statusError.response.data.message
            : 'Ha ocurrido un error inesperado.',
        )
      } else {
        alert = this.handlerSuccess(`Paquete validado`)
        this.props.savePackage()
        this.props.getSingleOrder(this.props.order.orderId)
        this.setState({ showPackageExistence: false })
      }
      this.setState({ alert })
    }

    if (loadingGroup && !next.loadingGroup) {
      if (!loadingHasError && next.loadingHasError) {
        alert = this.handlerError(
          loadingError.message
            ? loadingError.message
            : 'Ha ocurrido un error inesperado.',
        )
      } else {
        alert = this.handlerSuccess('Orden procesada')
        this.setState({ group: { ...group, show: false } })
        this.props.getSingleOrder(this.props.match.params.id)
      }
      this.setState({ alert })
    }

    if (transferIsLoading && !next.transferIsLoading) {
      if (!transferHasError && next.transferHasError) {
        alert = this.handlerError(
          transferError.message
            ? transferError.message
            : 'Ha ocurrido un error inesperado.',
        )
      } else {
        alert = this.handlerSuccess('Se ha creado una transferencia de la orden')
        this.props.getSingleOrder(this.props.match.params.id)
      }
      this.setState({ alert, showTransfer: false })
    }

    if (loadingExistence && !next.loadingExistence) {
      if (!singlePackage.id) {
        details.map(d => {
          existence.map(e => {
            if (e.product === d.product.id) {
              d.existence = e.existence >= e.quantity
            }
          })
        })
        this.setState({ group: { ...group, show: true }, details })
      } else {
        singlePackage.details.map(d => {
          existence.map(e => {
            if (e.product === d.product) {
              d.existence = e.existence >= e.quantity
            }
          })
        })
        this.setState({ showPackageExistence: true })
      }
    }
  }

  sendNotification(number, type, id) {
    const { playerId } = this.props
    let request = {
      headings: `Se ha realizado una venta`,
      body: `Vendió la orden No.${number}`,
      type: type,
      linkedId: id,
    }
    this.props.sendNotification(request)
  }

  handlerError = message => {
    return { show: true, title: 'Uh-Oh..', text: message, type: 'warning' }
  }

  handlerSuccess = message => {
    return { show: true, title: 'Yeah!', text: message, type: 'success' }
  }

  getOrderTotal = (details, type, balace) => {
    if (type === 3) return formatNumberWithCommas(balace * -1)
    if (!details) return formatNumberWithCommas(0)
    let total = 0
    details.map(d => (total += parseFloat(d.total) - parseFloat(d.discount)))
    return formatNumberWithCommas(total)
  }

  getGroupTotal = orders => {
    if (!orders) return formatNumberWithCommas(0)
    let total = 0
    orders.map(o => {
      o.details.map(d => (total += parseFloat(d.total) - parseFloat(d.discount)))
    })
    return formatNumberWithCommas(total)
  }

  getHeadersDetail = (order, showStatus, content) => {
    const headers = []
    showStatus &&
      headers.push({
        label: '',
        show: true,
        value: [],
        className: 'mini',
        custom: item => (
          <IconButton
            icon={faCheckCircle}
            tooltip={item.received ? 'Recibido' : 'Pendiente'}
            color={item.received ? 'green' : 'red'}
            onClick={() => this.props.changeStatus(item.id)}
          />
        ),
      })
    if (!content) {
      if (!(order.status !== 8 && order.status !== 7)) {
        headers.push({
          label: 'Paquete',
          show: true,
          value: ['number'],
          type: 'code',
          className: 'mini',
          custom: item => item.number !== 0 && `No. ${item.number}`,
        })
      }
    } else {
      if (order)
        headers.push({
          label: 'Paquete',
          show: true,
          value: ['number'],
          type: 'code',
          className: 'mini',
          custom: item => item.number !== 0 && `No. ${item.number}`,
        })
      else
        headers.push({
          label: 'Orden',
          show: true,
          value: ['number'],
          type: 'code',
          className: 'mini',
          custom: item => item.number !== 0 && `No. ${item.number}`,
        })
    }

    headers.push(
      ...[
        {
          label: 'Cantidad',
          show: true,
          value: ['quantity'],
          type: 'text',
          className: 'mini',
        },
        {
          label: 'Producto',
          show: true,
          value: ['productoC'],
          type: 'text',
          className: 'mini',
          custom: item =>
            (item.productoC = item.productData
              ? item.productData.name
              : item.product
              ? item.product.name
              : 'N/A'),
        },
        {
          label: 'Utilidad Bruta',
          show: true,
          value: ['utilidadC'],
          type: 'text',
          className: 'mini',
          custom: item =>
            (item.utilidadC = item.purchase
              ? formatNumberWithCommas(item.purchase)
              : 'N/A'),
        },
        {
          label: 'Precio',
          show: true,
          value: ['precioC'],
          type: 'text',
          className: 'medium',
          custom: item => (item.precioC = toMoney(item.total / item.quantity)),
        },
        {
          label: 'Subtotal',
          show: true,
          value: ['total'],
          type: 'currency',
          className: 'mini',
        },
        {
          label: 'Descuento',
          show: true,
          value: ['discount'],
          type: 'currency',
          className: 'mini',
        },
        {
          label: 'Total',
          show: true,
          value: ['totalC'],
          type: 'text',
          className: 'medium',
          custom: item => (item.totalC = toMoney(item.total - item.discount)),
        },
        { config: true, show: true, label: '', className: 'mini center' },
      ],
    )
    return headers
  }

  allProductsReceived() {
    const { packages } = this.props
    let received = true
    packages.map(p => {
      p.details.map(d => {
        if (!d.received) received = false
      })
    })
    return received
  }

  buttons = () => {
    const { order, deleteIsLoading, confirmIsLoading } = this.props
    const { group, details } = this.state
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        {order.type === 1 && order.status === 7 && !order.deleted && (
          <IconButton
            tooltip={'Transferir Orden'}
            size={'2x'}
            icon={faTruck}
            onClick={() => {
              this.setState({ showTransfer: true })
            }}
          />
        )}
        {order.type === 1 &&
          (order.status === 1 || (order.status === 9 && this.allProductsReceived())) &&
          !order.deleted && (
            <IconButton
              style={{ marginRight: '15px' }}
              tooltip={'Procesar Orden'}
              size={'2x'}
              icon={faBox}
              onClick={() => {
                if (order.warehouse) {
                  group.from = { value: order.warehouse.id, label: order.warehouse.name }
                  group.warehouseDisabled = true
                  this.props.getProductExistenceByOrder(order.warehouse.id, [
                    order.orderId,
                  ])
                } else group.show = true

                if (order.status === 1) {
                  group.orders.push(order)
                  order.details.map(d => {
                    details.push(d)
                  })
                } else group.groups.push(order)
                this.setState({ group, details })
              }}
            />
          )}
        {order.type === 1 && order.status === 1 && !order.deleted && (
          <IconButton
            style={{ marginRight: '15px' }}
            tooltip={'Marcar como Preventa'}
            size={'2x'}
            icon={faReceipt}
            onClick={() => this.setState({ showPresale: true })}
          />
        )}
        {(order.status === 1 || order.status === 5) && !order.deleted && (
          <IconButton
            style={{ marginRight: '15px' }}
            tooltip={'Eliminar orden'}
            size={'2x'}
            icon={faTrashAlt}
            onClick={() => this.setState({ showDelete: true })}
            spin={deleteIsLoading}
          />
        )}
        {(order.status === 1 || order.status === 5 || order.status == 8) &&
          !order.deleted && (
            <IconButton
              tooltip={'Confirmar orden'}
              icon={faClipboardCheck}
              size={'2x'}
              onClick={() => this.setState({ showConfirm: true })}
              spin={confirmIsLoading}
            />
          )}
      </div>
    )
  }

  onDateFromChange = date => {
    const { order } = this.props
    order.enabledAt = moment(date).hour(6).minute(0).valueOf()
  }

  handleCheckBoxChange = event => {
    const { name, checked } = event.target
    this.setState({ [name]: checked })
  }

  handleChangeRadio = event => {
    const { name, id } = event.target
    this.setState({ [name]: true, [id]: false })
  }

  presaleOrder = () => {
    const { order, convertPresale } = this.props
    const { warehouseS, deliverer, isPreSale, remove, payed } = this.state

    let errors = {}
    if (!order.warehouse && warehouseS.value === null) errors.warehouse = 'Es necesario'

    if (isPreSale && !order.enabledAt)
      order.enabledAt = moment(new Date()).hour(6).minute(0).valueOf()

    // if (!order.client.deliverer && !deliverer.value) errors.deliverer = 'Es necesario asignar un repartidor';

    if (Object.keys(errors).length === 0) {
      this.setState({ showAlert: false })
      let warehouse = !order.warehouse ? warehouseS.id : 0

      // const delivererSelected = !order.client.deliverer ? deliverer.value : 0;
      const delivererSelected = 0

      if (order.type === 1) {
        if (isPreSale)
          return convertPresale(
            this.props.match.params.id,
            order.enabledAt,
            warehouse,
            delivererSelected,
            remove,
            payed,
          )
        else {
          order.warehouseId = warehouse
          order.deliverer = delivererSelected
          this.validateOrder(order)
        }
      }
    }
    this.setState({ errors })
  }

  validateOrder = item => {
    setTimeout(() => {
      let {
        match,
        confirmPaymentOrder,
        confirmSaleOrder,
        order,
        isGeolocationAvailable,
        isGeolocationEnabled,
        coords,
        getDistance,
        currentModule,
        singleCompany,
      } = this.props
      if (item) order = Object.assign({}, item)
      let { alert } = this.state
      let isTest = singleCompany ? singleCompany.test : false

      if (order.type !== 3) {
        if (!isGeolocationAvailable && currentModule === 1000) {
          alert = this.handlerError(
            'Este dispositivo no es compatible con Geolocalizacion',
          )
          return this.setState({ alert })
        } else if (!isGeolocationEnabled && currentModule === 1000) {
          alert = this.handlerError('La geolocalizacion no esta activada')
          return this.setState({ alert })
        } else if (coords || currentModule == 2000) {
          if (order.type !== 2 && !order.warehouse && !order.warehouseId) {
            return this.setState({ showAlert: true, isPreSale: false })
          }
          /*
                    if (order.type !== 2 && !order.client.deliverer && !order.deliverer && order.client.cotizapClient !== 3000) {
                        return this.setState({showAlert: true, isPreSale: false});
                    }
                    */
          const distance = getDistance(
            order.client.latitude,
            order.client.longitude,
            coords.latitude,
            coords.longitude,
          )
          console.log('distancia', distance)
          //if (currentModule === 1000 && distance < 350) {
          //alert = this.handlerError(`La posicion actual se encuentra a mas de 200 metros del cliente.\n\nDistancia actual:${new Decimal(distance > 1000 ? distance / 1000 : distance).toFixed(2)}${distance > 1000 ? 'km' : 'm'}`);
          //return this.setState({alert});
          //} else {
          if (order.type === 1) {
            this.setState({ isPreSale: true })
            return confirmSaleOrder(
              this.props.match.params.id,
              currentModule,
              order.warehouseId || 0,
              order.deliverer || 0,
              order.presaleDiscProduct,
            )
          }
          //if (order.type === 1) return confirmSaleOrder(this.props.match.params.id,this.state.selectedCashBox.id,"14.596105","-90.510847");
          if (order.type === 2) return this.props.history.push(`${match.url}/nueva`)
          //}
        }
      } else return confirmPaymentOrder(this.props.match.params.id)
    }, 600)
  }

  deleteOrder = () => {
    this.setState({ showDelete: false })
    this.props.deleteSingleOrder(this.props.match.params.id)
  }

  onGoogleApiLoaded = (map, maps) => {
    const { coords, order } = this.props
    const directionsService = new maps.DirectionsService()
    const directionsDisplay = new maps.DirectionsRenderer()
    const origin = { lat: coords.latitude, lng: coords.longitude }
    const destiny = {
      lat: Number(order.client.latitude),
      lng: Number(order.client.longitude),
    }
    directionsDisplay.setMap(map)
    directionsService.route(
      {
        origin: new maps.LatLng(origin),
        destination: new maps.LatLng(destiny),
        travelMode: 'DRIVING',
      },
      (response, status) => {
        if (status === 'OK') {
          directionsDisplay.setDirections(response)
        }
      },
    )
  }

  goToWaze = () => {
    const { order } = this.props
    const route = `https://www.waze.com/ul?ll=${order.client.latitude}%2C${order.client.longitude}&navigate=yes`
    return (
      <a href={route} target={'_none'}>
        <IconButton
          tooltip={'Ir con waze'}
          icon={faMapMarkedAlt}
          style={{ color: 'white' }}
        />
      </a>
    )
  }

  getHeadersCashBoxRow = () => {
    return [
      { label: 'Nombre', show: true, value: ['name'], type: 'text', className: 'mini' },
      {
        label: 'Empresa',
        show: true,
        value: ['companyData', 'name'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Resolución',
        show: true,
        value: ['resolucionC'],
        type: 'text',
        className: 'medium',
        custom: item =>
          (item.resolucionC = item.resolutionData
            ? `Serie ${item.resolutionData.series} Numero ${item.resolutionData.number} del ${item.resolutionData.initialNumber} a ${item.resolutionData.finalNumber}`
            : '------'),
      },
      {
        label: 'Asignada a',
        show: true,
        value: ['userAssignedData', 'name'],
        type: 'text',
        className: 'mini',
      },
      { config: true, show: true, label: '', className: 'mini center' },
    ]
  }

  reGroupOrder = () => {
    const { group, packages } = this.state
    let { alert } = this.state
    let request = {
      orders: [],
      groups: [],
      onWayOrders: [],
      packages: [],
      description: '',
    }
    request.description = group.description || ''
    group.groups.map(g => {
      request.groups.push(g.groupId)
    })
    let value = true
    packages.map(p => {
      if (p.details.length === 0) value = false
    })
    if (value) {
      request.packages = packages
      //console.log(request)
      this.props.reGroupOrder(request)
    } else {
      alert = this.handlerError('No se pueden procesar paquetes vacíos, elimínelos')
      this.setState({ alert })
    }
  }

  groupOrder = reserve => {
    const { group, packages } = this.state
    let { alert } = this.state
    if (group.groups.length === 0) {
      let request = {
        fromWarehouse: 0,
        toWarehouse: 0,
        orders: [],
        request: false,
        packages: [],
      }
      request.fromWarehouse = group.from.value
      request.description = group.description || ''
      request.reserve = reserve
      group.orders.map(o => {
        request.orders.push(o.orderId)
      })
      let value = true
      packages.map(p => {
        if (p.details.length === 0) value = false
      })
      if (value) {
        request.packages = packages
        this.props.processOrder(request)
      } else {
        alert = this.handlerError('No se pueden procesar paquetes vacíos, elimínelos')
        this.setState({ alert })
      }
    } else this.reGroupOrder()
  }

  createTransfer = () => {
    const { selected } = this.state
    const { order } = this.props
    if (selected.toWarehouse) {
      let request = {}
      request.toWarehouse = selected.toWarehouse.value
      this.props.createTransferByGroup(order.groupId, request)
    }
  }

  renderContent = (details, type, balance, status, order) => {
    const { currentCurrency } = this.props

    return (
      <div style={{ backgroundColor: 'white', color: 'default' }}>
        <TableV2
          items={details}
          mobileAuto
          storageKey={`orderDetailContent`}
          headers={this.getHeadersDetail(order, status === 9, true)}
        />
        <Row>
          <Col xs={10} />
          <Col xs="auto">
            <Money component="h2">
              Total: {this.getOrderTotal(details, type, balance)}
            </Money>
          </Col>
        </Row>
      </div>
    )
  }

  renderPackages = () => {
    const { packages } = this.props
    return (
      packages.length > 0 &&
      packages.map((g, index) => (
        <Tab eventKey={index + 1} title={`Paquete No. ${g.number}`}>
          {this.renderContent(g.details, 1, 0, g.status, false)}
        </Tab>
      ))
    )
  }

  renderOrders = () => {
    const { groups } = this.props
    return (
      groups.length > 0 &&
      groups.map((g, index) => (
        <Tab eventKey={index + 1} title={`Order No. ${g.number}`}>
          {this.renderContent(g.details, g.type, g.balance, g.status, true)}
        </Tab>
      ))
    )
  }

  render() {
    const {
      order,
      coords,
      assignedCashBoxes,
      cashBoxesIsLoading,
      invoice,
      warehouses,
      id,
      groups,
      module,
      contractedCompanies,
      toWarehouses,
      singlePackage,
      currentCurrency,
    } = this.props
    const {
      showConfirm,
      showDelete,
      showPresale,
      showAlert,
      showCashBox,
      selectedCashBox,
      showInvoice,
      warehouseS,
      errors,
      showEmail,
      showPackageExistence,
    } = this.state
    const {
      isPreSale,
      remove,
      payed,
      group,
      key,
      isGroup,
      packages,
      details,
      selectedPackage,
      showPackage,
      showTransfer,
      internal,
      external,
      selected,
      orderPresentation,
      packagesPresentation,
    } = this.state
    let { alert } = this.state

    const date = new Date()
    date.setHours(0, 0, 0)

    return (
      <div>
        <Title title={'Detalle'} />
        <Row>
          <Col md={4}>
            <Row>
              <Col md={12}>
                <Card title={'Orden'} button={this.buttons()}>
                  <Row>
                    <Col>
                      <b>Empresa</b>
                    </Col>
                    <Col>{order.company ? order.company.name : 'Desconocido'}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>Tipo de pago</b>
                    </Col>
                    <Col>{order.paymentType === 1 ? 'Contado' : 'Credito'}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>Estado</b>
                    </Col>
                    <Col>{order.statusData && order.statusData.name}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>Tipo</b>
                    </Col>
                    <Col>{order.orderTypeData && order.orderTypeData.name}</Col>
                  </Row>
                  {order.type === 1 &&
                  order.linked &&
                  order.status !== 7 &&
                  order.status !== 9 &&
                  order.total < 75 ? (
                    <>
                      <Row>
                        <Col>
                          <b>Subtotal</b>
                        </Col>
                        <Money component={Col}>
                          {formatNumberWithCommas(order.total)}
                        </Money>
                      </Row>
                      <Row>
                        <Col>
                          <b style={{ color: 'rgba(203,64,96,0.99)' }}>Costo de envío</b>
                        </Col>
                        <Col style={{ color: 'rgba(203,64,96,0.99)' }}>Q. 10.00</Col>
                      </Row>
                      <Row>
                        <Col>
                          <b>Total</b>
                        </Col>
                        <Money component={Col}>
                          {formatNumberWithCommas(order.total + 10)}
                        </Money>
                      </Row>
                    </>
                  ) : (
                    <Row>
                      <Col>
                        <b>Total</b>
                      </Col>
                      <Money component={Col}>
                        {order.status === 7 && order.status === 9
                          ? this.getGroupTotal(groups)
                          : this.getOrderTotal(order.details, order.type, order.balance)}
                      </Money>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      <b>Bodega</b>
                    </Col>
                    <Col>{order.warehouse ? order.warehouse.name : 'Sin Bodega'}</Col>
                  </Row>
                  {order.description ? (
                    <Row>
                      <Col md={1}> </Col>
                      <Col md={10}>
                        <b>Descripcion</b>
                        <br /> {order.description}
                      </Col>
                      <Col md={1}> </Col>
                    </Row>
                  ) : (
                    ''
                  )}
                  <Row style={{ borderBottom: '1px solid black' }}></Row>
                  <Row>
                    <Col>
                      <b>Cliente</b>
                    </Col>
                    <Col>{order.client ? order.client.companyName : 'Desconocido'}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>Dirección</b>
                    </Col>
                    <Col>
                      {order.client
                        ? order.client.secondAddress
                          ? order.client.secondAddress
                          : order.client.address
                        : 'Desconocido'}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>Referencia</b>
                    </Col>
                    <Col>{order.client ? order.client.reference : 'Desconocido'}</Col>
                  </Row>
                  <Row>
                    <br />
                    <Col className={'text-center'}>
                      {order.deleted ? <b className={'red-text'}>Orden Eliminada</b> : ''}
                    </Col>
                  </Row>
                  <Row>
                    <br />
                    <Col className={'text-center'}>
                      {order.status === 5 && order.presaleDiscProduct ? (
                        <b style={{ color: 'rgba(33,114,29,0.6)', fontSize: 15 }}>
                          Producto entregado
                        </b>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <br />
                    <Col className={'text-center'}>
                      {order.status === 5 && order.presalePayed ? (
                        <b style={{ color: 'rgba(33,114,29,0.6)', fontSize: 15 }}>
                          Orden ya cancelada
                        </b>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                  <hr />
                </Card>
              </Col>
              {!id && order.type === 1 && order.client && coords && (
                <Col md={12}>
                  <MapCard
                    button={this.goToWaze()}
                    zoom={13}
                    title={'Ubicación del cliente'}
                    address={order.client.address}
                    latitude={Number(order.client.latitude) || coords.latitude}
                    longitude={Number(order.client.longitude) || coords.longitude}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) =>
                      this.onGoogleApiLoaded(map, maps)
                    }
                  />
                </Col>
              )}
            </Row>
          </Col>
          <Col md={8}>
            {isGroup && (order.status === 7 || order.status === 9) ? (
              <Card>
                <Row>
                  <Col>
                    <label className={'checkbox-label'}>
                      <input
                        onChange={this.handleChangeRadio}
                        checked={orderPresentation}
                        type="radio"
                        name={'orderPresentation'}
                        id={'packagesPresentation'}
                      />
                      Presentación por órdenes
                    </label>
                  </Col>
                  <Col>
                    <label className={'checkbox-label'}>
                      <input
                        onChange={this.handleChangeRadio}
                        checked={packagesPresentation}
                        type="radio"
                        name={'packagesPresentation'}
                        id={'orderPresentation'}
                      />
                      Presentación por paquetes
                    </label>
                  </Col>
                </Row>
                <Tabs
                  activeKey={key}
                  onSelect={e => this.setState({ key: e })}
                  style={{
                    justifyContent: 'center',
                    position: 'static',
                    marginBottom: 5,
                    borderRadius: 10,
                  }}>
                  {orderPresentation && this.renderOrders()}
                  {packagesPresentation && this.renderPackages()}
                </Tabs>
              </Card>
            ) : order.type === 1 ? (
              <Card title={'Detalles'} white>
                <TableV2
                  items={order.details}
                  headers={this.getHeadersDetail(order, false)}
                  mobileAuto
                  storageKey={`orderDetailsTable`}
                />
              </Card>
            ) : (
              order.client &&
              coords && (
                <MapCard
                  button={order.type !== 3 && order.type !== 7 && this.goToWaze()}
                  zoom={13}
                  title={'Ubicación del cliente'}
                  address={order.client.address}
                  latitude={Number(order.client.latitude) || coords.latitude}
                  longitude={Number(order.client.longitude) || coords.longitude}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={({ map, maps }) => this.onGoogleApiLoaded(map, maps)}
                />
              )
            )}
          </Col>
        </Row>

        <Modal
          show={showPackageExistence}
          size={'md'}
          centered
          aria-labelledby={'contained-modal-title-vcenter'}
          onHide={() => {
            this.setState({ showPackageExistence: false })
            this.props.savePackage()
          }}>
          <Modal.Header closeButton>
            <Modal.Title>{`Paquete No. ${singlePackage.number}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card>
              <TableV2
                headers={[
                  {
                    label: 'Existencia',
                    show: true,
                    value: [],
                    type: 'text',
                    className: 'mini',
                    select: true,
                    custom: item => (
                      <IconButton
                        icon={item.existence ? faCheckCircle : faTimesCircle}
                        color={item.existence ? 'green' : 'red'}
                        tooltip={
                          item.existence ? 'Sí hay existencia' : 'No hay existencia'
                        }
                      />
                    ),
                  },
                  {
                    label: 'Orden',
                    show: true,
                    value: ['order'],
                    type: 'text',
                    className: 'mini',
                  },
                  {
                    label: 'Producto',
                    show: true,
                    value: ['productoC'],
                    type: 'text',
                    className: 'mini',
                    custom: item =>
                      (item.productoC = `${item.productData.code}-${item.productData.name}`),
                  },
                  {
                    label: 'Cantidad',
                    show: true,
                    value: ['quantity'],
                    type: 'wholeNumber',
                    className: 'mini',
                  },
                  { config: true, show: true, label: '', className: 'mini center' },
                ]}
                items={singlePackage.details}
                mobileAuto
                storageKey={`ordersDetailPackage`}
              />
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <div style={{ textAlign: 'right' }}>
              <Button
                onClick={() => {
                  this.props.changeStatusByPackage(singlePackage.id)
                }}>
                Validar Paquete
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showPackage}
          size={'sm'}
          centered
          aria-labelledby={'contained-modal-title-vcenter'}
          onHide={() => this.setState({ showPackage: false })}>
          <Modal.Header closeButton>
            <Modal.Title>{`Paquete: ${selectedPackage.number}`}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <TableV2
              headers={[
                {
                  label: 'Orden',
                  show: true,
                  value: ['order'],
                  type: 'text',
                  className: 'mini',
                },
                {
                  label: 'Producto',
                  show: true,
                  value: ['productoC'],
                  type: 'text',
                  className: 'mini',
                  custom: item =>
                    (item.productoC = `${item.productData.code}-${item.productData.name}`),
                },
                {
                  label: 'Cantidad',
                  show: true,
                  value: ['quantity'],
                  type: 'wholeNumber',
                  className: 'mini',
                },
                {
                  label: 'Eliminar',
                  show: true,
                  value: [],
                  type: 'text',
                  className: 'mini',
                  select: true,
                  custom: (item, index) => (
                    <IconButton
                      icon={faTimesCircle}
                      size={'2x'}
                      color={'rgba(213,58,58,0.6)'}
                      onClick={() => {
                        selectedPackage.details.splice(index, 1)
                        let p = selectedPackage.products.splice(index, 1)
                        details.push(p[0])
                        this.setState({ details, selectedPackage })
                      }}
                    />
                  ),
                },
                { config: true, show: true, label: '', className: 'mini center' },
              ]}
              items={selectedPackage.products}
              mobileAuto
              storageKey={`orderDetailsPackage`}
            />
          </Modal.Body>
          <Modal.Footer>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div
                className={'big-button'}
                style={{ backgroundColor: 'rgba(213,58,58,0.6)' }}
                onClick={() => {
                  this.deletePackage()
                }}>
                Eliminar
              </div>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={group.show}
          size={'lg'}
          centered
          aria-labelledby={'contained-modal-title-vcenter'}
          onHide={() => {
            this.setState({
              details: details.map(d => {
                d.selected = false
                return d
              }),
            })
            this.setState({
              group: {
                orders: [],
                show: false,
                warehouseDisabled: false,
                description: '',
                from: 0,
                groups: [],
              },
              details: [],
              packages: [],
            })
          }}>
          <Modal.Header closeButton>
            <Modal.Title>{`Orden: #${
              group.orders.length === 1 ? group.orders[0].number : 'Ordenes seleccionadas'
            }`}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className={'column'}>
              {group.groups.length === 0 && (
                <Select
                  disabled={group.warehouseDisabled}
                  options={warehouses}
                  label={'Bodega Saliente'}
                  value={group.from}
                  onChange={d => {
                    let orders = []
                    group.orders.map(o => orders.push(o.id))
                    this.props.getProductExistenceByOrder(d.value, orders)
                    this.setState({ group: { ...group, from: d } })
                  }}
                />
              )}
              <Row style={{ borderBottom: '1px solid black' }} />
              <TextField
                prepend={<Icon icon={faBook} tooltip={'Descripción'} />}
                name={'quantity'}
                label={'Descripción'}
                placeholder={'Descripción'}
                value={group.description}
                onChange={e =>
                  this.setState({ group: { ...group, description: e.target.value } })
                }
              />
            </div>
            {details.length > 0 ? (
              <>
                <Card>
                  <Row>
                    <h6>Paquetes</h6>
                  </Row>
                  <Row>
                    {packages.length > 0 &&
                      packages.map(p => (
                        <Col
                          className={'text-center col-restaurant'}
                          xs={6}
                          sm={6}
                          md={3}
                          lg={3}
                          xl={2}
                          style={{ border: '1px solid white' }}
                          onClick={() => {
                            this.setState({ selectedPackage: p, showPackage: true })
                          }}>
                          <div
                            style={{
                              width: 50,
                              height: 70,
                              position: 'relative',
                              display: 'inline-block',
                            }}>
                            <h3 className={'text-table'} style={{ top: '30%' }}>
                              {p.number}
                            </h3>
                            <IconButton
                              icon={faBox}
                              className={'new-table'}
                              tooltip={'Ver contenido'}
                              color={'rgba(101,54,5,0.53)'}
                              size={'3x'}
                            />
                            <div className={'unread'}>
                              <h6>{p.details.length}</h6>
                            </div>
                          </div>
                        </Col>
                      ))}
                    <Col
                      className={'text-center col-restaurant'}
                      xs={6}
                      sm={6}
                      md={3}
                      lg={3}
                      xl={2}
                      style={{ border: '1px solid white' }}
                      onClick={() => {
                        if (
                          packages.length > 0 &&
                          (packages[packages.length - 1].details.length > 0) &
                            (details.filter(d => !d.selected).length > 0)
                        )
                          packages.push({
                            details: [],
                            number: packages.length + 1,
                            products: [],
                          })
                        else if (packages.length === 0)
                          packages.push({
                            details: [],
                            number: packages.length + 1,
                            products: [],
                          })
                        this.setState({ packages })
                      }}>
                      <div
                        style={{
                          width: 50,
                          height: 70,
                          position: 'relative',
                          display: 'inline-block',
                        }}>
                        <IconButton
                          icon={faPlus}
                          className={'new-table'}
                          tooltip={'Agregar'}
                          color={'rgba(136,135,194,0.32)'}
                          size={'3x'}
                        />
                      </div>
                    </Col>
                  </Row>
                </Card>
                <Card
                  title={
                    packages.length > 0
                      ? `Productos para asignar en el paquete ${packages.length}`
                      : 'Cree un paquete para asignar el producto'
                  }
                  header={
                    packages.length > 0
                      ? { backgroundColor: 'rgba(107,196,77,0.9)' }
                      : { backgroundColor: 'rgba(196,77,95,0.9)' }
                  }>
                  <TableV2
                    headers={[
                      {
                        label: 'Existencia',
                        show: true,
                        value: ['order'],
                        type: 'text',
                        className: 'mini',
                      },
                      {
                        label: 'Orden',
                        show: true,
                        value: ['order'],
                        type: 'text',
                        className: 'mini',
                      },
                      {
                        label: 'Producto',
                        show: true,
                        value: ['order'],
                        type: 'text',
                        className: 'mini',
                      },
                      {
                        label: 'Cantidad',
                        show: true,
                        value: ['order'],
                        type: 'text',
                        className: 'mini',
                      },
                      { config: true, show: true, label: '', className: 'mini center' },
                    ]}
                    items={details.filter(d => !d.selected)}
                    mobileAuto
                    storageKey={`orderDetailsAssign`}
                    onClickTr={(e, item) => {
                      if (packages.length > 0) {
                        packages[packages.length - 1].details.push(item.id)
                        packages[packages.length - 1].products.push({
                          order: item.orderId,
                          productData: {
                            code: item.product.code,
                            name: item.product.name,
                          },
                          quantity: item.quantity,
                          selected: false,
                        })
                        item.selected = true
                        this.setState({ packages, item })
                      }
                    }}
                    getItem={(item, e) => {
                      if (item !== null) {
                        if (packages.length > 0) {
                          packages[packages.length - 1].details.push(item.id)
                          packages[packages.length - 1].products.push({
                            order: item.orderId,
                            productData: {
                              code: item.product.code,
                              name: item.product.name,
                            },
                            quantity: item.quantity,
                            selected: false,
                          })
                          item.selected = true
                          this.setState({ packages, item })
                        }
                      } else {
                        item.selected = false
                        this.setState({ item })
                      }
                    }}
                  />
                  {/* <PaginatedTable
                                            headers={['Existencia', 'Orden', 'Producto', 'Cantidad']}
                                            renderRow={(item, index) =>
                                                <Tr className={'data'} key={`${index}`} onClick={() => {
                                                    if(packages.length>0){
                                                        packages[packages.length-1].details.push(item.id)
                                                        packages[packages.length-1].products.push(
                                                            {order: item.orderId, productData: {code: item.product.code, name: item.product.name},
                                                                quantity: item.quantity, selected: false})
                                                        item.selected = true;
                                                        this.setState({packages, item})
                                                    }
                                                }}>
                                                    <Td>
                                                        <IconButton icon={item.existence ? faCheckCircle: faTimesCircle}
                                                                    color={item.existence ? 'green' : 'red'}
                                                                    tooltip={item.existence ? 'Sí hay existencia' : 'No hay existencia'}/>
                                                    </Td>
                                                    <Td>{item.order}</Td>
                                                    <Td>{item.product ? item.product.name : 'N/A'}</Td>
                                                    <Td>{item.quantity}</Td>
                                                </Tr>
                                            }
                                            items={details.filter(d => !d.selected)}
                                        /> */}
                </Card>
              </>
            ) : (
              <Row>
                <h3>Las órdenes serán reprocesadas en los mismos paquetes</h3>
              </Row>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Row className={'container-buttons'}>
              {order.status !== 9 && (
                <Button
                  variant={'info'}
                  icon={faSave}
                  right
                  onClick={() => {
                    if (details.length > 0 && packages.length === 0) {
                      alert = this.handlerError('Debe crear un paquete')
                      this.setState({ alert })
                    } else if (
                      details.length > 0 &&
                      details.filter(d => !d.selected).length > 0
                    ) {
                      alert = this.handlerError('Debe asignar todos los productos')
                      this.setState({ alert })
                    } else this.groupOrder(false)
                  }}>
                  Preselección{' '}
                </Button>
              )}
              <Button
                variant={'info'}
                icon={faSave}
                right
                onClick={() => {
                  if (details.length > 0 && packages.length === 0) {
                    alert = this.handlerError('Debe crear un paquete')
                    this.setState({ alert })
                  } else if (
                    details.length > 0 &&
                    details.filter(d => !d.selected).length > 0
                  ) {
                    alert = this.handlerError('Debe asignar todos los productos')
                    this.setState({ alert })
                  } else this.groupOrder(true)
                }}>
                Procesar{' '}
              </Button>
            </Row>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showTransfer}
          size={'md'}
          centered
          onHide={() => this.setState({ showTransfer: false })}>
          <Modal.Header closeButton>
            <Modal.Title>{'Transferencia de Orden'}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {module === 2000 && (
              <Row>
                <Col>
                  <label className={'checkbox-label'}>
                    <input
                      onChange={this.handleChangeRadio}
                      checked={internal}
                      type="radio"
                      name={'internal'}
                      id={'external'}
                    />{' '}
                    Transferencia Interna
                  </label>
                </Col>
                <Col>
                  <label className={'checkbox-label'}>
                    <input
                      onChange={this.handleChangeRadio}
                      checked={external}
                      type="radio"
                      name={'external'}
                      id={'internal'}
                    />{' '}
                    Transferencia externa
                  </label>
                </Col>
              </Row>
            )}
            <div className={'column'}>
              {external && (
                <Col xs={12} sm={12} md={12} xl={12}>
                  <Select
                    label={'Compañía'}
                    value={selected.toCompany}
                    options={contractedCompanies}
                    onChange={w => {
                      this.setState({ selected: { ...selected, toCompany: w } })
                      this.props.getExternalActiveWarehouses(w.value)
                    }}
                  />
                </Col>
              )}
              <Col xs={12} sm={12} md={12} xl={12}>
                <Select
                  label={'Bodega de entrada'}
                  value={selected.toWarehouse}
                  options={external ? toWarehouses : warehouses}
                  onChange={w => {
                    this.setState({ selected: { ...selected, toWarehouse: w } })
                  }}
                  required
                  error={errors.tWarehouse}
                />
              </Col>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div style={{ textAlign: 'right' }}>
              <Button
                onClick={() => {
                  this.createTransfer()
                }}>
                Crear transferencia
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showAlert}
          size={'md'}
          centered
          onHide={() => this.setState({ showAlert: false })}>
          <Modal.Header closeButton>
            <Modal.Title>
              {isPreSale
                ? 'Seleccione una fecha de facturación'
                : `La orden: ${order.number}, le hace falta información.`}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className={'column'}>
              {isPreSale && (
                <CustomDatePicker
                  disabled={false}
                  label={'Para:'}
                  value={date}
                  disabledDays={{ before: date }}
                  onDayChange={this.onDateFromChange}
                />
              )}

              {!order.warehouse && (
                <Select
                  label={'Selecciona una bodega.'}
                  options={warehouses}
                  value={warehouseS}
                  required
                  onChange={data => this.setState({ warehouseS: data })}
                  error={errors.warehouse}
                />
              )}

              {/*{(order.client && !order.client.deliverer)&&
                            <Select
                                label={order.client.delivererData ? 'Repartidor asignado' : 'Asignar repartidor'}
                                disabled={order.client.delivererData}
                                options={deliverers}
                                value={deliverer}
                                required
                                onChange={(data) => this.setState({deliverer: data})}
                                error={errors.deliverer}
                            />}
                            */}
              <div>
                <Row>Elija una de las opciones si es necesario</Row>
                <Row>
                  <Col>
                    <label className={'checkbox-label'}>
                      <input
                        type="checkbox"
                        name="remove"
                        checked={remove}
                        onChange={this.handleCheckBoxChange}
                      />
                      Pendiente de pago/Mercadería despachada
                    </label>
                  </Col>
                  <Col>
                    <label className={'checkbox-label'}>
                      <input
                        type="checkbox"
                        name="payed"
                        checked={payed}
                        onChange={this.handleCheckBoxChange}
                      />
                      Pagada/Pendiente de entrega
                    </label>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div style={{ textAlign: 'right' }}>
              <Button
                onClick={() => {
                  this.presaleOrder()
                }}>
                Guardar
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showCashBox} //showCashBox
          size={'lg'}
          centered
          aria-labelledby={'contained-modal-title-vcenter'}
          onHide={() => this.setState({ showCashBox: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Seleccione caja a utilizar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <TableV2
                headers={this.getHeadersCashBoxRow()}
                items={assignedCashBoxes}
                loading={cashBoxesIsLoading}
                noItemsLegend={
                  assignedCashBoxes.length === 0
                    ? 'No tienes cajas asignadas por ningun productor'
                    : 'Sin items para mostrar'
                }
                mobileAuto
                storageKey={`orderDetailCashBox`}
                isSelected={item => item.id === selectedCashBox.id}
                onClickTr={(e, item) => this.setState({ selectedCashBox: item })}
                getItem={(item, e) => {
                  if (item !== null) {
                    this.setState({ selectedCashBox: item })
                  } else {
                    this.setState({ selectedCashBox: {} })
                  }
                }}
              />
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Row className={'container-buttons'}>
              <Button onClick={() => this.setState({ showCashBox: false })}>
                Cerrar
              </Button>
              <Button
                onClick={() => {
                  this.setState({ showCashBox: false })
                  this.setState({ showConfirm: true })
                }}
                disabled={selectedCashBox.id > 0 ? false : true}>
                Aceptar
              </Button>
            </Row>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showInvoice} //showCashBox
          size={'lg'}
          centered
          aria-labelledby={'contained-modal-title-vcenter'}
          onHide={() => this.setState({ showInvoice: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Orden</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {invoice &&
              invoice.length > 0 &&
              invoice.map((invoice, i) => {
                return (
                  <div>
                    <Container className="invoice">
                      <Row>
                        <Col className="text-center">
                          <h3>
                            {invoice && invoice.companyDetail
                              ? invoice.companyDetail.name
                              : ''}
                          </h3>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="text-center">
                          <h2>{invoice.companyDetail.name}</h2>
                        </Col>
                        <Col className="text-center">
                          <h2>NIT: {invoice.companyDetail.nit}</h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="text-center">{invoice.companyDetail.address}</Col>
                      </Row>

                      <Row>
                        {invoice.uuid ? (
                          <Col className="text-center">
                            Codigo de Autorizacion{invoice.uuid}
                          </Col>
                        ) : (
                          <Col className="text-center">
                            Resolucion No. {invoice && invoice.resolutionDetails.number}{' '}
                            de fecha{' '}
                            {formatDateFromMillis(
                              invoice.resolutionDetails.authorizationDate,
                            )}
                          </Col>
                        )}
                      </Row>
                      {invoice.uuid ? (
                        ''
                      ) : (
                        <Row>
                          <Col className="text-center">
                            Serie {invoice.resolutionDetails.series} Del{' '}
                            {invoice.resolutionDetails.initialNumber} al{' '}
                            {invoice.resolutionDetails.finalNumber}
                          </Col>
                        </Row>
                      )}

                      <Row className="text-center">
                        <Col>
                          <h2>
                            Orden No.{invoice.number} -{' '}
                            {formatDateFromMillis(invoice.createdAt)}
                          </h2>
                        </Col>
                      </Row>

                      <Row className="text-center">
                        <Col>
                          Nombre: <br /> {invoice.name}
                        </Col>
                        <Col>
                          Nit: <br /> {invoice.nit ? invoice.nit : 'C/F'}
                        </Col>
                      </Row>
                      <hr />
                      <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                        <Row>
                          <Col>
                            <b>Cantidad</b>
                          </Col>
                          <Col>
                            <b>Producto</b>
                          </Col>
                          <Col>
                            <b>Total</b>
                          </Col>
                        </Row>
                        <hr />
                        {invoice.invoiceDetails.map((value, index) => {
                          return (
                            <Row key={value.id}>
                              <Col>{value.quantity}</Col>
                              <Col>{value.description}</Col>
                              <Col>
                                {formatNumberWithCommas(parseFloat(value.amount))}
                              </Col>
                            </Row>
                          )
                        })}
                        <hr />

                        <Row>
                          <Col>
                            <h2>Total</h2>
                          </Col>
                          <Col></Col>
                          <Col>{formatNumberWithCommas(parseFloat(invoice.total))}</Col>
                        </Row>
                      </div>

                      <Row>
                        {' '}
                        <Col className="text-center">
                          (Impuesto de Ventas Incluido)
                        </Col>{' '}
                      </Row>
                    </Container>
                    <Row>
                      <Col></Col>
                      <Col></Col>
                      <Col className={'text-right'}>
                        <Button
                          onClick={() =>
                            this.props.downloadPrintableInvoice(
                              invoice.id,
                              invoice.number,
                              module,
                            )
                          }>
                          Descargar
                        </Button>
                      </Col>
                    </Row>
                    <br />
                  </div>
                )
              })}
            )
          </Modal.Body>
          <Modal.Footer>
            <Row className={'container-buttons'}>
              <Button
                onClick={() => {
                  this.setState({ showInvoice: false })
                  this.props.getSingleOrder(this.props.match.params.id)
                }}>
                Cerrar
              </Button>

              <Button
                onClick={() =>
                  this.props.downloadPrintableInvoiceByOrder(
                    invoice.length > 0 ? invoice[0].order : 0,
                    invoice.number,
                    module,
                  )
                }>
                Descargar todas
              </Button>

              <Button
                onClick={() => this.setState({ showInvoice: false, showEmail: true })}>
                Enviar
              </Button>
            </Row>
          </Modal.Footer>
        </Modal>

        <SweetAlert
          show={showDelete}
          title={'Eliminar'}
          text={`Desea Eliminar esta orden de ${
            order.orderTypeData && order.orderTypeData.name
          }?`}
          type={'warning'}
          showCancelButton
          onConfirm={() => {
            this.deleteOrder()
          }}
          onCancel={() => {
            this.setState({ showDelete: false })
          }}
        />

        <SweetAlert
          show={showConfirm}
          title={'Confirmar'}
          text={`Desea Confirmar esta orden de ${
            order.orderTypeData && order.orderTypeData.name
          }?`}
          type={'warning'}
          showCancelButton
          onConfirm={() => {
            this.setState({ showConfirm: false })
            this.validateOrder()
          }}
          onCancel={() => {
            this.setState({ showConfirm: false })
          }}
        />

        <SweetAlert
          show={showPresale}
          title={'Confirmar'}
          text={`Desea asignar esta orden de ${
            order.orderTypeData && order.orderTypeData.name
          } como preventa?`}
          type={'warning'}
          showCancelButton
          onConfirm={() => {
            this.setState({ showPresale: false, showAlert: true })
          }}
          onCancel={() => {
            this.setState({ showPresale: false })
          }}
        />

        <SweetAlert
          show={alert.show}
          title={alert.title}
          text={alert.text}
          type={alert.type}
          onConfirm={() => this.setState({ alert: { ...alert, show: false } })}
        />
        <SweetAlert
          show={showEmail}
          title="Factura Electrónica"
          text="Ingrese correo electrónico"
          type="input"
          inputType="email"
          inputPlaceholder="example@domain.com"
          showCancelButton
          onConfirm={inputValue => {
            this.props.sendInvoiceEmail({ id: invoice.id, email: inputValue, module })
            this.setState({ showEmail: false })
          }}
          onCancel={() => {
            this.setState({ showEmail: false })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  getDistance: (lat1, lon1, lat2, lon2) => getDiffBetweenPoints(lat1, lon1, lat2, lon2),
  order: selectGetSingleOrder(state),
  products: selectAllProducts(state),
  orderStatus: selectGetAllOrderStatus(state),
  orderTypes: selectGetAllOrderTypes(state),
  warehouse: selectSingleWarehouse(state),
  loading: loadingSelector([orderTypes.GET_SINGLE_ORDER])(state),
  hasError: hasErrorsSelector([orderTypes.GET_SINGLE_ORDER])(state),
  error: singleErrorSelector([orderTypes.GET_SINGLE_ORDER])(state),
  confirmIsLoading: loadingSelector([orderTypes.CONFIRM_SINGLE_ORDER])(state),
  confirmHasError: hasErrorsSelector([orderTypes.CONFIRM_SINGLE_ORDER])(state),
  confirmError: singleErrorSelector([orderTypes.CONFIRM_SINGLE_ORDER])(state),
  deleteIsLoading: loadingSelector([orderTypes.DELETE_SINGLE_ORDER])(state),
  deleteHasError: hasErrorsSelector([orderTypes.DELETE_SINGLE_ORDER])(state),
  deleteError: singleErrorSelector([orderTypes.DELETE_SINGLE_ORDER])(state),
  paymentIsLoading: loadingSelector([orderTypes.ON_CONFIRM_PAYMENT_ORDER])(state),
  paymentHasError: hasErrorsSelector([orderTypes.ON_CONFIRM_PAYMENT_ORDER])(state),
  paymentError: singleErrorSelector([orderTypes.ON_CONFIRM_PAYMENT_ORDER])(state),
  presaleIsLoading: loadingSelector([orderTypes.CONVERT_PRESALE])(state),
  presaleHasError: hasErrorsSelector([orderTypes.CONVERT_PRESALE])(state),
  presaleError: singleErrorSelector([orderTypes.CONVERT_PRESALE])(state),
  assignedCashBoxes: selectAssignedCashBoxes(state),
  invoice: selectConfirmSingleOrder(state),
  currentModule: selectCurrentModule(state),
  warehouses: selectAllWarehousesForSelect(state),
  deliverers: selectDeliverersToSelect(state),
  singleCompany: selectGetSingleCompany(state),
  playerId: selectPlayerId(state),
  groups: selectGetSingleGroup(state),
  packages: selectGetSingleGroupByPackages(state),
  singlePackage: selectSinglePackage(state),
  statusIsLoading: loadingSelector([orderTypes.CHANGE_STATUS])(state),
  statusHasError: hasErrorsSelector([orderTypes.CHANGE_STATUS])(state),
  statusError: singleErrorSelector([orderTypes.CHANGE_STATUS])(state),
  module: selectCurrentModule(state),
  loadingGroup: loadingSelector([typesOder.PROCESS_ORDER])(state),
  loadingHasError: hasErrorsSelector([typesOder.PROCESS_ORDER])(state),
  loadingError: singleErrorSelector([typesOder.PROCESS_ORDER])(state),
  contractedCompanies: companies(state),
  toWarehouses: selectExternalActiveWarehousesForSelect(state),
  transferIsLoading: loadingSelector([warehouseAction.CREATE_TRANSFER])(state),
  transferHasError: hasErrorsSelector([warehouseAction.CREATE_TRANSFER])(state),
  transferError: singleErrorSelector([warehouseAction.CREATE_TRANSFER])(state),
  existence: selectProductExistence(state),
  loadingExistence: loadingSelector([orderTypes.GET_EXISTENCE])(state),
  currentCurrency: selectCurrentCurrency(state),
})

const mapDispatchToProps = dispatch => ({
  getProducts: () => dispatch(getProducts()),
  getAllOrderTypes: () => dispatch(getAllOrderTypes()),
  getSingleOrder: id => dispatch(getSingleOrder(id)),
  deleteSingleOrder: id => dispatch(deleteSingleOrder(id)),
  getSingleWarehouse: id => dispatch(getSingleWarehouse(id)),
  confirmSaleOrder: (id, module, warehouse, deliverer, remove) =>
    dispatch(confirmSingleOrder(id, module, warehouse, deliverer, remove)),
  convertPresale: (id, new_date, warehouse, delivererId, remove, payed) =>
    dispatch(convertPresale(id, new_date, warehouse, delivererId, remove, payed)),
  getAssignedCashBoxes: () => dispatch(getAssignedCashBoxes()),
  confirmPaymentOrder: id => dispatch(onConfirmOrderPayment(id)),
  downloadPrintableInvoice: (id, defaultName, module) =>
    dispatch(downloadPrintableInvoice(id, defaultName, module)),
  downloadPrintableInvoiceByOrder: (id, defaultName, module) =>
    dispatch(downloadPrintableInvoiceByOrder(id, defaultName, module)),
  getWarehouses: () => dispatch(getWarehouses()),
  getDeliverers: module =>
    dispatch(module === 2000 ? getAllDeliverersByProducer() : getAllDeliverers()),
  sendInvoiceEmail: invoice => dispatch(sendInvoiceEmail(invoice)),
  sendNotification: request => dispatch(sendNotifications(request)),
  getGroup: id => dispatch(getGroup(id)),
  getGroupByPackage: id => dispatch(getGroupByPackages(id)),
  changeStatus: id => dispatch(changeStatus(id)),
  changeStatusByPackage: id => dispatch(changeStatusByPackage(id)),
  savePackage: () => dispatch(savePackage({})),
  processOrder: request => dispatch(processOrder(request)),
  reGroupOrder: request => dispatch(reGroupOrder(request)),
  getExternalActiveWarehouses: companyId =>
    dispatch(getExternalActiveWarehouses(companyId)),
  getContractedCompanies: () => dispatch(getContractedCompanies(10)),
  createTransferByOrder: (orderId, request) =>
    dispatch(createTransferByOrder(orderId, request)),
  createTransferByGroup: (groupId, request) =>
    dispatch(createTransferByGroup(groupId, request)),
  getProductExistenceByOrder: (id, orders) =>
    dispatch(getProductExistenceByOrder(id, orders)),
})

const ReduxComponent = connect(mapStateToProps, mapDispatchToProps)(OrdersDetails)

OrdersDetails.defaultProps = {
  invoice: {
    companyDetail: {},
    resolutionDetails: {},
    companyDetails: {},
    invoiceDetails: [],
    total: 0,
  },
}

const comp = geolocated({
  positionOptions: { enableHighAccuracy: false },
  userDecisionTimeout: 5000,
})(ReduxComponent)

export default comp
