import React from 'react'
import './DetailCard.scss'

interface IProps {
  title: string
  details: ISelect[]
  onClick?: () => void
}

/**
 * Show the details in a card with an action
 * @component
 * @param {string} title - Title of the card
 * @param {ISelect[]} details - Details to show
 * @returns
 */
export const DetailCard = ({ title, details, onClick }: IProps) => {
  return (
    <div className="container" style={{ paddingInline: 15 }}>
      <div className="order-summary ">
        <div className="order-header">
          <h1 className="order-title">{title}</h1>
        </div>
        <div>
          <div className="order-body">
            {details.map((detail, index) => (
              <div key={index} className="text-group">
                <p style={{ fontWeight: 'bold', margin: 0 }}>{detail.label}</p>
                <p>{detail.value}</p>
              </div>
            ))}
          </div>
          {onClick && (
            <div style={{ display: 'flex' }}>
              <button className="order-button" onClick={onClick}>
                Editar
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
