import React, { useState } from 'react'
import { Col, Collapse, ListGroup, Modal, Row } from 'react-bootstrap'
import { handlerError } from 'src/selectors/error.selector'
import { FormText, Switch, Button } from 'src/components'
import { itemTypes } from 'src/components/Items/ItemsToSelect'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import Alert from 'sweetalert-react'

export const SerieItem = ({ toSelect, changeItem, modal, type, importation, onAdd }) => {
  const [alert, setAlert] = useState({ title: '' })

  const validateSeriesIndividual = item => {
    let array = []
    item.series.forEach(p => {
      if (p) {
        let index = array.findIndex(f => f === p)
        if (index < 0) array.push(p)
      }
    })
    return array.length === item.series.length
  }

  const validateSeries = () => {
    let response = true
    toSelect.line.forEach(item => {
      if (response === false) return
      if (item.series) {
        response = validateSeriesIndividual(item)
      }
    })
    return response
  }

  const disabledOptions = () => {
    if (type === itemTypes.SELL) {
      if (!toSelect.includeIva && toSelect.phraseReference === null) return true
      return !(
        (toSelect.quantity && toSelect.quantity >= 0) ||
        (toSelect.sQuantity && toSelect.sQuantity >= 0)
      )
    } else if (type === itemTypes.PURCHASE) {
      return toSelect.onlySeries
        ? toSelect.activeSeries && !validateSeries()
        : !toSelect.subTotal ||
            toSelect.subTotal <= 0 ||
            (toSelect.activeSeries && validateSeries()) ||
            (importation && (!toSelect.tax || toSelect.tax < 0))
    } else if (type === itemTypes.EXPENSE) {
      return !toSelect.subTotal || toSelect.subTotal <= 0
    }
    return true
  }

  const renderValues = () => {
    return (
      <div>
        <Col xl={12} md={12}>
          <div style={{ marginTop: 8 }}>
            <Switch
              checked={toSelect.activeSeries}
              id={'activeSeries'}
              onChange={({ target }) => {
                if (!toSelect.haveQuantities) {
                  setAlert({
                    ...handlerError('Ingrese una cantidad para habilitar el botón'),
                    onConfirm: () => setAlert({ show: false }),
                  })
                } else {
                  toSelect.activeSeries = target.checked
                  toSelect.line = toSelect?.line.map(item => ({
                    ...item,
                    series: item.series
                      ? item.series.slice(0, item.quantity || 0)
                      : Array.from({ length: Number(item.quantity || 0) }, () => ''),
                  }))
                  toSelect.onlySeries = true
                }
                if (changeItem) changeItem()
              }}
              label={'Agregar números de serie'}
              info={
                'La cantidad de números de serie depende de la cantidad del producto ingresado'
              }
            />
          </div>
        </Col>
        {toSelect.activeSeries &&
          toSelect.line.map(itemLine => {
            if (itemLine.series && itemLine.series.length > 0)
              return (
                <ListGroup variant="light" style={{ marginTop: '17px', width: '100%' }}>
                  <ListGroup.Item
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      itemLine.openCollapse
                        ? (itemLine.openCollapse = !itemLine.openCollapse)
                        : (itemLine.openCollapse = true)
                      if (changeItem) changeItem()
                    }}>
                    {itemLine.productData.name}
                    <Collapse in={itemLine.openCollapse}>
                      <div>
                        {itemLine.series.map((p, index) => (
                          <Row key={index}>
                            <Col xl={12} md={12}>
                              <FormText
                                label={'Número de serie unidad No.' + (index + 1)}
                                value={p}
                                required
                                onClick={e => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                }}
                                onChange={e => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  itemLine.series[index] = e.target.value
                                  if (changeItem) changeItem()
                                }}
                              />
                            </Col>
                          </Row>
                        ))}
                      </div>
                    </Collapse>
                  </ListGroup.Item>
                </ListGroup>
              )
            return <></>
          })}
      </div>
    )
  }

  return (
    <>
      {modal ? (
        <Modal
          show={toSelect.showSeries}
          centered
          size={'md'}
          onHide={() => {
            toSelect.showSeries = false
            if (changeItem) changeItem()
          }}>
          <Modal.Header closeButton>
            <Modal.Title>{toSelect.productName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{renderValues()}</Modal.Body>
          <Modal.Footer>
            <Row className={'container-buttons'}>
              <Button
                onClick={() => {
                  toSelect.showSeries = false
                  if (onAdd) onAdd()
                }}
                disabled={disabledOptions()}
                icon={faPlusSquare}
                right>
                Agregar
              </Button>
            </Row>
          </Modal.Footer>
        </Modal>
      ) : (
        renderValues()
      )}
      <Alert {...alert} />
    </>
  )
}
