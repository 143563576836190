import React from 'react'
import './SideBar.scss'
import { selectMenuCollapsed, selectMenuGroup } from '../../selectors/dashboard.selector'
import { selectHideNav } from '../../selectors/utilities.selector'
import Menu2 from './Menu2'
import { Accordion } from 'react-bootstrap'
import { expandMenu } from 'src/actions/dashboard.actions'
import { useDispatch, useSelector } from 'react-redux'

function SideBar(props: { isSignedIn: boolean }): JSX.Element {
  const dispatch = useDispatch()
  const collapsed = useSelector(selectMenuCollapsed)
  const hide = useSelector(selectHideNav)
  const group = useSelector(selectMenuGroup)

  const { isSignedIn } = props
  const collapsedClass = collapsed ? '' : 'expand '
  return isSignedIn && !hide ? (
    <aside
      className={`sidebar ${collapsedClass}`}
      onClick={() => dispatch(expandMenu(!collapsed))}>
      <div className={'side-options'}>
        <Accordion className={'h-100'} activeKey={group}>
          <Menu2 />
        </Accordion>
      </div>
    </aside>
  ) : (
    <></>
  )
}

export default SideBar
