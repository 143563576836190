import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Alert from 'sweetalert-react'
import { Modal } from 'react-bootstrap'
import { Card, Icon, TableV2, Title } from '../../../components'
import { faEye } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes as typesCN,
  getCreditNotes,
  onCreate,
} from 'src/actions/creditnote.actions'
import {
  selectCreditNoteResponse,
  selectCreditNotes,
} from 'src/selectors/creditnote.selector'

import { isAllowed } from 'src/selectors/modules.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import { onSetModalPurchase } from 'src/actions/utilities.actions'
import CreditNoteCreate from './CreditNoteCreate'

const CreditNote = ({ modal, show, onHide }) => {
  const dispatch = useDispatch()

  const creditNotes = useSelector(selectCreditNotes)

  const cnResponse = useSelector(selectCreditNoteResponse)
  const loadingCn = useSelector(state => loadingSelector([typesCN.ON_CREATE])(state))
  const hasErrorCn = useSelector(state => hasErrors([typesCN.ON_CREATE])(state))

  const canCreate = useSelector(state => isAllowed(state, [1958, 6058, 12409, 12459]))

  const [cnModal, setCnModal] = useState({ show: false })
  const [actions, setActions] = useState({ cn: false })
  const [alert, setAlert] = useState({ show: false })

  useEffect(() => {
    dispatch(getCreditNotes())
  }, [])

  useEffect(() => {
    if (loadingCn) setActions({ ...actions, cn: true })
    else if (actions.cn) {
      setActions({ ...actions, cn: false })
      if (hasErrorCn)
        setAlert({
          ...handlerError(hasErrorCn.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess(cnResponse),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            setCnModal({ ...cnModal, show: false })
            dispatch(getCreditNotes())
          },
        })
    }
  }, [loadingCn])

  const render = () => {
    return (
      <div className="column">
        <Title
          title={modal ? undefined : 'Notas de crédito'}
          action={canCreate}
          onClick={() => setCnModal({ show: true })}
          actionTitle={'Crear nota'}
        />
        <Card>
          <TableV2
            customClass={'scrollX'}
            items={creditNotes}
            storageKey={'creditNotes'}
            mobileAuto
            headers={[
              {
                show: true,
                label: 'Documento',
                value: ['number'],
                type: 'text',
              },
              {
                show: true,
                label: 'Fecha',
                value: ['date'],
                type: 'date',
              },
              {
                show: true,
                label: 'Tipo',
                value: ['referenceName'],
                type: 'text',
              },
              {
                show: true,
                label: 'Referencia',
                value: ['referenceDescription'],
                type: 'text',
              },
              {
                show: true,
                label: 'Estado',
                type: 'text',
                custom: item =>
                  item.status === 1
                    ? 'Ingresado'
                    : item.status === 2
                    ? 'Completada'
                    : 'Anulada',
              },
              {
                show: true,
                label: 'NIT',
                value: ['nit'],
                type: 'text',
              },
              {
                show: true,
                label: 'Proveedor',
                value: ['providerName'],
                type: 'text',
              },
              {
                show: true,
                label: 'Concepto',
                value: ['description'],
                type: 'text',
              },
              {
                show: true,
                label: 'Total',
                value: ['total'],
                type: 'currency',
              },
              {
                show: true,
                config: true,
                custom: item =>
                  item.documentId &&
                  item.documentType === 1 && (
                    <Icon
                      tooltip={'Ver referencia'}
                      icon={faEye}
                      onClick={() => {
                        dispatch(onSetModalPurchase(item.documentId))
                      }}
                    />
                  ),
              },
            ]}
          />
        </Card>
      </div>
    )
  }

  return (
    <div>
      {modal ? (
        <Modal show={show} size={'xl'} centered onHide={() => onHide()}>
          <Modal.Header closeButton>
            <Modal.Title>Notas de Crédito</Modal.Title>
          </Modal.Header>
          <Modal.Body>{render()}</Modal.Body>
        </Modal>
      ) : (
        render()
      )}

      <CreditNoteCreate
        show={cnModal.show}
        onClose={() => setCnModal({ show: false })}
        onCreate={note => dispatch(onCreate(note))}
        loading={loadingCn}
      />

      <Alert {...alert} />
    </div>
  )
}

export default CreditNote
