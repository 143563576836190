import { actionTypes as types } from '../actions/accountbook.actions'
import { successState } from '../actions/global.actions'

const initialState = {
  items: [],
  item: {},
  logs: [],
}

const AccountbookReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(types.GET_ALL_ITEMS):
      return {
        ...state,
        items: action.items,
      }
    case successState(types.GET_ITEM):
      return {
        ...state,
        items: action.item,
      }
    case successState(types.GET_LOG):
      return {
        ...state,
        logs: action.logs,
      }
    default:
      return state ? state : initialState
  }
}

export default AccountbookReducer
