import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Alert from 'sweetalert-react'
import { Media } from 'react-breakpoints'
import { Modal, Container, Row, Col } from 'react-bootstrap'
import { Button, Icon, Card, TableV2 } from 'src/components'
import {
  faArrowDown,
  faArrowRight,
  faClipboardCheck,
  faClock,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'

import {
  acceptTransfer,
  actionTypes,
  getTransferDetail,
  onSetInfoTransfer,
  rejectTransfer,
} from 'src/actions/warehouse.actions'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, hasErrors } from 'src/selectors/error.selector'

import { formatDateFromMillis, formatHourFromMillis } from 'src/utils/formatters'
import { selectCurrentUser } from 'src/selectors/user.selector'
import { isAllowed } from 'src/selectors/modules.selector'
import { ListVariations } from 'src/content/Production/Categorization/Variation/ListVariations'
import { selectResponseValidate } from 'src/selectors/warehouse.selector'

import ModalTransferValidateAndConfirm from 'src/content/Transfer/DetailTransfers/ModalTransferValidateAndConfirm'
import { getMeasurementSymbol } from 'src/utils/utilities'

import { getItemsFormatted, getItemsQuantity } from '../TransferFunctions'
import { showAlert } from 'src/actions/alert.actions'

const ModalTransferDetail = ({
  show,
  id,
  title,
  onHide,
  isProduction,
  withoutCompany = false,
  onRefresh,
  isTransfer = false,
}) => {
  const dispatch = useDispatch()
  let interval

  const transferDetailIsLoading = useSelector(state =>
    loadingSelector([actionTypes.GET_TRANSFER_DETAIL])(state),
  )

  const loadingReject = useSelector(state =>
    loadingSelector([actionTypes.REJECT_TRANSFER])(state),
  )
  const hasErrorReject = useSelector(state =>
    hasErrors([actionTypes.REJECT_TRANSFER])(state),
  )

  const loadingAccept = useSelector(state =>
    loadingSelector([actionTypes.ACCEPT_TRANSFER])(state),
  )
  const hasErrorAccept = useSelector(state =>
    hasErrors([actionTypes.ACCEPT_TRANSFER])(state),
  )

  const user = useSelector(selectCurrentUser)
  const transfer = useSelector(state => state.warehouse.transfer.transfer) || {}
  const detail = useSelector(state => state.warehouse.transfer.detail)

  const confirm = useSelector(state => isAllowed(state, [12503]))
  const confirmOwn = useSelector(state => isAllowed(state, [12505]))

  const loadingValidate = useSelector(state =>
    loadingSelector([actionTypes.MASSIVE_VALIDATION])(state),
  )
  const responseValidate = useSelector(selectResponseValidate)

  const [actions, setActions] = useState({ reject: false, approve: false })
  const [alert, setAlert] = useState({ title: '' })
  const [, setChange] = useState([])
  const [openValidationTransfer, setOpenValidationTransfer] = useState(false)
  const [flag, setFlag] = useState(false)

  useEffect(() => {
    if (isProduction) {
      interval = setInterval(() => {
        refresh()
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [isProduction])

  useEffect(() => {
    if (show) dispatch(getTransferDetail(id, withoutCompany))
  }, [show])

  useEffect(() => {
    if (loadingReject) setActions({ ...actions, reject: true })
    else if (actions.reject) {
      setActions({ ...actions, reject: false })
      if (hasErrorReject) {
        dispatch(showAlert(handlerError(hasErrorReject.message)))
      } else {
        if (onRefresh) onRefresh()
        else onHide()
      }
    }
  }, [loadingReject])

  useEffect(() => {
    if (loadingAccept) setActions({ ...actions, approve: true })
    else if (actions.approve) {
      setActions({ ...actions, approve: false })
      if (hasErrorAccept)
        setAlert({
          ...handlerError(hasErrorAccept.message),
        })
      else {
        onHide()
        if (onRefresh) onRefresh()
      }
    }
  }, [loadingAccept])

  useEffect(() => {
    if (loadingValidate) setFlag(true)
    else if (flag) {
      if (responseValidate === undefined || Object.entries(responseValidate).length === 0)
        return
      const products = detail?.filter(d => d.movementTypeId === null) || []
      if (responseValidate.errors && products.length > 0)
        responseValidate?.errors.forEach(error => {
          const orderIndex = products.findIndex(o => o.id === parseInt(error.line))
          if (orderIndex > -1) {
            let order = products[orderIndex]

            if (orderIndex < 0) {
              order.status = 2
            } else {
              order.status = 3
              order.description = error.message
              order.available = error.error
            }

            products[orderIndex] = order
          }
        })
    }
  }, [loadingValidate])

  const actionButton = warehouse => {
    if (!warehouse || !warehouse.attendantsIds) return <div />

    const isAttendant = warehouse.attendantsIds.some(
      attendantId => attendantId === user.id,
    )

    const canApprove = isProduction
      ? transfer?.createdBy === user.id
        ? confirmOwn
        : confirm
      : isAttendant

    return (
      <div>
        {canApprove && transfer.stateId !== 4 && transfer.stateId !== 3 && (
          <Button
            loading={loadingReject}
            disabled={loadingAccept}
            icon={faTrash}
            right
            color={'secondary'}
            onClick={() => {
              setAlert({
                show: true,
                type: 'warning',
                title: 'Cancelar solicitud',
                text: 'Desea cancelar la solicitud? Esta acción no podrá ser revertida.',
                showCancelButton: true,
                cancelButtonColor: '#B35796',
                onCancel: () => setAlert({ ...alert, show: false }),
                cancelButtonText: 'Cerrar',
                confirmButtonText: 'Cancelar',
                onConfirm: () => {
                  dispatch(rejectTransfer(id))
                  setAlert({ ...alert, show: false })
                },
              })
            }}>
            Cancelar
          </Button>
        )}
        {canApprove && transfer.stateId === 1 && (
          <Button
            disabled={loadingReject}
            loading={loadingAccept}
            icon={faClipboardCheck}
            right
            color={'primary'}
            onClick={() => {
              if (isTransfer) {
                setOpenValidationTransfer(true)
              } else dispatch(acceptTransfer(id))
            }}>
            Aceptar
          </Button>
        )}
      </div>
    )
  }

  const refresh = () => {
    setChange([{ asd: true }])
  }

  const getItems = movementTypeId =>
    getItemsFormatted(detail.filter(d => d.movementTypeId === movementTypeId))

  const getTable = movementTypeId => {
    return (
      <TableV2
        marginFilter={0}
        items={getItems(movementTypeId)}
        loading={transferDetailIsLoading}
        renderRow={(item, index) => (
          <div className={'b-user-item'} key={index}>
            <div
              className={'justify-content-start align-items-start bu-en'}
              style={{ textAlign: 'left' }}>
              {' '}
              <div className={'b-user-name'}>
                {item?.productData?.name || 'desconocido'}
              </div>
              <div className={'b-user-email'}>
                {item?.productData?.code || 'desconocido'}
              </div>
              {movementTypeId === 1 && item.date && (
                <div className={'b-user-email'}>
                  Fecha de expiración: {formatDateFromMillis(item.date, null, true)}
                </div>
              )}
              {item.warehouseId && (
                <div className={'b-user-email'}>{item.warehouseName}</div>
              )}
              <div style={{ marginLeft: 10 }}>
                <ListVariations selected={item.listCategories} hideMessage />
              </div>
              <div style={{ marginLeft: 10 }}>
                <ListVariations
                  selected={item.listLocations}
                  hideMessage
                  groupName={'Ubicaciones: '}
                />
              </div>
              {item.commentary && (
                <div className={'b-user-email'} style={{ marginLeft: 5 }}>
                  {item.commentary}
                </div>
              )}
            </div>
            <div
              className={'d-flex row align-items-center mr-2 bu-b'}
              style={{ textAlign: 'right' }}>
              <div className={'b-user-email'}>
                {item.realQuantity}
                {getMeasurementSymbol(item.productData?.measurementUnitId)}
              </div>
            </div>
          </div>
        )}
      />
    )
  }

  const buttonArrow = inCard => {
    return (
      <Media>
        {({ breakpoints, currentBreakpoint }) => {
          const tablet = breakpoints[currentBreakpoint] > breakpoints.mobileLandscape
          const show = (tablet && inCard) || (!tablet && !inCard)
          return breakpoints[currentBreakpoint] >= breakpoints.mini ? (
            <div>
              {show && (
                <Icon
                  style={tablet ? {} : { marginTop: 10, marginBottom: 10 }}
                  icon={tablet ? faArrowRight : faArrowDown}
                  size={'2x'}
                />
              )}
            </div>
          ) : (
            <div />
          )
        }}
      </Media>
    )
  }

  const getTime = item => {
    let time
    if (item.stateId === 1) time = new Date().valueOf()
    else time = item.enabledAt

    return time - item.createdAt
  }

  const itemsQuantity = getItemsQuantity(getItems(transfer.isTransformation ? 2 : null))

  return (
    <div>
      <Modal
        show={show && !openValidationTransfer}
        centered
        size={transfer.isTransformation ? 'xl' : 'md'}
        onHide={() => {
          dispatch(onSetInfoTransfer({}))
          // setInfoTransfer({ loadingV: false, items: [] })
          onHide()
        }}
        aria-labelledby={'contained-modal-title-vcenter'}>
        {' '}
        <Modal.Header closeButton>
          <Modal.Title>
            {title} NO: {transfer ? transfer.number : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="invoice">
            <Row style={{ 'margin-top': '10px' }}>
              <Col style={{ width: '45%' }} className="text-center">
                <h6>
                  {transfer && transfer.fromWarehouseData
                    ? transfer.fromWarehouseData.name
                    : 'Desconocido'}
                </h6>
              </Col>
              <Col
                style={{ width: '10%', 'margin-top': '15px' }}
                className={'text-center'}>
                {transfer && transfer.documentTypeId === 16 ? (
                  <div className={'column'}>
                    <Icon icon={faClock} size={'2x'} />
                    <h6>{formatHourFromMillis(getTime(transfer), true)}</h6>
                  </div>
                ) : (
                  <Icon icon={faArrowRight} size={'2x'} />
                )}
              </Col>

              <Col style={{ width: '45%' }} className={'text-center'}>
                <h6>
                  {transfer.toWarehouseData
                    ? transfer.toWarehouseData.name
                    : 'Desconocido'}
                </h6>
              </Col>
            </Row>

            <Row>
              <Col>
                <br />
              </Col>
            </Row>
            <Row>
              <Col style={{ marginBottom: '5px' }}>
                <b>{transfer.recolect ? 'Lugar de recolección: ' : 'Dirección: '}</b>
                <br />
                {transfer.toWarehouseData
                  ? transfer.toWarehouseData.address
                  : 'Desconocido'}
              </Col>
            </Row>

            <Row className="text-center">
              <Col style={{ marginBottom: '5px' }}>
                <b>Número</b>
                <br />
                {transfer.number}
              </Col>
              <Col style={{ marginBottom: '5px' }}>
                <b>Fecha</b>
                <br />
                {formatDateFromMillis(transfer.createdAt)}
              </Col>
              <Col style={{ marginBottom: '5px' }} xl={4} lg={4} md={4} sm={6} xs={6}>
                <b>Estado</b>
                <br />
                {transfer.stateName}
              </Col>
              <Col style={{ marginBottom: '5px' }} xl={4} lg={4} md={4} sm={6} xs={6}>
                <b>Creado por</b>
                <br />
                {transfer.solicitedByData
                  ? transfer.solicitedByData.name
                  : 'No Disponible'}
              </Col>
              <Col style={{ marginBottom: '5px' }} xl={4} lg={4} md={4} sm={6} xs={6}>
                <b>Aceptada por</b>
                <br />
                {transfer.movedByData ? transfer.movedByData.name : 'No Disponible'}
              </Col>
              {transfer.requested && (
                <Col style={{ marginBottom: '5px' }} xl={4} lg={4} md={4} sm={6} xs={6}>
                  <b>Solicitada A</b>
                  <br />
                  {transfer.fromWarehouseData
                    ? transfer.fromWarehouseData.attendantData
                      ? transfer.fromWarehouseData.attendantData.name
                      : 'No disponible'
                    : 'No disponible'}
                </Col>
              )}
              <Col style={{ marginBottom: '5px' }} xl={4} lg={4} md={4} sm={6} xs={6}>
                <b>Cantidad de ítems transferidos</b> <br />
                {itemsQuantity}
              </Col>
            </Row>
            <hr />

            {transfer.isTransformation ? (
              <Row>
                <Col xl={12}>
                  <Row>
                    <Col xl={6} lg={6} md={6} sm={12} sx={12}>
                      <Card
                        title={'Insumos a convertir:'}
                        button={buttonArrow(true)}
                        bodyStyle={{ margin: 0 }}
                        white>
                        {' '}
                        {getTable(2)}
                      </Card>
                    </Col>

                    <Media>
                      {({ breakpoints, currentBreakpoint }) => {
                        const tablet =
                          breakpoints[currentBreakpoint] > breakpoints.mobileLandscape
                        return breakpoints[currentBreakpoint] >= breakpoints.mini ? (
                          !tablet ? (
                            <Col
                              className={'text-center'}
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}>
                              <Icon
                                style={tablet ? {} : { marginTop: 10, marginBottom: 10 }}
                                icon={tablet ? faArrowRight : faArrowDown}
                                size={'2x'}
                              />
                            </Col>
                          ) : (
                            <div />
                          )
                        ) : (
                          <div />
                        )
                      }}
                    </Media>

                    <Col xl={6} lg={6} md={6} sm={12} sx={12}>
                      <Card title={'Convertidos:'} bodyStyle={{ margin: 0 }} white>
                        {getTable(1)}
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              getTable(null)
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            {actionButton(
              transfer.requested ? transfer.fromWarehouseData : transfer.toWarehouseData,
            )}
          </Row>
        </Modal.Footer>
      </Modal>

      <ModalTransferValidateAndConfirm
        init={openValidationTransfer}
        onHide={success => {
          setOpenValidationTransfer(false)
          if (success) {
            onHide()
            if (onRefresh) onRefresh()
          }
        }}
      />

      <Alert {...alert} />
    </div>
  )
}
export default ModalTransferDetail
