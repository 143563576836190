import { getState } from 'src/utils/utilities'
import ModulesServices from 'src/services/modules.services'
import { executeAction } from './global.actions'
import { selectCurrentUserRoles } from 'src/selectors/modules.selector'
import { selectUsers } from 'src/selectors/modules.selector'

export const actionTypes = {
  SET_MODULE_ID: 'SET_MODULE_ID',

  GET_SUBMODULES: 'GET_SUBMODULES',
  GET_SUBMODULES_REQUEST: 'GET_SUBMODULES_REQUEST',
  GET_SUBMODULES_SUCCESS: 'GET_SUBMODULES_SUCCESS',
  GET_SUBMODULES_ERROR: 'GET_SUBMODULES_ERROR',

  GET_USERS_BY_COMPANY_AND_ROLE: 'GET_USERS_BY_COMPANY_AND_ROLE',
  GET_USERS_BY_COMPANY_AND_ROLE_REQUEST: 'GET_USERS_BY_COMPANY_AND_ROLE_REQUEST',
  GET_USERS_BY_COMPANY_AND_ROLE_SUCCESS: 'GET_USERS_BY_COMPANY_AND_ROLE_SUCCESS',
  GET_USERS_BY_COMPANY_AND_ROLE_ERROR: 'GET_USERS_BY_COMPANY_AND_ROLE_ERROR',

  GET_USERS_BY_COMPANY: 'GET_USERS_BY_COMPANY',
  GET_USERS_BY_COMPANY_REQUEST: 'GET_USERS_BY_COMPANY_REQUEST',
  GET_USERS_BY_COMPANY_SUCCESS: 'GET_USERS_BY_COMPANY_SUCCESS',
  GET_USERS_BY_COMPANY_ERROR: 'GET_USERS_BY_COMPANY_ERROR',

  GET_CURRENT_COMPANY_USERS: 'GET_CURRENT_COMPANY_USERS',
  GET_CURRENT_COMPANY_USERS_REQUEST: 'GET_CURRENT_COMPANY_USERS_REQUEST',
  GET_CURRENT_COMPANY_USERS_SUCCESS: 'GET_CURRENT_COMPANY_USERS_SUCCESS',
  GET_CURRENT_COMPANY_USERS_ERROR: 'GET_CURRENT_COMPANY_USERS_ERROR',

  GET_ROLES_BY_SUBMODULE: 'GET_ROLES_BY_SUBMODULE',
  GET_ROLES_BY_SUBMODULE_REQUEST: 'GET_ROLES_BY_SUBMODULE_REQUEST',
  GET_ROLES_BY_SUBMODULE_SUCCESS: 'GET_ROLES_BY_SUBMODULE_SUCCESS',
  GET_ROLES_BY_SUBMODULE_ERROR: 'GET_ROLES_BY_SUBMODULE_ERROR',

  GET_USERS_CHILDREN: 'GET_USERS_CHILDREN',

  GET_ROLES: 'GET_ROLES',
  GET_ROLES_REQUEST: 'GET_ROLES_REQUEST',
  GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
  GET_ROLES_ERROR: 'GET_ROLES_ERROR',

  GET_ROLES_BY_USER: 'GET_ROLES_BY_USER',
  GET_ROLES_BY_USER_REQUEST: 'GET_ROLES_BY_USER_REQUEST',
  GET_ROLES_BY_USER_SUCCESS: 'GET_ROLES_BY_USER_SUCCESS',
  GET_ROLES_BY_USER_ERROR: 'GET_ROLES_BY_USER_ERROR',

  GET_ALL_USERS_ROLES: 'GET_ALL_USERS_ROLES',
  GET_ALL_USERS_ROLES_REQUEST: 'GET_ALL_USERS_ROLES_REQUEST',
  GET_ALL_USERS_ROLES_SUCCESS: 'GET_ALL_USERS_ROLES_SUCCESS',
  GET_ALL_USERS_ROLES_ERROR: 'GET_ALL_USERS_ROLES_ERROR',

  PUT_ROLE_BY_USER: 'PUT_ROLE_BY_USER',
  PUT_ROLE_BY_USER_REQUEST: 'PUT_ROLE_BY_USER_REQUEST',
  PUT_ROLE_BY_USER_SUCCESS: 'PUT_ROLE_BY_USER_SUCCESS',
  PUT_ROLE_BY_USER_ERROR: 'PUT_ROLE_BY_USER_ERROR',

  DELETE_ROLE_BY_USER_REQUEST: 'DELETE_ROLE_BY_USER_REQUEST',
  DELETE_ROLE_BY_USER_SUCCESS: 'DELETE_ROLE_BY_USER_SUCCESS',
  DELETE_ROLE_BY_USER_ERROR: 'DELETE_ROLE_BY_USER_ERROR',

  UPDATE_ROLES_BY_USER: 'UPDATE_ROLES_BY_USER',
  UPDATE_ROLES_BY_USER_REQUEST: 'UPDATE_ROLES_BY_USER_REQUEST',
  UPDATE_ROLES_BY_USER_SUCCESS: 'UPDATE_ROLES_BY_USER_SUCCESS',
  UPDATE_ROLES_BY_USER_ERROR: 'UPDATE_ROLES_BY_USER_ERROR',

  GET_CURRENT_USER_ROLES: 'GET_CURRENT_USER_ROLES',
  GET_CURRENT_USER_ROLES_REQUEST: 'GET_CURRENT_USER_ROLES_REQUEST',
  GET_CURRENT_USER_ROLES_SUCCESS: 'GET_CURRENT_USER_ROLES_SUCCESS',
  GET_CURRENT_USER_ROLES_ERROR: 'GET_CURRENT_USER_ROLES_ERROR',

  GET_USERS_BY_ROLE: 'GET_USERS_BY_ROLE',

  LOADING_TOGGLE: 'LOADING_TOGGLE',
  ADMIN_MODULES: 'ADMIN_MODULES',
  GET_USERS_SUBMODULE: 'GET_USERS_SUBMODULE',
  GET_MODULES: 'GET_MODULES',
  CREATE_GROUP: 'CREATE_GROUP',
  UPDATE_GROUP: 'UPDATE_GROUP',
  DELETE_GROUP: 'DELETE_GROUP',
  GET_GROUP: 'GET_GROUP',
  GET_GROUPS: 'GET_GROUPS',
  GET_ROLES_FOR_SELECT: 'GET_ROLES_FOR_SELECT',
  SET_FAVORITE: 'SET_FAVORITE',
  REVOKE_FAVORITE: 'REVOKE_FAVORITE',
  GET_MODULE_GROUPS: 'GET_MODULE_GROUPS',
}

const getCurrentUserRolesRequest = () => ({
  type: actionTypes.GET_CURRENT_USER_ROLES_REQUEST,
})
const getCurrentUserRolesSuccess = roles => ({
  type: actionTypes.GET_CURRENT_USER_ROLES_SUCCESS,
  roles,
})
const getCurrentUserRolesError = error => ({
  type: actionTypes.GET_CURRENT_USER_ROLES_ERROR,
  error,
})

const onSetModuleId = module => ({ type: actionTypes.SET_MODULE_ID, module })

const getSubModulesRequest = () => ({
  type: actionTypes.GET_SUBMODULES_REQUEST,
})
const getSubModulesSuccess = subModules => ({
  type: actionTypes.GET_SUBMODULES_SUCCESS,
  subModules,
})
const getSubModulesError = error => ({
  type: actionTypes.GET_SUBMODULES_ERROR,
  error,
})

const getUsersByCompanyAndRoleRequest = () => ({
  type: actionTypes.GET_USERS_BY_COMPANY_AND_ROLE_REQUEST,
})
const getUsersByCompanyAndRoleSuccess = users => ({
  type: actionTypes.GET_USERS_BY_COMPANY_AND_ROLE_SUCCESS,
  users,
})
const getUsersByCompanyAndRoleError = error => ({
  type: actionTypes.GET_USERS_BY_COMPANY_AND_ROLE_ERROR,
  error,
})

const getUsersByCompanyRequest = () => ({
  type: actionTypes.GET_USERS_BY_COMPANY_REQUEST,
})
const getUsersByCompanySuccess = users => ({
  type: actionTypes.GET_USERS_BY_COMPANY_SUCCESS,
  users,
})
const getUsersByCompanyError = error => ({
  type: actionTypes.GET_USERS_BY_COMPANY_ERROR,
  error,
})

const getCurrentCompanyUsersRequest = () => ({
  type: actionTypes.GET_CURRENT_COMPANY_USERS_REQUEST,
})
const getCurrentCompanyUsersSuccess = users => ({
  type: actionTypes.GET_CURRENT_COMPANY_USERS_SUCCESS,
  users,
})
const getCurrentCompanyUsersError = error => ({
  type: actionTypes.GET_CURRENT_COMPANY_USERS_ERROR,
  error,
})

const getRolesBySubModuleRequest = () => ({
  type: actionTypes.GET_ROLES_BY_SUBMODULE_REQUEST,
})
const getRolesBySubModuleSuccess = roles => ({
  type: actionTypes.GET_ROLES_BY_SUBMODULE_SUCCESS,
  roles,
})
const getRolesBySubModuleError = error => ({
  type: actionTypes.GET_ROLES_BY_SUBMODULE_ERROR,
  error,
})

const getRolesRequest = () => ({ type: actionTypes.GET_ROLES_REQUEST })
const getRolesSuccess = roles => ({
  type: actionTypes.GET_ROLES_SUCCESS,
  roles,
})
const getRolesError = error => ({ type: actionTypes.GET_ROLES_ERROR, error })

const getRolesByUserRequest = () => ({
  type: actionTypes.GET_ROLES_BY_USER_REQUEST,
})
const getRolesByUserSuccess = roles => ({
  type: actionTypes.GET_ROLES_BY_USER_SUCCESS,
  roles,
})
const getRolesByUserError = error => ({
  type: actionTypes.GET_ROLES_BY_USER_ERROR,
  error,
})

const getAllUsersChildRolesRequest = () => ({
  type: actionTypes.GET_ALL_USERS_ROLES_REQUEST,
})
const getAllUsersChildRolesSuccess = users_roles => ({
  type: actionTypes.GET_ALL_USERS_ROLES_SUCCESS,
  users_roles,
})
const getAllUsersChildRolesError = error => ({
  type: actionTypes.GET_ALL_USERS_ROLES_ERROR,
  error,
})

const putRoleByUserRequest = () => ({
  type: actionTypes.PUT_ROLE_BY_USER_REQUEST,
})
const putRoleByUserSuccess = data => ({
  type: actionTypes.PUT_ROLE_BY_USER_SUCCESS,
  data,
})
const putRoleByUserError = error => ({
  type: actionTypes.PUT_ROLE_BY_USER_ERROR,
  error,
})

const deleteRoleByUserRequest = () => ({
  type: actionTypes.PUT_ROLE_BY_USER_REQUEST,
})
const deleteRoleByUserSuccess = data => ({
  type: actionTypes.PUT_ROLE_BY_USER_SUCCESS,
  data,
})
const deleteRoleByUserError = error => ({
  type: actionTypes.PUT_ROLE_BY_USER_ERROR,
  error,
})

const updateROlesByUserRequest = () => ({
  type: actionTypes.UPDATE_ROLES_BY_USER_REQUEST,
})
const updateROlesByUserSuccess = response => ({
  type: actionTypes.UPDATE_ROLES_BY_USER_SUCCESS,
  response,
})
const updateROlesByUserError = error => ({
  type: actionTypes.UPDATE_ROLES_BY_USER_ERROR,
  error,
})

const loadingToggle = value => ({ type: actionTypes.LOADING_TOGGLE, value })
const rolesForSelect = response => ({
  type: actionTypes.GET_ROLES_FOR_SELECT,
  response,
})

export const setModuleId = module => dispatch => {
  dispatch(onSetModuleId(module))
}

export const getRolesByModule = (module, noReplace) => async dispatch => {
  dispatch(getRolesRequest())
  try {
    const roles = await ModulesServices.getRolesByModule(module)
    if (!noReplace) dispatch(getRolesSuccess(roles))
    else dispatch(rolesForSelect(roles))
  } catch (e) {
    console.error('error: ', e.message)
    dispatch(getRolesError(e))
  }
}

export const getRolesByUser = (module, user) => async dispatch => {
  dispatch(getRolesByUserRequest())
  try {
    const roles = await ModulesServices.getRolesByUser(module, user)
    dispatch(getRolesByUserSuccess(roles))
  } catch (e) {
    console.error('error: ', e.message)
    dispatch(getRolesByUserError(e))
  }
}

export const getUsersChildrenByModule =
  (module, includeParent, force) => async dispatch => {
    const users = getState(selectUsers)
    const process = async () => ({
      users:
        users.length > 0 && !force
          ? users
          : await ModulesServices.getUsersChildren(module, includeParent),
    })
    dispatch(executeAction(actionTypes.GET_USERS_CHILDREN, process))
  }

export const getUsersByCompanyAndRole = (companyId, roleId) => async dispatch => {
  dispatch(getUsersByCompanyAndRoleRequest())
  try {
    const users = await ModulesServices.getUsersByCompanyAndRole(companyId, roleId)
    dispatch(getUsersByCompanyAndRoleSuccess(users))
  } catch (e) {
    console.error('error: ', e.message)
    dispatch(getUsersByCompanyAndRoleError(e))
  }
}

export const getUsersByCompany = companyId => async dispatch => {
  dispatch(getUsersByCompanyRequest())
  try {
    const users = await ModulesServices.getUsersByCompany(companyId)
    dispatch(getUsersByCompanySuccess(users))
  } catch (e) {
    console.error('error: ', e.message)
    dispatch(getUsersByCompanyError(e))
  }
}

export const getCurrentCompanyUsers = () => async dispatch => {
  dispatch(getCurrentCompanyUsersRequest())
  try {
    const users = await ModulesServices.getCurrentCompanyUsers()
    dispatch(getCurrentCompanyUsersSuccess(users))
  } catch (e) {
    console.error('error: ', e.message)
    dispatch(getCurrentCompanyUsersError(e))
  }
}

export const getRolesByUserChild = (module, user, noReplace) => async dispatch => {
  dispatch(getRolesByUserRequest())
  try {
    const roles = await ModulesServices.getRolesByUser(module, user)
    if (!noReplace) {
      dispatch(getRolesByUserSuccess(roles))
      dispatch(loadingToggle(false))
    } else dispatch(rolesForSelect(roles))
  } catch (e) {
    console.error('error: ', e.message)
    dispatch(getRolesByUserError(e))
  }
}

export const getAllUserChildRoles = module => async dispatch => {
  dispatch(getAllUsersChildRolesRequest())
  try {
    const roles = await ModulesServices.getAllUsersRoles(module)
    dispatch(getAllUsersChildRolesSuccess(roles))
  } catch (e) {
    console.error('error: ', e.message)
    dispatch(getAllUsersChildRolesError(e))
  }
}

export const updateRolesByUser = (module, user, roles, value) => async dispatch => {
  dispatch(updateROlesByUserRequest())
  const permissions = roles.map(r => r.id)
  try {
    await ModulesServices.addOrRevokeMultipleRoles(user, permissions, value)
    dispatch(updateROlesByUserSuccess('success'))
    dispatch(getRolesByUserChild(module, user))
  } catch (e) {
    dispatch(updateROlesByUserError(e))
  }
}

export const updateRolesByRole = (module, users, rol, value) => async dispatch => {
  dispatch(updateROlesByUserRequest())
  let promises = []
  users.map(async u => {
    if (value) promises.push(ModulesServices.putPermissionsByUser(module, u.id, rol))
    else promises.push(ModulesServices.deletePermissionsByUser(module, u.id, rol))
  })
  return Promise.all(promises)
    .then(() =>
      dispatch(
        updateROlesByUserSuccess('success'),
        dispatch(getRolesByUserChild(module, users[0].id)),
      ),
    )
    .catch(e => dispatch(updateROlesByUserError(e)))
}

export const putRoleByUser = (module, user, role, reload) => async dispatch => {
  dispatch(putRoleByUserRequest())
  if (reload) dispatch(loadingToggle(true))
  try {
    const data = await ModulesServices.putPermissionsByUser(module, user, role)
    dispatch(putRoleByUserSuccess(data))
    if (reload) dispatch(getRolesByUserChild(module, user))
  } catch (e) {
    console.error('error: ', e.message)
    dispatch(putRoleByUserError(e))
  }
}

export const deleteRoleByUser = (module, user, role, reload) => async dispatch => {
  dispatch(deleteRoleByUserRequest())
  if (reload) dispatch(loadingToggle(true))
  try {
    const data = await ModulesServices.deletePermissionsByUser(module, user, role)
    if (reload) dispatch(getRolesByUserChild(module, user))
    dispatch(deleteRoleByUserSuccess(data))
  } catch (e) {
    console.error('error: ', e.message)
    dispatch(deleteRoleByUserError(e))
  }
}

export const getSubmodules = id => async dispatch => {
  dispatch(getSubModulesRequest())
  try {
    const subModules = await ModulesServices.getSubmodules(id)
    dispatch(getSubModulesSuccess(subModules))
  } catch (error) {
    console.log('action', error)
    dispatch(getSubModulesError(error))
  }
}

export const getGroups = id => dispatch => {
  const process = async () => ({ groups: await ModulesServices.getGroups(id) })
  dispatch(executeAction(actionTypes.GET_MODULE_GROUPS, process))
}

export const getRolesBySubmodule = (module, subModule) => async dispatch => {
  dispatch(getRolesBySubModuleRequest())
  try {
    const roles = await ModulesServices.getSubmoduleRoles(module, subModule)
    dispatch(getRolesBySubModuleSuccess(roles))
  } catch (error) {
    console.log('action', error)
    dispatch(getRolesBySubModuleError(error))
  }
}

export const getCurrentUserRolesAction = module => async dispatch => {
  const roles = getState(selectCurrentUserRoles)
  if (roles.length > 0) return

  dispatch(getCurrentUserRolesRequest())
  try {
    const roles = await ModulesServices.getCurrentUserRoles(module)
    dispatch(getCurrentUserRolesSuccess(roles))
  } catch (e) {
    dispatch(getCurrentUserRolesError(e))
  }
}

export const getUsersByRole = roleId => dispatch => {
  const process = async () => ({
    users: await ModulesServices.getUsersByRole(roleId),
  })
  dispatch(executeAction(actionTypes.GET_USERS_BY_ROLE, process))
}

export const getModulesAdmin = () => dispatch => {
  const process = async () => ({
    adminModules: await ModulesServices.getModulesForAdmin(),
  })
  dispatch(executeAction(actionTypes.ADMIN_MODULES, process))
}

export const toogleModuleAdmin = id => dispatch => {
  const process = async () => ({
    module: await ModulesServices.toogleModuleForAdmin(id),
  })
  dispatch(executeAction(actionTypes.TOOGLE_MODULE, process))
}

export const getUsersInSubmodule = submodules => dispatch => {
  const process = async () => ({
    usersSubmodule: await ModulesServices.getUsersInSubmodule(submodules),
  })
  dispatch(executeAction(actionTypes.GET_USERS_SUBMODULE, process))
}

export const getCompanyUsers = () => dispatch => {
  const process = async () => ({
    usersSubmodule: await ModulesServices.getCurrentCompanyUsers(),
  })
  dispatch(executeAction(actionTypes.GET_USERS_SUBMODULE, process))
}

export const getAllAppModules = () => dispatch => {
  const process = async () => ({
    modules: await ModulesServices.getAllAppModules(),
  })
  dispatch(executeAction(actionTypes.GET_MODULES, process))
}

export const getAllGroups = () => dispatch => {
  const process = async () => ({ group: await ModulesServices.getAllGroups() })
  dispatch(executeAction(actionTypes.GET_GROUPS, process))
}

export const createGroup = request => dispatch => {
  const process = async () => ({
    modules: await ModulesServices.createGroup(request),
  })
  dispatch(executeAction(actionTypes.CREATE_GROUP, process))
}

export const getGroupRole = id => dispatch => {
  const process = async () => ({
    group: await ModulesServices.getGroupRoles(id),
  })
  dispatch(executeAction(actionTypes.GET_GROUP, process))
}

export const updateGroup = (id, request) => dispatch => {
  const process = async () => ({
    modules: await ModulesServices.updateGroup(id, request),
  })
  dispatch(executeAction(actionTypes.UPDATE_GROUP, process))
}

export const deleteGroup = id => dispatch => {
  const process = async () => ({
    modules: await ModulesServices.deleteGroup(id),
  })
  dispatch(executeAction(actionTypes.DELETE_GROUP, process))
}

export const setFavorite = id => dispatch => {
  const process = async () => ({
    message: await ModulesServices.setFavorite(id),
  })
  dispatch(executeAction(actionTypes.SET_FAVORITE, process))
}

export const revokeFavorite = id => dispatch => {
  const process = async () => ({
    message: await ModulesServices.revokeFavorite(id),
  })
  dispatch(executeAction(actionTypes.REVOKE_FAVORITE, process))
}
