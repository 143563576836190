import React, {useState, useEffect} from 'react';
import {useSelector,useDispatch} from "react-redux";

import {Col, Row} from "react-bootstrap";
import {
    faArrowLeft,
    faArrowRight,
    faChevronRight, faSave,
    faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import Alert from "sweetalert-react";

import Card from "../../../../components/cards/Card";
import Icon from "../../../../components/buttons/IconButton";
import Button from "../../../../components/buttons/Button";

import {actionTypes as typeO,getSingleOffer, onUpdateOffer, onResetUpdate} from "../../../../actions/offers.actions";
import {selectOffer, selectOfferUp} from "../../../../selectors/offers.selector";

import {getProducts, getProductsWithTransportationPayment} from "../../../../actions/products.actions";
import {selectAllProducts, selectTransportProducts} from "../../../../selectors/products.selector";

import {selectCurrentCompany} from "../../../../selectors/user.selector";

import {loadingSelector} from "../../../../selectors/loading.selector";
import {singleErrorSelector, hasErrorsSelector, handlerError, handlerSuccess} from "../../../../selectors/error.selector";
import { TableV2 } from '../../../../components';

const OfferUpdate = (props) => {
    const dispatch = useDispatch();

    const offer = useSelector((state) => selectOffer(state));
    const products = useSelector((state) => selectAllProducts(state));
    const productsT = useSelector((state) => selectTransportProducts(state));
    const company = useSelector((state) => selectCurrentCompany(state));
    const response = useSelector((state) => selectOfferUp(state));

    const loading = useSelector((state) => loadingSelector([typeO.ON_UPDATE_OFFER])(state));
    const hasError = useSelector((state) => hasErrorsSelector([typeO.ON_UPDATE_OFFER])(state));
    const error = useSelector((state) => singleErrorSelector([typeO.ON_UPDATE_OFFER])(state));

    const [productList, setProductList] = useState([]);
    const [alert, setAlert] = useState({title: 'default'});

    useEffect(() => {
        const {id} = props;
        if (id){
            dispatch(getSingleOffer(id))
        }
    }, []);

    useEffect(() => {
        if (!offer.productList || offer.productList.length <= 0 || !offer.category) return;
        if (offer.category.id === 1) dispatch(getProductsWithTransportationPayment());
        else dispatch(getProducts());
        setProductList(offer.productList.map((p) => (
            {...p, companyId: p.product.companyId, activeLink: p.product.activeLink, productCode: p.product.code, productName: p.product.name, productPrice: p.product.price}
        )));
    }, [offer.productList]);

    useEffect(() => {
        if (!hasError) return;
        setAlert({
            ...handlerError((error.message && error.message != '') || 'Ha ocurrido un error inesperado'),
            onConfirm: () => setAlert({...alert, show: false})
        });
    }, [hasError]);

    useEffect(() => {
        if (!response.id) return;
        dispatch(onResetUpdate());
        setAlert({
            ...handlerSuccess('Los productos del enlace han sido actualizados satisfactoriamente'),
            onConfirm: () => {
                setAlert({...alert, show: false});
                props.onChange(false);
                dispatch(getSingleOffer(props.id));
            }
        });
    }, [response]);

    const getItems = () => {
        let items = [];
        if (offer && offer.category) {
            if (offer.category.id === 1)
                items = productsT.map((p) => ({...p, companyId: p.product.companyId, activeLink: p.product.activeLink, productCode: p.product.code, productName: p.product.name, productPrice: p.product.price}));
            else items = products;
        }
        return items;
    }

    const filter = (item) => {
        const product = productList.find((p) => p.productId === item.productId);

        const own = item.companyId === company;
        const activeLink = item.activeLink;

        return !product && own && activeLink;
    };

    
    const addProduct = (item) => {
        setProductList([...productList, item]);
    };

    const deleteItem = (item) => {
        const items = [];
        productList.map((p) => {
            if (p.productId != item.productId)
                items.push(p);
        });

        setProductList(items);
    };

    const addAll = (items) => setProductList([...productList, ...items]);

    const removeAll = () => setProductList([]);

    const getHeaders = (deleted) => {
        const header = [
            { label: 'Código', show: true, value: ['productCode'], type: 'text', className: 'mini' },
            { label: 'Producto', show: true, value: ['productName'], type: 'text', className: 'medium' }

        ]
        header.push({ config: true, show: true, label: '', className: 'mini center' ,
            custom: (item) => <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Icon
                    icon={!deleted ? faChevronRight : faTrashAlt}
                    tooltip={!deleted ? 'Agregar' : 'Descartar'}
                    onClick={() => !deleted ? addProduct(item) : deleteItem(item)} />
            </div>
        });
        return header;
    }


    return (<div>
        <Row>
            <Col xl={6 } lg={6} md={12} sm={12} xs={12}>
                <Card>
                    <strong>Productos fuera del enlace</strong>
                    <Row className={'container-buttons'}>
                        <Button onClick={() => addAll(getItems().filter(filter))} disabled={getItems().filter(filter).length === 0} variant={'info'} icon={faArrowRight} right>Agregar todos </Button>
                    </Row>
                    <TableV2
                        pSize={0}
                        headers={getHeaders(false)}
                        items={getItems().filter(filter)}
                        mobileAuto
                        storageKey={`contractAddAll`}
                    />
                </Card>
            </Col>
            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Card>
                    <strong>Productos en el enlace</strong>
                    <Row style={{justifyContent: 'flex-start', margin: 3}}>
                        <Button onClick={() => removeAll(productList)} disabled={productList.length === 0} variant={'info'} icon={faArrowLeft}> Remover todos</Button>
                    </Row>
                    <TableV2
                        pSize={0}
                        headers={getHeaders(true)}
                        items={productList}
                        mobileAuto
                        storageKey={`contractAddAll`}
                    />
                </Card>
            </Col>
        </Row>

        <Row className={'container-buttons'}>
            <Button loading={loading} disabled={productList.length == 0} variant={'info'} icon={faSave} onClick={() => {
                const request = {
                    products: productList.map((p) => (p.productId)),
                    category: 1,
                    alias: 'default',
                    priceNegotiable: false,
                    availableUntil: new Date().valueOf()
                }

                dispatch(onUpdateOffer(props.id, request));
            }}> Actualiar</Button>
        </Row>

        <Alert {...alert}/>

    </div>);
};
export default OfferUpdate;
