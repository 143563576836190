import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";

import {Td, Tr} from "react-super-responsive-table";

import {
  Title,  Card, CustomSummary, TableV2
} from "../../../components";

import {
  selectIssues,
} from "../../../selectors/tickets.selector";

import {loadingSelector} from "../../../selectors/loading.selector";
import {
  actionTypes as typesT,
  getIssues
} from "../../../actions/tickets.actions";
import {
  formatDateFromMillis,
  formatNumberWithCommas,
  getItemValue
} from "../../../utils/formatters";
import SelectedDates from "../../../components/SelectedDates/SelectedDates";

const Issues = () => {
  const dispatch = useDispatch();

  const issues = useSelector(selectIssues);
  const loading = useSelector((state) => loadingSelector([typesT.GET_HISTORICAL_LOG])(state));

  useEffect(() => {
    dispatch(getIssues());
  }, []);

  const getHeaders = () => {
    return [
      {label: 'Fecha', show: true, value: ['date'], type: 'date' ,  className:'mini'},
      {label: 'Nombre', show: true, value: ['name'], type: 'text' ,  className:'mini'},
      {label: 'Email', show: true, value: ['email'], type: 'text' ,  className:'mini'},
      {label: 'Teléfono', show: true, value: ['phone'], type: 'text' ,  className:'mini'},
      {label: 'Tipo', show: true, value: ['issueType'], type: 'text' ,  className:'mini'},
      {label: 'Descripción', show: true, value: ['commentary'], type: 'text' ,  className:'mini'},
      {config:true, show: true, label:'', className:'mini'}
    ]
  }

  const getItemsMobile = (data) => {
    let array = []
        const headers = getHeaders()
        let store = localStorage.getItem(`issues`)
        if(!store) store={title: {value: ['name'], type: 'text'}, subtitle: {value: ['email'], type: 'text'},
            complement1: {value: ['phone'], type: 'text'}, complement2: {value: ['date'], type: 'date'}}
        else store = JSON.parse(store)
        data.map((item, i) => {
            let more = []
            let custom 
            headers.map((header) => {
                if (header.config) {custom = header.custom; return}
                if (header.custom && header.value) more.push({title: header.label , info: header.custom(item)})
                else more.push({title: header.label , info: getItemValue(item, header.value, header.type)})
            })

            let object = {id: item.id, title: getItemValue(item, store.title.value, store.title.type) ,
                subtitle: getItemValue(item, store.subtitle.value, store.subtitle.type),
                complement1: getItemValue(item, store.complement1.value, store.complement1.type),
                complement2: getItemValue(item, store.complement2.value, store.complement2.type),
                //selected: selected.filter(d => d.id === item.id).length > 0,
                key: `issues-${item.id}`, action: custom(item), more
            }
            array.push(object)
        })
        return array;
  }

  return (
    <div>
      <Title title={'Reporte de Problemas'}/>
      <Card white title={'Listado'}>
        <TableV2 
          storageKey={`issues`}
          headers={getHeaders()}
          items={issues.sort((a, b) => (b.date - a.date))}
          noItemsLegend={'No hay reportes con los filtros aplicados'}
          loading={loading}
          itemsMobile = {getItemsMobile(issues.sort((a, b) => (b.date - a.date)))}
        />
       
      </Card>
    </div>);
};
export default Issues;