import { getState } from 'src/utils/utilities'
import CurrenciesServices from 'src/services/currencies.services'
import {
  selectAllCurrencies,
  selectCountries,
  selectEconomicActivities,
  selectExportationCountries,
  selectExportationRegime,
  selectFiscalPrecinct,
  selectIncoterm,
} from 'src/selectors/currencies.selector'
import { executeAction } from './global.actions'

export const actionTypes = {
  GET_CURRENCIES: 'GET_CURRENCIES',
  GET_CURRENT_CURRENCY: 'GET_CURRENT_CURRENCY',
  SET_CURRENT_CURRENCY: 'GET_CURRENT_CURRENCY',
  GET_COUNTRIES: 'GET_COUNTRIES',
  GET_ECONOMIC_ACTIVITY: 'GET_ECONOMIC_ACTIVITY',
  GET_FISCAL_PRECINCT: 'GET_FISCAL_PRECINCT',
  GET_REGIMEN: 'GET_REGIMEN',
  GET_INCOTERM: 'GET_INCOTERM',
  GET_EXPORTATION_COUNTRIES: 'GET_EXPORTATION_COUNTRIES',
}

export const getAllCurrencies = () => dispatch => {
  const currencies = getState(selectAllCurrencies)
  const process = async () => ({
    currencies:
      currencies.length > 0 ? currencies : await CurrenciesServices.getAllCurrencies(),
  })
  dispatch(executeAction(actionTypes.GET_CURRENCIES, process))
}

/** Get all countries available **/
export const getCountries = () => dispatch => {
  const countries = getState(selectCountries)
  const process = async () => ({
    countries: countries.length > 0 ? countries : await CurrenciesServices.getCountries(),
  })
  dispatch(executeAction(actionTypes.GET_COUNTRIES, process))
}

export const getCurrentCurrency = () => dispatch => {
  const process = async () => ({
    currency: await CurrenciesServices.getCurrentCurrency(),
  })
  dispatch(executeAction(actionTypes.GET_CURRENT_CURRENCY, process))
}

export const setCurrentCurrency = currency => dispatch => {
  const process = () => ({
    currency,
  })
  dispatch(executeAction(actionTypes.SET_CURRENT_CURRENCY, process))
}

export const getAllEconomicActivity = country => dispatch => {
  const activities = getState(selectEconomicActivities)?.filter(
    activity => activity.country === country,
  )
  const process = async () => ({
    economicActivity:
      activities.length > 0
        ? activities
        : await CurrenciesServices.getEconomicActivity(country),
  })
  dispatch(executeAction(actionTypes.GET_ECONOMIC_ACTIVITY, process))
}

export const getFiscalPrecinct = () => dispatch => {
  const fiscalPrecinct = getState(selectFiscalPrecinct)
  if (fiscalPrecinct.length > 0) return
  const process = async () => ({
    fiscalPrecinct: await CurrenciesServices.getFiscalPrecinct(),
  })
  dispatch(executeAction(actionTypes.GET_FISCAL_PRECINCT, process))
}

export const getExportationRegimen = () => dispatch => {
  const expRegime = getState(selectExportationRegime)
  if (expRegime.length > 0) return
  const process = async () => ({
    regimen: await CurrenciesServices.getRegimen(),
  })
  dispatch(executeAction(actionTypes.GET_REGIMEN, process))
}

export const getIncoterm = () => dispatch => {
  const incoterms = getState(selectIncoterm)
  if (incoterms.length > 0) return
  const process = async () => ({
    incoterm: await CurrenciesServices.getIncoterm(),
  })
  dispatch(executeAction(actionTypes.GET_INCOTERM, process))
}

export const getExportationCountries = () => dispatch => {
  const countries = getState(selectExportationCountries)
  if (countries.length > 0) return
  const process = async () => ({
    exportationCountries: await CurrenciesServices.getExportationCountries(),
  })
  dispatch(executeAction(actionTypes.GET_EXPORTATION_COUNTRIES, process))
}
