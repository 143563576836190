import TransformationServices from '../services/transformation.services'
import { executeAction } from './global.actions'

export const actionTypes = {
  GET_TRANSFORMATIONS: 'GET_TRANSFORMATIONS',
  GET_TRANSFORMATIONS_REQUEST: 'GET_TRANSFORMATIONS_REQUEST',
  GET_TRANSFORMATIONS_SUCCESS: 'GET_TRANSFORMATIONS_SUCCESS',
  GET_TRANSFORMATIONS_ERROR: 'GET_TRANSFORMATIONS_ERROR',

  GET_DERIVED_PRODUCTS: 'GET_DERIVED_PRODUCTS',
  GET_DERIVED_PRODUCTS_REQUEST: 'GET_DERIVED_PRODUCTS_REQUEST',
  GET_DERIVED_PRODUCTS_SUCCESS: 'GET_DERIVED_PRODUCTS_SUCCESS',
  GET_DERIVED_PRODUCTS_ERROR: 'GET_DERIVED_PRODUCTS_ERROR',

  ON_CREATE_TRANSFORMATION: 'ON_CREATE_TRANSFORMATION',

  GET_INVENTORY_HISTORY: 'GET_INVENTORY_HISTORY',
}

const getTransformationsRequest = () => ({
  type: actionTypes.GET_TRANSFORMATIONS_REQUEST,
})
const getTransformationsSuccess = response => ({
  type: actionTypes.GET_TRANSFORMATIONS_SUCCESS,
  response,
})
const getTransformationsError = error => ({
  type: actionTypes.GET_TRANSFORMATIONS_ERROR,
  error,
})

const getDerivedProductsRequest = () => ({
  type: actionTypes.GET_DERIVED_PRODUCTS_REQUEST,
})
const getDerivedProductsSuccess = response => ({
  type: actionTypes.GET_DERIVED_PRODUCTS_SUCCESS,
  response,
})
const getDerivedProductsError = error => ({
  type: actionTypes.GET_DERIVED_PRODUCTS_ERROR,
  error,
})

export const onCreateTransformation = request => async dispatch => {
  const process = async () => ({
    response: await TransformationServices.onCreateTransformation(request),
  })
  dispatch(executeAction(actionTypes.ON_CREATE_TRANSFORMATION, process))
}

export const onCreateRecipeTransformation = request => async dispatch => {
  const process = async () => ({
    response: await TransformationServices.onCreateRecipeTransformation(request),
  })
  dispatch(executeAction(actionTypes.ON_CREATE_TRANSFORMATION, process))
}

export const getDerivedProducts = item => async dispatch => {
  dispatch(getDerivedProductsRequest())
  try {
    let derivatives
    if (item.productId === item.baseProduct) {
      derivatives = await TransformationServices.getDerivedProducts(item.baseProduct)
    } else derivatives = await TransformationServices.getBaseProducts(item.productId)

    // const derivatives = await TransformationServices.getDerivedProducts(item.baseProduct || item.productId);
    // const base = await TransformationServices.getBaseProducts(item.productId)
    if (Array.isArray(derivatives)) item.derivatives = [...derivatives]
    else item.derivatives = [derivatives]
    dispatch(getDerivedProductsSuccess(item))
  } catch (e) {
    dispatch(getDerivedProductsError(e))
  }
}

export const getTransformations = (start, end) => async dispatch => {
  dispatch(getTransformationsRequest())
  try {
    const response = await TransformationServices.getTransformation(start, end)
    dispatch(getTransformationsSuccess(response))
  } catch (e) {
    dispatch(getTransformationsError(e))
  }
}

export const getInventoryListByWarehouse =
  (warehouseId, productId, reserved) => dispatch => {
    const process = async () => ({
      response: await TransformationServices.getInventoryListByWarehouse(warehouseId, {
        productId,
        reserved,
      }),
    })
    dispatch(executeAction(actionTypes.GET_INVENTORY_HISTORY, process))
  }

export const getInventoryOrigin = (documentType, documentId) => dispatch => {
  const process = async () => ({
    response: await TransformationServices.getInventoryOrigin(documentType, documentId),
  })
  dispatch(executeAction(actionTypes.GET_INVENTORY_HISTORY, process))
}
