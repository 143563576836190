import { faCheckCircle, faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Icon, TableV2 } from 'src/components'
import CreateSubscription from 'src/content/Subscription/CreateSubscription'
import { loadingSelector } from 'src/selectors/loading.selector'
import { selectSubscribesReport } from 'src/selectors/report.selector'
import { actionTypes, deleteReportSubscription } from 'src/actions/report.actions'
import { formatDateFromMillis } from 'src/utils/formatters'

interface IProps {
  show: boolean
  onHide: () => void
}

/**
 * Modal to show the subscriptions report
 * @component
 * @param {boolean} show Show the modal
 * @param {function} onHide Function to hide the modal
 */
export const SubscriptionReport = ({ show, onHide }: IProps) => {
  const dispatch = useDispatch()
  const subscriptions = useSelector(selectSubscribesReport)

  const loading = useSelector(state =>
    loadingSelector([actionTypes.GET_REPORT_SUBSCRIPTION])(state),
  )
  const loadingDelete = useSelector(state =>
    loadingSelector([actionTypes.DELETE_REPORT_SUBSCRIPTION])(state),
  )
  const [subscription, setSubscription] = useState(null)
  const [utils, setUtils] = useState({
    showCreate: false,
    idAction: null,
  })

  const hide = () => {
    onHide()
    setSubscription(null)
  }

  return (
    <>
      <Modal show={show} onHide={() => hide()} centered>
        <Modal.Header closeButton>
          <Modal.Title>Suscripciones activas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableV2
            /* @ts-expect-error js */
            hideFilter
            items={subscriptions}
            loading={loading}
            renderRow={(item, index) => (
              <div>
                <div className={`b-user-item`} key={`${index}`}>
                  <div
                    className={'justify-content-start align-items-start bu-en'}
                    style={{ textAlign: 'left' }}>
                    <div className={`b-user-name`}>{item.alias}</div>
                    <div
                      className={`b-user-email`}>{`Siguiente fecha: ${formatDateFromMillis(
                      item.nextDate,
                    )}`}</div>
                  </div>
                  <div
                    className={'d-flex row align-items-center mr-2 bu-b'}
                    style={{ textAlign: 'right' }}>
                    <div className="d-flex">
                      <Icon
                        onClick={() => {
                          setSubscription(item)
                          setUtils({ ...utils, showCreate: true })
                        }}
                        icon={faEye}
                        size={'1_5x'}
                        tooltip={'Ver suscripción'}
                      />
                      <Icon
                        style={{ paddingLeft: '10px' }}
                        onClick={() => {
                          setUtils({ ...utils, idAction: item.id })
                          dispatch(deleteReportSubscription(item.id))
                        }}
                        spin={utils.idAction === item.id && loadingDelete}
                        color={'secondary'}
                        icon={faTrash}
                        size={'1_5x'}
                        tooltip={'Eliminar'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            color={'primary'}
            icon={faCheckCircle}
            onClick={() => setUtils({ ...utils, showCreate: true })}>
            Crear suscripción
          </Button>
        </Modal.Footer>
      </Modal>
      {/* @ts-expect-error js  */}
      <CreateSubscription
        show={utils.showCreate}
        modal
        onHide={() => setUtils({ ...utils, showCreate: false })}
        type={1}
        subscriptionType={2}
        onCreate={data => {
          data.ownerType = 3
        }}
        data={subscription}
      />
    </>
  )
}
