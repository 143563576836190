import moment from 'moment'
import { $http } from './http'
import { saveAs } from 'file-saver'
import { formatDateFromMillis } from '../utils/formatters'

class ReportServices {
  getPdfReport = async (params, name, api) => {
    const response = await $http.get(`/cotizap/reports/pdf/${api}`, {
      params,
      responseType: 'blob',
    })

    const file = new Blob([response.data], { type: 'application/pdf' })
    const today = new Date()
    const filename =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate() +
      ' - ' +
      name +
      '.pdf'
    saveAs(file, filename.replace('json', 'pdf'))

    /*const today = new Date();
    const filename = response.headers["content-disposition"]?
      response.headers["content-disposition"].replace('attachment; filename="','').replace('"',''):
      today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate() + " - "+name+".pdf";


    //saveAs(response.data, filename.replace('json','pdf'));
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();*/
  }

  getPdfReport2 = async (params, name, api, data) => {
    const response = await $http.put(`/cotizap/reports/pdf/${api}`, data, {
      params,
      responseType: 'blob',
    })

    const file = new Blob([response.data], { type: 'application/pdf' })
    const today = new Date()
    const filename =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate() +
      ' - ' +
      name +
      '.pdf'
    saveAs(file, filename.replace('json', 'pdf'))
  }

  getExcelReport = async (params, name, api, data) => {
    const response = await $http.put(`/cotizap/reports/excel/${api}`, data, {
      responseType: 'blob',
      params,
    })

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    const filename = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : moment().format('LL') + ` - ${name}- .xlsx`

    saveAs(file, filename.replace('json', 'xlsx'))
  }

  createSubscription = async request => {
    const response = await $http.post(`/cotizap/reports/pdf/subscription`, request)
    return response.data
  }

  getReportSubscription = async request => {
    const response = await $http.get(`/cotizap/reports/pdf/subscription`, request)
    return response.data
  }

  deleteReportSubscription = async id => {
    const response = await $http.delete(`/cotizap/reports/pdf/subscription/${id}`)
    return response.data
  }

  getSegmentationData = async (params, request) => {
    const response = await $http.put(
      '/cotizap/reports/segmentation' +
        (params.email && params.email !== '' ? '/email' : ''),
      request,
      { params },
    )
    return response.data
  }

  getReportSegmentation = async (params, request) => {
    const response = await $http.put(
      '/cotizap/reports/pdf/segmentation' +
        (params.email && params.email !== '' ? '/email' : ''),
      request,
      { params, responseType: 'blob' },
    )
    if (params.email) return 'Reporte enviado'
    const fileName = 'Reporte segmentado.xlsx'
    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    saveAs(file, fileName)
    return response.data
  }

  createScheduleReport = async request => {
    const response = await $http.post('/cotizap/reports/schedule/report', request)
    return response.data
  }

  updateScheduleReport = async (id, request) => {
    const response = await $http.put(`/cotizap/reports/schedule/report/${id}`, request)
    return response.data
  }

  deleteScheduleReport = async id => {
    const response = await $http.delete('/cotizap/reports/schedule/report/' + id)
    return response.data
  }

  getScheduleReports = async () => {
    const response = await $http.get('/cotizap/reports/schedule/report')
    return response.data
  }
  getSingleScheduleReports = async id => {
    const response = await $http.get('/cotizap/reports/schedule/report/' + id)
    return response.data
  }
  sendScheduleReport = async id => {
    const response = await $http.post('/cotizap/reports/schedule/report/send/' + id)
    return response.data
  }
  getWasteAndExpensesReportV2 = async (request, params) => {
    const response = await $http.put('/cotizap/reports/purchase', request, { params })
    return response.data
  }
  getBranchReport = async params => {
    const response = await $http.get('/cotizap/reports/branch', { params })
    return response.data
  }
  getBranchReportExcel = async params => {
    const response = await $http.get('/cotizap/reports/branch/excel', {
      params,
      responseType: 'blob',
    })

    const fileName = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : moment().format('LL') + ' - Reporte de ventas por sucursal.xlsx'

    const file = new Blob([response.data], { type: 'application/vnd.ms-excel' })
    saveAs(file, fileName.replace('json', 'xlsx'))
  }

  getSegmentationReportOpt = async params => {
    const response = await $http.get('/cotizap/reports/segmentation/opt', { params })
    return response.data
  }

  getSegmentationReportOptExcel = async (params, reportName) => {
    const response = await $http.get('/cotizap/reports/segmentation/opt/excel', {
      params,
      responseType: 'blob',
    })

    const name = reportName + ' ' + moment().format('D-MM-YYYY HH:mm:ss') + '.xlsx'
    const file = new Blob([response.data], { type: 'application/vnd.ms-excel' })
    saveAs(file, name)
  }

  /**
   * Service to get the sale report by items
   * @param {Object} params Params of the request
   * @param {number} params.startDate Start date
   * @param {number} params.endDate End date
   * @param {string | number} params.deliverer Deliverer id
   * @param {string | number} params.seller Seller id
   * @param {Object} request Request
   * @param {Array} request.categories Categories filter
   * @param {Array} request.pos Points of sale filter
   * @param {Array} request.warehouses Warehouses filter
   * @returns
   */
  getSaleReportByItems = async (params, request) => {
    const response = await $http.post(`/cotizap/reports/sale/items`, request, {
      params,
    })
    return response.data
  }

  /**
   * Service to get the sale report by items and convert in excel file
   * @param {Object} params Params of the request
   * @param {number} params.startDate Start date
   * @param {number} params.endDate End date
   * @param {string | number} params.deliverer Deliverer id
   * @param {string | number} params.seller Seller id
   * @param {boolean} params.segmented Report segmented by deliver
   * @param {Object} request Request
   * @param {Array} request.categories Categories filter
   * @param {Array} request.pos Points of sale filter
   * @param {Array} request.warehouses Warehouses filter
   */
  getSaleReportByItemsExcel = async (params, request) => {
    const response = await $http.put(`/cotizap/reports/sale/items/excel`, request, {
      responseType: 'blob',
      params,
    })

    const fileName = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : moment().format('LL') + ' - Ventar totales - .xlsx'

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    saveAs(file, fileName.replace('json', 'xlsx'))
  }

  /**
   * Action to get the sale report by items
   * @param {Object} params Params of the request
   * @param {number} params.startDate Start date
   * @param {number} params.endDate End date
   * @param {number} params.created Created by id
   * @param {number} params.deliverer Deliverer id
   * @param {number} params.warehouse Warehouse id
   * @param {number} params.typeDate Type date, 1: Day, 2: Week, 3: Month
   * @param {Object} request Request
   * @param {Array} request.categories Categories filter
   * @param {Array} request.pos Points of sale filter
   * @returns none
   */
  getTotalSaleReport = async (params, request) => {
    const response = await $http.put(`/cotizap/reports/sale/total`, request, {
      params,
    })
    return response.data
  }

  getVehicleReport = async params => {
    const response = await $http.get('/cotizap/reports/vehicle', { params })
    return response.data
  }
  getVehicleReportExcel = async params => {
    const response = await $http.get(`/cotizap/reports/vehicle/excel`, {
      params,
      responseType: 'blob',
    })

    const fileName = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : moment().format('LL') + ' - Control de vehículos - .xlsx'

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    saveAs(file, fileName.replace('json', 'xlsx'))
  }
}

export default new ReportServices()
