import { faSave, faSignOutAlt, faTimes } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { showAlert } from 'src/actions/alert.actions'
import { getUsersChildrenByModule } from 'src/actions/modules.actions'
import {
  getSingleScheduleReport,
  actionTypes,
  createScheduleReport,
  updateScheduleReport,
} from 'src/actions/report.actions'
import { Button, Folder, Icon, ListCategories, NumberField, Select } from 'src/components'
import { categoryType, flatCategories } from 'src/components/folders/Folder'
import {
  scheduledReportTypes,
  scheduledReportTypesEnum,
} from 'src/enums/scheduledReportsEnum'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'
import {
  errorsSelector,
  handlerError,
  handlerSuccess,
  hasErrors,
  hasErrorsSelector,
} from 'src/selectors/error.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { isAllowed, selectUsersToSelect } from 'src/selectors/modules.selector'
import { selectSingleScheduledReport } from 'src/selectors/report.selector'
import { selectCurrentModule } from 'src/selectors/user.selector'

const periodicityTypes = [
  { value: 1, label: 'Diariamente' },
  { value: 2, label: 'Semanalmente' },
  { value: 3, label: 'Mensualmente' },
]

const defaultConfiguration = {
  periodicity: null,
  type: null,
  offsetDays: 1,
}

export const CreateConfiguration = ({ show, id, onHide }) => {
  const dispatch = useDispatch()

  const singleConfiguration = useSelector(selectSingleScheduledReport)
  const module = useSelector(selectCurrentModule)
  const users = useSelector(selectUsersToSelect)
  const categories = useSelector(selectAllCategorizations)
  const allCategories = flatCategories(categories)

  const loadingCreate = useSelector(state =>
    loadingSelector([actionTypes.CREATE_SCHEDULED_REPORT])(state),
  )
  const hasErrorCreate = useSelector(state =>
    hasErrors([actionTypes.CREATE_SCHEDULED_REPORT])(state),
  )

  const loadingUpdate = useSelector(state =>
    loadingSelector([actionTypes.UPDATE_SCHEDULED_REPORT])(state),
  )
  const hasErrorUpdate = useSelector(state =>
    hasErrorsSelector([actionTypes.UPDATE_SCHEDULED_REPORT])(state),
  )
  const errorUpdate = useSelector(state =>
    errorsSelector([actionTypes.UPDATE_SCHEDULED_REPORT])(state),
  )

  const loading = useSelector(state =>
    loadingSelector([actionTypes.GET_SINGLE_SCHEDULED_REPORT])(state),
  )

  const [configuration, setConfiguration] = useState(defaultConfiguration)
  const [assignedUsers, setAssignedUsers] = useState([])
  const [actions, setActions] = useState({
    get: false,
    add: false,
    update: false,
  })
  const [errors, setErrors] = useState({})
  const [showCategorization, setShowCategorization] = useState(false)

  const editConfiguration = useSelector(state => isAllowed(state, [6653]) && !!id)
  const createConfiguration = useSelector(state => isAllowed(state, [6652]) && !id)

  useEffect(() => {
    if (!show) return
    setAssignedUsers([])
    dispatch(getUsersChildrenByModule(module, true))

    if (id) dispatch(getSingleScheduleReport(id))
  }, [show])

  useEffect(() => {
    if (!id || Object.keys(singleConfiguration).length === 0) {
      setConfiguration(defaultConfiguration)
    }
  }, [show, singleConfiguration])

  useEffect(() => {
    if (loadingCreate) setActions({ ...actions, add: true })
    else if (actions.add) {
      setActions({ ...actions, add: false })
      if (hasErrorCreate) {
        dispatch(
          showAlert({
            ...handlerError(
              hasErrorCreate.message || 'Error al crear reporte programado',
            ),
          }),
        )
      } else {
        dispatch(
          showAlert({
            ...handlerSuccess('Configuración creada con éxito'),
            onConfirm: () => {
              onHide(true)
            },
          }),
        )
      }
    }
  }, [loadingCreate])

  useEffect(() => {
    if (loadingUpdate) setActions({ ...actions, update: true })
    else if (actions.update) {
      setActions({ ...actions, add: false })
      if (hasErrorUpdate) {
        dispatch(
          showAlert({
            ...handlerError(
              errorUpdate.message || 'Error al actualizar reporte programado',
            ),
          }),
        )
      } else {
        dispatch(
          showAlert({
            ...handlerSuccess('Configuración actualizada con éxito'),
            onConfirm: () => {
              onHide(true)
            },
          }),
        )
      }
    }
  }, [loadingUpdate])

  useEffect(() => {
    if (loading) setActions({ ...actions, get: true })
    else if (actions.get) {
      setActions({ ...actions, get: false })
      if (!singleConfiguration) {
        setConfiguration(defaultConfiguration)
        return
      }

      const item = {}
      item.usersIds = []
      item.usersInfo = []

      const { usersIds, usersInfo, type, periodicity, categories } = singleConfiguration
      if (usersIds.length > 0) {
        item.usersIds = usersIds
        item.usersInfo = usersInfo
      } else {
        item.usersIds = []
        item.usersInfo = []
      }
      if (item.usersInfo) {
        setAssignedUsers(
          Object.assign(
            [],
            item.usersInfo.map(i => ({
              ...i,
              value: i.id,
              label: i.name,
            })),
          ),
        )
      }
      if (type) item.type = scheduledReportTypes.find(r => r.value === type)
      if (periodicity)
        item.periodicity = periodicityTypes.find(p => p.value === periodicity)
      item.categories = categories
        ? categories
            .split(',')
            .map(categoryId =>
              allCategories.find(category => category.id === Number(categoryId)),
            )
        : []
      setConfiguration({ ...singleConfiguration, ...item })
    }
  }, [loading])

  const handleSave = () => {
    const { type, periodicity, offsetDays } = configuration
    const errors = {}
    if (!type) errors.type = 'Es requerido'
    if (!periodicity) errors.periodicity = 'Es requerido'
    if (!assignedUsers || assignedUsers.length === 0) errors.assigned = 'Es requerido'
    if (
      type?.value === scheduledReportTypesEnum.SOON_EXPIRING_PRODUCTS.value &&
      !offsetDays
    )
      errors.offsetDays = 'Es requerido'
    if (
      type?.value === scheduledReportTypesEnum.SOON_EXPIRING_PRODUCTS.value &&
      offsetDays < 1
    )
      errors.offsetDays = 'No puede ser menor a 1'

    setErrors(errors)
    if (Object.keys(errors).length > 0) return

    const request = {
      ...configuration,
      type: type.value,
      periodicity: periodicity.value,
      assignedUsers: assignedUsers.map(user => user.id),
      categories:
        type?.value === scheduledReportTypesEnum.SOON_EXPIRING_PRODUCTS.value
          ? configuration.categories?.map(c => c.id).join(',')
          : null,
      offsetDays:
        type?.value === scheduledReportTypesEnum.SOON_EXPIRING_PRODUCTS.value
          ? offsetDays
          : 0,
    }

    if (id) dispatch(updateScheduleReport(id, request))
    else dispatch(createScheduleReport(request))
  }

  const onAssignCategorization = category => {
    const categories = configuration.categories || []
    const index = categories.findIndex(c => c.id === category.id)
    if (index === -1) categories.push(category)
    else categories.splice(index, 1)
    setConfiguration({ ...configuration, categories })
  }

  return (
    <>
      <Modal show={show} size={'xl'} centered onHide={() => onHide()}>
        <Modal.Header closeButton>
          <Modal.Title>
            {id && singleConfiguration
              ? singleConfiguration.correlative
              : 'Nueva configuración'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={6} lg={6} md={6} sm={6} xs={12}>
              <Select
                label={'Tipo de Reporte'}
                options={scheduledReportTypes}
                value={configuration.type}
                disabled={!editConfiguration && !createConfiguration}
                onChange={type => {
                  if (type.value === 3 || type.value === 4)
                    setConfiguration({
                      ...configuration,
                      type,
                      periodicity: periodicityTypes[0],
                    })
                  else setConfiguration({ ...configuration, type })
                  setErrors({ ...errors, type: '' })
                }}
                error={errors.type}
                required
              />
            </Col>

            <Col xl={6} lg={6} md={6} sm={6} xs={12}>
              <Select
                label={'Periodicidad (Al finalizar)'}
                options={periodicityTypes}
                value={configuration.periodicity}
                disabled={
                  (!editConfiguration && !createConfiguration) ||
                  configuration.type?.value === 3 ||
                  configuration.type?.value === 4
                }
                onChange={periodicity => {
                  setConfiguration({ ...configuration, periodicity })
                  setErrors({ ...errors, periodicity: '' })
                }}
                error={errors.periodicity}
                required
                info={'Se programa el envío del reporte el último dia del periodo'}
              />
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={12}>
              <Select
                label={'Asignados'}
                value={{ value: null, label: 'Selecciona un usuario' }}
                options={users.filter(
                  s => assignedUsers.filter(f => f.value === s.value).length === 0,
                )}
                required
                error={errors.assigned}
                disabled={!editConfiguration && !createConfiguration}
                onChange={selectedUser => {
                  if (assignedUsers.length === 0) selectedUser.principal = true
                  assignedUsers.push(selectedUser)
                  setAssignedUsers([...assignedUsers])
                }}
              />
              <Row className={'pl-1 mt-2'}>
                {assignedUsers?.map((p, i) => (
                  <div className={'user-tag  ml-2'} key={i}>
                    <label className={'label-user-tag'}>{p.label}</label>
                    {(editConfiguration || createConfiguration) && (
                      <Icon
                        className={'delete-user-tag'}
                        icon={faTimes}
                        tooltip={'Quitar'}
                        color={'white'}
                        onClick={() =>
                          setAssignedUsers([
                            ...assignedUsers.filter(d => p.id !== d.value),
                          ])
                        }
                      />
                    )}
                  </div>
                ))}
              </Row>
            </Col>
            {configuration.type?.value ===
              scheduledReportTypesEnum.SOON_EXPIRING_PRODUCTS.value && (
              <>
                <Col xl={6} lg={6} md={6}>
                  <NumberField
                    label={'Días de antelación'}
                    info="Por ejemplo: Considerar los siguientes 30 días para el reporte."
                    placeholder="30"
                    error={errors.offsetDays}
                    containerStyle={{ marginTop: 20 }}
                    value={configuration.offsetDays}
                    required
                    min={1}
                    disabled={!editConfiguration && !createConfiguration}
                    onValueChange={offsetDays =>
                      setConfiguration({ ...configuration, offsetDays })
                    }
                  />
                </Col>
                {(editConfiguration || createConfiguration) && (
                  <Col xs={12}>
                    <ListCategories
                      label={'Categorías'}
                      info={
                        'Si no se seleccionan categorías se tomarán en cuenta todos los ítems.'
                      }
                      items={configuration.categories}
                      onRemove={onAssignCategorization}
                      addItem={() => setShowCategorization(true)}
                      seeAddItem
                    />
                  </Col>
                )}
              </>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {((editConfiguration && id) || (createConfiguration && !id)) && (
            <Button
              loading={loadingCreate || loadingUpdate}
              onClick={() => handleSave()}
              icon={id ? faSignOutAlt : faSave}>
              {id ? 'Actualizar' : 'Crear'}
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Folder
        show={showCategorization}
        type={categoryType.PRODUCTS}
        list={configuration.categories?.map(c => c.id) || []}
        noMessage
        onHide={() => setShowCategorization(false)}
        data1={categories?.children ? categories.children[0] : {}}
        data2={categories?.children ? categories.children[1] : {}}
        onAssign={onAssignCategorization}
      />
    </>
  )
}
