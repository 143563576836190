import { actionTypes } from '../actions/videos.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  allVideos: [],
  productVideos: [],
  producerVideos: [],
  manuals: [],
  appVideos: [],
  total: 0,
  search: '',
}

const VideosReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_MANUALS):
      return { ...state, manuals: extract(action.manuals, []) }
    case successState(actionTypes.GET_ALL_VIDEOS):
      return {
        ...state,
        allVideos: extract(action.allVideos, []),
      }
    case successState(actionTypes.GET_VIDEOS): {
      const response = extract(action.videos, {})

      const { start, search } = action
      const { videos, total } = response

      let allVideos

      if (search === state.search) {
        const currentVideos = state.allVideos
          .slice(0, start)
          .concat(videos.map((video, i) => ({ ...video, index: start + i })))
          .concat(state.allVideos.slice(start, state.allVideos.length))

        allVideos = new Array(total)
          .fill({ categories: [], urls: [], video: {} })
          .map((video, i) => currentVideos.find(video => video.index === i) || video)
      } else {
        const currentVideos = videos.map((video, i) => ({
          ...video,
          index: start + i,
        }))

        allVideos = new Array(total)
          .fill({ categories: [], urls: [], video: {} })
          .map((video, i) => currentVideos.find(video => video.index === i) || video)
      }

      return {
        ...state,
        allVideos,
        total,
        search,
      }
    }
    case successState(actionTypes.GET_APP_VIDEOS):
      return {
        ...state,
        appVideos: extract(action.appVideos, []),
      }

    default:
      return state ? state : initialState
  }
}

export default VideosReducer
