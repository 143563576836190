import React from 'react'

import { Col, Row } from 'react-bootstrap'
import { Checkbox, Paragraph } from 'src/components'
import { toMoney } from 'src/utils/utilities'

interface ICreditNoteItemsListProps {
  items: ICreditNoteItem[]
  partial: boolean
  onChange: (item: ICreditNoteItem) => void
  onSelect: (item: ICreditNoteItem) => void
}

const CreditNoteItemsList = ({
  items,
  partial,
  onSelect,
  onChange,
}: ICreditNoteItemsListProps) => {
  const generalItem = (item: ICreditNoteItem) => (
    <div className={'column'} style={{ width: partial ? '95%' : '100%' }}>
      <Paragraph>{item.name}</Paragraph>
      <Paragraph>Cantidad {item.quantity}</Paragraph>
      <Paragraph>Total. {toMoney(item.total)}</Paragraph>
    </div>
  )

  const partialItem = (item: ICreditNoteItem) => {
    const lineItems = item.itemData.line.filter(subItem => subItem.quantity > 0)

    return (
      <div className={'column'} style={{ width: partial ? '95%' : '100%' }}>
        {lineItems.map(subItem => (
          <div key={subItem.productId} className={'space-between mb-2'}>
            <div className={'column'}>
              <Paragraph dim>
                {subItem?.productData?.code} {subItem?.productData?.name}
              </Paragraph>
            </div>
            <div className={'column'}>
              <Paragraph dim className={'d-flex flex-row-reverse'}>
                {subItem.quantity} u
              </Paragraph>
            </div>
          </div>
        ))}
        <div className={'space-between'}>
          <div className={'column'}>
            <Paragraph dim>Total</Paragraph>
          </div>
          <div className={'column'}>
            <Paragraph dim>{toMoney(item.amount)}</Paragraph>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Row>
      {items.map(item => {
        const renderPartialItem = partial && item.selected

        return (
          <Col
            xl={12}
            key={item.detailId}
            className={'b-item column'}
            onClick={() => {
              if (partial) {
                if (!item.selected) onSelect(item)
                else onChange({ ...item, selected: false })
              }
            }}>
            <div className={'d-flex'}>
              {partial && (
                <div style={{ width: '5%' }}>
                  <Checkbox checked={item.selected} onChange={e => e.preventDefault()} />
                </div>
              )}

              {!renderPartialItem ? generalItem(item) : partialItem(item)}
            </div>
          </Col>
        )
      })}
    </Row>
  )
}
export default CreditNoteItemsList
