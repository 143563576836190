import { $http } from './http'

class PromotionService {
  getAllPromotions = async (active?: boolean) => {
    const response = await $http.get(`/promotions`, { params: { active } })
    return response.data
  }

  createPromotion = async (request: IPromotionRequest) => {
    const response = await $http.post(`/promotions`, request)
    return response.data
  }

  updatePromotion = async (id: number, request: IPromotionRequest) => {
    const response = await $http.put(`/promotions/${id}`, request)
    return response.data
  }

  deletePromotion = async (id: number) => {
    const response = await $http.delete(`/promotions/${id}`)
    return response.data
  }

  getPromotionById = async (id: number) => {
    const response = await $http.get(`/promotions/${id}`)
    return response.data
  }
}
export default new PromotionService()
