import { useEffect, useState } from 'react'

function useIntersectionObserver(ref, options, once = true, enabled = true) {
  const [intersected, setIntersected] = useState(!enabled)

  const updateEntry = val => {
    const [entry] = val
    setIntersected(entry?.isIntersecting)
  }

  useEffect(() => {
    const isSupported = !!window.IntersectionObserver
    const element = ref?.current

    if (!isSupported || !element || (once && intersected)) return

    const observer = new IntersectionObserver(updateEntry, options)
    observer.observe(element)

    return () => observer.disconnect()
  }, [ref, options])

  return intersected
}

export default useIntersectionObserver
