import ParcelService from '../services/parcel.service'
import { executeAction } from './global.actions'

export const types = {
  GET_ALL_PARCEL_BY_COMPANY: 'GET_ALL_PARCEL_BY_COMPANY',
  GET_SINGLE_PARCEL: 'GET_SINGLE_PARCEL',
  SET_INFO: 'SET_INFO',
  ON_CREATE_OR_UPDATE: 'ON_CREATE_OR_UPDATE',
  GET_TYPES: 'GET_TYPES',
}

const setInfoParcel = parcel => ({ parcel, type: types.SET_INFO })

export const setInfo = parcel => dispatch => {
  dispatch(setInfoParcel(parcel))
}

export const getAllByCompany = params => async dispatch => {
  const process = async () => ({
    parcels: await ParcelService.getAllByCompany(params),
  })
  dispatch(executeAction(types.GET_ALL_PARCEL_BY_COMPANY, process))
}

export const findById = id => async dispatch => {
  const process = async () => ({ parcel: await ParcelService.findById(id) })
  dispatch(executeAction(types.GET_SINGLE_PARCEL, process))
}

export const create = request => async dispatch => {
  const process = async () => ({ parcel: await ParcelService.create(request) })
  dispatch(executeAction(types.ON_CREATE_OR_UPDATE, process))
}

export const update = (request, parcelId) => async dispatch => {
  const process = async () => ({
    parcel: await ParcelService.update(parcelId, request),
  })
  dispatch(executeAction(types.ON_CREATE_OR_UPDATE, process))
}

export const getTypes = () => async dispatch => {
  const process = async () => ({ types: await ParcelService.getTypes() })
  dispatch(executeAction(types.GET_TYPES, process))
}
