import './ReportsDashboard.scss'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { Link } from 'react-router-relative-link'
import { Modal } from 'react-bootstrap'
import { Title, Icon } from 'src/components'
import CSVLoaderV2 from 'src/components/CSVLoader/CSVLoaderV2'
import { CATEGORY_UPLOAD } from 'src/components/CSVLoader/CSVLoaderV2'

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import IconProducts from 'src/assets/v2/icon-products.svg'
import IconSales from 'src/assets/v2/icon-sales.svg'
import IconUsers from 'src/assets/v2/icon-users.svg'
import IconClients from 'src/assets/v2/icon-clients.svg'
import IconExpenses from 'src/assets/v2/icon-expenses.svg'
import IconWaste from 'src/assets/v2/icon-inventory.svg'
import IconInventory from 'src/assets/v2/icon-inventory.svg'
import IconActPas from 'src/assets/v2/icon-active-pasive.svg'
import IconReport from 'src/assets/v2/icon-report.svg'
import IconInvoice from 'src/assets/v2/icon-help.svg'

import { selectCurrentModule } from 'src/selectors/user.selector'
import { isAllowed } from 'src/selectors/modules.selector'
import { selectCompanyIsLimit } from 'src/selectors/company.selector'

import { reportPermissions } from 'src/enums/permissions'
import ParkingIcon from 'src/assets/images/svg/parking.svg'

const ReportsDashboard = () => {
  const isLimit = useSelector(selectCompanyIsLimit)
  const module = useSelector(selectCurrentModule)

  // PERMISSIONS
  const itemsSoldSegmented = useSelector(state =>
    isAllowed(state, [reportPermissions.itemsSoldSegmented]),
  )

  const inventoryReport = useSelector(state =>
    isAllowed(state, [reportPermissions.inventoryKardex]),
  )
  const inventoryHistory = useSelector(state =>
    isAllowed(state, [reportPermissions.inventoryHistorical]),
  )
  const inOutUser = useSelector(state => isAllowed(state, [reportPermissions.reportUser]))
  const totalSell = useSelector(state => isAllowed(state, [reportPermissions.totalSell]))
  const patrimonial = useSelector(state =>
    isAllowed(state, [reportPermissions.patrimonial]),
  )
  const waste = useSelector(state => isAllowed(state, [reportPermissions.waste]))
  const expense = useSelector(state => isAllowed(state, [reportPermissions.expense]))
  const clients = useSelector(state => isAllowed(state, [reportPermissions.client]))
  const purchase = useSelector(state => isAllowed(state, [reportPermissions.purchase]))
  const clientLog = useSelector(state => isAllowed(state, [reportPermissions.clientLog]))
  const segmentation = useSelector(state =>
    isAllowed(state, [reportPermissions.segmentation]),
  )
  const branchSales = useSelector(state => isAllowed(state, [reportPermissions.branch]))

  const [conciliationReport, setConciliationReport] = useState({
    show: false,
    nullify: false,
  })

  const getReports = () => {
    return [
      {
        name: 'Reporte de ventas por ítems',
        link: 'productos',
        icon: IconProducts,
        info: 'Ventas Totales por periodo',
        show: itemsSoldSegmented,
      },
      {
        name: 'Precios por producto en un periodo',
        link: 'productos/historial',
        icon: IconProducts,
        info: 'Precio de productos en un periodo',
        show: false, //pricePeriod,
      },
      {
        name: 'Reporte de inventarios',
        link: 'inventario/kardex',
        icon: IconInventory,
        info: 'Kardex de bodegas',
        show: inventoryReport,
      },
      {
        // TODO: revisar que hace
        name: 'Productos',
        link: 'products',
        icon: IconProducts,
        info: 'Ventas Totales por periodo',
        show: module === 11000,
      },
      {
        // TODO: revisar que hace
        name: 'Entregas',
        link: 'entregas',
        icon: IconSales,
        info: 'Ventas totales entregadas por medio del software',
        show: module === 11000,
      },
      {
        name: 'Historial de inventario',
        link: 'inventario',
        icon: IconInventory,
        info: 'Historial de inventario',
        show: !isLimit && inventoryHistory,
      },
      {
        name: 'Ventas por usuarios',
        link: 'usuarios',
        icon: IconUsers,
        info: 'Ingresos y Egresos de los Usuarios',
        show: !isLimit && inOutUser,
      },
      {
        name: 'Ventas totales',
        link: 'ventas/totales',
        icon: IconSales,
        info: 'Ventas totales',
        show: totalSell,
      },
      {
        name: 'Patrimonial',
        link: 'patrimonial',
        icon: IconActPas,
        info: 'Patrimonial',
        show: !isLimit && patrimonial,
      },
      {
        name: 'Reducciones de inventario',
        link: 'merma',
        icon: IconWaste,
        info: 'Reducciones de inventario',
        show: !isLimit && waste,
      },
      {
        name: 'Gastos',
        link: 'gastos',
        icon: IconExpenses,
        info: 'Gastos',
        show: !isLimit && expense,
      },
      {
        name: 'Clientes',
        link: 'balance',
        icon: IconClients,
        info: 'Cuentas por Cobrar',
        show: !isLimit && clients,
      },
      {
        name: 'Compras',
        link: 'compras',
        icon: IconExpenses,
        info: 'Compras',
        show: !isLimit && purchase,
      },
      {
        name: 'Bitácora de Clientes',
        link: 'logs',
        icon: IconClients,
        info: 'Bitácora de todos los clientes de la empresa',
        show: clientLog,
      },
      {
        name: 'Reportes por segmentación y presentación',
        link: 'segmentación',
        icon: IconInventory,
        info: 'Visualización y descarga de reportes con segmentación de categorías con distintas presentaciones',
        show: segmentation,
      },
      {
        name: 'Certificar de DTE',
        link: '',
        icon: IconInvoice,
        info: 'abc',
        show: false,
        onClick: () => setConciliationReport({ show: true, nullify: false }),
      },
      {
        name: 'Anular de DTE',
        link: '',
        icon: IconInvoice,
        info: 'abc',
        show: false,
        onClick: () => setConciliationReport({ show: true, nullify: true }),
      },
      {
        name: 'Ventas por sucursal',
        link: 'sucursales',
        icon: IconSales,
        info: 'Visualización y descarga de reportes de ventas segmentado por puntos de venta, bodegas o usuarios',
        show: branchSales,
      },
      {
        name: 'Control de vehículos',
        link: 'vehiculos',
        icon: ParkingIcon,
        info: 'Histórico de entrada y salida de vehículos',
        show: true,
      },
    ]
  }

  return (
    <div>
      <Title title={'Reportes'} />
      <div className={'columns'}>
        {getReports()
          .filter(f => f.show)
          .map((module, i) => (
            <Link
              key={i}
              to={module.link}
              onClick={() => {
                if (module.onClick) module.onClick()
              }}>
              <div className={'report-item'}>
                <img src={module.icon} alt={''} className={'report-icon'} />
                <div className={'report-name'}>{module.name}</div>
                <img src={IconReport} alt={''} className={'report-icon-back'} />
                <div className={'report-info'}>
                  <Icon
                    icon={faInfoCircle}
                    tooltip={module.info || module.name}
                    size={'lg'}
                  />
                </div>
              </div>
            </Link>
          ))}
      </div>

      <Modal
        show={conciliationReport.show}
        size={'md'}
        centered
        onHide={() => setConciliationReport({ ...conciliationReport, show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Conciliación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CSVLoaderV2
            category={
              conciliationReport.nullify
                ? CATEGORY_UPLOAD.INVOICE_CONCILIATION_NULLIFY
                : CATEGORY_UPLOAD.INVOICE_CONCILIATION_NULLIFY
            }
            title={'Cargar CSV de Facturas'}
            onSuccess={() =>
              setConciliationReport({ ...conciliationReport, show: false })
            }
          />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ReportsDashboard
