import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectGetAllCurrencies, selectGetBanks } from 'src/selectors/banks.selector'
import { Modal, Col, Row } from 'react-bootstrap'
import { selectorGetUsersSubmodule } from 'src/selectors/modules.selector'
import { createAccount } from 'src/actions/banks.actions'
import { actionTypes as typeT } from 'src/actions/banks.actions'
import { Button, FormText, Select, Switch } from 'src/components'

import { loadingSelector } from 'src/selectors/loading.selector'
import { showAlert } from 'src/actions/alert.actions'

import { defaultBankAccountForForm, types } from 'src/enums/bankEnums'
import { handlerError, handlerSuccess } from 'src/selectors/error.selector'

function validateAccountData(accountData: IBankAccount): boolean {
  const fieldsToValidate = [
    accountData?.name,
    accountData?.toName,

    //accountData?.report
  ]
  const fieldNumeric = [
    // accountData?.bank?.value,
    accountData?.type?.id,
    accountData?.accountNumber,
    accountData?.balance,
    //   userValue,
    accountData?.currency?.value,
  ]

  return (
    fieldsToValidate.every(
      field =>
        field !== null && field !== undefined && field !== '' && field.trim() !== '',
    ) && fieldNumeric.every(field => field !== null && field !== undefined && field !== 0)
  )
}

interface actionState {
  createdAccount?: boolean
}

const AddAccountForm: React.FC<AddAccountModalModalProps> = ({
  onHide,
}: AddAccountModalModalProps) => {
  const dispatch = useDispatch()

  const createdaAccountFlag = useSelector(state =>
    loadingSelector([typeT.CREATE_ACCOUNT])(state),
  )

  const banks = useSelector(selectGetBanks)
  const currencies = useSelector(selectGetAllCurrencies)
  const usersSubmodule = useSelector(state => selectorGetUsersSubmodule(state))

  const [accountData, setAccountData] = useState<IBankAccount>(defaultBankAccountForForm)
  const [actionsDone, setActionsDone] = useState<actionState>({ createdAccount: false })

  useEffect(() => {
    if (createdaAccountFlag) {
      setActionsDone({ ...actionsDone, createdAccount: true })
    }
  }, [createdaAccountFlag])

  useEffect(() => {
    if (actionsDone.createdAccount) {
      onHide() // Close the modal only after the account is successfully created
      setActionsDone({ ...actionsDone, createdAccount: false })
      dispatch(showAlert({ ...handlerSuccess('Cuenta Creada') }))
    }
  }, [actionsDone.createdAccount])

  const handleAccountData = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { id, value } = e.currentTarget

    setAccountData(prevData => ({
      ...prevData,
      [id]: value,
    }))
  }

  const addNewAccount = (e: React.FormEvent) => {
    e.preventDefault()

    if (!validateAccountData(accountData)) {
      dispatch(showAlert(handlerError('Revise los datos obligatorios del formulario.')))
    } else {
      dispatch(
        createAccount(
          accountData?.accountNumber,
          accountData?.name,
          accountData?.toName,
          accountData?.bank.value,
          accountData?.type.id,
          accountData?.balance,
          8000,
          accountData?.asigned,
          accountData?.currency.value,
          accountData.report,
        ),
      )
    }
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Nueva Cuenta </Modal.Title>
      </Modal.Header>

      <Modal.Body className={'pl-3 pr-3'} data-testid={'add-acount-form'}>
        <div data-testid={'add-acount-core'}>
          <Row>
            <Col xl={6} md={6} xs={12}>
              <FormText
                label={'Numero de Cuenta'}
                required
                data-testid="accountNumber"
                placeholder={'Numero'}
                type={'number'}
                name={'number'}
                onChange={handleAccountData}
                id="accountNumber"
                error={''}
              />
            </Col>
            <Col xl={6} md={6} xs={12}>
              <FormText
                label={'Alias'}
                required
                placeholder={'Alias'}
                data-testid={'name'}
                type={'text'}
                error={''}
                onChange={handleAccountData}
                id="name"
              />
            </Col>
          </Row>

          <Row>
            <Col xl={6} md={6} xs={12}>
              <FormText
                label={'A nombre de'}
                placeholder={'Nombre del propietario'}
                required
                type={'text'}
                error={''}
                data-testid={'toName'}
                onChange={handleAccountData}
                id="toName"
              />
            </Col>
            <Col xl={6} md={6} xs={12}>
              <Select
                label={'Banco'}
                options={banks}
                required
                dataCy={'accountBank'}
                onChange={data => {
                  setAccountData({ ...accountData, bank: data })
                }}
                id={'accountBank'}
                testId="customSelect1"
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Select
                label={'Tipo'}
                options={types}
                dataCy={'type'}
                required
                onChange={data => {
                  setAccountData({ ...accountData, type: data })
                }}
                id={'type'}
              />
            </Col>

            <Col>
              <Select
                required
                label={'Moneda'}
                options={currencies}
                onChange={data => {
                  setAccountData({ ...accountData, currency: data })
                }}
                id={'currencies'}
                dataCy={'currencies'}
              />
            </Col>
            <Col>
              <Select
                label={'Encargado'}
                options={usersSubmodule}
                onChange={data => {
                  setAccountData({ ...accountData, asigned: data.value })
                }}
                id={'subModule'}
                dataCy={'subModule'}
              />
            </Col>

            <Col>
              <FormText
                label={'Balance'}
                type={'number'}
                data-testid={'balance'}
                onChange={handleAccountData}
                placeholder={'0.0'}
                id={'balance'}
                name={'balance'}
              />
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={'mr-3'}>
          <Switch
            name={'report'}
            checked={accountData.report}
            dataCy={'shouldAccount'}
            id={'reportAccount'}
            onChange={() =>
              setAccountData({ ...accountData, report: !accountData.report })
            }
            label={'Afectar reporte patrimonial'}
            info={
              'Al activar esta opción, las actualizaciones sobre los saldos de esta cuenta se reflejarán en el reporte patrimonial.'
            }
            placement={'top'}
          />
        </div>
        <div>
          <Button
            onClick={addNewAccount}
            dataCy={'create-account'}
            data-testid={'create-account'}
            id={'create-account'}
            style={{ maxWidth: '100%' }}
            loading={createdaAccountFlag}>
            Crear
          </Button>
        </div>
      </Modal.Footer>
    </>
  )
}

const AddAccountModal: React.FC<AddAccountModalModalProps> = ({
  show,
  onHide,
}: AddAccountModalModalProps) => {
  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <AddAccountForm show={show} onHide={onHide} data-testid="account-add-form" />
    </Modal>
  )
}

export { AddAccountModal, AddAccountForm }
