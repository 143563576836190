import { useSelector } from 'react-redux'
import {
  selectGetTableOrder,
  selectPOSCorrelative,
} from 'src/selectors/restaurant.selector'
import { haveAnyValue } from 'src/utils/utilitiesV2'

const usePOSCorrelative = (): string => {
  const correlative = useSelector(selectPOSCorrelative)
  const tableOrder = useSelector(selectGetTableOrder)

  return haveAnyValue(tableOrder?.correlative) ? tableOrder.correlative : correlative
}
export default usePOSCorrelative
