import React, { Component } from 'react'
import { connect } from 'react-redux'

import './GranularPermission.scss'

import Card from 'src/components/cards/Card'
import Tabla from 'react-bootstrap/Table'

import { Row, Col } from 'react-bootstrap'
import { Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'

import {
  actionTypes as action,
  deleteRoleByUser,
  getAllUserChildRoles,
  getRolesByUserChild,
  getUsersChildrenByModule,
  putRoleByUser,
  updateRolesByUser,
  updateRolesByRole,
} from 'src/actions/modules.actions'
import {
  selectRolesByUser,
  selectUsers,
  selectUsersRoles,
} from 'src/selectors/modules.selector'

import { selectCurrentUser } from 'src/selectors/user.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'
import FormTextField from 'src/components/inputs/FormTextField/FormTextField'
import CustomSelect from 'src/components/inputs/Select/CustomSelect'
import { faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
import ButtonIcon from 'src/components/buttons/IconButton'

class index extends Component {
  state = {
    load: false,
    show: false,
    search: '',
    rolesArray: [],
    fixedHeaders: {},
    regularHeaders: [],
    role: {
      label: '-- Seleccione un permiso para filtrar --',
      value: '',
      role: '',
      child: '',
    },
    permissions: [],
    filter: [],
    loading: { value: false, id: null },
    loadingRole: { value: false, id: null },
    users_roles: [],
    user: {
      label: '-- Seleccione un usuario para filtrar --',
      value: '',
      role: '',
      child: '',
    },
    filterUser: [],
    groups: [],
  }

  componentDidMount() {
    const { module } = this.props.match.params
    const { currentUsers } = this.props
    this.props.getRolesByUserChild(module, currentUsers.id)
    this.props.getAllUsersRoles(module)
    this.props.getUsersChildrenByModule(module, false)
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { rolesIsLoading, usersRolesIsLoading } = this.props
    let { rolesArray, permissions, groups } = this.state
    const { roles } = next

    if (rolesIsLoading && !next.rolesIsLoading) {
      rolesArray.push({ role: 'ROLES', child: 'USUARIOS' })
      roles.map((r, index) => {
        if (index != 0) {
          groups.push({
            label: r.name,
            value: r.id,
            role: r.name,
            child: null,
          })
          permissions.push({
            label: r.name,
            value: r.id,
            role: r.name,
            child: null,
            options: [{ value: 0, label: 'Seleccionar todos', id: r.id }],
          })
          r.roles.map(g => {
            rolesArray.push({ role: r.name, child: g.name, id: g.id })
            permissions[index - 1].options.push({
              label: g.name,
              value: g.id,
              role: r.name,
              child: g.name,
            })
          })
        }
      })
      this.setState({
        fixedHeaders: rolesArray.slice(0, 1),
        regularHeaders: rolesArray.slice(1, rolesArray.length),
      })
      this.setState({ load: true, rolesArray: [] })
      //this.props.getUsersChildrenByModule(module, false);
    }

    if (usersRolesIsLoading && !next.usersRolesIsLoading) {
      this.setState({ users_roles: next.users_roles })
    }
  }

  handleChange = e => {
    this.setState({ search: e.target.value })
  }

  Userfilter = item => {
    const { search, filterUser } = this.state
    if (search) {
      const exp = new RegExp(search.toUpperCase(), 'g')
      return Object.keys(item.user).some(p =>
        `${JSON.stringify(item.user[p])}`.toUpperCase().match(exp),
      )
    } else {
      return filterUser.find(u => u.value === item.user.id)
    }
  }

  deleteFilter = item => {
    const { filter, permissions } = this.state
    const { roles } = this.props
    permissions.map(p => {
      if (p.role === item.role) {
        p.options.push(item)
      }
    })
    let index = filter.indexOf(item)
    if (index !== -1) {
      filter.splice(index, 1)
      this.setState(filter)
    }
    this.setState(permissions)
  }

  handleRoleChange = item => {
    const { filter, permissions } = this.state
    if (item.value === 0) {
      //select group
      permissions.map(p => {
        if (p.value === item.id) {
          p.options.map((o, index) => {
            if (index != 0) {
              filter.push(o)
            }
          })
          p.options.splice(1, p.options.length - 1)
        }
      })
    } else {
      //select item
      filter.push(item)
      permissions.map(p => {
        let index = p.options.indexOf(item)
        if (index >= 0) {
          p.options.splice(index, 1)
          this.setState(permissions)
        }
      })
    }
    this.setState(filter)
  }

  deleteUserFilter = item => {
    const { filterUser } = this.state
    let index = filterUser.indexOf(item)
    if (index !== -1) {
      filterUser.splice(index, 1)
      this.setState({ filterUser })
    }
  }

  handleUserChange = item => {
    const { filterUser } = this.state
    filterUser.push(item)
    this.setState({ filterUser })
  }

  handleItemChange = (user, role) => {
    //console.log("usuario: ",user);
    //console.log('rol', role)
    const { module } = this.props.match.params
    //this.setState({loading: {value: true, id: user.id}});
    if (!role.assigned) this.props.putRoleByUser(module, user.id, role.id)
    else this.props.deleteRoleByUser(module, user.id, role.id)
  }

  getAllUsers = (item, users) => {
    let array = 0
    users.map(u => {
      u.roles.map((ur, ind) => {
        if (ind !== 0) {
          ur.roles.map(urr => {
            if (
              ((item.id && item.id === urr.id) || item.value === urr.id) &&
              urr.assigned
            ) {
              array++
            }
          })
        }
      })
    })
    return array === users.length
  }

  getAllRoles = (user, roles, actualRoles) => {
    let array = 0
    roles.map((r, index) => {
      if (index != 0) {
        r.roles.map(rr => {
          actualRoles.map(f => {
            if (((f.id && f.id === rr.id) || f.value === rr.id) && rr.assigned) {
              array++
            }
          })
        })
      }
    })
    return actualRoles.length === array
  }

  setPermissionByUser = (user, roles, actualRoles) => {
    const { module } = this.props.match.params
    const { updateRolesByUser, users_roles } = this.props
    let value = this.getAllRoles(user, roles, actualRoles)
    let allRoles = []
    actualRoles.map(r => {
      allRoles.push({ id: r.id || r.value, name: r.child })
      users_roles.map(u => {
        u.roles.map(ur => {
          ur.roles.map(urr => {
            if ((r.id === urr.id || r.value === urr.id) && user.id === u.user.id) {
              urr.assigned = !value
            }
          })
        })
      })
    })
    updateRolesByUser(module, user.id, allRoles, !value)
  }

  setPermissionByRol = (rol, user) => {
    if (user.length > 0) {
      const { module } = this.props.match.params
      const { users_roles, updateRolesByRole } = this.props
      let value = this.getAllUsers(rol, user)
      let allUsers = []
      user.map(us => {
        allUsers.push(us.user)
        users_roles.map(u => {
          u.roles.map(ur => {
            ur.roles.map(urr => {
              if (
                (rol.id === urr.id || rol.value === urr.id) &&
                u.user.id === us.user.id
              ) {
                urr.assigned = !value
              }
            })
          })
        })
      })
      updateRolesByRole(module, allUsers, rol.id || rol.value, !value)
    }
  }

  renderFrozen = arr => {
    let value = false
    const { filter, regularHeaders } = this.state
    return arr.map((e, index) => (
      <tr className={'Tr'} key={index}>
        <td className={'Td'}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '15%' }}>
              <ButtonIcon
                icon={faCheckCircle}
                color={
                  this.getAllRoles(
                    e.user,
                    e.roles,
                    filter.length === 0 ? regularHeaders : filter,
                  )
                    ? 'green'
                    : '#CF1112'
                }
                tooltip={'Asignar/Quitar Todos'}
                onClick={() =>
                  this.setPermissionByUser(
                    e.user,
                    e.roles,
                    filter.length === 0 ? regularHeaders : filter,
                  )
                }
              />
            </div>
            <div style={{ width: '85%' }}>{e.user.name}</div>
          </div>
        </td>
      </tr>
    ))
  }

  regularRender = (user, role, index) => {
    if (index >= user.roles.length) return ''
    return user.roles[index].roles.map((urr, ind) => (
      <td
        key={ind}
        onClick={() => {
          this.handleItemChange(user.user, urr)
          urr.assigned = !urr.assigned
        }}
        className={urr.assigned ? 'background-green Td' : 'background-red Td'}>
        <input
          className={'checkbox'}
          style={{ cursor: 'pointer', marginTop: 20 }}
          type="checkbox"
          checked={urr.assigned ? true : false}
        />
      </td>
    ))
  }

  filterRender = (user, role) => {
    return user.roles.map(ur =>
      ur.roles.map(
        (urr, ind) =>
          urr.id === role.value && (
            <td
              key={ind}
              onClick={() => {
                this.handleItemChange(user.user, urr)
                urr.assigned = !urr.assigned
              }}
              className={urr.assigned ? 'background-green Td' : 'background-red Td'}>
              <input
                className={'checkbox'}
                style={{ cursor: 'pointer' }}
                type="checkbox"
                checked={urr.assigned ? true : false}
                onChange={() => console.log('')}
              />
            </td>
          ),
      ),
    )
  }

  renderRow = (item, index) => {
    const { regularHeaders, filter } = this.state
    return (
      <Tr className={'data Tr'} key={index + ''}>
        {filter.length === 0
          ? regularHeaders.map((r, index) => this.regularRender(item, r, index + 1))
          : filter.map(r => this.filterRender(item, r))}
      </Tr>
    )
  }

  render() {
    const { users_roles, user, filterUser } = this.state
    const { users } = this.props
    const { alert, load, filterTerm, search, role, permissions, filter } = this.state
    return (
      <div>
        <h1 className={'dashboard-welcome'}>Matriz de permisos</h1>
        <Row>
          <Col>
            <Card title={'Permisos'}>
              <Row style={{ marginBottom: 15 }}>
                <Col>
                  <FormTextField
                    label={'Buscar Usuario'}
                    id="filter"
                    value={filterTerm}
                    onChange={this.handleChange}
                  />
                </Col>
              </Row>
              <Row style={{ marginBottom: 15 }}>
                {
                  <Col xs={4}>
                    <CustomSelect
                      label={'Permisos'}
                      value={role}
                      onChange={this.handleRoleChange}
                      options={[...permissions]}
                      loading={!load}
                    />
                  </Col>
                }
                <Col xs={8}>
                  <div className={'input-wrapper has-feedback mt-3'}>
                    <label className={'form-label'}>Filtros Aplicados</label>
                    <Row>
                      {filter.map((r, index) => (
                        <div className={'permission'} key={index}>
                          <label style={{ marginRight: 5, paddingTop: 5 }}>
                            {r.label}
                          </label>
                          <ButtonIcon
                            className={'delete-button'}
                            icon={faTimes}
                            tooltip={'Quitar'}
                            color={'white'}
                            onClick={() => this.deleteFilter(r)}
                          />
                        </div>
                      ))}
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginBottom: 15 }}>
                {
                  <Col xs={4}>
                    <CustomSelect
                      label={'Usuarios'}
                      value={user}
                      onChange={this.handleUserChange}
                      options={users}
                      loading={!load}
                    />
                  </Col>
                }
                <Col xs={8}>
                  <div className={'input-wrapper has-feedback mt-3'}>
                    <label className={'form-label'}>Filtros Aplicados</label>
                    <Row>
                      {filterUser.map(r => (
                        <div className={'permission'}>
                          <label style={{ marginRight: 5, paddingTop: 5 }}>
                            {r.label}
                          </label>
                          <ButtonIcon
                            className={'delete-button'}
                            icon={faTimes}
                            tooltip={'Quitar'}
                            color={'white'}
                            onClick={() => this.deleteUserFilter(r)}
                          />
                        </div>
                      ))}
                    </Row>
                  </div>
                </Col>
              </Row>
              {load && (
                <ScrollSync>
                  <div className={'container-permission'}>
                    <div>
                      <Tabla
                        className={'fixed'}
                        striped
                        bordered
                        style={{ maxWidth: '25%', marginBottom: 0 }}>
                        <Thead className={'Thead'}>
                          <Tr className={'header Tr'}>
                            {this.state.fixedHeaders.map((item, index) => (
                              <Th
                                className={'Th'}
                                key={`${index}`}
                                style={{
                                  padding: 0,
                                  paddingBottom: 30,
                                  fontSize: 12,
                                }}>
                                <b>{item.role}</b>
                                <br />
                                {item.child}
                              </Th>
                            ))}
                          </Tr>
                        </Thead>
                      </Tabla>
                      <ScrollSyncPane group={'vertical'}>
                        <div style={{ overflow: 'hidden', maxHeight: 480 }}>
                          <Tabla
                            className={'fixed'}
                            striped
                            bordered
                            style={{ maxWidth: '25%' }}>
                            <Tbody className={'table-body text-center'}>
                              {this.renderFrozen(
                                users_roles.filter(
                                  search || filterUser.length > 0
                                    ? this.Userfilter
                                    : () => true,
                                ),
                              )}
                            </Tbody>
                          </Tabla>
                        </div>
                      </ScrollSyncPane>
                    </div>
                    <div className={'content-permission'}>
                      <ScrollSyncPane group={'horizontal'}>
                        <div
                          style={{
                            overflow: 'hidden',
                            width: '100%',
                            height: 100,
                          }}>
                          <Tabla className={'scrollable'} striped bordered hover>
                            <Thead className={'Thead'}>
                              <Tr className={'header Tr'}>
                                {filter.length === 0
                                  ? this.state.regularHeaders.map((item, index) => (
                                      <Th
                                        className={'Th'}
                                        key={`${index}`}
                                        style={{
                                          padding: 0,
                                          paddingBottom: 30,
                                          fontSize: 12,
                                        }}>
                                        <ButtonIcon
                                          icon={faCheckCircle}
                                          color={
                                            this.getAllUsers(
                                              item,
                                              users_roles.filter(
                                                search || filterUser.length > 0
                                                  ? this.Userfilter
                                                  : () => true,
                                              ),
                                            )
                                              ? 'green'
                                              : '#CF1112'
                                          }
                                          tooltip={'Asignar/Quitar Todos'}
                                          onClick={() =>
                                            this.setPermissionByRol(
                                              item,
                                              users_roles.filter(
                                                search ? this.Userfilter : () => true,
                                              ),
                                            )
                                          }
                                        />
                                        <b>{item.role}</b>
                                        <br />
                                        {item.child}
                                      </Th>
                                    ))
                                  : filter.map((item, index) => (
                                      <Th
                                        className={'Th'}
                                        key={`${index}`}
                                        style={{
                                          padding: 0,
                                          paddingBottom: 30,
                                          fontSize: 12,
                                        }}>
                                        <ButtonIcon
                                          icon={faCheckCircle}
                                          color={
                                            this.getAllUsers(
                                              item,
                                              users_roles.filter(
                                                search || filterUser.length > 0
                                                  ? this.Userfilter
                                                  : () => true,
                                              ),
                                            )
                                              ? 'green'
                                              : '#CF1112'
                                          }
                                          tooltip={'Asignar/Quitar Todos'}
                                          onClick={() =>
                                            this.setPermissionByRol(
                                              item,
                                              users_roles.filter(
                                                search || filterUser.length > 0
                                                  ? this.Userfilter
                                                  : () => true,
                                              ),
                                            )
                                          }
                                        />
                                        <b>{item.role}</b>
                                        <br />
                                        {item.child}
                                      </Th>
                                    ))}
                              </Tr>
                            </Thead>
                          </Tabla>
                        </div>
                      </ScrollSyncPane>
                      <ScrollSyncPane group={['vertical', 'horizontal']}>
                        <div className={'scroll'}>
                          <Tabla className={'scrollable'} striped bordered hover>
                            <Tbody className={'table-body text-center'}>
                              {users_roles &&
                                users_roles
                                  .filter(
                                    search || filterUser.length > 0
                                      ? this.Userfilter
                                      : () => true,
                                  )
                                  .map(this.renderRow)}
                            </Tbody>
                          </Tabla>
                        </div>
                      </ScrollSyncPane>
                    </div>
                  </div>
                </ScrollSync>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  users: selectUsers(state),
  usersIsLoading: loadingSelector([action.GET_USERS_CHILDREN])(state),
  users_roles: selectUsersRoles(state),
  usersRolesIsLoading: loadingSelector([action.GET_ALL_USERS_ROLES])(state),
  currentUsers: selectCurrentUser(state),
  roles: selectRolesByUser(state),
  rolesIsLoading: loadingSelector([action.GET_ROLES_BY_USER])(state),
})
const mapDispatchToProps = dispatch => ({
  getAllUsersRoles: module => dispatch(getAllUserChildRoles(module)),
  getRolesByUserChild: (module, id) => dispatch(getRolesByUserChild(module, id)),
  getUsersChildrenByModule: (module, includeParent) =>
    dispatch(getUsersChildrenByModule(module, includeParent)),
  deleteRoleByUser: (module, user, role) =>
    dispatch(deleteRoleByUser(module, user, role, false)),
  putRoleByUser: (module, user, role) =>
    dispatch(putRoleByUser(module, user, role, false)),
  updateRolesByUser: (module, user, roles, value) =>
    dispatch(updateRolesByUser(module, user, roles, value)),
  updateRolesByRole: (module, users, rol, value) =>
    dispatch(updateRolesByRole(module, users, rol, value)),
})
export default connect(mapStateToProps, mapDispatchToProps)(index)
