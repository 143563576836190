import { actionTypes } from 'src/actions/mentor.actions'
import { successState } from 'src/actions/global.actions'

const initialState: MState = {
  companyMentorPaginated: {
    total: 0,
    response: [],
  },
  createResponse: undefined,
  singleCompany: undefined,
  companyAddons: [],
  companyAddonsEntityResponse: {},
  companyFields: [],
  planOrders: [],
  planOrderDetails: {
    addons: [],
    plan: {},
  },
  client: {},
  companyActivities: [],
}

const MentorReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case successState(actionTypes.GET_COMPANY_CONFIGURATION_DATA):
      return { ...state, companyFields: payload }
    case successState(actionTypes.UPDATE_MENTOR_PLAN):
      return { ...state, companyAddonsEntityResponse: payload }
    case successState(actionTypes.GET_MENTOR_COMPANIES):
      return { ...state, companyMentorPaginated: payload }
    case successState(actionTypes.GET_SINGLE_COMPANY_MENTOR):
      return { ...state, singleCompany: payload }
    case successState(actionTypes.CREATE_OR_UPDATE_COMPANY):
      return { ...state, createResponse: payload }
    case successState(actionTypes.GET_COMPANY_ADDONS):
      return {
        ...state,
        companyAddons: payload.map(l => ({
          ...l,
          originalQuantity: l.quantity,
          originalAmount: l.amount,
          price: l.amount / l.quantity,
          originalPrice: l.amount / l.quantity,
        })),
      }
    case successState(actionTypes.ON_VALIDATE_UPDATE_ADDON):
      return { ...state, companyAddonsEntityResponse: payload }
    case successState(actionTypes.GET_PLAN_ORDERS):
      return { ...state, planOrders: payload.orders }
    case successState(actionTypes.GET_PLAN_ORDER_DETAILS):
      return { ...state, planOrderDetails: payload }
    case successState(actionTypes.GET_CLIENT_BY_COTIZAP_CLIENT):
      return { ...state, client: payload }
    case successState(actionTypes.GET_COMPANY_ACTIVITIES):
      return { ...state, companyActivities: payload }
    default:
      return state
  }
}
export default MentorReducer
