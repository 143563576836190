import React, { useLayoutEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { formatNumberWithCommas } from 'src/utils/formatters'
import DashboardCard from 'src/components/cards/dashboard-card/DashboardCard'
import { Card } from 'src/components'
import IconButton from 'src/components/buttons/IconButton'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const formatDescription = (description = '') =>
  description.split(/\\n|\n/).map((d, i) => <div key={i}>{d}</div>)

const PlanItem = ({
  item: plan,
  button,
  isActive,
  isPreview,
  userSpaces = 0,
  additionalUserSpaces = 0,
  style = {},
  addons = {},
  lite,
}) => {
  const summaryRef = useRef(null)

  const [size, setSize] = useState(0)

  useLayoutEffect(() => {
    if (window.innerWidth < 768) {
      setSize(null)
      return
    }

    const updateSize = () => setSize(summaryRef.current?.clientHeight)
    updateSize()

    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [summaryRef.current, JSON.stringify(plan)])

  const renderItem = isDlc => {
    const totalUserSpaces =
      ((isActive || isPreview) && userSpaces + additionalUserSpaces) || 0

    let totalUsers = plan.users + totalUserSpaces
    let totalWarehouses = plan.warehouse
    let totalFelPos = plan.felPos
    let totalPos = plan.POS
    let totalItems = plan.items
    const totalInvoices = plan.invoices
    if (Object.values(addons).length > 0) {
      totalUsers = addons.users
      totalWarehouses = addons.warehouses
      totalFelPos = addons.felPos
      totalPos = addons.POS
      totalItems = addons.items
    }

    const getIconButton = tooltip => (
      <label>
        <IconButton
          color={'rgba(34, 96, 149, 0.75)'}
          tooltip={formatDescription(tooltip)}
          icon={faInfoCircle}
          size={'sm'}
        />
      </label>
    )

    return (
      <Row style={{ alignItems: 'start', padding: '0 10px' }}>
        {!isDlc && (
          <Col xs={12} sm={12} lg={lite ? 12 : 5} ref={summaryRef}>
            {plan.description && (
              <div style={{ whiteSpace: 'pre-line' }} className={'mb-2'}>
                {formatDescription(plan.description)}
              </div>
            )}
            {additionalUserSpaces > 0 && (
              <div
                style={{
                  fontWeight: 700,
                  fontSize: 15,
                  color:
                    (isActive || isPreview) && plan.addUsers && additionalUserSpaces > 0
                      ? '#525252'
                      : '#226095',
                }}
                className={'mt-1'}>
                Q.{formatNumberWithCommas(plan.priceUsers * additionalUserSpaces)} anuales
                por {additionalUserSpaces} espacio(s) de usuario nuevos
              </div>
            )}
            <div style={{ fontWeight: 700, color: '#525252' }} className={'mt-2'}>
              {isActive ? 'Tus características:' : 'Características:'}
            </div>

            <ul style={{ marginBottom: 0 }}>
              {totalUsers > 0 && (
                <li>
                  <div style={{ fontWeight: 700, color: '#226095' }}>
                    {formatNumberWithCommas(totalUsers, 0)} usuario(s) contratados{' '}
                    {totalUserSpaces > 0 &&
                      getIconButton(
                        `${plan.users} usuario(s) del plan ${plan.name}\n${totalUserSpaces} espacio(s) de usuario contratados`,
                      )}
                  </div>
                </li>
              )}
              {totalWarehouses > 0 && (
                <li>
                  <div style={{ fontWeight: 700, color: '#226095' }}>
                    {formatNumberWithCommas(totalWarehouses, 0)} bodega(s) contratados{' '}
                  </div>
                </li>
              )}
              {totalPos > 0 && (
                <li>
                  <div style={{ fontWeight: 700, color: '#226095' }}>
                    {formatNumberWithCommas(totalPos, 0)} punto(s) de venta contratados{' '}
                  </div>
                </li>
              )}
              {totalFelPos > 0 && (
                <li>
                  <div style={{ fontWeight: 700, color: '#226095' }}>
                    {formatNumberWithCommas(totalFelPos, 0)} establecimiento(s)
                    comercial(es) contratados{' '}
                  </div>
                </li>
              )}
              {totalItems > 0 && (
                <li>
                  <div style={{ fontWeight: 700, color: '#226095' }}>
                    {formatNumberWithCommas(totalItems, 0)} ítems contratados{' '}
                  </div>
                </li>
              )}
            </ul>
            {(totalInvoices > 0 || plan.priceInvoice > 0) && (
              <ul style={{ margin: 0 }}>
                {totalInvoices > 0 && (
                  <li>
                    <div style={{ fontWeight: 700, color: '#226095' }}>
                      {formatNumberWithCommas(totalInvoices, 0)} facturas electrónicas sin
                      vencimiento{' '}
                    </div>
                  </li>
                )}
              </ul>
            )}
            <div style={{ fontWeight: 700, color: '#525252' }} className={'mt-2'}>
              Soporte:
            </div>
            <ul>
              <li>Acceso a videos de capacitación dentro de Kolo</li>
              {plan.support && (
                <li>
                  Atención personalizada: obtienes un chat con uno de nuestros mentores
                  para resolver dudas en tiempo real en horarios hábiles. Con opción de
                  solicitudes de desarrollo para tu empresa y el ecosistema kolo
                </li>
              )}
            </ul>
          </Col>
        )}

        {!lite && (
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={isDlc ? 12 : 7}
            style={{
              height: size || 'auto',
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#f6f6f6',
              padding: 10,
              borderRadius: 10,
            }}>
            <div style={{ fontWeight: 700, color: '#525252' }} className={'mb-2'}>
              {isDlc
                ? `Complemento del plan '${plan.name}'`
                : 'Aplicaciones incluidas con este plan'}
            </div>
            <div className="plan-icons">
              {plan.list
                ?.filter(d => d.showIcon)
                .map(item => (
                  <div key={item.id} className="plan-icon">
                    <DashboardCard icon={item.alterIcon} name={item.name} small />
                  </div>
                ))}
            </div>
          </Col>
        )}
      </Row>
    )
  }

  return (
    <Card
      white
      title={
        plan.id
          ? plan.name +
            (isActive && plan.extraUsers?.length > 0
              ? ` — ${plan.userSpaces} espacio(s) de usuario adicionales`
              : '')
          : 'Cargando el plan'
      }
      button={<div>{button}</div>}
      style={style}>
      {plan.id && renderItem(false)}
    </Card>
  )
}

export default PlanItem
