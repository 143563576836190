import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import { Button, Paragraph, FormText, Icon, NumberField } from 'src/components'
import { faBook, faCheckDouble, faWindowClose } from '@fortawesome/free-solid-svg-icons'

import { actionTypes } from 'src/actions/warehouse.actions'
import { loadingSelector } from 'src/selectors/loading.selector'

interface IItemProp {
  id: number
  documentTypeName: string
  documentCode: string
  referenceName: string
  code: string
  name: string
  existence: number
  quantity: number
  multiple: number
  decimals?: number
  subItems?: IItemProp[]
}

interface IProps {
  show: boolean
  selected: IItemProp[]
  state: number
  onHide: () => void
  onConfirm: (items: IItemProp[], desc: string, state: number) => void
}

/** Component that allows you to choose partial quantities of the items
 * to be dispatched
 * @component
 * @param {boolean} show Indicates if the component is rendered
 * @param {IItemProp[]} selected List of items to dispatch
 * @param {number} state Dispatch status
 * @param {function} onHide Close component
 * @param {function} onConfirm Action to dispatch items
 * */

const DispatchPartial = ({
  show = false,
  selected = [],
  state = 0,
  onHide,
  onConfirm,
}: IProps) => {
  const loading: boolean = useSelector(state =>
    loadingSelector([actionTypes.ON_UPDATE_STATUS_PENDING_MISSING_REQUEST])(state),
  )

  const [items, setItems] = useState<IItemProp[]>([])
  const [description, setDescription] = useState<string>('')

  useEffect(() => {
    if (!show) {
      setItems([])
      setDescription('')
    } else {
      setItems(
        selected.map((s: IItemProp) => {
          let name = s.name
          let code = s.code
          let existence = s.existence
          let multiple = 1
          let decimals = 0

          if (s.subItems && s.subItems.length > 0) {
            const subItem = s.subItems[0]

            name = subItem.name
            code = subItem.code
            existence = subItem.existence
            multiple = s.existence / subItem.existence
            decimals = subItem.decimals || 0
          }

          return { ...s, name, code, existence, quantity: existence, multiple, decimals }
        }),
      )
    }
  }, [show])

  const onClose = () => {
    onHide()
  }

  return (
    <div>
      <Modal show={show} size={'xl'} centered>
        <Modal.Header>
          <Modal.Title>{state === 5 ? 'Despacho' : 'Rechazo'} de solicitudes</Modal.Title>
        </Modal.Header>
        <Modal.Body className={'custom-modal-body'}>
          <Row>
            <Col xl={12}>
              <Paragraph>
                ¿Desea {state === 5 ? 'realizar' : 'rechazar'} los despachos pendientes?
                Está acción no podrá ser revertida. Puede realizar la operación completa o
                parcial, modificando las cantidades a operar.
              </Paragraph>
            </Col>
            <Col xl={12}>
              <FormText
                value={description}
                changeValue={setDescription}
                label={'Descripción'}
                prepend={<Icon icon={faBook} tooltip={'Descripción'} />}
              />
            </Col>
            <Col xl={12}>
              <Row>
                {items.map((item: IItemProp, index: number) => {
                  const split = item.referenceName?.split(',') || []

                  return (
                    <Col xl={12} key={item.id} className={'b-item column'}>
                      <div className={'d-flex'}>
                        <div style={{ width: '70%' }} className={'column'}>
                          <Paragraph>
                            {item.code} {item.name}
                          </Paragraph>
                          <Paragraph>{split.length > 0 ? split[0] : null}</Paragraph>
                          <Paragraph>
                            {item.documentTypeName}: {item.documentCode}
                          </Paragraph>
                        </div>
                        <div style={{ width: '30%' }}>
                          <NumberField
                            label={'Cantidad'}
                            value={item.quantity}
                            onValueChange={quantity => {
                              const list = Object.assign([], items)
                              list[index].quantity = quantity
                              setItems([...list])
                            }}
                            min={0}
                            max={item.existence}
                            decimals={item.decimals}
                            disabled={loading}
                          />
                        </div>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              disabled={loading}
              color={'secondary'}
              icon={faWindowClose}
              onClick={() => onClose()}>
              Cerrar
            </Button>

            <Button
              loading={loading}
              icon={faCheckDouble}
              onClick={() =>
                onConfirm(
                  items
                    .filter(i => i.quantity > 0)
                    .map(i => ({
                      ...i,
                      quantity: i.quantity * i.multiple,
                      typeAdjust: 3,
                    })),
                  description,
                  state,
                )
              }>
              Continuar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default DispatchPartial
