import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import StoreIcon from 'src/assets/images/svg/store.svg'
import LoadingIcon from 'src/assets/images/svg/loading.svg'
import Products from '../Client/Products/ProductsByPosition'
import Dashboard from '../Dashboard/Dashboard'
import { selectSubModules } from 'src/selectors/modules.selector'
import { selectSetCurrentCompany } from 'src/selectors/company.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { actionTypes as typesC } from 'src/actions/company.actions'
import {
  handlerError,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'
import { setMenu } from 'src/actions/dashboard.actions'
import {
  getCurrentUserRolesAction,
  getSubmodules,
  setModuleId,
} from 'src/actions/modules.actions'
import { saveCurrentModule } from 'src/actions/user.actions'
import { addItems } from 'src/actions/shopping.actions'
import { onSetDataToAlert } from 'src/actions/utilities.actions'
import CompanyProfile from '../CompanyProfile'
import CompanyConfigurations from '../Config/CompanyConfigurations'
import ReportsDashboard from '../Reports/Dashboard/ReportsDashboard'
import DocumentsDashboard from '../Documents/Dashboard/DocumentsDashboard'
import Users from '../Reports/Users/Users'
import InventoryHistory from '../Reports/InventoryHistory/InventoryHistory'
import BalanceEntries from '../Balance/BalanceEntries'
import AccountingBook from '../AccountingBook/AccountingBook'
import UpdatePermissions from '../Permissions/UpdatePermissions/UpdatePermissions'
import GranularPermission from '../Permissions/Granular/GranularPermission'
import Permissions from '../Permissions/Dashboard/Permissions'
import Prints from '../Prints/Prints'
import CompanyAccount from './Profile/CompanyAccount'
import BanksV2 from './Banks/BanksV2'
class CompanyRoutes extends Component {
  componentDidMount() {
    this.props.setModuleId({ id: 8000, title: 'Empresa' })
    this.props.getSubmodules(8000)
    this.props.saveCurrentModule()
    this.props.rolesAction()

    let shopping = window.localStorage.getItem('ADD_ITEM')
    if (shopping) {
      shopping = JSON.parse(shopping)
      this.props.addItems(shopping)
    } else this.props.addItems([])
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { submodules, setMenu, match } = this.props
    if (submodules !== next.submodules) {
      let menu = [
        {
          id: 8000,
          text: 'Menú Empresarial',
          icon: StoreIcon,
          link: `${match.url}/`,
        },
      ]
      next.submodules.forEach(subModule => {
        menu.push({
          id: subModule.id,
          text: subModule.name,
          icon: subModule.icon || LoadingIcon,
          link: subModule.route,
        })
      })
      setMenu(menu)
    }

    const { loadingC, hasErrorC } = this.props
    const { errorC, company } = next
    if (loadingC && !next.loadingC) {
      if (!hasErrorC && next.hasErrorC) {
        // TODO
      } else {
        this.props.setModuleId({
          id: 8000,
          title: `Menú Empresarial - ${company.name || ''}`,
        })
        if (!company.clientId) {
          // let alert = handlerError(`La empresa ${company.name} no posee un perfil de punto de venta.`);
          // alert.onConfirm = () => {
          //     history.replace('/perfil');
          //     setAlert({...alert, show: false});
          // };
          // setAlert(alert);
        }
      }
    }
  }

  render() {
    const { match } = this.props
    return (
      <Switch>
        <Route path={`${match.url}/prints`} component={Prints} />
        <Route path={`${match.url}/configuraciones`} component={CompanyConfigurations} />
        <Route path={`${match.url}/documentos`} component={DocumentsDashboard} />
        <Route path={`${match.url}/reportes/usuarios`} component={Users} />
        <Route path={`${match.url}/reportes/inventario`} component={InventoryHistory} />
        <Route path={`${match.url}/reportes/productos`} component={Products} />
        <Route path={`${match.url}/reportes`} component={ReportsDashboard} />
        <Route path={`${match.url}/balance`} component={BalanceEntries} />
        <Route path={`${match.url}/bancos`} component={BanksV2} />

        <Route
          path={`${match.url}/perfil/configuraciones`}
          component={CompanyConfigurations}
        />
        <Route path={`${match.url}/perfil/balance`} component={BalanceEntries} />
        <Route path={`${match.url}/perfil`} component={CompanyAccount} />

        <Route path={`${match.url}/patrimonio`} component={AccountingBook} />

        <Route
          path={`${match.url}/permisos/:module/:user/:name`}
          component={UpdatePermissions}
        />
        <Route
          path={`${match.url}/permisos/:module/matriz`}
          component={GranularPermission}
        />
        <Route path={`${match.url}/permisos/:module`} component={Permissions} />

        <Route path={``} component={Dashboard} />
      </Switch>
    )
  }
}

const mapStateToProps = state => ({
  submodules: selectSubModules(state),
  company: selectSetCurrentCompany(state),
  loadingC: loadingSelector([typesC.CURRENT_COMPANY])(state),
  hasErrorC: hasErrorsSelector([typesC.CURRENT_COMPANY])(state),
  errorC: singleErrorSelector([typesC.CURRENT_COMPANY])(state),
  handlerError: message => handlerError(message),
})
const mapDispatchToProps = dispatch => ({
  setMenu: routes => dispatch(setMenu(routes)),
  setModuleId: module => dispatch(setModuleId(module)),
  getSubmodules: module => dispatch(getSubmodules(module)),
  saveCurrentModule: () => dispatch(saveCurrentModule(8000)),
  rolesAction: () => dispatch(getCurrentUserRolesAction(8000)),
  addItems: shopping => dispatch(addItems(shopping)),
  setAlert: alert => dispatch(onSetDataToAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CompanyRoutes)
