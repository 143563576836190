import { actionTypes } from '../actions/versions.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  allVersions: [],
  lastVersion: {},
}

const VersionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_VERSIONS):
      return {
        ...state,
        allVersions: extract(action.allVersions, []),
      }

    case successState(actionTypes.GET_VERSION_DETAILS): {
      const allVersions = state.allVersions.map(version =>
        version.id === action.id
          ? { ...version, detail: extract(action.versionDetails, []) }
          : version,
      )

      return {
        ...state,
        allVersions,
      }
    }
    case successState(actionTypes.GET_LAST_VERSION):
      return {
        ...state,
        lastVersion: extract(action.lastVersion, {}),
      }

    default:
      return state ? state : initialState
  }
}

export default VersionsReducer
