import './TableOrderPage.scss'
import React from 'react'

import { useSelector } from 'react-redux'
import {
  selectGetTableOrder,
  selectPendingPayments,
} from 'src/selectors/restaurant.selector'
import Money from 'src/components/Money/Money'

const TableOrderSummary = () => {
  const tableOrder = useSelector(selectGetTableOrder)
  const pendingPayments = useSelector(selectPendingPayments)

  const total = tableOrder.amount
  const paid = pendingPayments.reduce((total, payment) => total + payment.amount, 0)
  const pending = total - paid

  return (
    <div className="pos-payments-summary">
      <div className="space-between">
        <div className="pos-payments-summary-label">Total</div>
        <Money className="pos-payments-summary-amount">{total}</Money>
      </div>
      <div className="space-between">
        <div className="pos-payments-summary-label">Abonado</div>
        <Money className="pos-payments-summary-amount">{paid}</Money>
      </div>
      <div className="pos-payments-summary-separator" />

      <div className="space-between">
        <div className="pos-payments-summary-label">Pendiente</div>
        <Money className="pos-payments-summary-amount">{pending}</Money>
      </div>
    </div>
  )
}
export default TableOrderSummary
