import { actionTypes } from '../actions/tickets.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  issues: [],
}

const TicketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_ISSUES):
      return {
        ...state,
        issues: extract(action.issues, []),
      }

    default:
      return state ? state : initialState
  }
}

export default TicketsReducer
