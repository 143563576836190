import React, { Component } from 'react'
import '../Card.scss'
import {
  faAngleUp,
  faAngleDown,
  faMoneyBillAlt,
  faMoneyCheckAlt,
} from '@fortawesome/free-solid-svg-icons'
import IconButton from '../../buttons/IconButton'
import Collapse from 'react-bootstrap/Collapse'

export default class CollapsibleCard extends Component {
  state = {
    collapsed: false,
  }

  UNSAFE_componentWillMount() {
    const { startCollapsed } = this.props
    this.setState({ collapsed: !startCollapsed })
  }

  render() {
    const { collapsed } = this.state
    return (
      <div className={`card ${this.props.className || ''}`} style={this.props.style}>
        {this.props.title !== undefined && (
          <div
            className={`card-header ${this.props.classHeader || ''} ${
              this.props.white ? 'ch-white' : ''
            }`}
            style={this.props.header}
          >
            <div>{this.props.title}</div>
            <div className={'button-icon'}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                {this.props.button && this.props.button}
                <IconButton
                  icon={!collapsed ? faAngleDown : faAngleUp}
                  tooltip={!collapsed ? 'Expandir' : 'Colapsar'}
                  color={this.props.colorButton}
                  onClick={() => this.setState({ collapsed: !collapsed })}
                />
              </div>
            </div>
          </div>
        )}
        <Collapse in={collapsed}>
          <div
            className={`card-body ${this.props.bodyClass || ''}`}
            style={this.props.bodyStyle}
          >
            {this.props.children}
          </div>
        </Collapse>
      </div>
    )
  }
}
