import { types } from '../actions/enumeration.actions'
import { successState, extract } from '../actions/global.actions'

const initialState = {
  enumeration: {},
  enumerations: [],
  report: [],
  recipe: {},
  customVariables: [],
  customVariable: {},
}

const EnumerationReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(types.GET_RECIPE_VARIABLE):
      return { ...state, customVariable: extract(action.customVariable, {}) }
    case successState(types.GET_CUSTOM_RECIPE):
      return { ...state, customVariables: extract(action.customVariables, []) }
    case successState(types.ON_GET_ENUMERATION):
      return { ...state, recipe: extract(action.response, {}) }
    case successState(types.ON_GET_ENUMERATIONS):
      return { ...state, enumerations: action.enumerations }
    case successState(types.GET_PRODUCT_ENUMERATION):
      return { ...state, enumeration: action.enumeration }
    case successState(types.GET_ENUMERATION_REPORT):
      return { ...state, report: action.response }
    default:
      return state ? state : initialState
  }
}

export default EnumerationReducer
