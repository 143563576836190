import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  actionTypes,
  getNotificationsField,
  updateNotificationsField,
} from 'src/actions/notifications.actions'
import { Row, Col, Accordion } from 'react-bootstrap'
import { Card } from 'react-bootstrap'
import { Title } from '../../components'
import { selectFieldsNotification } from 'src/selectors/notifications.selector'
import {
  faBell,
  faCaretDown,
  faEnvelope,
  faTools,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Switch from 'src/components/inputs/Switch/Switch'
import {
  selectCurrentCompany,
  selectCurrentModule,
  selectCurrentUser,
} from 'src/selectors/user.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import ProgressBar from 'react-bootstrap/ProgressBar'
import EmailConfirmationNotice from 'src/components/toolbar/EmailConfirmationNotice'
import { useAccordionButton as useAccordionToggle } from 'react-bootstrap/AccordionButton'

function RenderItems({ f, index, handleSwitchChange, action }) {
  const decoratedOnClick = useAccordionToggle(index, () => {})

  return (
    <Card style={{ margin: 5 }} key={index}>
      <div
        style={{ padding: '10px 5px', cursor: 'pointer' }}
        onClick={() => {
          decoratedOnClick()
        }}
      >
        <div
          className={'d-flex'}
          style={{ justifyContent: 'space-between', width: '100%' }}
        >
          <div className={'d-flex justify-content-flex-start'}>
            <FontAwesomeIcon
              size={'2x'}
              className={'name'}
              icon={faTools}
              style={{ marginRight: 10 }}
            />
            <div>{f.name}</div>
          </div>
          <div>
            <FontAwesomeIcon
              size={'1x'}
              className={'name'}
              icon={faCaretDown}
              style={{ marginRight: 10 }}
            />
          </div>
        </div>
      </div>
      <Accordion.Collapse eventKey={index}>
        <Card.Body>
          <div>{f.description}</div>
          <hr />
          <div style={{ marginBottom: 10 }}>
            <b>Recibirás estas notificaciones por los siguientes medios</b>
          </div>
          <div className={'d-flex'} style={{ justifyContent: 'space-between' }}>
            <div className={'d-flex justify-content-flex-start'}>
              <FontAwesomeIcon
                size={'2x'}
                className={'name'}
                icon={faBell}
                style={{ color: 'rgb(108,175,199)', marginRight: 10 }}
              />
              Web
            </div>
            <div>
              <div className={'d-flex'}>
                <label className={'checkbox-label'} style={{ marginRight: 5 }}>
                  {f.app ? 'Activado' : 'Desactivado'}
                </label>
                <Switch
                  loading={action.type === 1 && action.id === f.id}
                  checked={f.app}
                  id={'app'}
                  onChange={event => handleSwitchChange(event, f)}
                />
              </div>
            </div>
          </div>
          <div className={'d-flex'} style={{ justifyContent: 'space-between' }}>
            <div className={'d-flex justify-content-flex-start'}>
              <FontAwesomeIcon
                size={'2x'}
                className={'name'}
                icon={faEnvelope}
                style={{ color: 'rgb(108,175,199)', marginRight: 10 }}
              />
              Correo Electrónico
            </div>
            <div>
              <div className={'d-flex'}>
                <label className={'checkbox-label'} style={{ marginRight: 5 }}>
                  {f.email ? 'Activado' : 'Desactivado'}
                </label>
                <Switch
                  loading={action.type === 2 && action.id === f.id}
                  checked={f.email}
                  id={'email'}
                  onChange={event => handleSwitchChange(event, f)}
                />
              </div>
            </div>
          </div>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  )
}

const Configuration = () => {
  const dispatch = useDispatch()

  const fields = useSelector(selectFieldsNotification)
  const companyId = useSelector(selectCurrentCompany)
  const module = useSelector(selectCurrentModule)
  const user = useSelector(selectCurrentUser)

  const loadingUpdate = useSelector(loadingSelector([actionTypes.UPDATE_CONFIGURATION]))
  const loading = useSelector(loadingSelector([actionTypes.GET_FIELDS]))

  const [action, setAction] = useState({ id: 0, loading: false, type: 0 })

  useEffect(() => {
    dispatch(getNotificationsField())
  }, [])

  useEffect(() => {
    if (fields && fields.length > 0 && action.id !== 0) {
      if (!action.loading) setAction({ ...action, loading: true })
      else {
        setAction({ ...action, loading: false, id: 0 })
        dispatch(getNotificationsField())
      }
    }
  }, [loadingUpdate])

  const handleSwitchChange = (event, item) => {
    let { id, value } = event.target
    let app = item.app,
      email = item.email
    value = !(value === 'true')
    if (id === 'app') {
      app = value
      setAction({ ...action, id: item.id, type: 1 })
    } else {
      email = value
      setAction({ ...action, id: item.id, type: 2 })
    }
    dispatch(updateNotificationsField(item.id, app, email, companyId))
  }

  return (
    <div>
      {!user.verify && <EmailConfirmationNotice isSignedIn alwaysShow />}
      <Row className={'justify-content-center'}>
        <Col sm={12} md={8}>
          {loading && <ProgressBar now={100} animated />}
          <Title
            title={`Configuración de Notificaciones (${
              module === 2000
                ? 'Producción y Comercio'
                : module === 1000
                ? 'Transporte y Logística'
                : 'Administración'
            })`}
          />
          <Card>
            {fields && fields.length <= 0 && (
              <h3>
                No hay notificaciones a configurar según sus permisos en esta empresa
              </h3>
            )}
            <Accordion>
              {fields.map((f, index) => (
                <RenderItems
                  key={index}
                  f={f}
                  index={++index}
                  handleSwitchChange={handleSwitchChange}
                  action={action}
                />
              ))}
            </Accordion>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Configuration
