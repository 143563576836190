import { posPanelConfigurations } from 'src/enums/posPanelConfigurations'

export const selectGetAllTables = state =>
  state.restaurant.allTables.map(at => ({
    ...at,
    value: at.id,
    label: at.number,
  }))
export const selectGetTableOrder = state => state.restaurant.tableOrder
export const selectGetTablesStatus = state => state.restaurant.tableStatus
export const selectAllReservations = state => state.restaurant.reservation
export const selectChairsByTable = state => state.restaurant.chairs
export const selectReservationStatus = state => state.restaurant.reservationStatus
export const selectAllTableOrders = state => state.restaurant.allOrders
export const selectAllPOSUser = state =>
  state.restaurant.posUser.map(p => ({ ...p, value: p.id, label: p.name }))
export const selectAllPOSAdmins = state =>
  state.restaurant.posAdmins.map(p => ({ ...p, value: p.id, label: p.name }))
export const selectIsPosAdmin = state => state.restaurant.isPosAdmin
export const selectAllPOS = state =>
  state.restaurant.allPOS.map(p => ({ ...p, value: p.id, label: p.name }))
export const selectPOSResponse = state => state.restaurant.posResponse

export const selectTips = state => state.restaurant.tipsAvailable.filter(t => !t.discount)
export const selectDiscounts = state =>
  state.restaurant.tipsAvailable.filter(t => t.discount)

export const selectTableItems = state => state.restaurant.items
export const selectTableTotalItems = state => state.restaurant.total
export const selectTableCategoryItems = state => state.restaurant.categoryItems
export const selectTableTotalCategoryItems = state => state.restaurant.categoryTotal
export const selectTableSearchItems = state => state.restaurant.searchItems
export const selectTableTotalSearchItems = state => state.restaurant.searchTotal

export const selectPOSSearch = state => state.restaurant.search
export const selectPOSCategories = state => state.restaurant.categories
export const selectTableItem = state => state.restaurant.item
export const selectBillItems = state => state.restaurant.billItems
export const selectPrintData = state => state.restaurant.printData
export const selectTagsSelected = state => state.restaurant.tags
export const selectSeePreBill = state => state.restaurant.seePreBill
export const selectCategorySelected = state => state.restaurant.categorySelected
export const selectBillItemsSelected = state => state.restaurant.billItemsSelected
export const selectDescription = state => state.restaurant.description
export const selectTokenVerified = state => state.restaurant.tokenVerified
export const selectOrderPayments = state => state.restaurant.payments
export const selectPendingPayments = state => state.restaurant.pendingPayments
export const selectSelectedPendingPayment = state =>
  state.restaurant.selectedPendingPayment
export const selectDiscountSelected = state => state.restaurant.discountSelected
export const selectPOSTabs = state =>
  state.restaurant.tabs.filter(
    tab =>
      !tab.name || selectPosConfigurationByType(state, posPanelConfigurations[tab.name]),
  )

export const selectPOSTabSelected = state => state.restaurant.tabs.find(t => t.selected)

export const selectItemsType = state =>
  state.restaurant.posItemsType
    .sort((a, b) => a.type - b.type)
    .map(i => ({ ...i, label: i.name, value: i.id }))

export const selectListTags = (state, withInitial) => {
  const items = state.restaurant.billItems
  const tags = state.restaurant.tags
  const response = []
  if (withInitial) {
    response.push(
      ...[
        { value: -1, label: 'Todo' },
        { value: 0, label: 'Individual' },
      ],
    )
  }

  items.forEach(bi => {
    if (bi.tags) {
      bi.tags.forEach(t => {
        const rIndex = response.findIndex(r => r.value === t.label)
        if (rIndex === -1) response.push({ value: t.label, label: t.label })
      })
    } else if (bi.tag) {
      const rIndex = response.findIndex(r => r.value === bi.tag)
      if (rIndex === -1) response.push({ value: bi.tag, label: bi.tag })
    }
  })

  response.forEach(r => {
    const selected = tags.findIndex(t => t.value === r.value)
    r.selected = selected >= 0
  })

  return response
}

export const selectPOSTickets = state => {
  const allItems = state.restaurant.billItems
  const tags = selectListTags(state)

  const groupItems = items => {
    const groupedItems = []

    items
      .filter(item => !item.parentId)
      .forEach((item, index) => {
        if (!item.status) groupedItems.push({ ...item, customId: index })
        else {
          const responseItem = groupedItems.find(
            responseItem =>
              responseItem.id === item.id &&
              item.status &&
              responseItem.status &&
              item.status.id === responseItem.status.id &&
              item.suppliesText === responseItem.suppliesText &&
              item.commentary === responseItem.commentary,
          )
          if (responseItem) {
            responseItem.quantity += item.quantity
            responseItem.subtotal += item.subtotal
            responseItem.subItems.push(item)
          } else groupedItems.push({ ...item, subItems: [item], customId: index })
        }
      })

    return groupedItems
  }

  const withoutTagItems = allItems.filter(i => !i.tag)

  const withoutTicket = { tag: 'Sin ticket', items: groupItems(withoutTagItems) }

  const tickets = tags.map(tag => {
    const ticketItems = allItems.filter(i =>
      i.tags ? i.tags.some(itemTag => itemTag === tag.value) : i.tag === tag.value,
    )

    return {
      tag: tag.value,
      items: groupItems(ticketItems),
    }
  })

  const emptyTickets = state.restaurant.emptyTickets.filter(
    emptyTicket => !tickets.some(ticket => ticket.tag === emptyTicket.tag),
  )

  const allTickets = [withoutTicket, ...tickets]
    .filter(ticket => ticket.items && ticket.items.length > 0)
    .concat(emptyTickets)

  return allTickets
}

export const selectSelectedPOSTicket = state => state.restaurant.selectedTicket

export const selectLastPosId = state => state.restaurant.lastPosId
export const selectLastTerminalId = state => state.restaurant.lastTerminalId
export const selectPosConfigurations = state => state.restaurant.allPosConfigurations
export const selectPosConfiguration = state => state.restaurant.posConfiguration
export const selectPosConfigurationByType = (state, type) =>
  !state.restaurant.posConfiguration?.fields ||
  state.restaurant.posConfiguration?.fields?.find(field => field.type === type)?.value
export const selectOrderConcepts = state => state.restaurant.orderConcepts
export const selectPosConcepts = state => state.restaurant.posConcepts
export const selectProductPrices = state => state.restaurant.productPricesByPOS
export const selectClearRequest = state => state.restaurant.clearRequest
export const selectPosItemsExistence = state => state.restaurant.posItemsExistence
export const selectPosItemsExistenceSearch = state =>
  state.restaurant.posItemsExistenceSearch
export const selectPosItemsExistenceCategory = state =>
  state.restaurant.posItemsExistenceCategory
export const selectPosSelected = state => state.restaurant.posSelected
export const selectPosConfigurationResponse = state =>
  state.restaurant.posConfigurationResponse

export const selectPOSCorrelative = state => state.restaurant.billCorrelative
