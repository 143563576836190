export const selectAllClients = state => {
  const { allClients } = state.clients
  return allClients.map(client => {
    if (client.balance === null) client.balance = 0
    client.label = `${client.code || ''} - ${client.companyName}`
    client.name = client.companyName
    client.value = client.id
    return client
  })
}
export const selectTotalClients = state => state.clients.totalClients
export const selectTotalLogClients = state => state.clients.totalLog

export const allClientsToSelect = state => {
  return state.clients.allClients.map(client => {
    return { value: client, label: `${client.code} - ${client.companyName}` }
  })
}

export const selectSimpleClients = state => state.clients.clientSimple

export const simpleClients = state => {
  return state.clients.clientSimple.map(client => {
    if (client.balance === null) client.balance = 0
    if (client.vouchers === null) client.vouchers = 0
    if (client.creditNotes === null) client.creditNotes = 0
    client.label = `${client.code || ''} - ${client.companyName}`
    client.value = client.id
    return client
  })
}

export const simpleClientsV2 = state => {
  return state.clients.clientSimple.map(client => {
    if (client.balance === null) client.balance = 0
    if (client.vouchers === null) client.vouchers = 0

    client.label = `${client.code || ''} - ${client.companyName}`
    client.value = client.id
    return client
  })
}

export const selectSingleClient = state => state.clients.currentClient

export const selectCreateClient = state => state.clients.currentClient
export const selectUpdateClient = state => state.clients.currentClient
export const selectAllCotizapClients = state => state.clients.allCotizapClients
export const selectSomeCotizapClients = state => state.clients.someCotizapClients

export const selectDeliverers = state => state.clients.deliverers
export const selectDeliverersToSelect = state =>
  state.clients.deliverers.map(item => ({
    value: item.id,
    label: item.name,
  }))
export const selectSellers = state =>
  state.clients.sellers.map(item => ({ value: item.id, label: item.name }))
export const selectSellersP = state =>
  state.clients.sellersP.map(item => ({ value: item.id, label: item.name }))
export const selectGetSellers = state => state.clients.sellers
export const selectGetSellersToSelect = state =>
  state.clients.sellers.map(item => ({ value: item.id, label: item.name }))
export const selectSelectedClients = state => state.clients.selectedClients
export const selectGenericResponse = state => state.clients.genericResponse
export const selectUploadResponseClients = state => state.clients.uploadResponse
export const selectCompany = state => state.clients.company
export const selectGlobalClient = state => state.clients.global

export const selectCreate = state => state.clients.create

export const selectAllRequestsInfo = state => state.clients.requestsInfo
export const selectHistorical = state => state.clients.historical
export const selectHistoricalLog = state => state.clients.historicalLog
export const selectTotalHistoricalLog = state => state.clients.totalHistoricalLog
export const selectReport = state => state.clients.report
export const selectArchived = state => state.clients.archived
export const selectHideResponse = state => state.clients.hideAction
export const selectClientLog = state => state.clients.log
export const selectDirtyResponse = state => state.clients.dirty
export const selectClientReports = state => state.clients.reports
export const selectClientStatusReport = state => state.clients.statusReport
export const selectGetBalanceReport = state => state.clients.balanceReport
export const selectClientRequests = state => state.clients.requests
export const selectClientRequest = state => state.clients.request
export const selectSimpleLog = state => state.clients.simpleLog
export const selectVouchers = state => state.clients.vouchers
export const selectClientVouchers = state => state.clients.clients
export const selectTransactions = state => state.clients.transactions
export const selectProducts = state => state.clients.products
export const selectNITinfo = state => ({
  ...state.clients.infoNIT,
  ...state.clients.infoNIT?.clientInfo,
})
export const selectCUIinfo = state => ({
  ...state.clients.infoCUI,
  ...state.clients.infoCUI?.clientInfo,
})
export const selectIdentificationInfo = (state, identificationTypeId) =>
  state.clients.identificationsInfo[identificationTypeId]
