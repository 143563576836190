import {
  collection,
  addDoc,
  getDocs,
  query,
  limit,
  DocumentData,
  QueryDocumentSnapshot,
  updateDoc,
  writeBatch,
} from 'firebase/firestore'
import { firestore } from 'src/utils/firebase'
const COLLECTION_ROOT = 'sessions'

interface SessionConfig {
  userId: string
  placement: string
}

function getPath({ userId, placement }: SessionConfig): string[] {
  return [userId, placement]
}

async function createSession(path: string[], payload: unknown) {
  const collectionRef = collection(firestore, COLLECTION_ROOT, ...path)
  await addDoc(collectionRef, { ...(payload as object) })
}
async function saveSession(path: string[], payload: unknown): Promise<void> {
  const collectionRef = collection(firestore, COLLECTION_ROOT, ...path)

  const sessionDoc = await getSession(path)

  if (sessionDoc !== undefined) {
    await updateDoc(sessionDoc.ref, { ...(payload as object) })
  } else {
    await addDoc(collectionRef, { ...(payload as object) })
  }
}

async function getSession(
  path: string[],
): Promise<QueryDocumentSnapshot<DocumentData> | undefined> {
  const collectionRef = collection(firestore, COLLECTION_ROOT, ...path)
  const querySpec = query(collectionRef, limit(1))

  const docSnapshot = await getDocs(querySpec)

  if (docSnapshot.docs.length === 0) {
    return undefined
  }

  return docSnapshot.docs[0]
}

async function clearSession(path: string[]): Promise<void> {
  const collectionRef = collection(firestore, COLLECTION_ROOT, ...path)
  const deleteQuery = query(collectionRef)

  const snapshot = await getDocs(deleteQuery)
  if (snapshot.size > 0) {
    const batch = writeBatch(firestore)
    snapshot.docs.forEach(doc => {
      batch.delete(doc.ref)
    })
    await batch.commit()
  }
}

export { getSession, createSession, saveSession, getPath, clearSession }
