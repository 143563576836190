import React, { useEffect, useState } from 'react'

import { Paragraph, Icon } from 'src/components'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import { formatDateFromMillis, formatNumberWithCommas } from 'src/utils/formatters'
import { toMoney } from 'src/utils/utilities'

interface Props {
  addon: MCompanyAddons
  demo: boolean
  renewal?: boolean
  separate: boolean
  disabled: boolean
  edit: boolean
  onUpdate: (MCompanyAddons) => void
  onDelete: () => void
}

/** Component that renders the detail of an addon
 * @component
 * @param {MCompanyAddons} addon Addon to render detail
 * @param {boolean} demo Indicate if the company is a DEMO account
 * @param {boolean} renewal Indicate if the company is in renewal process
 * @param {boolean} separate Indicate if the necessary render a hr tag html
 * @param {boolean} disabled indicate if it is necessary to disable inputs
 * @param {boolean} edit Indicate if can possibly edit quantity and amount values
 * @param {Function} onUpdate Update information of addon
 * @param {Function} onDelete Delete addon
 * */
const DetailAddon = ({
  addon,
  demo,
  renewal = false,
  separate,
  disabled,
  onUpdate,
  edit,
  onDelete,
}: Props) => {
  const demoBehavior = demo || renewal

  const [editAddon, setEditAddon] = useState({
    quantity: 0,
    amount: 0,
  })

  useEffect(() => {
    if (!edit) return
    setEditAddon({
      quantity: addon.quantity,
      amount: addon.price,
    })
  }, [edit])

  const customInput = (value: number, id: string) => {
    return (
      <input
        disabled={disabled}
        type={'number'}
        value={value}
        id={id}
        onChange={e => {
          const { target } = e
          onChange(target.value, id)
        }}
      />
    )
  }

  const onChange = (value: string, id: string) => {
    let newValue
    if (isNaN(Number(value))) newValue = 0
    else if (Number(value) < 0) newValue = 0
    else if (id === 'quantity' && Number(value) > addon.originalQuantity && !demoBehavior)
      newValue = addon.originalQuantity
    else newValue = Number(value)

    const customAddon = { ...editAddon, [id]: newValue }
    setEditAddon(customAddon)
    onUpdate({
      ...addon,
      ...customAddon,
      price: editAddon.amount,
    })
  }

  return (
    <div className={'column mb-2'}>
      <div className={'d-flex'}>
        <div style={{ width: edit && !demoBehavior ? '90%' : '100%' }} className={'px-1'}>
          <div className={'space-between'}>
            <div className={'column'}>
              <Paragraph>
                {addon?.item?.code} {addon?.item?.name}:
              </Paragraph>
              <Paragraph>Precio:</Paragraph>
              <Paragraph>Total:</Paragraph>
              {addon.startDate && <Paragraph>Fecha de contratación</Paragraph>}
              {addon.nextDate && <Paragraph>Próxima fecha de cobro</Paragraph>}
              {!demoBehavior && <Paragraph>Pagos realizados</Paragraph>}
            </div>
            <div className={'column'}>
              <div>
                {!edit || !demoBehavior
                  ? formatNumberWithCommas(addon.quantity)
                  : customInput(editAddon.quantity, 'quantity')}
              </div>

              <div>
                {!edit || !demoBehavior
                  ? toMoney(addon.price)
                  : customInput(editAddon.amount, 'amount')}
              </div>

              <div>
                {toMoney(!edit ? addon.amount : editAddon.amount * editAddon.quantity)}
              </div>
              {addon.startDate && <div>{formatDateFromMillis(addon.startDate)}</div>}
              {addon.nextDate && <div>{formatDateFromMillis(addon.nextDate)}</div>}
              {!demoBehavior && <div>{formatNumberWithCommas(addon.paid)}</div>}
            </div>
          </div>
        </div>
        {edit && !demoBehavior && (
          <div>
            <Icon tooltip={'Eliminar'} icon={faTrash} onClick={onDelete} />
          </div>
        )}
      </div>
      {separate && <hr className={'m-0'} />}
    </div>
  )
}
export default DetailAddon
