import {
  selectCurrentCompany,
  selectSetCompanyFieldsValues,
} from 'src/selectors/company.selector'
import { getState } from 'src/utils/utilities'
import CompaniesServices from '../services/companies.services'
import ResolutionsServices from '../services/resolutions.service'
import { executeAction } from './global.actions'

export const actionTypes = {
  GET_COMPANY: 'GET_COMPANY',

  CURRENT_COMPANY: 'CURRENT_COMPANY',
  CURRENT_COMPANY_REQUEST: 'CURRENT_COMPANY_REQUEST',
  CURRENT_COMPANY_SUCCESS: 'CURRENT_COMPANY_SUCCESS',
  CURRENT_COMPANY_ERROR: 'CURRENT_COMPANY_ERROR',

  CURRENT_MODULE: 'CURRENT_MODULE',
  CURRENT_MODULE_REQUEST: 'CURRENT_MODULE_REQUEST',
  CURRENT_MODULE_SUCCESS: 'CURRENT_MODULE_SUCCESS',
  CURRENT_MODULE_ERROR: 'CURRENT_MODULE_ERROR',

  GET_ALL_COMPANIES: 'GET_ALL_COMPANIES',

  GET_PROMOTOR_COMPANIES: 'GET_PROMOTOR_COMPANIES',

  GET_COMPANIES_BY_TYPE: 'GET_COMPANIES_BY_TYPE',
  GET_COMPANIES_BY_TYPE_REQUEST: 'GET_COMPANIES_BY_TYPE_REQUEST',
  GET_COMPANIES_BY_TYPE_SUCCESS: 'GET_COMPANIES_BY_TYPE_SUCCESS',
  GET_COMPANIES_BY_TYPE_ERROR: 'GET_COMPANIES_BY_TYPE_ERROR',

  ACTIVE_SINGLE_COMPANY: 'ACTIVE_SINGLE_COMPANY',
  ACTIVE_SINGLE_COMPANY_REQUEST: 'ACTIVE_SINGLE_COMPANY_REQUEST',
  ACTIVE_SINGLE_COMPANY_SUCESS: 'ACTIVE_SINGLE_COMPANY_SUCESS',
  ACTIVE_SINGLE_COMPANY_ERROR: 'ACTIVE_SINGLE_COMPANY_ERROR',

  GET_SINGLE_COMPANY: 'GET_SINGLE_COMPANY',
  GET_SINGLE_COMPANY_REQUEST: 'GET_SINGLE_COMPANY_REQUEST',
  GET_SINGLE_COMPANY_SUCCESS: 'GET_SINGLE_COMPANY_SUCCESS',
  GET_SINGLE_COMPANY_ERROR: 'GET_SINGLE_COMPANY_ERROR',

  DELETE_SINGLE_COMPANY: 'DELETE_SINGLE_COMPANY',
  DELETE_SINGLE_COMPANY_REQUEST: 'DELETE_SINGLE_COMPANY_REQUEST',
  DELETE_SINGLE_COMPANY_SUCCESS: 'DELETE_SINGLE_COMPANY_SUCCESS',
  DELETE_SINGLE_COMPANY_ERROR: 'DELETE_SINGLE_COMPANY_ERROR',

  UPDATE_SINGLE_COMPANY: 'UPDATE_SINGLE_COMPANY',
  UPDATE_SINGLE_COMPANY_REQUEST: 'UPDATE_SINGLE_COMPANY_REQUEST',
  UPDATE_SINGLE_COMPANY_SUCCESS: 'UPDATE_SINGLE_COMPANY_SUCCESS',
  UPDATE_SINGLE_COMPANY_ERROR: 'UPDATE_SINGLE_COMPANY_ERROR',

  CREATE_ADMIN_COMPANY: 'CREATE_ADMIN_COMPANY',
  CREATE_ADMIN_COMPANY_REQUEST: 'CREATE_ADMIN_COMPANY_REQUEST',
  CREATE_ADMIN_COMPANY_SUCCESS: 'CREATE_ADMIN_COMPANY_SUCCESS',
  CREATE_ADMIN_COMPANY_ERROR: 'CREATE_ADMIN_COMPANY_ERROR',

  GET_COMPANIES_EDITABLE_FIELDS: 'GET_COMPANIES_EDITABLE_FIELDS',

  SET_COMPANIES_EDITABLE_FIELDS: 'SET_COMPANIES_EDITABLE_FIELDS',

  GET_PRODUCER_BY_CLIENT: 'GET_PRODUCER_BY_CLIENT',
  GET_FIELDS: 'GET_FIELDS',
  SET_FIELDS: 'SET_FIELDS',
  SET_FIELD: 'SET_FIELD',

  GET_COMPANY_NS: 'GET_COMPANY_NS',
  GET_COMPANIES_BY_ORDER_ID: 'GET_COMPANIES_BY_ORDER_ID',

  GET_FIELD: 'GET_FIELD',
  GET_FIELD_INVOICE: 'GET_FIELD_INVOICE',
  PATRIMONIAL_REPORT: 'PATRIMONIAL_REPORT',
  GET_BADGES: 'GET_BADGES',
  GET_BADGES_COMPANY: 'GET_BADGES_COMPANY',
  CREATE_BADGE: 'CREATE_BADGE',
  ASSIGN_BADGE: 'ASSIGN_BADGE',
  UNASSIGN_BADGE: 'UNASSIGN_BADGE',
  COMPANY_FIELDS_FORM: 'COMPANY_FIELDS_FORM',
  GET_MAX_DISCOUNT: 'GET_MAX_DISCOUNT',
  DTE_REPORT: 'DTE_REPORT',
  DTE_REPORT_EXCEL: 'DTE_REPORT_EXCEL',
  GET_COMPANIES_MENTORS: 'GET_COMPANIES_MENTORS',
  SET_COMPANIES_MENTORS: 'SET_COMPANIES_MENTORS',
  GET_FIELD_STEP_POS: 'GET_FIELD_STEP_POS',
  GET_FIELD_PRODUCT_REPEAT: 'GET_FIELD_PRODUCT_REPEAT',
  GET_CORRELATIVE_FIELD: 'GET_CORRELATIVE_FIELD',
  SET_CORRELATIVE_FIELD: 'SET_CORRELATIVE_FIELD',
  GET_WITHOUT_TAX_ON_QUOTE_FIELD: 'GET_WITHOUT_TAX_ON_QUOTE_FIELD',
  GET_TAX_PRESS_BELL_QUOTE: 'GET_TAX_PRESS_BELL_QUOTE',
  GET_COMMISSION_ON_QUOTE: 'GET_COMMISSION_ON_QUOTE',
  GET_FIELD_EDIT_PRICE_PURCHASE: 'GET_FIELD_EDIT_PRICE_PURCHASE',

  GET_INVOICE_PACKAGES: 'GET_INVOICE_PACKAGES',
  BUY_INVOICE_PACKAGES: 'BUY_INVOICE_PACKAGES',
  BUY_INVOICE_PACKAGES_REQUEST: 'BUY_INVOICE_PACKAGES_REQUEST',

  GET_COMPANY_DASHBOARD: 'GET_COMPANY_DASHBOARD',
  GET_COMPANY_DATA: 'GET_COMPANY_DATA',
  GET_COMPANY_CURRENT_BALANCE: 'GET_COMPANY_CURRENT_BALANCE',
  ACTIVATE_FREE_ACCOUNT: 'ACTIVATE_FREE_ACCOUNT',
}

const activateSingleCompanyRequest = () => ({
  type: actionTypes.ACTIVE_SINGLE_COMPANY_REQUEST,
})
const activateSingleCompanySuccess = activated => ({
  type: actionTypes.ACTIVE_SINGLE_COMPANY_SUCESS,
  activated,
})
const activateSingleCompanyError = () => ({
  type: actionTypes.ACTIVE_SINGLE_COMPANY_ERROR,
})

const getSingleCompanyRequest = () => ({
  type: actionTypes.GET_SINGLE_COMPANY_REQUEST,
})
const getSingleCompanySuccess = company => ({
  type: actionTypes.GET_SINGLE_COMPANY_SUCCESS,
  company,
})
const getSingleCompanyError = () => ({
  type: actionTypes.GET_SINGLE_COMPANY_ERROR,
})

const deleteSingleCompanyRequest = () => ({
  type: actionTypes.DELETE_SINGLE_COMPANY_REQUEST,
})
const deleteSingleCompanySuccess = compDeleted => ({
  type: actionTypes.DELETE_SINGLE_COMPANY_SUCCESS,
  compDeleted,
})
const deleteSingleCompanyError = () => ({
  type: actionTypes.DELETE_SINGLE_COMPANY_ERROR,
})

const updateSingleCompanyRequest = () => ({
  type: actionTypes.UPDATE_SINGLE_COMPANY_REQUEST,
})
const updateSingleCompanySuccess = company => ({
  type: actionTypes.UPDATE_SINGLE_COMPANY_SUCCESS,
  company,
})
const updateSingleCompanyError = () => ({
  type: actionTypes.UPDATE_SINGLE_COMPANY_ERROR,
})

const createAdminCompanyRequest = () => ({
  type: actionTypes.CREATE_ADMIN_COMPANY_REQUEST,
})
const createAdminCompanySuccess = newCompany => ({
  type: actionTypes.CREATE_ADMIN_COMPANY_SUCCESS,
  newCompany,
})
const createAdminCompanyError = error => ({
  type: actionTypes.CREATE_ADMIN_COMPANY_ERROR,
  error,
})

export const getCompanyNS = companyId => dispatch => {
  const process = async () => ({
    companyNS: await CompaniesServices.getCompanyNS(companyId),
  })
  dispatch(executeAction(actionTypes.GET_COMPANY_NS, process))
}

export const getCompaniesByClient = clientId => dispatch => {
  const process = async () => ({
    producersByClient: await CompaniesServices.getCompaniesByClient(clientId),
  })
  dispatch(executeAction(actionTypes.GET_PRODUCER_BY_CLIENT, process))
}

export const getCurrentCompany = () => async dispatch => {
  const company = getState(selectCurrentCompany)
  if (company.id) return

  const process = async () => ({
    company: await CompaniesServices.getCurrentCompany(),
  })
  dispatch(executeAction(actionTypes.CURRENT_COMPANY, process))
}

export const getCompany = companyId => async dispatch => {
  const process = async () => ({
    company: await CompaniesServices.getCompany(companyId),
  })
  dispatch(executeAction(actionTypes.GET_COMPANY, process))
}

export const getAllCompanies = () => async dispatch => {
  const process = async () => ({
    allCompanies: await CompaniesServices.getAllCompanies(),
  })
  dispatch(executeAction(actionTypes.GET_ALL_COMPANIES, process))
}

export const getPromotorCompanies = () => async dispatch => {
  const process = async () => ({
    promotorCompanies: await CompaniesServices.getPromotorCompanies(),
  })
  dispatch(executeAction(actionTypes.GET_PROMOTOR_COMPANIES, process))
}

export const getCompaniesByType =
  (isProducer, isShipper, isClient, contractType) => async dispatch => {
    const process = async () => ({
      companiesByType: await CompaniesServices.getCompaniesByType(
        isProducer,
        isShipper,
        isClient,
        contractType,
      ),
    })
    dispatch(executeAction(actionTypes.GET_COMPANIES_BY_TYPE, process))
  }

export const activateSingleCompany = idCompany => async dispatch => {
  dispatch(activateSingleCompanyRequest())
  try {
    const response = await CompaniesServices.activateSingleCompany(idCompany)
    dispatch(activateSingleCompanySuccess(response))
  } catch (error) {
    dispatch(activateSingleCompanyError(error))
  }
}

export const getSingleCompany = idCompany => async dispatch => {
  dispatch(getSingleCompanyRequest())
  try {
    const singleCompany = await CompaniesServices.getSingleCompany(idCompany)
    dispatch(getSingleCompanySuccess(singleCompany))
  } catch (error) {
    dispatch(getSingleCompanyError(error))
  }
}

export const deleteSingleCompany = idCompany => async dispatch => {
  dispatch(deleteSingleCompanyRequest())
  try {
    const response = await CompaniesServices.deleteSingleCompany(idCompany)
    dispatch(deleteSingleCompanySuccess(response))
  } catch (error) {
    dispatch(deleteSingleCompanyError(error))
  }
}

export const updateSingleCompany = (idCompany, request) => async dispatch => {
  dispatch(updateSingleCompanyRequest())
  try {
    const company = await CompaniesServices.updateSingleCompany(idCompany, request)
    dispatch(updateSingleCompanySuccess(company))
  } catch (error) {
    dispatch(updateSingleCompanyError(error))
  }
}

export const createAdminCompany = idCompany => async dispatch => {
  dispatch(createAdminCompanyRequest())
  try {
    const newCompany = await CompaniesServices.createSingleCompanyAdmin(idCompany)
    dispatch(createAdminCompanySuccess(newCompany))
  } catch (error) {
    dispatch(createAdminCompanyError(error))
  }
}

export const getEditableFields = () => async dispatch => {
  const process = async () => ({
    response: await CompaniesServices.getEditablesForCompany(),
  })
  dispatch(executeAction(actionTypes.GET_COMPANIES_EDITABLE_FIELDS, process))
}

export const setEditableFields =
  (
    email,
    comercialName,
    comercialAddress,
    phone,
    description,
    history,
    webSite,
    url,
    shortName,
  ) =>
  async dispatch => {
    const process = async () => ({
      response: await CompaniesServices.setEditablesForCompany({
        email,
        comercialName,
        comercialAddress,
        phone,
        description,
        history,
        webSite,
        url,
        shortName,
      }),
    })
    dispatch(executeAction(actionTypes.SET_COMPANIES_EDITABLE_FIELDS, process))
  }

export const updateEditableFields = request => async dispatch => {
  const process = async () => ({
    response: await CompaniesServices.setEditablesForCompany(request),
  })
  dispatch(executeAction(actionTypes.SET_COMPANIES_EDITABLE_FIELDS, process))
}

export const getCompanyFields = () => dispatch => {
  const process = async () => ({
    fields: await CompaniesServices.getCompanyFields(),
  })
  dispatch(executeAction(actionTypes.GET_FIELDS, process))
}

export const setCompanyFields = (list, forms, felData) => dispatch => {
  const process = async () => {
    const fields = await CompaniesServices.setCompanyFields(list)
    if (felData) await ResolutionsServices.createFELData(felData)
    if (!forms) dispatch(getCompanyFields())
    return { fields }
  }
  dispatch(executeAction(actionTypes.SET_FIELDS, process))
}

export const getCompaniesByOrderId = orderId => dispatch => {
  const process = async () => ({
    companies: await CompaniesServices.getCompaniesByOrder(orderId),
  })
  dispatch(executeAction(actionTypes.GET_COMPANIES_BY_ORDER_ID, process))
}

export const getCompaniesByOrders = orders => dispatch => {
  const process = async () => ({
    companies: await CompaniesServices.getCompaniesByOrders(orders),
  })
  dispatch(executeAction(actionTypes.GET_COMPANIES_BY_ORDER_ID, process))
}

export const setCompanyField = (id, value) => dispatch => {
  //const process = async () => ({fields: await CompaniesServices.setCompanyField(id,value)});
  const process = async () => {
    await CompaniesServices.setCompanyField(id, value)
    return { field: await CompaniesServices.getCompanyField(id) }
  }
  dispatch(executeAction(actionTypes.GET_FIELD, process))
}

export const getCompanyField = (id, params) => dispatch => {
  const fields = getState(selectSetCompanyFieldsValues)
  if (fields.some(field => field.id === id)) return

  const process = async () => ({
    field: await CompaniesServices.getCompanyField(id, params),
  })
  dispatch(executeAction(actionTypes.GET_FIELD, process))
}

export const getCompanyFieldStepPOS = (id, params) => dispatch => {
  const process = async () => ({
    field: await CompaniesServices.getCompanyField(93, params),
  })
  dispatch(executeAction(actionTypes.GET_FIELD_STEP_POS, process))
}

export const getCompanyFieldProductRepeat = () => dispatch => {
  const process = async () => ({
    field: await CompaniesServices.getCompanyField(105),
  })
  dispatch(executeAction(actionTypes.GET_FIELD_PRODUCT_REPEAT, process))
}

export const getCompanyFieldEditPricePurchase = () => dispatch => {
  const process = async () => ({
    field: await CompaniesServices.getCompanyField(121),
  })
  dispatch(executeAction(actionTypes.GET_FIELD_EDIT_PRICE_PURCHASE, process))
}

export const getMaxDiscount = () => dispatch => {
  const process = async () => ({
    maxDiscount: await CompaniesServices.getCompanyField(89),
  })
  dispatch(executeAction(actionTypes.GET_MAX_DISCOUNT, process))
}

export const getPatrimonialReport = (sDate, eDate, type, year) => dispatch => {
  const process = async () => ({
    report: await CompaniesServices.getPatrimonialReport(sDate, eDate, type, year),
  })
  dispatch(executeAction(actionTypes.PATRIMONIAL_REPORT, process))
}

export const getBadges = () => dispatch => {
  const process = async () => ({
    badges: await CompaniesServices.getAllBadges(),
  })
  dispatch(executeAction(actionTypes.GET_BADGES, process))
}

export const getBadgesByCompany = companyId => dispatch => {
  const process = async () => ({
    badges: await CompaniesServices.getBadgesByCompany(companyId),
  })
  dispatch(executeAction(actionTypes.GET_BADGES_COMPANY, process))
}

export const assignBadge = (id, companyId) => dispatch => {
  const process = async () => ({
    badges: await CompaniesServices.assingBadges(id, companyId),
  })
  dispatch(executeAction(actionTypes.ASSIGN_BADGE, process))
}

export const UnassignBadge = (id, companyId) => dispatch => {
  const process = async () => ({
    badges: await CompaniesServices.unassingBadges(id, companyId),
  })
  dispatch(executeAction(actionTypes.UNASSIGN_BADGE, process))
}

export const createBadge = request => dispatch => {
  const process = async () => ({
    badges: await CompaniesServices.createBadge(request),
  })
  dispatch(executeAction(actionTypes.CREATE_BADGE, process))
}

export const getCompanyFieldsForm = () => dispatch => {
  const process = async () => ({
    forms: await CompaniesServices.getCompanyFieldsForm(),
  })
  dispatch(executeAction(actionTypes.COMPANY_FIELDS_FORM, process))
}

export const getCorrelativeField = () => dispatch => {
  const process = async () => ({
    correlative: await CompaniesServices.getCorrelativeField(),
  })
  dispatch(executeAction(actionTypes.GET_CORRELATIVE_FIELD, process))
}

export const setCorrelativeField = value => dispatch => {
  const process = async () => ({
    correlative: await CompaniesServices.setCorrelativeField(value),
  })
  dispatch(executeAction(actionTypes.SET_CORRELATIVE_FIELD, process))
}

export const getDTEReport = params => dispatch => {
  const process = async () => ({
    dteReport: await CompaniesServices.getDTEReport(params),
  })
  dispatch(executeAction(actionTypes.DTE_REPORT, process))
}

/**
 * Get issued / nullified DTE report
 * @param {object} params query params (start, end)
 */
export const getDTEReportExcel = params => dispatch => {
  const process = async () => ({
    dteReport: await CompaniesServices.getDTEReportExcel(params),
  })
  dispatch(executeAction(actionTypes.DTE_REPORT_EXCEL, process))
}

export const getCompaniesMentor = params => dispatch => {
  const process = async () => ({
    response: await CompaniesServices.getCompaniesMentor(params),
  })
  dispatch(executeAction(actionTypes.GET_COMPANIES_MENTORS, process))
}

export const setCompaniesMentor = params => dispatch => {
  const process = async () => await CompaniesServices.setCompaniesMentor(params)
  dispatch(executeAction(actionTypes.SET_COMPANIES_MENTORS, process))
}

export const getWithoutTaxOnQuoteField = () => dispatch => {
  const process = async () => ({
    withoutTaxOnQuoteField: await CompaniesServices.getCompanyField(111),
  })
  dispatch(executeAction(actionTypes.GET_WITHOUT_TAX_ON_QUOTE_FIELD, process))
}

export const getTaxPressBellQuoteField = () => dispatch => {
  const process = async () => ({
    taxPressBellField: await CompaniesServices.getCompanyField(109),
  })
  dispatch(executeAction(actionTypes.GET_TAX_PRESS_BELL_QUOTE, process))
}

export const getCommissionOnQuoteField = () => dispatch => {
  const process = async () => ({
    commissionOnQuoteField: await CompaniesServices.getCompanyField(110),
  })
  dispatch(executeAction(actionTypes.GET_COMMISSION_ON_QUOTE, process))
}

export const getInvoicePackages = () => dispatch => {
  const process = async () => ({
    payload: await CompaniesServices.getInvoicePackages(),
  })
  dispatch(executeAction(actionTypes.GET_INVOICE_PACKAGES, process))
}

export const buyInvoicePackages = (invoicePackageId, request) => dispatch => {
  const process = async () => ({
    payload: await CompaniesServices.buyInvoicePackages(invoicePackageId, request),
  })
  dispatch(executeAction(actionTypes.BUY_INVOICE_PACKAGES, process))
}

export const buyInvoicePackagesRequest = (invoicePackageId, request) => dispatch => {
  const process = async () => ({
    payload: await CompaniesServices.buyInvoicePackages(invoicePackageId, request),
  })
  dispatch(executeAction(actionTypes.BUY_INVOICE_PACKAGES_REQUEST, process))
}

export const getCompanyDashboard = params => dispatch => {
  const process = async () => ({
    payload: await CompaniesServices.getCompanyDashboard(params),
  })
  dispatch(executeAction(actionTypes.GET_COMPANY_DASHBOARD, process))
}

export const getCompanyData = () => dispatch => {
  const process = async () => ({
    payload: await CompaniesServices.getCompanyData(),
  })
  dispatch(executeAction(actionTypes.GET_COMPANY_DATA, process))
}

/**
 * Get company balance
 */
export const getCompanyBalance = () => dispatch => {
  const process = async () => ({
    payload: await CompaniesServices.getCompanyBalance(),
  })
  dispatch(executeAction(actionTypes.GET_COMPANY_CURRENT_BALANCE, process))
}

/**
 * Activate free account
 * @param {string} token Unique token for activate account
 */
export const activateFreeAccount = token => dispatch => {
  const process = async () => ({
    payload: await CompaniesServices.activateFreeAccount(token),
  })
  dispatch(executeAction(actionTypes.ACTIVATE_FREE_ACCOUNT, process))
}
