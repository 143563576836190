import React from 'react'

import { Card, Button, Paragraph, Icon } from 'src/components'
import { Col, Row } from 'react-bootstrap'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { formatDateFromMillis } from 'src/utils/formatters'

interface Props {
  items: IRecipeItem[]
  title?: string
  onAdd: () => void
  onEdit: (item: IRecipeItem) => void
  onDelete: (item: IRecipeItem) => void
  disabled: boolean
}

/**
 * List of items selected for the recipe
 * @param {Object} props - Component props
 * @param {IRecipeItem[]} props.items - List of items for the recipe
 * @param {string} props.title - Title of card section
 * @param {function} props.onAdd - Callback indicating that a new item must be added
 * @param {function} props.onEdit - Callback indicating that the item must be edited
 * @param {function} props.onDelete - Callback indicating that the item must be deleted
 * @param {boolean} props.disabled - Disabled all functions
 * */

const RecipeItems = ({ items, title, onAdd, onEdit, onDelete, disabled }: Props) => {
  return (
    <Card title={title} white>
      <div className={'recipe-items-container'}>
        <div className={'recipe-items-main'}>
          <Row>
            {items.map(item => (
              <Col xl={12} key={item.product} className={'b-item'}>
                <div className={'d-flex'}>
                  <div className={'column'} style={{ width: '90%' }}>
                    <Paragraph dim>{item.code}</Paragraph>
                    <Paragraph>{item.name}</Paragraph>
                    <div className={'d-flex'}>
                      <Paragraph>{item.quantity} u</Paragraph>
                      {item.date && (
                        <Paragraph className={'ml-3'}>
                          Vence: {formatDateFromMillis(item.date, null, true)}
                        </Paragraph>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      width: '10%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}>
                    <Icon
                      tooltip={'Editar'}
                      icon={faEdit}
                      size={'1_5x'}
                      onClick={() => onEdit(item)}
                      disabled={disabled}
                    />
                    <Icon
                      tooltip={'Descartar'}
                      icon={faTrash}
                      size={'1_5x'}
                      onClick={() => onDelete(item)}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
        <div className={'d-flex'}>
          <Button style={{ flex: '1' }} onClick={() => onAdd()} disabled={disabled}>
            Agregar ítem
          </Button>
        </div>
      </div>
    </Card>
  )
}
export default RecipeItems
