import 'react-tabs/style/react-tabs.css'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Alert from 'sweetalert-react'
import {
  Modal,
  Row,
  Col,
  ProgressBar,
  Accordion,
  useAccordionButton as useAccordionToggle,
} from 'react-bootstrap'
// import {useAccordionToggle} from "react-bootstrap/AccordionToggle";

import { Button, CustomFilter, FormText, Icon, Switch, Select } from '../../index'
import {
  faKeyboard,
  faSortAmountDown,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons'

import { selectInventoryList } from 'src/selectors/transformation.selector'
import { actionTypes, getInventoryOrigin } from 'src/actions/transformation.actions'

import {
  actionTypes as typeO,
  returnProduct,
  getInventoryAvailableByOrderDetail,
  dispatchOrder,
} from 'src/actions/orders.actions'

import { getWarehouseLite } from 'src/actions/warehouse.actions'
import { selectOwnWarehouseLite } from 'src/selectors/warehouse.selector'

import { loadingSelector } from 'src/selectors/loading.selector'

import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'

import { formatDateFromMillis } from 'src/utils/formatters'
import Money from '../../Money/Money'

function RenderItem({ item, changeValue }) {
  const { id, code, name, items, quantity, necessary, key } = item

  const decoratedOnClick = useAccordionToggle(id, () => undefined)

  const disabled = necessary ? Number(quantity) === Number(necessary) : false
  // type = null -> devolucion normal
  // type = 1 -> creditNote
  // type = 2 -> voucher

  return (
    <li key={id}>
      <div className={'b-item'}>
        <div className={'space-between'} onClick={() => decoratedOnClick()}>
          <div className={'justify-content-start align-items-start bu-en left'}>
            <div className={'b-user-name'}>{name}</div>
            <div className={'b-user-email'}>{code}</div>
            {necessary && <div className={'b-user-email'}>Necesarios: {necessary}</div>}
          </div>
          <div className={'d-flex row align-items-center mr-2 bu-b right'}>
            <div className={'b-user-name mr-1'}>{quantity}</div>
            <Icon icon={faSortAmountDown} size={'2x'} />
          </div>
        </div>
      </div>

      <Accordion.Collapse eventKey={id}>
        <div className={'column'}>
          {items.map((subItem, i) => {
            const { invoice, purchasedPrice, originalQuantity, quantity, expireAt } =
              subItem

            return (
              <div className={'b-item pl-2'} key={i}>
                <div className={'space-between'}>
                  <div className={'justify-content-start align-items-start bu-en left'}>
                    <div className={'b-user-name'}>Factura: {invoice}</div>
                    <Money className={'b-user-email'}>{purchasedPrice}</Money>
                    <div className={'b-user-email'}>
                      Expiración{' '}
                      {expireAt !== null ? formatDateFromMillis(expireAt) : 'sin fecha'}
                    </div>
                  </div>
                  <div
                    className={'justify-content-start align-items-start bu-en'}
                    style={{ textAlign: 'right' }}
                  >
                    <div className={'b-user-name mr-2'}>
                      disponible. {originalQuantity}
                    </div>
                    <div className={'b-user-email mr-2'}>
                      <input
                        disabled={(!quantity || quantity <= 0) && disabled}
                        style={{ width: 100 }}
                        type="number"
                        onFocus={event => event.target.select()}
                        value={quantity}
                        onChange={({ target }) => {
                          let { value } = target
                          if (value < 0) value = ''
                          else if (necessary && Number(value) > Number(necessary))
                            value = necessary

                          if (Number(value) > Number(originalQuantity))
                            value = originalQuantity

                          changeValue(id, i, value, key)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </Accordion.Collapse>
    </li>
  )
}

const ModalOrderReturn = ({ returnType, open, onClose, id, number }) => {
  const dispatch = useDispatch()

  const warehouses = useSelector(selectOwnWarehouseLite)

  const inventorySale = useSelector(selectInventoryList)
  const loading = useSelector(state =>
    loadingSelector([actionTypes.GET_INVENTORY_HISTORY])(state),
  )

  const productsProcessAvailable = useSelector(
    state => state.orders.productsProcessAvailable,
  )
  const loadingP = useSelector(state =>
    loadingSelector([typeO.PRODUCTS_PROCESS_AVAILABLE])(state),
  )

  const loadingReturn = useSelector(state =>
    loadingSelector([typeO.RETURN_PRODUCT])(state),
  )
  const hasErrorReturn = useSelector(state => hasErrors([typeO.RETURN_PRODUCT])(state))

  const loadingDispatch = useSelector(state =>
    loadingSelector([typeO.DISPATCH_ORDER])(state),
  )
  const hasErrorDispatch = useSelector(state => hasErrors([typeO.DISPATCH_ORDER])(state))

  const [actions, setActions] = useState({
    sale: false,
    return: false,
    productsProcess: false,
    dispatch: false,
  })
  const [show, setShow] = useState(false)
  const [search, setSearch] = useState('')
  const [products, setProducts] = useState([])
  const [alert, setAlert] = useState({ title: '' })
  const [type, setType] = useState(null)
  const [code, setCode] = useState('')
  const [warehouse, setWarehouse] = useState({
    value: null,
    label: 'No seleccionado',
  })

  useEffect(() => {
    if (!open) return
    setShow(true)
    setCode('')
    setType(null)
    setWarehouse({ value: null, label: 'No seleccionado' })
    if (returnType === 1) dispatch(getInventoryOrigin(4, id))
    else if (returnType === 2) dispatch(getInventoryAvailableByOrderDetail(id))
    if (!warehouses || warehouses.length === 0) dispatch(getWarehouseLite())
  }, [open])

  useEffect(() => {
    if (loading) setActions({ ...actions, sale: true })
    else if (actions.sale) {
      setActions({ ...actions, sale: false })
      setInitProducts(inventorySale)
    }
  }, [loading])

  useEffect(() => {
    if (loadingP) setActions({ ...actions, productsProcess: true })
    else if (actions.productsProcess) {
      setActions({ ...actions, productsProcess: false })
      setInitProducts(productsProcessAvailable)
    }
  }, [loadingP])

  const setInitProducts = inventories => {
    const items = []

    inventories.forEach(i => {
      const iIndex = items.findIndex(item => item.id === i.productId)

      if (returnType === 2) {
        i.originalQuantity = i.quantity
        i.quantity = 0
      }

      if (iIndex > -1) {
        items[iIndex].items.push(i)
      } else {
        items.push({
          id: i.productId,
          name: i.name,
          code: i.code,
          items: [i],
          quantity: 0,
          necessary: i.necessary,
          conceptId: i.conceptId,
        })
      }
    })
    setProducts(items)
  }

  useEffect(() => {
    if (loadingReturn) setActions({ ...actions, return: true })
    else if (actions.return) {
      setActions({ ...actions, return: false })
      if (hasErrorReturn)
        setAlert({
          ...handlerError(hasErrorReturn.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else {
        onHide(true)
        setAlert({
          ...handlerSuccess('Devolución de producto exitosa.'),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      }
    }
  }, [loadingReturn])

  useEffect(() => {
    if (loadingDispatch) setActions({ ...actions, dispatch: true })
    else if (actions.dispatch) {
      setActions({ ...actions, dispatch: false })
      if (hasErrorDispatch)
        setAlert({
          ...handlerError(hasErrorDispatch.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else {
        onHide(true)
        setAlert({
          ...handlerSuccess('Despacho de producto exitosa.'),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      }
    }
  }, [loadingDispatch])

  const onHide = success => {
    setShow(false)
    setProducts([])
    onClose(success)
  }

  const getItems = valNecessary => {
    const response = products.map((p, index) => {
      let quantity = 0
      p.items.forEach(i => {
        if (!isNaN(i.quantity)) quantity += Number(i.quantity)
      })

      return { ...p, quantity, key: index }
    })

    if (valNecessary) return response.filter(r => Number(r.necessary) === r.quantity)
    else return response
  }

  const onSave = () => {
    const inventories = []
    products.forEach(p => {
      p.items.forEach(i => {
        if (!isNaN(i.quantity) && Number(i.quantity) > 0) {
          inventories.push({
            productId: p.id,
            inventoryId: i.id,
            quantity: i.quantity,
            invoice: i.invoice,
            expiredAt: i.expireAt ? formatDateFromMillis(i.expireAt) : null,
            conceptId: p.conceptId,
          })
        }
      })
    })

    if (returnType === 1)
      dispatch(returnProduct(id, inventories, type, code, warehouse.value))
    else dispatch(dispatchOrder(id, inventories))
  }

  const validate = () => {
    if (returnType === 1)
      return getItems().filter(i => i.quantity > 0).length <= 0 || !warehouse.value
    else {
      return getItems(true).length !== products.length
    }
  }

  return (
    <div>
      <Modal show={show} centered size={'lg'} onHide={() => undefined}>
        <Modal.Header>
          <Modal.Title>
            Orden: {number},{' '}
            {returnType === 1 ? 'devolución de producto' : 'Despacho de productos'}.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              {returnType === 1 && type === null && (
                <h6>
                  Al realizar está acción la existencia vendida retornara a la bodega y la
                  orden de venta quedara en estado Pendiente de despacho.
                </h6>
              )}
              {returnType === 1 && type === 1 && (
                <h6>
                  Al realizar está acción la existencia vendida retornara a la bodega, se
                  generara una Nota de Crédito con la información de la venta y la orden
                  de venta quedara en estado Completada.
                </h6>
              )}
              {returnType === 1 && type === 2 && (
                <h6>
                  Al realizar está acción la existencia vendida retornara a la bodega, se
                  generara un Vale para y la orden de venta quedara en estado Completado.
                </h6>
              )}
              {returnType === 2 && <h6>Despacho de productos.</h6>}
            </Col>
            {loading || loadingP ? (
              <Col xl={12}>
                <div className={'pb-custom'}>
                  <ProgressBar
                    label="Cargando"
                    animated
                    now={100}
                    style={{ marginBottom: 10 }}
                  />
                </div>
              </Col>
            ) : (
              <Col xl={12}>
                <Row>
                  {returnType === 1 && (
                    <Col xl={12}>
                      <Row>
                        <Col xl={4} lg={4} md={6} sm={12}>
                          <FormText
                            value={code}
                            label={'Opcional: Código de devolución'}
                            prepend={<Icon icon={faKeyboard} />}
                            changeValue={value => setCode(value)}
                          />
                        </Col>
                        <Col xl={8} lg={8} md={8} sm={12}>
                          <Select
                            label={'Bodega receptora'}
                            value={warehouse}
                            options={warehouses}
                            onChange={w => setWarehouse(w)}
                            required
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}
                  <Col xl={12}>
                    <CustomFilter
                      placeholder={'Buscar producto'}
                      search={search}
                      onChange={({ target }) => {
                        setSearch(target.value)
                      }}
                      customFilter={<div />}
                    />
                  </Col>
                  <Col xl={12}>
                    <Accordion defaultActiveKey={'0'}>
                      <ul
                        className={'main-list'}
                        style={{ border: '1px solid lightgray' }}
                      >
                        {getItems().map(item => (
                          <RenderItem
                            key={item.key}
                            item={item}
                            changeValue={(productId, subItemIndex, value, key) => {
                              const itemIndex = key
                              let product = products[itemIndex]

                              let total = 0
                              if (returnType === 2) {
                                product.items
                                  .filter((i, index) => index !== subItemIndex)
                                  .forEach(i => {
                                    if (!isNaN(i.quantity)) total += Number(i.quantity)
                                  })
                              }

                              if (
                                !(
                                  returnType === 2 &&
                                  total + (!isNaN(value) ? Number(value) : 0) >
                                    product.necessary
                                )
                              ) {
                                product.items[subItemIndex].quantity = value
                              }

                              products[itemIndex] = product
                              setProducts([...products])
                            }}
                          />
                        ))}
                      </ul>
                    </Accordion>
                  </Col>
                </Row>
                {returnType === 1 && (
                  <Row className={'mt-2'}>
                    <Col xl={6} md={6} sm={6}>
                      <Switch
                        id={'creditNote'}
                        cStyle={{ alignSelf: 'flex-end' }}
                        name={'creditNote'}
                        label={'Agregar nota de crédito'}
                        info={
                          'Para generar una nota de crédito, la orden de venta debe de estar certificada.'
                        }
                        checked={type === 1}
                        onChange={({ target }) => setType(target.checked ? 1 : null)}
                      />
                    </Col>
                    <Col xl={6} md={6} sm={6}>
                      <Switch
                        id={'voucher'}
                        cStyle={{ alignSelf: 'flex-end' }}
                        name={'voucher'}
                        label={'Agregar vale'}
                        checked={type === 2}
                        onChange={({ target }) => setType(target.checked ? 2 : null)}
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              disabled={loadingReturn || loadingDispatch}
              color={'secondary'}
              icon={faWindowClose}
              right
              onClick={() => {
                onHide()
              }}
            >
              Cerrar
            </Button>

            <Button
              loading={loadingReturn || loadingDispatch}
              color={'primary'}
              right
              onClick={() => {
                onSave()
              }}
              disabled={validate()}
            >
              Continuar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Alert {...alert} />
    </div>
  )
}
export default ModalOrderReturn
