import PaymentsServices from '../services/payments.services'
import { executeAction } from './global.actions'

export const actionTypes = {
  GET_SALE_ORDERS_BALANCE: 'GET_SALE_ORDERS_BALANCE',
  GET_SALE_ORDERS_BALANCE_CURRENT_USER: 'GET_SALE_ORDERS_BALANCE_CURRENT_USER',

  GET_BI_PAYMENTS_HISTORY: 'GET_BI_PAYMENTS_HISTORY',
  GET_BI_PAYMENTS_HISTORY_CURRENT_USER: 'GET_BI_PAYMENTS_HISTORY_CURRENT_USER',

  DEMO_PAYMENT: 'DEMO_PAYMENT',
}

export const getSaleOrdersBalance = (companyId, userId) => dispatch => {
  const process = async () => ({
    response: await PaymentsServices.getSaleOrdersBalance(companyId, userId),
  })
  dispatch(executeAction(actionTypes.GET_SALE_ORDERS_BALANCE, process))
}

export const getSaleOrdersBalanceCurrentUser = () => dispatch => {
  const process = async () => ({
    response: await PaymentsServices.getSaleOrdersBalanceCurrentUser(),
  })
  dispatch(executeAction(actionTypes.GET_SALE_ORDERS_BALANCE_CURRENT_USER, process))
}

export const getBIPaymentsHistory =
  (companyId, userId, agreementId, startDate, endDate) => dispatch => {
    const process = async () => ({
      response: await PaymentsServices.getBIPaymentsHistory(
        companyId,
        userId,
        agreementId,
        startDate,
        endDate,
      ),
    })
    dispatch(executeAction(actionTypes.GET_BI_PAYMENTS_HISTORY, process))
  }

export const getBIPaymentsHistoryCurrentUser =
  (agreementId, startDate, endDate) => dispatch => {
    const process = async () => ({
      response: await PaymentsServices.getBIPaymentsHistoryCurrentUser(
        agreementId,
        startDate,
        endDate,
      ),
    })
    dispatch(executeAction(actionTypes.GET_BI_PAYMENTS_HISTORY_CURRENT_USER, process))
  }

export const makeDemoPayment = amount => dispatch => {
  const process = async () => ({
    response: await PaymentsServices.postDemoPayment(amount),
  })
  dispatch(executeAction(actionTypes.DEMO_PAYMENT, process))
}
