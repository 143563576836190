import React, { useState } from 'react'

import { Row, Col, Modal } from 'react-bootstrap'
import { Button, NumberField } from 'src/components'

import ItemsFilter from 'src/components/CustomFilter/ItemsFilter'
import SimpleList from 'src/components/List/SimpleList'

import { haveAnyValue } from 'src/utils/utilitiesV2'
import { toMoney } from 'src/utils/utilities'

interface IPromotionFormItemsProps {
  items: IPromotionItemRequest[]
  onChange: (item: IPromotionItemRequest) => void
  onDelete: (item: IPromotionItemRequest) => void
  error?: string
  disabled?: boolean
}

const PromotionFormItems = ({
  items,
  onChange,
  onDelete,
  error,
  disabled,
}: IPromotionFormItemsProps) => {
  const [item, setItem] = useState<IPromotionItemRequest>({})

  const onSelect = (item: IItemLite) => {
    onSetItem(item.id, item.name, 0)
  }

  const findItemRequest = (id: number): IPromotionItemRequest =>
    items.find(i => i.itemId === id)

  const onChangeSimple = (item: ISimpleList) => {
    const promotionItem = findItemRequest(item.id)
    onSetItem(promotionItem.itemId, promotionItem.name, promotionItem.price)
  }

  const onDeleteSimple = (item: ISimpleList) => {
    const promotionItem = findItemRequest(item.id)
    onDelete(promotionItem)
  }

  const onSetItem = (id: number, name: string, price: number) => {
    setItem({
      itemId: id,
      name,
      price,
    })
  }

  const onCloseModal = () => {
    setItem({})
  }

  const itemsSimple: ISimpleList[] = items.map(item => ({
    id: item.itemId,
    title: item.name,
    subtitle: `Precio: ${toMoney(item.price)}`,
  }))

  return (
    <div>
      <Row>
        <Col xl={12}>
          <ItemsFilter
            label={'Selector de ítems'}
            info={'Selecciona un ítem para agregarlo a la promoción'}
            onSelect={onSelect}
            error={error}
            disabled={disabled}
          />
        </Col>
        <Col xl={12}>
          <SimpleList
            items={itemsSimple}
            onChange={onChangeSimple}
            onDelete={onDeleteSimple}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Modal show={haveAnyValue(item.itemId)} size={'sm'} centered onHide={onCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{item.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <NumberField
                label={'Precio'}
                value={item.price}
                onValueChange={(value: number) => setItem({ ...item, price: value })}
                required
                min={0}
                decimals={2}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={'primary'}
            disabled={!haveAnyValue(item.price)}
            onClick={() => {
              onChange(item)
              onCloseModal()
            }}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default PromotionFormItems
