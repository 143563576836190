import { CashTransferType } from 'src/enums/cashTransferTypes'
import BalanceService from '../services/balance.service'
import { executeAction } from './global.actions'

export const actionTypes = {
  GET_GENERAL_BALANCE: 'GET_GENERAL_BALANCE',
  GET_DETAIL_BALANCE: 'GET_DETAIL_BALANCE',
  GET_DETAIL_BALANCE_COMPANY: 'GET_DETAIL_BALANCE_COMPANY',
  GET_DOCUMENTS_BALANCE: 'GET_DOCUMENTS_BALANCE',
  GET_BALANCE_ENTRIES: 'GET_BALANCE_ENTRIES',
  GET_BALANCE_COMPANY_ENTRIES: 'GET_BALANCE_COMPANY_ENTRIES',
  GET_BALANCE_ENTRY_TYPES: 'GET_BALANCE_ENTRY_TYPES',
  RESET_BALANCE: 'RESET_BALANCE',
  GET_BALANCE_IN_COMPANY: 'GET_BALANCE_IN_COMPANY',
  GET_ADMIN_BALANCE_IN_COMPANY: 'GET_ADMIN_BALANCE_IN_COMPANY',
  GET_BALANCE_IN_COMPANY_KOLO: 'GET_BALANCE_IN_COMPANY_KOLO',
  GET_USERS_BALANCE: 'GET_USERS_BALANCE',
  CREATE_USER_ENTRY: 'CREATE_USER_ENTRY',
  GET_BALANCE_COMPANIES_KOLO: 'GET_BALANCE_COMPANIES_KOLO',
  ADD_USER_BALANCE_COMPANY_KOLO: 'ADD_USER_BALANCE_COMPANY_KOLO',
  GET_KOLO_COMPANY_BALANCE: 'GET_KOLO_COMPANY_BALANCE',
  GET_KOLO_BY_COMPANY_BALANCE: 'GET_KOLO_BY_COMPANY_BALANCE',
  GET_COMPANY_BALANCE: 'GET_COMPANY_BALANCE',
  GET_USER_COMPANY_BALANCE: 'GET_USER_COMPANY_BALANCE',
  GET_COMPANY_BALANCE_REPORT: 'GET_COMPANY_BALANCE_REPORT',
  GET_SENT_CASH_TRANSFERS: 'GET_SENT_CASH_TRANSFERS',
  GET_RECEIVED_CASH_TRANSFERS: 'GET_RECEIVED_CASH_TRANSFERS',
  CREATE_CASH_TRANSFER: 'CREATE_CASH_TRANSFER',
  APPROVE_CASH_TRANSFER: 'APPROVE_CASH_TRANSFER',
  CANCEL_CASH_TRANSFER: 'CANCEL_CASH_TRANSFER',
}

export const getBalance = () => dispatch => {
  const process = async () => ({
    balance: await BalanceService.getGeneralBalance(),
  })
  dispatch(executeAction(actionTypes.GET_GENERAL_BALANCE, process))
}

export const getBalanceDetails = () => dispatch => {
  const process = async () => ({
    balanceDetails: await BalanceService.getBalanceDetails(),
  })
  dispatch(executeAction(actionTypes.GET_DETAIL_BALANCE, process))
}

export const getBalanceDetailsCompany = idCompany => dispatch => {
  const process = async () => ({
    balanceDetailsCompany: await BalanceService.getBalanceDetailsCompany(idCompany),
  })
  dispatch(executeAction(actionTypes.GET_DETAIL_BALANCE_COMPANY, process))
}

export const getBalanceDocuments = (sDate, eDate) => dispatch => {
  const process = async () => ({
    balanceDocuments: await BalanceService.getBalanceDocuments(sDate, eDate),
  })
  dispatch(executeAction(actionTypes.GET_DOCUMENTS_BALANCE, process))
}

export const getBalanceEntryTypes = () => dispatch => {
  const process = async () => ({
    balanceEntryTypes: await BalanceService.getBalanceEntryTypes(),
  })
  dispatch(executeAction(actionTypes.GET_BALANCE_ENTRY_TYPES, process))
}

export const getBalanceEntries = (type, params) => dispatch => {
  const process = async () => ({
    balanceEntries: await BalanceService.getBalanceEntries(type, params),
  })
  dispatch(executeAction(actionTypes.GET_BALANCE_ENTRIES, process))
}

export const getBalanceCompanyEntries = (type, params) => dispatch => {
  const process = async () => ({
    balanceEntries: await BalanceService.getBalanceEntriesCompany(type, params),
  })
  dispatch(executeAction(actionTypes.GET_BALANCE_COMPANY_ENTRIES, process))
}

export const getBalanceInCompany = (userId, type) => dispatch => {
  const process = async () => ({
    balanceInCompany: await BalanceService.getBalanceInCompany({
      userId,
      type,
    }),
  })
  dispatch(executeAction(actionTypes.GET_BALANCE_IN_COMPANY, process))
}

export const getBalanceInCompanyWithKolo = () => dispatch => {
  const process = async () => ({
    balanceInCompanyKolo: await BalanceService.getBalanceInCompanyWithKolo(),
  })
  dispatch(executeAction(actionTypes.GET_BALANCE_IN_COMPANY_KOLO, process))
}

export const getUsersBalanceInCompany = module => dispatch => {
  const process = async () => ({
    usersBalance: await BalanceService.getBalanceUsers(module),
  })
  dispatch(executeAction(actionTypes.GET_USERS_BALANCE, process))
}

export const resetBalanceForAdmin = idCompany => dispatch => {
  const process = async () => ({
    reset: await BalanceService.resetBalanceCompany(idCompany),
  })
  dispatch(executeAction(actionTypes.RESET_BALANCE, process))
}

export const createUserBalanceEntry = (user, debit, amount, message) => dispatch => {
  const process = async () => ({
    userBalanceEntries: await BalanceService.createUserBalanceEntry(
      user,
      debit,
      amount,
      message,
    ),
  })
  dispatch(executeAction(actionTypes.CREATE_USER_ENTRY, process))
}

export const getCompaniesBalanceKolo = () => dispatch => {
  const process = async () => ({
    balanceCompanies: await BalanceService.getCompanyBalances(),
  })
  dispatch(executeAction(actionTypes.GET_BALANCE_COMPANIES_KOLO, process))
}

export const addAdminUserCompanyBalanceEntry =
  (user, company, amount, type, module) => dispatch => {
    const process = async () => ({
      userBalanceEntries: await BalanceService.addAdminUserCompanyBalanceEntry(
        user,
        company,
        amount,
        type,
        module,
      ),
    })
    dispatch(executeAction(actionTypes.ADD_USER_BALANCE_COMPANY_KOLO, process))
  }

export const getAdminUserEntiesInCompany = (user, type) => dispatch => {
  const process = async () => ({
    userEntriesInCompany: await BalanceService.getBalanceEntriesUserInCompany(user, type),
  })
  dispatch(executeAction(actionTypes.GET_ADMIN_BALANCE_IN_COMPANY, process))
}

export const onSetMaxCpcInUserBalance = (userId, maxCpc) => dispatch => {
  if (maxCpc) maxCpc = Number(maxCpc)
  const process = async () => ({
    balanceInCompany: await BalanceService.onSetMaxCpcInUserBalance(userId, maxCpc),
  })
  dispatch(executeAction(actionTypes.GET_BALANCE_IN_COMPANY, process))
}

export const getKoloCompanyBalance = () => dispatch => {
  const process = async () => ({
    koloCompaniesBalance: await BalanceService.getKoloCompanyBalance(),
  })
  dispatch(executeAction(actionTypes.GET_KOLO_COMPANY_BALANCE, process))
}

export const getKoloBalanceByCompanyAndModule =
  (companyId, moduleId, params) => dispatch => {
    const process = async () => ({
      koloCompanyBalance: await BalanceService.getKoloBalanceByCompanyAndModule(
        companyId,
        moduleId,
        params,
      ),
    })
    dispatch(executeAction(actionTypes.GET_KOLO_BY_COMPANY_BALANCE, process))
  }

export const getCompanyBalanceByType = params => dispatch => {
  if (params.user) {
    dispatch(getBalanceInCompany(params.userId, params.kolo ? 1 : 0))
  }
  const process = async () => ({
    response: await BalanceService.getCompanyBalanceByType(params),
  })
  dispatch(
    executeAction(
      params.user
        ? actionTypes.GET_USER_COMPANY_BALANCE
        : actionTypes.GET_COMPANY_BALANCE,
      process,
    ),
  )
}

export const getCompanyBalanceReport = params => dispatch => {
  const process = async () => ({
    balanceReportResponse: await BalanceService.getCompanyBalanceReport(params),
  })
  dispatch(executeAction(actionTypes.GET_COMPANY_BALANCE_REPORT, process))
}

export const getChildrenByParent = parentId => dispatch => {
  const process = async () => ({
    registers: await BalanceService.getChildrenByParent(parentId),
  })
  dispatch(executeAction(actionTypes.GET_COMPANY_BALANCE_REPORT_CHILDREN, process))
}

/**
 * Retrieves sent cash transfers using the provided request object.
 * @param {Object} request - The request object containing the necessary parameters.
 */
export const getSentCashTransfers = request => dispatch => {
  const process = async () => ({
    payload: await BalanceService.getCashTransfers({
      ...request,
      type: CashTransferType.SENT,
    }),
  })
  dispatch(executeAction(actionTypes.GET_SENT_CASH_TRANSFERS, process))
}

/**
 * Retrieves received cash transfers using the provided request object.
 * @param {Object} request - The request object containing the necessary parameters.
 */
export const getReceivedCashTransfers = request => dispatch => {
  const process = async () => ({
    payload: await BalanceService.getCashTransfers({
      ...request,
      type: CashTransferType.RECEIVED,
    }),
  })
  dispatch(executeAction(actionTypes.GET_RECEIVED_CASH_TRANSFERS, process))
}

/**
 * Creates a cash transfer using the provided request object.
 * @param {Object} request - The request object containing the necessary parameters.
 */
export const createCashTransfer = request => dispatch => {
  const process = async () => ({
    payload: await BalanceService.createCashTransfer(request),
  })
  dispatch(executeAction(actionTypes.CREATE_CASH_TRANSFER, process))
}

/**
 * Approves a cash transfer with the specified ID
 * @param {number} id - The ID of the cash transfer to be approved.
 */
export const approveCashTransfer = id => dispatch => {
  const process = async () => ({
    payload: await BalanceService.approveCashTransfer(id),
  })
  dispatch(executeAction(actionTypes.APPROVE_CASH_TRANSFER, process))
}

/**
 * Cancels a cash transfer with the specified ID
 * @param {number} id - The ID of the cash transfer to be canceled.
 */
export const cancelCashTransfer = id => dispatch => {
  const process = async () => ({
    payload: await BalanceService.cancelCashTransfer(id),
  })
  dispatch(executeAction(actionTypes.CANCEL_CASH_TRANSFER, process))
}
