import React, { Component } from 'react'
import { connect } from 'react-redux'
import './CotizapCompanies.scss'
import { Td, Tr } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import {
  ButtonGroup,
  Col,
  DropdownButton,
  Modal,
  Dropdown,
  Row,
  Table,
} from 'react-bootstrap'
import {
  actionTypes,
  activateSingleCompany,
  assignBadge,
  createBadge,
  getAllCompanies,
  getBadges,
  getBadgesByCompany,
  UnassignBadge,
  getDTEReport,
} from '../../../actions/company.actions'
import {
  selectAllCompanies,
  selectBadges,
  selectCompanyBadges,
  selectDTEReport,
} from '../../../selectors/company.selector'
import Card from '../../../components/cards/Card'
import Button from '../../../components/buttons/Button'
import NewCompany from '../NewCompany/NewCompany'
import { loadingSelector } from '../../../selectors/loading.selector'
import { formatNumberWithCommas } from '../../../utils/formatters'
import { history } from '../../../App'
import Gallery, { imageTypes } from '../../../components/gallery/Gallery'
import FormTextField from '../../../components/inputs/FormTextField/FormTextField'
import plus from '../../../assets/images/plus.svg'
import {
  Action,
  Title,
  Switch,
  Select,
  TableV2,
  Dropdown as Drop,
} from '../../../components'
import IconButton from '../../../components/buttons/IconButton'
import { faIdBadge } from '@fortawesome/free-solid-svg-icons'
import {
  actionTypes as action,
  assignCategorizationToEntity,
  getAllCategorizations,
  getCategorizationsByEntity,
} from '../../../actions/categorization.actions'
import { selectAllCategorizations } from '../../../selectors/categorizations.selector'
import Folder from '../../../components/folders/Folder'
import {
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
  singleErrorSelector,
} from '../../../selectors/error.selector'

import { getAllAppModules } from '../../../actions/modules.actions'
import { selectModules } from '../../../selectors/modules.selector'

import {
  actionTypes as typeO,
  getTypesAvailable,
  getLinksByCompany,
} from '../../../actions/offers.actions'
import { selectOfferLinkTypeAvailable } from '../../../selectors/offers.selector'
import Alert from 'sweetalert-react'
import { setParams } from '../../../actions/subscriptions.actions'

import { toMoney } from '../../../utils/utilities'

const today = new Date()

class CotizapCompanies extends Component {
  state = {
    active: true,
    inactive: false,
    showBadges: false,
    showAllBadges: false,
    companyId: 0,
    showCreate: false,
    images: [],
    request: { name: '', description: '', logo: '', url: '' },
    showCategorizations: false,
    alert: {
      show: false,
      title: 'default',
      text: 'default',
      type: 'info',
      onConfirm: () => console.log('default'),
    },
    module: { value: null, label: '- Todos -' },
    link: { value: null, label: '- Todos -' },
    sort: { value: 1, label: 'Saldos' },
    seeLink: { show: false },
    dateFrom: new Date(today.getFullYear(), today.getMonth(), 1),
    dateTo: new Date(),
    modalDTE: false,
  }

  componentDidMount() {
    const {
      getAllCompanies,
      getBadges,
      getAllCategorization,
      getModulesForAdmin,
      getTypesAvailable,
    } = this.props

    getAllCompanies()
    getBadges()
    getAllCategorization()
    getModulesForAdmin()
    getTypesAvailable()
  }

  UNSAFE_componentWillReceiveProps(next) {
    const {
      assignedLoading,
      createIsLoading,
      changeStateLoading,
      addIsLoading,
      addHasError,
      handlerError,
      handlerSuccess,
    } = this.props
    const { addError } = next
    let { alert } = this.state

    if (assignedLoading && !next.assignedLoading) this.setState({ showBadges: true })
    if (createIsLoading && !next.createIsLoading) {
      this.props.getBadges()
      this.setState({ showCreate: false })
    }
    if (changeStateLoading && !next.changeStateLoading) {
      this.props.getBadgesByCompany(this.state.companyId)
    }
    if (addIsLoading && !next.addIsLoading) {
      if (!addHasError && next.addHasError) {
        alert = handlerError(
          addError.response
            ? addError.response.data.message
            : 'ha ocurrido un error inesperado.',
        )
        alert.onConfirm = () => {
          this.setState({ alert: { ...alert, show: false } })
        }
      } else {
        alert = handlerSuccess('Categorías actualizadas')
        alert.onConfirm = () => {
          this.setState({ alert: { ...alert, show: false } })
        }
        this.props.getCategorizationsByCompany(this.state.companyId)
      }

      this.setState({ alert })
    }
  }

  onChangeSwitch = (companyId, event) => {
    this.props.activateSingleCompany(companyId).then(() => {
      this.props.getAllCompanies()
    })
  }

  handleCheckBoxChange = event => {
    const { name, checked } = event.target
    this.setState({ [name]: checked, page: 1 })
  }

  filter = item => {
    const { active, inactive, module, link } = this.state

    let seeByModule = true
    if (module.value) {
      seeByModule = item.modules.find(m => m === module.value) !== undefined
    }

    let seeByLink = true
    if (link.value) {
      if (link.value === 1000) seeByLink = item.linkTypes.length <= 0
      else seeByLink = item.linkTypes.find(m => m === link.value) !== undefined
    }

    return (
      ((item.active && active) || (!item.active && inactive)) && seeByModule && seeByLink
    )
  }

  getHeaders = () => {
    return [
      {
        label: 'ID',
        show: true,
        value: ['alphanumericId'],
        type: 'text',
        className: 'mini',
      },
      { label: 'NIT', show: true, value: ['nit'], type: 'text', className: 'mini name' },
      {
        label: 'Nombre',
        show: true,
        value: ['name'],
        type: 'text',
        className: 'mini name',
      },
      {
        label: 'Balance Productor',
        show: true,
        value: ['amount'],
        type: 'text',
        className: 'medium',
        select: true,
        custom: item => (
          <>
            Real: {toMoney(item.balanceProducer)} <br />
            Retenido: {toMoney(item.balanceRetProducer)} <br />
            Pendiente: {toMoney(item.balanceDepProducer)}
          </>
        ),
      },
      {
        label: 'Balance Transportista',
        show: true,
        value: ['amount'],
        type: 'text',
        className: 'mini',
        select: true,
        custom: item => (
          <>
            Real: {toMoney(item.balanceTransp)}
            <br />
            Retenido: {toMoney(item.balanceRetTransp)} <br />
            Pendiente: {toMoney(item.balanceDepTransp)}
          </>
        ),
      },
      {
        label: 'Estado',
        show: true,
        value: ['amount'],
        type: 'text',
        className: 'mini',
        select: true,
        custom: item => (
          <Switch
            disabled={false}
            checked={item.active}
            id={item.id}
            onChange={e => this.onChangeSwitch(item.id, e)}
          />
        ),
      },
      {
        config: true,
        show: true,
        label: '',
        className: 'mini center',
        custom: item => (
          <>
            <Drop
              disabled={false}
              items={[
                {
                  show: true,
                  title: 'Editar',
                  action: () => history.push(`/admin/empresas/editar/${item.id}`),
                },
                {
                  show: true,
                  title: 'Histórico',
                  action: () => history.push(`/admin/empresas/history/${item.id}`),
                },
                {
                  show: true,
                  title: 'Ver Insignias',
                  action: () => {
                    this.props.getBadgesByCompany(item.id)
                    this.setState({ companyId: item.id })
                  },
                },
                {
                  show: true,
                  title: 'Ver Categorías',
                  action: () => {
                    this.props.getCategorizationsByCompany(item.id)
                    this.setState({ companyId: item.id, showCategorizations: true })
                  },
                },
                {
                  show: true,
                  title: 'Información de enlaces',
                  action: () => {
                    this.setState({ seeLink: { ...item, show: true } })
                    this.props.getLinksByCompany(item.id)
                  },
                },
                {
                  show: true,
                  title: 'Ver suscripciones',
                  action: () => {
                    this.props.setParams({ id: item.id, type: 2, name: item.name })
                    history.push(`/admin/empresas/suscripciones/detalle`)
                  },
                },
              ]}
            />
          </>
        ),
      },
    ]
  }

  uploadImage = (url, name) => {
    this.setState({
      images: [...this.state.images, { url, original: url, thumbnail: url, name: name }],
    })
  }

  deleteImage = (image, index) => {
    const { images } = this.state
    images.splice(index, 1)
    this.setState({ images: new Array(...images) })
  }

  handleChange = event => {
    const { name, value } = event.target
    const { request } = this.state
    this.setState({ request: { ...request, [name]: value } })
  }

  changeState = item => {
    if (item.assigned) this.props.unassignBadge(item.id, this.state.companyId)
    else this.props.assignBadge(item.id, this.state.companyId)
  }

  assignCategorizationToCompany(categorization) {
    const { companyId } = this.state
    this.props.assignCategorizationToCompany(categorization, companyId)
  }

  customFilter = () => {
    const { active, inactive, module, link, sort, dateFrom, dateTo } = this.state
    const { modules, links } = this.props

    return (
      <Row>
        <Col xl={4} lg={4} md={6} sm={6} className={'borderRight'}>
          <div className={'column'}>
            <Row>
              <Col md={6}>
                <Switch
                  topLabel
                  checked={active}
                  label={'Activas'}
                  name={'active'}
                  onChange={this.handleCheckBoxChange}
                />
              </Col>
              <Col md={6}>
                <Switch
                  topLabel
                  checked={inactive}
                  label={'Inactivas'}
                  name={'inactive'}
                  onChange={this.handleCheckBoxChange}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col xl={4} lg={4} md={6} sm={6} className={'borderRight'}>
          <Select
            mt={0}
            label={'Por modulo'}
            info={'Filtra las empresas que tienen acceso al modulo seleccionado'}
            options={[{ value: null, label: '- Todos -' }, ...modules]}
            value={module}
            onChange={m => this.setState({ module: m })}
          />
        </Col>
        <Col xl={4} lg={4} md={6} sm={6} className={'borderRight'}>
          <Select
            mt={0}
            label={'Por Enlace'}
            info={'Filtra las empresas que tienen un enlace activo del tipo seleccionado'}
            options={[
              { value: null, label: '- Todos -' },
              { value: 1000, label: 'Sin enlaces' },
              ...links,
            ]}
            value={link}
            onChange={m => this.setState({ link: m })}
          />
        </Col>
        <Col xl={4} lg={4} md={6} sm={6} className={'borderRight'}>
          <Select
            mt={0}
            label={'Ordenar por'}
            info={'Ordena las empresas según el criterio seleccionado'}
            options={[
              { value: 1, label: 'Saldos' },
              { value: 2, label: 'Retenido' },
              { value: 3, label: 'Pendiente de depositar' },
            ]}
            value={sort}
            onChange={m => this.setState({ sort: m })}
          />
        </Col>
        <Col xl={2} lg={4} md={6} sm={6}>
          <div className={'column'}>
            <label className={'ftf-form-label left mt-3'}>Reporteria</label>
            <Row
              className={'container-buttons'}
              style={{ paddingBottom: 2, display: 'flex' }}>
              <Button
                style={{ flex: 2 }}
                color={'primary'}
                onClick={() =>
                  this.setState(
                    { modalDTE: true },
                    this.props.getReportDTE({
                      start: dateFrom.valueOf(),
                      end: dateTo.valueOf(),
                    }),
                  )
                }>
                Facturas emitidas
              </Button>
            </Row>
          </div>
        </Col>
        <hr />
      </Row>
    )
  }

  getItems = () => {
    const { allCompanies } = this.props
    const { sort } = this.state
    if (sort.value === 1) {
      return allCompanies
        .filter(this.filter)
        .sort(
          (a, b) =>
            a.balanceProducer + a.balanceTransp - (b.balanceProducer + b.balanceTransp),
        )
    } else if (sort.value === 2) {
      return allCompanies
        .filter(this.filter)
        .sort(
          (a, b) =>
            a.balanceRetProducer +
            a.balanceRetTransp -
            (b.balanceRetProducer + b.balanceRetTransp),
        )
    } else {
      return allCompanies
        .filter(this.filter)
        .sort(
          (a, b) =>
            b.balanceDepProducer +
            b.balanceDepTransp -
            (a.balanceDepProducer + a.balanceDepTransp),
        )
    }
  }

  render() {
    const {
      allCompaniesIsLoading,
      badges,
      allBadges,
      categorization,
      assignedIsLoading,
      invoiceType,
    } = this.props
    const {
      showCreate,
      images,
      showBadges,
      request,
      showAllBadges,
      showCategorizations,
      seeLink,
      modalDTE,
      dateFrom,
      dateTo,
    } = this.state

    return (
      <div>
        <Title
          title={'Empresas'}
          action
          to={'nueva'}
          actionTitle={'Crear nueva empresa'}
        />
        <Row>
          <Col sm={12} md={3}>
            <div>
              <Modal
                show={this.state.showNew}
                size="lg"
                centered
                onHide={() => this.setState({ showNew: false })}>
                <Modal.Header closeButton>
                  <Modal.Title>Nueva Empresa</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <NewCompany />
                </Modal.Body>
              </Modal>
            </div>
          </Col>
          <Col lg={12}>
            <Action to={'nueva'} action actionTitle={'Crear nueva empresa'} />
            <Card
              title={'Empresas'}
              white
              button={
                <IconButton
                  icon={faIdBadge}
                  tooltip={'Ver Insignias'}
                  onClick={() => this.setState({ showAllBadges: true })}
                />
              }>
              <TableV2
                items={this.getItems()}
                loading={allCompaniesIsLoading}
                customFilter={this.customFilter()}
                headers={this.getHeaders()}
                mobileAuto
                storageKey={`cotizapCompanies`}
              />
            </Card>
          </Col>
        </Row>

        <Folder
          loading={assignedIsLoading}
          onHide={() => this.setState({ showCategorizations: false })}
          onAssign={item => this.assignCategorizationToCompany(item.id)}
          data1={
            categorization && categorization.children ? categorization.children[0] : {}
          }
          data2={
            categorization && categorization.children ? categorization.children[1] : {}
          }
          show={showCategorizations}
        />

        <Modal
          show={showAllBadges}
          size={'lg'}
          centered
          onHide={() => this.setState({ showAllBadges: false })}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span>Insignias Creadas</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {allBadges &&
              allBadges.map((b, index) => (
                <Col sm={12} md={4} key={`${index}`}>
                  <Card style={{ height: 350 }} statusColor={'blue'}>
                    <Row className={'no-margins center'}>
                      <img
                        className={'badge-image'}
                        style={{ cursor: 'default' }}
                        src={b.logo}
                        alt=""
                      />
                    </Row>
                    <Row className={'no-margins center'}>
                      <h4 style={{ marginTop: 5 }}>
                        <b>{b.name}</b>
                      </h4>
                    </Row>
                    <Row className={'no-margins center'}>
                      <label style={{ marginTop: 5 }}>{b.description}</label>
                    </Row>
                  </Card>
                </Col>
              ))}
            <Col sm={12} md={4} onClick={() => this.setState({ showCreate: true })}>
              <Card style={{ height: 350, backgroundColor: 'rgba(100,229,85,0.7)' }}>
                <Row className={'no-margins center'}>
                  <img className={'badge-image'} src={plus} alt="" />
                </Row>
                <Row className={'no-margins center'}>
                  <h4 style={{ marginTop: 5 }}>
                    <b>CREAR NUEVA INSIGNIA</b>
                  </h4>
                </Row>
              </Card>
            </Col>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Modal
          show={showBadges}
          size={'lg'}
          centered
          onHide={() => this.setState({ showBadges: false })}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span>Insignias de la Empresa</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {badges &&
              badges.map((b, index) => (
                <Col sm={12} md={4} key={`${index}`} onClick={() => this.changeState(b)}>
                  <Card
                    status={b.assigned ? 'Asignada' : 'Sin Asignar'}
                    style={{ height: 350 }}
                    statusColor={b.assigned ? 'green' : 'red'}>
                    <Row className={'no-margins center'}>
                      <img className={'badge-image'} src={b.logo} alt="" />
                    </Row>
                    <Row className={'no-margins center'}>
                      <h4 style={{ marginTop: 5 }}>{b.name}</h4>
                    </Row>
                    <Row className={'no-margins center'}>
                      <label style={{ marginTop: 5 }}>{b.description}</label>
                    </Row>
                  </Card>
                </Col>
              ))}
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Modal
          show={showCreate}
          size={'md'}
          centered
          onHide={() => this.setState({ showCreate: false })}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span>Crear Insignia</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <Gallery
                  imageType={imageTypes.BADGE_ICON}
                  images={images}
                  onUploadSuccess={this.uploadImage}
                  onDelete={this.deleteImage}
                  maxWidth={100}
                  limit={1}
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <FormTextField
                  id={'name'}
                  label={'Nombre'}
                  placeholder={'Nombre'}
                  type={'text'}
                  name={'name'}
                  value={request.name}
                  onChange={this.handleChange}
                  required
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <FormTextField
                  id={'description'}
                  label={'Descripción'}
                  placeholder={'Descripción'}
                  type={'text'}
                  name={'description'}
                  value={request.description}
                  onChange={this.handleChange}
                  required
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <FormTextField
                  id={'description'}
                  label={'Sitio Web'}
                  placeholder={'Sitio Web'}
                  type={'text'}
                  name={'url'}
                  value={request.url}
                  onChange={this.handleChange}
                  required
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={request.name == '' && request.description == ''}
              onClick={() =>
                this.props.createBadge({
                  ...request,
                  logo: images.length > 0 ? images[0].url : '',
                })
              }>
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={seeLink.show}
          size={'lg'}
          centered
          onHide={() => this.setState({ seeLink: { show: false } })}>
          <Modal.Header closeButton>
            <Modal.Title>Empresa: {seeLink.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xl={12} md={12} sm={12}>
                <h6>
                  Modo de facturación:{' '}
                  {seeLink.koloCertifier
                    ? 'Con certificador KOLO'
                    : invoiceType.value === '1'
                    ? 'FEL'
                    : invoiceType.value === '2'
                    ? 'Impresa'
                    : 'Sin facturar'}
                </h6>
              </Col>
              <Col xl={12} md={12} sm={12}>
                <TableV2
                  headers={[
                    {
                      label: 'Enlace',
                      show: true,
                      value: ['linkName'],
                      type: 'text',
                      className: 'mini',
                    },
                    {
                      label: 'Transporte',
                      show: true,
                      value: ['transportistName'],
                      type: 'text',
                      className: 'mini',
                    },
                    {
                      label: 'Productor',
                      show: true,
                      value: ['producerName'],
                      type: 'text',
                      className: 'mini',
                    },
                    {
                      label: 'Bodega',
                      show: true,
                      value: ['bodegaC'],
                      type: 'text',
                      className: 'mini',
                      custom: item =>
                        (item.bodegaC = item.PWarehouseName || 'Sin asignar'),
                    },
                    { config: true, show: true, label: '', className: 'mini center' },
                  ]}
                  loading={this.props.loadingL}
                  items={this.props.companyLinks}
                  noItemsLegend={'No se encontro enlaces con los filtros aplicados'}
                  mobileAuto
                  storageKey={`modeFacturation`}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          show={seeLink.show}
          size={'lg'}
          centered
          onHide={() => this.setState({ seeLink: { show: false } })}>
          <Modal.Header closeButton>
            <Modal.Title>Empresa: {seeLink.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xl={12} md={12} sm={12}>
                <h6>
                  Modo de facturación:{' '}
                  {seeLink.koloCertifier
                    ? 'Con certificador KOLO'
                    : invoiceType.value === '1'
                    ? 'FEL'
                    : invoiceType.value === '2'
                    ? 'Impresa'
                    : 'Sin facturar'}
                </h6>
              </Col>
              <Col xl={12} md={12} sm={12}>
                <TableV2
                  headers={[
                    {
                      label: 'Enlace',
                      show: true,
                      value: ['linkName'],
                      type: 'text',
                      className: 'mini',
                    },
                    {
                      label: 'Transporte',
                      show: true,
                      value: ['transportistName'],
                      type: 'text',
                      className: 'mini',
                    },
                    {
                      label: 'Productor',
                      show: true,
                      value: ['producerName'],
                      type: 'text',
                      className: 'mini',
                    },
                    {
                      label: 'Bodega',
                      show: true,
                      value: ['bodegaC'],
                      type: 'text',
                      className: 'mini',
                      custom: item =>
                        (item.bodegaC = item.PWarehouseName || 'Sin asignar'),
                    },
                    { config: true, show: true, label: '', className: 'mini center' },
                  ]}
                  loading={this.props.loadingL}
                  items={this.props.companyLinks}
                  noItemsLegend={'No se encontro enlaces con los filtros aplicados'}
                  mobileAuto
                  storageKey={`modeFacturation`}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          show={modalDTE}
          size={'md'}
          centered
          onHide={() => this.setState({ modalDTE: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Reporte de DTE emitidas y anuladas</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xl={12} md={12} sm={12}>
                <TableV2
                  dateFilter
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                  onDateChange={(start, end) =>
                    this.setState(
                      { dateFrom: start, dateTo: end },
                      this.props.getReportDTE({
                        start: start.setHours(0, 0, 0).valueOf(),
                        end: end.setHours(23, 59, 59).valueOf(),
                      }),
                    )
                  }
                  loading={this.props.loadingR}
                  items={this.props.reportDTE}
                  mobileAuto
                  storageKey={`reportDTE`}
                  headers={[
                    {
                      label: 'Empresa',
                      show: true,
                      value: ['companyName'],
                      type: 'text',
                      className: 'large',
                    },
                    {
                      label: 'DTE Emitidas',
                      show: true,
                      value: ['issued'],
                      type: 'text',
                      className: 'medium',
                    },
                    {
                      label: 'DTE Anuladas',
                      show: true,
                      value: ['anulated'],
                      type: 'wholeNumber',
                      className: 'medium',
                    },
                    { config: true, show: true, label: '', className: 'mini center' },
                  ]}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Alert {...this.state.alert} />
      </div>
    )
  }
}

CotizapCompanies.defaultProps = {
  allCompanies: [],
  filteredCompanies: [],
}

const mapStateToProps = state => ({
  allCompanies: selectAllCompanies(state),
  reportDTE: selectDTEReport(state),
  allCompaniesIsLoading: loadingSelector([actionTypes.GET_ALL_COMPANIES])(state),
  allBadges: selectBadges(state),
  badges: selectCompanyBadges(state),
  assignedLoading: loadingSelector([actionTypes.GET_BADGES_COMPANY])(state),
  createIsLoading: loadingSelector([actionTypes.CREATE_BADGE])(state),
  changeStateLoading: loadingSelector([
    actionTypes.ASSIGN_BADGE,
    actionTypes.UNASSIGN_BADGE,
  ])(state),
  //categorization
  categorization: selectAllCategorizations(state),
  categorizationLoading: loadingSelector([action.GET_ALL])(state),
  assignedIsLoading: loadingSelector([action.GET_ASSIGNED])(state),
  addIsLoading: loadingSelector([action.ADD_ENTITY_CATEGORIZATION])(state),
  addHasError: hasErrorsSelector([action.ADD_ENTITY_CATEGORIZATION])(state),
  addError: singleErrorSelector([action.ADD_ENTITY_CATEGORIZATION])(state),
  //general
  handlerError: message => handlerError(message),
  handlerSuccess: message => handlerSuccess(message),
  modules: selectModules(state),
  links: selectOfferLinkTypeAvailable(state),
  companyLinks: state.offers.companyLinks,
  loadingL: loadingSelector([typeO.GET_LINKS_BY_COMPANY])(state),
  loadingR: loadingSelector([actionTypes.DTE_REPORT])(state),
  invoiceType: state.company.field,
})

const mapDispatchToProps = dispatch => ({
  getAllCompanies: () => dispatch(getAllCompanies()),
  activateSingleCompany: id => dispatch(activateSingleCompany(id)),
  getBadges: () => dispatch(getBadges()),
  getBadgesByCompany: companyId => dispatch(getBadgesByCompany(companyId)),
  assignBadge: (id, companyId) => dispatch(assignBadge(id, companyId)),
  unassignBadge: (id, companyId) => dispatch(UnassignBadge(id, companyId)),
  createBadge: request => dispatch(createBadge(request)),
  getAllCategorization: () => dispatch(getAllCategorizations(3)),
  getCategorizationsByCompany: companyId =>
    dispatch(getCategorizationsByEntity('company', companyId)),
  assignCategorizationToCompany: (categorization, id) =>
    dispatch(assignCategorizationToEntity('company', categorization, id, true)),
  getModulesForAdmin: () => dispatch(getAllAppModules()),
  getTypesAvailable: () => dispatch(getTypesAvailable(1, 11000)),
  getLinksByCompany: companyId => dispatch(getLinksByCompany(companyId, 11000)),
  setParams: params => dispatch(setParams(params)),
  getReportDTE: params => dispatch(getDTEReport(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CotizapCompanies)
