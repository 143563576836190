import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import Button from 'src/components/buttons/Button'

import { selectPosConfigurationByType } from 'src/selectors/restaurant.selector'

import { felDocumentType, getFelDocumentType } from 'src/enums/felDocumentTypes'
import { selectCompanyCountry } from 'src/selectors/company.selector'
import Select from 'src/components/inputs/Select/CustomSelect'
import { haveAnyValue } from 'src/utils/utilitiesV2'
import useAllTrue from 'src/hooks/useAllTrue'

const useDocumentFilter = (
  useExportation: boolean,
  validatePosConfiguration: boolean,
  configurations,
) => {
  return (doc: IFelDocumentType): boolean => {
    let see =
      doc.value !== felDocumentType.CREDIT_NOTE &&
      (doc.value !== 11 || (doc.value === 11 && useExportation))
    if (see && validatePosConfiguration && configurations)
      see = configurations[doc.panelConfig]
    return see
  }
}

interface Props {
  value: number
  onChange: (value: number) => void
  loading?: boolean
  disabled?: boolean
  useExportation?: boolean
  validatePosConfiguration?: boolean
  selectedMode?: boolean
  initChange?: boolean
}

/** Render buttons by SV fiscal document
 * @param {number} value fiscal document selected
 * @param {boolean} loading Add loading animation to button with same value
 * @param {boolean} disabled Disabled all buttons
 * @param {function} onChange return new fiscal document selected
 * @param {boolean} useExportation Add exportation button
 * @param {boolean} validatePosConfiguration Add exportation button
 * @param {boolean} selectedMode Add exportation button
 * @param {boolean} initChange Add exportation button
 * */
const SelectFiscalDocumentSV = ({
  value,
  loading = false,
  disabled = false,
  onChange,
  useExportation = false,
  validatePosConfiguration = false,
  selectedMode = false,
  initChange = false,
}: Props) => {
  const country = useSelector(selectCompanyCountry)

  const felDocumentTypes = getFelDocumentType(country.id)

  const configurations = useSelector(state => {
    if (validatePosConfiguration) {
      return felDocumentTypes.reduce((acc, docType) => {
        acc[docType.panelConfig] = selectPosConfigurationByType(
          state,
          docType.panelConfig,
        )
        return acc
      }, {})
    }
    return {}
  })

  const filter = useDocumentFilter(
    useExportation,
    validatePosConfiguration,
    configurations,
  )

  const documentTypes = felDocumentTypes.filter(filter)

  const automaticallyChange = useAllTrue([
    !haveAnyValue(value),
    initChange,
    documentTypes.length > 0,
  ])

  useEffect(() => {
    if (!automaticallyChange) return
    const documentType = documentTypes[0]
    onChange(documentType.value)
  }, [automaticallyChange])

  const numberOfButtons = documentTypes.length

  let gridTemplateColumns = '1fr'
  if (numberOfButtons === 2) gridTemplateColumns = '1fr 1fr'
  else if (numberOfButtons === 3) gridTemplateColumns = '1fr 1fr 1fr'
  else if (numberOfButtons >= 4) gridTemplateColumns = '1fr 1fr 1fr'

  const getDocumentType = (id: number): IFelDocumentType => {
    if (!haveAnyValue(id)) return { value: null, label: 'Sin seleccionar' }
    return documentTypes.find(doc => doc.value === id)
  }

  if (selectedMode)
    return (
      <Select
        label={'Tipo de documento'}
        value={getDocumentType(value)}
        onChange={(documentType: IFelDocumentType) => onChange(documentType.value)}
        options={documentTypes}
        disabled={disabled || loading}
        loading={loading}
        dataCy={'select-fiscal-type'}
      />
    )
  else
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: gridTemplateColumns,
          gridTemplateRows: 'auto',
          flexBasis: '100%',
          gap: '10px',
        }}>
        {documentTypes.map((documentType: ISelectNumber, index: number) => (
          <Button
            key={documentType.value}
            className={'w-100'}
            style={{
              gridColumn: index >= 3 && numberOfButtons === 4 ? '1 / -1' : null,
            }}
            color={value === documentType.value ? 'primary' : 'accent'}
            onClick={() => onChange(documentType.value)}
            dataCy={`button-fiscal-type-${documentType.value}`}
            disabled={disabled || loading}
            loading={loading && documentType.value === value}>
            {documentType.label}
          </Button>
        ))}
      </div>
    )
}
export default SelectFiscalDocumentSV
