import React, { Component } from 'react'
import { connect } from 'react-redux'

import Card from 'src/components/cards/Card'

import { Row, Col } from 'react-bootstrap'
import StepZilla from 'react-stepzilla'

import ExternalTransferForm from './ExternalTransferForm'
import ExternalTransferProduct from './ExternalTransferProduct'

import {
  actionTypes,
  getWarehouseInventory,
  getWarehouses,
  setInformationTransfer,
} from 'src/actions/warehouse.actions'
import {
  getContractedCompanies,
  getContractedByCompanies,
} from 'src/actions/contracts.actions'
import {
  selectAllOwnWarehousesForSelect,
  selectTransferDetail,
} from 'src/selectors/warehouse.selector'

import { loadingSelector } from 'src/selectors/loading.selector'

import { selectCurrentModule } from 'src/selectors/user.selector'

class ExternalTransfer extends Component {
  state = {
    steps: [
      {
        name: 'Información',
        component: <ExternalTransferForm {...this.props} />,
      },
      {
        name: 'Productos',
        component: <ExternalTransferProduct {...this.props} />,
      },
    ],
    module: 1000,
  }

  componentDidMount() {
    const { setInfo, getAllWarehouses, getContractedCompanies, match } = this.props

    let m = 1000
    if (match && match.params && match.params.moduleId) {
      m = Number(match.params.moduleId)
      this.setState({ module: Number(match.params.moduleId) })
    }

    setInfo({
      fromWarehouse: { label: '- Ninguno -', value: null },
      toWarehouse: { label: '- Ninguno -', value: null },
      toCompany: { label: '- Ninguno -', value: null },
      description: '',
      toTransfer: [],
    })
    getAllWarehouses()
    getContractedCompanies(m)
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { loadingW, setInfo, getInventory } = this.props
    const { ownWarehouses, transfer } = props

    if (loadingW && !props.loadingW) {
      if (ownWarehouses.length === 1) {
        transfer.fromWarehouse = ownWarehouses[0]
        setInfo(transfer)
        getInventory(transfer.fromWarehouse.value)
      }
    }
  }

  render() {
    const { steps } = this.state

    return (
      <Card title={'Creación de transferancia externa'}>
        <Row>
          <Col>
            <div className={'step-progress'}>
              <StepZilla
                steps={steps}
                stepsNavigation={false}
                dontValidate={true}
                showNavigation={false}
              />
            </div>
          </Col>
        </Row>
      </Card>
    )
  }
}
const mapStateToProps = state => ({
  ownWarehouses: selectAllOwnWarehousesForSelect(state),
  loadingW: loadingSelector([actionTypes.GET_ALL_WAREHOUSES])(state),
  transfer: selectTransferDetail(state),
  module: selectCurrentModule(state),
})
const mapDispatchToProps = dispatch => ({
  setInfo: transfer => dispatch(setInformationTransfer(transfer)),
  getAllWarehouses: () => dispatch(getWarehouses()),
  getContractedCompanies: module => {
    if (module === 2000) dispatch(getContractedCompanies(10))
    else if (module === 1000) dispatch(getContractedByCompanies(10))
  },
  getInventory: warehouseId => dispatch(getWarehouseInventory(warehouseId, false, false)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ExternalTransfer)
