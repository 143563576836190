import './ClientOrderHistory.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Row, Col, Container } from 'react-bootstrap'
import { faCheck } from '@fortawesome/free-solid-svg-icons/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'

import Card from '../../../components/cards/Card'

import { formatDateFromMillis } from '../../../utils/formatters'

import {
  actionTypes as typeO,
  getAllOrderTypes,
  getClientHistory,
} from '../../../actions/orders.actions'
import {
  selectGetAllOrderStatus,
  selectGetAllOrderTypes,
  selectGetAllSingleClientHistory,
} from '../../../selectors/orders.selector'

import { loadingSelector } from '../../../selectors/loading.selector'
import Money from '../../../components/Money/Money'
import { selectCurrentCurrency } from '../../../selectors/currencies.selector'
import { toMoney } from '../../../utils/utilities'
import { TableV2 } from '../../../components'

class ClientOrderHistory extends Component {
  state = {
    selectedOrder: {},
  }

  componentDidMount() {
    this.setState({ selectedOrder: {} })
    /*const {client} = this.props.match.params;
        if (client) {
            this.props.getClientHistory(client);
        }*/
    if (this.props.id) this.props.getClientHistory(this.props.id)
    this.props.getAllOrderTypes()
  }

  componentWillReceiveProps(next) {
    const { loading } = this.props
    const { orders } = next
    if (loading && !next.loading) {
      if (orders && orders.length > 0) this.setState({ selectedOrder: orders[0] })
    }
  }

  formatDate = date => {
    var current_datetime = new Date(date)
    let formatted_date =
      current_datetime.getDate() +
      '/' +
      (current_datetime.getMonth() + 1) +
      '/' +
      current_datetime.getFullYear()
    return formatted_date
  }

  findStatus = id => {
    const { orderStatus } = this.props
    var found = orderStatus.find(function (element) {
      return element.id === id
    })
    if (found && found.name) return found.name
    return 'N/A'
  }

  findOrderType = id => {
    const { orderTypes } = this.props
    var found = orderTypes.find(function (element) {
      return element.id === id
    })
    if (found && found.name) return found.name
    return 'N/A'
  }

  getTotalDetail = list => {
    let total = 0
    for (let i = 0; i < list.length; i++) {
      total = total + list[i].total * list[i].quantity
    }
    return total
  }

  render() {
    const { orders, loading, currentCurrency } = this.props
    const { selectedOrder } = this.state
    console.log(selectedOrder)

    return (
      <div>
        <h1 className={'dashboard-welcome'}>Historial de ordenes</h1>
        <Row>
          <Col md={5}>
            <Card title={'Historial'}>
              <TableV2
                headers={[
                  {
                    label: '',
                    show: true,
                    select: true,
                    className: 'mini',
                    custom: item =>
                      selectedOrder === item ? <FontAwesomeIcon icon={faCheck} /> : '',
                  },
                  {
                    label: 'Fecha creacion',
                    show: true,
                    value: ['createdAt'],
                    type: 'date',
                    className: 'mini',
                  },
                  {
                    label: 'Tipo',
                    show: true,
                    value: ['typeC'],
                    type: 'text',
                    className: 'mini',
                    custom: item => (item.typeC = this.findOrderType(item.type)),
                  },
                  {
                    label: 'Tipo de pago',
                    show: true,
                    value: ['tipoPagoC'],
                    type: 'text',
                    className: 'medium',
                    custom: item =>
                      (item.tipoPagoC = item.paymentType === 1 ? 'Contado' : 'Credito'),
                  },
                  {
                    label: 'Estado',
                    show: true,
                    value: ['amount'],
                    type: 'text',
                    className: 'status',
                    select: true,
                    custom: item => (
                      <div className={item.deleted ? 'del-text' : 'green-text'}>
                        {this.findStatus(item.status)}
                      </div>
                    ),
                  },
                  { config: true, show: true, label: '', className: 'mini center' },
                ]}
                items={orders}
                loading={loading}
                mobileAuto
                storageKey={`orderHistoryClient`}
                onClickTr={(e, item) => this.setState({ selectedOrder: item })}
              />
            </Card>
          </Col>
          <Col>
            <Card title={'Detalle de la Orden'}>
              {selectedOrder ? (
                <Container>
                  <Row>
                    <Col xs={8}>
                      <h4>
                        <b>Cliente: </b>
                        {selectedOrder.client ? selectedOrder.client.companyName : 'N/A'}
                      </h4>
                    </Col>
                    <Col xs={4} className={'text-center'}>
                      <b>Fecha de confirmación </b>
                      {selectedOrder.date
                        ? formatDateFromMillis(selectedOrder.date)
                        : 'N/A'}
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <h4>
                        <b>Bodega: </b>
                        {selectedOrder.warehouse
                          ? selectedOrder.warehouse.name
                          : 'Sin Bodega'}
                      </h4>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <br />
                    </Col>
                  </Row>
                  {selectedOrder.client && (
                    <>
                      <Row>
                        <Col>
                          <b>Ubicación: </b>
                          {selectedOrder.client ? selectedOrder.client.address : 'N/A'}
                        </Col>
                      </Row>
                      {selectedOrder.client.secondAddress && (
                        <Row>
                          <Col>
                            <b>Dirección: </b>
                            {selectedOrder.client
                              ? selectedOrder.client.secondAddress
                              : 'N/A'}
                          </Col>
                        </Row>
                      )}
                      {selectedOrder.client.reference && (
                        <Row>
                          <Col>
                            <b>Referencia: </b>
                            {selectedOrder.client
                              ? selectedOrder.client.reference
                              : 'N/A'}
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          <br />
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row className="text-center">
                    <Col>
                      <b>Tipo</b>
                      <br />{' '}
                      {selectedOrder.orderTypeData
                        ? selectedOrder.orderTypeData.name
                        : 'N/A'}
                    </Col>
                    <Col>
                      <b>Estado</b> <br />{' '}
                      {selectedOrder.statusData ? selectedOrder.statusData.name : 'N/A'}
                    </Col>
                    <Col>
                      <b>Tipo de venta</b> <br />{' '}
                      {selectedOrder.paymentType
                        ? selectedOrder.paymentType === 1
                          ? 'Contado'
                          : 'Credito'
                        : '---'}
                    </Col>
                    {selectedOrder.enabledAt ? (
                      <Col>
                        <b>Fecha estimada de entrega</b> <br />{' '}
                        {formatDateFromMillis(selectedOrder.enabledAt)}
                      </Col>
                    ) : (
                      ''
                    )}
                  </Row>
                  {selectedOrder.description ? (
                    <Row>
                      <Col>
                        <b>Descripcion</b>
                        <br /> {selectedOrder.description}
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}
                  <Row>
                    <br />
                    <Col className={'text-center'}>
                      {selectedOrder.deleted ? (
                        <b className={'red-text'}>Orden Eliminada</b>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <br />
                    <Col className={'text-center'}>
                      {selectedOrder.presaleDiscProduct ? (
                        <b style={{ color: 'rgba(33,114,29,0.6)', fontSize: 15 }}>
                          Producto entregado
                        </b>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <br />
                    <Col className={'text-center'}>
                      {selectedOrder.presalePayed ? (
                        <b style={{ color: 'rgba(33,114,29,0.6)', fontSize: 15 }}>
                          Orden ya cancelada
                        </b>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                  <TableV2
                    headers={[
                      {
                        label: 'Producto',
                        show: true,
                        value: ['product', 'name'],
                        type: 'text',
                        className: 'mini',
                      },
                      {
                        label: 'Cantidad',
                        show: true,
                        value: ['quantity'],
                        type: 'text',
                        className: 'mini',
                      },
                      {
                        label: 'Precio',
                        show: true,
                        value: ['precioC'],
                        type: 'text',
                        className: 'mini',
                        custom: item =>
                          (item.precioC = toMoney(item.total / item.quantity)),
                      },
                      {
                        label: 'Descuento',
                        show: true,
                        value: ['discount'],
                        type: 'currency',
                        className: 'mini',
                      },
                      {
                        label: 'Sub total',
                        show: true,
                        value: ['subtotal'],
                        type: 'currency',
                        className: 'mini',
                      },
                      { config: true, show: true, label: '', className: 'mini center' },
                    ]}
                    mobileAuto
                    storageKey={`clientOrderHistory1`}
                    items={selectedOrder.details}
                  />
                  <br />
                  {selectedOrder.details ? (
                    <Money component="span" className={'detail-text short'}>
                      <b>Total: </b>
                      {selectedOrder.total}
                    </Money>
                  ) : (
                    ''
                  )}
                </Container>
              ) : (
                ''
              )}
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  orders: selectGetAllSingleClientHistory(state),
  orderTypes: selectGetAllOrderTypes(state),
  orderStatus: selectGetAllOrderStatus(state),
  loading: loadingSelector([typeO.GET_SINGLE_CLIENT_HISTORY])(state),
  currentCurrency: selectCurrentCurrency(state),
})

const mapDispatchToProps = dispatch => ({
  getClientHistory: id => dispatch(getClientHistory(id)),
  getAllOrderTypes: () => dispatch(getAllOrderTypes()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientOrderHistory)
