import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Col, Modal, Row } from 'react-bootstrap'
import {
  Button,
  Card,
  CustomDate,
  CustomTabs,
  Dropdown,
  Folder,
  ListCategories,
  TableV2,
  Title,
  Select,
} from 'src/components'
import { faDownload, faFileExcel, faSearch } from '@fortawesome/free-solid-svg-icons'

import { getAllCategorizations } from 'src/actions/categorization.actions'
import { actionTypes as clienteType, getReportLog } from 'src/actions/clients.actions'
import {
  actionTypes as typesOder,
  getUsersReport,
  getDelivererProductReport,
  getProductReportByUserExcel,
} from 'src/actions/orders.actions'
import {
  actionTypes as reportType,
  getClientsExcelReport,
  getUserExcelReport,
} from 'src/actions/report.actions'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'
import { selectClientReports } from 'src/selectors/clients.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { selectUsersReport, selectProductReport } from 'src/selectors/orders.selector'
import { toMoney } from 'src/utils/utilities'

const userTypes = [
  { value: 1, label: 'Por creación' },
  { value: 2, label: 'Por confirmación' },
]

/**
 * Pantalla de reporte de ventas por usuarios.
 * @component
 * @returns
 */
export const UsersSale = () => {
  const dispatch = useDispatch()

  const loadingUsers = useSelector(state =>
    loadingSelector([typesOder.GET_USERS_REPORT])(state),
  )
  const loadingExcel = useSelector(state =>
    loadingSelector([reportType.GET_EXCEL, typesOder.GET_PRODUCT_REPORT_EXCEL])(state),
  )
  const loadingPdf = useSelector(state => loadingSelector([reportType.GET_PDF])(state))
  const reportLoading = useSelector(state =>
    loadingSelector([clienteType.GET_REPORT_LOG])(state),
  )
  const loadingProducts = useSelector(state =>
    loadingSelector([typesOder.GET_PRODUCT_REPORT])(state),
  )

  const users: IUsersTotals[] = useSelector(selectUsersReport)
  const clients: IUsersClients[] = useSelector(selectClientReports)
  const saleProducts: ISaleProducts[] = useSelector(selectProductReport)
  const categorization = useSelector(selectAllCategorizations)

  const [textFilters, setTextFilters] = useState('')
  const [filters, setFilters] = useState({
    startDate: new Date().setHours(0, 0, 0, 0).valueOf(),
    endDate: new Date().setHours(23, 59, 59, 59).valueOf(),
    selectedCategorization: [],
    userType: 1,
  })
  const [utils, setUtils] = useState({
    showOrders: false,
    showProducts: false,
    orders: [],
    line: true,
    factor: false,
    showDetails: false,
    showCategorization: false,
    key: 0,
    userSelected: null,
  })

  useEffect(() => {
    setUp(utils.key)
    dispatch(getAllCategorizations(16))
    setTextFilters(JSON.stringify(filters))
  }, [])

  /**
   * Consulta la información de los reportes.
   * @param key Tipo de reporte a consultar.
   * @returns None
   */
  const setUp = (key: number) => {
    setTextFilters(JSON.stringify(filters))

    const { startDate, endDate, selectedCategorization, userType } = filters
    if (Number(key) === 0)
      dispatch(
        getUsersReport(startDate, endDate, {
          categories: selectedCategorization.map(p => p.id),
          pos: [],
          userType,
        }),
      )
    else dispatch(getReportLog(startDate, endDate))
  }

  /**
   * @function Agrega o Remueve categorías seleccionadas para el filtrado de los ítems
   * @param {ICategory} item categoría seleccionada por el componente Folder
   * */
  const onAssignCategorizationToFilter = (item: ICategorization) => {
    const customCategories = Object.assign([], filters.selectedCategorization)
    const index = customCategories.findIndex(sc => sc.id === item.id)
    if (index === -1) customCategories.push(item)
    else customCategories.splice(index, 1)
    setFilters({ ...filters, selectedCategorization: customCategories })
  }

  /**
   * Filtra y ordena el detalle de los usuarios.
   * @param {IUsersTotals} array Arreglo de usuarios con sus totales
   * @returns
   */
  const sortInfo = (array: IUsersTotals[]) => {
    const info = []
    array
      .filter(
        r =>
          r.total > 0 ||
          r.visitTotal > 0 ||
          r.deposits > 0 ||
          r.utility > 0 ||
          r.cpp > 0 ||
          r.deliverer > 0,
      )
      .forEach(r => {
        info.push({
          name: r.name,
          visitTotal: r.visitTotal,
          total: r.total,
          payments: r.deposits,
          expenses: r.deliverer,
          rate: r.gratification,
          restaurant: r.cpc,
          quantity: r.quantity,
          id: r.id,
        })
      })

    return info.sort((a, b) => b.total - a.total)
  }

  /**
   * Convierte la lista de categorías a un string.
   * @returns String
   */
  const getCategorizationString = (): string => {
    let categorization = null
    filters.selectedCategorization.forEach(s => {
      if (!categorization) categorization = ''
      categorization += s.id + ','
    })
    return categorization
      ? categorization.substring(0, categorization.length - 1)
      : undefined
  }

  return (
    <div>
      <Title title="Reporte de Ventas por Usuarios" />
      <Row>
        <Col>
          <Card
            title={'Filtros'}
            white
            button={
              <Dropdown
                tooltip={'Reportes'}
                disabled={loadingUsers || reportLoading}
                loading={loadingExcel || loadingPdf}
                items={[
                  {
                    icon: faFileExcel,
                    title: 'Descargar Excel',
                    action: () => {
                      if (Number(utils.key) === 0)
                        dispatch(
                          getUserExcelReport(filters.startDate, filters.endDate, {
                            userType: filters.userType,
                          }),
                        )
                      else
                        dispatch(
                          getClientsExcelReport(filters.startDate, filters.endDate),
                        )
                    },
                  },
                ]}
              />
            }>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12}>
                <CustomDate
                  label={'Desde:'}
                  value={new Date(filters.startDate)}
                  onDayChange={(date: Date) =>
                    setFilters({
                      ...filters,
                      startDate: date?.setHours(0, 0, 0, 0)?.valueOf(),
                    })
                  }
                />
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <CustomDate
                  label={'Hasta:'}
                  value={new Date(filters.endDate)}
                  onDayChange={(date: Date) =>
                    setFilters({
                      ...filters,
                      endDate: date.setHours(23, 59, 59, 59).valueOf(),
                    })
                  }
                  disabledDays={{ before: new Date(filters.startDate) }}
                />
              </Col>

              <Col xl={6} lg={6} md={6} sm={12}>
                <Select
                  label={'Tipo de usuario'}
                  value={userTypes.find(u => u.value === filters.userType)}
                  options={userTypes}
                  onChange={({ value }) => setFilters({ ...filters, userType: value })}
                />
              </Col>

              {/*

              <Col xl={12}>
                <Button
                  style={{ marginTop: 5 }}
                  color={'primary'}
                  onClick={() => setUtils({ ...utils, showCategorization: true })}>
                  Filtrar por categorías
                </Button>
              </Col>*/}
              <Col xl={12}>
                <Row className={'pl-1'}>
                  <ListCategories
                    items={filters.selectedCategorization}
                    onRemove={onAssignCategorizationToFilter}
                  />
                </Row>
              </Col>
              <Col xl={12}>
                <Row className={'container-buttons'}>
                  <Button
                    color={'accent'}
                    icon={faSearch}
                    placement="left"
                    loading={loadingUsers || reportLoading}
                    onClick={() => setUp(utils.key)}>
                    Aplicar Filtros
                  </Button>
                </Row>
              </Col>
            </Row>
          </Card>
          <CustomTabs
            items={[
              {
                title: 'Usuarios',
                component: (
                  <TableV2
                    /** @ts-expect-error js */
                    loading={loadingUsers || reportLoading}
                    headers={[
                      {
                        label: 'Nombre',
                        show: true,
                        value: ['name'],
                        type: 'text',
                        className: 'mini',
                      },
                      {
                        label: 'Ordenes Entregadas',
                        show: true,
                        value: ['quantity'],
                        type: 'text',
                        className: 'mini',
                      },
                      {
                        label: 'Total visitas',
                        show: true,
                        value: ['visitTotal'],
                        type: 'text',
                        className: 'mini',
                      },
                      {
                        label: 'Total vendido',
                        show: true,
                        value: ['total'],
                        type: 'currency',
                        className: 'mini',
                      },
                      {
                        label: 'Utilidad Bruta',
                        show: true,
                        value: ['rate'],
                        type: 'currency',
                        className: 'mini',
                      },
                      {
                        label: 'Gastos',
                        show: true,
                        value: ['expenses'],
                        type: 'currency',
                        className: 'mini',
                      },
                      {
                        label: 'Depósitos',
                        show: true,
                        value: ['payments'],
                        type: 'currency',
                        className: 'mini',
                      },
                      {
                        config: true,
                        show: true,
                        className: 'mini center',
                        type: 'text',
                        value: ['button'],
                        label: 'Acción',
                        custom: item => (
                          <Dropdown
                            items={[
                              {
                                show: true,
                                title: 'Ver productos vendidos',
                                action: () => {
                                  setUtils({
                                    ...utils,
                                    showProducts: true,
                                    userSelected: item.id,
                                  })
                                  dispatch(
                                    getDelivererProductReport(
                                      filters.startDate,
                                      filters.endDate,
                                      item.id,
                                      getCategorizationString(),
                                      filters.userType,
                                    ),
                                  )
                                },
                              },
                            ]}
                          />
                        ),
                      },
                    ]}
                    items={sortInfo(users)}
                    mobileAuto
                    storageKey={`users`}
                  />
                ),
              },
              {
                title: 'Clientes',
                component: (
                  <TableV2
                    /** @ts-expect-error js */
                    headers={[
                      {
                        label: 'Nombre',
                        show: true,
                        value: ['name'],
                        type: 'text',
                        className: 'mini',
                      },
                      {
                        label: 'Email',
                        show: true,
                        value: ['email'],
                        type: 'text',
                        className: 'mini',
                      },
                      {
                        label: 'Clientes Activados',
                        show: true,
                        value: ['activated'],
                        type: 'wholeNumber',
                        className: 'mini',
                      },
                      {
                        label: 'Clientes Inactivos',
                        show: true,
                        value: ['inactivated'],
                        type: 'wholeNumber',
                        className: 'mini',
                      },
                      {
                        label: 'Clientes Editados',
                        show: true,
                        value: ['edited'],
                        type: 'wholeNumber',
                        className: 'mini',
                      },
                      {
                        label: 'Clientes Creados',
                        show: true,
                        value: ['created'],
                        type: 'wholeNumber',
                        className: 'mini',
                      },
                      { config: true, show: true, label: '', className: 'mini center' },
                    ]}
                    items={clients}
                    mobileAuto
                    storageKey={`usersReport`}
                  />
                ),
              },
            ]}
            defaultKey={utils.key}
            setKey={(key: number) => {
              setUtils({ ...utils, key })
              setUp(key)
            }}
          />
        </Col>
      </Row>

      <Modal
        show={utils.showProducts}
        size={'lg'}
        centered
        aria-labelledby={'contained-modal-title-vcenter'}
        onHide={() => setUtils({ ...utils, showProducts: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Productos vendidos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Button
              color={'accent'}
              loading={loadingExcel}
              icon={faDownload}
              onClick={() =>
                dispatch(
                  getProductReportByUserExcel(
                    filters.startDate,
                    filters.endDate,
                    utils.userSelected,
                    getCategorizationString(),
                    filters.userType,
                  ),
                )
              }>
              Descargar excel
            </Button>
          </div>
          <TableV2
            /** @ts-expect-error js */
            headers={[
              {
                label: 'Nombre',
                show: true,
                value: ['product', 'name'],
                type: 'text',
                className: 'mini',
              },
              {
                label: 'Cantidad',
                show: true,
                value: ['cantidadC'],
                type: 'text',
                className: 'mini',
                custom: item =>
                  (item.cantidadC = item.quantity
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')),
              },
              {
                label: 'Comisión',
                show: true,
                type: 'text',
                className: 'mini',
                custom: item =>
                  item?.product?.commissionPercentage !== null
                    ? item.product.commissionPercentage + '%'
                    : toMoney(item.product.commission),
              },
              {
                label: 'Descuento',
                show: true,
                value: ['discount'],
                type: 'currency',
                className: 'mini',
              },
              {
                label: 'Total',
                show: true,
                value: ['total'],
                type: 'currency',
                className: 'mini',
              },
              {
                label: 'Total comisión',
                show: true,
                value: ['totalCommission'],
                type: 'currency',
                className: 'mini',
              },
              { config: true, show: true, label: '', className: 'mini center' },
            ]}
            items={saleProducts.filter(r => r.quantity > 0)}
            mobileAuto
            storageKey={`usersProducts`}
            loading={loadingProducts}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      {/* @ts-expect-error js */}
      <Folder
        noMessage
        onHide={() => setUtils({ ...utils, showCategorization: false })}
        onAssign={onAssignCategorizationToFilter}
        data1={
          categorization && categorization.children ? categorization.children[0] : {}
        }
        data2={
          categorization && categorization.children ? categorization.children[1] : {}
        }
        show={utils.showCategorization}
        list={filters.selectedCategorization.map(s => s.id)}
      />
    </div>
  )
}
