import React, { useState } from 'react'

import { Modal, Row, Col } from 'react-bootstrap'
import { Button, SwitchV2 } from 'src/components'
import CSVLoaderV2, { CATEGORY_UPLOAD } from 'src/components/CSVLoader/CSVLoaderV2'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'

interface IProps {
  bankId: number
  accountId: number
  onSuccess: () => void
}

const BankUpCsv = ({ bankId, accountId, onSuccess }: IProps) => {
  const [open, setOpen] = useState(false)
  const [isBIBanking, setBIBanking] = useState(false)

  const RenderCSVLoader = (
    // @ts-ignore
    <CSVLoaderV2
      labelStyle={{ width: '100%' }}
      warehouseId={accountId}
      hideTemplate
      category={
        bankId === 1
          ? isBIBanking
            ? CATEGORY_UPLOAD.BI_CSV_BANKING
            : CATEGORY_UPLOAD.BI_CSV
          : bankId === 2
          ? CATEGORY_UPLOAD.RURAL_CSV
          : bankId === 3
          ? CATEGORY_UPLOAD.BAM_CSV
          : CATEGORY_UPLOAD.GYT_CSV
      }
      title={'Operaciones con CSV'}
      onSuccess={() => {
        setOpen(false)
        onSuccess()
      }}
    />
  )

  return (
    <div>
      {bankId === 1 ? (
        <Button icon={faCloudUploadAlt} onClick={() => setOpen(true)}>
          Operaciones con CSV
        </Button>
      ) : (
        RenderCSVLoader
      )}

      <Modal show={open} centered onHide={() => setOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Escoge el tipo de banca</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <SwitchV2
                checked={!isBIBanking}
                label={'BI en Linea'}
                info={
                  'Para subir el archivo CSV correctamente, separa el año de la fecha. Cambia el formato así:\n' +
                  'FECHA | TT | DESCRIPCIÓN | NO. DOC | DEBE (Q.) | HABER (Q.) | SALDO (Q.) | AÑO'
                }
                onChange={value => setBIBanking(!value)}
              />
            </Col>

            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <SwitchV2
                checked={isBIBanking}
                label={'BI Banking'}
                info={
                  'Para subir el archivo CSV correctamente, el formato de fecha debe ser dia/mes/año o dia-mes-año:\n' +
                  'FECHA | TT | DESCRIPCIÓN | NO. DOC | DEBE (Q.) | HABER (Q.) | SALDO (Q.)'
                }
                onChange={setBIBanking}
              />
            </Col>

            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              {RenderCSVLoader}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default BankUpCsv
