import { $http } from './http'

class VersionsService {
  getVersions = async () => {
    const response = await $http.get(`/versions`)
    return response.data
  }

  getVersionDetails = async versionId => {
    const response = await $http.get(`/versions/${versionId}`)
    return response.data
  }

  getLastVersion = async () => {
    const response = await $http.get(`/versions/last`)
    return response.data
  }

  saveNewVersion = async version => {
    const response = await $http.post(`/versions`, version)
    return response.data
  }
}

export default new VersionsService()
