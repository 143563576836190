import '../FormTextField/FormTextField.scss'
import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'
import { faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import IconButton from '../../buttons/IconButton'

export default class FormTextArea extends Component {
  render() {
    const {
      label,
      id,
      placeholder,
      name,
      value,
      onChange,
      error,
      info,
      required,
      className,
      cols,
      rows,
      classTextArea,
    } = this.props
    const { ...restProps } = this.props

    return (
      <div
        className={`form-label left input-wrapper has-feedback mt-3 ${
          className ? className : ''
        }`}
      >
        {(label || required) && (
          <label htmlFor={id}>
            {label} &nbsp;
            {info && (
              <label>
                {' '}
                <IconButton tooltip={info} icon={faInfoCircle} size={'0.25x'} />{' '}
              </label>
            )}
            {required && <span style={{ color: 'red', marginRight: 2 }}>&nbsp;* </span>}
          </label>
        )}

        <div>
          <textarea
            style={{ width: '100%' }}
            className={`${classTextArea}`}
            name={name}
            id={id}
            cols={cols || '30'}
            rows={rows || '1'}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            required={required}
            {...restProps}
          />
        </div>
        <span className="input-focus" />
        {error ? (
          <div className="input-error">
            <FontAwesomeIcon icon={faExclamationCircle} /> {error}
          </div>
        ) : (
          <div />
        )}
      </div>
    )
  }
}
