import { $http } from './http'
import { saveAs } from 'file-saver'

class PurchaseService {
  getPurchases = async params => {
    const response = await $http.get(`/purchase`, { params })
    return response.data
  }

  getSummative = async params => {
    const response = await $http.get(`/purchase/company/summative`, { params })
    return response.data
  }

  onCreatePurchase = async (request, commentary, confirmBy) => {
    const response = await $http.post('/purchase', request, {
      params: { commentary, confirmBy },
    })
    return response.data
  }

  onCreateOrderPurchase = async (request, commentary) => {
    const response = await $http.post('/purchase/order', request, {
      params: { commentary },
    })
    return response.data
  }

  onChangeOrderPurchase = async (request, commentary, id, action) => {
    const response = await $http.put(`/purchase/order/${id}`, request, {
      params: { commentary, action },
    })
    return response.data
  }

  onChangeOrderPurchaseV2 = async (request, commentary, id, action) => {
    const response = await $http.put(`/purchase/order/update/${id}`, request, {
      params: { commentary, action },
    })
    return response.data
  }

  getLog = async id => {
    const response = await $http.get(`/purchase/log/${id}`)
    return response.data
  }

  onCreateSimplePurchase = async request => {
    const response = await $http.post('/purchase/simple', request)
    return response.data
  }

  updateSimplePurchase = async (purchaseId, request) => {
    const response = await $http.put(`/purchase/simple/${purchaseId}`, request)
    return response.data
  }

  getPurchase = async id => {
    const response = await $http.get(`/purchase/${id}`)
    return response.data
  }

  onUpdateStatusPurchase = async (purchaseId, status, request) => {
    const response = await $http.put(`/purchase/update/${purchaseId}/${status}`, request)
    return response.data
  }

  onConfirmPurchase = async (purchaseId, request) => {
    const response = await $http.put(`/purchase/confirm/${purchaseId}`, request)
    return response.data
  }

  accreditMovementByPurchase = async (purchaseId, request) => {
    const response = await $http.put(`/purchase/accredit/${purchaseId}`, request)
    return response.data
  }

  onReserveCurrentPlaceInventory = async (purchaseId, params) => {
    const response = await $http.put(`/purchase/reserve/${purchaseId}`, {}, { params })
    return response.data
  }

  /*
  onUpdatePurchaseDetail = async (purchaseId, request, params) => {
    const response = await $http.put(`/purchase/details/${purchaseId}`, request, {
      params,
    })
    return response.data
  } */

  onNullifyPurchase = async (purchaseId, list) => {
    const response = await $http.put(`/purchase/nullify/${purchaseId}`, list)
    return response.data
  }

  onNullifyTransactionPurchase = async (purchaseId, params) => {
    const response = await $http.put(
      `/purchase/nullify/transaction/${purchaseId}`,
      {},
      { params },
    )
    return response.data
  }

  getByAmount = async amount => {
    const response = await $http.get(`/purchase/match/${amount}`)
    return response.data
  }

  accreditMultipleByMovement = async (movement, purchases) => {
    const response = await $http.put(`/purchase/accredit/multiple/${movement}`, purchases)
    return response.data
  }

  accreditMultipleByMovementV2 = async (movement, request) => {
    const response = await $http.put(
      `/purchase/accredit/multiple/${movement}/v2`,
      request,
    )
    return response.data
  }

  getTransactions = async (purchaseId, serie, productId) => {
    const response = await $http.get(`/purchase/transactions/view/${purchaseId}`, {
      params: { serie, productId },
    })
    return response.data
  }

  updateInformation = async (purchaseId, reference, conversion, detail) => {
    const response = await $http.put(`/purchase/information/${purchaseId}`, detail, {
      params: { reference, conversion },
    })
    return response.data
  }

  getDocuments = async purchaseId => {
    const response = await $http.get(`/purchase/transactions/documents/${purchaseId}`)
    return response.data
  }

  getProviders = async userId => {
    const response = await $http.get('/purchase/companies', { params: { userId } })
    return response.data
  }

  getProvidersProducts = async (companyId, userId) => {
    const response = await $http.get(`/purchase/company/products/${companyId}`, {
      params: { userId },
    })
    return response.data
  }

  setViewerProducts = async (providerId, viewerId, products) => {
    const response = await $http.post(
      `/purchase/company/products/${viewerId}/${providerId}`,
      products,
    )
    return response.data
  }

  linkExpense = async (id, list) => {
    const response = await $http.put(`/purchase/link/${id}`, list)
    return response.data
  }

  printPurchase = async (id, defaultName) => {
    const response = await $http.get(`/purchase/print/${id}`, {
      responseType: 'blob',
    })
    const file = new Blob([response.data], { type: 'application/pdf' })
    const today = new Date()
    const filename =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate() +
      defaultName +
      '.pdf'
    saveAs(file, filename.replace('json', 'pdf'))
  }

  getSumCPP = async params => {
    const response = await $http.get('/purchase/balance/cpp', { params })
    return response.data
  }

  exportCSV = async params => {
    const response = await $http.get('/purchase/export/csv', {
      params,
      responseType: 'blob',
    })

    // const fileName = response.headers['content-disposition']
    //   ? response.headers['content-disposition']
    //       .replace('attachment; filename="', '')
    //       .replace('"', '')
    //   : moment().format('LL') + 'report.csv'

    // let text = response.data
    //   .split('\n')
    //   .map(line => line.split(/\s+/).join(' '))
    //   .join('\n')

    // let csvContent = 'data:text/csv;charset=utf-8,'
    // csvContent += text
    const fileName = 'Reporte gastos por fecha de creación.xlsx'

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    saveAs(file, fileName.replace('json', 'xlsx'))
  }

  certificatePurchase = async id => {
    const response = await $http.put(`/purchase/certificate/${id}`)
    return response.data
  }

  getInvoiceByPurchase = async id => {
    const response = await $http.get(`/purchase/invoice/${id || ''}`)
    return response.data
  }

  loadCSV = async (file, type) => {
    let formData = new FormData()
    formData.append('file', file)
    const response = await $http.post(`/purchase/csv/product`, formData, {
      headers: { 'content-type': 'multipart/form-data' },
      params: { type },
    })
    return response.data
  }

  /**
   * Servicio de carga masiva de compras/gastos simples
   * @param {Array<Object>} purchases Lista de compras/gastos provenientes del CSV
   * @param {Number} type tipo de operaciones. 1: compra,  2: gasto
   * @returns Object Response service
   */
  loadSimpleCSV = async (purchases, type, currentIndex) => {
    const response = await $http.post(
      `/purchase/csv/simple/${type}?index=${currentIndex}`,
      purchases,
    )
    return response.data
  }

  addInventoryByPurchase = async (id, request) => {
    const response = await $http.post(`/purchase/update/inventory/${id}`, request)
    return response.data
  }

  /**
   * Servicio de carga masiva de productos a Ingreso de inventario
   * @param {Array<Object>} products Lista de productos provenientes del CSV
   * @param {Number} type tipo de operaciones. 0: compra, 1: importacion, 2: gasto
   * @returns Object Response service
   */
  loadProductsPurchase = async (products, type) => {
    const response = await $http.post(`/purchase/csv/products/${type}`, products)
    return response.data
  }

  updatePurchase = async (purchaseId, request) => {
    const response = await $http.put('/purchase/' + purchaseId, request)
    return response.data
  }
}
export default new PurchaseService()
