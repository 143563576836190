import React, { Component } from 'react'
import { connect } from 'react-redux'

import Card from 'src/components/cards/Card'
import Select from 'src/components/inputs/Select/CustomSelect'
import FormTextArea from 'src/components/inputs/FormTextArea/FormTextArea'
import Button from 'src/components/buttons/Button'

import { Row, Col } from 'react-bootstrap'
import { faBackspace, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import Alert from 'sweetalert-react'

import {
  getExternalActiveWarehouses,
  setInformationTransfer,
} from 'src/actions/warehouse.actions'
import {
  selectAllOwnWarehousesForSelect,
  selectTransferDetail,
} from 'src/selectors/warehouse.selector'
import { companies } from 'src/selectors/contracts.selector'

import { actionTypes, getProductsByProducerContract } from 'src/actions/contracts.actions'
import { selectContractedProducts } from 'src/selectors/contracts.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError } from 'src/selectors/error.selector'

class ExternalTransferForm extends Component {
  state = {
    errors: {},
    alert: {
      show: false,
      title: 'default',
      text: 'default',
      type: 'info',
      onConfirm: () => console.log('default'),
    },
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { loading, handlerError } = this.props
    const { contractedProducts } = props

    if (loading && !props.loading) {
      let { alert } = this.state
      if (!contractedProducts || contractedProducts.length <= 0) {
        alert = handlerError('No tiene productos en contratos activos con esta empresa.')
        alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
      } else {
        this.props.jumpToStep(1)
      }
      this.setState({ alert })
    }
  }

  onChangeSelect = (data, parametter) => {
    let { transfer, setInfo } = this.props
    setInfo({ ...transfer, [parametter]: data })
  }

  onChangeField = event => {
    const { name, value } = event.target
    let { transfer, setInfo } = this.props
    setInfo({ ...transfer, [name]: value })
  }

  validate = () => {
    const { transfer, setInfo } = this.props
    const { toCompany, description } = transfer
    let errors = {}

    if (!toCompany.value) errors.toCompany = 'Campo obligatorio'

    if (!description) errors.description = 'Campo obligatorio'

    if (Object.keys(errors).length === 0) {
      transfer.toWarehouse = { label: '- Ninguno -', value: null }
      transfer.toTransfer = []
      setInfo(transfer)
      this.props.getProductsByProducerContract(transfer.toCompany.value)
      this.props.getExternalActiveWarehouses(transfer.toCompany.value)
    }
    this.setState({ errors })
  }

  render() {
    const { transfer, contractedCompanies, loading } = this.props
    const { errors, alert } = this.state

    return (
      <div>
        <Row>
          <Col xl={2} lg={2} md={2} sm={2} xs={2} />
          <Col xl={8} lg={8} md={8} sm={8} xs={12}>
            <Card>
              <Row>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Select
                    label={'Empresa destino'}
                    value={transfer.toCompany}
                    options={contractedCompanies}
                    onChange={data => this.onChangeSelect(data, 'toCompany')}
                    error={errors.toCompany}
                    required
                  />
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FormTextArea
                    name={'description'}
                    label={'Transferencia entre bodegas'}
                    placeholder={
                      'Ingrese una descripción o concepto para la transferencia'
                    }
                    rows={2}
                    value={transfer.description}
                    onChange={this.onChangeField}
                    error={errors.description}
                    required
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xl={2} lg={2} md={2} sm={2} xs={2} />
        </Row>
        <Row className={'container-buttons'}>
          <Button
            disabled={loading}
            onClick={() => window.history.back()}
            icon={faBackspace}
          >
            Salir
          </Button>

          <Button
            loading={loading}
            onClick={() => this.validate()}
            icon={faLongArrowAltRight}
          >
            siguiente
          </Button>
        </Row>

        <Alert {...alert} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  transfer: selectTransferDetail(state),
  ownWarehouses: selectAllOwnWarehousesForSelect(state),
  contractedCompanies: companies(state),
  contractedProducts: selectContractedProducts(state),
  loading: loadingSelector([actionTypes.GET_PRODUCTS_CONTRACT_BY_PRODUCER])(state),
  handlerError: message => handlerError(message),
})
const mapDispatchToProps = dispatch => ({
  setInfo: transfer => dispatch(setInformationTransfer(transfer)),
  getProductsByProducerContract: id => dispatch(getProductsByProducerContract(id)),
  getExternalActiveWarehouses: companyId =>
    dispatch(getExternalActiveWarehouses(companyId)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ExternalTransferForm)
