import { $http } from './http'

class UserService {
  getCurrentUser = async () => {
    if (
      !$http.defaults.headers.common['COTIZAP-Company'] ||
      $http.defaults.headers.common['COTIZAP-Company'] === ''
    ) {
      $http.defaults.headers.common['COTIZAP-Company'] = '-'
    }

    const response = await $http.get(`/users/current`)
    return response.data
  }

  getCompanies = async () => {
    const response = await $http.get(`/users/companies`)
    return response.data
  }

  saveCurrentCompany = company => {
    $http.defaults.headers.common['COTIZAP-Company'] = company
  }

  saveCurrentModule = module => {
    $http.defaults.headers.common['COTIZAP-Module'] = module
  }

  createNewUser = async user => {
    const response = await $http.post(`/users`, user)
    return response.data
  }

  searchUserByEmail = async email => {
    const response = await $http.get(`/users/search?email=${email}`)
    return response.data
  }

  getUserFields = async id => {
    const response = await $http.get(`/users/${id}/fields`)
    return response.data
  }

  updateUserFields = async (id, fields) => {
    const response = await $http.put(`/users/${id}/fields`, fields)
    return response.data
  }

  getAdminUsers = async () => {
    const response = await $http.get(`/users/admin`)
    return response.data
  }

  getUsersByUser = async () => {
    const response = await $http.get(`/users/created`)
    return response.data
  }

  getProfile = async () => {
    const response = await $http.get(`/users`)
    return response.data
  }

  updateProfile = async request => {
    const response = await $http.put(`/users`, request)
    return response.data
  }

  sendConfirmEmail = async () => {
    const response = await $http.post(`/users/verify`, {})
    return response.data
  }

  confirmEmail = async oobCode => {
    const response = await $http.put(`/users/confirm/email`, {}, { params: { oobCode } })
    return response.data
  }

  hideNotice = async () => {
    const response = await $http.put(`/users/hide`, {})
    return response.data
  }

  updateDescription = async (userId, description) => {
    const response = await $http.put(
      `/users/description/${userId}?description=${description}`,
    )
    return response.data
  }
}

export default new UserService()
