import '../NavigationBar.scss'
import React from 'react'

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import Icon from 'src/components/buttons/IconButton'

const CustomTabsV2 = ({ options, defaultIndex, onSelect }) => {
  const getItems = () => {
    return options.filter(o => o.show === undefined || o.show)
  }

  return (
    <div>
      <Tabs
        defaultIndex={defaultIndex || 0}
        onSelect={index => {
          const key = getItems()[index]?.key || index
          if (onSelect) onSelect(key)
        }}>
        <div>
          <TabList>
            {getItems().map((o, index) => {
              return (
                <Tab key={o.key || index}>
                  <div className={'d-flex'}>
                    <div>{o.label}</div>
                    {o.icon && (
                      <Icon
                        icon={o.icon}
                        color={o.color}
                        style={{ marginLeft: 15, ...o.iconStyle }}
                      />
                    )}
                  </div>
                </Tab>
              )
            })}
          </TabList>
        </div>

        {getItems().map((op, index) => (
          <TabPanel key={op.key || index}>{op.render()}</TabPanel>
        ))}
      </Tabs>
    </div>
  )
}
export default CustomTabsV2
