import { useEffect, useState } from 'react'
import { formatHourFromMillis } from 'src/utils/formatters'

/**
 * Hook to format a date in milliseconds to a relative time format.
 * @param withSeconds If you want the formatted date to include the seconds.
 * @returns A function that receives a timestamp in millis and returns a formatted date of the difference of that date with the current date.
 */
function useFormatTimeAgo(withSeconds: boolean) {
  const [now, setNow] = useState(Date.now())

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(Date.now())
    }, 1000)
    return () => clearInterval(interval)
  })

  return (millis: number) => formatHourFromMillis(now - millis, withSeconds)
}

export default useFormatTimeAgo
