import React from 'react'

import { CustomSummary } from 'src/components'
import { toMoney } from 'src/utils/utilities'

interface IOrderDetailPaymentsProps {
  payments: IOrderDetailsPayment[]
}

const OrderDetailPayments = ({ payments = [] }: IOrderDetailPaymentsProps) => {
  return (
    <CustomSummary
      title={'tipos de pago'}
      leftTitle
      items={payments.map(payment => ({
        title: payment.paymentType,
        value: toMoney(payment.amount),
      }))}
    />
  )
}
export default OrderDetailPayments
