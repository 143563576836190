import React from 'react'

import { toMoney } from 'src/utils/utilities'
import { formatDateFromMillis } from 'src/utils/formatters'
import { style } from 'src/components/Print/TicketStyles'

import { haveAnyValue } from 'src/utils/utilitiesV2'

const RenderSegment = ({ segment }) => (
  <ul>
    {segment.subSegmentation?.length > 0 ? (
      <li>
        <span style={style.text}>
          {segment.name} {toMoney(segment.total)}
        </span>
        {segment.subSegmentation.map((subSegment, index) => (
          <RenderSegment key={index} segment={subSegment} />
        ))}
      </li>
    ) : (
      <li>
        <span style={style.text}>
          {segment.name} {toMoney(segment.total)}
        </span>
        <ul>
          {segment.items.map((item, index) => (
            <li key={index}>
              <span style={style.textContent}>
                {item.quantity} {item.name} {toMoney(item.total)}
              </span>
            </li>
          ))}
        </ul>
      </li>
    )}
  </ul>
)

const getTotalItems = segmentation => {
  let quantity = 0
  segmentation.forEach(segment => {
    if (segment.subSegmentation?.length > 0) {
      quantity += getTotalItems(segment.subSegmentation)
    } else {
      quantity += segment.items.reduce((sum, item) => sum + item.quantity, 0)
    }
  })
  return quantity
}

const RenderSegmentDeleted = ({ segment }) => (
  <ul>
    {segment.subValues?.length > 0
      ? segment.subValues.map((subSegment, index) => (
          <li key={index}>
            <span style={style.text}>
              PDV: {segment.value}: Terminal: {subSegment.value}
            </span>
            <RenderSegmentDeleted segment={subSegment} />
          </li>
        ))
      : segment.items.map((item, index) => (
          <li key={index}>
            <div style={style.textContent}>
              Ítem: {item.item}{' '}
              {haveAnyValue(item.description) ? `: ${item.description}` : ''}
            </div>
            <div style={style.textContent}>Cantidad: {item.quantity}</div>
            <div style={style.textContent}>Comandado por: {item.createdBy}</div>
            <div style={style.textContent}>Eliminado por: {item.deletedBy}</div>
            <div style={style.textContent}>Fecha: {formatDateFromMillis(item.date)}</div>
            <div style={style.textContent}>Razón: {item.deletedCommentary || ''}</div>
          </li>
        ))}
  </ul>
)

const TurnTicket = ({
  code,
  created,
  finished,
  startDate,
  endDate,
  balanceStart,
  balanceEnd,
  creditStart,
  paymentsEnd,
  cashEnd,
  creditEnd,
  depositsEnd,
  expensesEnd,
  name,
  tip,
  totalSellQuantity,
  totalCommandsQuantity,
  segmentation,
  itemsDeleted,
  creditNotes,
  vouchers,
  sentCashTransfers,
  receivedCashTransfers,
  cashReceived,
  cashSent,
  totalSale,
  discount,
  cashSale,
  creditSale,
  cardSale,
  cash,
  credit,
  creditCard,
  transfer,
  transferSale,
  cashPayment,
  cardPayment,
  transferPayment,
  cashPurchase,
  cashExpense,
  creditPurchase,
  creditExpense,
  bankPurchase,
  bankExpense,
}) => {
  return (
    <div id={'ticket_turn'} style={{ display: 'none' }}>
      <div style={{ marginRight: 10 }}>
        <p>
          <div style={style.text}>
            <strong>Turno Finalizado {code}</strong>
          </div>
        </p>
        <p>
          <div style={style.text}>
            Del {formatDateFromMillis(startDate)} - al{' '}
            {formatDateFromMillis(endDate !== null ? endDate : Date.now())}.
          </div>
        </p>
        <p>
          <div style={style.text}>
            <strong>Usuarios incluidos en el turno:</strong>
          </div>
          {name}
        </p>

        <div className="section">
          <div style={style.spaceBetween}>
            <div style={style.text}>
              <strong>Cantidad de ventas</strong>
            </div>
            <div style={style.textContent}>{totalSellQuantity || 0}</div>
          </div>
          <div style={style.spaceBetween}>
            <div style={style.text}>
              <strong>Cantidad de comandas</strong>
            </div>
            <div style={style.textContent}>{totalCommandsQuantity || 0}</div>
          </div>
          <div style={style.spaceBetween}>
            <div style={style.text}>Descuentos</div>
            <div style={style.textContent}>{toMoney(discount)}</div>
          </div>
          <div style={style.spaceBetween}>
            <div style={style.text}>Propina (incluida en ventas)</div>
            <div style={style.textContent}>{toMoney(tip || 0)}</div>
          </div>
        </div>

        <div style={style.spaceBetween}>
          <div style={style.text}>
            <strong>Ventas</strong>
          </div>
          <div style={style.textContent}>
            <strong>{toMoney(cash + creditCard + credit + transfer)}</strong>
          </div>
        </div>
        <div className="section" style={{ marginLeft: 20 }}>
          <div style={style.spaceBetween}>
            <div style={style.text}>Ventas en efectivo</div>
            <div style={style.textContent}>{toMoney(cash)}</div>
          </div>
          <div style={style.spaceBetween}>
            <div style={style.text}>Ventas en tarjeta</div>
            <div style={style.textContent}>{toMoney(creditCard)}</div>
          </div>
          <div style={style.spaceBetween}>
            <div style={style.text}>Ventas al crédito</div>
            <div style={style.textContent}>{toMoney(credit)}</div>
          </div>
          <div style={style.spaceBetween}>
            <div style={style.text}>Ventas por transferencias</div>
            <div style={style.textContent}>{toMoney(transfer)}</div>
          </div>
        </div>

        <div style={style.spaceBetween}>
          <div style={style.text}>
            <strong>Abonos de créditos</strong>
          </div>
          <div style={style.textContent}>
            <strong>{toMoney(cashPayment + cardPayment + transferPayment)}</strong>
          </div>
        </div>
        <div className="section" style={{ marginLeft: 20 }}>
          <div style={style.spaceBetween}>
            <div style={style.text}>Abonos en efectivo</div>
            <div style={style.textContent}>{toMoney(cashPayment)}</div>
          </div>
          <div style={style.spaceBetween}>
            <div style={style.text}>Abonos con tarjeta</div>
            <div style={style.textContent}>{toMoney(cardPayment)}</div>
          </div>
          <div style={style.spaceBetween}>
            <div style={style.text}>Abonos por transferencias o depósitos</div>
            <div style={style.textContent}>{toMoney(transferPayment)}</div>
          </div>
        </div>

        <div style={style.spaceBetween}>
          <div style={style.text}>
            <strong>Gastos y compras</strong>
          </div>
          <div style={style.textContent}>
            <strong>
              {toMoney(
                cashExpense +
                  cashPurchase +
                  bankExpense +
                  bankPurchase +
                  creditExpense +
                  creditPurchase,
              )}
            </strong>
          </div>
        </div>
        <div className="section" style={{ marginLeft: 20 }}>
          <div style={style.spaceBetween}>
            <div style={style.text}>En efectivo</div>
            <div style={style.textContent}>
              {toMoney(cashExpense + cashPurchase || 0)}
            </div>
          </div>
          <div style={style.spaceBetween}>
            <div style={style.text}>Con movimientos bancarios</div>
            <div style={style.textContent}>
              {toMoney(bankExpense + bankPurchase || 0)}
            </div>
          </div>
          <div style={style.spaceBetween}>
            <div style={style.text}>Con cuentas por pagar</div>
            <div style={style.textContent}>
              {toMoney(creditExpense + creditPurchase || 0)}
            </div>
          </div>
        </div>

        <div style={style.spaceBetween}>
          <div style={style.text}>
            <strong>Notas de crédito</strong>
          </div>
          <div style={style.textContent}>
            <strong>{toMoney(creditNotes)}</strong>
          </div>
        </div>

        <div style={style.spaceBetween}>
          <div style={style.text}>
            <strong>Vales</strong>
          </div>
          <div style={style.textContent}>
            <strong>{toMoney(vouchers)}</strong>
          </div>
        </div>

        <div style={style.text}>
          <strong>Efectivo</strong>
        </div>
        <div className="section" style={{ marginLeft: 20 }}>
          <div style={style.spaceBetween}>
            <div style={style.text}>Efectivo recibido durante el turno</div>
            <div style={style.textContent}>
              {endDate != null ? receivedCashTransfers : cashReceived}
            </div>
          </div>
          <div style={style.spaceBetween}>
            <div style={style.text}>Efectivo entregado durante el turno</div>
            <div style={style.textContent}>
              {endDate != null ? sentCashTransfers : cashSent}
            </div>
          </div>
        </div>

        <div style={style.spaceBetween}>
          <div style={style.text}>
            <strong>Efectivo inicial</strong>
          </div>
          <div style={style.textContent}>
            <strong>{toMoney(balanceStart)}</strong>
          </div>
        </div>
        <div style={style.spaceBetween}>
          <div style={style.text}>
            <strong>Efectivo final</strong>
          </div>
          <div style={style.textContent}>
            <strong>{toMoney(balanceEnd)}</strong>
          </div>
        </div>

        <div style={style.text}>
          <strong>Venta de artículos</strong>
        </div>
        <div className="section">
          <div style={style.spaceBetween}>
            <div style={style.text}>Total artículos vendidos</div>
            <div style={style.textContent}>
              {segmentation?.length > 0 ? getTotalItems(segmentation) : 0}
            </div>
          </div>
          {segmentation?.length > 0 && (
            <>
              <div>
                <hr />
              </div>
              <div style={{ ...style.center, ...style.subtitle }}>Ventas del turno:</div>
              <div>
                <hr />
              </div>
              {segmentation.map((segment, index) => (
                <RenderSegment key={index} segment={segment} />
              ))}
            </>
          )}

          {itemsDeleted?.length > 0 && (
            <>
              <div>
                <hr />
              </div>

              <div style={{ ...style.center, ...style.subtitle }}>
                Reporte de ítems eliminados (PDV)
              </div>

              <div>
                <hr />
              </div>

              {itemsDeleted.map((segment, index) => (
                <RenderSegmentDeleted key={index} segment={segment} />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
export default TurnTicket
