import { actionTypes } from '../actions/cashboxes.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  cashBoxes: [],
  assignedCashBoxes: [],
  cashBox: [],
}

const CashBoxReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_CASH_BOXES):
      return {
        ...state,
        cashBoxes: extract(action.cashBoxes, []),
      }
    case successState(actionTypes.GET_ASSIGNED_CASH_BOXES):
      return {
        ...state,
        assignedCashBoxes: extract(action.assignedCashBoxes, []),
      }
    case successState(actionTypes.GET_CASH_BOX):
      return {
        ...state,
        cashBox: extract(action.cashBox, {}),
      }
    case successState(actionTypes.GET_ASSIGNED_CASH_BOX):
      return {
        ...state,
        cashBox: extract(action.cashBox, {}),
      }
    default:
      return state ? state : initialState
  }
}

export default CashBoxReducer
