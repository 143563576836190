import { actionTypes } from '../actions/company.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  currentCompany: {},
  allCompanies: [],
  promotorCompanies: [],
  companiesByType: [],
  company: {},
  otherCompany: {},
  producersByClient: [],
  fields: [],
  companyNS: {},
  companies: [],
  field: {},
  fieldInvoice: {},
  report: [],
  badges: [],
  badgesAssigned: [],
  companiesByOrder: [],
  forms: {},
  reportDTE: [],
  fieldC: {},
  editableFields: {},
  fieldStepPOS: {},
  productRepeat: {},
  correlative: {},
  companiesMentor: [],
  withoutTaxOnQuoteField: {},
  taxPressBellField: {},
  commissionOnQuoteField: {},
  editPricesPurchase: {},
  invoicePackages: [],
  felItemTypes: [],
  country: {},
  identificationTypes: [],
}

const CompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_COMPANY_DATA): {
      const newFields = [...state.fields]
      const fields = extract(action.payload.fields, [])

      for (let field of fields) {
        if (newFields.every(f => f.id !== field.id)) newFields.push(field)
        else newFields[newFields.findIndex(f => f.id === field.id)] = field
      }
      return {
        ...state,
        company: extract(action.payload.company, {}),
        invoicePackages: extract(action.payload.invoicePackages, []),
        fields: newFields,
        felItemTypes: extract(action.payload.felItemTypes, []),
        country: extract(action.payload.country, {}),
        identificationTypes: extract(action.payload.identificationTypes, []),
      }
    }
    case successState(actionTypes.GET_COMPANY_CURRENT_BALANCE): {
      return {
        ...state,
        company: extract(
          { ...state.company.company, ownBalanceTransp: action.payload },
          {},
        ),
      }
    }
    case successState(actionTypes.GET_COMMISSION_ON_QUOTE):
      return {
        ...state,
        commissionOnQuoteField: extract(action.commissionOnQuoteField, {}),
      }
    case successState(actionTypes.GET_TAX_PRESS_BELL_QUOTE):
      return { ...state, taxPressBellField: extract(action.taxPressBellField, {}) }
    case successState(actionTypes.GET_WITHOUT_TAX_ON_QUOTE_FIELD):
      return {
        ...state,
        withoutTaxOnQuoteField: extract(action.withoutTaxOnQuoteField, {}),
      }
    case successState(actionTypes.GET_FIELD_PRODUCT_REPEAT):
      return { ...state, productRepeat: extract(action.field, {}) }
    case successState(actionTypes.GET_FIELD_STEP_POS):
      return { ...state, fieldStepPOS: extract(action.field, {}) }
    case successState(actionTypes.COMPANY_FIELDS_FORM):
      return { ...state, forms: extract(action.forms, {}) }
    case successState(actionTypes.GET_FIELD): {
      const field = extract(action.field, {})
      const fields = [...state.fields]
      if (fields.every(f => f.id !== field.id)) fields.push(field)
      else fields[fields.findIndex(f => f.id === field.id)] = field

      return { ...state, field, fields }
    }
    case successState(actionTypes.GET_COMPANIES_BY_ORDER_ID):
      return { ...state, companiesByOrder: extract(action.companies, []) }
    case successState(actionTypes.GET_COMPANY_NS):
      return {
        ...state,
        companyNS: extract(action.companyNS, {}),
      }
    case successState(actionTypes.GET_PRODUCER_BY_CLIENT):
      return {
        ...state,
        producersByClient: extract(action.producersByClient, []) || [],
      }
    case successState(actionTypes.CURRENT_COMPANY):
      return {
        ...state,
        company: extract(action.company, {}),
      }
    case successState(actionTypes.GET_COMPANY):
      return {
        ...state,
        otherCompany: extract(action.company, {}),
      }
    case successState(actionTypes.GET_COMPANIES_BY_TYPE):
      return {
        ...state,
        companiesByType: extract(action.companiesByType, []),
      }
    case actionTypes.CURRENT_COMPANY_SUCCESS:
      return {
        ...state,
        currentCompany: action.company,
      }
    case successState(actionTypes.GET_ALL_COMPANIES):
      return {
        ...state,
        allCompanies: extract(action.allCompanies, []),
      }
    case successState(actionTypes.GET_PROMOTOR_COMPANIES):
      return {
        ...state,
        promotorCompanies: extract(action.promotorCompanies, []),
      }
    case actionTypes.GET_COMPANIES_BY_TYPE_SUCCESS:
      return {
        ...state,
        companiesByType: action.companies,
      }
    case actionTypes.ACTIVE_SINGLE_COMPANY_SUCESS:
      return {
        ...state,
        activated: action.activated,
      }
    case actionTypes.GET_SINGLE_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.company,
      }
    case actionTypes.DELETE_SINGLE_COMPANY_SUCCESS:
      return {
        ...state,
        compDeleted: action.compDeleted,
      }
    case actionTypes.UPDATE_SINGLE_COMPANY_SUCCESS:
      return {
        ...state,
        compDeleted: action.compDeleted,
      }
    case actionTypes.CREATE_ADMIN_COMPANY_SUCCESS:
      return {
        ...state,
        newCompany: action.newCompany,
      }
    case successState(actionTypes.GET_COMPANIES_MENTORS):
      return {
        ...state,
        companiesMentor: extract(action.response, []),
      }
    case successState(actionTypes.GET_COMPANIES_EDITABLE_FIELDS):
      return { ...state, editableFields: extract(action.response, {}) }
    case successState(actionTypes.SET_COMPANIES_EDITABLE_FIELDS):
      return { ...state, editableFields: extract(action.response, {}) }
    case successState(actionTypes.DTE_REPORT):
      return {
        ...state,
        reportDTE: action.dteReport,
      }
    case successState(actionTypes.GET_FIELDS):
      return {
        ...state,
        fields: extract(action.fields, []) || [],
      }
    case successState(actionTypes.SET_FIELDS):
      return {
        ...state,
        forms: extract(action.fields, []) || [],
      }
    case successState(actionTypes.PATRIMONIAL_REPORT):
      return {
        ...state,
        report: extract(action.report, []) || [],
      }
    case successState(
      actionTypes.GET_CORRELATIVE_FIELD || actionTypes.SET_CORRELATIVE_FIELD,
    ):
      return { ...state, correlative: extract(action.correlative, {}) }
    case successState(actionTypes.GET_BADGES):
      return { ...state, badges: extract(action.badges, []) }
    case successState(actionTypes.GET_BADGES_COMPANY):
      return { ...state, badgesAssigned: extract(action.badges, []) }
    case successState(actionTypes.GET_FIELD_EDIT_PRICE_PURCHASE):
      return { ...state, editPricesPurchase: extract(action.field, {}) }
    case successState(actionTypes.GET_INVOICE_PACKAGES):
      return { ...state, invoicePackages: extract(action.payload, []) }
    default:
      return state ? state : initialState
  }
}

export default CompanyReducer
