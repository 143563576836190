import React from 'react'

import DateTimePicker from 'react-datetime-picker'
import IconButton from '../buttons/IconButton'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const CustomDatePickerWithHour = ({
  value,
  onChange,
  label,
  mt,
  className,
  required,
  info,
  dontNbsp,
  disabled,
}) => {
  return (
    <div
      className={`has-feedback ${className} column `.concat(
        mt !== undefined ? `mt-${mt}` : 'mt-3',
      )}>
      {label && (
        <label className={'ftf-form-label left'}>
          {required && <span style={{ color: 'red', marginRight: 2 }}>&nbsp;* </span>}
          {label} &nbsp;
          {info ? (
            <label>
              {' '}
              <IconButton
                tooltip={info}
                color={'rgba(34, 96, 149, 0.75)'}
                icon={faInfoCircle}
                size={'sm'}
              />{' '}
            </label>
          ) : (
            <label> {!dontNbsp && <>&nbsp;</>} </label>
          )}
        </label>
      )}

      <DateTimePicker
        value={value ? new Date(value) : null}
        className={'datetime-local'}
        onChange={value => onChange(value ? value.valueOf() : null)}
        disabled={disabled}
      />
    </div>
  )
}
export default CustomDatePickerWithHour