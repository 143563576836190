import { $http } from './http'

class PaymentsServices {
  getSaleOrdersBalance = async (companyId, userId) => {
    const response = await $http.get(
      `/payments/orders/sale/balance?company=${companyId}&user=${userId}`,
    )
    return response.data
  }

  getSaleOrdersBalanceCurrentUser = async () => {
    const response = await $http.get(`/payments/orders/sale/balance/current`)
    return response.data
  }

  getBIPaymentsHistory = async (companyId, userId, agreementId, startDate, endDate) => {
    const response = await $http.get(
      `/payments/bi/history?company=${companyId}&user=${userId}&agreement=${agreementId}&startDate=${startDate}&endDate=${endDate}`,
    )
    return response.data
  }

  getBIPaymentsHistoryCurrentUser = async (agreementId, startDate, endDate) => {
    const response = await $http.get(
      `/payments/bi/history/current?agreement=${agreementId}&startDate=${startDate}&endDate=${endDate}`,
    )
    return response.data
  }

  postDemoPayment = async amount => {
    const response = await $http.post(`payments//provisional/payment`, amount)
    return response.data
  }
}

export default new PaymentsServices()
