import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Row, Col, Modal } from 'react-bootstrap'
import Alert from 'sweetalert-react'

import Button from 'src/components/buttons/Button'
import MapCard from 'src/components/maps/MapCard'

import { actionTypes, getClientsInsidePolygon } from 'src/actions/clients.actions'

import { handlerError } from 'src/selectors/error.selector'
import { loadingSelector } from 'src/selectors/loading.selector'

import { faEye, faFilter, faWindowClose } from '@fortawesome/free-solid-svg-icons'

const latitude = 14.589465440976774
const longitude = -90.51898866891861

const ClientInsidePolygonCustom = props => {
  const dispatch = useDispatch()

  const loading = useSelector(state =>
    loadingSelector([actionTypes.GET_INSIDE_IN_POLYGON])(state),
  )
  const clientsInside = useSelector(state => state.clients.clientsInsidePolygon)

  const [actions, setActions] = useState({
    getInside: false,
    getByFilter: false,
  })
  const [show, setShow] = useState(false)
  const [google, setGoogle] = useState({
    map: null,
    maps: null,
    polygon: null,
    markers: [],
  })
  const [points, setPoints] = useState([])
  const [request, setRequest] = useState([])
  const [alert, setAlert] = useState({ title: '' })
  const [clients, setClients] = useState([])

  useEffect(() => {
    if (props.show) setShow(props.show)
  }, [props])

  useEffect(() => {
    if (show) {
      setPoints([])
      setClients([])
    }
  }, [show])

  useEffect(() => {
    if (points.length === 0) return
    const { map, maps, polygon } = google
    if (map === null) return
    if (maps === null) return
    if (polygon) polygon.setMap(null)

    const customPolygon = new maps.Polygon({
      paths: points,
      strokeColor: '#22205f',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#226095',
      fillOpacity: 0.35,
    })

    customPolygon.setMap(map)
    setGoogle({ ...google, polygon: customPolygon })
  }, [points])

  useEffect(() => {
    if (loading) setActions({ ...actions, getInside: true })
    else if (actions.getInside) {
      setActions({ ...actions, getInside: false, getByFilter: false })
      if (actions.getByFilter) {
        const ids = clientsInside.map(c => c.id)
        onHide(ids, 2)
      } else {
        setClients(clientsInside)
        seeClients(clientsInside)
      }
    }
  }, [loading])

  const onHide = (list, type) => {
    setShow(false)
    let clients = ''
    if (list)
      list.map((l, index) => {
        const sign = list.length !== index + 1 ? ',' : ''
        clients += l + sign
      })

    props.onHide({ clients, type })
  }

  const addPointToPolygon = ({ x, y, lat, lng, event, map, maps }) => {
    const customPoints = Object.assign([], points)

    if (customPoints.length === 0) {
      customPoints.push({ lat: lat, lng: lng })
      customPoints.push({ lat: lat, lng: lng })
    } else customPoints.splice(1, 0, { lat: lat, lng: lng })
    setPoints(customPoints)
  }

  const seeClients = clients => {
    const customClients = []
    if (clients) {
      const { map, maps, polygon, markers } = google

      if (markers) markers.map(m => m.setMap(null))

      const customMarkers = []

      clients.map(c => {
        const cc = new maps.LatLng(c.latitude, c.longitude)
        const marker = new maps.Marker({ position: cc, map: map })
        customClients.push(c)
        customMarkers.push(marker)
        // if (maps.geometry.poly.containsLocation(cc, polygon)) {}
      })
      polygon.setMap(map)
      setGoogle({ ...google, markers: customMarkers })
    }

    if (customClients.length === 0)
      setAlert({
        ...handlerError('No se encontraron clientes dentro del poligono'),
        onConfirm: () => setAlert({ ...alert, show: false }),
      })
  }

  const getClientsInside = onlySee => {
    const newRequest = points.map((p, index) => ({
      latitude: p.lat,
      longitude: p.lng,
      index,
    }))
    if (onlySee) {
      setRequest(newRequest)
      dispatch(getClientsInsidePolygon(newRequest))
    } else if (JSON.stringify(newRequest) === JSON.stringify(request)) {
      const ids = clients.map(c => c.id)
      onHide(ids, 2)
    } else {
      setActions({ ...actions, getByFilter: true })
      getClientsInside(true)
    }
  }

  return (
    <div className={'column'}>
      <Modal show={show} centered size={'lg'} onHide={() => onHide([], 1)}>
        <Modal.Header>
          <Modal.Title>Dibuja un polígono haciendo clic</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className={'column'}>
            <div className={'b-user-name'}>
              Total de clientes encontrados: {clients.length}
            </div>
            <MapCard
              noCollapse
              latitude={latitude}
              longitude={longitude}
              height={600}
              startCollapsed={false}
              markerList={[]}
              zoom={7}
              onClick={addPointToPolygon}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => {
                setGoogle({ ...google, map, maps })
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'button-container'}>
            <Button left icon={faWindowClose} onClick={() => onHide([], 1)}>
              Cerrar
            </Button>
            <Button
              disabled={points.length <= 3 || loading}
              variant={'secondary'}
              left
              icon={faEye}
              onClick={() => {
                getClientsInside(true)
              }}
            >
              Ver clientes
            </Button>
            <Button
              disabled={points.length <= 3}
              variant={'info'}
              left
              icon={faFilter}
              onClick={() => getClientsInside(false)}
            >
              Filtrar clientes
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Alert {...alert} />
    </div>
  )
}
export default ClientInsidePolygonCustom
