import { selectCurrentCompany } from './user.selector'

export const selectAllProducts = state =>
  state.products.allProducts.map(p => ({
    ...p,
    label: p.productName,
    value: p.productId,
  }))
export const selectPricesList = state =>
  state.products.names.map(p => ({ value: p.alias, label: p.alias }))
export const selectPricesNames = state => state.products.names.map(p => p.alias)
export const selectProductList = state =>
  state.products.productList.map(p => ({
    ...p,
    value: p.productId,
    label: p.productName,
    id: p.productId,
    name: p.productName,
  }))
export const selectProductsLite = state =>
  state.products.selector.map(p => ({
    ...p,
    value: p.id,
    label: p.code + ' - ' + p.name,
  }))
export const selectAllTotalProducts = state => state.products.total
export const selectProductDashboard = state => state.products.productDashboard
export const selectDTotalProducts = state => state.products.totalD

export const selectOwnProducts = state => state.products.allProducts

export const selecteAllProductsFormat = state => {
  return state.products.allProducts.map(p => {
    return {
      active: p.active || p.productActive,
      barcode: p.barcode || p.productBarcode,
      baseProductId: p.baseProductId || p.base_product,
      code: p.code || p.productCode,
      description: p.description || p.productDescription,
      id: p.id || p.productId,
      name: p.name || p.productName,
    }
  })
}

export const selectAllProductCategories = state => state.products.allProductCategories
export const selectSingleProduct = state => state.products.product
export const selectCreateProduct = state => state.products.newProduct
export const selectCreateProductCategory = state => state.products.newProductCategory
export const selectUpdateProduct = state => state.products.editedProduct
export const selectDeleteProductCategory = state => state
export const selectDeleteProduct = state => state
export const selectCompaniesByModel = state => state.products.companies

export const selectAllProductCategoriesForSelect = state =>
  state.products.allProductCategories.map(item => ({
    value: item.id,
    label: item.name,
  }))
export const selectAllProductSubCategoriesForSelect = state =>
  state.products.allProductSubCategories.map(item => ({
    value: item.id,
    label: item.name,
  }))
export const selectProductImages = state =>
  state.products.images.map(item => ({
    id: item.id,
    original: item.url,
    thumbnail: item.url,
    name: item.name,
    description: item.description,
  }))
export const selectExistenceWithSubitemsAcrossWarehouse = state =>
  state.products.existenceWithSubitemsAcrossWarehouses.filter(
    item => item.companyId === selectCurrentCompany(state),
  )
export const selectExistenceAcrossWarehouse = state =>
  state.products.existenceAcrossWarehouses.filter(
    item => item.companyId === selectCurrentCompany(state),
  )
export const selectExistenceConsignated = state =>
  state.products.existenceExternalWarehouses
export const selectProductReport = state => state.products.report || []
export const selectProductListReport = state => state.products.list || []
export const selectAllProductsForSelect = state =>
  state.products.allProducts.map(item => ({
    value: item.productId || item.id,
    data: item,
    label: (item.productCode || item.code) + ' - ' + (item.productName || item.name),
  }))
export const selectAllProductsWithActiveContract = state =>
  state.products.productsContract.map(item => ({
    value: item.id,
    data: item,
    label: item.code + ' - ' + item.name,
  }))
export const selectAllProductsWithAllForSelect = state =>
  [{ value: 0, label: 'Todos los Productos' }].concat(
    selectOrderedProducts(state.products.allProducts).map(item => ({
      value: item.productId,
      data: item,
      label: item.productCode + ' - ' + item.productName,
    })),
  )
export const selectAllProductsInWarehouse = state => state.products.productsWarehouses
export const selectAllProductsInWarehouseForSelect = state =>
  state.products.productsWarehouses.map(item => ({
    value: item.id,
    label: item.code + ' - ' + item.name,
  }))
export const selectUploadResponseProducts = state => state.products.uploadResponseProducts
export const selectProductsLine = state => getProductLine(state.products.allProducts)

export const getProductLine = list => {
  list = formatListProducts(list)
  const productsWithTotalExistence = []
  list.forEach(p => {
    if (p.base_product === p.productId) {
      const pl = list
        .filter(f => f.base_product === p.productId)
        .sort((a, b) => b.transformationFactor - a.transformationFactor)
      productsWithTotalExistence.push({
        productLine: pl,
      })
    }
  })
  return productsWithTotalExistence
}

export const selectOrderedProducts = list => {
  list = formatListProducts(list)
  const productsWithTotalExistence = []
  list.forEach(p => {
    if (p.base_product === p.productId) {
      const pl = list
        .filter(f => f.base_product === p.productId)
        .sort((a, b) => b.transformationFactor - a.transformationFactor)
      productsWithTotalExistence.push({
        productLine: pl,
      })
    }
  })
  let result = []
  productsWithTotalExistence.forEach(p => {
    p.productLine.forEach((pl, index) => {
      if (index === 0) result.push(pl)
    })
  })
  return result
}

export const selectAllProductsInWarehouseProducersForSelect = state => {
  const providers = []
  const warehouses = state.products.productsWarehouses
  wLoop: for (let i in warehouses) {
    if (!warehouses[i].companyData) continue
    let company = {
      value: warehouses[i].companyData.id,
      label: warehouses[i].companyData.name,
    }
    for (let j in providers) {
      if (providers[j].value === company.value) continue wLoop
    }
    providers.push(company)
  }
  return providers
}

export const getProductLineById = (list, base) => {
  list = getBaseProductWithSons(list)
  const baseProduct = list.find(l => l.productId === base)
  if (baseProduct)
    return baseProduct.child
      .map((c, i) => ({ ...c, i }))
      .sort((a, b) => b.transformationFactor - a.transformationFactor)
  else return []
}

export const getBaseProductWithSons = list => {
  list = formatListProducts(list)

  const newProducts = []
  list.forEach(p => {
    if (p.base_product === p.productId) {
      const child = list.filter(f => f.base_product === p.productId)
      newProducts.push({
        ...p,
        child,
      })
    }
  })
  return newProducts
}

export const getExclusiveProducts = list => {
  list = formatListProducts(list)

  const indexs = []
  const newProducts = []
  list.forEach(p => {
    const np = newProducts.find(n => n.productId === p.productId)
    if (!np) {
      const baseProduct = p.base_product ? p.base_product : p.productId
      const index = indexs.find(i => i === baseProduct)
      if (!index) {
        indexs.push(baseProduct)
        const pl = list
          .filter(f => f.base_product === baseProduct)
          .sort((a, b) => b.transformationFactor - a.transformationFactor)
        const exclusiveProduct = pl[0]
        newProducts.push({
          ...exclusiveProduct,
          productLine: pl,
        })
      }
    }

    /*
        if (p.base_product === p.productId) {
            const pl = list.filter((f) => f.base_product === p.productId).sort((a, b) => (b.transformationFactor - a.transformationFactor));
            const exclusiveProduct = pl[0];
            newProducts.push({
                ...exclusiveProduct, productLine: pl});
        }
        */
  })

  return newProducts
}

export const getProductsOrderByProductLine = list => {
  list = getExclusiveProducts(list)
  const newProducts = []
  list.forEach(ep => {
    newProducts.push(...ep.productLine)
  })

  return newProducts
}

export const getVisualizationFactorProducts = list => {
  list = formatListProducts(list)
  return list
}

export const getFormatProducts = list => {
  list = formatListProducts(list)

  const productsWithTotalExistence = []
  list.forEach(p => {
    if (!list.find(l => l.productId === p.base_product))
      productsWithTotalExistence.push({
        existence: p.productExistence,
        productLine: [p],
      })
    if (p.base_product === p.productId) {
      let existence = 0
      const pl = list
        .filter(f => f.base_product === p.productId)
        .map(f => {
          existence += f.productExistence * f.transformationFactor
          return f
        })
        .sort((a, b) => b.transformationFactor - a.transformationFactor)
      productsWithTotalExistence.push({
        existence,
        productLine: pl,
      })
    }
  })

  const products = []

  productsWithTotalExistence.forEach(p => {
    let existence = p.existence
    let newExistence = existence
    if (p.productLine.length === 1 && existence > 0) {
      let s = p.productLine[0]
      products.push({ ...s, productExistence: existence, marked: false })
    } else {
      p.productLine.forEach((pl, i) => {
        if (newExistence > 0) {
          let trunc = 0
          let marked = true
          if (i === 0) {
            newExistence = newExistence / pl.transformationFactor
            trunc = Math.floor(newExistence)
            marked = false
          } else {
            const big = p.productLine[i - 1].transformationFactor
            const small = pl.transformationFactor
            const base = big / small
            newExistence = (newExistence % 1) * base
            trunc = Math.floor(newExistence)
          }
          newExistence = parseFloat(newExistence)
          if (trunc > 0) products.push({ ...pl, productExistence: trunc, marked: marked })
        }
      })
    }
  })
  return products
}

const formatListProducts = list => {
  return list.map(p => {
    if (!p.base_product) p.base_product = p.baseProductId || p.baseProduct || p.productId
    if (p.transformationFactor <= 0 || !p.transformationFactor) p.transformationFactor = 1
    return p
  })
}

export const getProductContractedByWarehouse = (inventory, contracted) => {
  let products = []
  inventory.forEach(i => {
    const p = contracted.find(c => c.id === i.productId)
    if (p) products.push({ ...i, contracted: true })
    else products.push({ ...i, contracted: false })
  })
  return products
}

export const selectProductsByClient = state => state.products.productsByClient
export const selectTransportPayment = state => state.products.transportPayment
export const selectTransportPaymentC = state => state.products.transportationPaymentC
export const selectTransportPayments = state => state.products.transportationPayments
export const selectTransportPaymentS = state => state.products.transportationPayment
export const selectTransportProducts = state => state.products.transportationProducts

export const selectProductsCompanies = state => state.products.productsCompanies

export const selectAllProductsCompany = state => state.products.productsCompany
export const selectProductsByCompanyNS = state => state.products.productsByCompanyNS
export const selectProductsDeleted = state => state.products.deletedArchive
export const selectProductLog = state => state.products.logs
export const selectProductDataSheet = state => state.products.datasheet
export const selectProductToChart = state => state.products.productsChart
export const selectProductsCategories = state => state.products.categoriesChart
export const selectProductsSimple = state => {
  const { simples } = state.products
  if (!simples) return []
  return simples.map(s => ({
    ...s,
    label: s.code + ' ' + s.name,
    value: s.id,
    productId: s.id,
  }))
}
export const selectVariations = state => state.products.variations
export const selectProductExistences = state => state.products.productExistences
export const selectMeasurementUnits = state => state.products.measurementUnits

export const selectProductItemResponse = state => state.products.itemResponse
export const selectProductItemDetail = state => state.products.itemDetail
