interface Config {
  decimals?: number
  min?: number
  max?: number
  minLength?: number
  maxLength?: number
}

const defaultValue = {
  parsedValue: undefined,
  writingDecimal: false,
  writingNegative: false,
  zeroes: 0,
}

/**
 * Hook to handle decimal numbers
 */
function useDecimalNumber() {
  return (
    value: string | number,
    { decimals = 0, min, max, minLength, maxLength }: Config,
  ) => {
    if (typeof value === 'number') value = String(value)
    if (maxLength > 0 && value.length > maxLength) value = value.substring(0, maxLength)

    let parsedValue = parseFloat(parseFloat(value).toFixed(decimals + 1))
    if (value === '' || isNaN(parsedValue)) return defaultValue

    if (value.endsWith('-')) parsedValue *= -1
    if (parsedValue < min) {
      value = String(min)
      parsedValue = min
    }

    const hasDecimalPart = decimals > 0 && (value.includes('.') || value.includes(','))
    const decimalPartIndex = Math.max(value.indexOf('.'), value.indexOf(','), 0)
    const decimalPart = hasDecimalPart
      ? value.substring(decimalPartIndex + 1, value.length)
      : ''

    if (decimalPart.length > decimals) {
      let stringValue = String(value)
      const lastIndex = stringValue.length - 1
      stringValue = stringValue.substring(0, lastIndex - 1) + stringValue[lastIndex]
      parsedValue = parseFloat(parseFloat(stringValue).toFixed(decimals))
    }
    if (parsedValue > max) parsedValue = max

    const valueMatches = value.match(/^(\d+)\.([1-9]*)(0+)?$/)
    const decimalNonZeroes = valueMatches?.[2]?.length || 0
    const decimalZeroes = valueMatches?.[3]?.length || 0

    const writingDecimal = hasDecimalPart && decimalPartIndex === value.length - 1
    const writingNegative =
      (min === undefined || min < 0) &&
      value !== '-' &&
      value.includes('-') &&
      parsedValue === 0
    const zeroes = hasDecimalPart
      ? Math.max(Math.min(decimalZeroes, decimals - decimalNonZeroes), 0)
      : 0

    return { parsedValue, writingDecimal, writingNegative, zeroes }
  }
}

export default useDecimalNumber
