export enum Country {
  GT = 1,
  SV = 2,
  UNI = 3,
  HN = 4,
}

export const countries = {
  [Country.GT]: { id: Country.GT, name: 'Guatemala', ext: 'GT', iva: 0.12 },
  [Country.SV]: { id: Country.SV, name: 'El Salvador', ext: 'SV', iva: 0.13 },
  [Country.UNI]: { id: Country.UNI, name: 'Universal', ext: 'UNI', iva: 0.0 },
  [Country.HN]: {
    id: Country.HN,
    name: 'Honduras',
    ext: 'HN',
    iva: 0.15,
    taxLabel: 'ISV 15%',
  },
}

export const getTaxLabel = (countryId: number) => {
  const defaultLabel = 'IVA'

  const country = countries[countryId]
  if (country && country.taxLabel) return country.taxLabel
  else return defaultLabel
}

export default countries
