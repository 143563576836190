import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal, Spinner, Row, Col } from 'react-bootstrap'
import { TableV2, Paragraph, CustomTabs } from 'src/components'

import {
  acceptTransferMassive,
  actionTypes,
  validateInventoryTransfer,
} from 'src/actions/warehouse.actions'
import {
  selectResponseValidate,
  selectTransferDetail,
} from 'src/selectors/warehouse.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'

import { showAlert } from 'src/actions/alert.actions'

const tupleSize = 14

interface IProps {
  init: boolean
  onHide: (boolean) => void
}

/** Component that verifies the existence of the dispatch warehouse and  performs the inventory dispatch by tuples.
 * @param {boolean} init Indicates whether to start with verification
 * @param {Function} onHide Close de component
 * */
const ModalTransferValidateAndConfirm = ({ init, onHide }: IProps) => {
  const dispatch = useDispatch()

  const transferResponse: ITransferResponse = useSelector(selectTransferDetail)
  const { transfer, detail } = transferResponse

  const responseValidate: IMassiveResponse = useSelector(selectResponseValidate)
  const loadingValidation = useSelector(state =>
    loadingSelector([actionTypes.MASSIVE_VALIDATION])(state),
  )
  const errorValidation = useSelector(state =>
    hasErrors([actionTypes.MASSIVE_VALIDATION])(state),
  )

  const loadingConfirm = useSelector(state =>
    loadingSelector([actionTypes.MASSIVE_TRANFER])(state),
  )
  const errorConfirm = useSelector(state =>
    hasErrors([actionTypes.MASSIVE_TRANFER])(state),
  )

  const [flags, setFlags] = useState({ validation: false, confirmation: false })

  const [validation, setValidation] = useState<boolean>(false)
  const [validationFinished, setValidationFinished] = useState<boolean>(false)

  const [confirmation, setConfirmation] = useState<boolean>(false)
  const [confirmationFinished, setConfirmationFinished] = useState<boolean>(false)

  const [index, setIndex] = useState<number>(-1)

  const [errors, setErrors] = useState<IMassiveErrorResponse[]>([])
  const [showErrors, setShowErrors] = useState<boolean>(false)

  useEffect(() => {
    if (!init) return
    setValidation(true)
    onValidation(0)
  }, [init])

  useEffect(() => {
    if (loadingValidation) setFlags({ ...flags, validation: true })
    else if (flags.validation) {
      setFlags({ ...flags, validation: false })
      if (errorValidation)
        dispatch(
          showAlert({
            ...handlerError(errorValidation.message),
            onConfirm: () => onClose(),
          }),
        )
      else {
        setErrors([...errors, ...responseValidate.errors])
        onValidation(index + 1)
      }
    }
  }, [loadingValidation])

  useEffect(() => {
    if (loadingConfirm) setFlags({ ...flags, confirmation: true })
    else if (flags.confirmation) {
      setFlags({ ...flags, confirmation: false })
      if (errorConfirm)
        dispatch(
          showAlert({
            ...handlerError(errorConfirm.message),
            onConfirm: () => onClose(),
          }),
        )
      else onConfirm(index + 1)
    }
  }, [loadingConfirm])

  useEffect(() => {
    if (!validationFinished) return
    setValidationFinished(false)
    setValidation(false)
    if (errors.length > 0) setShowErrors(true)
    else {
      setConfirmation(true)
      onConfirm(0)
    }
  }, [validationFinished])

  useEffect(() => {
    if (!confirmationFinished) return
    setConfirmationFinished(false)
    setConfirmation(false)

    dispatch(
      showAlert({
        ...handlerSuccess(),
        onConfirm: () => onClose(true),
      }),
    )
  }, [confirmationFinished])

  const getNecessaryInfo = (
    currentIndex: number,
  ): {
    lastDetails: boolean
    detail: ITransferDetail[]
  } => {
    let afterNumber: number
    let lastDetails = false
    if (currentIndex + tupleSize >= detail.length - 1) {
      afterNumber = detail.length - 1
      lastDetails = true
    } else afterNumber = currentIndex + tupleSize

    const items: ITransferDetail[] = detail.slice(currentIndex, afterNumber + 1)
    setIndex(afterNumber)

    return { lastDetails, detail: items }
  }

  const onValidation = (currentIndex: number) => {
    if (currentIndex > detail.length - 1) return setValidationFinished(true)
    dispatch(validateInventoryTransfer(transfer.id, getNecessaryInfo(currentIndex)))
  }

  const onConfirm = (currentIndex: number) => {
    if (currentIndex > detail.length - 1) return setConfirmationFinished(true)
    dispatch(acceptTransferMassive(transfer.id, getNecessaryInfo(currentIndex)))
  }

  const onClose = (update?: boolean) => {
    setValidation(false)
    setConfirmation(false)
    setShowErrors(false)
    setErrors([])
    onHide(update)
  }

  const renderTable = (items: ITransferDetail[], showExistence?: boolean) => (
    <TableV2
      // @ts-ignore
      items={items}
      mobileAuto
      headers={[
        {
          show: true,
          label: 'Código',
          type: 'text',
          value: ['productData', 'code'],
        },
        {
          show: true,
          label: 'Nombre',
          type: 'text',
          value: ['productData', 'name'],
        },
        {
          show: true,
          label: 'Cantidad',
          type: 'number',
          value: ['quantity'],
        },
        {
          show: showExistence,
          label: 'En bodega',
          type: 'text',
          value: ['available'],
        },
      ]}
    />
  )

  return (
    <div>
      <Modal show={validation || confirmation} size={'sm'} centered>
        <Modal.Header>
          <Modal.Title>{transfer?.number}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={'center'}>
          <Row>
            <Col xl={12} className={'mb-3'}>
              <Paragraph>
                {validation
                  ? 'Verificando existencias'
                  : confirmation
                  ? 'Despachando inventario'
                  : ''}
              </Paragraph>
            </Col>
            <Col xl={12}>
              <Spinner animation={'border'} role={'status'} />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal show={showErrors} size={'lg'} centered onHide={() => onClose()}>
        <Modal.Header closeButton>
          <Modal.Title>{transfer?.number}: Problemas encontrados</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col xl={12}>
              <Paragraph>
                En el proceso de validación de inventario se detectaron ítems sin
                suficiente existencia para realizar el despacho de la transferencia.
              </Paragraph>
            </Col>
            <Col xl={12} className={'mt-3'}>
              <Paragraph>
                A continuación, en la pestaña &apos;Sin Inventario&apos; se detallan los
                items que no tienen inventario disponible.
              </Paragraph>
            </Col>
            <Col xl={12} className={'mt-3'}>
              <CustomTabs
                noCard
                items={[
                  {
                    title: `Sin existencias (${
                      detail?.filter(d => errors.some(e => Number(e.line) === d.id))
                        .length
                    })`,
                    component: renderTable(
                      detail?.filter(d => errors.some(e => Number(e.line) === d.id)),
                      true,
                    ),
                  },
                  {
                    title: `Con existencias (${
                      detail?.filter(d => !errors.some(e => Number(e.line) === d.id))
                        .length
                    })`,
                    component: renderTable(
                      detail?.filter(d => !errors.some(e => Number(e.line) === d.id)),
                    ),
                  },
                ]}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ModalTransferValidateAndConfirm
