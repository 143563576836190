import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Col, Modal, Row } from 'react-bootstrap'
import {
  faFileAlt,
  faPlaneDeparture,
  faPlaneArrival,
  faEye,
  faTimesCircle,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons'
import Alert from 'sweetalert-react'

import Card from '../../../components/cards/Card'
import IconButton from '../../../components/buttons/IconButton'
import Button from '../../../components/buttons/Button'
import { history } from '../../../App'

import {
  acceptTransfer,
  actionTypes,
  getTransferDetail,
  rejectTransfer,
  getAssociatedOrders,
} from '../../../actions/warehouse.actions'
import {
  selectAssociatedOrders,
  selectTransferDetail,
} from '../../../selectors/warehouse.selector'
import { selectCurrentUser, selectCurrentModule } from '../../../selectors/user.selector'
import { formatDateFromMillis } from '../../../utils/formatters'

import { loadingSelector } from '../../../selectors/loading.selector'
import {
  hasErrorsSelector,
  singleErrorSelector,
  handlerError,
  handlerSuccess,
} from '../../../selectors/error.selector'

import { Doughnut } from 'react-chartjs-2'
import Detail from '../../Orders/OrderDetail/OrdersDetails'
import { isAllowed } from '../../../selectors/modules.selector'
import { toMoney } from '../../../utils/utilities'
import { TableV2 } from '../../../components'

const options = {
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || ''
        let formatCurrency = amount => {
          if (amount === null) return ''
          return toMoney(amount)
        }

        if (label) {
          label += ': '
        }

        label += formatCurrency(
          tooltipItem.index == 0
            ? data.datasets[0].data[tooltipItem.index]
            : data.datasets[0].data[0] + data.datasets[0].data[tooltipItem.index],
        )
        return label
      },
    },
  },
}

class DetailTransfers extends Component {
  state = {
    alert: { title: 'default' },
    showOrders: false,
    orderDetail: { value: false, item: {} },
    productsInOrders: [],
  }

  UNSAFE_componentWillReceiveProps(next) {
    const {
      acceptTransferIsLoading,
      hasErrorA,
      handlerError,
      handlerSuccess,
      associatedOrdersIsLoading,
      associatedOrders,
    } = this.props
    const { errorA } = next

    if (acceptTransferIsLoading && !next.acceptTransferIsLoading) {
      let alert = { title: 'default' }
      if (!hasErrorA && next.hasErrorA) {
        alert = handlerError(errorA.message || 'Ha ocurrido un error inesperado')
        alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
      }
      this.setState({ alert })
    }

    if (associatedOrdersIsLoading && !next.associatedOrdersIsLoading) {
      if (!next.associatedOrders) return

      let list = []

      for (let i = 0; i < next.associatedOrders.length; i++) {
        let item = next.associatedOrders[i]
        //console.log(item);
        let entry = { id: item.productId, order: item.order.id }

        for (let j = 0; j < item.details.length; j++) {
          let detail = item.details[j]
          if (entry.id === detail.product) {
            entry.quantity = detail.quantity
            entry.price = detail.productData.price
          } else if (detail.productData.subProductId === entry.id) {
            entry.quantity = detail.quantity * detail.productData.subProductValue
            entry.price = detail.productData.subProductData.price
          } else {
            console.log(entry.id, detail.productData.product, detail.subProductId, detail)
          }
        }
        list.push(entry)
      }
      //console.log(list);

      this.setState({ productsInOrders: [...list] })
    }
  }

  componentDidMount() {
    const transferId = this.props.match.params.id
    this.props.getTransferDetail(transferId)
    this.props.getAssociatedOrders(transferId)
  }

  getQuantity(id, order) {
    const list = this.state.productsInOrders
    //console.log(list);
    if (!list || !id || !order) {
      //if(true){
      console.log(id, order)
      return 'N/A'
    }

    let amount = 0
    for (let i = 0; i < list.length; i++) {
      let product = list[i]
      if (product.order === order && product.id === id) {
        amount += product.quantity
      }
    }
    return amount
  }

  getDataPie = (totalOrders, totalTransfer) => {
    let data = {
      labels: ['Vendido', 'Total'],
      datasets: [
        {
          data: [totalOrders, totalTransfer],
          backgroundColor: ['#FF6384', '#36A2EB'],
          hoverBackgroundColor: ['#FF6384', '#36A2EB'],
        },
      ],
    }
    return data
  }

  toItemDetails = item => {
    history.push(`/produccion/productos/detalles/${item.productId}`)
  }

  getHeaders = warehouse => {
    const { user } = this.props
    const headers = []
    if (warehouse.attendant === user.id) {
      headers.push({
        label: '',
        show: true,
        value: [],
        type: 'text',
        className: 'mini',
        custom: item =>
          item.existence >= item.quantity ? (
            <IconButton
              icon={faCheckCircle}
              color={'green'}
              tooltip={'Si hay existencia'}
            />
          ) : (
            <IconButton
              icon={faTimesCircle}
              color={'red'}
              tooltip={'No hay existencia'}
            />
          ),
      })
    }
    headers.push(
      ...[
        { label: '#', show: true, index: true, select: true, className: 'mini' },
        {
          label: 'SKU',
          show: true,
          value: ['productData', 'code'],
          type: 'text',
          className: 'medium',
        },
        {
          label: 'Código de barras',
          show: true,
          value: ['productData', 'barcode'],
          type: 'nac',
          className: 'medium',
        },
        {
          label: 'Producto',
          show: true,
          value: ['productData', 'name'],
          type: 'text',
          className: 'mini',
        },
        {
          label: 'Cantidad',
          show: true,
          value: ['quantity'],
          type: 'measure',
          className: 'mini',
        },
      ],
    )

    if (warehouse.attendant === user.id) {
      headers.push({
        label: 'Existencia',
        show: true,
        value: ['existence'],
        type: 'measure',
        className: 'mini',
      })
    }

    headers.push({
      config: true,
      show: true,
      label: '',
      className: 'mini center',
      custom: item =>
        this.props.module === 2000 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
            }}>
            <IconButton
              tooltip={'Detalles'}
              icon={faFileAlt}
              onClick={() => {
                console.log(item)
                this.toItemDetails(item)
              }}
            />
          </div>
        ),
    })
    return headers
  }

  formatCurrency = amount => {
    if (amount === null) return ''
    return toMoney(amount)
  }

  getGrafic = transferDetail => {
    let see = false
    const { transfer } = transferDetail
    if (transfer) {
      const { fromWarehouseData, toWarehouseData } = transfer
      if (
        fromWarehouseData &&
        toWarehouseData &&
        fromWarehouseData.company !== toWarehouseData.company
      )
        see = true
    }
    return see
  }

  actionButton = (Warehouse, transfer, transferDetail) => {
    const { user, acceptTransferIsLoading, rejectTransferIsLoading } = this.props
    let isAttendant = false
    if (Warehouse.attendantsIds)
      isAttendant = Warehouse.attendantsIds.find(ai => ai === user.id)

    return (
      <>
        {isAttendant &&
          transfer.stateId !== 4 &&
          transfer.stateId !== 3 &&
          this.props.module === 1000 && (
            <Button
              variant={'danger'}
              loading={rejectTransferIsLoading}
              onClick={() => this.props.rejectTransfer(transferDetail.transfer.id)}>
              Rechazar{' '}
            </Button>
          )}
        {isAttendant &&
          transfer.stateId !== 4 &&
          transfer.stateId !== 3 &&
          this.props.module === 2000 && (
            <Button
              variant={'danger'}
              loading={rejectTransferIsLoading}
              onClick={() => this.props.rejectTransfer(transferDetail.transfer.id)}>
              Cancelar
            </Button>
          )}
        {isAttendant && transfer.stateId === 1 && (
          <Button
            variant={'success'}
            loading={acceptTransferIsLoading}
            onClick={() => this.props.acceptTransfer(transferDetail.transfer.id)}>
            Aceptar
          </Button>
        )}
      </>
    )
  }

  getHeadersAssociateOrder = () => {
    return [
      { label: '#', show: true, index: true, select: true, className: 'mini' },
      {
        label: 'Orden',
        show: true,
        value: ['order', 'number'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Producto',
        show: true,
        value: ['product', 'name'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Cantidad',
        show: true,
        value: ['cantidadC'],
        type: 'text',
        className: 'mini',
        custom: item =>
          (item.cantidadC = item.details
            ? this.getQuantity(item.productId, item.order.id)
            : ''),
      },
      {
        config: true,
        show: true,
        label: '',
        className: 'mini center',
        custom: item => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
            }}>
            <IconButton
              tooltip={'Ir a la orden'}
              icon={faFileAlt}
              onClick={() => this.toOrder(item)}
            />
            <IconButton
              tooltip={'Detalle de la orden'}
              icon={faEye}
              onClick={() => this.setState({ orderDetail: { value: true, item } })}
              style={{ marginLeft: 3 }}
            />
          </div>
        ),
      },
    ]
  }

  toOrder = item => {
    history.push(`/distribucion/ordenes/detalle/${item.orderId}`)
  }

  render() {
    const {
      transferDetail,
      transferDetailIsLoading,
      associatedOrders,
      associatedOrdersIsLoading,
      user,
    } = this.props
    const { orderDetail, productsInOrders } = this.state
    let fromWarehouse = {}
    let toWarehouse = {}
    let detail = []
    let transfer = { solicitedByData: {}, movedByData: {} }

    if (transferDetail.transfer) {
      fromWarehouse = transferDetail.transfer
        ? transferDetail.transfer.fromWarehouseData || {}
        : {}
      toWarehouse = transferDetail.transfer
        ? transferDetail.transfer.toWarehouseData || {}
        : {}
      detail = transferDetail.detail
      transfer = transferDetail.transfer
    }
    let headers2
    let headers
    if (transfer.requested) {
      if (fromWarehouse.attendant === user.id) {
        headers2 = [
          '',
          '#',
          'SKU',
          'Código de barras',
          'Producto',
          'Cantidad',
          'Existencia',
          '',
        ]
      } else headers2 = ['#', 'SKU', 'Código de barras', 'Producto', 'Cantidad', '']
    } else {
      if (toWarehouse.attendant === user.id)
        headers2 = [
          '',
          '#',
          'SKU',
          'Código de barras',
          'Producto',
          'Cantidad',
          'Existencia',
          '',
        ]
      else headers2 = ['#', 'SKU', 'Código de barras', 'Producto', 'Cantidad', '']
    }

    let totalTransfer = 0
    let totalOrders = 0

    if (detail)
      detail.map((item, index) => {
        totalTransfer += item.quantity * item.productData.price
      })

    if (productsInOrders)
      productsInOrders.map((item, index) => {
        totalOrders += item.quantity * item.price
      })
    const t = totalTransfer - totalOrders
    const percentage = (totalOrders / totalTransfer) * 100

    return (
      <div>
        <h1 className={'dashboard-welcome'}>Detalles</h1>
        <Row>
          <Col xs={12} sm={12} md={4} xl={4}>
            <Card title={'Transferencia'} button={<IconButton icon={faFileAlt} />}>
              <Row>
                <Col className={'filter-title'}>Número</Col>
                <Col className={'filter-description'}>{transfer.number}</Col>
              </Row>
              <Row>
                <Col className={'filter-title'}>Fecha</Col>
                <Col className={'filter-description'}>
                  {formatDateFromMillis(transfer.createdAt)}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col className={'filter-title'}>Estado</Col>
                <Col className={'filter-description'}>{transfer.stateName}</Col>
              </Row>
              <Row>
                <Col className={'filter-title'}>Creada Por</Col>
                <Col className={'filter-description'}>
                  {transfer.solicitedByData
                    ? transfer.solicitedByData.name
                    : 'No Disponible'}
                </Col>
              </Row>
              <Row>
                <Col className={'filter-title'}>Aceptada Por</Col>
                <Col className={'filter-description'}>
                  {(transfer.movedByData && transfer.movedByData.name) || 'No Disponible'}
                </Col>
              </Row>
              {transfer.requested && (
                <Row>
                  <Col className={'filter-title'}>Solicitada A</Col>
                  <Col className={'filter-description'}>
                    {fromWarehouse.attendantData
                      ? fromWarehouse.attendantData.name
                      : 'No Disponible'}
                  </Col>
                </Row>
              )}
              <br />
              <Row
                style={{
                  justifyContent: 'space-between',
                  paddingLeft: 15,
                  paddingRight: 15,
                }}>
                {transfer.requested
                  ? this.actionButton(fromWarehouse, transfer, transferDetail)
                  : this.actionButton(toWarehouse, transfer, transferDetail)}
              </Row>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={4} xl={4}>
            <Card
              title={'Bodega Saliente'}
              button={<IconButton icon={faPlaneDeparture} />}
              className={'stretched'}>
              <h6 className={'filter-title'}>{fromWarehouse.name}</h6>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={4} xl={4}>
            <Card
              title={'Bodega Entrante'}
              button={<IconButton icon={faPlaneArrival} />}
              className={'stretched'}>
              <h6 className={'filter-title'}>{toWarehouse.name}</h6>
            </Card>
          </Col>
        </Row>

        {this.getGrafic(transferDetail) && (
          <Row>
            <Col>
              <Card title={'Total consumido'}>
                {
                  <Row>
                    <Col>
                      <Row>
                        <Col className={'filter-title'}>Total Transferencia:</Col>
                        <Col className={'filter-description'}>
                          {this.formatCurrency(totalTransfer)}
                        </Col>
                      </Row>
                      <Row>
                        <Col className={'filter-title'}>Total Ordenes:</Col>
                        <Col className={'filter-description'}>
                          {this.formatCurrency(totalOrders)}
                        </Col>
                      </Row>
                      <Row>
                        <Col className={'filter-title'}>% Consumido:</Col>
                        <Col className={'filter-description'}>
                          {Math.floor(percentage)}%
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Doughnut
                        data={this.getDataPie(totalOrders, t)}
                        options={options}
                      />
                    </Col>
                  </Row>
                }
              </Card>
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <Card title={'Productos'}>
              <TableV2
                headers={this.getHeaders(
                  transfer.requested ? fromWarehouse : toWarehouse,
                )}
                items={detail}
                loading={transferDetailIsLoading}
                mobileAuto
                storageKey={`detailTransfer`}
              />
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card title={'Ordenes'}>
              <TableV2
                headers={this.getHeadersAssociateOrder()}
                items={associatedOrders}
                loading={associatedOrdersIsLoading}
                mobileAuto
                storageKey={`detailTransferOrders`}
              />
            </Card>
          </Col>
          <Modal
            show={orderDetail.value}
            size={'lg'}
            centered
            aria-labelledby={'contained-modal-title-vcenter'}
            onHide={() => this.setState({ orderDetail: { value: false, item: {} } })}>
            <Modal.Header closeButton>
              <Modal.Title>
                {orderDetail.item.order
                  ? `Detalle de la orden: #${orderDetail.item.order.number}`
                  : 'Detalle de la orden'}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Detail id={orderDetail.item.order ? orderDetail.item.order.id : null} />
            </Modal.Body>
          </Modal>
        </Row>

        <Alert {...this.state.alert} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: selectCurrentUser(state),
  module: selectCurrentModule(state),
  transferDetail: selectTransferDetail(state),
  transferDetailIsLoading: loadingSelector([actionTypes.GET_TRANSFER_DETAIL])(state),
  acceptTransferIsLoading: loadingSelector([actionTypes.ACCEPT_TRANSFER])(state),
  rejectTransferIsLoading: loadingSelector([actionTypes.REJECT_TRANSFER])(state),
  hasErrorA: hasErrorsSelector([actionTypes.ACCEPT_TRANSFER])(state),
  errorA: singleErrorSelector([actionTypes.ACCEPT_TRANSFER])(state),
  handlerSuccess: message => handlerSuccess(message),
  handlerError: message => handlerError(message),

  associatedOrders: selectAssociatedOrders(state),
  associatedOrdersIsLoading: loadingSelector([actionTypes.GET_ASSOCIATED_ORDERS])(state),
  confirm: isAllowed(state, [12503]),
})

const mapDispatchToProps = dispatch => ({
  getTransferDetail: id => dispatch(getTransferDetail(id)),
  acceptTransfer: id => dispatch(acceptTransfer(id)),
  rejectTransfer: id => dispatch(rejectTransfer(id)),
  getAssociatedOrders: id => dispatch(getAssociatedOrders(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DetailTransfers)
