import React from 'react'

import { ProgressBar } from 'react-bootstrap'

interface IProps {
  show: boolean
  percentage?: number
  label?: string
  progress?: boolean
}

/** Render a loading component
 * @param {boolean} show Indicate if the componente render
 * @param {boolean} percentage Current value of progress
 * @param {string} label Show label that represents visual percentage
 * @param {boolean} progress abc
 * */
const Loading: React.FC<IProps> = ({
  show,
  percentage = 100,
  label,
  progress,
}: IProps) => {
  const principalProgress = (
    <ProgressBar label={label || 'Cargando'} striped animated now={percentage} key={1} />
  )

  return (
    show &&
    (progress ? <ProgressBar>{principalProgress}</ProgressBar> : principalProgress)
  )
}
export default Loading
