import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Button, CustomDate, Title } from 'src/components'
interface RangeDateModaProps {
  show?: boolean
  onHide?: () => void
  mode?: 'Credito' | 'Transferencia'
  handlePaymentDate: (value: Date) => void
  validateOrder?: () => void
}
const RangeDateModal: React.FC<RangeDateModaProps> = ({
  show,
  onHide,
  handlePaymentDate,
}: RangeDateModaProps) => {
  const handleChange = data => {
    handlePaymentDate(data.valueOf())
  }

  return (
    <Modal show={show} onHide={onHide} centered size={'lg'}>
      <Modal.Header closeButton>
        <Modal.Title>Fecha de Registro</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <CustomDate
          label={'Fecha de registro'}
          required
          onDayChange={handleChange}
          disabledDays={{ before: new Date() }}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={onHide}>Continuar</Button>
      </Modal.Footer>
    </Modal>
  )
}
export default RangeDateModal
