import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Modal, Container, Tabs, Tab } from 'react-bootstrap'

import {
  ListCategories,
  Icon,
  Button,
  Card,
  CustomDate,
  Dropdown,
  TableV2,
  Title,
  Folder,
  Money,
} from 'src/components'
import {
  faBook,
  faFileExcel,
  faFilePdf,
  faReceipt,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes as typesOder,
  getDelivererProductReport,
  getDeliverReport,
  getSellerProductReport,
  getSellerReport,
  getSingleOrder,
  getUsersReport,
} from 'src/actions/orders.actions'

import {
  selectDelivererReport,
  selectFormatProducts,
  selectGetSingleOrder,
  selectProductReport,
  selectSellerReport,
  selectUsersReport,
} from 'src/selectors/orders.selector'

import { actionTypes as clientType, getReportLog } from 'src/actions/clients.actions'
import { selectClientReports } from 'src/selectors/clients.selector'
import { selectCurrentModule } from 'src/selectors/user.selector'

import {
  getClientsExcelReport,
  getClientsPdfReport,
  getUserExcelReport,
  getUserPdfReport,
  actionTypes as reportType,
} from 'src/actions/report.actions'

import {
  actionTypes as action,
  getAllCategorizations,
} from 'src/actions/categorization.actions'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'

import { selectCurrentCurrency } from 'src/selectors/currencies.selector'
import { toMoney } from 'src/utils/utilities'
import { formatDateFromMillis, formatNumberWithCommas } from 'src/utils/formatters'

import { loadingSelector } from 'src/selectors/loading.selector'

class Users extends Component {
  state = {
    startDate: new Date(),
    endDate: new Date(),
    show0: false,
    show1: false,
    show2: false,
    show3: false,
    key: 0,
    showOrders: false,
    showProducts: false,
    orders: [],
    line: true,
    factor: false,
    showDetail: false,
    selectedCategorization: [],
    showCategorizations: false,
  }

  componentDidMount() {
    this.getInfo()
    this.props.getCategories()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      loadingUsers,
      loadingDeliverer,
      loadingSeller,
      productsAreLoading,
      orderIsLoading,
      reportLoading,
    } = this.props

    if (loadingUsers && !nextProps.loadingUsers) this.setState({ show0: true })

    if (loadingDeliverer && !nextProps.loadingDeliverer) this.setState({ show1: true })

    if (loadingSeller && !nextProps.loadingSeller) this.setState({ show2: true })

    if (reportLoading && !nextProps.reportLoading) this.setState({ show3: true })

    if (productsAreLoading && !nextProps.productsAreLoading)
      this.setState({ showProducts: true })

    if (orderIsLoading && !nextProps.orderIsLoading)
      this.setState({ showDetail: true, showOrders: false })
  }

  getInfo() {
    const { startDate, endDate, selectedCategorization } = this.state
    let sDate = startDate.setHours(0, 0, 0, 0).valueOf()
    let eDate = endDate.setHours(23, 59, 59, 59).valueOf()
    this.props.getUsers(sDate, eDate, {
      categories: selectedCategorization.map(p => p.id),
      pos: [],
    })
    this.props.getReport(sDate, eDate)
  }

  onDateFromChange = date => {
    if (date) {
      this.setState({ startDate: date })
    }
  }

  onDateToChange = date => {
    if (date) {
      this.setState({ endDate: date })
    }
  }

  getOrderTotal = details => {
    if (!details) return formatNumberWithCommas(0)
    let total = 0
    details.map(d => (total += parseFloat(d.total) - parseFloat(d.discount)))
    return total.toFixed(2)
  }

  sortInfo = array => {
    let info = []
    array
      .filter(
        r =>
          r.total > 0 ||
          r.visitTotal > 0 ||
          r.deposits > 0 ||
          r.utility > 0 ||
          r.cpp > 0 ||
          r.deliverer > 0,
      )
      .forEach(r => {
        info.push({
          name: r.name,
          // detail: r.detail,
          visitTotal: r.visitTotal, //this.getTotalSale(r.visit_detail || []),
          total: r.total, //this.getTotalSale(r.detail),
          payments: r.deposits, //this.getTotalPayments(r.payments),
          expenses: r.deliverer, //this.getTotalExpenses(r.expenses),
          rate: r.gratification, //this.getTotalPurchase(r.detail),
          restaurant: r.cpc, //this.getTotalRestaurant(r.comandas),
          quantity: r.quantity,
          id: r.id,
          // email: r.name
        })
      })

    return info.sort((a, b) => b.total - a.total)
  }

  setKey = k => {
    this.setState({ key: k, labels: [] })
  }

  getCategorizationString = () => {
    const { selectedCategorization } = this.state
    let categorization = null
    selectedCategorization.forEach(s => {
      if (!categorization) categorization = ''
      categorization += s.id + ','
    })
    return categorization
      ? categorization.substring(0, categorization.length - 1)
      : undefined
  }

  getProductReport = user => {
    const { key, startDate, endDate } = this.state
    let sDate = startDate.setHours(0, 0, 0, 0).valueOf()
    let eDate = endDate.setHours(23, 59, 59, 59).valueOf()
    if (key === 2)
      this.props.getSellerProductReport(
        sDate,
        eDate,
        user,
        this.getCategorizationString(),
      )
    else
      this.props.getDelivererProductReport(
        sDate,
        eDate,
        user,
        this.getCategorizationString(),
      )
  }

  getHeadersDetail = () => {
    return [
      {
        label: 'Cantidad',
        show: true,
        value: ['quantity'],
        type: 'wholeNumber',
        className: 'mini',
      },
      {
        label: 'Producto',
        show: true,
        value: ['product', 'name'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Precio',
        show: true,
        value: ['precioC'],
        type: 'text',
        className: 'mini',
        custom: item =>
          (item.precioC = toMoney(item.total ? item.total / item.quantity : 0)),
      },
      {
        label: 'Subtotal',
        show: true,
        value: ['total'],
        type: 'currency',
        className: 'mini',
      },
      {
        label: 'Descuento',
        show: true,
        value: ['discount'],
        type: 'currency',
        className: 'mini',
      },
      {
        label: 'Total',
        show: true,
        value: ['totalC'],
        type: 'text',
        className: 'mini',
        custom: item =>
          (item.totalC = toMoney(item.total ? item.total - item.discount : 0)),
      },
      { config: true, show: true, label: '', className: 'mini center' },
    ]
  }

  getActions = item => {
    return (
      <Dropdown
        items={[
          {
            show: true,
            title: 'Ver Productos vendidos',
            action: () => this.getProductReport(item.id),
          },
        ]}
      />
    )
  }

  getHeaders = () => {
    return [
      // { label: 'ID', show: true, value: ['id'], type: 'text', className: 'mini' },
      { label: 'Nombre', show: true, value: ['name'], type: 'text', className: 'mini' },
      // { label: 'Email', show: true, value: ['email'], type: 'text', className: 'mini' },
      {
        label: 'Ordenes Entregadas',
        show: true,
        value: ['quantity'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Total visitas',
        show: true,
        value: ['visitTotal'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Total vendido',
        show: true,
        value: ['total'],
        type: 'currency',
        className: 'mini',
      },
      {
        label: 'Utilidad Bruta',
        show: true,
        value: ['rate'],
        type: 'currency',
        className: 'mini',
      },
      {
        label: 'Gastos',
        show: true,
        value: ['expenses'],
        type: 'currency',
        className: 'mini',
      },
      {
        label: 'Depósitos',
        show: true,
        value: ['payments'],
        type: 'currency',
        className: 'mini',
      },
      //   {
      //     label: 'Comandas',
      //     show: true,
      //     value: ['restaurant'],
      //     type: 'currency',
      //     className: 'mini',
      //   },
      {
        config: true,
        show: true,
        className: 'mini center',
        type: 'text',
        value: ['button'],
        label: 'Acción',
        custom: item => this.getActions(item),
      },
    ]
  }

  getHeadersReport = () => {
    return [
      // { label: 'ID', show: true, value: ['id'], type: 'text', className: 'mini' },
      { label: 'Nombre', show: true, value: ['name'], type: 'text', className: 'mini' },
      { label: 'Email', show: true, value: ['email'], type: 'text', className: 'mini' },
      {
        label: 'Clientes Activados',
        show: true,
        value: ['activated'],
        type: 'wholeNumber',
        className: 'mini',
      },
      {
        label: 'Clientes Inactivados',
        show: true,
        value: ['inactivated'],
        type: 'wholeNumber',
        className: 'mini',
      },
      {
        label: 'Clientes Editados',
        show: true,
        value: ['edited'],
        type: 'wholeNumber',
        className: 'mini',
      },
      {
        label: 'Clientes Creados',
        show: true,
        value: ['created'],
        type: 'wholeNumber',
        className: 'mini',
      },
      { config: true, show: true, label: '', className: 'mini center' },
    ]
  }

  /**
   * @function Agrega o Remueve categorías seleccionadas para el filtrado de los ítems
   * @param {ICategory} item categoría seleccionada por el componente Folder
   * */
  onAssignCategorizationToFilter = item => {
    /*
    * f (selectedCategorization.findIndex(p => p.id === item.id) === -1) {
              selectedCategorization.push(item)
              this.setState({ selectedCategorization }, () => {
                this.getInfo(startDate, endDate)
              })
            }
    * */

    const customCategories = Object.assign([], this.state.selectedCategorization)
    const index = customCategories.findIndex(sc => sc.id === item.id)
    if (index === -1) customCategories.push(item)
    else customCategories.splice(index, 1)
    this.setState({ selectedCategorization: customCategories })
  }

  render() {
    const {
      startDate,
      endDate,
      show0,
      show3,
      key,
      showOrders,
      orders,
      showProducts,
      line,
      showDetail,
      selectedCategorization,
      showCategorizations,
    } = this.state
    const {
      users,
      module,
      products,
      formatProducts,
      order,
      report,
      categorization,
      reportLoading,
      loadingUsers,
      loadingExcel,
      loadingPdf,
    } = this.props
    return (
      <div>
        <Title title={'Reporte de Ventas por Usuarios'} />
        <Row>
          <Col>
            <Card
              title={'Filtros'}
              white
              button={
                <Dropdown
                  tooltip={'Reportes'}
                  disabled={loadingUsers || reportLoading}
                  loading={loadingExcel || loadingPdf}
                  items={[
                    {
                      icon: faFileExcel,
                      title: 'Descargar Excel',
                      action: () => {
                        let sDate = startDate.setHours(0, 0, 0, 0).valueOf()
                        let eDate = endDate.setHours(23, 59, 59, 59).valueOf()
                        if (Number(key) === 0)
                          this.props.getUserExcel(
                            sDate,
                            eDate,
                            selectedCategorization.map(p => p.id),
                          )
                        else if (Number(key) === 3)
                          this.props.getClientExcel(sDate, eDate)
                      },
                    },
                    {
                      icon: faFilePdf,
                      title: 'Descargar PDF',
                      action: () => {
                        let sDate = startDate.setHours(0, 0, 0, 0).valueOf()
                        let eDate = endDate.setHours(23, 59, 59, 59).valueOf()
                        if (key === 0)
                          this.props.getUserPdf(
                            sDate,
                            eDate,
                            selectedCategorization.map(p => p.id),
                          )
                        else if (key === 3) this.props.getClientPdf(sDate, eDate)
                      },
                    },
                  ]}
                />
              }>
              <Row>
                <Col xl={6} lg={6} md={6} sm={12}>
                  <CustomDate
                    label={'Desde:'}
                    value={startDate}
                    onDayChange={date => this.setState({ startDate: date })}
                  />
                </Col>
                <Col xl={6} lg={6} md={6} sm={12}>
                  <CustomDate
                    label={'Hasta:'}
                    value={endDate}
                    onDayChange={date => this.setState({ endDate: date })}
                    disabledDays={{ before: startDate }}
                  />
                </Col>
                <Col xl={12}>
                  <Button
                    style={{ marginTop: 5 }}
                    color={'primary'}
                    onClick={() => this.setState({ showCategorizations: true })}>
                    Filtrar por categorías
                  </Button>
                </Col>
                <Col xl={12}>
                  <Row className={'pl-1'}>
                    <ListCategories
                      items={selectedCategorization}
                      onRemove={this.onAssignCategorizationToFilter}
                    />
                  </Row>
                </Col>
                <Col xl={12}>
                  <Row className={'container-buttons'}>
                    <Button
                      color={'accent'}
                      icon={faSearch}
                      loading={loadingUsers || reportLoading}
                      onClick={() => this.getInfo()}>
                      Aplicar Filtros
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Card white>
          <Tabs
            activeKey={key}
            onSelect={this.setKey}
            style={{
              justifyContent: 'center',
              position: 'static',
              marginBottom: 5,
              borderRadius: 10,
            }}>
            <Tab eventKey={0} title="Usuarios">
              {show0 && (
                <>
                  <div style={{ backgroundColor: 'white', color: 'default' }}>
                    <Card>
                      <TableV2
                        loading={loadingUsers || reportLoading}
                        headers={this.getHeaders()}
                        items={this.sortInfo(users)}
                        mobileAuto
                        storageKey={`users`}
                      />
                    </Card>
                  </div>
                </>
              )}
            </Tab>
            <Tab eventKey={3} title="Clientes">
              {show3 && (
                <div style={{ backgroundColor: 'white', color: 'default' }}>
                  <Card>
                    <TableV2
                      headers={this.getHeadersReport()}
                      items={report}
                      mobileAuto
                      storageKey={`usersReport`}
                    />
                  </Card>
                </div>
              )}
            </Tab>
          </Tabs>
        </Card>

        <Modal
          show={showOrders}
          size={'md'}
          centered
          aria-labelledby={'contained-modal-title-vcenter'}
          onHide={() => this.setState({ showOrders: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Órdenes de venta</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <TableV2
              headers={[
                {
                  label: 'id',
                  show: true,
                  value: ['id'],
                  type: 'text',
                  className: 'medium',
                },
                {
                  label: 'No. Orden',
                  show: true,
                  value: ['order'],
                  type: 'text',
                  className: 'medium',
                },
                {
                  label: 'Cliente',
                  show: true,
                  value: ['client'],
                  type: 'text',
                  className: 'medium',
                },
                {
                  label: 'Total',
                  show: true,
                  value: ['total'],
                  type: 'currency',
                  className: 'mini',
                },
                {
                  config: true,
                  show: true,
                  label: '',
                  className: 'mini center',
                  custom: item =>
                    (module === 1000 || module === 2000) && (
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Icon
                          icon={faBook}
                          tooltip={'Ver detalle completo'}
                          onClick={() => {
                            if (module === 2000)
                              this.props.history.push(
                                `/produccion/ordenes/detalle/${item.id}`,
                              )
                            else
                              this.props.history.push(
                                `/distribucion/ordenes/detalle/${item.id}`,
                              )
                          }}
                        />
                        <Icon
                          icon={faReceipt}
                          tooltip={'Ver detalle completo'}
                          onClick={() => {
                            this.props.getSingleOrder(item.id)
                          }}
                        />
                      </div>
                    ),
                },
              ]}
              items={orders}
              mobileAuto
              storageKey={`usersOrders`}
            />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Modal
          show={showProducts}
          size={'lg'}
          centered
          aria-labelledby={'contained-modal-title-vcenter'}
          onHide={() => this.setState({ showProducts: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Productos vendidos</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TableV2
              headers={[
                {
                  label: 'Nombre',
                  show: true,
                  value: ['product', 'name'],
                  type: 'text',
                  className: 'mini',
                },
                {
                  label: 'Cantidad',
                  show: true,
                  value: ['cantidadC'],
                  type: 'text',
                  className: 'mini',
                  custom: item =>
                    (item.cantidadC = item.quantity
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')),
                },
                {
                  label: 'Descuento',
                  show: true,
                  value: ['discount'],
                  type: 'currency',
                  className: 'mini',
                },
                {
                  label: 'Total',
                  show: true,
                  value: ['total'],
                  type: 'currency',
                  className: 'mini',
                },
                { config: true, show: true, label: '', className: 'mini center' },
              ]}
              items={line ? products.filter(r => r.quantity > 0) : formatProducts}
              mobileAuto
              storageKey={`usersProducts`}
            />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Modal
          show={showDetail} //showCashBox
          size={'lg'}
          centered
          aria-labelledby={'contained-modal-title-vcenter'}
          onHide={() => this.setState({ showDetail: false, showOrders: true })}>
          <Modal.Header closeButton>
            <Modal.Title>Detalle de Orden</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container className="invoice">
              <Row>
                <Col xs={8}>
                  <h4>
                    <b>Cliente: </b>
                    {order.client ? order.client.companyName : 'N/A'}
                  </h4>
                </Col>
                <Col xs={4} className={'text-center'}>
                  <b>Última fecha de actualización </b>
                  {order.date ? formatDateFromMillis(order.date) : 'N/A'}
                </Col>
              </Row>

              <Row>
                <Col>
                  <h4>
                    <b>Bodega: </b>
                    {order.warehouse ? order.warehouse.name : 'Sin Bodega'}
                  </h4>
                </Col>
              </Row>

              <Row>
                <Col>
                  <br />
                </Col>
              </Row>

              <Row className="text-center">
                <Col>
                  <b>Tipo</b>
                  <br /> {order.orderTypeData && order.orderTypeData.name}
                </Col>
                <Col>
                  <b>Estado</b> <br /> {order.statusData && order.statusData.name}
                </Col>
                <Col>
                  <b>Tipo de venta</b> <br />{' '}
                  {order.paymentType
                    ? order.paymentType === 1
                      ? 'Contado'
                      : 'Credito'
                    : '---'}
                </Col>
                {order.enabledAt ? (
                  <Col>
                    <b>Fecha estimada de entrega</b> <br />{' '}
                    {formatDateFromMillis(order.enabledAt)}
                  </Col>
                ) : (
                  ''
                )}
              </Row>
              {order.description ? (
                <Row>
                  <Col>
                    <b>Descripcion</b>
                    <br /> {order.description}
                  </Col>
                </Row>
              ) : (
                ''
              )}
              <Row>
                <br />
                <Col className={'text-center'}>
                  {order.deleted ? <b className={'red-text'}>Orden Eliminada</b> : ''}
                </Col>
              </Row>
              <hr />
              {order && showDetail && (
                <>
                  <TableV2
                    headers={this.getHeadersDetail()}
                    mobileAuto
                    storageKey={`userDetail`}
                    items={order.details}
                  />
                  <Row>
                    <Col xs={10} />
                    <Money component={Col} xs="auto">
                      <h2>
                        Total:{' '}
                        {this.getOrderTotal(order.details, order.type, order.balance)}
                      </h2>
                    </Money>
                  </Row>
                </>
              )}
            </Container>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Folder
          noMessage
          onHide={() => this.setState({ showCategorizations: false })}
          onAssign={this.onAssignCategorizationToFilter}
          data1={
            categorization && categorization.children ? categorization.children[0] : {}
          }
          data2={
            categorization && categorization.children ? categorization.children[1] : {}
          }
          show={showCategorizations}
          list={selectedCategorization.map(s => s.id)}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  users: selectUsersReport(state),
  loadingUsers: loadingSelector([typesOder.GET_USERS_REPORT])(state),
  deliverers: selectDelivererReport(state),
  loadingDeliverer: loadingSelector([typesOder.GET_DELIVERER_REPORT])(state),
  sellers: selectSellerReport(state),
  loadingSeller: loadingSelector([typesOder.GET_SELLER_REPORT])(state),
  module: selectCurrentModule(state),
  products: selectProductReport(state),
  productsAreLoading: loadingSelector([typesOder.GET_PRODUCT_REPORT])(state),
  formatProducts: selectFormatProducts(state),
  order: selectGetSingleOrder(state),
  orderIsLoading: loadingSelector([typesOder.GET_SINGLE_ORDER])(state),
  reportLoading: loadingSelector([clientType.GET_REPORT_LOG])(state),
  report: selectClientReports(state),
  categorization: selectAllCategorizations(state),
  categorizationLoading: loadingSelector([action.GET_ALL])(state),
  currentCurrency: selectCurrentCurrency(state),
  loadingExcel: loadingSelector([reportType.GET_EXCEL])(state),
  loadingPdf: loadingSelector([reportType.GET_PDF])(state),
})

const mapDispatchToProps = dispatch => ({
  getUsers: (sDate, eDate, categories) =>
    dispatch(getUsersReport(sDate, eDate, categories)),
  getDeliverers: (sDate, eDate) => dispatch(getDeliverReport(sDate, eDate)),
  getSellers: (sDate, eDate) => dispatch(getSellerReport(sDate, eDate)),
  getSellerProductReport: (sDate, eDate, user, categorization) =>
    dispatch(getSellerProductReport(sDate, eDate, user, categorization)),
  getReport: (sDate, eDate) => dispatch(getReportLog(sDate, eDate)),
  getDelivererProductReport: (sDate, eDate, user, categorization) =>
    dispatch(getDelivererProductReport(sDate, eDate, user, categorization)),
  getSingleOrder: id => dispatch(getSingleOrder(id)),
  getUserPdf: (sDate, eDate, categories) =>
    dispatch(getUserPdfReport(sDate, eDate, categories)),
  getUserExcel: (sDate, eDate, categories) =>
    dispatch(getUserExcelReport(sDate, eDate, categories)),
  getClientPdf: (sDate, eDate) => dispatch(getClientsPdfReport(sDate, eDate)),
  getClientExcel: (sDate, eDate) => dispatch(getClientsExcelReport(sDate, eDate)),
  getCategories: () => dispatch(getAllCategorizations(16)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Users)
