import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Col, Row} from "react-bootstrap";
import {SwitchV2} from "src/components";
import FormText from "src/components/inputs/FormTextField/FormText";

interface IProps {
    label: string
    title: string
    sheetId: string
    onChange: (sheetId: string) => void
}

/**
 * Component to configure the Google Sheet to use for the spreadsheet
 * @param label
 * @param sheetId       The ID of the Google Sheet
 * @param onChange      Callback to call when the sheet ID changes
 * @constructor         Constructor
 */
const GoogleSheetSelector = ({
         label,
         title,
         sheetId,
         onChange
}: IProps) => {

    const enabled = sheetId !== null && sheetId !== 'null'

    return (
        <Row>
            <Col>
                <SwitchV2
                    info={label}
                    label={title}
                    checked={enabled}
                    onChange={(value: boolean) => {
                        onChange(value ? "" : null)
                    }}
                />
            </Col>
            <Col>
                {enabled && <FormText
                    label={'Id de la pagina'}
                    mt={0}
                    prepend={'#'}
                    info={''}
                    type={'text'}
                    onFocus={event => event.target.select()}
                    value={sheetId}
                    changeValue={v =>
                        onChange(v)
                    }
                    rows={6}
                />}
            </Col>
        </Row>
    )

}

export default GoogleSheetSelector