import React from 'react'

import {
  ButtonGroup,
  DropdownButton,
  Dropdown as DropdownBootstrap,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import Icon from 'src/components/buttons/IconButton'

const Dropdown = ({
  loading = false,
  style = {},
  disabled = false,
  variant = 'primary',
  position = 'start',
  title = '',
  items = [],
  onClick = () => undefined,
  tooltip = null,
}) => {
  const filterFunction = item => item.show == null || item.show
  const filteredItems = items?.filter(filterFunction) || []
  const withoutItems = filteredItems.length === 0

  if (withoutItems) return <div />

  return loading ? (
    <Spinner variant={'secondary'} size={'md'} animation={'grow'} />
  ) : (
    <OverlayTrigger
      style={{ dataHtml: true }}
      placement={'top'}
      overlay={tooltip ? <Tooltip id="tooltip">{tooltip}</Tooltip> : <div />}>
      <div>
        <ButtonGroup size={'sm'} style={{ textTransform: 'none', ...style }}>
          <DropdownButton
            onClick={e => e.stopPropagation()}
            className={'dp-custom'}
            variant={variant}
            disabled={disabled}
            drop={position || 'start'}
            as={ButtonGroup}
            title={title}
            id={`dropdown-button-drop-${position || 'start'}`}>
            {filteredItems.map((item, i) => (
              <div key={i}>
                {item.divide && <DropdownBootstrap.Divider />}
                <OverlayTrigger
                  style={{ dataHtml: true }}
                  placement={'left'}
                  overlay={
                    item.tooltip ? (
                      <Tooltip id="tooltip">{item.tooltip}</Tooltip>
                    ) : (
                      <div />
                    )
                  }>
                  <DropdownBootstrap.Item
                    {...item}
                    eventKey={i}
                    onClick={() => {
                      if (onClick) onClick(item)
                      if (item.action) item.action()
                    }}>
                    {item.icon && !item.right && (
                      <Icon
                        icon={item.icon}
                        color={item.iconColor}
                        style={{ display: 'inline', marginRight: '8px' }}
                      />
                    )}
                    {item.title}
                    {item.icon && item.right && (
                      <Icon
                        icon={item.icon}
                        color={item.iconColor}
                        style={{ display: 'inline', marginLeft: '8px' }}
                      />
                    )}
                  </DropdownBootstrap.Item>
                </OverlayTrigger>
              </div>
            ))}
          </DropdownButton>
        </ButtonGroup>
      </div>
    </OverlayTrigger>
  )
}
export default Dropdown
