import React, { Component } from 'react'
import { connect } from 'react-redux'

import Card from '../../components/cards/Card'
import Button from '../../components/buttons/Button'
import Gallery, { imageTypes } from '../../components/gallery/Gallery'
import ProductHistoryPrice from './ProductHistoryPrice/ProductHistoryPrice'

import { history } from '../../App'

import { Col, Row, ProgressBar } from 'react-bootstrap'
import SweetAlert from 'sweetalert-react'

import { store } from '../../reducers'

import {
  actionTypes as productActionTypes,
  deleteProduct,
  getCompaniesByModelProduct,
  getExistenceAcrossWarehouses,
  getExistenceExternalWarehouses,
  getProducts,
  getReport,
  getSingleProduct,
  updateProduct,
} from '../../actions/products.actions'
import {
  selectAllProductsForSelect,
  selectExistenceAcrossWarehouse,
  selectExistenceConsignated,
  selectSingleProduct,
  getBaseProductWithSons,
  selectAllProducts,
  selectCompaniesByModel,
  selectProductReport,
} from '../../selectors/products.selector'

import {
  actionTypes,
  deleteFile,
  updateFile,
  uploadFile,
  uploadVideo,
} from '../../actions/uploads.actions'

import { loadingSelector } from '../../selectors/loading.selector'
import {
  hasErrorsSelector,
  singleErrorSelector,
  handlerError,
  handlerSuccess,
} from '../../selectors/error.selector'

import { selectCurrentCompany, selectCurrentModule } from '../../selectors/user.selector'
import { getAllOrdersByModelProduct } from '../../actions/orders.actions'
import {
  actionTypes as action,
  assignCategorizationToEntity,
  getAllCategorizations,
  getCategorizationsByEntity,
} from '../../actions/categorization.actions'
import { selectAllCategorizations } from '../../selectors/categorizations.selector'

import PolygonContractedByProduct from './PolygonContractedByProduct'
import { FilePicker } from 'react-file-picker'
import CustomTabs from '../../components/navigation/Tabs/CustomTabs'
import { selectVideos } from '../../selectors/uploads.selector'
import ProductDetail from '../../components/custom/ProductDetail/ProductDetail'
import Folder, { categoryType } from '../../components/folders/Folder'
import { selectCompanyIsLimit } from '../../selectors/company.selector'
import { TableV2 } from '../../components'

const today = new Date()

class ProductDetails extends Component {
  state = {
    name: '',
    code: '',
    barcode: '',
    description: '',
    category: {},
    subCategory: {},
    baseProduct: { label: '- Ninguno -', value: null },
    conversionFactor: null,
    price: null,
    production: null,
    company: null,
    errors: {},
    uploadProgress: 0,
    showSuccess: false,
    showError: false,
    showWarning: false,
    showDeleteSuccess: false,
    showDeleteError: false,
    showDetail: false,
    showCategorizations: false,
    transformation: false,

    editing: false,

    modal: false,

    enumeration: {
      name: null,
      description: null,
      restricted: false,
      public: false,
      input: { label: 'Ninguno', value: { id: null, quantity: 0 } },
      output: [],
    },
    output: [],
    selected: { label: 'Ninguno', value: { id: null, quantity: 0 } },
    info: {},
    alert: {
      show: false,
      title: 'default',
      text: 'default',
      type: 'info',
      onConfirm: () => console.log('default'),
    },
    labels: [],
    filter: { value: 0, label: 'día' },
    historyProduct: [],
    actual: null,
    dateFrom: new Date(today.getFullYear(), today.getMonth(), 1),
    dateTo: today,

    publicRoot: { value: 0, label: 'Seleccione una categorizacion' },
    root: { value: 0, label: 'Seleccione una categorizacion' },
    categorizationChildren: [],
    publicChildren: [],
    activeOnline: false,
    item: {},
    subPrice: 0,
    activeLink: false,
    father: false,
    contractedPolygon: { show: false, params: {} },
    activePurchase: false,
    isExpense: false,
    selectedExpense: {},
    activeUtility: false,
    uploading: false,
    showErrorVideo: false,
    parcel: {},
  }

  componentDidMount() {
    const { id } = this.props.match.params
    const { module } = this.props

    const { dateFrom } = this.state
    this.props.getCategorizationsByProduct(id)
    this.props.getAllProducts()
    this.props.getAllCategorization()
    this.props.getSingleProduct(id)

    this.props.getProductPriceHistory(id, dateFrom.getTime(), today.getTime())
    if (module === 11000)
      this.props.getOrdersByModelProduct(id, dateFrom.getTime(), today.getTime())
    if (module === 11000) this.props.getCompaniesByModelProduct(id)
  }

  UNSAFE_componentWillReceiveProps(next) {
    const {
      products,
      product,
      updateProductIsLoading,
      updateProductHasError,
      deleteProductIsLoading,
      deleteProductHasError,
      productPriceHistoryLoading,
      addIsLoading,
      addHasError,
      pLoading,
    } = this.props
    const nProduct = next.product
    const nProducts = next.products
    const productsA = next.productsA
    const { addError } = next

    if (pLoading && !next.pLoading) {
      this.props.getExistenceAcrossWarehouses(this.props.match.params.id)
      this.props.getExistenceExternalWarehouses(this.props.match.params.id)
    }

    if (updateProductIsLoading && !next.updateProductIsLoading) {
      if (!updateProductHasError && next.updateProductHasError) {
        this.setState({ showSuccess: false, showError: true })
      } else {
        this.setState({ showSuccess: true, showError: false })
      }
    }

    if (deleteProductIsLoading && !next.deleteProductIsLoading) {
      if (!deleteProductHasError && next.deleteProductHasError) {
        this.setState({ showDeleteSuccess: false, showDeleteError: true })
      } else {
        this.setState({ showDeleteSuccess: true, showDeleteError: false })
      }
    }
    if (product !== nProduct && nProduct) {
      this.setState(
        {
          transformation: nProduct.transformation,
          conversionFactor: nProduct.transformationFactor,
          name: nProduct.name,
          code: nProduct.code,
          barcode: nProduct.barcode || '',
          description: nProduct.description || '',
          category: {
            value: nProduct.categoryData ? nProduct.categoryData.id : null,
            label: nProduct.categoryData ? nProduct.categoryData.name : null,
          },
          subCategory: {
            value: nProduct.subCategoryData ? nProduct.subCategoryData.id : null,
            label: nProduct.subCategoryData ? nProduct.subCategoryData.name : null,
          },
          price: nProduct.price,
          production: nProduct.productionPrice,
          company: nProduct.companyId,
          activeOnline: nProduct.activeOnline,
          subPrice: nProduct.subPrice,
          father: nProduct.subProductData !== null,
          activeLink: nProduct.activeLink,
          activeUtility: nProduct.activeUtility,
          activePurchase: nProduct.activePurchase,
          isExpense: !!nProduct.expenseType,
          parcel: nProduct.parcel
            ? {
                ...nProduct.parcel,
                label: nProduct.parcel.largeName,
                value: nProduct.parcel.id,
              }
            : {},
        },
        () => {
          if (nProduct.categoryData)
            this.props.getProductSubCategories(nProduct.categoryData.id)
        },
      )
    }

    if (nProduct && products !== nProducts && nProducts) {
      if (nProduct.baseProductId) {
        const list = this.props.getBaseProductWithSons(productsA)
        const base = list.find(l => l.productId === nProduct.baseProductId)
        if (base) {
          const ap = base.child.sort(
            (a, b) => a.transformationFactor - b.transformationFactor,
          )
          const lineProducts = ap.map((c, i) => ({ ...c, i }))
          const lProduct = lineProducts.find(l => l.productId === nProduct.id)
          if (lProduct && lProduct.i > 0) {
            const newLProduct = lineProducts.find(l => l.i === lProduct.i - 1)
            const conversionFactor =
              (nProduct.transformationFactor || 1) /
              (newLProduct.transformationFactor || 1)
            const baseProduct = nProducts.find(v => v.value === newLProduct.productId)
            if (baseProduct) this.setState({ baseProduct, conversionFactor })
          }
        }
      }
    }

    if (addIsLoading && !next.addIsLoading) {
      let { alert } = this.state
      if (addIsLoading && !next.addIsLoading) {
        if (!addHasError && next.addHasError) {
          alert = handlerError(
            addError.response
              ? addError.response.data.message
              : 'ha ocurrido un error inesperado.',
          )
          alert.onConfirm = () => {
            this.setState({ alert: { ...alert, show: false } })
          }
        } else {
          alert = handlerSuccess('Categorías actualizadas')
          alert.onConfirm = () => {
            this.setState({ alert: { ...alert, show: false } })
          }
          this.props.getCategorizationsByProduct(this.props.match.params.id)
        }
      }
      this.setState({ alert })
    }

    if (productPriceHistoryLoading && !next.productPriceHistoryLoading) {
      this.setState({ actual: next.priceHistory[0] })
    }
  }

  filter = item => {
    return (
      item.enumerationDetails.filter(
        ed =>
          ed.detailType === 1 && ed.id.productId === parseInt(this.props.match.params.id),
      ).length > 0
    )
  }

  uploadVideo = file => {
    const { product } = this.props
    this.setState({ uploading: true })

    const onProgress = progress => this.setState({ progress })

    const onError = error => {
      this.setState({
        progress: 0,
        showErrorVideo: true,
        errorTextVideo: 'Lo sentimos, no fué posible subir tu video en este momento',
      })
    }

    const onSuccess = (url, randomName) => {
      this.setState({ uploading: false, uploadProgress: 0, urlVideo: url })
      this.props.updateFile({
        imageType: imageTypes.PRODUCT,
        entityId: product.id,
        url,
        randomName,
        company: product.companyId,
      })
    }

    this.props.uploadVideo(file, onProgress, onSuccess, onError, imageTypes.PRODUCT.path)
  }

  assignCategorizationToProduct(categorization) {
    const { id } = this.props.match.params
    this.props.assignCategorizationToProduct(categorization, id)
  }

  render() {
    const { company, showCategorizations } = this.state
    const { showSuccess, showError, showWarning, showDeleteError, showDeleteSuccess } =
      this.state
    const { product, existenceAcrossWarehouses, existenceConsignated, categorization } =
      this.props

    const currentCompanyId = selectCurrentCompany(store.getState())

    const { alert, item } = this.state
    const { id } = this.props.match.params
    const { module, companies, videos, isLimit } = this.props
    const { contractedPolygon, uploading } = this.state

    return (
      <div>
        <h1 className={'dashboard-welcome'}>{product && product.name}</h1>
        <Row>
          <Col md={4}>
            <Card
              status={product.active ? 'Activo' : 'Inactivo'}
              statusColor={product.active ? 'green' : 'red'}
              style={{ height: '100%' }}>
              <CustomTabs
                items={[
                  {
                    title: 'Imágenes',
                    info: 'Imágenes relacionadas al producto',
                    component: (
                      <Gallery
                        company={product && product.companyId}
                        imageType={imageTypes.PRODUCT}
                        entityId={product && product.id}
                        showUploader={company === currentCompanyId}
                        maxWidth={300}
                      />
                    ),
                  },
                  {
                    title: 'Videos',
                    info: 'Videos relacionados al producto',
                    component: (
                      <div style={{ maxHeight: 400, overflow: 'scroll' }}>
                        {videos && videos.length > 0 ? (
                          videos.map((item, index) => (
                            <Row key={index}>
                              <video width="320" height="240" controls>
                                <source src={item.url + '#t=10'} type="video/mp4" />
                              </video>
                            </Row>
                          ))
                        ) : (
                          <h4 style={{ textAlign: 'center', marginTop: 50 }}>
                            Sin videos para mostrar
                          </h4>
                        )}
                        {company === currentCompanyId && (
                          <FilePicker
                            maxSize={250}
                            extensions={['mp4']}
                            onChange={this.uploadVideo}
                            onError={errMsg =>
                              this.setState({
                                showErrorVideo: true,
                                errorTextVideo: errMsg,
                              })
                            }>
                            <div>
                              {uploading ? (
                                <ProgressBar
                                  variant="success"
                                  style={{ marginTop: 5 }}
                                  now={this.state.progress}
                                />
                              ) : (
                                <Button className={'big-button'} disabled={uploading}>
                                  Cargar Video
                                </Button>
                              )}
                            </div>
                          </FilePicker>
                        )}
                      </div>
                    ),
                  },
                ]}
              />
            </Card>
            <br />
          </Col>
          <Col md={8}>
            <ProductDetail
              id={id}
              disabled={company !== currentCompanyId}
              onAdd={() => this.setState({ showCategorizations: true })}
              onRemove={item => this.assignCategorizationToProduct(item.value)}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 25 }}>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <ProductHistoryPrice onHide={() => undefined} productId={id} />
          </Col>
        </Row>

        {!isLimit && (
          <Row style={{ marginTop: 25 }}>
            <Col xl={4} lg={4} md={12} sm={12} xs={12}>
              <Card
                title={
                  'Existencia en bodegas: ' +
                  existenceAcrossWarehouses.reduce(
                    (accumulator, value) => accumulator + value.productExistence,
                    0,
                  ) +
                  ' unidades'
                }
                style={{ height: '100%', marginBottom: 25 }}>
                {existenceAcrossWarehouses.length > 0 ? (
                  <TableV2
                    customClass={'scroll-x-without-height'}
                    headers={[
                      {
                        label: '#',
                        show: true,
                        index: true,
                        className: 'center mini',
                        select: true,
                      },
                      {
                        label: 'Bodega',
                        show: true,
                        value: ['warehouseName'],
                        type: 'text',
                        className: 'medium',
                      },
                      {
                        label: 'Existencia',
                        show: true,
                        value: ['productExistence'],
                        type: 'measure',
                        className: 'mini right',
                      },
                      { config: true, show: true, label: '', className: 'mini center' },
                    ]}
                    items={existenceAcrossWarehouses}
                    mobileAuto
                    storageKey={`existenceware`}
                    onClickTr={(e, item) =>
                      history.push(`/produccion/bodegas/detalle/${item.warehouseId}`)
                    }
                  />
                ) : (
                  <h4 style={{ textAlign: 'center', marginTop: 50 }}>
                    No hay existencia de este producto en ninguna bodega
                  </h4>
                )}
              </Card>
            </Col>

            {module === 11000 && (
              <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                <Card
                  title={'Companías que usan el producto'}
                  style={{ height: '100%', marginBottom: 25 }}>
                  <TableV2
                    headers={[
                      {
                        label: 'SKU / Código',
                        show: true,
                        value: ['alphanumericId'],
                        type: 'text',
                        className: 'center mini',
                      },
                      {
                        label: 'Nombre',
                        show: true,
                        value: ['name'],
                        type: 'text',
                        className: 'medium',
                      },
                      { config: true, show: true, label: '', className: 'mini center' },
                    ]}
                    items={companies}
                    noItemsLegend={'Ninguna compañía usa este producto'}
                    mobileAuto
                    storageKey={`companiesUsed`}
                  />
                </Card>
              </Col>
            )}

            {module === 2000 && company === currentCompanyId && (
              <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                <Card
                  title={
                    'Existencia en consignación: ' +
                    existenceConsignated.reduce(
                      (accumulator, value) => accumulator + value.productExistence,
                      0,
                    ) +
                    ' unidades'
                  }
                  style={{ height: '100%', marginBottom: 25 }}>
                  <TableV2
                    customClass={'scroll-x-without-height'}
                    headers={[
                      {
                        label: '#',
                        show: true,
                        index: true,
                        select: true,
                        className: 'mini',
                      },
                      {
                        label: 'Transportista',
                        show: true,
                        value: ['companyName'],
                        type: 'text',
                        className: 'mini',
                      },
                      {
                        label: 'Bodega',
                        show: true,
                        value: ['warehouseName'],
                        type: 'text',
                        className: 'mini',
                      },
                      {
                        label: 'Existencia',
                        show: true,
                        value: ['productExistence'],
                        type: 'measure',
                        className: 'mini',
                      },
                      { config: true, show: true, label: '', className: 'mini center' },
                    ]}
                    items={existenceConsignated}
                    noItemsLegend={'Este producto no se encuentra en consignación'}
                    mobileAuto
                    storageKey={`consignacion`}
                  />
                </Card>
              </Col>
            )}
          </Row>
        )}

        <PolygonContractedByProduct
          show={contractedPolygon.show}
          withGeo={false}
          params={contractedPolygon.params}
          onClose={() =>
            this.setState({ contractedPolygon: { ...contractedPolygon, show: false } })
          }
        />

        <SweetAlert
          show={showSuccess}
          title={'!Exito!'}
          text={'Producto guardado exitosamente.'}
          type={'success'}
          onConfirm={() => this.setState({ showSuccess: false, editing: false })}
        />
        <SweetAlert
          show={showError}
          title={'¡Uh-Oh!'}
          text={'Error en los datos trasmitidos, por favor revise e intente de nuevo.'}
          type={'error'}
          onConfirm={() => this.setState({ showError: false })}
        />
        <SweetAlert
          show={showWarning}
          title={'¿Estás seguro que quieres eliminar el producto?'}
          text={'Esta acción no se puede deshacer'}
          type={'warning'}
          onConfirm={this.deleteProduct}
          confirmButtonText={'Eliminar producto'}
          confirmButtonColor={'orange'}
          showCancelButton={true}
          cancelButtonText={'Cancelar'}
          onCancel={() => this.setState({ showError: false })}
        />
        <SweetAlert
          show={showDeleteSuccess}
          title={'!Exito!'}
          text={'Producto eliminado exitosamente.'}
          type={'success'}
          onConfirm={() => this.setState({ showDeleteSuccess: false }, history.goBack)}
        />
        <SweetAlert
          show={showDeleteError}
          title={'¡Uh-Oh!'}
          text={'No fue posible eliminar el producto, por favor intente más tarde'}
          type={'error'}
          onConfirm={() => this.setState({ showDeleteError: false })}
        />

        {/***********************************************************
         ***********************MODAL CATEGORIZATION*****************
         * **********************************************************/}
        <Folder
          onHide={() => this.setState({ showCategorizations: false, isFilter: false })}
          onAssign={item => this.assignCategorizationToProduct(item.id)}
          data1={
            categorization && categorization.children ? categorization.children[0] : {}
          }
          data2={
            categorization && categorization.children ? categorization.children[1] : {}
          }
          show={showCategorizations}
          type={categoryType.PRODUCTS}
        />

        <SweetAlert
          show={this.state.showErrorVideo}
          title="Error"
          text="Ocurrio un error al almacenar los datos"
          type={'warning'}
          onConfirm={() => {
            this.setState({ showErrorVideo: false })
          }}
        />

        <SweetAlert {...alert} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  product: selectSingleProduct(state),
  existenceAcrossWarehouses: selectExistenceAcrossWarehouse(state),
  existenceConsignated: selectExistenceConsignated(state),
  priceHistory: selectProductReport(state),
  products: selectAllProductsForSelect(state),
  //categorization
  categorization: selectAllCategorizations(state),
  categorizationLoading: loadingSelector([action.GET_ALL])(state),
  //product
  uploadIsLoading: loadingSelector([
    actionTypes.UPLOAD,
    productActionTypes.ADD_PRODUCT_IMAGES,
  ])(state),

  productPriceHistoryLoading: loadingSelector([productActionTypes.PRODUCT_PRICE_HISTORY])(
    state,
  ),

  updateProductIsLoading: loadingSelector([productActionTypes.UPDATE_PRODUCT])(state),
  updateProductHasError: hasErrorsSelector([productActionTypes.UPDATE_PRODUCT])(state),
  updateProductErrors: singleErrorSelector([productActionTypes.UPDATE_PRODUCT])(state),

  deleteProductIsLoading: loadingSelector([productActionTypes.DELETE_PRODUCT])(state),
  deleteProductHasError: hasErrorsSelector([productActionTypes.DELETE_PRODUCT])(state),
  deleteProductErrors: singleErrorSelector([productActionTypes.DELETE_PRODUCT])(state),

  addIsLoading: loadingSelector([action.ADD_ENTITY_CATEGORIZATION])(state),
  addHasError: hasErrorsSelector([action.ADD_ENTITY_CATEGORIZATION])(state),
  addError: singleErrorSelector([action.ADD_ENTITY_CATEGORIZATION])(state),

  handlerError: message => handlerError(message),
  handlerSuccess: message => handlerSuccess(message),

  pLoading: loadingSelector([productActionTypes.GET_SINGLE_PRODUCT])(state),
  getBaseProductWithSons: list => getBaseProductWithSons(list),
  productsA: selectAllProducts(state),

  module: selectCurrentModule(state),
  companies: selectCompaniesByModel(state),
  loadingCompanies: loadingSelector([productActionTypes.GET_COMPANIES_BY_MODEL])(state),
  videos: selectVideos(state),
  isLimit: selectCompanyIsLimit(state),
})

const mapDispatchToProps = dispatch => ({
  //categorization
  getAllCategorization: () => dispatch(getAllCategorizations(3)),
  assignCategorizationToProduct: (categorizationId, productId) =>
    dispatch(assignCategorizationToEntity('products', categorizationId, productId, true)),
  getCategorizationsByProduct: productId =>
    dispatch(getCategorizationsByEntity('products', productId)),
  //product
  getSingleProduct: id => dispatch(getSingleProduct(id)),
  getExistenceAcrossWarehouses: productId =>
    dispatch(getExistenceAcrossWarehouses(productId)),
  getExistenceExternalWarehouses: productId =>
    dispatch(getExistenceExternalWarehouses(productId)),
  deleteProduct: productId => dispatch(deleteProduct(productId)),
  updateProduct: (
    id,
    name,
    code,
    barcode,
    description,
    price,
    categoryId,
    subCategoryId,
    baseProduct,
    conversionFactor,
    subProductId,
    subProductValue,
    transformation,
    activeOnline,
    subPrice,
    activeLink,
    production,
    activePurchase,
    expenseType,
    activeUtility,
    parcelId,
  ) =>
    dispatch(
      updateProduct(
        id,
        name,
        code,
        barcode,
        description,
        price,
        categoryId,
        subCategoryId,
        baseProduct,
        conversionFactor,
        subProductId,
        subProductValue,
        transformation,
        activeOnline,
        subPrice,
        activeLink,
        production,
        activePurchase,
        expenseType,
        activeUtility,
        parcelId,
      ),
    ),
  uploadFile: (file, path, onProgress, onSuccess, onError) =>
    dispatch(uploadFile(file, path, onProgress, onSuccess, onError)),
  deleteFile: (name, onSuccess) => dispatch(deleteFile(name, onSuccess)),
  getProductPriceHistory: (id, startDate, endDate) =>
    dispatch(getReport(id, startDate, endDate)),
  getAllProducts: () => dispatch(getProducts()),
  getOrdersByModelProduct: (id, start, end) =>
    dispatch(getAllOrdersByModelProduct(id, start, end)),
  getCompaniesByModelProduct: id => dispatch(getCompaniesByModelProduct(id)),
  uploadVideo: (file, onProgress, onSuccess, onError, path) =>
    dispatch(uploadVideo(file, onProgress, onSuccess, onError, path)),
  updateFile: data => dispatch(updateFile(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails)
