import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setModalCreateCard, setModalManageCards } from 'src/actions/utilities.actions'
import { Button, Dropdown, TableV2 } from 'src/components'

import {
  selectModalCreateCard,
  selectModalManageCards,
} from 'src/selectors/utilities.selector'
import {
  actions as accountActions,
  deleteCard,
  favoriteCard,
  getCards,
} from 'src/actions/account.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'
import { selectCreditCards } from 'src/selectors/account.selector'
import { showAlert } from 'src/actions/alert.actions'

/**
 * Componente para renderizar un modal para administrar las tarjetas de crédito
 * @component
 */
const ModalManageCards = () => {
  const dispatch = useDispatch()

  const [action, setAction] = useState({
    cardDelete: false,
    cardFavorite: false,
  })

  const modalManageCards = useSelector(selectModalManageCards)
  const modalCreateCard = useSelector(selectModalCreateCard)

  const loadingDeleteCard = useSelector(state =>
    loadingSelector([accountActions.DELETE_CARD])(state),
  )
  const hasErrorDeleteCard = useSelector(state =>
    hasErrorsSelector([accountActions.DELETE_CARD])(state),
  )
  const errorDeleteCard = useSelector(state =>
    singleErrorSelector([accountActions.DELETE_CARD])(state),
  )

  const loadingFavoriteCard = useSelector(state =>
    loadingSelector([accountActions.FAVORITE_CARD])(state),
  )
  const hasErrorFavoriteCard = useSelector(state =>
    hasErrorsSelector([accountActions.FAVORITE_CARD])(state),
  )
  const errorFavoriteCard = useSelector(state =>
    singleErrorSelector([accountActions.FAVORITE_CARD])(state),
  )

  const cards: ICreditCard[] = useSelector(selectCreditCards)
  const loadingCards = useSelector(state =>
    loadingSelector([accountActions.GET_CARDS])(state),
  )

  const loadingRow = useSelector(state =>
    loadingSelector([accountActions.FAVORITE_CARD, accountActions.DELETE_CARD])(state),
  )

  useEffect(() => {
    if (!modalManageCards.show) return
    dispatch(getCards())
  }, [modalManageCards.show])

  useEffect(() => {
    if (loadingDeleteCard) setAction({ ...action, cardDelete: true })
    else if (action.cardDelete) {
      setAction({ ...action, cardDelete: false })
      if (hasErrorDeleteCard)
        dispatch(
          showAlert({
            ...handlerError(errorDeleteCard.message),
          }),
        )
      else
        dispatch(
          showAlert({
            ...handlerSuccess('Tarjeta de crédito eliminada satisfactoriamente'),
            onConfirm: () => {
              dispatch(getCards())
            },
          }),
        )
    }
  }, [loadingDeleteCard])

  useEffect(() => {
    if (loadingFavoriteCard) setAction({ ...action, cardFavorite: true })
    else if (action.cardFavorite) {
      setAction({ ...action, cardFavorite: false })
      if (hasErrorFavoriteCard)
        dispatch(
          showAlert({
            ...handlerError(errorFavoriteCard.message),
          }),
        )
      else
        dispatch(
          showAlert({
            ...handlerSuccess(
              'Tarjeta de crédito establecida como predeterminada satisfactoriamente',
            ),
            onConfirm: () => {
              dispatch(getCards())
            },
          }),
        )
    }
  }, [loadingFavoriteCard])

  const getActions = item => (
    <Dropdown
      loading={loadingRow}
      items={[
        {
          show: !item.favorite,
          title: 'Establecer como favorita',
          action: () => dispatch(favoriteCard(item.id)),
        },
        {
          show: true,
          title: 'Eliminar',
          action: () => dispatch(deleteCard(item.id)),
        },
      ]}
    />
  )

  const headers = [
    { label: 'Alias', show: true, value: ['alias'], type: 'text' },
    {
      label: 'Número de tarjeta',
      show: true,
      custom: ({ logo, number }) => (
        <>
          <span
            className={`input-card ${logo || 'none'}`}
            style={{
              width: '60px',
              height: '30px',
            }}
          />
          <span className={'ml-2'}>{number}</span>
        </>
      ),
      type: 'text',
    },
    {
      label: 'Favorita',
      show: true,
      custom: ({ favorite }) => (favorite ? 'Sí' : 'No'),
      type: 'text',
    },
    {
      show: true,
      config: true,
      type: 'text',
      className: 'center',
      value: ['button'],
      label: 'Acción',
      custom: getActions,
    },
  ]

  return (
    <div style={{ position: 'fixed' }}>
      <Modal
        show={modalManageCards.show && !modalCreateCard.show}
        centered
        size={'lg'}
        onHide={() => {
          dispatch(setModalManageCards({ show: false }))
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Tarjetas de crédito</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableV2
            /** @ts-expect-error js */
            items={cards}
            loading={loadingCards}
            headers={headers}
          />
        </Modal.Body>
        <Modal.Footer className={'container-buttons'}>
          <Button
            color="secondary"
            onClick={() => dispatch(setModalCreateCard({ show: true }))}>
            Registrar tarjeta
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ModalManageCards
