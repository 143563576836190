import React, {Component, useState, useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import {Container} from "react-bootstrap";
import Card from "../../../components/cards/Card";
import {Td, Tr} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import {Col, Modal, Row} from "react-bootstrap";
import {formatNumberWithCommas} from "../../../utils/formatters";
import Switch from "../../../components/inputs/Switch/Switch";
import IconButton from "../../../components/buttons/IconButton";
import {faHistory, faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {getAllCompanies} from "../../../actions/company.actions";
import {selectAllCompanies} from "../../../selectors/company.selector";
import {selectAddUserBalance, selectGetCompaniesBalance} from "../../../selectors/balance.selector";
import {
    actionTypes as balanceActions,
    addAdminUserCompanyBalanceEntry,
    getCompaniesBalanceKolo
} from "../../../actions/balance.actions";
import NewDisbursement from "../../Distrbution/Disbursements/NewDisbursement";
import Button from "../../../components/buttons/Button";
import Select from "../../../components/inputs/Select/CustomSelect";
import FormTextField from "../../../components/inputs/FormTextField/FormTextField";
import SweetAlert from "sweetalert-react";
import {loadingSelector} from "../../../selectors/loading.selector";
import { TableV2 } from '../../../components';
import { toMoney } from '../../../utils/utilities';

const KoloBalance = () =>{

    const dispatch = useDispatch();
    const companies = useSelector(state => selectAllCompanies(state));
    const companiesBalance = useSelector(state => selectGetCompaniesBalance(state));
    const userEntries = useSelector(state => selectAddUserBalance(state));
    const entriesLoading = useSelector(state => loadingSelector([balanceActions.ADD_USER_BALANCE_COMPANY_KOLO])(state));

    const [showUsers,setShowUsers] = useState(false);
    const [users,setUsers] = useState([]);
    const [amount,setAmount] = useState(0.0);
    const [company,setCompany] = useState(0);
    const [user,setUser] = useState({});
    const [addEntry,setAddEntry] = useState(false);
    const [editModule,setEditModule] = useState({value: 1000, label: 'Transportista'});
    const [operation,setOperation] = useState({value: 1, label: 'Acreditar'});
    const [clicked,setClicked] = useState(false);
    const [withBalance,setWithBalance] = useState(false);
    const [active,setActive] = useState(true);
    const [alertForm,setAlertForm] = useState({
        title:"",
        text:"",
        type: "success",
        show: false,
        //onConfirm:()=>{setUserModal({...alertForm,show:false})}
    });

    useEffect(()=>{
        dispatch(getAllCompanies());
        dispatch(getCompaniesBalanceKolo())

    },[]);

    useEffect(()=>{
        if(entriesLoading && clicked){
            dispatch(getAllCompanies());
            dispatch(getCompaniesBalanceKolo());
            setAlertForm({type: 'success',title: 'Exito',text: 'La entrada ha sido creada con exito',show: true,onConfirm:()=>{
                    setAlertForm({...alertForm,show:false});
                }});
            setClicked(false);
            findUsersForCompany(company);
        }

    },[entriesLoading]);

    const getHeadersTable = () => {
        return [
            
            {label: 'ID', show: true, value: ['alphanumericId'], type: 'text' ,  className:'mini'},
            {label: 'NIT', show: true, value: ['nit'], type: 'text' ,  className:'mini'},
            {label: 'Nombre', show: true, value: ['name'], type: 'text' ,  className:'name mini'},
            {label: 'Balance Productor', show: true, value: ['amount'], type: 'text' ,  className:'medium', select:true,
                custom: (item) => <>
                    Real: {toMoney(item.balanceProducer)} <br />
                    Retenido: {toMoney(item.balanceRetTransp)}
                </>
            },
            {
                label: 'Balance Transportista', show: true, value: ['amount'], type: 'text', className: 'medium', select:true,
                custom: (item) => <>
                    Real: {toMoney(item.balanceTransp)} <br />
                    Retenido: {toMoney(item.balanceRetTransp)}
                </>
            },
            {
                label: 'Acciones', show: true, value: ['amount'], type: 'text', className: 'medium', select:true,
                custom: (item) => <Button onClick={() => {
                    findUsersForCompany(item.id);
                    setCompany(item.id);
                    setShowUsers(true);
                }
                } >Usuarios</Button>
            },
            {
                config: true, show: true, label: '', className: 'mini center'
            }
        ]
    }


    const getHeaders = () => {
        return [
            {label: 'Nombre', show: true, value: ['userData','name'], type: 'text' ,  className:'mini'},
            {label: 'Correo', show: true, value: ['userData','email'], type: 'text' ,  className:'mini'},
            {label: 'Balance Transportista', show: true, value: ['balanceTransportist'], type: 'currency' ,  className:'mini'},
            {label: 'Balance Productor', show: true, value: ['balanceProducer'], type: 'currency' ,  className:'mini'},
            {config:true, show: true, label:'', className:'mini center',
            custom:(item)=>  <Button onClick={()=>{
                if(!item.userData)
                    return;
                setUser(item.userData);
                setAddEntry(true);
            }
            } >Editar</Button>}

        ]
    }



    const findUsersForCompany = (id) =>{
        let entry = companiesBalance.find(c=> c.company.id==id)
        if(entry){
            setUsers([...entry.users]);
            console.log("found");
            return;
        }
    };
    const filterCompanies = (item) =>{
        //console.log((withBalance && (item.balance>0 || item.balanceProducer||item.balanceRetProducer||item.balanceRetTransp||item.balanceTransp) ) || (!withBalance));
        return(((withBalance && (item.balance>0 || item.balanceProducer||item.balanceRetProducer||item.balanceRetTransp||item.balanceTransp) ) || (!withBalance) ) &&
            ((active && item.active) || (!active))
        )
    };

    return(
        <div className={""}>
            <div><h1 className={"dashboard-welcome"}>Empresas Registradas</h1></div>
            <Row>
                <Col md={4}>
                    <Card title={"Filtros"}>
                        <Row>
                            <Col>
                                <label className={'checkbox-label'}><input type="checkbox" name="active" checked={withBalance} onChange={()=>setWithBalance(!withBalance)}/> Con Balance</label>
                            </Col>
                            <Col>
                                <label className={'checkbox-label'}><input type="checkbox" name="active" checked={active} onChange={()=>setActive(!active)}/> Activas</label>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col >
                    <Card title={'Empresas'}>
                        <TableV2
                            headers={getHeadersTable()}
                            items={companies.filter(filterCompanies)}
                            mobileAuto
                            storageKey={`empresasKoloBalance`}
                        />
                    </Card>
                </Col>
            </Row>
            <Modal show={showUsers} size="lg" centered onHide={()=>{setUsers([]);setAmount(0.0);setShowUsers(false);}}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Usuarios
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div>
                            <TableV2
                                headers={getHeaders()}
                                items={users}
                                noItemsLegend={'No hay movimientos para mostrar'}

                                mobileAuto
                                storageKey={`usersKoloBalance`}
                            />
                        </div>

                        <br/>
                        {
                            addEntry && <div>
                                <Row>
                                    <Col>
                                        <br/>
                                        <label htmlFor="">Usuario</label><br/>
                                        <b>{user.name}</b>
                                    </Col>
                                    <Col>
                                        <Select
                                            required
                                            label={'Balance'}
                                            options={[{value: 1000, label: 'Transportista'}, {value: 2000, label: 'Productor'}]}
                                            value={editModule}
                                            onChange={(s) => setEditModule(s)}
                                        />
                                    </Col>
                                    <Col>
                                        <Select
                                            required
                                            label={'Tipo'}
                                            options={[{value: 1, label: 'Acreditar'}, {value: 0, label: 'Retirar'}]}
                                            value={operation}
                                            onChange={(s) => setOperation(s)}
                                        />
                                    </Col>
                                    <Col>
                                        <FormTextField
                                            label={"Cantidad"}
                                            type={"number"}
                                            id={"amount"}
                                            placeholder={"0.0"}
                                            name={"amount"}
                                            value={Math.abs(amount)}
                                            onChange={(e)=>{setAmount(e.target.value)}}
                                            error={""}
                                            inputClassName={operation.value==1?"green":"red"}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button disabled={clicked} onClick={()=>{
                                            //console.log(user.id,company,amount,operation.value,editModule.value);
                                            setClicked(true);
                                            dispatch(addAdminUserCompanyBalanceEntry(user.id,company,amount,operation.value,editModule.value));
                                        }
                                        } >Agregar entrada</Button>
                                        <Button onClick={()=>{
                                            setAmount(0.0);
                                            setUser({});
                                            setAddEntry(false);
                                        }
                                        } >Cancelar</Button>
                                    </Col>
                                    <Col>

                                    </Col>
                                </Row>
                            </div>
                        }

                    </div>
                </Modal.Body>
            </Modal>
            <SweetAlert{...alertForm}/>
        </div>
    )
};

export default KoloBalance;
