/**
 * Metodo para obtener colores KOLO
 * @param color Color que se quiere obtener
 * @returns Color en Hexa
 */

function getColor(color?: string): string | null {
  if (!color) return null

  switch (color) {
    case 'primary':
      return '#226095'
    case 'secondary':
      return '#B35796'
    case 'accent':
      return '#12A8BB'
    default:
      return color
  }
}

export { getColor }
