import DisbursementsServices from '../services/disbursements.service'
import { executeAction } from './global.actions'

export const actionTypes = {
  GET_DISBURSEMENTS: 'GET_DISBURSEMENTS',
  CREATE_DISBURSEMENT: 'CREATE_DISBURSEMENT',
  GET_DISBURSEMENTS_BALANCE: 'GET_DISBURSEMENTS_BALANCE',
  GET_DISBURSEMENTS_BALANCE_COTIZAP: 'GET_DISBURSEMENTS_BALANCE_COTIZAP',
  GET_DISBURSEMENTS_BALANCE_DETAILS: 'GET_DISBURSEMENTS_BALANCE_DETAILS',
  GET_SINGLE_DISBURSEMENT: 'GET_SINGLE_DISBURSEMENT',

  GET_DISBURSEMENTS_ADMIN: 'GET_DISBURSEMENTS_ADMIN',
  UPDATE_SINGLE_DISBURSEMENT_ADMIN: 'UPDATE_SINGLE_DISBURSEMENT_ADMIN',
  APPROVE_SINGLE_DISBURSEMENT_ADMIN: 'APPROVE_SINGLE_DISBURSEMENT_ADMIN',
  GET_CSV: 'GET_CSV',
  ON_CSV: 'ON_CSV',
  GET_DISBURSEMENTS_REQUEST: 'GET_DISBURSEMENTS_REQUEST',
}

export const getDisbursements = () => dispatch => {
  const process = async () => ({
    disbursements: await DisbursementsServices.getDisbursementsHistory(),
  })
  dispatch(executeAction(actionTypes.GET_DISBURSEMENTS, process))
}

export const createDisbursement = (amount, params) => dispatch => {
  const process = async () => {
    const newDisbursement = await DisbursementsServices.createDisbursementsRequest(
      amount,
      params,
    )
    dispatch(getDisbursements())
    return { newDisbursement }
  }
  dispatch(executeAction(actionTypes.CREATE_DISBURSEMENT, process))
}

export const getDisbursementsBalance = () => dispatch => {
  const process = async () => ({
    disbursementsBalance: await DisbursementsServices.getDisbursementsBalance(),
  })
  dispatch(executeAction(actionTypes.GET_DISBURSEMENTS_BALANCE, process))
}

export const getDisbursementsBalanceCotizap = () => dispatch => {
  const process = async () => ({
    disbursementsBalanceCotizap:
      await DisbursementsServices.getDisbursementsBalanceCotizap(),
  })
  dispatch(executeAction(actionTypes.GET_DISBURSEMENTS_BALANCE_COTIZAP, process))
}

export const getDisbursementsDetails = () => dispatch => {
  const process = async () => ({
    disbursementsDetails: await DisbursementsServices.getDisbursementsBalanceDetails(),
  })
  dispatch(executeAction(actionTypes.GET_DISBURSEMENTS_BALANCE_DETAILS, process))
}

export const getDisbursement = id => dispatch => {
  const process = async () => ({
    disbursement: await DisbursementsServices.getSingleDisbursement(id),
  })
  dispatch(executeAction(actionTypes.GET_SINGLE_DISBURSEMENT, process))
}

export const getDisbursementsAdmin = filters => dispatch => {
  const process = async () => ({
    disbursementsAdmin: await DisbursementsServices.getDisbursementsAdmin(filters),
  })
  dispatch(executeAction(actionTypes.GET_DISBURSEMENTS_ADMIN, process))
}

export const updateDisbursementAdmin = (id, status, description) => dispatch => {
  const process = async () => ({
    updatedDisbursement: await DisbursementsServices.putSingleDisbursementsAdmin(
      id,
      status,
      description,
    ),
  })
  dispatch(executeAction(actionTypes.UPDATE_SINGLE_DISBURSEMENT_ADMIN, process))
}

export const approveDisbursementAdmin = (id, document) => dispatch => {
  const process = async () => ({
    approvedDisbursement: await DisbursementsServices.approveSingleDisbursementsAdmin(
      id,
      document,
    ),
  })
  dispatch(executeAction(actionTypes.APPROVE_SINGLE_DISBURSEMENT_ADMIN, process))
}

export const getCSV = id => dispatch => {
  const process = async () => ({ csv: await DisbursementsServices.getCSV(id) })
  dispatch(executeAction(actionTypes.GET_CSV, process))
}

export const onCSV = (id, file) => dispatch => {
  const process = async () => ({
    csv: await DisbursementsServices.onCSV(id, file),
  })
  dispatch(executeAction(actionTypes.ON_CSV, process))
}

export const getDisburstmentRequestsByParameter = params => dispatch => {
  const process = async () => ({
    response: await DisbursementsServices.getDisburstmentRequestsByParameter(params),
  })
  dispatch(executeAction(actionTypes.GET_DISBURSEMENTS_REQUEST, process))
}
