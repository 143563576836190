import {
  faAngleDown,
  faAngleUp,
  faMinus,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { Col, Collapse, ListGroup, Row } from 'react-bootstrap'
import { FormText, Icon, Switch } from 'src/components'
import { itemTypes } from 'src/components/Items/ItemsToSelect'
import NumberField from 'src/components/NumberField/NumberField'

export const LineQuantites = ({
  line = [],
  currentItem, // Item principal del árbol de ítems
  onChangeQuantity,
  maxDiscount,
  modal,
  select,
  type,
  onCheckSubtotal,
  onChangeSubTotal,
  currency,
  conversion,
  importation,
  haveLocations,
  locations,
  fieldEditPrice, // Company configuration, can edit the price in PURCHASE AND EXPENSES.
}) => {
  const useDecimals = currentItem.decimals !== null
  const itemOnlyCost = fieldEditPrice || useDecimals

  useEffect(() => {
    if (!modal) renderValues()
  }, [currentItem.quantity, currentItem.baseProductQuantity, currentItem])

  useEffect(() => {
    renderValues()
  }, [currentItem.selectedPrice])

  useEffect(() => {
    if (type !== itemTypes.PURCHASE && type !== itemTypes.EXPENSE) renderDiscounts()
  }, [currentItem.discount])

  useEffect(() => {
    if ((type === itemTypes.PURCHASE || type === itemTypes.EXPENSE) && !itemOnlyCost) {
      getSinglePriceBySubTotal(currentItem.subTotal)
    }
  }, [currentItem.subTotal, line])

  const renderDiscounts = () => {
    currentItem.maxDiscount = 0
    currentItem.lineItems &&
      currentItem.lineItems.forEach(l => {
        l.discount = getNewDiscount(l, currentItem.discount)

        if (l.quantity > 0) {
          let nowQuantity = (l.productData.transformationFactor || 1) * l.quantity
          const maxDiscount = getMaxDiscount(
            nowQuantity / (l.transformationFactor || 1),
            l,
          )
          currentItem.maxDiscount = currentItem.maxDiscount + parseFloat(maxDiscount)
        }
      })
    currentItem.subTotal = currentItem.lineItems && getLineTotal()
    onChangeQuantity(currentItem)
  }

  const getNewDiscount = (item, discount) => {
    let total = currentItem.subTotalWithoutDiscount //getTotalWithOutDiscount()
    let newDiscount
    if (item.productId === currentItem.value) {
      newDiscount =
        (item.quantity *
          (currentItem.selectedPrice
            ? currentItem.selectedPrice.value
            : currentItem.productPrice) *
          discount) /
        total
    } else {
      newDiscount =
        (item.quantity *
          (item.priceInSale ? item.priceInSale : item.productData.price) *
          discount) /
        total
    }
    return Number(newDiscount !== 0 ? newDiscount.toFixed(2) : newDiscount)
  }
  const getSinglePriceBySubTotal = subTotal => {
    if (Object.entries(currentItem).length !== 0) {
      currentItem.haveQuantities = false
      if (currentItem.individualTotal) {
        line !== null &&
          line.forEach(l => {
            if (l.quantity !== null) {
              if (l.quantity === 0) {
                l.ownPrice = 0
                l.subTotal = 0
              } else {
                currentItem.haveQuantities = true
                let price = l.subTotal / (l.quantity !== 0 ? l.quantity : 1) || 0
                l.ownPrice = Number(price).toFixed(2)
              }
            }
          })
      } else {
        let totalQuantityBase = 0
        line !== null &&
          line.forEach(l => {
            if (l.quantity !== null) {
              if (l.quantity === 0) {
                l.ownPrice = 0
                currentItem.subTotal = 0
                if (importation) {
                  currentItem.tax = 0
                  currentItem.customPrice = 0
                }
              } else {
                currentItem.haveQuantities = true
                totalQuantityBase +=
                  l.presentationFactor !== null
                    ? l.presentationFactor * l.quantity
                    : l.quantity
              }
            }
          })
        let priceBase = subTotal / (totalQuantityBase !== 0 ? totalQuantityBase : 1) || 0
        line !== null &&
          line.forEach(l => {
            if (l.quantity !== null) {
              if (l.quantity === 0) {
                l.ownPrice = 0
                currentItem.subTotal = 0
                if (importation) {
                  currentItem.tax = 0
                  currentItem.customPrice = 0
                }
              } else {
                l.haveQuantities = true
                let subtotal =
                  priceBase *
                    (l.presentationFactor !== null ? l.presentationFactor : 1) || 0
                l.ownPrice = Number(subtotal).toFixed(2) || 0
              }
            }
          })
      }
      if (modal) {
        onChangeSubTotal()
      }
    }
  }

  const renderValues = () => {
    if (
      currentItem.lineItems &&
      type !== itemTypes.PURCHASE &&
      type !== itemTypes.EXPENSE
    ) {
      currentItem.subTotal = getLineTotal()
      currentItem.subTotalWithoutDiscount = getLineTotal(true)
      if (type !== 3) {
        let newLine = renderQuantities(currentItem.baseProductQuantity, line)
        currentItem.lineItems = newLine
      }
      renderDiscounts()
      onChangeQuantity(currentItem)
    }
  }

  const getSubPrice = item => {
    const { saleRecipe, productPrice, selectedPrice } = currentItem
    const { productData } = item
    let presentationFactorV2 = line.find(l => (l.productId = currentItem.id))
    let price = 0
    if (productData) {
      const useFactor = productData.useFactor
      if (saleRecipe == null) {
        price = useFactor
          ? (item.presentationFactor || 1) *
            ((selectedPrice ? selectedPrice.value : productPrice) /
              (presentationFactorV2 !== null
                ? presentationFactorV2.presentationFactor
                : 1))
          : productData.price
      } else {
        price = useFactor
          ? (item.subProductValue / currentItem.subProductValue) *
            (selectedPrice ? selectedPrice.value : productPrice)
          : productData.price
      }
    }
    item.priceInSale = price.toFixed(2)
    return price.toFixed(2)
  }

  const getSubTotal = (item, ignore, subItem) => {
    let subTotal = 0
    let price = currentItem.selectedPrice
      ? currentItem.selectedPrice.value
      : currentItem.price || currentItem.productPrice
    if (subItem) {
      subTotal += (item.subQuantity || 0) * getSubPrice(item)
    } else {
      item.productData.price = price
      if (item.quantity >= 0) subTotal += (item.quantity || 0) * price
    }
    if (ignore) return subTotal
    else return subTotal //- (item.discount || 0)
  }

  const getMaxDiscount = (quantity, item, subItem) => {
    const discountLimit = module === 1000
    let max

    if (currentItem.saleRecipe) {
      max = getSubTotal(item, true, subItem)
    } else {
      if (isNaN(quantity)) quantity = 0
      let discount = 0
      let price =
        parseFloat(item.priceInSale) ||
        (currentItem.selectedPrice
          ? currentItem.selectedPrice.value
          : currentItem.productPrice) ||
        currentItem.price
      if (currentItem.prices && discountLimit) discount = price
      else if (!discountLimit) discount = price

      if (currentItem.discountLimit) discount = currentItem.discountLimitedNumber
      max = parseFloat(discount) * parseFloat(quantity)
    }
    return Number.parseFloat((Number.parseFloat(maxDiscount || 0) * max) / 100).toFixed(2)
  }

  const updateSubTotal = () => {
    let sub = 0
    currentItem.haveQuantities = false
    line.forEach(x => {
      if (x.quantity > 0 && x.ownPrice) {
        currentItem.haveQuantities = true
        sub = sub + x.quantity * (x.ownPrice || 0)
      }
    })
    return sub.toFixed(2)
  }

  const changeQuantity = (quantity, item) => {
    if (isNaN(quantity)) quantity = 0

    quantity = Number(quantity)
    if (quantity < 0) quantity = 0
    if (type === itemTypes.PURCHASE || type === itemTypes.EXPENSE) {
      currentItem.active = false //series
      item.quantity = quantity
      if (!fieldEditPrice) {
        getSinglePriceBySubTotal(currentItem.subTotal)
      } else currentItem.subTotal = updateSubTotal()
      onChangeQuantity(currentItem, true)
    } else {
      if (currentItem === null) return
      item.quantity = quantity

      let nowQuantity = (item.productData.transformationFactor || 1) * quantity

      if (item.quantity) item.subQuantity = quantity
      const maxDiscountAux = getMaxDiscount(
        nowQuantity / (item.transformationFactor || 1),
        item,
      )
      if (parseFloat(currentItem.discount) > maxDiscountAux) {
        if (Number(maxDiscount) !== null) {
          currentItem.discount = maxDiscountAux
        } else if (currentItem.discountLimit) {
          currentItem.discount = currentItem.discountLimitedNumber
        } else {
          currentItem.discount = 0
        }
      }

      if (currentItem.selectedPrice) {
        currentItem.price = currentItem.selectedPrice.value
        currentItem.productPrice = currentItem.selectedPrice.value
      }
      currentItem.error = null

      if (type !== itemTypes.PURCHASE && type !== itemTypes.EXPENSE) {
        let val = getLineTotal()
        currentItem.subTotal = val
      }
      currentItem.maxDiscount = maxDiscountAux
      currentItem.subTotalWithoutDiscount = getLineTotal(true)
      if (type !== 3 && type !== 4) {
        let newLine = renderQuantities(currentItem.baseProductQuantity, line)
        currentItem.lineItems = newLine
      }

      onChangeQuantity(currentItem, true)
    }
  }

  const getLineTotal = without => {
    let subtotal = 0
    currentItem.haveQuantities = false
    line.forEach(l => {
      if (l.quantity > 0) {
        currentItem.haveQuantities = true
        if (l.productData.id === currentItem.value) {
          //l.price = currentItem.selectedPrice.value
          subtotal += getSubTotal(l, null, false)
        } else {
          subtotal += getSubTotal(l, null, true)
        }
      }
    })
    if (without) return subtotal
    else return subtotal - (currentItem.discount || 0)
  }

  const getRealQuantities = quantity => {
    let current = quantity
    for (let p of line || []) {
      if (p.quantity !== null && p.quantity !== 0)
        current -= parseFloat((p.quantity * (p.presentationFactor || 1)).toFixed(2))
    }
    return current
  }

  const [oneTime, setOneTime] = useState(false)

  const renderQuantities = (quantity, line) => {
    if (oneTime) return
    let current = getRealQuantities(quantity)
    let mod = 0
    let quotient = 0
    let stock = []
    if (quantity < 0) currentItem.alert = true
    else currentItem.alert = false
    for (let p of line || []) {
      if (current <= 0) {
        p.currentQuantity = 0
        stock.push(p)
        continue
      }
      if (p.presentationFactor == null) {
        p.currentQuantity = current
        current = current - current
      } else {
        if (useDecimals) {
          quotient = current / p.presentationFactor
          p.currentQuantity = quotient
          stock.push(p)
          continue
        } else {
          if (current < p.presentationFactor) {
            p.currentQuantity = 0
            stock.push(p)
            continue
          }
          mod = current % p.presentationFactor
          quotient = Math.floor(current / p.presentationFactor)
          p.currentQuantity = quotient
          current = mod
        }
      }
      stock.push(p)
    }
    if (type === 3 && stock.length > 0) {
      setOneTime(true)
    }
    return stock
  }

  const getSubItem = (item, key) => {
    return (
      <div>
        <ul className={'base-sub-items'}>
          <li key={key}>
            <div>{item.code + '-' + item.name}</div>
          </li>
        </ul>
      </div>
    )
  }

  const gridNoPurchase = (isTrue, isFalse) => {
    if (type !== itemTypes.PURCHASE && type !== itemTypes.EXPENSE) return isTrue
    return isFalse
  }

  const subTotalorPriceMobile = (item, disabled, name, param, general) => {
    return (
      <FormText
        id={name}
        name={name}
        disabled={disabled}
        label={param === 'subTotal' ? `SubTotal (${currency})` : `Precio (${currency})`}
        type={'text'}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
        }}
        value={
          general
            ? currentItem[param]
            : currentItem.individualTotal
            ? item[param] || 0
            : param === 'subTotal'
            ? currentItem[param] || 0
            : item[param] || 0
        }
        prepend={
          <Icon
            disabled={disabled}
            icon={faMinus}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              if (currentItem.individualTotal) {
                if (param === 'subTotal') {
                  let price =
                    ((item.subTotal ? item.subTotal : 0) - 1) / (item.quantity || 1)
                  item.subTotal = (item.subTotal ? item.subTotal : 0) - 1
                  item.ownPrice = price.toFixed(2)
                } else {
                  item.ownPrice = Number(item.ownPrice || 0) - 1
                  let sub = item.ownPrice * item.quantity
                  item.subTotal = sub.toFixed(2)
                }
              } else {
                if (param === 'subTotal') {
                  currentItem.subTotal =
                    (currentItem.subTotal ? currentItem.subTotal : 0) - 1
                } else {
                  item[param] = (item[param] ? Number(item[param]) : 0) - 1
                  currentItem.subTotal = updateSubTotal()
                }
              }
              onChangeSubTotal(item)
            }}
          />
        }
        append={
          <Icon
            disabled={disabled}
            icon={faPlus}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              if (currentItem.individualTotal) {
                if (param === 'subTotal') {
                  let price =
                    ((item.subTotal ? Number(item.subTotal) : 0) + 1) /
                    (item.quantity || 1)
                  item.subTotal = (item.subTotal ? Number(item.subTotal) : 0) + 1
                  item.ownPrice = price.toFixed(2)
                } else {
                  item.ownPrice = Number(item.ownPrice || 0) + 1
                  let sub = item.ownPrice * item.quantity
                  item.subTotal = sub.toFixed(2)
                }
              } else {
                if (param === 'subTotal') {
                  currentItem[param] =
                    (currentItem[param] ? Number(currentItem[param]) : 0) + 1
                } else {
                  item[param] = (item[param] ? Number(item[param]) : 0) + 1
                  currentItem.subTotal = updateSubTotal()
                }
              }
              onChangeSubTotal(item)
            }}
          />
        }
        onChange={e => {
          e.preventDefault()
          e.stopPropagation()
          let { value } = e.target
          if (!value || value < 0) value = 0

          if (currentItem.individualTotal) {
            item.subTotal = value

            if (item.quantity > 0) {
              if (param === 'subTotal') {
                let price = value / item.quantity
                item.ownPrice = price.toFixed(2)
              } else {
                let sub = value * item.quantity
                item.subTotal = sub.toFixed(2)
              }
            }
          } else {
            if (param === 'subTotal') {
              currentItem[param] = value
            } else {
              item[param] = value
              currentItem.subTotal = updateSubTotal()
            }
          }
          onChangeSubTotal(item)
        }}
        error={currentItem.error}
      />
    )
  }

  const inputTaxesAndTotals = (item, disabled, general, param) => {
    return (
      <FormText
        disabled={disabled}
        label={param === 'tax' ? `Impuestos (${currency})` : `Total (Q)`}
        type={'number'}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
        }}
        value={
          param === 'tax'
            ? !currentItem.individualTotal
              ? currentItem.tax
              : item.tax
            : !currentItem.individualTotal
            ? currentItem.customPrice
              ? currentItem.customPrice
              : parseFloat(
                  (Number(currentItem.subTotal || 0) + Number(currentItem.tax || 0)) *
                    conversion,
                ).toFixed(2)
            : item.customPrice ||
              parseFloat(
                (Number(item.subTotal || 0) + Number(item.tax || 0)) * conversion,
              ).toFixed(2)
        }
        prepend={
          <Icon
            icon={faMinus}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              if (currentItem.individualTotal) {
                item[param] = (item[param] ? item[param] : 0) - 1
              } else {
                currentItem[param] = (currentItem[param] ? currentItem[param] : 0) - 1
              }
              onChangeSubTotal(item)
            }}
          />
        }
        append={
          <Icon
            icon={faPlus}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              if (currentItem.individualTotal) {
                item[param] = (item[param] ? item[param] || 0 : 0) + 1
              } else {
                currentItem[param] =
                  (currentItem[param] ? currentItem[param] || 0 : 0) + 1
              }
              onChangeSubTotal(item)
            }}
          />
        }
        onChange={({ target }) => {
          let { value } = target
          if (!value || value < 0) value = 0

          if (currentItem.individualTotal) {
            item[param] = Number(value)
          } else {
            currentItem[param] = Number(value)
          }

          onChangeSubTotal(item)
        }}
        error={currentItem.error}
      />
    )
  }

  const getGeneralInputs = (item, general, disabled) => {
    return (
      <>
        <div style={{ marginTop: 5, display: 'flex', width: '100%' }}>
          {line.length > 1 && ((fieldEditPrice && importation) || !fieldEditPrice) && (
            <Col xs={importation ? 12 : 5} style={{ marginTop: 15 }}>
              <Switch
                //disabled={isGeneral}
                id={'individualTotal'}
                cStyle={{ alignSelf: 'flex-end' }}
                name={'checkDiscount'}
                checked={currentItem.individualTotal}
                onChange={({ target }) => {
                  const { checked } = target
                  currentItem.individualTotal = checked
                  if (checked) {
                    currentItem.subTotal = 0
                    if (importation) {
                      currentItem.tax = 0
                      currentItem.customPrice = 0
                    }
                  } else
                    line.forEach(l => {
                      l.ownPrice = 0
                      l.subTotal = 0
                      if (importation) {
                        l.tax = 0
                        l.customPrice = 0
                      }
                    })
                  onCheckSubtotal(checked)
                }}
                label={'Valores monetarios individuales'}
                topLabel
              />
            </Col>
          )}
          {(type === itemTypes.PURCHASE || type === itemTypes.EXPENSE) &&
            !importation && (
              <Col>
                {subTotalorPriceMobile(item, disabled, 'general', 'subTotal', general)}
              </Col>
            )}
        </div>
        {type === itemTypes.PURCHASE && importation && !currentItem.individualTotal && (
          <>
            {subTotalorPriceMobile(item, disabled, 'general', 'subTotal', general)}
            <Col xs={12}>
              {inputTaxesAndTotals(
                item,
                !currentItem.individualTotal
                  ? Number(currentItem.subTotal || 0) === 0
                  : !item.subTotal || Number(item.subTotal || 0) === 0,
                general,
                'tax',
              )}
            </Col>
            <Col xs={12}>
              {inputTaxesAndTotals(
                item,
                !currentItem.individualTotal
                  ? Number(currentItem.subTotal || 0) === 0
                  : !item.subTotal || Number(item.subTotal || 0) === 0,
                general,
                'customPrice',
              )}
            </Col>
          </>
        )}
      </>
    )
  }

  // DESKTOP
  const detailsItem = (item, isSubItem) => {
    return (
      <>
        <Col xs={gridNoPurchase(7, 4)} md={gridNoPurchase(7, 4)}>
          {/* Input de cantidad del item.  */}
          <NumberField
            value={item.quantity || 0}
            onValueChange={value => {
              changeQuantity(value, item)
            }}
            decimals={useDecimals ? currentItem.decimals : 0}
          />
        </Col>
        {type !== itemTypes.PURCHASE &&
        type !== itemTypes.EXPENSE &&
        type !== itemTypes.PRODUCTION ? (
          <>
            <Col
              className={'b-user-email right'}
              style={{ textAlign: 'right', marginRight: '7px' }}
              xs={5}
              md={5}>
              {currentItem.id !== 1 ? `${item.currentQuantity || 0.0} u.` : ''}{' '}
              {/* Vista previa de la cantidad actual disponible para un item. */}
            </Col>
          </>
        ) : (
          <>
            <Col
              xs={itemOnlyCost ? 4 : type === itemTypes.PRODUCTION ? 5 : 3}
              md={itemOnlyCost ? 4 : type === itemTypes.PRODUCTION ? 5 : 3}>
              {itemOnlyCost || type === itemTypes.PRODUCTION ? (
                <>
                  <NumberField
                    disabled={item.quantity === 0 || !item.quantity}
                    value={item.ownPrice || 0}
                    onValueChange={value => {
                      if (!value || value < 0) value = 0
                      if (currentItem.individualTotal) {
                        item.ownPrice = value
                        if (item.quantity > 0) {
                          let sub = value * item.quantity
                          item.subTotal = sub.toFixed(2)
                        }
                      } else {
                        item.ownPrice = value
                        currentItem.subTotal = updateSubTotal()
                      }
                      onChangeSubTotal(item)
                    }}
                    decimals={4}
                  />
                </>
              ) : (
                `${currency}. ` + (item.ownPrice || '0.00')
              )}{' '}
              {/* Preview of price calculated by subtotal | fieldEditPrice: OFF*/}
            </Col>
            {(currentItem.individualTotal || !isSubItem) &&
              type !== itemTypes.PRODUCTION && (
                <Col>
                  {itemOnlyCost ? (
                    `${currency}.  ${
                      currentItem.individualTotal
                        ? item.subTotal || '0.00'
                        : currentItem.subTotal || '0.00'
                    }`
                  ) : (
                    <NumberField
                      disabled={item.quantity === 0 || !item.quantity}
                      value={
                        (currentItem.individualTotal
                          ? item.subTotal
                          : currentItem.subTotal) || 0
                      }
                      onValueChange={value => {
                        if (!value || value < 0) value = 0
                        if (currentItem.individualTotal) {
                          item.subTotal = value
                          if (item.quantity > 0) {
                            let price = value / item.quantity
                            item.ownPrice = price.toFixed(2)
                          }
                        } else currentItem.subTotal = value

                        onChangeSubTotal(item)
                      }}
                      decimals={4}
                    />
                  )}
                </Col>
              )}

            {!isSubItem && // Switch que permite trabajar los montos generales como Subtotal, precio , etc, de manera individual por cada item del árbol de ítems
              item.presentationFactor !== null && // Variable : currentiItem -> individualTotal
              type !== itemTypes.PRODUCTION &&
              (!itemOnlyCost || importation) && (
                <Col xs={2} md={2}>
                  <Switch
                    checked={currentItem.individualTotal}
                    label={' '}
                    disabled={item.quantity === 0 || !item.quantity}
                    onChange={({ target }) => {
                      const { checked } = target
                      currentItem.individualTotal = checked
                      if (checked) {
                        currentItem.subTotal = 0
                        if (importation) {
                          currentItem.tax = 0
                          currentItem.customPrice = 0
                        }
                      } else
                        line.forEach(l => {
                          l.ownPrice = 0
                          l.subTotal = 0
                          if (importation) {
                            l.tax = 0
                            l.customPrice = 0
                          }
                        })
                      onCheckSubtotal(checked)
                    }}
                    info={'Aplicar subtotales individuales al árbol de ítems'}
                  />
                </Col>
              )}
          </>
        )}

        {/* Para agregar mas atributos al items agregar aqui en el detalle
          ademas se debe modificar las cantidades del grid. 
          En el getHeaders() del archivo ItemsToSelect, cambiar tamanos para que se vea bien el diseno. */}
      </>
    )
  }

  const inputQuantity = item => {
    return (
      <NumberField
        value={item.quantity || 0}
        onValueChange={value => {
          changeQuantity(value, item)
        }}
        decimals={useDecimals ? currentItem.decimals : 0}
        label={
          currentItem.id !== 1 &&
          (importation ? (
            'Cantidad'
          ) : (
            <div className="space-between">
              <div>{item.productData.name}</div>
              {type !== itemTypes.PURCHASE && type !== itemTypes.EXPENSE && (
                <div>{item.currentQuantity + ' u.'}</div>
              )}
            </div>
          ))
        }
        labelStyle={{ width: '100%', marginTop: 15 }}
      />
    )
  }

  const renderItemValuesMobile = (item, index) => {
    return (
      <>
        <Row>
          <Col>
            {inputQuantity(item)}
            {(type === itemTypes.PURCHASE || type === itemTypes.EXPENSE) && // Preview of price calculated by subtotal | fieldEditPrice: OFF
              !itemOnlyCost && (
                <div
                  className="ft-sub left ml-2"
                  style={{
                    cursor: 'pointer',
                    fontWeight: 400,
                  }}>
                  Precio unitario: {`${currency}. ` + (item.ownPrice || '0.00')}
                </div>
              )}
          </Col>
          {(((type === itemTypes.PURCHASE || type === itemTypes.EXPENSE) && // Enter price manually if fieldEditPrice: ON , or Production
            itemOnlyCost) ||
            type === itemTypes.PRODUCTION) && (
            <Col xs={6} md={6}>
              {subTotalorPriceMobile(item, false, 'mobil', 'ownPrice')}
            </Col>
          )}
          {(type === itemTypes.PURCHASE || type === itemTypes.EXPENSE) && // GASTO O COMPRA, Valores individuales, NO importacion,  fieldEditPrice: OFF
            currentItem.individualTotal &&
            !importation &&
            !itemOnlyCost && (
              <Col xs={6} md={6}>
                {subTotalorPriceMobile(item, false, 'mobil', 'subTotal')}{' '}
                {/* Input para ingreso de subtotal propio de cada item. */}
              </Col>
            )}
        </Row>
        {(type === itemTypes.PURCHASE || type === itemTypes.EXPENSE) && // Compras de importacion .
          currentItem.individualTotal &&
          importation && (
            <>
              <Col xs={12} md={12}>
                {subTotalorPriceMobile(
                  item,
                  item.quantity === 0 || !item.quantity,
                  'mobilImporation' + index,
                  'subTotal',
                )}
              </Col>
              <Col xs={12} md={12}>
                {inputTaxesAndTotals(
                  item,
                  !currentItem.individualTotal
                    ? Number(currentItem.subTotal || 0) === 0
                    : !item.subTotal || Number(item.subTotal || 0) === 0,
                  null,
                  'tax',
                )}{' '}
              </Col>
              <Col xs={12} md={12}>
                {inputTaxesAndTotals(
                  item,
                  !currentItem.individualTotal
                    ? Number(currentItem.subTotal || 0) === 0
                    : !item.subTotal || Number(item.subTotal || 0) === 0,
                  null,
                  'customPrice',
                )}{' '}
              </Col>
            </>
          )}

        {/* Para agregar mas atributos al items agregar aqui*/}
      </>
    )
  }

  const renderItemValues = (item, index, subItem) => {
    return (
      <Row key={index}>
        {subItem ? ( // is a subItem from line items
          <>
            <Col xs={gridNoPurchase(8, 6)} md={gridNoPurchase(8, 6)}>
              {getSubItem(item.productData, index)} {/* Only getting the name */}
            </Col>
            <Col xs={gridNoPurchase(4, 6)} md={gridNoPurchase(4, 6)}>
              <Row>{detailsItem(item, subItem)}</Row> {/* More values to item */}
            </Col>
          </>
        ) : (
          <>{detailsItem(item, subItem)}</> // More values to item
        )}
      </Row>
    )
  }

  if (type !== itemTypes.PURCHASE && type !== itemTypes.EXPENSE)
    renderQuantities(currentItem.baseProductQuantity, line !== null ? line : [])
  return (
    <div className={'column'}>
      {currentItem.alert && currentItem.id !== 1 && type !== 3 && (
        <label
          className={'left'}
          style={{
            fontSize: '13px',
            letterSpacing: '-0.31px',
            lineHeight: '22px',
            color: 'red',
          }}>
          Se están agregando items sin existencia
        </label>
      )}

      {/* Main item from line items. */}
      <Row>
        {select && (
          <Col
            xs={
              modal
                ? 12
                : haveLocations
                ? type === itemTypes.PRODUCTION
                  ? 8
                  : 6
                : gridNoPurchase(8, 6)
            }
            md={
              modal
                ? 12
                : haveLocations
                ? type === itemTypes.PRODUCTION
                  ? 8
                  : 6
                : gridNoPurchase(8, 6)
            }>
            {select}
          </Col>
        )}
        {/* // Ubicaciones.  */}
        {haveLocations &&
          type !== itemTypes.PURCHASE &&
          type !== itemTypes.EXPENSE &&
          type !== itemTypes.PRODUCTION && (
            <Col xs={2} md={2}>
              {locations &&
                locations.map(item => (
                  <>
                    {item.name}
                    <br />
                  </>
                ))}
            </Col>
          )}
        {/* // Values of the main item (quantity, price, subtotal, etc) */}
        <Col
          xs={modal ? 12 : gridNoPurchase(4, 6)}
          md={modal ? 12 : gridNoPurchase(4, 6)}>
          {line &&
            line
              .filter(l => l.productData.id === currentItem.value)
              .map(item =>
                modal
                  ? renderItemValuesMobile(item, 10000)
                  : renderItemValues(item, 10000, false),
              )}
        </Col>
      </Row>

      {/* Items Line  */}
      <div style={{ marginTop: modal ? '0px' : '10px' }}>
        {line &&
          line
            .filter(l => l.productData.id !== currentItem.value)
            .map((item, index) => {
              if (importation && modal) {
                // Compras de importacion MOVIL
                return (
                  <>
                    <ListGroup variant="light" style={{ marginTop: '17px' }}>
                      <ListGroup.Item
                        onClick={e => {
                          e.preventDefault()
                          e.stopPropagation()
                          item.openCollapse
                            ? (item.openCollapse = !item.openCollapse)
                            : (item.openCollapse = true)
                          onChangeSubTotal()
                        }}>
                        {' '}
                        {item.productData.name}
                        <div style={{ position: 'absolute', right: 10, bottom: '15px' }}>
                          <Icon
                            icon={!item.openCollapse ? faAngleDown : faAngleUp}
                            tooltip={!item.openCollapse ? 'Expandir' : 'Colapsar'}
                          />
                        </div>
                        <Collapse in={item.openCollapse}>
                          <div>
                            {modal
                              ? renderItemValuesMobile(item, index)
                              : renderItemValues(item, index, true)}
                          </div>{' '}
                          {/* Item values from line item */}
                        </Collapse>
                      </ListGroup.Item>
                    </ListGroup>
                  </>
                )
              } else {
                return (
                  <>
                    {modal
                      ? renderItemValuesMobile(item, index)
                      : renderItemValues(item, index, true)}
                  </>
                ) // Item values from line item
              }
            })}
      </div>

      {/* Valores generales que afectan el árbol de ítems, MOVIL */}
      <div>
        {(type === itemTypes.PURCHASE || type === itemTypes.EXPENSE) &&
          modal &&
          line &&
          line
            .filter(l => l.productData.id === currentItem.value)
            .map(item =>
              getGeneralInputs(item, true, currentItem.individualTotal || itemOnlyCost),
            )}
      </div>
    </div>
  )
}
