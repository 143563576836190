import { actionTypes } from '../actions/balance.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  balance: 0.0,
  balanceInCompany: {},
  balanceInCompanyKolo: {},
  balanceDetails: {},
  balanceDetailsCompany: {},
  balanceDocuments: [],
  balanceEntries: [],
  balanceEntryTypes: [],
  usersBalance: [],
  userBalanceEntries: [],
  balanceCompanies: [],
  userEntriesInCompany: [],
  koloCompaniesBalance: [],
  koloCompanyBalance: [],
  companyBalance: [],
  totalBalance: 0,
  uCompanyBalance: [],
  uTotalBalance: 0,
  balanceReportResponse: {},
  registers: [],
  sentCashTransfers: [],
  receivedCashTransfers: [],
  totalSentCashTransfers: null,
  totalReceivedCashTransfers: null,
}

const BalanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_COMPANY_BALANCE_REPORT_CHILDREN):
      return { ...state, registers: extract(action.registers, []) }
    case successState(actionTypes.GET_COMPANY_BALANCE_REPORT):
      return {
        ...state,
        balanceReportResponse: extract(action.balanceReportResponse, {}),
      }
    case successState(actionTypes.GET_USER_COMPANY_BALANCE):
      return {
        ...state,
        uCompanyBalance: action.response ? action.response.list : [],
        uTotalBalance: action.response ? action.response.total : 0,
      }
    case successState(actionTypes.GET_COMPANY_BALANCE):
      return {
        ...state,
        companyBalance: action.response ? action.response.list : [],
        totalBalance: action.response ? action.response.total : 0,
      }
    case successState(actionTypes.GET_KOLO_BY_COMPANY_BALANCE):
      return {
        ...state,
        koloCompanyBalance: extract(action.koloCompanyBalance, []),
      }
    case successState(actionTypes.GET_KOLO_COMPANY_BALANCE):
      return {
        ...state,
        koloCompaniesBalance: extract(action.koloCompaniesBalance, []),
      }
    case successState(actionTypes.GET_GENERAL_BALANCE):
      return {
        ...state,
        balance: extract(action.balance, []),
      }
    case successState(actionTypes.GET_DETAIL_BALANCE):
      return {
        ...state,
        balanceDetails: extract(action.balanceDetails, []),
      }
    case successState(actionTypes.GET_DETAIL_BALANCE_COMPANY):
      return {
        ...state,
        balanceDetailsCompany: extract(action.balanceDetailsCompany, []),
      }
    case successState(actionTypes.GET_DOCUMENTS_BALANCE):
      return {
        ...state,
        balanceDocuments: extract(action.balanceDocuments, []),
      }
    case successState(actionTypes.GET_BALANCE_ENTRY_TYPES):
      return {
        ...state,
        balanceEntryTypes: extract(action.balanceEntryTypes, []),
      }
    case successState(actionTypes.GET_BALANCE_ENTRIES):
      return {
        ...state,
        balanceEntries: extract(action.balanceEntries, []),
      }
    case successState(actionTypes.GET_BALANCE_COMPANY_ENTRIES):
      return {
        ...state,
        balanceEntries: action.balanceEntries ? action.balanceEntries.list : [],
        totalBalance: action.balanceEntries ? action.balanceEntries.total : 0,
      }
    case successState(actionTypes.GET_BALANCE_IN_COMPANY):
      return {
        ...state,
        balanceInCompany: extract(action.balanceInCompany, 0.0),
      }
    case successState(actionTypes.GET_BALANCE_IN_COMPANY_KOLO):
      return {
        ...state,
        balanceInCompanyKolo: extract(action.balanceInCompanyKolo, 0.0),
      }
    case successState(actionTypes.GET_USERS_BALANCE):
      return {
        ...state,
        usersBalance: extract(action.usersBalance, 0.0),
      }
    case successState(actionTypes.CREATE_USER_ENTRY):
      return {
        ...state,
        userBalanceEntries: extract(action.userBalanceEntries, []),
      }
    case successState(actionTypes.GET_BALANCE_COMPANIES_KOLO):
      return {
        ...state,
        balanceCompanies: extract(action.balanceCompanies, []), //ADD_USER_BALANCE_COMPANY_KOLO
      }
    case successState(actionTypes.ADD_USER_BALANCE_COMPANY_KOLO):
      return {
        ...state,
        userBalanceEntries: extract(action.userBalanceEntries, []), //ADD_USER_BALANCE_COMPANY_KOLO
      }
    case successState(actionTypes.GET_ADMIN_BALANCE_IN_COMPANY):
      return {
        ...state,
        userEntriesInCompany: extract(action.userEntriesInCompany, []), //ADD_USER_BALANCE_COMPANY_KOLO
      }
    case successState(actionTypes.GET_SENT_CASH_TRANSFERS):
      return {
        ...state,
        sentCashTransfers: extract(action.payload.cashTransfers, {}),
        totalSentCashTransfers: extract(action.payload.total, null),
      }
    case successState(actionTypes.GET_RECEIVED_CASH_TRANSFERS):
      return {
        ...state,
        receivedCashTransfers: extract(action.payload.cashTransfers, {}),
        totalReceivedCashTransfers: extract(action.payload.total, null),
      }
    default:
      return state ? state : initialState
  }
}

export default BalanceReducer
