const scheduledReportTypesEnum = {
  GENERAL_TURN: { value: 1, label: 'Turno general' },
  PATRIMONIAL: { value: 2, label: 'Patrimonial' },
  WASTE: { value: 3, label: 'Gastos segmentado' },
  INVENTORY_MIN_MAX: { value: 4, label: 'Inventario mínimo y máximo' },
  SOON_EXPIRING_PRODUCTS: { value: 5, label: 'Productos próximos a vencer' },
}

const scheduledReportTypes = Object.values(scheduledReportTypesEnum)

export { scheduledReportTypesEnum, scheduledReportTypes }
