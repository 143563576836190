import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Col, ProgressBar, Row} from "react-bootstrap";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import Card from "../../../components/cards/Card";
import {HorizontalBar} from "react-chartjs-2";
import {optionsBar, colorArray} from '../options'
import {actionTypes, getBalanceReport} from "../../../actions/clients.actions";
import {selectGetBalanceReport} from "../../../selectors/clients.selector";
import {formatNumberWithCommas} from "../../../utils/formatters";
import {TableV2, Title} from "../../../components";
import {loadingSelector} from "../../../selectors/loading.selector";
import {quantityOptionsClients, quantityOptionsUsers} from '../../../utils/graphs'
import CustomSelect from "../../../components/inputs/Select/CustomSelect";
import { selectCurrentCurrency } from "../../../selectors/currencies.selector";

const ClientBalance = () => {

  const dispatch = useDispatch()

  const report = useSelector(selectGetBalanceReport)
  const currentCurrency = useSelector(selectCurrentCurrency)

  const loading = useSelector((state) => loadingSelector([actionTypes.GET_BALANCE_REPORT])(state))

  const [action, setAction] = useState({})
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  const [quantityClients, setQuantityClients] = useState({value: 10, label: '10 Clientes'});

  useEffect(() => {
    dispatch(getBalanceReport({start: null, end: null}))
  }, [])

  const onDateFromChange = date => {
    if(date){
      setStartDate(date)
      dispatch(getBalanceReport({start: date.valueOf(), end: endDate.valueOf()}))
    }
  };

  const onDateToChange = date => {
    if(date){
      setEndDate(date)
      dispatch(getBalanceReport({start: startDate.valueOf(), end: date.valueOf()}))
    }
  };

  const changeQuantity = (item) => {
    setQuantityClients(item)
  };

  const getData = () => {
    const data = [...report].slice(0, quantityClients.value)

    return (
      {
        labels: data.map(r => r.companyName),
        datasets: [
          {
            data: data.map(r => r.balance),
            label: 'Clientes',
            backgroundColor:  data.map((p, i) => colorArray[i] + '66'),
            borderColor: data.map((p, i) => colorArray[i]),
            borderWidth: 2,
          }
        ]
      }
    )
  }

  const customFilters = () => {
    return(
      <div>
        <Col xs={12} sm={12} md={6}>
          <CustomDatePicker
            label={'Desde:'}
            value={startDate}
            onDayChange={onDateFromChange}
          />
        </Col>
        <Col xs={12} sm={12} md={6}>
          <CustomDatePicker
            label={'Hasta:'}
            value={endDate}
            onDayChange={onDateToChange}
            disabledDays={{before: startDate}}
          />
        </Col>
      </div>
    )
  }

  return(
    <div>
      <Title title={'Balance de Clientes'}/>
      {loading &&
      <Row>
        <Col>
          <div className={"pb-custom"}>
            <ProgressBar label='Cargando' animated now={100} style={{marginBottom: 10}}/>
          </div>
        </Col>
      </Row>
      }
      <Row>
        <Col xs={12} md={3}>
          <Card>
            <CustomSelect label={"Cantidad de Clientes"}
                          name={"clients_quantity"}
                          value={quantityClients}
                          options={quantityOptionsClients}
                          onChange={changeQuantity}
            />
            <TableV2
              
              items={report.slice(0, quantityClients.value)}
              customFilter={customFilters()}
              headers={[
                { label: 'Nombre', show: true, value: ['nombreC'], type: 'text', className: 'mini', custom:(item)=>item.nombreC = `${item.code}-${item.companyName}` },
                { label: 'Balance', show: true, value: ['balance'], type: 'currency', className: 'mini' },
                { config: true, show: true, label: '', className: 'mini center' }

              ]}
              mobileAuto
              storageKey={`clientBalance`}
            />
          </Card>
        </Col>
        <Col xs={12} md={9}>
          <Card>
            <div>
              <div className="g-title">Balances</div>
              <div className="ru-graph-w">
                <HorizontalBar data={getData()} height={600} options={optionsBar}/>
              </div>
              <div className="ru-graph-m">
                <HorizontalBar data={getData()} height={400} options={optionsBar}/>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )

}

export default ClientBalance;
