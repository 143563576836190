import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Col, Row } from 'react-bootstrap'

import { Select, CustomTabs, TableV2 } from '../../index'

import { actionTypes as typeO, getOrderReport } from '../../../actions/orders.actions'
import {
  selectBalanceReport,
  selectOrderReport,
} from '../../../selectors/orders.selector'

import { selectUsers } from '../../../selectors/modules.selector'

import { loadingSelector } from '../../../selectors/loading.selector'

import { formatNumberWithCommas } from '../../../utils/formatters'
import { selectAllWarehousesForSelect } from '../../../selectors/warehouse.selector'
import Money from '../../Money/Money'
import { Chart } from 'src/components/Graph/Chart'
import { ChartType } from 'src/enums/ChartType'

const ModalOrderTotalSummary = ({ cDates, categories, pSale }) => {
  const dispatch = useDispatch()

  const warehouses = useSelector(selectAllWarehousesForSelect)
  const users = useSelector(selectUsers)

  const report = useSelector(selectOrderReport)
  const balance = useSelector(selectBalanceReport)
  const loading = useSelector(state => loadingSelector([typeO.GET_ORDER_REPORT])(state))

  /*permissions*/
  const [admin] = useState(true)

  const graphForm = [
    { value: 0, label: 'Barra' },
    { value: 1, label: 'Lineal' },
    { value: 2, label: 'Dona' },
  ]

  const [dates, setDates] = useState({ start: new Date(), end: new Date() })
  const [filter, setFilter] = useState({ value: 1, label: 'Día' })
  const [f, setF] = useState('')
  const [filters, setFilters] = useState({
    warehouse: { label: '- Todos -', value: null },
    cUser: { label: '- Todos -', value: null },
    dUser: { label: '- Todos -', value: null },
    start: new Date(),
    end: new Date(),
  })
  const [graph, setGraph] = useState([
    { value: 0, label: 'Barra' },
    { value: 0, label: 'Barra' },
    { value: 0, label: 'Barra' },
    { value: 0, label: 'Barra' },
    { value: 0, label: 'Barra' },
  ])

  useEffect(() => {
    setF(JSON.stringify({ ...filters, ...cDates, ...categories, ...pSale }))
  }, [cDates])

  useEffect(() => {
    if (
      f === '' ||
      f === JSON.stringify({ ...filters, ...cDates, ...categories, ...pSale })
    )
      return
    setF(JSON.stringify({ ...filters, ...cDates, ...categories, ...pSale }))
  }, [filters, categories, pSale])

  useEffect(() => {
    if (f !== JSON.stringify({ ...filters, ...cDates, ...categories, ...pSale })) return
    setUp(cDates.start, cDates.end)
  }, [f])

  const setUpChart = (start, end, filterType) => {
    const { warehouse, cUser, dUser } = filters
    dispatch(
      getOrderReport(
        start,
        end,
        true,
        cUser.value,
        dUser.value,
        1,
        filterType,
        null,
        warehouse.value,
        null,
        {
          categories: categories ? categories.map(c => c.id) : [],
          pos: pSale ? pSale.map(d => d.id) : [],
        },
      ),
    )
  }

  const setUp = (start, end) => {
    setDates({ start, end })

    const cStart = start.setHours(0, 0, 0, 0).valueOf()
    const cEnd = end.setHours(23, 59, 59, 99).valueOf()

    setUpChart(cStart, cEnd, filter.value)
  }

  const formatData = (id, name) => {
    return {
      labels: report.map(r => r.label),
      datasets: [
        {
          data: report.map(r =>
            id === 0 ? r.total : id === 1 ? r.cpc : id === 2 ? r.purchase : r.cotizap,
          ),
          label: name,
        },
      ],
    }
  }

  const renderChart = (id, name) => {
    return (
      <div style={{ backgroundColor: 'white', color: 'default' }}>
        <Row>
          <Col xl={12} lg={12} md={12}>
            <Row className={'container-buttons'}>
              <Col xs={4}>
                <Select
                  label={'Filtros'}
                  options={[
                    { value: 1, label: 'Día' },
                    { value: 2, label: 'Semana' },
                    { value: 3, label: 'Mes' },
                  ]}
                  value={filter}
                  onChange={e => {
                    setUpChart(
                      dates.start.setHours(0, 0, 0, 0).valueOf(),
                      dates.end.setHours(23, 59, 59, 99).valueOf(),
                      e.value,
                    )
                    setFilter(e)
                  }}
                />
              </Col>
              <Col xs={4}>
                <Select
                  label={'Forma'}
                  options={graphForm}
                  value={graph[id]}
                  onChange={e => {
                    graph[id] = e
                    setGraph([...graph])
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={12} md={12} sm={12}>
            <Chart
              data={formatData(id, name)}
              currencyValues
              title={name}
              height={graph[id].value === 0 ? 450 : 150}
              type={
                graph[id].value === 0
                  ? ChartType.BAR
                  : graph[id].value === 1
                  ? ChartType.LINE
                  : ChartType.DONUT
              }
            />
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <div>
      <Row>
        <Col xl={4} md={6} sm={6} xs={12}>
          <Select
            label={'Por Bodega'}
            info={'Filtra las ordenes por la bodega selccionada'}
            options={[{ value: null, label: '- Todos -' }, ...warehouses]}
            value={filters.warehouse}
            name={'warehouse'}
            onChange={warehouse => setFilters({ ...filters, warehouse })}
          />
        </Col>
        <Col xl={4} md={6} sm={6} xs={12}>
          <Select
            label={'Creador por'}
            info={'Filtra las ordenes por el usuario que la creo'}
            options={[{ value: 0, label: '- Todos -' }, ...users]}
            value={filters.cUser}
            name={'cUser'}
            onChange={cUser => setFilters({ ...filters, cUser })}
          />
        </Col>
        <Col xl={4} md={6} sm={6} xs={12}>
          <Select
            label={'Entregado por'}
            info={'Filtra las ordenes por el usuario que la entrego'}
            options={[{ value: 0, label: '- Todos -' }, ...users]}
            value={filters.dUser}
            name={'dUser'}
            onChange={dUser => setFilters({ ...filters, dUser })}
          />
        </Col>
      </Row>

      <CustomTabs
        loading={loading}
        items={[
          {
            show: false,
            title: 'Totales',
            info: 'Sumatoria de totales',
            component: () => (
              <Row>
                <Col xl={12} sm={12} md={12}>
                  <TableV2
                    loading={loading}
                    hideFilter
                    items={[
                      { label: 'Total de órdenes', value: balance.total || 0 },
                      { label: 'Utilidad Bruta', value: balance.purchase || 0 },
                      { label: 'Descuentos', value: balance.discount || 0 },
                    ]}
                    renderRow={(item, index) => (
                      <div key={index} className={'b-user-item'}>
                        <div
                          className={'justify-content-start align-items-start bu-en'}
                          style={{ textAlign: 'left' }}>
                          <div className={'b-user-name'}>{item.label}</div>
                          <Money className={'b-user-balance-m'}>
                            {formatNumberWithCommas(Math.abs(item.value))}
                          </Money>
                        </div>
                        <div
                          className={'d-flex row align-items-center mr-2 bu-b'}
                          style={{ textAlign: 'right' }}>
                          <Money className={'b-user-balance mr-2'}>
                            {formatNumberWithCommas(Math.abs(item.value))}
                          </Money>
                        </div>
                      </div>
                    )}
                  />
                </Col>
              </Row>
            ),
          },
          {
            show: admin,
            title: 'Ordenes',
            info: 'Grafica de Ordenes propias',
            component: () => renderChart(0, 'Balance de ordenes'),
          },
          {
            show: admin,
            title: 'Cuentas por cobrar',
            info: 'Grafica de las cuentas por cobrar',
            component: () => renderChart(1, 'Balance de Cuentas por cobrar'),
          },
          {
            show: admin,
            title: 'Utilidad bruta',
            info: 'Grafica de utilidad bruta',
            component: () => renderChart(2, 'Balance de transportista'),
          },
          {
            show: admin,
            title: 'Ordenes enlazadas',
            info: 'Grafica de ordenes enlazadas',
            component: () => renderChart(4, 'Ordenes Enlazadas'),
          },
        ]}
      />
    </div>
  )
}
export default ModalOrderTotalSummary
