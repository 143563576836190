import React, { useEffect } from 'react'

import { Col, Row } from 'react-bootstrap'
import { MoneyField } from 'src/components/index'
import { haveAnyValue } from 'src/utils/utilitiesV2'

import useDecimalNumber from 'src/hooks/useDecimalNumber'

interface ICashInformationProps {
  amount: number
  received: number
  onChange: (received: number, returned: number) => void
  disabled?: boolean
}

const CashInformation = ({
  amount,
  received,
  onChange,
  disabled = false,
}: ICashInformationProps) => {
  const getDecimalNumber = useDecimalNumber()

  const { parsedValue: returned } = getDecimalNumber(received - amount, {
    decimals: 2,
    min: 0,
  })

  useEffect(() => {
    processChange(received)
  }, [amount])

  const processChange = (value: number) => {
    const newAmount = haveAnyValue(amount) ? amount : 0

    const returned: number = value < newAmount ? 0 : value - newAmount
    onChange(value, returned)
  }

  return (
    <Row>
      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
        <MoneyField
          label={'Efectivo recibido'}
          value={received}
          min={0}
          disabled={disabled}
          onValueChange={value => processChange(value)}
        />
      </Col>
      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
        <MoneyField label={'Cambio'} value={returned} min={0} disabled />
      </Col>
    </Row>
  )
}
export default CashInformation
