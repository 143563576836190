import React, { Component }  from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Card from '../../../components/cards/Card';
import CollapsibleCard from '../../../components/cards/collapsible-card/CollapsibleCard';
import CustomSelect from '../../../components/inputs/Select/CustomSelect';
import { getInventoryDetail, getCategorizations, getWarehouses, actionTypes } from '../../../actions/warehouse.actions';
import { getProducts } from '../../../actions/products.actions';
import { selectInventoryDetail, selectCategorizations, selectAllWarehousesWithAllForSelect } from '../../../selectors/warehouse.selector';
import { selectAllProductsWithAllForSelect } from '../../../selectors/products.selector';

import { loadingSelector } from "../../../selectors/loading.selector";
import { TableV2 } from '../../../components';

class Inventory extends Component {
    
    state = {
        startDate: new Date(),
        endDate: new Date(),
        selectedWarehouse: null,
        selectedProduct: null,
        selectedCategorization: null
    };
    
    componentDidMount() {
        this.props.getWarehouses()
        this.props.getCategorizations()
        this.props.getProducts()
        this.getInventoryMovements({})
    }
    
    onDateFiltersChanged = (startDate, endDate) => {
        const {selectedWarehouse, selectedProduct} = this.state;
        this.setState({startDate, endDate})
        this.getInventoryMovements({selectedWarehouse, selectedProduct, startDate, endDate});
    };
    
    getInventoryMovements = ({selectedWarehouse, selectedProduct, startDate, endDate}) => {
        startDate = startDate || this.state.startDate;
        endDate = endDate || this.state.endDate;
        const warehouseId = selectedWarehouse && selectedWarehouse.value > 0 ? selectedWarehouse.value : null
        const productId = selectedProduct && selectedProduct.value > 0 ? selectedProduct.value : null

        this.props.getInventoryDetail(warehouseId, productId, startDate.getTime(), endDate.getTime());
    }

 
    getHeaders = () => {
        return [
            {label: 'Bodega', show: true, value: ['warehouseNode'], type: 'text' ,  className:'mini'},
            {label: 'Descripción', show: true, value: ['description'], type: 'text' ,  className:'mini'},
            {label: 'Producto', show: true, value: ['productName'], type: 'text' ,  className:'mini'},
            {label: 'Cantidad', show: true, value: ['originalQuantity'], type: 'text' ,  className:'mini'},
            {label: 'Movimiento', show: true, value: ['movementTypeName'], type: 'text' ,  className:'mini'},
            {label: 'Categorización', show: true, value: ['categorizationName'], type: 'text' ,  className:'mini'},
            {label: 'Modificado por', show: true, value: ['userName'], type: 'text' ,  className:'mini'},
            {label: 'Fecha', show: true, value: ['date'], type: 'date' ,  className:'mini'},
            {config:true, show: true, label:'', className:'mini center'}

        ]
    }

    onSelectWarehouse = (selectedWarehouse) => {
        const {selectedProduct} = this.state;
        this.setState({selectedWarehouse})
        this.getInventoryMovements({selectedWarehouse, selectedProduct});
    }

    onSelectProduct = (selectedProduct) => {
        const {selectedWarehouse} = this.state;
        this.setState({selectedProduct})
        this.getInventoryMovements({selectedWarehouse, selectedProduct});
    }

    onSelectCategorization = (selectedCategorization) => {
        this.setState({selectedCategorization: selectedCategorization})
    }

    render() {
        const { selectedWarehouse, selectedProduct, selectedCategorization } = this.state;
        const { inventoryDetail, products, warehouses, categorizations, inventoryHistoryLoading } = this.props;
        const headers = ['Bodega', 'Descripción', 'Producto', 'Cantidad', 'Movimiento', 'Categorización', 'Modificado por', 'Fecha'];
        let items = inventoryDetail.sort((a, b) => b.date - a.date);
        if (selectedCategorization) {
            items = items.filter(i => i.categorizationId === selectedCategorization.value);
        }
        return (
            <div>
                <Row>
                    <Col md={3}>
                        <CollapsibleCard title={'Filtros'} startCollapsed={false}>
                            <Row>
                               <Col>
                                <CustomSelect 
                                    label={'Bodega'}
                                    value={selectedWarehouse}
                                    options={warehouses}
                                    onChange={this.onSelectWarehouse}
                                />
                               </Col>
                            </Row>
                            <Row>
                                <Col>
                                <CustomSelect 
                                    label={'Producto'}
                                    value={selectedProduct}
                                    options={products}
                                    onChange={this.onSelectProduct}
                                />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <CustomSelect 
                                    label={'Categorización'}
                                    value={selectedCategorization}
                                    options={categorizations}
                                    onChange={this.onSelectCategorization}
                                />
                                </Col>
                            </Row>
                        </CollapsibleCard>
                    </Col>
                    <Col md={9}>
                        <Card title={'Historial de Inventario'}>
                            <TableV2
                                items={items}
                                loading={inventoryHistoryLoading}
                                dateFilter={true}
                                onDateChange={this.onDateFiltersChanged}
                                noItemsLegend={'No se encontraron movimientos con los filtros aplicados'}
                                headers={this.getHeaders()}
                                mobileAuto
                                storageKey={`inventoryOld`}
                                
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    inventoryDetail: selectInventoryDetail(state),
    warehouses: selectAllWarehousesWithAllForSelect(state),
    categorizations: selectCategorizations(state),
    inventoryHistoryLoading: loadingSelector([actionTypes.GET_INVENTORY_DETAIL])(state),
    products: selectAllProductsWithAllForSelect(state)
});

const mapDispatchToProps = dispatch => ({
    getInventoryDetail: (warehouseId, productId, startDate, endDate) => dispatch(getInventoryDetail(warehouseId, productId, startDate, endDate)),
    getCategorizations: () => dispatch(getCategorizations()),
    getWarehouses: () => dispatch(getWarehouses()),
    getProducts: () => dispatch(getProducts())
})

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
