import VideosService from '../services/videos.services'
import { executeAction } from './global.actions'

export const actionTypes = {
  GET_ALL_VIDEOS: 'GET_ALL_VIDEOS',
  GET_VIDEOS: 'GET_VIDEOS',
  SAVE_NEW_VIDEO: 'SAVE_NEW_VIDEO',
  EDIT_VIDEO: 'EDIT_VIDEO',
  REMOVE_VIDEO: 'REMOVE_VIDEO',
  REMOVE_URL_VIDEO: 'REMOVE_URL_VIDEO',
  GET_MANUALS: 'GET_MANUALS',
  GET_APP_VIDEOS: 'GET_APP_VIDEOS',
}

export const getAllVideos = companyId => dispatch => {
  const process = async () => ({
    allVideos: await VideosService.getAllVideos({ companyId }),
  })
  dispatch(executeAction(actionTypes.GET_ALL_VIDEOS, process))
}

export const getVideos = (companyId, start, end, search) => dispatch => {
  const process = async () => ({
    videos: await VideosService.getVideos({ companyId, start, end, search }),
    start,
    end,
    search,
  })
  dispatch(executeAction(actionTypes.GET_VIDEOS, process))
}

export const saveNewVideo = video => dispatch => {
  const process = async () => {
    const videoResponse = await VideosService.saveNewVideo(video)
    dispatch(getAllVideos())
    return { videoResponse }
  }
  dispatch(executeAction(actionTypes.SAVE_NEW_VIDEO, process))
}

export const editVideo = (video, videoId) => dispatch => {
  const process = async () => {
    const videoResponse = await VideosService.editVideo(video, videoId)
    dispatch(getAllVideos())
    return { videoResponse }
  }
  dispatch(executeAction(actionTypes.EDIT_VIDEO, process))
}

export const removeVideo = videoId => dispatch => {
  const process = async () => {
    const videoResponse = await VideosService.removeVideo(videoId)
    dispatch(getAllVideos())
    return { videoResponse }
  }
  dispatch(executeAction(actionTypes.REMOVE_VIDEO, process))
}

export const removeUrlVideo = urlId => dispatch => {
  const process = async () => {
    const videoResponse = await VideosService.removeUrlVideo(urlId)
    dispatch(getAllVideos())
    return { videoResponse }
  }
  dispatch(executeAction(actionTypes.REMOVE_URL_VIDEO, process))
}

export const getManuals = () => dispatch => {
  const process = async () => ({ manuals: await VideosService.getManuals() })
  dispatch(executeAction(actionTypes.GET_MANUALS, process))
}

export const getAppVideos = appId => dispatch => {
  const process = async () => ({
    appVideos: await VideosService.getAppVideos(appId),
  })
  dispatch(executeAction(actionTypes.GET_APP_VIDEOS, process))
}
