import ProductsService from '../services/products.service'
import UploadService from '../services/uploads.services'
import { imageTypes } from '../components/gallery/Gallery'
import { executeAction } from './global.actions'
import {
  selectAllProducts,
  selectMeasurementUnits,
  selectPricesList,
  selectProductsSimple,
} from '../selectors/products.selector'
import { getState } from 'src/utils/utilities'

export const actionTypes = {
  GET_ALL_PRODUCTS: 'GET_ALL_PRODUCTS',
  GET_ALL_PRODUCTS_REQUEST: 'GET_ALL_PRODUCTS_REQUEST',
  GET_ALL_PRODUCTS_SUCCESS: 'GET_ALL_PRODUCTS_SUCCESS',
  GET_ALL_PRODUCTS_ERROR: 'GET_ALL_PRODUCTS_ERROR',

  GET_MODEL_PRODUCTS: 'GET_MODEL_PRODUCTS',
  GET_MODEL_PRODUCTS_REQUEST: 'GET_MODEL_PRODUCTS_REQUEST',
  GET_MODEL_PRODUCTS_SUCCESS: 'GET_MODEL_PRODUCTS_SUCCESS',
  GET_MODEL_PRODUCTS_ERROR: 'GET_MODEL_PRODUCTS_ERROR',

  GET_ALL_PRODUCT_CATEGORIES: 'GET_ALL_PRODUCT_CATEGORIES',
  GET_ALL_PRODUCT_CATEGORIES_REQUEST: 'GET_ALL_PRODUCT_CATEGORIES_REQUEST',
  GET_ALL_PRODUCT_CATEGORIES_SUCCESS: 'GET_ALL_PRODUCT_CATEGORIES_SUCCESS',
  GET_ALL_PRODUCT_CATEGORIES_ERROR: 'GET_ALL_PRODUCT_CATEGORIES_ERROR',

  GET_ALL_PRODUCT_SUBCATEGORIES: 'GET_ALL_PRODUCT_SUBCATEGORIES',
  GET_ALL_PRODUCT_SUBCATEGORIES_REQUEST: 'GET_ALL_PRODUCT_SUBCATEGORIES_REQUEST',
  GET_ALL_PRODUCT_SUBCATEGORIES_SUCCESS: 'GET_ALL_PRODUCT_SUBCATEGORIES_SUCCESS',
  GET_ALL_PRODUCT_SUBCATEGORIES_ERROR: 'GET_ALL_PRODUCT_SUBCATEGORIES_ERROR',

  GET_SINGLE_PRODUCT: 'GET_SINGLE_PRODUCT',
  GET_MAIN_PRODUCT_EXISTENCE: 'GET_MAIN_PRODUCT_EXISTENCE',

  CREATE_PRODUCT: 'CREATE_PRODUCT',
  CREATE_PRODUCT_REQUEST: 'CREATE_PRODUCT_REQUEST',
  CREATE_PRODUCT_SUCCESS: 'CREATE_PRODUCT_SUCCESS',
  CREATE_PRODUCT_ERROR: 'CREATE_PRODUCT_ERROR',

  CREATE_PRODUCT_CATEGORY: 'CREATE_PRODUCT_CATEGORY',
  CREATE_PRODUCT_CATEGORY_REQUEST: 'CREATE_PRODUCT_CATEGORY_REQUEST',
  CREATE_PRODUCT_CATEGORY_SUCCESS: 'CREATE_PRODUCT_CATEGORY_SUCCESS',
  CREATE_PRODUCT_CATEGORY_ERROR: 'CREATE_PRODUCT_CATEGORY_ERROR',

  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  UPDATE_PRODUCT_REQUEST: 'UPDATE_PRODUCT_REQUEST',
  UPDATE_PRODUCT_SUCCESS: 'UPDATE_PRODUCT_SUCCESS',
  UPDATE_PRODUCT_ERROR: 'UPDATE_PRODUCT_ERROR',

  UPDATE_MULTIPLE_PRODUCTS_REQUEST: 'UPDATE_MULTIPLE_PRODUCTS_REQUEST',
  UPDATE_MULTIPLE_PRODUCTS_SUCCESS: 'UPDATE_MULTIPLE_PRODUCTS_SUCCESS',
  UPDATE_MULTIPLE_PRODUCTS_ERROR: 'UPDATE_MULTIPLE_PRODUCTS_ERROR',

  DELETE_PRODUCT_CATEGORY: 'DELETE_PRODUCT_CATEGORY',
  DELETE_PRODUCT_CATEGORY_REQUEST: 'DELETE_PRODUCT_CATEGORY_REQUEST',
  DELETE_PRODUCT_CATEGORY_SUCCESS: 'DELETE_PRODUCT_CATEGORY_SUCCESS',
  DELETE_PRODUCT_CATEGORY_ERROR: 'DELETE_PRODUCT_CATEGORY_ERROR',

  DELETE_PRODUCT: 'DELETE_PRODUCT',
  DELETE_PRODUCT_REQUEST: 'DELETE_PRODUCT_REQUEST',
  DELETE_PRODUCT_SUCCESS: 'DELETE_PRODUCT_SUCCESS',
  DELETE_PRODUCT_ERROR: 'DELETE_PRODUCT_ERROR',

  ADD_PRODUCT_IMAGES: 'ADD_PRODUCT_IMAGES',
  ADD_PRODUCT_IMAGES_REQUEST: 'ADD_PRODUCT_IMAGES_REQUEST',
  ADD_PRODUCT_IMAGES_SUCCESS: 'ADD_PRODUCT_IMAGES_SUCCESS',
  ADD_PRODUCT_IMAGES_ERROR: 'ADD_PRODUCT_IMAGES_ERROR',

  DELETE_PRODUCT_IMAGES: 'DELETE_PRODUCT_IMAGES',
  DELETE_PRODUCT_IMAGES_REQUEST: 'DELETE_PRODUCT_IMAGES_REQUEST',
  DELETE_PRODUCT_IMAGES_SUCCESS: 'DELETE_PRODUCT_IMAGES_SUCCESS',
  DELETE_PRODUCT_IMAGES_ERROR: 'DELETE_PRODUCT_IMAGES_ERROR',

  GET_PRODUCT_IMAGES: 'GET_PRODUCT_IMAGES',
  GET_PRODUCT_IMAGES_REQUEST: 'GET_PRODUCT_IMAGES_REQUEST',
  GET_PRODUCT_IMAGES_SUCCESS: 'GET_PRODUCT_IMAGES_SUCCESS',
  GET_PRODUCT_IMAGES_ERROR: 'GET_PRODUCT_IMAGES_ERROR',

  ACTIVE_PRODUCT: 'ACTIVE_PRODUCT',
  ACTIVE_PRODUCT_REQUEST: 'ACTIVE_PRODUCT_REQUEST',
  ACTIVE_PRODUCT_SUCCESS: 'ACTIVE_PRODUCT_SUCCESS',
  ACTIVE_PRODUCT_ERROR: 'ACTIVE_PRODUCT_ERROR',

  EXISTENCE_ACROSS_WAREHOUSES: 'EXISTENCE_ACROSS_WAREHOUSES',
  EXISTENCE_ACROSS_WAREHOUSES_REQUEST: 'EXISTENCE_ACROSS_WAREHOUSES_REQUEST',
  EXISTENCE_ACROSS_WAREHOUSES_SUCCESS: 'EXISTENCE_ACROSS_WAREHOUSES_SUCCESS',
  EXISTENCE_ACROSS_WAREHOUSES_ERROR: 'EXISTENCE_ACROSS_WAREHOUSES_ERROR',

  EXISTENCE_EXTERNAL_WAREHOUSES: 'EXISTENCE_EXTERNAL_WAREHOUSES',
  EXISTENCE_EXTERNAL_WAREHOUSES_REQUEST: 'EXISTENCE_EXTERNAL_WAREHOUSES_REQUEST',
  EXISTENCE_EXTERNAL_WAREHOUSES_SUCCESS: 'EXISTENCE_EXTERNAL_WAREHOUSES_SUCCESS',
  EXISTENCE_EXTERNAL_WAREHOUSES_ERROR: 'EXISTENCE_EXTERNAL_WAREHOUSES_ERROR',

  GET_EXISTENCE_WITH_SUBITEMS_ACROSS_WAREHOUSES:
    'GET_EXISTENCE_WITH_SUBITEMS_ACROSS_WAREHOUSES',

  PRODUCT_REPORT: 'PRODUCT_REPORT',
  PRODUCT_LIST_REPORT: 'PRODUCT_LIST_REPORT',

  GET_ALL_PRODUCTS_BY_CLIENT: 'GET_ALL_PRODUCTS_BY_CLIENT',

  GET_ALL_PRODUCTS_WITH_ACTIVE_CONTRACT: 'GET_ALL_PRODUCTS_WITH_ACTIVE_CONTRACT',

  GET_ALL_PRODUCTS_IN_WAREHOUSES: 'GET_ALL_PRODUCTS_IN_WAREHOUSES',

  UPLOAD_CSV: 'UPLOAD_CSV',
  UPLOAD_UPDATE_CSV: 'UPLOAD_UPDATE_CSV',
  UPLOAD_CSV_REQUEST: 'UPLOAD_CSV_REQUEST',
  UPLOAD_CSV_SUCCESS: 'UPLOAD_CSV_SUCCESS',
  UPLOAD_CSV_ERROR: 'UPLOAD_CSV_ERROR',

  UPLOAD_TRANSPORTATION_CSV: 'UPLOAD_TRANSPORTATION_CSV',
  UPLOAD_TRANSPORTATION_CSV_REQUEST: 'UPLOAD_TRANSPORTATION_CSV_REQUEST',
  UPLOAD_TRANSPORTATION_CSV_SUCCESS: 'UPLOAD_TRANSPORTATION_CSV_SUCCESS',
  UPLOAD_TRANSPORTATION_CSV_ERROR: 'UPLOAD_TRANSPORTATION_CSV_ERROR',

  TRANSPORT_PAYMENT: 'TRANSPORT_PAYMENT',
  TRANSPORT_PAYMENT_CREATE: 'TRANSPORT_PAYMENT_CREATE',
  TRANSPORT_PAYMENT_ALL: 'TRANSPORT_PAYMENT_ALL',
  TRANSPORT_PAYMENT_SINGLE: 'TRANSPORT_PAYMENT_SINGLE',
  TRANSPORT_PAYMENT_PRODUCTS: 'TRANSPORT_PAYMENT_PRODUCTS',

  GET_ALL_PRODUCTS_COMPANIES: 'GET_ALL_PRODUCTS_COMPANIES',
  GET_ALL_PRODUCTS_COMPANIES_CHANGE: 'GET_ALL_PRODUCTS_COMPANIES_CHANGE',

  GET_ALL_PRODUCTS_COMPANY: 'GET_ALL_PRODUCTS_COMPANY',
  GET_PRODUCTS_BY_COMPANY_NS: 'GET_PRODUCTS_BY_COMPANY_NS',

  UPDATE_PARAMS: 'UPDATE_PARAMS',

  GET_COMPANIES_BY_MODEL: 'GET_COMPANIES_BY_MODEL',

  CHANGE_STATE_ON_PROFILE: 'CHANGE_STATE_ON_PROFILE',
  DELETE: 'DELETE',
  GET_PRODUCT_LIST: 'GET_PRODUCT_LIST',
  GET_PRODUCT_DASHBOARD: 'GET_PRODUCT_DASHBOARD',
  GET_LOG: 'GET_LOG',
  EXPORT_CSV: 'EXPORT_CSV',
  GET_DATASHEET: 'GET_DATASHEET',
  UPDATE_DATASHEET: 'UPDATE_DATASHEET',
  CREATE_DATASHEET: 'CREATE_DATASHEET',
  GET_PDF_DATASHEET: 'GET_PDF_DATASHEET',
  SEE_PRODUCT_LINE: 'SEE_PRODUCT_LINE',
  CLEAR_PRODUCT_LINE: 'CLEAR_PRODUCT_LINE',
  UPDATE_PRODUCT_LINE: 'UPDATE_PRODUCT_LINE',
  GET_ALL_PRODUCTS_SIMPLE: 'GET_ALL_PRODUCTS_SIMPLE',
  ASSIGN_CATEGORY: 'ASSIGN_CATEGORY',
  PRODUCT_WASTE: 'PRODUCT_WASTE',
  CREATE_TRANSFER: 'CREATE_TRANSFER',
  HYSTORIC_EXCEL: 'HYSTORIC_EXCEL',
  GET_EXCEL: 'GET_EXCEL',
  CHART_PRODUCTS: 'CHART_PRODUCTS',
  CHART_CATEGORIES: 'CHART_CATEGORIES',
  GET_PRICES_NAMES: 'GET_PRICES_NAMES',
  UPDATE_PRICES_NAMES: 'UPDATE_PRICES_NAMES',
  GET_PRODUCTS_SELECTOR: 'GET_PRODUCTS_SELECTOR',

  GET_VARIATIONS: 'GET_VARIATIONS',
  DELETE_VARIATION: 'DELETE_VARIATION',
  GET_EXCEL_EXISTENCES: 'GET_EXCEL_EXISTENCES',

  GET_MEASUREMENT_UNITS: 'GET_MEASUREMENT_UNITS',
  GET_ITEM_DETAIL: 'GET_ITEM_DETAIL',
  ON_CREATE_OR_UPDATE_ITEM: 'ON_CREATE_OR_UPDATE_ITEM',
}

const getAllProductsRequest = () => ({
  type: actionTypes.GET_ALL_PRODUCTS_REQUEST,
})
const getAllProductsSuccess = products => ({
  type: actionTypes.GET_ALL_PRODUCTS_SUCCESS,
  products,
})
const getAllProductsError = error => ({
  type: actionTypes.GET_ALL_PRODUCTS_ERROR,
  error,
})

const getModelProductsRequest = () => ({
  type: actionTypes.GET_MODEL_PRODUCTS_REQUEST,
})
const getModelProductsSuccess = products => ({
  type: actionTypes.GET_MODEL_PRODUCTS_SUCCESS,
  products,
})
const getModelProductsError = error => ({
  type: actionTypes.GET_MODEL_PRODUCTS_ERROR,
  error,
})

const getAllProductCategoriesRequest = () => ({
  type: actionTypes.GET_ALL_PRODUCT_CATEGORIES,
})
const getAllProductCategoriesSuccess = categories => ({
  type: actionTypes.GET_ALL_PRODUCT_CATEGORIES_SUCCESS,
  categories,
})
const getAllProductCategoriesError = error => ({
  type: actionTypes.GET_ALL_PRODUCT_CATEGORIES_ERROR,
  error,
})

const getAllProductSubcategoriesRequest = () => ({
  type: actionTypes.GET_ALL_PRODUCT_SUBCATEGORIES,
})
const getAllProductSubcategoriesSuccess = categories => ({
  type: actionTypes.GET_ALL_PRODUCT_SUBCATEGORIES_SUCCESS,
  categories,
})
const getAllProductSubcategoriesError = error => ({
  type: actionTypes.GET_ALL_PRODUCT_SUBCATEGORIES_ERROR,
  error,
})

const createProductCategoryRequest = () => ({
  type: actionTypes.CREATE_PRODUCT_CATEGORY_REQUEST,
})
const createProductCategorySuccess = product => ({
  type: actionTypes.CREATE_PRODUCT_CATEGORY_SUCCESS,
  product,
})
const createProductCategoryError = error => ({
  type: actionTypes.CREATE_PRODUCT_CATEGORY_ERROR,
  error,
})

const deleteProductCategoryRequest = () => ({
  type: actionTypes.DELETE_PRODUCT_CATEGORY_REQUEST,
})
const deleteProductCategorySuccess = () => ({
  type: actionTypes.DELETE_PRODUCT_CATEGORY_SUCCESS,
})
const deleteProductCategoryError = error => ({
  type: actionTypes.DELETE_PRODUCT_CATEGORY_ERROR,
  error,
})

const deleteProductRequest = () => ({
  type: actionTypes.DELETE_PRODUCT_REQUEST,
})
const deleteProductSuccess = () => ({
  type: actionTypes.DELETE_PRODUCT_SUCCESS,
})
const deleteProductError = error => ({
  type: actionTypes.DELETE_PRODUCT_ERROR,
  error,
})

const addProductImagesRequest = () => ({
  type: actionTypes.ADD_PRODUCT_IMAGES_REQUEST,
})
const addProductImagesSuccess = () => ({
  type: actionTypes.ADD_PRODUCT_IMAGES_SUCCESS,
})
const addProductImagesError = error => ({
  type: actionTypes.ADD_PRODUCT_IMAGES_ERROR,
  error,
})

const deleteProductImagesRequest = () => ({
  type: actionTypes.DELETE_PRODUCT_IMAGES_REQUEST,
})
const deleteProductImagesSuccess = () => ({
  type: actionTypes.DELETE_PRODUCT_IMAGES_SUCCESS,
})
const deleteProductImagesError = error => ({
  type: actionTypes.DELETE_PRODUCT_IMAGES_ERROR,
  error,
})

const getProductImagesRequest = () => ({
  type: actionTypes.GET_PRODUCT_IMAGES_REQUEST,
})
const getProductImagesSuccess = images => ({
  type: actionTypes.GET_PRODUCT_IMAGES_SUCCESS,
  images,
})
const getProductImagesError = error => ({
  type: actionTypes.GET_PRODUCT_IMAGES_ERROR,
  error,
})

const activeProductRequest = () => ({
  type: actionTypes.ACTIVE_PRODUCT_REQUEST,
})
const activeProductSuccess = result => ({
  type: actionTypes.ACTIVE_PRODUCT_SUCCESS,
  result,
})
const activeProductError = error => ({
  type: actionTypes.ACTIVE_PRODUCT_ERROR,
  error,
})

const existenceAcrossWarehousesProductRequest = () => ({
  type: actionTypes.EXISTENCE_ACROSS_WAREHOUSES_REQUEST,
})
const existenceAcrossWarehousesProductSuccess = existence => ({
  type: actionTypes.EXISTENCE_ACROSS_WAREHOUSES_SUCCESS,
  existence,
})
const existenceAcrossWarehousesProductError = error => ({
  type: actionTypes.EXISTENCE_ACROSS_WAREHOUSES_ERROR,
  error,
})

const existenceExternaWarehousesProductRequest = () => ({
  type: actionTypes.EXISTENCE_EXTERNAL_WAREHOUSES_REQUEST,
})
const existenceExternaWarehousesProductSuccess = response => ({
  type: actionTypes.EXISTENCE_EXTERNAL_WAREHOUSES_SUCCESS,
  response,
})
const existenceExternaWarehousesProductError = error => ({
  type: actionTypes.EXISTENCE_EXTERNAL_WAREHOUSES_ERROR,
  error,
})

const uploadCSVRequest = () => ({ type: actionTypes.UPLOAD_CSV_REQUEST })
const uploadCSVSuccess = response => ({
  type: actionTypes.UPLOAD_CSV_SUCCESS,
  response,
})
const uploadCSVError = error => ({
  type: actionTypes.UPLOAD_CSV_ERROR,
  error,
})

const uploadTransportationCSVRequest = () => ({
  type: actionTypes.UPLOAD_TRANSPORTATION_CSV_REQUEST,
})
const uploadTransportationCSVSuccess = response => ({
  type: actionTypes.UPLOAD_TRANSPORTATION_CSV_SUCCESS,
  response,
})
const uploadTransportationCSVError = error => ({
  type: actionTypes.UPLOAD_TRANSPORTATION_CSV_ERROR,
  error,
})

const onTransportPayment = response => ({
  type: actionTypes.TRANSPORT_PAYMENT,
  response,
})

const onChangeCompaniesProducts = items => ({
  type: actionTypes.GET_ALL_PRODUCTS_COMPANIES_CHANGE,
  items,
})

export const onSetTransportPayment = transportPayment => dispatch =>
  dispatch(onTransportPayment(transportPayment))

export const getProducts =
  (items, skip, size, search, active, available, contract, typeId, composed) =>
  async dispatch => {
    if (items) return dispatch(getAllProductsSuccess(items))
    dispatch(getAllProductsRequest())

    const products = getState(selectAllProducts)
    if (products.length > 0) return

    try {
      const products = await ProductsService.getProducts(
        skip,
        size,
        search,
        active,
        available,
        contract,
        typeId,
        composed,
      )
      dispatch(getAllProductsSuccess(products))
    } catch (error) {
      dispatch(getAllProductsError(error))
    }
  }

export const getProductList =
  (
    items,
    skip,
    size,
    search,
    active,
    available,
    contract,
    typeId,
    composed,
    categorization,
    sort,
    start,
    end,
    activeId,
    providerId,
    getTotal,
  ) =>
  async dispatch => {
    if (items) return dispatch(getAllProductsSuccess(items))

    activeId = activeId !== 0 ? activeId : null
    search = search !== '' ? search : null
    const process = async () => ({
      response: await ProductsService.getProducts(
        skip,
        size,
        search,
        active,
        available,
        contract,
        typeId,
        composed,
        categorization,
        sort,
        start,
        end,
        null,
        activeId,
        providerId,
        getTotal,
      ),
    })
    dispatch(executeAction(actionTypes.GET_PRODUCT_LIST, process))
  }

export const getProductDashboard =
  (
    skip,
    size,
    search,
    active,
    available,
    contract,
    typeId,
    composed,
    categorization,
    sort,
    start,
    end,
    pos,
  ) =>
  async dispatch => {
    const process = async () => ({
      response: await ProductsService.getProducts(
        skip,
        size,
        search,
        active,
        available,
        contract,
        typeId,
        composed,
        categorization,
        sort,
        start,
        end,
        pos,
      ),
    })
    dispatch(executeAction(actionTypes.GET_PRODUCT_DASHBOARD, process))
  }

export const getModelProducts = () => async dispatch => {
  dispatch(getModelProductsRequest())
  try {
    const products = await ProductsService.getModelProducts()
    dispatch(getModelProductsSuccess(products))
  } catch (e) {
    dispatch(getModelProductsError(e))
  }
}

export const getGlobalProducts = () => async dispatch => {
  dispatch(getAllProductsRequest())
  try {
    const products = await ProductsService.getGlobalProducts()
    dispatch(getAllProductsSuccess(products))
  } catch (e) {
    dispatch(getAllProductsError(e))
  }
}

export const getProductCategories = () => async dispatch => {
  dispatch(getAllProductSubcategoriesRequest())
  try {
    const products = await ProductsService.getProductCategories()
    dispatch(getAllProductCategoriesSuccess(products))
  } catch (error) {
    console.log('action', error)
    dispatch(getAllProductCategoriesError(error))
  }
}

export const getProductSubCategories = category => async dispatch => {
  dispatch(getAllProductCategoriesRequest())
  try {
    const categories = await ProductsService.getProductSubCategories(category)
    dispatch(getAllProductSubcategoriesSuccess(categories))
  } catch (error) {
    console.log('action', error)
    dispatch(getAllProductSubcategoriesError(error))
  }
}

export const getSingleProduct = id => async dispatch => {
  const process = async () => ({
    product: await ProductsService.getSingleProduct(id),
  })
  dispatch(executeAction(actionTypes.GET_SINGLE_PRODUCT, process))
}

export const createProductCategory =
  (name, code, barcode, description, canBeSold, canBeBought, picture, active) =>
  async dispatch => {
    dispatch(createProductCategoryRequest())
    try {
      const product = await ProductsService.createProductCategory({
        name,
        code,
        barcode,
        description,
        canBeSold,
        canBeBought,
        picture,
        active,
      })
      dispatch(createProductCategorySuccess(product))
    } catch (error) {
      console.log('action', error)
      dispatch(createProductCategoryError(error))
    }
  }

export const updateMultipleProducts = products => async dispatch => {
  const process = async () => await ProductsService.updateMultipleProducts(products)
  dispatch(executeAction(actionTypes.UPDATE_MULTIPLE_PRODUCTS_REQUEST, process))
}

export const deleteProductCategory = id => async dispatch => {
  dispatch(deleteProductCategoryRequest())
  try {
    const product = await ProductsService.deleteProductCategory(id)
    dispatch(deleteProductCategorySuccess(product))
  } catch (error) {
    console.log('action', error)
    dispatch(deleteProductCategoryError(error))
  }
}

export const deleteProduct = productId => async dispatch => {
  dispatch(deleteProductRequest())
  try {
    const product = await ProductsService.deleteProduct(productId)
    dispatch(deleteProductSuccess(product))
  } catch (error) {
    console.log('action', error)
    dispatch(deleteProductError(error))
  }
}

export const addProductImage = (product, url, name, description) => async dispatch => {
  dispatch(addProductImagesRequest())
  try {
    await ProductsService.addImage(product, url, name, description)
    dispatch(getProductImages(product))
    dispatch(addProductImagesSuccess())
  } catch (error) {
    console.log(error)
    dispatch(addProductImagesError(error.response.data))
  }
}

export const deleteProductImage = (productID, imageID) => async dispatch => {
  dispatch(deleteProductImagesRequest())
  try {
    await ProductsService.deleteImage(productID, imageID)
    dispatch(getProductImages(productID))
    dispatch(deleteProductImagesSuccess())
  } catch (error) {
    console.log(error)
    dispatch(deleteProductImagesError(error.response.data))
  }
}

export const getProductImages = product => async dispatch => {
  dispatch(getProductImagesRequest())
  try {
    const images = await ProductsService.getImages(product)
    dispatch(getProductImagesSuccess(images))
  } catch (error) {
    console.log(error)
    dispatch(getProductImagesError(error.response.data))
  }
}

export const toggleProductActive = id => async dispatch => {
  dispatch(activeProductRequest())
  try {
    const result = await ProductsService.toggleProductActive(id)
    dispatch(getProducts())
    dispatch(activeProductSuccess(result))
  } catch (error) {
    console.log(error)
    dispatch(activeProductError(error.response.data))
  }
}

export const getExistenceWithSubitemsAcrossWarehouses = productId => async dispatch => {
  const process = async () => ({
    existence: await ProductsService.existenceWithSubitemsAcrossWarehouses(productId),
  })
  dispatch(
    executeAction(actionTypes.GET_EXISTENCE_WITH_SUBITEMS_ACROSS_WAREHOUSES, process),
  )
}

export const getExistenceAcrossWarehouses = productId => async dispatch => {
  dispatch(existenceAcrossWarehousesProductRequest())
  try {
    const existence = await ProductsService.existenceAcrossWarehouses(productId, false)
    dispatch(existenceAcrossWarehousesProductSuccess(existence))
  } catch (error) {
    console.log(error)
    dispatch(existenceAcrossWarehousesProductError(error))
  }
}

export const getExistenceExternalWarehouses = productId => async dispatch => {
  dispatch(existenceExternaWarehousesProductRequest())
  try {
    const response = await ProductsService.existenceAcrossWarehouses(productId, true)
    dispatch(existenceExternaWarehousesProductSuccess(response))
  } catch (e) {
    dispatch(existenceExternaWarehousesProductError(e))
  }
}

export const updateProduct = (id, request) => dispatch => {
  const process = async () => ({
    product: await ProductsService.updateProduct(id, request),
  })
  dispatch(executeAction(actionTypes.UPDATE_PRODUCT, process))
}

export const createProduct = (request, images) => dispatch => {
  const process = async () => {
    const product = await ProductsService.createProduct(request)
    if (images.length > 0)
      await UploadService.addImages(imageTypes.PRODUCT.id, product.id, images)
    return { product }
  }
  dispatch(executeAction(actionTypes.CREATE_PRODUCT, process))
}

export const getReport = (productId, startDate, enDate, filter) => dispatch => {
  const process = async () => ({
    history: await ProductsService.getReport(productId, startDate, enDate, filter),
  })
  dispatch(executeAction(actionTypes.PRODUCT_REPORT, process))
}

export const getChart = (list, startDate, enDate, filter) => dispatch => {
  const process = async () => ({
    history: await ProductsService.getChart(list, startDate, enDate, filter),
  })
  dispatch(executeAction(actionTypes.PRODUCT_LIST_REPORT, process))
}

export const getAllProductsByClient = clientId => dispatch => {
  const process = async () => ({
    productsByClient: await ProductsService.getProductsByClient(clientId),
  })
  dispatch(executeAction(actionTypes.GET_ALL_PRODUCTS_BY_CLIENT, process))
}

export const getProductsWithActiveContract = () => dispatch => {
  const process = async () => ({
    products: await ProductsService.getProductsWithActiveContract(),
  })
  dispatch(executeAction(actionTypes.GET_ALL_PRODUCTS_WITH_ACTIVE_CONTRACT, process))
}

export const getOwnOnlineProducts = companyId => dispatch => {
  const process = async () => ({
    productsByClient: await ProductsService.getOwnOnlineProducts(companyId),
  })
  dispatch(executeAction(actionTypes.GET_ALL_PRODUCTS_BY_CLIENT, process))
}

export const getProductsInWarehouses = () => dispatch => {
  const process = async () => ({
    productsWarehouses: await ProductsService.getProductsInWarehouses(),
  })
  dispatch(executeAction(actionTypes.GET_ALL_PRODUCTS_IN_WAREHOUSES, process))
}

export const loadProductsCSV = (file, onSuccess, model) => async dispatch => {
  dispatch(uploadCSVRequest())
  try {
    const response = await ProductsService.loadProductsCSV(file, model)
    dispatch(uploadCSVSuccess(response))
    onSuccess(response, false)
  } catch (e) {
    console.log(e)
    onSuccess(e, true)
    dispatch(uploadCSVError(e))
  }
}
export const massiveLoadProducts = (data, currentIndex, onSuccess) => async dispatch => {
  const response = await ProductsService.loadProducts(data, currentIndex, false)
  const process = async () => ({
    response,
  })
  dispatch(executeAction(actionTypes.UPLOAD_CSV, process))
  onSuccess(response, false)
}

export const massiveUpdateProducts =
  (data, currentIndex, onSuccess) => async dispatch => {
    const response = await ProductsService.updateLoadProducts(data, currentIndex, false)
    const process = async () => ({
      response,
    })
    dispatch(executeAction(actionTypes.UPLOAD_UPDATE_CSV, process))
    onSuccess(response, false)
  }

export const updateProductsCSV = (file, onSuccess, model) => async dispatch => {
  dispatch(uploadCSVRequest())
  try {
    const response = await ProductsService.updateProductsCSV(file, model)
    dispatch(uploadCSVSuccess(response))
    onSuccess(response, false)
  } catch (e) {
    console.log(e)
    onSuccess(e, true)
    dispatch(uploadCSVError(e))
  }
}

export const loadProductsTransportationPaymentCSV =
  (file, onSuccess, polygonId) => async dispatch => {
    dispatch(uploadTransportationCSVRequest())
    try {
      const response = await ProductsService.loadProductsTransportationPaymentCSV(
        file,
        polygonId,
      )
      dispatch(uploadTransportationCSVSuccess(response))
      onSuccess(response, false)
    } catch (e) {
      console.log(e)
      onSuccess(e, true)
      dispatch(uploadTransportationCSVError(e))
    }
  }

export const onCreateTransportationPayment = request => dispatch => {
  const process = async () => ({
    transportationPaymentC: await ProductsService.onCreateTransportationPayment(request),
  })
  dispatch(executeAction(actionTypes.TRANSPORT_PAYMENT_CREATE, process))
}

export const getTransportationPayments = (productId, polygonId) => dispatch => {
  const process = async () => ({
    transportationPayments: await ProductsService.getTransportationPayments(
      productId,
      polygonId,
    ),
  })
  dispatch(executeAction(actionTypes.TRANSPORT_PAYMENT_ALL, process))
}

export const getTransportationPayment = transportationId => dispatch => {
  const process = async () => ({
    transportationPayment: await ProductsService.getTransportationPayment(
      transportationId,
    ),
  })
  dispatch(executeAction(actionTypes.TRANSPORT_PAYMENT_SINGLE, process))
}

export const getProductsWithTransportationPayment = () => dispatch => {
  const process = async () => ({
    transportationProducts: await ProductsService.getProductsWithTransportationPayment(),
  })
  dispatch(executeAction(actionTypes.TRANSPORT_PAYMENT_PRODUCTS, process))
}

export const getAllProducts = type => dispatch => {
  const process = async () => ({
    productsCompanies: await ProductsService.getAllProducts(type),
  })
  dispatch(executeAction(actionTypes.GET_ALL_PRODUCTS_COMPANIES, process))
}

export const changeCompaniesProducts = items => dispatch =>
  dispatch(onChangeCompaniesProducts(items))

export const getProductsCompany = companyId => async dispatch => {
  const process = async () => ({
    productsCompany: await ProductsService.getProductsCompany(companyId),
  })
  dispatch(executeAction(actionTypes.GET_ALL_PRODUCTS_COMPANY, process))
}

export const getProductsByCompanyNS = companyId => dispatch => {
  const process = async () => ({
    productsByCompanyNS: await ProductsService.getProductsByCompanyNS(companyId),
  })
  dispatch(executeAction(actionTypes.GET_PRODUCTS_BY_COMPANY_NS, process))
}

export const onUpdateParams = request => dispatch => {
  const process = async () => ({
    updated: await ProductsService.onUpdateParams(request),
  })
  dispatch(executeAction(actionTypes.UPDATE_PARAMS, process))
}

export const getCompaniesByModelProduct = id => dispatch => {
  const process = async () => ({
    response: await ProductsService.getCompaniesByModelProduct(id),
  })
  dispatch(executeAction(actionTypes.GET_COMPANIES_BY_MODEL, process))
}

export const changeStateOnKoloProfile = id => dispatch => {
  const process = async () => await ProductsService.changeStateOnKoloProfile(id)
  dispatch(executeAction(actionTypes.CHANGE_STATE_ON_PROFILE, process))
}

export const deleteProducts = (productIds, archive, all) => dispatch => {
  const process = async () => ({
    response: await ProductsService.deleteProducts(productIds, archive, all),
  })
  dispatch(executeAction(actionTypes.DELETE, process))
}

export const getLog = (productId, start, end) => dispatch => {
  const process = async () => ({
    response: await ProductsService.getLog(productId, { start, end }),
  })
  dispatch(executeAction(actionTypes.GET_LOG, process))
}

export const exportCSV = (params, list) => dispatch => {
  const process = async () => ({
    response: await ProductsService.exportCSV(list, params),
  })
  dispatch(executeAction(actionTypes.EXPORT_CSV, process))
}

export const getDatasheet = id => dispatch => {
  const process = async () => ({
    response: await ProductsService.getDataSheet(id),
  })
  dispatch(executeAction(actionTypes.GET_DATASHEET, process))
}

export const updateDatasheet = (id, request) => dispatch => {
  const process = async () => ({
    response: await ProductsService.updateDataSheet(id, request),
  })
  dispatch(executeAction(actionTypes.UPDATE_DATASHEET, process))
}

export const createDatasheet = (id, request) => dispatch => {
  const process = async () => ({
    response: await ProductsService.createDataSheet(id, request),
  })
  dispatch(executeAction(actionTypes.CREATE_DATASHEET, process))
}

export const getPDFDatasheet = id => dispatch => {
  const process = async () => await ProductsService.getPDFDatasheet(id)
  dispatch(executeAction(actionTypes.GET_PDF_DATASHEET, process))
}

export const seeProductLine = id => dispatch => {
  const process = async () => ({
    productLine: await ProductsService.seeProductLine(id),
  })
  dispatch(executeAction(actionTypes.SEE_PRODUCT_LINE, process))
}

export const clearProductLine = () => dispatch => {
  dispatch(executeAction(actionTypes.CLEAR_PRODUCT_LINE))
}

export const updateProductLine = (id, params) => dispatch => {
  const process = async () => ({
    response: await ProductsService.updateProductLine(id, params),
  })
  dispatch(executeAction(actionTypes.UPDATE_PRODUCT_LINE, process))
}

export const createNodeProductLine = (id, params) => dispatch => {
  const process = async () => ({
    response: await ProductsService.createNodeProductLine(id, params),
  })
  dispatch(executeAction(actionTypes.UPDATE_PRODUCT_LINE, process))
}

export const deleteProductLine = id => dispatch => {
  const process = async () => ({
    response: await ProductsService.deleteProductLine(id),
  })
  dispatch(executeAction(actionTypes.UPDATE_PRODUCT_LINE, process))
}

export const getProductsSimple = () => dispatch => {
  const products = getState(selectProductsSimple)
  if (products.length > 0) return

  const process = async () => ({
    simples: await ProductsService.getProductsSimple(),
  })
  dispatch(executeAction(actionTypes.GET_ALL_PRODUCTS_SIMPLE, process))
}

export const assignCategorizationProduct =
  (categorization, list, remove, all) => dispatch => {
    const process = async () => ({
      simples: await ProductsService.assignEntity(categorization, list, remove, all),
    })
    dispatch(executeAction(actionTypes.ASSIGN_CATEGORY, process))
  }

export const productWasteRequest = list => dispatch => {
  const process = async () => ({
    simples: await ProductsService.productWaste(list),
  })
  dispatch(executeAction(actionTypes.PRODUCT_WASTE, process))
}

export const productCreateTransfer = request => dispatch => {
  const process = async () => ({
    simples: await ProductsService.createTransfer(request),
  })
  dispatch(executeAction(actionTypes.CREATE_TRANSFER, process))
}

export const historicExcel = (params, id, name) => dispatch => {
  const process = async () => ({
    response: await ProductsService.historicExcel(params, id, name),
  })
  dispatch(executeAction(actionTypes.HYSTORIC_EXCEL, process))
}

export const getProductsToChart = request => dispatch => {
  const process = async () => ({
    products: await ProductsService.productToChartReport(request),
  })
  dispatch(executeAction(actionTypes.CHART_PRODUCTS, process))
}

export const getProductsToChartCategory = list => dispatch => {
  const process = async () => ({
    products: await ProductsService.productToChartReportCategory(list),
  })
  dispatch(executeAction(actionTypes.CHART_CATEGORIES, process))
}

export const getProductPricesNames = () => dispatch => {
  const names = getState(selectPricesList)
  if (names.length > 0) return
  const process = async () => ({
    names: await ProductsService.getProductPricesNames(),
  })
  dispatch(executeAction(actionTypes.GET_PRICES_NAMES, process))
}

export const getProductsForSelector = params => dispatch => {
  const process = async () => ({
    selector: await ProductsService.getProductsLite(params),
  })
  dispatch(executeAction(actionTypes.GET_PRODUCTS_SELECTOR, process))
}

export const setProductPricesNames = request => dispatch => {
  const process = async () => ({
    names: await ProductsService.updateProductPricesNames(request),
  })
  dispatch(executeAction(actionTypes.UPDATE_PRICES_NAMES, process))
}

export const getExcelByReport = (list, request) => dispatch => {
  const process = async () => ({
    excel: await ProductsService.getExcelByReport(request, list),
  })
  dispatch(executeAction(actionTypes.GET_EXCEL, process))
}

export const getProductVariations = productId => dispatch => {
  const process = async () => ({
    variations: await ProductsService.getVariationsProduct(productId),
  })
  dispatch(executeAction(actionTypes.GET_VARIATIONS, process))
}

export const deleteProductVariations = listId => dispatch => {
  const process = async () => ({
    response: await ProductsService.deleteProductVariation(listId),
  })
  dispatch(executeAction(actionTypes.DELETE_VARIATION, process))
}

export const getProductMainExistence = listId => dispatch => {
  const process = async () => ({
    response: await ProductsService.getProductMainExistence(listId),
  })
  dispatch(executeAction(actionTypes.GET_MAIN_PRODUCT_EXISTENCE, process))
}

export const getExcelExistences = listId => dispatch => {
  const process = async () => ({
    excel: await ProductsService.getExcelExistences(listId),
  })
  dispatch(executeAction(actionTypes.GET_EXCEL_EXISTENCES, process))
}

/**
 * Get all measurement units
 */
export const getMeasurementUnits = () => dispatch => {
  const measurementUnits = getState(selectMeasurementUnits)
  if (measurementUnits.length > 0) return

  const process = async () => ({
    response: await ProductsService.getMeasurementUnits(),
  })
  dispatch(executeAction(actionTypes.GET_MEASUREMENT_UNITS, process))
}

/** get the full detail of the item
 * @function
 * @param {number} itemId ID of the item to get detail
 * **/
export const getItemDetail = itemId => dispatch => {
  const process = async () => ({
    itemDetail: await ProductsService.getItemDetail(itemId),
  })
  dispatch(executeAction(actionTypes.GET_ITEM_DETAIL, process))
}

/** Method to create an item or update the information of an item.
 * @function
 * @param {number} itemId Id of the item to update, if it is null, the item will be created
 * @param {Object} request Request with info necessary to create or update item
 * @param {Object[]} images Image to add to item
 * **/
export const onCreateOrUpdateItem = (itemId, request, images) => dispatch => {
  const process = async () => {
    const itemResponse = await ProductsService.onCreateOrUpdateItem(itemId, request)
    if (images && images.length > 0)
      await UploadService.addImages(imageTypes.PRODUCT.id, itemResponse.id, images)
    return { itemResponse }
  }
  dispatch(executeAction(actionTypes.ON_CREATE_OR_UPDATE_ITEM, process))
}
