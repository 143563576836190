import './Switch.scss'
import React from 'react'
import Paragraph from 'src/components/Paragraph/Paragraph'

interface IProps {
  checked: boolean
  onChange: (boolean) => void
  label: string
  info: string
  required?: boolean
  // Extras
  disabled?: boolean
  className?: string
  id?: string
}

const SwitchV2 = ({
  checked = false,
  onChange,
  label,
  info,
  required,
  className,
  disabled = false,
  id,
}: IProps) => {
  return (
    <div className={`d-flex my-2 ${className}`}>
      <div style={{ minWidth: '46px' }} className={'mr-3'}>
        <label className={'switch'}>
          <input
            type={'checkbox'}
            disabled={disabled}
            checked={checked}
            onChange={e => {
              onChange(e.target.checked)
            }}
            id={id}
            data-testid={id}
          />
          <span className={'slider'} />
        </label>
      </div>
      <div className={'column'}>
        <Paragraph bold dim={disabled}>
          {required && <span style={{ color: 'red', marginRight: 2 }}>&nbsp;* </span>}{' '}
          {label}
        </Paragraph>
        <Paragraph dim={disabled} className={required ? 'pl-2' : ''}>
          {info}
        </Paragraph>
      </div>
    </div>
  )
}
export default SwitchV2
