import React, {Component} from 'react';
import {connect} from "react-redux"
import {Col, Row} from "react-bootstrap";
import Card from "../../../components/cards/Card";
import {formatDateFromMillis} from "../../../utils/formatters";
import {selectDocumentRequests} from "../../../selectors/resolutions.selector";
import {loadingSelector} from "../../../selectors/loading.selector";
import {actionTypes, getAllDocumentRequests} from "../../../actions/resolutions.actions";
import IconButton from "../../../components/buttons/IconButton";
import {faEye} from '@fortawesome/free-solid-svg-icons'
import CollapsibleCard from "../../../components/cards/collapsible-card/CollapsibleCard";
import { TableV2 } from '../../../components';

class PrintDocuments extends Component {

    state = {
        pending: true,
        done: true,
    };

    handleCheckBoxChange = (event) => {
        const {name, checked} = event.target;
        this.setState({[name]: checked, page: 1});
    };

    filter = item => {
        const {pending, done} = this.state;
        return ( (pending && !item.done) || (done && item.done) )
    };

    componentDidMount() {
        this.props.getAllDocumentRequests();
    }

    
    render() {
        const {documents, documentsIsLoading} = this.props;
        const {pending, done} = this.state;
        return (

            <div>
                <h1 className="dashboard-welcome">Solicitud de Documentos</h1>
                <Row>
                    <Col md={4}>
                        <CollapsibleCard title={'Filtros'} startCollapsed={true}>
                            <h6>Estado</h6>
                            <Row>
                                <Col>
                                    <label className={'checkbox-label'}><input type="checkbox" name="pending" checked={pending} onChange={this.handleCheckBoxChange}/> Pendientes</label>
                                </Col>
                                <Col>
                                    <label className={'checkbox-label'}><input type="checkbox" name="done" checked={done} onChange={this.handleCheckBoxChange}/> Finalizadas</label>
                                </Col>
                            </Row>
                        </CollapsibleCard>
                    </Col>
                    <Col>
                        <Card title={'Solicitudes'}>
                            <TableV2
                                headers={[

                                    { label: 'Fecha', show: true, value: ['createdAt'], type: 'date', className: 'short' },
                                    { label: 'Empresa', show: true, value: ['companyData','name'], type: 'text', className: 'mini' },
                                    { label: 'Estado', show: true, value: ['estadoC'], type: 'text', className: 'short',
                                        custom:(item)=> item.estadoC =  item.cancelled? 'Cancelada': item.done ? 'Finalizada' : 'Pendiente' },
                                    { config: true, show: true, label: '', className: 'mini center',
                                    custom:(item)=> <IconButton tooltip={'Detalles'} icon={faEye} linkRoute={`detalles/${item.id}`}/> }
                                ]}
                                items={documents.filter(this.filter)}
                                loading={documentsIsLoading}
                                mobileAuto
                                storageKey={`printDocuments`}
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    documents: selectDocumentRequests(state),
    documentsIsLoading: loadingSelector([actionTypes.GET_COMPANY_DOCUMENT_REQUESTS])(state),
});

const mapDispatchToProps = dispatch => ({
   getAllDocumentRequests: ()=> dispatch(getAllDocumentRequests())
});

export default connect(mapStateToProps, mapDispatchToProps)(PrintDocuments);