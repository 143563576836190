import { $http } from './http'

class DepartmentsService {
  getAllRegions = async () => {
    const response = await $http.get(`/address`)
    return response.data
  }

  getSingleRegion = async id => {
    const response = await $http.get(`/address/${id}`)
    return response.data
  }

  getLocalitiesByRegion = async id => {
    const response = await $http.get(`/address/${id}/localities`)
    return response.data
  }

  getAllLocalities = async () => {
    const response = await $http.get(`/address/localities`)
    return response.data
  }

  getSingleLocality = async id => {
    const response = await $http.get(`/address/localities/${id}`)
    return response.data
  }
}

export default new DepartmentsService()
