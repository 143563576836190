import './CSVLoader.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Col, Row, Modal } from 'react-bootstrap'
import { FilePicker } from 'react-file-picker'
import SweetAlert from 'sweetalert-react'
import { faDownload, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Td, Tr } from 'react-super-responsive-table'

import Card from '../../components/cards/Card'
import Button from '../../components/buttons/Button'
import IconButton from '../../components/buttons/IconButton'
import Select from '../../components/inputs/Select/CustomSelect'

import { loadClientsCSV } from '../../actions/clients.actions'
import {
  loadProductsCSV,
  loadProductsTransportationPaymentCSV,
} from '../../actions/products.actions'
import { selectUploadResponseClients } from '../../selectors/clients.selector'
import { selectUploadResponseProducts } from '../../selectors/products.selector'

import { selectCsvResponse } from '../../selectors/warehouse.selector'

import { selectSetCurrentCompany } from '../../selectors/company.selector'

import { getAllPolygons } from '../../actions/polygons.actions'
import { selectAllPolygonsBySelect } from '../../selectors/polygons.selector'

import { selectCurrentModule } from '../../selectors/user.selector'
import { TableV2 } from '../../components'

const CATEGORY_UPLOAD = {
  CLIENTS: 'CLIENTS',
  PRODUCTS: 'PRODUCTS',
  TRANSPORTATION: 'TRANSPORTATION',
  NONE: 'NONE',
  WAREHOUSE: 'WAREHOUSE',
}
const URL_TEMPLATE_CLIENTS =
  'https://firebasestorage.googleapis.com/v0/b/distribution-app-dev.appspot.com/o/file_client.csv?alt=media&token=0b7c7f80-8ff1-41d1-8b83-d77cc6503e6d'
const URL_TEMPLATE_PRODUCTS =
  'https://firebasestorage.googleapis.com/v0/b/distribution-app-dev.appspot.com/o/file_products.csv?alt=media&token=a7f4a399-2bc6-4518-bb47-a9bb85960800'
const URL_TEMPLATE_TRANSPORTIST =
  'https://firebasestorage.googleapis.com/v0/b/distribution-app-dev.appspot.com/o/file_transport_products.csv?alt=media&token=04867189-a8b1-49c4-90bf-446c070ba5f4'
const URL_TEMPLATE_WAREHOUSE =
  'https://firebasestorage.googleapis.com/v0/b/distribution-app-prod.appspot.com/o/files%2Fwarehouse.csv?alt=media&token=d7072e60-476a-449f-8ed8-498d274d4fb2'

class CSVLoader extends Component {
  state = {
    showError: false,
    showSuccess: false,
    showMessageList: false,
    global: false,
    errorText: '',
    fullResponse: {},
    categoryUpload: CATEGORY_UPLOAD.NONE,
    isLoading: false,
    showInfo: false,
    info: { title: '', content: '' },
    transportationModal: false,
    polygon: { value: null, label: 'sin seleccionar' },
  }

  componentWillMount() {
    const { module, getAllPolygons } = this.props
    if (module === 2000) getAllPolygons()
  }

  onSuccessLoadFile = (response, isError) => {
    this.setState({ isLoading: false })
    if (!isError) {
      console.log(response)
      this.showInfo(response)
    } else {
      this.setState({
        showSuccess: false,
        showError: true,
        errorText: 'Error al subir el archivo',
        fullResponse: {
          errorColumns: ['Linea', 'Columna', 'Error', 'Descripcion'],
          errors: [],
          items: 0,
          message: 'Error al subir el archivo',
          ok: false,
        },
      })
    }
  }

  showInfo = response => {
    if (response.ok === false) {
      this.setState({
        showSuccess: false,
        showError: true,
        errorText: response.message,
        fullResponse: response,
      })
    } else {
      this.setState({ showSuccess: true, showError: false, fullResponse: response })
    }
  }

  saveClientsCSV = file => {
    const { global } = this.state
    this.setState({ categoryUpload: CATEGORY_UPLOAD.CLIENTS, isLoading: true })
    this.props.uploadClientsCSV(file, global, this.onSuccessLoadFile)
  }

  saveProductsCSV = file => {
    this.setState({ categoryUpload: CATEGORY_UPLOAD.PRODUCTS, isLoading: true })
    this.props.uploadProductsCSV(file, this.onSuccessLoadFile, false)
  }

  saveTransportationProductsCSV = (file, polygonId) => {
    this.setState({
      categoryUpload: CATEGORY_UPLOAD.TRANSPORTATION,
      isLoading: true,
      transportationModal: false,
    })
    this.props.uploadTransportationCSV(file, this.onSuccessLoadFile, polygonId)
  }

  getButton = (type, title, url) => {
    return (
      <Row className={'container-buttons'}>
        <IconButton
          tooltip={'Información'}
          icon={faInfoCircle}
          onClick={() => this.showInfoDownload(type)}
        />
        <a download={`Plantilla ${title}`} href={url} style={{ color: 'white' }}>
          <IconButton tooltip={'Descargar Plantilla'} icon={faDownload} />
        </a>
      </Row>
    )
  }

  showInfoDownload = type => {
    switch (type) {
      case CATEGORY_UPLOAD.WAREHOUSE:
        this.setState({
          info: {
            title: 'Creacion multiple de bodegas',
            content:
              'Los nombres de las columnas deben ser escritos en minúscula y sin tildes.\n' +
              'Las columnas para las cuales no hay información se dejan en blanco',
          },
        })
        break
      case CATEGORY_UPLOAD.TRANSPORTATION:
        this.setState({
          info: {
            title: 'Pago de transporte',
            content:
              'Los nombres de las columnas deben ser escritos en minúscula y sin tildes.\n' +
              'Las columnas para las cuales no hay información se dejan en blanco\n' +
              'Los valores de las columnas de rangos son (unidades maxima/porcentaje)',
          },
        })
        break
      case CATEGORY_UPLOAD.PRODUCTS:
        this.setState({
          info: {
            title: 'Productos',
            content:
              'Los nombres de las columnas deben ser escritos en minúscula y sin tildes.\n' +
              'Las columnas para las cuales no hay información se dejan en blanco\n' +
              "Los valores de la columna 'Transformación automática' pueden ser Si o No",
          },
        })
        break
      case CATEGORY_UPLOAD.CLIENTS:
        this.setState({
          info: {
            title: 'Clientes',
            content:
              'Los nombres de las columnas deben ser escritos en minúscula y sin tildes.\n' +
              'Las columnas para las cuales no hay información se dejan en blanco',
          },
        })
        break
    }
    this.setState({ showInfo: true })
  }

  handleCheckBoxChange = event => {
    const { name, checked } = event.target
    this.setState({ [name]: checked })
  }

  componentDidMount() {}

  render() {
    const { showError, errorText, showSuccess, fullResponse, showMessageList, global } =
      this.state
    const { company, module, polygons } = this.props
    const { categoryUpload, isLoading, transportationModal, polygon } = this.state

    return (
      <div>
        <Row>
          <Col>
            <Card
              title={'Carga CSV Clientes'}
              button={this.getButton(
                CATEGORY_UPLOAD.CLIENTS,
                'Clientes',
                URL_TEMPLATE_CLIENTS,
              )}>
              <FilePicker
                maxSize={5}
                extensions={['csv']}
                onChange={this.saveClientsCSV}
                onError={errMsg => this.setState({ showError: true, errorText: errMsg })}>
                {' '}
                <div style={{ paddingTop: 10 }}>
                  <Button
                    loading={isLoading && categoryUpload == CATEGORY_UPLOAD.CLIENTS}
                    disabled={isLoading}
                    className={'big-button no-bottom-margin'}>
                    Cargar Clientes
                  </Button>
                </div>
              </FilePicker>
              <br />
              {company && company.email === 'cotizap@cotizap.com' ? (
                <label className={'checkbox-label'}>
                  <input
                    type="checkbox"
                    name="global"
                    checked={global}
                    onChange={this.handleCheckBoxChange}
                  />{' '}
                  Subir como globales
                </label>
              ) : (
                ''
              )}
            </Card>
          </Col>
          <Col>
            <Card
              title={'Carga CSV Productos'}
              button={this.getButton(
                CATEGORY_UPLOAD.PRODUCTS,
                'Productos',
                URL_TEMPLATE_PRODUCTS,
              )}>
              <FilePicker
                maxSize={5}
                extensions={['csv']}
                onChange={this.saveProductsCSV}
                onError={errMsg => this.setState({ showError: true, errorText: errMsg })}>
                <div style={{ paddingTop: 10 }}>
                  <Button
                    loading={isLoading && categoryUpload == CATEGORY_UPLOAD.PRODUCTS}
                    disabled={isLoading}
                    className={'big-button no-bottom-margin'}>
                    Cargar Productos
                  </Button>
                </div>
              </FilePicker>
            </Card>
          </Col>
          {module === 2000 && (
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              <Col>
                <Card
                  title={'Carga CSV Productos para trasnsporte'}
                  button={this.getButton(
                    CATEGORY_UPLOAD.TRANSPORTATION,
                    'Pago de transporte',
                    URL_TEMPLATE_TRANSPORTIST,
                  )}>
                  <div style={{ paddingTop: 10 }}>
                    <Button
                      loading={
                        isLoading && categoryUpload == CATEGORY_UPLOAD.TRANSPORTATION
                      }
                      disabled={isLoading}
                      className={'big-button no-bottom-margin'}
                      onClick={() => this.setState({ transportationModal: true })}>
                      Cargar Pago de transporte
                    </Button>
                  </div>
                  <br />
                </Card>
              </Col>
            </Col>
          )}
          <Col>
            <Card
              title={'Carga CSV Bodegas'}
              button={this.getButton(
                CATEGORY_UPLOAD.WAREHOUSE,
                'bODEGAS',
                URL_TEMPLATE_WAREHOUSE,
              )}>
              {' '}
              <FilePicker
                maxSize={5}
                extensions={['csv']}
                onChange={this.loadWarehouse}
                onError={errMsg => this.setState({ showError: true, errorText: errMsg })}>
                <div style={{ paddingTop: 10 }}>
                  <Button className={'big-button no-bottom-margin'}>
                    Cargar bodegas
                  </Button>
                </div>
              </FilePicker>
            </Card>
          </Col>
        </Row>

        <SweetAlert
          show={showError}
          title="Error"
          text={errorText}
          type={'warning'}
          onConfirm={() => {
            if (fullResponse.errors != undefined) {
              if (fullResponse.errors.length > 0) {
                this.setState({ showMessageList: true })
              }
            }
            this.setState({ showError: false })
          }}
        />
        <SweetAlert
          show={showSuccess}
          title="Cargado con exito"
          text={'Archivo cargado con exito'}
          type={'success'}
          onConfirm={() => {
            if (fullResponse.errors != undefined) {
              if (fullResponse.errors.length > 0) {
                this.setState({ showMessageList: true })
              }
            }
            this.setState({ showSuccess: false })
          }}
        />
        <SweetAlert
          show={this.state.showInfo}
          type={'info'}
          title={this.state.info.title}
          text={this.state.info.content}
          onConfirm={() =>
            this.setState({ showInfo: false, info: { title: '', content: '' } })
          }
        />

        <Modal
          show={showMessageList}
          size="lg"
          centered
          onHide={() => this.setState({ showMessageList: false })}>
          {' '}
          <Modal.Header closeButton>
            <Modal.Title>Detalle de carga</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Durante la carga se encontraron los siguientes errores o detalles</p>
            <TableV2
              headers={[
                {
                  label: 'Línea',
                  show: true,
                  value: ['line'],
                  type: 'text',
                  className: 'mini',
                },
                {
                  label: 'Columna',
                  show: true,
                  value: ['column'],
                  type: 'text',
                  className: 'mini',
                },
                {
                  label: 'Error',
                  show: true,
                  value: ['error'],
                  type: 'text',
                  className: 'mini',
                },
                {
                  label: 'Mensaje',
                  show: true,
                  value: ['message'],
                  type: 'text',
                  className: 'medium',
                },
                { config: true, show: true, label: '', className: 'mini center' },
              ]}
              items={fullResponse.errors}
              mobileAuto
              storageKey={`csvLoader`}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={transportationModal}
          size={'md'}
          centered
          onHide={() => this.setState({ transportationModal: false })}>
          {' '}
          <Modal.Header closeButton>
            <Modal.Title>Se debe escoger un poligono de transporte</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Select
              label={'Zona de tranporte'}
              value={polygon}
              options={polygons}
              onChange={d => this.setState({ polygon: d })}
              required
            />

            <FilePicker
              maxSize={5}
              extensions={['csv']}
              onChange={file => this.saveTransportationProductsCSV(file, polygon.value)}
              onError={errMsg => this.setState({ showError: true, errorText: errMsg })}>
              {' '}
              <div style={{ paddingTop: 10 }}>
                <Button
                  loading={isLoading && categoryUpload == CATEGORY_UPLOAD.TRANSPORTATION}
                  disabled={!polygon.value || isLoading}
                  className={'big-button no-bottom-margin'}>
                  Cargar Pago de transporte
                </Button>
              </div>
            </FilePicker>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  uploadResponseClients: selectUploadResponseClients(state),
  uploadResponseProducts: selectUploadResponseProducts(state),
  company: selectSetCurrentCompany(state),
  module: selectCurrentModule(state),
  polygons: selectAllPolygonsBySelect(state),
  csvWarehouse: selectCsvResponse(state),
})

const mapDispatchToProps = dispatch => ({
  uploadClientsCSV: (file, global, onSuccessLoadFile) =>
    dispatch(loadClientsCSV(file, global, onSuccessLoadFile)),
  uploadProductsCSV: (file, onSuccessLoadFile, model) =>
    dispatch(loadProductsCSV(file, onSuccessLoadFile, model)),
  getAllPolygons: () => dispatch(getAllPolygons()),
  uploadTransportationCSV: (file, onSuccessLoadFile, polygonId) =>
    dispatch(loadProductsTransportationPaymentCSV(file, onSuccessLoadFile, polygonId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CSVLoader)
