import React, { useState } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'
import { faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import IconButton from '../../buttons/IconButton'
interface FormTextProps {
  label: string
  value: string | number
  mt?: number
  type?: string
  id?: string
  placeholder?: string
  name?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  error?: string
  info?: string
  required?: boolean
  className?: string
  disabled?: boolean
  dataCy?: string
  style?: React.CSSProperties
  igStyle?: React.CSSProperties
  prepend?: string | JSX.Element
  append?: string | JSX.Element
  prependMoneySymbol?: boolean
  appendMoneySymbol?: boolean
  currencies?: {
    currentCurrency: {
      symbol: string
    }
  }
  ptext?: boolean
  atext?: boolean
  sub?: string
  subI?: string
  max?: number
  min?: number
  noSpace?: boolean
  lStyle?: React.CSSProperties
  changeValue?: (value: string, event?: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  aPadding?: boolean
  hideMaX?: boolean
}

const FormTextV2: React.FC<FormTextProps> = ({
  mt = 3,
  label,
  type,
  id,

  placeholder,
  name,
  value,
  onChange,
  error,
  info,
  required,
  className,
  disabled,
  style,
  igStyle,
  currencies,
  ptext,
  atext,
  sub,
  subI,
  max,
  min,
  noSpace,
  lStyle,
  changeValue,
  onClick,
  aPadding,
  hideMaX,
  prependMoneySymbol,
  prepend,
  append,
  appendMoneySymbol,
  dataCy,
}) => {
  const [inputValue, setInputValue] = useState<string | number>(value || '')

  const getFTMValidation = (): 'error' | 'success' => {
    if (!inputValue && (required || min)) return 'error'

    const length = String(inputValue).length

    if (min && length < min) return 'error'
    else return 'success'
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value

    if (max && newValue.length > max) return

    setInputValue(newValue)

    if (onChange) onChange(e)

    if (changeValue) changeValue(newValue, e)
  }

  const prependContent = prependMoneySymbol ? currencies.currentCurrency.symbol : prepend

  const appendContent = appendMoneySymbol ? currencies.currentCurrency.symbol : append

  return (
    <div
      className={`input-wrapper has-feedback ${mt >= 0 ? `mt-${mt}` : 'mt-3'} ${
        className || ''
      }`}
      style={style}>
      {(label || required) && (
        <label className="ftf-form-label left" htmlFor={id} style={lStyle}>
          {label} {!noSpace && <>&nbsp;</>}
          {info ? (
            <label>
              {' '}
              <IconButton
                tooltip={info}
                color="rgba(34, 96, 149, 0.75)"
                icon={faInfoCircle}
                size="sm"
              />{' '}
            </label>
          ) : (
            !noSpace && <label> &nbsp;</label>
          )}
          {required && <span style={{ color: 'red', marginRight: 2 }}>&nbsp;* </span>}
        </label>
      )}

      <div>
        <InputGroup style={igStyle}>
          {prependContent &&
            (!ptext ? (
              <InputGroup.Text style={aPadding ? { padding: 0 } : {}}>
                {prependContent}
              </InputGroup.Text>
            ) : (
              prependContent
            ))}
          <FormControl
            aria-describedby="inputGroup-sizing-default"
            disabled={disabled}
            type={!type || type === 'text' ? 'search' : type}
            placeholder={placeholder}
            name={name}
            maxLength={max}
            min={min || 0}
            value={inputValue}
            onChange={handleInputChange}
            autoComplete="off"
            data-testid={dataCy}
            onClick={onClick}
          />
          {appendContent &&
            (!atext ? (
              <InputGroup.Text style={aPadding ? { padding: 0 } : {}}>
                {appendContent}
              </InputGroup.Text>
            ) : (
              appendContent
            ))}
        </InputGroup>
        {max && !hideMaX && (
          <div className={`ft-max ft-max-${getFTMValidation()}`}>
            {inputValue ? String(inputValue).length : 0}/{max}
          </div>
        )}
      </div>
      <span className="input-focus" />
      {sub && (
        <div className="ft-sub left">
          {sub}{' '}
          {subI && (
            <label>
              {' '}
              <IconButton
                tooltip={subI}
                color="rgba(34, 96, 149, 0.75)"
                icon={faInfoCircle}
                size="sm"
              />{' '}
            </label>
          )}
        </div>
      )}
      {error && (
        <div className="left input-error">
          <FontAwesomeIcon icon={faExclamationCircle} /> {error}
        </div>
      )}
    </div>
  )
}

export default FormTextV2
