import * as React from 'react'
import {
  Col,
  ListGroup,
  Modal,
  Row,
  Tab,
  Jumbotron,
  Container,
  Carousel,
  Button,
  InputGroup,
} from 'react-bootstrap'

import { connect } from 'react-redux'
import Card from 'src/components/cards/Card'
import CustomSelect from 'src/components/inputs/Select/CustomSelect'
import { selectAllClients } from 'src/selectors/clients.selector'
import { getAllClients } from 'src/actions/clients.actions'
import ClientIcon from 'src/assets/images/svg/client.svg'

import '../Subscriptions.scss'
import DayPicker from 'react-day-picker'
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment'

import moment from 'moment'

class ModalSelectClient extends React.Component {
  state = {
    client: { label: '- No seleccionado -', value: null },

    minDate: parseDate(moment().add(1, 'days')),
    selectedDay: parseDate(moment().add(1, 'days')),
    today: parseDate(moment()),
    locale: 'es',
  }

  componentDidMount() {
    this.props.getAllClients()
  }

  onChangeClient = client => {
    this.setState({ client })
  }

  selectClient = () => {
    if (this.state.client.value) {
      this.props.onSelectClient(this.state.client, this.state.selectedDay)
    }
  }

  onSelectedDay = date => {
    if (moment(date).isSameOrAfter(this.state.today)) {
      this.setState({
        selectedDay: date,
      })
    }
  }

  render() {
    const { isRequiredClient, allClients, order, loading, setInfo } = this.props
    const { client } = this.state

    const { companies, minDate, showModalDate, showBuySuccess } = this.state

    for (let i = 0; i < allClients.length; i++) {
      const client = allClients[i]
      if (client.companyName.match('Consumidor Final')) {
        allClients.splice(i, 1)
      }
    }

    return (
      <Modal
        dialogClassName="my-modal-subscription-clients"
        centered
        show={this.props.show}
        onHide={this.props.onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>Seleccione cliente y fecha de inicio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={4}>
              <CustomSelect
                label={'Cliente'}
                placeholder={'pj. Tienda Geovani'}
                options={allClients}
                value={client}
                onChange={data => this.onChangeClient(data)}
                loading={loading}
                required
              />

              <DayPicker
                format={'DD/MM/YYYY'}
                formatDate={formatDate}
                parseDate={parseDate}
                disabledDays={[{ before: minDate }]}
                localeUtils={MomentLocaleUtils}
                locale={this.state.locale}
                // value={startDate}
                selectedDays={this.state.selectedDay}
                onDayClick={this.onSelectedDay}
                // onDayChange={this.onDateChange}
                keepFocus={true}
              />
            </Col>

            <Col md={8}>
              <div className={'custom-card-subscription'}>
                <Row style={{ justifyContent: 'center' }}>
                  <img
                    src={client.picture || ClientIcon}
                    alt={'client profile'}
                    className={'picture-client'}
                  />
                </Row>
                <Row style={{ justifyContent: 'center' }}>
                  <span className={'client-name'}>
                    {client.companyName || 'Desconocido'}
                  </span>
                </Row>
                <hr />
                <Row className={'flex-container space-between'}>
                  <Col xs={2} className={'label'}>
                    Código:
                  </Col>
                  <Col className={'description'}>{client.code || '-'}</Col>
                </Row>
                <Row className={'flex-container space-between'}>
                  <Col xs={2} className={'label'}>
                    Teléfono:
                  </Col>
                  <Col className={'description'}>{client.phone || '-'}</Col>
                </Row>
                <Row className={'flex-container space-between'}>
                  <Col xs={2} className={'label'}>
                    Dirección:
                  </Col>
                  <Col className={'description'}>{client.address || '-'}</Col>
                </Row>
                <Row className={'flex-container space-between'}>
                  <Col xs={2} className={'label'}>
                    Repartidor:
                  </Col>
                  <Col className={'description'}>{client.transporter || '-'}</Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row className={'container-buttons'}>
            <Button
              disabled={!client.value}
              variant={'secondary'}
              onClick={() => this.selectClient()}
            >
              Seleccionar
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  allClients: selectAllClients(state),
})
const mapDispatchToProps = dispatch => ({
  getAllClients: () => dispatch(getAllClients()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalSelectClient)
