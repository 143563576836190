import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Modal } from 'react-bootstrap'
import {
  faEdit,
  faEraser,
  faFileInvoice,
  faInfoCircle,
  faPlus,
  faSave,
} from '@fortawesome/free-solid-svg-icons'
import Alert from 'sweetalert-react'

import { Title, CustomTabs, FormText, Button, Select, Switch, Icon } from 'src/components'

import ImageInput, { imageTypes } from 'src/components/inputs/ImageInput/ImageInput'

import {
  actionTypes as typeC,
  getCompanyFieldsForm,
  getCorrelativeField,
  setCompanyFields,
  setCorrelativeField,
} from 'src/actions/company.actions'
import {
  selectCompanyForms,
  selectCorrelativeField,
} from 'src/selectors/company.selector'

import {
  actionTypes as typeR,
  getFELData,
  createFELData,
  getPhaseTypes,
  getCompanyPhrases,
  setCompanyPhrases,
} from 'src/actions/resolutions.actions'
import {
  selectCompanyPhrases,
  selectGetFELData,
} from 'src/selectors/resolutions.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerSuccess,
  handlerError,
  singleErrorSelector,
  hasErrorsSelector,
} from 'src/selectors/error.selector'

import { selectCurrentCompany } from 'src/selectors/user.selector'

import { getUsersChildrenByModule } from 'src/actions/modules.actions'
import { selectUsers } from 'src/selectors/modules.selector'

import { capitalizeString, valEmail } from 'src/utils/utilities'
import CustomColorPicker from 'src/components/colorpicker/CustomColorPicker'
import { getAllCurrencies, setCurrentCurrency } from 'src/actions/currencies.actions'

import GoogleOAuth from './GoogleOAuth'
import useBeforeUnload from 'src/hooks/useBeforeUnload'
import { Prompt } from 'react-router-dom'
import QuoteConfig from './QuoteConfig'
import IconButton from 'src/components/buttons/IconButton'

const CompanyConfigurations = () => {
  const dispatch = useDispatch()

  const felData = useSelector(selectGetFELData)
  const company = useSelector(selectCurrentCompany)
  const users = useSelector(selectUsers)
  const correlative = useSelector(selectCorrelativeField)

  const forms = useSelector(selectCompanyForms)
  const loading = useSelector(state =>
    loadingSelector([typeC.COMPANY_FIELDS_FORM])(state),
  )

  const loadingS = useSelector(state => loadingSelector([typeC.SET_FIELDS])(state))
  const hasErrorS = useSelector(state => hasErrorsSelector([typeC.SET_FIELDS])(state))
  const errorS = useSelector(state => singleErrorSelector([typeC.SET_FIELDS])(state))

  const loadingR = useSelector(state => loadingSelector([typeR.SET_FELDATA])(state))
  const hasErrorR = useSelector(state => hasErrorsSelector([typeR.SET_FELDATA])(state))
  const errorR = useSelector(state => singleErrorSelector([typeR.SET_FELDATA])(state))

  const loadingPhrasesTypes = useSelector(state =>
    loadingSelector([typeR.GET_PHRASES])(state),
  )
  const loadingCompanyPhrases = useSelector(state =>
    loadingSelector([typeR.GET_COMPANY_PHRASES])(state),
  )
  const phrasesTypes = useSelector(state =>
    Object.assign(
      [],
      state.resolutions.phrases.map(p => ({
        ...p,
        label: `Tipo Frase: ${p.id}`,
        value: p.id,
      })),
    ),
  )
  const companyPhrases = useSelector(selectCompanyPhrases)
  const currencies = useSelector(state =>
    Object.assign(
      [],
      state.currencies.allCurrencies.map(c => ({
        ...c,
        label: capitalizeString(`${c.name} (${c.code})`),
        value: c.id,
      })),
    ),
  )

  const [alert, setAlert] = useState({ title: '' })
  const [actions, setActions] = useState({
    fel: false,
    get: false,
    save: false,
    certificate: false,
    phrases: false,
  })
  const [returnFelData, setFelData] = useState(false)
  const [commercial, setCommercial] = useState(null)
  const [fel, setFel] = useState(null)
  const [general, setGeneral] = useState(null)
  const [requestModal, setRequestModal] = useState({ show: false })
  const [felD, setFelD] = useState(false)
  const [f, setF] = useState(false)
  const [phrases, setPhrases] = useState([])
  const [corr, setCorrelative] = useState({})
  const [pendingChanges, setPendingChanges] = useState(false)
  const [formsLoaded, setFormsLoaded] = useState(false)
  const [activeLocations, setActiveLocations] = useState({
    show: false,
    changeLocations: false,
  })
  const [locationQuantity, setLocationQuantity] = useState({ value: 3, label: '3' })
  const [locations, setLocations] = useState(['SubBodegas', 'Estantes', 'Niveles'])
  // const [logoSrc, setLogoSrc] = useState({
  //   src: { url: null },
  // })

  const currentCurrency =
    commercial && currencies.find(c => c.id === Number(commercial.currency.value))

  const [afilOptions] = useState([
    { value: 'GEN', label: 'GEN' },
    { value: 'PEQ', label: 'PEQ' },
  ])
  const [afilOption, setAfil] = useState({})

  const [documentOptions] = useState([
    { value: 'FACT', label: 'FACT' },
    { value: 'FPEQ', label: 'FPEQ' },
  ])
  const [documentOption, setDocument] = useState({})

  const setUp = () => {
    setFelData(true)
    dispatch(getCompanyFieldsForm())
    dispatch(getFELData())
    dispatch(getCorrelativeField())
  }
  useEffect(() => {
    if (general !== null && general?.warehouseLocations?.value !== '0') {
      setActiveLocations({ ...activeLocations, show: true })
      let locationsN = general.warehouseLocations.value.split(',')
      setLocationQuantity({
        value: locationsN.length,
        label: locationsN.length.toString(),
      })
      setLocations(locationsN)
    }
  }, [general])

  useEffect(() => {
    if (company === 1) dispatch(getUsersChildrenByModule(11000, true))
    setUp()
  }, [])

  useEffect(() => {
    if (!commercial && !fel && !general) return
    if (!formsLoaded) {
      return setFormsLoaded(true)
    }
    setPendingChanges(true)
  }, [commercial, fel, general])

  useBeforeUnload(() => {
    if (pendingChanges) return ''
  })

  useEffect(() => {
    if (loading) setActions({ ...actions, get: true })
    else if (actions.get) {
      setActions({ ...actions, get: false })
      setF(true)
    }
  }, [loading])

  useEffect(() => {
    if (!correlative) return
    setCorrelative(correlative)
  }, [correlative])

  useEffect(() => {
    if (!f) return
    setF(false)
    setCommercial(forms.commercial)
    setGeneral(forms.general)
    setFel(forms.fel)
    dispatch(getPhaseTypes())
    dispatch(getAllCurrencies())
    dispatch(getCompanyPhrases())
    const { afilIVA, tipoDocumento } = forms.fel
    setAfil(afilOptions.find(i => i.value === afilIVA.value))
    setPendingChanges(false)
    if (tipoDocumento)
      setDocument(documentOptions.find(i => i.value === tipoDocumento.value))
  }, [f])

  useEffect(() => {
    if (loadingPhrasesTypes) setActions({ ...actions, phrases: true })
    else if (actions.phrases) {
      setActions({ ...actions, phrases: false })
    }
  }, [loadingPhrasesTypes])

  useEffect(() => {
    if (loadingCompanyPhrases) setActions({ ...actions, companyPhrases: true })
    else if (actions.companyPhrases) {
      setActions({ ...actions, companyPhrases: false })
      setPhrases(companyPhrases)
    }
  }, [loadingCompanyPhrases])

  useEffect(() => {
    if (loadingS) setActions({ ...actions, save: true })
    else if (actions.save) {
      setActions({ ...actions, save: false })
      if (hasErrorS)
        setAlert({
          ...handlerError(errorS.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else {
        setAlert({
          ...handlerSuccess('Información guardada satisfactoriamente'),
          onConfirm: () => {
            //setUp();
            setAlert({ ...alert, show: false })
          },
        })
      }
    }
  }, [loadingS])

  useEffect(() => {
    if (loadingR) setActions({ ...actions, certificate: true })
    else if (actions.certificate && !felD) {
      setActions({ ...actions, certificate: false })
      if (hasErrorR)
        setAlert({
          ...handlerError(errorR.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else {
        setUp()
        setRequestModal({ show: false })
        setAlert({
          ...handlerSuccess(
            'Los datos han sido enviados a la plataforma para su aprobacion',
          ),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      }
    } else setFelD(false)
  }, [loadingR])

  useEffect(() => {
    if (!felData.id) return
    if (!returnFelData) return
    setFelData(false)
    setRequestModal({ ...felData })
  }, [felData])

  const changeCommercialFields = ({ target }) => {
    const { value, name } = target
    setCommercial({ ...commercial, [name]: { ...commercial[name], value } })
  }

  const changeFelFields = ({ target }) => {
    const { value, name } = target
    setFel({ ...fel, [name]: { ...fel[name], value } })
  }

  const validate = form => {
    let disabled = false
    Object.keys(form).forEach(k => {
      const key = form[k]
      if (key) {
        if (k === 'email') disabled = !valEmail(key.value)
      }
    })

    return disabled
  }

  const saveFields = (form, felR) => {
    const list = []
    Object.keys(form).forEach(k => {
      const key = form[k]
      if (key?.id === 123) {
        if (activeLocations.show) {
          let nameLocations = ''
          locations.forEach((x, i) => {
            nameLocations = nameLocations + x
            if (i < locations.length - 1) nameLocations = nameLocations + ','
          })
          list.push({
            companyId: 0,
            fieldId: key?.id,
            value: nameLocations,
          })
        } else {
          list.push({
            companyId: 0,
            fieldId: key?.id,
            value: '0',
          })
        }
      } else {
        list.push({
          companyId: 0,
          fieldId: key?.id,
          value: key.value,
        })
      }
    })

    dispatch(setCompanyFields(list, true, felR ? { ...requestModal, company } : null))

    dispatch(setCorrelativeField(corr.nextVal))
    setPendingChanges(false)
  }

  const savePhrases = () => {
    dispatch(setCompanyPhrases(phrases))
  }

  const onChangeFelData = ({ target }) => {
    const { name, value } = target
    setRequestModal({ ...requestModal, [name]: value })
  }

  const createRequest = c => {
    if (!c)
      dispatch(
        setCompanyFields([{ fieldId: 79, value: requestModal.certificate.value }], true),
      )
    else setFelD(true)
    dispatch(
      createFELData(
        company,
        requestModal.edxArea,
        requestModal.edxPassword,
        requestModal.g4sRequestor,
        requestModal.g4sUserName,
        requestModal.tekraContract,
        requestModal.tekraClient,
        requestModal.tekraPass,
        requestModal.tekraUser,
        requestModal.infileUser,
        requestModal.infileKey,
        requestModal.infileApiKey,
      ),
    )
  }

  const valRequest = values => {
    let disabled = false

    values.forEach(v => {
      if (!requestModal[v]) disabled = true
    })

    return disabled
  }

  const getUser = () => {
    const id = general.disbursement ? general.disbursement.value : 0
    return users.find(u => u.value === parseInt(id))
  }

  const hideLocations = () => {
    locations.forEach((x, i) => {
      if (x === '') {
        if (i === 0) locations[i] = 'SubBodegas'
        else if (i === 1) locations[i] = 'Estantes'
        else if (i === 2) locations[i] = 'Niveles'
      }
    })
    setLocations([...locations])
    setActiveLocations({ ...activeLocations, changeLocations: false })

    setGeneral({
      ...general,
      warehouseLocations: {
        ...general?.warehouseLocations,
        value: locations.toString().replace('[', '').replace(']', ''),
      },
    })
  }

  return (
    <div>
      <Prompt
        when={pendingChanges}
        message="Hay cambios sin guardar, ¿Está seguro que desea salir?"
      />

      <Title title={'Configuraciones'} />

      {fel && general && commercial && (
        <CustomTabs
          disabled={loadingS}
          items={[
            {
              title: 'Datos de la empresa',
              info: 'Información comercial de la empresa',
              component: (
                <Row>
                  <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                    <FormText
                      disabled={loading || loadingS}
                      label={'Nombre comercial'}
                      name={'name'}
                      type={'text'}
                      value={commercial.name && commercial.name.value}
                      required
                      onChange={changeCommercialFields}
                    />
                  </Col>
                  <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                    <FormText
                      disabled={loading || loadingS}
                      label={'Dirección Sede'}
                      name={'address'}
                      type={'text'}
                      value={commercial.address && commercial.address.value}
                      required
                      onChange={changeCommercialFields}
                    />
                  </Col>
                  <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                    <FormText
                      disabled={loading || loadingS}
                      label={'Departamento'}
                      name={'department'}
                      type={'text'}
                      value={commercial.department && commercial.department.value}
                      required
                      onChange={changeCommercialFields}
                    />
                  </Col>
                  <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                    <FormText
                      disabled={loading || loadingS}
                      label={'Municipio'}
                      name={'municipality'}
                      type={'text'}
                      value={commercial.municipality && commercial.municipality.value}
                      required
                      onChange={changeCommercialFields}
                    />
                  </Col>
                  <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                    <FormText
                      disabled={loading || loadingS}
                      label={'No. de inscripción'}
                      name={'inscriptionNumber'}
                      type={'number'}
                      value={
                        commercial.inscriptionNumber && commercial.inscriptionNumber.value
                      }
                      required
                      onChange={changeCommercialFields}
                    />
                  </Col>
                  <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                    <FormText
                      disabled={loading || loadingS}
                      label={'Fecha de vencimiento de inscripción'}
                      name={'expirationDate'}
                      type={'text'}
                      value={commercial.expirationDate && commercial.expirationDate.value}
                      required
                      onChange={changeCommercialFields}
                    />
                  </Col>
                  <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                    <FormText
                      disabled={loading || loadingS}
                      label={'Correo electrónico de la empresa'}
                      name={'email'}
                      type={'email'}
                      value={commercial.email && commercial.email.value}
                      required
                      onChange={changeCommercialFields}
                      error={
                        valEmail(commercial.email ? commercial.email.value : '')
                          ? ''
                          : 'Email incorrecto'
                      }
                    />
                  </Col>
                  <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                    <Select
                      required
                      label={'Tipo de moneda'}
                      value={currentCurrency}
                      options={currencies}
                      onChange={currency => {
                        setCommercial({
                          ...commercial,
                          currency: {
                            ...commercial.currency,
                            value: currency.value,
                          },
                        })
                      }}
                    />
                  </Col>
                  <Col xl={12} lg={12} sm={12}>
                    <Row className={'container-buttons'}>
                      <Button
                        loading={loadingS}
                        icon={faEdit}
                        right
                        color={'primary'}
                        disabled={loading || validate(commercial)}
                        onClick={() => {
                          saveFields(commercial)
                          dispatch(
                            setCurrentCurrency(
                              currencies.find(
                                c => c.id === Number(commercial.currency.value),
                              ),
                            ),
                          )
                        }}>
                        Guardar datos
                      </Button>
                    </Row>
                  </Col>
                </Row>
              ),
            },
            {
              title: 'Configuración FEL',
              info: 'Información necesaria para factura electrónica',
              component: (
                <Row>
                  <Col xl={6} lg={6} md={6} sm={6}>
                    <Select
                      required
                      label={'Modo de facturación'}
                      value={{
                        value: fel.type ? fel.type.value : '1',
                        label: !fel.type
                          ? 'Facturación FEL'
                          : fel.type.value === '1'
                          ? 'Facturación FEL'
                          : fel.type.value === '2'
                          ? 'Facturación Impresa'
                          : 'No facturar',
                      }}
                      options={[
                        { value: '1', label: 'Facturación FEL' },
                        { value: '2', label: 'Facturación Impresa' },
                        { value: '3', label: 'No facturar' },
                      ]}
                      onChange={value => {
                        setFel({ ...fel, type: { ...fel.type, value: value.value } })
                      }}
                    />
                  </Col>

                  {fel.type && fel.type.value !== '1' ? (
                    <Col xl={6} md={6} className={'mt-5'}>
                      <Button
                        loading={loadingS}
                        icon={faEdit}
                        right
                        color={'primary'}
                        onClick={() => {
                          saveFields(fel)
                          // createRequest(true);
                        }}>
                        Guardar cambio
                      </Button>
                    </Col>
                  ) : !fel.useCertificate || !fel.useCertificate.value ? (
                    <Col xl={6} lg={6} sm={6} className={'mt-5'}>
                      <Button
                        color={'primary'}
                        icon={faFileInvoice}
                        right
                        onClick={() =>
                          setRequestModal({
                            edxArea: '.',
                            edxPassword: '',
                            ...felData,
                            show: true,
                          })
                        }>
                        Registrar FEL
                      </Button>
                    </Col>
                  ) : (
                    <>
                      <Col xl={4} lg={4} md={4}>
                        <Select
                          label={'Tipo de documento'}
                          value={documentOption}
                          options={documentOptions}
                          onChange={d => setDocument(d)}
                        />
                      </Col>
                      <Col xl={6} lg={6} md={6}>
                        <Select
                          label={'Afiliación de IVA'}
                          value={afilOption}
                          options={afilOptions}
                          onChange={d => setAfil(d)}
                        />
                      </Col>
                      {/*<Col xl={6} lg={6} md={6}><FormText
                                value={fel.afilIVA && fel.afilIVA.value}
                                label={'Afiliación de IVA'}
                                name={'afilIVA'}
                                type={'text'}
                                required
                                onChange={changeFelFields}
                            /></Col>*/}
                      <Col xl={6} lg={6} md={6}>
                        <FormText
                          value={fel.code && fel.code.value}
                          label={'Código del establecimiento'}
                          name={'code'}
                          type={'text'}
                          required
                          onChange={changeFelFields}
                        />
                      </Col>
                      <Col xl={6} lg={6} md={6}>
                        <FormText
                          value={fel.felRegimen && fel.felRegimen.value}
                          label={'Regimen'}
                          name={'felRegimen'}
                          type={'text'}
                          required
                          onChange={changeFelFields}
                        />
                      </Col>
                      <Col xl={6} lg={6} md={6}>
                        <FormText
                          value={fel.postalCode && fel.postalCode.value}
                          label={'Código postal'}
                          name={'postalCode'}
                          type={'text'}
                          required
                          onChange={changeFelFields}
                        />
                      </Col>
                      {phrases.map((phrase, i) => {
                        const phraseType =
                          phrasesTypes.find(p => p.id === phrase.phraseId) || {}

                        return (
                          <>
                            <Col xl={4} lg={12} md={12}>
                              <Select
                                label={'Frase'}
                                value={phraseType}
                                options={phrasesTypes.filter(
                                  p => !phrases.find(ph => ph.phraseId === p.id),
                                )}
                                required
                                onChange={phraseType => {
                                  const newPhrase = { ...phrase, phraseId: phraseType.id }
                                  const newPhrases = [...phrases]
                                  newPhrases[i] = newPhrase
                                  setPhrases(newPhrases)
                                }}
                              />
                            </Col>
                            <Col xl={4} lg={6} md={6} xs={5}>
                              <FormText
                                value={phraseType.code}
                                label={'Tipo'}
                                type={'number'}
                                readOnly
                                onChange={ev => {
                                  const newPhraseType = phrasesTypes.find(
                                    p =>
                                      p.code === ev.target.value &&
                                      p.stage === phraseType.stage,
                                  )
                                  const newPhrase = {
                                    ...phrase,
                                    phraseId: newPhraseType.id,
                                  }
                                  const newPhrases = [...phrases]
                                  newPhrases[i] = newPhrase
                                  setPhrases(newPhrases)
                                }}
                              />
                            </Col>
                            <Col xl={3} lg={5} md={5} xs={5}>
                              <FormText
                                value={phraseType.stage}
                                label={'Escenario'}
                                type={'number'}
                                readOnly
                                onChange={ev => {
                                  const newPhraseType = phrasesTypes.find(
                                    p =>
                                      p.code === phraseType.code &&
                                      p.stage === ev.target.value,
                                  )
                                  const newPhrase = {
                                    ...phrase,
                                    phraseId: newPhraseType.id,
                                  }
                                  const newPhrases = [...phrases]
                                  newPhrases[i] = newPhrase
                                  setPhrases(newPhrases)
                                }}
                              />
                            </Col>
                            <Col xl={1} lg={1} md={1} xs={1}>
                              <Button
                                style={{ position: 'relative', top: 47 }}
                                icon={faEraser}
                                right
                                color={'danger'}
                                onClick={() => {
                                  const newPhrases = [...phrases]
                                  newPhrases.splice(i, 1)
                                  setPhrases(newPhrases)
                                }}
                              />
                            </Col>
                          </>
                        )
                      })}
                      {phrasesTypes.filter(
                        p => !phrases.find(ph => ph.phraseId === p.id),
                      ) && (
                        <Col xl={12} lg={12} md={12}>
                          <Button
                            style={{ flex: 1 }}
                            icon={faPlus}
                            right
                            onClick={() => {
                              setPhrases([
                                ...phrases,
                                { companyId: company, phraseId: null },
                              ])
                            }}
                            disabled={phrases.some(p => p.phraseId === null)}>
                            Agregar frase
                          </Button>
                        </Col>
                      )}
                      <Col xl={4} lg={4} md={4} className={'mt-4'}>
                        <Switch
                          label={'Usar FEL como productor'}
                          checked={
                            fel.felProducer ? fel.felProducer.value !== '0' : false
                          }
                          onChange={({ target }) => {
                            const { checked } = target
                            setFel({
                              ...fel,
                              felProducer: {
                                ...fel.felProducer,
                                value: checked ? '1' : '0',
                              },
                            })
                          }}
                          topLabel
                        />
                      </Col>
                      <Col xl={4} lg={4} md={4} className={'mt-4'}>
                        <Switch
                          label={'Usar FEL propio'}
                          checked={fel.ownFel ? fel.ownFel.value !== '0' : false}
                          onChange={({ target }) => {
                            const { checked } = target
                            setFel({
                              ...fel,
                              ownFel: { ...fel.ownFel, value: checked ? '1' : '0' },
                            })
                          }}
                          topLabel
                        />
                      </Col>

                      <Col xl={12} lg={12} md={12} />
                      <Col xl={4} lg={4} md={4}>
                        <Select
                          required
                          label={'Certificador'}
                          value={{
                            value: fel.useCertificate.value,
                            label:
                              fel.useCertificate.value === '1'
                                ? 'EDX'
                                : fel.useCertificate.value === '2'
                                ? 'G4S'
                                : fel.useCertificate.value === '3'
                                ? 'TEKRA'
                                : fel.useCertificate.value === '4'
                                ? 'INFILE'
                                : 'COFIDI',
                          }}
                          options={[
                            { value: '1', label: 'Usar EDX' },
                            { value: '2', label: 'Usar G4S' },
                            { value: '3', label: 'Usar TEKRA' },
                            { value: '4', label: 'Usar INFILE' },
                            { value: '5', label: 'Usar COFIDI' },
                          ]}
                          onChange={certificate =>
                            setFel({
                              ...fel,
                              useCertificate: {
                                ...fel.useCertificate,
                                value: certificate.value,
                              },
                            })
                          }
                        />
                      </Col>
                      {fel.useCertificate.value === '1' ? (
                        <>
                          <Col xl={4} lg={4} md={4} sm={12}>
                            <FormText
                              label={'EDX-Area'}
                              type={'text'}
                              name={'edxArea'}
                              value={requestModal.edxArea}
                              onChange={onChangeFelData}
                              onFocus={event => event.target.select()}
                            />
                          </Col>
                          <Col xl={4} lg={4} md={4} sm={12}>
                            <FormText
                              disabled={loadingR}
                              label={'EDX-Contraseña'}
                              type={'password'}
                              name={'edxPassword'}
                              value={requestModal.edxPassword}
                              onChange={onChangeFelData}
                            />
                          </Col>
                        </>
                      ) : fel.useCertificate.value === '2' ||
                        fel.useCertificate.value === '5' ? (
                        <>
                          <Col xl={4} lg={4} md={4} sm={12}>
                            <FormText
                              disabled={loadingR}
                              label={'Requestor'}
                              type={'text'}
                              name={'g4sRequestor'}
                              value={requestModal.g4sRequestor || '.'}
                              onChange={onChangeFelData}
                              onFocus={event => event.target.select()}
                            />
                          </Col>
                          <Col xl={4} lg={4} md={4} sm={12}>
                            <FormText
                              disabled={loadingR}
                              label={'Usuario'}
                              type={'text'}
                              name={'g4sUserName'}
                              value={requestModal.g4sUserName || '.'}
                              onChange={onChangeFelData}
                              onFocus={event => event.target.select()}
                            />
                          </Col>
                        </>
                      ) : fel.useCertificate.value === '3' ? (
                        <>
                          <Col xl={4} lg={4} md={4} sm={12}>
                            <FormText
                              disabled={loadingR}
                              label={'TEKRA-Usuario'}
                              type={'text'}
                              name={'tekraUser'}
                              value={requestModal.tekraUser}
                              onChange={onChangeFelData}
                              onFocus={event => event.target.select()}
                            />
                          </Col>
                          <Col xl={4} lg={4} md={4} sm={12}>
                            <FormText
                              disabled={loadingR}
                              label={'TEKRA-Clave'}
                              type={'text'}
                              name={'tekraPass'}
                              value={requestModal.tekraPass}
                              onChange={onChangeFelData}
                              onFocus={event => event.target.select()}
                            />
                          </Col>
                          <Col xl={4} lg={4} md={4} sm={12}>
                            <FormText
                              disabled={loadingR}
                              label={'TEKRA-Cliente'}
                              type={'text'}
                              name={'tekraClient'}
                              value={requestModal.tekraClient}
                              onChange={onChangeFelData}
                              onFocus={event => event.target.select()}
                            />
                          </Col>
                          <Col xl={4} lg={4} md={4} sm={12}>
                            <FormText
                              disabled={loadingR}
                              label={'TEKRA-Contrato'}
                              type={'text'}
                              name={'tekraContract'}
                              value={requestModal.tekraContract}
                              onChange={onChangeFelData}
                              onFocus={event => event.target.select()}
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col xl={4} lg={4} md={4} sm={12}>
                            <FormText
                              disabled={loadingR}
                              label={'INFILE-Usuario'}
                              type={'text'}
                              name={'infileUser'}
                              value={requestModal.infileUser || '.'}
                              onChange={onChangeFelData}
                              onFocus={event => event.target.select()}
                            />
                          </Col>
                          <Col xl={4} lg={4} md={4} sm={12}>
                            <FormText
                              disabled={loadingR}
                              label={'INFILE-Llave'}
                              type={'text'}
                              name={'infileKey'}
                              value={requestModal.infileKey || '.'}
                              onChange={onChangeFelData}
                              onFocus={event => event.target.select()}
                            />
                          </Col>
                          <Col xl={4} lg={4} md={4} sm={12}>
                            <FormText
                              disabled={loadingR}
                              label={'INFILE-API-KEY'}
                              type={'text'}
                              name={'infileApiKey'}
                              value={requestModal.infileApiKey || '.'}
                              onChange={onChangeFelData}
                              onFocus={event => event.target.select()}
                            />
                          </Col>
                        </>
                      )}

                      <Col xl={12} lg={12} md={12}>
                        <Row className={'container-buttons'}>
                          <Button
                            disabled={
                              loading ||
                              validate(fel) ||
                              (fel.useCertificate.value === '1' &&
                                valRequest(['edxArea', 'edxPassword'])) ||
                              (fel.useCertificate.value === '2' &&
                                valRequest(['g4sRequestor', 'g4sUserName'])) ||
                              (fel.useCertificate.value === '3' &&
                                valRequest([
                                  'tekraContract',
                                  'tekraClient',
                                  'tekraPass',
                                  'tekraUser',
                                ])) ||
                              (fel.useCertificate.value === '4' &&
                                valRequest(['infileUser', 'infileKey', 'infileApiKey']))
                            }
                            icon={faEdit}
                            right
                            color={'primary'}
                            loading={loadingS}
                            onClick={() => {
                              saveFields(
                                {
                                  ...fel,
                                  afilIVA: { value: afilOption.value, id: 75 },
                                  tipoDocumento: { value: documentOption.value, id: 92 },
                                },
                                true,
                              )
                              savePhrases()
                              //createRequest(true);
                            }}>
                            Guardar datos
                          </Button>
                        </Row>
                      </Col>
                    </>
                  )}
                </Row>
              ),
            },
            {
              title: 'Otras configuraciones',
              info: 'Configuraciones',
              component: (
                <Row>
                  <Col xl={10} lg={10} md={10}>
                    <div>
                      <h3>
                        <b>CLIENTES</b>
                      </h3>
                    </div>
                    <Row>
                      <Col xl={12} lg={12} md={12}>
                        <div className={'column'}>
                          <label>
                            A los clientes que no registren ventas luego de
                            <input
                              id={'inactive'}
                              type={'number'}
                              name={'inactive'}
                              placeholder={'días'}
                              value={general.client && general.client.value}
                              onChange={({ target }) => {
                                let { value } = target
                                if (!value || value < 0) value = 0
                                setGeneral({
                                  ...general,
                                  client: { ...general.client, value },
                                })
                              }}
                              style={{ margin: '0px 6px', width: 40 }}
                            />
                            días cambiarán su estado a inactivo
                          </label>
                        </div>
                      </Col>
                      <Col xl={6} lg={6} md={6}>
                        <Switch
                          topLabel
                          label={
                            'Realizar ventas a crédito a todos los clientes sin importar el límite de crédito.'
                          }
                          checked={
                            general.freeCredit ? general.freeCredit.value !== '0' : false
                          }
                          onChange={({ target }) => {
                            const { checked } = target
                            setGeneral({
                              ...general,
                              freeCredit: {
                                ...general.freeCredit,
                                value: checked ? '1' : '0',
                              },
                            })
                          }}
                          info={
                            'Al activar la opción, todas las ventas a crédito ignoraran el límite de crédito del cliente.'
                          }
                        />
                      </Col>
                      <Col xl={6} lg={6} md={6}>
                        <Switch
                          topLabel
                          label={
                            'Asignar el correo electrónico del cliente a la confirmación de una venta.'
                          }
                          checked={
                            general.orderClientEmail
                              ? general.orderClientEmail.value !== '0'
                              : false
                          }
                          onChange={({ target }) => {
                            const { checked } = target
                            setGeneral({
                              ...general,
                              orderClientEmail: {
                                ...general.orderClientEmail,
                                value: checked ? '1' : '0',
                              },
                            })
                          }}
                          info={
                            'Al activar la opción, al confirmar una venta el correo electrónico del cliente será asignado automáticamente a la factura.'
                          }
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col xl={12} lg={12} md={12}>
                    <div>
                      <h3>
                        <b>DESCUENTOS</b>
                      </h3>
                    </div>
                    <Row>
                      <Col xl={4} lg={4} md={4}>
                        <Switch
                          topLabel
                          label={'Desactivar los descuentos dentro de la empresa'}
                          checked={
                            general.discount ? general.discount.value !== 'false' : false
                          }
                          onChange={({ target }) => {
                            const { checked } = target
                            setGeneral({
                              ...general,
                              discount: {
                                ...general.discount,
                                value: checked.toString(),
                              },
                            })
                          }}
                        />
                      </Col>
                      <Col xl={4} lg={4} md={4}>
                        <FormText
                          mt={0}
                          prepend={'%'}
                          label={'Porcentaje maximo posible a descontar en cada venta'}
                          type={'number'}
                          name={'maxDiscount'}
                          value={
                            general.maxDiscount
                              ? Number.parseFloat(general.maxDiscount.value)
                              : 0
                          }
                          onChange={({ target }) => {
                            let { value } = target
                            if (!value || value < 0) value = 0
                            else if (value > 100) value = 100
                            setGeneral({
                              ...general,
                              maxDiscount: { ...general.maxDiscount, value },
                            })
                          }}
                          onFocus={event => event.target.select()}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col xl={12} lg={12} md={12}>
                    <div>
                      <h3>
                        <b>FACTURACIÓN</b>
                      </h3>
                    </div>
                    <Row>
                      <Col xl={6} lg={6} md={6}>
                        <Select
                          mt={0}
                          label={'Flujo de certificación'}
                          info={
                            'Se debe de definir el flujo que debera realizar una orden de venta respecto con la certificación electrónica (FEL)'
                          }
                          value={{
                            value: general.certificate.value,
                            label:
                              general.certificate.value === '1'
                                ? 'No emisión factura electrónica'
                                : 'Siempre emitir factura electrónica',
                          }}
                          options={[
                            {
                              value: 1,
                              label: 'No emisión factura electrónica',
                            },
                            {
                              value: 2,
                              label: 'Siempre emitir factura electrónica',
                            },
                          ]}
                          onChange={certificate =>
                            setGeneral({
                              ...general,
                              certificate: {
                                ...general.certificate,
                                value: `${certificate.value}`,
                              },
                            })
                          }
                        />
                      </Col>
                      <Col xl={6} lg={6} md={6}>
                        <FormText
                          mt={0}
                          label={'Codigo correlativo'}
                          type={'number'}
                          name={'correlative'}
                          info={
                            'Indica cual es el correlativo numerico actual de las ventas.'
                          }
                          value={corr.nextVal}
                          onChange={({ target }) => {
                            let { value } = target
                            if (!value || value < 0) value = 0
                            setCorrelative({ ...corr, nextVal: value })
                          }}
                          changeValue={value => {
                            setGeneral({
                              ...general,
                              footer: { ...general.footer, value },
                            })
                          }}
                          onFocus={event => event.target.select()}
                        />
                      </Col>
                      <Col xl={12} lg={12} md={8} sm={12} xs={12}>
                        <FormText
                          mt={0}
                          label={'"Pie de pagina" en las facturas'}
                          info={
                            'En la sección inferior de las facturas se mostrara la información que se agregue en este campo'
                          }
                          as={'textarea'}
                          value={general.footer && general.footer.value}
                          changeValue={value => {
                            setGeneral({
                              ...general,
                              footer: { ...general.footer, value },
                            })
                          }}
                          max={600}
                        />
                      </Col>
                      <Col xl={6} lg={6} md={6}>
                        <Switch
                          topLabel
                          label={'Mostrar el Gran total de la factura en texto'}
                          checked={
                            general.invoiceTotalText &&
                            general.invoiceTotalText.value === '1'
                          }
                          onChange={({ target }) => {
                            const { checked } = target
                            setGeneral({
                              ...general,
                              invoiceTotalText: {
                                ...general.invoiceTotalText,
                                value: checked ? '1' : '0',
                              },
                            })
                          }}
                          info={'Imprime el total de la factura en letras.'}
                        />
                      </Col>
                      <Col xl={6} lg={6} md={6}>
                        <Switch
                          topLabel
                          label={'Activar FEL automáticamente'}
                          checked={
                            general.automaticFel && general.automaticFel.value === '1'
                          }
                          onChange={({ target }) => {
                            const { checked } = target
                            setGeneral({
                              ...general,
                              automaticFel: {
                                ...general.automaticFel,
                                value: checked ? '1' : '0',
                              },
                            })
                          }}
                          info={
                            'Al activar esta opción se activará automáticamente la certificación de FEL durante confirmaciones de venta.'
                          }
                        />
                      </Col>
                      <Col xl={6} lg={6} md={6}>
                        <Switch
                          topLabel
                          label={"Mostrar SKU's en la facturación"}
                          checked={
                            general.visibleSKU && general.visibleSKU.value === 'true'
                          }
                          onChange={({ target }) => {
                            const { checked } = target
                            setGeneral({
                              ...general,
                              visibleSKU: {
                                ...general.visibleSKU,
                                value: checked ? 'true' : 'false',
                              },
                            })
                          }}
                          info={
                            'Al activar esta opción el SKU de los productos se mostrara en la factura.'
                          }
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col xl={12} lg={12} md={12}>
                    <div>
                      <h3>
                        <b>COBROS CON TARJETA</b>
                      </h3>
                    </div>
                    <Row>
                      <Col xl={6} lg={6} md={6}>
                        <Switch
                          topLabel
                          label={'Administrar cobros con tarjeta'}
                          checked={
                            general.paymentTarget
                              ? general.paymentTarget.value !== '0'
                              : false
                          }
                          onChange={({ target }) => {
                            const { checked } = target
                            setGeneral({
                              ...general,
                              paymentTarget: {
                                ...general.paymentTarget,
                                value: checked ? '1' : '0',
                              },
                            })
                          }}
                          info={
                            'Al estar activa la admistración de los cobros con tarjeta, las ordenes de venta con el tipo de pago: Cobros con tarjeta, seran visibles y operables en el modulo Cobros con tarjeta, de lo contrario, no se llevará la adminsitración.'
                          }
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col xl={12} lg={12} md={12}>
                    <div>
                      <h3>
                        <b>COTIZACIONES</b>
                      </h3>
                    </div>
                    <Row>
                      <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <Switch
                          topLabel
                          label={'Utilizar plantilla 01 M, en PDF'}
                          checked={general?.template01MQuote?.value === '1'}
                          changeValue={value =>
                            setGeneral({
                              ...general,
                              template01MQuote: {
                                ...general.template01MQuote,
                                value: value ? '1' : '0',
                              },
                            })
                          }
                          info={
                            'Al estar activo se utilizara la plantilla 01 M en el pdf de cotizaciones, si esta desactivado se descargar la plantilla basica del PDF actual'
                          }
                        />
                      </Col>

                      {general?.template01MQuote?.value === '1' && (
                        <Col xl={12}>
                          <QuoteConfig
                            description={general?.descriptionQuote}
                            onChange={({ description }) => {
                              setGeneral({
                                ...general,
                                descriptionQuote: {
                                  ...general.descriptionQuote,
                                  value: description,
                                },
                              })
                            }}
                          />
                        </Col>
                      )}

                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <CustomColorPicker
                          label={'Color del texto en los pdf'}
                          color={`rgb(${general.textColor.value})`}
                          onChangeColor={(value, color) => {
                            let rgb = color.r + ',' + color.g + ',' + color.b
                            setGeneral({
                              ...general,
                              textColor: { ...general.textColor, value: rgb },
                            })
                          }}
                        />
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <CustomColorPicker
                          color={`rgb(${general.backgroundColor.value})`}
                          label={'Color de fondo de los encabezados en los pdf'}
                          onChangeColor={(value, color) => {
                            let rgb = color.r + ',' + color.g + ',' + color.b
                            setGeneral({
                              ...general,
                              backgroundColor: {
                                ...general.backgroundColor,
                                value: rgb,
                              },
                            })
                          }}
                        />
                      </Col>

                      <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <Switch
                          topLabel
                          label={'Visualizar fotos de los ítems de la cotización'}
                          checked={general?.imageOnQuote?.value === '1'}
                          changeValue={value =>
                            setGeneral({
                              ...general,
                              imageOnQuote: {
                                ...general.imageOnQuote,
                                value: value ? '1' : '0',
                              },
                            })
                          }
                        />
                      </Col>

                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Switch
                          topLabel
                          label={
                            'Los precios de los items seleccionados están sin impuestos'
                          }
                          checked={general?.withoutTaxOnQuote?.value === '1'}
                          changeValue={value =>
                            setGeneral({
                              ...general,
                              withoutTaxOnQuote: {
                                ...general.withoutTaxOnQuote,
                                value: value ? '1' : '0',
                              },
                            })
                          }
                        />
                      </Col>

                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Switch
                          topLabel
                          label={'Manejo de impuesto de Timbre de prensa'}
                          checked={general?.taxPressBellOnQuote?.value === '1'}
                          changeValue={value =>
                            setGeneral({
                              ...general,
                              taxPressBellOnQuote: {
                                ...general.taxPressBellOnQuote,
                                value: value ? '1' : '0',
                              },
                            })
                          }
                        />
                      </Col>

                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Switch
                          topLabel
                          label={'Permitir facturar abonos en cotizaciones'}
                          info={
                            'Al activar la opción se permite facturar los abonos en cotizaciones en lugar de la cotización.'
                          }
                          checked={general?.billQuotePayments?.value === '1'}
                          changeValue={value =>
                            setGeneral({
                              ...general,
                              billQuotePayments: {
                                ...general.billQuotePayments,
                                value: value ? '1' : '0',
                              },
                            })
                          }
                        />
                      </Col>

                      <Col xl={6} lg={6} md={6} sm={12} xs={!2}>
                        <FormText
                          label={'Manejar comisión de agencia'}
                          prepend={'%'}
                          value={general?.commissionOnQuote?.value || 0}
                          type={'number'}
                          changeValue={value => {
                            if (!value) value = 0
                            if (value < 0) value = 0
                            if (value > 100) value = 100
                            setGeneral({
                              ...general,
                              commissionOnQuote: {
                                ...general.commissionOnQuote,
                                value: value,
                              },
                            })
                          }}
                        />
                      </Col>

                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormText
                          label={'Nombre de representante'}
                          value={general?.representativeNameQuote?.value}
                          type={'text'}
                          changeValue={value => {
                            setGeneral({
                              ...general,
                              representativeNameQuote: {
                                ...general.representativeNameQuote,
                                value: value,
                              },
                            })
                          }}
                        />
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormText
                          label={'Corre electrónico del representante'}
                          value={general?.representativeEmailQuote?.value}
                          type={'email'}
                          changeValue={value => {
                            setGeneral({
                              ...general,
                              representativeEmailQuote: {
                                ...general.representativeEmailQuote,
                                value: value,
                              },
                            })
                          }}
                        />
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormText
                          label={'Número telefonico del representante'}
                          value={general?.representativePhoneQuote?.value}
                          type={'email'}
                          changeValue={value => {
                            setGeneral({
                              ...general,
                              representativePhoneQuote: {
                                ...general.representativePhoneQuote,
                                value: value,
                              },
                            })
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col xl={12} lg={12} md={12}>
                    <div>
                      <h3>
                        <b>TRANSACCIONES</b>
                      </h3>
                    </div>
                    <Row>
                      <Col xl={6} lg={6} md={6}>
                        <Switch
                          topLabel
                          label={
                            'Registrar mismo producto en creacion de Ventas, Compras o Gastos'
                          }
                          info={
                            'Al estar activa esta opción, el sistema permitirá hacer registros del mismo producto durante la creación de una Venta, Compra o Gasto.'
                          }
                          checked={
                            general.productRepeat
                              ? general.productRepeat.value !== 'false'
                              : false
                          }
                          onChange={({ target }) => {
                            const { checked } = target
                            setGeneral({
                              ...general,
                              productRepeat: {
                                ...general.productRepeat,
                                value: checked ? 'true' : 'false',
                              },
                            })
                          }}
                        />
                      </Col>
                      <Col xl={6} lg={6} md={6}>
                        <Switch
                          topLabel
                          label={'Ingresar precios de forma manual Compras/Gastos'}
                          info={
                            'Al estar activa esta opción las compras/gastos se les habilitara el ingreso de forma manual del precio de los items, y el subtotal se calculara en base al precio'
                          }
                          checked={
                            general.editPricesPurchase
                              ? general.editPricesPurchase.value !== 'false'
                              : false
                          }
                          onChange={({ target }) => {
                            const { checked } = target
                            setGeneral({
                              ...general,
                              editPricesPurchase: {
                                ...general.editPricesPurchase,
                                value: checked ? 'true' : 'false',
                              },
                            })
                          }}
                        />
                      </Col>
                      <Col xl={6} lg={6} md={6}>
                        <Switch
                          topLabel
                          label={'Reservar inventario al realizar despachos pendientes'}
                          info={
                            'Al estar activa esta opción y al realizar una confirmación de orden de venta con la opción de despachos pendientes, el inventario en bodega sera reservado y dejara de estar disponible para ventas'
                          }
                          checked={
                            general.dispatch ? general.dispatch.value !== 'false' : false
                          }
                          onChange={({ target }) => {
                            const { checked } = target
                            setGeneral({
                              ...general,
                              dispatch: {
                                ...general.dispatch,
                                value: checked ? 'true' : 'false',
                              },
                            })
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col xl={12} lg={12} md={12}>
                    <div>
                      <h3>
                        <b>BODEGAS</b>
                      </h3>
                    </div>
                    <Row>
                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormText
                          label={'Token inicio de sesion Wialon'}
                          info={'Token con el cual se puede iniciar sesion con Wialon.'}
                          value={general?.wialonKey?.value}
                          type={'text'}
                          changeValue={value => {
                            setGeneral({
                              ...general,
                              wialonKey: {
                                ...general.wialonKey,
                                value: value,
                              },
                            })
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  {company === 1 && (
                    <Col xl={12} lg={12} md={12}>
                      <div>
                        <h3>
                          <b>CONFIGURACIONES KOLO</b>
                        </h3>
                      </div>
                      <Row>
                        <Col xl={6} lg={6} md={6}>
                          <Select
                            label={'Encargado de Desembolsos'}
                            options={[{ value: 0, label: 'No asignar' }, ...users]}
                            value={getUser()}
                            onChange={u =>
                              setGeneral({
                                ...general,
                                disbursement: {
                                  ...general.disbursement,
                                  value: u.value,
                                },
                              })
                            }
                            required
                          />
                        </Col>
                        <Col xl={6} lg={6} md={6}>
                          <FormText
                            prepend={'%'}
                            label={
                              'Se les cobrara de recargo a los clientes que paguen con tarjeta desde el link de pago'
                            }
                            type={'number'}
                            name={'surcharge'}
                            value={
                              general.surcharge
                                ? Number.parseFloat(general.surcharge.value)
                                : 0
                            }
                            onChange={({ target }) => {
                              let { value } = target
                              if (!value || value < 0) value = 0
                              setGeneral({
                                ...general,
                                surcharge: { ...general.surcharge, value },
                              })
                            }}
                            onFocus={event => event.target.select()}
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}

                  <Col xl={12} lg={12} md={12}>
                    <Switch
                      topLabel
                      label={'Marcar propina como opcional'}
                      checked={general?.tipOptional?.value === 'true'}
                      changeValue={value =>
                        setGeneral({
                          ...general,
                          tipOptional: { ...general?.tipOptional, value: `${value}` },
                        })
                      }
                    />
                  </Col>
                  <Col xl={12} lg={12} md={12}>
                    <h3>
                      <b>UBICACIONES</b>
                    </h3>
                    <Row>
                      <Col xl={6} lg={6} md={6}>
                        <Switch
                          topLabel
                          label={
                            'Al estar activa esta opción, se habilitaran las ubicaciones en la bodega, con un maximo de 3.'
                          }
                          checked={activeLocations.show}
                          onChange={({ target }) => {
                            const { checked } = target
                            setActiveLocations({ ...activeLocations, show: checked })
                            if (!checked) {
                              general.warehouseLocations.value = '0'
                              setGeneral({
                                ...general,
                                seeWarehouseLocations: {
                                  ...general.seeWarehouseLocations,
                                  value: 'false',
                                },
                                invoiceWarehouseLocations: {
                                  ...general.invoiceWarehouseLocations,
                                  value: 'false',
                                },
                                orderWarehouseLocations: {
                                  ...general.orderWarehouseLocations,
                                  value: 'false',
                                },
                              })
                            }
                          }}
                        />
                      </Col>
                      {activeLocations.show && (
                        <>
                          <Col xl={6} lg={6} md={6}>
                            <Select
                              mt={0}
                              label={'Cantidad de ubicaciones'}
                              info={'Minimo 1 ubicacion y 3 como maximo'}
                              style={{ margin: '15px 0' }}
                              options={[
                                { value: 1, label: '1' },
                                { value: 2, label: '2' },
                                { value: 3, label: '3' },
                              ]}
                              value={locationQuantity}
                              onChange={value => {
                                setLocationQuantity(value)
                                if (value.value === 1)
                                  setLocations(locations.splice(0, 1))
                                if (value.value === 2) {
                                  if (locations.length === 1)
                                    setLocations([...locations, 'Estantes'])
                                  else if (locations.length === 3)
                                    setLocations(locations.splice(0, 2))
                                }
                                if (value.value === 3) {
                                  if (locations.length === 1) {
                                    locations.push('Estantes')
                                    locations.push('Niveles')
                                    setLocations(locations)
                                  } else if (locations.length === 2)
                                    setLocations([...locations, 'Niveles'])
                                }
                              }}
                              subText={
                                <div>
                                  <br />
                                  <p>
                                    <Col>
                                      <Row>
                                        {locations.map((val, i) => (
                                          <>
                                            <b>{val}</b> {i < locations.length - 1 && '→'}
                                          </>
                                        ))}
                                        &nbsp;&nbsp;
                                        <Icon
                                          onClick={() => {
                                            setActiveLocations({
                                              ...activeLocations,
                                              changeLocations: true,
                                            })
                                          }}
                                          tooltip={'Personalizar ubicaciones'}
                                          icon={faEdit}
                                        />
                                      </Row>
                                    </Col>
                                  </p>
                                </div>
                              }
                            />
                          </Col>

                          <Col xl={6} lg={6} md={6}>
                            <Switch
                              topLabel
                              label={
                                'Al estar activa esta opción, permite la visualizacion de las ubicaciones en el inventario y en la bodega. '
                              }
                              checked={
                                general.seeWarehouseLocations
                                  ? general.seeWarehouseLocations.value !== 'false'
                                  : false
                              }
                              onChange={({ target }) => {
                                const { checked } = target
                                setGeneral({
                                  ...general,
                                  seeWarehouseLocations: {
                                    ...general.seeWarehouseLocations,
                                    value: checked ? 'true' : 'false',
                                  },
                                })
                              }}
                            />
                          </Col>
                          <Col xl={6} lg={6} md={6}>
                            <Switch
                              topLabel
                              label={
                                'Al estar activa esta opción, permite visualizar las ubicaciones en el detalle de la orden, asi como en el ticket. '
                              }
                              checked={
                                general.orderWarehouseLocations
                                  ? general.orderWarehouseLocations.value !== 'false'
                                  : false
                              }
                              onChange={({ target }) => {
                                const { checked } = target
                                setGeneral({
                                  ...general,
                                  orderWarehouseLocations: {
                                    ...general.orderWarehouseLocations,
                                    value: checked ? 'true' : 'false',
                                  },
                                })
                              }}
                            />
                          </Col>
                          <Col xl={6} lg={6} md={6}>
                            <Switch
                              topLabel
                              label={
                                'Al estar activa esta opción, permite Visualizar las ubicaciones en la factura. '
                              }
                              checked={
                                general.invoiceWarehouseLocations
                                  ? general.invoiceWarehouseLocations.value !== 'false'
                                  : false
                              }
                              onChange={({ target }) => {
                                const { checked } = target
                                setGeneral({
                                  ...general,
                                  invoiceWarehouseLocations: {
                                    ...general.invoiceWarehouseLocations,
                                    value: checked ? 'true' : 'false',
                                  },
                                })
                              }}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </Col>
                  <Col xl={12} lg={12} md={12}>
                    <h3>
                      <b>TICKETS</b>
                    </h3>
                    <Row>
                      <Col xl={6} lg={6} md={6}>
                        <Switch
                          topLabel
                          label={'Imprimir logo en tickets.'}
                          info={
                            'Al estar activa esta opción se imprimirá un logo en los tickets de órdenes de venta y facturas.'
                          }
                          checked={general?.activeTicketsLogo?.value === 'true'}
                          changeValue={value =>
                            setGeneral({
                              ...general,
                              activeTicketsLogo: {
                                ...general?.activeTicketsLogo,
                                value: String(value),
                              },
                            })
                          }
                        />
                      </Col>
                      {general?.activeTicketsLogo?.value === 'true' && (
                        <Col xl={6} lg={6} md={6}>
                          <div>
                            Logo para tickets{' '}
                            <label>
                              <IconButton
                                tooltip={
                                  'Si no se agrega un logo, no se imprimirá nada en los tickets.'
                                }
                                color={'rgba(34, 96, 149, 0.75)'}
                                icon={faInfoCircle}
                                size={'sm'}
                              />
                            </label>
                          </div>
                          <ImageInput
                            imageType={imageTypes.TICKET_LOGO}
                            src={{
                              url:
                                general?.ticketsLogo?.value !== 'null' &&
                                general?.ticketsLogo?.value,
                            }}
                            disabled={loading || loadingS}
                            style={{ height: 100, width: 100 }}
                            onSuccessFile={url => {
                              setGeneral({
                                ...general,
                                ticketsLogo: {
                                  ...general?.ticketsLogo,
                                  value: url,
                                },
                              })
                            }}
                          />
                        </Col>
                      )}
                    </Row>
                  </Col>
                  <Col xl={12} lg={12} sm={12}>
                    <Row className={'container-buttons'}>
                      <Button
                        loading={loadingS}
                        icon={faEdit}
                        right
                        color={'primary'}
                        disabled={loading || validate(general)}
                        onClick={() => {
                          saveFields(general)
                        }}>
                        Guardar datos
                      </Button>
                    </Row>
                  </Col>
                </Row>
              ),
            },
            {
              show: company === 1,
              title: 'Autenticación google OAuth',
              info: 'Servicios para el registro de cuenta de google para la descarga de pdf de google docs',
              component: () => <GoogleOAuth />,
            },
          ]}
        />
      )}

      <Modal
        show={activeLocations.changeLocations}
        centered
        size={'xs'}
        onHide={() => hideLocations()}>
        <Modal.Header closeButton>
          <Modal.Title>Ubicaciones</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12} lg={12} md={12}>
              <FormText
                label={'Ubicacion Principal'}
                value={locations[0]}
                type={'text'}
                info={'Ingrese el nombre de la primera ubicacion'}
                changeValue={value => {
                  locations[0] = value
                  setLocations([...locations])
                }}
              />
            </Col>
            {(locationQuantity.value === 2 || locationQuantity.value === 3) && (
              <Col xl={12} lg={12} md={12}>
                <FormText
                  label={'Ubicacion secundaria'}
                  value={locations[1]}
                  type={'text'}
                  info={'Ingrese el nombre de la primera ubicacion'}
                  changeValue={value => {
                    locations[1] = value
                    setLocations([...locations])
                  }}
                />
              </Col>
            )}
            {locationQuantity.value === 3 && (
              <Col xl={12} lg={12} md={12}>
                <FormText
                  label={'Ubicacion final'}
                  value={locations[2]}
                  type={'text'}
                  info={'Ingrese el nombre de la primera ubicacion'}
                  changeValue={value => {
                    locations[2] = value
                    setLocations([...locations])
                  }}
                />
              </Col>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              loading={loadingR}
              icon={faSave}
              color={'primary'}
              right
              onClick={() => hideLocations()}>
              Guardar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={requestModal.show}
        centered
        size={'lg'}
        onHide={() => setRequestModal({ show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Registrar FEL</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col xl={12} lg={12} md={12}>
              <p>
                Cotizap puede utilizar las credenciales de diferentes Certificadores para
                generar facturas electronicas de manera automatica. Ingresa las
                credenciales del certificador para que esta empresa genere facturas
                electronicas. Deberas de llenar los nuevos datos requeridos para realizar
                este proceso.
              </p>
            </Col>

            <Col xl={6} lg={6} md={6}>
              <Select
                disabled={loadingR}
                required
                label={'Certificador'}
                value={requestModal.certificate}
                options={[
                  { value: 1, label: 'Usar EDX' },
                  { value: 2, label: 'Usar G4S' },
                  { value: 3, label: 'Usar TEKRA' },
                  { value: 4, label: 'Usar INFILE' },
                  { value: 5, label: 'Usar COFIDI' },
                ]}
                onChange={certificate =>
                  setRequestModal({ ...requestModal, certificate })
                }
              />
            </Col>

            <Col xl={6} lg={6} md={6} />

            {requestModal.certificate ? (
              requestModal.certificate.value === 1 ? (
                <>
                  <Col xl={6} lg={6} md={6} sm={12}>
                    <FormText
                      disabled={loadingR}
                      label={'EDX-Area'}
                      type={'text'}
                      name={'edxArea'}
                      value={requestModal.edxArea}
                      onChange={onChangeFelData}
                      onFocus={event => event.target.select()}
                    />
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12}>
                    <FormText
                      disabled={loadingR}
                      label={'EDX-Contraseña'}
                      type={'password'}
                      name={'edxPassword'}
                      value={requestModal.edxPassword}
                      onChange={onChangeFelData}
                    />
                  </Col>
                </>
              ) : requestModal.certificate.value === 2 ||
                requestModal.certificate.value === 5 ? (
                <>
                  <Col xl={6} lg={6} md={6} sm={12}>
                    <FormText
                      disabled={loadingR}
                      label={'Requestor'}
                      type={'text'}
                      name={'Requestor'}
                      value={requestModal.g4sRequestor}
                      onChange={onChangeFelData}
                    />
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12}>
                    <FormText
                      disabled={loadingR}
                      label={'Usuario'}
                      type={'text'}
                      name={'UserName'}
                      value={requestModal.g4sUserName}
                      onChange={onChangeFelData}
                    />
                  </Col>
                </>
              ) : requestModal.certificate.value === 3 ? (
                <>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <FormText
                      disabled={loadingR}
                      label={'TEKRA-Usuario'}
                      type={'text'}
                      name={'tekraUser'}
                      value={requestModal.tekraUser}
                      onChange={onChangeFelData}
                    />
                  </Col>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <FormText
                      disabled={loadingR}
                      label={'TEKRA-Clave'}
                      type={'text'}
                      name={'tekraPass'}
                      value={requestModal.tekraPass}
                      onChange={onChangeFelData}
                    />
                  </Col>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <FormText
                      disabled={loadingR}
                      label={'TEKRA-Cliente'}
                      type={'text'}
                      name={'tekraClient'}
                      value={requestModal.tekraClient}
                      onChange={onChangeFelData}
                    />
                  </Col>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <FormText
                      disabled={loadingR}
                      label={'TEKRA-Contrato'}
                      type={'text'}
                      name={'tekraContract'}
                      value={requestModal.tekraContract}
                      onChange={onChangeFelData}
                    />
                  </Col>
                </>
              ) : (
                <>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <FormText
                      disabled={loadingR}
                      label={'INFILE-Usuario'}
                      type={'text'}
                      name={'infileUser'}
                      value={requestModal.infileUser}
                      onChange={onChangeFelData}
                    />
                  </Col>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <FormText
                      disabled={loadingR}
                      label={'INFILE-Llave'}
                      type={'text'}
                      name={'infileKey'}
                      value={requestModal.infileKey}
                      onChange={onChangeFelData}
                    />
                  </Col>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <FormText
                      disabled={loadingR}
                      label={'INFILE-API-KEY'}
                      type={'text'}
                      name={'infileApiKey'}
                      value={requestModal.infileApiKey}
                      onChange={onChangeFelData}
                    />
                  </Col>
                </>
              )
            ) : (
              ''
            )}
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              disabled={
                !requestModal.certificate ||
                (requestModal.certificate.value === 1 &&
                  valRequest(['edxArea', 'edxPassword'])) ||
                (requestModal.certificate.value === 2 &&
                  valRequest(['g4sRequestor', 'g4sUserName']))
              }
              loading={loadingR}
              icon={faSave}
              color={'primary'}
              right
              onClick={() => createRequest()}>
              Crear solicitud
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Alert {...alert} />
    </div>
  )
}
export default CompanyConfigurations
