import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CustomCreate } from 'src/components/index'

import ProductsService from 'src/services/products.service'

import {
  actionTypes as productsActions,
  getProductsForSelector,
} from 'src/actions/products.actions'
import { selectProductsLite } from 'src/selectors/products.selector'

import { loadingSelector } from 'src/selectors/loading.selector'

interface IItemsFilterProps {
  label: string
  info: string
  onSelect: (value: IItemLite) => void
  value?: IItemLite
  error?: string
  disabled?: boolean
}

const ItemsFilter = ({
  label,
  info,
  onSelect,
  value,
  error,
  disabled = false,
}: IItemsFilterProps) => {
  const dispatch = useDispatch()

  const items: IItemLite[] = useSelector(selectProductsLite)
  const loading: boolean = useSelector(state =>
    loadingSelector([productsActions.GET_PRODUCTS_SELECTOR])(state),
  )

  useEffect(() => {
    if (items.length === 0) dispatch(getProductsForSelector())
  }, [])

  return (
    <CustomCreate
      label={label}
      info={info}
      value={value || { value: null, label: 'Selecciona un ítem' }}
      options={items}
      onChange={(value: IItemLite) => onSelect(value)}
      placeHolder={'Selecciona un ítem'}
      textLabel={'Buscar ítem'}
      isLoading={loading}
      async
      dontClear
      error={error}
      disabled={disabled}
      loadOptions={async (search: string) => {
        if (!(search == null || search === '')) {
          const data: any[] = await ProductsService.getProductsLite({ search })
          return [
            ...data.map(p => ({
              value: p.id,
              label: `${p.name} - ${p.code}`,
            })),
          ]
        } else return items
      }}
    />
  )
}
export default ItemsFilter
