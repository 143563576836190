import React, { Component, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Col, Modal, Row } from 'react-bootstrap'
import { Button, FormTextField, Select as CustomSelect, Icon } from '../index'
import FormTextArea from '../inputs/FormTextArea/FormTextArea'
import { faEdit, faFilePdf, faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ButtonIcon from '../buttons/IconButton'
import { isAllowed } from 'src/selectors/modules.selector'
import { selectProductDataSheet } from 'src/selectors/products.selector'
import {
  createDatasheet,
  getDatasheet,
  updateDatasheet,
  getPDFDatasheet,
} from 'src/actions/products.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import { actionTypes } from 'src/actions/products.actions'
import {
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
} from 'src/selectors/error.selector'
import Alert from 'sweetalert-react'

const DataSheet = ({
  modal,
  show,
  onHide,
  onUpdate = undefined,
  id,
  company,
  product,
  update,
  categories,
}) => {
  const dispatch = useDispatch()
  const datasheet = useSelector(selectProductDataSheet)
  const loadingC = useSelector(state =>
    loadingSelector([actionTypes.CREATE_DATASHEET])(state),
  )
  const loadingCHasError = useSelector(state =>
    hasErrorsSelector([actionTypes.CREATE_DATASHEET])(state),
  )

  const loadingU = useSelector(state =>
    loadingSelector([actionTypes.UPDATE_DATASHEET])(state),
  )
  const loadingUHasError = useSelector(state =>
    hasErrorsSelector([actionTypes.UPDATE_DATASHEET])(state),
  )

  const canEdit = useSelector(state => isAllowed(state, [2060, 7110]))

  const [action, setAction] = useState({
    get: false,
    expanded: false,
    filter: false,
  })
  const [alert, setAlert] = useState({ title: '' })

  const [edit, setEdit] = useState(false)
  const [item, setItem] = useState({})
  const [code, setCode] = useState(null)
  const [bars, setBars] = useState([])
  const [cat, setCat] = useState({
    label: 'Seleccionar una categoria',
    value: null,
  })
  const [block, setBlock] = useState(false)
  const [title, setTitle] = useState(null)
  const [create, setCreate] = useState(null)
  const [data, setData] = useState(false)

  useEffect(() => {
    if (item && onUpdate) onUpdate(item)
  }, [item])

  useEffect(() => {
    setItem({ ...item, codes: bars })
    setCode('')
  }, [bars])

  useEffect(() => {
    if (show) {
      if (id) {
        init()
        setTitle('Ficha tecnica del producto')
        setCreate(false)
      } else {
        if (canEdit === true && company === true) {
          setBlock(false)
        } else {
          setBlock(true)
        }
        setTitle('Nueva ficha tecnica')
        setBars([])
        setCreate(true)
      }
    } else {
      setItem({})
    }
  }, [show])

  useEffect(() => {
    setBars(datasheet.codes)
    setItem(datasheet)
  }, [datasheet])

  useEffect(() => {
    setItem({})
    setBars([])
  }, [data])

  const init = () => {
    dispatch(getDatasheet(id))
    setBlock(true)
  }

  useEffect(() => {
    if (loadingC) setAction({ ...action, create: true })
    else if (action.create) {
      setAction({ ...action, create: false })
      if (loadingCHasError)
        setAlert({
          ...handlerError(loadingCHasError.message),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            setBlock(true)
            setCreate(true)
          },
        })
      else
        setAlert({
          ...handlerSuccess('Ficha tecnica creada'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            setBlock(true)
            setEdit(false)
            update()
            init()
          },
        })
    }
  }, [loadingC])

  useEffect(() => {
    if (loadingU) setAction({ ...action, update: true })
    else if (action.update) {
      setAction({ ...action, update: false })
      if (loadingCHasError)
        setAlert({
          ...handlerError(loadingCHasError.message),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            setBlock(true)
            setCreate(true)
          },
        })
      else
        setAlert({
          ...handlerSuccess('Ficha tecnica actualizada'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            setBlock(true)
            setEdit(false)
            init()
          },
        })
    }
  }, [loadingU])

  const onChange = event => {
    const { name, value } = event.target
    setItem({ ...item, [name]: value })
  }

  const barCode = event => {
    const { value } = event.target
    setCode(value)
  }

  const deleteUser = id => {
    setBars(bars.filter(p => id !== p))
  }

  const save = () => {
    if (id) {
      dispatch(updateDatasheet(id, item))
    }
  }

  const render = () => {
    return (
      <Row>
        <Col md={6}>
          <FormTextArea
            label={'Descripción'}
            name={'description'}
            id={'description'}
            rows={'3'}
            value={item.description}
            onChange={onChange}
            required
            disabled={block}
          />
        </Col>
        <Col md={6}>
          <FormTextArea
            label={'Ingredientes'}
            name={'ingredients'}
            id={'ingredients'}
            rows={'3'}
            value={item.ingredients}
            onChange={onChange}
            required
            disabled={block}
          />
        </Col>
        <Col md={6}>
          <FormTextArea
            label={'Datos nutricionales'}
            name={'nutritions'}
            id={'nutritions'}
            rows={'3'}
            value={item.nutritions}
            onChange={onChange}
            required
            disabled={block}
          />
        </Col>
        <Col md={6}>
          <FormTextArea
            label={'Registro sanitario'}
            name={'sanitary'}
            id={'sanitary'}
            rows={'3'}
            value={item.sanitary}
            onChange={onChange}
            required
            disabled={block}
          />
        </Col>
        <Col md={6}>
          <FormTextField
            label={'Codigo de Barra'}
            type={'text'}
            id={'code'}
            name={'code'}
            value={code}
            onChange={barCode}
          />
          <Button
            className={'align-bottom'}
            color={'primary'}
            onClick={() => {
              setBars([...bars, code])
            }}
            disabled={block}>
            <FontAwesomeIcon icon={faSave} /> Agregar codigo
          </Button>
        </Col>
        <Col md={6}>
          <Row className={'pl-1'}>
            {bars &&
              bars.map((p, index) => (
                <div className={'user-tag ml-2 mt-2'} key={index}>
                  <label className={'label-user-tag'}>{p}</label>
                  <ButtonIcon
                    disable={block}
                    className={'delete-user-tag'}
                    icon={faTimes}
                    tooltip={'Quitar'}
                    color={'white'}
                    onClick={() => deleteUser(p)}
                  />
                </div>
              ))}
          </Row>
        </Col>
        <Col md={6}>
          <FormTextArea
            label={'Presentaciones'}
            name={'presentations'}
            id={'presentations'}
            rows={'3'}
            value={item.presentations}
            onChange={onChange}
            required
            disabled={block}
          />
        </Col>
        <Col md={6}>
          <FormTextArea
            label={'Embalaje'}
            name={'packaging'}
            id={'packaging'}
            rows={'3'}
            value={item.packaging}
            onChange={onChange}
            required
            disabled={block}
          />
        </Col>
        <Col md={6}>
          <FormTextField
            label={'Tiempo de vida'}
            type={'text'}
            id={'lifetime'}
            name={'lifetime'}
            value={item.lifetime}
            onChange={onChange}
            required
            disabled={block}
          />
        </Col>
        <Col md={6}>
          <FormTextField
            label={'Almacenamiento'}
            type={'text'}
            id={'storage'}
            name={'storage'}
            value={item.storage}
            onChange={onChange}
            required
            disabled={block}
          />
        </Col>
        <Col md={6}>
          <FormTextField
            label={'Codificacion'}
            type={'text'}
            id={'codification'}
            name={'codification'}
            value={item.codification}
            onChange={onChange}
            required
            disabled={block}
          />
        </Col>
        <Col md={6}>
          <CustomSelect
            options={
              categories && categories.children
                ? categories.children.map(t => ({
                    value: t.id,
                    label: t.name,
                  }))
                : []
            }
            label={'Categorizacion'}
            value={cat}
            onChange={value => {
              setItem({ ...item, categorization: value.value })
              setCat({ ...value })
            }}
            required
            disabled={block}
          />
        </Col>
        <Col md={12}>
          <FormTextArea
            label={'Comentarios'}
            name={'commentary'}
            id={'commentary'}
            rows={'4'}
            value={item.commentary}
            onChange={onChange}
            required
            disabled={block}
          />
        </Col>
        <Alert {...alert} />
      </Row>
    )
  }

  const renderButtons = () => (
    <div>
      {create === true ? (
        <Row className={'container-buttons'}>
          <Button
            color={'primary'}
            icon={faSave}
            loading={loadingC}
            onClick={() => {
              dispatch(createDatasheet(product, item))
            }}>
            Crear
          </Button>
        </Row>
      ) : !edit ? (
        <Row className={'container-buttons'}>
          <Button
            onClick={() => {
              dispatch(getPDFDatasheet(id))
            }}>
            <Icon icon={faFilePdf} tooltip={'Descargar en PDF'} />
          </Button>
          <Button
            color={'info'}
            icon={faEdit}
            onClick={() => {
              setEdit(true)
              setBlock(!block)
            }}>
            Editar
          </Button>
        </Row>
      ) : (
        <Row className={'container-buttons'}>
          <Button
            color={'primary'}
            icon={faSave}
            loading={loadingU}
            onClick={() => save()}>
            Guardar
          </Button>
          <Button
            color={'secondary'}
            icon={faTimes}
            onClick={() => {
              init()
              setEdit(false)
            }}>
            Cancelar
          </Button>
        </Row>
      )}
    </div>
  )

  return modal ? (
    <Modal
      show={show}
      size={'lg'}
      centered
      onHide={() => {
        setEdit(false)
        setItem({})
        setData(true)
        onHide()
      }}>
      <Modal.Header closeButton>
        <Modal.Title>
          <span>{title}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{render()}</Modal.Body>
      <Modal.Footer>{renderButtons()}</Modal.Footer>
    </Modal>
  ) : (
    render()
  )
}

export default DataSheet
