import React from 'react'
import NumberField, { IProps } from 'src/components/NumberField/NumberField'

/**
 * Number input field supporting decimals
 * @component
 * @props are the same as NumberField's
 */
const MoneyField = (props: IProps) => {
  return <NumberField decimals={2} isMoney appendBefore {...props} />
}

export default MoneyField
