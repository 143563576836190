import './TableOrderPage.scss'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import uuid from 'uuid-random'
import {
  Button,
  Checkbox,
  CustomDate,
  FormText,
  Icon,
  ListTags,
  Paragraph,
  PrintTicket,
  SelectClient,
  SwitchV2,
  Tag,
  Tooltip,
} from 'src/components'
import InvoiceConceptField from 'src/components/InvoiceConceptField/InvoiceConceptField'
import SelectFiscalDocumentSV from 'src/components/custom/SelectFiscalDocumentSV/SelectFiscalDocumentSV'
import Money from 'src/components/Money/Money'
import IdentificationField from 'src/components/IdentificationField/IdentificationField'
import {
  faBuilding,
  faCar,
  faCashRegister,
  faCheck,
  faClock,
  faCreditCard,
  faEdit,
  faFileAlt,
  faFileInvoice,
  faMoneyBill,
  faMoneyCheck,
  faPlus,
  faStepForward,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes as restaurantActions,
  generateOrder,
  getOrderPayments,
  getTableOrder,
  immediatelyInvoice,
  onFreezeTerminal,
  onSaveTableOrderClear,
  onSetBillItemsSelected,
  onSetPrintData,
  onSetSeePreBill,
  onUpdateBillItems,
  onUpdateItems,
  resetPayments,
  setClearValue,
  setDiscountSelected,
  setPendingPayments,
  setSelectedPendingPayment,
  setShowItemDetail,
  setTableOrder,
  updateTableOrderValues,
  updateTipInTableOrder,
} from 'src/actions/restaurant.actions'
import {
  selectAllPOSUser,
  selectBillItems,
  selectBillItemsSelected,
  selectDescription,
  selectDiscounts,
  selectDiscountSelected,
  selectGetTableOrder,
  selectPendingPayments,
  selectPosConfigurationByType,
  selectPOSTabSelected,
  selectSelectedPendingPayment,
  selectSelectedPOSTicket,
  selectTips,
} from 'src/selectors/restaurant.selector'
import { valNit } from 'src/selectors/utilities.selector'
import {
  actionTypes as clientActions,
  onCreateClientStep,
} from 'src/actions/clients.actions'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import {
  onSetModalOrder,
  setModalCreateClient,
  setModalEditClient,
  types,
} from 'src/actions/utilities.actions'
import { isAllowed } from 'src/selectors/modules.selector'

import { formatDateFromMillis } from 'src/utils/formatters'
import {
  getPOSDetails,
  itemTypes,
  printGiftTicket,
  printTicket,
  toMoney,
} from 'src/utils/utilities'
import { selectCurrentCompany } from 'src/selectors/user.selector'
import { posPanelConfigurations } from 'src/enums/posPanelConfigurations'
import { terminalTypesEnum } from 'src/enums/terminalTypes'
import { showAlert } from 'src/actions/alert.actions'

import {
  actionTypes as tActions,
  onCreateTransactionFlag,
  onSetTransactionUuId,
} from 'src/actions/transaction.action'
import {
  selectTransactionFlag,
  selectTransactionUuIdFlag,
} from 'src/selectors/transaction.selector'

import {
  selectCompanyCountry,
  selectCompanyFieldById,
  selectCompanyFieldValue,
  selectIdentificationTypes,
} from 'src/selectors/company.selector'
import { Country } from 'src/enums/countries'
import { posDetailStatus, posTypesEnum } from 'src/enums/posTypes'
import companyFields, { companyFieldsFel } from 'src/enums/companyFields'
import { PaymentType } from 'src/enums/paymentTypes'
import { BankDetails } from 'src/content/Orders/OrderCreate/BankDetails'
import useDecimalNumber from 'src/hooks/useDecimalNumber'
import useFormatTimeAgo from 'src/hooks/useFormatTimeAgo'
import { identificationTypesEnum } from 'src/enums/identificationTypes'
import { haveAnyValue, validateMultipleIdentifications } from 'src/utils/utilitiesV2'
import { selectAllPrinters } from 'src/selectors/printer.selector'

import TableOrderItemSelected from './TableOrderItemSelected'
import { invoiceTicketByInstructions } from 'src/components/Printer/TicketEpson'
import RangeDateModal from 'src/components/modal/Shared/RangeDateModal'
import { Col } from 'react-bootstrap'
import useTaxBehavior from 'src/hooks/useTaxBehavior'
import { felDocumentType } from 'src/enums/felDocumentTypes'
import usePOSCorrelative from 'src/content/Restaurant/hooks/usePOSCorrelative'
import CashInformation from 'src/components/inputs/CashInformation/CashInformation'
import { sendPrintInstructions } from 'src/actions/printer.actions'
import { PrintInstructionId, PrintInstructionType } from 'src/enums/printerEnum'

const TableOrderBill = ({
  onShowQR,
  modal = false,
  dontShow = false,
  initClient = false,
}) => {
  const getDecimalNumber = useDecimalNumber()
  const formatTimeAgo = useFormatTimeAgo(true)

  const [cash, setCash] = useState(0)
  const [cashReturned, setCashReturned] = useState(0)
  const [startedAt] = useState(new Date().valueOf())

  const correlative = usePOSCorrelative()
  const uuId = useSelector(selectTransactionUuIdFlag)
  const flagResponse = useSelector(selectTransactionFlag)
  const loadingUuid = useSelector(state =>
    loadingSelector([tActions.ON_CREATE_TRANSACTION_FLAG])(state),
  )
  const hasErrorUuid = useSelector(state =>
    loadingSelector([tActions.ON_CREATE_TRANSACTION_FLAG])(state),
  )

  const textRef = useRef(null)

  const dispatch = useDispatch()

  const country = useSelector(selectCompanyCountry)
  const isGT = country?.id === Country.GT
  const isSV = country?.id === Country.SV
  const isHN = country?.id === Country.HN

  const companyId = useSelector(selectCurrentCompany)
  const tableOrder = useSelector(selectGetTableOrder)
  const identificationTypes = useSelector(selectIdentificationTypes)

  const posUser = useSelector(selectAllPOSUser)
  const pos = posUser.find(pos => pos.id === tableOrder.posId)
  const pendingPayments = useSelector(selectPendingPayments)
  const selectedPendingPayment = useSelector(selectSelectedPendingPayment)

  const { parsedValue: totalAmount } = getDecimalNumber(tableOrder.amount, {
    decimals: 2,
  })
  const { parsedValue: totalPayment } = getDecimalNumber(
    pendingPayments.reduce((total, payment) => total + payment.amount, 0),
    { decimals: 2 },
  )

  const selectedTab = useSelector(selectPOSTabSelected)
  const selectedTicket = useSelector(selectSelectedPOSTicket)

  const cardSurcharge = useSelector(state =>
    selectCompanyFieldValue(state, companyFields.cardSurcharge),
  )
  const automaticCardSurcharge = useSelector(state =>
    selectCompanyFieldValue(state, companyFields.automaticCardSurcharge),
  )

  const canUseTips = useSelector(state =>
    selectPosConfigurationByType(state, posPanelConfigurations.tips),
  )
  const canUseDiscounts = useSelector(state =>
    selectPosConfigurationByType(state, posPanelConfigurations.discounts),
  )
  const canUsePaymentCash = useSelector(state =>
    selectPosConfigurationByType(state, posPanelConfigurations.paymentCash),
  )
  const canUsePaymentCredit = useSelector(state =>
    selectPosConfigurationByType(state, posPanelConfigurations.paymentCredit),
  )
  const canUsePaymentCard = useSelector(state =>
    selectPosConfigurationByType(state, posPanelConfigurations.paymentCard),
  )
  const canUsePaymentTransfer = useSelector(state =>
    selectPosConfigurationByType(state, posPanelConfigurations.paymentTransfer),
  )
  const canUsePayPreBill = useSelector(state =>
    selectPosConfigurationByType(state, posPanelConfigurations.payPreBill),
  )
  const canUsePayBill = useSelector(state =>
    selectPosConfigurationByType(state, posPanelConfigurations.payBill),
  )
  const canUsePayOrder = useSelector(state =>
    selectPosConfigurationByType(state, posPanelConfigurations.payOrder),
  )
  const canUsePayReceipt = useSelector(state =>
    selectPosConfigurationByType(state, posPanelConfigurations.payReceipt),
  )
  const canUsePayActionPaymentMultiple = useSelector(state =>
    selectPosConfigurationByType(state, posPanelConfigurations.actionPaymentMultiple),
  )
  const canUseTimer = useSelector(state =>
    selectPosConfigurationByType(state, posPanelConfigurations.timer),
  )

  const canUseIdentifications = useSelector(state =>
    identificationTypes.reduce((acc, identificationType) => {
      acc[identificationType.id] = selectPosConfigurationByType(
        state,
        posPanelConfigurations[`identification${identificationType.id}`],
      )
      return acc
    }, {}),
  )

  const canUseConcepts =
    useSelector(state =>
      selectPosConfigurationByType(state, posPanelConfigurations.concepts),
    ) && pos?.changeConcept

  const prints = useSelector(selectAllPrinters)
  const tips = useSelector(selectTips)
  const discounts = useSelector(selectDiscounts)
  const billItems = useSelector(selectBillItems)

  const selected = useSelector(selectBillItemsSelected)
  const description = useSelector(selectDescription)

  const felConfiguration = useSelector(state =>
    selectCompanyFieldById(state, companyFieldsFel.useFEL),
  )

  const useFEL = felConfiguration?.value === '1'

  const disabledButtons = useSelector(state =>
    loadingSelector([types.GET_MODAL_ORDER, tActions.ON_CREATE_TRANSACTION_FLAG])(state),
  )

  const loadingGet = useSelector(state =>
    loadingSelector([
      restaurantActions.GET_TABLE_ORDER,
      restaurantActions.SET_PRODUCT_STATUS,
    ])(state),
  )

  const generate = useSelector(state => state.restaurant.generate)
  const loading = useSelector(state =>
    loadingSelector([
      restaurantActions.GENERATE_ORDER,
      restaurantActions.GENERATE_ORDER_IMMEDIATELY,
    ])(state),
  )
  const hasError = useSelector(state =>
    hasErrors([
      restaurantActions.GENERATE_ORDER,
      restaurantActions.GENERATE_ORDER_IMMEDIATELY,
    ])(state),
  )

  const canDiscount = useSelector(state => isAllowed(state, [2203]))
  const fastBill = useSelector(state => isAllowed(state, [2205]))

  const payments = [
    { value: 1, label: 'Efectivo', icon: faMoneyBill, hide: !canUsePaymentCash },
    {
      value: 2,
      label: 'Crédito',
      icon: faMoneyCheck,
      hide: !canUsePaymentCredit || companyId === 1147,
    },
    { value: 6, label: 'Tarjeta', icon: faCreditCard, hide: !canUsePaymentCard },
    {
      value: PaymentType.BANK_TRANSFER,
      label: 'Transferencia',
      icon: faBuilding,
      hide: !canUsePaymentTransfer,
    },
  ]

  const [concept, setConcept] = useState({})
  const [identifications, setIdentifications] = useState({})
  const [actions, setActions] = useState({
    invoice: false,
    update: false,
    concepts: false,
    flag: false,
  })
  const [credit, setCredit] = useState(false)
  const [client, setClient] = useState({
    value: 0,
    label: '3000CA - Consumidor Final',
    nit: 'CF',
    code: '3000CA',
  })

  const [fiscalDocumentSelected, setFiscalDocument] = useState(null)
  const [payment, setPayment] = useState({})
  const [applyCardSurcharge, setApplyCardSurcharge] = useState(automaticCardSurcharge)
  const [itemDetail, setItemDetail] = useState({})
  const discountSelected = useSelector(selectDiscountSelected)
  const [invoiceAmount, setInvoiceAmount] = useState({ amount: 0, concept: null })
  const [orderFlags, setOrderFlags] = useState({
    immediateBilling: false,
    withoutInventory: false,
    dontCertificate: false,
  })
  const [bankData, setBankData] = useState({
    show: false,
    transferDetail: null,
  })
  const [showModalDateCreedit, setShowModalDateCreedit] = useState(false)
  const [showDateSelectorForCredit, setShowDateSelectorForCredit] = useState(false)
  const [paymentDate, setPaymentDate] = useState()

  const handlePaymentDate = value => {
    setPaymentDate(value)
  }

  const toFreeze = !tableOrder.credit && credit
  const isImmediatelyInvoice =
    tableOrder?.stepItems === 4 ||
    tableOrder.tableType === terminalTypesEnum.DELIVERY.value
  const isNewClient = client.code === '3000CA' || client.clientInfo === null

  const canSetCardSurcharge =
    isGT && payment.value === PaymentType.CARD && cardSurcharge > 0

  const setClientCF = () => {
    if (!isGT) {
      const obj = {
        id: {
          id: isSV
            ? identificationTypesEnum.NIT_SV
            : isHN
            ? identificationTypesEnum.RTN
            : identificationTypes.IDE,
          value: null,
        },
      }

      const identifications = Object.entries(obj).reduce((o, [, value]) => {
        const { id, value: val } = value
        o[id] = { identificationId: id, value: val }
        return o
      }, {})

      setIdentifications(identifications)
    }

    setClient({
      value: 0,
      label: 'Consumidor Final',
      nit: 'CF',
      code: '3000CA',
    })
  }

  useEffect(() => {
    if (payment?.value === 2 && selectedPendingPayment === null) {
      setShowModalDateCreedit(true)
    }
  }, [payment])
  useEffect(() => {
    if (!initClient) return
    setClientCF()
  }, [initClient])

  /** Check if the order was already confirmed once
   * @function
   * */
  useEffect(() => {
    if (loadingUuid) setActions({ ...actions, flag: true })
    else if (actions.flag) {
      setActions({ ...actions, flag: false })
      if (hasErrorUuid) {
        dispatch(showAlert(handlerError(hasErrorUuid.message)))
        setFiscalDocument(null)
      } else {
        if (flagResponse) generatePayment(orderFlags)
        else
          dispatch(
            showAlert(
              handlerError(
                `ePos: ${new Date().valueOf()}: No se pudo realizar la operación, intente de nuevo.`,
              ),
            ),
          )
      }
    }
  }, [loadingUuid])

  useEffect(() => {
    if (!tableOrder.credit || credit) return
    dispatch(getOrderPayments(tableOrder.id))
    setFiscalDocument(tableOrder.felDocumentType)
  }, [tableOrder.credit])

  useEffect(() => {
    if (dontShow) return
    if (loading) setActions({ ...actions, invoice: true })
    else if (actions.invoice) {
      setActions({ ...actions, invoice: false })
      if (hasError) {
        const noInventory = hasError?.message?.indexOf('No hay suficiente') >= 0
        if (noInventory && isImmediatelyInvoice) {
          let message = hasError.message
          message +=
            '\n¿Desea continuar sin descontar existencia? El inventario que no se descuente podrá ser despachado con la función de despacho pendiente en la aplicación de bodegas.'
          dispatch(
            showAlert({
              ...handlerError(message),
              showCancelButton: true,
              cancelButtonText: 'Cancelar',
              confirmButtonColor: '#B35796',
              confirmButtonText: 'Continuar',
              onConfirm: () => {
                generatePayment({ immediateBilling: true, withoutInventory: true })
              },
            }),
          )
        } else
          dispatch(
            showAlert({
              ...handlerError(hasError.message),
              onConfirm: () => {
                dispatch(onSetTransactionUuId(uuid()))
              },
            }),
          )
      } else {
        if (credit) {
          let newPendingPayments = [...pendingPayments]

          if (isImmediatelyInvoice)
            newPendingPayments = newPendingPayments.map(p => ({
              ...p,
              id: true,
              client,
            }))
          else
            newPendingPayments[selectedPendingPayment] = {
              ...newPendingPayments[selectedPendingPayment],
              id: true,
              client,
              paymentType: payment.value,
            }

          dispatch(setPendingPayments(newPendingPayments))
        }

        setClientCF()
        setPayment({})
        dispatch(setDiscountSelected({ value: null, tipValue: null, id: null }))
        setInvoiceAmount({ amount: 0, concept: null })
        const { invoices, orderId, orderSellNumber } = generate

        dispatch(
          showAlert({
            ...handlerSuccess('Operación realizada con éxito'),
            onConfirm: () => {
              let printInstructions = []
              if (invoices?.length > 0)
                printInstructions = invoices[0].printInstructions || []
              dispatch(
                sendPrintInstructions(
                  PrintInstructionId.POS,
                  PrintInstructionType.SELL,
                  printInstructions,
                ),
              )

              setTimeout(() => {
                setFiscalDocument(null)
                if (modal) dispatch(onSetSeePreBill(false))

                const container = document.getElementById('ticket-invoice-by-pos')

                const seeOrderDetail = tableOrder.seeOrderDetail

                if (payment.value === 100) {
                  onShowQR()
                  dispatch(getTableOrder(tableOrder.tableId))
                } else if (invoices && invoices.length > 0) {
                  if (prints && prints.length > 0) {
                    let logoContext = null
                    if (pos?.activeInvoiceTicketsLogo) {
                      const canvas = document.getElementById('invoice-canvas')
                      const context = canvas.getContext('2d')
                      const img = document.getElementById('invoice-ticket-logo')
                      if (img) context.drawImage(img, 0, 0, 300, 175)
                      logoContext = context
                    }

                    const data = invoiceTicketByInstructions(
                      printInstructions,
                      logoContext,
                    )

                    let address = ''
                    const items = []

                    let customData = null
                    let itemsPrint = null
                    if (prints.filter(pt => pt.invoice).length > 0) {
                      itemsPrint = prints
                        .filter(pt => pt.invoice)
                        .map(pt => {
                          if (pt.drawerType) {
                            data.push({ type: 12, value: pt.drawerType })
                          }
                          return { ...pt, data }
                        })
                    } else customData = data
                    if (tableOrder.printGift) {
                      data.push(
                        ...printGiftTicket({
                          name: tableOrder.posName,
                          address,
                          reference: orderSellNumber,
                          date: new Date().valueOf(),
                          items,
                        }),
                      )
                    }

                    dispatch(
                      onSetPrintData({
                        show: true,
                        customData,
                        itemsPrint,
                        content: container?.innerHTML,
                        size: 'sm',
                        onClose: () => {
                          if (orderId) {
                            if (!seeOrderDetail) dispatch(setClearValue(true))
                            else dispatch(onSetModalOrder(orderId))
                          } else dispatch(getTableOrder(tableOrder.tableId))
                        },
                        logo: pos?.activeInvoiceTicketsLogo && pos?.ticketLogo,
                      }),
                    )
                  } else {
                    printTicket(
                      container?.innerHTML,
                      null,
                      null,
                      (pos.activeInvoiceTicketsLogo && pos.ticketsLogo) || 'undefined',
                    )
                    if (orderId) {
                      if (!seeOrderDetail) dispatch(setClearValue(true))
                      else dispatch(onSetModalOrder(orderId))
                    } else dispatch(getTableOrder(tableOrder.tableId))
                  }
                } else if (orderId) {
                  if (!seeOrderDetail) dispatch(setClearValue(true))
                  else dispatch(onSetModalOrder(orderId))
                } else {
                  dispatch(onSetTransactionUuId(uuid()))
                  dispatch(getTableOrder(tableOrder.tableId))
                }
              }, 400)
            },
          }),
        )
      }
    }
  }, [loading])

  useEffect(() => {
    if (dontShow) return
    setCredit(tableOrder?.credit)
  }, [tableOrder?.credit])

  useEffect(() => {
    if (dontShow) return
    setApplyCardSurcharge(automaticCardSurcharge)
  }, [automaticCardSurcharge])

  useEffect(() => {
    if (dontShow) return
    if (loadingGet) setActions({ ...actions, update: true })
    else if (actions.update) {
      setActions({ ...actions, update: false })
      dispatch(
        setDiscountSelected({
          id: tableOrder.discount?.id || discountSelected.id,
          value: tableOrder?.discount?.val || discountSelected.value,
          tipValue: tableOrder?.tip?.val || discountSelected.tipValue,
          tipId: tableOrder.tip?.id || discountSelected.tipId,
        }),
      )
      setItemDetail({})
    }
  }, [loadingGet])

  useEffect(() => {
    if (dontShow) return
    if (selectedPendingPayment === null) return

    const newPayment = pendingPayments[selectedPendingPayment]
    setPayment(
      payments.find(
        paymentType => newPayment && paymentType.value === newPayment.paymentType,
      ) || {},
    )

    textRef.current?.focus()
  }, [selectedPendingPayment])

  const actionTransactionFlag = () => {
    const request = {
      documentType: itemTypes.POS,
      documentId: tableOrder?.id,
      documentCode: tableOrder?.alphanumericCode,
      uuId,
    }

    if (
      payment?.value === 2 &&
      showDateSelectorForCredit &&
      (paymentDate === undefined || paymentDate === null)
    ) {
      dispatch(showAlert(handlerError('Debe ingresar una fecha de Pago en Credito')))
    } else if (
      payment?.value === 2 &&
      showModalDateCreedit &&
      (paymentDate === undefined || paymentDate === null)
    ) {
      dispatch(showAlert(handlerError('Debe ingresar una fecha de Pago en Credito')))
    } else if (
      payment?.value === 2 &&
      (paymentDate === undefined || paymentDate === '')
    ) {
      dispatch(showAlert(handlerError('Debe ingresar una fecha de pago')))
    } else {
      dispatch(onCreateTransactionFlag(request))
    }
  }

  const generatePayment = params => {
    const { immediateBilling, withoutInventory, dontCertificate } = params

    let cashReceived = 0,
      cashReturnedRequest = 0

    let request = {}

    if (credit) {
      let amount
      let pendingPaymentsRequest

      if (isImmediatelyInvoice) {
        pendingPaymentsRequest = pendingPayments?.map(pp => ({
          amount: pp.amount,
          paymentType: pp.paymentType,
          cashReceived: pp.cashReceived,
          cashReturned: pp.cashReturned,
        }))
        amount = totalAmount
      } else {
        const pendingPayment = pendingPayments[selectedPendingPayment] || {}

        amount = pendingPayment.amount || 0
        pendingPaymentsRequest = []

        if (payment.value === PaymentType.CASH) {
          cashReceived = pendingPayment.cashReceived
          cashReturnedRequest = pendingPayment.cashReturned
        }
      }

      request = {
        cashReceived,
        cashReturned: cashReturnedRequest,
        amount,
        tableOrder: tableOrder.id,
        products: [],
        client: client.label,
        nit: client.nit,
        clientId: client.value,
        cui: client.cui,
        code: '',
        description:
          tableOrder.posType === posTypesEnum.PARKING.type
            ? tableOrder.tableNumber
            : invoiceAmount.concept,
        paymentType: payment.value,
        pendingPayments: pendingPaymentsRequest,
        credit: true,
        payment: true,
        tipValue: tipAmount,
        discount: discountAmount,
        tableId: tableOrder.tableId,
        conceptId: concept.value,
        identifications: Object.values(identifications),
        applyCardSurcharge: canSetCardSurcharge && applyCardSurcharge,
        transferDetail: bankData.transferDetail,
        felDocumentType: fiscalDocumentSelected,
      }
    } else {
      if (payment.value === PaymentType.CASH && cash > 0) {
        cashReceived = cash
        cashReturnedRequest = cashReturned
      }

      request = {
        cashReceived,
        cashReturned: cashReturnedRequest,
        amount: 0,
        tip: false,
        percent: 0,
        tableOrder: tableOrder.id,
        products: [],
        client: client.label,
        nit: client.nit,
        clientId: client.value,
        cui: client.cui,
        email: client.email,
        address: client.address,
        phone: client.phone,
        reference: client.reference,
        code: '',
        paymentType: payment.value,
        immediateBilling,
        tipValue: tipAmount,
        discount: discountAmount,
        tableId: tableOrder.tableId,
        dontCertificate,
        conceptId: concept.value,
        description:
          tableOrder.posType === posTypesEnum.PARKING.type
            ? tableOrder.tableNumber
            : null,
        identifications: Object.values(identifications),
        applyCardSurcharge: canSetCardSurcharge && applyCardSurcharge,
        transferDetail: bankData.transferDetail,
        felDocumentType: fiscalDocumentSelected,
      }

      groupedItems
        .filter(
          item => isSelected(item) && item?.status?.id === posDetailStatus.ENTREGADA,
        )
        .forEach(item => {
          if (item.subItems) {
            item.subItems.forEach(si => {
              request.amount += si.subtotal
              request.products.push({
                productId: si.id,
                price: si.subtotal,
                discount: 0,
                orderDetailId: si.detailId,
                commentary:
                  tableOrder.posType === posTypesEnum.PARKING.type
                    ? tableOrder.tableNumber
                    : null,
              })
            })
          } else {
            request.amount += item.subtotal
            request.products.push({
              productId: item.id,
              price: item.subtotal,
              discount: 0,
              orderDetailId: item.detailId,
              commentary:
                tableOrder.posType === posTypesEnum.PARKING.type
                  ? tableOrder.tableNumber
                  : null,
            })
          }
        })
    }

    if (isImmediatelyInvoice) {
      if (!showModalDateCreedit) {
        const hasPaymentType2 = request?.pendingPayments?.some(
          item => item.paymentType === 2,
        )
        if (hasPaymentType2 && !showDateSelectorForCredit) {
          request = Object.assign({}, request, {
            paymentDateOrderCredit: paymentDate
              ? paymentDate.valueOf()
              : new Date().valueOf(),
          })
        }
      }
      if (request?.paymentType === 2) {
        request = Object.assign({}, request, {
          paymentDateOrderCredit: paymentDate
            ? paymentDate.valueOf()
            : new Date().valueOf(),
        })
      }

      dispatch(
        immediatelyInvoice(
          {
            ...request,
            itemsToCommand,
            products: [],
            correlative,
          },
          { withoutInventory },
        ),
      )
    } else {
      if (request?.paymentType === 2) {
        request = Object.assign({}, request, {
          paymentDateOrderCredit: paymentDate
            ? paymentDate.valueOf()
            : new Date().valueOf(),
        })
      }

      dispatch(generateOrder(request, dontCertificate, true))
    }
  }

  const getItems = () => {
    const items =
      selectedTab.value === 0
        ? billItems
        : billItems.filter(item => (item.tag || 'Sin ticket') === selectedTicket) || []

    const groupedItems = []

    items.sort((a, b) => a.payed - b.payed)

    const itemsToGroup = [
      ...items.filter(item => !item.parentId),
      ...items.filter(item => item.parentId),
    ]

    itemsToGroup.forEach((item, index) => {
      const toLocal = !item.parentId && !item.status

      if (!toLocal) {
        const responseItem = groupedItems.find(responseItem => {
          if (responseItem.detailId === item.parentId) return true
          return (
            responseItem.id === item.id &&
            item.status &&
            responseItem.status &&
            item.status.id === responseItem.status.id &&
            item.suppliesText === responseItem.suppliesText &&
            item.commentary === responseItem.commentary
          )
        })

        if (responseItem) {
          responseItem.quantity += item.parentId ? 0 : item.quantity
          responseItem.subtotal += item.subtotal
          responseItem.discount += item.discount
          responseItem.subItems.push(item)
          if (item.parentId)
            responseItem.subSupplies.push({
              suppliesText: item.suppliesText,
              extra: item.extra,
              status: item.status,
            })
        } else
          groupedItems.push({
            ...item,
            subItems: [item],
            customId: index,
            subSupplies: [],
          })
      } else groupedItems.push({ ...item, customId: index })
    })

    return groupedItems
  }

  const updateQuantityItem = item => {
    const index = billItems.findIndex(bi => bi.groupValue === item.groupValue)
    if (item.deleted) billItems.splice(index, 1)
    else {
      const billItem = billItems[index]
      billItem.quantity = item.quantity
      billItem.subtotal = item.quantity * (item.price + (item.extra || 0))
      billItem.discount = item.discount
      billItems[index] = billItem
    }
    dispatch(onUpdateBillItems(billItems))
  }

  const groupedItems = getItems()
  const itemsToCommand = getPOSDetails(groupedItems)

  const variableTip = (customAmount, isDiscount) => {
    if (customAmount > 0) {
      let tip = null
      if (isDiscount && (tableOrder.discount?.id || discountSelected.id) && discounts)
        tip = discounts.find(
          t => t.id === (tableOrder.discount?.id || discountSelected.id),
        )
      else if (!isDiscount && (tableOrder.tip?.id || discountSelected.tipId) && tips)
        tip = tips.find(t => t.id === (tableOrder.tip?.id || discountSelected.tipId))
      if (tip) {
        const decimal = tip.percentage * 0.01
        return Number.parseFloat(customAmount * decimal).toFixed(2)
      }
    }
    return 0
  }

  const newDiscount = (isTip, customAmount) => {
    return Number.parseFloat(
      (isTip ? discountSelected.tipValue : discountSelected.value) ||
        variableTip(customAmount || getSubtotal(), isTip === false),
    )
  }

  const getTips = (customAmount, isDiscount) => {
    const it = isDiscount ? discounts : tips

    return (
      (isDiscount ? canUseDiscounts : canUseTips) && (
        <div>
          <label className={'ftf-form-label'}>
            Agregar {isDiscount ? 'descuento' : 'propina'}
          </label>
          <div className={'d-flex'} style={{ 'overflow-y': 'auto' }}>
            <Tag
              isNumber
              editable
              value={newDiscount(!isDiscount, customAmount)}
              onChange={a => {
                a = isNaN(a) ? 0 : Number(a)

                if (a && a < 0) a = 0
                if (isDiscount) {
                  if (a > customAmount) a = customAmount
                  dispatch(setDiscountSelected({ ...discountSelected, value: a }))
                } else dispatch(setDiscountSelected({ ...discountSelected, tipValue: a }))
              }}
              dataCy={`tips-${isDiscount ? 'descuento' : 'propina'}`}
              limitValue={isDiscount ? customAmount : undefined}
            />
            <ListTags
              toggle
              tags={it.map(it => {
                const selected = isDiscount
                  ? discountSelected.value
                    ? false
                    : discountSelected.id === it.id
                  : discountSelected.tipValue
                  ? false
                  : discountSelected.tipId === it.id
                return { ...it, value: it.id, label: `${it.percentage}%`, selected }
              })}
              onChange={tipValue => {
                let tipIdValue = tipValue.value
                if (isDiscount) {
                  if (discountSelected.id === tipValue.value) {
                    dispatch(
                      setDiscountSelected({ ...discountSelected, id: null, value: null }),
                    )
                    tipIdValue = null
                  }
                } else if (discountSelected.tipId === tipValue.value) {
                  dispatch(
                    setDiscountSelected({
                      ...discountSelected,
                      tipId: null,
                      tipValue: null,
                    }),
                  )
                  tipIdValue = null
                }
                if (!isImmediatelyInvoice && tableOrder.id) {
                  return dispatch(
                    updateTipInTableOrder(tableOrder.id, {
                      tipId: tipIdValue,
                      discount: isDiscount,
                    }),
                  )
                }
                const values = tipIdValue === null ? null : tipValue
                const tip = isDiscount ? { ...tableOrder.tip } : values
                const discount = isDiscount ? values : { ...tableOrder.discount }
                if (isDiscount) tip.val = discountSelected.tipValue
                else discount.val = discountSelected.value

                dispatch(
                  updateTableOrderValues({
                    ...tableOrder,
                    tip,
                    discount,
                  }),
                )
              }}
            />
          </div>
        </div>
      )
    )
  }

  const handleChangeClient = (newClient, identifications) => {
    setClient({
      ...newClient,
      label: `${newClient.code} - ${newClient.companyName}`,
      value: newClient.id,
    })

    setIdentifications(identifications)
  }

  const isSelected = (item, custom) => {
    return (
      isImmediatelyInvoice ||
      (custom >= 0
        ? item.customId === custom
        : selected.find(s => item.customId === s) >= 0)
    )
  }

  const getPendant = withDiscount => {
    let response = totalAmount || 0
    if (withDiscount) {
      response -= newDiscount(false, response)
    }
    return response
  }

  const getSubtotal = (withDiscount, withTip) => {
    let response = 0
    if (!credit && groupedItems.every(i => !isSelected(i))) {
      groupedItems.forEach(i => (response += i.subtotal - i.discount))
    } else if (!credit || (isImmediatelyInvoice && toFreeze)) {
      groupedItems
        .filter(item => isSelected(item))
        .forEach(item => {
          response += item.subtotal - item.discount
        })
    } else if (toFreeze) response = totalAmount
    else if (credit && isImmediatelyInvoice) {
      response = groupedItems.reduce(
        (amount, item) => amount + (item.subtotal - item.discount),
        0,
      )
    } else response = totalPayment || 0

    if (withDiscount && (!credit || toFreeze)) response -= newDiscount(false)
    if (withTip) response += newDiscount(true, response)
    return response
  }

  const subtotalAmount = getSubtotal()
  const discountAmount = newDiscount(false)
  const subtotalWithoutTaxes = subtotalAmount - discountAmount

  const individualTax = discountAmount / (groupedItems.length || 1)
  const itemsFormatted = groupedItems.map(i => ({
    quantity: i.quantity,
    price: i.price,
    discount: i.discount + individualTax,
    taxRate: i.taxRate,
  }))

  const subtotalIVA = useTaxBehavior(fiscalDocumentSelected, itemsFormatted)

  const totalWithVariables = subtotalWithoutTaxes + subtotalIVA

  const tipAmount = newDiscount(true, totalWithVariables)
  const cardSurchargeAmount =
    canSetCardSurcharge && applyCardSurcharge
      ? ((totalWithVariables + tipAmount) * Number(cardSurcharge)) / 100
      : 0
  const total = totalWithVariables + tipAmount + cardSurchargeAmount

  const validateIfAllItemsAreCorrect = () => {
    if (credit || isImmediatelyInvoice) return true

    const itemsTOValidate = []

    groupedItems
      .filter(item => isSelected(item))
      .forEach(item => {
        if (item.subItems) {
          item.subItems.forEach(si => {
            itemsTOValidate.push(si)
          })
        } else {
          itemsTOValidate.push(item)
        }
      })
    if (itemsTOValidate.length === 0) return false

    return itemsTOValidate.every(item => item.status?.id === posDetailStatus.ENTREGADA)
  }

  const isPaymentDisabled = isPreBill => {
    let clientValid

    if (!isGT)
      clientValid =
        fiscalDocumentSelected === felDocumentType.INVOICE ||
        validateMultipleIdentifications(Object.values(identifications))
    else {
      clientValid =
        valNit(client.nit) ||
        haveAnyValue(client.cui) ||
        validateMultipleIdentifications(Object.values(identifications))
    }

    const allItemsAreCorrect = validateIfAllItemsAreCorrect()

    const toDisabledButtons = disabledButtons || !allItemsAreCorrect

    if (
      toDisabledButtons ||
      getSubtotal(true) <= 0 ||
      (!isPreBill && !payment.value) ||
      (!isPreBill && !clientValid) ||
      (!credit && groupedItems.every(i => !isSelected(i))) ||
      (credit && !isImmediatelyInvoice && selectedPendingPayment === null) ||
      (credit &&
        isImmediatelyInvoice &&
        (totalPayment !== totalAmount || pendingPayments.some(p => !p.paymentType)))
    )
      return true

    return (
      credit &&
      !isImmediatelyInvoice &&
      pendingPayments[selectedPendingPayment] &&
      (pendingPayments[selectedPendingPayment].amount <= 0 ||
        !pendingPayments[selectedPendingPayment].paymentType ||
        pendingPayments[selectedPendingPayment].id)
    )
  }

  const renderFiscalDocumentSV = isSV && (
    <SelectFiscalDocumentSV
      selectedMode
      value={fiscalDocumentSelected}
      loading={loading}
      validatePosConfiguration
      initChange
      onChange={value => {
        setFiscalDocument(value)
      }}
    />
  )

  const renderCreditDateSelector = () => {
    return (
      <CustomDate
        onDayChange={date => {
          setPaymentDate(date)
        }}
        label={'Fecha de pago'}
        required
        disabledDays={{ before: new Date() }}
      />
    )
  }

  const getDateForSave = () => {
    let actualDate

    if (payment?.value === 2) {
      actualDate = formatDateFromMillis(paymentDate)
    } else {
      actualDate = formatDateFromMillis(tableOrder.createdAt || new Date().valueOf())
    }

    return actualDate
  }

  const removeItemSelected = item => {
    const immediatelyWithToken = pos?.clearToken && isImmediatelyInvoice
    if (immediatelyWithToken) {
      onUpdateItemsSelected([{ ...item, deleted: true }])
    } else {
      const is = billItems.filter(bi => bi.groupValue !== item.groupValue)
      dispatch(onUpdateBillItems(is))
    }
  }

  const onUpdateItemsSelected = itemsEdited => {
    const updateRequestRemote = itemsEdited
      .filter(i => i.detailId)
      .map(i => ({
        productId: i.detailId,
        deleted: i.deleted,
        discount: i.discount,
      }))

    const seeInventory = updateRequestRemote.some(ur => ur.deleted)
    const updateRequest = { updated: updateRequestRemote }
    const action = (returnInventory, byLink) => {
      itemsEdited.forEach(i => updateQuantityItem(i))

      if (tableOrder.id) {
        dispatch(
          onUpdateItems(tableOrder.id, {
            ...updateRequest,
            returnInventory,
            byLink,
          }),
        )
      }
      setItemDetail({})
    }

    const immediatelyWithToken = pos?.clearToken && isImmediatelyInvoice
    if (!tableOrder.id && !immediatelyWithToken) {
      action(false)
    } else {
      dispatch(
        onSaveTableOrderClear({
          all: false,
          action,
          items: updateRequestRemote,
          seeInventory,
        }),
      )
    }
  }

  const renderCreditPayment = () => {
    const paymentPending = haveAnyValue(selectedPendingPayment)
      ? pendingPayments[selectedPendingPayment]
      : {}

    return (
      <>
        {isImmediatelyInvoice && (
          <label className={'ftf-form-label'}>
            Nota: Todos los pagos se realizarán a la vez.
          </label>
        )}
        <FormText
          data-cy={'amount-pay'}
          autoFocus
          required
          ref={textRef}
          disabled={haveAnyValue(paymentPending.id)}
          label={`Monto del pago${
            selectedPendingPayment === null ? '' : ` ${selectedPendingPayment + 1}`
          }`}
          value={paymentPending.amount || 0}
          type={'number'}
          prependMoneySymbol
          changeValue={amount => {
            amount = amount ? Number(amount) : 0
            amount = Number.parseFloat(amount.toFixed(2))
            if (amount < 0) amount = 0

            const pendingPaymentsAmount = pendingPayments
              .filter((ignore, i) => i !== selectedPendingPayment)
              .reduce((total, payment) => total + payment.amount, 0)

            const limit = Number((totalAmount - pendingPaymentsAmount).toFixed(5))

            const newPendingPayments = [...pendingPayments]

            if (amount > limit) amount = limit

            paymentPending.amount = amount

            newPendingPayments[selectedPendingPayment] = paymentPending
            dispatch(setPendingPayments(newPendingPayments))
          }}
        />
        <label className={'ftf-form-label'}>
          Tipo de pago
          <span style={{ color: 'red', marginRight: '2px' }}>*</span>
        </label>

        <div className={'pos-bill-buttons'}>
          {payments
            .filter(p => !p.hide)
            .map(p => (
              <Button
                key={p.id}
                disabled={
                  haveAnyValue(paymentPending.id) ||
                  (p.value === PaymentType.BANK_TRANSFER &&
                    payment.value !== p.value &&
                    pendingPayments.some(
                      p => p.paymentType === PaymentType.BANK_TRANSFER,
                    ))
                }
                color={payment.value === p.value ? 'primary' : 'accent'}
                icon={p.icon}
                dataCy={`button-${p.label.toLowerCase()}`}
                onClick={() => {
                  setPayment(p)

                  if (p?.value === 2) {
                    setShowDateSelectorForCredit(true)
                  } else {
                    setShowDateSelectorForCredit(false)
                    setPaymentDate(null)
                  }
                  if (selectedPendingPayment !== null) {
                    paymentPending.paymentType = p.value

                    const newPendingPayments = [...pendingPayments]
                    newPendingPayments[selectedPendingPayment] = paymentPending
                    dispatch(setPendingPayments(newPendingPayments))
                  }
                }}>
                {p.label}
              </Button>
            ))}
        </div>

        {paymentPending.paymentType === PaymentType.CASH && (
          <CashInformation
            amount={paymentPending.amount}
            received={paymentPending.cashReceived}
            onChange={(received, returned) => {
              paymentPending.cashReceived = received
              paymentPending.cashReturned = returned

              const newPendingPayments = [...pendingPayments]
              newPendingPayments[selectedPendingPayment] = paymentPending
              dispatch(setPendingPayments(newPendingPayments))
            }}
            disabled={haveAnyValue(paymentPending.id)}
          />
        )}

        {showDateSelectorForCredit && <Col xl={12}>{renderCreditDateSelector()}</Col>}
      </>
    )
  }

  //formatDateFromMillis(tableOrder.createdAt || new Date().valueOf())
  return (
    <div className={`pos-bill-container ${modal ? 'pos-bill-container-modal' : ''}`}>
      <div className={'pos-bill-header'}>
        <div className={'space-between'}>
          <div>{getDateForSave()}</div>
          {canUseTimer && (
            <div>
              {tableOrder.createdAt
                ? formatTimeAgo(tableOrder.createdAt)
                : isImmediatelyInvoice
                ? formatTimeAgo(startedAt)
                : 'Sin iniciar'}
            </div>
          )}
        </div>
        <div className={'b-user-email'}>{description}</div>
      </div>

      <div className={'pos-bill-body'}>
        {toFreeze ? (
          <>
            <div className={'b-user-name no-transform'}>
              - Se requiere congelar la cuenta antes de realizar los pagos mixtos.
            </div>

            <div className={'b-user-name no-transform'}>
              - Ya no será posible agregar más artículos a la cuenta después de
              congelarse.
            </div>

            <div className={'b-user-name no-transform'}>
              - La terminal se libera hasta que se realice el último pago a la cuenta.
            </div>
            <SelectClient
              actionType={clientActions.GET_CLIENT_POLYGON}
              className="mt-0"
              label="Cliente"
              value={client}
              onChange={handleChangeClient}
              onCreateOption={handleChangeClient}
              init
              allClients
            />
            {isGT && (
              <>
                {canUseIdentifications[identificationTypesEnum.NIT_GT] && (
                  <IdentificationField
                    name={'nit'}
                    identificationTypeId={identificationTypesEnum.NIT_GT}
                    value={client.nit}
                    onChange={nit => setClient({ ...client, nit })}
                    onSearch={(client, identifications) => {
                      setClient({
                        ...client,
                        label: client.clientName,
                        companyName: client.clientName,
                        businessName: client.clientName,
                        nit: client.NIT,
                        cui: client.cui,
                      })
                      setIdentifications(identifications)
                    }}
                  />
                )}
                {canUseIdentifications[identificationTypesEnum.CUI] && (
                  <IdentificationField
                    name={'cui'}
                    identificationTypeId={identificationTypesEnum.CUI}
                    value={client.cui}
                    onChange={cui => setClient({ ...client, cui })}
                    onSearch={(client, identifications) => {
                      setClient({
                        ...client,
                        label: client.clientName,
                        companyName: client.clientName,
                        businessName: client.clientName,
                        nit: client.NIT,
                        cui: client.cui,
                      })
                      setIdentifications(identifications)
                    }}
                  />
                )}
              </>
            )}
            {identificationTypes
              .filter(
                identificationType =>
                  identificationType.id !== identificationTypesEnum.NIT_GT &&
                  identificationType.id !== identificationTypesEnum.CUI &&
                  canUseIdentifications[identificationType.id],
              )
              .map(identificationType => (
                <IdentificationField
                  key={identificationType.id}
                  name={identificationType.name.toLowerCase()}
                  identificationTypeId={identificationType.id}
                  value={identifications[identificationType.id]?.value}
                  onChange={value => {
                    const newIdentifications = { ...identifications }
                    newIdentifications[identificationType.id] = {
                      identificationTypeId: identificationType.id,
                      value,
                    }

                    setIdentifications(newIdentifications)
                  }}
                />
              ))}
            {renderFiscalDocumentSV}
            {canUseConcepts && (
              <InvoiceConceptField
                posId={pos.id}
                value={concept}
                onChange={newConcept => {
                  if (!newConcept) return
                  setConcept(newConcept)
                }}
              />
            )}
            {canDiscount && <div>{getTips(getSubtotal(), true)}</div>}

            <div>{getTips(getPendant(true), false)}</div>
          </>
        ) : (
          <>
            {credit ? (
              renderCreditPayment()
            ) : (
              <>
                <div style={{ display: 'flex', alignItems: 'flex-end', gap: 10 }}>
                  <Button
                    onClick={() => {
                      if (isNewClient) {
                        dispatch(
                          setModalCreateClient({
                            show: true,
                            onCreateOption: handleChangeClient,
                          }),
                        )
                        dispatch(
                          onCreateClientStep({
                            companyName: client.companyName,
                            businessName: client.companyName,
                            nit: client.nit,
                            identifications,
                          }),
                        )
                      } else
                        dispatch(
                          setModalEditClient({
                            show: true,
                            id: client.id || client.clientInfo?.id,
                            onSave: handleChangeClient,
                          }),
                        )
                    }}
                    icon={isNewClient ? faPlus : faEdit}
                    tooltip={`${isNewClient ? 'Crear' : 'Editar'} cliente`}
                    style={{ margin: 0, height: 38, width: 38 }}
                  />
                  <SelectClient
                    actionType={clientActions.GET_CLIENT_POLYGON}
                    className="mt-0"
                    label="Cliente"
                    value={client}
                    onChange={handleChangeClient}
                    onCreateOption={handleChangeClient}
                    init
                    allClients
                  />
                </div>
                <RangeDateModal
                  handlePaymentDate={handlePaymentDate}
                  show={showModalDateCreedit}
                  onHide={() => setShowModalDateCreedit(false)}
                />

                {isGT && (
                  <>
                    {canUseIdentifications[identificationTypesEnum.NIT_GT] && (
                      <IdentificationField
                        name={'nit'}
                        identificationTypeId={identificationTypesEnum.NIT_GT}
                        value={client.nit}
                        onChange={nit => setClient({ ...client, nit })}
                        onSearch={(client, identifications) => {
                          setClient({
                            ...client,
                            label: client.clientName,
                            companyName: client.clientName,
                            businessName: client.clientName,
                            nit: client.NIT,
                            cui: client.cui,
                          })
                          setIdentifications(identifications)
                        }}
                      />
                    )}
                    {canUseIdentifications[identificationTypesEnum.CUI] && (
                      <IdentificationField
                        name={'cui'}
                        identificationTypeId={identificationTypesEnum.CUI}
                        value={client.cui}
                        onChange={cui => {
                          setClient({ ...client, cui })

                          const newIdentifications = { ...identifications }
                          newIdentifications[identificationTypesEnum.CUI] = {
                            identificationTypeId: identificationTypesEnum.CUI,
                            value: cui,
                          }

                          setIdentifications(newIdentifications)
                        }}
                        onSearch={(client, identifications) => {
                          setClient({
                            ...client,
                            label: client.clientName,
                            companyName: client.clientName,
                            businessName: client.clientName,
                            nit: client.NIT,
                            cui: client.cui,
                          })
                          setIdentifications(identifications)
                        }}
                      />
                    )}
                  </>
                )}
                {identificationTypes
                  .filter(
                    identificationType =>
                      identificationType.id !== identificationTypesEnum.NIT_GT &&
                      identificationType.id !== identificationTypesEnum.CUI &&
                      canUseIdentifications[identificationType.id],
                  )
                  .map(identificationType => (
                    <IdentificationField
                      key={identificationType.id}
                      name={identificationType.name.toLowerCase()}
                      identificationTypeId={identificationType.id}
                      value={identifications[identificationType.id]?.value}
                      onChange={value => {
                        const newIdentifications = { ...identifications }
                        newIdentifications[identificationType.id] = {
                          identificationTypeId: identificationType.id,
                          value,
                        }

                        setIdentifications(newIdentifications)
                      }}
                    />
                  ))}
                {canUseConcepts && (
                  <InvoiceConceptField
                    posId={pos.id}
                    value={concept}
                    onChange={newConcept => {
                      if (!newConcept) return
                      setConcept(newConcept)
                    }}
                  />
                )}
                <label className={'ftf-form-label'}>
                  Tipo de pago
                  <span style={{ color: 'red', marginRight: 2 }}>*</span>
                </label>
                <div className={'pos-bill-buttons'}>
                  {payments
                    .filter(p => !p.hide)
                    .map(p => (
                      <Button
                        key={p.id}
                        style={{
                          flex:
                            payments.filter(p => !p.hide).length % 3 !== 1
                              ? '1'
                              : 'calc(50% - 5px)',
                        }}
                        color={payment.value === p.value ? 'primary' : 'accent'}
                        icon={p.icon}
                        onClick={() => {
                          if (p.value === PaymentType.BANK_TRANSFER)
                            setBankData({ ...bankData, show: true })
                          setPayment(p)
                        }}>
                        {p.label}
                      </Button>
                    ))}
                </div>
                {canUsePayActionPaymentMultiple && selectedTab.value !== 1 && (
                  <Button
                    className={'w-100'}
                    color={'secondary'}
                    id={'abonar-pagos'}
                    loading={loading}
                    disabled={
                      billItems.length <= 0 || (!isImmediatelyInvoice && !tableOrder.id)
                    }
                    onClick={() => {
                      setCredit(true)
                      dispatch(onSetBillItemsSelected([]))
                    }}>
                    Pagos mixtos
                  </Button>
                )}
                {canSetCardSurcharge && (
                  <SwitchV2
                    label={`Recargo (${cardSurcharge}%)`}
                    info={'El recargo se aplica al total de la factura.'}
                    checked={applyCardSurcharge}
                    onChange={setApplyCardSurcharge}
                  />
                )}

                {groupedItems.length > 0 && (
                  <>
                    {!isImmediatelyInvoice && (
                      <div className={'d-flex b-user-name'}>
                        <Checkbox
                          label={'Seleccionar todos'}
                          checked={
                            groupedItems.filter(i => isSelected(i)).length ===
                            groupedItems.filter(i => i.status && i.status.id === 4).length
                          }
                          onChange={({ target }) => {
                            const { checked } = target
                            const toSelect = []
                            if (checked)
                              toSelect.push(
                                ...groupedItems
                                  .filter(i => i.status && i.status.id === 4)
                                  .map(i => i.customId),
                              )

                            dispatch(onSetBillItemsSelected(toSelect))
                          }}
                        />
                      </div>
                    )}
                    <div className={'pos-bill-items'}>
                      {groupedItems.map((i, index) => {
                        const disabled = i.payed || !i.status || i.status.id !== 4
                        const checked = isSelected(i)

                        let statusIcon
                        let statusColor = '#12a8bb'
                        let statusName
                        if (i.payed) {
                          statusName = 'Pagado'
                          statusIcon = faCheck
                          statusColor = '#8d9'
                        } else if (i.status) {
                          if (i?.status?.id === 3 && tableOrder?.posType === 2) {
                            statusName = 'Entregar Vehículo'
                            statusIcon = faCar
                          } else {
                            statusName = i.status.name
                            if (statusName === 'Entregada') {
                              statusIcon = faCashRegister
                            } else if (statusName === 'En Preparación') {
                              statusIcon = faClock
                            } else if (statusName === 'En Espera') {
                              statusIcon = faClock
                            } else {
                              statusIcon = faStepForward
                            }
                          }
                        } else {
                          statusName = isImmediatelyInvoice
                            ? 'Por facturar'
                            : 'Por comandar'
                          statusIcon = faStepForward
                        }

                        const itemSubtotal = i.subtotal
                        const itemDiscount = i.discount
                        const subtotalWithDiscount = itemSubtotal - itemDiscount
                        const tax = 0 // withTaxDetailed ? getTaxes(subtotalWithDiscount, country.id) : 0

                        const itemTotal = subtotalWithDiscount + tax

                        const seeDetails = tax + itemDiscount > 0

                        return (
                          <div
                            key={index}
                            className={`pos-product b-item ${disabled ? '' : 'enabled'}`}
                            onClick={() => {
                              if (disabled) return

                              if (checked)
                                dispatch(
                                  onSetBillItemsSelected(
                                    selected.filter(s => !isSelected(i, s)),
                                  ),
                                )
                              else {
                                dispatch(
                                  onSetBillItemsSelected([...selected, i.customId]),
                                )
                              }
                            }}>
                            <div className={'top-bar space-between'}>
                              {!isImmediatelyInvoice && (
                                <Checkbox
                                  disabled={disabled}
                                  checked={checked}
                                  className={'checkbox'}
                                />
                              )}
                              <div className="icons">
                                {!i.status && (
                                  <Icon
                                    icon={faTrash}
                                    color={'secondary'}
                                    tooltip={'Eliminar'}
                                    onClick={ev => {
                                      ev.stopPropagation()
                                      removeItemSelected(i)
                                    }}
                                  />
                                )}
                                <Icon
                                  icon={faEdit}
                                  color={'accent'}
                                  tooltip={'Editar'}
                                  onClick={ev => {
                                    const subItems = i.subItems || [i]

                                    ev.stopPropagation()
                                    setItemDetail({
                                      ...i,
                                      show: true,
                                      subItems: subItems.map(subItem => ({
                                        ...subItem,
                                        originalQuantity: subItem.quantity,
                                      })),
                                      simple: i.supplies?.length === 0,
                                    })
                                  }}
                                />
                                {i.openVariation && (
                                  <Icon
                                    icon={faPlus}
                                    color={'accent'}
                                    tooltip={'Agregar variaciones'}
                                    onClick={ev => {
                                      ev.stopPropagation()
                                      dispatch(
                                        setShowItemDetail({
                                          ...i,
                                          status: null,
                                          parentId: i.detailId,
                                          quantity: 1,
                                          price: 0,
                                          show: true,
                                          add: true,
                                          existence: 0,
                                          seeExistence: false,
                                        }),
                                      )
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                            <div className={'space-between'}>
                              <Paragraph bold>{i.name}</Paragraph>
                              {!i.requiresAmountOnly && (
                                <Paragraph bold>{i.quantity} u</Paragraph>
                              )}
                            </div>
                            <Paragraph>{i.suppliesText}</Paragraph>
                            {i.subSupplies?.length > 0 && (
                              <div>
                                <Paragraph bold>Detalles</Paragraph>
                                {i.subSupplies.map((s, index) => (
                                  <div className={'d-flex'} key={index}>
                                    <Paragraph>
                                      ({s.status?.name || 'Por comandar'}
                                      {s.extra > 0 && `, +${toMoney(s.extra)}`}){' '}
                                      {s.suppliesText}
                                    </Paragraph>
                                  </div>
                                ))}
                              </div>
                            )}

                            <div className={'space-between'}>
                              <div className={'column'}>
                                <Paragraph>subtotal</Paragraph>
                                {seeDetails && (
                                  <div className={'column'}>
                                    {itemDiscount > 0 && <Paragraph>Descuento</Paragraph>}
                                    {tax > 0 && <Paragraph>iva</Paragraph>}
                                    <Paragraph>Total</Paragraph>
                                  </div>
                                )}
                              </div>
                              <div className={'column'}>
                                <Paragraph bold>{toMoney(itemSubtotal)}</Paragraph>
                                {seeDetails && (
                                  <div className={'column'}>
                                    {itemDiscount > 0 && (
                                      <Paragraph bold>{toMoney(itemDiscount)}</Paragraph>
                                    )}
                                    {tax > 0 && (
                                      <Paragraph bold>{toMoney(tax)}</Paragraph>
                                    )}
                                    <Paragraph bold>{toMoney(itemTotal)}</Paragraph>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className={'status-bar space-between'}>
                              <div className="b-user-name" />
                              <div className="status">
                                <div className="b-user-email">{statusName}</div>
                                <Icon
                                  icon={statusIcon}
                                  color={statusColor}
                                  style={{ cursor: 'default' }}
                                />
                              </div>
                            </div>
                            {i.commentary && (
                              <div className={'commentary b-user-email'}>
                                {i.commentary}
                              </div>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </>
                )}
                {canDiscount && getTips(credit ? totalAmount : getSubtotal(), true)}
                {getTips(getSubtotal(true), false)}

                {payment?.value === PaymentType.CASH && (
                  <CashInformation
                    amount={total}
                    received={cash}
                    onChange={(received, returned) => {
                      setCash(received)
                      setCashReturned(returned)
                    }}
                    disabled={
                      credit &&
                      selectedPendingPayment !== null &&
                      pendingPayments[selectedPendingPayment] &&
                      pendingPayments[selectedPendingPayment].id
                    }
                  />
                )}
              </>
            )}

            <div
              style={{
                display: 'block',
              }}>
              <BankDetails
                onUpdate={bankDetails =>
                  setBankData({ ...bankData, transferDetail: bankDetails })
                }
                onActive={active => setBankData({ ...bankData, active })}
                active={bankData.active}
                bankDetails={bankData.transferDetail}
                modal={!(payment.value === PaymentType.BANK_TRANSFER && credit)}
                show={bankData.show}
                onHide={() => setBankData({ ...bankData, show: false })}
              />
            </div>
          </>
        )}
      </div>
      {
        <div className={'pos-bill-footer'}>
          {!tableOrder.credit && (
            <>
              {renderFiscalDocumentSV}

              {subtotalAmount > 0 && (
                <div className={'space-between'}>
                  <div>Subtotal</div>
                  <Money>{subtotalAmount}</Money>
                </div>
              )}
              {discountAmount > 0 && (
                <Tooltip
                  placement="bottom"
                  content={
                    <>
                      <div>Subtotal con descuento</div>
                      {toMoney(subtotalAmount - discountAmount)}
                    </>
                  }>
                  <div className={'space-between'}>
                    Descuento
                    <Money>{discountAmount}</Money>
                  </div>
                </Tooltip>
              )}

              {subtotalIVA !== 0 && (
                <div className={'space-between'}>
                  <div>IVA</div>
                  <Money>{subtotalIVA}</Money>
                </div>
              )}

              {subtotalIVA + discountAmount !== 0 && (
                <div
                  className={'space-between'}
                  style={{ paddingTop: 5, borderTop: '2px solid #ddd' }}>
                  <div>Subtotal</div>
                  <Money>{totalWithVariables}</Money>
                </div>
              )}

              {tipAmount > 0 && (
                <Tooltip
                  placement="bottom"
                  content={
                    <>
                      <div>Subtotal con propina</div>
                      {toMoney(subtotalAmount - discountAmount + tipAmount)}
                    </>
                  }>
                  <div className={'space-between'}>
                    Propina
                    <Money>{tipAmount}</Money>
                  </div>
                </Tooltip>
              )}
              {cardSurchargeAmount > 0 && (
                <Tooltip
                  placement="bottom"
                  content={
                    <>
                      <div>Subtotal con recargo por tarjeta</div>
                      {toMoney(
                        subtotalAmount - discountAmount + tipAmount + cardSurchargeAmount,
                      )}
                    </>
                  }>
                  <div className={'space-between'}>
                    Recargo por tarjeta
                    <Money>{cardSurchargeAmount}</Money>
                  </div>
                </Tooltip>
              )}
              {total > 0 && (
                <div
                  className={'space-between'}
                  style={{ fontSize: 16, paddingTop: 5, borderTop: '2px solid #ddd' }}>
                  <div>Total</div>
                  <Money>{total}</Money>
                </div>
              )}
            </>
          )}
          <div className={'pos-bill-footer-buttons'}>
            {toFreeze ? (
              <>
                <Button
                  style={{ flexBasis: '100%' }}
                  className={'w-100'}
                  loading={loadingGet}
                  dataCy={'submit-congelar'}
                  disabled={isSV && !fiscalDocumentSelected}
                  onClick={() =>
                    dispatch(
                      showAlert({
                        show: true,
                        title: 'Congelar cuenta',
                        text: '¿Estás seguro de congelar la cuenta? Ya no será posible agregar más artículos a la cuenta y tampoco se podrán modificar los valores de descuento y propina.',
                        type: 'warning',
                        showCancelButton: true,
                        cancelButtonText: 'Cancelar',
                        confirmButtonColor: '#226095',
                        confirmButtonText: 'Congelar',
                        onConfirm: () => {
                          setPayment({})
                          dispatch(resetPayments())
                          if (isImmediatelyInvoice) {
                            dispatch(
                              setTableOrder({
                                credit: true,
                                amount: total,
                                total: total,
                              }),
                            )
                          } else
                            dispatch(
                              onFreezeTerminal(tableOrder.id, {
                                client: client.label,
                                nit: client.nit,
                                clientId: client.value,
                                cui: client.cui,
                                code: '',
                                tip: tipAmount,
                                discount: discountAmount,
                                conceptId: concept.value,
                                applyCardSurcharge:
                                  canSetCardSurcharge && applyCardSurcharge,
                                amount: total,
                                felDocumentType: fiscalDocumentSelected,
                              }),
                            )
                        },
                      }),
                    )
                  }>
                  Congelar cuenta
                </Button>
                <Button
                  style={{ flexBasis: '100%' }}
                  className={'w-100'}
                  color={'secondary'}
                  loading={loadingGet}
                  onClick={() => {
                    setCredit(false)
                  }}>
                  Cancelar
                </Button>
              </>
            ) : (
              <>
                {!credit && (
                  <>
                    {canUsePayPreBill && (
                      <PrintTicket
                        {...tableOrder}
                        tax={subtotalIVA}
                        className={'w-100'}
                        tableId={tableOrder.id}
                        pos={pos}
                        selected={groupedItems.filter(i => isSelected(i))}
                        disabled={isPaymentDisabled(true)}
                        tipValue={tipAmount}
                        discount={discountAmount}
                        styleButton={{ flexBasis: '100%' }}
                        onPrintData={data => {
                          dispatch(
                            onSetPrintData({
                              ...data,
                              show: true,
                            }),
                          )
                        }}
                        clientData={client}
                        showDeliveryData={
                          tableOrder.tableType === terminalTypesEnum.DELIVERY.value
                        }
                        canSetCardSurcharge={canSetCardSurcharge}
                        fiscalDocumentSelected={fiscalDocumentSelected}
                      />
                    )}

                    {canUsePayOrder && (
                      <Button
                        className={'w-100'}
                        icon={faFileAlt}
                        color={'secondary'}
                        loading={loading}
                        onClick={() => {
                          setOrderFlags({
                            immediateBilling: false,
                            withoutInventory: false,
                            dontCertificate: false,
                          })
                          actionTransactionFlag()
                        }}
                        disabled={isPaymentDisabled()}
                        dataCy={'submit-orden'}>
                        Orden
                      </Button>
                    )}

                    {canUsePayReceipt && (
                      <Button
                        className={'w-100'}
                        icon={faFileAlt}
                        color={'primary'}
                        loading={loading}
                        onClick={() => {
                          setOrderFlags({
                            immediateBilling: true,
                            withoutInventory: false,
                            dontCertificate: true,
                          })
                          actionTransactionFlag()
                        }}
                        disabled={isPaymentDisabled()}
                        dataCy={'submit-recibo'}>
                        {country.id === Country.UNI ? 'Vender' : 'Recibo'}
                      </Button>
                    )}
                  </>
                )}
                {payment.value !== PaymentType.LINK &&
                  ((canUsePayBill && fastBill && useFEL) || credit) && (
                    <Button
                      className={'w-100'}
                      color={'primary'}
                      icon={faFileInvoice}
                      loading={loading}
                      disabled={isPaymentDisabled()}
                      style={{ flex: 1 }}
                      onClick={() => {
                        setOrderFlags({
                          immediateBilling: true,
                          withoutInventory: false,
                          dontCertificate: false,
                        })
                        actionTransactionFlag()
                      }}
                      dataCy={`submit-pagar-facturar`}>
                      {!credit
                        ? isSV
                          ? 'DTE'
                          : 'Facturar'
                        : isImmediatelyInvoice
                        ? 'Pagar todo'
                        : pendingPayments.filter(p => !p.id).length === 1 &&
                          tableOrder.total - totalPayment === 0
                        ? 'Terminar de pagar'
                        : 'Pagar'}
                    </Button>
                  )}
                {credit && (
                  <Button
                    style={{ flexBasis: '100%' }}
                    className={'w-100'}
                    color={'danger'}
                    loading={loading}
                    dataCy={'remover-pago'}
                    disabled={
                      selectedPendingPayment === null ||
                      !pendingPayments[selectedPendingPayment] ||
                      pendingPayments[selectedPendingPayment].id
                    }
                    onClick={() => {
                      if (selectedPendingPayment === null) return
                      const newPendingPayments = [...pendingPayments]
                      newPendingPayments.splice(selectedPendingPayment, 1)

                      let newSelectedPendingPayment = selectedPendingPayment
                      if (newPendingPayments.length === 0)
                        newSelectedPendingPayment = null
                      else if (newSelectedPendingPayment === newPendingPayments.length)
                        newSelectedPendingPayment--

                      if (
                        newSelectedPendingPayment &&
                        newPendingPayments[newSelectedPendingPayment].id
                      )
                        newSelectedPendingPayment = null

                      dispatch(setSelectedPendingPayment(newSelectedPendingPayment))
                      dispatch(setPendingPayments(newPendingPayments))

                      if (modal) dispatch(onSetSeePreBill(false))
                    }}>
                    Remover pago
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      }

      <TableOrderItemSelected
        show={itemDetail.show}
        item={itemDetail}
        tableOrder={tableOrder}
        isImmediatelyInvoice={isImmediatelyInvoice}
        onClose={() => setItemDetail({})}
        onSave={itemsEdited => {
          onUpdateItemsSelected(itemsEdited)
        }}
      />
    </div>
  )
}

export default TableOrderBill
