import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import { Button, FormText, MoneyField } from 'src/components'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  handlerSuccess,
  hasErrors,
  singleErrorSelector,
} from 'src/selectors/error.selector'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { showAlert } from 'src/actions/alert.actions'
import CustomSelect from 'src/components/inputs/Select/CustomSelect'
import { selectModalCreateCashTransfer } from 'src/selectors/utilities.selector'
import { setModalCreateCashTransfer } from 'src/actions/utilities.actions'
import { selectUsers } from 'src/selectors/modules.selector'
import { getUsersChildrenByModule } from 'src/actions/modules.actions'
import { selectCurrentUser } from 'src/selectors/user.selector'
import { selectBalanceInCompany } from 'src/selectors/balance.selector'
import {
  actionTypes as balanceActions,
  createCashTransfer,
} from 'src/actions/balance.actions'

interface IActions {
  create?: boolean
}

const defaultCashTransfer: ICashTransfer = {
  concept: '',
  amount: 0,
}

export interface IModalCreateCashTransfer {
  show: boolean
}

const defaultModalCreate: IModalCreateCashTransfer = {
  show: false,
}

/**
 * @component ModalCreateCashTransfer
 * @description Component to create a cash transfer via modal
 */
const ModalCreateCashTransfer = () => {
  const dispatch = useDispatch()

  const [actions, setActions] = useState<IActions>({ create: false })
  const [cashTransfer, setCashTransfer] = useState<ICashTransfer>(defaultCashTransfer)

  const users = useSelector(selectUsers)
  const currentUser = useSelector(selectCurrentUser)
  const userBalance = useSelector(selectBalanceInCompany)
  const { show }: IModalCreateCashTransfer = useSelector(selectModalCreateCashTransfer)

  const usersOptions = users.filter(user => user.id !== currentUser.id)
  const user = usersOptions.find(user => user.id === cashTransfer.targetUserId) || {
    value: null,
    label: 'Selecciona un usuario',
  }
  const actualCash = Math.abs(userBalance.balanceTransportist)

  const loadingCreate = useSelector(state =>
    loadingSelector([balanceActions.CREATE_CASH_TRANSFER])(state),
  )
  const hasErrorCreate = useSelector(state =>
    hasErrors([balanceActions.CREATE_CASH_TRANSFER])(state),
  )
  const errorCreate = useSelector(state =>
    singleErrorSelector([balanceActions.CREATE_CASH_TRANSFER])(state),
  )

  useEffect(() => {
    setCashTransfer(defaultCashTransfer)
    if (usersOptions.length === 0) dispatch(getUsersChildrenByModule(1000, true))
  }, [show])

  useEffect(() => {
    if (loadingCreate) setActions({ ...actions, create: true })
    else if (actions.create) {
      setActions({ ...actions, create: false })
      if (hasErrorCreate)
        dispatch(
          showAlert({
            ...handlerError(errorCreate.message),
          }),
        )
      else {
        dispatch(
          showAlert({
            ...handlerSuccess('Transferencia de efectivo creada exitosamente'),
            onConfirm: () => dispatch(setModalCreateCashTransfer(defaultModalCreate)),
          }),
        )
      }
    }
  }, [loadingCreate])

  return (
    <Modal
      show={show}
      size="lg"
      centered
      onHide={() => dispatch(setModalCreateCashTransfer(defaultModalCreate))}>
      <Modal.Header closeButton>
        <Modal.Title>Crear transferencia de efectivo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={6}>
            <MoneyField
              label={'Efectivo circulante actual'}
              name={'actual-cash'}
              value={actualCash}
              disabled
            />
          </Col>
          <Col xs={6}>
            <MoneyField
              label={'Efectivo circulante final'}
              name={'final-cash'}
              value={actualCash - cashTransfer.amount}
              disabled
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Col xs={6}>
            <CustomSelect
              label={'Transferir efectivo a'}
              style={{ margin: 0 }}
              mt={0}
              value={user}
              options={usersOptions}
              onChange={user =>
                setCashTransfer({
                  ...cashTransfer,
                  targetUserId: user.id,
                })
              }
            />
          </Col>
          <Col xs={6}>
            <MoneyField
              label={'Monto a transferir'}
              name={'amount-cash'}
              value={cashTransfer.amount}
              onValueChange={amount => setCashTransfer({ ...cashTransfer, amount })}
              max={actualCash}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormText
              label={'Concepto de transferencia'}
              type={'text'}
              name={'concept'}
              value={cashTransfer.concept}
              onChange={e =>
                setCashTransfer({ ...cashTransfer, concept: e.target.value })
              }
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className={'container-buttons'}>
          <Button
            loading={loadingCreate}
            icon={faSave}
            onClick={() => {
              dispatch(createCashTransfer(cashTransfer))
            }}
            dataCy={'concept-create-button'}>
            Crear
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalCreateCashTransfer
