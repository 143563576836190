function createBank(id: number): IBank {
  // Return an IBank object with the provided ID. You can customize this further by fetching additional bank details based on the ID.
  return {
    id,
    countryId: 0, // Initialize as needed
    deleted: false, // Initialize as needed
    description: null, // Initialize as needed
    name: '', // Initialize as needed
    value: 0, // Initialize as needed
    label: '', // Initialize as needed
  }
}

function createCurrency(id: number): Currency {
  // Return a Currency object with the provided ID. You can customize this further by fetching additional currency details based on the ID.
  return {
    id,
    code: '', // Initialize as needed
    name: '', // Initialize as needed
    pluralName: '', // Initialize as needed
    symbol: '', // Initialize as needed
    symbolSpace: '', // Initialize as needed
    value: 0, // Initialize as needed
    label: '', // Initialize as needed
  }
}
function transformData(data: any): IBankAccount {
  const transformedData: IBankAccount = {
    accountNumber: data.accountNumber,
    active: data.active,
    asigned: data.asigned,
    balance: data.balance,
    bank: {
      id: data.bank?.id ?? 0,
      countryId: data.bank?.countryId ?? 0,
      deleted: data.bank?.deleted ?? false,
      description: data.bank?.description ?? null,
      name: data.bank?.name ?? '',
      value: data.bank?.value ?? 0,
      label: data.bank?.label ?? '',
    },
    company: data.company,
    createdAt: data.createdAt,
    createdBy: data.createdBy,
    createdData: data.createdData,
    currency: {
      id: data.currency?.id ?? 0,
      code: data.currency?.code ?? '',
      name: data.currency?.name ?? '',
      pluralName: data.currency?.pluralName ?? '',
      symbol: data.currency?.symbol ?? '',
      symbolSpace: data.currency?.symbolSpace ?? '',
      value: data.currency?.value ?? 0,
      label: data.currency?.label ?? '',
    },
    deleted: data.deleted,
    deletedAt: data.deletedAt,
    deletedBy: data.deletedBy,
    deletedData: data.deletedData,
    disbursement: data.disbursement,
    editable: data.editable,
    id: data.id,
    kolo: data.kolo,
    module: data.module,
    name: data.name,
    report: data.report,
    toName: data.toName,
    transformation: data.transformation,
    type: {
      id: data.type?.id ?? 0,
      label: data.type?.label ?? '',
    },
    updatedAt: data.updatedAt,
    updatedBy: data.updatedBy,
    updatedData: data.updatedData,
  }

  return transformedData
}
function transformDataUpdatedAccount(data: any): IBankAccount {
  const transformedData: IBankAccount = {
    currency: {
      id: data.currency,
      code: '',
      name: '',
      pluralName: '',
      symbol: '',
      symbolSpace: '',
      value: 0,
      label: '',
    },
    type: {
      id: data.type,
      label: '',
    },
    bank: {
      id: data.bank,
      countryId: 0,
      deleted: false,
      description: null,
      name: '',
      value: 0,
      label: '',
    },
    ...data,
  }

  delete transformedData.currency
  delete transformedData.type
  delete transformedData.bank

  return transformedData
}
function transformAccounts(accounts: any[]): IBankAccount[] {
  return accounts.map(account => {
    return {
      ...account,
      bank: createBank(account.bank), // Convert bank ID to an IBank object
      currency: createCurrency(account.currency), // Convert currency ID to a Currency object
      // Convert the date fields to Date objects if they're numbers
      createdAt: account.createdAt ? new Date(account.createdAt) : null,
      updatedAt: account.updatedAt ? new Date(account.updatedAt) : null,
      deletedAt: account.deletedAt ? new Date(account.deletedAt) : null,
      // Add any other necessary transformations as needed
    }
  })
}
const getImageSrc = bank => {
  const bankImg = [
    '',
    'https://firebasestorage.googleapis.com/v0/b/distribution-app-dev.appspot.com/o/Banks%2F1.png?alt=media&token=5e51f64a-485e-4a01-a41c-468e8cc17d65',
    'https://firebasestorage.googleapis.com/v0/b/distribution-app-dev.appspot.com/o/Banks%2F2.png?alt=media&token=4da6be5a-54d9-41c6-9af2-f013c4d591a5',
    'https://firebasestorage.googleapis.com/v0/b/distribution-app-dev.appspot.com/o/Banks%2F3.png?alt=media&token=27ca0e34-da9c-4f74-a189-872f969b7b1e',
    'https://firebasestorage.googleapis.com/v0/b/distribution-app-dev.appspot.com/o/Banks%2F4.png?alt=media&token=01e7268c-c694-4270-b2b7-368d0e99d241',
  ]
  return bankImg[bank]
}
const getBalance = (includeAcredit, history) => {
  let total = 0

  const movements = history?.movements

  if (!movements) {
    return total
  }

  for (const movement of movements) {
    const subTotal = parseFloat(movement.amount)

    if (includeAcredit) {
      if (movement.refDocument && movement.authorizedBy) {
        total += subTotal
      }
    } else {
      if (!movement.refDocument) {
        total += subTotal
      }
    }
  }

  return total
}
function mergeByBank(map, attribute) {
  const mergedResults = new Map()

  for (const [key, value] of map) {
    const attrValue = key[attribute]

    if (mergedResults.has(attrValue)) {
      const existingValue = mergedResults.get(attrValue)
      mergedResults.set(attrValue, existingValue.concat(value))
    } else {
      mergedResults.set(attrValue, value)
    }
  }

  return mergedResults
}
function groupBy(items, key) {
  const map = new Map()
  items.forEach(item => {
    const keyValue = item[key]

    const currArr = map.has(keyValue) ? map.get(keyValue) : []

    currArr.push(item)

    map.set(keyValue, currArr)
  })
  return map
}

export {
  createBank,
  createCurrency,
  transformData,
  transformAccounts,
  getImageSrc,
  getBalance,
  mergeByBank,
  transformDataUpdatedAccount,
  groupBy,
}
