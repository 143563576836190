import React, { Component } from 'react'
import CreatableSelect from 'react-select/creatable'
import AsyncCreatableSelect from 'react-select/async-creatable'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'
import { faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import '../Select/CustomSelect.scss'
import IconButton from '../../buttons/IconButton'

const selectStyle = {
  menuPortal: base => ({ ...base, zIndex: 9999 }),
}

export default class CustomCreate extends Component {
  state = {
    i: '',
  }

  function = inputValue => (this.props.textLabel || '') + inputValue

  promiseOptions = inputValue =>
    new Promise(resolve => {
      this.setState({ i: inputValue })
      setTimeout(() => {
        if (this.state.i.length === inputValue.length) {
          resolve(this.props.loadOptions(inputValue))
        }
      }, 1000)
    })

  render() {
    const {
      subText,
      alias,
      dontNbsp,
      error,
      required,
      label,
      style,
      className,
      withoutLabel,
      info,
      mt,
      async,
      dontClear,
      disabled,
      dataCy,
      noOptionsMessage,
    } = this.props
    return (
      <div
        className={`input-wrapper has-feedback column ${className} ${
          mt !== undefined ? `mt-${mt}` : 'mt-3'
        }`}
        style={style}
        data-cy={dataCy}>
        {withoutLabel ? (
          ''
        ) : (
          <label className={'ftf-form-label left'}>
            {required && (
              <span
                style={{
                  color: 'red',
                  marginRight: 2,
                }}>
                &nbsp;*{' '}
              </span>
            )}
            {label} &nbsp;
            {info ? (
              <label>
                {' '}
                <IconButton
                  tooltip={info}
                  color={'rgba(34, 96, 149, 0.75)'}
                  icon={faInfoCircle}
                  size={'sm'}
                />{' '}
              </label>
            ) : (
              <label> {!dontNbsp && <>&nbsp;</>} </label>
            )}
          </label>
        )}

        {async ? (
          <AsyncCreatableSelect
            className="basic-single"
            menuPortalTarget={document.body}
            isClearable={!dontClear}
            styles={selectStyle}
            isDisabled={this.props.isLoading || this.props.disabled}
            isLoading={this.props.isLoading}
            onChange={this.props.onChange}
            onCreateOption={this.props.onCreateOption}
            formatCreateLabel={this.function}
            value={this.props.value}
            placeholder={this.props.placeholder}
            cacheOptions
            defaultOptions
            loadOptions={this.promiseOptions}
            noOptionsMessage={() =>
              noOptionsMessage || 'No hay opciones, escribe para crear una nueva'
            }
          />
        ) : (
          <CreatableSelect
            className="basic-single"
            menuPortalTarget={document.body}
            isClearable={!dontClear}
            styles={selectStyle}
            isDisabled={this.props.isLoading || disabled}
            isLoading={this.props.isLoading}
            onChange={this.props.onChange}
            onCreateOption={this.props.onCreateOption}
            options={this.props.options}
            formatCreateLabel={this.function}
            value={this.props.value}
            placeholder={this.props.placeholder}
            noOptionsMessage={() =>
              noOptionsMessage || 'No hay opciones, escribe para crear una nueva'
            }
          />
        )}
        {error ? (
          <div className="input-error">
            <FontAwesomeIcon icon={faExclamationCircle} /> {error}
          </div>
        ) : (
          <div />
        )}
        {alias ? (
          <div style={{ marginLeft: '5px' }} className="input-alias">
            {' '}
            {alias ? 'Alias: '.concat(alias) : ''}{' '}
          </div>
        ) : (
          <div />
        )}
        {subText ? (
          <div style={{ marginLeft: '5px' }} className="input-alias">
            {' '}
            {subText ? subText : ''}{' '}
          </div>
        ) : (
          <div />
        )}
      </div>
    )
  }
}
