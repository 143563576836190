export const selectRecipe = state => state.enumeration.recipe
export const selectEnumeration = state => state.enumeration.enumeration
export const selectEnumerations = state => state.enumeration.enumerations
export const selectEnumerationReport = state => state.enumeration.report
export const selectEnumerationsForSelect = state =>
  state.enumeration.enumerations.map(item => ({
    value: item.id,
    data: item,
    label: item.name + ' - ' + item.description,
  }))
export const selectEnumerationVariablesRecipe = state =>
  state.enumeration.customVariables.map(c => ({ ...c, value: c.id, label: c.name }))

export const selectEnumerationCustomVariable = state => state?.enumeration?.customVariable
