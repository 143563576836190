import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Route, Switch } from 'react-router-dom'
import SecuredContent from './SecuredContent'
import Login from './authentication/Login/Login'

import './Content.scss'
import DistributionRoutes from './Distrbution/DistributionRoutes'
import ProductionRoutes from './Production/ProductionRoutes'
import AdminRoutes from './Admin/AdminRoutes'
import ClientRoutes from './Client/ClientRoutes'

import Alert from 'sweetalert-react'

import { selectAlert } from 'src/selectors/utilities.selector'
import CompanyConfigurations from './Config/CompanyConfigurations'
import CompanyRoutes from './Company/CompanyRoutes'
import Notifications from './Notifications/Notifications'
import BalanceEntries from './Balance/BalanceEntries'
import KoloRoutes from './Kolo/KoloRoutes'
import Maintenance from '../components/maintenance/Maintenance'
import Manuals from './Admin/Manuals/Manuals'
import ManualVideos from './Admin/ManualVideos/ManualVideos'
import Configuration from './Notifications/Configuration'
import InformationV2 from './Admin/Information/InformationV2'
import { selectCurrentUser } from 'src/selectors/user.selector'
import Redirect from './Redirect/Redirect'
import Dashboard from './Dashboard/Dashboard'
import CompanyAccount from './Company/Profile/CompanyAccount'

import ModalWelcome from '../components/ModalWelcome/ModalWelcome'
import EmailConfirmationNotice from '../components/toolbar/EmailConfirmationNotice'
import DemoNotice from '../components/toolbar/DemoNotice'
import { selectSetCurrentCompany } from 'src/selectors/company.selector'
import PlanCheckout from 'src/content/Distrbution/Plans/PlanCheckout'
import InvoicePackageCheckout from 'src/content/Distrbution/Plans/InvoicePackageCheckout'
import UserSpaceCheckout from 'src/content/Distrbution/Plans/UserSpaceCheckout'
import PlanEndedNotice from 'src/components/toolbar/PlanEndedNotice'
import PaymentNotice from 'src/components/toolbar/PaymentNotice'
import { PaymentAccount } from './Distrbution/Plans/PaymentAccount'
import PlaygroundPage from 'src/content/Playground/PlaygroundPage'
import { Env, environment } from 'src/environment'

class Content extends Component {
  render() {
    const { isSignedIn, currentUser, alert, currentCompany } = this.props
    return (
      <Switch>
        <Route
          path={`${process.env.PUBLIC_URL}/login`}
          strict={false}
          component={Login}
        />
        <Route path={`${process.env.PUBLIC_URL}/activation`} component={Redirect} />
        <SecuredContent
          isSignedIn={isSignedIn}
          isOwner={currentCompany?.owner === currentUser?.id}
          isActive={
            Object.entries(currentCompany).length > 0 && 'active' in currentCompany
              ? currentCompany?.active
              : true
          }
          isDefaulter={currentCompany?.defaulter}
          isTest={currentCompany?.test}>
          <div className={'main-content'}>
            <EmailConfirmationNotice isSignedIn={isSignedIn} alwaysShow={false} />
            <DemoNotice isSignedIn={isSignedIn} />
            <PaymentNotice />
            <PlanEndedNotice
              isSignedIn={isSignedIn}
              isOwner={currentCompany?.owner === currentUser?.id}
            />
            <div>
              <Switch>
                <Route
                  path={`${process.env.PUBLIC_URL}/distribucion`}
                  component={DistributionRoutes}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/produccion`}
                  component={ProductionRoutes}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/empresa`}
                  component={CompanyRoutes}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/cliente`}
                  component={ClientRoutes}
                />
                <Route path={`${process.env.PUBLIC_URL}/kolo`} component={KoloRoutes} />

                <Route
                  path={`${process.env.PUBLIC_URL}/perfil/configuraciones`}
                  component={CompanyConfigurations}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/perfil/balance`}
                  component={BalanceEntries}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/perfil/cuenta`}
                  component={PaymentAccount}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/perfil`}
                  component={CompanyAccount}
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/checkout/:planId`}
                  component={PlanCheckout}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/invoice-checkout/:invoicePackageId`}
                  component={InvoicePackageCheckout}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/user-space-checkout`}
                  component={UserSpaceCheckout}
                />

                <Route path={`${process.env.PUBLIC_URL}/admin`} component={AdminRoutes} />
                {/*<Route path={`${process.env.PUBLIC_URL}/documentos`} component={DocumentsRoutes}/>*/}
                {/*<Route path={`${process.env.PUBLIC_URL}/promotor`} component={PromotorRoutes}/>*/}
                <Route
                  path={`${process.env.PUBLIC_URL}/notificaciones`}
                  component={Notifications}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/configuracion`}
                  component={Configuration}
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/videos/:version/:id`}
                  component={InformationV2}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/videos`}
                  component={InformationV2}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/informacion`}
                  component={InformationV2}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/mantenimiento`}
                  component={Maintenance}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/manuales/:id`}
                  component={ManualVideos}
                />
                <Route path={`${process.env.PUBLIC_URL}/manuales`} component={Manuals} />
                <Route
                  path={`${process.env.PUBLIC_URL}/verify/redirect`}
                  component={Redirect}
                />
                <Route path={`${process.env.PUBLIC_URL}/oauth`} component={Redirect} />

                {environment !== Env.prod && (
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/playground`}
                    component={PlaygroundPage}
                  />
                )}

                <Route exact path={`${process.env.PUBLIC_URL}`} component={Dashboard} />
              </Switch>

              <Alert {...alert} />
              <ModalWelcome />
            </div>
          </div>
        </SecuredContent>
      </Switch>
    )
  }
}

const mapStateToProps = state => ({
  alert: selectAlert(state),
  currentUser: selectCurrentUser(state),
  currentCompany: selectSetCurrentCompany(state),
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Content)
