import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col } from 'react-bootstrap'
import { Button, SwitchV2, FormText } from 'src/components'

import { loadingSelector } from 'src/selectors/loading.selector'
import { actionTypes as type } from 'src/actions/user.actions'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import { showAlert } from 'src/actions/alert.actions'
import { haveAnyValue, isSafariNavigator } from 'src/utils/utilitiesV2'

const NewUser = ({ user, save, disabled = false }) => {
  const dispatch = useDispatch()

  const loading = useSelector(state => loadingSelector([type.UPDATE_PROFILE])(state))
  const error = useSelector(state => hasErrors([type.UPDATE_PROFILE])(state))

  const [userData, setUser] = useState({})
  const [errors, setErrors] = useState({})
  const [useSafari, setUseSafari] = useState(false)

  const [flags, setFlags] = useState({ update: false })

  useEffect(() => {
    if (!haveAnyValue(user)) return
    setUser(user)
    setUseSafari(isSafariNavigator())
  }, [user])

  useEffect(() => {
    if (loading) setFlags({ ...flags, update: true })
    else if (flags.update) {
      setFlags({ ...flags, update: false })
      const alert = error ? handlerError(error.message) : handlerSuccess()
      dispatch(showAlert(alert))
    }
  }, [loading])

  const change = event => {
    const { name, value } = event.target
    setUser(prevUser => ({ ...prevUser, [name]: value }))
  }

  const validate = () => {
    const { name, email, dpi, phone1, phone2 } = userData
    let errors = {}
    if (!name) {
      errors.name = 'Es requerido'
    }
    if (!email) {
      errors.email = 'Es requerido'
    } else if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      errors.email = 'Email invalido'
    }
    if (dpi && (!/^([0-9])*$/.test(dpi) || dpi.length !== 13)) {
      errors.dpi = 'DPI incorrecto'
    }
    if (phone1 && (!/^([0-9])*$/.test(phone1) || phone1.length !== 8)) {
      errors.phone1 = 'Telefono invalido'
    }
    if (phone2 && (!/^([0-9])*$/.test(phone2) || phone2.length !== 8)) {
      errors.phone2 = 'Telefono invalido'
    }
    if (Object.keys(errors).length === 0) {
      save(userData)
    }
    setErrors(errors)
  }

  return (
    <div>
      <Row>
        <Col lg={4} md={4} sm={4}>
          <FormText
            id={'name'}
            label={'Nombre'}
            placeholder={'pj. Juan López'}
            type={'text'}
            name={'name'}
            value={userData.name}
            error={errors.name}
            onChange={change}
            required
          />
        </Col>
        <Col lg={4} md={4} sm={4}>
          <FormText
            id={'email'}
            label={'Correo electrónico'}
            placeholder={'pj. usuario@gmail.com'}
            type={'email'}
            name={'email'}
            disabled={disabled}
            value={userData.email}
            error={errors.email}
            onChange={change}
            required
          />
        </Col>
        <Col lg={4} md={4} sm={4}>
          <FormText
            id={'dpi'}
            label={'Documento de identificación'}
            placeholder={'pj. 2020 80800 0101'}
            type={'text'}
            name={'dpi'}
            value={userData.dpi}
            error={errors.dpi}
            onChange={change}
          />
        </Col>
        <Col lg={4} md={4} sm={4}>
          <FormText
            id={'phon1'}
            label={'Telefono principal'}
            placeholder={'pj. 24208600'}
            type={'text'}
            name={'phone1'}
            value={userData.phone1}
            error={errors.phone1}
            onChange={change}
          />
        </Col>
        <Col lg={4} md={4} sm={4}>
          <FormText
            id={'phon2'}
            label={'Telefono secundario'}
            placeholder={'pj. 24208600'}
            type={'text'}
            name={'phone2'}
            value={userData.phone2}
            error={errors.phone2}
            onChange={change}
          />
        </Col>
        <Col lg={4} md={4} sm={4}>
          <FormText
            id={'address'}
            label={'Dirección'}
            placeholder={'pj. 4ta avenida, colonia bella, zona 4'}
            type={'text'}
            name={'address'}
            value={userData.address}
            onChange={change}
          />
        </Col>
        <Col lg={4} md={4} sm={12} xs={12}>
          <SwitchV2
            label={'Uso de navegador SAFARI'}
            info={''}
            checked={useSafari}
            onChange={value => {
              setUseSafari(value)
              localStorage.setItem('isSafari', value)
            }}
          />
        </Col>
      </Row>
      <Row className={'container-buttons'}>
        <Button color={'primary'} loading={loading} onClick={() => validate()}>
          {user ? 'Guardar' : 'Crear usuario'}
        </Button>
      </Row>
    </div>
  )
}
export default NewUser
