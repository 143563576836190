import { executeAction } from './global.actions'
import BanksService from '../services/banks.service'
import { selectGetAccounts } from '../selectors/banks.selector'
import { getState } from 'src/utils/utilities'

export const actionTypes = {
  GET_BANKS: 'GET_BANKS',
  GET_ACCOUNTS: 'GET_ACCOUNTS',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  UPDATE_ACCOUNT: 'UPDATE_ACCOUNT',
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
  GET_ACCOUNT_HISTORY: 'GET_ACCOUNT_HISTORY',
  SET_MOVEMENTS_CSV: 'SET_MOVEMENTS_CSV',
  SET_MOVEMENTS_CSV_V2: 'SET_MOVEMENTS_CSV_V2',
  CREATE_OPERATION: 'CREATE_OPERATION',
  GET_MOVEMENT_BY_DEPOSIT: 'GET_MOVEMENT_BY_DEPOSIT',
  GET_DEPOSITS_COMPANY: 'GET_DEPOSITS_COMPANY',
  REVERT_MOVEMENT: 'REVERT_MOVEMENT',
  ACCEPT_RETENTION: 'ACCEPT_RETENTION',
  CHANGE_STATUS_ACCOUNT: 'CHANGE_STATUS_ACCOUNT',
  CHANGE_STATUS_ACCOUNT_SUCCESS: 'CHANGE_STATUS_ACCOUNT_SUCCESS',
  CHANGE_STATUS_ACCOUNT_ERROR: 'CHANGE_STATUS_ACCOUNT_ERROR',
  CHANGE_STATUS_KOLO: 'CHANGE_STATUS_KOLO',
  CSV_MOVEMENTS: 'CSV_MOVEMENTS',
  EXCEL_MOVEMENTS: 'EXCEL_MOVEMENTS',
  ACCEPT_MOVEMENT: 'ACCEPT_MOVEMENT',
}

const changeStatusAccountRequest = () => ({
  type: actionTypes.CHANGE_STATUS_ACCOUNT,
})
const changeStatusAccountSuccess = status => ({
  type: actionTypes.CHANGE_STATUS_ACCOUNT_SUCCESS,
  status,
})
const changeStatusAccountError = error => ({
  type: actionTypes.CHANGE_STATUS_ACCOUNT_ERROR,
  error,
})

export const getBanks = () => dispatch => {
  const process = async () => ({ banks: await BanksService.getBanks() })
  dispatch(executeAction(actionTypes.GET_BANKS, process))
}

export const getAccounts = byKolo => dispatch => {
  const process = async () => ({
    bankAccounts: await BanksService.getAccounts({ byKolo }),
  })
  dispatch(executeAction(actionTypes.GET_ACCOUNTS, process))
}

export const createAccount =
  (accountNumber, name, toName, bank, type, balance, module, asigned, currency, report) =>
  dispatch => {
    const process = async () => {
      const bankAccount = await BanksService.postAccount(
        accountNumber,
        name,
        toName,
        bank,
        type,
        balance,
        module,
        asigned,
        currency,
        report,
      )
      dispatch(getAccounts())
      return { bankAccount }
    }
    dispatch(executeAction(actionTypes.CREATE_ACCOUNT, process))
  }

export const updateAccount = item => dispatch => {
  const process = async () => {
    const bankAccount = await BanksService.putAccount(item)
    dispatch(getAccounts())
    return { bankAccount }
  }
  dispatch(executeAction(actionTypes.UPDATE_ACCOUNT, process))
}

export const deleteAccount = id => dispatch => {
  const process = async () => {
    const deleteAccount = await BanksService.deleteAccount(id)
    dispatch(getAccounts())
    return { deleteAccount }
  }
  dispatch(executeAction(actionTypes.DELETE_ACCOUNT, process))
}

export const getAccountHistory = (id, params) => dispatch => {
  const process = async () => ({
    bankHistory: await BanksService.getAccountHistory(id, params),
  })
  dispatch(executeAction(actionTypes.GET_ACCOUNT_HISTORY, process))
}

export const onCreateTransactionCSV = (bankAccountsId, file) => dispatch => {
  const process = async () => ({
    movements: await BanksService.onCreateTransactionCSV(bankAccountsId, file),
  })
  dispatch(executeAction(actionTypes.SET_MOVEMENTS_CSV, process))
}

export const createBankOperation = (request, params) => dispatch => {
  const process = async () => ({
    movement: await BanksService.postMovement(request, params),
  })
  dispatch(executeAction(actionTypes.CREATE_OPERATION, process))
}

export const getMovementMatchDeposit =
  (documentId, isPurchase, currency, amount) => dispatch => {
    const process = async () => ({
      movementByDeposit: await BanksService.getMovementMatchDeposit(
        documentId,
        isPurchase,
        currency,
        amount,
      ),
    })
    dispatch(executeAction(actionTypes.GET_MOVEMENT_BY_DEPOSIT, process))
  }

export const revertOperation = (movement, message) => dispatch => {
  const process = async () => ({
    revert: await BanksService.revertMovement(movement, message),
  })
  dispatch(executeAction(actionTypes.REVERT_MOVEMENT, process))
}
export const getAllDepositsByCompany = () => dispatch => {
  const process = async () => ({
    deposits: await BanksService.getDepositsByCompany(),
  })
  dispatch(executeAction(actionTypes.GET_DEPOSITS_COMPANY, process))
}

export const acceptRetention = (movementId, isRevert) => dispatch => {
  const process = async () => ({
    retention: await BanksService.acceptRetention(movementId, isRevert),
  })
  dispatch(executeAction(actionTypes.ACCEPT_RETENTION, process))
}

export const changeStatusAccount = id => async dispatch => {
  dispatch(changeStatusAccountRequest())
  try {
    const status = await BanksService.changeStatusAccount(id)
    dispatch(changeStatusAccountSuccess(status))
  } catch (error) {
    dispatch(changeStatusAccountError(error))
  }
}

export const getExcelMovements =
  (start, end, pending, skip, size, search, id, receipt) => dispatch => {
    const process = async () => ({
      response: await BanksService.getExcelMovements(
        { start, end, pending, skip, size, search, receipt },
        id,
      ),
    })
    dispatch(executeAction(actionTypes.EXCEL_MOVEMENTS, process))
  }

export const acceptMovementRequest = request => dispatch => {
  const process = async () => ({
    response: await BanksService.acceptMovementRequest(request),
  })
  dispatch(executeAction(actionTypes.ACCEPT_MOVEMENT, process))
}

// SET_MOVEMENTS_CSV_V2
export const onCreateTransactionCSVV2 =
  (accountId, index, data, onSuccess, biBanking) => async dispatch => {
    const response = await BanksService.onCreateTransactionCSVV2(
      accountId,
      index,
      data,
      biBanking,
    )
    const process = () => ({ response })
    dispatch(executeAction(actionTypes.SET_MOVEMENTS_CSV_V2, process))
    onSuccess(response, false)
  }
