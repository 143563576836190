import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Modal, Row, Col, ProgressBar } from 'react-bootstrap'
import { FormText, Select, Button, Switch } from 'src/components'
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  getCompany,
  createDemoAccount,
  updateAccount,
} from 'src/actions/mentor.actions'
import {
  selectCompanyCreatedMentor,
  selectSingleCompanyMentor,
} from 'src/selectors/mentor.selector'

import { showAlert } from 'src/actions/alert.actions'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import { loadingSelector } from 'src/selectors/loading.selector'

import {
  actionTypes as currencyTypes,
  getAllCurrencies,
  getCountries,
} from 'src/actions/currencies.actions'
import { selectAllCurrencies, selectCountries } from 'src/selectors/currencies.selector'

import { valNit } from 'src/selectors/utilities.selector'
import { valEmail } from 'src/utils/utilities'

import { isAllowed } from 'src/selectors/modules.selector'
import { mentorPermissions } from 'src/enums/permissions'
import { Country } from 'src/enums/countries'
import { PlanEnum } from 'src/enums/planTypes'
import {identificationTypesEnum} from "src/enums/identificationTypes";
import IdentificationField from "src/components/IdentificationField/IdentificationField";

interface IFlags {
  get: boolean
  createOrUpdate: boolean
  getCurrencies: boolean
  getCountries: boolean
}

interface IProps {
  show: boolean
  id?: number
  title?: string
  onHide: (update: boolean) => void
  planId?: number
  addons?: MCompanyAddons[]
  orderId?: number
}

/** Component to Create, Read and Update accounts
 * @component
 * @param {boolean} show Indicate whether the component should be initialized
 * @param {number} id Id of company to Read or Update information
 * @param {string} title Name of company to Read or Update information
 * @param {Function} onHide Close the component
 * @param {number} planId Id of the plan to assign to the new company
 * @param {MCompanyAddons[]} addons List of addons to assign to the new company
 * */
const CreateOrUpdateCompany = ({
  show,
  id,
  title,
  onHide,
  planId,
  addons,
  orderId,
}: IProps) => {
  const dispatch = useDispatch()

  /** SERVICE RESPONSE **/
  const singleCompany = useSelector(selectSingleCompanyMentor)
  const loadingGetCompany = useSelector(state =>
    loadingSelector([actionTypes.GET_SINGLE_COMPANY_MENTOR])(state),
  )
  const hasErrorGet = useSelector(state =>
    hasErrors([actionTypes.GET_SINGLE_COMPANY_MENTOR])(state),
  )

  const createResponse = useSelector(selectCompanyCreatedMentor)
  const loadingCreateOrUpdateCompany = useSelector(state =>
    loadingSelector([actionTypes.CREATE_OR_UPDATE_COMPANY])(state),
  )
  const hasErrorCreateOrUpdate = useSelector(state =>
    hasErrors([actionTypes.CREATE_OR_UPDATE_COMPANY])(state),
  )

  const currencies: ISelect[] = useSelector(selectAllCurrencies)
  const loadingCurrencies = useSelector(state =>
    loadingSelector([currencyTypes.GET_CURRENCIES])(state),
  )

  const countries: ISelect[] = useSelector(selectCountries)
  const loadingCountries = useSelector(state =>
    loadingSelector([currencyTypes.GET_COUNTRIES])(state),
  )

  const [flags, setFlags] = useState<IFlags>({
    get: false,
    createOrUpdate: false,
    getCurrencies: false,
    getCountries: false,
  })

  /** PERMISSIONS **/
  const canCreateAccount: boolean = useSelector(state =>
    isAllowed(state, [mentorPermissions.createAccount]),
  )
  const canUpdateAccount: boolean = useSelector(state =>
    isAllowed(state, [mentorPermissions.updateAccount]),
  )
  const canUpdateCreditFelStatus: boolean = useSelector(state =>
    isAllowed(state, [mentorPermissions.canUpdateCreditFelStatus]),
  )
  const canUpdateThirdEmitFEL: boolean = useSelector(state =>
    isAllowed(state, [mentorPermissions.canUpdateThirdEmitFEL]),
  )

  const [data, setData] = useState({
    active: true,
    nit: null,
    name: null,
    shortName: null,
    address: null,
    phone: null,
    email: null,
    planId: planId || null,
    currency: null,
    country: null,
    externalEmissionFEL: false,
    creditFEL: false,
    addons: addons || [],
    orderId: null,
    clientNameFel : null,
    clientAddressFel : null,
  })

  const [clientFelData, setClientFelData] = useState({
    clientName: null,
    clientAddress: null
  })

  const loading = loadingCountries || loadingCurrencies || loadingGetCompany
  const disabled: boolean =
    loading ||
    loadingCreateOrUpdateCompany ||
    (id ? !canUpdateAccount || !data.active : false)

  useEffect(() => {
    if (!show) return
    dispatch(getAllCurrencies())
  }, [show])

  useEffect(() => {
    if (loadingCurrencies) setFlags({ ...flags, getCurrencies: true })
    else if (flags.getCurrencies) {
      setFlags({ ...flags, getCurrencies: false })
      dispatch(getCountries())
    }
  }, [loadingCurrencies])

  useEffect(() => {
    if (loadingCountries) setFlags({ ...flags, getCountries: true })
    else if (flags.getCountries) {
      setFlags({ ...flags, getCountries: false })
      if (id) dispatch(getCompany(id))
    }
  }, [loadingCountries])

  useEffect(() => {
    if (loadingGetCompany) setFlags({ ...flags, get: true })
    else if (flags.get) {
      setFlags({ ...flags, get: false })
      if (hasErrorGet) dispatch(showAlert({ ...handlerError(hasErrorGet.message) }))
      else setData(singleCompany)
    }
  }, [loadingGetCompany])

  useEffect(() => {
    if (loadingCreateOrUpdateCompany) setFlags({ ...flags, createOrUpdate: true })
    else if (flags.createOrUpdate) {
      setFlags({ ...flags, createOrUpdate: false })
      const alert = hasErrorCreateOrUpdate
        ? { ...handlerError(hasErrorCreateOrUpdate.message) }
        : {
            ...handlerSuccess(
              id
                ? undefined
                : `Usuario: ${data.email}, ${
                    createResponse
                      ? `Contraseña: ${createResponse}`
                      : 'ya está registrado, puede ingresar a la nueva empresa con su actual contraseña'
                  }`,
            ),
            onConfirm: () => onClose(true),
          }
      dispatch(showAlert(alert))
    }
  }, [loadingCreateOrUpdateCompany])

  const onClose = (update: boolean) => {
    onHide(update)
    setData({
      active: true,
      nit: null,
      name: null,
      shortName: null,
      address: null,
      phone: null,
      email: null,
      planId: null,
      currency: null,
      country: null,
      externalEmissionFEL: false,
      creditFEL: false,
      addons: null,
      orderId: null,
      clientNameFel: null,
      clientAddressFel: null,
    })

    setClientFelData({
      clientName: null,
      clientAddress: null
    })
  }

  const getTextValue = (key: string): string => {
    return data[key]
  }

  const getSelectValue = (key: string, options: ISelect[]): ISelect => {
    return (
      options.find((o: ISelect) => o.value === data[key]) || {
        value: null,
        label: 'Sin seleccionar',
      }
    )
  }

  const onSave = () => {
    let messageAlert = ''
    if (planId !== null) data.planId = planId
    if (addons !== null) data.addons = addons
    if (orderId !== null) data.orderId = orderId
    if (!data.country) messageAlert += ' País,'
    if (!data.nit || (data.country === Country.GT && !valNit(data.nit)))
      messageAlert += ' NIT,'
    if (!data.name) messageAlert += ' nombre,'
    if (!data.shortName) messageAlert += ' nombre corto,'
    if (!data.address) messageAlert += ' dirección,'
    if (!data.phone || isNaN(data.phone)) messageAlert += ' teléfono,'
    if (!data.email || !valEmail(data.email)) messageAlert += ' email,'
    if (!data.planId) messageAlert += ' plan,'
    if (!data.currency) messageAlert += ' Moneda,'
    if (data.planId === PlanEnum.FREE) data.creditFEL = false
    if (data.country === Country.GT && !id) {
      if(!clientFelData.clientName) messageAlert += ' Nombre del cliente,'
      else{
        data.clientNameFel = clientFelData.clientName
        data.clientAddressFel = clientFelData.clientAddress
      }
    }

    if (messageAlert === '') {
      if (!id) {
       dispatch(createDemoAccount(data))
      } else dispatch(updateAccount(id, data))
    } else
      dispatch(
        showAlert({
          ...handlerError('Valores incorrectos pra los siguientes campos' + messageAlert),
        }),
      )
  }

  const fields: MCompanyFields[] = [
    {
      key: 'country',
      type: 2,
      label: 'País',
      options: countries,
      available: true,
    },
    {
      key: 'currency',
      type: 2,
      label: 'Moneda',
      options: currencies,
      available: true,
    },
    {
      key: 'nit',
      type: 1,
      label: 'NIT',
      inputType: 'text',
      available: true,

    },
    {
      key: 'name',
      type: 1,
      label: 'Nombre',
      inputType: 'text',
      available: true,
    },
    {
      key: 'shortName',
      type: 1,
      label: 'Nombre Corto',
      inputType: 'text',
      available: true,
    },
    {
      key: 'address',
      type: 1,
      label: 'Dirección',
      inputType: 'text',
      available: true,
    },
    {
      key: 'phone',
      type: 1,
      label: 'Teléfono',
      inputType: 'tel',
      available: true,
    },
    {
      key: 'email',
      type: 1,
      label: 'Correo electrónico',
      inputType: 'email',
      disabled: true,
      available: true,
    },
    {
      key: 'planId',
      type: 2,
      label: 'Planes',
      options: [
        { value: 8, label: 'KOLO FEL' },
        { value: 9, label: 'KOLO POS' },
        { value: 11, label: 'KOLO PRO' },
        { value: 10, label: 'KOLO ERP' },
        { value: 18, label: 'KOLO Gratuito' },
        { value: 19, label: 'POS 1 Usuario' },
        { value: 20, label: 'POS 3 Usuarios' },
        { value: 21, label: 'PRO 1 Usuario' },
        { value: 22, label: 'PRO 3 Usuario' },
        { value: 23, label: 'ERP 3 Usuarios' },
        { value: 24, label: 'ERP 5 Usuarios' },
      ],
      disabled: true,
      available: true,
      hide: planId !== null,
    },
    {
      key: 'externalEmissionFEL',
      type: 2,
      label: 'Tipo de emisión FEL',
      options: [
        { value: false, label: 'KOLO' },
        { value: true, label: 'Tercero' },
      ],
      available: canUpdateThirdEmitFEL,
    },
    {
      key: 'creditFEL',
      type: 2,
      label: 'Habilitar crédito de facturas',
      options: [
        { value: true, label: 'SI' },
        { value: false, label: 'NO' },
      ],
      available: canUpdateCreditFelStatus,
      hide: data.planId === PlanEnum.FREE,
    },
  ]

  return (
    <Modal show={show} size={'xl'} centered onHide={() => onClose(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{id ? title : 'Crear cuenta DEMO'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={'custom-modal-body column'}>
          <Row>
            <Col xl={12}>
              {loading && (
                <ProgressBar
                  label="Cargando"
                  animated
                  now={100}
                  style={{ marginBottom: 10 }}
                />
              )}
            </Col>

            {id && (
              <Col xl={12}>
                <Switch
                  topLabel
                  label={'Estado de la Empresa'}
                  checked={data.active}
                  changeValue={v => setData({ ...data, active: v })}
                />
              </Col>
            )}

            {fields
                .filter((field: MCompanyFields) => !field.hide)
                .map((field: MCompanyFields) => {
                  const inputDisabled =
                      !field.available || disabled || (field.disabled && id !== undefined)

                  return (
                      <Col key={field.key} xl={4} lg={4} md={6} sm={12} xs={12}>
                        {
                          field.key === "nit" && data.country === Country.GT ?
                              <IdentificationField
                                  identificationTypeId={identificationTypesEnum.NIT_GT}
                                  value={data.nit}
                                  onChange={value => setData({...data, [field.key]: value})}
                                  onSearch={(client: any) => {
                                    console.log(client)
                                    setClientFelData(client)
                                  }}
                                  required
                              />
                              :
                              field.type === 1 ? (
                                  <FormText
                                      required
                                      disabled={inputDisabled}
                                      label={field.label}
                                      name={field.key}
                                      id={field.key}
                                      value={getTextValue(field.key)}
                                      changeValue={v => {
                                        setData({...data, [field.key]: v})
                                      }}
                                  />
                              ) : (
                                  <Select
                                      required
                                      disabled={inputDisabled}
                                      label={field.label}
                                      name={field.key}
                                      id={field.key}
                                      options={field.options}
                                      value={getSelectValue(field.key, field.options)}
                                      onChange={v => {
                                        setData({...data, [field.key]: v.value})
                                      }}
                                  />
                              )}
                      </Col>
                  )
                })}

          </Row>
          {data.country === Country.GT && !id &&(<>
          <Col xl={12}>
            <hr />
          </Col>
            <Row>
              <Col xl={12}>Datos de facturación</Col>
                <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                    <FormText
                        required
                        disabled={true}
                        label={'Nombre del cliente'}
                        name={'clientName'}
                        id={'clientName'}
                        value={clientFelData.clientName}
                    />
                </Col>
              <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                <FormText
                    required
                    label={'Dirección del cliente'}
                    name={'clientAddressFel'}
                    id={'clientAddressFel'}
                    changeValue={v => setClientFelData({...clientFelData, clientAddress: v})}
                    value={clientFelData.clientAddress}
                />
              </Col>
          </Row></>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Row className={'container-buttons'}>
          <Button
            disabled={loadingGetCompany || loadingCreateOrUpdateCompany}
            color={'secondary'}
            icon={faWindowClose}
            onClick={() => onClose(false)}>
            Cerrar
          </Button>
          <Button
            disabled={loadingGetCompany}
            loading={loadingCreateOrUpdateCompany}
            icon={faSave}
            onClick={onSave}
            disabeld={id ? !canUpdateAccount : !canCreateAccount}>
            {id ? 'Actualizar' : 'Crear'}
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}
export default CreateOrUpdateCompany
