import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import {
  Button,
  Loading,
  SwitchV2,
  Paragraph,
  FormText,
  MoneyField,
  Select,
} from 'src/components'
import { faCheckDouble, faWindowClose } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  getItemsToCreditNote,
  onCreateCreditNoteBySells,
} from 'src/actions/invoice.actions'
import { selectInvoiceItemsCreditNote } from 'src/selectors/invoice.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'

import { showAlert } from 'src/actions/alert.actions'
import { haveAnyValue } from 'src/utils/utilitiesV2'

import CreditNoteItemSelected from 'src/content/CreditNote/sell/CreditNoteItemSelected'
import CreditNoteItemsList from 'src/content/CreditNote/sell/CreditNoteItemsList'

interface IProps {
  show: boolean
  orderId: number
  code: string
  clientId: number
  onHide: (update: boolean) => void
}

interface IReturnType {
  value: number
  label: string
  info: string
}

const defaultReturnType: IReturnType = {
  value: 3,
  label: 'Saldo a favor',
  info: 'Se registrará un saldo a favor al cliente.',
}

const returnTypes: IReturnType[] = [
  {
    value: 0,
    label: 'Sin devolución',
    info: 'No se realizará ninguna devolución al cliente ni se registrará una salida monetaria de las cuentas.',
  },
  {
    value: 1,
    label: 'Efectivo',
    info: 'Se registrará una salida de efectivo circulante del usuario quien confirmo la orden de venta.',
  },
  defaultReturnType,
]

/** Render a modal to create a credit note by order id
 * @param {boolean} show Indicate if the componente render
 * @param {number} orderId Id of order to crate a credit note
 * @param {number} clientId Id of client from the order
 * @param {string} code Number of order to create a credit note
 * @param {function} onHide Function to close the component
 * */
const CreateCreditNoteSell = ({
  show = false,
  orderId,
  clientId,
  code,
  onHide,
}: IProps) => {
  const dispatch = useDispatch()

  const responseItems: ICreditNoteItem[] = useSelector(selectInvoiceItemsCreditNote)
  const loadingGet = useSelector(state =>
    loadingSelector([actionTypes.GET_INVOICE_ITEMS_TO_CREDIT_NOTE])(state),
  )
  const hasErrorGet = useSelector(state =>
    hasErrors([actionTypes.GET_INVOICE_ITEMS_TO_CREDIT_NOTE])(state),
  )

  const loadingCreate = useSelector(state =>
    loadingSelector([actionTypes.ON_CREATE_CREDIT_NOTE_BY_SELLS])(state),
  )
  const hasErrorCreate = useSelector(state =>
    hasErrors([actionTypes.ON_CREATE_CREDIT_NOTE_BY_SELLS])(state),
  )

  const withoutFEL: boolean = responseItems.some(i => !haveAnyValue(i.uuid))

  const [flags, setFlags] = useState({ get: false, create: false })
  const [itemSelected, setItemSelected] = useState<ICreditNoteItem>(undefined)

  const [items, setItems] = useState<ICreditNoteItem[]>([])
  const [isCreditNote, setIsCreditNote] = useState<boolean>(true)
  const [returnInventory, setReturnInventory] = useState<boolean>(true)
  const [returnType, setReturnType] = useState<IReturnType>(defaultReturnType)
  const [partial, setPartial] = useState<boolean>(false)
  const [reason, setReason] = useState<string>(null)
  const [generalAmount, setGeneralAmount] = useState<number>(0)
  const [creditNoteAmount, setCreditNoteAmount] = useState<number>(0)

  useEffect(() => {
    if (!show) return
    dispatch(getItemsToCreditNote(orderId))
  }, [show])

  useEffect(() => {
    if (loadingGet) setFlags({ ...flags, get: true })
    else if (flags.get) {
      setFlags({ ...flags, get: false })
      if (hasErrorGet)
        dispatch(
          showAlert({
            ...handlerError(hasErrorGet.message),
            onConfirm: () => onClose(false),
          }),
        )
      else {
        setItems(responseItems.map(r => ({ ...onFormatData(r) })))
        setGeneralAmount(responseItems.reduce((acc, item) => acc + item.total, 0))
        setCreditNoteAmount(0)
        setIsCreditNote(!withoutFEL)
      }
    }
  }, [loadingGet])

  useEffect(() => {
    if (loadingCreate) setFlags({ ...flags, create: true })
    else if (flags.create) {
      setFlags({ ...flags, create: false })
      const alert = hasErrorCreate
        ? {
            ...handlerError(hasErrorCreate.message),
          }
        : {
            ...handlerSuccess(),
            onConfirm: () => onClose(true),
          }

      dispatch(showAlert(alert))
    }
  }, [loadingCreate])

  const onFormatData = (item: ICreditNoteItem): ICreditNoteItem => {
    const line: ILineItem[] = item.itemData.line.map(itemLine => {
      return {
        ...itemLine,
        quantity: itemLine.productId === item.itemId ? item.quantity : 0,
      }
    })

    return {
      ...item,
      amount: item.total,
      selected: false,
      itemData: { ...item.itemData, line },
    }
  }

  const onClose = (update: boolean) => {
    onHide(update)
    setItems([])
    setIsCreditNote(true)
    setReturnInventory(true)
    setReturnType(defaultReturnType)
    setPartial(false)
    setReason(null)
  }

  const onSave = () => {
    const request = {
      orderId,
      clientId,
      returnInventory,
      returnBalance: haveAnyValue(returnType.value),
      returnType: returnType.value,
      partial,
      items: [],
      reason,
      creditNote: isCreditNote,
      amount: getTotalSelected(),
    }
    if (returnInventory)
      items
        .filter(i => (partial ? i.selected : true))
        .forEach(i => {
          const baseData = {
            detailId: i.detailId,
            invoiceId: i.invoiceId,
            orderId: i.orderId,
            felItemTypeId: i.felItemTypeId,
            measurementUnitId: i.measurementUnitId,
          }

          if (!partial) {
            request.items.push({
              ...baseData,
              itemId: i.itemId,
              quantity: i.quantity,
              price: i.price,
              name: i.name,
            })
          } else {
            request.items.push(
              ...i.itemData.line
                .filter(subItem => subItem.quantity > 0)
                .map(subItem => {
                  const price = getSubPrice(i, subItem)
                  return {
                    ...baseData,
                    itemId: subItem.productId,
                    quantity: subItem.quantity,
                    price: price,
                    name: subItem?.productData?.name,
                  }
                }),
            )
          }
        })

    dispatch(onCreateCreditNoteBySells(request))
  }

  const getSubPrice = (item: ICreditNoteItem, subItem: ILineItem): number => {
    const baseQuantity: number = getQuantityUsed(item)
    const factor: number = !subItem.presentationFactor ? 1 : subItem.presentationFactor

    return (factor * item.amount) / baseQuantity
  }

  const onItemUpdate = (item: ICreditNoteItem) => {
    const customItems = Object.assign([], items)
    const index = customItems.findIndex(ci => ci.detailId === item.detailId)
    customItems[index] = item
    setItemSelected(undefined)
    setItems(customItems)
  }

  const getQuantityUsed = (item: ICreditNoteItem): number =>
    item?.itemData?.line.reduce(
      (used, item) =>
        used + (!item.presentationFactor ? 1 : item.presentationFactor) * item.quantity,
      0,
    )

  const getTotalSelected = (): number => {
    if (!returnInventory) return !partial ? generalAmount : creditNoteAmount
    return items
      .filter(i => (partial ? i.selected : true))
      .reduce((amount, item) => amount + Number(partial ? item.amount : item.total), 0)
  }

  const renderTotal = (
    <Col xl={12}>
      <Row>
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <MoneyField
            label={'Valor total de la venta'}
            disabled
            value={generalAmount}
            isMoney
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <MoneyField
            label={'Monto de la nota / vale a emitir'}
            disabled={!(!returnInventory && partial)}
            value={getTotalSelected()}
            onValueChange={setCreditNoteAmount}
            min={0}
            max={generalAmount}
            isMoney
          />
        </Col>
      </Row>
    </Col>
  )

  const renderItems = (
    <Col xl={12}>
      <CreditNoteItemsList
        items={items}
        partial={partial}
        onChange={onItemUpdate}
        onSelect={setItemSelected}
      />
    </Col>
  )

  return (
    <div>
      <Modal
        show={
          show && !(itemSelected !== undefined && itemSelected?.detailId !== undefined)
        }
        centered
        size={'xl'}>
        <Modal.Header>
          <Modal.Title>{code} Crear nota de crédito</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Paragraph>
                Se le creará al cliente una nota de crédito con saldo a favor que podrá
                utilizar en otras ordenes de venta. La orden de venta cambiará de estado
                a: Acreditada con vale.
              </Paragraph>
            </Col>
            <Col xl={12}>
              <FormText
                label={'Motivo del ajuste'}
                value={reason}
                changeValue={v => setReason(v)}
                required
              />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <SwitchV2
                disabled={loadingCreate || withoutFEL}
                checked={isCreditNote}
                label={'Nota de crédito'}
                info={
                  'Si la opción está activa se emitirá una nota de crédito, de lo contrario se creara un vale.'
                }
                onChange={setIsCreditNote}
              />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <SwitchV2
                disabled={loadingCreate}
                checked={returnInventory}
                label={'Devolución de inventario'}
                info={'Retorná el inventario vendido a la bodega de origen'}
                onChange={setReturnInventory}
              />
            </Col>

            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <SwitchV2
                disabled={loadingCreate}
                checked={partial}
                label={'Nota parcial'}
                info={'¿Desea realizar la nota de crédito parcial??'}
                onChange={setPartial}
              />
            </Col>

            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <Select
                label={'Devolución monetaria'}
                info={
                  'Realizá la devolución monetaria al cliente por el total seleccionado'
                }
                value={returnType}
                options={returnTypes}
                onChange={setReturnType}
                subText={
                  <Paragraph className={'mt-2'} size={'big'}>
                    {returnType.info}
                  </Paragraph>
                }
              />
            </Col>

            <Col xl={12}>
              <Loading show={loadingGet} />
              <hr />
            </Col>

            {renderTotal}

            {returnInventory && renderItems}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              disabled={loadingCreate}
              color={'secondary'}
              icon={faWindowClose}
              onClick={() => onClose(false)}>
              Cerrar
            </Button>
            <Button
              disabled={
                reason === null ||
                reason === undefined ||
                reason === '' ||
                getTotalSelected() <= 0
              }
              loading={loadingCreate}
              color={'primary'}
              icon={faCheckDouble}
              onClick={onSave}>
              Crear
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <CreditNoteItemSelected
        item={itemSelected}
        onHide={() => setItemSelected(undefined)}
        onChange={onItemUpdate}
      />
    </div>
  )
}
export default CreateCreditNoteSell
