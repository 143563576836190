import { actionTypes as companyActionTypes } from 'src/actions/company.actions'
import { actions } from 'src/actions/plans.actions'
import { extract, successState } from 'src/actions/global.actions'

const initialState = {
  plans: [],
  plan: {},
  companyPlan: {},
  userPlan: {},
  userModules: {},
  orders: [],
  total: 0,
  planPendingPayment: false,
}

const PlansReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(companyActionTypes.GET_COMPANY_DATA):
      return {
        ...state,
        plan: extract(action.payload.plan, {}),
      }
    case successState(actions.GET_PLANS):
      return {
        ...state,
        plans: extract(action.response, []),
      }
    case successState(actions.GET_ACTIVE_PLAN):
      return {
        ...state,
        plan: extract(action.response, {}),
      }
    case successState(actions.GET_COMPANY_ACTIVE_PLAN):
      return {
        ...state,
        companyPlan: extract(action.response, {}),
      }
    case successState(actions.ADD_USER):
      return {
        ...state,
        userPlan: extract(action.response, {}),
      }
    case successState(actions.GET_USER_PLAN):
      return {
        ...state,
        userModules: extract(action.response, {}),
      }
    case successState(actions.GET_HISTORY):
      return {
        ...state,
        orders: extract(action.response.orders, []),
        total: extract(action.response.total, 0),
      }
    case successState(actions.PLAN_PENDING_PAYMENT):
      return {
        ...state,
        planPendingPayment: extract(action.response, {}),
      }
    default:
      return state ? state : initialState
  }
}

export default PlansReducer
