import { actionTypes } from '../actions/notifications.actions'
import { successState } from '../actions/global.actions'

const initialState = {
  notifications: [],
  recent: [],
  playerId: '',
  enabled: false,
  order: {},
  fields: [],
  users: [],
}

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_ALL_NOTIFICATIONS): {
      return { ...state, notifications: action.response }
    }
    case successState(actionTypes.GET_RECENT_NOTIFICATIONS): {
      return { ...state, recent: action.response }
    }
    case successState(actionTypes.GET_PLAYER_ID): {
      return { ...state, playerId: action.response }
    }
    case successState(actionTypes.IS_ENABLED): {
      return { ...state, enabled: action.response }
    }
    case successState(actionTypes.GET_ORDER_BY_NOTIFICATION): {
      return { ...state, order: action.response }
    }
    case successState(actionTypes.GET_FIELDS): {
      return { ...state, fields: action.response }
    }
    case successState(actionTypes.GET_USERS_BY_NOTIFICATION): {
      return { ...state, users: action.response }
    }
    default:
      return state ? state : initialState
  }
}

export default notificationReducer
