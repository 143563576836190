import React, { useEffect, useState } from 'react'

// ================== Redux ====================
import { useSelector, useDispatch } from 'react-redux'

import { Media } from 'react-breakpoints'
import { Modal, Row, Col, ProgressBar } from 'react-bootstrap'
import { CustomFilter, Button, Icon } from 'src/components'
import Alert from 'sweetalert-react'

import {
  faWindowClose,
  faPlus,
  faSave,
  faArrowRight,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import {
  actionTypes,
  getUsersByNotification,
  setUsersToNotification,
} from 'src/actions/notifications.actions'

import { selectUsersByNotification } from 'src/selectors/notifications.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerSuccess,
  handlerError,
  singleErrorSelector,
  hasErrorsSelector,
} from 'src/selectors/error.selector'

export const ConfigNotificationFields = ({
  show,
  onHide,
  usersToSelect,
  notificationField,
  title,
}) => {
  const dispatch = useDispatch()

  const loading = useSelector(state =>
    loadingSelector([actionTypes.SET_USERS_TO_NOTIFICATION])(state),
  )
  const hasError = useSelector(state =>
    hasErrorsSelector([actionTypes.SET_USERS_TO_NOTIFICATION])(state),
  )
  const error = useSelector(state =>
    singleErrorSelector([actionTypes.SET_USERS_TO_NOTIFICATION])(state),
  )

  const loadingV = useSelector(state =>
    loadingSelector([actionTypes.GET_USERS_BY_NOTIFICATION])(state),
  )
  const hasErrorV = useSelector(state =>
    hasErrorsSelector([actionTypes.GET_USERS_BY_NOTIFICATION])(state),
  )
  const errorV = useSelector(state =>
    singleErrorSelector([actionTypes.GET_USERS_BY_NOTIFICATION])(state),
  )

  const users = useSelector(selectUsersByNotification)

  const [usersToDelete, setUsersToDelete] = useState([])
  const [usersToAdd, setUsersToAdd] = useState([])
  const [save, setSave] = useState(false)
  const [get, setGet] = useState(false)
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState([])
  const [searchA, setSearchA] = useState(null)
  const [searchB, setSearchB] = useState(null)
  const [mobile, setMobile] = useState(false)
  const [alert, setAlert] = useState({ title: '' })

  useEffect(() => {
    if (show) {
      setOpen(true)
      dispatch(getUsersByNotification(notificationField || 76))
    }
  }, [show])

  useEffect(() => {
    if (loadingV) setGet(true)
    else if (get) {
      setGet(false)
      if (hasErrorV)
        setAlert({
          ...handlerError(errorV.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else {
        if (usersToSelect) setSelected(usersToSelect.map(formatUsers))
        else setSelected(users)
      }
    }
  }, [loadingV])

  useEffect(() => {
    if (loading) setSave(true)
    else if (save) {
      setSave(false)
      let al
      if (hasError) al = { ...handlerError(error.message) }
      else al = { ...handlerSuccess('Cambios aplicados satisfactoriamente') }
      al.onConfirm = () => setAlert({ ...alert, show: false })
      setAlert({ ...al })
    }
  }, [loading])

  const hide = () => {
    setOpen(false)
    setSelected([])
    setUsersToAdd([])
    setUsersToDelete([])
    onHide()
  }

  const filter = (item, search) => {
    if (search && search !== '') {
      const exp = new RegExp(search.toUpperCase(), 'g')
      const i = { name: item.name, email: item.email }
      return Object.keys(i).some(p => `${JSON.stringify(i[p])}`.toUpperCase().match(exp))
    }
    return true
  }

  const formatUsers = item => {
    let nombre = item.label.split('-')
    let user = {}
    user.name = nombre[0]
    user.email = nombre[1]
    return {
      ...user,
      userId: Number(item.value),
      value: users.some(x => Number(x.userId) === item.value),
    }
  }

  const itemsToSelect = (toAssign, items, title, noItemsLegend, search, setSearch) => {
    const is =
      items && items.filter(i => filter(i, search)).length > 0
        ? items.filter(i => filter(i, search))
        : []

    return (
      <div>
        {title && <h5>{title}</h5>}

        <CustomFilter
          search={search}
          onChange={({ target }) => setSearch(target.value)}
          customFilter={<div />}
        />

        {is.length > 0 ? (
          <ul className="main-list">
            {is.map((item, index) => (
              <li key={index}>
                <div className="b-user-item">
                  <div className={'justify-center-start align-items-start bu-en left'}>
                    <div className={'b-user-name'}>{item.email}</div>
                    <div className={'b-user-email'}>{item.name}</div>
                  </div>
                  {
                    <div className={'d-flex row align-items-center mr-2 bu-b right'}>
                      <Button
                        disabled={loading || loadingV}
                        color={'accent'}
                        onClick={() => {
                          if (toAssign) {
                            let user = usersToDelete.find(m => m === item.userId)
                            if (user !== undefined) {
                              setUsersToDelete(
                                usersToDelete.filter(m => m !== item.userId),
                              )
                            }
                            setUsersToAdd([...usersToAdd, item.userId])
                            let index = selected.findIndex(
                              user => user.userId === item.userId,
                            )
                            if (index !== undefined) {
                              selected[index].value = true
                              setSelected(selected)
                            }
                          } else {
                            let user = usersToAdd.find(m => m === item.userId)
                            if (user !== undefined) {
                              setUsersToAdd(usersToAdd.filter(m => m !== item.userId))
                            } else {
                              setUsersToDelete([...usersToDelete, item.userId])
                            }

                            let index = selected.findIndex(
                              user => user.userId === item.userId,
                            )
                            if (index !== undefined) {
                              selected[index].value = false
                              setSelected(selected)
                            }
                          }
                        }}>
                        <Icon
                          tooltip={toAssign ? 'Agregar' : 'Descartar'}
                          icon={toAssign ? (mobile ? faPlus : faArrowRight) : faTrash}
                        />
                      </Button>
                    </div>
                  }
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <h6>
            {search && search !== ''
              ? 'No se encontraron usuarios con los filtros aplicados'
              : noItemsLegend}
          </h6>
        )}
      </div>
    )
  }

  return (
    <div>
      <Modal show={open && !mobile} centered size={'lg'}>
        <Modal.Header>
          <Modal.Title>{title || 'Usuarios con notificación de turnos'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Media>
            {({ breakpoints, currentBreakpoint }) => {
              const tablet = breakpoints[currentBreakpoint] > breakpoints.tablet
              const colValue = tablet ? 6 : 12

              return breakpoints[currentBreakpoint] >= breakpoints.mini ? (
                <Row>
                  {tablet && (
                    <Col xl={6} lg={6} md={12} sm={12}>
                      {itemsToSelect(
                        true,
                        selected && selected.filter(user => user.value === false),
                        'Usuarios disponibles para asignar',
                        'No hay usuarios disponibles con los filtros aplicados',
                        searchA,
                        setSearchA,
                      )}
                    </Col>
                  )}

                  <Col xl={colValue} lg={colValue} md={12} sm={12}>
                    {loadingV && (
                      <div className={'pb-custom'}>
                        <ProgressBar
                          label="Cargando"
                          animated
                          now={100}
                          style={{ marginBottom: 10 }}
                        />
                      </div>
                    )}

                    {itemsToSelect(
                      false,
                      selected.filter(user => user.value === true),
                      'Usuarios Asignados',
                      'Sin usuarios asignados',
                      searchB,
                      setSearchB,
                    )}
                  </Col>
                  {!tablet && (
                    <Col xl={12} lg={12} md={12}>
                      <Row
                        className={'container-buttons'}
                        style={{ paddingBottom: 2, display: 'flex' }}>
                        <Button
                          disabled={loading || loadingV}
                          style={{ flex: 1 }}
                          icon={faPlus}
                          right
                          color={'primary'}
                          onClick={() => setMobile(true)}>
                          Asignar usuarios
                        </Button>
                      </Row>
                    </Col>
                  )}
                </Row>
              ) : (
                <div />
              )
            }}
          </Media>
        </Modal.Body>
        <Modal.Footer>
          <Row className="container-buttons">
            <Button
              color={'secondary'}
              icon={faWindowClose}
              right
              onClick={() => hide()}
              disabled={loading || loadingV}>
              Cerrar
            </Button>
            <Button
              disabled={loadingV}
              loading={loading}
              color={'primary'}
              icon={faSave}
              right
              onClick={() =>
                setAlert({
                  show: true,
                  title: 'Asignación de notificación',
                  text: `¿Desea asignar las notificaciones a los usuarios seleccionados ?`,
                  type: 'warning',
                  showCancelButton: true,
                  cancelButtonColor: '#B35796',
                  cancelButtonText: 'Cancelar',
                  confirmButtonText: 'Asignar',
                  confirmButtonColor: '#226095',
                  onCancel: () => setAlert({ ...alert, show: false }),
                  onConfirm: () => {
                    setAlert({ ...alert, show: false })
                    let request = {
                      fieldId: notificationField || 76,
                      toAdd: usersToAdd,
                      toDelete: usersToDelete,
                    }
                    dispatch(setUsersToNotification(request))

                    hide()
                  },
                })
              }>
              Guardar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal show={mobile} centered size={'md'} onHide={() => setMobile(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Usuarios disponibles para asignar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {itemsToSelect(
            true,
            selected && selected.filter(user => user.value === false),
            null,
            'No hay usuarios disponibles con los filtros aplicados',
            searchA,
            setSearchA,
          )}
        </Modal.Body>
      </Modal>

      <Alert {...alert} />
    </div>
  )
}
