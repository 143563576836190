import './ProductItems.scss'
import React, { useState, useEffect } from 'react'
import { Row, Col, Accordion } from 'react-bootstrap'
import { BulletList as List } from 'react-content-loader'
import { CustomFilter, Card } from 'src/components'

const ProductItems = props => {
  const [get, setGet] = useState(0)
  const [search, setSearch] = useState('')
  const [items, setItems] = useState([])
  //const [cpShow, setCPShow] = useState(false);

  useEffect(() => {
    const { customItems } = props
    const companies = []
    customItems.forEach(i => {
      const company = companies.find(c => c.value === i.companyId)
      if (!company) companies.push({ value: i.companyId, label: i.companyName })
    })

    let groups = []
    let indexS = []

    customItems.forEach(p => {
      const companyId = p.companyId
      if (props.expense && p.expense && groups.filter(g => g.expense).length === 0) {
        const companyProducts = customItems.filter(pp => pp.expense)
        groups.push({
          companyId: companyId,
          companyName: 'Gastos asociados',
          products: companyProducts,
          expense: true,
        })
      }
      if (indexS.indexOf(companyId) === -1) {
        indexS.push(companyId)

        const companyProducts = customItems.filter(
          pp => pp.companyId === companyId && (props.expense ? !pp.expense : true),
        )

        groups.push({
          companyId: companyId,
          companyName: props.expense ? 'Productos' : p.companyName,
          products: companyProducts,
        })
      }
    })
    setItems(groups)
    setGet(get + 1)
  }, [props])

  const productFilter = item => {
    let see = false
    Object.keys(item).forEach(i => {
      const textValue = JSON.stringify(item[i])
      if (textValue?.toUpperCase().indexOf(search?.toUpperCase()) > -1) {
        see = true
      }
    })
    return search ? see : true
  }

  const filterCompany = item => {
    return item.products.filter(productFilter).filter(props.filter).length > 0
  }

  return (
    <div className={'pi'}>
      <Row>
        {!props.hideFilter && (
          <Col xl={12} style={{ marginBottom: 0 }}>
            <Card>
              {' '}
              <CustomFilter
                style={{ padding: 0 }}
                igStyle={{ margin: 0 }}
                mt={0}
                placeholder={'  Buscar por empresa/ nombre de producto / precio'}
                onChange={({ target }) => setSearch(target.value)}
                search={search}
              />
            </Card>
          </Col>
        )}

        <Col xl={12} md={12} className={'pi-scroll'} style={props.style}>
          {props.loading ? (
            <List />
          ) : items.filter(filterCompany).length > 0 ? (
            <ul>
              {items.filter(filterCompany).map((group, ig) => (
                <li key={ig}>
                  <div className={'column'}>
                    {!props.hideSticky && (
                      <Card
                        bodyStyle={{ margin: 0 }}
                        style={{ marginBottom: 0 }}
                        className={'pi-sticky left'}>
                        {group.companyName}
                      </Card>
                    )}
                    {/*
                    <div className={'pi-sticky left'}>{group.companyName}</div>
                    */}
                    <Card bodyStyle={{ padding: '3px' }} className={'pi-container'}>
                      <ul>
                        <Accordion defaultActiveKey={'0'}>
                          {group.products
                            .filter(productFilter)
                            .filter(props.filter)
                            .map(props.renderItem)}
                        </Accordion>
                      </ul>
                    </Card>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <h4>{props.noItems}</h4>
          )}
        </Col>
      </Row>

      {/*        <Modal
            show={cpShow}
            centered
            size={'lg'}
            onHide={() => setCPShow(false)}>
            <Modal.Header closeButton><Modal.Title>Crear producto</Modal.Title></Modal.Header>

            <Modal.Body><CreateProduct modal onlyCloseModal={(response) => {
                setCPShow(false);
                props.recharge({
                    companyId: response.companyId,
                    companyName: company.name,
                    existence: 0,
                    productActive: true,
                    productCode: response.code,
                    productFactor: response.transformationFactor,
                    productId: response.id,
                    productName: response.name,
                    productPrice: response.price,
                    subProductId: response.subProductId,
                    subProducts: []
                });
            }}/></Modal.Body>
        </Modal>
        */}
    </div>
  )
}

export default React.memo(ProductItems)
