import { useState, useEffect } from 'react'

/**
 * A hook that triggers when all boolean values in an array are true.
 * It checks the boolean array for changes and sets the state to true if all values are true.
 *
 * @param {boolean[]} flags - Array of boolean states to monitor.
 * @returns {boolean} The state indicating whether all flags are true.
 */
const useAllTrue = (flags: boolean[]): boolean => {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    const areAllTrue = flags.every(flag => flag)
    setReady(areAllTrue)
  }, [flags])

  return ready
}
export default useAllTrue
