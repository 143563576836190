import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { rootReducer } from './root.reducer'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootStore = createStore(
  persistedReducer,
  {},
  composeEnhancers(applyMiddleware(thunk)),
)

export const store = rootStore
export const persistor = persistStore(store)

export const persist = callback => persistStore(rootStore, null, callback)
