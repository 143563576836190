import '../Reports.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Row, Col, Modal, Container } from 'react-bootstrap'
import { Line } from 'react-chartjs-2'
import {
  TableV2,
  Title,
  CustomTabs,
  Card,
  Select,
  Icon,
  CustomDate,
} from 'src/components'
import {
  faArrowDown,
  faArrowUp,
  faBook,
  faDownload,
  faInfoCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'

import { actionTypes, getPatrimonialReport } from 'src/actions/company.actions'
import { selectPatrimonialReport } from 'src/selectors/company.selector'

import { formatDateFromMillis } from 'src/utils/formatters'
import { selectCurrentCompany, selectCurrentModule } from 'src/selectors/user.selector'

import {
  types,
  getExcelByReport,
  onSetModalOrder,
  onSetModalPurchase,
} from 'src/actions/utilities.actions'
import { selectReportLog } from 'src/selectors/utilities.selector'

import { toMoney } from 'src/utils/utilities'
import { selectCurrentCurrency } from 'src/selectors/currencies.selector'

import { loadingSelector } from 'src/selectors/loading.selector'

import PatrimonialReportValues from './PatrimonialReportValues'

let min = 0,
  max = 0

const properties = {
  fill: true,
  lineTension: 0.1,
  borderCapStyle: 'butt',
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderWidth: 3,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: 'rgba(75,192,192,1)',
  pointHoverBorderColor: 'rgba(220,220,220,1)',
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10,
}

class PatrimonialReport extends Component {
  state = {
    options: {
      scales: {
        xAxes: [{ stacked: true, gridLines: { display: false } }],
        yAxes: [
          {
            id: 'accounts',
            stacked: true,
            type: 'linear',
            ticks: {
              beginAtZero: true,
              callback: function (label) {
                return label > 999999 || label < -999999
                  ? Number(label / 1000000).toFixed(0) + 'M'
                  : label > 999 || label < -999
                  ? Number(label / 1000).toFixed(0) + 'k'
                  : label
              },
              max: max,
              min: min,
            },
            position: 'left',
          },
          {
            id: 'patrimonial',
            type: 'linear',
            ticks: {
              beginAtZero: true,
              callback: function (label) {
                return label > 999999 || label < -999999
                  ? Number(label / 1000000).toFixed(0) + 'M'
                  : label > 999 || label < -999
                  ? Number(label / 1000).toFixed(0) + 'k'
                  : label
              },
              max: max,
              min: min,
            },
            position: 'right',
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            let label = data.datasets[tooltipItem.datasetIndex].label || ''
            let formatCurrency = amount => {
              if (amount === null) return ''
              return toMoney(amount)
            }

            if (label) {
              label += ': '
            }
            label += formatCurrency(tooltipItem.yLabel)
            return label
          },
        },
      },
      legend: {
        position: 'left',
        labels: {
          fontSize: 12,
          fontStyle: 'bold',
          fontColor: '#282c34',
          fontFamily: 'Montserrat',
        },
      },
    },
    optionsMobile: {
      scales: {
        xAxes: [{ stacked: true, gridLines: { display: false } }],
        yAxes: [
          {
            id: 'accounts',
            stacked: true,
            type: 'linear',
            ticks: {
              beginAtZero: true,
              callback: function (label) {
                return label > 999999 || label < -999999
                  ? Number(label / 1000000).toFixed(0) + 'M'
                  : label > 999 || label < -999
                  ? Number(label / 1000).toFixed(0) + 'k'
                  : label
              },
              max: max,
              min: min,
            },
            position: 'left',
          },
          {
            id: 'patrimonial',
            type: 'linear',
            ticks: {
              beginAtZero: true,
              callback: function (label) {
                return label > 999999 || label < -999999
                  ? Number(label / 1000000).toFixed(0) + 'M'
                  : label > 999 || label < -999
                  ? Number(label / 1000).toFixed(0) + 'k'
                  : label
              },
              max: max,
              min: min,
            },
            position: 'right',
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            let label = data.datasets[tooltipItem.datasetIndex].label || ''
            let formatCurrency = amount => {
              if (amount === null) return ''
              return toMoney(amount)
            }

            if (label) {
              label += ': '
            }
            label += formatCurrency(tooltipItem.yLabel)
            return label
          },
        },
      },
      legend: {
        position: 'top',
        align: 'start',
        labels: {
          fontSize: 12,
          fontStyle: 'bold',
          fontColor: '#282c34',
          fontFamily: 'Montserrat',
        },
      },
    },
    startDate: new Date(),
    endDate: new Date(),
    type: { value: 0, label: 'Seleccione un Filtro' },
    showDetail: false,
    showResume: false,
    showReference: false,
    parentId: null,
    selected: {},
    showInfo: false,
    types: [
      { value: 1, label: 'Inventario' },
      { value: 2, label: 'Cuentas por Cobrar' },
      { value: 3, label: 'Cuentas por Pagar' },
      { value: 4, label: 'Bancos' },
      { value: 5, label: 'Depósitos' },
      { value: 6, label: 'Órdenes' },
      { value: 7, label: 'Transferencia' },
      { value: 8, label: 'Compras/Gastos' },
    ],
    selectedType: [],
    showAccounting: false,
    //merchant, bank, cpc, cpp, cash, total
    accounting: [
      { name: 'Mercadería', assets: 0, liabilities: 0 },
      { name: 'Bancos', assets: 0, liabilities: 0 },
      { name: 'Cuentas por Cobrar', assets: 0, liabilities: 0 },
      { name: 'Cuentas por Pagar', assets: 0, liabilities: 0 },
      { name: 'Efectivo Circulante', assets: 0, liabilities: 0 },
      { name: 'Patrimonio', assets: 0, liabilities: 0 },
    ],
    transaction: [
      { name: 'Inventario', assets: 0, liabilities: 0 },
      { name: 'Cuentas por Cobrar', assets: 0, liabilities: 0 },
      { name: 'Cuentas por Pagar', assets: 0, liabilities: 0 },
      { name: 'Bancos', assets: 0, liabilities: 0 },
      { name: 'Depósitos', assets: 0, liabilities: 0 },
      { name: 'Órdenes', assets: 0, liabilities: 0 },
      { name: 'Transferencia', assets: 0, liabilities: 0 },
      { name: 'Compras/Gastos', assets: 0, liabilities: 0 },
    ],
  }

  componentDidMount() {
    this.updateData()
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { loadingLog } = this.props
    const { loading } = this.props
    const { report } = next
    const { options, optionsMobile } = this.state

    if (loadingLog && !next.loadingLog) this.setState({ showDetail: true })

    if (loading && !next.loading) {
      let h = 0
      report &&
        report.forEach(item => {
          if (item.cpp < min) min = Number(item.cpp)
          h =
            Number(item.total || 0) +
            Number(item.bank || 0) +
            Number(item.cpc || 0) +
            Number(item.card || 0) +
            Number(item.transportist || 0)
          if (h > max) max = Number(h)
        })
      options.scales.yAxes.forEach(r => {
        r.ticks.min = Number(min + min * 0.2).toFixed(2)
        r.ticks.max = Number(max + max * 0.2).toFixed(2)
      })

      optionsMobile.scales.yAxes.forEach(r => {
        r.ticks.min = Number(min + min * 0.2).toFixed(2)
        r.ticks.max = Number(max + max * 0.2).toFixed(2)
      })
      this.setState({ options, optionsMobile })
    }
  }

  cleanTables = () => {
    this.setState(
      {
        accounting: [
          { name: 'Mercadería', assets: 0, liabilities: 0 },
          { name: 'Bancos', assets: 0, liabilities: 0 },
          { name: 'Cuentas por Cobrar', assets: 0, liabilities: 0 },
          { name: 'Cuentas por Pagar', assets: 0, liabilities: 0 },
          { name: 'Efectivo Circulante', assets: 0, liabilities: 0 },
          { name: 'Patrimonio', assets: 0, liabilities: 0 },
        ],
        transaction: [
          { name: 'Inventario', assets: 0, liabilities: 0 },
          { name: 'Cuentas por Cobrar', assets: 0, liabilities: 0 },
          { name: 'Cuentas por Pagar', assets: 0, liabilities: 0 },
          { name: 'Depósitos', assets: 0, liabilities: 0 },
          { name: 'Edición de Balance', assets: 0, liabilities: 0 },
          { name: 'Órdenes', assets: 0, liabilities: 0 },
          { name: 'Transferencia', assets: 0, liabilities: 0 },
          { name: 'Compras/Gastos', assets: 0, liabilities: 0 },
        ],
      },
      () => this.getAccountingTable(),
    )
  }

  getAccountingTable = () => {
    const { accounting, transaction } = this.state
    const { report } = this.props
    let total, bank, cpc, cpp, cash
    report.filter(this.filter).forEach((r, index) => {
      if (index !== 0) {
        total = r.total - (r.prevTotal || r.total)
        if (total > 0) accounting[0].assets += total
        else accounting[0].liabilities += total

        bank = r.bank - (r.prevBank || r.bank)
        if (bank > 0) accounting[1].assets += bank
        else accounting[1].liabilities += bank

        cpc = r.cpc - (r.prevCpc || r.cpc)
        if (cpc > 0) accounting[2].assets += cpc
        else accounting[2].liabilities += cpc

        cpp = r.cpp - (r.prevCpp || r.cpp)
        if (cpp > 0) accounting[3].assets += cpp
        else accounting[3].liabilities += cpp

        cash =
          Number(r.transportist || 0) -
          (Number(r.prevDeposits || 0) || Number(r.transportist || 0))
        if (cash > 0) accounting[4].assets += cash
        else accounting[4].liabilities += cash
      }
      if (r.value > 0) transaction[r.fieldId - 1].assets += r.value
      else transaction[r.fieldId - 1].liabilities += r.value
    })
    this.setState({ report, showAccounting: true })
  }

  updateData = () => {
    const { startDate, endDate } = this.state
    let sDate = startDate.setHours(0, 0, 0, 0).valueOf()
    let eDate = endDate.setHours(23, 59, 59, 59).valueOf()
    this.props.getPatrimonialReport(sDate, eDate, 1, 0)
  }

  onDateFromChange = date => {
    if (date) {
      this.setState({ startDate: date }, () => this.updateData())
    }
  }

  onDateToChange = date => {
    if (date) {
      this.setState({ endDate: date }, () => this.updateData())
    }
  }

  getChartData = () => {
    const { report } = this.props
    let data = report.map(item => {
      return {
        ...item,
        heritage: Number(item.result),
      }
    })
    return {
      labels: data.filter(this.filter).map(r => {
        return formatDateFromMillis(r.date)
      }),
      datasets: [
        {
          data: data.filter(this.filter).map(r => {
            return r.heritage
          }),
          label: 'Patrimonio',
          yAxisID: 'patrimonial',
          borderColor: `rgb(37, 140, 16)`,
          pointBorderColor: 'rgb(133,231,115)',
          pointBackgroundColor: '#fff',
          ...properties,
          borderWidth: 4,
        },
        {
          data: data.filter(this.filter).map(r => {
            return Number(r.bank || 0)
          }),
          label: 'Bancos',
          yAxisID: 'accounts',
          borderColor: `rgb(203, 97, 16)`,
          backgroundColor: 'rgba(203, 97, 16, 0.3)',
          ...properties,
        },
        {
          data: data.filter(this.filter).map(r => {
            return Number(r.total || 0)
          }),
          label: 'Mercadería',
          yAxisID: 'accounts',
          borderColor: `rgb(234, 214, 27)`,
          backgroundColor: 'rgba(234, 214, 27, 0.3)',
          ...properties,
        },
        {
          data: data.filter(this.filter).map(r => {
            return Number(r.cpc || 0)
          }),
          label: 'Cuentas por Cobrar',
          yAxisID: 'accounts',
          borderColor: `rgb(18, 119, 149)`,
          backgroundColor: 'rgba(18, 119, 149, 0.3)',
          ...properties,
        },
        {
          data: data.filter(this.filter).map(r => {
            return Number(r.transportist || 0)
          }),
          label: 'Efectivo Circulante',
          yAxisID: 'accounts',
          borderColor: `rgb(194, 82, 219)`,
          backgroundColor: 'rgba(194, 82, 219, 0.3)',
          ...properties,
        },
        {
          data: data.filter(this.filter).map(r => {
            return Number(r.card || 0)
          }),
          label: 'Cobros con tarjeta',
          yAxisID: 'accounts',
          borderColor: `rgb(96, 82, 219)`,
          backgroundColor: 'rgba(96, 82, 219, 0.3)',
          ...properties,
        },
        {
          data: data.filter(this.filter).map(r => {
            return Number(r.cpp || 0)
          }),
          label: 'Cuentas por pagar',
          yAxisID: 'accounts',
          borderColor: `rgb(217, 33, 70)`,
          backgroundColor: 'rgba(217, 33, 70, 0.4)',
          ...properties,
        },
        {
          data: data.filter(this.filter).map(r => {
            return Number(r.positiveBalance || 0)
          }),
          label: 'Cuenta por pagar Clientes',
          yAxisID: 'accounts',
          borderColor: `rgb(192,192,192)`,
          backgroundColor: 'rgba(220,220,220)',
          ...properties,
        },
      ],
    }
  }

  downloadExcel = () => {
    const { startDate, endDate } = this.state
    let sDate = startDate.setHours(0, 0, 0, 0).valueOf()
    let eDate = endDate.setHours(23, 59, 59, 59).valueOf()
    this.props.downloadExcel(sDate, eDate)
  }

  renderArrow = (prev, next, color) => {
    return prev > next ? (
      <Icon
        icon={faArrowDown}
        style={{ marginLeft: 10 }}
        iconStyle={{ fontSize: '1.05rem' }}
        tooltip={toMoney(next - (prev || next))}
        color={color ? color : '#ef5151'}
      />
    ) : prev === next ? (
      <></>
    ) : (
      <Icon
        style={{ marginLeft: 10 }}
        iconStyle={{ fontSize: '1.05rem' }}
        tooltip={toMoney(next - (prev || next))}
        icon={faArrowUp}
        color={color ? color : '#20ce20'}
      />
    )
  }

  renderButtons = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className={'kolo-newImage report-download'} style={{ marginRight: 5 }}>
          <Icon
            icon={faDownload}
            color={'white'}
            size={'3x'}
            onClick={this.downloadExcel}
            tooltip={'Descargar Reporte'}
          />
        </div>
        <div
          className={'kolo-newImage'}
          style={{
            padding: '1px 10px',
            backgroundColor: 'blue',
            marginTop: 35,
            marginRight: 5,
          }}>
          <Icon
            icon={faInfoCircle}
            color={'white'}
            size={'3x'}
            onClick={() => this.setState({ showInfo: true })}
            tooltip={'Información'}
          />
        </div>
        <div
          className={'kolo-newImage'}
          style={{ padding: '1px 10px', backgroundColor: 'blue', marginTop: 35 }}>
          <Icon
            icon={faBook}
            color={'white'}
            size={'3x'}
            onClick={() =>
              this.setState(
                {
                  accounting: [
                    { name: 'Mercadería', assets: 0, liabilities: 0 },
                    { name: 'Bancos', assets: 0, liabilities: 0 },
                    { name: 'Cuentas por Cobrar', assets: 0, liabilities: 0 },
                    { name: 'Cuentas por Pagar', assets: 0, liabilities: 0 },
                    { name: 'Efectivo Circulante', assets: 0, liabilities: 0 },
                    { name: 'Patrimonio', assets: 0, liabilities: 0 },
                  ],
                },
                () => this.cleanTables(),
              )
            }
            tooltip={'Cuentas'}
          />
        </div>
      </div>
    )
  }

  renderInfo = (title, information) => {
    return (
      <>
        <Row style={{ marginBottom: 8, borderBottom: '2px dotted grey' }}>
          <h3>{title}</h3>
        </Row>
        {information &&
          information.map(i => (
            <>
              <Row style={{ marginBottom: 8, borderBottom: '1px dotted lightgrey' }}>
                <Col className={'text-center'} xs={5} md={5} xl={5}>
                  <h5>{i.subtitle}</h5>
                </Col>
                <Col
                  className={'text-center'}
                  style={{ display: 'flex', alignSelf: 'center', padding: 0 }}
                  xs={6}
                  md={6}
                  xl={5}>
                  {i.values.map(v => (
                    <>
                      <div
                        className={'permission'}
                        style={{
                          justifyContent: 'center',
                          backgroundColor: v.up ? 'limegreen' : 'lightcoral',
                          marginBottom: 0,
                        }}>
                        <label style={{ marginRight: 5, padding: 5, color: 'white' }}>
                          <b>{v.label}</b>
                        </label>
                        {this.renderArrow(v.up ? -10 : 10, 5, 'white')}
                      </div>
                      {v.condition && (
                        <div style={{ marginRight: 15 }}>
                          <b>Ó</b>
                        </div>
                      )}
                    </>
                  ))}
                </Col>
              </Row>
            </>
          ))}
      </>
    )
  }

  deleteFilter = index => {
    const { selectedType } = this.state
    selectedType.splice(index, 1)
    this.setState({ selectedType })
  }

  filterType = item => {
    const { selectedType } = this.state
    let s = selectedType.findIndex(s => s.value === item.value)
    return s === -1
  }

  filter = item => {
    const { selectedType } = this.state
    if (selectedType.length === 0) return true
    let s = selectedType.findIndex(s => s.value === item.fieldId)
    return s !== -1
  }

  render() {
    const {
      startDate,
      endDate,
      type,
      showInfo,
      selectedType,
      types,
      showAccounting,
      accounting,
      transaction,
      optionsMobile,
      options,
    } = this.state
    const { loading, report } = this.props

    return (
      <Card>
        <Title title={'Reporte Patrimonial'} />
        <Row>
          <Col xs={12} sm={12} md={3} xl={3}>
            <CustomDate
              label={'Desde:'}
              value={startDate}
              onDayChange={this.onDateFromChange}
            />
          </Col>
          <Col xs={12} sm={12} md={3} xl={3}>
            <CustomDate
              label={'Hasta:'}
              value={endDate}
              onDayChange={this.onDateToChange}
              disabledDays={{ before: startDate }}
            />
          </Col>
          <Col xs={12} sm={12} md={3} xl={3}>
            <Select
              label={'Filtro:'}
              options={types.filter(this.filterType)}
              value={type}
              onChange={e => {
                selectedType.push(e)
                this.setState({ selectedType })
              }}
              required
            />
          </Col>
        </Row>
        <Row>
          <div className={'input-wrapper has-feedback mt-3'}>
            <label className={'g-title'}>Filtros Aplicados</label>
            <Row>
              {selectedType.map((r, index) => (
                <div className={'permission'} key={`${index}`} style={{ marginLeft: 10 }}>
                  <label style={{ marginRight: 5, paddingTop: 5 }}>{r.label}</label>
                  <Icon
                    className={'delete-button'}
                    icon={faTimes}
                    tooltip={'Quitar'}
                    color={'white'}
                    onClick={() => this.deleteFilter(index)}
                  />
                </div>
              ))}
            </Row>
          </div>
        </Row>
        <Row>
          <Col>
            {!loading && report && (
              <>
                <div className="ru-graph-w">
                  <Line data={this.getChartData} height={200} options={options} />
                </div>
                <div className="ru-graph-m">
                  <Line data={this.getChartData} height={400} options={optionsMobile} />
                </div>
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <div>{this.renderButtons()}</div>
            </Row>

            <PatrimonialReportValues items={report.filter(this.filter)} />
          </Col>
        </Row>

        <Modal
          show={showInfo}
          size={'lg'}
          centered
          onHide={() => this.setState({ showInfo: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Información</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <b>Las acciones registradas afectan a las siguientes cuentas:</b>
              {this.renderInfo('Ventas', [
                {
                  subtitle: 'Venta al contado',
                  values: [
                    { label: 'Efectivo Circulante', up: true },
                    { label: 'Mercadería', up: false },
                  ],
                },
                {
                  subtitle: 'Venta al crédito',
                  values: [
                    { label: 'Cuentas por Cobrar', up: true },
                    { label: 'Mercadería', up: false },
                  ],
                },
              ])}
              {this.renderInfo('Clientes', [
                {
                  subtitle: 'Abono a Cuentas',
                  values: [
                    { label: 'Efectivo Circulante', up: true },
                    { label: 'Cuentas por Pagar', up: false },
                  ],
                },
              ])}
              {this.renderInfo('Inventario', [
                {
                  subtitle: 'Transferencia/Conversión',
                  values: [
                    { label: 'Mercadería', up: true, condition: true },
                    { label: 'Mercadería', up: false },
                  ],
                },
                {
                  subtitle: 'Edición Manual',
                  values: [
                    { label: 'Mercadería', up: true, condition: true },
                    { label: 'Mercadería', up: false },
                  ],
                },
              ])}
              {this.renderInfo('Depósito', [
                {
                  subtitle: 'Depósito Aprobado',
                  values: [
                    { label: 'Bancos', up: true },
                    { label: 'Efectivo Circulante', up: false },
                  ],
                },
              ])}
              {this.renderInfo('Compra', [
                {
                  subtitle: 'Compra al Crédito',
                  values: [
                    { label: 'Mercadería', up: true },
                    { label: 'Cuentas por Pagar', up: false },
                  ],
                },
                {
                  subtitle: 'Compra con Balance',
                  values: [
                    { label: 'Mercadería', up: true },
                    { label: 'Efectivo Circulante', up: false },
                  ],
                },
                {
                  subtitle: 'Compra con Bancos',
                  values: [
                    { label: 'Mercadería', up: true },
                    { label: 'Bancos', up: false },
                  ],
                },
              ])}
              {this.renderInfo('Balance', [
                {
                  subtitle: 'Edición de Balance',
                  values: [
                    { label: 'Efectivo Circulante', up: true, condition: true },
                    { label: 'Efectivo Circulante', up: false },
                  ],
                },
              ])}
              {this.renderInfo('Merma', [
                {
                  subtitle: 'Aprobación de Merma',
                  values: [{ label: 'Mercadería', up: false }],
                },
              ])}
              {this.renderInfo('Gastos', [
                {
                  subtitle: 'Aprobación de Gastos',
                  values: [
                    { label: 'Bancos', up: false, condition: true },
                    { label: 'Efectivo Circulante', up: false },
                  ],
                },
              ])}
            </Container>
          </Modal.Body>
        </Modal>

        <Modal
          show={showAccounting}
          size={'md'}
          centered
          onHide={() => this.setState({ showAccounting: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Tabla Contable</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <CustomTabs
                items={[
                  {
                    title: `Transacciones`,
                    info: 'Muestra una tabla contable de las transacciones',
                    component: (
                      <TableV2
                        headers={[
                          {
                            label: 'Cuenta',
                            show: true,
                            value: ['name'],
                            type: 'text',
                            className: 'medium',
                          },
                          {
                            label: 'Activo',
                            show: true,
                            value: ['assets'],
                            type: 'currency',
                            className: 'mini',
                          },
                          {
                            label: 'Pasivo',
                            show: true,
                            value: ['liabilities'],
                            type: 'currency',
                            className: 'mini',
                          },
                          {
                            config: true,
                            show: true,
                            label: '',
                            className: 'mini center',
                          },
                        ]}
                        items={transaction}
                        mobileAuto
                        storageKey={`patrimonialTran`}
                      />
                    ),
                  },
                  {
                    title: `Cuentas`,
                    info: 'Muestra una tabla contable de las cuentas',
                    component: (
                      <TableV2
                        headers={[
                          {
                            label: 'Cuenta',
                            show: true,
                            value: ['name'],
                            type: 'text',
                            className: 'medium',
                          },
                          {
                            label: 'Activo',
                            show: true,
                            value: ['assets'],
                            type: 'currency',
                            className: 'mini',
                          },
                          {
                            label: 'Pasivo',
                            show: true,
                            value: ['liabilities'],
                            type: 'currency',
                            className: 'mini',
                          },
                          {
                            config: true,
                            show: true,
                            label: '',
                            className: 'mini center',
                          },
                        ]}
                        items={accounting}
                        mobileAuto
                        storageKey={`patrimonialCuentas`}
                      />
                    ),
                  },
                ]}
              />
            </Container>
          </Modal.Body>
        </Modal>
      </Card>
    )
  }
}

const mapStateToProps = state => ({
  report: selectPatrimonialReport(state),
  loading: loadingSelector([actionTypes.PATRIMONIAL_REPORT])(state),
  company: selectCurrentCompany(state),
  module: selectCurrentModule(state),
  log: selectReportLog(state),
  loadingLog: loadingSelector([types.GET_REPORT])(state),
  currentCurrency: selectCurrentCurrency(state),
})

const mapDispatchToProps = dispatch => ({
  getPatrimonialReport: (startDate, endDate, type, year) =>
    dispatch(getPatrimonialReport(startDate, endDate, type, year)),
  downloadExcel: (start, end) => dispatch(getExcelByReport(start, end)),
  onSetModalOrder: id => dispatch(onSetModalOrder(id)),
  onSetModalPurchase: id => dispatch(onSetModalPurchase(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PatrimonialReport)
