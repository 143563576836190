import './CreateOrderVisit.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import ClientIcon from 'src/assets/images/svg/client.svg'

import CustomSelect from 'src/components/inputs/Select/CustomSelect'
import CustomDatePicker from 'src/components/CustomDatePicker/CustomDatePicker'
import Button from 'src/components/buttons/Button'

import { actionTypes, getClientsSimple } from 'src/actions/clients.actions'
import { simpleClients } from 'src/selectors/clients.selector'

import { selectCurrentModule, selectCurrentUser } from 'src/selectors/user.selector'

import { Row, Col } from 'react-bootstrap'
import { parseDate } from 'react-day-picker/moment'
import moment from 'moment'
import { getUsersChildrenByModule } from 'src/actions/modules.actions'
import { isAllowed, selectUsers } from 'src/selectors/modules.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import FormTextArea from 'src/components/inputs/FormTextArea/FormTextArea'
import { clientPermissions } from 'src/enums/permissions'
import { actionTypes as orderType } from 'src/actions/orders.actions'

class CreateOrderVisit extends Component {
  state = {
    client: { value: {}, label: 'No seleccionado' },
    transporter: { value: null, label: 'No seleccionado' },
    dealer: { value: null, label: 'No seleccionado' },
    description: '',
    switchState: false,
    expireDate: parseDate(moment()),
    minDate: parseDate(moment()),
    maxDate: parseDate(moment().add(4, 'days')),
    errors: {},
  }

  componentDidMount() {
    const { isRequiredClient, isAllowed, getUsers, module, getClients, user } = this.props
    getUsers(module)
    if (isRequiredClient) {
      if (isAllowed([clientPermissions.viewAll])) getClients(null, null)
      else getClients(user.id, user.id)
    }
  }

  onDateChange = date => {
    this.setState({ expireDate: date })
  }

  validate = () => {
    const { isRequiredClient } = this.props
    const { value } = this.state.client
    const { transporter } = this.state
    const { description } = this.state
    let errors = {}
    if (isRequiredClient && !value) errors.client = 'Es necesario escoger un cliente'
    if (!transporter.value) errors.transporter = 'No hay repartidor asignado'

    if (Object.keys(errors).length === 0) {
      const { expireDate } = this.state
      const visit = {
        expireDate: moment(expireDate).valueOf(),
        client: value,
        transporter: transporter.value,
        description: description,
      }
      this.props.onCreateOrderVisit(visit)
    }
    this.setState({ errors })
  }

  onChangeSwitch = e => {
    this.setState({
      switchState: e.target.checked,
      transporter: { value: null, label: 'No seleccionado' },
      dealer: { value: null, label: 'No seleccionado' },
    })
  }

  handleInputChange = event => {
    let { name, value } = event.target
    this.setState({ [name]: value })
  }

  render() {
    const { allClientsToSelect, loading, loadingClient, isRequiredClient, users } =
      this.props
    const { expireDate, client, transporter, description, minDate, errors } = this.state
    return (
      <div>
        <Row>
          <Col>
            <Row>
              {isRequiredClient && (
                <Col md={12}>
                  <CustomSelect
                    disabled={loading}
                    loading={loadingClient}
                    label={'Cliente a visitar'}
                    placeholder={'Seleccione un cliente'}
                    options={allClientsToSelect}
                    value={client}
                    onChange={client => this.setState({ client })}
                    error={errors.client}
                    required
                  />
                </Col>
              )}
              <Col md={12}>
                <CustomSelect
                  disabled={loading}
                  loading={loading}
                  label={'Usuario encargado'}
                  placeholder={'Seleccione un usuario'}
                  options={users}
                  value={transporter}
                  onChange={transporter => this.setState({ transporter })}
                  error={errors.transporter}
                />
              </Col>
              <Col md={12}>
                <CustomDatePicker
                  disabled={loading}
                  label={'Dia de la visita'}
                  value={expireDate}
                  onDayChange={this.onDateChange}
                  format={'LL'}
                  disabledDays={[{ before: minDate }]}
                  required
                />
              </Col>
              <Col md={12}>
                <FormTextArea
                  label={'Agrege una descripcion'}
                  value={description}
                  name={'description'}
                  id={'description'}
                  rows={'3'}
                  onChange={this.handleInputChange}
                />
              </Col>
            </Row>
          </Col>
          {isRequiredClient && (
            <Col lg={7} md={7} sm={7}>
              <div className={'custom-card'}>
                <Row style={{ justifyContent: 'center' }}>
                  <img
                    src={client.picture || ClientIcon}
                    alt={'client profile'}
                    className={'picture-client'}
                  />
                </Row>
                <Row style={{ justifyContent: 'center' }}>
                  <span className={'client-name'}>
                    {client.companyName || 'desconocido'}
                  </span>
                </Row>
                <hr />
                <Row className={'flex-container space-between'}>
                  <Col xs={3} className={'label'}>
                    Código
                  </Col>
                  <Col className={'description'}>{client.code || 'desconocido'}</Col>
                </Row>
                <Row className={'flex-container space-between'}>
                  <Col xs={3} className={'label'}>
                    Teléfono
                  </Col>
                  <Col className={'description'}>{client.phone || 'desconocido'}</Col>
                </Row>
                <Row className={'flex-container space-between'}>
                  <Col xs={3} className={'label'}>
                    Dirección
                  </Col>
                  <Col className={'description'}>{client.address || 'desconocido'}</Col>
                </Row>
              </div>
            </Col>
          )}
        </Row>
        <br />
        <Row className={'container-buttons'}>
          <Button loading={loading} onClick={() => this.validate()}>
            Programar visita
          </Button>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  module: selectCurrentModule(state),
  user: selectCurrentUser(state),
  allClientsToSelect: simpleClients(state),
  users: selectUsers(state),
  isAllowed: permissions => isAllowed(state, permissions),
  loadingClient: loadingSelector([actionTypes.GET_ALL_CLIENTS])(state),
  loading: loadingSelector([
    orderType.ON_CREATE_ORDERS_VISIT,
    orderType.ON_CREATE_VISIT_ORDER,
  ])(state),
})
const mapDispatchToProps = dispatch => ({
  getUsers: module => dispatch(getUsersChildrenByModule(module, true)),
  getClients: (deliverer, vendor) => {
    let attendant = {
      force: true,
      attendant: null,
    }
    if (deliverer) attendant.attendant = deliverer
    else if (vendor) attendant.attendant = vendor

    dispatch(getClientsSimple(attendant))
  },
})
export default connect(mapStateToProps, mapDispatchToProps)(CreateOrderVisit)
