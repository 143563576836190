import { $http } from './http'

class CompanyDynamicfieldsService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  async createExtraField(extraField: DynamicField): Promise<DynamicField> {
    try {
      const response = await $http.post(`/companyextrafields/create`, extraField)

      return response.data
    } catch (error) {
      throw new Error(`Error creating extra field: ${error.message}`)
    }
  }

  async createExtraFieldWithSelectorOptions(
    extraField: DynamicField,
    option: string,
  ): Promise<DynamicField> {
    try {
      const response = await $http.post(`/companyextrafields/create`, extraField)

      return response.data
    } catch (error) {
      throw new Error(`Error creating extra field: ${error.message}`)
    }
  }

  async readAllExtraFields(): Promise<DynamicField[]> {
    try {
      const response = await $http.get(`/companyextrafields/all`)

      return response.data
    } catch (error) {
      throw new Error(`Error reading extra fields: ${error.message}`)
    }
  }

  async readExtraField(): Promise<DynamicField[]> {
    try {
      const response = await $http.get(`/companyextrafields/all`)

      return response.data
    } catch (error) {
      throw new Error(`Error reading extra fields: ${error.message}`)
    }
  }

  async readExtraFieldOptions(fieldId): Promise<OptionsSelector[]> {
    try {
      const response = await $http.get(`/companyextrafields/selectoptions/${fieldId}/all`)
      return response.data
    } catch (error) {
      throw new Error(`Error reading extra fields: ${error.message}`)
    }
  }

  async editExtraFieldOptions(fieldId, id, option): Promise<OptionsSelector> {
    try {
      const response = await $http.post(
        `/companyextrafields/selectoptions/${fieldId}/${id}/edit`,
        option,
      )
      return response.data
    } catch (error) {
      throw new Error(`Error reading extra fields: ${error.message}`)
    }
  }

  async createWithOptions(
    dynamicField: DynamicField,
    options: any,
  ): Promise<DynamicField> {
    try {
      const dataRequest = {
        dynamicField: dynamicField,
        options: options,
      }

      const response = await $http.post(
        `/companyextrafields/create/withselectoptions`,
        dataRequest,
      )

      if (!response || !response.data) {
        throw new Error('Invalid response from server')
      }

      return response.data as DynamicField
    } catch (error) {
      throw new Error(
        `Error creating with options: ${error instanceof Error ? error.message : error}`,
      )
    }
  }

  async editExtraFieldOptionsBulk(
    fieldId,
    options: OptionsSelector[],
  ): Promise<OptionsSelector[]> {
    try {
      const response = await $http.put(
        `/companyextrafields/selectoptions/${fieldId}/edit/bulkedit`,
        options,
      )
      return response.data
    } catch (error) {
      throw new Error(`Error reading extra fields: ${error.message}`)
    }
  }

  async readAllExtraFieldsByEntity(entitytype): Promise<EntityFields[]> {
    try {
      const response = await $http.get(
        `/companyextrafields/${entitytype}/viewdynamicvalues`,
      )
      return response.data
    } catch (error) {
      throw new Error(`Error reading extra fields: ${error.message}`)
    }
  }

  async deleteOptionsExtraBulk(fieldId, options): Promise<OptionsSelector[]> {
    try {
      const response = await $http.post(
        `/companyextrafields/selectoptions/${fieldId}/delete/bulkdelete`,
        options,
      )
      return response.data
    } catch (error) {
      throw new Error(`Error reading extra fields: ${error.message}`)
    }
  }

  async readEntityDynamicFieldsValues(entitytype, idclient): Promise<[]> {
    try {
      const response = await $http.get(
        `companyextrafields/${entitytype}/viewdynamicvalues/${idclient}`,
      )

      return response.data
    } catch (error) {
      throw new Error(`Error reading extra fields: ${error.message}`)
    }
  }

  async createOptionsBulk(fieldId, options): Promise<OptionsSelector[]> {
    try {
      const response = await $http.post(
        `/companyextrafields/selectoptions/${fieldId}/bulkinsert`,
        options,
      )
      return response.data
    } catch (error) {
      throw new Error(`Error reading extra fields: ${error.message}`)
    }
  }

  async editExtraField(extraField: DynamicField, idField): Promise<DynamicField> {
    try {
      const response = await $http.put(`/companyextrafields/edit/${idField}`, extraField)
      return response.data
    } catch (error) {
      throw new Error(`Error editing extra field: ${error.message}`)
    }
  }

  async deleteExtraField(id: number): Promise<void> {
    try {
      const response = await $http.post(`/companyextrafields/delete/${id}`)
    } catch (error) {
      throw new Error(`Error deleting extra field: ${error.message}`)
    }
  }

  async createOptionsSelector(
    id: number,
    optionData: string,
  ): Promise<{ value: string }> {
    try {
      const response = await $http.post(`/companyextrafields/selectoptions/${id}`, {
        value: optionData,
      })

      return response.data
    } catch (error) {
      throw new Error(`Error deleting extra field: ${error.message}`)
    }
  }
}

export default new CompanyDynamicfieldsService()
