import React from 'react'
import { formatDateFromMillis } from 'src/utils/formatters'
import { getNumber, getPhraseLabel, toMoney } from 'src/utils/utilities'
import { style } from 'src/components/Print/TicketStyles'
import { selectCompanyCountry } from 'src/selectors/company.selector'
import { useSelector } from 'react-redux'
import { getFormattedIds, haveAnyValue } from 'src/utils/utilitiesV2'
import { felDocumentType } from 'src/enums/felDocumentTypes'

const PrintInvoiceTicket = ({
  invoices = [],
  reference = null,
  logo = null,
  pos = {},
  footerPhrase,
  id = 'ticket-invoice-c',
}) => {
  const {
    title,
    subtitle,
    text,
    textContent,
    center,
    left,
    right,
    dFlex,
    body,
    spaceBetween,
    bold,
  } = style

  const country = useSelector(selectCompanyCountry)

  return (
    <div style={{ display: 'none' }}>
      <div className={'ticket'} id={id} style={{ paddingRight: 50, width: '100%' }}>
        {invoices.map((i, index) => {
          const cert = i.uuId !== null && i.uuId !== ''
          const commercialName = cert
            ? i.comercialName || pos.commercialName || i.companyDetail.name
            : i.comercialName || pos.commercialName || pos.name || i.companyDetail.name

          let typeInvoice = 'ENVIO'
          if (cert) {
            switch (i.documentType) {
              case 1:
                if (i.tipoAfiliacion === 3) typeInvoice = 'Factura Pequeño Contribuyente'
                else if (i.felDocumentType === felDocumentType.SUJETO_EXC)
                  typeInvoice = 'Factura de sujeto excluido'
                else typeInvoice = 'FACTURA'
                break
              case 2:
                typeInvoice = 'Factura Especial'
                break
              case 4:
                typeInvoice = 'NOTA DE CRÉDITO'
                break
              case 5:
                typeInvoice = 'RECIBO'
                break
              default:
                typeInvoice = 'ENVIO'
                break
            }
          }

          const identifications = getFormattedIds(
            country.id,
            i.nit,
            i.nrc,
            i.cui,
            i.foreignId,
          )

          const nullify = i.cancelled

          return (
            <div key={i.id} style={{ ...body }}>
              <div style={{ ...center }}>
                <div style={{ ...title }}>
                  {i.emisorName
                    ? i.emisorName
                    : i.companyDetail
                    ? i.companyDetail.name
                    : ''}
                </div>
                <div style={{ ...text }}>{i.commercialAddress}</div>
                {cert && <div style={{ ...text }}>NIT: {i.companyDetail.nit}</div>}
                <div style={{ ...subtitle }}>{commercialName}</div>

                {cert ? (
                  <div>
                    <div style={{ ...subtitle, marginTop: 15 }}>
                      Documento Tributario Electrónico
                    </div>
                    <div style={{ ...text }}>{typeInvoice.toUpperCase()}</div>
                    <div style={{ ...text }}>AUTORIZACIÓN:</div>
                    <div style={{ ...text }}>{i.uuId}</div>
                    <div style={{ ...text }}>Serie: {i.authSeries}</div>
                    <div style={{ ...text }}>Número: {i.authNumber}</div>
                    <div style={{ ...text }}>
                      Fecha de Emisión: {formatDateFromMillis(i.createdAt, 6)}
                    </div>
                    <div style={{ ...text }}>
                      Fecha de certificación: {formatDateFromMillis(i.date, 6)}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div style={{ ...text }}>{typeInvoice.toUpperCase()}</div>
                    <div style={{ ...text }}>
                      Fecha: {formatDateFromMillis(i.createdAt)}
                    </div>
                  </div>
                )}

                {nullify && (
                  <div style={{ ...center, ...subtitle, ...bold }}>ANULADA</div>
                )}

                <div style={{ ...dFlex }}>
                  <div style={{ ...text }}>Nombre: </div>
                  <div style={{ ...textContent }}>{i.name}</div>
                </div>

                <div>
                  {identifications.map(clientID => (
                    <div key={clientID.identificationTypeId} style={{ ...dFlex }}>
                      <div style={{ ...text }}>{clientID.label}: </div>
                      <div style={{ ...textContent }}>{clientID.value}</div>
                    </div>
                  ))}
                </div>

                <div style={{ ...dFlex }}>
                  <div style={{ ...text }}>Dirección: </div>
                  <div style={{ ...textContent }}>{i.address}</div>
                </div>

                <div style={{ ...dFlex }}>
                  <div style={{ ...text }}>Referencia: </div>
                  <div style={{ ...textContent }}>{reference}</div>
                </div>

                <div style={{ ...dFlex }}>
                  <div style={{ ...text }}>Descripción: </div>
                  <div style={{ ...textContent }}>{i.description || '--'}</div>
                </div>

                {i.commentary && (
                  <div style={{ ...dFlex }}>
                    <div style={{ ...text }}>Comentario: </div>
                    <div style={{ ...textContent }}>{i.commentary}</div>
                  </div>
                )}
              </div>

              <div style={{ ...center, marginTop: 15 }}>
                <table style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                  <thead>
                    <tr>
                      <th style={{ width: '10%', ...left, ...text }}>UNI</th>
                      <th style={{ width: '54%', ...left, ...text }}>Descripción</th>
                      <th style={{ width: '18%', ...right, ...text, paddingRight: 3 }}>
                        Precio
                      </th>
                      <th style={{ width: '18%', ...right, ...text, paddingRight: 3 }}>
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {i.invoiceDetails
                      ?.filter(d => !d.viewInSummary)
                      .map((d, index) => (
                        <tr key={index}>
                          <td style={{ width: '10%', ...left, ...text }}>{d.quantity}</td>
                          <td style={{ width: '54%', ...left }}>
                            <div style={{ ...text }}>
                              {d.description}
                              {haveAnyValue(d.variations) && ` (${d.variations})`}
                            </div>
                            {d.commentary && (
                              <div style={textContent}>{d.commentary}</div>
                            )}
                          </td>
                          <td
                            style={{ width: '18%', ...right, ...text, paddingRight: 12 }}>
                            {getNumber(d.amount / d.quantity)}
                          </td>
                          <td
                            style={{ width: '18%', ...right, ...text, paddingRight: 12 }}>
                            {getNumber(d.amount)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                {i.invoiceDetails &&
                  i.invoiceDetails
                    .filter(d => d.viewInSummary)
                    .map((item, index) => (
                      <div key={index} style={{ ...spaceBetween, ...text }}>
                        <div>{item.description}</div>
                        {toMoney(item.amount)}
                      </div>
                    ))}

                <div style={{ ...spaceBetween, ...text }}>
                  <div>Total</div>
                  {toMoney(i.total)}
                </div>
              </div>

              {cert && (
                <div style={{ ...center, marginBottom: 20 }}>
                  <div style={{ ...center }}>
                    <div style={{ ...text }}>Certificador: </div>
                    <div style={{ ...textContent }}>
                      {i.nameCert || 'Sin certificador'}
                    </div>
                  </div>
                  <div style={{ ...center }}>
                    <div style={{ ...text }}>NIT: </div>
                    <div style={{ ...textContent }}>
                      {i.nitCert || 'Sin certificador'}
                    </div>
                  </div>

                  {i.phraseTypes?.map((p, ii) => (
                    <div style={text} key={ii}>
                      {getPhraseLabel(p, i)}
                    </div>
                  ))}
                </div>
              )}

              {footerPhrase && footerPhrase !== '' && (
                <div style={{ ...textContent, ...center }}>
                  <br />
                  <br />
                  <span>{footerPhrase}</span>
                </div>
              )}

              {invoices.length !== index + 1 && (
                <hr style={{ borderTop: '1px dashed' }} />
              )}
            </div>
          )
        })}
      </div>

      <div style={{ display: 'none' }}>
        <canvas id={'invoice-canvas'} width={300} height={275} />
        {logo && (
          <img
            id={'invoice-ticket-logo'}
            alt={'logo'}
            src={`data:image/bmp;base64,${logo}`}
          />
        )}
      </div>
    </div>
  )
}
export default PrintInvoiceTicket
