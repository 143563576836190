import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Button, FormText, FormTextField, TableV2 } from 'src/components'
import { ListVariations } from 'src/content/Production/Categorization/Variation/ListVariations'
import { actionTypes, adjustVisitOrder } from 'src/actions/orders.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'

import { showAlert } from 'src/actions/alert.actions'

const ModalOrderAdjustLocal = ({ show, onHide, items, locations, orderId }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [actions, setActions] = useState({ visit: false })

  const loadingVisit = useSelector(state =>
    loadingSelector([actionTypes.ADJUST_VISIT_ORDER])(state),
  )
  const hasErrorVisit = useSelector(state =>
    hasErrorsSelector([actionTypes.ADJUST_VISIT_ORDER])(state),
  )
  const errorVisit = useSelector(state =>
    singleErrorSelector([actionTypes.ADJUST_VISIT_ORDER])(state),
  )

  const disabledReject = data.some(d => d.combo && d.quantity !== d.quantityDeliver)

  useEffect(() => {
    if (show && items?.length > 0) setData(formatItems(items))
  }, [show])

  useEffect(() => {
    if (loadingVisit) setActions({ ...actions, visit: true })
    else if (actions.visit) {
      setActions({ ...actions, visit: false })
      const alert = hasErrorVisit
        ? handlerError(errorVisit.message)
        : {
            ...handlerSuccess('Ajuste realizado con éxito'),
            onConfirm: () => onHide(true),
          }
      dispatch(showAlert(alert))
    }
  }, [loadingVisit])

  /**
   * Formatea los items agregándole la cantidad a despachar por defecto
   */
  const formatItems = data => {
    return data.map(item => ({
      id: item.id,
      listLocations: item.listLocations,
      categories: item.categories,
      name: (item.product ? item.product.name : item.productData.name) || 'Desconocido',
      code: (item.product ? item.product.code : item.productData.code) || 'Desconocido',
      commentary: item.commentary,
      quantity: item?.quantity,
      quantityDeliver:
        item.quantityDeliver !== null ? item.quantityDeliver : item.quantity,
      inventoryChangeRequestId: item.inventoryChangeRequestId,
      order: item.order,
      combo: item.useRecipe,
    }))
  }

  const validateAdjust = (quantity, adjust) => {
    if (adjust > quantity) return quantity
    else return Number(adjust)
  }

  const inputQuantity = item => {
    return (
      <FormText
        name={'deliver' + item.id}
        mt={0}
        type={'number'}
        value={item.quantityDeliver || 0}
        onChange={e => {
          let { value } = e.target
          if (!value || value < 0) value = 0
          item.quantityDeliver = validateAdjust(item.quantity, value)
          setData([...data])
        }}
      />
    )
  }

  const getHeaders = [
    {
      label: 'SKU',
      show: true,
      value: ['code'],
      type: 'text',
      className: 'product',
    },
    {
      label: 'Ítem',
      show: true,
      value: ['name'],
      type: 'text',
      className: 'large',
      style: { minWidth: 150 },
      custom: item => (
        <div>
          <label
            style={{
              display: 'flex',
              marginBottom: '0px',
            }}>
            {item.name}
          </label>

          {item.categories && <ListVariations selected={item.categories} />}
          <div>{item.commentary}</div>
          {item.combo && <div>Componente de combo</div>}
        </div>
      ),
    },
    {
      label: 'Ubicación',
      show: locations,
      value: ['listLocations'],
      type: 'text',
      className: 'mini',
      custom: item => (
        <div>
          {item.listLocations && <ListVariations selected={item.listLocations} />}
        </div>
      ),
    },
    {
      label: 'Cantidad',
      show: true,
      value: ['quantity'],
      type: 'text',
      className: 'mini',
    },
    {
      label: 'A despachar',
      show: true,
      value: ['quantityDeliver'],
      type: 'text',
      style: { minWidth: 200 },
      className: 'mini',
      custom: item => inputQuantity(item),
    },
    { config: true, show: true, label: '', className: 'mini center' },
  ]

  const adjustDispatch = type => {
    let request = {
      orderId: orderId,
      details: data,
      typeAdjust: type,
    }
    dispatch(adjustVisitOrder(orderId, request))
  }

  return (
    <div style={{ position: 'fixed' }}>
      <Modal show={show} size={'lg'} centered onHide={() => onHide()}>
        <Modal.Header closeButton>
          <Modal.Title>Ajuste</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormTextField
            id={'description'}
            label={'Descripción'}
            placeholder={`(Opcional)`}
            type={'text'}
            name={'description'}
          />
          <TableV2
            headers={getHeaders}
            mobileAuto
            storageKey={`modalOrder`}
            items={data}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            loading={loadingVisit}
            color={'primary'}
            onClick={() => {
              adjustDispatch(1)
            }}
            tooltip={`Todas las cantidades ingresadas serán despachadas, las restantes quedarán pendientes de despacho y podrán desecharse más adelante.`}
            placement={'left'}>
            Despachar y dejar pendiente
          </Button>
          <Button
            loading={loadingVisit}
            disabled={disabledReject}
            color={'accent'}
            onClick={() => {
              adjustDispatch(2)
            }}
            tooltip={`Se actualizarán las cantidades ingresadas en la orden de venta y la factura
             (FEL si fue facturada originalmente). Las cantidades pendientes del despacho serán marcadas como Rechazadas.`}>
            Despachar y rechazar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export const ModalOrderAdjust = React.memo(ModalOrderAdjustLocal)
