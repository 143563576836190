import React, { ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import { Geocoding } from 'src/components'
import ClientIcon from 'src/assets/images/svg/client.svg'

interface props {
  id: number
  onHide: () => void
}

const ClientDetailResume = ({ id, onHide }: props) => {
  const client = {}

  const [show, setShow] = useState(false)

  useEffect(() => {
    if (!id || id === 0) return
    setShow(true)
  }, [id])

  const onClose = () => {
    setShow(false)
    onHide()
  }

  return (
    <div>
      <Modal show={show} size={'lg'} centered onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Detalle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <div style={{ justifyContent: 'center', display: 'flex' }}>
                <img className={'picture-client'} src={ClientIcon} alt={'client_name'} />
              </div>
            </Col>
            <Col xl={12}>Client categories</Col>
            <Col xl={12}>
              <div className={'column'}>
                <div className={'space-between'}>
                  <div className={'b-user-name'}>Teléfono</div>
                  <div className={'b-user-email'}>45170598</div>
                </div>
                <div className={'space-between'}>
                  <div className={'b-user-name'}>Código</div>
                  <div className={'b-user-email'}>45170598</div>
                </div>
                <div className={'space-between'}>
                  <div className={'b-user-name'}>NIT</div>
                  <div className={'b-user-email'}>45170598</div>
                </div>
                <div className={'space-between'}>
                  <div className={'b-user-name'}>CUI</div>
                  <div className={'b-user-email'}>45170598</div>
                </div>
                <div className={'space-between'}>
                  <div className={'b-user-name'}>Dirección</div>
                  <div className={'b-user-email'}>45170598</div>
                </div>
                <div className={'space-between'}>
                  <div className={'b-user-name'}>Referencia</div>
                  <div className={'b-user-email'}>45170598</div>
                </div>
              </div>
            </Col>

            <Col xl={12}>
              <Geocoding
                lat={14.55}
                lng={-90.55}
                auto={false}
                defaultAddress={'12 calle'}
                editable={false}
                getData={() => undefined}
                noEdit
                withoutCard
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ClientDetailResume
