import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Col, Modal, Row } from 'react-bootstrap'
import { FormText, Paragraph, InfoNit, Select, Icon, Button } from '../../../components'
import { faFileInvoice, faTextWidth } from '@fortawesome/free-solid-svg-icons'

import { actionTypes, getProviders } from '../../../actions/producer.actions'
import { selectAllProviders } from '../../../selectors/producer.selector'

import { loadingSelector } from '../../../selectors/loading.selector'

import { getNumber, toMoney } from '../../../utils/utilities'
import { valNit } from '../../../selectors/utilities.selector'
import { actionTypes as typesC, getInfoNIT } from '../../../actions/clients.actions'
import { selectNITinfo } from '../../../selectors/clients.selector'

let interval = null

const CreditNoteCreate = ({
  show,
  initialValues,
  onClose,
  loading,
  onCreate,
  noModal,
  hideProvider,
}) => {
  const dispatch = useDispatch()

  const providers = useSelector(selectAllProviders)
  const loadingProv = useSelector(state =>
    loadingSelector([actionTypes.GET_PROVIDERS])(state),
  )

  const infoNIT = useSelector(selectNITinfo)
  const loadingN = useSelector(state => loadingSelector([typesC.GET_NIT_INFO])(state))

  const [flags, setFlag] = useState({ nit: false })

  const [note, setNote] = useState({})
  const { total, provider, number, description } = note

  useEffect(() => {
    if (!show) return
    setNote({
      total: initialValues?.amount || 0,
      provider: {
        value: initialValues?.providerId,
        nit: initialValues?.nit,
        label: initialValues?.name,
      },
    })

    if (!initialValues || !initialValues.providerId) {
      dispatch(getProviders())
    }
  }, [show])

  useEffect(() => {
    if (loadingN) setFlag({ ...flags, nit: true })
    else if (flags.nit) {
      setFlag({ ...flags, nit: false })
      setNote({
        ...note,
        provider: {
          value: null,
          nit: provider?.nit,
          label: infoNIT.clientName,
        },
      })
    }
  }, [loadingN])

  const onHide = () => {
    setNote({})
    onClose()
  }

  const renderBody = () => (
    <Row>
      <Col xl={12}>
        <Paragraph>
          Está acción creara una nota de acreditación y aumentara el saldo a favor del
          proveedor
        </Paragraph>
      </Col>

      {!hideProvider && (
        <Col xl={12}>
          <Row>
            <Col xl={6}>
              <InfoNit
                nit={provider?.nit}
                disabled={loading || initialValues?.providerId || loadingN}
                onChange={({ target }) => {
                  let { value } = target
                  if (!value) value = ''
                  let newNit = ''
                  value.split('-').forEach(v => (newNit += v))
                  value = newNit
                  newNit = ''
                  value.split('/').forEach(v => (newNit += v))

                  setNote({ ...note, provider: { ...note.provider, nit: newNit } })
                  if (valNit(newNit)) {
                    clearTimeout(interval)
                    interval = setTimeout(() => {
                      if (newNit.toUpperCase() !== 'CF') {
                        let array = providers.filter(s => s.nit === newNit)
                        if (array.length > 0) setNote({ ...note, provider: array[0] })
                        else dispatch(getInfoNIT(newNit))
                      }
                    }, 500)
                  }
                }}
              />
            </Col>
            <Col xl={6}>
              <Select
                required
                label={'Proveedor'}
                value={provider}
                options={providers}
                disabled={loading || initialValues?.providerId}
                onChange={value => setNote({ ...note, provider: value })}
                loading={loadingProv || loadingN}
              />
            </Col>
          </Row>
        </Col>
      )}

      <Col xl={6}>
        <FormText
          label={'Monto'}
          value={getNumber(total)}
          type={'number'}
          disabled={initialValues?.amount}
          changeValue={value => {
            if (value < 0) value = 0
            setNote({ ...note, total: value })
          }}
          required
        />
      </Col>
      <Col xl={6}>
        <FormText
          value={number}
          prepend={<Icon tooltip={'documento'} icon={faFileInvoice} />}
          label={'Documento de referencia'}
          changeValue={value => setNote({ ...note, number: value })}
          disabled={loading}
        />
      </Col>
      <Col xl={12}>
        <FormText
          as={'textarea'}
          rows={'3'}
          prepend={<Icon tooltip={'concepto'} icon={faTextWidth} />}
          label={'Concepto'}
          value={description}
          changeValue={value => setNote({ ...note, description: value })}
          disabled={loading}
          max={450}
        />
      </Col>
    </Row>
  )

  const renderActions = () => (
    <Row className={'container-buttons'}>
      <Button
        disabled={!provider?.nit || !provider?.label || !total || total <= 0}
        loading={loading}
        onClick={() => {
          onCreate({
            total,
            number,
            description,
            nit: provider.nit,
            providerId: provider.value,
            providerName: provider.label,
          })
        }}>
        Acreditar
      </Button>
    </Row>
  )

  return noModal ? (
    <div>
      <div>{renderBody()}</div>
      <div className={'mt-4'}>{renderActions()}</div>
    </div>
  ) : (
    <Modal show={show} size={'lg'} centered onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Nota de Acreditación</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderBody()}</Modal.Body>
      <Modal.Footer>{renderActions()}</Modal.Footer>
    </Modal>
  )
}
export default CreditNoteCreate
