import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './reducers'
import ReactBreakpoints from 'react-breakpoints'
import { $http } from './services/http'
import { selectCurrentCompany, selectCurrentModule } from './selectors/user.selector'
import FirebaseProvider from './components/FirebaseProvider/FirebaseProvider'
import { BugsnagErrorBoundary } from './components/BugsnagErrorBoundary'
import { FlagProvider } from './providers/FlagProvider'

const breakpoints = {
  mini: 10,
  mobile: 360,
  mobileLandscape: 480,
  toMini: 590,
  tablet: 768,
  mobileTable: 800,
  mobileTable2: 992,
  tabletLandscape: 1024,
  desktop: 1200,
  desktopLarge: 1500,
  desktopWide: 1920,
}

function onBeforeLift() {
  const state = store.getState()
  const company = selectCurrentCompany(state)
  const module = selectCurrentModule(state)

  if (company !== null) {
    $http.defaults.headers.common['COTIZAP-Company'] = company

    if (module !== null) {
      $http.defaults.headers.common['COTIZAP-Module'] = module
    }
  }

  if (state.user) state.user.currentUser = state.user.currentUser || {}
}

ReactDOM.render(
  <BugsnagErrorBoundary>
    <Provider store={store}>
      <PersistGate
        loading={<div>Hola Mundo</div>}
        persistor={persistor}
        onBeforeLift={onBeforeLift}>
        <ReactBreakpoints breakpoints={breakpoints}>
          <FirebaseProvider>
            <FlagProvider>
              <App />
            </FlagProvider>
          </FirebaseProvider>
        </ReactBreakpoints>
      </PersistGate>
    </Provider>
  </BugsnagErrorBoundary>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
