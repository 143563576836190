import { $http } from './http'

class AccountbookService {
  getAllItems = async () => {
    const response = await $http.get(`/accounting`)
    return response.data
  }

  getItem = async itemId => {
    const response = await $http.get(`/accounting/${itemId}`)
    return response.data
  }

  getLog = async itemId => {
    const response = await $http.get(`/accounting/log/${itemId}`)
    return response.data
  }

  updateItem = async (itemId, request) => {
    const response = await $http.put(`/accounting/${itemId}`, request)
    return response.data
  }

  createItem = async request => {
    const response = await $http.post(`/accounting`, request)
    return response.data
  }

  deleteItem = async (itemId, request) => {
    const response = await $http.put(`/accounting/deleted/${itemId}`, request)
    return response.data
  }
}

export default new AccountbookService()
