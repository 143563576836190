import React from 'react'

import DynamicFormPresentation from 'src/components/DynamicFormPresentation/DynamicFormPresentation'

interface IOrderDetailDataProps {
  order: IOrderDetails
}

const presentations: IDynamicFormPresentation[] = [
  { label: 'Código del cliente', values: ['client', 'code'], type: 'text' },
  { label: 'Cliente', values: ['client', 'name'], type: 'text' },
  { label: 'Estado', values: ['status'], type: 'text' },
  { label: 'Fecha de ingreso', values: ['createdAt'], type: 'date' },
  { label: 'Fecha de confirmación', values: ['finishedAt'], type: 'date' },
  { label: 'Fecha del pago', values: ['paymentAt'], type: 'date' },
  { label: 'Ingresado por', values: ['createdBy'], type: 'text' },
  { label: 'Confirmado por', values: ['confirmedBy'], type: 'text' },
]

const OrderDetailData = ({ order }: IOrderDetailDataProps) => {
  return (
    <div>
      <DynamicFormPresentation data={order} presentation={presentations} />
      <hr />
    </div>
  )
}
export default OrderDetailData
