export const selectPurchaseStatus = (id, expense) => {
  let response = { id, name: 'default' }

  switch (id) {
    case 1:
      response.name = 'Ingresada'
      break
    case 2:
      if (expense) response.name = 'Cuenta por Pagar'
      else response.name = 'Cuenta por Pagar'
      break
    case 3:
      response.name = 'Completada'
      break
    case 4:
      response.name = 'Cancelada'
      break
    case 5:
      response.name = 'Reservado'
      break
    case 6:
      response.name = 'Anulada'
      break
    case 7:
      response.name = 'Pendiente de inventariar'
      break
    case 8:
      response.name = 'Pendiente de certificar'
      break
    case 9:
      response.name = 'Pendiente de crear '
      break

    default:
      return
  }

  return response
}

export const selectPurchase = state => state.purchase.createPurchase
export const selectPurchases = state => state.purchase.purchases
export const selectPurchaseSummative = state => state.purchase.summative
export const selectTotalPurchases = state => state.purchase.totalPurchase
export const selectPurchaseLog = state => state.purchase.log
export const selectSinglePurchase = state => state.purchase.purchase
export const selectStep = state => state.purchase.step
export const selectUpdatedResponse = state => state.purchase.updated
export const selectNullifyResponse = state => state.purchase.nullifyResponse
export const selectPurchasesSimple = state => state.purchase.simplePurchases
export const selectMultipleAccreditation = state => state.purchase.accreditMultiple
export const selectPurchaseTransactions = status => status.purchase.transactions
export const selectPurchaseDocuments = status => status.purchase.documents
export const selectPurchasesSingle = status => status.purchase.purchasesSingle
export const selectPurchaseInvoices = status => status.purchase.invoice
