import { $http } from './http'

class AccountService {
  getCards = async () => {
    const response = await $http.get('/card')
    return response.data
  }

  createCard = async (request: ICreateCreditCardRequest) => {
    const response = await $http.post('/card', request)
    return response.data
  }

  deleteCard = async (id: number) => {
    const response = await $http.delete(`/card/${id}`)
    return response.data
  }

  favoriteCard = async (id: number) => {
    const response = await $http.put(`/card/${id}`)
    return response.data
  }

  getFavoriteCard = async () => {
    const response = await $http.get(`/card/favorite`)
    return response.data
  }
}

export default new AccountService()
