import React, { useEffect, useState } from 'react'
import { Button, Card, FormText, Icon } from 'src/components'
// import {useAccordionToggle} from "react-bootstrap/AccordionToggle";
import {
  Accordion,
  useAccordionButton as useAccordionToggle,
  Col,
  Modal,
  Row,
} from 'react-bootstrap'
import { formatNumberWithCommas } from 'src/utils/formatters'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Money from 'src/components/Money/Money'

function RenderItems({ eventKey, item, onMove, validated }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {})
  return (
    <li key={eventKey} style={{ padding: 0, 'list-style': 'none' }}>
      <div className="b-user-item" style={{ position: 'relative' }}>
        <div
          className="justify-content-start align-items-start bu-en"
          onClick={() => {
            decoratedOnClick()
          }}
          style={{ textAlign: 'left' }}
        >
          <div className="b-user-name">{item.productName}</div>
          <Money className="b-user-email">{item.price}</Money>
        </div>

        <div
          className={'d-flex row align-items-center mr-2 bu-b'}
          style={{ textAlign: 'right' }}
        >
          <Row
            className={'container-buttons'}
            style={{ paddingBottom: 2, display: 'flex' }}
          >
            <FormText
              disabled
              style={{ flex: 1, marginLeft: 5 }}
              igStyle={{ margin: 0, padding: 0 }}
              mt={0}
              type={'number'}
              placeholder={'Cantidad de unidades'}
              name={'quantity'}
              value={item.quantity}
            />
          </Row>
        </div>
      </div>
      <Accordion.Collapse eventKey={eventKey}>
        <div className={'pi-more'} style={{ padding: 15, paddingBottom: 5 }}>
          {item.series
            .filter(d => !!d.validated === validated)
            .map((d, index) => (
              <Row style={{ border: '1px solid lightblue', borderRadius: 5 }}>
                {!!d.validated && (
                  <Col md={2} style={{ textAlign: 'center', alignSelf: 'center' }}>
                    <Icon
                      icon={faArrowLeft}
                      size={'2x'}
                      onClick={() => onMove(d.id)}
                      tooltip={'No inventariado'}
                    />
                  </Col>
                )}
                <Col md={10}>
                  <FormText
                    value={d.serie}
                    disabled
                    label={'Número de serie unidad No.' + (index + 1)}
                    //type={'number'}
                  />
                </Col>
                {!d.validated && (
                  <Col md={2} style={{ textAlign: 'center', alignSelf: 'center' }}>
                    <Icon
                      icon={faArrowRight}
                      size={'2x'}
                      onClick={() => onMove(d.id)}
                      tooltip={'Inventariar'}
                    />
                  </Col>
                )}
              </Row>
            ))}
        </div>
      </Accordion.Collapse>
    </li>
  )
}

const InventoryPurchase = ({ data, modal, show, onHide, onSave }) => {
  const [items, setItems] = useState([])

  useEffect(() => {
    if (data) setItems(data)
  }, [data])

  const move = id => {
    let r = items.map(p => {
      let s = []
      if (p.series) {
        s = p.series.map(s => {
          if (s.id === id) {
            s.validated = !s.validated
          }
          return s
        })
      }
      return { ...p, series: s }
    })
    setItems([...r])
  }

  const filter = (item, validated) => {
    if (item.series)
      return item.series.filter(s => !!s.validated === validated).length > 0
    return true
  }

  const renderButtons = () => {
    return (
      <Button
        color={'primary'}
        disabled={items.filter(d => filter(d, false)).length > 0}
        onClick={() => onSave()}
      >
        Confirmar
      </Button>
    )
  }

  const renderContent = () => {
    return (
      <Row>
        <Col>
          <Card white title={'No Inventariado'}>
            <Accordion defaultActiveKey={'0'}>
              <ul
                style={{
                  border: '1px solid lightgray',
                  padding: 0,
                  margin: 0,
                  overflowY: 'auto',
                  minHeight: 500,
                  maxHeight: 550,
                }}
              >
                {items
                  .filter(d => filter(d, false))
                  .map((item, index) => (
                    <RenderItems
                      validated={false}
                      item={item}
                      eventKey={index}
                      onMove={id => move(id)}
                    />
                  ))}
              </ul>
            </Accordion>
          </Card>
        </Col>
        <Col>
          <Card white title={'Inventariado'}>
            <Accordion defaultActiveKey={'0'}>
              <ul
                style={{
                  border: '1px solid lightgray',
                  padding: 0,
                  margin: 0,
                  overflowY: 'auto',
                  minHeight: 500,
                  maxHeight: 550,
                }}
              >
                {items
                  .filter(d => filter(d, true))
                  .map((item, index) => (
                    <RenderItems
                      validated
                      item={item}
                      eventKey={index}
                      onMove={id => move(id)}
                    />
                  ))}
              </ul>
            </Accordion>
          </Card>
        </Col>
        {!modal && renderButtons()}
      </Row>
    )
  }

  return modal ? (
    <Modal show={show} centered size={'lg'} onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Inventariar productos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Debe inventariar todo el producto para poder confirmar la compra</h5>
        {items && renderContent()}
      </Modal.Body>
      <Modal.Footer>{renderButtons()}</Modal.Footer>
    </Modal>
  ) : (
    items && renderContent()
  )
}

export default InventoryPurchase
