import './Dashboard.scss'
import 'src/content/CompanyList/CompanyList.scss'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Placeholder from 'src/components/Placeholder/Placeholder'

import { Row, Col } from 'react-bootstrap'
import { Media } from 'react-breakpoints'

import {
  actionTypes as moduleActionTypes,
  actionTypes as types,
  getUsersChildrenByModule,
} from 'src/actions/modules.actions'
import {
  isAllowed,
  selectCurrentUserRoles,
  selectUsers,
} from 'src/selectors/modules.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { Card, Icon, Select, TableV2 } from 'src/components'
import { selectCurrentModule, selectCurrentUser } from 'src/selectors/user.selector'
import { selectSetCurrentCompany } from 'src/selectors/company.selector'

import {
  selectDTotalProducts,
  selectProductDashboard,
} from 'src/selectors/products.selector'
import {
  selectBalanceReport,
  selectDashboardOrders,
  selectDTotalOrders,
} from 'src/selectors/orders.selector'
import {
  formatDateFromMillis,
  formatNumberWithCommas,
  getItemValue,
} from 'src/utils/formatters'
import box from 'src/assets/images/svg/box-closed.svg'
import { selectPurchaseSummative } from 'src/selectors/purchase.selector'
import { history } from 'src/App'
import { selectMenuRoutes } from 'src/selectors/dashboard.selector'
import Dropdown from 'src/components/buttons/Dropdown'
import { onSetModalOrder } from 'src/actions/utilities.actions'
import { downloadPrintableInvoiceByOrder } from 'src/actions/resolutions.actions'

import { onShowModalWelcome } from 'src/actions/utilities.actions'
import SelectedDates from 'src/components/SelectedDates/SelectedDates'
import { selectAllPOSUser } from 'src/selectors/restaurant.selector'
import { getAllPOSByUser } from 'src/actions/restaurant.actions'
import Money from 'src/components/Money/Money'
import {
  getCompanyDashboard,
  actionTypes as companyActionTypes,
} from 'src/actions/company.actions'
import { onPrintInvoice } from 'src/actions/orders.actions'
import { actionTypes as pdfActions } from 'src/actions/resolutions.actions'

const bag =
  'https://firebasestorage.googleapis.com/v0/b/cotizap-prod.appspot.com/o/icons%2FIcono_Desembolsos.svg?alt=media&token=5b17c3b6-943f-4580-b16a-483dc1fc1141'
const expenses =
  'https://firebasestorage.googleapis.com/v0/b/cotizap-prod.appspot.com/o/modules%2FIcono_Gastos.svg?alt=media&token=73ba0f2b-c14c-485e-9042-16c9cf70e4d6'

const Dashboard = props => {
  const dispatch = useDispatch()
  const company = useSelector(selectSetCurrentCompany)

  const products = useSelector(selectProductDashboard)
  const total = useSelector(selectDTotalProducts)

  const orders = useSelector(selectDashboardOrders)
  const totalO = useSelector(selectDTotalOrders)

  const posUser = useSelector(selectAllPOSUser)
  const balance = useSelector(selectBalanceReport)

  const summative = useSelector(selectPurchaseSummative)

  const currentModule = useSelector(selectCurrentModule)
  const loading = useSelector(state => loadingSelector([types.GET_SUBMODULES])(state))
  const user = useSelector(selectCurrentUser)
  const users = useSelector(selectUsers)
  const loadGroups = useSelector(state =>
    loadingSelector([moduleActionTypes.GET_MODULE_GROUPS])(state),
  )
  const routes = useSelector(selectMenuRoutes)

  const roles = useSelector(selectCurrentUserRoles)
  const loadingRoles = useSelector(state =>
    loadingSelector([moduleActionTypes.GET_CURRENT_USER_ROLES])(state),
  )
  const loadingDashboard = useSelector(state =>
    loadingSelector([companyActionTypes.GET_COMPANY_DASHBOARD])(state),
  )

  const loadingInvoice = useSelector(state =>
    loadingSelector([pdfActions.DOWNLOAD_PRINTABLE_INVOICE_BY_ORDER])(state),
  )

  const dashboardSee = useSelector(state => isAllowed(state, [1004]))
  const seeOrders = useSelector(state => isAllowed(state, [1100, 1105]))
  const seeProducts = useSelector(state => isAllowed(state, [7105]))

  const [action, setAction] = useState({ load: false, roles: false })
  const [userFilter, setUserFilter] = useState({ value: null, label: 'Todos' })
  const [pos, setPOS] = useState({ value: null, label: 'Todos los puntos de venta' })
  const [dates, setDates] = useState({
    dateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    dateTo: new Date(new Date().setHours(23, 59, 59, 59)),
  })
  const [orderAction, setOrderAction] = useState(0)

  useEffect(() => {
    if (props.noFunctionality || !company.id) return

    dispatch(getAllPOSByUser(user.id))
    if (!loadingRoles && !loadGroups) {
      const Ad = isAllowed({ modules: { currentUserRoles: roles } }, [1001])
      const dG = isAllowed({ modules: { currentUserRoles: roles } }, [1004])
      const dP = isAllowed({ modules: { currentUserRoles: roles } }, [1005])
      const dO = isAllowed({ modules: { currentUserRoles: roles } }, [1100, 1105])
      const dW = isAllowed({ modules: { currentUserRoles: roles } }, [1151, 1154])
      if (!(dG || dP || Ad)) {
        if (dO) history.replace('/distribucion/ordenes/false')
        else if (dW) history.replace('/distribucion/bodegas')
        else
          history.replace(
            routes.length > 1
              ? routes[1].modules && routes[1].modules.length > 0
                ? routes[1].modules[0].link
                : '/'
              : '/',
          )
      }
    }
  }, [company.id])

  useEffect(() => {
    if (loadingRoles || roles.length === 0) return
    if (dashboardSee) dispatch(getUsersChildrenByModule(currentModule, true))
    setup({
      sDate: dates.start,
      eDate: dates.end,
      posId: pos.value,
      confirmedById: userFilter.value,
    })
  }, [loadingRoles])

  useEffect(() => {
    if (loadingRoles) {
      action.roles = true
      setAction({ ...action })
    } else if (action.roles) {
      setAction({ ...action, roles: false })
      const Ad = isAllowed({ modules: { currentUserRoles: roles } }, [1000])
      const dG = isAllowed({ modules: { currentUserRoles: roles } }, [1004])
      const dP = isAllowed({ modules: { currentUserRoles: roles } }, [1005])
      const dO = isAllowed({ modules: { currentUserRoles: roles } }, [1100, 1105])
      const dW = isAllowed({ modules: { currentUserRoles: roles } }, [1151, 1154])
      if (!(dG || dP || Ad)) {
        if (dO) history.replace('/distribucion/ordenes/false')
        else if (dW) history.replace('/distribucion/bodegas')
        else
          history.replace(
            routes.length > 1
              ? routes[1].modules && routes[1].modules.length > 0
                ? routes[1].modules[0].link
                : '/'
              : '/',
          )
      }
    }
  }, [loadingRoles])

  useEffect(() => {
    if (!dates.filter) return
    setup({
      sDate: dates.start,
      eDate: dates.end,
      posId: pos.value,
      confirmedById: userFilter.value,
    })
  }, [dates])

  const setup = ({ sDate, eDate, posId, confirmedById } = {}) => {
    let start = dates.dateFrom.valueOf()
    let end = dates.dateTo.valueOf()
    if (sDate) start = new Date(sDate).setHours(0, 0, 0, 0).valueOf()
    if (eDate) end = new Date(eDate).setHours(23, 59, 59, 59).valueOf()

    dispatch(
      getCompanyDashboard({
        start,
        end,
        confirmedById,
        posId,
      }),
    )

    if (company.test) {
      const seeVideo = localStorage.getItem(`welcome-${company.id}`)
      if (!seeVideo) {
        localStorage.setItem(`welcome-${company.id}`, company.id)
        dispatch(onShowModalWelcome(true))
      }
    }
  }

  const getActions = item => {
    return (
      <Dropdown
        onClick={() => setOrderAction(item.id)}
        loading={loadingInvoice && orderAction === item.id}
        items={[
          { title: 'Detalle completo', action: () => dispatch(onSetModalOrder(item.id)) },
          {
            show:
              !item.deleted &&
              (item.status === 3 ||
                item.status === 15 ||
                item.status === 13 ||
                item.status === 5 ||
                item.status === 17 ||
                item.status === 18),
            title: 'Ver facturas',
            action: () => {
              dispatch(
                onPrintInvoice({
                  active: true,
                  orderId: item.id,
                  defaultName: item?.number,
                  print: false,
                  documentType: 1,
                }),
              )
            },
          },
          {
            show:
              !item.deleted &&
              (item.status === 3 ||
                item.status === 5 ||
                item.status === 17 ||
                item.status === 18),
            title: 'Descargar facturas',
            action: () => {
              dispatch(downloadPrintableInvoiceByOrder(item.id, item.number, 1000))
            },
          },
        ]}
      />
    )
  }

  const getOrderHeaders = [
    { label: 'Código', show: true, value: ['number'], type: 'text' },
    { label: 'Cliente', show: true, value: ['client', 'name'], type: 'text' },
    {
      label: 'Fecha',
      show: true,
      value: ['enabledAt'],
      type: 'text',
      custom: item => formatDateFromMillis(item.enabledAt || item.createdAt),
    },
    { label: 'Pago', show: true, value: ['paymentTypeData', 'name'], type: 'text' },
    { label: 'Estado', show: true, value: ['statusData', 'name'], type: 'text' },
    { label: 'Total', show: true, value: ['total'], type: 'currency' },
    {
      label: '',
      config: true,
      show: true,
      custom: item => getActions(item),
    },
  ]

  const getProductsMobile = () => {
    let array = []
    let store = localStorage.getItem(`d-prod-${user.id}`)
    if (!store)
      store = {
        title: { value: ['productCode'], type: 'text' },
        subtitle: { value: ['productName'], type: 'text' },
        complement1: { value: ['productPrice'], type: 'currency' },
        complement2: { value: ['productSale'], type: 'number' },
      }
    else store = JSON.parse(store)

    products.forEach(item => {
      let more = [
        { title: `Código: `, info: item.productCode },
        { title: `Nombre: `, info: item.productName },
        { title: `Fecha de Creación: `, info: formatDateFromMillis(item.createdAt) },
        { title: `Precio: `, info: formatNumberWithCommas(item.productPrice) },
        { title: `U. vendidas: `, info: item.productSale },
      ]

      let object = {
        id: item.id,
        title: getItemValue(item, store.title.value, store.title.type),
        subtitle: getItemValue(item, store.subtitle.value, store.subtitle.type),
        complement1: getItemValue(item, store.complement1.value, store.complement1.type),
        complement2: getItemValue(item, store.complement2.value, store.complement2.type),
        key: `d-prod-${item.id}`,
        more,
      }
      array.push(object)
    })
    return array
  }

  const getProductsHeaders = () => {
    return [
      { label: '', show: true, select: true, value: ['image', 'url'], type: 'code' },
      {
        label: 'Código',
        show: window.innerWidth < 480,
        value: ['productCode'],
        type: 'text',
      },
      { label: 'Nombre', show: true, value: ['productName'], type: 'text' },
      {
        label: 'Fecha de creación',
        show: window.innerWidth < 480,
        value: ['createdAt'],
        type: 'date',
      },
      { label: 'U. vendida', show: true, value: ['productSale'], type: 'number' },
    ]
  }

  const renderFooter = (text, link, condition) => {
    return condition ? (
      <div className={'d-flex justify-content-end'} onClick={() => history.push(link)}>
        <div
          style={{ color: '#226095', fontWeight: 500, cursor: 'pointer' }}
          className={'border-bottom'}>
          {text}
        </div>
      </div>
    ) : (
      <div />
    )
  }

  return loading && !props.noFunctionality ? (
    <Row>
      {[0, 0, 0].map((i, index) => (
        <Col key={index} md={4}>
          <Placeholder />
        </Col>
      ))}
    </Row>
  ) : (
    <div style={{ position: 'relative', width: '100%' }}>
      {(loadingDashboard || loadingRoles) && !props.noFunctionality && (
        <div className={'dashboard-loading'}>
          <Icon
            size={'5x'}
            spin={loadingDashboard || loadingRoles}
            className={'dashboard-loading-icon'}
            color={'#226095'}
          />
        </div>
      )}
      <Media>
        {({ breakpoints, currentBreakpoint }) => {
          const tablet = breakpoints[currentBreakpoint] > breakpoints.tablet
          const mobile = breakpoints[currentBreakpoint] < breakpoints.tablet

          return breakpoints[currentBreakpoint] >= breakpoints.mini ? (
            <div>
              <Row>
                <Col
                  style={{ display: 'flex', alignItems: 'end' }}
                  xl={8}
                  lg={8}
                  md={12}
                  sm={12}
                  xs={12}>
                  <h1 className={'dashboard-welcome'}>
                    {company.shortName || company.name}
                  </h1>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} lg={6}>
                  <SelectedDates
                    mt={0}
                    tablet
                    nonExecute
                    initialPreset={{ value: 2, label: 'Este mes' }}
                    mobile={mobile}
                    dates={dates}
                    // dateFrom={dates.start}
                    // dateTo={dates.end}
                    onDateChange={(start, end) => {
                      if (end !== null)
                        setDates({ dateFrom: start, dateTo: end, filter: true })
                    }}
                  />
                </Col>
                {dashboardSee && (
                  <Col sm={12} md={6} lg={3}>
                    <Select
                      mt={0}
                      label={'Entregado por'}
                      info={'Filtra las ordenes por el usuario que la entrego'}
                      value={userFilter}
                      options={[{ value: null, label: 'Todos' }, ...users]}
                      onChange={newUser => {
                        if (userFilter.value === newUser.value) return
                        setUserFilter(newUser)
                        setup({
                          sDate: dates.start,
                          eDate: dates.end,
                          posId: pos.value,
                          confirmedById: newUser.value,
                        })
                      }}
                    />
                  </Col>
                )}
                {/* <Col sm={12} md={6} lg={3}>
                  <Select
                    mt={0}
                    options={[
                      { value: null, label: 'Todos los estados' },
                      ...orderStatus,
                    ]}
                    value={status}
                    label={'Buscar por estado'}
                    onChange={value => {
                      setStatus(value)
                    }}
                  />
                </Col> */}
                <Col sm={12} md={6} lg={3}>
                  <Select
                    mt={0}
                    label={'Por punto de venta'}
                    value={pos}
                    info={'Filtra las ordenes por el punto de venta'}
                    options={[{ value: null, label: '- Todos -' }, ...posUser]}
                    onChange={newPos => {
                      if (pos.value === newPos.value) return
                      setPOS(newPos)
                      setup({
                        sDate: dates.start,
                        eDate: dates.end,
                        posId: newPos.value,
                        confirmedById: userFilter.value,
                      })
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} xl={4} className={'mt-2'}>
                  <Card className={'h-100'}>
                    <Row className="align-items-center">
                      <Col xs={9} md={8} lg={8} xl={8}>
                        <h6 className={'dashboard-title dashboard-f-color'}>
                          TOTAL DE VENTAS
                        </h6>
                        <Money
                          component="h4"
                          className={'dashboard-currency'}
                          style={{ fontWeight: 700 }}>
                          {balance.total}
                        </Money>
                      </Col>
                      <Col xs={3} md={4} lg={4} xl={4} style={{ textAlign: 'center' }}>
                        <img src={bag} height={80} width={80} alt={'bag'} />
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col sm={12} xl={4} className={'mt-2'}>
                  <Card className={'h-100'}>
                    <Row className="align-items-center">
                      <Col xs={8} md={8} lg={8} xl={8}>
                        <h6 className={'dashboard-title dashboard-f-color'}>
                          TOTAL DE GASTOS
                        </h6>
                        <Money component="h4" className={'dashboard-currency'}>
                          {summative.summative}
                        </Money>
                      </Col>
                      <Col xs={4} md={4} lg={4} xl={4} style={{ textAlign: 'center' }}>
                        <img src={expenses} height={80} width={80} alt={'expenses'} />
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col sm={12} xl={4} className={'mt-2'}>
                  <Card bodyClass={'dashboard-card-blue-body'} className={'h-100'}>
                    <h6 className={'dashboard-title'}>UTILIDAD BRUTA</h6>
                    <Money component="h4" style={{ fontWeight: 700 }}>
                      {balance.purchase}
                    </Money>
                  </Card>
                </Col>
              </Row>
            </div>
          ) : (
            <div />
          )
        }}
      </Media>
      <Row className={'mt-3'}>
        <Col sm={12} md={12} lg={8}>
          <Card
            className={'h-100'}
            footer={renderFooter(
              'Ver todas las órdenes',
              'distribucion/ordenes/false',
              seeOrders,
            )}>
            <div
              className={'mb-2 d-flex justify-content-between'}
              style={{ alignItems: 'center' }}>
              <div>
                <h4 className={'dashboard-currency'} style={{ color: '#226095' }}>
                  Últimos pedidos
                </h4>
                <h6 className={'dashboard-subtitle dashboard-f-color'}>
                  {totalO > 10 ? 10 : totalO} PEDIDOS EN TOTAL
                </h6>
              </div>
            </div>
            <TableV2
              storageKey={`d-ord-${user.id}`}
              total={totalO > 10 ? 10 : totalO}
              hideFilter
              headers={getOrderHeaders}
              items={orders}
              mobileAuto
              customClass={'scrollX'}
            />
          </Card>
        </Col>
        <Col sm={12} md={12} lg={4}>
          <Card
            className={'h-100'}
            footer={renderFooter(
              'Ver todos los productos',
              'distribucion/productos',
              seeProducts,
            )}>
            <h4 className={'dashboard-currency'} style={{ color: '#226095' }}>
              Productos más vendidos
            </h4>
            <h6 className={'dashboard-subtitle dashboard-f-color mb-4'}>
              {total > 10 ? 10 : total} PRODUCTOS EN TOTAL
            </h6>
            <TableV2
              storageKey={`d-prod-${user.id}`}
              items={products}
              customClass={'scrollX'}
              headers={getProductsHeaders()}
              hideFilter
              total={total > 10 ? 10 : total}
              itemsMobile={getProductsMobile()}
              renderRow={(item, index) => (
                <tr key={index} className={'data'}>
                  <td className={`text-center`}>
                    <img
                      src={item.image ? item.image.url : box}
                      alt={item.productName}
                      height={30}
                      width={30}
                      style={{ objectFit: 'cover', borderRadius: '50%' }}
                    />
                  </td>
                  <td className={`text-left medium`}>{item.productName}</td>
                  <td className={`text-left`}>
                    {formatNumberWithCommas(item.productSale || 0)} U.
                  </td>
                </tr>
              )}
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Dashboard
