import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Modal } from 'react-bootstrap'
import {
  faSave,
  faTextWidth,
  faTimes,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons'

import {
  Icon,
  FormText as TextField,
  Select,
  Button,
  CustomDate,
  SwitchV2,
} from 'src/components'
import ButtonIcon from 'src/components/buttons/IconButton'
import Folder, { categoryType } from 'src/components/folders/Folder'

import { setInfo } from 'src/actions/purchase.actions'
import { selectPurchase } from 'src/selectors/purchase.selector'

import { selectAllWarehousesForSelect } from 'src/selectors/warehouse.selector'
import { valNit, trim } from 'src/selectors/utilities.selector'
import { selectCurrentModule } from 'src/selectors/user.selector'
import { isAllowed, selectUsers } from 'src/selectors/modules.selector'
import { getUsersChildrenByModule } from 'src/actions/modules.actions'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  actionTypes as typeC,
  getAllCategorizations,
} from 'src/actions/categorization.actions'
import { selectCurrentCurrency } from 'src/selectors/currencies.selector'
import { expensePermissions, purchasePermissions } from 'src/enums/permissions'

const PurchaseForm = props => {
  const dispatch = useDispatch()

  const module = useSelector(selectCurrentModule)
  const users = useSelector(selectUsers)
  const purchase = useSelector(selectPurchase)
  const warehouses = useSelector(selectAllWarehousesForSelect)
  //const provider = useSelector(selectProvider)
  const categorization = useSelector(selectAllCategorizations)
  const loadingAssigned = useSelector(state =>
    loadingSelector([typeC.GET_ASSIGNED, typeC.GET_ALL])(state),
  )

  const another = useSelector(state =>
    isAllowed(state, [expensePermissions.createOnBehalfOfOtherUser]),
  )
  const skipCategoriesPurchases = useSelector(state =>
    isAllowed(state, [purchasePermissions.skipCategoryEntry]),
  )
  const skipCategoriesExpenses = useSelector(state =>
    isAllowed(state, [expensePermissions.skipCategoryEntry]),
  )
  const canChangeAddInventory = useSelector(state =>
    isAllowed(state, [purchasePermissions.canChangeAddInventory]),
  )
  const canChangeRequirePayments = useSelector(state =>
    isAllowed(state, [
      props?.isPurchase
        ? purchasePermissions.canChangeRequirePayments
        : expensePermissions.canChangeRequirePayments,
    ]),
  )

  const canSkipCategories = props.isPurchase
    ? skipCategoriesPurchases
    : skipCategoriesExpenses

  const currentCurrency = useSelector(selectCurrentCurrency)

  const [selectedCategories, setSelectedCategories] = useState([])
  const [showCategorization, setShowCategorization] = useState({ show: false })

  useEffect(() => {
    dispatch(getUsersChildrenByModule(module, true))
    dispatch(getAllCategorizations(props.isPurchase ? 6 : 2))
  }, [])

  const changeText = ({ target }) => {
    const { value, name } = target
    purchase[name] = name === 'nit' || name === 'invoice' ? trim(value) : value
    dispatch(setInfo(purchase))
  }

  const getTotal = (discount, q) => {
    let response = 0
    if (purchase.products)
      purchase.products.forEach(s => {
        if (purchase.exchangeLocal) {
          if (q)
            response += Number(
              s.customPrice || Number(s.subTotal || 0) + Number(s.tax || 0),
            )
          else
            response += parseFloat(
              Number(
                (Number(s.subTotal || 0) + Number(s.tax || 0)) / purchase.conversion,
              ).toFixed(2),
            )
        } else {
          if (q)
            response += Number(
              s.customPrice ||
                (Number(s.subTotal || 0) + Number(s.tax || 0)) * purchase.conversion,
            )
          else response += Number(Number(s.subTotal || 0) + Number(s.tax || 0))
        }
      })

    if (discount) response -= discount

    return response
  }

  const validate = () => {
    const { products, nit, invoice, warehouse, checked, removeInventory, emissionAt } =
      purchase
    if (!props.noValidate && props.isPurchase && checked && !valNit(nit)) return true
    else if (!products || products.length <= 0) return true
    else if (props.isPurchase && checked && !invoice) return true
    else if (!emissionAt) return true
    else if (!canSkipCategories && selectedCategories.length === 0) return true
    else
      return (
        (props.isPurchase || removeInventory) &&
        (!warehouse || !warehouse.value) &&
        purchase.addInventory
      )
  }

  return (
    <div>
      <Row>
        {another && !props.isPurchase && (
          <Col xl={12} md={12} sm={12}>
            <Select
              label={'Crear bajo el nombre del siguiente usuario:'}
              options={[{ value: null, label: 'sin seleccionar' }, ...users]}
              value={purchase.confirmBy}
              onChange={d => dispatch(setInfo({ ...purchase, confirmBy: d }))}
              disabled={props.loading}
            />
          </Col>
        )}

        {!props.order && (
          <>
            <Col xl={12} lg={12} md={12} sm={12}>
              <CustomDate
                disabled={props.loading}
                label={`Fecha de emisión de ${
                  props.isPurchase ? 'la compra' : 'el gasto'
                }`}
                value={purchase.emissionAt}
                disabledDays={{ after: new Date() }}
                required
                onDayChange={e => dispatch(setInfo({ ...purchase, emissionAt: e }))}
              />
            </Col>
          </>
        )}

        <Col xl={12} lg={12} sm={12}>
          <Row className={'pl-1 mt-1'}>
            {selectedCategories.map((p, i) => (
              <div className={'user-tag product-t ml-2'} key={i}>
                <label className={'label-user-tag'}>{p.name}</label>
                <ButtonIcon
                  className={'delete-user-tag d-product-t'}
                  icon={faTimes}
                  tooltip={'Quitar'}
                  color={'white'}
                  onClick={() => {
                    setSelectedCategories(selectedCategories.filter(f => f.id !== p.id))
                    dispatch(
                      setInfo({
                        ...purchase,
                        categories: selectedCategories.filter(f => f.id !== p.id),
                      }),
                    )
                  }}
                />
              </div>
            ))}
            <Button
              style={{ marginLeft: 15 }}
              color={'primary'}
              onClick={() => {
                setShowCategorization({
                  ...showCategorization,
                  show: true,
                  isOrder: true,
                })
                dispatch(getAllCategorizations(props.isPurchase ? 6 : 2))
              }}>
              Agregar categorías
            </Button>
            {!canSkipCategories && selectedCategories.length === 0 && (
              <div style={{ marginLeft: '15px' }} className={'ft-sub left'}>
                Se debe de seleccionar al menos una categoría.
              </div>
            )}
          </Row>
          <hr />
        </Col>

        <Col xl={12}>
          <Row>
            {canChangeAddInventory && props.isPurchase && (
              <Col xl={12}>
                <SwitchV2
                  label={'Generar inventario'}
                  info={
                    'Al confirmarse la compra ingresará inventario en la bodega seleccionada.'
                  }
                  checked={purchase.addInventory}
                  onChange={addInventory =>
                    dispatch(setInfo({ ...purchase, addInventory }))
                  }
                />
              </Col>
            )}

            {!props.order && (props.isPurchase ? purchase.addInventory : true) && (
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <Select
                  disabled={props.loading}
                  required={props.isPurchase}
                  label={'Bodega'}
                  placeholder={'Selecciona una bodega'}
                  options={warehouses}
                  value={purchase.warehouse}
                  onChange={d => dispatch(setInfo({ ...purchase, warehouse: d }))}
                />
              </Col>
            )}

            {!props.isPurchase && purchase.warehouse && !props.order && (
              <Col xl={12} lg={12} md={12}>
                <SwitchV2
                  label={'Reducir inventario'}
                  info={
                    'Al activar esta opción removerá el inventario en existencia de los ítems seleccionados en el gasto.'
                  }
                  checked={purchase.removeInventory}
                  onChange={removeInventory =>
                    dispatch(setInfo({ ...purchase, removeInventory }))
                  }
                  disabled={props.loading}
                />
              </Col>
            )}

            {canChangeRequirePayments && (
              <Col xl={12}>
                <SwitchV2
                  label={'Requerir pagos'}
                  info={
                    'Para confirmar se requerirá ingresar pagos o dejarla como cuenta por pagar.'
                  }
                  checked={purchase.addPayments}
                  onChange={addPayments =>
                    dispatch(setInfo({ ...purchase, addPayments }))
                  }
                />
              </Col>
            )}
          </Row>
        </Col>

        {purchase.addPayments && (
          <Col xl={12} lg={12} md={12} sm={12}>
            <CustomDate
              disabled={props.loading}
              label={`Agregar fecha de Pago`}
              value={purchase.paymentDate}
              disabledDays={[{ before: new Date() }]}
              onDayChange={e => dispatch(setInfo({ ...purchase, paymentDate: e }))}
            />
          </Col>
        )}

        {props.isPurchase && !props.order && (
          <>
            <Col xl={12} lg={12} md={12}>
              <hr />
            </Col>
            {!props.certificate && (
              <Col xl={6} lg={6} md={12}>
                <TextField
                  //prependMoneySymbol
                  prepend={
                    props.importation ? 'Q' : props?.currencies?.currentCurrency.symbol
                  }
                  name={'subtotal'}
                  label={props.importation ? 'Total' : 'Subtotal'}
                  value={
                    props.importation
                      ? Number(getTotal(null, true)).toFixed(2)
                      : getTotal()
                  }
                  onChange={changeText}
                  disabled
                />
              </Col>
            )}
            {!props.importation && !props.certificate && (
              <Col xl={6} lg={6} md={12}>
                <TextField
                  prepend={'Q'}
                  name={'discount'}
                  label={'Descuento'}
                  value={purchase.discount}
                  type={'number'}
                  onChange={({ target }) => {
                    let { value } = target
                    if (!value || value < 0) value = 0

                    if (value > getTotal()) value = getTotal()

                    dispatch(setInfo({ ...purchase, discount: value }))
                  }}
                  disabled={props.loading}
                />
              </Col>
            )}
            {purchase.conversion !== 1 ? (
              <Col xl={6} lg={6} md={12}>
                <TextField
                  prepend={props.importation ? '$' : currentCurrency.symbol}
                  name={'total'}
                  label={'Total'}
                  value={getTotal(purchase.discount)}
                  onChange={changeText}
                  disabled
                />
              </Col>
            ) : (
              <></>
            )}
          </>
        )}

        <Col xl={12} md={12}>
          <TextField
            prepend={<Icon tooltip={'Comentario'} icon={faTextWidth} />}
            name={'description'}
            label={'Opcional, ingresa un comentario al gasto a registrar'}
            value={purchase.description}
            onChange={changeText}
            as={'textarea'}
            rows={3}
            disabled={props.loading}
          />
        </Col>

        {props.isPurchase && props.process && !props.order && (
          <Col
            className={'mt-5'}
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            style={{ paddingBottom: 2, display: 'flex' }}>
            <Button
              style={{ flex: 1 }}
              disabled={validate()}
              color={'primary'}
              onClick={() => {
                props.jumpToStep(1)
              }}>
              Procesar
            </Button>
          </Col>
        )}

        <Col xl={12}>
          <Modal.Footer>
            <Row className={'container-buttons'}>
              <Button
                disabled={props.loading}
                color={'secondary'}
                icon={faWindowClose}
                onClick={() => dispatch(setInfo({ ...purchase, show: false }))}>
                Cerrar
              </Button>
              <Button
                loading={props.loading}
                disabled={!props.order && validate()}
                icon={faSave}
                right
                onClick={() => props.save()}>
                Guardar
              </Button>
            </Row>
          </Modal.Footer>
        </Col>
      </Row>

      <Folder
        noMessage
        onHide={() =>
          setShowCategorization({
            ...showCategorization,
            show: false,
            filter: false,
            isOrder: false,
          })
        }
        onAssign={item => {
          let d = selectedCategories.find(d => d.id === item.id)
          if (!d) {
            selectedCategories.push(item)
            setSelectedCategories([...selectedCategories])
            dispatch(setInfo({ ...purchase, categories: selectedCategories }))
          }
        }}
        data1={
          categorization && categorization.children ? categorization.children[0] : {}
        }
        data2={
          categorization && categorization.children ? categorization.children[1] : {}
        }
        show={showCategorization.show}
        loading={loadingAssigned}
        list={selectedCategories.map(p => p.id)}
        type={props.isPurchase ? categoryType.PURCHASE : categoryType.EXPENSES}
      />
    </div>
  )
}
export default PurchaseForm
