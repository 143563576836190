export const selectParcels = (state, type) =>
  state.parcel.parcels
    .filter(p => !type || p.type === type)
    .map(p => ({ ...p, value: p.id, label: p.largeName }))
export const selectParcel = state => state.parcel.parcel
export const selectParcelTypes = state =>
  state.parcel.types.map(type => ({
    ...type,
    value: type.id,
    label: type.name,
  }))
