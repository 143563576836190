import './Tag.scss'
import React, { useState, useRef } from 'react'

import { Icon } from '../../index'
import { Overlay, Popover } from 'react-bootstrap'
import {
  faCaretUp,
  faCaretDown,
  faEdit,
  faSave,
  faTag,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons'

let interval = null
const Tag = ({
  isComment = false,
  editable = false,
  value,
  onChange,
  toggle = false,
  selected = false,
  options = [],
  noDelete = false,
  isNumber = false,
  dataCy,
  limitValue,
}) => {
  const [edt, setEdit] = useState(false)
  const [text, setText] = useState('')
  const [popover, setPopover] = useState(false)
  const ref = useRef(null)
  const [target, setTarget] = useState(null)

  return (
    <div
      className={`c-item ${selected ? 'custom-tag-selected' : 'custom-tag'} child  ${
        isComment ? 'tag-container-comment' : 'tag-container'
      }`}
      style={{
        cursor: editable || toggle ? 'pointer' : null,
      }}
      onClick={() => {
        if (toggle) {
          onChange(value)
        }
      }}>
      <Icon
        icon={
          !editable
            ? faTag
            : !value && !edt
            ? faEdit
            : edt
            ? faSave
            : !noDelete
            ? faWindowClose
            : faEdit
        }
        dataCy={dataCy}
        onClick={() => {
          if (editable) {
            if ((!edt && !value) || isNumber) {
              setEdit(true)
              setText(value)
            } else if (edt) {
              setEdit(false)
              onChange(text)
            } else if (noDelete) {
              setEdit(true)
              setText(value)
            } else {
              onChange('')
              setText('')
            }
          }
        }}
      />

      {edt ? (
        <div className={'d-flex'} ref={ref}>
          <input
            className={isComment ? 'tag-input-comment' : 'tag-input'}
            type={isNumber ? 'number' : 'text'}
            value={text || value}
            autoFocus
            onChange={({ target }) => {
              let { value } = target
              if (!value) value = ''
              value = value.toLowerCase()

              if (isNumber) {
                if (isNaN(value) || Number(value) < 0) value = 0
              }

              if (limitValue && Number(value) > limitValue) {
                value = limitValue
                setText(limitValue)
              } else setText(value)

              if (!noDelete) {
                clearInterval(interval)
                interval = setTimeout(() => {
                  /*if (isNumber)
                                setEdit(false); setText('');*/
                  onChange(value)
                }, 600)
              }
            }}
            maxLength={isComment ? 35 : 14}
            data-cy={dataCy + '-input'}
          />

          {options && options.length > 0 && (
            <Icon
              icon={popover ? faCaretUp : faCaretDown}
              onClick={e => {
                setPopover(!popover)
                setTarget(e.target)
              }}
            />
          )}

          <Overlay
            show={popover}
            placement={'bottom'}
            container={ref}
            containerPadding={20}
            target={target}>
            <Popover id={'popover-contained'}>
              <div className={'tag-popover column'}>
                <div className={'tag-popover-header'}>
                  <h6>Sugerencias</h6>
                </div>
                <div className={'tag-popover-body'}>
                  <ul style={{ margin: 0, padding: 0 }}>
                    {options &&
                      options.length > 0 &&
                      options
                        .filter(o => o.value)
                        .map((o, i) => (
                          <li key={i} style={{ padding: 0, 'list-style': 'none' }}>
                            <div
                              className={'b-item'}
                              onClick={() => {
                                setPopover(false)
                                setEdit(false)
                                onChange(o.label)
                                setText(o.label)
                              }}>
                              <div
                                className={
                                  'justify-content-start align-items-start bu-en left'
                                }>
                                {o.label}
                              </div>
                            </div>
                          </li>
                        ))}
                  </ul>
                </div>
              </div>
            </Popover>
          </Overlay>
        </div>
      ) : (
        <label
          style={{
            cursor: editable || toggle ? 'pointer' : null,
          }}
          className={'ml-1 c-item-tag child'}>
          {value}
        </label>
      )}
    </div>
  )
}
export default Tag
