import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import {
  Button,
  Dropdown,
  FormText,
  Paragraph,
  TableV2,
  SwitchV2,
  Select,
  Icon,
} from 'src/components'

import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  createPosConfiguration,
  getPosConfigurations,
  updatePosConfiguration,
  deletePosConfiguration,
  togglePosConfiguration,
} from 'src/actions/restaurant.actions'
import { selectPosConfigurations } from 'src/selectors/restaurant.selector'

import { selectCompanyCountry } from 'src/selectors/company.selector'

import { selectUsersByCompanyForSelect } from 'src/selectors/modules.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'

import { getPanelConfigurationField } from 'src/content/Restaurant/POSFunctions'
import { showAlert } from 'src/actions/alert.actions'

interface IModalCreateProps {
  users?: ISelect[]
  show?: boolean
  update?: boolean
  name?: string
  fields?: IPOSPanelConfiguration[]
  originalFields?: IPOSPanelConfigurationField[]
}
interface IActionsProps {
  create: boolean
  update: boolean
  delete: boolean
}

const mapFormToConfigurations = (
  form: IPOSPanelConfiguration[],
  originalFields: IPOSPanelConfigurationField[] = [],
) => {
  const response = []
  form.forEach(groupFields => {
    groupFields.fields.forEach(field => {
      response.push({
        id: originalFields.find(of => of.type === field.type)?.id,
        type: field.type,
        value: field.value,
      })
    })
  })

  return response
}

const POSPanelConfig = ({ show, onHide, pos }) => {
  const dispatch = useDispatch()

  const users = useSelector(selectUsersByCompanyForSelect)
  const configurations = useSelector(selectPosConfigurations)
  const country = useSelector(selectCompanyCountry)

  const loadingGet = useSelector(state =>
    loadingSelector([actionTypes.GET_POS_CONFIGURATIONS])(state),
  )

  const loadingCreate = useSelector(state =>
    loadingSelector([actionTypes.CREATE_POS_CONFIGURATION])(state),
  )
  const hasErrorCreate = useSelector(state =>
    hasErrors([actionTypes.CREATE_POS_CONFIGURATION])(state),
  )

  const loadingUpdate = useSelector(state =>
    loadingSelector([actionTypes.UPDATE_POS_CONFIGURATION])(state),
  )
  const hasErrorUpdate = useSelector(state =>
    hasErrors([actionTypes.UPDATE_POS_CONFIGURATION])(state),
  )

  const loadingDelete = useSelector(state =>
    loadingSelector([actionTypes.DELETE_POS_CONFIGURATION])(state),
  )
  const hasErrorDelete = useSelector(state =>
    hasErrors([actionTypes.DELETE_POS_CONFIGURATION])(state),
  )

  const [actions, setActions] = useState<IActionsProps>({
    create: false,
    update: false,
    delete: false,
  })
  const [modalCreate, setModalCreate] = useState<IModalCreateProps>({})

  const filteredUsers = pos.users
    ?.filter(
      user =>
        configurations.every(config => !config.users.includes(user.posUserId)) &&
        (modalCreate.users ? modalCreate.users.every(u => u.id !== user.id) : true),
    )
    .map(user => ({ id: user.id, value: user.posUserId, label: user.name }))

  useEffect(() => {
    if (!show) return
    dispatch(getPosConfigurations(pos.id))
  }, [show])

  useEffect(() => {
    if (loadingCreate) setActions({ ...actions, create: true })
    else if (actions.create) {
      setActions({ ...actions, create: false })

      let alert

      if (hasErrorCreate)
        alert = handlerError(
          hasErrorCreate.message || 'No se pudo crear la configuración',
        )
      else {
        setModalCreate({ show: false })
        dispatch(getPosConfigurations(pos.id))
        alert = handlerSuccess('Configuración creada con éxito')
      }
      dispatch(showAlert(alert))
    }
  }, [loadingCreate])

  useEffect(() => {
    if (loadingUpdate) setActions({ ...actions, update: true })
    else if (actions.update) {
      setActions({ ...actions, update: false })

      let alert
      if (hasErrorUpdate)
        alert = handlerError(
          hasErrorUpdate.message || 'No se pudo actualizar la configuración',
        )
      else {
        setModalCreate({ show: false })
        dispatch(getPosConfigurations(pos.id))
        alert = handlerSuccess('Configuración actualizada con éxito')
      }
      dispatch(showAlert(alert))
    }
  }, [loadingUpdate])

  useEffect(() => {
    if (loadingDelete) setActions({ ...actions, delete: true })
    else if (actions.delete) {
      setActions({ ...actions, delete: false })

      let alert

      if (hasErrorDelete)
        alert = handlerError(
          hasErrorDelete.message || 'No se pudo eliminar la configuración',
        )
      else {
        dispatch(getPosConfigurations(pos.id))
        alert = handlerSuccess('Configuración eliminada con éxito')
      }
      dispatch(showAlert(alert))
    }
  }, [loadingDelete])

  const mapConfigurationsToForm = (
    configurations: IPOSPanelConfigurationField[] = [],
    defaultValue = false,
  ): IPOSPanelConfiguration[] => {
    const fieldsGrouped = getPanelConfigurationField(country.id, pos.stepItems)
    return [...fieldsGrouped].map(group => {
      group.fields = [...group.fields].map(field => {
        const value =
          configurations.find(c => c.type === field.type)?.value ?? defaultValue
        return { ...field, value }
      })
      return group
    })
  }

  const headers: ITableHeader[] = [
    { show: true, label: 'Nombre', type: 'text', value: ['name'] },
    {
      show: true,
      label: 'Usuarios',
      type: 'text',
      value: [],
      custom: item =>
        item.users
          .map(posUserId => pos.users?.find(user => user.posUserId === posUserId)?.name)
          .join(', ') || '---',
    },
    {
      show: true,
      label: 'Estado',
      type: 'text',
      value: [],
      custom: item => (item.active ? 'Activo' : 'Inactivo'),
    },
    { show: true, label: 'Fecha creación', type: 'date', value: ['createdAt'] },
    {
      show: true,
      label: 'Creado por',
      type: 'text',
      value: [],
      custom: item => users.find(u => u.id === item.createdBy)?.name,
    },
    { show: true, label: 'Fecha Actualización', type: 'date', value: ['updatedAt'] },
    {
      show: true,
      config: true,
      label: '',
      type: 'text',
      value: [],
      custom: item => (
        <Dropdown
          loading={loadingUpdate || loadingDelete}
          items={[
            {
              title: 'Editar',
              action: () => {
                const fields = mapConfigurationsToForm(item.fields)
                setModalCreate({
                  show: true,
                  ...item,
                  fields,
                  users: pos.users
                    ?.filter(user => item.users.includes(user.posUserId))
                    .map(user => ({
                      id: user.id,
                      value: user.posUserId,
                      label: user.name,
                    })),
                  originalFields: item.fields,
                  update: true,
                })
              },
            },
            {
              title: item.active ? 'Desactivar' : 'Activar',
              action: () => dispatch(togglePosConfiguration(item.id)),
            },
            {
              title: 'Eliminar',
              action: () => dispatch(deletePosConfiguration(item.id)),
            },
          ]}
        />
      ),
    },
  ]

  return (
    <div>
      <Modal show={show && !modalCreate.show} centered size={'xl'} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Configuraciones del panel del punto de venta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <TableV2
                // @ts-ignore
                items={configurations}
                headers={headers}
                loading={loadingGet}
                noItemsLegend={
                  'No existen configuraciones del panel en este punto de venta'
                }
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              loading={loadingUpdate || loadingDelete}
              onClick={() =>
                setModalCreate({
                  show: true,
                  fields: mapConfigurationsToForm([], true),
                  users: [],
                })
              }>
              Nueva configuración
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalCreate.show}
        centered
        size={'lg'}
        onHide={() => setModalCreate({ fields: mapConfigurationsToForm() })}>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalCreate.update ? 'Actualizar' : 'Crear'} configuración
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={'custom-modal-body'}>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <FormText
                label={'Alias'}
                type={'text'}
                id={'posConfigAlias'}
                placeholder={'Opcional'}
                name={'pos'}
                value={modalCreate.name}
                onChange={e => setModalCreate({ ...modalCreate, name: e.target.value })}
              />
            </Col>

            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Select
                label={'Usuarios'}
                value={{ value: null, label: 'Selecciona un usuario' }}
                options={filteredUsers}
                onChange={value =>
                  setModalCreate({
                    ...modalCreate,
                    users: [...modalCreate.users, value],
                  })
                }
              />
            </Col>

            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Row className={'pl-1 mt-2'}>
                {modalCreate.users?.map(user => (
                  <div key={Number(user.value)} className={'user-tag  ml-2'}>
                    <label className={'label-user-tag'}>{user.label}</label>
                    <Icon
                      className={'delete-user-tag'}
                      icon={faTimes}
                      tooltip={'Quitar'}
                      color={'white'}
                      onClick={() =>
                        setModalCreate({
                          ...modalCreate,
                          users: modalCreate.users.filter(u => u.value !== user.value),
                        })
                      }
                    />
                  </div>
                ))}
              </Row>
            </Col>

            <Col xl={6} lg={6} md={6} sm={6} xs={12}>
              <Button
                style={{ width: '100%' }}
                onClick={() => {
                  setModalCreate({
                    ...modalCreate,
                    fields: mapConfigurationsToForm([], true),
                  })
                }}>
                Activar todo
              </Button>
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={12}>
              <Button
                style={{ width: '100%' }}
                onClick={() => {
                  setModalCreate({
                    ...modalCreate,
                    fields: mapConfigurationsToForm([]),
                  })
                }}>
                Desactivar todo
              </Button>
            </Col>

            {modalCreate.fields?.map((groupField, indexGroup) => (
              <Col
                key={groupField.type}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={'mt-3'}>
                <Paragraph size={'extraBig'}>{groupField.name}</Paragraph>

                <Row>
                  {groupField.fields.map((field, indexField) => (
                    <Col key={field.type} xl={6} lg={6} md={6} sm={12} xs={12}>
                      <SwitchV2
                        label={field.name}
                        info={''}
                        checked={field.value}
                        onChange={value => {
                          const fields = [...modalCreate.fields]
                          fields[indexGroup].fields[indexField].value = value

                          setModalCreate({
                            ...modalCreate,
                            fields: fields,
                          })
                        }}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              loading={loadingCreate || loadingUpdate}
              icon={faSave}
              onClick={() => {
                if (modalCreate.update)
                  dispatch(
                    updatePosConfiguration({
                      ...modalCreate,
                      fields: mapFormToConfigurations(
                        modalCreate.fields,
                        modalCreate.originalFields,
                      ),
                      users: modalCreate.users.map(user => user.value),
                    }),
                  )
                else
                  dispatch(
                    createPosConfiguration({
                      posId: pos.id,
                      name: modalCreate.name,
                      fields: mapFormToConfigurations(modalCreate.fields),
                      users: modalCreate.users.map(user => user.value),
                    }),
                  )
              }}>
              {modalCreate.update ? 'Actualizar' : 'Crear'}
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default POSPanelConfig
