import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Media } from 'react-breakpoints'
import { Col, Modal, Row } from 'react-bootstrap'
import {
  Button,
  Card,
  CustomFilter,
  CustomTabs,
  FilterByCategory,
  FormText,
  Icon,
  Switch,
  Title,
  Select,
  TableV2,
} from 'src/components'
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faPlus,
  faSave,
} from '@fortawesome/free-solid-svg-icons'

import {
  types,
  assignTablesPrint,
  createPrint,
  deletePrinter,
  getAll,
  getPrint,
  savePrintConnect,
  updatePrint,
  sendPrintToLocalhost,
} from 'src/actions/printer.actions'
import { selectPrintLocalResponse } from 'src/selectors/printer.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, hasErrors } from 'src/selectors/error.selector'

import { connect, getEscPosCommands, sendToPrint } from './PrintFunctions'
import { printTicket } from 'src/utils/utilities'

import { getAllCategorizations } from 'src/actions/categorization.actions'
import { getTables } from 'src/actions/restaurant.actions'
import { selectGetAllTables } from 'src/selectors/restaurant.selector'

import { showAlert } from 'src/actions/alert.actions'

import {
  printerTextSizesArray,
  printerTextSizes,
  PrinterTextSizesEnum,
  printConnectionTypesArray,
  PrintConnectionTypeEnum,
  printConnectionTypes,
} from 'src/enums/printerEnum'

import Printer from './Printer'

const drawerOptions = [
  { value: null, label: 'Ninguno' },
  { value: 1, label: 'PIN NO.2' },
  { value: 2, label: 'PIN NO.5' },
]

const Prints = ({
  isModal,
  customData,
  show,
  onHide,
  nav,
  itemsPrint,
  tableId,
  noPrinters,
  pos = {},
}) => {
  const dispatch = useDispatch()

  const [actions, setActions] = useState({
    uc: false,
    all: false,
    individual: false,
    assign: false,
    localPrint: false,
  })

  const tables = useSelector(selectGetAllTables)

  const all = useSelector(state => state.printers.all)
  const loadingAll = useSelector(state => loadingSelector([types.GET_PRINTERS])(state))
  const loadingAssign = useSelector(state => loadingSelector([types.ADD_TABLES])(state))

  const loadingIndividual = useSelector(state =>
    loadingSelector([types.GET_PRINT])(state),
  )
  const print = useSelector(state => state.printers.print)

  const loading = useSelector(state =>
    loadingSelector([types.CREATE_PRINTER, types.UPDATE_PRINTER])(state),
  )
  const hasErrorCreateOrUpdate = useSelector(state =>
    hasErrors([types.CREATE_PRINTER, types.UPDATE_PRINTER])(state),
  )

  const loadingS = useSelector(state =>
    loadingSelector([types.SAVE_PRINT_CONNECT])(state),
  )
  const dataS = useSelector(state => state.printers.data)

  const loadingPrintLocal = useSelector(state =>
    loadingSelector([types.SEND_PRINT_TO_LOCALHOST])(state),
  )
  const hasErrorPrintLocal = useSelector(state =>
    hasErrors([types.SEND_PRINT_TO_LOCALHOST])(state),
  )
  const printLocalResponse = useSelector(selectPrintLocalResponse)

  const [prints, setPrints] = useState([])

  const [index, setIndex] = useState(-1)
  const [open, setOpen] = useState(false)
  const [saved, setSaved] = useState(false)
  const [createModal, setCreateModal] = useState({ show: false })
  const [table, setTable] = useState({ show: false })
  const [printId, setPrintId] = useState(null)

  useEffect(() => {
    if (!noPrinters) dispatch(getAll(tableId))
    else if (all.length > 0 && prints.length <= 0) setPrints(all)
    if (!isModal) {
      dispatch(getAllCategorizations(12))
    }
  }, [])

  useEffect(() => {
    if (loadingAll) setActions({ ...actions, all: true })
    else if (actions.all) {
      setActions({ ...actions, all: false })
      setPrints(all)
    }
  }, [loadingAll])

  useEffect(() => {
    if (loading) setActions({ ...actions, uc: true })
    else if (actions.uc) {
      setActions({ ...actions, uc: false })
      if (hasErrorCreateOrUpdate) {
        dispatch(
          showAlert({
            ...handlerError(hasErrorCreateOrUpdate.message),
          }),
        )
      } else {
        dispatch(getAll(tableId))
        setCreateModal({ show: false })
      }
    }
  }, [loading])

  useEffect(() => {
    if (!isModal || !show) return
    setOpen(show)
  }, [show])

  useEffect(() => {
    if (index === -1) return

    if (itemsPrint) {
      if (itemsPrint.length < index + 1) {
        setIndex(-1)
        return
      }
      individualPrint(itemsPrint[index], true, true)
    } else setIndex(-1)
  }, [index])

  useEffect(() => {
    if (loadingS) setSaved(true)
    else if (saved) {
      setSaved(false)
      const { id, value } = dataS
      const s = Object.assign(
        [],
        prints.map(p => {
          if (p.id === id) p.pC = value
          return p
        }),
      )
      setPrints(s)
    }
  }, [loadingS])

  useEffect(() => {
    if (!itemsPrint) return
    multiplePrint()
  }, [itemsPrint])

  useEffect(() => {
    if (loadingIndividual) setActions({ ...actions, individual: true })
    else if (actions.individual) {
      setActions({ ...actions, individual: false })

      setTable({ ...print, show: true })
    }
  }, [loadingIndividual])

  useEffect(() => {
    if (loadingAssign) setActions({ ...actions, assign: true })
    else if (actions.assign) {
      setActions({ ...actions, assign: false })
      setTable({ ...print, show: false })
    }
  }, [loadingAssign])

  useEffect(() => {
    if (loadingPrintLocal) setActions({ ...actions, localPrint: true })
    else if (actions.localPrint) {
      setActions({ ...actions, localPrint: false })
      let { status, message } = printLocalResponse
      let connected = true
      if (hasErrorPrintLocal) {
        connected = false
        message = 'No fue posible conectarse al servidor de impresión.'
      }
      const item = prints.find(p => p.id === printId)
      setPrintId(null)

      setPrintData({
        ...item,
        cLoading: false,
        connected: connected,
        error: !status,
        e: message,
      })
      if (index > -1) setIndex(index + 1)
    }
  }, [loadingPrintLocal])

  const setPrintData = print => {
    const index = prints.findIndex(p => p.id === print.id)
    prints[index] = print
    setPrints(Object.assign([], prints))
  }

  const getData = print => {
    const dataExample = []

    dataExample.push(
      ...[
        { type: 1, value: 1 },
        { type: 2, value: 2 },
        { type: 3, value: true },
        { type: 4, value: 1 },
        { type: 5, value: 'SAMPLE RESTAURANT' },
        { type: 4, value: 2 },
        { type: 1, value: 2 },
        { type: 5, value: 'Solicitud de producción\n' },
        { type: 2, value: 1 },
        { type: 5, value: 'Mesa: 10\n' },
        { type: 5, value: 'Mesero: Lorem Impson\n' },
        { type: 1, value: 1 },
        { type: 6, value: new Date().valueOf() },
        { type: 7 },
        { type: 1, value: 1 },
      ],
    )

    const products = [
      { name: 'Descripción', quantity: 'CTD', ext: [] },
      {
        name: 'Hamburguesa artesanal',
        quantity: '2',
        ext: [
          { name: 'Queso', quantity: 1, original: 1 },
          { name: 'Lechuga', quantity: -1, original: 1 },
        ],
      },
      {
        name: 'Chuchitos Típicos',
        quantity: '2',
        ext: [
          { name: 'salsa', quantity: 1, original: 1 },
          { name: 'Carne', quantity: -1, original: 1 },
        ],
      },
      {
        name: 'Exquisita sinfonía de mariscos al grill con salsa de azafrán y toques de hierbas frescas mediterráneas',
        quantity: '2',
        ext: [
          { name: 'Queso', quantity: 1, original: 1 },
          { name: 'Lechuga', quantity: -1, original: 1 },
        ],
      },
      {
        name: 'Ternera de cocción lenta en su jugo con puré de raíces antiguas y ensalada de brotes orgánicos al aroma de trufa',
        quantity: '2',
        ext: [
          { name: 'salsa', quantity: 1, original: 1 },
          { name: 'Carne', quantity: -1, original: 1 },
        ],
      },
    ]

    const table = []

    products.forEach(({ name, quantity, ext }) => {
      table.push({ type: 3, value: true })
      table.push({ type: 5, value: quantity, min: 5 })
      table.push({ type: 5, value: `${name}\n` })
      table.push({ type: 3, value: false })

      ext.forEach(e => {
        let ex = ' - '
        if (e.quantity > 0) ex += `${e.quantity} ext. de ${e.name}`
        else if (e.quantity < 0) {
          if (e.quantity + e.original === 0) ex += `Sin ${e.name}`
          else ex += `Sin ${e.quantity} de ${e.name}`
        }

        ex += '\n'
        table.push({ type: 5, value: ex })
      })
    })

    dataExample.push(...table, ...[{ type: 7 }])
    dataExample.push({ type: 12, value: print.drawerType })
    return dataExample
  }

  const individualPrint = (item, sendPrint, fromPrinterCategories) => {
    if (!item) return
    if (item.connectionType === PrintConnectionTypeEnum.WEB) {
      printTicket(
        nav,
        null,
        null,
        (pos.activePreTicketsLogo && pos.ticketsLogo) || 'undefined',
      )
    } else {
      setPrintData({ ...item, cLoading: true })
      setPrintId(item.id)

      if (item.connectionType === PrintConnectionTypeEnum.ESC_POS) {
        getEscPosCommands(getData(item), item.textSize, data => {
          dispatch(
            sendPrintToLocalhost(
              {
                ip: item.ip,
                data: data,
              },
              item.host,
            ),
          )
        })
      } else {
        connect(
          item.ip,
          response => {
            const { ok, error, printer } = response
            setPrintData({
              ...item,
              cLoading: false,
              connected: ok,
              printer,
              error: !ok,
              e: error,
            })
            if (ok && sendPrint) {
              sendToPrint(
                printer,
                fromPrinterCategories ? item.data : customData || getData(item),
                item.textSize?.value,
              )
            } else {
              if (index > -1) setIndex(index + 1)
            }
          },
          () => {
            setPrintId(null)
            if (index > -1) setIndex(index + 1)
          },
        )
      }
    }
  }

  const multiplePrint = () => {
    setIndex(0)
  }

  const listPrints = () => (
    <div className="mt-3">
      {!isModal && (
        <Row className="container-buttons">
          <Button
            color={'primary'}
            onClick={() =>
              setCreateModal({
                show: true,
                connectionType: printConnectionTypes[PrintConnectionTypeEnum.WEB],
                textSize: printerTextSizes[PrinterTextSizesEnum.SMALL],
              })
            }>
            <Icon icon={faPlus} tooltip={'Agregar impresora'} />
          </Button>
        </Row>
      )}
      <TableV2
        items={prints}
        hideFilter
        renderRow={item => {
          const loading = item.id === printId

          return (
            <Printer
              key={item.id}
              loading={loading}
              disabled={loading}
              print={item}
              showAdminActions
              onPrint={() => individualPrint(item, true)}
              onConnect={host => {
                window.open(
                  `https://${host}`,
                  item.alias,
                  'width=550,height=550,scrollbars=NO',
                )
                dispatch(savePrintConnect(item.id, true))
              }}
              onDisconnectPrint={() => undefined}
              onConfig={() => {
                setTable({ ...item })
                dispatch(getTables())
                dispatch(getPrint(item.id))
              }}
              onEdit={() => {
                const drawer = drawerOptions.find(d => d.value === item.drawerType)
                const textSize =
                  printerTextSizes[item.textSize || PrinterTextSizesEnum.SMALL]
                const connectionType =
                  printConnectionTypes[item.connectionType || PrintConnectionTypeEnum.WEB]

                setCreateModal({
                  ...item,
                  show: true,
                  drawer,
                  textSize,
                  connectionType,
                })
              }}
              onDelete={() => {
                setTable({ ...item })
                dispatch(deletePrinter(item.id))
              }}
            />
          )
        }}
      />
    </div>
  )

  const itemsTableToSelect = (items, assigned) => {
    if (!assigned)
      items = items.filter(t => {
        if (!table.tables) return true
        const zIndex = table.tables.findIndex(tt => tt.id === t.id)
        return zIndex === -1
      })

    return (
      <div>
        <CustomFilter
          customFilter={<div />}
          search={table.search}
          onChange={({ target }) => setTable({ ...table, search: target.value })}
        />
        <Card
          white
          title={assigned ? 'Asignados' : 'Terminales'}
          button={
            <Icon
              icon={assigned ? faArrowLeft : faArrowRight}
              onClick={() => {
                if (!assigned)
                  setTable({
                    ...table,
                    tables: [...table.tables, ...items],
                    showSelect: false,
                  })
                else setTable({ ...table, tables: [] })
              }}
            />
          }>
          <ul className={'main-list'} style={{ border: '1px solid lightgray' }}>
            {items.map(i => (
              <li key={i.id}>
                <div className={'b-user-item'}>
                  {assigned && (
                    <Button
                      onClick={() => {
                        const iIndex = table.tables.findIndex(tt => tt.id === i.id)
                        table.tables.splice(iIndex, 1)
                        setTable({ ...table })
                      }}>
                      <Icon icon={faAngleLeft} />
                    </Button>
                  )}
                  <div className={'justify-content-start align-items-start bu-en left'}>
                    <div className={'b-user-name'}>{i.number}</div>
                  </div>
                  {!assigned && (
                    <div className={'d-flex row align-items-center mr-2 bu-b right'}>
                      <Button
                        onClick={() => {
                          setTable({ ...table, tables: [...table.tables, i] })
                        }}>
                        <Icon icon={faAngleRight} />
                      </Button>
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </Card>
      </div>
    )
  }

  const isValidData = () => {
    if (!createModal.alias || !createModal.connectionType) return false
    if (createModal.connectionType?.value !== PrintConnectionTypeEnum.WEB) {
      if (!createModal.ip) return false
      if (createModal.connectionType?.value === PrintConnectionTypeEnum.ESC_POS) {
        if (!createModal.host) return false
      }
    }
    return true
  }

  return (
    <div>
      {!isModal ? (
        <div>
          <Title title={'Control de impresoras'} />

          <CustomTabs
            items={[
              { title: 'Listado', component: () => listPrints() },
              { title: 'Grupos', component: () => <div /> },
            ]}
          />
        </div>
      ) : (
        <Modal
          show={open}
          size={'lg'}
          centered
          onHide={() => {
            setOpen(false)
            onHide()
          }}>
          <Modal.Header closeButton>
            <Modal.Title>Impresoras</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CustomTabs
              items={[
                { title: 'Listado', component: () => listPrints() },
                { title: 'Grupos', component: () => <div /> },
              ]}
            />
          </Modal.Body>
        </Modal>
      )}

      <Modal
        show={createModal.show}
        centered
        onHide={() => setCreateModal({ show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>
            {createModal.id ? 'Edición: ' + createModal.alias : 'Agregar '}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12}>
              <div className={'column'}>
                <label className={'ftf-form-label left mt-3'}>Categorias</label>
                <FilterByCategory
                  initial={createModal.show}
                  list={createModal.categories}
                  get={categories => {
                    setCreateModal({ ...createModal, categories })
                  }}
                />
              </div>
            </Col>

            <Col xl={12} lg={12} md={12} sm={12}>
              <FormText
                required
                label={'Alias'}
                info={'Ingresa un nombre para la identificación del dispositivo'}
                value={createModal.alias}
                onChange={({ target }) => {
                  const { value } = target
                  setCreateModal({ ...createModal, alias: value })
                }}
              />
            </Col>

            <Col xl={12}>
              <Select
                label={'Tipo de conexión'}
                options={printConnectionTypesArray}
                value={createModal.connectionType}
                onChange={connectionType =>
                  setCreateModal({ ...createModal, connectionType })
                }
              />
            </Col>

            {createModal.connectionType?.value !== PrintConnectionTypeEnum.WEB && (
              <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                  <FormText
                    required
                    label={'Dirección IP de la impresora'}
                    info={'Dirección IP de la impresora'}
                    value={createModal.ip}
                    onChange={({ target }) => {
                      const { value } = target
                      setCreateModal({ ...createModal, ip: value })
                    }}
                  />
                </Col>

                {createModal.connectionType?.value ===
                  PrintConnectionTypeEnum.ESC_POS && (
                  <Col xl={12} lg={12} md={12} sm={12}>
                    <FormText
                      required
                      label={'Dirección IP del servidor principal'}
                      info={
                        'Dirección IP del dispositivo con el servidor de impresión de kolo'
                      }
                      value={createModal.host}
                      onChange={({ target }) => {
                        const { value } = target
                        setCreateModal({ ...createModal, host: value })
                      }}
                    />
                  </Col>
                )}

                <Col xl={12}>
                  <Select
                    label={'Cash Drawer'}
                    value={createModal.drawer}
                    options={drawerOptions}
                    onChange={drawer => setCreateModal({ ...createModal, drawer })}
                    subText={
                      'El cajon de efectivo se abrira cuando se envie a imprimir una factura'
                    }
                  />
                </Col>
                <Col xl={12} className={'mt-2'}>
                  <Switch
                    label={
                      'Imprimir automaticamente las facturas desde el punto de ventas'
                    }
                    topLabel
                    checked={createModal.invoice}
                    changeValue={value => {
                      setCreateModal({ ...createModal, invoice: value })
                    }}
                  />
                </Col>
                <Col xl={12} className={'mt-2'}>
                  <Select
                    label={'Tamaño de letra'}
                    value={createModal.textSize}
                    options={printerTextSizesArray}
                    onChange={textSize => setCreateModal({ ...createModal, textSize })}
                  />
                </Col>
              </Row>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className="container-buttons">
            <Button
              loading={loading}
              disabled={!isValidData()}
              icon={faSave}
              color={'primary'}
              onClick={() => {
                let c = []
                if (createModal.categories) c = createModal.categories.map(pc => pc.id)

                const drawerType = createModal.drawer?.value
                const textSize = createModal.textSize?.value
                const connectionType = createModal.connectionType?.value
                let ip
                if (connectionType === PrintConnectionTypeEnum.WEB) ip = '0.0.0.0'
                else ip = createModal.ip

                const request = {
                  ...createModal,
                  categories: c,
                  drawerType,
                  textSize,
                  connectionType,
                  ip,
                }

                if (createModal.id) {
                  dispatch(updatePrint(request))
                } else {
                  dispatch(createPrint(request))
                }
              }}>
              Guardar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={table.show && !table.showSelect}
        centered
        size={'lg'}
        onHide={() => setTable({ ...table, show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>{table.alias}: asignación de terminales</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Media>
            {({ breakpoints, currentBreakpoint }) => {
              const tablet = breakpoints[currentBreakpoint] > breakpoints.mobile

              return (
                <Row>
                  {tablet && (
                    <Col xl={5} lg={5} md={5} sm={6} xs={6}>
                      {itemsTableToSelect(tables)}
                    </Col>
                  )}
                  <Col
                    xl={!tablet ? 12 : 7}
                    lg={!tablet ? 12 : 7}
                    md={!tablet ? 12 : 7}
                    sm={!tablet ? 12 : 6}
                    xs={!tablet ? 12 : 6}>
                    {itemsTableToSelect(table.tables, true)}
                  </Col>
                  {!tablet && (
                    <Col xl={12} className={'d-flex mt-3'}>
                      <Button
                        style={{ flex: 1 }}
                        onClick={() => setTable({ ...table, showSelect: true })}>
                        Seleccionar terminales
                      </Button>
                    </Col>
                  )}
                </Row>
              )
            }}
          </Media>
        </Modal.Body>

        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              loading={loadingAssign}
              onClick={() => {
                dispatch(
                  assignTablesPrint(
                    table.id,
                    table.tables.map(t => t.id),
                  ),
                )
              }}>
              Guardar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={table.showSelect}
        centered
        onHide={() => setTable({ ...table, showSelect: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Selección de terminales</Modal.Title>
        </Modal.Header>
        <Modal.Body>{itemsTableToSelect(tables)}</Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              color={'secondary'}
              onClick={() => {
                setTable({ ...table, showSelect: false })
              }}>
              Cerrar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default Prints
