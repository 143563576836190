export const selectGeneralBalance = state => state.balance.balance
export const selectBalanceDetails = state => state.balance.balanceDetails
export const selectBalanceDetailsCompany = state => state.balance.balanceDetailsCompany
export const selectBalanceDocuments = state => state.balance.balanceDocuments
export const selectBalanceEntries = state => state.balance.balanceEntries
export const selectBalanceCompanyEntries = state => state.balance.balanceEntries
export const selectBalanceEntryTypes = state =>
  state.balance.balanceEntryTypes.map(b => ({
    ...b,
    value: b.id,
    label: b.name,
  }))
export const selectBalanceInCompany = state => state.balance.balanceInCompany
export const selectBalanceInCompanyKolo = state => state.balance.balanceInCompanyKolo
export const selectGetUsersBalance = state => state.balance.usersBalance
export const selectGetCompaniesBalance = state => state.balance.balanceCompanies
export const selectAddUserBalance = state => state.balance.userBalanceEntries
export const selectAdminUserEntries = state => state.balance.userEntriesInCompany
export const selectKoloCompaniesBalance = state => state.balance.koloCompaniesBalance
export const selectKoloCompanyBalance = state => state.balance.koloCompanyBalance
export const selectCompanyBalance = state => state.balance.companyBalance
export const selectTotalCompanyBalance = state => state.balance.totalBalance
export const selectUCompanyBalance = state => state.balance.uCompanyBalance
export const selectUTotalCompanyBalance = state => state.balance.uTotalBalance
export const selectSentCashTransfers = state => state.balance.sentCashTransfers
export const selectTotalSentCashTransfers = state => state.balance.totalSentCashTransfers
export const selectReceivedCashTransfers = state => state.balance.receivedCashTransfers
export const selectTotalReceivedCashTransfers = state =>
  state.balance.totalReceivedCashTransfers
