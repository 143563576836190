import React from 'react'

import Icon from 'src/components/buttons/IconButton'
import {
  faInfoCircle,
  faPlus,
  faTimes,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons'
import IconButton from 'src/components/buttons/IconButton'

interface IPropsItem {
  label: string
  icon: IconDefinition
  tooltip: string
  onClick: () => void
  className: string
  disabled: boolean
}

const Item: React.FC<IPropsItem> = ({
  label,
  icon,
  tooltip,
  onClick,
  className,
  disabled,
}) => (
  <div className={`c-item centered ${className}`}>
    <label className={'label-user-tag'}>{label}</label>
    <Icon
      className={`c-item-button centered ${className}`}
      icon={icon}
      tooltip={tooltip}
      color={'white'}
      onClick={onClick}
      disabled={disabled}
    />
  </div>
)

interface Props {
  items: ISelect[]
  onRemove: (item) => void
  addItem?: () => void
  label?: string
  disabled?: boolean
  seeAddItem?: boolean
  info?: string
}

/**
 * Component that renders a list of categories
 * @component
 * @param {ISelect[]} items List of categories to render
 * @param {function} onRemove Function to indicate to the main app that a category has been removed from the list
 * @param {function} addItem Function that communicates that you clicked to add item
 * @param {string} label Optional label to identify the list
 * @param {boolean} disabled Disabled state
 * @param {boolean} seeAddItem Show the add item button
 * @param {string} info Information to show in the tooltip
 * */
const ListCategories = ({
  items,
  onRemove,
  addItem,
  label,
  disabled = false,
  seeAddItem = false,
  info,
}: Props) => {
  return (
    <div className={'column'}>
      {label && (
        <label className={'ftf-form-label mt-3'}>
          {label}&nbsp;
          {info && (
            <label>
              <IconButton
                tooltip={info}
                color={'rgba(34, 96, 149, 0.75)'}
                icon={faInfoCircle}
                size={'sm'}
              />
            </label>
          )}
        </label>
      )}

      <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10 }}>
        {seeAddItem && (
          <Item
            label={'Agregar'}
            icon={faPlus}
            tooltip={'Agregar'}
            onClick={addItem}
            className={'add'}
            disabled={disabled}
          />
        )}

        {items?.map((i: ISelect, key: number) => (
          <Item
            key={key}
            label={i?.label || i?.name}
            icon={faTimes}
            tooltip={'Remover'}
            onClick={() => onRemove(i)}
            className={'assigned'}
            disabled={disabled}
          />
        ))}
      </div>
    </div>
  )
}
export default ListCategories
