import '../Transfers.scss'
import '../../Permissions/UpdatePermissions/UpdatePermissions.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'
import {
  faExclamationCircle,
  faQuestionCircle,
  faSave,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import Alert from 'sweetalert-react'

import Card from 'src/components/cards/Card'
import Select from 'src/components/inputs/Select/CustomSelect'
import Icon from 'src/components/buttons/IconButton'
import Switch from 'src/components/inputs/Switch/Switch'
import SelectProducts from 'src/components/SelectProducts/SelectProducts'

import {
  actionTypes,
  createTransfer,
  createTransferInventory,
  getWarehouseInventory,
  getWarehouses,
  setInventoryList,
} from 'src/actions/warehouse.actions'
import { actionTypes as typesO, getOrdersPreSale } from 'src/actions/orders.actions'
import {
  selectCurrentCompany,
  selectCurrentModule,
  selectCurrentUser,
} from 'src/selectors/user.selector'
import {
  selectAllOwnWarehousesForSelect,
  selectAllWarehousesForSelect,
  selectWarehouseInventory,
} from 'src/selectors/warehouse.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  errorsSelector,
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
} from 'src/selectors/error.selector'
import { selectOrdersPreSales } from 'src/selectors/orders.selector'
import Placeholder from 'src/components/Placeholder/Placeholder'
import { getUsersChildrenByModule } from 'src/actions/modules.actions'

import { CustomFilter, Title } from 'src/components'
import FAB from 'src/components/buttons/FAB'
import Button from 'src/components/buttons/Button'
import ButtonIcon from 'src/components/buttons/IconButton'
import {
  actionTypes as action,
  getAllCategorizations,
} from 'src/actions/categorization.actions'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'
import Folder, { categoryType } from 'src/components/folders/Folder'

class CreateTransfer extends Component {
  state = {
    withExistence: true,
    withNoExistence: false,
    active: true,
    inactive: true,
    owned: true,
    consigned: true,
    myWarehouses: true,
    othersWarehouses: true,
    wholeInventory: false,
    search: '',
    fromWarehouse: { value: null, label: 'sin seleccionar' },
    toWarehouse: { value: null, label: 'sin seleccionar' },
    alert: { title: 'title' },
    errors: {},
    selected: { value: 0, label: 'Sin seleccionar' },
    selectedCategorization: [],
    showCategorizations: false,
  }

  componentDidMount() {
    const { module } = this.props
    this.props.getAllWarehouses()
    //this.props.getInventory();
    this.props.getUsersChildrenByModule(module, true)
    this.props.getAllCategorization()
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { inventoryIsLoading, setInventoryList } = this.props
    const { inventory, history } = next

    if (inventoryIsLoading && !next.inventoryIsLoading) {
      let items = inventory.map(i => {
        if (!i.productActive) {
          i.error = 'El producto esta inactivo'
          i.disabled = true
        } else if (i.productExistence <= 0) {
          i.error = 'El producto no tiene existencia'
          i.disabled = false
        }
        return {
          ...i,
          checked: false,
          realQuantity: 0,
          subTotal: 0,
          maxDiscount: 0,
          quantity: 0,
          discount: 0,
          sQuantity: 0,
        }
      })
      setInventoryList(items)
    }

    const { createTransferIsLoading, createTransferHasError } = this.props
    const { createTransferError } = next

    if (createTransferIsLoading && !next.createTransferIsLoading) {
      let alert = { title: 'title' }
      if (!createTransferHasError && next.createTransferHasError) {
        alert = handlerError(
          createTransferError.message ||
            'Error en los datos trasmitidos, por favor revise e intente de nuevo.',
        )
        alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
      } else {
        alert = handlerSuccess('Transferencia progamada exitosamente')
        alert.onConfirm = () => {
          this.setState({ alert: { ...alert, show: false } }, () => {
            setInventoryList([])
            history.goBack()
          })
        }
      }
      this.setState({ alert })
    }
  }

  handleCheckBoxChange = event => {
    const { name, checked } = event.target
    this.setState({ [name]: checked })
  }

  filterWarehouses = item => {
    const { myWarehouses, othersWarehouses, fromWarehouse } = this.state
    const { user } = this.props
    const { val } = this.props.match.params

    let see = true
    if (fromWarehouse.restricted) {
      if (fromWarehouse.accessible.find(a => a.warehouseId === item.value)) see = true
      else see = false
    }

    if (item.restricted) {
      if (item.accessible.find(a => a.warehouseId === fromWarehouse.value)) see = true
      else see = false
    }

    if (!item.active) see = false

    if (val)
      return (
        item.value !== (fromWarehouse && fromWarehouse.value) &&
        item.attendant !== user.id &&
        see
      )
    else
      return (
        ((myWarehouses && item.attendant === user.id) ||
          (othersWarehouses && item.attendant !== user.id)) &&
        item.value !== (fromWarehouse && fromWarehouse.value) &&
        see
      )
  }

  seeOptions = (checked, index) => {
    const { setInventoryList, inventory } = this.props
    inventory.map(i => {
      if (i.productId === index) {
        i.checked = checked
      }
    })
    setInventoryList(inventory)
  }

  changeQuantity = (value, index) => {
    const { setInventoryList, inventory } = this.props
    let item = inventory.find(i => i.productId === index)
    if (!item) return
    let quantity = parseInt(value)

    if (quantity < 0) quantity = 0
    if (item.productExistence > 0 && quantity > item.productExistence)
      quantity = item.productExistence

    let nowQuantity = (item.transformationFactor || 1) * quantity
    if (item.subProductId)
      nowQuantity +=
        (item.subProductData.transformationFactor || 1) * (item.sQuantity || 0)

    item.realQuantity = nowQuantity / (item.transformationFactor || 1)
    item.quantity = quantity

    inventory.map(i => {
      if (i.productId === index) {
        i = item
      }
    })
    setInventoryList(inventory)
  }

  changeSQuantity = (value, index) => {
    const { setInventoryList, inventory } = this.props

    let item = inventory.find(i => i.productId === index)
    if (!item) return

    let quantity = parseInt(value)
    const maxQuantity = item.subProductValue || 0

    if (quantity > maxQuantity) quantity = maxQuantity
    if (quantity < 0) quantity = 0

    let nowQuantity = (item.subProductData.transformationFactor || 1) * quantity

    if (item.quantity) nowQuantity += item.transformationFactor * (item.quantity || 0)

    item.realQuantity = nowQuantity / (item.transformationFactor || 1)
    item.sQuantity = quantity

    inventory.map(i => {
      if (i.productId === index) {
        i = item
      }
    })

    setInventoryList(inventory)
  }

  filterProducts = item => {
    const { search, selectedCategorization } = this.state
    const exp = new RegExp(search.toUpperCase(), 'g')
    let response = Object.keys(item).some(p =>
      `${JSON.stringify(item[p])}`.toUpperCase().match(exp),
    )
    if (response && selectedCategorization.length > 0) {
      response = false
      item.categories.map(c => {
        if (selectedCategorization.findIndex(d => d.id === c.id) !== -1) response = true
        else response = false
      })
    }
    return response
  }

  filter = item => {
    const { withExistence, withNoExistence, active, inactive, owned, consigned } =
      this.state
    const { val } = this.props.match.params
    const { company, module } = this.props
    if (val)
      return (
        ((withExistence && item.productExistence > 0) ||
          (withNoExistence && item.productExistence === 0)) &&
        ((active && item.active) || (inactive && !item.active)) &&
        ((owned && item.productCompanyId === company) ||
          (consigned && module !== 2000 && item.productCompanyId !== company))
      )
    else
      return (
        ((withExistence && item.productExistence > 0) ||
          (withNoExistence && item.productExistence === 0)) &&
        ((active && item.productActive) || (inactive && !item.productActive)) &&
        ((owned && item.productCompanyId === company) ||
          (consigned && module !== 2000 && item.productCompanyId !== company))
      )
  }

  validate = () => {
    const { toWarehouse, fromWarehouse, wholeInventory } = this.state
    const { inventory } = this.props
    let errors = {}

    if (!fromWarehouse.value) errors.fWarehouse = 'Campo obligatorio'
    if (!toWarehouse.value) errors.tWarehouse = 'Campo obligatorio'
    if (!wholeInventory && inventory.filter(i => i.checked).length <= 0)
      errors.inventory = 'Debe seleccionar al menos 1 producto a transferir.'

    if (Object.keys(errors).length === 0) {
      let alert = {
        show: true,
        title: 'Transferencia entre bodegas',
        text: 'Ingrese una descripción o concepto para la transferencia \n (OPCIONAL)',
        type: 'input',
        inputPlaceholder: 'Nueva transferencia',
        onConfirm: description => this.save(description),
        confirmButtonText: 'Guardar',
        confirmButtonColor: '#22B573',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        onCancel: () => this.setState({ alert: { title: '' } }),
      }
      this.setState({ alert })
    }
    this.setState({ errors })
  }

  save = description => {
    const { val } = this.props.match.params
    this.setState({ alert: { title: '' } })
    if (val) this.requestTransfer(description)
    else this.createTransfer(description)
  }

  createTransfer = description => {
    const { toWarehouse, fromWarehouse, wholeInventory, selectedCategorization } =
      this.state
    const { inventory, createTransferInventory, createTransfer } = this.props
    if (wholeInventory)
      createTransferInventory(
        fromWarehouse.value,
        toWarehouse.value,
        description,
        null,
        selectedCategorization.map(p => p.id),
      )
    else {
      let alert = null
      let items = Object.assign(
        [],
        inventory
          .filter(i => i.checked)
          .map(i => {
            if (i.realQuantity <= 0) {
              alert = handlerError('Debe espesificar la cantidad de los productos')
              alert.onConfirm = () => this.setState({ alert: { title: '' } })
            }
            return { ...i, quantity: i.realQuantity }
          }),
      )
      if (alert) {
        setTimeout(() => this.setState({ alert }), 300)
      } else {
        //console.log({fromWarehouse, toWarehouse, description, items})
        createTransfer(fromWarehouse.value, toWarehouse.value, description, false, items)
      }
    }
  }

  requestTransfer = description => {
    const { toWarehouse, fromWarehouse } = this.state
    const { inventory, createTransfer } = this.props
    let alert = null
    let selected = []
    inventory.map(i => {
      if (i.checked) selected.push(i)
    })
    let items = Object.assign(
      [],
      selected.map(i => {
        if (i.realQuantity <= 0 || !i.realQuantity) {
          alert = handlerError('Debe especificar la cantidad de los productos')
          alert.onConfirm = () => this.setState({ alert: { title: '' } })
        }
        return { ...i, quantity: i.realQuantity }
      }),
    )
    if (alert) {
      setTimeout(() => this.setState({ alert }), 300)
    } else {
      //console.log({fromWarehouse, toWarehouse, description, items})
      createTransfer(fromWarehouse.value, toWarehouse.value, description, true, items)
    }
  }

  customFilter = () => {
    const {
      wholeInventory,
      selectedCategorization,
      withExistence,
      withNoExistence,
      active,
      inactive,
      myWarehouses,
      othersWarehouses,
      owned,
      consigned,
    } = this.state
    const { module } = this.props
    const { val } = this.props.match.params
    return (
      <Card
        style={
          wholeInventory
            ? { pointerEvents: 'none', opacity: 0.5, backgroundColor: '#CCC' }
            : {}
        }>
        <b>Estados:</b>
        <Row className={'filter-controls'}>
          <Col md={6} xs={6}>
            <label className={'checkbox-label'}>
              <input
                type="checkbox"
                name="withExistence"
                checked={withExistence}
                onChange={this.handleCheckBoxChange}
              />{' '}
              Con Existencia
            </label>
          </Col>
          <Col md={6} xs={6}>
            <label className={'checkbox-label'}>
              <input
                type="checkbox"
                name="withNoExistence"
                checked={withNoExistence}
                onChange={this.handleCheckBoxChange}
              />{' '}
              Sin Existencia
            </label>
          </Col>
          <Col md={6} xs={6}>
            <label className={'checkbox-label'}>
              <input
                type="checkbox"
                name="active"
                checked={active}
                onChange={this.handleCheckBoxChange}
              />{' '}
              Activos
            </label>
          </Col>
          <Col md={6} xs={6}>
            <label className={'checkbox-label'}>
              <input
                type="checkbox"
                name="inactive"
                checked={inactive}
                onChange={this.handleCheckBoxChange}
              />{' '}
              Inactivos
            </label>
          </Col>
        </Row>
        <b>Mostrar productos: </b>
        <Row className={'filter-controls'}>
          <Col md={6} xs={6}>
            <label className={'checkbox-label'}>
              <input
                type="checkbox"
                name="owned"
                checked={owned}
                onChange={this.handleCheckBoxChange}
              />{' '}
              Producto propio
            </label>
          </Col>
          {module !== 2000 && (
            <Col md={6} xs={6}>
              <label className={'checkbox-label'}>
                <input
                  type="checkbox"
                  name="consigned"
                  checked={module !== 2000 ? consigned : false}
                  onChange={this.handleCheckBoxChange}
                />
                Producto Kolo
              </label>
            </Col>
          )}
        </Row>
        {!val && (
          <>
            <b>Bodegas:</b>
            <Row className={'filter-controls'}>
              <Col md={6} xs={6}>
                <label className={'checkbox-label'}>
                  <input
                    type="checkbox"
                    name="myWarehouses"
                    checked={myWarehouses}
                    onChange={this.handleCheckBoxChange}
                  />{' '}
                  Propias
                </label>
              </Col>
              <Col md={6} xs={6}>
                <label className={'checkbox-label'}>
                  <input
                    type="checkbox"
                    name="othersWarehouses"
                    checked={othersWarehouses}
                    onChange={this.handleCheckBoxChange}
                  />{' '}
                  De otros
                </label>
              </Col>
            </Row>
          </>
        )}
        <Button
          color={'primary'}
          onClick={() => this.setState({ showCategorizations: true })}>
          Filtrar por categorías
        </Button>
        <Row className={'pl-1'}>
          {selectedCategorization.map((p, key) => (
            <div className={'user-tag product-t ml-2'} key={key}>
              <label className={'label-user-tag'}>{p.name}</label>
              <ButtonIcon
                className={'delete-user-tag d-product-t'}
                icon={faTimes}
                tooltip={'Quitar'}
                color={'white'}
                onClick={() =>
                  this.setState({
                    selectedCategorization: selectedCategorization.filter(
                      f => f.id !== p.id,
                    ),
                  })
                }
              />
            </div>
          ))}
        </Row>
      </Card>
    )
  }

  renderFromWarehouse = (warehouses, label, val) => {
    const { module, inventoryIsLoading } = this.props
    const { errors, fromWarehouse, toWarehouse } = this.state
    return (
      <Select
        disabled={inventoryIsLoading}
        label={label}
        value={fromWarehouse}
        options={
          val === 'request'
            ? warehouses.filter(w => w.value !== fromWarehouse.value)
            : warehouses.filter(w => w.value !== toWarehouse.value)
        }
        onChange={w => {
          this.setState({ fromWarehouse: w })
          this.props.getInventory(w.value, module === 2000)
        }}
        required
        error={errors.fWarehouse}
      />
    )
  }

  renderToWarehouse = (warehouses, label, val) => {
    const { errors, fromWarehouse, toWarehouse } = this.state
    return (
      <Select
        label={label}
        value={toWarehouse}
        options={
          val === 'request'
            ? warehouses.filter(w => w.value !== fromWarehouse.value)
            : warehouses.filter(this.filterWarehouses)
        }
        onChange={w => {
          this.setState({ toWarehouse: w })
        }}
        required
        error={errors.tWarehouse}
      />
    )
  }

  render() {
    const {
      fromWarehouse,
      search,
      errors,
      alert,
      wholeInventory,
      selectedCategorization,
      showCategorizations,
    } = this.state

    const {
      ownWarehouses,
      allWarehouses,
      inventoryIsLoading,
      inventory,
      company,
      categorization,
    } = this.props
    const { val } = this.props.match.params
    let items = inventory.filter(this.filter)

    return (
      <div>
        <Title title={val ? 'Solicitar Transferencia' : 'Realizar Transferencia'} />
        <FAB
          show
          icon={faSave}
          onClick={() => this.validate()}
          tooltip={val ? 'Solicitar Transferencia' : 'Guardar Transferencia'}
        />
        <Row>
          <Col xs={12} sm={12} md={6} xl={6} lg={6}>
            <Card className={'h-100'}>
              <Row>
                <h4 className={'w-100'} style={{ fontWeight: 'bold', color: '#2F3542' }}>
                  <div
                    className={'d-flex w-100'}
                    style={{ justifyContent: 'space-between', height: 20 }}>
                    <div>{val ? 'Bodega a Solicitar' : 'Bodega Saliente'}</div>
                    {!val && items.length > 0 && (
                      <div>
                        <Switch
                          label={'Inventario Completo'}
                          checked={wholeInventory}
                          value={wholeInventory}
                          name={'wholeInventory'}
                          onChange={() =>
                            this.setState({ wholeInventory: !wholeInventory })
                          }
                        />
                        <br />
                      </div>
                    )}
                  </div>
                </h4>
                <Col xl={11} lg={11} md={11} sm={11} xs={11}>
                  {val
                    ? this.renderFromWarehouse(
                        allWarehouses,
                        'Bodega a solicitar',
                        'request',
                      )
                    : this.renderFromWarehouse(
                        ownWarehouses,
                        'Bodega Saliente',
                        'create',
                      )}
                </Col>
                <Col xl={1} lg={1} md={1} sm={1} xs={1} className={'info-button'}>
                  <Icon
                    icon={faQuestionCircle}
                    tooltip={'Seleccione la bodega a quien desea solicitar el producto'}
                  />
                </Col>
                {val && (
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    {fromWarehouse.attendantData && (
                      <h6>
                        <b>Encargado: </b>
                        {fromWarehouse.attendantData.name}
                      </h6>
                    )}
                  </Col>
                )}
              </Row>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={6} xl={6} lg={6}>
            <Card className={'h-100'}>
              <Row>
                <h4 className={'w-100'} style={{ fontWeight: 'bold', color: '#2F3542' }}>
                  <div
                    className={'d-flex w-100'}
                    style={{ justifyContent: 'space-between', height: 20 }}>
                    <div>{val ? 'Bodega Receptora' : 'Bodega Destino'}</div>
                  </div>
                </h4>
                <Col xl={11} lg={11} md={11} sm={11} xs={11}>
                  {val
                    ? this.renderToWarehouse(ownWarehouses, 'Mis bodegas', 'request')
                    : this.renderToWarehouse(allWarehouses, 'Bodega Destino', 'create')}
                </Col>
                <Col xl={1} lg={1} md={1} sm={1} xs={1} className={'info-button'}>
                  <Icon
                    icon={faQuestionCircle}
                    tooltip={'Selecciona la bodega que recibirá el producto'}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} xl={12} lg={12} style={{ height: '1vh' }}>
            <div
              style={
                wholeInventory
                  ? {
                      pointerEvents: 'none',
                      opacity: 0.5,
                      backgroundColor: '#CCC',
                    }
                  : {}
              }>
              {errors.inventory && (
                <div className="input-error">
                  <FontAwesomeIcon icon={faExclamationCircle} /> {errors.inventory}
                </div>
              )}
              <CustomFilter
                search={search}
                onChange={({ target }) => this.setState({ search: target.value })}
                customFilter={this.customFilter()}
              />
              <div>
                <Row className={'product_list'} style={{ height: '600px !important' }}>
                  {inventoryIsLoading ? (
                    [0, 0, 0].map((i, index) => (
                      <Col key={index}>
                        <Placeholder />
                      </Col>
                    ))
                  ) : items.length > 0 ? (
                    items.filter(this.filterProducts).map(
                      (i, index) =>
                        i.productExistence > 0 && (
                          <Col xl={3} lg={3} md={6} sm={6} xs={12} key={index}>
                            <SelectProducts
                              index={i.productId}
                              sku={i.productCode}
                              title={i.productName}
                              // subtitle={`${i.productCompanyData ? i.productCompanyData.alphanumeric : ''} - ${i.productCompanyData.name}`}
                              description={i.productDescription}
                              price={i.productPrice}
                              seeDiscount={false}
                              existence={i.productExistence}
                              checked={i.checked}
                              quantity={i.quantity}
                              sQuantity={i.sQuantity}
                              sValue={i.subProductId}
                              sTitle={i.subProductData ? i.subProductData.name : ''}
                              seeOptions={this.seeOptions}
                              changeQuantity={this.changeQuantity}
                              changeSQuantity={this.changeSQuantity}
                              error={i.error}
                              disabled={i.disabled}
                              warehouseId={fromWarehouse && fromWarehouse.value}
                              ribbon={i.productCompanyId !== company ? 'KOLO' : 'Propio'}
                              ribbonClass={
                                i.productCompanyId !== company ? 'kolo_pink' : 'kolo_blue'
                              }
                            />
                          </Col>
                        ),
                    )
                  ) : (
                    <h4
                      style={{
                        selfAlign: 'center',
                        margin: '0 auto',
                        fontSize: 50,
                      }}>
                      {'Sin items para mostrar'}
                    </h4>
                  )}
                </Row>
              </div>
            </div>
          </Col>
        </Row>

        <Folder
          noMessage
          onHide={() => this.setState({ showCategorizations: false })}
          onAssign={item => {
            if (selectedCategorization.findIndex(d => d.id === item.id) === -1)
              selectedCategorization.push(item)
            this.setState({ selectedCategorization })
          }}
          data1={
            categorization && categorization.children ? categorization.children[0] : {}
          }
          data2={
            categorization && categorization.children ? categorization.children[1] : {}
          }
          show={showCategorizations}
          type={categoryType.PRODUCTS}
        />

        <Alert {...alert} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: selectCurrentUser(state),
  module: selectCurrentModule(state),
  company: selectCurrentCompany(state),
  inventory: selectWarehouseInventory(state),
  ownWarehouses: selectAllOwnWarehousesForSelect(state),
  allWarehouses: selectAllWarehousesForSelect(state),

  inventoryIsLoading: loadingSelector([actionTypes.GET_WAREHOUSE_INVENTORY])(state),

  createTransferIsLoading: loadingSelector([actionTypes.CREATE_TRANSFER])(state),
  createTransferInventoryIsLoading: loadingSelector([
    actionTypes.CREATE_TRANSFER_INVENTORY,
  ])(state),
  createTransferHasError: hasErrorsSelector([actionTypes.CREATE_TRANSFER])(state),
  createTransferInventoryHasError: hasErrorsSelector([
    actionTypes.CREATE_TRANSFER_INVENTORY,
  ])(state),
  createTransferError: errorsSelector([actionTypes.CREATE_TRANSFER])(state),
  createTransferInventoryError: errorsSelector([actionTypes.CREATE_TRANSFER_INVENTORY])(
    state,
  ),
  preSales: selectOrdersPreSales(state),
  loadingP: loadingSelector([typesO.GET_ORDERS_PRE_SALE])(state),
  categorization: selectAllCategorizations(state),
  categorizationLoading: loadingSelector([action.GET_ALL])(state),
})
const mapDispatchToProps = dispatch => ({
  getAllWarehouses: () => dispatch(getWarehouses(false, false, true)),
  getInventory: (warehouseId, includeNoExistence) =>
    dispatch(getWarehouseInventory(warehouseId, false, false)),
  getUsersChildrenByModule: (module, includeParent) =>
    dispatch(getUsersChildrenByModule(module, includeParent)),

  createTransfer: (fromWarehouseId, toWarehouseId, description, requested, detail) =>
    dispatch(
      createTransfer(
        fromWarehouseId,
        toWarehouseId,
        false,
        description,
        requested,
        detail,
      ),
    ),
  createTransferInventory: (
    fromWarehouseId,
    toWarehouseId,
    description,
    detail,
    categories,
  ) =>
    dispatch(
      createTransferInventory(
        fromWarehouseId,
        toWarehouseId,
        false,
        description,
        detail,
        categories,
      ),
    ),
  getPreSales: () => dispatch(getOrdersPreSale()),
  setInventoryList: list => dispatch(setInventoryList(list)),
  getAllCategorization: () => dispatch(getAllCategorizations(3)),
})
export default connect(mapStateToProps, mapDispatchToProps)(CreateTransfer)
