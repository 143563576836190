import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actionTypes, getInfoCUI } from 'src/actions/clients.actions'
import { FormText } from 'src/components'
import useDebounce from 'src/hooks/useDebounce'
import { selectCUIinfo } from 'src/selectors/clients.selector'
import { handlerError, hasErrorsSelector } from 'src/selectors/error.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import Alert from 'sweetalert-react'
export const InfoCUI = ({
  disabled,
  cui,
  onChange,
  onChangeName,
  searchOwn,
  getClient,
  error,
  ...props
}) => {
  const dispatch = useDispatch()
  const infoCUI = useSelector(selectCUIinfo)

  const loadingCUI = useSelector(state =>
    loadingSelector([actionTypes.GET_CUI_INFO])(state),
  )
  const hasErrorCui = useSelector(state =>
    hasErrorsSelector([actionTypes.GET_CUI_INFO])(state),
  )

  const [temporalName, setTemporalName] = useState('')
  const [action, setAction] = useState({})
  const [alert, setAlert] = useState({ title: '' })

  useEffect(() => {
    if (loadingCUI && (getClient || onChangeName))
      setAction({ ...action, searchCui: true })
    else if (action.searchCui) {
      if (hasErrorCui) {
        setAlert({
          ...handlerError('No se pudo obtener la información con el CUI indicado'),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      } else {
        if (getClient) getClient(infoCUI)
        setTemporalName(infoCUI.clientName)
      }
    }
  }, [loadingCUI])

  const handleCUISearch = useDebounce(cui => {
    if (!cui || cui.length !== 13) return
    dispatch(getInfoCUI(cui, searchOwn))
  }, 1000)

  return (
    <>
      <FormText
        disabled={disabled || loadingCUI}
        label={'CUI'}
        min={cui && 13}
        max={cui && 13}
        id={'cui'}
        placeholder={'CUI'}
        name={'cui'}
        value={cui || ''}
        onChange={ev => {
          let valid = isNaN(ev.target.value) ? 0 : Number(ev.target.value)
          if (valid <= 0 && ev.target.value !== '') return
          setTemporalName('')
          onChange(ev)
          if (onChangeName || getClient) handleCUISearch(ev.target.value)
        }}
        error={error}
        {...props}
      />
      {onChangeName && (
        <div
          style={{ cursor: 'pointer', textAlign: 'left' }}
          onClick={() => {
            onChangeName(temporalName)
            setTemporalName('')
          }}>
          <u>
            <i>{temporalName}</i>
          </u>
        </div>
      )}
      <Alert {...alert} />
    </>
  )
}
