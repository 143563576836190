import './Login.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import AuthContainer from '../common/AuthContainer'
import LoginInput from 'src/components/inputs/LoginInput/LoginInput'
import Button from 'src/components/buttons/Button'

import { faAt, faKey } from '@fortawesome/free-solid-svg-icons'
import { Row, Col } from 'react-bootstrap'
import Sweetalert from 'sweetalert-react'

import { actionTypes as types, login, recoverPass } from 'src/actions/user.actions'
import { selectRecoverPass } from 'src/selectors/user.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { hasErrorsSelector, singleErrorSelector } from 'src/selectors/error.selector'

class Login extends Component {
  state = {
    email: '',
    password: '',
    recover: false,
    alert: { show: false, title: 'deafult', text: 'deafult', type: 'info' },
  }

  UNSAFE_componentWillReceiveProps(props) {
    console.log('INSIDE:::')

    const { loading, hasError } = this.props
    const { recoverMessage, error } = props
    if (loading && !props.loading) {
      let { alert, recover } = this.state
      if (!hasError && props.hasError) {
        alert = this.handlerError(error.message)
      } else {
        recover = false
        alert = this.handlerSuccess(recoverMessage.msg)
      }
      this.setState({ alert, recover })
    }

    const { loadingL, hasErrorL } = this.props
    const { errorL } = props

    if (loadingL && !props.loadingL) {
      if (!hasErrorL && props.hasErrorL) {
        let alert = this.handlerError(errorL.message || 'ha ocurrido un error inesperado')
        this.setState({ alert })
      }
    }
  }

  handlerError = message => {
    return { show: true, title: 'Uh-Oh..', text: message, type: 'warning' }
  }

  handlerSuccess = message => {
    return { show: true, title: 'Yeah!', text: message, type: 'success' }
  }

  handleChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  login = (email, password) => {
    this.password.validate()
    this.props.login(email, password)
  }

  validate = () => {
    const { email } = this.state
    if (email) this.props.recoverPass(email)
  }

  render() {
    const { email, password, recover, alert } = this.state
    const { loading } = this.props

    return (
      <AuthContainer>
        <h2 className={'mx-auto'}>Bienvenido</h2>
        <LoginInput
          icon={faAt}
          placeholder={'Correo electrónico'}
          autoFocus
          type="email"
          name={'email'}
          id={'email'}
          value={email}
          onChange={this.handleChange}
          submit={() => this.login(email, password)}
        />
        {!recover ? (
          <Row>
            <Col md={12}>
              <LoginInput
                ref={ref => (this.password = ref)}
                icon={faKey}
                placeholder={'Contraseña'}
                type={'password'}
                name={'password'}
                id={'password'}
                value={password}
                onChange={this.handleChange}
                submit={() => this.login(email, password)}
              />
            </Col>
            <Col md={12}>
              <Button
                className={'large-button rounded-pill mt-3'}
                onClick={() => this.login(email, password)}
                id={'login'}>
                Iniciar Sesión
              </Button>
            </Col>
            <Col md={12}>
              <Button
                onClick={() => this.setState({ recover: true })}
                color={'white'}
                className={'large-button'}
                variant={'link'}>
                Olvidé mi contraseña
              </Button>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col md={12}>
              <Button
                loading={loading}
                onClick={() => this.validate()}
                className={'large-button rounded-pill mt-3'}>
                Recuperar contraseña
              </Button>
            </Col>
            <Col md={12}>
              <Button
                disabled={loading}
                onClick={() => this.setState({ recover: false })}
                color={'white'}
                className={'large-button'}
                variant={'link'}>
                Volver
              </Button>
            </Col>
          </Row>
        )}
        <Sweetalert
          show={alert.show}
          title={alert.title}
          text={alert.text}
          type={alert.type}
          onConfirm={() => this.setState({ alert: { ...alert, show: false } })}
        />
      </AuthContainer>
    )
  }
}

const mapStateToProps = state => ({
  recoverMessage: selectRecoverPass(state),
  loading: loadingSelector([types.RECOVER_PASSWORD_BY_EMAIL])(state),
  hasError: hasErrorsSelector([types.RECOVER_PASSWORD_BY_EMAIL])(state),
  error: singleErrorSelector([types.RECOVER_PASSWORD_BY_EMAIL])(state),
  loadingL: loadingSelector([types.LOGIN])(state),
  hasErrorL: hasErrorsSelector([types.LOGIN])(state),
  errorL: singleErrorSelector([types.LOGIN])(state),
})

const mapDispatchToProps = dispatch => ({
  login: (email, password) => dispatch(login(email, password)),
  recoverPass: email => dispatch(recoverPass(email)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
