import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Alert from 'sweetalert-react'
import { Row, Col, Modal } from 'react-bootstrap'
import {
  Title,
  Card,
  Button,
  FormText,
  Action,
  Switch,
  TableV2,
  Dropdown,
  Icon,
} from 'src/components'
import { faFileExcel, faSave } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes as typeP,
  getProviders,
  onCreateProvider,
  onUpdateProvider,
  exportProviders,
} from 'src/actions/producer.actions'

import { isAllowed } from 'src/selectors/modules.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  singleErrorSelector,
  handlerSuccess,
  handlerError,
  hasErrorsSelector,
} from 'src/selectors/error.selector'

import { valNit } from 'src/selectors/utilities.selector'

import { actionTypes as typesC, getInfoNIT } from 'src/actions/clients.actions'
import { selectNITinfo } from 'src/selectors/clients.selector'

import useDebounce from 'src/hooks/useDebounce'
import InfoNit from 'src/components/InfoNit/InfoNit'
import ProvidersAdvances from './ProvidersAdvances'
import ProvidersAdvancesHistory from './ProvidersAdvancesHistory'
import { selectActivePlans } from 'src/selectors/plans.selector'
import { Country } from '../../enums/countries'
import { selectCompanyCountry } from '../../selectors/company.selector'

const PurchaseProvider = () => {
  const dispatch = useDispatch()
  const infoNIT = useSelector(selectNITinfo)

  const country = useSelector(selectCompanyCountry)
  const providers = useSelector(state => state.producer.providers)
  const loadingProviders = useSelector(state =>
    loadingSelector([typeP.GET_PROVIDERS])(state),
  )

  const loadingCreate = useSelector(state =>
    loadingSelector([typeP.CREATE_PROVIDER])(state),
  )
  const errorCreate = useSelector(state =>
    singleErrorSelector([typeP.CREATE_PROVIDER])(state),
  )
  const hasErrorCreate = useSelector(state =>
    hasErrorsSelector([typeP.CREATE_PROVIDER])(state),
  )

  const loadingNit = useSelector(state => loadingSelector([typesC.GET_NIT_INFO])(state))
  const hasErrorNit = useSelector(state =>
    hasErrorsSelector([typesC.GET_NIT_INFO])(state),
  )

  //Permissions
  const canCreateProvider = useSelector(state => isAllowed(state, [1202, 4302]))
  const canUpdateProvider = useSelector(state => isAllowed(state, [1203, 4303]))
  const canAddProviderBalance = useSelector(state => isAllowed(state, [1204]))
  const canSeeMoneyBalances = useSelector(state => isAllowed(state, [1205, 4305]))

  const [actions, setActions] = useState({})
  const [alert, setAlert] = useState({ title: '' })
  const [selected, setSelected] = useState([])
  const [all, setAll] = useState(false)
  const [providerModal, setProviderModal] = useState({})
  const [advancesModal, setAdvancesModal] = useState({})
  const [advancesHistoryModal, setAdvancesHistoryModal] = useState({})

  const plan = useSelector(selectActivePlans)

  const handleNitSearch = useDebounce(nit => {
    if (!nit || nit === 'CF' || nit === 'cf' || country.id === Country.SV || !valNit(nit))
      return

    dispatch(getInfoNIT(nit))
  }, 1000)

  const handleNitChange = ev => {
    setProviderModal({ ...providerModal, nit: ev.target.value })
  }

  useEffect(() => {
    dispatch(getProviders())
  }, [])

  useEffect(() => {
    if (loadingCreate) setActions({ create: true })
    else if (actions.create) {
      setActions({ create: false })
      if (hasErrorCreate)
        setAlert({
          ...handlerError(errorCreate.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Proveedor creado con éxito'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            setProviderModal({ show: false })
            dispatch(getProviders())
          },
        })
    }
  }, [loadingCreate])

  useEffect(() => {
    if (loadingNit) setActions({ ...actions, search: true })
    else if (actions.search) {
      if (hasErrorNit)
        return setAlert({
          ...handlerError('No se pudo obtener la información con el NIT indicado'),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })

      setProviderModal({ ...providerModal, name: infoNIT.clientName })
    }
  }, [loadingNit])

  const getActions = p => (
    <Dropdown
      items={[
        {
          show: canUpdateProvider,
          title: 'Editar',
          action: () => setProviderModal({ show: true, create: false, ...p }),
        },
        {
          show: canAddProviderBalance && plan?.id !== 9,
          title: 'Añadir adelanto al proveedor',
          action: () => setAdvancesModal({ ...p, show: true }),
        },
        {
          show: plan?.id !== 9,
          title: 'Historial del saldo a favor',
          action: () =>
            setAdvancesHistoryModal({
              show: true,
              providerId: p.id,
              providerName: p.name,
            }),
        },
      ]}
    />
  )

  const headers = [
    {
      label: '',
      show: true,
      select: true,
      value: [''],
      type: '',
      checked: all && selected.length === 0,
      style: { verticalAlign: 'middle', textAlign: 'center' },
      classNameCustom: item => `center mini ${item.dirty ? 't-gray' : ''}`,
    },
    { label: 'NIT', show: true, value: ['nit'], type: 'text' },
    { label: 'Nombre ', show: true, value: ['name'], type: 'text' },
    {
      label: 'Pequeño contribuyente',
      show: true,
      select: true,
      custom: item => (item.fpeq ? 'Sí' : 'No'),
      value: [],
      type: 'text',
    },
    { label: 'CPP', show: plan?.id !== 9, value: ['balanceCpp'], type: 'currency' },
    {
      label: 'Saldo a favor',
      show: plan?.id !== 9,
      value: ['balance'],
      type: 'currency',
    },
    {
      label: 'Acción',
      className: 'center',
      config: true,
      show: canUpdateProvider || canAddProviderBalance,
      custom: item => getActions(item),
    },
  ]

  const rowClick = item => {
    if (selected.some(provider => provider === item.id))
      setSelected(selected.filter(provider => provider !== item.id))
    else setSelected([...selected, item.id])
  }

  return (
    <div>
      <Title
        title={'Proveedores'}
        action={canCreateProvider}
        actionTitle={'Nuevo proveedor'}
        onClick={() => {
          setProviderModal({ show: true, create: true })
        }}
      />
      <Row>
        <Col xl={12} lg={12} md={12} sm={12}>
          <Card
            title={'Listado'}
            white
            button={
              <Icon
                tooltip={'Exportar excel'}
                icon={faFileExcel}
                onClick={() =>
                  dispatch(
                    exportProviders(selected.length > 0 ? selected.join(',') : null),
                  )
                }
              />
            }>
            <Action
              action={canCreateProvider}
              actionTitle={'Nuevo proveedor'}
              onClick={() => {
                setProviderModal({ show: true, create: true })
              }}
            />
            <div className={'column'}>
              <TableV2
                loading={loadingProviders}
                items={providers}
                noItemsLegend={'No se encontraron proveedores'}
                headers={headers}
                onClick={() => {
                  dispatch(getProviders())
                }}
                mobileAuto
                storageKey={'purchaseProvider'}
                balancePermission={canSeeMoneyBalances}
                onClickTr={(e, item) => rowClick(item)}
                isSelected={item =>
                  all || selected.some(provider => provider === item.id)
                }
                onCheck={value => {
                  setSelected([])
                  setAll(value)
                }}
                getItems={item => {
                  if (item === null) {
                    setAll(false)
                    setSelected([])
                  } else rowClick(item)
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Modal
        show={providerModal.show}
        centered
        size={'md'}
        onHide={() => setProviderModal({ show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>
            {providerModal.create
              ? 'Crear nuevo proveedor'
              : `Editar al proveedor: ${providerModal.name}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12} lg={12} md={12}>
              <InfoNit
                nit={providerModal.nit}
                label={'NIT del proveedor'}
                noValidate={country.id === Country.SV}
                onChange={ev => {
                  handleNitChange(ev)
                  handleNitSearch(ev.target.value)
                }}
              />
            </Col>
            <Col xl={12} lg={12} md={12}>
              <FormText
                disabled={loadingCreate}
                label={'Nombre del proveedor'}
                value={providerModal.name}
                required
                onChange={({ target }) => {
                  const { value } = target
                  setProviderModal({ ...providerModal, name: value })
                }}
              />
            </Col>

            <Col xl={12} lg={12} md={12}>
              <Switch
                checked={providerModal.fpeq}
                topLabel
                label={'Marcar como Pequeño Contribuyente'}
                onChange={({ target }) => {
                  const { checked } = target
                  setProviderModal({ ...providerModal, fpeq: checked })
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              loading={loadingCreate || loadingNit}
              disabled={
                !providerModal.name ||
                (country.id === Country.GT && !valNit(providerModal.nit))
              }
              color={'primary'}
              icon={faSave}
              onClick={() => {
                if (providerModal.create) dispatch(onCreateProvider(providerModal))
                else dispatch(onUpdateProvider(providerModal.id, providerModal))
              }}>
              {providerModal.create ? 'Crear' : 'Actualizar'}
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <ProvidersAdvances
        {...advancesModal}
        title={`${advancesModal.name}: Adelanto`}
        paymentCash
        paymentBank
        paymentCN
        canSelectAllUsers
        onClose={success => {
          setAdvancesModal({})
          if (success) dispatch(getProviders())
        }}
      />

      <ProvidersAdvancesHistory
        {...advancesHistoryModal}
        balancePermission={canSeeMoneyBalances}
        onHide={() => setAdvancesHistoryModal({})}
      />

      <Alert {...alert} />
    </div>
  )
}
export default PurchaseProvider
