import React, {Component} from 'react';
import {connect} from "react-redux"
import {actionTypes as type, getCompaniesByType} from "../../../actions/company.actions";
import {selectCompaniesByType} from "../../../selectors/company.selector";
import {loadingSelector} from "../../../selectors/loading.selector";
import Card from "../../../components/cards/Card";
import {Row, Col} from "react-bootstrap";
import Switch from "../../../components/inputs/Switch/Switch";
import {actionTypes as contractsActionTypes, toggleProducerShipperContract} from "../../../actions/contracts.actions";
import {hasErrorsSelector, singleErrorSelector} from "../../../selectors/error.selector";
import SweetAlert from "sweetalert-react";
import {Link} from "react-router-relative-link";
import { TableV2 } from '../../../components';

class Shipper extends Component {

    state = {
        showSuccess: false,
        showError: false,

        active: true,
        inactive: false
    };

    componentDidMount() {
        this.props.getShippers();
    };

    componentWillReceiveProps(next) {
        const {isLoading, hasError} = this.props;

        if (isLoading && !next.isLoading) {
            if (!hasError && next.hasError) {
                this.setState({showSuccess: false, showError: true});
            } else {
                this.setState({showSuccess: true, showError: false});
            }
        }
    }

    handleCheckBoxChange = (event) => {
        const {name, checked} = event.target;
        this.setState({[name]: checked})
    };

    filter = (item) => {
        const {active, inactive} = this.state;
        return ((item.active && active) || (!item.active && inactive));
    };

    onChangeSwitch = (data, item) => {
        this.props.toggleProducerShipperContract(item.id);
    };

 
    render() {
        const {shippers, loading} = this.props;
        const {error} = this.props;
        const {showError, showSuccess} = this.state;
        return (
            <div>
                <h1 className={'dashboard-welcome'}>Transportistas</h1>
                <Row>
                    <Col md={4}>
                        <Row>
                            <Col md={12}>
                                <Card title={'Filtros'}>

                                </Card>
                            </Col>
                            <Col md={12}>
                                <Link className={'big-button'} to={'ofertas'}>Ofertas de transporte</Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={8}>
                        <Card title={'Empresas'}>
                            <TableV2
                                headers={[

                                    { label: 'NIT', show: true, value: ['nit'], type: 'nit', className: 'mini' },
                                    { label: 'Nombre', show: true, value: ['name'], type: 'text', className: 'mini' },
                                    { label: 'Email', show: true, value: ['email'], type: 'text', className: 'mini' },
                                    {
                                        label: 'Contrato', show: true, value: ['email'], type: 'text', className: 'mini', select:true,
                                        custom: (item) => <Switch
                                            id={item.id}
                                            checked={item.hasContract}
                                            onChange={(e) => this.onChangeSwitch(e, item)} />
                                    },
                                    { config: true, show: true, label: '', className: 'mini center' }
                                ]}
                                items={shippers}
                                loading={loading}
                                mobileAuto
                                storageKey={`shipper`}
                            />
                        </Card>
                    </Col>
                </Row>

                <SweetAlert
                    show={showSuccess}
                    title={"!Exito!"}
                    text={"Contrato modificado exitosamente."}
                    type={"success"}
                    onConfirm={() => this.setState({showSuccess: false})}
                />
                <SweetAlert
                    show={showError}
                    title={"¡Uh-Oh!"}
                    text={error.message || "Error en los datos transmitidos, por favor revise e intente de nuevo."}
                    type={"error"}
                    onConfirm={() => this.setState({showError: false})}
                />

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    shippers: selectCompaniesByType(state),
    loading: loadingSelector([type.GET_COMPANIES_BY_TYPE])(state),

    isLoading: loadingSelector([contractsActionTypes.TOGGLE_PRODUCER_SHIPPER_CONTRACT])(state),
    hasError: hasErrorsSelector([contractsActionTypes.TOGGLE_PRODUCER_SHIPPER_CONTRACT])(state),
    error: singleErrorSelector([contractsActionTypes.TOGGLE_PRODUCER_SHIPPER_CONTRACT])(state),
});
const mapDispatchToProps = dispatch => ({
    getShippers: () => dispatch(getCompaniesByType(false, true, false, 10)),
    toggleProducerShipperContract: (companyId) => dispatch(toggleProducerShipperContract(companyId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Shipper);
