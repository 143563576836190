import './QuoteAdjustment.scss'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'

import { Row, Col } from 'react-bootstrap'

import Alert from 'sweetalert-react'
import {
  Title,
  Card,
  Select,
  SelectedDates,
  Button,
  TableV2,
  FAB,
} from '../../components'
import { Media } from 'react-breakpoints'
import { faSave, faSearch } from '@fortawesome/free-solid-svg-icons'

import { actionTypes as actionW, getWarehouseLite } from 'src/actions/warehouse.actions'

import { selectWarehouseLite } from 'src/selectors/warehouse.selector'

import {
  actionTypes as actionO,
  getQuoteAdjustment,
  onQuoteAdjustment,
} from 'src/actions/orders.actions'
import { selectOrderMatrix } from 'src/selectors/orders.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { hasErrors, handlerError, handlerSuccess } from 'src/selectors/error.selector'

import { formatDateFromMillis } from 'src/utils/formatters'
import { ListVariations } from 'src/content/Production/Categorization/Variation/ListVariations'

const QuoteAdjustment = () => {
  const dispatch = useDispatch()

  const warehouses = useSelector(selectWarehouseLite)
  const loadingW = useSelector(state =>
    loadingSelector([actionW.GET_WAREHOUSE_LITE])(state),
  )

  const matrixResponse = useSelector(selectOrderMatrix)
  const loadingM = useSelector(state =>
    loadingSelector([actionO.GET_QUOTE_ADJUSTMENT])(state),
  )

  const loadingQA = useSelector(state =>
    loadingSelector([actionO.ON_QUOTE_ADJUSTMENT])(state),
  )
  const hasErrorQA = useSelector(state => hasErrors([actionO.ON_QUOTE_ADJUSTMENT])(state))

  const [flags, setFlags] = useState({ get: false, put: false })

  const [search, setSearch] = useState(null)
  const [showFAB, setShowFAB] = useState(false)
  const [alert, setAlert] = useState({ title: '' })
  const [matrix, setMatrix] = useState({
    header: [],
    items: [],
  })
  const [params, setParams] = useState({
    warehouse: { value: null, label: 'Sin bodega seleccionada' },
    orderType: 5,
    statusList: '14',
    start: null,
    end: null,
  })

  useEffect(() => {
    dispatch(getWarehouseLite())
  }, [])

  useEffect(() => {
    if (loadingM) setFlags({ ...flags, get: true })
    else if (flags.get) {
      setFlags({ ...flags, get: false })
      setMatrix(matrixResponse)
      matrixResponse.items &&
        matrixResponse.items.length > 0 &&
        matrixResponse.items[0].items.forEach(x => {
          updateQuantities(x)
        })
      setShowFAB(true)
    }
  }, [loadingM])

  useEffect(() => {
    if (loadingQA) setFlags({ ...flags, put: true })
    else if (flags.put) {
      setFlags({ ...flags, put: false })
      if (hasErrorQA)
        setAlert({
          ...handlerError(hasErrorQA.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Operación Exitosa'),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
    }
  }, [loadingQA])

  const getHeaders = () => {
    const headers = []
    matrix.header.forEach((h, i) => {
      const quantityInUsed = getQuantityInUsed(h)
      const u = (h.value || 0) - quantityInUsed
      const opt = u >= 0
      headers.push({
        show: true,
        label: h.name,
        key: h.key,
        type: 'text',
        className: i === 0 ? 'static' : 'text-center static',
        style:
          i === 0
            ? {
                minWidth: 300,
              }
            : null,
        title:
          i === 0 ? (
            <div className={'column'}>
              <div>Cotizaciones</div>
              <div className={'ml-5'}>{matrix.items.length}</div>
            </div>
          ) : (
            <div className={'column'}>
              <div>{h.name}</div>
              {h.variations && h.variations.length > 0 && (
                <div style={{ justifyContent: 'center', display: 'flex' }}>
                  {' '}
                  <ListVariations selected={h.variations} />{' '}
                </div>
              )}
              <div>{h.value} u. disponible</div>
              <div style={{ color: opt ? 'green' : 'red' }}>{quantityInUsed} en uso</div>
              <div style={{ color: opt ? 'green' : 'red' }}>
                {Math.abs(u)} {opt ? 'pendiente de asignar' : 'sin inventario'}
              </div>
            </div>
          ),
      })
    })
    // getAvailable()
    return headers
  }

  const getQuantityInUsed = value => {
    let response = 0
    matrixResponse.items.forEach(item => {
      item.items
        .filter(i => i.key === value.key && i.categories === value.categories)
        .map(i => (response += Number.parseInt(i.value || 0)))
    })
    return response
  }

  const updateQuantities = item => {
    let header = matrixResponse.header.find(
      x => x.key === item.key && x.categories === item.categories,
    )
    item.presentationFactor = header ? header.presentationFactor : ''
    item.baseId = header ? header.baseId : ''

    let isBase =
      matrixResponse.header.filter(
        val => val.baseId === item.key && val.categories === item.categories,
      ).length > 0
    let quantity = getQuantityInUsed(item)
    if (item.baseId !== null || isBase) {
      let lineItems = matrixResponse.header.filter(x =>
        isBase
          ? (x.baseId === item.key || x.key === item.key) &&
            x.categories === item.categories
          : (x.baseId === item.baseId || x.key === item.baseId) &&
            x.categories === item.categories,
      )
      renderQuantities(
        (isBase ? lineItems[1].baseExistence : header.baseExistence) -
          quantity * (item.presentationFactor || 1),
        lineItems.sort((a, b) => b.presentationFactor - a.presentationFactor),
      )
    }
  }

  const renderQuantities = (quantity, line) => {
    let current = quantity
    let mod = 0
    let quotient = 0

    for (let p of line) {
      if (current <= 0) {
        p.value = 0
        continue
      }
      if (p.presentationFactor == null) {
        p.value = current
        current = current - current
      } else {
        if (current < p.presentationFactor) {
          p.value = 0
          continue
        }
        mod = current % p.presentationFactor
        quotient = Math.floor(current / p.presentationFactor)
        p.value = quotient
        current = mod
      }
    }
  }

  const filterSearch = item => {
    if (!(search && search !== '')) return true

    const { clientName, number, userName, items } = item
    const obj = Object.assign(
      {},
      {
        clientName,
        number,
        userName,
        items: items.filter(si => si.withValue),
      },
    )

    const exp = new RegExp(search.toUpperCase(), 'g')
    return Object.keys(obj).some(p =>
      `${JSON.stringify(obj[p])}`.toUpperCase().match(exp),
    )
  }

  const onSetItemValue = (orderId, subItemKey, value, categories) => {
    const itemIndex = matrix.items.findIndex(i => i.orderId === orderId)
    const item = Object.assign({}, matrix.items[itemIndex])

    const subItemIndex = item.items.findIndex(
      i => i.key === subItemKey && i.categories === categories,
    )
    const subItem = Object.assign({}, item.items[subItemIndex])

    subItem.value = value
    item.items[subItemIndex] = subItem
    matrix.items[itemIndex] = item
    setMatrix({ ...matrix })
  }

  const gs = (innerHtml, isMobile) => {
    return isMobile ? (
      <td>{innerHtml}</td>
    ) : (
      <th scope="row" className={'tf-th static'}>
        {innerHtml}
      </th>
    )
  }

  const onSave = () => {
    const request = matrix.items.map(item => {
      return {
        orderId: item.orderId,
        items: item.items.filter(i => i.value && i.value > 0),
      }
    })
    dispatch(onQuoteAdjustment(params.warehouse.value, request))
  }

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        const mobile = breakpoints[currentBreakpoint] < breakpoints.tablet

        return (
          <div>
            <Title title={'Ajuste de cotizaciones'} />

            <Card>
              <Row>
                <Col xl={12}>
                  <SelectedDates
                    disabled={loadingM || loadingQA}
                    onDateChange={(start, end) => setParams({ ...params, start, end })}
                  />
                </Col>
                <Col xl={6} md={6} sm={12}>
                  <Select
                    disabled={loadingM || loadingQA}
                    label={'Bodega de salida'}
                    options={warehouses}
                    onChange={w => setParams({ ...params, warehouse: w })}
                    loading={loadingW}
                    required
                  />
                </Col>
                <Col xl={6} md={6} sm={12}>
                  <div className={'mt-5'}>
                    <Button
                      disabled={
                        !params.warehouse.value ||
                        !params.start ||
                        !params.end ||
                        loadingQA
                      }
                      icon={faSearch}
                      loading={loadingM}
                      right
                      onClick={() => {
                        dispatch(
                          getQuoteAdjustment(
                            params.warehouse.value,
                            params.orderType,
                            params.statusList,
                            params.start,
                            params.end,
                          ),
                        )
                      }}
                    >
                      Actualizar
                    </Button>
                  </div>
                </Col>

                <Col xl={12} sm={12}>
                  <TableV2
                    customClass={'tableFixHead'}
                    headers={getHeaders()}
                    items={matrix.items.filter(filterSearch)}
                    placeholder={'Buscar por Cliente/Producto/No. Orden'}
                    handleChange={search => setSearch(search)}
                    renderRow={item => {
                      const { clientName, number, userName, date, items } = item
                      return (
                        <tr className={'data'} key={item.orderId}>
                          {gs(
                            <div className={'column'} style={{ color: 'black' }}>
                              <div className={'b-user-name'}>
                                <label>{clientName}</label>
                              </div>
                              <div className={'b-user-email'}>
                                {number} {formatDateFromMillis(date)}
                              </div>
                              <div className={'b-user-email'}>{userName}</div>
                            </div>,
                            mobile,
                          )}

                          {items.map(subItem => {
                            const { withValue, value } = subItem
                            return (
                              <td
                                className={'text-center'}
                                style={{ minWidth: 150 }}
                                key={subItem.key + '-' + subItem.categories}
                              >
                                {withValue ? (
                                  <input
                                    disabled={loadingQA}
                                    type="number"
                                    value={value}
                                    onChange={({ target }) => {
                                      let { value } = target
                                      if (value < 0) return

                                      if (value && value > 0) {
                                        value = Math.trunc(value)
                                      }
                                      onSetItemValue(
                                        item.orderId,
                                        subItem.key,
                                        value,
                                        subItem.categories,
                                      )
                                      updateQuantities(subItem, value)
                                    }}
                                  />
                                ) : (
                                  '---'
                                )}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    }}
                  />
                </Col>
              </Row>
            </Card>

            <FAB
              show={showFAB}
              icon={faSave}
              dontToggle
              loading={loadingQA}
              onClick={() => {
                setAlert({
                  show: true,
                  type: 'warning',
                  title: 'Ajuste de cotizaciones',
                  text: '¿Desea realizar el ajuste de las cantidades cotizadas? Esta acción actualiza los ítems y sus cantidades de las cotizaciones modificadas.',
                  showCancelButton: true,
                  cancelButtonText: 'Cancelar',
                  confirmButtonText: 'Ajustar',
                  confirmButtonColor: '#226095',
                  onCancel: () => setAlert({ ...alert, show: false }),
                  onConfirm: () => {
                    setAlert({ ...alert, show: false })
                    onSave()
                  },
                })
              }}
            />

            <Alert {...alert} />
          </div>
        )
      }}
    </Media>
  )
}
export default QuoteAdjustment
