import { actionTypes } from '../actions/creditnote.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  response: '',
  creditNotes: [],
  creditNotesAvailable: [],
}

const CreditNoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.ON_CREATE):
      return { ...state, response: extract(action.response, '') }
    case successState(actionTypes.GET_CREDIT_NOTE):
      return { ...state, creditNotes: extract(action.creditNotes, []) }
    case successState(actionTypes.GET_CREDIT_NOTE_AVAILABLE):
      return {
        ...state,
        creditNotesAvailable: extract(action.creditNotesAvailable, []),
      }
    default:
      return state ? state : initialState
  }
}
export default CreditNoteReducer
