import "../OfferCreate/OfferCreate.scss";
import React, {Component} from 'react';
import {connect} from "react-redux"

import Card from "../../../../components/cards/Card";
import Icon from "../../../../components/buttons/IconButton";
import CollapsibleCard from "../../../../components/cards/collapsible-card/CollapsibleCard";
import FormTextField from "../../../../components/inputs/FormTextField/FormTextField";
import Select from "../../../../components/inputs/Select/CustomSelect";
import Input from "../../../../components/inputs/FormTextField/FormText";
import {TooltipRail, Handle, Track} from "../../../../components/slider/SliderComponents";

import {formatDateFromMillis} from "../../../../utils/formatters";

import OfferUpdate from "../OfferUpdate/OfferUpdate";

import {
    Col,
    Row,
    Modal,
    ToggleButtonGroup,
    ToggleButton
} from "react-bootstrap";
import {Td, Tr} from "react-super-responsive-table";
import {Slider, Rail, Handles, Tracks} from "react-compound-slider";
import {
    faCalendarDay,
    faEdit,
    faEye,
    faFileAlt, faLink, faTimesCircle,
    faWindowClose
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Alert from "sweetalert-react";

import {
    actionTypes as types,
    getSingleOffer,
    getContraOffersByProducer, acceptOffer,
    onUpdateSetting
} from "../../../../actions/offers.actions";
import {selectOffer, selectContraOffers} from "../../../../selectors/offers.selector";

import {selectCurrentCompany, selectCurrentModule} from "../../../../selectors/user.selector";
import {loadingSelector} from "../../../../selectors/loading.selector";
import {
    hasErrorsSelector,
    singleErrorSelector,
    handlerSuccess, handlerError
} from "../../../../selectors/error.selector";
import Button from "../../../../components/buttons/Button";
import Gallery, {imageTypes} from "../../../../components/gallery/Gallery";
import {MAPS_API_KEY} from "../../../../settings/app.settings";
import GoogleMapReact from "google-map-react";
import MapCard from "../../../../components/maps/MapCard";
import { TableV2 } from "../../../../components";

const sliderStyle = {
    position: 'relative',
    width: '100%',
}

const latitude = 14.589465440976774;
const longitude = -90.51898866891861;

const days = [
    {id: 1, title: 'Lunes'},
    {id: 2, title: 'Martes'},
    {id: 3, title: 'Miercoles'},
    {id: 4, title: 'Jueves'},
    {id: 5, title: 'Viernes'},
    {id: 6, title: 'Sabado'},
    {id: 7, title: 'Domingo'}
];

class OffersDetails extends Component {

    state = {
        productDetails: {
            show: false,
            product: {},
            productPriceDetail: [],
            transportationPayments: []
        },
        details: {
            show: false,
            amounts: [],
            polygon: {}
        },
        domain: [1, 2],
        values: [0],
        update: [0],
        disabled: false,
        price: {minimum: 0, maximum: 0, price: 0},
        alert: {title: 'title'},
        images: [],
        polygonCords: [],
        updateChange: false,
        delivery: {
            show: false,
            deliveryType: {value: null, label: 'sin seleccionar', description: null},
            deliveryDays: [],
            create: false
        },
        module: 1000
    };

    componentDidMount() {
        const {match} = this.props;
        if (match && match.params && match.params.moduleId)
            this.setState({module: Number(match.params.moduleId)});

        setTimeout(function() {
            this.setUp();
        }.bind(this), 1000)
    };

    setUp = () => {
        const {params} = this.props.match;
        this.props.getOffer(params.id);
        // this.props.getContraOffers(params.id);
    };

    componentWillReceiveProps(props) {
        const {loadingAccept, hasErrorAccept, history, loading} = this.props;
        const {error, offer} = props;
        let {mapS, mapsS, alert} = this.state;

        if (loadingAccept && !props.loadingAccept) {
            if (!hasErrorAccept && props.hasErrorAccept) {
                alert = handlerError(error && error.response ? error.response.data.message : 'Ha ocurrido un error inesperado');
                alert.onConfirm = () => {
                    this.setState({alert: {...alert, show: false}});
                };
            } else {
                alert = handlerSuccess('Oferta de transporte aceptada satisfactoriamente');
                alert.onConfirm = () => {
                    this.setState({alert: {...alert, show: false}});
                    history.goBack();
                };
            }
            this.setState({alert});
        }
        if(loading && !props.loading){
            if (offer.polygonData && offer.polygonData.pointList.length >0) {
                const polygonCoords = [];
                offer.polygonData.pointList.map((p) => (polygonCoords.push({index: p.index, lat: parseFloat(p.latitude), lng: parseFloat(p.longitude)})));
                polygonCoords.push({...polygonCoords[0], index: polygonCoords.length});
                polygonCoords.sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
                this.setState({polygonCoords});
                this.onGoogleApiLoaded(mapS, mapsS, polygonCoords);
            }
        }

        const {loadingS, hasErrorS} = this.props;
        const {errorS} = props;
        if (loadingS && !props.loadingS) {
            if (!hasErrorS && props.hasErrorS) {
                alert = handlerError(errorS.message || 'Ha ocurrido un error inesperado');
                alert.onConfirm = () => this.setState({alert: {...alert, show: false}});
            } else {
                this.setUp();
                alert = handlerSuccess('La operacion ha sido exitosa');
                alert.onConfirm = () => this.setState({
                    alert: {...alert, show: false},
                    delivery: {...this.state.delivery, show: false}
                })
            }
            this.setState({alert});
        }

    };

    seePricesDetails = (item) => {
        const details = Object.assign({}, {...item, show: true});

        const length = details.amounts.length;
        let disabled = false;
        let values = [1];
        let update = [1]
        let domain = [1, length];
        if (length === 1) {
            disabled = true
            domain = [0, length]
        };
        const price = details.amounts[0];
        this.setState({details, domain, disabled, values, update, price});

    };

    renderRowContra = (item, index) => {
        return (
            <Tr className={'data'} key={index}>
                <Td className={'medium'}>{item.shipperName}</Td>
                <Td classNeme={'medium'}>{moment(item.createdAt).format('DD/MM/YYYY')}</Td>
                <Td className={'short center'}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                        <Icon linkRoute={`response/${item.id}`} linkProps={{target: '_blank'}} tooltip={'Ver respuesta'} icon={faFileAlt}/>
                    </div>
                </Td>
            </Tr>
        );
    };

    renderRow = (item, index) => {
        const {product} = item;


        return (
            <Tr className={'data'} key={`${index}`}>
                <Td className={'mini'}>{product ? product.code : "desconocido"}</Td>
                <Td className={'medium'}>{product ? product.name : "desconocido"}</Td>
                <Td>{product ? product.price : "desconocido"}</Td>
            </Tr>
        );
    };

    onChange = (values) => {
        const {details} = this.state;
        const index = (values[0] - 1);
        const price = details.amounts[index];
        this.setState({values, price});
    };

    onUpdate = (update) => {
        const {details} = this.state;
        const index = (update[0] - 1);
        const price = details.amounts[index];
        this.setState({update, price});
    };

    acceptOffer = (setting) => {
        if (!setting)
            this.setState({delivery: {...this.state.delivery, show: true, create: true}});
        else {
            let deliveryType = {};
            let deliveryDays = null;
            if (setting.deliveryType === 1) {
                deliveryType = {value: 1, label: 'Tiempo en dias', description: 'Cuanto escoges tiempo en dias adquieres el compromiso en entregar la orden en el tiempo establecido.'};
                deliveryDays = setting.deliveryDays;
            } else {
                deliveryType = {value: 2, label: 'Dias de entrega', description: 'Las entregas se programaran para el proximo dia seleccionado.'};
                deliveryDays = setting.deliveryDays.split(',').map((d) => (parseInt(d)));
            }
            this.setState({delivery: {...this.state.delivery, show: true, deliveryType, deliveryDays, create: false}});
        }
    };

    getCenter = (paths) => {
        const center = paths.map(p => {
            return [p.lat, p.lng]
        }).reduce((x, y) => {
            return [x[0] + y[0] / paths.length, x[1] + y[1] / paths.length]
        }, [0, 0])
        return {latLng: {lat: center[0], lng: center[1]}};
    };

    onGoogleApiLoaded = (map, maps, points) => {
        if (!map || !maps) return;
        let {mapS, mapsS, manualPolygon} = this.state;

        if (manualPolygon !== undefined) manualPolygon.setMap(null);

        if (!mapS || !mapsS) {
            mapS = map;
            mapsS = maps
        }
        const center = this.getCenter(points);
        if (center.latLng.lat > 0) mapS.panTo(center.latLng);

        manualPolygon = new mapsS.Polygon({
            paths: points,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35
        });

        manualPolygon.setMap(mapS);
        this.setState({mapS, mapsS, manualPolygon});
    };

    acceptLink = () => {
        const {accept, match} = this.props;
        const {delivery} = this.state;
        // match.params.id
        let deliveryDays = '';

        if (delivery.deliveryType.value === 2)
            delivery.deliveryDays.map((d, index) => {
                let space = ',';
                if (delivery.deliveryDays.length === (index+1))
                    space = '';
                deliveryDays += (d + space) ;
            });
        else deliveryDays = delivery.deliveryDays;

        const query = {
            deliveryType: delivery.deliveryType.value,
            deliveryDays: deliveryDays,
            deliveryAmount: 0
        };
        accept(match.params.id, query);
    };

    updateDelivery = () => {
        const {match, onUpdateSetting} = this.props;
        const {delivery} = this.state;

        let deliveryDays = '';

        if (delivery.deliveryType.value === 2)
            delivery.deliveryDays.map((d, index) => {
                let space = ',';
                if (delivery.deliveryDays.length === (index+1))
                    space = '';
                deliveryDays += (d + space) ;
            });
        else deliveryDays = delivery.deliveryDays;

        const query = {
            deliveryType: delivery.deliveryType.value,
            deliveryDays: deliveryDays,
            deliveryAmount: 0
        };
        onUpdateSetting(match.params.id, query);
    };

    render() {
        const {offer, loading, company, loadingS, loadingAccept, currentCurrency} = this.props;

        const {productDetails, details, domain, values, disabled, price, polygonCords, updateChange, delivery} = this.state;
        const {module, show, product, transportationPayments} = productDetails;

        return (
            <div style={{marginBottom: 50}}>
                <h1 className={'dashboard-welcome'}>{offer.alias || 'Cargando...'}</h1>
                <Row>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Row>
                            <Col xl={12} lg={12} md={12}>
                                <Card title={'Detalles'}>
                                    <Gallery
                                        company={offer.company}
                                        imageType={imageTypes.OFFER}
                                        entityId={this.props.match.params.id}
                                        showUploader={offer.company === company}
                                    />
                                    <div>
                                        <Row style={{justifyContent: 'center'}}>
                                            <span className={'client-name'}>{offer.alias || 'Desconocido'}</span>
                                        </Row>
                                        <Row className={'flex-container space-between'}>
                                            <Col className={'label'}>Código:</Col>
                                            <Col className={'description'}>{offer.correlative || 'Desconocido'}</Col>
                                        </Row>
                                        <Row className={'flex-container space-between'}>
                                            <Col className={'label'}>Disponible hasta:</Col>
                                            <Col className={'description'}>{offer.correlative ? formatDateFromMillis(offer.availableUntil) : 'Desconocido'}</Col>
                                        </Row>
                                        <Row className={'flex-container space-between'}>
                                            <Col className={'label'}>Compañia</Col>
                                            <Col className={'description'}>{offer.companyName || 'Desconocido'}</Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                            <Col xl={12} lg={12} md={12}>
                                <Card title={'Descripción'}>
                                    <p>{offer.notes}</p>
                                </Card>
                            </Col>
                            <Col>
                                <MapCard
                                    address={offer.polygonName || 'Vista Previa'}
                                    latitude={latitude}
                                    longitude={longitude}
                                    markerList={[]}
                                    showMarker={true}
                                    zoom={13}
                                    yesIWantToUseGoogleMapApiInternals
                                    onGoogleApiLoaded={({ map, maps }) => this.onGoogleApiLoaded(map, maps, polygonCords)}
                                />
                            </Col>
                            {((module === 1000 || module === 11000) && !loading && !offer.contractId) &&
                            <Col md={12}>
                                <Row>
                                    <Col>
                                        <Button className={'big-button'} onClick={() => this.acceptOffer()}> Aceptar oferta</Button>
                                    </Col>
                                </Row>
                            </Col>}
                            {(offer.contractId && offer.transportist && offer.transportist.id === company) && <Col md={12}>
                                <Row>
                                    <Col>
                                        <Button className={'big-button'} onClick={() => this.acceptOffer(offer.setting)}> Tiempos de entrega</Button>
                                    </Col>
                                </Row>
                            </Col>}
                        </Row>
                    </Col>
                    <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                        <Card title={'Detalles del enlace'}>
                            {(offer.company && offer.company === company) && <Row className={'container-buttons'}>
                                <Button variant={'danger'} onClick={() => this.setState({updateChange: false})} disabled={!updateChange} icon={faWindowClose} > Cancelar</Button>
                                <Button variant={'info'} onClick={() => this.setState({updateChange: true})} disabled={updateChange} icon={faEdit} > Actualizar productos</Button>
                            </Row>}

                            {(offer.company && offer.company === company && updateChange) ?
                                <OfferUpdate id={this.props.match.params.id} onChange={(value) => this.setState({updateChange: value})}/> :
                                <Row>
                                    <Col xl={12} lg={12} md={12}>
                                        <TableV2
                                            headers={[
                                                { label: 'Codigo', show: true, value: ['product','code'], type: 'text', className: 'mini', custom:(item)=> item.product ? item.product.code : "desconocido" },
                                                { label: 'Nombre', show: true, value: ['product','name'], type: 'text', className: 'mini', custom:(item)=> item.product ? item.product.name : "desconocido" },
                                                { label: 'Precio unitario ', show: true, value: ['product', 'price'], type: 'currency', className: 'mini' },
                                                { config: true, show: true, label: '', className: 'mini center' }
                                            ]}
                                            items={offer.productList}
                                            loading={loading}
                                            mobileAuto
                                            storageKey={`offerDetails`}
                                        />
                                    </Col>
                                    {/*
                                <Col xl={7} lg={7} md={8}>
                                    <Card title={'Solicitudes'}>
                                        <PaginatedTable
                                            headers={['Transportista', 'Propuesto', 'Acciones']}
                                            renderRow={this.renderRowContra}
                                            items={contraOffers.sort((a, b) => (b.createdAt - a.createdAt))}
                                            loading={loadingContra}
                                            noItemsLegend={'No hay contra ofertas.'}
                                        />
                                    </Card>
                                </Col>
                                */}
                                </Row>
                            }
                        </Card>
                    </Col>
                </Row>

                <Modal
                    show={show}
                    size={'lg'}
                    centered
                    onHide={() => this.setState({productDetails: {...productDetails, show: false}})}>

                    <Modal.Header closeButton>
                        <Modal.Title>{product.name || ''} Zonas de trasnporte</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <TableV2
                            headers={[
                                { label: '#', show: true, value: ['polygon', 'code'], type: 'text', className: 'mini' },
                                { label: 'Localidad', show: true, value: ['polygon', 'name'], type: 'text', className: 'medium' },
                                {
                                    config: true, show: true, label: '', className: 'mini center',
                                    custom: (item,i) => <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <Icon icon={faEye} tooltip={'Detalles'} onClick={() => this.seePricesDetails(i)} style={{ marginRight: 4 }} />
                                    </div>
                                }

                            ]}
                            items={transportationPayments}
                            mobileAuto
                            storageKey={`transportZones`}
                        />

                        <Modal
                            show={details.show}
                            size={'lg'}
                            centered
                            onHide={() => this.setState({details: {...details, show: false}})}>

                            <Modal.Header closeButton>
                                <Modal.Title>{product.name || ''}: {details.polygon ? details.polygon.name : ''}</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <div style={{margin: 3}} className={'column'}>
                                    <br/><br/>
                                    <Slider
                                        disabled={disabled}
                                        step={1}
                                        domain={domain}
                                        rootStyle={sliderStyle}
                                        onUpdate={this.onUpdate}
                                        onChange={this.onChange}
                                        values={values}
                                    >
                                        <Rail>{railProps => <TooltipRail {...railProps} />}</Rail>
                                        <Handles>
                                            {({handles, activeHandleID, getHandleProps}) => (
                                                <div className="slider-handles">
                                                    {handles.map(handle => (
                                                        <Handle
                                                            key={handle.id}
                                                            disabled={disabled}
                                                            handle={handle}
                                                            domain={domain}
                                                            isActive={handle.id === activeHandleID}
                                                            getHandleProps={getHandleProps}
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                        </Handles>
                                        <Tracks right={false}>
                                            {({tracks, getTrackProps}) => (
                                                <div className="slider-tracks">
                                                    {tracks.map(({id, source, target}) => (
                                                        <Track
                                                            key={id}
                                                            disabled={disabled}
                                                            source={source}
                                                            target={target}
                                                            getTrackProps={getTrackProps}
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                        </Tracks>
                                    </Slider>
                                    <br/><br/>

                                    <div className={'container-offer column'}>
                                        <Row>
                                            <Col xl={7} lg={7} md={7} sm={7}
                                                 xs={8}>
                                                <div
                                                    className={'container-offer'}>
                                                    <div
                                                        className={'center-offer'}>
                                                        <span><strong>Unidades</strong></span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={5} lg={5} md={5} sm={5}
                                                 xs={4}>
                                                <div
                                                    className={'container-offer'}>
                                                    <div
                                                        className={'center-offer'}>
                                                        <span><strong>Pagos</strong></span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={7} lg={7} md={7} sm={7}
                                                 xs={8}>
                                                <div
                                                    className={'container-offer'}>
                                                    <div
                                                        className={'flex-end-offer'}>
                                                        <div>
                                                            <FormTextField
                                                                disabled
                                                                mt={0}
                                                                type={'number'}
                                                                name={'minimum'}
                                                                onChange={() => console.log('value')}
                                                                value={price.minimum}
                                                            />
                                                        </div>
                                                        <div className={'mt-4'}>
                                                            <strong>{'<'}=={'>'}</strong>
                                                        </div>
                                                        <div>
                                                            <FormTextField
                                                                disabled
                                                                mt={0}
                                                                type={'number'}
                                                                name={'maximum'}
                                                                onChange={() => console.log('value')}
                                                                value={price.maximum}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={5} lg={5} md={5} sm={5}
                                                 xs={4}>
                                                <div
                                                    className={'container-offer'}>
                                                    <div
                                                        className={'flex-start-offer'}>
                                                        <div>
                                                            <FormTextField
                                                                disabled
                                                                mt={0}
                                                                id={'price'}
                                                                currency
                                                                name={'price'}
                                                                onChange={() => console.log('value')}
                                                                value={price.amount}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <br/><br/>
                                <CollapsibleCard title={'Mostrar lista'}
                                                 startCollapsed={true}>
                                    {
                                        details.amounts.map((p) =>
                                            <Row key={p.id}>
                                                <Col xl={7} lg={7} md={7} sm={7}
                                                     xs={8}>
                                                    <div
                                                        className={'container-offer'}>
                                                        <div
                                                            className={'flex-end-offer'}>
                                                            <div>
                                                                <FormTextField
                                                                    disabled
                                                                    mt={0}
                                                                    type={'number'}
                                                                    name={'minimum'}
                                                                    onChange={() => console.log('value')}
                                                                    value={p.minimum}
                                                                />
                                                            </div>
                                                            <div
                                                                className={'mt-4'}>
                                                                <strong>{'<'}=={'>'}</strong>
                                                            </div>
                                                            <div>
                                                                <FormTextField
                                                                    disabled
                                                                    mt={0}
                                                                    type={'number'}
                                                                    name={'maximum'}
                                                                    onChange={() => console.log('value')}
                                                                    value={p.maximum}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xl={5} lg={5} md={5} sm={5}
                                                     xs={4}>
                                                    <div
                                                        className={'container-offer'}>
                                                        <div
                                                            className={'flex-start-offer'}>
                                                            <div>
                                                                <FormTextField
                                                                    disabled
                                                                    mt={0}
                                                                    id={'price'}
                                                                    currency
                                                                    name={'price'}
                                                                    onChange={() => console.log('value')}
                                                                    value={p.amount || 0.00}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                </CollapsibleCard>

                            </Modal.Body>
                        </Modal>


                    </Modal.Body>

                </Modal>

                <Modal
                    show={delivery.show}
                    size={'lg'}
                    centered
                    onHide={() => this.setState({delivery: {create: false, show: false, deliveryType: {value: null, label: 'sin seleccionar', description: null}, deliveryDays: []}})}>

                    <Modal.Header closeButton><Modal.Title>Es necesario indicar los tiempos de entrega</Modal.Title></Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={6}><Select
                                label={'Tipo de entrega'}
                                options={
                                    [{
                                        value: 1,
                                        label: 'Tiempo en dias',
                                        description: 'Cuanto escoges tiempo en dias adquieres el compromiso en entregar la orden en el tiempo establecido.'
                                    }, {
                                        value: 2,
                                        label: 'Dias de entrega',
                                        description: 'Las entregas se programaran para el proximo dia seleccionado.'
                                    }]
                                }
                                value={delivery.deliveryType}
                                onChange={(type) => {
                                    let deliveryDays = null;
                                    if (type.value === 1)
                                        deliveryDays = '1';
                                    else deliveryDays = [];
                                    this.setState({delivery: {...delivery, deliveryType: type, deliveryDays}})
                                }}
                                required
                            /></Col>
                            <Col md={6}>
                                <br/>
                                <br/>
                                <span><strong>{delivery.deliveryType.value ? delivery.deliveryType.description : 'Es necesario espesificar el tipo de entrega para determinar a los clientes que dia sera entregado su producto'}</strong></span>
                            </Col>
                        </Row>
                        {delivery.deliveryType.value && <Row>
                            {delivery.deliveryType.value === 2 && <Col>
                                <div>
                                    <h6>Días de entrega</h6>
                                    <ToggleButtonGroup type="checkbox" value={delivery.deliveryDays} onChange={(deliveryDays) => this.setState({delivery: {...delivery, deliveryDays}})}>{
                                        days.map((item, index) => (
                                            <ToggleButton value={item.id} key={item.id}>{item.title}</ToggleButton>
                                        ))
                                    }
                                    </ToggleButtonGroup>
                                </div>
                            </Col>}
                            {delivery.deliveryType.value === 1 && <Col md={6}>
                                <Input
                                    label={'Tiempo en dias despues del pedido'}
                                    prepend={'Dias'}
                                    type={'number'}
                                    value={delivery.deliveryDays}
                                    onChange={({target}) => {
                                        const {value} = target;
                                        delivery.deliveryDays = value;
                                        this.setState({delivery: {...delivery}});
                                    }}
                                    required
                                />
                            </Col>}
                        </Row>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Row className={'container-buttons'}>
                            <Button
                                onClick={() => delivery.create ? this.acceptLink() : this.updateDelivery()}
                                icon={delivery.create ? faLink : faCalendarDay}
                                variant={'info'}
                                loading={loadingAccept || loadingS}
                                disabled={!delivery.deliveryType.value || (delivery.deliveryType.value === 1 && !delivery.deliveryDays) || (delivery.deliveryType.value === 2 && (!delivery.deliveryDays || delivery.deliveryDays.length <= 0))}
                            > {delivery.create ? 'Aceptar enlace' : 'Actualizar tiempos de entrega'}</Button>
                        </Row>
                    </Modal.Footer>
                </Modal>

                <Alert {...this.state.alert}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    offer: selectOffer(state),
    contraOffers: selectContraOffers(state),
    loading: loadingSelector([types.GET_SINGLE_OFFER])(state),
    loadingContra: loadingSelector([types.GET_ALL_CONTRA_OFFER])(state),
    loadingAccept: loadingSelector([types.ON_ACCEPT_OFFER])(state),
    hasErrorAccept: hasErrorsSelector([types.ON_ACCEPT_OFFER])(state),
    errorAccept: singleErrorSelector([types.ON_ACCEPT_OFFER])(state),
    module: selectCurrentModule(state),
    company: selectCurrentCompany(state),
    loadingS: loadingSelector([types.ON_UPDATE_SETTING])(state),
    hasErrorS: hasErrorsSelector([types.ON_UPDATE_SETTING])(state),
    errorS: singleErrorSelector([types.ON_UPDATE_SETTING])(state)
});
const mapDispatchToProps = dispatch => ({
    getOffer: (id) => dispatch(getSingleOffer(id)),
    getContraOffers: (id) => dispatch(getContraOffersByProducer(id)),
    accept: (id, setting) => dispatch(acceptOffer(id, setting)),
    onUpdateSetting: (id, request) => dispatch(onUpdateSetting(id, request))
});
export default connect(mapStateToProps, mapDispatchToProps)(OffersDetails);
