import NotificationsService from '../services/notifications.service'
import { executeAction } from './global.actions'

export const actionTypes = {
  GET_ALL_NOTIFICATIONS: 'GET_ALL_NOTIFICATIONS',
  GET_RECENT_NOTIFICATIONS: 'GET_RECENT_NOTIFICATIONS',
  SUBSCRIBE_USER: 'SUBSCRIBE_USER',
  GET_PLAYER_ID: 'GET_PLAYER_ID',
  IS_ENABLED: 'IS_ENABLED',
  SEND_NOTIFICATION: 'SEND_NOTIFICATION',
  MARK_AS_READ: 'MARK_AS_READ',
  GET_ORDER_BY_NOTIFICATION: 'GET_ORDER_BY_NOTIFICATION',
  GET_FIELDS: 'GET_FIELDS',
  UPDATE_CONFIGURATION: 'UPDATE_CONFIGURATION',
  GET_USERS_BY_NOTIFICATION: 'GET_USERS_BY_NOTIFICATION',
  SET_USERS_TO_NOTIFICATION: 'SET_USERS_TO_NOTIFICATION',
}

export const getAllNotification = (sDate, eDate) => dispatch => {
  const process = async () => ({
    response: await NotificationsService.getAllNotifications(sDate, eDate),
  })
  dispatch(executeAction(actionTypes.GET_ALL_NOTIFICATIONS, process))
}

export const getRecentNotifications = () => dispatch => {
  const process = async () => ({
    response: await NotificationsService.getRecentNotifications(),
  })
  dispatch(executeAction(actionTypes.GET_RECENT_NOTIFICATIONS, process))
}

export const subscribeUser = id => dispatch => {
  const process = async () => ({
    response: await NotificationsService.subscribeUser(id),
  })
  dispatch(executeAction(actionTypes.SUBSCRIBE_USER, process))
}

export const sendNotifications = request => dispatch => {
  const process = async () => ({
    response: await NotificationsService.sendNotification(request),
  })
  dispatch(executeAction(actionTypes.SEND_NOTIFICATION, process))
}

export const markAsRead = id => dispatch => {
  const process = async () => ({
    response: await NotificationsService.markAsRead(id),
  })
  dispatch(executeAction(actionTypes.MARK_AS_READ, process))
}

export const markAsReadList = ids => dispatch => {
  let promises = []
  ids.map(async id => {
    promises.push(NotificationsService.markAsRead(id))
  })
  return Promise.all(promises).then(() => dispatch(getRecentNotifications()))
}

export const getOrderByNotification = id => dispatch => {
  const process = async () => ({
    response: await NotificationsService.getOrder(id),
  })
  dispatch(executeAction(actionTypes.GET_ORDER_BY_NOTIFICATION, process))
}

export const getNotificationsField = () => dispatch => {
  const process = async () => ({
    response: await NotificationsService.getFields(),
  })
  dispatch(executeAction(actionTypes.GET_FIELDS, process))
}

export const updateNotificationsField = (id, app, email, companyId) => dispatch => {
  const process = async () => ({
    response: await NotificationsService.updateFieldConfiguration(
      id,
      app,
      email,
      companyId,
    ),
  })
  dispatch(executeAction(actionTypes.UPDATE_CONFIGURATION, process))
}

export const getUsersByNotification = id => dispatch => {
  const process = async () => ({
    response: await NotificationsService.getUsersByNotification(id),
  })
  dispatch(executeAction(actionTypes.GET_USERS_BY_NOTIFICATION, process))
}

export const setUsersToNotification = request => dispatch => {
  const process = async () => await NotificationsService.setUsersToNotification(request)
  dispatch(executeAction(actionTypes.SET_USERS_TO_NOTIFICATION, process))
}
