import './ProductItems.scss'
import React from 'react'

import { Accordion, useAccordionButton as useAccordionToggle } from 'react-bootstrap'
// import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

import holder from 'src/assets/images/svg/food.svg'
import { ListVariations } from 'src/content/Production/Categorization/Variation/ListVariations'

/** INFO: Object structure
 * title
 * code
 * existence
 * quantity
 * price
 * subTotal
 * more {
 *   Unit price
 *   Discount
 *   sub products
 * }
 * */

function CustomToggle({ eventKey, props }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    return
  })
  return (
    <div
      className={'column '.concat(props.disabled ? 'pi-disabled' : '')}
      style={{ ...props.style, cursor: 'pointer' }}
      onClick={() => {
        if (!props.disabled) {
          if (!props.more && props.setSelect) props.setSelect()
        }
      }}>
      <div className={'pi-src'}>
        <img src={props.img || holder} alt="" />
      </div>
      <div
        className={'pi-body column'}
        onClick={() => {
          if (props.more) decoratedOnClick()
        }}>
        <div className={'pi-body-title space-between'}>
          <div className={'left'}>{props.title}</div>
          {props.titleSecond && <span>{props.titleSecond}</span>}
        </div>
        <div className={'pi-body-sub-title'}>
          <div className={'left'}>{props.subTitle}</div>
          {props.input ? (
            <span className={'d-flex'} style={{ justifyContent: 'flex-end' }}>
              {props.input}
              {props.subTitleSecond && (
                <div style={{ marginTop: 8 }}>{props.subTitleSecond}</div>
              )}
            </span>
          ) : (
            props.subTitleSecond && <span>{props.subTitleSecond}</span>
          )}
        </div>
        <div>
          {props.listCategories && props.listCategories.length > 0 && (
            <ListVariations selected={props.listCategories} />
          )}
        </div>
        <div>
          {props.listLocations && props.listLocations.length > 0 && (
            <ListVariations selected={props.listLocations} groupName={'Ubicacion'} />
          )}
        </div>
      </div>
      {props.actions && <div className={'pi-actions'}>{props.actions}</div>}
      {props.field && <div>{props.field}</div>}
    </div>
  )
}

const ProductItem = props => {
  return (
    <li style={{ backgroundColor: props.disabled ? '#d3d3d3' : null }}>
      <CustomToggle eventKey={props.eventKey} props={{ ...props }} />
      {props.more && (
        <Accordion.Collapse eventKey={props.eventKey}>
          <div className={'pi-more'}>
            <hr />
            {props.more}
          </div>
        </Accordion.Collapse>
      )}
    </li>
  )
}
export default React.memo(ProductItem)
