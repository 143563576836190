export const selectGetBanks = state => {
  return state.bank.banks.map(bank => ({ ...bank, value: bank.id, label: bank.name }))
}
export const selectGetAccounts = state => state.bank.bankAccounts

export const selectGetAccountsForSelect = state =>
  state.bank.bankAccounts.map(b => {
    return { ...b, value: b.id, label: `${b.name} - No. ${b.accountNumber}` }
  })
export const selectRequestAccount = state => state.bank.requestAccount
export const selectCreateAccount = state => state.bank.bankAccount
export const selectUpdateAccount = state => state.bank.bankAccount
export const selectDeleteAccount = state => state.bank.deletedAccount
export const selectGetHistory = state => state.bank.bankHistory

export const selectCSVResponse = state => state.bank.csvResponse
export const selectCreateOperation = state => state.bank.movement
export const selectMovementByDeposit = state =>
  state.bank.movementByDeposit.map(m => ({ ...m }))
export const selectDeposits = state => state.bank.deposits
export const selectRevertOperation = state => state.bank.revert
export const selectGetAllCurrencies = state => [
  ...state.currencies.allCurrencies.map(c => ({ ...c, label: c.code, value: c.id })),
]
