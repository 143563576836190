import React, { useState, useEffect } from 'react'

import { geolocated } from 'react-geolocated'

import WarehouseService from '../../services/warehouse.service'

const GetLocation = ({
  start,
  warehouseId,
  show,
  getGeo,
  isGeolocationAvailable,
  isGeolocationEnabled,
  coords,
}) => {
  const [initial, setInit] = useState(false)

  useEffect(() => {
    if (!initial && start) {
      if (coords) getLocation()
    }
  }, [coords])

  const getLocation = async () => {
    setInit(true)

    let cc = { latitude: null, longitude: null }
    let useOwn = false

    if (warehouseId) {
      const gpsCoords = await WarehouseService.getGPSCoords(warehouseId)
      if (gpsCoords && gpsCoords.length > 0 && !gpsCoords[0].type) {
        cc = {
          latitude: gpsCoords[0].latitude,
          longitude: gpsCoords[0].longitude,
        }
      } else useOwn = true
    } else useOwn = true

    if (useOwn) {
      if (isGeolocationAvailable && isGeolocationEnabled) {
        cc = { latitude: coords.latitude, longitude: coords.longitude }
      }
    }

    if (getGeo) getGeo(cc)
  }

  return show ? <div onClick={() => getLocation()}>Click me</div> : <div />
}
export default geolocated({
  positionOptions: { enableHighAccuracy: true, userDecisionTimeout: 3000 },
})(GetLocation)
