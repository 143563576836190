import AccountService from '../services/account.service'
import { executeAction } from './global.actions'

export const actions = {
  GET_CARDS: 'GET_CARDS',
  CREATE_CARD: 'CREATE_CARD',
  DELETE_CARD: 'DELETE_CARD',
  FAVORITE_CARD: 'FAVORITE_CARD',
  GET_FAVORITE_CARD: 'GET_FAVORITE_CARD',
}

/**
 * Obtiene las tarjetas de una empresa
 * @returns None
 */
export const getCards = () => dispatch => {
  const process = async () => ({ cards: await AccountService.getCards() })
  dispatch(executeAction(actions.GET_CARDS, process))
}

/**
 * Crea una tarjeta para una empresa
 * @param {object} request
 * @returns None
 */
export const createCard = (request: ICreateCreditCardRequest) => dispatch => {
  const process = async () => {
    const response = await AccountService.createCard(request)
    dispatch(getCards())
    return { response }
  }
  dispatch(executeAction(actions.CREATE_CARD, process))
}

/**
 * Elimina una tarjeta de una empresa
 * @param {number} id Identificador de la tarjeta
 * @returns None
 */
export const deleteCard = (id: number) => dispatch => {
  const process = async () => {
    const response = await AccountService.deleteCard(id)
    dispatch(getCards())
    return { response }
  }
  dispatch(executeAction(actions.DELETE_CARD, process))
}

/**
 * Marca una tarjeta como favorita para pagos recurrentes
 * @param {number} id Identificador de la tarjeta
 * @returns None
 */
export const favoriteCard = (id: number) => dispatch => {
  const process = async () => {
    const response = await AccountService.favoriteCard(id)
    dispatch(getCards())
    return { response }
  }
  dispatch(executeAction(actions.FAVORITE_CARD, process))
}

export const getFavoriteCard = () => dispatch => {
  const process = async () => ({
    response: await AccountService.getFavoriteCard(),
  })
  dispatch(executeAction(actions.GET_FAVORITE_CARD, process))
}
