import React from 'react'
import { useSelector } from 'react-redux'
import { Select } from 'src/components'
import CustomCreate from 'src/components/inputs/Creable/CustomCreate'
import { isAllowed } from 'src/selectors/modules.selector'
import { toMoney } from 'src/utils/utilities'

interface Props {
  item: IItemSelectTable
  currentCurrency: ICurrency
  onUpdate: () => void
  mobile?: boolean
  ignorePermissions?: boolean
}

/** Component that handles the price part of the item.
 * @component
 * @param {IItemSelectTable} item Table item selected
 * @param {ICurrency} currentCurrency Current currency
 * @param {Function} onUpdate Function to update the item
 * @param {boolean} mobile Flag to know if it is mobile
 * @param {boolean} ignorePermissions Ignore balance display permissions
 * @returns
 */
export const ItemPrice = ({
  item,
  currentCurrency,
  onUpdate,
  mobile,
  ignorePermissions = false,
}: Props) => {
  const canMultiplePrice =
    useSelector(state => isAllowed(state, [1131])) || ignorePermissions
  const canUpdatePrice =
    (useSelector(state => isAllowed(state, [1132])) && item.activeUpdatable) ||
    ignorePermissions

  return (
    <div style={{ marginTop: 3 }}>
      {canUpdatePrice ? (
        <CustomCreate
          label={mobile && 'Precio'}
          style={{ width: '100%', flex: 1 }}
          placeholder={'Buscar o seleccionar'}
          value={
            item.selectedPrice || {
              value: item.price,
              label: toMoney(item.price),
            }
          }
          textLabel={`Agregar precio ${currentCurrency.symbol}`}
          mt={0}
          withoutLabel={!mobile}
          options={
            item.list
              ? item.list.map(l => ({
                  value: Number(l.price),
                  alias: l.alias,
                  label: `${l.alias} - ${toMoney(l.price)}`,
                }))
              : []
          }
          alias={item.selectedAlias}
          onCreateOption={value => {
            if (!isNaN(value)) {
              if (value < 0) value = 0
              item.price = value
              item.selectedPrice = {
                value: Number(value),
                label: toMoney(value),
                alias: 'Nuevo precio',
              }
              item.selectedAlias = 'Nuevo precio'

              if (item.productData && item.productData.price === undefined)
                item.productData.price = value

              item.save = false
              onUpdate()
            }
          }}
          onChange={value => {
            if (value) {
              const comp = `${value.value || 0}`
              if (comp.match(/[0-9]+/g)) {
                if (value.value < 0)
                  value = {
                    value: Number(value.value),
                    label: toMoney(value.value),
                  }

                item.price = value.value
                item.selectedPrice = value
                item.selectedAlias = value.alias
                item.save = false
              }
              onUpdate()
            }
          }}
        />
      ) : canMultiplePrice && item.list && item.list.length > 0 ? (
        <Select
          label={mobile && 'Precio'}
          value={
            item.selectedPrice || {
              value: item.price,
              label: toMoney(item.price),
            }
          }
          options={item.list.map(l => ({
            value: Number(l.price),
            alias: l.alias,
            label: `${l.alias} - ${toMoney(l.price)}`,
          }))}
          onChange={value => {
            item.selectedPrice = {
              ...value,
              label: toMoney(value.value),
            }
            item.selectedAlias = value.alias
            item.price = value.value
            onUpdate()
          }}
          mt={0}
          style={{ width: '100%', flex: 1 }}
          withoutLabel={!mobile}
          //disabled={item.add}
          alias={item.selectedAlias}
        />
      ) : (
        `Precio por defecto:  ${toMoney(item.price)}`
      )}
    </div>
  )
}
