import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import { CustomReference } from 'src/components'

import {
  actionTypes,
  deleteSingleOrder,
  getOrderDetailsById,
  onConfirmOrderPayment,
  onSetOrderDetailId,
} from 'src/actions/orders.actions'
import { selectOrderDetails, selectOrderIdToDetail } from 'src/selectors/orders.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'

import { onSetModalOrder } from 'src/actions/utilities.actions'
import { selectModalOrderId } from 'src/selectors/utilities.selector'

import { showAlert } from 'src/actions/alert.actions'

import { haveAnyValue } from 'src/utils/utilitiesV2'
import { orderActionTypeEnum } from 'src/components/custom/OrderDetail/OrderActionTypeEnum'

import OrderDetailData from 'src/components/custom/OrderDetail/OrderDetailData'
import OrderDetailItems from 'src/components/custom/OrderDetail/OrderDetailItems'
import OrderDetailPayments from 'src/components/custom/OrderDetail/OrderDetailPayments'
import OrderDetailButtons from 'src/components/custom/OrderDetail/OrderDetailButtons'

const ModalOrderDetail = () => {
  const dispatch = useDispatch()

  const orderId: number = useSelector(selectOrderIdToDetail)
  const show: boolean = haveAnyValue(orderId)

  const sellId = useSelector(selectModalOrderId)
  const showSellDetail: boolean = haveAnyValue(sellId)

  const order: IOrderDetails = useSelector(selectOrderDetails)
  const loading: boolean = useSelector(state =>
    loadingSelector([actionTypes.GET_ORDER_DETAILS])(state),
  )
  const error = useSelector(state => hasErrors([actionTypes.GET_ORDER_DETAILS])(state))

  const loadingActions: boolean = useSelector(state =>
    loadingSelector([
      actionTypes.DELETE_SINGLE_ORDER,
      actionTypes.ON_CONFIRM_PAYMENT_ORDER,
    ])(state),
  )
  const errorActions = useSelector(state =>
    hasErrors([actionTypes.DELETE_SINGLE_ORDER, actionTypes.ON_CONFIRM_PAYMENT_ORDER])(
      state,
    ),
  )

  const [flags, setFlag] = useState<ICRUDFlags>({ get: false })
  const [depositModal, setDepositModal] = useState({
    show: false,
    documentId: null,
  })

  const [actionType, setActionType] = useState<number>(null)

  const openModal: boolean = show && !showSellDetail && !depositModal.show

  useEffect(() => {
    if (!show) return
    setUp()
  }, [show])

  useEffect(() => {
    if (loading) setFlag({ ...flags, get: true })
    else if (flags.get) {
      setFlag({ ...flags, get: false })
      if (error)
        dispatch(
          showAlert({
            ...handlerError(error.message),
            onConfirm: () => onHide(),
          }),
        )
    }
  }, [loading])

  useEffect(() => {
    if (!show) return
    if (loadingActions) setFlag({ ...flags, update: true })
    else if (flags.update) {
      setFlag({ ...flags, update: false })
      const alert = errorActions
        ? handlerError(errorActions.message)
        : {
            ...handlerSuccess(),
            onConfirm: () => setUp(),
          }
      dispatch(showAlert(alert))
    }
  }, [loadingActions])

  const setUp = () => {
    dispatch(getOrderDetailsById(orderId))
  }

  const onHide = () => {
    dispatch(onSetOrderDetailId(null))
  }

  const onClickAction = (actionType: number) => {
    switch (actionType) {
      case orderActionTypeEnum.SEE_DEPOSIT:
        setDepositModal({ show: true, documentId: order.depositId })
        break
      case orderActionTypeEnum.REJECT:
        dispatch(deleteSingleOrder(orderId))
        break
      case orderActionTypeEnum.CONFIRM:
        dispatch(onConfirmOrderPayment(orderId))
        break
    }
    setActionType(actionType)
  }

  const getTitle: string = loading ? 'Cargando...' : `Orden ${order.code}`

  return (
    <div>
      <Modal show={openModal} size={'lg'} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>{getTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div>Cargando...</div>
          ) : (
            <Row>
              <Col xl={12}>
                <OrderDetailData order={order} />
              </Col>
              <Col xl={12}>
                <OrderDetailPayments payments={order.payments} />
              </Col>
              <Col xl={12}>
                <OrderDetailItems
                  items={order.items}
                  viewDetail={item => dispatch(onSetModalOrder(item.itemId))}
                />
              </Col>
            </Row>
          )}
        </Modal.Body>
        {!loading && (
          <Modal.Footer>
            <OrderDetailButtons
              stateId={order.statusId}
              depositId={order.depositId}
              onClick={onClickAction}
              loading={loadingActions}
              actionType={actionType}
            />
          </Modal.Footer>
        )}
      </Modal>

      <CustomReference
        show={depositModal.show}
        documentModule={2}
        documentType={4}
        documentId={depositModal.documentId}
        onHide={() => setDepositModal({ ...depositModal, show: false })}
      />
    </div>
  )
}
export default ModalOrderDetail
