import { faFileExcel, faSearch } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'

import { Col, Row } from 'react-bootstrap'
import {
  Title,
  Card,
  SelectedDates,
  Button,
  TableV2,
  CustomSummary,
  Select,
  Dropdown,
} from 'src/components'

import { useDispatch, useSelector } from 'react-redux'
import {
  actionTypes,
  exportWarehouseKardex,
  getWarehouseKardexReport,
  getWarehouseLite,
} from 'src/actions/warehouse.actions'
import {
  selectKardexReport,
  selectOwnWarehouseLite,
} from 'src/selectors/warehouse.selector'

import { loadingSelector } from 'src/selectors/loading.selector'

import { formatNumberWithCommas } from 'src/utils/formatters'
import { toMoney } from 'src/utils/utilities'

const movementsOptions = [
  {
    label: 'Con movimientos',
    value: 1,
  },
  {
    label: 'Sin movimientos',
    value: 2,
  },
  {
    label: 'Todos',
    value: 3,
  },
]

export const InventoryKardex = () => {
  const dispatch = useDispatch()

  const warehouses = useSelector(selectOwnWarehouseLite)
  const kardexReport = useSelector(selectKardexReport)

  const loadingKardex = useSelector(state =>
    loadingSelector([actionTypes.DOWNLOAD_GENERAL_KARDEX])(state),
  )
  const loadingKardexReport = useSelector(state =>
    loadingSelector([actionTypes.GET_WAREHOUSE_KARDEX_REPORT])(state),
  )

  const [filters, setFilters] = useState({
    startDate: new Date().setHours(0, 0, 0, 0).valueOf(),
    endDate: new Date().valueOf(),
    warehouse: null,
    movements: movementsOptions[0],
  })
  const [details, setDetails] = useState([])
  const [totals, setTotals] = useState({})

  useEffect(() => {
    dispatch(getWarehouseLite())
  }, [])

  useEffect(() => {
    if (warehouses.length === 0 || filters.warehouse !== null) return
    setFilters({ ...filters, warehouse: warehouses[0] })
    if (warehouses[0]) setUp(warehouses[0])
  }, [warehouses])

  useEffect(() => {
    if (Object.entries(kardexReport).length === 0) return
    setDetails(kardexReport.details)
    setTotals(kardexReport.totals)
  }, [kardexReport])

  const setUp = w => {
    dispatch(
      getWarehouseKardexReport(
        w ? w.value : filters?.warehouse?.value,
        filters?.startDate !== null
          ? filters?.startDate
          : new Date().setHours(0, 0, 0, 0).valueOf(),
        filters.endDate !== null ? filters.endDate : new Date().valueOf(),
        filters.movements.value,
      ),
    )
  }

  const getSubItems = (list, defaultValue, type) => {
    if (!list || list.length <= 1)
      return (
        <div>
          {type === 1 && defaultValue}
          {type === 2 && toMoney(defaultValue)}
          {type === 3 &&
            (list.length === 1
              ? formatNumberWithCommas(list[0].currentQuantity || 0)
              : formatNumberWithCommas(defaultValue || 0))}
        </div>
      )
    return (
      <div>
        <ul className={'base-sub-items'}>
          {list?.map(subItem => (
            <li key={subItem.id}>
              {type === 1 && `${subItem.productData.code} - ${subItem.productData.name}`}
              {type === 2 && toMoney(subItem.currentQuantity)}
              {type === 3 && formatNumberWithCommas(subItem.currentQuantity || 0)}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  const headers = [
    {
      label: 'Item',
      show: true,
      value: ['name'],
      type: 'text',
      className: 'medium',
      style: { minWidth: 280 },
      custom: item => {
        return getSubItems(item.initialQuantity, `${item.code} - ${item.name}`, 1)
      },
    },
    {
      label: 'Cantidad inicial',
      show: true,
      className: 'medium',
      custom: item => {
        return getSubItems(item.initialQuantity, item.prevQuantity, 3)
      },
    },
    {
      label: 'Entrada',
      show: true,
      value: ['item'],
      type: 'text',
      className: 'medium',
      custom: item => {
        return getSubItems(item.inAmount, item.quantityIn, 3)
      },
    },
    {
      label: 'Salida',
      show: true,
      value: ['item'],
      type: 'text',
      className: 'medium',
      custom: item => {
        return getSubItems(item.outAmount, Math.abs(item.quantityOut), 3)
      },
    },
    {
      label: 'Cantidad final',
      show: true,
      value: ['item'],
      type: 'text',
      className: 'medium',
      custom: item => {
        return getSubItems(item.finalQuantity, item.nowQuantity, 3)
      },
    },
    {
      label: 'Costo inicial',
      show: true,
      type: 'currency',
      value: ['amountInitial'],
      className: 'medium',
    },
    {
      label: 'Costo final',
      show: true,
      value: ['amountFinal'],
      type: 'currency',
      className: 'medium',
    },
    {
      label: '',
      className: 'mini center',
      show: true,
      config: true,
    },
  ]

  return (
    <div>
      <Title title={'Reporte de Inventario'} />

      <Card
        title={'Kardex de Bodega'}
        white
        button={
          <Row className={'container-buttons'} style={{ alignItems: 'center' }}>
            <Dropdown
              style={{ textTransform: 'none' }}
              tooltip={'Exportar'}
              loading={loadingKardex}
              items={[
                {
                  title: 'Exportar kardex',
                  tooltip: 'Exporta un excel del kardex con los filtros aplicados',
                  action: () => {
                    dispatch(
                      exportWarehouseKardex(
                        filters.warehouse.value,
                        filters.startDate,
                        filters.endDate,
                        filters.warehouse.label,
                        null,
                        filters.movements.value,
                      ),
                    )
                  },
                  icon: faFileExcel,
                  iconColor: 'green',
                  show: filters.warehouse !== null,
                },
              ]}
            />
          </Row>
        }>
        <CustomSummary
          items={[
            {
              show: true,
              title: 'Cantidad Inicial',
              value: formatNumberWithCommas(totals.prevQuantity),
            },
            {
              show: true,
              title: 'Costo Inicial',
              value: toMoney(totals.amountInitial),
            },
            {
              show: true,
              title: 'Entrada',
              value: formatNumberWithCommas(totals.quantityIn),
            },
            {
              show: true,
              title: 'Salida',
              value: formatNumberWithCommas(totals.quantityOut),
            },
            {
              show: true,
              title: 'Cantidad final',
              value: formatNumberWithCommas(totals.nowQuantity),
            },
            {
              show: true,
              title: 'Costo Final',
              value: toMoney(totals.amountFinal),
            },
          ]}
        />

        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <SelectedDates
              nonExecute
              onDateChange={(start, end) => {
                setFilters({ ...filters, startDate: start, endDate: end })
              }}
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={4} xl={4}>
            <Select
              label={'Bodega'}
              value={filters.warehouse}
              options={warehouses}
              onChange={data => setFilters({ ...filters, warehouse: data })}
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={4} xl={4}>
            <Select
              label={'Por movimientos'}
              value={filters.movements}
              options={movementsOptions}
              onChange={data => setFilters({ ...filters, movements: data })}
            />
          </Col>
          <Col xl={12} className="mt-2">
            <Row className={'container-buttons'}>
              <Button
                color={'accent'}
                icon={faSearch}
                disabled={filters.warehouse === null || loadingKardex}
                loading={loadingKardexReport}
                onClick={() => setUp()}>
                Aplicar filtros
              </Button>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <TableV2 loading={loadingKardexReport} headers={headers} items={details} />
          </Col>
        </Row>
      </Card>
    </div>
  )
}
