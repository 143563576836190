import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'

import { Col, Modal, Row } from 'react-bootstrap'
import {
  Dropdown,
  Select,
  SelectedDates,
  TableV2,
  Button,
  Switch,
  Icon,
  Commentary,
  FAB,
  Folder,
} from 'src/components'
import {
  faBoxOpen,
  faCubes,
  faEllipsisV,
  faFileExcel,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  exportWarehouseKardex,
  getInventoryExistence,
} from 'src/actions/warehouse.actions'
import { selectSingleWarehouse } from 'src/selectors/warehouse.selector'

import { loadingSelector } from 'src/selectors/loading.selector'

import { isAllowed } from 'src/selectors/modules.selector'

import { getMeasurementSymbol, toMoney } from 'src/utils/utilities'
import Money from 'src/components/Money/Money'
import InventoryReport from 'src/components/inventory/InventoryReport'
import InventoryHistory from 'src/components/inventory/InventoryHistory'
import { formatNumberWithCommas } from '../../utils/formatters'
import { WarehouseVariatons } from 'src/content/Warehouse/WarehouseVariatons'
import { getCompanyField } from 'src/actions/company.actions'
import { selectCompanyFieldById } from 'src/selectors/company.selector'
import CSVLoaderV2, { CATEGORY_UPLOAD } from 'src/components/CSVLoader/CSVLoaderV2'
import {
  types as inventoryTypes,
  dispatchPendingInventory,
  getCSVInventoryProducts,
  getCSVLocations,
} from 'src/actions/inventory.actions'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'
import { categoryType } from 'src/components/folders/Folder'
import IconButton from 'src/components/buttons/IconButton'
import {
  getAllCategorizations,
  getCategorizationByType,
} from 'src/actions/categorization.actions'
import { SubItemInventory } from 'src/content/Warehouse/SubItemInventory'
import { inventoriesPermissions } from 'src/enums/permissions'
import {
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
} from 'src/selectors/error.selector'
import { showAlert } from 'src/actions/alert.actions'
import companyFields from 'src/enums/companyFields'

const existenceOptions = [
  { value: 0, label: 'Todos' },
  { value: 1, label: 'Con existencia' },
  { value: 2, label: 'Sin existencia' },
  { value: 3, label: 'Con inventario pendiente' },
]

const defaultExistenceOption = existenceOptions[1]

const WarehouseInventory = ({ id, modal, show, onHide, name, isInventory }) => {
  const dispatch = useDispatch()

  const singleWarehouse = useSelector(selectSingleWarehouse)
  const response = useSelector(state => state.warehouse.inventoryResponse)
  const { items, total, value } = response
  const seeWarehouseLocations = useSelector(state => selectCompanyFieldById(state, 124))
  const seeMeasureUnit = useSelector(state =>
    selectCompanyFieldById(state, companyFields.seeMeasureUnit),
  )
  const categorization = useSelector(selectAllCategorizations)

  const loadingKardex = useSelector(state =>
    loadingSelector([actionTypes.DOWNLOAD_GENERAL_KARDEX])(state),
  )
  const loadingInventory = useSelector(state =>
    loadingSelector([actionTypes.INVENTORY_WAREHOUSE])(state),
  )

  const loadingDispatch = useSelector(state =>
    loadingSelector([inventoryTypes.DISPATCH_PENDING_INVENTORY])(state),
  )
  const hasErrorDispatch = useSelector(state =>
    hasErrorsSelector([inventoryTypes.DISPATCH_PENDING_INVENTORY])(state),
  )

  const seeSum = useSelector(state => isAllowed(state, [1160, 2110]))
  const seeMoneyBalances = useSelector(state => isAllowed(state, [1163, 2112]))
  const canDispatchPendingInventory = useSelector(state =>
    isAllowed(state, [inventoriesPermissions.dispatchPendingInventory]),
  )

  const [action, setAction] = useState({ dispatch: false })
  const [selectedInventory, setSelectedInventory] = useState([])
  const [report, setReport] = useState({
    warehouseId: null,
    productId: 0,
    productName: null,
    showItem: false,
    reserved: false,
  })
  const [history, setHistory] = useState({
    warehouseId: null,
    productId: 0,
    productName: null,
  })
  const [filters, setFilters] = useState({
    skip: 0,
    size: 10,
    search: '',
    typeId: defaultExistenceOption,
    categorization: null,
  })
  const [kardex, setKardex] = useState({})
  const [seeBase, setSeeBase] = useState(false)
  const [variation, setVariation] = useState({
    productId: null,
    name: '',
    warehouseId: null,
    show: false,
    baseItemData: {},
    filterByVariations: false,
  })
  const [all, setAll] = useState(false)
  const [showCategorization, setShowCategorization] = useState(false)
  const [categorizationSelected, setCategorizationSelected] = useState([])

  useEffect(() => {
    if (show && id) {
      setFilters({ ...filters, typeId: defaultExistenceOption })
      const presentationFactor = localStorage.getItem('presentation-factor')
      setSeeBase(presentationFactor === 'true')
    }
    dispatch(getCompanyField(124))
    dispatch(getAllCategorizations(categoryType.PRODUCTS.id))
  }, [show])

  useEffect(() => {
    setUp()
  }, [id, filters])

  useEffect(() => {
    if (loadingDispatch) setAction({ ...action, dispatch: true })
    else if (action.dispatch) {
      setAction({ ...action, dispatch: false })
      if (hasErrorDispatch)
        dispatch(
          showAlert({
            ...handlerError('No se pudo despachar el inventario pendiente'),
          }),
        )
      else {
        setUp()
        dispatch(
          showAlert({
            ...handlerSuccess('Inventario despachado exitosamente'),
          }),
        )
      }
    }
  }, [loadingDispatch])

  const setUp = () => {
    if (loadingInventory || !id) return
    dispatch(
      getInventoryExistence(
        id,
        true,
        true,
        filters.skip,
        filters.size,
        filters.search,
        filters.typeId.value,
        filters.categorization,
      ),
    )
  }

  const isSelected = item => {
    const isSelected = selectedInventory.some(inventory => inventory.id === item.id)
    return (!all || !isSelected) && (all || isSelected)
  }

  const customFilterElement = (
    <div className={'mb-2'}>
      <Row>
        <Col className={'borderRight'}>
          <Row>
            <Col xl={4} md={6} sm={6} xs={12}>
              <Select
                label={'Por existencia'}
                value={filters.typeId}
                info={'Filtra los productos por la existencia'}
                options={existenceOptions}
                onChange={value => setFilters({ ...filters, typeId: value })}
              />
            </Col>
            <Col xl={4} md={6} sm={6} xs={12}>
              <Switch
                mt={3}
                topLabel
                label={'Ver cantidad respecto al ítem base'}
                info={
                  'Se visualizaran las cantidades de los ítems en unidades del ítem base'
                }
                checked={seeBase}
                changeValue={value => {
                  localStorage.setItem('presentation-factor', value)
                  setSeeBase(value)
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col
              style={{ marginLeft: 3 }}
              className="mt-4"
              xl={12}
              md={12}
              sm={12}
              xs={12}>
              <Button
                className={'align-bottom'}
                onClick={() => {
                  setVariation({
                    filterByVariations: true,
                    show: true,
                    warehouseId: id,
                  })
                  dispatch(getCategorizationByType(20))
                }}>
                Por variaciones
              </Button>
            </Col>
            <Col className="mt-2 mb-4" xl={12} md={12} sm={12} xs={12}>
              <Row className={'pl-1'}>
                <Col xl={12} md={12} sm={12} xs={12}>
                  <Button
                    color={'primary'}
                    onClick={() => {
                      setShowCategorization({
                        ...showCategorization,
                        show: true,
                        filter: true,
                      })
                    }}>
                    Filtrar por categorías
                  </Button>
                </Col>
                {categorizationSelected.map(p => (
                  <div className={'user-tag product-t ml-2'} key={p.id}>
                    <label className={'label-user-tag'}>{p.name}</label>
                    <IconButton
                      className={'delete-user-tag d-product-t'}
                      icon={faTimes}
                      tooltip={'Quitar'}
                      color={'white'}
                      onClick={() => {
                        const list = categorizationSelected.filter(f => f.id !== p.id)
                        setCategorizationSelected(list)
                        setFilters({
                          ...filters,
                          categorization: getCategorizationString(list),
                        })
                      }}
                    />
                  </div>
                ))}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xl={4} md={12} sm={12} xs={12}>
          {seeWarehouseLocations.value === 'true' && (
            <Col className="mt-4" xl={12} md={12} sm={12} xs={12}>
              <CSVLoaderV2
                warehouseId={id}
                category={CATEGORY_UPLOAD.LOCATIONS}
                title={'Actualización Ubicaciones'}
                onSuccess={() => setUp()}
              />
            </Col>
          )}
          <Col className="mt-4" xl={12} md={12} sm={12} xs={12}>
            <CSVLoaderV2
              warehouseId={id}
              category={CATEGORY_UPLOAD.INVENTORY}
              title={'Cargar inventario'}
              onSuccess={() => setUp()}
              hideTemplate
            />
          </Col>
        </Col>
      </Row>
    </div>
  )

  const getActions = item => {
    return (
      <Dropdown
        items={[
          {
            title: 'Procedencia de inventario',
            action: () => {
              setReport({
                warehouseId: id,
                productId: item.id,
                productName: `${item.code} ${item.name}`,
                showItem: item.subItems && item.subItems.length > 1,
              })
            },
          },
          {
            title: 'Histórico de transacciones',
            action: () => {
              setHistory({
                warehouseId: id,
                productId: item.id,
                productName: `${item.code} ${item.name}`,
              })
            },
          },
          {
            show: item.reserved > 0,
            title: 'Ver procedencia de reservas',
            action: () =>
              setReport({
                warehouseId: id,
                productId: item.id,
                productName: `${item.code} ${item.name}`,
                showItem: item.subItems && item.subItems.length > 1,
                reserved: true,
              }),
          },
          {
            title: 'Descargar KARDEX',
            action: () => {
              setKardex({
                show: true,
                warehouseId: id,
                productId: item.id,
                name: item.name,
              })
            },
          },
        ]}
      />
    )
  }

  const getSubItems = (item, type, defaultValue, variations) => {
    return (
      <SubItemInventory
        item={item}
        type={type}
        defaultValue={defaultValue}
        action={
          (variations != null ||
            (seeWarehouseLocations.value === 'true' && variations === null)) && (
            <div className={'mt-1'}>
              <div className={'d-flex'}>
                <Commentary
                  value={
                    variations !== null && variations !== ''
                      ? seeWarehouseLocations.value === 'true'
                        ? 'Ver variaciones y ubicaciones'
                        : 'Ver variaciones'
                      : 'Ver ubicaciones'
                  }
                  icon={
                    <Icon
                      icon={faCubes}
                      tooltip={'Ver detalle'}
                      style={{ marginLeft: 5, marginTop: 5 }}
                      onClick={() => {
                        setVariation({
                          warehouseId: id,
                          productId: item.id,
                          name: defaultValue,
                          show: true,
                          baseItemData: item,
                        })
                      }}
                    />
                  }
                  onClick={() =>
                    setVariation({
                      warehouseId: id,
                      productId: item.id,
                      name: defaultValue,
                      show: true,
                      baseItemData: item,
                    })
                  }
                />
              </div>
            </div>
          )
        }
      />
    )
  }

  const headers = [
    {
      label: '',
      show: isInventory,
      select: false,
      checked: all && selectedInventory.length === 0,
      className: 'mini',
    },
    {
      label: 'SKU',
      show: true,
      value: ['code'],
      type: 'text',
      className: 'medium',
      style: { minWidth: 140 },
      custom: item => {
        return getSubItems(item, 1, item.code)
      },
    },

    {
      label: 'Nombre',
      show: true,
      value: ['name'],
      type: 'text',
      style: { minWidth: 280 },
      custom: item => {
        return getSubItems(item, 2, item.name, item.categories)
      },
    },
    {
      label: 'Medida',
      show: seeMeasureUnit?.value === 'true',
      type: 'text',
      className: 'mini center',
      custom: item =>
        item.measurementValue == null
          ? '---'
          : `${item.measurementValue} ${getMeasurementSymbol(item.measurementUnitId)}`,
    },
    {
      label: 'Disponible',
      show: true,
      value: ['available'],
      type: 'number',
      className: 'medium',
      style: { minWidth: 100 },
      custom: item => {
        return getSubItems(item, 4, formatNumberWithCommas(item.available))
      },
    },
    {
      label: 'Reservado',
      show: true,
      value: ['reserved'],
      type: 'wholeNumber',
      className: 'mini',
      style: { minWidth: 100 },
      custom: item => {
        return getSubItems(item, 5, formatNumberWithCommas(item.reserved))
      },
    },
    {
      label: 'Pendiente',
      show: true,
      value: ['pending'],
      type: 'wholeNumber',
      className: 'mini',
      style: { minWidth: 100 },
      custom: item => {
        return getSubItems(item, 8, formatNumberWithCommas(item.pending))
      },
      info: 'Es la sumatoria de los despachos pendientes del ítem que no son reservas por despacho posterior',
    },
    {
      label: 'Total',
      show: true,
      value: ['existence'],
      type: 'number',
      className: 'medium',
      style: { minWidth: 100 },
      custom: item => {
        return getSubItems(item, 6, formatNumberWithCommas(item.existence))
      },
    },
    {
      label: 'Costo',
      show: seeSum || seeMoneyBalances,
      value: ['cost'],
      type: 'currency',
      className: 'medium',
      style: { minWidth: 100 },
      custom: item => {
        return getSubItems(item, 7, toMoney(item.cost))
      },
    },
    {
      label: 'Precio',
      show: seeSum || seeMoneyBalances,
      value: ['price'],
      type: 'currency',
      className: 'medium',
      style: { minWidth: 125 },
      custom: item => {
        return getSubItems(item, 3, toMoney(item.price))
      },
    },
    { show: true, label: '', config: true, custom: item => getActions(item) },
  ]

  const getItemValue = (label, value) => (
    <div
      className={'d-flex'}
      style={{
        justifyContent: 'space-between',
        paddingLeft: 10,
        paddingRight: 20,
      }}>
      <div className={'b-user-email'} style={{ minWidth: 100 }}>
        <b>{label}</b>
      </div>
      <div className={'b-user-email text-right'}>{value}</div>
    </div>
  )

  const rowOrderClicked = data => {
    setSelectedInventory(
      selectedInventory.some(inventory => inventory.id === data.id)
        ? selectedInventory.filter(inventory => inventory.id !== data.id)
        : [...selectedInventory, data],
    )
  }

  const tableElement = (
    <div>
      <TableV2
        loading={loadingInventory}
        customClass={'scrollX'}
        headers={headers}
        onCheck={
          isInventory
            ? value => {
                setAll(value)
                setSelectedInventory([])
              }
            : undefined
        }
        getItems={
          isInventory
            ? item => {
                if (item === null) setSelectedInventory([])
                else rowOrderClicked(item)
              }
            : undefined
        }
        onClickTr={isInventory ? (e, item) => rowOrderClicked(item) : undefined}
        isSelected={item => isSelected(item)}
        total={total}
        items={items}
        placeholder={'Buscar por nombre/código'}
        noItemsLegend={`No hay ítems con los filtros aplicados`}
        getPagination={(skip, size, search) =>
          setFilters({ ...filters, skip, size, search })
        }
        handleChange={search =>
          setTimeout(() => {
            setFilters({ ...filters, search })
          }, 1000)
        }
        customFilter={customFilterElement}
        mobileAuto
        storageKey={'warehouseinventory'}
        // principalAction={item =>
        //   item?.item.categories && renderVariations(item.item.id, item.item.name)
        // }
        mobileSlide={({ item }) => (
          <div className={'column'}>
            {getItemValue('SKU', item.code)}
            {getItemValue('Nombre', item.name)}
            {getItemValue(
              'Medida',
              `${item.measurementValue} ${getMeasurementSymbol(item.measurementUnitId)}`,
            )}
            {getItemValue('Disponible', formatNumberWithCommas(item.available))}
            {getItemValue('Reservado', formatNumberWithCommas(item.reserved))}
            {getItemValue('Total', formatNumberWithCommas(item.existence))}
            {(seeSum || seeMoneyBalances) && getItemValue('Costo', toMoney(item.cost))}

            {item.subItems && item.subItems.length > 0 && (
              <div>
                <div className={'b-user-name mb-1 mt-2 ml-2'}>Sub ítems</div>
                {item.subItems.map(subItem => (
                  <div key={subItem.id} className={'column'}>
                    {getItemValue(
                      subItem.code,
                      formatNumberWithCommas(subItem.available),
                    )}
                    {getItemValue(subItem.name, formatNumberWithCommas(subItem.reserved))}
                    {getItemValue('total', formatNumberWithCommas(subItem.existence))}
                    {getItemValue('costo', toMoney(subItem.cost))}
                    <div>
                      <hr className={'m-0'} />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      />
    </div>
  )

  const getCategorizationString = selected => {
    let categorization = null
    const list = selected ? selected : categorizationSelected
    list.forEach(s => {
      if (!categorization) categorization = ''
      categorization += s.id + ','
    })
    return categorization ? categorization.substring(0, categorization.length - 1) : null
  }

  return (
    <>
      {modal ? (
        <Modal
          show={show && !report.productId && !history.productId && !variation.show}
          onHide={() => onHide()}
          centered
          size={'xl'}>
          <Modal.Header closeButton>
            <Modal.Title>
              {singleWarehouse ? singleWarehouse.name : name ? name : 'Bodega'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {(seeSum || seeMoneyBalances) && (
              <Row>
                <Col>
                  <Money component="span">
                    <strong>Valor de compra mercadería </strong>
                    {value}
                  </Money>
                </Col>
              </Row>
            )}
            <Row>
              <Col>{tableElement}</Col>
            </Row>
          </Modal.Body>
        </Modal>
      ) : (
        tableElement
      )}

      <WarehouseVariatons
        id={variation.warehouseId}
        singleWarehouse={singleWarehouse}
        productId={variation.productId}
        modal
        show={variation.show}
        name={variation.name}
        onHide={() => {
          setVariation({
            productId: 0,
            name: '',
            show: false,
            baseItemData: {},
            filterByVariations: false,
          })
        }}
        action={seeWarehouseLocations.value === 'true' && !variation.filterByVariations}
        baseItemData={variation.baseItemData}
        dontGetCategorization={variation.filterByVariations}
        filterByVariations={variation.filterByVariations}
      />

      <InventoryReport
        warehouse={report.warehouseId}
        product={report.productId}
        productName={report.productName}
        showItem={report.showItem}
        reserved={report.reserved}
        close={() =>
          setReport({
            ...report,
            showItem: false,
            warehouseId: null,
            productId: 0,
            productName: null,
          })
        }
      />

      <InventoryHistory
        warehouse={history.warehouseId}
        product={history.productId}
        productName={history.productName}
        close={() => setHistory({ warehouseId: null, productId: 0, productName: null })}
      />

      <Modal show={kardex.show} centered size={'md'} onHide={() => setKardex({})}>
        <Modal.Header closeButton>
          <Modal.Title>Descarga de Kardex: {kardex.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SelectedDates
            dates={{ dateFrom: kardex.start, dateTo: kardex.end }}
            onDateChange={(start, end) => setKardex({ ...kardex, start, end })}
            customSize={{ xl: 12 }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              loading={loadingKardex}
              disabled={!kardex.start || !kardex.end}
              icon={faFileExcel}
              onClick={() => {
                dispatch(
                  exportWarehouseKardex(
                    id,
                    kardex.start,
                    kardex.end,
                    kardex.name,
                    kardex.productId,
                  ),
                )
              }}>
              Descargar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <FAB
        show={id && isInventory}
        icon={faEllipsisV}
        tooltip={''}
        loading={loadingDispatch}
        items={[
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: 'Despachar inventario pendiente',
            icon: faBoxOpen,
            action: () =>
              dispatch(
                dispatchPendingInventory(
                  id,
                  selectedInventory.map(inventory => inventory.id),
                ),
              ),
            show:
              canDispatchPendingInventory &&
              ((all && items.every(item => item.existence > 0 && item.pending > 0)) ||
                (selectedInventory.length > 0 &&
                  selectedInventory.every(
                    item => item.existence > 0 && item.pending > 0,
                  ))),
          },
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: 'Exportar CSV ubicaciones',
            icon: faFileExcel,
            action: () => {
              dispatch(
                getCSVLocations(
                  id,
                  selectedInventory.map(inventory => inventory.id),
                  all,
                ),
              )
            },
            show:
              seeWarehouseLocations.value === 'true' &&
              (all || selectedInventory.length > 0),
          },
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: 'CSV para subida de inventario',
            icon: faFileExcel,
            action: () => {
              dispatch(getCSVInventoryProducts())
            },
            show: true,
          },
        ]}
      />

      <Folder
        noMessage
        onHide={() => setShowCategorization(false)}
        onAssign={item => {
          if (categorizationSelected.findIndex(d => d.id === item.id) === -1) {
            categorizationSelected.push(item)
            setFilters({ ...filters, categorization: getCategorizationString() })
          } else {
            const list = categorizationSelected.filter(d => d.id !== item.id)
            setCategorizationSelected(list)
            setFilters({ ...filters, categorization: getCategorizationString() })
          }
        }}
        list={categorizationSelected.map(p => p.id)}
        data1={
          categorization && categorization.children ? categorization.children[0] : {}
        }
        data2={
          categorization && categorization.children ? categorization.children[1] : {}
        }
        show={showCategorization}
        type={categoryType.PRODUCTS}
      />
    </>
  )
}

export default WarehouseInventory
