import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Modal, Row, Col, ProgressBar } from 'react-bootstrap'
import {
  Card,
  Button,
  FormText,
  FormTextField,
  CustomFilter,
  Select,
} from 'src/components'
import {
  faExclamationCircle,
  faPlus,
  faSave,
  faTimesCircle,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons'
import Alert from 'sweetalert-react'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  hasErrorsSelector,
  singleErrorSelector,
  handlerSuccess,
  handlerError,
} from 'src/selectors/error.selector'

import { selectorGroup } from 'src/selectors/modules.selector'
import {
  actionTypes,
  createGroup,
  deleteGroup,
  getCurrentUserRolesAction,
  getGroupRole,
  getRolesByModule,
  getRolesByUserChild,
  updateGroup,
} from 'src/actions/modules.actions'
import { Media } from 'react-breakpoints'
import {
  selectCurrentCompany,
  selectCurrentModule,
  selectCurrentUser,
} from 'src/selectors/user.selector'
import Switch from 'src/components/inputs/Switch/Switch'
import IconButton from 'src/components/buttons/IconButton'
import { setChangeLink } from 'src/actions/offers.actions'

const PermissionGroup = ({
  groupId,
  roles,
  onHide,
  show,
  edit,
  canDelete,
  select,
  onAssign,
}) => {
  const dispatch = useDispatch()

  const company = useSelector(selectCurrentCompany)
  const user = useSelector(selectCurrentUser)

  const group = useSelector(selectorGroup)
  const loadingG = useSelector(state => loadingSelector([actionTypes.GET_GROUP])(state))

  const loadingV = useSelector(state =>
    loadingSelector([actionTypes.CREATE_GROUP, actionTypes.UPDATE_GROUP])(state),
  )
  const hasErrorV = useSelector(state =>
    hasErrorsSelector([actionTypes.CREATE_GROUP, actionTypes.UPDATE_GROUP])(state),
  )
  const errorV = useSelector(state =>
    singleErrorSelector([actionTypes.CREATE_GROUP, actionTypes.UPDATE_GROUP])(state),
  )

  const loadingD = useSelector(state =>
    loadingSelector([actionTypes.DELETE_GROUP])(state),
  )
  const hasErrorD = useSelector(state =>
    hasErrorsSelector([actionTypes.DELETE_GROUP])(state),
  )
  const errorD = useSelector(state =>
    singleErrorSelector([actionTypes.DELETE_GROUP])(state),
  )

  const [actions, setActions] = useState({
    get: false,
    set: false,
    delete: false,
  })
  const [search, setSearch] = useState(null)
  const [selected, setSelected] = useState([])
  const [alert, setAlert] = useState({ title: '' })
  const [item, setItem] = useState({
    name: null,
    description: null,
    public: false,
    edit: true,
    roles: [],
  })
  const [error, setError] = useState({})
  const [mobile, setModalMobile] = useState(false)
  const [module, setModule] = useState({
    value: 0,
    label: 'Seleccione un módulo',
  })

  useEffect(() => {
    if (loadingD) setActions({ ...actions, delete: true })
    else if (actions.delete) {
      setActions({ ...actions, delete: false })
      if (hasErrorD)
        setAlert({
          ...handlerError(errorD.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Grupo de permisos eliminado satisfactoriamente'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            onHide()
          },
        })
    }
  }, [loadingD])

  useEffect(() => {
    if (!show) {
      setItem({
        name: null,
        description: null,
        roles: [],
        edit: true,
        public: false,
      })
      setSelected([])
    }
  }, [show])

  useEffect(() => {
    if (!groupId) return
    dispatch(getGroupRole(groupId))
  }, [groupId])

  useEffect(() => {
    if (group && group.id) {
      setItem({
        name: group.name,
        description: group.description,
        public: group.public,
        edit: company === group.companyId,
        roles: null,
      })
      setSelected([...group.roles])
    }
  }, [group])

  useEffect(() => {
    if (loadingV) setActions({ ...actions, set: true })
    else if (actions.set) {
      setActions({ ...actions, set: false })
      if (hasErrorV)
        setAlert({
          ...handlerError(errorV.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Grupo de permisos guardado satisfactoriamente'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            onHide()
          },
        })
    }
  }, [loadingV])

  useEffect(() => {
    if (module.value !== 0) {
      dispatch(getRolesByUserChild(module.value, user.id, true))
    }
  }, [module])

  const filter = item => {
    if (search && search !== '') {
      const exp = new RegExp(search.toUpperCase(), 'g')
      return Object.keys(item).some(p =>
        `${JSON.stringify(item[p])}`.toUpperCase().match(exp),
      )
    }
    return true
  }

  const filterRole = item => {
    const index = selected.findIndex(p => p.id === item.id)
    return index === -1
  }

  const onDropList = event => {
    event.preventDefault()
    const data = event.dataTransfer.getData('text')
    const id = data.split('-')[1]

    const index = selected.findIndex(d => d.id === Number(id))
    if (index > -1) {
      setSelected(selected.filter(p => p.id !== Number(id)))
    }
  }

  const onDropTo = event => {
    event.preventDefault()
    const data = event.dataTransfer.getData('text')
    const id = data.split('-')[1]

    const index = selected.findIndex(d => d.id === Number(id))
    if (index === -1) {
      let item = {}
      roles.map(r => {
        let value = r.roles.find(p => p.id === Number(id))
        if (value) {
          item = value
        }
      })
      setSelected([...selected, item])
    }
  }

  const onDrag = event => {
    event.dataTransfer.setData('text', event.target.id)
  }

  const onChange = event => {
    const { name, value } = event.target
    setItem({ ...item, [name]: value })
  }

  const onCheck = event => {
    const { name, checked } = event.target
    setItem({ ...item, [name]: checked })
  }

  const itemsToSelect = isPc => {
    return (
      <div>
        {select && (
          <Row>
            <Select
              label={'Módulo'}
              options={[
                { value: 1000, label: 'Transportista' },
                { value: 2000, label: 'Productor' },
                { value: 8000, label: 'Configuraciones' },
              ]}
              value={module}
              onChange={value => {
                setModule(value)
              }}
            />
          </Row>
        )}
        <CustomFilter
          search={search}
          onChange={({ target }) => setSearch(target.value)}
          customFilter={<div />}
        />
        <div>
          <ul className={'main-list'}>
            {roles &&
              roles
                .filter(r => r.id > 0 && filter(r))
                .map(role => (
                  <li>
                    <div className={'column'}>
                      <Card
                        bodyStyle={{ margin: 0 }}
                        style={{ marginBottom: 0 }}
                        className={'pi-sticky left'}>
                        {role.name}
                      </Card>
                      <ul
                        onDrop={onDropList}
                        onDragOver={event => event.preventDefault()}
                        style={{ padding: 0, margin: 0 }}
                        id={'main'}>
                        {role &&
                          role.roles
                            .filter(r => filterRole(r) && filter(r))
                            .map((p, index) => (
                              <li
                                key={index}
                                style={{ padding: 0, 'list-style': 'none' }}
                                id={`p-${p.id}`}
                                draggable={isPc}
                                onDragStart={onDrag}>
                                <div
                                  className="b-user-item"
                                  onClick={() => {
                                    if (!isPc) {
                                      selected.push(p)
                                      setSelected([...selected])
                                      if (onAssign) onAssign(p)
                                    }
                                  }}>
                                  <div
                                    className="justify-center-start align-items-start bu-en"
                                    style={{ textAlign: 'left' }}>
                                    <div className="b-user-name">{p.name}</div>
                                    <div className="b-user-email">{p.description}</div>
                                  </div>
                                </div>
                              </li>
                            ))}
                      </ul>
                    </div>
                  </li>
                ))}
          </ul>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Modal show={show && !select} size={'lg'} centered onHide={() => onHide()}>
        <Modal.Header closeButton>
          <Modal.Title>Grupo de permisos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={6} lg={6} md={12} sm={12}>
              <FormTextField
                label={'Nombre'}
                type={'text'}
                name={'name'}
                value={item.name}
                onChange={onChange}
                error={error.name}
                required
                disabled={!(edit && item.edit)}
              />
            </Col>
            <Col xl={6} lg={6} md={12} sm={12}>
              <FormTextField
                label={'Descripcion'}
                type={'text'}
                name={'description'}
                value={item.description}
                onChange={onChange}
                error={error.description}
                required
                disabled={!(edit && item.edit)}
              />
            </Col>
            {company === 1 && (
              <Col>
                <Switch
                  style={{ marginTop: 5 }}
                  checked={item.public}
                  onChange={onCheck}
                  name={'public'}
                  id={'public'}
                  label={'Marcar como un grupo de permisos público'}
                  info={
                    'Los grupos de permisos públicos pueden ser usados por todas las empresas'
                  }
                />
              </Col>
            )}
          </Row>
          <hr />
          <Row>
            <Col xl={12} lg={12} md={12} sm={12}>
              <Media>
                {({ breakpoints, currentBreakpoint }) => {
                  const tablet = breakpoints[currentBreakpoint] > breakpoints.tablet
                  return breakpoints[currentBreakpoint] >= breakpoints.mini ? (
                    <Row>
                      {tablet && edit && item.edit && (
                        <Col xl={5} lg={5} md={5}>
                          {itemsToSelect(true)}
                        </Col>
                      )}
                      <Col xl={tablet ? 7 : 12} lg={tablet ? 7 : 12} md={tablet ? 7 : 12}>
                        <Card title={'Se compone de'} white>
                          <div className={'column'}>
                            {tablet && edit && item.edit && (
                              <div>
                                <b>Arrastre los roles a asignar</b>
                              </div>
                            )}
                            {loadingG && (
                              <div className="pb-custom">
                                <ProgressBar
                                  label={'Cargando'}
                                  animated
                                  now={100}
                                  style={{ marginBottom: 10 }}
                                />
                              </div>
                            )}
                            <ul
                              className={'main-list'}
                              onDrop={onDropTo}
                              onDragOver={event => event.preventDefault()}>
                              {selected &&
                                selected.map((p, index) => (
                                  <li
                                    key={index}
                                    style={{ padding: 0, 'list-style': 'none' }}
                                    id={`p-${p.id}`}
                                    draggable
                                    onDragStart={onDrag}>
                                    <div className="b-user-item">
                                      <div
                                        className="justify-center-start align-items-start bu-en"
                                        style={{ textAlign: 'left' }}>
                                        <div className="b-user-name">{p.name}</div>
                                        <div className="b-user-email">
                                          {p.description}
                                        </div>
                                      </div>
                                      <div>
                                        {!tablet && edit && item.edit && (
                                          <IconButton
                                            color={'red'}
                                            icon={faTimesCircle}
                                            onClick={() =>
                                              setSelected(
                                                selected.filter(
                                                  f => f.id !== Number(p.id),
                                                ),
                                              )
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </Card>
                      </Col>

                      {!tablet && edit && item.edit && (
                        <Col xl={12} lg={12} md={12}>
                          <Row
                            className={'container-buttons'}
                            style={{ paddingBottom: 2, display: 'flex' }}>
                            <Button
                              style={{ flex: 1 }}
                              icon={faPlus}
                              right
                              color={'primary'}
                              onClick={() => setModalMobile(true)}>
                              Agregar Rol
                            </Button>
                          </Row>
                        </Col>
                      )}
                    </Row>
                  ) : (
                    <div />
                  )
                }}
              </Media>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className="container-buttons">
            {canDelete && (
              <Button
                disabled={loadingV}
                color={'secondary'}
                icon={faWindowClose}
                onClick={() => (groupId ? dispatch(deleteGroup(groupId)) : onHide())}>
                {groupId ? 'Eliminar' : 'Cancelar'}
              </Button>
            )}
            {edit && item.edit && (
              <Button
                loading={loadingV}
                disabled={selected.length === 0}
                color={'primary'}
                icon={faSave}
                onClick={() => {
                  let error = {}
                  if (!item.name) error.name = 'Este campo es requerido'
                  if (!item.description) error.description = 'Este campo es requerido'
                  setError(error)
                  if (Object.keys(error).length === 0) {
                    let request = { ...item }
                    request.roles = selected.map(p => p.id)
                    if (groupId) dispatch(updateGroup(group.id, request))
                    else dispatch(createGroup(request))
                  }
                }}>
                Guardar
              </Button>
            )}
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={mobile || (select && show)}
        centered
        size={'mg'}
        onHide={() => {
          setModalMobile(false)
          if (select) onHide()
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Roles para la selección</Modal.Title>
        </Modal.Header>
        <Modal.Body>{itemsToSelect(false)}</Modal.Body>
      </Modal>

      <Alert {...alert} />
    </div>
  )
}
export default PermissionGroup
