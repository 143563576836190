import { $http } from './http'

class EnumerationsServices {
  onCreateEnumeration = async request => {
    const response = await $http.post('/enumeration', request)
    return response.data
  }

  onGetEnumerations = async isPublic => {
    const response = await $http.get(`/enumeration?public=${isPublic}`)
    return response.data
  }

  onGetEnumeration = async id => {
    const response = await $http.get(`/enumeration/${id}`)
    return response.data
  }

  onGetProductEnumeration = async id => {
    const response = await $http.get(`/enumeration/product/${id}`)
    return response.data
  }

  getEnumerationReport = async id => {
    const response = await $http.get(`/enumeration/report/product/${id}`)
    return response.data
  }

  updateEnumeration = async (id, request) => {
    const response = await $http.put(`/enumeration/${id}`, request)
    return response.data
  }

  deleteEnumeration = async id => {
    const response = await $http.delete(`/enumeration/${id}`)
    return response.data
  }

  loadRecipesCSV = async (file, global) => {
    let formData = new FormData()
    formData.append('file', file)
    const response = await $http.post(
      `/enumeration/csv?global=${global ? `true` : 'false'}`,
      formData,
      { headers: { 'content-type': 'multipart/form-data' } },
    )
    return response.data
  }

  getVariablesRecipe = async () => {
    const response = await $http.get('/enumeration/variable')
    return response.data
  }

  getVariableRecipe = async id => {
    const response = await $http.get(`/enumeration/variable/${id}`)
    return response.data
  }

  createVariableRecipe = async request => {
    const response = await $http.post('/enumeration/variable', request)
    return response.data
  }

  updateVariableRecipe = async (id, request, params) => {
    const response = await $http.put(`/enumeration/variable/${id}`, request, { params })
    return response.data
  }
}

export default new EnumerationsServices()
