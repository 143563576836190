import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import { TableV2, Icon, SelectedDates } from '../../components'
import { faEye } from '@fortawesome/free-solid-svg-icons'

import { actionTypes, getHistory } from 'src/actions/producer.actions'
import { selectHistoryProvider } from 'src/selectors/producer.selector'

import { loadingSelector } from 'src/selectors/loading.selector'

import { onSetModalPurchase } from 'src/actions/utilities.actions'

const ProvidersAdvancesHistory = ({
  providerId,
  providerName,
  show,
  onHide,
  balancePermission,
}) => {
  const dispatch = useDispatch()

  const loadingHistory = useSelector(state =>
    loadingSelector([actionTypes.GET_HISTORY])(state),
  )
  const history = useSelector(selectHistoryProvider)

  const [open, setOpen] = useState(false)
  const [filters, setFilters] = useState({
    start: null,
    end: null,
    skip: 0,
    size: 10,
    search: null,
  })
  const [filtersText, setFiltersText] = useState('')

  useEffect(() => {
    if (!show) return
    setOpen(true)
    setFiltersText(JSON.stringify(filters))
  }, [show])

  useEffect(() => {
    if (filtersText === '' || filtersText === JSON.stringify(filters)) return
    setFiltersText(JSON.stringify(filters))
  }, [filters])

  useEffect(() => {
    if (filtersText !== JSON.stringify(filters)) return
    setup()
  }, [filtersText])

  const setup = () => {
    if (!open) return
    dispatch(getHistory(providerId, filters.start, filters.end))
  }

  const onClose = () => {
    setOpen(false)
    setFiltersText('')
    setFilters({ start: null, end: null, skip: 0, size: 10, search: null })
    onHide()
  }

  const headers = [
    { label: 'Fecha de Registro', show: true, type: 'date', value: ['createdAt'] },
    { label: 'Descripción', show: true, type: 'text', value: ['documentDescription'] },
    { label: 'Creado', show: true, type: 'text', value: ['createdData', 'name'] },
    { label: 'Previo', show: true, type: 'currency', value: ['previousBalance'] },
    { label: 'Monto', show: true, type: 'currency', value: ['amount'] },
    { label: 'Nuevo', show: true, type: 'currency', value: ['newBalance'] },
    {
      label: '',
      show: true,
      type: '',
      custom: item => (
        <Icon
          icon={faEye}
          tooltip={'Ver detalle'}
          onClick={() => dispatch(onSetModalPurchase(item.documentId, setup))}
        />
      ),
    },
  ]

  return (
    <div>
      <Modal show={open} centered size={'xl'} onHide={() => onClose()}>
        <Modal.Header closeButton>
          <Modal.Title>{providerName}: Historial de adelantos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <SelectedDates
                withOptionNull
                onDateChange={(start, end) => setFilters({ ...filters, start, end })}
              />
            </Col>
            <Col xl={12}>
              <TableV2
                headers={headers}
                items={history}
                loading={loadingHistory}
                mobileAuto
                storageKey={'advancesHistory'}
                balancePermission={balancePermission}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ProvidersAdvancesHistory
