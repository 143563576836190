import './ManufacturePOS.scss'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Alert from 'sweetalert-react'
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import { Col, ProgressBar, Row } from 'react-bootstrap'
import {
  Button,
  Empty,
  Icon,
  SelectedDates,
  Card,
  Select,
  ListCategories,
} from 'src/components'
import CommandTicket from 'src/components/Print/commandTicket'
import {
  faCheckCircle,
  faCheckDouble,
  faPrint,
  faSearch,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons'

import { WSS_URL } from 'src/settings/app.settings'

import {
  acceptTransfer,
  actionTypes as typeW,
  onValidateTransfer,
  rejectTransfer,
} from 'src/actions/warehouse.actions'

import { selectAllTransfers } from 'src/selectors/warehouse.selector'

import { selectCurrentCompany, selectCurrentUser } from 'src/selectors/user.selector'
import { loadingSelector } from 'src/selectors/loading.selector'

import { handlerError, hasErrors } from 'src/selectors/error.selector'
import { formatDateFromMillis, formatHourFromMillis } from 'src/utils/formatters'
import { colorArray } from '../../Reports/options'
import { selectSetCurrentCompany } from 'src/selectors/company.selector'

import { printCommandTicket } from 'src/components/Printer/TicketEpson'
import {
  getAllPOSByUser,
  getPosTransfersTransformations,
  onSetPrintData,
} from 'src/actions/restaurant.actions'
import { selectAllPOSUser, selectGetTableOrder } from 'src/selectors/restaurant.selector'
import { setGeneralData } from 'src/actions/printer.actions'
import { haveAnyValue } from 'src/utils/utilitiesV2'
import { isAllowed } from 'src/selectors/modules.selector'
import { manufacturePermissions } from 'src/enums/permissions'

let client

const getStringIds = (values, id, remove) => {
  const valuesArray = values.split(',').filter(v => v !== '')
  if (remove) {
    const index = valuesArray.indexOf(id.valueOf().toString())
    if (index !== -1) valuesArray.splice(index, 1)
  } else valuesArray.push(id)
  return valuesArray.join(',')
}

const getSelect = (values, array) => {
  const valuesArray = values.split(',').filter(id => id !== '')
  if (valuesArray.length === 0) return []

  return valuesArray.map(id => {
    return array.find(s => s.value === Number(id))
  })
}

const statusArray = [
  { value: 1, label: 'Nueva' },
  { value: 3, label: 'Terminada' },
  { value: 4, label: 'Cancelada' },
  { value: 8, label: 'Por validar' },
]

const defaultUser = { value: null, label: '-- Todos --' }

const today = new Date()

const ManufacturePOS = ({
  tableOrderId,
  onRefresh,
  tableId,
  posType,
  posName,
  description,
  footerPhrase,
  logo,
  canApprovePermission = false,
  canRejectPermission = false,
}) => {
  const dispatch = useDispatch()

  const companyId = useSelector(selectCurrentCompany)
  const company = useSelector(selectSetCurrentCompany)
  const user = useSelector(selectCurrentUser)
  const posUser = useSelector(selectAllPOSUser)
  const tableOrder = useSelector(selectGetTableOrder)

  const pos = posUser.find(pos => pos.id === tableOrder.posId)

  const prints = useSelector(state => state.printers.all)

  const loadingT = useSelector(state => loadingSelector([typeW.GET_TRANSFERS])(state))
  const items = useSelector(selectAllTransfers)

  const loadingR = useSelector(state => loadingSelector([typeW.REJECT_TRANSFER])(state))
  const hasErrorR = useSelector(state => hasErrors([typeW.REJECT_TRANSFER])(state))

  const loadingA = useSelector(state => loadingSelector([typeW.ACCEPT_TRANSFER])(state))
  const hasErrorA = useSelector(state => hasErrors([typeW.ACCEPT_TRANSFER])(state))

  const loadingV = useSelector(state =>
    loadingSelector([typeW.ON_VALIDATE_MANUFACTURE])(state),
  )
  const hasErrorV = useSelector(state =>
    hasErrors([typeW.ON_VALIDATE_MANUFACTURE])(state),
  )

  const disabledActions = loadingR || loadingA || loadingV

  /* PERMISSIONS */
  const canApprove =
    canApprovePermission ||
    useSelector(state => isAllowed(state, [manufacturePermissions.approve]))
  const canReject =
    canRejectPermission ||
    useSelector(state => isAllowed(state, [manufacturePermissions.reject]))
  const canValidate = useSelector(state =>
    isAllowed(state, [manufacturePermissions.validate]),
  )

  const canApproveOrReject = canApprove || canReject

  const [flags, setFlags] = useState({
    get: false,
    update: false,
    approve: false,
    reject: false,
  })
  const [action, setAction] = useState({})

  const [alert, setAlert] = useState({ title: '' })
  const [refreshCount, setRefreshCount] = useState(0)
  const [current, setCurrent] = useState(0)
  const [filters, setFilters] = useState({
    skip: null,
    size: null,
    user: defaultUser,
    start: today.setHours(0, 0, 0, 0),
    end: today.setHours(23, 59, 59, 999),
    stateIds: haveAnyValue(tableOrderId) ? '1,8,3' : '1,8',
    posIds: '',
  })
  const [search] = useState(null)

  useEffect(() => {
    if (tableOrderId) return
    if (posUser.length === 0) dispatch(getAllPOSByUser(user.id))
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setRefreshCount(new Date().valueOf())
    }, 1000)
  }, [refreshCount])

  useEffect(() => {
    setTimeout(
      () => {
        webSocket()
        setCurrent(new Date().valueOf())
      },
      current === 0 ? 10 : 35000,
    )
  }, [current])

  useEffect(() => {
    if (loadingT) setFlags({ ...flags, get: true })
    else if (flags.get) {
      setFlags({ ...flags, get: false, update: false })
    }
  }, [loadingT])

  const webSocket = () => {
    setFlags({ ...flags, update: true })
    /*if (client && client.close) {
      client.close()
    }
    client = new W3CWebSocket(`${WSS_URL}/report/${companyId + 100002}`)
    client.onopen = () => undefined
    client.onmessage = () => {}
    client.onclose = () => undefined*/
  }

  useEffect(() => {
    if (!flags.update) return
    setUp()
  }, [flags.update])

  useEffect(() => {
    if (loadingA) setFlags({ ...flags, approve: true })
    else if (flags.approve) {
      setFlags({ ...flags, approve: false })
      if (hasErrorA)
        setAlert({
          ...handlerError(hasErrorA.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else {
        if (onRefresh) onRefresh()
        webSocket()
      }
    }
  }, [loadingA])

  useEffect(() => {
    if (loadingR) setFlags({ ...flags, reject: true })
    else if (flags.reject) {
      setFlags({ ...flags, reject: false })
      if (hasErrorR)
        setAlert({
          ...handlerError(hasErrorR.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else {
        if (onRefresh) onRefresh()
        webSocket()
      }
    }
  }, [loadingR])

  useEffect(() => {
    if (loadingV) setFlags({ ...flags, validate: true })
    else if (flags.validate) {
      setFlags({ ...flags, validate: false })
      if (hasErrorV)
        setAlert({
          ...handlerError(hasErrorV.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else {
        if (onRefresh) onRefresh()
        webSocket()
      }
    }
  }, [loadingV])

  const setUp = () => {
    setFlags({ ...flags, update: false })
    const params = {
      startDate: tableOrderId !== undefined ? null : filters.start,
      endDate: tableOrderId !== undefined ? null : filters.end,
      skip: filters.skip,
      size: filters.size,
      stateIds: filters.stateIds,
      onlyTransformations: true,
      documentType: 16,
      documentId: tableOrderId,
      withDetail: true,
      getTotal: true,
      tableOrderId,
    }

    dispatch(getPosTransfersTransformations(params))
  }

  const filterSearch = item => {
    let searched
    if (haveAnyValue(search)) {
      const obj = {
        number: item.number,
        reference: item.reference,
        details: item.details
          ? item.details.map(id => ({
              name: id.productName,
              code: id.productCode,
            }))
          : [],
      }
      const exp = new RegExp(search.toUpperCase(), 'g')
      searched = Object.keys(obj).some(p =>
        `${JSON.stringify(obj[p])}`.toUpperCase().match(exp),
      )
    } else searched = true

    let user
    if (haveAnyValue(filters?.user?.value)) {
      user = item.solicitedBy === filters.user.value
    } else user = true

    let pos
    if (haveAnyValue(filters?.posIds)) {
      pos = filters.posIds.includes(item.posId.toString())
    } else if (!tableOrderId) {
      pos = posUser.map(p => p.id).some(id => id === item.posId)
    } else pos = true

    return searched && user && pos
  }

  const getItems = () => {
    return items.filter(filterSearch).sort((a, b) => a.id - b.id)
  }

  const getUsers = () => {
    const uniqueSolicitedBy = new Set(items.map(i => i.solicitedBy))
    return Array.from(uniqueSolicitedBy).map(value => ({ value, label: value }))
  }

  const getItemsByTicket = items => {
    const response = []
    if (items) {
      response.push(
        ...items.map(i => {
          const item = {
            name: i.productName,
            quantity: i.quantity,
            supplies: [],
            groupValue: i.groupValue,
            commentary: i.commentary,
            tag: i.tag,
          }

          if (i.description !== undefined && i.description !== '') {
            i.description
              ?.split(',')
              .filter(descSplit => descSplit && descSplit.trim().length !== 0)
              .forEach(descSplit => {
                const subItem = descSplit.trim()
                const values = subItem.split(' ')
                const subQuantity = Number(values[0])
                const subName = subItem.slice(values[0].length, subItem.length)
                item.supplies.push({ quantity: subQuantity, name: subName })
              })
          }

          return item
        }),
      )
    }
    return response
  }

  const customFilter = (
    <Row>
      <Col xl={4} lg={4} md={4} sm={12} xs={12}>
        <Select
          label={'Estado'}
          options={statusArray.filter(
            s => !filters.stateIds.includes(s.value.toString()),
          )}
          value={{ value: null, label: 'Seleccione un estado' }}
          onChange={({ value }) =>
            setFilters({ ...filters, stateIds: getStringIds(filters.stateIds, value) })
          }
        />

        <ListCategories
          items={getSelect(filters.stateIds, statusArray)}
          onRemove={({ value }) =>
            setFilters({
              ...filters,
              stateIds: getStringIds(filters.stateIds, value, true),
            })
          }
        />
      </Col>
      <Col xl={4} lg={4} md={4} sm={12} xs={12}>
        <Select
          label={'Puntos de ventas'}
          options={posUser.filter(p => !filters.posIds.includes(p.value.toString()))}
          value={{ value: null, label: 'Seleccione un punto de venta' }}
          onChange={({ value }) =>
            setFilters({ ...filters, posIds: getStringIds(filters.posIds, value) })
          }
        />

        <ListCategories
          items={getSelect(filters.posIds, posUser)}
          onRemove={({ value }) =>
            setFilters({
              ...filters,
              posIds: getStringIds(filters.posIds, value, true),
            })
          }
        />
      </Col>

      <Col xl={4} lg={4} md={4} sm={12} xs={12}>
        <Select
          label={'Creado por'}
          options={[
            defaultUser,
            ...getUsers().filter(p => filters?.user?.value !== p.id),
          ]}
          value={filters.user}
          onChange={value => setFilters({ ...filters, user: value })}
        />
      </Col>

      <Col xl={12}>
        <Row className={'container-buttons'}>
          <Button onClick={() => setUp()} icon={faSearch}>
            Filtrar
          </Button>
        </Row>
      </Col>
    </Row>
  )

  return (
    <div className="pos-manufacture">
      {!tableOrderId && (
        <Card title={'Comandas'} white>
          <SelectedDates
            dates={{ dateFrom: filters.start, dateTo: filters.end }}
            setValueDates
            withOptionNull
            onDateChange={(start, end) => setFilters({ ...filters, start, end })}
            nonExecute
            customFilter={customFilter}
            filter
          />
        </Card>
      )}

      {/* <Title title={'Comandas'} /> */}
      {loadingT && (
        <div className={'pb-custom'}>
          <ProgressBar label="Cargando" animated now={100} style={{ marginBottom: 10 }} />
        </div>
      )}

      {getItems().length <= 0 ? (
        <Empty
          loading={loadingT}
          onClick={() => {
            webSocket()
          }}
        />
      ) : (
        <Row className="pos-manufacture-items">
          {getItems().map((item, index) => {
            const itemsByTicket = getItemsByTicket(item.details)

            const loadingItem = disabledActions && action.id === item.id

            return (
              <Col key={index} xl={3} lg={4} md={4} sm={6} xs={12}>
                <div className={'manufacture-container'}>
                  <div
                    className={'manufacture-head'}
                    style={{ backgroundColor: colorArray[index] }}>
                    <div className={'column'}>
                      <div className={'b-user-name white space-between'}>
                        <div>#{item.number}</div>
                        <div>{item.reference}</div>
                      </div>
                      <div className={'b-user-email white space-between'}>
                        <div>{formatDateFromMillis(item.createdAt)}</div>
                        <div>
                          {item.statusId === 1
                            ? formatHourFromMillis(
                                new Date().valueOf() - item.createdAt,
                                true,
                              )
                            : formatHourFromMillis(
                                item.reviewedAt - item.createdAt,
                                true,
                              )}
                        </div>
                      </div>
                      {haveAnyValue(item.clientName) && (
                        <div className={'b-user-name white'}>{item.clientName}</div>
                      )}
                    </div>
                  </div>
                  <div className={'manufacture-body'}>
                    {item.details &&
                      item.details.map((id, i) => (
                        <div key={i} className={'b-item d-flex b-bottom'}>
                          <div className={'b-user-name mr-2'}>{id.quantity}</div>
                          <div className={'column'}>
                            <div className={'b-user-name'}>{id.productName}</div>
                            <div className={'b-user-email'}>{id.commentary}</div>
                            {id.description && (
                              <div className={'column'}>
                                {id.description.split(',').map((desc, i) => (
                                  <div className={'b-user-email'} key={i}>
                                    {desc}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>

                          <CommandTicket
                            keyId={item.id}
                            correlative={item.number}
                            pos={pos}
                            userName={user.name}
                            date={formatDateFromMillis(item.createdAt)}
                            title={item.reference}
                            items={itemsByTicket}
                          />
                        </div>
                      ))}
                  </div>
                  <div className={'manufacture-footer'}>
                    <Row className={'container-buttons'}>
                      {item.stateId === 1 && canApproveOrReject ? (
                        <>
                          {canReject && (
                            <Icon
                              spin={loadingItem && action.type === 1}
                              disabled={disabledActions}
                              onClick={() => {
                                setAction({ type: 1, id: item.id })
                                dispatch(rejectTransfer(item.id))
                              }}
                              icon={faWindowClose}
                              size={'2x'}
                              style={{ marginRight: 15, color: 'red' }}
                              tooltip={'Rechazar'}
                            />
                          )}

                          {canApprove && (
                            <Icon
                              spin={loadingItem && action.type === 2}
                              disabled={disabledActions}
                              onClick={() => {
                                setAction({ type: 2, id: item.id })
                                dispatch(acceptTransfer(item.id))
                              }}
                              icon={faCheckCircle}
                              size={'2x'}
                              tooltip={'Aprobar'}
                              style={{ color: 'green' }}
                            />
                          )}
                        </>
                      ) : item.stateId === 8 && canValidate ? (
                        <Icon
                          icon={faCheckDouble}
                          onClick={() => {
                            setAction({ type: 3, id: item.id })
                            dispatch(onValidateTransfer(item.id))
                          }}
                          size={'2x'}
                          tooltip={'Validar manufactura'}
                          style={{ color: 'green' }}
                          spin={loadingItem && action.type === 3}
                          disabled={disabledActions}
                        />
                      ) : (
                        <div>{item.stateName}</div>
                      )}
                      <Icon
                        disabled={loadingA || (loadingR && action.id !== item.id)}
                        icon={faPrint}
                        size={'2x'}
                        style={{ marginLeft: 15, color: 'gray' }}
                        tooltip={'Imprimir'}
                        onClick={() => {
                          if (prints && prints.length > 0) {
                            const data = printCommandTicket(
                              itemsByTicket,
                              item.solicitedBy || user.name,
                              {
                                posType: posType || 1,
                                posName: posName || company.name,
                                tableNumber: item.reference,
                                description,
                                footerPhrase,
                              },
                              item.createdAt,
                              item.number,
                              logo,
                            )
                            dispatch(
                              onSetPrintData({
                                show: true,
                                customData: data,
                                element: `ticket-command-${item.id}`,
                                noPrinters: tableId !== undefined,
                              }),
                            )
                          } else
                            dispatch(
                              onSetPrintData({
                                show: true,
                                element: `ticket-command-${item.id}`,
                                noPrinters: tableId !== undefined,
                              }),
                            )
                          dispatch(
                            setGeneralData({
                              tableId,
                            }),
                          )
                        }}
                      />
                    </Row>
                  </div>
                </div>
              </Col>
            )
          })}
        </Row>
      )}

      <Alert {...alert} />
    </div>
  )
}
export default ManufacturePOS
