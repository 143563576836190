import "./Balance.scss";
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import { useLocation } from 'react-router-dom';

import {Modal, Tab, Tabs, Row, Col} from "react-bootstrap";
import {Td, Tr} from "react-super-responsive-table";
import {faCheck, faEye} from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert-react";

import {
    Card, Button, Select, FormText as FormTextField, Icon as IconButton, CustomReference, BalanceTable,
    Title,
    TableV2
} from "../../components";

import {formatDateFromMillis} from "../../utils/formatters";

import {
    selectAdminUserEntries,
    selectBalanceEntries,
    selectBalanceEntryTypes,selectGetUsersBalance
} from "../../selectors/balance.selector";
import {selectCurrentModule} from "../../selectors/user.selector";
import {selectSetCurrentCompany} from "../../selectors/company.selector";
import {selectGetSingleInvoice} from "../../selectors/resolutions.selector";

import {getOfferLinkBalance} from "../../actions/offers.actions";
import {selectOfferLinkBalance} from "../../selectors/offers.selector";

import {
    createUserBalanceEntry, getAdminUserEntiesInCompany, getBalanceEntries,
    getBalanceEntryTypes, getUsersBalanceInCompany
} from "../../actions/balance.actions";

import {selectDeposits, selectGetAccounts, selectGetBanks} from "../../selectors/banks.selector";
import {actionTypes as bankActions,getAccounts, getAllDepositsByCompany, getBanks} from "../../actions/banks.actions";

import {getDisbursements} from "../../actions/disbursements.actions";

import {getUsersChildrenByModule} from "../../actions/modules.actions";
import {selectUsersName} from "../../selectors/modules.selector";

import {actionTypes as balanceActions} from "../../actions/balance.actions"

import {loadingSelector} from "../../selectors/loading.selector";

import InvoiceModal from "./InvoiceModal";
import NewDisbursement from "../Distrbution/Disbursements/NewDisbursement";
import { toMoney } from "../../utils/utilities";
import { selectCurrentCurrency } from "../../selectors/currencies.selector";

const BalanceDisbursements = () =>{
    const dispatch = useDispatch();
    const entries = useSelector(selectBalanceEntries);
    const deposits = useSelector(selectDeposits);
    const entryTypes = useSelector(selectBalanceEntryTypes);
    const module = useSelector(selectCurrentModule);
    const company = useSelector(selectSetCurrentCompany);
    const currentCurrency = useSelector(selectCurrentCurrency);

    const usersBalance = useSelector(selectGetUsersBalance);
    const banks = useSelector(selectGetBanks);
    const accounts = useSelector(selectGetAccounts);
    const userLog = useSelector(selectAdminUserEntries);
    const linkBalance = useSelector(selectOfferLinkBalance);

    const usersSelector = useSelector(state => selectUsersName(state));
    const depositsLoading = useSelector(state => loadingSelector([bankActions.GET_DEPOSITS_COMPANY])(state));
    const userBalanceEntryLoading = useSelector(state => loadingSelector([balanceActions.CREATE_USER_ENTRY])(state));

    const invoiceSelector = useSelector(selectGetSingleInvoice,shallowEqual);

    const [balanceType,setBalanceType] = useState(0);
    const [selectedModule] = useState(module>2000?1000:module);
    const [showNew,setShowNew] = useState(false);
    const [isKolo] = useState(useLocation().pathname.includes('/kolo'));
    const [userModal, setUserModal] = useState(false);
    const [selectedUser,setSelectedUser] = useState({value: null, label: '- Seleccionar -'});
    const [clicked,setClicked] = useState(false);
    const [operation,setOperation] = useState({value: 1, label: 'Acreditar'});
    const [amount,setAmount] = useState(0.0);
    const [showAlert] = useState(false);
    const [showList,setShowList] = useState(false);
    const [invoice,setInvoice] = useState({id: 0, show: false});
    const [entryMessage,setEntryMessage] = useState('');
    const [errorModal,setErrorModal] = useState({show:false,message:''});
    const [showInvoice,setShowInvoice] = useState(false);
    const [alertForm,setAlertForm] = useState({
        title:"",
        text:"",
        type: "success",
        show: showAlert,
        onConfirm:()=>{setUserModal({...alertForm,show:false})}
    });
    const [customRef, setCustomRef] = useState({show: false, documentType: 0, documentId: 0});

    const [actions, setAction] = useState({entry: false});
    const [filters, setFilter] = useState({
        type: {value: null, label: '- Todos -'}
    });

    const [showBK, setBK] = useState(false);

    useEffect(()=>{
        setBalanceType(2);
        dispatch(getBalanceEntryTypes());
        dispatch(getUsersBalanceInCompany());
        dispatch(getBanks());
        dispatch(getAccounts());
        dispatch(getDisbursements());
        dispatch(getAllDepositsByCompany())
        if (isKolo)
            dispatch(getOfferLinkBalance(1, 2));
        setUp(new Date(), new Date());
    },[]);

    const setUp = (sDate, eDate) => {
        const start = sDate.setHours(0, 0, 0, 0).valueOf();
        const end = eDate.setHours(23, 59, 59, 59).valueOf();

        dispatch(getBalanceEntries(2, {start, end}));
    };

    useEffect(()=>{
        if(usersSelector&&clicked){
            setClicked(false);
            setUserModal(true);
        }
    },[usersSelector]);

    useEffect(() =>{
        let t = 0;
        deposits.map(d =>{
            if(!d.authorizedBy) t += d.amount
        })
    }, [depositsLoading])

    useEffect(()=>{
        if (userBalanceEntryLoading)
            setAction({...actions, entry: true});
        else if (actions.entry) {
            setAlertForm({type: 'success',title: 'Exito',text: 'La entrada ha sido creada con exito',show: true,onConfirm:()=>{
                    setAlertForm({...alertForm,show:false});
                    setSelectedUser({value: null, label: '- Seleccionar -'});
                    setOperation({value: 1, label: 'Acreditar'});
                    setAmount(0.0);
                    setClicked(false);
                    dispatch(getUsersBalanceInCompany());
                }});
        }
    },[userBalanceEntryLoading]);

    useEffect( () => {
        console.log("got invoice",invoiceSelector,clicked);
        setInvoice(invoiceSelector);
        setShowInvoice(true);
    },[invoiceSelector]);

    const showReference = (entryType, reference) =>{
        if(entryType==1 || entryType == 8 || entryType == 13 || entryType == 14 || entryType == 7 || entryType == 6){
            setInvoice({show: true, id: reference})
        }
        else setCustomRef({show: true, documentType: entryType, documentId: reference});
    };

    const renderRowDisbursements = (item, index) => {
        return (
            <Tr className={'data'} key={`${index}`}>
                <Td className={'mini'}>{index+1}</Td>
                <Td className={'mini'}>{formatDateFromMillis(item.createdAt)}</Td>
                <Td className={'mini'}>{toMoney(item.amount)}</Td>
                <Td className={'name'}>{getStatusName(item.status)}</Td>
                <Td className={'name'}>{item.statusDesc}</Td>
                <Td className={'name'}>{item.user?item.user:'-'}</Td>
                <Td className={'name'}>{item.auth?item.auth:'-'}</Td>
            </Tr>
        )
    };

    const renderRow = (item, index) => {
        //headers={['Fecha', 'Accion', 'Cantidad','Balance anterior', 'Balance']}
        if (!item.type)
            return ;
        return (
            <Tr className={'data'} key={index}>
                <Td className={'mini'}> {formatDateFromMillis(item.createdAt)}</Td>
                <Td className={'short'}>{getTypeFromId(item.type).name}</Td>
                <Td className={'mini'}>{toMoney(item.amount)}</Td>
                <Td className={'mini'}>{toMoney(item.previousBalance)}</Td>
                <Td className={'mini'}>{toMoney(item.newBalance)}</Td>
                <Td className={'mini'}> {
                    item.reference && (item.type !== 17 || item.type !== 18) &&
                    <Button onClick={()=>{showReference(item.type,item.reference);}} >Referencia</Button>
                } </Td>
            </Tr>
        );
    };

    const getHeaders = () => {
        return [
            {label: 'Fecha', show: true, hideTr:(item) => !item.type ? true : false, value: ['createdAt'], type: 'date' ,  className:'mini'},
            {label: 'Accion', show: true, value: ['accion'], type: 'text' ,  className:'short', custom:(item)=> item.accion =getTypeFromId(item.type).name},
            {label: 'Cantidad', show: true, value: ['amount'], type: 'currency' ,  className:'mini'},
            {label: 'Balance anterior', show: true, value: ['previousBalance'], type: 'currency' ,  className:'mini'},
            {label: 'Balance Nuevo', show: true, value: ['newBalance'], type: 'currency' ,  className:'mini'},
            {config:true, show: true, label:'', className:'mini', custom:(item)=>item.reference && (item.type !== 17 || item.type !== 18) &&
            <Button onClick={()=>{showReference(item.type,item.reference);}} >Referencia</Button>}

        ]
    }

    const getTypeFromId = (id) => {
        let found  = entryTypes.find( t => t.id === id);
        return found;
    };

    const filter = (item) => {
        let type = getTypeFromId(item.type);
        return(
            item.type &&
            type &&
            type.module===selectedModule
        )
    };

    const getStatusName = (status) =>{
        switch (status) {
            case 1:
                return 'Solicitada';
            case 2:
                return 'En proceso';
            case 3:
                return 'Problema';
            case 4:
                return 'Rechazada';
            case 5:
                return 'Aprobada';
            default:
                return 'N/A';
        }
    };

    const findBalanceForUser = (id) =>{
        let user = usersBalance.find(u=>u.user==id);
        if(user)
            return module==1000?user.balanceTransportist:user.balanceProducer;
        return 0.00;
    };

    const getUsers = (clear) =>{
        if(clear){
            setSelectedUser({value: null, label: '- Seleccionar -'});
            setOperation({value: 1, label: 'Acreditar'});
            setAmount(0.0);
        }

        dispatch(getUsersChildrenByModule(module, true));
        setClicked(true);
    };

    const createEntry = () =>{
        if(!(selectedUser.value && operation.value>=0 && entryMessage.length >= 0)){
            setErrorModal({show:true,message: 'Revise los datos de los campos obligatorios'});
            return;
        }
        else{
            setErrorModal({show:false,message: ''});
        }

        dispatch(createUserBalanceEntry(selectedUser.value,operation.value,Math.abs(amount),entryMessage));
        setClicked(false);
        setEntryMessage('');
        setUserModal(false);
    };


    const getHeadersUser = () => {
        return [
            {label: '', show: true,  custom: (item) => <IconButton icon={faCheck}/>, classNameCustom:(item) => item.previousBalance < item.newBalance ? 'mini purchased' : 'mini kolo_pink' },
            {label: 'Fecha', show: true, value: ['createdAt'], type: 'date' ,  className:'mini'},
            {label: 'Accion', show: true, value: ['accionC'], type: 'text' ,  className:'short', custom:(item) => item.accionC =  (item.deleted?"(Eliminado)":"").concat(getTypeFromId(item.type).name) },
            {label: 'Cantidad', show: true, value: ['amount'], type: 'currency' ,  className:'mini'},
            {label: 'Balance Anterior', show: true, value: ['previousBalance'], type: 'currency' ,  className:'mini'},
            {label: 'Balance nuevo', show: true, value: ['newBalance'], type: 'currency' ,  className:'mini'},
            {label: 'Por', show: true, value: ['createdData','name'], type: 'text' ,  className:'mini'},
            {label: 'Desc', show: true, value: ['description'], type: 'text' ,  className:'mini'},
            {config:true, show: true, label:'', className:'mini'}

        ]
    }

    const getTotalBalance = () =>{
        let total = 0
        usersBalance.filter(u=>u.type==(isKolo?1:0)).map(item => {
            if(module===1000) total += item.balanceTransportist
            else total += item.balanceProducer
        })
        return total
    };

    const filterItems = (item) => {
        const {type} = filters;
        return (
            ((type.value && type.value === item.type) || (!type.value))
        );
    };

    const getBalanceStructure = () => {
        let available = 0, ret = 0;

        if (linkBalance) {
            const {balanceT, balanceTRet, balanceP, balancePRet} = linkBalance;

            if (module === 2000) {
                available = balanceP;
                ret = balancePRet;
            } else {
                available = balanceT;
                ret = balanceTRet;
            }
        }
        return {available, ret};
    };

    

    return (
        <div>
            <Title
                title={'Balance de la empresa'}
                action={false}
            />
            <Row>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}><Row>
                    <Col xl={12} md={12} sm={12}>
                        <Card title={"Balance a la fecha"}>
                            <h2>{company.name}</h2>
                            {
                                module&& module===1000 &&
                                <div>
                                    {
                                        isKolo && <>
                                            <h3>
                                                Balance Transportista
                                                <b className={company.balanceTransp<0?company.balanceTransp>0?'green':'red':''} >
                                                    {toMoney(company.balanceTransp)}
                                                </b><br/>
                                            </h3>

                                            <h3>
                                                Balance Transportista Retenido
                                                <b className={company.balanceRetTransp < 0 ? 'red' : company.balanceRetTransp>0?'green':''} >
                                                    {toMoney(company.balanceRetTransp)}
                                                </b><br/>
                                            </h3>
                                        </>
                                    }
                                    {
                                        balanceType === 2 && !isKolo &&
                                        <h3>
                                            {/*Saldos propios, Transportista*/}
                                            {/*<b className={company.ownBalanceTransp<0?company.ownBalanceTransp>0?'green':'red':''} >*/}
                                            {/*    {toMoney(company.ownBalanceTransp)}*/}
                                            {/*</b>*/}
                                            Sumatoria de Efectivo Circulante
                                            <b className={getTotalBalance()>=0?'green':'red'} >
                                                {toMoney(getTotalBalance())}
                                            </b>
                                        </h3>
                                    }

                                </div>

                            }
                            {
                                module && module === 2000 &&
                                <div>
                                    {isKolo &&
                                    <>
                                        <h3>
                                            Balance Productor
                                            <b className={company.balanceProducer<0?company.balanceProducer>0?'green':'red':''} >
                                                {toMoney(company.balanceProducer)}
                                            </b><br/>
                                        </h3>

                                        <h3>
                                            Balance Productor Retenido
                                            <b className={company.balanceRetProducer<0?company.balanceRetProducer>0?'green':'red':''} >
                                                {toMoney(company.balanceRetProducer)}
                                            </b><br/>
                                        </h3>
                                    </>
                                    }
                                    {
                                        balanceType === 2 && !isKolo &&
                                        <h3>
                                            Saldos propios, Productor
                                            <b className={company.ownBalanceProd<0?company.ownBalanceProd>0?'green':'red':''} >
                                                {toMoney(company.ownBalanceProd)}
                                            </b>
                                        </h3>
                                    }
                                </div>
                            }
                            {/*
                            <Button className={'big-button'} onClick={()=> {setShowNew(true)} }>Solicitar desembolso</Button>
                            */}
                        </Card>
                    </Col>
                    <Col xl={12} md={12} sm={12}>
                        {isKolo && <Card>
                            <div className={'justify-center column'}>
                                <span>Balance con KOLO</span>
                                <h4>{toMoney(linkBalance ? module === 1000 ? (linkBalance.balanceT || 0) : (linkBalance.balanceP || 0) : 0)}</h4>
                            </div>
                            <div style={{position: 'absolute', top: 10, right: 15}}><Row className={'container-buttons'}>
                                <IconButton onClick={() => setBK(true)} tooltip={'Ver balance'} icon={faEye}/>
                            </Row></div>
                        </Card>}
                    </Col>
                    <Col xl={12} md={12} sm={12}>
                        <Card title={'Usuarios con balance'}>
                            {!isKolo && <Row className={'container-buttons'}>
                                <Button onClick={()=>{
                                    setShowList(true);
                                    getUsers(true);
                                }}>Agregar a usuario</Button>
                            </Row>}
                            <TableV2
                                customClass={'scroll-x-without-height'}
                                pSize={0}
                                items={usersBalance.filter(u=> u.type === (isKolo ? 1 : 0))}
                                noItemsLegend={'No hay movimientos para mostrar'}
                                headers={[
                                    {label: 'Nombre', show: true, value: ['userData','name'], type: 'text' ,  className:'mini'},
                                    {label: 'Correo', show: true, value: ['userData','email'], type: 'text' ,  className:'mini'},
                                    {label: 'Balance', show: true, value: ['balanceC'], type: 'text' ,  className:'mini', custom:(item)=>item.balanceC = toMoney(module===1000? item.balanceTransportist:item.balanceProducer) },
                                    {config:true, show: true, label:'', className:'mini'}
                                ]}
                                mobileAuto
                                storageKey={`balanceUsers`}
                                onClickTr = {(e,item)=> {
                                    if(isKolo){
                                        return;
                                    }
                                    setSelectedUser({value:item.user,label:item.userData.name});
                                    setAmount(module===1000? item.balanceTransportist:item.balanceProducer);
                                    dispatch(getAdminUserEntiesInCompany(item.user,0));
                                    setShowList(false);
                                    getUsers();
                                }}
                            />
                        </Card>
{/*

                        {isKolo &&
                        <Card title={"Usuarios con Balance"}>
                            <PaginatedTable
                                headers={['Nombre', 'Correo', 'Balance']}
                                items={usersBalance.filter(u=>u.type==(isKolo?1:0))}
                                renderRow={renderRowUsers}
                                noItemsLegend={'No hay movimientos para mostrar'}
                            />
                        </Card>
                        }
                        <Card title={"Historial de desembolsos"}>
                            <PaginatedTable
                                headers={['Numero', 'Fecha', 'Cantidad', 'Estado','Descripcion','Creado','Autorizado']}
                                items={disbursements}
                                renderRow={renderRowDisbursements}
                                noItemsLegend={'No hay movimientos para mostrar'}
                            />
                        </Card>

                        {!isKolo &&
                        <Card title={"Usuarios con Balance"}>
                            <div>
                                <Row>
                                    <Col><Button onClick={()=>{
                                        setShowList(true);
                                        getUsers(true);
                                    }
                                    } >Agregar a usuario</Button></Col>
                                    <Col></Col>
                                    <Col></Col>
                                    <Col></Col>
                                </Row>
                            </div>
                            <PaginatedTable
                                headers={['Nombre', 'Correo', 'Balance']}
                                items={usersBalance.filter(u=>u.type==(isKolo?1:0))}
                                renderRow={renderRowUsers}
                                noItemsLegend={'No hay movimientos para mostrar'}
                            />
                        </Card>
                        }
*/}

                    </Col>
                </Row>
                </Col>
                <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                    <Card title={"Historial"} white>
                        <Row>
                            <Col xl={6} sm={12}>
                                <Select
                                    label={'Filtro por tipo'}
                                    options={[{value: null, label: '- Todos -'}, ...entryTypes.filter((et) => et.module === module)]}
                                    value={filters.type}
                                    onChange={(value) => setFilter({...filters, type: value})}
                                />
                            </Col>
                        </Row>
                        {isKolo&&
                        <Tabs defaultActiveKey={isKolo?"real":"own"} id="uncontrolled-tab-example" style={{position:'inherit',boxShadow:'none'}}>
                            <Tab eventKey="real" title="Disponible">
                                <div className={'tab-content'} >
                                    <TableV2
                                        mobileAuto
                                        storageKey={`historyDisbur`}
                                        headers={getHeaders()}
                                        items={entries?entries.filter(filter).filter(filterItems).filter((x)=>x.balanceType ===-1).sort((a, b) => (a.id > b.id)):[]}
                                        dateFilter
                                        onDateChange={(start, end) => setUp(start, end)}
                                    />
                                </div>
                            </Tab>
                            <Tab eventKey="retention" title="Retenido">
                                <div className={'tab-content'} >
                                    <TableV2
                                        storageKey={`historyDisbur`}
                                        headers={getHeaders()}
                                        items={entries?entries.filter(filter).filter(filterItems).filter((x)=>x.balanceType ===1):[]}
                                        dateFilter
                                        onDateChange={(start, end) => setUp(start, end)}
                                    />
                                </div>
                            </Tab>
                        </Tabs>
                        }
                        {!isKolo &&
                        <Tabs defaultActiveKey="own" id="uncontrolled-tab-example" style={{position:'inherit',boxShadow:'none'}}>
                            <Tab eventKey="own" title={company.name}>
                                <div className={'tab-content'} >
                                    <TableV2
                                        dateFilter
                                        onDateChange={(start, end) => setUp(start, end)}
                                        headers={getHeaders()}
                                        mobileAuto
                                        storageKey={`historyDisbur`}
                                        items={entries?entries.filter(filter).filter(filterItems).filter((x)=>x.balanceType ===0).filter((x)=>x.owner===company.id).sort((a, b) => (b.id - a.id)):entries}

                                    />
                                </div>
                            </Tab>
                            {/*<Tab eventKey="cotizap" title="Cotizap">*/}
                            {/*    <div className={'tab-content'} >*/}
                            {/*        <Table*/}
                            {/*            headers={['Fecha', 'Accion', 'Cantidad','Balance anterior', 'Balance Nuevo', 'Referencia']}*/}
                            {/*            items={entries?entries.filter(filter).filter((x)=>x.balanceType ===2).sort((a, b) => (a.id > b.id)):entries}*/}
                            {/*            renderRow={renderRow}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</Tab>*/}

                        </Tabs>
                        }      
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                </Col>
            </Row>

            <Modal show={showNew} size="md" centered onHide={()=>setShowNew(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Nueva Solicitud
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NewDisbursement bankAccounts={accounts.map(b =>
                    {return {...b, value: b.id, label: `${b.name} - No. ${b.accountNumber}`}})} banks={banks} item={module===1000?company.balanceTransp:company.balanceProducer} closeThis={() => setShowNew(false)}/>
                </Modal.Body>
            </Modal>

            <Modal show={userModal} size="lg" centered onHide={()=>setUserModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedUser.value?"Usuario " + selectedUser.label:"Agregar a usuario"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        errorModal.show&&
                        <Row style={{color:"red"}}>{errorModal.message}</Row>
                    }
                    <Row>
                        {showList&&
                        <Col sm={12} md={4}>
                            <Select
                                label={'Usuario'}
                                options={[{value: null, label: '- Seleccionar -'}].concat(usersSelector)}
                                value={selectedUser}
                                onChange={(data) => {
                                    setSelectedUser(data);
                                    setAmount(findBalanceForUser(data.id));
                                }}
                                required
                            />
                        </Col>
                        }

                        <Col sm={12} md={4}>
                            <Select
                                required
                                label={'Tipo'}
                                options={[{value: 1, label: 'Acreditar'}, {value: 0, label: 'Retirar'}]}
                                value={operation}
                                onChange={(s) => setOperation(s)}
                            />
                        </Col>
                        <Col sm={12} md={4}>
                            <FormTextField
                                label={"Cantidad"}
                                type={"number"}
                                id={"amount"}
                                placeholder={"0.0"}
                                name={"amount"}
                                value={Math.abs(amount)}
                                onChange={(e)=>{setAmount(e.target.value)}}
                                error={""}
                                inputClassName={operation.value==1?"green":"red"}
                                required
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9}>
                            <FormTextField
                                label={`Motivo (${entryMessage.length}/50)`}
                                type={"text"}
                                id={"message"}
                                placeholder={"Motivo"}
                                name={"message"}
                                value={entryMessage}
                                onChange={(e)=>{

                                    if(e.target.value && e.target.value.length>50){
                                        setEntryMessage(e.target.value.slice(0,50));
                                    }
                                    else{
                                        setEntryMessage(e.target.value);
                                    }
                                }}
                                error={""}
                                required
                            />
                        </Col>
                        <Col md={3}>
                            <br/>
                            <br/>
                            <Button
                                onClick={() => createEntry()}
                                // loading={loadingAccept || loadingS}
                                disabled={!selectedUser.value&&amount<=0}
                            > Agregar entrada</Button>
                        </Col>

                    </Row>
                    <hr/>
                    {!showList&&
                        <div>
                            <h2>Historial</h2>
                            <Row>
                                <Col xl={6} sm={12}>
                                    <Select
                                        label={'Filtro por tipo'}
                                        options={[{value: null, label: '- Todos -'}, ...entryTypes.filter((et) => et.module === module)]}
                                        value={filters.type}
                                        onChange={(value) => setFilter({...filters, type: value})}
                                    />
                                </Col>
                                <Col xl={12} sm={12}>
                                    <TableV2
                                        headers={getHeadersUser()}
                                        mobileAuto
                                        customClass={'scroll-x-without-height'}
                                        items={userLog.filter(filterItems)}
                                        storageKey={`historyUser`}
                                    />
                                    {/* <Table
                                        headers={['', 'Fecha', 'Accion', `Cantidad (${currentCurrency.symbol})`,`Balance Anterior (${currentCurrency.symbol})`, `Balance nuevo (${currentCurrency.symbol})`, 'Por', 'Desc']}
                                        items={userLog.filter(filterItems)}
                                        renderRow={renderRowHistory}
                                    /> */}
                                </Col>
                            </Row>
                        </div>
                    }

                </Modal.Body>
            </Modal>
            <InvoiceModal invoice={invoice} show={showInvoice}  onHide={()=>{setShowInvoice(false)}} />
            <SweetAlert{...alertForm}/>

            <CustomReference
                show={customRef.show}
                documentModule={2}
                documentType={customRef.documentType}
                documentId={customRef.documentId}
                onHide={() => setCustomRef({...customRef, show: false})}
            />

            <Modal
                show={showBK}
                centered
                size={'lg'}
                onHide={() => setBK(false)}>

                <Modal.Header><Modal.Title>Balance con Kolo</Modal.Title></Modal.Header>
                <Modal.Body>{showBK && <BalanceTable
                    principal={{xl: 12, lg: 12, md: 12, sm: 12, xs: 12}}
                    second={{xl: 12, lg: 12, md: 12, sm: 12, xs: 12}}
                    third={{xl: 6, lg: 6, md: 6, sm: 6, xs: 6}}
                    balanceType={-1}
                    linkId={linkBalance.linkId}
                    balance={getBalanceStructure()}
                />}</Modal.Body>
            </Modal>

        </div>
    )
};

export default BalanceDisbursements;
