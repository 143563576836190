import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Row, Col, Form } from 'react-bootstrap'
import { faArrowRight, faBackspace } from '@fortawesome/free-solid-svg-icons'

import Card from 'src/components/cards/Card'
import Button from 'src/components/buttons/Button'
import MapCard from 'src/components/maps/MapCard'
import FormText from 'src/components/inputs/FormTextField/FormText'
import Select from 'src/components/inputs/Select/CustomSelect'

import { setChangeLink } from 'src/actions/offers.actions'
import { selectLinkSingle, selectLinkCategory } from 'src/selectors/offers.selector'

import { selectCurrentCompany } from 'src/selectors/user.selector'

import { getCenter } from 'src/selectors/utilities.selector'

const ContractPayments = props => {
  const dispatch = useDispatch()

  const link = useSelector(selectLinkSingle)
  const company = useSelector(selectCurrentCompany)

  const [index, setIndex] = useState(1)
  const [cust, setCust] = useState('')

  const [google, setGoogle] = useState({ map: null, maps: null })

  const linkTypes = useSelector(selectLinkCategory)

  useEffect(() => {
    if (link && link.type !== undefined) {
      let type = linkTypes.find(l => l.value === link.type)
      if (!type) {
        type = linkTypes
          .filter(l => (link.shipperId === company ? l.value === 5 : l.value !== 5))
          .filter(l => l.kolo === props.isKolo)[0]
      }

      link.type = type.value
      link.info = type
      dispatch(setChangeLink(link))
      setCust(JSON.stringify(link))
    }
    onChangeInput({ target: { value: 20, name: 'paymentPerPercentage' } })
  }, [])

  useEffect(() => {
    setIndex(1)
  }, [cust])

  const onChangeInput = event => {
    const { name, value } = event.target
    link.setting = { ...link.setting, [name]: value }
    dispatch(setChangeLink(link))
    setCust(JSON.stringify(link))
  }

  return (
    <div className={'column'}>
      <Row>
        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Card title={'Detalles de pagos'}>
                <Form>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Select
                        disabled={link.shipperId === company}
                        label={'Tipo de enlace'}
                        options={linkTypes.filter(l => l.kolo === props.isKolo)}
                        required
                        value={link.info}
                        onChange={value => {
                          link.type = value.value
                          link.info = value
                          dispatch(setChangeLink(link))
                          setCust(JSON.stringify(link))
                        }}
                      />
                    </Col>

                    {!props.isKolo ? (
                      link.type && (
                        <>
                          {/* {link.type === 3 && <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <FormText
                                prependMoneySymbol
                                label={'Pago por cajilla'}
                                name={'paymentStandard'}
                                type={'number'}
                                value={link.setting.paymentStandard}
                                onChange={onChangeInput}
                                sub={'Se paga un monto por cada producto individual entregado'}
                            />
                        </Col>}*/}
                          {link.type === 2 && (
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                              <FormText
                                disabled
                                prependMoneySymbol
                                label={'Pago por factura entregada'}
                                name={'paymentPerInvoice'}
                                type={'number'}
                                value={link.setting.paymentPerInvoice}
                                onChange={onChangeInput}
                                sub={'Cada factura entregada tiene un costo.'}
                              />
                            </Col>
                          )}
                        </>
                      )
                    ) : (
                      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        {/*<FormText
                            disabled
                            prepend={'%'}
                            label={'Pago por un porcentaje de la factura entregada'}
                            name={'paymentPerPercentage'}
                            type={'number'}
                            value={link.setting.paymentPerPercentage}
                            onChange={onChangeInput}
                            sub={'Se paga un porcentaje de la factura a kolo.'}
                        />*/}
                      </Col>
                    )}
                  </Row>
                </Form>
              </Card>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Card>
                <div className={'column left'}>
                  {link.type && link.info ? (
                    <div className={'column'}>
                      <h4>{link.info.info.title}</h4>
                      <p>{link.info.info.tDesc}</p>
                      <h5>{link.info.info.subTitle}</h5>
                      <p>{link.info.info.sDesc}</p>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xl={8} lg={8} md={6} sm={12} xs={12}>
          <MapCard
            title={link.polygonName}
            latitude={14.55}
            longitude={-90.55}
            height={600}
            startCollapsed={false}
            markerList={[]}
            zoom={7}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              setTimeout(() => {
                let points = link.points
                  .sort((a, b) => a.index - b.index)
                  .map(p => ({
                    lat: Number(p.latitude),
                    lng: Number(p.longitude),
                  }))

                const polygon = new maps.Polygon({
                  path: points,
                  strokeColor: `#24ecff`,
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: `#24ecff`,
                  fillOpacity: 0.35,
                })

                const center = getCenter(points)

                map.setZoom(8)
                map.panTo(center.latLng)
                polygon.setMap(map)
              }, 300)
            }}
          />
        </Col>
      </Row>

      <Row className={'container-buttons'}>
        <Button left icon={faBackspace} onClick={() => window.close()}>
          Salir
        </Button>
        <Button
          variant={'info'}
          right
          icon={faArrowRight}
          onClick={() => {
            props.jumpToStep(1)
          }}
        >
          Siguiente
        </Button>
      </Row>
    </div>
  )
}
export default ContractPayments
