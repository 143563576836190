import { getState } from 'src/utils/utilities'
import ProducersService from '../services/producers.service'
import { executeAction } from './global.actions'
import { selectProducers } from '../selectors/producer.selector'

export const actionTypes = {
  GET_ALL_PRODUCERS: 'GET_ALL_PRODUCERS',
  GET_ALL_PRODUCERS_REQUEST: 'GET_ALL_PRODUCERS_REQUEST',
  GET_ALL_PRODUCERS_SUCCESS: 'GET_ALL_PRODUCERS_SUCCESS',
  GET_ALL_PRODUCERS_ERROR: 'GET_ALL_PRODUCERS_ERROR',

  GET_PRODUCER: 'GET_PRODUCER',
  GET_PRODUCER_REQUEST: 'GET_PRODUCER_REQUEST',
  GET_PRODUCER_SUCCESS: 'GET_PRODUCER_SUCCESS',
  GET_PRODUCER_ERROR: 'GET_PRODUCER_ERROR',
  GET_BY_NIT: 'GET_BY_NIT',
  GET_PROVIDERS: 'GET_PROVIDERS',
  GET_HISTORY: 'GET_HISTORY',
  CREATE_PROVIDER: 'CREATE_PROVIDER',
  GET_SINGLE_PROVIDER: 'GET_SINGLE_PROVIDER',
  GET_EXCEL_REPORT: 'GET_EXCEL_REPORT',
}

const getAllProducersRequest = () => ({
  type: actionTypes.GET_ALL_PRODUCERS_REQUEST,
})
const getAllProducersSuccess = response => ({
  type: actionTypes.GET_ALL_PRODUCERS_SUCCESS,
  response,
})
const getAllProducersError = error => ({
  type: actionTypes.GET_ALL_PRODUCERS_ERROR,
  error,
})

const getAllProducerRequest = () => ({
  type: actionTypes.GET_PRODUCER_REQUEST,
})
const getAllProducerSuccess = response => ({
  type: actionTypes.GET_PRODUCER_SUCCESS,
  response,
})
const getAllProducerError = error => ({
  type: actionTypes.GET_PRODUCER_ERROR,
  error,
})

export const getProducers = () => async dispatch => {
  const producers = getState(selectProducers)
  if (producers.length > 0) return

  dispatch(getAllProducersRequest())
  try {
    const response = await ProducersService.getAllProducer()
    dispatch(getAllProducersSuccess(response))
  } catch (e) {
    dispatch(getAllProducersError(e))
  }
}

export const getProducer = id => async dispatch => {
  dispatch(getAllProducerRequest())
  try {
    const response = await ProducersService.getProducer(id)
    dispatch(getAllProducerSuccess(response))
  } catch (e) {
    dispatch(getAllProducerError(e))
  }
}

export const getProviderByNit = nit => async dispatch => {
  const process = async () => ({
    provider: await ProducersService.getProviderByNit(nit),
  })
  dispatch(executeAction(actionTypes.GET_BY_NIT, process))
}

export const getProviders = () => async dispatch => {
  const process = async () => ({
    providers: await ProducersService.getProviders(),
  })
  dispatch(executeAction(actionTypes.GET_PROVIDERS, process))
}

export const getHistory = (id, start, end) => async dispatch => {
  const process = async () => ({
    history: await ProducersService.getHistory(id, start, end),
  })
  dispatch(executeAction(actionTypes.GET_HISTORY, process))
}

export const onCreateProvider = params => async dispatch => {
  const process = async () => ({
    createProviderResponse: await ProducersService.onCreateProvider(params),
  })
  dispatch(executeAction(actionTypes.CREATE_PROVIDER, process))
}

export const onUpdateProvider = (id, params) => async dispatch => {
  const process = async () => ({
    createProviderResponse: await ProducersService.onUpdateProvider(id, params),
  })
  dispatch(executeAction(actionTypes.CREATE_PROVIDER, process))
}

export const getSingleProvider = id => async dispatch => {
  const process = async () => ({
    singleProvider: await ProducersService.getSingleProvider(id),
  })
  dispatch(executeAction(actionTypes.GET_SINGLE_PROVIDER, process))
}

/**
 * Action to get excel report of providers.
 * @param {string} providerIds List of provides (null get all)
 */
export const exportProviders = providerIds => async dispatch => {
  const process = async () => ({
    providers: await ProducersService.exportProviders(providerIds),
  })
  dispatch(executeAction(actionTypes.GET_EXCEL_REPORT, process))
}
