export const selectGetExpenses = state => state.expenses.expenses
export const selectGetExpensesProducts = state => state.expenses.expensesProducts
export const selectGetExpensesAdmin = state => state.expenses.adminExpenses

export const selectGetExpenseTypes = state => {
  const expenseTypes = state.expenses.expenseType ? state.expenses.expenseType : []
  let index = []
  let categories = []
  expenseTypes.map(item => {
    if (index.indexOf(item.category.id) === -1) {
      index.push(item.category.id)
      const options = expenseTypes
        .filter(t => t.category.id === item.category.id)
        .map(t => ({ value: t.id, label: t.name, ...t }))
      categories.push({
        options,
        value: item.category.id,
        label: item.category.name,
      })
    }

    return {
      value: item.id,
      label: item.name,
      ...item,
    }
  })
  return categories
}

export const selectExpenseTypes = state => state.expenses.expenseType
export const selectCreditExpense = state => state.expenses.expense
export const selectExpenseLog = state => state.expenses.expenseLog
