import './Geocoding.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Card from '../../cards/Card'
import Icon from '../../buttons/IconButton'

import { MAPS_API_KEY } from 'src/settings/app.settings'

import GoogleMapReact from 'google-map-react'
import { geolocated } from 'react-geolocated'

import { InputGroup, FormControl, ProgressBar, Col, Row } from 'react-bootstrap'
import { faSearchLocation } from '@fortawesome/free-solid-svg-icons'

import { getAddressComponent } from 'src/selectors/utilities.selector'
import { Button, Switch } from '../../index'
import { validateGeoLocation } from '../../../utils/utilities'

let interval = null
class Geocoding extends Component {
  state = {
    manual: true,
    values: {
      address: '',
      latLng: '',
    },
    map: null,
    maps: null,
    geocoder: null,
    infoWindow: null,
    marker: null,
    time: 3000,
    loading: false,
    update: false,
  }

  componentDidMount() {
    const { loading, onlyAddress } = this.props
    if (loading) loading(true)
    if (onlyAddress) this.setState({ manual: false })
  }

  button = () => {
    const { isGeolocationAvailable, isGeolocationEnabled, coords, editable } = this.props
    return (
      editable && (
        <Button
          color={'secondary'}
          onClick={async () => {
            const gpsResponse = await validateGeoLocation(
              { isGeolocationAvailable, isGeolocationEnabled, coords },
              null,
              null,
              null,
              true,
              true,
            )

            this.useMyCords(gpsResponse.cc.latitude, gpsResponse.cc.longitude)
          }}>
          Usar mi Ubicación
        </Button>
      )
    )
  }

  onChange = (value, parammeter) => {
    let { values } = this.state
    values[parammeter] = value

    this.setState({ values })
  }

  useMyCords = (lat, lng) => {
    this.setState({ loading: true })
    setTimeout(() => {
      if (this.props.loading) this.props.loading(false)
      this.setState({ time: 2000, loading: false })

      const { marker } = this.state
      this.setState({
        values: { ...this.state.values, latLng: `${lat},${lng}` },
      })
      marker.setMap(null)
      this.geocodeLatLng(15)
    }, 100)
  }

  onGoogleApiLoaded = (map, maps, latitude, longitude) => {
    if (!map) map = this.state.map
    if (!maps) maps = this.state.maps
    this.setState({ loading: true })
    setTimeout(() => {
      if (this.props.loading) this.props.loading(false)
      let { lat, lng, auto, defaultAddress, editable, coords } = this.props
      this.setState({ time: 2000, loading: false })
      const geocoder = new maps.Geocoder()
      const infoWindow = new maps.InfoWindow()

      editable &&
        map.addListener('click', e => {
          const { marker } = this.state
          this.setState({
            values: {
              ...this.state.values,
              latLng: `${e.latLng.lat()},${e.latLng.lng()}`,
            },
          })
          marker.setMap(null)
          this.geocodeLatLng()
        })

      this.setState({ map, maps, geocoder, infoWindow })

      lat = latitude || lat || (coords && coords.latitude) || 14.55
      lng = longitude || lng || (coords && coords.longitude) || -90.55

      if (lat && lng) {
        if (auto) {
          this.setState({
            values: { ...this.state.values, latLng: `${lat},${lng}` },
          })
          this.geocodeLatLng()
        } else {
          const marker = new maps.Marker({
            position: { lat: parseFloat(lat), lng: parseFloat(lng) },
            map,
            animation: maps.Animation.DROP,
          })
          marker.setMap(map)
          if (defaultAddress) {
            infoWindow.setContent(defaultAddress)
            infoWindow.open(map, marker)
          }
          this.setState({
            marker,
            values: { address: defaultAddress || '', latLng: `${lat},${lng}` },
          })
        }
      }
    }, this.state.time)
  }

  action = () => {
    const { manual, marker } = this.state
    const { onlyAddress } = this.props
    if (marker) {
      marker.setMap(null)
    }

    if (onlyAddress) this.geocodeAddress()
    else {
      if (manual) {
        this.geocodeLatLng()
      } else {
        this.geocodeAddress()
      }
    }
  }

  geocodeAddress = newZoom => {
    this.setState({ manual: false })
    const { geocoder, maps, map, infoWindow, values } = this.state
    const { zoom } = this.props
    if (geocoder)
      geocoder.geocode({ address: values.address }, (results, status) => {
        if (status === 'OK') {
          const center = results[0].geometry.location
          if (!zoom) map.setZoom(11)
          if (newZoom) map.setZoom(newZoom)
          map.panTo(center)

          const marker = new maps.Marker({
            position: center,
            map,
            animation: maps.Animation.DROP,
          })

          infoWindow.setContent(results[0].formatted_address)
          infoWindow.open(map, marker)
          this.setState({ marker })
          this.props.getData({
            address: results[0].formatted_address,
            latLng: { lat: center.lat(), lng: center.lng() },
            town: this.props.getAddress(results[0], 'administrative_area_level_2', true),
          })
        } else alert('No tuvo éxito por la siguiente razón: ' + status)
      })
  }

  getAddressB = components => {
    const { getAddress } = this.props
    return `${getAddress(components, 'country')}${getAddress(
      components,
      'administrative_area_level_1',
    )}${getAddress(components, 'administrative_area_level_2')}${getAddress(
      components,
      'locality',
    )}${getAddress(components, 'sublocality')}${getAddress(
      components,
      'route',
    )}${getAddress(components, 'street_number')}`
  }

  geocodeLatLng = newZoom => {
    const { onlyAddress } = this.props
    if (!onlyAddress) this.setState({ manual: true })
    const { geocoder, maps, map, infoWindow, values } = this.state
    const { zoom } = this.props
    const latLngStr = values.latLng.split(',', 2)
    const latLng = {
      lat: parseFloat(latLngStr[0]),
      lng: parseFloat(latLngStr[1]),
    }
    if (geocoder)
      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            const components = results[0]
            const address = `${this.getAddressB(components)}`
            const marker = new maps.Marker({
              position: latLng,
              map,
              animation: maps.Animation.DROP,
            })
            if (!zoom) map.setZoom(11)
            if (newZoom) map.setZoom(newZoom)
            map.panTo(latLng)
            infoWindow.setContent(address)

            infoWindow.open(map, marker)

            this.setState({
              marker,
              values: { address, latLng: values.latLng },
            })
            this.props.getData({
              address,
              latLng,
              town: this.props.getAddress(
                components,
                'administrative_area_level_2',
                true,
              ),
            })
          } else {
            console.log('not found')
          }
        } else {
          console.log(status)
        }
      })
  }

  getMap = () => {
    const { manual, values, loading } = this.state
    let { lat, lng, editable, coords, onlyAddress } = this.props

    lat = lat || (coords && coords.latitude) || 14.55
    lng = lng || (coords && coords.longitude) || -90.55

    const { address, latLng } = values

    return (
      <div>
        <div>
          {editable && !onlyAddress && (
            <Row>
              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                }}>
                <label className={'checkbox-label'} style={{ marginRight: 8 }}>
                  Dirección
                </label>
                <Switch
                  checked={manual}
                  label={'Coordenadas'}
                  name={'manual'}
                  toggle
                  onChange={e => this.setState({ manual: e.target.checked })}
                />
              </Col>
            </Row>
          )}
          <InputGroup>
            <FormControl
              disabled={!editable}
              type={'text'}
              value={manual ? latLng : address}
              onChange={({ target }) => {
                this.onChange(target.value, manual ? 'latLng' : 'address')

                if (!manual) {
                  clearTimeout(interval)
                  interval = setTimeout(() => {
                    this.action()
                  }, 1000)
                }
              }}
              placeholder={`pj. ${
                manual ? '14.55, -90.55' : 'Guatemala, Guatemala, Zona 1'
              }`}
            />

            {editable && (
              <InputGroup>
                <InputGroup.Text>
                  <Icon
                    icon={faSearchLocation}
                    tooltip={'Buscar'}
                    onClick={() => this.action()}
                  />
                </InputGroup.Text>
              </InputGroup>
            )}
          </InputGroup>
          {loading && (
            <Row>
              <Col>
                <div className={'pb-custom'}>
                  <ProgressBar
                    label="Cargando"
                    animated
                    now={100}
                    style={{ marginBottom: 10 }}
                  />
                </div>
              </Col>
            </Row>
          )}
        </div>
        <div className={'geocoding-style'}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: MAPS_API_KEY }}
            defaultCenter={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
            defaultZoom={14}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) =>
              this.onGoogleApiLoaded(map, maps)
            }></GoogleMapReact>
        </div>
      </div>
    )
  }

  render() {
    const { withoutTitle, withoutCard } = this.props

    return withoutCard ? (
      this.getMap()
    ) : (
      <Card title={withoutTitle ? null : 'Ubicación'} button={this.button()}>
        {this.getMap()}
      </Card>
    )
  }
}

const mapStateToProps = () => ({
  getAddress: (components, component, space) =>
    getAddressComponent(components.address_components, component, space),
})
const mapDispatchToProps = () => ({})

const ReduxComponent = connect(mapStateToProps, mapDispatchToProps)(Geocoding)

export default geolocated({
  positionOptions: { enableHighAccuracy: true },
  userDecisionTimeout: 3000,
})(ReduxComponent)
