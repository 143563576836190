import './ImageCarousels.scss'
import React, { Component } from 'react'

import { Carousel } from 'react-bootstrap'
import Food from 'src/assets/images/svg/food.svg'

export default class ImageCarousels extends Component {
  render() {
    const { images } = this.props

    return (
      <Carousel>
        {images.length > 0 ? (
          images.map((img, i) => (
            <Carousel.Item key={i}>
              <img className={'d-block w-100 max'} src={img.url} alt={img.name} />
            </Carousel.Item>
          ))
        ) : (
          <Carousel.Item>
            <img className={'d-block w-100 max'} src={Food} alt={'default'} />
          </Carousel.Item>
        )}
      </Carousel>
    )
  }
}
