import React, { useContext, useEffect, useState } from 'react'
import AccountList from './AccountList/AccountsList'
import AccountButtons from './AccountList/AccountButtons'
import AccountTableList from './AccountTable/AccountTableList'
import { Card } from 'src/components'
import {
  AccountMovementsContext,
  BankContext,
  defaultMovementsFilter,
  MovementsFilter,
  OperationsContext,
  OperationsType,
} from 'src/providers/BankContext'

import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import {
  selectCreateAccount,
  selectGetAccounts,
  selectUpdateAccount,
} from 'src/selectors/banks.selector'
import { transformDataUpdatedAccount } from '../../utils/bankHelpers/bankHelpers'

const styleSpan = { color: 'red', marginRight: 2 }
interface AccountViewProps {
  accounts?: IBankAccount[]
  banks?: IBank[]
}
function isNotEmptyObject(obj) {
  return Object.keys(obj).length !== 0
}
const AccountsView: React.FC<AccountViewProps> = ({ accounts, banks }) => {
  const { accounnt, updateAccount } = useContext(BankContext) || {}
  const createdAccount = useSelector(selectCreateAccount)
  const updatedAccount = useSelector(selectUpdateAccount)

  const accountList = useSelector(selectGetAccounts)

  const [currentParams, setCurrentParams] =
    useState<MovementsFilter>(defaultMovementsFilter)
  const [currentOperation, setCurrentOperation] = useState<OperationsType>({
    value: 0,
    label: '',
  })

  const updateOperationsType = (item: OperationsType) => {
    setCurrentOperation(prevOperations => ({
      ...prevOperations,
      ...item,
    }))
  }

  const updateParams = (item: MovementsFilter) => {
    setCurrentParams(prevParams => ({
      ...prevParams,
      ...item,
    }))
  }

  const operationsContextValue = {
    operations: currentOperation,
    updateOperations: updateOperationsType,
  }

  const movementsContextValue = {
    params: currentParams,
    updateParams: updateParams,
  }
  useEffect(() => {
    if (isNotEmptyObject(createdAccount) && !isNotEmptyObject(updatedAccount)) {
      updateAccount(accounts[accounts.length - 1])
    }
    if (isNotEmptyObject(updatedAccount)) {
      updateAccount(transformDataUpdatedAccount(updatedAccount))
    }
  }, [accountList])

  const annauncmentTitle = () => {
    return (
      <label>
        {' '}
        <span style={styleSpan}>* </span> Si desea hacer desembolsos, debe de tener una
        cuenta seleccionada.
      </label>
    )
  }

  return (
    <>
      <div id={'account-view'}>
        <Row>
          <Col xl={4} lg={4} md={12} sm={12} xs={12}>
            <Card title={'Listado de Cuentas'}>
              {annauncmentTitle()}

              <AccountList accounts={accounts} banks={banks} />
              <AccountButtons />
            </Card>
          </Col>
          <Col xl={8} lg={8} md={12} sm={12} xs={12}>
            <AccountMovementsContext.Provider value={movementsContextValue}>
              <OperationsContext.Provider value={operationsContextValue}>
                {accounnt.accountNumber !== undefined ? (
                  <Card title={'Detalle'} white>
                    <AccountTableList accountSelected={accounnt} />
                  </Card>
                ) : (
                  <Card title={'Detalle'} white>
                    <h3>Es necesario seleccionar una cuenta bancaria</h3>
                  </Card>
                )}
              </OperationsContext.Provider>
            </AccountMovementsContext.Provider>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AccountsView
