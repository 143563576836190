import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import Button from 'src/components/buttons/Button'
import Loading from 'src/components/Loading/Loading'
import Select from 'src/components/inputs/Select/CustomSelect'
import TableV2 from 'src/components/Pagination/PaginatedTableV2'
import { loadingSelector } from 'src/selectors/loading.selector'
import { actionTypes, getOrderVersionItems } from 'src/actions/orders.actions'
import {
  selectOrderVersionItems,
  selectOrderVersions,
} from 'src/selectors/orders.selector'
import { ItemSelectionTable } from 'src/components/Transaction/ItemSelectionTable'
import { itemTypes } from 'src/utils/utilities'

interface IProps {
  id: number
  show: boolean
  onHide: () => void
}

/**
 * Modal to show versions of order.
 * @param {number} id Order id
 * @param {boolean} show Show modal
 * @param {function} onHide Function to hide modal
 */
export const OrderHistorical = ({ id, show, onHide }: IProps) => {
  const dispatch = useDispatch()

  const versions: IOrderVersion[] = useSelector(selectOrderVersions)
  const versionDetails: IItemDetailToEdit[] = useSelector(selectOrderVersionItems)

  const loading = useSelector(state =>
    loadingSelector([actionTypes.GET_ORDER_VERSION])(state),
  )

  const [version, setVersion] = useState<IOrderVersion | null>(null)

  useEffect(() => {
    if (!loading && id && versions.length > 0) {
      setVersion(versions[versions.length - 1])
      dispatch(
        getOrderVersionItems({
          orderId: id,
          date: versions[versions.length - 1].date,
        }),
      )
    }
  }, [loading])

  return (
    <>
      <Modal show={show} size={'xl'} centered onHide={() => onHide()}>
        <Modal.Header closeButton>
          <Modal.Title>Historial de cambios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loading show={loading} />

          <Select
            dataCy={'select-version'}
            label={'Seleccionar una versión'}
            options={versions}
            value={version}
            onChange={(version: IOrderVersion) => {
              setVersion(version)
              dispatch(
                getOrderVersionItems({
                  orderId: id,
                  date: version.date,
                }),
              )
            }}
          />
          <TableV2
            /* @ts-expect-error JS */
            hideFilter
            items={version !== null ? [version] : []}
            headers={[
              {
                show: true,
                label: 'Titulo',
                value: ['version'],
                type: 'text',
                className: 'medium center',
              },
              {
                show: true,
                label: 'Fecha de actualización',
                value: ['date'],
                type: 'date',
                className: 'medium center',
              },
              {
                show: true,
                label: 'Modificado por',
                value: ['userData', 'name'],
                type: 'text',
                className: 'medium center',
              },
            ]}
          />
          <hr />
          <ItemSelectionTable
            type={itemTypes.SELL}
            balancePermission
            details={versionDetails}
            isEditParam
            forDisplay
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide} dataCy={'close'}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
