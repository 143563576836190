import React, {Component} from 'react';
import {connect} from "react-redux"

import Card from "../../../components/cards/Card";
import IconButton from "../../../components/buttons/IconButton";
import {formatDateFromMillis} from "../../../utils/formatters";

import {Col, Container, Modal, Row} from "react-bootstrap"
import {Td, Tr} from "react-super-responsive-table";
import {Link} from "react-router-relative-link";
import Alert from "sweetalert-react";

import {
    actionTypes as types,
    getAllOffers, getAvailableOffers,
    getContraOffersByProducer,
    getSingleOffer,
    getOfferArchived,
    onArchiveOffer, onArchiveOfferByList
} from "../../../actions/offers.actions";
import {selectContraOffers, selectOffer, selectOffers, selectaAchived} from "../../../selectors/offers.selector";

import {loadingSelector} from "../../../selectors/loading.selector";
import {hasErrorsSelector, singleErrorSelector, handlerSuccess, handlerError} from "../../../selectors/error.selector";

import {
    faFileAlt,
    faSync,
    faBook,
    faEye,
    faCheck, faWindowMinimize, faArchive, faTrashRestore
} from "@fortawesome/free-solid-svg-icons";
import {Handles, Rail, Slider, Tracks} from "react-compound-slider";
import {Handle, TooltipRail, Track} from "../../../components/slider/SliderComponents";
import FormTextField from "../../../components/inputs/FormTextField/FormTextField";
import CollapsibleCard from "../../../components/cards/collapsible-card/CollapsibleCard";
import ModalTable from "../../../components/ModalTable/ModalTable";

import moment from "moment";
import {
    selectCurrentCompany,
    selectCurrentModule
} from "../../../selectors/user.selector";

import LinkList from "./LinkList";
import CSVLoader, {CATEGORY_UPLOAD} from "../../../components/CSVLoader/CSVLoader";
import {loadProductsTransportationPaymentCSV} from "../../../actions/products.actions";
import { TableV2 } from '../../../components';

const sliderStyle = {
    position: 'relative',
    width: '100%',
}


class Offers extends Component {

    state = {
        withContract: true,
        withoutContract: true,
        showResume: false,
        resume: false,
        contra: false,
        productDetails: {
            show: false,
            productPriceDetail: []
        },
        domain: [1, 2],
        values: [0],
        update: [0],
        disabled: false,
        price: {minimum: 0, maximum: 0, price: 0},
        archived: {value: false, items: []},
        alert: {title: 'title'},
        selectedOffers: [],
    }

    componentDidMount() {
        this.props.getAllOffers(this.props.module);
    };

    componentWillReceiveProps(next) {
        const {loadingOffer} = this.props

        if(loadingOffer && !next.loadingOffer) this.state.showResume = true;

        const {loadingA, hasErrorA, loadingGA} = this.props;
        const {errorA, archived} = next;
        if (loadingA && !next.loadingA) {
            let alert = {title: 'title'};
            if (!hasErrorA && next.hasErrorA) {
                alert = handlerError(errorA.message || 'Ha ocurrido un error inesperado');
                alert.onConfirm = () => this.setState({alert: {...alert, show: false}});
            } else {
                this.props.getAllOffers(this.props.module);
                alert = handlerSuccess('Enlace archivado satisfactoriamente');
                alert.onConfirm = () => this.setState({alert: {...alert, show: false}, archived: {value: false, items: []}});
            }
            this.setState({alert});
        }

        if (loadingGA && !next.loadingGA) {
            if (archived && archived.length > 0)
                this.setState({archived: {value: true, items: archived}});
            else {
                let alert = handlerError('No hay enlaces archivados');
                alert.onConfirm = () => this.setState({alert: {...alert, show: false}});
                this.setState({alert});
            }
        }
    }

    isSelected = (item) =>{
        const {selectedOffers} = this.state
        let selected = selectedOffers.filter(o => o.id === item.id).length
        return selected > 0;
    }

    onSelectRow = (e, item) => {
        let select = false;
        if(!e.target.type && !e.target.href) select = true
        if(select) {
            const {selectedOffers} = this.state;
            const index = selectedOffers.findIndex(i => i.id === item.id)
            if (index === -1) selectedOffers.push(item)
            else selectedOffers.splice(index, 1)
            this.setState({selectedOffers});
        }
    }

    getHeaders = () => {
        return [
            
            {
                label: '', show: true, value: [], type: 'text', className: 'mini', select: true,
                custom: (item) =>
                    item.contractId ?
                        <IconButton tooltip={'Contratado'} icon={faCheck} color={'green'} />
                        :
                        <IconButton tooltip={'Sin contrato'} icon={faWindowMinimize} color={'red'} />
            },
            { label: 'Código', show: true, value: ['correlative'], type: 'text', className: 'mini' },
            { label: 'Compañia', show: true, value: ['companyName'], type: 'text', className: 'mini' },
            { label: 'Nombre', show: true, value: ['alias'], type: 'text' ,  className:'mini'},
            { label: 'Descripción', show: true, value: ['notes'], type: 'text', className: 'mini' },
            { label: 'Disponible hasta', show: true, value: ['availableUntil'], type: 'date', className: 'mini' },
            {
                config: true, show: true, label: '', className: 'mini center',
                custom: (item) => <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                    {/*
            <IconButton onClick={() => this.goToResume(item)} tooltip={'Resumen'} icon={faFileAlt}/>
            <IconButton onClick={() => this.goToContra(item)} tooltip={'Contraofertas'} icon={faEye}/>
            */}
                    <IconButton onClick={() => this.goTo(item, '/detalles')} tooltip={'Ver detalles'} icon={faBook} />
                    <IconButton style={{ marginLeft: '5px' }} onClick={() => this.props.onArchiveOffer(item.id)} tooltip={'Archivar'} icon={faArchive} />
                    {/*<IconButton onClick={() => this.goTo(item, '/editar')} tooltip={'Editar'} icon={faEdit}/> */}
                </div>
            }
        ]
    }


    goToResume = (item) =>{
        this.setState({resume: true})
        this.props.getOffer(item.id);
    }

    goToContra = (item) =>{
        this.setState({contra: true});
        this.props.getOffer(item.id);
        this.props.getContraOffers(item.id);
    }

    goTo = (item, route) => {
        const {history, match} = this.props;
        const url = `${match.url}${route}/${item.id}`;
        history.push(url);
    };

    button = () => {
        return (
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                <IconButton
                    tooltip={'Actualizar'} icon={faSync}
                    onClick={() => this.props.getAllOffers(this.props.module)}
                />
                <IconButton
                    loading={this.props.loadingGA}
                    style={{marginLeft: '5px'}}
                    tooltip={'Archivadas'}
                    icon={faArchive}
                    onClick={() => this.props.getOfferArchived()}
                />
            </div>
        );
    };

    seePricesDetails = (item) => {
        const productDetails = Object.assign({}, {...item, show: true});
        const length = productDetails.productPriceDetail.length;
        let disabled = false;
        let values = [1];
        let update = [1]
        let domain = [1, length];
        if (length === 1) {
            disabled = true
            domain = [0, length]
        };
        const price = productDetails.productPriceDetail[0];
        this.setState({productDetails, domain, disabled, values, update, price});
    };

    onChange = (values) => {
        const {productDetails} = this.state;
        const index = (values[0] - 1);
        const price = productDetails.productPriceDetail[index];
        this.setState({values, price});
    };

    onUpdate = (update) => {
        const {productDetails} = this.state;
        const index = (update[0] - 1);
        const price = productDetails.productPriceDetail[index];
        this.setState({update, price});
    };


    

    filter = (item) => {
        const {withContract, withoutContract} = this.state;
        const {company, module} = this.props;


        let contracted = false;

        if (module === 1000) {
            if (item.contractData) {
                if (item.contractData.company2Id === company) contracted = true;
            }
        } else if (item.contractData) contracted = true;

        return (contracted && withContract) || (!item.contractId && withoutContract)
    };

    handleCheckBoxChange = (event) => {
        const {name, checked} = event.target;
        this.setState({[name]: checked})
    };

    saveTransportationProductsCSV = (file, onSuccess, param, polygonId) => {
        this.props.uploadTransportationCSV(file,onSuccess, polygonId);
    };

    render() {
        const {offers, loading, offer, contraOffers, loadingContra, module} = this.props;
        const {showResume, productDetails, domain, values, disabled, price, resume, contra, withContract,
            withoutContract, archived, selectedOffers} = this.state;

        return (
            <div style={{marginBottom: 50}}>
                <Row>
                    <Col md={4}>
                        {
                            module===2000 &&
                            <CSVLoader
                                category={CATEGORY_UPLOAD.TRANSPORTATION}
                                title={'Cargar productos para transporte'}
                                onUpload={this.saveTransportationProductsCSV}
                            />
                        }
                        <Row>
                            <Col md={12}>
                                <CollapsibleCard title={'Filtros'} startCollapsed={true}>
                                    <h6>Contratos</h6>
                                    <Row>
                                        <Col md={6}>
                                            <label className={'checkbox-label'}>
                                                <input type="checkbox" checked={withContract} name="withContract" onChange={this.handleCheckBoxChange}/> Contratados
                                            </label>
                                        </Col>
                                        <Col md={6}>

                                            <label className={'checkbox-label'}>
                                                <input type="checkbox" checked={withoutContract} name="withoutContract" onChange={this.handleCheckBoxChange}/> Sin contato
                                            </label>
                                        </Col>
                                    </Row>
                                </CollapsibleCard>
                            </Col>
                            {
                                module === 2000 &&
                                <Col md={12}>
                                    <Link className={'big-button'} to={'nueva'}>Nueva Oferta</Link>
                                </Col>
                            }
                            {
                                selectedOffers.length > 0 &&
                                <div className={'big-button'} onClick={() => {
                                    this.props.onArchiveOfferByList(selectedOffers.map(o => {return o.id}))
                                }}>
                                    Archivar Ofertas
                                </div>
                            }
                        </Row>
                    </Col>
                    <Col>
                        <Card title={'Enlaces'} button={this.button()}>
                            <TableV2
                                headers = {this.getHeaders()}
                                items={offers.filter(this.filter).sort((a, b) => (b.createdAt - a.createdAt))}
                                loading={loading}
                                mobileAuto
                                storageKey={`offersProduction`}
                                isSelected ={(item)=> this.isSelected(item)}
                                onClickTr={(e,item)=> this.onSelectRow(e, item)}
                            />
                        </Card>
                    </Col>
                </Row>
                {/*********************************************************************
                 *********************MODAL DETALLE Y CONTRAOFERTA**********************
                 **********************************************************************/}
                <Modal
                    show={showResume}
                    size={'lg'}
                    centered
                    aria-labelledby={'contained-modal-title-vcenter'}
                    onHide ={() => this.setState({showResume: false, resume: false, contra: false})}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Oferta: {offer.alias}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container className="invoice">
                            <Row>
                                <Col><h4><b>Compañia: </b>{offer.companyName ? offer.companyName : 'N/A'}</h4></Col>
                            </Row>

                            <Row>
                                <Col><h4><b>Correlativo: </b>{offer.correlative ? offer.correlative : 'N/A'}</h4></Col>
                            </Row>

                            <Row><Col><br/></Col></Row>

                            <Row className="text-center">
                                <Col><b>Disponible hasta</b><br/> {formatDateFromMillis(offer.availableUntil)}</Col>
                                <Col><b>Fecha Expiración</b> <br/>{formatDateFromMillis(offer.expirationDate)}</Col>
                                <Col><b>Ubicación</b> <br/>{offer.polygonName}</Col>
                            </Row>

                            <Row><Col><br/></Col></Row>

                            <Row>
                                <Col><b>Descripcion: </b> {offer.notes}</Col>
                            </Row>

                            <Row><Col><br/></Col></Row>

                            <hr/>
                            {
                                resume &&
                                <Col>
                                    <TableV2
                                        headers={[
                                            { label: 'Nombre', show: true, value: ['productName'], type: 'text', className: 'mini' },
                                            {
                                                config: true, show: true, label: '', className: 'mini center',
                                                custom: (item) => <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                                    <IconButton onClick={() => this.seePricesDetails(item)} tooltip={'Ver precios'} icon={faFileAlt} />
                                                </div>
                                            }
                                        ]}
                                        items={offer.productList}
                                        loading={loading}
                                        mobileAuto
                                    />
                                </Col>
                            }
                            {
                                contra &&
                                <Col>
                                    <TableV2
                                        headers={[
                                            { label: 'Transportista', show: true, value: ['shipperName'], type: 'text', className: 'medium' },
                                            { label: 'Propuesto', show: true, value: ['createdAt'], type: 'date', className: 'medium' },
                                            {
                                                config: true, show: true, label: '', className: 'mini center',
                                                custom: (item) =>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                                        <IconButton linkRoute={`response/${item.id}`} linkProps={{ target: '_blank' }} tooltip={'Ver respuesta'} icon={faEye} />
                                                    </div>
                                            }
                                        ]}
                                        items={contraOffers.sort((a, b) => (b.createdAt - a.createdAt))}
                                        loading={loadingContra}
                                        noItemsLegend={'No hay contra ofertas.'}
                                        mobileAuto
                                        storageKey={`contraOffer`}

                                    />
                                </Col>
                            }
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
                {/*********************************************************************
                 ****************************MODAL PRODUCTOS****************************
                 **********************************************************************/}
                <Modal
                    show={productDetails.show}
                    size={'md'}
                    centered
                    onHide={() => this.setState({productDetails: {...productDetails, show: false}})}>

                    <Modal.Header closeButton>
                        <Modal.Title>Precios de {productDetails.productName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{margin: 3}} className={'column'}>
                            <br/><br/>
                            <Slider
                                disabled={disabled}
                                step={1}
                                domain={domain}
                                rootStyle={sliderStyle}
                                onUpdate={this.onUpdate}
                                onChange={this.onChange}
                                values={values}
                            >
                                <Rail>{railProps => <TooltipRail {...railProps} />}</Rail>
                                <Handles>
                                    {({ handles, activeHandleID, getHandleProps }) => (
                                        <div className="slider-handles">
                                            {handles.map(handle => (
                                                <Handle
                                                    key={handle.id}
                                                    disabled={disabled}
                                                    handle={handle}
                                                    domain={domain}
                                                    isActive={handle.id === activeHandleID}
                                                    getHandleProps={getHandleProps}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </Handles>
                                <Tracks right={false}>
                                    {({ tracks, getTrackProps }) => (
                                        <div className="slider-tracks">
                                            {tracks.map(({ id, source, target }) => (
                                                <Track
                                                    key={id}
                                                    disabled={disabled}
                                                    source={source}
                                                    target={target}
                                                    getTrackProps={getTrackProps}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </Tracks>
                            </Slider>
                            <br/><br/>

                            <div className={'container-offer column'}>
                                <Row>
                                    <Col xl={7} lg={7} md={7} sm={7} xs={8}>
                                        <div className={'container-offer'}>
                                            <div className={'center-offer'}>
                                                <span><strong>Unidades</strong></span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl={5} lg={5} md={5} sm={5} xs={4}>
                                        <div className={'container-offer'}>
                                            <div className={'center-offer'}>
                                                <span><strong>Precios</strong></span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={7} lg={7} md={7} sm={7} xs={8}>
                                        <div className={'container-offer'}>
                                            <div className={'flex-end-offer'}>
                                                <div>
                                                    <FormTextField
                                                        disabled
                                                        mt={0}
                                                        type={'number'}
                                                        name={'minimum'}
                                                        onChange={() => console.log('value')}
                                                        value={price.minimum}
                                                    />
                                                </div>
                                                <div className={'mt-4'}><strong>{'<'}=={'>'}</strong></div>
                                                <div>
                                                    <FormTextField
                                                        disabled
                                                        mt={0}
                                                        type={'number'}
                                                        name={'maximum'}
                                                        onChange={() => console.log('value')}
                                                        value={price.maximum}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl={5} lg={5} md={5} sm={5} xs={4}>
                                        <div className={'container-offer'}>
                                            <div className={'flex-start-offer'}>
                                                <div>
                                                    <FormTextField
                                                        disabled
                                                        mt={0}
                                                        id={'price'}
                                                        currency
                                                        name={'price'}
                                                        onChange={() => console.log('value')}
                                                        value={price.price}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <br/><br/>
                        <CollapsibleCard title={'Mostrar lista'} startCollapsed={true}>
                            {
                                productDetails.productPriceDetail.map((p) =>
                                    <Row key={p.id}>
                                        <Col xl={7} lg={7} md={7} sm={7} xs={8}>
                                            <div className={'container-offer'}>
                                                <div className={'flex-end-offer'}>
                                                    <div>
                                                        <FormTextField
                                                            disabled
                                                            mt={0}
                                                            type={'number'}
                                                            name={'minimum'}
                                                            onChange={() => console.log('value')}
                                                            value={p.minimum}
                                                        />
                                                    </div>
                                                    <div className={'mt-4'}><strong>{'<'}=={'>'}</strong></div>
                                                    <div>
                                                        <FormTextField
                                                            disabled
                                                            mt={0}
                                                            type={'number'}
                                                            name={'maximum'}
                                                            onChange={() => console.log('value')}
                                                            value={p.maximum}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={5} lg={5} md={5} sm={5} xs={4}>
                                            <div className={'container-offer'}>
                                                <div className={'flex-start-offer'}>
                                                    <div>
                                                        <FormTextField
                                                            disabled
                                                            mt={0}
                                                            id={'price'}
                                                            currency
                                                            name={'price'}
                                                            onChange={() => console.log('value')}
                                                            value={p.price || 0.00}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                )
                            }
                        </CollapsibleCard>
                    </Modal.Body>
                </Modal>

                <ModalTable
                    show={archived.value}
                    title={'Enlaces archivados'}
                    size={'lg'}
                    onHide={() => this.setState({archived: {value: false, items: []}})}
                    items={archived.items}
                    headers={['#', 'Alias', 'Categoria', 'Actualizada por', '']}
                    renderRow={(item, index) =>
                        <Tr className={'data'} key={index}>
                            <Td className={'mini'}>{item.correlative}</Td>
                            <Td className={'medium'}>{item.alias}</Td>
                            <Td className={'mini'}>{item.category.name}</Td>
                            <Td className={'medium'}>{item.updatedData && item.updatedData.name}</Td>
                            <Td className={'mini'}><IconButton
                                icon={faTrashRestore}
                                tooltip={'Recuperar'}
                                onClick={() => this.props.onArchiveOffer(item.id)}
                            /></Td>
                        </Tr>
                    }
                />

                <Alert {...this.state.alert}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    offers: selectOffers(state),
    loading: loadingSelector([types.GET_ALL_OFFERS])(state),
    hsaError: hasErrorsSelector([types.GET_ALL_OFFERS])(state),
    error: singleErrorSelector([types.GET_ALL_OFFERS])(state),
    //offer detail
    offer: selectOffer(state),
    contraOffers: selectContraOffers(state),
    loadingOffer: loadingSelector([types.GET_SINGLE_OFFER])(state),
    loadingContra: loadingSelector([types.GET_ALL_CONTRA_OFFER])(state),
    company: selectCurrentCompany(state),
    module: selectCurrentModule(state),
    loadingA: loadingSelector([types.ON_ARCHIVE])(state),
    hasErrorA: hasErrorsSelector([types.ON_ARCHIVE])(state),
    errorA: singleErrorSelector([types.ON_ARCHIVE])(state),
    loadingGA: loadingSelector([types.ARCHIVED])(state),
    archived: selectaAchived(state),
    handlerError: (message) => handlerError(message),
    handlerSuccess: (message) => handlerSuccess(message),
});
const mapDispatchToProps = dispatch => ({
    getAllOffers: (module) => {
        if (module === 2000)
            dispatch(getAllOffers());
        else if (module === 1000)
            dispatch(getAvailableOffers());
        else if (module === 11000)
            dispatch(getAvailableOffers(5));
    },
    getOffer: (id) => dispatch(getSingleOffer(id)),
    getContraOffers: (id) => dispatch(getContraOffersByProducer(id)),
    getOfferArchived: () => dispatch(getOfferArchived ()),
    onArchiveOffer: (id) => dispatch(onArchiveOffer(id)),
    onArchiveOfferByList: (list) => dispatch(onArchiveOfferByList(list)),
    uploadTransportationCSV: (file, onSuccessLoadFile, polygonId) => dispatch(loadProductsTransportationPaymentCSV(file, onSuccessLoadFile, polygonId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LinkList);
