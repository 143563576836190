import OffersServices from '../services/offers.services'
import { executeAction, actionError } from './global.actions'
import { types } from './utilities.actions'

export const actionTypes = {
  GET_ALL_OFFERS: 'GET_ALL_OFFERS',
  GET_ALL_OFFERS_REQUEST: 'GET_ALL_OFFERS_REQUEST',
  GET_ALL_OFFERS_SUCCESS: 'GET_ALL_OFFERS_SUCCESS',
  GET_ALL_OFFERS_ERROR: 'GET_ALL_OFFERS_ERROR',

  GET_SINGLE_OFFER: 'GET_SINGLE_OFFER',
  GET_SINGLE_OFFER_REQUEST: 'GET_SINGLE_OFFER_REQUEST',
  GET_SINGLE_OFFER_SUCCESS: 'GET_SINGLE_OFFER_SUCCESS',
  GET_SINGLE_OFFER_ERROR: 'GET_SINGLE_OFFER_ERROR',

  ON_CREATE_OFFER: 'ON_CREATE_OFFER',
  ON_CREATE_OFFER_REQUEST: 'ON_CREATE_OFFER_REQUEST',
  ON_CREATE_OFFER_SUCCESS: 'ON_CREATE_OFFER_SUCCESS',
  ON_CREATE_OFFER_ERROR: 'ON_CREATE_OFFER_ERROR',

  ON_UPDATE_OFFER: 'ON_UPDATE_OFFER',
  ON_UPDATE_OFFER_REQUEST: 'ON_UPDATE_OFFER_REQUEST',
  ON_UPDATE_OFFER_SUCCESS: 'ON_UPDATE_OFFER_SUCCESS',
  ON_UPDATE_OFFER_ERROR: 'ON_UPDATE_OFFER_ERROR',

  ON_ACCEPT_OFFER: 'ON_ACCEPT_OFFER',
  ON_ACCEPT_OFFER_REQUEST: 'ON_ACCEPT_OFFER_REQUEST',
  ON_ACCEPT_OFFER_SUCCESS: 'ON_ACCEPT_OFFER_SUCCESS',
  ON_ACCEPT_OFFER_ERROR: 'ON_ACCEPT_OFFER_ERROR',

  GET_SINGLE_CONTRA_OFFER: 'GET_SINGLE_CONTRA_OFFER',
  GET_SINGLE_CONTRA_OFFER_REQUEST: 'GET_SINGLE_CONTRA_OFFER_REQUEST',
  GET_SINGLE_CONTRA_OFFER_SUCCESS: 'GET_SINGLE_CONTRA_OFFER_SUCCESS',
  GET_SINGLE_CONTRA_OFFER_ERROR: 'GET_SINGLE_CONTRA_OFFER_ERROR',

  GET_ALL_CONTRA_OFFER: 'GET_ALL_CONTRA_OFFER',
  GET_ALL_CONTRA_OFFER_REQUEST: 'GET_ALL_CONTRA_OFFER_REQUEST',
  GET_ALL_CONTRA_OFFER_SUCCESS: 'GET_ALL_CONTRA_OFFER_SUCCESS',
  GET_ALL_CONTRA_OFFER_ERROR: 'GET_ALL_CONTRA_OFFER_ERROR',

  ON_CREATE_CONTRA_OFFER: 'ON_CREATE_CONTRA_OFFER',
  ON_CREATE_CONTRA_OFFER_REQUEST: 'ON_CREATE_CONTRA_OFFER_REQUEST',
  ON_CREATE_CONTRA_OFFER_SUCCESS: 'ON_CREATE_CONTRA_OFFER_SUCCESS',
  ON_CREATE_CONTRA_OFFER_ERROR: 'ON_CREATE_CONTRA_OFFER_ERROR',

  ON_ACCEPT_PRODUCER_CONTRA_OFFER: 'ON_ACCEPT_PRODUCER_CONTRA_OFFER',
  ON_ACCEPT_PRODUCER_CONTRA_OFFER_REQUEST: 'ON_ACCEPT_PRODUCER_CONTRA_OFFER_REQUEST',
  ON_ACCEPT_PRODUCER_CONTRA_OFFER_SUCCESS: 'ON_ACCEPT_PRODUCER_CONTRA_OFFER_SUCCESS',
  ON_ACCEPT_PRODUCER_CONTRA_OFFER_ERROR: 'ON_ACCEPT_PRODUCER_CONTRA_OFFER_ERROR',

  CHANGE_STATUS_OFFER: 'CHANGE_STATUS_OFFER',
  OFFER_CATEGORIES: 'OFFER_CATEGORIES',
  SET_STEPS: 'SET_STEPS',
  ARCHIVED: 'ARCHIVED',
  ON_ARCHIVE: 'ON_ARCHIVE',
  ON_UPDATE_SETTING: 'ON_UPDATE_SETTING',
  GET_POLYGON_CONTRACTED: 'GET_POLYGON_CONTRACTED',
  GET_LINK_CONTRACTED: 'GET_LINK_CONTRACTED',
  GET_LINK_AVAILABLE: 'GET_LINK_AVAILABLE',
  GET_LINK_KOLO_AVAILABLE: 'GET_LINK_KOLO_AVAILABLE',
  GET_LINK_REQUEST: 'GET_LINK_REQUEST',
  GET_LINK_PROFILE: 'GET_LINK_PROFILE',
  GET_LINK_SINGLE: 'GET_LINK_SINGLE',
  ON_SEND_REQUEST_LINK: 'ON_SEND_REQUEST_LINK',
  ON_SEND_REQUEST_SETTING: 'ON_SEND_REQUEST_SETTING',
  CHANGE_VALUES: 'CHANGE_VALUES',
  ON_SET_PARCELS: 'ON_SET_PARCELS',
  ON_UPDATE_LINK: 'ON_UPDATE_LINK',
  GET_LINK_TYPE_AVAILABLE: 'GET_LINK_TYPE_AVAILABLE',
  GET_LINK_BALANCE: 'GET_LINK_BALANCE',
  ON_VALIDATE_PRODUCT: 'ON_VALIDATE_PRODUCT',
  GET_NEAREST_DEALER: 'GET_NEAREST_DEALER',
  EXISTENCE: 'EXISTENCE',
  ON_CANCEL_OFFER: 'ON_CANCEL_OFFER',
  GET_LINKS_BY_COMPANY: 'GET_LINKS_BY_COMPANY',
}

const onAcceptProducerContraOfferRequest = () => ({
  type: actionTypes.ON_ACCEPT_PRODUCER_CONTRA_OFFER_REQUEST,
})
const onAcceptProducerContraOfferSuccess = response => ({
  type: actionTypes.ON_ACCEPT_PRODUCER_CONTRA_OFFER_SUCCESS,
  response,
})
const onAcceptProducerContraOfferError = error => ({
  type: actionTypes.ON_ACCEPT_PRODUCER_CONTRA_OFFER_ERROR,
  error,
})

const onCreateContraOfferRequest = () => ({
  type: actionTypes.ON_CREATE_CONTRA_OFFER_REQUEST,
})
const onCreateContraOfferSuccess = response => ({
  type: actionTypes.ON_CREATE_CONTRA_OFFER_SUCCESS,
  response,
})
const onCreateContraOfferError = error => ({
  type: actionTypes.ON_CREATE_CONTRA_OFFER_ERROR,
  error,
})

const getAllContraOfferRequest = () => ({
  type: actionTypes.GET_ALL_CONTRA_OFFER_REQUEST,
})
const getAllContraOfferSucces = response => ({
  type: actionTypes.GET_ALL_CONTRA_OFFER_SUCCESS,
  response,
})
const getAllContraOfferError = error => ({
  type: actionTypes.GET_ALL_CONTRA_OFFER_ERROR,
  error,
})

const getSingleContraOfferSucces = response => ({
  type: actionTypes.GET_SINGLE_CONTRA_OFFER_SUCCESS,
  response,
})

const onAcceptOfferRequest = () => ({
  type: actionTypes.ON_ACCEPT_OFFER_REQUEST,
})
const onAcceptOfferSuccess = response => ({
  type: actionTypes.ON_ACCEPT_OFFER_SUCCESS,
  response,
})
const onAcceptOfferError = error => ({
  type: actionTypes.ON_ACCEPT_OFFER_ERROR,
  error,
})

const onUpdateOfferSuccess = response => ({
  type: actionTypes.ON_UPDATE_OFFER_SUCCESS,
  response,
})

const onCreateOfferRequest = () => ({
  type: actionTypes.ON_CREATE_OFFER_REQUEST,
})
const onCreateOfferSuccess = response => ({
  type: actionTypes.ON_CREATE_OFFER_SUCCESS,
  response,
})
const onCreateOfferError = error => ({
  type: actionTypes.ON_CREATE_OFFER_ERROR,
  error,
})

const getAllOffersRequest = () => ({
  type: actionTypes.GET_ALL_OFFERS_REQUEST,
})
const getAllOffersSuccess = offers => ({
  type: actionTypes.GET_ALL_OFFERS_SUCCESS,
  offers,
})
const getAllOffersError = error => ({
  type: actionTypes.GET_ALL_OFFERS_ERROR,
  error,
})

const getSingleOfferRequest = () => ({
  type: actionTypes.GET_SINGLE_OFFER_REQUEST,
})
const getSingleOfferSuccess = offer => ({
  type: actionTypes.GET_SINGLE_OFFER_SUCCESS,
  offer,
})
const getSingleOfferError = error => ({
  type: actionTypes.GET_SINGLE_OFFER_ERROR,
  error,
})

const setStepsSuccess = steps => ({ type: actionTypes.SET_STEPS, steps })

const setArchiveResponse = response => ({
  type: 'ON_ARCHIVE_SUCCESS',
  archived: response,
})

export const getPriceOfProduct = (producerId, shipperId, productId) => async () => {
  const response = await OffersServices.getPriceOfProduct(
    producerId,
    shipperId,
    productId,
  )
  console.log(response)
}

export const onAcceptProducerContraOffer = contraOfferId => async dispatch => {
  dispatch(onAcceptProducerContraOfferRequest())
  try {
    const response = await OffersServices.onAcceptProduceroffer(contraOfferId)
    dispatch(onAcceptProducerContraOfferSuccess(response))
  } catch (e) {
    dispatch(onAcceptProducerContraOfferError(e))
  }
}

export const getContraOffersByProducer = idOffer => async dispatch => {
  dispatch(getAllContraOfferRequest())
  try {
    const response = await OffersServices.getContraOffersByProducer(idOffer)
    dispatch(getAllContraOfferSucces(response))
  } catch (e) {
    dispatch(getAllContraOfferError(e))
  }
}

export const onCreateNewContraOffer = contraOffer => async dispatch => {
  dispatch(onCreateContraOfferRequest())
  try {
    const response = await OffersServices.onCreateNewContraOffer(contraOffer)
    dispatch(onCreateContraOfferSuccess(response))
  } catch (e) {
    dispatch(onCreateContraOfferError(e))
  }
}

export const setInformationToContraOffer = offer => dispatch => {
  dispatch(getSingleContraOfferSucces(offer))
}

export const getAvailableOffers = category => async dispatch => {
  dispatch(getAllOffersRequest())
  try {
    const offers = await OffersServices.getAvailableOffers(category)
    dispatch(getAllOffersSuccess(offers))
  } catch (e) {
    console.log('action', e)
    dispatch(getAllOffersError(e))
  }
}

export const acceptOffer = (offer, setting) => async dispatch => {
  dispatch(onAcceptOfferRequest())
  try {
    const response = await OffersServices.onAcceptOffer(offer, setting)
    dispatch(onAcceptOfferSuccess(response))
  } catch (e) {
    dispatch(onAcceptOfferError(e))
  }
}

export const onUpdateOffer = (id, offer) => dispatch => {
  const process = async () => ({
    responseUpdate: await OffersServices.onUpdateOffer(id, offer),
  })
  dispatch(executeAction(actionTypes.ON_UPDATE_OFFER, process))
}

export const onResetUpdate = () => dispatch => {
  dispatch(onUpdateOfferSuccess({}))
}

export const onCreateOffer = offer => async dispatch => {
  dispatch(onCreateOfferRequest())
  try {
    const response = await OffersServices.onCreateOffer(offer)
    dispatch(onCreateOfferSuccess(response))
  } catch (e) {
    dispatch(onCreateOfferError(e))
  }
}

export const setInformationToOffer = offer => dispatch => {
  dispatch(getSingleOfferSuccess(offer))
}

export const getAllOffers = () => async dispatch => {
  dispatch(getAllOffersRequest())
  try {
    const offers = await OffersServices.getAllOffers()
    dispatch(getAllOffersSuccess(offers))
  } catch (e) {
    console.log('action', e)
    dispatch(getAllOffersError(e))
  }
}

export const getSingleOffer = id => async dispatch => {
  dispatch(getSingleOfferRequest())
  try {
    const offer = await OffersServices.getSingleOffer(id)
    dispatch(getSingleOfferSuccess(offer))
  } catch (e) {
    console.log('action', e)
    dispatch(getSingleOfferError(e))
  }
}

export const onChangeOfferStatus = (offerId, statusId, params, module) => dispatch => {
  const process = async () => ({
    change: await OffersServices.onChangeOfferStatus(offerId, statusId, params, module),
  })
  dispatch(executeAction(actionTypes.CHANGE_STATUS_OFFER, process))
}

export const getOfferCategories = () => dispatch => {
  const process = async () => ({
    categories: await OffersServices.getOfferCategories(),
  })
  dispatch(executeAction(actionTypes.OFFER_CATEGORIES, process))
}

export const setSteps = steps => dispatch => {
  dispatch(setStepsSuccess(steps))
}

export const getOfferArchived = () => dispatch => {
  const process = async () => ({
    archived: await OffersServices.getArchived(),
  })
  dispatch(executeAction(actionTypes.ARCHIVED, process))
}

export const onArchiveOffer = id => dispatch => {
  const process = async () => ({
    archived: await OffersServices.onArchive(id),
  })
  dispatch(executeAction(actionTypes.ON_ARCHIVE, process))
}

export const setArchiveOffer = data => dispatch => dispatch(setArchiveResponse(data))

export const onArchiveOfferByList = list => dispatch => {
  const promises = []
  list.map(async l => {
    promises.push(OffersServices.onArchive(l))
  })
  return Promise.all(promises)
    .then(() => {
      dispatch(executeAction(types.ON_ARCHIVE))
    })
    .catch(() => actionError(types.ON_ARCHIVE, { message: 'Error al archivar' }))
}

export const onUpdateSetting = (id, request) => dispatch => {
  const process = async () => ({
    setting: await OffersServices.onUpdateSetting(id, request),
  })
  dispatch(executeAction(actionTypes.ON_UPDATE_SETTING, process))
}

export const getPolygonContracted = productId => dispatch => {
  const process = async () => ({
    polygonContracted: await OffersServices.getPolygonContracted(productId),
  })
  dispatch(executeAction(actionTypes.GET_POLYGON_CONTRACTED, process))
}

export const getLinkContracted = (type, module) => dispatch => {
  const process = async () => ({
    linkContracted: await OffersServices.getCompaniesLinked(type, {}, module),
  })
  dispatch(executeAction(actionTypes.GET_LINK_CONTRACTED, process))
}

export const getLinkAvailable = module => dispatch => {
  const process = async () => ({
    linkAvailable: await OffersServices.getCompaniesLinked(1, {}, module),
  })
  dispatch(executeAction(actionTypes.GET_LINK_AVAILABLE, process))
}

export const getLinkKoloAvailable = module => dispatch => {
  const process = async () => ({
    linkKoloAvailable: await OffersServices.getCompaniesLinked(2, {}, module),
  })
  dispatch(executeAction(actionTypes.GET_LINK_KOLO_AVAILABLE, process))
}

export const getLinkRequest = module => dispatch => {
  const process = async () => ({
    linksRequest: await OffersServices.getCompaniesLinked(5, {}, module),
  })
  dispatch(executeAction(actionTypes.GET_LINK_REQUEST, process))
}

export const getLinksByCompany = (companyId, module) => dispatch => {
  const process = async () => ({
    companyLinks: await OffersServices.getCompaniesLinked(8, { companyId }, module),
  })
  dispatch(executeAction(actionTypes.GET_LINKS_BY_COMPANY, process))
}

export const getLinkProfile = (company, type, linkType) => dispatch => {
  const process = async () => ({
    linkProfile: await OffersServices.getLinkProfile(company, type, linkType),
  })
  dispatch(executeAction(actionTypes.GET_LINK_PROFILE, process))
}

const setChangeSingleLink = linkSingle => ({
  type: 'GET_LINK_SINGLE_SUCCESS',
  linkSingle,
})

export const setChangeLink = linkSingle => async dispatch => {
  dispatch(setChangeSingleLink(linkSingle))
}

export const getSingleLink = (zoneId, type) => dispatch => {
  const process = async () => ({
    linkSingle: await OffersServices.getSingleLink(zoneId, type),
  })
  dispatch(executeAction(actionTypes.GET_LINK_SINGLE, process))
}

export const onSendRequestLink = (request, type, module) => dispatch => {
  const process = async () => ({
    linkSingle: await OffersServices.onSendRequestLink(request, type, module),
  })
  dispatch(executeAction(actionTypes.ON_SEND_REQUEST_LINK, process))
}

export const onSendRequestSettingLInk = (offerId, request, module) => dispatch => {
  const process = async () => ({
    settingResponse: await OffersServices.onSendRequestSettingLInk(
      offerId,
      request,
      module,
    ),
  })
  dispatch(executeAction(actionTypes.ON_SEND_REQUEST_SETTING, process))
}

export const onSetMaxQuantity = (products, companyId, params, module) => dispatch => {
  const process = async () => ({
    maxResponse: await OffersServices.onSetMaxQuantity(
      products,
      companyId,
      params,
      module,
    ),
  })
  dispatch(executeAction(actionTypes.CHANGE_VALUES, process))
}

export const onSetParcel = (companyLinked, request, params, module) => dispatch => {
  const process = async () => ({
    setParcelResponse: await OffersServices.onSetParcel(
      companyLinked,
      request,
      params,
      module,
    ),
  })
  dispatch(executeAction(actionTypes.ON_SET_PARCELS, process))
}

export const onUpdateLink = (linkId, request) => dispatch => {
  const process = async () => ({
    linkResponseU: await OffersServices.onUpdateLink(linkId, request),
  })
  dispatch(executeAction(actionTypes.ON_UPDATE_LINK, process))
}

export const getTypesAvailable = (companyLinked, module) => dispatch => {
  const process = async () => ({
    typesAvailable: await OffersServices.getTypesAvailable(companyLinked, module),
  })
  dispatch(executeAction(actionTypes.GET_LINK_TYPE_AVAILABLE, process))
}

export const getOfferLinkBalance = (companyId, linkType) => dispatch => {
  const process = async () => ({
    linkBalance: await OffersServices.getOfferLinkBalance(companyId, linkType),
  })
  dispatch(executeAction(actionTypes.GET_LINK_BALANCE, process))
}

export const onValidateProduct = (productId, module) => dispatch => {
  const process = async () => ({
    validate: await OffersServices.onValidateProduct(productId, module),
  })
  dispatch(executeAction(actionTypes.ON_VALIDATE_PRODUCT, process))
}

export const getNearestDealer = (orderId, distance) => dispatch => {
  const process = async () => ({
    dealer: await OffersServices.getNearestDealer(orderId, distance),
  })
  dispatch(executeAction(actionTypes.GET_NEAREST_DEALER, process))
}

export const getInventoryExistence = (linkId, productId) => dispatch => {
  const process = async () => ({
    existence: await OffersServices.getInventoryExistence(linkId, productId),
  })
  dispatch(executeAction(actionTypes.EXISTENCE, process))
}

export const onCancelOffer = (offerId, module) => dispatch => {
  const process = async () => ({
    cancel: await OffersServices.onCancelOfferZone(offerId, module),
  })
  dispatch(executeAction(actionTypes.ON_CANCEL_OFFER, process))
}
