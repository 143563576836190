import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Line } from 'react-chartjs-2'
import { Col, Row, Modal, ProgressBar } from 'react-bootstrap'
import {
  Card,
  Dropdown,
  CustomDate as CustomDatePicker,
  Select as CustomSelect,
  Button,
  Icon,
  Folder,
  Empty,
} from 'src/components'
import {
  faCalendarTimes,
  faFileExcel,
  faSearch,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes as typeP,
  getChart,
  getExcelByReport,
  getProductsSimple,
  getProductsToChart,
  getProductsToChartCategory,
  getReport,
  historicExcel,
} from 'src/actions/products.actions'
import {
  selectProductListReport,
  selectProductReport,
  selectProductsCategories,
  selectProductsSimple,
  selectProductToChart,
} from 'src/selectors/products.selector'

import { selectAllCategorizations } from 'src/selectors/categorizations.selector'
import { getAllCategorizations } from 'src/actions/categorization.actions'

import { loadingSelector } from 'src/selectors/loading.selector'

import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import { showAlert } from 'src/actions/alert.actions'
import { toMoney } from 'src/utils/utilities'

const chartOptions = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          callback: function (label) {
            return label > 999999
              ? label / 100000000 + 'M'
              : label > 999
              ? label / 1000 + 'k'
              : label
          },
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || ''
        let formatCurrency = amount => {
          if (amount === null) return ''
          return toMoney(amount)
        }

        if (label) {
          label += ': '
        }
        label += formatCurrency(tooltipItem.yLabel)
        return label
      },
    },
  },
}

const chartOptionsMobile = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          callback: function (label) {
            return label > 999999
              ? label / 100000000 + 'M'
              : label > 999
              ? label / 1000 + 'k'
              : label
          },
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || ''
        let formatCurrency = amount => {
          if (amount === null) return ''
          return toMoney(amount)
        }

        if (label) {
          label += ': '
        }
        label += formatCurrency(tooltipItem.yLabel)
        return label
      },
    },
  },
}

const today = new Date()

const ProductHistoryPrice = ({
  many = false,
  productId,
  modal,
  name,
  show,
  title = undefined,
  onHide,
}) => {
  const dispatch = useDispatch()

  const report = useSelector(selectProductReport)
  const list = useSelector(selectProductListReport)
  const products = useSelector(selectProductsSimple)
  const categorization = useSelector(selectAllCategorizations)
  const productsChart = useSelector(selectProductToChart)
  const categoriesChart = useSelector(selectProductsCategories)

  const loadingH = useSelector(state =>
    loadingSelector([
      typeP.PRODUCT_REPORT,
      typeP.PRODUCT_LIST_REPORT,
      typeP.CHART_PRODUCTS,
    ])(state),
  )
  const loadingP = useSelector(state => loadingSelector([typeP.GET_EXCEL])(state))
  const hasErrorP = useSelector(state => hasErrors([typeP.GET_EXCEL])(state))

  const loadingE = useSelector(state => loadingSelector([typeP.HYSTORIC_EXCEL])(state))
  const hasErrorE = useSelector(state => hasErrors([typeP.HYSTORIC_EXCEL])(state))

  const loadingDropDown = loadingP || loadingH

  const [actions, setActions] = useState({
    disbursement: false,
    loading: true,
    create: false,
    update: false,
    associated: false,
  })

  const [filter, setFilter] = useState({ value: 1, label: 'Día' })
  const [dateFrom, setDateFrom] = useState(
    new Date(today.getFullYear(), today.getMonth(), 1),
  )
  const [dateTo, setDateTo] = useState(today)
  const [iLoading, setLoading] = useState(true)
  const [selected, setSelected] = useState([])
  const [showCategorizations, setShowCategorization] = useState(false)
  const [selectedCategorization, setSelectedCategorization] = useState([])

  useEffect(() => {
    if (!modal) {
      dispatch(getProductsSimple())
      dispatch(getAllCategorizations(3))
    }
    setUp(dateFrom, dateTo, filter)
  }, [])

  useEffect(() => {
    if (loadingE) setActions({ ...actions, excel: true })
    else if (actions.excel) {
      setActions({ ...actions, excel: false })
      const alert = hasErrorE
        ? { ...handlerError(hasErrorE.message) }
        : { ...handlerSuccess() }
      dispatch(showAlert(alert))
    }
  }, [loadingE])

  useEffect(() => {
    if (!productsChart) return
    if (productsChart.length > 0)
      setSelected(
        products.filter(s => {
          return productsChart.filter(f => f === s.value).length !== 0
        }),
      )
  }, [productsChart])

  useEffect(() => {
    if (!categoriesChart) return
    if (categoriesChart.length > 0)
      setSelected(
        products.filter(s => {
          return categoriesChart.filter(f => f === s.value).length !== 0
        }),
      )
    else setSelected([])
  }, [categoriesChart])

  useEffect(() => {
    if (loadingP) setActions({ ...actions, products: true })
    else if (actions.products) {
      setActions({ ...actions, products: false })
      const alert = hasErrorP
        ? { ...handlerError(hasErrorP.message) }
        : { ...handlerSuccess() }
      dispatch(showAlert(alert))
    }
  }, [loadingP])

  const setUp = (start, end, type) => {
    let from = start.setHours(0, 0, 0, 0).valueOf()
    let to = end.setHours(23, 59, 59, 59).valueOf()

    if (many) {
      const ids = selected.map(s => s.value)
      if (ids.length > 0) dispatch(getChart(ids, from, to, type.value))
    } else dispatch(getReport(productId, from, to, type.value))
    setLoading(false)
  }

  const getProductsChart = (start, end, type) => {
    let from = start.setHours(0, 0, 0, 0).valueOf()
    let to = end.setHours(23, 59, 59, 59).valueOf()
    let object = { start: from, end: to, type: type.value }
    dispatch(getProductsToChart(object))
  }

  const setUpReport = (start, end, type) => {
    let from = start.setHours(0, 0, 0, 0).valueOf()
    let to = end.setHours(23, 59, 59, 59).valueOf()
    let object = { start: from, end: to, type: type.value }
    let productsReport = []
    selected.forEach(s => {
      productsReport.push(s.value)
    })
    dispatch(getExcelByReport(productsReport, object))
  }

  const deleteProduct = id => {
    setSelected(selected.filter(p => id !== p.value))
  }

  const getData = chartData => {
    let firstH = 0,
      firstP = 0,
      firstA = 0,
      h = -1,
      p = -1,
      av = -1
    let data = chartData || report
    data.forEach(r => {
      if (r.history > 0 && h === -1) firstH = r.history
      if (r.history !== h && r.history > 0) h = r.history
      else if (r.history === 0) r.history = h

      if (r.purchase > 0 && p === -1) firstP = r.purchase
      if (r.purchase !== p && r.purchase > 0) p = r.purchase
      else if (r.purchase === 0) r.purchase = p

      if (r.average > 0 && av === -1) firstA = r.average
      if (r.average !== av && r.average > 0) av = r.average
      else if (r.average === 0) r.average = av
    })

    data.forEach(r => {
      if (r.purchase === -1) r.purchase = firstP
      if (r.history === -1) r.history = firstH
      if (r.average === -1) r.average = firstA
    })

    return {
      labels: data.map(r => r.label.substring(0, 10)),
      datasets: [
        {
          data: data.map(r => r.average),
          label: 'Precio promedio de venta',
          borderColor: `rgb(90, 193, 69)`,
          backgroundColor: 'rgba(0,0,0,0)',
        },
        {
          data: data.map(r => r.history),
          label: 'Historial de precio de venta',
          borderColor: `rgb(203, 97, 16)`,
          backgroundColor: 'rgba(203, 97, 16, 0.3)',
        },
        {
          data: data.map(r => r.purchase),
          label: 'Historial de precio de compra',
          borderColor: `rgb(18, 119, 149)`,
          backgroundColor: 'rgba(18, 119, 149, 0.3)',
        },
      ],
    }
  }

  const renderActions = (
    <Dropdown
      loading={loadingDropDown}
      tooltip={'Acciones'}
      items={[
        {
          icon: faCalendarTimes,
          title: 'Ítems vendidos en el periodo',
          action: () => getProductsChart(dateFrom, dateTo, filter),
        },
        {
          icon: faFileExcel,
          title: 'Descargar Reporte',
          action: () => setUpReport(dateFrom, dateTo, filter),
        },
      ]}
    />
  )

  const onAssignCategorization = item => {
    const customCategories = Object.assign([], selectedCategorization)
    const index = customCategories.findIndex(sc => sc.id === item.id)
    if (index === -1) customCategories.push(item)
    else customCategories.splice(index, 1)
    setSelectedCategorization(customCategories)

    dispatch(getProductsToChartCategory(customCategories.map(c => c.id)))
  }

  const renderComponent = () => {
    return (
      <div className={'column'}>
        <Row>
          <Col lg={4} md={4} sm={6} xs={12}>
            <CustomDatePicker
              label={'Desde:'}
              value={dateFrom}
              onDayChange={date => {
                setDateFrom(date)
              }}
            />
          </Col>
          <Col lg={4} md={4} sm={6} xs={12}>
            <CustomDatePicker
              label={'Hasta:'}
              value={dateTo}
              onDayChange={date => {
                setDateTo(date)
              }}
            />
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <CustomSelect
              label={'Filtro:'}
              options={[
                { value: 1, label: 'Día' },
                { value: 2, label: 'Semana' },
                { value: 3, label: 'Mes' },
              ]}
              value={filter}
              onChange={item => {
                setFilter(item)
              }}
            />
          </Col>
          {many && (
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className={'column'}>
                <CustomSelect
                  label={'Productos:'}
                  options={
                    categoriesChart && categoriesChart.length > 0
                      ? products
                          .filter(s => {
                            return categoriesChart.filter(f => f === s.value).length !== 0
                          })
                          .filter(
                            p =>
                              p.value > 0 &&
                              selected.findIndex(s => s.value === p.value) === -1,
                          )
                      : products.filter(
                          p =>
                            p.value > 0 &&
                            selected.findIndex(s => s.value === p.value) === -1,
                        )
                  }
                  value={{ value: null, label: 'Seleccione un producto' }}
                  onChange={item => {
                    if (selected.findIndex(p => p.value === item.value) === -1)
                      setSelected([...selected, item])
                  }}
                />

                <Row className={'pl-1'}>
                  {selected &&
                    selected.map((p, index) => (
                      <div className={'user-tag product-t ml-2'} key={index}>
                        <label className={'label-user-tag'}>{p.label}</label>
                        <Icon
                          className={'delete-user-tag d-product-t'}
                          icon={faTimes}
                          tooltip={'Quitar'}
                          color={'white'}
                          onClick={() => deleteProduct(p.value)}
                        />
                      </div>
                    ))}
                </Row>
              </div>
            </Col>
          )}
          {many && (
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              <div className={'column'}>
                <label className={'ftf-form-label left mt-3'}>Categorías</label>
                <Button
                  style={{ marginLeft: 10, marginTop: 10 }}
                  color={'primary'}
                  onClick={() => setShowCategorization(true)}>
                  Filtrar por categorías
                </Button>
                <Row>
                  {selectedCategorization &&
                    selectedCategorization.map((p, key) => (
                      <div className={'user-tag product-t ml-2'} key={key}>
                        <label className={'label-user-tag'}>{p.name}</label>
                        <Icon
                          className={'delete-user-tag d-product-t'}
                          icon={faTimes}
                          tooltip={'Quitar'}
                          color={'white'}
                          onClick={() => onAssignCategorization(p)}
                        />
                      </div>
                    ))}
                </Row>
              </div>
            </Col>
          )}

          <Col xl={12}>
            <hr />
          </Col>
          <Col xl={12}>
            <Row className={'container-buttons'}>
              <Button
                disabled={many && selected.length === 0}
                icon={faSearch}
                color={'accent'}
                loading={loadingH}
                onClick={() => setUp(dateFrom, dateTo, filter)}>
                Aplicar Filtros
              </Button>
            </Row>
          </Col>
        </Row>
        <hr />
        {many ? (
          <Row>
            {selected.length > 0 &&
              list.map((l, index) => (
                <Col xs={12} md={6} lg={6} key={index}>
                  <Card>
                    <h4>{l.name}</h4>
                    <div>
                      <div className="ru-graph-w">
                        <Line
                          data={getData(l.list)}
                          height={100}
                          options={chartOptions}
                        />
                      </div>
                      <div className="ru-graph-m">
                        <Line
                          data={getData(l.list)}
                          height={300}
                          options={chartOptionsMobile}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              ))}
          </Row>
        ) : report.length > 0 ? (
          <>
            <div className="ru-graph-w">
              <Line data={getData()} height={100} options={chartOptions} />
            </div>
            <div className="ru-graph-m">
              <Line data={getData()} height={300} options={chartOptionsMobile} />
            </div>
          </>
        ) : (
          <Empty subtitleSecond={'Verifique los filtros aplicados.'} hideButton />
        )}
        <Folder
          noMessage
          onHide={() => setShowCategorization(false)}
          onAssign={item => onAssignCategorization(item)}
          data1={
            categorization && categorization.children ? categorization.children[0] : {}
          }
          data2={
            categorization && categorization.children ? categorization.children[1] : {}
          }
          show={showCategorizations}
          list={selectedCategorization.map(p => p.id)}
        />
      </div>
    )
  }

  return (
    <div className={'column'}>
      {modal ? (
        <Modal show={show} centered size={'xl'} onHide={() => onHide()}>
          <Modal.Header closeButton>
            <Modal.Title>Flujo de precios: {title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {(loadingH || iLoading) && <ProgressBar animated now={100} />}
            {renderComponent()}
          </Modal.Body>
          <Modal.Footer>
            <Row className={'container-buttons'}>
              <Button
                icon={faFileExcel}
                left
                loading={loadingE}
                variant={'primary'}
                onClick={() =>
                  dispatch(
                    historicExcel(
                      {
                        start: dateFrom.valueOf(),
                        end: dateTo.valueOf(),
                        type: filter.value,
                      },
                      productId,
                      name,
                    ),
                  )
                }>
                Exportar
              </Button>
            </Row>
          </Modal.Footer>
        </Modal>
      ) : (
        <Card title={'Flujo de precios'} white button={many ? renderActions : undefined}>
          {(loadingH || iLoading) && <ProgressBar animated now={100} />}
          {renderComponent()}
        </Card>
      )}
    </div>
  )
}

export default ProductHistoryPrice
