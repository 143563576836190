import React, { Component } from 'react'

import { Row, Col, Modal } from 'react-bootstrap'

import { Card, TableV2 } from '../index'
import { Bar } from 'react-chartjs-2'
import { faArchive, faTrash } from '@fortawesome/free-solid-svg-icons'
import Button from '../buttons/Button'
import { chartOptions, chartOptionsMobile } from './chartOptions'

import Money from '../Money/Money'

export default class ModalTable extends Component {
  state = {
    line: true,
    factor: false,
    showChart: false,
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next.show) this.setState({ showChart: next.show })
  }

  handleChangeRadio = event => {
    const { name, id } = event.target
    this.setState({ [name]: true, [id]: false })
    this.props.changeRadio(name)
  }

  renderRow = (item, index) => {
    return this.props.renderRow(item, index)
  }

  getHeaders = headers => {
    const newHeaders = []
    headers.forEach(header => {
      if (header === '') {
        newHeaders.push({ label: header, show: true, select: true })
      } else {
        newHeaders.push({ label: header, show: true })
      }
    })
    if (!this.props.renderRow) newHeaders.push({ config: true, label: '' })
    return newHeaders
  }

  render() {
    const {
      bDisabled,
      bLoading,
      show,
      title,
      headers,
      items,
      size,
      loading,
      button,
      subtitle,
      del,
      loadingDel,
      changeRadio,
      chart,
      renderRow,
      dateFilter,
      onDateChange,
      total,
      onClickTr,
      isSelected,
    } = this.props
    return (
      <Modal
        show={show}
        size={size || 'md'}
        centered
        onHide={() => {
          if (changeRadio) this.setState({ line: true, factor: false })
          this.props.onHide()
        }}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {subtitle && <h5>{subtitle}</h5>}
          {this.props.sum && (
            <Card>
              <div className={'justify-center column'}>
                <span>{this.props.sum.title}</span>
                <Money component="h4">
                  <strong>{this.props.sum.value}</strong>
                </Money>
              </div>
            </Card>
          )}
          {changeRadio && (
            <Row>
              <Col>
                <label className={'checkbox-label'}>
                  <input
                    onChange={this.handleChangeRadio}
                    checked={this.state.line}
                    type="radio"
                    name={'line'}
                    id={'factor'}
                  />{' '}
                  Árbol de ítems
                </label>
              </Col>
              <Col>
                <label className={'checkbox-label'}>
                  <input
                    onChange={this.handleChangeRadio}
                    checked={this.state.factor}
                    type="radio"
                    name={'factor'}
                    id={'line'}
                  />{' '}
                  Factor de presentación
                </label>
              </Col>
            </Row>
          )}
          {chart ? (
            renderRow ? (
              <Card>
                <TableV2
                  customClass={'scroll-x-without-height'}
                  dateFilter={dateFilter}
                  onDateChange={onDateChange}
                  loading={loading}
                  headers={this.getHeaders(headers)}
                  renderRow={this.renderRow}
                  items={items}
                />
                {show && (
                  <div>
                    <div className="ru-graph-w">
                      {chart && this.state.showChart && (
                        <Bar data={chart} height={300} options={chartOptions} />
                      )}
                    </div>
                    <div className="ru-graph-m">
                      {chart && this.state.showChart && (
                        <Bar data={chart} height={400} options={chartOptionsMobile} />
                      )}
                    </div>
                  </div>
                )}
              </Card>
            ) : (
              show && (
                <>
                  <div className="ru-graph-w">
                    {chart && this.state.showChart && (
                      <Bar data={chart} height={300} options={chartOptions} />
                    )}
                  </div>
                  <div className="ru-graph-m">
                    {chart && this.state.showChart && (
                      <Bar data={chart} height={400} options={chartOptionsMobile} />
                    )}
                  </div>
                </>
              )
            )
          ) : (
            <Card>
              <TableV2
                storageKey={title}
                mobileAuto={!renderRow}
                dateFilter={dateFilter}
                onDateChange={onDateChange}
                loading={loading}
                headers={renderRow ? this.getHeaders(headers) : headers}
                renderRow={renderRow && this.renderRow}
                items={items}
                total={total}
                onClickTr={onClickTr ? (e, item) => onClickTr(e, item) : undefined}
                isSelected={isSelected ? selected => isSelected(selected) : undefined}
                balancePermission={this.props.balancePermission}
              />
            </Card>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            {del && (
              <Button
                color={'secondary'}
                icon={faTrash}
                loading={loadingDel}
                onClick={this.props.onDelete}>
                Eliminar
              </Button>
            )}
            {button && (
              <Button
                icon={this.props.icon || faArchive}
                right
                disabled={bDisabled}
                color={'primary'}
                loading={bLoading}
                onClick={this.props.onClick}>
                {button}
              </Button>
            )}
          </Row>
        </Modal.Footer>
      </Modal>
    )
  }
}
