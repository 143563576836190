import { LOG_ENABLED } from '../settings/app.settings'

export const actionTypes = {
  GLOBAL_RESET: 'GLOBAL_RESET',
}

export const globalReset = () => ({
  type: actionTypes.GLOBAL_RESET,
})

export const actionStart = action => ({ type: requestState(action) })
export const actionSuccess = (action, data = {}) => ({
  type: successState(action),
  ...data,
})
export const actionError = (action, error) => {
  if (LOG_ENABLED) {
    console.log('actions: ', action)
    console.log('error: ', error)
  }

  let theError = {}
  if (error.response && error.response.data) {
    if (typeof error.response.data === 'object') {
      theError = error.response.data
    }
  } else if (error.message) {
    theError = { ...error }
  }

  return { type: errorState(action), error: theError }
}
export const clearActionResult = action => ({ type: successState(action) })

export const executeError = (action, error) => async dispatch => {
  dispatch(actionStart(action))
  dispatch(actionError(action, error))
}

export const executeAction =
  (action, process, returnValue = false) =>
  async dispatch => {
    dispatch(actionStart(action))
    try {
      if (process) {
        if (returnValue) {
          const result = await process()
          dispatch(actionSuccess(action, { ...result }))
          return result
        } else dispatch(actionSuccess(action, { ...(await process()) }))
      } else {
        dispatch(actionSuccess(action))
      }
    } catch (error) {
      console.log('error execute: ', error)
      dispatch(actionError(action, error))
    }
  }

export const requestState = type => `${type}_REQUEST`
export const successState = type => `${type}_SUCCESS`
export const errorState = type => `${type}_ERROR`

export const extract = (value, defaultValue) =>
  value !== undefined ? value : defaultValue
