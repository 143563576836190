import React from 'react'
import { Modal } from 'react-bootstrap'
import './AlertKolo.scss'
import Check from 'src/assets/check.svg'
export const AlertKolo = ({ show, type, title, custom }) => {
  const icons = [
    {
      type: 'success',
      icon: Check,
    },
    // Agregar mas iconos dependiendo la accion.
  ]

  return (
    <Modal className="kolo-backdrop" show={show} centered backdrop={false}>
      <Modal.Body>
        <div className={`alert-kolo-card`}>
          <img
            className="alert-kolo-icon"
            src={type ? icons.find(x => x.type === type).icon : Check}
            alt={''}
          />
          <div className="alert-kolo-title">{'¡Yeah!'}</div>
          <div className="alert-kolo-subtitle">{title}</div>

          {/**
           * Agregar botones genericos de confirmacion o cancelar
           */}

          {custom && custom}
        </div>
      </Modal.Body>
    </Modal>
  )
}
