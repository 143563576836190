import "./ShoppingCart.scss"
import React, {Component} from 'react';
import {connect} from "react-redux";
import IconBack from "../../../../assets/images/newDesign/arrow-left-black.svg";
import {Button, Col, Row, Spinner} from "react-bootstrap";
import {formatNumberWithCommas} from "../../../../utils/formatters";
import moment from "moment";
import {selectShopping, selectShoppingResponse} from "../../../../selectors/shopping.selector";
import {
    handlerError,
    handlerSuccess,
    hasErrorsSelector,
    singleErrorSelector
} from "../../../../selectors/error.selector";
import {loadingSelector} from "../../../../selectors/loading.selector";
import {actionTypes as typesS, addItems, onCreateOrder} from "../../../../actions/shopping.actions";
import PlaceholderProduct from "../../../../assets/images/newDesign/placeholder-product.svg";
import Alert from "sweetalert-react";
import IconMinus from "../../../../assets/images/newDesign/icon-minus.svg";
import IconPlus from "../../../../assets/images/newDesign/icon-plus.svg";
import Money from "../../../../components/Money/Money";


class ShoppingCartV2 extends Component {

    state = {
        modal: {show: false, item: {}, index: -1},
        alert: {title: 'default'}
    };

    componentWillReceiveProps(next) {
        const {loadingS, hasErrorS, handlerError, handlerSuccess, addItems, history} = this.props;
        const {errorS, response} = next;
        let alert = {title: 'default'};

        if (loadingS && !next.loadingS) {
            if (!hasErrorS && next.hasErrorS) {
                alert = handlerError(errorS.message || 'Ha ocurrido un error inesperado.');
                alert.onConfirm = () => this.setState({
                    alert: {
                        ...alert,
                        show: false
                    }
                });
            } else {
                alert = handlerSuccess(`La orden número: ${response.number}, ha sido creada satisfactoriamente.`);
                alert.onConfirm = () => {
                    this.setState({alert: {...alert, show: false}});
                    addItems([]);
                    history.replace(`/cliente/solicitudes/detalle/${response.id}`);
                };
            }
        }

        this.setState({alert});
    };

    setDataToModal = (show, item, index) => {
        this.setState({modal: {show, item, index}});
    };

    onDeleteItem = (index) => {
        const {shopping, addItems, handlerError} = this.props;
        let alert = handlerError('¿Está seguro de descartar este producto?.');
        alert.showCancelButton = true;

        alert.title="Atención"

        alert.confirmButtonText="Eliminar"
        alert.cancelButtonText="Cancelar"

        alert.onConfirm = () => {
            shopping.splice(index, 1);
            addItems(shopping);
            this.setState({alert: {...alert, show: false}});
        };

        alert.onCancel = () => this.setState({alert: {...alert, show: false}});
        this.setState({alert});
    };

    addItem = () => {
        let {shopping, addItems} = this.props;
        const {index, item} = this.state.modal;

        shopping[index] = item;
        addItems(shopping);
        this.setDataToModal(false, {}, -1);
    };

    getTotal = () => {
        const {shopping} = this.props;
        let total = 0;
        shopping.map((s) => (total += parseInt(s.subTotal)));
        return formatNumberWithCommas(total);
    };

    createOrder = () => {
        const {shopping} = this.props;
        let groups = [];
        let indexS = [];
        shopping.map((s) => {
            let index = indexS.indexOf(s.offerId);
            if (index === -1) {
                indexS.push(s.offerId);
                let deliveryDate = null;
                let polygonRoute = null;
                const products = shopping.filter((sp) => sp.offerId === s.offerId).map((sp) => {
                    if (sp.deliveryDate)
                        deliveryDate = moment(sp.deliveryDate, 'DD/MM/YYYY').valueOf();
                    if (sp.polygonRoute)
                        polygonRoute = sp.polygonRoute.id

                    return {
                        productId: sp.id,
                        offerId: sp.offerId,
                        quantity: sp.quantity,
                        discount: 0
                    }
                });
                groups.push({offerId: s.offerId, products, deliveryDate, polygonRoute});
            }
        });

        this.props.onCreateOrder({groups});
    };

    upQuantity = (product) => {
        const {products} = this.state;
        let item = products.find((i) => i.productId === product.productId);

        if (!item) return;
        let quantity = parseInt(item.quantity || 0);

        if(quantity < (product.existence - (product.quantityInCart || 0))){
            item.quantity = quantity + 1;
        }

        products.map((i) => {
            if (i.productId === product.productId) {
                i = item;
            }
        });
        this.setState({products}, ()=>this.productsToRender())
    }

    downQuantity = (product) => {
        const {products} = this.state;
        let item = products.find((i) => i.productId === product.productId);

        if (!item) return;
        let quantity = parseInt(item.quantity || 0);

        if(quantity > 0){
            item.quantity = quantity - 1;
        }

        products.map((i) => {
            if (i.productId === product.productId) {
                i = item;
            }
        });
        this.setState({products}, ()=>this.productsToRender())
    }

    render() {

        const {shopping, loadingS} = this.props;
        const {alert, modal} = this.state;

        const {show, item} = modal;

        console.log(shopping)

        return(
            <div className="content">
                <div className={"button-back"} onClick={()=> window.history.back()}>
                    <img className={'icon-back'} src={IconBack} alt={"Back"}/>
                </div>

                <label className={"title title-back"}>Carrito de compras</label>

                <Row className={"product-profile-card shopping-cart-card no-margins"}>
                    <Col className={"no-margins"}>
                        <Row className={"no-margins"}>
                            <Col className={"no-margins"}>
                                <div className={'header-title'}>
                                    <Row className={"no-margins title-text"} style={{padding: 0}}>
                                        <Col md={4} className={"no-margins"}>
                                            <Row className={"no-margins"}>
                                                <Col md={5} className={"no-margins"}>

                                                </Col>
                                                <Col md={7} className={"no-margins title-text"}>
                                                    Productos
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={4} className={"no-margins"}>
                                            <Row className={"no-margins"}>
                                                <Col md={6} className={"no-margins"}>
                                                    Precio
                                                </Col>
                                                <Col md={6} className={"no-margins"}>
                                                    Cantidad
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={4} className={"no-margins"}>
                                            <Row className={"no-margins"}>
                                                <Col md={6} className={"no-margins"}>
                                                    Entrega Estimada
                                                </Col>
                                                <Col md={6} className={"no-margins"}>
                                                    Subtotal
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>


                        </Row>

                    <Row className={"no-margins"}>
                        <Col className={"no-margins"}>

                        <div className={'products-cart'}>
                            {
                                shopping && shopping.length > 0 ?
                                    shopping.map((item, index) => {
                                        return (
                                            <div className={"product-item"}>
                                                <Row className={"no-margins title-text"}>
                                                    <Col md={4} className={"no-margins"}>
                                                        <Row className={"no-margins"}>
                                                            <Col md={5} className={"no-margins"}>
                                                                <img className={'product-image-cart'}
                                                                     src={item.thumbnail || PlaceholderProduct}
                                                                     alt={item.name}/>
                                                            </Col>
                                                            <Col md={7} className={"no-margins item-text"} style={{}}>
                                                                <Row className={"no-margins"}>
                                                                    <Col className={"no-margins"} style={{}}>
                                                                        <label
                                                                            className={"name-text"}>{item.name}</label>
                                                                    </Col>
                                                                </Row>
                                                                <Row className={"no-margins"}>
                                                                    <Col className={"no-margins"} style={{}}>
                                                                        <label
                                                                            className={"producer-text"}>Productor: {item.companyName}</label>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col md={4} className={"no-margins item-text"}>
                                                        <Row className={"no-margins"}>
                                                            <Col md={6} className={"no-margins"}>
                                                                <Money component='label'
                                                                    className={"price-text"}>{item.price}</Money>
                                                            </Col>
                                                            <Col md={6} className={"no-margins"}>
                                                                {/*<label>{item.quantity}</label>*/}

                                                                <Row className={"no-margins center"}>
                                                                    {/*<div className={"button-quantity-card"}*/}
                                                                    {/*     onClick={() => {*/}
                                                                    {/*         this.downQuantity(item)*/}
                                                                    {/*     }}>*/}
                                                                    {/*    <img className={'icon-quantity'}*/}
                                                                    {/*         src={IconMinus} alt={"-"}/>*/}
                                                                    {/*</div>*/}
                                                                    <label
                                                                        className={"quantity-text"}> {item.quantity || 0} </label>
                                                                    {/*<div className={"button-quantity-card"}*/}
                                                                    {/*     onClick={() => {*/}
                                                                    {/*         this.upQuantity(item)*/}
                                                                    {/*     }}>*/}
                                                                    {/*    <img className={'icon-quantity'}*/}
                                                                    {/*         src={IconPlus} alt={"+"}/>*/}
                                                                    {/*</div>*/}
                                                                </Row>

                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col md={4} className={"no-margins item-text"}>
                                                        <Row className={"no-margins"}>
                                                            <Col md={6} className={"no-margins"}>
                                                                <label
                                                                    className={"delivery-text"}>{item.deliveryDate}</label>
                                                            </Col>
                                                            <Col md={6} className={"no-margins"}>
                                                                <Money component='label'
                                                                    className={"subtotal-text"}>{item.subTotal}</Money>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                                <Row className={"no-margins"} style={{justifyContent: 'flex-end'}}>
                                                    <div className={"delete-text"}
                                                         onClick={() => this.onDeleteItem(index)}>Eliminar
                                                    </div>
                                                </Row>

                                                <div className={"divider"}/>
                                            </div>
                                        )
                                    })

                                    :
                                    <div className={"no-data"}>
                                        No hay contenido
                                        <div className={"divider"}/>
                                    </div>
                            }
                    </div>

                        </Col>
                    </Row>

                    <Row className={"no-margins"}>
                        <Col className={"no-margins"}>

                            <div className={'footer-total'}>
                                <Row className={"no-margins"} style={{justifyContent: 'space-between'}}>

                                    <div className={"no-margins footer-text-total"}>
                                        Total
                                    </div>

                                    <Money className={"no-margins footer-text-total"} style={{fontSize: 24, justifyContent: 'flex-end'}}>
                                        {this.getTotal()}
                                    </Money>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    </Col>
                </Row>

                <Row className={"no-margins button-order"}>
                    <button className={'button'} style={{paddingLeft: 35, paddingRight: 35, margin: 25}}
                            disabled={loadingS || shopping.length <= 0}
                            onClick={() => this.createOrder()}>
                        {loadingS &&
                        <Spinner
                            style={{marginRight: 10, marginTop: 4}}
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />}
                        Crear solicitud de compra
                    </button>
                </Row>

                <Alert {...alert}/>

            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    shopping: selectShopping(state),
    handlerError: (message) => handlerError(message),
    handlerSuccess: (message) => handlerSuccess(message),
    loadingS: loadingSelector([typesS.ON_CREATE_ORDER])(state),
    hasErrorS: hasErrorsSelector([typesS.ON_CREATE_ORDER])(state),
    errorS: singleErrorSelector([typesS.ON_CREATE_ORDER])(state),
    response: selectShoppingResponse(state)
});
const mapDispatchToProps = (dispatch) => ({
    addItems: (shopping) => dispatch(addItems(shopping, true)),
    onCreateOrder: (products) => dispatch(onCreateOrder(products))
});
export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCartV2);
