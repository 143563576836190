import { faClipboardCheck, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import React, { useState, useEffect } from 'react'
import { Accordion, Col, Row } from 'react-bootstrap'
import Alert from 'sweetalert-react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, Icon, ProductItem } from 'src/components'
import { loadingSelector } from 'src/selectors/loading.selector'
import { LocationComponent } from './LocationComponent'
import { assignWarehouseLocations, types } from 'src/actions/inventory.actions'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'

interface SimpleCategorization {
  id: number
  value: number
  name: string
  description: string
  parentCategorization: number
  children: SimpleCategorization[]
}

interface Item {
  productId: number
  id: number
  companyId: number
  code: string
  name: string
  available: number
  listCategorization: SimpleCategorization[]
  listLocations: SimpleCategorization[]
  subItems: Item[]
}

interface LocationInfo {
  id: number
  productId: number
  key: number
  name: string
  code: string
  quantity: number
  listCategorization: SimpleCategorization[]
  listLocations: SimpleCategorization[]
  subWarehouse: SimpleCategorization
  shelf: SimpleCategorization
  level: SimpleCategorization
}

interface requestLocation {
  productId: number
  quantity: number
  categories: number[]
  locations: number[]
  details: requestLocation[]
}

interface Props {
  warehouseId: number
  itemFrom: Item
  onSuccess?: () => void
}

export const CreateLocation: React.FC<Props> = ({ itemFrom, warehouseId, onSuccess }) => {
  const dispatch = useDispatch()

  const loadingAssing = useSelector(state =>
    loadingSelector([types.ASSIGN_WAREHOUSE_LOCATION])(state),
  )
  const hasErrorAM = useSelector(state =>
    hasErrors([types.ASSIGN_WAREHOUSE_LOCATION])(state),
  )

  const [flags, setFlags] = useState({ confirm: false })
  const [alert, setAlert] = useState({})
  const [itemsTo, setItemsTo] = useState<LocationInfo[]>([])
  const [location, setLocation] = useState({
    show: false,
    edit: false,
    id: null,
    key: null,
    subWarehouse: null,
    shelf: null,
    level: null,
    quantity: 0,
    maxQuantity: 0,
    name: '',
    code: '',
    productId: 0,
    decimals: 0,
  })
  useEffect(() => {
    if (loadingAssing) setFlags({ ...flags, confirm: true })
    else if (flags.confirm) {
      setFlags({ ...flags, confirm: false })
      if (hasErrorAM) {
        setAlert({
          ...handlerError(hasErrorAM.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      } else {
        setAlert({
          ...handlerSuccess('Producto asignado con exito'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            onSuccess()
          },
        })
      }
    }
  }, [loadingAssing])

  const itemsSpace = (items, emptyText, useClass) => {
    return (
      <div className={'pi'}>
        <Row>
          <Col xl={12} md={12} className={useClass ? 'pi-scroll' : ''}>
            {items && items.length > 0 ? (
              <ul>
                <li>
                  <div className={'column'}>
                    <Card
                      bodyStyle={{ margin: 0 }}
                      style={{ marginBottom: 0 }}
                      className={'pi-sticky left'}>
                      Item
                    </Card>
                    <Card className={'pi-container'}>
                      <ul>
                        <Accordion defaultActiveKey={'0'}>{items}</Accordion>
                      </ul>
                    </Card>
                  </div>
                </li>
              </ul>
            ) : (
              <h4>{emptyText}</h4>
            )}
          </Col>
        </Row>
      </div>
    )
  }

  const itemsToSelect = base => {
    return (
      JSON.stringify(base) !== '{}' &&
      itemsSpace(
        base.subItems
          .filter(x => x.available > 0)
          .map((item, i) => (
            <ProductItem
              key={i}
              listCategories={base.listCategorization}
              listLocations={base.listLocations}
              title={item.name}
              subTitle={item.code}
              titleSecond={`${item.available} u.`}
              // actions ={}
              setSelect={() =>
                setLocation({
                  ...location,
                  show: true,
                  id: item.productId,
                  maxQuantity: item.available,
                  name: item.name,
                  code: item.code,
                  productId: item.id || item.productId,
                  decimals: base.decimals,
                })
              }
            />
          )),
        'Todos los productos han sido asignados',
        false,
      )
    )
  }

  return (
    <div>
      <Row>
        <Col xl={5} lg={5} md={12} sm={12} xs={12}>
          {itemsToSelect(itemFrom)}
        </Col>
        <Col xl={7} lg={7} md={12} sm={12} xs={12}>
          {itemsSpace(
            itemsTo.length > 0 &&
              itemsTo.map((item, index) => (
                <ProductItem
                  key={index}
                  listCategories={item.listCategorization}
                  listLocations={item.listLocations}
                  title={item.name}
                  subTitle={item.code}
                  titleSecond={`${item.quantity} u.`}
                  actions={
                    <Icon
                      color={'secondary'}
                      icon={faWindowClose}
                      tooltip={'Descartar'}
                      size={'2x'}
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        const val = itemsTo.find(x => x.key === item.key)
                        itemFrom.subItems.find(
                          x => (x.productId = val.productId),
                        ).available += val.quantity
                        setItemsTo(itemsTo.filter(x => x.key !== item.key))
                      }}
                    />
                  }
                  setSelect={() => {
                    const itemAvailable = itemFrom.subItems.find(
                      x => x.id === item.productId,
                    )
                    setLocation({
                      ...location,
                      edit: true,
                      show: true,
                      key: item.key,
                      subWarehouse: item.subWarehouse,
                      shelf: item.shelf,
                      level: item.level,
                      quantity: item.quantity,
                      maxQuantity: itemAvailable?.available + item.quantity,
                      name: item.name,
                      code: item.code,
                      productId: item.id || item.productId,
                    })
                  }}
                />
              )),
            'Sin productos agregados',
            true,
          )}
        </Col>
      </Row>
      <hr />
      <Row className={'container-buttons'}>
        <Button
          loading={loadingAssing}
          disabled={loadingAssing || itemsTo.length === 0}
          icon={faClipboardCheck}
          onClick={() => {
            const request = {} as requestLocation
            let quantityRemove = 0
            request.productId =
              itemFrom.productId !== null ? itemFrom.productId : itemFrom.id
            request.details = []
            const categories = []
            if (itemFrom.listCategorization !== null) {
              itemFrom.listCategorization.forEach(x => {
                categories.push(x.id)
              })
              request.categories = categories
            }
            if (itemFrom.listLocations !== undefined && itemFrom.listLocations !== null) {
              const list = []
              itemFrom.listLocations.forEach(x => {
                list.push(x.id)
              })
              request.locations = list
            }

            itemsTo.forEach(x => {
              const detail = {} as requestLocation
              quantityRemove = quantityRemove + x.quantity

              detail.quantity = x.quantity
              detail.productId = x.productId
              if (itemFrom.listCategorization !== null) detail.categories = categories

              const locations = []
              if (x.listLocations !== null) {
                x.listLocations.forEach(x => {
                  locations.push(x.value)
                })
                detail.locations = locations
              }
              request.details.push(detail)
            })

            request.quantity = quantityRemove

            dispatch(assignWarehouseLocations(request, { warehouseId: warehouseId }))
          }}>
          {'Confirmar'}
        </Button>
      </Row>
      <LocationComponent
        modeSelection={false}
        onSaveEdit={item => {
          const index = itemsTo.findIndex(x => x.key === item.key)
          itemFrom.subItems.find(x => (x.productId = item.productId)).available +=
            itemsTo[index].quantity - item.quantity

          itemsTo[index] = {
            name: itemFrom.name,
            code: itemFrom.code,
            listCategorization: itemFrom.listCategorization,
            ...item,
          }

          setItemsTo([...itemsTo])
        }}
        value={location}
        onHide={defaultLocation => setLocation(defaultLocation)}
        onConfirm={item => {
          setLocation({
            ...location,
            show: false,
            edit: false,
            id: null,
            subWarehouse: {},
            shelf: {},
            level: {},
            quantity: 0,
          })
          itemFrom.subItems.forEach(x => {
            if (x.id === item.productId || x.productId === item.productId) {
              x.available = x.available - item.quantity
            }
          })
          setItemsTo([
            ...itemsTo,
            {
              ...item,
              key: new Date().valueOf(),
              name: item.name,
              code: item.code,
              listCategorization: itemFrom.listCategorization,
              productId: item.productId,
            },
          ])
        }}
      />
      <Alert {...alert} />
    </div>
  )
}
