import React, {Component, useState, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";

import {Modal, Row, Col, ProgressBar, Container} from "react-bootstrap";

import Button from "../../components/buttons/Button";
import Icon from "../../components/buttons/IconButton";
import MapCard from "../../components/maps/MapCard";

import {actionTypes as types, getPolygonContracted} from "../../actions/offers.actions";
import {selectPolygonContracted} from "../../selectors/offers.selector";

import {loadingSelector} from "../../selectors/loading.selector";
import {faWindowClose} from "@fortawesome/free-solid-svg-icons";
import { TableV2 } from '../../components';

const latitude = 14.589465440976774;
const longitude = -90.51898866891861;

const PolygonContractedByProduct = (props) => {
    const dispatch = useDispatch();

    const loading = useSelector((state) => loadingSelector([types.GET_POLYGON_CONTRACTED])(state));
    const contracted = useSelector((state) => selectPolygonContracted(state));

    const [google, setGoogle] = useState({map: null, maps: null, polygons: [], polygon: null});

    useEffect(() => {
        if (!props.show) return;
        setTimeout(() => dispatch(getPolygonContracted(props.productId)), 1000);
    }, [props.show]);

    useEffect(() => {
        if (!(props.show && !loading && contracted.length > 0)) return;
        let {map, maps, polygons} = google;
        if (!map) return;

        if (polygons.length > 0)
            polygons.map((p) => (p.setMap(null)));

        const items = [];
        let center = null;

        const infoWindow = new maps.InfoWindow();

        contracted.map((c) => {
            let points = c.polygonPoints;
            points = points.sort((a, b) => (a.index - b.index)).map((p) => ({lat: Number(p.latitude), lng: Number(p.longitude)}));
            points.push(points[0]);

            const polygon = new maps.Polygon({
                path: points,
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35
            });

            center = getCenter(points);

            polygon.addListener('click', (event) => {
                const contentString = `<b>${c.name}</b><br>${c.notes}`;
                infoWindow.setContent(contentString);
                infoWindow.setPosition(event.latLng);
                infoWindow.open(map);

                setGoogle({...google, polygon: c});
            });

            items.push(polygon);
        });


        if (center && center.latLng.lat > 0)
            map.panTo(center.latLng);

        map.setZoom(8);

        items.map((p) => (p.setMap(map)));

        setGoogle({...google, map, maps, polygons: items});
    }, [contracted]);

    const getCenter = (paths) => {
        const center = paths.map((p) => {
            return [p.lat, p.lng];
        }).reduce((x, y) => {
            return [x[0] + y[0] / paths.length, x[1] + y[1] / paths.length];
        }, [0, 0]);
        return {latLng: {lat: center[0], lng: center[1]}};
    };

    const getItems = (items) => {
        let offers = [];
        const index = [];
        items.map((i) => {
            i.offers.map((o) => {
                const ind = index.find((ind) => ind === o.id);
                if (!ind) {
                    index.push(o.id);
                    offers.push(o);
                }
            });
        });
        return offers;
    };

    return (<Modal
        show={props.show}
        size={'xl'}
        centered
        onHide={() => {}}>

        <Modal.Body><Container>
            {loading && <ProgressBar animated now={100}/>}
            <MapCard
                latitude={latitude}
                longitude={longitude}
                height={350}
                startCollapsed={false}
                markerList={[]}
                zoom={8}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({map, maps}) => setGoogle({
                    map,
                    maps,
                    polygons: [],
                    polygon: null
                })}
            />
            <TableV2
                items={contracted}
                mobileAuto
                storageKey={`polygonProduct`}
                headers = {[
                    {label: 'Enlace', show: true, value: ['offerName'], type: 'text' ,  className:'medium'},
                    {label: 'Productor', show: true, value: ['producer'], type: 'text' ,  className:'medium'},
                    {label: 'Transportista', show: true, value: ['transportist'], type: 'text' ,  className:'medium'},
                    {label: 'Categoria', show: true, value: ['type'], type: 'text' ,  className:'mini'},
                    {config:true, show: true, label:'', className:'mini center'}

                ]}
                customClass = {`scroll-x-without-heigth`}
            />
        </Container></Modal.Body>

        <Modal.Footer>
            <Row className={'container-buttons'}>
                <Button left icon={faWindowClose} variant={'secondary'} onClick={() => props.onClose()}> Cerrar</Button>
            </Row>
        </Modal.Footer>
    </Modal>);
};
export default PolygonContractedByProduct;
