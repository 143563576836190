import { $http } from './http'

class ParcelService {
  getAllByCompany = async params => {
    const response = await $http.get('/parcel', { params })
    return response.data
  }

  findById = async id => {
    const response = await $http.get(`/parcel/${id}`)
    return response.data
  }

  create = async request => {
    const response = await $http.post('/parcel', request)
    return response.data
  }

  update = async (parcelId, request) => {
    const response = await $http.put(`/parcel/${parcelId}`, request)
    return response.data
  }

  getTypes = async () => {
    const response = await $http.get('/parcel/types')
    return response.data
  }
}
export default new ParcelService()
