import React, { useState, useEffect } from 'react'

import CreateOrUpdateRecipe from './CreateOrUpdateRecipe'
import { ConversionWarehouse } from '../ConversionType/ConversionWarehouse'

interface IConversionProps {
  show: boolean
  recipe: IRecipe
}

interface Props {
  show: boolean
  onHide: () => void
  onCallBack: (response: any) => void
}

/**
 * Component that facilitates the use of free conversions
 * @param {Object} props - Component props
 * @param {boolean} props.show - Open Modal to create a recipe
 * @param {function} props.onHide - Close modal
 * @param {function} props.onCallBack - Return enumeration object
 */
const FreeConversion = ({ show, onHide, onCallBack }: Props) => {
  const [showRecipe, setShowRecipe] = useState<boolean>(false)
  const [conversion, setConversion] = useState<IConversionProps>({
    show: false,
    recipe: undefined,
  })

  useEffect(() => {
    if (!show) return
    setShowRecipe(true)
  }, [show])

  const validateInfo = data => {
    if (
      !(
        data.conversionDescription &&
        data.fromWarehouse?.value &&
        data.toWarehouse?.value
      )
    ) {
      return
    }

    const request = {
      conversionDescription: data.conversionDescription,
      fromWarehouseId: data.fromWarehouse.value,
      toWarehouseId: data.toWarehouse.value,
      isTransformation: true,
      onConsignment: false,
      detailList: [],
    }

    request.detailList = [
      {
        quantity: data.quantity || 1,
        name: conversion.recipe.name,
        description: conversion.recipe.description,
        products: conversion.recipe.items.map(item => ({
          productId: item.product,
          quantity: item.quantity,
          detailType: item.type,
          expireDate: item.date,
        })),
      },
    ]

    onCallBack(request)
    setConversion({ ...conversion, show: false })
  }

  const onClose = () => {
    onHide()
  }

  return (
    <div>
      <CreateOrUpdateRecipe
        show={showRecipe}
        title={'Conversión libre'}
        onHide={update => {
          setShowRecipe(false)
          if (!update) onClose()
        }}
        onCallBack={recipe => setConversion({ recipe, show: true })}
        fast
      />

      <ConversionWarehouse
        show={conversion.show}
        fast
        onHide={() => {
          setConversion({ ...conversion, show: false })
          onClose()
        }}
        onSave={data => validateInfo(data)}
      />
    </div>
  )
}
export default FreeConversion
