import React from 'react'
import { useSelector } from 'react-redux'
import { selectUsers } from 'src/selectors/modules.selector'
import { formatDateFromMillis, formatNumberWithCommas } from 'src/utils/formatters'
import { toMoney } from 'src/utils/utilities'

export const PaymentTicket = ({ order, company }) => {
  const users = useSelector(selectUsers)

  const createdByUser = users.find(user => user.id === (order.createdBy || 1))?.name
  const confirmedByUser = users.find(user => user.id === (order.confirmedBy || 1))?.name

  return order && company ? (
    <div style={{ display: 'none' }}>
      <div id={'ticketpayment'}>
        <p className="center">
          <b>{company.id === 791 ? 'AE' : company.id === 860 ? 'IM' : company.name}</b>
          <br />
          {company.id !== 791 && (company.id === 860 ? 'Ciudad' : company.address)}
          <br />
          Orden NO. {order.number} -
          {formatDateFromMillis(order.enabledAt || order.createdAt)}
        </p>

        <p className={'center'}>
          <b>Cliente: </b>
          {order.client ? order.client.name : 'Desconocido'} <br />
          <b>Forma de pago: </b>
          {order.paymentTypeData ? order.paymentTypeData.name : 'Desconocido'}
          <br />
          <b> Estado: </b>
          {order.statusData ? order.statusData.name : 'Desconocido'}
          <br />
          <b> Fecha de ingreso: </b>
          {order.createdAt ? formatDateFromMillis(order.createdAt) : '---'}
          <br />
          <b> Fecha de Pago: </b>
          {order.createdAt ? formatDateFromMillis(order.createdAt) : '---'}
          <br />
          <b> Fecha de Confirmacion: </b>
          {order.date !== null ? formatDateFromMillis(order.date) : 'Sin confirmar'}
          <br />
          <b> Ingresado Por: </b>
          {createdByUser || 'Sin registrar'}
          <br />
          <b> Confirmado Por: </b>
          {confirmedByUser || 'Sin confirmar'}
          <br />
          {order.paymentTypeData && order.paymentTypeData.id === 1 && (
            <>
              <b> Usuario Afectado: </b>
              {confirmedByUser || 'Sin confirmar'}
              <br />
            </>
          )}
        </p>

        {order.depositData && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p>
              <b>Deposito Asociado: </b>
              <br />
              <b>&nbsp; - {order.depositData.subTitle} </b> <br />
              <b>&nbsp; - {order.depositData.responsibleName} </b> <br />
              <b>&nbsp; - {order.depositData.subResponsibleName} </b> <br />
              <b> &nbsp; - Referencia: </b>
              {order.depositData.numberReference || 'Sin confirmar'}
              <br />
              <b> &nbsp; - Total: </b>
              {toMoney(order.depositData.total)}
              <br />
            </p>
          </div>
        )}

        <table className={'center'}>
          <thead>
            <th>CxC</th>
            <th>Total Venta</th>
            <th>Abono </th>
          </thead>
          <tbody>
            {order.payments &&
              order.payments.map((item, index) => (
                <tr key={index}>
                  <td className={'center'} style={{ width: 110 }}>
                    {item.number}
                    <br />
                  </td>
                  <td className={'center'} style={{ width: 110 }}>
                    {toMoney(item.total)}
                    <br />
                  </td>
                  <td className={'center'} style={{ width: 100 }}>
                    {toMoney(item.paymentAmount)}
                  </td>
                </tr>
              ))}
            <tr className="total-row">
              <td className={'center'} style={{ width: 50 }}>
                Total
              </td>
              <td className={'center'} style={{ width: 125 }} />
              <td className={'center'} style={{ width: 100 }}>
                {toMoney(Math.abs(order.balance))}
              </td>
            </tr>
          </tbody>
        </table>

        {order.paymentTypeData && order.paymentTypeData.id === 2 && (
          <div className={'center'}>
            <b>Abonos: </b>
            {toMoney(order.total - order.balance)}
          </div>
        )}

        <p className={'center'}>
          <b>ESTE DOCUMENTO NO ES UNA FACTURA ELECTRÓNICA</b>
        </p>
      </div>

      <div id={'ticket-gift'}>
        <p className={'left'}>
          <b>Código: </b> {order.number} <br />
          <b>Fecha: </b>
          {formatDateFromMillis(order.enabledAt || order.createdAt)} <br />
        </p>

        <table>
          <thead>
            <th>Ct</th>
            <th>Detalle</th>
          </thead>
          <tbody>
            {(order.details ? order.details : []).map((item, index) => (
              <tr key={index}>
                <td className={'center'} style={{ width: 50 }}>
                  {item.quantity}
                </td>
                <td className={'center'} style={{ width: 300 }}>
                  {item.productData
                    ? item.productData.name
                    : item.product
                    ? item.product.name
                    : 'Desconocido'}
                  <br />
                  {item.categories && item.categories.map(cat => cat.name + ' ')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    <div id={'ticketpayment'}></div>
  )
}
