import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {TableV2, Title} from "../../../components";
import {Col, Row} from "react-bootstrap";
import Card from "../../../components/cards/Card";
import { selectClientRequests, simpleClients} from "../../../selectors/clients.selector";
import ClientDetail from "../../../components/custom/ClientDetail/ClientDetail";
import IconButton from "../../../components/buttons/IconButton";
import {faBook} from "@fortawesome/free-solid-svg-icons";
import {actionTypes, getAllRequests, getClientsSimple} from "../../../actions/clients.actions";
import CustomSelect from "../../../components/inputs/Select/CustomSelect";
import {loadingSelector} from "../../../selectors/loading.selector";

const ClientRequest = () => {
  const dispatch = useDispatch()

  const requests = useSelector(selectClientRequests)
  const clients = useSelector(simpleClients)
  const loading = useSelector((state) => loadingSelector([actionTypes.GET_CLIENT_REQUESTS])(state))

  const [show, setShow] = useState(false)
  const [info, setInfo] = useState({clientId: null, requestId: null})
  const [filters, setFilters] = useState({client: {value: null, label: '-- Todos --'}})

  useEffect(() => {
    dispatch(getClientsSimple(null))
  }, [])

  useEffect(() => {
    dispatch(getAllRequests({clientId: filters.client.value}))
  }, [filters])

  useEffect(() => {
    if(info.clientId && info.requestId)
      setShow(true)
  }, [info])



  const renderHeaders = () => {
    return [
      { label: 'No.', show: true, value: ['id'], type: 'text', className: 'mini' },
      { label: 'Cliente', show: true, value: ['client'], type: 'text', className: 'medium' },
      { label: 'Solicitado por', show: true, value: ['responsible'], type: 'text', className: 'medium' },
      { label: 'Fecha', show: true, value: ['solicitedAt'], type: 'date', className: 'medium' },
      {
        label: 'Estado', show: true, value: ['estadoC'], type: 'text', className: 'medium',
        custom: (item) => item.estadoC = item.status === 3 ? 'Aprobado' : item.status === 2 ? 'Rechazado' : 'Ingresado'
      },
      {
        config: true, show: true, label: '', className: 'mini center',
        custom: (item) => item.status === 1 &&
          <IconButton icon={faBook} size={'1_5x'} onClick={() => setInfo({ clientId: item.clientId, requestId: item.id })} />
      }
    ]
  }

  const customFilter = () => {
    return(
      <CustomSelect
        label={'Cliente'}
        placeholder={'pj. Tienda Geovani'}
        options={clients}
        value={filters.client}
        onChange={(data) => setFilters({...filters, client: data})}
        required
      />
    )
  }

  return(
    <div>
      <Title title={'Solicitudes de edición de clientes'}/>
      <Row>
        <Col lg={12}>
          <Card title={'Solicitudes'} white>
            <TableV2
              customFilter={customFilter()}
              items={requests}
              loading={loading}
              headers={renderHeaders()}
              mobileAuto
              storageKey={`clientRequest`}

            />
          </Card>
        </Col>
      </Row>

      <ClientDetail show={show} title={'Solicitud de edición'} modal compare isDisabled id={info.clientId} requestId={info.requestId}
                    onHide={() => { dispatch(getAllRequests(filters.client.value))
                      setShow(false); setInfo({clientId: null, requestId: null})
                    }}/>
    </div>
  )
}

export default ClientRequest;
