import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Alert from 'sweetalert-react'
import { Modal, Col, Row } from 'react-bootstrap'
import { FormText, Select, Button, TableV2 } from 'src/components'
import { faSave } from '@fortawesome/free-solid-svg-icons'

import { formatNumberWithCommas, getNumber } from 'src/utils/formatters'

import {
  accreditMultipleByMovementV2,
  actionTypes as typeP,
  getByAmount,
} from 'src/actions/purchase.actions'
import {
  selectPurchasesSimple,
  selectPurchaseStatus,
} from 'src/selectors/purchase.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'

import CreditNoteCreate from '../../CreditNote/purchase/CreditNoteCreate'

const AccreditationMultiple = ({ show, item, onHide }) => {
  const dispatch = useDispatch()

  const loadingP = useSelector(state => loadingSelector([typeP.GET_BY_AMOUNT])(state))
  const simplesResponse = useSelector(selectPurchasesSimple)

  const loadingAM = useSelector(state =>
    loadingSelector([typeP.ACCREDIT_MULTIPLE_V2])(state),
  )
  const hasErrorAM = useSelector(state => hasErrors([typeP.ACCREDIT_MULTIPLE_V2])(state))

  const [alert, setAlert] = useState({ title: '' })
  const [flags, setFlags] = useState({ get: false, accreditation: false })
  const [open, setOpen] = useState(false)
  const [simples, setSimples] = useState([])
  const [documentType, setDocumentType] = useState({})

  const [accreditationNote, setAccreditationNote] = useState({})

  useEffect(() => {
    if (!show) return
    setOpen(true)
    setDocumentType({ value: null, label: 'Sin filtrar' })
    dispatch(getByAmount(Math.abs(item.amount)))
  }, [show])

  useEffect(() => {
    if (loadingP) setFlags({ ...flags, get: true })
    else if (flags.get) {
      setFlags({ ...flags, get: false })
      setSimples([...simplesResponse.map(s => ({ ...s, selected: false }))])
    }
  }, [loadingP])

  useEffect(() => {
    if (loadingAM) setFlags({ ...flags, accreditation: true })
    else if (flags.accreditation) {
      setFlags({ ...flags, accreditation: false })
      if (hasErrorAM) {
        setAlert({
          ...handlerError(hasErrorAM.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      } else {
        setAlert({
          ...handlerSuccess('Documento acreditado satisfactoriamente'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            onClose(true)
          },
        })
      }
    }
  }, [loadingAM])

  const onClose = success => {
    setOpen(false)
    setSimples([])
    setDocumentType({})
    setAccreditationNote({})
    onHide(success)
  }

  const getItems =
    documentType.value === null
      ? simples
      : simples.filter(
          item =>
            (documentType.value === 1 && item.type !== 3) ||
            (documentType.value === 2 && item.type === 3) ||
            (documentType.value === 3 && item.type === 4),
        )

  const getAmount = (t, simple) => {
    let amount = 0

    const cSimples = Object.assign([], [...simples.filter(s => s.selected)])
    if (simple) cSimples.push(simple)

    cSimples.forEach(s => {
      let subtotal = 0
      if (s.status === 1) subtotal += getNumber(s.total)
      else subtotal += getNumber(s.balance)
      amount += getNumber(subtotal)
    })

    if (t) {
      amount += getNumber(t)
    }
    return getNumber(amount)
  }

  const addOrRemove = item => {
    const index = simples.findIndex(s => item.id === s.id)
    let simple = simples[index]

    simple.selected = !simple.selected

    simples[index] = simple
    setSimples([...simples])
  }

  return (
    <div>
      <Modal
        show={open && !accreditationNote.show}
        centered
        size={'xl'}
        onHide={() => onClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Movimiento bancario {item?.reference}</Modal.Title>
        </Modal.Header>

        <Modal.Body className={'custom-modal-body'}>
          <Row>
            <Col xl={12} md={12} sm={12}>
              <div>
                <h6>Es necesario seleccionar los ítems a acreditar</h6>
              </div>
            </Col>
            <Col xl={4} md={6} sm={12}>
              <FormText
                label={'Saldo disponible para acreditación'}
                value={formatNumberWithCommas(getAmount(item?.amount || 0) * -1)}
                disabled
                onChange={() => undefined}
              />
            </Col>
            <Col xl={12} md={12} sm={12}>
              <Row>
                <Col xs={4} lg={4} md={6} sm={6}>
                  <Select
                    label={'Filtra por tipo de documento'}
                    options={[
                      { value: null, label: 'Sin filtrar' },
                      { value: 1, label: 'Compras' },
                      { value: 2, label: 'Gastos' },
                    ]}
                    value={documentType}
                    onChange={s => setDocumentType(s)}
                  />
                </Col>
              </Row>
            </Col>
            <Col xl={!2}>
              <TableV2
                headers={[
                  {
                    show: true,
                    label: '',
                    value: ['selected'],
                    type: 'text',
                    custom: data => (
                      <input
                        type={'checkbox'}
                        disabled={!data.selected && getAmount(item?.amount, item) > 0}
                        checked={data.selected}
                        onChange={() => undefined}
                      />
                    ),
                  },
                  {
                    show: true,
                    label: 'Referencia',
                    value: ['invoice'],
                    type: 'text',
                    custom: v => `${v.type === 1 ? 'Compra' : 'Gasto'} - ${v.invoice}`,
                  },
                  { show: true, label: 'nit', value: ['nit'], type: 'text' },
                  {
                    show: true,
                    label: 'Proveedor',
                    value: ['providerName'],
                    type: 'text',
                  },
                  { show: true, label: 'Creado por', value: ['userName'], type: 'text' },
                  {
                    show: true,
                    label: 'Estado',
                    value: ['status'],
                    type: 'text',
                    custom: data => selectPurchaseStatus(data.status).name,
                  },
                  {
                    show: true,
                    label: 'Tipo de pago',
                    value: ['type'],
                    type: 'text',
                    custom: data =>
                      data.type === 1 ? 'Crédito' : data.type === 2 ? 'Contado' : 'Gasto',
                  },
                  { show: true, label: 'Total', value: ['total'], type: 'currency' },
                  { show: true, label: 'Saldo', value: ['balance'], type: 'currency' },
                ]}
                items={getItems}
                isSelected={data => data.selected}
                onClickTr={(e, data) => {
                  if (!(!data.selected && getAmount(item?.amount, item) > 0))
                    addOrRemove(data)
                }}
              />
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              color={'primary'}
              loading={loadingAM}
              disabled={getAmount(item?.amount) !== 0}
              icon={faSave}
              onClick={() => {
                const purchases = simples
                  .filter(s => s.selected)
                  .map(s => ({
                    purchaseId: s.id,
                    amount: s.status === 1 ? s.total : s.balance,
                    creditNote: s.type === 4,
                  }))
                if (getAmount(item?.amount || 0, null) > 0) {
                  setAccreditationNote({
                    show: true,
                    purchases,
                    initialValues: {
                      amount: getAmount(item?.amount || 0, null),
                    },
                  })
                }
                dispatch(accreditMultipleByMovementV2(item?.id, { purchases }))
              }}>
              Guardar
            </Button>
            <Button
              color={'accent'}
              loading={loadingAM}
              disabled={!(getAmount() < (item?.amount || 0) * -1)}
              icon={faSave}
              onClick={() => {
                const selected = simples.filter(s => s.selected)
                const providers = []
                const purchases = selected.map(s => {
                  if (providers.findIndex(p => p.providerId === s.providerId) === -1)
                    providers.push({
                      providerId: s.providerId,
                      nit: s.nit,
                      name: s.providerName,
                    })

                  return {
                    purchaseId: s.id,
                    amount: s.status === 1 ? s.total : s.balance,
                    creditNote: s.type === 4,
                  }
                })

                if (providers.length > 1) {
                  return setAlert({
                    ...handlerError(
                      'Para utilizar la función de nota de crédito, se necesita que todos los documentos a acreditar sean del mismo proveedor',
                    ),
                    onConfirm: () => setAlert({ ...alert, show: false }),
                  })
                }

                setAccreditationNote({
                  show: true,
                  purchases,
                  initialValues: {
                    amount: getAmount(item?.amount || 0, null, true) * -1,
                    ...providers[0],
                  },
                })
              }}>
              Guardar y crear nota de acreditación
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <CreditNoteCreate
        show={accreditationNote.show}
        initialValues={accreditationNote.initialValues}
        onClose={() => setAccreditationNote({})}
        onCreate={note => {
          dispatch(
            accreditMultipleByMovementV2(item?.id, {
              purchases: accreditationNote.purchases,
              note,
            }),
          )
        }}
        loading={loadingAM}
      />

      <Alert {...alert} />
    </div>
  )
}
export default AccreditationMultiple
