import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Row, Col, Modal } from 'react-bootstrap'
import Alert from 'sweetalert-react'

import {
  Title,
  SwitchV2,
  Card,
  Button,
  TableV2,
  Icon,
  Dropdown,
  CustomSummary,
} from '../../components'

import {
  actionTypes as typeP,
  getPurchase,
  getTransactions,
  onNullifyPurchase,
  onNullifyTransactionPurchase,
} from '../../actions/purchase.actions'

import {
  selectNullifyResponse,
  selectPurchaseTransactions,
} from 'src/selectors/purchase.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import { actionTypes, getCreditNotes } from '../../actions/creditnote.actions'
import { selectCreditNotes } from '../../selectors/creditnote.selector'
import { faCheck, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { toMoney } from 'src/utils/utilities'

const PurchaseNullify = props => {
  const dispatch = useDispatch()

  const transactions = useSelector(selectPurchaseTransactions)
  const loading = useSelector(state => loadingSelector([typeP.TRANSACTIONS])(state))

  const creditNotes = useSelector(selectCreditNotes)
  const loadingCN = useSelector(state =>
    loadingSelector([actionTypes.GET_CREDIT_NOTE])(state),
  )

  const loadingN = useSelector(state => loadingSelector([typeP.NULLIFY_RESPONSE])(state))
  const hasError = useSelector(state => hasErrors([typeP.NULLIFY_RESPONSE])(state))
  const responseNullify = useSelector(selectNullifyResponse)

  const [actions, setActions] = useState({ nullify: false, cn: false })
  const [modal, setModal] = useState({ show: false, items: [] })

  const filtersTypeDefault = {
    inventory: { id: 1, value: true, label: 'Existencia', show: true },
    sale: { id: 2, value: true, label: 'Ventas', documentType: 4, show: true },
    waste: {
      id: 2,
      value: true,
      label: 'Reducciones de inventario',
      documentType: 12,
      show: true,
    },
    manufacture: {
      id: 2,
      value: true,
      label: 'Manufactura',
      documentType: 3,
      show: true,
    },
    credit: { id: 4, value: true, label: 'Notas de crédito', show: !props.transactions },
    balance: { id: 5, value: true, label: 'Saldo', show: !props.transactions },
    movement: { id: 6, value: true, label: 'Bancos', show: !props.transactions },
    providerBalance: {
      id: 7,
      value: true,
      label: 'Saldo de proveedor',
      show: !props.transactions,
    },
  }

  const [filters, setFilters] = useState({ ...filtersTypeDefault })
  const [alert, setAlert] = useState({ title: '' })
  const [id, setId] = useState(0)

  useEffect(() => {
    if (!props.modal) {
      const { match } = props
      const { params } = match
      dispatch(getTransactions(params.id, props.serie, props.product))
      setId(params.id)
      dispatch(getCreditNotes())
      dispatch(getPurchase(params.id))
    }
  }, [])

  useEffect(() => {
    if (props.show) {
      setId(props.id)
      dispatch(getTransactions(props.id, props.serie, props.product))
    }
  }, [props.show])

  useEffect(() => {
    if (loadingCN) setActions({ ...actions, cn: true })
    else if (actions.cn) {
      setModal({
        ...modal,
        items: creditNotes
          .filter(s => s.status === 1)
          .map(c => ({ ...c, type: 4, selected: false })),
      })
    }
  }, [loadingCN])

  useEffect(() => {
    if (loadingN && props.show) setActions({ ...actions, nullify: true })
    else if (actions.nullify) {
      setActions({ ...actions, nullify: false })
      if (hasError)
        setAlert({
          ...handlerError(hasError.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else {
        setAlert({
          ...handlerSuccess(responseNullify),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            onClose(true)
          },
        })
      }
    }
  }, [loadingN])

  const customFilter = () => {
    if (filters.length === 0) return
    return (
      <Row>
        {Object.keys(filtersTypeDefault)
          .filter(key => {
            const filter = filtersTypeDefault[key]
            return filter.show
          })
          .map((filterKey, index) => (
            <Col key={index} xl={3} lg={3} md={4} sm={4}>
              <SwitchV2
                label={filters[filterKey].label}
                checked={filters[filterKey].value}
                id={filterKey}
                onChange={checked =>
                  setFilters({
                    ...filters,
                    [filterKey]: { ...filters[filterKey], value: checked },
                  })
                }
              />
            </Col>
          ))}
      </Row>
    )
  }

  const iFilters = item => {
    const {
      inventory,
      sale,
      waste,
      credit,
      balance,
      movement,
      providerBalance,
      manufacture,
    } = filters

    return (
      (inventory.value && inventory.id === item.referenceType) ||
      filterByDocumentType(item, sale) ||
      filterByDocumentType(item, waste) ||
      (credit.value && credit.id === item.referenceType && credit.show) ||
      (balance.value && balance.id === item.referenceType && balance.show) ||
      (movement.value && movement.id === item.referenceType && movement.show) ||
      (providerBalance.value &&
        providerBalance.id === item.referenceType &&
        providerBalance.show) ||
      filterByDocumentType(item, manufacture)
    )
  }

  const filterByDocumentType = (item, document) => {
    return (
      document.value &&
      document.id === item.referenceType &&
      item.documentType === document.documentType
    )
  }

  const nullify = list => {
    setAlert({
      show: true,
      type: 'warning',
      title: 'Anulación de Factura',
      text: '¿Desea anular la factura? Se anularán todas las transacciones relacionadas. Esta acción no podrá ser revertida',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Anular',
      confirmButtonColor: '#B35796',
      onCancel: () => setAlert({ ...alert, show: false }),
      onConfirm: () => {
        setAlert({ ...alert, show: false })
        dispatch(onNullifyPurchase(id, list))
      },
    })
  }

  const nullifyTransaction = transactionId => {
    setAlert({
      show: true,
      type: 'warning',
      title: 'Anulación de pago',
      text: '¿Desea anular el pago?  Esta acción no podrá ser revertida',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Anular',
      confirmButtonColor: '#B35796',
      onCancel: () => setAlert({ ...alert, show: false }),
      onConfirm: () => {
        setAlert({ ...alert, show: false })
        dispatch(onNullifyTransactionPurchase(id, { transactionId }))
      },
    })
  }

  const getHeaders = () => {
    return [
      {
        label: '',
        select: true,
        value: [''],
        show: !props.transactions,
        type: '',
        classNameCustom: item =>
          `mini center t-${
            item.referenceType !== 1 &&
            item.referenceType !== 2 &&
            item.referenceType !== 3
              ? item.valid === 0
                ? 'red'
                : 'green'
              : ''
          }`,
        custom: item =>
          item.referenceType !== 1 &&
          item.referenceType !== 2 &&
          item.referenceType !== 3 && (
            <Icon
              icon={item.valid === 0 ? faExclamationCircle : faCheck}
              tooltip={item.valid === 0 ? 'Anulada' : 'Valida'}
            />
          ),
      },
      {
        label: 'Serie',
        show: !props.transactions,
        value: ['serieC'],
        type: 'text',
        className: 'medium',
        custom: item => (item.serieC = item.serie || 'Sin número de serie'),
      },
      {
        label: 'Código',
        show: props.transactions,
        value: ['referenceName'],
        type: 'text',
        className: 'medium',
      },
      { label: 'Fecha', show: true, value: ['date'], type: 'date', className: 'mini' },
      {
        label: 'Tipo',
        show: true,
        value: ['documentName'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Referencia',
        show: true,
        value: ['name'],
        type: 'text',
        className: 'medium',
      },
      {
        label: 'Cantidad',
        show: true,
        value: ['quantity'],
        type: 'text',
        className: 'mini',
        classNameCustom: item => (item.type !== 1 ? 'mini right' : 'mini'),
      },
      {
        label: 'Descripción',
        show: true,
        value: ['description'],
        type: 'text',
        className: 'medium',
      },
      {
        config: true,
        show: true,
        label: '',
        className: 'mini center',
        custom: item =>
          item.referenceType !== 1 &&
          item.referenceType !== 2 &&
          item.referenceType !== 3 &&
          item.valid !== 0 && (
            <Dropdown
              className={'dp-custom'}
              items={[
                {
                  title: 'Anular',
                  action: () => {
                    nullifyTransaction(item.transactionId)
                  },
                },
              ]}
            />
          ),
      },
    ]
  }

  const calculateCost = (transactions, filterCondition) => {
    return transactions
      .filter(filterCondition)
      .reduce((accumulator, current) => accumulator + current.cost, 0)
  }

  const summary = () => {
    const { inventory, sale, waste, manufacture } = filters

    const total = calculateCost(transactions, () => true)
    const existence = calculateCost(
      transactions,
      item => inventory.value && inventory.id === item.referenceType,
    )
    const sales = calculateCost(transactions, item => filterByDocumentType(item, sale))
    const wastes = calculateCost(transactions, item => filterByDocumentType(item, waste))
    const manufactures = calculateCost(transactions, item =>
      filterByDocumentType(item, manufacture),
    )

    return (
      <CustomSummary
        title={'Porcentaje de uso en base al costo'}
        items={[
          {
            show: true,
            title: 'Existencia',
            values: [toMoney(existence), ((existence / total) * 100).toFixed(2) + '%'],
          },
          {
            show: true,
            title: 'Ventas',
            values: [toMoney(sales), ((sales / total) * 100).toFixed(2) + '%'],
          },
          {
            show: true,
            title: 'Reducciones',
            values: [toMoney(wastes), ((wastes / total) * 100).toFixed(2) + '%'],
          },
          {
            show: true,
            title: 'Manufacturas',
            values: [
              toMoney(manufactures),
              ((manufactures / total) * 100).toFixed(2) + '%',
            ],
          },
        ]}
      />
    )
  }

  const renderTable = () => {
    return (
      <Card white>
        {summary()}
        <TableV2
          loading={loading || loadingN}
          items={transactions.filter(iFilters)}
          customFilter={customFilter()}
          noItemsLegend={'No hay transacciones registradas con los filtros aplicados'}
          mobileAuto
          storageKey={`transactions`}
          headers={getHeaders()}
        />
        {!props.read && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button loading={loadingN} color={'secondary'} onClick={() => nullify()}>
              Anular Factura
            </Button>
          </div>
        )}
      </Card>
    )
  }

  const onClose = val => {
    setFilters(filtersTypeDefault)
    props.onHide(val)
  }

  return (
    <div>
      {props.modal ? (
        <Modal show={props.show} centered size={'xl'} onHide={() => onClose()}>
          <Modal.Header closeButton>
            <Modal.Title>Transacciones</Modal.Title>
          </Modal.Header>
          <Modal.Body>{renderTable()}</Modal.Body>
        </Modal>
      ) : (
        <div>
          <Title title={'Transacciones'} />
          {renderTable()}
        </div>
      )}
      <Alert {...alert} />
    </div>
  )
}
export default PurchaseNullify
