import React, { Component, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container, Modal, Row } from 'react-bootstrap'
import { formatDateFromMillis, formatNumberWithCommas } from 'src/utils/formatters'
import Button from 'src/components/buttons/Button'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  actionTypes as resolutionActions,
  getSingleInvoice,
} from 'src/actions/resolutions.actions'
import { selectGetSingleInvoice } from 'src/selectors/resolutions.selector'
import { getInfoReference } from 'src/actions/utilities.actions'

const InvoiceModal = props => {
  const dispatch = useDispatch()

  const invoice = useSelector(selectGetSingleInvoice)
  const loading = useSelector(state =>
    loadingSelector([resolutionActions.GET_SINGLE_INVOICE])(state),
  )

  const [show, setShow] = useState(false)

  useEffect(() => {
    console.log('changed props')
    if (props.show) {
      setShow(props.show)
    }
  }, [props])

  useEffect(() => {
    if (show) {
      dispatch(getSingleInvoice(props.invoice))
    }
  }, [show])

  useEffect(() => {
    if (invoice.id > 0) {
      setShow(true)
    }
  }, [loading])

  const onHide = () => {
    setShow(false)
    props.onHide()
  }

  return show && invoice.id && !loading ? (
    <Modal v>
      <Modal.Header closeButton>
        <Modal.Title>Orden</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="invoice">
          <Row>
            <Col className="text-center">
              <h3>{invoice ? invoice.companyDetail.name : ''}</h3>
            </Col>
          </Row>

          <Row>
            <Col className="text-center">
              <h2>{invoice.companyDetail.name}</h2>
            </Col>
            <Col className="text-center">
              <h2>NIT: {invoice.companyDetail.nit}</h2>
            </Col>
          </Row>

          <Row>
            <Col className="text-center">{invoice.companyDetail.address}</Col>
          </Row>

          <Row>
            {invoice.uuid ? (
              <Col className="text-center">Codigo de Autorizacion{invoice.uuid}</Col>
            ) : (
              <Col className="text-center">
                Resolucion No. {invoice && invoice.resolutionDetails.number} de fecha{' '}
                {formatDateFromMillis(invoice.resolutionDetails.authorizationDate)}
              </Col>
            )}
          </Row>
          {invoice.uuid ? (
            ''
          ) : (
            <Row>
              <Col className="text-center">
                Serie {invoice.resolutionDetails.series} Del{' '}
                {invoice.resolutionDetails.initialNumber} al{' '}
                {invoice.resolutionDetails.finalNumber}
              </Col>
            </Row>
          )}

          <Row className="text-center">
            <Col>
              <h2>
                Orden No.{invoice.number} - {formatDateFromMillis(invoice.createdAt)}
              </h2>
            </Col>
          </Row>

          <Row className="text-center">
            <Col>
              Nombre: <br /> {invoice.name}
            </Col>
            <Col>
              Nit: <br /> {invoice.nit ? invoice.nit : 'C/F'}
            </Col>
          </Row>
          <hr />
          <div style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Row>
              <Col>
                <b>Cantidad</b>
              </Col>
              <Col>
                <b>Producto</b>
              </Col>
              <Col>
                <b>Total</b>
              </Col>
            </Row>
            <hr />
            {invoice.invoiceDetails.map((value, index) => {
              return (
                <Row key={value.id}>
                  <Col>{value.quantity}</Col>
                  <Col>{value.description}</Col>
                  <Col>{formatNumberWithCommas(parseFloat(value.amount))}</Col>
                </Row>
              )
            })}
            <hr />

            <Row>
              <Col>
                <h2>Total</h2>
              </Col>
              <Col></Col>
              <Col>{formatNumberWithCommas(parseFloat(invoice.total))}</Col>
            </Row>
          </div>
          <Row>
            {' '}
            <Col className="text-center">(Impuesto de Ventas Incluido)</Col>{' '}
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        {/*<Row className={'container-buttons'}>*/}
        {/*    <Button*/}
        {/*        onClick={() => this.props.downloadPrintableInvoice(invoice.id,invoice.number)}>*/}
        {/*        Descargar*/}
        {/*    </Button>*/}
        {/*    <Button*/}
        {/*        onClick={() => this.setState({showInvoice: false, showEmail: true})}>*/}
        {/*        Enviar*/}
        {/*    </Button>*/}
        {/*</Row>*/}
      </Modal.Footer>
    </Modal>
  ) : (
    ''
  )
}

export default InvoiceModal
