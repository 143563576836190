/** @module actions/orders **/
import OrdersService from '../services/orders.service'
import OfferService from '../services/offers.services'
import UploadService from '../services/uploads.services'

import { executeAction } from './global.actions'
import { imageTypes } from '../components/gallery/Gallery'
import { getState } from 'src/utils/utilities'
import { selectGetAllOrderStatus } from 'src/selectors/orders.selector'

export const actionTypes = {
  CREATE_NEW_ORDER: 'CREATE_NEW_ORDER',

  CONVERT_PRESALE: 'CREATE_NEW_PRESALE',
  CONVERT_PRESALE_REQUEST: 'CREATE_NEW_PRESALE_REQUEST',
  CONVERT_PRESALE_SUCCESS: 'CREATE_NEW_PRESALE_SUCCESS',
  CONVERT_PRESALE_ERROR: 'CREATE_NEW_PRESALE_ERROR',

  GET_ALL_ORDERS: 'GET_ALL_ORDERS',
  GET_ALL_ORDERS_REQUEST: 'GET_ALL_ORDERS_REQUEST',
  GET_ALL_ORDERS_SUCCESS: 'GET_ALL_ORDERS_SUCCESS',
  GET_ALL_ORDERS_ERROR: 'GET_ALL_ORDERS_ERROR',

  GET_ALL_ORDERS_MAP: 'GET_ALL_ORDERS_MAP',
  GET_ALL_ORDERS_MAP_REQUEST: 'GET_ALL_ORDERS_MAP_REQUEST',
  GET_ALL_ORDERS_MAP_SUCCESS: 'GET_ALL_ORDERS_MAP_SUCCESS',
  GET_ALL_ORDERS_MAP_ERROR: 'GET_ALL_ORDERS_MAP_ERROR',

  GET_ALL_ORDER_STATUS: 'GET_ALL_ORDER_STATUS',
  GET_ALL_ORDER_STATUS_REQUEST: 'GET_ALL_ORDER_STATUS_REQUEST',
  GET_ALL_ORDER_STATUS_SUCCESS: 'GET_ALL_ORDER_STATUS_SUCCESS',
  GET_ALL_ORDER_STATUS_ERROR: 'GET_ALL_ORDER_STATUS_ERROR',

  GET_ALL_ORDER_TYPES: 'GET_ALL_ORDER_TYPES',
  GET_ALL_ORDER_TYPES_REQUEST: 'GET_ALL_ORDER_TYPES_REQUEST',
  GET_ALL_ORDER_TYPES_SUCCESS: 'GET_ALL_ORDER_TYPES_SUCCESS',
  GET_ALL_ORDER_TYPES_ERROR: 'GET_ALL_ORDER_TYPES_ERROR',

  GET_ALL_ORDERS_PRODUCT: 'GET_ALL_ORDERS_PRODUCT',
  GET_PAYMENTS_BY_CPP: 'GET_PAYMENTS_BY_CPP',
  GET_ALL_ORDERS_PRODUCT_REQUEST: 'GET_ALL_ORDERS_PRODUCT_REQUEST',
  GET_ALL_ORDERS_PRODUCT_SUCCESS: 'GET_ALL_ORDERS_PRODUCT_SUCCESS',
  GET_ALL_ORDERS_PRODUCT_ERROR: 'GET_ALL_ORDERS_PRODUCT_ERROR',

  GET_ALL_ORDER_PAYMENT_TYPES: 'GET_ALL_ORDER_PAYMENT_TYPES',
  GET_ALL_ORDER_PAYMENT_TYPES_REQUEST: 'GET_ALL_ORDER_PAYMENT_TYPES_REQUEST',
  GET_ALL_ORDER_PAYMENT_TYPES_SUCCESS: 'GET_ALL_ORDER_PAYMENT_TYPES_SUCCESS',
  GET_ALL_ORDER_PAYMENT_TYPES_ERROR: 'GET_ALL_ORDER_PAYMENT_TYPES_ERROR',

  GET_SINGLE_ORDER: 'GET_SINGLE_ORDER',
  GET_SINGLE_ORDER_REQUEST: 'GET_SINGLE_ORDER_REQUEST',
  GET_SINGLE_ORDER_SUCCESS: 'GET_SINGLE_ORDER_SUCCESS',
  GET_SINGLE_ORDER_ERROR: 'GET_SINGLE_ORDER_ERROR',

  DELETE_SINGLE_ORDER: 'DELETE_SINGLE_ORDER',
  DELETE_SINGLE_ORDER_REQUEST: 'DELETE_SINGLE_ORDER_REQUEST',
  DELETE_SINGLE_ORDER_SUCCESS: 'DELETE_SINGLE_ORDER_SUCCESS',
  DELETE_SINGLE_ORDER_ERROR: 'DELETE_SINGLE_ORDER_ERROR',

  CONFIRM_SINGLE_ORDER: 'CONFIRM_SINGLE_ORDER',
  CONFIRM_SINGLE_ORDER_REQUEST: 'CONFIRM_SINGLE_ORDER_REQUEST',
  CONFIRM_SINGLE_ORDER_SUCCESS: 'CONFIRM_SINGLE_ORDER_SUCCESS',
  CONFIRM_SINGLE_ORDER_ERROR: 'CONFIRM_SINGLE_ORDER_ERROR',

  GET_TRANSPORTIST_RATE: 'GET_TRANSPORTIST_RATE',
  GET_TRANSPORTIST_RATE_REQUEST: 'GET_TRANSPORTIST_RATE_REQUEST',
  GET_TRANSPORTIST_RATE_SUCCESS: 'GET_TRANSPORTIST_RATE_SUCCESS',
  GET_TRANSPORTIST_RATE_ERROR: 'GET_TRANSPORTIST_RATE_ERROR',

  GET_SINGLE_CLIENT_HISTORY: 'GET_SINGLE_CLIENT_HISTORY',
  GET_SINGLE_CLIENT_HISTORY_REQUEST: 'GET_SINGLE_CLIENT_HISTORY_REQUEST',
  GET_SINGLE_CLIENT_HISTORY_SUCCESS: 'GET_SINGLE_CLIENT_HISTORY_SUCCESS',
  GET_SINGLE_CLIENT_HISTORY_ERROR: 'GET_SINGLE_CLIENT_HISTORY_ERROR',

  GET_ORDER_BALANCE: 'GET_ORDER_BALANCE',
  GET_ORDER_BALANCE_EXCEL: 'GET_ORDER_BALANCE_EXCEL',

  ON_CREATE_VISIT_ORDER: 'ON_CREATE_VISIT_ORDER',

  ON_CREATE_PAYMENT_ORDER: 'ON_CREATE_PAYMENT_ORDER',

  GET_USERS_REPORT: 'GET_USERS_REPORT',
  GET_DELIVERER_REPORT: 'GET_DELIVERER_REPORT',
  GET_SELLER_REPORT: 'GET_SELLER_REPORT',
  GET_PRODUCT_REPORT: 'GET_PRODUCT_REPORT',
  GET_PRODUCT_REPORT_EXCEL: 'GET_PRODUCT_REPORT_EXCEL',

  ON_CONFIRM_PAYMENT_ORDER: 'ON_CONFIRM_PAYMENT_ORDER',
  ON_CONFIRM_PAYMENT_ORDER_REQUEST: 'ON_CONFIRM_PAYMENT_ORDER_REQUEST',
  ON_CONFIRM_PAYMENT_ORDER_SUCCESS: 'ON_CONFIRM_PAYMENT_ORDER_SUCCESS',
  ON_CONFIRM_PAYMENT_ORDER_ERROR: 'ON_CONFIRM_PAYMENT_ORDER_ERROR',

  ON_CONFIRM_VISIT_ORDER: 'ON_CONFIRM_VISIT_ORDER',
  ON_CONFIRM_VISIT_ORDER_REQUEST: 'ON_CONFIRM_VISIT_ORDER_REQUEST',
  ON_CONFIRM_VISIT_ORDER_SUCCESS: 'ON_CONFIRM_VISIT_ORDER_SUCCESS',
  ON_CONFIRM_VISIT_ORDER_ERROR: 'ON_CONFIRM_VISIT_ORDER_ERROR',

  ON_CREATE_ORDERS_VISIT: 'ON_CREATE_ORDERS_VISIT',

  GET_VISITS_ORDERS: 'GET_VISITS_ORDERS',
  GET_VISITS_ORDERS_REQUEST: 'GET_VISITS_ORDERS_REQUEST',
  GET_VISITS_ORDERS_SUCCESS: 'GET_VISITS_ORDERS_SUCCESS',
  GET_VISITS_ORDERS_ERROR: 'GET_VISITS_ORDERS_ERROR',

  GET_PRODUCT_PRICES: 'GET_PRODUCT_PRICES',
  GET_PRODUCT_PRICES_REQUEST: 'GET_PRODUCT_PRICES_REQUEST',
  GET_PRODUCT_PRICES_SUCCESS: 'GET_PRODUCT_PRICES_SUCCESS',
  GET_PRODUCT_PRICES_ERROR: 'GET_PRODUCT_PRICES_ERROR',

  GET_INVOICE: 'GET_INVOICE',
  PRINT_ORDER: 'PRINT_ORDER',
  WAREHOUSE_HISTORY: 'WAREHOUSE_HISTORY',

  GET_EXTERNAL_ORDERS: 'GET_EXTERNAL_ORDERS',
  GET_EXTERNAL_ORDERS_REQUEST: 'GET_EXTERNAL_ORDERS_REQUEST',
  GET_EXTERNAL_ORDERS_SUCCESS: 'GET_EXTERNAL_ORDERS_SUCCESS',
  GET_EXTERNAL_ORDERS_ERROR: 'GET_EXTERNAL_ORDERS_ERROR',

  GET_BI_PAYMENTS_HISTORY_CURRENT_USER: 'GET_BI_PAYMENTS_HISTORY_CURRENT_USER',
  GET_SALE_ORDERS_BALANCE_CURRENT_USER: 'GET_SALE_ORDERS_BALANCE_CURRENT_USER',

  GET_ORDERS_PRE_SALE: 'GET_ORDERS_PRE_SALE',

  ORDERS_BY_CLIENT: 'ORDERS_BY_CLIENT',
  ORDER_BY_CLIENT: 'ORDER_BY_CLIENT',

  EXTERNAL_ORDER: 'EXTERNAL_ORDER',
  GET_G_ORDERS: 'GET_G_ORDERS',
  CONFIRM_ORDER: 'CONFIRM_ORDER',
  ORDER_USERS: 'ORDER_USERS',
  All_ADMIN_ORDERS: 'All_ADMIN_ORDERS',
  CONFIRM_VISIT_ORDER_NO_SALE: 'CONFIRM_VISIT_ORDER_NO_SALE',

  GET_EXCEL: 'GET_EXCEL',
  UPDATE_PARAMS: 'UPDATE_PARAMS',
  GET_BALANCE_REPORT: 'GET_BALANCE_REPORT',

  UPDATE_PRODUCTS_ORDER: 'UPDATE_PRODUCTS_ORDER',
  PROCESS_ORDER: 'PROCESS_ORDER',
  GET_GROUP: 'GET_GROUP',
  GET_GROUP_BY_PACKAGES: 'GET_GROUP_BY_PACKAGES',
  CHANGE_STATUS: 'CHANGE_STATUS',
  GET_PACKAGE: 'GET_PACKAGE',
  GET_EXISTENCE: 'GET_EXISTENCE',
  GET_PRODUCTS_ON_ORDERS: 'GET_PRODUCTS_ON_ORDERS',
  GET_ORDERS_LINKED: 'GET_ORDERS_LINKED',
  GET_TRANSFER_ORDERS: 'GET_TRANSFER_ORDERS',
  GET_PAYMENT_ORDERS: 'GET_PAYMENT_ORDERS',
  GET_ORDERS_TO_PAY_BY_ORDER: 'GET_ORDERS_TO_PAY_BY_ORDER',
  GET_PAYMENT_ORDER: 'GET_PAYMENT_ORDER',
  REJECT_ORDER: 'REJECT_ORDER',
  ON_DEFERRED_PAYMENT: 'ON_DEFERRED_PAYMENT',
  ON_NULLIFY_PAYMENT: 'ON_NULLIFY_PAYMENT',
  ON_NULLIFY_RECEIPT_PAYMENT: 'ON_NULLIFY_RECEIPT_PAYMENT',
  GET_ORDER_REPORT: 'GET_ORDER_REPORT',
  CASH_REGISTER_REPORT: 'CASH_REGISTER_REPORT',
  ADD_RETENTION: 'ADD_RETENTION',
  ON_PROCESS_ORDER: 'ON_PROCESS_ORDER',
  GET_ROUTE: 'GET_ROUTE',
  SET_POSITION: 'SET_POSITION',
  GET_ORDER_COUNT: 'GET_ORDER_COUNT',
  GET_ADMIN_REPORT: 'GET_ADMIN_REPORT',
  GET_DASHBOARD_ORDERS: 'GET_DASHBOARD_ORDERS',
  ON_ACCREDIT_WITH_BANK: 'ON_ACCREDIT_WITH_BANK',
  GET_INVOICES_TICKET: 'GET_INVOICES_TICKET',
  PAY_WITH_VOUCHER: 'PAY_WITH_VOUCHER',
  CHANGES_ORDER_DATE: 'CHANGES_ORDER_DATE',
  CREATE_VOUCHER: 'CREATE_VOUCHER',
  DELETE_VOUCHER: 'DELETE_VOUCHER',
  LIBERATE_VOUCHER: 'LIBERATE_VOUCHER',
  DISPATCH_ORDER: 'DISPATCH_ORDER',
  CHANGE_ORDER_CATEGORIES: 'CHANGE_ORDER_CATEGORIES',
  GET_HISTORY: 'GET_HISTORY',
  CREATE_CARD_LIQUIDATION: 'CREATE_CARD_LIQUIDATION',
  GET_CARD_LIQUIDATION: 'GET_CARD_LIQUIDATION',
  GET_ORDERS_LIQUIDATED: 'GET_ORDERS_LIQUIDATED',
  NULLIFY_CARD_LIQUIDATION: 'NULLIFY_CARD_LIQUIDATION',
  RETURN_PRODUCT: 'RETURN_PRODUCT',
  PRODUCTS_PROCESS_AVAILABLE: 'PRODUCTS_PROCESS_AVAILABLE',
  EDIT_MASSIVE_VALUES: 'EDIT_MASSIVE_VALUES',
  GET_PAYMENT_RECEIPT: 'GET_PAYMENT_RECEIPT',
  EDIT_MASSIVE_PAYMENT: 'EDIT_MASSIVE_PAYMENTS',
  GET_ARCHIVED_PAYMENT: 'GET_ARCHIVED_PAYMENT',
  EDIT_ARCHIVED_PAYMENT: 'EDIT_ARCHIVED_PAYMENT',
  GET_QUOTE_ADJUSTMENT: 'GET_QUOTE_ADJUSTMENT',
  ON_QUOTE_ADJUSTMENT: 'ON_QUOTE_ADJUSTMENT',
  ON_NULLIFY_SPECIFIC_PAYMENT_NUMBER: 'ON_NULLIFY_SPECIFIC_PAYMENT_NUMBER',
  SEE_PAYMENT_TYPE: 'SEE_PAYMENT_TYPE',
  PAYMENT_VOUCHER: 'PAYMENT_VOUCHER',
  GET_PAYMENTS_BY_ORDER: 'GET_PAYMENTS_BY_ORDER',
  GET_ORDERS_SUMMARY: 'GET_ORDERS_SUMMARY',
  MASSIVE_CONFIRMATION: 'MASSIVE_CONFIRMATION',
  GET_ALL_ORDERS_LITE: 'GET_ALL_ORDERS_LITE',
  GET_PAYMENT_REPORT_CSV: 'GET_PAYMENT_REPORT_CSV',
  MASSIVE_PAYMENTS: 'MASSIVE_PAYMENTS',
  CHANGE_DATE_VISIT: 'CHANGE_DATE_VISIT',
  ADJUST_VISIT_ORDER: 'ADJUST_VISIT_ORDER',
  PRINT_INVOICE: 'PRINT_INVOICE',
  GET_ORDERS_ADDONS: 'GET_ORDERS_ADDONS',
  PAY_ORDER_ACCOUNT: 'PAY_ORDER_ACCOUNT',
  GET_ORDER_VERSION: 'GET_ORDER_VERSION',
  GET_ORDER_VERSION_ITEMS: 'GET_ORDER_VERSION_ITEMS',
  VERIFY_ORDER_USE_RECIPES: 'VERIFY_ORDER_USE_RECIPES',
  ORDER_DETAIL_ID: 'ORDER_DETAIL_ID',
  GET_ORDER_DETAILS: 'GET_ORDER_DETAILS',
}

const getVisitsOrdersRequest = () => ({
  type: actionTypes.GET_VISITS_ORDERS_REQUEST,
})
const getVisitsOrdersSuccess = orders => ({
  type: actionTypes.GET_VISITS_ORDERS_SUCCESS,
  orders,
})
const getVisitsOrdersError = error => ({
  type: actionTypes.GET_VISITS_ORDERS_ERROR,
  error,
})

const convertPresaleRequest = () => ({
  type: actionTypes.CONVERT_PRESALE_REQUEST,
})
const convertPresaleSuccess = order => ({
  type: actionTypes.CONVERT_PRESALE_SUCCESS,
  order,
})
const convertPresaleError = error => ({
  type: actionTypes.CONVERT_PRESALE_ERROR,
  error,
})

const getAllOrdersRequest = () => ({
  type: actionTypes.GET_ALL_ORDERS_REQUEST,
})
const getAllOrdersSuccess = orders => ({
  type: actionTypes.GET_ALL_ORDERS_SUCCESS,
  orders,
})
const getAllOrdersError = error => ({
  type: actionTypes.GET_ALL_ORDERS_ERROR,
  error,
})

const getAllOrdersMapRequest = () => ({
  type: actionTypes.GET_ALL_ORDERS_MAP_REQUEST,
})
const getAllOrdersMapSuccess = orders => ({
  type: actionTypes.GET_ALL_ORDERS_MAP_SUCCESS,
  orders,
})
const getAllOrdersMapError = error => ({
  type: actionTypes.GET_ALL_ORDERS_MAP_ERROR,
  error,
})

const getAllOrdersProductRequest = () => ({
  type: actionTypes.GET_ALL_ORDERS_PRODUCT_REQUEST,
})
const getAllOrdersProductSuccess = ordersProduct => ({
  type: actionTypes.GET_ALL_ORDERS_PRODUCT_SUCCESS,
  ordersProduct,
})
const getAllOrdersProductError = error => ({
  type: actionTypes.GET_ALL_ORDERS_PRODUCT_ERROR,
  error,
})

const getAllOrderStatusRequest = () => ({
  type: actionTypes.GET_ALL_ORDER_STATUS_REQUEST,
})
const getAllOrderStatusSuccess = orderStatus => ({
  type: actionTypes.GET_ALL_ORDER_STATUS_SUCCESS,
  orderStatus,
})
const getAllOrderStatusError = error => ({
  type: actionTypes.GET_ALL_ORDER_STATUS_ERROR,
  error,
})

const getAllOrderTypesRequest = () => ({
  type: actionTypes.GET_ALL_ORDER_TYPES_REQUEST,
})
const getAllOrderTypesSuccess = orderTypes => ({
  type: actionTypes.GET_ALL_ORDER_TYPES_SUCCESS,
  orderTypes,
})
const getAllOrderTypesError = error => ({
  type: actionTypes.GET_ALL_ORDER_TYPES_ERROR,
  error,
})

const getAllOrderPaymentTypesRequest = () => ({
  type: actionTypes.GET_ALL_ORDER_PAYMENT_TYPES_REQUEST,
})
const getAllOrderPaymentTypesSuccess = orderPayments => ({
  type: actionTypes.GET_ALL_ORDER_PAYMENT_TYPES_SUCCESS,
  orderPayments,
})
const getAllOrderPaymentTypesError = error => ({
  type: actionTypes.GET_ALL_ORDER_PAYMENT_TYPES_ERROR,
  error,
})

const getSingleOrderRequest = () => ({
  type: actionTypes.GET_SINGLE_ORDER_REQUEST,
})
const getSingleOrderSuccess = order => ({
  type: actionTypes.GET_SINGLE_ORDER_SUCCESS,
  order,
})
const getSingleOrderError = error => ({
  type: actionTypes.GET_SINGLE_ORDER_ERROR,
  error,
})

// const deleteSingleOrderRequest = () => ({
//   type: actionTypes.DELETE_SINGLE_ORDER_REQUEST,
// })
// const deleteSingleOrderSuccess = status => ({
//   type: actionTypes.DELETE_SINGLE_ORDER_SUCCESS,
//   status,
// })
// const deleteSingleOrderError = error => ({
//   type: actionTypes.DELETE_SINGLE_ORDER_ERROR,
//   error,
// })

const confirmSingleOrderRequest = () => ({
  type: actionTypes.CONFIRM_SINGLE_ORDER_REQUEST,
})
const confirmSingleOrderSuccess = order => ({
  type: actionTypes.CONFIRM_SINGLE_ORDER_SUCCESS,
  order,
})
const confirmSingleOrderError = error => ({
  type: actionTypes.CONFIRM_SINGLE_ORDER_ERROR,
  error,
})

const getTransportistRequest = () => ({
  type: actionTypes.GET_TRANSPORTIST_RATE_REQUEST,
})
const getTransportistSuccess = response => ({
  type: actionTypes.GET_TRANSPORTIST_RATE_SUCCESS,
  response,
})
const getTransportistError = error => ({
  type: actionTypes.GET_TRANSPORTIST_RATE_ERROR,
  error,
})

const getSingleClientHistoryRequest = () => ({
  type: actionTypes.GET_SINGLE_CLIENT_HISTORY_REQUEST,
})
const getSingleClientHistorySuccess = orders => ({
  type: actionTypes.GET_SINGLE_CLIENT_HISTORY_SUCCESS,
  orders,
})
const getSingleClientHistoryError = error => ({
  type: actionTypes.GET_SINGLE_CLIENT_HISTORY_ERROR,
  error,
})

const getPricesRequest = () => ({
  type: actionTypes.GET_PRODUCT_PRICES_REQUEST,
})
const getPricesSuccess = orders => ({
  type: actionTypes.GET_PRODUCT_PRICES_SUCCESS,
  orders,
})
const getPricesError = error => ({
  type: actionTypes.GET_PRODUCT_PRICES_ERROR,
  error,
})

const getExternalOrdersRequest = () => ({
  type: actionTypes.GET_EXTERNAL_ORDERS_REQUEST,
})
const getExternalOrdersSuccess = response => ({
  type: actionTypes.GET_EXTERNAL_ORDERS_SUCCESS,
  response,
})
const getExternalOrdersError = error => ({
  type: actionTypes.GET_EXTERNAL_ORDERS_ERROR,
  error,
})

const savePackageSuccess = packageId => ({
  type: actionTypes.GET_PACKAGE,
  packageId,
})
export const savePackage = value => dispatch => {
  dispatch(savePackageSuccess(value))
}

export const getExternalOrder = () => async dispatch => {
  dispatch(getExternalOrdersRequest())
  try {
    const response = await OrdersService.getExternalOrders()
    dispatch(getExternalOrdersSuccess(response))
  } catch (e) {
    dispatch(getExternalOrdersError(e))
  }
}

const getOfferPrice = async (product, index, order) => {
  let price = { value: 0, index, orderId: order.orderId }
  try {
    const prices = await OfferService.getPriceOfProduct(
      product.product.companyId,
      order.client.deliverer,
      product.product.id,
      order.client.latitude,
      order.client.longitude,
    )
    prices.forEach(p => {
      if (product.quantity >= p.minimum && product.quantity <= p.maximum) {
        price.value = p.price
      }
    })
  } catch (e) {
    console.error(e)
  }
  return price
}

export const setInformationOrder = order => dispatch =>
  dispatch(getSingleOrderSuccess(order))

export const getProductPrice = orders => async dispatch => {
  dispatch(getPricesRequest())
  const promises = []
  orders.forEach(o => {
    o.details.forEach((p, i) => {
      promises.push(getOfferPrice(p, i, o))
    })
  })
  return Promise.all(promises)
    .then(response => {
      let newOrders = Object.assign([], orders)
      response.forEach(r => {
        newOrders
          .filter(o => o.orderId === r.orderId)
          .forEach(o => {
            o.details[r.index].offerPrice = r.value
          })
      })
      dispatch(getPricesSuccess(newOrders))
    })
    .catch(e => dispatch(getPricesError(e)))
}

export const getAllOrdersBySearch =
  ({
    client,
    deleted,
    payment,
    status,
    type,
    warehouse,
    sDate,
    eDate,
    confirmedBy,
    deliveredBy,
  }) =>
  async dispatch => {
    dispatch(getAllOrdersRequest())
    try {
      const orders = await OrdersService.getAllOrdersBySearch({
        client,
        deleted,
        payment,
        status,
        type,
        warehouse,
        sDate,
        eDate,
        confirmedBy,
        deliveredBy,
      })
      dispatch(getAllOrdersSuccess(orders))
    } catch (error) {
      console.log('action', error)
      dispatch(getAllOrdersError(error))
    }
  }

export const getAllOrdersByProduct = (productId, start, end) => async dispatch => {
  dispatch(getAllOrdersProductRequest())
  try {
    const order = await OrdersService.getOrdersByProduct(productId, start, end)
    dispatch(getAllOrdersProductSuccess(order))
  } catch (error) {
    console.log('action', error)
    dispatch(getAllOrdersProductError(error))
  }
}

export const getAllOrdersByModelProduct = (productId, start, end) => async dispatch => {
  dispatch(getAllOrdersProductRequest())
  try {
    const order = await OrdersService.getOrdersByModelProduct(productId, start, end)
    dispatch(getAllOrdersProductSuccess(order))
  } catch (error) {
    console.log('action', error)
    dispatch(getAllOrdersProductError(error))
  }
}

export const createNewOrder = request => async dispatch => {
  const process = async () => ({
    order: await OrdersService.createNewOrder(request),
  })
  dispatch(executeAction(actionTypes.CREATE_NEW_ORDER, process))
}

export const convertPresale =
  (id, new_date, warehouse, deliverer, remove, payed) => async dispatch => {
    dispatch(convertPresaleRequest())
    try {
      const response = await OrdersService.convertPresale(
        id,
        new_date,
        warehouse,
        deliverer,
        remove,
        payed,
      )
      dispatch(convertPresaleSuccess(response))
    } catch (error) {
      dispatch(convertPresaleError(error))
    }
  }

export const getAllOrders = params => async dispatch => {
  dispatch(getAllOrdersRequest())
  try {
    const orders = await OrdersService.getAllOrders(params)
    dispatch(getAllOrdersSuccess(orders))
  } catch (error) {
    console.log('action', error)
    dispatch(getAllOrdersError(error))
  }
}

export const getOrdersSummary = params => async dispatch => {
  const process = async () => ({
    response: await OrdersService.getOrdersSummary(params),
  })
  dispatch(executeAction(actionTypes.GET_ORDERS_SUMMARY, process))
}

export const getOrderByNumber = (number, auth) => async dispatch => {
  dispatch(getAllOrdersRequest())
  try {
    const orders = await OrdersService.getOrderByNumber(number, auth)
    dispatch(getAllOrdersSuccess(orders))
  } catch (error) {
    console.log('action', error)
    dispatch(getAllOrdersError(error))
  }
}

export const getDashboardOrders = params => async dispatch => {
  const process = async () => ({
    orders: await OrdersService.getAllOrders(params),
  })
  dispatch(executeAction(actionTypes.GET_DASHBOARD_ORDERS, process))
}

export const getAdminReport = params => async dispatch => {
  const process = async () => ({
    report: await OrdersService.getAdminReport(params),
  })
  dispatch(executeAction(actionTypes.GET_ADMIN_REPORT, process))
}

export const getAllOrdersMap = params => async dispatch => {
  dispatch(getAllOrdersMapRequest())
  try {
    const ordersMap = await OrdersService.getAllOrdersMap(params)
    dispatch(getAllOrdersMapSuccess(ordersMap))
  } catch (error) {
    console.log('action', error)
    dispatch(getAllOrdersMapError(error))
  }
}

export const getOrdersCount = (start, end) => async dispatch => {
  const process = async () => ({
    counts: await OrdersService.getOrdersCount({ start, end, type: 1 }),
  })
  dispatch(executeAction(actionTypes.GET_ORDER_COUNT, process))
}

export const getOrderReport =
  (
    start,
    end,
    pending,
    deliverer,
    vendor,
    type,
    filterType,
    client,
    warehouse,
    payment,
    request,
  ) =>
  async dispatch => {
    const process = async () => ({
      report: await OrdersService.getOrderReport(
        {
          start,
          end,
          pending,
          deliverer,
          vendor,
          type,
          filterType,
          client,
          warehouse,
          payment,
        },
        request,
      ),
    })
    dispatch(executeAction(actionTypes.GET_ORDER_REPORT, process))
  }

export const getAllOrderStatus = () => async dispatch => {
  const orderStatus = getState(selectGetAllOrderStatus)
  if (orderStatus.length) return

  dispatch(getAllOrderStatusRequest())
  try {
    const status = await OrdersService.getAllStatus()
    dispatch(getAllOrderStatusSuccess(status))
  } catch (error) {
    dispatch(getAllOrderStatusError(error))
  }
}

export const getAllOrderTypes = () => async dispatch => {
  dispatch(getAllOrderTypesRequest())
  try {
    const status = await OrdersService.getAllOrderTypes()
    dispatch(getAllOrderTypesSuccess(status))
  } catch (error) {
    console.log('action', error)
    dispatch(getAllOrderTypesError(error))
  }
}

export const getAllOrderPaymentTypes = () => async dispatch => {
  dispatch(getAllOrderPaymentTypesRequest())
  try {
    const status = await OrdersService.getAllOrderTypes()
    dispatch(getAllOrderPaymentTypesSuccess(status))
  } catch (error) {
    console.log('action', error)
    dispatch(getAllOrderPaymentTypesError(error))
  }
}

export const getSingleOrder = id => async dispatch => {
  const process = async () => ({
    order: await OrdersService.getSingleOrder(id),
  })
  dispatch(executeAction(actionTypes.GET_SINGLE_ORDER, process))
}

export const deleteSingleOrder = (id, description) => async dispatch => {
  const process = async () => ({
    status: await OrdersService.deleteSingleOrder(id, description),
  })
  dispatch(executeAction(actionTypes.DELETE_SINGLE_ORDER, process))
}

export const confirmSingleOrder =
  (
    id,
    moduleId,
    warehouseId,
    delivererId,
    removeInventory,
    paymentDate,
    user,
    commentary,
    createInventory,
    retention,
  ) =>
  async dispatch => {
    dispatch(confirmSingleOrderRequest())
    try {
      const status = await OrdersService.deliverOrder(id, moduleId, {
        removeInventory,
        paymentDate,
        user,
        commentary,
        createInventory,
        retention,
      })
      dispatch(confirmSingleOrderSuccess(status))
    } catch (error) {
      console.log('action', error.response)
      dispatch(confirmSingleOrderError(error))
    }
  }

export const deliverOrder = (id, module, request, images) => async dispatch => {
  const process = async () => {
    if (images && images.length > 0)
      await UploadService.addImages(imageTypes.INVOICES.id, id, images)
    return {
      order: await OrdersService.deliverOrder(id, module, request),
    }
  }
  dispatch(executeAction(actionTypes.CONFIRM_SINGLE_ORDER, process))
}

export const dispatchOrder = (id, request) => async dispatch => {
  const process = async () => await OrdersService.dispatchOrder(id, request)
  dispatch(executeAction(actionTypes.DISPATCH_ORDER, process))
}

/*
* export const confirmSingleOrder = (id,cashbox,latitude,longitude, module) => async dispatch => {
    dispatch(confirmSingleOrderRequest ());
    try {
        const status = await OrdersService.confirmOrder(id,cashbox,latitude,longitude, module);
        dispatch(confirmSingleOrderSuccess(status));
    } catch (error) {
        console.log("action", error.response);
        dispatch(confirmSingleOrderError(error));
    }
};
* */

export const getTransportistRate = () => async dispatch => {
  dispatch(getTransportistRequest())
  try {
    const rate = await OrdersService.getTransportistRate()
    dispatch(getTransportistSuccess(rate))
  } catch (error) {
    console.log('action', error)
    dispatch(getTransportistError(error))
  }
}

export const getClientHistory = id => async dispatch => {
  dispatch(getSingleClientHistoryRequest())
  try {
    let status = await OrdersService.getSingleClientHistory(id)
    if (status.length > 0) {
      status = status.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
    }
    dispatch(getSingleClientHistorySuccess(status.reverse()))
  } catch (error) {
    console.log('action', error.response)
    dispatch(getSingleClientHistoryError(error))
  }
}

export const getOrdersPreSale = () => dispatch => {
  const process = async () => ({
    ordersPreSale: await OrdersService.getPreSaleOrders(),
  })
  dispatch(executeAction(actionTypes.GET_ORDERS_PRE_SALE, process))
}

export const getOrderBalanceByCustomers = (id, categories, start, end) => dispatch => {
  const process = async () => ({
    ordersWithBalance: await OrdersService.getOrderBalance(id, categories, start, end),
  })
  dispatch(executeAction(actionTypes.GET_ORDER_BALANCE, process))
}

export const getOrderBalanceByCustomersExcel =
  (id, categories, start, end) => dispatch => {
    const process = async () => ({
      response: await OrdersService.getOrderBalanceExcel(id, categories, start, end),
    })
    dispatch(executeAction(actionTypes.GET_ORDER_BALANCE_EXCEL, process))
  }

export const getInvoiceByOrder = (id, documentType) => dispatch => {
  let process
  if (!documentType)
    process = async () => ({
      invoice: await OrdersService.getInvoiceByOrder(id),
    })
  else
    process = async () => ({
      invoice: await OrdersService.getInvoiceByDocumentType(id, documentType),
    })
  dispatch(executeAction(actionTypes.GET_INVOICE, process))
}

export const printOrder = (id, defaultName, quote, workOrder) => dispatch => {
  const process = async () =>
    await OrdersService.printOrder(id, defaultName, quote, workOrder)
  dispatch(executeAction(actionTypes.PRINT_ORDER, process))
}

export const onCreateOrderVisit = visit => dispatch => {
  const process = async () => ({
    orderVisit: await OrdersService.onCreateOrderVisit(visit),
  })
  dispatch(executeAction(actionTypes.ON_CREATE_VISIT_ORDER, process))
}

export const onCreateOrderPayment = payment => dispatch => {
  const process = async () => {
    const orderPayment = await OrdersService.onCreateOrderPayment(payment)

    if (orderPayment.deposit && payment.images && payment.images.length > 0) {
      await UploadService.addImages(
        imageTypes.DEPOSITS.id,
        orderPayment.deposit,
        payment.images,
      )
    }

    return { orderPayment }
  }
  dispatch(executeAction(actionTypes.ON_CREATE_PAYMENT_ORDER, process))
}

export const onConfirmOrderPayment = (id, clientId) => dispatch => {
  const process = async () => ({
    response: await OrdersService.onConfirmOrderPayment(id, clientId),
  })
  dispatch(executeAction(actionTypes.ON_CONFIRM_PAYMENT_ORDER, process))
  /*
    dispatch(confirmPaymentOrderRequest());
    try {
        const response = await OrdersService.onConfirmOrderPayment(id, clientId);
        dispatch(confirmPaymentOrderSuccess(response.data));
    } catch (error) {
        console.error('hola', error.response);
        dispatch(confirmPaymentOrderError(error));
    }
    */
}

export const onConfirmOrderVisit = (visitId, sale, module) => async dispatch => {
  const process = async () => ({
    response: await OrdersService.onConfirmOrderVisit(visitId, sale, module),
  })
  dispatch(executeAction(actionTypes.ON_CONFIRM_VISIT_ORDER, process))
}

export const onCreateOrdersVisit = (request, params) => async dispatch => {
  const process = async () => ({
    response: await OrdersService.onCreateOrdersVisit(request, params),
  })
  dispatch(executeAction(actionTypes.ON_CREATE_ORDERS_VISIT, process))
}

export const getVisitsOrders = transportist => async dispatch => {
  dispatch(getVisitsOrdersRequest())
  try {
    const response = await OrdersService.getVisitsOrdersList(transportist)
    dispatch(getVisitsOrdersSuccess(response))
  } catch (e) {
    console.error('error', e)
    dispatch(getVisitsOrdersError(e))
  }
}

export const getSaleOrdersBalanceCurrentUser = () => dispatch => {
  const process = async () => ({
    response: await OrdersService.getSaleOrdersBalanceCurrentUser(),
  })
  dispatch(executeAction(actionTypes.GET_SALE_ORDERS_BALANCE_CURRENT_USER, process))
}

export const getBIPaymentsHistoryCurrentUser =
  (agreementId, startDate, endDate) => dispatch => {
    const process = async () => ({
      response: await OrdersService.getBIPaymentsHistoryCurrentUser(
        agreementId,
        startDate,
        endDate,
      ),
    })
    dispatch(executeAction(actionTypes.GET_BI_PAYMENTS_HISTORY_CURRENT_USER, process))
  }

export const getOrdersByClient = () => dispatch => {
  const process = async () => ({
    ordersByClient: await OrdersService.getOrdersByClient(),
  })
  dispatch(executeAction(actionTypes.ORDERS_BY_CLIENT, process))
}

export const getOrderByClient = id => dispatch => {
  const process = async () => ({
    orderByClient: await OrdersService.getOrderByClient(id),
  })
  dispatch(executeAction(actionTypes.ORDER_BY_CLIENT, process))
}

export const getSingleExternalOrder = id => async dispatch => {
  dispatch(getSingleOrderRequest())
  try {
    const response = await OrdersService.getExternalOrder(id)
    dispatch(getSingleOrderSuccess(response))
  } catch (e) {
    dispatch(getSingleOrderError(e))
  }
}

export const onConfirmOrder = (orderId, warehouseId, principal) => dispatch => {
  const process = async () => ({
    orderConfirmed: await OrdersService.onConfirmOrder(orderId, warehouseId, principal),
  })
  dispatch(executeAction(actionTypes.CONFIRM_ORDER, process))
}

export const getGOrders = clientId => dispatch => {
  const process = async () => ({
    orders: await OrdersService.getAllGOrders(clientId),
  })
  dispatch(executeAction(actionTypes.GET_G_ORDERS, process))
}

export const getOrderUsers = () => dispatch => {
  const users = async () => ({
    orderUsers: await OrdersService.getOrderUsers(),
  })
  dispatch(executeAction(actionTypes.ORDER_USERS, users))
}

export const getAllOrdersAdmin = (from, to, pending) => dispatch => {
  const users = async () => ({
    ordersAdmin: await OrdersService.getAllOrdersAdmin(from, to, pending),
  })
  dispatch(executeAction(actionTypes.All_ADMIN_ORDERS, users))
}

export const getAdminFilters = () => dispatch => {
  const filters = async () => ({
    filterData: await OrdersService.getAdminFilters(),
  })
  dispatch(executeAction(actionTypes.All_ADMIN_ORDERS, filters))
}

export const getExcelByOrders = params => dispatch => {
  const process = async () => ({
    excel: await OrdersService.getExcelByOrders(params),
  })
  dispatch(executeAction(actionTypes.GET_EXCEL, process))
}

export const getExcelByProducts = list => dispatch => {
  const process = async () => ({
    excel: await OrdersService.getExcelByProducts(list),
  })
  dispatch(executeAction(actionTypes.GET_EXCEL, process))
}

export const confirmVisitNoSale = (order, params) => dispatch => {
  const process = async () => ({
    excel: await OrdersService.onConfirmOrderVisitNoSale(order, params),
  })
  dispatch(executeAction(actionTypes.CONFIRM_VISIT_ORDER_NO_SALE, process))
}

/**
 * Obtiene los totales del usuario.s
 * @param {number} startDate Fecha inicial
 * @param {number} endDate Fecha final
 * @param {Object} data filters data
 * @returns IUsersTotals
 */
export const getUsersReport = (startDate, endDate, data) => dispatch => {
  const process = async () => ({
    usersReport: await OrdersService.getUsersReport(startDate, endDate, data),
  })
  dispatch(executeAction(actionTypes.GET_USERS_REPORT, process))
}

export const getDeliverReport = (startDate, endDate) => dispatch => {
  const process = async () => ({
    deliverReport: await OrdersService.getReportByRol(1003, startDate, endDate),
  })
  dispatch(executeAction(actionTypes.GET_DELIVERER_REPORT, process))
}

export const getSellerReport = (startDate, endDate) => dispatch => {
  const process = async () => ({
    sellerReport: await OrdersService.getReportByRol(1002, startDate, endDate),
  })
  dispatch(executeAction(actionTypes.GET_SELLER_REPORT, process))
}

export const getDelivererProductReport =
  (startDate, endDate, user, categorization, userType) => dispatch => {
    const process = async () => ({
      productReport: await OrdersService.getProductReportByRolAndUser(
        1003,
        startDate,
        endDate,
        user,
        categorization,
        userType,
      ),
    })
    dispatch(executeAction(actionTypes.GET_PRODUCT_REPORT, process))
  }

export const getSellerProductReport =
  (startDate, endDate, user, categorization) => dispatch => {
    const process = async () => ({
      productReport: await OrdersService.getProductReportByRolAndUser(
        1002,
        startDate,
        endDate,
        user,
        categorization,
      ),
    })
    dispatch(executeAction(actionTypes.GET_PRODUCT_REPORT, process))
  }

/**
 * Action to get product report by user in excel format.
 * @param {number} startDate Start date of the report
 * @param {number} endDate End date of the report
 * @param {number} user User id
 * @param {string} categorization Filter by categories
 * @param {number} userType User type filter
 * @returns None
 */
export const getProductReportByUserExcel =
  (startDate, endDate, user, categorization, userType) => dispatch => {
    const process = async () => ({
      productReport: await OrdersService.getProductReportByRolAndUserExcel(
        1002,
        startDate,
        endDate,
        user,
        categorization,
        userType,
      ),
    })
    dispatch(executeAction(actionTypes.GET_PRODUCT_REPORT_EXCEL, process))
  }

export const getUserReport =
  (type, startDate, endDate, users, products, warehouses, categories, pos) =>
  dispatch => {
    const process = async () => ({
      productReport: await OrdersService.getProductReportBySeller(
        type,
        startDate,
        endDate,
        users,
        products,
        warehouses,
        categories,
        pos,
      ),
    })
    dispatch(executeAction(actionTypes.GET_PRODUCT_REPORT, process))
  }

export const onUpdateOrderParameters = (parameters, orderId) => dispatch => {
  const process = async () => ({
    update: await OrdersService.onUpdateOrderParameters(parameters, orderId),
  })
  dispatch(executeAction(actionTypes.UPDATE_PARAMS, process))
}

export const onAssignDeliveryCompany = request => dispatch => {
  const process = async () => ({
    assign: await OrdersService.onAssignDeliveryCompany(request),
  })
  dispatch(executeAction(actionTypes.UPDATE_PARAMS, process))
}

export const onUpdateSelectedOrdersParameters = (parameters, orders) => dispatch => {
  const promises = []
  orders.map(async o => {
    promises.push(OrdersService.onUpdateOrderParameters(parameters, o))
  })
  return Promise.all(promises).then(() => {
    dispatch(executeAction(actionTypes.UPDATE_PARAMS))
  })
}

export const onUpdateOrderListParameters = (parameters, orders) => dispatch => {
  const process = async () => ({
    update: await OrdersService.onUpdateOrderListParameters(parameters, orders),
  })
  dispatch(executeAction(actionTypes.UPDATE_PARAMS, process))
}

export const getBalanceReport = params => dispatch => {
  const process = async () => ({
    balance: await OrdersService.getBalanceReport(params),
  })
  dispatch(executeAction(actionTypes.GET_BALANCE_REPORT, process))
}

/**
 *
 * @param {number} orderId Order id to update
 * @param {Object} request Request to update
 * @param {String} request.description Description of the update
 * @param {String} request.number Number of the order
 * @param {number} request.paymentType Payment type of the order
 * @param {number} request.client Client id of the order
 * @param {String} request.clientName Client name of the order
 * @param {String} request.contactName Contact name of the order
 * @param {number} request.felDocumentType FEL document type of the order
 * @param {Array} request.productDetail List of products of the order
 * @param {number} request.commission Commission of the order (Quote)
 * @param {number} request.tax Tax of the order (Quote)
 * @param {number} request.taxPressBell Tax press bell of the order (Quote)
 * @returns
 */
export const updateOrderProducts = (orderId, request) => dispatch => {
  const process = async () => ({
    updated: await OrdersService.updateOrderProducts(orderId, request),
  })
  dispatch(executeAction(actionTypes.UPDATE_PRODUCTS_ORDER, process))
}

export const processOrder = request => dispatch => {
  const process = async () => await OrdersService.processOrder(request)
  dispatch(executeAction(actionTypes.ON_PROCESS_ORDER, process))
}

export const reGroupOrder = request => dispatch => {
  const process = async () => await OrdersService.reGroupOrder(request)
  dispatch(executeAction(actionTypes.ON_PROCESS_ORDER, process))
}

export const getGroup = id => dispatch => {
  const process = async () => ({ orders: await OrdersService.getGroup(id) })
  dispatch(executeAction(actionTypes.GET_GROUP, process))
}

export const getGroupByPackages = id => dispatch => {
  const process = async () => ({
    packages: await OrdersService.getGroupByPackage(id),
  })
  dispatch(executeAction(actionTypes.GET_GROUP_BY_PACKAGES, process))
}

export const changeStatus = id => dispatch => {
  const process = async () => await OrdersService.changeStatus(id)
  dispatch(executeAction(actionTypes.CHANGE_STATUS, process))
}

export const changeStatusByPackage = id => dispatch => {
  const process = async () => await OrdersService.changeStatusByPackage(id)
  dispatch(executeAction(actionTypes.CHANGE_STATUS, process))
}

export const getPackage = packageId => dispatch => {
  const process = async () => ({
    singlePackage: await OrdersService.getPackage(packageId),
  })
  dispatch(executeAction(actionTypes.GET_PACKAGE, process))
}

export const printGroup = (id, module, packages) => dispatch => {
  const process = async () => await OrdersService.printGroup(id, module, packages)
  dispatch(executeAction(actionTypes.PRINT_ORDER, process))
}

export const getProductExistenceByOrder = (id, orders, reserved) => dispatch => {
  const process = async () => ({
    existence: await OrdersService.getProductExistenceByOrder(id, orders, reserved),
  })
  dispatch(executeAction(actionTypes.GET_EXISTENCE, process))
}

export const getProductsOnOrders = params => dispatch => {
  const process = async () => ({
    report: await OrdersService.getProductsOnOrders(params.start, params.end, params),
  })
  dispatch(executeAction(actionTypes.GET_PRODUCTS_ON_ORDERS, process))
}

export const getOrdersLinked = (from, to) => dispatch => {
  const process = async () => ({
    orders: await OrdersService.getOrdersLinked(from, to),
  })
  dispatch(executeAction(actionTypes.GET_ORDERS_LINKED, process))
}

export const getTransferOrders = () => dispatch => {
  const process = async () => ({
    orders: await OrdersService.getTransferOrders(),
  })
  dispatch(executeAction(actionTypes.GET_TRANSFER_ORDERS, process))
}

export const getPaymentOrders = params => async dispatch => {
  const process = async () => ({
    paymentOrders: await OrdersService.getPaymentOrders(params),
  })
  dispatch(executeAction(actionTypes.GET_PAYMENT_ORDERS, process))
}

export const getOrdersToPayByOrder = params => async dispatch => {
  const process = async () => ({
    payload: await OrdersService.getOrdersToPayByOrder(params),
  })
  dispatch(executeAction(actionTypes.GET_ORDERS_TO_PAY_BY_ORDER, process))
}

export const onCreatePaymentSpecificOrder = (orderId, request) => async dispatch => {
  const process = async () => ({
    response: await OrdersService.onCreatePaymentSpecificOrder(orderId, request),
  })
  dispatch(executeAction(actionTypes.ON_CONFIRM_PAYMENT_ORDER, process))
}

export const rejectOrder = (orderId, description, type) => async dispatch => {
  const process = async () =>
    await OrdersService.rejectOrder(orderId, { description, type })
  dispatch(executeAction(actionTypes.REJECT_ORDER, process))
}

export const cashRegisterReport = (start, end, user) => async dispatch => {
  const process = async () => await OrdersService.cashRegisterReport(start, end, user)
  dispatch(executeAction(actionTypes.CASH_REGISTER_REPORT, process))
}

/*
*
* export const getSingleOrder = (id) => async dispatch => {
    dispatch(getSingleOrderRequest ());
    try {
        const status = await OrdersService.getSingleOrder(id);
        dispatch(getSingleOrderSuccess(status));
    } catch (error) {
        console.log("action", error);
        dispatch(getSingleOrderError(error));
    }
};*/

export const onDeferredPayment = (orderId, paymentDate) => async dispatch => {
  const process = async () => ({
    order: await OrdersService.onDeferredPayment(orderId, paymentDate),
  })
  dispatch(executeAction(actionTypes.ON_DEFERRED_PAYMENT, process))
}

export const onNullifyPayment = orderId => async dispatch => {
  const process = async () => ({
    order: await OrdersService.onNullifyPayment(orderId),
  })
  dispatch(executeAction(actionTypes.ON_NULLIFY_PAYMENT, process))
}

export const onNullifyReceiptPayment = orderId => async dispatch => {
  const process = async () => ({
    order: await OrdersService.onNullifyReceiptPayment(orderId),
  })
  dispatch(executeAction(actionTypes.ON_NULLIFY_RECEIPT_PAYMENT, process))
}

export const addRetention = (orderId, amount) => async dispatch => {
  const process = async () => ({
    response: await OrdersService.addRetention(orderId, amount),
  })
  dispatch(executeAction(actionTypes.ADD_RETENTION, process))
}

export const changeDataOrder = order => dispatch => {
  const process = () => ({ order })
  dispatch(executeAction(actionTypes.GET_SINGLE_ORDER, process))
}

export const onProcess = (request, imgS, orderId) => async dispatch => {
  const process = async () => {
    if (imgS && imgS.length > 0)
      await UploadService.addImages(imageTypes.INVOICES.id, orderId, imgS)
    return { response: await OrdersService.onProcess(request) }
  }
  dispatch(executeAction(actionTypes.ON_PROCESS_ORDER, process))
}

export const getRoute = params => async dispatch => {
  const process = async () => ({ route: await OrdersService.getRoute(params) })
  dispatch(executeAction(actionTypes.GET_ROUTE, process))
}

export const setPositions = params => async dispatch => {
  const process = async () => ({
    route: await OrdersService.setPositions(params),
  })
  dispatch(executeAction(actionTypes.SET_POSITION, process))
}

export const accreditOrder = (movementId, liquidationId) => async dispatch => {
  const process = async () => await OrdersService.accreditOrder(movementId, liquidationId)
  dispatch(executeAction(actionTypes.ON_ACCREDIT_WITH_BANK, process))
}

export const getInvoiceTicketPDF = (orderId, number, documentType) => async dispatch => {
  const process = async () =>
    await OrdersService.getInvoiceTicketPDF(orderId, number, documentType)
  dispatch(executeAction(actionTypes.GET_INVOICES_TICKET, process))
}

export const payWithVoucher = (orderId, voucherId, request) => async dispatch => {
  const process = async () =>
    await OrdersService.payWithVoucher(orderId, voucherId, request)
  dispatch(executeAction(actionTypes.PAY_WITH_VOUCHER, process))
}

export const changeDatesOrder = request => async dispatch => {
  const process = async () => await OrdersService.changeDatesOrder(request)
  dispatch(executeAction(actionTypes.CHANGES_ORDER_DATE, process))
}

export const createVoucher = request => async dispatch => {
  const process = async () => await OrdersService.createVoucher(request)
  dispatch(executeAction(actionTypes.CREATE_VOUCHER, process))
}

export const deleteVoucher = voucherId => async dispatch => {
  const process = async () => await OrdersService.deleteVoucher(voucherId)
  dispatch(executeAction(actionTypes.DELETE_VOUCHER, process))
}

export const liberateVoucher = voucherId => async dispatch => {
  const process = async () => await OrdersService.liberateVoucher(voucherId)
  dispatch(executeAction(actionTypes.LIBERATE_VOUCHER, process))
}

export const getExistenceByOrder = orderId => async dispatch => {
  const process = async () => ({
    existence: await OrdersService.getExistenceByOrder(orderId),
  })
  dispatch(executeAction(actionTypes.GET_EXISTENCE, process))
}

export const changeCategorization = (list, category, remove) => async dispatch => {
  const process = async () => ({
    existence: await OrdersService.changeCategorization(list, category, remove),
  })
  dispatch(executeAction(actionTypes.CHANGE_ORDER_CATEGORIES, process))
}

export const getPaymentHistory = id => async dispatch => {
  const process = async () => ({
    historyPayment: await OrdersService.getPaymentHistory(id),
  })
  dispatch(executeAction(actionTypes.GET_HISTORY, process))
}

export const createCardLiquidation = request => async dispatch => {
  const process = async () => await OrdersService.createCardLiquidation(request)
  dispatch(executeAction(actionTypes.CREATE_CARD_LIQUIDATION, process))
}

export const getCardLiquidation = params => async dispatch => {
  const process = async () => ({
    cardsLiquidation: await OrdersService.getCardLiquidation(params),
  })
  dispatch(executeAction(actionTypes.GET_CARD_LIQUIDATION, process))
}

export const getOrdersByLiquidation = liquidationId => async dispatch => {
  const process = async () => ({
    ordersLiquidated: await OrdersService.getOrdersByLiquidation(liquidationId),
  })
  dispatch(executeAction(actionTypes.GET_ORDERS_LIQUIDATED, process))
}

export const returnProduct =
  (orderId, request, type, code, warehouseId) => async dispatch => {
    const process = async () => ({
      response: await OrdersService.returnProduct(
        orderId,
        request,
        type,
        code,
        warehouseId,
      ),
    })
    dispatch(executeAction(actionTypes.RETURN_PRODUCT, process))
  }

export const getInventoryAvailableByOrderDetail = orderId => async dispatch => {
  const process = async () => ({
    productsProcessAvailable: await OrdersService.getInventoryAvailableByOrderDetail(
      orderId,
    ),
  })
  dispatch(executeAction(actionTypes.PRODUCTS_PROCESS_AVAILABLE, process))
}

export const updateListOrders = (request, params) => async dispatch => {
  const process = async () => await OrdersService.updateListOrders(request, params)
  dispatch(executeAction(actionTypes.EDIT_MASSIVE_VALUES, process))
}

export const getPaymentReceipt = (code, params) => async dispatch => {
  const process = async () => ({
    paymentReceipt: await OrdersService.getPaymentReceipt(code, params),
  })
  dispatch(executeAction(actionTypes.GET_PAYMENT_RECEIPT, process))
}

export const editMassivePayments = (request, params) => async dispatch => {
  const process = async () => ({
    paymentReceipt: await OrdersService.editMassivePayments(request, params),
  })
  dispatch(executeAction(actionTypes.EDIT_MASSIVE_PAYMENT, process))
}

export const getArchivedPayment = () => async dispatch => {
  const process = async () => ({
    archived: await OrdersService.getArchivedPayment(),
  })
  dispatch(executeAction(actionTypes.GET_ARCHIVED_PAYMENT, process))
}

export const editArchivedPayment = (request, params) => async dispatch => {
  const process = async () => ({
    archived: await OrdersService.editPaymentArchived(request, params),
  })
  dispatch(executeAction(actionTypes.EDIT_ARCHIVED_PAYMENT, process))
}

export const getQuoteAdjustment =
  (warehouseId, orderType, statusList, start, end) => async dispatch => {
    const process = async () => ({
      response: await OrdersService.getQuoteAdjustment({
        warehouseId,
        orderType,
        statusList,
        start,
        end,
      }),
    })
    dispatch(executeAction(actionTypes.GET_QUOTE_ADJUSTMENT, process))
  }

export const onQuoteAdjustment = (warehouseId, request) => async dispatch => {
  const process = async () => ({
    response: await OrdersService.onQuoteAdjustment(warehouseId, request),
  })
  dispatch(executeAction(actionTypes.ON_QUOTE_ADJUSTMENT, process))
}

export const onNullifySpecificPaymentNumber = (code, params) => async dispatch => {
  const process = async () =>
    await OrdersService.onNullifySpecificPaymentNumber(code, params)
  dispatch(executeAction(actionTypes.ON_NULLIFY_SPECIFIC_PAYMENT_NUMBER, process))
}

export const onSetPaymentId = paymentSee => async dispatch => {
  const process = async () => ({ paymentSee })
  dispatch(executeAction(actionTypes.SEE_PAYMENT_TYPE, process))
}

export const payCreditWithVoucher = (ids, selected, type) => async dispatch => {
  const process = async () => ({
    response: await OrdersService.payCreditWithVoucher(ids, selected, type),
  })
  dispatch(executeAction(actionTypes.PAYMENT_VOUCHER, process))
}

export const getPaymentsByOrder = id => async dispatch => {
  const process = async () => ({
    response: await OrdersService.getPaymentsByOrder(id),
  })
  dispatch(executeAction(actionTypes.GET_PAYMENTS_BY_ORDER, process))
}

export const onConfirmOrderByMassiveAction = (orderIds, params) => async dispatch => {
  const process = async () => ({
    response: await OrdersService.confirmMassiveOrders(orderIds, params),
  })
  dispatch(executeAction(actionTypes.MASSIVE_CONFIRMATION, process))
}

export const onConfirmSingleOrderByMassiveAction = (id, request) => async dispatch => {
  const process = async () => await OrdersService.deliverOrder(id, 1000, request)
  dispatch(executeAction(actionTypes.MASSIVE_CONFIRMATION, process))
}

export const onCertifyOrderByMassiveAction = (orderIds, params) => async dispatch => {
  const process = async () => ({
    response: await OrdersService.certifyMassiveOrders(orderIds, params),
  })
  dispatch(executeAction(actionTypes.MASSIVE_CONFIRMATION, process))
}

export /**
 * Actions para Certificacion de una orden
 * @param {number} orderId Id de la orden a certificar.
 * @param {Object} params Parametros
 * @param {number} params.confirmDateType Fecha de confirmacion
 * @returns None
 */
const onCertifySingleOrderByMassiveAction = (orderId, params) => async dispatch => {
  const process = async () => ({
    response: await OrdersService.certifySingleOrder(orderId, params),
  })
  dispatch(executeAction(actionTypes.MASSIVE_CONFIRMATION, process))
}

export const onCancelOrderByMassiveAction = orderIds => async dispatch => {
  const process = async () => ({
    response: await OrdersService.cancelMassiveOrders(orderIds),
  })
  dispatch(executeAction(actionTypes.MASSIVE_CONFIRMATION, process))
}

export const getAllOrdersLite = params => async dispatch => {
  const process = async () => ({
    ordersLite: await OrdersService.getAllOrdersLite(params),
  })
  dispatch(executeAction(actionTypes.GET_ALL_ORDERS_LITE, process))
}
export const getPaymentsOrder = orderId => async dispatch => {
  const process = async () => ({
    documents: await OrdersService.getDocumentsByOrder(orderId),
  })
  dispatch(executeAction(actionTypes.GET_PAYMENTS_BY_CPP, process))
}

/*  Ejemplo de objeto JSDocs
 * @typedef {Object} ParametroCpc
 * @property {number} start date to start filter
 * @property {number} end   data to end filter
 * @property {number} client id del cliente si hay filtro.
 * @property {number} type    tipo de ordenes default 1
 * @property {string} categories  filtro de categorias para las ordenes.
 * @property {number} paymentType filtro de tipo de pago.
 */

export /**
 * Actions para obtener el reporte csv de las cuentas por cobrar
 * @param {Object} params Parametros
 * @param {number} params.start date to start filter
 * @param {number} params.end   data to end filter
 * @param {number} params.client id del cliente si hay filtro.
 * @param {number} params.type    tipo de ordenes default 1
 * @param {string} params.categories  filtro de categorias para las ordenes.
 * @param {number} params.paymentType filtro de tipo de pago.
 * @returns None
 */
const getCpcReportCSV = params => async dispatch => {
  const process = async () => ({
    response: await OrdersService.getCpcReportCSV(params),
  })
  dispatch(executeAction(actionTypes.GET_PAYMENT_REPORT_CSV, process))
}

export const onCreatePaymentByCSVValues = item => async dispatch => {
  const process = async () => ({
    response: await OrdersService.onCreatePaymentByCSVValues(item),
  })
  dispatch(executeAction(actionTypes.MASSIVE_PAYMENTS, process))
}
/**
 * Action para cambiar la fecha de la visita (posponer)
 * @param {*} id  Id de la visita (cotizap_order)
 * @param {*} date  Nueva fecha
 * @returns None
 */
export const onChangeDateVisit = (id, date) => async dispatch => {
  const process = async () => ({
    response: await OrdersService.onChangeDateVisit(id, date),
  })
  dispatch(executeAction(actionTypes.CHANGE_DATE_VISIT, process))
}

/**
 * Action para realizar el ajuste de despacho
 * @param {*} id                      Id de la orden de visita
 * @param {Object} request            Request
 * @param {number} request.orderId    Id de la orden
 * @param {number} request.typeAdjust Tipo de despacho
 * @param {Array}  request.details    Listado de productos en el despacho,
 * @returns None
 */
export const adjustVisitOrder = (id, request) => async dispatch => {
  const process = async () => ({
    response: await OrdersService.adjustVisitOrder(id, request),
  })
  dispatch(executeAction(actionTypes.ADJUST_VISIT_ORDER, process))
}

export const onPrintInvoice = value => dispatch => {
  const process = async () => ({
    response: value,
  })
  dispatch(executeAction(actionTypes.PRINT_INVOICE, process))
}

/**
 * Action to obtain the orders of an account through the client cotizap
 * @param {number} idClient Cotizap Client id
 * @returns None
 */
export const getOrderAccount = idClient => async dispatch => {
  const process = async () => ({
    response: await OrdersService.getAccountOrdersByClient(idClient),
  })
  dispatch(executeAction(actionTypes.GET_ORDERS_ADDONS, process))
}

/**
 * Action to pay for an order with a card
 * @param {number} orderId Order to pay
 * @returns None
 */
export const payOrderAccount = orderId => async dispatch => {
  const process = async () => ({
    response: await OrdersService.payOrderAccount(orderId),
  })
  dispatch(executeAction(actionTypes.PAY_ORDER_ACCOUNT, process))
}

/**
 * Action to get versions of an order
 * @param {number} orderId Order id
 * @returns None
 */
export const getOrderVersion = orderId => async dispatch => {
  const process = async () => ({
    response: await OrdersService.getVersionsOrder(orderId),
  })
  dispatch(executeAction(actionTypes.GET_ORDER_VERSION, process))
}

/**
 * Action to get version of an order
 * @param {Object} params
 * @param {number} params.orderId
 * @param {number} params.date
 */
export const getOrderVersionItems = params => async dispatch => {
  const process = async () => ({
    response: await OrdersService.getVersionOrder(params),
  })
  dispatch(executeAction(actionTypes.GET_ORDER_VERSION_ITEMS, process))
}

export const verifyOrderUseRecipe = orderId => async dispatch => {
  const process = async () => ({
    response: await OrdersService.verifyOrderUseRecipe(orderId),
  })
  dispatch(executeAction(actionTypes.VERIFY_ORDER_USE_RECIPES, process))
}

export const onNullifyCardLiquidation = liquidationId => async dispatch => {
  const process = async () => await OrdersService.onNullifyCardLiquidation(liquidationId)
  dispatch(executeAction(actionTypes.NULLIFY_CARD_LIQUIDATION, process))
}

export const onSetOrderDetailId = id => async dispatch => {
  const process = () => ({ id })
  dispatch(executeAction(actionTypes.ORDER_DETAIL_ID, process))
}

export const getOrderDetailsById = id => async dispatch => {
  const process = async () => ({ payload: await OrdersService.getOrderDetailsById(id) })
  dispatch(executeAction(actionTypes.GET_ORDER_DETAILS, process))
}
