import React, {Component} from 'react';
import {connect} from "react-redux"

import {
    ButtonToolbar,
    Col,
    FormControl,
    InputGroup,
    Modal,
    Row,
    ToggleButton,
    ToggleButtonGroup
} from "react-bootstrap";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {Td, Tr} from "react-super-responsive-table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";
import SweetAlert from "sweetalert-react";
import GoogleMapReact from "google-map-react";

import {
    Card, FormText, Button, Select, CollapsibleCard, TableV2
} from "../../../components";

import {
    actionTypes as typeP,
    createPolygon,
    getPolygonCategories, getSinglePolygon,
    updatePolygon
} from "../../../actions/polygons.actions";
import {selectSinglePolygon, selectPolygonCategories} from "../../../selectors/polygons.selector";

import {getAllSellers, getAllSellersByProducer, getAllDeliverers, getAllDeliverersByProducer} from "../../../actions/clients.actions";
import {selectSellers, selectDeliverersToSelect} from "../../../selectors/clients.selector";

import {getCompaniesByType} from "../../../actions/company.actions";
import {selectCompaniesByTypeForSelect} from "../../../selectors/company.selector";

import {selectCurrentModule} from "../../../selectors/user.selector";

import {formatNumberWithCommas} from "../../../utils/formatters";

import {loadingSelector} from "../../../selectors/loading.selector";
import {singleErrorSelector, hasErrorsSelector, handlerSuccess, handlerError} from "../../../selectors/error.selector";

import {MAPS_API_KEY} from "../../../settings/app.settings";
import MarkerIcon from "../../../assets/images/svg/marker.svg";
import Money from '../../../components/Money/Money';

const latitude = 14.589465440976774;
const longitude = -90.51898866891861;

const Marker = () => <img src={MarkerIcon} alt={'position'} width={40}/>;

const days = [
    {id: 0, title: 'Domingo'},
    {id: 1, title: 'Lunes'},
    {id: 2, title: 'Martes'},
    {id: 3, title: 'Miercoles'},
    {id: 4, title: 'Jueves'},
    {id: 5, title: 'Viernes'},
    {id: 6, title: 'Sabado'},
]

const times = [
    {id: 0, title: '1:00'},
    {id: 1, title: '2:00'},
    {id: 2, title: '3:00'},
    {id: 3, title: '4:00'},
    {id: 4, title: '5:00'},
    {id: 5, title: '6:00'},
    {id: 6, title: '7:00'},
    {id: 7, title: '8:00'},
    {id: 8, title: '9:00'},
    {id: 9, title: '10:00'},
    {id: 10, title: '11:00'},
    {id: 11, title: '12:00'},
    {id: 12, title: '13:00'},
    {id: 13, title: '14:00'},
    {id: 14, title: '15:00'},
    {id: 15, title: '16:00'},
    {id: 16, title: '17:00'},
    {id: 17, title: '18:00'},
    {id: 18, title: '19:00'},
    {id: 19, title: '20:00'},
    {id: 20, title: '21:00'},
    {id: 21, title: '22:00'},
    {id: 22, title: '23:00'},
    {id: 23, title: '24:00'},
];

class NewPolygon2 extends Component {

    state = {
        polygonCoords : [],
        alertForm: {
            show: false,
            title: 'Default',
            text: 'Default',
            type: 'success'
        },
        defaultAlertForm: {
            show: false,
            title: 'Default',
            text: 'Default',
            type: 'success'
        },
        polygon : {
            name : '',
            notes : '',
            code : '',
            deliveryDays: 0,
            deliveryAmount: 0,
            shipping: 0
        },
        polygonIndex : 0,
        category: {value: null, label: 'sin categoria'},
        seller: {value: null, label: 'sin vendedor'},
        delivery: {value: null, label: 'sin repartidor'},

        typeMap: 1,
        pickUpPoints: [],
        showModal: false,

        returnableBox: 0,
        nonReturnableBox: 0,
        pickUpDays: [],
        pickUpTimes1: [],
        pickUpTimes2: [],
        type: null,
        estimatedDeliveryDays: 0,
        errors: {},
        manualPolygon: null
    };

    componentDidMount() {
        const {module, getCategories, getSellers, getDeliverers, getCompaniesByType, match, getSinglePolygon} = this.props;
        getCategories();
        getSellers(module);
        getDeliverers(module);

        if (module === 11000)
            getCompaniesByType();
        const {params} = match;
        if (params.polygon)
            setTimeout(() => getSinglePolygon(params.polygon), 300);
    };

    componentWillReceiveProps(nextProps, nextContext) {
        const {loadingP, loadingU, loadingC, hasErrorU, hasErrorC} = this.props;
        const {polygon, errorU, errorC} = nextProps;

        if (loadingP && !nextProps.loadingP && polygon != null) {
            const {mapS, mapsS} = this.state;

            let custom = {
                ...polygon,
                ...polygon.setting
            };

            if (polygon.shipper) {
                custom.shipper = {value: polygon.shipper.id, label: polygon.shipper.name};
            }

            this.setState({
                polygon: custom,
                category: {...polygon.category, value: polygon.category.id, label: polygon.category.name}
            });

            if (polygon.deliverer)
                this.setState({
                    delivery: {...polygon.deliverer, value: polygon.deliverer.id, label: polygon.deliverer.name}
                });

            if (polygon.vendor)
                this.setState({
                    seller: {...polygon.vendor, value: polygon.vendor.id, label: polygon.vendor.name}
                });

            if (polygon.points && polygon.points.length >= 1) {
                const polygonCoords = [];
                polygon.points.map((p) => (polygonCoords.push({index: p.index, lat: parseFloat(p.latitude), lng: parseFloat(p.longitude)})));
                polygonCoords.push({...polygonCoords[0], index: polygonCoords.length});
                polygonCoords.sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
                this.setState({polygonCoords});
                this.onGoogleApiLoaded(mapS, mapsS, polygonCoords);
            }

            if (polygon.pickUpPoints && polygon.pickUpPoints.length >= 1) {
                const pickUpPoints = [];
                polygon.pickUpPoints.map((p) => (pickUpPoints.push({
                    editable: false,
                    estimatedDeliveryDays: p.deliveryDays,
                    id: p.id,
                    lat: parseFloat(p.latitude),
                    lng: parseFloat(p.longitude),
                    nonReturnableBox: p.priceNonReturnable,
                    pickUpDays: p.pickUpDays,
                    pickUpTimes: p.pickUpTimes,
                    returnableBox: p.priceReturnable,
                    type: p.type
                })));
                this.setState({pickUpPoints});
            }
        }

        if (loadingU && !nextProps.loadingU) {
            let alert = {title: ''};
            if (!hasErrorU && nextProps.hasErrorU) {
                alert = handlerError(errorU.message || 'Ha ocurrido un error inesperado');
                alert.onConfirm = () => this.setState({alertForm: {...alert, show: false}});
            } else {
                alert = handlerSuccess('Polygono actualizado satisfactoriamente');
                alert.onConfirm = () => this.setState({alertForm: {...alert, show: false}});
            }
            this.setState({alertForm: alert});
        }

        if (loadingC && !nextProps.loadingC) {
            let alert = {title: ''};
            if (!hasErrorC && nextProps.hasErrorC) {
                alert = handlerError(errorC.message || 'Ha ocurrido un error inesperado');
                alert.onConfirm = () => this.setState({alertForm: {...alert, show: false}});
            } else {
                alert = handlerSuccess('Polygono creado satisfactoriamente');
                alert.onConfirm = () => this.setState({alertForm: {...alert, show: false}});
            }
            this.setState({alertForm: alert});
        }
    };

    setDefaultState = () => {
        const alertForm = Object.assign({}, this.state.defaultAlertForm);
        this.setState({alertForm});
    };

    validateForm = (value, rule) => {
        let polygon = this.state.polygon;
        const rules = {
            required: () => {
                return polygon[value] !== undefined ? '' : 'Es necesario';
            }
        };
        return rules[rule]();

    };

    handleInputChange = (event) => {
        const {name, value} = event.target;
        let {polygon} = this.state;
        polygon[name] = value;
        this.setState({polygon: polygon});
    };

    save = () => {
        const required = 'Este dato es requerido';
        let {polygon, manualPolygon, category, seller, delivery, pickUpPoints} = this.state;
        let errors = {};

        let alertForm = Object.assign({}, this.state.alertForm);

        alertForm.title = 'Datos invalidos';
        alertForm.type = 'warning';
        alertForm.show = true;
        alertForm.onConfirm = () => this.setState({alertForm: {...alertForm, show: false}});

        if (!polygon.name) errors.name = required;
        if (!category.value) errors.name = required;
        if (category.value === 2) {
            if (!seller.value) errors.seller = required;
            if (!delivery.value) errors.delivery = required;
        }
        /*
        if (category.value === 1 || category.value === 5) {
            if (category.value === 1 && !polygon.paymentStandard || polygon.paymentStandard < 0) errors.paymentStandard = required;
            if (category.value === 1 && !polygon.paymentPerInvoice || polygon.paymentPerInvoice < 0) errors.paymentPerInvoice = required;
            if (!polygon.paymentPerPercentage || polygon.paymentPerPercentage < 0) errors.paymentPerPercentage = required;

            if (!polygon.deliveryDays || polygon.deliveryDays <= 0) errors.deliveryDays = required;
        }
        */

        if (category.value === 4) {
            if (pickUpPoints.length === 0){
                alertForm.text = 'Se debe ingresar punto de recolección.';
                this.setState({alertForm});
                return;
            }
        }

        if (category.value === 5) {
            if (!polygon.shipper)
                errors.shipper = 'Este campo es requerido';
            else polygon.shipperId = polygon.shipper.value;
        }
/*
        if (category.value === 1 || category.value === 5) {
            let sum = 0;
            sum += parseInt(polygon.paymentStandard || 0);
            sum += parseInt(polygon.paymentPerInvoice || 0);
            sum += parseInt(polygon.paymentPerPercentage || 0);
            if (sum <= 0) {
                alertForm.text = 'Es necesario asignar un pago';
                this.setState({alertForm});
                return;
            }
        }
        */

        let polygonCoords = [];
        let firstPoint = {};
        let newPolygonIndex = 0;
        if (manualPolygon.getPath() !== undefined)
        {
            firstPoint = manualPolygon.getPath().getAt(0);
            for (let i =0; i < manualPolygon.getPath().getLength()-1; i++) {
                let xy = manualPolygon.getPath().getAt(i);
                polygonCoords.push({index: newPolygonIndex, lat : xy.lat(), lng: xy.lng()});
                newPolygonIndex++;
            }
        }

        let request = Object.assign({}, polygon);
        request.coordinates = polygonCoords.map(item => ({index: (item.index !== undefined) ? item.index : polygonCoords.length-1, latitude: item.lat, longitude: item.lng}));

        if (request.coordinates.length < 3) {
            alertForm.text = 'Debe dibujar un poligono de al menos 3 puntos.';
            this.setState({alertForm});
            return;
        }

        if (Object.keys(errors).length === 0) {
            request.category = category.value;
            request.vendor = seller.value;
            request.deliverer = delivery.value;

            request.pickUpPoints = pickUpPoints.map(item => ({
                latitude: item.lat.toString(),
                longitude: item.lng.toString(),
                priceReturnable: item.returnableBox,
                priceNonReturnable: item.nonReturnableBox,
                pickUpDays: item.pickUpDays,
                pickUpTimes: item.pickUpTimes,
                type: item.type == 0 ? false : true,
                estimatedDeliveryDays: item.estimatedDeliveryDays
            }));

            if (request.category === 1 || request.category === 5)
                request.setting = {
                    deliveryDays: polygon.deliveryDays,
                    deliveryType: 1,
                    paymentStandard: polygon.paymentStandard || 0,
                    paymentPerInvoice: polygon.paymentPerInvoice || 0,
                    paymentPerPercentage: polygon.paymentPerPercentage || 0
                };


            const {createPolygon, match, updatePolygon} = this.props;
            const {params} = match;
            if (params.polygon)
                updatePolygon(params.polygon, request);
            else
                createPolygon(request);
        }
        this.setState({errors});
    };

    addPointToPolygon = ({x, y, lat, lng, event,map,maps}) => {
        let {polygonCoords, manualPolygon} = this.state;
        let contentString = '';
        let newPolygonIndex = 0;
        let fistPoint = {};
        if (manualPolygon.getPath() !== undefined)
        {
            polygonCoords = [];
            fistPoint = manualPolygon.getPath().getAt(0);
            for (let i =0; i < manualPolygon.getPath().getLength()-1; i++) {
                let xy = manualPolygon.getPath().getAt(i);
                contentString += 'pos:' + xy.lat() + ',' + xy.lng();
                polygonCoords.push({index: newPolygonIndex, lat : xy.lat(), lng: xy.lng()});
                newPolygonIndex++;
            }
        }

        if (polygonCoords.length === 0 )
        {
            polygonCoords.push({ index: newPolygonIndex, lat: lat, lng: lng });
            polygonCoords.push({ lat: lat, lng: lng });
        }
        else
        {
            polygonCoords.push({ index: newPolygonIndex, lat: lat, lng: lng });
            newPolygonIndex++;
            polygonCoords.push({index: newPolygonIndex, lat :  fistPoint.lat(), lng: fistPoint.lng()});
        }
        this.setState({polygonCoords});
        this.onGoogleApiLoaded(map,maps,polygonCoords);
    };

    resetFields = () => {
        let {manualPolygon,mapsS} = this.state;

        manualPolygon.setMap(null);

        this.setState({
            manualPolygon,
            polygonCoords : [],
            polygon : {
                name : '',
                notes : '',
                code : ''
            },
            polygonIndex : 0
        });
    };

    onGoogleApiLoaded = (map, maps,polygonCoords) => {
        setTimeout(() => {
            let {mapS,mapsS, manualPolygon} = this.state;
            if (mapS === undefined && map !== undefined)
            {
                mapS = map;
                mapsS = maps;
                this.setState({mapS,mapsS})
            }
            if (manualPolygon) {
                manualPolygon.setMap(null);
            }
            if (polygonCoords === undefined)
            {
                let {polygonCoords} = this.state;
            }

            manualPolygon = new mapsS.Polygon({
                paths: polygonCoords,
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35,
                editable : true,
            });
            manualPolygon.setMap(mapS);
            this.setState({manualPolygon});
        }, 100);
    };

    getCategories =() => {
        const {categories, module} = this.props;

        let items = [];
        switch (module) {
            case 1000:
                items = categories.filter((c) => c.id != 3 && c.id != 5);
                break
            case 2000:
                items = categories.filter((c) => c.id != 3 && c.id != 5);
                break
            default: items = categories;
        }

        return items;
    }

    onChangeSelect = (data, parammeter) => {
        this.setState({[parammeter]: data});

        if (parammeter == 'category' && data.value !== 4){
            this.setState({
                typeMap: 1,
                pickUpPoints: []
            })
        }
    };

    getHeaders = () => {
        return [
            {label: 'No', show: true, index:true , select:true, className:'mini'},
            {label: 'Latitud y Longitud', show: true, value: ['dimensionC'], type: 'text' ,  className:'mini', custom:(item)=> item.dimensionC=  item.lat.toFixed(4)+' , '+item.lng.toFixed(4)},
            {label: 'Retornable', show: true, value: ['returnableBox'], type: 'currency' ,  className:'short'},
            {label: 'No retornable', show: true, value: ['nonReturnableBox'], type: 'currency' ,  className:'short'},
            {label: 'Días de recolección', show: true, value: ['recoleccionC'], type: 'text', className: 'short', select:true,
                custom: (item) => {
                    const d = item.pickUpDays.split(',');
                    d.sort();
                    const pd = d.map((item, index) => {
                        return days[parseInt(item)].title
                    })
                    item.recoleccionC = pd.toString().replace(/,/g,", ")
                    return (pd.toString().replace(/,/g,", "))
            }},
            {label: 'Horario', show: true, value: ['horarioC'], type: 'text' ,  className:'short', 
                custom: (item) => {
                    const pickUpTimesArray = item.pickUpTimes.split(',').map((item, index) => {
                        return times[parseInt(item)].title
                    })
                    item.horarioC = pickUpTimesArray.toString().replace(/,/g, ", ")
                    return (pickUpTimesArray.toString().replace(/,/g, ", "))
                }
            },
            {label: 'Tipo', show: true, value: ['tipoC'], type: 'text' ,  className:'mini', custom:(item)=> item.tipoC =  item.type == 0 ? 'Fija' : 'Movil'},
            {config:true, show: true, label:'', className:'mini center'}

        ]
    }

    renderRowPickUpPoint = (item, index) => {
        const d = item.pickUpDays.split(',');
        d.sort();

        const pd = d.map((item, index)=> {
            return days[parseInt(item)].title
        })

        const pickUpTimesArray = item.pickUpTimes.split(',').map((item, index)=> {
            return times[parseInt(item)].title
        })

        return (
            <Tr className={'data'} key={`${index}`}>
                <Td className={'mini'}>{index+1}</Td>
                <Td className={'short'}>{item.lat.toFixed(4)+' , '+item.lng.toFixed(4)}</Td>
                <Money component={Td} className={'short'}>{item.returnableBox}</Money>
                <Money component={Td} className={'short'}>{item.nonReturnableBox}</Money>
                <Td className={'short'}>{pd.toString().replace(/,/g,", ")}</Td>
                <Td className={'short'}>{pickUpTimesArray.toString().replace(/,/g,", ")}</Td>
                <Td className={'mini'}>{item.type == 0 ? 'Fija' : 'Movil'}</Td>
            </Tr>
        );
    };

    handleTypeMap = (val) => {
        this.setState({typeMap: val})
    };

    detailPickUpPoint = ({lat, lng}) => {
        const point = {id: this.state.pickUpPoints.length + 1, lat, lng}
        this.setState({currentPoint: point, showModal: true});
    }

    onHide = () => this.setState({
        showModal: false
    });

    onChangeReturnableBox = (value) => {
        if (value > 0){
            this.setState({returnableBox: value});
        }
    };

    onChangeNonReturnableBox = (value) => {
        if (value > 0){
            this.setState({nonReturnableBox: value});
        }
    };

    onChangeEstimatedDeliveryDays = (value) => {
        if (value > 0){
            this.setState({estimatedDeliveryDays: value});
        }
    };

    handlePickUpDays = (value) => {
        this.setState({pickUpDays: value})
    }

    handleType = (value) => {
        this.setState({type: value})
    }

    handlePickUpTimes = (value, type) => {
        if (type == 1)
            this.setState({pickUpTimes1: value})
        if (type == 2)
            this.setState({pickUpTimes2: value})
    }

    addPickUpPoint = () => {
        let {pickUpPoints, currentPoint, pickUpDays, pickUpTimes1, pickUpTimes2, returnableBox, nonReturnableBox, type, estimatedDeliveryDays} = this.state;

        const pickUpTimesArray = pickUpTimes1.concat(pickUpTimes2)

        pickUpTimesArray.sort(function (a, b) {
            if (parseInt(a) < parseInt(b)) {
                return -1;
            }
            if (parseInt(b) < parseInt(a)) {
                return 1;
            }
            return 0;
        });

        const point = {
            id: currentPoint.id,
            lat: currentPoint.lat,
            lng: currentPoint.lng,
            returnableBox: parseFloat(returnableBox),
            nonReturnableBox: parseFloat(nonReturnableBox),
            pickUpDays: pickUpDays.toString(),
            pickUpTimes: pickUpTimesArray.toString(),
            type: type,
            estimatedDeliveryDays: estimatedDeliveryDays
        }

        pickUpPoints.push(point)
        this.setState({
            pickUpPoints,
            showModal: false,
            returnableBox: 0,
            nonReturnableBox: 0,
            pickUpDays: [],
            pickUpTimes1: [],
            pickUpTimes2: [],
            currentPoint: {},
            type: null,
            estimatedDeliveryDays: 0
        });
    }

    render() {
        const {setting, errors, alertForm, category, seller, delivery, polygon, typeMap, pickUpPoints, showModal, returnableBox, nonReturnableBox, currentPickUpPoint, pickUpDays, pickUpTimes1, pickUpTimes2, type, estimatedDeliveryDays} = this.state;
        const {sellers, deliverers, shippers, loadingC, loadingU} = this.props;

        const categories = this.getCategories();

        return (
            <div className={'sub-style'} >
                <Row>
                    <Col md={6}>
                        <Card title={'Crear Polígono'}>
                            <form>
                                <Row>
                                    <Col md={6}>
                                        <FormText
                                            label={"Nombre"}
                                            type={"text"}
                                            id={"name"}
                                            placeholder={"Nombre"}
                                            name={"name"}
                                            value={this.state.polygon.name}
                                            onChange={this.handleInputChange}
                                            required
                                            error={errors.name}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormText
                                            label={"Observaciones"}
                                            type={"text"}
                                            id={"notes"}
                                            placeholder={"Observaciones"}
                                            name={"notes"}
                                            value={this.state.polygon.notes}
                                            onChange={this.handleInputChange}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={12}>
                                        <Select
                                            label={category.value ? category.description : 'Seleccione una categoria'}
                                            value={category}
                                            options={categories}
                                            onChange={(data) => this.onChangeSelect(data, 'category')}
                                            equired
                                            error={errors.category}
                                        />
                                    </Col>
                                    {category.value === 2 && <>
                                        <Col md={6}>
                                            <Select
                                                required
                                                label={'Vendedor encargado'}
                                                value={seller}
                                                options={sellers}
                                                onChange={(data) => this.onChangeSelect(data, 'seller')}
                                                error={errors.seller}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Select
                                                required
                                                label={'Repartidor encargado'}
                                                value={delivery}
                                                options={deliverers}
                                                onChange={(data) => this.onChangeSelect(data, 'delivery')}
                                                error={errors.delivery}
                                            />
                                        </Col>
                                    </>}
                                    {/*
                                    {(category.value === 1 || category.value === 5) && <>
                                        <Col md={6}>
                                            <FormText
                                                prepend={'Dias'}
                                                label={"Tiempo estimado de entrega"}
                                                name={'deliveryDays'}
                                                type={'number'}
                                                value={polygon.deliveryDays}
                                                onChange={this.handleInputChange}
                                                required
                                                error={errors.deliveryDays}
                                            />
                                        </Col>
                                        {(category.value === 1) && <>
                                            <Col md={6}>
                                                <FormText
                                                    prependMoneySymbol
                                                    label={'Pago por cajilla'}
                                                    name={'paymentStandard'}
                                                    type={'number'}
                                                    value={polygon.paymentStandard}
                                                    onChange={this.handleInputChange}
                                                    required
                                                    error={errors.paymentStandard}
                                                />
                                            </Col>

                                            <Col md={6}>
                                                <FormText
                                                    prependMoneySymbol
                                                    label={'Pago por factura entregada'}
                                                    name={'paymentPerInvoice'}
                                                    type={'number'}
                                                    value={polygon.paymentPerInvoice}
                                                    onChange={this.handleInputChange}
                                                    required
                                                    error={errors.paymentPerInvoice}
                                                />
                                            </Col>
                                        </>}

                                        <Col md={6}>
                                            <FormText
                                                prepend={'%'}
                                                label={'Pago por un porcentaje de la factura entregada'}
                                                name={'paymentPerPercentage'}
                                                type={'number'}
                                                value={polygon.paymentPerPercentage}
                                                onChange={this.handleInputChange}
                                                required
                                                error={errors.paymentPerPercentage}
                                            />
                                        </Col>
                                    </>}
                                    */}
                                    {category.value === 5 && <>
                                        <Col md={6}>
                                            <Select
                                                label={'Empresa transportista a cargo de las entregas'}
                                                placeholder={'pj: transportista S.A'}
                                                options={shippers}
                                                value={polygon.shipper}
                                                onChange={(s) => this.setState({polygon: {...polygon, shipper: s}})}
                                                required
                                            />
                                        </Col>
                                        <Col md={6}><FormText
                                            value={polygon.shipping}
                                            label={'Pago de envio'}
                                            prependMoneySymbol
                                            type={'numeric'}
                                            required
                                            onChange={this.handleInputChange}
                                            name={'shipping'}
                                        /></Col>
                                    </>}
                                </Row>

                                <Row className={'container-buttons'}>
                                    <Button loading={loadingC || loadingU} onClick={() => this.save()} > <FontAwesomeIcon icon={faPlus}/> Guardar</Button>
                                </Row>
                                <Row>
                                    <br/>
                                    <br/>
                                    <br/>
                                </Row>
                            </form>
                        </Card>

                        {(category.value === 4 || category.value === 1 || category.value === 5) &&
                        <CollapsibleCard className={'sub-style'} header={{backgroundColor: '#1b4060'}} title={'Puntos de recolección'}>
                            <TableV2
                                headers={this.getHeaders()}
                                items={pickUpPoints}
                                mobileAuto
                                storageKey={`newPolygon`}

                            />
                        </CollapsibleCard>
                        }
                    </Col>
                    <Col md={6}>
                        <CollapsibleCard header={{backgroundColor: '#1b4060'}} title={'Ubicación'} bodyClass={'map-container'} startCollapsed={false}>

                            <ButtonToolbar style={{justifyContent: 'center'}}>
                                <ToggleButtonGroup type="radio" name="options" onChange={this.handleTypeMap}
                                                   defaultValue={1}>
                                    <ToggleButton style={{width: 140}} variant="secondary" value={1}>Dibujar
                                        polígono</ToggleButton>
                                    {(category.value === 4 || category.value === 1 || category.value === 5) &&
                                    <ToggleButton
                                        disabled={(category.value === 1 || category.value === 5) && pickUpPoints.length > 0}
                                        style={{width: 140}} variant="secondary" value={2}>
                                        Punto de Recolección</ToggleButton>
                                    }
                                </ToggleButtonGroup>
                            </ButtonToolbar>

                            <Row className={'map-address'}>{typeMap == 1 ? 'Dibuja el nuevo polígono haciendo clic' : 'Haz click en algún lugar del mapa para agregar un punto de recolección'}</Row>
                            <div style={{width:'100%', height:500}}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{key: MAPS_API_KEY}}
                                    defaultCenter={{lat: latitude, lng: longitude}}
                                    defaultZoom={14}
                                    onClick={typeMap == 1 ? this.addPointToPolygon : this.detailPickUpPoint}
                                    yesIWantToUseGoogleMapApiInternals
                                    // onChildClick={(value,item) => this.onChildClickCallback(value,item)}
                                    onGoogleApiLoaded={({ map, maps }) => this.onGoogleApiLoaded(map, maps)}
                                >
                                    {pickUpPoints.length > 0 &&
                                        pickUpPoints.map((item, index) => (
                                            <div key={`${index}`}
                                                 lat={Number(item.lat)}
                                                 lng={Number(item.lng)}
                                                 style={{position: 'absolute', left: -20, top: -40}}>
                                                <Marker/>
                                            </div>
                                        ))
                                    }
                                </GoogleMapReact>
                            </div>
                        </CollapsibleCard>
                    </Col>
                </Row>

                <Modal size={'lg'} centered show={showModal} onHide={this.onHide}>
                    <Modal.Header closeButton>
                        <Modal.Title><Marker/>Punto de recolección</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Row style={{justifyContent: 'flex-start', marginLeft: 16, marginRight: 16}}>
                                <Col>
                                    <div>
                                        <h6>Días de recolección</h6>
                                        <ToggleButtonGroup type="checkbox" value={pickUpDays} onChange={this.handlePickUpDays}>
                                            {
                                                days.map((item, index) => (
                                                    <ToggleButton value={item.id}>{item.title}</ToggleButton>
                                                ))
                                            }
                                        </ToggleButtonGroup>
                                    </div>
                                </Col>
                            </Row>
                            <br/>

                            <Row style={{justifyContent: 'flex-start', marginLeft: 16, marginRight: 16}}>
                                <Col md={6}>
                                    <div>
                                        <h6>Horarios de recolección</h6>
                                        <Row style={{justifyContent: 'center', backgroundColor: '#F0F0F0', padding: 10, margin: 10}}>
                                            <Col style={{textAlign: 'right'}}>
                                                <ToggleButtonGroup vertical type="checkbox" value={pickUpTimes1} onChange={(data)=>{this.handlePickUpTimes(data, 1)}}>
                                                    {
                                                        times.filter((i, index) => (index < 12)).map((item, index) => (
                                                            <ToggleButton value={item.id}>{item.title}</ToggleButton>
                                                        ))
                                                    }
                                                </ToggleButtonGroup>
                                            </Col>
                                            <Col>
                                                <ToggleButtonGroup vertical type="checkbox" value={pickUpTimes2} onChange={(data)=>{this.handlePickUpTimes(data, 2)}}>
                                                    {
                                                        times.filter((i, index) => (index > 11)).map((item, index) => (
                                                            <ToggleButton value={item.id}>{item.title}</ToggleButton>
                                                        ))
                                                    }
                                                </ToggleButtonGroup>
                                            </Col>
                                        </Row>
                                        <br/>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    {category.value === 4 && <Row>
                                        <div>
                                            <h6>Precio por cajilla</h6>
                                            <InputGroup style={{maxWidth: 300}}>
                                                <InputGroup.Text>Retornable</InputGroup.Text>
                                                <FormControl
                                                    style={{textAlign: 'center'}}
                                                    aria-label="Default"
                                                    type="number"
                                                    step={1}
                                                    onFocus={(event)=>event.target.select()}
                                                    value={returnableBox}
                                                    aria-describedby="inputGroup-sizing-default"
                                                    onChange={({target}) => this.onChangeReturnableBox(target.value)}
                                                />
                                            </InputGroup>
                                            <InputGroup style={{maxWidth: 300}}>
                                                <InputGroup.Text>No Retornable</InputGroup.Text>
                                                <FormControl
                                                    style={{textAlign: 'center'}}
                                                    aria-label="Default"
                                                    type="number"
                                                    step={1}
                                                    onFocus={(event)=>event.target.select()}
                                                    value={nonReturnableBox}
                                                    aria-describedby="inputGroup-sizing-default"
                                                    onChange={({target}) => this.onChangeNonReturnableBox(target.value)}
                                                />
                                            </InputGroup>
                                        </div>
                                    </Row>}
                                    <br/>
                                    <Row>
                                        <div>
                                            <h6>Tipo:</h6>
                                            <ToggleButtonGroup type="radio" name="options" value={type} onChange={this.handleType}>
                                                <ToggleButton value={0}>Fija</ToggleButton>
                                                <ToggleButton value={1}>Movil</ToggleButton>
                                            </ToggleButtonGroup>
                                        </div>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <div>
                                            <h6>Tiempo de entrega</h6>
                                            <InputGroup style={{maxWidth: 300}}>
                                                <FormControl
                                                    style={{textAlign: 'center'}}
                                                    aria-label="Default"
                                                    type="number"
                                                    step={1}
                                                    onFocus={(event)=>event.target.select()}
                                                    value={estimatedDeliveryDays}
                                                    aria-describedby="inputGroup-sizing-default"
                                                    onChange={({target}) => this.onChangeEstimatedDeliveryDays(target.value)}
                                                />
                                                <InputGroup.Text>días</InputGroup.Text>
                                            </InputGroup>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={'container-buttons'}>
                                <Button disabled={type === null || pickUpDays.length == 0 || (pickUpTimes1.length == 0 && pickUpTimes2.length == 0)} variant="secondary" onClick={()=>this.addPickUpPoint()}>
                                    Agregar
                                </Button>
                            </Row>
                        </div>
                    </Modal.Body>

                </Modal>

                <SweetAlert {...alertForm}/>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    createResponse: selectSinglePolygon(state),
    categories: selectPolygonCategories(state),
    module: selectCurrentModule(state),
    sellers: selectSellers(state),
    deliverers: selectDeliverersToSelect(state),
    shippers: selectCompaniesByTypeForSelect(state),
    polygon : selectSinglePolygon(state),
    loadingP: loadingSelector([typeP.GET_SINGLE_POLYGON])(state),
    loadingU: loadingSelector([typeP.UPDATE_POLYGON])(state),
    hasErrorU: hasErrorsSelector([typeP.UPDATE_POLYGON])(state),
    errorU: singleErrorSelector([typeP.UPDATE_POLYGON])(state),
    loadingC: loadingSelector([typeP.CREATE_POLYGON])(state),
    hasErrorC: hasErrorsSelector([typeP.CREATE_POLYGON])(state),
    errorC: singleErrorSelector([typeP.CREATE_POLYGON])(state)
});

const mapDispatchToProps = dispatch => ({
    createPolygon: (request) => dispatch(createPolygon(request)),
    getCategories: () => dispatch(getPolygonCategories()),
    getSellers: (module) => {
        if (module === 1000)
            dispatch(getAllSellers());
        else if (module === 2000)
            dispatch(getAllSellersByProducer());
    },
    getDeliverers: (module) => {
        if (module === 1000)
            dispatch(getAllDeliverers())
        else if (module === 2000)
            dispatch(getAllDeliverersByProducer())
    },
    getCompaniesByType: () => dispatch(getCompaniesByType(true, true, false, 10)),
    updatePolygon: (id,request) => dispatch(updatePolygon(id,request)),
    getSinglePolygon : (id) => dispatch(getSinglePolygon(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPolygon2);
