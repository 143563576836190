import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { showAlert } from 'src/actions/alert.actions'
import {
  actions as actionP,
  addCompanyUserInPlan,
  addUserInPlan,
  getActivePlan,
  getCompanyActivePlan,
} from 'src/actions/plans.actions'
import { setModalAddUser, setModalUsers } from 'src/actions/utilities.actions'
import { history } from 'src/App'
import { Button, FormText } from 'src/components'
import {
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { selectActivePlans, selectAddUser } from 'src/selectors/plans.selector'
import { selectModalAddUser } from 'src/selectors/utilities.selector'

const ModalAddUser = () => {
  const dispatch = useDispatch()

  const modalAddUser = useSelector(selectModalAddUser)
  const { companyId } = modalAddUser

  const plan = useSelector(selectActivePlans)
  const userPlan = useSelector(selectAddUser)

  const createLoading = useSelector(state => loadingSelector([actionP.ADD_USER])(state))
  const createHasError = useSelector(state =>
    hasErrorsSelector([actionP.ADD_USER])(state),
  )
  const createError = useSelector(state => singleErrorSelector([actionP.ADD_USER])(state))

  const [action, setAction] = useState({
    create: false,
  })

  useEffect(() => {
    if (createLoading) setAction({ ...action, create: true })
    else if (action.create) {
      setAction({ ...action, create: false })
      if (createHasError) {
        dispatch(
          showAlert({
            ...handlerError(createError.message || 'No se ha podido crear el usuario'),
          }),
        )
      } else {
        let message = userPlan.pass
          ? `Usuario creado, la contraseña del usuario es: ${userPlan.pass}`
          : 'Usuario agregado exitosamente. No se puede proporcionar la contraseña debido a que el usuario ya existía en la plataforma.'
        dispatch(
          showAlert({
            ...handlerSuccess(message),
            showCancelButton: true,
            showConfirmButton: !companyId,
            cancelButtonText: 'Cerrar',
            confirmButtonText: 'Personalizar permisos',
            confirmButtonColor: '#226095',
            onCancel: () => {
              dispatch(
                setModalAddUser({
                  show: false,
                  id: null,
                }),
              )
              if (companyId) dispatch(getCompanyActivePlan(companyId))
              else dispatch(getActivePlan())
            },
            onConfirm: () => {
              dispatch(
                setModalAddUser({
                  show: false,
                  id: null,
                }),
              )
              dispatch(setModalUsers({ show: false, users: [] }))
              history.push(`/distribucion/permisos/1000/${userPlan.id}/${userPlan.name}`)
            },
          }),
        )
      }
    }
  }, [createLoading])

  const handleInputChange = event => {
    const { name, value } = event.target

    if (name === 'email') {
      if (value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        if (validateUsers(value))
          dispatch(setModalAddUser({ ...modalAddUser, error: null, [name]: value }))
        else
          dispatch(
            setModalAddUser({
              ...modalAddUser,
              error: 'Este correo ya está registrado en la empresa',
              [name]: value,
            }),
          )
      } else
        dispatch(
          setModalAddUser({ ...modalAddUser, error: 'Correo no válido', [name]: value }),
        )
    } else dispatch(setModalAddUser({ ...modalAddUser, [name]: value }))
  }

  const validateUsers = (value, syntax) => {
    let compare1 = plan.userList
      ? plan.userList.findIndex(d => d.email === value) === -1
      : true
    let compare2 = plan.extraUsers
      ? plan.extraUsers.findIndex(d => d.email === value) === -1
      : true
    let compare3 = syntax ? value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) : true
    return compare1 && compare2 && compare3
  }

  return (
    <div style={{ position: 'fixed' }}>
      <Modal
        show={modalAddUser.show}
        onHide={() => dispatch(setModalAddUser({ show: false, id: null }))}
        centered
        size={'md'}>
        <Modal.Header closeButton>
          <Modal.Title>
            Agregar Usuario {modalAddUser.new ? 'nuevo' : 'existente'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col sm={12} md={12}>
                <FormText
                  label={'Correo Electrónico'}
                  type={'email'}
                  id={'email'}
                  placeholder={'Correo'}
                  name={'email'}
                  value={modalAddUser.email}
                  onChange={handleInputChange}
                  required
                  error={modalAddUser.error}
                />
              </Col>

              {modalAddUser.new && (
                <>
                  <Col sm={12} md={12}>
                    <FormText
                      label={'Nombre'}
                      type={'text'}
                      id={'name'}
                      placeholder={'Nombre'}
                      name={'name'}
                      value={modalAddUser.name}
                      onChange={handleInputChange}
                      required
                    />
                  </Col>
                  <Col sm={12} md={12} className={'mb-4'}>
                    <FormText
                      label={'DPI (Opcional)'}
                      min={modalAddUser.dpi && 13}
                      max={modalAddUser.dpi && 13}
                      type={'number'}
                      id={'dpi'}
                      placeholder={'DPI'}
                      name={'dpi'}
                      value={modalAddUser.dpi}
                      onChange={handleInputChange}
                    />
                  </Col>
                </>
              )}
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              !modalAddUser.email ||
              (modalAddUser.new && !modalAddUser.name) ||
              (modalAddUser.type.value === 1 && modalAddUser.modules.length === 0) ||
              !validateUsers(modalAddUser.email, true)
            }
            loading={createLoading}
            onClick={() => {
              const request = {
                name: modalAddUser.name,
                email: modalAddUser.email,
                dpi: /\d{13}/.test(modalAddUser.dpi) ? modalAddUser.dpi : null,
                find: !modalAddUser.new,
              }

              if (companyId) dispatch(addCompanyUserInPlan(companyId, request))
              else dispatch(addUserInPlan(request))
            }}>
            Agregar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default ModalAddUser
