import { extract, successState } from '../actions/global.actions'
import { actions } from 'src/actions/promotions.actions'

const initialState: IPromotionState = {
  promotions: [],
  promotionResponse: undefined,
  promotionId: null,
  promotion: undefined,
}

const PromotionReducer = (
  state = initialState,
  action: { type: string; payload: any },
) => {
  switch (action.type) {
    case successState(actions.GET_PROMOTION):
      return { ...state, promotion: extract(action.payload, undefined) }
    case successState(actions.ON_SET_PROMOTION_ID):
      return { ...state, promotionId: extract(action.payload, null) }
    case successState(actions.ON_CREATE_OR_UPDATE_PROMOTION):
      return { ...state, promotionResponse: extract(action.payload, undefined) }
    case successState(actions.GET_ALL_PROMOTIONS):
      return { ...state, promotions: extract(action.payload, []) }
    default:
      return state ? state : initialState
  }
}
export default PromotionReducer
