import {
  faPencilAlt,
  faPlus,
  faPlusCircle,
  faSearch,
  faTrash,
  faTrashRestoreAlt,
} from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { Col, ListGroup, Modal, ProgressBar, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  actionTypes,
  createCategorization,
  deleteListCategorizationType,
  getCategorizationByType,
  updateCategorization,
} from 'src/actions/categorization.actions'
import { deleteProductVariations } from 'src/actions/products.actions'
import { Button, Checkbox, FormTextField, Icon } from 'src/components'
import IconButton from 'src/components/buttons/IconButton'
import FormText from 'src/components/inputs/FormTextField/FormText'
import {
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { isAllowed } from 'src/selectors/modules.selector'
import Alert from 'sweetalert-react'

export const VariationComponent = ({
  loading,
  selected,
  onSave,
  data,
  onlySee,
  childName = 'children',
  modeSelection,
  parentId = 20,
  nameCategories = 'Categorias',
  forLocations,
}) => {
  const dispatch = useDispatch()

  const loadingC = useSelector(state =>
    loadingSelector([actionTypes.CREATE_CATEGORIZATION])(state),
  )

  const loadingu = useSelector(state =>
    loadingSelector([actionTypes.UPDATE_CATEGORIZATION])(state),
  )

  const loadingD = useSelector(state =>
    loadingSelector([actionTypes.DELETE_VARIATION])(state),
  )
  const hasErrorD = useSelector(state =>
    hasErrorsSelector([actionTypes.DELETE_VARIATION])(state),
  )

  const errorD = useSelector(state =>
    singleErrorSelector([actionTypes.DELETE_VARIATION])(state),
  )

  const canEdit = useSelector(state => isAllowed(state, [6103]))
  const canDelete = useSelector(state => isAllowed(state, [6104]))

  const canDoActions = !onlySee && !modeSelection

  useEffect(() => {
    if (loadingC) setAction({ ...action, create: true })
    else if (action.create) {
      setAction({ ...action, create: false })
      dispatch(getCategorizationByType(parentId))
    }
  }, [loadingC])

  useEffect(() => {
    if (loadingu) setAction({ ...action, update: true })
    else if (action.update) {
      setAction({ ...action, update: false })
      dispatch(getCategorizationByType(parentId))
    }
  }, [loadingu])

  const [list, setList] = useState([])
  const [listVariations, setListVariations] = useState([])
  const [newCategorization, setNewCategorization] = useState({
    description: '',
    name: '',
    parentCategorization: '',
    type: parentId,
  })
  const [editCategorization, setEditCategorization] = useState({
    description: '',
    name: '',
  })
  const [errors, setErrors] = useState({})
  const [alert, setAlert] = useState({})
  const [action, setAction] = useState({})
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (onSave) onSave(listVariations)
  }, [listVariations])

  useEffect(() => {
    setListVariations(selected || [])
  }, [selected])

  useEffect(() => {
    if (data !== undefined && data !== null) {
      if (Array.isArray(data)) setList(data)
      else if (Array.isArray(data.children)) setList(data.children)

      /*if (Array.isArray(data) && Array.isArray(data[0]?.children))
        setList(data[0].children)
      else if (Array.isArray(data.children)) setList(data.children)*/
    }
  }, [data])

  useEffect(() => {
    if (loadingD) setAction({ ...action, delete: true })
    else if (action.delete) {
      setAction({ ...action, delete: false })
      if (hasErrorD)
        setAlert({
          ...handlerError(errorD.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else {
        setAlert({
          ...handlerSuccess('Variacion eliminada exitosamente'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            setListVariations([])
            dispatch(getCategorizationByType(parentId))
          },
        })
      }
    }
  }, [loadingD])

  const deleteVariations = () => {
    let toDelete = []
    listVariations.forEach(x => {
      toDelete.push(x.id)
    })
    if (parentId !== 20) dispatch(deleteListCategorizationType(toDelete, parentId))
    else dispatch(deleteProductVariations(toDelete))
  }

  const filter = item => {
    if (search && search !== '') {
      const exp = new RegExp(search.toUpperCase(), 'g')
      return Object.keys(item).some(p =>
        `${JSON.stringify(item[p])}`.toUpperCase().match(exp),
      )
    }
    return true
  }
  return (
    <div>
      {loading || loadingD ? (
        <Row>
          <Col>
            <div className={'pb-custom'}>
              <ProgressBar
                label="Cargando"
                animated
                now={100}
                style={{ marginBottom: 10 }}
              />
            </div>
          </Col>
        </Row>
      ) : (
        <div style={{ width: '100%' }}>
          <div>
            <FormText
              prepend={<IconButton icon={faSearch} />}
              onKeyUp={e => setSearch(e.target.value)}
              placeholder={'Buscar entre las categorías'}
              type="text"
            />
          </div>
          <div>
            {
              <ListGroup>
                <ListGroup.Item
                  variant="dark"
                  as="li"
                  className="d-flex justify-content-between align-items-start">
                  <div className="ms-2 me-auto">
                    <b> {parentId !== 20 ? nameCategories : 'Variaciones'}</b>
                  </div>
                  <div>
                    <Row>
                      {listVariations.length > 0 && canDoActions && canDelete && (
                        <Icon
                          style={{ marginLeft: 5 }}
                          icon={faTrash}
                          tooltip={`Eliminar ${
                            parentId !== 20 ? nameCategories : 'variaciones'
                          } seleccionadas`}
                          onClick={() => {
                            let alert = {
                              title: `Importante`,
                              text: 'Si hay inventario en existencia con esa variación, automáticamente se le des asignará la variación al inventario.',
                              type: 'info',
                              show: true,
                              showCancelButton: true,
                              onCancel: () =>
                                setAlert({ alert: { ...alert, show: false } }),
                              onConfirm: () => {
                                deleteVariations()
                                setAlert({ alert: { ...alert, show: false } })
                              },
                            }
                            setAlert({ ...alert })
                          }}
                        />
                      )}
                      {canDoActions && !forLocations && (
                        <Icon
                          disabled={onlySee}
                          style={{ marginLeft: 5 }}
                          icon={faPlusCircle}
                          tooltip={`Crear nueva ${
                            parentId !== 20 ? nameCategories : 'variación'
                          }`}
                          onClick={() =>
                            setNewCategorization({
                              ...newCategorization,
                              show: true,
                              showCreate: true,
                              type: parentId,
                            })
                          }
                        />
                      )}
                    </Row>
                  </div>
                </ListGroup.Item>
                <div style={{ overflowY: 'scroll', maxHeight: '450px' }}>
                  {list?.map((item, index) => {
                    let children =
                      item[childName] &&
                      item[childName].filter(ch => {
                        if (filter({ name: ch.name })) return true
                        if (filter({ name: item.name })) return true
                        return false
                      })
                    return (
                      ((children && children.length > 0) || search === '') && (
                        <ListGroup.Item key={index}>
                          <Row>
                            <label>
                              <b> {item.name}</b> &nbsp;
                            </label>
                            {canDoActions && !forLocations && (
                              <Icon
                                icon={faTrashRestoreAlt}
                                tooltip={`Eliminar ${
                                  parentId !== 20 ? nameCategories : 'variación'
                                } padre`}
                                onClick={() => {
                                  let alert = {
                                    title: `Importante`,
                                    text: `Eliminar una ${
                                      parentId !== 20 ? nameCategories : 'variación'
                                    } padre, implicara la eliminación de todas las sub ${
                                      parentId !== 20 ? nameCategories : 'variación'
                                    } que le pertenezcan. Si hay inventario en existencia con esa variación, automáticamente se le des asignará la variación al inventario.`,
                                    type: 'info',
                                    show: true,
                                    showCancelButton: true,
                                    onCancel: () =>
                                      setAlert({ alert: { ...alert, show: false } }),
                                    onConfirm: () => {
                                      if (parentId !== 20)
                                        dispatch(
                                          deleteListCategorizationType(
                                            [item.id],
                                            parentId,
                                          ),
                                        )
                                      else dispatch(deleteProductVariations([item.id]))
                                      setAlert({ alert: { ...alert, show: false } })
                                    },
                                  }
                                  setAlert({ ...alert })
                                }}
                              />
                            )}
                          </Row>
                          {children?.length > 0 && search === '' && !modeSelection && (
                            <Row>
                              <Checkbox
                                checked={
                                  listVariations.filter(x =>
                                    item.children.find(y => y.id === x.id),
                                  ).length === item.children.length
                                }
                                disabled={onlySee}
                                id={'check-all' + item.id}
                                label={'Seleccionar todas'}
                                style={{ marginRight: '10px' }}
                                onChange={({ target }) => {
                                  if (target.checked) {
                                    let notIn = item.children.filter(
                                      i =>
                                        listVariations.find(y => y.id === i.id) ===
                                        undefined,
                                    )
                                    setListVariations(listVariations => [
                                      ...listVariations,
                                      ...notIn.map(x => {
                                        return { id: x.id, name: x.name }
                                      }),
                                    ])
                                  } else {
                                    setListVariations(
                                      listVariations.filter(
                                        x =>
                                          item.children.find(y => y.id === x.id) ===
                                          undefined,
                                      ),
                                    )
                                  }
                                }}
                              />
                            </Row>
                          )}
                          {children?.map((child, i) => (
                            <Row key={i}>
                              <Checkbox
                                disabled={onlySee}
                                checked={
                                  listVariations &&
                                  listVariations.find(x => x.id === child.id) !==
                                    undefined
                                }
                                name={modeSelection && 'group' + item.id}
                                radio={modeSelection}
                                id={'check-' + child.id}
                                label={child.name}
                                style={{ marginRight: '10px' }}
                                onChange={({ target }) => {
                                  if (target.checked) {
                                    if (modeSelection) {
                                      let a = listVariations.filter(
                                        x => x.parentCategorization !== item.id,
                                      )
                                      setListVariations([
                                        ...a,
                                        {
                                          id: child.id,
                                          name: child.name,
                                          parentCategorization: item.id,
                                        },
                                      ])
                                    } else
                                      setListVariations([
                                        ...listVariations,
                                        {
                                          id: child.id,
                                          name: child.name,
                                          parentCategorization: item.id,
                                        },
                                      ])
                                  } else {
                                    let vari = listVariations.find(x => x.id === child.id)
                                    setListVariations(
                                      listVariations.filter(u => u.id !== vari.id),
                                    )
                                  }
                                }}
                              />
                              {canDoActions && canEdit && (
                                <Icon
                                  icon={faPencilAlt}
                                  tooltip={'Editar'}
                                  onClick={() => {
                                    setEditCategorization({
                                      ...editCategorization,
                                      assigned: false,
                                      id: child.id,
                                      parent: item.id,
                                      principal: false,
                                      public: false,
                                      publicCategorization: false,
                                      show: true,
                                      toggled: false,
                                      type: parentId,
                                      showEdit: true,
                                    })
                                  }}
                                />
                              )}
                            </Row>
                          ))}
                          {modeSelection && (
                            <Row>
                              <div
                                className="ft-sub left mt-2 ml-2"
                                style={{
                                  cursor: 'pointer',
                                  fontWeight: 500,
                                  textDecoration: 'underline',
                                }}
                                onClick={() => {
                                  setListVariations(
                                    listVariations.filter(
                                      x =>
                                        item.children.find(y => y.id === x.id) ===
                                        undefined,
                                    ),
                                  )
                                }}>
                                Quitar selección
                              </div>
                            </Row>
                          )}
                          <div style={{ position: 'absolute', right: 15, bottom: 5 }}>
                            {canDoActions && (
                              <Icon
                                icon={faPlus}
                                tooltip={'Crear ' + item.name}
                                style={{ marginRight: '5px' }}
                                onClick={() =>
                                  setNewCategorization({
                                    ...newCategorization,
                                    parentCategorizationId: item.id,
                                    show: true,
                                  })
                                }
                              />
                            )}
                          </div>
                        </ListGroup.Item>
                      )
                    )
                  })}
                </div>
              </ListGroup>
            }
          </div>
          <Alert {...alert} />
        </div>
      )}
      {/*****************************************************
       ***********************MODAL CREATE***********************
       * ****************************************************/}
      <Modal
        show={newCategorization.show || editCategorization.showEdit}
        size={'md'}
        centered
        onHide={() => {
          setNewCategorization({})
          setEditCategorization({})
        }}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Crear {parentId !== 20 ? nameCategories : 'Variacion'}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <FormTextField
                id={'name'}
                label={'Nombre'}
                placeholder={'Nombre'}
                type={'text'}
                name={'name'}
                value={
                  newCategorization.show
                    ? newCategorization.name
                    : editCategorization.name
                }
                onChange={e => {
                  setErrors({})
                  if (newCategorization.show)
                    setNewCategorization({ ...newCategorization, name: e.target.value })
                  else
                    setEditCategorization({ ...editCategorization, name: e.target.value })
                }}
                required
                error={errors.name}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <FormTextField
                id={'description'}
                label={'Descripción'}
                placeholder={'Descripción'}
                type={'text'}
                name={'description'}
                value={
                  newCategorization.show
                    ? newCategorization.description
                    : editCategorization.description
                }
                onChange={e => {
                  setErrors({})
                  if (newCategorization.show)
                    setNewCategorization({
                      ...newCategorization,
                      description: e.target.value,
                    })
                  else
                    setEditCategorization({
                      ...editCategorization,
                      description: e.target.value,
                    })
                }}
                required
                error={errors.description}
              />
            </Col>
            {newCategorization.showCreate && (
              <Col xs={4} sm={4} md={4} lg={4}>
                <FormTextField
                  id={'color'}
                  label={'Color'}
                  type={'color'}
                  name={'color'}
                  value={newCategorization.color}
                  onChange={e => {
                    setNewCategorization({ ...newCategorization, color: e.target.value })
                  }}
                />
              </Col>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              if (
                newCategorization.show
                  ? !newCategorization.name
                  : !editCategorization.name
              ) {
                errors.name = 'El nombre es requerido'
              }
              if (
                newCategorization.show
                  ? !newCategorization.description
                  : !editCategorization.description
              ) {
                errors.description = 'La descripción es requerida'
              }
              setErrors({ ...errors })
              if (Object.keys(errors).length === 0) {
                if (newCategorization.show) {
                  dispatch(createCategorization({ ...newCategorization }))
                  setNewCategorization({ show: false })
                } else {
                  dispatch(
                    updateCategorization(
                      editCategorization.id,
                      { ...editCategorization },
                      null,
                    ),
                  )
                  setEditCategorization({ showEdit: false })
                }
              }
            }}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
