import React from 'react'
import { style } from 'src/components/Print/TicketStyles'

import { getItemsGroupedByTag } from 'src/content/Restaurant/POSFunctions'

const commandTicket = ({
  keyId,
  correlative,
  pos,
  userName,
  date,
  title,
  items = [],
  footerPhrase,
}) => {
  return (
    <div
      id={`ticket-command${keyId !== undefined ? `-${keyId}` : ''}`}
      style={{ display: 'none' }}>
      <div style={{ marginRight: 10 }}>
        <div style={{ ...style.left, ...style.subtitle }}># {correlative}</div>
        <div style={{ ...style.center }}>
          <div style={{ ...style.title }}>{pos?.commercialName || pos?.name}</div>
          <div style={{ ...style.subtitle }}>Solicitud de producción</div>
        </div>
        <div style={{ ...style.spaceBetween }}>
          <div style={style.text}>Encargado:</div>
          <div style={style.textContent}>{userName}</div>
        </div>
        <div style={{ ...style.spaceBetween }}>
          <div style={style.text}>Fecha:</div>
          <div style={style.textContent}>{date}</div>
        </div>
        <div style={{ ...style.left, ...style.textContent }}>{title}</div>

        {getItemsGroupedByTag(items).map(({ tag, items }, i) => (
          <div style={{ ...style.center, marginTop: 15 }} key={i}>
            <div style={style.text}>Ticket: {tag}</div>
            <table style={{ marginLeft: 'auto', marginRight: 'auto', width: 275 }}>
              <tr>
                <th style={{ width: '15%', ...style.left, ...style.text }}>Cant</th>
                <th style={{ width: '40%', ...style.left, ...style.text }}>Ítem</th>
                <th
                  style={{
                    width: '45%',
                    ...style.right,
                    ...style.text,
                    paddingRight: 12,
                  }}>
                  Insumos
                </th>
              </tr>

              {items?.map((item, i) => (
                <tr key={i}>
                  <td style={{ width: '15%', ...style.left, ...style.text }}>
                    {item.quantity}
                  </td>
                  <td style={{ width: '40%', ...style.left, ...style.text }}>
                    {item.name || 'N/A'}
                  </td>
                  <td
                    style={{
                      width: '45%',
                      ...style.right,
                      ...style.text,
                      paddingRight: 12,
                    }}>
                    <div>
                      {item.supplies &&
                        item.supplies
                          .filter(ingredient => ingredient.quantity !== 0)
                          .map((ingredient, indexI) => (
                            <div style={{ ...style.textContent }} key={indexI}>
                              ({ingredient.quantity}) {ingredient.name}
                              {indexI + 1 !== item.supplies.length && <br />}
                            </div>
                          ))}
                      {item.commentary && <div>{item.commentary}</div>}
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        ))}

        {footerPhrase && (
          <div style={{ ...style.center, ...style.textContent }}>{footerPhrase}</div>
        )}
      </div>
    </div>
  )
}
export default commandTicket
