import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Button } from '../index'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { printTicket } from 'src/utils/utilities'

import { style } from 'src/components/Print/TicketStyles'

import {
  actionTypes as type,
  getPreBillCorrelative,
} from 'src/actions/restaurant.actions'

import {
  selectCompanyFieldValue,
  selectSetCurrentCompany,
} from 'src/selectors/company.selector'
import { formatNumberWithCommas } from 'src/utils/formatters'

import { selectCurrentUser } from 'src/selectors/user.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import companyFields from 'src/enums/companyFields'
import { parseToItemsToTickets } from 'src/content/Prints/PrintFunctions'
import { haveAnyValue } from 'src/utils/utilitiesV2'
import { selectGetTableOrder } from 'src/selectors/restaurant.selector'
import { terminalTypesEnum } from 'src/enums/terminalTypes'
import usePOSCorrelative from 'src/content/Restaurant/hooks/usePOSCorrelative'

const line = '____________________________'

const PrintTicket = ({
  tipValue,
  className,
  tableNumber,
  discount,
  selected,
  disabled,
  onPrintData,
  waiterData,
  styleButton = {},
  tipOptional = false,
  pos = {},
  clientData = {},
  showDeliveryData = false,
  canSetCardSurcharge,
  footerPhrase,
  tax = 0,
}) => {
  const dispatch = useDispatch()

  const company = useSelector(selectSetCurrentCompany)
  const tableOrder = useSelector(selectGetTableOrder)
  const prints = useSelector(state => state.printers.all)
  const user = useSelector(selectCurrentUser)

  const correlative = usePOSCorrelative()

  const isImmediatelyInvoice =
    tableOrder?.stepItems === 4 ||
    tableOrder.tableType === terminalTypesEnum.DELIVERY.value

  const loading = useSelector(state =>
    loadingSelector([type.GET_BILL_CORRELATIVE])(state),
  )
  const cardSurcharge = useSelector(state =>
    selectCompanyFieldValue(state, companyFields.cardSurcharge),
  )

  const [get, setGet] = useState(false)
  const [see, setSee] = useState(false)

  const getItems = () => {
    const totalItems = selected
    const unifyItems = []
    totalItems.forEach(item => {
      let aux = unifyItems.findIndex(
        u => u.groupValue && item.groupValue && item.productId === u.productId,
      )
      if (aux === -1) {
        unifyItems.push({
          ...item,
          subtotal: item.subtotal - item.discount,
        })
      } else {
        unifyItems[aux].quantity += item.quantity
        unifyItems[aux].subtotal += item.subtotal - item.discount
      }
    })

    return unifyItems
  }

  const getTotal = () => {
    let response = 0
    const items = getItems()
    if (items) {
      items.forEach(d => {
        response += d.subtotal
      })
    }
    return Number(response)
  }

  const total = getTotal()

  useEffect(() => {
    if (!see) return
    if (isImmediatelyInvoice || !pos.sameCorrelative)
      dispatch(getPreBillCorrelative(false, pos.sameCorrelative, correlative))
    else onPrintInit()
  }, [see])

  useEffect(() => {
    if (!see) return
    if (loading) setGet(true)
    else if (get) {
      setGet(false)
      onPrintInit()
    }
  }, [loading])

  const onPrintInit = () => {
    onPrintValue()
    setSee(false)
  }

  const getValue = (value, indexOf) => {
    if (!value) return line
    else if (indexOf && value.toUpperCase().indexOf(indexOf) >= 0) return line
    else return value
  }

  const print = () => {
    const container = document.getElementById('pos-ticket').innerHTML
    printTicket(
      container,
      null,
      null,
      (pos.activePreTicketsLogo && pos.ticketsLogo) || 'undefined',
    )
  }

  const getNumber = value => {
    return formatNumberWithCommas(value || 0)
  }

  const getTicketName = () => {
    const tag = selected?.[0]?.tag

    if (selected?.every(s => s.tag === tag)) {
      return `Ticket: ${tag}\n`
    }
    return ''
  }

  const onPrintValue = () => {
    if (prints && prints.length > 0) {
      const container = document.getElementById('pos-ticket').innerHTML

      const sendData = []

      if (pos.activePreTicketsLogo) {
        const canvas = document.getElementById('pre-bill-canvas')
        const context = canvas.getContext('2d')
        const img = document.getElementById('pre-bill-ticket-logo')
        if (img) context.drawImage(img, 0, 0, 300, 175)

        sendData.push({
          type: 9,
          value: {
            context: context,
            x: 0,
            y: 0,
            width: 300,
            height: 175,
          },
        })
      }

      const tag = getTicketName()

      sendData.push(
        ...[
          { type: 5, value: correlative + '\n' },
          { type: 2, value: 2 },
          { type: 3, value: true },
          { type: 4, value: 1 },
          { type: 5, value: (pos.commercialName || pos.name) + '\n' },
          { type: 4, value: 2 },
          { type: 5, value: company.address + '\n' },
          { type: 5, value: 'Pre cuenta\n' },
          { type: 1, value: 2 },
          { type: 2, value: 1 },
          { type: 3, value: false },
          { type: 5, value: 'Terminal: ' + tableNumber + '\n' },
          {
            type: 5,
            value: 'Mesero: ' + (waiterData ? waiterData.name : user.name) + '\n',
          },
          { type: 5, value: tag },
          { type: 6, value: new Date().valueOf() },
          { type: 1, value: 2 },
          ...parseToItemsToTickets(getItems()),
        ],
      )

      getSubTotals().forEach(subtotal => {
        sendData.push({
          type: 11,
          value: `${subtotal.label}${subtotal.name}`,
          otherValue: formatNumberWithCommas(subtotal.value),
        })
      })

      sendData.push(
        ...[
          { type: 2, value: 2 },
          { type: 7, value: null },
          { type: 3, value: true },
          { type: 2, value: 1 },
          ...getClientData(),
          { type: 2, value: 2 },
          { type: 5, value: 'ESTE DOCUMENTO NO ES UNA FACTURA\n' },
          { type: 1, value: 1 },
        ],
      )

      if (footerPhrase && footerPhrase !== '')
        sendData.push(
          ...[
            { type: 5, value: footerPhrase },
            { type: 1, value: 1 },
          ],
        )

      let customData = null
      let itemsPrint = null
      if (prints.filter(pt => pt.invoice).length > 0) {
        itemsPrint = prints
          .filter(pt => pt.invoice)
          .map(pt => {
            return { ...pt, data: sendData }
          })
      } else customData = sendData
      onPrintData({ show: true, customData, itemsPrint, content: container, size: 'sm' })
    } else {
      print()
    }
  }

  const getClientData = () => {
    const response = [
      {
        type: 5,
        value: `NOMBRE: ${getValue(clientData.label, 'CONSUMIDOR FINAL')}\n\n`,
      },
      {
        type: 5,
        value: `NIT: ${getValue(clientData.nit, 'CF')}\n\n`,
      },
      {
        type: 5,
        value: `EMAIL: ${getValue(clientData.email)}\n\n`,
      },
      {
        type: 5,
        value: `DIRECCIÓN: ${getValue(clientData.address)}\n\n`,
      },
    ]

    if (showDeliveryData) {
      response.push(
        ...[
          {
            type: 5,
            value: `TELÉFONO: ${getValue(clientData.phone)}\n\n`,
          },
          {
            type: 5,
            value: `REFERENCIA: ${getValue(clientData.reference)}\n\n`,
          },
        ],
      )
    }

    return response
  }

  const getSubTotals = () => {
    const response = [{ label: 'Subtotal', name: '', value: total }]
    let subTotal = total

    if (discount) {
      subTotal -= Number(discount)
      response.push(
        { label: 'Descuento', name: '', value: Number(discount) },
        { label: 'Subtotal', name: ' con descuento', value: subTotal },
      )
    }

    if (tax > 0) {
      subTotal += tax
      response.push({ label: 'IVA', name: '', value: tax })
      response.push({ label: 'Subtotal', name: ' con IVA', value: subTotal })
    }

    if (tipValue) {
      subTotal += Number(tipValue)
      response.push(
        {
          label: `Propina`,
          name: tipOptional ? ' (opcional)' : '',
          value: Number(tipValue),
        },
        { label: 'Subtotal', name: ' con propina', value: subTotal },
      )
    }

    if (canSetCardSurcharge) {
      subTotal = Number(subTotal)
      const surchargeAmount = (subTotal * Number(cardSurcharge)) / 100
      subTotal += surchargeAmount
      response.push(
        {
          label: 'Recargo',
          name: ' por tarjeta',
          value: Number(surchargeAmount),
        },
        { label: 'Subtotal', name: ' con recargo', value: Number(subTotal) },
      )
    }
    return response
  }

  return (
    <>
      <Button
        color={'primary'}
        className={className || ''}
        disabled={disabled}
        onClick={() => {
          setSee(true)
        }}
        loading={loading}
        icon={faPrint}
        style={styleButton}
        dataCy={'pre-cuenta'}>
        Pre cuenta
      </Button>

      <div style={{ display: 'none' }} id={'pos-ticket'}>
        <div style={{ marginRight: 10 }}>
          <div style={{ ...style.left, ...style.subtitle }}># {correlative}</div>
          <div style={{ ...style.center }}>
            <div style={{ ...style.title }}>{pos.commercialName || pos.name}</div>
            <div style={{ ...style.title }}>{company.address}</div>
            <div style={{ ...style.subtitle }}>Pre cuenta</div>
          </div>
          <div style={{ ...style.left }}>
            <div style={{ ...style.subtitle }}>Terminal: {tableNumber}</div>
            {getTicketName() !== '' && (
              <div style={{ ...style.subtitle }}>{getTicketName()}</div>
            )}
          </div>

          {user && (
            <div style={{ ...style.spaceBetween }}>
              <div style={style.text}>Encargado:</div>
              <div style={style.textContent}>
                {waiterData ? waiterData.name : user.name}
              </div>
            </div>
          )}

          <div style={{ ...style.center, marginTop: 15 }}>
            <table style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <thead>
                <tr>
                  <th style={{ width: '10%', ...style.left, ...style.text }}></th>
                  <th style={{ width: '54%', ...style.left, ...style.text }}>Ítem</th>
                  <th
                    style={{
                      width: '18%',
                      ...style.right,
                      ...style.text,
                      paddingRight: 3,
                    }}>
                    Precio
                  </th>
                  <th
                    style={{
                      width: '18%',
                      ...style.right,
                      ...style.text,
                      paddingRight: 3,
                    }}>
                    Total
                  </th>
                </tr>
              </thead>

              {getItems()?.map((d, index) => (
                <tr key={index}>
                  <td style={{ width: '10%', ...style.left, ...style.text }}>
                    {d.quantity}
                  </td>
                  <td style={{ width: '54%', ...style.left, ...style.text }}>
                    {d.name || 'N/A'}
                  </td>
                  <td
                    style={{
                      width: '18%',
                      ...style.right,
                      ...style.textContent,
                      paddingRight: 3,
                    }}>
                    {getNumber(d.subtotal / d.quantity)}
                  </td>
                  <td
                    style={{
                      width: '18%',
                      ...style.right,
                      ...style.textContent,
                      paddingRight: 3,
                    }}>
                    {getNumber(d.subtotal)}
                  </td>
                </tr>
              ))}
            </table>
          </div>

          <div>
            {getSubTotals().map((v, index) => (
              <div key={index} style={{ ...style.spaceBetween }}>
                <div style={{ ...style.text }}>
                  {v.label} {v.name}
                </div>
                <div style={{ ...style.textContent }}>
                  {formatNumberWithCommas(v.value)}
                </div>
              </div>
            ))}
          </div>

          <div style={{ ...style.left, marginTop: 15 }}>
            <div style={{ ...style.textContent, ...style.detail }}>
              Cliente:
              <span>{getValue(clientData.label, 'CONSUMIDOR FINAL')}</span>
            </div>
            <div style={{ ...style.textContent, ...style.detail }}>
              NIT:
              <span>{getValue(clientData.nit, 'CF')}</span>
            </div>
            <div style={{ ...style.textContent, ...style.detail }}>
              Email:
              <span>{getValue(clientData.email)}</span>
            </div>
            <div style={{ ...style.textContent, ...style.detail }}>
              Dirección:
              <span>{getValue(clientData.address)}</span>
            </div>
            {showDeliveryData && (
              <>
                <div style={{ ...style.textContent, ...style.detail }}>
                  Teléfono:
                  <span>{getValue(clientData.phone)}</span>
                </div>

                <div style={{ ...style.textContent, ...style.detail }}>
                  Referencia:
                  <span>{getValue(clientData.reference)}</span>
                </div>
              </>
            )}
            {footerPhrase && footerPhrase !== '' && (
              <div style={{ ...style.textContent, ...style.center }}>
                <br />
                <br />
                <span>{footerPhrase}</span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div style={{ display: 'none' }}>
        <canvas id={'pre-bill-canvas'} width={300} height={175} />
        {pos?.ticketLogo && (
          <img
            id={'pre-bill-ticket-logo'}
            alt={'logo'}
            src={`data:image/bmp;base64,${pos.ticketLogo}`}
          />
        )}
      </div>
    </>
  )
}
export default PrintTicket
