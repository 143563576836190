import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Td, Tr } from 'react-super-responsive-table'
import { geolocated } from 'react-geolocated'
import { Row, Col, ToggleButtonGroup, ToggleButton, Modal } from 'react-bootstrap'
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from '@fortawesome/free-solid-svg-icons'
import Alert from 'sweetalert-react'

import FormText from '../../../../components/inputs/FormTextField/FormText'
import MapCard from '../../../../components/maps/MapCard'
import Button from '../../../../components/buttons/Button'
import Select from '../../../../components/inputs/Select/CustomSelect'

import { selectAllPolygonsBySelect } from '../../../../selectors/polygons.selector'

import { setInformationToOffer } from '../../../../actions/offers.actions'
import { selectOffer } from '../../../../selectors/offers.selector'

import {
  actionTypes as typssP,
  getTransportationPayments,
} from '../../../../actions/products.actions'

import { loadingSelector } from '../../../../selectors/loading.selector'
import {
  hasErrorsSelector,
  singleErrorSelector,
  handlerError,
} from '../../../../selectors/error.selector'

import MarkerIcon from '../../../../assets/images/svg/marker.svg'
import { getPolygonByCategory } from '../../../../actions/polygons.actions'
import { selectCurrentCurrency } from '../../../../selectors/currencies.selector'
import { TableV2 } from '../../../../components'

const Marker = () => <img src={MarkerIcon} alt={'position'} width={40} />

const days = [
  { id: 0, title: 'Domingo' },
  { id: 1, title: 'Lunes' },
  { id: 2, title: 'Martes' },
  { id: 3, title: 'Miercoles' },
  { id: 4, title: 'Jueves' },
  { id: 5, title: 'Viernes' },
  { id: 6, title: 'Sabado' },
]

const times = [
  { id: 0, title: '1:00' },
  { id: 1, title: '2:00' },
  { id: 2, title: '3:00' },
  { id: 3, title: '4:00' },
  { id: 4, title: '5:00' },
  { id: 5, title: '6:00' },
  { id: 6, title: '7:00' },
  { id: 7, title: '8:00' },
  { id: 8, title: '9:00' },
  { id: 9, title: '10:00' },
  { id: 10, title: '11:00' },
  { id: 11, title: '12:00' },
  { id: 12, title: '13:00' },
  { id: 13, title: '14:00' },
  { id: 14, title: '15:00' },
  { id: 15, title: '16:00' },
  { id: 16, title: '17:00' },
  { id: 17, title: '18:00' },
  { id: 18, title: '19:00' },
  { id: 19, title: '20:00' },
  { id: 20, title: '21:00' },
  { id: 21, title: '22:00' },
  { id: 22, title: '23:00' },
  { id: 23, title: '24:00' },
]

class OfferPosition extends Component {
  state = {
    mapS: null,
    mapsS: null,
    manualPolygon: null,
    alert: { title: 'title' },
    pickup: false,
    modal: { show: false, point: {} },
    pickUpDays: [],
    pickUpTimes1: [],
    pickUpTimes2: [],
    type: null,
    estimatedDeliveryDays: 0,
  }

  componentWillReceiveProps(next) {
    const { loading, hasError } = this.props
    const { error } = next
    if (loading && !next.loading) {
      let alert = { title: 'title' }

      if (!hasError && next.hasError) {
        alert = handlerError(error.message || 'Ha ocurrido un error inesperado.')
        alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
      }
      this.setState({ alert })
    }
  }

  onGoogleApiLoaded = (map, maps) => {
    setTimeout(() => {
      const { offer } = this.props
      this.setState({ mapS: map, mapsS: maps })
      if (offer.polygon && offer.polygon.value) {
        this.setPolygon(offer.polygon)
      }
    }, 1000)
  }

  setPolygon = polygon => {
    if (polygon.value) {
      let { mapS, mapsS, manualPolygon } = this.state
      if (manualPolygon) manualPolygon.setMap(null)
      if ((mapS, mapsS)) {
        const points = polygon.points.map(p => ({
          lat: Number(p.latitude),
          lng: Number(p.longitude),
        }))
        manualPolygon = new mapsS.Polygon({
          path: points,
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.35,
        })

        const center = this.getCenter(points)
        mapS.setZoom(7)
        if (center.latLng.lat > 0) mapS.panTo(center.latLng)
        manualPolygon.setMap(mapS)
        this.setState({ manualPolygon })
      }
    }
  }

  getCenter = paths => {
    const center = paths
      .map(p => {
        return [p.lat, p.lng]
      })
      .reduce(
        (x, y) => {
          return [x[0] + y[0] / paths.length, x[1] + y[1] / paths.length]
        },
        [0, 0],
      )
    return { latLng: { lat: center[0], lng: center[1] } }
  }

  getLabel = (offer, symbol) => {
    let label = { label: '', sign: symbol }
    if (offer.category && offer.category.value) {
      switch (offer.category.value) {
        case 2:
          label.label = offer.category.description
          break
        case 3:
          label.label = offer.category.description
          break
        case 4:
          label = { label: offer.category.description, sign: '%' }
          break
        case 5:
          label = { label: offer.category.description, sign: '%' }
          break
      }
    }
    return label
  }

  onChangeInput = event => {
    const { name, value } = event.target
    const { offer, setInfo } = this.props
    const { polygon } = offer
    polygon[name] = value

    offer.polygon = polygon

    setInfo(offer)
  }

  renderRowPickUpPoint = (item, index) => {
    const d = item.pickUpDays.split(',')
    d.sort()

    const pd = d.map((item, index) => {
      return days[parseInt(item)].title
    })

    const pickUpTimesArray = item.pickUpTimes.split(',').map((item, index) => {
      return times[parseInt(item)].title
    })

    let lat = parseFloat(item.latitude).toFixed(4)
    let lng = parseFloat(item.longitude).toFixed(4)

    return (
      <Tr className={'data'} key={`${index}`}>
        <Td className={'short'}>{lat + ' , ' + lng}</Td>
        {/*
                <Td className={'short'}>Q.{formatNumberWithCommas(item.returnableBox)}</Td>
                <Td className={'short'}>Q.{formatNumberWithCommas(item.nonReturnableBox)}</Td>
                */}
        <Td className={'short'}>{pd.toString().replace(/,/g, ', ')}</Td>
        <Td className={'short'}>{pickUpTimesArray.toString().replace(/,/g, ', ')}</Td>
        <Td className={'mini'}>{item.type == 0 ? 'Fija' : 'Movil'}</Td>
      </Tr>
    )
  }

  detailPickUpPoint = ({ lat, lng }) => {
    const point = { id: 'p1', lat, lng, value: 'd' }
    this.setState({ modal: { show: true, point } })
  }

  filter = item => {
    const { offer } = this.props
    if (!offer.pickup) return item

    const { value } = offer.pickup

    return (
      (value === 1 && item.value === undefined) ||
      (value === 2 && item.value != undefined)
    )
  }

  handlePickUpDays = value => {
    this.setState({ pickUpDays: value })
  }

  handleType = value => {
    this.setState({ type: value })
  }

  handlePickUpTimes = (value, type) => {
    if (type == 1) this.setState({ pickUpTimes1: value })
    if (type == 2) this.setState({ pickUpTimes2: value })
  }

  onChangeEstimatedDeliveryDays = value => {
    if (value > 0) {
      this.setState({ estimatedDeliveryDays: value })
    }
  }

  addPickUpPoint = () => {
    let { pickUpDays, pickUpTimes1, pickUpTimes2, type, estimatedDeliveryDays } =
      this.state
    let { point } = this.state.modal
    const { offer, setInfo } = this.props

    const pickUpTimesArray = pickUpTimes1.concat(pickUpTimes2)

    pickUpTimesArray.sort(function (a, b) {
      if (parseInt(a) < parseInt(b)) {
        return -1
      }
      if (parseInt(b) < parseInt(a)) {
        return 1
      }
      return 0
    })

    const newPoint = {
      id: point.id,
      value: point.id,
      latitude: point.lat,
      longitude: point.lng,
      returnableBox: 0,
      nonReturnableBox: 0,
      pickUpDays: pickUpDays.toString(),
      pickUpTimes: pickUpTimesArray.toString(),
      type: type,
      estimatedDeliveryDays: estimatedDeliveryDays,
    }

    offer.polygon.pickUpPoints.push(newPoint)
    setInfo(offer)
    this.setState({
      modal: { show: false, point: {} },
      pickUpDays: [],
      pickUpTimes1: [],
      pickUpTimes2: [],
      currentPoint: {},
      type: null,
      estimatedDeliveryDays: 0,
      pickup: false,
    })
  }

  getColor = category => {
    let color = 'white'
    switch (category) {
      case 1:
        color = 'kolo_green'
        break
      case 2:
        color = 'kolo_gray'
        break
      case 3:
        color = 'kolo_red'
        break
      case 4:
        color = 'kolo_pink'
        break
      case 5:
        color = 'kolo_blue'
        break
      default:
        color = 'white'
    }
    return color
  }

  selectPolygon = polygon => {
    const { offer, setInfo } = this.props
    if (offer.category.value === 5) polygon.deliveryAmount = 17

    setInfo({ ...offer, polygon })
    this.setPolygon(polygon)
  }

  render() {
    const { getTransportationPayments, polygons, offer, setInfo, currentCurrency } =
      this.props
    const { show } = this.state.modal

    const { polygon } = offer

    const latitude = 14.589465440976774
    const longitude = -90.51898866891861

    let deliveryAmount = 0
    let pickUpPoints = []
    if (polygon) {
      deliveryAmount = polygon.deliveryAmount
      pickUpPoints = polygon.pickUpPoints || []
    }

    const label = this.getLabel(offer)

    let headers = [
      {
        label: '',
        show: true,
        classNameCustom: item => `mini ${this.getColor(item.categoryId)}`,
        simpleChecked: true,
        select: true,
        custom: item => (
          <label className={'checkbox-label'}>
            <input
              type={'checkbox'}
              name={'kolo'}
              checked={polygon.value === item.value}
              onChange={() => {
                this.selectPolygon(item, true)
              }}
            />
          </label>
        ),
      },
      {
        label: 'Categoria',
        show: true,
        value: ['category', 'name'],
        type: 'text',
        className: 'short',
      },
      { label: 'Código', show: true, value: ['code'], type: 'text', className: 'short' },
      {
        label: 'Nombre',
        show: true,
        value: ['label'],
        type: 'text',
        className: 'medium',
      },
      { label: 'Nota', show: true, value: ['notes'], type: 'text' },
    ]

    if (offer.kolo) {
      headers.push({
        label: 'Transportista',
        show: true,
        value: ['shipper', 'name'],
        type: 'text',
        className: 'medium',
        custom: item => (item.shipper ? item.shipper.name : 'Sin definir'),
      })
    }

    headers.push({ config: true, show: true, label: '', className: 'mini center' })

    return (
      <div className={'column'}>
        <Row>
          <Col lg={7} md={7} sm={12} xs={12} className={'card'}>
            {/*
                        <br/>
                        {(offer.category && offer.category.value != 1 && offer.category.value != 5) &&
                        <label className={'checkbox-label left'}>
                            <input type={'checkbox'} name={'kolo'} checked={offer.kolo} onChange={({target}) => {
                                setInfo({...offer, kolo: target.checked});
                            }}/> {offer.kolo ? 'Activo' : 'Desactivado'}: Los productos listados en este contrato seran vistos en KOLO
                        </label>}
                        */}

            <Select
              label={'Tipo de transporte'}
              options={[
                { value: false, label: 'Enlazar con terceros' },
                { value: true, label: 'Enlazar con transporte certificado por kolo' },
              ]}
              value={
                !offer.kolo
                  ? { value: false, label: 'Enlazar con terceros' }
                  : { value: true, label: 'Enlazar con transporte certificado por kolo' }
              }
              onChange={d => {
                setInfo({ ...offer, kolo: d.value })
                this.props.getPolygons(d.value ? 5 : 1)
              }}
              disabled={offer.category.value === 5}
            />
            <TableV2
              items={polygons}
              pSize={0}
              headers={headers}
              mobileAuto
              storageKey={`polygonsoffer`}
              onClickTr={(e, item) => {
                this.selectPolygon(item, true)
              }}
              isSelected={item => polygon.value === item.value}
            />
          </Col>
          <Col lg={5} md={5} sm={12} xs={12}>
            <Row>
              <Col xl={12} lg={12} md={12} sx={12} xs={12}>
                <Row>
                  <Col md={12}>
                    <FormText
                      disabled={!offer.polygon || offer.category.value === 5}
                      label={label.label}
                      value={deliveryAmount}
                      prepend={label.sign}
                      onChange={this.onChangeInput}
                      name={'deliveryAmount'}
                      type={'number'}
                      required
                    />
                  </Col>
                  {/*
                        <Col md={12}><Select
                            label={'Tipo de recoleccion'}
                            options={[{value: 1, label: 'A bodega de transportista'}, {value: 2, label: 'A bodega de productor'}]}
                            value={offer.pickup}
                            disabled={!polygon}
                            onChange={(p) => {
                                offer.pickup = p;
                                setInfo(offer);
                            }}
                            required
                        /></Col>
                        <Col md={12}><CollapsibleCard className={'sub-style'} header={{backgroundColor: '#1b4060'}} title={'Puntos de recolección'}>
                            {offer.pickup.value === 2 &&
                            <Row className={'container-buttons'}>
                                <Button disabled={!this.state.pickup} onClick={() => this.setState({pickup: false})} icon={faStopCircle}> Cancelar</Button>
                                <Button disabled={this.state.pickup || pickUpPoints.filter((p) => p.value).length >= 1} onClick={() => this.setState({pickup: true})} icon={faMapMarker} variant={'info'}> Agregar punto de recolección</Button>
                            </Row>}
                            <Table
                                headers={['Latitud y Longitud', 'Días de recolección', 'Horario', 'Tipo']}
                                items={pickUpPoints.filter(this.filter)}
                                renderRow={this.renderRowPickUpPoint}
                            />
                        </CollapsibleCard></Col>
                        */}
                </Row>
              </Col>
              <Col>
                <MapCard
                  title={polygon ? polygon.label : ''}
                  latitude={latitude}
                  longitude={longitude}
                  height={500}
                  startCollapsed={false}
                  markerList={[]}
                  onClick={this.state.pickup && this.detailPickUpPoint}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={({ map, maps }) =>
                    this.onGoogleApiLoaded(map, maps)
                  }>
                  {polygon.value != 0 &&
                    polygon.pickUpPoints.length > 0 &&
                    polygon.pickUpPoints.filter(this.filter).map((item, index) => (
                      <div
                        key={`${index}`}
                        lat={Number(item.latitude)}
                        lng={Number(item.longitude)}
                        style={{ position: 'absolute', left: -20, top: -40 }}>
                        <Marker />
                      </div>
                    ))}

                  <div>hil</div>
                </MapCard>
              </Col>
            </Row>
          </Col>
        </Row>

        {/*
                <br/>
                <br/>
                <br/>
                <Select
                    label={'Poligonos de ofertas'}
                    options={polygons}
                    value={offer.polygon}
                    onChange={(polygon) => {
                        if (offer.category.value === 5)
                            polygon.deliveryAmount = 15;

                        setInfo({...offer, polygon});
                        this.setPolygon(polygon);
                        // getTransportationPayments(polygon.id);
                    }}
                    required
                />
                */}

        <Row className={'container-buttons'}>
          <Button onClick={() => this.props.jumpToStep(0)} icon={faLongArrowAltLeft}>
            Atras
          </Button>
          <Button
            disabled={!polygon || !polygon.deliveryAmount || polygon.deliveryAmount <= 0}
            onClick={() => this.props.jumpToStep(2)}
            icon={faLongArrowAltRight}>
            Siguiente
          </Button>
        </Row>

        <Alert {...this.state.alert} />

        <Modal
          size={'lg'}
          centered
          show={show}
          onHide={() => this.setState({ modal: { show: false, point: {} } })}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Marker />
              Punto de recolección
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Row
                style={{ justifyContent: 'flex-start', marginLeft: 16, marginRight: 16 }}>
                <Col>
                  <div>
                    <h6>Dias de recolección</h6>
                    <ToggleButtonGroup
                      type="checkbox"
                      value={this.state.pickUpDays}
                      onChange={this.handlePickUpDays}>
                      {days.map((day, index) => (
                        <ToggleButton key={index} value={day.id}>
                          {day.title}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </div>
                </Col>
              </Row>
              <br />
              <Row
                style={{ justifyContent: 'flex-start', marginLeft: 16, marginRight: 16 }}>
                <Col>
                  <div>
                    <h6>Horarios de recolección</h6>
                    <Row
                      style={{
                        justifyContent: 'center',
                        backgroundColor: '#F0F0F0',
                        padding: 10,
                        margin: 10,
                      }}>
                      <Col style={{ textAlign: 'right' }}>
                        <ToggleButtonGroup
                          ToggleButtonGroup
                          vertical
                          type="checkbox"
                          value={this.state.pickUpTimes1}
                          onChange={data => {
                            this.handlePickUpTimes(data, 1)
                          }}>
                          {times
                            .filter((i, index) => index < 12)
                            .map((time, index) => (
                              <ToggleButton key={index} value={time.id}>
                                {time.title}
                              </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                      </Col>
                      <Col>
                        <ToggleButtonGroup
                          vertical
                          type="checkbox"
                          value={this.state.pickUpTimes2}
                          onChange={data => {
                            this.handlePickUpTimes(data, 2)
                          }}>
                          {times
                            .filter((i, index) => index > 11)
                            .map((time, index) => (
                              <ToggleButton key={index} value={time.id}>
                                {time.title}
                              </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={6}>
                  <Row>
                    <div>
                      <h6>Tipo:</h6>
                      <ToggleButtonGroup
                        type="radio"
                        name="options"
                        value={this.state.type}
                        onChange={this.handleType}>
                        <ToggleButton value={0}>Fija</ToggleButton>
                        <ToggleButton value={1}>Movil</ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </Row>
                  <br />
                  <Row>
                    <div>
                      <h6>Tiempo de entrega</h6>
                      <FormText
                        type={'number'}
                        prepend={'Días'}
                        value={this.state.estimatedDeliveryDays}
                        onChange={({ target }) =>
                          this.onChangeEstimatedDeliveryDays(target.value)
                        }
                      />
                    </div>
                  </Row>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Row className={'container-buttons'}>
              <Button
                disabled={
                  this.state.type === null ||
                  this.state.pickUpDays.length == 0 ||
                  (this.state.pickUpTimes1.length == 0 &&
                    this.state.pickUpTimes2.length == 0)
                }
                variant="secondary"
                onClick={() => this.addPickUpPoint()}>
                Agregar
              </Button>
            </Row>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  polygons: selectAllPolygonsBySelect(state),
  offer: selectOffer(state),
  loading: loadingSelector([typssP.TRANSPORT_PAYMENT_ALL])(state),
  hasError: hasErrorsSelector([typssP.TRANSPORT_PAYMENT_ALL])(state),
  error: singleErrorSelector([typssP.TRANSPORT_PAYMENT_ALL])(state),
  currentCurrency: selectCurrentCurrency(state),
})
const mapDispatchToProps = dispatch => ({
  setInfo: offer => dispatch(setInformationToOffer(offer)),
  getTransportationPayments: polygonId =>
    dispatch(getTransportationPayments(0, polygonId)),
  getPolygons: category => dispatch(getPolygonByCategory(category)),
})

const ReduxComponent = connect(mapStateToProps, mapDispatchToProps)(OfferPosition)
export default geolocated({
  positionOptions: { enableHighAccuracy: false },
  userDecisionTimeout: 500,
})(ReduxComponent)
