import './DemoFinished.scss'
import React from 'react'
import { Button, Title } from 'src/components'
import { Modal } from 'react-bootstrap'
import Dashboard from 'src/content/Dashboard'
import { Link } from 'react-router-dom'

const DemoFinished = ({ isOwner, isTest }) => {
  return (
    <>
      <Dashboard noFunctionality />

      <div className="blur-overlay" />

      <Modal
        show
        backdrop={false}
        backdropClassName="demo-backdrop"
        size={'lg'}
        style={{
          pointerEvents: 'none',
          zIndex: 1,
        }}
        centered>
        <Modal.Header>
          <Modal.Title>
            {isTest ? 'Tu periodo de prueba ha finalizado' : 'Tu plan ha expirado'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="demo-base">
            <Title title="¡Gracias por usar KOLO!" />
            <p className="b-user-email">
              Para seguir disfrutando del control que KOLO te brinda renueva tu plan o
              bien contáctate con nuestros asesores.
            </p>
            <div className="demo-buttons">
              <a
                href="https://info.kolo-app.com/precios/"
                target="_blank"
                rel="noreferrer">
                <Button>Ver planes</Button>
              </a>
              {isOwner && (
                <Link to="/perfil/cuenta">
                  <Button>Renovar plan</Button>
                </Link>
              )}
              <a href="https://wa.me/+50245252182" target="_blank" rel="noreferrer">
                <Button color="secondary">Contactar a un asesor</Button>
              </a>
            </div>
            <p className="b-user-email">
              O puedes llamar al <a href="tel:502-4525-2182">+502 4525-2182</a>.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default DemoFinished
