import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Bar, Doughnut, Line } from 'react-chartjs-2'
import { Row, Col, ProgressBar } from 'react-bootstrap'
import {
  Card,
  Select as CustomSelect,
  CustomDate as CustomDatePicker,
  Icon as ButtonIcon,
  Title,
  Button,
} from 'src/components'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  getCategorizations,
  getInventoryReport,
  getWarehouseLite,
} from 'src/actions/warehouse.actions'
import {
  selectCategorizations,
  selectInventoryDetails,
  selectOwnWarehouseLite,
} from 'src/selectors/warehouse.selector'

import { getProductsSimple } from 'src/actions/products.actions'
import { selectProductsSimple } from 'src/selectors/products.selector'

import { loadingSelector } from 'src/selectors/loading.selector'

import { getCategorization } from 'src/actions/categorization.actions'
import { selectCategorization } from 'src/selectors/categorizations.selector'

import { graphs as colorArray } from 'src/utils/graphs'

const chartOptions2 = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        offset: true,
        ticks: {
          beginAtZero: true,
          callback: function (label) {
            return label > 999999
              ? label / 100000000 + 'M'
              : label > 999
              ? label / 1000 + 'k'
              : label
          },
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || ''
        if (label) {
          label += ': '
        }
        label += tooltipItem.yLabel + ' unidades'
        return label
      },
    },
  },
  plugins: {
    labels: {
      render: function () {
        return ''
      },
      display: false,
      fontSize: 12,
      fontStyle: 'bold',
      fontColor: '#282c34',
      fontFamily: 'Montserrat',
    },
  },
  legend: {
    display: false,
  },
}

const chartOptions2Mobile = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        offset: true,
        ticks: {
          beginAtZero: true,
          callback: function (label) {
            return label > 999999
              ? label / 100000000 + 'M'
              : label > 999
              ? label / 1000 + 'k'
              : label
          },
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || ''
        if (label) {
          label += ': '
        }
        label += tooltipItem.yLabel + ' unidades'
        return label
      },
    },
  },
  plugins: {
    labels: {
      render: function () {
        return ''
      },
      overlap: false,
    },
  },
  legend: {
    display: false,
  },
}
class InventoryHistory extends Component {
  state = {
    startDate: new Date(),
    endDate: new Date(),
    selectedWarehouse: null,
    selectedProduct: null,
    selectedCategorization: null,
    products: [],
    categorizations: [],
    graph: { value: 0, label: 'Barra' },
    graphForm: [
      { value: 0, label: 'Barra' },
      { value: 1, label: 'Lineal' },
    ],
    showCategorizations: false,
    categorizationChildren: [],
    root: { value: 0, label: 'Seleccione una categorización' },
    selectedType: { value: 1, label: 'Día' },
  }

  componentDidMount() {
    this.props.getWarehouses()
    this.props.getCategorizations()
    this.props.getProducts()
  }

  setUp = () => {
    let {
      selectedWarehouse,
      products,
      categorizations,
      startDate,
      endDate,
      selectedType,
    } = this.state

    const warehouseId =
      selectedWarehouse && selectedWarehouse.value > 0 ? selectedWarehouse.value : null
    if (products.length > 0 || categorizations.length > 0) {
      let request = { products: [], categorizations: [] }
      request.products = products
      categorizations.forEach(c => {
        request.categorizations.push(c.id)
      })
      //console.log(request);
      this.props.getListInventoryDetail(
        warehouseId,
        request,
        startDate.getTime(),
        endDate.getTime(),
        selectedType?.value,
      )
    }
  }

  onDateFromChange = date => {
    if (date) {
      this.setState({ startDate: date })
    }
  }

  onDateToChange = date => {
    if (date) {
      this.setState({ endDate: date })
    }
  }

  onSelectWarehouse = selectedWarehouse => {
    this.setState({ selectedWarehouse })
  }

  onSelectType = selectedType => {
    this.setState({ selectedType })
  }

  onSelectProduct = selectedProduct => {
    const { products } = this.state
    if (products.findIndex(p => p === selectedProduct.value) === -1) {
      products.push(selectedProduct.value)
      this.setState({ products })
    }
  }

  onGraphChange = e => {
    this.setState({ graph: e })
  }

  getProductName = id => {
    const { allProducts } = this.props
    let name = 'Sin nombre'
    allProducts.forEach(p => {
      if (p.value === id) name = p.label
    })
    return name
  }

  getInfoToChart = (labels, index) => {
    console.log(labels)
    // let labels = this.getLabels(details)
    return {
      labels: labels.details.map(r => {
        return `${r.movementTypeName}`
      }),
      datasets: [
        {
          data: labels.details.map(r => {
            return r.originalQuantity
          }),
          label: labels.name,
          backgroundColor: colorArray[index] + '66',
          borderColor: colorArray[index],
          borderWidth: 2,
        },
      ],
    }
  }

  deleteCategorization = id => {
    let { categorizations } = this.state
    categorizations = categorizations.filter(c => c.id !== id)
    this.setState({ categorizations })
  }

  deleteProduct = id => {
    let { products } = this.state
    products = products.filter(p => p !== id)
    this.setState({ products })
  }

  //render
  renderHistoryChart = () => {
    const { graph, graphForm } = this.state
    const { inventoryDetails } = this.props
    return (
      <div style={{ backgroundColor: 'white', color: 'default' }}>
        <Card style={{ marginBottom: 0 }}>
          <Row>
            <CustomSelect
              label={'Forma:'}
              options={graphForm}
              value={graph}
              onChange={e => this.onGraphChange(e)}
            />
          </Row>
          <Col sm={12}>
            {inventoryDetails &&
              inventoryDetails.length > 0 &&
              graph.value === 0 &&
              inventoryDetails.map((i, index) => {
                return (
                  <div key={index}>
                    <div className="g-title">{i.name}</div>
                    <div className="ru-graph-w">
                      <Bar
                        data={this.getInfoToChart(i, index)}
                        height={300}
                        options={chartOptions2}
                      />
                    </div>
                    <div className="ru-graph-m">
                      <Bar
                        data={this.getInfoToChart(i, index)}
                        height={400}
                        options={chartOptions2Mobile}
                      />
                    </div>
                  </div>
                )
              })}
            {graph.value === 1 &&
              inventoryDetails.map((i, index) => (
                <div key={index}>
                  <div className="g-title">{i.name}</div>
                  <div className="ru-graph-w">
                    <Line
                      data={this.getInfoToChart(i, index)}
                      height={300}
                      options={chartOptions2}
                    />
                  </div>
                  <div className="ru-graph-m">
                    <Line
                      data={this.getInfoToChart(i, index)}
                      height={400}
                      options={chartOptions2Mobile}
                    />
                  </div>
                </div>
              ))}
            {graph.value === 2 &&
              inventoryDetails.map((i, index) => (
                <div key={index}>
                  <div className="g-title">{i.name}</div>
                  <div className="ru-graph-w">
                    <Doughnut data={this.getInfoToChart(i, index)} height={200} />
                  </div>
                  <div className="ru-graph-m">
                    <Doughnut data={this.getInfoToChart(i, index)} height={400} />
                  </div>
                </div>
              ))}
          </Col>
        </Card>
      </div>
    )
  }

  render() {
    const {
      selectedWarehouse,
      selectedProduct,
      startDate,
      endDate,
      products,
      selectedType,
    } = this.state
    const { allProducts, warehouses } = this.props

    return (
      <div>
        <Title title={'Reporte de Inventario'} />
        <Row>
          <Col>
            <Card title={'Filtros'} white>
              <Row>
                <Col xs={12} sm={12} md={3} lg={2} xl={2}>
                  <CustomDatePicker
                    disabled={false}
                    label={'Desde:'}
                    value={startDate}
                    onDayChange={this.onDateFromChange}
                  />
                </Col>
                <Col xs={12} sm={12} md={3} lg={2} xl={2}>
                  <CustomDatePicker
                    disabledDays={{ before: startDate }}
                    disabled={false}
                    label={'Hasta:'}
                    value={endDate}
                    onDayChange={this.onDateToChange}
                  />
                </Col>
                <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                  <CustomSelect
                    label={'Modo de presentación'}
                    value={selectedType}
                    options={[
                      { value: 1, label: 'Día' },
                      { value: 2, label: 'Semana' },
                      { value: 3, label: 'Mes' },
                    ]}
                    onChange={this.onSelectType}
                  />
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                  <CustomSelect
                    label={'Bodega'}
                    value={selectedWarehouse}
                    options={warehouses}
                    onChange={this.onSelectWarehouse}
                  />
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                  <CustomSelect
                    label={'Producto'}
                    value={selectedProduct}
                    options={allProducts.filter(
                      p => p.value !== 0 && products.findIndex(d => d === p.value) === -1,
                    )}
                    onChange={this.onSelectProduct}
                  />
                </Col>
                <Col xl={12}>
                  <Row>
                    {products &&
                      products.map((p, i) => (
                        <div className={'permission'} key={i}>
                          <label style={{ marginRight: 5, paddingTop: 5 }}>
                            {this.getProductName(p)}
                          </label>
                          <ButtonIcon
                            className={'delete-button'}
                            icon={faTimes}
                            tooltip={'Quitar'}
                            color={'white'}
                            onClick={() => this.deleteProduct(p)}
                          />
                        </div>
                      ))}
                  </Row>
                </Col>
                <Col xl={12}>
                  <hr />
                </Col>
                <Col xl={12}>
                  <Row className={'container-buttons'}>
                    <Button
                      loading={this.props.inventoryHistoryLoading}
                      icon={faSearch}
                      color={'accent'}
                      onClick={() => this.setUp()}>
                      Aplicar Filtros
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card title={'Historial de Inventario'} white>
              <Row>
                <Col xl={12}>
                  {this.props.inventoryHistoryLoading && (
                    <ProgressBar animated now={100} />
                  )}
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  {products && products.length > 0 && this.renderHistoryChart()}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  inventoryDetails: selectInventoryDetails(state),
  warehouses: selectOwnWarehouseLite(state),
  categorizations: selectCategorizations(state),
  inventoryHistoryLoading: loadingSelector([actionTypes.GET_INVENTORY_DETAILS])(state),
  allProducts: selectProductsSimple(state),

  //categorizations
  categorization: selectCategorization(state),
  categorizationLoading: loadingSelector([actionTypes.GET_ALL_CATEGORIZATIONS])(state),
})

const mapDispatchToProps = dispatch => ({
  getListInventoryDetail: (warehouseId, request, startDate, endDate, type) =>
    dispatch(getInventoryReport(warehouseId, request, startDate, endDate, type)),
  getCategorizations: () => dispatch(getCategorizations()),
  getWarehouses: () => dispatch(getWarehouseLite()),
  getProducts: () => dispatch(getProductsSimple()),
  getCategorization: id => dispatch(getCategorization(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InventoryHistory)
