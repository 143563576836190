import React from 'react'

import { Title } from 'src/components'
import NumberFieldPlayground from 'src/content/Playground/NumberFieldPlayground'

/**
 * Playground page intended to test components / features
 * @page
 */
const PlaygroundPage = () => {
  return (
    <div>
      <Title title={'Playground'} />
      <NumberFieldPlayground />
    </div>
  )
}

export default PlaygroundPage
