import { actionTypes } from 'src/actions/products.actions'
import { actionTypes as companyActionTypes } from 'src/actions/company.actions'
import { extract, successState } from 'src/actions/global.actions'

const initialState = {
  allProducts: [],
  total: 0,
  allProductCategories: [],
  allProductSubCategories: [],
  images: [],
  existenceWithSubitemsAcrossWarehouses: [],
  existenceAcrossWarehouses: [],
  existenceExternalWarehouses: [],
  report: [],
  product: {},
  productsByClient: [],
  productsContract: [],
  productsWarehouses: [],
  uploadResponseProducts: null,
  transportPayment: {
    polygon: { value: null, label: 'No seleccionado' },
    product: {},
    amounts: [],
    kolo: false,
  },
  transportationPaymentC: {},
  transportationPayments: [],
  transportationPayment: {},
  transportationProducts: [],
  productsCompanies: [],
  productsCompany: [],
  productsByCompanyNS: [],
  companies: [],
  deletedArchive: {},
  list: [],
  productList: [],
  productDashboard: [],
  totalD: 0,
  logs: [],
  datasheet: {},
  productLine: [],
  simples: [],
  productsChart: [],
  categoriesChart: [],
  names: [],
  selector: [],
  variations: [],
  productExistences: {},
  measurementUnits: [],
  itemResponse: {},
  itemDetail: {},
}

const ProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_ITEM_DETAIL):
      return { ...state, itemDetail: action.itemDetail }
    case successState(actionTypes.ON_CREATE_OR_UPDATE_ITEM):
      return { ...state, itemResponse: action.itemResponse }
    case successState(companyActionTypes.GET_COMPANY_DASHBOARD):
      return {
        ...state,
        productDashboard: action.payload.products
          ? action.payload.products.list
            ? action.payload.products.list
            : action.payload.products
          : [],
        totalD: action.payload.products ? action.payload.products.total : 0,
      }
    case successState(actionTypes.GET_SINGLE_PRODUCT):
      return { ...state, product: extract(action.product, {}) }
    case successState(actionTypes.GET_ALL_PRODUCTS_SIMPLE):
      return { ...state, simples: extract(action.simples, []) }
    case successState(actionTypes.SEE_PRODUCT_LINE):
      return { ...state, productLine: extract(action.productLine, []) }
    case successState(actionTypes.CLEAR_PRODUCT_LINE):
      return { ...state, productLine: [] }
    case successState(actionTypes.DELETE):
      return { ...state, deletedArchive: extract(action.response, {}) }
    case successState(actionTypes.GET_PRODUCTS_BY_COMPANY_NS):
      return {
        ...state,
        productsByCompanyNS: extract(action.productsByCompanyNS, []),
      }
    case successState(actionTypes.GET_ALL_PRODUCTS_COMPANY):
      return {
        ...state,
        productsCompany: extract(action.productsCompany, []),
      }
    case actionTypes.GET_ALL_PRODUCTS_COMPANIES_CHANGE:
      return {
        ...state,
        productsCompanies: action.items,
      }
    case successState(actionTypes.GET_ALL_PRODUCTS_COMPANIES):
      return {
        ...state,
        productsCompanies: extract(action.productsCompanies, []),
      }
    case successState(actionTypes.TRANSPORT_PAYMENT_PRODUCTS):
      return {
        ...state,
        transportationProducts: extract(action.transportationProducts, []),
      }
    case successState(actionTypes.TRANSPORT_PAYMENT_SINGLE):
      return {
        ...state,
        transportationPayment: extract(action.transportationPayment, {}),
      }
    case successState(actionTypes.TRANSPORT_PAYMENT_ALL):
      return {
        ...state,
        transportationPayments: extract(action.transportationPayments, []),
      }
    case successState(actionTypes.TRANSPORT_PAYMENT_CREATE):
      return {
        ...state,
        transportationPaymentC: extract(action.transportationPaymentC, {}),
      }
    case actionTypes.TRANSPORT_PAYMENT:
      return {
        ...state,
        transportPayment: action.response,
      }
    case successState(actionTypes.GET_ALL_PRODUCTS_BY_CLIENT):
      return {
        ...state,
        productsByClient: extract(action.productsByClient, []),
      }
    case successState(actionTypes.GET_ALL_PRODUCTS_WITH_ACTIVE_CONTRACT):
      return {
        ...state,
        productsContract: extract(action.products, []),
      }
    case actionTypes.GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        allProducts: action.products
          ? action.products.list
            ? action.products.list
            : action.products
          : [],
        //total: action.products ? action.products.total : 0
      }
    case successState(actionTypes.GET_PRODUCT_LIST):
      return {
        ...state,
        productList: action.response
          ? action.response.list
            ? action.response.list
            : action.response
          : [],
        total:
          action.response && action.response.getTotal
            ? action.response.total
            : state.total,
      }

    case actionTypes.GET_MODEL_PRODUCTS_SUCCESS:
      return {
        ...state,
        allProducts: action.products,
        total: action.products ? action.products.size : 0,
      }

    case actionTypes.GET_ALL_PRODUCT_CATEGORIES_SUCCESS:
      return {
        ...state,
        allProductCategories: action.categories,
      }
    case actionTypes.GET_ALL_PRODUCT_SUBCATEGORIES_SUCCESS:
      return {
        ...state,
        allProductSubCategories: action.categories,
      }
    case successState(actionTypes.CREATE_PRODUCT):
      return { ...state, product: extract(action.product, {}) }
    case actionTypes.CREATE_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        newProductCategory: action.newProductCategory,
      }
    case successState(actionTypes.UPDATE_PRODUCT):
      return { ...state, editedProduct: extract(action.product, {}) }
    case actionTypes.DELETE_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
      }
    case actionTypes.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
      }
    case actionTypes.GET_PRODUCT_IMAGES_SUCCESS:
      return {
        ...state,
        images: action.images,
      }
    case successState(actionTypes.GET_EXISTENCE_WITH_SUBITEMS_ACROSS_WAREHOUSES):
      return {
        ...state,
        existenceWithSubitemsAcrossWarehouses: action.existence,
      }
    case actionTypes.EXISTENCE_ACROSS_WAREHOUSES_SUCCESS:
      return {
        ...state,
        existenceAcrossWarehouses: action.existence,
      }
    case actionTypes.EXISTENCE_EXTERNAL_WAREHOUSES_SUCCESS:
      return {
        ...state,
        existenceExternalWarehouses: action.response,
      }
    case successState(actionTypes.PRODUCT_REPORT):
      return {
        ...state,
        report: extract(action.history, []),
      }
    case successState(actionTypes.PRODUCT_LIST_REPORT):
      return {
        ...state,
        list: extract(action.history, []),
      }
    case successState(actionTypes.GET_ALL_PRODUCTS_IN_WAREHOUSES):
      return {
        ...state,
        productsWarehouses: extract(action.productsWarehouses, []),
      }
    case actionTypes.UPLOAD_CSV_SUCCESS:
      return {
        ...state,
        uploadResponseProducts: action.response,
      }
    case successState(actionTypes.GET_COMPANIES_BY_MODEL):
      return {
        ...state,
        companies: action.response,
      }
    case successState(actionTypes.GET_LOG):
      return {
        ...state,
        logs: action.response,
      }
    case successState(actionTypes.GET_DATASHEET):
      return {
        ...state,
        datasheet: action.response,
      }
    case successState(actionTypes.UPDATE_PRODUCT_LINE):
      return {
        ...state,
      }
    case successState(actionTypes.CHART_PRODUCTS):
      return {
        ...state,
        productsChart: action.products,
      }
    case successState(actionTypes.CHART_CATEGORIES):
      return {
        ...state,
        categoriesChart: action.products,
      }
    case successState(actionTypes.GET_PRICES_NAMES):
      return {
        ...state,
        names: extract(action.names, []),
      }
    case successState(actionTypes.GET_PRODUCTS_SELECTOR):
      return {
        ...state,
        selector: action.selector,
      }
    case successState(actionTypes.GET_VARIATIONS):
      return {
        ...state,
        variations: action.variations,
      }
    case successState(actionTypes.GET_MAIN_PRODUCT_EXISTENCE):
      return {
        ...state,
        productExistences: action.response,
      }
    case successState(actionTypes.GET_MEASUREMENT_UNITS):
      return {
        ...state,
        measurementUnits: action.response,
      }
    default:
      return state ? state : initialState
  }
}

export default ProductsReducer
