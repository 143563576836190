import React from 'react'

import { Row, Col } from 'react-bootstrap'
import { Paragraph } from 'src/components'

import { haveAnyValue } from 'src/utils/utilitiesV2'
import { formatDateFromMillis } from 'src/utils/formatters'

interface IDynamicFormPresentationProps {
  data?: any
  presentation: IDynamicFormPresentation[]
  bold?: boolean
}

const DynamicFormPresentation = ({
  data = {},
  presentation = [],
  bold = false,
}: IDynamicFormPresentationProps) => {
  const getValue = (presentation: IDynamicFormPresentation): string => {
    if (!haveAnyValue(data)) return ''

    const value = presentation.values.reduce((acc, key) => acc && acc[key], data)

    switch (presentation.type) {
      case 'date':
        return formatDateFromMillis(value)
      default:
        return value
    }
  }

  return (
    <Row>
      {presentation.map(presentation => (
        <Col
          key={presentation.label}
          xl={4}
          lg={4}
          md={6}
          sm={6}
          xs={6}
          className={'column'}>
          <Paragraph bold={bold}>{presentation.label}</Paragraph>
          <Paragraph dim>{getValue(presentation)}</Paragraph>
        </Col>
      ))}
    </Row>
  )
}
export default DynamicFormPresentation
