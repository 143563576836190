import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import ReactPaginate from 'react-paginate'
import CustomFilter from '../CustomFilter/CustomFilter'

import { selectAllCategorizations } from 'src/selectors/categorizations.selector'

import { OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap'
import Folder, { categoryType } from '../folders/Folder'
import IconButton from '../buttons/IconButton'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { loadingSelector } from 'src/selectors/loading.selector'
import { actionTypes } from 'src/actions/videos.actions'

const PaginatedVideo = ({ data, onEdit, total, onPageChange, onSearch }) => {
  const categorization = useSelector(selectAllCategorizations)

  const [items, setItems] = useState([])
  const [videoList, setVideoList] = useState([])

  const [pageSize] = useState(6)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')

  const [selected, setSelected] = useState([])
  const [showCategories, setShowCategories] = useState(false)

  const loadingVideos = useSelector(state =>
    loadingSelector([actionTypes.GET_VIDEOS])(state),
  )

  useEffect(() => {
    if (!data || !Array.isArray(data)) return
    setItems(data)
    fillVideosList(data)
  }, [data])

  const fillVideosList = videos => {
    const data = []
    videos.forEach(item => {
      item.urls.forEach(t => {
        data.push(t.id)
      })
    })
    setVideoList(data)
  }

  const stopOtherVideos = video => {
    const videos = []
    videoList.forEach(v => {
      var element = document.getElementById(`vid-${v}`)
      if (element) videos.push(element)
    })

    for (let i = 0; i < videos.length; i++) {
      if (videos[i].id !== `vid-${video.id}`) {
        videos[i].pause()
      }
    }
  }

  const filterByCategories = item => {
    if (selected.length === 0) return true
    return (
      item.categories.filter(c => selected.findIndex(s => s.id === c.id) !== -1).length >
      0
    )
  }

  const filter = item => {
    let see = false
    const newItem = {
      title: item.video.title,
      description: item.video.description,
    }
    Object.keys(newItem).forEach(i => {
      const textValue = JSON.stringify(newItem[i]) || ''
      if (textValue.toUpperCase().indexOf(search.toUpperCase()) > -1) see = true
    })

    return search ? see : true
  }

  const renderCategorizations = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexFlow: 'wrap',
          marginTop: 5,
        }}>
        {selected.map((c, index) => (
          <div className="chip" key={index}>
            <div className="chip-head">{c.name && c.name.charAt && c.name.charAt(0)}</div>
            <div className="chip-content">{c.name}</div>
            <div
              className="chip-close"
              onClick={() => {
                setSelected(selected.filter(s => s.id !== c.id))
              }}>
              <svg
                className="chip-svg"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true">
                <path
                  d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7
                                  15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"
                />
              </svg>
            </div>
          </div>
        ))}
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="tooltip">Agregar Categorias</Tooltip>}>
          <div className="chip-close" onClick={() => setShowCategories(true)}>
            <svg
              className="chip-svg chip-add"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true">
              <path
                d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7
                                  15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"
              />
            </svg>
          </div>
        </OverlayTrigger>
      </div>
    )
  }

  const customFilter = () => {
    return (
      <div className={'column'}>
        <label className={'ftf-form-label left mt-3'}>Filtrar por categorias</label>
        <br />
        {renderCategorizations()} <br />
        <hr />
      </div>
    )
  }

  const renderPaginated = () => {
    const itemsToRender = onSearch
      ? items.filter(filterByCategories)
      : items.filter(filterByCategories).filter(filter)
    const totalItems = itemsToRender.length
    const totalPages = Math.ceil((total || totalItems) / pageSize)
    const upperLimit = (page - 1) * pageSize + pageSize
    const lowerLimit = (page - 1) * pageSize

    return (
      <div className={'pv-container'}>
        <CustomFilter
          placeholder={'Buscar por titulo / descripción'}
          search={search}
          onChange={({ target }) => {
            const { value } = target
            setSearch(value)
            onSearch && onSearch(value)
            setPage(1)
          }}
          customFilter={customFilter()}
        />
        {loadingVideos && (
          <div>
            <ProgressBar animated now={100} />
          </div>
        )}

        {itemsToRender.length > 0 ? (
          <div className={'mi-content mi-columns'}>
            {itemsToRender.slice(lowerLimit, upperLimit).map((item, index) => (
              <div key={index} className="mi-video-item">
                {item.urls.length > 0 && (
                  <video
                    onPlay={() => stopOtherVideos(item.urls[0])}
                    id={`vid-${item.urls[0].id}`}
                    width="310"
                    height="234"
                    controls>
                    <source src={item.urls[0].url} type="video/mp4" />
                  </video>
                )}
                <div>
                  <h6 className="mi-video-title d-flex">
                    {item.video.title}
                    {onEdit && (
                      <IconButton
                        style={{ marginLeft: 10 }}
                        icon={faEdit}
                        onClick={() => onEdit(item)}
                      />
                    )}
                  </h6>
                  <p
                    className="mi-video-description"
                    dangerouslySetInnerHTML={{ __html: item.video.description }}
                  />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <h3 style={{ textAlign: 'center', marginTop: 50 }}>Sin videos para mostrar</h3>
        )}

        {totalPages > 1 && (
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={({ selected }) => {
              onPageChange && onPageChange(selected + 1, search)
              setItems(data)
              setTimeout(() => {
                setPage(selected + 1)
              }, 200)
            }}
            containerClassName={'pagination'}
            activeClassName={'active'}
            forcePage={page - 1}
          />
        )}

        <Folder
          onHide={() => setShowCategories(false)}
          onAssign={item => {
            setSelected([...selected, item])
          }}
          data1={
            categorization && categorization.children ? categorization.children[0] : {}
          }
          data2={
            categorization && categorization.children ? categorization.children[1] : {}
          }
          show={showCategories}
          list={selected.map(p => p.id)}
          noMessage
          type={categoryType.VIDEO}
        />
      </div>
    )
  }

  return renderPaginated()
}
export default PaginatedVideo
