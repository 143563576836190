interface PeriodicityType {
  value: number
  label: string
}

const periodicityTypes: PeriodicityType[] = [
  { value: 1, label: 'MENSUAL' },
  { value: 2, label: 'ANUAL' },
]
export default periodicityTypes
