import {
  selectGetCompanyFields,
  selectGetSingleCompany,
} from 'src/selectors/company.selector'

import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import Card from 'src/components/cards/Card'
import check from 'src/assets/images/svg/tick.svg'
import { FilePicker } from 'react-file-picker'
import { ProgressBar } from 'react-bootstrap'

import FormTextField from 'src/components/inputs/FormTextField/FormTextField'
import Button from 'src/components/buttons/Button'
import { faSave, faUpload, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons'
import SweetAlert from 'sweetalert-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  actionTypes,
  getEditableFields,
  getSingleCompany,
  setEditableFields,
} from 'src/actions/company.actions'
import {
  errorsSelector,
  hasErrorsSelector,
  singleErrorSelector,
  handlerError,
  handlerSuccess,
} from 'src/selectors/error.selector'
import { connect } from 'react-redux'
import { $http } from 'src/services/http'
import { getDocuments, uploadDocument } from 'src/actions/uploads.actions'
import IconButton from 'src/components/buttons/IconButton'

import Geocoding from 'src/components/maps/Geocoding/Geocoding'
import { getAllLocalities } from 'src/actions/address.actions'
import { selectAllLocalitiesForSelect } from 'src/selectors/address.selector'
import TextField from 'src/components/inputs/FormTextField/FormTextField'
import Select from '../components/inputs/Select/CustomSelect'
import {
  selectPhonesCompanies,
  selectBusinessTypes,
} from 'src/selectors/utilities.selector'
import { getPhoneCompanies, getBusinessTypes } from '../actions/utilities.actions'
import { getPermission, selectCurrentUserRoles } from 'src/selectors/modules.selector'
import {
  actionTypes as typesC,
  onCreateGlobalClient,
  getGlobalClient,
  onUpdateGlobalClient,
} from '../actions/clients.actions'
import { selectGlobalClient } from 'src/selectors/clients.selector'
import { geolocated } from 'react-geolocated'
import { selectSetCurrentCompany } from 'src/selectors/company.selector'
import { types as t, getActiveAuth, onSetAuth } from '../actions/authorization.actions'
import { selectActiveAuth } from 'src/selectors/authorization.selector'
import { getCurrentUser } from '../actions/user.actions'
import {
  selectCurrentCompany,
  selectCurrentModule,
  selectCurrentUser,
} from 'src/selectors/user.selector'
import { Link } from 'react-router-relative-link'
import { getCurrentUserRolesAction } from '../actions/modules.actions'
import Gallery, { imageTypes } from '../components/gallery/Gallery'
import FormTextArea from '../components/inputs/FormTextArea/FormTextArea'

let DocumentType = {
  DPI: 1,
  PATENTE: 2,
  RTU: 3,
}

let loadedEdited = false
let loadedSet = false

const iconCheck = { width: '20px', heigth: 'auto', margin: '1px' }
class CompanyProfile extends Component {
  state = {
    comercialName: '',
    shortName: '',
    nit: '',
    comercialAddress: '',
    email: '',
    description: '',
    phone: '',
    history: '',
    webSite: '',
    active: this.props.company.active | '',
    code: this.props.company.alphanumericId,
    selectedOption: null,
    company: this.props.company,
    isDistributor: false,
    isClient: false,
    isProducer: false,
    showSuccess: false,

    document_dpi: null,
    document_patente: null,
    document_rtu: null,
    showError: false,
    errorText: '',
    uploadProgress: 0,
    uploadIsLoading: false,
    docUpload: 0,
    canEditDocs: false,

    method: 'GET',
    client: {},
    errors: {},
    alert: { title: 'default' },
    showConfirm: false,
  }
  onProgress = progress => this.setState({ uploadProgress: progress })

  onError = error => {
    this.setState({
      progress: 0,
      showError: true,
      errorText: 'Lo sentimos, no fué posible subir tu imagen en este momento',
    })
  }

  onSuccess = (url, type) => {
    switch (type) {
      case 1:
        this.setState({ document_dpi: url })
        break
      case 2:
        this.setState({ document_patente: url })
        break
      case 3:
        this.setState({ document_rtu: url })
        break
    }
    this.setState({
      uploadIsLoading: false,
      uploadProgress: 0,
      canEditDocs: false,
    })
  }

  loadDocs = docs => {
    //console.log(docs)
    if (docs != null) {
      docs.forEach(val => {
        switch (val.legalDocumentType) {
          case 1:
            this.setState({ document_dpi: val.url })
            break
          case 2:
            this.setState({ document_patente: val.url })
            break
          case 3:
            this.setState({ document_rtu: val.url })
            break
        }
      })
    }
  }

  uploadDpi = file => {
    this.setState({ uploadIsLoading: true, docUpload: DocumentType.DPI })
    this.props.uploadDocument(
      file,
      DocumentType.DPI,
      this.onProgress,
      this.onSuccess,
      this.onError,
    )
  }

  uploadPatente = file => {
    this.setState({ uploadIsLoading: true, docUpload: DocumentType.PATENTE })
    this.props.uploadDocument(
      file,
      DocumentType.PATENTE,
      this.onProgress,
      this.onSuccess,
      this.onError,
    )
  }

  uploadRTU = file => {
    this.setState({ uploadIsLoading: true, docUpload: DocumentType.RTU })
    this.props.uploadDocument(
      file,
      DocumentType.RTU,
      this.onProgress,
      this.onSuccess,
      this.onError,
    )
  }

  validateEmail = value => {
    const emailValid = value && value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    return emailValid ? '' : 'Email invalido'
  }

  validate = () => {
    const { currentCompany, onCreateGlobalClient, onUpdateGlobalClient } = this.props
    const { client } = this.state
    let errors = {}

    if (!client.nit) errors.nit = 'Este campo es requerido'
    if (!client.companyName) errors.companyName = 'Este campo es requerido'
    if (!client.address) errors.address = 'Este campo es requerido'
    if (!client.town) errors.town = 'Este campo es requerido'
    if (!client.businessType) errors.businessType = 'Este campo es requerido'
    if (!client.phoneCompany) errors.phoneCompany = 'Este campo es requerido'
    if (!client.phone) errors.phone = 'Este campo es requerido'
    if (!client.latitude) errors.latitude = 'Este campo es requerido'
    if (!client.longitude) errors.longitude = 'Este campo es requerido'

    if (Object.keys(errors).length === 0) {
      let request = Object.assign(
        {},
        {
          ...client,
          companyId: currentCompany.id,
          businessType: client.businessType.value,
          phoneCompany: client.phoneCompany.value,
          town: client.town.value,
        },
      )
      let alert = {
        show: true,
        type: 'warning',
        title: '¡Atención!',
        text: '¿Desea actualizar la información publica del punto de venta?',
        showCancelButton: true,
        confirmButtonText: 'Si, actualizar',
        confirmButtonColor: 'green',
        cancelButtonText: 'Cancelar',
        onCancel: () => this.setState({ alert: { title: 'default', show: false } }),
      }
      if (currentCompany.clientId) {
        this.setState({ method: 'PUT' })
        alert.onConfirm = () => {
          this.setState({ alert: { title: 'default', show: false } })
          onUpdateGlobalClient(request, currentCompany.clientId)
        }
      } else {
        this.setState({ method: 'POST' })
        alert.onConfirm = () => {
          this.setState({ alert: { title: 'default', show: false } })
          onCreateGlobalClient(request)
        }
      }

      this.setState({ alert })
    }
    this.setState({ errors })
  }

  componentDidMount () {
    const {
      getAllLocalities,
      getPhoneCompanies,
      getBusinessTypes,
      getGlobalClient,
      handlerError,
      currentCompany,
      modules,
    } = this.props
    const idCompany = $http.defaults.headers.common['COTIZAP-Company']
    this.props.getCurrentRoles(1000)
    this.props.getDocuments(this.loadDocs)
    this.props.getSingleCompany(idCompany)
    this.props.getEditableFields()
    getAllLocalities()
    getPhoneCompanies()
    getBusinessTypes()
    if (currentCompany.clientId) getGlobalClient(currentCompany.clientId)
    this.props.getActiveAuth()
  }

  UNSAFE_componentWillReceiveProps (next) {
    const { editableFields, activeAuthLoading, activeHasError } = this.props
    if (next.editableFields !== {} && !loadedEdited) {
      loadedEdited = true
      let editable = next.editableFields
      this.setState({
        comercialName: editable.comercialName,
        email: editable.email,
        comercialAddress: editable.comercialAddress,
        phone: editable.phone,
        description: editable.description,
        history: editable.history,
        webSite: editable.webSite,
        shortName: editable.shortName,
      })
    }
    const { loading, hasError, handlerError, handlerSuccess } = this.props
    const { error, global, activeError } = next
    if (loading && !next.loading) {
      let { alert, method, client } = this.state
      if (!hasError && next.hasError) {
        alert = handlerError(error.message || 'Ha ocurrido un error inesperado')
        alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
      } else {
        if (method != 'GET') {
          alert = handlerSuccess('Información actualizada satisfactoriamente')
          alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
        } else {
          const { businessTypeData, phoneCompanyData, localityData } = global

          const businessType = businessTypeData
            ? { value: businessTypeData.id, label: businessTypeData.name }
            : null
          const phoneCompany = phoneCompanyData
            ? { value: phoneCompanyData.id, label: phoneCompanyData.name }
            : null
          const town = localityData
            ? { value: localityData.id, label: localityData.name }
            : null

          client = Object.assign(
            {},
            {
              ...global,
              businessType,
              phoneCompany,
              town,
            },
          )
        }
      }
      this.setState({ alert, client })
    }
    if (next.editableFields !== editableFields && loadedSet) {
      loadedSet = true
      this.setState({ showSuccess: true })
    }

    if (activeAuthLoading && !next.activeAuthLoading) {
      let { alert } = this.state
      if (!activeHasError && next.activeHasError) {
        alert = handlerError(activeError.message || 'Ha ocurrido un error inesperado')
        alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
      } else {
        alert = handlerSuccess('Solicitud enviada')
        alert.onConfirm = () => {
          this.setState({ alert: { ...alert, show: false } })
          this.props.getActiveAuth()
        }
      }
      this.setState({ alert })
    }
  }

  handleInputChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  saveEdited = () => {
    let {
      email,
      comercialName,
      comercialAddress,
      phone,
      description,
      webSite,
      history,
      shortName,
    } = this.state
    if (!phone) phone = ''
    if (!description) description = ''
    if (!webSite) webSite = ''
    if (!history) history = ''
    if (!shortName) shortName = ''
    this.props.setEditableFields(
      email,
      comercialName,
      comercialAddress,
      phone,
      description,
      history,
      webSite,
      shortName,
    )
    loadedSet = true
  }

  toggleEditDoc = () => {
    this.setState({ canEditDocs: !this.state.canEditDocs })
  }

  button = () => {
    return (
      <IconButton tooltip={'Editar'} icon={faEdit} onClick={() => this.toggleEditDoc()} />
    )
  }

  getData = data => {
    let { client } = this.state
    const { towns } = this.props
    client.address = data.address
    client.latitude = data.latLng.lat
    client.longitude = data.latLng.lng

    if (data.town) {
      const town = towns.find(t => t.label === data.town)
      if (town) client.town = town
    }
    this.setState({ client })
  }

  onChangeText = (event, parammeter) => {
    const { value } = event.target
    let { client } = this.state
    client[parammeter] = value
    this.setState({ client })
  }

  onChange = (data, parammeter) => {
    let { client } = this.state
    client[parammeter] = data
    this.setState({ client })
  }

  render () {
    const {
      errors,
      comercialName,
      shortName,
      comercialAddress,
      email,
      client,
      description,
      phone,
      history,
      webSite,
    } = this.state
    const {
      company,
      coords,
      loading,
      towns,
      businessTypes,
      phonesCompanies,
      getPermission,
      currentCompany,
      companyId,
    } = this.props

    const disabled = false //!getPermission(3052);
    let lat = 14.55
    let lng = -90.55
    if (coords) {
      lat = coords.latitude || 0
      lng = coords.longitude || 0
    }
    return (
      <div>
        <Row>
          <Col md={4}>
            <Card
              status={company.active ? 'Activo' : 'Inactivo'}
              statusColor={company.active ? 'green' : 'red'}>
              <div className={'column'}>
                <Row style={{ justifyContent: 'center' }}>
                  <Gallery
                    company={company && company.id}
                    imageType={imageTypes.LOGO}
                    entityId={company && company.id}
                    showUploader={company.id === companyId}
                    limit={1}
                  />
                </Row>
                <Row style={{ justifyContent: 'center' }}>
                  <span className={'client-name'}>{company.name}</span>
                </Row>
                <Row className={'flex-container space-between'}>
                  <Col className={'label left'}>Codigo:</Col>
                  <Col className={'description'}>{company.alphanumericId}</Col>
                </Row>
                <Row className={'flex-container space-between'}>
                  <Col className={'label left'}>NIT:</Col>
                  <Col className={'description'}>{company.nit}</Col>
                </Row>
                <Row className={'flex-container space-between'}>
                  <Col className={'label left'}>Direccion:</Col>
                  <Col className={'description'}>{company.address}</Col>
                </Row>
                <Row className={'flex-container space-between'}>
                  <Col className={'label left'}>Email:</Col>
                  <Col className={'description'}>{company.email}</Col>
                </Row>

                {getPermission(2800) ||
                getPermission(1100) ||
                getPermission(1107) ||
                getPermission(2251) ? (
                  <Link to={`balance`} className={'big-button'}>
                    Balance
                  </Link>
                ) : (
                  ''
                )}

                {getPermission(2251) || getPermission(1107) ? (
                  <Link to={`configuraciones`} className={'big-button'}>
                    Configuraciones
                  </Link>
                ) : (
                  ''
                )}
              </div>
            </Card>
          </Col>

          <Col>
            <Card title={'Editar'}>
              <form>
                <Row style={{ justifyContent: 'center' }}>
                  <Col>
                    <FormTextField
                      label={'Nombre Comercial'}
                      type={'text'}
                      id={'name'}
                      placeholder={'Nombre'}
                      name={'comercialName'}
                      value={comercialName}
                      onChange={this.handleInputChange}
                      error={errors.name}
                    />
                  </Col>

                  <Col>
                    <FormTextField
                      label={'Nombre Corto'}
                      type={'text'}
                      id={'shortName'}
                      placeholder={'Nombre'}
                      name={'shortName'}
                      value={shortName}
                      onChange={this.handleInputChange}
                      error={errors.shortName}
                    />
                  </Col>

                  <Col>
                    <FormTextField
                      label={'Direccion comercial'}
                      type={'text'}
                      id={'address'}
                      placeholder={'Direccion'}
                      name={'comercialAddress'}
                      value={comercialAddress}
                      onChange={this.handleInputChange}
                      error={errors.address}
                    />
                  </Col>

                  <Col>
                    <FormTextField
                      label={'Email'}
                      type={'email'}
                      id={'email'}
                      placeholder={'Email'}
                      name={'email'}
                      value={email}
                      onChange={this.handleInputChange}
                      error={this.validateEmail(this.state.email)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormTextField
                      label={'Descripción'}
                      type={'text'}
                      id={'description'}
                      placeholder={'Descripción'}
                      name={'description'}
                      value={description}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormTextField
                      label={'Teléfono'}
                      type={'text'}
                      id={'phone'}
                      placeholder={'Teléfono'}
                      name={'phone'}
                      value={phone}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col md={6}>
                    <FormTextField
                      label={'Sitio Web'}
                      type={'text'}
                      id={'webSite'}
                      placeholder={'https://www.ejemplo.com'}
                      name={'webSite'}
                      value={webSite}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormTextArea
                      label={'Historia'}
                      value={history}
                      name={'history'}
                      id={'history'}
                      rows={'4'}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col>
                    <Button onClick={() => this.saveEdited()}>
                      {' '}
                      <FontAwesomeIcon icon={faSave} /> Guardar
                    </Button>
                    <SweetAlert
                      show={this.state.showSuccess}
                      title="Guardado"
                      text="Cambios guardados"
                      type={'success'}
                      onConfirm={() => {
                        this.setState({ showSuccess: false })
                      }}
                    />
                    <SweetAlert
                      show={this.state.showError}
                      title="Error"
                      text="Ocurrio un error al almacenar los datos"
                      type={'success'}
                      onConfirm={() => {
                        this.setState({ showError: false })
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <br />
                  <br />
                  <br />
                </Row>
              </form>
            </Card>

            <br />
            <Card title={'Documentacion'} button={this.button()}>
              <Col>
                {this.state.document_dpi != null && !this.state.canEditDocs ? (
                  <div>
                    <a
                      href={this.state.document_dpi}
                      target="_blank"
                      rel="noopener noreferrer">
                      DPI representante legal{' '}
                      <img style={iconCheck} alt="cargado" src={check} />
                    </a>
                  </div>
                ) : (
                  <FilePicker
                    maxSize={5}
                    extensions={['jpeg', 'jpg', 'png', 'pdf']}
                    onChange={this.uploadDpi}
                    onError={errMsg =>
                      this.setState({ showError: true, errorText: errMsg })
                    }>
                    <div style={{ paddingTop: 10 }}>
                      <Button>
                        <FontAwesomeIcon icon={faUpload} /> DPI representante legal
                      </Button>
                      {this.state.uploadIsLoading &&
                      this.state.docUpload == DocumentType.DPI ? (
                        <ProgressBar
                          variant="success"
                          style={{ marginTop: 5 }}
                          now={this.state.uploadProgress}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </FilePicker>
                )}
              </Col>
              <Col>
                {this.state.document_patente != null && !this.state.canEditDocs ? (
                  <div>
                    <a
                      href={this.state.document_patente}
                      target="_blank"
                      rel="noopener noreferrer">
                      Patente de Comercio{' '}
                      <img style={iconCheck} alt="cargado" src={check} />
                    </a>
                  </div>
                ) : (
                  <FilePicker
                    maxSize={5}
                    extensions={['jpeg', 'jpg', 'png', 'pdf']}
                    onChange={this.uploadPatente}
                    onError={errMsg =>
                      this.setState({ showError: true, errorText: errMsg })
                    }>
                    <div style={{ paddingTop: 10 }}>
                      <Button>
                        <FontAwesomeIcon icon={faUpload} /> Patente de comercio
                      </Button>
                      {this.state.uploadIsLoading &&
                      this.state.docUpload == DocumentType.PATENTE ? (
                        <ProgressBar
                          variant="success"
                          style={{ marginTop: 5 }}
                          now={this.state.uploadProgress}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </FilePicker>
                )}
              </Col>
              <Col>
                {this.state.document_rtu != null && !this.state.canEditDocs ? (
                  <div>
                    <a
                      href={this.state.document_rtu}
                      target="_blank"
                      rel="noopener noreferrer">
                      Constancia de RTU{' '}
                      <img alt="cargado" style={iconCheck} src={check} />
                    </a>
                  </div>
                ) : (
                  <FilePicker
                    maxSize={5}
                    extensions={['jpeg', 'jpg', 'png', 'pdf']}
                    onChange={this.uploadRTU}
                    onError={errMsg =>
                      this.setState({ showError: true, errorText: errMsg })
                    }>
                    <div style={{ paddingTop: 10 }}>
                      <Button>
                        <FontAwesomeIcon icon={faUpload} /> Constancia RTU
                      </Button>
                      {this.state.uploadIsLoading &&
                      this.state.docUpload == DocumentType.RTU ? (
                        <ProgressBar
                          variant="success"
                          style={{ marginTop: 5 }}
                          now={this.state.uploadProgress}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </FilePicker>
                )}
              </Col>
            </Card>
          </Col>
        </Row>
        <br />
        <h1 className={'dashboard-welcome'}>Punto de Venta</h1>
        <br />
        <Row>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <Geocoding
              lat={client.latitude || lat}
              lng={client.longitude || lng}
              auto={currentCompany.clientId ? false : true}
              defaultAddress={client.address}
              editable={!disabled}
              getData={this.getData}
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <Card title={'Información publica'}>
              <form>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={3}>
                    <br />
                    <span>NIT</span>
                  </Col>
                  <Col>
                    <TextField
                      placeholder={'pj. CF'}
                      type={'text'}
                      value={client.nit}
                      error={errors.nit}
                      disabled={loading || disabled}
                      onChange={data => this.onChangeText(data, 'nit', 'client')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={3}>
                    <br />
                    <span>Nombre</span>
                  </Col>
                  <Col>
                    <TextField
                      placeholder={'Nombre'}
                      type={'text'}
                      value={client.companyName}
                      error={errors.companyName}
                      disabled={loading || disabled}
                      onChange={data => this.onChangeText(data, 'companyName', 'client')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={3}>
                    <br />
                    <span>Dirección</span>
                  </Col>
                  <Col>
                    <TextField
                      placeholder={'Dirección'}
                      type={'text'}
                      value={client.address}
                      error={errors.address}
                      disabled={loading || disabled}
                      onChange={data => this.onChangeText(data, 'address', 'client')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={3}>
                    <br />
                    <span>Referencia</span>
                  </Col>
                  <Col>
                    <TextField
                      placeholder={'Referencia'}
                      type={'text'}
                      value={client.reference}
                      error={errors.reference}
                      disabled={loading || disabled}
                      onChange={data => this.onChangeText(data, 'reference', 'client')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={3}>
                    <br />
                    <span>Municipio</span>
                  </Col>
                  <Col>
                    <Select
                      value={client.town}
                      withoutLabel
                      placeholder={'Municipio'}
                      options={towns}
                      error={errors.town}
                      disabled={loading || disabled}
                      onChange={data => this.onChange(data, 'town', 'client')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={3}>
                    <br />
                    <span>Tipo de Negocio</span>
                  </Col>
                  <Col>
                    <Select
                      value={client.businessType}
                      withoutLabel
                      placeholder={'Tipo de negocio'}
                      options={businessTypes}
                      disabled={loading || disabled}
                      error={errors.businessType}
                      onChange={data => this.onChange(data, 'businessType', 'client')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={3}>
                    <br />
                    <span>Compañía Telefónica</span>
                  </Col>
                  <Col>
                    <Select
                      value={client.phoneCompany}
                      withoutLabel
                      placeholder={'Compañía Telefónica'}
                      options={phonesCompanies}
                      disabled={loading || disabled}
                      error={errors.phoneCompany}
                      onChange={data => this.onChange(data, 'phoneCompany', 'client')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={3}>
                    <br />
                    <span>Teléfono</span>
                  </Col>
                  <Col>
                    <TextField
                      placeholder={'Teléfono'}
                      type={'number'}
                      value={client.phone}
                      error={errors.phone}
                      disabled={loading || disabled}
                      onChange={data => this.onChangeText(data, 'phone', 'client')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={3}>
                    <br />
                    <span>Ubicación</span>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <TextField
                          placeholder={'Latitude'}
                          type={'number'}
                          value={client.latitude}
                          error={errors.latitude}
                          disabled={loading || disabled}
                          onChange={data => this.onChangeText(data, 'latitude', 'client')}
                        />
                      </Col>
                      <Col>
                        <TextField
                          placeholder={'Longitude'}
                          type={'number'}
                          value={client.longitude}
                          error={errors.longitude}
                          disabled={loading || disabled}
                          onChange={data =>
                            this.onChangeText(data, 'longitude', 'client')
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </form>
            </Card>
            {!disabled && (
              <Row className={'container-buttons'}>
                <Button loading={loading} onClick={() => this.validate()}>
                  <FontAwesomeIcon icon={faPlus} />
                  {currentCompany.clientId ? 'Actualizar' : 'Crear'}
                </Button>
              </Row>
            )}
          </Col>
        </Row>

        <SweetAlert {...this.state.alert} />
      </div>
    )
  }
}

CompanyProfile.defaultProps = {
  company: {},
  loading: false,
  hasError: false,
}

const mapStateToProps = state => ({
  company: selectGetSingleCompany(state),
  editableFields: selectGetCompanyFields(state),
  loading: loadingSelector([actionTypes.UPDATE_SINGLE_COMPANY])(state),
  hasError: hasErrorsSelector([actionTypes.UPDATE_SINGLE_COMPANY])(state),
  error: singleErrorSelector([actionTypes.UPDATE_SINGLE_COMPANY])(state),
  errors: errorsSelector([
    actionTypes.UPDATE_SINGLE_COMPANY,
    actionTypes.CURRENT_COMPANY_SUCCESS,
  ])(state),
  towns: selectAllLocalitiesForSelect(state),
  businessTypes: selectBusinessTypes(state),
  phonesCompanies: selectPhonesCompanies(state),
  getPermission: id => getPermission(state, id),
  global: selectGlobalClient(state),
  loadingC: loadingSelector([typesC.GLOBAL_CLIENT])(state),
  hasErrorC: hasErrorsSelector([typesC.GLOBAL_CLIENT])(state),
  errorC: singleErrorSelector([typesC.GLOBAL_CLIENT])(state),
  handlerError: message => handlerError(message),
  handlerSuccess: message => handlerSuccess(message),
  currentCompany: selectSetCurrentCompany(state),
  activeAuth: selectActiveAuth(state),
  activeAuthLoading: loadingSelector([t.ON_SET_AUTH])(state),
  activeHasError: hasErrorsSelector([t.ON_SET_AUTH])(state),
  activeError: singleErrorSelector([t.ON_SET_AUTH])(state),
  user: selectCurrentUser(state),
  currentRoles: selectCurrentUserRoles(state),
  modules: selectCurrentModule(state),
  companyId: selectCurrentCompany(state),
})

const mapDispatchToProps = dispatch => ({
  getSingleCompany: idCompany => dispatch(getSingleCompany(idCompany)),
  getEditableFields: () => dispatch(getEditableFields()),
  setEditableFields: (
    email,
    comercialName,
    comercialAddress,
    phone,
    description,
    history,
    webSite,
    shortName,
  ) =>
    dispatch(
      setEditableFields(
        email,
        comercialName,
        comercialAddress,
        phone,
        description,
        history,
        webSite,
        null,
        shortName,
      ),
    ),
  getDocuments: loadDocs => dispatch(getDocuments(loadDocs)),
  uploadDocument: (file, documentType, onProgress, onSuccess, onError) =>
    dispatch(uploadDocument(file, documentType, onProgress, onSuccess, onError)),
  getAllLocalities: () => dispatch(getAllLocalities()),
  getPhoneCompanies: () => dispatch(getPhoneCompanies()),
  getBusinessTypes: () => dispatch(getBusinessTypes()),
  onCreateGlobalClient: request => dispatch(onCreateGlobalClient(request)),
  getGlobalClient: id => dispatch(getGlobalClient(id)),
  onUpdateGlobalClient: (request, id) => dispatch(onUpdateGlobalClient(request, id)),
  onSetAuth: () => dispatch(onSetAuth()),
  getActiveAuth: () => dispatch(getActiveAuth()),
  getCurrentUser: () => dispatch(getCurrentUser()),
  getCurrentRoles: module => dispatch(getCurrentUserRolesAction(module)),
})

const ReduxComponent = connect(mapStateToProps, mapDispatchToProps)(CompanyProfile)
export default geolocated({
  positionOptions: { enableHighAccuracy: false },
  userDecisionTimeout: 500,
})(ReduxComponent)
