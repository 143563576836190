import React, { useState } from 'react'
import { Modal } from 'react-bootstrap' // Import Modal and Modal.Title
import { Row } from 'react-bootstrap' // Import other necessary components

import { Button } from 'src/components'
import { useDispatch, useSelector } from 'react-redux'
import { types, onCreateInventoryRequest } from 'src/actions/inventory.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import FormTextV2 from '../../inputs/FormTextField/FormTextV2'

interface InventoryModalDescriptionProps {
  show?: boolean
  onHide?: () => void
  data?: dataRequestForInventory
}

const InventoryModalDescription: React.FC<InventoryModalDescriptionProps> = ({
  show,
  onHide,
  data,
}: InventoryModalDescriptionProps) => {
  const dispatch = useDispatch()
  const [description, setDescription] = useState('')
  const loadingC = useSelector(state => loadingSelector([types.ON_CREATE_REQUEST])(state))

  const processInventoryRequest = () => {
    const { type, warehouseId, details, isAllInventory } = data
    if (data) {
      dispatch(
        onCreateInventoryRequest(
          {
            type: type,
            warehouseId: warehouseId,
            details: details,
            description: description,
          },
          isAllInventory,
        ),
      )
    }
  }

  return (
    <>
      <Modal size={'lg'} centered show={show} onHide={onHide} className={'pl-1 pr-1'}>
        <Modal.Header closeButton>
          <Modal.Title>Descripcion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div data-testid={'description-modal'}>
            <FormTextV2
              className={'mb-3'}
              value={description}
              label={'Descripción (opcional):'}
              type={'text'}
              placeholder={'Indica la razón del ajuste de inventario'}
              max={100}
              dataCy={'desc'}
              onChange={e => {
                setDescription(e.target.value)
              }}
              currencies={{
                currentCurrency: {
                  symbol: '',
                },
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Button dataCy={'button-cancel'} color={'secondary'} onClick={onHide}>
              Cancelar
            </Button>
            <Button
              dataCy={'button-continue'}
              onClick={processInventoryRequest}
              loading={loadingC}>
              Continuar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default InventoryModalDescription
