import React from 'react'
import { useDispatch } from 'react-redux'
import { setModalCreateCashTransfer } from 'src/actions/utilities.actions'

import { BalanceTable, Title } from 'src/components'

/**
 * @page
 * Balance entries page
 */
const BalanceEntries = () => {
  const dispatch = useDispatch()

  return (
    <div>
      <Title
        title={'Mi caja'}
        action
        dontHide
        actionTitle={'Crear transferencia'}
        onClick={() => {
          dispatch(setModalCreateCashTransfer({ show: true }))
        }}
      />
      <BalanceTable balance={{ available: 0, ret: 0 }} balanceType={3} byUser />
    </div>
  )
}

export default BalanceEntries
