import './CSVLoader.scss'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { saveAs } from 'file-saver'

import {
  faCloudDownloadAlt,
  faCloudUploadAlt,
  faDownload,
  faInfoCircle,
  faPlay,
} from '@fortawesome/free-solid-svg-icons'
import SweetAlert from 'sweetalert-react'
import Alert from 'sweetalert-react'

import { Col, Modal, ProgressBar, Row } from 'react-bootstrap'
import { Button, Icon, Icon as IconButton, Paragraph, TableV2 } from 'src/components'

import {
  actionTypes,
  massiveLoadClients,
  massiveLoadClientsSv,
} from 'src/actions/clients.actions'

import { handlerInfo, hasErrors } from 'src/selectors/error.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { actionTypes as typeW, massiveLoadWarehouse } from 'src/actions/warehouse.actions'
import {
  actionTypes as typesP,
  massiveLoadProducts,
  massiveUpdateProducts,
} from 'src/actions/products.actions'

import {
  actionTypes as typeIn,
  invoiceConciliation,
  invoiceConciliationNullify,
} from 'src/actions/cashboxes.actions'

import { formatDateFromMillis, formatHourFromMillis } from 'src/utils/formatters'
import {
  massiveLoadInventory,
  massiveLoadLocations,
  massiveLoadProductProduction,
  types as typesInventory,
} from 'src/actions/inventory.actions'
import {
  actionTypes as typePu,
  loadSimples,
  massiveLoadProduct,
} from 'src/actions/purchase.actions'

import {
  actionTypes as typeBank,
  onCreateTransactionCSVV2,
} from 'src/actions/banks.actions'

import { types as typeUtility, getCSVTemplate } from 'src/actions/utilities.actions'
import {
  getCSVTemplateForProductPrices,
  loadProductPricesByPOS,
  actionTypes as restaurantTypes,
} from 'src/actions/restaurant.actions'

/**
 * Componenete de carga masiva y vista previa de los datos.
 * @component
 * @param props Parametros
 * @param {Object} props.category  Categoria a la que pertenece la carga masiva @see CATEGORY_UPLOAD
 * @param {String} props.title   Titulo del boton
 * @param {Action} props.getCsv  Accion cuando se presiona el descargar plantilla.
 * @param {boolean} props.loading Animacion de loading
 * @param {string} props.info  Informacion que se desea agregar a la carga masiva
 * @param {Action} props.onSuccess Accion que se desea realizar cuando la carga termine exitosamente
 * @param {boolean} props.hideTemplate Oculta el boton para descargar la plantilla
 * @param {number} props.warehouseId Id de bodega, util cuando se desea hacer carga a una bodega.
 * @param {object} props.labelStyle Id de bodega, util cuando se desea hacer carga a una bodega.
 * @returns None
 */

const CSVLoaderV2 = ({
  category,
  title,
  getCsv,
  loading,
  info,
  onSuccess,
  hideTemplate,
  warehouseId,
  type,
  onFinishLoad,
  labelStyle = {},
}) => {
  const dispatch = useDispatch()

  const loadingDownloadTemplate = useSelector(state =>
    loadingSelector([
      typeUtility.GET_CSV_TEMPLATE,
      restaurantTypes.CSV_TEMPLATE_PRODUCT_PRICES_BY_POS,
    ])(state),
  )

  const [showMessageList, setShowMessageList] = useState(false)
  const [fullResponse, setFullResponse] = useState({ errors: [], errorsData: [] })
  const [isLoading, setIsLoading] = useState(false)
  const [showInfoToDownload, setShowInfoToDownload] = useState(false)
  const [information, setInfo] = useState({ title: '', content: '' })
  const [constIndex] = useState(30)

  const [data, setData] = useState({
    all: [],
    success: [],
    error: [],
  })
  const [dataSimple, setDataSimple] = useState([])
  const [succesCount, setSuccessCount] = useState(0)
  const [failedCount, setFailedCount] = useState(0)
  const [headers, setHeaders] = useState([])
  const [dataActive, setDataActive] = useState([])
  const [dataPreview, setDataPreview] = useState([])
  const [showPreview, setShowPreview] = useState(false)
  const [loadIndex, setLoadIndex] = useState(0)
  const loadingLoad = useSelector(state => loadingSelector([category.loading])(state))
  const errorLoad = useSelector(state => hasErrors([category.loading])(state))
  const [flags, setFlags] = useState({ confirm: false, get: false, refresh: false })
  const [alert, setAlert] = useState({ title: '' })
  const [initialTime, setInitialTime] = useState(0)
  const [finishTime, setFinishTime] = useState(0)
  const [dataProducts, setDataProducts] = useState([])

  useEffect(() => {
    if (loadingLoad) setFlags({ ...flags, confirm: true })
    else if (flags.confirm) {
      setFlags({ ...flags, confirm: false, refresh: true })
      if (errorLoad) {
        const error = data.error.concat({
          line: loadIndex + '-' + constIndex,
          column: '*',
          error: 'Fallo la carga de esta tupla',
          message: 'Fallo la carga de esta tupla',
        })
        setData({ ...data, error })
      } else {
        const success = data.success.concat(dataActive)
        setData({ ...data, success })
      }
      goToNextData()
    }
  }, [loadingLoad])

  const onSuccessLoadFile = response => {
    if (
      category === CATEGORY_UPLOAD.LOAD_PRODUCT_PURCHASE ||
      category === CATEGORY_UPLOAD.LOAD_PRODUCT_PRODUCTION
    )
      setDataProducts(response)
    showInfo(response)
  }

  const showInfo = response => {
    if (response.ok === false) {
      let index = response.errors[response.errors.length - 1]
      let limit = index.line.split('-')
      let failed = parseInt(limit[1]) - (parseInt(limit[0]) - 1)
      setSuccessCount(succesCount + response.items - 1)
      setFailedCount(failedCount + failed + 1)
      const errors = fullResponse.errors.concat(response.errors)
      let newErrors = dataSimple.filter(
        (x, i) => i >= parseInt(limit[0]) - 2 && i < parseInt(limit[1]),
      )
      const errorsData = fullResponse.errorsData.concat(newErrors)
      setFullResponse({ ...fullResponse, errors, errorsData })
    } else {
      setSuccessCount(succesCount + response.items - response.errors.length)
      setFailedCount(failedCount + response.errors.length)
      const errors = fullResponse.errors.concat(response.errors)
      let newErrors = []
      response.errors.map(error =>
        newErrors.push(dataSimple.find((x, i) => i === parseInt(error.line) - 1)),
      )

      const errorsData = fullResponse.errorsData.concat(newErrors)
      setFullResponse({ ...fullResponse, errors, errorsData })
    }
  }

  const showInfoDownload = () => {
    switch (category) {
      case CATEGORY_UPLOAD.PRODUCTS:
        setInfo({
          title: 'Productos',
          content:
            'Los nombres de las columnas deben ser escritos en minúscula y sin tildes.\n' +
            'Las columnas para las cuales no hay información se dejan en blanco\n' +
            "Los valores desde la columna 'Transformación automática' en adelante pueden ser Si o No",
        })
        break
      case CATEGORY_UPLOAD.UPDATE_PRODUCTS:
        setInfo({
          title: 'Productos',
          content:
            'El SKU es el código del producto\n' +
            "Los valores desde la columna 'Transformación automática' en adelante pueden ser Si o No",
        })
        break
      case CATEGORY_UPLOAD.CLIENTS:
        setInfo({
          title: 'Clientes',
          content:
            'Los nombres de las columnas deben ser escritos en minúscula y sin tildes.\n' +
            'Las columnas para las cuales no hay información se dejan en blanco',
        })
        break
      case CATEGORY_UPLOAD.PURCHASE:
        setInfo({
          title: 'Compras/Gastos',
          content:
            'Los campos obligatorios son SKU, cantidad y subtotal, los demás campos pueden quedar vacíos',
        })
        break
      case CATEGORY_UPLOAD.PURCHASE_SIMPLE:
        setInfo({
          title: 'Compras/Gastos',
          content:
            'Los campos obligatorios son NIT, Nombre y Total, el campo CPP significa Cuenta Pendiente de Pago y se llena con un SI o se deja vacio, los demas campos pueden quedar vacíos.',
        })
        break
      case CATEGORY_UPLOAD.LOAD_PRODUCT_PURCHASE:
        setInfo({
          title: 'Compras/Gastos',
          content:
            'Los campos obligatorios son SKU, cantidad y subtotal \n' +
            'Compras y Gastos: Los demas campos deben estar vacios',
        })
        break
      case CATEGORY_UPLOAD.LOAD_PRODUCT_PRICES_BY_POS:
        setInfo({
          title: 'Precios de productos',
          content:
            'Los campos obligatorios son SKU y precio \n' +
            'El precio debe ser mayor a 0 para se ingresado',
        })
        break
      default:
        setInfo({
          title: title,
          content: information || 'No hay información',
        })
        break
    }
    setShowInfoToDownload(true)
  }

  const goToNextData = () => {
    let currentIndex = loadIndex + constIndex
    let newArray = data.all.slice(currentIndex, currentIndex + constIndex)
    if (newArray.length > 0) {
      setLoadIndex(currentIndex)
      setDataPreview(newArray)
      setDataActive(newArray)
      initLoad(currentIndex)
    } else {
      const finished = new Date()
      setFinishTime(finished.valueOf())
      if (
        category === CATEGORY_UPLOAD.LOAD_PRODUCT_PURCHASE ||
        category === CATEGORY_UPLOAD.LOAD_PRODUCT_PRODUCTION
      ) {
        setShowPreview(false)
        if (fullResponse.errors.length > 0) {
          setShowMessageList(true)
        } else {
          onHide()
        }
      } else
        setAlert({
          ...handlerInfo(
            'Proceso concluido',
            `El proceso ha concluido, se realizaron ${succesCount} cargas exitosas y se encontraron fallos en ${failedCount} datos`,
          ),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            setShowPreview(false)
            if (fullResponse.errors.length > 0) {
              setShowMessageList(true)
            } else {
              onHide()
            }
          },
        })
    }
  }

  /**
   * Metodo que inicia la carga de los datos activos
   * @param {Number} index Indice actual del arreglo donde se encuentra la carga.
   */
  const initLoad = index => {
    const values =
      category === CATEGORY_UPLOAD.LOAD_PRODUCT_PURCHASE ||
      category === CATEGORY_UPLOAD.LOAD_PRODUCT_PRODUCTION
        ? data.all
        : data.all.slice(index, index + constIndex)

    const banksCSV = [
      CATEGORY_UPLOAD.BI_CSV.type,
      CATEGORY_UPLOAD.BI_CSV_BANKING.type,
      CATEGORY_UPLOAD.RURAL_CSV.type,
      CATEGORY_UPLOAD.BAM_CSV.type,
      CATEGORY_UPLOAD.GYT_CSV.type,
    ]

    if (
      banksCSV.findIndex(b => b === category.type) >= 0 ||
      category === CATEGORY_UPLOAD.LOCATIONS ||
      category === CATEGORY_UPLOAD.INVENTORY ||
      category === CATEGORY_UPLOAD.PRODUCT_PRICES_POS
    )
      dispatch(category.load(warehouseId, values, index, onSuccessLoadFile))
    else if (category === CATEGORY_UPLOAD.SIMPLE_PURCHASE)
      dispatch(category.load(values, type, index, onSuccessLoadFile))
    else if (category === CATEGORY_UPLOAD.LOAD_PRODUCT_PURCHASE) {
      setLoadIndex(1000000000)
      dispatch(category.load(values, type, onSuccessLoadFile))
    } else {
      if (category === CATEGORY_UPLOAD.LOAD_PRODUCT_PRODUCTION) setLoadIndex(1000000000)
      dispatch(
        category.load(
          values,
          category === CATEGORY_UPLOAD.LOAD_PRODUCT_PRODUCTION ? warehouseId : index,
          onSuccessLoadFile,
        ),
      )
    }
  }

  const onUpload = tar => {
    setIsLoading(true)

    let file = tar.files[0]

    let formData = new FormData()
    formData.append('file', file)

    let reader = new FileReader()
    reader.onload = e => {
      let items = parseCSV(e.target.result)

      if (items.length > 1) {
        const array = items.slice(1, items.length)
        setData({ ...data, all: array })
        setDataPreview(array)
        if (items[0].length === category.object.length) {
          if (category?.returnItems) {
            onSuccess(items)
          } else {
            setShowPreview(true)
            setDataActive(items.slice(1, loadIndex + constIndex))
          }
        } else {
          setAlert({
            ...handlerInfo(
              'CSV erróneo',
              `Los encabezados del csv ingresado no coinciden con los esperados`,
            ),
            onConfirm: () => {
              setAlert({ ...alert, show: false })

              onHide()
            },
          })
        }
      } else {
        setAlert({
          ...handlerInfo('CSV erróneo', `El archivo cargado no cuenta con datos`),
          onConfirm: () => {
            setAlert({ ...alert, show: false })

            onHide()
          },
        })
      }
    }
    reader.readAsBinaryString(file)

    //onUpload(file, onSuccessLoadFile, false, selected)
  }

  const onHide = () => {
    setShowMessageList(false)
    setData({
      all: [],
      success: [],
      error: [],
    })
    setFullResponse({
      errors: [],
      errorsData: [],
    })
    setSuccessCount(0)
    setFailedCount(0)
    setIsLoading(false)
    setLoadIndex(0)
    if (onFinishLoad) onFinishLoad(dataProducts)
    if (onSuccess) onSuccess()
    setInitialTime(0)
    setFinishTime(0)
  }

  const getHeaders = () => {
    const header = []
    try {
      headers.forEach((h, i) => {
        header.push({
          show: true,
          label: h,
          value: [category.object[i].label],
          type: 'text',
        })
      })
    } catch (er) {
      return []
    }
    return header
  }

  const detectCSVSeparator = csv => {
    const separators = [',', ';']
    let separatorUsed = ','
    let maxCount = 0

    for (const separator of separators) {
      const count = csv.split(separator).length - 1
      if (count > maxCount) {
        maxCount = count
        separatorUsed = separator
      }
    }

    return separatorUsed
  }

  const parseCSV = text => {
    let lines = text.replace(/[\rï»¿#]/g, '').split('\n')

    if (lines.length === 0) return lines

    let separator = ','
    if (lines[0].includes('sep=,')) lines.shift()
    else separator = detectCSVSeparator(lines[0])

    setDataSimple(lines)
    const headers = category.object

    return lines
      .filter(i => i !== '')
      .map((line, index) => {
        const values = line.split(separator)
        if (index === 0) {
          setHeaders(values)
          return values
        }
        return headers.reduce(function (object, header, index) {
          object[header.label] = (values[index] || '').trim()
          return object
        }, {})
      })
  }
  const getTime = () => {
    let prom
    if (category === CATEGORY_UPLOAD.PRODUCTS) {
      prom = 4500
    } else if (category === CATEGORY_UPLOAD.CLIENTS) {
      prom = 9000
    } else {
      prom = 1000
    }
    return formatHourFromMillis(prom * (data.all.length / constIndex), true)
  }

  const twoParts = !hideTemplate || info

  return (
    <div style={{ alignItems: 'center', marginBottom: 15 }}>
      {info && (
        <div className={'csv-text-m mb-2'} style={{ textTransform: 'uppercase' }}>
          INGRESE LA INFORMACIÓN DE FORMA MANUAL O
        </div>
      )}
      <div className={'d-flex justify-content-center'}>
        {info && (
          <div className={'mt-2 mr-2 csv-text-w'} style={{ textTransform: 'uppercase' }}>
            INGRESE LA INFORMACIÓN DE FORMA MANUAL O
          </div>
        )}
        <Row>
          <Col xl={twoParts ? 9 : 12} xs={twoParts ? 10 : 12} md={twoParts ? 9 : 12}>
            <div className={'picker_file_csv_container'}>
              <input
                style={{ visibility: 'hidden' }}
                // disabled={loading !== undefined ? loading : isLoading}
                type="file"
                accept=".csv"
                name={category.type}
                id={category.type}
                value={null}
                onChange={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (e.target.files.length !== 0) {
                    onUpload(e.target)
                  }
                  e.target.value = null
                }}
              />
              <label
                htmlFor={category.type}
                style={{ position: 'absolute', ...labelStyle }}>
                <div
                  className={`picker_file_csv ${
                    (loading !== undefined ? loading : isLoading) ? 'disabled' : ''
                  }`}>
                  <IconButton
                    icon={faCloudUploadAlt}
                    tooltip={title}
                    spin={loading !== undefined ? loading : isLoading}
                    style={{ marginRight: 5 }}
                    color={'white'}
                  />
                  <div>{title}</div>
                </div>
              </label>
            </div>
          </Col>

          {twoParts && (
            <Col xl={3} xs={2} md={3}>
              <Row>
                {!hideTemplate && (
                  <>
                    {!getCsv ? (
                      <IconButton
                        spin={loadingDownloadTemplate}
                        tooltip={'Descargar Plantilla'}
                        icon={faCloudDownloadAlt}
                        size={'2x'}
                        onClick={() => {
                          if (!category.typeId) return
                          if (
                            category.typeId === CATEGORY_UPLOAD.PRODUCT_PRICES_POS.typeId
                          )
                            dispatch(getCSVTemplateForProductPrices(warehouseId))
                          else dispatch(getCSVTemplate(category.typeId, category.name))
                        }}
                      />
                    ) : (
                      <IconButton
                        tooltip={'Descargar Plantilla efg'}
                        icon={faCloudDownloadAlt}
                        size={'2x'}
                        onClick={() => getCsv()}
                      />
                    )}
                  </>
                )}

                {info && (
                  <div style={{ alignSelf: 'center' }}>
                    <IconButton
                      tooltip={'Información'}
                      icon={faInfoCircle}
                      size={'2x'}
                      onClick={() => showInfoDownload(category)}
                    />
                  </div>
                )}
              </Row>
            </Col>
          )}
        </Row>
      </div>

      <SweetAlert
        show={showInfoToDownload}
        type={'info'}
        title={information.title}
        text={information.content}
        onConfirm={() => {
          setShowInfoToDownload(false)
          setInfo({ title: '', content: '' })
        }}
      />

      <Modal
        show={showPreview}
        size={'xl'}
        centered
        onHide={() => {
          setShowPreview(false)
          onHide()
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Vista previa datos a cargar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col xl={12} className={'mb-2'}>
            <Paragraph>
              Acontinuacion se muestra una vista previa de los datos del csv cargado
            </Paragraph>
          </Col>
          <Col xl={12}>
            <ProgressBar
              now={(data.success.length * 100) / (data.all.length - 1)}
              label={`${data.success.length} / ${data.all.length - 1}`}
              animated={loadingLoad}
              variant={'success'}
            />
          </Col>

          <Col xl={12} style={{ marginTop: '10px' }}>
            <div className={'d-flex'}>
              <Button
                disabled={initialTime !== 0}
                color={'primary'}
                onClick={() => {
                  initLoad(0)
                  const initial = new Date()
                  setSuccessCount(0)
                  setInitialTime(initial.valueOf())
                  setFinishTime(0)
                }}>
                <Icon
                  spin={initialTime !== 0 && finishTime === 0}
                  icon={faPlay}
                  tooltip={'Iniciar proceso'}
                />
              </Button>
              <div className={'column mt-1 ml-1'}>
                <div className={'b-user-name no-transform'}>
                  Tiempo estimado: {getTime()}
                </div>
                <div className={'b-user-name no-transform'}>
                  Tiempo transcurrido:{' '}
                  {formatHourFromMillis(
                    initialTime === 0
                      ? 0
                      : (finishTime > 0 ? finishTime : new Date().valueOf()) -
                          initialTime,
                    true,
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col xl={12}>
            <div className={'space-between'}>
              <div className={'b-user-name no-transform'}>
                Inicio:{' '}
                {initialTime === 0 ? '-' : formatDateFromMillis(initialTime, true)}
              </div>
              <div className={'b-user-name no-transform'}>
                Fin: {finishTime === 0 ? '-' : formatDateFromMillis(finishTime, true)}
              </div>
            </div>
          </Col>
          <Col xl={12}>
            <TableV2
              headers={headers.length > 0 && getHeaders()}
              items={dataPreview.length > 0 && dataPreview}
              noItemsLegend={'No se encontraron datos'}
              mobileAuto
              editable
              setItems={datos => {
                if (datos) {
                  setDataPreview([...datos])
                  setData({ ...data, all: datos })
                } else {
                  setDataPreview([...dataPreview])
                  setData({ ...data, all: dataPreview })
                }
              }}
            />
          </Col>
        </Modal.Body>
      </Modal>

      <Modal
        show={showMessageList}
        size="lg"
        centered
        onHide={() => {
          onHide()
        }}>
        {' '}
        <Modal.Header closeButton>
          <Modal.Title>Detalle de carga</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={9}>
              <p> Datos insertados correctamente: {succesCount} </p>
              <p>
                Durante la carga se encontraron los siguientes errores o detalles,
                haciendo un total de {failedCount}
              </p>
            </Col>
            <Col>
              <div>
                <Button
                  color={'accent'}
                  onClick={() => {
                    const fileName = 'datos_fallidos.csv'
                    let text =
                      dataSimple[0] +
                      '\n' +
                      fullResponse.errorsData
                        .map(line => line)
                        .join('\n')
                        .replace(/[ï»¿#]/g, '')
                    let csvContent = 'data:text/csv;charset=utf-8,'
                    csvContent += text
                    saveAs(csvContent, fileName.replace('json', 'csv'))
                  }}>
                  <Icon icon={faDownload} tooltip={'Descargar CSV'} /> Descargar
                </Button>
              </div>
            </Col>
          </Row>
          <TableV2
            headers={[
              {
                label: 'Línea',
                show: true,
                value: ['line'],
                type: 'text',
                className: 'mini',
              },
              {
                label: 'Columna',
                show: true,
                value: ['column'],
                type: 'text',
                className: 'mini',
              },
              {
                label: 'Error',
                show: true,
                value: ['error'],
                type: 'text',
                className: 'mini',
              },
              {
                label: 'Mensaje',
                show: true,
                value: ['message'],
                type: 'text',
                className: 'medium',
              },
              { config: true, show: true, label: '', className: 'mini center' },
            ]}
            items={fullResponse.errors}
            mobileAuto
            storageKey={`csvLoader`}
          />
        </Modal.Body>
      </Modal>

      <Alert {...alert} />
    </div>
  )
}

export default CSVLoaderV2

export const CATEGORY_UPLOAD = {
  CLIENTS: {
    typeId: 1,
    type: 'CLIENTS',
    name: 'create-clients.csv',
    url: 'https://firebasestorage.googleapis.com/v0/b/cotizap-prod.appspot.com/o/csv%2Fkolo-clientes-gt.csv?alt=media&token=a95b65f9-6b0a-4b9c-a900-91823eb5b2a5',
    load: (data, currentIndex, onSuccessLoadFile) =>
      massiveLoadClients(data, currentIndex, onSuccessLoadFile),
    loading: actionTypes.UPLOAD_CSV,
    object: [
      { label: 'codigo' },
      { label: 'nombre' },
      { label: 'nit' },
      { label: 'cui' },
      { label: 'id extranjero' },
      { label: 'email' },
      { label: 'municipio' },
      { label: 'direccion' },
      { label: 'referencia' },
      { label: 'latitud' },
      { label: 'longitud' },
      { label: 'contacto' },
      { label: 'telefono' },
      { label: 'categorias' },
      { label: 'cuenta por cobrar' },
      { label: 'referencia cuenta por cobrar' },
    ],
  },
  CLIENTS_UNI: {
    typeId: 14,
    type: 'CLIENTS',
    name: 'create-clients.csv',
    load: (data, currentIndex, onSuccessLoadFile) =>
      massiveLoadClientsSv(data, currentIndex, onSuccessLoadFile),
    loading: actionTypes.UPLOAD_CSV,
    object: [
      { label: 'codigo' },
      { label: 'nombre' },
      { label: 'Identificación tributaria' },
      { label: 'id extranjero' },
      { label: 'email' },
      { label: 'direccion' },
      { label: 'referencia' },
      { label: 'latitud' },
      { label: 'longitud' },
      { label: 'contacto' },
      { label: 'telefono' },
      { label: 'categorias' },
      { label: 'cuenta por cobrar' },
      { label: 'referencia cuenta por cobrar' },
    ],
  },
  CLIENTS_HN: {
    typeId: 13,
    type: 'CLIENTS',
    name: 'create-clients.csv',
    load: (data, currentIndex, onSuccessLoadFile) =>
      massiveLoadClientsSv(data, currentIndex, onSuccessLoadFile),
    loading: actionTypes.UPLOAD_CSV,
    object: [
      { label: 'codigo' },
      { label: 'nombre' },
      { label: 'rtn' },
      { label: 'id extranjero' },
      { label: 'email' },
      { label: 'direccion' },
      { label: 'referencia' },
      { label: 'latitud' },
      { label: 'longitud' },
      { label: 'contacto' },
      { label: 'telefono' },
      { label: 'categorias' },
      { label: 'cuenta por cobrar' },
      { label: 'referencia cuenta por cobrar' },
    ],
  },
  CLIENTS_SV: {
    typeId: 2,
    type: 'CLIENTS',
    name: 'create-clients.csv',
    url: 'https://firebasestorage.googleapis.com/v0/b/cotizap-prod.appspot.com/o/csv%2Fkolo-clientes.csv?alt=media&token=526fad67-0a50-4c6a-8832-c08e3a6c382c',
    load: (data, currentIndex, onSuccessLoadFile) =>
      massiveLoadClientsSv(data, currentIndex, onSuccessLoadFile),
    loading: actionTypes.UPLOAD_CSV,
    object: [
      { label: 'codigo' },
      { label: 'nombre' },
      { label: 'nit' },
      { label: 'nrc' },
      { label: 'dui' },
      { label: 'id extranjero' },
      { label: 'email' },
      { label: 'municipio' },
      { label: 'direccion' },
      { label: 'referencia' },
      { label: 'latitud' },
      { label: 'longitud' },
      { label: 'contacto' },
      { label: 'telefono' },
      { label: 'categorias' },
      { label: 'actividad economica' },
      { label: 'cuenta por cobrar' },
      { label: 'referencia cuenta por cobrar' },
    ],
  },
  PRODUCTS: {
    typeId: 3,
    type: 'PRODUCTS',
    name: 'create-items.csv',
    url: 'https://firebasestorage.googleapis.com/v0/b/cotizap-prod.appspot.com/o/csv%2Fkolo-items-1.1.csv?alt=media&token=fcccb531-6533-4424-b222-9e9bc21814c6',
    load: (data, currentIndex, onSuccessLoadFile) =>
      massiveLoadProducts(data, currentIndex, onSuccessLoadFile),
    loading: typesP.UPLOAD_CSV,
    object: [
      { label: 'SKU' },
      { label: 'codigos barras' },
      { label: 'nombre' },
      { label: 'descripcion' },
      { label: 'producto base' },
      { label: 'factor conversion' },
      { label: 'precio' },
      { label: 'transformacion automatica' },
      { label: 'ventas sin inventario' },
      { label: 'activar para enlace kolo' },
      { label: 'compras' },
      { label: 'gastos' },
      { label: 'ventas' },
      { label: 'reduccion de inventarios' },
      { label: 'ingreso de inventarios' },
      { label: 'categorias' },
      { label: 'bodega' },
      { label: 'cantidad' },
      { label: 'costo promedio' },
      { label: 'BIEN O SERVICIO' },
      { label: 'Variaciones' },
      { label: 'costo unitario' },
      { label: 'unidad de medida' },
      { label: 'equivalencia de medida' },
      { label: 'numero de decimales' },
      { label: 'comision en porcentaje' },
      { label: 'comision' },
      { label: 'Venta por monto' },
      { label: 'Combo' },
      { label: 'Descuento de sub recetas' },
      { label: 'Variación Abierta' },
      { label: 'lotes' },
      { label: 'Impuesto' },
    ],
  },
  UPDATE_PRODUCTS: {
    typeId: 4,
    type: 'UPDATE_PRODUCTS',
    name: 'update-items.csv',
    url: 'https://firebasestorage.googleapis.com/v0/b/cotizap-prod.appspot.com/o/csv%2Fkolo-actualizacion-items-1.1.csv?alt=media&token=f10b30a1-62af-4f0e-b67b-4f9daaf880ca',
    load: (data, currentIndex, onSuccessLoadFile) =>
      massiveUpdateProducts(data, currentIndex, onSuccessLoadFile),
    loading: typesP.UPLOAD_UPDATE_CSV,
    object: [
      { label: 'sku' },
      { label: 'nombre' },
      { label: 'precio' },
      { label: 'activo' },
      { label: 'transformacion automatica' },
      { label: 'ventas sin inventario' },
      { label: 'activar para enlace kolo' },
      { label: 'compras' },
      { label: 'actualizar precio en venta' },
      { label: 'gastos' },
      { label: 'ventas' },
      { label: 'Reduccion de inventario' },
      { label: 'Ingreso de inventario' },
      { label: 'usar factor' },
      { label: 'categorias' },
      { label: 'nuevo SKU' },
      { label: 'Codigo de barra' },
      { label: 'Bien o Servicio' },
      { label: 'variaciones' },
      { label: 'proveedor' },
      { label: 'id proveedor' },
      { label: 'costo unitario' },
      { label: 'unidad de medida' },
      { label: 'equivalencia de medida' },
      { label: 'numero de decimales' },
      { label: 'comision en porcentaje' },
      { label: 'comision' },
      { label: 'Venta por monto' },
      { label: 'Combo' },
      { label: 'Descuento de sub recetas' },
      { label: 'Variación Abierta' },
      { label: 'lotes' },
      { label: 'Impuesto' },
    ],
  },
  TRANSPORTATION: {
    typeId: 5,
    type: 'TRANSPORTATION',
    name: 'up_recipe.csv',
    url: 'https://firebasestorage.googleapis.com/v0/b/cotizap-prod.appspot.com/o/csv%2Fkolo-file_transport_products.csv?alt=media&token=6fa3151e-5c74-4c50-a12c-a93ce240d9a1',
  },
  WAREHOUSES: {
    typeId: 6,
    type: 'WAREHOUSES',
    name: 'up_warehouses.csv',
    url: 'https://firebasestorage.googleapis.com/v0/b/cotizap-prod.appspot.com/o/csv%2Fkolo-bodegas.csv?alt=media&token=647c60dd-18eb-43dd-80a9-07e60bacfdfb',
    load: (data, currentIndex, onSuccessLoadFile) =>
      massiveLoadWarehouse(data, currentIndex, onSuccessLoadFile),
    loading: typeW.LOAD_CSV,
    object: [
      { label: 'nombre' },
      { label: 'direccion' },
      { label: 'latitude' },
      { label: 'longitude' },
      { label: 'tipo' },
      { label: '(opcional codigo) encargado' },
    ],
  },
  INVOICE_CONCILIATION: {
    type: 'INVOICE_CONCILIATION',
    name: 'invoice_conciliation.csv',
    url: undefined,
    load: (data, currentIndex, onSuccessLoadFile) =>
      invoiceConciliation(data, currentIndex, onSuccessLoadFile),
    loading: typeIn.INVOICE_CONCILIATION,
    object: [
      { label: 'Fecha de emision' },
      { label: 'Fecha de autorizacion' },
      { label: 'Numero de autorizacion' },
      { label: 'Tipo de DTE (nombre)' },
      { label: 'Serie' },
      { label: 'Numero de DTE' },
      { label: 'NIT del emisor' },
      { label: 'Nombre completo del emisor' },
      { label: 'Codigo de establecimiento' },
      { label: 'Nombre del establecimiento' },
      { label: 'ID del receptor' },
      { label: 'Nombre completo del receptor' },
      { label: 'NIT del Certificador' },
      { label: 'Nombre completo del Certificador' },
      { label: 'Moneda' },
      { label: 'Monto (Gran Total)' },
      { label: 'Estado' },
      { label: 'Marca de anulado' },
      { label: 'Fecha de anulacion' },
      { label: 'IVA (monto de este impuesto)' },
    ],
  },
  INVOICE_CONCILIATION_NULLIFY: {
    type: 'INVOICE_CONCILIATION_NULLIFY',
    name: 'invoice_conciliation_nullify.csv',
    url: undefined,
    load: (data, currentIndex, onSuccessLoadFile) =>
      invoiceConciliationNullify(data, currentIndex, onSuccessLoadFile),
    loading: typeIn.INVOICE_CONCILIATION,
    object: [
      { label: 'Numero de autorizacion' },
      { label: 'Fecha de autorizacion' },
      { label: 'ID del receptor' },
      { label: 'Nombre completo del receptor' },
      { label: 'Total' },
    ],
  },
  LOCATIONS: {
    typeId: 7,
    type: 'LOCATIONS',
    name: 'update_locations.csv',
    url: 'https://firebasestorage.googleapis.com/v0/b/cotizap-prod.appspot.com/o/csv%2Fkolo-ubicaciones-inventario.csv?alt=media&token=94c44371-f31b-470f-9cb3-fed2939893f8',
    load: (warehouseId, data, currentIndex, onSuccessLoadFile) =>
      massiveLoadLocations(warehouseId, data, currentIndex, onSuccessLoadFile),
    loading: typesInventory.LOAD_LOCATIONS_CSV,
    object: [
      { label: 'codigo' },
      { label: 'nombre' },
      { label: 'variacion' },
      { label: 'ubicacion' },
      { label: 'cantidad' },
    ],
  },
  LOAD_PRODUCT_PURCHASE: {
    typeId: 8,
    type: 'PRODUCT_PURCHASE',
    name: 'purchase-file.csv',
    url: 'https://firebasestorage.googleapis.com/v0/b/cotizap-prod.appspot.com/o/csv%2Fkolo-compras.csv?alt=media&token=c9514872-eb6b-43c5-93a2-b5d3e1b1da35',
    load: (data, type, onSuccessLoadFile) =>
      massiveLoadProduct(data, type, onSuccessLoadFile),
    loading: typePu.LOAD_PRODUCTS_CSV,
    object: [
      { label: 'sku' },
      { label: 'variaciones' },
      { label: 'cantidad' },
      { label: 'sub total' },
      { label: 'fecha de vencimiento' },
      { label: 'numeros de serie' },
      { label: 'tasa de impuesto ' },
      { label: 'total Q' },
    ],
  },
  LOAD_PRODUCT_PRODUCTION: {
    typeId: 9,
    type: 'PRODUCT_PRODUCTION',
    name: 'production-file.csv',
    url: 'https://firebasestorage.googleapis.com/v0/b/cotizap-prod.appspot.com/o/csv%2Fkolo-production-file.csv?alt=media&token=f038c66f-6577-47e5-b168-d0fdbbd840e9',
    load: (data, warehouseId, onSuccessLoadFile) =>
      massiveLoadProductProduction(data, warehouseId, onSuccessLoadFile),
    loading: typesInventory.GET_PRODUCTS_PRODUCTION,
    object: [
      { label: 'sku' },
      { label: 'variaciones' },
      { label: 'cantidad' },
      { label: 'costo unitario' },
    ],
  },
  LOAD_PAYMENTS_CPC: {
    typeId: 12,
    type: 'LOAD_PAYMENTS_CPC',
    name: 'payments-cpc-file.csv',
    returnItems: true,
    object: [
      { label: 'codigo' },
      { label: 'nombre' },
      { label: 'saldo' },
      { label: 'numeroDePago' },
      { label: 'tipoPago' },
      { label: 'cuenta' },
      { label: 'totalAbonar' },
      { label: 'numeroVenta' },
      { label: 'totalVenta' },
      { label: 'saldoPendiente' },
      { label: 'fechaVenta' },
      { label: 'montoPagar' },
      { label: 'fechaDePago' },
      { label: 'pagoInmediato' },
      { label: 'tipoAbono' },
    ],
  },
  SIMPLE_PURCHASE: {
    typeId: 10,
    type: 'SIMPLE PURCHASE',
    name: 'up_purchase_simple.csv',
    url: 'https://firebasestorage.googleapis.com/v0/b/cotizap-prod.appspot.com/o/csv%2Fkolo_gastos_abreviados.csv?alt=media&token=d95d6f8c-80d2-406b-838c-ff67d93bf87b',
    load: (data, type, index, onSuccessLoadFile) =>
      loadSimples(data, type, index, onSuccessLoadFile),
    loading: typePu.CREATE_CSV,
    object: [
      { label: 'Fecha de emision' },
      { label: 'NIT del proveedor' },
      { label: 'Nombre del proveedor' },
      { label: 'Numero de serie' },
      { label: 'Numero de factura' },
      { label: 'Total' },
      { label: 'Categorias' },
      { label: 'Descripcion' },
    ],
  },
  BI_CSV: {
    type: 'BI_CSV',
    loading: typeBank.SET_MOVEMENTS_CSV_V2,
    load: (accountId, data, index, onSuccessLoadFile) =>
      onCreateTransactionCSVV2(accountId, index, data, onSuccessLoadFile),
    object: [
      { label: 'Fecha' },
      { label: 'TT' },
      { label: 'Descripción' },
      { label: 'No. Doc' },
      { label: 'Debe (Q.)' },
      { label: 'Haber (Q.)' },
      { label: 'Saldo (Q.)' },
      { label: 'Año' },
    ],
  },
  BI_CSV_BANKING: {
    type: 'BI_CSV_BANKING',
    loading: typeBank.SET_MOVEMENTS_CSV_V2,
    load: (accountId, data, index, onSuccessLoadFile) =>
      onCreateTransactionCSVV2(accountId, index, data, onSuccessLoadFile, true),
    object: [
      { label: 'Fecha' },
      { label: 'TT' },
      { label: 'Descripción' },
      { label: 'No. Doc' },
      { label: 'Debe (Q.)' },
      { label: 'Haber (Q.)' },
      { label: 'Saldo (Q.)' },
    ],
  },
  RURAL_CSV: {
    type: 'RURAL_CSV',
    loading: typeBank.SET_MOVEMENTS_CSV_V2,
    load: (accountId, data, index, onSuccessLoadFile) =>
      onCreateTransactionCSVV2(accountId, index, data, onSuccessLoadFile),
    // "Fecha","Oficina","Descripción","Referencia","Secuencial","Cheque Propio / Local / Efectivo","Débito (-)","Crédito (+)","Saldo Contable","Saldo Disponible"
    object: [
      { label: 'Fecha' },
      { label: 'Oficina' },
      { label: 'Descripción' },
      { label: 'Referencia' },
      { label: 'Secuencial' },
      { label: 'Cheque Propio / Local / Efectivo' },
      { label: 'Débito (-)' },
      { label: 'Crédito (+)' },
      { label: 'Saldo Contable' },
      { label: 'Saldo Disponible' },
    ],
  },
  BAM_CSV: {
    type: 'BAM_CSV',
    loading: typeBank.SET_MOVEMENTS_CSV_V2,
    load: (accountId, data, index, onSuccessLoadFile) =>
      onCreateTransactionCSVV2(accountId, index, data, onSuccessLoadFile),
    // "Fecha","Hora","Documento","Descripción","Débito","Crédito","Saldo","Agencia","Referencia"
    object: [
      { label: 'Fecha' },
      { label: 'Hora' },
      { label: 'Documento' },
      { label: 'Descripción' },
      { label: 'Débito' },
      { label: 'Crédito' },
      { label: 'Saldo' },
      { label: 'Agencia' },
      { label: 'Referencia' },
    ],
  },
  GYT_CSV: {
    type: 'GYT_CSV',
    loading: typeBank.SET_MOVEMENTS_CSV_V2,
    load: (accountId, data, index, onSuccessLoadFile) =>
      onCreateTransactionCSVV2(accountId, index, data, onSuccessLoadFile),
    object: [
      { label: '#' },
      { label: 'Fecha' },
      { label: 'Referencia' },
      { label: 'Descripción' },
      { label: 'Débito' },
      { label: 'Crédito' },
      { label: 'Saldo' },
      { label: 'Agencia' },
    ],
  },
  INVENTORY: {
    type: 'INVENTORY',
    loading: typesInventory.LOAD_CSV,
    load: (warehouseId, data, index, onSuccessLoadFile) =>
      massiveLoadInventory(warehouseId, data, index, onSuccessLoadFile),
    object: [
      { label: 'sku' },
      { label: 'nombre' },
      { label: 'variacion' },
      { label: 'cantidad' },
      { label: 'costo unitario' },
    ],
  },
  PRODUCT_PRICES_POS: {
    typeId: 11,
    type: 'PRODUCT_PRICES_POS',
    loading: restaurantTypes.LOAD_PRODUCT_PRICES_BY_POS,
    load: (posId, data, index, onSuccessLoadFile) =>
      loadProductPricesByPOS(posId, data, index, onSuccessLoadFile),
    object: [{ label: 'SKU' }, { label: 'nombre' }, { label: 'precio' }],
  },
}
