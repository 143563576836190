import React, { Component } from 'react'
import { connect } from 'react-redux'
import ImageGallery from 'react-image-gallery'
import { FilePicker } from 'react-file-picker'
import ManIcon from 'src/assets/images/man.png'
import { ProgressBar } from 'react-bootstrap'
import Button from '../buttons/Button'
import {
  actionTypes,
  deleteFile,
  getFiles,
  uploadFile,
} from 'src/actions/uploads.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import SweetAlert from 'sweetalert-react'
import { selectFilesForGallery2 } from 'src/selectors/uploads.selector'
import 'react-image-gallery/styles/css/image-gallery.css'
import './Gallery.scss'

class Gallery extends Component {
  state = {
    uploadProgress: 0,
    showDelete: false,
    showError: false,
    errorText: '',
  }

  componentDidMount() {
    const { imageType, entityId, company } = this.props
    if (imageType && entityId && company) {
      this.props.getImages(imageType, entityId, company)
    }
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { imageType, entityId, company } = this.props
    if (entityId !== next.entityId || company !== next.company) {
      if (imageType && next.entityId && next.company) {
        this.props.getImages(imageType, next.entityId, next.company)
      }
    }
  }

  deleteAction = () => {
    const { images, onDelete, imageType, entityId, company } = this.props
    const index = this.gallery.getCurrentIndex()
    const image = images[index]
    const onSuccess = () => {
      this.setState({ deleted: image })
      this.gallery.slideToIndex(index - 1 < 0 ? 0 : index - 1)
      if (onDelete) {
        onDelete(image, index)
      }
    }
    this.props.deleteFile(image.id, image.name, imageType, entityId, company, onSuccess)
  }

  uploadImage = file => {
    const { imageType, entityId, company, onUploadSuccess } = this.props

    const onProgress = progress => this.setState({ uploadProgress: progress })
    const onError = () => {
      this.setState({
        progress: 0,
        showError: true,
        errorText: 'Lo sentimos, no fué posible subir tu imagen en este momento',
      })
    }

    const onSuccess = (url, name) => {
      if (onUploadSuccess) {
        onUploadSuccess(url, name)
      }
      this.setState({ uploadProgress: 0 })
    }

    this.props.uploadFile(
      file,
      imageType,
      entityId,
      company,
      onProgress,
      onSuccess,
      onError,
    )
  }

  render() {
    const { uploadProgress, errorText, showError, showDelete } = this.state
    const {
      imageType,
      images,
      showThumbnails,
      showUploader,
      uploadIsLoading,
      imagesIsLoading,
      maxWidth,
      limit,
      noItemLegend,
      style,
      title,
    } = this.props
    return imageType ? (
      <div style={style}>
        <div>
          {images && images.length ? (
            <div
              style={{
                width: 20,
                backgroundColor: 'rgba(237,70,45,0.8)',
                borderRadius: 5,
              }}
            >
              {showUploader && (
                <h6
                  className={'gallery-delete'}
                  onClick={() => this.setState({ showDelete: true })}
                >
                  X
                </h6>
              )}
            </div>
          ) : (
            <div />
          )}
        </div>
        <div>
          <div className={'gallery-container'}>
            {images && images.length > 0 ? (
              <div
                style={{
                  maxWidth: maxWidth,
                  maxHeight: maxWidth,
                  overflow: maxWidth ? 'scroll' : '',
                }}
              >
                <ImageGallery
                  ref={ref => (this.gallery = ref)}
                  items={images}
                  showPlayButton={false}
                  showFullscreenButton={false}
                  showThumbnails={showThumbnails && images.length > 1}
                  disableArrowKeys
                />
              </div>
            ) : imagesIsLoading || uploadIsLoading ? (
              <h4 style={{ textAlign: 'center', marginTop: 50 }}>Cargando...</h4>
            ) : imageType.id === 1 ? (
              <img src={ManIcon} alt={'client profile'} className={'client-picture'} />
            ) : (
              <h4 style={{ textAlign: 'center', marginTop: 50 }}>
                {noItemLegend || 'Sin imágenes para mostrar'}
              </h4>
            )}
            {showUploader && (limit ? limit > images.length : true) && (
              <FilePicker
                maxSize={5}
                extensions={['jpeg', 'jpg', 'png', 'svg']}
                onChange={this.uploadImage}
                onError={errMsg => this.setState({ showError: true, errorText: errMsg })}
              >
                <div style={{ paddingTop: 10 }}>
                  <Button
                    loading={uploadIsLoading}
                    className={'big-button no-bottom-margin'}
                    onClick={this.uploadImage}
                  >
                    {title || 'Agregar imagen'}
                  </Button>
                  {uploadIsLoading && (
                    <ProgressBar
                      style={{ marginTop: 5 }}
                      variant={'success'}
                      now={uploadProgress}
                      label={`${uploadProgress.toFixed(2)}%`}
                    />
                  )}
                </div>
              </FilePicker>
            )}

            <SweetAlert
              show={showError}
              title={'¡Uh-Oh!'}
              text={errorText}
              type={'error'}
              onConfirm={() => this.setState({ showError: false })}
            />

            <SweetAlert
              show={showDelete}
              title={'Advertencia'}
              showCancelButton
              text={'¿Desea eliminar esta imagen?'}
              type={'warning'}
              onConfirm={() => {
                this.deleteAction()
                this.setState({ showDelete: false })
              }}
              onCancel={() => this.setState({ showDelete: false })}
            />
          </div>
        </div>
      </div>
    ) : (
      <div />
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  images: ownProps.images
    ? ownProps.images
    : ownProps.entityId
    ? selectFilesForGallery2(state)
    : [],
  uploadIsLoading: loadingSelector([actionTypes.UPLOAD])(state),
  imagesIsLoading: loadingSelector([actionTypes.GET_FILES])(state),
})

const mapDispatchToProps = dispatch => ({
  getImages: (imageType, entityId, company) =>
    dispatch(getFiles(imageType, entityId, company, 2)),
  uploadFile: (file, imageType, entityId, company, onProgress, onSuccess, onError) =>
    dispatch(
      uploadFile({
        file,
        imageType,
        entityId,
        company,
        onProgress,
        onSuccess,
        onError,
        gallery: 2,
      }),
    ),
  deleteFile: (id, name, imageType, entityId, company, onSuccess) =>
    dispatch(
      deleteFile({
        id,
        name,
        imageType,
        entityId,
        company,
        onSuccess,
        gallery: 2,
      }),
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(Gallery)

Gallery.defaultProps = {
  showThumbnails: true,
  showUploader: true,
  maxWidth: 'none',
}

export const imageTypes = {
  COMPANY: { id: 1, path: '/companies' },
  PRODUCT: { id: 2, path: '/products' },
  WAREHOUSE: { id: 3, path: '/warehouses' },
  CLIENT: { id: 4, path: '/clients' },
  DEPOSITS: { id: 5, path: '/deposits' },
  INVOICES: { id: 5, path: '/invoices' },
  OFFER: { id: 6, path: '/offer' },
  LOGO: { id: 7, path: '/logo' },
  CATEGORY: { id: 8, path: '/categorization' },
  CATEGORY_BANNER: { id: 9, path: '/banner' },
  CATEGORY_ICON: { id: 10, path: '/icon' },
}
