import './Manuals.scss'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ProgressBar } from 'react-bootstrap'

import { AppCard, CustomFilter, Title } from 'src/components'
import { actionTypes, getManuals } from 'src/actions/videos.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import Paragraph from 'src/components/Paragraph/Paragraph'

const Manuals = () => {
  const dispatch = useDispatch()

  const manuals = useSelector(state => state.videos.manuals)
  const loading = useSelector(state => loadingSelector([actionTypes.GET_MANUALS])(state))

  const [search, setSearch] = useState(null)

  useEffect(() => {
    if (!manuals.length) dispatch(getManuals())
  }, [])

  const filterGroups = item => {
    if (!search || search === '') return true
    const obj = {
      name: item.name,
      apps: item.apps.map(app => ({
        name: app.name,
      })),
    }
    const exp = new RegExp(search.toUpperCase(), 'g')
    return Object.keys(obj).some(p => JSON.stringify(obj[p]).toUpperCase().match(exp))
  }

  const filterApps = item => {
    if (!search || search === '') return item

    const exp = new RegExp(search.toUpperCase(), 'g')
    if (item.name.toUpperCase().match(exp)) return item

    const itemsCopy = { ...item }
    itemsCopy.apps = itemsCopy.apps.filter(app => app.name.toUpperCase().match(exp))
    return itemsCopy
  }

  const filteredManuals = manuals
    .filter(filterGroups)
    .map(filterApps)
    .sort((a, b) => a.id - b.id)

  return (
    <div>
      <Title title={'Manuales'} />
      <CustomFilter
        customFilter={<div />}
        search={search}
        onChange={({ target }) => {
          const { value } = target
          setSearch(value)
        }}
        placeholder={'# categoría / app'}
      />
      {loading && <ProgressBar now={100} animated />}
      {filteredManuals.map(({ id, name, apps }) => (
        <div key={id} className="manuals">
          <h4 className="manuals-title">{name}</h4>
          <div className="manuals-appcards">
            {apps.map(app => (
              <AppCard {...app} />
            ))}
          </div>
        </div>
      ))}
      {!loading && manuals.length === 0 && <Paragraph>No hay manuales</Paragraph>}
    </div>
  )
}

export default Manuals
