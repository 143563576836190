import './AccessCard.scss'
import React from 'react'

import IconButton from '../../buttons/IconButton'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const nonFavoritable = ['Ver perfil']

const notLinkable = ['balance', 'profile', 'log']

const ConditionalLink = ({ children, to, isActive, ...props }) => {
  return isActive ? (
    <Link to={to} {...props}>
      {children}
    </Link>
  ) : (
    <div {...props}>{children}</div>
  )
}

const AccessCard = ({
  onClick,
  icon,
  name,
  link = '',
  favorite,
  method,
  loading,
  change,
  id,
}) => {
  return (
    <div className={'access-card'}>
      <div className="access-card-buttons">
        {!nonFavoritable.includes(name) && (
          <IconButton
            icon={faStar}
            color={favorite === true ? '#FFE65D' : '#DBDEE6'}
            size={'md'}
            onClick={method}
            spin={loading && change === id}
          />
        )}
      </div>
      <ConditionalLink
        to={link}
        isActive={!notLinkable.includes(link)}
        style={{ height: '100%', width: '100%' }}>
        <div
          onClick={() => {
            if (onClick) onClick()
          }}>
          <img src={icon} alt="access-img" style={{ width: '100%' }} />
          <div className={'access-name'}>{name}</div>
        </div>
      </ConditionalLink>
    </div>
  )
}
export default AccessCard
