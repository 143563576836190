import React from 'react'
import DashboardCard from 'src/components/cards/dashboard-card/DashboardCard'

import { connect } from 'react-redux'
import {
  getPermission,
  selectModuleId,
  selectSubModules,
} from 'src/selectors/modules.selector'
import LoadingIcon from 'src/assets/images/svg/loading.svg'

class DashboardKolo extends React.Component {
  koloFilter = item => {
    const exp = new RegExp(/kolo/, 'gmi')
    if (`${item.name}`.match(exp) && item.id !== 2600 && item.id !== 1650) return true
    return false
  }

  render() {
    const { module, subModules } = this.props

    return (
      <div>
        <h1 className={'dashboard-welcome'}>
          KOLO {module !== null ? module.title : ''}
        </h1>
        <div className={'modules-list'}>
          {subModules &&
            subModules
              .filter(this.koloFilter)
              .map(s =>
                s.showIcon ? (
                  <DashboardCard
                    key={s.id}
                    icon={s.icon || LoadingIcon}
                    name={s.name}
                    link={s.route}
                    notification={s.notifications}
                  />
                ) : (
                  ''
                ),
              )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  module: selectModuleId(state),
  subModules: selectSubModules(state),
  getPermission: id => getPermission(state, id),
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardKolo)
