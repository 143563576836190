import React, { Component } from 'react'
import { connect } from 'react-redux'

import Alert from 'sweetalert-react'
import { Row, Col, Modal } from 'react-bootstrap'

import ClientContacts from 'src/content/Clients/Contacts/ClientContacts'

import {
  actionTypes as typesC,
  createClient,
  onCreateClientStep,
} from 'src/actions/clients.actions'
import {
  selectCreate,
  selectDeliverersToSelect,
  selectSellers,
  selectSingleClient,
} from 'src/selectors/clients.selector'

import { selectAllLocalitiesForSelect } from 'src/selectors/address.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  hasErrorsSelector,
  singleErrorSelector,
  handlerSuccess,
  handlerError,
} from 'src/selectors/error.selector'

import CustomSelect from 'src/components/inputs/Select/CustomSelect'
import FormTextField from 'src/components/inputs/FormTextField/FormText'
import Card from 'src/components/cards/Card'
import Button from 'src/components/buttons/Button'
import ButtonIcon from 'src/components/buttons/IconButton'
import { faArrowLeft, faSave } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { selectUsers, selectUsersToSelect } from 'src/selectors/modules.selector'
import { getUsersChildrenByModule } from 'src/actions/modules.actions'
import { selectCurrentModule } from 'src/selectors/user.selector'
import { FormText } from 'src/components'
import { createBrowserHistory } from 'history'
import CustomDatePicker from 'src/components/CustomDatePicker/CustomDatePicker'
import { identificationTypesEnum } from 'src/enums/identificationTypes'
import { Country } from 'src/enums/countries'
import { selectCompanyCountry } from 'src/selectors/company.selector'
import { ClientAdditionalInfo } from 'src/components/modal/CreatClientExtra/ClientAdditionalInfo'
import { fetchDynamicFieldsByEntity } from 'src/actions/dynamicfield.actions'
import { dynamicEntityType } from 'src/enums/dynamicFieldsEnum'
import { selectEntityFields } from 'src/selectors/dynamicFields.selector'

export const history = createBrowserHistory()

/*const weekDay = [
    {label:'Domingo', value:1},
    {label:'Lunes', value:2},
    {label:'Martes', value:3},
    {label:'Miércoles', value:4},
    {label:'Jueves', value:5},
    {label:'Viernes', value:6},
    {label:'Sábado', value:7}
];

const vehicleTypeOptions = [
    {label:'Vehículo Primario', value:1},
    {label:'Camión grande', value:2},
    {label:'Panel grande', value:3},
    {label:'Panel pequeña', value:4}
];*/

class SpecificClient extends Component {
  state = {
    errors: {},
    alert: { title: 'text' },
    areas: [],
    clients: [],
    multi: false,
    modalC: false,
    email: null,
    emails: [],
    valid: true,
  }

  constructor(props) {
    super(props)
    this.state = {
      inputStates: [],
      isValid: true,
    }
    this.handleInputChangeDymFields = this.handleInputChangeDymFields.bind(this)
  }

  componentDidMount() {
    const { client, module } = this.props
    this.props.getUsers(module, true)
    if (client.town && client.town != '' && client.town.areas) {
      this.setState({
        areas: client.town.areas.map(a => {
          return { value: a.id, label: a.name }
        }),
      })
    } else {
      this.setState({ valid: false })
    }

    this.props.getDynamicFields(dynamicEntityType.CLIENTS)

    if (this.props.entityFields.length === 0) {
      this.setState({ isValid: false })
    }
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { loading, hasError, handlerError, handlerSuccess, onlyCloseModal, setClient } =
      this.props
    const { error, response } = next
    let alert = { title: 'text' }

    if (loading && !next.loading) {
      if (!hasError && next.hasError) {
        alert = handlerError(
          error.response
            ? error.response.data.message
            : 'Ha ocurrido un error inesperado',
        )
        alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
      } else {
        alert = handlerSuccess('Cliente creado satisfactoriamente')
        alert.onConfirm = () => {
          this.setState({ alert: { ...alert, show: false } })
          setClient({})

          if (onlyCloseModal) {
            onlyCloseModal(response)
          } else history.goBack()
        }
      }
      this.setState({ alert })
    }
  }

  onDateFromChange = date => {
    let { client, setClient } = this.props
    if (date) {
      setClient({ ...client, date: date })
    }
  }

  handleInputChange = event => {
    const { name, value } = event.target
    let { client, setClient } = this.props
    client[name] = value
    setClient(client)
  }

  handleCheckBoxChange = event => {
    const { name, checked } = event.target
    let { client, setClient } = this.props
    let { emails } = this.state

    this.setState({ [name]: checked })
    if (!checked)
      setClient({ ...client, email: client.email ? client.email.value : null })
    else if (client.email) {
      let array = [{ label: client.email, value: client.email }, ...emails]
      let newArray = array.map(item => {
        return [item.value, item]
      })
      var MapArr = new Map(newArray) // Pares de clave y valor
      let values = [...MapArr.values()]
      this.setState({ emails: [...values] })
      setClient({
        ...client,
        email: { label: client.email, value: client.email },
      })
    }
  }

  handleCurrencyInputChange = (event, maskValue, value) => {
    const { name } = event.target
    let { client, setClient } = this.props
    client[name] = value
    setClient(client)
  }

  validate = () => {
    let { client, createClient, country } = this.props
    let errors = {}
    if (!client.nit) errors.nit = 'Este campo es requerido'
    if (!client.companyName) errors.companyName = 'Este campo es requerido'
    if (!client.address) errors.address = 'Este campo es requerido'
    if (client.cui && client.cui.length < 13) errors.cui = 'El Cui debe tener 13 dígitos'

    if (!client.economicActivity && country.id === Country.SV)
      errors.economicActivity = 'Este campo es requerido'

    if (country.id === Country.SV) {
      if (!client?.region?.value) errors.region = 'Este campo es requerido'
      if (!client?.locality?.value) errors.locality = 'Este campo es requerido'

      const clientDui = client.identifications?.[identificationTypesEnum.DUI]
      if (clientDui) {
        let dui = clientDui.value
        if (dui.length < 8 && dui !== '')
          errors.clientIdentifications = 'El DUI debe tener 8 dígitos'
      }
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors })
      return
    }

    let { identifications, viewers, selectedCategorization, ...clientData } = client
    const images = client.src ? [client.src] : []

    let identificationsValues = []
    if (identifications) identificationsValues = Object.values(identifications)
    const filteredStatesInputs = this.state.inputStates.filter(
      item => item !== null && item !== undefined,
    )

    const request = {
      ...clientData,
      businessType: 1,
      phoneCompany: 1,
      town: 1,
      extraFieldValues: filteredStatesInputs,
      contactType: 1,
      vendor: 0,
      deliverer: 0,
      callDay: 0,
      visitDay: 0,
      localityId: clientData.locality?.id,
      exportationCountry: !clientData?.exportationCountry?.value
        ? null
        : clientData.exportationCountry?.code,
      date: clientData.date ? clientData.date.valueOf() : undefined,
      identifications: identificationsValues,
      viewers: viewers?.map(viewer => viewer.value),
    }

    createClient(request, images, selectedCategorization)
  }

  onSelectUser = selectedUser => {
    const { client, setClient } = this.props
    const { viewers = [] } = client

    viewers.push(selectedUser)
    setClient({ ...client, viewers })
  }

  deleteUser = id => {
    const { client, setClient } = this.props

    const viewers = client.viewers.filter(p => id !== p.value)
    setClient({ client, viewers })
  }

  handleInputChangeDymFields(index, idField, value) {
    this.setState(
      prevState => {
        const updatedStates = [...prevState.inputStates]
        if (updatedStates[index]) {
          updatedStates[index] = {
            ...updatedStates[index],
            fieldId: idField,
            value: value,
          }
        } else {
          updatedStates[index] = { fieldId: idField, value: value }
        }
        return { inputStates: updatedStates }
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
    )
  }

  validateFields = (fields, arrayVals) => {
    return fields.every(field => {
      if (field.dynamicField.required) {
        // Check if the required field is present and filled in arrayVals
        return arrayVals.some(
          val =>
            val &&
            val.fieldId === field.dynamicField.id &&
            val.value !== null &&
            val.value !== '',
        )
      }
      return true
    })
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the state has changed
    if (prevState.inputStates !== this.state.inputStates) {
      if (this.state.isValid === true) {
        const isValid = this.validateFields(
          this.props.entityFields,
          this.state.inputStates,
        )
        this.setState({ isValid: !isValid })
      }
    }
  }
  render() {
    const { client, setClient, loading, users } = this.props
    const { errors, alert, modalC, email, emails, valid } = this.state

    return (
      <div>
        <Card>
          <Row>
            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
              <CustomDatePicker
                label={'Fecha de nacimiento'}
                value={client.date}
                onDayChange={this.onDateFromChange}
              />
            </Col>
            {/*
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <FormTextField
                              label={"Dirección Fiscal"} type={"text"} id={"secondAddress"} placeholder={"Dirección"}
                              name={"secondAddress"} value={client.secondAddress}
                              onChange={this.handleInputChange} error={errors.secondAddress}
                            />
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                            <CustomSelect
                                disabled={areas.length === 0}
                                value={client.area}
                                options={areas}
                                label={"Zona/Sector"}
                                placeholder={areas.length === 0 ? "No hay zonas registradas" : "Seleccionar "}
                                name={"area"}
                                onChange={(data) => setClient({...client, area: data})}
                            />
                        </Col>*/}
            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
              <FormTextField
                disabled={loading}
                label={'Referencia para entregas y delivery'}
                type={'text'}
                name={'reference'}
                placeholder={'Referencia para entregas y delivery'}
                value={client.reference}
                onChange={this.handleInputChange}
                error={errors?.reference}
              />
            </Col>
            {/*
                        <Col xl={4} lg={4} md={4} sm={6} xs={6}>

                            <FormTextField
                                disabled={loading}
                                label={"Zona"}
                                type={"text"}
                                id={"areaName"}
                                placeholder={"Zona"}
                                name={"areaName"}
                                value={client.areaName}
                                onChange={this.handleInputChange}
                                error={errors.areaName}
                            />
                        </Col>

                        <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                            <FormTextField
                                disabled={loading}
                                label={"Calle o Avenida"}
                                type={"text"}
                                id={"street"}
                                placeholder={"Calle o Avenida"}
                                name={"street"}
                                value={client.street}
                                onChange={this.handleInputChange}
                                error={errors.street}
                            />
                        </Col>
                        */}

            {/*}
            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
              <FormTextField
                disabled={loading}
                label={'Contacto'}
                type={'text'}
                id={'contactName'}
                placeholder={'Nombre de contacto'}
                name={'contactName'}
                value={client.contactName}
                onChange={this.handleInputChange}
              />
            </Col> */}
            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
              <FormTextField
                disabled={loading}
                label={'Límite de Crédito'}
                type={'number'}
                id={'creditLimit'}
                placeholder={'Límite de credito'}
                name={'creditLimit'}
                value={client.creditLimit}
                onChange={({ target }) =>
                  setClient({
                    ...client,
                    creditLimit: !target.value || target.value < 0 ? 0 : target.value,
                  })
                }
              />
            </Col>

            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
              <FormTextField
                disabled={loading}
                label={'Código de proveedor'}
                type={'text'}
                id={'codeProvider'}
                placeholder={'Añadir código de proveedor'}
                name={'codeProvider'}
                value={client.codeProvider}
                onChange={({ target }) =>
                  setClient({
                    ...client,
                    codeProvider: target.value,
                  })
                }
                sub={
                  'El valor indicado sera visible en ordenes de cotización, el código de proveedor corresponde al código dado al cliente como empresa proveedora'
                }
              />
            </Col>

            {/*
                            <Col xl={2} lg={2} md={4} sm={6} xs={6}>
                                <FormTextField
                                    label={"Código Postal"}
                                    type={"text"}
                                    id={"postalCode"}
                                    placeholder={"Código Postal"}
                                    name={"postalCode"}
                                    value={client.postalCode}
                                    onChange={this.handleInputChange}
                                />
                            </Col>
                        */}
            {/*
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                             this.state.multi ?
                                <CustomSelect
                                    value={client.email}
                                    options={emails}
                                    label={"Correo electronico principal"}
                                    required
                                    placeholder={"Seleccionar "}
                                    name={"email"}
                                    menuPortalTarget={document.querySelector('body')}
                                    onChange={(data) => {
                                        setClient({...client, email: data})
                                    }}
                                    error={errors.email}
                                />
                                :
                                <FormTextField
                                    label={"Correo Electrónico"}
                                    type={"text"}
                                    id={"email"}
                                    placeholder={"Correo Electrónico"}
                                    name={"email"}
                                    value={client.email}
                                    onChange={this.handleInputChange}
                                />
                            }
                            {/*<label className={'checkbox-label'} style={{float: "left"}}>
                                <input type="checkbox" name="multi" checked={this.state.multi}
                                       onChange={this.handleCheckBoxChange}/> Multiples correos</label>*/}
            {/*
                                this.state.multi &&
                                <Row className={'mt-2'} style={{width: '100%'}}>
                                    {emails && emails.map((email, index) =>
                                            <div key={index} className={'c-item assigned ml-2'}>
                                                <label className={'label-user-tag'}> {email.value}</label>
                                                <IconButton
                                                    className={'c-item-button assigned'} icon={faTimes} iconStyle={{marginTop: 10}}
                                                    tooltip={'Eliminar'} color={'white'} onClick={() => {
                                                    let {client, setClient} = this.props;
                                                    this.setState({emails: emails.filter(p => email.value !== p.value)})
                                                    if(email === client.email) setClient({...client, email: null})
                                                }}
                                                />
                                            </div>
                                        )}
                                    <div className={'c-item ml-2'}>
                                        <br/>
                                        <IconButton className={'c-item-button assigned'} style={{borderRadius: 5}} icon={faPlus} iconStyle={{marginTop: 10}}
                                                    tooltip={'Agregar nueva opción'} color={'white'} onClick={() => this.setState({modalC: true})}/>
                                        Agregar correo electronico
                                    </div>
                                </Row>

                        </Col>
                        */}

            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <CustomSelect
                label={'Usuarios visualizadores'}
                options={users.filter(s => {
                  return (
                    !client.viewers || client.viewers?.every(f => f.value !== s.value)
                  )
                })}
                error={errors?.attendant}
                onChange={this.onSelectUser}
              />
              {client.viewers?.length > 0 && (
                <Row className={'pl-1'} style={{ width: '100%' }}>
                  <br />
                  {client.viewers.map(p => (
                    <div key={p.value} className={'user-tag  ml-2 mt-2'}>
                      <label className={'label-user-tag'}>{p.label}</label>
                      <ButtonIcon
                        className={'delete-user-tag'}
                        icon={faTimes}
                        tooltip={'Quitar'}
                        color={'white'}
                        onClick={() => this.deleteUser(p.value)}
                      />
                    </div>
                  ))}
                </Row>
              )}
            </Col>

            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <ClientContacts
                options={client.contacts}
                onChange={list => {
                  let { client, setClient } = this.props
                  client.contacts = list
                  setClient(client)
                }}
                show
              />
            </Col>
          </Row>
        </Card>
        <Card>
          {this.props.entityFields.length > 0 && (
            <ClientAdditionalInfo
              onChange={this.handleInputChangeDymFields}
              mode={'create'}
            />
          )}
        </Card>
        <hr />
        <Row className={'container-buttons'}>
          <Button
            disabled={loading}
            onClick={() => this.props.jumpToStep(0)}
            icon={faArrowLeft}>
            Atras
          </Button>
          <Button
            loading={loading}
            disabled={this.state.isValid}
            onClick={() => this.validate()}
            icon={faSave}>
            Guardar
          </Button>
        </Row>

        <Modal
          show={modalC}
          centered
          size={'md'}
          onHide={() => this.setState({ modalC: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Nuevo correo electronico</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormText
              value={email}
              type={'text'}
              label={'Correo electronico'}
              required
              onChange={({ target }) => {
                let { value } = target
                this.setState({ email: value })
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Row className={'container-buttons'}>
              <Button
                disabled={!email}
                icon={faSave}
                color={'primary'}
                onClick={() => {
                  let item = { label: email, value: email }
                  if (emails.length === 0)
                    this.props.setClient({ ...this.props.client, email: item })
                  this.setState({
                    emails: [...emails, item],
                    modalC: false,
                    email: null,
                  })
                }}>
                Guardar
              </Button>
            </Row>
          </Modal.Footer>
        </Modal>

        <Alert {...alert} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  client: selectCreate(state),
  allClients: selectUsersToSelect(state),
  country: selectCompanyCountry(state),
  users: selectUsers(state),
  module: selectCurrentModule(state),
  localities: selectAllLocalitiesForSelect(state),
  entityFields: selectEntityFields(state),
  deliverers: selectDeliverersToSelect(state),
  sellers: selectSellers(state),
  loading: loadingSelector([typesC.CREATE_SINGLE_CLIENTS])(state),
  hasError: hasErrorsSelector([typesC.CREATE_SINGLE_CLIENTS])(state),
  error: singleErrorSelector([typesC.CREATE_SINGLE_CLIENTS])(state),
  handlerError: message => handlerError(message),
  handlerSuccess: message => handlerSuccess(message),
  response: selectSingleClient(state),
})
const mapDispatchToProps = dispatch => ({
  setClient: client => dispatch(onCreateClientStep(client)),
  getDynamicFields: client => dispatch(fetchDynamicFieldsByEntity(client)),
  createClient: (client, images, categorization) =>
    dispatch(createClient(client, images, categorization)),
  getUsers: (module, includeParent) =>
    dispatch(getUsersChildrenByModule(module, includeParent)),
})
export default connect(mapStateToProps, mapDispatchToProps)(SpecificClient)
