import './ModalCreateCard.scss'
import React, { useEffect, useState } from 'react'

import { Modal, Row, Col } from 'react-bootstrap'
import { Button, Input, FormText } from 'src/components'

import { validCreditCard } from 'src/utils/validators'
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import cardTypes from 'src/enums/cardTypes'
import { useDispatch, useSelector } from 'react-redux'
import { loadingSelector } from 'src/selectors/loading.selector'

import { actions as accountActions, createCard } from 'src/actions/account.actions'
import {
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'
import { selectModalCreateCard } from 'src/selectors/utilities.selector'
import { setModalCreateCard } from 'src/actions/utilities.actions'
import { showAlert } from 'src/actions/alert.actions'
import { selectCreditCards } from 'src/selectors/account.selector'

interface ICreateCreditCardErrors {
  cardNumber?: string
  cardName?: string
  expirationMM?: string
  expirationAA?: string
  alias?: string
  ccv?: string
  postalCode?: string
}

/**
 * Componente para renderizar un modal para crear una tarjeta de crédito
 * @component
 */
const ModalCreateCard = () => {
  const dispatch = useDispatch()

  const cards: ICreditCard[] = useSelector(selectCreditCards)

  const now = new Date()
  const nowYear = now.getFullYear()
  const nowMonth = now.getMonth() + 1

  const [cardType, setCardType] = useState('none')
  const [card, setCard] = useState<ICreateCreditCardRequest>({})
  const [valid, setValid] = useState<ICreateCreditCardErrors>({})
  const [errors, setErrors] = useState<ICreateCreditCardErrors>({})

  const [action, setAction] = useState({
    cardCreate: false,
  })

  let { cardNumber } = card
  const { cardName, ccv, expirationMM, expirationAA, alias, postalCode } = card

  const modalCreateCard = useSelector(selectModalCreateCard)

  const loadingCreateCard = useSelector(state =>
    loadingSelector([accountActions.CREATE_CARD])(state),
  )
  const hasErrorCreateCard = useSelector(state =>
    hasErrorsSelector([accountActions.CREATE_CARD])(state),
  )
  const errorCreateCard = useSelector(state =>
    singleErrorSelector([accountActions.CREATE_CARD])(state),
  )

  const validateInputsCreditCard = () => {
    const errors: ICreateCreditCardErrors = {}

    if (!card.cardNumber) errors.cardNumber = 'El campo es requerído'
    if (!validCreditCard(card.cardNumber))
      errors.cardNumber = 'El número de tarjeta no es válido.'
    if (!card.cardName) errors.cardName = 'El campo es requerído'
    if (card.cardName && card.cardName.length < 3)
      errors.cardName = 'El nombre es muy corto'
    if (!card.expirationMM) errors.expirationMM = 'El mes es requerído'
    if (!card.expirationAA) errors.expirationAA = 'El año es requerído'
    if (!card.alias) errors.alias = 'El campo es requerído'
    if (cards.some(item => item.alias === card.alias)) errors.alias = 'El alias ya existe'

    const month = parseInt(card.expirationMM)
    const year = parseInt(card.expirationAA) + 2000

    if (month < 1 && month > 12)
      errors.expirationMM = 'El mes debe ser un número entre 01 - 12.'
    if (year < nowYear)
      errors.expirationAA = 'El año debe ser un número mayor a ' + (nowYear - 2000)
    if (year === nowYear && month < nowMonth + 1)
      errors.expirationMM = 'La tarjeta está vencida'

    if (!card.ccv) errors.ccv = 'El campo es requerído'

    setErrors(errors)

    return Object.keys(errors).length === 0
  }

  const onHide = () => {
    dispatch(setModalCreateCard({ show: false }))
  }

  useEffect(() => {
    setCard({})
    setErrors({})
    setValid({})
  }, [modalCreateCard.show])

  useEffect(() => {
    if (loadingCreateCard) setAction({ ...action, cardCreate: true })
    else if (action.cardCreate) {
      setAction({ ...action, cardCreate: false })
      if (hasErrorCreateCard)
        dispatch(
          showAlert({
            ...handlerError(errorCreateCard.message),
          }),
        )
      else
        dispatch(
          showAlert({
            ...handlerSuccess('Tarjeta de crédito registrada satisfactoriamente'),
            onConfirm: () => {
              onHide()
            },
          }),
        )
    }
  }, [loadingCreateCard])

  const handleChange = e => {
    let { value } = e.target
    const { name } = e.target
    const number = value
    switch (name) {
      case 'cardNumber':
        if (!/[^0-9-\s]+/.test(number) || !number) {
          if (!cardNumber) cardNumber = ''
          if (cardNumber.length < number.length) {
            if (number.match(/^\d{4}$/) !== null) {
              value = number + '-'
            } else if (number.match(/^\d{4}-\d{4}$/) !== null) {
              value = number + '-'
            } else if (number.match(/^\d{4}-\d{4}-\d{4}$/) !== null) {
              value = number + '-'
            }
          }

          setCardType('none')
          setValid(valid => ({ ...valid, [name]: '' }))

          for (const item of Object.values(cardTypes)) {
            if (number.match(item.pattern)) {
              setCardType(item.name)
              if (item.valid_length.includes(number.replace(/-/g, '').length)) {
                if (validCreditCard(number)) {
                  setValid(valid => ({
                    ...valid,
                    [name]: 'Número de tarjeta válido',
                  }))
                  setErrors(errors => ({ ...errors, [name]: '' }))
                } else {
                  setErrors(errors => ({
                    ...errors,
                    [name]: 'El número de tarjeta no es válido.',
                  }))
                }
              } else {
                setErrors(errors => ({
                  ...errors,
                  [name]: 'La longitud del número de tarjeta no es válida',
                }))
              }
              break
            }
          }

          setCard(inputs => ({ ...inputs, [name]: value }))
        }
        break
      case 'ccv':
        if (/^\d+$/.test(number) || !number) {
          if (number.length > 4) {
            value = number.substring(0, 4)
          }
          setCard(inputs => ({ ...inputs, [name]: value }))
        }
        break
      case 'expirationMM':
        if (/^\d+$/.test(number) || !number) {
          setErrors(errors => ({ ...errors, [name]: '' }))
          if (number < 1 || number > 12) {
            setErrors(errors => ({
              ...errors,
              [name]: 'El mes debe ser un número entre 01 - 12.',
            }))
          }
          setCard(inputs => ({ ...inputs, [name]: value }))
        }
        break
      case 'expirationAA':
        if (/^\d+$/.test(number) || !number) {
          setErrors(errors => ({ ...errors, [name]: '' }))
          const year = parseInt(number) + 2000
          if (year < nowYear) {
            setErrors(errors => ({
              ...errors,
              [name]: 'El año debe ser un número mayor a ' + (nowYear - 2000),
            }))
          }
          setCard(inputs => ({ ...inputs, [name]: value }))
        }
        break
      case 'cardName':
        if (!value) value = ''
        setCard(inputs => ({ ...inputs, [name]: value.toUpperCase() }))
        break
      default:
        setCard(inputs => ({ ...inputs, [name]: value }))
    }
  }

  return (
    <Modal show={modalCreateCard.show} onHide={onHide} centered>
      <Modal.Header>
        <Modal.Title>Registrar nueva tarjeta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xl={12} md={12} sm={12}>
            <FormText
              label={'Alias'}
              value={alias}
              max={50}
              autoComplete={'cc-number'}
              name={'alias'}
              type={'text'}
              error={errors.alias}
              onChange={handleChange}
            />
          </Col>
          <Col xl={12} md={12} sm={12}>
            <div className="d-flex flex-column pb-3 flex-fill">
              <label className={'ftf-form-label left mt-3'}>
                Número de tarjeta de crédito
              </label>
              {/** @ts-expect-error js */}
              <Input
                className={`input-card ${cardType}`}
                name="cardNumber"
                type="tel"
                placeholder={'#'}
                maxLength={19}
                value={cardNumber}
                autoComplete="cc-number"
                error={errors.cardNumber}
                valid={valid.cardNumber}
                onChange={handleChange}
              />
            </div>
          </Col>
          <Col xl={12} md={12} sm={12}>
            <FormText
              label={'Nombre en Tarjeta de crédito'}
              value={cardName}
              max={50}
              autoComplete={'cc-number'}
              name={'cardName'}
              type={'text'}
              error={errors.cardName}
              onChange={handleChange}
            />
          </Col>
          <Col xl={12} md={12} sm={12}>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={'column'}>
                  <label className={'ftf-form-label left mt-3'}>
                    Fecha de Expiración
                  </label>
                  <Row>
                    <Col xl={6} lg={6} md={6}>
                      <FormText
                        mt={2}
                        placeholder={'MM'}
                        name={'expirationMM'}
                        type={'number'}
                        max={2}
                        value={expirationMM}
                        onChange={handleChange}
                        error={errors.expirationMM}
                        hideMaX
                      />
                    </Col>
                    <Col xl={6} lg={6} md={6}>
                      <FormText
                        mt={2}
                        placeholder={'AA'}
                        name={'expirationAA'}
                        type={'number'}
                        max={2}
                        value={expirationAA}
                        onChange={handleChange}
                        error={errors.expirationAA}
                        hideMaX
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <FormText
                  placeholder={'ccv'}
                  name={'ccv'}
                  type={'number'}
                  max={4}
                  value={ccv}
                  onChange={handleChange}
                  error={errors.ccv}
                  hideMaX
                  label={'Código de Verificación'}
                />
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <FormText
                  placeholder={'01010'}
                  name={'postalCode'}
                  type={'number'}
                  max={8}
                  value={postalCode}
                  onChange={handleChange}
                  error={errors.postalCode}
                  hideMaX
                  label={'Código postal'}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className={'container-buttons'}>
          <Button icon={faWindowClose} right color={'secondary'} onClick={onHide}>
            Cerrar
          </Button>
          <Button
            icon={faSave}
            loading={loadingCreateCard}
            right
            color={'primary'}
            onClick={() => {
              if (validateInputsCreditCard()) {
                const {
                  cardNumber,
                  cardName,
                  ccv,
                  expirationMM,
                  expirationAA,
                  alias,
                  postalCode,
                } = card

                const request = {
                  name: cardName,
                  number: cardNumber,
                  cvv: ccv,
                  expirationDate: `${expirationMM}/${expirationAA}`,
                  alias,
                  favorite: true,
                  logo: cardType,
                  postalCode,
                }
                dispatch(createCard(request))
              }
            }}>
            Registrar
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalCreateCard
