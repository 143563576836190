import React from 'react'
import { useSelector } from 'react-redux'

import { Modal, Row } from 'react-bootstrap'
import { Button, Paragraph } from 'src/components'
import { faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons'

import { haveAnyValue } from 'src/utils/utilitiesV2'
import { loadingSelector } from 'src/selectors/loading.selector'
import { actions } from 'src/actions/taxes.actions'

interface ITaxToDeleteProps {
  tax: Tax
  onClose: () => void
  onDelete: () => void
}

const TaxToDelete = ({ tax, onClose, onDelete }: ITaxToDeleteProps) => {
  const loading: boolean = useSelector(state =>
    loadingSelector([actions.DELETE_TAX])(state),
  )

  return (
    <div>
      <Modal show={haveAnyValue(tax.id)} centered>
        <Modal.Header>
          <Modal.Title>Eliminar impuesto: {tax.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={'center'}>
          <Paragraph>¿Estás seguro de que deseas realizar esta acción?</Paragraph>
          <Paragraph>
            Este proceso eliminará el impuesto y lo des-asignará de todos los ítems a los
            que esté asignado.
          </Paragraph>
          <Paragraph>
            Ten en cuenta que este proceso puede demorar unos segundos.
          </Paragraph>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              color={'secondary'}
              icon={faWindowClose}
              onClick={onClose}
              disabled={loading}>
              Cancelar
            </Button>
            <Button color={'primary'} icon={faTrash} onClick={onDelete} loading={loading}>
              Eliminar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default TaxToDelete
