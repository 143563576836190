export const selectAllPolygons = state => state.polygons.polygons
export const selectAllPolygonsBySelect = state =>
  state.polygons.polygons.map(p => ({
    ...p,
    label: p.companyName + ' - ' + p.name,
    value: p.id,
  }))
export const selectSinglePolygon = state => state.polygons.polygon
export const selectUpdatedPolygon = state => state.polygons.updatedPolygon
export const selectPolygonCategories = state =>
  state.polygons.categories.map(c => ({ ...c, value: c.id, label: c.name }))
export const selectSubscriptionsPolygons = state =>
  state.polygons.subscriptionsPolygons.map(p => ({
    ...p,
    label: p.name,
    value: p.id,
  }))
export const selectSettingResponse = state => state.polygons.settingResponse
