import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { FABV2, Title } from 'src/components'
import { isAllowed } from 'src/selectors/modules.selector'

import { faPlus } from '@fortawesome/free-solid-svg-icons'

import PromotionList from 'src/content/Promotion/PromotionList'
import PromotionForm from 'src/content/Promotion/Form/PromotionForm'

import {
  actions,
  getAllPromotions,
  getPromotionById,
  onCreatePromotion,
  onDeletePromotion,
  onSetPromotionId,
  onUpdatePromotion,
  onUpdatePromotionsList,
} from 'src/actions/promotions.actions'

import { getAllPOSByUser } from 'src/actions/restaurant.actions'

import { promotionPermissions } from 'src/enums/permissions'
import { selectCurrentUser } from 'src/selectors/user.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  handlerSuccess,
  handlerWarningButtons,
  hasErrors,
} from 'src/selectors/error.selector'
import { showAlert } from 'src/actions/alert.actions'
import {
  selectAllPromotions,
  selectPromotion,
  selectPromotionId,
  selectPromotionResponse,
} from 'src/selectors/promotions.selector'
import { haveAnyValue } from 'src/utils/utilitiesV2'

const PromotionPage = () => {
  const dispatch = useDispatch()

  const user = useSelector(selectCurrentUser)
  const promotionId: number = useSelector(selectPromotionId)

  const canCreate: boolean = useSelector(state =>
    isAllowed(state, [promotionPermissions.create]),
  )

  const loadingGet: boolean = useSelector(state =>
    loadingSelector([actions.GET_PROMOTION])(state),
  )
  const errorGet = useSelector(state => hasErrors([actions.GET_PROMOTION])(state))
  const promotionDetail: IPromotionRequest = useSelector(selectPromotion)

  const loadingCRUD: boolean = useSelector(state =>
    loadingSelector([actions.ON_CREATE_OR_UPDATE_PROMOTION])(state),
  )
  const errorCRUD = useSelector(state =>
    hasErrors([actions.ON_CREATE_OR_UPDATE_PROMOTION])(state),
  )
  const promotionResponse: IPromotion = useSelector(selectPromotionResponse)

  const loadingDelete: boolean = useSelector(state =>
    loadingSelector([actions.ON_DELETE_PROMOTION])(state),
  )
  const errorDelete = useSelector(state =>
    hasErrors([actions.ON_DELETE_PROMOTION])(state),
  )

  const promotions: IPromotion[] = useSelector(selectAllPromotions)

  const [promotion, setPromotion] = useState<IPromotionRequest>(undefined)
  const [flagCRUD, setFlagCRUD] = useState<boolean>(false)
  const [flagDelete, setFlagDelete] = useState<boolean>(false)
  const [flagGet, setFlagGet] = useState<boolean>(false)

  useEffect(() => {
    setUp()
    dispatch(getAllPOSByUser(user.id))
  }, [])

  useEffect(() => {
    if (loadingCRUD) setFlagCRUD(true)
    else if (flagCRUD) {
      setFlagCRUD(false)
      const alert: IAlert = errorCRUD
        ? handlerError(errorCRUD.message)
        : {
            ...handlerSuccess(),
            onConfirm: () => {
              if (haveAnyValue(promotionResponse)) {
                const customList = [...promotions]

                const promotion = customList.findIndex(p => p.id === promotionResponse.id)
                if (promotion > -1) customList[promotion] = promotionResponse
                else customList.push(promotionResponse)

                dispatch(onUpdatePromotionsList(customList))
                setPromotion(undefined)
              } else setUp()
            },
          }
      dispatch(showAlert(alert))
    }
  }, [loadingCRUD])

  useEffect(() => {
    if (loadingDelete) setFlagDelete(true)
    else if (flagDelete) {
      setFlagDelete(false)
      dispatch(onSetPromotionId(null))
      const alert: IAlert = errorDelete
        ? handlerError(errorDelete.message)
        : {
            ...handlerSuccess(),
            onConfirm: () => {
              dispatch(
                onUpdatePromotionsList(promotions.filter(p => p.id !== promotionId)),
              )
            },
          }
      dispatch(showAlert(alert))
    }
  }, [loadingDelete])

  useEffect(() => {
    if (loadingGet) setFlagGet(true)
    else if (flagGet) {
      setFlagGet(false)
      if (errorGet) dispatch(showAlert(handlerError(errorGet.message)))
      else setPromotion({ ...promotionDetail, id: promotionId })
      dispatch(onSetPromotionId(null))
    }
  }, [loadingGet])

  const setUp = () => {
    dispatch(getAllPromotions())
  }

  const onAction = (request: IPromotionRequest) => {
    if (!request.id) dispatch(onCreatePromotion(request))
    else dispatch(onUpdatePromotion(request.id, request))
  }

  const onDelete = (promotion: IPromotion) => {
    dispatch(
      showAlert({
        ...handlerWarningButtons(
          'Eliminar promoción',
          `¿Estás seguro de eliminar la promoción ${promotion.name}? está acción no podrá ser revertida.`,
        ),
        onConfirm: () => {
          dispatch(onSetPromotionId(promotion.id))
          dispatch(onDeletePromotion(promotion.id))
        },
      }),
    )
  }

  return (
    <div>
      <Title title="Promociones" />

      <PromotionList
        onRefresh={() => setUp()}
        onDelete={onDelete}
        onUpdate={(promotion: IPromotion) => {
          dispatch(onSetPromotionId(promotion.id))
          dispatch(getPromotionById(promotion.id))
        }}
      />

      <PromotionForm
        promotion={promotion}
        onHide={() => setPromotion(undefined)}
        onAction={onAction}
        loading={loadingCRUD}
      />

      <FABV2
        show={canCreate}
        title={'Crear'}
        icon={faPlus}
        onClick={() => setPromotion({ items: [] })}
      />
    </div>
  )
}
export default PromotionPage
