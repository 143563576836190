import './FormTextField.scss'
import React, { Component } from 'react'

import { InputGroup, FormControl } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'
import { faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import IconButton from '../../buttons/IconButton'
import { connect } from 'react-redux'

class FormText extends Component {
  getFTMValidation = () => {
    let { min, required, value } = this.props

    if (!value && (required || min)) return 'error'

    let length = (value + '').length

    if (min && length < min) return 'error'
    else return 'success'
  }

  render() {
    const {
      mt,
      label,
      type,
      id,
      placeholder,
      name,
      value,
      onChange,
      error,
      info,
      required,
      className,
      disabled,
      style,
      igStyle,
    } = this.props
    const { ...restProps } = this.props
    let {
      prepend,
      append,
      prependMoneySymbol,
      appendMoneySymbol,
      currencies,
      ptext,
      atext,
      sub,
      subI,
      max,
      min,
      noSpace,
      lStyle,
      changeValue,
      onClick,
    } = this.props
    prepend = prependMoneySymbol ? currencies.currentCurrency.symbol : prepend
    append = appendMoneySymbol ? currencies.currentCurrency.symbol : append

    const { aPadding, hideMaX } = this.props

    return (
      <div
        className={`input-wrapper has-feedback ${mt >= 0 ? 'mt-'.concat(mt) : 'mt-3'} ${
          className ? className : ''
        }`}
        style={style}>
        {(label || required) && (
          <label className="ftf-form-label left" htmlFor={id} style={lStyle}>
            {label} {!noSpace && <>&nbsp;</>}
            {info ? (
              <label>
                {' '}
                <IconButton
                  tooltip={info}
                  color={'rgba(34, 96, 149, 0.75)'}
                  icon={faInfoCircle}
                  size={'sm'}
                />{' '}
              </label>
            ) : (
              !noSpace && <label> &nbsp;</label>
            )}
            {required && <span style={{ color: 'red', marginRight: 2 }}>&nbsp;* </span>}
          </label>
        )}

        <div>
          <InputGroup style={igStyle}>
            {prepend &&
              (!ptext ? (
                <InputGroup.Text style={aPadding ? { padding: 0 } : {}}>
                  {prepend}
                </InputGroup.Text>
              ) : (
                prepend
              ))}
            <FormControl
              {...restProps}
              aria-describedby="inputGroup-sizing-default"
              disabled={disabled}
              type={!type || type === 'text' ? 'search' : type}
              placeholder={placeholder}
              name={name}
              min={min || 0}
              value={value}
              onChange={e => {
                if (max > 0) {
                  let ev = e.target.value

                  if (!ev) ev = 0
                  ev = ev + ''
                  if (ev.length <= max) {
                    if (onChange) onChange(e)
                    if (changeValue) changeValue(e.target.value)
                  }
                } else {
                  if (onChange) onChange(e)
                  if (changeValue) changeValue(e.target.value, e)
                }
              }}
              //onFocus={(event)=> event.target.select()}
              autoComplete={'off'}
              onClick={e => onClick && onClick(e)}
            />
            {append &&
              (!atext ? (
                <InputGroup.Text style={aPadding ? { padding: 0 } : {}}>
                  {append}
                </InputGroup.Text>
              ) : (
                append
              ))}
            {/*append && <InputGroup.Append>
                            {!atext ? <InputGroup.Text style={aPadding ? {padding: 0} : {}}>{append}</InputGroup.Text> : append}
                        </InputGroup.Append>*/}
          </InputGroup>
          {max && !hideMaX && (
            <div className={'ft-max ft-max-'.concat(this.getFTMValidation())}>
              {value ? (value + '').length : 0}/{max}
            </div>
          )}
        </div>
        <span className="input-focus" />
        {sub && (
          <div className={'ft-sub left'}>
            {sub}{' '}
            {subI && (
              <label>
                {' '}
                <IconButton
                  tooltip={subI}
                  color={'rgba(34, 96, 149, 0.75)'}
                  icon={faInfoCircle}
                  size={'sm'}
                />{' '}
              </label>
            )}
          </div>
        )}
        {error ? (
          <div className="left input-error">
            <FontAwesomeIcon icon={faExclamationCircle} /> {error}
          </div>
        ) : (
          <div />
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currencies: state.currencies,
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(FormText)
