import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { actionTypes, getWarehouseLite } from 'src/actions/warehouse.actions'
import { Button, Card, FormText, Select } from 'src/components'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  selectOwnWarehouseLite,
  selectWarehouseLite,
} from 'src/selectors/warehouse.selector'

/**
 * Component that allows you to indicate a number of times to apply a conversion and the warehouses for shipping and receiving inventory
 * @param {Object} props - Props of component
 * @param {boolean} show - Open modal component
 * @param {boolean} fast - Render warehouses options
 * @param {function} onHide - Close modal component
 * @param {function} onSave - Close modal component and return quantity, warehouses data selected
 * @param {number} quantity - Initial quantity data
 * @param {boolean} hideQuantity - Hide quantity input
 * */
export const ConversionWarehouse = ({
  show,
  fast = false,
  onHide,
  onSave,
  quantity = 1,
  hideQuantity = false,
}) => {
  const dispatch = useDispatch()

  const loading = useSelector(state =>
    loadingSelector([actionTypes.GET_WAREHOUSE_LITE])(state),
  )

  const warehouses = useSelector(selectWarehouseLite)
  const ownWarehouses = useSelector(selectOwnWarehouseLite)
  const conversionInitial = {
    fromWarehouse: {
      value: null,
      label: 'Sin seleccionar',
    },
    toWarehouse: {
      value: null,
      label: 'Sin seleccionar',
    },
    conversionDescription: '',
    quantity: 1,
  }
  const [conversionData, setConversionData] = useState(conversionInitial)

  useEffect(() => {
    if (warehouses.length === 0) dispatch(getWarehouseLite({ all: true }))
  }, [])

  useEffect(() => {
    if (show) {
      setConversionData({ ...conversionInitial, quantity: quantity || 1 })
    }
  }, [show])

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      centered
      onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Cantidad {fast ? 'y Bodegas' : ''}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {!hideQuantity && (
            <Col xl={12} lg={12} md={12} sm={12}>
              <Card>
                <Row>
                  <Col xl={12}>
                    <div className={'space-between'}>
                      <div>
                        <h6 className={'dashboard-title dashboard-f-color'}>Cantidad</h6>
                      </div>
                    </div>
                  </Col>
                  <Col xl={12}>
                    <FormText
                      id={'quantity'}
                      label={
                        'Cantidad de unidades en las que se transformará esta receta'
                      }
                      placeholder={`Unidades`}
                      type={'number'}
                      name={'description'}
                      value={conversionData.quantity}
                      autoFocus={show}
                      onChange={({ target }) => {
                        let { value } = target
                        setConversionData({ ...conversionData, quantity: Number(value) })
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          )}

          {fast && (
            <Row>
              <Col xl={6} lg={6} md={6} sm={12}>
                <Card>
                  <Row>
                    <Col xl={12}>
                      <div className={'space-between'}>
                        <div>
                          <h6 className={'dashboard-title dashboard-f-color'}>
                            Bodega saliente
                          </h6>
                        </div>
                      </div>
                    </Col>
                    <Col xl={12}>
                      <Select
                        label={'Seleccionar una bodega'}
                        options={ownWarehouses}
                        value={conversionData.fromWarehouse}
                        loading={loading}
                        onChange={fw => {
                          setConversionData({ ...conversionData, fromWarehouse: fw })
                        }}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <Card>
                  <Row>
                    <Col xl={12}>
                      <div className={'space-between'}>
                        <div>
                          <h6 className={'dashboard-title dashboard-f-color'}>
                            Bodega Receptora
                          </h6>
                        </div>
                      </div>
                    </Col>
                    <Col xl={12}>
                      <Select
                        label={'Seleccionar una bodega'}
                        options={warehouses}
                        value={conversionData.toWarehouse}
                        loading={loading}
                        onChange={tw => {
                          setConversionData({ ...conversionData, toWarehouse: tw })
                        }}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <Card>
                  <Row>
                    <Col xl={12}>
                      <div className={'space-between'}>
                        <div>
                          <h6 className={'dashboard-title dashboard-f-color'}>
                            Descripción o concepto para la conversión
                          </h6>
                        </div>
                      </div>
                    </Col>
                    <Col xl={12}>
                      <FormText
                        label={'Descripción'}
                        type={'text'}
                        name={'description'}
                        value={conversionData.conversionDescription}
                        onChange={e =>
                          setConversionData({
                            ...conversionData,
                            conversionDescription: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          )}
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button
          icon={faTimes}
          color={'secondary'}
          onClick={() => {
            onHide()
          }}>
          Cerrar
        </Button>
        <Button
          icon={faSave}
          disabled={
            !conversionData.quantity ||
            conversionData.quantity <= 0 ||
            (fast &&
              (conversionData.fromWarehouse.value === null ||
                conversionData.toWarehouse.value === null))
          }
          onClick={() => {
            onSave(conversionData)
          }}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
