import React, { Component } from 'react'
import { connect } from 'react-redux'

import CreateResolutions from './CreateResolutions/CreateResolutions'

import Card from '../../../components/cards/Card'
import IconButton from '../../../components/buttons/IconButton'
import { formatDateFromMillis } from '../../../utils/formatters'

import { Col, Row, Modal } from 'react-bootstrap'
import { Td, Tr } from 'react-super-responsive-table'
import { faSync, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import SweetAlert from 'sweetalert-react'

import {
  actionTypes as types,
  getResolutions,
  onCreateResolution,
  getDocumentsType,
} from '../../../actions/resolutions.actions'
import {
  selectResolutions,
  selectCreate,
  selectDocumentsTypes,
} from '../../../selectors/resolutions.selector'

import { loadingSelector } from '../../../selectors/loading.selector'
import { hasErrorsSelector, singleErrorSelector } from '../../../selectors/error.selector'
import { TableV2 } from '../../../components'

class Resolutions extends Component {
  state = {
    alert: {
      show: false,
      title: 'default',
      text: 'default',
      type: 'info',
    },
    createResolution: false,
  }

  handlerError = message => {
    return { show: true, title: 'Uh-Oh..', text: message, type: 'warning' }
  }

  handlerSuccess = message => {
    return { show: true, title: 'Yeah!', text: message, type: 'success' }
  }

  componentDidMount() {
    this.props.getDocumentsType()
    this.props.getResolutions()
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { loadingCreate, createHasError } = this.props
    const { create, createError } = props
    if (loadingCreate && !props.loadingCreate) {
      if (!createHasError && props.createHasError) {
        console.log(createError)
      } else {
        const alert = this.handlerSuccess('Resolución creada con exito!')
        this.setState({ alert, createResolution: false })
        this.props.getResolutions()
      }
    }
  }

  getDocument = item => {
    const { documents } = this.props
    let type = 'N/A'
    documents.filter(d => d.value === item.documentType).map(d => (type = d.label))
    return type
  }

  goTo = item => {
    const { history, match } = this.props
    const url = `${match.url}/${item.id}`
    history.push(url)
  }

  renderRow = (item, index) => {
    return (
      <Tr className={'data'} key={`${index}`}>
        <Td>{item.series}</Td>
        <Td>{item.initialNumber}</Td>
        <Td>{item.finalNumber}</Td>
        <Td>{this.getDocument(item)}</Td>
        <Td>{item.number}</Td>
        <Td>{formatDateFromMillis(item.authorizationDate)}</Td>
        <Td>{formatDateFromMillis(item.expirationDate)}</Td>
        <Td className={'short center'}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
            }}>
            <IconButton
              onClick={() => this.goTo(item)}
              tooltip={'Ver facturas'}
              icon={faFileAlt}
            />
          </div>
        </Td>
      </Tr>
    )
  }

  getHeaders = () => {
    return [
      { label: 'Serie', show: true, value: ['series'], type: 'text', className: 'mini' },
      {
        label: 'Inicia',
        show: true,
        value: ['initialNumber'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Finaliza',
        show: true,
        value: ['finalNumber'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'documento',
        show: true,
        value: ['documentoC'],
        type: 'text',
        className: 'mini',
        custom: item => (item.documentoC = this.getDocument(item)),
      },
      {
        label: 'NO. resolución',
        show: true,
        value: ['number'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Autorizado',
        show: true,
        value: ['authorizationDate'],
        type: 'date',
        className: 'mini',
      },
      {
        label: 'Expirará',
        show: true,
        value: ['expirationDate'],
        type: 'date',
        className: 'mini',
      },
      {
        config: true,
        show: true,
        label: '',
        className: 'mini center',
        custom: item => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
            }}>
            <IconButton
              onClick={() => this.goTo(item)}
              tooltip={'Ver facturas'}
              icon={faFileAlt}
            />
          </div>
        ),
      },
    ]
  }

  createResolutions = request => {
    this.props.onCreateResolution(request)
  }

  button = () => {
    return (
      <IconButton
        tooltip={'Actualizar'}
        icon={faSync}
        onClick={() => this.props.getResolutions()}
      />
    )
  }

  render() {
    const { resolutions, loading, loadingCreate, documents } = this.props
    const { alert } = this.state

    return (
      <div style={{ marginBottom: 50 }}>
        <Row>
          <Col md={4}>
            <Row>
              <Col md={12}>
                <Card title={'Filtros'}></Card>
              </Col>
              <Col md={12}>
                <div
                  onClick={() => this.setState({ createResolution: true })}
                  className={'big-button btn'}>
                  Nueva resolución
                </div>
              </Col>
            </Row>
          </Col>
          <Col>
            <Card title={'Lista de resoluciones'} button={this.button()}>
              <TableV2
                headers={this.getHeaders()}
                items={resolutions.sort((a, b) => b.createdAt - a.createdAt)}
                loading={loading}
                mobileAuto
                storageKey={`resolutions`}
              />
            </Card>
          </Col>
        </Row>
        <Modal
          show={this.state.createResolution}
          size={'lg'}
          centered
          onHide={() => this.setState({ createResolution: loadingCreate })}>
          <Modal.Header closeButton={!loadingCreate}>
            <Modal.Title>Creación de resolución</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreateResolutions
              documentsTypes={documents}
              loading={loadingCreate}
              getRequest={this.createResolutions}
            />
          </Modal.Body>
        </Modal>

        <SweetAlert
          show={alert.show}
          title={alert.title}
          text={alert.text}
          type={alert.type}
          onConfirm={() => this.setState({ alert: { ...alert, show: false } })}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  resolutions: selectResolutions(state),
  loading: loadingSelector([types.GET_RESOLUTIONS])(state),
  hasError: hasErrorsSelector([types.GET_RESOLUTIONS])(state),
  error: singleErrorSelector([types.GET_RESOLUTIONS])(state),
  create: selectCreate(state),
  loadingCreate: loadingSelector([types.ON_CREATE_RESOLUTION])(state),
  createHasError: hasErrorsSelector([types.ON_CREATE_RESOLUTION])(state),
  createError: singleErrorSelector([types.ON_CREATE_RESOLUTION])(state),
  documents: selectDocumentsTypes(state),
})
const mapDispatchToProps = dispatch => ({
  getResolutions: () => dispatch(getResolutions()),
  onCreateResolution: request => dispatch(onCreateResolution(request)),
  getDocumentsType: () => dispatch(getDocumentsType()),
})
export default connect(mapStateToProps, mapDispatchToProps)(Resolutions)
