import { types } from '../actions/printer.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  all: [],
  data: {},
  print: {},
  generalData: {},
  local: { status: false, message: '' },
  printInstructions: {},
}

const PrinterReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(types.SEND_PRINT_INSTRUCTIONS):
      return { ...state, printInstructions: extract(action.payload, {}) }
    case successState(types.GET_PRINT):
      return { ...state, print: extract(action.print, {}) }
    case successState(types.SAVE_PRINT_CONNECT):
      return { ...state, data: extract(action.data, {}) }
    case successState(types.GET_PRINTERS):
      return { ...state, all: extract(action.printers, []) }
    case successState(types.SAVE_LOCAL_PRINTS):
      return { ...state, all: extract(action.printers, []) }
    case successState(types.SET_GENERAL_DATA):
      return { ...state, generalData: extract(action.data, {}) }
    case successState(types.SEND_PRINT_TO_LOCALHOST):
      return { ...state, local: extract(action.local, {}) }
    default:
      return state ? state : initialState
  }
}
export default PrinterReducer
