import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import {
  Button,
  Card,
  CustomSummary,
  Icon,
  Select,
  SelectedDates,
  TableV2,
  Title,
} from 'src/components'
import { faFileExcel, faSearch } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { selectUsers } from 'src/selectors/modules.selector'
import { getAllPOSByUser } from 'src/actions/restaurant.actions'
import { selectCurrentUser } from 'src/selectors/user.selector'
import { getUsersChildrenByModule } from 'src/actions/modules.actions'
import { selectAllPOSUser } from 'src/selectors/restaurant.selector'
import { selectVehicleReport } from 'src/selectors/report.selector'
import {
  actionTypes,
  getVehicleReport,
  getVehicleReportExcel,
} from 'src/actions/report.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import { toMoney } from 'src/utils/utilities'
import parkingTypes from 'src/enums/parkingTypes'

const defaultFilter: ISelectNumber = {
  value: null,
  label: 'Todos',
}
const rateTypes: ISelectNumber[] = parkingTypes

const dateTypes: ISelectNumber[] = [
  {
    value: 0,
    label: 'De salida',
  },
  {
    value: 1,
    label: 'De ingreso',
  },
]

const headers = [
  {
    label: 'Orden de venta',
    show: true,
    value: ['code'],
    type: 'text',
  },
  {
    label: 'Fecha de ingreso',
    show: true,
    value: ['inDate'],
    type: 'date',
  },
  {
    label: 'Fecha de salida',
    show: true,
    value: ['outDate'],
    type: 'date',
  },
  {
    label: 'Placas',
    show: true,
    value: ['plate'],
    type: 'text',
  },
  {
    label: 'Tarifa',
    show: true,
    type: 'text',
    custom: item => rateTypes.find(x => x.value === item.rate)?.label,
  },
  {
    label: 'Cantidad',
    show: true,
    value: ['quantity'],
    type: 'number',
  },
  {
    label: 'Total',
    show: true,
    value: ['total'],
    type: 'currency',
  },
  {
    label: 'Ingresado por',
    show: true,
    value: ['enteredByData', 'name'],
    type: 'text',
  },
  {
    label: 'Terminado por',
    show: true,
    value: ['finishedByData', 'name'],
    type: 'text',
  },
]

/**
 * View of Vehicles Report
 * @returns
 */
export const VehiclesControl = () => {
  const dispatch = useDispatch()

  const report: IVehicleReport[] = useSelector(selectVehicleReport)
  const user = useSelector(selectCurrentUser)
  const users: ISelect[] = useSelector(selectUsers)
  const pointOfSales = useSelector(selectAllPOSUser)

  const reportLoading = useSelector(state =>
    loadingSelector([actionTypes.GET_VEHICLE_REPORT])(state),
  )

  const [filters, setFilters] = useState({
    startDate: new Date().setHours(0, 0, 0, 0),
    endDate: new Date(),
    rate: defaultFilter,
    enteredBy: defaultFilter,
    finishedBy: defaultFilter,
    pos: defaultFilter,
    dateType: dateTypes[0],
  })

  useEffect(() => {
    dispatch(getAllPOSByUser(user.id))
    dispatch(getUsersChildrenByModule(1000, true))
    setUp()
  }, [])

  const setUp = () => {
    dispatch(
      getVehicleReport({
        startDate: filters.startDate.valueOf(),
        endDate: filters.endDate.valueOf(),
        rate: filters.rate.value,
        enteredBy: filters.enteredBy.value,
        finishedBy: filters.finishedBy.value,
        pos: filters.pos.value,
        dateType: filters.dateType.value,
      }),
    )
  }

  return (
    <div>
      <Title title={'Reporte de Vehículos'} />
      <Card
        title={'Filtros'}
        white
        button={
          <Icon
            icon={faFileExcel}
            disabled={reportLoading}
            spin={reportLoading}
            onClick={() => {
              dispatch(
                getVehicleReportExcel({
                  startDate: filters.startDate.valueOf(),
                  endDate: filters.endDate.valueOf(),
                  rate: filters.rate.value,
                  enteredBy: filters.enteredBy.value,
                  finishedBy: filters.finishedBy.value,
                  pos: filters.pos.value,
                  dateType: filters.dateType.value,
                }),
              )
            }}
          />
        }>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <SelectedDates
              nonExecute
              onDateChange={(start, end) => {
                setFilters({ ...filters, startDate: start, endDate: end })
              }}
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} xl={4}>
            <Select
              disabled={reportLoading}
              label={'Tarifa'}
              value={filters.rate}
              options={[defaultFilter, ...rateTypes]}
              onChange={data => setFilters({ ...filters, rate: data })}
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} xl={4}>
            <Select
              disabled={reportLoading}
              label={'Ingresado por'}
              name={'deliverer'}
              value={filters.enteredBy}
              options={[defaultFilter, ...users]}
              onChange={(item: ISelectNumber) =>
                setFilters({ ...filters, enteredBy: item })
              }
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} xl={4}>
            <Select
              disabled={reportLoading}
              label={'Finalizado por'}
              name={'finishedBy'}
              value={filters.finishedBy}
              options={[defaultFilter, ...users]}
              onChange={(item: ISelectNumber) =>
                setFilters({ ...filters, finishedBy: item })
              }
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} xl={4}>
            <Select
              disabled={reportLoading}
              label={'Puntos de venta'}
              options={[defaultFilter, ...pointOfSales.filter(x => x.type === 2)]}
              value={filters.pos}
              placeholder={'Seleccione un punto de venta'}
              onChange={e => setFilters({ ...filters, pos: e })}
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} xl={4}>
            <Select
              disabled={reportLoading}
              label={'Por fecha'}
              options={dateTypes}
              value={filters.dateType}
              placeholder={'Seleccione un punto de venta'}
              onChange={e => setFilters({ ...filters, dateType: e })}
            />
          </Col>
          <Col xl={12}>
            <Row className={'container-buttons'}>
              <Button
                color={'accent'}
                icon={faSearch}
                loading={reportLoading}
                onClick={() => setUp()}>
                Aplicar filtros
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>
      <Card>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <CustomSummary
            items={[
              {
                show: true,
                title: 'Total de ventas',
                value: report.length,
              },
              {
                show: true,
                title: 'Monto total',
                value: toMoney(report.reduce((acc, item) => acc + (item.total || 0), 0)),
              },
            ]}
          />
          <TableV2
            /** @ts-expect-error js */
            loading={reportLoading}
            headers={headers}
            items={report}
            mobileAuto
            storageKey={'vehicleReport'}
          />
        </Col>
      </Card>
    </div>
  )
}
