import { posPanelConfigurations } from './posPanelConfigurations'
import { Country } from './countries'
import { haveAnyValue } from '../utils/utilitiesV2'

export enum felDocumentType {
  INVOICE = 1,
  INVOICE_WITHOUT_IVA = 2,
  CREDIT_FISCAL = 3,
  INVOICE_CREDIT = 4,
  CREDIT_NOTE = 5,
  EXPORTATION_INVOICE = 11,
  SUJETO_EXC = 14,
  INVOICE_PEQ = 16,
}

export const fiscalTypes: IDictionary<IFelDocumentType> = {
  [felDocumentType.INVOICE]: {
    value: felDocumentType.INVOICE,
    label: 'Factura',
    panelConfig: posPanelConfigurations.payFiscalInvoice,
  },
  /*[felDocumentType.INVOICE_WITHOUT_IVA]: {
    value: felDocumentType.INVOICE_WITHOUT_IVA,
    label: 'Factura sin IVA',
    panelConfig: posPanelConfigurations.payOrder,
    countries: [Country.SV],
  },*/
  [felDocumentType.CREDIT_FISCAL]: {
    value: felDocumentType.CREDIT_FISCAL,
    label: 'Crédito fiscal',
    panelConfig: posPanelConfigurations.payFiscalCredit,
    countries: [Country.SV],
  },
  [felDocumentType.CREDIT_NOTE]: {
    value: felDocumentType.CREDIT_NOTE,
    label: 'Notas de crédito',
  },
  [felDocumentType.EXPORTATION_INVOICE]: {
    value: felDocumentType.EXPORTATION_INVOICE,
    label: 'Factura de exportación',
  },
  [felDocumentType.SUJETO_EXC]: {
    value: felDocumentType.SUJETO_EXC,
    label: 'Factura de sujeto excluido',
    panelConfig: posPanelConfigurations.payExcludedSubject,
    countries: [Country.SV],
  },
  [felDocumentType.INVOICE_CREDIT]: {
    value: felDocumentType.INVOICE_CREDIT,
    label: 'Factura Cambiaria',
    countries: [Country.GT],
  },
}

const felDocumentTypes: IFelDocumentType[] = Object.values(fiscalTypes)

export const getFelDocumentType = (country: number): IFelDocumentType[] => {
  return felDocumentTypes
    .filter(ft => {
      if (!haveAnyValue(ft.countries)) return true
      else return ft.countries.includes(country)
    })
    .map(ft => {
      if (ft.value === felDocumentType.INVOICE && country === Country.SV)
        return { ...ft, panelConfig: posPanelConfigurations.payFiscalInvoice }
      return ft
    })
}

export default felDocumentTypes
