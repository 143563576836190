import React, { Component } from 'react'
import { connect } from 'react-redux'

import Card from 'src/components/cards/Card'

import Information from './OfferInformation'
import Position from './OfferPosition'
import OfferProduct from './OfferProducts'
import OfferResume from './OfferResume'

import {
  actionTypes as types,
  getSingleOffer,
  setInformationToOffer,
  getOfferCategories,
  setSteps,
} from 'src/actions/offers.actions'
import { selectOffer, selectSteps } from 'src/selectors/offers.selector'

import {
  getProductsWithTransportationPayment,
  getProducts,
} from 'src/actions/products.actions'

import { loadingSelector } from 'src/selectors/loading.selector'

import { BulletList } from 'react-content-loader'
import { parseDate } from 'react-day-picker/moment'
import { Row, Col } from 'react-bootstrap'
import StepZilla from 'react-stepzilla'

import moment from 'moment'
import { getAllPolygons, getPolygonByCategory } from 'src/actions/polygons.actions'

class OfferCreate extends Component {
  state = {
    steps: [
      {
        value: 0,
        name: 'Información',
        component: (
          <Information
            isUpdate={this.props.match.params.id ? true : false}
            match={this.props.match}
          />
        ),
      },
      {
        value: 1,
        name: 'Poligono',
        component: (
          <Position
            isUpdate={this.props.match.params.id ? true : false}
            match={this.props.match}
          />
        ),
      },
      {
        value: 2,
        name: 'Productos',
        component: (
          <OfferProduct
            isUpdate={this.props.match.params.id ? true : false}
            match={this.props.match}
          />
        ),
      },
      {
        value: 3,
        name: 'Resumen',
        component: (
          <OfferResume
            isUpdate={this.props.match.params.id ? true : false}
            history={this.props.history}
            match={this.props.match}
          />
        ),
      },
    ],
  }

  componentDidMount() {
    const { match, setInfo, getProducts, getOfferCategories, setSteps, getPolygons } =
      this.props
    // getProducts();
    // getPolygons();

    setSteps([0])
    getOfferCategories()
    setInfo({
      polygon: { value: 0, label: 'Sin seleccionar' },
      kolo: false,
      productList: [],
      priceNegotiable: false,
      alias: '',
      notes: '',
      deliveryAmount: 0,
      pickup: { value: 1, label: 'A bodega de transportista' },
    })
    const { id } = match.params
    if (id) this.props.getOffer(id)
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { loading, match, setInfo } = this.props
    const { id } = match.params
    const { offer } = props
    if (id && loading && !props.loading) {
      offer.availableUntil = parseDate(moment(offer.availableUntil))
      offer.polygon = { value: offer.polygon, label: offer.polygonName }
      setInfo(offer)
    }
  }

  render() {
    const { steps } = this.state
    const { loading, match, stepsValues } = this.props

    const title = match.params.id
      ? 'Editar oferta de transporte'
      : 'Crear oferta de transporte'

    return (
      <Card title={title}>
        {loading || stepsValues.length === 0 ? (
          <BulletList />
        ) : (
          <Row>
            <Col>
              <div className={'step-progress'}>
                <StepZilla
                  steps={steps.filter(s => stepsValues.find(v => v === s.value) != null)}
                  stepsNavigation={false}
                  dontValidate={true}
                  showNavigation={false}
                />
              </div>
            </Col>
          </Row>
        )}
      </Card>
    )
  }
}

const mapStateToProps = state => ({
  loading: loadingSelector([types.GET_SINGLE_OFFER])(state),
  offer: selectOffer(state),
  stepsValues: selectSteps(state),
})
const mapDispatchToProps = dispatch => ({
  getPolygons: () => dispatch(getPolygonByCategory(1)),
  getOffer: id => {
    dispatch(getSingleOffer(id))
  },
  setInfo: offer => dispatch(setInformationToOffer(offer)),
  getProducts: () => dispatch(getProducts()),
  getOfferCategories: () => dispatch(getOfferCategories()),
  setSteps: steps => dispatch(setSteps(steps)),
})
export default connect(mapStateToProps, mapDispatchToProps)(OfferCreate)
