import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'

import { Col, Row } from 'react-bootstrap'
import {
  Card,
  CustomSummary,
  Select,
  SelectedDates,
  TableV2,
  Title,
  Icon,
  Button,
} from 'src/components'

import {
  actionTypes,
  getBranchReport,
  getBranchReportExcel,
} from 'src/actions/report.actions'
import { selectBranchReport } from 'src/selectors/report.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { getItemValue } from 'src/utils/formatters'

import { faFileExcel, faSearch, faSync } from '@fortawesome/free-solid-svg-icons'
import { PaymentType } from 'src/enums/paymentTypes'

const reportOptions = [
  { value: 1, label: 'Bodegas' },
  { value: 2, label: 'Puntos de venta' },
  { value: 3, label: 'Usuarios' },
]

const paymentTypes = [
  { value: null, label: 'Todos' },
  { value: PaymentType.CASH, label: 'Efectivo' },
  { value: PaymentType.CREDIT, label: 'Crédito' },
  { value: PaymentType.BANK_TRANSFER, label: 'Transferencia' },
  { value: PaymentType.CARD, label: 'Tarjeta' },
]

const params = [
  {
    id: 'total',
    label: 'Total de ventas',
    type: 'currency',
  },
  {
    id: 'cost',
    label: 'Costo de ventas',
    type: 'currency',
  },
  {
    id: 'utility',
    label: 'Utilidad',
    type: 'currency',
  },
  {
    id: 'profitability',
    label: 'Rentabilidad',
    type: 'percentage',
  },
  {
    id: 'tip',
    label: 'Propina',
    type: 'currency',
  },
  {
    id: 'clientCount',
    label: 'Clientes',
    type: 'number',
  },
  {
    id: 'ticketAVG',
    label: 'Ticket promedio',
    type: 'currency',
  },
]

const BranchesReport = () => {
  const dispatch = useDispatch()

  const report = useSelector(selectBranchReport)
  const items = report.filter(r => !r.summary)
  const summary = report.filter(r => r.summary)

  const loading = useSelector(state =>
    loadingSelector([actionTypes.GET_BRANCH_REPORT])(state),
  )

  const loadingDownload = useSelector(state =>
    loadingSelector([actionTypes.GET_BRANCH_REPORT_EXCEL])(state),
  )

  const [filters, setFilters] = useState({
    start: new Date().setHours(0, 0, 0, 0).valueOf(),
    end: new Date().setHours(23, 59, 59, 99).valueOf(),
    branchType: 1,
    paymentType: { value: null, label: 'Todos' },
  })

  const reportOption = reportOptions.find(option => option.value === filters.branchType)

  useEffect(() => {
    setUp(false)
  }, [])

  const setUp = excel => {
    const params = {
      ...filters,
      paymentType: filters.paymentType.value,
    }

    if (excel) dispatch(getBranchReportExcel(params))
    else dispatch(getBranchReport(params))
  }

  const renderButtons = (
    <Row className={'container-buttons'}>
      <Icon
        icon={faSync}
        spin={loading}
        tooltip={'Actualizar'}
        onClick={() => setUp()}
        className={'mr-3'}
      />
      <Icon
        icon={faFileExcel}
        disabled={loading}
        onClick={() => setUp(true)}
        spin={loadingDownload}
        tooltip={'Descargar'}
      />
    </Row>
  )

  const getSummary = () => {
    const itemSummary = summary.length > 0 ? summary[0] : {}

    return params.map(param => ({
      show: true,
      title: param.label,
      value: getItemValue(itemSummary, [param.id], param.type),
    }))
  }

  const getHeaders = () => {
    const headers = [
      {
        label: 'Nombre',
        value: ['name'],
        type: 'text',
        show: true,
      },
    ]

    params.forEach(param =>
      headers.push({
        label: param.label,
        value: [param.id],
        type: param.type,
        show: true,
      }),
    )

    return headers
  }

  return (
    <div>
      <Title title={'Reporte de Ventas por Sucursal'} />
      <Row>
        <Col xs={12}>
          <Card title={'Filtros'} white button={renderButtons}>
            <Row>
              <Col xs={12}>
                <SelectedDates
                  setValueDates
                  nonExecute
                  onDateChange={(start, end) => {
                    setFilters({ ...filters, start, end })
                  }}
                  initialPreset={{ value: 0, label: 'Hoy' }}
                />
              </Col>
              <Col xl={6} lg={6} md={12} sm={12}>
                <Select
                  className={'mb-2'}
                  disabled={loading}
                  label={'Reporte de ventas por:'}
                  value={reportOption}
                  options={reportOptions}
                  required
                  onChange={branchType => {
                    setFilters({ ...filters, branchType: branchType.value })
                  }}
                />
              </Col>
              <Col xl={6} lg={6} md={12} sm={12}>
                <Select
                  label={'Por tipo de pago'}
                  disabled={loading}
                  options={paymentTypes}
                  value={filters.paymentType}
                  onChange={v => setFilters({ ...filters, paymentType: v })}
                />
              </Col>
              <Col xl={12}>
                <Row className={'container-buttons'}>
                  <Button color={'accent'} icon={faSearch} onClick={() => setUp(false)}>
                    Aplicar Filtros
                  </Button>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12}>
          <Card>
            <CustomSummary items={getSummary()} />
            <TableV2
              loading={loading}
              headers={getHeaders()}
              items={items}
              customClass={'scroll-x-without-height'}
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default BranchesReport
