import { $http } from './http'
import moment from 'moment'
import { saveAs } from 'file-saver'

class BanksService {
  getBanks = async () => {
    const response = await $http.get(`/bank/accounts/banks`)
    return response.data
  }

  getAccounts = async params => {
    const response = await $http.get(`/bank/accounts`, { params })
    return response.data
  }

  postAccount = async (
    accountNumber,
    name,
    toName,
    bank,
    type,
    balance,
    module,
    assigned,
    currency,
    report,
  ) => {
    if (!balance || balance == '') balance = 0.0
    const response = await $http.post(`/bank/accounts`, {
      accountNumber,
      name,
      toName,
      bank,
      type,
      balance,
      module,
      assigned,
      currency,
      report,
    })
    return response.data
  }

  putAccount = async item => {
    const response = await $http.put(`/bank/accounts/`, item)
    return response.data
  }

  deleteAccount = async id => {
    const response = await $http.delete(`/bank/accounts/${id}`)
    return response.data
  }

  getAccountHistory = async (id, params) => {
    const response = await $http.get(`/bank/accounts/history/${id}`, {
      params,
    })
    return response.data
  }

  postMovement = async (request, params) => {
    const response = await $http.post(`/bank/accounts/operation`, request, {
      params,
    })
    return response.data
  }

  onCreateTransactionCSV = async (bankAccountsId, file) => {
    let formData = new FormData()
    formData.append('file', file)

    const response = await $http.post(
      `/bank/accounts/operation/csv/${bankAccountsId}`,
      formData,
      { headers: { 'content-type': 'multipart/form-data' } },
    )
    return response.data
  }

  getMovementMatchDeposit = async (documentId, isPurchase, currency, amount) => {
    const response = await $http.get(`/bank/accounts/match/deposit/${documentId}`, {
      params: { currency, isPurchase, amount },
    })
    return response.data
  }

  getDepositsByCompany = async () => {
    const response = await $http.get(`/deposit/banks`)
    return response.data
  }

  revertMovement = async (movement, message) => {
    const response = await $http.post(
      `/bank/accounts/operation/revert/${movement}`,
      message,
    )
    return response.data
  }

  acceptRetention = async (movementId, revert) => {
    const response = await $http.put(
      `/bank/accounts/accept/retention/${movementId}`,
      {},
      { params: { revert } },
    )
    return response.data
  }

  changeStatusAccount = async id => {
    const response = await $http.put(`/bank/accounts/disbursement/${id}`, {})
    return response.data
  }

  updateKoloValue = async id => {
    const response = await $http.put(`/bank/accounts/kolo/toggle/${id}`)
    return response.data
  }

  getExcelMovements = async (params, id) => {
    const response = await $http.get(`/bank/accounts/operation/excel/${id}`, {
      responseType: 'blob',
      params,
    })

    const fileName = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : moment().format('LL') + ' - Movimientos bancarios - .xlsx'

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    saveAs(file, fileName.replace('json', 'xlsx'))
  }

  acceptMovementRequest = async request => {
    const response = await $http.put(`/bank/accounts/movement/request/accept`, request)
    return response.data
  }

  onCreateTransactionCSVV2 = async (bankAccountsId, index, request, biBanking) => {
    const response = await $http.post(
      `/bank/accounts/operation/csv/${bankAccountsId}/v2`,
      request,
      {
        params: { index, biBanking },
      },
    )
    return response.data
  }
}

export default new BanksService()
