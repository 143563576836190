import React from 'react'
import { useSelector } from 'react-redux'
import { FormText } from '../../components'

import { loadingSelector } from 'src/selectors/loading.selector'
import { actionTypes } from 'src/actions/clients.actions'

import IconSearch from 'src/assets/v2/icon-search2.svg'
import IconImg from '../buttons/IconImg'
import { valNit } from 'src/selectors/utilities.selector'

const InfoNit = ({
  nit,
  label = 'NIT',
  onChange,
  onClick,
  disabled,
  required = true,
  noValidate,
  ...props
}) => {
  const loadingNit = useSelector(state =>
    loadingSelector([actionTypes.GET_NIT_INFO])(state),
  )

  return (
    <FormText
      required={required}
      label={label}
      id={'nit'}
      placeholder={'CF'}
      value={nit}
      disabled={disabled || loadingNit}
      error={!noValidate && nit && !valNit(nit) ? 'NIT invalido' : ''}
      onChange={onChange}
      {...props}
      append={
        onClick && (
          <IconImg
            style={{ margin: null }}
            icon={IconSearch}
            loading={loadingNit}
            placement={'top'}
            tooltip={'Buscar NIT'}
            onClick={onClick}
          />
        )
      }
    />
  )
}

export default InfoNit
