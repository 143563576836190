import { $http } from './http'

class AuthorizationService {
  getAuthByState = async state => {
    const response = await $http.get(`/authorization/${state}`)
    return response.data
  }

  setAuth = async () => {
    const response = await $http.post(`/authorization`)
    return response.data
  }

  acceptAuth = async id => {
    const response = await $http.put(`/authorization/accept/${id}`)
    return response.data
  }

  rejectAuth = async id => {
    const response = await $http.put(`/authorization/reject/${id}`)
    return response.data
  }

  getActiveAuth = async () => {
    const response = await $http.get(`/authorization/active`)
    return response.data
  }

  getOAuthURL = async () => {
    const response = await $http.get('/docs/url/oauth')
    return response.data
  }

  createAndStoreCredential = async (state, code) => {
    const response = await $http.post('/docs/register', {}, { params: { state, code } })
    return response.data
  }
}

export default new AuthorizationService()
