import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormText, Icon, NumberField } from 'src/components'

import {
  selectIdentificationType,
  selectIdentificationTypes,
} from 'src/selectors/company.selector'
import { valNit } from 'src/selectors/utilities.selector'
import useDebounce from 'src/hooks/useDebounce'
import { getInfoCUI, getInfoNIT } from 'src/actions/clients.actions'
import { selectIdentificationInfo } from 'src/selectors/clients.selector'
import { actionTypes as clientActions } from 'src/actions/clients.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import { showAlert } from 'src/actions/alert.actions'
import {
  handlerError,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'
import { faIdCard } from '@fortawesome/free-solid-svg-icons'
import { identificationTypesEnum } from 'src/enums/identificationTypes'
import { globalReset } from 'src/actions/global.actions'

interface IProps {
  value: string | number
  onChange: (value: string | number) => void
  onSearch?: (value: unknown, identifications: unknown) => void
  required?: boolean
  disabled?: boolean
  noValidate?: boolean
  identificationTypeId: number
  options?: unknown[]
  onFound?: (value: unknown) => void
}

interface IdentificationPattern {
  id?: number
  min?: number
  max?: number
  type?: 'number' | 'text'
  validator?: (value: string | number) => boolean
  searchAction?: string
  searchFunction?: (value: unknown, signal: AbortSignal) => void
}

const identificationsPatterns: IdentificationPattern[] = [
  {
    id: identificationTypesEnum.NIT_GT,
    type: 'text',
    validator: valNit,
    searchAction: clientActions.GET_NIT_INFO,
    searchFunction: getInfoNIT,
  },
  {
    id: identificationTypesEnum.CUI,
    type: 'number',
    min: 13,
    max: 13,
    searchAction: clientActions.GET_CUI_INFO,
    searchFunction: getInfoCUI,
  },
  {
    id: identificationTypesEnum.DUI,
    type: 'text',
    min: 10,
    max: 10,
  },
  {
    id: identificationTypesEnum.ID_EXTRANJERO,
    type: 'text',
    min: 3,
    max: 18,
  },
]

/**
 * Identification field component
 * @param {string | number} value identification value
 * @param {function} onChange callback function to call every time input changes
 * @param {function} onSearch callback function to call every time info is queried to the server
 * @param {boolean} required if true, value is required
 * @param {boolean} disabled if true, input is disabled
 * @param {boolean} noValidate if true, info is not validated
 * @param {number} identificationTypeId identification id
 * @param {object[]} options List of object with nit value
 * @param {function} onFound Get existing option by nit
 * @param props
 */
const IdentificationField = ({
  value,
  onChange,
  onSearch,
  required,
  disabled,
  noValidate,
  identificationTypeId,
  options,
  onFound,
  ...props
}: IProps) => {
  const dispatch = useDispatch()
  const abortControllerRef = useRef(new AbortController())

  const [isValid, setIsValid] = useState(true)
  const [actions, setActions] = useState({ search: false })

  const pattern = identificationsPatterns.find(x => x.id === identificationTypeId) || {}
  const identificationTypes = useSelector(selectIdentificationTypes)

  const identificationType: IdentificationType = useSelector(state =>
    selectIdentificationType(state, identificationTypeId),
  )

  const identificationInfo = useSelector(state =>
    selectIdentificationInfo(state, identificationTypeId),
  )
  const loadingIdentificationInfo = useSelector(state =>
    loadingSelector([pattern.searchAction])(state),
  )
  const hasErrorIdentificationInfo = useSelector(state =>
    hasErrorsSelector([pattern.searchAction])(state),
  )
  const errorIdentificationInfo = useSelector(state =>
    singleErrorSelector([pattern.searchAction])(state),
  )

  const searchByIdentificationCallback = () => {
    if (loadingIdentificationInfo) {
      if (actions.search) abortControllerRef.current.abort()
      setActions({ ...actions, search: true })
    } else if (actions.search) {
      setActions({ ...actions, search: false })

      if (errorIdentificationInfo.message === 'canceled') {
        abortControllerRef.current = new AbortController()
        return dispatch(globalReset())
      }
      if (hasErrorIdentificationInfo)
        return dispatch(
          showAlert({
            ...handlerError(
              `No se pudo obtener la información con el ${identificationType.name} indicado`,
            ),
          }),
        )

      if (identificationInfo.clientInfo?.locked)
        return dispatch(
          showAlert({
            title: `El cliente ${identificationInfo.clientName} no tiene autorización de ventas`,
            text: `Razón: ${
              identificationInfo.clientInfo?.lockMessage || 'Sin razón'
            }\nContáctese con el administrador`,
            type: 'error',
            confirmButtonText: 'Cerrar',
            confirmButtonColor: '#B35796',
          }),
        )

      const identifications = {}
      identificationTypes.forEach(
        identificationType =>
          (identifications[identificationType.id] = {
            identificationTypeId: identificationType.id,
            value: '',
          }),
      )
      identifications[identificationTypeId] = {
        identificationTypeId,
        value: String(value),
      }
      identificationInfo.clientInfo?.identifications?.forEach(identification => {
        identifications[identification.identificationTypeId] = identification
      })

      onSearch?.(identificationInfo, identifications)
    }
  }

  // @ts-expect-error
  useEffect(searchByIdentificationCallback, [loadingIdentificationInfo])

  const dataType = pattern.type || 'text'

  const isEmpty = !value

  const debouncedSearch = useDebounce((value, isValid) => {
    if (!isValid) return

    if (options && onFound) {
      // @ts-expect-error
      const option = options.find(option => option.nit === value)
      if (option) return onFound(option)
    }

    if (pattern.searchFunction)
      dispatch(pattern.searchFunction(value, abortControllerRef.current.signal))
  }, 2000)

  const handleChange = (newValue: string | number) => {
    const isValid =
      noValidate ||
      (isEmpty && !required) ||
      (!identificationType.pattern && !pattern.validator) ||
      Boolean(String(newValue).match(identificationType.pattern)) ||
      pattern.validator?.(newValue)

    onChange(newValue)
    setIsValid(isValid)
    if (actions.search) searchByIdentificationCallback()
    debouncedSearch(newValue, isValid)
  }

  return dataType === 'number' ? (
    <NumberField
      label={identificationType.name}
      info={identificationType.description}
      value={value == null || value === '' ? undefined : Number(value)}
      onValueChange={handleChange}
      error={
        isValid
          ? ''
          : identificationType.patternDescription ||
            `El ${identificationType.name} no es válido`
      }
      min={0}
      minLength={!isEmpty && pattern.min}
      maxLength={!isEmpty && pattern.max}
      containerStyle={{ margin: '1rem auto 10px' }}
      loading={loadingIdentificationInfo}
      append={<Icon icon={faIdCard} spin={loadingIdentificationInfo} />}
      appendBefore
      disabled={disabled}
      {...props}
    />
  ) : (
    <FormText
      label={identificationType.name}
      info={identificationType.description}
      value={String(value || '')}
      changeValue={handleChange}
      error={
        isValid
          ? ''
          : identificationType.patternDescription ||
            `El ${identificationType.name} no es válido`
      }
      min={!isEmpty && pattern.min}
      max={!isEmpty && pattern.max}
      prepend={<Icon icon={faIdCard} spin={loadingIdentificationInfo} />}
      disabled={disabled}
      igStyle={{ margin: 0 }}
      {...props}
    />
  )
}

export default IdentificationField
