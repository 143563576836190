import { $http } from './http'

class PolygonsService {
  getAllPolygons = async () => {
    const response = await $http.get(`/polygons`)
    return response.data
  }

  getSinglePolygons = async id => {
    const response = await $http.get(`/polygons/${id}`)
    return response.data
  }

  createPolygon = async request => {
    const response = await $http.post(`/polygons`, request)
    return response.data
  }

  updatePolygon = async (id, request) => {
    const response = await $http.put(`/polygons/${id}`, request)
    return response.data
  }

  getPolygonCategories = async () => {
    const response = await $http.get('/polygons/categories')
    return response.data
  }

  getSubscriptionsPolygons = async () => {
    const response = await $http.get(`/polygons/subscriptions`)
    return response.data
  }

  getPolygonByCategory = async categoryId => {
    const response = await $http.get(`/polygons/category/${categoryId}`)
    return response.data
  }

  onUpdatePolygonSetting = async (polygonId, request) => {
    const response = await $http.put(`/polygons/setting/${polygonId}`, request)
    return response.data
  }

  changeHierarchy = async polygons => {
    const response = await $http.put(`/polygons/hierarchy`, polygons)
    return response.data
  }

  changeShipping = async (polygonId, shipping) => {
    const response = await $http.put(`/polygons/shipping/${polygonId}/${shipping}`)
    return response.data
  }
}

export default new PolygonsService()
