import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Row, Col } from 'react-bootstrap'
import { Title, Card, Button } from 'src/components'
import cardPlaceHolder from 'src/assets/v2/card-placeholder.png'

import { getCards } from 'src/actions/account.actions'
import { getInvoices } from 'src/actions/subscriptions.actions'

import { selectSetCurrentCompany } from 'src/selectors/company.selector'

import {
  actionTypes as actionO,
  getOrderAccount,
  payOrderAccount,
} from 'src/actions/orders.actions'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerSuccess,
  handlerError,
  singleErrorSelector,
  hasErrorsSelector,
} from 'src/selectors/error.selector'

import { setModalManageCards } from 'src/actions/utilities.actions'
import { ModalPaymentAddons } from 'src/content/Documents/Account/ModalPaymentAddons'
import { showAlert } from 'src/actions/alert.actions'

import Subscription from '../../Subscription/Subscription'
import HistoryPlan from './HistoryPlan'

/**
 * Componente para mostrar el estado de cuenta de la empresa.
 * @component
 */
export const PaymentAccount = () => {
  const dispatch = useDispatch()

  const company = useSelector(selectSetCurrentCompany)

  const [actions, setActions] = useState({ cardAction: false, payAction: false })
  const [payAddon, setPayAddon] = useState({
    id: null,
    amount: 0,
    show: false,
  })

  const loadingPay = useSelector(state =>
    loadingSelector([actionO.PAY_ORDER_ACCOUNT])(state),
  )
  const hasErrorPay = useSelector(state =>
    hasErrorsSelector([actionO.PAY_ORDER_ACCOUNT])(state),
  )
  const errorPay = useSelector(state =>
    singleErrorSelector([actionO.PAY_ORDER_ACCOUNT])(state),
  )

  useEffect(() => {
    dispatch(getCards())
    dispatch(getInvoices())
  }, [])

  useEffect(() => {
    if (loadingPay) setActions({ ...actions, payAction: true })
    else if (actions.payAction) {
      setActions({ ...actions, payAction: false })
      if (hasErrorPay)
        dispatch(
          showAlert({
            ...handlerError(errorPay.message),
          }),
        )
      else
        dispatch(
          showAlert({
            ...handlerSuccess('Pago realizado correctamente'),
            onConfirm: () => {
              setPayAddon({
                id: null,
                amount: 0,
                show: false,
              })
              dispatch(getOrderAccount(company.clientId))
            },
          }),
        )
    }
  }, [loadingPay])

  return (
    <div>
      <div className="space-between">
        <Title
          title={'Estado de cuenta'}
          // action
          // actionTitle={'Gestionar tarjetas'}
          // icon={cardPlaceHolder}
          // onClick={() => {
          //   dispatch(setModalManageCards({ show: true }))
          // }}
        />
        <div>
          {window.innerWidth > 650 && (
            <Button
              className={'mt-3'}
              color={'secondary'}
              img={cardPlaceHolder}
              onClick={() => dispatch(setModalManageCards({ show: true }))}>
              Gestionar tarjetas
            </Button>
          )}
        </div>
      </div>

      <Row>
        <Col xl={5} lg={5} md={5} sm={12}>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12}>
              <Card
                title={'Cobros '}
                white
                button={
                  window.innerWidth <= 650 && (
                    <Button
                      color={'secondary'}
                      img={cardPlaceHolder}
                      onClick={() => dispatch(setModalManageCards({ show: true }))}>
                      Gestionar tarjetas
                    </Button>
                  )
                }>
                <HistoryPlan
                  show={company.clientId !== undefined}
                  id={company.clientId}
                  setPayAddon={item => setPayAddon(item)}
                />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xl={7} lg={7} md={7} sm={12}>
          <Card title={'Suscripciones'} white>
            {Object.entries(company).length > 0 && (
              <Subscription
                mini
                queryParams={{
                  type: 2,
                  id: company.id,
                }}
                own
                noAction
              />
            )}
          </Card>
        </Col>
      </Row>

      <ModalPaymentAddons
        show={payAddon.show}
        onHide={() =>
          setPayAddon({
            id: null,
            amount: 0,
            show: false,
          })
        }
        onClick={() => dispatch(payOrderAccount(payAddon.id))}
        amount={payAddon.amount}
      />
    </div>
  )
}
