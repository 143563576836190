import './Plans.scss'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'

import { Col, ProgressBar, Row, Modal } from 'react-bootstrap'
import { Icon, Button, FormText } from 'src/components'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import { actionTypes, updatePlanCompany } from 'src/actions/mentor.actions'
import { selectCompanyAddonEntity } from 'src/selectors/mentor.selector'

import {
  actions as actionP,
  getActivePlan,
  getCompanyActivePlan,
  getPlans,
} from 'src/actions/plans.actions'
import {
  selectActivePlans,
  selectCompanyActivePlans,
  selectPlans,
} from 'src/selectors/plans.selector'

import {
  handlerError,
  handlerInfoWithButtons,
  handlerSuccess,
  hasErrors,
} from 'src/selectors/error.selector'
import { loadingSelector } from 'src/selectors/loading.selector'

import { getGroups } from 'src/actions/modules.actions'
import { showAlert } from 'src/actions/alert.actions'

import UpdateAddonsErrorModal from 'src/content/Admin/Mentor/Addon/UpdateAddonsErrorModal'
import PlanItem from './PlanItem'

/**
 * Shows the company's current plan and plans you can upgrade to
 * @component
 * @param {boolean} show initialize the component
 * @param {number} companyId Company ID displaying
 * @param {string} name Company name
 * @param {boolean} isDemo Indicates if the company is Demo
 * @param {function} onHide Close the component
 * */
const Plans = ({ show, companyId, name, isDemo, onHide }) => {
  const dispatch = useDispatch()

  const plans = useSelector(selectPlans)
  const plan = useSelector(companyId ? selectCompanyActivePlans : selectActivePlans)
  const loading = useSelector(state => loadingSelector([actionP.GET_PLANS])(state))

  const companyAddonsEntityResponse = useSelector(selectCompanyAddonEntity)
  const changeLoading = useSelector(state =>
    loadingSelector([actionTypes.UPDATE_MENTOR_PLAN])(state),
  )
  const changeHasError = useSelector(state =>
    hasErrors([actionTypes.UPDATE_MENTOR_PLAN])(state),
  )

  const [open, setOpen] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false)

  const [, setModalDlc] = useState({ show: false, items: [] })
  const [action, setAction] = useState({
    change: false,
    dlc: false,
  })
  const [openPrice, setOpenPrice] = useState({ show: false, price: 0, item: {} })

  useEffect(() => {
    if (!show) return
    setOpen(true)

    if (plans.length === 0) dispatch(getPlans())
    if (companyId) dispatch(getCompanyActivePlan(companyId))
  }, [show])

  useEffect(() => {
    if (changeLoading) setAction({ ...action, change: true })
    else if (action.change) {
      setAction({ ...action, change: false })

      if (changeHasError)
        dispatch(
          showAlert({
            ...handlerError(changeHasError.message),
          }),
        )
      else {
        const alert = !companyAddonsEntityResponse.principal
          ? {
              ...handlerError(
                'No es posible actualizar el plan ya que actualmente posee más ítems de los permitidos.',
              ),
              onConfirm: () => setOpenErrorModal(true),
            }
          : {
              ...handlerSuccess('Cambio de plan exitoso'),
              onConfirm: () => {
                dispatch(getGroups(1000))
                if (companyId) dispatch(getCompanyActivePlan(companyId))
                else dispatch(getActivePlan())
                setOpenPrice({ ...openPrice, show: false })
              },
            }
        dispatch(showAlert(alert))
      }
    }
  }, [changeLoading])

  const change = item =>
    !changeLoading ? (
      <div
        className={'account-action'}
        onClick={() => {
          dispatch(
            showAlert({
              ...handlerInfoWithButtons(
                'Actualizar Plan',
                isDemo
                  ? '¿Desea actualizar el plan actual? Esta acción la puede repetir nuevamente sin costo alguno mientras la cuenta siga en estado DEMO.'
                  : '¿Desea actualizar el plan actual? Se mantendrá la fecha de cobro y se realizará un cobro parcial por el tiempo utilizado del plan anterior y el tiempo utilizado por el nuevo plan.',
              ),
              onConfirm: () => {
                if (isDemo) dispatch(updatePlanCompany(companyId, item.id))
                else setOpenPrice({ show: true, price: 0, item })
              },
            }),
          )
        }}>
        Activar plan
      </div>
    ) : (
      <div>
        <Icon spin={changeLoading} size={'1_5x'} icon={faSpinner} />
      </div>
    )

  const renderPlan = (p, index, title, info) => (
    <Row key={index}>
      <Col>
        <PlanItem
          item={p}
          info={info}
          onUpgrade={modal => {
            setModalDlc(modal)
          }}
          button={title}
          isActive={!isDemo && plan.id === p.id}
        />
      </Col>
    </Row>
  )

  const onClose = update => {
    setOpen(false)
    onHide(update)
  }

  return (
    <div>
      <Modal show={open && !openPrice.show} size={'xl'} centered onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {loading && (
              <Row>
                <Col>
                  <div className={'pb-custom'}>
                    <ProgressBar
                      label="Cargando"
                      animated
                      now={100}
                      style={{ marginBottom: 10 }}
                    />
                  </div>
                </Col>
              </Row>
            )}
            {plan && plan.id && (
              <>
                <h4 className={'plan-text'}>
                  {isDemo ? 'Plan de Prueba' : 'Plan Activo'}
                </h4>
                {renderPlan(plan, null, undefined)}
              </>
            )}
            <h4 className={'plan-text'}>{plan && plan.id ? 'Otros Planes' : 'Planes'}</h4>
            {plans
              .filter(p => p.id !== plan.id)
              .map((p, index) => renderPlan(p, index, change(p)))}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={openPrice.show}
        size={'sm'}
        centered
        onHide={() => setOpenPrice({ ...openPrice, show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Precio: {openPrice?.item?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <FormText
                label={'Costo del Plan'}
                value={openPrice.price}
                prepend={'Q'}
                type={'number'}
                required
                changeValue={v => setOpenPrice({ ...openPrice, price: v })}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              loading={changeLoading}
              disabled={!openPrice.price || openPrice.price <= 0}
              onClick={() =>
                dispatch(updatePlanCompany(companyId, openPrice.item.id, openPrice.price))
              }>
              Actualizar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <UpdateAddonsErrorModal
        show={openErrorModal}
        items={companyAddonsEntityResponse?.items}
        onHide={() => setOpenErrorModal(false)}
      />
    </div>
  )
}

export default Plans
