import { connect } from 'react-redux'
import './Expenses.scss'

import {
  actionTypes,
  createExpense,
  getExpenses,
  getExpenseType,
  getExpensesAdmin,
  getExpensesProducts,
  creditExpense,
} from '../../actions/expenses.actions'
import {
  selectGetExpenses,
  selectGetExpenseTypes,
  selectExpenseTypes,
  selectGetExpensesProducts,
} from '../../selectors/expenses.selector'

import {
  actionTypes as typesM,
  getUsersChildrenByModule,
} from '../../actions/modules.actions'
import {
  getPermission,
  selectCurrentUserRoles,
  selectUsersToSelect,
} from '../../selectors/modules.selector'

import { selectCurrentModule } from '../../selectors/user.selector'

import { loadingSelector } from '../../selectors/loading.selector'

import Purchase from '../Purchase/Purchase'

const mapStateToProps = state => ({
  expenseType: selectGetExpenseTypes(state),
  types: selectExpenseTypes(state),
  expensesIsLoading: loadingSelector([actionTypes.GET_EXPENSES])(state),
  createIsLoading: loadingSelector([actionTypes.CREATE_EXPENSE])(state),
  expenses: selectGetExpenses(state),
  products: selectGetExpensesProducts(state),
  getPermission: id => getPermission(state, id),
  roles: selectCurrentUserRoles(state),
  loadingM: loadingSelector([typesM.GET_CURRENT_USER_ROLES])(state),
  loadingP: loadingSelector([actionTypes.GET_EXPENSES_PRODUCTS])(state),
  loadingC: loadingSelector([actionTypes.CREDIT_EXPENSE])(state),
  module: selectCurrentModule(state),
  users: selectUsersToSelect(state),
})

const mapDispatchToProps = dispatch => ({
  getUsers: module => dispatch(getUsersChildrenByModule(module, true)),
  getExpenses: () => dispatch(getExpenses()),
  getExpensesProducts: () => dispatch(getExpensesProducts()),
  getExpensesAdmin: () => dispatch(getExpensesAdmin()),
  getExpenseTypes: () => dispatch(getExpenseType()),
  creditExpense: (expense, amount, type, list) =>
    dispatch(creditExpense(expense, amount, type, list)),
  createExpense: (number, series, nit, description, total, expenseType, products) =>
    dispatch(
      createExpense(number, series, nit, description, total, expenseType, products),
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(Purchase)
