import { actionTypes } from '../actions/modules.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  subModulesApp: [],
  rolesApp: {},
  users: [],
  roles: [],
  rolesByUser: [],
  dataPermissions: null,
  module: null,
  usersByCompanyAndRole: [],
  usersByCompany: [],
  currentCompanyUsers: [],
  currentUserRoles: [],
  usersByRole: [],
  loadingToggle: false,
  users_roles: [],
  adminModules: [],
  editedModule: {},
  usersSubmodule: [],
  modules: [],
  group: {},
  groups: [],
  moduleGroups: [],
  rolesSelect: [],
}

const ModulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_MODULES):
      return { ...state, modules: extract(action.modules, []) }
    case successState(actionTypes.GET_GROUP):
      return { ...state, group: extract(action.group, {}) }
    case successState(actionTypes.GET_GROUPS):
      return { ...state, groups: extract(action.group, {}) }
    case actionTypes.GET_ROLES_FOR_SELECT:
      console.log(action.response)
      return { ...state, rolesSelect: extract(action.response, []) }
    case actionTypes.LOADING_TOGGLE:
      return {
        ...state,
        loadingToggle: action.value,
      }
    case actionTypes.GET_CURRENT_USER_ROLES_SUCCESS:
      return {
        ...state,
        currentUserRoles: action.roles,
      }
    case actionTypes.SET_MODULE_ID:
      return {
        ...state,
        module: action.module,
      }
    case actionTypes.PUT_ROLE_BY_USER_SUCCESS:
      return {
        ...state,
        dataPermissions: action.data,
      }
    case actionTypes.DELETE_ROLE_BY_USER_SUCCESS:
      return {
        ...state,
        dataPermissions: action.data,
      }
    case actionTypes.GET_ROLES_BY_USER_SUCCESS:
      return {
        ...state,
        rolesByUser: action.roles,
      }
    case actionTypes.GET_ALL_USERS_ROLES_SUCCESS:
      return {
        ...state,
        users_roles: action.users_roles,
      }
    case actionTypes.GET_ROLES_SUCCESS:
      return {
        ...state,
        rolesByUser: action.roles,
      }
    case successState(actionTypes.GET_USERS_CHILDREN):
      return {
        ...state,
        users: action.users,
      }
    case actionTypes.GET_USERS_BY_COMPANY_AND_ROLE_SUCCESS:
      return {
        ...state,
        usersByCompanyAndRole: action.users,
      }
    case actionTypes.GET_USERS_BY_COMPANY_SUCCESS:
      return {
        ...state,
        usersByCompany: action.users,
      }
    case actionTypes.GET_CURRENT_COMPANY_USERS_SUCCESS:
      return {
        ...state,
        currentCompanyUsers: action.users,
      }
    case actionTypes.GET_SUBMODULES_SUCCESS:
      return {
        ...state,
        subModulesApp: action.subModules,
      }
    case successState(actionTypes.GET_MODULE_GROUPS):
      return {
        ...state,
        moduleGroups: action.groups,
      }
    case actionTypes.GET_ROLES_BY_SUBMODULE_SUCCESS:
      return {
        ...state,
        rolesApp: action.roles,
      }
    case successState(actionTypes.GET_USERS_BY_ROLE):
      return {
        ...state,
        usersByRole: extract(action.users, []),
      }
    case successState(actionTypes.ADMIN_MODULES):
      return {
        ...state,
        adminModules: extract(action.adminModules, []),
      }
    case successState(actionTypes.TOOGLE_MODULE):
      return {
        ...state,
        editedModule: extract(action.module, []),
      }
    case successState(actionTypes.GET_USERS_SUBMODULE):
      return {
        ...state,
        usersSubmodule: extract(action.usersSubmodule, []),
      }
    case successState(actionTypes.SET_FAVORITE):
      return {
        ...state,
        message: extract(action.message),
      }
    case successState(actionTypes.REVOKE_FAVORITE):
      return {
        ...state,
        message: extract(action.message),
      }
    default:
      return state ? state : initialState
  }
}

export default ModulesReducer
