import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Row, Col, Modal } from 'react-bootstrap'
import {
  faArrowsAltH,
  faArrowsAltV,
  faBox,
  faEdit,
  faExpandAlt,
  faSave,
} from '@fortawesome/free-solid-svg-icons'
import Alert from 'sweetalert-react'

import Icon from 'src/components/buttons/IconButton'
import Button from 'src/components/buttons/Button'
import FormText from 'src/components/inputs/FormTextField/FormText'
import Select from 'src/components/inputs/Select/CustomSelect'

import {
  types,
  findById,
  setInfo,
  create,
  update,
  getTypes,
} from 'src/actions/parcel.actions'
import { selectParcel, selectParcelTypes } from 'src/selectors/parcel.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  hasErrorsSelector,
  singleErrorSelector,
  handlerSuccess,
  handlerError,
} from 'src/selectors/error.selector'

import { selectCurrentCompany, selectCurrentModule } from 'src/selectors/user.selector'

const positions = [
  { value: 1, label: 'Todas' },
  { value: 2, label: 'Solo parado' },
  { value: 3, label: 'Solo acostado' },
]

const ParcelCEV = props => {
  const dispatch = useDispatch()

  const parcel = useSelector(selectParcel)
  const loading = useSelector(state => loadingSelector([types.GET_SINGLE_PARCEL])(state))
  const hasError = useSelector(state =>
    hasErrorsSelector([types.GET_SINGLE_PARCEL])(state),
  )
  const error = useSelector(state =>
    singleErrorSelector([types.GET_SINGLE_PARCEL])(state),
  )

  const loadingA = useSelector(state =>
    loadingSelector([types.ON_CREATE_OR_UPDATE])(state),
  )
  const hasErrorA = useSelector(state =>
    hasErrorsSelector([types.ON_CREATE_OR_UPDATE])(state),
  )
  const errorA = useSelector(state =>
    singleErrorSelector([types.ON_CREATE_OR_UPDATE])(state),
  )

  const parcelTypes = useSelector(selectParcelTypes)

  const company = useSelector(selectCurrentCompany)
  const module = useSelector(selectCurrentModule)

  const [alert, setAlert] = useState({ title: '' })

  const [show, setShow] = useState(false)
  const [action, setAction] = useState({ action: false, get: false })

  useEffect(() => {
    if (!parcelTypes || parcelTypes.length <= 0) dispatch(getTypes())
  }, [])

  useEffect(() => {
    if (props.show) setShow(props.show)
  }, [props])

  useEffect(() => {
    if (show) {
      if (props.type > 1) {
        dispatch(findById(props.id))
      } else dispatch(setInfo({}))
    }
  }, [show])

  useEffect(() => {
    if (loading) setAction({ ...action, get: true })
    else if (action.get)
      if (hasError)
        setAlert({
          ...handlerError(error.message),
          onConfirm: () => onHide(),
        })
  }, [loading])

  useEffect(() => {
    if (loadingA) setAction({ ...action, action: true })
    else if (action.action) {
      if (hasErrorA)
        setAlert({
          ...handlerError(errorA.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess(
            props.type === 1
              ? 'Paquete creado satisfactoriamente'
              : 'Pquete actualizado satisfactorimante',
          ),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            onHide(true)
          },
        })
    }
  }, [loadingA])

  const onHide = update => {
    if (!loadingA) {
      props.onHide(update)
      setShow(false)
      setAlert({ ...alert, show: false })
      dispatch(setInfo(Object.assign({}, {})))
    }
  }

  const changeValues = ({ target }) => {
    let { name, value, type } = target
    if (type === 'number') {
      if (!value || value <= 0) value = 1
    }
    dispatch(setInfo({ ...parcel, [name]: value }))
  }

  const getType = type => {
    return parcelTypes.find(t => t.value === type)
  }

  const getPosition = position => {
    return positions.find(t => t.value === position)
  }

  return (
    <div>
      <Modal show={show} centered size={'lg'} onHide={() => onHide()}>
        <Modal.Header closeButton>
          <Modal.Title>
            {props.type === 1
              ? 'Creación de paquete'
              : props.type === 2
              ? 'Actualización de paquete '
              : props.type === 3 && 'Paquete: '}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col xl={12} md={12} sm={12}>
              <FormText
                disabled={props.type > 2 || loadingA || loading}
                prepend={<Icon icon={faBox} />}
                value={parcel.name}
                type={'text'}
                label={'Alias'}
                name={'name'}
                placeholder={'pj. Paquete mediano'}
                onChange={changeValues}
                required={props.type !== 3}
              />
            </Col>
            <Col xl={6} md={6} sm={12}>
              <FormText
                disabled={props.type > 2 || loadingA || loading}
                prepend={<Icon icon={faArrowsAltV} />}
                value={parcel.high}
                type={'number'}
                label={'Alto'}
                name={'high'}
                placeholder={'pj. 20'}
                append={'cm'}
                onChange={changeValues}
                required={props.type !== 3}
              />
            </Col>
            <Col xl={6} md={6} sm={12}>
              <FormText
                disabled={props.type > 2 || loadingA || loading}
                prepend={<Icon icon={faExpandAlt} />}
                value={parcel.width}
                type={'number'}
                label={'Ancho'}
                name={'width'}
                placeholder={'pj. 20'}
                append={'cm'}
                onChange={changeValues}
                required={props.type !== 3}
              />
            </Col>
            <Col xl={12} md={12} sm={12}>
              <FormText
                disabled={props.type > 2 || loadingA || loading}
                prepend={<Icon icon={faArrowsAltH} />}
                value={parcel.length}
                type={'number'}
                label={'Profundidad'}
                name={'length'}
                placeholder={'pj. 20'}
                append={'cm'}
                onChange={changeValues}
                required={props.type !== 3}
              />
            </Col>
            <Col xl={6} md={6} sm={12}>
              <Select
                disabled={props.type > 2 || loadingA || loading}
                value={getType(parcel.type)}
                label={'Tipo de paquete'}
                options={parcelTypes}
                onChange={value => dispatch(setInfo({ ...parcel, type: value.value }))}
                required={props.type !== 3}
              />
            </Col>
            {company === 1 && module === 11000 && parcel.type === 2 && (
              <Col xl={6} md={6} sm={12}>
                <FormText
                  disabled={props.type > 2 || loadingA || loading}
                  prependMoneySymbol
                  value={parcel.amount}
                  type={'number'}
                  label={'Pago por paquete kolo'}
                  name={'amount'}
                  onChange={changeValues}
                  required={props.type !== 3}
                />
              </Col>
            )}
            {/*
                <Col xl={6} md={6} sm={12}>
                    <Select
                        disabled={props.type > 2 || loadingA || loading}
                        value={getPosition(parcel.position)}
                        label={'Posición'}
                        options={positions}
                        onChange={(value) => dispatch(setInfo({...parcel, position: value.value}))}
                        required={props.type !== 3}
                    />
                </Col>
                */}
          </Row>
        </Modal.Body>

        {(props.type === 1 || props.type === 2) && (
          <Modal.Footer>
            <Row className={'container-buttons'}>
              <Button
                disabled={
                  (parcel.type === 2 &&
                    company === 1 &&
                    module === 11000 &&
                    !parcel.amount) ||
                  !parcel.name ||
                  !parcel.length ||
                  !parcel.width ||
                  !parcel.high ||
                  !parcel.type
                }
                loading={loadingA}
                variant={'info'}
                icon={props.type === 1 ? faSave : faEdit}
                onClick={() => {
                  const request = Object.assign(
                    {},
                    {
                      ...parcel,
                    },
                  )

                  switch (props.type) {
                    case 1:
                      dispatch(create(request))
                      break
                    case 2:
                      dispatch(update(request, parcel.id))
                      break
                  }
                }}
              >
                {props.type === 1 ? 'Crear' : 'Actualizar'}
              </Button>
            </Row>
          </Modal.Footer>
        )}
      </Modal>

      <Alert {...alert} />
    </div>
  )
}
export default ParcelCEV
