import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Row, Col } from 'react-bootstrap'
import SweetAlert from 'sweetalert-react'

import FormTextField from 'src/components/inputs/FormTextField/FormTextField'
import Button from 'src/components/buttons/Button'

import { actionTypes, createDisbursement } from 'src/actions/disbursements.actions'
import {
  actionTypes as bankActions,
  getAccounts,
  getBanks,
} from 'src/actions/banks.actions'
import {
  selectDisbursements,
  selectDisbursementsBalance,
  selectDisbursementsBalanceCotizap,
  selectDisbursementsBalanceDetails,
} from 'src/selectors/disbursements.selector'

import { loadingSelector } from 'src/selectors/loading.selector'

import {
  hasErrorsSelector,
  singleErrorSelector,
  handlerSuccess,
  handlerError,
} from 'src/selectors/error.selector'
import { selectGetAccountsForSelect, selectGetBanks } from 'src/selectors/banks.selector'
import CustomSelect from 'src/components/inputs/Select/CustomSelect'

class NewDisbursement extends Component {
  state = {
    amount: null,
    alertForm: {
      title: '',
      text: '',
      type: 'success',
      show: false,
    },
    account: { value: 0, label: '--Seleccione una cuenta--' },
  }

  handleInputChange = event => {
    const { name, value } = event.target
    console.log(value)
    this.setState({ [name]: value })
  }

  handleCurrencyInputChange = (event, maskValue, value) => {
    const { name } = event.target
    this.setState({ [name]: value })
  }

  checkValue = val => {
    const { amount } = this.state
    if (amount === null || (amount > val && amount !== 0)) {
      return true
    }
    return false
  }

  makeRequest = () => {
    const { amount, account } = this.state
    if (amount >= 1 || !account.value) this.props.createNewRequest(amount, account.value)
    else {
      const { alertForm } = this.state
      alertForm.title = 'Solicitud invalida'
      alertForm.text = `Revise los datos de la solicitud.`
      alertForm.type = 'error'
      alertForm.show = true
      this.setState({ alertForm: alertForm })
    }
  }
  UNSAFE_componentWillReceiveProps(next, nextContext) {
    const { createIsLoading, hasError } = this.props
    const { error } = next
    if (createIsLoading && !next.createIsLoading) {
      let { alertForm } = this.state

      if (!hasError && next.hasError) {
        alertForm = handlerError(error.message || 'Ha ocurrido un error inesperado')
      } else {
        alertForm = handlerSuccess('La solicitude ha sigo ingresada')
      }
      this.setState({ alertForm })
    }
  }

  ContractedCompaniesChanged = value => {
    this.setState({ account: value })
  }

  render() {
    const { amount, alertForm, account } = this.state
    const { bankAccounts } = this.props
    return (
      <div>
        <Row>
          <Col>
            <FormTextField
              label={'Cantidad'}
              type={'text'}
              id={'amount'}
              placeholder={'Q. 0.00'}
              name={'amount'}
              value={amount}
              onChange={this.handleCurrencyInputChange}
              error={''}
              currency
              required
            />
          </Col>
          <Col>
            <CustomSelect
              label={'Banco'}
              options={bankAccounts}
              value={account}
              onChange={this.ContractedCompaniesChanged}
              required
            />
          </Col>
        </Row>
        <Row>
          <Button onClick={() => this.makeRequest()}>Crear solicitud </Button>
        </Row>
        <SweetAlert
          show={alertForm.show}
          title={alertForm.title}
          text={alertForm.text}
          type={alertForm.type}
          onConfirm={() => {
            const { alertForm } = this.state
            alertForm.show = false
            this.setState({ alertForm: alertForm })
            this.props.closeThis()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  createIsLoading: loadingSelector([actionTypes.CREATE_DISBURSEMENT])(state),
  hasError: hasErrorsSelector([actionTypes.CREATE_DISBURSEMENT])(state),
  error: singleErrorSelector([actionTypes.CREATE_DISBURSEMENT])(state),
})

const mapDispatchToProps = dispatch => ({
  createNewRequest: (amount, bank) => dispatch(createDisbursement(amount, bank)),
})
export default connect(mapStateToProps, mapDispatchToProps)(NewDisbursement)
