import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Row, Col } from 'react-bootstrap'
import { Td, Tr } from 'react-super-responsive-table'

import {
  Card,
  TableV2,
  Dropdown,
  CustomReference,
  Select,
  Title,
  Action,
  ModalTable,
  Button,
  CustomSummary,
} from 'src/components'
import InventoryReport from 'src/components/inventory/InventoryReport'

import {
  types,
  getChangeRequests,
  onUpdateInventoryRequest,
  getReportRequest,
  getReportSum,
  nullifyInventoryRequest,
} from 'src/actions/inventory.actions'
import {
  selectInventoryRequests,
  selectInventoryReportRequest,
  selectInventoryReport,
  selectTotalInventoryRequests,
} from 'src/selectors/inventory.selector'

import {
  selectCurrentUserRoles,
  isAllowed,
  selectUsers,
} from 'src/selectors/modules.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerSuccess,
  handlerError,
  singleErrorSelector,
  hasErrorsSelector,
  hasErrors,
} from 'src/selectors/error.selector'

import { formatNumberWithCommas } from 'src/utils/formatters'
import { useLocation } from 'react-router-dom'
import { toMoney } from 'src/utils/utilities'
import { selectCurrentCurrency } from 'src/selectors/currencies.selector'
import { selectCurrentUser } from 'src/selectors/user.selector'
import { selectWarehouseLite } from 'src/selectors/warehouse.selector'
import { getWarehouseLite } from 'src/actions/warehouse.actions'
import { showAlert } from 'src/actions/alert.actions'
import { getUsersChildrenByModule } from 'src/actions/modules.actions'
import { increasePermissions } from 'src/enums/permissions'

const defaultValue = { value: null, label: '- Todos -' }

const states = [
  defaultValue,
  { value: 1, label: 'Ingresada' },
  { value: 2, label: 'Cancelada' },
  { value: 3, label: 'Aceptada' },
]

const Waste = () => {
  const dispatch = useDispatch()
  const isWaste = useLocation().pathname.includes('/waste')

  const currentUser = useSelector(selectCurrentUser)
  const roles = useSelector(selectCurrentUserRoles)
  const currentCurrency = useSelector(selectCurrentCurrency)

  const warehouses = useSelector(selectWarehouseLite)

  const requests = useSelector(selectInventoryRequests)
  const totalRequests = useSelector(selectTotalInventoryRequests)
  const report = useSelector(selectInventoryReportRequest)
  const summation = useSelector(selectInventoryReport)
  const users = useSelector(selectUsers)
  const requestsLoading = useSelector(state =>
    loadingSelector([types.GET_CHANGE_REQUESTS])(state),
  )

  const singleRequestLoading = useSelector(state =>
    loadingSelector([types.GET_CHANGE_REQUEST])(state),
  )
  const singleRequestHasError = useSelector(state =>
    hasErrorsSelector([types.GET_CHANGE_REQUEST])(state),
  )
  const singleRequestError = useSelector(state =>
    singleErrorSelector([types.GET_CHANGE_REQUEST])(state),
  )

  const loadingReport = useSelector(state =>
    loadingSelector([types.GET_REPORT_REQUEST])(state),
  )

  const loadingNullify = useSelector(state =>
    loadingSelector([types.NULLIFY_INVENTORY_REQUEST])(state),
  )
  const hasErrorNullify = useSelector(state =>
    hasErrors([types.NULLIFY_INVENTORY_REQUEST])(state),
  )

  const canSeeAllWastes = useSelector(state => isAllowed(state, [12301]))
  const canSeeAllProductions = useSelector(state => isAllowed(state, [12355]))

  const canCreateCreateWaste = useSelector(state => isAllowed(state, [12302]))
  const canCreateProduction = useSelector(state => isAllowed(state, [12356]))

  const canAcceptWaste = useSelector(state => isAllowed(state, [12303]))
  const canAcceptProduction = useSelector(state => isAllowed(state, [12357]))

  const canAcceptOwnWaste = useSelector(state => isAllowed(state, [12305]))
  const canAccessOwnProduction = useSelector(state => isAllowed(state, [12360]))

  const canAcceptArchivedProducts = useSelector(state => isAllowed(state, [7112, 2062]))

  const canSeeMoneyBalances = useSelector(state => isAllowed(state, [12304]))
  const canNullifyProduction = useSelector(state =>
    isAllowed(state, [increasePermissions.nullify]),
  )

  const [initialLoading, setInitialLoading] = useState(true)

  const [action, setAction] = useState({
    waste: false,
    action: false,
    see: false,
    update: false,
    id: null,
    get: false,
    nullify: false,
  })
  const [showSum, setShowSum] = useState(false)

  const [textFilter, setTextFilter] = useState('')
  const [filters, setFilters] = useState({
    state: defaultValue,
    warehouse: defaultValue,
    skip: 0,
    size: 10,
    user: defaultValue,
    auth: defaultValue,
    start: new Date().setHours(0, 0, 0, 0).valueOf(),
    end: new Date().setHours(23, 59, 59, 99).valueOf(),
    search: '',
  })
  const [inventoryReport, setInventoryReport] = useState({ title: '', documentId: null })

  useEffect(() => {
    dispatch(getWarehouseLite())
    if (users.length === 0) dispatch(getUsersChildrenByModule(1000, true))
  }, [])

  useEffect(() => {
    if (!roles || roles.length <= 0) return
    setTextFilter(JSON.stringify(filters))
    setInitialLoading(false)

    setAction({ ...action, waste: isWaste })
  }, [roles])

  useEffect(() => {
    if (textFilter === '' || textFilter === JSON.stringify(filters)) return
    setTextFilter(JSON.stringify(filters))
  }, [filters])

  useEffect(() => {
    if (textFilter !== JSON.stringify(filters)) return
    setUp()
  }, [textFilter])

  useEffect(() => {
    if (singleRequestLoading) setAction({ ...action, action: true })
    else if (action.action) {
      if (singleRequestHasError) {
        dispatch(
          showAlert({
            ...handlerError(singleRequestError.message),
          }),
        )
      } else if (action.update) {
        setUp()
        dispatch(
          showAlert({
            ...handlerSuccess('Solicitud actualizada'),
          }),
        )
      }
      setAction({ ...action, action: false, see: false, update: false, id: null })
    }
  }, [singleRequestLoading])

  useEffect(() => {
    if (loadingNullify) setAction({ ...action, nullify: true })
    else if (action.nullify) {
      setAction({ ...action, nullify: false })
      const alert = hasErrorNullify
        ? {
            ...handlerError(
              hasErrorNullify?.message || 'Ocurrió un error al anular la solicitud',
            ),
          }
        : { ...handlerSuccess('Solicitud anulada con éxito') }
      dispatch(showAlert({ ...alert }))
      setUp()
    }
  }, [loadingNullify])

  const setUp = () => {
    const { user, auth, state, skip, size, start, end, search, warehouse } = filters

    dispatch(
      getChangeRequests({
        all: isWaste ? canSeeAllWastes : canSeeAllProductions,
        sDate: start,
        eDate: end,
        type: isWaste ? 3 : 1,
        userId: user.value,
        authId: auth.value,
        statusId: state.value,
        warehouseId: warehouse.value,
        skip,
        size,
        search,
      }),
    )
    if (isWaste) dispatch(getReportRequest(start, end, user.value, auth.value))
  }

  const handleChangeRadio = name => {
    const { start, end } = filters
    const factor = name === 'factor'
    dispatch(getReportSum(start, end, action.waste ? 3 : 1, factor))
  }

  const createRandomColor = () => {
    return `${parseInt((Math.random() * 240).toString())}, ${parseInt(
      (60 + Math.random() * 240).toString(),
    )}, ${parseInt((80 + Math.random() * 240).toString())}`
  }

  const getChartData = () => {
    const labels = summation.sort((a, b) => b.total - a.total).map(p => p.name)
    const data = summation.sort((a, b) => b.total - a.total).map(p => p.total)
    return {
      labels,
      datasets: [
        {
          data,
          label: 'asdf',
          backgroundColor: `rgba(${createRandomColor()},0.6)`,
          borderColor: 'rgb(204,214,169)',
        },
      ],
    }
  }

  const filterComponent = () => {
    const { start, end } = filters
    return (
      <div>
        <Row>
          <Col xl={3} md={3} sm={12}>
            <Button
              style={{ flex: 1 }}
              color={'primary'}
              onClick={() => {
                dispatch(getReportSum(start, end, action.waste ? 3 : 1, false))
                setShowSum(true)
              }}>
              Sumatoria
            </Button>
          </Col>
        </Row>
        <h6>Por usuarios</h6>
        <Row>
          <Col xl={4} md={4} sm={12}>
            <Select
              options={[defaultValue, ...users]}
              label={'Usuario solicitante'}
              value={filters.user}
              onChange={v => setFilters({ ...filters, user: v })}
            />
          </Col>
          <Col xl={4} md={4} sm={12}>
            <Select
              options={[defaultValue, ...users]}
              label={'Usuario autorizador'}
              value={filters.auth}
              onChange={v => setFilters({ ...filters, auth: v })}
            />
          </Col>
          <Col xl={4} md={4} sm={12}>
            <Select
              options={states}
              label={'Estado'}
              value={filters.state}
              onChange={v => setFilters({ ...filters, state: v })}
            />
          </Col>
          <Col xl={4} md={4} sm={12}>
            <Select
              options={[defaultValue, ...warehouses]}
              label={'Bodega'}
              value={filters.warehouse}
              onChange={v => setFilters({ ...filters, warehouse: v })}
            />
          </Col>
        </Row>
        <br />
      </div>
    )
  }

  const headers = [
    { label: 'Número', show: true, value: ['number'], type: 'text', className: 'mini' },
    {
      label: 'Solicitante',
      show: true,
      value: ['userName'],
      type: 'text',
      className: 'medium',
    },
    {
      label: 'Autorizador',
      show: true,
      value: ['authName'],
      type: 'text',
      className: 'medium',
      custom: item => item.authName || 'Sin autorizar',
    },
    {
      label: 'Estado',
      show: true,
      value: ['statusName'],
      type: 'text',
      className: 'mini',
    },
    { label: 'Fecha', show: true, value: ['date'], type: 'date', className: 'mini' },
    {
      label: `Monto (${currentCurrency.symbol})`,
      show: isWaste && canSeeMoneyBalances,
      value: ['amount'],
      type: 'currency',
      className: 'mini',
    },
    {
      label: `Descripcion`,
      show: true,
      value: ['description'],
      type: 'text',
      className: 'medium',
    },
    {
      config: true,
      show: true,
      label: '',
      className: 'mini',
      custom: item => {
        let acceptPermission
        if (action.waste) {
          if (item.warehouseId == null) acceptPermission = canAcceptArchivedProducts
          else if (item.userId === currentUser.id) acceptPermission = canAcceptOwnWaste
          else acceptPermission = canAcceptWaste
        } else if (item.userId === currentUser.id)
          acceptPermission = canAccessOwnProduction
        else acceptPermission = canAcceptProduction

        return (
          <Dropdown
            disabled={singleRequestLoading}
            loading={(singleRequestLoading || loadingNullify) && action.id === item.id}
            items={[
              {
                title: 'Ver detalles',
                action: () => {
                  setAction({ ...action, see: true, id: item.id })
                },
              },
              {
                title: 'Anular',
                show: item.status === 3 && canNullifyProduction && !isWaste,
                action: () => {
                  dispatch(
                    showAlert({
                      ...handlerSuccess('¿Desea anular la solicitud?'),
                      title: 'Anular',
                      type: 'warning',
                      showCancelButton: true,
                      onConfirm: () => {
                        setAction({ ...action, id: item.id })
                        dispatch(nullifyInventoryRequest(item.id))
                      },
                    }),
                  )
                },
              },
              {
                title: 'Aceptar',
                show: acceptPermission && item.status === 1,
                action: () =>
                  dispatch(
                    showAlert({
                      ...handlerSuccess(
                        item.warehouseId
                          ? '¿Desea aceptar la solicitud?'
                          : 'Al aceptar la solicitud de reducción de inventario por productos a archivar, se reducirá la existencia total de los productos en' +
                              ' la solicitud y dichos productos serán archivados de forma automática.',
                      ),
                      title: 'Aceptar',
                      type: 'warning',
                      showCancelButton: true,
                      onConfirm: () => {
                        setAction({ ...action, id: item.id, update: true })
                        dispatch(onUpdateInventoryRequest(item.id, 3))
                      },
                    }),
                  ),
              },
              {
                title: 'Rechazar',
                show:
                  item.status === 1 &&
                  ((action.waste && canAcceptWaste) ||
                    (!action.waste && canAcceptProduction)),
                action: () =>
                  dispatch(
                    showAlert({
                      ...handlerSuccess('¿Desea rechazar la solicitud?'),
                      title: 'Rechazar',
                      type: 'warning',
                      showCancelButton: true,
                      onConfirm: () => {
                        setAction({ ...action, id: item.id, update: true })
                        dispatch(onUpdateInventoryRequest(item.id, 2))
                      },
                    }),
                  ),
              },
              {
                title: 'Ver procedencia de inventario',
                show: (item.status === 3 || item.status === 1) && isWaste,
                action: () =>
                  setInventoryReport({
                    title: item.number,
                    documentId: item.id,
                  }),
              },
            ]}
          />
        )
      },
    },
  ]

  const renderTable = withDateFilter => {
    return (
      <Row>
        <Col xl={12} md={12} sm={12}>
          {isWaste && canSeeMoneyBalances && (
            <CustomSummary
              items={[
                {
                  title: 'Total reducción de inventarios',
                  value: toMoney(report.purchase),
                },
              ]}
            />
          )}
        </Col>
        <Col xl={12} md={12} sm={12}>
          <Action
            action={
              (isWaste && canCreateCreateWaste) || (!isWaste && canCreateProduction)
            }
            to={`nuevo/${isWaste}`}
            actionTitle={`Crear ${isWaste ? 'reducción' : 'ingreso'} de inventario`}
          />
        </Col>
        <Col xl={12} md={12} sm={12}>
          <TableV2
            storageKey={`waste-`}
            items={requests}
            headers={headers}
            loading={initialLoading || requestsLoading}
            getPagination={(skip, size, search) =>
              setFilters({ ...filters, skip, size, search })
            }
            total={totalRequests}
            handleChange={search =>
              setTimeout(() => {
                setFilters({ ...filters, search })
              }, 100)
            }
            dateFilter={withDateFilter}
            onDateChange={(start, end) =>
              setFilters({
                ...filters,
                start: start.setHours(0, 0, 0, 0).valueOf(),
                end: end.setHours(23, 59, 59, 59),
              })
            }
            noItemsLegend={'No hay solicitudes con el filtro seleccionado'}
            customFilter={filterComponent()}
            mobileAuto
          />
        </Col>
      </Row>
    )
  }

  return (
    <div>
      <Title
        title={isWaste ? 'Reducción de inventario' : 'Ingreso de inventario'}
        action={(isWaste && canCreateCreateWaste) || (!isWaste && canCreateProduction)}
        to={`nuevo/${isWaste}`}
        actionTitle={`Crear ${isWaste ? 'reducción' : 'ingreso'} de inventario`}
      />
      <Row>
        <Col xl={12} md={12} sm={12} xs={12}>
          <Card white title={'listado de solicitudes'}>
            {renderTable(true)}
          </Card>
        </Col>
      </Row>

      <ModalTable
        show={showSum}
        title={'Sumatoria de Productos'}
        size={'xl'}
        onHide={() => {
          setShowSum(false)
        }}
        loading={loadingReport}
        headers={['', 'Código', 'Producto', 'Cantidad']}
        items={summation.sort((a, b) => b.total - a.total)}
        changeRadio={handleChangeRadio}
        chart={summation.length > 0 && showSum ? getChartData() : null}
        renderRow={(item, index) => (
          <Tr key={index} className={'data'}>
            <Td className={'mini'} />
            <Td className={'medium'}>{`${item.name.split('-')[0]}-${
              item.name.split('-')[1]
            }`}</Td>
            <Td className={'name'}>{item.name.split('-')[2]}</Td>
            <Td className={'medium'}>{formatNumberWithCommas(item.total)} U.</Td>
          </Tr>
        )}
      />

      <CustomReference
        show={action.see}
        documentModule={5}
        documentType={2}
        documentId={action.id}
        onHide={() => setAction({ ...action, see: false, id: null })}
        balancePermission={canSeeMoneyBalances}
      />

      <InventoryReport
        showItem
        documentType={12}
        documentId={inventoryReport.documentId}
        productName={'Reducción NO: '.concat(inventoryReport.title)}
        close={() => setInventoryReport({ documentId: null, title: '' })}
      />
    </div>
  )
}
export default Waste
