import { $http } from './http'

class TicketsService {
  getIssues = async () => {
    const response = await $http.get(`/tickets/issues`)
    return response.data
  }
}

export default new TicketsService()
