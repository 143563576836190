import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { formatNumberWithCommas } from 'src/utils/formatters'
import { Card } from 'src/components'
import IconButton from 'src/components/buttons/IconButton'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const formatDescription = (description = '') =>
  description.split(/\\n|\n/).map((d, i) => <div key={i}>{d}</div>)

const getIconButton = tooltip => (
  <label>
    <IconButton
      color={'rgba(34, 96, 149, 0.75)'}
      tooltip={formatDescription(tooltip)}
      icon={faInfoCircle}
      size={'sm'}
    />
  </label>
)

const UserSpaceItem = ({
  item: plan,
  button,
  userSpaces = 0,
  additionalUserSpaces = 0,
}) => {
  const extraWarehouses = userSpaces * 3
  const extraFelPos = userSpaces
  const extraPos = userSpaces * 2
  const extraItems = userSpaces * 1000
  const extraInvoices = userSpaces * 200

  const totalUsers = plan.users + userSpaces
  const totalWarehouses = plan.warehouse + extraWarehouses
  const totalFelPos = plan.felPos + extraFelPos
  const totalPos = plan.POS + extraPos
  const totalItems = plan.items + extraItems
  const totalInvoices = plan.invoices + extraInvoices

  const newWarehouses = additionalUserSpaces * 3
  const newFelPos = additionalUserSpaces
  const newPos = additionalUserSpaces * 2
  const newItems = additionalUserSpaces * 1000
  const newInvoices = additionalUserSpaces * 200

  return (
    <Card
      white
      title={plan.id ? 'Características' : 'Cargando'}
      button={<div>{button}</div>}
      style={{ height: '100%' }}>
      {plan.id && (
        <Row style={{ alignItems: 'start', padding: '0 10px' }}>
          <Col xs={12}>
            <div
              style={{
                fontWeight: 700,
                fontSize: 15,
                color: '#226095',
              }}
              className={'mt-1'}>
              Q.{formatNumberWithCommas(plan.priceUsers * additionalUserSpaces)} anuales
              por {additionalUserSpaces} espacio(s) de usuario nuevos
            </div>
            <div style={{ fontWeight: 700, color: '#525252' }} className={'mt-2'}>
              Tus características:
            </div>
            <ul style={{ marginBottom: 0 }}>
              {totalUsers > 0 && (
                <li>
                  <div style={{ fontWeight: 700, color: '#226095' }}>
                    {formatNumberWithCommas(totalUsers, 0)} usuario(s) contratados
                    {userSpaces > 0 &&
                      getIconButton(
                        `${plan.users} usuario(s) del plan ${plan.name}\n${userSpaces} espacio(s) de usuario adicionales`,
                      )}
                  </div>
                </li>
              )}
              {totalWarehouses > 0 && (
                <li>
                  <div style={{ fontWeight: 700, color: '#226095' }}>
                    {formatNumberWithCommas(totalWarehouses, 0)} bodega(s) contratados{' '}
                    {extraWarehouses > 0 &&
                      getIconButton(
                        (plan.warehouse > 0
                          ? `${plan.warehouse} bodega(s) del plan ${plan.name}\n`
                          : '') + `${extraWarehouses} bodega(s) adicionales`,
                      )}
                  </div>
                </li>
              )}
              {totalPos > 0 && (
                <li>
                  <div style={{ fontWeight: 700, color: '#226095' }}>
                    {formatNumberWithCommas(totalPos, 0)} punto(s) de venta contratados{' '}
                    {extraPos > 0 &&
                      getIconButton(
                        (plan.POS > 0
                          ? `${plan.POS} punto(s) de venta del plan ${plan.name}\n`
                          : '') + `${extraPos} punto(s) de venta adicionales`,
                      )}
                  </div>
                </li>
              )}
              {totalFelPos > 0 && (
                <li>
                  <div style={{ fontWeight: 700, color: '#226095' }}>
                    {formatNumberWithCommas(totalFelPos, 0)} establecimiento(s)
                    comercial(es) contratados{' '}
                    {extraFelPos > 0 &&
                      getIconButton(
                        (plan.felPos > 0
                          ? `${plan.felPos} establecimiento(s) comercial(es) del plan ${plan.name}\n`
                          : '') +
                          `${extraFelPos} establecimiento(s) comercial(es) adicionales`,
                      )}
                  </div>
                </li>
              )}
              {totalItems > 0 && (
                <li>
                  <div style={{ fontWeight: 700, color: '#226095' }}>
                    {formatNumberWithCommas(totalItems, 0)} ítems contratados{' '}
                    {extraItems > 0 &&
                      getIconButton(
                        (plan.items > 0
                          ? `${plan.items} ítems del plan ${plan.name}\n`
                          : '') + `${extraItems} ítems adicionales`,
                      )}
                  </div>
                </li>
              )}
            </ul>
            {(totalInvoices > 0 || plan.priceInvoice > 0) && (
              <ul style={{ margin: 0 }}>
                {totalInvoices > 0 && (
                  <li>
                    <div style={{ fontWeight: 700, color: '#226095' }}>
                      {formatNumberWithCommas(totalInvoices, 0)} facturas electrónicas sin
                      vencimiento{' '}
                      {extraInvoices > 0 &&
                        getIconButton(
                          `${plan.invoices} facturas electrónicas del plan ${plan.name}\n${extraInvoices} facturas electrónicas adicionales`,
                        )}
                    </div>
                  </li>
                )}
              </ul>
            )}
            <div style={{ fontWeight: 700, color: '#525252' }} className={'mt-2'}>
              Características adicionales por {additionalUserSpaces} espacio(s) de
              usuario:
            </div>
            <ul style={{ marginBottom: 0 }}>
              {additionalUserSpaces > 0 && (
                <li>
                  <div
                    style={{
                      fontWeight: 700,
                      color: '#226095',
                      margin: '5px 0',
                    }}>
                    {additionalUserSpaces} usuario(s) contratados
                  </div>
                </li>
              )}
              {newWarehouses > 0 && (
                <li>
                  <div style={{ fontWeight: 700, color: '#226095', marginBottom: 5 }}>
                    {formatNumberWithCommas(newWarehouses, 0)} bodega(s) contratados
                  </div>
                </li>
              )}
              {newPos > 0 && (
                <li>
                  <div style={{ fontWeight: 700, color: '#226095', marginBottom: 5 }}>
                    {formatNumberWithCommas(newPos, 0)} punto(s) de venta contratados
                  </div>
                </li>
              )}
              {newFelPos > 0 && (
                <li>
                  <div style={{ fontWeight: 700, color: '#226095', marginBottom: 5 }}>
                    {formatNumberWithCommas(newFelPos, 0)} establecimiento(s)
                    comercial(es) contratados
                  </div>
                </li>
              )}
              {newItems > 0 && (
                <li>
                  <div style={{ fontWeight: 700, color: '#226095', marginBottom: 5 }}>
                    {formatNumberWithCommas(newItems, 0)} ítems contratados
                  </div>
                </li>
              )}
              {newInvoices > 0 && (
                <li>
                  <div style={{ fontWeight: 700, color: '#226095', marginBottom: 5 }}>
                    {formatNumberWithCommas(newInvoices, 0)} facturas electrónicas sin
                    vencimiento
                  </div>
                </li>
              )}
            </ul>
          </Col>
        </Row>
      )}
    </Card>
  )
}

export default UserSpaceItem
