import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Select, Button } from 'src/components'
import Switch from 'src/components/inputs/Switch/Switch'

export const ModalTaxFree = ({ show, item, onClose, updateTax }) => {
  const [toSelect, setSelect] = useState({})
  const phrases = useSelector(state =>
    Object.assign(
      [],
      state.resolutions.phrases.map(p => ({ ...p, label: p.phrase, value: p.id })),
    ),
  )
  useEffect(() => {
    setSelect(item)
  }, [item])

  return (
    <Modal show={show} centered size={'md'} onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Configuración del IVA</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ marginTop: 5, display: 'flex' }}>
          <Switch
            id={'t'}
            cStyle={{ alignSelf: 'flex-end' }}
            name={'t'}
            checked={toSelect.includeIva}
            onChange={({ target }) => {
              if (target.checked) setSelect({ ...toSelect, phraseReference: undefined })
              setSelect({ ...toSelect, includeIva: target.checked })
            }}
            label={'IVA'}
            topLabel
          />
          <Select
            withoutLabel
            value={toSelect.phraseReference}
            onChange={value => {
              setSelect({ ...toSelect, phraseReference: value })
            }}
            options={phrases}
            disabled={toSelect.includeIva}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            if (updateTax) updateTax(toSelect)
            onClose()
          }}
          icon={faPlusSquare}
          right>
          Actualizar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
