import { getState } from 'src/utils/utilities'
import DepartmentsService from 'src/services/address.service'
import { executeAction } from 'src/actions/global.actions'
import { selectAllLocalities, selectAllRegions } from 'src/selectors/address.selector'

export const actionTypes = {
  GET_ALL_REGIONS: 'GET_ALL_REGIONS',
  GET_SINGLE_REGION: 'GET_SINGLE_REGION',
  GET_LOCALITIES_BY_REGION: 'GET_LOCALITIES_BY_REGION',
  GET_ALL_LOCALITIES: 'GET_ALL_LOCALITIES',
  GET_SINGLE_LOCALITY: 'GET_SINGLE_LOCALITY',
}

/**
 * Get all regions of company country
 */
export const getAllRegions = () => async dispatch => {
  const regions = getState(selectAllRegions)
  if (regions.length > 0) return

  const process = async () => ({
    payload: await DepartmentsService.getAllRegions(),
  })
  dispatch(executeAction(actionTypes.GET_ALL_REGIONS, process))
}

/**
 * Get a single region
 * @param {id} region id
 */
export const getSingleRegion = id => async dispatch => {
  const process = async () => ({
    payload: await DepartmentsService.getSingleRegion(id),
  })
  dispatch(executeAction(actionTypes.GET_SINGLE_REGION, process))
}

/**
 * Get all localities of a region
 */
export const getLocalitiesByRegion = id => async dispatch => {
  const process = async () => ({
    payload: await DepartmentsService.getLocalitiesByRegion(id),
  })
  dispatch(executeAction(actionTypes.GET_LOCALITIES_BY_REGION, process))
}

/**
 * Get all localities
 */
export const getAllLocalities = () => async dispatch => {
  const localities = getState(selectAllLocalities)
  if (localities.length > 0) return

  const process = async () => ({
    payload: await DepartmentsService.getAllLocalities(),
  })
  dispatch(executeAction(actionTypes.GET_ALL_LOCALITIES, process))
}

/**
 * Get a single locality
 */
export const getSingleLocality = id => async dispatch => {
  const process = async () => ({
    payload: await DepartmentsService.getSingleLocality(id),
  })
  dispatch(executeAction(actionTypes.GET_SINGLE_LOCALITY, process))
}
