import React, {Component} from 'react';
import {connect} from "react-redux"
import "./Subscriptions.scss"
import { TableV2 } from "../../../components/index"
import {Col, OverlayTrigger, Row, Tooltip, Button, Modal, Spinner, Jumbotron, ButtonToolbar, ToggleButtonGroup, ToggleButton} from "react-bootstrap"
import CollapsibleCard from "../../../components/cards/collapsible-card/CollapsibleCard";
import Alert from "sweetalert-react";
import {selectCompaniesByClient, selectSetCurrentCompany} from "../../../selectors/company.selector";
import {loadingSelector} from "../../../selectors/loading.selector";
import {actionTypes as typesC, getCompaniesByClient} from "../../../actions/company.actions";
import {handlerError, hasErrorsSelector, singleErrorSelector} from "../../../selectors/error.selector";
import {
    actionTypes as typeS,
    getSubscriptions,
    saveNewSubscription,
    getSubscriptionsClient,
    buySubscription,
    getActiveSubscriptionsClient,
    getSubscriptionsCompany
} from "../../../actions/subscriptions.actions";
import {selectSubscriptions, selectSubscriptionsCompany, selectSubscriptionsClient, selectSubscriptionsActiveClient} from "../../../selectors/subscriptions.selector";
import {formatNumberWithCommas} from "../../../utils/formatters";
import Placeholder from "../../../components/Placeholder/Placeholder";
import ModalSelectClient from "../../Production/Subscriptions/SubscriptionCreate/ModalSelectClient";
import moment from "moment";
import DayPicker from "react-day-picker";

import MomentLocaleUtils, {formatDate, parseDate} from "react-day-picker/moment";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";
import BoxO from "../../../assets/images/svg/box-open.svg";
import BoxC from "../../../assets/images/svg/box-closed.svg";
import Money from '../../../components/Money/Money';
import { toMoney } from '../../../utils/utilities';


const BoxOpen = () =>
    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">El tipo de agrupación es abierta, Al momento de suscribirte puedes modificar el contenido de la agrupación.</Tooltip>}>
        <img src={BoxO} alt={'position'} width={40}/>
    </OverlayTrigger>;
const BoxClosed = () =>
    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">El tipo de agrupación es cerrada, Recibes el contenido descrito en la agrupación.</Tooltip>}>
        <img src={BoxC} alt={'position'} width={40}/>
    </OverlayTrigger>;

const intervals = [
    {value: 0, label: 'Diariamente', singular: 'Día', plural: 'días'},
    {value: 1, label: 'Semanalmente', singular: 'Semana', plural: 'semanas'},
    {value: 2, label: 'Mensualmente', singular: 'Mes', plural: 'meses'},
    {value: 3, label: 'Anualmente', singular: 'Año', plural: 'años'},
]

const offColors = [
    '#dc3445',
    '#de773c',
    '#e0ba32',
    '#7db754',
    '#22B573'
]

const days = [0,1,2,3,4,5,6,7];

class Subscriptions extends Component {

    state = {
        alert: {title: 'default'},
        companies: [],
        search: '',
        showConfirmation: false,
        minDate: moment().add(1, 'days'),
        selectedDay: parseDate(moment().add(1, 'days')),
        today: parseDate(moment()),
        showModalDate: false,
        locale: 'es',
        currentSubscription: null,
        showBuySuccess: false,
        client: null,
        // showClientModal: false,
        paymentMethod: 0
    };

    componentDidMount() {
        this.props.getSubscriptionsClient();
        this.props.getActiveSubscriptionsClient();
        // this.props.getSubscriptionsCompany();
    };

    componentWillReceiveProps(next) {
        const {loadingC, hasErrorC, loadingP, hasErrorP, handlerError, loadingS, hasErrorS, loadingB, hasErrorB} = this.props;
        const {errorC, errorP, listCompanies, errorB} = next;
        let alert = {title: 'default'};

        if (loadingC && !next.loadingC) {
            if (!hasErrorC && next.hasErrorC) {
                alert = handlerError(errorC.message || 'Ha ocurrido un error inesperado.');
                alert.onConfirm = () => this.setState({alert: {...alert, show: false}});
            } else {
                let companies = [];
                listCompanies.map((c) => (companies.push({...c, isSelected: true})));
                this.setState({companies});
            }
        }

        if (loadingS && !next.loadingS) {
            if (!hasErrorS && !next.hasErrorS) {
                console.log('SUSCRIPTIONS::::::', next.subscriptions)
            } else {
                this.setState({subscriptionsError: true});
            }
        }

        if (loadingB && !next.loadingB) {
            if (!hasErrorB && next.hasErrorB) {
                alert = handlerError(errorB.message || 'Ha ocurrido un error inesperado.');
                alert.onConfirm = () => this.setState({alert: {...alert, show: false}});
            } else {
                this.setState({showBuySuccess: true, showModalDate: false});
            }
        }

        this.setState({alert});
    };


    handleItemsChange = (event) => {
        const {checked} = event.target;
        const {companies} = this.state;
        const list = companies.map((c) => ({...c, isSelected: checked}));
        this.setState({companies: list});
    };

    handlerCheckBox = (event, index) => {
        const {checked} = event.target;
        const {companies} = this.state;
        companies[index].isSelected = checked;
        this.setState({companies});
    };

    filter = (item) => {
        const {companies} = this.state;
        const company = companies.find((c) => c.id === item.company);
        return (company && company.isSelected);
    };

    subscribe = () => {
        const {currentSubscription, selectedDay, paymentMethod} = this.state;

        let itemsPro = [];
        currentSubscription.subscription.subscriptionDetail.map((item) => {
            const {productId, quantity} = item;
            itemsPro.push({
                product: productId,
                quantity
            })
        });

        const request = {
            subscriptionPolygonId: currentSubscription.id,
            companyId: currentSubscription.subscription.companyId,
            subscriptionId: currentSubscription.subscription.id,
            startDate: moment(selectedDay).valueOf(),
            paymentMethod,
            shipperId: currentSubscription.shipperId,
            subscriptionDetail: itemsPro
        }

        this.props.buySubscription(request);

        this.setState({
            showConfirmation: false
        })
    }

    getContent = (subscriptionDetail) => {
        let content = [];
        subscriptionDetail.filter((i, index) => (index < 3)).map((product) => {
            const {quantity, subtotal, discount, total, productData} = product;
            content.push({
                name: productData.name,
                quantity: quantity,
                discount,
                subtotal,
                total
            })
        })

        return content;
    }

    totalProductDetail = (products) => {
        let total = 0;
        if (products)
        products.map((product) => {
            total += product.total;
        });
        return total
    };

    normalPrice = (products, times) => {
        let total = 0;

        if (times) {
            products.map((product) => {
                total += product.subtotal - product.discount;
            });
            return formatNumberWithCommas(total * times);
        }
        return formatNumberWithCommas(0)
    };

    totalSubscription = (products, generalDiscount, times) => {
        let total = 0;
        let discount = 0;

        if (times) {
            products.map((product) => {
                total += product.subtotal - product.discount;
            });
            discount = total * generalDiscount/100;

            return formatNumberWithCommas((total - discount) * times);
        }
        return formatNumberWithCommas(0)
    };

    getBackColor = (discount) => {
        if ((discount) > 40){
            return offColors[0]
        } else if ((discount) > 30){
            return offColors[1]
        } else if ((discount) > 20){
            return offColors[2]
        } else if ((discount) > 10){
            return offColors[3]
        } else {
            return offColors[4]
        }
    }

    onSelectedDay = (date) => {
        if (moment(date).isSameOrAfter(this.state.today)){
            this.setState({
                selectedDay: date,
            });
        }
    };

    onSelectClient = (client, selectedDay) => {
        this.setState({client, selectedDay, showClientModal: false, showConfirmation: true});
    }

    onHideClientModal = () => this.setState({showClientModal: false});

    onHide = () => this.setState({showModalDate: false});

    handlePaymentMethod = (val) => {
        this.setState({paymentMethod: val})
    };

    goTo = (item, route) => {
        const {history, match} = this.props;
        const url = `${match.url}${route}/${item.id}`;
        history.push(url, {subscriptionId: item.id});
    };

  

    formatDateFromMillis = (millis) => {
        const day = moment(millis).utc(true).utcOffset(6);
        return moment(day).format('DD/MM/YYYY');
    };

    render() {
        const {loadingB, subscriptionsPolygons, loadingSP, subscriptionsActive, subscriptionsCompany, loadingSC} = this.props;
        const {companies, minDate, showModalDate, showBuySuccess, paymentMethod, currentSubscription} = this.state;

        let deliveryDays = [];
        let disabledDays = [];
        if (currentSubscription && currentSubscription.pickUpDays){
            const stringDays = currentSubscription.pickUpDays.split(',');
            for(let i=0; i< stringDays.length; i++) {
                const deliveryDay = currentSubscription.deliveryDays + parseInt(stringDays[i]);
                const day = deliveryDay < 7 ? deliveryDay : deliveryDay % 7;
                deliveryDays.push(day);
            }
            disabledDays = days.filter(item => !deliveryDays.includes(item));
        }

        return (
            <div>
                <h1 className={'dashboard-welcome'}>Suscripciones</h1>

                <Row>
                    {/*<Col xl={3} lg={3} md={3} sm={12} xs={12}>*/}
                    {/*    <CollapsibleCard header={{backgroundColor: '#1b4060'}} title={'Filtrar por'} startCollapsed={false}>*/}
                    {/*        <div className={'column'}>*/}
                    {/*            <h6><strong>Productor</strong></h6>*/}
                    {/*            <h2 className={'item-title'}>*/}
                    {/*                <input style={{cursor: 'pointer', marginRight: 4}} type="checkbox" name={'all'} checked={companies.filter((c) => c.isSelected).length === companies.length} onChange={this.handleItemsChange}/>*/}
                    {/*                {companies.filter((c) => c.isSelected).length !== companies.length ? 'Marcar todos' : 'Desmarcar todos'}*/}
                    {/*            </h2>*/}

                    {/*            <form>*/}
                    {/*                {companies.map((c, i) =>*/}
                    {/*                    <label*/}
                    {/*                        key={c.id}*/}
                    {/*                        className={'checkbox-label'}>*/}
                    {/*                        <input*/}
                    {/*                            type={"checkbox"}*/}
                    {/*                            name={`producer - ${i}`}*/}
                    {/*                            checked={c.isSelected}*/}
                    {/*                            onChange={(event) => this.handlerCheckBox(event, i)}/> {`${c.alphanumericId || ''} - ${c.name}`}*/}
                    {/*                    </label>*/}
                    {/*                )}*/}
                    {/*            </form>*/}
                    {/*        </div>*/}
                    {/*    </CollapsibleCard>*/}
                    {/*</Col>*/}
                    <Col>
                        <div className={'back-subscription-card'}>
                            { loadingSP ?
                                new Array(0,0,0,0).map((i, index) => <Col key={index} md={3}><Placeholder/></Col>)
                                :
                                subscriptionsPolygons.length > 0 ?
                                    subscriptionsPolygons.map((item, index) =>{

                                        const total = item.totalSubscription * item.subscription.times;
                                        const value = this.totalProductDetail(item.subscription.subscriptionDetail) * 1.17;
                                        const discount = 1 - (item.totalSubscription / (value + (item.price * item.subscription.drawers)));

                                        return (
                                        <Col xl={3} lg={4} md={6} sm={6} xs={12} key={index}>
                                            <div className={'subscription-card'}>
                                                <div className="header-subs">
                                                    <div style={{height: 100, backgroundColor: '#1b4060', borderTopLeftRadius: '5px', borderTopRightRadius: '5px', textAlign: 'center', paddingTop: 10}}>
                                                        <h1 style={{color: 'white'}}>{item.subscription.title}</h1>
                                                        <h6 style={{color: 'white'}}>{item.subscription.description}</h6>
                                                        <h6 style={{color: 'white', fontSize: 12}}>{item.subscription.companyName}</h6>
                                                    </div>
                                                    {/*{discount > 0 &&*/}
                                                    {/*<div className="arrow-right"*/}
                                                    {/*     style={{backgroundColor: this.getBackColor(discount*100)}}>*/}
                                                    {/*    <span*/}
                                                    {/*        className="arrow-text"><b>{(discount - Math.floor(discount)) === 0 ? discount * 100 : (discount * 100).toFixed(4)}%</b></span>*/}
                                                    {/*</div>*/}
                                                    {/*}*/}
                                                </div>
                                                <Row style={{margin: 10}}>
                                                    <Col>
                                                        <h4>Contenido</h4>
                                                        {
                                                            this.getContent(item.subscription.subscriptionDetail).map((item, index) =>(
                                                                <p>{item.quantity + ' ' + item.name }</p>
                                                            ))
                                                        }

                                                        <br/>
                                                        <h2>Entregas cada</h2>
                                                        <p>{(item.subscription.step > 1 ? item.subscription.step + ' ' + intervals[item.subscription.intervalType].plural : intervals[item.subscription.intervalType].singular )}</p>

                                                        <br/>
                                                        <h2>Cantidad de entregas</h2>
                                                        <p>{item.subscription.times + (item.subscription.times > 1 ? ' veces':' vez')}</p>

                                                        {/*<br/>*/}
                                                        {/*<h2>Tipo de agrupación</h2>*/}
                                                        {/*<p>{item.subscription.type === 1 ? 'Cerrada':'Abierta'}</p>*/}

                                                    </Col>
                                                </Row>

                                                {/*{discount > 0 &&*/}
                                                {/*<Row style={{position: 'absolute', bottom: 90, right: 50}}>*/}
                                                {/*    <Money component='h6' style={{textAlign: 'end'}}>*/}
                                                {/*        <del>{total / (1 - discount)}</del>*/}
                                                {/*    </h6>*/}
                                                {/*</Row>*/}
                                                {/*}*/}

                                                <Row style={{position: 'absolute', bottom:100, right: 50}}>
                                                    {item.subscription.type === 1 ?
                                                            <BoxClosed/>
                                                        :
                                                            <BoxOpen/>
                                                    }
                                                </Row>

                                                <Row style={{position: 'absolute', bottom:70, right: 50}}>
                                                    <Money component='h1' style={{textAlign: 'end'}}>{total}</Money>
                                                </Row>
                                                <Row style={{position: 'absolute', bottom:20, right: 40}}>
                                                    {/*<Button variant="danger" onClick={()=>this.setState({showClientModal: true, currentSubscription: item})}>Suscribirse</Button>*/}

                                                    <Button disabled={loadingB} style={{alignContent: 'center'}} variant="danger" onClick={()=>this.setState({showModalDate: true, currentSubscription: item})}>
                                                        {loadingB &&
                                                        <Spinner
                                                            style={{marginRight: 10, marginTop: 4}}
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />}
                                                        Suscribirme
                                                    </Button>
                                                </Row>
                                            </div>

                                        </Col>
                                    )})
                                :
                                    <div>
                                        <h1>Vacío</h1>
                                        <p>
                                            Actualmente no existen suscripciones, intenta en otro momento.
                                        </p>
                                    </div>
                            }
                        </div>

                        <CollapsibleCard header={{backgroundColor: '#1b4060'}} style={{marginTop: 20}} title={'Activas'}>
                            <TableV2
                                
                                items={subscriptionsActive}
                                loading={loadingSC}
                                mobileAuto
                                storageKey={`subscriptionClient`}
                                headers={[
                                    {label: 'Nombre', show: true, value: ['subscription','title'], type: 'text' ,  className:'mini'},
                                    {label: 'Próxima entrega', show: true, value: ['fecha'], type: 'text' ,  className:'mini',
                                        custom:(item)=> item.fecha =  item.status ? this.formatDateFromMillis(item.nextDate) : '-'},
                                    {label: 'Estado', show: true, value: ['estadoC'], type: 'text' ,  className:'mini',
                                        custom: (item) => item.estadoC = item.status ? 'En curso' : 'En espera'
                                    },
                                    {
                                        config: true, show: true, label: '', className: 'medium center',
                                        custom: (item) => <Row>
                                            <Col>
                                                <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip">Detalle</Tooltip>}>
                                                    <Button size={'sm'} variant={'outline-secondary'} onClick={() => this.goTo(item, '/detail')}>
                                                        <FontAwesomeIcon className={'name'} icon={faInfoCircle} />
                                                    </Button>
                                                </OverlayTrigger>
                                            </Col>
                                        </Row>
                                    }

                                ]}
                            />
                        </CollapsibleCard>
                    </Col>
                </Row>

                <Modal size="sm" centered show={showModalDate} onHide={this.onHide}>
                    <Modal.Header closeButton>
                        <Modal.Title>Fecha de inicio</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Row style={{justifyContent: 'center'}}>
                                <DayPicker
                                    format={'DD/MM/YYYY'}
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    disabledDays={[{before: parseDate(moment().add(currentSubscription ? currentSubscription.deliveryDays + 1 : 1, 'days'))}, { daysOfWeek: disabledDays }]}
                                    localeUtils={MomentLocaleUtils}
                                    locale={this.state.locale}
                                    // value={startDate}
                                    selectedDays={this.state.selectedDay}
                                    onDayClick={this.onSelectedDay}
                                    // onDayChange={this.onDateChange}
                                    keepFocus={true}/>

                            </Row>

                            <Row style={{justifyContent: 'center'}}>
                                <h6>Forma de pago</h6>
                            </Row>
                            <Row style={{justifyContent: 'center', marginBottom: 20}}>
                                <ButtonToolbar>
                                    <ToggleButtonGroup type="radio" name="options" onChange={this.handlePaymentMethod}>
                                        <ToggleButton className="Btn-active" variant={'secondary'} value={1}>Deposito</ToggleButton>
                                        <ToggleButton className="Btn-active" variant={'secondary'} value={2}>Efectivo</ToggleButton>
                                        <ToggleButton className="Btn-active" variant={'secondary'} value={3}>Tarjeta</ToggleButton>
                                    </ToggleButtonGroup>
                                </ButtonToolbar>
                            </Row>
                            <Row className={'container-buttons'}>
                                <Button disabled={loadingB || paymentMethod == 0} style={{alignContent: 'center'}} variant="danger" onClick={()=>this.setState({showConfirmation: true})}>
                                    {loadingB &&
                                    <Spinner
                                        style={{marginRight: 10, marginTop: 4}}
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />}
                                    Suscribirme
                                </Button>


                                {/*<Button variant="danger" onClick={()=>this.setState({showConfirmation: true})}>Aceptar</Button>*/}
                            </Row>
                        </div>
                    </Modal.Body>

                </Modal>

                {/*<ModalSelectClient show={this.state.showClientModal} onHide={this.onHideClientModal} onSelectClient={this.onSelectClient}/>*/}

                <Alert {...this.state.alert}/>

                <Alert
                    show={this.state.showConfirmation}
                    title="Información"
                    text={this.state.currentSubscription ? "¿Desea suscribirse a " + this.state.currentSubscription.subscription.title + " por el precio de " + toMoney(this.totalSubscription(this.state.currentSubscription.subscription.subscriptionDetail, this.state.currentSubscription.subscription.discount, this.state.currentSubscription.subscription.times)) + "?" : ""}
                    type={"warning"}
                    showCancelButton
                    confirmButtonText="Suscribirme"
                    cancelButtonText="Cancelar"
                    onConfirm={() => {
                        this.subscribe();
                    }}
                    onCancel={() => {
                        this.setState({showConfirmation: false});
                    }}
                />

                <Alert
                    show={showBuySuccess}
                    title={"!Exito!"}
                    text={this.state.currentSubscription ? "Te has suscrito a " + this.state.currentSubscription.subscription.title + " exitosamente." : ""}
                    type={"success"}
                    onConfirm={() => this.setState({showBuySuccess: false})}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    company: selectSetCurrentCompany(state),
    // listCompanies: selectCompaniesByClient(state),
    // loadingC: loadingSelector([typesC.GET_PRODUCER_BY_CLIENT])(state),
    // hasErrorC: hasErrorsSelector([typesC.GET_PRODUCER_BY_CLIENT])(state),
    // errorC: singleErrorSelector([typesC.GET_PRODUCER_BY_CLIENT])(state),

    subscriptionsPolygons: selectSubscriptionsClient(state),
    loadingSP: loadingSelector([typeS.GET_SUBSCRIPTIONS_CLIENT])(state),
    hasErrorSP: hasErrorsSelector([typeS.GET_SUBSCRIPTIONS_CLIENT])(state),
    errorSP: singleErrorSelector([typeS.GET_SUBSCRIPTIONS_CLIENT])(state),

    loadingB: loadingSelector([typeS.BUY_SUBSCRIPTION])(state),
    hasErrorB: hasErrorsSelector([typeS.BUY_SUBSCRIPTION])(state),
    errorB: singleErrorSelector([typeS.BUY_SUBSCRIPTION])(state),

    subscriptionsActive: selectSubscriptionsActiveClient(state),

    // subscriptionsCompany: selectSubscriptionsCompany(state),
    // loadingSC: loadingSelector([typeS.GET_SUBSCRIPTIONS_COMPANY])(state),
    // hasErrorSC: hasErrorsSelector([typeS.GET_SUBSCRIPTIONS_COMPANY])(state),
    // errorSC: singleErrorSelector([typeS.GET_SUBSCRIPTIONS_COMPANY])(state),

    handlerError: (message) => handlerError(message)
});
const mapDispatchToProps = dispatch => ({
    // getCompanies: (id) => dispatch(getCompaniesByClient(id)),
    getSubscriptionsClient: () => dispatch(getSubscriptionsClient()),
    buySubscription: (request) => dispatch(buySubscription(request)),
    // getSubscriptionsCompany: () => dispatch(getSubscriptionsCompany()),
    getActiveSubscriptionsClient: () => dispatch(getActiveSubscriptionsClient())
});
export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
