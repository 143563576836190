import React from 'react'
import AccountBalance from './AccountBalance'
import '../Banks.scss'
import { useSelector } from 'react-redux'
import { selectGetAllCurrencies } from 'src/selectors/banks.selector'
import AccordionList from './AccordionList'

interface AccountListProps {
  accounts: IBankAccount[]
  banks: IBank[]
}
const AccountList: React.FC<AccountListProps> = ({ accounts, banks }) => {
  const currencies = useSelector(selectGetAllCurrencies)

  return (
    <>
      <div className="d-flex flex-column  ">
        <div className="balance text-sm-start ">
          {currencies && <AccountBalance accounts={accounts} currencies={currencies} />}
        </div>
        <div className="account-list">
          <AccordionList accounts={accounts} banks={banks} />
        </div>
      </div>
    </>
  )
}
//
export default AccountList
