import TaxesService from 'src/services/taxes.service'
import { executeAction } from './global.actions'
import { getState } from 'src/utils/utilities'
import { selectAllTaxes } from 'src/selectors/taxes.selector'

export const actions = {
  GET_ALL_TAXES: 'GET_ALL_TAXES',
  UPDATE_TAX_OR_CREATE_TAX: 'UPDATE_TAX_OR_CREATE_TAX',
  DELETE_TAX: 'DELETE_TAX',
}

export const getAllTaxes = (force?: boolean) => async dispatch => {
  const taxes = getState(selectAllTaxes)
  if (taxes?.length > 0 && !force) return

  const process = async () => ({ payload: await TaxesService.getAllTaxes() })
  dispatch(executeAction(actions.GET_ALL_TAXES, process))
}

export const onCreateTax = (request: Tax) => async dispatch => {
  const process = async () => ({ payload: await TaxesService.onCreate(request) })
  dispatch(executeAction(actions.UPDATE_TAX_OR_CREATE_TAX, process))
}

export const onUpdateTax = (id: number, request: Tax) => async dispatch => {
  const process = async () => ({ payload: await TaxesService.onUpdate(id, request) })
  dispatch(executeAction(actions.UPDATE_TAX_OR_CREATE_TAX, process))
}

export const onDeleteTax = (id: number) => async dispatch => {
  const process = async () => await TaxesService.onDelete(id)
  dispatch(executeAction(actions.DELETE_TAX, process))
}
