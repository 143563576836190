import './POS.scss'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Alert from 'sweetalert-react'
import { Modal, Row, Col } from 'react-bootstrap'
import { FormText, Select, Button } from 'src/components'
import ImageInput, { imageTypes } from 'src/components/inputs/ImageInput/ImageInput'
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons'

import { getWarehouseLite } from 'src/actions/warehouse.actions'
import { selectWarehouseLite } from 'src/selectors/warehouse.selector'

import {
  actionTypes as typeT,
  createTable,
  setInfo,
  deleteTable,
} from 'src/actions/restaurant.actions'

import {
  selectCompanyIsLimit,
  selectSetCurrentCompany,
} from 'src/selectors/company.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import { terminalTypes } from 'src/enums/terminalTypes'

const POSTerminal = ({
  userId,
  show,
  id,
  title,
  posId,
  onHide,
  warehouseId,
  number,
  type,
  free,
  posType = {},
}) => {
  const dispatch = useDispatch()

  const emptyTerminal = {
    src: { url: null },
    type: terminalTypes[0],
  }

  const warehouses = useSelector(selectWarehouseLite)
  const isLimit = useSelector(selectCompanyIsLimit)
  const company = useSelector(selectSetCurrentCompany)

  const loadingCreate = useSelector(state => loadingSelector([typeT.CREATE_TABLE])(state))
  const hasErrorCreate = useSelector(state => hasErrors([typeT.CREATE_TABLE])(state))

  const loadingUpdate = useSelector(state => loadingSelector([typeT.SET_WAITER])(state))
  const hasErrorUpdate = useSelector(state => hasErrors([typeT.SET_WAITER])(state))

  const loadingDelete = useSelector(state => loadingSelector([typeT.DELETE_TABLE])(state))
  const hasErrorDelete = useSelector(state => hasErrors([typeT.DELETE_TABLE])(state))

  const [flags, setFlags] = useState({ create: false, update: false, delete: false })
  const [alert, setAlert] = useState({ title: '' })

  const [open, setOpen] = useState(false)
  const [terminal, setTerminal] = useState(emptyTerminal)

  useEffect(() => {
    if (!show) return

    setOpen(true)
    if (warehouses.length === 0) dispatch(getWarehouseLite())
    if (id) {
      setTerminal({
        id,
        src: { url: null },
        number,
        warehouse: onSetWarehouse(warehouses, true),
        type: terminalTypes.find(t => t.value === type),
      })
    }
  }, [show])

  useEffect(() => {
    if (warehouses.length === 0) return
    if (show && id && !terminal.warehouse && warehouseId) {
      onSetWarehouse(warehouses)
    }
  }, [warehouses.length])

  useEffect(() => {
    if (loadingCreate) setFlags({ ...flags, create: true })
    else if (flags.create) {
      setFlags({ ...flags, create: false })
      setOpen(false)
      if (hasErrorCreate)
        setAlert({
          ...handlerError(hasErrorCreate.message),
          onConfirm: () => {
            setOpen(true)
            setAlert({ ...alert, show: false })
          },
        })
      else {
        setAlert({
          ...handlerSuccess('Terminal creada satisfactoriamente'),
          showCancelButton: true,
          cancelButtonText: 'Cerrar',
          confirmButtonText: 'Seguir creando',
          confirmButtonColor: '#226095',
          onCancel: () => onClose(true),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            setTerminal(emptyTerminal)
            setOpen(true)
          },
        })
      }
    }
  }, [loadingCreate])

  useEffect(() => {
    if (loadingUpdate) setFlags({ ...flags, update: true })
    else if (flags.update) {
      setFlags({ ...flags, update: false })
      setOpen(false)

      if (hasErrorUpdate)
        setAlert({
          ...handlerError(hasErrorUpdate.message),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            setOpen(true)
          },
        })
      else
        setAlert({
          ...handlerSuccess('Terminal actualizada'),
          onConfirm: () => {
            onClose(true)
          },
        })
    }
  }, [loadingUpdate])

  useEffect(() => {
    if (loadingDelete) setFlags({ ...flags, delete: true })
    else if (flags.delete) {
      setFlags({ ...flags, delete: false })

      let newAlert
      let onConfirm = () => setAlert({ ...alert, show: false })
      if (hasErrorDelete) newAlert = handlerError(hasErrorDelete.message)
      else newAlert = handlerSuccess('Terminal eliminada con éxito')

      setAlert({
        ...newAlert,
        onConfirm: () => {
          onConfirm()
          if (!hasErrorDelete) {
            onClose(true)
          }
        },
      })
    }
  }, [loadingDelete])

  const onSetWarehouse = (ws, get) => {
    const w = ws.find(w => w.value === warehouseId)
    if (get) return w
    else setTerminal({ ...terminal, warehouse: w })
  }

  const onClose = success => {
    setAlert({ ...alert, show: false })
    setTerminal(emptyTerminal)
    setOpen(false)
    onHide(success)
  }

  return (
    <div>
      <Modal show={open} size={'lg'} centered onHide={() => onClose()}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col xl={6} md={6} sm={12}>
              <ImageInput
                imageType={imageTypes.POS_TERMINAL}
                src={terminal.src}
                placeholder={posType.image}
                disabled={loadingCreate || loadingUpdate || loadingDelete}
                entityId={id}
                onSuccessFile={url => {
                  setTerminal({ ...terminal, src: { url } })
                }}
              />
            </Col>
            <Col xl={6} md={6} sm={12}>
              <Row>
                <Col xl={12}>
                  <FormText
                    disabled={loadingCreate || loadingUpdate || loadingDelete}
                    label={'Nombre (opcional)'}
                    value={terminal.number}
                    changeValue={number => setTerminal({ ...terminal, number })}
                  />
                </Col>
                <Col xl={12}>
                  <Select
                    disabled={!free || loadingCreate || loadingUpdate || loadingDelete}
                    label={'Tipo'}
                    options={terminalTypes}
                    value={terminal.type}
                    onChange={type => setTerminal({ ...terminal, type })}
                    required
                    subText={
                      !free &&
                      'No es posible editar el tipo de la terminal ya que la terminal está ocupada.'
                    }
                  />
                </Col>
                {!isLimit && (
                  <Col xl={12}>
                    <Select
                      disabled={!free || loadingCreate || loadingUpdate || loadingDelete}
                      label={'Bodega asignada'}
                      options={warehouses}
                      value={terminal.warehouse}
                      onChange={warehouse => setTerminal({ ...terminal, warehouse })}
                      required
                      subText={
                        !free &&
                        'No es posible editar la bodega de la terminal ya que la terminal está ocupada.'
                      }
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Row className={'container-buttons'}>
            {id && (
              <Button
                loading={loadingDelete}
                disabled={loadingCreate || loadingUpdate}
                icon={faTrash}
                color={'secondary'}
                onClick={() => {
                  if (!free)
                    setAlert({
                      ...handlerError(
                        'No es posible eliminar la terminal ya que está ocupada',
                      ),
                      onConfirm: setAlert({ ...alert, show: false }),
                    })
                  else dispatch(deleteTable(id))
                }}>
                Eliminar
              </Button>
            )}

            <Button
              loading={loadingCreate || loadingUpdate}
              disabled={(!terminal.warehouse && !isLimit) || loadingDelete}
              icon={faSave}
              onClick={() => {
                if (!id) {
                  dispatch(
                    createTable({
                      capacity: 1,
                      warehouse: isLimit ? company.warehouse : terminal.warehouse.value,
                      type: terminal.type.value,
                      posId,
                      tableName: terminal.number,
                      url: terminal.src.url,
                    }),
                  )
                } else {
                  dispatch(
                    setInfo(
                      userId,
                      isLimit ? company.warehouse : terminal.warehouse.value,
                      terminal.type.value,
                      id,
                      terminal.number,
                    ),
                  )
                }
              }}>
              Guardar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Alert {...alert} />
    </div>
  )
}
export default POSTerminal
