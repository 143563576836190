import { actionTypes } from '../actions/expenses.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  expense: {},
  expenseType: [],
  expenses: [],
  adminExpenses: [],
  updateResult: [],
  expensesProducts: [],
  expenseLog: [],
}

const ExpensesReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_EXPENSE_TYPE):
      return {
        ...state,
        expenseType: extract(action.expenseType, []),
      }
    case successState(actionTypes.GET_EXPENSES):
      return {
        ...state,
        expenses: extract(action.expenses, []),
      }
    case successState(actionTypes.GET_EXPENSES_PRODUCTS):
      return {
        ...state,
        expensesProducts: extract(action.expensesProducts, []),
      }
    case successState(actionTypes.GET_EXPENSES_ADMIN):
      return {
        ...state,
        expenses: extract(action.expenses, []),
        adminExpenses: extract(action.expenses, []),
      }
    case successState(actionTypes.CREATE_EXPENSE):
      return {
        ...state,
        expense: extract(action.expense, {}),
      }
    case successState(actionTypes.UPDATE_EXPENSE):
      return {
        ...state,
        updateResult: extract(action.result, []),
      }
    case successState(actionTypes.CREDIT_EXPENSE):
      return {
        ...state,
        expense: extract(action.expense, {}),
      }
    case successState(actionTypes.GET_EXPENSE_LOG):
      console.log('in Reducer', action.expenseLog)
      return {
        ...state,
        expenseLog: extract(action.expenseLog, []),
      }
    default:
      return state ? state : initialState
  }
}

export default ExpensesReducer
