import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CustomCreate from 'src/components/inputs/Creable/CustomCreate'

import { getClientsSimple, onCreateClientStep } from 'src/actions/clients.actions'
import { simpleClientsV2 } from 'src/selectors/clients.selector'

import { setModalCreateClient } from 'src/actions/utilities.actions'

import { selectIdentificationTypes } from 'src/selectors/company.selector'
import { selectCurrentUser } from 'src/selectors/user.selector'

import { selectCurrentUserRoles } from 'src/selectors/modules.selector'

import { identificationTypesEnum } from 'src/enums/identificationTypes'

import clientsService from 'src/services/clients.service'

import { loadingSelector } from 'src/selectors/loading.selector'
import { showAlert } from 'src/actions/alert.actions'

interface IProps {
  actionType: string
  label?: string
  value: any
  identifications?: { [key in identificationTypesEnum]?: IdentificationOption }
  info?: string
  className?: string
  onChange: (value, identifications) => void
  onCreateOption?: (value) => void
  disabled?: boolean
  showAllOption?: boolean
  allClients?: boolean
  init?: boolean
  noCreate?: boolean
}

const allOption = { value: null, label: '- Todos -' }

/**
 * Select client component
 * @prop {string} actionType - Action type to show loading
 * @prop {string} label - Label to show in the component
 * @prop {object} value - Value of the component
 * @prop {array} identifications - Identifications of the client
 * @prop {string} info - Info to show in the component
 * @prop {string} className - Class name of the component
 * @prop {function} onChange - Function to execute when the value changes
 * @prop {function} onCreateOption - Function to execute when the user creates a new option
 * @prop {boolean} disabled - If true, the component is disabled
 * @prop {boolean} showAllOption - If true, the component will show an option to select all
 */
const SelectClient = ({
  actionType,
  label = 'Seleccionar cliente',
  value,
  identifications = {},
  info,
  className = '',
  onChange,
  onCreateOption,
  disabled,
  showAllOption,
  allClients = false,
  init = false,
  noCreate = false,
}: IProps) => {
  const dispatch = useDispatch()

  const roles = useSelector(selectCurrentUserRoles)

  const clients = useSelector(simpleClientsV2)
  const identificationTypes = useSelector(selectIdentificationTypes)
  const user = useSelector(selectCurrentUser)

  const loading = useSelector(state => loadingSelector([actionType])(state))

  const [clientsData, setClientsData] = useState([])

  const attendantID: number = allClients ? null : user.id

  useEffect(() => {
    if (!init) return
    if (!roles || roles.length <= 0) return
    if (clients.length <= 0)
      dispatch(getClientsSimple({ attendant: attendantID, size: 100 }))
  }, [roles?.length])

  useEffect(() => {
    setClientsData(clients)
  }, [clients])

  const formatClients = clients => {
    const formattedClients = clients?.map(client => ({
      ...client,
      value: client.id,
      label: `${client.code || ''} - ${client.companyName}`,
    }))

    if (clients && showAllOption) formattedClients.unshift(allOption)
    return formattedClients
  }

  return (
    <CustomCreate
      disabled={disabled}
      label={label}
      className={className}
      async
      dontClear
      options={clientsData}
      isLoading={loading}
      placeholder={'Buscar o seleccionar'}
      textLabel={noCreate ? 'Buscando...: ' : 'Nuevo cliente: '}
      value={value}
      info={info}
      style={{ margin: 0 }}
      onChange={newClient => {
        if (newClient.locked)
          return dispatch(
            showAlert({
              title: 'El cliente no tiene autorización de ventas',
              text: `Razón: ${
                newClient.lockMessage || 'Sin razón'
              }\nContáctese con el administrador`,
              type: 'error',
              confirmButtonText: 'Cerrar',
              confirmButtonColor: '#B35796',
            }),
          )

        const identifications = {}
        identificationTypes.forEach(
          identificationType =>
            (identifications[identificationType.id] = {
              identificationTypeId: identificationType.id,
              value: '',
            }),
        )
        newClient.identifications?.forEach(
          identification =>
            (identifications[identification.identificationTypeId] = identification),
        )

        onChange(newClient, identifications)
      }}
      onCreateOption={name => {
        if (noCreate) return
        dispatch(setModalCreateClient({ show: true, onCreateOption }))
        dispatch(
          onCreateClientStep({
            companyName: name,
            businessName: name,
            identifications,
            email: value.email,
            address: value.address,
          }),
        )
      }}
      loadOptions={async searchItem => {
        if (searchItem == null || searchItem === '')
          return showAllOption ? [allOption, ...clients] : clients

        const data: unknown[] = await clientsService.getClientsSimple({
          attendant: attendantID,
          size: 100,
          search: searchItem,
        })
        const formattedClients = formatClients(data)
        if (data.length > 0) setClientsData(formattedClients)

        return formattedClients
      }}
    />
  )
}

export default SelectClient
