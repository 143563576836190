import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {loadingSelector} from "../../../selectors/loading.selector";
import {actionTypes, clientStatusReport} from "../../../actions/clients.actions";
import {selectClientStatusReport} from "../../../selectors/clients.selector";
import Card from "../../../components/cards/Card";
import {Col, Row} from "react-bootstrap";
import plus from "../../../assets/images/plus.svg";
import CustomSelect from "../../../components/inputs/Select/CustomSelect";
import {getUsersChildrenByModule} from "../../../actions/modules.actions";
import {selectCurrentModule} from "../../../selectors/user.selector";
import {selectUsers} from "../../../selectors/modules.selector";
import SweetAlert from "sweetalert-react";
import {TableV2, Title} from "../../../components";

const ClientReport = () =>{

    const dispatch = useDispatch();
    const[items,setItems] = useState([{start: new Date(), end: new Date(), user: {value: null, label: 'Seleccione un usuario'}}]);
    const [actions, setActions] = useState({loading: false});
    const [alert, setAlert] = useState({data: {}, show: false, title: 'Faltan filtros', text: 'Seleccione un usuario en todas las tablas',
        type: 'info', onConfirm: () => console.log('default')})

    const loading = useSelector((state) => loadingSelector([actionTypes.STATUS_REPORT])(state));
    const reports = useSelector(selectClientStatusReport);
    const module = useSelector(selectCurrentModule)
    const users = useSelector(selectUsers)



    useEffect(()=>{
        dispatch(getUsersChildrenByModule(module, true))
        setup()
    },[]);

    useEffect(() => {
        if (loading) {
            if (!actions.loading) return setActions({...actions, loading: true});
        } else if (actions.loading) {
            if(reports){
                reports.map((p, index) => {
                    items[index] = {...items[index], resumes: p.resumes}
                })
                setItems([...items])
                setActions({...actions, loading: false})
            }
        }
    }, [loading]);

    const setup = (cards) => {
        let request = cards ? cards.map(i => {return {SDate: i.start.valueOf(), EDate: i.end.valueOf(), user: i.user.value || 0}})
            : items.map(i => {return {SDate: i.start.valueOf(), EDate: i.end.valueOf(), user: i.user.value || 0}})
        dispatch(clientStatusReport(request))
    }

    const onDateFiltersChanged = (start, end, p, index) =>{
        p.start = start
        p.end = end
        items[index] = p
        setItems([...items])
        setup(items)
    }

    const onChange = (data, p, index) =>{
        p.user = data
        items[index] = p
        setItems([...items])
        setup(items)
    }

    const renderButton = (item, index) => {
        return(
            <div style={{width: 20, backgroundColor: 'rgba(237,70,45,0.8)', borderRadius: 5, margin: 10}}>
                <h6 className={'gallery-delete'} style={{margin: 0}} onClick={() => {
                    setItems(items.filter((f, i) => i !== index))}}
                >X</h6>
            </div>
        )
    }

    return(
        <div>
            <div>
                <Title title={'Reporte de Clientes'}/>
            </div>
            <Row>
                {
                    items.map((p, index) =>
                        <Col sm={12} md={4} key={`${index}`}>
                            <Card button={index!==0 && renderButton(p, index)} buttonStyle={{display: 'flex', justifyContent: 'flex-end'}}
                              >  <CustomSelect
                                    value={p.user}
                                    label={'Usuario'}
                                    placeholder={'Usuario'}
                                    options={users}
                                    onChange={(data) => onChange(data, p, index)}
                                />
                                <TableV2
                                    dateFilter
                                    onDateChange={(start, end) => onDateFiltersChanged(start, end, p, index)}
                                    dateFrom={ p.start}
                                    dateTo={ p.end}
                                    items={p.resumes}
                                    tableDivStyle={{height: index!== 0 ? 265 : 300, overflowY: 'scroll'}}
                                    mobileAuto
                                    storageKey={`clientReport`}
                                    headers={[
                                        { label: 'Código', show: true, value: ['code'], type: 'text', className: 'mini' },
                                        { label: 'Nombre', show: true, value: ['name'], type: 'text', className: 'mini' },
                                        {
                                            label: 'Estado', show: true, value: ['estadoC'], type: 'text', className: 'mini',
                                            custom: (item) => item.estadoC = item.active ? 'Activo' : 'Inactivo'
                                        },
                                        {
                                            show: true, label: 'Nuevo', value: ['nuevoC'], type: 'text', className: 'mini center', classNameCustom: (item) => `mini center t-green`,
                                            custom: (item) =>item.nuevoC = item.new ? 'Nuevo' : '---'
                                        },
                                        { config: true, show: true, label: '', className: 'mini center' }
                                    ]}
                                />
                            </Card>
                        </Col>
                    )
                }
                <Col sm={12} md={3} onClick={() => {
                    let empty = items.filter(i => !i.user.value).length
                    if(empty > 0){
                        alert.show = true;
                        alert.onConfirm = () => {setAlert({...alert, show: false})};
                        setAlert({...alert})
                        return
                    }
                    let array = [...items, {start: new Date(), end: new Date(), user: {value: null, label: 'Seleccione un usuario'}}]
                    setItems(array)
                    setup(array)
                }}
                  >  <Card style={{height: 650, backgroundColor: 'rgb(205 247 212)'}}
                          bodyStyle={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div>
                            <Row className={"no-margins center"}>
                                <img className={'badge-image'} src={plus} alt=""/>
                            </Row>
                            <Row className={"no-margins center"}>
                                <h4 style={{marginTop: 5}}><b>AGREGAR COMPARACIÓN</b></h4>
                            </Row>
                        </div>
                    </Card>
                </Col>
            </Row>

            <SweetAlert {...alert} />
        </div>
    )
}

export default ClientReport;
