import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Col, Row, Modal } from 'react-bootstrap'
import { Td, Tr } from 'react-super-responsive-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'
import Alert from 'sweetalert-react'

import {
  Card,
  FormText,
  Button,
  Select,
  Icon,
  Geocoding,
  TableV2,
} from '../../../components'

import {
  getWarehousesTypes,
  createSingleWarehouse,
  getSingleWarehouse,
  updateSingleWarehouse,
  actionTypes as typesWarehouse,
  getWarehouses,
} from '../../../actions/warehouse.actions'
import {
  selectAllWarehousesTypesForSelect,
  selectCreateWarehouse,
  selectSingleWarehouse,
  selectUpdateWarehouse,
  selectAllWarehousesForSelect,
} from '../../../selectors/warehouse.selector'

import { getUsersChildrenByModule } from '../../../actions/modules.actions'
import { selectUsersToSelect } from '../../../selectors/modules.selector'
import { getAllLocalities } from '../../../actions/address.actions'
import { selectAllLocalitiesForSelect } from '../../../selectors/address.selector'

import { selectCurrentModule } from '../../../selectors/user.selector'

import { loadingSelector } from '../../../selectors/loading.selector'
import {
  hasErrorsSelector,
  singleErrorSelector,
  handlerSuccess,
  handlerError,
} from '../../../selectors/error.selector'

import WarehouseIcon from '../../../assets/images/svg/warehouse.svg'
import {
  faAddressCard,
  faExclamationCircle,
  faFileCode,
  faMapMarker,
  faPlusCircle,
  faSave,
  faSignOutAlt,
  faTimes,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import CSVLoaderV2, { CATEGORY_UPLOAD } from '../../../components/CSVLoader/CSVLoaderV2'
import CustomSelect from '../../../components/inputs/Select/CustomSelect'
import ButtonIcon from '../../../components/buttons/IconButton'
import CollapsibleCard from '../../../components/cards/collapsible-card/CollapsibleCard'

class CreateWarehouse extends Component {
  state = {
    alert: { title: '' },
    warehouse: {},
    defaultWarehouse: {},
    errors: {},
    created: false,
    warehousesAccessible: [],
    modal: false,
    selected: {},
    attendants: [],
    selectedAttendant: false,
  }

  componentDidMount() {
    const {
      match,
      getTypes,
      getWarehouses,
      getUsers,
      getAllLocalities,
      module,
      getSingleWarehouse,
    } = this.props
    if (match.params && match.params.id) getSingleWarehouse(match.params.id)
    getTypes()
    getWarehouses(module === 15000)
    getUsers(module, true)
    getAllLocalities()
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { loadingS, history } = this.props
    const { singleWarehouse } = next
    if (loadingS && !next.loadingS) {
      if (singleWarehouse != null) {
        const { typeData, localityData, attendantData, accessible } = singleWarehouse

        let warehousesAccessible = []
        let warehouse = { ...singleWarehouse }
        if (typeData) warehouse.type = { value: typeData.id, label: typeData.name }
        if (localityData)
          warehouse.town = { value: localityData.id, label: localityData.name }
        if (attendantData)
          warehouse.attendant = { value: attendantData.id, label: attendantData.name }
        if (accessible && accessible.length > 0)
          accessible.map(a => warehousesAccessible.push(a))

        this.setState({
          warehouse,
          defaultWarehouse: Object.assign({}, warehouse),
          warehousesAccessible,
        })
      }
    }

    const { updateIsLoading, updateHasError } = this.props
    const { updateError } = next

    if (updateIsLoading && !next.updateIsLoading) {
      let alert = { title: 'd' }
      if (!updateHasError && next.updateHasError) {
        alert = handlerError(updateError.message || 'Ha ocurrido un problema inesperado')
        alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
      } else {
        alert = handlerSuccess(
          'La información de la bodega ha sido actualizada satisfactoriamente',
        )
        alert.onConfirm = () => {
          this.setState({ alert: { ...alert, show: false } })
          history.goBack()
        }
      }
      this.setState({ alert })
    }

    const { createIsLoading, createHasError } = this.props
    const { createError } = next
    if (createIsLoading && !next.createIsLoading) {
      let alert = { title: 'd' }
      if (!createHasError && next.createHasError) {
        alert = handlerError(createError.message || 'Ha ocurrido un problema inesperado')
        alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
      } else {
        alert = handlerSuccess('La bodega ha sido creada satisfactoriamente')
        alert.onConfirm = () => {
          this.setState({ alert: { ...alert, show: false } })
          history.goBack()
        }
      }
      this.setState({ alert })
    }
  }

  onChange = event => {
    const { warehouse } = this.state
    const { value, name } = event.target
    warehouse[name] = value
    this.setState({ warehouse })
  }

  handleCheckBoxChange = event => {
    const { name, checked } = event.target
    let { warehouse } = this.state
    warehouse[name] = checked
    this.setState({ warehouse })
  }

  warehouseFilter = item => {
    const { warehousesAccessible } = this.state
    const warehouse = warehousesAccessible.find(w => w.id === item.id && !w.deleted)

    return !warehouse || item.deleted
  }

  selectWarehouse = id => {
    const { warehouses } = this.props
    let warehouse = {
      id: 0,
      name: '---',
      code: '---',
      attendantData: { name: '---' },
      warehouseId: id,
    }

    const w = warehouses.find(wa => wa.id === id)
    if (w) warehouse = { ...w, warehouseId: id }

    return warehouse
  }

  save = () => {
    const { warehouse, warehousesAccessible, attendants } = this.state
    const { name, address, type, town, restricted, latitude, longitude } = warehouse
    let errors = {}
    if (!name) errors.name = 'Es requerido'
    if (!address) errors.address = 'Es requerido'
    if (!type || !type.value) errors.type = 'Es requerido'
    if (!attendants || attendants.length == 0) errors.attendant = 'Es requerido'
    if (!town || !town.value) errors.town = 'Es requerido'
    if (restricted && warehousesAccessible.length <= 0) errors.restricted = 'Es requerido'
    if (type.value === 1) {
      if (!latitude) errors.latitude = 'Es requerido'
      if (!longitude) errors.longitude = 'Es requerido'
    }

    let ids = attendants.map(d => d.id)

    if (Object.keys(errors).length === 0) {
      let request = Object.assign({}, warehouse)
      request.type = warehouse.type.value
      request.town = warehouse.town.value
      request.listAttendants = ids
      request.restricted = warehouse.restricted
      const principal = attendants.find(a => a.principal)
      if (principal) request.principal = principal.value

      const { id } = this.props.match.params

      if (id) this.update(request)
      else this.create(request)
    }
    this.setState({ errors })
  }

  create = warehouse => {
    const { warehousesAccessible } = this.state
    const { module } = this.props
    let accessible = []
    if (warehouse.restricted) {
      accessible = warehousesAccessible
        .filter(w => !w.deleted)
        .map(d => ({ id: null, warehouseId: d.id, deleted: false }))
    }
    warehouse.accessible = accessible
    warehouse.kolo = module === 15000
    return this.props.createSingleWarehouse(warehouse)
  }

  update = request => {
    const { warehousesAccessible } = this.state
    const { singleWarehouse } = this.props
    let accessible = []
    if (request.restricted) {
      warehousesAccessible.map(wa => {
        if (wa.deleted) {
          const sa = singleWarehouse.accessible.find(
            sa => sa.warehouseId === wa.warehouseId,
          )
          if (sa) {
            accessible.push({ ...sa, warehouseId: wa.warehouseId, deleted: true })
          }
        } else {
          let access = { id: null, warehouseId: wa.warehouseId, deleted: false }
          singleWarehouse.accessible.map(a => {
            if (access.warehouseId === a.warehouseId) access.id = a.id
          })
          accessible.push(access)
        }
      })
    }
    request.accessible = accessible

    return this.props.updateWarehouse(request.id, request)
  }

  onSelectUser = selectedUser => {
    const { attendants } = this.state
    if (attendants.length === 0) selectedUser.principal = true
    attendants.push(selectedUser)
    this.setState({ attendants })
  }

  deleteUser = id => {
    let { attendants } = this.state
    attendants = attendants.filter(p => id !== p.value)
    this.setState({ attendants })
  }

  render() {
    const {
      warehouse,
      attendants,
      defaultWarehouse,
      errors,
      created,
      warehousesAccessible,
      alert,
      modal,
      selected,
    } = this.state

    const { id } = this.props.match.params
    const title = id ? 'Edición de Bodega' : 'Creación de Bodega'

    const edited = id !== undefined && defaultWarehouse.id !== undefined

    const { warehouses, users, towns, types, createIsLoading, updateIsLoading, module } =
      this.props

    return (
      <div>
        <h1 className={'dashboard-welcome'}>{title}</h1>
        <Row>
          {edited ? (
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              <Card ribbon={defaultWarehouse.active ? 'Inactivo' : 'Activo'}>
                <Row style={{ justifyContent: 'center' }}>
                  <img
                    src={WarehouseIcon}
                    alt={'client profile'}
                    className={'client-picture'}
                  />
                </Row>
                <Row style={{ justifyContent: 'center' }}>
                  <span className={'client-name'}>{defaultWarehouse.name}</span>
                </Row>
                <Row className={'flex-container space-between'}>
                  <Col xs={4} className={'label'}>
                    Código:
                  </Col>
                  <Col className={'description'}>
                    {defaultWarehouse.code || 'No asignado'}
                  </Col>
                </Row>
                <Row className={'flex-container space-between'}>
                  <Col xs={4} className={'label'}>
                    Localidad:
                  </Col>
                  <Col className={'description'}>{defaultWarehouse.town.label}</Col>
                </Row>
                <Row className={'flex-container space-between'}>
                  <Col xs={4} className={'label'}>
                    Dirección:
                  </Col>
                  <Col className={'description'}>{defaultWarehouse.address}</Col>
                </Row>
                <hr />
                <Row className={'flex-container space-between'}>
                  <Col xs={4} className={'label'}>
                    Tipo de bodega:
                  </Col>
                  <Col className={'description'}>{defaultWarehouse.type.label}</Col>
                </Row>
                <hr />
                <Row className={'flex-container space-between'}>
                  <Col xs={4} className={'label'}>
                    Encargado:
                  </Col>
                  <Col className={'description'}>Pendiente</Col>
                </Row>
              </Card>
            </Col>
          ) : (
            <Col lg={4}>
              <Row>
                <Col xl={12} md={12}>
                  {module !== 15000 && (
                    <CSVLoaderV2
                      category={CATEGORY_UPLOAD.WAREHOUSES}
                      title={'Carga CSV Bodegas'}
                      // line
                      // showInfo
                    />
                  )}
                </Col>
                {warehouse.type && warehouse.type.value === 1 && (
                  <Col xl={12} md={12}>
                    <Geocoding
                      lat={14.55}
                      lng={-90.55}
                      auto
                      editable
                      getData={data => {
                        const { latLng } = data
                        this.onChange({ target: { value: latLng.lat, name: 'latitude' } })
                        this.onChange({
                          target: { value: latLng.lng, name: 'longitude' },
                        })
                      }}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          )}
          <Col lg={8}>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <Card title={'Datos de la bodega'}>
                  <Row>
                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <FormText
                        prepend={
                          <Icon icon={faAddressCard} tooltip={'Nombre de la bodega'} />
                        }
                        name={'name'}
                        label={'Nombre de la bodega'}
                        placeholder={'pj. bodega central'}
                        value={warehouse.name}
                        onChange={this.onChange}
                        error={errors.name}
                        required
                      />
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <CustomSelect
                        label={'Encargados de bodega'}
                        value={{ value: null, label: 'Encargado de bodega' }}
                        options={users.filter(s => {
                          return (
                            this.state.attendants.filter(f => f.value === s.value)
                              .length === 0
                          )
                        })}
                        error={errors.attendant}
                        onChange={this.onSelectUser}
                      />
                    </Col>

                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <br />
                      <label className={'checkbox-label'}>
                        <input
                          type={'checkbox'}
                          name={'restricted'}
                          checked={warehouse.restricted}
                          onChange={this.handleCheckBoxChange}
                        />{' '}
                        {warehouse.restricted
                          ? 'Restringido: Solo las bodegas listadas podran transferir productos.'
                          : 'Libre: Todas las bodegas pueden transferir productos.'}
                      </label>
                    </Col>
                    <Col xl={12} lg={12} md={12}>
                      <Row className={'pl-1 mt-2'}>
                        {attendants &&
                          attendants.map((attendant, index) => (
                            <div className={'user-tag  ml-2'} key={index}>
                              <label className={'label-user-tag'}>
                                {attendant.label}
                              </label>
                              <ButtonIcon
                                className={'delete-user-tag'}
                                icon={faTimes}
                                tooltip={'Quitar'}
                                color={'white'}
                                onClick={() => this.deleteUser(attendant.value)}
                              />
                            </div>
                          ))}
                      </Row>
                    </Col>
                    <Col xl={12} lg={12} md={12}>
                      {attendants && attendants.length > 0 && (
                        <CustomSelect
                          info={
                            'Cuando una orden kolo es creada, el usuario encargado de la orden seran el encargado principal de la bodega'
                          }
                          options={attendants}
                          value={attendants.find(a => a.principal)}
                          label={'Asignar encargado principal'}
                          onChange={a => {
                            const atts = attendants.map(at => {
                              at.principal = a.value === at.value
                              return at
                            })
                            this.setState({ attendants: atts })
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                </Card>
              </Col>
              {warehouse.restricted && (
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Card title={'Bodegas permitidas'}>
                    <Row className={'container-buttons'}>
                      <Button
                        disabled={createIsLoading || updateIsLoading}
                        onClick={() => this.setState({ modal: true })}
                        variant={'info'}
                        icon={faPlusCircle}>
                        {' '}
                        Agregar
                      </Button>
                    </Row>
                    {errors.restricted != undefined && (
                      <div className="input-error">
                        <FontAwesomeIcon icon={faExclamationCircle} /> {errors.restricted}
                      </div>
                    )}
                    <TableV2
                      headers={[
                        { label: 'Código', show: true },
                        { label: 'Nombre', show: true },
                        { label: 'Acción', show: true },
                      ]}
                      items={warehousesAccessible.filter(
                        d => d.deleted == null || d.deleted === false,
                      )}
                      renderRow={(item, index) => {
                        const warehouse = this.selectWarehouse(item.warehouseId)
                        return (
                          <Tr className={'data'} key={index}>
                            <Td className={'mini'}>{warehouse.code}</Td>
                            <Td className={'medium'}>{warehouse.name}</Td>
                            <Td className={'mini'}>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                }}>
                                {' '}
                                <Icon
                                  icon={faTrashAlt}
                                  tooltip={'descartar'}
                                  onClick={() => {
                                    warehousesAccessible.map((wa, i) => {
                                      if (wa.warehouseId === item.warehouseId) {
                                        wa.deleted = true
                                      }
                                    })
                                    this.setState({ warehousesAccessible })
                                  }}
                                />
                              </div>
                            </Td>
                          </Tr>
                        )
                      }}
                    />
                  </Card>
                </Col>
              )}
              <Col>
                <br />
                <Row className={'container-buttons'} z>
                  <Button
                    loading={createIsLoading || updateIsLoading}
                    onClick={() => (created ? this.back() : this.save())}>
                    <FontAwesomeIcon icon={created ? faSignOutAlt : faSave} />
                    {id ? '   Actualizar' : created ? '   Salir' : '   Crear'}
                  </Button>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Modal
          show={modal}
          size={'md'}
          centered
          onHide={() => this.setState({ modal: false })}>
          {' '}
          <Modal.Header closeButton>
            <Modal.Title>Seleccione una bodega</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={'column'}>
              <Select
                label={'Selecionar...'}
                placeholder={'selecionar...'}
                options={warehouses.filter(this.warehouseFilter)}
                value={selected}
                onChange={value => {
                  let count = 0
                  warehousesAccessible
                    .filter(w => w.warehouseId === value.value)
                    .map(w => {
                      w.deleted = false
                      count++
                    })

                  if (count <= 0)
                    warehousesAccessible.push({
                      ...value,
                      warehouseId: value.value,
                      deleted: false,
                    })
                  this.setState({
                    warehousesAccessible,
                    modal: false,
                    selected: { value: null },
                  })
                }}
              />
            </div>
          </Modal.Body>
        </Modal>

        <Alert {...alert} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  types: selectAllWarehousesTypesForSelect(state),
  users: selectUsersToSelect(state),
  towns: selectAllLocalitiesForSelect(state),
  module: selectCurrentModule(state),
  warehouses: selectAllWarehousesForSelect(state),
  createResponse: selectCreateWarehouse(state),
  singleWarehouse: selectSingleWarehouse(state),
  updateResponse: selectUpdateWarehouse(state),
  loadingS: loadingSelector([typesWarehouse.GET_SINGLE_WAREHOUSE])(state),
  createIsLoading: loadingSelector([typesWarehouse.CREATE_WAREHOUSE])(state),
  createHasError: hasErrorsSelector([typesWarehouse.CREATE_WAREHOUSE])(state),
  createError: singleErrorSelector([typesWarehouse.CREATE_WAREHOUSE])(state),
  updateIsLoading: loadingSelector([typesWarehouse.UPDATE_WAREHOUSE])(state),
  updateHasError: hasErrorsSelector([typesWarehouse.UPDATE_WAREHOUSE])(state),
  updateError: singleErrorSelector([typesWarehouse.UPDATE_WAREHOUSE])(state),
  loadingW: loadingSelector([typesWarehouse.GET_ALL_WAREHOUSES])(state),
})
const mapDispatchToProps = dispatch => ({
  getTypes: () => dispatch(getWarehousesTypes()),
  getUsers: (module, includeParent) =>
    dispatch(getUsersChildrenByModule(module, includeParent)),
  getAllLocalities: () => dispatch(getAllLocalities()),
  createSingleWarehouse: warehouse => dispatch(createSingleWarehouse(warehouse)),
  getSingleWarehouse: id => dispatch(getSingleWarehouse(id)),
  updateWarehouse: (id, request) => dispatch(updateSingleWarehouse(id, request)),
  getWarehouses: isKolo => dispatch(getWarehouses(false, isKolo)),
})
export default connect(mapStateToProps, mapDispatchToProps)(CreateWarehouse)
