import './ProductProfile.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import IconBack from 'src/assets/images/newDesign/arrow-left-black.svg'
import IconCart from 'src/assets/images/newDesign/icon-cart.svg'
import { Badge, Row, Col, Carousel, Modal } from 'react-bootstrap'
import IconMinus from 'src/assets/images/newDesign/icon-minus.svg'
import IconPlus from 'src/assets/images/newDesign/icon-plus.svg'

import IconLeft from 'src/assets/images/newDesign/arrow-left-gray.svg'
import IconRight from 'src/assets/images/newDesign/arrow-right-gray.svg'
import { formatNumberWithCommas } from 'src/utils/formatters'

import PlaceholderProduct from 'src/assets/images/newDesign/placeholder-product.svg'
import { selectShopping } from 'src/selectors/shopping.selector'
import { addItem } from 'src/actions/shopping.actions'
import { handlerError } from 'src/selectors/error.selector'
import Alert from 'sweetalert-react'
import Money from 'src/components/Money/Money'

class ProductProfile extends Component {
  state = {
    alert: { title: 'default' },
    product: {},
    showModal: false,
  }

  componentDidMount() {
    const { product } = this.props.history.location.state
    this.setState({ product })

    window.scrollTo(0, 0)
  }

  goTo = (item, route) => {
    const { history, match } = this.props
    const url = `${match.url}${route}/${item.productId}`
    history.push(url, { product: item })
  }

  addItem = product => {
    let alert = handlerError(product.productData.name + ' añadido al carrito!')
    alert.show = true
    alert.type = 'success'
    alert.title = 'Información'
    alert.confirmButtonText = 'Aceptar'

    alert.onConfirm = () =>
      this.setState({ alert: { ...alert, show: false } }, () => {
        const item = {
          id: product.productId,
          name: product.productData.name,
          description: product.productData.description,
          offerId: product.offerId,
          companyName: product.companyName,
          companyId: product.productData.companyId,
          price: product.productData.price,
          quantity: product.quantity,
          subTotal: product.quantity * parseFloat(product.productData.price),
          images: product.images,
          thumbnail: product.thumbnail,
          deliveryDate: product.deliveryDate,
          polygonRoute: product.polygonRoute,
        }

        this.props.addItem(item, this.props.shopping)
      })
    this.setState({ alert })
  }

  upQuantity = () => {
    let { product } = this.state
    let quantity = parseInt(product.quantity || 0)

    // if(quantity < (product.existence - (product.quantityInCart || 0))){
    product.quantity = quantity + 1
    // }

    this.setState({ product })
  }

  downQuantity = () => {
    let { product } = this.state
    let quantity = parseInt(product.quantity || 0)

    if (quantity > 0) {
      product.quantity = quantity - 1
    }

    this.setState({ product })
  }

  goTo = route => {
    const { history } = this.props
    const url = `/cliente/productos${route}`
    history.push(url)
  }

  isLandscape = () => {
    return window.innerHeight < window.innerWidth
  }

  render() {
    const { product, alert } = this.state
    const { shopping } = this.props
    const { productData } = product

    return (
      <div className="content">
        {/*<div className={"button-cart"} onClick={()=> this.goTo('/compra')}>*/}
        {/*    <img className={'icon-cart'} src={IconCart} alt={"Cart"}/>*/}
        {/*    {*/}
        {/*        shopping.length > 0 &&*/}
        {/*        <Badge className={"badge"} pill variant="danger">{shopping.length}</Badge>*/}
        {/*    }*/}
        {/*</div>*/}

        <div className={'button-back'} onClick={() => window.history.back()}>
          <img className={'icon-back'} src={IconBack} alt={'Back'} />
        </div>

        <label className={'title title-back'}>Producto</label>

        <Row className={'product-profile-card no-margins'}>
          <Col md={5} className={'no-margins'}>
            <Row className={'no-margins center'}>
              <Col>
                <Carousel
                  style={{ margin: 0, padding: 0 }}
                  prevIcon={<img src={IconLeft} alt={'Next'} />}
                  nextIcon={<img src={IconRight} alt={'Next'} />}
                >
                  {product.images && product.images.length > 0 ? (
                    product.images.map((item, index) => {
                      return (
                        <Carousel.Item>
                          <img
                            className={'product-image-profile'}
                            src={item.url}
                            alt=""
                            onClick={() => {
                              this.setState({
                                currentImage: item.url,
                                showModal: true,
                              })
                            }}
                          />
                        </Carousel.Item>
                      )
                    })
                  ) : (
                    <Carousel.Item>
                      <img
                        className={'product-image-profile'}
                        src={PlaceholderProduct}
                        alt=""
                      />
                    </Carousel.Item>
                  )}
                </Carousel>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className={'no-margins'}>
              <label className={'product-title product-title-profile'}>
                {productData ? productData.name : ''}
              </label>
            </Row>

            <Row className={'no-margins'}>
              <label className={'product-category product-category-profile no-margins'}>
                Productor: {productData ? productData.companyData.name : ''}
              </label>
            </Row>

            {/*<Row className={"no-margins"}>*/}
            {/*    <label className={"product-category no-margins"}>Categoría: Categoría</label>*/}
            {/*</Row>*/}

            <Row className={'no-margins'}>
              <label className={'product-description-profile'}>
                {(productData && productData.description) ||
                  'No hay descripción del producto disponible.'}
              </label>
            </Row>

            <Row className={'no-margins'}>
              <Money component="span" className={'product-price product-price-profile'}>
                {productData ? `Precio: ${productData.price}` : ''}
              </Money>
            </Row>

            <Row className={'no-margins'}>
              <Col md={6} className={'no-margins'}>
                {product.existence !== 0 ? (
                  <Row className={'no-margins pp-quantity'}>
                    <div
                      className={'button-quantity center'}
                      style={{ marginLeft: 0 }}
                      onClick={() => {
                        this.downQuantity(product)
                      }}
                    >
                      <img className={'icon-quantity'} src={IconMinus} />
                    </div>
                    <div className={'product-quantity'}>{product.quantity || 0}</div>
                    <div
                      className={'button-quantity center'}
                      onClick={() => {
                        this.upQuantity(product)
                      }}
                    >
                      <img className={'icon-quantity'} src={IconPlus} />
                    </div>
                  </Row>
                ) : (
                  <Row className={'no-margins'}>
                    <label
                      className={'product-category product-category-profile'}
                      style={{ padding: 0 }}
                    >
                      No hay existencia
                    </label>
                  </Row>
                )}
              </Col>
              <Col md={6} className={'no-margins button-add'}>
                <button
                  disabled={!product.quantity}
                  className={'button'}
                  onClick={() => {
                    this.addItem(product)
                  }}
                >
                  Agregar a carrito
                </button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Modal
          className="modal-product"
          show={this.isLandscape() && this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          centered
        >
          <img
            style={{ objectFit: 'scale-down', borderRadius: 20 }}
            src={this.state.currentImage}
            alt=""
            onClick={() => this.setState({ showModal: false })}
          />
        </Modal>

        <div className={'footer'}>
          <label className={'footer-text'}>Cotizap © 2020</label>
        </div>

        <Alert {...alert} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  shopping: selectShopping(state),
})
const mapDispatchToProps = dispatch => ({
  addItem: (item, items) => dispatch(addItem(item, items)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ProductProfile)
