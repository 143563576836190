import { actionTypes } from '../actions/report.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  requests: [],
  subscriptions: [],
  report: [],
  queries: {},
  scheduledReports: [],
  scheduledSingleReport: [],
  purchaseWasteReport: [],
  branchReport: [],
  reportSegmented: [],
  itemSaleReport: [],
  totalSaleReport: [],
  vehicleReport: [],
}

const ReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_SEGMENTATION_REPORT):
      return { ...state, reportSegmented: extract(action.response, []) }
    case successState(actionTypes.PURCHASE_WASTE_REPORT):
      return { ...state, purchaseWasteReport: extract(action.response, []) }
    case successState(actionTypes.GET_PDF):
      return {
        ...state,
      }
    case successState(actionTypes.GET_REPORT_SUBSCRIPTION):
      return {
        ...state,
        subscriptions: extract(action.response, []),
      }
    case successState(actionTypes.GET_SEGMENTATION_DATA):
      return {
        ...state,
        report: extract(action.response, []),
      }
    case successState(actionTypes.GET_SCHEDULED_REPORTS):
      return {
        ...state,
        scheduledReports: extract(action.response, []),
      }
    case successState(actionTypes.GET_SINGLE_SCHEDULED_REPORT):
      return {
        ...state,
        scheduledSingleReport: extract(action.response, []),
      }
    case actionTypes.SET_SEGMENTATION_DATA:
      return {
        ...state,
        queries: extract(action.queries, {}),
      }
    case successState(actionTypes.GET_BRANCH_REPORT):
      return {
        ...state,
        branchReport: extract(action.response, []),
      }
    case successState(actionTypes.GET_SALE_REPORT_BY_ITEMS):
      return {
        ...state,
        itemSaleReport: extract(action.response, []),
      }
    case successState(actionTypes.GET_TOTAL_SALE_REPORT):
      return {
        ...state,
        totalSaleReport: extract(action.response, []),
      }
    case successState(actionTypes.GET_VEHICLE_REPORT):
      return {
        ...state,
        vehicleReport: extract(action.response, []),
      }
    default:
      return state ? state : initialState
  }
}

export default ReportReducer
