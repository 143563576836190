import './Commentary.scss'
import React, { useState } from 'react'

import { Modal, Row, Col } from 'react-bootstrap'
import { Icon, Button, FormText } from '../../index'
import { faPencilAlt, faWindowClose } from '@fortawesome/free-solid-svg-icons'

const Commentary = ({
  value,
  textCommentary,
  onChange,
  edit,
  max,
  show,
  onHide,
  label,
  commentaryLabel,
  prepend,
  currentValue = null,
  textarea = true,
  style,
  onClick,
  icon,
  isRequired,
}) => {
  const [editModal, setEditModal] = useState({ show: false, value: null })

  return (
    <div className={'mt-1'} style={{ ...style }}>
      <div className={'d-flex'}>
        <div
          className={'commentary-body'}
          onClick={
            onClick
              ? e => {
                  e.preventDefault()
                  e.stopPropagation()
                  onClick()
                }
              : () => edit && setEditModal({ show: true, value: null })
          }>
          {value || (!edit ? '' : commentaryLabel || 'Agregar un comentario')}
        </div>

        {edit && (
          <Icon
            style={{ marginLeft: 5 }}
            icon={faPencilAlt}
            tooltip={
              commentaryLabel || (value ? 'Editar comentario' : 'Añadir un comentario')
            }
            onClick={() => setEditModal({ show: true, value: currentValue })}
          />
        )}
        {icon && icon}
      </div>

      <Modal
        show={show || editModal.show}
        size={'md'}
        centered
        onHide={() => {
          setEditModal({ show: false, value: null })
          onHide && onHide()
        }}>
        <Modal.Header closeButton>
          <Modal.Title>{textCommentary}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {prepend}
            <Col xl={12}>
              <FormText
                label={label || 'Añade un comentario'}
                value={editModal.value}
                changeValue={v => setEditModal({ ...editModal, value: v })}
                max={max}
                as={textarea ? 'textarea' : undefined}
                rows={5}
                required={isRequired}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              color={'secondary'}
              icon={faWindowClose}
              right
              onClick={() => {
                setEditModal({ show: false, value: null })
                onHide && onHide()
              }}>
              Cerrar
            </Button>
            <Button
              color={'primary'}
              icon={faPencilAlt}
              right
              onClick={() => {
                if (isRequired && !editModal.value) return
                onChange(editModal.value)
                setEditModal({ show: false, value: null })
                onHide && onHide()
              }}>
              Editar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default Commentary
