import React, { Component } from 'react'
import IconHamburger from 'src/assets/v2/icon-hamburguer.svg'

class MenuToogle extends Component {
  render() {
    return (
      <div
        className={'toogle'}
        onClick={this.props.onToogle}
        name="menu-toggle"
        id="menu-toggle">
        <img width={26} height={26} src={IconHamburger} alt="" />
      </div>
    )
  }
}

export default MenuToogle
