import './TableOrderPage.scss'
import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { selectSelectedPOSTicket } from 'src/selectors/restaurant.selector'
import {
  actionTypes as restaurantActions,
  addPOSEmptyTickets,
  onSetSeePreBill,
  removePOSEmptyTicket,
  setSelectedPOSTicket,
} from 'src/actions/restaurant.actions'
import Money from 'src/components/Money/Money'
import { Button, FormText, Icon, Select } from 'src/components'
import { Dropdown, Modal } from 'react-bootstrap'
import { selectPOSTickets, selectGetTableOrder } from 'src/selectors/restaurant.selector'
import { onUpdateItems } from 'src/actions/restaurant.actions'
import { faEdit, faEllipsisH, faSave } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import IconButton from 'src/components/buttons/IconButton'
import { loadingSelector } from 'src/selectors/loading.selector'
import { default as DropButton } from 'src/components/buttons/Dropdown'

const TableOrderTickets = () => {
  const dispatch = useDispatch()

  const [quantities, setQuantities] = useState([])
  const [ticketDetailModal, setTicketDetailModal] = useState({ show: false })
  const [renameTicketModal, setRenameTicketModal] = useState({ show: false })
  const [combineTicketModal, setCombineTicketModal] = useState({
    show: false,
    ticket: {},
  })

  const tableOrder = useSelector(selectGetTableOrder)

  const tickets = useSelector(selectPOSTickets).map(ticket => ({
    ...ticket,
    paid: ticket.items.length > 0 && ticket.items.every(item => item.status?.id === 5),
  }))
  const selectedTicketTag = useSelector(selectSelectedPOSTicket)
  const selectedTicket = tickets.find(ticket => ticket.tag === selectedTicketTag) || {
    items: [],
  }

  const ticketOptions = tickets.map(ticket => ({
    value: ticket,
    label: ticket.tag,
  }))

  const loadingRenameTicket = useSelector(state =>
    loadingSelector([restaurantActions.GET_TABLE_ITEMS])(state),
  )
  const loadingUpdateTicket = useSelector(state =>
    loadingSelector([restaurantActions.GET_TABLE_ORDER])(state),
  )

  const handleChangeItemsTags = details => {
    const updateRequest = {
      tags: details.map(detail =>
        detail.tag === 'Sin ticket' ? { ...detail, tag: null } : detail,
      ),
    }

    if (tableOrder.id) dispatch(onUpdateItems(tableOrder.id, updateRequest))
  }

  return (
    <div className="pos-tickets">
      <div className="pos-tickets-cards">
        {tickets.map(ticket => (
          <div
            key={ticket.tag}
            className={`pos-tickets-card ${ticket.paid ? 'paid' : ''}`}>
            {loadingUpdateTicket && <div className="loading"></div>}
            <div className="index">
              {ticket.tag}
              {ticket.tag !== 'Sin ticket' && (
                <Dropdown className="ticket-menu-base">
                  <Dropdown.Toggle as={ItemToggle}>
                    <IconButton icon={faEllipsisH} className="ticket-menu-icon" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu as={ItemMenu} className="ticket-menu">
                    <Dropdown.Item
                      eventKey="1"
                      className={'ticket-menu-item'}
                      color={'secondary'}
                      onClick={() => {
                        setRenameTicketModal({ show: true, ticket, newTag: '' })
                      }}>
                      Cambiar nombre
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="2"
                      className={'ticket-menu-item'}
                      color={'secondary'}
                      onClick={() => {
                        setCombineTicketModal({ show: true, ticket })
                      }}>
                      Combinar ticket
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="3"
                      className={'ticket-menu-item'}
                      color={'secondary'}
                      onClick={() => {
                        const details = ticket.items.map(item => ({
                          detailIds: item.subItems.map(({ detailId }) => detailId),
                          tag: 'Sin ticket',
                          quantity: item.quantity,
                        }))

                        handleChangeItemsTags(details)
                        dispatch(removePOSEmptyTicket(ticket.tag))
                      }}>
                      Eliminar
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
            <div
              className="ticket-content"
              style={
                loadingUpdateTicket
                  ? {
                      backgroundColor: '#c2c2c2',
                    }
                  : {}
              }>
              {ticket.items.length === 0 && (
                <h6 className="ticket-item-base-empty">Sin items</h6>
              )}
              {ticket.items.map(item => (
                <Dropdown
                  key={item.code}
                  drop="end"
                  className="ticket-item-base"
                  onToggle={show => {
                    if (!show) return
                    const newQuantities = tickets.map(menuTicket => ({
                      tag: menuTicket.tag,
                      detailIds:
                        item.subItems && item.subItems.map(({ detailId }) => detailId),
                      quantity: menuTicket.tag === selectedTicket.tag ? item.quantity : 0,
                    }))
                    setQuantities(newQuantities)
                  }}>
                  <Dropdown.Toggle as={ItemToggle}>
                    <div className="ticket-item-content">
                      <div className="ticket-item-name">
                        {item.quantity} × {item.name}
                      </div>
                      <Money className="ticket-item-price">{item.subtotal}</Money>
                    </div>
                    <div className="ticket-item-status">
                      {item.status?.name || 'Ingresada'}
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={ItemMenu} className="ticket-item-menu">
                    {item.status?.id === 5 ? (
                      <h6>Este ítem ya ha sido pagado</h6>
                    ) : item.status?.id === undefined ? (
                      <h6>Comanda antes de mover este ítem</h6>
                    ) : tickets.length === 1 ? (
                      <h6>Añade otro ticket para mover ítems</h6>
                    ) : (
                      <>
                        <h6>Mover a</h6>
                        {tickets
                          .filter(menuTicket => menuTicket.tag !== ticket.tag)
                          .map(menuTicket => {
                            const quantity = quantities.find(
                              quantity => quantity.tag === menuTicket.tag,
                            )?.quantity
                            return (
                              <div
                                className={'ticket-item-menu-item'}
                                disabled={loadingUpdateTicket}
                                key={menuTicket.tag}>
                                <h6>{menuTicket.tag}</h6>
                                <div className={'ticket-item-menu-item-buttons'}>
                                  <button
                                    disabled={quantity < 1}
                                    className={`pos-button-circle pbc-medium ${
                                      quantity < 1
                                        ? 'color-accent-disabled'
                                        : 'color-accent'
                                    }`}
                                    onClick={() => {
                                      const newQuantities = [...quantities]
                                      newQuantities.find(
                                        quantity => quantity.tag === menuTicket.tag,
                                      ).quantity--
                                      newQuantities.find(
                                        quantity => quantity.tag === ticket.tag,
                                      ).quantity++

                                      setQuantities(newQuantities)
                                    }}>
                                    -
                                  </button>
                                  <FormText
                                    className="ticket-item-menu-item-quantity"
                                    value={quantity}
                                    type="number"
                                    onChange={({ target }) => {
                                      let quantity = Number(target.value) || 0
                                      const lastQuantity = quantities.find(
                                        quantity => quantity.tag === menuTicket.tag,
                                      ).quantity

                                      const total = quantities.reduce(
                                        (acc, q) =>
                                          q.tag === ticket.tag
                                            ? acc
                                            : q.tag === menuTicket.tag
                                            ? acc + quantity
                                            : acc + q.quantity,
                                        0,
                                      )
                                      const totalWithoutQuantity = total - quantity

                                      if (quantity < 0) quantity = 0
                                      if (total >= item.quantity)
                                        quantity = item.quantity - totalWithoutQuantity

                                      const newQuantities = [...quantities]
                                      newQuantities.find(
                                        quantity => quantity.tag === menuTicket.tag,
                                      ).quantity = quantity
                                      newQuantities.find(
                                        quantity => quantity.tag === ticket.tag,
                                      ).quantity = lastQuantity - quantity

                                      setQuantities(newQuantities)
                                    }}
                                  />
                                  <button
                                    disabled={
                                      quantities.reduce(
                                        (acc, quantity) =>
                                          quantity.tag === ticket.tag
                                            ? acc
                                            : acc + quantity.quantity,
                                        0,
                                      ) >= item.quantity
                                    }
                                    className={`pos-button-circle pbc-medium ${
                                      quantities.reduce(
                                        (acc, quantity) =>
                                          quantity.tag === ticket.tag
                                            ? acc
                                            : acc + quantity.quantity,
                                        0,
                                      ) >= item.quantity
                                        ? 'color-accent-disabled'
                                        : 'color-accent'
                                    }`}
                                    onClick={() => {
                                      const newQuantities = [...quantities]
                                      newQuantities.find(
                                        quantity => quantity.tag === menuTicket.tag,
                                      ).quantity++
                                      newQuantities.find(
                                        quantity => quantity.tag === ticket.tag,
                                      ).quantity--

                                      setQuantities(newQuantities)
                                    }}>
                                    +
                                  </button>
                                </div>
                              </div>
                            )
                          })}
                        <Dropdown.Item eventKey="1">
                          <Button
                            icon={faSave}
                            className={'w-100'}
                            color={'secondary'}
                            onClick={() => {
                              const details = quantities.filter(
                                ({ tag, quantity }) =>
                                  tag !== ticket.tag && quantity !== 0,
                              )

                              handleChangeItemsTags(details)
                            }}>
                            Guardar
                          </Button>
                        </Dropdown.Item>
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              ))}
            </div>
            <div className="ticket-summary">
              <div className="space-between">
                Total
                <Money className={'amount'}>
                  {ticket.items.reduce((acc, item) => acc + item.subtotal, 0)}
                </Money>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="pos-tickets-summaries">
        <div
          className={'pos-tickets-summary add'}
          onClick={() => {
            let tag = 1
            const findTag = ticket => ticket.tag === `Ticket ${tag}`
            let usedTag = tickets.some(findTag)

            while (usedTag) {
              tag++
              usedTag = tickets.some(findTag)
            }

            tag = `Ticket ${tag}`

            dispatch(addPOSEmptyTickets(tag))
            dispatch(setSelectedPOSTicket(tag))
          }}>
          <div className="index">
            <Icon icon={faPlus} />
          </div>
          <div className={'amount'}>Añadir ticket</div>
        </div>
        {tickets.map(ticket => (
          <div
            key={ticket.tag}
            className={`pos-tickets-summary ${ticket.paid ? 'paid' : ''} ${
              selectedTicketTag === ticket.tag ? 'selected' : ''
            }`}
            onClick={() => {
              if (selectedTicketTag !== ticket.tag)
                dispatch(setSelectedPOSTicket(ticket.tag))
              if (window.innerWidth < 1000) setTicketDetailModal({ show: true })
            }}>
            <div className="index">{ticket.tag}</div>
            <Money className={'amount'}>
              {ticket.items.reduce((acc, item) => acc + item.subtotal, 0)}
            </Money>
            {ticket.paid && <div className="state">(Pagado)</div>}
          </div>
        ))}
      </div>

      <Modal
        show={ticketDetailModal.show}
        centered
        size={'xl'}
        onHide={() => setTicketDetailModal({ show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedTicketTag}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            key={selectedTicket.tag}
            className={`pos-tickets-card ${selectedTicket.paid ? 'paid' : ''}`}>
            <div className="index">{selectedTicket.tag}</div>
            <div
              className="ticket-content"
              style={
                loadingUpdateTicket
                  ? {
                      backgroundColor: '#c2c2c2',
                    }
                  : {}
              }>
              {loadingUpdateTicket && <div className="loading" />}
              {selectedTicket.items && selectedTicket.items.length === 0 ? (
                <h6 className="ticket-item-base-empty">Sin items</h6>
              ) : (
                selectedTicket.items.map(item => (
                  <Dropdown
                    key={item.code}
                    drop="end"
                    flip
                    className="ticket-item-base"
                    offset
                    onToggle={show => {
                      if (!show) return

                      const newQuantities = tickets.map(menuTicket => ({
                        tag: menuTicket.tag,
                        detailIds: item.subItems.map(({ detailId }) => detailId),
                        quantity:
                          menuTicket.tag === selectedTicket.tag ? item.quantity : 0,
                      }))
                      setQuantities(newQuantities)
                    }}>
                    <Dropdown.Toggle as={ItemToggle}>
                      <div className="ticket-item-content">
                        <div className="ticket-item-name">
                          {item.quantity} × {item.name}
                        </div>
                        <Money className="ticket-item-price">{item.subtotal}</Money>
                      </div>
                      <div className="ticket-item-status">{item.status?.name}</div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu as={ItemMenu} className="ticket-item-menu">
                      {item.status?.id === 5 ? (
                        <h6>Este ítem ya ha sido pagado</h6>
                      ) : item.status?.id === undefined ? (
                        <h6>Comanda antes de mover este ítem</h6>
                      ) : tickets.length === 1 ? (
                        <h6>Añade otro ticket para mover ítems</h6>
                      ) : (
                        <>
                          <h6>Mover a</h6>
                          {tickets
                            .filter(menuTicket => menuTicket.tag !== selectedTicket.tag)
                            .map(menuTicket => {
                              const quantity = quantities.find(
                                quantity => quantity.tag === menuTicket.tag,
                              )?.quantity

                              return (
                                <div
                                  className={'ticket-item-menu-item'}
                                  key={menuTicket.tag}>
                                  <h6>{menuTicket.tag}</h6>
                                  <div className={'ticket-item-menu-item-buttons'}>
                                    <button
                                      disabled={quantity < 1}
                                      className={`pos-button-circle pbc-medium ${
                                        menuTicket.tag <= 1
                                          ? 'color-accent-disabled'
                                          : 'color-accent'
                                      }`}
                                      onClick={() => {
                                        const newQuantities = [...quantities]
                                        newQuantities.find(
                                          quantity => quantity.tag === menuTicket.tag,
                                        ).quantity--
                                        newQuantities.find(
                                          quantity => quantity.tag === selectedTicket.tag,
                                        ).quantity++

                                        setQuantities(newQuantities)
                                      }}>
                                      -
                                    </button>
                                    <h6>{quantity}</h6>
                                    <button
                                      disabled={
                                        quantities.reduce(
                                          (acc, quantity) =>
                                            quantity.tag === selectedTicket.tag
                                              ? acc
                                              : acc + quantity.quantity,
                                          0,
                                        ) >= item.quantity
                                      }
                                      className={
                                        'pos-button-circle pbc-medium color-accent'
                                      }
                                      onClick={() => {
                                        const newQuantities = [...quantities]
                                        newQuantities.find(
                                          quantity => quantity.tag === menuTicket.tag,
                                        ).quantity++
                                        newQuantities.find(
                                          quantity => quantity.tag === selectedTicket.tag,
                                        ).quantity--

                                        setQuantities(newQuantities)
                                      }}>
                                      +
                                    </button>
                                  </div>
                                </div>
                              )
                            })}
                          <Dropdown.Item eventKey="1">
                            <Button
                              icon={faSave}
                              className={'w-100'}
                              color={'secondary'}
                              onClick={() => {
                                const details = quantities.filter(
                                  ({ tag, quantity }) =>
                                    tag !== selectedTicket.tag && quantity !== 0,
                                )

                                handleChangeItemsTags(details)
                              }}>
                              Guardar
                            </Button>
                          </Dropdown.Item>
                        </>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                ))
              )}
            </div>
            <div className="ticket-summary">
              <div className="space-between">
                Total
                <Money className={'amount'}>
                  {selectedTicket.items.reduce((acc, item) => acc + item.subtotal, 0)}
                </Money>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {selectedTicket.tag !== 'Sin ticket' && (
            <DropButton
              items={[
                {
                  title: 'Cambiar nombre',
                  action: () =>
                    setRenameTicketModal({
                      show: true,
                      ticket: selectedTicket,
                      newTag: '',
                    }),
                },
                {
                  title: 'Combinar ticket',
                  action: () =>
                    setCombineTicketModal({ show: true, ticket: selectedTicket }),
                },
                {
                  title: 'Eliminar',
                  action: () => {
                    const details = selectedTicket.items.map(item => ({
                      detailIds: item.subItems.map(({ detailId }) => detailId),
                      tag: 'Sin ticket',
                      quantity: item.quantity,
                    }))

                    handleChangeItemsTags(details)
                    dispatch(removePOSEmptyTicket(selectedTicket.tag))
                    setTicketDetailModal({ show: false })
                  },
                },
              ]}
            />
          )}{' '}
          <div className={'container-buttons'}>
            <Button
              disabled={selectedTicket.items.length === 0}
              onClick={() => {
                dispatch(onSetSeePreBill(true))
              }}>
              Cuenta
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={renameTicketModal.show}
        centered
        size={'md'}
        onHide={() => setRenameTicketModal({ show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Renombrar {renameTicketModal.tag}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormText
            autoFocus
            required
            label={'Nuevo nombre'}
            value={renameTicketModal.newTag}
            changeValue={newTag => {
              setRenameTicketModal({
                ...renameTicketModal,
                newTag,
              })
            }}
            error={renameTicketModal.newTag === 'Sin ticket'}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className={'container-buttons'}>
            <Button
              loading={loadingRenameTicket}
              icon={faEdit}
              disabled={!renameTicketModal.newTag}
              onClick={() => {
                if (renameTicketModal.ticket.items.length === 0) {
                  dispatch(addPOSEmptyTickets(renameTicketModal.newTag))
                  dispatch(removePOSEmptyTicket(renameTicketModal.ticket.tag))
                } else {
                  const details = renameTicketModal.ticket.items.map(item => ({
                    detailIds: item.subItems.map(({ detailId }) => detailId),
                    tag: renameTicketModal.newTag,
                    quantity: item.quantity,
                  }))

                  dispatch(removePOSEmptyTicket(renameTicketModal.ticket.tag))
                  handleChangeItemsTags(details)
                }

                setRenameTicketModal({
                  ...renameTicketModal,
                  show: false,
                })
                setTicketDetailModal({ show: false })
              }}>
              Cambiar nombre
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={combineTicketModal.show}
        centered
        size={'md'}
        onHide={() => setCombineTicketModal({ show: false, ticket: {} })}>
        <Modal.Header closeButton>
          <Modal.Title>Combinar {combineTicketModal.ticket.tag}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Select
            label={'Selecciona el ticket para combinar'}
            options={ticketOptions.filter(
              ticket => ticket.label !== combineTicketModal.ticket.tag,
            )}
            value={combineTicketModal.newTicket}
            onChange={ticket => {
              setCombineTicketModal({
                ...combineTicketModal,
                newTicket: ticket,
              })
            }}
            required
          />
        </Modal.Body>
        <Modal.Footer>
          <div className={'container-buttons'}>
            <Button
              loading={loadingRenameTicket}
              icon={faEdit}
              disabled={!combineTicketModal.newTicket}
              onClick={() => {
                const details = combineTicketModal.ticket.items.map(item => ({
                  detailIds: item.subItems.map(({ detailId }) => detailId),
                  quantity: item.quantity,
                  tag: combineTicketModal.newTicket.label,
                }))

                dispatch(removePOSEmptyTicket(combineTicketModal.ticket.tag))
                handleChangeItemsTags(details)
                setCombineTicketModal({
                  ...combineTicketModal,
                  show: false,
                })
                setTicketDetailModal({ show: false })
              }}>
              Combinar tickets
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

const ItemToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div className="ticket-item" ref={ref} onClick={onClick}>
    {children}
  </div>
))

const ItemMenu = React.forwardRef(({ children, style, className }, ref) => {
  return (
    <div ref={ref} style={style} className={className}>
      {children}
    </div>
  )
})

export default TableOrderTickets
