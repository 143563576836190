export const selectOffers = state => state.offers.offers
export const selectOffer = state => state.offers.offer
export const selectContraOffer = state => state.offers.contraOffer
export const selectContraOffers = state => state.offers.contraOffers
export const selectCreateResponse = state => state.offers.createResponse
export const selectUpdateResponse = state => state.offers.updateResponse
export const selectProducerAcceptedResponse = state =>
  state.offers.producerAcceptedContraOffer
export const selectOfferCategories = state =>
  state.offers.categories.map(c => ({ ...c, value: c.id, label: c.name }))
export const selectSteps = state => state.offers.stepsValues
export const selectOfferUp = state => state.offers.responseUpdate
export const selectaAchived = state => state.offers.archived
export const selectAchive = state => state.offers.onArchive
export const selectChange = state => state.offers.change
export const selectPolygonContracted = state => state.offers.polygonContracted
export const selectLinkContracted = state => state.offers.linkContracted
export const selectLinkAvailable = state => state.offers.linkAvailable
export const selectLinkKoloAvailable = state => state.offers.linkKoloAvailable
export const selectLinkRequest = state => state.offers.linksRequest
export const selectLinkProfile = state => state.offers.linkProfile
export const selectLinkSingle = state => state.offers.linkSingle
export const selectOfferSettingResponse = state => state.offers.settingResponse
export const selectOfferSetParcel = state => state.offers.setParcelResponse
export const selectOfferUpdateLink = state => state.offers.linkResponseU
export const selectOfferLinkTypeAvailable = state =>
  state.offers.typesAvailable.map(t => ({
    ...t,
    value: t.id,
    label: t.name,
  }))
export const selectOfferLinkBalance = state => state.offers.linkBalance
export const selectOfferProductValidate = state => state.offers.validate

const getInfo = (title, tDesc, subTitle, sDesc) => {
  return { title, tDesc, subTitle, sDesc }
}

export const selectLinkCategory = () => {
  return [
    {
      kolo: true,
      value: 1,
      label: 'Kolo almacena y Kolo entrega',
      info: getInfo(
        'Kolo almacena y Kolo entrega',
        'Al escoger esta opción existirá un costo de logística por cada paquete entregado.',
        'KOLO Mall',
        'Los productos asignados al enlace aparecerán en Kolo Mall',
      ),
    },
    {
      kolo: false,
      value: 2,
      label: 'Tercero almacena y Tercero entrega',
      info: getInfo(
        'Ventas consignada',
        'Se establece el pago por la entrega de un producto, desde el producto con la funcionalidad de zonas de enlace.',
        '',
        'El transportista enlazado realiza sus propias ventas con producto establecido en el enlace',
      ),
    },
    {
      kolo: false,
      value: 3,
      label: 'Tu almacenas y Tercero entrega',
      info: getInfo(
        'Entregas programadas',
        'Se cobra el precio de entrega por cada paquete de entrega utilizado',
        '',
        'Realiza ordenes de venta y asigna al transportista enlazado para su entrega',
      ),
    },
    {
      kolo: true,
      value: 4,
      label: 'Tu almacenas y KOLO entrega',
      info: getInfo(
        'Entregas programadas',
        'Se cobra el precio de entrega por cada paquete de entrega utilizado',
        'KOLO Mall',
        'Los productos asignados al enlace aparecerán en Kolo Mall y asigna al transportista enlazado para su entrega',
      ),
    },
    {
      kolo: true,
      value: 5,
      label: 'Tu almacenas y Tu entregas',
      info: getInfo(
        'Tu almacenas y Tu entregas',
        'Al escoger esta opción existira un costo de losgistica por el 15% de la factura entregada al cliente',
        'KOLO Mall',
        'Los productos asignados al enlace aparecerán en KOLO Mall',
      ),
    },
  ]
}
