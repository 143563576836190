import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Td, Tr } from 'react-super-responsive-table'
import { Col, Modal, Row } from 'react-bootstrap'
import { faInfoCircle, faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import SweetAlert from 'sweetalert-react'
import ReactNotification, { store } from 'react-notifications-component'

import {
  Card,
  FormTextField,
  FormText,
  Select as CustomSelect,
  Button,
  Switch,
  Title,
  Icon as IconButton,
  TableV2,
  NumberField,
  MoneyField,
} from '../../components'
import FormTextArea from '../../components/inputs/FormTextArea/FormTextArea'
import Folder, { categoryType } from '../../components/folders/Folder'

import Gallery, { imageTypes } from '../../components/gallery/Gallery'

import { history } from '../../App'

import {
  actionTypes as producActionTypes,
  createProduct,
  getModelProducts,
  getProductPricesNames,
  getProductsSimple,
  loadProductsCSV,
  getMeasurementUnits,
  getProductList,
} from '../../actions/products.actions'
import {
  selectSingleProduct,
  selectProductsSimple,
  selectPricesList,
  selectMeasurementUnits,
} from '../../selectors/products.selector'

import { loadingSelector } from '../../selectors/loading.selector'

import { deleteFile, uploadFile } from '../../actions/uploads.actions'

import {
  hasErrorsSelector,
  singleErrorSelector,
  handlerInfoWithButtons,
} from '../../selectors/error.selector'

import {
  actionTypes as action,
  getAllCategorizations,
  getCategorizationByType,
} from '../../actions/categorization.actions'

import {
  selectAllCategorizations,
  selectCategorizationByType,
} from '../../selectors/categorizations.selector'

import { selectCurrentModule } from '../../selectors/user.selector'
import { getExpenseType } from '../../actions/expenses.actions'
import { selectGetExpenseTypes } from '../../selectors/expenses.selector'

import { selectParcels } from '../../selectors/parcel.selector'
import { getPermission, isAllowed } from '../../selectors/modules.selector'

import DataSheet from '../../components/DataSheet/DataSheet'
import CustomDatePicker from '../../components/CustomDatePicker/CustomDatePicker'
import { selectCompanyIsLimit, selectFelItemTypes } from 'src/selectors/company.selector'
import { getPhaseTypes } from '../../actions/resolutions.actions'
import CustomCreate from '../../components/inputs/Creable/CustomCreate'
import Money from '../../components/Money/Money'
import { toMoney } from '../../utils/utilities'
import CSVLoaderV2, { CATEGORY_UPLOAD } from 'src/components/CSVLoader/CSVLoaderV2'
import { selectActivePlans } from 'src/selectors/plans.selector'
import { ListVariations } from 'src/content/Production/Categorization/Variation/ListVariations'
import { showAlert } from 'src/actions/alert.actions'

const defaultOption = { value: null, label: 'Sin seleccionar' }

class CreateProduct extends Component {
  state = {
    name: '',
    code: '',
    barcode: '',
    description: '',
    category: null,
    subCategory: null,
    price: 0,
    productionPrice: 0,
    production: null,
    baseProduct: { label: '- Ninguno -', value: null },
    conversionFactor: null,
    transformation: false,
    images: [],
    errors: {},
    showSuccess: false,
    showError: false,
    showCategorizations: false,
    showVariations: false,
    alertBase: {
      data: {},
      show: false,
      title: 'default',
      text: 'default',
      type: 'info',
      onConfirm: () => console.log('default'),
    },
    successAlert: {
      show: false,
      type: 'success',
      showCancelButton: true,
      confirmButtonText: 'Ir a detalles.',
      confirmButtonColor: 'green',
      cancelButtonText: 'Cerrar',
      title: '¡Yeah!',
      text: 'item creado satisfactoriamente.\n\n¿Desea ir al detalle del item?',
      onConfirm: () => console.log('confirm'),
      onCancel: () => console.log('cancel'),
    },
    root: { value: 0, label: 'Seleccione una categorizacion' },
    categorizationChildren: [],
    categorizationList: [],
    categorization: [],
    activeOnline: false,
    subPrice: 0,
    father: false,
    activeLink: false,
    activePurchase: false,
    activeExpenses: false,
    activeSell: false,
    activeWaste: false,
    activeProduction: false,
    teoricExistence: false,
    includeIva: true,
    phraseReference: { value: null, label: 'Selecciona una frase' },
    isExpense: false,
    selectedExpense: {},
    activeUtility: false,
    activeUpdatable: false,
    parcel: null,
    useFactor: false,
    withoutInventory: false,
    activeArchive: false,
    dataSheet: false,
    itemDS: {},
    setPrices: false,
    modalPrices: { show: false, price: 0 },
    create: {},
    prices: [],
    defaultPrice: null,
    archiveAutomatic: false,
    archiveInDate: false,
    archiveInInventory: false,
    archiveDate: null,
    barcodes: [],
    offerPrice: null,
    offerQuantity: null,
    felItemType: defaultOption,
    measurementUnit: defaultOption,
    listVariations: [],
    autoBarcode: false,
    useDecimals: false,
    decimals: null,
  }

  componentDidMount() {
    const { module } = this.props
    if (module === 11000) this.props.getModelProducts()
    else this.props.getAllProducts()
    this.props.getAllCategorization()
    this.props.getExpenseTypes()
    this.props.getNames()
    this.props.getMeasurementUnits()
    // this.props.getPhrases()

    if (this.props.expense) {
      let val = {
        label: `Precio por defecto - ${toMoney(0)}`,
        value: 0,
        alias: 'Precio por defecto',
      }
      this.state.prices.push(val)
      this.setState({ defaultPrice: val, price: val.value })
    }
    //this.props.getParcels();
  }

  UNSAFE_componentWillReceiveProps(next) {
    const {
      createIsLoading,
      createHasError,
      module,
      onlyCloseModal,
      loadingNames,
      itemSelected,
      name,
    } = this.props
    const { product } = next

    if (name) {
      this.setState({ name: name })
    }
    if (itemSelected) this.setState({ baseProduct: itemSelected })

    if (loadingNames && !next.loadingNames) {
      this.setState({ create: { options: next.namesList } })
    }

    if (createIsLoading && !next.createIsLoading) {
      let { successAlert } = this.state
      if (!createHasError && next.createHasError) {
        this.setState({ showSuccess: false, showError: true })
      } else {
        successAlert.show = true
        if (onlyCloseModal) successAlert.text = 'item creado satisfactoriamente'
        successAlert.onConfirm = () => {
          this.setState({ successAlert: { ...successAlert, show: false } })
          if (!onlyCloseModal) {
            let route = ''
            if (module === 11000) route = 'admin'
            else if (module === 1000) route = 'distribucion'
            else if (module === 2000) route = 'produccion'
            route = `/${route}/productos/detalles/${product.id}`
            history.replace(route)
          } else onlyCloseModal(product)
        }
        if (!onlyCloseModal)
          successAlert.onCancel = () => {
            this.setState({ successAlert: { ...successAlert, show: false } })
            if (this.props.onClose) this.props.onClose(true)
          }
        else {
          successAlert.showCancelButton = false
          successAlert.confirmButtonText = 'Cerrar'
        }
      }
      this.setState({ successAlert })
    }
  }

  handleInputChange = event => {
    let { name, value, type } = event.target
    if (type === 'number') {
      if (!value || value < 0) value = 0
      if (!isNaN(value)) {
        if (name === 'discountLimitedNumber') {
          if (Number(value) > Number(this.state.price)) value = this.state.price
        }

        this.setState({ [name]: value })
      }
    } else this.setState({ [name]: value })
  }

  uploadImage = (url, name) => {
    this.setState({
      images: [...this.state.images, { original: url, thumbnail: url, name: name }],
    })
  }

  deleteImage = (image, index) => {
    const { images } = this.state
    images.splice(index, 1)
    this.setState({ images: new Array(...images) })
  }

  onBaseProductChange = item => {
    if (item.value)
      this.setState({ baseProduct: item, conversionFactor: null, father: true })
    else this.setState({ baseProduct: item, conversionFactor: null, father: false })
  }

  onPriceChange = item => {
    this.setState({ defaultPrice: item })
  }

  showNotification = (title, message) => {
    return store.addNotification({
      title: title,
      message: message,
      type: 'success',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'bounceIn'],
      animationOut: ['animated', 'bounceOut'],
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    })
  }

  save = () => {
    const {
      barcodes,
      name,
      description,
      measurementUnit,
      categorizationList,
      price,
      productionPrice,
      images,
      baseProduct,
      conversionFactor,
      transformation,
      create,
      includeIva,
      phraseReference,
      activeOnline,
      activeLink,
      production,
      activePurchase,
      activeExpenses,
      activeSell,
      activeWaste,
      activeProduction,
      isExpense,
      selectedExpense,
      activeUtility,
      parcel,
      useFactor,
      withoutInventory,
      dataSheet,
      discountLimited,
      discountLimitedNumber,
      code,
      itemDS,
      prices,
      activeArchive,
      teoricExistence,
      activeUpdatable,
      archiveDate,
      archiveAutomatic,
      archiveInDate,
      archiveInInventory,
      defaultPrice,
      offerPrice,
      offerQuantity,
      felItemType,
      listVariations,
      autoBarcode,
      useDecimals,
      decimals,
    } = this.state
    const { createProduct, module } = this.props
    let errors = {}
    let data = null
    let list = []
    let listAlias = []

    if (!includeIva && !phraseReference.value) {
      errors.phrase = 'Campo requerido'
    }

    if (!name) {
      errors.name = 'Campo requerido'
    }
    // SI HAY UN PRECIO AGREGADO
    if (prices.length > 0 && (defaultPrice === null || price === null)) {
      errors.price = 'Campo requerido'
    }

    if (baseProduct.value && !conversionFactor) {
      errors.conversionFactor = 'Campo requerido'
    }

    if (archiveInDate && !archiveDate) {
      errors.archiveDate = 'Campo requerido'
    }

    if (discountLimited && (!discountLimitedNumber || discountLimitedNumber <= 0))
      errors.discountLimitedNumber = 'Campo requerido'

    if (dataSheet) {
      data = itemDS
    }

    if (prices.length !== 0) {
      prices.forEach(s => {
        list.push(s.value)
        listAlias.push(s.alias)
      })
    } else {
      list.push(0)
      listAlias.push('Precio por defecto')
      this.setState({ price: 0 })
    }

    if (!felItemType.value) errors.felItemType = 'Campo requerido'

    if (useDecimals && decimals === null) errors.decimals = 'Limite de 1 a 6 decimales'

    if (Object.keys(errors).length === 0) {
      let base = baseProduct.value
      let factor = conversionFactor || null
      let subProductId = baseProduct.value
      let subProductValue = conversionFactor || null

      let categorization = []
      categorizationList.map(c => {
        categorization.push(c.id)
      })

      let variations = []
      listVariations.map(v => variations.push(v.id))

      let model = module === 11000

      let oP = null
      if (offerPrice && offerPrice > 0) {
        oP = { price: offerPrice, quantity: offerQuantity }
      }

      createProduct(
        {
          name,
          code,
          barcodes,
          validateBarcodes: true,
          description,
          measurementUnitId: measurementUnit.value,
          price,
          productionPrice,
          list,
          listAlias,
          categorization,
          baseProduct: base,
          conversionFactor: factor,
          subProductId,
          subProductValue,
          transformation,
          model,
          activeOnline,
          subPrice: 0,
          activeLink,
          production,
          activePurchase: this.props.expense ? this.props.expense === 4 : activePurchase,
          activeExpenses: this.props.expense ? this.props.expense === 5 : activeExpenses,
          phraseReference: phraseReference ? phraseReference.value : null,
          activeSell: this.props.sale ? this.props.sale === 1 : activeSell,
          activeWaste,
          activeProduction,
          expenseType: isExpense ? selectedExpense.value : null,
          activeUtility,
          parcelId: parcel ? parcel.value : null,
          useFactor,
          withoutInventory,
          activeUpdatable,
          discountLimited,
          discountLimitedNumber,
          teoricExistence,
          data,
          activeArchive: withoutInventory && activeArchive,
          archiveAutomatic,
          includeIva,
          archiveInDate,
          archiveInInventory,
          archiveDate: archiveInDate ? archiveDate.valueOf() : null,
          offerPrice: oP,
          felItemTypeId: felItemType.value,
          categories: variations,
          autoBarcode,
          useDecimals,
          decimals,
        },
        images.map(item => ({ url: item.original, name: item.name })),
      )
    }

    this.setState({ errors: errors })
  }

  clearForm = () => {
    this.setState({
      name: '',
      code: '',
      barcode: '',
      description: '',
      category: null,
      subCategory: null,
      price: null,
      baseProduct: { label: '- Ninguno -', value: null },
      conversionFactor: null,
      images: [],
      showSuccess: false,
      showError: false,
      categorizationList: [],
    })
  }

  expenseTypeChanged = value => {
    this.setState({ selectedExpense: value })
  }

  deletePrice = (id, alias) => {
    let { prices, defaultPrice } = this.state
    let ids = prices
    ids = ids.filter(p => id !== p.value || alias !== p.alias)
    if (defaultPrice && id === defaultPrice.value) {
      this.setState({ defaultPrice: null, price: 0 })
    }
    this.setState({ prices: [...ids] })
  }

  renderRow = (item, index) => {
    const { categorizationList } = this.state
    return (
      <Tr key={`${index}`} className={'data'}>
        <Td className={'name text-center'}>{item.name}</Td>
        <Td className={'name text-center'}>{item.description}</Td>
        <Td className={'mini text-center'}>
          <IconButton
            icon={faTrashAlt}
            tooltip={'Quitar'}
            onClick={() => {
              categorizationList.splice(index, 1)
              this.setState({ categorizationList })
            }}
          />
        </Td>
      </Tr>
    )
  }

  saveProductsCSV = (file, onSuccess, param) => {
    this.props.uploadProductsCSV(file, onSuccess, param)
  }

  createOption = label => ({
    value: label,
    label: label,
  })

  handleChange = newValue => {
    const { create, modalPrices } = this.state
    this.setState({ create: { ...create, value: newValue } })
    if (newValue)
      this.setState({
        modalPrices: { ...modalPrices, alias: newValue.value, valias: newValue },
      })
    else this.setState({ modalPrices: { ...modalPrices, alias: '', valias: null } })
  }

  handleCreate = inputValue => {
    const { modalPrices, create } = this.state
    const { namesList } = this.props
    console.log(namesList)
    this.setState({ create: { ...create, isLoading: true } })
    setTimeout(() => {
      const options = namesList
      const newOption = this.createOption(inputValue)
      this.setState({
        create: { isLoading: false, options: [...options, newOption], value: newOption },
        modalPrices: { ...modalPrices, alias: inputValue, valias: newOption },
      })
    }, 250)
  }

  render() {
    const {
      alertBase,
      name,
      barcode,
      description,
      price,
      productionPrice,
      errors,
      images,
      production,
      baseProduct,
      conversionFactor,
      showSuccess,
      showError,
      showCategorizations,
      categorizationList,
      activeUtility,
      useFactor,
      withoutInventory,
      discountLimited,
      discountLimitedNumber,
      modalPrices,
      prices,
      setPrices,
      dataSheet,
      teoricExistence,
      activeUpdatable,
      activeArchive,
      includeIva,
      phraseReference,
      archiveAutomatic,
      archiveInDate,
      archiveDate,
      archiveInInventory,
      offerPrice,
      offerQuantity,
      autoBarcode,
      decimals,
      useDecimals,
    } = this.state
    const {
      products,
      createIsLoading,
      createError,
      module,
      categorization,
      modal,
      isLimit,
      variations,
      plan,
      felItemTypes,
    } = this.props

    const measurementUnits = [defaultOption].concat(
      this.props.measurementUnits.map(item => ({
        label: `${item.name} (${item.symbol})`,
        value: item.id,
      })),
    )

    const {
      successAlert,
      transformation,
      activePurchase,
      activeExpenses,
      activeSell,
      activeWaste,
      activeProduction,
      activeLink,
      code,
      listVariations,
    } = this.state

    return (
      <div>
        <ReactNotification />
        <Title title={'Nuevo item'} />
        <Row>
          <Col md={modal ? 12 : 4}>
            <CSVLoaderV2
              info
              category={CATEGORY_UPLOAD.PRODUCTS}
              title={'Carga Masiva de items'}
              onSuccess={() => this.props.getProductList()}
            />
          </Col>
          <Col md={modal ? 12 : 8}>
            <Card
              white={modal}
              title={'item'}
              style={{ height: '100%', marginTop: modal ? 15 : 0 }}
              bodyStyle={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}>
              {' '}
              <Row>
                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                  <Gallery
                    imageType={imageTypes.PRODUCT}
                    images={images}
                    onUploadSuccess={this.uploadImage}
                    onDelete={this.deleteImage}
                    maxWidth={200}
                  />
                </Col>
                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                  <Row>
                    <Col xl={12} lg={12} md={6} sm={6} xs={12}>
                      <FormTextField
                        label={'SKU / Código'}
                        info={'Ingrese el No. De SKU / Código o identificación del item'}
                        type={'text'}
                        id={'code'}
                        placeholder={'SKU / Código'}
                        name={'code'}
                        value={code}
                        onChange={this.handleInputChange}
                      />
                    </Col>
                    <Col xl={12} lg={12} md={6} sm={6} xs={12}>
                      <FormTextField
                        label={'Nombre'}
                        info={'Ingrese nombre del item ej. Miel de abeja'}
                        type={'text'}
                        id={'name'}
                        placeholder={'Nombre'}
                        name={'name'}
                        value={name}
                        onChange={this.handleInputChange}
                        error={errors.name || createError.name}
                        required
                      />
                    </Col>

                    <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                      <CustomSelect
                        label={'Bien o Servicio'}
                        info={
                          'La propiedad "Bien o Servicio" se usará para la factura electrónica'
                        }
                        required
                        value={this.state.felItemType}
                        options={felItemTypes}
                        onChange={felItemType => this.setState({ felItemType })}
                        error={errors.felItemType}
                      />
                    </Col>

                    <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                      <CustomSelect
                        label={'Unidad de medida'}
                        value={this.state.measurementUnit}
                        options={measurementUnits}
                        onChange={measurementUnit => this.setState({ measurementUnit })}
                        error={errors.measurementUnit}
                      />
                    </Col>

                    <Col xl={12} lg={12} md={6} sm={6} xs={12}>
                      <FormTextField
                        prependMoneySymbol
                        label={'Costo Unitario'}
                        type={'number'}
                        id={'productionPrice'}
                        placeholder={'Costo Unitario'}
                        name={'productionPrice'}
                        value={productionPrice}
                        onChange={this.handleInputChange}
                        error={errors.productionPrice || createError.productionPrice}
                        info={'Ingrese Costo Unitario para Ingreso de Inventario'}
                      />
                    </Col>

                    <Col xl={12} lg={12} md={6} sm={6} xs={12}>
                      {this.props.isAllowed([2058, 7108]) ? (
                        <div className={'input-wrapper has-feedback mt-3'}>
                          <label className={'label-input'}>Precios del item</label>
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 10,
                            }}>
                            {prices?.map((price, index) => (
                              <div key={index} className={'c-item assigned centered'}>
                                <Money component="label" className={'label-user-tag'}>
                                  {price.value}
                                </Money>
                                <IconButton
                                  className={'c-item-button assigned centered'}
                                  icon={faTimes}
                                  tooltip={'Eliminar'}
                                  color={'white'}
                                  onClick={() =>
                                    this.deletePrice(price.value, price.alias)
                                  }
                                />
                              </div>
                            ))}
                            <div
                              className={'c-item add centered'}
                              onClick={() =>
                                this.setState({
                                  modalPrices: { show: true, price: 0 },
                                  create: { ...this.state.create, value: null },
                                })
                              }>
                              Agregar
                              <IconButton
                                className={'c-item-button add centered'}
                                icon={faPlus}
                                tooltip={'Crear precio'}
                                color={'white'}
                              />
                            </div>
                          </div>
                          <CustomSelect
                            label={'Precio de venta por defecto'}
                            info={
                              'Es el precio de venta que queda por defecto en las compras'
                            }
                            options={[...prices]}
                            value={this.state.defaultPrice}
                            onChange={this.onPriceChange}
                            error={errors.price || createError.price}
                          />
                        </div>
                      ) : (
                        <FormTextField
                          label={'Precio de Venta'}
                          type={'number'}
                          id={'price'}
                          placeholder={'Precio de venta'}
                          name={'price'}
                          value={price}
                          onChange={this.handleInputChange}
                          error={errors.price || createError.price}
                          info={'Ingrese precio de venta'}
                        />
                      )}
                    </Col>
                    <Col xl={5} lg={5} md={5} sm={5} xs={12}>
                      <Switch
                        id={'useDecimals'}
                        name={'useDecimals'}
                        checked={useDecimals}
                        label={'Usar decimales'}
                        info={
                          'Al activar la opción el item podrá ser configurado para usar decimales'
                        }
                        topLabel
                        onChange={({ target }) => {
                          const { checked } = target
                          if (checked)
                            this.props.showAlert({
                              ...handlerInfoWithButtons(
                                'Efectos en el item al activar decimales',
                                'Una vez el item sea configurado y guardado con decimales, esta acción no podrá ser revertida y tampoco podrá ser configurado para usar un árbol de ítems.',
                              ),
                              onConfirm: () => {
                                this.setState({
                                  useDecimals: checked,
                                  decimals: checked ? 1 : null,
                                  baseProduct: defaultOption,
                                  conversionFactor: null,
                                })
                              },
                              showCancelButton: true,
                            })
                          else
                            this.setState({
                              useDecimals: checked,
                              decimals: checked ? 1 : null,
                            })
                        }}
                      />
                    </Col>
                    {useDecimals && (
                      <Col xl={7} lg={7} md={7} sm={7} xs={12}>
                        <NumberField
                          mt={0}
                          label={'Cantidad de decimales'}
                          id={'decimals'}
                          name={'decimals'}
                          value={decimals}
                          min={1}
                          max={6}
                          onValueChange={value =>
                            this.setState({
                              decimals: value,
                            })
                          }
                          error={errors.decimals}
                          info={'De 1 a 6 decimales'}
                        />
                      </Col>
                    )}
                  </Row>
                </Col>

                {plan?.id !== 8 && (
                  <>
                    <Col xl={8} lg={12} sm={12} xs={12} className={'mt-3'}>
                      <Row>
                        <Col>
                          {/*<ProductBarcodes
                            barcodes={autoBarcode ? [] : this.state.barcodes}
                            init
                            disabled={autoBarcode}
                            onChange={b => {
                              this.setState({ barcodes: b })
                            }}
                          />*/}
                        </Col>
                        <Col>
                          <Switch
                            id={'autoBarcode'}
                            name={'autoBarcode'}
                            checked={autoBarcode}
                            label={'Codigo de barra automáticamente'}
                            info={
                              'Al activar la opción, se generara un codigo de barra de forma aleatoria'
                            }
                            topLabel
                            onChange={() => this.setState({ autoBarcode: !autoBarcode })}
                          />
                        </Col>
                      </Row>
                    </Col>
                    {!useDecimals && (
                      <>
                        <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                          <CustomSelect
                            label={'Ítem base'}
                            info={'Es el ítem base del item derivado'}
                            options={[{ label: '- Ninguno -', value: null }, ...products]}
                            value={baseProduct}
                            disabled={this.props.itemSelected}
                            onChange={this.onBaseProductChange}
                            error={errors.baseProduct || createError.baseProduct}
                          />
                        </Col>
                        <Col
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={12}
                          style={{ marginTop: 15 }}>
                          <NumberField
                            disabled={!baseProduct.value}
                            label={'Factor de Conversión'}
                            info={
                              'Determinar cuántas unidades del ítem base conforman un item derivado'
                            }
                            id={'conversionFactor'}
                            name={'conversionFactor'}
                            value={conversionFactor}
                            onValueChange={value =>
                              this.setState({ conversionFactor: value })
                            }
                            error={
                              errors.conversionFactor || createError.conversionFactor
                            }
                          />
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                          <Switch
                            id={'useFactor'}
                            name={'useFactor'}
                            checked={useFactor}
                            label={
                              'Al activar esta opción el sistema tomara el precio del item actual dividido el factor de conversión. El item de esta operación será el precio definido para el item hijo siempre que se venda como subitem. '
                            }
                            topLabel
                            onChange={() => this.setState({ useFactor: !useFactor })}
                          />
                        </Col>
                      </>
                    )}
                    {!isLimit && (
                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Switch
                          id={'teoricExistence'}
                          name={'teoricExistence'}
                          checked={teoricExistence}
                          label={
                            'Al activar la configuración el item mostrara la existencia teorica segun la existencia de los insumos que lo componen en su receta.'
                          }
                          topLabel
                          onChange={() =>
                            this.setState({ teoricExistence: !teoricExistence })
                          }
                        />
                      </Col>
                    )}
                    {/*
                                    this.props.isAllowed([7114]) &&
                                    <>
                                        <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                                            <Switch id={'includeIva'} name={'includeIva'} checked={includeIva}
                                              info={'Permite decidir si el precio establecido se factura con IVA o no'}
                                              label={'Vender con IVA'}
                                              topLabel onChange={({target}) => this.setState({includeIva: target.checked})}
                                            />
                                            {
                                                !includeIva &&
                                                <Select
                                                  label={"Frase"}
                                                  value={phraseReference}
                                                  options={this.props.phrases}
                                                  onChange={(value) => this.setState({phraseReference: value})}
                                                  info={"Selecciona la frase con la que no cobrará iva este item"}
                                                  error={errors.phrase}
                                                />
                                            }
                                        </Col>
                                    </>
                                */}

                    <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                      <Switch
                        id={'discountLimited'}
                        name={'discountLimited'}
                        checked={discountLimited}
                        label={
                          'Al activar esta opción el sistema tomara el valor del limite de descuento para validar el descuento dado en ordenes de venta.'
                        }
                        topLabel
                        onChange={() =>
                          this.setState({ discountLimited: !discountLimited })
                        }
                      />
                    </Col>

                    {discountLimited && (
                      <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                        <FormText
                          disabled={!price}
                          label={'Limite de descuento'}
                          info={
                            'Determina la cantidad maxima posible de descuento al realizar ordenes de venta.'
                          }
                          type={'number'}
                          id={'discountLimitedNumber'}
                          placeholder={'0.00'}
                          name={'discountLimitedNumber'}
                          value={discountLimitedNumber}
                          onChange={this.handleInputChange}
                          error={errors.discountLimitedNumber}
                        />
                      </Col>
                    )}
                    <Col md={12}>
                      <div className={`form-label left input-wrapper has-feedback mt-3 `}>
                        <label>
                          Variaciones &nbsp;
                          <label>
                            {' '}
                            <IconButton
                              tooltip={
                                'Si el item pertenece a un árbol de ítems, las variaciones serán las del ítem base'
                              }
                              icon={faInfoCircle}
                              size={'0.25x'}
                            />{' '}
                          </label>
                        </label>
                      </div>
                      <Row>
                        <Col lg={6} sm={12}>
                          <div
                            className={'big-button'}
                            onClick={() => {
                              this.props.getVariations()
                              this.setState({ showVariations: true })
                            }}>
                            Agregar Variaciones
                          </div>
                        </Col>
                        <Col lg={12} sm={12}>
                          <ListVariations
                            show={this.state.showVariations}
                            onHide={() => this.setState({ showVariations: false })}
                            onSave={list => {
                              this.setState({
                                showVariations: false,
                                listVariations: list,
                              })
                            }}
                            data={variations}
                            selected={listVariations}
                            hideMessage
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12}>
                      <FormTextArea
                        label={'Descripción'}
                        info={
                          'Ingresar una breve descripción ej, Miel de abeja artesanal 100% pura'
                        }
                        value={description}
                        name={'description'}
                        id={'description'}
                        rows={'6'}
                        onChange={this.handleInputChange}
                        error={errors.description || createError.description}
                      />
                    </Col>
                    <Col md={12}>
                      <Row>
                        <Col lg={6} sm={12}>
                          <div
                            className={'big-button'}
                            style={{ marginTop: 35 }}
                            onClick={() => {
                              this.props.getAllCategorization()
                              this.setState({ showCategorizations: true })
                            }}>
                            Agregar Categorizaciones
                          </div>
                        </Col>

                        <Col lg={12} sm={12}>
                          <TableV2
                            customClass={'scroll-x-without-height'}
                            headers={[
                              {
                                label: 'Nombre',
                                show: true,
                                value: ['name'],
                                type: 'text',
                                className: 'name text-center',
                              },
                              {
                                label: 'Descripción',
                                show: true,
                                value: ['description'],
                                type: 'text',
                                className: 'name text-center',
                              },
                              {
                                config: true,
                                show: true,
                                label: '',
                                className: 'mini center',
                                custom: (item, index) => (
                                  <IconButton
                                    icon={faTrashAlt}
                                    tooltip={'Quitar'}
                                    onClick={() => {
                                      categorizationList.splice(index, 1)
                                      this.setState({ categorizationList })
                                    }}
                                  />
                                ),
                              },
                            ]}
                            items={categorizationList}
                            mobileAuto
                            storageKey={'createProduct'}
                          />
                        </Col>
                      </Row>
                    </Col>

                    {!isLimit && (
                      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Row>
                          {/* <Col md={6} sm={6} xs={12}>
                        <label className={'checkbox-label'}>
                          <br />
                          <h6 style={{ color: '#3394CE' }}>
                            Transformación automática:{' '}
                          </h6>
                          <input
                            type={'checkbox'}
                            name={'transformation'}
                            checked={transformation}
                            onChange={({ target }) =>
                              this.setState({ transformation: target.checked })
                            }
                          />{' '}
                          {transformation
                            ? 'El item se agrupará de forma automática durante una transferencia de mercadería.'
                            : 'El item no se agrupará de forma automática durante una transferencia de mercadería.'}
                        </label>
                      </Col> */}

                          <Col md={6} sm={6} xs={12}>
                            <label className={'checkbox-label'}>
                              <br />
                              <h6 style={{ color: '#3394CE' }}>
                                Activar para no usar inventario en ventas
                              </h6>
                              <input
                                type={'checkbox'}
                                name={'withoutInventory'}
                                checked={withoutInventory}
                                onChange={({ target }) => {
                                  if (target.checked === true) {
                                    this.setState({
                                      withoutInventory: target.checked,
                                      archiveInInventory: false,
                                    })
                                  } else {
                                    this.setState({
                                      withoutInventory: target.checked,
                                      activeArchive: false,
                                    })
                                  }
                                }}
                              />{' '}
                              Los items con el switch activo no requeriran inventario
                              durante una venta y durante una producción
                            </label>
                          </Col>

                          <Col md={6} sm={6} xs={12}>
                            <label className={'checkbox-label'}>
                              <br />
                              <h6 style={{ color: '#3394CE' }}>
                                Archivar item de forma automática{' '}
                              </h6>
                              <input
                                type={'checkbox'}
                                id={'archiveAutomatic'}
                                name={'archiveAutomatic'}
                                checked={archiveAutomatic}
                                label={'Archivar item de forma automatica'}
                                onChange={({ target }) => {
                                  if (target.checked === true) {
                                    this.setState({ archiveAutomatic: target.checked })
                                  } else {
                                    this.setState({
                                      archiveAutomatic: target.checked,
                                      activeArchive: false,
                                      archiveInDate: false,
                                      archiveInInventory: false,
                                    })
                                  }
                                }}
                              />{' '}
                              {archiveAutomatic
                                ? 'El item se almacenara de forma automática segun las configuraciones seleccionadas.'
                                : 'El item no se archivara de forma automática.'}
                            </label>
                          </Col>

                          {archiveAutomatic && (
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                              <br />
                              <Col md={6} sm={6} xs={12}>
                                <Switch
                                  id={'archiveInDate'}
                                  name={'archiveInDate'}
                                  checked={archiveInDate}
                                  label={'Archivar item en una fecha definida'}
                                  info={
                                    'Al tener la configuración activa, el item sera archivado de forma automatica en la fecha indicada.'
                                  }
                                  topLabel={window.innerWidth < 480}
                                  onChange={() =>
                                    this.setState({ archiveInDate: !archiveInDate })
                                  }
                                />

                                {archiveInDate && (
                                  <CustomDatePicker
                                    disabled={false}
                                    label={'Fecha para archivar el item:'}
                                    value={archiveDate}
                                    disabledDays={{ before: new Date() }}
                                    onDayChange={value =>
                                      this.setState({ archiveDate: value })
                                    }
                                    error={errors.archiveDate}
                                  />
                                )}
                              </Col>
                              {!withoutInventory ? (
                                <Col md={6} sm={6} xs={12}>
                                  <Switch
                                    id={'archiveInInventory'}
                                    name={'archiveInInventory'}
                                    checked={archiveInInventory}
                                    label={
                                      'Archivar item al acabarse el inventario total'
                                    }
                                    info={
                                      'Al tener la configuración activa, el item sera archivado de forma automatica cuando ya no haya disponible inventario del item.'
                                    }
                                    topLabel={window.innerWidth < 480}
                                    onChange={() =>
                                      this.setState({
                                        archiveInInventory: !archiveInInventory,
                                      })
                                    }
                                  />
                                </Col>
                              ) : (
                                <Col md={6} sm={6} xs={12}>
                                  <Switch
                                    id={'activeArchive'}
                                    name={'activeArchive'}
                                    checked={activeArchive}
                                    label={
                                      'Archivar item despues de confirmar una orden de venta'
                                    }
                                    info={
                                      'Al tener la configuración activa, el item sera archivado de forma automatica al confirmar una orden de venta donde se involucre el item'
                                    }
                                    topLabel={window.innerWidth < 480}
                                    onChange={() =>
                                      this.setState({ activeArchive: !activeArchive })
                                    }
                                  />
                                </Col>
                              )}
                            </Col>
                          )}

                          {module === 2000 && (
                            <Col md={6} sm={6} xs={12}>
                              <label className={'checkbox-label'}>
                                <br />
                                <h6 style={{ color: '#3394CE' }}>
                                  Activar para enlace KOLO: (
                                  {activeLink ? 'Activo' : 'Inactivo'})
                                </h6>
                                <input
                                  type={'checkbox'}
                                  name={'activeLink'}
                                  checked={this.state.activeLink}
                                  onChange={({ target }) =>
                                    this.setState({ activeLink: target.checked })
                                  }
                                />{' '}
                                Active la opción para que el item sea listado para enlaces
                                KOLO.
                              </label>
                            </Col>
                          )}
                          <Col md={6} sm={6} xs={12}>
                            <label className={'checkbox-label'}>
                              <br />
                              <h6 style={{ color: '#3394CE' }}>
                                Activar para modulo de Compras
                              </h6>
                              <input
                                type={'checkbox'}
                                disabled={
                                  this.props.expense ? this.props.expense === 4 : false
                                }
                                name={'activePurchase'}
                                checked={
                                  this.props.expense
                                    ? this.props.expense === 4
                                    : activePurchase
                                }
                                onChange={({ target }) =>
                                  this.setState({ activePurchase: target.checked })
                                }
                              />{' '}
                              Active la opción para que el item sea listado para el modulo
                              de compras.
                            </label>
                          </Col>
                          <Col md={6} sm={6} xs={12}>
                            <label className={'checkbox-label'}>
                              <br />
                              <h6 style={{ color: '#3394CE' }}>
                                Activar para modulo de Gastos
                              </h6>
                              <input
                                type={'checkbox'}
                                disabled={
                                  this.props.expense ? this.props.expense === 5 : false
                                }
                                name={'activeExpenses'}
                                checked={
                                  this.props.expense
                                    ? this.props.expense === 5
                                    : activeExpenses
                                }
                                onChange={({ target }) => {
                                  this.setState({ activeExpenses: target.checked })
                                }}
                              />
                              Active la opción para que el item sea listado para el modulo
                              de gastos.
                            </label>
                          </Col>
                          <Col md={6} sm={6} xs={12}>
                            <label className={'checkbox-label'}>
                              <br />
                              <h6 style={{ color: '#3394CE' }}>
                                Activar para modulo de Ventas
                              </h6>
                              <input
                                type={'checkbox'}
                                name={'activeSell'}
                                checked={this.props.sale || activeSell}
                                onChange={({ target }) =>
                                  this.setState({ activeSell: target.checked })
                                }
                                disabled={this.props.sale}
                              />{' '}
                              Active la opción para que el item sea listado para el modulo
                              de ventas.
                            </label>
                          </Col>
                          <Col md={6} sm={6} xs={12}>
                            <label className={'checkbox-label'}>
                              <br />
                              <h6 style={{ color: '#3394CE' }}>
                                Activar para modulo de Reducción de inventario
                              </h6>
                              <input
                                type={'checkbox'}
                                name={'activeWaste'}
                                checked={activeWaste}
                                onChange={({ target }) =>
                                  this.setState({ activeWaste: target.checked })
                                }
                              />{' '}
                              Active la opción para que el item sea listado para el modulo
                              de Reducción de inventario.
                            </label>
                          </Col>
                          <Col md={6} sm={6} xs={12}>
                            <label className={'checkbox-label'}>
                              <br />
                              <h6 style={{ color: '#3394CE' }}>
                                Activar para modulo de Ingreso de inventarios
                              </h6>
                              <input
                                type={'checkbox'}
                                name={'activeProduction'}
                                checked={activeProduction}
                                onChange={({ target }) =>
                                  this.setState({ activeProduction: target.checked })
                                }
                              />{' '}
                              Active la opción para que el item sea listado para el modulo
                              de Ingreso de inventarios.
                            </label>
                          </Col>
                          <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                            <label className={'checkbox-label'}>
                              <br />
                              <h6 style={{ color: '#3394CE' }}>
                                Actualizar precio durante la venta
                              </h6>
                              <input
                                type={'checkbox'}
                                name={'activeUpdatable'}
                                checked={activeUpdatable}
                                onChange={({ target }) =>
                                  this.setState({ activeUpdatable: target.checked })
                                }
                              />{' '}
                              Permite al usuario actualizar el precio del item durante la
                              creación de la orden de venta
                            </label>
                          </Col>

                          {/*<Col md={6} sm={6} xs={12}>
                                        <label className={'checkbox-label'}>
                                            <br/>
                                            <h6 style={{color: '#3394CE'}}>Activar para el calculo de utilidad bruta</h6>
                                            <input type={'checkbox'} name={'activeUtility'} checked={activeUtility} onChange={({target}) => this.setState({activeUtility: target.checked})}/>{' '}
                                            Si durante una venta el inventario vendido no posee un precio de compra, se tomara el precio de produccion indicado en el item con el switch activo, para calcular la utilidad bruta
                                        </label>
                                    </Col>*/}
                        </Row>
                      </Col>
                    )}
                  </>
                )}
              </Row>
              {dataSheet && (
                <Card
                  white={modal}
                  title={'Ficha Tecnica'}
                  style={{ height: '100%', marginTop: modal ? 15 : 0 }}
                  bodyStyle={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}>
                  {' '}
                  <DataSheet onUpdate={item => this.setState({ itemDS: item })} />
                </Card>
              )}
              <Row className={'container-buttons'}>
                {!isLimit && (
                  <Switch
                    id={'dataSheet'}
                    name={'dataSheet'}
                    checked={dataSheet}
                    label={'Agregar Ficha tecnica'}
                    onChange={() => {
                      if (!dataSheet) this.props.getAllCategorization(15)
                      this.setState({ dataSheet: !dataSheet, itemDS: {} })
                    }}
                  />
                )}{' '}
                <Button
                  className={'align-bottom ml-2'}
                  color={'primary'}
                  onClick={() => this.save()}
                  loading={createIsLoading}
                  icon={faSave}>
                  Guardar
                </Button>
              </Row>
            </Card>
          </Col>
          <SweetAlert
            show={showSuccess}
            title={'¡Exito!'}
            text={'item guardado exitosamente.'}
            type={'success'}
            onConfirm={() => this.clearForm()}
          />
          <SweetAlert
            show={showError}
            title={'¡Uh-Oh!'}
            text={
              createError.message ||
              'Error en los datos trasmitidos, por favor revise e intente de nuevo.'
            }
            type={'error'}
            onConfirm={() => this.setState({ showError: false })}
          />

          <SweetAlert {...alertBase} />
          <SweetAlert {...successAlert} />

          {/***********************************************************
           ***********************MODAL CATEGORIZATION*****************
           * **********************************************************/}
          <Folder
            onHide={() => {
              this.setState({ showCategorizations: false })
              this.props.getAllCategorization(15)
            }}
            onAssign={item => {
              categorizationList.push(item)
              this.setState({ categorizationList })
              this.showNotification(
                'Añadido!',
                `Se ha añadido la categorización ${item.name}`,
              )
            }}
            data1={
              categorization && categorization.children ? categorization.children[0] : {}
            }
            data2={
              categorization && categorization.children ? categorization.children[1] : {}
            }
            show={showCategorizations}
            type={categoryType.PRODUCTS}
          />
        </Row>

        <Modal
          show={modalPrices.show}
          centered
          size={'md'}
          onHide={() => this.setState({ modalPrices: { show: false } })}>
          <Modal.Header closeButton>
            <Modal.Title>Agregar precio de venta</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CustomCreate
              label={'Alias para el precio'}
              onChange={this.handleChange}
              onCreateOption={this.handleCreate}
              options={this.state.create.options}
              value={this.state.create.value}
              placeholder={'Buscar o seleccionar'}
              textLabel={'Nuevo alias: '}
              required
            />
            <MoneyField
              value={modalPrices.price}
              onValueChange={price =>
                this.setState({ modalPrices: { ...modalPrices, price } })
              }
            />
          </Modal.Body>
          <Modal.Footer>
            <Row className={'container-buttons'}>
              <Button
                disabled={!modalPrices.price}
                icon={faSave}
                color={'primary'}
                onClick={() => {
                  let alias = modalPrices.alias
                    ? modalPrices.alias
                    : 'Precio ' + (prices.length + 1)
                  let val = {
                    label: alias + ' - ' + toMoney(modalPrices.price),
                    value: modalPrices.price,
                    alias: alias,
                  }
                  prices.push(val)
                  if (this.state.defaultPrice === null)
                    this.setState({
                      defaultPrice: val,
                      price: val.value,
                      modalPrices: { show: false },
                    })
                  else this.setState({ modalPrices: { show: false } })
                }}>
                Guardar
              </Button>
            </Row>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  products: selectProductsSimple(state),
  product: selectSingleProduct(state),
  namesList: selectPricesList(state),
  createIsLoading: loadingSelector([producActionTypes.CREATE_PRODUCT])(state),
  createHasError: hasErrorsSelector([producActionTypes.CREATE_PRODUCT])(state),
  createError: singleErrorSelector([producActionTypes.CREATE_PRODUCT])(state),
  //categorization
  categorization: selectAllCategorizations(state),
  categorizationLoading: loadingSelector([action.GET_ALL])(state),
  module: selectCurrentModule(state),
  expenseType: selectGetExpenseTypes(state),
  parcels: selectParcels(state),
  //permission
  getPermission: id => getPermission(state, id),
  isAllowed: permissions => isAllowed(state, permissions),
  isLimit: selectCompanyIsLimit(state),
  loadingNames: loadingSelector([producActionTypes.GET_PRICES_NAMES])(state),
  phrases: Object.assign(
    [],
    state.resolutions.phrases.map(p => ({ ...p, label: p.phrase, value: p.id })),
  ),
  plan: selectActivePlans(state),
  variations: selectCategorizationByType(state),
  felItemTypes: selectFelItemTypes(state),
  measurementUnits: selectMeasurementUnits(state),
})

const mapDispatchToProps = dispatch => ({
  getAllProducts: () => dispatch(getProductsSimple()),
  getProductList: () => dispatch(getProductList()),
  getModelProducts: () => dispatch(getModelProducts()),
  createProduct: (request, images) => dispatch(createProduct(request, images)),
  // createProduct: (name, code, barcode, description, price, categorization, images, baseProduct, conversionFactor, subProductId, subProductValue, transformation, model, activeOnline, subPrice, activeLink, production, activePurchase, activeExpenses, activeSell, activeWaste, activeProduction, expenseType, activeUtility, parcelId, useFactor, withoutInventory, discountLimited, discountLimitedNumber, data) => dispatch(createProduct({name, code, barcode, description, price, categorization, images, baseProduct, conversionFactor, subProductId, subProductValue, transformation, model, activeOnline, subPrice, activeLink, production, activePurchase, activeExpenses, activeSell, activeWaste, activeProduction, expenseType, activeUtility, parcelId, useFactor, withoutInventory, discountLimited, discountLimitedNumber, data}, images)),
  uploadFile: (file, path, onProgress, onSuccess, onError) =>
    dispatch(uploadFile(file, path, onProgress, onSuccess, onError)),
  deleteFile: (name, onSuccess) => dispatch(deleteFile({ name, onSuccess })),
  //categorization
  getAllCategorization: type => dispatch(getAllCategorizations(type || 3)),
  //expenses
  getExpenseTypes: () => dispatch(getExpenseType()),
  getPhrases: () => dispatch(getPhaseTypes(6)),

  uploadProductsCSV: (file, onSuccessLoadFile, model) =>
    dispatch(loadProductsCSV(file, onSuccessLoadFile, model)),
  getNames: () => dispatch(getProductPricesNames()),
  getVariations: () => dispatch(getCategorizationByType(20)),
  getMeasurementUnits: () => dispatch(getMeasurementUnits()),
  showAlert: request => dispatch(showAlert(request)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateProduct)
