import React, { Component } from 'react';
import { connect } from 'react-redux';

import {Row, Col, Modal} from 'react-bootstrap';
import { TreeTable, TreeState } from 'cp-react-tree-table';
import Alert from 'sweetalert-react';

import Card from '../../../components/cards/Card';
import CustomSelect from "../../../components/inputs/Select/CustomSelect";

import {
    selectCategorization,
    selectCategorizationTree,selectRootsForSelect,
} from '../../../selectors/categorizations.selector';

import {
    actionTypes, getProductsByRoot, getCategorizationWithProductsByRoot, copyProductsByRoot
} from '../../../actions/categorization.actions';

import { handlerError, handlerSuccess, hasErrorsSelector, singleErrorSelector } from "../../../selectors/error.selector";
import { loadingSelector } from '../../../selectors/loading.selector';
import {Link} from "react-router-relative-link";
import { TableV2 } from '../../../components';

class PublicCategorization extends Component {

    state = {
        categorizationId: null,
        categorization: {},
        newCategorization: {},
        errors: {},
        categorizationChildren: [],
        showSuccess: false,
        showError: false,
        showInfo: false,
        successAlert: {
            show: false,
            type: 'info',
            showCancelButton: false,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: 'green',
            title: '¡Yeah!',
            text: 'Categorización creada satisfactoriamente',
            onConfirm: () => console.log('confirm'),
            onCancel: () => console.log('cancel')
        },
        root: {value: 0, label: 'Seleccione una categorización'},
        products: [],
        selected: {},
        filter: null,
    }

    componentDidMount() {
        this.props.getPublicRoots();
    }

    componentWillReceiveProps(next) {
        const {categorization, copyIsLoading, copyHasError} = this.props;
        const {copyError} = next
        let {successAlert, showSuccess, showError} = this.state;

        const categorizationData = next.categorization;

        if (categorization !== categorizationData) {
            let pr = this.getProducts(categorizationData, [])
            console.log(pr)
            const categorizationTree = this.transformCategorization(categorizationData);
            const categorizationChildren = categorizationTree && TreeState.create(categorizationTree.children || []);
            this.setState({ categorization: categorizationData, categorizationChildren, products: pr});
        }

        if(copyIsLoading && !next.copyIsLoading){
            if(!copyHasError && next.copyHasError){
                this.setState({ showSuccess: false, showError: true });
                successAlert = handlerError(copyError.response ? copyError.response.data.message
                    : 'Ha ocurrido un error inesperado');
                successAlert.onConfirm = () => {
                    this.setState({ successAlert: { ...successAlert, show: false } });
                };
                successAlert.onCancel = () => {
                    this.setState({ successAlert: { ...successAlert, show: false } });
                };
            }
            else{
                successAlert = handlerSuccess('Productos Creados');
                successAlert.onConfirm = () => {
                    this.setState({ successAlert: { ...successAlert, show: false } });
                };
                successAlert.onCancel = () => {
                    this.setState({ successAlert: { ...successAlert, show: false } });
                };
            }
            this.setState({ successAlert });
        }
    }

    onCategoryChange =(e) =>{
        this.setState({root: e})
        this.props.getCategorization(e.value);
    }

    onSelectCategorization = (item) =>{
        const {selected} = this.state
        if(selected === item.id)
            this.setState({selected: 0, filter: null})
        else
            this.setState({filter: item.products, selected: item.id});
    }

    renderNameCell = (row) => {
        const {selected} = this.state
        return (
            <div style={selected === row.data.id ? {backgroundColor: 'rgba(75,107,180,0.6)', paddingLeft: (row.metadata.depth * 15) + 'px', width: '100%'}
                    : {paddingLeft: (row.metadata.depth * 15) + 'px', width: '100%'}}
                 className={row.metadata.hasChildren ? 'with-children' : 'without-children'}
              >  <Row style={{display: 'flex', justifyContent: 'flex-start', }}>
                    {(row.metadata.hasChildren) ? (
                        <button className="toggle-button" onClick={row.toggleChildren}></button>
                    ) : ''
                    }
                    <span style={{cursor: 'pointer'}}
                        onClick={() => this.onSelectCategorization(row.data)}
                        >{row.data.name}</span>
                </Row>
            </div>
        );
    }

    renderDetailCell = (row) => {
        const {selected} = this.state
        return (
            <span style={selected === row.data.id ? {backgroundColor: 'rgba(75,107,180,0.6)'}: {}}
                className={'description-cell'}>{row.data.description}</span>
        );
    }


    handleOnChange = (newValue) => {
        this.setState({ categorizationChildren: newValue });
    }

    transformCategorization = (categorization) => {
        if (categorization) {
            let { name, description, children, id, products } = categorization;
            children = children && children.map(cat => this.transformCategorization(cat))
            return { data: { name, description, id, products }, height: 32, children }
        }
    }

    getProducts = (categorization, list) =>{
        if(categorization){
            categorization.products.map(p =>{this.compareProducts(list, p)})
            if(categorization.children)
                categorization.children.map(c => {list = this.getProducts(c, list)})
        }
        return list;
    }

    compareProducts = (list, prod) =>{
        if(list.find(l => l.id === prod.id)){
            return list;
        }
        list.push(prod)
        return list;
    }

    filter = (item) =>{
        const {filter} = this.state
        return filter ? filter.find(i => i.id ===item.id) : true
    }

    render() {
        const { categorizationChildren, successAlert, showInfo, root, products} = this.state;
        const {roots, currentCurrency} = this.props;
        return (
            <div>
                <Row>
                    <Col sm={12} xs={12} md={8} lg={8}>
                        <Card title={'Categorizaciones'}>
                            <Row>
                                <CustomSelect
                                    label={'Categorizaciones Principales'}
                                    options={roots}
                                    value={root}
                                    onChange={this.onCategoryChange}
                                />
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <TreeTable value={categorizationChildren} onChange={this.handleOnChange}
                                      >  <TreeTable.Column renderHeaderCell={() => <span>Nombre</span>} renderCell={this.renderNameCell} />
                                        <TreeTable.Column renderHeaderCell={() => <span>Descripción</span>} renderCell={this.renderDetailCell} />
                                    </TreeTable>
                                </Col>
                            </Row>
                            {
                                root.value !== 0 &&
                                <Row>
                                    <Col xs={8}/>
                                    <Col xs={4}>
                                        <div className={'big-button'} style={{marginTop: 20}}
                                             onClick={() => this.props.copyProductsByRoot(root.value)}>Copiar Productos</div>
                                    </Col>
                                </Row>
                            }
                        </Card>
                    </Col>
                    <Col sm={12} xs={12} md={4} lg={4}>
                        <Card title={'Productos de la Categoría'}>
                            <TableV2
                                headers={[

                                    { label: 'Nombre', show: true, value: ['name'], type: 'text', className: 'medium' },
                                    { label: 'Precio', show: true, value: ['price'], type: 'currency', className: 'mini' },
                                    { config: true, show: true, label: '', className: 'mini center' }
                                ]}
                                items={products.filter(this.filter)}
                                mobileAuto
                                storageKey={`categorization`}
                            />
                        </Card>
                        <Link className={'big-button'} to={'lista'}>Categorizaciones</Link>
                    </Col>

                </Row>

                {/*****************************************************
                 ***********************MODAL INFO***********************
                 * ****************************************************/}
                <Modal show={showInfo}
                       size={'lg'}
                       centered
                       onHide={() => this.setState({ showInfo: false })}
                  >  <Modal.Header closeButton>
                        <Modal.Title>
                            <span>Información de las categorizaciones</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>

                <Alert {...successAlert} />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    categorization: selectCategorization(state),
    categorizationTree: selectCategorizationTree(state),
    categorizationLoading: loadingSelector([actionTypes.GET_PRODUCTS_BY_PUBLIC_ROOT])(state),
    copyIsLoading: loadingSelector([actionTypes.COPY_PRODUCTS])(state),
    copyHasError: hasErrorsSelector([actionTypes.COPY_PRODUCTS])(state),
    copyError: singleErrorSelector([actionTypes.COPY_PRODUCTS])(state),
    roots: selectRootsForSelect(state),
});
const mapDispatchToProps = dispatch => ({
    getCategorization: (categorizationId) => dispatch(getProductsByRoot(categorizationId)),
    copyProductsByRoot: (root) => dispatch(copyProductsByRoot(root)),
    handlerError: (message) => handlerError(message),
    handlerSuccess: (message) => handlerSuccess(message),
    getPublicRoots: () => dispatch(getCategorizationWithProductsByRoot()),
});
export default connect(mapStateToProps, mapDispatchToProps)(PublicCategorization);
