import React, { useState, useEffect } from 'react'

import { Modal, Row, Col } from 'react-bootstrap'
import { Button, Paragraph, NumberField, CustomDate } from 'src/components'
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons'

import { haveAnyValue } from 'src/utils/utilitiesV2'

interface Props {
  show: boolean
  item: IRecipeItem
  onHide: () => void
  onChange: (item: IRecipeItem) => void
  showDate: boolean
}

/**
 * Detail of a recipe item, you can update the quantity and expiration date
 * @param {Object} props - Component props
 * @param {boolean} props.show - Open recipe item detail
 * @param {IRecipeItem} props.item - Recipe Item to see
 * @param {function} props.onHide - Close recipe item detail
 * @param {function} props.onChange - CallBack with new item detail
 * @param {boolean} props.showDate - Indicates whether you can view and edit the expiration date
 */
const RecipeItemDetail = ({ show, item, onHide, onChange, showDate }: Props) => {
  const [data, setData] = useState<IRecipeItem>(item)

  useEffect(() => {
    if (!show) return
    setData(item)
  }, [show])

  return (
    <Modal show={show} centered onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Selección del ítem</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xl={12}>
            <div className={'b-item'}>
              <Paragraph dim>{item.code}</Paragraph>
              <Paragraph>{item.name}</Paragraph>
            </div>
          </Col>
          <Col xl={12}>
            <NumberField
              label={'Cantidad'}
              value={data.quantity}
              decimals={data.decimals}
              onValueChange={quantity => {
                if (!haveAnyValue(quantity)) quantity = 0
                else if (quantity < 0) quantity = 0
                setData({ ...data, quantity: quantity })
              }}
              autoFocus={show}
            />
          </Col>
          {showDate && (
            <Col xl={12}>
              <CustomDate
                label={'Fecha de expiración'}
                value={data.date ? new Date(data.date) : null}
                onDayChange={(date: Date) => {
                  let dateValueOf: number = undefined
                  if (date) dateValueOf = date.valueOf()
                  setData({ ...data, date: dateValueOf })
                }}
                disabledDays={{}}
              />
            </Col>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className={'container-buttons'}>
          <Button color={'secondary'} icon={faWindowClose} onClick={() => onHide()}>
            Cerrar
          </Button>
          <Button
            icon={faSave}
            disabled={!data.quantity || data.quantity <= 0}
            onClick={() => onChange(data)}>
            Continuar
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}
export default RecipeItemDetail
