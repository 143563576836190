import React from 'react'

import { Modal } from 'react-bootstrap'
import { getItemValue } from 'src/utils/formatters'
import { TableV2 } from '../index'

const CSVLoaderError = ({ show, items, onHide }) => {
  const getItemsMobile = () => {
    let array = []
    let store = {
      title: { value: ['line'], type: 'text' },
      subtitle: { value: ['column2'], type: 'text' },
      complement1: { value: ['error'], type: 'text' },
      complement2: { value: ['message'], type: 'text' },
    }

    if (items) {
      items.map((item, index) => {
        const { line, column, error, message } = item
        const more = [
          { title: 'Línea', info: line },
          { title: 'Columna', info: column },
          { title: 'Error', info: error },
          { title: 'Mensaje', info: message },
        ]

        const object = {
          id: index,
          title: getItemValue(item, store.title.value, store.title.type),
          subtitle: getItemValue(item, store.subtitle.value, store.subtitle.type),
          complement1: getItemValue(
            item,
            store.complement1.value,
            store.complement1.type,
          ),
          complement2: getItemValue(
            item,
            store.complement2.value,
            store.complement2.type,
          ),
          key: `prod-${index}`,
          more,
        }

        array.push(object)
      })
    }
    return array
  }

  return (
    <Modal show={show} size={'xl'} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Detalle de carga</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Durante la carga se encontraron los siguientes errores o detalles</p>
        <TableV2
          headers={[
            { label: 'Línea', show: true, type: 'text', value: ['line'] },
            { label: 'Columna', show: true, type: 'text', value: ['column'] },
            { label: 'Error', show: true, type: 'text', value: ['error'] },
            { label: 'Mensaje', show: true, type: 'text', value: ['message'] },
          ]}
          items={items || []}
          renderRow={(item, i) => (
            <tr key={i} className={'table-option a-in-opacity'}>
              <td className={'text-left'}>{item.line}</td>
              <td className={'text-left'}>{item.column}</td>
              <td className={'text-left'}>{item.error}</td>
              <td className={'text-left'}>{item.message}</td>
            </tr>
          )}
          itemsMobile={getItemsMobile()}
        />
      </Modal.Body>
    </Modal>
  )
}
export default CSVLoaderError
