import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage'
import uuid from 'uuid-random'
import UploadService from '../services/uploads.services'
import { executeAction } from './global.actions'
import { getApp } from 'firebase/app'

export const actionTypes = {
  UPLOAD: 'UPLOAD',
  UPLOAD_REQUEST: 'UPLOAD_REQUEST',
  UPLOAD_SUCCESS: 'UPLOAD_SUCCESS',
  UPLOAD_ERROR: 'UPLOAD_ERROR',

  GET_FILES: 'GET_FILES',
  GET_FILES_REQUEST: 'GET_FILES_REQUEST',
  GET_FILES_SUCCESS: 'GET_FILES_SUCCESS',
  GET_FILES_ERROR: 'GET_FILES_ERROR',

  GET_FILES2: 'GET_FILES2',
  GET_FILES_REQUEST2: 'GET_FILES_REQUEST2',
  GET_FILES_SUCCESS2: 'GET_FILES_SUCCESS2',
  GET_FILES_ERROR2: 'GET_FILES_ERROR2',

  DELETE_FILE: 'DELETE_FILE',
  DELETE_FILE_REQUEST: 'DELETE_FILE_REQUEST',
  DELETE_FILE_SUCCESS: 'DELETE_FILE_SUCCESS',
  DELETE_FILE_ERROR: 'DELETE_FILE_ERROR',

  GET_PHOTOS: 'GET_PHOTOS',
  GET_PHOTOS_REQUEST: 'GET_PHOTOS_REQUEST',
  GET_PHOTOS_SUCCESS: 'GET_PHOTOS_SUCCESS',
  GET_PHOTOS_ERROR: 'GET_PHOTOS_ERROR',

  GET_LOGO: 'GET_LOGO',
  GET_LOGO_REQUEST: 'GET_LOGO_REQUEST',
  GET_LOGO_SUCCESS: 'GET_LOGO_SUCCESS',
  GET_LOGO_ERROR: 'GET_LOGO_ERROR',

  GET_DOCUMENTS: 'GET_DOCUMENTS',
}

export const uploadRequest = () => ({ type: actionTypes.UPLOAD_REQUEST })
export const uploadSuccess = () => ({ type: actionTypes.UPLOAD_SUCCESS })
export const uploadError = () => ({ type: actionTypes.UPLOAD_ERROR })

export const getFilesRequest = () => ({ type: actionTypes.GET_FILES_REQUEST })
export const getFilesSuccess = files => ({
  type: actionTypes.GET_FILES_SUCCESS,
  files,
})
export const getFilesError = () => ({ type: actionTypes.GET_FILES_ERROR })

export const getFilesRequest2 = () => ({
  type: actionTypes.GET_FILES_REQUEST2,
})
export const getFilesSuccess2 = files => ({
  type: actionTypes.GET_FILES_SUCCESS2,
  files,
})
export const getFilesError2 = () => ({ type: actionTypes.GET_FILES_ERROR2 })

export const deleteFileRequest = () => ({
  type: actionTypes.DELETE_FILE_REQUEST,
})
export const deleteFileSuccess = () => ({
  type: actionTypes.DELETE_FILE_SUCCESS,
})
export const deleteFileError = () => ({ type: actionTypes.DELETE_FILE_ERROR })

export const getPhotosRequest = () => ({
  type: actionTypes.GET_PHOTOS_REQUEST,
})
export const getPhotosSuccess = files => ({
  type: actionTypes.GET_PHOTOS_SUCCESS,
  files,
})
export const getPhotosError = () => ({ type: actionTypes.GET_PHOTOS_ERROR })

export const getLogoRequest = () => ({ type: actionTypes.GET_LOGO_REQUEST })
export const getLogoSuccess = logo => ({
  type: actionTypes.GET_LOGO_SUCCESS,
  logo,
})
export const getLogoError = () => ({ type: actionTypes.GET_LOGO_ERROR })

export const uploadFile =
  ({
    file,
    imageType,
    entityId,
    description,
    company,
    onProgress,
    onSuccess,
    onError,
    carousel,
    gallery,
  }) =>
  dispatch => {
    if (file instanceof File) {
      dispatch(uploadRequest())
      const { name } = file
      const extension = name.split('.').reverse()[0]
      const randomName = `${imageType.path}/${uuid()}.${extension}`

      const storage = getStorage(getApp())
      const storageRef = ref(storage, randomName)

      const uploadTask = uploadBytesResumable(storageRef, file)

      /*
      const uploadTask = ref.put(file)
      */
      uploadTask.on(
        'state_changed',
        snapshot => {
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          if (onProgress) {
            onProgress(progress)
          }
        },
        error => {
          dispatch(uploadError())
          if (onError) {
            onError(error)
          }
        },
        async () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async url => {
            if (entityId) {
              await UploadService.addImage(
                imageType.id,
                entityId,
                url,
                randomName,
                description,
              )
              if (carousel) dispatch(getCarouselPhotos(imageType, entityId, company))
              else dispatch(getFiles(imageType, entityId, company, gallery))
            }
            if (onSuccess) {
              onSuccess(url, randomName)
            }
            dispatch(uploadSuccess())
          })
          // const url = await uploadTask.snapshot.ref.getDownloadURL()
        },
      )
    }
  }

export const getFiles = (imageType, entityId, company, gallery) => async dispatch => {
  if (gallery === 1 || !gallery) dispatch(getFilesRequest())
  if (gallery === 2) dispatch(getFilesRequest2())
  try {
    const files = await UploadService.getImages(imageType.id, entityId, company)
    if (gallery === 1 || !gallery) dispatch(getFilesSuccess(files))
    if (gallery === 2) dispatch(getFilesSuccess2(files))
  } catch (error) {
    console.log(error)
    if (gallery === 1 || !gallery) dispatch(getFilesError(error.response.data))
    if (gallery === 2) dispatch(getFilesError2(error.response.data))
  }
}

export const getLogo = company => async dispatch => {
  if (company === 0) {
    dispatch(getLogoSuccess([]))
    return
  }
  dispatch(getLogoRequest())
  try {
    const files = await UploadService.getImages(7, company, company)
    dispatch(getLogoSuccess(files))
  } catch (error) {
    console.log(error)
    dispatch(getLogoError(error.response.data))
  }
}

export const deleteFile =
  ({ id, name, imageType, entityId, company, onSuccess, carousel, gallery }) =>
  async dispatch => {
    dispatch(deleteFileRequest())
    try {
      const storage = getStorage(getApp())
      const storageRef = ref(storage, name)

      await deleteObject(storageRef)

      if (id && entityId) {
        await UploadService.deleteImage(imageType.id, entityId, id)
        if (carousel) dispatch(getCarouselPhotos(imageType, entityId, company))
        else dispatch(getFiles(imageType, entityId, company, gallery))
      }
      if (onSuccess) {
        onSuccess()
      }
      dispatch(deleteFileSuccess())
    } catch (error) {
      console.log(error)
      dispatch(deleteFileError(error))
    }
  }

export const getDocuments = () => async dispatch => {
  const process = async () => ({
    response: await UploadService.getDocuments(),
  })
  dispatch(executeAction(actionTypes.GET_DOCUMENTS, process))
}

export const uploadDocument =
  (file, type, onProgress, onSuccess, onError) => async () => {
    if (file instanceof File) {
      //dispatch(uploadRequest());
      const { name } = file
      const extension = name.split('.').reverse()[0]
      const randomName = `doc-${uuid()}.${extension}`

      const storage = getStorage(getApp())
      const ref = storage.ref().child(randomName)
      const uploadTask = ref.put(file)

      uploadTask.on(
        'state_changed',
        snapshot => {
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          if (onProgress) {
            onProgress(progress)
          }
        },
        error => {
          //dispatch(uploadError());
          if (onError) {
            onError(error)
          }
        },
        async () => {
          const url = await uploadTask.snapshot.ref.getDownloadURL()
          const responseFile = await UploadService.addDocument(type, url, randomName)
          if (onSuccess) {
            onSuccess(url, responseFile.legalDocumentType)
          }
          //dispatch(uploadSuccess());
        },
      )
    }
  }

export const updateFile =
  ({ imageType, entityId, url, randomName, description, company }) =>
  async dispatch => {
    await UploadService.addImage(
      imageType.id,
      entityId,
      url,
      randomName,
      description,
      true,
    )
    dispatch(getFiles(imageType, entityId, company))
  }

export const uploadVideo =
  (file, onProgress, onSuccess, onError, path) => async dispatch => {
    if (file instanceof File) {
      console.log('INSIDE::::')

      //dispatch(uploadRequest());
      const { name } = file
      const extension = name.split('.').reverse()[0]
      const randomName = path
        ? `${path}/${uuid()}.${extension}`
        : `/videos/vid-${uuid()}.${extension}`

      const storage = getStorage(getApp())
      const storageRef = ref(storage, randomName)
      const uploadTask = uploadBytesResumable(storageRef, file)

      // const ref = storage.ref().child(randomName)
      // const uploadTask = ref.put(file)

      console.log('RND NAMW::::', randomName)

      uploadTask.on(
        'state_changed',
        snapshot => {
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          if (onProgress) {
            onProgress(progress)
          }
        },
        error => {
          dispatch(uploadError())
          if (onError) {
            onError(error)
          }
        },
        async () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async url => {
            console.log('URL::::', url)
            if (onSuccess) {
              onSuccess(url, randomName)
            }
          })
          //const url = await uploadTask.snapshot.ref.getDownloadURL()
        },
      )
    }
  }

export const getCarouselPhotos = (imageType, entityId, company) => async dispatch => {
  dispatch(getPhotosRequest())
  try {
    const files = await UploadService.getImages(imageType.id, entityId, company)
    dispatch(getPhotosSuccess(files))
  } catch (error) {
    console.log(error)
    dispatch(getPhotosError(error.response.data))
  }
}

export const addImage = (type, parentId, images) => dispatch => {
  const process = async () => ({
    response: await UploadService.addImages(type, parentId, images),
  })
  dispatch(executeAction(actionTypes.UPLOAD, process))
}

export const deleteImage = (type, parentId, imageId) => dispatch => {
  const process = async () => ({
    response: await UploadService.deleteImage(type, parentId, imageId),
  })
  dispatch(executeAction(actionTypes.DELETE_FILE, process))
}
