import React, { useState, useLayoutEffect } from 'react'
import './StatusStep.scss'
import { formatDateFromMillis } from 'src/utils/formatters'

import ArrowUp from 'src/assets/arrow-up.svg'

const StatusStep = ({ status, value, current }) => {
  const [isOpen, setOpen] = useState(false)
  const [maxHeight, setHe] = useState(200)

  useLayoutEffect(() => {
    const height = 60 * (status.length > 0 ? status.length : 1)
    setHe(height)
    const content = document.getElementById('cc')
    content.style.height = height + 'px'
  }, [status])

  return (
    <div className={'d-flex flex-row align-items-start row-height-normal'} id={'cc'}>
      <div className={'d-flex'}>
        {!isOpen ? (
          <>
            <span
              className={'a-in-opacity a-in-zoom dot-normal mr-2'}
              style={{ backgroundColor: '#449747' }}
            />
            {current}
          </>
        ) : (
          <div className={`no-select d-flex flex-column align-items-end`} style={{}}>
            {status &&
              status.map((item, i) => (
                <div
                  key={1}
                  className={'d-flex flex-row'}
                  style={{ zIndex: i === 0 ? 1 : i + 2 }}
                >
                  <div className={'d-flex flex-column align-items-end mx-2 '}>
                    <div className={'order-text-small'}>{item.statusName}</div>
                    <div className={'order-date'}>{formatDateFromMillis(item.date)}</div>
                  </div>
                  <div
                    className={
                      'd-flex flex-column justify-content-center align-items-center'
                    }
                    style={{ width: 18 }}
                  >
                    <span
                      className={
                        value === item.statusId ? 'dot-normal heart' : 'dot-small'
                      }
                      style={{
                        backgroundColor: value === item.statusId ? '#449747' : '#DCDCDC',
                        marginTop: 4,
                      }}
                    />
                    {value !== item.statusId && (
                      <div
                        className={'status-line'}
                        style={{
                          backgroundColor:
                            value === item.statusId ? '#525252' : '#DCDCDC',
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>

      <img
        style={{ marginTop: '-8px', marginRight: '-8px' }}
        className={!isOpen ? 'arrow-rotate' : 'arrow-normal'}
        src={ArrowUp}
        alt={''}
        onClick={() => {
          const open = !isOpen

          const content = document.getElementById('cc')
          content.style.maxHeight = !open ? '50px' : maxHeight + 'px'

          setOpen(open)
        }}
      />
    </div>
  )
}
export default StatusStep
