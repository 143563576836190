import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, ProgressBar, Modal, Table } from 'react-bootstrap'
import Card from 'src/components/cards/Card'
import CollapsibleCard from 'src/components/cards/collapsible-card/CollapsibleCard'
import CustomSelect from 'src/components/inputs/Select/CustomSelect'
import { Td, Tr } from 'react-super-responsive-table'
import { formatDateFromMillis } from 'src/utils/formatters'
import { getWarehouses } from 'src/actions/warehouse.actions'
import { getProducts } from 'src/actions/products.actions'
import { selectAllProductsWithAllForSelect } from 'src/selectors/products.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { Bar } from 'react-chartjs-2'
import ButtonIcon from 'src/components/buttons/IconButton'
import {
  faCheckCircle,
  faEye,
  faFilter,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { actionTypes as OrderActions, getUserReport } from 'src/actions/orders.actions'
import { selectCurrentModule, selectCurrentUser } from 'src/selectors/user.selector'
import { selectProductReport } from 'src/selectors/orders.selector'
import { Button, Icon, SelectedDates, Title } from 'src/components'
import { getUsersChildrenByModule } from 'src/actions/modules.actions'
import { selectUsers } from 'src/selectors/modules.selector'
import { selectAllWarehousesForSelect } from 'src/selectors/warehouse.selector'
import {
  actionTypes,
  deleteReportSubscription,
  getReportSubscription,
  getSalesPdfReport,
} from 'src/actions/report.actions'

import SaleGrph from 'src/components/modal/Orders/ModalOrderTotalSummary'

import { graphs as colorArray, quantityOptions } from 'src/utils/graphs'
import {
  actionTypes as action,
  getAllCategorizations,
} from 'src/actions/categorization.actions'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'
import Folder from 'src/components/folders/Folder'
import CreateSubscription from '../../Subscription/CreateSubscription'
import {
  selectQueriesReport,
  selectSubscribesReport,
} from 'src/selectors/report.selector'
import { handlerSuccess } from 'src/selectors/error.selector'
import Alert from 'sweetalert-react'
import { actionTypes as posTypes, getAllPOSByUser } from 'src/actions/restaurant.actions'
import { selectAllPOSUser } from 'src/selectors/restaurant.selector'
import { toMoney } from 'src/utils/utilities'
import Money from 'src/components/Money/Money'

const chartOptions = {
  scales: {
    xAxes: [{ stacked: true, gridLines: { display: false } }],
    yAxes: [
      {
        stacked: true,
        type: 'linear',
        ticks: {
          beginAtZero: true,
          callback: function (label) {
            return label > 999999
              ? label / 100000000 + 'M'
              : label > 999
              ? label / 1000 + 'k'
              : label
          },
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || ''
        if (label) {
          label += ': '
        }
        label += toMoney(tooltipItem.yLabel)
        return label
      },
    },
  },
  plugins: {
    labels: {
      render: function () {
        // return args.percentage <= 0 ? '' : args.percentage + '%'
        return ''
      },
      fontSize: 12,
      fontStyle: 'bold',
      fontColor: '#282c34',
      fontFamily: 'Montserrat',
    },
  },
  legend: { display: false },
}

const chartOptionsMobile = {
  scales: {
    xAxes: [{ stacked: true, gridLines: { display: false } }],
    yAxes: [
      {
        stacked: true,
        type: 'linear',
        ticks: {
          beginAtZero: true,
          callback: function (label) {
            return label > 999999
              ? label / 100000000 + 'M'
              : label > 999
              ? label / 1000 + 'k'
              : label
          },
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || ''
        if (label) {
          label += ': '
        }
        label += toMoney(tooltipItem.yLabel)
        return label
      },
    },
  },
  plugins: {
    labels: {
      render: function () {
        return ''
      },
      display: false,
      fontSize: 12,
      fontStyle: 'bold',
      fontColor: '#282c34',
      fontFamily: 'Montserrat',
    },
  },
  legend: { display: false },
}

const types = [
  { value: 1, label: 'Ventas propias' },
  { value: 2, label: 'Ventas Kolo' },
  { value: 3, label: 'Ventas Totales' },
]

class SaleReport extends Component {
  state = {
    startDate: new Date(),
    endDate: new Date(),
    selectedUser: { value: 0, label: 'Seleccione un usuario' },
    selectedType: { value: 1, label: 'Ventas propias' },
    products: [],
    users: [],
    selectedProduct: { value: 0, label: 'Seleccione un producto' },
    // selectUser: {}
    selectedWarehouse: { value: 0, label: 'Seleccione una bodega' },
    warehouses: [],
    quantityProducts: { value: 10, label: '10 Productos' },
    isTotalSale: window.location.href.includes('/totales'),
    sortProducts: { value: 1, label: 'Con más' },
    selectedProducts: [],
    showCategorizations: false,
    selectedCategorization: [],
    subscriptionModal: { show: false },
    subscription: null,
    alert: { show: false },
    selectedPos: [],
    flagSetValues: false,
  }

  componentDidMount() {
    const { module, user } = this.props
    const { products, selectedType, users, warehouses } = this.state

    this.getReport({ type: selectedType, users, products, warehouses })
    this.props.getProducts()
    this.props.getWarehouses()

    this.props.getUsersChildrenByModule(module, true)
    this.props.getCategories()
    this.props.getReportSubscription()
    this.props.getPos(user.id)
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { loadingAction, loadingCreate, queries } = this.props

    if (queries?.params && !this.state.flagSetValues) {
      const { selectedType, products, users, warehouses } = this.state

      if (this.props.users.length > 0) {
        this.setState({ flagSetValues: true })
        // console.log(queries)
        // console.log(users)
        // console.log(this.props.users)
        // console.log(this.props.users.find(user => user.id === queries.params.userId))
        if (queries.params.userId) {
          let user = this.props.users.find(user => user.id === queries.params.userId)
          users.push(user)
          this.setState({ users })
        }

        this.setState({
          startDate: new Date(queries.params.start),
          endDate: new Date(queries.params.end),
        })
        this.getReport({
          users,
          type: selectedType,
          startDate: queries.params.start,
          endDate: queries.params.end,
          products,
          warehouses,
        })
      }
    }

    if (loadingAction && !next.loadingAction) {
      this.props.getReportSubscription()
      this.setState({
        alert: {
          ...handlerSuccess('Suscripción borrada'),
          onConfirm: () => this.setState({ alert: { show: false } }),
        },
        id: null,
      })
    }

    if (loadingCreate && !next.loadingCreate) {
      this.props.getReportSubscription()
    }
  }

  onDateFromChange = date => {
    const { endDate, selectedType, users, products, warehouses } = this.state
    if (date) {
      this.setState({ startDate: date })
      this.getReport({
        users,
        type: selectedType,
        startDate: date,
        endDate,
        products,
        warehouses,
      })
    }
  }

  onDateToChange = date => {
    const { startDate, selectedType, users, products, warehouses } = this.state
    if (date) {
      this.setState({ endDate: date })
      this.getReport({
        users,
        type: selectedType,
        startDate,
        endDate: date,
        products,
        warehouses,
      })
    }
  }

  getReport = ({ users, products, warehouses, startDate, endDate, type }) => {
    startDate = startDate || this.state.startDate
    endDate = endDate || this.state.endDate
    const { selectedCategorization, selectedPos } = this.state

    let start = new Date(startDate).setHours(0, 0, 0, 0).valueOf()
    let end = new Date(endDate).setHours(23, 59, 59, 59).valueOf()

    let list = products.map(p => p.value)
    let listUsers = users.map(p => p.value)
    let listWarehouses = warehouses.map(p => p.value)
    if (!this.state.isTotalSale)
      this.props.getUserReport(
        type.value,
        start,
        end,
        listUsers,
        list,
        listWarehouses,
        selectedCategorization.map(p => p.id),
        selectedPos.map(p => p.id),
      )
  }

  getPdf = ({ users, products, warehouses, startDate, endDate, type }) => {
    const { selectedCategorization, selectedPos } = this.state
    startDate = startDate || this.state.startDate
    endDate = endDate || this.state.endDate

    let list = products.map(p => p.value)
    let listUsers = users.map(p => p.value)
    let listWarehouses = warehouses.map(p => p.value)
    this.props.getSalePdf(
      startDate.setHours(0, 0, 0, 0).valueOf(),
      endDate.setHours(23, 59, 59, 59).valueOf(),
      type.value,
      {
        users: listUsers,
        products: list,
        warehouses: listWarehouses,
        categories: selectedCategorization.map(p => p.id),
        pos: selectedPos.map(p => p.id),
      },
    )
  }

  renderRow = (item, index) => {
    return (
      <Tr className="data" key={index}>
        <Td>{item.warehouseNode}</Td>
        <Td>{item.originalQuantity}</Td>
        <Td>{item.categorizationName}</Td>
        <Td>{formatDateFromMillis(item.date)}</Td>
      </Tr>
    )
  }

  onSelectProduct = selectedProduct => {
    const { users, selectedType, products, warehouses } = this.state
    products.push(selectedProduct)
    this.setState({ products })
    this.getReport({ users, type: selectedType, products, warehouses })
  }

  onSelectWarehouse = selectedWarehouse => {
    const { warehouses, selectedType, products, users } = this.state
    warehouses.push(selectedWarehouse)
    this.setState({ warehouses })
    this.getReport({ users, type: selectedType, products, warehouses })
  }

  onSelectType = e => {
    const { users, products, warehouses } = this.state
    this.setState({ selectedType: e })
    this.getReport({ users, type: e, products, warehouses })
  }

  onSelectUser = selectedUser => {
    const { selectedType, products, users, warehouses } = this.state
    users.push(selectedUser)
    this.setState({ users })
    this.getReport({ users, type: selectedType, products, warehouses })
  }

  getData = details => {
    const { selectedProducts, sortProducts } = this.state
    let data = []
    details.forEach(d => {
      d.products.forEach(p => {
        let index = data.findIndex(l => l.id === p.id)
        if (index === -1) {
          let see = selectedProducts.findIndex(l => l.id === p.id) === -1
          data.push({
            ...p,
            totalSold: p.total,
            color: see ? colorArray[data.length] : 'white',
            see,
          })
        } else {
          data[index].totalSold += p.total
        }
      })
    })
    if (sortProducts.value === 1) return data.sort((a, b) => b.totalSold - a.totalSold)
    else return data.sort((a, b) => a.totalSold - b.totalSold)
  }

  getValues = (details, id) => {
    const { selectedProducts } = this.state
    let data = []
    details.forEach(d => {
      let see = selectedProducts.findIndex(l => l.id === id) === -1
      if (see) {
        let value = d.products.findIndex(l => l.id === id)
        if (value !== -1) {
          data.push(d.products[value])
        } else {
          data.push({ total: 0 })
        }
      } else data.push({ total: 0 })
    })
    return data
  }

  getInfoToChart = () => {
    const { report } = this.props
    const { selectedProducts, quantityProducts } = this.state
    let data = this.getData(report)
    let labels = report
      .map(r => {
        return {
          ...r,
          products: r.products.filter(l => {
            return selectedProducts.findIndex(p => p.id === l.id) === -1
          }),
        }
      })
      .filter(r => r.products.length > 0)
    return {
      labels: labels.map(r => {
        return formatDateFromMillis(r.date)
      }),
      datasets: data
        .filter(d => d.see)
        .slice(0, quantityProducts.value)
        .map(d => {
          return {
            data: this.getValues(labels, d.id).map(s => s.total),
            label: d.name,
            backgroundColor: d.color + 66,
            borderColor: d.color,
            borderWidth: 2,
          }
        }),
    }
  }

  deleteUser = id => {
    const { selectedType, products, warehouses } = this.state
    let { users } = this.state
    users = users.filter(p => id !== p.value)
    this.setState({ users })
    this.getReport({ users, type: selectedType, products, warehouses })
  }

  deleteProduct = id => {
    const { users, selectedType, warehouses } = this.state
    let { products } = this.state
    products = products.filter(p => id !== p.value)
    this.setState({ products })
    this.getReport({ users, type: selectedType, products, warehouses })
  }

  deleteWarehouse = id => {
    const { users, products, selectedType } = this.state
    let { warehouses } = this.state
    warehouses = warehouses.filter(p => id !== p.value)
    this.setState({ warehouses })
    this.getReport({ users, type: selectedType, products, warehouses })
  }

  rowClicked = data => {
    let { selectedProducts } = this.state
    const index = selectedProducts.findIndex(p => p.id === data.id)
    if (index === -1) selectedProducts.push(data)
    else selectedProducts.splice(index, 1)
    this.setState({ selectedProducts })
  }

  render() {
    const {
      selectedUser,
      selectedProduct,
      selectedType,
      startDate,
      endDate,
      products,
      users,
      selectedWarehouse,
      warehouses,
      isTotalSale,
      quantityProducts,
      sortProducts,
      showCategorizations,
      selectedCategorization,
      selectedPos,
      subscriptionModal,
      showList,
      subscription,
      alert,
      loadingAction,
      loading,
    } = this.state
    const {
      allProducts,
      reportIsLoading,
      allWarehouses,
      report,
      categorization,
      subscriptions,
      allPOS,
    } = this.props
    return (
      <div>
        <Title title={'Reporte de Ventas'} />

        <Row>
          <Col xl={12} lg={12} md={12} sm={12}>
            <Card title={'Filtros'} white>
              <Row>
                <Col xs={12}>
                  <SelectedDates
                    noFormat
                    nonExecute
                    dates={{ dateFrom: startDate, dateTo: endDate }}
                    onDateChange={(startDate, endDate) =>
                      this.setState({ startDate, endDate })
                    }
                  />
                </Col>
                {!this.state.isTotalSale && (
                  <Col xl={4} lg={4} md={4} sm={12} xs={12} className={'mt-5'}>
                    <Button
                      icon={faFilter}
                      loading={reportIsLoading}
                      onClick={() => {
                        this.getReport({
                          users,
                          type: selectedType,
                          products,
                          warehouses,
                        })
                      }}>
                      Filtrar
                    </Button>
                  </Col>
                )}
                {isTotalSale && (
                  <Col xl={2} lg={2} md={2} sm={12} xs={12} className={'mt-5'}>
                    <Button
                      color={'primary'}
                      icon={faCheckCircle}
                      onClick={() => {
                        this.setState({ showList: true })
                      }}>
                      Ver suscripciones
                    </Button>
                  </Col>
                )}
              </Row>
              <Row className={'pl-1'}>
                {selectedCategorization.map((p, index) => (
                  <div className={'user-tag product-t ml-2'} key={index}>
                    <label className={'label-user-tag'}>{p.name}</label>
                    <ButtonIcon
                      className={'delete-user-tag d-product-t'}
                      icon={faTimes}
                      tooltip={'Quitar'}
                      color={'white'}
                      onClick={() =>
                        this.setState(
                          {
                            selectedCategorization: selectedCategorization.filter(
                              f => f.id !== p.id,
                            ),
                          },
                          () => {
                            this.getReport({
                              users,
                              products,
                              warehouses,
                              startDate,
                              endDate,
                              type: selectedType,
                            })
                          },
                        )
                      }
                    />
                  </div>
                ))}
                <Button
                  style={{ marginLeft: 10, marginTop: 15 }}
                  color={'primary'}
                  onClick={() => this.setState({ showCategorizations: true })}>
                  Filtrar por categorías
                </Button>
              </Row>
              <Row>
                <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                  <CustomSelect
                    label={'Puntos de venta'}
                    options={allPOS}
                    placeholder={'Seleccione un punto de venta'}
                    onChange={e => {
                      selectedPos.push(e)
                      this.setState({ selectedPos }, () =>
                        this.getReport({
                          users,
                          products,
                          warehouses,
                          startDate,
                          endDate,
                          type: selectedType,
                        }),
                      )
                    }}
                  />
                </Col>
                <Col
                  xl={9}
                  lg={9}
                  md={8}
                  sm={12}
                  xs={12}
                  style={{ alignSelf: 'flex-end' }}>
                  <Row>
                    {selectedPos.map((p, index) => (
                      <div className={'user-tag product-t ml-2'} key={index}>
                        <label className={'label-user-tag'}>{p.label}</label>
                        <ButtonIcon
                          className={'delete-user-tag d-product-t'}
                          icon={faTimes}
                          tooltip={'Quitar'}
                          color={'white'}
                          onClick={() =>
                            this.setState(
                              {
                                selectedPos: selectedPos.filter(f => f.id !== p.id),
                              },
                              () => {
                                this.getReport({
                                  users,
                                  products,
                                  warehouses,
                                  startDate,
                                  endDate,
                                  type: selectedType,
                                })
                              },
                            )
                          }
                        />
                      </div>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
          {isTotalSale ? (
            <Col xl={12} lg={12} md={12} sm={12}>
              <CollapsibleCard title={'Reporte de ventas'}>
                <SaleGrph
                  cDates={{ start: startDate, end: endDate }}
                  categories={selectedCategorization}
                  pSale={selectedPos}
                />
              </CollapsibleCard>
            </Col>
          ) : (
            <Col xl={12} lg={12} md={12} sm={12}>
              <Row>
                <Col>
                  <CollapsibleCard title={'Reporte de ventas'}>
                    <Row>
                      <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                        <CustomSelect
                          label={'Tipo'}
                          value={selectedType}
                          options={types}
                          onChange={this.onSelectType}
                        />
                      </Col>
                      <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                        <CustomSelect
                          label={'Usuario'}
                          value={selectedUser}
                          options={this.props.users.filter(s => {
                            return users.filter(f => f.value === s.value).length === 0
                          })}
                          onChange={this.onSelectUser}
                        />
                      </Col>
                      <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                        <CustomSelect
                          label={'Producto'}
                          value={selectedProduct}
                          options={allProducts.filter(s => {
                            return (
                              products.filter(f => f.value === s.value).length === 0 &&
                              s.value !== 0
                            )
                          })}
                          onChange={this.onSelectProduct}
                        />
                      </Col>
                      <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                        <CustomSelect
                          label={'Bodegas'}
                          value={selectedWarehouse}
                          options={allWarehouses.filter(s => {
                            return (
                              warehouses.filter(f => f.value === s.value).length === 0 &&
                              s.value !== 0
                            )
                          })}
                          onChange={this.onSelectWarehouse}
                        />
                      </Col>
                      <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                        <Button
                          color={'primary'}
                          style={{ marginTop: 45 }}
                          onClick={() =>
                            this.getPdf({
                              users,
                              products,
                              warehouses,
                              type: selectedType,
                            })
                          }>
                          Descargar en PDF
                        </Button>
                      </Col>
                    </Row>
                    <Row className={'pl-1'}>
                      {users &&
                        users.map((p, key) => (
                          <div className={'user-tag  ml-2'} key={key}>
                            <label className={'label-user-tag'}>{p.label}</label>
                            <ButtonIcon
                              className={'delete-user-tag'}
                              icon={faTimes}
                              tooltip={'Quitar'}
                              color={'white'}
                              onClick={() => this.deleteUser(p.value)}
                            />
                          </div>
                        ))}
                    </Row>
                    <Row className={'pl-1'}>
                      {products &&
                        products.map(p => (
                          <div className={'user-tag product-t ml-2'} key={p.value}>
                            <label className={'label-user-tag'}>{p.label}</label>
                            <ButtonIcon
                              className={'delete-user-tag d-product-t'}
                              icon={faTimes}
                              tooltip={'Quitar'}
                              color={'white'}
                              onClick={() => this.deleteProduct(p.value)}
                            />
                          </div>
                        ))}
                    </Row>
                    <Row className={'pl-1'}>
                      {warehouses &&
                        warehouses.map(p => (
                          <div className={'user-tag warehouse-t ml-2'} key={p.value}>
                            <label className={'label-user-tag'}>{p.label}</label>
                            <ButtonIcon
                              className={'delete-user-tag d-warehouse-t'}
                              icon={faTimes}
                              tooltip={'Quitar'}
                              color={'white'}
                              onClick={() => this.deleteWarehouse(p.value)}
                            />
                          </div>
                        ))}
                    </Row>
                    <br />
                    {reportIsLoading && (
                      <Row>
                        <Col>
                          <div className={'pb-custom'}>
                            <ProgressBar
                              label="Cargando"
                              animated
                              now={100}
                              style={{ marginBottom: 10 }}
                            />
                          </div>
                        </Col>
                      </Row>
                    )}
                    <br />
                    <Row>
                      <Col md={4}>
                        <CustomSelect
                          label={'Cantidad de Productos'}
                          name={'product_quantity'}
                          value={quantityProducts}
                          options={quantityOptions}
                          onChange={value => this.setState({ quantityProducts: value })}
                        />
                      </Col>
                      <Col md={4}>
                        <CustomSelect
                          label={'Ordenar'}
                          name={'sort'}
                          options={[
                            { value: 1, label: 'Con más' },
                            { value: 2, label: 'Con menos' },
                          ]}
                          value={sortProducts}
                          onChange={value => this.setState({ sortProducts: value })}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={8}>
                        <>
                          <div className="ru-graph-w" style={{ marginTop: 16 }}>
                            <Bar
                              data={this.getInfoToChart(1)}
                              height={200}
                              options={chartOptions}
                            />
                          </div>
                          <div className="ru-graph-m" style={{ marginTop: 16 }}>
                            <Bar
                              data={this.getInfoToChart(1)}
                              height={400}
                              options={chartOptionsMobile}
                            />
                          </div>
                        </>
                      </Col>
                      <Col lg={4}>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th style={{ width: 70 }} />
                              <th>Nombre</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {report && this.getData(report).length > 0 ? (
                              this.getData(report)
                                .slice(0, quantityProducts.value)
                                .map((item, index) => {
                                  return (
                                    <tr
                                      key={index}
                                      className={'g-row'}
                                      onClick={() => this.rowClicked(item)}>
                                      <td className={'index-container'}>
                                        {index + 1}
                                        <div
                                          className="g-color"
                                          style={{
                                            backgroundColor: item.color + '66',
                                            borderColor: item.color,
                                            borderWidth: 2,
                                          }}
                                        />
                                      </td>
                                      <td className={'text-left'}>{item.name}</td>
                                      <Money component="td" className={'text-right'}>
                                        {item.totalSold}
                                      </Money>
                                    </tr>
                                  )
                                })
                            ) : (
                              <tr className={'g-row'}>
                                <td colSpan="3">Sin datos</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </CollapsibleCard>
                </Col>
              </Row>
            </Col>
          )}
        </Row>

        <Modal
          show={showList}
          size={'md'}
          onHide={() => this.setState({ showList: false, subscription: null })}
          centered>
          <Modal.Header closeButton>
            <Modal.Title>Suscripciones activas</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table
              hideFilter
              items={subscriptions}
              loading={loading}
              renderRow={(item, index) => (
                <div>
                  <div className={`b-user-item`} key={`${index}`}>
                    <div
                      className={'justify-content-start align-items-start bu-en'}
                      style={{ textAlign: 'left' }}>
                      <div className={`b-user-name`}>{item.alias}</div>
                      <div
                        className={`b-user-email`}>{`Siguiente fecha: ${formatDateFromMillis(
                        item.nextDate,
                      )}`}</div>
                    </div>
                    <div
                      className={'d-flex row align-items-center mr-2 bu-b'}
                      style={{ textAlign: 'right' }}>
                      <div className="d-flex">
                        <Icon
                          onClick={() =>
                            this.setState({
                              subscription: item,
                              subscriptionModal: { show: true },
                            })
                          }
                          icon={faEye}
                          size={'1_5x'}
                          tooltip={'Ver suscripción'}
                        />
                        <Icon
                          onClick={() => {
                            this.setState({ id: item.id })
                            this.props.deleteReportSubscription(item.id)
                          }}
                          spin={this.state.id === item.id && loadingAction}
                          color={'rgb(195,75,222)'}
                          icon={faTrash}
                          size={'1_5x'}
                          tooltip={'Eliminar'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              color={'primary'}
              icon={faCheckCircle}
              onClick={() => {
                this.setState({ subscriptionModal: { show: true } })
              }}>
              Crear suscripción
            </Button>
          </Modal.Footer>
        </Modal>

        <CreateSubscription
          show={subscriptionModal.show}
          modal
          onHide={() => this.setState({ subscriptionModal: { show: false } })}
          type={1}
          subscriptionType={2}
          onCreate={data => {
            data.ownerType = 3
          }}
          data={subscription}
        />

        <Alert {...alert} />

        <Folder
          noMessage
          list={selectedCategorization.map(d => d.id)}
          onHide={() => this.setState({ showCategorizations: false })}
          onAssign={item => {
            if (selectedCategorization.findIndex(p => p.id === item.id) === -1) {
              selectedCategorization.push(item)
              this.setState({ selectedCategorization }, () => {
                this.getReport({
                  users,
                  products,
                  warehouses,
                  startDate,
                  endDate,
                  type: selectedType,
                })
              })
            }
          }}
          data1={
            categorization && categorization.children ? categorization.children[0] : {}
          }
          data2={
            categorization && categorization.children ? categorization.children[1] : {}
          }
          show={showCategorizations}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  queries: selectQueriesReport(state),
  user: selectCurrentUser(state),
  reportIsLoading: loadingSelector([OrderActions.GET_PRODUCT_REPORT])(state),
  allProducts: selectAllProductsWithAllForSelect(state),
  module: selectCurrentModule(state),
  products: selectProductReport(state),
  report: selectProductReport(state),
  users: selectUsers(state),
  allWarehouses: selectAllWarehousesForSelect(state),
  categorization: selectAllCategorizations(state),
  categorizationLoading: loadingSelector([action.GET_ALL])(state),
  subscriptions: selectSubscribesReport(state),
  loadingAction: loadingSelector([actionTypes.DELETE_REPORT_SUBSCRIPTION])(state),
  loadingCreate: loadingSelector([actionTypes.CREATE_SUBSCRIPTION])(state),
  loading: loadingSelector([actionTypes.GET_REPORT_SUBSCRIPTION])(state),
  handlerSuccess: message => handlerSuccess(message),
  allPOS: selectAllPOSUser(state),
  loadingPos: loadingSelector([posTypes.GET_ALL_POS_USER])(state),
})

const mapDispatchToProps = dispatch => ({
  getUsersChildrenByModule: (module, includeParent) =>
    dispatch(getUsersChildrenByModule(module, includeParent)),
  getProducts: () => dispatch(getProducts()),
  getUserReport: (type, start, end, users, products, warehouses, categories, pos) =>
    dispatch(
      getUserReport(type, start, end, users, products, warehouses, categories, pos),
    ),
  getWarehouses: () => dispatch(getWarehouses(false, false)),
  getSalePdf: (start, end, type, data) =>
    dispatch(getSalesPdfReport(start, end, type, data)),
  getCategories: () => dispatch(getAllCategorizations(16)),
  getReportSubscription: () => dispatch(getReportSubscription()),
  deleteReportSubscription: id => dispatch(deleteReportSubscription(id)),
  getPos: userId => dispatch(getAllPOSByUser(userId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SaleReport)
