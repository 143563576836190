import { actionTypes } from '../actions/polygons.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  polygon: {},
  updatedPolygon: {},
  polygons: [],
  categories: [],
  subscriptionsPolygons: [],
  settingResponse: '',
}

const PolygonReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.ON_UPDATE_SETTING):
      return { ...state, settingResponse: extract(action.settingResponse, '') }
    case successState(actionTypes.POLYGON_CATEGORIES):
      return {
        ...state,
        categories: extract(action.categories, []),
      }
    case successState(actionTypes.GET_ALL_POLYGONS):
      return {
        ...state,
        polygons: extract(action.polygons, []),
      }
    case successState(actionTypes.GET_SINGLE_POLYGON):
      return {
        ...state,
        polygon: extract(action.polygon, []),
      }
    case successState(actionTypes.CREATE_POLYGON):
      return {
        ...state,
        polygon: extract(action.polygon, []),
      }
    case successState(actionTypes.UPDATE_POLYGON):
      return {
        ...state,
        updatedPolygon: extract(action.polygon, []),
      }
    case successState(actionTypes.GET_SUBSCRIPTIONS_POLYGONS):
      return {
        ...state,
        subscriptionsPolygons: extract(action.subscriptionsPolygons, []),
      }
    default:
      return state ? state : initialState
  }
}

export default PolygonReducer
