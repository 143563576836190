import React, { ReactElement, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Icon, TableV2, Paragraph, CustomReference } from 'src/components'
import { faBook, faEye } from '@fortawesome/free-solid-svg-icons'

import { toMoney } from 'src/utils/utilities'
import { onSetModalOrder, onSetModalPurchase } from 'src/actions/utilities.actions'

import { patrimonialAccounts, getPatrimonialDifference } from './PatrimonialFunctions'

import PatrimonialResume from './PatrimonialResume'

interface Props {
  items: IPatrimonialLog[]
}

/**
 * `PatrimonialReportValues` is a React component that displays a table of patrimonial report data.
 * It shows the adjustments made to each account.
 *
 * @param {Props} props - The properties that define the component's behavior and rendering.
 * @param {IPatrimonialLog[]} props.items - An array of patrimonial log items to be displayed in the table.
 *
 * @returns {ReactElement} The rendered component.
 */
const PatrimonialReportValues = ({ items }: Props): ReactElement => {
  const dispatch = useDispatch()

  const [modalReference, setModalReference] = useState({
    show: false,
    documentId: 0,
    documentType: 0,
    documentModule: 0,
  })
  const [resume, setResume] = useState({
    show: false,
    patrimonialLog: null,
  })

  const customData = (item: IPatrimonialLog, param: string, prevParam?: string) => {
    const { value, difference, color, icon } = getPatrimonialDifference(
      item,
      param,
      prevParam,
    )

    return (
      <div className={'column'}>
        <div className={'d-flex'}>
          <div>{toMoney(value)}</div>
          {difference !== 0 && (
            <Icon
              icon={icon}
              style={{ marginLeft: 10 }}
              iconStyle={{ fontSize: '1.05rem' }}
              color={color}
              tooltip={toMoney(difference)}
            />
          )}
        </div>
        {difference !== 0 && (
          <Paragraph size={'small'}>({toMoney(difference)})</Paragraph>
        )}
      </div>
    )
  }

  const getHeaders = (): ITableHeader[] => {
    const headers: ITableHeader[] = [
      { show: true, label: 'Fecha', type: 'date', value: ['date'] },
      { show: true, label: 'Resumen', type: 'text', value: ['resume'] },
    ]

    patrimonialAccounts.forEach(account => {
      headers.push({
        show: true,
        label: account.name,
        type: 'number',
        value: [account.param],
        custom: i => customData(i, account.param, account.prevParam),
      })
    })

    headers.push({
      show: true,
      config: true,
      label: '',
      className: 'mini center',
      type: '',
      value: [''],
      custom: (item: IPatrimonialLog) => {
        return (
          <div className={'column'}>
            <Icon
              icon={faEye}
              tooltip={'Ver detalle'}
              onClick={() => {
                let show = true
                let documentType = 0
                let documentModule = 0
                switch (item.fieldId) {
                  case 1: //Merma /produccion
                    documentModule = 5
                    documentType = 2
                    break
                  case 4: // depositos
                    documentModule = 1
                    documentType = 6
                    break
                  case 6:
                  case 2:
                    show = false
                    dispatch(onSetModalOrder(item.parentId))
                    break
                  case 8:
                    show = false
                    dispatch(onSetModalPurchase(item.parentId))
                    break
                  default:
                    show = false
                    break
                }
                if (show)
                  setModalReference({
                    show: true,
                    documentId: item.parentId,
                    documentType,
                    documentModule,
                  })
              }}
              size={'1x'}
            />
            <Icon
              icon={faBook}
              tooltip={'Resumen de cambios'}
              onClick={() => setResume({ show: true, patrimonialLog: item })}
              size={'1x'}
            />
          </div>
        )
      },
    })

    return headers
  }

  return (
    <div>
      <TableV2
        // @ts-ignore
        items={items}
        placeholder={'Resumen, valores sin coma'}
        mobileAuto
        storageKey={`patrimonialReport`}
        headers={getHeaders()}
      />

      <CustomReference
        {...modalReference}
        onHide={() => setModalReference({ ...modalReference, show: false })}
      />

      <PatrimonialResume
        show={resume.show}
        log={resume.patrimonialLog}
        onHide={() => setResume({ ...resume, show: false })}
      />
    </div>
  )
}
export default PatrimonialReportValues
