import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal, Col, Row } from 'react-bootstrap'
import { Paragraph, Select, Button } from 'src/components'

import { showAlert } from 'src/actions/alert.actions'

import { actionTypes, getWarehouseLite } from 'src/actions/warehouse.actions'
import { selectWarehouseLite } from 'src/selectors/warehouse.selector'

import { actionTypes as typeP, productCreateTransfer } from 'src/actions/products.actions'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'

interface Props {
  show: boolean
  items: number[]
  onHide: () => void
  params: IItemFiltersParams
}
/**
 * Componente para realizar una transferencia masiva de los ítems seleccionados y de las bodegas con existencias.
 * @component
 * @param {boolean} show Indica si el componente debe ser renderizado o no
 * @param {number[]} items Listado de los ítems a realizar la transferencia, si va vacío entonces se realizará por todos los ítems
 * @param {IItemFiltersParams} params Filtros a considerar para la transferencia de todos los ítems
 * @param {function} onHide CallBack para cerrar el componente desde la app principal
 * */
const TransferInventoryByItems = ({ show, items, params, onHide }: Props) => {
  const dispatch = useDispatch()

  const warehouses = useSelector(selectWarehouseLite)

  const loadingGetWarehouses = useSelector(state =>
    loadingSelector([actionTypes.GET_WAREHOUSE_LITE])(state),
  )

  const loadingCreateTransfer = useSelector(state =>
    loadingSelector([typeP.CREATE_TRANSFER])(state),
  )
  const hasErrorInCreateTransfer = useSelector(state =>
    hasErrors([typeP.CREATE_TRANSFER])(state),
  )

  const [flags, setFlags] = useState({ create: false })
  const [open, setOpen] = useState(false)
  const [warehouse, setWarehouse] = useState({ value: null, label: 'Sin seleccionar' })

  useEffect(() => {
    if (!show) return
    setOpen(true)
    dispatch(getWarehouseLite())
  }, [show])

  useEffect(() => {
    if (loadingCreateTransfer) setFlags({ ...flags, create: true })
    else if (flags.create) {
      setFlags({ ...flags, create: false })

      const alert = hasErrorInCreateTransfer
        ? { ...handlerError(hasErrorInCreateTransfer.message) }
        : { ...handlerSuccess(), onConfirm: () => onClose() }
      dispatch(showAlert(alert))
    }
  }, [loadingCreateTransfer])

  const onClose = () => {
    setOpen(false)
    onHide()
    setWarehouse({ value: null, label: 'Sin seleccionar' })
  }

  return (
    <Modal show={open} centered size={'lg'} onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Transferencia de Inventario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xl={12}>
            <Paragraph>
              Se creara una solicitud de transferencia con la existencia total de todos
              los items seleccionados hacia una bodega
            </Paragraph>
          </Col>
          <Col xl={12}>
            <Select
              label={'Bodega Receptora'}
              disabled={loadingCreateTransfer}
              loading={loadingGetWarehouses}
              required
              value={warehouse}
              options={warehouses}
              onChange={w => setWarehouse(w)}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className={'container-buttons'}>
          <Button
            disabled={!warehouse.value}
            loading={loadingCreateTransfer}
            onClick={() =>
              dispatch(
                productCreateTransfer({
                  products: items,
                  warehouseId: warehouse.value,
                  all: params.all,
                }),
              )
            }>
            Crear Transferencia
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}
export default TransferInventoryByItems
