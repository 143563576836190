import React, { Component } from 'react'

import CollapsibleCard from './collapsible-card/CollapsibleCard'

import { Row, Col } from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Checkbox from '../inputs/Checkbox/Checkbox'

export default class FilterCard extends Component {
  componentDidMount() {
    const { module, submodule } = this.props
    let states = this.state
    let state = window.localStorage.getItem(`${module}-${submodule}`)
    if (state) {
      state = JSON.parse(state)
      if (Object.keys(state).length !== Object.keys(states).length) state = states
      this.setState({ ...state })
    } else this.props.customFilter(this.state)
  }

  state = {
    entered: true,
    confirmed: true,
    delivered: true,
    unfulfilled: true,
    pending: true,
    processed: true,
    onWay: true,
    requestSend: true,
    requestAccepted: true,
    requestRejected: true,
    canceled: true,
    reserve: true,
    nullify: true,
  }

  handleCheckBoxChange = event => {
    const { name, checked } = event.target
    this.setState({ [name]: checked })
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    const { module, submodule } = this.props
    window.localStorage.setItem(`${module}-${submodule}`, JSON.stringify(nextState))
    this.props.customFilter(nextState)
  }

  render() {
    const { title, module, submodule } = this.props

    const {
      entered,
      confirmed,
      delivered,
      unfulfilled,
      pending,
      processed,
      onWay,
      canceled,
    } = this.state
    const { requestSend, requestAccepted, requestRejected, reserve, nullify } = this.state

    return (
      <CollapsibleCard collapsed={true} title={title}>
        <div className={'column'}>
          {(submodule === 'orders' ||
            submodule === 'purchase' ||
            submodule === 'expense') && (
            <div>
              <h6>Por estado</h6>
              <Row>
                {(submodule === 'orders' ||
                  submodule === 'purchase' ||
                  submodule === 'expense') && (
                  <Col xl={4} lg={4} md={6} sm={6} xs={6}>
                    <Checkbox
                      name={'entered'}
                      checked={entered}
                      onChange={this.handleCheckBoxChange}
                      label={' Ingresadas'}
                      info={
                        submodule === 'orders'
                          ? 'Visualiza las ordenes sin confirmar/incumplidas'
                          : submodule === 'purchase'
                          ? 'Visualiza las compras sin aprobar/rechazar'
                          : 'Visualiza los gastos sin aprobar/rechazar'
                      }
                    />
                  </Col>
                )}
                {submodule === 'orders' && (
                  <Col xl={4} lg={4} md={6} sm={6} xs={6}>
                    <Checkbox
                      name={'confirmed'}
                      checked={confirmed}
                      onChange={this.handleCheckBoxChange}
                      label={' Confirmadas'}
                      info={'Visualiza las ordenes confirmadas'}
                    />
                  </Col>
                )}
                {(submodule === 'orders' ||
                  submodule === 'purchase' ||
                  submodule === 'expense') && (
                  <Col xl={4} lg={4} md={6} sm={6} xs={6}>
                    <Checkbox
                      name={'delivered'}
                      checked={delivered}
                      onChange={this.handleCheckBoxChange}
                      label={' Completas'}
                      info={
                        submodule === 'orders'
                          ? 'Visualiza las ordenes completadas'
                          : 'Visualiza las compras completadas'
                      }
                    />
                  </Col>
                )}
                {submodule === 'orders' && (
                  <Col xl={4} lg={4} md={6} sm={6} xs={6}>
                    <Checkbox
                      name={'unfulfilled'}
                      checked={unfulfilled}
                      onChange={this.handleCheckBoxChange}
                      label={' Incumplidas'}
                      info={'Visualiza las ordenes incumplidas'}
                    />
                  </Col>
                )}
                {(submodule === 'orders' ||
                  submodule === 'purchase' ||
                  submodule === 'expense') && (
                  <Col xl={4} lg={4} md={6} sm={6} xs={6}>
                    <Checkbox
                      name={'pending'}
                      checked={pending}
                      onChange={this.handleCheckBoxChange}
                      label={' Pendientes'}
                      info={
                        submodule === 'orders'
                          ? 'Visualiza las ordenes pendientes'
                          : submodule === 'purchase'
                          ? 'Visualiza las compras pendientes'
                          : 'Visualiza los gastos pendientes'
                      }
                    />
                  </Col>
                )}
                {submodule === 'orders' && (
                  <Col xl={4} lg={4} md={6} sm={6} xs={6}>
                    <Checkbox
                      name={'processed'}
                      checked={processed}
                      onChange={this.handleCheckBoxChange}
                      label={' Procesada'}
                      info={'Visualiza las ordenes procesadas'}
                    />
                  </Col>
                )}
                {submodule === 'orders' && (
                  <Col xl={4} lg={4} md={6} sm={6} xs={6}>
                    <Checkbox
                      name={'onWay'}
                      checked={onWay}
                      onChange={this.handleCheckBoxChange}
                      label={' En camino'}
                      info={'Visualiza las ordenes en camino'}
                    />
                  </Col>
                )}
                {(submodule === 'purchase' || submodule === 'expense') && (
                  <Col xl={4} lg={4} md={6} sm={6} xs={6}>
                    <Checkbox
                      name={'canceled'}
                      checked={canceled}
                      onChange={this.handleCheckBoxChange}
                      label={' Cancelada'}
                      info={
                        submodule === 'orders'
                          ? 'Visualiza las ordenes canceladas'
                          : submodule === 'purchase'
                          ? 'Visualiza las compras cancenladas'
                          : 'Visualiza los gastos cancelados'
                      }
                    />
                  </Col>
                )}
                {submodule === 'purchase ' && (
                  <Col xl={4} lg={4} md={6} sm={6} xs={6}>
                    <label className={'checkbox-label'}>
                      <input
                        type={'checkbox'}
                        name={'reserve'}
                        checked={reserve}
                        onChange={this.handleCheckBoxChange}
                      />{' '}
                      En reserva
                    </label>
                  </Col>
                )}
                {submodule === 'purchase' && (
                  <Col xl={4} lg={4} md={6} sm={6} xs={6}>
                    <Checkbox
                      name={'nullify'}
                      checked={nullify}
                      onChange={this.handleCheckBoxChange}
                      label={' Anulada'}
                      info={'Visualiza las compras anuladas'}
                    />
                  </Col>
                )}
              </Row>
            </div>
          )}

          {submodule === 'request' && <div className={'column'}></div>}

          {submodule === 'requestInfo' && (
            <div>
              <h6>Por estado</h6>
              <Row>
                <Col xl={4} lg={4} md={6} sm={6} xs={6}>
                  <label className={'checkbox-label'}>
                    <input
                      type={'checkbox'}
                      name={'requestSend'}
                      checked={requestSend}
                      onChange={this.handleCheckBoxChange}
                    />{' '}
                    Solicitadas
                  </label>
                </Col>
                <Col xl={4} lg={4} md={6} sm={6} xs={6}>
                  <label className={'checkbox-label'}>
                    <input
                      type={'checkbox'}
                      name={'requestAccepted'}
                      checked={requestAccepted}
                      onChange={this.handleCheckBoxChange}
                    />{' '}
                    Aceptadas
                  </label>
                </Col>
                <Col xl={4} lg={4} md={6} sm={6} xs={6}>
                  <label className={'checkbox-label'}>
                    <input
                      type={'checkbox'}
                      name={'requestRejected'}
                      checked={requestRejected}
                      onChange={this.handleCheckBoxChange}
                    />{' '}
                    Rechazadas
                  </label>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </CollapsibleCard>
    )
  }
}
