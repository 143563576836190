import React, { useEffect, useState } from 'react'

import Alert from 'sweetalert-react'
import { Modal } from 'react-bootstrap'

const QRScanner = ({ show, onHide }) => {
  const [value, setValue] = useState(null)
  const [alert, setAlert] = useState({ title: '' })
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!show) return
    setValue(null)
    if (!('BarcodeDetector' in window)) {
      setAlert({
        show: true,
        title: 'Sin soporte',
        type: 'error',
        text: 'Su navegador no tiene soporte para el escaneo de códigos. Por favor pruebe con los navegadores web Chrome o Safari.',
        confirmButtonText: 'Cerrar',
        confirmButtonColor: '#B35796',
        onConfirm: () => onClose(),
      })
    } else setOpen(true)
  }, [show])

  useEffect(() => {
    if (!open) return
    const video = document.querySelector('#video')

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      // Use video without audio
      const constraints = {
        video: { facingMode: 'environment' },
        audio: false,
      }
      // Start video stream
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => (video.srcObject = stream))
    }
  }, [open])

  useEffect(() => {
    if (value === null || value === undefined || value === '') return
    const values = value.split('_kolo_')
    const format = values[0]
    const response = values[1]

    onClose({ format, response })
  }, [value])

  const onClose = response => {
    onHide(response)
    setAlert({ ...alert, show: false })
    setOpen(false)
    const video = document.querySelector('#video')
    if (video) {
      video.pause()
      video.src = ''
      if (video.srcObject && video.srcObject.getTracks)
        video.srcObject.getTracks().forEach(function (track) {
          track.stop()
        })
    }
  }

  const detectCode = () => {
    const { BarcodeDetector } = window
    const barcodeDetector = new BarcodeDetector({ formats: ['qr_code'] })
    const video = document.querySelector('#video')
    // Start detecting codes on to the video element
    barcodeDetector
      .detect(video)
      .then(codes => {
        // If no codes exit function
        if (codes.length === 0) return

        for (const barcode of codes) {
          const { format, rawValue } = barcode
          setValue(format + '_kolo_' + rawValue)
        }
      })
      .catch(err => {
        // Log an error if one happens
        console.error(err)
      })
  }

  if (open) {
    setInterval(detectCode, 100)
  }

  return (
    <div>
      <Modal show={open} size={'sm'} centered onHide={() => onClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Escaner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={'center'}>
            <video id={'video'} width={250} height={250} autoPlay />
          </div>
        </Modal.Body>
      </Modal>

      <Alert {...alert} />
    </div>
  )
}
export default QRScanner
