import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectGetAdminReport } from 'src/selectors/orders.selector'
import { actionTypes, getAdminReport } from 'src/actions/orders.actions'
import { Card, Select, SelectedDates, Title } from 'src/components'
import { Row, Col, ProgressBar, Table } from 'react-bootstrap'
import { getTypesAvailable } from 'src/actions/offers.actions'
import { selectOfferLinkTypeAvailable } from 'src/selectors/offers.selector'
import { getAllCompanies } from 'src/actions/company.actions'
import { selectAllCompanies } from 'src/selectors/company.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { formatNumberWithCommas } from 'src/utils/formatters'
import { graphs as colorArray } from 'src/utils/graphs'
import { Bar } from 'react-chartjs-2'
import { toMoney } from 'src/utils/utilities'

const chartOptions = {
  scales: {
    xAxes: [{ stacked: true, gridLines: { display: false } }],
    yAxes: [
      {
        stacked: true,
        type: 'linear',
        ticks: {
          beginAtZero: true,
          callback: function (label, index, labels) {
            return label > 999999
              ? label / 100000000 + 'M'
              : label > 999
              ? label / 1000 + 'k'
              : label
          },
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || ''
        if (label) {
          label += ': '
        }
        label += toMoney(tooltipItem.yLabel)
        return label
      },
    },
  },
  plugins: {
    labels: {
      render: function (args) {
        return args.percentage <= 0 ? '' : args.percentage + '%'
      },
      fontSize: 12,
      fontStyle: 'bold',
      fontColor: '#282c34',
      fontFamily: 'Montserrat',
    },
  },
  legend: { display: false },
}

const chartOptionsMobile = {
  scales: {
    xAxes: [{ stacked: true, gridLines: { display: false } }],
    yAxes: [
      {
        stacked: true,
        type: 'linear',
        ticks: {
          beginAtZero: true,
          callback: function (label, index, labels) {
            return label > 999999
              ? label / 100000000 + 'M'
              : label > 999
              ? label / 1000 + 'k'
              : label
          },
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || ''
        if (label) {
          label += ': '
        }
        label += toMoney(tooltipItem.yLabel)
        return label
      },
    },
  },
  plugins: {
    labels: {
      render: function (args) {
        return ''
      },
      display: false,
      fontSize: 12,
      fontStyle: 'bold',
      fontColor: '#282c34',
      fontFamily: 'Montserrat',
    },
  },
  legend: { display: false },
}

const DelivererReport = () => {
  const dispatch = useDispatch()

  const companies = useSelector(selectAllCompanies)
  const report = useSelector(selectGetAdminReport)
  const reportLoading = useSelector(state =>
    loadingSelector([actionTypes.GET_ADMIN_REPORT])(state),
  )
  const links = useSelector(selectOfferLinkTypeAvailable)

  const [filters, setFilters] = useState({
    start: new Date(),
    end: new Date(),
    producer: { value: null, label: 'Sin seleccionar' },
    transportist: { value: null, label: 'Sin seleccionar' },
    linkType: { value: null, label: 'Sin seleccionar' },
    linked: { value: 1, label: 'Ventas Propias' },
  })
  const [selectedCompanies, setSelectedCompanies] = useState([])

  useEffect(() => {
    dispatch(getTypesAvailable(0, 11000))
    dispatch(getAllCompanies())
  }, [])

  useEffect(() => {
    setUp()
  }, [filters])

  const setUp = () => {
    const { start, end, linked, linkType, producer, transportist } = filters
    dispatch(
      getAdminReport({
        start: start.valueOf(),
        end: end.valueOf(),
        linked: linked.value === 2,
        company: producer.value,
        transportistId: transportist.value,
        linkType: linkType.value,
      }),
    )
  }

  const getCompanyName = id => {
    let c = companies.filter(c => c.id === id)
    if (c.length > 0) return c[0].name
    return 'Sin nombre'
  }

  const getData = () => {
    let chartCompanies = [],
      chartDates = []
    let lastDate = null,
      count = 0
    report.map(r => {
      const label = r.label.split('-')
      const company = label[0]
      const date = label[1] + '/' + label[2] + '/' + label[3]
      if (!lastDate) lastDate = date
      else if (lastDate !== date) {
        //si la fecha cambia lleno a todas las compañías con un dato nuevo 0
        chartCompanies.map(c => c.quantities.push(0))
        //llevo el contador de las cantidades totales en todas las compañías
        count = chartCompanies[0].quantities.length
        lastDate = date
      }
      let index = chartCompanies.findIndex(c => c.id === Number(company))
      if (index === -1) {
        //cada compañía nueva le lleno los espacios de 0 para que todas tengan la misma cantidad
        let quantities = []
        for (let i = 1; i < count; i++) quantities.push(0)
        chartCompanies.push({
          id: Number(company),
          name: getCompanyName(Number(company)),
          color: colorArray[chartCompanies.length],
          see: selectedCompanies.filter(s => s.id === Number(company)).length === 0,
          quantities: [...quantities, r.quantity],
        })
      }
      //si la compañía ya está, entonces sustituyo el último valor agregado con el nuevo
      else
        chartCompanies[index].quantities[chartCompanies[index].quantities.length - 1] =
          r.quantity

      //agrego los labels de fechas si no existen
      index = chartDates.findIndex(d => d === date)
      if (index === -1) chartDates.push(date)
    })
    return { chartCompanies, chartDates }
  }

  const getChart = () => {
    const data = getData()
    return {
      labels: data.chartDates,
      datasets: data.chartCompanies
        .filter(d => d.see)
        .map((d, i) => {
          return {
            data: d.quantities,
            label: d.name,
            backgroundColor: d.color + '66',
            borderColor: d.color,
            borderWidth: 2,
          }
        }),
    }
  }

  const getQuantity = company => {
    let result = 0
    company.quantities.map(p => {
      result += p
    })
    return result
  }

  const rowClicked = data => {
    const index = selectedCompanies.findIndex(p => p.id === data.id)
    if (index === -1) selectedCompanies.push(data)
    else selectedCompanies.splice(index, 1)
    setSelectedCompanies([...selectedCompanies])
  }

  const renderCharts = () => {
    return (
      <Row style={{ backgroundColor: 'white', marginTop: 16 }}>
        <Col lg={8}>
          <>
            <div className="ru-graph-w" style={{ marginTop: 16 }}>
              <Bar data={getChart()} height={200} options={chartOptions} />
            </div>
            <div className="ru-graph-m" style={{ marginTop: 16 }}>
              <Bar data={getChart()} height={400} options={chartOptionsMobile} />
            </div>
          </>
        </Col>
        <Col lg={4}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th style={{ width: 70 }} />
                <th>Nombre</th>
                <th>Total de Entregas</th>
              </tr>
            </thead>
            <tbody>
              {report ? (
                getData().chartCompanies.map((item, index) => {
                  return (
                    <tr className={'g-row'} onClick={() => rowClicked(item)}>
                      <td className={'index-container'}>
                        {index + 1}
                        <div
                          className="g-color"
                          style={{
                            backgroundColor: item.see
                              ? colorArray[index] + '66'
                              : 'white',
                            borderColor: item.see ? colorArray[index] : 'white',
                            borderWidth: 2,
                          }}
                        />
                      </td>
                      <td className={'text-left'}>{item.name}</td>
                      <td className={'text-right'}>
                        {formatNumberWithCommas(getQuantity(item))}
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr className={'g-row'}>
                  <td colSpan="3">Sin datos</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    )
  }

  return (
    <div>
      <Title title={'Reporte de Entregas'} />
      <Card>
        <SelectedDates
          onDateChange={(start, end) => setFilters({ ...filters, start, end })}
        />
        <Row>
          <Col>
            <Select
              label={'Filtrar por empresa productora'}
              info={'Filtra por la empresa que produce el producto'}
              value={filters.producer}
              options={[{ value: null, label: '- sin seleccionar -' }, ...companies]}
              onChange={data => setFilters({ ...filters, producer: data })}
            />
          </Col>
          <Col>
            <Select
              label={'Filtrar por empresa transportista'}
              info={'Filtra por la empresa que transporta el producto'}
              value={filters.transportist}
              options={[{ value: null, label: '- sin seleccionar -' }, ...companies]}
              onChange={data => setFilters({ ...filters, transportist: data })}
            />
          </Col>
          <Col>
            <Select
              label={'Filtrar por tipo de venta'}
              info={'Filtra por la empresa que produce el producto'}
              value={filters.linked}
              options={[
                { value: 1, label: 'Ordenes Propias' },
                { value: 2, label: 'Órdenes enlazadas' },
              ]}
              onChange={data => setFilters({ ...filters, linked: data })}
            />
          </Col>
          <Col>
            <Select
              label={'Filtrar por tipo de enlace'}
              info={'Filtra por los tipos de enlaces existentes'}
              value={filters.linkType}
              options={[{ value: null, label: '- sin seleccionar -' }, ...links]}
              onChange={data => setFilters({ ...filters, linkType: data })}
            />
          </Col>
        </Row>
        {reportLoading && (
          <Row>
            <Col>
              <div className={'pb-custom'}>
                <ProgressBar
                  label="Cargando"
                  animated
                  now={100}
                  style={{ marginBottom: 10 }}
                />
              </div>
            </Col>
          </Row>
        )}
        <br />
        <br />
        {renderCharts()}
      </Card>
    </div>
  )
}

export default DelivererReport
