import React, {Component} from 'react';
import {connect} from "react-redux";
import "./TransportPayment.scss";

import {Col, Row, Modal} from "react-bootstrap";
import {Td, Tr} from "react-super-responsive-table";
import {
    faLongArrowAltLeft,
    faLongArrowAltRight,
    faPlusCircle
} from "@fortawesome/free-solid-svg-icons";

import Card from "../../../../components/cards/Card";
import Icon from "../../../../components/buttons/IconButton";
import Button from "../../../../components/buttons/Button";
import FormTextField from "../../../../components/inputs/FormTextField/FormTextField";

import {onSetTransportPayment} from "../../../../actions/products.actions";
import {selectTransportPayment} from "../../../../selectors/products.selector";
import {formatNumberWithCommas} from "../../../../utils/formatters";

import ArrowLeftRight from "../../../../assets/images/newDesign/arrow-left-right.svg";
import IconCart from "../../../../assets/images/newDesign/icon-cart.svg";
import {history} from "../../../../App";
import IconPlus from "../../../../assets/images/newDesign/icon-plus.svg";
import Money from '../../../../components/Money/Money';
import { selectCurrentCurrency } from '../../../../selectors/currencies.selector';
import { TableV2 } from '../../../../components';


class TransportPaymentPayments extends Component {

    state = {
        modal: {value: false, minimum: 0, maximum: 1, amount: 0, amounts: []},
        errors: {}
    };

    setModal = () => {
        const {transportPayment} = this.props;
        const {amounts} = transportPayment;
        let minimum = 0;
        let maximum = 1;
        amounts.map((a) => {
            minimum = a.minimum++;
            maximum = minimum++;
        });
        const modal = {value: true, minimum, maximum, amounts, amount: 0};

        this.setState({modal});
    };

    handleInputChange = (event) => {
        const {name, value} = event.target;
        let {modal} = this.state;
        modal[name] = value;
        if (modal.maximum < modal.minimum) modal.maximum = modal.minimum;
        this.setState({modal});
    };

    getPercentage = (price, productPrice) => {
        return parseInt((price * 100) / productPrice);
    };

    addProductToProductInfo = () => {
        let {modal} = this.state;
        let {minimum, maximum, amount} = modal;
        let errors = {};

        if (minimum < 0) errors.minimum = 'Es requerido';
        if (!maximum || maximum <= 0) errors.maximum = 'Es requerido';
        if (!amount || amount <= 0) errors.amount = 'Es requerido';

        if (Object.keys(errors).length === 0) {
            minimum = parseFloat(minimum);
            maximum = parseFloat(maximum);
            amount = parseFloat(amount);
            modal.amounts.push({minimum, amount, maximum});
            this.setState({modal: {...modal, minimum: maximum + 1, maximum: maximum + 2, amount: 0}});
        }

        this.setState({errors});
    };

    addAmounts = () => {
        const {transportPayment, setInfo} = this.props;
        let {modal} = this.state;
        const {amounts} = modal;

        setInfo({...transportPayment, amounts});

        this.setState({modal: {value: false, minimum: 0, maximum: 1, amount: 0, amounts: []}});
    };

    render() {
        const {transportPayment, currentCurrency} = this.props;
        const {product} = transportPayment;
        const {modal, errors} = this.state;
        const {value, amounts, minimum, maximum, amount} = modal;

        return (
            <div className={'column'}>
                <Card
                    title={'Pagos de transporte'}
                    button={<Icon onClick={() => this.setModal()} tooltip={'Agregar'} icon={faPlusCircle}/>}>
                    <TableV2
                        items={transportPayment.amounts}
                        mobileAuto
                        storageKey={`transportPayment`}
                        headers={[

                            { label: 'Minima', show: true, value: ['minimum'], type: 'measure', className: 'mini' },
                            { label: 'Maxima', show: true, value: ['maximum'], type: 'measure', className: 'mini' },
                            { label: 'Pago', show: true, value: ['amount'], type: 'currency', className: 'mini' },
                            { config: true, show: true, label: '', className: 'mini center' }
                        ]}

                    />
                </Card>

                <Modal
                    show={value}
                    // size={'lg'}
                    centered
                    onHide={() => this.setState({modal: {amount: 0, value: false, minimum: 0, maximum: 1, amounts: []}})}>

                    <Modal.Header closeButton>
                        <Modal.Title className={"tp-title-modal"}>{product.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mx-3">
                            <span className="tp-1">Precio unitario:</span>
                            <Money component='span' className={"tp-description"}>{product.price}</Money>
                        </Row>
                        <Row className="mx-3">
                            <span className="tp-1">Descripción:</span>
                            <span className={"tp-description"}>{product.description || 'Sin descripción'}</span>
                        </Row>
                        <div className="tp-divider"/>
                        <Row className="mt-4 mb-3">
                            <Col className="tp-title center">
                                Unidades
                            </Col>
                            <Col className="tp-title center">
                                Pago de transporte
                            </Col>
                        </Row>
                        {
                            amounts.map((a, i) =>
                              <Row>
                                  <Col>
                                      <Row>
                                          <Col>
                                              <div>
                                                  <span className="tp-label-2">De {a.minimum} a {a.maximum} unidades</span>
                                              </div>
                                          </Col>
                                      </Row>
                                  </Col>
                                  <Col>
                                      <Row>
                                          <Col>
                                              <div>
                                                  <Money component='span' className="tp-label-2 w-100 text-right">{a.amount} - {this.getPercentage(a.amount, product.price)}% del PU</Money>
                                              </div>
                                          </Col>
                                      </Row>
                                  </Col>
                              </Row>
                            )
                        }
                        <div className="tp-divider"/>
                        <Row>
                            <Col xs={6}>
                                <Row>
                                    <Col xs={5} className="p-0 pl-2">
                                        <div>
                                            <span className="tp-label">Desde:</span>
                                            <FormTextField
                                              disabled
                                              mt={0}
                                              type={'number'}
                                              name={'minimum'}
                                              onChange={this.handleInputChange}
                                              value={minimum}
                                              error={errors.minimum}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={2} className="d-flex p-0 center">
                                        <img className='icon-arrows' src={ArrowLeftRight}/>
                                    </Col>
                                    <Col xs={5} className="p-0 pr-2">
                                       <div>
                                           <span className="tp-label">Hasta:</span>
                                           <FormTextField
                                             mt={0}
                                             type={'number'}
                                             name={'maximum'}
                                             onChange={this.handleInputChange}
                                             value={maximum}
                                             error={errors.maximum}
                                           />
                                       </div>
                                   </Col>
                                </Row>
                            </Col>
                            <Col xs={6}>
                                <Row>
                                    <Col>
                                        <div>
                                            <span className="tp-label w-100 text-right">{this.getPercentage(amount, product.price)}% del PU</span>
                                            <FormTextField
                                              mt={0}
                                              id={'amount'}
                                              type={'number'}
                                              name={'amount'}
                                              onChange={this.handleInputChange}
                                              value={amount}
                                              error={errors.amount}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={'auto'} className="d-flex p-0">
                                        <div className="tp-button-add"
                                             onClick={() => this.addProductToProductInfo()}>
                                            <img src={IconPlus} alt={"+"}/>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            {/*<div className={'center-offer'}>*/}
                            {/*    <Icon*/}
                            {/*      tooltip={'Agregar nuevo rango'}*/}
                            {/*      icon={faPlusCircle}*/}
                            {/*      onClick={() => this.addProductToProductInfo()}*/}
                            {/*    />*/}
                            {/*</div>*/}

                            {/*<div className="tp-button-add"*/}
                            {/*     onClick={() => this.addProductToProductInfo()}>*/}
                            {/*    <img src={IconPlus} alt={"+"}/>*/}
                            {/*</div>*/}

                        </Row>


                    </Modal.Body>
                    <Modal.Footer>
                        <Row className={'container-buttons'}>
                            <Button
                                disabled={amounts.length <= 0}
                                onClick={() => this.addAmounts()}>
                                Agregar
                            </Button>
                        </Row>
                    </Modal.Footer>
                </Modal>

                <Row className={'container-buttons'}>
                    <Button
                        onClick={() => this.props.jumpToStep(0)} icon={faLongArrowAltLeft}>
                        Atras
                    </Button>
                    <Button
                        disabled={transportPayment.amounts.length <= 0}
                        onClick={() => this.props.jumpToStep(2)} icon={faLongArrowAltRight}>
                        Siguiente
                    </Button>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    transportPayment: selectTransportPayment(state),
    currentCurrency: selectCurrentCurrency(state)
});

const mapDispatchToProps = dispatch => ({
    setInfo: (info) => dispatch(onSetTransportPayment(info))
});

export default connect(mapStateToProps, mapDispatchToProps)(TransportPaymentPayments);
