// @ts-nocheck
import React, { useState, useEffect, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Modal } from 'react-bootstrap'
import {
  Paragraph,
  Icon,
  SwitchV2,
  Card,
  Title,
  FormText,
  Select,
  FABV2,
  Button,
} from 'src/components'
import ImageInput, { imageTypes } from 'src/components/inputs/ImageInput/ImageInput'

import {
  faBoxOpen,
  faBusinessTime,
  faFile,
  faFileInvoice,
  faInfoCircle,
  faMapPin,
  faMoneyBill,
  faPortrait,
  faRulerCombined,
  faSave,
  faWarehouse,
  faWindowClose,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons'
import { faGoogle, faSellsy } from '@fortawesome/free-brands-svg-icons'

import {
  actionTypes,
  getCompanyFields,
  setCompanyFields,
} from 'src/actions/company.actions'
import {
  selectSetCompanyFieldsValues,
  selectSetCurrentCompany,
} from 'src/selectors/company.selector'

import {
  actionTypes as type,
  getCompanyConfigurationData,
  updateCompanyConfigurationData,
} from 'src/actions/mentor.actions'

import {
  actionTypes as currencyTypes,
  getAllCurrencies,
  getCountries,
} from 'src/actions/currencies.actions'
import { selectAllCurrencies, selectCountries } from 'src/selectors/currencies.selector'

import { showAlert } from 'src/actions/alert.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'

import companyFields, {
  companyFieldsInvoice,
  companyFieldsPurchase,
  companyFieldsQuote,
  companyFieldsSell,
} from 'src/enums/companyFields'

import { setModalManageConcepts } from 'src/actions/utilities.actions'

import { selectCompanyConfigurationData } from 'src/selectors/mentor.selector'
import { companyExistInGoogleOauth } from 'src/selectors/authorization.selector'

import GoogleOAuth from './GoogleOAuth'
import GoogleSheetSelector from 'src/content/Config/GoogleSheetSelector'
import QuoteConfig from './QuoteConfig'
import CompanyConfigLocation from './CompanyConfigLocation'
import FELCompanyConfig from 'src/content/Admin/Mentor/FELCompanyConfig'

interface IFlags {
  get: boolean
  init: boolean
  update: boolean
  getCurrencies: boolean
  getCountries: boolean
}

interface ItemProps {
  value: number
  title: string
  icon: IconDefinition
  fields?: FieldProps[]
}

interface FieldProps {
  key: number
  type: 1 | 2 | 3 | 4 | 5 | 6
  label: string
  description?: string
  prepend?: string
  inputType?: 'number' | 'text' | 'email' | 'tel'
  max?: number
  min?: number
  options?: ISelect[]
  customRender?: ReactNode
  mentor?: boolean
  show?: boolean
  onChange?: (value: ISelect) => void
}

const COUNTRY_FIELD = 134
const CompanyConfiguration = ({
  show = true,
  id,
  name,
  onHide,
  modal = false,
  mentorMode = false,
}: IMentorCompanyModalConfiguration) => {
  const dispatch = useDispatch()

  const company = useSelector(selectSetCurrentCompany)
  const companyFieldsValues = useSelector(selectSetCompanyFieldsValues)
  const companyConfiguration = useSelector(selectCompanyConfigurationData)
  const companyRegisteredGoogle = useSelector(companyExistInGoogleOauth)

  const loadingGetFields = useSelector(state =>
    loadingSelector([actionTypes.GET_FIELDS, type.GET_COMPANY_CONFIGURATION_DATA])(state),
  )

  const hasErrorGetFields = useSelector(state =>
    hasErrors([actionTypes.GET_FIELDS, type.GET_COMPANY_CONFIGURATION_DATA])(state),
  )

  const loadingUpdate = useSelector(state =>
    loadingSelector([actionTypes.SET_FIELDS, type.UPDATE_COMPANY_CONFIGURATION_DATA])(
      state,
    ),
  )

  const errorUpdate = useSelector(state =>
    hasErrors([actionTypes.SET_FIELDS, type.UPDATE_COMPANY_CONFIGURATION_DATA])(state),
  )

  const currencies: ISelect[] = useSelector(selectAllCurrencies)
  const loadingCurrencies = useSelector(state =>
    loadingSelector([currencyTypes.GET_CURRENCIES])(state),
  )

  const countries: ISelect[] = useSelector(selectCountries)
  const loadingCountries = useSelector(state =>
    loadingSelector([currencyTypes.GET_COUNTRIES])(state),
  )

  const [flags, setFlags] = useState<IFlags>({
    get: false,
    init: false,
    update: false,
    getCurrencies: false,
    getCountries: false,
  })

  const [companyFieldValues, setValues] = useState([])
  const [felCompany, setFelCompany] = useState({
    show: false,
    id: null,
    companyName: null,
  })

  const disabled = loadingUpdate || loadingCurrencies || loadingGetFields

  useEffect(() => {
    if (modal) return
    dispatch(getAllCurrencies())
  }, [modal])

  useEffect(() => {
    if (!(show && modal)) return
    dispatch(getAllCurrencies())
  }, [show])

  useEffect(() => {
    if (loadingCurrencies && show) setFlags({ ...flags, getCurrencies: true })
    else if (flags.getCurrencies) {
      setFlags({ ...flags, getCurrencies: false })
      dispatch(getCountries())
    }
  }, [loadingCurrencies])

  useEffect(() => {
    if (loadingCountries && show) setFlags({ ...flags, getCountries: true })
    else if (flags.getCountries) {
      setFlags({ ...flags, getCountries: false })
      if (mentorMode) dispatch(getCompanyConfigurationData(id))
      else dispatch(getCompanyFields())
    }
  }, [loadingCountries])

  useEffect(() => {
    if (loadingGetFields) setFlags({ ...flags, get: true })
    else if (flags.get) {
      setFlags({ ...flags, get: false, init: true })

      if (hasErrorGetFields)
        dispatch(
          showAlert({
            ...handlerError(hasErrorGetFields.message),
            onConfirm: () => {
              if (modal) onHide()
            },
          }),
        )
      else {
        if (modal) setValues(companyConfiguration)
        else setValues(companyFieldsValues)
      }
    }
  }, [loadingGetFields])

  useEffect(() => {
    if (loadingUpdate) setFlags({ ...flags, update: true })
    else if (flags.update) {
      setFlags({ ...flags, update: false })

      const customAlert = errorUpdate
        ? handlerError(errorUpdate.message)
        : handlerSuccess('Datos guardados exitosamente')

      dispatch(showAlert(customAlert))
    }
  }, [loadingUpdate])

  const find = (key: number): FieldCompany => {
    const index = companyFieldValues.findIndex(f => f.id === key)
    return { ...companyFieldValues[index], index }
  }

  const getInputTextValue = (key: number): string => {
    const fieldItem = find(key)
    if (fieldItem?.value === 'null' || fieldItem?.value === 'Null') return null
    return find(key)?.value
  }

  const getLocationValue = () => {
    const fieldItem = find(123)
    if (
      fieldItem.value !== null &&
      fieldItem.value !== undefined &&
      fieldItem.value !== '0'
    )
      return fieldItem.value.split(',')
    else return []
  }

  const getSwitchValue = (key: number): boolean => {
    const field = find(key)
    return field.value === 'true'
  }

  const getSelectValue = (key: number, options: ISelect[]): ISelect => {
    const fieldItem = find(key)
    const o = options.find(i => i.value.toString() === fieldItem.value)
    if (o) return o
    else return { value: null, label: 'Sin seleccionar' }
  }

  const getImageValue = (key: number): object => {
    return { url: find(key)?.value }
  }

  const onChangeLocation = (value: string[]) => {
    const fieldItem = find(123)
    if (value.length > 0) companyFieldValues[fieldItem.index].value = value.toString()
    else companyFieldValues[fieldItem.index].value = '0'
    setValues([...companyFieldValues])
  }

  const svCompany = find(134)?.value === '2'
  const useLocations = companyFieldValues[find(123)?.index]?.value !== '0'

  const getGoogleSheetComponent = (title: string, label: string, value: number) => {
    return (
      <GoogleSheetSelector
        title={title}
        label={label}
        sheetId={find(value)?.value}
        onChange={(sheetId: string) => {
          const fieldItem = find(value)
          companyFieldValues[fieldItem.index].value = sheetId
          setValues([...companyFieldValues])
        }}
      />
    )
  }

  const items: ItemProps[] = [
    {
      value: 0,
      title: 'Región y Moneda',
      icon: faMapPin,
      fields: [
        {
          key: COUNTRY_FIELD,
          type: 3,
          label: 'País',
          description: 'Región a operar',
          options: countries,
          onChange: data => {
            if (data.value === 2)
              [111, 109, 110].forEach(td => {
                companyFieldValues[find(td)?.index].value = 'false'
                setValues([...companyFieldValues])
              })
          },
        },
        {
          key: 108,
          type: 3,
          label: 'Moneda',
          description: 'Moneda a operar',
          options: currencies,
        },
      ],
    },
    {
      value: 1,
      title: 'Clientes',
      icon: faPortrait,
      fields: [
        {
          key: 96,
          type: 1,
          label:
            'Realizar ventas a crédito a todos los clientes sin importar el límite de crédito.',
          description:
            'Al activar la opción, todas las ventas a crédito ignoraran el límite de crédito del cliente.',
        },
        {
          key: 116,
          type: 1,
          label:
            'Asignar el correo electrónico del cliente a la confirmación de una venta.',
          description:
            'Al activar la opción, al confirmar una venta el correo electrónico del cliente será asignado automáticamente a la factura.',
        },
      ],
    },
    {
      value: 2,
      title: 'Ventas',
      icon: faSellsy,
      fields: [
        {
          key: 140,
          type: 1,
          label: 'Recargo con tarjeta automático',
          show: !svCompany,
        },
        {
          key: 86,
          type: 2,
          label: 'Porcentaje de recargo con tarjeta',
          show: !svCompany,
          prepend: '%',
          inputType: 'number',
          min: 0,
          max: 100,
        },
        {
          key: 84,
          type: 1,
          label: 'Desactivar los descuentos dentro de la empresa',
        },
        {
          key: 137,
          type: 1,
          label: 'Permitir editar ventas ya confirmadas.',
          mentor: true,
        },
        {
          key: companyFieldsSell.canChangeInventoryReturnOptionByCompany,
          type: 1,
          label: 'Mostrar la opción de retorno de inventario',
        },
        {
          key: companyFieldsSell.useDraftSale,
          type: 1,
          label: 'Habilitar borradores en ventas',
        },
        {
          key: companyFieldsSell.enabledZeroPrices,
          type: 1,
          label: 'Permitir facturar items con precio cero',
        },
        {
          key: 89,
          type: 2,
          label: 'Porcentaje máximo posible a descontar en cada venta',
          prepend: '%',
          inputType: 'number',
          min: 0,
          max: 100,
        },

        {
          key: 90,
          type: 6,
          customRender: (
            <Row className={'container-buttons mt-4'}>
              <Button
                style={{ flex: 1 }}
                color={'accent'}
                onClick={() => {
                  dispatch(setModalManageConcepts({ show: true }))
                }}>
                Configurar Conceptos de Facturación
              </Button>
            </Row>
          ),
        },
      ],
    },
    {
      value: 3,
      title: 'Facturas',
      icon: faFileInvoice,
      fields: [
        {
          key: 94,
          type: 3,
          label: 'Flujo de certificación',
          description:
            'Se debe de definir el flujo que deberá realizar una orden de venta respecto con la certificación electrónica (FEL)',
          options: [
            {
              value: '0',
              label: 'No emisión factura electrónica',
            },
            {
              value: '1',
              label: 'Siempre emitir factura electrónica',
            },
          ],
        },
        {
          key: 0,
          type: 6,
          label: 'Configuración FEL',
          customRender: (
            <Row
              className={'container-buttons'}
              style={{ paddingBottom: 2, display: 'flex' }}>
              <Button
                style={{ flex: 1 }}
                color={'accent'}
                onClick={() => {
                  setFelCompany({
                    show: true,
                    id: modal ? id : company.id,
                    companyName: modal ? name : company.name,
                  })
                }}>
                Configuración FEL
              </Button>
            </Row>
          ),
        },
        {
          key: 97,
          type: 4,
          label: '"Pie de pagina" en las facturas',
          description:
            'En la sección inferior de las facturas se mostrara la información que se agregue en este campo',
          inputType: 'text',
          max: 1200,
        },
        {
          key: 107,
          type: 1,
          label: 'Mostrar el Gran total de la factura en texto',
          description: 'Imprime el total de la factura en letras.',
        },
        {
          key: 117,
          type: 1,
          label: 'Activar FEL automáticamente',
          description:
            'Al activar esta opción se activará automáticamente la certificación de FEL durante confirmaciones de venta.',
        },
        {
          key: 132,
          type: 1,
          label: "Mostrar SKU's en la facturación",
          description:
            'Al activar esta opción el SKU de los productos se mostrara en la factura.',
        },
        {
          key: companyFields.useExportationInvoice,
          type: 1,
          label: 'Habilitar facturas de exportación',
          description:
            'Al activar esta opción al momento de crear ordenes de venta se podrá seleccionar el tipo de factura de exportación.',
        },
        {
          key: 129,
          type: 1,
          label: 'Imprimir logo en tickets.',
          description:
            'Al estar activa esta opción se imprimirá un logo en los tickets de órdenes de venta y facturas.',
        },
        {
          key: 130,
          type: 5,
          label: 'Logo para tickets (200 x 175 px)',
          description:
            'Dimensiones ideales: 200 x 175 px. Formatos recomendados: PNG, JPG, o SVG.',
        },
        {
          key: companyFieldsInvoice.invoiceCredit,
          type: 1,
          label: 'Habilitar factura cambiaria en ventas al crédito',
          description:
            'Al habilitar está opción, todas las ventas al crédito emitirán una factura cambiaria',
        },
        {
          key: companyFieldsInvoice.footerInvoiceCredit,
          type: 4,
          label: '"Pie de pagina" en las facturas cambiarias',
          description:
            'En la sección inferior de las facturas se mostrara la información que se agregue en este campo',
          inputType: 'text',
          max: 1200,
        },
        {
          key: companyFieldsInvoice.taxIncluded,
          type: 1,
          label: 'Impuesto incluido en el precio',
          description:
            'Cuando esta opción está activa, el impuesto ya estará incluido en el precio de venta.',
        },
        {
          key: companyFieldsInvoice.taxDetailed,
          type: 1,
          label: 'Impuesto detallado',
          description:
            'Cuando esta opción está activa, se detallará el impuesto en la orden de venta. Si los precios no incluyen el impuesto, este se detallará y se sumará al total de la venta.',
        },
        {
          key: companyFieldsInvoice.showUserCreator,
          type: 1,
          label: 'Ver creador del documento de la factura',
          description:
            'Permite visualizar el nombre del usuario que creó el documento de la factura en el PDF y ticket de la factura.',
        },
        {
          key: companyFieldsInvoice.sellWithSFCCorrelative,
          type: 1,
          label: 'Correlativo de facturación en las ventas',
          description:
            'Al crear una orden de venta desde POS o APP Ventas, se utilizará el correlativo del Sistema de Facturación controlada para el correlativo de la venta',
        },
        {
          key: companyFieldsInvoice.qrInvoice,
          type: 1,
          label: 'QR en facturas',
          description:
            'Muestra el QR para descarga de facturas tanto en el PDF como en el Ticket',
        },
        {
          key: companyFieldsInvoice.enableViewSellPaymentsIntoBillTicket,
          type: 1,
          label: 'Pagos en el ticket de la factura',
          description:
            'Al activar esta opción, se mostrarán los pagos realizados en la factura en el ticket de la misma',
        },
      ],
    },
    {
      value: 4,
      title: 'Cotizaciones',
      icon: faFile,
      fields: [
        {
          key: 118,
          type: 1,
          label: 'Utilizar plantilla clásica en PDF de cotizaciones',
          description:
            'Al estar activo se utilizara la plantilla clásica en el pdf de cotizaciones',
        },
        {
          key: 115,
          type: 1,
          label: 'Visualizar fotos de los ítems de la cotización',
        },
        {
          key: 111,
          type: 1,
          label: 'Los precios de los items seleccionados están sin impuestos',
          show: !svCompany,
        },
        {
          key: 109,
          type: 1,
          label: 'Manejo de impuesto de Timbre de prensa',
          show: !svCompany,
        },
        {
          key: 119,
          type: 1,
          label: 'Permitir facturar abonos en cotizaciones',
          description:
            'Al activar la opción se permite facturar los abonos en cotizaciones en lugar de la cotización.',
        },
        {
          key: 110,
          type: 2,
          label: 'Manejar comisión de agencia',
          inputType: 'number',
          max: 100,
          min: 0,
          prepend: '%',
          show: !svCompany,
        },
        {
          key: 112,
          type: 2,
          label: 'Nombre de representante',
          inputType: 'text',
        },
        {
          key: 113,
          type: 2,
          label: 'Corre electrónico del representante',
          inputType: 'email',
        },
        {
          key: 114,
          type: 2,
          label: 'Número telefónico del representante',
          inputType: 'tel',
        },
        {
          key: 120,
          type: 6,
          label: 'Descripciones en las cotizaciones',
          customRender: (
            <QuoteConfig
              initial={flags.init}
              description={{ value: getInputTextValue(120) }}
              onChange={({ description }) => {
                const fieldItem = find(120)
                companyFieldValues[fieldItem.index].value = description
                setValues([...companyFieldValues])
              }}
            />
          ),
        },
        {
          key: companyFieldsQuote.quoteContactByUser,
          type: 1,
          label: 'Usar datos de perfil como contacto',
          description:
            'Al estar activa se toman los datos de Perfil como datos de contacto en la cotización',
          show: true,
        },
        {
          key: companyFieldsQuote.quoteNameFieldReference,
          type: 2,
          label: 'Nombre del campo para la descripción de la cotización',
          inputType: 'text',
        },
        {
          key: companyFieldsQuote.quoteNameFieldRepresentative,
          type: 2,
          label:
            'Nombre del campo para la información del representante de la cotización',
          inputType: 'text',
        },
      ],
    },
    {
      value: 5,
      title: 'Transacciones',
      icon: faRulerCombined,
      fields: [
        {
          key: 105,
          type: 1,
          label: 'Registrar mismo producto en creación de Ventas, Compras o Gastos',
          description:
            'Al estar activa esta opción, el sistema permitirá hacer registros del mismo producto durante la creación de una Venta, Compra o Gasto.',
        },
        {
          key: 121,
          type: 1,
          label: 'Ingresar precios de forma manual Compras/Gastos',
          description:
            'Al estar activa esta opción las compras/gastos se les habilitara el ingreso de forma manual del precio de los items, y el subtotal se calculara en base al precio',
        },
        {
          key: 131,
          type: 1,
          label: 'Reservar inventario al realizar despachos pendientes',
          description:
            'Al estar activa esta opción y al realizar una confirmación de orden de venta con la opción de despachos pendientes, el inventario en bodega será reservado y dejara de estar disponible para ventas',
        },
        {
          key: 122,
          type: 1,
          label: 'Marcar propina como opcional',
        },
        {
          key: companyFields.useDraftPurchases,
          type: 1,
          label: 'Habilitar borradores en compras',
          description:
            'Permite guardar un respaldo de los movimientos hechos durante la creación de una compra.',
        },
        {
          key: companyFields.useDraftExpenses,
          type: 1,
          label: 'Habilitar borradores en gastos',
          description:
            'Permite guardar un respaldo de los movimientos hechos durante la creación de un gasto.',
        },
        {
          key: companyFields.useExperimentalBalance,
          type: 1,
          label: 'Habilitar ajuste experimental del efectivo',
          description:
            'Se implemento una función que garantiza el ajuste correcto al efectivo de los usuarios.',
        },
        {
          key: companyFieldsPurchase.addTaxDetailed,
          type: 1,
          label: 'Agregar impuesto detallado a las compras y gastos.',
          description:
            'Permite a la cuenta agregar impuesto detallado a las compras y gastos',
        },
      ],
    },
    {
      value: 9,
      title: 'Turnos',
      icon: faBusinessTime,
      fields: [
        {
          key: 133,
          type: 1,
          label: 'Ver terminales en el reporte de Turnos',
          description:
            'Se mostrara en el reporte de turnos las terminales utilizadas durante el periodo del turno.',
        },
        {
          key: 139,
          type: 1,
          label: 'Ver ítems en el reporte de turnos',
          description:
            'Se mostrará en el reporte de turnos los ítems utilizados durante el periodo del turno.',
        },
        {
          key: 163,
          type: 1,
          label: 'Ver ítems eliminados del PDV en el reporte de turnos',
          description:
            'Se mostrará en el reporte de turnos los ítems eliminados de un PDV durante el periodo del turno.',
        },
      ],
    },
    {
      value: 6,
      title: 'Bodegas',
      icon: faWarehouse,
      fields: [
        {
          key: 127,
          type: 2,
          label: 'Token inicio de sesión Wialon',
          description: 'Token con el cual se puede iniciar sesión con Wialon.',
          inputType: 'text',
        },
        {
          key: 123,
          type: 6,
          label: 'Ubicaciones',
          customRender: (
            <CompanyConfigLocation
              show={flags.init}
              initialLocations={getLocationValue()}
              onUpdateInit={() => setFlags({ ...flags, init: false })}
              onChange={(value: string[]) => {
                onChangeLocation(value)
                if (value.length === 0) {
                  const toDisabled = [124, 125, 126]
                  toDisabled.forEach(td => {
                    const fI = find(td)
                    companyFieldValues[fI.index].value = 'false'
                    setValues([...companyFieldValues])
                  })
                }
              }}
            />
          ),
        },
        {
          key: 124,
          type: 1,
          disabled: !useLocations,
          label:
            'Al estar activa esta opción, permite la visualización de las ubicaciones en el inventario y en la bodega.',
        },
        {
          key: 125,
          type: 1,
          disabled: !useLocations,
          label:
            'Al estar activa esta opción, permite visualizar las ubicaciones en el detalle de la orden, asi como en el ticket.',
        },
        {
          key: 126,
          type: 1,
          disabled: !useLocations,
          label:
            'Al estar activa esta opción, permite Visualizar las ubicaciones en la factura.',
        },
      ],
    },
    {
      value: 7,
      title: 'Inventarios',
      icon: faBoxOpen,
      fields: [
        {
          key: 141,
          type: 1,
          label: 'Despachar inventario pendiente automáticamente',
          description:
            'Al estar activa esta opción se despachará el inventario pendiente automáticamente en ingresos de inventario, compras y transferencias.',
        },
        {
          key: 146,
          type: 1,
          label: 'Visualizar unidad de medida en inventario y reportes',
          description:
            'Al estar activa esta opción se mostrará la unidad de medida en inventario y reportes.',
        },
      ],
    },
    {
      value: 8,
      title: 'Depósitos',
      icon: faMoneyBill,
      fields: [
        {
          key: 136,
          type: 1,
          label: 'Aprobar depósitos automáticamente',
          description:
            'Al estar activa esta opción, permite aprobar automáticamente un depósito cuando existe un movimiento bancario con los mismos valores (cuenta bancaria, monto y número de referencia).',
        },
        {
          key: companyFields.automaticApproveDepositsFromSale,
          type: 1,
          label: 'Aprobar depósitos de tipo de pago transferencia',
          description:
            "Al estar activa esta opción, permite aprobar automáticamente un depósito provenientes de ordenes de venta con tipo de pago 'Transferencia'.",
        },
      ],
    },
    {
      value: 9,
      title: 'Google',
      icon: faGoogle,
      fields: [
        {
          key: 123,
          type: 6,
          label:
            'Servicios para el registro de cuentas de Google y la conexión con Google Sheets.',
          customRender: <GoogleOAuth />,
        },
        {
          key: companyFields.spreadsheetId,
          show: companyRegisteredGoogle,
          type: 2,
          label: 'ID de la hoja de cálculo',
          description:
            'ID con el cual será identificada la hoja de cálculo en Google Sheets.',
        },
        {
          key: companyFields.salesSheetId,
          show: companyRegisteredGoogle,
          type: 6,
          label: 'ID de pestaña para información de ventas',
          description:
            'Pestaña en la hoja de cálculo que contiene la información de ventas.',
          customRender: getGoogleSheetComponent(
            'Ventas',
            'ID de pestaña para información de ventas',
            companyFields.salesSheetId,
          ),
        },
        {
          key: companyFields.itemsSheetId,
          show: companyRegisteredGoogle,
          type: 6,
          label: 'ID de pestaña para información de ventas',
          description:
            'Pestaña en la hoja de cálculo que contiene la información de los items de las ventas.',
          customRender: getGoogleSheetComponent(
            'Items de ventas',
            'ID de pestaña para información de items de ventas',
            companyFields.itemsSheetId,
          ),
        },
        {
          key: companyFields.cxcSheetId,
          show: companyRegisteredGoogle,
          type: 6,
          label: 'ID de pestaña para información de ventas',
          description:
            'Pestaña en la hoja de cálculo que contiene la información de las cuentas por cobrar.',
          customRender: getGoogleSheetComponent(
            'Cuentas por cobrar',
            'ID de pestaña para información de cuentas por cobrar',
            companyFields.cxcSheetId,
          ),
        },
        {
          key: companyFields.pendingDispatchSpreadsheetId,
          show: companyRegisteredGoogle,
          type: 2,
          label: 'ID hoja de cálculo Despachos pendientes',
          description:
            'ID con el cual será identificada la hoja de cálculo para despachos pendientes en Google Sheets.',
        },
        {
          key: companyFields.pendingDispatchSheetId,
          show: companyRegisteredGoogle,
          type: 6,
          label: 'ID de pestaña para despachos pendientes',
          description:
            'Pestaña en la hoja de cálculo que contiene la información de despachos pendientes.',
          customRender: getGoogleSheetComponent(
            'Despachos pendientes',
            'ID de pestaña para información de despachos pendientes',
            companyFields.pendingDispatchSheetId,
          ),
        },
        {
          key: companyFields.kardexSpreadsheetId,
          show: companyRegisteredGoogle,
          type: 2,
          label: 'ID hoja de cálculo para Kardex',
          description:
            'ID con el cual será identificada la hoja de cálculo para kardex en Google Sheets.',
        },
        {
          key: companyFields.kardexSheetId,
          show: companyRegisteredGoogle,
          type: 6,
          label: 'ID de pestaña para Kardex',
          description:
            'Pestaña en la hoja de cálculo que contiene la información de Kardex.',
          customRender: getGoogleSheetComponent(
            'Kardex',
            'ID de pestaña para información de kardex',
            companyFields.kardexSheetId,
          ),
        },
      ],
    },
  ]

  const filterFields = (field): boolean => {
    return !field.mentor || (field.mentor && mentorMode)
  }

  const onSave = () => {
    const fieldsToSave: FieldCompany[] = []
    items.forEach((i: ItemProps) => {
      i.fields
        .filter((f: FieldProps) => f.key > 0)
        .forEach((f: FieldProps) => {
          fieldsToSave.push({
            id: f.key,
            value: find(f.key)?.value,
            fieldId: f.key,
          })
        })
    })
    if (mentorMode) dispatch(updateCompanyConfigurationData(id, fieldsToSave))
    else dispatch(setCompanyFields(fieldsToSave, true))
  }

  const render = (
    <div className={'column'}>
      {items.map((item: ItemProps) => (
        <Card
          white
          key={item.value}
          title={
            <div className={'d-flex mb-2'}>
              <Icon
                style={{ marginRight: '10px' }}
                icon={item.icon}
                tooltip={item.title}
              />
              <Paragraph size={'big'} upper>
                {item.title}
              </Paragraph>
            </div>
          }>
          <Row>
            {item.fields?.filter(filterFields)?.map((field: FieldProps) => {
              if (field.show !== undefined && !field.show) return <></>
              let colSize = { xl: 6, lg: 6, md: 6 }
              if (field.type === 4 || field.type === 6)
                colSize = { xl: 12, lg: 12, md: 12 }

              return (
                <Col
                  key={field.key}
                  xl={colSize.xl}
                  lg={colSize.lg}
                  md={colSize.md}
                  className="my-1"
                  sm={12}>
                  {field.type === 1 ? (
                    <SwitchV2
                      label={field.label}
                      checked={getSwitchValue(field.key)}
                      info={field.description || 'Sin descripción'}
                      onChange={(value: boolean) => {
                        const newValue = value ? 'true' : 'false'
                        const fieldItem = find(field.key)
                        companyFieldValues[fieldItem.index].value = newValue
                        setValues([...companyFieldValues])
                      }}
                      disabled={disabled || field.disabled}
                    />
                  ) : field.type === 2 || field.type === 4 ? (
                    <FormText
                      /* @ts-expect-error */
                      label={field.label}
                      mt={0}
                      info={field.description}
                      prepend={field.prepend}
                      type={field.inputType}
                      onFocus={event => event.target.select()}
                      value={getInputTextValue(field.key)}
                      changeValue={v => {
                        const fieldItem = find(field.key)
                        companyFieldValues[fieldItem.index].value = v
                        setValues([...companyFieldValues])
                      }}
                      as={field.type === 4 ? 'textarea' : undefined}
                      max={field.max}
                      min={field.min}
                      rows={6}
                      disabled={disabled}
                    />
                  ) : field.type === 3 ? (
                    <Select
                      label={field.label}
                      mt={0}
                      info={field.description}
                      options={field.options}
                      value={getSelectValue(field.key, field.options)}
                      disabled={disabled}
                      onChange={v => {
                        const fieldItem = find(field.key)
                        companyFieldValues[fieldItem.index].value = v.value.toString()
                        setValues([...companyFieldValues])
                        if (field.onChange) field.onChange(v)
                      }}
                    />
                  ) : field.type === 5 ? (
                    <div>
                      <div className={'d-flex'}>
                        <div className={'mr-1'}>{field.label}</div>
                        {field.description && (
                          <Icon
                            tooltip={field.description}
                            color={'rgba(34, 96, 149, 0.75)'}
                            icon={faInfoCircle}
                            size={'sm'}
                          />
                        )}
                      </div>
                      <ImageInput
                        imageType={imageTypes.TICKET_LOGO}
                        src={getImageValue(field.key)}
                        style={{ height: 100, width: 100 }}
                        onSuccessFile={url => {
                          const fieldItem = find(field.key)
                          companyFieldValues[fieldItem.index].value = url
                          setValues([...companyFieldValues])
                        }}
                        disabled={disabled}
                      />
                    </div>
                  ) : (
                    <div>{field.customRender}</div>
                  )}
                </Col>
              )
            })}
          </Row>
        </Card>
      ))}
    </div>
  )

  return (
    <div>
      {modal ? (
        <Modal show={show && !felCompany.show} centered size={'xl'}>
          <Modal.Header>
            <Modal.Title>{name}: Configuraciones</Modal.Title>
          </Modal.Header>
          <Modal.Body className={'custom-modal-body'}>{render}</Modal.Body>
          <Modal.Footer>
            <Row className={'container-buttons'}>
              <Button
                disabled={disabled}
                icon={faWindowClose}
                color={'secondary'}
                onClick={() => {
                  setValues([])
                  onHide()
                }}>
                Cerrar
              </Button>
              <Button loading={loadingUpdate} icon={faSave} onClick={onSave}>
                Guardar
              </Button>
            </Row>
          </Modal.Footer>
        </Modal>
      ) : (
        <div className={'column'}>
          <Title title={'Configuraciones'} />
          {render}

          <FABV2
            title={'Guardar'}
            icon={faSave}
            show={!loadingCurrencies}
            loading={loadingUpdate}
            onClick={onSave}
          />
        </div>
      )}

      <FELCompanyConfig
        {...felCompany}
        country={Number(find(COUNTRY_FIELD).value)}
        onHide={() => setFelCompany({ ...felCompany, show: false })}
        useExportation={find(companyFields.useExportationInvoice)?.value === 'true'}
      />
    </div>
  )
}
export default CompanyConfiguration
