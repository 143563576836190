import { IconDefinition, faPlus } from '@fortawesome/free-solid-svg-icons'
import './FABV2.scss'
import React, { useState, useEffect } from 'react'
import IconButton from './IconButton'

interface FabChild {
  text: string
  color?: string
  icon: React.ReactNode | IconDefinition
  onClick?: (e: React.FormEvent) => void
}

interface FabProps {
  onClick?: (e: React.FormEvent) => void
  title?: string
  icon: React.ReactNode | IconDefinition
  items?: FabChild[]
  textAnimated?: boolean
  show: boolean
  loading?: boolean
}

export const FABV2: React.FC<FabProps> = ({
  title,
  items,
  textAnimated,
  icon,
  onClick,
  show,
  loading,
}: FabProps) => {
  const [hover, setHover] = useState(false)
  const [see, setSee] = useState(false)
  useEffect(() => {
    setSee(show)
  }, [show])

  return (
    <div
      style={{
        zIndex: 5,
        right: 15,
        bottom: 20,
        position: 'fixed',
        display: 'fixed',
      }}
      onMouseLeave={() => {
        setHover(false)
      }}>
      {see && (
        <div id="contenedor">
          {hover && (
            <div id={'contenedor'} className={hover ? 'showFabs' : 'hiddenFabs'}>
              {items &&
                items.map((item, index) => (
                  <div
                    style={{
                      backgroundColor: item.color || 'rgb(179, 87, 150, 502)',
                      height: '45px',
                      opacity: loading ? 0.5 : 1,
                      pointerEvents: loading ? 'none' : 'auto',
                    }}
                    className={textAnimated ? 'fabKolo btn-icon' : 'fabKolo'}
                    key={index}
                    onClick={item.onClick}>
                    <div>
                      <span className={textAnimated ? 'hidden' : ''}>
                        {item.text} &nbsp;
                      </span>
                    </div>

                    <div>
                      <IconButton icon={item.icon} spin={loading} />
                    </div>
                  </div>
                ))}
            </div>
          )}
          <div
            className={textAnimated ? 'fabKolo btn-icon' : 'fabKolo'}
            style={{
              backgroundColor: '#12A8BB',
              width: hover && textAnimated ? '150px' : '',
              opacity: loading ? 0.5 : 1,
              pointerEvents: loading ? 'none' : 'auto',
            }}
            onMouseEnter={() => {
              setHover(true)
            }}
            onClick={onClick}>
            <div>
              <IconButton icon={icon || faPlus} spin={loading} />
            </div>
            <div>
              <span className={!hover && textAnimated ? 'hidden' : ''}>
                &nbsp;{title}{' '}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default FABV2
