import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Alert from 'sweetalert-react'
import { Modal } from 'react-bootstrap'
import { TableV2, Icon } from 'src/components'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  seePurchasePayments,
  getDocuments,
  onNullifyTransactionPurchase,
} from 'src/actions/purchase.actions'
import { selectPurchaseDocuments } from 'src/selectors/purchase.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  handlerSuccess,
  hasErrors,
} from '../../../selectors/error.selector'
import { isAllowed } from '../../../selectors/modules.selector'

const PurchaseDocumentPayments = ({ onRefresh = () => undefined }) => {
  const dispatch = useDispatch()

  const loading = useSelector(state =>
    loadingSelector([actionTypes.SEE_PURCHASE_PAYMENTS])(state),
  )

  const purchase = useSelector(state => state.purchase.paymentsPurchaseValues)

  const loadingDocuments = useSelector(state =>
    loadingSelector([actionTypes.DOCUMENTS])(state),
  )
  const documents = useSelector(selectPurchaseDocuments)

  const loadingNullify = useSelector(state =>
    loadingSelector([actionTypes.NULLIFY_RESPONSE])(state),
  )
  const errorNullify = useSelector(state =>
    hasErrors([actionTypes.NULLIFY_RESPONSE])(state),
  )

  // PERMISSIONS
  const cppNullify = useSelector(state => isAllowed(state, [12651]))

  const [alert, setAlert] = useState({ title: '' })
  const [flags, setFlags] = useState({ getId: false, nullify: false })
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (loading) setFlags({ ...flags, getId: true })
    else if (flags.getId && purchase.id) {
      setFlags({ ...flags, getId: false })
      setShow(true)
      dispatch(getDocuments(purchase.id))
    }
  }, [loading])

  useEffect(() => {
    if (loadingNullify && show) setFlags({ ...flags, nullify: true })
    else if (flags.nullify) {
      setFlags({ ...flags, nullify: false })
      if (errorNullify)
        setAlert({
          ...handlerError(errorNullify.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Pago anulado satisfactoriamente'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            dispatch(getDocuments(purchase.id))
            onRefresh()
          },
        })
    }
  }, [loadingNullify])

  const nullifyTransaction = transactionId => {
    setAlert({
      show: true,
      type: 'warning',
      title: 'Anulación de pago',
      text: '¿Desea anular el pago?  Esta acción no podra ser revertida',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Anular',
      confirmButtonColor: '#B35796',
      onCancel: () => setAlert({ ...alert, show: false }),
      onConfirm: () => {
        setAlert({ ...alert, show: false })
        dispatch(onNullifyTransactionPurchase(purchase.id, { transactionId }))
      },
    })
  }

  return (
    <div>
      <Modal
        show={show}
        size={'xl'}
        centered
        onHide={() => {
          setShow(false)
          dispatch(seePurchasePayments({}))
        }}>
        <Modal.Header closeButton>
          <Modal.Title>
            DOCUMENTOS UTILIZADOS EN LA COMPRA: {purchase?.invoice}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableV2
            loading={loadingDocuments || loadingNullify}
            items={documents}
            headers={[
              {
                label: 'Estado',
                show: true,
                value: ['valid'],
                type: 'text',
                className: 'mini',
                custom: item => <div>{item.valid ? 'Vigente' : 'Anulada'}</div>,
              },
              {
                label: 'Fecha',
                show: true,
                value: ['date'],
                type: 'date',
                className: 'mini',
              },
              {
                label: 'Tipo',
                show: true,
                value: ['documentName'],
                type: 'text',
                className: 'mini',
              },
              {
                label: 'Referencia',
                show: true,
                value: ['referenceName'],
                type: 'text',
                className: 'medium',
              },
              {
                label: 'Cantidad',
                show: true,
                value: ['quantity'],
                type: 'currency',
                className: 'mini',
              },
              {
                label: 'Descripción',
                show: true,
                value: ['description'],
                type: 'text',
                className: 'medium',
                config: !cppNullify,
              },
              {
                config: cppNullify,
                show: cppNullify,
                label: '',
                className: 'mini',
                custom: item => {
                  return item.valid ? (
                    <Icon
                      disabled={loadingNullify}
                      icon={faTrash}
                      color={'red'}
                      tooltip={'Anular pago'}
                      onClick={() => {
                        nullifyTransaction(item.transactionId)
                      }}
                    />
                  ) : (
                    <div />
                  )
                },
              },
            ]}
            storageKey={`modalDocuments`}
          />
        </Modal.Body>
      </Modal>

      <Alert {...alert} />
    </div>
  )
}
export default PurchaseDocumentPayments
