import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  Card,
  FormText as TextField,
  Button,
  Select,
  Geocoding,
  Switch,
  Checkbox,
  IconImg,
} from 'src/components'

import { faExclamationCircle, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'
import { geolocated } from 'react-geolocated'
import Alert from 'sweetalert-react'

import { getAllLocalities } from 'src/actions/address.actions'
import { selectAllLocalities } from 'src/selectors/address.selector'

import { actionTypes, createAdminCompany } from 'src/actions/company.actions'
import { actionTypes as typesC } from 'src/actions/clients.actions'
import { selectCreateAdminNewCompany } from 'src/selectors/company.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  singleErrorSelector,
  hasErrorsSelector,
  handlerError,
  handlerSuccess,
} from 'src/selectors/error.selector'
import IconSearch from 'src/assets/v2/icon-search2.svg'
import { getInfoNIT } from 'src/actions/clients.actions'
import { selectNITinfo } from 'src/selectors/clients.selector'
import { getPlans } from 'src/actions/plans.actions'
import { selectPlansToSelect } from 'src/selectors/plans.selector'
import { formatNumberWithCommas } from 'src/utils/formatters'
import PlanItem from 'src/content/Distrbution/Plans/PlanItem'

const phoneCompanyOptions = [
  { label: 'Claro', value: 1 },
  { label: 'Tigo', value: 2 },
  { label: 'Movistar', value: 3 },
]

const businessTypeOptions = [
  { label: 'Tienda/Abarroteráa', value: 2 },
  { label: 'Panadería/Pastelería', value: 3 },
  { label: 'Comedor/Restaurante', value: 4 },
  { label: 'Aceitera', value: 5 },
  { label: 'Miscelanea', value: 6 },
  { label: 'Taller de autos', value: 9 },
  { label: 'Taller de Motos', value: 10 },
  { label: 'Car Wash', value: 11 },
  { label: 'Venta de repuestos', value: 12 },
  { label: 'Negocio Independiente', value: 13 },
]

class CreateCompany extends Component {
  state = {
    company: {
      name: '',
      address: '',
      nit: 'CF',
      email: '',
      description: '',
      phone: '',
      isDistributor: false,
      isClient: true,
      isProducer: false,
      isPromotor: false,
      principalWarehouse: false,
      koloCertifier: false,
    },
    client: {
      nit: 'CF',
      companyName: '',
      address: '',
      secondAddress: '',
      reference: '',
      town: null,
      businessType: null,
      phoneCompany: null,
      phone: '',
      latitude: '',
      longitude: '',
      postalCode: '',
    },
    errors: {},
    errorsC: {},
    alert: { title: 'default' },
    showInfo: false,
  }

  componentDidMount () {
    this.props.getAllLocalities()
    this.props.getPlans()
  }

  UNSAFE_componentWillReceiveProps (props) {
    const { hasError, hasErrorN, loading, loadingN, handlerError, handlerSuccess } =
      this.props
    const { error, companyResponse, infoNIT } = props
    const { company } = this.state

    let errors = {}
    let alert = { title: 'default' }

    if (loading && !props.loading) {
      if (!hasError && props.hasError) {
        /*if (error.response && error.response.data && error.response.status != 500) {
                    if (data) {
                        Object.keys(data).map((d) => (errors[d] = data[d]));
                    }
                }*/
        alert = handlerError(
          error.response ? error.response.data : 'Ha ocurrido un error inesperado',
        )
        alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
      } else {
        if (companyResponse)
          alert = handlerSuccess(
            `Usuario: ${company.email}, Contraseña: ${companyResponse}`,
          )
        else
          alert = handlerSuccess(
            `El usuario: ${company.email}, ya está registrado, puede ingresar a la nueva empresa con su actual contraseña`,
          )
        alert.onConfirm = () => {
          this.setState({ alert: { ...alert, show: false } })
          this.props.history.goBack()
        }
      }
    }

    if (loadingN && !props.loadingN) {
      if (!hasErrorN && props.hasErrorN) {
        const { client } = this.state
        this.setState({
          client: { ...client, companyName: '' },
          company: { ...company, name: '' },
        })
        alert = handlerError('No se ha encontrado información con el NIT ingresado')
        alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
      } else {
        const { client } = this.state
        this.setState({
          client: { ...client, companyName: infoNIT.clientName },
          company: { ...company, name: infoNIT.clientName },
        })
      }
    }

    this.setState({ alert, errors })
  }

  onChangeText = (data, parametter, object) => {
    const { value } = data.target
    let ob = this.state[object]
    ob[parametter] = value
    if (object === 'company') {
      if (parametter === 'nit') {
        const { client } = this.state
        this.setState({ client: { ...client, [parametter]: value } })
      } else if (parametter === 'name') {
        const { client } = this.state
        this.setState({ client: { ...client, companyName: value } })
      } else if (parametter === 'address') {
        const { client } = this.state
        this.setState({ client: { ...client, secondAddress: value } })
      }
    }
    this.setState({ ...ob })
  }

  onCheckboxChange = data => {
    const { name, checked } = data.target
    let { company } = this.state

    if (name === 'isDistributor') {
      company.isDistributor = checked
      company.limit = !checked
    } else if (name === 'limit') {
      company.isDistributor = !checked
      company.limit = checked
    } else {
      company[name] = !company[name]
    }

    this.setState({ company })
  }

  onChange = (data, parametter, object) => {
    let ob = this.state[object]
    ob[parametter] = data
    this.setState({ ...ob })
  }

  validate = () => {
    let { company, client } = this.state
    let { plans } = this.props
    let errors = {}
    let errorsC = {}

    const plan = plans.find(p => p.id === company.plan) || {}

    /*Object.keys(company).filter((c) => c !== 'isClient' && c !== 'isDistributor' && c !== 'isProducer' && c !== 'isPromotor' && c !== 'principalWarehouse' && c !== 'koloCertifier' && c !== 'isLimit').map((c) => {
            if (!company[c]) errors[c] = 'El campo es requerido';
        });*/

    // if (!company.limit && !company.isDistributor)
    //   errors.company = 'Se debe escoger un plan.'

    if (!plan?.id) errors.plan = 'Se debe escoger un plan.'

    if (
      !company.limit &&
      !company.isClient &&
      !company.isProducer &&
      !company.isDistributor &&
      !company.isPromotor
    )
      errors.company = 'Es necesario un tipo de empresa'

    if (company.isClient) {
      Object.keys(client)
        .filter(c => c !== 'reference' && c !== 'businessType' && c !== 'phoneCompany')
        .forEach(c => {
          if (!client[c]) errorsC[c] = 'El campo es requerido'
        })

      if (client.latitude && !/^-?\d+(.\d+)?$/.test(client.latitude))
        errorsC.latitude = 'Latitud incorrecta'
      if (client.longitude && !/^-?\d+(.\d+)?$/.test(client.longitude))
        errorsC.latitude = 'Longitude incorrecta'
      if (client.phone && !/^[0-9]+$/.test(client.phone))
        errorsC.phone = 'Telefono incorrecto'
      if (client.phone && client.phone.length !== 8) errorsC.phone = 'Telefono incorrecto'
    }

    if (Object.keys(errors).length === 0 && Object.keys(errorsC).length === 0) {
      let clientData = Object.assign({}, client)
      if (company.isClient) {
        clientData.town = client.town.value
        // clientData.businessType = client.businessType.value;
        // clientData.phoneCompany = client.phoneCompany.value;
      }
      company.planId = plan.id
      company.client = clientData
      company.assignPromotor = this.props.location.state
        ? this.props.location.state.assignPromotor
        : false
      if (!company.isKolo) company.isKolo = false

      this.props.createSingleAdminCompany(company)
    }
    this.setState({ errors, errorsC, showInfo: false })
  }

  getData = data => {
    let { client } = this.state
    const { towns } = this.props
    const formatTowns = towns.map(t => ({ label: t.name, value: t.id }))

    client.address = data.address
    //client.secondAddress = data.address
    client.latitude = data.latLng.lat
    client.longitude = data.latLng.lng

    if (data.town) {
      const town = formatTowns.find(t => t.label === data.town)
      if (town) client.town = town
      else client.town = ''
    } else client.town = ''
    this.setState({ client })
  }

  render () {
    const { company, errors, errorsC, alert, client, showInfo } = this.state
    const { loading, towns, coords, loadingN, plans } = this.props

    const formatTowns = towns.map(t => ({ label: t.name, value: t.id }))

    const plan = plans.find(p => p.id === company.plan) || {}

    let lat = 14.55
    let lng = -90.55
    if (coords) {
      lat = coords.latitude || 0
      lng = coords.longitude || 0
    }

    return (
      <div>
        <h1 className={'dashboard-welcome'}>Nueva Empresa</h1>
        <br />

        <Row>
          <Col>
            <Card>
              <h5>Información de la empresa</h5>
              <hr />
              <form>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={3}>
                    <br />
                    <span>Nombre</span>
                  </Col>
                  <Col>
                    <TextField
                      placeholder={'Nombre'}
                      type={'text'}
                      disabled={loadingN}
                      value={company.name}
                      error={errors.name}
                      onChange={data => this.onChangeText(data, 'name', 'company')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={3}>
                    <br />
                    <span>Nombre Corto</span>
                  </Col>
                  <Col>
                    <TextField
                      placeholder={'Nombre Corto'}
                      type={'text'}
                      value={company.shortName}
                      error={errors.shortName}
                      onChange={data => this.onChangeText(data, 'shortName', 'company')}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={4} lg={4} md={4} sm={3}>
                    <br />
                    <span>Dirección</span>
                  </Col>
                  <Col>
                    <TextField
                      placeholder={'Dirección'}
                      type={'text'}
                      value={company.address}
                      error={errors.address}
                      onChange={data => this.onChangeText(data, 'address', 'company')}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={4} lg={4} md={4} sm={3}>
                    <br />
                    <span>NIT</span>
                  </Col>
                  <Col>
                    <TextField
                      placeholder={'NIT'}
                      type={'text'}
                      value={company.nit}
                      error={errors.nit}
                      disabled={loadingN}
                      onChange={data => this.onChangeText(data, 'nit', 'company')}
                      append={
                        <IconImg
                          style={{ margin: null }}
                          icon={IconSearch}
                          loading={loadingN}
                          placement={'top'}
                          tooltip={'Buscar NIT'}
                          onClick={() => this.props.getNIT(company.nit)}
                        />
                      }
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={4} lg={4} md={4} sm={3}>
                    <br />
                    <span>Correo Eléctronico</span>
                  </Col>
                  <Col>
                    <TextField
                      placeholder={'p.j. empresa@cotizap.com'}
                      type={'text'}
                      value={company.email}
                      error={errors.email}
                      onChange={data => this.onChangeText(data, 'email', 'company')}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={4} lg={4} md={4} sm={3}>
                    <br />
                    <span>Descripción</span>
                  </Col>
                  <Col>
                    <TextField
                      placeholder={'Descripción'}
                      type={'text'}
                      value={company.description}
                      error={errors.description}
                      onChange={data => this.onChangeText(data, 'description', 'company')}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={4} lg={4} md={4} sm={3}>
                    <br />
                    <span>Teléfono de la compañía</span>
                  </Col>
                  <Col>
                    <TextField
                      placeholder={'Teléfono'}
                      type={'text'}
                      value={company.phone}
                      error={errors.phone}
                      onChange={data => this.onChangeText(data, 'phone', 'company')}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={4} lg={4} md={4} sm={3} xs={3}>
                    <br />
                    <span>Usar certificador de KOLO</span>
                  </Col>
                  <Col>
                    <br />
                    <Row>
                      <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                        <Switch
                          id={'koloCertifier'}
                          checked={company.koloCertifier}
                          onChange={() => {
                            this.onCheckboxChange({
                              target: { name: 'koloCertifier' },
                            })
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={3} xs={3}>
                    <br />
                    <span>Vitrina KOLO</span>
                  </Col>
                  <Col>
                    <br />
                    <Row>
                      <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                        <Switch
                          id={'isKolo'}
                          checked={company.isKolo}
                          onChange={() => {
                            this.onCheckboxChange({
                              target: { name: 'isKolo' },
                            })
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <br />
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <br />
                    <span>Plan de empresa</span>
                  </Col>

                  <Col>
                    <Select
                      value={plan}
                      placeholder={'s'}
                      options={plans}
                      onChange={data => this.onChange(data.id, 'plan', 'company')}
                      error={errorsC.plan}
                    />
                    {Boolean(plan.id) && <PlanItem item={plan} />}
                    <br />
                  </Col>
                </Row>
              </form>
            </Card>
            {!company.isClient && (
              <Row className={'container-buttons'}>
                <Button
                  loading={loading}
                  onClick={() => {
                    company.principalWarehouse = true
                    this.setState({ company: company })
                    this.validate()
                  }}>
                  <FontAwesomeIcon icon={faPlus} /> Crear empresa
                </Button>
              </Row>
            )}
          </Col>
        </Row>

        {company.isClient && (
          <Row style={{ height: 700 }}>
            <Col style={{ height: '100%' }} xl={6} lg={6} md={6} xm={12} xs={12}>
              <Geocoding
                lat={client.latitude || lat}
                lng={client.longitude || lng}
                defaultAddress={client.address}
                auto={true}
                editable={true}
                getData={this.getData}
                zoom
                style={{ height: '100%' }}
              />
            </Col>
            <Col style={{ height: '100%' }} xl={6} lg={6} md={6} xm={12} xs={12}>
              <Card style={{ height: '100%' }}>
                <h5>Información del punto de venta</h5>
                <hr />
                <form>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={3}>
                      <br />
                      <span>NIT</span>
                    </Col>
                    <Col>
                      <TextField
                        placeholder={'pj. CF'}
                        type={'text'}
                        value={client.nit}
                        error={errorsC.nit}
                        disabled
                        onChange={data => this.onChangeText(data, 'nit', 'client')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={3}>
                      <br />
                      <span>Nombre</span>
                    </Col>
                    <Col>
                      <TextField
                        placeholder={'Nombre'}
                        type={'text'}
                        disabled
                        value={client.companyName}
                        error={errorsC.companyName}
                        onChange={data =>
                          this.onChangeText(data, 'companyName', 'client')
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={3}>
                      <br />
                      <span>Dirección</span>
                    </Col>
                    <Col>
                      <TextField
                        placeholder={'Dirección'}
                        type={'text'}
                        disabled
                        value={client.secondAddress}
                        error={errorsC.address}
                        onChange={data =>
                          this.onChangeText(data, 'secondAddress', 'client')
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={3}>
                      <br />
                      <span>Referencia</span>
                    </Col>
                    <Col>
                      <TextField
                        placeholder={'Referencia'}
                        type={'text'}
                        value={client.reference}
                        error={errorsC.reference}
                        onChange={data => this.onChangeText(data, 'reference', 'client')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={3}>
                      <br />
                      <span>Municipio</span>
                    </Col>
                    <Col>
                      <Select
                        disabled={client.town !== ''}
                        value={client.town}
                        withoutLabel
                        placeholder={'Municipio'}
                        options={formatTowns}
                        onChange={data => this.onChange(data, 'town', 'client')}
                        error={errorsC.town}
                      />
                    </Col>
                  </Row>
                  {/*
                                <Row>
                                    <Col xl={4} lg={4} md={4} sm={3} xl={3}>
                                        <br/>
                                        <span>Tipo de Negocio</span>
                                    </Col>
                                    <Col>
                                        <Select
                                            value={client.businessType}
                                            withoutLabel
                                            placeholder={'Tipo de negocio'}
                                            options={businessTypeOptions}
                                            onChange={(data) => this.onChange(data, 'businessType', 'client')}
                                            error={errorsC.businessType}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={4} lg={4} md={4} sm={3} xl={3}>
                                        <br/>
                                        <span>Compañía Telefónica</span>
                                    </Col>
                                    <Col>
                                        <Select
                                            value={client.phoneCompany}
                                            withoutLabel
                                            placeholder={'Compañía Telefónica'}
                                            options={phoneCompanyOptions}
                                            onChange={(data) => this.onChange(data, 'phoneCompany', 'client')}
                                            error={errorsC.phoneCompany}
                                        />
                                    </Col>
                                </Row>
                                */}
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={3}>
                      <br />
                      <span>Teléfono</span>
                    </Col>
                    <Col>
                      <TextField
                        placeholder={'Teléfono'}
                        type={'number'}
                        value={client.phone}
                        error={errorsC.phone}
                        onChange={data => this.onChangeText(data, 'phone', 'client')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={3}>
                      <br />
                      <span>Código Postal</span>
                    </Col>
                    <Col>
                      <TextField
                        placeholder={'Código Postal'}
                        type={'text'}
                        value={client.postalCode}
                        onChange={data => this.onChangeText(data, 'postalCode', 'client')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={3}>
                      <br />
                      <span>Ubicación</span>
                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <TextField
                            disabled
                            placeholder={'Latitude'}
                            type={'number'}
                            value={client.latitude}
                            error={errorsC.latitude}
                            onChange={data =>
                              this.onChangeText(data, 'latitude', 'client')
                            }
                          />
                        </Col>
                        <Col>
                          <TextField
                            disabled
                            placeholder={'Longitude'}
                            type={'number'}
                            value={client.longitude}
                            error={errorsC.longitude}
                            onChange={data =>
                              this.onChangeText(data, 'longitude', 'client')
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </form>
              </Card>
            </Col>
          </Row>
        )}

        {company.isClient && (
          <Row className={'container-buttons mt-5'}>
            <Button
              color={'primary'}
              icon={faPlus}
              loading={loading}
              onClick={() => {
                company.principalWarehouse = true
                /*this.setState({company: company});*/ this.validate()
              }}>
              Crear empresa
            </Button>
          </Row>
        )}

        <Alert {...alert} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  companyResponse: selectCreateAdminNewCompany(state),
  loading: loadingSelector([actionTypes.CREATE_ADMIN_COMPANY])(state),
  hasError: hasErrorsSelector([actionTypes.CREATE_ADMIN_COMPANY])(state),
  error: singleErrorSelector([actionTypes.CREATE_ADMIN_COMPANY])(state),
  loadingN: loadingSelector([typesC.GET_NIT_INFO])(state),
  hasErrorN: hasErrorsSelector([typesC.GET_NIT_INFO])(state),
  handlerSuccess: message => handlerSuccess(message),
  handlerError: message => handlerError(message),
  towns: selectAllLocalities(state),
  infoNIT: selectNITinfo(state),
  plans: selectPlansToSelect(state),
})
const mapDispatchToProps = dispatch => ({
  createSingleAdminCompany: company => dispatch(createAdminCompany(company)),
  getAllLocalities: () => dispatch(getAllLocalities()),
  getNIT: nit => dispatch(getInfoNIT(nit)),
  getPlans: () => dispatch(getPlans()),
})

const ReduxComponent = connect(mapStateToProps, mapDispatchToProps)(CreateCompany)
export default geolocated({
  positionOptions: { enableHighAccuracy: false },
  userDecisionTimeout: 500,
})(ReduxComponent)
