import { capitalizeWorld, haveAnyValue } from 'src/utils/utilitiesV2'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'

const patrimonialAccounts: IPatrimonialAccounts[] = [
  { name: 'Inventario', param: 'total' },
  { name: 'Bancos', param: 'bank' },
  { name: 'Cuentas por cobrar', param: 'cpc' },
  { name: 'Efectivo Circulante', param: 'transportist', prevParam: 'deposits' },
  { name: 'Cobros con tarjeta', param: 'card' },
  { name: 'Cuentas por Pagar', param: 'cpp' },
  { name: 'Cuenta por pagar clientes', param: 'positiveBalance' },
  { name: 'Patrimonio', param: 'result' },
]

/**
 * Calculates the patrimonial difference between two values.
 *
 * @param {IPatrimonialLog} log - The log object that contains the patrimonial values.
 * @param {string} param - The parameter that will be used to get the current value from the log.
 * @param {string} [prevParam] - The parameter that will be used to get the previous value from the log. If not provided, it will use the same value as `param`.
 *
 * @returns {IPatrimonialDifference} An object that contains the current value, the previous value, the difference between both, the color representing if the difference is positive or negative, and the corresponding icon.
 */
const getPatrimonialDifference = (
  log: IPatrimonialLog,
  param: string,
  prevParam?: string,
): IPatrimonialDifference => {
  if (prevParam === undefined) prevParam = param
  const prevParamValue = `prev${capitalizeWorld(prevParam)}`

  let color = ''
  let icon = null

  const value: number = log[param]
  const prevValue: number = log[prevParamValue]
  let difference = 0
  if (haveAnyValue(prevValue)) {
    difference = value - prevValue
    if (difference !== 0) {
      if (value > prevValue) {
        color = 'green'
        icon = faArrowUp
      } else if (value < prevValue) {
        color = 'red'
        icon = faArrowDown
      }
    }
  }

  return { value, prevValue, difference, color, icon }
}

export { patrimonialAccounts, getPatrimonialDifference }
