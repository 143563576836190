import React, { useState, useEffect } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  actionTypes,
  createCategorization,
  deleteCategorization,
  getCategorizationByType,
  updateCategorization,
} from 'src/actions/categorization.actions'
import { Button, CustomCreate, FormTextField, Icon } from 'src/components'
import {
  selectCategorizationByType,
  selectCategorization,
} from 'src/selectors/categorizations.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  faEdit,
  faLocationArrow,
  faSave,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { selectCompanyFieldById } from 'src/selectors/company.selector'
import Alert from 'sweetalert-react'
import NumberField from 'src/components/NumberField/NumberField'

export const LocationComponent = ({
  value,
  onHide,
  onConfirm,
  onSaveEdit,
  modeSelection,
}) => {
  const loadingLocations = useSelector(state =>
    loadingSelector([actionTypes.GET_CATEGORIZATION_BY_TYPE])(state),
  )
  const loadingCreate = useSelector(state =>
    loadingSelector([actionTypes.CREATE_CATEGORIZATION])(state),
  )
  const loadingUpdate = useSelector(state =>
    loadingSelector([
      actionTypes.UPDATE_CATEGORIZATION,
      actionTypes.DELETE_CATEGORIZATION,
    ])(state),
  )

  const responseLocation = useSelector(selectCategorization)
  const [location, setLocation] = useState({
    show: false,
    edit: false,
    id: null,
    subWarehouse: null,
    shelf: null,
    level: null,
    quantity: 0,
    decimals: 0,
  })
  const dispatch = useDispatch()
  const companyLocations = useSelector(selectCategorizationByType)
  const seeWarehouseLocations = useSelector(state => selectCompanyFieldById(state, 123))

  const [subWarehouses, setSubWarehouses] = useState({})
  const [shelves, setShelves] = useState({})
  const [levels, setLevels] = useState({})
  const [alert, setAlert] = useState({})
  const [newCategorization, setNewCategorization] = useState({
    description: '',
    name: '',
    parentCategorization: '',
    type: 21,
    locationType: 0,
  })
  const [errors, setErrors] = useState({})
  const [nameLocations, setNameLocations] = useState([])
  const [action, setAction] = useState({})
  const [locationQuantity, setLocationQuantity] = useState({ value: 3, label: '3' })

  useEffect(() => {
    if (loadingCreate) setAction({ ...action, create: true })
    else if (action.create) {
      setAction({ ...action, create: false })
      const val = {
        label: responseLocation.name,
        name: responseLocation.name,
        value: responseLocation.id,
        children: responseLocation.childrenCategorization,
        description: responseLocation.description,
      }
      if (newCategorization.locationType === 1)
        setLocation({
          ...location,
          subWarehouse: val,
        })
      if (newCategorization.locationType === 2)
        setLocation({
          ...location,
          shelf: val,
        })
      if (newCategorization.locationType === 3)
        setLocation({
          ...location,
          level: val,
        })
      dispatch(getCategorizationByType(21))
    }
  }, [loadingCreate])

  useEffect(() => {
    if (value.show) {
      setLocation({
        ...value,
        show: true,
      })
    }
  }, [value])

  useEffect(() => {
    if (loadingLocations) setAction({ ...action, get: true })
    else if (action.get) {
      setAction({ ...action, get: false })
      const locationCategory = companyLocations.children.find(
        x => x.name === 'Ubicaciones',
      )
      for (let i = 0; i < locationQuantity.value; i++) {
        const locations = locationCategory.children.find(x => x.name === nameLocations[i])
        if (i === 0) setSubWarehouses(locations || {})
        else if (i === 1) setShelves(locations || {})
        else if (i === 2) setLevels(locations || {})
      }
    }
  }, [loadingLocations])

  useEffect(() => {
    if (loadingUpdate) setAction({ ...action, update: true })
    else if (action.update) {
      setAction({ ...action, update: false })
      dispatch(getCategorizationByType(21))
      setLocation({ ...location, subWarehouse: null, shelf: null, level: null })
    }
  }, [loadingUpdate])

  useEffect(() => {
    if (Object.entries(companyLocations).length !== 0 && nameLocations.length > 0) {
      const locationCategory = companyLocations.children.find(
        x => x.name === 'Ubicaciones',
      )
      if (locationCategory) {
        const sub = locationCategory.children.find(x => x.name === nameLocations[0])
        if (!sub) createLocations(nameLocations[0], 'Ubicación 1', locationCategory.id)
        setSubWarehouses(sub || {})
        if (locationQuantity.value >= 2) {
          const shelves = locationCategory.children.find(x => x.name === nameLocations[1])
          if (!shelves)
            createLocations(nameLocations[1], 'Ubicación 2', locationCategory.id)
          setShelves(shelves || {})
        }
        if (locationQuantity.value === 3) {
          const level = locationCategory.children.find(x => x.name === nameLocations[2])
          if (!level)
            createLocations(nameLocations[2], 'Ubicación 3', locationCategory.id)
          setLevels(level || {})
        }
      } else {
        createLocations('Ubicaciones', 'General', null)
      }
    }
  }, [nameLocations])

  const createLocations = (name, description, parent) => {
    dispatch(
      createCategorization({
        description: description,
        name: name,
        parentCategorizationId: parent,
        type: 21,
      }),
    )
  }

  useEffect(() => {
    if (JSON.stringify(seeWarehouseLocations) !== '{}') {
      const locationsN = seeWarehouseLocations.value.split(',')
      setNameLocations(locationsN)
      setLocationQuantity({
        value: locationsN.length,
        label: locationsN.length.toString(),
      })
    }
  }, [seeWarehouseLocations])

  const hide = () => {
    const defaultLocation = {
      ...location,
      show: false,
      edit: false,
      id: null,
      subWarehouse: null,
      shelf: null,
      level: null,
      quantity: 0,
    }

    setLocation(defaultLocation)
    if (onHide) onHide(defaultLocation)
  }

  const getActions = (id, parent, location) => {
    return (
      location !== null && (
        <>
          {' '}
          <Icon
            icon={faEdit}
            tooltip={'Editar'}
            onClick={() => {
              setNewCategorization({
                ...newCategorization,
                name: location.name,
                description: location.description,
                edit: true,
                assigned: false,
                id: id,
                parent: parent,
                principal: false,
                public: false,
                publicCategorization: false,
                show: true,
                toggled: false,
                type: 21,
              })
            }}
          />
          <Icon
            icon={faTrash}
            tooltip={'Eliminar'}
            onClick={() => {
              let alert = {
                title: `Importante`,
                text: 'Se eliminara la ubicación que se encuentra activa en el selector',
                type: 'info',
                show: true,
                showCancelButton: true,
                onCancel: () => setAlert({ alert: { ...alert, show: false } }),
                onConfirm: () => {
                  dispatch(deleteCategorization(id))
                  setAlert({ alert: { ...alert, show: false } })
                },
              }
              setAlert({ ...alert })
            }}
          />
        </>
      )
    )
  }

  return (
    <>
      <Modal
        show={location.show}
        centered
        // size={'md'}
        onHide={() => {
          hide()
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Ubicaciones</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              {value.maxQuantity && !modeSelection && (
                <h2>
                  {'Existencia disponible : ' +
                    (value.maxQuantity - location.quantity).toFixed(
                      location.decimals || 0,
                    )}
                </h2>
              )}
              <Row>
                <Col xl={11} lg={11} md={11} sm={11} xs={11}>
                  <CustomCreate
                    loading={loadingLocations}
                    onChange={data => {
                      setLocation({
                        ...location,
                        subWarehouse: data,
                      })
                    }}
                    onCreateOption={value => {
                      setNewCategorization({
                        ...newCategorization,
                        parentCategorizationId: subWarehouses.id,
                        show: true,
                        name: value,
                        locationType: 1,
                      })
                    }}
                    isLoading={loadingLocations}
                    disabled={loadingLocations}
                    options={
                      subWarehouses?.children?.map(subBodega => ({
                        label: subBodega.name,
                        name: subBodega.name,
                        value: subBodega.id,
                        children: subBodega.children,
                        description: subBodega.description,
                      })) || []
                    }
                    value={location.subWarehouse}
                    placeholder={'Buscar o seleccionar'}
                    textLabel={`Nuevo ${nameLocations[0]}: `}
                    required
                    label={nameLocations[0]}
                  />
                </Col>
                <Col xl={1} lg={1} md={1} sm={1} xs={1} style={{ marginTop: '45px' }}>
                  {getActions(
                    location.subWarehouse?.value,
                    subWarehouses.id,
                    location.subWarehouse,
                  )}
                </Col>
              </Row>
            </Col>

            {(locationQuantity.value === 2 || locationQuantity.value === 3) && (
              <>
                <Col xl={11} lg={11} md={11} sm={11} xs={11}>
                  <CustomCreate
                    loading={loadingLocations}
                    onChange={data => {
                      setLocation({ ...location, shelf: data })
                    }}
                    onCreateOption={value => {
                      setNewCategorization({
                        ...newCategorization,
                        parentCategorizationId: shelves.id,
                        show: true,
                        name: value,
                        locationType: 2,
                      })
                    }}
                    isLoading={loadingLocations}
                    disabled={loadingLocations || location.subWarehouse === null}
                    options={
                      shelves?.children?.map(subBodega => ({
                        label: subBodega.name,
                        name: subBodega.name,
                        value: subBodega.id,
                        children: subBodega.children,
                        description: subBodega.description,
                      })) || []
                    }
                    value={location.shelf}
                    placeholder={'Buscar o seleccionar'}
                    textLabel={`Nuevo ${nameLocations[1]}: `}
                    required
                    label={nameLocations[1]}
                  />
                </Col>

                <Col xl={1} lg={1} md={1} sm={1} xs={1} style={{ marginTop: '45px' }}>
                  {getActions(location.shelf?.value, shelves.id, location.shelf)}
                </Col>
              </>
            )}

            {locationQuantity.value === 3 && (
              <>
                <Col xl={11} lg={11} md={11} sm={11} xs={11}>
                  <CustomCreate
                    loading={loadingLocations}
                    onChange={data => {
                      setLocation({ ...location, level: data })
                    }}
                    onCreateOption={value => {
                      setNewCategorization({
                        ...newCategorization,
                        parentCategorizationId: levels.id,
                        show: true,
                        name: value,
                        locationType: 3,
                      })
                    }}
                    isLoading={loadingLocations}
                    disabled={loadingLocations || location.shelf === null}
                    options={
                      levels?.children?.map(subBodega => ({
                        label: subBodega.name,
                        name: subBodega.name,
                        value: subBodega.id,
                        children: subBodega.children,
                        description: subBodega.description,
                      })) || []
                    }
                    value={location.level}
                    placeholder={'Buscar o seleccionar'}
                    textLabel={`Nuevo ${nameLocations[2]}: `}
                    required
                    label={nameLocations[2]}
                  />
                </Col>
                <Col xl={1} lg={1} md={1} sm={1} xs={1} style={{ marginTop: '45px' }}>
                  {getActions(location.level?.value, levels.id, location.level)}
                </Col>
              </>
            )}
            {!modeSelection && (
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <NumberField
                  label={'Cantidad'}
                  value={location.quantity || 0}
                  onValueChange={value => {
                    setLocation({ ...location, quantity: value })
                  }}
                  max={value.maxQuantity}
                  decimals={location.decimals}
                />
              </Col>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            {modeSelection && (
              <Button
                loading={loadingLocations}
                disabled={loadingLocations}
                icon={faLocationArrow}
                onClick={() => {
                  const response = {
                    quantity: location.quantity,
                    listLocations: [],
                    ...location,
                  }
                  onConfirm(response)
                  hide()
                }}>
                {'Sin ubicación'}
              </Button>
            )}
            <Button
              loading={loadingLocations}
              disabled={loadingLocations || (location.quantity === 0 && !modeSelection)}
              icon={faSave}
              onClick={() => {
                const listLocations = []
                if (location.subWarehouse !== null)
                  listLocations.push(location.subWarehouse)
                if (location.shelf !== null) listLocations.push(location.shelf)
                if (location.level !== null) listLocations.push(location.level)

                const response = {
                  quantity: location.quantity,
                  listLocations,
                  ...location,
                }
                if (location.edit) onSaveEdit(response)
                else onConfirm(response)
                hide()
              }}>
              {'Guardar'}
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
      {/*****************************************************
       ***********************MODAL CREATE***********************
       * ****************************************************/}
      <Modal
        show={newCategorization.show}
        size={'md'}
        centered
        onHide={() => {
          setNewCategorization({})
        }}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Crear Ubicacion</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <FormTextField
                id={'name'}
                label={'Nombre'}
                placeholder={'Nombre'}
                type={'text'}
                name={'name'}
                value={newCategorization.name}
                onChange={e => {
                  setErrors({})
                  setNewCategorization({ ...newCategorization, name: e.target.value })
                }}
                required
                error={errors.name}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <FormTextField
                id={'description'}
                label={'Descripción'}
                placeholder={'Descripción'}
                type={'text'}
                name={'description'}
                value={newCategorization.description}
                onChange={e => {
                  setErrors({})
                  setNewCategorization({
                    ...newCategorization,
                    description: e.target.value,
                  })
                }}
                required
                error={errors.description}
              />
            </Col>
            {!newCategorization.edit && (
              <Col xs={4} sm={4} md={4} lg={4}>
                <FormTextField
                  id={'color'}
                  label={'Color'}
                  type={'color'}
                  name={'color'}
                  value={newCategorization.color}
                  onChange={e => {
                    setNewCategorization({ ...newCategorization, color: e.target.value })
                  }}
                />
              </Col>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              if (!newCategorization.name) {
                errors.name = 'El nombre es requerido'
              }
              if (!newCategorization.description) {
                errors.description = 'La descripción es requerida'
              }
              setErrors({ ...errors })
              if (Object.keys(errors).length === 0) {
                if (!newCategorization.edit) {
                  dispatch(createCategorization({ ...newCategorization }))
                } else
                  dispatch(
                    updateCategorization(
                      newCategorization.id,
                      { ...newCategorization },
                      null,
                    ),
                  )
                setNewCategorization({
                  show: false,
                  type: newCategorization.type,
                  locationType: newCategorization.locationType,
                })
              }
            }}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
      <Alert {...alert} />
    </>
  )
}
