import React from 'react'

import { Col, Modal, Row } from 'react-bootstrap'
import Icon from 'src/components/buttons/IconButton'
import Paragraph from 'src/components/Paragraph/Paragraph'
import { faEye } from '@fortawesome/free-solid-svg-icons'

interface IProps {
  show: boolean
  canSeeInventory: boolean
  series?: IPurchaseDetailSerie[]
  itemName?: string
  onHide: () => void
  toSeeInventory: (serie: string) => void
}

/** Show purchase item series
 * @param {boolean} show indicates whether the detail modal should be shown
 * @param {boolean} canSeeInventory Indicate if the user can see inventory
 * @param {IPurchaseDetailSerie[]} series List of series to see
 * @param {string} itemName Item Name
 * @param {function} onHide Close component
 * @param {function} toSeeInventory Function to see inventory
 * **/
const PurchaseDetailItemSeries: React.FC<IProps> = ({
  show,
  canSeeInventory,
  series = [],
  itemName,
  onHide,
  toSeeInventory,
}) => {
  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{itemName} series</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'custom-modal-body'}>
        <Row>
          {series.map((s, index) => (
            <Col xl={12} key={s.id}>
              <div className={'d-flex'}>
                <div
                  style={{ width: canSeeInventory ? '93%' : '100%' }}
                  className={'column'}>
                  <Paragraph>Número de serie unidad No. {++index}</Paragraph>
                  <Paragraph bold>{s.serie}</Paragraph>
                </div>
                {canSeeInventory && (
                  <Icon
                    icon={faEye}
                    size={'1_5x'}
                    tooltip={'Ver inventario'}
                    onClick={() => toSeeInventory(s.serie)}
                    dataCy={'iconInventory'}
                  />
                )}
              </div>
              <hr style={{ width: '100%' }} />
            </Col>
          ))}
        </Row>
      </Modal.Body>
    </Modal>
  )
}
export default PurchaseDetailItemSeries
