import * as React from 'react'
import {
  Col,
  Modal,
  Row,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Alert,
} from 'react-bootstrap'

import '../Subscriptions.scss'

class ModalSelectPeriodicity extends React.Component {
  state = {
    interval: {
      value: 1,
      label: 'Semanalmente',
      singular: 'semana',
      plural: 'semanas',
    },
    step: 1,
    times: 1,
  }

  selectPeriodicity = () => {
    const { step, interval, times } = this.state
    let periodicity = {
      step,
      interval,
      times,
    }
    this.props.onSelectPeriodicity(periodicity)
  }

  onChangeTimes = value => {
    if (value > 0) {
      this.setState({ times: value })
    }
  }

  handleTimes = value => {
    switch (value) {
      case 1:
      case 4:
        this.setState({ times: value, step: 1 })
        break
      case 2:
        this.setState({ times: value, step: 2 })
        break
      default:
        break
    }
  }

  render() {
    const { times } = this.state

    return (
      <Modal
        className={'sub-style'}
        dialogClassName="my-modal-subscription-periodicity"
        centered
        show={this.props.show}
        onHide={this.props.onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>Selección de periodicidad</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div>
                <h6>Cuantas entregas quieres:</h6>
                <ToggleButtonGroup
                  type="radio"
                  name="options"
                  value={times}
                  onChange={this.handleTimes}
                  style={{ width: '100%' }}
                >
                  <ToggleButton className="Btn-active" variant={'secondary'} value={1}>
                    1
                  </ToggleButton>
                  <ToggleButton className="Btn-active" variant={'secondary'} value={2}>
                    2
                  </ToggleButton>
                  <ToggleButton className="Btn-active" variant={'secondary'} value={4}>
                    4
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>

              <Alert style={{ marginTop: 16, backgroundColor: '#d1ebf0' }}>
                <Alert.Heading style={{ color: '#0c5460' }}>Detalle</Alert.Heading>
                <ul>
                  <li>
                    <h6 style={{ color: '#0c5460' }}>1: Entrega del día.</h6>
                  </li>
                  <li>
                    <h6 style={{ color: '#0c5460' }}>2: Entregas cada 15 días.</h6>
                  </li>
                  <li>
                    <h6 style={{ color: '#0c5460' }}>4: Entregas cada semana.</h6>
                  </li>
                </ul>
              </Alert>
            </Col>
          </Row>

          <Row className={'container-buttons'}>
            <Button variant={'danger'} onClick={() => this.selectPeriodicity()}>
              Seleccionar
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    )
  }
}

export default ModalSelectPeriodicity
