import { actionTypes } from '../actions/disbursements.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  disbursements: [],
  disbursement: {},
  disbursementsBalance: 0.0,
  disbursementsBalanceCotizap: 0.0,
  getDisbursementsDetails: [],
  disbursementsAdmin: [],
  csvResponse: {},
  requests: [],
  requestsTotal: 0,
}

const DisbursementReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_DISBURSEMENTS_REQUEST): {
      const response = extract(action.response, {})
      return {
        ...state,
        requests: response.requests || [],
        requestsTotal: response.count || 0,
      }
    }
    case successState(actionTypes.GET_DISBURSEMENTS_ADMIN): {
      const r = extract(action.disbursementsAdmin, {})

      return {
        ...state,
        disbursementsAdmin: r.requests || [],
      }
    }
    case successState(actionTypes.GET_DISBURSEMENTS):
      return {
        ...state,
        disbursements: extract(action.disbursements, []),
      }

    case successState(actionTypes.GET_DISBURSEMENTS_BALANCE):
      return {
        ...state,
        disbursementsBalance: extract(action.disbursementsBalance, 0.0),
      }
    case successState(actionTypes.GET_DISBURSEMENTS_BALANCE_COTIZAP):
      return {
        ...state,
        disbursementsBalanceCotizap: extract(action.disbursementsBalanceCotizap, 0.0),
      }
    case successState(actionTypes.GET_DISBURSEMENTS_BALANCE_DETAILS):
      return {
        ...state,
        disbursementsDetails: extract(action.disbursementsDetails, []),
      }
    case successState(actionTypes.GET_SINGLE_DISBURSEMENT):
      return {
        ...state,
        disbursement: extract(action.disbursement, []),
      }
    case successState(actionTypes.ON_CSV):
      return {
        ...state,
        csvResponse: action.csv,
      }
    default:
      return state ? state : initialState
  }
}
export default DisbursementReducer
