export const orderDispatchType = {
  confirmWithoutInventory: 1,
  confirmWithFullDispatch: 2,
  confirmWithPartialDispatch: 3,
  confirmWithPendingDispatch: 4,
}

export const orderDispatchStatus = {
  1: 'Sin despacho',
  2: 'Pendiente',
  3: 'Despachado',
}
