import "./OfferCreate.scss"
import React, {Component} from 'react';
import {connect} from "react-redux";

import Card from "../../../../components/cards/Card";
import Button from "../../../../components/buttons/Button";
import Icon from "../../../../components/buttons/IconButton";
import FormTextField from "../../../../components/inputs/FormTextField/FormTextField";
import {TooltipRail, Handle, Track} from "../../../../components/slider/SliderComponents";

import {formatNumberWithCommas} from "../../../../utils/formatters";

import {Row, Col, Modal} from "react-bootstrap";
import {Td, Tr} from "react-super-responsive-table";
import {Slider, Rail, Handles, Tracks} from "react-compound-slider";

import {
    faCheck, faChevronRight, faPlusCircle,
    faWindowClose,
    faLongArrowAltLeft,
    faLongArrowAltRight, faTimes,
    faEdit, faTrash, faHighlighter,
    faPlus, faEye, faTrashAlt, faArrowRight, faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import Alert from "sweetalert-react";

import {setInformationToOffer} from "../../../../actions/offers.actions";
import {selectOffer} from "../../../../selectors/offers.selector";

import {
    selectTransportProducts,
    selectOwnProducts
} from "../../../../selectors/products.selector";

// import {selectCurrentCompany} from "../../../../selectors/company.selector";
import {selectCurrentCompany} from "../../../../selectors/user.selector";

import {handlerError} from "../../../../selectors/error.selector";
import CollapsibleCard
    from "../../../../components/cards/collapsible-card/CollapsibleCard";
import Money from "../../../../components/Money/Money";
import { TableV2 } from "../../../../components";

const sliderStyle = {
    position: 'relative',
    width: '100%',
}

class OfferProducts extends Component {

    state = {
        va: {},
        alert: {title: 'title'},
        productDetails: {
            show: false,
            amounts: [],
            product: {},
            transportationPayments: []
        },
        details: {
            show: false,
            amounts: [],
            polygon: {}
        },
        domain: [1, 2],
        values: [0],
        update: [0],
        disabled: false,
        price: {minimum: 0, maximum: 0, price: 0}
    };

    filter = (item) => {
        const {offer, company} = this.props;
        const product = offer.productList.find((p) => p.productId === item.productId);

        let activeLink = false;
        let own = false;

        if (offer.category.value === 1) {
            own = item.product.companyId === company;
            activeLink = item.product.activeLink;
        } else {
            own = item.companyId === company;
            activeLink = item.activeLink;
        }
        return !product && own && activeLink;
    };

    addProduct = (item) => {
        let {offer, setInfo} = this.props;
        offer.productList.push({
            ...item,
            productName: item.productName
        });
        this.setState({va: offer});
        setInfo(offer);
    };

    deleteItem = (item, index, ignore) => {
        let {alert} = this.state;
        if (!ignore) {
            alert = {
                show: true,
                title: '¡Cuidado!',
                text: '¿Desea elimnar este producto de la oferta?',
                type: 'warning',
                showCancelButton: true,
                onCancel: () => this.setState({alert: {...alert, show: false}}),
                onConfirm: () => {
                    let {offer, setInfo} = this.props;
                    offer.productList.splice(index, 1);
                    this.setState({alert: {...alert, show: false}});
                    setInfo(offer);
                }
            };
            this.setState({alert});
        } else {
            let {offer, setInfo} = this.props;
            offer.productList.splice(index, 1);
            setInfo(offer);
            this.setState({alert: {title: 'i'}})
        }
    };

    seePricesDetails = (item) => {
        const details = Object.assign({}, {...item, show: true});

        const length = details.amounts.length;
        let disabled = false;
        let values = [1];
        let update = [1]
        let domain = [1, length];
        if (length === 1) {
            disabled = true
            domain = [0, length]
        };
        const price = details.amounts[0];
        this.setState({details, domain, disabled, values, update, price});

    };

    onChange = (values) => {
        const {details} = this.state;
        const index = (values[0] - 1);
        const price = details.amounts[index];
        this.setState({values, price});
    };

    onUpdate = (update) => {
        const {details} = this.state;
        const index = (update[0] - 1);
        const price = details.amounts[index];
        this.setState({update, price});
    };

    addAll = (items) => {
        items.map((i) => this.addProduct(i));
        // this.addProduct(item)
    };

    removeAll = () => {
        const {offer, setInfo} = this.props;
        offer.productList = Object.assign([], []);
        setInfo({...offer});
        this.setState({alert: {title: 'd'}});
    };

    getHeaders = (deleted) => {
        const {category} = this.props.offer;
        return [
            {label: 'Código', show: true, value: ['productCode'], type: 'text' ,  className:'medium'},
            {label: 'Producto', show: true, value: ['productName'], type: 'text' ,  className:'medium'},
            {label: 'Precio unitario', show: true, value: ['productPrice'], type: 'text' ,  className:'medium'},
            {config:true, show: true, label:'', className:'mini center', custom:(item, index)=> 
            <div style={{display: 'flex', justifyContent: 'space-around'}}>
                        {category.value === 1 && <Icon icon={faEye} tooltip={'Detalles'} onClick={() => this.setState({productDetails: {...item, show: true}})} style={{marginRight: 4}}/>}
                        <Icon
                            icon={!deleted ? faChevronRight : faTrashAlt}
                            tooltip={!deleted ? 'Agregar' : 'Descartar'}
                            onClick={() => !deleted ? this.addProduct(item) : this.deleteItem(item, index)}/>
                    </div>}

        ]
    }

    render() {

        const {offer, productsT, products} = this.props;
        const {productDetails, details, domain, values, disabled, price} = this.state;
        const {show, product, transportationPayments} = productDetails;
        const {category} = this.props.offer;

        let items = [];
        if (offer.category.value === 1) {
            items = products.map((p) => ({...p, productCode: p.product.code, productName: p.product.name, productPrice: p.product.price}));
        } else items = productsT;

        return (
            <div>
                <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Card title={'Producto a escoger'}>
                            <Row className={'container-buttons'}>
                                <Button onClick={() => this.addAll(items.filter(this.filter))} disabled={items.filter(this.filter).length === 0} variant={'info'} icon={faArrowRight} right>Agregar todos </Button>
                            </Row>
                            <TableV2
                                items={items.filter(this.filter)}
                                mobileAuto
                                storageKey={`productToChoose`}
                                headers={this.getHeaders(false)}
                            />
                        </Card>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Card title={'Producto escogido'}>
                            <div className={'left'}>
                                <Button onClick={() => this.removeAll()} disabled={offer.productList.length === 0} variant={'info'} icon={faArrowLeft} left> Remover todos</Button>
                            </div>
                            <TableV2
                                headers={this.getHeaders(true)}
                                storageKey={`chooseProduct`}
                                items={offer.productList}
                                mobileAuto
                            />
                        </Card>
                    </Col>
                </Row>

                <Row className={'container-buttons'}>
                    <Button
                        onClick={() => {
                            if (category.value === 2) {
                                this.props.jumpToStep(1);
                            } else this.props.jumpToStep(0);
                        }
                        } icon={faLongArrowAltLeft}>
                        Atras
                    </Button>
                    <Button
                        disabled={offer.productList.length <= 0}
                        onClick={() => {
                            if (category.value != 1) {
                                this.props.jumpToStep(3);
                            } else this.props.jumpToStep(2);
                        }
                        } icon={faLongArrowAltRight}>
                        siguiente
                    </Button>
                </Row>

                <Modal
                    show={show}
                    size={'lg'}
                    centered
                    onHide={() => this.setState({productDetails: {...productDetails, show: false}})}>

                    <Modal.Header closeButton>
                        <Modal.Title>{product.name || ''} Zonas de trasnporte</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <TableV2
                            headers = {[
                                {label: '#', show: true, value: ['polygon','code'], type: 'text' , className:'mini'},
                                {label: 'Localidad', show: true, value: ['polygon','name'], type: 'text' ,  className:'mini'},
                                {config:true, show: true, label:'', className:'mini center',
                                    custom:(item,i)=> <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                    <Icon icon={faEye} tooltip={'Detalles'} onClick={() => this.seePricesDetails(i)} style={{marginRight: 4}}/>
                                </div>}
                            ]}
                            items={transportationPayments}
                            mobileAuto
                            storageKey={`offerProductsZone`}
                        />
                        <Modal
                            show={details.show}
                            size={'lg'}
                            centered
                            onHide={() => this.setState({details: {...details, show: false}})}>

                            <Modal.Header closeButton>
                                <Modal.Title>{product.name || ''}: {details.polygon ? details.polygon.name : ''}</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <div style={{margin: 3}} className={'column'}>
                                    <br/><br/>
                                    <Slider
                                        disabled={disabled}
                                        step={1}
                                        domain={domain}
                                        rootStyle={sliderStyle}
                                        onUpdate={this.onUpdate}
                                        onChange={this.onChange}
                                        values={values}
                                    >
                                        <Rail>{railProps => <TooltipRail {...railProps} />}</Rail>
                                        <Handles>
                                            {({handles, activeHandleID, getHandleProps}) => (
                                                <div className="slider-handles">
                                                    {handles.map(handle => (
                                                        <Handle
                                                            key={handle.id}
                                                            disabled={disabled}
                                                            handle={handle}
                                                            domain={domain}
                                                            isActive={handle.id === activeHandleID}
                                                            getHandleProps={getHandleProps}
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                        </Handles>
                                        <Tracks right={false}>
                                            {({tracks, getTrackProps}) => (
                                                <div className="slider-tracks">
                                                    {tracks.map(({id, source, target}) => (
                                                        <Track
                                                            key={id}
                                                            disabled={disabled}
                                                            source={source}
                                                            target={target}
                                                            getTrackProps={getTrackProps}
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                        </Tracks>
                                    </Slider>
                                    <br/><br/>

                                    <div className={'container-offer column'}>
                                        <Row>
                                            <Col xl={7} lg={7} md={7} sm={7}
                                                 xs={8}>
                                                <div
                                                    className={'container-offer'}>
                                                    <div
                                                        className={'center-offer'}>
                                                        <span><strong>Unidades</strong></span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={5} lg={5} md={5} sm={5}
                                                 xs={4}>
                                                <div
                                                    className={'container-offer'}>
                                                    <div
                                                        className={'center-offer'}>
                                                        <span><strong>Pagos</strong></span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={7} lg={7} md={7} sm={7}
                                                 xs={8}>
                                                <div
                                                    className={'container-offer'}>
                                                    <div
                                                        className={'flex-end-offer'}>
                                                        <div>
                                                            <FormTextField
                                                                disabled
                                                                mt={0}
                                                                type={'number'}
                                                                name={'minimum'}
                                                                onChange={() => console.log('value')}
                                                                value={price.minimum}
                                                            />
                                                        </div>
                                                        <div className={'mt-4'}>
                                                            <strong>{'<'}=={'>'}</strong>
                                                        </div>
                                                        <div>
                                                            <FormTextField
                                                                disabled
                                                                mt={0}
                                                                type={'number'}
                                                                name={'maximum'}
                                                                onChange={() => console.log('value')}
                                                                value={price.maximum}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={5} lg={5} md={5} sm={5}
                                                 xs={4}>
                                                <div
                                                    className={'container-offer'}>
                                                    <div
                                                        className={'flex-start-offer'}>
                                                        <div>
                                                            <FormTextField
                                                                disabled
                                                                mt={0}
                                                                id={'price'}
                                                                currency
                                                                name={'price'}
                                                                onChange={() => console.log('value')}
                                                                value={price.amount}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <br/><br/>
                                <CollapsibleCard title={'Mostrar lista'}
                                                 startCollapsed={true}>
                                    {
                                        details.amounts.map((p) =>
                                            <Row key={p.id}>
                                                <Col xl={7} lg={7} md={7} sm={7}
                                                     xs={8}>
                                                    <div
                                                        className={'container-offer'}>
                                                        <div
                                                            className={'flex-end-offer'}>
                                                            <div>
                                                                <FormTextField
                                                                    disabled
                                                                    mt={0}
                                                                    type={'number'}
                                                                    name={'minimum'}
                                                                    onChange={() => console.log('value')}
                                                                    value={p.minimum}
                                                                />
                                                            </div>
                                                            <div
                                                                className={'mt-4'}>
                                                                <strong>{'<'}=={'>'}</strong>
                                                            </div>
                                                            <div>
                                                                <FormTextField
                                                                    disabled
                                                                    mt={0}
                                                                    type={'number'}
                                                                    name={'maximum'}
                                                                    onChange={() => console.log('value')}
                                                                    value={p.maximum}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xl={5} lg={5} md={5} sm={5}
                                                     xs={4}>
                                                    <div
                                                        className={'container-offer'}>
                                                        <div
                                                            className={'flex-start-offer'}>
                                                            <div>
                                                                <FormTextField
                                                                    disabled
                                                                    mt={0}
                                                                    id={'price'}
                                                                    currency
                                                                    name={'price'}
                                                                    onChange={() => console.log('value')}
                                                                    value={p.amount || 0.00}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                </CollapsibleCard>

                            </Modal.Body>
                        </Modal>
                    </Modal.Body>

                </Modal>

                <Alert {...this.state.alert}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    offer: selectOffer(state),
    products: selectTransportProducts(state),
    productsT: selectOwnProducts(state),
    company: selectCurrentCompany(state)
});
const mapDispatchToProps = dispatch => ({
    setInfo: (offer) => dispatch(setInformationToOffer(offer))
});
export default connect(mapStateToProps, mapDispatchToProps)(OfferProducts);
