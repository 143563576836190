import { $http } from './http'

class BalanceService {
  getGeneralBalance = async () => {
    const response = await $http.get(`/balance/company`)
    return response.data
  }

  getBalanceDetails = async () => {
    const response = await $http.get(`/balance/details`)
    return response.data
  }

  getBalanceDetailsCompany = async idCompany => {
    const response = await $http.get(`/balance/details/${idCompany}`)
    return response.data
  }

  getBalanceDocuments = async (sDate, eDate) => {
    let params = ''
    if (sDate) params += `sDate=${sDate}`
    if (eDate) params += (eDate ? '&' : '').concat(`eDate=${eDate}`)
    const response = await $http.get(
      `/balance/documents?`.concat(params.length > 0 ? params : ''),
    )
    return response.data
  }

  getBalanceEntryTypes = async () => {
    const response = await $http.get(`/balance/entries/types`)
    return response.data
  }

  getBalanceEntries = async (type, params) => {
    const response = await $http.get(`/balance/entries/${type}`, { params })
    return response.data
  }

  getBalanceEntriesCompany = async (type, params) => {
    const response = await $http.get(`/balance/entries/company/${type}`, {
      params,
    })
    return response.data
  }

  getBalanceEntriesUserInCompany = async (user, type) => {
    const response = await $http.get(`/balance/entries/company/user/${user}/${type}`)
    return response.data
  }

  getBalanceInCompany = async params => {
    const response = await $http.get(`/balance/company`, { params })
    return response.data
  }

  getBalanceInCompanyWithKolo = async () => {
    const response = await $http.get(`/balance/kolo`)
    return response.data
  }

  resetBalanceCompany = async id => {
    const response = await $http.post(`/balance/reset/${id}`)
    return response.data
  }

  getBalanceUsers = async () => {
    const response = await $http.get(`/balance/users`)
    return response.data
  }

  createUserBalanceEntry = async (user, debit, amount, message) => {
    let param = ''
    if (message) {
      param = '&message=' + encodeURIComponent(message)
    }
    const response = await $http.post(
      `/balance/admin/entry/${user}?debit=${debit}${param}`,
      amount,
    )
    return response.data
  }

  getCompanyBalances = async () => {
    const response = await $http.get(`/balance/admin/kolo`)
    return response.data
  }

  addAdminUserCompanyBalanceEntry = async (user, company, amount, type, module) => {
    const response = await $http.post(`/balance/cotizap/entry/${module}`, {
      createdBy: user,
      company: company,
      amount: amount,
      type: type,
    })
    return response.data
  }

  onSetMaxCpcInUserBalance = async (userId, maxCpc) => {
    const response = await $http.put(`/balance/company/cpc/${userId}`, maxCpc)
    return response.data
  }

  getKoloCompanyBalance = async () => {
    const response = await $http.get('/balance/admin/ckolo')
    return response.data
  }

  getKoloBalanceByCompanyAndModule = async (companyId, moduleId, params) => {
    const response = await $http.get(`/balance/admin/ckolo/${companyId}/${moduleId}`, {
      params,
    })
    return response.data
  }

  getCompanyBalanceByType = async params => {
    const response = await $http.get('/balance/company/balance/by/type', {
      params,
    })
    return response.data
  }

  getCompanyBalanceReport = async params => {
    const response = await $http.get('/balance/company/report', { params })
    return response.data
  }

  getChildrenByParent = async id => {
    const response = await $http.get(`/balance/company/report/children/${id}`)
    return response.data
  }

  getCashTransfers = async params => {
    const response = await $http.get(`/balance/transfer`, { params })
    return response.data
  }

  createCashTransfer = async request => {
    const response = await $http.post(`/balance/transfer`, request)
    return response.data
  }

  approveCashTransfer = async id => {
    const response = await $http.put(`/balance/transfer/${id}`)
    return response.data
  }

  cancelCashTransfer = async id => {
    const response = await $http.delete(`/balance/transfer/${id}`)
    return response.data
  }
}

export default new BalanceService()
