import React, {Component} from 'react';
import {connect} from "react-redux";

import {Td, Tr} from "react-super-responsive-table";
import {Col, Row} from "react-bootstrap";
import {faBook, faMoneyCheck} from "@fortawesome/free-solid-svg-icons";

import Card from "../../../components/cards/Card";
import MapCard from "../../../components/maps/MapCard";
import Icon from "../../../components/buttons/IconButton";

import {actionTypes as typssP, getTransportationPayments} from "../../../actions/products.actions";
import {selectTransportPayments} from "../../../selectors/products.selector";

import {loadingSelector} from "../../../selectors/loading.selector";
import { TableV2 } from '../../../components';

const latitude = 14.589465440976774;
const longitude = -90.51898866891861;

class Transport extends Component {

    state = {
        mapS: null,
        mapsS: null,
        manualPolygon: null,
        selected: -1
    };

    componentDidMount() {
        const {match, getTransportationPayments} = this.props;
        if (match.params.id) getTransportationPayments(match.params.id);
    };

    onGoogleApiLoaded = (map, maps) => {
        this.setState({mapS: map, mapsS: maps});
    };

    setPolygon = (polygon, index) => {
        let {mapS, mapsS, manualPolygon, selected} = this.state;

        if (index != selected && polygon.id) {
            this.setState({selected: index});
            if (manualPolygon) manualPolygon.setMap(null);
            if (mapS, mapsS) {
                const points = polygon.pointList.map((p) => ({lat: Number(p.latitude), lng: Number(p.longitude)}));
                manualPolygon = new mapsS.Polygon({
                    path: points,
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#FF0000",
                    fillOpacity: 0.35
                });

                const center = this.getCenter(points);
                if (center.latLng.lat > 0) mapS.panTo(center.latLng);
                manualPolygon.setMap(mapS);
                this.setState({manualPolygon});
            }
        } else {
            manualPolygon.setMap(null);
            this.setState({manualPolygon, selected: -1});
        }
    };

    getCenter = (paths) => {
        const center = paths.map(p => {
            return [p.lat, p.lng]
        }).reduce((x, y) => {
            return [x[0] + y[0] / paths.length, x[1] + y[1] / paths.length]
        }, [0, 0])
        return {latLng: {lat: center[0], lng: center[1]}};
    };

    goTo = () => {
        const {history, match} = this.props;
        const url = match.url + '/pagos';
        history.push(url);
    };

    toDetails = (item) => {
        const {history, match} = this.props;
        const url = match.url + `/detalle/${item.id}`;
        history.push(url);
    };

    render() {
        const {loading, transportationPayments} = this.props;

        return (
            <div>
                <h1 className={'dashboard-welcome'}>Zonas de transporte</h1>
                <Row>
                    <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                        <MapCard
                            latitude={latitude}
                            longitude={longitude}
                            height={500}
                            startCollapsed={false}
                            markerList={[]}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => this.onGoogleApiLoaded(map, maps)}
                        />
                    </Col>
                    <Col xl={4} lg={4} md={4} xm={12} xs={12}>
                        <Card title={'Zonas de transporte'} button={<Icon tooltip={'Pago de transporte'} icon={faMoneyCheck} onClick={() => this.goTo()}/>}>
                            <TableV2
                                headers={[
                                    {label: 'Codigo', show: true, value: ['polygon','code'], type: 'text' ,  className:'mini'},
                                    {label: 'Localidad', show: true, value: ['polygon','name'], type: 'text' ,  className:'medium'},
                                    {config:true, show: true, label:'', className:'mini center',
                                        custom:(item) => <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                        <Icon
                                            icon={faBook}
                                            tooltip={'Detalles'}
                                            onClick={() => this.toDetails(item)}
                                            onMouseOver={(e) => e.stopPropagation()}
                                        />
                                    </div>}
                                ]}
                                mobileAuto
                                storageKey={`transportZones`}
                                onClickTr={(e,item, index)=>this.setPolygon(item.polygon, index) }
                                isSelected = {(item, index)=> 
                                    index === this.state.selected
                                }
                                loading={loading}
                                items={transportationPayments}
                            />
                           {/* <Table
                                loading={loading}
                                headers={['Codigo', 'Localidad', '']}
                                items={transportationPayments}
                                renderRow={(item, index) => (
                                    <Tr className={`data ${index === this.state.selected ? 'active' : ''}`} data-item={item} key={index} onClick={() => this.setPolygon(item.polygon, index)}>
                                        <Td className={'mini'}>{item.polygon.code}</Td>
                                        <Td className={'medium'}>{item.polygon.name}</Td>
                                        <Td className={'mini'}>
                                            <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                                <Icon
                                                    icon={faBook}
                                                    tooltip={'Detalles'}
                                                    onClick={() => this.toDetails(item)}
                                                    onMouseOver={(e) => e.stopPropagation()}
                                                />
                                            </div>

                                        </Td>
                                    </Tr>
                                )}
                            /> */}
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    transportationPayments: selectTransportPayments(state),
    loading: loadingSelector([typssP.TRANSPORT_PAYMENT_ALL])(state)
});
const mapDispatchToProps = dispatch => ({
    getTransportationPayments: (productId) => dispatch(getTransportationPayments(productId))
});
export default connect(mapStateToProps, mapDispatchToProps)(Transport);
