import React from 'react'

import { formatNumberWithCommas } from 'src/utils/formatters'

interface BalanceItemProps {
  total?: number
  symbol?: string
}

interface AccountBalanceProps {
  accounts?: IBankAccount[]
  currencies?: any[]
}
const BalanceItem: React.FC<BalanceItemProps> = ({ total, symbol }: BalanceItemProps) => (
  <div>
    <h2 className={'title-general mt-0 mb-0'}>
      Resumen: {symbol + '  ' + formatNumberWithCommas(total)}
    </h2>
  </div>
)
const AccountBalance: React.FC<AccountBalanceProps> = ({ accounts, currencies }) => {
  const totalBalances: { [currencyId: number]: number } = {}

  accounts.forEach(({ currency, balance }) => {
    totalBalances[currency.id] = (totalBalances[currency.id] || 0) + balance
  })

  const result = Object.entries(totalBalances).map(([currencyId, total]) => {
    const currencyInfo = currencies.find(curr => curr.id === parseInt(currencyId))! || 1
    return {
      totalBalance: total,
      currency: currencyInfo.code,
      symbol: currencyInfo.symbol,
    }
  })

  // @ts-ignore
  return (
    <>
      <div
        className="d-flex flex-column"
        id={'account-balance'}
        data-testid={'account-balance'}>
        <ul style={{ listStyle: 'none', paddingLeft: '0px' }}>
          {currencies &&
            result?.map(({ totalBalance, symbol }, index) => (
              <li key={index}>
                <BalanceItem total={totalBalance} symbol={symbol} />
              </li>
            ))}
        </ul>
      </div>
    </>
  )
}

export default AccountBalance
