import React, { useEffect, useLayoutEffect, useState } from 'react'
import { ButtonGroup, Col, Form, ListGroup, Modal, Row } from 'react-bootstrap'
import { Button, FormText, Select, SwitchV2 } from 'src/components'
import { useDispatch, useSelector } from 'react-redux'
import {
  createDynamicField,
  createDynamicFieldWithSelectorOptionsBulk,
  createFromEditDynamicFieldWithSelectorOptionsBulk,
  deleteDynamicFieldWithSelectorOptionsBulk,
  editDynamicField,
  editDynamicFieldWithSelectorOptionsBulk,
  fetchDynamicFieldsOptions,
} from 'src/actions/dynamicfield.actions'
import { fetchDynamicFieldsOptionsSelector } from 'src/selectors/dynamicFields.selector'
import { showAlert } from 'src/actions/alert.actions'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import { clientsOptions, dataTypeOptions } from 'src/enums/dynamicFieldsEnum'
import IconButton from 'src/components/buttons/IconButton'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { loadingSelector } from 'src/selectors/loading.selector'
import { actionTypes as typeT } from 'src/actions/dynamicfield.actions'
import {
  compareArrays,
  compareChanges,
} from 'src/utils/dynamicFieldsHelpers/dynamicFieldsHelpers'

interface CreateDynamicFieldProps {
  onHide: () => void
  mode: 'create' | 'edit'
  item?: DynamicField
  idDynamicField?: number
}

type Option = {
  value: number
  label: string
}

const CreateDynamicField: React.FC<CreateDynamicFieldProps> = ({
  onHide,
  mode,
  item,
}: CreateDynamicFieldProps) => {
  const dispatch = useDispatch()

  const optionsFields: OptionsSelector[] = useSelector(fetchDynamicFieldsOptionsSelector)

  const upddatedFieldLoading = useSelector(state =>
    loadingSelector([typeT.UPDATE_FIELD])(state),
  )
  const createdFieldLoading = useSelector(state =>
    loadingSelector([typeT.CREATE_FIELD])(state),
  )

  const createFieldError = useSelector(state => hasErrors([typeT.CREATE_FIELD])(state))

  const createdFieldLoadingWithOptions = useSelector(state =>
    loadingSelector([typeT.CREATE_FIELD_WITH_OPTIONS])(state),
  )
  const loadingDynamicFieldsSelectOptions = useSelector(state =>
    loadingSelector([typeT.GET_FIELDS_OPTIONS])(state),
  )

  const editedFieldLoadingWithOptions = useSelector(state =>
    loadingSelector([typeT.EDIT_FIELD_WITH_OPTIONS])(state),
  )
  const deletedOptionsLoading = useSelector(state =>
    loadingSelector([typeT.DELETE_OPTIONS])(state),
  )
  const editFromCreatedFieldLoadingWithOptions = useSelector(state =>
    loadingSelector([typeT.CREATE_FIELD_FROM_EDIT_WITH_OPTIONS])(state),
  )

  const [fieldData, setFieldData] = useState<DynamicField>({
    name: '',
    label: '',
    required: false,
  })

  const [showOptions, setShowOptions] = useState(false)
  const [inputValues, setInputValues] = useState<string[]>([''])
  const [elements, setElements] = useState([])
  const [elementsId, setElementsId] = useState<OptionsSelector[]>([])
  const [actions, setAcitons] = useState({ created: false, edited: false })
  const [elementToDelete, setElementsToDelete] = useState([])

  const indexForDataType =
    mode === 'edit'
      ? dataTypeOptions.findIndex(option => option.id === fieldData?.dataType)
      : 0
  const indexForEntityType =
    mode === 'edit'
      ? clientsOptions.findIndex(option => option.id === Number(fieldData?.entityType))
      : 0

  const clientOptionsSelect: Option[] = clientsOptions.map(opt => ({
    value: opt.id,
    label: opt.label,
  }))

  const dataTypeOptionsSelect: Option[] = dataTypeOptions.map(opt => ({
    value: opt.id,
    label: opt.label,
  }))
  useEffect(() => {
    if (mode === 'edit') {
      if (fieldData.dataType === 2)
        setElementsId(prevOptions => [...prevOptions, ...optionsFields])
    }
  }, [optionsFields])

  useEffect(() => {
    if (mode === 'edit') {
      if (item.dataType === 2) {
        dispatch(fetchDynamicFieldsOptions(item.id))
      }
    }
  }, [])

  useEffect(() => {
    if (createdFieldLoading || createdFieldLoadingWithOptions) {
      setAcitons({ ...actions, created: true })
    }

    if (actions.created) {
      if (
        createFieldError !== undefined &&
        createFieldError &&
        createFieldError.message !== ''
      ) {
        dispatch(showAlert(handlerError('Error al crear el campo dinamico')))
      } else {
        onHide()
        setAcitons({ ...actions, created: false })

        dispatch(showAlert(handlerSuccess('Campo Dinamico Creado')))
      }
    }
  }, [createdFieldLoading, createdFieldLoadingWithOptions, createFieldError])

  useEffect(() => {
    if (upddatedFieldLoading) {
      setAcitons({ ...actions, edited: true })
    }
    if (actions.edited) {
      setAcitons({ ...actions, edited: false })
      onHide()
      dispatch(showAlert(handlerSuccess('Cambios  Guardados')))
    }
  }, [upddatedFieldLoading])

  useEffect(() => {
    if (
      editedFieldLoadingWithOptions ||
      deletedOptionsLoading ||
      editFromCreatedFieldLoadingWithOptions
    ) {
      setAcitons({ ...actions, edited: true })
    }
    if (actions.edited) {
      setAcitons({ ...actions, edited: false })
      onHide()
      dispatch(showAlert(handlerSuccess('Cambios  Guardados')))
    }
  }, [
    editedFieldLoadingWithOptions,
    deletedOptionsLoading,
    editFromCreatedFieldLoadingWithOptions,
  ])
  useEffect(() => {
    if (mode === 'edit') {
      setFieldData(item)
    }
  }, [item])
  useEffect(() => {
    if (fieldData.dataType === 2) {
      setShowOptions(true)
    } else {
      setShowOptions(false)
    }
  }, [fieldData])

  useLayoutEffect(() => {
    if (mode === 'create') {
      setElements([
        <>
          <FormText name={'op1'} />
          <IconButton
            className={'align-center'}
            tooltip={'Información'}
            icon={faTrash}
            size={'xl'}
          />
        </>,
      ])
    }
    if (mode === 'edit') {
      setElements([
        <>
          <FormText name={'op1'} />
          <IconButton
            className={'align-center'}
            tooltip={'Información'}
            icon={faTrash}
            size={'xl'}
          />
        </>,
      ])
    }
  }, [mode])

  const editDynamicFieldCurrent = () => {
    if (showOptions) {
      const allNonEmpty = inputValues.every(str => str.trim() !== '')
      const changes = compareArrays(elementsId, optionsFields)

      // @ts-ignore
      const newArray = elementsId.map(({ fieldId, ...rest }) => rest)
      elementsId.sort((a, b) => a.id - b.id)

      if (elementToDelete.length > 0) {
        if (!compareChanges(item, fieldData))
          dispatch(editDynamicField(fieldData, item.id))
        dispatch(deleteDynamicFieldWithSelectorOptionsBulk(item.id, elementToDelete))
      }
      if (inputValues.length > 0 && allNonEmpty) {
        if (!compareChanges(item, fieldData))
          dispatch(editDynamicField(fieldData, item.id))
        dispatch(createFromEditDynamicFieldWithSelectorOptionsBulk(item.id, inputValues))
      }
      if (elementsId.length > 0) {
        if (changes.length > 0) {
          if (!compareChanges(item, fieldData))
            dispatch(editDynamicField(fieldData, item.id))

          dispatch(editDynamicFieldWithSelectorOptionsBulk(item.id, newArray))
        } else {
          onHide()
          dispatch(showAlert(handlerSuccess('Cambios Guardados')))
        }
        if (!compareChanges(item, fieldData))
          dispatch(editDynamicField(fieldData, item.id))
      }
    } else {
      if (item.dataType === 2 && fieldData.dataType !== 2)
        dispatch(
          deleteDynamicFieldWithSelectorOptionsBulk(
            item.id,
            elementsId?.map(obj => obj?.id),
          ),
        )
      dispatch(editDynamicField(fieldData, item.id))
    }
  }

  const handleAdd = () => {
    setElements([...elements, ''])
  }

  const handleDelete = id => {
    setElements(elements.filter((_, i) => i !== id))
    setInputValues(inputValues.filter((_, i) => i !== id))
  }

  const handleFieldData = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { id, value } = e.currentTarget
    setFieldData(prevData => ({
      ...prevData,
      [id]: value,
    }))
  }

  const handleInputChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newInputValues = [...inputValues]
      newInputValues[index] = event.currentTarget.value

      setInputValues(newInputValues)
    }
  const createField = () => {
    if (
      !fieldData?.name?.trim() &&
      !fieldData?.label?.trim() &&
      !fieldData?.description?.trim() &&
      !fieldData?.dataType &&
      !fieldData?.entityType?.trim()
    ) {
      dispatch(showAlert(handlerError('Ingrese Los Campos Requeridos')))
    } else {
      if (showOptions) {
        dispatch(createDynamicFieldWithSelectorOptionsBulk(fieldData, inputValues))
      } else {
        dispatch(createDynamicField(fieldData))
      }
    }
  }

  const handleRemoveInputEdit = (index: number, item) => {
    setElementsToDelete(prevElements => [...prevElements, item?.id])

    const newElementsIds = elementsId.filter(el => el.id !== item.id)
    setElementsId(newElementsIds)
  }
  const updateItemValue = (id: number, newValue: string) => {
    setElementsId(prevItems =>
      prevItems.map(item => (item.id === id ? { ...item, value: newValue } : item)),
    )
  }

  const renderListSelectElements = () => {
    return (
      <>
        <ListGroup variant={'flush'}>
          {elementsId.filter(
            (obj, index, self) => self.findIndex(o => o.id === obj.id) === index,
          ).length > 0 &&
            elementsId.map((element, index) => (
              <ListGroup.Item key={index} variant={'flush'} style={{ border: 'none' }}>
                <div className={'d-flex flex-row'}>
                  <FormText
                    name={'oped'}
                    value={elementsId[index].value}
                    placeholder={'opcion'}
                    onChange={e => {
                      updateItemValue(elementsId[index].id, e.target.value)
                    }}
                    key={index}
                  />

                  <IconButton
                    className={'ml-5 align-content-center'}
                    tooltip={'Información'}
                    icon={faTrash}
                    size={'xl'}
                    onClick={() => handleRemoveInputEdit(index, elementsId[index])}
                  />
                </div>
              </ListGroup.Item>
            ))}
        </ListGroup>

        <ListGroup variant={'flush'}>
          {elements.map((element, index) => (
            <ListGroup.Item key={index} variant={'flush'} style={{ border: 'none' }}>
              <div className={'d-flex flex-row'}>
                <FormText
                  name={'op'}
                  placeholder={'opcion'}
                  onChange={handleInputChange(index)}
                  key={index}
                />
                <IconButton
                  className={'ml-5 align-content-center'}
                  tooltip={'Información'}
                  icon={faTrash}
                  size={'xl'}
                  onClick={() => handleDelete(index)}
                />
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>

        <ButtonGroup>
          <Button className={'ml-3'} variant={'info'} size={'md'} onClick={handleAdd}>
            Agregar Opcion
          </Button>
        </ButtonGroup>
      </>
    )
  }
  // @ts-ignore

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          {mode === 'create' && (
            <h6 className={'medium mt-2'}> Crear Nuevo Campo Dinamico</h6>
          )}
          {mode === 'edit' && <h6>{`Editar Campo Dinamico ${item.name}`} </h6>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {mode === 'create' && (
          <Form data-testid={'createform'}>
            <Row>
              <Col xl={4} lg={4} md={6} sm={12}>
                <FormText
                  label={'Nombre'}
                  id={'name'}
                  data-testid={'name'}
                  onChange={handleFieldData}
                  placeholder={'Nombre del campo'}
                  required
                />
              </Col>
              <Col xl={4} lg={4} md={6} sm={12}>
                <FormText
                  label={'Descripción'}
                  id={'description'}
                  data-testid={'description'}
                  onChange={handleFieldData}
                  placeholder={'Descripcion del Campo'}
                  required
                />
              </Col>
              <Col xl={4} lg={4} md={6} sm={12}>
                <FormText
                  label={'Etiqueta'}
                  id={'label'}
                  data-testid={'label'}
                  onChange={handleFieldData}
                  required
                />
              </Col>
              <Col xl={4} lg={4} md={6} sm={12}>
                <SwitchV2
                  checked={fieldData.required}
                  className={'mt-4 ml-2'}
                  data-testid={'required'}
                  label={'Requerido'}
                  onChange={() =>
                    setFieldData({ ...fieldData, required: !fieldData.required })
                  }
                  info={'Indica si el campo sera obligatorio'}
                />
              </Col>

              <Col xl={4} lg={4} md={6} sm={12}>
                <Select
                  label={'Tipo de Entidad'}
                  options={clientOptionsSelect}
                  dataCy={'entityType'}
                  id={'entityType'}
                  onChange={data => {
                    setFieldData({ ...fieldData, entityType: data.value })
                  }}
                  required
                />
              </Col>
              <Col xl={4} lg={4} md={6} sm={12}>
                <Select
                  label={'Tipo de Dato'}
                  options={dataTypeOptionsSelect}
                  dataCy={'dataType'}
                  onChange={data => {
                    setFieldData({ ...fieldData, dataType: data.value })
                  }}></Select>{' '}
                {showOptions && (
                  <>
                    <ListGroup variant={'flush'}>
                      {elements.map((element, index) => (
                        <ListGroup.Item
                          key={element.id}
                          variant={'flush'}
                          style={{ border: 'none' }}>
                          <div className={'d-flex flex-row'}>
                            <FormText
                              name={'op'}
                              placeholder={'opcion'}
                              onChange={handleInputChange(index)}
                              key={index}
                            />
                            <IconButton
                              className={'ml-5 align-content-center'}
                              tooltip={'Información'}
                              icon={faTrash}
                              size={'xl'}
                              onClick={() => handleDelete(index)}
                            />
                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                    <ButtonGroup>
                      <Button
                        className={'ml-3'}
                        variant={'info'}
                        size={'md'}
                        onClick={handleAdd}>
                        Agregar Opcion
                      </Button>
                    </ButtonGroup>
                  </>
                )}
              </Col>
            </Row>
          </Form>
        )}

        {mode === 'edit' && (
          <Form data-testid={'editform'}>
            <Row>
              <Col xl={4} lg={4} md={6} sm={12}>
                <FormText
                  label={'Nombre'}
                  id={'name'}
                  type={'text'}
                  onChange={e => {
                    setFieldData({ ...fieldData, name: e.target.value })
                  }}
                  value={fieldData?.name}
                  required
                />
              </Col>
              <Col xl={4} lg={4} md={6} sm={12}>
                <FormText
                  label={'Descripción'}
                  id={'description'}
                  onChange={e => {
                    setFieldData({ ...fieldData, description: e.target.value })
                  }}
                  value={fieldData?.description}
                  required
                />
              </Col>
              <Col xl={4} lg={4} md={6} sm={12}>
                {' '}
                <FormText
                  label={'Etiqueta'}
                  id={'label'}
                  onChange={e => {
                    setFieldData({ ...fieldData, label: e.target.value })
                  }}
                  value={fieldData?.label}
                  required
                />
              </Col>
              <Col xl={4} lg={4} md={6} sm={12}>
                <SwitchV2
                  checked={fieldData.required}
                  className={'mt-4 ml-2'}
                  label={'Requerido'}
                  onChange={() =>
                    setFieldData({ ...fieldData, required: !fieldData.required })
                  }
                  info={'Indica si el campo sera obligatorio'}
                />
              </Col>
              <Col xl={4} lg={4} md={6} sm={12}>
                <Select
                  label={'Tipo de Entidad'}
                  options={clientOptionsSelect}
                  id={'entityType'}
                  value={clientOptionsSelect[indexForEntityType]}
                  onChange={data => {
                    setFieldData({ ...fieldData, entityType: data.value })
                  }}
                  required></Select>{' '}
              </Col>
              <Col xl={4} lg={4} md={6} sm={12}>
                <Select
                  label={'Tipo de Dato'}
                  options={dataTypeOptionsSelect}
                  value={dataTypeOptionsSelect[indexForDataType]}
                  loading={loadingDynamicFieldsSelectOptions}
                  onChange={data => {
                    setFieldData({ ...fieldData, dataType: data.value })
                  }}
                />

                {showOptions && renderListSelectElements()}
              </Col>
            </Row>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        {mode === 'create' && (
          <Row className={'container-buttons'}>
            <Button
              onClick={createField}
              loading={
                showOptions ? createdFieldLoadingWithOptions : createdFieldLoading
              }>
              Crear
            </Button>
          </Row>
        )}
        {mode === 'edit' && (
          <Row className={'container-buttons'}>
            <Button
              onClick={() => editDynamicFieldCurrent()}
              loading={upddatedFieldLoading}>
              Guardar
            </Button>
          </Row>
        )}
      </Modal.Footer>
    </>
  )
}
export default CreateDynamicField
