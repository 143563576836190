import { actionTypes } from '../actions/uploads.actions'
import { successState } from '../actions/global.actions'

const initialState = {
  files: [],
  files2: [],
  photos: [],
  videos: [],
  logo: [],
  documents: [],
}

const ClientReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_SUCCESS:
      return {
        ...state,
      }
    case actionTypes.GET_FILES_SUCCESS:
      return {
        ...state,
        files: action.files,
      }
    case actionTypes.GET_FILES_SUCCESS2:
      return {
        ...state,
        files2: action.files,
      }
    case actionTypes.GET_PHOTOS_SUCCESS:
      return {
        ...state,
        photos: action.files,
      }
    case actionTypes.GET_LOGO_SUCCESS:
      console.log(action)
      return {
        ...state,
        logo: action.logo && action.logo.length > 0 ? action.logo[0] : {},
      }
    case successState(actionTypes.GET_DOCUMENTS):
      return {
        ...state,
        documents: action.response || [],
      }
    default:
      return state ? state : initialState
  }
}

export default ClientReducer
