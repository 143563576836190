import React, { Suspense, useContext, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import { Card } from 'react-bootstrap'

import { Icon, TableV2 } from 'src/components/index'
import '../Banks.scss'
import { faEdit, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { formatNumberWithCommas } from 'src/utils/formatters'
import { useSelector } from 'react-redux'
import { isAllowed } from 'src/selectors/modules.selector'

import { BankContext } from 'src/providers/BankContext'
import EditAccountModal from '../Shared/EditAccountModal'
import { bankAccountPermissionsEnum } from 'src/enums/bankEnums'
import { selectGetAllCurrencies } from 'src/selectors/banks.selector'
import { groupBy, mergeByBank } from 'src/utils/bankHelpers/bankHelpers'
import { Td } from 'react-super-responsive-table'
import { Loading } from 'src/components'

type accordionProps = {
  accounts: IBankAccount[]
  banks: IBank[]
}

const AccordionList: React.FC<accordionProps> = ({ accounts, banks }: accordionProps) => {
  const currencies = useSelector(selectGetAllCurrencies)
  const allowEditAccount = useSelector(state =>
    isAllowed(state, [bankAccountPermissionsEnum.EditAccount]),
  )

  const [editShowModal, setEditShowModal] = useState<boolean>(false)

  const { accounnt, updateAccount } = useContext(BankContext) || {}

  const getCurrencySymbolItem = id => {
    let sym = ''

    sym = currencies?.find(c => c.id === id)?.symbol || {}

    return sym
  }

  const handleClick = item => {
    updateAccount(item)
  }
  const accountCategorized = groupBy(accounts, 'bank')

  const renderAccountListItems = (item, index) => {
    const keyName = banks?.find(bank => bank?.id === item.bank.id)?.name

    return (
      <div key={index}>
        <div
          className={`b-user-item ${isActiveStatus(item.id)}`}
          key={`${index}`}
          onClick={() => {
            handleClick(item)
          }}>
          <div
            className={'justify-content-start align-items-start bu-en'}
            style={{ textAlign: 'left' }}>
            <div className={`b-user-name ${isActiveStatus(item.id)}`}>{item.name}</div>
            <div className={`b-user-email ${isActiveStatus(item.id)}`}>
              {item.accountNumber + ' - ' + keyName}
            </div>
            <div className={`b-user-balance-m ${isActiveStatus(item.id)}`}>
              {getCurrencySymbolItem(item.currency.id) +
                '.' +
                formatNumberWithCommas(item.balance)}
            </div>
          </div>
          <div
            className={'d-flex row align-items-center mr-2 bu-b'}
            style={{ textAlign: 'right' }}>
            <div className={`b-user-balance mr-2 ${isActiveStatus(item.id)}`}>
              {getCurrencySymbolItem(item.currency.id) +
                '.' +
                formatNumberWithCommas(item.balance)}
            </div>
            {allowEditAccount && (
              <Td className={'mini'}>
                <Icon
                  color={'gray'}
                  onClick={() => setEditShowModal(true)}
                  icon={faEdit}
                  size={'1_5x'}
                  tooltip={'Editar cuenta bancaria'}
                />
              </Td>
            )}
          </div>
        </div>
      </div>
    )
  }

  const isActiveStatus = account => {
    return account === accounnt.id ? 'active' : ' '
  }

  const displayElements = () => {
    const mergedAccounts = mergeByBank(accountCategorized, 'id')
    const elements = []
    mergedAccounts.forEach((value, key) => {
      const keyName = banks?.find(bank => bank?.id === key)?.name

      const keyId = banks?.find(item => item?.id === key)?.id

      // @ts-ignore
      elements.push(
        <Accordion.Item eventKey={keyId?.toString()} key={keyId?.toString()}>
          <Card style={{ marginBottom: 2 }} key={keyId}>
            <Accordion.Button
              as={Card.Header}
              className={'header-pink'}
              style={{ padding: '10px 5px', cursor: 'pointer' }}
              eventKey={keyId?.toString()}>
              <div
                className={'d-flex'}
                style={{ justifyContent: 'space-between', width: '100%' }}>
                <div className={'d-flex justify-content-flex-start'}>
                  <div className={'mt-1 ml-3'}>{keyName}</div>
                </div>
                <div className={'d-flex'}>
                  <Icon
                    size={'1x'}
                    className={'name'}
                    icon={faCaretDown}
                    style={{ color: 'white', marginRight: 10, marginTop: 10 }}
                  />
                </div>
              </div>
            </Accordion.Button>
            <Accordion.Body key={keyId?.toString()}>
              <div>
                <TableV2
                  // @ts-ignore
                  customClass={'scroll-x-without-height '}
                  hideFilter
                  key={keyId?.toString()}
                  items={value}
                  renderRow={renderAccountListItems}
                />
              </div>
            </Accordion.Body>
          </Card>
        </Accordion.Item>,
      )
    })
    return elements
  }

  return (
    <>
      <Suspense fallback={<Loading show={true} progress={true} label={'loading'} />}>
        <Accordion id={'accordion-list'} data-testid={'accordion-list'}>
          {displayElements()}
        </Accordion>
        <EditAccountModal show={editShowModal} onHide={() => setEditShowModal(false)} />
      </Suspense>
    </>
  )
}

export default AccordionList
