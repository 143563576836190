import './TableOrderPage.scss'
import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import {
  selectGetTableOrder,
  selectPendingPayments,
  selectSelectedPendingPayment,
  selectTips,
  selectDiscounts,
  selectDiscountSelected,
  selectOrderPayments,
} from 'src/selectors/restaurant.selector'
import {
  setPendingPayments,
  setSelectedPendingPayment,
  onSetSeePreBill,
  resetPendingPayments,
} from 'src/actions/restaurant.actions'
import Money from 'src/components/Money/Money'
import { CustomDate, Icon } from 'src/components'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { PaymentType } from 'src/enums/paymentTypes'
import { Col } from 'react-bootstrap'

const TableOrderPayments = () => {
  const dispatch = useDispatch()

  const payments = [
    { value: PaymentType.CASH, label: 'Efectivo' },
    { value: PaymentType.CREDIT, label: 'Crédito' },
    { value: PaymentType.BANK_TRANSFER, label: 'Transferencia' },
    { value: PaymentType.CARD, label: 'Tarjeta' },
  ]

  const pendingPayments = useSelector(selectPendingPayments)
  const selectedPendingPayment = useSelector(selectSelectedPendingPayment)

  const totalPayment = pendingPayments.reduce(
    (total, payment) => total + payment.amount,
    0,
  )

  const tableOrder = useSelector(selectGetTableOrder)
  const orderPayments = useSelector(selectOrderPayments)

  const tips = useSelector(selectTips)
  const discounts = useSelector(selectDiscounts)
  const discountSelected = useSelector(selectDiscountSelected)
  const [showModalDateCreedit, setShowModalDateCreedit] = useState(false)
  const [paymentDate, setPaymentDate] = useState(null)
  useEffect(() => {
    if (orderPayments.length === 0) {
      if (pendingPayments.length === 0) return

      dispatch(resetPendingPayments())
      return
    }

    let newPayments = [...pendingPayments]

    if (newPayments.length === orderPayments.length) {
      newPayments[selectedPendingPayment] = {
        ...newPayments[selectedPendingPayment],
        id: true,
      }
    } else {
      newPayments = orderPayments
    }

    dispatch(setPendingPayments(newPayments))
  }, [orderPayments.length])

  useEffect(() => {
    if (selectedPendingPayment?.value === 2) {
      setShowModalDateCreedit(true)
    }
  }, [selectedPendingPayment])

  const renderCreditDateSelector = () => {
    return (
      <CustomDate
        onDayChange={date => {
          setPaymentDate(date)
        }}
        label={'Fecha de pago'}
        required
        disabledDays={{ after: new Date() }}
      />
    )
  }
  const variableTip = (customAmount, isDiscount) => {
    if (customAmount > 0) {
      let tip = null
      if (isDiscount && tableOrder.discount && tableOrder.discount.id && discounts)
        tip = discounts.find(t => t.id === tableOrder.discount.id)
      else if (!isDiscount && tableOrder.tip && tableOrder.tip.id && tips)
        tip = tips.find(t => t.id === tableOrder.tip.id)
      if (tip) {
        const decimal = tip.percentage * 0.01
        return Number.parseFloat(customAmount * decimal).toFixed(2)
      }
    }
    return 0
  }

  const getSubtotal = (withDiscount, withTip) => {
    let response = totalPayment || 0
    if (withTip) response += newDiscount(true, response)
    return response
  }

  const newDiscount = (isTip, customAmount) => {
    return Number.parseFloat(
      (isTip ? discountSelected.tipValue : discountSelected.value) ||
        variableTip(customAmount || getSubtotal(), isTip === false),
    )
  }

  return (
    <div
      className={`pos-payments-cards ${pendingPayments.length < 3 ? 'few-items' : ''}`}>
      <div
        data-cy={'add-payment-button'}
        className={'pos-payments-card add'}
        disabled={totalPayment >= tableOrder.total - newDiscount(false, tableOrder.total)}
        onClick={() => {
          dispatch(setPendingPayments([...pendingPayments, { amount: 0 }]))
          dispatch(setSelectedPendingPayment(pendingPayments.length))
          if (window.innerWidth < 1000) dispatch(onSetSeePreBill(true))
        }}>
        <div className="index">
          <Icon icon={faPlus} />
        </div>
        <div className={'amount'}>Añadir pago</div>
      </div>
      {pendingPayments.map((payment, i) => (
        <div
          data-cy={'pos-payment-card-' + i}
          key={i}
          className={`pos-payments-card ${payment.id ? 'paid' : ''} ${
            selectedPendingPayment === i ? 'selected' : ''
          }`}
          onClick={() => {
            if (selectedPendingPayment !== i) dispatch(setSelectedPendingPayment(i))
            if (window.innerWidth < 1000) dispatch(onSetSeePreBill(true))
          }}>
          <div className="index">{i + 1}</div>
          <Money className={'amount'}>{payment.amount}</Money>
          {payment.id && <div className="state">(Pagado)</div>}
          {payment.paymentType && (
            <div className="payment-type">
              {payments.find(p => p.value === payment.paymentType).label}
            </div>
          )}
          {selectedPendingPayment?.value === 2 && (
            <Col xl={12}>{renderCreditDateSelector()}</Col>
          )}
        </div>
      ))}
    </div>
  )
}
export default TableOrderPayments
