import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setModalInvoicePackages } from 'src/actions/utilities.actions'
import { history } from 'src/App'
import { Icon, TableV2 } from 'src/components'
import Commentary from 'src/components/inputs/Commentary/Commentary'
import { selectInvoicePackages } from 'src/selectors/company.selector'

import { selectModalInvoicePackages } from 'src/selectors/utilities.selector'
import { formatNumberWithCommas } from 'src/utils/formatters'

const headers = [
  { label: 'Nombre', className: 'name', show: true, value: ['name'], type: 'text' },
  {
    label: 'Facturas',
    className: 'mini',
    show: true,
    value: ['invoices'],
    type: 'number',
  },
  { label: 'Precio', show: true, value: ['price'], type: 'money' },
  { label: 'Precio por DTE', show: true, value: ['price'], type: 'money' },
  { label: 'Comprar', show: true },
]

const ModalInvoicePackages = () => {
  const dispatch = useDispatch()

  const modalInvoicePackages = useSelector(selectModalInvoicePackages)
  const invoicePackages = useSelector(selectInvoicePackages)

  return (
    <div style={{ position: 'fixed' }}>
      <Modal
        show={modalInvoicePackages.show}
        centered
        size={'xl'}
        onHide={() => {
          dispatch(setModalInvoicePackages({ show: false }))
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Paquetes de facturas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={'column'}>
            <TableV2
              items={invoicePackages}
              headers={headers}
              renderRow={item => (
                <tr className={'data'} key={item.id}>
                  <td className={''}>
                    {item.name || `Paquete #${item.id}`}
                    {item.bestValue && <Commentary value={'Mejor valor'} edit={false} />}
                  </td>
                  <td className={''}>{item.invoices}</td>
                  <td className={''}>Q.{formatNumberWithCommas(item.price)}</td>
                  <td className={''}>
                    Q.{formatNumberWithCommas(item.price / item.invoices)}
                  </td>
                  <td className={''}>
                    <Icon
                      icon={faShoppingCart}
                      color={'primary'}
                      tooltip={'Comprar paquete'}
                      size={'2x'}
                      onClick={() => {
                        dispatch(setModalInvoicePackages({ show: false }))
                        history.push(`/invoice-checkout/${item.id}`)
                      }}
                    />
                  </td>
                </tr>
              )}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ModalInvoicePackages
