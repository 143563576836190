import { executeAction } from './global.actions'
import InvoiceService from 'src/services/invoice.service'

export const actionTypes = {
  GET_INVOICE_ITEMS_TO_CREDIT_NOTE: 'GET_INVOICE_ITEMS_TO_CREDIT_NOTE',
  ON_CREATE_CREDIT_NOTE_BY_SELLS: 'ON_CREATE_CREDIT_NOTE_BY_SELLS',
  GET_INVOICE_DEALERS: 'GET_INVOICE_DEALERS',
  GET_INVOICE_ISSUED_BY_DEALER: 'GET_INVOICE_ISSUED_BY_DEALER',
  GET_INVOICE_RECEIVED_BY_DEALER_EXCEL: 'GET_INVOICE_RECEIVED_BY_DEALER_EXCEL',
  GET_INVOICE_PRINT_INSTRUCTIONS: 'GET_INVOICE_PRINT_INSTRUCTIONS',
}

/** Get items by invoice detail from order id
 * @param {number} orderId Cotizap order id
 * */
export const getItemsToCreditNote = (orderId: number) => dispatch => {
  const process = async () => ({
    payload: await InvoiceService.getItemsToCreditNote(orderId),
  })
  dispatch(executeAction(actionTypes.GET_INVOICE_ITEMS_TO_CREDIT_NOTE, process))
}

/** Create a credit note and voucher entity by sell items
 * @param {object} request Request with the value to create credit note
 * */
export const onCreateCreditNoteBySells = request => dispatch => {
  const process = async () => ({
    payload: await InvoiceService.onCreateCreditNoteBySells(request),
  })
  dispatch(executeAction(actionTypes.ON_CREATE_CREDIT_NOTE_BY_SELLS, process))
}

/**
 * Action to get invoice dealers of Cotizap
 * @param {object} request Request to get invoice dealers
 * @param {number} request.startDate Start date to get invoice dealers
 * @param {number} request.endDate End date to get invoice dealers
 * @param {string} request.search Search by dealer
 * @returns
 */
export const getInvoiceDealers = request => dispatch => {
  const process = async () => ({
    payload: await InvoiceService.getInvoiceDealers(request),
  })
  dispatch(executeAction(actionTypes.GET_INVOICE_DEALERS, process))
}

/**
 * Action to get invoice dealers issued by company
 * @param {object} request Request to get invoice dealers
 * @param {number} request.startDate Start date to get invoice dealers
 * @param {number} request.endDate End date to get invoice dealers
 * @param {number} request.companyId Company id to get invoice dealers
 * @returns
 */
export const getInvoiceIssuedByDealer = request => dispatch => {
  const process = async () => ({
    payload: await InvoiceService.getInvoiceIssuedByDealer(request),
  })
  dispatch(executeAction(actionTypes.GET_INVOICE_ISSUED_BY_DEALER, process))
}

/**
 * Action to get invoice dealers issued by company in excel
 * @param {object} request Request to get invoice dealers
 * @param {number} request.startDate Start date to get invoice dealers
 * @param {number} request.endDate End date to get invoice dealers
 * @param {number} request.companyId Company id to get invoice dealers
 * @returns
 */
export const getInvoiceIssuedByDealerExcel = request => dispatch => {
  const process = async () => ({
    payload: await InvoiceService.getInvoiceIssuedByDealerExcel(request),
  })
  dispatch(executeAction(actionTypes.GET_INVOICE_RECEIVED_BY_DEALER_EXCEL, process))
}

export const getPrintInstructionsByDocumentData =
  (documentId: number, documentType: number) => dispatch => {
    const process = async () => ({
      payload: await InvoiceService.getPrintInstructionsByDocumentData(
        documentId,
        documentType,
      ),
    })
    dispatch(executeAction(actionTypes.GET_INVOICE_PRINT_INSTRUCTIONS, process))
  }
