import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";


import {
    Title, CustomTabs,  TableV2
} from "../../components";


import {getTypesAvailable, getLinkContracted} from "../../actions/offers.actions";
import {
    selectLinkContracted,
    selectOfferLinkTypeAvailable
} from "../../selectors/offers.selector";

const iL = [
    {title: 'Cargando...'},
    {title: 'Cargando...'},
    {title: 'Cargando...'}
];

const KoloLinkAdmin = () => {
    const dispatch = useDispatch();

    const links = useSelector(selectOfferLinkTypeAvailable);
    const linkContracted = useSelector(selectLinkContracted);

    useEffect(() => {
        dispatch(getTypesAvailable(0, 11000));
        dispatch(getLinkContracted(7, 11000));
    }, []);

    const getTable = (linkType) => {
        return <div>
            <TableV2
                items={linkContracted.filter((l) => l.linkType === linkType)}
                mobileAuto
                storageKey={'koloLinkAdmin'}
                headers={[
                    {label: 'Enlace', show: true, value: ['linkName'], type: 'text' ,  className:'mini'},
                    {label: 'Productor', show: true, value: ['producerName'], type: 'text' ,  className:'medium'},
                    {label: 'Transportista', show: true, value: ['transportistName'], type: 'text' ,  className:'medium'},
                    {label: 'Zonas', show: true, value: ['zones'], type: 'text' ,  className:'mini'},
                    {config:true, show: true, label:'', className:'mini'}
                ]}
            />
            
        </div>
    };

    return (<div>
        <Title
            title={'Enlaces'}
        />

        <CustomTabs items={links.length <= 0 ? iL : links.map((l) => ({
            title: l.name,
            component: () => getTable(l.id)
        }))}/>
    </div>);
};
export default KoloLinkAdmin;
