import React, {Component} from 'react';
import {connect} from "react-redux"

import Card from "../../../components/cards/Card";
import {Button, Col, OverlayTrigger, Row, Spinner, Tooltip} from "react-bootstrap"
import {Link} from "react-router-relative-link";

import {actionTypes as typeS, getSubscriptions, getSubscriptionsCompany, getSubscriptionsPolygons, acceptSubscription} from "../../../actions/subscriptions.actions"
import {selectCompaniesByClient} from "../../../selectors/company.selector";
import {loadingSelector} from "../../../selectors/loading.selector";
import {handlerError, hasErrorsSelector, singleErrorSelector} from "../../../selectors/error.selector";
import {
    selectSubscriptions,
    selectSubscriptionsCompany,
    selectSubscriptionsPolygons
} from "../../../selectors/subscriptions.selector";
import CollapsibleCard from "../../../components/cards/collapsible-card/CollapsibleCard";
import {faPencilAlt, faTrash, faCheckDouble, faCheckSquare, faInfoCircle} from "@fortawesome/free-solid-svg-icons";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";
import {formatDateFromMillis} from "../../../utils/formatters";
import Alert from "sweetalert-react";
import { TableV2 } from '../../../components';


const intervals = [
    {value: 0, label: 'Diariamente', singular: 'día', plural: 'días'},
    {value: 1, label: 'Semanalmente', singular: 'semana', plural: 'semanas'},
    {value: 2, label: 'Mensualmente', singular: 'mes', plural: 'meses'},
    {value: 3, label: 'Anualmente', singular: 'año', plural: 'años'},
]

class Subscriptions extends Component {

    state = {
        alert: {title: 'default'},

        currentSubscription: {},
        showConfirmation: false,
        showAcceptSuccess: false
    };

    componentDidMount() {
        this.props.getSubscriptions();
        this.props.getSubscriptionsCompany();
        // this.props.getSubscriptionsPolygons();
    };

    componentWillReceiveProps(next) {
        const {loadingS, hasErrorS, loadingAS, hasErrorAS, handlerError} = this.props;
        let alert = {title: 'default'};

        if (loadingS && !next.loadingS) {
            if (!hasErrorS && !next.hasErrorS) {
                console.log('SUSCRIPTIONS::::::', this.props.subscriptions)
            } else {
                this.setState({subscriptionsError: true});
            }
        }

        if (loadingAS && !next.loadingAS) {
            if (!hasErrorAS && next.hasErrorAS) {
                alert = handlerError(next.errorAS.message || 'Ha ocurrido un error inesperado.');
                alert.onConfirm = () => this.setState({alert: {...alert, show: false}});
            } else {
                this.setState({showAcceptSuccess: true});
            }
        }

    }

    acceptSubscription = () => {
        this.props.acceptSubscription(this.state.currentSubscription.id);
        this.setState({
            showConfirmation: false
        })
    }

    

    getHeaders = () => {
        return [
            
            {label: 'Nombre', show: true, value: ['title'], type: 'text' ,  className:'mini'},
            {label: 'Descripción', show: true, value: ['description'], type: 'text' ,  className:'mini'},
            {label: 'Periodicidad', show: true, value: ['periodicidadC'], type: 'text' ,  className:'mini',
            custom:(item)=> item.periodicidadC = 'Cada ' + item.step + ' ' + (item.step == 1 ? intervals[item.intervalType].singular : intervals[item.intervalType].plural)},
            {label: 'Repetir', show: true, value: ['repetirC'], type: 'text' ,  className:'mini',
            custom:(item)=> item.repetirC =  item.times + (item.times > 1 ? ' veces' : 'vez')},
            {label: 'Estado', show: true, value: ['statusC'], type: 'text' ,  className:'mini',
            custom:(item)=> item.statusC =  item.status ? 'Activo' : 'Inactivo'},
            {config:true, show: true, label:'', className:'mini center'}
        ]
    }
    
    getHeadersClient = () => {
        return [

            { label: 'Cliente', show: true, value: ['cotizapClient', 'companyName'], type: 'text', className: 'mini' },
            { label: 'Suscripción', show: true, value: ['subscription', 'title'], type: 'text', className: 'mini' },
            { label: 'Fecha solicitud', show: true, value: ['createdAt'], type: 'date', className: 'mini' },
            {
                label: 'Próxima entrega', show: true, value: ['entregaC'], type: 'text', className: 'mini',
                custom: (item) => item.entregaC = item.status ? formatDateFromMillis(item.nextDate) : '-'
            },
            {
                label: 'Metodo de Pago', show: true, value: ['methodPayC'], type: 'text', className: 'mini',
                custom: (item) => item.methodPayC = item.paymentMethod == 1 ? 'Deposito' : 'Efectivo'
            },
            {
                label: 'Estado', show: true, value: ['statusC'], type: 'text', className: 'mini',
                custom: (item) => item.statusC = item.status ? 'En Curso' : 'En espera'
            },
            {
                config: true, show: true, label: '', className: 'mini center', custom: (item) =>
                    <Row>
                        <Col>
                            {/*{item.status ?*/}
                            {/*    <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip">Solicitud ya aceptada</Tooltip>}>*/}
                            {/*        <FontAwesomeIcon className={'name'} style={{color: 'green'}} icon={faCheckDouble}/>*/}
                            {/*    </OverlayTrigger>*/}
                            {/*    :*/}
                            {/*    <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip">Aceptar</Tooltip>}>*/}
                            {/*        <Button disabled={this.props.loadingAS} size={'sm'} variant={'danger'} onClick={()=>{this.setState({currentSubscription: item, showConfirmation: true})}}>*/}
                            {/*            {this.props.loadingAS &&*/}
                            {/*            <Spinner*/}
                            {/*                style={{marginRight: 10, marginTop: 2}}*/}
                            {/*                as="span"*/}
                            {/*                animation="border"*/}
                            {/*                size="sm"*/}
                            {/*                role="status"*/}
                            {/*                aria-hidden="true"*/}
                            {/*            />}*/}
                            {/*            <FontAwesomeIcon className={'name'} icon={faCheckSquare}/>*/}
                            {/*        </Button>*/}
                            {/*    </OverlayTrigger>*/}
                            {/*}*/}
                            <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip">Detalle</Tooltip>}>
                                <Button size={'sm'} variant={'outline-secondary'} onClick={() => this.goTo(item, '/detail')}>
                                    <FontAwesomeIcon className={'name'} icon={faInfoCircle} />
                                </Button>
                            </OverlayTrigger>
                        </Col>
                    </Row>
            }
        ]
    }

    goTo = (item, route) => {
        const {history, match} = this.props;
        const url = `${match.url}${route}/${item.id}`;
        history.push(url, {subscriptionId: item.id});
    };

    render() {
        const {loadingS, subscriptions, subscriptionsCompany, loadingSC} = this.props;
        const {showAcceptSuccess} = this.state;

        console.log('SUBSCRIPTION POLYGONS::::', this.props.subscriptionsPolygons);

        return (
            <div>
                <h1 className={'dashboard-welcome'}>Agrupaciones de Productos</h1>

                <Row>
                    <Col md={4}>
                        <Link to={'new'} className={'big-button'}>Crear agrupación</Link>
                    </Col>
                    <Col>
                        <CollapsibleCard header={{backgroundColor: '#1b4060'}} title={'Listado'}>
                            <TableV2
                                loading={loadingS}
                                items={subscriptions}
                                mobileAuto
                                storageKey={`subsCreateAgrupacion`}
                                headers = {this.getHeaders()}
                            />
                        </CollapsibleCard>

                        <CollapsibleCard header={{backgroundColor: '#1b4060'}} title={'Suscripciones activas'}>
                            <TableV2
                                headers={this.getHeadersClient()}
                                loading={loadingSC}
                                mobileAuto
                                storageKey={`subsClient`}
                                items={subscriptionsCompany}
                            />
                        </CollapsibleCard>
                    </Col>
                </Row>

                <Alert {...this.state.alert}/>

                <Alert
                    show={this.state.showConfirmation}
                    title="Información"
                    text={this.state.currentSubscription.cotizapClient ? "¿Desea aceptar la solicitud de suscripción de " + this.state.currentSubscription.cotizapClient.companyName + "?" : ""}
                    type={"warning"}
                    showCancelButton
                    confirmButtonText="Aceptar"
                    cancelButtonText="Cancelar"
                    onConfirm={() => {
                        this.acceptSubscription();
                    }}
                    onCancel={() => {
                        this.setState({showConfirmation: false});
                    }}
                />

                <Alert
                    show={showAcceptSuccess}
                    title={"!Exito!"}
                    text={this.state.currentSubscription.cotizapClient ? "Has aceptado la solicitud de " + this.state.currentSubscription.cotizapClient.companyName + " exitosamente." : ""}
                    type={"success"}
                    onConfirm={() => this.setState({showAcceptSuccess: false})}
                />

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    subscriptions: selectSubscriptions(state),
    subscriptionsCompany: selectSubscriptionsCompany(state),

    // subscriptionsPolygons: selectSubscriptionsPolygons(state),

    loadingS: loadingSelector([typeS.GET_SUBSCRIPTIONS])(state),
    hasErrorS: hasErrorsSelector([typeS.GET_SUBSCRIPTIONS])(state),
    errorS: singleErrorSelector([typeS.GET_SUBSCRIPTIONS])(state),

    loadingSC: loadingSelector([typeS.GET_SUBSCRIPTIONS_COMPANY])(state),
    hasErrorSC: hasErrorsSelector([typeS.GET_SUBSCRIPTIONS_COMPANY])(state),
    errorSC: singleErrorSelector([typeS.GET_SUBSCRIPTIONS_COMPANY])(state),

    loadingAS: loadingSelector([typeS.ACCEPT_SUBSCRIPTION])(state),
    hasErrorAS: hasErrorsSelector([typeS.ACCEPT_SUBSCRIPTION])(state),
    errorAS: singleErrorSelector([typeS.ACCEPT_SUBSCRIPTION])(state),

    handlerError: (message) => handlerError(message)
});
const mapDispatchToProps = dispatch => ({
    getSubscriptions: () => dispatch(getSubscriptions()),
    getSubscriptionsCompany: () => dispatch(getSubscriptionsCompany()),
    // getSubscriptionsPolygons: () => dispatch(getSubscriptionsPolygons()),
    acceptSubscription: (id) => dispatch(acceptSubscription((id)))
});
export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
