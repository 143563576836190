import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import { faShareSquare, faStar, faUser } from '@fortawesome/free-solid-svg-icons'
import Alert from 'sweetalert-react'

import { CustomTabs, Select, FormText, Icon, Button, Switch } from '../../index'

import { onRefresh, setModalOrderTransferInfo } from 'src/actions/utilities.actions'
import { selectModalOrderTransfer } from 'src/selectors/utilities.selector'

import { selectCurrentModule } from 'src/selectors/user.selector'

import {
  actionTypes as typeW,
  getWarehouses,
  getExternalActiveWarehouses,
  createTransferByGroup,
  createTransferByOrder,
} from 'src/actions/warehouse.actions'
import {
  selectAllWarehousesForSelect,
  selectExternalActiveWarehousesForSelect,
} from 'src/selectors/warehouse.selector'

import { getCompaniesByOrderId } from 'src/actions/company.actions'
import { selectCompaniesByOrder } from 'src/selectors/company.selector'

import { actionTypes as typeOf, getNearestDealer } from 'src/actions/offers.actions'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  hasErrorsSelector,
  singleErrorSelector,
  handlerSuccess,
  handlerError,
} from 'src/selectors/error.selector'

const ModalOrderTransfer = () => {
  const dispatch = useDispatch()

  const order = useSelector(selectModalOrderTransfer)
  const module = useSelector(selectCurrentModule)
  const warehouses = useSelector(selectAllWarehousesForSelect)
  const toWarehouses = useSelector(selectExternalActiveWarehousesForSelect)
  const companies = useSelector(selectCompaniesByOrder)
  const dealer = useSelector(state => state.offers.dealer)

  const loading = useSelector(state =>
    loadingSelector([typeW.CREATE_TRANSFER_BY_GROUP])(state),
  )
  const hasError = useSelector(state =>
    hasErrorsSelector([typeW.CREATE_TRANSFER_BY_GROUP])(state),
  )
  const error = useSelector(state =>
    singleErrorSelector([typeW.CREATE_TRANSFER_BY_GROUP])(state),
  )

  const loadingD = useSelector(state =>
    loadingSelector([typeOf.GET_NEAREST_DEALER])(state),
  )
  const hasErrorD = useSelector(state =>
    hasErrorsSelector([typeOf.GET_NEAREST_DEALER])(state),
  )
  const errorD = useSelector(state =>
    singleErrorSelector([typeOf.GET_NEAREST_DEALER])(state),
  )

  const [actions, setActions] = useState({
    start: false,
    transfer: false,
    dealer: false,
  })
  const [alert, setAlert] = useState({ title: '' })
  const [automatic, setAutomatic] = useState(false)
  const [distance, setDistance] = useState(300)

  useEffect(() => {
    if (order.show && !actions.start) {
      setActions({ ...actions, start: true })
      if (module === 1000 || order.linkType !== 4) dispatch(getWarehouses(false, false))

      if (module === 2000) dispatch(getCompaniesByOrderId(order.id))
    }
  }, [order])

  useEffect(() => {
    if (loading) setActions({ ...actions, transfer: true })
    else if (actions.transfer) {
      setActions({ ...actions, transfer: false })

      if (hasError)
        setAlert({
          ...handlerError(error.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Solicitud creada satisfactoriamente'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            onHide()
            if (order.refresh) dispatch(onRefresh(true))
          },
        })
    }
  }, [loading])

  useEffect(() => {
    if (loadingD) setActions({ ...actions, dealer: true })
    else if (actions.dealer) {
      setActions({ ...actions, dealer: false })
      if (hasErrorD)
        setAlert({
          ...handlerError(errorD.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
    }
  }, [loadingD])

  /*
    this.setEditParams(item, true);
    if (module === 2000)
        getCompaniesByOfferId(item.id);
    */

  const onHide = () => {
    dispatch(setModalOrderTransferInfo({ show: false }))
  }

  return (
    <div>
      <Modal show={order.show} size={'md'} centered onHide={() => onHide()}>
        <Modal.Header closeButton>
          <Modal.Title>Órden: {order.number}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomTabs
            items={[
              {
                value: 0,
                show: module === 1000 ? true : order.linkType !== 4,
                title: 'Interno',
                info: 'Transfiere de encargado con los usuario de la empresa',
                component: (
                  <div>
                    <Row>
                      <Col xl={12} md={12} sm={12}>
                        <Select
                          disabled={loading}
                          required
                          label={'Bodega destino'}
                          options={warehouses}
                          value={order.w}
                          onChange={w =>
                            dispatch(setModalOrderTransferInfo({ ...order, w }))
                          }
                        />
                      </Col>
                    </Row>
                    <div className={'ml-1 mt-2 tag-log'}>
                      {order.w?.attendantsInfo
                        ?.sort((a, b) => b.principal - a.principal)
                        .map((d, index) => (
                          <FormText
                            key={d.name}
                            prepend={
                              <Icon
                                tooltip={
                                  d.principal
                                    ? 'Encargado Principal'
                                    : 'Encargado secundario'
                                }
                                icon={d.principal ? faStar : faUser}
                              />
                            }
                            mt={index > 0 ? 0 : 1}
                            label={index === 0 ? 'Encargados' : undefined}
                            value={d.name}
                            disabled
                          />
                        ))}
                    </div>
                  </div>
                ),
              },
              {
                value: 1,
                show: module === 2000,
                title: 'Externo',
                info: 'Asigna como encargado a una empresa enlazada',
                component: (
                  <Row>
                    <Col xl={12} md={12} sm={12}>
                      <Switch
                        disabled={loadingD}
                        info={'Asignar de forma automatica'}
                        label={'Asignar de forma automatica'}
                        topLabel
                        checked={automatic}
                        onChange={() => setAutomatic(!automatic)}
                      />
                    </Col>
                    {automatic && (
                      <Col xl={12} md={12} sm={12}>
                        <FormText
                          disabled={loadingD}
                          label={'Buscar repartidor mas cercano en un radio establecido'}
                          prepend={'mt'}
                          value={distance}
                          type={'number'}
                          onChange={({ target }) => setDistance(target.value)}
                          append={
                            <Button
                              loading={loadingD}
                              color={'accent'}
                              disabled={!dispatch || dispatch <= 0}
                              onClick={() =>
                                dispatch(getNearestDealer(order.id, distance))
                              }
                            >
                              Buscar
                            </Button>
                          }
                          atext
                        />
                      </Col>
                    )}
                    <Col xl={12} md={12} sm={12}>
                      <Select
                        disabled={loading || automatic}
                        label={'Transportista a transferir la orden'}
                        options={companies}
                        required
                        value={!automatic ? order.shipper : { label: dealer.companyName }}
                        onChange={shipper => {
                          dispatch(setModalOrderTransferInfo({ ...order, shipper }))
                          dispatch(getExternalActiveWarehouses(shipper.value))
                        }}
                      />
                    </Col>
                    <Col xl={12} md={12} sm={12}>
                      <Select
                        disabled={!order.shipper || loading || automatic}
                        required
                        label={'Escoja una bodega destino del transportista'}
                        options={
                          toWarehouses ? toWarehouses.filter(tw => tw.koloDelivery) : []
                        }
                        value={!automatic ? order.w : { label: dealer.warehouseName }}
                        onChange={w =>
                          dispatch(setModalOrderTransferInfo({ ...order, w }))
                        }
                      />
                    </Col>
                    <Col xl={12} md={12} sm={12}>
                      <FormText
                        prepend={<Icon icon={faUser} tooltip={'Encargado'} />}
                        disabled
                        label={'Encargado'}
                        value={
                          automatic
                            ? dealer.attendantName
                            : order.w
                            ? order.w.attendantData
                              ? order.w.attendantData.name
                              : ''
                            : ''
                        }
                      />
                    </Col>
                  </Row>
                ),
              },
            ]}
          />
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              disabled={automatic ? !dealer.warehouseId : !order.w}
              loading={loading}
              color={'primary'}
              icon={faShareSquare}
              right
              onClick={() => {
                const toWarehouse = automatic ? dealer.warehouseId : order.w.value

                if (order.groupId) {
                  dispatch(createTransferByGroup(order.groupId, { toWarehouse }))
                } else {
                  dispatch(createTransferByOrder(order.id, { toWarehouse }, true))
                }
              }}
            >
              Transferir
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Alert {...alert} />
    </div>
  )
}
export default ModalOrderTransfer
