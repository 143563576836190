import OrdersService from '../services/orders.service'
import { executeAction } from './global.actions'

export const actionTypes = {
  ADD_ITEM: 'ADD_ITEM',

  ON_CREATE_ORDER: 'ON_CREATE_ORDER',
}

const updateShopping = shopping => ({ type: actionTypes.ADD_ITEM, shopping })

export const onCreateOrder = request => dispatch => {
  const process = async () => ({
    shoppingCreate: await OrdersService.onCreateOrderByClient(request),
  })
  dispatch(executeAction(actionTypes.ON_CREATE_ORDER, process))
}

export const addItems = (shopping, withCache) => dispatch => {
  if (!withCache) dispatch(updateShopping(shopping))
  else {
    window.localStorage.setItem(actionTypes.ADD_ITEM, JSON.stringify(shopping))
    dispatch(updateShopping(shopping))
  }
}

export const addItem = (item, items) => dispatch => {
  const product = items.find(i => i.id === item.id)
  if (product) {
    items = items.map(i => {
      if (item.id === i.id) {
        i.quantity += parseInt(item.quantity)
        i.subTotal += parseFloat(item.subTotal)
      }
      return i
    })
  } else {
    items.push(item)
  }

  window.localStorage.setItem(actionTypes.ADD_ITEM, JSON.stringify(items))
  dispatch(updateShopping(items))
}
