import React, {Component} from 'react';
import {connect} from "react-redux"
import {
    acceptSubscription,
    actionTypes as typeS,
    actionTypes as types,
    getActiveSubscriptionClient,
    updateProducts
} from "../../../../actions/subscriptions.actions";
import {selectSubscriptionActiveClient} from "../../../../selectors/subscriptions.selector";
import {loadingSelector} from "../../../../selectors/loading.selector";
import {Button, Col, Row, Spinner, Alert} from "react-bootstrap";
import {formatNumberWithCommas} from "../../../../utils/formatters";
import moment from "moment";
import CollapsibleCard from "../../../../components/cards/collapsible-card/CollapsibleCard";
import {Td, Tr} from "react-super-responsive-table";
import ModalSelectProducts from "../../../Production/Subscriptions/SubscriptionCreate/ModalSelectProducts";
import SweetAlert from "sweetalert-react";

import "./SubscriptionClientDetail.scss"
import Placeholder from "../../../../components/Placeholder/Placeholder";
import {handlerError, hasErrorsSelector, singleErrorSelector} from "../../../../selectors/error.selector";
import {selectCurrentModule} from "../../../../selectors/user.selector";
import {toMoney} from "../../../../utils/utilities";
import Money from '../../../../components/Money/Money';

const intervals = [
    {value: 0, label: 'Diariamente', singular: 'Día', plural: 'días'},
    {value: 1, label: 'Semanalmente', singular: 'Semana', plural: 'semanas'},
    {value: 2, label: 'Mensualmente', singular: 'Mes', plural: 'meses'},
    {value: 3, label: 'Anualmente', singular: 'Año', plural: 'años'},
]

class SubscriptionClientDetail extends Component {

    state = {
        products: [],
        showProductsModal: false,
        showConfirmation: false,

        alert: {
            title: 'default',
            text: 'default',
            type: 'info',
            show: false,
            onConfirm: () => console.log('default')
        },

        showUpdateSuccess: false,
        showConfirmConfirmation: false
    };

    componentDidMount() {
        const {subscriptionId} = this.props.history.location.state;
        this.props.getActiveSubscriptionClient(subscriptionId);
    };

    componentWillReceiveProps(next) {
        const {loading, loadingUP, hasErrorUP, loadingAS, hasErrorAS, handlerError} = this.props;
        const {subscriptionPolygon, errorUP, errorAS} = next;

        let alert = {title: 'title'};

        if (loadingUP && !next.loadingUP){
            if (!hasErrorUP && next.hasErrorUP) {
                alert = handlerError(errorUP.message || 'Ha ocurrido un error inesperado.');
                alert.onConfirm = () => this.setState({alert: {...alert, show: false}});

            } else {
                this.setState({showUpdateSuccess: true});
            }
        }

        if (loadingAS && !next.loadingAS) {
            if (!hasErrorAS && next.hasErrorAS) {
                alert = handlerError(errorAS.message || 'Ha ocurrido un error inesperado.');
                alert.onConfirm = () => this.setState({alert: {...alert, show: false}});
            } else {
                this.setState({showAcceptSuccess: true});
            }
        }
    };

    getContent = (subscriptionDetail) => {
        let content = [];
        subscriptionDetail.filter((i, index) => (index < 3)).map((product) => {
            const {quantity, subtotal, discount, total, productData} = product;
            content.push({
                name: productData.name,
                quantity: quantity,
                discount,
                subtotal,
                total
            })
        })

        return content;
    }

    formatDateFromMillis = (millis) => {
        const day = moment(millis).utc(true).utcOffset(6);
        return moment(day).format('DD/MM/YYYY');
    };

    renderRow = (item, index) => {
        return (
            <Tr className={'data'} key={`${index}`}>
                <Td>{item.quantity}</Td>
                <Td>{item.productData.name}</Td>
            </Tr>
        );
    };

    onHideProductsModal = () => this.setState({showProductsModal: false});
    onSelectProducts = (products) => {

        console.log('PRODUCTS:::::', products)

        this.setState({products, showProductsModal: false});
    }

    renderRowProduct = (item, index) => {
        return (
            <Tr className={'data'} key={`${index}`}>
                <Td className={'short'}>{item.productCode}</Td>
                <Td className={'medium'}>{item.productName}</Td>
                <Td className={'medium'}>{toMoney(item.productPrice)}</Td>
                <Td className={'short'}>{item.quantity}</Td>
                <Td className={'short '}>{toMoney((item.productPrice * item.quantity) - item.discount)}</Td>
            </Tr>
        );
    };

    getMaximumTotalAmount = () => {
        const {activeSubscriptionClient} = this.props;
        const {subscription} = activeSubscriptionClient;

        const productsDefault = subscription ? subscription.subscriptionDetail : [];

        let total = 0;
        productsDefault.map((product) => {
            total += product.total;
        });
        return total
    };

    totalProductDetail = () => {
        const {products} = this.state;
        let total = 0;
        products.filter((i) => i.checked).map((product) => {
            total += (product.productPrice * product.quantity) - product.discount;
        });
        return total
    };

    save = () => {
        const {activeSubscriptionClient, loading, loadingUP} = this.props;
        const {products} = this.state

        let itemsPro = [];
        products.filter((i) => i.checked).map((product) => {
            const {productId, quantity} = product;
            itemsPro.push({
                product: productId,
                quantity,
            })
        });

        this.props.updateProducts(activeSubscriptionClient.id, itemsPro)
        this.setState({showConfirmation: false})
    }

    acceptSubscription = () => {
        const {activeSubscriptionClient} = this.props;

        this.props.acceptSubscription(activeSubscriptionClient.id);
        this.setState({
            showConfirmConfirmation: false
        })
    }

    render() {
        const {products, showUpdateSuccess} = this.state;
        const {activeSubscriptionClient, loading, loadingUP, module, loadingAS} = this.props;
        console.log('getActiveSubscriptionClient:::::', this.props.activeSubscriptionClient);

        const {subscription, subscriptionPolygon, subscriptionClientDetails} = activeSubscriptionClient;
        const total = subscriptionPolygon ? subscriptionPolygon.totalSubscription * activeSubscriptionClient.subscription.times : 0;


        // const productsDefault = subscription ? subscription.subscriptionDetail : [];

        const productsDefault = subscriptionClientDetails || [];


        let currentStatus = 0;
        if (this.totalProductDetail() > this.getMaximumTotalAmount()){
            currentStatus = 3
        } else if (this.totalProductDetail() < (this.getMaximumTotalAmount() * 0.9)){
            currentStatus = 2
        } else {
            currentStatus = 1
        }

        console.log('MODULE::', module)

        let availableDays = 0;

        if (subscription){
            const date1 = this.formatDateFromMillis( subscription.nextDate);

            const date2 = moment().subtract(subscriptionPolygon ? subscriptionPolygon.deliveryDays + 2 : 0, 'days');

            availableDays = date2.diff( moment(date1, 'DD/MM/YYYY'), 'days')
        }

    const images =[
        {url: 'https://images.pexels.com/photos/5617/red-tomato-vegetable.jpg?cs=srgb&dl=comida-tomate-vegetal-verdura-5617.jpg&fm=jpg'},
        {url: 'https://images.pexels.com/photos/144248/potatoes-vegetables-erdfrucht-bio-144248.jpeg?cs=srgb&dl=comida-cosecha-ingrediente-patatas-144248.jpg&fm=jpg'},
        {url: 'https://images.pexels.com/photos/143133/pexels-photo-143133.jpeg?cs=srgb&dl=comida-crecer-de-madera-fresco-143133.jpg&fm=jpg'},
        {url: 'https://images.pexels.com/photos/533342/pexels-photo-533342.jpeg?cs=srgb&dl=agricultura-cebollas-cebollas-tiernas-concentrarse-533342.jpg&fm=jpg'},
        {url: 'https://images.pexels.com/photos/1453713/pexels-photo-1453713.jpeg?cs=srgb&dl=agricultura-color-comida-comiendo-sano-1453713.jpg&fm=jpg'},
        {url: 'https://images.pexels.com/photos/1053914/pexels-photo-1053914.jpeg?cs=srgb&dl=comiendo-sano-delicioso-dulce-fresco-1053914.jpg&fm=jpg'},
        {url: 'https://images.pexels.com/photos/2294477/pexels-photo-2294477.jpeg?cs=srgb&dl=citricos-comida-delicioso-fotografia-de-comida-2294477.jpg&fm=jpg'}
    ]

        return (
            <div>
                <div className={'back-subscription-card'}>
                    <Row>
                        <Col className={'back-shadow sub-style'} style={{padding: 0}}>
                            <div >
                                <div className="header-subs">
                                    <div style={{
                                        height: 100,
                                        backgroundColor: '#1b4060',
                                        borderTopLeftRadius: '5px',
                                        borderTopRightRadius: '5px',
                                        textAlign: 'center',
                                        paddingTop: 10
                                    }}>
                                        <h1 style={{color: 'white'}}>{subscription ? subscription.title : 'Agrupación de Productos'}</h1>
                                        <h6 style={{color: 'white'}}>{subscription ? subscription.description : 'Cargando...'}</h6>

                                    </div>
                                    {/*{discount > 0 &&*/}
                                    {/*<div className="arrow-right"*/}
                                    {/*     style={{backgroundColor: this.getBackColor(discount*100)}}>*/}
                                    {/*    <span*/}
                                    {/*        className="arrow-text"><b>{(discount - Math.floor(discount)) === 0 ? discount * 100 : (discount * 100).toFixed(4)}%</b></span>*/}
                                    {/*</div>*/}
                                    {/*}*/}
                                </div>
                                <Row style={{margin: 10}}>

                                    <Col md={3}>
                                        <Alert style={{marginTop: 16, backgroundColor:'#d1ebf0', color: '#0c5460', width: '100%', textAlign: 'center'}}>
                                            <Alert.Heading>Estado</Alert.Heading>
                                            <h6 style={{color: '#0c5460'}}>{(loading || loadingAS) ? 'Cargando...' : (activeSubscriptionClient.status ? 'En Curso' : 'En espera')}</h6>
                                        </Alert>
                                        {!loading && !loadingAS && module === 2000 && !activeSubscriptionClient.status &&
                                            <Button variant={'info'} style={{marginTop: 4}}
                                                    onClick={() => this.setState({showConfirmConfirmation: true})} block>
                                                Aceptar
                                                </Button>
                                        }
                                    </Col>

                                    <Col>
                                        <Row style={{marginLeft: 16, marginRight: 16}}>
                                            <Col md={5}>
                                                <div className={'sub-style'} style={{marginTop: 16}}>
                                                    {
                                                        module === 2000 &&
                                                            <div>
                                                                <h2>Cliente:</h2>
                                                                <h6 style={{marginTop: 4}}>{activeSubscriptionClient.cotizapClient && activeSubscriptionClient.cotizapClient.companyName || '-'}</h6>
                                                                <br/>
                                                            </div>
                                                    }
                                                    {
                                                        module === 3000 &&
                                                        <div>
                                                            <h2>Productor:</h2>
                                                            <h6 style={{marginTop: 4}}>{activeSubscriptionClient.companyName || '-'}</h6>
                                                            <br/>
                                                        </div>
                                                    }
                                                    <h2>Valor de esta Agrupación</h2>
                                                    <Money component='h6' style={{marginTop: 4}}>{total}</Money>
                                                    <br/>
                                                    <h2>Tipo de Agrupación</h2>
                                                    <h6 style={{marginTop: 4}}>{subscription ? subscription.type === 2 ? 'Abierta' : 'Cerrada' : '-'}</h6>
                                                    <br/>
                                                    <h2>Monto máximo en productos</h2>
                                                    <Money component='h6' style={{marginTop: 4}}>{this.getMaximumTotalAmount()}</Money>

                                                </div>
                                            </Col>

                                            <Col md={4}>
                                                <div className={'sub-style'} style={{marginTop: 16}}>
                                                    <h2>Transportista encargado de la entrega:</h2>
                                                    <h6 style={{marginTop: 4}}>{activeSubscriptionClient.shipperName || '-'}</h6>
                                                    <br/>
                                                    <h2>Entregas cada</h2>
                                                    <h6 style={{marginTop: 4}}>{subscription ? (subscription.step > 1 ? subscription.step + ' ' + intervals[subscription.intervalType].plural : intervals[subscription.intervalType].singular) : '-'}</h6>
                                                    <br/>
                                                    <h2>Cantidad de entregas</h2>
                                                    <h6 style={{marginTop: 4}}>{subscription ? (subscription.times + (subscription.times > 1 ? ' veces' : ' vez')) : '-'}</h6>
                                                    <br/>
                                                    <h2>Proxima fecha de Entrega:</h2>
                                                    <h6 style={{marginTop: 4}}>{subscription ? (subscription.status ? this.formatDateFromMillis(subscription.nextDate) : '-') : '-'}</h6>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row style={{margin: 16}}>
                                            {subscription && subscription.type === 2 && module === 3000 &&
                                            <Alert style={{
                                                backgroundColor: '#f8d6da',
                                                color: '#721b24',
                                                width: '100%',
                                                textAlign: 'center'
                                            }}>
                                                <b>Atención:</b> Tienes {availableDays} días para modificar los
                                                productos de tu siguiente entrega
                                            </Alert>
                                            }
                                        </Row>
                                    </Col>



                                </Row>

                                {/*<Row style={{marginTop: 4}}></Row>*/}

                                {/*{discount > 0 &&*/}
                                {/*<Row style={{position: 'absolute', bottom: 90, right: 50}}>*/}
                                {/*    <h6 style={{textAlign: 'end'}}>*/}
                                {/*        <del>Q.{formatNumberWithCommas(total / (1 - discount))}</del>*/}
                                {/*    </h6>*/}
                                {/*</Row>*/}
                                {/*}*/}
                                {/*<Row style={{marginLeft: 24, marginRight: 24, justifyContent: 'flex-end'}}>*/}
                                {/*    <h1 style={{textAlign: 'end', marginBottom: 24}}>Q.{formatNumberWithCommas(total)}</h1>*/}
                                {/*</Row>*/}
                                {/*<Row style={{marginLeft: 20, marginRight: 20, justifyContent: 'flex-end'}}>*/}
                                {/*    <Button disabled={true} style={{alignContent: 'center', marginBottom: 20}} variant="danger">*/}
                                {/*        En Curso*/}
                                {/*    </Button>*/}
                                {/*</Row>*/}

                            </div>

                        </Col>

                    </Row>

                    <Row>
                        <Col className={'product-style back-shadow'}>

                            {module === 3000 &&
                            <Row style={{marginTop: 20, marginLeft: 20, marginRight: 20, justifyContent: 'flex-end'}}>
                                {subscription && subscription.type === 2 &&
                                <Col md={3}>
                                    <Button variant={'info'} style={{marginTop: 4}}
                                            onClick={() => this.setState({showProductsModal: true})} block>Modificar
                                        productos</Button>
                                </Col>
                                }

                                <Col>
                                    {
                                        currentStatus === 3 ?
                                            <Alert style={{
                                                backgroundColor: '#f8d6da',
                                                color: '#721b24',
                                                width: '100%',
                                                textAlign: 'center'
                                            }}>
                                                <b>Atención:</b> El total de los productos seleccionados excede del
                                                monto total por una diferencia de
                                                {toMoney(this.totalProductDetail() - this.getMaximumTotalAmount())}
                                            </Alert>
                                            :
                                            currentStatus === 2 ?
                                                <Alert style={{
                                                    backgroundColor: '#fff3cd',
                                                    color: '#8a6b11',
                                                    width: '100%',
                                                    textAlign: 'center'
                                                }}>
                                                    <b>Total de
                                                        productos: </b>{toMoney(this.totalProductDetail())},
                                                    tienes
                                                    {toMoney(this.getMaximumTotalAmount() - this.totalProductDetail())} disponibles.
                                                </Alert>
                                                :
                                                <Alert style={{
                                                    backgroundColor: '#d4edda',
                                                    color: '#145723',
                                                    width: '100%',
                                                    textAlign: 'center'
                                                }}>
                                                    <b>Total de
                                                        productos: </b>{toMoney(this.totalProductDetail())}
                                                </Alert>
                                    }
                                </Col>
                            </Row>
                            }

                            <Row>

                            <div className={'products-container'}>

                                {loading ?
                                    new Array(0,0,0).map((i, index) => <Col key={index} md={3}><Placeholder/></Col>)
                                    :
                                    products.filter((d) => d.checked).map((item, index) =>
                                    {
                                        const ind = Math.floor(Math.random() * 7)
                                        return (
                                            <div style={{marginRight: 10, marginLeft: 10}}>
                                                <Row>
                                                    <Col md={4}>
                                                        <div className={'product-card product-style'}>
                                                            <div className="container-style">
                                                                <img className={'image'} src={images[ind].url} alt=""/>
                                                                <div className={"gradient"}/>
                                                                <h6 className={"bottom-left"} style={{bottom: 34, color:'white', textShadow:'1px 1px #000000aa'}}>{item.productCode}</h6>
                                                                <h1 className={"bottom-left"}>{item.productName}</h1>
                                                            </div>
                                                            <div style={{padding: 10, marginLeft:6, marginRight:6}}>
                                                                <Money component='p'><strong style={{color: '#0c5460'}}>Precio Unitario:</strong> {item.productPrice}</Money>
                                                                <p><b style={{color: '#0c5460'}}>Cantidad</b> {item.quantity}</p>
                                                                <hr style={{margin: 4, padding: 4}}/>
                                                                <Money component='h6' style={{textAlign: 'end'}}><b style={{color: '#0c5460'}}>Total:</b> {(item.productPrice * item.quantity) - item.discount}</Money>
                                                            </div>
                                                        </div>
                                                    </Col>

                                                </Row>
                                            </div>
                                        )
                                    }
                                )}
                            </div>

                            </Row>
                        </Col>


                        {/*<Col>*/}
                        {/*    <CollapsibleCard className={'sub-style'} header={{backgroundColor: '#1b4060'}} title={'Productos'}>*/}

                        {/*        <Row style={{marginLeft: 20, marginRight: 20, justifyContent:'flex-end'}}>*/}
                        {/*            <Col md={3}>*/}
                        {/*                <Button variant={'info'} style={{marginTop: 4}} onClick={()=>this.setState({showProductsModal: true})} block>Modificar productos</Button>*/}
                        {/*            </Col>*/}

                        {/*            <Col>*/}
                        {/*                {*/}
                        {/*                    currentStatus === 2 ?*/}
                        {/*                        <Alert style={{backgroundColor:'#f8d6da', color: '#721b24', width: '100%', textAlign: 'center'}}>*/}
                        {/*                            <b>Atención:</b> El total de los productos seleccionados excede del monto total por una diferencia de Q.{formatNumberWithCommas(this.totalProductDetail()- this.getMaximumTotalAmount())}*/}
                        {/*                        </Alert>*/}
                        {/*                    :*/}
                        {/*                        currentStatus === 1 ?*/}
                        {/*                            <Alert style={{backgroundColor:'#fff3cd', color: '#8a6b11', width: '100%', textAlign: 'center'}}>*/}
                        {/*                                <b>Total de productos:</b> Q.{formatNumberWithCommas(this.totalProductDetail())}, tienes Q.{formatNumberWithCommas(this.getMaximumTotalAmount()-this.totalProductDetail())} disponibles.*/}
                        {/*                            </Alert>*/}
                        {/*                                :*/}
                        {/*                            <Alert style={{backgroundColor:'#d4edda', color: '#145723', width: '100%', textAlign: 'center'}}>*/}
                        {/*                                <b>Total de productos:</b> Q.{formatNumberWithCommas(this.totalProductDetail())}*/}
                        {/*                            </Alert>*/}
                        {/*                }*/}
                        {/*            </Col>*/}
                        {/*        </Row>*/}

                        {/*        <PaginatedTable*/}
                        {/*            loading={loading}*/}
                        {/*            headers={['ID', 'Nombre', 'Precio', 'Cantidad', 'Total']}*/}
                        {/*            items={products.filter((d) => d.checked)}*/}
                        {/*            renderRow={this.renderRowProduct}*/}
                        {/*        />*/}

                        {/*        <br/>*/}

                        {/*    </CollapsibleCard>*/}
                        {/*</Col>*/}

                    </Row>

                    {subscription && subscription.type === 2 &&
                    <Row className={'container-buttons'}>
                        <Button disabled={products.length === 0 || currentStatus === 3 || loadingUP}
                                onClick={() => this.setState({showConfirmation: true})} variant={'danger'} block>
                            {loadingUP &&
                            <Spinner
                                style={{marginRight: 10, marginTop: 4}}
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />}
                            {module === 3000 &&
                                <strong>{loadingUP ? 'Guardando' : 'Guardar cambios en la agrupación'}</strong>
                            }
                            {module === 2000 &&
                            <strong>{loadingUP ? 'Guardando' : 'Llenar canasta y Generar orden'}</strong>
                            }
                        </Button>
                    </Row>
                    }

                </div>

                {subscription &&
                    <ModalSelectProducts companyId={subscription.companyId} productsDefault={productsDefault} show={this.state.showProductsModal} onHide={this.onHideProductsModal} onSelectProducts={this.onSelectProducts}/>
                }

                <SweetAlert
                    {...alert}
                />

                <SweetAlert
                    show={this.state.showConfirmation}
                    title="Información"
                    text={currentStatus ? (currentStatus == 2 ? "Tienes " + toMoney(this.getMaximumTotalAmount()-this.totalProductDetail()) + " disponibles, ¿Deseas de todas formas guardar los cambios en la suscripción?" : "¿Desea guardar los cambios en la suscripción?") : ""}
                    type={"warning"}
                    showCancelButton
                    confirmButtonText="Aceptar"
                    cancelButtonText="Cancelar"
                    onConfirm={() => {
                        this.save()
                    }}
                    onCancel={() => {
                        this.setState({showConfirmation: false});
                    }}
                />

                <SweetAlert
                    show={this.state.showConfirmConfirmation}
                    title="Información"
                    text={activeSubscriptionClient.cotizapClient ? "¿Desea aceptar la solicitud de suscripción de " + activeSubscriptionClient.cotizapClient.companyName + "?" : ""}
                    type={"warning"}
                    showCancelButton
                    confirmButtonText="Aceptar"
                    cancelButtonText="Cancelar"
                    onConfirm={() => {
                        this.acceptSubscription();
                    }}
                    onCancel={() => {
                        this.setState({showConfirmConfirmation: false});
                    }}
                />

                <SweetAlert
                    show={showUpdateSuccess}
                    title={"!Exito!"}
                    text={"Has actualizado los productos dentro de la agrupación exitosamente."}
                    type={"success"}
                    onConfirm={() =>
                        this.setState({showUpdateSuccess: false}, ()=> window.history.back())}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({



    // offer: selectOffer(state),
    // loading: loadingSelector([types.GET_SINGLE_OFFER])(state),
    // loadingAccept: loadingSelector([types.ON_ACCEPT_OFFER])(state),
    // hasErrorAccept: hasErrorsSelector([types.ON_ACCEPT_OFFER])(state),
    // errorAccept: singleErrorSelector([types.ON_ACCEPT_OFFER])(state),

    // currentCompany: selectSetCurrentCompany(state),
    //
    // subscriptionPolygon: selectSubscriptionPolygon(state),
    // loading: loadingSelector([types.GET_SUBSCRIPTION_POLYGON])(state),

    module: selectCurrentModule(state),

    //
    loadingUP: loadingSelector([types.UPDATE_PRODUCTS])(state),
    hasErrorUP: hasErrorsSelector([types.UPDATE_PRODUCTS])(state),
    errorUP: singleErrorSelector([types.UPDATE_PRODUCTS])(state),

    loadingAS: loadingSelector([typeS.ACCEPT_SUBSCRIPTION])(state),
    hasErrorAS: hasErrorsSelector([typeS.ACCEPT_SUBSCRIPTION])(state),
    errorAS: singleErrorSelector([typeS.ACCEPT_SUBSCRIPTION])(state),

    handlerError: (message) => handlerError(message),

    activeSubscriptionClient: selectSubscriptionActiveClient(state),
    loading: loadingSelector([types.GET_ACTIVE_SUBSCRIPTION_CLIENT])(state)
});
const mapDispatchToProps = dispatch => ({
    // getOffer: (id) => dispatch(getSingleOffer(id)),
    // accept: (id, loadEstimateAt) => dispatch(acceptOffer(id, loadEstimateAt)),
    // getSubscriptionPolygon: (id) => dispatch(getSubscriptionPolygon(id)),
    // assignShipper: (id) => dispatch(assignShipper(id))

    getActiveSubscriptionClient: (id) => dispatch(getActiveSubscriptionClient(id)),
    updateProducts: (id, products) => dispatch(updateProducts(id, products)),
    acceptSubscription: (id) => dispatch(acceptSubscription((id)))
});
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionClientDetail);
