import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Alert from 'sweetalert-react'
import { Modal, Row, Col } from 'react-bootstrap'
import { Button, TableV2, Icon, FormText } from 'src/components'
import { faCheck, faEdit, faWindowClose } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  getMaxMinConfigurationToWarehouse,
  createMaxMinItemToWarehouse,
  updateMaxMinItemToWarehouse,
} from 'src/actions/warehouse.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  handlerSuccess,
  hasErrors,
} from '../../../selectors/error.selector'

const WarehouseItemsMinMax = ({ show = false, warehouseId, name = null, onHide }) => {
  const dispatch = useDispatch()

  const items = useSelector(state => state.warehouse.minMaxItems)
  const loading = useSelector(state =>
    loadingSelector([actionTypes.WAREHOUSE_INVENTORY_MIN_MAX])(state),
  )

  const minMaxActionResponse = useSelector(state => state.warehouse.minMaxActionResponse)
  const loadingCU = useSelector(state =>
    loadingSelector([actionTypes.WAREHOUSE_INVENTORY_MIN_MAX_CREATE_UPDATE])(state),
  )
  const error = useSelector(state =>
    hasErrors([actionTypes.WAREHOUSE_INVENTORY_MIN_MAX_CREATE_UPDATE])(state),
  )

  const [flags, setFlags] = useState({ createUpdate: false })
  const [alert, setAlert] = useState({ title: 'undefined' })
  const [seeModal, setModal] = useState(false)
  const [seeItem, setSeeItem] = useState({ show: false })

  useEffect(() => {
    if (!show) return
    setModal(true)
    dispatch(getMaxMinConfigurationToWarehouse(warehouseId))
  }, [show])

  useEffect(() => {
    if (loadingCU) setFlags({ ...flags, createUpdate: true })
    else if (flags.createUpdate) {
      setFlags({ ...flags, createUpdate: false })

      let errorValues

      if (error) {
        errorValues = { show: true, message: error.message }
      } else {
        if (!minMaxActionResponse.ok)
          errorValues = { show: true, message: minMaxActionResponse.message }
        else errorValues = { show: false }
      }

      if (errorValues.show)
        setAlert({
          ...handlerError(errorValues.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Operación exitosa'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            setSeeItem({ show: false })
            dispatch(getMaxMinConfigurationToWarehouse(warehouseId))
          },
        })
    }
  }, [loadingCU])

  return (
    <div>
      <Modal
        show={seeModal && !seeItem.show}
        centered
        size={'lg'}
        onHide={() => {
          setModal(false)
          onHide()
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Minimos y Maximos: {name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <TableV2
                headers={[
                  { label: 'SKU', show: true, value: ['code'], type: 'text' },
                  { label: 'Ítem', show: true, value: ['name'], type: 'text' },
                  { label: 'Mínimo', show: true, value: ['min'], type: 'text' },
                  { label: 'Máximo', show: true, value: ['max'], type: 'text' },
                  { label: 'Notificar', show: true, value: ['minNotify'], type: 'text' },
                  {
                    label: 'Acciones',
                    className: 'mini',
                    show: true,
                    value: [''],
                    config: true,
                    type: 'text',
                    custom: item => (
                      <div className={'d-flex'}>
                        <Icon
                          icon={faEdit}
                          style={{ marginRight: 5 }}
                          onClick={() => {
                            setSeeItem({ ...item, show: true })
                          }}
                        />
                      </div>
                    ),
                  },
                ]}
                loading={loading}
                items={items}
                mobileAuto
                storageKey={'warehouse-min-max-table-filter'}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal show={seeItem.show} centered size={'md'} onHide={() => undefined}>
        <Modal.Header closeButton={false}>
          <Modal.Title>
            {seeItem.code}: {seeItem.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <FormText
                disabled={loadingCU}
                label={'Mínimo'}
                value={seeItem.min}
                type={'number'}
                changeValue={v => {
                  if (!v) v = 0
                  if (v <= 0) v = 0
                  setSeeItem({ ...seeItem, min: v })
                }}
              />
            </Col>
            <Col xl={12}>
              <FormText
                disabled={loadingCU}
                label={'Máximo'}
                value={seeItem.max}
                type={'number'}
                changeValue={v => {
                  if (!v) v = 0
                  if (v <= 0) v = 0
                  setSeeItem({ ...seeItem, max: v })
                }}
              />
            </Col>
            <Col xl={12}>
              <FormText
                disabled={loadingCU}
                label={'Mínimo a notificar'}
                value={seeItem.minNotify}
                type={'number'}
                changeValue={v => {
                  if (!v) v = 0
                  if (v <= 0) v = 0
                  setSeeItem({ ...seeItem, minNotify: v })
                }}
              />
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              disabled={loadingCU}
              color={'secondary'}
              icon={faWindowClose}
              onClick={() => setSeeItem({ show: false })}>
              Cerrar
            </Button>
            <Button
              loading={loadingCU}
              color={'primary'}
              icon={faCheck}
              onClick={() => {
                if (seeItem.id > 0) {
                  dispatch(updateMaxMinItemToWarehouse(warehouseId, [seeItem]))
                } else dispatch(createMaxMinItemToWarehouse(warehouseId, [seeItem]))
              }}>
              Guardar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Alert {...alert} />
    </div>
  )
}
export default WarehouseItemsMinMax
