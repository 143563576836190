// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import { TableV2, Button, Icon } from 'src/components'
import { faBook, faFileExcel } from '@fortawesome/free-solid-svg-icons'

import { formatNumberWithCommas } from 'src/utils/formatters'

import {
  actionTypes,
  getProductMainExistence,
  getExcelExistences,
  getExistenceWithSubitemsAcrossWarehouses,
} from 'src/actions/products.actions'
import {
  selectExistenceWithSubitemsAcrossWarehouse,
  selectProductExistences,
} from 'src/selectors/products.selector'

import { loadingSelector } from 'src/selectors/loading.selector'

import InventoryHistory from 'src/components/inventory/InventoryHistory'

const getSubItems = (subItems, type, defaultValue) => {
  if (!subItems || subItems.length <= 0) return <div>{defaultValue}</div>
  return (
    <div>
      <ul className={'base-sub-items'}>
        {subItems.map(subItem => (
          <li key={subItem.id}>
            <div>
              {type === 1 && subItem.code}
              {type === 2 && subItem.name}
              {type === 4 && formatNumberWithCommas(subItem.available)}
              {type === 5 && formatNumberWithCommas(subItem.existence)}
              {type === 6 && formatNumberWithCommas(subItem.reserved)}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

interface Props {
  show: boolean
  single: boolean
  items: number[]
  onHide: () => void
}

/**
 * Componente para visualizar la existencia de los ítems o de un ítem en concreto.
 * @component
 * @param {boolean} show Indica si el componente debe ser renderizado o no
 * @param {boolean} single Indica si será una visualización de un ítem
 * @param {number[]} items Listado de ítems a visualizar su existencia
 * @param {function} onHide callBack para cerrar el componente desde la app principal
 * */
const GeneralExistence = ({ show, single, items, onHide }: Props) => {
  const dispatch = useDispatch()

  const response = useSelector(selectProductExistences)
  const responseSingle = useSelector(selectExistenceWithSubitemsAcrossWarehouse)
  const loading = useSelector(state =>
    loadingSelector([
      actionTypes.GET_MAIN_PRODUCT_EXISTENCE,
      actionTypes.GET_EXISTENCE_WITH_SUBITEMS_ACROSS_WAREHOUSES,
    ])(state),
  )
  const loadingExcel = useSelector(state =>
    loadingSelector([actionTypes.GET_EXCEL_EXISTENCES])(state),
  )

  const [open, setOpen] = useState(false)
  const [history, setHistory] = useState({ warehouseId: null, itemId: null, name: null })

  useEffect(() => {
    if (!show) return
    setOpen(true)
    if (!single) dispatch(getProductMainExistence(items))
    else dispatch(getExistenceWithSubitemsAcrossWarehouses(items[0]))
  }, [show])

  const onClose = () => {
    setOpen(false)
    onHide()
  }

  const header = [
    {
      label: 'Código Bodega',
      show: single,
      value: ['warehouseCode'],
      type: 'text',
      className: 'mini',
    },
    {
      label: 'Bodega',
      show: single,
      value: ['warehouseName'],
      type: 'text',
      className: 'mini',
    },
    {
      label: 'SKU / Código',
      show: true,
      value: ['productCode'],
      type: 'text',
      className: 'mini',
      custom: item => getSubItems(item.subItems, 1, item.productCode),
    },
    {
      label: 'Nombre',
      show: true,
      value: ['productName'],
      type: 'text',
      className: 'mini',
      style: { minWidth: 150 },
      custom: item => getSubItems(item.subItems, 2, item.productName),
    },
    {
      label: 'Existencia',
      show: true,
      value: single ? ['existence'] : ['productExistence'],
      type: 'text',
      className: 'mini',
      custom: item =>
        getSubItems(
          item.subItems,
          5,
          formatNumberWithCommas(single ? item.existence : item.productExistence),
        ),
    },
    {
      label: '',
      show: single,
      value: [''],
      type: 'text',
      className: 'mini',
      custom: item => (
        <Icon
          tooltip={'Ver histórico'}
          icon={faBook}
          onClick={() => {
            setHistory({
              itemId: item.productId,
              warehouseId: item.warehouseId,
              name: `${item.productCode} ${item.productName}`,
            })
          }}
        />
      ),
    },
  ]

  return (
    <div>
      <Modal
        show={open && !history.warehouseId}
        centered
        size={'lg'}
        onHide={() => onClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Existencias</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              {!single && (
                <Row className={'container-buttons'}>
                  <Button
                    color={'accent'}
                    disabled={loading || loadingExcel}
                    onClick={() => dispatch(getExcelExistences(items))}>
                    <Icon
                      loading={loadingExcel}
                      tooltip={'Descargar Excel'}
                      icon={faFileExcel}
                    />
                  </Button>
                </Row>
              )}
            </Col>
            <Col xl={12}>
              <TableV2
                loading={loading}
                mobileAuto
                items={!single ? response?.list : responseSingle}
                headers={header}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <InventoryHistory
        warehouse={history.warehouseId}
        product={history.itemId}
        productName={history.name}
        close={() => setHistory({ warehouseId: null, itemId: null, name: null })}
      />
    </div>
  )
}
export default GeneralExistence
