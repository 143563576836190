import './ImageInput.scss'
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { ProgressBar } from 'react-bootstrap'
import Alert from 'sweetalert-react'

import { Icon } from '../../index'
import AddImage from 'src/assets/v2/add-image.svg'
import DeleteImage from 'src/assets/v2/icon-delete.svg'

import { actionTypes, deleteFile, uploadFile } from 'src/actions/uploads.actions'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError } from 'src/selectors/error.selector'
import { selectCurrentCompany } from 'src/selectors/user.selector'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const ImageInput = ({
  src,
  onSuccessFile,
  imageType,
  entityId,
  gallery,
  disabled,
  placeholder,
  style,
}) => {
  const dispatch = useDispatch()

  const company = useSelector(selectCurrentCompany)

  const [alert, setAlert] = useState({ title: '' })

  const uploadImageLoading = useSelector(state =>
    loadingSelector([actionTypes.UPLOAD])(state),
  )
  const [uploadProgress, setUploadProgress] = useState(0)

  const loadingDL = useSelector(state =>
    loadingSelector([actionTypes.DELETE_FILE])(state),
  )

  const upload = file => {
    const onProgress = progress => setUploadProgress(progress)
    const onError = () => {
      setUploadProgress(0)
      setAlert({
        ...handlerError('Lo sentimos, no fué posible subir tu imagen en este momento'),
        onConfirm: () => setAlert({ show: false }),
      })
    }

    const onSuccess = (url, name) => {
      setUploadProgress(0)
      if (onSuccessFile) onSuccessFile(url, name)
    }

    dispatch(
      uploadFile({
        file,
        imageType,
        entityId,
        description: '',
        company: company,
        onProgress,
        onSuccess,
        onError,
        gallery,
      }),
    )
  }

  const onDeleteImage = () => {
    if (entityId) {
      dispatch(
        deleteFile({
          id: src.id,
          name: src.name,
          imageType,
          entityId,
          company: company,
          onSuccess: () => onSuccessFile(),
          gallery,
        }),
      )
    } else if (onSuccessFile) onSuccessFile()
  }

  return (
    <div>
      {src && src.url ? (
        <div className="custom-add-logo" style={{ position: 'relative', ...style }}>
          <img
            src={src.url}
            alt="image"
            style={{ objectFit: 'contain', height: '90%', width: '80%' }}
          />
          {!disabled && (
            <div className="custom-delete a-in-opacity" onClick={() => onDeleteImage()}>
              {!loadingDL ? (
                <img src={DeleteImage} alt="delete img" />
              ) : (
                <Icon icon={faSpinner} spin={loadingDL} size={'1_5x'} />
              )}
            </div>
          )}
        </div>
      ) : (
        <div
          className="custom-add-logo"
          style={{ background: '#797c8038', position: 'relative', ...style }}>
          <input
            style={{ visibility: 'hidden' }}
            type="file"
            accept={['.jpeg', '.jpg', '.png', '.svg']}
            name={'file'}
            id={'file'}
            onChange={({ target }) => {
              const { files } = target
              if (files.length !== 0) {
                upload(files[0])
              }
            }}
          />
          <label htmlFor={'file'} style={{ position: 'absolute', textAlign: 'center' }}>
            <img
              src={placeholder || AddImage}
              style={{ cursor: 'pointer' }}
              alt={'logo'}
              height={75}
              width={75}
            />
            <div className="custom-img-text">Agregar imagen</div>
            {uploadImageLoading && (
              <ProgressBar
                style={{ marginTop: 5 }}
                variant={'success'}
                now={uploadProgress}
                label={`${uploadProgress.toFixed(2)}%`}
              />
            )}
          </label>
        </div>
      )}

      <Alert {...alert} />
    </div>
  )
}
export default ImageInput

export const imageTypes = {
  COMPANY: { id: 1, path: '/companies' },
  PRODUCT: { id: 2, path: '/products' },
  WAREHOUSE: { id: 3, path: '/warehouses' },
  CLIENT: { id: 4, path: '/clients' },
  DEPOSITS: { id: 5, path: '/deposits' },
  INVOICES: { id: 5, path: '/invoices' },
  OFFER: { id: 6, path: '/offer' },
  LOGO: { id: 7, path: '/logo' },
  CATEGORY: { id: 8, path: '/categorization' },
  CATEGORY_BANNER: { id: 9, path: '/banner' },
  CATEGORY_ICON: { id: 10, path: '/icon' },
  BADGE_ICON: { id: 11, path: '/badge' },
  POS_LOGO: { id: 12, path: '/pos/logo' },
  POS_OUTSTANDING: { id: 13, path: '/pos/outstanding' },
  POS_TERMINAL_ORDER: { id: 14, path: '/pos/terminal/order' },
  POS_TERMINAL: { id: 16, path: '/pos/terminal' },
  TICKET_LOGO: { id: 17, path: '/ticket/logo' },
  POS_TICKET_LOGO: { id: 18, path: '/pos/ticket/logo' },
}
