import React from 'react'

import { Modal } from 'react-bootstrap'
import { TableV2 } from 'src/components'

const headerValidationResponse = [
  { show: true, label: 'Addon', value: ['name'], type: 'text' },
  {
    show: true,
    label: 'Cantidad Sobrante',
    value: ['code'],
    type: 'number',
  },
]

interface IProps {
  show: boolean
  items: MCompanyAddonsEntityResponse[]
  onHide: () => void
}

/** Shows the list of errors when updating addons or a plan
 * @param {boolean} show Indicate whether the component should be initialized
 * @param {Function} onHide Close the component
 * @param {MCompanyAddonsEntityResponse[]} items List of errors
 * */
const UpdateAddonsErrorModal = ({ show, onHide, items }: IProps) => {
  return (
    <Modal show={show} size={'lg'} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Problemas al actualizar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TableV2
          /* @ts-expect-error*/
          headers={headerValidationResponse}
          mobileAuto
          items={items}
        />
      </Modal.Body>
    </Modal>
  )
}
export default UpdateAddonsErrorModal
