import { $http } from './http'
import { saveAs } from 'file-saver'

class BooksService {
  purchaseBookReport = async params => {
    const response = await $http.get('/books/purchase', { params })
    return response.data
  }
  purchaseBookReportExcel = async params => {
    const response = await $http.get('/books/purchase/excel', {
      params,
      responseType: 'blob',
    })
    const name = 'Libro de compras.xlsx'
    const file = new Blob([response.data], { type: 'application/vnd.ms-excel' })
    saveAs(file, name)
  }

  cppBookReportExcel = async params => {
    const response = await $http.get('/books/cpp/excel', {
      params,
      responseType: 'blob',
    })
    const name = 'Cuentas por pagar.xlsx'
    const file = new Blob([response.data], { type: 'application/vnd.ms-excel' })
    saveAs(file, name)
  }
}
export default new BooksService()
