import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";

import {Row, Col} from "react-bootstrap";
import {Td, Tr} from "react-super-responsive-table";

import {
    faArrowLeft,
    faArrowRight,
    faBackspace, faChevronRight, faTrash
} from "@fortawesome/free-solid-svg-icons";

import Icon from "../../../components/buttons/IconButton";
import Card from "../../../components/cards/Card";
import Button from "../../../components/buttons/Button";

import {setChangeLink} from "../../../actions/offers.actions";
import {selectLinkSingle} from "../../../selectors/offers.selector";

import {actionTypes as type} from "../../../actions/products.actions";

import {
    selectOwnProducts
} from "../../../selectors/products.selector";

import {selectCurrentCompany} from "../../../selectors/user.selector";

import {loadingSelector} from "../../../selectors/loading.selector";
import FormTextField from "../../../components/inputs/FormTextField/FormTextField";
import FormText from "../../../components/inputs/FormTextField/FormText";
import { TableV2 } from '../../../components';

const ContractProducts = (props) => {
    const dispatch = useDispatch();

    const link = useSelector(selectLinkSingle);
    const company = useSelector(selectCurrentCompany);
    const loading = useSelector((state) => loadingSelector([type.GET_ALL_PRODUCTS])(state));
    const products = useSelector(selectOwnProducts);

    const [index, setIndex] = useState(1);
    const [cust, setCust] = useState('');

    useEffect(() => {
        setIndex(1);
    }, [cust]);

    const filterProducts = (item) => {
        const product = link.products.find((p) => p.productId === item.productId);
        return !product && (item.companyId === company) && item.activeLink;
    };

    const addProduct = (item) => {
        link.products.push({...item, amount: 0});
        dispatch(setChangeLink(link));
        setCust(JSON.stringify(link));
    };

    const deleteItem = (item, all) => {
        if (!all) {
            const index = link.products.findIndex((p) => p.productId === item.productId);
            if (index !== -1) {
                link.products.splice(index, 1);
            }
        } else link.products = [];

        dispatch(setChangeLink(link));
        setCust(JSON.stringify(link));
    };

    const renderRow = (item, index, isDelete) => {
        return (<Tr className={'data'} key={index}>
            <Td className={'mini'}>{item.productCode}</Td>
            <Td className={'medium'}>{item.productName}</Td>
            {(link.type === 2 && isDelete) && <Td className={'medium'}>
                <FormTextField
                    value={item.amount}
                    onMouseOver={(e) => e.stopPropagation()}
                    onClick={(e) => e.stopPropagation()}
                    type={'number'}
                    onChange={(event) => {
                        event.stopPropagation();
                        let {value} = event.target;
                        if (!value || value < 0) value = 0;
                        item.amount = value;
                        const index = link.products.findIndex((p) => p.productId === item.productId);
                        link.products[index] = item;

                        dispatch(setChangeLink(link));
                        setCust(JSON.stringify(link));
                    }}
                    className={'edit-input'}
                />
            </Td>}
            <Td className={'mini'}><Icon
                icon={isDelete ? faTrash : faChevronRight}
                tooltip={isDelete ? 'Descartar' : 'Agregar'}
                onClick={() => isDelete ? deleteItem(item, index) : addProduct(item)}
            /></Td>
        </Tr>);
    };

    const getHeader = () => {
        
        const header = ['Código', 'Producto'];
        if (link.type === 2)
            header.push('Monto');
        header.push('');
        return header;
    };

    const getHeaders = (a) => {
        const header =  [
            {label: 'Código', show: true, value: ['amount'], type: 'text' ,  className:'mini'},
            {label: 'Producto', show: true, value: ['amount'], type: 'text' ,  className:'mini'}

        ]
        if (link.type === 2 && a )
            header.push({label: 'Monto', show: true, value: ['amount'], type: 'text' ,  className:'mini'});
        header.push({config:true, show: true, label:'', className:'mini center'});
        return header;
    }

    return (<div className={'column'}>
        <Row>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}><Card title={'Productos fuera del enlace'}>
                <Row className={'container-buttons'}>
                    <Button variant={'secondary'} icon={faArrowRight} right onClick={() => {
                        products.filter(filterProducts).map((p) => addProduct(p));
                    }}
                    >Agregar todo</Button>
                </Row>
                <TableV2
                    headers={getHeaders(false)}
                    items={products ? products.filter(filterProducts) : []}
                    loading={loading}
                    mobileAuto
                    storageKey={`contractAddAll`}
                />
                {/* <Table
                    headers={['Código', 'Producto', '']}
                    items={products ? products.filter(filterProducts) : []}
                    renderRow={(item, index) => renderRow(item, index, false)}
                    loading={loading}
                /> */}
            </Card></Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}><Card title={'Productos dentro del enlace'}>
                <div className={'left column'}>
                    <Button variant={'secondary'} icon={faArrowLeft} left onClick={() => deleteItem(null, true)}
                    >Remover todo</Button>
                </div>
                <TableV2
                    headers={getHeaders(true)}
                    items={link.products}
                    mobileAuto
                    storageKey={`contractRemoveAll`}
                />
                {/* <Table
                    headers={getHeader()}
                    items={link.products}
                    renderRow={(item, index) => renderRow(item, index, true)}
                /> */}
            </Card></Col>
        </Row>

        <Row className={'container-buttons'}>
            <Button left icon={faBackspace} onClick={() => props.jumpToStep(0)}>Atras</Button>
            <Button variant={'info'} right icon={faArrowRight} onClick={() => props.jumpToStep(2)}>Resumen</Button>
        </Row>

    </div>);
};
export default ContractProducts;
