export const selectSubscriptions = state => state.subscriptions.subscriptions
export const selectSubscriptionsClient = state => state.subscriptions.subscriptionsClient
export const selectSubscriptionsActiveClient = state =>
  state.subscriptions.subscriptionsActiveClient
export const selectSubscriptionActiveClient = state =>
  state.subscriptions.subscriptionActiveClient
export const selectSubscriptionsCompany = state =>
  state.subscriptions.subscriptionsCompany
export const selectSubscriptionsShipper = state =>
  state.subscriptions.subscriptionsShipper
export const selectSubscriptionsPolygons = state =>
  state.subscriptions.subscriptionsPolygons
export const selectSubscriptionsPolygonsDistribution = state =>
  state.subscriptions.subscriptionsPolygonsDistribution
export const selectSubscriptionPolygon = state => state.subscriptions.subscriptionPolygon
