import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'

import { Button, Icon, TableV2, Checkbox } from '../../index'

import {
  actionTypes as typeO,
  getExcelByProducts,
  getProductsOnOrders,
} from 'src/actions/orders.actions'
import { selectProductReport } from 'src/selectors/orders.selector'

import { loadingSelector } from 'src/selectors/loading.selector'

const ModalOrderSummary = ({ show, params, onClose }) => {
  const dispatch = useDispatch()

  const loading = useSelector(state =>
    loadingSelector([typeO.GET_PRODUCTS_ON_ORDERS])(state),
  )
  const items = useSelector(selectProductReport)
  const loadingE = useSelector(state => loadingSelector([typeO.GET_EXCEL])(state))

  const [actions, setActions] = useState({ get: false })
  const [open, setOpen] = useState(false)
  const [presentation, setPresentation] = useState(false)

  useEffect(() => {
    if (!show) return
    setUp(presentation)
  }, [show])

  useEffect(() => {
    if (loading) setActions({ ...actions, get: true })
    else if (actions.get) {
      setActions({ ...actions, get: false })
      setOpen(true)
    }
  }, [loading])

  const setUp = presentationValue => {
    dispatch(getProductsOnOrders({ ...params, presentation: presentationValue }))
  }

  const getHeaders = () => {
    return [
      {
        label: 'SKU',
        show: true,
        value: ['code'],
        type: 'text',
        className: 'mini',
        select: true,
      },
      {
        label: 'Ítem',
        show: true,
        value: ['name'],
        type: 'text',
        className: 'medium',
      },
      {
        label: 'Cantidad',
        show: true,
        value: ['quantity'],
        type: 'number',
        className: 'mini',
      },
      {
        label: 'Total',
        show: true,
        value: ['total'],
        type: 'currency',
        className: 'mini',
      },
      {
        label: 'Descuento',
        show: true,
        value: ['discount'],
        type: 'currency',
        className: 'mini',
      },
    ]
  }

  const onHide = () => {
    setOpen(false)
    setPresentation(false)
    onClose()
  }

  return (
    <div>
      <Modal
        show={open}
        centered
        size={'lg'}
        onHide={() => {
          onHide()
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Sumatoria de productos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <Row>
                <Col>
                  <Checkbox
                    checked={!presentation}
                    disabled={loading}
                    label={'Presentación simple'}
                    changeValue={() => {
                      setPresentation(false)
                      setUp(false)
                    }}
                  />
                </Col>
                <Col>
                  <Checkbox
                    checked={presentation}
                    disabled={loading}
                    label={'Presentación por árbol de ítems'}
                    changeValue={() => {
                      setPresentation(true)
                      setUp(true)
                    }}
                  />
                </Col>
              </Row>
            </Col>

            <Col xl={12}>
              <Row className={'container-buttons'}>
                <Button
                  disabled={loading || items.length === 0}
                  onClick={() => dispatch(getExcelByProducts(items))}>
                  <Icon spin={loadingE} icon={faFileExcel} tooltip={'descargar'} />
                </Button>
              </Row>
            </Col>

            <Col xl={12} lg={12} md={12}>
              <TableV2
                headers={getHeaders()}
                loading={loading}
                items={items}
                mobileAuto
                storageKey={'modalOrderSumm'}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ModalOrderSummary
