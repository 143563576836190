import { actionTypes } from 'src/actions/address.actions'
import { extract, successState } from 'src/actions/global.actions'

const initialState = {
  allRegions: [],
  singleRegion: {},
  regionLocalities: [],
  allLocalities: [],
  singleLocality: {},
}

const DepartmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_ALL_REGIONS):
      return {
        ...state,
        allRegions: extract(action.payload, []),
      }
    case successState(actionTypes.GET_SINGLE_REGION):
      return {
        ...state,
        singleRegion: extract(action.payload, {}),
      }
    case successState(actionTypes.GET_LOCALITIES_BY_REGION):
      return {
        ...state,
        regionLocalities: extract(action.payload, []),
      }
    case successState(actionTypes.GET_ALL_LOCALITIES):
      return {
        ...state,
        allLocalities: extract(action.payload, []),
      }
    case successState(actionTypes.GET_SINGLE_LOCALITY):
      return {
        ...state,
        singleLocality: extract(action.payload, {}),
      }
    default:
      return state ? state : initialState
  }
}

export default DepartmentsReducer
