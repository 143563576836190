import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actionTypes as restaurantActions } from 'src/actions/restaurant.actions'
import { selectOrderConcepts, selectPosConcepts } from 'src/selectors/restaurant.selector'
import { orderPermissions, posPermissions, quotePermissions } from 'src/enums/permissions'
import { isAllowed } from 'src/selectors/modules.selector'
import CustomCreate from 'src/components/inputs/Creable/CustomCreate'
import CustomSelect from 'src/components/inputs/Select/CustomSelect'
import { selectFelItemTypes } from 'src/selectors/company.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { setModalCreateConcepts } from 'src/actions/utilities.actions'
import { hasErrorsSelector } from 'src/selectors/error.selector'

const defaultConcept = { label: 'Sin seleccionar', value: null }

interface IProps {
  value: typeof defaultConcept
  onChange: (value: typeof defaultConcept) => void
  onCreateOption?: (name: string) => void
  posId?: number
  isQuote?: boolean
}

/**
 * @Component
 * @description Form field to select a concept to invoice
 * @param value - Selected concept
 * @param onChange - Function to handle the change of the selected concept
 * @param onCreateOption - Function to handle the creation of a new concept
 * @param posId - Id of the pos
 * @param isQuote - If the concept is for a quote
 * @param props - Rest of the props of the component
 */
const InvoiceConceptField = ({
  value,
  onChange,
  onCreateOption,
  posId = undefined,
  isQuote = false,
  ...props
}: IProps) => {
  const dispatch = useDispatch()

  const felItemTypes = useSelector(selectFelItemTypes)
  const concepts = useSelector(posId ? selectPosConcepts : selectOrderConcepts).map(
    concept => ({
      label: concept.name,
      value: concept.id,
    }),
  )
  const allConcepts = [defaultConcept, ...concepts]

  const [actions, setActions] = useState({
    concepts: false,
    createConcept: false,
  })

  const loadingConcepts = useSelector(state =>
    loadingSelector([restaurantActions.GET_POS_INVOICE_CONCEPTS])(state),
  )
  const hasErrorConcepts = useSelector(state =>
    hasErrorsSelector([restaurantActions.GET_POS_INVOICE_CONCEPTS])(state),
  )

  const canCreateOrderConcepts = useSelector(state =>
    isAllowed(state, [orderPermissions.createConceptsAgile]),
  )

  const canCreateQuoteConcepts = useSelector(state =>
    isAllowed(state, [quotePermissions.createConceptsAgile]),
  )

  const canPosCreateConcepts = useSelector(state =>
    isAllowed(state, [
      posPermissions.createConceptsAgile,
      quotePermissions.createConceptsAgile,
    ]),
  )

  useEffect(() => {
    if (loadingConcepts) setActions({ ...actions, concepts: true })
    else if (actions.concepts) {
      setActions({ ...actions, concepts: false, createConcept: false })
      if (!hasErrorConcepts && actions.createConcept)
        onChange(allConcepts[allConcepts.length - 1])
    }
  }, [loadingConcepts])

  const canCreateConcepts = posId
    ? canPosCreateConcepts
    : isQuote
    ? canCreateQuoteConcepts
    : canCreateOrderConcepts

  const ConceptComponent = canCreateConcepts ? CustomCreate : CustomSelect

  return (
    <ConceptComponent
      label={'Concepto a facturar'}
      placeholder={'Concepto'}
      info={
        'Si no se selecciona un concepto se usarán los items de la venta en la factura.'
      }
      textLabel={'Crear concepto: '}
      isLoading={loadingConcepts}
      options={allConcepts}
      value={value?.value ? value : defaultConcept}
      onChange={onChange}
      onCreateOption={name => {
        dispatch(
          setModalCreateConcepts({
            show: true,
            concept: {
              name,
              felItemType: felItemTypes[0].value,
              posId,
            },
          }),
        )
        setActions({ ...actions, createConcept: true })
        if (onCreateOption) onCreateOption(name)
      }}
      dataCy={'input-concept'}
      {...props}
    />
  )
}

export default InvoiceConceptField
