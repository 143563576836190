import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API,
  plugins: [new BugsnagPluginReact()],
  appVersion: process.env.REACT_APP_VERSION,
})

const BugsnagErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

export { BugsnagErrorBoundary }
