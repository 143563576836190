import { store } from 'src/reducers'
import { formatNumberWithCommas } from 'src/utils/formatters'
import WarehouseService from 'src/services/warehouse.service'
import { getDiffBetweenPoints } from 'src/selectors/geolocation.selecteor'
import { selectCompanyFieldById } from 'src/selectors/company.selector'
import { haveAnyValue } from 'src/utils/utilitiesV2'

export const formatDistance = distance => {
  let number = distance > 1000 ? distance / 1000 : distance
  return `${formatNumberWithCommas(number)}  ${distance > 1000 ? 'km' : 'm'}`
}

export const arrayMove = (array, oldIndex, newIndex) => {
  if (newIndex >= array.length) {
    let k = newIndex - array.length + 1
    while (k--) {
      array.push(undefined)
    }
  }
  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0])
  return array
}

export const valEmail = email => {
  if (email === null || email === undefined || email === '') return false

  // eslint-disable-next-line no-useless-escape
  return /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
    email,
  )
}

export const getNumber = number => {
  let response = parseFloat(number || 0).toFixed(2)
  return Number.parseFloat(response)
}

const getTicketLogo = customUrl => {
  const activeTicketsLogo = getState(state => selectCompanyFieldById(state, 129))
  const ticketsLogo = getState(state => selectCompanyFieldById(state, 130))

  const url =
    customUrl && customUrl !== 'undefined'
      ? customUrl
      : activeTicketsLogo?.value === 'true'
      ? ticketsLogo?.value
      : ''

  return haveAnyValue(url) && url !== 'undefined' && url !== 'null'
    ? `<img class="logo center" src="${url}" />`
    : ''
}

export const printTicket = (body, size, customWindow, customUrl) => {
  const sizePage = '@page{margin: 0;}'

  const logo = getTicketLogo(customUrl)

  const w = customWindow || window

  const pr = w.open('', 'PRINT')

  if (pr) {
    pr.document.write('<!DOCTYPE html>')
    pr.document.write('<html><head><title>Ticket</title>')
    pr.document.write('<meta charset="UTF-8">')
    pr.document.write(
      '<meta name="viewport" content="width=device-width, initial-scale=1.0">',
    )
    pr.document.write('<meta http-equiv="X-UA-Compatible" content="ie=edge">')
    pr.document.write(
      `<style type="text/css">
        html, body {
        -webkit-print-color-adjust: exact;
        margin: 0;
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 97%;
        padding: 1px;
        }
        * {
        font-size: 11px;
        font-family: "Montserrat", sans-serif;
        }
        .total-row * {
        font-size: 17px;
        font-weight: 500;
        }
        td,th,tr,table {
        border-top: 1px solid black;
        border-collapse: collapse;
        }
        td.product, th.product {
        width: 75px;
        max-width: 75px;
        }
        td.quantity, th.quantity {
        word-break: break-all;
        }
        td.price, th.price {
        width: 40px;
        max-width: 40px;
        word-break: break-all;
        }
        .center {
        text-align: center;
        align-content: center;
        margin: 10px auto;
        }
        .ticket {
        width: 155px;
        max-width: 155px;
        }
        .logo {
          display: block;
          height: 50px;
        }

        ${sizePage}
      </style>`,
    )
    pr.document.write('</head>')
    pr.document.write(
      `<body>
        ${logo}
        ${body}
      </body>
    </html>`,
    )

    pr.document.close()
    pr.focus()

    setTimeout(() => {
      pr.print()
      setTimeout(() => pr.close(), 10000)
    }, 500)
  }
}

export const printGiftTicket = ({ name, address, reference, date, items, dontCut }) => {
  const data = [
    { type: dontCut ? -1 : 8, value: null },
    { type: 1, value: 1 },
    { type: 2, value: 2 },
    { type: 3, value: true },
    { type: 4, value: 1 },
    { type: 5, value: name },
    { type: 4, value: 2 },
    { type: 1, value: 2 },
    { type: 3, value: false },
    { type: 5, value: address + '\n' },
    { type: 5, value: 'TICKET DE REGALO\n' },
    { type: 2, value: 1 },
    { type: 5, value: `Código: ${reference}\n` },
    { type: 1, value: 1 },
    { type: 6, value: date },
    { type: 7, value: null },
  ]

  items.forEach(({ quantity, description }) => {
    quantity = quantity + ''
    while (quantity.length < 5) {
      quantity += ' '
    }
    data.push(
      ...[
        { type: 5, value: quantity },
        { type: 5, value: description + '\n' },
      ],
    )
  })
  data.push({ type: 7, value: null })

  return data
}

export const capitalizeString = string => {
  if (!string) return ''

  const words = string.split(' ')
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1))

  return capitalizedWords.join(' ')
}

export const getState = getter => {
  return getter(store.getState())
}

export const toMoney = (
  quantity,
  noSymbol = false,
  decimals = 2,
  customDecimals = false,
) => {
  const symbol = getState(state => state.currencies.currentCurrency.symbol)
  const money = formatNumberWithCommas(quantity || 0, decimals, customDecimals)
  return `${noSymbol ? '' : !symbol ? 'Q.' : `${symbol} `}${money}`
}

const currency = [
  { symbol: 'Q', code: 'GTQ', exchange: 7.6 },
  { symbol: '$', code: 'MXN', exchange: 20.32 },
  { symbol: '$', code: 'USD', exchange: 7.6 },
  { symbol: '€', code: 'EUR', exchage: 0.95 },
]

export const getExchangeRate = codeTo => {
  return currency.find(curr => curr.code === codeTo)
}

export const getPOSDetails = items => {
  const response = []
  items?.forEach(i => {
    const item = {
      productId: i.id,
      price: i.price,
      tableOrderId: i.tableOrderId,
      discount: i.discount,
      type: i.type,
      commentary: i.commentary,
      description: i.suppliesText,
      parentDetailId: i.parentId,
    }
    if (!i.variable || !i.supplies) {
      item.quantity = i.quantity
      item.ingredients = i.supplies
        ? i.supplies.map(is => ({
            productId: is.id,
            originalQuantity: is.original || 0,
            quantity: !is.variable
              ? is.original + is.quantity * is.original
              : is.quantity + (is.original || 0),
            extra: is.price,
            variable: is.variable,
            quantityToInventory: is.quantityToInventory,
          }))
        : []
      item.tags = getTagsForSaveProducts(i.tags)
      item.tag = i.tag
      response.push(item)
    } else {
      let tagIndex = 0
      i.supplies.forEach(is => {
        for (let index = 0; index < is.quantity; index++) {
          response.push({
            ...item,
            quantity: 1,
            tags: getTagsForSaveProducts([i.tags[tagIndex]]),
            ingredients: [
              {
                productId: is.id,
                originalQuantity: 0,
                quantity: 1,
                extra: is.price,
                variable: is.variable,
                quantityToInventory: is.quantityToInventory,
              },
            ],
          })
          tagIndex++
        }
      })
    }
  })

  return response
}

function getTagsForSaveProducts(tags) {
  let response = ''
  if (tags)
    tags
      .filter(t => t.tag)
      .forEach((t, i) => {
        const sign = tags.length - 1 === i ? '' : ','
        response += t.tag + sign
      })
  return response
}

export const validateGeoLocation = async (
  webLocation,
  wialonDeviceId,
  pointValidation,
  messageError,
  ignoreWarehouseLocation,
  ownPrincipalWarehouse,
) => {
  const { isGeolocationAvailable, isGeolocationEnabled, coords } = webLocation

  let cc = { latitude: 0, longitude: 0 }
  let message = null
  let ok = false

  const gpsCoords =
    ignoreWarehouseLocation && !ownPrincipalWarehouse
      ? null
      : await WarehouseService.getGPSCoords(wialonDeviceId, ownPrincipalWarehouse)

  if (gpsCoords && gpsCoords.length > 0) {
    cc = {
      latitude: gpsCoords[0].latitude,
      longitude: gpsCoords[0].longitude,
    }
  } else {
    if (!isGeolocationAvailable)
      message = 'Este dispositivo no es compatible con Geolocalización'
    else if (!isGeolocationEnabled) message = 'La Geolocalización no esta activada'
    else if (!coords)
      message = 'No fue posible obtener la localización de este dispositivo'
    else {
      cc = {
        latitude: coords.latitude,
        longitude: coords.longitude,
      }
    }
  }
  if (!message && pointValidation) {
    const distance = getDiffBetweenPoints(
      pointValidation?.latitude,
      pointValidation?.longitude,
      cc.latitude,
      cc.longitude,
    )
    if (distance > 300) {
      message = messageError + formatNumberWithCommas(parseInt(distance)) + ' metros'
    } else ok = true
  } else if (!pointValidation) ok = true

  return { ok, message, cc }
}

export const getPhraseLabel = (phrase, i) => {
  let label = phrase.phrase + ''
  const withResolution = [4, 32, 33].some(r => r === phrase.id)

  if (withResolution && i.resolution && i.dateResolution) {
    label = label
      .replace('XXXX', i?.resolution || 'XXXX')
      .replace('dd/mm/aaaa', i?.dateResolution || 'dd/mm/aaaa')
  }
  return label
}

/**
 * Get measurement unit symbol by id
 * @param {number} id Id of measurement unit
 * @returns string
 */
export const getMeasurementSymbol = id => {
  const measurementUnits = getState(state => state.products.measurementUnits)
  if (measurementUnits)
    return measurementUnits.find(item => item.id === id)?.symbol || 'u.'
}

export const objectFullCopy = object => JSON.parse(JSON.stringify(object))

export const itemTypes = {
  SELL: 1,
  WASTE: 2,
  PRODUCTION: 3,
  PURCHASE: 4,
  EXPENSE: 5,
  TRANSFER: 6,
  TRANSFER_TRANSFORMATION: 7,
  ADDONS: 8,
  VISIT_DISPATCH: 9,
  CREDIT_NOTE_ITEMS: 10,
  POS: 11,
  RECIPE_ITEMS: 12,
  DEPOSIT: 13,
}

export const filterObject = (obj, predicate) =>
  Object.fromEntries(Object.entries(obj).filter(predicate))
