import React, { Component } from 'react'

import './DashboardCard.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'

export default class DashboardCard extends Component {
  render() {
    const { icon, name, key, selected, small, fontIcon, invert, notification } =
      this.props
    return (
      <div
        key={key}
        className="no-select"
        onClick={this.props.onClick}
        style={{ position: 'relative' }}
      >
        <div className={`dashboard-card ${selected ? 'selected' : ''}`}>
          {icon && (
            <img
              src={icon}
              className={`dashboard-option-icon ${small ? 'small' : ''} ${
                invert ? 'invert' : ''
              }`}
              height={30}
              width={30}
              alt="icon"
            />
          )}
          {fontIcon && (
            <FontAwesomeIcon
              className={`dashboard-option-icon ${small ? 'small' : ''}`}
              size={small ? 'lg' : '10x'}
              icon={fontIcon}
            />
          )}
          <div className={`dashboard-option-name ${small ? 'small' : ''}`}>{name}</div>
        </div>
        {notification > 0 && (
          <div className={'unread unread-transform'}>
            <div style={{ fontSize: 16, marginBottom: 5 }}>{notification}</div>
          </div>
        )}
      </div>
    )
  }
}
