import { faFileInvoice, faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'src/components/buttons/Button'
import FormTextField from 'src/components/inputs/FormTextField/FormTextField'
import Select from 'src/components/inputs/Select/CustomSelect'
import CustomDatePicker from 'src/components/CustomDatePicker/CustomDatePicker'
import IconButton from 'src/components/buttons/IconButton'
import Gallery, { imageTypes } from 'src/components/gallery/Gallery'
import { depositsPermissions } from 'src/enums/permissions'
import { isAllowed, selectUsersToSelect } from 'src/selectors/modules.selector'
import { selectGetAccountsForSelect } from 'src/selectors/banks.selector'
import { selectReceipt, selectReference } from 'src/selectors/deposits.selector'
import { getAccounts } from 'src/actions/banks.actions'
import {
  createDeposit,
  getReceipt,
  getReference,
  updateDepositData,
} from 'src/actions/deposits.actions'
import {
  selectAllCurrencies,
  selectCurrentCurrency,
} from 'src/selectors/currencies.selector'
import NumberField from 'src/components/NumberField/NumberField'

let interval = null
let intervalReceipt = null

enum currencies {
  DOLLAR = 2,
}

interface IProps {
  show: boolean
  onHide: (refresh?: boolean) => void
  depositDetail?: IDeposit
  loading?: boolean
}

/**
 * Component to create or update deposit
 * @param {boolean} show Show the modal
 * @param {function} onHide Function to close the modal
 * @param {IDeposit} depositDetail Deposit detail to edit
 * @param {boolean} loading Loading state
 * @returns
 */
export const Deposit = ({ show, onHide, depositDetail, loading }: IProps) => {
  const dispatch = useDispatch()

  const [deposit, setDeposit] = useState<IDeposit>({
    account: null,
    bank: null,
    currency: null,
    userId: null,
    registerDate: null,
    date: null,
    number: null,
    receipt: '',
    amount: 0,
    description: '',
    change: null,
  })
  const [images, setImages] = useState([])
  const [loadingOperation, setLoadingOperation] = useState(false)
  const accounts: ISelect[] = useSelector(selectGetAccountsForSelect)
  const referenceValue = useSelector(selectReference)
  const users = useSelector(selectUsersToSelect)
  const receiptValue = useSelector(selectReceipt)
  const currency: ICurrency = useSelector(selectCurrentCurrency)
  const allCurrencies: ICurrency[] = useSelector(selectAllCurrencies)
  const isEdit = depositDetail !== undefined

  const numberReference = useSelector(state =>
    isAllowed(state, [depositsPermissions.canRepiteReference]),
  )
  const createMovementByAnotherUser = useSelector(state =>
    isAllowed(state, [depositsPermissions.createDepositoToOtherUser]),
  )

  useEffect(() => {
    if (accounts.length === 0) dispatch(getAccounts(false))
  }, [])

  useEffect(() => {
    if (loading) {
      setLoadingOperation(true)
    } else if (loadingOperation) {
      setLoadingOperation(false)
      onCloseModal()
    }
  }, [loading])

  useEffect(() => {
    if (depositDetail) {
      setDeposit(depositDetail)
    }
  }, [depositDetail])

  const getCurrencyById = (id: number): ICurrency => {
    return allCurrencies.find(currency => currency.id === id)
  }

  const uploadImage = (url: string, name: string) => {
    setImages([...images, { url, original: url, thumbnail: url, name }])
  }

  const deleteImages = () => {
    setImages([])
  }

  const onCloseModal = (refresh?: boolean) => {
    setImages([])
    setDeposit({
      account: null,
      bank: null,
      currency: null,
      userId: null,
      registerDate: null,
      date: null,
      number: null,
      receipt: '',
      amount: 0,
      description: '',
      change: null,
    })
    onHide(refresh)
  }

  const disabledAction =
    !deposit.bank ||
    (deposit.bank !== 5 && !deposit.number) ||
    (!deposit.change && deposit.currency === currencies.DOLLAR) ||
    !deposit.amount ||
    deposit.amount <= 0 ||
    (numberReference && referenceValue)

  return (
    <>
      <Modal show={show} centered size={'lg'} onHide={() => onCloseModal()}>
        <Modal.Header>
          <Modal.Title>Nuevo depósito</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={6} md={6} xs={12}>
              <Select
                label={'Cuenta de Banco'}
                isSearchable={false}
                options={accounts}
                value={accounts?.find(account => account.id === deposit.account)}
                info={'Seleccionar la cuenta bancaria'}
                onChange={account =>
                  setDeposit({
                    ...deposit,
                    account: account.id,
                    currency: account.currency,
                    bank: account.bank,
                  })
                }
                required
                dataCy={'account'}
              />
            </Col>
            {createMovementByAnotherUser && (
              <Col xl={6} md={6} xs={12}>
                <Select
                  label={'Asignar a otro usuario'}
                  isSearchable={false}
                  placeholder={'Opcional'}
                  options={users}
                  value={users.find(user => user.id === deposit.userId)}
                  info={'Seleccionar el usuario al que se le asignará el depósito'}
                  onChange={value => setDeposit({ ...deposit, userId: value.id })}
                  dataCy={'user'}
                />
              </Col>
            )}
            <Col xl={6} md={6} xs={12}>
              <CustomDatePicker
                label={'Fecha de registro:'}
                value={deposit.date}
                disabledDays={{ after: new Date() }}
                onDayChange={date => setDeposit({ ...deposit, date })}
              />
            </Col>

            <Col xl={6} md={6} xs={12} className="mt-3">
              <NumberField
                value={Number(deposit.number)}
                maxLength={16}
                label={
                  deposit.bank !== 5
                    ? 'Número de referencia'
                    : 'El número de referencia sera asignado de forma automática'
                }
                disabled={!deposit.bank || deposit.bank === 5}
                append={<IconButton tooltip={'Referencia'} icon={faFileInvoice} />}
                appendBefore
                error={
                  numberReference && deposit.number && referenceValue
                    ? 'Nota: El número de referencia ya fue ingresado anteriormente.'
                    : null
                }
                onValueChange={value => {
                  setDeposit({ ...deposit, number: !value ? 0 : value })
                  if (value !== null && value !== undefined) {
                    clearTimeout(interval)
                    interval = setTimeout(() => {
                      dispatch(getReference(value, { accountId: deposit.account }))
                    }, 750)
                  }
                }}
                required={deposit.bank && deposit.bank !== 5}
                data-testid={'referenceNumber'}
              />
            </Col>

            <Col xl={6} md={6} xs={12}>
              <FormTextField
                prepend={<IconButton icon={faFileInvoice} />}
                label={'Recibo'}
                placeholder={'pj. 1234'}
                name={'receipt'}
                value={deposit.receipt}
                sub={
                  receiptValue
                    ? 'Nota: El recibo ingresado ya fue ingresado anteriormente'
                    : null
                }
                info={'No. de recibo del deposito'}
                onChange={({ target }) => {
                  const value = target.value
                  setDeposit({ ...deposit, receipt: value })
                  if (value !== null && value !== undefined && value !== '') {
                    clearTimeout(intervalReceipt)
                    intervalReceipt = setTimeout(() => {
                      dispatch(getReceipt(target.value))
                    }, 750)
                  }
                }}
                data-testid={'receipt'}
              />
            </Col>

            <Col xl={6} md={6} xs={12} className="mt-3">
              <NumberField
                value={deposit.amount}
                append={deposit.bank && currency.symbol}
                appendBefore
                label={'Monto del depósito'}
                required
                onValueChange={amount => setDeposit({ ...deposit, amount })}
                info={'Monto del efectivo que se va a depositar'}
                decimals={2}
                disabled={isEdit ? depositDetail?.reference !== null : false}
                data-testid={'amount'}
              />
            </Col>

            {deposit.bank && deposit.currency === currencies.DOLLAR && (
              <>
                <Col />
                <Col xl={6} md={6} xs={12}>
                  <NumberField
                    label={'Tasa de conversión'}
                    value={deposit.change}
                    onValueChange={change => setDeposit({ ...deposit, change })}
                    appendBefore
                    append={deposit.bank && getCurrencyById(deposit.currency)?.symbol}
                    required
                    decimals={2}
                    data-testid={'conversion'}
                  />
                </Col>
              </>
            )}

            <Col xl={12} xs={12}>
              <FormTextField
                as={'textarea'}
                rows={3}
                label={'Descripción'}
                placeholder={'Opcional'}
                name={'description'}
                value={deposit.description}
                onChange={({ target }) => {
                  setDeposit({ ...deposit, description: target.value })
                }}
                max={300}
                data-testid={'description'}
              />
            </Col>
            <Col xl={12} md={12} xs={12}>
              <Gallery
                imageType={imageTypes.DEPOSITS}
                images={isEdit ? undefined : images}
                onUploadSuccess={uploadImage}
                onDelete={deleteImages}
                title={'Agregar foto de depósito'}
                limit={1}
                maxWidth={256}
                company={depositDetail?.company}
                entityId={depositDetail?.id}
              />
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              disabled={loading}
              color={'secondary'}
              icon={faWindowClose}
              dataCy={'button-close'}
              onClick={() => onCloseModal()}>
              Cerrar
            </Button>
            <Button
              loading={loading}
              disabled={disabledAction}
              color={'primary'}
              dataCy={'button-save'}
              icon={faSave}
              onClick={() => {
                const request = {
                  amount: deposit.amount,
                  bank: deposit.account,
                  userId: deposit.userId,
                  number: deposit.number,
                  own: true,
                  description: deposit.description,
                  currency: deposit.currency,
                  change: deposit.change,
                  receipt: deposit.receipt,
                  registerDate: deposit.date ? deposit.date.valueOf() : null,
                }
                if (isEdit) dispatch(updateDepositData(depositDetail.id, request))
                else dispatch(createDeposit(request, images))
              }}>
              {isEdit ? 'Actualizar' : 'Registrar'}
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  )
}
