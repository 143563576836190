import React, { Component } from "react";
import {
  selectCategorization,
  selectCategorizationTree,
  selectCategorizationTypeForSelect,
  selectReport,
} from "../../../../selectors/categorizations.selector";
import {
  getCategorization,
  actionTypes,
  createCategorization,
  updateCategorization,
  deleteCategorization,
  getAllTypes,
  copyByTree,
  copyByList,
  getProductsByCategorization,
  deleteListCategorization,
  moveByList,
} from "../../../../actions/categorization.actions";
import { connect } from "react-redux";
import { Row, Col, Modal } from "react-bootstrap";
import {
  faTrashAlt,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import Card from "../../../../components/cards/Card";
import Button from "../../../../components/buttons/Button";
import { loadingSelector } from "../../../../selectors/loading.selector";
import FormTextField from "../../../../components/inputs/FormTextField/FormTextField";
import { faSave, faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index.es";
import IconButton from "../../../../components/buttons/IconButton";
import {
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
  singleErrorSelector,
} from "../../../../selectors/error.selector";
import Alert from "sweetalert-react";

import "./CategorizationDetail.scss";
import CustomSelect from "../../../../components/inputs/Select/CustomSelect";
import {
  selectCurrentCompany,
  selectCurrentModule,
} from "../../../../selectors/user.selector";
import Gallery, { imageTypes } from "../../../../components/gallery/Gallery";
import CarouselGallery from "../../../../components/CarouselGallery/CarouselGallery";
import CollapsibleCard from "../../../../components/cards/collapsible-card/CollapsibleCard";
import Gallery2 from "../../../../components/gallery/Gallery2";
import FoldersComponent from "../../../../components/folders/FoldersComponent";

import CustomColorPicker from "../../../../components/colorpicker/CustomColorPicker";
import {history} from "../../../../App";
import {isAllowed} from "../../../../selectors/modules.selector";
import { TableV2 } from "../../../../components";

class CategorizationDetail extends Component {
  state = {
    categorizationId: null,
    categorization: {},
    newCategorization: {},
    errors: {},
    categorizationChildren: [],
    showSuccess: false,
    showError: false,
    showCopy: false,
    showInfo: false,
    successAlert: {
      show: false,
      type: "info",
      showCancelButton: false,
      confirmButtonText: "Aceptar",
      confirmButtonColor: "green",
      title: "¡Yeah!",
      onConfirm: () => console.log("confirm"),
      onCancel: () => console.log("cancel"),
    },
    deleteAlert: {
      title: "Eliminar categorización",
      text: `¿Desea eliminar la categorización seleccionada?`,
      type: "warning",
      showCancelButton: true,
    },
    selectedModal: { value: 0, label: "Seleccione un tipo" },
    selectedForm: { value: 0, label: "Seleccione un tipo" },
    list: [],
    destiny: 0,
    selectDestiny: false,
    isCopy: false,
    images: [],
    banner: null,
    isRoot: false,
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    const { module } = this.props;
    this.setState({ categorizationId: id });
    this.props.getCategorization(id);
    if (module === 11000) this.props.getProducts(id);
  }

  componentWillReceiveProps(next) {
    const {categorization, match, createIsLoading, createHasError, getCategorization, deleteCategorizationHasError, deleteCategorizationIsLoading,
      updateCategorizationIsLoading, updateCategorizationHasError, typesIsLoading, copyTreeIsLoading, copyListIsLoading,
      copyTreeHasError, copyListHasError, moveListIsLoading, moveListHasError, module} = this.props;



    const { copyTreeError, copyListError, moveListError } = next;

    let { successAlert } = this.state;

    const categorizationData = next.categorization;

    if (typesIsLoading && !next.typesIsLoading) {
      next.types.map((t) => {
        if (categorization.type === t.value) {
          this.setState({ selectedForm: t });
        }
      });
    }

    if (updateCategorizationIsLoading && !next.updateCategorizationIsLoading) {
      if (!updateCategorizationHasError && next.updateCategorizationHasError) {
        this.setState({ showSuccess: false, showError: true });
      } else {
        successAlert.show = true;
        successAlert.text = this.state.categorization.type === 2 ? "Gasto modificado exitosamente" : "Categorización modificada exitosamente";
        successAlert.onConfirm = () => {
          this.props.getCategorization(Number(this.props.match.params.id));
          this.setState({ successAlert: { ...successAlert, show: false } });
        };
        successAlert.onCancel = () => {
          this.setState({ successAlert: { ...successAlert, show: false } });
        };
      }
      this.setState({ successAlert });
    }

    if (createIsLoading && !next.createIsLoading) {
      if (!createHasError && next.createHasError) {
        this.setState({ showSuccess: false, showError: true });
      } else {
        this.deleteImage(this.state.images, 0);
        getCategorization(next.match.params.id);
        successAlert.show = true;
        successAlert.text = this.state.categorization.type === 2 ? 'Gasto creado con éxito' : 'Categorización creada con éxito'
        successAlert.onConfirm = () => {
          this.setState({ successAlert: { ...successAlert, show: false } });
        };
        successAlert.onCancel = () => {
          this.setState({ successAlert: { ...successAlert, show: false } });
        };
      }
      this.setState({ successAlert });
    }
    if (deleteCategorizationIsLoading && !next.deleteCategorizationIsLoading) {
      if (!deleteCategorizationHasError && next.deleteCategorizationHasError) {
        this.setState({ showSuccess: false, showError: true });
      } else {
        getCategorization(next.match.params.id);
        successAlert = handlerSuccess(this.state.categorization.type === 2 ? 'Gasto eliminado exitosamente' : "Categorización eliminada exitosamente.");
        successAlert.onConfirm = () =>{
          this.setState({ successAlert: { ...successAlert, show: false } });
          if(this.state.isRoot){
            history.push(`/${module === 1000 ? 'distribucion' : module === 2000 ? 'produccion' : 'admin'}/categorizaciones`)
          }
        }
      }
      this.setState({ successAlert });
    }
    if (match.params.id !== next.match.params.id) {
      this.setState({ categorizationId: next.match.params.id });
      getCategorization(next.match.params.id);
    }

    if (categorization !== categorizationData) {
      this.props.getAllTypes();
      this.setState({categorization: categorizationData,});
    }

    if (copyTreeIsLoading && !next.copyTreeIsLoading) {
      if (!copyTreeHasError && next.copyTreeHasError) {
        this.setState({ showSuccess: false, showError: true });
        console.log(copyTreeError.response ? copyTreeError.response.data.message : "Ha ocurrido un error inesperado.");
        successAlert = handlerError(
          copyTreeError.response
            ? copyTreeError.response.data.message
            : "Ha ocurrido un error inesperado."
        );
        successAlert.onConfirm = () => {
          this.setState({ successAlert: { ...successAlert, show: false } });
        };
        successAlert.onCancel = () => {
          this.setState({ successAlert: { ...successAlert, show: false } });
        };
      } else {
        successAlert = handlerSuccess("Copiado exitoso");
        successAlert.onConfirm = () => {
          this.setState({ successAlert: { ...successAlert, show: false } });
          this.props.getCategorization(this.props.match.params.id);
        };
        successAlert.onCancel = () => {
          this.setState({ successAlert: { ...successAlert, show: false } });
        };
      }
      this.setState({ successAlert });
    }

    if (copyListIsLoading && !next.copyListIsLoading) {
      if (!copyListHasError && next.copyListHasError) {
        this.setState({ showSuccess: false, showError: true });
        console.log(
          copyListError.response
            ? copyListError.response.data.message
            : "Ha ocurrido un error inesperado."
        );
        successAlert = handlerError(
          copyListError.response
            ? copyListError.response.data.message
            : "Ha ocurrido un error inesperado."
        );
        successAlert.onConfirm = () => {
          this.setState({ successAlert: { ...successAlert, show: false } });
        };
        successAlert.onCancel = () => {
          this.setState({ successAlert: { ...successAlert, show: false } });
        };
      } else {
        successAlert = handlerSuccess("Copiado exitoso");
        successAlert.onConfirm = () => {
          this.setState({ successAlert: { ...successAlert, show: false } });
          this.props.getCategorization(this.props.match.params.id);
        };
        successAlert.onCancel = () => {
          this.setState({ successAlert: { ...successAlert, show: false } });
        };
      }
      this.setState({ successAlert });
    }

    if (moveListIsLoading && !next.moveListIsLoading) {
      if (!moveListHasError && next.moveListHasError) {
        this.setState({ showSuccess: false, showError: true });
        console.log(
          moveListError.response
            ? moveListError.response.data.message
            : "Ha ocurrido un error inesperado."
        );
        successAlert = handlerError(
          moveListError.response
            ? moveListError.response.data.message
            : "Ha ocurrido un error inesperado."
        );
        successAlert.onConfirm = () => {
          this.setState({ successAlert: { ...successAlert, show: false } });
        };
        successAlert.onCancel = () => {
          this.setState({ successAlert: { ...successAlert, show: false } });
        };
      } else {
        successAlert = handlerSuccess("Categorizaciones movidas con éxito");
        successAlert.onConfirm = () => {
          this.setState({ successAlert: { ...successAlert, show: false } });
          this.props.getCategorization(this.props.match.params.id);
        };
        successAlert.onCancel = () => {
          this.setState({ successAlert: { ...successAlert, show: false } });
        };
      }
      this.setState({ successAlert });
    }
  }

  onAddChild = (parentCategorizationId) => {
    const confirmAction = () => {
      const { newCategorization, categorization, images } = this.state;
      const { module } = this.props;
      newCategorization.type = categorization.type;
      this.props.createCategorization(
        { ...newCategorization, parentCategorizationId },
        categorization.type === 4 ? images : null,
        categorization.type !== 4 ? images : null
      );
      this.setState({ showConfirm: false, newCategorization: {} });
    };
    this.setState({ showConfirm: true, confirmAction });
  };

  handleDeleteCategorization = (categorizations, id) => {
    const { deleteAlert } = this.state;

    const action = () => {
      this.props.deleteListCategorization(categorizations, Number(id));
      deleteAlert.show = false;
      let isRoot = categorizations.filter(f => f === Number(id)).length > 0
      this.setState({ deleteAlert, list: [], destiny: 0, isRoot });
    };
    deleteAlert.onConfirm = action;
    deleteAlert.onCancel = () => {
      this.setState({ deleteAlert: { ...deleteAlert, show: false } });
    };
    deleteAlert.show = true;
    this.setState({ deleteAlert });
  };

  changeMainForm = (event) => {
    const { name, value } = event.target;
    const { categorization } = this.state;
    categorization[name] = value;
    this.setState({ categorization });
  };

  onTypesChangeForm = (e) => {
    let { categorization } = this.state;
    categorization.type = e.value;
    this.setState({ selectedForm: e, categorization });
  };

  changeModalForm = (event) => {
    const { name, value } = event.target;
    const { newCategorization } = this.state;
    newCategorization[name] = value;
    this.setState({ newCategorization });
  };

  onTypesChangeModal = (e) => {
    let { newCategorization } = this.state;
    newCategorization.type = e.value;
    this.setState({ selectedModal: e, newCategorization });
  };

  save = () => {
    const { categorization, categorizationId } = this.state;
    const errors = {};

    if (!categorization.name) {
      errors.name = "El nombre es requerido";
    }
    if (!categorization.description) {
      errors.description = "La descripción es requerida";
    }
    if (!categorization.type) {
      errors.type = "El tipo es requerido";
    }
    categorization.publicCategorization = categorization.public;

    if (Object.keys(errors).length === 0) {
      this.props.updateCategorization(categorizationId, categorization);
    }
    this.setState({ errors });
  };


  action = (action) => {
    const { list, destiny } = this.state;
    let request = [];
    list.map((l) => {
      request.push(l.id);
    });
    if (action === 0) this.props.moveByList(request, destiny);
    else if (action === 1) this.props.copyByList(request, destiny);
    else if (action === 2) this.props.copyByTree(request, destiny);
    this.setState({list: [], destiny: 0, selectDestiny: false, isCopy: false, showCopy: false,});
  };

  selectCategorization = (item) => {
    const { list, selectDestiny } = this.state;
    if (selectDestiny) {
      this.setState({ destiny: item.id });
    } else {
      let add = true;
      list.map((l, index) => {
        if (l.id === item.id) {
          list.splice(index, 1);
          add = false;
        }
      });
      if (add) list.push(item);
      this.setState({ list });
    }
  };

  handleOnChange = (newValue) => {
    this.setState({ categorizationChildren: newValue });
  };



  handleCheckBoxChange = (event) => {
    const { name, checked } = event.target;
    let { categorization } = this.state;
    categorization[name] = checked;
    this.setState({ categorization });
  };

  handleChangeColor = (color) => {
    const { categorization } = this.state;
    this.setState({ categorization: { ...categorization, color } });
  };

  deleteSelected = () => {
    const { id } = this.props.match.params;
    const { list } = this.state;
    let categorizations = [];
    list.map((l) => {
      categorizations.push(l.id);
    });
    this.handleDeleteCategorization(categorizations, Number(id));
  };

  uploadImage = (url, name) => {
    this.setState({
      images: [
        ...this.state.images,
        { url, original: url, thumbnail: url, name: name },
      ],
    });
  };

  deleteImage = (image, index) => {
    const { images } = this.state;
    images.splice(index, 1);
    this.setState({ images: new Array(...images) });
  };

  render() {
    const { categorization, errors, showConfirm, confirmAction, successAlert, newCategorization, categorizationId, deleteAlert,
      selectedForm, showCopy, selectDestiny, showInfo, list, isCopy, images, banner} = this.state;
    const {types, report, module, company, categorizationLoading} = this.props;

    const { companies, products } = report;

    return (
      <div>
        {categorization.type === 4 && (
          <Row>
            <Col lg={12} md={12} sm={12}>
              <CollapsibleCard
                title={"Imágenes"}
                header={{ backgroundColor: "white", color: "black" }}
                classHeader={"kolo-company"}
                colorButton={"black"}
              >
                <Row>
                  {!categorization.parent && (
                    <Col xs={12} md={4}>
                      <div>
                        <h4 className={"kolo-company"}>Banner</h4>
                      </div>
                      <CarouselGallery
                        company={categorization.company}
                        imageType={imageTypes.CATEGORY_BANNER}
                        entityId={categorization && categorization.id}
                        showUploader={categorization.company === company}
                        noItemLegend={"Sin banner para mostrar"}
                        maxWidth={300}
                        limit={5}
                      />
                    </Col>
                  )}
                  <Col xs={12} md={4}>
                    <div>
                      <h4 className={"kolo-company"}>Logo</h4>
                    </div>
                    <Card style={{ height: 345 }}>
                      <Gallery
                        company={categorization.company}
                        imageType={imageTypes.CATEGORY}
                        entityId={categorization && categorization.id}
                        showUploader={categorization.company === company}
                        noItemLegend={"Sin logo para mostrar"}
                        maxWidth={256}
                        limit={1}
                      />
                    </Card>
                  </Col>
                  {!categorization.parent && (
                    <Col xs={12} md={4}>
                      <div>
                        <h4 className={"kolo-company"}>Ícono</h4>
                      </div>
                      <Card style={{ height: 345 }}>
                        <Gallery2
                          company={categorization.company}
                          imageType={imageTypes.CATEGORY_ICON}
                          entityId={categorization && categorization.id}
                          showUploader={categorization.company === company}
                          noItemLegend={"Sin ícono para mostrar"}
                          maxWidth={300}
                          limit={1}
                        />
                      </Card>
                    </Col>
                  )}
                </Row>
              </CollapsibleCard>
            </Col>
          </Row>
        )}
        <Row>
          <Col sm={12} xs={12} md={4} lg={4}>
            <Card title={"Categoría"}>
              {categorization.type !== 4 && (
                <Col xs={12} md={12}>
                  <Gallery2
                    company={categorization.company}
                    imageType={imageTypes.CATEGORY_ICON}
                    entityId={categorization && categorization.id}
                    showUploader={categorization.company === company}
                    noItemLegend={"Sin ícono para mostrar"}
                    maxWidth={150}
                    limit={1}
                  />
                </Col>
              )}
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <FormTextField
                    id={"name"}
                    label={"Nombre"}
                    placeholder={"Nombre"}
                    type={"text"}
                    name={"name"}
                    value={categorization.name}
                    onChange={this.changeMainForm}
                    required
                    error={errors.name}
                  />
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <FormTextField
                    id={"description"}
                    label={"Descripción"}
                    placeholder={"Descripción"}
                    type={"text"}
                    name={"description"}
                    value={categorization.description}
                    onChange={this.changeMainForm}
                    required
                    error={errors.address}
                  />
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <CustomSelect
                    value={selectedForm}
                    options={types}
                    onChange={this.onTypesChangeForm}
                    label={"Tipo de categorización"}
                    error={errors.selected}
                    disabled={true}
                  />
                </Col>
                { categorization.name && (
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <CustomColorPicker
                          color={categorization.color}
                          onChangeColor={this.handleChangeColor}
                      />
                    </Col>
                )}
                <br/>
                {module === 11000 && (
                  <Col lg={12} md={12} sm={12}>
                    <label className={"checkbox-label"}>
                      <input
                        type={"checkbox"}
                        name={"public"}
                        checked={categorization.public}
                        onChange={this.handleCheckBoxChange}
                      />{" "}
                      {categorization.public
                        ? "Publica: todas las empresas podran utilizar esta categorización"
                        : "Privada: Solo esta empresa podra utilizar esta categorización"}
                    </label>
                  </Col>
                )}
                {module === 11000 && (
                  <Col lg={12} md={12} sm={12}>
                    <label className={"checkbox-label"}>
                      <input
                        type={"checkbox"}
                        name={"principal"}
                        checked={categorization.principal}
                        onChange={this.handleCheckBoxChange}
                      />
                      Seleccionar como categoría principal
                    </label>
                  </Col>
                )}
              </Row>
              {categorization.company === company && (
                <Row>
                  <Col style={{ alignSelf: "flex-end", textAlign: "right" }}>
                    <Button
                      className={"align-bottom"}
                      onClick={() => this.props.history.goBack()}
                      variant={"secondary"}
                    >
                      <FontAwesomeIcon icon={faTimes} /> Regresar
                    </Button>
                    <Button className={"align-bottom"} onClick={this.save}>
                      <FontAwesomeIcon icon={faSave} /> Guardar
                    </Button>
                  </Col>
                </Row>
              )}
            </Card>
          </Col>
          <Col sm={12} xs={12} md={8} lg={8}>
            <Card title={"Subcategorias"} white>
              <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                <Col xs={2}>
                  {/*<IconButton
                    icon={faInfo}
                    onClick={() => this.setState({ showInfo: true })}
                    size={"2x"}
                    color={"rgba(15,20,180,0.6)"}
                  />*/}
                </Col>
                <Col
                  xs={10}
                  style={{ alignSelf: "flex-end", textAlign: "right" }}
                >
                  {categorization.company === company &&
                    list.length > 0 &&
                    !selectDestiny && (
                      <Button
                        variant={"danger"}
                        className={"align-bottom"}
                        onClick={() => this.deleteSelected()}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                        Borrar
                      </Button>
                    )}
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FoldersComponent add={this.props.add} remove={this.props.remove}
                    categorization={this.props.categorization}
                    onAdd={(node) => node.company === company && this.onAddChild(node.id)}
                    onSelect={(list) => this.setState({list})}
                    loading={categorizationLoading}
                    edit={categorization.company === company}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {companies.length > 0 && (
          <Row>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              <Card title={"Empresas que utilizan la categoria"}>
                <TableV2
                  headers={[
                    { label: 'Código', show: true, value: ['alphanumericId'], type: 'text', className: 'mini' },
                    { label: 'Empresa', show: true, value: ['name'], type: 'text', className: 'mini'},
                    { config: true, show: true, label: '', className: 'mini center' }
                  ]}
                  items={companies}
                  mobileAuto
                  storageKey={`categorizationCompanies`}
                />
              </Card>
            </Col>
            <Col xl={8} lg={8} md={8} sm={12} xs={12}>
              <Card title={"Productos que utilizan la categoria"}>
                <TableV2
                  headers={[
                    { label: 'Código', show: true, value: ['code'], type: 'text', className: 'medium' },
                    { label: 'Producto', show: true, value: ['name'], type: 'text', className: 'mini' },
                    { label: 'Empresa', show: true, value: ['empresaC'], type: 'text', className: 'mini',
                      custom:(item)=> item.empresaC =  `${item.companyData.alphanumericId} - ${item.companyData.name}` },
                    { config: true, show: true, label: '', className: 'mini center' }
                  ]}
                  items={products}
                  mobileAuto
                  storageKey={`categorizationProducts`}
                />
              </Card>
            </Col>
          </Row>
        )}
        {/*****************************************************
         ***********************MODAL CREATE***********************
         * ****************************************************/}
        <Modal
          show={showConfirm}
          size={"md"}
          centered
          onHide={() => this.setState({ showConfirm: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span>Crear Categorización</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <Gallery
                  imageType={
                    categorization.type === 4
                      ? imageTypes.CATEGORY
                      : imageTypes.CATEGORY_ICON
                  }
                  images={images}
                  onUploadSuccess={this.uploadImage}
                  onDelete={this.deleteImage}
                  noItemLegend={
                    module === 11000
                      ? "Sin logo para mostrar"
                      : "Sin icono para mostrar"
                  }    limit={1}
                  />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <FormTextField
                      id={"name"}
                      label={"Nombre"}
                      placeholder={"Nombre"}
                      type={"text"}
                      name={"name"}
                      value={newCategorization.name}
                      onChange={this.changeModalForm}
                      required
                      error={errors.name}
                  />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <FormTextField
                      id={"description"}
                      label={"Descripción"}
                      placeholder={"Descripción"}
                      type={"text"}
                      name={"description"}
                      value={newCategorization.description}
                      onChange={this.changeModalForm}
                      required
                      error={errors.description}
                  />
                </Col>
                {
                  selectedForm && selectedForm.value === 4 &&
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <FormTextField
                        id={"color"}
                        label={"Color"}
                        placeholder={"Color"}
                        type={"text"}
                        name={"color"}
                        value={newCategorization.color}
                        onChange={this.changeModalForm}
                    />
                  </Col>
                }
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => { confirmAction() ;
              }}

            >
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>
        {/*****************************************************
         *********************MODAL COPY/MOVE*********************
         * ****************************************************/}
        <Modal
          show={showCopy}
          size={"md"}
          centered
          onHide={() =>
            this.setState({
              showCopy: false,
              list: [],
              selectDestiny: false,
              destiny: 0,
            })
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span>
                {isCopy ? "Copiar Categorizaciones" : "Mover Categorizaciones"}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <h4>Elementos por {isCopy ? "copiar" : "mover"}</h4>
              </Col>
            </Row>
            <TableV2
              headers={[
                {label: 'Nombre', show: true, value: ['name'], type: 'text' ,  className:'medium'},
                {label: 'Descripción', show: true, value: ['description'], type: 'text' ,  className:'mini'},
                {config:true, show: true, label:'', className:'mini center'}
              ]}
              items={list}
              mobileAuto
              storageKey={`categorizationElements`}
            />
            <br />
            <Row>
              <Col
                className={"text-center"}
                style={{ color: "rgba(197,147,48,0.6)" }}
              >
                <h5>{isCopy && "LEYENDA"}</h5>
              </Col>
            </Row>
            <Row>
              {list.length === 1 && isCopy && (
                <Col>
                  <h6>
                    Copiar Árbol: Copia la categoría seleccionada junto con sus
                    subcategorías
                  </h6>
                </Col>
              )}
            </Row>
            <Row>
              {isCopy && (
                <Col>
                  <h6>
                    Copiar Lista: Copia <b>Unicamente</b> las categorías
                    seleccionadas, sin sus subcategorías
                  </h6>
                </Col>
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {isCopy ? (
              <>
                <Button
                  onClick={() => {
                    this.action(1);
                  }}
                >
                  Copiar Lista
                </Button>
                <Button
                  onClick={() => {
                    this.action(2);
                  }}
                >
                  Copiar Árbol
                </Button>
              </>
            ) : (
              <Button
                onClick={() => {
                  this.action(0);
                }}
              >
                Mover
              </Button>
            )}
          </Modal.Footer>
        </Modal>

        {/*****************************************************
         ***********************MODAL INFO***********************
         * ****************************************************/}
        <Modal
          show={showInfo}
          size={"lg"}
          centered
          onHide={() => this.setState({ showInfo: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span>Información de las categorizaciones</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card title={"Descripción"}>
              <Row>
                <Col>
                  <h6>
                    Las categorizaciones son utilizadas para organizar los
                    productos según su como la empresa lo vea necesario
                  </h6>
                </Col>
              </Row>
            </Card>
            <Card title={"Funcionalidad"}>
              <Row>
                <Col>
                  <ul>
                    <li>
                      <h6 style={{ display: "flex" }}>
                        Para crear una categorización principal puede ir a la
                        página principal de las
                        <span
                          style={{
                            marginLeft: 5,
                            cursor: "pointer",
                            backgroundColor: "rgba(29,38,186,0.67)",
                            color: "white",
                            borderRadius: 5,
                          }}
                          onClick={() =>
                            this.props.history.push(
                              "/produccion/categorizaciones"
                            )
                          }
                        >
                          {" "}
                          categorizaciones
                        </span>
                      </h6>
                    </li>
                  </ul>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ul>
                    <li>
                      <h6>
                        Para crear una subcategoría el usuario debe pulsar el
                        botón agregar similar al siguiente
                      </h6>
                    </li>
                  </ul>
                </Col>
              </Row>
              <Row>
                <Col style={{ display: "flex", justifyContent: "center" }}>
                  <Button variant={"success"} className={"align-bottom"}>
                    <FontAwesomeIcon icon={faPlus} /> Agregar
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ul>
                    <li>
                      <h6>
                        Las subcategorías también pueden tener subcategorías,
                        para esto se debe pulsar el siguiente botón ubicado en
                        cada item de la tabla
                      </h6>
                    </li>
                  </ul>
                </Col>
              </Row>
              <Row>
                <Col style={{ display: "flex", justifyContent: "center" }}>
                  <IconButton icon={faPlus} size={"2x"}/>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ul>
                    <li>
                      <h6>
                        Se pueden copiar las subcategorías a otra subcategoría,
                        para esto existen 2 modos:
                      </h6>
                      <ul>
                        <li>
                          Por Árbol: Copia la subcategoría con todas las
                          subcategorías relacionadas a esta misma
                        </li>
                      </ul>
                      <ul>
                        <li>
                          Por Lista: Selecciona varias subcategorías y las copia
                          sin sus subcategorías relacionadas
                        </li>
                      </ul>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Card>
            <Card title={"Copiar"}>
              <Row>
                <Col>
                  <ul>
                    <li>
                      <h6>
                        Para copiar se debe dar click al nombre de la
                        subcategoría, este se pinta de un color azul, luego de
                        haber seleccionado cada subcategoría se da click en el
                        siguiente botón
                      </h6>
                    </li>
                  </ul>
                </Col>
              </Row>
              <Row>
                <Col style={{ display: "flex", justifyContent: "center" }}>
                  <Button variant={"info"} className={"align-bottom"}>
                    <FontAwesomeIcon icon={faCopy} /> Copiar
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ul>
                    <li>
                      <h6>
                        Ahora se debe seleccionar una subcategoría destino, que
                        es donde todas las subcategorías se copiarán, al
                        seleccionarla se pintará de color azul. Después se da
                        click al siguiente botón
                      </h6>
                    </li>
                  </ul>
                </Col>
              </Row>
              <Row>
                <Col style={{ display: "flex", justifyContent: "center" }}>
                  <Button variant={"success"} className={"align-bottom"}>
                    <FontAwesomeIcon icon={faSave} /> Guardar
                  </Button>
                </Col>
              </Row>
            </Card>
          </Modal.Body>
          <Modal.Footer/>
        </Modal>

        <Alert {...deleteAlert} />
        <Alert {...successAlert} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  categorization: selectCategorization(state),
  types: selectCategorizationTypeForSelect(state),
  typesIsLoading: loadingSelector([actionTypes.GET_TYPES])(state),
  categorizationTree: selectCategorizationTree(state),
  categorizationLoading: loadingSelector([actionTypes.GET_CATEGORIZATION])(
    state
  ),
  createIsLoading: loadingSelector([actionTypes.CREATE_CATEGORIZATION])(state),
  createHasError: hasErrorsSelector([actionTypes.CREATE_CATEGORIZATION])(state),
  createError: singleErrorSelector([actionTypes.CREATE_CATEGORIZATION])(state),
  deleteCategorizationIsLoading: loadingSelector([
    actionTypes.DELETE_CATEGORIZATION,
  ])(state),
  deleteCategorizationHasError: hasErrorsSelector([
    actionTypes.DELETE_CATEGORIZATION,
  ])(state),
  updateCategorizationIsLoading: loadingSelector([
    actionTypes.UPDATE_CATEGORIZATION,
  ])(state),
  updateCategorizationHasError: hasErrorsSelector([
    actionTypes.UPDATE_CATEGORIZATION,
  ])(state),
  copyTreeIsLoading: loadingSelector([actionTypes.COPY_BY_TREE])(state),
  copyTreeHasError: hasErrorsSelector([actionTypes.COPY_BY_TREE])(state),
  copyTreeError: singleErrorSelector([actionTypes.COPY_BY_TREE])(state),
  copyListIsLoading: loadingSelector([actionTypes.COPY_BY_LIST])(state),
  copyListHasError: hasErrorsSelector([actionTypes.COPY_BY_LIST])(state),
  copyListError: singleErrorSelector([actionTypes.COPY_BY_LIST])(state),
  moveListIsLoading: loadingSelector([actionTypes.MOVE_BY_LIST])(state),
  moveListHasError: hasErrorsSelector([actionTypes.MOVE_BY_LIST])(state),
  moveListError: singleErrorSelector([actionTypes.MOVE_BY_LIST])(state),
  report: selectReport(state),
  module: selectCurrentModule(state),
  company: selectCurrentCompany(state),
  add: isAllowed(state, [6002, 6102]),
  edit: isAllowed(state, [6003, 6103]),
  remove: isAllowed(state, [6004, 6104]),
});
const mapDispatchToProps = (dispatch) => ({
  getCategorization: (categorizationId) =>
    dispatch(getCategorization(categorizationId)),
  createCategorization: (categorization, images, icon) =>
    dispatch(createCategorization(categorization, images, null, icon)),
  updateCategorization: (categorizationId, categorization) =>
    dispatch(updateCategorization(categorizationId, categorization)),
  deleteCategorization: (categorizationId) =>
    dispatch(deleteCategorization(categorizationId)),
  deleteListCategorization: (categorizations, parent) =>
    dispatch(deleteListCategorization(categorizations, parent)),
  handlerError: (message) => handlerError(message),
  handlerSuccess: (message) => handlerSuccess(message),
  getAllTypes: () => dispatch(getAllTypes()),
  copyByTree: (list, parent) => dispatch(copyByTree(list, parent)),
  copyByList: (list, parent) => dispatch(copyByList(list, parent)),
  moveByList: (list, parent) => dispatch(moveByList(list, parent)),
  getProducts: (id) => dispatch(getProductsByCategorization(id)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategorizationDetail);
