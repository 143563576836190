import { $http } from './http'
import { saveAs } from 'file-saver'

class DisbursementsService {
  getDisbursementsHistory = async () => {
    const response = await $http.get(`/disbursements`)
    return response.data
  }

  createDisbursementsRequest = async (amount, params) => {
    const response = await $http.post(`/disbursements`, amount, { params })
    return response.data
  }

  getDisbursementsBalance = async () => {
    const response = await $http.get(`/disbursements/balance`)
    return response.data
  }

  getDisbursementsBalanceCotizap = async () => {
    const response = await $http.get(`/disbursements/balance/cotizap`)
    return response.data
  }

  getDisbursementsBalanceDetails = async () => {
    const response = await $http.get(`/disbursements/balance/details`)
    return response.data
  }

  getDisbursementsAdmin = async filters => {
    let route = '/disbursements/admin'
    Object.keys(filters).map(f => (route += `?${f}=${filters[f]}`))

    const response = await $http.get(route)
    return response.data
  }

  getSingleDisbursement = async id => {
    const response = await $http.get(`/disbursements/${id}`)
    return response.data
  }

  putSingleDisbursementsAdmin = async (id, status, description) => {
    const response = await $http.put(`/disbursements/admin/${id}/${status}`, description)
    return response.data
  }

  approveSingleDisbursementsAdmin = async (id, document) => {
    const response = await $http.put(`/disbursements/admin/${id}`, document)
    return response.data
  }

  getCSV = async id => {
    const response = await $http.get(`/disbursements/approved/csv/${id}`)
    let text = response.data
      .split('\n')
      .map(line => line.split(/\s+/).join(' '))
      .join('\n')

    let csvContent = 'data:text/csv;charset=utf-8,'
    csvContent += text
    saveAs(csvContent, 'desembolsos.csv')
    return response.data
  }

  onCSV = async (id, file) => {
    let formData = new FormData()
    formData.append('file', file)

    const response = await $http.post(`/disbursements/approved/csv/${id}`, formData, {
      headers: { 'content-type': 'multipart/form-data' },
    })
    return response.data
  }

  getDisburstmentRequestsByParameter = async params => {
    const response = await $http.get('/disbursements/admin', { params })
    return response.data
  }
}

export default new DisbursementsService()
