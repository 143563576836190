import React from 'react'
import { getItemValue } from 'src/utils/formatters'

interface IProps {
  item: IItemVariationLite
  type: number
  negate?: boolean
  seeBase: boolean
  defaultValue: string
  action: JSX.Element
}

const attributesItem = {
  1: { value: 'code', type: 'text' },
  2: { value: 'name', type: 'text' },
  3: { value: 'price', type: 'currency' },
  4: { value: 'available', type: 'number' },
  5: { value: 'reserved', type: 'number' },
  8: { value: 'pending', type: 'number' },
  6: { value: 'existence', type: 'number' },
  7: { value: 'cost', type: 'currency' },
}

/**
 * List the line items presentation.
 * @param {IItemVariationLite} item Item to show details
 * @param {number} type Type of attribute to show
 * @param {boolean} negate If true, show a negative sign before the value
 * @param {boolean} seeBase If true, show the base value
 * @param {string} defaultValue Default value to show if seeBase is true
 * @param {JSX.Element} action Action to show after the value
 * @returns
 */
export const SubItemInventory = ({
  item,
  type,
  negate,
  seeBase,
  defaultValue,
  action,
}: IProps) => {
  let { subItems } = item
  subItems = subItems ?? []

  return subItems.length <= 1 || item.itemFactor ? (
    <div>
      {negate && '-'}
      {subItems.length === 0
        ? defaultValue
        : getItemValue(
            subItems[0],
            [attributesItem[type].value],
            attributesItem[type].type,
            null,
            null,
            null,
            subItems[0].decimals,
          )}
      {action}
    </div>
  ) : (
    <div>
      {seeBase && defaultValue}
      <ul className={'base-sub-items'}>
        {subItems.map((subItem: IItem) => (
          <li key={subItem.id}>
            <div>
              {negate && '- '}
              {getItemValue(
                subItem,
                [attributesItem[type].value],
                attributesItem[type].type,
                null,
                null,
                null,
                subItem.decimals,
              )}
            </div>
          </li>
        ))}
        {action}
      </ul>
    </div>
  )
}
