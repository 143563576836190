import status, { payments, PurchasePayments } from 'src/enums/purchaseEnums'
import { Country } from 'src/enums/countries'
import { identifications, identificationTypesEnum } from 'src/enums/identificationTypes'

const purchaseFields: IDictionary<IPurchaseField> = {
  createdByName: { title: 'Ingresado por', value: 'createdByName', type: 'text' },
  updatedByName: { title: 'Autorizado por', value: 'updatedByName', type: 'text' },
  createdAt: { title: 'Fecha de ingreso', value: 'createdAt', type: 'date' },
  emissionAt: { title: 'Fecha de emisión', value: 'emissionAt', type: 'date' },
  paymentAt: {
    title: 'Fecha de pago',
    value: 'paymentDate',
    type: 'date',
    separate: true,
  },
  serie: { title: 'No. Serie', value: 'serie', type: 'text' },
  invoice: { title: 'Factura', value: 'invoice', type: 'text' },
  provider: { title: 'Proveedor', value: 'provider', type: 'text' },
  nit: { title: 'NIT', value: 'nit', type: 'text', separate: true },
  warehouseName: { title: 'Bodega destino', value: 'warehouseName', type: 'text' },
  statusName: { title: 'Estado', value: 'statusName', type: 'text' },
  inventoryTypePurchase: {
    title: 'Ingresar inventario',
    value: 'inventoryType',
    type: 'text',
  },
  inventoryTypeExpense: {
    title: 'Reducir inventario',
    value: 'inventoryType',
    type: 'text',
  },
  paymentTypeName: {
    title: 'Pagos requeridos',
    value: 'paymentTypeName',
    type: 'text',
    separate: true,
  },
  subtotal: { title: 'Subtotal', value: 'subtotal', type: 'money' },
  discount: { title: 'Descuento', value: 'discount', type: 'money' },
  total: { title: 'Total', value: 'total', type: 'money' },
  balance: { title: 'Saldo pendiente', value: 'balance', type: 'money' },
  commentary: { title: '', value: 'commentary', type: 'text' },
}

/** Get the list of fields to render in the purchase detail
 * @param {boolean} withMoneyFields Indicates whether the money type fields need to be obtained
 * @param {boolean} isPurchase Indicates if document is a purchase or expense
 * @param {number} country Country of the company
 * @return {IPurchaseField[]} list of fields
 * **/
function getPurchaseFields(
  withMoneyFields: boolean,
  isPurchase: boolean,
  country: number,
): IPurchaseField[] {
  const fields = Object.values(purchaseFields)
  if (isPurchase) fields.splice(12, 1)
  else fields.splice(11, 1)

  const fieldIdentification = fields.find(f => f.value === 'nit')
  fieldIdentification.title = identifications[getPurchaseDefaultIdentification(country)]

  if (withMoneyFields) return fields
  else return fields.filter(f => f.type !== 'money')
}

/** Get the purchase status name
 * @param {number} state Current purchase status
 * @return {string} Name of status on purchase
 * */
function getPurchaseState(state: number): string {
  return status[state]
}

/** Get the purchases payment type name
 * @param {number} payment Current purchase payment type
 * @return {string} Name of payment type on purchase
 * **/
function getPurchasePayment(payment: number): string {
  return payments[payment]?.label
}

/** Get a provider object
 * @param {number} value ID of provider
 * @param {string} label Name of provider
 * @param {string} nit NIT of provider
 * @return {ISelectConsultNit} Provider object
 * **/
function getPurchaseProviderObj(
  value: number,
  label: string,
  nit: string,
): ISelectConsultNit {
  return { value, label, nit }
}

/** Get payment type list to purchase
 * @param {boolean} isPurchase Filter payments by purchase or expense
 * @return {ISelectNumber[]} List of payment type
 * **/
function getPurchasePayments(isPurchase: boolean): ISelectNumber[] {
  return Object.values(payments).filter(p =>
    isPurchase
      ? p.value < PurchasePayments.EXPENSE
      : p.value === PurchasePayments.EXPENSE,
  )
}

/** Get items segmented by warehouse
 * @param {IPurchaseDetail[]} details Items of purchase
 * @return {IPurchaseItem[]} List segmented by warehouse
 * **/
function getPurchaseItems(details: IPurchaseDetail[] = []): IPurchaseItem[] {
  if (details === null) return []

  const items: IPurchaseItem[] = []
  details.forEach(d => {
    const itemIndex = items.findIndex(i => i.warehouseId === d.warehouseId)
    if (itemIndex === -1)
      items.push({
        warehouseId: d.warehouseId,
        warehouseName: d.warehouseName,
        items: [d],
      })
    else items[itemIndex].items.push(d)
  })

  return items
}

const getPurchaseDefaultIdentification = (country: number): number => {
  let response: number
  switch (country) {
    case Country.GT:
      response = identificationTypesEnum.NIT_GT
      break
    case Country.SV:
      response = identificationTypesEnum.NIT_SV
      break
    case Country.HN:
      response = identificationTypesEnum.RTN
      break
    default:
      response = identificationTypesEnum.IDE
      break
  }
  return response
}

export {
  getPurchaseFields,
  getPurchaseState,
  getPurchasePayment,
  getPurchaseProviderObj,
  getPurchasePayments,
  getPurchaseItems,
  getPurchaseDefaultIdentification,
}
