import { fetchAndActivate, getAll, isSupported } from 'firebase/remote-config'
import React, { useState, createContext, useEffect, useContext } from 'react'
import { firebaseRemoteConfig } from 'src/utils/firebase'
import semverSatisfies from 'semver/functions/satisfies'

type FeatureFlagConfig = { [key: string]: string }
const FlagContext = createContext<FeatureFlagConfig>({})

const FlagProvider = ({ children }) => {
  const [flags, setFlags] = useState<FeatureFlagConfig>({})

  useEffect(() => {
    firebaseRemoteConfig.defaultConfig = {}

    const initFlags = async () => {
      const supported = await isSupported()
      if (supported === false) return

      try {
        const activated = await fetchAndActivate(firebaseRemoteConfig)

        if (activated === false) {
          console.warn('not activated')
        }

        const remoteFlags = await getAll(firebaseRemoteConfig)
        const newFlags = {
          ...flags,
        }

        for (const [key, config] of Object.entries(remoteFlags)) {
          const appVer = process.env.REACT_APP_VERSION
          newFlags[key] = String(semverSatisfies(appVer, config.asString()))
        }
        setFlags(newFlags)
      } catch (error) {
        console.error(error)
      }
    }

    initFlags()
  }, [])

  return <FlagContext.Provider value={flags}>{children}</FlagContext.Provider>
}

const useFlags = () => {
  const featureFlags = useContext(FlagContext)
  return featureFlags
}

export { FlagProvider, useFlags }
