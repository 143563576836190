import { $http } from './http'

class OffersService {
  getAllOffers = async () => {
    const response = await $http.get('/offers')
    return response.data
  }

  getSingleOffer = async id => {
    const response = await $http.get(`/offers/${id}`)
    return response.data
  }

  onCreateOffer = async offer => {
    const response = await $http.post(`/offers`, offer)
    return response.data
  }

  onUpdateOffer = async (id, offer) => {
    const response = await $http.put(`/offers/${id}`, offer)
    return response.data
  }

  getAvailableOffers = async category => {
    const response = await $http.get(
      `/offers/available${category ? `?categoryId=${category}` : ''}`,
    )
    return response.data
  }

  onAcceptProduceroffer = async contraOfferId => {
    const response = await $http.post(`/offers/producer/accept/${contraOfferId}`)
    return response.data
  }

  onAcceptOffer = async (offer, setting) => {
    const response = await $http.post(`/offers/shipper/accept/${offer}`, setting)
    return response.data
  }

  getOfferByProduct = async id => {
    const response = await $http.get(`/offers/contains/product/${id}`)
    return response.data
  }

  getPriceOfProduct = async (producerId, shipperId, productId, latitude, longitude) => {
    const response = await $http.get(
      `offers/prices/${producerId}/${shipperId}/${productId}/${latitude}/${longitude}`,
    )
    return response.data
  }

  onCreateNewContraOffer = async contraOffer => {
    const response = await $http.post(`offers/responses`, contraOffer)
    return response.data
  }

  getContraOffersByProducer = async idOffer => {
    const response = await $http.get(`offers/responses/${idOffer}`)
    return response.data
  }

  onChangeOfferStatus = async (offerId, statusId, setting, module) => {
    if (!setting) setting = {}
    const response = await $http.put(`/offers/change/${offerId}/${statusId}`, setting, {
      params: { module },
    })
    return response.data
  }

  getOfferCategories = async () => {
    const response = await $http.get('/offers/categories')
    return response.data
  }

  getArchived = async () => {
    const response = await $http.get('/offers/archived')
    return response.data
  }

  onArchive = async id => {
    const response = await $http.put(`/offers/archived/${id}`)
    return response.data
  }

  onUpdateSetting = async (id, request) => {
    const response = await $http.put(`/offers/setting/${id}`, request)
    return response.data
  }

  getPolygonContracted = async productId => {
    const response = await $http.get(`/offers/polygons/product`, {
      params: { productId },
    })
    return response.data
  }

  getCompaniesLinked = async (type, params, module) => {
    const response = await $http.get(`/offers/links/${type}`, {
      params: { ...params, module },
    })
    return response.data
  }

  getLinkProfile = async (company, type, linkType) => {
    const response = await $http.get(`/offers/links/profile/${company}/${type}`, {
      params: { linkType },
    })
    return response.data
  }

  getSingleLink = async (zoneId, type) => {
    const response = await $http.get(`/offers/links/single/${zoneId}/${type}`)
    return response.data
  }

  onSendRequestLink = async (request, type, module) => {
    const response = await $http.post(`/offers/links/request/${type}`, request, {
      params: { module },
    })
    return response.data
  }

  onSendRequestSettingLInk = async (offerId, request, module) => {
    const response = await $http.put(
      `/offers/links/request/setting/${offerId}`,
      request,
      { params: { module } },
    )
    return response.data
  }

  onSetMaxQuantity = async (products, companyId, params, module) => {
    const response = await $http.post(
      `/offers/links/products/${companyId}/max`,
      products,
      { params: { ...params, module } },
    )
    return response.data
  }

  onSetParcel = async (companyLinked, request, params, module) => {
    const response = await $http.put(`/offers/links/parcel/${companyLinked}`, request, {
      params: { ...params, module },
    })
    return response.data
  }

  onUpdateLink = async (linkId, request) => {
    const response = await $http.put(`/offers/links/update/${linkId}`, request)
    return response.data
  }

  getTypesAvailable = async (companyLinked, module) => {
    const response = await $http.get(`/offers/links/type/${companyLinked}`, {
      params: { module },
    })
    return response.data
  }

  getOfferLinkBalance = async (companyId, linkType) => {
    const response = await $http.get(`/offers/links/balance/${companyId}/${linkType}`)
    return response.data
  }

  onValidateProduct = async (productId, module) => {
    const response = await $http.get(`/offers/validate/${productId}`, {
      params: { module },
    })
    return response.data
  }

  getNearestDealer = async (orderId, distance) => {
    const response = await $http.get(`/offers/nearest/dealer/${orderId}`, {
      params: { distance },
    })
    return response.data
  }

  getInventoryExistence = async (linkId, productId) => {
    const response = await $http.get('/offers/inventory', {
      params: { linkId, productId },
    })
    return response.data
  }

  onCancelOfferZone = async (offerId, module) => {
    const response = await $http.put(
      `/offers/cancel/${offerId}`,
      {},
      { params: { module } },
    )
    return response.data
  }
}

export default new OffersService()
