import AccountbookService from '../services/accountbook.service'
import { executeAction } from './global.actions'

export const actionTypes = {
  GET_ALL_ITEMS: 'GET_ALL_ITEMS',
  GET_ITEM: 'GET_ITEM',
  CREATE_ITEM: 'CREATE_ITEM',
  UPDATE_ITEM: 'UPDATE_ITEM',
  DELETE_ITEM: 'DELETE_ITEM',
  GET_LOG: 'GET_LOG',
}

export const getAllItems = () => dispatch => {
  const process = async () => ({
    items: await AccountbookService.getAllItems(),
  })
  dispatch(executeAction(actionTypes.GET_ALL_ITEMS, process))
}

export const getAccountingItem = id => dispatch => {
  const process = async () => ({ item: await AccountbookService.getItem(id) })
  dispatch(executeAction(actionTypes.GET_ITEM, process))
}

export const getLogByItem = id => dispatch => {
  const process = async () => ({ logs: await AccountbookService.getLog(id) })
  dispatch(executeAction(actionTypes.GET_LOG, process))
}

export const createItem = request => dispatch => {
  const process = async () => await AccountbookService.createItem(request)
  dispatch(executeAction(actionTypes.CREATE_ITEM, process))
}

export const updateItem = list => dispatch => {
  const promises = []
  list.map(async l => {
    promises.push(AccountbookService.updateItem(l.id, l))
  })
  return Promise.all(promises).then(() => {
    dispatch(executeAction(actionTypes.UPDATE_ITEM))
  })
}

export const deleteItem = (id, request) => dispatch => {
  const process = async () => await AccountbookService.deleteItem(id, request)
  dispatch(executeAction(actionTypes.DELETE_ITEM, process))
}
