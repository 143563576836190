import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import Button from 'src/components/buttons/Button'
import CustomSummary from 'src/components/Summaries/CustomSummary'
import Card from 'src/components/cards/Card'
import Dropdown from 'src/components/buttons/Dropdown'
import SelectedDates from 'src/components/SelectedDates/SelectedDates'
import HistoryPlan from 'src/content/Distrbution/Plans/HistoryPlan'
import { InvoicesIssued } from './InvoicesIssued'
import Title from 'src/components/Title/Title'
import { default as TableV2 } from 'src/components/Pagination/PaginatedTableV2'
import { faEye, faFile, faSearch } from '@fortawesome/free-solid-svg-icons'
import { loadingSelector } from 'src/selectors/loading.selector'
import { actionTypes, getInvoiceDealers } from 'src/actions/invoice.actions'
import { selectInvoiceDealers } from 'src/selectors/invoice.selector'

const today = new Date()

/**
 * Page to show the list of FEL dealers
 * @component
 */
export const FelDealers = () => {
  const dispatch = useDispatch()

  const dealers: IInvoiceDealer[] = useSelector(selectInvoiceDealers)

  const reportLoading: boolean = useSelector(state =>
    loadingSelector([actionTypes.GET_INVOICE_DEALERS])(state),
  )
  const invoiceIssuedLoading: boolean = useSelector(state =>
    loadingSelector([actionTypes.GET_INVOICE_ISSUED_BY_DEALER])(state),
  )

  const [filters, setFilters] = useState({
    startDate: new Date(today.getFullYear(), today.getMonth(), 1),
    endDate: today,
  })
  const [stateAccount, setStateAccount] = useState({
    show: false,
    id: undefined,
    title: undefined,
  })

  const [invoicesIssued, setInvoicesIssued] = useState({
    show: false,
    id: undefined,
    name: '',
  })

  useEffect(() => {
    dispatch(
      getInvoiceDealers({
        startDate: filters.startDate.valueOf(),
        endDate: filters.endDate.valueOf(),
      }),
    )
  }, [])

  return (
    <div className={'text-center'}>
      <Title title={'Distribuidores FEL'} />
      <Card title={'filtros'} white>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} data-testid="dates">
            <SelectedDates
              nonExecute
              dates={{
                dateFrom: filters.startDate,
                dateTo: filters.endDate,
              }}
              onDateChange={(startDate, endDate) =>
                setFilters({ ...filters, startDate, endDate })
              }
            />
          </Col>
        </Row>
        <Row style={{ float: 'right' }}>
          <Button
            loading={reportLoading}
            dataCy={'apply-filters'}
            color={'accent'}
            icon={faSearch}
            onClick={() => {
              dispatch(
                getInvoiceDealers({
                  startDate: filters.startDate.valueOf(),
                  endDate: filters.endDate.valueOf(),
                }),
              )
            }}>
            Aplicar Filtros
          </Button>
        </Row>
      </Card>
      <Card>
        <CustomSummary
          dataCy={'summary'}
          items={[
            {
              title: 'Total de clientes',
              value: dealers.reduce((acc, dealer) => acc + dealer.clients, 0),
            },
            {
              title: 'Total de facturas',
              value: dealers.reduce((acc, dealer) => acc + dealer.invoices, 0),
            },
          ]}
        />
        <TableV2
          /* @ts-expect-error JS */
          storageKey={'dealers-fel'}
          mobileAuto
          loading={reportLoading}
          items={dealers}
          headers={[
            {
              show: true,
              label: 'Código',
              type: 'text',
              value: ['code'],
            },
            {
              show: true,
              label: 'Nombre',
              type: 'text',
              value: ['name'],
            },
            {
              show: true,
              label: 'Correo',
              type: 'text',
              value: ['email'],
            },
            {
              show: true,
              label: 'Total de clientes',
              type: 'wholeNumber',
              value: ['clients'],
            },
            {
              show: true,
              label: 'Total de facturas',
              type: 'wholeNumber',
              value: ['invoices'],
            },
            {
              show: true,
              config: true,
              label: 'Acción',
              custom: (item: IInvoiceDealer) => (
                <Dropdown
                  loading={invoiceIssuedLoading}
                  items={[
                    {
                      show: true,
                      icon: faEye,
                      title: 'Ver estado de cuenta',
                      action: () =>
                        setStateAccount({
                          show: true,
                          id: item.clientId,
                          title: `${item.code} ${item.name}`,
                        }),
                    },
                    {
                      show: true,
                      title: 'Ver facturas emitidas',
                      icon: faFile,
                      action: () => {
                        setInvoicesIssued({
                          show: true,
                          id: item.id,
                          name: `${item.code} ${item.name}`,
                        })
                      },
                    },
                  ]}
                />
              ),
            },
          ]}
        />
      </Card>

      <InvoicesIssued
        {...invoicesIssued}
        filters={filters}
        modal
        onHide={() => setInvoicesIssued({ ...invoicesIssued, show: false })}
      />
      <HistoryPlan
        show={stateAccount.show}
        id={stateAccount.id}
        name={stateAccount.title}
        modal
        mentorMode
        onHide={() => setStateAccount({ ...stateAccount, show: false })}
      />
    </div>
  )
}
