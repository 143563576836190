import React, { useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
const CAPTCHA_KEY = '6LewSr8fAAAAAHz5HSxYFeOftKGbSrCWTryvtJui'

export const ReCaptcha = ({ onChange }) => {
  const captcha = useRef(null)

  const verified = async () => {
    if (captcha?.current.getValue()) {
      onChange(captcha.current.getValue())
      console.log('El usuario no es un robot')
    } else {
      onChange(null)
    }
  }
  return (
    <ReCAPTCHA
      ref={captcha}
      sitekey={CAPTCHA_KEY}
      style={{ display: 'inline-block' }}
      onChange={() => verified()}
    />
  )
}
