import { actionTypes } from '../actions/producer.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  producers: [],
  producer: {},
  provider: {},
  providers: [],
  history: [],
  singleProvider: {},
}

const ProducerReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_SINGLE_PROVIDER):
      return { ...state, singleProvider: extract(action.singleProvider, {}) }
    case successState(actionTypes.GET_BY_NIT):
      return { ...state, provider: extract(action.provider, {}) }
    case successState(actionTypes.GET_PROVIDERS):
      return { ...state, providers: extract(action.providers, []) }
    case successState(actionTypes.GET_HISTORY):
      return { ...state, history: extract(action.history, {}) }
    case actionTypes.GET_PRODUCER_SUCCESS:
      return {
        ...state,
        producer: action.response,
      }
    case actionTypes.GET_ALL_PRODUCERS_SUCCESS:
      return {
        ...state,
        producers: action.response,
      }
    default:
      return state ? state : initialState
  }
}

export default ProducerReducer
