import React, { Component } from 'react'
import { connect } from 'react-redux'

import Card from 'src/components/cards/Card'
import FormTextField from 'src/components/inputs/FormTextField/FormTextField'
import Select from 'src/components/inputs/Select/CustomSelect'
import FormTextArea from 'src/components/inputs/FormTextArea/FormTextArea'
import CustomDatePicker from 'src/components/CustomDatePicker/CustomDatePicker'
import Gallery, { imageTypes } from 'src/components/gallery/Gallery'

import { faLongArrowAltRight, faBackspace } from '@fortawesome/free-solid-svg-icons'
import { Row, Col } from 'react-bootstrap'

import { selectAllPolygonsBySelect } from 'src/selectors/polygons.selector'

import { setInformationToOffer, setSteps } from 'src/actions/offers.actions'
import { selectOffer, selectOfferCategories } from 'src/selectors/offers.selector'
import Button from 'src/components/buttons/Button'

import { getPolygonByCategory } from 'src/actions/polygons.actions'
import {
  getProducts,
  getProductsWithTransportationPayment,
} from 'src/actions/products.actions'

class OfferInformation extends Component {
  state = {
    images: [],
    errors: {},
    title: 'default',
  }

  componentDidMount() {
    const { id } = this.props.match.params
    this.setState({ title: id ? 'Editar Oferta' : 'Crear Oferta' })
  }

  uploadImage = (url, name) => {
    this.setState({
      images: [...this.state.images, { original: url, thumbnail: url, name: name }],
    })
  }

  deleteImage = (image, index) => {
    const { images } = this.state
    images.splice(index, 1)
    this.setState({ images: new Array(...images) })
  }

  handleInputChange = event => {
    const { name, value } = event.target
    const { offer, setInfo } = this.props
    offer[name] = value
    setInfo(offer)
  }

  onDateChange = date => {
    const { offer, setInfo } = this.props
    setInfo({ ...offer, availableUntil: date })
  }

  handleChange = event => {
    const { offer, setInfo } = this.props
    const { id, value } = event.target
    offer[id] = value === 'true' ? false : true
    setInfo(offer)
  }

  render() {
    const { images, errors } = this.state
    const { offer, categories, setInfo, setSteps } = this.props

    return (
      <div>
        <Row>
          <Col md={4}>
            <Card
              style={{ height: '100%' }}
              bodyStyle={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Gallery
                imageType={imageTypes.OFFER}
                images={images}
                onUploadSuccess={this.uploadImage}
                onDelete={this.deleteImage}
              />
            </Card>
          </Col>
          <Col>
            <Row>
              <Col md={12}>
                <Card title={'Datos de la oferta'}>
                  <Row>
                    <Col md={6}>
                      <FormTextField
                        label={'Alias'}
                        type={'text'}
                        placeholder={'Crea un alias'}
                        name={'alias'}
                        onChange={this.handleInputChange}
                        value={offer.alias}
                        error={errors.alias}
                        required
                      />
                    </Col>
                    <Col md={6}>
                      <FormTextArea
                        label={'Descripción'}
                        type={'text'}
                        placeholder={'Describa la oferta para el transportista.'}
                        name={'notes'}
                        onChange={this.handleInputChange}
                        value={offer.notes}
                        error={errors.notes}
                        required
                      />
                    </Col>
                    <Col md={6}>
                      <CustomDatePicker
                        label={'Oferta disponible hasta'}
                        value={offer.availableUntil}
                        error={errors.availableUntil}
                        onDayChange={this.onDateChange}
                        disabledDays={[]}
                        format={'LL'}
                        required
                      />
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={12}>
                          <Select
                            label={'Categoria'}
                            options={categories}
                            value={offer.category}
                            onChange={c => {
                              offer.category = c
                              if (c.value === 1) setSteps([0, 2, 3])
                              else setSteps([0, 1, 2, 3])
                              setInfo(offer)
                            }}
                            error={errors.category}
                            required
                          />
                        </Col>
                        <Col>
                          <span>
                            {offer.category
                              ? offer.category.description || 'Sin categoria'
                              : 'sin descripcion'}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    {/*
                                        {(offer.category && offer.category.value == 3) && <Col md={6}>
                                            <FormText
                                                label={'Indicar el pago por orden entregada.'}
                                                prepend={'Q'}
                                                type={'number'}
                                                name={'deliveryAmount'}
                                                value={offer.deliveryAmount}
                                                onChange={this.handleInputChange}
                                                error={errors.deliveryAmount}
                                                required
                                            />
                                        </Col>}
                                        */}
                    {/*
                                        <Col md={6}>
                                            <Row className={'flex-container space-between'}>
                                                <Col><span><strong>Precio negociable.</strong></span></Col>
                                                <Col style={{alignSelf: 'flex-end', textAlign: 'right'}}>
                                                    <Switch
                                                        checked={offer.priceNegotiable}
                                                        value={offer.priceNegotiable}
                                                        id={'priceNegotiable'}
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        */}
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={'container-buttons'}>
          <Button onClick={() => window.history.back()} icon={faBackspace}>
            Salir
          </Button>
          <Button
            disabled={
              !offer.alias || !offer.notes || !offer.availableUntil || !offer.category
            }
            onClick={() => {
              if (offer.category) {
                if (offer.category.value !== 1) {
                  if (offer.category.value === 5) {
                    setInfo({ ...offer, kolo: true })
                    this.props.getPolygons(5)
                  } else {
                    setInfo({ ...offer, kolo: false })
                    this.props.getPolygons(1)
                  }
                }
                this.props.getProducts(offer.category.value)
                this.props.jumpToStep(1)
              }
            }}
            icon={faLongArrowAltRight}
          >
            siguiente
          </Button>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  polygons: selectAllPolygonsBySelect(state),
  offer: selectOffer(state),
  categories: selectOfferCategories(state),
})
const mapDispatchToProps = dispatch => ({
  setInfo: offer => dispatch(setInformationToOffer(offer)),
  setSteps: steps => dispatch(setSteps(steps)),
  getPolygons: category => dispatch(getPolygonByCategory(category)),
  getProducts: category => {
    if (category === 1) dispatch(getProductsWithTransportationPayment())
    else dispatch(getProducts())
  },
})
export default connect(mapStateToProps, mapDispatchToProps)(OfferInformation)
