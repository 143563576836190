import React, { useLayoutEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { actionTypes as typeT, getAccounts, getBanks } from 'src/actions/banks.actions'
import AccountsView from 'src/components/Banks/AccountsView'

import { getAllCurrencies } from 'src/actions/currencies.actions'
import { getCompanyUsers as getUsersInSubmodule } from 'src/actions/modules.actions'

import { BankContext } from 'src/providers/BankContext'
import { Title } from 'src/components'
import { defaultBankAccount } from 'src/enums/bankEnums'
import { selectGetAccounts, selectGetBanks } from 'src/selectors/banks.selector'
import { transformAccounts } from 'src/utils/bankHelpers/bankHelpers'
import { loadingSelector } from 'src/selectors/loading.selector'

const BanksV2: React.FC = () => {
  const dispatch = useDispatch()

  const accountList = useSelector(selectGetAccounts)
  const bankList = useSelector(selectGetBanks)
  const loadingCreateOrUpdateAccount = useSelector(state =>
    loadingSelector([typeT.CREATE_ACCOUNT, typeT.UPDATE_ACCOUNT])(state),
  )

  const [account, setAccount] = useState<IBankAccount>(defaultBankAccount)
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false)
  const accountsFormated = transformAccounts(accountList)

  useLayoutEffect(() => {
    if (loadingCreateOrUpdateAccount) {
      dispatch(getAccounts(false))
    }
  }, [loadingCreateOrUpdateAccount])

  useLayoutEffect(() => {
    if (accountsFormated && bankList && bankList.length > 0) {
      setIsDataLoaded(true)
    }
  }, [bankList, accountsFormated])

  useLayoutEffect(() => {
    fetchAllData()
  }, [])

  const updateAccount = (item: IBankAccount) => {
    setAccount(prevAccount => ({
      ...prevAccount,
      ...item,
    }))
  }

  const bankContextValue = {
    accounnt: account,
    updateAccount: updateAccount,
  }

  const getUsers = () => {
    const submodule = [6150, 6200, 8003, 11750]

    // @ts-ignore
    dispatch(getUsersInSubmodule(submodule))
  }

  const fetchAllData = () => {
    dispatch(getBanks())
    dispatch(getAllCurrencies())
    dispatch(getAccounts(false))
    getUsers()
  }

  return (
    <>
      <BankContext.Provider value={bankContextValue}>
        <div className="w-100">
          <Title title={'Bancos'} />
          {isDataLoaded && <AccountsView banks={bankList} accounts={accountsFormated} />}
        </div>
      </BankContext.Provider>
    </>
  )
}

export default BanksV2
