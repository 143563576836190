import { executeAction } from './global.actions'
import CreditNoteService from '../services/creditnote.service'

export const actionTypes = {
  ON_CREATE: 'ON_CREATE',
  GET_CREDIT_NOTE: 'GET_CREDIT_NOTE',
  GET_CREDIT_NOTE_AVAILABLE: 'GET_CREDIT_NOTE_AVAILABLE',
}

export const onCreate = request => dispatch => {
  const process = async () => ({
    response: await CreditNoteService.onCreate(request),
  })
  dispatch(executeAction(actionTypes.ON_CREATE, process))
}

export const getCreditNotes = () => dispatch => {
  const process = async () => ({
    creditNotes: await CreditNoteService.getCreditNotes(),
  })
  dispatch(executeAction(actionTypes.GET_CREDIT_NOTE, process))
}

export const getCreditNotesAvailable = params => dispatch => {
  const process = async () => ({
    creditNotesAvailable: await CreditNoteService.getCreditNotes(params),
  })
  dispatch(executeAction(actionTypes.GET_CREDIT_NOTE_AVAILABLE, process))
}
