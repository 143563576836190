import { successState } from 'src/actions/global.actions'
import { actionTypes } from 'src/actions/transaction.action'

const initialState: IInitialStateTransaction = {
  generalConfig: {
    value: null,
    haveDiscountGeneral: false,
    withOutIVA: {
      active: false,
      value: null,
    },
  },
  itemTable: {
    item: {},
    index: null,
  },
  flagResponse: false,
  uuIdFlag: undefined,
}

const TransactionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case successState(actionTypes.ON_SET_TRANSACTION_UUID):
      return { ...state, uuIdFlag: payload }
    case successState(actionTypes.ON_CREATE_TRANSACTION_FLAG):
      return { ...state, flagResponse: payload }
    case successState(actionTypes.SET_GENERAL_ACTION):
      return {
        ...state,
        generalConfig: { ...state.generalConfig, ...payload },
      }
    case successState(actionTypes.SET_ITEM_TABLE):
      return {
        ...state,
        itemTable: { ...state.itemTable, ...payload },
      }
    default:
      return state
  }
}
export default TransactionReducer
