import './ModalWelcome.scss'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { onShowModalWelcome } from 'src/actions/utilities.actions'

import IconClose from 'src/assets/icon-close.svg'
import LogoKolo from 'src/assets/logo-kolo-white.svg'

const ModalWelcome = () => {
  const dispatch = useDispatch()

  const show = useSelector(state => state.utilities.showModalWelcome)

  const [videoSrc, setVideoSrc] = useState('')
  const [isPlaying, setIsPlaying] = useState(false)

  useLayoutEffect(() => {
    window.addEventListener('keydown', handleEsc)

    if (show) {
      setTimeout(() => {
        setVideoSrc(
          'https://firebasestorage.googleapis.com/v0/b/cotizap-prod.appspot.com/o/videos%2Fbienvenida.mp4?alt=media&token=8da9ddfb-b481-4303-8697-e5297a3bc4a5',
        )
      }, 1000)
    }

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [show])

  useEffect(() => {
    if (videoSrc === '') return
    setTimeout(() => {
      playVideo()
    }, 400)
  }, [videoSrc])

  const handleEsc = event => {
    if (event.keyCode === 27) {
      window.removeEventListener('keydown', handleEsc)
      close(false)
    }
  }

  const close = event => {
    if (event) event.stopPropagation()

    const modal = document.getElementById('myModalWelcome')
    if (modal) modal.style.opacity = '0'

    playVideo()
    setTimeout(() => {
      dispatch(onShowModalWelcome(false))
    }, 300)
  }

  const playVideo = event => {
    const video = document.getElementById(`welcome-video`)

    if (video && videoSrc) {
      video.volume = 0.3
      if (isPlaying) {
        video.pause()
        setIsPlaying(false)
      } else {
        video?.play()
        setIsPlaying(true)
      }
    }
  }

  return (
    show &&
    videoSrc !== '' && (
      <div id={'myModalWelcome'} className={'welcome-modal'}>
        <div
          className={'welcome-card'}
          onClick={e => {
            e.stopPropagation()
          }}>
          <div className={'welcome-container'}>
            <div className="pp-btn-close" onClick={close}>
              <img className="invert" width={20} height={20} src={IconClose} alt={'x'} />
            </div>
            <div className="mw-text-1 a-in-left">Bienvenido a</div>
            <img className="mw-logo" src={LogoKolo} alt={''} />
            <div className="mw-text-2">
              ¡Bienvenidos! en este video podrás ver información de cómo comenzar a usar
              Kolo
            </div>

            <div className="position-relative">
              <video id={'welcome-video'} className="mw-video" controls>
                <source src={videoSrc} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    )
  )
}
export default ModalWelcome
