import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteCard, actions as typeA } from 'src/actions/account.actions'
import { Icon, Input } from 'src/components'
import { loadingSelector } from 'src/selectors/loading.selector'
import DeleteImage from 'src/assets/v2/icon-delete.svg'
import cardTypes from 'src/enums/cardTypes'

function CreditCard({ id, alias, number, logo, noAlias, noDelete, style = {} }) {
  const dispatch = useDispatch()

  logo ||= cardTypes.find(card => card.number?.match(card.pattern))?.name

  const loadingCD = useSelector(state => loadingSelector([typeA.DELETE_CARD])(state))

  return (
    <div style={{ position: 'relative' }}>
      <div className={'d-flex'}>
        <Input
          className={`input-card ${logo || 'none'}`}
          name="number"
          placeholder={'#'}
          disabled
          maxLength={19}
          value={`${!noAlias && alias ? `${alias} - ` : ''}${number}` || 'NO DISPONIBLE'}
          autocomplete="cc-number"
          style={{ border: 'none', ...style }}
          containerStyle={{ width: '100%' }}
        />
        {!noDelete &&
          (!loadingCD ? (
            <img
              style={{
                position: 'absolute',
                right: 10,
                top: '25%',
                cursor: 'pointer',
              }}
              src={DeleteImage}
              className={'delete-h'}
              onClick={() => dispatch(deleteCard(id))}
              alt={'-'}
            />
          ) : (
            <div
              style={{
                position: 'absolute',
                left: 235,
                top: '28%',
              }}>
              <Icon icon={faSpinner} spin={loadingCD} size={'1_5x'} />
            </div>
          ))}
      </div>
    </div>
  )
}

export default CreditCard
