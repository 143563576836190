import React, { useEffect, useState } from 'react'
import { Treebeard } from 'react-treebeard'
import decorators from 'react-treebeard/dist/components/Decorators'
import defaultTheme from 'react-treebeard/dist/themes/default'
import * as filters from './filters'
import IconButton from '../buttons/IconButton'
import {
  faEdit,
  faFolder,
  faFolderOpen,
  faPlusCircle,
  faSearch,
  faTag,
  faToggleOff,
  faToggleOn,
} from '@fortawesome/free-solid-svg-icons'
import FormText from '../inputs/FormTextField/FormText'
import './FolderComponent.scss'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectCategorizationAssignedForSelect } from 'src/selectors/categorizations.selector'
import { actionTypes as types } from 'src/actions/categorization.actions'
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye'
import { history } from '../../App'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { loadingSelector } from 'src/selectors/loading.selector'
import Alert from 'sweetalert-react'
import { actionTypes } from 'src/actions/products.actions'
import { actionTypes as clientType } from 'src/actions/clients.actions'

const FoldersComponent = ({
  categorization,
  onAdd,
  onSelect,
  loading,
  show,
  edit,
  modal,
  onHide,
  onAssign,
  onDelete,
  noMessage,
  remove,
  onEdit,
  add,
  see,
  loadingAction,
  list,
}) => {
  const productCategorization = useSelector(selectCategorizationAssignedForSelect)
  const loadingAssign = useSelector(state =>
    loadingSelector([
      types.ADD_ENTITY_CATEGORIZATION,
      actionTypes.ASSIGN_CATEGORY,
      clientType.ASSIGN_CATEGORIES_TO_MULTIPLE_CLIENTS,
    ])(state),
  )

  const [data, setData] = useState({})
  let [cursor, setCursor] = useState({})
  const [change, setChange] = useState(true)
  const [selected, setSelected] = useState([])
  const [action, setAction] = useState({ add: false, id: 0, node: null })
  const [alert, setAlert] = useState({ show: false })
  const [clicked, setClicked] = useState([])

  useEffect(() => {
    if (!show) {
      setClicked([])
    }
  }, [show])

  useEffect(() => {
    setData(transform(categorization))
  }, [categorization])

  useEffect(() => {
    if (list) setClicked(list)
  }, [list])

  useEffect(() => {
    setSelected([])
  }, [loading])

  useEffect(() => {
    if (action.node && !action.add) {
      if (noMessage) {
        onAssign(action.node)
      } else {
        let alert = {
          show: true,
          title: '¿Desea añadir esta categoría?',
          type: 'info',
          confirmButtonColor: '#226095',
          onConfirm: () => {
            onAssign(action.node)
            setAlert({ show: false })
          },
          showCancelButton: true,
          confirmButtonText: 'Asignar',
          cancelButtonText: 'Cancelar',
          onCancel: () => setAlert({ show: false }),
        }
        setAlert({ ...alert })
      }
    }
  }, [action])

  useEffect(() => {
    if (loadingAssign) setAction({ ...action, add: true })
    else if (action.add) {
      let index = clicked.findIndex(d => d === action.id)
      if (index === -1) {
        if (!action.remove) setClicked([...clicked, action.id])
      } else setClicked(clicked.filter((d, i) => i !== index))
      setAction({ ...action, add: false, id: 0, node: null })
    } else setAction({ ...action, add: false, id: 0, node: null })
  }, [loadingAssign])

  const transform = categorization => {
    if (categorization) {
      let { children } = categorization
      children = children && children.map(cat => transform(cat))
      return {
        ...categorization,
        children: children && children.length > 0 ? children : null,
      }
    }
  }

  const onSelectItem = node => {
    if (onSelect) {
      node.selected = !node.selected
      setCursor(node)
      let info = setSelect(categorization, node)
      setData(info)

      let index = selected.findIndex(t => t.id === node.id)
      if (index === -1) selected.push(node)
      else selected.splice(index, 1)
      onSelect(selected)
    }
  }

  const setSelect = (categorization, node) => {
    if (categorization) {
      if (categorization.id === node.id) categorization.selected = node.selected
      let { children } = categorization
      children = children && children.map(cat => setSelect(cat, node))
      return {
        ...categorization,
        children: children && children.length > 0 ? children : null,
      }
    }
  }

  const setToggle = (categorization, node) => {
    if (categorization) {
      if (categorization.id === node.id) categorization.toggled = node.toggled
      let { children } = categorization
      children = children && children.map(cat => setToggle(cat, node))
      return {
        ...categorization,
        children: children && children.length > 0 ? children : null,
      }
    }
  }

  const onFilterMouseUp = ({ target: { value } }) => {
    const filter = value.trim()
    if (!filter) {
      return setData(transform(categorization))
    }
    let filtered = filters.filterTree(categorization, filter)
    filtered = filters.expandFilteredNodes(filtered, filter)
    setData(transform(filtered))
  }

  const onToggle = (node, toggled) => {
    if (cursor) {
      cursor.active = false
    }
    if (node.children && change) node.toggled = toggled
    else setChange(true)
    setCursor(node)
    setData(setToggle(categorization, node))
  }

  defaultTheme.tree.base = {
    ...defaultTheme.tree.base,
    backgroundColor: 'rgba(231,231,231, 0.5)',
  }

  const hasChildren = node => {
    return node.children && node.children.length > 0
  }

  const assigned = node => {
    let assigned = productCategorization.filter(f => f.value === node.id).length > 0
    let selected = clicked.filter(c => c === node.id).length > 0
    return assigned ? 1 : selected ? 2 : 0
  }

  const Header = ({ style, node }) => {
    const getStyles = () => {
      let styles = { ...style.title }
      styles = {
        ...styles,
        display: 'flex',
        justifyContent: 'space-between',
        color: '#226095',
      }
      styles = hasChildren(node) ? { ...styles } : { ...styles, marginLeft: 20 }
      return styles
    }

    return (
      <div
        style={{
          ...style.base,
          backgroundColor: node.selected ? 'rgba(142,189,236,0.8)' : '',
        }}
        className={'folder-header'}
      >
        <OverlayTrigger
          placement={'bottom'}
          overlay={
            node.description ? (
              <Tooltip id="tooltip">{node.description}</Tooltip>
            ) : (
              <div />
            )
          }
        >
          <div>
            <div style={getStyles()} onClick={() => onSelectItem(node)}>
              <div className={'d-flex'} style={{ color: node.id < 0 && 'white' }}>
                <IconButton
                  icon={
                    hasChildren(node) ? (node.toggled ? faFolderOpen : faFolder) : faTag
                  }
                  style={{ marginRight: 5 }}
                  color={'rgb(42 46 187)'}
                />
                {node.children && node.children.length > 0 ? (
                  <b>
                    {node.id && node.id > 0 ? node.id : ''}{' '}
                    {node.name && node.name.toUpperCase()}
                  </b>
                ) : (
                  `${node.id && node.id > 0 ? node.id : ''} ${
                    node.name && node.name.toUpperCase()
                  }`
                )}
              </div>

              {node.id > 0 ? (
                <div className={'d-flex'}>
                  {onAssign && node.id > 0 ? (
                    assigned(node) === 0 ? (
                      <IconButton
                        spin={loadingAssign && node.id === action.id}
                        icon={faToggleOff}
                        color={'gray'}
                        style={{ marginLeft: 15 }}
                        size={'1_5x'}
                        tooltip={'No Seleccionado'}
                        onClick={() => setAction({ ...action, id: node.id, node })}
                      />
                    ) : (
                      <IconButton
                        spin={loadingAssign && node.id === action.id}
                        icon={faToggleOn}
                        color={assigned(node) === 2 ? '#226095' : 'green'}
                        style={{ marginLeft: 15 }}
                        size={'1_5x'}
                        tooltip={assigned(node) === 2 ? 'Seleccionado' : 'Asignado'}
                        onClick={() =>
                          setAction({
                            ...action,
                            id: node.id,
                            node,
                            remove: true,
                          })
                        }
                      />
                    )
                  ) : (
                    ''
                  )}
                  {edit && onEdit && (
                    <IconButton
                      icon={faEdit}
                      style={{ marginLeft: 15 }}
                      size={'1_5x'}
                      onClick={() => onEdit(node)}
                    />
                  )}
                  {see && (
                    <IconButton
                      icon={faEye}
                      size={'1_5x'}
                      style={{ marginLeft: 15 }}
                      tooltip={'Ver categoría'}
                      placement={'left'}
                      onClick={() => history.push(`categorizaciones/detalle/${node.id}`)}
                    />
                  )}
                  {remove && onDelete && (
                    <IconButton
                      spin={loadingAction.loading && loadingAction.id === node.id}
                      icon={faTrash}
                      style={{ marginLeft: 15 }}
                      size={'1_5x'}
                      onClick={() => onDelete(node)}
                    />
                  )}
                </div>
              ) : add && node.id === -2 ? (
                <div>
                  <div
                    className={'categorization-add'}
                    onClick={() => onAdd({ id: null })}
                  >
                    CREAR CATEGORIA
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
            {add && node.name && node.id > 0 && (
              <div
                style={{
                  marginLeft: hasChildren(node) ? 20 : 40,
                  color: '#A3A3A3',
                }}
                className={'add-file-div'}
                onClick={() => onAdd(node)}
              >
                <IconButton icon={faPlusCircle} style={{ marginRight: 5 }} />
                {`Añadir subcategoría de ${node.name && node.name.toUpperCase()}`}
              </div>
            )}
          </div>
        </OverlayTrigger>
      </div>
    )
  }

  const Container = props => {
    return (
      <div
        onClick={props.onClick}
        style={{
          ...props.style,
          backgroundColor: props.node.selected
            ? 'rgba(142,189,236,0.8)'
            : props.node.id === -2
            ? 'rgb(195 45 97)'
            : props.node.id === -1
            ? 'rgb(11 161 173)'
            : props.node.id === 0
            ? '#3c3737'
            : 'rgba(232,231,231, 0.5)',
          borderBottom: '1px solid white',
        }}
      >
        <div
          style={{
            display: 'flex',
            borderBottom: '1px solid white',
            borderLeft: '2px solid #bbbbbb',
          }}
        >
          <div
            onClick={() => onToggle(props.node, !props.node.toggled)}
            style={{ marginTop: 5, marginLeft: 5 }}
          >
            {!props.terminal ? (
              <decorators.Toggle
                node={props.node}
                style={{
                  ...props.style.toggle,
                  wrapper: {
                    ...props.style.toggle.wrapper,
                    transform: props.node.toggled && 'rotate(90deg)',
                    left: props.node.toggled ? '80%' : '70%',
                    top: props.node.toggled ? '70%' : '55%',
                  },
                  arrow: { fill: '#226095' },
                }}
              />
            ) : (
              <div className={'item-position'}>--</div>
            )}
          </div>
          <decorators.Header node={props.node} style={props.style.header} />
        </div>
      </div>
    )
  }

  decorators.Header = Header

  const render = () => {
    return (
      <div style={{ width: '100%' }}>
        <div>
          <FormText
            prepend={<IconButton icon={faSearch} />}
            onKeyUp={onFilterMouseUp}
            placeholder={'Buscar entre las categorias'}
            type="text"
          />
        </div>
        <div style={{ overflowY: 'scroll' }}>
          {
            <Treebeard
              data={data}
              //onToggle={onToggle}
              decorators={{ ...decorators, Container }}
              onSelect={onSelectItem}
            />
          }
        </div>
        <Alert {...alert} />
      </div>
    )
  }

  return modal ? (
    <Modal
      show={show} //showCashBox
      size={'lg'}
      centered
      aria-labelledby={'contained-modal-title-vcenter'}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>Categorizaciones</Modal.Title>
      </Modal.Header>
      <Modal.Body>{render()}</Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  ) : (
    render()
  )
}

export default FoldersComponent
