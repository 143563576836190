import React, { useEffect, useState } from 'react'

import { Card, FABV2, Loading, Title } from 'src/components'

import CreateDynamicFieldModal from 'src/components/CompanyDynamicFields/CreateDynamicFieldModal'

import { useDispatch, useSelector } from 'react-redux'

import { fetchDynamicFields } from 'src/actions/dynamicfield.actions'

import DynamicFieldTable from 'src/components/CompanyDynamicFields/DynamicFieldTable'
import { isAllowed } from 'src/selectors/modules.selector'
import { getCurrentUserRolesAction } from 'src/actions/modules.actions'
import { dynamicFieldsPermissions } from 'src/enums/dynamicFieldsEnum'
import { loadingFields, selectDynamicFields } from 'src/selectors/dynamicFields.selector'
import { Col, Row } from 'react-bootstrap'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const Dynamicfieldspage: React.FC = () => {
  const dispatch = useDispatch()

  const allowViewDynamicFields = useSelector(state =>
    isAllowed(state, [dynamicFieldsPermissions.viewDynamicFields]),
  )
  const allowCreateDynamicFields = useSelector(state =>
    isAllowed(state, [dynamicFieldsPermissions.createDynamicFields]),
  )

  const dynamicFields = useSelector(selectDynamicFields)
  const statusDynamicFieldReq = useSelector(loadingFields)

  const [modalCreateDymShow, setModalCreateDymShow] = useState<boolean>(false)

  useEffect(() => {
    dispatch(fetchDynamicFields())
    dispatch(getCurrentUserRolesAction())
  }, [])

  if (statusDynamicFieldReq === 'loading') {
    return <Loading show={true} />
  }
  if (statusDynamicFieldReq === 'failed') {
    return <div>Error: {'error'}</div>
  }
  return (
    <>
      <Title title={'Campos Dinámicos'} />
      <div className={'d-flex flex-column w-100'}>
        <Row>
          <Col xl={12}>
            <Card>
              <DynamicFieldTable dynamicFields={dynamicFields} />
            </Card>
          </Col>
        </Row>

        {allowViewDynamicFields && (
          <CreateDynamicFieldModal
            show={modalCreateDymShow}
            mode={'create'}
            onHide={() => setModalCreateDymShow(false)}
          />
        )}
      </div>
      <FABV2
        show={allowCreateDynamicFields}
        title={'Agregar Nuevo Campo'}
        onClick={() => setModalCreateDymShow(true)}
        icon={faPlus}
      />
    </>
  )
}

export default Dynamicfieldspage
