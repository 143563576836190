import { $http } from './http'

class NotificationsService {
  getAllNotifications = async (sDate, eDate) => {
    const response = await $http.get(`/notifications/history/${sDate}/${eDate}`)
    return response.data
  }

  getRecentNotifications = async () => {
    const response = await $http.get(`/notifications/history/recent`)
    return response.data
  }

  sendNotification = async request => {
    const response = await $http.post(`/notifications/send`, request)
    return response.data
  }

  subscribeUser = async request => {
    const response = await $http.post(`/notifications/subscribe`, request)
    return response.data
  }

  markAsRead = async id => {
    const response = await $http.put(`/notifications/history/read/${id}`)
    return response.data
  }

  getOrder = async id => {
    const response = await $http.get(`/notifications/order/${id}`)
    return response.data
  }

  getFields = async () => {
    const response = await $http.get(`/notifications/field`)
    return response.data
  }

  updateFieldConfiguration = async (id, app, email, companyId) => {
    const response = await $http.put(
      `/notifications/field/${id}`,
      {},
      { params: { email, app, companyId } },
    )
    return response.data
  }

  getUsersByNotification = async id => {
    const response = await $http.get(`/notifications/field/${id}`)
    return response.data
  }

  setUsersToNotification = async request => {
    const response = await $http.post(`/notifications/field/users`, request)
    return response.data
  }
}

export default new NotificationsService()
