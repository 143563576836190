import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import { Button, Dropdown, TableV2 } from 'src/components'

import {
  actionTypes,
  deleteInvoiceConcept,
  getOrderInvoiceConcepts,
  getPosInvoiceConcepts,
} from 'src/actions/restaurant.actions'

import { selectOrderConcepts, selectPosConcepts } from 'src/selectors/restaurant.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
} from 'src/selectors/error.selector'
import { showAlert } from 'src/actions/alert.actions'
import { selectFelItemTypes } from 'src/selectors/company.selector'
import {
  setModalCreateConcepts,
  setModalManageConcepts,
} from 'src/actions/utilities.actions'
import {
  selectModalCreateConcepts,
  selectModalManageConcepts,
} from 'src/selectors/utilities.selector'
import { IModalCreateConcept } from 'src/content/Restaurant/Concepts/ModalCreateConcept'

interface IModalManageConcepts {
  show?: boolean
  posId?: number
}

interface IActions {
  delete?: boolean
}

const headers = [
  { label: 'Nombre', show: true, className: 'name', type: 'text' },
  { label: 'Bien o Servicio', show: true, className: 'name', type: 'text' },
  { show: true, className: 'mini' },
]

/**
 * @component ModalManageConcepts
 * @description Component to manage the POS invoice concepts via modal
 */
const ModalManageConcepts = () => {
  const dispatch = useDispatch()

  const { show, posId }: IModalManageConcepts = useSelector(selectModalManageConcepts)
  const modalCreateConcepts: IModalCreateConcept = useSelector(selectModalCreateConcepts)
  const felItemTypes = useSelector(selectFelItemTypes)
  const concepts: IInvoiceConcept[] = useSelector(
    posId ? selectPosConcepts : selectOrderConcepts,
  )

  const loadingGet = useSelector(state =>
    loadingSelector([
      actionTypes.GET_ORDER_INVOICE_CONCEPTS,
      actionTypes.GET_POS_INVOICE_CONCEPTS,
    ])(state),
  )

  const loadingDelete = useSelector(state =>
    loadingSelector([actionTypes.DELETE_INVOICE_CONCEPT])(state),
  )
  const hasErrorDelete = useSelector(state =>
    hasErrorsSelector([actionTypes.DELETE_INVOICE_CONCEPT])(state),
  )

  const [actions, setActions] = useState<IActions>({})

  const getConcepts = () =>
    dispatch(posId ? getPosInvoiceConcepts(posId, true) : getOrderInvoiceConcepts(true))

  useEffect(() => {
    if (!show) return
    getConcepts()
  }, [show])

  useEffect(() => {
    if (loadingDelete) setActions({ ...actions, delete: true })
    else if (actions.delete) {
      setActions({ ...actions, delete: false })

      if (hasErrorDelete)
        dispatch(
          showAlert({
            ...handlerError('No se pudo eliminar el concepto de facturación'),
          }),
        )
      else {
        getConcepts()
        dispatch(
          showAlert({
            ...handlerSuccess('Concepto de facturación eliminado con éxito'),
            confirmButtonText: 'Cerrar',
          }),
        )
      }
    }
  }, [loadingDelete])

  return (
    <div>
      <Modal
        show={show && !modalCreateConcepts.show}
        size="lg"
        centered
        onHide={() => dispatch(setModalManageConcepts({}))}>
        <Modal.Header closeButton>
          <Modal.Title>
            Conceptos de facturación {posId ? 'del punto de venta' : 'de ventas'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <TableV2
                // @ts-expect-error
                items={concepts}
                headers={headers}
                loading={loadingGet}
                noItemsLegend={'No existen conceptos de facturación'}
                renderRow={concept => (
                  <tr key={concept.id} className={'data'}>
                    <td className={'text-left'}>{concept.name}</td>
                    <td className={'text-left'}>
                      {felItemTypes.find(fel => fel.value === concept.felItemType)?.label}
                    </td>
                    <td className={'text-center'}>
                      <Dropdown
                        loading={loadingDelete}
                        items={[
                          {
                            title: 'Editar',
                            action: () =>
                              dispatch(
                                setModalCreateConcepts({
                                  show: true,
                                  update: true,
                                  concept,
                                }),
                              ),
                          },
                          {
                            title: 'Eliminar',
                            action: () => dispatch(deleteInvoiceConcept(concept.id)),
                          },
                        ]}
                      />
                    </td>
                  </tr>
                )}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              loading={loadingDelete}
              onClick={() =>
                dispatch(
                  setModalCreateConcepts({
                    show: true,
                    concept: {
                      name: '',
                      felItemType: felItemTypes[0].value,
                      posId,
                    },
                  }),
                )
              }>
              Crear nuevo concepto
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ModalManageConcepts
