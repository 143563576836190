import React, { useEffect, useState } from 'react'
import { TableV2 } from '..'

import { graphs as colorArray } from 'src/utils/graphs'
import { toMoney } from 'src/utils/utilities'
interface Props {
  labels: ISelect[]
  datasets: IDatasetGraph[]
  title: string
  currency?: boolean
  updateGraph?: (index: number[]) => void
}

/**
 * Table component for charts
 * @component
 * @param {ISelect[]} labels List of labels (rows)
 * @param {IDatasetGraph[]} datasets List of datasets, values of labels
 * @param {string} title Title of the table
 * @param {boolean} currency If the values are currency
 * @param {function} updateGraph Function to update the graph, if the user clicks on a row
 */
const ChartTableComponent = ({
  labels,
  datasets,
  title,
  currency,
  updateGraph,
}: Props) => {
  const [hiddenData, setHiddenData] = useState<number[]>([])

  useEffect(() => {
    setHiddenData([])
  }, [datasets])

  /**
   * Handle the click on a row, save the index of that data
   * @param index Index of the row clicked
   */
  const handleRowClick = index => {
    if (hiddenData.includes(index)) {
      setHiddenData(hiddenData.filter(i => i !== index))
    } else {
      setHiddenData([...hiddenData, index])
    }
    updateGraph(hiddenData)
  }

  return (
    <>
      <TableV2
        /* @ts-expect-error  */
        headers={[
          {
            label: '',
            show: true,
            style: { width: 20 },
          },
          {
            label: 'Nombre',
            show: true,
          },
          {
            label: title,
            show: true,
          },
        ]}
        items={labels}
        renderRow={(label: ISelect, index: number) => {
          const value = datasets[0].data[index]
          return (
            <tr onClick={() => handleRowClick(index)} key={index}>
              <td className={'index-container'}>
                {index + 1}
                <div
                  className="g-color"
                  style={{
                    backgroundColor: hiddenData.includes(index)
                      ? 'white'
                      : colorArray[index] + '66',
                    borderColor: colorArray[index],
                    borderWidth: 2,
                  }}
                />
              </td>
              <td className={'text-left'}>{label.label}</td>
              <td className={'text-right'}>
                {currency ? toMoney(value) : value + ' u.'}
              </td>
            </tr>
          )
        }}
      />
    </>
  )
}

export const ChartTable = React.memo(ChartTableComponent)
