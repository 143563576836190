import RequestServices from '../services/requests.service'
import { executeAction } from './global.actions'

export const actionTypes = {
  GET_COTIZAP_REQUESTS: 'GET_COTIZAP_REQUESTS',
  UPDATE_COTIZAP_REQUEST: 'UPDATE_COTIZAP_REQUEST',
}

export const getCotizapRequest = type => dispatch => {
  const process = async () => ({
    requests: await RequestServices.getRequests(type),
  })
  dispatch(executeAction(actionTypes.GET_COTIZAP_REQUESTS, process))
}

export const updateCotizapRequest = (state, request, message) => dispatch => {
  const process = async () => ({
    request: await RequestServices.updateRequests(state, request, message),
  })
  dispatch(executeAction(actionTypes.UPDATE_COTIZAP_REQUEST, process))
}
