import { Graph } from 'react-d3-graph'
import React from 'react'
import './TreeGraph.scss'
import 'src/index.scss'
import { getColor } from 'src/utils/getColor'

const myConfig = {
  automaticRearrangeAfterDropNode: false,
  collapsible: false,
  directed: true,
  focusAnimationDuration: 0.75,
  focusZoom: 1,
  freezeAllDragEvents: false,
  height: 400,
  highlightDegree: 1,
  highlightOpacity: 0.2, //1
  linkHighlightBehavior: true,
  maxZoom: 8,
  minZoom: 0.1,
  nodeHighlightBehavior: true,
  panAndZoom: false,
  staticGraph: false,
  staticGraphWithDragAndDrop: true,
  width: 800,
  d3: {
    alphaTarget: 0.05,
    gravity: -400,
    linkLength: 180,
    linkStrength: 1,
    disableLinkForce: true,
  },
  node: {
    color: getColor('accent'),
    fontColor: 'black',
    fontSize: 12,
    fontWeight: 'normal',
    highlightColor: 'SAME', //red
    highlightFontSize: 12,
    highlightFontWeight: 'bold',
    highlightStrokeColor: getColor('primary'),
    highlightStrokeWidth: 'SAME',
    labelProperty: 'name',
    mouseCursor: 'pointer',
    opacity: 1,
    renderLabel: true,
    size: 500,
    strokeColor: 'none',
    strokeWidth: 2, //1.5
    svg: '',
    symbolType: 'circle',
    labelPosition: 'bottom',
  },
  link: {
    color: getColor('secondary'),
    fontColor: 'black',
    fontSize: 12,
    fontWeight: 'normal',
    highlightColor: getColor('primary'),
    highlightFontSize: 8,
    highlightFontWeight: 'bold',
    mouseCursor: 'pointer',
    opacity: 1,
    labelProperty: 'label',
    renderLabel: true,
    semanticStrokeWidth: true, //false
    strokeWidth: 1.5, //3
    markerHeight: 6,
    markerWidth: 6,
    strokeDasharray: 0,
    strokeDashoffset: 0,
    strokeLinecap: 'butt',
  },
}



export const TreeGraph = ({ data, editItem }) => {

  const onClickNode = function(nodeId) {
    editItem(nodeId)
  };
  
  // const onClickLink = function(source, target) {
  //   window.alert(`Clicked link between ${source} and ${target}`);
  // };


  return (
    <div className="container__graph">
      <div className="container__graph-area">
        <Graph
          id="graph-id" // id is mandatory, if no id is defined rd3g will throw an error
          data={data}
          width={400}
          config={myConfig}
          onClickNode={(nodo) => onClickNode(nodo)}
          //onClickLink={onClickLink}
        />
      </div>
    </div>
  )
}
