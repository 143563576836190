import React, {Component} from 'react';
import {connect} from "react-redux"

import {actionTypes as types, onUpdateOffer, onCreateOffer, setInformationToOffer} from "../../../../actions/offers.actions";
import {selectOffer, selectCreateResponse, selectUpdateResponse} from "../../../../selectors/offers.selector";

import {loadingSelector} from "../../../../selectors/loading.selector";
import {hasErrorsSelector, singleErrorSelector} from "../../../../selectors/error.selector";

import Icon from "../../../../components/buttons/IconButton";
import Button from "../../../../components/buttons/Button";
import Card from "../../../../components/cards/Card";

import moment from "moment";
import {Row, Col, Modal} from "react-bootstrap";
import {Td, Tr} from "react-super-responsive-table";
import {
    faEye,
    faFileAlt,
    faLongArrowAltLeft,
    faSave
} from "@fortawesome/free-solid-svg-icons";
import {
    formatDateFromMillis,
    formatNumberWithCommas
} from "../../../../utils/formatters";
import SweetAlert from "sweetalert-react";
import FormTextField
    from "../../../../components/inputs/FormTextField/FormTextField";
import {Handles, Rail, Slider, Tracks} from "react-compound-slider";
import {
    Handle,
    TooltipRail, Track
} from "../../../../components/slider/SliderComponents";
import CollapsibleCard
    from "../../../../components/cards/collapsible-card/CollapsibleCard";
import { selectCurrentCurrency } from '../../../../selectors/currencies.selector';
import { TableV2 } from '../../../../components';

const sliderStyle = {
    position: 'relative',
    width: '100%',
}

const days = [
    {id: 0, title: 'Domingo'},
    {id: 1, title: 'Lunes'},
    {id: 2, title: 'Martes'},
    {id: 3, title: 'Miercoles'},
    {id: 4, title: 'Jueves'},
    {id: 5, title: 'Viernes'},
    {id: 6, title: 'Sabado'},
]

const times = [
    {id: 0, title: '1:00'},
    {id: 1, title: '2:00'},
    {id: 2, title: '3:00'},
    {id: 3, title: '4:00'},
    {id: 4, title: '5:00'},
    {id: 5, title: '6:00'},
    {id: 6, title: '7:00'},
    {id: 7, title: '8:00'},
    {id: 8, title: '9:00'},
    {id: 9, title: '10:00'},
    {id: 10, title: '11:00'},
    {id: 11, title: '12:00'},
    {id: 12, title: '13:00'},
    {id: 13, title: '14:00'},
    {id: 14, title: '15:00'},
    {id: 15, title: '16:00'},
    {id: 16, title: '17:00'},
    {id: 17, title: '18:00'},
    {id: 18, title: '19:00'},
    {id: 19, title: '20:00'},
    {id: 20, title: '21:00'},
    {id: 21, title: '22:00'},
    {id: 22, title: '23:00'},
    {id: 23, title: '24:00'},
]

class OfferResume extends Component {

    state = {
        productDetails: {
            show: false,
            product: {},
            productPriceDetail: [],
            transportationPayments: []
        },
        details: {
            show: false,
            amounts: [],
            polygon: {}
        },
        alert: {
            show: false,
            title: 'default',
            text: 'default',
            type: 'info',
            onConfirm: () => console.log('default')
        },
        domain: [1, 2],
        values: [0],
        update: [0],
        disabled: false,
        price: {minimum: 0, maximum: 0, price: 0}
    };

    handlerError  = (message) => {
        return {show: true, title: 'Uh-Oh..', text: message, type: 'warning'};
    };

    handlerSuccess = (message) => {
        return {show: true, title: 'Yeah!', text: message, type: 'success'};
    };

    componentWillReceiveProps(props) {
        const {loadingUpdate, hasErrorUpdate, loading, hasError, setInfo, history} = this.props;
        const {errorUpdate, updateResponse, error, createResponse} = props;
        let {alert} = this.state;
        if (loading && !props.loading) {
            if (!hasError && props.hasError) {
                alert = this.handlerError(error.response ? error.response.data.message : 'Ha ocurrido un error inesperado.');
                alert.onConfirm = () => this.setState({alert: {...alert, show: false}});
            } else {
                alert = this.handlerSuccess('La oferta a sido creada satisfactoriamente');
                alert.onConfirm = () => {
                    // setInfo({productList: []});
                    this.setState({alert: {show: false}})
                    history.replace(`/produccion/ofertas/detalles/${createResponse.id}`);
                };
            }
            this.setState({alert});
        }
        if (loadingUpdate && !props.loadingUpdate) {
            if (!hasErrorUpdate && props.hasErrorUpdate) {
                alert = this.handlerError(errorUpdate.response ? errorUpdate.response.data.message : 'Ha ocurrido un error inesperado.');
                alert.onConfirm = () => this.setState({alert: {...alert, show: false}});
            } else {
                alert = this.handlerSuccess('La oferta a sido actualizada satisfactoriamente');
                alert.onConfirm = () => {
                    setInfo({productList: []});
                    this.setState({alert: {show: false}})
                    history.replace(`/produccion/ofertas/detalles/${updateResponse.id}`);
                };
            }
            this.setState({alert});
        }
    };

    seePricesDetails = (item) => {
        const details = Object.assign({}, {...item, show: true});

        const length = details.amounts.length;
        let disabled = false;
        let values = [1];
        let update = [1]
        let domain = [1, length];
        if (length === 1) {
            disabled = true
            domain = [0, length]
        };
        const price = details.amounts[0];
        this.setState({details, domain, disabled, values, update, price});

    };

    renderRow = (item, index) => {
        const {category} = this.props.offer;

        return (
            <Tr className={'data'} key={`${index}`}>
                <Td className={'medium'}>{item.productName}</Td>
                <Td className={'short center'}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                        {category.value === 1 && <Icon onClick={() => this.setState({productDetails: {...item, show: true}})} tooltip={'Ver precios'} icon={faFileAlt}/>}
                    </div>
                </Td>
            </Tr>
        );
    };

    validate = () => {
        const {offer, createOffer, isUpdate, match, updateOffer} = this.props;
        let newOffer = Object.assign({}, {...offer});
        newOffer.availableUntil = moment(offer.availableUntil).valueOf();
        newOffer.priceNegotiable = offer.priceNegotiable || false;
        newOffer.category = offer.category.value;
        newOffer.products = offer.productList.map((p) => (p.productId));
        newOffer.productList = [];

        if (newOffer.category != 1) {
            newOffer.polygon = offer.polygon.id;
            newOffer.deliveryAmount = offer.polygon.deliveryAmount;
        } else
            newOffer.polygon = null;

        if (isUpdate) updateOffer(match.params.id, newOffer);
        else createOffer(newOffer);
    };

    getPercentage = (price, productPrice) => {
        return formatNumberWithCommas((price * 100) / productPrice);
    };

    onChange = (values) => {
        const {details} = this.state;
        const index = (values[0] - 1);
        const price = details.amounts[index];
        this.setState({values, price});
    };

    onUpdate = (update) => {
        const {details} = this.state;
        const index = (update[0] - 1);
        const price = details.amounts[index];
        this.setState({update, price});
    };

    getDeliveryAmount = (offer, symbol) => {
        let deliveryAmount = {value: 0, sign: symbol};

        if (offer.polygon && offer.polygon.deliveryAmount)
            deliveryAmount.value = offer.polygon.deliveryAmount;

        if (offer.category && offer.category.value)
            switch (offer.category.value) {
                case 2:
                    deliveryAmount.sign = symbol;
                    break
                case 3:
                    deliveryAmount.sign = symbol;
                    break
                case 4:
                    deliveryAmount.sign = '%';
                    break
                case 5:
                    deliveryAmount.sign = '%';
                    break
            }
        return deliveryAmount;
    };

    getPickUp = (offer) => {
        let item = null;
        if (offer.category && offer.category.value != 1 && offer.polygon && offer.polygon.pickUpPoints && offer.pickup) {
            const list = offer.polygon.pickUpPoints.filter(this.filter);
            if (list.length >= 1)
                item = list[0];
        }
        if (item) {
            const d = item.pickUpDays.split(',');
            d.sort();

            const pd = d.map((item)=> {
                return days[parseInt(item)].title
            });

            const pickUpTimesArray = item.pickUpTimes.split(',').map((item)=> {
                return times[parseInt(item)].title
            });

            let lat = parseFloat(item.latitude).toFixed(4);
            let lng = parseFloat(item.longitude).toFixed(4);

            item.d = d;
            item.pd = pd;
            item.pickUpTimesArray = pickUpTimesArray;
            item.lat = lat;
            item.lng = lng;
        }
        return item ? item : {};
    };

    filter = (item) => {
        const {offer} = this.props;
        if (!offer.pickup) return item;

        const {value} = offer.pickup;

        return ((value === 1 && item.value === undefined) || (value === 2 && item.value != undefined));
    };

    render() {
        const {offer, loading, isUpdate, loadingUpdate, currentCurrency} = this.props;
        const {alert} = this.state;

        const {productDetails, details, domain, values, disabled, price} = this.state;
        const {show, product, transportationPayments} = productDetails;
        const {category} = this.props.offer;

        const deliveryAmount = this.getDeliveryAmount(offer, currentCurrency.symbol);
        // const pickup = this.getPickUp(offer);

        return (
            <div>
                <Row>
                    <Col md={7}>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <Card title={'Detalles'}>
                                    <div>
                                        <Row style={{justifyContent: 'center'}}>
                                            <span className={'client-name'}>{offer.alias || 'Desconocido'}</span>
                                        </Row>
                                        <Row className={'flex-container space-between'}>
                                            <Col className={'label left'}>Disponible hasta:</Col>
                                            <Col className={'description'}>{offer.availableUntil ? formatDateFromMillis(offer.availableUntil) : 'Desconocido'}</Col>
                                        </Row>
                                        {offer.category.value != 1 && <Row className={'flex-container space-between'}>
                                            <Col className={'label left'}>Recoleeción de producto: </Col>
                                            <Col className={'description'}>{offer.pickup.label}</Col>
                                        </Row>}
                                        {offer.category.value != 1 && <Row className={'flex-container space-between'}>
                                            <Col className={'label left'}>Pago por transporte: </Col>
                                            <Col className={'description'}>{deliveryAmount.sign} {deliveryAmount.value}</Col>
                                        </Row>}
                                        <Row className={'flex-container space-between'}>
                                            <Col className={'label left'}>Tipo de contrato:</Col>
                                            <Col className={'description'}>{offer.category.name}</Col>
                                        </Row>
                                        <Row className={'flex-container space-between'}>
                                            <Col className={'description left'}>{offer.category.description}</Col>
                                        </Row>
                                        <br/>
                                        <Row className={'flex-container space-between'}>
                                            <Col className={'label left'}>Descripción del contrato:</Col>
                                            <Col className={'description'}>{offer.notes}</Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                            {/*
                            {offer.category.value != 1 && <Col lg={12} md={12} sm={12} xs={12}>
                                <Card title={'Recolección de producto'}>
                                    <p>{offer.pickup.label}</p>
                                    <div>
                                        <Row className={'flex-container space-between'}>
                                            <Col className={'label left'}>Latitud y Longitud: </Col>
                                            <Col className={'description'}>{pickup.lat && pickup.lat + ',' + pickup.lng}</Col>
                                        </Row>
                                        <Row className={'flex-container space-between'}>
                                            <Col className={'label left'}>Días de recolección: </Col>
                                            <Col className={'description'}>{pickup.pd && pickup.pd.toString().replace(/,/g,", ")}</Col>
                                        </Row>
                                        <Row className={'flex-container space-between'}>
                                            <Col className={'label left'}>Horario: </Col>
                                            <Col className={'description'}>{pickup.pickUpTimesArray && pickup.pickUpTimesArray.toString().replace(/,/g,", ")}</Col>
                                        </Row>
                                        <Row className={'flex-container space-between'}>
                                            <Col className={'label left'}>Tipo de bodega: </Col>
                                            <Col className={'description'}>{pickup.type == 0 ? 'Bodega fija' : 'Bodega movil'}}</Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>}
                            */}
                        </Row>
                    </Col>
                    <Col md={5}>
                        <Card title={'Productos en la oferta'}>
                            <TableV2
                                headers={[
                                    { label: 'Nombre', show: true, value: ['productName'], type: 'text', className: 'medium' },
                                    {
                                        config: true, show: true, label: '', className: 'short center',
                                        custom: (item) => <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                            {category.value === 1 && <Icon onClick={() => this.setState({ productDetails: { ...item, show: true } })} tooltip={'Ver precios'} icon={faFileAlt} />}
                                        </div>
                                    }
                                ]}
                                mobileAuto
                                storageKey={`offerResume`}
                                items={offer.productList}
                            />
                        </Card>
                    </Col>
                </Row>

                <Modal
                    show={show}
                    size={'lg'}
                    centered
                    onHide={() => this.setState({productDetails: {...productDetails, show: false}})}>

                    <Modal.Header closeButton>
                        <Modal.Title>{product.name || ''} Zonas de trasnporte</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <TableV2
                            headers = {[
                                {label: '#', show: true, value: ['polygon','code'], type: 'text' , className:'mini'},
                                {label: 'Localidad', show: true, value: ['polygon','name'], type: 'text' ,  className:'mini'},
                                {config:true, show: true, label:'', className:'mini center',
                                    custom:(item,i)=> <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                    <Icon icon={faEye} tooltip={'Detalles'} onClick={() => this.seePricesDetails(i)} style={{marginRight: 4}}/>
                                </div>}
                            ]}
                            items={transportationPayments}
                            mobileAuto
                            storageKey={`offerResumeLocal`}
                        />

                        <Modal
                            show={details.show}
                            size={'lg'}
                            centered
                            onHide={() => this.setState({details: {...details, show: false}})}>

                            <Modal.Header closeButton>
                                <Modal.Title>{product.name || ''}: {details.polygon ? details.polygon.name : ''}</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <div style={{margin: 3}} className={'column'}>
                                    <br/><br/>
                                    <Slider
                                        disabled={disabled}
                                        step={1}
                                        domain={domain}
                                        rootStyle={sliderStyle}
                                        onUpdate={this.onUpdate}
                                        onChange={this.onChange}
                                        values={values}
                                    >
                                        <Rail>{railProps => <TooltipRail {...railProps} />}</Rail>
                                        <Handles>
                                            {({handles, activeHandleID, getHandleProps}) => (
                                                <div className="slider-handles">
                                                    {handles.map(handle => (
                                                        <Handle
                                                            key={handle.id}
                                                            disabled={disabled}
                                                            handle={handle}
                                                            domain={domain}
                                                            isActive={handle.id === activeHandleID}
                                                            getHandleProps={getHandleProps}
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                        </Handles>
                                        <Tracks right={false}>
                                            {({tracks, getTrackProps}) => (
                                                <div className="slider-tracks">
                                                    {tracks.map(({id, source, target}) => (
                                                        <Track
                                                            key={id}
                                                            disabled={disabled}
                                                            source={source}
                                                            target={target}
                                                            getTrackProps={getTrackProps}
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                        </Tracks>
                                    </Slider>
                                    <br/><br/>

                                    <div className={'container-offer column'}>
                                        <Row>
                                            <Col xl={7} lg={7} md={7} sm={7}
                                                 xs={8}>
                                                <div
                                                    className={'container-offer'}>
                                                    <div
                                                        className={'center-offer'}>
                                                        <span><strong>Unidades</strong></span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={5} lg={5} md={5} sm={5}
                                                 xs={4}>
                                                <div
                                                    className={'container-offer'}>
                                                    <div
                                                        className={'center-offer'}>
                                                        <span><strong>Pagos</strong></span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={7} lg={7} md={7} sm={7}
                                                 xs={8}>
                                                <div
                                                    className={'container-offer'}>
                                                    <div
                                                        className={'flex-end-offer'}>
                                                        <div>
                                                            <FormTextField
                                                                disabled
                                                                mt={0}
                                                                type={'number'}
                                                                name={'minimum'}
                                                                onChange={() => console.log('value')}
                                                                value={price.minimum}
                                                            />
                                                        </div>
                                                        <div className={'mt-4'}>
                                                            <strong>{'<'}=={'>'}</strong>
                                                        </div>
                                                        <div>
                                                            <FormTextField
                                                                disabled
                                                                mt={0}
                                                                type={'number'}
                                                                name={'maximum'}
                                                                onChange={() => console.log('value')}
                                                                value={price.maximum}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={5} lg={5} md={5} sm={5}
                                                 xs={4}>
                                                <div
                                                    className={'container-offer'}>
                                                    <div
                                                        className={'flex-start-offer'}>
                                                        <div>
                                                            <FormTextField
                                                                disabled
                                                                mt={0}
                                                                id={'price'}
                                                                currency
                                                                name={'price'}
                                                                onChange={() => console.log('value')}
                                                                value={price.amount}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <br/><br/>
                                <CollapsibleCard title={'Mostrar lista'}
                                                 startCollapsed={true}>
                                    {
                                        details.amounts.map((p) =>
                                            <Row key={p.id}>
                                                <Col xl={7} lg={7} md={7} sm={7}
                                                     xs={8}>
                                                    <div
                                                        className={'container-offer'}>
                                                        <div
                                                            className={'flex-end-offer'}>
                                                            <div>
                                                                <FormTextField
                                                                    disabled
                                                                    mt={0}
                                                                    type={'number'}
                                                                    name={'minimum'}
                                                                    onChange={() => console.log('value')}
                                                                    value={p.minimum}
                                                                />
                                                            </div>
                                                            <div
                                                                className={'mt-4'}>
                                                                <strong>{'<'}=={'>'}</strong>
                                                            </div>
                                                            <div>
                                                                <FormTextField
                                                                    disabled
                                                                    mt={0}
                                                                    type={'number'}
                                                                    name={'maximum'}
                                                                    onChange={() => console.log('value')}
                                                                    value={p.maximum}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xl={5} lg={5} md={5} sm={5}
                                                     xs={4}>
                                                    <div
                                                        className={'container-offer'}>
                                                        <div
                                                            className={'flex-start-offer'}>
                                                            <div>
                                                                <FormTextField
                                                                    disabled
                                                                    mt={0}
                                                                    id={'price'}
                                                                    currency
                                                                    name={'price'}
                                                                    onChange={() => console.log('value')}
                                                                    value={p.amount || 0.00}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                </CollapsibleCard>

                            </Modal.Body>
                        </Modal>


                    </Modal.Body>

                </Modal>

{/*

                <Modal
                    show={productDetails.show}
                    size={'md'}
                    centered
                    onHide={() => this.setState({productDetails: {...productDetails, show: false}})}>

                    <Modal.Header closeButton>
                        <Money component='span'><strong>{productDetails.productName}{productDetails.productPrice}</strong></Money>
                    </Modal.Header>

                    <Modal.Body>
                        <div className={'container-offer column'}>
                            <Row>
                                <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                    <div className={'container-offer'}>
                                        <div className={'center-offer'}>
                                            <span><strong>Unidades</strong></span>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={5} lg={5} md={5} sm={5} xs={5}>
                                    <div className={'container-offer'}>
                                        <div className={'center-offer'}>
                                            <span><strong>Precios</strong></span>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                                    <div className={'container-offer'}>
                                        <div className={'center-offer'}>
                                            <span><strong>%</strong></span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            {
                                productDetails.productPriceDetail.map((p, index) =>
                                    <Row key={index}>
                                        <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                            <div className={'container-offer'}>
                                                <div className={'flex-end-offer'}>
                                                    <div>
                                                        <FormTextField
                                                            disabled
                                                            mt={0}
                                                            type={'number'}
                                                            name={'minimum'}
                                                            value={p.minimum}
                                                        />
                                                    </div>
                                                    <div className={'mt-4'}><strong>{'<'}=={'>'}</strong></div>
                                                    <div>
                                                        <FormTextField
                                                            disabled
                                                            mt={0}
                                                            type={'number'}
                                                            name={'maximum'}
                                                            value={p.maximum}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={5} lg={5} md={5} sm={5} xs={5}>
                                            <div className={'container-offer'}>
                                                <div className={'flex-start-offer'}>
                                                    <div>
                                                        <FormTextField
                                                            disabled
                                                            mt={0}
                                                            id={'price'}
                                                            type={'number'}
                                                            name={'price'}
                                                            value={p.amount || 0.00}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                                            <div className={'container-offer'}>
                                                <div className={'center-offer'}>
                                                    <div>
                                                        <br/>
                                                        <span>{this.getPercentage(p.price, productDetails.product.price)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                )
                            }
                        </div>
                    </Modal.Body>
                </Modal>

*/}

                <Row className={'container-buttons'}>
                    <Button
                        disabled={loading || loadingUpdate}
                        onClick={() => {
                            if (category.value === 1)
                                this.props.jumpToStep(1)
                            else this.props.jumpToStep(2)
                        }} icon={faLongArrowAltLeft}>
                        Atras
                    </Button>
                    <Button
                        loading={loading || loadingUpdate}
                        onClick={() => this.validate()} icon={faSave}>
                        {isUpdate ? 'Editar' : 'Crear'}
                    </Button>
                </Row>

                <SweetAlert
                    show={alert.show}
                    title={alert.title}
                    text={alert.text}
                    type={alert.type}
                    onConfirm={() => alert.onConfirm()}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    offer: selectOffer(state),
    loading: loadingSelector([types.ON_CREATE_OFFER])(state),
    hasError: hasErrorsSelector([types.ON_CREATE_OFFER])(state),
    error: singleErrorSelector([types.ON_CREATE_OFFER])(state),
    createResponse: selectCreateResponse(state),
    updateResponse: selectUpdateResponse(state),
    loadingUpdate: loadingSelector([types.ON_UPDATE_OFFER])(state),
    hasErrorUpdate: hasErrorsSelector([types.ON_UPDATE_OFFER])(state),
    errorUpdate: singleErrorSelector([types.ON_UPDATE_OFFER])(state),
    currentCurrency: selectCurrentCurrency(state),
});
const mapDispatchToProps = dispatch => ({
    createOffer: (offer) => dispatch(onCreateOffer(offer)),
    setInfo: (offer) => dispatch(setInformationToOffer(offer)),
    updateOffer: (id, offer) => dispatch(onUpdateOffer(id, offer))
});
export default connect(mapStateToProps, mapDispatchToProps)(OfferResume);
