import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Alert from 'sweetalert-react'
import { Modal, Row, Col } from 'react-bootstrap'
import {
  Button,
  CSVLoaderError,
  FilterByCategory,
  FormText,
  Icon,
  Select,
  TableV2,
} from 'src/components'
import {
  faEye,
  faPlus,
  faSave,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  deleteMultiplePOS,
  getAllPOS,
  onCreatePOS,
  onUpdatePOS,
} from 'src/actions/restaurant.actions'
import { selectAllPOS, selectPOSResponse } from 'src/selectors/restaurant.selector'

import { isAllowed, selectUsersToSelect } from 'src/selectors/modules.selector'
import { getAllCategorizations } from 'src/actions/categorization.actions'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import { getUsersChildrenByModule } from 'src/actions/modules.actions'
import { posTypes } from 'src/enums/posTypes'

const POSList = ({ show, onHide }) => {
  const dispatch = useDispatch()

  const users = useSelector(selectUsersToSelect)

  const allPOS = useSelector(selectAllPOS)
  const loadingAllPOS = useSelector(state =>
    loadingSelector([actionTypes.GET_ALL_POS])(state),
  )

  const loadingPosCU = useSelector(state =>
    loadingSelector([actionTypes.CREATE_OR_UPDATE_POS])(state),
  )
  const hasErrorCU = useSelector(state =>
    hasErrors([actionTypes.CREATE_OR_UPDATE_POS])(state),
  )
  const posResponse = useSelector(selectPOSResponse)

  const loadingDPOS = useSelector(state =>
    loadingSelector([actionTypes.DELETE_POS])(state),
  )
  const hasErrorDPOS = useSelector(state => hasErrors([actionTypes.DELETE_POS])(state))
  const deletePOSResponse = useSelector(state => state.restaurant.deletePOSResponse)

  // PERMISSIONS
  const createPOS = useSelector(state => isAllowed(state, [2201]))
  const deletePOS = useSelector(state => isAllowed(state, [2202]))

  const [flags, setFlags] = useState({
    success: false,
    createUpdate: false,
    delete: false,
  })
  const [alert, setAlert] = useState({ title: '' })
  const [open, setOpen] = useState(false)
  const [posId, setPosId] = useState(null)
  const [posItem, setPosItem] = useState({ users: [] })

  const [loaderError, setLoaderError] = useState({
    show: false,
    items: [],
  })

  useEffect(() => {
    if (!show) return
    setOpen(true)
    dispatch(getAllPOS())
    dispatch(getAllCategorizations(12))
    dispatch(getUsersChildrenByModule(1000, true))
  }, [show])

  useEffect(() => {
    if (loadingPosCU) setFlags({ ...flags, createUpdate: true })
    else if (flags.createUpdate) {
      setFlags({ ...flags, createUpdate: false })
      if (hasErrorCU)
        setAlert({
          ...handlerError(hasErrorCU.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess(posResponse),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            setPosItem({ show: false, users: [] })
            dispatch(getAllPOS(true))
            setFlags({ ...flags, success: true })
          },
        })
    }
  }, [loadingPosCU])

  useEffect(() => {
    if (loadingDPOS) setFlags({ ...flags, delete: true })
    else if (flags.delete) {
      setFlags({ ...flags, delete: false })
      if (hasErrorDPOS)
        setAlert({
          ...handlerError(hasErrorDPOS.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else {
        if (!deletePOSResponse.ok) {
          setLoaderError({
            show: true,
            items: deletePOSResponse.errors,
          })
        } else {
          setAlert({
            ...handlerSuccess(deletePOSResponse.message),
            onConfirm: () => {
              setAlert({ ...alert, show: false })
              dispatch(getAllPOS(true))
              setPosId(0)
              setFlags({ ...flags, success: true })
            },
          })
        }
      }
    }
  }, [loadingDPOS])

  const onClose = () => {
    setOpen(false)
    onHide(flags.success)
  }

  return (
    <div>
      <Modal
        show={open && !alert.show && !loaderError.show && !posItem.show}
        size={'lg'}
        centered
        onHide={() => onClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Listado de Puntos de venta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <TableV2
                headers={[]}
                items={allPOS}
                loading={loadingAllPOS}
                renderRow={(item, i) => (
                  <div key={i} className={'b-user-item'}>
                    <div className="justify-content-start align-items-start bu-en left">
                      <div className="b-user-name">{item.name}</div>
                      <div className="b-user-email">
                        {item.address || 'Sin dirección'}
                      </div>
                    </div>
                    <div className="d-flex row align-items-center mr-2 bu-b right">
                      <Icon
                        icon={faEye}
                        tooltip={'Ver Detalle'}
                        onClick={() => {
                          const type = posTypes.find(
                            t =>
                              item.type === t.type && item.subType == Boolean(t.subType),
                          )
                          setPosItem({ ...item, type, show: true })
                        }}
                      />
                      {deletePOS && (
                        <Icon
                          spin={posId === item.id && loadingDPOS}
                          style={{ marginLeft: 5 }}
                          tooltip={'Eliminar'}
                          icon={faTrash}
                          color={'secondary'}
                          onClick={() => {
                            setAlert({
                              show: true,
                              title: 'Eliminar Punto de venta: ' + item.name,
                              text: '¿Desea eliminar el punto de venta? ya no será posible acceder al punto de venta y los reportes por punto de ventas ya no consideran esta información. Está acción no podrá ser revertida.',
                              type: 'warning',
                              showCancelButton: true,
                              cancelButtonText: 'Cancelar',
                              confirmButtonText: 'Eliminar',
                              confirmButtonColor: '#226095',
                              onConfirm: () => {
                                setAlert({ ...alert, show: false })
                                setPosId(item.id)
                                dispatch(deleteMultiplePOS([item.id]))
                              },
                              onCancel: () => setAlert({ ...alert, show: false }),
                            })
                          }}
                        />
                      )}
                    </div>
                  </div>
                )}
              />
            </Col>
          </Row>
        </Modal.Body>

        {createPOS && (
          <Modal.Footer>
            <Row className="container-buttons">
              <Button
                icon={faPlus}
                onClick={() => setPosItem({ create: true, show: true, users: [] })}>
                Agregar
              </Button>
            </Row>
          </Modal.Footer>
        )}
      </Modal>

      <Modal
        show={posItem.show}
        size={'lg'}
        centered
        onHide={() => setPosItem({ show: false, users: [] })}>
        <Modal.Header closeButton>
          <Modal.Title>
            {posItem.create ? 'Crear Punto de Venta' : posItem.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {posItem.type?.value !== 3 && (
              <Col lg={12} md={12} sm={12}>
                <div className={'column'}>
                  <label className={'ftf-form-label left mt-3'}>Categorías</label>
                  <FilterByCategory
                    initial={posItem.show}
                    list={posItem.categories}
                    get={categories => {
                      setPosItem({ ...posItem, categories })
                    }}
                  />
                </div>
              </Col>
            )}

            <Col lg={6} md={6} sm={12}>
              <FormText
                disabled={loadingPosCU}
                label={'Nombre'}
                value={posItem.name}
                required
                changeValue={value => setPosItem({ ...posItem, name: value })}
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <FormText
                disabled={loadingPosCU}
                label={'Dirección'}
                value={posItem.address}
                changeValue={value => setPosItem({ ...posItem, address: value })}
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Select
                label={'Tipo de punto de venta'}
                disabled={posItem.id || loadingPosCU}
                value={posItem.type}
                options={posTypes}
                onChange={type => setPosItem({ ...posItem, type })}
                required
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Select
                disabled={loadingPosCU}
                label={'Usuarios disponibles'}
                options={[
                  { value: 0, label: 'Asignar a Todos' },
                  { value: -1, label: 'Remover a todos' },
                  ...users,
                ].filter(u => posItem.users.findIndex(pu => pu.id === u.value) === -1)}
                onChange={value => {
                  let u = Object.assign([], posItem.users)
                  if (value.value === -1) {
                    u = []
                  } else if (value.value === 0) {
                    const uu = users.filter(
                      u => posItem.users.findIndex(pu => pu.id === u.value) === -1,
                    )
                    u.push(...uu)
                  } else {
                    u.push(value)
                  }
                  setPosItem({ ...posItem, users: u })
                }}
                value={{ value: null, label: 'Selección' }}
              />
            </Col>
            <Col lg={12} md={12} sm={12}>
              <Row className={'pl-1 pt-2'}>
                {posItem.users.map(u => (
                  <div
                    onClick={() =>
                      setPosItem({
                        ...posItem,
                        users: posItem.users.filter(pu => pu.id !== u.id),
                      })
                    }
                    key={u.id}
                    style={{ borderColor: '#226095' }}
                    className={`c-item father assigned ml-2`}>
                    <label className={'c-item-tag father'}>{u.name}</label>
                    <Icon
                      className={`c-item-button father assigned`}
                      style={{ backgroundColor: '#226095' }}
                      icon={faTimes}
                      tooltip={'Remover'}
                      color={'white'}
                      onClick={() => undefined}
                    />
                  </div>
                ))}
              </Row>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Row className="container-buttons">
            <Button
              loading={loadingPosCU}
              icon={faSave}
              disabled={!posItem.name || posItem.users.length <= 0 || !posItem.type}
              onClick={() => {
                const params = { name: posItem.name, address: posItem.address }
                const users = posItem.users.map(u => u.id)
                const categories = posItem.categories?.map(pc => pc.id) || []

                if (posItem.create)
                  dispatch(
                    onCreatePOS(
                      {
                        users,
                        categories,
                        agileSelection: posItem.agileSelection,
                        type: posItem.type.type,
                        subType: posItem.type.subType,
                        footerPhrase: posItem.footerPhrase,
                        skipScanner: posItem.skipScanner,
                      },
                      params,
                    ),
                  )
                else
                  dispatch(
                    onUpdatePOS(
                      posItem.id,
                      {
                        users,
                        categories,
                        agileSelection: posItem.agileSelection,
                        footerPhrase: posItem.footerPhrase,
                        skipScanner: posItem.skipScanner,
                      },
                      params,
                    ),
                  )
              }}>
              {posItem.create ? 'Crear POS' : 'Actualizar POS'}
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <CSVLoaderError
        {...loaderError}
        onHide={() => setLoaderError({ ...loaderError, show: false })}
      />

      <Alert {...alert} />
    </div>
  )
}
export default POSList
