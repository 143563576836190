import '../Transfers.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Card from '../../../components/cards/Card'
import Select from '../../../components/inputs/Select/CustomSelect'
import Icon from '../../../components/buttons/IconButton'
import Button from '../../../components/buttons/Button'
import TextField from '../../../components/inputs/FormTextField/FormTextField'

import { Row, Col, Modal } from 'react-bootstrap'
import { Tr, Td } from 'react-super-responsive-table'
import {
  faArrowLeft,
  faArrowRight,
  faCheck,
  faChevronRight,
  faInfoCircle,
  faQuestionCircle,
  faSave,
  faTimes,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import Alert from 'sweetalert-react'

import {
  selectAllOwnWarehousesForSelect,
  selectExternalActiveWarehousesForSelect,
  selectWarehouseInventory,
  selectTransferDetail,
  selectAllWarehousesForSelect,
} from '../../../selectors/warehouse.selector'

import {
  actionTypes,
  createTransfer,
  getWarehouseInventory,
  getWarehouses,
  setInformationTransfer,
} from '../../../actions/warehouse.actions'

import { selectContractedProducts } from '../../../selectors/contracts.selector'

import { getProductContractedByWarehouse } from '../../../selectors/products.selector'
import { loadingSelector } from '../../../selectors/loading.selector'
import {
  errorsSelector,
  hasErrorsSelector,
  handlerError,
  handlerSuccess,
} from '../../../selectors/error.selector'
import { selectCurrentUser } from '../../../selectors/user.selector'
import { TableV2 } from '../../../components'

class ExternalTransferProduct extends Component {
  state = {
    active: true,
    inactive: true,
    contracted: true,
    noContracted: false,
    error: {},
    modal: {
      show: false,
      selected: {},
      quantity: 0,
    },
    alert: {
      show: false,
      title: 'default',
      text: 'default',
      type: 'info',
      onConfirm: () => console.log('default'),
    },
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { loadingT, hasErrorT, handlerSuccess, handlerError } = this.props
    const { errorT } = props

    if (loadingT && !props.loadingT) {
      let { alert } = this.state
      if (!hasErrorT && props.hasErrorT) {
        alert = handlerError(
          errorT && errorT.response
            ? errorT.response.data.message
            : 'Ha ocurrido un Error inesperado',
        )
        alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
      } else {
        alert = handlerSuccess('Transferencia externa creada satisfactoriamente')
        alert.onConfirm = () => {
          this.setState({ alert: { ...alert, show: false } })
          this.props.history.goBack()
        }
      }
      this.setState({ alert })
    }
  }

  handleCheckBoxChange = event => {
    const { name, checked } = event.target
    this.setState({ [name]: checked })
  }

  onChangeSelect = (data, parametter) => {
    let { transfer, setInfo } = this.props
    setInfo({ ...transfer, [parametter]: data })
  }

  filter = item => {
    const { transfer } = this.props
    const { contracted, noContracted } = this.state
    const product = transfer.toTransfer.find(t => t.productId === item.productId)
    return (
      !product && ((item.contracted && contracted) || (!item.contracted && noContracted))
    )
  }

  handleInputChange = event => {
    const { value } = event.target
    let { modal } = this.state
    let quantity = parseInt(value)
    if (quantity <= 0) quantity = 0
    if (quantity > modal.selected.productExistence)
      quantity = modal.selected.productExistence
    this.setState({ modal: { ...modal, quantity } })
  }

  addProduct = () => {
    let { transfer, setInfo } = this.props
    let { modal } = this.state

    transfer.toTransfer.push({ ...modal.selected, quantity: modal.quantity })
    setInfo(transfer)
    this.setState({ modal: { ...modal, show: false } })
  }

  removeFromTransfer = index => {
    let { transfer, setInfo } = this.props
    transfer.toTransfer.splice(index, 1)
    setInfo(transfer)
  }

  validate = () => {
    const { transfer } = this.props
    let error = {}

    if (!transfer.fromWarehouse.value)
      error.fromWarehouse = 'Se debe escoger una bodega de salida'
    if (!transfer.toWarehouse.value)
      error.toWarehouse = 'Se debe escoger una bodega destino'

    if (Object.keys(error).length === 0)
      this.props.createTransfer(
        transfer.fromWarehouse.value,
        transfer.toWarehouse.value,
        transfer.description,
        transfer.toTransfer,
      )
    this.setState({ error })
  }

  render() {
    const { active, inactive, error, contracted, noContracted, modal, alert } = this.state
    const {
      allWarehouses,
      transfer,
      toWarehouses,
      inventory,
      contractedProducts,
      loadingT,
    } = this.props
    let newWarehouses = allWarehouses.filter(
      w =>
        w.attendant === this.props.user.id ||
        w.attendantsIds.some(id => id === this.props.user.id),
    )

    const products = this.props.products(inventory, contractedProducts)
    return (
      <div>
        <Row>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <Card title={'Bodega saliente'}>
              <span className={'filter-title'}>Mostrar productos:</span>
              <Row className={'transfers-subheader'}>
                {/*
                                    <Col sm={3} style={{paddingTop: 10}}>
                                        <label className={'checkbox-label'}><input type="checkbox" name="active" checked={active} onChange={this.handleCheckBoxChange}/> Activos</label>
                                    </Col>
                                    <Col sm={3} style={{paddingTop: 10}}>
                                        <label className={'checkbox-label'}><input type="checkbox" name="inactive" checked={inactive} onChange={this.handleCheckBoxChange}/> Inactivos</label>
                                    </Col>
                                    */}
                <Col sm={3} style={{ paddingTop: 10 }}>
                  <label className={'checkbox-label'}>
                    <input
                      type="checkbox"
                      name="contracted"
                      checked={contracted}
                      onChange={this.handleCheckBoxChange}
                    />{' '}
                    Contratado
                  </label>
                </Col>
                <Col sm={3} style={{ paddingTop: 10 }}>
                  <label className={'checkbox-label'}>
                    <input
                      type="checkbox"
                      name="noContracted"
                      checked={noContracted}
                      onChange={this.handleCheckBoxChange}
                    />{' '}
                    Sin contrato
                  </label>
                </Col>
              </Row>

              <Row>
                <Col xl={11} lg={11} md={11} sm={11} xs={11}>
                  <Select
                    className={'transfer-from-spacer'}
                    value={transfer.fromWarehouse}
                    options={newWarehouses.filter(
                      item =>
                        item.value !==
                        (transfer.toWarehouse && transfer.toWarehouse.value),
                    )}
                    onChange={data => {
                      this.onChangeSelect(data, 'fromWarehouse')
                      this.props.getInventory(data.value)
                    }}
                    error={error.fromWarehouse}
                    label={'Bodega Origen'}
                  />
                </Col>
                <Col xl={1} lg={1} md={1} sm={1} xs={1} className={'info-button'}>
                  {transfer.fromWarehouse && transfer.fromWarehouse.value ? (
                    <Icon icon={faInfoCircle} tooltip={'Ver detalles'} />
                  ) : (
                    <Icon
                      icon={faQuestionCircle}
                      tooltip={'Selecciona la bodega saliente'}
                    />
                  )}
                </Col>
              </Row>
              {transfer.fromWarehouse && transfer.fromWarehouse.value ? (
                <TableV2
                  headers={[
                    {
                      label: '',
                      show: true,
                      select: true,
                      custom: item =>
                        item.contracted ? (
                          <Icon tooltip={'Contratado'} icon={faCheck} color={'green'} />
                        ) : (
                          <Icon tooltip={'Sin contratado'} icon={faTimes} color={'red'} />
                        ),
                      className: 'mini',
                    },
                    {
                      label: 'SKU',
                      show: true,
                      value: ['productCode'],
                      type: 'text',
                      className: 'name',
                    },
                    {
                      label: 'Producto',
                      show: true,
                      value: ['productName'],
                      type: 'text',
                      className: 'medium right',
                    },
                    {
                      label: 'Existencia',
                      show: true,
                      value: ['productExistence'],
                      type: 'measure',
                      className: 'mini',
                    },
                    {
                      config: true,
                      show: true,
                      label: '',
                      className: 'mini center',
                      custom: item =>
                        item.contracted && (
                          <Icon
                            icon={faArrowRight}
                            tooltip={'agregar'}
                            onClick={() =>
                              this.setState({
                                modal: {
                                  show: true,
                                  selected: item,
                                  quantity: 0,
                                },
                              })
                            }
                          />
                        ),
                    },
                  ]}
                  noItemsLegend={'Esta bodega no posee productos con contratos activos'}
                  items={products.filter(this.filter)}
                  mobileAuto
                  storageKey={`externalTran1`}
                />
              ) : (
                <h4
                  style={{
                    textAlign: 'center',
                    marginTop: 50,
                  }}>
                  Seleccione una bodega para visualizar su inventario
                </h4>
              )}
            </Card>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <Card title={'Bodega destino'}>
              <Row>
                <Col xl={11} lg={11} md={11} sm={11} xs={11}>
                  <Select
                    value={transfer.toWarehouse}
                    options={toWarehouses}
                    error={error.toWarehouse}
                    onChange={data => this.onChangeSelect(data, 'toWarehouse')}
                    label={'Bodega Destino'}
                  />
                </Col>

                <Col xs={1} className={'info-button'}>
                  {transfer.toWarehouse && transfer.toWarehouse.value ? (
                    <Icon icon={faInfoCircle} tooltip={'Ver detalles'} />
                  ) : (
                    <Icon
                      icon={faQuestionCircle}
                      tooltip={'Selecciona la empresa y la bodega de destino'}
                    />
                  )}
                </Col>
              </Row>
              <TableV2
                headers={[
                  {
                    label: 'SKU',
                    show: true,
                    value: ['productCode'],
                    type: 'text',
                    className: 'name',
                  },
                  {
                    label: 'Producto',
                    show: true,
                    value: ['productName'],
                    type: 'text',
                    className: 'medium right',
                  },
                  {
                    label: 'Existencia',
                    show: true,
                    value: ['quantity'],
                    type: 'measure',
                    className: 'mini',
                  },
                  {
                    config: true,
                    show: true,
                    label: '',
                    className: 'mini center',
                    custom: (item, index) => (
                      <Icon
                        icon={faTrashAlt}
                        tooltip={'Eliminar'}
                        onClick={() => this.removeFromTransfer(index)}
                      />
                    ),
                  },
                ]}
                noItemsLegend={'Seleccione 1 o más productos para transferir.'}
                mobileAuto
                storageKey={`externalTran2`}
              />
            </Card>
          </Col>
        </Row>

        <Row className={'container-buttons'}>
          <Button
            disabled={loadingT}
            onClick={() => this.props.jumpToStep(0)}
            icon={faArrowLeft}>
            Salir
          </Button>

          <Button
            loading={loadingT}
            disabled={transfer.toTransfer.length <= 0}
            onClick={() => this.validate()}
            icon={faSave}>
            Crear
          </Button>
        </Row>

        <Modal
          show={modal.show}
          size={'md'}
          centered
          onHide={() =>
            this.setState({
              modal: {
                show: false,
                selected: {},
                quantity: 0,
              },
            })
          }>
          <Modal.Header closeButton>
            <div>
              <Modal.Title>Agregar producto</Modal.Title>
              <span>
                <strong>{modal.selected.productName}:</strong>{' '}
                {modal.selected.productDescription}
              </span>
            </div>
          </Modal.Header>

          <Modal.Body>
            <div>
              <TextField
                label={`Selecciona el total a agregar (${modal.selected.productExistence})`}
                placeholder={modal.selected.productExistence}
                type={'number'}
                name={'quantity'}
                value={modal.quantity}
                onChange={this.handleInputChange}
                required
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Row className={'container-buttons'}>
              <Button disabled={modal.quantity <= 0} onClick={() => this.addProduct()}>
                Agregar
              </Button>
            </Row>
          </Modal.Footer>
        </Modal>

        <Alert {...alert} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  allWarehouses: selectAllWarehousesForSelect(state),
  user: selectCurrentUser(state),
  ownWarehouses: selectAllOwnWarehousesForSelect(state),
  toWarehouses: selectExternalActiveWarehousesForSelect(state),
  transfer: selectTransferDetail(state),
  inventory: selectWarehouseInventory(state),
  contractedProducts: selectContractedProducts(state),
  products: (inventory, contracted) =>
    getProductContractedByWarehouse(inventory, contracted),
  loadingT: loadingSelector([actionTypes.CREATE_TRANSFER])(state),
  hasErrorT: hasErrorsSelector([actionTypes.CREATE_TRANSFER])(state),
  errorT: errorsSelector([actionTypes.CREATE_TRANSFER])(state),
  handlerSuccess: message => handlerSuccess(message),
  handlerError: message => handlerError(message),
})
const mapDispatchToProps = dispatch => ({
  setInfo: transfer => dispatch(setInformationTransfer(transfer)),
  getInventory: warehouseId => dispatch(getWarehouseInventory(warehouseId, false, true)),
  createTransfer: (fromWarehouseId, toWarehouseId, description, detail) =>
    dispatch(
      createTransfer(fromWarehouseId, toWarehouseId, false, description, false, detail),
    ),
  getWarehouses: () => dispatch(getWarehouses(false, false)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ExternalTransferProduct)
