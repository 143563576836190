import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Row, Col, Modal } from 'react-bootstrap'
import {
  faCheck,
  faCheckDouble,
  faEye,
  faMinusCircle,
  faMoneyBillWave,
  faSignal,
  faTimes,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons'

import {
  TableV2,
  Icon,
  Card,
  Select,
  Button,
  FormText,
  CustomTabs,
  SelectedDates,
  Dropdown,
} from 'src/components'
import {
  approveCashTransfer,
  actionTypes as balanceActions,
  cancelCashTransfer,
  getCompanyBalanceByType,
  getReceivedCashTransfers,
  getSentCashTransfers,
} from 'src/actions/balance.actions'
import {
  selectBalanceInCompany,
  selectCompanyBalance,
  selectReceivedCashTransfers,
  selectSentCashTransfers,
  selectTotalCompanyBalance,
  selectTotalReceivedCashTransfers,
  selectTotalSentCashTransfers,
  selectUCompanyBalance,
  selectUTotalCompanyBalance,
} from 'src/selectors/balance.selector'

import { getAccounts } from 'src/actions/banks.actions'
import { selectGetAccounts } from 'src/selectors/banks.selector'

import {
  actionTypes as disbursementActions,
  createDisbursement,
} from 'src/actions/disbursements.actions'

import {
  handlerError,
  handlerSuccess,
  singleErrorSelector,
  hasErrorsSelector,
} from 'src/selectors/error.selector'
import { loadingSelector } from 'src/selectors/loading.selector'

import { formatDateFromMillis, getItemValue } from 'src/utils/formatters'

import CustomReference from '../modal/CustomReference'
import { toMoney } from 'src/utils/utilities'
import { showAlert } from 'src/actions/alert.actions'
import { CashTransferStatus, cashTransferStatusEnum } from 'src/enums/cashTransferStatus'
import { selectUsers } from 'src/selectors/modules.selector'
import IconButton from '../buttons/IconButton'

const balanceInitialStatus = {
  start: null,
  end: null,
  size: 10,
  skip: 0,
  search: null,
}
const transferInitialStatus = {
  start: null,
  end: null,
  size: 10,
  skip: 0,
  search: null,
  statusId: CashTransferStatus.PENDING_APPROVAL,
}

/**
 * Company / user balance
 * @prop {boolean} byUser - True if balance is from a user
 * @prop {boolean} hideDisbursement - If true hides disbursement button
 * @prop {boolean} balanceType - Balance type
 * @prop {boolean} userId - User's id
 * @prop {boolean} balance - Balance object
 */
const BalanceTable = ({
  byUser = false,
  hideDisbursement = false,
  balanceType,
  userId,
  balance,
}) => {
  const dispatch = useDispatch()

  const users = useSelector(selectUsers)
  const userBalance = useSelector(selectBalanceInCompany)
  const loadingUserBalance = useSelector(state =>
    loadingSelector([
      balanceActions.GET_COMPANY_BALANCE,
      balanceActions.GET_USER_COMPANY_BALANCE,
    ])(state),
  )
  const loadingSentCashTransfers = useSelector(state =>
    loadingSelector([balanceActions.GET_SENT_CASH_TRANSFERS])(state),
  )
  const loadingReceivedCashTransfers = useSelector(state =>
    loadingSelector([balanceActions.GET_RECEIVED_CASH_TRANSFERS])(state),
  )

  const loadingCreateDisbursement = useSelector(state =>
    loadingSelector([disbursementActions.CREATE_DISBURSEMENT])(state),
  )
  const hasErrorCreateDisbursement = useSelector(state =>
    hasErrorsSelector([disbursementActions.CREATE_DISBURSEMENT])(state),
  )
  const errorCreateDisbursement = useSelector(state =>
    singleErrorSelector([disbursementActions.CREATE_DISBURSEMENT])(state),
  )

  const loadingCreateCashTransfer = useSelector(state =>
    loadingSelector([balanceActions.CREATE_CASH_TRANSFER])(state),
  )
  const hasErrorCreateCashTransfer = useSelector(state =>
    hasErrorsSelector([balanceActions.CREATE_CASH_TRANSFER])(state),
  )

  const loadingApproveCashTransfer = useSelector(state =>
    loadingSelector([balanceActions.APPROVE_CASH_TRANSFER])(state),
  )
  const hasErrorApproveCashTransfer = useSelector(state =>
    hasErrorsSelector([balanceActions.APPROVE_CASH_TRANSFER])(state),
  )
  const errorApproveCashTransfer = useSelector(state =>
    singleErrorSelector([balanceActions.APPROVE_CASH_TRANSFER])(state),
  )

  const loadingCancelCashTransfer = useSelector(state =>
    loadingSelector([balanceActions.CANCEL_CASH_TRANSFER])(state),
  )
  const hasErrorCancelCashTransfer = useSelector(state =>
    hasErrorsSelector([balanceActions.CANCEL_CASH_TRANSFER])(state),
  )
  const errorCancelCashTransfer = useSelector(state =>
    singleErrorSelector([balanceActions.CANCEL_CASH_TRANSFER])(state),
  )

  const loadingCreateEntry = useSelector(state =>
    loadingSelector([balanceActions.CREATE_USER_ENTRY])(state),
  )
  const hasErrorCreate = useSelector(state =>
    hasErrorsSelector([balanceActions.CREATE_USER_ENTRY])(state),
  )

  const companyBalanceItems = useSelector(selectCompanyBalance)
  const userBalanceItems = useSelector(selectUCompanyBalance)
  const sentCashTransfers = useSelector(selectSentCashTransfers)
  const receivedCashTransfers = useSelector(selectReceivedCashTransfers)

  const totalCompanyBalance = useSelector(selectTotalCompanyBalance)
  const totalUserBalance = useSelector(selectUTotalCompanyBalance)
  const totalSentCashTransfers = useSelector(selectTotalSentCashTransfers)
  const totalReceivedCashTransfers = useSelector(selectTotalReceivedCashTransfers)

  const bankAccounts = useSelector(selectGetAccounts)

  const [actions, setActions] = useState({
    disbursement: false,
    entry: false,
    approveTransfer: false,
    cancelTransfer: false,
  })
  const [key, setKey] = useState(0)
  const [reference, setReference] = useState({ show: false, type: 1, documentId: null })
  const [disbursement, setDisbursement] = useState({
    show: false,
    amount: 0,
    account: null,
  })

  const [balanceFilters, setBalanceFilters] = useState(balanceInitialStatus)
  const [sentTransferFilters, setSentTransferFilters] = useState(transferInitialStatus)
  const [receivedTransferFilters, setReceivedTransferFilters] =
    useState(transferInitialStatus)

  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    handleGetUserBalances()

    // TODO: Eliminar una vez las tabs sean capaces de mostrar filtros distintos
    if (!loaded) return setLoaded(true)
    handleGetSentCashTransfers({
      ...balanceFilters,
      statusId: sentTransferFilters.statusId,
    })
    handleGetReceivedCashTransfers({
      ...balanceFilters,
      statusId: receivedTransferFilters.statusId,
    })
  }, [balanceFilters])

  useEffect(() => {
    handleGetSentCashTransfers()

    // TODO: Eliminar una vez las tabs sean capaces de mostrar filtros distintos
    if (!loaded) return
    handleGetUserBalances(sentTransferFilters)
    handleGetReceivedCashTransfers(sentTransferFilters)
  }, [sentTransferFilters])

  useEffect(() => {
    handleGetReceivedCashTransfers()

    // TODO: Eliminar una vez las tabs sean capaces de mostrar filtros distintos
    if (!loaded) return
    handleGetUserBalances(receivedTransferFilters)
    handleGetSentCashTransfers(receivedTransferFilters)
  }, [receivedTransferFilters])

  useEffect(() => {
    if (loadingCreateDisbursement) setActions({ ...actions, disbursement: true })
    else if (actions.disbursement) {
      setActions({ ...actions, disbursement: false })
      if (hasErrorCreateDisbursement)
        dispatch(
          showAlert({
            ...handlerError(errorCreateDisbursement.message),
          }),
        )
      else
        dispatch(
          showAlert({
            ...handlerSuccess('Solicitud creada exitosamente'),
            onConfirm: () => {
              setDisbursement({ ...disbursement, show: false })
            },
          }),
        )
    }
  }, [loadingCreateDisbursement])

  useEffect(() => {
    if (loadingCreateCashTransfer) setActions({ ...actions, approveTransfer: true })
    else if (actions.approveTransfer) {
      setActions({ ...actions, approveTransfer: false })
      if (!hasErrorCreateCashTransfer) handleGetSentCashTransfers()
    }
  }, [loadingCreateCashTransfer])

  useEffect(() => {
    if (loadingApproveCashTransfer) setActions({ ...actions, approveTransfer: true })
    else if (actions.approveTransfer) {
      setActions({ ...actions, approveTransfer: false })
      if (hasErrorApproveCashTransfer)
        dispatch(
          showAlert({
            ...handlerError(errorApproveCashTransfer.message),
          }),
        )
      else {
        handleGetReceivedCashTransfers()
        handleGetUserBalances()
        dispatch(
          showAlert({
            ...handlerSuccess('Transferencia de efectivo aprobada exitosamente'),
          }),
        )
      }
    }
  }, [loadingApproveCashTransfer])

  useEffect(() => {
    if (loadingCancelCashTransfer) setActions({ ...actions, cancelTransfer: true })
    else if (actions.cancelTransfer) {
      setActions({ ...actions, cancelTransfer: false })
      if (hasErrorCancelCashTransfer)
        dispatch(
          showAlert({
            ...handlerError(errorCancelCashTransfer.message),
          }),
        )
      else {
        if (key === 1) handleGetSentCashTransfers()
        else {
          handleGetReceivedCashTransfers()
          handleGetUserBalances()
        }
        dispatch(
          showAlert({
            ...handlerSuccess(
              `Transferencia de efectivo ${
                key === 1 ? 'cancelada' : 'rechazada'
              } exitosamente`,
            ),
          }),
        )
      }
    }
  }, [loadingCancelCashTransfer])

  useEffect(() => {
    if (loadingCreateEntry) setActions({ ...actions, entry: true })
    else if (actions.entry) {
      setActions({ ...actions, entry: false })
      if (!hasErrorCreate) handleGetUserBalances()
    }
  }, [loadingCreateEntry])

  const statusOptions = [
    { value: CashTransferStatus.PENDING_APPROVAL, label: 'Pendientes de aprobar' },
    { value: CashTransferStatus.APPROVED, label: 'Aprobadas' },
    { value: CashTransferStatus.CANCELED, label: 'Canceladas', show: key === 1 },
    { value: CashTransferStatus.REJECTED, label: 'Rechazadas', show: key === 2 },
    { value: null, label: 'Todas' },
  ]

  const handleGetUserBalances = (filters = balanceFilters) => {
    dispatch(
      getCompanyBalanceByType({
        start: filters.start ? filters.start.valueOf() : null,
        end: filters.end ? filters.end.valueOf() : null,
        module: 1000,
        balanceType,
        user: byUser,
        userId,
        ...filters,
      }),
    )
  }

  const handleGetSentCashTransfers = (filters = sentTransferFilters) => {
    dispatch(
      getSentCashTransfers({
        startDate: filters.start ? filters.start.valueOf() : null,
        endDate: filters.end ? filters.end.valueOf() : null,
        ...filters,
      }),
    )
  }

  const handleGetReceivedCashTransfers = (filters = receivedTransferFilters) => {
    dispatch(
      getReceivedCashTransfers({
        startDate: filters.start ? filters.start.valueOf() : null,
        endDate: filters.end ? filters.end.valueOf() : null,
        ...filters,
      }),
    )
  }

  const getColor = item => {
    let color = ''
    let icon = faMinusCircle
    let flip = ''

    if (item.previousBalance > item.newBalance) {
      color = 'red'
      icon = faSignal
      flip = 'horizontal'
    } else if (item.previousBalance < item.newBalance) {
      color = 'green'
      icon = faSignal
    }

    return { color, icon, flip }
  }

  const getItemsMobile = data => {
    const store = localStorage.getItem(`bal`)
      ? JSON.parse(localStorage.getItem(`bal`))
      : {
          title: { value: ['createdAt'], type: 'date' },
          subtitle: { value: ['amount', 'name'], type: 'currency' },
          complement1: { value: ['previousBalance', 'name'], type: 'currency' },
          complement2: { value: ['newBalance'], type: 'currency' },
        }

    return data.map(item => ({
      id: item.id,
      title: getItemValue(item, store.title.value, store.title.type),
      subtitle: getItemValue(item, store.subtitle.value, store.subtitle.type),
      complement1: getItemValue(item, store.complement1.value, store.complement1.type),
      complement2: getItemValue(item, store.complement2.value, store.complement2.type),
      key: `bal-${item.id}`,
      action: (
        <Icon
          icon={faEye}
          tooltip={'Ver referencia'}
          onClick={() =>
            setReference({
              show: true,
              type: item.type,
              documentId: item.reference || item.id,
            })
          }
        />
      ),
      more: [
        { title: `Fecha: `, info: formatDateFromMillis(item.createdAt) },
        { title: `Acción:  `, info: item.entryType ? item.entryType.name : '---' },
        { title: `Monto: `, info: toMoney(item.amount) },
        {
          title: `Balance anterior: `,
          info: toMoney(item.previousBalance),
        },
        { title: `Balance nuevo: `, info: toMoney(item.newBalance) },
      ],
    }))
  }

  const getAmountColor = amount => {
    let color = 'gray'
    if (amount > 0) color = 'green'
    else if (amount < 0) color = 'red'
    return color
  }

  const available = byUser ? userBalance.balanceTransportist : balance.available

  const balanceItems = (byUser ? userBalanceItems : companyBalanceItems).map(item => ({
    ...item,
    ...getColor(item),
  }))

  const balanceHeaders = [
    {
      label: '',
      custom: item => (
        <Icon color={item.color} flip={item.flip} icon={item.icon} tooltip={''} />
      ),
      className: 'mini',
    },
    {
      label: 'Fecha',
      value: ['createdAt', 'name'],
      type: 'date',
      className: 'mini',
    },
    {
      label: 'Acción',
      value: ['entryType', 'name'],
      type: 'text',
      className: 'short',
    },
    {
      label: 'Referencia',
      value: ['numberReference'],
      type: 'text',
      className: 'short',
    },
    {
      label: 'Saldo anterior',
      value: ['previousBalance', 'name'],
      type: 'currency',
      className: 'mini',
    },
    {
      label: 'Ajuste',
      value: ['amount', 'name'],
      type: 'currency',
      className: 'mini',
    },
    {
      label: 'Saldo nuevo',
      value: ['newBalance', 'name'],
      type: 'currency',
      className: 'mini',
    },
    {
      config: true,
      label: '',
      className: 'mini center',
      custom: item => (
        <IconButton
          tooltip={'Ver detalle'}
          icon={faEye}
          onClick={() =>
            setReference({
              show: true,
              type: item.type,
              documentId: item.reference || item.id,
            })
          }
        />
      ),
    },
  ]

  const balanceTableElement = (
    <TableV2
      // @ts-expect-error
      loading={loadingUserBalance}
      headers={balanceHeaders}
      storageKey={'bal'}
      items={balanceItems}
      itemsMobile={getItemsMobile(balanceItems)}
      total={byUser ? totalUserBalance : totalCompanyBalance}
      handleChange={search =>
        setTimeout(() => {
          setBalanceFilters({ ...balanceFilters, search })
        }, 1000)
      }
      customFilter={
        <SelectedDates
          withOptionNull
          onDateChange={(start, end) =>
            setBalanceFilters({ ...balanceFilters, start, end })
          }
        />
      }
      getPagination={(skip, size, search) =>
        setBalanceFilters({ ...balanceFilters, skip, size, search })
      }
    />
  )

  const transferHeaders = [
    {
      label: 'Código',
      value: ['code'],
      type: 'text',
      className: 'mini',
    },
    {
      label: 'Fecha de creación',
      value: ['createdAt'],
      type: 'date',
      className: 'mini',
    },
    {
      label: 'Estado',
      custom: item => cashTransferStatusEnum[item.statusId]?.name || 'Desconocido',
      type: 'text',
      className: 'short',
    },
    {
      label: 'Fecha de aprobación',
      value: ['confirmedAt'],
      type: 'date',
      className: 'mini',
    },
    {
      label: key === 1 ? 'Transferido a' : 'Transferido por',
      custom: item =>
        users.find(user => user.id === (key === 1 ? item.targetUserId : item.createdBy))
          ?.name || 'Desconocido',
      type: 'text',
      className: 'short',
    },
    {
      label: 'Monto',
      value: ['amount'],
      type: 'currency',
      className: 'mini',
    },
    {
      label: 'Concepto',
      value: ['concept'],
      type: 'text',
      className: 'short',
    },
    {
      config: true,
      label: '',
      className: 'mini center',
      custom: item => (
        <Dropdown
          disabled={item.statusId !== CashTransferStatus.PENDING_APPROVAL}
          items={[
            {
              show: key === 1,
              title: 'Anular',
              icon: faTimes,
              action: () => dispatch(cancelCashTransfer(item.id)),
            },
            {
              show: key === 2,
              title: 'Aprobar',
              icon: faCheck,
              action: () => dispatch(approveCashTransfer(item.id)),
            },
            {
              title: 'Rechazar',
              show: key === 2,
              icon: faTimes,
              action: () => dispatch(cancelCashTransfer(item.id)),
            },
          ]}
        />
      ),
    },
  ]

  const sentTransfersTableElement = (
    <TableV2
      // @ts-expect-error
      loading={loadingSentCashTransfers}
      headers={transferHeaders}
      storageKey={'sent-cash-transfers'}
      items={sentCashTransfers}
      total={totalSentCashTransfers}
      handleChange={search =>
        setTimeout(() => {
          setSentTransferFilters({ ...sentTransferFilters, search })
        }, 1000)
      }
      customFilter={
        <>
          <SelectedDates
            withOptionNull
            onDateChange={(start, end) =>
              setSentTransferFilters({ ...sentTransferFilters, start, end })
            }
          />
          <Row>
            <Col xs={12} md={4}>
              <Select
                label={'Por estado'}
                value={statusOptions.find(
                  status => status.value === sentTransferFilters.statusId,
                )}
                info={'Filtra las órdenes por el estado'}
                options={statusOptions}
                onChange={status =>
                  setSentTransferFilters({
                    ...sentTransferFilters,
                    statusId: status.value,
                  })
                }
              />
            </Col>
          </Row>
        </>
      }
      getPagination={(skip, size, search) =>
        setSentTransferFilters({ ...sentTransferFilters, skip, size, search })
      }
    />
  )

  const receivedTransfersTableElement = (
    <TableV2
      // @ts-expect-error
      loading={loadingReceivedCashTransfers}
      headers={transferHeaders}
      storageKey={'received-cash-transfers'}
      items={receivedCashTransfers}
      total={totalReceivedCashTransfers}
      handleChange={search =>
        setTimeout(() => {
          setReceivedTransferFilters({ ...receivedTransferFilters, search })
        }, 1000)
      }
      customFilter={
        <>
          <SelectedDates
            withOptionNull
            onDateChange={(start, end) =>
              setReceivedTransferFilters({ ...receivedTransferFilters, start, end })
            }
          />
          <Row>
            <Col xs={12} md={4}>
              <Select
                label={'Por estado'}
                value={statusOptions.find(
                  status => status.value === receivedTransferFilters.statusId,
                )}
                info={'Filtra las órdenes por el estado'}
                options={statusOptions}
                onChange={status =>
                  setReceivedTransferFilters({
                    ...receivedTransferFilters,
                    statusId: status.value,
                  })
                }
              />
            </Col>
          </Row>
        </>
      }
      getPagination={(skip, size, search) =>
        setReceivedTransferFilters({ ...receivedTransferFilters, skip, size, search })
      }
    />
  )

  return (
    <div>
      <Row>
        <Col xs={12}>
          <Card>
            <div className={'justify-center column'}>
              <span>{byUser ? 'Efectivo circulante' : 'Disponible'}</span>
              <h4 className={getAmountColor(available)}>{toMoney(available)}</h4>
            </div>
            {balance.available > 0 && !byUser && !hideDisbursement && (
              <div style={{ position: 'absolute', top: 15, right: 15 }}>
                <Row className={'container-buttons'}>
                  <Icon
                    onClick={() => {
                      dispatch(getAccounts())
                      setDisbursement({
                        show: true,
                        amount: 0,
                        account: { value: null, label: 'Sin seleccionar' },
                      })
                    }}
                    tooltip={'Solicitar desembolso'}
                    icon={faMoneyBillWave}
                  />
                </Row>
              </div>
            )}
          </Card>
        </Col>
        <Col xs={12}>
          <CustomTabs
            setKey={newKey => {
              setKey(Number(newKey))
            }}
            items={[
              {
                title: byUser ? 'Efectivo circulante' : 'Disponible',
                component: balanceTableElement,
              },
              {
                show: byUser,
                title: `Transferencias de efectivo solicitadas${
                  totalSentCashTransfers ? ` (${totalSentCashTransfers})` : ''
                }`,
                component: sentTransfersTableElement,
              },
              {
                show: byUser,
                title: `Transferencias de efectivo recibidas${
                  totalReceivedCashTransfers ? ` (${totalReceivedCashTransfers})` : ''
                }`,
                component: receivedTransfersTableElement,
              },
            ]}
          />
        </Col>
      </Row>

      <CustomReference
        show={reference.show}
        documentModule={2}
        documentType={reference.type}
        documentId={reference.documentId}
        onHide={() => setReference({ ...reference, show: false })}
      />

      <Modal
        show={disbursement.show}
        centered
        onHide={() => setDisbursement({ ...disbursement, show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Solicitud de desembolso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12} md={12} sm={12}>
              <FormText
                disabled={loadingCreateDisbursement}
                prependMoneySymbol
                label={'Cantidad'}
                value={disbursement.amount}
                type={'number'}
                onChange={({ target }) => {
                  let { value } = target
                  if (!value || value < 0) value = 0

                  if (value > balance.available) value = balance.available

                  setDisbursement({ ...disbursement, amount: value })
                }}
              />
            </Col>
            <Col xl={12} md={12} sm={12}>
              <Select
                disabled={loadingCreateDisbursement}
                label={'Cuenta bancaria'}
                options={Object.assign(
                  [],
                  bankAccounts.map(a => ({
                    ...a,
                    value: a.id,
                    label: `${a.accountNumber} - ${a.name}`,
                  })),
                )}
                value={disbursement.account}
                onChange={account => setDisbursement({ ...disbursement, account })}
              />
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              disabled={loadingCreateDisbursement}
              icon={faWindowClose}
              right
              variant={'secondary'}
              onClick={() => setDisbursement({ ...disbursement, show: false })}>
              Cerrar
            </Button>
            <Button
              loading={loadingCreateDisbursement}
              disabled={
                !disbursement.amount ||
                disbursement.amount <= 0 ||
                !disbursement.account.value
              }
              icon={faCheckDouble}
              right
              variant={'success'}
              onClick={() => {
                dispatch(
                  createDisbursement(disbursement.amount, {
                    bank: disbursement.account.value,
                  }),
                )
              }}>
              Solicitar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default BalanceTable
