export const selectSubModules = state => state.modules.subModulesApp
export const selectRolesBySubModules = state => state.modules.rolesApp
export const selectUsers = state => {
  return state.modules.users.map(u => ({
    ...u,
    value: u.id,
    label: `${u.name}`,
    balance: 0,
  }))
}
export const selectUsersName = state => {
  return state.modules.users.map(u => ({
    ...u,
    value: u.id,
    label: `${u.name}`,
  }))
}
export const selectUsersRoles = state => state.modules.users_roles
export const selectUserSelect = state =>
  [{ value: 0, label: 'Todos los Meseros' }].concat(
    state.modules.users ? selectUsersToSelect(state) : [],
  )
export const selectUsersToSelect = state =>
  state.modules.users.map(item => ({
    ...item,
    value: item.id,
    label: item.name,
  }))
export const selectRoles = state => state.modules.roles
export const selectRolesByUser = state => state.modules.rolesByUser
export const selectDataPermissionsSuccess = state => state.modules.dataPermissions
export const selectModuleId = state => state.modules.module
export const selectCurrentUserRoles = state => state.modules.currentUserRoles

export const selectUsersByCompanyAndRoleForSelect = state =>
  state.modules.usersByCompanyAndRole.map(item => ({
    value: item.id,
    label: item.email + ' - ' + item.name,
  }))
export const selectUsersByCompanyAndRoleWithAllForSelect = state =>
  [{ value: 0, label: 'Todos los Usuarios' }].concat(
    state.modules.usersByCompanyAndRole === null
      ? []
      : state.modules.usersByCompanyAndRole.map(item => ({
          value: item.id,
          label: item.email + ' - ' + item.name,
        })),
  )

export const selectUsersByCompanyForSelect = state =>
  state.modules.usersByCompany.map(item => ({
    value: item.id,
    label: item.email + ' - ' + item.name,
    name: item.name,
    id: item.id,
  }))
export const selectUsersByCompanyWithAllForSelect = state =>
  [{ value: 0, label: 'Todos los Usuarios' }].concat(
    state.modules.usersByCompany === null
      ? []
      : state.modules.usersByCompany.map(item => ({
          value: item.id,
          label: item.email + ' - ' + item.name,
        })),
  )

export const selectCurrentCompanyUsersForSelect = state =>
  state.modules.currentCompanyUsers.map(item => ({
    value: item.id,
    label: item.email + ' - ' + item.name,
  }))
export const selectCurrentCompanyUsersWithAllForSelect = state =>
  [{ value: 0, label: 'Todos los Usuarios' }].concat(
    state.modules.currentCompanyUsers === null
      ? []
      : state.modules.currentCompanyUsers.map(item => ({
          value: item.id,
          label: item.email + ' - ' + item.name,
        })),
  )
export const selectUsersByRoleForSelect = state =>
  state.modules.usersByRole.map(item => ({
    value: item.id,
    label: item.name,
  }))

export const withPermission = (state, roleId) => {
  const allRoles = []
  state.modules.currentUserRoles.forEach(r => {
    r.roles.forEach(role => {
      allRoles.push(role)
    })
  })
  return allRoles.find(p => p.id === roleId)?.assigned || false
}

export const getDirectAction = state => {
  const { currentUserRoles } = state.modules
  const actions = []
  currentUserRoles.forEach(r => {
    r.roles.forEach(role => {
      if (role.assigned && role.icon && role.route) actions.push(role)
    })
  })
  return actions
}

export const getPermission = (state, permission) => {
  const { currentUserRoles } = state.modules
  const allPermission = []
  currentUserRoles.forEach(r => {
    r.roles.forEach(role => {
      allPermission.push(role)
    })
  })
  const bool = allPermission.find(p => p.id === permission)
  return bool ? bool.assigned : false
}

export const isAllowed = (state, permissions) => {
  let allowed = false
  permissions.forEach(permission => {
    if (!allowed) allowed = getPermission(state, permission)
  })
  return allowed
}

export const newGetPermission = (state, permission) => {
  const { currentUserRoles } = state.modules

  const allPermission = []
  currentUserRoles.forEach(r => {
    r.roles.forEach(role => {
      allPermission.push(role)
    })
  })
  const bool = allPermission.find(p => p.id === permission)
  return bool ? bool.assigned : false
}

export const loadingToggle = state => state.modules.loadingToggle
export const selectorGetAdminModules = state => state.modules.adminModules
export const selectorToogleAdminModule = state => state.modules.editedModule
export const selectorGetUsersSubmodule = state =>
  state.modules.usersSubmodule.map(item => ({
    value: item.id,
    label: item.name,
  }))
export const selectorGroup = state => state.modules.group
export const selectorGroups = state => state.modules.groups
export const selectModuleGroups = state => state.modules.moduleGroups
export const rolesSelect = state => state.modules.rolesSelect
export const selectModules = state =>
  state.modules.modules.map(m => ({ ...m, value: m.id, label: m.name }))
