import { actionTypes } from '../actions/requests.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  requests: [],
}

const RequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_COTIZAP_REQUESTS):
      return {
        ...state,
        requests: extract(action.requests, {}),
      }
    case successState(actionTypes.UPDATE_COTIZAP_REQUEST):
      return {
        ...state,
        request: extract(action.request, {}),
      }
    default:
      return state ? state : initialState
  }
}

export default RequestsReducer
