export const selectCompanyMentorPaginated = state => state.mentor.companyMentorPaginated
export const selectSingleCompanyMentor = state => state.mentor.singleCompany
export const selectCompanyCreatedMentor = state => state.mentor.createResponse
export const selectCompanyAddons = state => state.mentor.companyAddons
export const selectCompanyAddonEntity = state => state.mentor.companyAddonsEntityResponse
export const selectCompanyConfigurationData = state => state.mentor.companyFields
export const selectPlanOrders = state => state.mentor.planOrders
export const selectPlanOrderDetails = state => state.mentor.planOrderDetails
export const selectClientCompany = state => state.mentor.client
export const selectCompanyActivities = state => state.mentor.companyActivities
