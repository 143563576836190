export enum identificationTypesEnum {
  NIT_GT = 1,
  CUI = 2,
  NIT_SV = 3,
  NRC = 4,
  DUI = 5,
  ID_EXTRANJERO = 6,
  RTN = 7,
  IDE = 8,
}

export const identifications = {
  [identificationTypesEnum.NIT_GT]: 'NIT',
  [identificationTypesEnum.CUI]: 'DPI',
  [identificationTypesEnum.NIT_SV]: 'NIT',
  [identificationTypesEnum.NRC]: 'NRC',
  [identificationTypesEnum.DUI]: 'DUI',
  [identificationTypesEnum.ID_EXTRANJERO]: 'ID EXTRANJERO',
  [identificationTypesEnum.RTN]: 'RTN',
  [identificationTypesEnum.IDE]: 'Identificación Tributaria',
}
