import { types } from '../actions/parcel.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  types: [],
  parcels: [],
  parcel: {},
}

const ParcelReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(types.GET_TYPES):
      return { ...state, types: extract(action.types, []) }
    case types.SET_INFO:
      return { ...state, parcel: action.parcel }
    case successState(types.GET_SINGLE_PARCEL):
      return { ...state, parcel: extract(action.parcel, {}) }
    case successState(types.GET_ALL_PARCEL_BY_COMPANY):
      return { ...state, parcels: extract(action.parcels, []) }
    default:
      return state ? state : initialState
  }
}

export default ParcelReducer
