import './Plans.scss'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Col, ProgressBar, Row } from 'react-bootstrap'
import ModalCreateCard from '../../Documents/Account/ModalCreateCard'
import {
  actions as actionP,
  getCompanyActivePlan,
  getPlans,
  updateCompanyPlan,
  updatePlan,
  updatePlanRequest,
} from 'src/actions/plans.actions'
import {
  selectActivePlans,
  selectCompanyActivePlans,
  selectPlans,
} from 'src/selectors/plans.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import PlanItem from './PlanItem'
import { Button, Card, FormText, Icon, InfoNit, Select, Switch } from 'src/components'
import {
  handlerError,
  handlerInfo,
  handlerSuccess,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'
import Alert from 'sweetalert-react'
import { faAddressBook, faIdCard, faSave } from '@fortawesome/free-solid-svg-icons'
import { getGroups } from 'src/actions/modules.actions'

import { actions as typeA, getCards } from 'src/actions/account.actions'
import {
  selectOtherCompany,
  selectSetCurrentCompany,
} from 'src/selectors/company.selector'
import { history } from 'src/App'
import cardplace from 'src/assets/v2/card-placeholder.png'
import { createCard } from 'src/actions/account.actions'
import { actionTypes as typesC, getInfoNIT } from 'src/actions/clients.actions'

import CreditCard from 'src/components/CreditCard/CreditCard'
import useDebounce from 'src/hooks/useDebounce'
import { valNit } from 'src/selectors/utilities.selector'
import { selectNITinfo } from 'src/selectors/clients.selector'
import { ReCaptcha } from 'src/components/ReCaptcha/ReCaptcha'
import { formatNumberWithCommas } from 'src/utils/formatters'
import {
  getCompany,
  getCurrentCompany,
  actionTypes as companyActions,
} from 'src/actions/company.actions'
import { selectAllRegionsForSelect } from 'src/selectors/address.selector'
import { getAllRegions } from 'src/actions/address.actions'

const Option = props => {
  const {
    className,
    cx,
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps,
  } = props
  const card = props.data

  return (
    <div
      ref={innerRef}
      css={getStyles('option', props)}
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected,
        },
        className,
      )}
      {...innerProps}>
      <CreditCard {...card} style={{ backgroundColor: 'transparent' }} noDelete />
    </div>
  )
}

const PlanCheckout = ({ match }) => {
  const planId = Number(match.params?.planId)
  const companyId = Number(match.params?.companyId)

  const dispatch = useDispatch()

  const company = useSelector(companyId ? selectOtherCompany : selectSetCurrentCompany)
  const loadingCompany = useSelector(state =>
    loadingSelector([typesC.CURRENT_COMPANY, companyActions.GET_COMPANY])(state),
  )

  const plans = useSelector(selectPlans)
  const plan = useSelector(companyId ? selectCompanyActivePlans : selectActivePlans)
  const newPlan = plans.find(p => p.id === planId) || {}
  const loadingPlans = useSelector(state => loadingSelector([actionP.GET_PLANS])(state))

  const totalUsers = [...(plan.userList || []), ...(plan.extraUsers || [])].length
  const extraUsers = Math.max(totalUsers - newPlan.users, 0)

  const cards = useSelector(state => state.account.cards).map(c => ({
    ...c,
    label: `${c.alias ? `${c.alias} - ` : ''}${c.number}`,
    value: c.id,
  }))
  const loadingCards = useSelector(state => loadingSelector([typeA.GET_CARDS])(state))

  const localities = useSelector(selectAllRegionsForSelect)

  const loadingNit = useSelector(state => loadingSelector([typesC.GET_NIT_INFO])(state))
  const hasErrorNit = useSelector(state =>
    hasErrorsSelector([typesC.GET_NIT_INFO])(state),
  )
  const infoNIT = useSelector(selectNITinfo)

  const changeLoading = useSelector(state =>
    loadingSelector([actionP.UPDATE_PLAN])(state),
  )
  const changeHasError = useSelector(state =>
    hasErrorsSelector([actionP.UPDATE_PLAN])(state),
  )
  const changeError = useSelector(state =>
    singleErrorSelector([actionP.UPDATE_PLAN])(state),
  )

  const loadingCC = useSelector(state => loadingSelector([typeA.CREATE_CARD])(state))
  const hasErrorCC = useSelector(state => hasErrorsSelector([typeA.CREATE_CARD])(state))
  const errorCC = useSelector(state => singleErrorSelector([typeA.CREATE_CARD])(state))

  const loadingCD = useSelector(state => loadingSelector([typeA.DELETE_CARD])(state))
  const hasErrorCD = useSelector(state => hasErrorsSelector([typeA.DELETE_CARD])(state))
  const errorCD = useSelector(state => singleErrorSelector([typeA.DELETE_CARD])(state))

  const [action, setAction] = useState({
    change: false,
    card: false,
    company: false,
  })
  const [modalCard, setModalCard] = useState({ show: false })
  const [card, setCard] = useState(cards.find(c => c.favorite) || {})
  const [name, setName] = useState(company.name)
  const [amount, setAmount] = useState(null)
  const [isTest, setIsTest] = useState(false)
  const [document, setDocument] = useState(null)
  const [nit, setNit] = useState(company.nit)
  const [address, setAddress] = useState(company.address)
  const [postalCode, setPostalCode] = useState('')
  const [locality, setLocality] = useState({})
  const [valueCaptcha, setValueCaptcha] = useState(null)

  const [alert, setAlert] = useState({ show: false })

  useEffect(() => {
    if (!company.isTest && !companyId) history.replace('/distribucion/planes')
    if (companyId) {
      dispatch(getCompanyActivePlan(companyId))
      dispatch(getCompany(companyId))
    } else {
      dispatch(getCards())
    }
    if (plans.length === 0) dispatch(getPlans())
    if (!localities.length) dispatch(getAllRegions())
  }, [])

  useEffect(() => {
    if (loadingCompany) setAction({ ...action, company: true })
    else if (action.company) {
      setAction({ ...action, company: false })
      setName(company.name)
      setNit(company.nit)
      setAddress(company.address)
    }
  }, [loadingCompany])

  useEffect(() => {
    if (loadingCards) setAction({ ...action, card: true })
    else if (action.card) {
      setAction({ ...action, card: false })
      setCard(cards.find(c => c.favorite) || {})
    }
  }, [loadingCards])

  useEffect(() => {
    if (changeLoading) setAction({ ...action, change: true })
    else if (action.change) {
      setAction({ ...action, change: false })
      if (changeHasError) {
        setAlert({
          ...handlerError(changeError.message || 'No se ha podido cambiar de plan'),
          onConfirm: () => setAlert({ show: false }),
        })
      } else {
        setAlert({
          ...handlerSuccess('Cambio de plan exitoso'),
          onConfirm: () => {
            dispatch(getGroups(1000))
            dispatch(getCurrentCompany())
            setAlert({ show: false })
            history.push(companyId ? '/admin/mentores' : '/perfil')
          },
        })
      }
    }
  }, [changeLoading])

  useEffect(() => {
    if (loadingCC) setAction({ ...action, cardCreate: true })
    else if (action.cardCreate) {
      setAction({ ...action, cardCreate: false })
      if (hasErrorCC)
        setAlert({
          ...handlerError(errorCC.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Tarjeta de crédito registrada satisfactoriamente'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            dispatch(getCards())
          },
        })
    }
  }, [loadingCC])

  useEffect(() => {
    if (loadingCD) setAction({ ...action, cardDelete: true })
    else if (action.cardDelete) {
      setAction({ ...action, cardDelete: false })
      if (hasErrorCD)
        setAlert({
          ...handlerError(errorCD.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Tarjeta de crédito eliminada satisfactoriamente'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            dispatch(getCards())
          },
        })
    }
  }, [loadingCD])

  useEffect(() => {
    if (loadingNit) setAction({ ...action, search: true })
    else if (action.search) {
      if (hasErrorNit) {
        setAlert({
          ...handlerError('No se pudo obtener la información con el NIT indicado'),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      } else setName(infoNIT.clientName)
    }
  }, [loadingNit])

  const handleNitSearch = useDebounce(nit => {
    if (!nit || nit === 'CF' || !valNit(nit)) return
    dispatch(getInfoNIT(nit))
  }, 1000)

  const changeNIT = ({ target }) => {
    const { value } = target
    setNit(value)
    if (value.toUpperCase() !== 'CF' && valNit(value)) handleNitSearch(value)
  }

  return (
    <div className="checkout-base">
      <h1>Adquirir Plan</h1>
      {loadingPlans ? (
        <Row>
          <Col>
            <div className={'pb-custom'}>
              <ProgressBar
                label="Cargando"
                animated
                now={100}
                style={{ marginBottom: 10 }}
              />
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <Row style={{ marginBottom: 20 }}>
            <Col xs={12} md={12} lg={12} xl={6} style={{ padding: 10 }}>
              <PlanItem
                item={newPlan}
                isPreview
                userSpaces={company.features?.userSpaces}
                style={{ marginBottom: 0 }}
              />
            </Col>
            <Col xs={12} md={12} lg={12} xl={6} style={{ padding: 10 }}>
              <Card
                style={{
                  height: '100%',
                }}>
                {companyId ? (
                  <>
                    <h2>Modo demo</h2>
                    <Row style={{ alignItems: 'flex-end', marginBottom: 30 }}>
                      <Col xs={12} style={{ margin: '15px 0 20px' }}>
                        <Switch
                          // topLabel
                          label={'Activar plan en modo demo'}
                          checked={isTest}
                          onChange={({ target }) => {
                            const { checked } = target
                            setIsTest(checked)
                          }}
                          info={
                            'Al activar esta opción se activará el plan en modo demo.'
                          }
                        />
                      </Col>
                    </Row>
                    <h2>Pago personalizado</h2>
                    <Row style={{ alignItems: 'flex-end', marginBottom: 30 }}>
                      <Col
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        style={{ marginBottom: 20 }}>
                        <FormText
                          placeholder={'Ingrese el pago hecho por el cliente (Opcional)'}
                          type={'number'}
                          name={'amount'}
                          value={amount}
                          onChange={ev => setAmount(Number(ev.target.value))}
                        />
                      </Col>
                      <Col
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        style={{ marginBottom: 20 }}>
                        <FormText
                          placeholder={'Documento de referencia (Opcional)'}
                          name={'document'}
                          value={document}
                          onChange={ev => setDocument(ev.target.value)}
                        />
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <h2>Método de pago</h2>
                    <Row style={{ alignItems: 'center', marginBottom: 30 }}>
                      <Col xl={8} lg={8} md={6} sm={6} xs={12}>
                        <Select
                          withoutLabel
                          disabled={loadingCards}
                          options={cards}
                          renderOption={Option}
                          value={card}
                          onChange={value => setCard(value)}
                        />
                      </Col>
                      <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                        <Button
                          color={'secondary'}
                          style={{ margin: 0, width: '100%' }}
                          img={cardplace}
                          onClick={() => {
                            setModalCard({ show: true })
                          }}>
                          Registrar Tarjeta
                        </Button>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormText
                          label={'Código postal'}
                          placeholder={'Ingrese el código postal de la tarjeta'}
                          type={'number'}
                          name={'postalCode'}
                          value={postalCode}
                          onChange={ev => setPostalCode(ev.target.value)}
                          max={10}
                        />
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Select
                          label={'Departamento'}
                          placeholder={'Ingrese el departamento de la tarjeta'}
                          options={localities}
                          value={locality}
                          onChange={value => setLocality(value)}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                <h2>Facturación</h2>
                <Row style={{ alignItems: 'flex-end', marginBottom: 40 }}>
                  <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                    <InfoNit
                      type={'text'}
                      name={'nit'}
                      prepend={<Icon icon={faIdCard} spin={loadingNit} />}
                      nit={nit}
                      disabled={loadingNit}
                      required={false}
                      noValidate
                      onChange={ev => {
                        changeNIT(ev)
                      }}
                    />
                  </Col>
                  <Col xl={8} lg={8} md={6} sm={6} xs={12}>
                    <FormText
                      label={'Nombre'}
                      placeholder={'Ingrese el nombre para la factura'}
                      type={'text'}
                      name={'name'}
                      value={name}
                      onChange={ev => setName(ev.target.value)}
                    />
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <FormText
                      label={'Dirección'}
                      placeholder={'Ingrese la dirección para la factura'}
                      type={'text'}
                      name={'address'}
                      value={address}
                      onChange={ev => setAddress(ev.target.value)}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row
            className={'container-buttons'}
            style={{
              alignItems: 'center',
              gap: 20,
            }}>
            {!companyId && (
              <>
                <ReCaptcha onChange={value => setValueCaptcha(value)} />
                <Button
                  color={'secondary'}
                  disabled={valueCaptcha !== null}
                  icon={faAddressBook}
                  loading={changeLoading}
                  onClick={() => {
                    dispatch(updatePlanRequest(newPlan.id, { contact: true }))
                    setAlert({
                      ...handlerInfo(
                        '',
                        'Hemos mandado tu solicitud a un asesor. Pronto te daremos respuesta.',
                      ),
                      confirmButtonText: 'Cerrar',
                      onConfirm: () => {
                        setAlert({ show: false })
                      },
                    })
                  }}>
                  Contactar Mentor
                </Button>
              </>
            )}
            <Button
              color={'primary'}
              disabled={
                !companyId &&
                (!card.id || !postalCode || !locality.value || valueCaptcha === null)
              }
              icon={faSave}
              loading={changeLoading}
              onClick={() => {
                setAlert({
                  ...handlerInfo(
                    companyId
                      ? `¿Está seguro que desea activar este plan para la empresa ${company.name}?`
                      : '¿Está seguro que desea adquirir este plan?',
                    companyId
                      ? 'Las funcionalidades del plan se estarán activando de manera inmediata.'
                      : `Se cobrarán Q.${formatNumberWithCommas(
                          newPlan.price,
                        )} por activar el plan ${newPlan.name} ${
                          extraUsers > 0
                            ? `y Q.${formatNumberWithCommas(
                                extraUsers * newPlan.priceUsers,
                              )} por ${extraUsers} espacios de usuario (Total Q.${formatNumberWithCommas(
                                newPlan.price + extraUsers * newPlan.priceUsers,
                              )}) `
                            : ''
                        }a tu método de pago y las funcionalidades del plan se estarán activando de manera inmediata.`,
                  ),
                  showCancelButton: true,
                  confirmButtonText: 'Activar',
                  cancelButtonText: 'Cancelar',
                  onConfirm: () => {
                    setAlert({
                      ...handlerInfo(
                        companyId ? 'Cambiando el plan' : 'Realizando el cobro',
                        'Espera un momento',
                      ),
                      showCancelButton: false,
                      showConfirmButton: false,
                    })

                    if (companyId)
                      dispatch(
                        updateCompanyPlan(newPlan.id, companyId, {
                          amount,
                          document,
                          test: isTest,
                          name,
                          nit,
                          address,
                        }),
                      )
                    else
                      dispatch(
                        updatePlan(newPlan.id, {
                          name,
                          nit,
                          address,
                          postalCode,
                          locality: locality.value,
                          cardId: card.id,
                        }),
                      )
                  },
                  onCancel: () => {
                    setAlert({ show: false })
                  },
                })
              }}>
              Adquirir
            </Button>
          </Row>
        </>
      )}

      <ModalCreateCard
        create={modalCard.show}
        onHide={() => setModalCard({ show: false })}
        onCreate={({
          cardNumber,
          cardName,
          ccv,
          expirationMM,
          expirationAA,
          alias,
          logo,
        }) => {
          const request = {
            name: cardName,
            number: cardNumber,
            cvv: ccv,
            expirationDate: `${expirationMM}/${expirationAA}`,
            alias,
            favorite: true,
            logo,
          }
          dispatch(createCard(request))
        }}
      />

      <Alert {...alert} />
    </div>
  )
}

export default PlanCheckout
