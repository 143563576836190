import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectAllVersions,
  selectAllVersionsToSelect,
  selectLastVersion,
} from 'src/selectors/versions.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  actionTypes as actionTypesVersions,
  getAllVersions,
  getLastVersion,
  getVersionDetails,
} from 'src/actions/versions.actions'
import {
  actionTypes as actionTypesVideos,
  getAllVideos,
  getVideos,
} from 'src/actions/videos.actions'
import {
  getAllCategorizations,
  getAllPublicCategorizations,
} from 'src/actions/categorization.actions'
import ReactQuill from 'react-quill'
import { Col, Row, ProgressBar, Modal } from 'react-bootstrap'
import {
  Button,
  Card,
  Card as CustomCard,
  CustomFilter,
  FAB,
  FormText,
  Icon,
  Select,
  Table,
  Title,
} from 'src/components'
import { selectCurrentModule } from 'src/selectors/user.selector'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'

import './Information.scss'
import { history } from '../../../App'
import PaginatedVideo from 'src/components/Pagination/PaginatedVideo'
import Folder, { categoryType } from 'src/components/folders/Folder'
import PermissionGroup from '../../Permissions/UpdatePermissions/PermissionGroup'
import {
  selectAllCategorizations,
  selectItemCategorizations,
} from 'src/selectors/categorizations.selector'
import { rolesSelect } from 'src/selectors/modules.selector'
import ButtonIcon from 'src/components/buttons/IconButton'
import { faEdit, faEye, faTimes, faVideo } from '@fortawesome/free-solid-svg-icons'
import IconButton from 'src/components/buttons/IconButton'
import { selectAllVideos, selectTotalVideos } from 'src/selectors/videos.selector'
import ModalVideo from './ModalVideo'
import { useLocation } from 'react-router-dom'
import { Media } from 'react-breakpoints'
import useDebounce from '../../../hooks/useDebounce'

const Information = props => {
  const dispatch = useDispatch()

  const module = useSelector(selectCurrentModule)

  const categorization = useSelector(selectAllCategorizations)
  const itemCategorization = useSelector(selectItemCategorizations)
  const versions = useSelector(selectAllVersions)
  const versionsSelect = useSelector(selectAllVersionsToSelect)
  const loading = useSelector(state =>
    loadingSelector([actionTypesVersions.GET_VERSIONS, actionTypesVideos.GET_ALL_VIDEOS])(
      state,
    ),
  )
  const roles = useSelector(rolesSelect)
  const videos = useSelector(selectAllVideos)
  const total = useSelector(selectTotalVideos)

  const [action, setAction] = useState({ loading: false })
  const [item, setItem] = useState({})
  const [search, setSearch] = useState('')
  const [searchVersion, setSearchVersion] = useState('')
  const [modal, setModal] = useState({ show: false, video: [] })
  const [filters, setFilters] = useState({
    showC: false,
    categories: [],
    categories2: [],
    showR: false,
    roles: [],
    roles2: [],
    detail: false,
  })
  const [modalVersions, setModalVersions] = useState(false)
  const [create, setCreate] = useState({
    show: false,
    videos: [],
    categories: [],
  })
  const [data, setData] = useState({ title: '', description: '', id: 0 })
  const [filterVideos, setFilterVideos] = useState([])
  const [loadingV, setLoadingV] = useState(false)

  const tablet = window.innerWidth > 768 && window.innerWidth <= 1366
  const admin = useState(useLocation().pathname.includes('/admin'))
  const [isVideo] = useState(window.location.href.includes('/videos'))
  const [isDetail, setDetail] = useState(false)
  const [version, setVersion] = useState({})
  const [lastDet, setLast] = useState({})
  const [viewingDetailVideos, setViewingDetailVideos] = useState(false)

  const step = 6

  useEffect(() => {
    dispatch(getAllVersions())
    // dispatch(getAllVideos(1))
    dispatch(getVideos(1, 0, step))
    dispatch(getAllPublicCategorizations(19))
    if (props.match.params.version) {
      dispatch(getVersionDetails(Number(props.match.params.version)))
    }
    if (props.match.params.id) {
      setDetail(true)
    }
  }, [])

  useEffect(() => {
    if (!isDetail && !versions) return
    const newItem = versionsSelect.find(v => v.id === item.id)
    if (newItem) setItem(newItem)

    setVersion(versions.find(s => s.id === Number(props.match.params.version)))
    setAction({ ...action, loading: false })
  }, [isDetail, versions])

  useEffect(() => {
    if (!version || Object.keys(version).length === 0) return
    if (
      version.detail &&
      version.detail.filter(s => s.id === Number(props.match.params.id)).length === 0
    ) {
      setDetail(false)
    } else {
      setLast(
        version.detail &&
          version.detail.filter(s => s.id === Number(props.match.params.id))[0],
      )
    }
  }, [version])

  useEffect(() => {
    if (!lastDet) return
  }, [lastDet])

  useEffect(() => {
    if (!loading) {
      if (isVideo) {
        if (itemCategorization && itemCategorization.length > 0) {
          setItem(itemCategorization[0])
        }
      } else if (versions && versions.length > 0) {
        setItem(versionsSelect[0])
        dispatch(getVersionDetails(versionsSelect[0].id))
      }
    }
  }, [loading])

  useEffect(() => {
    setLoadingV(true)
    setTimeout(() => {
      setFilterVideos([])
      setFilterVideos([
        ...videos.filter(s => s.categories.filter(f => f.id === item.id).length !== 0),
      ])
      setLoadingV(false)
    }, 100)
  }, [item])

  const stopOtherVideos = video => {
    const videos = []
    filterVideos.map(v => {
      v.urls.map(url => {
        var element = document.getElementById(`vid-${url.id}`)
        if (element) videos.push(element)
      })
    })
    for (let i = 0; i < videos.length; i++) {
      if (videos[i].id !== `vid-${video.id}`) {
        videos[i].pause()
      }
    }
  }

  const filter = item => {
    if (search && search !== '') {
      const exp = new RegExp(search.toUpperCase(), 'g')
      return Object.keys(item).some(p =>
        `${JSON.stringify(item[p])}`.toUpperCase().match(exp),
      )
    }
    if (filters.categories.length === 0 && filters.roles.length === 0) return true
    let result = false
    item.detail.map(d => {
      d.categorizations.map(c => {
        let index = filters.categories.findIndex(f => f.id === c.id)
        if (index !== -1) result = true
      })

      d.roles.map(c => {
        let index = filters.roles.findIndex(f => f.name === c)
        if (index !== -1) result = true
      })
    })
    return result
  }

  const filterVersion = item => {
    if (searchVersion && searchVersion !== '') {
      const exp = new RegExp(searchVersion.toUpperCase(), 'g')
      return Object.keys(item).some(p =>
        `${JSON.stringify(item[p])}`.toUpperCase().match(exp),
      )
    }
    if (filters.categories2.length === 0 && filters.roles2.length === 0) return true
    let result = false
    item.categorizations.map(c => {
      let index = filters.categories2.findIndex(f => f.id === c.id)
      if (index !== -1) result = true
    })

    item.roles.map(c => {
      let index = filters.roles2.findIndex(f => f.name === c)
      if (index !== -1) result = true
    })
    return result
  }

  const renderButton = d => {
    return d.videos && d.videos.length > 0 ? (
      <div
        onClick={() => {
          setModal({ show: true, video: d.videos, noEdit: true })
          setViewingDetailVideos(true)
        }}>
        Ver videos
      </div>
    ) : (
      <div />
    )
  }

  const customFilter = version => {
    return !isVideo ? (
      <CustomCard>
        <Row style={{ marginTop: 10 }}>
          {filters[version ? 'categories2' : 'categories'].map(p => (
            <div className={'user-tag warehouse-t ml-2'}>
              <label className={'label-user-tag'}>{p.name}</label>
              <ButtonIcon
                className={'delete-user-tag d-warehouse-t'}
                icon={faTimes}
                tooltip={'Quitar'}
                color={'white'}
                onClick={() => {
                  filters[version ? 'categories2' : 'categories'] = filters[
                    version ? 'categories2' : 'categories'
                  ].filter(d => d.id !== p.id)
                  setFilters({ ...filters })
                }}
              />
            </div>
          ))}
          <Button
            style={{ marginLeft: 10 }}
            color={'primary'}
            onClick={() => setFilters({ ...filters, showC: true, detail: version })}>
            Categorías
          </Button>
        </Row>
        <hr />
        <Row>
          {filters[version ? 'roles2' : 'roles'].map(p => (
            <div className={'user-tag user-t ml-2'}>
              <label className={'label-user-tag'}>{p.name}</label>
              <ButtonIcon
                className={'delete-user-tag d-user-t'}
                icon={faTimes}
                tooltip={'Quitar'}
                color={'white'}
                onClick={() => {
                  filters[version ? 'roles2' : 'roles'] = filters[
                    version ? 'roles2' : 'roles'
                  ].filter(d => d.id !== p.id)
                  setFilters({ ...filters })
                }}
              />
            </div>
          ))}
          <Button
            style={{ marginLeft: 10 }}
            color={'primary'}
            onClick={() => setFilters({ ...filters, showR: true, detail: version })}>
            Permisos
          </Button>
        </Row>
      </CustomCard>
    ) : (
      <div></div>
    )
  }

  const renderRowVersions = (id, item, index) => {
    return (
      <div
        className={`b-user-item ${id === item.id ? 'active' : ''}`}
        style={{ border: '1px solid lightgray' }}
        key={`${index}`}
        onClick={() => {
          if (!item.detail) {
            dispatch(getVersionDetails(item.id))
            setAction({ ...action, loading: true })
          }
          setItem(item)
          setModalVersions(false)
        }}>
        <div
          className={'justify-content-start align-items-start bu-en'}
          style={{ textAlign: 'left' }}>
          <div
            className={`b-user-name ${
              id === item.id ? 'active' : ''
            }`}>{`Version ${item.versionName}`}</div>
          <div className={`b-user-email ${id === item.id ? 'active' : ''}`}>
            {item.description && item.description.length > 0
              ? item.description.slice(
                  0,
                  item.description.length > (tablet ? 50 : 100)
                    ? tablet
                      ? 50
                      : 100
                    : item.description.length,
                ) + '...'
              : 'Sin descripción'}
          </div>
        </div>
        <div
          className={'d-flex row align-items-center mr-2 bu-b'}
          style={{ textAlign: 'right' }}>
          <Icon icon={faEye} size={'1_5x'} tooltip={'Visualizar versión'} />
        </div>
      </div>
    )
  }

  const renderItemsVersions = item => {
    return (
      item.detail &&
      item.detail.filter(filterVersion).map(d => (
        <CustomCard
          version={item.id}
          id={d.id}
          icon
          title={`Sección: ${d.title || ''}`}
          white
          button={renderButton(d)}>
          <div>
            <Row>
              <Col sm={12} md={7} lg={8}>
                <ReactQuill
                  style={{ marginTop: 10 }}
                  className={'custom-quill'}
                  modules={{ toolbar: null }}
                  theme="snow"
                  value={d.description || ''}
                  readOnly
                  onChange={value => {}}
                />
              </Col>
              <Col sm={12} md={5} lg={4}>
                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <b>Permisos</b>
                    <Row style={{ paddingBottom: 10, alignItems: 'center' }}>
                      {d.roles &&
                        d.roles.map(p => (
                          <div className="chip mb-2">
                            <div className="chip-head">{p.charAt(0)}</div>
                            <div className="chip-content">{p}</div>
                          </div>
                        ))}
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <b>Categorias</b>
                    <Row style={{ paddingBottom: 10, alignItems: 'center' }}>
                      {d.categorizations &&
                        d.categorizations.map(p => (
                          <div className="chip mb-3 mb-0 ml-1">
                            <div className="chip-head">{p.name.charAt(0)}</div>
                            <div className="chip-content">{p.name}</div>
                          </div>
                        ))}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </CustomCard>
      ))
    )
  }

  const renderRowVideos = (id, item, index) => {
    return (
      <div
        className={`b-user-item ${id === item.id ? 'active' : ''}`}
        style={{ border: '1px solid lightgray' }}
        key={`${index}`}
        onClick={() => {
          setTimeout(() => {
            setItem(item)
            setAction({ ...action, loading: false })
          }, 200)
          setAction({ ...action, loading: true })
          setModalVersions(false)
        }}>
        <div
          className={'justify-content-start align-items-start bu-en'}
          style={{ textAlign: 'left' }}>
          <div
            className={`b-user-name ${
              id === item.id ? 'active' : ''
            }`}>{`${item.name}`}</div>
        </div>
        <div
          className={'d-flex row align-items-center mr-2 bu-b'}
          style={{ textAlign: 'right' }}>
          <Icon icon={faEye} size={'1_5x'} tooltip={'Ver videos'} />
        </div>
      </div>
    )
  }

  const renderItemsVideos = () => {
    let items =
      (isDetail && lastDet ? lastDet.videos : filterVideos.filter(filterVersion)) || []
    return items.length > 0 ? (
      items &&
        items.map(d => (
          <CustomCard title={`${d.video.title || ''}`} white>
            <div>
              <Row>
                <Col sm={12} md={8} lg={7}>
                  <ReactQuill
                    style={{ marginTop: 10 }}
                    className={'custom-quill'}
                    modules={{ toolbar: null }}
                    theme="snow"
                    value={d.video.description || ''}
                    readOnly
                    onChange={value => {}}
                  />
                </Col>
                <Col sm={12} md={4} lg={5}>
                  <video
                    onPlay={() => stopOtherVideos(d.urls[0])}
                    id={`vid-${d.urls[0].id}`}
                    width="100%"
                    height="240px"
                    controls>
                    <source src={d.urls[0].url} type="video/mp4" />
                  </video>
                </Col>
              </Row>
            </div>
          </CustomCard>
        ))
    ) : (
      <h3 style={{ textAlign: 'center' }}>Sin videos para mostrar</h3>
    )
  }

  const renderVersions = () => {
    const id = item.id
    return (
      <Col sm={12} md={3}>
        {window.innerWidth > 480 && !isVideo && (
          <div>
            <Button
              className={'d-flex'}
              style={{ width: '100%', justifyContent: 'center' }}
              color={'accent'}
              onClick={() => setModal({ video: videos, total, show: true })}>
              <IconButton icon={faVideo} color={'white'} /> Ver videos
            </Button>
          </div>
        )}
        {!isDetail && <Title title={isVideo ? 'Videos' : 'Ver todas las versiones'} />}
        {!isDetail && (
          <CustomFilter
            search={search}
            onChange={({ target }) => setSearch(target.value)}
            customFilter={customFilter(false)}
          />
        )}
        <Row>
          <Col xl={12} style={{ overflowY: 'scroll', height: window.innerHeight - 260 }}>
            {isDetail && lastDet ? (
              <CustomCard
                white
                title={`Descripción`}
                style={{
                  overflowY: 'scroll',
                  height: window.innerHeight - 260,
                }}>
                <div>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <ReactQuill
                        style={{ marginTop: 10 }}
                        className={'custom-quill'}
                        modules={{ toolbar: null }}
                        theme="snow"
                        value={lastDet.description || ''}
                        readOnly
                        onChange={value => {}}
                      />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Row>
                        <Col>
                          <b>Permisos</b>
                          <Row style={{ paddingBottom: 10, alignItems: 'center' }}>
                            {lastDet.roles &&
                              lastDet.roles.map(p => (
                                <div className="chip mb-2">
                                  <div className="chip-head">{p.charAt(0)}</div>
                                  <div className="chip-content">{p}</div>
                                </div>
                              ))}
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <b>Categorias</b>
                          <Row style={{ paddingBottom: 10, alignItems: 'center' }}>
                            {lastDet.categorizations &&
                              lastDet.categorizations.map(p => (
                                <div className="chip mb-3 mb-0 ml-1">
                                  <div className="chip-head">{p.name.charAt(0)}</div>
                                  <div className="chip-content">{p.name}</div>
                                </div>
                              ))}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </CustomCard>
            ) : (
              <Table
                customClass={'w-100'}
                hideFilter
                pageSize={isVideo ? 2 : 1}
                items={
                  isVideo ? itemCategorization.filter(filter) : versions.filter(filter)
                }
                renderRow={(item, index) =>
                  isVideo
                    ? renderRowVideos(id, item, index)
                    : renderRowVersions(id, item, index)
                }
              />
            )}
          </Col>
        </Row>
      </Col>
    )
  }

  const onSearch = useDebounce(search => {
    dispatch(getVideos(1, 0, step, search))
  }, 500)

  return (
    <div>
      <Media>
        {({ breakpoints, currentBreakpoint }) => {
          const mobile = breakpoints[currentBreakpoint] < 480
          return !mobile && isDetail && <Title title={lastDet && lastDet.title} />
        }}
      </Media>
      <Row>
        <Media>
          {({ breakpoints, currentBreakpoint }) => {
            const mobile = breakpoints[currentBreakpoint] < 480
            return !mobile ? (
              renderVersions()
            ) : isDetail && lastDet ? (
              <CustomCard white title={`Sección: ${lastDet.title}`}>
                <div>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <ReactQuill
                        style={{ marginTop: 10 }}
                        className={'custom-quill'}
                        modules={{ toolbar: null }}
                        theme="snow"
                        value={lastDet.description || ''}
                        readOnly
                        onChange={value => {}}
                      />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Row>
                        <Col>
                          <b>Permisos</b>
                          <Row style={{ paddingBottom: 10, alignItems: 'center' }}>
                            {lastDet.roles &&
                              lastDet.roles.map(p => (
                                <div className="chip mb-2">
                                  <div className="chip-head">{p.charAt(0)}</div>
                                  <div className="chip-content">{p}</div>
                                </div>
                              ))}
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <b>Categorias</b>
                          <Row style={{ paddingBottom: 10, alignItems: 'center' }}>
                            {lastDet.categorizations &&
                              lastDet.categorizations.map(p => (
                                <div className="chip mb-3 mb-0 ml-1">
                                  <div className="chip-head">{p.name.charAt(0)}</div>
                                  <div className="chip-content">{p.name}</div>
                                </div>
                              ))}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </CustomCard>
            ) : (
              <div style={{ margin: 10, width: '100%' }}>
                <Title title={isVideo ? 'Videos' : 'Versiones'} />
                <Select
                  label={isVideo ? 'Escoger categoria' : 'Escoger versión'}
                  options={isVideo ? itemCategorization : versionsSelect}
                  value={item}
                  onChange={value => setItem(value)}
                />
              </div>
            )
          }}
        </Media>

        <Col sm={12} md={9}>
          <Media>
            {({ breakpoints, currentBreakpoint }) => {
              const mobile = breakpoints[currentBreakpoint] < 480
              return (
                !mobile &&
                !isDetail && (
                  <Title
                    title={
                      isVideo
                        ? `${item.name || ''}`
                        : `Version ${item.versionName || ''}: ${item.description || ''}`
                    }
                  />
                )
              )
            }}
          </Media>
          {!isDetail && (
            <CustomFilter
              search={searchVersion}
              onChange={({ target }) => setSearchVersion(target.value)}
              customFilter={customFilter(true)}
            />
          )}
          {(loading || action.loading || loadingV) && !isDetail && (
            <div className={'pb-custom'}>
              <ProgressBar
                label="Cargando"
                animated
                now={100}
                style={{ marginBottom: 10 }}
              />
            </div>
          )}
          <div>{isVideo ? renderItemsVideos(item) : renderItemsVersions(item)}</div>
        </Col>
      </Row>

      <Modal
        centered
        show={modal.show && !create.show}
        onHide={() => {
          setModal({ show: false, video: [] })
          setViewingDetailVideos(false)
        }}
        size={'xl'}>
        <Modal.Header closeButton>
          <Modal.Title>Videos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PaginatedVideo
            data={viewingDetailVideos ? modal.video : videos}
            total={viewingDetailVideos ? modal.video.length : total}
            onEdit={
              module === 11000 && admin && !modal.noEdit
                ? item => {
                    setData({
                      title: item.video.title,
                      description: item.video.description,
                      id: item.video.id,
                    })
                    setCreate({
                      show: true,
                      categories: item.categories,
                      videos: item.urls,
                    })
                  }
                : null
            }
            onPageChange={(page, search) => {
              if (videos.find(video => video.index === (page - 1) * step)) return
              dispatch(getVideos(1, (page - 1) * step, page * step, search))
            }}
            onSearch={onSearch}
          />
        </Modal.Body>
        <Modal.Footer>
          {module === 11000 && admin && !modal.noEdit && (
            <Button onClick={() => setCreate({ show: true, videos: [], categories: [] })}>
              Agregar Video
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <ModalVideo
        show={create.show}
        onHide={() => {
          setCreate({ show: false, videos: [], categories: [] })
          setData({ description: '', title: '', id: 0 })
        }}
        categories={create.categories}
        urlsFromWS={create.videos}
        data={data}
        manual
      />

      <Modal
        centered
        show={modalVersions}
        onHide={() => setModalVersions(false)}
        size={'xl'}>
        <Modal.Header closeButton>
          <Modal.Title>Versiones</Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderVersions()}</Modal.Body>
      </Modal>

      <Folder
        onHide={() => setFilters({ ...filters, showC: false, detail: false })}
        onAssign={item => {
          if (filters.detail)
            setFilters({
              ...filters,
              categories2: [...filters.categories2, item],
            })
          else
            setFilters({
              ...filters,
              categories: [...filters.categories, item],
            })
        }}
        data1={
          categorization && categorization.children ? categorization.children[0] : {}
        }
        show={filters.showC}
        noMessage
        type={categoryType.VIDEO}
      />

      <PermissionGroup
        roles={roles}
        show={filters.showR}
        select
        onAssign={item => {
          if (filters.detail)
            setFilters({ ...filters, roles2: [...filters.roles2, item] })
          else setFilters({ ...filters, roles: [...filters.roles, item] })
        }}
        onHide={() => setFilters({ ...filters, showR: false, detail: false })}
      />

      <FAB
        show={module === 11000 && admin}
        onClick={() => {
          history.push('/admin/information/nueva')
        }}
        icon={faPlus}
        tooltip={'Crear nueva versión'}
      />
    </div>
  )
}

export default Information
