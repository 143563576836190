import './FAB.scss'
import React, { useEffect, useState } from 'react'

import { Fab, Action } from 'react-tiny-fab'

import { faArrowLeft, faArrowRight, faPlus } from '@fortawesome/free-solid-svg-icons'

import Icon from './IconButton'

const FAB = props => {
  const [toggled, setToggle] = useState(false)
  const [seeNext, setSeeNext] = useState(false)
  const [next, setNext] = useState(false)

  useEffect(() => {
    if (props.items) {
      setSeeNext(props.items.filter(item => item.show && item.next).length > 0)
    }
  }, [props])

  const getDimmens = () => {
    let right = 1
    let bottom = 1

    switch (true) {
      case window.screen.width < 1199:
        right = 1
        bottom = 40
        break

      case window.screen.width > 1200:
        right = 1
        bottom = 40

        break

      default:
        break
    }
    return { right: 1, bottom: 1 }
  }

  const filter = item => {
    return seeNext ? (next && item.next) || (!next && !item.next) : true
  }

  return (
    <div>
      <Fab
        id={'fab-kolo'}
        className={'rtf  '
          .concat(toggled ? 'open' : '')
          .concat(props.show ? '' : ' hide-fab')}
        icon={
          <Icon
            disabled={props.disabled}
            spin={props.loading}
            icon={props.icon || faPlus}
            tooltip={props.tooltip || ''}
          />
        }
        style={{
          zIndex: 1,
          right: getDimmens().right,
          bottom: getDimmens().bottom,
        }}
        mainButtonStyles={{
          backgroundColor: '#12A8BB',
          ...props.mainButtonStyles,
        }}
        alwaysShowTitle={true}
        onClick={() => {
          if (props.loading) return
          if (!props.dontToggle) setToggle(!toggled)
          if (props.onClick) props.onClick()
        }}>
        {seeNext && (
          <Action
            text={!next ? 'Ver más opciones' : 'Regresar'}
            onClick={() => setNext(!next)}
            style={{ backgroundColor: '#098c9d' }}>
            <Icon icon={!next ? faArrowRight : faArrowLeft} />
          </Action>
        )}
        {props.items &&
          !props.loading &&
          props.items
            .filter(item => item.show)
            .filter(filter)
            .map((item, i) => (
              <Action
                key={i}
                text={item.text}
                onClick={() => {
                  if (!item.dontToggle) setToggle(!toggled)
                  item.action()
                }}
                style={{ backgroundColor: '#098c9d', ...item.style }}>
                {<Icon icon={item.icon} />}
              </Action>
            ))}
      </Fab>
    </div>
  )
}
export default FAB
