import React, { Component } from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'

class CustomColorPicker extends Component {
  state = {
    displayColorPicker: false,
    color: '#FFFFFF',
  }

  componentDidMount() {
    const { color } = this.props
    if (color) this.setState({ color })
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  }

  handleChangeColor = color => {
    this.setState({ color: color.hex })
    this.props.onChangeColor(color.hex, color.rgb)
  }

  render() {
    const styles = reactCSS({
      default: {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `${this.state.color}`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '10px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
          border: '2px solid #bfbcbc',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    })

    return (
      <div className={`input-wrapper has-feedback mt-3`}>
        <label className="ftf-form-label left mr-3">
          <b>{this.props.label || 'Color'}</b>
        </label>
        <div style={styles.swatch} onClick={this.handleClick}>
          <div style={styles.color} />
        </div>
        {this.state.displayColorPicker ? (
          <div id={'dd'} style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <SketchPicker
              color={this.state.color}
              onChange={this.handleChangeColor}
              // onChange={this.props.onChangeColor}
            />
          </div>
        ) : null}
      </div>
    )
  }
}

export default CustomColorPicker
