export const actionTypes = {
  MENU_SHOW: 'MENU_SHOW',
  MENU_EXPAND: 'MENU_EXPAND',
  MENU_SELECTED: 'MENU_SELECTED',
  MENU_SET: 'MENU_SET',
  SET_SEARCH: 'SET_SEARCH',
  GROUP_SELECTED: 'GROUP_SELECTED',
}

const menuShow = val => ({ type: actionTypes.MENU_SHOW, val })
const menuExpand = val => ({ type: actionTypes.MENU_EXPAND, val })
const menuSelected = val => ({ type: actionTypes.MENU_SELECTED, val })
const groupSelected = val => ({ type: actionTypes.GROUP_SELECTED, val })
const menuSet = val => ({ type: actionTypes.MENU_SET, val })

export const showMenu = val => dispatch => {
  dispatch(menuShow(val))
}

export const expandMenu = val => dispatch => {
  dispatch(menuExpand(val))
  if (val) dispatch(groupSelected(undefined))
}

export const selectMenu = val => dispatch => {
  dispatch(menuSelected(val))
  dispatch(groupSelected(undefined))
  dispatch(menuExpand(true))
}

export const setGroup = (val, collapse) => dispatch => {
  dispatch(groupSelected(val))
  if (collapse) {
    dispatch(selectMenu(0))
    dispatch(menuExpand(true))
  }
}

export const setMenu = val => dispatch => {
  dispatch(menuSet(val))
}

export const setSearchShow = payload => dispatch => {
  dispatch({ type: actionTypes.SET_SEARCH, payload })
}
