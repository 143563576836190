import React from 'react'
import DashboardCard from 'src/components/cards/dashboard-card/DashboardCard'
import ChecklistIcon from 'src/assets/images/svg/checklist.svg'
import ContractIcon from 'src/assets/images/svg/contract.svg'
import ClipboardIcon from 'src/assets/images/svg/clipboard.svg'

import { connect } from 'react-redux'
import './DocumentsDashboard.scss'
import { getCurrentCompany } from 'src/actions/logs.actions'
import { selectCurrentCompany } from 'src/selectors/logs.selector'
import Placeholder from 'src/components/Placeholder/Placeholder'
import { Row, Col } from 'react-bootstrap'

class DocumentsDashboard extends React.Component {
  componentDidMount() {
    this.props.getCurrentCompany()
  }

  render() {
    const { currentCompany } = this.props
    return !currentCompany ? (
      <Row>
        {[0, 0, 0].map((i, index) => (
          <Col key={index} md={4}>
            <Placeholder />
          </Col>
        ))}
      </Row>
    ) : (
      <div>
        <h1 className={'dashboard-welcome'}>Mis Documentos</h1>
        <div className={'documents-modules-list'}>
          {currentCompany && currentCompany.isClient ? (
            <DashboardCard
              name={'Contrato Cliente'}
              icon={ClipboardIcon}
              link={'/documentos/contrato/cliente'}
            />
          ) : (
            ''
          )}
          {currentCompany && currentCompany.isDistributor ? (
            <DashboardCard
              name={'Modulo Transportista'}
              icon={
                'https://firebasestorage.googleapis.com/v0/b/distribution-app-dev.appspot.com/o/assets%2Ftracking.svg?alt=media&token=51ff0b1c-a183-44d8-bf49-55f5855cc9d4'
              }
              link={'/documentos/modulo/transportista'}
            />
          ) : (
            ''
          )}
          {currentCompany && currentCompany.isProducer ? (
            <DashboardCard
              name={'Modulo Productor'}
              icon={
                'https://firebasestorage.googleapis.com/v0/b/distribution-app-dev.appspot.com/o/assets%2Ffactory.svg?alt=media&token=cf4ad364-7ebd-4b43-8c75-d8e173689dd3'
              }
              link={'/documentos/modulo/productor'}
            />
          ) : (
            ''
          )}
          {/*<DashboardCard name={'Términos y Condiciones'} icon={ContractIcon} link={'/documentos/terms'}/>*/}
          <DashboardCard
            name={'Usuarios y permisos'}
            icon={
              'https://firebasestorage.googleapis.com/v0/b/distribution-app-dev.appspot.com/o/assets%2Femployee.svg?alt=media&token=55b1d1e5-3623-4ca4-88e3-0d7856081c18'
            }
            link={'/documentos/permisos/4000'}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentCompany: selectCurrentCompany(state),
})

const mapDispatchToProps = dispatch => ({
  getCurrentCompany: () => dispatch(getCurrentCompany()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsDashboard)
