import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col } from 'react-bootstrap'
import {
  TableV2,
  Card,
  SelectedDates,
  Button,
  Paragraph,
  CustomSummary,
  Select,
} from 'src/components'
import { faDownload, faSearch } from '@fortawesome/free-solid-svg-icons'
import { toMoney } from 'src/utils/utilities'

import {
  actions,
  purchaseBookReport,
  purchaseBookReportExcel,
} from 'src/actions/books.actions'
import { selectPurchaseBook } from 'src/selectors/books.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, hasErrors } from 'src/selectors/error.selector'
import { showAlert } from 'src/actions/alert.actions'

const headers: ITableHeader[] = [
  {
    show: true,
    label: 'Fecha',
    type: 'date',
    value: ['date'],
  },
  {
    show: true,
    label: 'Categorías',
    type: 'text',
    value: ['categories'],
  },
  {
    show: true,
    label: 'Tipo',
    type: 'text',
    value: ['type'],
  },
  {
    show: true,
    label: 'Tipo documento',
    type: 'text',
    value: ['documentType'],
  },
  {
    show: true,
    label: 'Serie',
    type: 'text',
    value: ['serie'],
  },
  {
    show: true,
    label: 'Documento',
    type: 'text',
    value: ['document'],
  },
  {
    show: true,
    label: 'Estado',
    type: 'text',
    value: ['state'],
  },
  {
    show: true,
    label: 'Proveedor',
    type: 'text',
    value: ['provider'],
    custom: (item: IPurchaseBook) => (
      <div className={'column'}>
        <div>{item.providerName}</div>
        <div>{item.providerNit}</div>
      </div>
    ),
  },
  {
    show: true,
    label: 'FPEQ',
    type: 'text',
    value: ['FPEQ'],
  },
  {
    show: true,
    label: 'IVA',
    type: 'currency',
    value: ['iva'],
  },
  {
    show: true,
    label: 'Total',
    type: 'currency',
    value: ['total'],
  },
  {
    show: true,
    label: 'Pendiente',
    type: 'currency',
    value: ['balance'],
  },
]

const today = new Date()

/** Shows the accounting report Purchase Book
 * @page
 * **/
const PurchaseBook = () => {
  const dispatch = useDispatch()

  const items: IPurchaseBook[] = useSelector(selectPurchaseBook)

  const loadingReport = useSelector(state =>
    loadingSelector([actions.GET_PURCHASE_BOOK, actions.GET_PURCHASE_BOOK_EXCEL])(state),
  )
  const hasErrorReport = useSelector(state =>
    hasErrors([actions.GET_PURCHASE_BOOK, actions.GET_PURCHASE_BOOK_EXCEL])(state),
  )

  const [flags, setFlags] = useState({ get: false, initialized: false })

  const [filters, setFilters] = useState({
    start: new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0).valueOf(),
    end: today.setHours(23, 59, 59).valueOf(),
    bill: { value: null, label: 'Todos' },
    byCreationDate: { value: false, label: 'Emisión' },
  })

  const total: number = items.reduce((total, item) => total + item.total, 0)
  const balance: number = items.reduce((balance, item) => balance + item.balance, 0)
  const iva: number = items.reduce((iva, item) => iva + item.iva, 0)

  useEffect(() => setUp(false), [])

  useEffect(() => {
    if (loadingReport) setFlags({ ...flags, get: true })
    else if (flags.get) {
      setFlags({ ...flags, get: false })
      if (hasErrorReport) dispatch(showAlert(handlerError(hasErrorReport.message)))
    }
  }, [loadingReport])

  const setUp = (excel: boolean) => {
    const params = {
      ...filters,
      bill: filters.bill.value,
      byCreationDate: filters.byCreationDate.value,
    }

    if (excel) dispatch(purchaseBookReportExcel(params))
    else dispatch(purchaseBookReport(params))
  }

  const customFilters = (
    <Row>
      <Col xl={12}>
        <hr />
      </Col>
      <Col xl={12}>
        <Paragraph size={'big'}>Filtros</Paragraph>
      </Col>

      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
        <Select
          label={'Por fecha de'}
          options={[
            { value: false, label: 'Emisión' },
            { value: true, label: 'Creación' },
          ]}
          value={filters.byCreationDate}
          onChange={v => setFilters({ ...filters, byCreationDate: v })}
        />
      </Col>

      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
        <Select
          label={'Tipo de documento'}
          options={[
            { value: null, label: 'Todos' },
            { value: true, label: 'Facturas' },
            { value: false, label: 'Recibos' },
          ]}
          value={filters.bill}
          onChange={v => setFilters({ ...filters, bill: v })}
        />
      </Col>
    </Row>
  )

  return (
    <div>
      <Card white title={'Libro de Compras'}>
        <Row>
          <Col xl={12}>
            <SelectedDates
              disabled={loadingReport}
              onDateChange={(start, end) => setFilters({ ...filters, start, end })}
              initialPreset={{ value: 2, label: 'Este mes' }}
              filter
              customFilter={customFilters}
            />
          </Col>
          <Col xl={12}>
            <Row className={'container-buttons'}>
              <Button
                loading={loadingReport}
                icon={faSearch}
                onClick={() => setUp(false)}>
                Buscar
              </Button>
              <Button
                loading={loadingReport}
                color={'accent'}
                icon={faDownload}
                onClick={() => setUp(true)}>
                Descargar
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>

      <Card white title={'Resultado'}>
        <Row>
          <Col xl={12}>
            <CustomSummary
              items={[
                { title: 'TOTAL', value: toMoney(total) },
                { title: 'PAGADO', value: toMoney(total - balance) },
                { title: 'PENDIENTE', value: toMoney(balance) },
                { title: 'IVA', value: toMoney(iva) },
              ]}
            />
          </Col>
          <Col xl={12}>
            <TableV2
              // @ts-ignore
              loading={loadingReport}
              headers={headers}
              items={items}
              mobileAuto
            />
          </Col>
        </Row>
      </Card>
    </div>
  )
}
export default PurchaseBook
