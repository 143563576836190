import React from 'react'

import { Row, Col } from 'react-bootstrap'
import FormTextV2 from 'src/components/inputs/FormTextField/FormTextV2'

interface IClientTaxExemptProps {
  values: IClientTaxExemptValues
  onChange: (values: IClientTaxExemptValues) => void
}

const ClientTaxExempt = ({
  values = {
    purchaseNumber: '',
    sag: '',
    register: '',
  },
  onChange,
}: IClientTaxExemptProps) => {
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target
    const response = Object.assign({}, values)

    response[name] = value
    onChange(response)
  }

  return (
    <Row>
      <Col xl={12}>
        <FormTextV2
          label={'Orden de compra exenta'}
          required
          name={'purchaseNumber'}
          value={values.purchaseNumber}
          onChange={onChangeInput}
        />
      </Col>
      <Col xl={12}>
        <FormTextV2
          label={'SAG'}
          name={'sag'}
          value={values.sag}
          onChange={onChangeInput}
        />
      </Col>
      <Col xl={12}>
        <FormTextV2
          label={'Constancia de registro'}
          required
          name={'register'}
          value={values.register}
          onChange={onChangeInput}
        />
      </Col>
    </Row>
  )
}
export default ClientTaxExempt
