import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Alert from 'sweetalert-react'
import { Modal, Row, Col } from 'react-bootstrap'

import { Button, TableV2, Paragraph, Icon, CustomTabs } from 'src/components'
import CSVLoaderV2, { CATEGORY_UPLOAD } from 'src/components/CSVLoader/CSVLoaderV2'
import {
  faPause,
  faPlay,
  faSpinner,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons'

import { actionTypes, onCreatePaymentByCSVValues } from 'src/actions/orders.actions'

import { handlerInfo, hasErrors } from 'src/selectors/error.selector'

import { toMoney } from 'src/utils/utilities'
import { formatHourFromMillis } from 'src/utils/formatters'
import { loadingSelector } from '../../../../selectors/loading.selector'

const PaymentsCSV = ({ show = false, onHide }) => {
  const dispatch = useDispatch()

  const loading = useSelector(state =>
    loadingSelector([actionTypes.MASSIVE_PAYMENTS])(state),
  )
  const error = useSelector(state => hasErrors([actionTypes.MASSIVE_PAYMENTS])(state))

  const [flags, setFlags] = useState({ process: false })

  const [update, setUpdate] = useState(false)
  const [open, setOpen] = useState(false)
  const [alert, setAlert] = useState({ title: 'title' })
  const [items, setItems] = useState([])
  const [seePreview, setSeePreview] = useState(false)

  const [timer, setTimer] = useState(0)
  const [initialTime, setInitialTime] = useState(0)
  const [finishTime, setFinishTime] = useState(0)

  const [play, setPlay] = useState({
    isPlay: false,
    item: null,
  })

  useEffect(() => {
    if (!show) return
    setOpen(true)
    setSeePreview(false)
    setItems([])
    setUpdate(false)
  }, [show])

  useEffect(() => {
    setTimeout(() => {
      const today = new Date()
      setTimer(today.valueOf())
    }, 1000)
  }, [timer])

  useEffect(() => {
    if (loading) setFlags({ ...flags, process: true })
    else if (flags.process) {
      setFlags({ ...flags, process: false })
      setUpdate(true)
      if (error) {
        items[play.item.key].status = 3
        items[play.item.key].error = error.message || 'Error en la operación'
      } else items[play.item.key].status = 2
      setItems([...items])
      goToNext()
    }
  }, [loading])

  useEffect(() => {
    if (!play.isPlay) return
    goToNext()
  }, [play.isPlay])

  const goToNext = () => {
    const first = items.filter(i => i.status === 1)[0]

    if (first) {
      setPlay({ ...play, item: first })
      dispatch(onCreatePaymentByCSVValues(first))
    } else {
      const finished = new Date()
      setFinishTime(finished.valueOf())
      setPlay({ ...play, isPlay: false })
      setAlert({
        ...handlerInfo(
          'Proceso concluido',
          `El proceso ha concluido, se realizaron ${
            items.filter(o => o.status === 2).length
          } operaciones exitosas y en ${
            items.filter(o => o.status === 3).length
          } se detectaron errores.`,
        ),
        onConfirm: () => setAlert({ ...alert, show: false }),
      })
    }
  }

  const onCreateGroups = results => {
    //const g = results.filter(i => i.tipoAbono === 'general')
    //const p = results.filter(i => i.tipoAbono === 'parcial')

    const i = []

    results.forEach(r => {
      const parcial = r.tipoAbono === 'parcial'

      const index = i.findIndex(ii => r.numeroDePago === ii.number)
      let item
      if (parcial) item = { number: r.numeroVenta, amount: r.montoPagar }
      else item = { number: r.codigo + ' ' + r.nombre, amount: r.montoPagar }

      if (parcial && index > -1) {
        i[index].items.push(item)
      } else {
        i.push({
          clientCode: r.codigo,
          amount: r.montoPagar,
          number: r.numeroDePago,
          paymentType: r.tipoPago,
          account: r.cuenta,
          date: r.fechaDePago,
          immediate: r.pagoInmediato === 'si',
          pagoInmediato: r.pagoInmediato,
          items: [item],
          type: r.tipoAbono,
          status: 1,
          key: i.length,
        })
      }
    })

    /*
    p.forEach(pi => {
      const index = i.findIndex(ii => pi.numeroDePago === ii.number)
      const item = { number: pi.numeroVenta, amount: pi.montoPagar }

      if (index > -1) {
        i[index].items.push(item)
      } else {
        i.push({
          clientCode: '',
          number: pi.numeroDePago,
          paymentType: pi.tipoPago,
          account: pi.cuenta,
          date: pi.fechaDePago,
          immediate: pi.pagoInmediato === 'si',
          items: [item],
          type: pi.tipoAbono,
          status: 1,
          key: i.length,
        })
      }
    })
    */

    setItems(i)
    setSeePreview(true)
  }

  const onClose = () => {
    setOpen(false)
    setSeePreview(false)
    setItems([])
    onHide(update)
  }

  const renderTable = status => {
    return (
      <TableV2
        headers={[
          { label: 'Número del abono', show: true, value: ['number'], type: 'code' },
          {
            label: 'Tipo de pago',
            show: true,
            value: ['paymentType'],
            type: 'text',
          },
          { label: 'Cuenta', show: true, value: ['account'], type: 'text' },
          { label: 'Fecha de pago', show: true, value: ['date'], type: 'text' },
          { label: 'Tipo de abono', show: true, value: ['type'], type: 'text' },
          {
            label: 'CpC',
            show: true,
            value: ['items'],
            type: 'text',
            style: { minWidth: 140 },
            custom: i => (
              <div>
                <ul className={'base-sub-items'}>
                  {i.items.map((ii, index) => (
                    <li key={index}>
                      <div className={'column'}>
                        <div className={'b-user-name'}>{ii.number}</div>
                        <div className={'b-user-email ml-3'}>{toMoney(ii.amount)}</div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ),
          },
          {
            label: 'Aprobacion inmedita',
            show: true,
            value: ['pagoInmediato'],
            type: 'text',
          },
          { label: 'Error', show: status === 3, value: ['error'], type: 'text' },
          {
            label: '',
            show: status === 1 && play.isPlay,
            value: ['v'],
            type: 'text',
            custom: item =>
              play.isPlay &&
              item.key === play?.item?.key && (
                <Icon spin icon={faSpinner} tooltip={'Operando'} />
              ),
          },
        ]}
        items={items.filter(i => i.status === status)}
        mobileAuto
      />
    )
  }

  return (
    <div>
      <Modal
        show={open}
        size={'md'}
        centered
        onHide={() => {
          setOpen(false)
          onHide(update)
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Ingrese el CSV</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <CSVLoaderV2
              category={CATEGORY_UPLOAD.LOAD_PAYMENTS_CPC}
              title={'CSV de abonos a cuentas por cobrar'}
              onSuccess={items => {
                setOpen(false)
                if (!items || items.filter(i => typeof i === 'object').length <= 0)
                  setAlert({
                    ...hasErrors('No fue posible encontrar items en el csv'),
                    onSuccess: () => {
                      setAlert({ ...alert, show: false })
                      onHide()
                    },
                  })
                else {
                  const r = items.filter(i => typeof i === 'object')
                  onCreateGroups(r)
                }
              }}
            />
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={seePreview} size={'xl'} centered onHide={() => undefined}>
        <Modal.Header closeButton={false}>
          <Modal.Title>Abonos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <Paragraph>
                En la siguiente tabla de información se muestran todos los abonos a
                realizar indicando a que cuentas por cobrar van dirigidas
              </Paragraph>
            </Col>
            <Col xl={12}>
              <div className={'d-flex mt-2'}>
                <Button
                  color={'primary'}
                  disabled={play.isPlay}
                  onClick={() => {
                    const initial = new Date()
                    setInitialTime(initial.valueOf())
                    setFinishTime(0)

                    setPlay({ isPlay: true, index: null })
                  }}>
                  <Icon icon={faPlay} tooltip={'Iniciar proceso'} spin={play.isPlay} />
                </Button>
                <Button
                  disabled={!play.isPlay}
                  color={'accent'}
                  onClick={() => {
                    const finished = new Date()
                    setFinishTime(finished.valueOf())
                    setPlay({ isPlay: false, index: null })
                  }}>
                  <Icon icon={faPause} tooltip={'Pausar proceso'} />
                </Button>
                <div className={'column mt-1 ml-1'}>
                  <div className={'b-user-name no-transform'}>
                    Tiempo transcurrido:
                    {formatHourFromMillis(
                      initialTime === 0
                        ? 0
                        : (finishTime > 0 ? finishTime : new Date().valueOf()) -
                            initialTime,
                      true,
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={12}>
              <CustomTabs
                ctBank
                items={[
                  {
                    title: 'Por operar',
                    component: renderTable(1),
                  },
                  {
                    title: 'Operado con Exito',
                    component: renderTable(2),
                  },
                  {
                    title: 'Operado con Errores',
                    component: renderTable(3),
                  },
                ]}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              disabled={play.isPlay}
              color={'secondary'}
              icon={faWindowClose}
              onClick={() => onClose()}>
              Cerrar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Alert {...alert} />
    </div>
  )
}
export default PaymentsCSV
