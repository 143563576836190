import { ConfigurationFELInvoiceTypeEnum } from 'src/enums/ConfigurationFELInvoiceTypeEnum'

const getFELConfigurationByTaxExemptValues = (
  taxExemptValues: IClientTaxExemptValues,
): IFELConfigurationField[] => {
  if (!taxExemptValues) return null
  return [
    {
      type: ConfigurationFELInvoiceTypeEnum.PURCHASE_NUMBER,
      valueString: taxExemptValues?.purchaseNumber,
    },
    {
      type: ConfigurationFELInvoiceTypeEnum.SAG,
      valueString: taxExemptValues?.sag,
    },
    {
      type: ConfigurationFELInvoiceTypeEnum.REGISTER,
      valueString: taxExemptValues?.register,
    },
  ]
}

const formatFELConfiguration = (
  felConfigurations: IFELConfigurationField[],
): IExtraParamsToOrder => {
  const response: IExtraParamsToOrder = {}
  felConfigurations?.forEach(configuration => {
    switch (configuration.type) {
      case ConfigurationFELInvoiceTypeEnum.PURCHASE_NUMBER:
        if (!response.taxExemptValues) response.taxExemptValues = {}
        response.taxExemptValues.purchaseNumber = configuration.valueString
        break
      case ConfigurationFELInvoiceTypeEnum.SAG:
        if (!response.taxExemptValues) response.taxExemptValues = {}
        response.taxExemptValues.sag = configuration.valueString
        break
      case ConfigurationFELInvoiceTypeEnum.REGISTER:
        if (!response.taxExemptValues) response.taxExemptValues = {}
        response.taxExemptValues.register = configuration.valueString
        break
    }
  })
  return response
}
export { formatFELConfiguration, getFELConfigurationByTaxExemptValues }
