import './ClientsMap.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { selectSelectedClients } from 'src/selectors/clients.selector'
import MapCard from 'src/components/maps/MapCard'

const latitude = 14.589465440976774
const longitude = -90.51898866891861

class ClientsMap extends Component {
  state = {
    allClients: [],
    height: 0,
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    let height = window.innerHeight - 250
    this.setState({ height })
  }

  render() {
    const { selectedClients } = this.props
    const { height } = this.state
    return (
      <div style={{ paddingBottom: 50 }}>
        <MapCard
          startCollapsed={false}
          address={'Mapa'}
          height={height}
          latitude={latitude}
          longitude={longitude}
          markerList={selectedClients}
          showMarker={true}
        />
      </div>
    )
  }
}

ClientsMap.defaultProps = {
  allClients: [],
  selectedClients: [],
}

const mapStateToProps = state => ({
  selectedClients: selectSelectedClients(state),
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ClientsMap)
