import { actionTypes } from '../actions/warehouse.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  allWarehouses: [],
  ownWarehouses: [],
  externalActiveWarehouses: [],
  warehouseTypes: [],
  deletedWarehouse: null,
  activeWarehouse: null,
  warehouse: null,
  inventory: [],
  products: [],
  warehouseExistence: [],
  transfers: [],
  totalTransfers: 0,
  transfer: { transfer: {}, detail: [] },
  categorizations: [],
  inventoryDetail: [],
  inventoryDetails: [],
  report: [],
  associatedOrders: [],
  reportTotal: [],
  reportTotalAdmin: [],
  inventoryWithPrices: [],
  origin: 1,
  userWarehouses: [],
  processed: [],
  orders: [],
  lastLogInventory: {},
  purchases: 0,
  purchasesByWarehouse: 0,
  csv: {},
  historical: [],
  resultBatch: '',
  total: 0,
  totalInventory: 0,
  value: 0,
  sale: 0,
  totalSale: 0,
  purchase: 0,
  warehousesLite: [],
  itemsToSelect: [],
  inventoryResponse: {
    items: 0,
    total: 0,
    value: 0,
    sale: 0,
  },
  itemsToSelectDetail: [],
  lineItemsToSelect: {},
  inventoryMissingResponse: {
    items: 0,
    total: 0,
  },
  inventoryVariation: {
    items: 0,
    total: 0,
    value: 0,
    sale: 0,
  },
  gpsWarehouse: {},
  minMaxItems: [],
  minMaxActionResponse: {},
  responseLoad: {},
  responseValidate: {},
  infoTransfer: {},
  kardexReport: {},
}

const WarehouseReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.WAREHOUSE_INVENTORY_MIN_MAX_CREATE_UPDATE):
      return { ...state, minMaxActionResponse: extract(action.response, {}) }
    case successState(actionTypes.WAREHOUSE_INVENTORY_MIN_MAX):
      return { ...state, minMaxItems: extract(action.response, []) }
    case successState(actionTypes.GET_INVENTORY_MISSING_ITEMS):
      return {
        ...state,
        inventoryMissingResponse: extract(action.response, { items: 0, total: 0 }),
      }
    case successState(actionTypes.GET_ITEMS_TO_SELECT):
      return { ...state, itemsToSelect: extract(action.items, []) }
    case successState(actionTypes.GET_ITEMS_TO_SELECT_DETAIL):
      return { ...state, itemsToSelectDetail: extract(action.items, []) }
    case successState(actionTypes.GET_ITEMS_TO_SELECT_BARCODE):
      return { ...state, itemsToSelectDetail: extract(action.items, []) }
    case successState(actionTypes.GET_LINE_ITEMS_TO_SELECT):
      return { ...state, lineItemsToSelect: extract(action.data, {}) }
    case successState(actionTypes.GET_WAREHOUSE_LITE):
      return { ...state, warehousesLite: extract(action.response, []) }
    case successState(actionTypes.MASIVE_BATCH):
      return { ...state, resultBatch: extract(action.result, '') }
    case successState(actionTypes.LOAD_CSV):
      return { ...state, csv: extract(action.csv, {}) }
    case successState(actionTypes.GET_HISTORICAL_INVENTORY):
      return { ...state, historical: extract(action.historical, []) }
    case successState(actionTypes.ON_VALIDATE_INVENTORY):
      return {
        ...state,
        lastLogInventory: extract(action.lastLogInventory, {}),
      }
    case successState(actionTypes.GET_LAST_INVENTORY_LOG):
      return {
        ...state,
        lastLogInventory: extract(action.lastLogInventory, {}),
      }
    case successState(actionTypes.PURCHASE_REPORT):
      return { ...state, purchases: action.purchases }
    case successState(actionTypes.PURCHASE_REPORT_BY_WAREHOUSE):
      return { ...state, purchasesByWarehouse: action.purchases }
    case successState(actionTypes.GET_INVENTORY_WITH_PRICE):
      return {
        ...state,
        inventoryWithPrices: extract(action.inventory, []),
      }
    case actionTypes.SET_INFO_TRANSFER_EXTERNAL:
      return {
        ...state,
        transfer: action.transfer,
      }
    case actionTypes.INVENTORY_SUCCESS:
      return {
        ...state,
        inventory: action.response,
        inventoryWithPrices: action.response,
      }
    case successState(actionTypes.GET_ALL_WAREHOUSES):
      return {
        ...state,
        allWarehouses: extract(action.warehouses, []),
      }
    case successState(actionTypes.GET_ALL_WAREHOUSES2):
      return {
        ...state,
        allWarehouses: extract(action.warehouses?.list, []),
        total: action.warehouses?.total,
        totalSale: action.warehouses?.sale,
        purchase: action.warehouses?.purchase,
      }
    case successState(actionTypes.GET_ALL_USER_WAREHOUSES):
      return {
        ...state,
        allWarehouses: extract(action.userWarehouses, []),
        userWarehouses: extract(action.userWarehouses, []),
      }
    case successState(actionTypes.GET_ALL_WAREHOUSES_USER):
      return {
        ...state,
        allWarehouses: extract(action.warehouses, []),
        ownWarehouses: extract(action.warehouses, []),
      }
    case successState(actionTypes.GET_EXTERNAL_ACTIVE_WAREHOUSES):
      return {
        ...state,
        externalActiveWarehouses: extract(action.warehouses, []),
      }
    case successState(actionTypes.GET_ALL_PRODUCTS):
      return {
        ...state,
        products: extract(action.products, []),
      }
    case successState(actionTypes.GET_ALL_WAREHOUSES_TYPES):
      return {
        ...state,
        warehouseTypes: extract(action.warehousesTypes, []),
      }
    case successState(actionTypes.GET_SINGLE_WAREHOUSE):
      return {
        ...state,
        warehouse: extract(action.warehouse, {}),
      }
    case successState(actionTypes.UPDATE_WAREHOUSE):
      return {
        ...state,
        editedWarehouse: extract(action.warehouse),
      }
    case successState(actionTypes.CREATE_WAREHOUSE):
      return {
        ...state,
        newWarehouse: extract(action.warehouse),
      }
    case successState(actionTypes.DELETE_WAREHOUSE):
      return {
        ...state,
        deletedWarehouse: extract(action.result),
      }
    case successState(actionTypes.ACTIVE_WAREHOUSE):
      return {
        ...state,
        activeWarehouse: extract(action.result),
      }
    case successState(actionTypes.GET_WAREHOUSE_INVENTORY):
      return {
        ...state,
        inventory: extract(action.inventory, []),
      }
    case successState(actionTypes.GET_EXISTENCE_BY_WAREHOUSE):
      return {
        ...state,
        warehouseExistence: extract(action.existence, []),
      }
    case successState(actionTypes.GET_TRANSFERS): {
      const totalTransfers = extract(action.transfers.total, []) ?? state.totalTransfers
      return {
        ...state,
        transfers: extract(action.transfers.items, []),
        totalTransfers,
      }
    }
    case successState(actionTypes.GET_TRANSFER_DETAIL):
      return {
        ...state,
        transfer: extract(action.transfer, null),
      }
    case successState(actionTypes.GET_ALL_CATEGORIZATIONS):
      return {
        ...state,
        categorizations: extract(action.categorizations, []),
      }
    case successState(actionTypes.GET_INVENTORY_DETAIL):
      return {
        ...state,
        inventoryDetail: extract(action.inventoryDetail, []),
      }
    case successState(actionTypes.GET_INVENTORY_DETAILS):
      return {
        ...state,
        inventoryDetails: extract(action.inventoryDetails, []),
      }
    case successState(actionTypes.INVENTORY_WAREHOUSE):
      return {
        ...state,
        inventoryResponse: extract(action.response, {}),
      }
    case successState(actionTypes.GET_TOTAL_PRODUCT_REPORT):
      return {
        ...state,
        reportTotal: extract(action.reportTotal, []),
      }
    case successState(actionTypes.GET_TOTAL_PRODUCT_ADMIN_REPORT):
      return {
        ...state,
        reportTotalAdmin: extract(action.reportTotalAdmin, []),
      }
    case successState(actionTypes.GET_ASSOCIATED_ORDERS):
      return {
        ...state,
        associatedOrders: extract(action.associatedOrders, []),
      }
    case actionTypes.SET_ORIGIN:
      return {
        ...state,
        origin: action.origin,
      }
    case successState(actionTypes.GET_ORDERS_PROCESSED):
      return {
        ...state,
        processed: extract(action.processed, []),
      }
    case successState(actionTypes.GET_ORDERS):
      return {
        ...state,
        orders: extract(action.warehouses, []),
      }
    case successState(actionTypes.GET_INVENTORY_VARIATION):
      return {
        ...state,
        inventoryVariation: extract(action.response, {}),
      }
    case successState(actionTypes.GET_GPS_WAREHOUSE):
      return {
        ...state,
        gpsWarehouse: extract(action.response, {}),
      }
    case successState(actionTypes.MASSIVE_TRANFER):
      return {
        ...state,
        responseLoad: extract(action.response, {}),
      }
    case successState(actionTypes.MASSIVE_VALIDATION):
      return {
        ...state,
        responseValidate: extract(action.response, {}),
      }
    case successState(actionTypes.INFO_TRANSFER): {
      return {
        ...state,
        infoTransfer: { ...action.payload },
      }
    }
    case successState(actionTypes.GET_WAREHOUSE_KARDEX_REPORT):
      return {
        ...state,
        kardexReport: extract(action.response, {}),
      }
    default:
      return state ? state : initialState
  }
}

export default WarehouseReducer
