import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCompanyData } from 'src/actions/company.actions'
import { TemplateNotification } from 'src/content/Notifications/TemplateNotification'
import { selectCurrentCompany } from 'src/selectors/user.selector'
import { connectWebSocket } from 'src/utils/connectToWebsocket'

interface IProps {
  inactive: boolean
}

/**
 * Component to show a notification when the account is deactivated
 * @param inactive if the account is active
 */
export const AccountDeactivated = ({ inactive }: IProps) => {
  const dispatch = useDispatch()
  const companyId = useSelector(selectCurrentCompany)
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (!companyId) return
    connectWebSocket(`company/active/${companyId}`, () => {
      dispatch(getCompanyData())
      setShow(true)
    })
  }, [companyId])

  return show || inactive ? (
    <TemplateNotification
      title={'¡Ups!'}
      description="Tu cuenta presenta un saldo pendiente de pago, comunícate con un asesor"
      showButtons
    />
  ) : (
    <> </>
  )
}
