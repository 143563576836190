import { actionTypes } from '../actions/user.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  currentUser: {},
  companies: [],
  currentCompany: null,
  currentModule: null,
  createResponse: null,
  searchResponse: null,
  fieldsResponse: [],
  updateFieldsResponse: null,
  recoverMessage: { msg: '' },
  adminUsers: [],
  users: [],
  error: false,
  profile: {},
}

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CURRENT_USER_ERROR:
      return { ...state, error: true }
    case successState(actionTypes.GET_PROFILE):
      return { ...state, profile: action.profile }
    case actionTypes.RECOVER_PASSWORD_BY_EMAIL_SUCCESS:
      return {
        ...state,
        recoverMessage: action.response,
      }
    case actionTypes.UPDATE_USER_FIELDS_SUCCESS:
      return {
        ...state,
        updateFieldsResponse: action.response,
      }
    case actionTypes.GET_USER_FIELDS_SUCCESS:
      return {
        ...state,
        fieldsResponse: action.response,
      }
    case actionTypes.SEARCH_USER_BY_EMAIL_SUCCESS:
      return {
        ...state,
        searchResponse: action.response,
      }
    case actionTypes.CREATE_NEW_USER_SUCCESS:
      return {
        ...state,
        createResponse: action.response,
      }
    case actionTypes.GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        currentUser:
          action.user && initialState.currentUser
            ? { ...initialState.currentUser, ...action.user }
            : action.user || {},
        error: false,
      }
    case successState(actionTypes.SEND_EMAIL):
      return {
        ...state,
        currentUser:
          action.user && initialState.currentUser
            ? { ...initialState.currentUser, ...action.user }
            : action.user || {},
        error: false,
      }
    case actionTypes.GET_USER_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.companies || [],
      }
    case actionTypes.SAVE_CURRENT_COMPANY:
      return {
        ...state,
        currentCompany: action.company,
      }
    case actionTypes.SAVE_CURRENT_MODULE:
      return {
        ...state,
        currentModule: action.module,
      }
    case successState(actionTypes.GET_ALL_USERS_COTIZAP):
      return {
        ...state,
        adminUsers: extract(action.adminUsers, []),
      }
    case successState(actionTypes.GET_USERS_BY_USER):
      return {
        ...state,
        users: extract(action.users, []),
      }
    default:
      return state ? state : initialState
  }
}

export default UserReducer
