import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { getProviders } from 'src/actions/producer.actions'
import { selectAllProviders } from 'src/selectors/producer.selector'
import { setInfo } from 'src/actions/purchase.actions'
import { Button, CustomDate, FormText, Icon, Switch } from '../../index'
import { faBook, faIdCard, faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import { valNit } from 'src/selectors/utilities.selector'
import ButtonIcon from '../../buttons/IconButton'
import {
  actionTypes as typeC,
  getAllCategorizations,
} from 'src/actions/categorization.actions'
import Folder, { categoryType } from '../../folders/Folder'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, hasErrorsSelector } from 'src/selectors/error.selector'
import Alert from 'sweetalert-react'
import CustomCreate from '../../inputs/Creable/CustomCreate'
import CSVLoaderV2, { CATEGORY_UPLOAD } from '../../CSVLoader/CSVLoaderV2'
import { actionTypes as typeT, createBankOperation } from 'src/actions/banks.actions'
import Operation from '../../Banks/Operation'

import { selectNITinfo } from 'src/selectors/clients.selector'

/* ------------- NIT --------------*/
import useDebounce from '../../../hooks/useDebounce'
import InfoNit from 'src/components/InfoNit/InfoNit'
import { actionTypes as typesC, getInfoNIT } from 'src/actions/clients.actions'

const SimplePurchase = ({
  modal,
  purchase,
  show,
  onHide,
  onSave,
  load,
  amount,
  updated,
  onCancel,
  bank,
}) => {
  const dispatch = useDispatch()

  const providers = useSelector(selectAllProviders)
  const categorization = useSelector(selectAllCategorizations)
  const loadingAssigned = useSelector(state =>
    loadingSelector([typeC.GET_ASSIGNED, typeC.GET_ALL])(state),
  )
  const loadingOp = useSelector(state => loadingSelector([typeT.CREATE_OPERATION])(state))
  const hasErrorOp = useSelector(state =>
    hasErrorsSelector([typeT.CREATE_OPERATION])(state),
  )

  const loadingNit = useSelector(state => loadingSelector([typesC.GET_NIT_INFO])(state))
  const hasErrorNit = useSelector(state =>
    hasErrorsSelector([typesC.GET_NIT_INFO])(state),
  )
  const infoNIT = useSelector(selectNITinfo)

  const [alert, setAlert] = useState({ title: '' })
  const [item, setItem] = useState({})
  const [provider, setProvider] = useState({})
  const [create, setCreate] = useState({
    isLoading: false,
    options: providers,
    value: undefined,
  })
  const [modalCreate, setModalCreate] = useState({ show: false })

  const [selectedCategories, setSelectedCategories] = useState([])
  const [showCategorization, setShowCategorization] = useState({})
  const [actions, setActions] = useState({
    retention: false,
    multiple: false,
    deposit: false,
    matched: false,
    simples: false,
    movement: false,
    associated: false,
  })
  const [alertForm] = useState({
    title: '',
    text: '',
    type: 'success',
    show: false,
  })

  useEffect(() => {
    dispatch(getProviders())
  }, [])

  useEffect(() => {
    if (loadingOp) setActions({ ...actions, movement: true })
    else if (actions.movement) {
      setActions({ ...actions, movement: false })
      if (!hasErrorOp && updated) updated()
    }
  }, [loadingOp])

  const onChange = e => {
    const { value, name } = e.target
    setItem({ ...item, [name]: value })
  }

  useEffect(() => {
    if (loadingNit) setActions({ ...actions, search: true })
    else if (actions.search) {
      if (hasErrorNit) {
        setAlert({
          ...handlerError('No se pudo obtener la información con el NIT indicado'),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      } else {
        setProvider({ ...provider, nit: infoNIT.NIT, name: infoNIT.clientName })
        setCreate({ ...create, value: { label: infoNIT.clientName } })
      }
    }
  }, [loadingNit])

  const handleChange = newValue => {
    setItem({ ...item, value: newValue })
    setCreate({ ...create, value: newValue })
    if (newValue)
      setProvider({
        name: newValue.label,
        nit: newValue.nit ? newValue.nit : '',
      })
    else setProvider({ name: '', nit: '' })
  }

  const handleCreate = inputValue => {
    setCreate({ ...item, isLoading: true })
    setTimeout(() => {
      const options = providers
      const newOption = { value: '', label: inputValue }
      setCreate({
        isLoading: false,
        options: [...options, newOption],
        value: newOption,
      })
      setProvider({ name: newOption.label, nit: 'CF' })
      setItem({ ...item, name: newOption.label, nit: 'CF' })
    }, 500)
  }

  const validateModal = () => {
    //if(!create.value) return true;
    if (provider.nit && !valNit(provider.nit)) return true
    else if (bank) return false
    else if (!amount && (!item.total || item.total <= 0)) return true
    return false
  }

  const save = () => {
    let request = {
      date: item.date ? item.date.valueOf() : null,
      nit: provider.nit,
      provider: provider.name,
      serie: item.serie,
      invoice: item.invoice,
      total: amount ? amount : item.total,
      categories: selectedCategories.map(p => p.id),
      description: item.description,
      paymentType: purchase ? 1 : 3,
      nullify: item.nullify,
    }
    onSave(request)
    setCreate({ isLoading: false, options: providers, value: undefined })
    setProvider({})
    setItem({})
    setSelectedCategories([])
  }

  const handleNitSearch = useDebounce(nit => {
    if (!nit || nit === 'CF' || !valNit(nit)) return
    dispatch(getInfoNIT(nit))
  }, 1000)

  const changeNIT = ({ target }) => {
    const { value } = target
    setProvider({ ...provider, nit: value })
    if (valNit(value)) {
      if (value.toUpperCase() !== 'CF') {
        let array = create.options.filter(s => s.nit === value)
        if (array.length > 0) setCreate({ ...create, value: array })
        else handleNitSearch(value)
      } else {
        let c = {}
        c.nit = value
        setCreate({ ...create, value: c })
      }
    }
  }

  const renderForm = () => {
    return (
      <div>
        <Row>
          {amount == null && (
            <Col md={modal ? 12 : 4} className="mb-4">
              <CSVLoaderV2
                category={CATEGORY_UPLOAD.SIMPLE_PURCHASE}
                title={`Carga de ${purchase ? 'Compras simples' : 'Gastos simples'} `}
                onFinishLoad={() => {
                  updated()
                }}
                type={purchase ? 1 : 2}
              />
            </Col>
          )}

          <Col xl={12} md={12}>
            <CustomDate
              label={'Fecha de emisión'}
              value={item.date}
              onDayChange={d => setItem({ ...item, date: d })}
            />
          </Col>
          <Col xl={6} lg={6} md={6}>
            <InfoNit
              type={'text'}
              name={'nit'}
              required={purchase}
              prepend={<Icon icon={faIdCard} spin={loadingNit} />}
              nit={provider.nit}
              disabled={loadingNit}
              onChange={ev => {
                changeNIT(ev)
              }}
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={6} xs={12}>
            <CustomCreate
              label={'Seleccionar proveedor'}
              options={providers}
              onChange={handleChange}
              onCreateOption={handleCreate}
              value={create.value}
              textLabel={'Agregar proveedor: '}
              placeholder={'Selecciona un proveedor'}
              required={purchase}
            />
          </Col>

          <Col xl={12} lg={12} md={12}>
            <FormText
              label={'Número de serie'}
              required={purchase}
              value={item.serie}
              type={'text'}
              placeholder={'Número de serie'}
              name={'serie'}
              onChange={onChange}
            />
          </Col>

          <Col xl={12} lg={12} md={12}>
            <FormText
              label={'Número de factura o documento de referencia'}
              value={item.invoice}
              type={'text'}
              placeholder={'Número de factura'}
              name={'invoice'}
              onChange={onChange}
            />
          </Col>

          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <FormText
              disabled={amount !== null}
              label={'Total'}
              name={'total'}
              placeholder={'Total'}
              value={amount ? amount : item.total}
              required
              prependMoneySymbol
              onChange={onChange}
            />
          </Col>

          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <FormText
              label={'Descripción'}
              name={'description'}
              placeholder={'Descripción'}
              value={item.description}
              prepend={<Icon icon={faBook} />}
              onChange={onChange}
            />
          </Col>

          {bank && (
            <Col xl={12} lg={12}>
              <Switch
                topLabel
                label={'Marcar como anulada: No afectara al saldo bancario'}
                checked={item.nullify}
                changeValue={nullify => setItem({ ...item, nullify })}
              />
            </Col>
          )}

          {
            // AGREGAR CATEGORIAS
            <Col xl={12} lg={12} sm={12}>
              <Row className={'pl-1'}>
                {selectedCategories.map((p, index) => (
                  <div className={'user-tag product-t ml-2'} key={index}>
                    <label className={'label-user-tag'}>{p.name}</label>
                    <ButtonIcon
                      className={'delete-user-tag d-product-t'}
                      icon={faTimes}
                      tooltip={'Quitar'}
                      color={'white'}
                      onClick={() => {
                        setSelectedCategories(
                          selectedCategories.filter(f => f.id !== p.id),
                        )
                        setItem({
                          ...item,
                          categories: selectedCategories.filter(f => f.id !== p.id),
                        })
                      }}
                    />
                  </div>
                ))}
                <Button
                  style={{ marginLeft: 15 }}
                  color={'primary'}
                  onClick={() => {
                    setShowCategorization({
                      ...showCategorization,
                      show: true,
                      isOrder: true,
                    })
                    dispatch(getAllCategorizations(purchase ? 6 : 2))
                  }}>
                  Agregar categorías
                </Button>
              </Row>
            </Col>
          }
        </Row>

        <Alert {...alert} />
      </div>
    )
  }

  return (
    <>
      {modal ? (
        <Modal
          size={'md'}
          centered
          show={show && !modalCreate.show && !showCategorization.show}
          onHide={onHide}>
          <Modal.Header closeButton>
            <Modal.Title>{purchase ? 'Compra abreviada' : 'Gasto abreviado'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={'container'}>{renderForm()}</div>
          </Modal.Body>
          <Modal.Footer>
            {!purchase && amount === null && (
              <Button
                color={'secondary'}
                disabled={validateModal()}
                onClick={() => {
                  setModalCreate({
                    ...modalCreate,
                    amount: item.total,
                    show: true,
                  })
                }}>
                Crear movimiento bancario
              </Button>
            )}
            {onCancel && (
              <Button
                loading={load}
                color={'secondary'}
                onClick={() => {
                  onHide()
                  onCancel()
                }}>
                Cancelar
              </Button>
            )}
            <Button
              loading={load}
              disabled={validateModal()}
              icon={faSave}
              right
              onClick={() => save()}>
              Registrar
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        renderForm()
      )}
      <Folder
        noMessage
        onHide={() =>
          setShowCategorization({
            ...showCategorization,
            show: false,
            filter: false,
            isOrder: false,
          })
        }
        onAssign={item => {
          let d = selectedCategories.find(d => d.id === item.id)
          if (!d) {
            selectedCategories.push(item)
            setSelectedCategories([...selectedCategories])
            dispatch(setInfo({ ...purchase, categories: selectedCategories }))
          }
        }}
        data1={
          categorization && categorization.children ? categorization.children[0] : {}
        }
        data2={
          categorization && categorization.children ? categorization.children[1] : {}
        }
        show={showCategorization.show}
        loading={loadingAssigned}
        list={selectedCategories.map(p => p.id)}
        type={purchase ? categoryType.PURCHASE : categoryType.EXPENSES}
      />

      <Operation
        show={modalCreate.show}
        onHide={() => setModalCreate({ ...modalCreate, show: false })}
        amount={modalCreate.amount}
        purchase
        onSave={movement => {
          let request = {
            nit: provider.nit,
            provider: provider.name,
            paymentType: purchase ? 1 : 3,
            total: amount ? amount : item.total,
            description: item.description,
            categories: selectedCategories.map(p => p.id),
          }
          movement.purchase = request
          dispatch(createBankOperation(movement, { duplicate: false }))
        }}
      />
      <Alert {...alertForm} />
    </>
  )
}

export default SimplePurchase
