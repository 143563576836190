import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Modal } from 'react-bootstrap'
import {
  faArrowDown,
  faArrowUp,
  faBook,
  faEye,
  faSave,
  faTextWidth,
} from '@fortawesome/free-solid-svg-icons'
import Alert from 'sweetalert-react'

import {
  Title,
  Card,
  BalanceTable,
  Select,
  TableV2,
  FormText,
  Icon,
  Button,
  Dropdown,
  CustomSummary,
  CustomReference,
  MoneyField,
} from 'src/components'

import {
  actionTypes as balanceActions,
  createUserBalanceEntry,
  getUsersBalanceInCompany,
} from 'src/actions/balance.actions'
import { selectGetUsersBalance } from 'src/selectors/balance.selector'

import { selectSetCurrentCompany } from 'src/selectors/company.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  hasErrorsSelector,
  singleErrorSelector,
  handlerSuccess,
} from 'src/selectors/error.selector'

import ModalTransferDetail from 'src/content/Transfer/DetailTransfers/ModalTransferDetail'
import Money from 'src/components/Money/Money'
import { toMoney } from 'src/utils/utilities'
import { isAllowed } from 'src/selectors/modules.selector'
import { userCashBoxPermissions } from 'src/enums/permissions'
import { getCompanyBalance } from 'src/actions/company.actions'

const BalanceCompany = () => {
  const dispatch = useDispatch()

  const company = useSelector(selectSetCurrentCompany)
  const registers = useSelector(state => state.balance.registers)

  const users = useSelector(selectGetUsersBalance)
  const loadingUsersBalance = useSelector(state =>
    loadingSelector([balanceActions.GET_USERS_BALANCE])(state),
  )

  const loadingCreateEntry = useSelector(state =>
    loadingSelector([balanceActions.CREATE_USER_ENTRY])(state),
  )
  const hasErrorCreate = useSelector(state =>
    hasErrorsSelector([balanceActions.CREATE_USER_ENTRY])(state),
  )
  const errorCreate = useSelector(state =>
    singleErrorSelector([balanceActions.CREATE_USER_ENTRY])(state),
  )

  const loadingBC = useSelector(state =>
    loadingSelector([balanceActions.GET_COMPANY_BALANCE_REPORT_CHILDREN])(state),
  )

  const canIncrease = useSelector(state =>
    isAllowed(state, [userCashBoxPermissions.increase]),
  )
  const canDecrease = useSelector(state =>
    isAllowed(state, [userCashBoxPermissions.decrease]),
  )

  const [actions, setActions] = useState({ entry: false })
  const [userBalance, setUBalance] = useState({})
  const [alert, setAlert] = useState({ title: '' })
  const [reference, setReference] = useState({ show: false })
  const [recollect, setRecollect] = useState({})
  const [customRef, setRef] = useState({})

  const [newFilter, setFilter] = useState({ value: 0, label: 'Con saldo' })

  useEffect(() => {
    dispatch(getUsersBalanceInCompany())
    dispatch(getCompanyBalance())
  }, [])

  useEffect(() => {
    if (loadingCreateEntry) setActions({ ...actions, entry: true })
    else if (actions.entry) {
      setActions({ ...actions, entry: false })
      if (hasErrorCreate)
        setAlert({
          ...handlerSuccess(errorCreate.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Operación exitosa'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            setUBalance({ bShow: false })
            dispatch(getUsersBalanceInCompany())
            dispatch(getCompanyBalance())
          },
        })
    }
  }, [loadingCreateEntry])

  const renderUser = (item, index) => {
    return (
      <div className={'b-user-item'} key={`${index}`}>
        <div
          className={'justify-content-start align-items-start bu-en'}
          style={{ textAlign: 'left' }}>
          <div className={'b-user-name'}>{item?.userData?.name}</div>
          <div className={'b-user-email'}>{item?.userData?.email}</div>
          <Money className={'b-user-balance-m'}>{item.balanceTransportist}</Money>
        </div>
        <div
          className={'d-flex row align-items-center mr-2 bu-b'}
          style={{ textAlign: 'right' }}>
          <Money className={'b-user-balance mr-2'}>{item.balanceTransportist}</Money>
          <Dropdown
            className={'dp-custom'}
            items={[
              {
                icon: faEye,
                title: 'Ver cuadre',
                action: () =>
                  setUBalance({
                    show: true,
                    id: item.user,
                    name: item.userData.name,
                    balance: item.balanceTransportist,
                  }),
              },
              {
                icon: faArrowUp,
                show: canIncrease,
                title: 'Aumentar el efectivo',
                action: () =>
                  setUBalance({
                    bShow: true,
                    id: item.user,
                    name: item.userData?.name,
                    balance: Math.abs(item.balanceTransportist),
                    type: 0,
                  }),
              },
              {
                icon: faArrowDown,
                show: canDecrease,
                title: 'Disminuir el efectivo',
                action: () =>
                  setUBalance({
                    bShow: true,
                    id: item.user,
                    name: item.userData?.name,
                    balance: Math.abs(item.balanceTransportist),
                    type: 1,
                  }),
              },
            ]}
          />
        </div>
      </div>
    )
  }

  const seeReference = item => {
    switch (item.type) {
      default:
        setRecollect({
          show: true,
          id: item.documentId,
          title: `${item.typeName}: ${item.reference}`,
        })
        break
      case 2:
      case 3:
      case 6:
      case 7:
        setRef({
          show: true,
          documentModule: 2,
          documentType: 1,
          documentId: item.documentId,
        })
        break
      case 4:
        setRef({
          show: true,
          documentModule: 2,
          documentType: 4,
          documentId: item.documentId,
        })
        break
      case 5:
        setRef({
          show: true,
          documentModule: 2,
          documentType: 9,
          documentId: item.documentId,
        })
        break
    }
  }

  const headers = [
    { label: 'Tipo', show: true, value: ['typeName'], type: 'text', className: 'mini' },
    {
      label: 'Referencia',
      show: true,
      value: ['reference'],
      type: 'text',
      className: 'medium',
    },
    {
      label: 'Creado',
      show: true,
      value: ['createdAt'],
      type: 'date',
      className: 'mini',
    },
    {
      label: 'Disponible',
      show: true,
      value: ['available'],
      type: 'currency',
      className: 'mini',
    },
    {
      label: 'Retenido',
      show: true,
      value: ['detained'],
      type: 'currency',
      className: 'mini',
    },
    {
      label: 'A depositar',
      show: true,
      value: ['toDeposit'],
      type: 'currency',
      className: 'mini',
    },
    {
      config: true,
      show: true,
      label: '',
      className: 'mini',
      custom: item => (
        <Icon
          icon={faBook}
          tooltip={'Ver referencia'}
          onClick={() => seeReference(item)}
        />
      ),
    },
  ]

  return (
    <div className={'column'}>
      <Title title={'Efectivo circulante'} />

      <Row>
        <Col lg={4}>
          <Card title={'Usuarios'}>
            <TableV2
              customClass={'scroll-x-without-height'}
              customFilter={
                <div style={{ width: '100%' }}>
                  <Select
                    label={'Tipo de reporte'}
                    options={[
                      { label: 'Todos', value: null },
                      { label: 'Con saldo', value: 0 },
                      { label: 'Filtrado con efectivo', value: 1 },
                      { label: 'Filtrado sin efectivo', value: 2 },
                    ]}
                    value={newFilter}
                    onChange={type => setFilter(type)}
                  />
                  <br />
                </div>
              }
              loading={loadingUsersBalance}
              items={users.filter(
                u =>
                  u.type === 0 &&
                  (newFilter.value === 0
                    ? u.balanceTransportist !== 0
                    : newFilter.value === 1
                    ? u.balanceTransportist < 0
                    : newFilter.value === 2
                    ? u.balanceTransportist >= 0
                    : true),
              )}
              renderRow={renderUser}
            />
          </Card>
        </Col>
        <Col lg={8}>
          <BalanceTable
            balance={{ available: company.ownBalanceTransp, ret: 0, dep: null }}
            balanceType={0}
            hideDisbursement
          />
        </Col>
      </Row>

      <Modal
        show={userBalance.show}
        centered
        size={'lg'}
        onHide={() => setUBalance({ show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>{userBalance.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BalanceTable
            balance={{ available: 0, ret: 0 }}
            balanceType={3}
            byUser
            userId={userBalance.id}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={userBalance.bShow}
        centered
        size={'md'}
        onHide={() => setUBalance({ bShow: false })}>
        <Modal.Header closeButton>
          <Modal.Title>{userBalance.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={6} lg={6} md={6} sm={12}>
              <MoneyField
                label={'Saldo disponible'}
                value={userBalance.balance}
                disabled
              />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12}>
              <MoneyField
                label={`Saldo a ${userBalance.type === 0 ? 'acreditar' : 'retirar'}`}
                value={userBalance.uBalance}
                onValueChange={uBalance => {
                  setUBalance({ ...userBalance, uBalance })
                }}
                disabled={loadingCreateEntry}
                min={0}
                max={userBalance.type === 1 ? userBalance.balance : undefined}
              />
            </Col>
            <Col xl={12} lg={12} md={12} sm={12}>
              <FormText
                disabled={loadingCreateEntry}
                as={'textarea'}
                rows={'3'}
                prepend={<Icon tooltip={'concepto'} icon={faTextWidth} />}
                placeholder={'Concepto...'}
                value={userBalance.description}
                max={50}
                onChange={({ target }) =>
                  setUBalance({ ...userBalance, description: target.value })
                }
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              loading={loadingCreateEntry}
              disabled={!userBalance.uBalance || userBalance.uBalance <= 0}
              color={'primary'}
              icon={faSave}
              right
              onClick={() => {
                dispatch(
                  createUserBalanceEntry(
                    userBalance.id,
                    userBalance.type,
                    Math.abs(userBalance.uBalance),
                    userBalance.description,
                  ),
                )
              }}>
              Guardar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={reference.show && !recollect.show && !customRef.show}
        centered
        size={'lg'}
        onHide={() => setReference({ show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>
            {reference.typeName}: {reference.reference}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12} md={12} sm={12}>
              <CustomSummary
                items={[
                  { title: 'Disponible', value: toMoney(reference.available) },
                  { title: 'Retenido', value: toMoney(reference.detained) },
                  { title: 'A depositar', value: toMoney(reference.toDeposit) },
                ]}
              />
            </Col>
            <Col xl={12} md={12} sm={12}>
              <TableV2
                hideFilter
                items={loadingBC ? [] : registers}
                noItemsLegend={'No se encontraron reportes con los filtros aplicados'}
                loading={loadingBC}
                headers={headers}
                mobileAuto
                storageKey={'generalRegistryLogs'}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <ModalTransferDetail {...recollect} onHide={() => setRecollect({})} />

      <CustomReference {...customRef} onHide={() => setRef({})} />

      <Alert {...alert} />
    </div>
  )
}

export default BalanceCompany
