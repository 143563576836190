import './TableOrderPage.scss'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { onSetCategorySelected } from 'src/actions/restaurant.actions'
import {
  selectCategorySelected,
  selectPOSCategories,
} from 'src/selectors/restaurant.selector'

const TableOrderCategories = () => {
  const dispatch = useDispatch()

  const selected = useSelector(selectCategorySelected)
  const categories = useSelector(selectPOSCategories)

  return (
    <div className="pos-categories">
      {categories.map(i => {
        const active = i.id === selected.id
        return (
          <div
            key={i.id}
            style={{ backgroundColor: i.color || '#12A8BB' }}
            className={`pos-category ${active ? 'selected' : ''}`}
            onClick={() => {
              if (selected.id === i.id) dispatch(onSetCategorySelected({}))
              else dispatch(onSetCategorySelected(i))
            }}
          >
            <div className={'pos-category-name'}>{i.name}</div>
          </div>
        )
      })}
    </div>
  )
}
export default TableOrderCategories
