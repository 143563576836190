import { actionTypes } from '../actions/shopping.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  shopping: [],
  shoppingCreate: {},
}

const ShoppingReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.ON_CREATE_ORDER):
      return {
        ...state,
        shoppingCreate: extract(action.shoppingCreate, {}),
      }
    case actionTypes.ADD_ITEM:
      return {
        ...state,
        shopping: action.shopping,
      }
    default:
      return state ? state : initialState
  }
}

export default ShoppingReducer
