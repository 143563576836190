import { faEdit, faFileExcel, faPlus } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { useContext, useEffect, useRef, useState } from 'react'
import React from 'react'
import { Button, Row, Stack } from 'react-bootstrap'
import { CustomTabs, Icon, Button as ButtonC } from 'src/components'
import {
  AccountMovementsContext,
  BankContext,
  MovementsFilter,
} from 'src/providers/BankContext'

import {
  getExcelMovements,
  actionTypes as typeT,
  deleteAccount,
  getAccountHistory,
} from 'src/actions/banks.actions'
import DepositPage from 'src/content/Deposits/DepositPage'
import { useDispatch, useSelector } from 'react-redux'
import { selectGetHistory } from 'src/selectors/banks.selector'
import AccountMovementsTable from './AccountMovementsTable'
import EditAccountModal from '../Shared/EditAccountModal'
import OperationsModal from '../Shared/OperationsModal'
import BankUpCsv from '../BankUpCsv'

import { loadingSelector } from 'src/selectors/loading.selector'

import { isAllowed } from 'src/selectors/modules.selector'
import {
  bankAccountPermissionsEnum,
  defaultBankAccount,
  TableElementEnum,
} from 'src/enums/bankEnums'
import { showAlert } from 'src/actions/alert.actions'
import { getImageSrc } from 'src/utils/bankHelpers/bankHelpers'
import {
  handlerError,
  handlerSuccess,
  handlerWarningButtons,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'

interface AccountTableListProps {
  accountSelected?: IBankAccount
}
interface actionState {
  excelMovement?: boolean
  deletedAccount?: boolean
}

const AccountTableList: React.FC<AccountTableListProps> = ({
  accountSelected,
}: AccountTableListProps) => {
  const dispatch = useDispatch()
  const { params, updateParams } = useContext(AccountMovementsContext) || {}

  const loadingEM = useSelector(state => loadingSelector([typeT.EXCEL_MOVEMENTS])(state))
  const importAccountMovementsCSV = useSelector(state =>
    isAllowed(state, [bankAccountPermissionsEnum.ImportAccountMovementsCSV]),
  )
  const loadingT = useSelector(state => loadingSelector([typeT.SET_MOVEMENTS_CSV])(state))
  const loadingCreateOrUpdateAccount = useSelector(state =>
    loadingSelector([typeT.CREATE_ACCOUNT, typeT.UPDATE_ACCOUNT])(state),
  )
  const hasErrorEM = useSelector(state =>
    hasErrorsSelector([typeT.EXCEL_MOVEMENTS])(state),
  )
  const errorEM = useSelector(state =>
    singleErrorSelector([typeT.EXCEL_MOVEMENTS])(state),
  )
  const { updateAccount } = useContext(BankContext) || {}
  const history = useSelector(selectGetHistory)

  const historyMovements: requestHistoryMovementData[] = history.movements

  const allowEditAccount = useSelector(state =>
    isAllowed(state, [bankAccountPermissionsEnum.EditAccount]),
  )
  const allowDeleteAccount = useSelector(state =>
    isAllowed(state, [bankAccountPermissionsEnum.DeleteAccount]),
  )
  const allowCreateOperation = useSelector(state =>
    isAllowed(state, [bankAccountPermissionsEnum.CreateAccountMovementOperation]),
  )

  const allowExportAccountCSV = useSelector(state =>
    isAllowed(state, [bankAccountPermissionsEnum.ExportAccountMovementsCSV]),
  )
  const deletedAccount = useSelector(state =>
    loadingSelector([typeT.DELETE_ACCOUNT])(state),
  )

  const [modalEditShow, setmodalEditShow] = useState(false)
  const [modalOperationsShow, setModalOperationsShow] = useState(false)
  const [keyDefault, setKeyDefault] = useState(0)
  const [activeKey, setActiveKey] = useState(0)

  const [actionsDone, setActionsDone] = useState<actionState>({
    excelMovement: false,
    deletedAccount: false,
  })

  const accounnt = accountSelected
  const parentRef = useRef()

  useEffect(() => {
    if (loadingCreateOrUpdateAccount) {
      setKeyDefault(1)
    }
  }, [loadingCreateOrUpdateAccount])

  const handleExcelClick = () => {
    const getHistoryParams = { pending: false, skip: 0, size: 10, search: '' }

    const sDate = params.start ? params.start : null
    const eDate = params.end ? params.end : null

    dispatch(
      getExcelMovements(
        sDate,
        eDate,
        getHistoryParams.pending,
        getHistoryParams.skip,
        getHistoryParams.size,
        getHistoryParams.search,
        accounnt.id,
        null,
      ),
    )
  }
  const onDeleteAccount = () => {
    if (accounnt.id) {
      dispatch(
        showAlert({
          ...handlerWarningButtons('advertencia', 'Desea eliminar esta cuenta'),
          onConfirm: () => {
            dispatch(deleteAccount(accounnt.id))
          },
        }),
      )
    }
  }

  useEffect(() => {
    if (deletedAccount && accountSelected.id) {
      setTimeout(() => {
        setActionsDone({ ...actionsDone, deletedAccount: true })

        if (actionsDone.deletedAccount) {
          setActionsDone({ ...actionsDone, deletedAccount: false })
          dispatch(showAlert(handlerSuccess('Cuenta Eliminada')))
          updateAccount(defaultBankAccount)
        }
      }, 500)
    }
  }, [deletedAccount])

  useEffect(() => {
    let paramFirst: Partial<MovementsFilter> = { pending: true, skip: 0, size: 10 }
    switch (activeKey) {
      case 0:
        updateParams(paramFirst)

        break
      case 1:
        paramFirst = { pending: false, skip: 0 }
        updateParams(paramFirst)
        break
      case 2:
        break

      default:
        break
    }
  }, [activeKey])

  useEffect(() => {
    if (loadingEM) setActionsDone({ ...actionsDone, excelMovement: true })

    if (actionsDone.excelMovement) {
      setActionsDone({ ...actionsDone, excelMovement: false })
      if (hasErrorEM) {
        dispatch(showAlert(handlerError(errorEM.message)))
      } else {
        dispatch(
          showAlert(
            handlerSuccess(
              'Se exportaron los movimientos acreditados en las fechas indicadas.',
            ),
          ),
        )
      }
    }
  }, [loadingEM])

  return (
    <>
      <Row data-testid={'account-row-details'}>
        <Stack>
          <div className="d-flex align-items-center pl-1">
            {getImageSrc(accounnt.bank.id) !== undefined && (
              <img
                style={{ maxHeight: '150px', maxWidth: '150px' }}
                src={getImageSrc(accounnt.bank.id)}
                // src={bankImg[bankAccount.bank]}
                alt={'cuenta'}
              />
            )}
            {getImageSrc(accounnt.bank.id) !== undefined ? (
              <h2 className={'title-general'} data-testid={'account-name'}>
                Cuenta {accounnt.name}
              </h2>
            ) : (
              <h2 className={'pl-2 title-general'} data-testid={'account-name'}>
                Cuenta {accounnt.name}
              </h2>
            )}
          </div>
        </Stack>
      </Row>
      <Row>
        <div className={'d-flex flex-column gap-0 pl-3'}>
          <div data-testid={'accountToname'}>
            <p className={'fw-light mb-0'}>
              A Nombre de: <b>{accounnt.toName}</b>
            </p>
          </div>
        </div>
      </Row>
      <Row className={'justify-content-lg-end  container-buttons pr-3'}>
        <Stack>
          {allowEditAccount ? (
            <>
              <Button onClick={() => setmodalEditShow(true)}>
                <Icon tooltip={'Editar cuenta bancaria'} size={'1_1x'} icon={faEdit} />
              </Button>
            </>
          ) : null}
          {allowDeleteAccount ? (
            <>
              <Button variant={'danger'} onClick={onDeleteAccount}>
                <Icon tooltip={'Eliminar'} icon={faTrash} />
              </Button>
            </>
          ) : null}
          {allowExportAccountCSV ? (
            <>
              <ButtonC variant={'dark'} onClick={handleExcelClick} loading={loadingT}>
                <Icon
                  spin={loadingEM}
                  tooltip={'Exportar transacciones'}
                  icon={faFileExcel}
                />
              </ButtonC>
            </>
          ) : null}
          {allowCreateOperation ? (
            <>
              <Button variant={'info'} onClick={() => setModalOperationsShow(true)}>
                <Icon tooltip={'Agregar Operación'} icon={faPlus} />
              </Button>
            </>
          ) : null}
        </Stack>
        <div>
          {importAccountMovementsCSV ? (
            <>
              {' '}
              <BankUpCsv
                bankId={accounnt.bank.id}
                accountId={accounnt.id}
                onSuccess={() => dispatch(getAccountHistory(accounnt.id, params))}
              />
            </>
          ) : null}
        </div>
      </Row>

      <Row style={{ display: 'contents' }}>
        <CustomTabs
          noCard
          ctBank
          setKey={key => {
            setActiveKey(key)
          }}
          defaultKey={keyDefault}
          items={[
            {
              title: 'M. Autorizados',
              info: 'Registro de movimientos bancarios acreditados',
              component: (
                <>
                  <AccountMovementsTable
                    activeTable={TableElementEnum.authorized}
                    parentRef={parentRef}
                    historyMovementsAccount={historyMovements}
                    sizeList={history?.size}
                  />
                </>
              ),
            },
            {
              title: 'M. Pendientes',
              info: 'Registro de movimientos bancarios pendientes de acreditación',
              component: (
                <>
                  <AccountMovementsTable
                    activeTable={TableElementEnum.pending}
                    parentRef={parentRef}
                    setKey={setKeyDefault}
                    historyMovementsAccount={historyMovements}
                    sizeList={history?.size}
                  />
                </>
              ),
            },
            {
              title: 'Solicitudes de depositos',
              info: 'Solicitudes de depositos',
              component: (
                <>
                  <DepositPage
                    hide={true}
                    admin={accounnt.kolo}
                    bank={accounnt.bank.id}
                    currency={accounnt.currency.id}
                  />
                </>
              ),
            },
          ]}
        />
      </Row>

      <Row>
        <EditAccountModal show={modalEditShow} onHide={() => setmodalEditShow(false)} />
        <OperationsModal
          show={modalOperationsShow}
          onShow={() => setModalOperationsShow(true)}
          onHide={() => setModalOperationsShow(false)}
        />
      </Row>
    </>
  )
}

export default AccountTableList
