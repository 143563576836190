import TicketsService from '../services/tickets.service'
import { executeAction } from './global.actions'

export const actionTypes = {
  GET_ISSUES: 'GET_ISSUES',
}

export const getIssues = () => dispatch => {
  const process = async () => ({ issues: await TicketsService.getIssues() })
  dispatch(executeAction(actionTypes.GET_ISSUES, process))
}
