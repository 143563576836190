import React, { Component } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import './CustomDatePicker.scss'
import 'react-day-picker/lib/style.css'
import FormTextField from '../inputs/FormTextField/FormTextField'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import 'moment/locale/es'
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment'
import IconButton from '../buttons/IconButton'

const today = new Date()
export default class CustomDatePicker extends Component {
  time = new Date().getTime()

  state = {
    pickerShowing: false,
  }

  validateDate = date => {
    const { onDayChange } = this.props
    if (onDayChange) {
      onDayChange(date)
    }
  }

  showPicker = () => {
    if (this.state.pickerShowing) {
      this.picker.hideDayPicker()
      this.setState({ pickerShowing: false })
    } else {
      this.picker.showDayPicker()
      this.setState({ pickerShowing: true })
    }
  }

  render() {
    const {
      value,
      required,
      label,
      info,
      disabled,
      placeholder,
      textButton,
      error,
      disabledDays,
      format,
      prepend,
      mt,
      style,
      rangePicker,
      lite,
    } = this.props
    return (
      <DayPickerInput
        disabled={disabled}
        ref={ref => (this.picker = ref)}
        format={format || 'DD/MM/YYYY'}
        style={{ width: '100%' }}
        formatDate={formatDate}
        parseDate={parseDate}
        dayPickerProps={
          rangePicker || {
            locale: 'es',
            localeUtils: MomentLocaleUtils,
            disabledDays: disabledDays || [{ after: today }],
          }
        }
        value={value}
        onDayChange={this.validateDate}
        keepFocus={true}
        component={props =>
          lite ? (
            <IconButton
              textButton={textButton}
              icon={faCalendarAlt}
              disabled={disabled}
              style={style}
              onClick={this.showPicker}
            />
          ) : (
            <FormTextField
              {...props}
              style={style}
              required={required}
              mt={mt}
              label={label}
              disabled={disabled}
              append={
                !prepend ? (
                  <IconButton
                    icon={faCalendarAlt}
                    disabled={disabled}
                    style={style}
                    onClick={this.showPicker}
                  />
                ) : null
              }
              prepend={
                prepend ? (
                  <IconButton
                    icon={faCalendarAlt}
                    disabled={disabled}
                    style={style}
                    onClick={this.showPicker}
                  />
                ) : null
              }
              placeholder={placeholder}
              error={error}
              info={info}
            />
          )
        }
      />
    )
  }
}

CustomDatePicker.defaultProps = {
  placeholder: 'Fecha (DD/MM/AAAA)',
}
