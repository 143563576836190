import React, { useEffect } from 'react'
import logo from 'src/assets/images/newDesign/LogoKoloEmpresas.svg'
import './Maintenance.scss'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserError } from 'src/selectors/user.selector'
import { history } from '../../App'
import { getCurrentUser } from 'src/actions/user.actions'

const Maintenance = () => {
  const dispatch = useDispatch()

  const error = useSelector(selectUserError)

  useEffect(() => {
    if (error) {
      dispatch(getCurrentUser())
    }
  }, [])

  useEffect(() => {
    if (!error) {
      history.push('/')
    }
  }, [error])

  return (
    <div className={'maintenance'} style={{ height: window.screen.height - 250 }}>
      <div style={{ textAlign: 'center', margin: 10 }}>
        <div className={'pointer'}>
          <img src={logo} alt="maintenance" height={300} width={300} />
        </div>
        <h4 className={'maintenance-text'}>Estamos en Mantenimiento</h4>
      </div>
    </div>
  )
}

export default Maintenance
