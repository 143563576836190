import './Polygons.scss'
import React, {Component} from 'react';
import {connect} from "react-redux"

import {Td, Tr} from "react-super-responsive-table";
import {ButtonGroup, Col, Dropdown, DropdownButton, Modal, Row} from "react-bootstrap";
import {Link} from "react-router-relative-link";

import Card from "../../components/cards/Card";
import IconButton from "../../components/buttons/IconButton";
import MapCard from "../../components/maps/MapCard";

import {
    faInfoCircle,
    faPencilAlt
} from "@fortawesome/free-solid-svg-icons/index";

import {actionTypes, getAllPolygons} from "../../actions/polygons.actions";
import {selectAllPolygons} from "../../selectors/polygons.selector";

import {loadingSelector} from "../../selectors/loading.selector";

import {selectCurrentModule} from "../../selectors/user.selector";
import CollapsibleCard
    from "../../components/cards/collapsible-card/CollapsibleCard";
import {faArchive, faSlidersH, faTrash, faTrashRestore} from "@fortawesome/free-solid-svg-icons";
import ModalTable from "../../components/ModalTable/ModalTable";
import {changeArchivedStatus, getArchivedData, types as CommonTypes} from "../../actions/utilities.actions";
import {selectArchivedData} from "../../selectors/utilities.selector";
import {handlerError, handlerSuccess, hasErrorsSelector, singleErrorSelector} from "../../selectors/error.selector";
import Alert from "sweetalert-react";
import {getAllDeliverers, getAllDeliverersByProducer, getAllSellers} from "../../actions/clients.actions";
import {selectDeliverersToSelect, selectGetSellersToSelect} from "../../selectors/clients.selector";
import CustomSelect from "../../components/inputs/Select/CustomSelect";
import {getPermission, isAllowed} from "../../selectors/modules.selector";
import Switch from "../../components/inputs/Switch/Switch";
import Button from "../../components/buttons/Button";
import {Action, TableV2, Title} from "../../components";

const latitude = 14.589465440976774;
const longitude = -90.51898866891861;
class Clients extends Component {

    state = {
        polygonCoords : [],
        polygons: [],
        filters: {
            delivery: true,
            transport: true,
            global: true,
            subscription: true,
            kolo: true
        },
        showFilter: false,
        showArchived: false,
        restore: false,
        selectedPolygons: [],
        deliverer: {value: null, label: 'No seleccionado'},
        seller: {value: null, label: 'No seleccionado'},
    };

    componentDidMount() {
        const {module} = this.props
        this.props.getAllPolygons();
        this.props.getArchivedPolygons();
        if (module === 1000)
            this.props.getAllDeliverers();
        else if (module === 2000)
            getAllDeliverersByProducer();
        this.props.getSellers();
    }


    componentWillReceiveProps(next) {
        const {polygonsAreLoading, statusIsLoading, statusHasError, handlerError, handlerSuccess} = this.props;
        const {polygons, statusError} = next;
        let {alert, restore} = this.state

        if (polygonsAreLoading && !next.polygonsAreLoading) {
            const items = polygons.map((p) => {
                p.color = 'white';
                switch (p.categoryId) {
                    case 1:
                        p.color = 'green';
                        break
                    case 2:
                        p.color = 'gray';
                        break
                    case 3:
                        p.color = 'red';
                        break
                    case 4:
                        p.color = 'kolo_pink'
                        break
                    case 5:
                        p.color = 'kolo_blue';
                        break
                    default: p.color = 'white';
                }
                return p;
            });
            this.setState({polygons: items});
        }

        if(statusIsLoading && !next.statusIsLoading){
            if (!statusHasError && next.statusHasError) {
                alert = handlerError(statusError.response ? statusError.response.data.message : 'ha ocurrido un error inesperado.');
                alert.onConfirm = () => {this.setState({alert: {...alert, show: false}});};
            } else {
                if(restore) alert = handlerSuccess('Polígono recuperado');
                else alert = handlerSuccess('Polígono archivado');
                alert.onConfirm = () => {
                    this.setState({alert: {...alert, show: false}, restore: false});
                    this.props.getAllPolygons();
                    this.props.getArchivedPolygons();
                };
            }
            this.setState({alert});
        }
    };

    isSelected = (item) =>{
        const {selectedPolygons} = this.state
        let selected = selectedPolygons.filter(o => o.id === item.id).length
        return selected > 0 ? true: false;
    };

    onSelectRow = (e, item) => {
        let select = false;
        if(!e.target.type && !e.target.href) select = true
        if(select) {
            const {selectedPolygons} = this.state;
            const index = selectedPolygons.findIndex(i => i.id === item.id)
            if (index === -1) selectedPolygons.push(item)
            else selectedPolygons.splice(index, 1)
            this.setState({selectedPolygons});
        }
    }

    rowClicked = (e,data) => {
        let select = false;
        if(!e.target.type && !e.target.href && !e.target.value) select = true
        if(select) {
            let {polygonCoords, selectedPolygons} = this.state
            let selected = this.isSelected(data)
            if (!selected) {
                if (data.points.length > 0) {
                    polygonCoords.push(data.points.map(f => {
                        return {lat: Number(f.latitude), lng: Number(f.longitude)}
                    }))
                }
            } else {
                const index = selectedPolygons.findIndex(i => i.id === data.id)
                selectedPolygons = selectedPolygons.filter((f, ind) => index != ind)
                polygonCoords = polygonCoords.filter((f, ind) => index != ind)
            }
            this.setState({polygonCoords, selectedPolygons},() =>this.onGoogleApiLoaded());
        }
    };

    onGoogleApiLoaded = (map, maps) => {
        let {mapS,mapsS, manualPolygon} = this.state;
        if (manualPolygon !== undefined) manualPolygon.map(p => p.setMap(null));
        manualPolygon = []
        if (mapS === undefined && map !== undefined)
        {
            mapS = map;
            mapsS = maps;
            this.setState({mapS,mapsS})
        }
        let {polygonCoords} = this.state;
        polygonCoords.map((p, index) => {
            manualPolygon.push(new mapsS.Polygon({
                paths: p,
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35
            }));
            manualPolygon[index].setMap(mapS);
        })
        this.setState({manualPolygon});
    };

    goTo = (route) => {
        const {match} = this.props;
        this.props.history.push(match.url + route);
    };

    renderRow = (item, index) => {
        const {isAllowed} = this.props;
        const canArchive = isAllowed([2854, 1854, 11854]);
        const edit = isAllowed([11853, 1853, 2853]);


        return (
            <Tr className={`data ${this.isSelected(item) ? 'active' : ''}`} key={`${index}`}  data-item={item}
                onClick={(e) => {
                    this.rowClicked(e, item);
                    this.onSelectRow(e, item)
                }}>
                <Td className={`mini ${item.color}`}><IconButton tooltip={item.category.description} icon={faInfoCircle}/></Td>
                <Td className={'mini'}>{item.category.name}</Td>
                <Td className={'mini'}>{item.code}</Td>
                <Td className={'medium'}>{item.name}</Td>
                <Td className={'medium'}>{item.vendor ? item.vendor.name : 'Sin Vendedor'}</Td>
                <Td className={'medium'}>{item.deliverer ? item.deliverer.name : 'Sin Repartidor'}</Td>
                <Td className={'medium'}>{item.notes}</Td>
                <Td className={'mini center'}>
                    {
                        (canArchive || edit) &&
                        <Row>
                            <ButtonGroup size={'sm'}>
                                <DropdownButton className={'dp-custom'} drop={'left'} as={ButtonGroup} title={''}
                                                id={'bg-nested-dropdown'}>
                                    {
                                        canArchive &&
                                        <Dropdown.Item eventKey={'1'} onClick={() => {
                                            this.props.changeArchivedStatus(item.id)
                                        }}>Archivar</Dropdown.Item>
                                    }
                                    {
                                        edit &&
                                        <Dropdown.Item eventKey={'2'}
                                                       onClick={() => this.goTo(`/editar/${item.id}`)}>Editar</Dropdown.Item>
                                    }
                                </DropdownButton>
                            </ButtonGroup>
                        </Row>
                    }
                </Td>
            </Tr>
        )
    };

    getHeaders = () => {
        const {isAllowed} = this.props;
        const canArchive = isAllowed([2854, 1854, 11854]);
        const edit = isAllowed([11853, 1853, 2853]);
        return [
            {label: '', show: true,value:[], select:true,  classNameCustom:(item)=>`mini ${item.color}`,
            custom:(item)=> <IconButton tooltip={item.category.description} icon={faInfoCircle}/>},
            {label: 'Categoria', show: true, value: ['category','name'], type: 'text' ,  className:'mini'},
            {label: 'Código', show: true, value: ['code'], type: 'text' ,  className:'mini'},
            {label: 'Nombre', show: true, value: ['name'], type: 'text' ,  className:'medium'},
            {label: 'Vendedor', show: true, value: ['vendorC'], type: 'text' ,  className:'medium',
            custom:(item)=> item.vendorC= item.vendor ? item.vendor.name : 'Sin Vendedor'},
            {label: 'Repartidor', show: true, value: ['deliverC'], type: 'text' ,  className:'medium',
            custom:(item)=> item.deliverC = item.deliverer ? item.deliverer.name : 'Sin Repartidor'},
            {label: 'Nota', show: true, value: ['notes'], type: 'text' ,  className:'medium'},
            {config:true, show: true, label:'', className:'mini center', 
            custom:(item)=> {
                return(
                    (canArchive || edit) &&
                        <Row>
                            <ButtonGroup size={'sm'}>
                                <DropdownButton className={'dp-custom'} drop={'left'} as={ButtonGroup} title={''}
                                                id={'bg-nested-dropdown'}>
                                    {
                                        canArchive &&
                                        <Dropdown.Item eventKey={'1'} onClick={() => {
                                            this.props.changeArchivedStatus(item.id)
                                        }}>Archivar</Dropdown.Item>
                                    }
                                    {
                                        edit &&
                                        <Dropdown.Item eventKey={'2'}
                                                       onClick={() => this.goTo(`/editar/${item.id}`)}>Editar</Dropdown.Item>
                                    }
                                </DropdownButton>
                            </ButtonGroup>
                        </Row>
                )
            }}

        ]
    }

    handleCheckBoxChange = (event) => {
        const {name, checked} = event.target;
        let {filters} = this.state;
        filters[name] = checked;

        this.setState({filters});
    };

    handleSwitchChange = (event) => {
        let {filters} = this.state;
        let {id, value} = event.target;
        value = !(value === 'true');
        filters[id] = value;
        this.setState({filters});
    };

    filter = (item) => {
        const {filters, seller, deliverer} = this.state;
        return (
            (
                (
                  (filters.delivery && item.categoryId === 1) ||
                  (filters.transport && item.categoryId === 2) ||
                  (filters.global && item.categoryId === 3) ||
                  (filters.subscription && item.categoryId === 4) ||
                  (filters.kolo && item.categoryId === 5)
                ) &&
                (seller.value ? item.vendorId === seller.value : true) &&
                (deliverer.value ? item.delivererId === deliverer.value : true)
            )
        )
    };

    button = () =>{
        const {module, getPermission} = this.props
        let canArchive = false
        if(module === 1000 && (getPermission(1852))) canArchive = true
        if(module === 2000 && (getPermission(2854))) canArchive = true
        return(
            canArchive &&
                <IconButton tooltip={'Mostrar polígonos archivadas'}
                            icon={faArchive} onClick={() => this.setState({showArchived: true})}
                />
        )
    }

    renderButtons = () => {
        return(
          <div style={{display: 'flex', justifyContent: 'center', marginTop: 15}}>
              <IconButton icon={faSlidersH} color={'#3570A3'}  size={'3x'}
                          onClick={() => this.setState({showFilter: true})}/>
          </div>
        )
    }

    customFilter = () => {
        const { sellers, deliverers} = this.props;
        const { filters, seller, deliverer} = this.state;
        return <div>
            <h6>Categoria</h6>
            <Row>
                <Col>
                    <div className={'d-flex'}>
                        <Switch
                            checked={filters.delivery}
                            id={"delivery"}
                            onChange={this.handleSwitchChange}/>
                        <label className={'checkbox-label'}>Entregas</label>
                    </div>
                </Col>
                <Col>
                    <div className={'d-flex'}>
                        <Switch
                            checked={filters.transport}
                            id={"transport"}
                            onChange={this.handleSwitchChange}/>
                        <label className={'checkbox-label'}>Transporte</label>
                    </div>
                </Col>
                <Col>
                    <div className={'d-flex'}>
                        <Switch
                            checked={filters.global}
                            id={"global"}
                            onChange={this.handleSwitchChange}/>
                        <label className={'checkbox-label'}>Global</label>
                    </div>
                </Col>
                <Col>
                    <div className={'d-flex'}>
                        <Switch
                            checked={filters.subscription}
                            id={"subscription"}
                            onChange={this.handleSwitchChange}/>
                        <label className={'checkbox-label'}>Suscripcion</label>
                    </div>
                </Col>
                <Col>
                    <div className={'d-flex'}>
                        <Switch
                            checked={filters.kolo}
                            id={"kolo"}
                            onChange={this.handleSwitchChange}/>
                        <label className={'checkbox-label'}>KOLO</label>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <CustomSelect
                        label={'Vendedor'}
                        placeholder={'pj. Luis López'}
                        options={[{value: null, label: 'Sin seleccionar'},...sellers]}
                        value={seller}
                        onChange={(seller) => this.setState({seller})}
                    />
                </Col>
                <Col>
                    <CustomSelect
                        label={'Repartidor'}
                        placeholder={'pj. Luis López'}
                        options={[{value: null, label: 'Sin seleccionar'},...deliverers]}
                        value={deliverer}
                        onChange={(deliverer) => this.setState({deliverer})}
                    />
                </Col>
            </Row>
            <br/>
        </div>
    }

    render() {
        const {polygonsAreLoading, archived, isAllowed} = this.props;
        const {polygons, alert, showArchived} = this.state;

        const create = isAllowed([2852, 1852, 11852]);

        return (
            <div style={{paddingBottom: 50}}>
                <Title
                  title={'Polígonos'}
                  action={create}
                  to={'nuevo'}
                  actionTitle={'Crear nuevo polígono'}
                />
                <Row>
                    <Col lg={4}>
                        <MapCard
                            startCollapsed={window.screen.width < 480}
                            address={'Vista Previa'}
                            latitude={latitude}
                            longitude={longitude}
                            markerList={[]}
                            showMarker={true}
                            yesIWantToUseGoogleMapApiInternals
                            onChildClick={(value,item) => this.onChildClickCallback(value,item)}
                            onGoogleApiLoaded={({ map, maps }) => this.onGoogleApiLoaded(map, maps)}
                            height={window.screen.height-230}
                        />
                    </Col>
                    <Col lg={8}>
                        <Action
                          to={'nuevo'}
                          action={create}
                          actionTitle={'Crear nuevo polígono'}
                        />
                        <Card title={'Polígonos'} button={this.button()} white>
                            <TableV2
                                headers={this.getHeaders()}
                                pSize={1}
                                customFilter = {this.customFilter()}
                                items={polygons.filter(this.filter)}
                                loading={polygonsAreLoading}
                                buttonTitle={this.renderButtons()}
                                mobileAuto
                                storageKey={`polygons`}
                                onClickTr={(e,item)=> {console.log(item);this.rowClicked(e, item);
                                    this.onSelectRow(e, item)}}
                                isSelected={(item)=> this.isSelected(item) }
                                getItems={(item,e)=> {
                                    if(item === null){
                                        this.setState({selectedPolygons: [], polygonCoords:[]},() =>this.onGoogleApiLoaded());
                                    }else{
                                        this.rowClicked(e, item.item)
                                        this.onSelectRow(e, item.item)
                                    }
                                }}
                                
                            />
                            
                        </Card>
                    </Col>
                </Row>

                <ModalTable
                    show={showArchived}
                    title={'Poligonos Archivados'}
                    onHide={() => this.setState({showArchived: false})}
                    headers={['', 'Código', 'Nombre', 'Nota', 'Recuperar']}
                    items={archived}
                    renderRow={(item, index) =>
                        <Tr className={'data'} key={`${index}`}>
                            <Td className={`mini ${item.category === 1 ? 'green' 
                                : item.category === 2 ? 'gray' : item.category === 3 ? 'red' 
                                : item.category === 4 ? 'kolo_pink' : item.category === 5 ? 'kolo_blue' : 'white'}`}>
                                <IconButton tooltip={item.category.description || '---'} icon={faInfoCircle}/></Td>
                            <Td className={'mini'}>{item.code}</Td>
                            <Td className={'medium'}>{item.name}</Td>
                            <Td className={'medium'}>{item.notes || '---'}</Td>
                            <Td>
                                <IconButton icon={faTrashRestore} tooltip={'Recuperar'}
                                            onClick={() => {
                                                this.setState({restore: true})
                                                this.props.changeArchivedStatus(item.id)
                                            }}/>
                            </Td>
                        </Tr>
                    }
                />


                <Alert {...alert}/>
            </div>
        );
    }
}

Clients.defaultProps = {
polygons : []
};

const mapStateToProps = (state) => ({
    polygons: selectAllPolygons(state),
    polygonsAreLoading: loadingSelector([actionTypes.GET_ALL_POLYGONS])(state),
    module: selectCurrentModule(state),
    archived: selectArchivedData(state),
    statusIsLoading: loadingSelector([CommonTypes.CHANGE_STATUS_DATA])(state),
    statusHasError: hasErrorsSelector([CommonTypes.CHANGE_STATUS_DATA])(state),
    statusError: singleErrorSelector([CommonTypes.CHANGE_STATUS_DATA])(state),
    handlerError: (message) => handlerError(message),
    handlerSuccess: (message) => handlerSuccess(message),
    sellers: selectGetSellersToSelect(state),
    deliverers: selectDeliverersToSelect(state),
    getPermission: (id) => getPermission(state, id),
    isAllowed: (ids) => isAllowed(state, ids)
});

const mapDispatchToProps = dispatch => ({
    getAllPolygons: () => dispatch(getAllPolygons()),
    getArchivedPolygons: () => dispatch(getArchivedData('polygons')),
    changeArchivedStatus: (id) => dispatch(changeArchivedStatus('polygons',id)),
    getAllDeliverers: () => dispatch(getAllDeliverers()),
    getAllDeliverersByProducer: () => dispatch(getAllDeliverersByProducer()),
    getSellers: () => dispatch(getAllSellers())
});

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
