import React, { Component } from 'react'

import { ButtonGroup, Col, Dropdown, DropdownButton, Modal, Row } from 'react-bootstrap'
import { Td, Tr } from 'react-super-responsive-table'
import { connect } from 'react-redux'
import Alert from 'sweetalert-react'
import { Line } from 'react-chartjs-2'
import { faArchive, faTrashRestore } from '@fortawesome/free-solid-svg-icons'

import {
  Action,
  Button,
  CustomDate as CustomDatePicker,
  CustomTabs,
  FormTextField,
  Icon as IconButton,
  ModalTable,
  Select as CustomSelect,
  TableV2,
  Title,
} from '../../components'

import { formatDateFromMillis, formatNumberWithCommas } from 'src/utils/formatters'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  actionTypes,
  createItem,
  deleteItem,
  getAccountingItem,
  getAllItems,
  getLogByItem,
  updateItem,
} from 'src/actions/accountbook.actions'

import {
  selectAllItems,
  selectItem,
  selectLogs,
} from 'src/selectors/accountbook.selector'
import { getWarehouses } from 'src/actions/warehouse.actions'
import { selectAllWarehousesForSelect } from 'src/selectors/warehouse.selector'
import { getAccounts } from 'src/actions/banks.actions'
import { selectGetAccountsForSelect } from 'src/selectors/banks.selector'
import { selectAllProductsForSelect } from 'src/selectors/products.selector'
import { actionTypes as typesP, getProducts } from 'src/actions/products.actions'
import {
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'
import {
  changeArchivedStatus,
  getArchivedData,
  types as CommonTypes,
} from 'src/actions/utilities.actions'

import { selectArchivedData } from 'src/selectors/utilities.selector'
import { getPermission } from 'src/selectors/modules.selector'
import { toMoney } from 'src/utils/utilities'
import Money from 'src/components/Money/Money'
import { selectCurrentCurrency } from 'src/selectors/currencies.selector'

const chartOptions = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          callback: function (label) {
            return label > 999999
              ? label / 100000000 + 'M'
              : label > 999
              ? label / 1000 + 'k'
              : label
          },
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || ''
        let formatCurrency = amount => {
          if (amount === null) return ''
          return toMoney(amount)
        }

        if (label) {
          label += ': '
        }
        label += formatCurrency(tooltipItem.yLabel)
        return label
      },
    },
  },
  legend: {
    position: 'left',
  },
}

const chartOptionsMobile = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          callback: function (label) {
            return label > 999999
              ? label / 100000000 + 'M'
              : label > 999
              ? label / 1000 + 'k'
              : label
          },
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || ''
        let formatCurrency = amount => {
          if (amount === null) return ''
          return toMoney(amount)
        }

        if (label) {
          label += ': '
        }
        label += formatCurrency(tooltipItem.yLabel)
        return label
      },
    },
  },
  legend: {
    position: 'top',
  },
}

class AccountingBook extends Component {
  state = {
    createItem: false,
    showArchived: false,
    showLogs: false,
    restore: false,
    alert: {
      show: false,
      title: 'default',
      text: 'default',
      type: 'info',
      onConfirm: () => console.log('default'),
    },
    newItem: {
      name: '',
      initialValue: 0,
      finalValue: 0,
      acquisitionDate: new Date(),
      expirationDate: new Date(),
      type: 0,
      parentId: 0,
      parentType: 0,
      tax: 0,
    },
    types: [
      { value: 1, label: 'Cuenta bancaria' },
      { value: 2, label: 'Bodega' },
      { value: 3, label: 'Producto' },
    ],
    typeValue: { value: 0, label: 'Seleccione una referencia' },
    parentValue: { value: 0, label: 'Seleccione una cuenta' },
    errors: {},
    selected: {},
    selectedItems: [],
    active: true,
  }

  componentDidMount() {
    this.props.getAllItems()
    this.props.getAllProducts()
    this.props.getWarehouses()
    this.props.getAccounts()
    this.props.getArchivedItem()
  }

  UNSAFE_componentWillReceiveProps(next) {
    const {
      createIsLoading,
      createHasError,
      statusIsLoading,
      statusHasError,
      handlerError,
      handlerSuccess,
      deleteIsLoading,
      deleteHasError,
      loadingLogs,
      updateIsLoading,
      updateHasError,
      itemsAreLoading,
      getPermission,
    } = this.props
    const { createError, statusError, deleteError, updateError, items } = next
    let { alert, restore } = this.state

    if (itemsAreLoading && !next.itemsAreLoading) {
      let archive = getPermission(12030) || getPermission(12023) || getPermission(12016)
      let del = getPermission(12031) || getPermission(12024) || getPermission(12017)
      let edit = getPermission(12028) || getPermission(12021) || getPermission(12014)
      items.map(item => {
        item.delete = del
        item.archive = archive
        item.edit = edit
        item.estimate = Number(
          this.estimate(
            item.updatedDate || item.initialDate,
            item.finalDate,
            item.estimateValue,
            item.finalValue,
          ),
        ).toFixed(2)
      })
    }

    if (loadingLogs && !next.loadingLogs) {
      this.setState({ showLogs: true })
    }

    if (createIsLoading && !next.createIsLoading) {
      if (!createHasError && next.createHasError) {
        alert = handlerError(createError.message || 'Error al crear el item')
        alert.onConfirm = () => {
          this.setState({ alert: { ...alert, show: false } })
        }
      } else {
        alert = handlerSuccess('Item creado exitosamente')
        alert.onConfirm = () => {
          this.setState({
            alert: { ...alert, show: false },
            createItem: false,
          })
          this.restartItem()
          this.props.getAllItems()
        }
      }
      this.setState({ alert })
    }

    if (statusIsLoading && !next.statusIsLoading) {
      if (!statusHasError && next.statusHasError) {
        alert = handlerError(
          statusError.response
            ? statusError.response.data.message
            : 'ha ocurrido un error inesperado.',
        )
        alert.onConfirm = () => {
          this.setState({ alert: { ...alert, show: false } })
        }
      } else {
        if (restore) alert = handlerSuccess('Registro recuperado')
        else alert = handlerSuccess('Registro archivado')
        alert.onConfirm = () => {
          this.setState({ alert: { ...alert, show: false }, restore: false })
          this.props.getAllItems()
          this.props.getArchivedItem()
        }
      }
      this.setState({ alert })
    }

    if (deleteIsLoading && !next.deleteIsLoading) {
      if (!deleteHasError && next.deleteHasError) {
        alert = handlerError(
          deleteError.response
            ? deleteError.response.data.message
            : 'ha ocurrido un error inesperado.',
        )
        alert.onConfirm = () => {
          this.setState({ alert: { ...alert, show: false } })
        }
      } else {
        alert = handlerSuccess('Registro eliminado')
        alert.onConfirm = () => {
          this.setState({ alert: { ...alert, show: false }, restore: false })
          this.props.getAllItems()
        }
      }
      this.setState({ alert })
    }

    if (updateIsLoading && !next.updateIsLoading) {
      if (!updateHasError && next.updateHasError) {
        alert = handlerError(
          updateError.response
            ? updateError.response.data.message
            : 'ha ocurrido un error inesperado.',
        )
        alert.onConfirm = () => {
          this.setState({ alert: { ...alert, show: false } })
        }
      } else {
        alert = handlerSuccess('Modificación exitosa')
        alert.onConfirm = () => {
          this.setState({ alert: { ...alert, show: false }, restore: false })
          this.props.getAllItems()
        }
      }
      this.setState({ alert })
    }
  }

  handleCheckBoxChange = event => {
    const { name, checked } = event.target
    this.setState({ [name]: checked, page: 1 })
  }

  onDateChange = (from, to) => {
    this.setState({ startDate: from, endDate: to })
  }

  handleInputChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  handleCurrencyItemChange = (event, maskValue, value) => {
    const { name } = event.target
    const { newItem } = this.state
    this.setState({ newItem: { ...newItem, [name]: value } })
  }

  handleItemChange = event => {
    const { name, value } = event.target
    const { newItem } = this.state
    this.setState({ newItem: { ...newItem, [name]: value } })
  }

  save = () => {
    const { newItem } = this.state
    let {
      acquisitionDate,
      expirationDate,
      finalValue,
      initialValue,
      name,
      parentId,
      parentType,
      tax,
      type,
    } = newItem

    let errors = {}
    if (!initialValue) errors.initialValue = 'Este campo es requerido'
    if (!acquisitionDate) errors.acquisitionDate = 'Este campo es requerido'
    if (!name) errors.name = 'Este campo es requerido'
    if (type === 1) {
      if (!expirationDate) errors.expirationDate = 'Este campo es requerido'
      if (finalValue < 0) errors.finalValue = 'Este campo es requerido'
      if (!parentType) errors.parentType = 'Este campo es requerido'
      if (!parentId) errors.parentId = 'Este campo es requerido'
    } else {
      if (!tax) errors.tax = 'Este campo es requerido'
    }

    if (Object.keys(errors).length === 0) {
      let request = {
        ...newItem,
        acquisitionDate: acquisitionDate.valueOf(),
        expirationDate: expirationDate.valueOf(),
      }
      this.props.saveItem(request)
    }
    this.setState({ errors })
  }

  restartItem = () => {
    this.setState({
      newItem: {
        name: '',
        initialValue: 0,
        finalValue: 0,
        acquisitionDate: new Date(),
        expirationDate: new Date(),
        type: 0,
        parentId: 0,
        parentType: 0,
        tax: 0,
      },
    })
  }

  estimate = (x0, xf, y0, yf) => {
    let x = new Date().valueOf()
    if (x > xf) return yf
    //y = m(x-x0)+y0
    let m = (yf - y0) / (xf - x0)
    return m * (x - x0) + y0
  }

  isSelected = id => {
    const { selectedItems } = this.state
    let item = selectedItems.filter(p => p.id === id)
    return item.length > 0 ? item[0] : null
  }

  renderAsset = (item, index) => {
    const { selectedItems } = this.state
    let selected = this.isSelected(item.id)
    return (
      <Tr className={'data'} key={`${index}`}>
        <Td className={'medium'}>{item.name}</Td>
        <Td className={'medium'}>{formatDateFromMillis(item.initialDate)}</Td>
        <Td>{formatNumberWithCommas(item.initialValue)}</Td>
        <Td className={'medium'}>{formatDateFromMillis(item.finalDate)}</Td>
        <Td>{formatNumberWithCommas(item.finalValue)}</Td>
        <Td style={{ color: 'green' }}>
          {selected ? (
            <FormTextField
              type={'number'}
              value={selected.newValue}
              onMouseOver={e => e.stopPropagation()}
              onClick={e => e.stopPropagation()}
              onChange={event => {
                event.stopPropagation()
                selectedItems.map(p => {
                  if (p.id === item.id) p.newValue = event.target.value
                })
                this.setState({ selectedItems })
              }}
              className={'edit-input'}
            />
          ) : (
            formatNumberWithCommas(item.estimate)
          )}
        </Td>
        <Td className={'medium'}>{item.parent}</Td>
        <Td className={'mini'}>
          <ButtonGroup size={'sm'}>
            <DropdownButton
              className={'dp-custom'}
              drop={'left'}
              as={ButtonGroup}
              title={''}
              id={'bg-nested-dropdown'}>
              {item.edit && (
                <Dropdown.Item
                  eventKey={'1'}
                  onClick={() => {
                    selectedItems.push({
                      ...item,
                      prevValue: item.estimate,
                      newValue: item.estimate,
                    })
                    this.setState({ selectedItems })
                  }}>
                  Editar
                </Dropdown.Item>
              )}

              {item.archive && (
                <Dropdown.Item
                  eventKey={'2'}
                  onClick={() => {
                    this.props.changeArchivedStatus(item.id)
                  }}>
                  Archivar
                </Dropdown.Item>
              )}
              <Dropdown.Item
                eventKey={'3'}
                onClick={() => {
                  this.props.getLogByItem(item.id)
                  this.setState({ selected: item })
                }}>
                Ver log
              </Dropdown.Item>
              {item.delete && (
                <>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    eventKey={'4'}
                    onClick={() => this.props.deleteItem(item.id)}>
                    Eliminar
                  </Dropdown.Item>
                </>
              )}
            </DropdownButton>
          </ButtonGroup>
        </Td>
      </Tr>
    )
  }

  button = () => {
    return (
      <IconButton
        tooltip={'Mostrar registros archivados'}
        icon={faArchive}
        onClick={() => this.setState({ showArchived: true })}
      />
    )
  }

  edit = () => {
    const { selectedItems } = this.state
    selectedItems.map(s => {
      s.acquisitionDate = s.initialDate
      s.expirationDate = s.finalDate
    })
    this.props.updateItem(selectedItems)
    this.setState({ selectedItems: [] })
  }

  getAssetsSum = () => {
    const { items, getPermission } = this.props
    if (getPermission(12029) || getPermission(12022) || getPermission(12015)) {
      let total = 0
      items.map(item => {
        if (item.type === 1) total += Number(item.estimate)
      })
      return formatNumberWithCommas(total)
    }
    return formatNumberWithCommas(0)
  }

  getLiabilitySum = () => {
    const { items, getPermission } = this.props
    if (getPermission(12029) || getPermission(12022) || getPermission(12015)) {
      let total = 0
      items.map(i => {
        if (i.type === 2) total += Number(i.initialValue + (i.initialValue * i.tax) / 100)
      })
      return formatNumberWithCommas(total)
    }
    return formatNumberWithCommas(0)
  }

  createRandomColor = () => {
    return `${parseInt((Math.random() * 240).toString())}, ${parseInt(
      (60 + Math.random() * 240).toString(),
    )}, ${parseInt((80 + Math.random() * 240).toString())}`
  }

  getChartData = type => {
    const { items } = this.props
    let labels = [
      'Periodo inicial',
      formatDateFromMillis(new Date().valueOf()),
      'Periodo final',
    ]
    let data = items.filter(i => i.type === type)
    return {
      labels: labels,
      datasets: data.map(r => {
        let color = this.createRandomColor()
        return {
          data: [r.initialValue, r.estimate, r.finalValue],
          label: r.name,
          backgroundColor: `rgba(${color},0.6)`,
          borderColor: `rgb(${color})`,
        }
      }),
    }
  }

  getHeaders = () => {
    return [
      { label: 'Nombre', show: true, value: ['name'], type: 'text', className: 'medium' },
      {
        label: 'Fecha de adquisición',
        show: true,
        value: ['initialDate'],
        type: 'date',
        className: 'medium',
      },
      {
        label: `Valor inicial`,
        show: true,
        value: ['initialValue'],
        type: 'currency',
        className: 'mini',
      },
      {
        label: `Fecha de caducidad`,
        show: true,
        value: ['finalDate'],
        type: 'date',
        className: 'medium',
      },
      {
        label: `Valor final `,
        show: true,
        value: ['finalValue'],
        type: 'currency',
        className: 'mini',
      },
      {
        label: `Valor estimado`,
        show: true,
        value: ['estimate'],
        type: 'currency',
        className: 'mini',
        custom: item => {
          const { selectedItems } = this.state
          let selected = this.isSelected(item.id)
          return selected ? (
            <FormTextField
              type={'number'}
              value={selected.newValue}
              onMouseOver={e => e.stopPropagation()}
              onClick={e => e.stopPropagation()}
              onChange={event => {
                event.stopPropagation()
                selectedItems.map(p => {
                  if (p.id === item.id) p.newValue = event.target.value
                })
                this.setState({ selectedItems })
              }}
              className={'edit-input'}
            />
          ) : (
            formatNumberWithCommas(item.estimate)
          )
        },
      },
      {
        label: `Referencia`,
        show: true,
        value: ['parent'],
        type: 'text',
        className: 'medium',
      },
      {
        config: true,
        show: true,
        label: '',
        className: 'mini',
        custom: item => {
          const { selectedItems } = this.state
          return (
            <ButtonGroup size={'sm'}>
              <DropdownButton
                className={'dp-custom'}
                drop={'left'}
                as={ButtonGroup}
                title={''}
                id={'bg-nested-dropdown'}>
                {item.edit && (
                  <Dropdown.Item
                    eventKey={'1'}
                    onClick={() => {
                      selectedItems.push({
                        ...item,
                        prevValue: item.estimate,
                        newValue: item.estimate,
                      })
                      this.setState({ selectedItems })
                    }}>
                    Editar
                  </Dropdown.Item>
                )}

                {item.archive && (
                  <Dropdown.Item
                    eventKey={'2'}
                    onClick={() => {
                      this.props.changeArchivedStatus(item.id)
                    }}>
                    Archivar
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  eventKey={'3'}
                  onClick={() => {
                    this.props.getLogByItem(item.id)
                    this.setState({ selected: item })
                  }}>
                  Ver log
                </Dropdown.Item>
                {item.delete && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      eventKey={'4'}
                      onClick={() => this.props.deleteItem(item.id)}>
                      Eliminar
                    </Dropdown.Item>
                  </>
                )}
              </DropdownButton>
            </ButtonGroup>
          )
        },
      },
    ]
  }
  getHeadersActivo = () => {
    return [
      {
        label: 'Responsable',
        show: true,
        value: ['user'],
        type: 'text',
        className: 'medium',
      },
      {
        label: 'Acción',
        show: true,
        value: ['action'],
        type: 'text',
        className: 'medium',
      },
      { label: `Fecha`, show: true, value: ['date'], type: 'date', className: 'mini' },
      {
        label: `Valor anterior`,
        show: true,
        value: ['prevValue'],
        type: 'currency',
        className: 'mini',
      },
      {
        label: `Valor nuevo`,
        show: true,
        value: ['entryType'],
        type: 'text',
        className: 'mini',
      },
      {
        label: `Descripción`,
        show: true,
        value: ['newValue'],
        type: 'currency',
        className: 'medium',
      },
      { config: true, show: true, label: '', className: 'mini' },
    ]
  }
  render() {
    const {
      items,
      itemsAreLoading,
      products,
      warehouses,
      accounts,
      archived,
      logs,
      currentCurrency,
    } = this.props
    const {
      createItem,
      alert,
      newItem,
      types,
      typeValue,
      parentValue,
      showArchived,
      errors,
      showLogs,
      selected,
      selectedItems,
      active,
    } = this.state
    let {
      acquisitionDate,
      expirationDate,
      finalValue,
      initialValue,
      name,
      tax,
      type,
      parentType,
    } = newItem
    return (
      <div>
        <Title
          title={'Activos y Pasivos'}
          action
          onClick={() =>
            active
              ? this.setState({
                  newItem: { ...newItem, type: 1 },
                  createItem: true,
                })
              : this.setState({
                  newItem: { ...newItem, type: 2 },
                  createItem: true,
                })
          }
          actionTitle={active ? 'Agregar Activo' : 'Agregar Pasivo'}
        />

        <Row>
          <Col>
            <div className={'column'}>
              <CustomTabs
                setKey={key => this.setState({ active: parseInt(key) === 0 })}
                loading={itemsAreLoading}
                items={[
                  {
                    title: `Activos (${toMoney(this.getAssetsSum())})`,
                    info: 'Activos registrados dentro de la empresa',
                    component: (
                      <>
                        <br />
                        <Row style={{ justifyContent: 'flex-end' }}>
                          <Action
                            action
                            onClick={() =>
                              this.setState({
                                newItem: { ...newItem, type: 1 },
                                createItem: true,
                              })
                            }
                            actionTitle={'Agregar Activo'}
                          />
                          {selectedItems.length > 0 && (
                            <Col
                              sm={12}
                              md={3}
                              className={'big-button kolo_green'}
                              style={{ margin: '0 5px' }}
                              onClick={() => this.edit()}>
                              Guardar
                            </Col>
                          )}
                          {selectedItems.length > 0 && (
                            <Col
                              sm={12}
                              md={3}
                              className={'big-button kolo_red'}
                              style={{ margin: '0 5px' }}
                              onClick={() => this.setState({ selectedItems: [] })}>
                              Cancelar
                            </Col>
                          )}
                        </Row>
                        <Row style={{ alignItems: 'center' }}>
                          <Col lg={6}>
                            <div className={'column'}>
                              <TableV2
                                storageKey={`activosBook`}
                                mobileAuto
                                headers={this.getHeaders()}
                                items={items.filter(f => f.type === 1)}
                                loading={itemsAreLoading}
                                noItemsLegend={'Sin activos registrados'}
                                tableDivStyle={{ minHeight: '450px', maxHeight: '600px' }}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div style={{ textAlign: 'center', marginTop: 16 }}>
                              <Money component="h4" className={'kolo-company'}>
                                Sumatoria de Activos: {this.getAssetsSum()}
                              </Money>
                            </div>
                            <>
                              <div className="ru-graph-w">
                                <Line
                                  data={this.getChartData(1)}
                                  height={200}
                                  options={chartOptions}
                                />
                              </div>
                              <div className="ru-graph-m">
                                <Line
                                  data={this.getChartData(1)}
                                  height={400}
                                  options={chartOptionsMobile}
                                />
                              </div>
                            </>
                          </Col>
                        </Row>
                      </>
                    ),
                  },
                  {
                    title: `Pasivos (${toMoney(this.getLiabilitySum())})`,
                    info: 'Pasivos registrados dentro de la empresa',
                    component: (
                      <div className={'column'}>
                        <br />
                        <Row style={{ justifyContent: 'flex-end' }}>
                          <Action
                            action
                            onClick={() =>
                              this.setState({
                                newItem: { ...newItem, type: 2 },
                                createItem: true,
                              })
                            }
                            actionTitle={'Agregar Pasivo'}
                          />
                          {selectedItems.length > 0 && (
                            <Col
                              sm={12}
                              md={3}
                              className={'big-button kolo_green'}
                              style={{ margin: '0 5px' }}
                              onClick={() => this.edit()}>
                              Guardar
                            </Col>
                          )}
                          {selectedItems.length > 0 && (
                            <Col
                              sm={12}
                              md={3}
                              className={'big-button kolo_red'}
                              style={{ margin: '0 5px' }}
                              onClick={() => this.setState({ selectedItems: [] })}>
                              Cancelar
                            </Col>
                          )}
                        </Row>
                        <Row style={{ alignItems: 'center' }}>
                          <Col lg={6}>
                            <TableV2
                              mobileAuto={true}
                              storageKey={`pasivosBook`}
                              headers={[
                                {
                                  label: 'Nombre',
                                  show: true,
                                  value: ['name'],
                                  type: 'text',
                                  className: 'medium',
                                },
                                {
                                  label: 'Fecha de adquisición',
                                  show: true,
                                  value: ['acquisitionDate'],
                                  type: 'date',
                                  className: 'medium',
                                },
                                {
                                  label: `Valor `,
                                  show: true,
                                  value: ['initialValue'],
                                  type: 'currency',
                                  className: 'mini',
                                },
                                {
                                  label: 'Intereses',
                                  show: true,
                                  value: ['tax'],
                                  type: 'number',
                                  className: 'mini',
                                  custom: item => {
                                    return formatNumberWithCommas(item.tax) + '%'
                                  },
                                },
                                {
                                  label: '',
                                  config: true,
                                  show: true,
                                  className: 'mini',
                                  custom: item => (
                                    <ButtonGroup size={'sm'}>
                                      <DropdownButton
                                        className={'dp-custom'}
                                        drop={'left'}
                                        as={ButtonGroup}
                                        title={''}
                                        id={'bg-nested-dropdown'}>
                                        {/*<Dropdown.Item eventKey={'1'} onClick={()=> this.goTo(`/editar/${item.id}`)}>Editar</Dropdown.Item>*/}
                                        {item.archive && (
                                          <Dropdown.Item
                                            eventKey={'2'}
                                            onClick={() => {
                                              this.props.changeArchivedStatus(item.id)
                                            }}>
                                            Archivar
                                          </Dropdown.Item>
                                        )}
                                        <Dropdown.Item
                                          eventKey={'3'}
                                          onClick={() => {
                                            this.props.getLogByItem(item.id)
                                          }}>
                                          Ver log
                                        </Dropdown.Item>
                                        {item.delete && (
                                          <>
                                            <Dropdown.Divider />
                                            <Dropdown.Item
                                              eventKey={'4'}
                                              onClick={() =>
                                                this.props.deleteItem(item.id)
                                              }>
                                              Eliminar
                                            </Dropdown.Item>
                                          </>
                                        )}
                                      </DropdownButton>
                                    </ButtonGroup>
                                  ),
                                },
                              ]}
                              items={items.filter(f => f.type === 2)}
                              loading={itemsAreLoading}
                              noItemsLegend={'Sin pasivos registrados'}
                              tableDivStyle={{
                                overflowX: 'scroll',
                                minHeight: '450px',
                                maxHeight: '600px',
                              }}
                            />
                            {/* <PaginatedTable
                                                        headers={['Nombre', 'Fecha de adquisición', `Valor (${currentCurrency.symbol})`, 'Intereses']}
                                                        items={items.filter(f => f.type == 2)}
                                                        renderRow={this.renderLiability}
                                                        loading={itemsAreLoading}
                                                        noItemsLegend={'Sin pasivos registrados'}
                                                        tableDivStyle={{overflowX:"scroll",minHeight:"450px",maxHeight:"600px"}}
                                                    /> */}
                          </Col>
                          <Col lg={6}>
                            <div style={{ textAlign: 'center', marginTop: 16 }}>
                              <Money component="h4" className={'kolo-company'}>
                                Sumatoria de Pasivos: {this.getLiabilitySum()}
                              </Money>
                            </div>
                            <>
                              <div className="ru-graph-w">
                                <Line
                                  data={this.getChartData(2)}
                                  height={200}
                                  options={chartOptions}
                                />
                              </div>
                              <div className="ru-graph-m">
                                <Line
                                  data={this.getChartData(2)}
                                  height={400}
                                  options={chartOptions}
                                />
                              </div>
                            </>
                          </Col>
                        </Row>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </Col>
        </Row>

        <Modal
          show={createItem}
          size={'lg'}
          centered
          onHide={() => {
            this.setState({ createItem: false })
            this.restartItem()
          }}>
          <Modal.Header closeButton>
            <Modal.Title>{type === 1 ? 'Nuevo Activo' : 'Nuevo Pasivo'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Row>
                <Col xs={12} sm={12} md={4} xl={4}>
                  <FormTextField
                    label={'Nombre'}
                    type={'text'}
                    placeholder={'Nombre'}
                    value={name}
                    name={'name'}
                    onChange={this.handleItemChange}
                    required
                    error={errors.name}
                  />
                </Col>
                <Col xs={12} sm={12} md={4} xl={4}>
                  <CustomDatePicker
                    label={'Fecha de adquisición:'}
                    value={acquisitionDate}
                    onDayChange={date => {
                      if (date) {
                        this.setState({
                          newItem: { ...newItem, acquisitionDate: date },
                        })
                      }
                    }}
                    required
                    error={errors.acquisitionDate}
                  />
                </Col>
                {type === 1 ? (
                  <Col xs={12} sm={12} md={4} xl={4}>
                    <CustomDatePicker
                      label={'Fecha de expiración:'}
                      value={expirationDate}
                      onDayChange={date => {
                        if (date) {
                          this.setState({
                            newItem: { ...newItem, expirationDate: date },
                          })
                        }
                      }}
                      disabledDays={{ before: acquisitionDate }}
                      required
                      error={errors.expirationDate}
                    />
                  </Col>
                ) : (
                  <Col xs={12} sm={12} md={4} xl={4}>
                    <FormTextField
                      label={'Valor de adquicisión'}
                      type={'number'}
                      placeholder={'Valor'}
                      name={'initialValue'}
                      value={initialValue}
                      onChange={this.handleCurrencyItemChange}
                      currency
                      error={errors.initialValue}
                      required
                    />
                  </Col>
                )}
              </Row>
              {type === 1 ? (
                <>
                  <Row>
                    <Col xs={12} sm={12} md={4} xl={4}>
                      <FormTextField
                        label={'Valor de adquicisión'}
                        type={'number'}
                        placeholder={'Valor'}
                        name={'initialValue'}
                        value={initialValue}
                        onChange={this.handleCurrencyItemChange}
                        currency
                        error={errors.initialValue}
                        required
                      />
                    </Col>
                    <Col xs={12} sm={12} md={4} xl={4}>
                      <FormTextField
                        label={'Valor de expiración'}
                        type={'number'}
                        placeholder={'Valor'}
                        name={'finalValue'}
                        value={finalValue}
                        onChange={this.handleCurrencyItemChange}
                        error={errors.finalValue}
                        currency
                        required
                      />
                    </Col>
                    <Col>
                      <CustomSelect
                        label={'Referencia'}
                        options={types}
                        value={typeValue}
                        onChange={type => {
                          let val
                          if (type.value === 1)
                            val = { value: 0, label: 'Seleccione una cuenta' }
                          else if (type.value === 2)
                            val = { value: 0, label: 'Seleccione una bodega' }
                          else val = { value: 0, label: 'Seleccione un producto' }
                          this.setState({
                            parentValue: val,
                            typeValue: type,
                            newItem: { ...newItem, parentType: type.value },
                          })
                        }}
                        error={errors.parentType}
                        required
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CustomSelect
                        label={
                          parentType === 1
                            ? 'Cuenta de banco'
                            : parentType === 2
                            ? 'Bodega'
                            : 'Producto'
                        }
                        options={
                          parentType === 1
                            ? accounts
                            : parentType === 2
                            ? warehouses
                            : products
                        }
                        value={parentValue}
                        onChange={type => {
                          this.setState({
                            parentValue: type,
                            newItem: { ...newItem, parentId: type.value },
                          })
                        }}
                        error={errors.parentId}
                        required
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                <Row>
                  <Col xs={12} sm={12} md={4} xl={4}>
                    <FormTextField
                      label={'Interés (%)'}
                      type={'number'}
                      placeholder={'Porcentaje'}
                      name={'tax'}
                      value={tax}
                      onChange={this.handleItemChange}
                      error={errors.tax}
                      required
                    />
                  </Col>
                </Row>
              )}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Row className={'container-buttons'}>
              <Button onClick={() => this.setState({ createItem: false })}>Cerrar</Button>
              <Button onClick={() => this.save()}>Guardar</Button>
            </Row>
          </Modal.Footer>
        </Modal>

        <ModalTable
          show={showArchived}
          title={'Elementos Archivados'}
          size={'lg'}
          onHide={() => this.setState({ showArchived: false })}
          headers={[
            'Tipo',
            'Nombre',
            'Fecha de adquisición',
            `Valor inicial (${currentCurrency.symbol})`,
          ]}
          items={archived}
          renderRow={(item, index) => (
            <Tr className={'data'} key={`${index}`}>
              <Td className={'mini'}>{item.type === 1 ? 'Activo' : 'Pasivo'}</Td>
              <Td className={'medium'}>{item.name}</Td>
              <Td className={'medium'}>{formatDateFromMillis(item.initialDate)}</Td>
              <Td>{formatNumberWithCommas(item.initialValue)}</Td>
              <Td className={'mini'}>
                <IconButton
                  icon={faTrashRestore}
                  tooltip={'Recuperar'}
                  onClick={() => {
                    this.setState({ restore: true })
                    this.props.changeArchivedStatus(item.id)
                  }}
                />
              </Td>
            </Tr>
          )}
        />

        <Modal
          show={showLogs}
          size={'lg'}
          centered
          onHide={() => {
            this.setState({ showLogs: false, selected: {} })
          }}>
          <Modal.Header closeButton>
            <Modal.Title>{selected.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="text-center">
              <Col>
                <b>Fecha de adquisición</b>
                <br />
                {formatDateFromMillis(selected.acquisitionDate)}
              </Col>
              <Col>
                <b>Valor estimado</b> <br />{' '}
                {formatNumberWithCommas(
                  this.estimate(
                    selected.updatedDate || selected.initialDate,
                    selected.finalDate,
                    selected.estimateValue,
                    selected.finalValue,
                  ),
                )}
              </Col>
              <Col>
                <b>Fecha de caducidad</b> <br />{' '}
                {formatDateFromMillis(selected.expirationDate)}
              </Col>
            </Row>
            <TableV2
              headers={this.getHeadersActivo()}
              items={logs}
              tableDivStyle={{
                overflowX: 'scroll',
                minHeight: '450px',
                maxHeight: '600px',
              }}
              mobileAuto={true}
              storageKey={`logs`}
              customClass={'scroll-x-without-height'}
            />
          </Modal.Body>
          <Modal.Footer />
        </Modal>

        <Alert {...alert} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  items: selectAllItems(state),
  item: selectItem(state),
  logs: selectLogs(state),
  loadingLogs: loadingSelector([actionTypes.GET_LOG])(state),
  warehouses: selectAllWarehousesForSelect(state),
  itemsAreLoading: loadingSelector([actionTypes.GET_ALL_ITEMS])(state),
  createIsLoading: loadingSelector([actionTypes.CREATE_ITEM])(state),
  createHasError: hasErrorsSelector([actionTypes.CREATE_ITEM])(state),
  createError: singleErrorSelector([actionTypes.CREATE_ITEM])(state),
  accounts: selectGetAccountsForSelect(state),
  products: selectAllProductsForSelect(state),
  loadingP: loadingSelector([typesP.GET_ALL_PRODUCTS])(state),
  archived: selectArchivedData(state),
  statusIsLoading: loadingSelector([CommonTypes.CHANGE_STATUS_DATA])(state),
  statusHasError: hasErrorsSelector([CommonTypes.CHANGE_STATUS_DATA])(state),
  statusError: singleErrorSelector([CommonTypes.CHANGE_STATUS_DATA])(state),
  deleteIsLoading: loadingSelector([actionTypes.DELETE_ITEM])(state),
  deleteHasError: hasErrorsSelector([actionTypes.DELETE_ITEM])(state),
  deleteError: singleErrorSelector([actionTypes.DELETE_ITEM])(state),
  updateIsLoading: loadingSelector([actionTypes.UPDATE_ITEM])(state),
  updateHasError: hasErrorsSelector([actionTypes.UPDATE_ITEM])(state),
  updateError: singleErrorSelector([actionTypes.UPDATE_ITEM])(state),
  handlerError: message => handlerError(message),
  handlerSuccess: message => handlerSuccess(message),
  getPermission: id => getPermission(state, id),
  currentCurrency: selectCurrentCurrency(state),
})

const mapDispatchToProps = dispatch => ({
  getAllItems: () => dispatch(getAllItems()),
  getItem: id => dispatch(getAccountingItem(id)),
  saveItem: request => dispatch(createItem(request)),
  deleteItem: (id, request) => dispatch(deleteItem(id, request)),
  updateItem: list => dispatch(updateItem(list)),
  getWarehouses: () => dispatch(getWarehouses(false, false)),
  getAccounts: () => dispatch(getAccounts()),
  getAllProducts: () => dispatch(getProducts()),
  getArchivedItem: () => dispatch(getArchivedData('accounting')),
  changeArchivedStatus: id => dispatch(changeArchivedStatus('accounting', id)),
  getLogByItem: id => dispatch(getLogByItem(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountingBook)
