export const selectFilesRaw = state => state.uploads.files
export const selectFilesForGallery = state =>
  state.uploads.files
    .filter(f => !f.video)
    .map(item => ({
      id: item.id,
      original: item.url,
      thumbnail: item.url,
      name: item.name,
      description: item.description,
      companyId: item.companyId,
      imageTypeId: item.imageTypeId,
      parentId: item.parentId,
    }))
export const selectFilesForGallery2 = state =>
  state.uploads.files2.map(item => ({
    id: item.id,
    original: item.url,
    thumbnail: item.url,
    name: item.name,
    description: item.description,
    companyId: item.companyId,
    imageTypeId: item.imageTypeId,
    parentId: item.parentId,
  }))

export const selectVideos = state => state.uploads.files.filter(f => f.video)

export const selectFilesForCarousel = state =>
  state.uploads.photos.map(item => ({
    id: item.id,
    original: item.url,
    thumbnail: item.url,
    name: item.name,
    description: item.description,
    companyId: item.companyId,
    imageTypeId: item.imageTypeId,
    parentId: item.parentId,
  }))

export const selectLogo = state => state.uploads.logo
export const selectDocuments = state => state.uploads.documents
