import React from 'react'
import { HorizontalBar, Bar } from 'react-chartjs-2'
import { ChartType } from 'src/enums/ChartType'
import { getChartConfig } from 'src/utils/getChartConfig'

interface Props {
  data: IDataGraph
  tooltips?: ITooltipGraph
  type: ChartType
  yAxisName: string
  height?: number
}

/**
 * Line Chart component
 * @component
 * @param {IDataGraph} data List of items to display
 * @param {ITooltipGraph} tooltips Tooltip configuration
 * @param {number} height Height of the graph
 * @param {number} type Type of the graph
 * @param {string} yAxisName Name of the Y axis, substitutes for the values in y
 */
export const BarChart = ({
  height = 375,
  data,
  tooltips = {},
  type,
  yAxisName,
}: Props) => {
  const chartOptions = getChartConfig(type, yAxisName)

  return type === ChartType.HORIZONTAL_BAR ? (
    <HorizontalBar
      redraw
      data={data}
      height={height}
      options={{ ...chartOptions, tooltips }}
    />
  ) : (
    <Bar data={data} height={height} options={{ ...chartOptions, tooltips }} />
  )
}
