import React from 'react'
import { toMoney } from 'src/utils/utilities'

function Money({
  children,
  component = 'div',
  noComponent = false,
  noSymbol = false,
  decimals = 2,
  ...props
}) {
  const Tag = noComponent ? React.Fragment : component
  props = noComponent ? {} : props

  const formatChildren = children => {
    children = children ?? 0

    if (!isNaN(children)) return toMoney(children, noSymbol, decimals)
    if (Array.isArray(children)) return children.map(formatChildren)
    if (typeof children === 'object' && children.props)
      return {
        ...children,
        props: {
          ...children.props,
          children: formatChildren(children.props.children),
        },
      }

    return children
  }

  return (
    <Tag style={{ whiteSpace: 'nowrap' }} {...props}>
      {formatChildren(children)}
    </Tag>
  )
}

export default Money
