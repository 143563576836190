import { createContext } from 'react'
import { MovementFiltersContext } from './MovementFiltersContext'

const defaultBankAccount: IBankAccount = {
  accountNumber: 0,
  active: false,
  asigned: undefined,
  balance: 0,
  bank: undefined,
  company: 0,
  createdAt: undefined,
  createdBy: undefined,
  createdData: undefined,
  currency: undefined,
  deleted: false,
  deletedAt: undefined,
  deletedBy: 0,
  deletedData: undefined,
  disbursement: undefined,
  editable: undefined,
  id: undefined,
  kolo: false,
  module: 0,
  name: '',
  report: false,
  toName: '',
  transformation: undefined,
  type: undefined,
  updatedAt: undefined,
  updatedBy: undefined,
  updatedData: undefined,
}
export interface BankContextAccount {
  accounnt?: IBankAccount
  updateAccount?: (account: IBankAccount) => void
}

export interface OperationsType {
  label?: string
  value?: number
}

export const operationOptions: OperationsType[] = [
  { value: 101, label: 'Acreditar a la cuenta' },
  { value: 102, label: 'Retirar de la cuenta' },
  { value: 104, label: 'Transferir a efectivo circulante' },
  { value: 103, label: 'Transferencia entre cuentas' },
]

//.slice(0, accounts.length > 1 ? 4 : 3)}

export interface OperationsContextState {
  operations?: OperationsType
  accountToTransfer?: IBankAccount
  duplicate?: boolean
  updateOperations?: (operations: OperationsType) => void
}

const BankContext = createContext<BankContextAccount | null>(null)
const OperationsContext = createContext<OperationsContextState | null>(null)

export interface MovementsFilter {
  pending?: boolean
  skip?: number
  size?: number
  search?: string
  start?: number
  end?: number
  accountId?: number
  randomKey?: number
}
const currentDate = new Date()
export const defaultMovementsFilter: MovementsFilter = {
  pending: false,
  skip: 0,
  size: 10,
  search: '',
}

const AccountMovementsContext = createContext<MovementFiltersContext | null>(null)

export { BankContext, AccountMovementsContext, OperationsContext }
