import ParkingIcon from 'src/assets/images/svg/parking.svg'
import TableIcon from 'src/assets/images/svg/table.svg'
import TerminalIcon from 'src/assets/images/svg/terminal.svg'

export const posTypesEnum = {
  GENERAL: { value: 1, label: 'General', type: 1, subType: 1, image: TerminalIcon },
  RESTAURANT: { value: 2, label: 'Restaurante', type: 1, subType: 0, image: TableIcon },
  PARKING: { value: 3, label: 'Parqueos', type: 2, subType: 0, image: ParkingIcon },
  BUSINESS: { value: 4, label: 'Empresarial', type: 3, subType: 0, image: TerminalIcon },
}

export const posTypes = Object.values(posTypesEnum)

export enum posDetailStatus {
  EN_ESPERA = 1,
  EN_PREPARACION = 2,
  PREPARADA = 3,
  ENTREGADA = 4,
  PAGADA = 5,
}
