import React, { useState } from 'react'
import { Col, ProgressBar, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Select } from 'src/components'
import { Chart } from 'src/components/Graph/Chart'
import { ChartType } from 'src/enums/ChartType'
import { selectTotalSaleReport } from 'src/selectors/report.selector'

interface IProps {
  loading: boolean
  title: string
  param: string
  onChange?: (option: ISelectNumber) => void
}

/**
 * Chart to show in total sale report
 * @component
 * @param {boolean} loading Loading animation
 * @param {string} title Title of the graph
 * @param {string} param Parameter to show in the graph
 * @param {function} onChange Function to change the date type
 * @returns
 */
export const ChartReportSales = ({ loading, title, param, onChange }: IProps) => {
  const report = useSelector(selectTotalSaleReport)
  const graphTypes: ISelectNumber[] = [
    { value: ChartType.BAR, label: 'Barra' },
    { value: ChartType.LINE, label: 'Lineal' },
    { value: ChartType.DONUT, label: 'Dona' },
  ]

  const dateTypes: ISelectNumber[] = [
    { value: 1, label: 'Día' },
    { value: 2, label: 'Semana' },
    { value: 3, label: 'Mes' },
  ]
  const [filterGraph, setFilterGraph] = useState({
    dateType: dateTypes[0],
    graphType: graphTypes[0],
  })
  const { dateType, graphType } = filterGraph

  const handleDateTypeChange = option => {
    if (onChange) onChange(option)
    setFilterGraph(prevState => ({
      ...prevState,
      dateType: option,
    }))
  }

  const handleGraphTypeChange = option => {
    setFilterGraph(prevState => ({
      ...prevState,
      graphType: option,
    }))
  }

  /**
   * Format the data to show in the graph
   * @returns IDataGraph
   */
  const formatData = (): IDataGraph => {
    return {
      labels: report.map(r => r.label),
      datasets: [
        {
          data: report.map(r => r[param]),
          label: title,
        },
      ],
    }
  }

  return (
    <>
      <Row>
        <Col>
          {loading && (
            <Row>
              <Col>
                <div className={'pb-custom'}>
                  <ProgressBar
                    label="Cargando"
                    animated
                    now={100}
                    style={{ marginBottom: 10 }}
                  />
                </div>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Col md={4}>
        <Select
          id={'type_date' + param}
          label={'Tipo de fecha'}
          name={'type_date' + param}
          dataCy={'type_date'}
          value={dateType}
          options={dateTypes}
          onChange={handleDateTypeChange}
        />
      </Col>
      <Col md={4}>
        <Select
          id={'form' + param}
          label={'Forma'}
          name={'form' + param}
          dataCy={'form_graph'}
          value={graphType}
          options={graphTypes}
          onChange={handleGraphTypeChange}
        />
      </Col>
      <Col xl={12} sm={12}>
        {!loading && (
          <Chart
            data={formatData()}
            currencyValues
            title={title}
            height={graphType.value === ChartType.BAR ? 450 : 150}
            type={graphType.value}
          />
        )}
      </Col>
    </>
  )
}
