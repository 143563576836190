import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import { Button } from 'src/components'
import { ItemSelectionTable } from 'src/components/Transaction/ItemSelectionTable'
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons'

import { itemTypes } from 'src/utils/utilities'

import { actionTypes, addNewAddons } from 'src/actions/mentor.actions'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  handlerInfoWithButtons,
  handlerSuccess,
  hasErrors,
} from 'src/selectors/error.selector'
import { showAlert } from 'src/actions/alert.actions'
import { ResumePlan } from './ResumePlan'

interface Props {
  show: boolean
  companyId: number
  title: string
  onHide: (boolean) => void
  demo: boolean
  renewal?: boolean
}

/** Component to add new addons to the company
 * @component
 * @param {boolean} show Indicate whether the component should be initialized
 * @param {number} companyId ID of the company to which the addons will be added
 * @param {string} title Company name and code
 * @param {Function} onHide Close the component
 * @param {boolean} demo Indicate if the company is a DEMO account
 * @param {boolean} renewal Indicate if the company is in renewal process
 * */
const AddAddons = ({ show, companyId, title, onHide, demo, renewal }: Props) => {
  const dispatch = useDispatch()

  const loadingAdd = useSelector(state =>
    loadingSelector([actionTypes.ADD_ADDONS])(state),
  )
  const errorAdd = useSelector(state => hasErrors([actionTypes.ADD_ADDONS])(state))

  const [flags, setFlags] = useState({ add: false })
  const [showResume, setShowResume] = useState(false)
  const [selected, setSelected] = useState([])

  useEffect(() => {
    if (loadingAdd) setFlags({ ...flags, add: true })
    else if (flags.add) {
      setFlags({ ...flags, add: false })
      const alert = errorAdd
        ? { ...handlerError(errorAdd.message) }
        : { ...handlerSuccess(), onConfirm: () => onClose(true) }

      dispatch(showAlert(alert))
    }
  }, [loadingAdd])

  const onClose = (update: boolean) => {
    setSelected([])
    setShowResume(false)
    onHide(update)
  }

  const onSave = () => {
    if (selected.length === 0 || selected.some(v => !v.value))
      dispatch(showAlert({ ...handlerError('Es necesario seleccionar los addons.') }))
    else {
      const request: MCompanyAddons[] = []
      selected.forEach(s => {
        request.push({
          item: { id: s.id },
          amount: s.subTotal,
          quantity: s?.line[0]?.quantity,
        })
      })

      if (!(demo || renewal)) {
        if (request.some(r => !r.amount || r.amount <= 0))
          return dispatch(
            showAlert({
              ...handlerError('Es necesario asignarle montos a los addons seleccionados'),
            }),
          )
      }

      if (demo || renewal) dispatch(addNewAddons(companyId, request))
      else
        dispatch(
          showAlert({
            ...handlerInfoWithButtons(
              'Agregar Addons',
              '¿Desea añadir nuevos addons? Los addons no se aplicarán inmediatamente, se emitirá una factura por los addons nuevos agregados y se aplicarán a la cuenta en el momento en que la factura sea pagada. El cobro de los addons será parcial según el periodo de uso respecto a la fecha de pago del plan principal.',
            ),
            onConfirm: () => dispatch(addNewAddons(companyId, request)),
          }),
        )
    }
  }

  return (
    <>
      <Modal show={show} size={'xl'} centered onHide={() => undefined}>
        <Modal.Header>
          <Modal.Title>Agregar Addons {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={'custom-modal-body'}>
            <Row>
              <Col xl={12}>
                <ItemSelectionTable
                  type={itemTypes.ADDONS}
                  balancePermission
                  onWarehouse={() => undefined}
                  onSelected={(
                    items: IItemSelectTable[],
                    deleted: IItemSelectTable[],
                  ) => {
                    setSelected(items)
                  }}
                  details={[]}
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              disabled={loadingAdd}
              color={'secondary'}
              icon={faWindowClose}
              onClick={() => onClose(false)}>
              Cerrar
            </Button>

            <Button
              icon={faSave}
              onClick={() => setShowResume(true)}
              loading={loadingAdd}>
              Agregar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
      <ResumePlan
        companyId={companyId}
        addons={
          selected.length > 0
            ? selected.map(s => ({
                item: { id: s.id, name: s.label, code: s.code },
                price: s.selectedPrice?.value,
                amount: s.subTotal,
                quantity: s.line ? s?.line[0]?.quantity : 0,
              }))
            : []
        }
        onSave={() => onSave()}
        show={showResume}
        isDemo={demo}
        isRenewal={renewal}
        onHide={() => setShowResume(false)}
      />
    </>
  )
}
export default AddAddons
