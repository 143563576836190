import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCategorizations } from 'src/actions/categorization.actions'
import { getUsersChildrenByModule } from 'src/actions/modules.actions'
import {
  actionTypes as actionReport,
  getTotalSaleReport,
} from 'src/actions/report.actions'
import { getAllPOSByUser } from 'src/actions/restaurant.actions'
import { getWarehouseLite } from 'src/actions/warehouse.actions'
import {
  Card,
  Select,
  Title,
  Icon,
  SelectedDates,
  Button,
  Folder,
  CustomTabs,
  ListCategories,
} from 'src/components'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { selectUsers } from 'src/selectors/modules.selector'
import { selectAllPOSUser } from 'src/selectors/restaurant.selector'
import { selectCurrentUser } from 'src/selectors/user.selector'
import { selectWarehouseLite } from 'src/selectors/warehouse.selector'
import { SubscriptionReport } from './SubscriptionReport'
import { ChartReportSales } from './ChartReportSales'

const today = new Date()

export const TotalSales = () => {
  const dispatch = useDispatch()

  const defaultValue: ISelectNumber = { label: '- Todos -', value: null }
  const dateTypeDefault: ISelectNumber = { value: 1, label: 'Día' }

  const user = useSelector(selectCurrentUser)
  const users: ISelect[] = useSelector(selectUsers)
  const warehouses: IWarehouse[] = useSelector(selectWarehouseLite)
  const categorization: ICategorization = useSelector(selectAllCategorizations)
  const pointOfSales = useSelector(selectAllPOSUser)

  const reportLoading = useSelector(state =>
    loadingSelector([actionReport.GET_TOTAL_SALE_REPORT])(state),
  )

  const [filters, setFilters] = useState({
    fromDate: new Date(today.getFullYear(), today.getMonth(), 1),
    toDate: today,
    warehouse: defaultValue,
    createdBy: defaultValue,
    deliveredBy: defaultValue,
    selectedCategorizations: [],
    selectedPos: [],
    dateType: dateTypeDefault,
    type: 1,
  })

  const [utils, setUtils] = useState({
    showCategorizations: false,
    showSubscriptions: false,
  })

  useEffect(() => {
    dispatch(getAllPOSByUser(user.id))
    dispatch(getWarehouseLite({ all: true }))
    dispatch(getAllCategorizations(16))
    dispatch(getUsersChildrenByModule(1000, true))
    setUp(filters.dateType)
  }, [])

  useEffect(() => {
    if (filters.dateType) setUp(filters.dateType)
  }, [filters.type])

  /**
   * Function that is responsible for obtaining the data of the report.
   * @param dateType date type. 1: Day, 2: Week, 3: Month
   */
  const setUp = (dateType: ISelectNumber) => {
    const {
      warehouse,
      createdBy,
      deliveredBy,
      toDate,
      fromDate,
      selectedCategorizations,
      selectedPos,
      type,
    } = filters

    const params = {
      startDate: fromDate.valueOf(),
      endDate: toDate.valueOf(),
      warehouse: warehouse.value,
      vendor: createdBy.value,
      deliverer: deliveredBy.value,
      dateType: dateType.value,
      type: type,
    }
    const request = {
      categories: selectedCategorizations ? selectedCategorizations.map(c => c.id) : [],
      pos: selectedPos ? selectedPos.map(d => d.id) : [],
    }
    dispatch(getTotalSaleReport(params, request))
  }

  /**
   * Gets the report filters
   * @returns JSX.Element
   */
  const getFilters = () => {
    const { warehouse, createdBy, deliveredBy, selectedCategorizations, selectedPos } =
      filters
    return (
      <>
        <Col xl={12} lg={12} md={12} sm={12}>
          <SelectedDates
            nonExecute
            disabled={reportLoading}
            dates={{
              dateFrom: filters.fromDate,
              dateTo: filters.toDate,
            }}
            onDateChange={(fromDate, toDate) =>
              setFilters({ ...filters, fromDate, toDate })
            }
          />
        </Col>
        <Col xl={4} lg={4} md={6} sm={12}>
          <Select
            label={'Por Bodega'}
            disabled={reportLoading}
            info={'Filtra las ordenes por la bodega seleccionada'}
            options={[defaultValue, ...warehouses]}
            value={warehouse}
            name={'warehouse'}
            onChange={(warehouse: ISelectNumber) => setFilters({ ...filters, warehouse })}
          />
        </Col>
        <Col xl={4} lg={4} md={6} sm={12}>
          <Select
            label={'Creador por'}
            disabled={reportLoading}
            info={'Filtra las ordenes por el usuario que la creo'}
            options={[defaultValue, ...users]}
            value={createdBy}
            name={'createdBy'}
            onChange={(createdBy: ISelectNumber) => setFilters({ ...filters, createdBy })}
          />
        </Col>
        <Col xl={4} lg={4} md={6} sm={12}>
          <Select
            disabled={reportLoading}
            label={'Entregado por'}
            info={'Filtra las ordenes por el usuario que la entrego'}
            options={[defaultValue, ...users]}
            value={deliveredBy}
            name={'deliveredBy'}
            onChange={(deliveredBy: ISelectNumber) =>
              setFilters({ ...filters, deliveredBy })
            }
          />
        </Col>
        <Col xl={4} lg={4} md={6} sm={12}>
          <div className={'column'}>
            <Select
              disabled={reportLoading}
              label={'Puntos de venta'}
              options={pointOfSales.filter(
                x => selectedPos.findIndex(y => y.id === x.id) === -1,
              )}
              value={null}
              placeholder={'Seleccione un punto de venta'}
              onChange={e => {
                setFilters({ ...filters, selectedPos: [...selectedPos, e] })
              }}
            />
            <Row>
              {filters.selectedPos.map((p, i) => (
                <div className={'user-tag warehouse-t ml-2'} key={i}>
                  <label className={'label-user-tag'}>{p.label}</label>
                  <Icon
                    className={'delete-user-tag d-warehouse-t'}
                    icon={faTimes}
                    tooltip={'Quitar'}
                    color={'white'}
                    onClick={() =>
                      setFilters({
                        ...filters,
                        selectedPos: selectedPos.filter(f => f.id !== p.id),
                      })
                    }
                  />
                </div>
              ))}
            </Row>
          </div>
        </Col>
        <Col xl={4} lg={4} md={6} sm={12} className={'mt-5'}>
          <div className={'column'}>
            <Button
              style={{ width: '250px' }}
              disabled={reportLoading}
              onClick={() => setUtils({ ...utils, showCategorizations: true })}>
              Filtrar por categorías
            </Button>
            <Row className={'pl-1'}>
              <ListCategories
                items={selectedCategorizations}
                onRemove={category =>
                  setFilters({
                    ...filters,
                    selectedCategorizations: selectedCategorizations.filter(
                      f => f.id !== category.id,
                    ),
                  })
                }
              />
            </Row>
          </div>
        </Col>
      </>
    )
  }

  /**
   * get the graph of the report
   * @param param Parameter to get the correct value
   * @param title Title of the graph
   * @returns Graph
   */
  const renderChart = (param: string, title: string) => {
    return (
      <ChartReportSales
        title={title}
        param={param}
        loading={reportLoading}
        onChange={option => {
          setFilters({ ...filters, dateType: option })
          setUp(option)
        }}
      />
    )
  }

  return (
    <div className={'text-center'}>
      <Title title={'Reporte de ventas'} />
      <Card title={'Filtros'} white>
        <Row>{getFilters()}</Row>
        <Row style={{ float: 'right' }}>
          <Button
            loading={reportLoading}
            color={'accent'}
            icon={faSearch}
            onClick={() => setUp(filters.dateType)}>
            Aplicar Filtros
          </Button>
        </Row>
      </Card>
      <Card title={'Visualizar'} white>
        <CustomTabs
          noCard
          setKey={key => setFilters({ ...filters, type: Number(key) === 3 ? 4 : 1 })}
          items={[
            {
              title: 'Ordenes',
              info: 'Gráfica de Ordenes propias',
              component: () => renderChart('total', 'Balance de ordenes'),
            },
            {
              title: 'Utilidad bruta',
              info: 'Gráfica de utilidad bruta',
              component: () => renderChart('purchase', 'Balance de transportista'),
            },
            {
              title: 'Descuentos',
              info: 'Gráfica de descuentos en ordenes',
              component: () => renderChart('discount', 'Balance de Cuentas por cobrar'),
            },
            {
              title: 'Cuentas por cobrar',
              info: 'Gráfica de las cuentas por cobrar',
              component: () => renderChart('cpc', 'Balance de Cuentas por cobrar'),
            },
          ]}
        />
      </Card>

      <SubscriptionReport
        show={utils.showSubscriptions}
        onHide={() => setUtils({ ...utils, showSubscriptions: false })}
      />

      {/* @ts-expect-error javascript */}
      <Folder
        noMessage
        list={filters.selectedCategorizations.map(d => d.id)}
        onHide={() => setUtils({ ...utils, showCategorizations: false })}
        onAssign={item => {
          if (filters.selectedCategorizations.findIndex(p => p.id === item.id) === -1) {
            filters.selectedCategorizations.push(item)
            setFilters({ ...filters })
          }
        }}
        data1={
          categorization && categorization.children ? categorization.children[0] : {}
        }
        data2={
          categorization && categorization.children ? categorization.children[1] : {}
        }
        show={utils.showCategorizations}
      />
    </div>
  )
}
