export const itemsPermissions = {
  create: 7102,
  update: 7103,
  activeOrDesactive: 7104,
  archive: 7106,
  updatePrice: 7107,
  configMultiplePrices: 7108,
  dataSheet: 7110,
  wasteInventoryRequest: 7111,
  approveWasteInventoryRequest: 7112,
  transferInventory: 7113,
  activeOrDesactiveIVA: 7114,
  seeMoneyValue: 7115,
  updateConfig: 7115, // TODO: create new permission
}

export const reportPermissions = {
  totalSell: 1401,
  itemsSoldSegmented: 1402,
  inventoryHistorical: 1403,
  reportUser: 1404,
  historicPrice: 1405,
  patrimonial: 1406,
  waste: 1407,
  expense: 1408,
  client: 1409,
  purchase: 1410,
  clientLog: 1411,
  inventoryKardex: 1412,
  branch: 1413,
  segmentation: 1414,
}

export const inventoriesPermissions = {
  seeOwn: 1901,
  seeAll: 1902,
  seeMoneyBalances: 1903,
  downloadReport: 1904,
  seeInventory: 1905,
  dispatchPendingInventory: 1906,
}

export const userCashBoxPermissions = {
  increase: 12102,
  decrease: 12103,
}

export const mentorPermissions = {
  see: 11151,
  felConfig: 11152,
  updatePlan: 11153,
  updateUsers: 11154,
  createAccount: 11155,
  updateAccount: 11156,
  updateDemoDate: 11157,
  canAddAddon: 11158,
  canEditAddon: 11159,
  canFinishDemo: 11160,
  canPostponePayments: 11161,
  seeDTEReport: 11162,
  canEditCompanyConfig: 11163,
  canUpdateCreditFelStatus: 11164,
  canUpdateThirdEmitFEL: 11165,
}

export const depositsPermissions = {
  seeOwnDeposits: 1551,
  seeOthersDeposits: 1552,
  createDeposit: 1553,
  approveAndReject: 1554,
  nullify: 1555,
  approveWithoutDocument: 1556,
  approveWithoutMovBank: 1557,
  canRepiteReference: 1558,
  createDepositoToOtherUser: 1560,
}

export const posPermissions = {
  manageConcepts: 2213,
  createConceptsAgile: 2214,
  clearTerminal: 2209,
}

export const orderPermissions = {
  canFilterByDate: 1108,
  confirmWithoutInventory: 1111,
  canChangeInventoryReturnOption: 1113,
  canDownloadReportSales: 1136,
  confirmWithFullDispatch: 1140,
  confirmWithPartialDispatch: 1141,
  createOrderCash: 1142,
  createOrderCredit: 1143,
  createOrderCard: 1144,
  manageConcepts: 1145,
  createConceptsAgile: 1146,
  canUpdateOrderCompleted: 1147,
  createOrderMultiple: 1148,
  confirmWithPendingDispatch: 1149,
  createOrderTransfer: 1167,
}

export const quotePermissions = {
  manageConcepts: 6464,
  createConceptsAgile: 6465,
  confirmWithoutInventory: 6455,
  confirmWithFulLInventory: 6466,
  confirmWithPartialInventory: 6467,
  confirmWithPendingInventory: 6468,
}

export const purchasePermissions = {
  viewBalances: 1952,
  createPurchases: 1953,
  canPaymentWithBanks: 1954,
  canPaymentWithCash: 1955,
  creditNotePayment: 1956,
  viewCreditNotes: 1957,
  createCreditNote: 1958,
  nullifyPurchase: 1959,
  warehouseSelector: 1960,
  noInvoiceRequired: 1961,
  canPaymentInConfirm: 1962,
  viewAllProducts: 1963,
  confirmWithoutPayments: 1964,
  confirmWithoutInventory: 1965,
  importPurchase: 1966,
  canViewPayments: 1967,
  emitSpecialInvoice: 1968,
  createPreDatePurchases: 1969,
  skipCategoryEntry: 1970,
  generatePurchaseInventory: 1971,
  canPaymentWithProviderBalance: 1972,
  viewMonetaryBalances: 1973,
  downloadReport: 1974,
  createPurchase: 1975,
  canChangeRequirePayments: 1976,
  canChangeAddInventory: 1977,
}

export const expensePermissions = {
  viewOwnExpenses: 12401,
  viewOtherUsersExpenses: 12402,
  createExpense: 12403,
  canPaymentWithBanks: 12404,
  canPaymentWithCash: 12405,
  viewSummaries: 12406,
  creditNoteCredit: 12407,
  viewCreditNotes: 12408,
  createCreditNote: 12409,
  createOnBehalfOfOtherUser: 12410,
  confirmWithoutPayments: 12411,
  canViewPayments: 12412,
  associateExpensesToPurchase: 12413,
  createPreDateExpenses: 12414,
  skipCategoryEntry: 12415,
  canPaymentWithProviderBalance: 12416,
  viewMonetaryBalances: 12417,
  downloadReport: 12418,
  nullifyExpenses: 12419,
  canChangeRequirePayments: 12420,
  skipInvoice: 12421,
}

export const clientPermissions = {
  create: 1052,
  update: 1053,
  view: 1054,
  delete: 1055,
  archive: 1056,
  viewAll: 1057,
  lock: 1069,
  unlock: 1070,
}

export const userPermissions = {
  updateDescription: 1455,
}

export const dispatchPermissions = {
  approve: 1251,
  reject: 1252,
  viewAll: 1254,
  downloadFile: 1255,
}

export const increasePermissions = {
  nullify: 12361,
}

export const orderVoucherPermissions = {
  view: 16601,
  create: 16602,
  nullify: 16603,
  release: 16604,
}

export const creditNoteSellPermissions = {
  view: 16404,
  create: 16401,
  nullify: 16402,
}

export const correlativePermissions = {
  create: 2102,
  update: 2103,
  delete: 2104,
}

export const taxesPermissions = {
  create: 16352,
  update: 16353,
  delete: 16354,
}
export const manufacturePermissions = {
  approve: 12202,
  reject: 12203,
  validate: 12204,
}

export const promotionPermissions = {
  create: 2152,
  update: 2153,
  delete: 2154,
}