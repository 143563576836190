import './PaginatedTable.scss'
import React, { Component } from 'react'

import { Col, ProgressBar, Row, Pagination } from 'react-bootstrap'
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'
import ReactPaginate from 'react-paginate'

import {
  Select as CustomSelect,
  CustomDate as CustomDatePicker,
  FormText as FormTextField,
  CustomFilter,
  Checkbox,
} from '../index'
import './PaginatedTable.scss'

import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { sortableContainer, sortableElement } from 'react-sortable-hoc'

const pageSizes = [
  { value: 5, label: '5 resultados por página' },
  { value: 10, label: '10 resultados por página' },
  { value: 20, label: '20 resultados por página' },
  { value: 50, label: '50 resultados por página' },
  { value: 100, label: '100 resultados por página' },
]
const presets = [
  { value: 0, label: 'Hoy' },
  { value: 1, label: 'Últimos 7 días' },
  { value: 2, label: 'Este mes' },
  { value: 3, label: 'El mes pasado' },
  { value: 4, label: 'Últimos 2 meses' },
  { value: 5, label: 'Últimos 3 meses' },
]

const today = new Date()
let interval = null

const SortableItem = sortableElement(({ children }) => {
  return children
})

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>
})

export default class PaginatedTable extends Component {
  state = {
    page: 1,
    pageSize: pageSizes[1],
    search: '',
    editables: [],
    selection: [],
    selectable: false,
    dateFrom: new Date(),
    dateTo: new Date(),
    preset: { value: 0, label: 'Hoy' },
    drop: false,
    time: 800,
  }

  myTimer = () => {
    const { handleChange } = this.props
    const { time, search } = this.state
    if (time <= 0) {
      clearInterval(interval)
      interval = null
      this.resetPagination()
      handleChange(search)
    } else this.setState({ time: time - 100 })
  }

  componentDidMount() {
    let { dateFilter, dateFrom, dateTo, filterTerm, selectable, pageSize, inventory } =
      this.props
    dateFrom = dateFrom ? dateFrom : { initial: inventory ? null : new Date() }
    dateTo = dateTo ? dateTo : { initial: inventory ? null : new Date() }
    if (dateFilter) {
      this.setState({
        dateFrom: dateFrom.initial,
        dateFromMin: dateFrom.min,
        dateFromMax: dateFrom.max,

        dateTo: dateTo.initial,
        dateToMin: dateTo.min,
        dateToMax: dateTo.max,
        filterTerm: filterTerm,
        selectable,
      })
    }
    if (pageSize !== undefined) this.setState({ pageSize: pageSizes[pageSize] })
  }

  componentWillReceiveProps(next) {
    const { handleChange } = this.props
    if (next.loading && handleChange) {
      this.setState({ time: 800 })
    }
  }

  handleChange = e => {
    const { handleChange } = this.props
    this.setState({ search: e.target.value, page: 1, editables: [] })
    clearInterval(interval)
    this.setState({ time: 799 })
    interval = setTimeout(() => {
      this.setState({ time: 800 })
      this.resetPagination()
      if (handleChange) handleChange(this.state.search)
    }, 1000)

    /*if(handleChange && !interval) {
            interval = setInterval(this.myTimer, 100);
        }
        else if(interval) this.setState({time: 799})*/
  }

  handlePageClick = data => {
    if (this.props.getPagination) {
      const { pageSize, search } = this.state
      this.props.getPagination(data.selected * pageSize.value, pageSize.value, search)
    }
    this.setState({ page: data.selected + 1, editables: [] })
  }
  onPageSizeChanges = pageSize => {
    if (this.props.getPagination) {
      const { search } = this.state
      this.props.getPagination(0, pageSize.value, search)
    }
    this.setState({ pageSize: pageSize, page: 1, editables: [] })
  }
  handleCheckBoxChange = e => this.props.handleCheckBoxChange(e)

  filter = item => {
    const { search } = this.state
    const exp = new RegExp(search.toUpperCase(), 'g')
    const copy = { ...item, id: null }
    return Object.keys(copy).some(p =>
      `${JSON.stringify(copy[p])}`.toUpperCase().match(exp),
    )
  }

  edit = id => {
    this.state.editables.push(id)
    this.setState({ editables: this.state.editables })
  }

  doneEdit = id => {
    const index = this.state.editables.indexOf(id)
    this.state.editables.splice(index, 1)
    this.setState({ editables: this.state.editables })
  }

  resetPagination = () => {
    this.setState({ page: 1, editables: [] })
  }

  selectedRows = () => {
    return this.state.selection.length
  }

  resetSelection = () => {
    return new Promise(resolve => {
      this.setState({ selection: [] }, resolve)
    })
  }

  renderRow = (item, index) => {
    const { dragAndDrop } = this.props
    item.editable = this.state.editables.includes(item[this.props.identifier])
    if (dragAndDrop)
      return (
        <SortableItem key={index} index={index} sortIndex={index}>
          {this.props.renderRow(item, index)}
        </SortableItem>
      )
    return this.props.renderRow(item, index)
  }

  onSelectRow = (item, id) => {
    const { selection } = this.state
    const index = selection.findIndex(i => i[id] === item[id])
    index !== -1 ? selection.splice(index, 1) : selection.push(item)
    this.setState({ selection })
  }

  onPresetChange = preset => {
    const { onDateChange } = this.props

    let start = new Date()
    start.setUTCHours(0, 0, 0)

    let end = new Date()
    end.setUTCHours(23, 59, 59)

    switch (preset.value) {
      case 1: {
        start.setDate(start.getDate() - 7)
        break
      }
      case 2: {
        start = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0)
        break
      }
      case 3: {
        start = new Date(today.getFullYear(), today.getMonth() - 1, 1, 0, 0, 0)
        end = new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59)
        break
      }
      case 4: {
        start.setMonth(start.getMonth() - 2)
        break
      }
      case 5: {
        start.setMonth(start.getMonth() - 3)
        break
      }
      default:
    }

    this.setState({ dateFrom: start, dateTo: end, preset: preset, page: 1 })
    if (onDateChange) {
      const { search } = this.state
      onDateChange(start, end, search)
    }
  }

  onDateFromChange = date => {
    const { search, dateTo } = this.state
    if (date) {
      this.setState(
        { dateFrom: date, preset: null, dateTo: !dateTo ? new Date() : dateTo },
        () => this.props.onDateChange(date, this.state.dateTo, search),
      )
    } else {
      this.setState({ dateFrom: null, preset: null }, () =>
        this.props.onDateChange(date, this.state.dateTo, search),
      )
    }
  }

  onDateToChange = date => {
    const { search, dateFrom } = this.state
    if (date) {
      this.setState({ dateTo: date, dateFrom: !dateFrom ? new Date() : dateFrom }, () =>
        this.props.onDateChange(this.state.dateFrom, date, search),
      )
    } else {
      this.setState({ dateTo: null })
      this.props.onDateChange(this.state.dateFrom, date, search)
    }
  }

  renderRows = (renderRow, total, lowerLimit, upperLimit, itemsToRender) => {
    return renderRow && total
      ? itemsToRender.map(this.renderRow)
      : itemsToRender.slice(lowerLimit, upperLimit).map(this.renderRow)
  }

  render() {
    const { page, pageSize, search, preset, time } = this.state
    const {
      items,
      headers,
      renderRow,
      filter,
      loading,
      noItemsLegend,
      dateFilter,
      tableDivStyle,
      placeholder,
      total,
      customClass,
      button,
      handleChange,
      footerText,
      onCheck,
    } = this.props
    const { dateFrom, dateTo, filterTerm } = this.state

    const { customFilter, defaultActive, hideFilter, headerText, withoutPagination } =
      this.props
    const { dragAndDrop, onSortEnd } = this.props

    const itemsToRender = Array.isArray(items)
      ? items.filter(handleChange ? () => true : search ? this.filter : () => true)
      : []
    const totalItems = itemsToRender.length
    const totalPages = Math.ceil((total || totalItems) / pageSize.value)
    const upperLimit = (page - 1) * pageSize.value + pageSize.value
    const lowerLimit = (page - 1) * pageSize.value

    return (
      <div className={customClass ? customClass : ''}>
        {dateFilter && (
          <Row>
            <Col>
              <CustomDatePicker
                label={'Desde:'}
                value={dateFrom}
                disabledDays={{ after: dateTo }}
                onDayChange={this.onDateFromChange}
                error={this.state.dateFromError}
                info={'Fecha de inicio'}
              />
            </Col>
            <Col>
              <CustomDatePicker
                label={'Hasta:'}
                value={dateTo}
                disabledDays={{ before: dateFrom }}
                onDayChange={this.onDateToChange}
                error={this.state.dateToError}
                info={'Fecha de finalizaciòn'}
              />
            </Col>
            <Col>
              <CustomSelect
                value={preset}
                options={presets}
                onChange={this.onPresetChange}
                label={'o'}
              />
            </Col>
          </Row>
        )}

        {!hideFilter ? (
          <>
            {!customFilter ? (
              <Row>
                <Col>
                  {filter && (
                    <FormTextField
                      placeholder={'Buscar...'}
                      // label={'Buscar'}
                      id="filter"
                      value={filterTerm}
                      onChange={this.handleChange}
                    />
                  )}
                </Col>
                <Col xs={4}>
                  <CustomSelect
                    // label={'Ver'}
                    withoutLabel
                    value={this.state.pageSize}
                    onChange={this.onPageSizeChanges}
                    options={pageSizes}
                  />
                </Col>
                {button && (
                  <Col sm={12} md={this.props.size || 2}>
                    {button}
                  </Col>
                )}
              </Row>
            ) : (
              <CustomFilter
                defaultActive={defaultActive}
                placeholder={placeholder}
                search={filterTerm}
                onChange={this.handleChange}
                customFilter={customFilter}
                onScan={this.props.onScan}
              />
            )}
          </>
        ) : (
          ''
        )}
        {(loading || time < 800) && (
          <Row>
            <Col>
              <div className={'pb-custom'}>
                <ProgressBar
                  label="Cargando"
                  animated
                  now={100}
                  style={{ marginBottom: 10 }}
                />
              </div>
            </Col>
          </Row>
        )}
        <div
          style={{
            backgroundColor: (loading || time < 800) && 'rgba(203,200,200,0.3)',
            opacity: (loading || time < 800) && 0.7,
          }}>
          {itemsToRender.length > 0 ? (
            <div style={{ ...tableDivStyle }}>
              {headerText && <p>{headerText}</p>}
              <Table className={itemsToRender.length === 0 ? 'filter-menu' : ''}>
                <Thead>
                  <Tr className={'header'}>
                    {headers &&
                      headers.map((item, index) =>
                        onCheck && index === 0 ? (
                          <Th key={`${index}`}>
                            <OverlayTrigger
                              placement={'top'}
                              overlay={
                                <Tooltip id={'tooltip'}>Seleccionar todos</Tooltip>
                              }>
                              <Checkbox
                                style={{ display: 'inline-table' }}
                                checked={item}
                                onChange={() => onCheck(!item)}
                              />
                            </OverlayTrigger>
                          </Th>
                        ) : (
                          <Th key={`${index}`}>{item}</Th>
                        ),
                      )}
                  </Tr>
                </Thead>
                {itemsToRender.length > 0 ? (
                  <Tbody className={'table-body text-center'}>
                    {!dragAndDrop ? (
                      this.renderRows(
                        renderRow,
                        total,
                        lowerLimit,
                        upperLimit,
                        itemsToRender,
                      )
                    ) : (
                      <SortableContainer hideSortableGhost={true} onSortEnd={onSortEnd}>
                        <div>
                          {this.renderRows(
                            renderRow,
                            total,
                            lowerLimit,
                            upperLimit,
                            itemsToRender,
                          )}
                        </div>
                      </SortableContainer>
                    )}

                    {/*{
                                                    renderRow && total ?  itemsToRender.map(this.renderRow) :
                                                        itemsToRender.slice(lowerLimit, upperLimit).map(this.renderRow)
                                                }*/}
                  </Tbody>
                ) : (
                  <Tbody className={'table-body text-center'}>
                    <Tr className={'empty'}>
                      {renderRow &&
                        headers.map(h => (
                          <Td className={'medium'} style={{ color: 'white' }}>
                            KOLO
                          </Td>
                        ))}
                    </Tr>
                  </Tbody>
                )}
              </Table>
              {footerText && (
                <Row
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    textAlign: 'right',
                    marginRight: 5,
                  }}>
                  <h2>{footerText}</h2>
                </Row>
              )}
            </div>
          ) : (
            <h3 style={{ textAlign: 'center', marginTop: 50 }}>
              {noItemsLegend || 'Sin items para mostrar'}
            </h3>
          )}

          {withoutPagination ? (
            <Row className={'container-buttons'}>
              <Col xl={6} md={6} sm={12} xs={12}>
                <div>
                  <Row className={'container-buttons'}>
                    <Col xl={3} md={3} sm={3} xs={12}>
                      <div>
                        <Pagination>
                          <Pagination.Prev
                            disabled={page <= 1}
                            onClick={() => this.handlePageClick({ selected: page - 2 })}
                          />
                          <Pagination.Next
                            disabled={itemsToRender.length <= 0}
                            onClick={() => this.handlePageClick({ selected: page })}
                          />
                        </Pagination>
                      </div>
                    </Col>
                    <Col xl={9} md={9} sm={9} xs={12}>
                      <CustomSelect
                        withoutLabel
                        value={this.state.pageSize}
                        onChange={this.onPageSizeChanges}
                        options={pageSizes}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          ) : (
            <Row className={'container-buttons'}>
              <Col
                xl={hideFilter ? 12 : 9}
                lg={hideFilter ? 12 : 9}
                md={hideFilter ? 12 : 9}
                sm={12}
                xs={12}>
                {(total || itemsToRender.length) > pageSize.value && (
                  <ReactPaginate
                    ref={ref => (this.pagination = ref)}
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    forcePage={page - 1}
                  />
                )}
              </Col>

              {!hideFilter && itemsToRender.length > 0 && (
                <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                  <CustomSelect
                    withoutLabel
                    value={this.state.pageSize}
                    onChange={this.onPageSizeChanges}
                    options={pageSizes}
                  />
                </Col>
              )}
            </Row>
          )}

          {!hideFilter && itemsToRender.length > 0 && (
            <div style={{ textAlign: 'center', width: '100%', marginTop: 20 }}>
              Mostrando {lowerLimit + 1} a{' '}
              {(total || itemsToRender.length) < upperLimit
                ? total || itemsToRender.length
                : upperLimit}{' '}
              {withoutPagination ? '' : ` de ${total || itemsToRender.length}`}
            </div>
          )}
        </div>
      </div>
    )
  }
}

PaginatedTable.defaultProps = {
  items: [],
  selection: [],
  renderRow: null,
  renderHeader: null,
  filter: true,
  selectable: false,
  identifier: 'id',
}
