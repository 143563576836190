import React, { Component } from 'react'
import './NewCompany.scss'
import { connect } from 'react-redux'

import FormTextField from 'src/components/inputs/FormTextField/FormTextField'
import { faPlus } from '@fortawesome/free-solid-svg-icons/index'
import Button from 'src/components/buttons/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'
import SweetAlert from 'sweetalert-react'
import { createAdminCompany } from 'src/actions/company.actions'
import { selectCreateAdminNewCompany } from 'src/selectors/company.selector'
import { Modal, Col, Row } from 'react-bootstrap'
import Select from 'src/components/inputs/Select/CustomSelect'
import IconButton from 'src/components/buttons/IconButton'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

class NewCompany extends Component {
  state = {
    warehouse: false,
    name: '',
    address: '',
    nit: '',
    email: '',
    isDistributor: false,
    isClient: false,
    isProducer: false,
    isPromotor: false,
  }

  save = principalWarehouse => {
    const { name, address, nit, email, isDistributor, isClient, isProducer, isPromotor } =
      this.state
    this.props.createSingleAdminCompany(
      name,
      address,
      nit,
      email,
      isDistributor,
      isClient,
      isProducer,
      isPromotor,
      principalWarehouse,
    )
    console.log(this.state)
    this.setState({ showSuccess: true })
  }

  getCredentials = () => {
    const { email } = this.state
    const { newCompany } = this.props
    return `Usuario: ${email}, Contraseña: ${newCompany}`
  }

  onCheckboxChange = index => {
    switch (index) {
      case 0:
        this.setState(prevState => ({
          isDistributor: !prevState.isDistributor,
        }))
        break
      case 1:
        this.setState(prevState => ({ isClient: !prevState.isClient }))
        break
      case 2:
        this.setState(prevState => ({ isProducer: !prevState.isProducer }))
        break
      case 3:
        this.setState(prevState => ({ isPromotor: !prevState.isPromotor }))
        break
      default:
    }
  }

  clearState = () => {
    this.setState({
      name: '',
      address: '',
      nit: '',
      email: '',
      isDistributor: false,
      isClient: false,
      isProducer: false,
      isPromotor: false,
    })
  }

  handleInputChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  render() {
    return (
      <div>
        <Row>
          <Col sm={6}>
            <FormTextField
              label={'Nombre'}
              type={'text'}
              id={'name'}
              placeholder={'Nombre'}
              name={'name'}
              value={this.state.name}
              onChange={this.handleInputChange}
              error={''}
            />
          </Col>

          <Col sm={6}>
            <FormTextField
              label={'Direccion'}
              type={'text'}
              id={'address'}
              placeholder={'Direccion'}
              name={'address'}
              value={this.state.address}
              onChange={this.handleInputChange}
              error={''}
            />
          </Col>

          <Col sm={6}>
            <FormTextField
              label={'NIT'}
              type={'text'}
              id={'nit'}
              placeholder={'NIT'}
              name={'nit'}
              value={this.state.nit}
              onChange={this.handleInputChange}
              error={''}
            />
          </Col>
          <Col sm={6}>
            <FormTextField
              label={'Email'}
              type={'email'}
              id={'email'}
              placeholder={'Email'}
              name={'email'}
              value={this.state.email}
              onChange={this.handleInputChange}
              error={''}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <label className={'form-label'} htmlFor="nombre">
              Distribuidor
            </label>
            <br />
            <input
              id={'isDistributor'}
              name="isDistributor"
              type="checkbox"
              checked={this.state.isDistributor}
              onChange={e => this.onCheckboxChange(0)}
            />
          </Col>

          <Col>
            <label className={'form-label'} htmlFor="nombre">
              Cliente
            </label>
            <br />
            <input
              id={'isClient'}
              name="isClient"
              type="checkbox"
              checked={this.state.isClient}
              onChange={e => this.onCheckboxChange(1)}
            />
          </Col>

          <Col>
            <label className={'form-label'} htmlFor="nombre">
              Productor
            </label>
            <br />
            <input
              id={'isProducer'}
              name="isProducer"
              type="checkbox"
              checked={this.state.isProducer}
              onChange={e => this.onCheckboxChange(2)}
            />
          </Col>

          <Col>
            <label className={'form-label'} htmlFor="nombre">
              Promotor
            </label>
            <br />
            <input
              id={'isPromotor'}
              name="isPromotor"
              type="checkbox"
              checked={this.state.isPromotor}
              onChange={e => this.onCheckboxChange(3)}
            />
          </Col>
        </Row>

        <Row>
          <Col style={{ textAlign: 'right' }}>
            <Button onClick={() => this.setState({ warehouse: true })}>
              {' '}
              <FontAwesomeIcon icon={faPlus} /> Guardar
            </Button>
            <SweetAlert
              show={this.state.showSuccess}
              title="Empresa creada"
              text={'Credenciales ' + this.getCredentials()}
              type={'success'}
              onConfirm={() => {
                //name, address, nit, email, isDistributor, isClient, isProducer
                this.clearState()
                this.setState({ showSuccess: false })
              }}
            />
          </Col>
        </Row>

        <Modal
          show={this.state.warehouse}
          size={'md'}
          centered
          onHide={() => this.setState({ warehouse: false })}>
          <Modal.Header>
            <Modal.Title>Bodega principal</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className={'column'}>
              <span>
                Crear bodega principal de la empresa? ciertas conversiones/transacciones
                se harán automáticamente a esta bodega
              </span>
              <br />
              <Row>
                <Col>
                  <div
                    className={'big-button'}
                    onClick={() => {
                      this.save(true)
                    }}>
                    Sí
                  </div>
                </Col>
                <Col>
                  <div
                    className={'big-button'}
                    style={{ backgroundColor: 'rgba(157,13,12,0.5)' }}
                    onClick={() => {
                      this.save(false)
                    }}>
                    No
                  </div>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  newCompany: selectCreateAdminNewCompany(state),
})

const mapDispatchToProps = dispatch => ({
  createSingleAdminCompany: (
    name,
    address,
    nit,
    email,
    isDistributor,
    isClient,
    isProducer,
    isPromotor,
    principalWarehouse,
  ) =>
    dispatch(
      createAdminCompany({
        name,
        address,
        nit,
        email,
        isDistributor,
        isClient,
        isProducer,
        isPromotor,
        principalWarehouse,
      }),
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewCompany)
