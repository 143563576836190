import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import { FormText, SwitchV2, Switch, Button, NumberField, Loading } from 'src/components'
import { ItemSelectionTable } from 'src/components/Transaction/ItemSelectionTable'
import { itemTypes } from 'src/utils/utilities'

import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons'

import {
  types,
  getVariableRecipe,
  createVariableRecipe,
  updateVariableRecipe,
} from 'src/actions/enumeration.actions'
import { selectEnumerationCustomVariable } from 'src/selectors/enumeration.selector'

import { getProductsForSelector } from 'src/actions/products.actions'
import { selectProductsLite } from 'src/selectors/products.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import { showAlert } from 'src/actions/alert.actions'
import { selectSetCurrentCompany } from 'src/selectors/company.selector'

const RecipeVariableCreate = ({ id, show = false, onHide }) => {
  const dispatch = useDispatch()

  const company = useSelector(selectSetCurrentCompany)
  const products = useSelector(selectProductsLite)

  const loading = useSelector(state =>
    loadingSelector([types.ON_CREATE_CUSTOM_VARIABLE])(state),
  )

  const hasError = useSelector(state =>
    hasErrors([types.ON_CREATE_CUSTOM_VARIABLE])(state),
  )

  const customVariable = useSelector(selectEnumerationCustomVariable)
  const loadingG = useSelector(state =>
    loadingSelector([types.GET_RECIPE_VARIABLE])(state),
  )

  const [flags, setFlag] = useState({ create: false, get: false })

  const [error, setError] = useState<IRecipeVariableError>({})
  const [create, setCreate] = useState<IRecipeVariableCreate>({})

  const { name, required, multiple, unlimited, variablePrice, items, min, max } = create
  const [recipeVariableItems, setRecipeVariableItems] = useState<IItemSelectTable[]>([])

  useEffect(() => {
    if (!show) return
    setCreate({ items: [] })
    setError({})
    if (!products || products.length === 0) dispatch(getProductsForSelector())
    if (id) dispatch(getVariableRecipe(id))
  }, [show])

  useEffect(() => {
    if (loading) setFlag({ ...flags, create: true })
    else if (flags.create) {
      setFlag({ ...flags, create: false })
      const alert = hasError
        ? {
            ...handlerError(hasError.message),
          }
        : {
            ...handlerSuccess('Operación exitosa'),
            onConfirm: () => onClose(true),
          }
      dispatch(showAlert(alert))
    }
  }, [loading])

  useEffect(() => {
    if (loadingG) setFlag({ ...flags, get: true })
    else if (flags.get) {
      setFlag({ ...flags, get: false })

      const details: IItemDetailToEdit[] = customVariable.products.map(p => {
        return {
          productId: p.productId,
          categories: [],
          id: p.id,
          key: p.id,
          quantity: p.quantity,
          subtotal: p.price,
        }
      })

      setCreate({
        name: customVariable.name,
        required: customVariable.required,
        multiple: customVariable.multiple,
        unlimited: customVariable.unlimited,
        variablePrice: customVariable.variablePrice,
        min: customVariable.min,
        max: customVariable.max,
        items: details,
      })
    }
  }, [loadingG])

  const onClose = (refresh?: boolean) => {
    setCreate({})
    onHide(refresh)
    setError({})
  }

  const save = () => {
    const errors: IRecipeVariableError = {}
    if (!name) errors.name = 'Campo requerido'
    if (multiple && !unlimited) {
      if (!min) errors.min = 'Campo requerido'
      else if (min > max) errors.min = 'El valor mínimo no puede ser mayor al máximo'
      if (!max) errors.max = 'Campo requerido'
    }

    const products = recipeVariableItems
      .filter(i => i.id !== undefined)
      .map(i => {
        return {
          productId: i?.id,
          quantity: i?.line[0]?.quantity || 1,
          price: i?.subTotal || 0,
        }
      })

    if (!products || products.length === 0)
      errors.items = 'Es necesario seleccionar uno o mas ítems.'

    if (errors.items?.length > 0) dispatch(showAlert({ ...handlerError(errors.items) }))

    setError(errors)

    if (Object.keys(errors).length <= 0) {
      const request = Object.assign(
        {},
        {
          ...create,
          unlimited: !variablePrice ? unlimited : false,
          items: [],
          products,
        },
      )

      if (!id) dispatch(createVariableRecipe(request))
      else dispatch(updateVariableRecipe(id, request))
    }
  }

  return (
    <div>
      <Modal show={show} centered onHide={() => undefined} size={'xl'}>
        <Modal.Header>
          <Modal.Title>{id ? 'Editar ' : 'Crear '} variación</Modal.Title>
        </Modal.Header>
        <Modal.Body className={'custom-modal-body'}>
          <Row>
            <Col xl={12}>
              <FormText
                required
                disabled={loading}
                label={'Alias de la variación'}
                value={name}
                type={'text'}
                changeValue={v => setCreate({ ...create, name: v })}
                error={error.name}
              />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <SwitchV2
                disabled={loading}
                label={'Obligatorio'}
                info={
                  'Indica que dicha variación debera ser seleccionada obligatoriamente en la receta'
                }
                checked={required}
                onChange={v => setCreate({ ...create, required: v })}
              />
            </Col>

            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <SwitchV2
                disabled={loading}
                label={'Varias selecciones'}
                info={'Indica que dicha variación se podrá seleccionar mas de una opción'}
                checked={multiple}
                onChange={v => setCreate({ ...create, multiple: v })}
              />
            </Col>

            {multiple && (
              <Col xl={12}>
                <Row>
                  <Col xl={6} lg={4} md={6} sm={12} xs={12}>
                    <SwitchV2
                      disabled={loading || variablePrice}
                      label={'Selección Ilimitada'}
                      info={
                        'Indica que dicha variación podrá seleccionarse con cualquier cantidad'
                      }
                      checked={unlimited}
                      onChange={v =>
                        setCreate({
                          ...create,
                          unlimited: v,
                          variablePrice: false,
                          min: 0,
                          max: 0,
                        })
                      }
                    />
                  </Col>

                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <SwitchV2
                      disabled={loading || unlimited}
                      label={'Precio de oferta'}
                      info={
                        'Activa esta opción para calcular automáticamente el precio final de una oferta especial al seleccionar ítems de la variación.'
                      }
                      checked={variablePrice}
                      onChange={v =>
                        setCreate({ ...create, variablePrice: v, unlimited: false })
                      }
                    />
                  </Col>

                  {!unlimited && (
                    <Col xl={12}>
                      <Row>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                          <NumberField
                            disabled={loading}
                            label={
                              variablePrice
                                ? 'Cantidad de ítems a cobrar'
                                : 'Cantidad minima'
                            }
                            value={min}
                            onValueChange={v => {
                              if (!v) v = 0
                              if (v < 0) v = 0
                              setCreate({ ...create, min: v })
                            }}
                            error={error.min}
                          />
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                          <NumberField
                            disabled={loading}
                            label={
                              variablePrice
                                ? 'Cantidad de ítems en la oferta'
                                : 'Cantidad maxima'
                            }
                            value={max}
                            onValueChange={v => {
                              if (!v) v = 1
                              if (v < 1) v = 1
                              setCreate({ ...create, max: v })
                            }}
                            error={error.max}
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Col>
            )}

            <Col xl={12}>
              {loadingG ? (
                <Loading show />
              ) : (
                <ItemSelectionTable
                  type={itemTypes.RECIPE_ITEMS}
                  warehouseId={company.warehouse}
                  onSelected={(selected: IItemSelectTable[]) => {
                    setRecipeVariableItems(selected)
                  }}
                  balancePermission={false}
                  details={items}
                  onWarehouse={() => undefined}
                  isEditParam={id !== undefined}
                />
              )}
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              disabled={loading}
              icon={faWindowClose}
              color={'secondary'}
              onClick={() => onClose()}>
              Cerrar
            </Button>

            <Button
              loading={loading}
              icon={faSave}
              onClick={() => {
                save()
              }}>
              Guardar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default RecipeVariableCreate
