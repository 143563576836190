import './TableOrderPage.scss'
import React from 'react'

import Placeholder from 'src/assets/images/newDesign/placeholder.svg'
import Money from 'src/components/Money/Money'
import Icon from 'src/components/buttons/IconButton'
import { faSync } from '@fortawesome/free-solid-svg-icons'

interface Props {
  productId: number
  code: string
  name: string
  price: number
  url: string
  onClick: () => void
  dataCy: string
  seeExistence: boolean
  existence: number
  loadingExistence: boolean
}

const ItemCard = ({
  productId,
  code,
  name,
  price,
  url,
  onClick,
  dataCy,
  seeExistence,
  existence,
  loadingExistence,
}: Props) => {
  const getItemInformation = (
    <h6
      className={'pos-item-code b-user-email'}
      style={seeExistence ? { color: existence > 0 ? 'green' : 'red' } : {}}>
      {seeExistence ? (
        <div className={'d-flex'}>
          Existencia:{' '}
          {loadingExistence ? (
            <Icon icon={faSync} spin tooltip={'Cargando'} />
          ) : (
            existence
          )}
        </div>
      ) : (
        code
      )}
    </h6>
  )

  return (
    <div
      key={productId}
      className={'pos-item a-in-opacity'}
      onClick={onClick}
      data-cy={dataCy}>
      <img className={'pos-item-img'} src={url ? url : Placeholder} alt={name} />
      <div className={'pos-item-data'}>
        <div className={'pos-item-label'}>
          <div className={'pos-item-name b-user-name'}>
            {name.length > 45 ? name.substring(0, 45) + '...' : name}
          </div>
        </div>

        <div className={'pos-item-component'}>
          {getItemInformation}
          <Money component="h5" className={'pos-item-price'}>
            {price}
          </Money>
        </div>
      </div>
    </div>
  )
}
export default ItemCard
