import React, { useContext } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FormText } from 'src/components'
import { IconImg, Switch, Select } from 'src/components/index'
import IconSearch from 'src/assets/v2/icon-search2.svg'
import { AccountMovementsContext } from 'src/providers/BankContext'

interface DetailsFiltersProps {
  handleFilterChange?: () => void
  users?: []
  activeTab?: string
}

const DetailsFilters: React.FC<DetailsFiltersProps> = ({
  handleFilterChange,
  users,
  activeTab,
}: DetailsFiltersProps) => {
  const { params, updateParams } = useContext(AccountMovementsContext) || {}
  const handleFilter = event => {
    const { name, checked } = event.target
    updateParams({ [name]: checked })
  }

  const renderSelectFilters = () => {
    return (
      <>
        <Col sm={12} md={4}>
          <Select
            label={'Operacion'}
            options={[
              { value: -1, label: 'Todos' },
              { value: 101, label: 'Acreditaciones a la cuenta' },
              { value: 102, label: 'Retiros de la cuenta' },
            ]}
            info={'Tipo de operación'}
          />
        </Col>
        <Col sm={12} md={4}>
          <Select
            label={'Creado por'}
            options={users}
            info={'Filtrar por usuario que creo la transacción'}
          />
        </Col>
        <Col sm={12} md={4}>
          <Select
            label={'Autorizado por'}
            options={users}
            info={'Filtrar por usuario que autorizo la transaccion'}
          />
        </Col>
      </>
    )
  }
  return (
    <>
      {activeTab === 'authorized' ? (
        <Row>
          <Col className={'borderRight'}>
            <FormText
              placeholder={'Buscar...'}
              id={'receipt'}
              label={'Por número de recibo'}
              info={
                ' Se buscaran todas los movimientos bancarios que esten asociados a los depositos que tengan el número de recibo que se ingresara en este campo.'
              }
              append={
                <IconImg
                  style={{ padding: '5px' }}
                  icon={IconSearch}
                  tooltip={'Buscar'}
                />
              }
              aPadding
            />
          </Col>
        </Row>
      ) : null}

      <Row className={'justify-content-center pl-2'}>
        {activeTab === 'authorized' ? (
          <>
            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <Switch
                name={'nRef'}
                //   onChange={handleFilter}
                label={' Sin referencia'}
                info={'Visualiza las transacciones sin No. De boleta'}
              />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <Switch
                name={'wRef'}
                label={' Con referencia'}
                info={'Visualiza las transacciones con No. De boleta'}
              />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <Switch
                name={'disbursement'}
                label={' Desembolso'}
                info={'Visualiza todos los desembolsos'}
              />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <Switch
                name={'deposit'}
                label={' Deposito'}
                info={'Visualiza todos los depósitos'}
              />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <Switch
                name={'expense'}
                label={' Gasto'}
                info={'Visualiza todos los movimientos de gastos'}
              />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <Switch
                name={'purchase'}
                label={' Compra'}
                info={'Visualiza las transacciones para compras'}
              />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <Switch
                name={'transfer'}
                label={' Transferencia'}
                info={'Visualiza las transferencias realizadas '}
              />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <Switch
                name={'duplicate'}
                label={'Duplicados'}
                info={'Visualiza las referencias duplicadas'}
              />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <Switch
                name={'voucher'}
                label={' Generado con Vale'}
                info={'Visualiza todos los movimientos que provienen de un vale.'}
              />
            </Col>
          </>
        ) : null}
      </Row>
      <Row>{renderSelectFilters()}</Row>
    </>
  )
}

export default DetailsFilters
