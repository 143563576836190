import { executeAction } from './global.actions'
import BooksService from 'src/services/books.service'

export const actions = {
  GET_PURCHASE_BOOK: 'GET_PURCHASE_BOOK',
  GET_PURCHASE_BOOK_EXCEL: 'GET_PURCHASE_BOOK_EXCEL',
}

/** Obtains a list of purchases and expenses with information necessary for the purchase book.
 * @function
 * @param {Object} params Filters parameters
 * @param {number} params.start Initial date for taking results
 * @param {number} params.end Finish date for taking results
 * @param {Boolean} params.bill Indicates if all documents should be shown, only invoices or only receipts.
 * @param {boolean} params.byCreationDate Indicates by which type of date to filter.
 * **/
export const purchaseBookReport = params => dispatch => {
  const process = async () => ({ payload: await BooksService.purchaseBookReport(params) })
  dispatch(executeAction(actions.GET_PURCHASE_BOOK, process))
}

/** Obtains an EXCEL of purchases and expenses with the necessary information for the purchase book.
 * @function
 * @param {Object} params Filters parameters
 * @param {number} params.start Initial date for taking results
 * @param {number} params.end Finish date for taking results
 * @param {Boolean} params.bill Indicates if all documents should be shown, only invoices or only receipts.
 * @param {boolean} params.byCreationDate Indicates by which type of date to filter.
 * **/
export const purchaseBookReportExcel = params => dispatch => {
  const process = async () => await BooksService.purchaseBookReportExcel(params)
  dispatch(executeAction(actions.GET_PURCHASE_BOOK_EXCEL, process))
}

/** Obtains an EXCEL of purchases and expenses with the necessary information for the purchase book.
 * @function
 * @param {Object} params Filters parameters
 * @param {number} params.startDate Initial date for taking results
 * @param {number} params.endDate Finish date for taking results
 * @param {Boolean} params.status Indicates the status of the order
 * @param {string} params.search Filter search by order number.
 * @param {number} params.providerId Filter search by provider.
 * @param {string} params.categories Filter search by category.
 * @param {number} params.warehouseId Filter search by warehouse.
 * @param {number} params.overdueType Filter search by overdue type.
 * **/
export const cppBookReportExcel = params => dispatch => {
  const process = async () => await BooksService.cppBookReportExcel(params)
  dispatch(executeAction(actions.GET_PURCHASE_BOOK_EXCEL, process))
}
