import { $http } from './http'

class ExpensesService {
  getExpenseType = async () => {
    const response = await $http.get(`/expense/types`)
    return response.data
  }

  getExpenses = async () => {
    const response = await $http.get(`/expense`)
    return response.data
  }

  getExpensesProducts = async () => {
    const response = await $http.get(`/expense/products`)
    return response.data
  }

  getExpensesAdmin = async () => {
    const response = await $http.get(`/expense/admin`)
    return response.data
  }

  createExpenseRequest = async (
    number,
    series,
    nit,
    description,
    total,
    type,
    products,
  ) => {
    const response = await $http.post(`/expense`, {
      expense: { number, series, nit, description, total, type },
      productos: products,
    })
    return response.data
  }

  updateDepositStatus = async (state, toUpdate, message) => {
    /*const querystring = require('query-string')
    let queryString = querystring.stringify({ message: message })
    console.log(state, toUpdate, message)
    const response = await $http.put(
      `/expense/state/${state}`.concat(message ? `?${queryString}` : ''),
      toUpdate,
    )
    return response.data*/
    return null
  }

  creditExpense = async (expense, amount, type, list) => {
    console.log('in service')
    const response = await $http.post(
      `/expense/credit/${expense}?amount=${amount}&type=${type}`,
      list,
    )
    return response.data
  }

  getExpenseLog = async expense => {
    const response = await $http.get(`/changeLog/${expense}/0`)
    return response.data
  }
}
export default new ExpensesService()
