import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Modal } from 'react-bootstrap'
import { TableV2, Icon, CustomReference } from 'src/components'
import { faBook } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes as type,
  getInventoryListByWarehouse,
  getInventoryOrigin,
} from 'src/actions/transformation.actions'
import { selectInventoryList } from 'src/selectors/transformation.selector'
import { selectCurrentCompany } from 'src/selectors/user.selector'
import { loadingSelector } from 'src/selectors/loading.selector'

import { itemTypes, toMoney } from 'src/utils/utilities'
import { onSetModalOrder, onSetModalPurchase } from 'src/actions/utilities.actions'
import ModalTransferDetail from '../../content/Transfer/DetailTransfers/ModalTransferDetail'

const canGetReferenceDetail: number[] = [
  itemTypes.SELL,
  itemTypes.WASTE,
  itemTypes.PRODUCTION,
  itemTypes.PURCHASE,
  itemTypes.TRANSFER_TRANSFORMATION,
  itemTypes.TRANSFER,
]

interface IReference {
  show: boolean
  transfer?: boolean
  documentType: number
  documentId: number
  title?: string
}

interface Props {
  documentId?: number
  documentType?: number
  warehouse?: number
  product?: number
  showItem?: boolean
  reserved?: boolean
  close: () => void
  productName: string
}

/** Show Origin by inventory transaction or existence
 * @param {number} documentId Id of document transaction
 * @param {number} documentType Type of document transaction
 * @param {number} warehouse Id of warehouse with inventory existence
 * @param {number} product Id of item with inventory existence
 * @param {function} close CallBock when click in close button
 * @param {string} productName Title of modal
 * @param {boolean} showItem Show or hide items name
 * @param {boolean} reserved
 *
 * **/
const InventoryReport = ({
  documentId,
  documentType,
  warehouse,
  product,
  close,
  productName,
  showItem = false,
  reserved = false,
}: Props) => {
  const dispatch = useDispatch()

  const company = useSelector(selectCurrentCompany)

  const inventory: originInventory[] = useSelector(selectInventoryList)
  const loading = useSelector(state =>
    loadingSelector([type.GET_INVENTORY_HISTORY])(state),
  )

  const [warehouseId, setWarehouseId] = useState(null)
  const [customReference, setCustomReference] = useState<IReference>({
    show: false,
    documentId: null,
    documentType: null,
    transfer: false,
    title: null,
  })

  useEffect(() => {
    if (documentId) {
      setWarehouseId(documentType)
    } else if (warehouse) {
      setWarehouseId(warehouse)
    }
  }, [documentId, documentType, warehouse])

  useEffect(() => {
    if (!warehouseId) return
    if (documentId) dispatch(getInventoryOrigin(warehouseId, documentId))
    else dispatch(getInventoryListByWarehouse(warehouseId, product, reserved))
  }, [warehouseId])

  const getReference = (item: originInventory) => {
    switch (item.transactionType) {
      case itemTypes.SELL:
        dispatch(onSetModalOrder(item.originDocument))
        break
      case itemTypes.PRODUCTION:
      case itemTypes.WASTE:
        setCustomReference({
          show: true,
          documentId: item.originDocument,
          documentType: item.originDocumentType,
        })
        break
      case itemTypes.PURCHASE:
        dispatch(onSetModalPurchase(item.originDocument))
        break
      case itemTypes.TRANSFER_TRANSFORMATION:
      case itemTypes.TRANSFER:
        setCustomReference({
          ...customReference,
          transfer: true,
          documentId: item.originDocument,
          title: `${item.documentTypeName} ${item.reference}`,
        })
        break
    }
  }

  const headers: ITableHeader[] = [
    {
      show: true,
      label: 'Fecha',
      type: 'date',
      value: ['date'],
    },
    {
      show: true,
      label: 'Tipo',
      type: 'text',
      value: ['documentTypeName'],
    },
    {
      show: true,
      label: 'Referencia',
      type: 'text',
      value: ['reference'],
    },
    {
      show: showItem,
      label: 'SKU',
      type: 'text',
      value: ['code'],
    },
    {
      show: showItem,
      label: 'Nombre',
      type: 'text',
      value: ['name'],
    },
    {
      show: true,
      label: 'Cantidad',
      type: 'number',
      value: ['quantity'],
    },
    {
      show: !reserved,
      label: 'Costo p/u',
      type: 'currency',
      value: ['purchasedPrice'],
      custom: (item: originInventory) => toMoney(item.purchasedPrice / item.quantity),
    },
    {
      show: !reserved,
      label: 'Costo T.',
      type: 'currency',
      value: ['purchasedPrice'],
    },
    {
      show: true,
      label: '',
      type: 'text',
      value: ['button'],
      config: true,
      custom: (item: originInventory) =>
        canGetReferenceDetail.some(v => item.transactionType === v) && (
          <Icon
            icon={faBook}
            tooltip={'Ver referencia'}
            onClick={() => getReference(item)}
          />
        ),
    },
  ]

  return (
    <div>
      <Modal
        show={warehouseId != null && !customReference.show}
        size={'xl'}
        centered
        onHide={() => {
          setWarehouseId(null)
          close()
        }}>
        <Modal.Header closeButton>
          <Modal.Title>{productName || 'Origen de la existencia'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableV2
            // @ts-ignore
            loading={loading}
            items={inventory}
            headers={headers}
            storageKey={`invtReport-${company}`}
            mobileAuto
          />
        </Modal.Body>
      </Modal>

      <CustomReference
        {...customReference}
        documentModule={5}
        onHide={() => setCustomReference({ ...customReference, show: false })}
      />

      <ModalTransferDetail
        show={customReference.transfer}
        id={customReference.documentId}
        title={customReference.title}
        isProduction
        onHide={() => setCustomReference({ ...customReference, transfer: false })}
        onRefresh={() => undefined}
      />
    </div>
  )
}
export default InventoryReport
