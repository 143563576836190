export const selectSubscribesReport = state => state.report.subscriptions
export const selectSegmentationReport = state => state.report.report
export const selectQueriesReport = state => state.report.queries
export const selectScheduledReports = state => state.report.scheduledReports
export const selectSingleScheduledReport = state => state.report.scheduledSingleReport
export const selectPurchaseWasteReport = state =>
  state.report.purchaseWasteReport?.map(r => ({ ...r, label: r.name, products: [r] }))
export const selectBranchReport = state => state.report.branchReport
export const selectReportSegmented = state => state.report.reportSegmented
export const selectItemSaleReport = state => state.report.itemSaleReport
export const selectTotalSaleReport = state => state.report.totalSaleReport
export const selectVehicleReport = state => state.report.vehicleReport
