import { types } from '../actions/utilities.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  alert: { title: 'default' },
  phonesCompanies: [],
  businessTypes: [],
  archived: [],
  methods: [],
  report: {},
  reference: {},
  inventory: [],
  inventorySelected: [],
  modalOrderId: null,
  modalOrder: {},
  modalConfirm: { show: false },
  modalOrderPayment: {},
  refresh: false,
  hide: false,
  processId: null,
  transferData: {},
  paymentsData: { order: {}, show: false },
  summary: {},
  presale: null,
  showModalWelcome: false,
  modalPurchaseId: null,
  onClosePurchase: () => undefined,
  modalPurchase: {},
  modalOrderCurrency: {},
  modalUsers: { show: false, users: [] },
  modalAddUser: { show: false, id: null },
  modalInvoicePackages: { show: false },
  modalCreateCard: { show: false },
  modalManageCards: { show: false },
  modalManageConcepts: { show: false },
  modalCreateConcepts: { show: false },
  modalCreateClient: { show: false },
  modalEditClient: { show: false },
  modalCreateCashTransfer: { show: false },
  modalPendingPayment: {
    active: false,
    key: 'last-notification-payment',
  },
}

const UtilitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(types.MODAL_WELCOME_SHOW):
      return { ...state, showModalWelcome: extract(action.show, false) }
    case successState(types.SET_ORDER_MODAL_SUMMARY):
      return { ...state, summary: extract(action.values, {}) }
    case successState(types.SET_ORDER_MODAL_TRANSFER):
      return { ...state, transferData: extract(action.data, {}) }
    case successState(types.SET_ORDER_MODAL_PAYMENTS):
      return { ...state, paymentsData: extract(action.data, {}) }
    case successState(types.SET_ORDER_PROCESS_ID):
      return { ...state, processId: extract(action.processId, null) }
    case successState(types.HIDE_NAVIGATION):
      return { ...state, hide: extract(action.hide, false) }
    case successState(types.ON_REFRESH):
      return { ...state, refresh: extract(action.refresh, false) }
    case successState(types.GET_MODAL_ORDER):
      return { ...state, modalOrder: action.order }
    case successState(types.GET_MODAL_CONFIRM):
      return {
        ...state,
        modalConfirm: {
          ...action.order,
          show: true,
          confirmBy: null,
          paymentDate: null,
          commentary: null,
          retention: null,
          gratification: null,
          cash: null,
          dontCertificate: false,
        },
      }
    case successState(types.SET_MODAL_CONFIRM):
      return {
        ...state,
        modalConfirm: action.modalConfirm,
      }
    case successState(types.SET_MODAL_ORDER_PAYMENT):
      return {
        ...state,
        modalOrderPayment: action.payload,
      }
    case types.MODAL_ORDER:
      return {
        ...state,
        modalOrderId: action.id,
        presale: false,
        modalOrderCurrency: action.currency,
      }
    case successState(types.MODAL_PURCHASE):
      return {
        ...state,
        modalPurchaseId: action.seePurchase.id,
        onClosePurchase: action.seePurchase.onClose,
        presale: false,
      }
    case successState(types.MODAL_USERS):
      return { ...state, modalUsers: action.payload }
    case successState(types.MODAL_ADD_USER):
      return { ...state, modalAddUser: action.payload }
    case successState(types.MODAL_INVOICE_PACKAGES):
      return { ...state, modalInvoicePackages: action.payload }
    case successState(types.MODAL_CREATE_CARD):
      return { ...state, modalCreateCard: action.payload }
    case successState(types.MODAL_MANAGE_CARDS):
      return { ...state, modalManageCards: action.payload }
    case successState(types.MODAL_MANAGE_CONCEPTS):
      return { ...state, modalManageConcepts: action.payload }
    case successState(types.MODAL_CREATE_CONCEPTS):
      return { ...state, modalCreateConcepts: action.payload }
    case successState(types.MODAL_CREATE_CLIENT):
      return { ...state, modalCreateClient: action.payload }
    case successState(types.MODAL_EDIT_CLIENT):
      return { ...state, modalEditClient: action.payload }
    case successState(types.MODAL_CREATE_CASH_TRANSFER):
      return { ...state, modalCreateCashTransfer: action.payload }
    case successState(types.GET_MODAL_PURCHASE):
      return { ...state, modalPurchase: action.purchase }
    case types.MODAL_ORDER_PRESALE:
      return { ...state, modalOrderId: action.id, presale: true }
    case successState(types.INVENTORY_SELECTED):
      return {
        ...state,
        inventorySelected: extract(action.inventorySelected, []),
      }
    case successState(types.INVENTORY_BY_COMPONENT):
      return { ...state, inventory: extract(action.inventory, []) }
    case successState(types.CUSTOM_REFERENCE):
      return { ...state, reference: extract(action.reference, {}) }
    case successState(types.GET_METHODS_PAYMENT):
      return { ...state, methods: extract(action.methods, []) }
    case successState(types.GET_BUSINESS_TYPES):
      return {
        ...state,
        businessTypes: extract(action.businessTypes, []),
      }
    case successState(types.GET_PHONE_COMPANIES):
      return {
        ...state,
        phonesCompanies: extract(action.phonesCompanies, []),
      }
    case types.SET_DATA_TO_ALERT:
      return {
        ...state,
        alert: action.alert,
      }
    case successState(types.GET_ARCHIVED_DATA):
      return {
        ...state,
        archived: action.archived,
      }
    case successState(types.GET_REPORT):
      return {
        ...state,
        report: action.report,
      }

    case successState(types.MODAL_PENDING_PAYMENT):
      return {
        ...state,
        modalPendingPayment: {
          ...state.modalPendingPayment,
          active: extract(action.response, false),
        },
      }
    default:
      return state ? state : initialState
  }
}
export default UtilitiesReducer
