import countries from 'src/enums/countries'
import { $http } from './http'
import { saveAs } from 'file-saver'

class MentorService {
  getCompanies = async (params: MFilterParams) => {
    const response = await $http.get<unknown>(`/company/mentors`, { params })

    if (response.data !== undefined) {
      // @ts-ignore
      response.data.response = response.data.response.map(item => {
        return { ...item, country: countries[item.country] }
      })
    }
    return response.data
  }

  getCompaniesExcel = async (params: MFilterParams) => {
    const response = await $http.get<unknown>(`/company/mentors/excel`, {
      params,
      responseType: 'blob',
    })
    const name = `empresas ${params.isDemo ? 'demo' : 'operativas'}.xlsx`
    // @ts-ignore
    const file = new Blob([response.data], { type: 'application/vnd.ms-excel' })
    saveAs(file, name)
  }

  updatePlanCompany = async (companyId: number, planId: number, planPrice?: number) => {
    const response = await $http.put<unknown>(
      '/company/mentors/update/plan',
      {},
      { params: { companyId, planId, planPrice } },
    )
    return response.data
  }

  createDemoAccount = async (request: MCompanyData) => {
    const response = await $http.post<unknown>('/company/mentors', request)
    return response.data
  }

  getCompany = async (companyId: number) => {
    const response = await $http.get<unknown>(`/company/mentors/${companyId}`)
    return response.data
  }

  updateAccount = async (companyId: number, request: MCompanyData) => {
    const response = await $http.put<unknown>(`/company/mentors/${companyId}`, request)
    return response.data
  }

  updateDateAccount = async (companyId: number, date: number) => {
    const response = await $http.put<unknown>(
      `/company/mentors/update/date/${companyId}/demo`,
      date,
    )
    return response.data
  }

  getCompanyAddons = async (companyId: number) => {
    const response = await $http.get<unknown>(`/company/mentors/${companyId}/addons`)
    return response.data
  }

  addNewAddons = async (companyId: number, request: MCompanyAddons[]) => {
    const response = await $http.post<unknown>(
      `/company/mentors/${companyId}/addons`,
      request,
    )
    return response.data
  }

  onValidateUpdateAddon = async (
    companyId: number,
    request: MCompanyAddonsEntityResponse[],
  ) => {
    const response = await $http.put<unknown>(
      `/company/mentors/${companyId}/addons/validate`,
      request,
    )
    return response.data
  }

  updateAddons = async (companyId: number, request: MCompanyAddons[]) => {
    const response = await $http.put<unknown>(
      `/company/mentors/${companyId}/addons`,
      request,
    )
    return response.data
  }

  onFinishDemoAccount = async (companyId: number, request: IFinishDemoRequest) => {
    const response = await $http.put<unknown>(
      `/company/mentors/${companyId}/finish/demo`,
      request,
    )
    return response.data
  }

  onPostponePayment = async (sellId: number) => {
    const response = await $http.put<unknown>('/company/mentors/invoice/postpone', sellId)
    return response.data
  }

  getCompanyConfigurationData = async (companyId: number) => {
    const response = await $http.get<unknown>(`/company/mentors/${companyId}/fields`)
    return response.data
  }

  updateCompanyConfigurationData = async (companyId: number, list: FieldCompany[]) => {
    const response = await $http.post<unknown>(
      `/company/mentors/${companyId}/fields`,
      list,
    )
    return response.data
  }

  getPlanOrders = async () => {
    const response = await $http.get<unknown>(`/company/mentors/order/plan`)
    return response.data
  }

  getPlanOrderDetails = async (orderId: number) => {
    const response = await $http.get<unknown>(`/company/mentors/order/plan/${orderId}`)
    return response.data
  }

  getClientByCotizapClient = async (cotizapClientId: number, companyId: number) => {
    const response = await $http.get<unknown>(
      `/clients/cotizap/${cotizapClientId}/${companyId}`,
    )
    return response.data
  }

  getCompanyActivities = async (params: MFilterParams) => {
    const response = await $http.get<unknown>(`/company/mentors/activity`, { params })
    return response.data
  }

  getCompanyActivitiesExcel = async (params: MFilterParams) => {
    const response = await $http.get<unknown>(`/company/mentors/activity/excel`, {
      params,
      responseType: 'blob',
    })

    const name = `Actividad-de-empresas.xlsx`
    // @ts-ignore
    const file = new Blob([response.data], { type: 'application/vnd.ms-excel' })
    saveAs(file, name)
  }

  getExpiredInvoicesReport = async () => {
    const response = await $http.get<unknown>(`/company/mentors/invoice/expired`, {
      responseType: 'blob',
    })

    const name = `facturas-a-vencer.xlsx`
    // @ts-ignore
    const file = new Blob([response.data], { type: 'application/vnd.ms-excel' })
    saveAs(file, name)
  }
}
export default new MentorService()
