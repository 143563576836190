import React, {Component} from 'react';
import {connect} from "react-redux"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import './CotizapClients.scss'
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-relative-link";
import {getAllCotizapClients} from "../../../actions/clients.actions";
import {selectAllCotizapClients} from "../../../selectors/clients.selector";
import Card from "../../../components/cards/Card";
import {Action, TableV2, Title} from "../../../components";

class CotizapClients extends Component {

    componentDidMount() {
        this.props.getAllCotizapClients();
    }

    onChangePage = (pager) => {
        this.setState({pager: pager})
    };

    toDetails = (client) => {
        this.props.history.push('clientes/detalles')
    };

   

    render() {
        const {allCotizapClients} = this.props;
        return (
            <div style={{paddingBottom: 50}}>
                <Title
                  title={'Clientes'}
                  to={'nuevo'}
                  action
                  actionTitle={'Crear nuevo cliente'}
                />
                <Row>
                    <Col>
                        <Action
                          to={'nuevo'} action
                          actionTitle={'Crear nuevo cliente'}
                        />
                        <Card title={'Clientes Globales'} white>
                            <TableV2
                                headers={[
                                    {label: 'Código', show: true, value: ['id'], type: 'text' ,  className:'mini'},
                                    {label: 'Nombre', show: true, value: ['companyName'], type: 'text' ,  className:'mini'},
                                    {
                                        label: 'Direccion', show: true, value: ['amount'], type: 'text', className: 'mini', action:true, select:true,
                                        custom: (item) => <div className={'big-button'} style={{ color: 'white' }}>
                                            <Link to={`editar/${item.id}`}>Editar</Link>
                                        </div>
                                    },
                                    {config:true, show: true, label:'', className:'mini center'}

                                ]}
                                items={allCotizapClients}
                                mobileAuto
                                storageKey={`globalClients`}
                            />

                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

CotizapClients.defaultProps = {
    allCotizapClients: [],
};

const mapStateToProps = (state) => ({
    allCotizapClients: selectAllCotizapClients(state),
});

const mapDispatchToProps = dispatch => ({
    getAllCotizapClients: () => dispatch(getAllCotizapClients()),

});

export default connect(mapStateToProps, mapDispatchToProps)(CotizapClients);
