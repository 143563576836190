import { executeAction } from './global.actions'

export const actionTypes = {
  SET_CLIENT_BY_STEPS: 'SET_CLIENT_BY_STEPS',
  SET_ORDER_BY_STEPS: 'SET_ORDER_BY_STEPS',
  SET_WAREHOUSE_BY_STEPS: 'SET_WAREHOUSE_BY_STEPS',
  SET_PRODUCTS_BY_STEPS: 'SET_PRODUCTS_BY_STEPS',
}

export const setClient = client => dispatch => {
  const process = () => ({ client: client })
  dispatch(executeAction(actionTypes.SET_CLIENT_BY_STEPS, process))
}

export const setOrder = order => dispatch => {
  const process = () => ({ order: order })
  dispatch(executeAction(actionTypes.SET_ORDER_BY_STEPS, process))
}

export const setWarehouse = warehouse => dispatch => {
  const process = () => ({ warehouse: warehouse })
  dispatch(executeAction(actionTypes.SET_WAREHOUSE_BY_STEPS, process))
}

export const setProducts = products => dispatch => {
  const process = () => ({ products: products })
  dispatch(executeAction(actionTypes.SET_PRODUCTS_BY_STEPS, process))
}
