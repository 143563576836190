import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Modal } from 'react-bootstrap'
import { Title, Card, Icon, Dropdown, TableV2 } from '../../components'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import Alert from 'sweetalert-react'

import {
  actionTypes as typeS,
  getSubscriptions,
  getOrders,
  updateSubscription,
  concludeSubscription,
} from '../../actions/subscriptions.actions'

import { loadingSelector } from '../../selectors/loading.selector'
import {
  handlerError,
  handlerSuccess,
  singleErrorSelector,
  hasErrorsSelector,
} from '../../selectors/error.selector'

import { formatDateFromMillis } from '../../utils/formatters'

import { onSetModalOrder } from '../../actions/utilities.actions'

import CreateSubscription from './CreateSubscription'
import Money from '../../components/Money/Money'
import { useLocation } from 'react-router-dom'
import { isAllowed } from '../../selectors/modules.selector'

const Subscription = ({ mini, own, queryParams, noAction }) => {
  const dispatch = useDispatch()

  const subscriptions = useSelector(state => state.subscriptions.subscriptions)
  const loading = useSelector(state => loadingSelector([typeS.GET_SUBSCRIPTIONS])(state))

  const orders = useSelector(state => state.subscriptions.orders)
  const loadingO = useSelector(state => loadingSelector([typeS.GET_ORDERS])(state))

  const loadingU = useSelector(state =>
    loadingSelector([typeS.UPDATE_SUBSCRIPTION])(state),
  )
  const hasErrorU = useSelector(state =>
    hasErrorsSelector([typeS.UPDATE_SUBSCRIPTION])(state),
  )
  const errorU = useSelector(state =>
    singleErrorSelector([typeS.UPDATE_SUBSCRIPTION])(state),
  )

  const loadingC = useSelector(state =>
    loadingSelector([typeS.CONCLUDE_SUBSCRIPTION])(state),
  )
  const hasErrorC = useSelector(state =>
    hasErrorsSelector([typeS.CONCLUDE_SUBSCRIPTION])(state),
  )
  const errorC = useSelector(state =>
    singleErrorSelector([typeS.CONCLUDE_SUBSCRIPTION])(state),
  )

  const [actions, setActions] = useState({ update: false, conclude: false })

  const [init, setInit] = useState(false)
  const [textFilter, setTextFilter] = useState('')
  const [filters, setFilter] = useState({
    client: null,
    clientType: null,
    start: null,
    end: null,
    name: '',
  })
  const [modal, setModal] = useState({ show: false, number: '00001' })
  const [edit, setEdit] = useState({ show: false })
  const [alert, setAlert] = useState({ title: '' })
  const [selected, setSelected] = useState(null)

  /* -- Permisos Saldos -- */
  const [fromClients] = useState(useLocation().pathname.includes('/clientes'))
  const seeMoneyBalances = useSelector(state => isAllowed(state, [1067, 2167]))

  useEffect(() => {
    let params = queryParams || window.localStorage.getItem('subscription-params')
    if (params) {
      params = !queryParams ? JSON.parse(params) : queryParams
      setFilter({
        ...filters,
        client: params.id,
        clientType: params.type,
        name: params.name,
      })
      setInit(true)
    }
  }, [])

  useEffect(() => {
    if (!init) return
    setTextFilter(JSON.stringify(filters))
  }, [init])

  useEffect(() => {
    if (textFilter === '' || textFilter === JSON.stringify(filters)) return
    setTextFilter(JSON.stringify(filters))
  }, [filters])

  useEffect(() => {
    if (textFilter !== JSON.stringify(filters)) return
    setUp()
  }, [textFilter])

  useEffect(() => {
    if (loadingU) setActions({ ...actions, update: true })
    else if (actions.update) {
      setActions({ ...actions, update: false })
      setSelected(null)
      if (hasErrorU) onAlert(true, errorU.message)
      else onAlert(false, 'Subscripción editada satisfactoriamente.', () => setUp())
    }
  }, [loadingU])

  useEffect(() => {
    if (loadingC) setActions({ ...actions, conclude: true })
    else if (actions.conclude) {
      setActions({ ...actions, conclude: false })
      setSelected(null)
      if (hasErrorC) onAlert(true, errorC.message)
      else onAlert(false, 'Suscripción concluida satisfactoriamente.', () => setUp())
    }
  }, [loadingC])

  const setUp = () => {
    dispatch(getSubscriptions({ ...filters, own }))
  }

  const renderTable = () => {
    let headers = [
      {
        label: 'Alias',
        show: true,
        value: ['alias'],
        type: 'text',
        className: 'medium',
      },
      {
        label: 'Estado',
        show: true,
        value: ['estadoC'],
        type: 'text',
        className: 'mini',
        custom: item => (item.estadoC = item.conclude ? 'Terminado' : 'Vigente'),
      },
      {
        label: 'Cantidad',
        show: true,
        value: ['quantity'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Monto',
        show: true,
        value: ['total'],
        type: 'currency',
        className: 'mini',
      },
      {
        label: 'Encargado',
        show: !mini,
        value: ['user'],
        type: 'text',
        className: 'medium',
      },
      {
        label: 'Pagos',
        show: !mini,
        value: ['recurrence'],
        type: 'currency',
        classNameCustom: () => ('mini ' + mini ? 'hide-td' : ''),
      },
      {
        label: 'Pagados',
        show: !mini,
        value: ['payed'],
        type: 'currency',
        classNameCustom: () => ('mini ' + mini ? 'hide-td' : ''),
      },
      {
        label: 'Modo de cobro',
        show: !mini,
        value: ['modoC'],
        type: 'text',
        className: 'mini ',
        custom: item => (item.modoC = item.automatic ? 'Automático' : 'Manual'),
      },
      {
        label: 'Fecha inicio',
        show: true,
        value: ['startDate'],
        type: 'date',
        className: 'mini',
      },
      {
        label: 'Fecha fin',
        show: !mini,
        value: ['endDate'],
        type: 'date',
        className: 'mini',
      },
      {
        label: 'Próximo Cobro',
        show: true,
        value: ['nextDate'],
        type: 'date',
        className: 'mini',
      },
      {
        config: true,
        show: !noAction,
        label: '',
        className: 'mini center',
        custom: item => (
          <Dropdown
            loading={selected === item.id && (loadingU || loadingC)}
            items={[
              {
                title: 'Ver suscripción',
                action: () => {
                  dispatch(onSetModalOrder(item.reference))
                },
              },
              {
                title: 'Ver cobros',
                action: () => {
                  dispatch(getOrders(item.id))
                  setModal({ ...modal, show: true })
                },
                show: fromClients ? seeMoneyBalances : true,
              },
              {
                title: 'Editar suscripción',
                action: () => {
                  setEdit({
                    amount: item.order?.total,
                    show: true,
                    id: item.id,
                    data: { ...item, startDate: new Date(item.startDate) },
                  })
                },
              },
              {
                title: 'Concluir suscripción',
                action: () => {
                  setAlert({
                    title: `Concluir suscripción: ${item.alias}`,
                    text: '¿Desea concluir la suscripción?',
                    type: 'warning',
                    showCancelButton: true,
                    cancelButtonText: 'Cancelar',
                    show: true,
                    confirmButtonColor: '#B35796',
                    confirmButtonText: 'concluir',
                    onCancel: () => setAlert({ ...alert, show: false }),
                    onConfirm: () => {
                      setSelected(item.id)
                      setAlert({ ...alert, show: false })
                      dispatch(concludeSubscription(item.id))
                    },
                  })
                },
              },
            ]}
          />
        ),
      },
    ]

    return (
      <>
        <TableV2
          loading={loading}
          items={subscriptions}
          headers={headers}
          mobileAuto
          storageKey={`subscription`}
          balancePermission={fromClients ? seeMoneyBalances : true}
        />
      </>
    )
  }

  const onAlert = (isError, message, action) => {
    const al = isError ? { ...handlerError(message) } : { ...handlerSuccess(message) }
    setAlert({
      ...al,
      onConfirm: () => {
        setAlert({ ...alert, show: false })
        if (action) action()
      },
    })
  }

  return (
    <div>
      {!mini && <Title title={'Suscripciones: '.concat(filters.name)} />}

      {!mini ? (
        <Card white title={'Listado'}>
          {renderTable()}
        </Card>
      ) : (
        renderTable()
      )}

      <Modal
        show={modal.show}
        centered
        size={'md'}
        onHide={() => setModal({ ...modal, show: false })}>
        <Modal.Header closseButton>
          <Modal.Title>Suscripción: {modal.number}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableV2
            hideFilter
            loading={loadingO}
            items={orders}
            renderRow={(item, index) => (
              <div className={'b-user-item'} key={index}>
                <div
                  className={'justify-content-start align-items-start bu-en'}
                  style={{ textAlign: 'left' }}>
                  <div className={'b-user-name'}>
                    {item.number} - {formatDateFromMillis(item.createdAt)}
                  </div>
                  <div className={'b-user-email'}>
                    {item.status === 1
                      ? 'Pendiente'
                      : item.status === 3
                      ? `Pagado ${formatDateFromMillis(item.enabledAt)}`
                      : ''}
                  </div>
                  <Money className={'b-user-balance-m'}>{item.total}</Money>
                </div>
                <div
                  className={'d-flex row align-items-center mr-2 bu-b'}
                  style={{ textAlign: 'right' }}>
                  <Money className={'b-user-balance mr-2'}>{item.total}</Money>
                  <Icon
                    icon={faEye}
                    tooltip={'Ver cobro'}
                    onClick={() => {
                      dispatch(onSetModalOrder(item.id))
                    }}
                  />
                </div>
              </div>
            )}
          />
        </Modal.Body>
      </Modal>

      <CreateSubscription
        subscriptionType={1}
        show={edit.show}
        modal
        type={2}
        onHide={() => setEdit({ ...edit, show: false })}
        data={edit.data}
        amount={edit.amount}
        onCreate={({ alias, paymentDay, recurrence, automatic }) => {
          setEdit({ ...edit, show: false })
          const request = { alias, paymentDay, recurrence, automatic }
          setAlert({
            title: 'Editar Subscripción',
            text: '¿Desea modificar la información de la suscripción?',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            show: true,
            confirmButtonColor: '#12A8BB',
            confirmButtonText: 'Editar',
            onCancel: () => setAlert({ ...alert, show: false }),
            onConfirm: () => {
              setSelected(edit.id)
              setAlert({ ...alert, show: false })
              dispatch(updateSubscription(edit.id, request))
            },
          })
        }}
      />

      <Alert {...alert} />
    </div>
  )
}
export default Subscription
