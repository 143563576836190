import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import StepZilla from 'react-stepzilla'
import { Col, Row } from 'react-bootstrap'
import { Card } from 'src/components'

import { getAllLocalities } from 'src/actions/address.actions'
import { onCreateClientStep } from 'src/actions/clients.actions'

import RequiredInformation from './RequiredInformation'
import SpecificClient from 'src/content/Clients/Create/SpecificClient'
import { geolocated } from 'react-geolocated'
import { validateGeoLocation } from 'src/utils/utilities'
import { selectCreate } from 'src/selectors/clients.selector'

const CreateClientIndex = props => {
  const client = useSelector(selectCreate)

  const {
    inModal,
    onlyCloseModal,
    isGeolocationAvailable,
    isGeolocationEnabled,
    coords,
  } = props

  const dispatch = useDispatch()

  const steps = [
    {
      name: 'Información necesaria',
      component: (
        <RequiredInformation
          jumpToStep
          inModal={inModal}
          onlyCloseModal={onlyCloseModal}
        />
      ),
    },
    {
      name: 'Información complementaria',
      component: <SpecificClient props={props} onlyCloseModal={onlyCloseModal} />,
    },
  ]

  const getLocation = async () => {
    const gpsResponse = await validateGeoLocation(
      { isGeolocationAvailable, isGeolocationEnabled, coords },
      null,
      null,
      null,
      true,
      true,
    )
    dispatch(
      onCreateClientStep({
        ...client,
        latitude: gpsResponse.cc.latitude,
        longitude: gpsResponse.cc.longitude,
      }),
    )
  }

  useEffect(() => {
    dispatch(getAllLocalities())
    dispatch(
      onCreateClientStep({
        images: [],
        auto: true,
        nit: 'CF',
        address: 'Ciudad',
        code: '',
        companyName: '',
        ...client,
      }),
    )
  }, [])

  useEffect(() => {
    if (!coords) return
    getLocation()
  }, [coords])

  return (
    <div>
      <Card>
        <Row>
          <Col>
            <div className={'step-progress'}>
              <StepZilla
                steps={steps}
                stepsNavigation={false}
                dontValidate={true}
                showNavigation={false}
              />
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  )
}
export default geolocated({
  positionOptions: { enableHighAccuracy: true, userDecisionTimeout: 5000 },
})(CreateClientIndex)
