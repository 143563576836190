export const selectCurrentUser = state => state.user.currentUser || {}

export const selectUserCompanies = state => state.user.companies
export const selectCurrentCompany = state => state.user.currentCompany
export const selectCurrentModule = state => state.user.currentModule

export const selectCreateResponse = state => state.user.createResponse
export const selectSearchResponse = state => state.user.searchResponse
export const selectRecoverPass = state => state.user.recoverMessage
export const selectUserError = state => state.user.error
export const selectUserProfile = state => state.user.profile

export const selectUsersByUser = state => state.user.users

export const selectUserFields = state => {
  let { fieldsResponse } = state.user
  if (fieldsResponse)
    return fieldsResponse.map(field => {
      const { fieldType } = field
      let type = 'text'
      switch (fieldType) {
        case 'string':
          type = 'text'
          break
        case 'long':
          type = 'date'
          break
        default:
          type = fieldType
      }
      return { ...field, type }
    })
  else return []
}

export const selectUpdateFieldsResponse = state => state.user.updateFieldsResponse
