import 'src/components/custom/RecipeDetail/RecipeDetail.scss'
import 'src/content/Balance/Balance.scss'

import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Media } from 'react-breakpoints'
import { Modal, Row, Col, ProgressBar } from 'react-bootstrap'
import { CustomFilter, Button, Icon } from '../../components'
import Alert from 'sweetalert-react'

import {
  faWindowClose,
  faPlus,
  faSave,
  faArrowRight,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  setClientViewersUser,
  getClientViewersByClientId,
} from 'src/actions/clients.actions'

import { isAllowed, selectUsers } from 'src/selectors/modules.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerSuccess,
  handlerError,
  singleErrorSelector,
  hasErrorsSelector,
} from 'src/selectors/error.selector'

const ClientViewers = ({ show, id, name, onHide }) => {
  const dispatch = useDispatch()

  const loading = useSelector(state =>
    loadingSelector([actionTypes.SET_CLIENT_VIEWER])(state),
  )
  const hasError = useSelector(state =>
    hasErrorsSelector([actionTypes.SET_CLIENT_VIEWER])(state),
  )
  const error = useSelector(state =>
    singleErrorSelector([actionTypes.SET_CLIENT_VIEWER])(state),
  )

  const viewers = useSelector(state => state.clients.viewers)
  const loadingV = useSelector(state =>
    loadingSelector([actionTypes.GET_USERS_VIEWERS])(state),
  )
  const hasErrorV = useSelector(state =>
    hasErrorsSelector([actionTypes.GET_USERS_VIEWERS])(state),
  )
  const errorV = useSelector(state =>
    singleErrorSelector([actionTypes.GET_USERS_VIEWERS])(state),
  )

  const canEdit = useSelector(state => isAllowed(state, [1064, 2164]))
  const users = useSelector(selectUsers)

  const [save, setSave] = useState(false)
  const [get, setGet] = useState(false)
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState([])
  const [searchA, setSearchA] = useState(null)
  const [searchB, setSearchB] = useState(null)
  const [mobile, setMobile] = useState(false)
  const [alert, setAlert] = useState({ title: '' })

  useEffect(() => {
    if (show && id) {
      setOpen(true)
      dispatch(getClientViewersByClientId(id))
    }
  }, [show])

  useEffect(() => {
    if (loadingV) setGet(true)
    else if (get) {
      setGet(false)
      if (hasErrorV)
        setAlert({
          ...handlerError(errorV.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else {
        setSelected(viewers)
      }
    }
  }, [loadingV])

  useEffect(() => {
    if (loading) setSave(true)
    else if (save) {
      setSave(false)
      let al
      if (hasError) al = { ...handlerError(error.message) }
      else al = { ...handlerSuccess('Cambios aplicados satisfactoriamente') }
      al.onConfirm = () => setAlert({ ...alert, show: false })
      setAlert({ ...al })
    }
  }, [loading])

  const hide = () => {
    setOpen(false)
    setSelected([])
    onHide()
  }

  const filterUser = item => {
    return selected.find(i => i.id === item.id) === undefined
  }

  const filter = (item, search) => {
    if (search && search !== '') {
      const exp = new RegExp(search.toUpperCase(), 'g')
      const i = { name: item.name, email: item.email }
      return Object.keys(i).some(p => `${JSON.stringify(i[p])}`.toUpperCase().match(exp))
    }
    return true
  }

  const itemsToSelect = (toAssign, items, title, noItemsLegend, search, setSearch) => {
    const is =
      items && items.filter(i => filter(i, search)).length > 0
        ? items.filter(i => filter(i, search))
        : []
    return (
      <div>
        {title && <h5>{title}</h5>}

        <CustomFilter
          search={search}
          onChange={({ target }) => setSearch(target.value)}
          customFilter={<div />}
        />

        {is.length > 0 ? (
          <ul className="main-list">
            {is.map((item, index) => (
              <li key={index}>
                <div className="b-user-item">
                  <div className={'justify-center-start align-items-start bu-en left'}>
                    <div className={'b-user-name'}>{item.name}</div>
                    <div className={'b-user-email'}>{item.email}</div>
                  </div>
                  {canEdit && (
                    <div className={'d-flex row align-items-center mr-2 bu-b right'}>
                      <Button
                        disabled={loading || loadingV}
                        color={'accent'}
                        onClick={() => {
                          if (toAssign) setSelected([...selected, item])
                          else setSelected(selected.filter(s => s.id !== item.id))
                        }}>
                        <Icon
                          tooltip={toAssign ? 'Agregar' : 'Descartar'}
                          icon={toAssign ? (mobile ? faPlus : faArrowRight) : faTrash}
                        />
                      </Button>
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <h6>
            {search && search !== ''
              ? 'No se encontraron usuarios con los filtros aplicados'
              : noItemsLegend}
          </h6>
        )}
      </div>
    )
  }

  return (
    <div>
      <Modal show={open && !mobile} centered size={'lg'} onHide={() => {}}>
        <Modal.Header>
          <Modal.Title>Visualizadores del cliente: {name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Media>
            {({ breakpoints, currentBreakpoint }) => {
              const tablet = breakpoints[currentBreakpoint] > breakpoints.tablet
              const colValue = tablet && canEdit ? 6 : 12

              return breakpoints[currentBreakpoint] >= breakpoints.mini ? (
                <Row>
                  {tablet && canEdit && (
                    <Col xl={6} lg={6} md={12} sm={12}>
                      {itemsToSelect(
                        true,
                        users.filter(filterUser),
                        'Usuarios disponibles para asignar',
                        'No hay usuarios disponibles con los filtros aplicados',
                        searchA,
                        setSearchA,
                      )}
                    </Col>
                  )}

                  <Col xl={colValue} lg={colValue} md={12} sm={12}>
                    {loadingV && (
                      <div className={'pb-custom'}>
                        <ProgressBar
                          label="Cargando"
                          animated
                          now={100}
                          style={{ marginBottom: 10 }}
                        />
                      </div>
                    )}

                    {itemsToSelect(
                      false,
                      selected,
                      'Usuarios Asignados',
                      'Sin usuarios asignados',
                      searchB,
                      setSearchB,
                    )}
                  </Col>
                  {!tablet && canEdit && (
                    <Col xl={12} lg={12} md={12}>
                      <Row
                        className={'container-buttons'}
                        style={{ paddingBottom: 2, display: 'flex' }}>
                        <Button
                          disabled={loading || loadingV}
                          style={{ flex: 1 }}
                          icon={faPlus}
                          right
                          color={'primary'}
                          onClick={() => setMobile(true)}>
                          Asignar usuarios
                        </Button>
                      </Row>
                    </Col>
                  )}
                </Row>
              ) : (
                <div />
              )
            }}
          </Media>
        </Modal.Body>
        <Modal.Footer>
          <Row className="container-buttons">
            <Button
              color={'secondary'}
              icon={faWindowClose}
              right
              onClick={() => hide()}
              disabled={loading || loadingV}>
              Cerrar
            </Button>
            {canEdit && (
              <Button
                disabled={loadingV}
                loading={loading}
                color={'primary'}
                icon={faSave}
                right
                onClick={() =>
                  setAlert({
                    show: true,
                    title: 'Asignación',
                    text: `¿Desea asignar a los usuarios seleccionados como visualizadores del cliente: ${name}?`,
                    type: 'warning',
                    showCancelButton: true,
                    cancelButtonColor: '#B35796',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Asignar',
                    confirmButtonColor: '#226095',
                    onCancel: () => setAlert({ ...alert, show: false }),
                    onConfirm: () => {
                      setAlert({ ...alert, show: false })

                      dispatch(
                        setClientViewersUser({
                          type: 1,
                          clients: [id],
                          users: Object.assign(
                            [],
                            selected.map(s => s.id),
                          ),
                          hideOldViewers: true,
                          hideCurrentViewers: false,
                          add: true,
                        }),
                      )
                    },
                  })
                }>
                Guardar
              </Button>
            )}
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal show={mobile} centered size={'md'} onHide={() => setMobile(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Usuarios disponibles para asignar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {itemsToSelect(
            true,
            users.filter(filterUser),
            null,
            'No hay usuarios disponibles con los filtros aplicados',
            searchA,
            setSearchA,
          )}
        </Modal.Body>
      </Modal>

      <Alert {...alert} />
    </div>
  )
}
export default ClientViewers
