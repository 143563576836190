// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import { TableV2, Button, Paragraph } from 'src/components'
import { faCheck, faTrash } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  deleteProducts,
  productWasteRequest,
} from 'src/actions/products.actions'
import { selectProductsDeleted } from 'src/selectors/products.selector'

import {
  types,
  getArchivedData,
  changeArchivedStatusByList,
} from 'src/actions/utilities.actions'
import { selectArchivedData } from 'src/selectors/utilities.selector'

import { showAlert } from 'src/actions/alert.actions'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'

import { isAllowed } from 'src/selectors/modules.selector'
import { itemsPermissions } from 'src/enums/permissions'

const headerError = [
  {
    label: 'Ítem',
    show: true,
    value: ['column'],
    type: 'text',
    className: 'medium',
  },
  {
    label: 'Bodega',
    show: true,
    value: ['message'],
    type: 'text',
    className: 'medium',
  },
  {
    show: true,
    label: 'Existencia',
    value: ['error'],
    type: 'number',
    className: 'mini',
  },
]

interface Props {
  type: number
  show: boolean
  onHide: (boolean) => void
}
/**
 * Componente para visualizar los ítems archivados y poder recuperarlos o eliminarlos por completo de la cuenta
 * @component
 * @param {number} type Indica si el componente se usara para archivar multiples ítems o visualizar los ítems ya archivados
 * @param {boolean} show Indica si el componente debe ser renderizado o no
 * @param {function} onHide callBack para cerrar el componente desde la app principal
 * */
const ItemsArchived = ({ type, show, onHide }: Props) => {
  const dispatch = useDispatch()

  const seePrice = useSelector(state =>
    isAllowed(state, [itemsPermissions.seeMoneyValue]),
  )

  const items = useSelector(selectArchivedData)
  const loading = useSelector(state => loadingSelector([types.GET_ARCHIVED_DATA])(state))

  const loadingRecover = useSelector(state =>
    loadingSelector([types.CHANGE_STATUS_DATA])(state),
  )

  const loadingWaste = useSelector(state =>
    loadingSelector([actionTypes.PRODUCT_WASTE])(state),
  )
  const hasErrorWaste = useSelector(state =>
    hasErrors([actionTypes.PRODUCT_WASTE])(state),
  )

  const loadingDelete = useSelector(state => loadingSelector([actionTypes.DELETE])(state))
  const archiveResponse = useSelector(selectProductsDeleted)
  const hasError = useSelector(state =>
    hasErrors([types.GET_ARCHIVED_DATA, actionTypes.DELETE])(state),
  )

  const [flags, setFlag] = useState({ actioned: false, waste: false })

  const [open, setOpen] = useState(false)
  const [openExistence, setOpenExistence] = useState(false)
  const [all, setAll] = useState(false)
  const [selected, setSelected] = useState([])

  const disabled = (!all && selected.length <= 0) || loading

  const loadingAction = loadingDelete || loadingRecover

  useEffect(() => {
    if (!show || type !== 1) return
    setOpen(true)
    dispatch(getArchivedData('products'))
  }, [show])

  useEffect(() => {
    if (show && loadingAction) setFlag({ ...flags, actioned: true })
    else if (flags.actioned) {
      setFlag({ ...flags, actioned: false })
      let alert
      if (hasError) alert = { ...handlerError(hasError.message) }
      else if (type === 2 && !archiveResponse.ok)
        alert = {
          ...handlerError(archiveResponse.message),
          onConfirm: () => setOpenExistence(true),
        }
      else alert = { ...handlerSuccess(), onConfirm: () => onClose(true) }

      dispatch(showAlert(alert))
    }
  }, [loadingAction])

  useEffect(() => {
    if (show && loadingWaste) setFlag({ ...flags, waste: true })
    else if (flags.waste) {
      setFlag({ ...flags, waste: true })
      const alert = hasErrorWaste
        ? { ...handlerError(hasErrorWaste.message) }
        : { ...handlerSuccess(), onConfirm: () => onClose(true) }
      dispatch(showAlert(alert))
    }
  }, [loadingWaste])

  const onClose = (update: boolean) => {
    setOpen(false)
    setOpenExistence(false)
    onHide(update)
    setSelected([])
    setAll(false)
  }

  const isSelected = item => {
    const select = selected.find(s => item.id === s)
    return select !== undefined
  }

  const onAction = (deleted: boolean) => {
    const itemsToArchiveOrDelete = Object.assign([], selected)
    if (all) items.forEach(i => itemsToArchiveOrDelete.push(i.id))

    if (!deleted) dispatch(changeArchivedStatusByList('products', itemsToArchiveOrDelete))
    else dispatch(deleteProducts(itemsToArchiveOrDelete))
  }

  const onSelect = item => {
    setAll(false)
    const cs = Object.assign([], selected)
    if (isSelected(item)) setSelected(cs.filter(s => s !== item.id))
    else {
      cs.push(item.id)
      setSelected(cs)
    }
  }

  return (
    <div>
      <Modal show={open} centered size={'lg'} onHide={() => onClose(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Ítems archivados</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <TableV2
                loading={loading}
                items={items}
                mobileAuto
                isSelected={item => (all ? true : isSelected(item))}
                onCheck={value => {
                  setAll(value)
                  setSelected([])
                }}
                onClickTr={(e, item) => {
                  onSelect(item)
                }}
                getItems={item => {
                  if (item) onSelect(item)
                  else {
                    setAll(false)
                    setSelected([])
                  }
                }}
                headers={[
                  {
                    label: 'SKU / Código',
                    checked: all,
                    show: true,
                    select: true,
                    value: ['code'],
                    type: 'text',
                    className: 'mini',
                  },
                  {
                    label: 'Nombre',
                    show: true,
                    value: ['name'],
                    type: 'text',
                    className: 'medium',
                  },
                  {
                    label: 'Precio',
                    show: seePrice,
                    value: ['price'],
                    type: 'currency',
                    className: 'mini',
                  },
                ]}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              color={'secondary'}
              icon={faTrash}
              loading={loadingDelete}
              disabled={disabled || loadingRecover}
              onClick={() => onAction(true)}>
              Eliminar
            </Button>

            <Button
              icon={faCheck}
              loading={loadingRecover}
              disabled={disabled || loadingDelete}
              onClick={() => onAction(false)}>
              Recuperar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal show={openExistence} centered size={'lg'} onHide={() => onClose(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Problemas al archivar ítems</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <Paragraph>
                No fue posible archivar los ítems seleccionados por que algunos tienen
                existencia en bodega
              </Paragraph>
            </Col>

            <Col xl={12}>
              <TableV2 mobileAuto items={archiveResponse?.errors} headers={headerError} />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              icon={faTrash}
              loading={loadingWaste}
              onClick={() =>
                dispatch(productWasteRequest(archiveResponse?.errors.map(e => e.line)))
              }>
              Solicitar reducción de inventario
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default ItemsArchived
