import { actionTypes } from '../actions/banks.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  banks: [],
  bankAccounts: [],
  requestAccount: {},
  bankAccount: {},
  deletedAccount: false,
  bankHistory: [],
  csvResponse: {},
  operation: [],
  movementByDeposit: [],
  deposits: [],
  revert: {},
}

const BankReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_MOVEMENT_BY_DEPOSIT):
      return {
        ...state,
        movementByDeposit: extract(action.movementByDeposit, []),
      }
    case successState(actionTypes.SET_MOVEMENTS_CSV): {
      return {
        ...state,
        csvResponse: extract(action.movements, {}),
      }
    }
    case successState(actionTypes.GET_BANKS): {
      return {
        ...state,
        banks: extract(action.banks, []) || [],
      }
    }
    case successState(actionTypes.GET_ACCOUNTS): {
      let account = action.bankAccounts.filter(s => s.bank === 9)
      return {
        ...state,
        bankAccounts: extract(action.bankAccounts, []),
        requestAccount: account.length > 0 ? account[0] : {},
      }
    }
    case successState(actionTypes.CREATE_ACCOUNT): {
      return {
        ...state,
        bankAccount: extract(action.bankAccount, {}),
      }
    }
    case successState(actionTypes.UPDATE_ACCOUNT): {
      return {
        ...state,
        bankAccount: extract(action.bankAccount, {}),
      }
    }
    case successState(actionTypes.DELETE_ACCOUNT): {
      return {
        ...state,
        deletedAccount: extract(action.deletedAccount, {}),
      }
    }
    case successState(actionTypes.GET_ACCOUNT_HISTORY): {
      return {
        ...state,
        bankHistory: extract(action.bankHistory, {}),
      }
    }
    case successState(actionTypes.CREATE_OPERATION): {
      return {
        ...state,
        movement: extract(action.movement, {}),
      }
    }
    case successState(actionTypes.GET_DEPOSITS_COMPANY): {
      return {
        ...state,
        deposits: extract(action.deposits, []),
      }
    }
    case successState(actionTypes.REVERT_MOVEMENT): {
      return {
        ...state,
        revert: extract(action.revert, {}),
      }
    }
    default:
      return state ? state : initialState
  }
}

export default BankReducer
