// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import { TableV2, Button, Icon } from 'src/components'
import { faEdit, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import RecipeVariableCreate from './RecipeVariableCreate'

import {
  types,
  getVariablesRecipe,
  updateVariableRecipe,
} from 'src/actions/enumeration.actions'
import { selectEnumerationVariablesRecipe } from 'src/selectors/enumeration.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { showAlert } from 'src/actions/alert.actions'

interface Props {
  show: boolean
  onHide: () => void
}

/**
 * Componente para mostrar todas las variaciones que puede ser reutilizadas para recetas del POS
 * @component
 * @param {boolean} show Indica si el componente debe ser renderizado o no
 * @param {function} onHide callBack para cerrar el componente desde la app principal
 * */
const RecipeVariable = ({ show = false, onHide }: Props) => {
  const dispatch = useDispatch()

  const variables = useSelector(selectEnumerationVariablesRecipe)
  const loading = useSelector(state => loadingSelector([types.GET_CUSTOM_RECIPE])(state))
  const loadingD = useSelector(state =>
    loadingSelector([types.ON_CREATE_CUSTOM_VARIABLE])(state),
  )

  const [seeList, setSeeList] = useState(false)
  const [seeCreateVariable, setCreateVariable] = useState({ show: false, id: null })

  useEffect(() => {
    if (!show) return
    setSeeList(true)
    setUp()
  }, [show])

  const setUp = () => {
    dispatch(getVariablesRecipe())
  }

  const onClose = () => {
    setSeeList(false)
    onHide()
  }

  return (
    <div>
      <Modal
        show={seeList && !seeCreateVariable.show}
        centered
        size={'lg'}
        onHide={() => onClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Variaciones de recetas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <div className={'center'}>
                <Button
                  icon={faPlusCircle}
                  disabled={loading}
                  onClick={() => {
                    setCreateVariable({ show: true, id: null })
                  }}>
                  Agregar nueva variación
                </Button>
              </div>
            </Col>
            <Col xl={12}>
              <TableV2
                headers={[
                  {
                    label: 'Alias',
                    className: 'medium',
                    show: true,
                    value: ['name'],
                    type: 'text',
                  },
                  {
                    label: 'Creado por',
                    show: true,
                    value: ['createdData', 'name'],
                    type: 'text',
                  },
                  { label: 'Creado el', show: true, value: ['createdAt'], type: 'date' },
                  {
                    label: 'Actualizado por',
                    show: true,
                    value: ['updatedData', 'name'],
                    type: 'text',
                  },
                  {
                    label: 'Actualizado el',
                    show: true,
                    value: ['updatedAt'],
                    type: 'date',
                  },
                  {
                    label: 'Acciones',
                    className: 'mini',
                    show: true,
                    value: ['name'],
                    config: true,
                    type: 'text',
                    custom: item => (
                      <div className={'d-flex'}>
                        <Icon
                          disabled={loadingD}
                          style={{ marginRight: 10 }}
                          icon={faEdit}
                          tooltip={'Editar'}
                          size={'1_5x'}
                          onClick={() => setCreateVariable({ show: true, id: item.id })}
                        />

                        <Icon
                          spin={loadingD && seeCreateVariable.id === item.id}
                          disabled={loadingD && seeCreateVariable.id !== item.id}
                          icon={faTrash}
                          tooltip={'Borrar'}
                          size={'1_5x'}
                          onClick={() =>
                            dispatch(
                              showAlert({
                                show: true,
                                title: `Eliminar variación ${item.name}`,
                                type: 'warning',
                                text: '¿Desea eliminar la variación? Está acción no podrá ser revertida',
                                showCancelButton: true,
                                cancelButtonText: 'cancelar',
                                confirmButtonText: 'Eliminar',
                                confirmButtonColor: '#b35796',
                                onConfirm: () => {
                                  setCreateVariable({ show: false, id: item.id })
                                  dispatch(
                                    updateVariableRecipe(item.id, {}, { deleted: true }),
                                  )
                                },
                              }),
                            )
                          }
                        />
                      </div>
                    ),
                  },
                ]}
                loading={loading}
                items={variables}
                mobileAuto
                storageKey={'recipe-variable-table-filter'}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <RecipeVariableCreate
        show={seeCreateVariable.show}
        id={seeCreateVariable.id}
        onHide={refresh => {
          setCreateVariable({ show: false, id: null })
          if (refresh) setUp()
        }}
      />
    </div>
  )
}

export default RecipeVariable
