import { actionTypes } from 'src/actions/purchase.actions'
import { actionTypes as companyActionTypes } from 'src/actions/company.actions'
import { extract, successState } from 'src/actions/global.actions'

const initialState = {
  createPurchase: {
    products: [],
    discount: 0,
    warehouses: [],
  },
  purchases: [],
  totalPurchase: 0,
  purchase: {},
  step: 0,
  updated: '',
  nullifyResponse: '',
  simplePurchases: [],
  accreditMultiple: '',
  auto: {},
  created: {},
  transactions: [],
  providers: [],
  providersProducts: [],
  providersProductsUser: [],
  setViewer: '',
  documents: [],
  purchasesSingle: [],
  summative: {},
  sum: 0,
  invoice: [],
  log: [],
  paymentsPurchaseValues: {},
}

const PurchaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(companyActionTypes.GET_COMPANY_DASHBOARD):
      return {
        ...state,
        summative: action.payload.purchaseSummative
          ? action.payload.purchaseSummative
          : {},
      }
    case successState(actionTypes.SEE_PURCHASE_PAYMENTS):
      return {
        ...state,
        paymentsPurchaseValues: extract(action.paymentsPurchaseValues, {}),
      }
    case successState(actionTypes.GET_SUM_CPP):
      return { ...state, sum: extract(action.sum || 0, 0) }
    case successState(actionTypes.CREATE):
      return { ...state, created: extract(action.purchase, {}) }
    case successState(actionTypes.SEE_AUTO):
      return { ...state, auto: extract(action.auto, null) }
    case successState(actionTypes.SET_VIEWER):
      return { ...state, setViewer: extract(action.setViewer, '') }
    case successState(actionTypes.PROVIDERS_PRODUCTS_USER):
      return {
        ...state,
        providersProductsUser: extract(action.providersProductsUser, []),
      }
    case successState(actionTypes.PROVIDERS_PRODUCTS):
      return {
        ...state,
        providersProducts: extract(action.providersProducts, []),
      }
    case successState(actionTypes.PROVIDERS):
      return { ...state, providers: extract(action.providers, []) }
    case successState(actionTypes.TRANSACTIONS):
      return { ...state, transactions: extract(action.transactions, []) }
    case successState(actionTypes.DOCUMENTS):
      return { ...state, documents: extract(action.documents, []) }
    case successState(actionTypes.ACCREDIT_MULTIPLE):
      return {
        ...state,
        accreditMultiple: extract(action.accreditMultiple, ''),
      }
    case successState(actionTypes.GET_BY_AMOUNT):
      return { ...state, simplePurchases: extract(action.simplePurchases, []) }
    case successState(actionTypes.NULLIFY_RESPONSE):
      return { ...state, nullifyResponse: extract(action.nullifyResponse, '') }
    case successState(actionTypes.ACCREDIT):
      return { ...state, purchase: extract(action.purchase, {}) }
    case successState(actionTypes.UPDATE_STATUS):
      return { ...state, updated: extract(action.updated, '') }
    case successState(actionTypes.GET_INVOICE):
      return { ...state, invoice: extract(action.invoice, []) }
    case successState(actionTypes.GET_SINGLE):
      return { ...state, purchase: extract(action.purchase, {}) }
    case successState(actionTypes.GET_LOG):
      return { ...state, log: extract(action.response, []) }
    case successState(actionTypes.GET_ALL_PURCHASE):
      return {
        ...state,
        purchases: action.purchases ? action.purchases.list : [],
        totalPurchase: action.purchases ? action.purchases.total : 0,
      }
    case successState(actionTypes.GET_SUMMATIVE):
      return {
        ...state,
        summative: action.summative ? action.summative : {},
      }
    case successState(actionTypes.GET_PURCHASES_SINGLE):
      return {
        ...state,
        purchasesSingle: action.purchases ? action.purchases.list : [],
      }
    case actionTypes.SET_INFO:
      return {
        ...state,
        createPurchase: action.purchase,
      }
    case actionTypes.SET_STEP:
      return {
        ...state,
        step: action.step,
      }

    default:
      return state ? state : initialState
  }
}
export default PurchaseReducer
