import {
  selectAllPOS,
  selectAllPOSUser,
  selectOrderConcepts,
  selectPosConcepts,
  selectPosConfiguration,
  selectTips,
} from 'src/selectors/restaurant.selector'
import { getState } from 'src/utils/utilities'
import RestaurantService from '../services/restaurant.service'
import UploadService from '../services/uploads.services'
import { executeAction } from './global.actions'

import { actionTypes as warehouseActionTypes } from 'src/actions/warehouse.actions'
import { haveAnyValue } from 'src/utils/utilitiesV2'
import { getPresentationExistence } from 'src/content/Restaurant/POSFunctions'
import dispatch from 'src/content/Dispatch/Dispatch'

export const actionTypes = {
  CREATE_TABLE_ORDER: 'CREATE_TABLE_ORDER',
  CREATE_TABLE_ORDER_REQUEST: 'CREATE_TABLE_ORDER_REQUEST',
  CREATE_TABLE_ORDER_SUCCESS: 'CREATE_TABLE_ORDER_SUCCESS',
  CREATE_TABLE_ORDER_ERROR: 'CREATE_TABLE_ORDER_ERROR',

  GET_TABLE_ORDER: 'GET_TABLE_ORDER',

  GET_TABLES: 'GET_TABLES',
  GET_TABLES_REQUEST: 'GET_TABLES_REQUEST',
  GET_TABLES_SUCCESS: 'GET_TABLES_SUCCESS',
  GET_TABLES_ERROR: 'GET_TABLES_ERROR',

  CREATE_TABLE: 'CREATE_TABLE',
  CREATE_TABLE_REQUEST: 'CREATE_TABLE_REQUEST',
  CREATE_TABLE_SUCCESS: 'CREATE_TABLE_SUCCESS',
  CREATE_TABLE_ERROR: 'CREATE_TABLE_ERROR',

  DELETE_TABLE: 'DELETE_TABLE',

  ADD_PRODUCT: 'ADD_PRODUCT',
  ADD_PRODUCT_REQUEST: 'ADD_PRODUCT_REQUEST',
  ADD_PRODUCT_SUCCESS: 'ADD_PRODUCT_SUCCESS',
  ADD_PRODUCT_ERROR: 'ADD_PRODUCT_ERROR',

  DELETE_PRODUCT: 'DELETE_PRODUCT',
  DELETE_PRODUCT_REQUEST: 'DELETE_PRODUCT_REQUEST',
  DELETE_PRODUCT_SUCCESS: 'DELETE_PRODUCT_SUCCESS',
  DELETE_PRODUCT_ERROR: 'DELETE_PRODUCT_ERROR',

  GET_TABLES_STATUS: 'GET_TABLES_STATUS',
  GET_TABLES_STATUS_REQUEST: 'GET_TABLES_STATUS_REQUEST',
  GET_TABLES_STATUS_SUCCESS: 'GET_TABLES_STATUS_SUCCESS',
  GET_TABLES_STATUS_ERROR: 'GET_TABLES_STATUS_ERROR',

  SET_WAITER: 'SET_WAITER',
  SET_WAITER_REQUEST: 'SET_WAITER_REQUEST',
  SET_WAITER_SUCCESS: 'SET_WAITER_SUCCESS',
  SET_WAITER_ERROR: 'SET_WAITER_ERROR',

  GENERATE_ORDER: 'GENERATE_ORDER',
  GENERATE_ORDER_DEPRECATED: 'GENERATE_ORDER_DEPRECATED',

  CREATE_CHAIR: 'CREATE_CHAIR',
  CREATE_CHAIR_REQUEST: 'CREATE_CHAIR_REQUEST',
  CREATE_CHAIR_SUCCESS: 'CREATE_CHAIR_SUCCESS',
  CREATE_CHAIR_ERROR: 'CREATE_CHAIR_ERROR',

  DELETE_CHAIR: 'DELETE_CHAIR',
  DELETE_CHAIR_REQUEST: 'DELETE_CHAIR_REQUEST',
  DELETE_CHAIR_SUCCESS: 'DELETE_CHAIR_SUCCESS',
  DELETE_CHAIR_ERROR: 'DELETE_CHAIR_ERROR',

  GET_RESERVED_STATUS: 'GET_RESERVED_STATUS',
  GET_RESERVED_STATUS_REQUEST: 'GET_RESERVED_STATUS_REQUEST',
  GET_RESERVED_STATUS_SUCCESS: 'GET_RESERVED_STATUS_SUCCESS',
  GET_RESERVED_STATUS_ERROR: 'GET_RESERVED_STATUS_ERROR',

  BOOK_A_TABLE: 'BOOK_A_TABLE',
  BOOK_A_TABLE_REQUEST: 'BOOK_A_TABLE_REQUEST',
  BOOK_A_TABLE_SUCCESS: 'BOOK_A_TABLE_SUCCESS',
  BOOK_A_TABLE_ERROR: 'BOOK_A_TABLE_ERROR',

  GET_RESERVATION_TABLE: 'GET_RESERVATION_TABLE',
  GET_RESERVATION_TABLE_REQUEST: 'GET_RESERVATION_TABLE_REQUEST',
  GET_RESERVATION_TABLE_SUCCESS: 'GET_RESERVATION_TABLE_SUCCESS',
  GET_RESERVATION_TABLE_ERROR: 'GET_RESERVATION_TABLE_ERROR',

  DELETE_RESERVATION: 'DELETE_RESERVATION',
  DELETE_RESERVATION_REQUEST: 'DELETE_RESERVATION_REQUEST',
  DELETE_RESERVATION_SUCCESS: 'DELETE_RESERVATION_SUCCESS',
  DELETE_RESERVATION_ERROR: 'DELETE_RESERVATION_ERROR',

  GET_CHAIRS: 'GET_CHAIRS',
  GET_CHAIRS_REQUEST: 'GET_CHAIRS_REQUEST',
  GET_CHAIRS_SUCCESS: 'GET_CHAIRS_SUCCESS',
  GET_CHAIRS_ERROR: 'GET_CHAIRS_ERROR',

  GET_ALL_TABLE_ORDERS: 'GET_ALL_TABLE_ORDERS',
  GET_ALL_TABLE_ORDERS_REQUEST: 'GET_ALL_TABLE_ORDERS_REQUEST',
  GET_ALL_TABLE_ORDERS_SUCCESS: 'GET_ALL_TABLE_ORDERS_SUCCESS',
  GET_ALL_TABLE_ORDERS_ERROR: 'GET_ALL_TABLE_ORDERS_ERROR',

  SET_PRODUCT_STATUS: 'SET_PRODUCT_STATUS',
  SET_PRODUCT_STATUS_REQUEST: 'SET_PRODUCT_STATUS_REQUEST',
  SET_PRODUCT_STATUS_SUCCESS: 'SET_PRODUCT_STATUS_SUCCESS',
  SET_PRODUCT_STATUS_ERROR: 'SET_PRODUCT_STATUS_ERROR',

  DELETE_TABLE_ORDER: 'DELETE_TABLE_ORDER',
  PRINT_TABLE_ORDER: 'PRINT_TABLE_ORDER',
  CREATE_OR_DELETE_TIP: 'CREATE_OR_DELETE_TIP',
  GET_TIPS_AVAILABLE: 'GET_TIPS_AVAILABLE',
  UPDATE_TIP_TABLE_ORDER: 'UPDATE_TIP_TABLE_ORDER',
  DOWNLOAD_COMANDA_PDF: 'DOWNLOAD_COMANDA_PDF',
  DOWNLOAD_PRODUCTION_PDF: 'DOWNLOAD_PRODUCTION_PDF',

  GET_ALL_POS_USER: 'GET_ALL_POS_USER',
  GET_ALL_POS: 'GET_ALL_POS',
  CREATE_OR_UPDATE_POS: 'CREATE_OR_UPDATE_POS',
  UPDATE_TAG: 'UPDATE_TAG',
  TRANSFER_TABLE: 'TRANSFER_TABLE',
  GET_TABLE_POS_CATEGORY: 'GET_TABLE_POS_CATEGORY',
  GET_TABLE_ITEMS: 'GET_TABLE_ITEMS',
  REPLACE_TABLE_ITEMS: 'REPLACE_TABLE_ITEMS',
  GET_TABLE_CATEGORY_ITEMS: 'GET_TABLE_CATEGORY_ITEMS',
  REPLACE_TABLE_CATEGORY_ITEMS: 'REPLACE_TABLE_CATEGORY_ITEMS',
  GET_TABLE_SEARCH_ITEMS: 'GET_TABLE_SEARCH_ITEMS',
  REPLACE_TABLE_SEARCH_ITEMS: 'REPLACE_TABLE_SEARCH_ITEMS',
  SHOW_ITEM_DETAIL: 'SHOW_ITEM_DETAIL',
  UPDATE_BILL_ITEMS: 'UPDATE_BILL_ITEMS',
  ON_SET_PRINT_DATA: 'ON_SET_PRINT_DATA',
  ON_SET_TAG_SELECTED: 'ON_SET_TAG_SELECTED',
  ON_SET_VIEW_PRE_BILL: 'ON_SET_VIEW_PRE_BILL',
  ON_SET_CATEGORY_SELECTED: 'ON_SET_CATEGORY_SELECTED',
  ON_SET_BILL_ITEMS_SELECTED: 'ON_SET_BILL_ITEMS_SELECTED',
  GET_BILL_CORRELATIVE: 'GET_BILL_CORRELATIVE',
  CLEAR_BILL_CORRELATIVE: 'CLEAR_BILL_CORRELATIVE',
  GET_RECIPE: 'GET_RECIPE',
  DELETE_POS: 'DELETE_POS',
  ON_FREEZE_ACCOUNT: 'ON_FREEZE_ACCOUNT',
  GET_POS_PROFILE: 'GET_POS_PROFILE',
  UPDATE_POS_STEP: 'UPDATE_POS_STEP',
  POS_ITEM_TYPE: 'POS_ITEM_TYPE',
  POS_ITEM_POST_PUT_DELETE: 'POS_ITEM_POST_PUT_DELETE',
  ON_SAVE_DESCRIPTION: 'ON_SAVE_DESCRIPTION',
  GENERATE_TOKEN: 'GENERATE_TOKEN',
  VERIFY_TOKEN: 'VERIFY_TOKEN',
  GET_POS_ADMINS: 'GET_POS_ADMINS',
  IS_POS_ADMIN: 'IS_POS_ADMIN',
  ADD_POS_ADMIN: 'ADD_POS_ADMIN',
  REMOVE_POS_ADMIN: 'REMOVE_POS_ADMIN',
  GET_ORDER_PAYMENTS: 'GET_ORDER_PAYMENTS',
  CLEAR_TABLE_ORDER: 'CLEAR_TABLE_ORDER',
  SET_PENDING_PAYMENTS: 'SET_PENDING_PAYMENTS',
  RESET_PENDING_PAYMENTS: 'RESET_PENDING_PAYMENTS',
  RESET_PAYMENTS: 'RESET_PAYMENTS',
  SET_SELECTED_PENDING_PAYMENT: 'SET_SELECTED_PENDING_PAYMENT',
  SET_DISCOUNT_SELECTED: 'SET_DISCOUNT_SELECTED',
  SET_TAB_SELECTED: 'SET_TAB_SELECTED',
  ADD_EMPTY_TICKETS: 'ADD_EMPTY_TICKETS',
  REMOVE_EMPTY_TICKET: 'REMOVE_EMPTY_TICKET',
  RESET_EMPTY_TICKETS: 'RESET_EMPTY_TICKETS',
  SET_SELECTED_TICKET: 'SET_SELECTED_TICKET',
  SET_TABLE_SEARCH: 'SET_TABLE_SEARCH',
  SET_LAST_POS: 'SET_LAST_POS',
  SET_LAST_TERMINAL: 'SET_LAST_TERMINAL',
  SET_CLEAR_VALUE: 'SET_CLEAR_VALUE',
  SET_TABLE_ORDER: 'SET_TABLE_ORDER',
  GENERATE_ORDER_IMMEDIATELY: 'GENERATE_ORDER_IMMEDIATELY',

  GET_POS_CONFIGURATIONS: 'GET_POS_CONFIGURATIONS',
  GET_POS_CONFIGURATION_BY_USER: 'GET_POS_CONFIGURATION_BY_USER',
  CREATE_POS_CONFIGURATION: 'CREATE_POS_CONFIGURATION',
  UPDATE_POS_CONFIGURATION: 'UPDATE_POS_CONFIGURATION',
  DELETE_POS_CONFIGURATION: 'DELETE_POS_CONFIGURATION',
  ASSIGN_POS_CONFIGURATION_USERS: 'ASSIGN_POS_CONFIGURATION_USERS',

  GET_ORDER_INVOICE_CONCEPTS: 'GET_ORDER_INVOICE_CONCEPTS',
  GET_POS_INVOICE_CONCEPTS: 'GET_POS_INVOICE_CONCEPTS',
  CREATE_INVOICE_CONCEPT: 'CREATE_INVOICE_CONCEPT',
  UPDATE_INVOICE_CONCEPT: 'UPDATE_INVOICE_CONCEPT',
  DELETE_INVOICE_CONCEPT: 'DELETE_INVOICE_CONCEPT',

  GET_PRODUCT_PRICES_BY_POS: 'GET_PRODUCT_PRICES_BY_POS',
  UPDATE_PRODUCT_PRICE_BY_POS: 'UPDATE_PRODUCT_PRICE_BY_POS',
  DELETE_PRODUCT_PRICE_BY_POS: 'DELETE_PRODUCT_PRICE_BY_POS',
  LOAD_PRODUCT_PRICES_BY_POS: 'LOAD_PRODUCT_PRICES_BY_POS',
  CSV_TEMPLATE_PRODUCT_PRICES_BY_POS: 'CSV_TEMPLATE_PRODUCT_PRICES_BY_POS',
  TABLE_ORDER_CLEAR_REQUEST: 'TABLE_ORDER_CLEAR_REQUEST',
  SET_POS_ITEMS_EXISTENCE: 'SET_POS_ITEMS_EXISTENCE',
  SET_POS_SELECTED: 'SET_POS_SELECTED',
}

const createTableRequest = () => ({ type: actionTypes.CREATE_TABLE_REQUEST })
const createTableSuccess = table => ({ type: actionTypes.CREATE_TABLE_SUCCESS, table })
const createTableError = error => ({ type: actionTypes.CREATE_TABLE_ERROR, error })

const getTablesStatusRequest = () => ({ type: actionTypes.GET_TABLES_STATUS_REQUEST })
const getTablesStatusSuccess = tableStatus => ({
  type: actionTypes.GET_TABLES_STATUS_SUCCESS,
  tableStatus,
})
const getTablesStatusError = error => ({
  type: actionTypes.GET_TABLES_STATUS_ERROR,
  error,
})

const setWaiterRequest = () => ({ type: actionTypes.SET_WAITER_REQUEST })
const setWaiterSuccess = tableOrder => ({
  type: actionTypes.SET_WAITER_SUCCESS,
  tableOrder,
})
const setWaiterError = error => ({ type: actionTypes.SET_WAITER_ERROR, error })

const createChairRequest = () => ({ type: actionTypes.CREATE_CHAIR_REQUEST })
const createChairSuccess = request => ({
  type: actionTypes.CREATE_CHAIR_SUCCESS,
  request,
})
const createChairError = error => ({ type: actionTypes.CREATE_CHAIR_ERROR, error })

const deleteChairRequest = () => ({ type: actionTypes.DELETE_CHAIR_REQUEST })
const deleteChairSuccess = request => ({
  type: actionTypes.DELETE_CHAIR_SUCCESS,
  request,
})
const deleteChairError = error => ({ type: actionTypes.DELETE_CHAIR_ERROR, error })

const getChairsRequest = () => ({ type: actionTypes.GET_CHAIRS_REQUEST })
const getChairsSuccess = request => ({ type: actionTypes.GET_CHAIRS_SUCCESS, request })
const getChairsError = error => ({ type: actionTypes.GET_CHAIRS_ERROR, error })

const getReservedStatusRequest = () => ({ type: actionTypes.GET_RESERVED_STATUS_REQUEST })
const getReservedStatusSuccess = tableStatus => ({
  type: actionTypes.GET_RESERVED_STATUS_SUCCESS,
  tableStatus,
})
const getReservedStatusError = error => ({
  type: actionTypes.GET_RESERVED_STATUS_ERROR,
  error,
})

const getReservationTableRequest = () => ({
  type: actionTypes.GET_RESERVATION_TABLE_REQUEST,
})
const getReservationTableSuccess = table => ({
  type: actionTypes.GET_RESERVATION_TABLE_SUCCESS,
  table,
})
const getReservationTableError = error => ({
  type: actionTypes.GET_RESERVATION_TABLE_ERROR,
  error,
})

const getAllTableOrdersRequest = () => ({
  type: actionTypes.GET_ALL_TABLE_ORDERS_REQUEST,
})
const getAllTableOrdersSuccess = response => ({
  type: actionTypes.GET_ALL_TABLE_ORDERS_SUCCESS,
  response,
})
const getAllTableOrdersError = error => ({
  type: actionTypes.GET_ALL_TABLE_ORDERS_ERROR,
  error,
})

export const createTable = tableRestaurant => async dispatch => {
  dispatch(createTableRequest())
  try {
    const table = await RestaurantService.createTable(tableRestaurant)
    if (tableRestaurant.url) {
      await UploadService.addImage(16, table.id, tableRestaurant.url, table.number)
    }
    dispatch(createTableSuccess(table))
  } catch (error) {
    console.log('action', error)
    dispatch(createTableError(error))
  }
}

export const deleteTable = id => dispatch => {
  const process = async () => ({ response: await RestaurantService.deleteTable(id) })
  dispatch(executeAction(actionTypes.DELETE_TABLE, process))
}

export const getTables = params => async dispatch => {
  const process = async () => ({
    tables: await RestaurantService.getTablesByCompany(params),
  })
  dispatch(executeAction(actionTypes.GET_TABLES, process))
}

export const getTableOrder = (id, posId) => async dispatch => {
  const process = async () => ({
    tableOrder: await RestaurantService.getTableOrder(id, posId),
  })
  dispatch(executeAction(actionTypes.GET_TABLE_ORDER, process))
}

export const createTableOrder = (request, posId, withoutInventory) => async dispatch => {
  const process = async () => {
    const tableOrder = await RestaurantService.createTableOrder(
      request,
      posId,
      withoutInventory,
    )
    /*if (posId)
            dispatch(getTables({posId}));*/
    const response = await RestaurantService.getTableOrder(tableOrder.tableId, posId)

    if (request.img) {
      await UploadService.addImage(
        14,
        response.id,
        request.img,
        request.name,
        request.description,
      )
    }

    if (request.name) response.tableNumber = request.name

    return { tableOrder: { ...response } }
  }
  dispatch(executeAction(actionTypes.GET_TABLE_ORDER, process))
}

export const addProduct = (request, withoutInventory) => async dispatch => {
  const process = async () => {
    await RestaurantService.addProductToTableOrder(request, withoutInventory)
    return { tableOrder: await RestaurantService.getTableOrder(request.tableId) }
  }
  dispatch(executeAction(actionTypes.GET_TABLE_ORDER, process))
}

export const deleteProductByTableOrder = (table, detail) => async dispatch => {
  const process = async () => ({
    tableOrder: await RestaurantService.deleteProduct(table, detail),
  })
  dispatch(executeAction(actionTypes.DELETE_PRODUCT, process))
}

export const getTablesStatus = () => async dispatch => {
  dispatch(getTablesStatusRequest())
  try {
    const tableStatus = await RestaurantService.getTablesStatus()
    dispatch(getTablesStatusSuccess(tableStatus))
  } catch (error) {
    console.log('action', error)
    dispatch(getTablesStatusError(error))
  }
}

export const setInfo = (user, warehouse, type, tableId, tableName) => async dispatch => {
  dispatch(setWaiterRequest())
  try {
    const tableOrder = await RestaurantService.setWaiter(
      user,
      warehouse,
      type,
      tableId,
      tableName,
    )
    dispatch(setWaiterSuccess(tableOrder))
  } catch (error) {
    console.log('action', error)
    dispatch(setWaiterError(error))
  }
}

export const setWaiterToTablesSelected = (user, tables, posId) => async dispatch => {
  dispatch(setWaiterRequest())
  let promises = []
  tables.map(async t => {
    promises.push(RestaurantService.setWaiter(user, t))
  })
  return Promise.all(promises)
    .then(() => dispatch(setWaiterSuccess('success')), dispatch(getTables({ posId })))
    .catch(e => dispatch(setWaiterError(e)))
}

export const generateOrder = (request, dontCertificate) => async dispatch => {
  const process = async () => ({
    response: await RestaurantService.generateOrder(request, dontCertificate),
  })
  dispatch(executeAction(actionTypes.GENERATE_ORDER, process))
}

export const immediatelyInvoice = (request, params) => async dispatch => {
  const process = async () => ({
    response: await RestaurantService.immediatelyInvoice(request, params),
  })
  dispatch(executeAction(actionTypes.GENERATE_ORDER_IMMEDIATELY, process))
}

export const createChair = request => async dispatch => {
  dispatch(createChairRequest())
  try {
    const response = await RestaurantService.createChair(request)
    dispatch(createChairSuccess(response))
  } catch (error) {
    console.log('action', error)
    dispatch(createChairError(error))
  }
}

export const deleteChair = (tableId, chairId) => async dispatch => {
  dispatch(deleteChairRequest())
  try {
    const response = await RestaurantService.deleteChair(tableId, chairId)
    dispatch(deleteChairSuccess(response))
  } catch (error) {
    console.log('action', error)
    dispatch(deleteChairError(error))
  }
}

export const getChairs = tableId => async dispatch => {
  dispatch(getChairsRequest())
  try {
    const response = await RestaurantService.getChairs(tableId)
    dispatch(getChairsSuccess(response))
  } catch (error) {
    console.log('action', error)
    dispatch(getChairsError(error))
  }
}

export const getReservedStatus = () => async dispatch => {
  dispatch(getReservedStatusRequest())
  try {
    const response = await RestaurantService.getReservedStatus()
    dispatch(getReservedStatusSuccess(response))
  } catch (error) {
    console.log('action', error)
    dispatch(getReservedStatusError(error))
  }
}

export const getReservationsByTable = tableId => async dispatch => {
  dispatch(getReservationTableRequest())
  try {
    const response = await RestaurantService.getReservationByTable(tableId)
    dispatch(getReservationTableSuccess(response))
  } catch (error) {
    console.log('action', error)
    dispatch(getReservationTableError(error))
  }
}

export const getAllTableOrders = () => async dispatch => {
  dispatch(getAllTableOrdersRequest())
  try {
    const response = await RestaurantService.getAllTableOrders()
    dispatch(getAllTableOrdersSuccess(response))
  } catch (error) {
    console.log('action', error)
    dispatch(getAllTableOrdersError(error))
  }
}

export const updateProductsState =
  (orderId, detailIds, status, description, closeModal, noAcceptProduction) =>
  async dispatch => {
    let process
    if (!closeModal)
      process = async () => ({
        response: await RestaurantService.updateProductsState(
          orderId,
          detailIds,
          status,
          description,
          noAcceptProduction,
        ),
      })
    else
      process = async () =>
        await RestaurantService.updateProductsState(
          orderId,
          detailIds,
          status,
          description,
          noAcceptProduction,
        )
    dispatch(executeAction(actionTypes.SET_PRODUCT_STATUS, process))
  }

export const deleteTableOrder = (id, posId, returnInventory, byLink) => dispatch => {
  const process = async () => {
    await RestaurantService.deleteTableOrder(id, returnInventory, byLink)
    // dispatch(getTables({posId}));
    return {}
  }
  dispatch(executeAction(actionTypes.DELETE_TABLE_ORDER, process))
}

export const printableOrder = id => dispatch => {
  const process = async () => await RestaurantService.printableOrder(id)
  dispatch(executeAction(actionTypes.PRINT_TABLE_ORDER, process))
}

export const createTip =
  (percentage, discount, favorite, posId, alias = null) =>
  dispatch => {
    const process = async () => ({
      response: await RestaurantService.createTip(percentage, {
        discount,
        favorite,
        posId,
        alias,
      }),
    })
    dispatch(executeAction(actionTypes.CREATE_OR_DELETE_TIP, process))
  }

export const updateTipFavorite = (tipId, discount, favorite, posId) => dispatch => {
  const process = async () => ({
    response: await RestaurantService.updateTipFavorite(tipId, {
      discount,
      favorite,
      posId,
    }),
  })
  dispatch(executeAction(actionTypes.CREATE_OR_DELETE_TIP, process))
}

export const deleteTip = tipId => dispatch => {
  const process = async () => ({ response: await RestaurantService.deleteTip(tipId) })
  dispatch(executeAction(actionTypes.CREATE_OR_DELETE_TIP, process))
}

export const getTipsAvailable =
  (posId, force = false) =>
  dispatch => {
    const tips = getState(selectTips)
    if (tips.length > 0 && !force) return

    const process = async () => ({
      response: await RestaurantService.getTipsAvailable({ posId }),
    })
    dispatch(executeAction(actionTypes.GET_TIPS_AVAILABLE, process))
  }

export const updateTipInTableOrder = (tableOrderId, params) => dispatch => {
  const process = async () => ({
    tableOrder: await RestaurantService.updateTipInTableOrder(tableOrderId, params),
  })
  dispatch(executeAction(actionTypes.GET_TABLE_ORDER, process))
}

export const updateDiscountInTableOrder = (tableOrderId, params) => dispatch => {
  const process = async () => ({
    tableOrder: await RestaurantService.updateDiscountInTableOrder(tableOrderId, params),
  })
  dispatch(executeAction(actionTypes.GET_TABLE_ORDER, process))
}

export const getTicketPDF = tableOrderId => dispatch => {
  const process = async () => await RestaurantService.getTicketPDF(tableOrderId)
  dispatch(executeAction(actionTypes.DOWNLOAD_COMANDA_PDF, process))
}

export const getTicketProductionPDF = (tableOrderId, detailIds) => dispatch => {
  const process = async () =>
    await RestaurantService.getTicketProductionPDF(tableOrderId, detailIds)
  dispatch(executeAction(actionTypes.DOWNLOAD_PRODUCTION_PDF, process))
}

export const getAllPOSByUser =
  (userId, force = false) =>
  dispatch => {
    const pos = getState(selectAllPOSUser)
    if (pos.length > 0 && !force) return

    const process = async () => ({
      posUser: await RestaurantService.getAllPOS({ userId }),
    })
    dispatch(executeAction(actionTypes.GET_ALL_POS_USER, process))
  }

export const getAllPOS =
  (force = false) =>
  dispatch => {
    const pos = getState(selectAllPOS)
    if (pos.length > 0 && !force) return

    const process = async () => ({ allPOS: await RestaurantService.getAllPOS() })
    dispatch(executeAction(actionTypes.GET_ALL_POS, process))
  }

export const getPosAdmins = posId => dispatch => {
  const process = async () => ({ response: await RestaurantService.getPosAdmins(posId) })
  dispatch(executeAction(actionTypes.GET_POS_ADMINS, process))
}

export const isPosAdmin = (posId, userId) => dispatch => {
  const process = async () => ({
    response: await RestaurantService.isPosAdmin(posId, userId),
  })
  dispatch(executeAction(actionTypes.IS_POS_ADMIN, process))
}

export const addPosAdmin = (posId, userId, user) => dispatch => {
  const process = async () => ({
    response: await RestaurantService.addPosAdmin(posId, { userId }),
    admin: user,
  })
  dispatch(executeAction(actionTypes.ADD_POS_ADMIN, process))
}

export const removePosAdmin = (posId, userId) => dispatch => {
  const process = async () => ({
    response: await RestaurantService.removePosAdmin(posId, { userId }),
    id: userId,
  })
  dispatch(executeAction(actionTypes.REMOVE_POS_ADMIN, process))
}

export const onCreatePOS = (request, params) => dispatch => {
  const process = async () => ({
    posResponse: await RestaurantService.onCreatePOS(request, params),
  })
  dispatch(executeAction(actionTypes.CREATE_OR_UPDATE_POS, process))
}

export const onUpdatePOS = (posId, request, params) => dispatch => {
  const process = async () => ({
    posResponse: await RestaurantService.onUpdatePOS(posId, request, params),
  })
  dispatch(executeAction(actionTypes.CREATE_OR_UPDATE_POS, process))
}

export const updateDetailTag = (detailId, tag, isCommentary) => dispatch => {
  const process = async () =>
    await RestaurantService.updateDetailTag(detailId, tag, isCommentary)
  dispatch(executeAction(actionTypes.UPDATE_TAG, process))
}

export const transferTableAction = (tableId, newTableId) => dispatch => {
  const process = async () => ({
    tableOrder: await RestaurantService.transferTable(tableId, newTableId),
  })
  dispatch(executeAction(actionTypes.TRANSFER_TABLE, process))
}

export const getCategoriesByTablePOS = (tableId, posId) => dispatch => {
  const process = async () => ({
    categories: await RestaurantService.getCategoriesByTablePOS(tableId, posId),
  })
  dispatch(executeAction(actionTypes.GET_TABLE_POS_CATEGORY, process))
}

export const getItems = (tableId, posId, params) => dispatch => {
  const process = async () => ({
    items: await RestaurantService.getItems(tableId, posId, params),
  })
  dispatch(executeAction(actionTypes.GET_TABLE_ITEMS, process))
}

export const replaceItems = (tableId, posId, params) => dispatch => {
  const process = async () => ({
    items: await RestaurantService.getItems(tableId, posId, params),
  })
  dispatch(executeAction(actionTypes.REPLACE_TABLE_ITEMS, process))
}

export const getCategoryItems = (tableId, posId, params) => dispatch => {
  const process = async () => ({
    items: await RestaurantService.getItems(tableId, posId, params),
  })
  dispatch(executeAction(actionTypes.GET_TABLE_CATEGORY_ITEMS, process))
}

export const replaceCategoryItems = (tableId, posId, params) => dispatch => {
  const process = async () => ({
    items: await RestaurantService.getItems(tableId, posId, params),
  })
  dispatch(executeAction(actionTypes.REPLACE_TABLE_CATEGORY_ITEMS, process))
}

export const setPOSSearch = search => dispatch => {
  const process = () => ({ search })
  dispatch(executeAction(actionTypes.SET_TABLE_SEARCH, process))
}

export const getSearchItems = (tableId, posId, params) => dispatch => {
  const process = async () => ({
    items: await RestaurantService.getItems(tableId, posId, params),
  })
  dispatch(executeAction(actionTypes.GET_TABLE_SEARCH_ITEMS, process))
}

export const replaceSearchItems = (tableId, posId, params) => dispatch => {
  const process = async () => ({
    items: await RestaurantService.getItems(tableId, posId, params),
  })
  dispatch(executeAction(actionTypes.REPLACE_TABLE_SEARCH_ITEMS, process))
}

export const setItems = () => dispatch => {
  const process = () => ({ items: { items: [], total: 0 } })
  dispatch(executeAction(actionTypes.GET_TABLE_ITEMS, process))
}

export const setShowItemDetail = item => dispatch => {
  const process = async () => ({ item })
  dispatch(executeAction(actionTypes.SHOW_ITEM_DETAIL, process))
}

export const onUpdateBillItems = billItems => dispatch => {
  const process = () => ({ billItems })
  dispatch(executeAction(actionTypes.UPDATE_BILL_ITEMS, process))
}

export const onSetPrintData = data => dispatch => {
  const process = () => ({ data })
  dispatch(executeAction(actionTypes.ON_SET_PRINT_DATA, process))
}

export const onSetTagSelected = tags => dispatch => {
  const process = () => ({ tags })
  dispatch(executeAction(actionTypes.ON_SET_TAG_SELECTED, process))
}

export const onSetSeePreBill = value => dispatch => {
  const process = () => ({ preBillValue: value })
  dispatch(executeAction(actionTypes.ON_SET_VIEW_PRE_BILL, process))
}

export const onSetCategorySelected = categorySelected => dispatch => {
  const process = () => ({ categorySelected })
  dispatch(executeAction(actionTypes.ON_SET_CATEGORY_SELECTED, process))
}

export const onUpdateItems = (orderId, request) => dispatch => {
  const process = async () => ({
    tableOrder: await RestaurantService.onUpdateItems(orderId, request),
  })
  dispatch(executeAction(actionTypes.GET_TABLE_ORDER, process))
}

export const onSetBillItemsSelected = items => dispatch => {
  const process = async () => ({ items })
  dispatch(executeAction(actionTypes.ON_SET_BILL_ITEMS_SELECTED, process))
}

export const getPreBillCorrelative =
  (ticket, sameCorrelative, correlative) => dispatch => {
    const process = async () => ({
      billCorrelative: haveAnyValue(correlative)
        ? correlative
        : await RestaurantService.getPreBillCorrelative({
            ticket,
            sameCorrelative,
          }),
    })
    dispatch(executeAction(actionTypes.GET_BILL_CORRELATIVE, process))
  }

export const clearPreBillCorrelative = () => dispatch => {
  const process = () => ({ billCorrelative: null })
  dispatch(executeAction(actionTypes.CLEAR_BILL_CORRELATIVE, process))
}

export const getRecipe = recipeId => dispatch => {
  const process = async () => ({ recipe: await RestaurantService.getRecipe(recipeId) })
  dispatch(executeAction(actionTypes.GET_RECIPE, process))
}

export const deleteMultiplePOS = listPOS => dispatch => {
  const process = async () => ({
    response: await RestaurantService.deleteMultiplePOS(listPOS),
  })
  dispatch(executeAction(actionTypes.DELETE_POS, process))
}

export const onFreezeTerminal = (terminalId, request) => dispatch => {
  const process = async () => ({
    tableOrder: await RestaurantService.onFreezeTerminal(terminalId, request),
  })
  dispatch(executeAction(actionTypes.GET_TABLE_ORDER, process))
}

export const setTableOrder = payload => dispatch => {
  const process = () => ({
    payload,
  })
  dispatch(executeAction(actionTypes.SET_TABLE_ORDER, process))
}

export const getPosProfile = posId => dispatch => {
  const process = async () => ({
    posProfile: await RestaurantService.getPosProfile(posId),
  })
  dispatch(executeAction(actionTypes.GET_POS_PROFILE, process))
}

export const updatePosProfile = (posId, request) => dispatch => {
  const process = async () => ({
    posProfile: await RestaurantService.updatePosProfile(posId, request),
  })
  dispatch(executeAction(actionTypes.GET_POS_PROFILE, process))
}

export const updatePosStepItems = (posId, params) => dispatch => {
  const process = async () => {
    await RestaurantService.updatePosStepItems(posId, params)
    return { response: params }
  }
  dispatch(executeAction(actionTypes.UPDATE_POS_STEP, process))
}

export const getPOSItemsType = posId => dispatch => {
  const process = async () => ({ items: await RestaurantService.getPOSItemsType(posId) })
  dispatch(executeAction(actionTypes.POS_ITEM_TYPE, process))
}

export const onCreatePosItemType = (posId, request) => dispatch => {
  const process = async () => await RestaurantService.onCreatePosItemType(posId, request)
  dispatch(executeAction(actionTypes.POS_ITEM_POST_PUT_DELETE, process))
}

export const onUpdatePosItem = (posItemId, request) => dispatch => {
  const process = async () => await RestaurantService.onUpdatePosItem(posItemId, request)
  dispatch(executeAction(actionTypes.POS_ITEM_POST_PUT_DELETE, process))
}

export const onDeletePosItem = posItemId => dispatch => {
  const process = async () => await RestaurantService.onDeletePosItem(posItemId)
  dispatch(executeAction(actionTypes.POS_ITEM_POST_PUT_DELETE, process))
}

export const onSaveDescription = description => dispatch => {
  const process = () => ({ description })
  dispatch(executeAction(actionTypes.ON_SAVE_DESCRIPTION, process))
}

export const generateToken =
  (userId, posId, orderId, items = []) =>
  dispatch => {
    const process = async () =>
      await RestaurantService.generateToken({ userId, posId, orderId, items })
    dispatch(executeAction(actionTypes.GENERATE_TOKEN, process))
  }

export const verifyToken = token => dispatch => {
  const process = async () => await RestaurantService.verifyToken(token)
  dispatch(executeAction(actionTypes.VERIFY_TOKEN, process))
}

export const getOrderPayments = orderId => dispatch => {
  const process = async () => await RestaurantService.getOrderPayments(orderId)
  dispatch(executeAction(actionTypes.GET_ORDER_PAYMENTS, process))
}

export const clearTableOrder = () => dispatch => {
  dispatch(executeAction(actionTypes.CLEAR_TABLE_ORDER))
}

export const setPendingPayments = payload => dispatch => {
  const process = () => ({ payload })
  dispatch(executeAction(actionTypes.SET_PENDING_PAYMENTS, process))
}

export const resetPendingPayments = () => dispatch => {
  dispatch(executeAction(actionTypes.RESET_PENDING_PAYMENTS))
}

export const resetPayments = () => dispatch => {
  dispatch(executeAction(actionTypes.RESET_PAYMENTS))
}

export const setSelectedPendingPayment = payload => dispatch => {
  const process = () => ({ payload })
  dispatch(executeAction(actionTypes.SET_SELECTED_PENDING_PAYMENT, process))
}

export const setDiscountSelected = payload => dispatch => {
  const process = () => ({ payload })
  dispatch(executeAction(actionTypes.SET_DISCOUNT_SELECTED, process))
}

export const setTabSelected = payload => dispatch => {
  const process = () => ({ payload })
  dispatch(executeAction(actionTypes.SET_TAB_SELECTED, process))
}

export const addPOSEmptyTickets = payload => dispatch => {
  const process = () => ({ payload })
  dispatch(executeAction(actionTypes.ADD_EMPTY_TICKETS, process))
}

export const removePOSEmptyTicket = payload => dispatch => {
  const process = () => ({ payload })
  dispatch(executeAction(actionTypes.REMOVE_EMPTY_TICKET, process))
}

export const resetPOSEmptyTickets = () => dispatch => {
  dispatch(executeAction(actionTypes.RESET_EMPTY_TICKETS))
}

export const setSelectedPOSTicket = payload => dispatch => {
  const process = () => ({ payload })
  dispatch(executeAction(actionTypes.SET_SELECTED_TICKET, process))
}
export const updateTableOrderValues = tableOrder => dispatch => {
  const process = () => ({ tableOrder })
  dispatch(executeAction(actionTypes.GET_TABLE_ORDER, process))
}

export const setLastPosId = payload => dispatch => {
  const process = () => ({ payload })
  dispatch(executeAction(actionTypes.SET_LAST_POS, process))
}
export const setLastTerminalId = payload => dispatch => {
  const process = () => ({ payload })
  dispatch(executeAction(actionTypes.SET_LAST_TERMINAL, process))
}

export const setClearValue = value => dispatch => {
  const process = () => ({ value })
  dispatch(executeAction(actionTypes.SET_CLEAR_VALUE, process))
}

export const getPosConfigurations = posId => dispatch => {
  const process = async () => await RestaurantService.getPosConfigurations(posId)
  dispatch(executeAction(actionTypes.GET_POS_CONFIGURATIONS, process))
}

export const getPosConfigurationByUser =
  (posId, posUserId, force = false) =>
  dispatch => {
    const configuration = getState(selectPosConfiguration)
    if ((configuration === '' || posId === configuration.posId) && !force) return

    const process = async () =>
      await RestaurantService.getPosConfigurationByUser(posId, posUserId)
    dispatch(executeAction(actionTypes.GET_POS_CONFIGURATION_BY_USER, process))
  }

export const createPosConfiguration = request => dispatch => {
  const process = async () => await RestaurantService.createPosConfiguration(request)
  dispatch(executeAction(actionTypes.CREATE_POS_CONFIGURATION, process))
}

export const updatePosConfiguration = request => dispatch => {
  const process = async () => await RestaurantService.updatePosConfiguration(request)
  dispatch(executeAction(actionTypes.UPDATE_POS_CONFIGURATION, process))
}

export const togglePosConfiguration = configurationId => dispatch => {
  const process = async () =>
    await RestaurantService.togglePosConfiguration(configurationId)
  dispatch(executeAction(actionTypes.UPDATE_POS_CONFIGURATION, process))
}

export const deletePosConfiguration = configurationId => dispatch => {
  const process = async () =>
    await RestaurantService.deletePosConfiguration(configurationId)
  dispatch(executeAction(actionTypes.DELETE_POS_CONFIGURATION, process))
}

export const assignPosConfigurationUsers = (configurationId, posUserIds) => dispatch => {
  const process = async () =>
    await RestaurantService.assignPosConfigurationUsers(configurationId, posUserIds)
  dispatch(executeAction(actionTypes.ASSIGN_POS_CONFIGURATION_USERS, process))
}

/**
 * Get order invoice concepts by POS
 * @param {boolean} force Force to get data from API
 */
export const getOrderInvoiceConcepts =
  (force = false) =>
  dispatch => {
    const concepts = getState(selectOrderConcepts)
    if (concepts.length > 0 && !force) return

    const process = async () => await RestaurantService.getOrderConcepts()
    dispatch(executeAction(actionTypes.GET_ORDER_INVOICE_CONCEPTS, process))
  }

/**
 * Get POS invoice concepts by POS
 * @param {number} posId POS Id
 * @param {boolean} force Force to get data from API
 */
export const getPosInvoiceConcepts =
  (posId, force = false) =>
  dispatch => {
    const concepts = getState(selectPosConcepts)
    if (concepts.length > 0 && concepts.some(c => c.posId === posId) && !force) return

    const process = async () => await RestaurantService.getPosConcepts(posId)
    dispatch(executeAction(actionTypes.GET_POS_INVOICE_CONCEPTS, process))
  }

/**
 * Create invoice Concept
 * @param {IInvoiceConcept} request
 */
export const createInvoiceConcept = request => dispatch => {
  const process = async () => await RestaurantService.createPosConcept(request)
  dispatch(executeAction(actionTypes.CREATE_INVOICE_CONCEPT, process))
}

/**
 * Update invoice Concept
 * @param {IInvoiceConcept} request
 */
export const updateInvoiceConcept = request => dispatch => {
  const process = async () => await RestaurantService.updatePosConcept(request)
  dispatch(executeAction(actionTypes.UPDATE_INVOICE_CONCEPT, process))
}

/**
 * Deletes invoice Concept
 * @param {number} conceptId
 */
export const deleteInvoiceConcept = conceptId => dispatch => {
  const process = async () => await RestaurantService.deletePosConcept(conceptId)
  dispatch(executeAction(actionTypes.DELETE_INVOICE_CONCEPT, process))
}

/**
 * Get POS current transfer transformations
 */
export const getPosTransfersTransformations = params => dispatch => {
  const process = async () => ({
    transfers: await RestaurantService.getPosTransfersTransformations(params),
  })
  dispatch(executeAction(warehouseActionTypes.GET_TRANSFERS, process))
}

/**
 * The function `getProductPricesByPOS` is an asynchronous action that dispatches an action to get
 * product prices by point of sale (POS) ID.
 * @param {number} posId - The `posId` parameter represents the unique identifier of the point of sale
 */
export const getProductPricesByPOS = posId => dispatch => {
  const process = async () => ({
    response: await RestaurantService.getProductPricesByPOS(posId),
  })
  dispatch(executeAction(actionTypes.GET_PRODUCT_PRICES_BY_POS, process))
}

/**
 * The function updates the price of a product by point of sale (POS) using a dispatch action.
 * @param {number} posId - The ID of the point of sale (POS) where the product price needs to be updated.
 * @param request - The `request` parameter is an object that contains the data needed to update the
 * product price. It could include properties such as the product ID, the new price, and any other
 * relevant information required for the update.
 */
export const updateProductPriceByPOS = (posId, request) => dispatch => {
  const process = async () => await RestaurantService.updateProductPrices(posId, request)
  dispatch(executeAction(actionTypes.UPDATE_PRODUCT_PRICE_BY_POS, process))
}

/**
 * The function deletes a product price by POS ID and product ID.
 * @param {number} posId - The posId parameter represents the ID of the point of sale (POS) where the product
 * price needs to be deleted.
 * @param {number} id - The id parameter represents the unique identifier of the product price that needs to be
 * deleted.
 */
export const deleteProductPriceByPOS = (posId, id) => dispatch => {
  const process = async () => await RestaurantService.deleteProductPrice(posId, id)
  dispatch(executeAction(actionTypes.DELETE_PRODUCT_PRICE_BY_POS, process))
}

/**
 * The function `loadProductPricesByPOS` is an asynchronous action that dispatches a request to load
 * product prices for a specific point of sale (POS) using the provided POS ID, index, and request
 * parameters.
 * @param {number} posId - The `posId` parameter represents the ID of the point of sale (POS) system. It is used
 * to identify a specific POS system in order to load product prices associated with it.
 * @param {number} index - The `index` parameter is used to specify the starting index of the product prices to
 * be loaded. It determines the offset from which the product prices will be fetched.
 * @param request - The `request` parameter is an object that contains the necessary information for
 * the API request. It could include things like filters, sorting options, or any other data needed to
 * retrieve the product prices for a specific point of sale (POS).
 */
export const loadProductPricesByPOS =
  (posId, request, index, onSuccess) => async dispatch => {
    const response = await RestaurantService.loadProductPrices(posId, index, request)
    const process = async () => ({
      response,
    })
    dispatch(executeAction(actionTypes.LOAD_PRODUCT_PRICES_BY_POS, process))

    onSuccess(response, false)
  }

/**
 * The function exports a constant that retrieves a CSV template for product prices based on a given
 * POS ID.
 * @param {number} posId - The `posId` parameter represents the unique identifier of the point of sale
 */
export const getCSVTemplateForProductPrices = posId => dispatch => {
  const process = async () =>
    await RestaurantService.getCSVTemplateForProductPrices(posId)
  dispatch(executeAction(actionTypes.CSV_TEMPLATE_PRODUCT_PRICES_BY_POS, process))
}

export const onSaveTableOrderClear = clearRequest => dispatch => {
  const process = () => ({ clearRequest })
  dispatch(executeAction(actionTypes.TABLE_ORDER_CLEAR_REQUEST, process))
}

/**
 * Initialize the terminal cleanup process
 * @param {number} tableOrderId Order to clear
 * @param {string} token Token that allows the action to be performed
 * */

export const initClearTerminal = (tableOrderId, token) => dispatch => {
  const clearRequest = {
    all: true,
    action: (returnInventory, byLink) => {
      dispatch(deleteTableOrder(tableOrderId, null, returnInventory, byLink))
    },
    token,
    seeInventory: true,
    byLink: haveAnyValue(token),
  }
  dispatch(onSaveTableOrderClear(clearRequest))
}

export const setPOSItemsExistence = (items, itemsSelected) => dispatch => {
  const process = () => {
    let payload = {}
    items.forEach((value, key) => {
      payload[key] = getPresentationExistence(value, itemsSelected)
    })
    return { payload }
  }
  dispatch(executeAction(actionTypes.SET_POS_ITEMS_EXISTENCE, process))
}

export const setPOSSelected = posSelected => dispatch => {
  const process = () => ({ posSelected })
  dispatch(executeAction(actionTypes.SET_POS_SELECTED, process))
}
