export const selectAllCurrencies = state =>
  state.currencies.allCurrencies.map(c => ({
    ...c,
    value: c.id,
    label: `${c.code} ${c.name}`,
  }))
export const selectCurrentCurrency = state => state.currencies.currentCurrency
export const selectCountries = state =>
  state.currencies.countries.map(c => ({ ...c, value: c.id, label: c.name }))
export const selectFELPhrases = state =>
  state.currencies.economicActivities.map(e => ({
    ...e,
    value: e.id,
    label: `${e.phrase} (${e.code},${e.stage})`,
  }))
export const selectEconomicActivities = state =>
  state.currencies.economicActivities.map(e => ({
    ...e,
    value: e.id,
    label: `${e.phrase} ${e.description}`,
  }))
export const selectIncoterm = state =>
  state.currencies.incoterm.map(i => ({
    ...i,
    value: i.id,
    label: i.code + ' ' + i.name,
    valueString: i.code,
  }))

export const selectFiscalPrecinct = state =>
  state.currencies.fiscalPrecinct.map(f => ({
    ...f,
    value: f.id,
    label: f.code + ' - ' + f.description,
  }))
export const selectExportationRegime = state =>
  state.currencies.regimen.map(r => ({
    ...r,
    value: r.id,
    label: r.code + ' - ' + r.description,
  }))
export const selectExportationCountries = state =>
  state.currencies.exportationCountries.map(c => ({
    ...c,
    value: c.id,
    label: c.code + ' - ' + c.name,
  }))
