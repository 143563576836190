import { $http } from './http'

class CreditNoteService {
  onCreate = async request => {
    const response = await $http.post('/credit/note', request)
    return response.data
  }

  getCreditNotes = async params => {
    const response = await $http.get('/credit/note', { params })
    return response.data
  }
}
export default new CreditNoteService()
