import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, Select } from 'src/components'
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import { selectCreditCards } from 'src/selectors/account.selector'
import { actions as accountActions, favoriteCard } from 'src/actions/account.actions'
import { actionTypes as actionOrder } from 'src/actions/orders.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import { setModalCreateCard } from 'src/actions/utilities.actions'
import { toMoney } from 'src/utils/utilities'

interface ISelectCard {
  value: number
  label: string
  favorite: boolean
}

interface IProps {
  show: boolean
  amount: number
  onClick: () => void
  onHide: () => void
}

/**
 * Modal para realizar pagos de addons.
 * @param {boolean} show - Indica si el modal se muestra o no.
 * @param {number} amount - Monto a pagar.
 * @param {function} onClick - Función que se ejecuta al dar click en el botón de pagar.
 * @param {function} onHide - Función que se ejecuta al cerrar el modal.
 * @component
 */

export const ModalPaymentAddons = ({ show, amount, onClick, onHide }: IProps) => {
  const dispatch = useDispatch()

  const loadingFavoriteCard = useSelector(state =>
    loadingSelector([accountActions.FAVORITE_CARD])(state),
  )
  const loadingPay = useSelector(state =>
    loadingSelector([actionOrder.PAY_ORDER_ACCOUNT])(state),
  )

  const cards: ICreditCard[] = useSelector(selectCreditCards)
  const [cardsSelect, setCardsSelect] = useState<ISelectCard[]>([])

  useEffect(() => {
    if (cards.length === 0) return
    setCardsSelect(
      cards.map(card => ({
        value: card.id,
        label: `${card.alias} - ${card.number}`,
        favorite: card.favorite,
      })),
    )
  }, [cards])

  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Pago con tarjeta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2>
          Kolo realizará el cobro de <b>{toMoney(amount)}</b> de la tarjeta de crédito
          seleccionada y se habilitarán todos los ajustes que se incluyen en la factura.
        </h2>
        <Card
          style={{
            height: '100%',
          }}>
          <Row style={{ alignItems: 'center', marginBottom: 30 }}>
            <Col xl={8} lg={8} md={6} sm={6} xs={12}>
              <Select
                label={'Tarjeta de crédito'}
                loading={loadingFavoriteCard || loadingPay}
                options={cardsSelect}
                value={cardsSelect.find(card => card.favorite)}
                onChange={card => dispatch(favoriteCard(card.value))}
              />
            </Col>
            <Col xl={4} lg={4} md={6} sm={6} xs={12}>
              <Button
                loading={loadingPay || loadingFavoriteCard}
                color={'secondary'}
                style={{ width: '100%', marginTop: 30 }}
                onClick={() => {
                  dispatch(setModalCreateCard({ show: true }))
                }}>
                Registrar Tarjeta
              </Button>
            </Col>
          </Row>
        </Card>
      </Modal.Body>
      <Modal.Footer className={'container-buttons'}>
        <Button
          color="primary"
          icon={faMoneyBill}
          loading={loadingPay || loadingFavoriteCard}
          onClick={() => {
            if (onClick) onClick()
          }}>
          Pagar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
