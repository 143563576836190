import React, {Component} from 'react';
import {connect} from "react-redux"

import './Recipes.scss'

import {Col, Container, Modal, Row} from "react-bootstrap";
import {
    faArrowCircleDown, faArrowRight,
    faFileAlt,
    faInfo,
    faPlusCircle,
    faTimes,
    faTimesCircle
} from '@fortawesome/free-solid-svg-icons';

import Card from "../../components/cards/Card";
import IconButton from "../../components/buttons/IconButton";
import CollapsibleCard from "../../components/cards/collapsible-card/CollapsibleCard";

import {getProducts, getProductsWithActiveContract} from "../../actions/products.actions";
import {selectAllProductsForSelect, selectAllProductsWithActiveContract} from "../../selectors/products.selector";

import {types, onCreateEnumeration, onGetEnumerations} from "../../actions/enumeration.actions";
import {selectEnumerations} from "../../selectors/enumeration.selector";
import {loadingSelector} from "../../selectors/loading.selector";
import FormTextField from "../../components/inputs/FormTextField/FormTextField";
import FormTextArea from "../../components/inputs/FormTextArea/FormTextArea";
import CustomSelect from "../../components/inputs/Select/CustomSelect";
import Button from "../../components/buttons/Button";
import SweetAlert from "sweetalert-react";
import {handlerError, handlerSuccess, hasErrorsSelector, singleErrorSelector} from "../../selectors/error.selector";
import {selectCurrentModule} from "../../selectors/user.selector";
import { TableV2 } from '../../components';

class ProductsList extends Component {

    state = {
        modal: false,
        //filters
        enumeration: {
            name: null,
            description: null,
            restricted: false,
            public: false,
            input: {label: 'Ninguno', value: {id: null, quantity: 0}},
            output: [],
        },
        showSuccess: false,
        showError: false,
        showWarning: false,
        showDeleteSuccess: false,
        showDeleteError: false,
        showDetail: false,
        input: [],
        output: [],
        selected: {label: 'Ninguno', value: {id: null, quantity: 0}},
        info: {},
        filterProduct: [],
        alert: {
            show: false,
            title: 'default',
            text: 'default',
            type: 'info',
            onConfirm: () => console.log('default')
        },
        isPublic: false,
        restricted: false,
    };

    componentDidMount() {
        const{module} = this.props
        this.props.getProducts()
        /*else
            this.props.getProductsWithActiveContract()*/
        this.props.onGetEnumerations(true);
    }

    componentWillReceiveProps(next) {
        const {eLoading, eError, eHasError} = this.props
        if (eLoading && !next.eLoading) {
            let {alert} = this.state;
            if (!eHasError && next.eHasError) {
                alert = handlerError(eError.response ? eError.response.data.message : 'ha ocurrido un error inesperado.');
                alert.onConfirm = () => {
                    this.setState({alert: {...alert, show: false}});
                };
            } else {
                alert = handlerSuccess('Receta de conversión creada satisfactoriamente.');
                alert.onConfirm = () => {
                    this.setState({alert: {...alert, show: false}});
                    this.resetEnumeration( false);
                    this.props.onGetEnumerations(true);
                };
            }
            this.setState({alert});
        }
    }

    resetEnumeration = (modal) => {
        this.setState({
            enumeration: {
                name: '',
                description: '',
                restricted: false,
                output: [],
                input: []
            },
            modal,
            input: [],
            output: [],
            selected: {label: 'Ninguno', value: {id: null, quantity: 0}}
        })
    };

    addButton = () =>{
        return (
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
              >  <IconButton
                    onClick={() => this.setState({modal: true})} tooltip={'Crear Receta'} size={'1x'} icon={faPlusCircle}
                />
            </div>
        )
    }

    deleteButton = (item, char) =>{
        const {output, input} = this.state
        return(
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
              >  <IconButton
                    onClick={() =>
                        {
                            char === 'i' ?
                                this.setState({input: input.filter((i) =>i.value.id !== item.value.id)})
                                :
                                this.setState({output: output.filter((o) =>o.value.id !== item.value.id)})
                        }
                    }
                    tooltip={'Quitar producto'}
                    size={'1x'}
                    icon={faTimes}
                />
            </div>
        )
    }

    handleEnumerationInputChange = (event, target, route) => {
        const {name, value} = event.target;
        let {enumeration} = this.state;
        if (!target) enumeration[name] = value;
        else enumeration[route].value[name] = value;
        this.setState({enumeration});
    };

    handleEnumerationChange = (event, item, array, char) => {
        const {value} = event.target;
        array.map(a=>{
            if(a.value.id === item.value.id){
                a.value.quantity = parseInt(value)
                item.value.quantity = parseInt(value)
            }
        })
        if(char === 'i')
            this.setState({input: array});
        else
            this.setState({output: array});
    };

    onOutputChange = (item) => {
        const {selected} = this.state;
        this.setState({selected: {label: item.label, value: {id: item.value, quantity: 0}}});
    };

    onSelectFilter = (item) =>{
        const {filterProduct} = this.state
        filterProduct.push({label: item.label, value: item.value})
        this.setState(filterProduct)
    }

    handleCheckBoxChange = (event) => {
        const {name, checked} = event.target;
        let {enumeration} = this.state;
        enumeration[name] = checked;
        this.setState({enumeration});
    };

    handleCheckBoxFilterChange = (event) => {
        const {name, checked} = event.target;
        this.setState({[name]: checked});
    };

    addEnumeration = () => {
        const {enumeration, output, input} = this.state;
        let request = Object.assign({}, enumeration);
        request.output = []
        request.input = []
        input.map(i =>{
            request.input.push({product: i.value.id, quantity: parseInt(i.value.quantity)})
        })
        output.map(o =>{
            request.output.push({product: o.value.id, quantity: parseInt(o.value.quantity)})
        })
        this.props.onCreateEnumeration(request);
    };

    filterContract = (item) =>{
        const {filterProduct} = this.state
        const {productsContract} = this.props
        let quantity = item.enumerationDetails.length
        //if there are not filters
        if(filterProduct.length===0){
            item.enumerationDetails.map((ed) =>{
                productsContract.map((pc) =>{
                    if(ed.id.productId === pc.value && this.compareProducts(ed.id.productId)){
                        quantity--;
                    }
                })
            })
            if(quantity === 0) return this.getRecipe(item)
            return false
        }
        //if there are someone filter
        item.enumerationDetails.map((ed) =>{
            productsContract.map((pc) =>{
                if(ed.id.productId === pc.value  && this.compareProducts(ed.id.productId)){
                    quantity --
                }
            })
        })
        if(quantity !== 0) return false
        let status = false
        filterProduct.map((fp) =>{
            item.enumerationDetails.map(ed =>{
                if(ed.id.productId === fp.value && this.compareProducts(ed.id.productId)){
                    status = this.getRecipe(item)
                }
            })
        })
        return status
    }

    filterRecipe = (item) =>{
        const {filterProduct} = this.state
        if(filterProduct.length===0) {
            let arr = item.enumerationDetails.length
            item.enumerationDetails.map(ed =>{
                if(this.compareProducts(ed.id.productId)){
                    arr --;
                }
            })
            if(arr ===0){
                return this.getRecipe(item)
            }
            return false
        }
        let status = false
        filterProduct.map((fp) =>{
            item.enumerationDetails.map((ed) =>{
                if(ed.id.productId === fp.value && this.compareProducts(ed.id.productId)){
                    status = this.getRecipe(item)
                }
            })
        })
        return status
    }

    getRecipe = (item) => {
        const {isPublic, restricted} = this.state
        if(isPublic && restricted) return true
        else if(restricted && !isPublic && !item.public) return true
        else if(isPublic && !restricted && !item.restricted) return true
        else if(!isPublic && !restricted && !item.restricted && !item.public) return true
        else return false
    }

    filterDetail = (item, value) =>{
        return (item.detailType === value)
    }

    filterProduct = (item) =>{
        const {input, output} = this.state
        return input.filter((i)=>i.value.id === item.value).length === 0 && output.filter((o)=> o.value.id === item.value).length ===0
    }

    deleteFilter = (item) =>{
        const {filterProduct} = this.state;
        let index = filterProduct.indexOf(item);
        if(index !== -1){
            filterProduct.splice(index, 1);
            this.setState(filterProduct)
        }
    }



    getProductName = (id) =>{
        const {products, productsContract, module} = this.props
        let value = 'N/A'
        //if(module === 2000)
            products.map((o) =>{ if(o.value === id) value = o.label})
        //else
        //    productsContract.map((o) =>{ if(o.value === id) value = o.label})
        return value
    }

    compareProducts = (id) =>{
        const {products, productsContract, module} = this.props
        //if(module === 2000)
            return products.find((o) =>{ if(o.value === id) return true})
        //else
        //    return productsContract.find((o) =>{ if(o.value === id) return true})
    }

    render() {
        const {loadingEnum, enumerations, products, eLoading, productsContract, module} = this.props
        const {info, output, selected, enumeration, modal,showDetail, input, alert, filterProduct, restricted, isPublic} = this.state
        return (
            <div>
                <Row>
                    <Col xl={12} className={'text-center'}>
                        <CollapsibleCard title={'Filtros'} startCollapsed={false} style={{display: 'flex'}}
                          >  <Row>
                                <Col xl={4}>
                                    <Card>
                                        <label className={'form-label'}>Filtrar por atributo</label>
                                        <Row>
                                            <Col>
                                                <label className={'checkbox-label'}><input type="checkbox" name="isPublic" checked={isPublic} onChange={this.handleCheckBoxFilterChange}/> Público </label>
                                            </Col>
                                            <Col>
                                                <label className={'checkbox-label'}><input type="checkbox" name="restricted" checked={restricted} onChange={this.handleCheckBoxFilterChange}/> Restringido </label>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col xl={8}>
                                    <CustomSelect
                                        options={products}
                                        placeholder={'selecciona...'}
                                        label={'Filtrar por producto'}
                                        value={selected}
                                        onChange={this.onSelectFilter}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                {
                                    filterProduct.map((r) =>
                                        <div className={'filter-recipes'} >
                                            <label style={{marginRight: 5, paddingTop: 5}}>{r.label}</label>
                                            <IconButton className={'delete-button'} icon={faTimes} tooltip={'Quitar'}
                                                        color={'white'} onClick={() => this.deleteFilter(r)}/>
                                        </div>
                                    )
                                }
                            </Row>
                        </CollapsibleCard>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Card title={'recetas'} style={{height: '100%', marginBottom: 25}} button={this.addButton()}>
                            <TableV2
                                headers={[
                                    { label: '#', show: true, value: ['amount'], type: 'text', className: 'mini center',
                                    custom:(item)=> item.public ? <IconButton tooltip={'Público'} icon={faInfo} color={'blue'}/> :
                                    item.restricted ? <IconButton tooltip={'Restringido'} icon={faInfo} color={'red'}/> :
                                        <IconButton tooltip={'Libre'} icon={faInfo} color={'green'}/> },
                                    { label: 'Nombre', show: true, value: ['name'], type: 'text', className: 'medium' },
                                    { label: 'descripción', show: true, value: ['description'], type: 'text', className: 'vv' },
                                    { config: true, show: true, label: '', className: 'mini center' }
                                ]}
                                items={enumerations.filter((e) => this.filterRecipe(e))}
                                noItemsLegend={'La empresa no tiene recetas creadas'}
                                loading={loadingEnum}
                                onClickTr={(e,item)=>this.setState({info: item}) }
                                mobileAuto
                                storageKey={`recipesList1`}
                            />
                        </Card>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Card title={'recetas'} style={{height: '100%', marginBottom: 25}} button={this.addButton()}>
                            {
                                info.enumerationDetails ?
                                <Row>
                                    <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                                        {
                                            info.enumerationDetails.filter((d) => this.filterDetail(d, 1)).map((i) =>
                                                <Card style={{width: '100%'}} title={this.getProductName(i.id.productId)}
                                                  >  <FormTextField
                                                        disabled={true}
                                                        name={'quantity'}
                                                        value={i.quantity}
                                                    />
                                                </Card>
                                            )
                                        }
                                    </Col>
                                    <Col xl={2} lg={2} md={2} sm={12} xs={12}
                                         style={{padding: '40% 0', maxHeight: 500, backgroundColor: 'rgba(51,227,35,0.45)'}} className={'text-center detail-recipe'}
                                      >  <IconButton icon={faArrowRight} size={'2x'} color={'white'}/>
                                    </Col>
                                    <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                                        {
                                            info.enumerationDetails.filter((d) => this.filterDetail(d, 0)).map((i) =>
                                                <Card style={{width: '100%'}} title={this.getProductName(i.id.productId)}
                                                  >  <FormTextField
                                                        disabled={true}
                                                        name={'quantity'}
                                                        value={i.quantity}
                                                    />
                                                </Card>
                                            )
                                        }
                                    </Col>
                                </Row>
                                :
                                <h4 style={{textAlign: 'center', marginTop: 50,}}>{'Seleccione una receta'}</h4>
                            }
                        </Card>
                    </Col>
                </Row>
                {/************************************************************
                 *************************MODAL RECIPE***********************
                 * ***********************************************************/}
                <Modal
                    show={modal}
                    size={'md'}
                    centered
                    onHide={() => this.setState({modal: false, output: [], input: []})}>

                    <Modal.Header closeButton>
                        <div>
                            <Modal.Title>Agregar receta</Modal.Title>
                            <span>Crea recetas de conversion para especificar a que otros productos puede ser transformado este producto.</span>
                        </div>
                    </Modal.Header>

                    <Modal.Body>
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <FormTextField
                                    label={'Alias'}
                                    type={'text'}
                                    id={'name'}
                                    name={'name'}
                                    placeholder={'Nombra la receta'}
                                    onChange={this.handleEnumerationInputChange}
                                    value={enumeration.name}
                                    required
                                />
                            </Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <FormTextArea
                                    label={'Descripción'}
                                    name={'description'}
                                    id={'description'}
                                    rows={'5'}
                                    placeholder={'Describe la receta'}
                                    value={enumeration.description}
                                    onChange={this.handleEnumerationInputChange}
                                    required
                                />
                            </Col>

                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <h6 style={{color: '#3394CE'}}>Restricción: </h6>
                                <label className={'checkbox-label'}>
                                    <input
                                        type="checkbox" name="restricted" checked={enumeration.restricted}
                                        onChange={this.handleCheckBoxChange}/> {'  '}

                                    {enumeration.restricted ? 'Activado: Solo los usuarios con el permiso de transformación restringida podran transformar a este producto' : 'Desactivado: Todos los usuarios podran transformar a este producto.'}
                                </label>
                            </Col>

                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <h6 style={{color: '#3394CE'}}>Visibilidad: </h6>
                                <label className={'checkbox-label'}>
                                    <input
                                        type="checkbox" name="public" checked={enumeration.public}
                                        onChange={this.handleCheckBoxChange}/> {'  '}

                                    {enumeration.public ? 'Activado: Todas la compañías podrán usar esta receta' : 'Desactivado: Solo los usuarios de esta compañía podrán usar esta receta.'}
                                </label>
                            </Col>

                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <CustomSelect
                                    options={products.filter(this.filterProduct)
                                        /*: productsContract.filter(this.filterProduct)*/}
                                    placeholder={'selecciona...'}
                                    label={'Producto'}
                                    value={selected}
                                    onChange={this.onOutputChange}
                                    required
                                />
                            </Col>

                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Row>
                                    <Col xl={5} lg={5} md={5} sm={12} xs={12} className={'text-center'}>
                                        <h4>Productos a Convertir</h4>
                                        {
                                            input.map((i) =>
                                                <Card style={{width: '100%'}} title={i.label} button={this.deleteButton(i, 'i')}
                                                  >  <FormTextField
                                                        mt={0}
                                                        type={'number'}
                                                        name={'quantity'}
                                                        value={i.value.quantity}
                                                        onChange={(event) => this.handleEnumerationChange(event, i, input, 'i')}
                                                    />
                                                </Card>
                                            )
                                        }
                                        <IconButton color={'rgba(66, 206, 59, 0.67)'} style={{marginTop: 10}} icon={faPlusCircle} size={'2x'} onClick={()=>{
                                            if(!selected.value.id) return
                                            input.push(selected);
                                            this.setState(input)
                                            this.setState({selected: {label: 'Ninguno', value: {id: null, quantity: 0}}})
                                        }
                                        } tooltip={'Agregar producto a convertir'}/>
                                    </Col>
                                    <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                        <div className={'container-offer'}>
                                            <div className={'center-offer'}>
                                                <span><strong>{'=>'}</strong></span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl={5} lg={5} md={5} sm={12} xs={12} className={'text-center'}>
                                        <h4>Productos Convertidos</h4>
                                        {
                                            output.map(o=>
                                                <Card style={{width: '100%'}} title={o.label} button={this.deleteButton(o, 'o')}>
                                                    <FormTextField
                                                        disabled={!o.value.id}
                                                        mt={0}
                                                        type={'number'}
                                                        name={'quantity'}
                                                        value={o.value.quantity}
                                                        onChange={(event) => this.handleEnumerationChange(event, o, output, 'o')}
                                                    />
                                                </Card>
                                            )
                                        }
                                        <IconButton color={'rgba(66, 206, 59, 0.67)'} style={{marginTop: 10}} icon={faPlusCircle} size={'2x'} onClick={()=>{
                                            if(!selected.value.id) return
                                            output.push(selected);
                                            this.setState(output)
                                            this.setState({selected: {label: 'Ninguno', value: {id: null, quantity: 0}}})
                                        }
                                        } tooltip={'Agregar producto convertido'}/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Row className={'container-buttons'}>
                            <Button
                                disabled={eLoading}
                                onClick={() => this.setState({modal: false, output: [], input: []})}
                              >  Cerrar
                            </Button>
                            <Button
                                loading={eLoading}
                                disabled={!enumeration.name || !enumeration.description
                                || input.length === 0 || !(input.length > 0 && input[0].value.id) || (input.length > 0 && input[0].value.quantity <=0)
                                || output.length === 0 || !(output.length > 0 && output[0].value.id) || (output.length > 0 && output[0].value.quantity <=0)}
                                onClick={() => this.addEnumeration()}>
                                Crear
                            </Button>
                        </Row>
                    </Modal.Footer>

                </Modal>

                <SweetAlert {...alert}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    products: selectAllProductsForSelect(state),
    productsContract: selectAllProductsWithActiveContract(state),
    module: selectCurrentModule(state),
    enumerations: selectEnumerations(state),
    loadingEnum: loadingSelector([types.ON_GET_ENUMERATIONS])(state),
    eLoading: loadingSelector([types.ON_CREATE])(state),
    eHasError: hasErrorsSelector([types.ON_CREATE])(state),
    eError: singleErrorSelector([types.ON_CREATE])(state),
    handlerError: (message) => handlerError(message),
    handlerSuccess: (message) => handlerSuccess(message),
});

const mapDispatchToProps = dispatch => ({
    getProducts: () => dispatch(getProducts()),
    getProductsWithActiveContract: () => dispatch(getProductsWithActiveContract()),
    onCreateEnumeration: (request) => dispatch(onCreateEnumeration(request)),
    onGetEnumerations: (isPublic) => dispatch(onGetEnumerations(isPublic)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsList);
