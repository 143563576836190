import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Alert } from 'react-bootstrap'
import { Icon } from '..'

import { Media } from 'react-breakpoints'
import { selectCurrentUser } from 'src/selectors/user.selector'
import { hideNotice, sendConfirmEmail } from 'src/actions/user.actions'
import { selectHideNav } from 'src/selectors/utilities.selector'
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'

const EmailConfirmationNotice = ({ isSignedIn, alwaysShow }) => {
  const dispatch = useDispatch()

  const [show, setShow] = useState(true)

  const user = useSelector(selectCurrentUser)
  const hide = useSelector(selectHideNav)

  const sendEmail = () => {
    dispatch(sendConfirmEmail())
    setShow(false)
  }

  return (
    isSignedIn &&
    !hide && (
      <Media>
        {({ breakpoints, currentBreakpoint }) => {
          const tablet = breakpoints[currentBreakpoint] > breakpoints.tablet
          const see = ((user && user.notice) || alwaysShow) && show

          return (
            <div
              style={{
                position: alwaysShow ? 'block' : 'fixed',
                width: '92%',
                zIndex: 2,
                color: 'white',
              }}
            >
              <Alert
                show={see}
                style={{ backgroundColor: '#B35796', height: tablet ? 42 : 65 }}
              >
                <div className={'d-flex space-between'}>
                  <div
                    className={'b-user-name white d-flex'}
                    style={{
                      cursor: 'pointer',
                      fontWeight: 500,
                      textDecoration: 'underline',
                      color: 'white',
                    }}
                    onClick={() => {
                      sendEmail()
                    }}
                  >
                    {!alwaysShow && (
                      <div className={'mr-1'}>
                        <Icon
                          icon={faWindowClose}
                          color={'white'}
                          tooltip={'cerrar'}
                          onClick={() => {
                            dispatch(hideNotice())
                            setShow(false)
                          }}
                        />
                      </div>
                    )}
                    <div>
                      Verifica tu dirección de correo electrónico: {user && user.email}
                    </div>
                  </div>
                </div>
              </Alert>
            </div>
          )
        }}
      </Media>
    )

    /*
    isSignedIn && !hide &&
    <div style={{position: alwaysShow ? 'block' : 'fixed', width: '100%', height: 45, zIndex: 2}}>
      <Alert show={((user && user.notice) || alwaysShow) && show} style={{backgroundColor: '#B35796', color: 'white', height: 45}}
             dismissible={!alwaysShow} onClose={() => {
        dispatch(hideNotice())
        setShow(false)
      }}>
        <Alert.Heading>
          <div>
            <Media>{({breakpoints, currentBreakpoint}) => {
              const tablet = breakpoints[currentBreakpoint] > breakpoints.tablet;
              return breakpoints[currentBreakpoint] >= breakpoints.mini ? <div className={''}>
                  {tablet ? <div className={'d-flex space-between'}>
                      <span style={{lineHeight: 2, fontSize: 16.5}}>Verifica tu dirección de correo electrónico: <i>{user && user.email}</i></span>
                      <Button onClick={() => {sendEmail()}} style={{lineHeight: 0, padding: 17}} className={'verify-button'} color={'primary'}>Verificar</Button>
                    </div>
                    :
                    <Button onClick={() => {sendEmail()}} color={'primary'} className={'verify-button'} style={{marginLeft: 40}}>Verificar correo</Button>
                  }
                </div>:
                <div/>
            }}</Media>
          </div>
        </Alert.Heading>
      </Alert>

      <SweetAlert {...alert}/>
    </div> */
  )
}

export default EmailConfirmationNotice
