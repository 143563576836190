import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCategorizations } from 'src/actions/categorization.actions'
import {
  getScheduledReports,
  actionTypes,
  deleteScheduleReport,
  sendScheduledReport,
} from 'src/actions/report.actions'
import { Action, Card, TableV2, Title, Dropdown as DropdownCustom } from 'src/components'
import IconButton from 'src/components/buttons/IconButton'
import { categoryType, flatCategories } from 'src/components/folders/Folder'
import { CreateConfiguration } from 'src/content/Reports/Configuration/CreateConfiguration'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'
import {
  errorsSelector,
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
} from 'src/selectors/error.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { isAllowed } from 'src/selectors/modules.selector'
import { selectScheduledReports } from 'src/selectors/report.selector'
import Alert from 'sweetalert-react'

export const ReportConfiguration = () => {
  const dispatch = useDispatch()

  const [showDetail, setShowDetail] = useState({})

  const configurations = useSelector(selectScheduledReports)
  const categories = useSelector(selectAllCategorizations)
  const allCategories = flatCategories(categories)

  const [alert, setAlert] = useState({})
  const [actions, setActions] = useState({})

  const loading = useSelector(state =>
    loadingSelector([actionTypes.GET_SCHEDULED_REPORTS])(state),
  )
  const loadingDelete = useSelector(state =>
    loadingSelector([actionTypes.DELETE_SCHEDULED_REPORT])(state),
  )
  const hasErrorDelete = useSelector(state =>
    hasErrorsSelector([actionTypes.DELETE_SCHEDULED_REPORT])(state),
  )
  const errorDelete = useSelector(state =>
    errorsSelector([actionTypes.DELETE_SCHEDULED_REPORT])(state),
  )

  const loadingSend = useSelector(state =>
    loadingSelector([actionTypes.SEND_SCHEDULED_REPORT])(state),
  )
  const hasErrorSend = useSelector(state =>
    hasErrorsSelector([actionTypes.SEND_SCHEDULED_REPORT])(state),
  )
  const errorSend = useSelector(state =>
    errorsSelector([actionTypes.SEND_SCHEDULED_REPORT])(state),
  )

  const editPermission = useSelector(state => isAllowed(state, [6653]))
  const deletePermission = useSelector(state => isAllowed(state, [6654]))
  const createPermission = useSelector(state => isAllowed(state, [6652]))

  useEffect(() => {
    dispatch(getScheduledReports())
    dispatch(getAllCategorizations(categoryType.PRODUCTS.id))
  }, [])

  useEffect(() => {
    if (loadingDelete) setActions({ ...actions, add: true })
    else if (actions.add) {
      setActions({ ...actions, add: false })
      if (hasErrorDelete) {
        setAlert({
          ...handlerError(errorDelete.message || 'Error al crear reporte programado'),
          onConfirm: () => setAlert({ show: false }),
        })
      } else {
        setAlert({
          ...handlerSuccess('Configuración eliminada con éxito'),
          onConfirm: () => {
            setAlert({ show: false })
            dispatch(getScheduledReports())
          },
        })
      }
    }
  }, [loadingDelete])

  useEffect(() => {
    if (loadingSend) setActions({ ...actions, add: true })
    else if (actions.add) {
      setActions({ ...actions, add: false })
      if (hasErrorSend) {
        setAlert({
          ...handlerError(errorSend.message || 'Error al enviar reporte programado'),
          onConfirm: () => setAlert({ show: false }),
        })
      } else {
        setAlert({
          ...handlerSuccess('El reporte ha sido enviado con éxito'),
          onConfirm: () => {
            setAlert({ show: false })
            dispatch(getScheduledReports())
          },
        })
      }
    }
  }, [loadingSend])

  const getUsers = item => {
    let encargados = ''
    item.usersInfo.map(p => (encargados += p.name + '<br/>'))
    return { __html: encargados }
  }

  const MyComponent = item => {
    return <div dangerouslySetInnerHTML={getUsers(item)} />
  }

  const getHeaders = () => {
    return [
      {
        label: 'Correlativo',
        show: true,
        value: ['correlative'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Creado por',
        show: true,
        value: ['createdData', 'name'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Tipo de reporte',
        show: true,
        value: ['typeC'],
        type: 'text',
        className: 'mini',
        custom: item =>
          (item.typeC =
            item.type === 1
              ? 'Turno general'
              : item.type === 2
              ? 'Patrimonial'
              : item.type === 3
              ? 'Gastos Segmentado'
              : item.type === 4
              ? 'Inventario Mínimo y Máximo'
              : 'Productos próximos a vencer'),
      },
      {
        label: 'Usuarios a notificar',
        show: true,
        value: ['createdData'],
        type: 'text',
        className: 'mini',
        select: true,
        custom: item =>
          item.usersInfo && item.usersInfo.length !== 0 ? (
            item.usersInfo.length > 1 ? (
              <label
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  //justifyContent: 'center',
                  marginBottom: '0px',
                }}>
                {' '}
                Varios asignados
                <IconButton
                  style={{ alignItems: 'center', display: 'flex', marginLeft: '4px' }}
                  tooltip={MyComponent(item)}
                  icon={faInfoCircle}
                  size={'0.25x'}
                />{' '}
              </label>
            ) : (
              item.usersInfo[0].name
            )
          ) : item.usersInfo[0] ? (
            item?.attendantData[0]?.name
          ) : (
            'No hay asignados'
          ),
      },
      {
        label: 'Periodicidad',
        show: true,
        value: ['periodicityC'],
        type: 'text',
        className: 'mini',
        custom: item =>
          (item.periodicityC =
            item.periodicity === 1
              ? 'Diariamente'
              : item.periodicity === 2
              ? 'Semanalmente'
              : 'Mensualmente'),
      },
      {
        label: 'Días de anticipación',
        show: true,
        value: ['offsetDays'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Categorías',
        show: true,
        value: ['categoriesC'],
        type: 'text',
        className: 'mini',
        custom: item =>
          (item.categoriesC = item.categories
            ? item.categories
                ?.split(',')
                .map(
                  categoryId =>
                    allCategories?.find(category => category.id === Number(categoryId))
                      ?.name || 'Desconocida',
                )
                .join(', ')
            : ''),
      },
      {
        label: '',
        show: true,
        className: 'mini center',
        config: true,
        custom: item => (
          <DropdownCustom
            loading={loadingSend}
            items={[
              {
                title: 'Editar',
                action: () => {
                  setShowDetail({ show: true, id: item.id })
                },
                show: editPermission,
              },
              {
                title: 'Eliminar',
                show: deletePermission,
                action: () => {
                  setAlert({
                    show: true,
                    title: '¡Advertencia!',
                    text: `¿ Desea eliminar esta configuracion ? Esta acción no podra ser revertida.`,
                    showCancelButton: true,
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Eliminar',
                    type: 'warning',
                    onCancel: () => setAlert({ show: false }),
                    onConfirm: () => {
                      dispatch(deleteScheduleReport(item.id))
                      setAlert({ show: false })
                    },
                  })
                },
              },
              {
                title: 'Enviar reporte',
                action: () => {
                  dispatch(sendScheduledReport(item.id))
                },
              },
            ]}
          />
        ),
      },
    ]
  }

  const button = () => {
    return (
      <Action
        action={createPermission}
        actionTitle={'Crear nueva configuración'}
        onClick={() => {
          setShowDetail({ show: true })
        }}
      />
    )
  }

  return (
    <div style={{ paddingBottom: 50 }}>
      <Title
        title={'Configuración de reportes'}
        action={createPermission} //permiso de crear
        actionTitle={'Crear nueva configuración'}
        onClick={() => {
          setShowDetail({ show: true })
        }}
      />

      <Row>
        <Col lg={12}>
          <Card title={'Listado'} button={button()} white>
            <TableV2
              customClass={'scrollX'}
              headers={getHeaders()}
              loading={loading}
              items={configurations}
              mobileAuto
              storageKey={`configurationReports`}
            />
          </Card>
        </Col>
      </Row>
      <CreateConfiguration
        id={showDetail.id}
        show={showDetail.show}
        onHide={isUpdate => {
          setShowDetail({ show: false, id: 0 })
          if (isUpdate) dispatch(getScheduledReports())
        }}
      />

      <Alert {...alert} />
    </div>
  )
}
