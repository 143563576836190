import { actionTypes } from '../actions/categorization.actions'
import { successState, extract } from '../actions/global.actions'

const initialState = {
  categorization: {},
  categorizations: [],
  subcategorizations: [],
  roots: {},
  categorizationEntity: [],
  types: [],
  response: { companies: [], products: [] },
  allCategorizations: {},
  categorizationByParent: [],
  categorizationByType: {},
}

const CategorizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.SET_LOCAL_CATEGORIES):
      return { ...state, categorizationEntity: extract(action.response, []) }
    case successState(actionTypes.GET_PRODUCTS): {
      return {
        ...state,
        response: extract(action.response, {}),
      }
    }
    case successState(actionTypes.GET_ALL): {
      return {
        ...state,
        allCategorizations: extract(action.response, {}),
      }
    }
    case successState(actionTypes.GET_CATEGORIZATIONS): {
      return {
        ...state,
        categorizations: action.categorizations,
      }
    }

    case successState(actionTypes.GET_CATEGORIZATION): {
      return {
        ...state,
        categorization: action.categorization,
      }
    }

    case successState(actionTypes.CREATE_CATEGORIZATION): {
      return {
        ...state,
        categorization: action.categorization,
      }
    }

    case successState(actionTypes.UPDATE_CATEGORIZATION): {
      return {
        ...state,
        categorization: action.categorization,
      }
    }

    case successState(actionTypes.GET_ALL_ROOTS): {
      return {
        ...state,
        roots: action.roots,
      }
    }

    case successState(actionTypes.GET_CATEGORIZATIONS_BY_PRODUCT): {
      return {
        ...state,
        categorizationEntity: action.categorizations,
      }
    }

    case successState(actionTypes.GET_TYPES): {
      return {
        ...state,
        types: action.types,
      }
    }

    case successState(actionTypes.GET_ALL_CATEGORIZATION_PRODUCTS): {
      return {
        ...state,
        categorization: action.products,
      }
    }

    case successState(actionTypes.GET_PRODUCTS_BY_PUBLIC_ROOT): {
      return {
        ...state,
        categorization: action.response,
      }
    }

    case successState(actionTypes.GET_PUBLIC_ROOTS): {
      return {
        ...state,
        roots: action.response,
      }
    }

    case successState(actionTypes.GET_ASSIGNED): {
      return { ...state, categorizationEntity: action.response }
    }

    case successState(actionTypes.GET_CATEGORIZATIONS_BY_PARENT): {
      return {
        ...state,
        categorizationByParent: action.categorizations,
      }
    }

    case successState(actionTypes.GET_CATEGORIZATION_BY_TYPE): {
      return {
        ...state,
        categorizationByType: action.response,
      }
    }

    default:
      return state ? state : initialState
  }
}

export default CategorizationReducer
