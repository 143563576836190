import React from 'react'
import { OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap'
import { Placement } from 'react-bootstrap/esm/types'

interface IProps {
  placement?: Placement
  className?: string
  content: React.ReactNode
  children: React.ReactNode
}

/**
 * Tooltip component
 * @param {placement} placement - placement of tooltip
 * @param {string} className - class name to apply to tooltip
 * @param {React.ReactNode} content - content to display in tooltip
 * @param {React.ReactNode} children - children to render
 */
function Tooltip({ placement = 'left', className = '', content, children }: IProps) {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        content ? (
          <BootstrapTooltip id="tooltip" className={className}>
            {content}
          </BootstrapTooltip>
        ) : (
          <div />
        )
      }>
      <div>{children}</div>
    </OverlayTrigger>
  )
}

export default Tooltip
