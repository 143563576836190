import { actionTypes } from '../actions/resolutions.actions'
import { extract, successState, errorState } from '../actions/global.actions'

const initialState = {
  documents: [],
  resolutions: [],
  freeResolutions: [],
  limit: 0,
  createResponse: null,
  error: null,
  bills: [],
  bill: {},
  documentRequests: [],
  documentRequest: null,
  felData: {},
  adminFelData: [],
  changedFelData: {},
  profileOptions: [],
  profile: {},
  invoice: {},
  phrases: [],
  companyPhrases: [],
}

const ResolutionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_PHRASES):
      return { ...state, phrases: extract(action.phrases, []) }
    case successState(actionTypes.GET_COMPANY_PHRASES):
      return { ...state, companyPhrases: extract(action.companyPhrases, []) }
    case successState(actionTypes.UPDATE_INVOICE_PROFILE):
      return {
        ...state,
        profile: extract(action.profile, {}),
      }
    case successState(actionTypes.GET_INVOICE_PROFILE):
      return {
        ...state,
        profile: extract(action.profile, {}),
      }
    case successState(actionTypes.GET_INVOICE_PROFILE_OPTIONS):
      return {
        ...state,
        profileOptions: extract(action.profileOptions, []),
      }
    case successState(actionTypes.GET_BILLS):
      return {
        ...state,
        bills: extract(action.bills, []),
      }
    case successState(actionTypes.CANCEL_BILL):
      return {
        ...state,
        bill: extract(action.bill, {}),
      }
    case successState(actionTypes.GET_DOCUMENTS_TYPES):
      return {
        ...state,
        documents: extract(action.documents, []),
      }
    case errorState(actionTypes.ON_CREATE_RESOLUTION):
      return {
        ...state,
        error: extract(action.createResponse, null),
      }
    case successState(actionTypes.ON_CREATE_RESOLUTION):
      return {
        ...state,
        createResponse: extract(action.createResponse, null),
      }
    case successState(actionTypes.GET_RESOLUTIONS_LIMIT):
      return {
        ...state,
        limit: extract(action.limit, 0),
      }
    case successState(actionTypes.GET_RESOLUTIONS):
      return {
        ...state,
        resolutions: extract(action.resolutions, []),
      }
    case successState(actionTypes.GET_FREE_RESOLUTIONS):
      return {
        ...state,
        freeResolutions: extract(action.freeResolutions, []),
      }
    case successState(actionTypes.GET_COMPANY_DOCUMENT_REQUESTS):
      return {
        ...state,
        documentRequests: extract(action.documentRequests, []),
      }
    case successState(actionTypes.GET_ALL_DOCUMENT_REQUESTS):
      return {
        ...state,
        documentRequests: extract(action.documentRequests, []),
      }
    case successState(actionTypes.GET_DOCUMENT_REQUEST):
      return {
        ...state,
        documentRequest: extract(action.documentRequest, null),
      }
    case successState(actionTypes.CREATE_DOCUMENT_REQUEST):
      return {
        ...state,
        documentRequest: extract(action.documentRequest, null),
      }
    case successState(actionTypes.CANCEL_DOCUMENT_REQUEST):
      return {
        ...state,
        documentRequest: extract(action.documentRequest, null),
      }
    case successState(actionTypes.MARK_DOCUMENT_REQUEST_AS_DONE):
      return {
        ...state,
        documentRequest: extract(action.documentRequest, null),
      }
    case successState(actionTypes.GET_FELDATA):
      return {
        ...state,
        felData: extract(action.felData, null),
      }
    case successState(actionTypes.SET_FELDATA):
      return {
        ...state,
        felData: extract(action.felData, null),
      }
    case successState(actionTypes.GET_ADMIN_FELDATA):
      return {
        ...state,
        felData: extract(action.adminFelData, null),
      }
    case successState(actionTypes.TOOGLE_ADMIN_FELDATA):
      return {
        ...state,
        felData: extract(action.changedFelData, null),
      }
    case successState(actionTypes.GET_SINGLE_INVOICE):
      return {
        ...state,
        invoice: extract(action.invoice, null),
      }
    default:
      return state ? state : initialState
  }
}

export default ResolutionsReducer
