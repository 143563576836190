import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import Button from 'src/components/buttons/Button'
import Paragraph from 'src/components/Paragraph/Paragraph'
import Switch from 'src/components/inputs/Switch/SwitchV2'
import Loading from 'src/components/Loading/Loading'

import { faFileInvoice, faWindowClose } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes as orderActions,
  verifyOrderUseRecipe,
} from 'src/actions/orders.actions'

import {
  actionTypes as resolutionsActions,
  nullifyInvoiceAndOrder,
} from 'src/actions/resolutions.actions'

import { isAllowed } from 'src/selectors/modules.selector'
import { selectCompanyFieldById } from 'src/selectors/company.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'

import { orderPermissions } from 'src/enums/permissions'
import { companyFieldsSell } from 'src/enums/companyFields'
import { showAlert } from 'src/actions/alert.actions'
import { selectOrderUseRecipe } from '../../../selectors/orders.selector'

interface IProps {
  show: boolean
  orderId: number
  number: string
  onHide: (update: boolean) => void
}

/** Render a modal to cancel order
 * @param {IProps} props properties of component
 * @param {boolean} props.show Indicates whether the order cancellation modal should be displayed or not.
 * @param {number} props.orderId The ID of the order to be canceled.
 * @param {string} props.number The number of the order to be canceled.
 * @param {void} props.onHide Function called when the cancellation modal is closed.
 * **/

const OrderNullify = ({ show, orderId, number, onHide }: IProps) => {
  const dispatch = useDispatch()

  const loadingCancelBill: boolean = useSelector(state =>
    loadingSelector([resolutionsActions.CANCEL_BILL])(state),
  )
  const hasErrorCancelBill = useSelector(state =>
    hasErrors([resolutionsActions.CANCEL_BILL])(state),
  )
  const loadingVerifyOrder: boolean = useSelector(state =>
    loadingSelector([orderActions.VERIFY_ORDER_USE_RECIPES])(state),
  )
  const useRecipe: boolean = useSelector(selectOrderUseRecipe)

  /*PERMISSIONS*/
  const canChangeInventoryReturnOptionByUser: boolean = useSelector(state =>
    isAllowed(state, [orderPermissions.canChangeInventoryReturnOption]),
  )

  /*CONFIG*/
  const canChangeInventoryReturnOptionByCompany: boolean =
    useSelector(state =>
      selectCompanyFieldById(
        state,
        companyFieldsSell.canChangeInventoryReturnOptionByCompany,
      ),
    )?.value === 'true'

  const inventoryReturnIsDisabled =
    !(canChangeInventoryReturnOptionByUser && canChangeInventoryReturnOptionByCompany) ||
    loadingCancelBill

  const [flags, setFlags] = useState({ nullify: false })
  const [returnInventory, setReturnInventory] = useState<boolean>(true)
  const [returnInventoryInsume, setReturnInventoryInsume] = useState<boolean>(true)

  useEffect(() => {
    if (!show) return
    dispatch(verifyOrderUseRecipe(orderId))
  }, [show])

  useEffect(() => {
    if (loadingCancelBill) setFlags({ ...flags, nullify: true })
    else if (flags.nullify) {
      setFlags({ ...flags, nullify: false })

      let alert
      if (hasErrorCancelBill) alert = handlerError(hasErrorCancelBill.message)
      else {
        onClose(true)
        alert = handlerSuccess('Orden anulada exitosamente.')
      }

      dispatch(showAlert(alert))
    }
  }, [loadingCancelBill])

  const onClose = (update: boolean) => {
    onHide(update)
    setReturnInventory(true)
    setReturnInventoryInsume(true)
  }

  const onNullify = () => {
    dispatch(
      nullifyInvoiceAndOrder(orderId, {
        returnProduct: returnInventory,
        returnInsume: returnInventoryInsume,
      }),
    )
  }

  return (
    <Modal
      show={show}
      centered
      size={'lg'}
      onHide={() => (loadingCancelBill ? undefined : onClose(false))}>
      <Modal.Header closeButton={!loadingCancelBill}>
        <Modal.Title>Anular orden: {number}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xl={12}>
            <Paragraph>¿Deseas anular la orden seleccionada?</Paragraph>
          </Col>
          <Col xl={12}>
            <Paragraph>Las transacciones monetarias serán revertidas.</Paragraph>
          </Col>
          <Col xl={12}>
            <Switch
              disabled={inventoryReturnIsDisabled || loadingCancelBill}
              checked={returnInventory}
              onChange={value => {
                setReturnInventory(value)
                if (!value) setReturnInventoryInsume(false)
              }}
              label={'Devolución de inventario'}
              info={'El producto total de la orden será devuelto a la bodega de origen'}
              id={'inventory-return'}
            />
          </Col>

          <Col xl={12}>
            <Loading show={loadingVerifyOrder} />
          </Col>

          {useRecipe && (
            <Col xl={12}>
              <Switch
                disabled={inventoryReturnIsDisabled || !returnInventory}
                checked={returnInventoryInsume}
                onChange={setReturnInventoryInsume}
                label={'Devolución de inventario de insumos'}
                info={
                  'Los insumos utilizados en la elaboración del producto final serán devueltos a la bodega de origen'
                }
                id={'inventory-return-insume'}
              />
            </Col>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className={'container-buttons'}>
          <Button
            disabled={loadingCancelBill || loadingVerifyOrder}
            color={'secondary'}
            icon={faWindowClose}
            onClick={() => onClose(false)}
            dataCy={'button-close'}>
            Cerrar
          </Button>
          <Button
            dataCy={'button-nullify'}
            disabled={loadingVerifyOrder}
            loading={loadingCancelBill}
            icon={faFileInvoice}
            onClick={onNullify}>
            Anular
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}
export default OrderNullify
