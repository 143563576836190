import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Modal, Row } from 'react-bootstrap'
import { Button, PaginatedVideo } from 'src/components'

import { faWindowClose } from '@fortawesome/free-solid-svg-icons'

import { getAllVideos } from 'src/actions/videos.actions'
import { selectAllVideos } from 'src/selectors/videos.selector'
import { getAllPublicCategorizations } from 'src/actions/categorization.actions'

import ModalVideo from '../Information/ModalVideo'

const ManualConfiguration = ({ show, company, onHide }) => {
  const dispatch = useDispatch()

  const videos = useSelector(selectAllVideos)

  const [modal, setModal] = useState(false)
  const [create, setCreate] = useState({
    show: false,
    videos: [],
    categories: [],
  })
  const [data, setData] = useState({ title: '', description: '', id: 0 })

  useEffect(() => {
    if (show) {
      setModal(true)
      dispatch(getAllPublicCategorizations(19, { companyId: company.id }))

      if (!videos || videos.length <= 0) dispatch(getAllVideos(company.id))
    }
  }, [show])

  const onClose = () => {
    onHide()
    setModal(false)
  }

  return (
    <div>
      <Modal show={modal} centered size={'xl'}>
        <Modal.Header>
          <Modal.Title>Configurar manuales: {company.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <PaginatedVideo
              data={videos}
              onEdit={item => {
                setData({
                  title: item.video.title,
                  description: item.video.description,
                  id: item.video.id,
                })
                setCreate({
                  show: true,
                  categories: item.categories,
                  videos: item.urls,
                })
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button color={'secondary'} icon={faWindowClose} onClick={() => onClose()}>
              Cerrar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <ModalVideo
        show={create.show}
        onHide={() => {
          setCreate({ show: false, videos: [], categories: [] })
          setData({ description: '', title: '', id: 0 })
        }}
        categories={create.categories}
        urlsFromWS={create.videos}
        data={data}
        manual={company.id}
      />
    </div>
  )
}
export default ManualConfiguration
