import './Invoices.scss'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Row, Col, Container, ProgressBar } from 'react-bootstrap'
import { Button, Icon, PrintInvoiceTicket } from 'src/components'
import Money from 'src/components/Money/Money'
import LogoKolo from 'src/assets/v2/logo-kolo.svg'

import { faFilePdf, faPrint } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes as typeO,
  getInvoiceByOrder,
  getInvoiceTicketPDF,
  onPrintInvoice,
} from 'src/actions/orders.actions'
import { selectGetInvoiceByOrder } from 'src/selectors/orders.selector'

import {
  selectCompanyCountry,
  selectCompanyFieldById,
  selectGetSingleCompany,
} from 'src/selectors/company.selector'
import {
  actionTypes as typeR,
  downloadPrintableInvoiceByOrder,
} from 'src/actions/resolutions.actions'
import { selectCurrentModule } from 'src/selectors/user.selector'

import { loadingSelector } from 'src/selectors/loading.selector'

import { onHide } from 'src/actions/utilities.actions'

import { formatDateFromMillis, formatNumberToWords } from 'src/utils/formatters'

import { getPhraseLabel } from 'src/utils/utilities'
import { selectPurchaseInvoices } from 'src/selectors/purchase.selector'

import { getCompanyField } from 'src/actions/company.actions'
import { ListVariations } from 'src/content/Production/Categorization/Variation/ListVariations'
import { Country } from 'src/enums/countries'
import { invoiceTicket } from 'src/components/Printer/TicketEpson'
import { onSetPrintData } from 'src/actions/restaurant.actions'
import { getFormattedIds } from 'src/utils/utilitiesV2'
import { felDocumentType } from 'src/enums/felDocumentTypes'

const Invoices = props => {
  const dispatch = useDispatch()

  const { componentToPrint, displayNone } = props

  const [isPurchase] = useState(window.location.href.includes('/compras'))
  const [isVoucher] = useState(window.location.href.includes('/vales'))
  const [isNote] = useState(window.location.href.includes('/notas'))
  const [isCxc] = useState(window.location.href.includes('/cxc'))

  const invoices = useSelector(
    isPurchase ? selectPurchaseInvoices : selectGetInvoiceByOrder,
  )
  const country = useSelector(selectCompanyCountry)
  const company = useSelector(selectGetSingleCompany)

  const module = useSelector(selectCurrentModule)

  const loadingIPDF = useSelector(state =>
    loadingSelector([typeR.DOWNLOAD_PRINTABLE_INVOICE_BY_ORDER])(state),
  )
  const loadingTPDF = useSelector(state =>
    loadingSelector([typeO.GET_INVOICES_TICKET])(state),
  )

  const loading = useSelector(state => loadingSelector([typeO.GET_INVOICE])(state))
  const [hide, setHide] = useState(false)
  const [cNumber, setNumber] = useState(null)
  const [data, setData] = useState({})
  const [isCN, setCN] = useState(false)
  const [isRD, setIsRD] = useState(false) // Recibo por donación

  const seeWarehouseLocations = useSelector(state => selectCompanyFieldById(state, 126))
  const visibleSKU = useSelector(state => selectCompanyFieldById(state, 132))
  const invoiceTotalText = useSelector(state => selectCompanyFieldById(state, 107))
  const withoutTaxOnQuote = useSelector(state => selectCompanyFieldById(state, 111))

  const showSKU = !isCxc && visibleSKU && visibleSKU.value === 'true'

  useEffect(() => {
    const { match, displayNone } = props
    const { orderId, number, creditNote, purchaseId, typeReceipt } = match.params

    const documentId = isPurchase ? purchaseId : orderId
    const documentType = isPurchase
      ? 2
      : isVoucher || isNote
      ? 4
      : isCxc
      ? typeReceipt
      : 1
    setCN(creditNote === 'true')
    setIsRD(typeReceipt === '6')

    if (!displayNone) {
      dispatch(getInvoiceByOrder(documentId, documentType))
      setData({ documentType, documentId })
      dispatch(onHide(true))
    }

    if (number) setNumber(number)

    dispatch(getCompanyField(132))
    dispatch(getCompanyField(107))
    dispatch(getCompanyField(111))
  }, [])

  useEffect(() => {
    if (!hide) return
    printInvoice()
    setTimeout(() => {
      setHide(false)
    }, 1000)
  }, [hide])

  const printInvoice = () => {
    const getPDF = dispatch(
      downloadPrintableInvoiceByOrder(
        data.documentId,
        cNumber,
        module,
        data.documentType,
        isCN,
        false,
      ),
    )
    getPDF.then(file => {
      dispatch(
        onSetPrintData({
          show: true,
          pdfBlob: file,
        }),
      )
    })
  }

  const getFormattedDate = (millis, uuid) => {
    if (uuid) return formatDateFromMillis(millis, 6)
    else return formatDateFromMillis(millis)
  }

  const print = () => {
    dispatch(
      onSetPrintData({
        show: true,
        element: 'ticket-invoice-c',
        customData: invoiceTicket(country.id, invoices),
      }),
    )
  }

  const renderRetentions = i => {
    return (
      (i.isr > 0 || i.iva > 0) && (
        <>
          <div className="invoice-total-title special-invoice">
            Retenciones Factura Especial
          </div>
          <div style={{ border: '1px solid #226095', padding: 3 }}>
            {i.iva > 0 && (
              <div
                className="invoice-total"
                style={{ padding: 0, justifyContent: 'flex-end' }}>
                <div
                  className="invoice-total-title w-100"
                  style={{ fontSize: 12, lineHeight: 'normal' }}>
                  Retención IVA:
                </div>
                <Money
                  className="invoice-total-text"
                  style={{ fontSize: 12, lineHeight: 'normal' }}>
                  {parseFloat(i.iva)}
                </Money>
              </div>
            )}
            {i.isr > 0 && (
              <div
                className={'invoice-total'}
                style={{ padding: 0, justifyContent: 'flex-end' }}>
                <div
                  className="invoice-total-title w-100"
                  style={{ fontSize: 12, lineHeight: 'normal' }}>
                  Retención ISR:
                </div>
                <Money
                  className="invoice-total-text"
                  style={{ fontSize: 12, lineHeight: 'normal' }}>
                  {parseFloat(i.isr)}
                </Money>
              </div>
            )}
            <div
              className={'invoice-total'}
              style={{
                padding: 0,
                justifyContent: 'flex-end',
                maxWidth: 'inherit',
              }}>
              <div
                className="invoice-total-title w-100"
                style={{ fontSize: 12, lineHeight: 'normal' }}>
                Total Menos Retenciones:
              </div>
              <Money
                className="invoice-total-text"
                style={{ fontSize: 12, lineHeight: 'normal' }}>
                {parseFloat(i.total) - parseFloat(i.isr || 0) - parseFloat(i.iva || 0)}
              </Money>
            </div>
          </div>
        </>
      )
    )
  }

  const getItems = (details, isVerifiedSat, isQuote, felDocumentType) => {
    const response = formatItems(details, isVerifiedSat, isQuote, felDocumentType)

    return response?.filter(
      d =>
        d.description !== 'Comisión de agencia' &&
        (country.id !== Country.GT || !d.viewInSummary),
    )
  }

  const formatItems = (details, uuId, isQuote, felDocumentType) => {
    const withoutIva = isCommission({ invoiceDetails: details })
    return details?.map(d => {
      let total
      if (
        withoutIva ||
        d.taxPressBell > 0 ||
        (withoutTaxOnQuote?.value === 'true' && uuId !== null && isQuote === true)
      )
        total = d.amount - (d.iva || 0)
      else if (felDocumentType === 1 && country.id === Country.SV && d.retention !== null)
        total = d.amount + d.retention
      else total = d.amount

      let unitPrice =
        d.price !== null
          ? felDocumentType === 1 && country.id === Country.SV
            ? d.priceWithTax
            : d.price
          : (total + d.discount) / (d.quantity || 1)
      let subTotal = d.partialAmount !== null ? d.partialAmount : unitPrice * d.quantity

      return { ...d, unitPrice, subTotal, total }
    })
  }

  const getTotalValue = (title, value) => (
    <div className="invoice-total" style={{ padding: 0, justifyContent: 'flex-end' }}>
      <div className="invoice-total-title">{title}:</div>
      <Money className="invoice-total-text">{parseFloat(value)}</Money>
    </div>
  )

  const getCommission = i => {
    return i.invoiceDetails?.filter(d => d.description === 'Comisión de agencia')[0]
  }

  const isCommission = i => {
    return getCommission(i) !== undefined
  }

  const getSubTotalsValues = i => {
    let commission = getCommission(i)
    const items = getItems(i.invoiceDetails, i.uuId, i.quote)

    if (commission !== undefined) commission = formatItems([commission])[0]

    const subtotal = items?.reduce((total, i) => total + i.total, 0)
    const tax = items?.reduce((total, i) => total + (i.iva || 0), commission?.iva || 0)
    const taxPressBell = items?.reduce(
      (total, i) => total + (i.taxPressBell || 0),
      commission?.taxPressBell || 0,
    )
    return {
      subtotal,
      tax,
      taxPressBell,
      withSubTotal: commission || taxPressBell > 0,
      commission: commission?.total || 0,
    }
  }

  const getTotalComponent = i => {
    const { withSubTotal, subtotal, commission, tax, taxPressBell } =
      getSubTotalsValues(i)

    const summaries =
      country.id === Country.GT ? i.invoiceDetails?.filter(d => d.viewInSummary) : []
    return (
      <Row className={'container-buttons'}>
        <div className={'column'}>
          {withSubTotal || summaries.length > 0 ? (
            <div>{getTotalValue('Sub Total', subtotal)}</div>
          ) : (
            withoutTaxOnQuote.value === 'true' &&
            i.quote && <div>{getTotalValue('Total', subtotal)}</div>
          )}

          {commission > 0 && (
            <div>
              <div>{getTotalValue('Comisión de agencia', commission)}</div>
              <div>{getTotalValue('Total', subtotal + commission)}</div>
            </div>
          )}

          {tax > 0 && i.documentType !== 4 && <div>{getTotalValue('IVA', tax)}</div>}

          {taxPressBell > 0 && <div>{getTotalValue('Timbre', taxPressBell)}</div>}

          {i.retention !== null &&
            i.felDocumentType === 1 &&
            country.id === Country.SV && (
              <>
                <div>{getTotalValue('Total', i.total + i.retention)}</div>
                <div>{getTotalValue('(-) IVA Retenido', i.retention)}</div>
              </>
            )}
          {i.surcharge > 0 && (
            <div>{getTotalValue('Recargo por tarjeta', i.surcharge)}</div>
          )}
          {summaries.length > 0 && (
            <>
              {summaries.map((s, index) => (
                <div key={index}>{getTotalValue(s.description, s.amount)}</div>
              ))}
            </>
          )}

          <div>{getTotalValue('Gran Total', i.total)}</div>
        </div>
      </Row>
    )
  }

  /**
   * Create a header in table.
   * @param {string} name Name of the header
   * @param {number} size Size of the header
   * @returns JSX.Element
   */
  const setHeaderTable = (name, size) => {
    return (
      <Col
        xl={size}
        lg={size}
        md={size}
        sm={size}
        xs={size}
        className={'invoice-subtitle-t'}>
        {name}
      </Col>
    )
  }

  /**
   * Create a item in table.
   * @param {string} value Value of the header
   * @param {number} size Size of the header
   * @returns JSX.Element
   */
  const setValueTable = (value, size) => {
    return (
      <Money
        component={Col}
        xl={size}
        lg={size}
        md={size}
        sm={size}
        xs={size}
        className={'invoice-detail-t text-center'}>
        {value}
      </Money>
    )
  }

  return (
    <div style={displayNone ? { display: 'none' } : {}}>
      <Container
        ref={componentToPrint ? el => (componentToPrint.current = el) : undefined}
        className="print-page"
        style={
          displayNone
            ? { paddingRight: 20, paddingLeft: 50, paddingTop: 50 }
            : { paddingRight: 0 }
        }>
        {!displayNone && (
          <div className="t-container-k">
            <div className="title-k mb-3">
              {isVoucher
                ? 'Vales'
                : isNote
                ? 'Nota de Crédito'
                : isCxc
                ? isRD
                  ? 'Recibo por donación'
                  : 'Recibos'
                : 'Facturas'}
            </div>
            <Row className="container-buttons">
              <div className="column">
                <label className="ftf-form-label left mt-3">
                  {isVoucher
                    ? 'Vales'
                    : isNote
                    ? 'Nota de Crédito'
                    : isCxc
                    ? 'Recibos'
                    : 'Facturas'}
                </label>
                <Row className="container-buttons">
                  <Button
                    color={'primary'}
                    onClick={() => setHide(true)}
                    disabled={loadingTPDF || loadingIPDF}>
                    <Icon
                      icon={faPrint}
                      tooltip={
                        isVoucher
                          ? 'Imprimir vale'
                          : isNote
                          ? 'Imprimir nota de crédito'
                          : isCxc
                          ? 'Imprimir recibo'
                          : 'Imprimir facturas'
                      }
                    />
                  </Button>
                  <Button
                    color={'primary'}
                    onClick={() => {
                      const { match } = props
                      const { number } = match.params
                      dispatch(
                        downloadPrintableInvoiceByOrder(
                          data.documentId,
                          number,
                          module,
                          data.documentType,
                          isCN,
                        ),
                      )
                    }}
                    disabled={loadingTPDF}>
                    <Icon
                      spin={loadingIPDF}
                      icon={faFilePdf}
                      tooltip={`Descargar PDF de ${
                        isVoucher ? 'vales' : isNote ? 'nota de crédito' : 'facturas'
                      }`}
                    />
                  </Button>
                </Row>
              </div>
              {!isPurchase && !isCN && (
                <div className="column">
                  <label className="ftf-form-label left mt-3">Tickets</label>
                  <Row className="container-buttons">
                    <Button
                      color={'secondary'}
                      onClick={() => print()}
                      disabled={loadingIPDF || loadingTPDF}>
                      <Icon icon={faPrint} tooltip={'Imprimir Tickets'} />
                    </Button>
                    <Button
                      color={'secondary'}
                      onClick={() => {
                        const { match } = props
                        const { number } = match.params
                        dispatch(
                          getInvoiceTicketPDF(data.documentId, number, data.documentType),
                        )
                      }}
                      disabled={loadingIPDF}>
                      <Icon
                        spin={loadingTPDF}
                        icon={faFilePdf}
                        tooltip={'Descargar PDF de Tickets'}
                      />
                    </Button>
                  </Row>
                </div>
              )}
            </Row>
          </div>
        )}

        {loading && (
          <div className={'pb-custom'}>
            <ProgressBar
              label="Cargando"
              animated
              now={100}
              style={{ marginBottom: 10 }}
            />
          </div>
        )}
        <div>
          {invoices.map((i, index) => {
            const { phraseTypes } = i
            const cert = i.uuId !== null || i.uuId !== ''
            const isFiscalCreditSV = i.felDocumentType === 3 && country.id === Country.SV

            let printClass = ''
            if (invoices.length !== index + 1) printClass = 'print-after'

            const identifications = getFormattedIds(
              country.id,
              i.nit,
              i.nrc,
              i.cui,
              i.foreignId,
            )

            return (
              <p className={printClass} key={index}>
                <div className="invoice-border">
                  <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12} className={'company-data'}>
                      {company.id && (
                        <img
                          src={i.logo ? i.logo.url : LogoKolo}
                          className="invoice-logo"
                          style={{ marginLeft: 24 }}
                          alt=""
                        />
                      )}
                      <div className={'invoice-title mb-1 mx-4'}>{i.comercialName}</div>
                      <div className={'invoice-text mx-4'}>
                        {i.emisorName
                          ? i.emisorName
                          : i.companyDetail
                          ? i.companyDetail?.name
                          : ''}
                      </div>
                      {cert && (
                        <div className={'invoice-text mx-4'}>
                          NIT: {i.companyDetail?.nit}
                        </div>
                      )}
                      {cert && country.id === Country.SV && (
                        <div className={'invoice-text mx-4'}>
                          NRC: {i.companyDetail?.nrc}
                        </div>
                      )}
                      <div className={'invoice-text mx-4'}>
                        {i.commercialAddress || i.companyDetail?.address}
                      </div>
                      <div className={'invoice-text mx-4'}>{i.companyDetail?.email}</div>
                    </Col>

                    {cert && (
                      <Col
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        className={'invoice-document-type'}>
                        <div className="fel-data-container">
                          <div className="invoice-fel pl-1">
                            Documento Tributario Electrónico
                          </div>
                          <div className="invoice-subtitle">Tipo de documento:</div>
                          <div className="invoice-text mx-2">
                            {cert
                              ? isPurchase
                                ? 'Factura Especial'
                                : isFiscalCreditSV
                                ? 'Crédito fiscal'
                                : i.felDocumentType === felDocumentType.SUJETO_EXC
                                ? 'Factura de sujeto excluido'
                                : isCN && country.id === Country.SV
                                ? 'Nota de Crédito'
                                : (i.tipoAfiliacion && i.tipoAfiliacion === 3) ||
                                  i.felDocumentType === felDocumentType.INVOICE_PEQ
                                ? 'Factura Pequeño Contribuyente'
                                : isCxc
                                ? isRD
                                  ? 'Recibo por donación'
                                  : 'Recibo'
                                : 'Factura'
                              : 'ENVÍO'}
                          </div>
                          <div className="invoice-subtitle">
                            {country.id === Country.GT
                              ? 'Número de Autorización:'
                              : 'Código de generación:'}
                          </div>
                          <div className="invoice-text mx-2">
                            {i.factType === '1'
                              ? i.uuId
                              : 'ESTE DOCUMENTO NO ES UNA FACTURA ELECTRÓNICA'}
                          </div>
                          <div className="invoice-subtitle">
                            {country.id === Country.GT ? 'Serie:' : 'Número de control:'}
                          </div>
                          <div className="invoice-text mx-2">{i.authSeries || '---'}</div>
                          <div className="invoice-subtitle">
                            {country.id === Country.GT
                              ? 'Número:'
                              : 'Sello de recepción:'}
                          </div>
                          <div className="invoice-text mx-2">
                            {i.authNumber || i.number}
                          </div>
                          <div className="invoice-subtitle">Fecha de emisión:</div>
                          <div className="invoice-text mx-2">
                            {getFormattedDate(i.createdAt, i.uuId)}
                          </div>
                          <div className="invoice-subtitle">Fecha de autorización:</div>
                          <div className="invoice-text mx-2">
                            {getFormattedDate(i.date, i.uuId)}
                          </div>
                          {isCN && country.id === Country.GT && (
                            <>
                              <div className={'invoice-subtitle'}>
                                Fecha emisión doc. origen
                              </div>
                              <div className="invoice-text mx-2">{i.cnDate}</div>
                              <div className={'invoice-subtitle'}>
                                Número de autorización doc. origen:
                              </div>
                              <div className="invoice-text mx-2">{i.number}</div>
                              <div className={'invoice-subtitle'}>Motivo ajuste: </div>
                              <div className="invoice-text mx-2">{i.description}</div>
                            </>
                          )}
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Row className={'client-data mt-2'}>
                    <Col>
                      <div className={'d-flex'}>
                        <div className="invoice-subtitle-c">Nombre:</div>
                        <div className="invoice-text-c mx-1">{i.name}</div>
                      </div>
                      {cert && (
                        <div className={'column'}>
                          {identifications.map(clientID => (
                            <div key={clientID.identificationTypeId} className={'d-flex'}>
                              <div className={'invoice-subtitle-c'}>{clientID.label}</div>
                              <div className={'invoice-text-c mx-1'}>
                                {clientID.value}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      <div className={'d-flex'}>
                        <div className="invoice-subtitle-c">Dirección:</div>
                        <div className="invoice-text-c mx-1">{i.address}</div>
                      </div>
                      {cNumber && (
                        <div className={'d-flex'}>
                          <div className="invoice-subtitle-c">Referencia:</div>
                          <div className="invoice-text-c mx-1">{cNumber}</div>
                        </div>
                      )}
                      {i.description && !isCN && (
                        <div className={'d-flex'}>
                          <div className={'invoice-subtitle-c'}>Descripción</div>
                          <div className={'invoice-text-c mx-1'}>{i.description}</div>
                        </div>
                      )}
                      {i.commentary && (
                        <div className={'d-flex'}>
                          <div className={'invoice-subtitle-c'}>Comentario</div>
                          <div className={'invoice-text-c mx-1'}>{i.commentary}</div>
                        </div>
                      )}
                    </Col>
                  </Row>

                  {i.cancelled && (
                    <div className={'center'}>
                      <h4>ANULADA</h4>
                    </div>
                  )}

                  {i.documentType === 4 && i.associatedInvoices && (
                    <Row>
                      <Col className={'mx-4'}>
                        <div className="invoice-w">
                          <br />
                          <Row className="invoice-fel">
                            <Col
                              xl={3}
                              lg={3}
                              md={3}
                              sm={3}
                              xs={3}
                              className={'invoice-subtitle-t'}>
                              Tipo de documento
                            </Col>
                            {isFiscalCreditSV &&
                              setHeaderTable('Descuento', showSKU ? 1 : 2)}
                            <Col
                              xl={6}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              className={'invoice-subtitle-t'}>
                              No. documento
                            </Col>
                            <Col
                              xl={2}
                              lg={2}
                              md={2}
                              sm={2}
                              xs={2}
                              className={'invoice-subtitle-t'}>
                              Fecha del documento
                            </Col>
                          </Row>
                          <hr />
                          {i.associatedInvoices?.map((associatedInvoice, index) => (
                            <Row key={index}>
                              <Col
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                className={'invoice-detail-t text-center'}>
                                {country.id === Country.SV
                                  ? 'Comprobante de crédito fiscal'
                                  : 'Factura'}
                              </Col>
                              <Col
                                xl={6}
                                lg={6}
                                md={6}
                                sm={6}
                                xs={6}
                                className={'invoice-detail-t text-center'}>
                                {associatedInvoice.uuId ||
                                  'ESTE DOCUMENTO NO ES UNA FACTURA ELECTRÓNICA'}
                              </Col>
                              <Col
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                className={'invoice-detail-t text-center'}>
                                {associatedInvoice.authDate}
                              </Col>
                            </Row>
                          ))}
                          <hr />
                        </div>
                        <div className="invoice-m ">
                          <Row className="invoice-fel">
                            <Col xs={3} className={'invoice-subtitle-t'}>
                              Cantidad
                            </Col>
                            <Col xs={5} className={'invoice-subtitle-t'}>
                              Detalle
                            </Col>
                            <Col xs={4} className={'invoice-subtitle-t'}>
                              Total
                            </Col>
                          </Row>
                          {getItems(i.invoiceDetails, i.uuId, i.quote)?.map(
                            (d, index) => (
                              <Row key={index}>
                                <Col xs={3} className={'invoice-detail-t text-center'}>
                                  {d.quantity}
                                </Col>
                                <Col xs={5} className={'invoice-detail-t'}>
                                  {d.description}
                                </Col>
                                <Money
                                  component={Col}
                                  xs={4}
                                  className={'invoice-detail-t text-right'}>
                                  {parseFloat(d.total)}
                                </Money>
                              </Row>
                            ),
                          )}
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col className={'mx-4'}>
                      <div className="invoice-w">
                        <br />
                        <Row className="invoice-fel">
                          <Col
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            className={'invoice-subtitle-t'}>
                            Cantidad
                          </Col>

                          {showSKU && (
                            <Col
                              xl={isFiscalCreditSV ? 1 : 2}
                              lg={isFiscalCreditSV ? 1 : 2}
                              md={isFiscalCreditSV ? 1 : 2}
                              sm={isFiscalCreditSV ? 1 : 2}
                              xs={isFiscalCreditSV ? 1 : 2}
                              className={'invoice-subtitle-t  text-center'}>
                              SKU
                            </Col>
                          )}

                          <Col
                            xl={showSKU || isFiscalCreditSV ? 3 : 4}
                            lg={showSKU || isFiscalCreditSV ? 3 : 4}
                            md={showSKU || isFiscalCreditSV ? 3 : 4}
                            sm={showSKU || isFiscalCreditSV ? 3 : 4}
                            xs={showSKU || isFiscalCreditSV ? 3 : 4}
                            className={'invoice-subtitle-t  text-left'}>
                            {isCxc ? 'Cuenta por cobrar' : 'Producto'}
                          </Col>
                          <Col
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            className={'invoice-subtitle-t'}>
                            Precio Unitario
                          </Col>
                          {isFiscalCreditSV &&
                            setHeaderTable('Descuento', showSKU ? 1 : 2)}
                          <Col
                            xl={2}
                            lg={2}
                            md={2}
                            sm={2}
                            xs={2}
                            className={'invoice-subtitle-t'}>
                            Subtotal
                          </Col>
                          {isFiscalCreditSV && setHeaderTable('IVA', 1)}
                          {!isFiscalCreditSV &&
                            setHeaderTable('Descuento', showSKU ? 1 : 2)}
                          <Col
                            xl={2}
                            lg={2}
                            md={2}
                            sm={2}
                            xs={2}
                            className={'invoice-subtitle-t'}>
                            Total
                          </Col>
                        </Row>
                        <hr />
                        {getItems(
                          i.invoiceDetails,
                          i.uuId,
                          i.quote,
                          i.felDocumentType,
                        )?.map((d, index) => (
                          <Row key={index}>
                            <Col
                              xl={1}
                              lg={1}
                              md={1}
                              sm={1}
                              xs={1}
                              className={'invoice-detail-t text-center'}>
                              {d.quantity}
                            </Col>
                            {showSKU && (
                              <Col
                                xl={isFiscalCreditSV ? 1 : 2}
                                lg={isFiscalCreditSV ? 1 : 2}
                                md={isFiscalCreditSV ? 1 : 2}
                                sm={isFiscalCreditSV ? 1 : 2}
                                xs={isFiscalCreditSV ? 1 : 2}
                                className={'invoice-detail-t text-center'}>
                                {d?.productData?.code}
                              </Col>
                            )}
                            <Col
                              xl={showSKU || isFiscalCreditSV ? 3 : 4}
                              lg={showSKU || isFiscalCreditSV ? 3 : 4}
                              md={showSKU || isFiscalCreditSV ? 3 : 4}
                              sm={showSKU || isFiscalCreditSV ? 3 : 4}
                              xs={showSKU || isFiscalCreditSV ? 3 : 4}
                              className={'invoice-detail-t column'}>
                              <div>{d.description}</div>
                              <ListVariations selected={d.listCategories} hideMessage />
                              {seeWarehouseLocations &&
                                seeWarehouseLocations.value === 'true' && (
                                  <ListVariations
                                    selected={d.listLocations}
                                    hideMessage
                                    groupName={'Ubicaciones'}
                                  />
                                )}

                              {d.commentary && <div>{d.commentary}</div>}
                              <br />
                            </Col>
                            <Money
                              component={Col}
                              xl={1}
                              lg={1}
                              md={1}
                              sm={1}
                              xs={1}
                              className={'invoice-detail-t text-center'}>
                              {d.unitPrice}
                            </Money>
                            {isFiscalCreditSV &&
                              setValueTable(parseFloat(d.discount), showSKU ? 1 : 2)}
                            <Money
                              component={Col}
                              xl={2}
                              lg={2}
                              md={2}
                              sm={2}
                              xs={2}
                              className={'invoice-detail-t text-center'}>
                              {d.subTotal}
                            </Money>
                            {isFiscalCreditSV && setValueTable(d.detailTax, 1)}
                            {!isFiscalCreditSV &&
                              setValueTable(parseFloat(d.discount), showSKU ? 1 : 2)}
                            <Money
                              component={Col}
                              xl={2}
                              lg={2}
                              md={2}
                              sm={2}
                              xs={2}
                              className={'invoice-detail-t text-center'}>
                              {d.total}
                            </Money>
                          </Row>
                        ))}
                        <hr />
                        <Row>
                          <Col className={'d-flex'}>
                            <div
                              className={'d-flex w-100'}
                              style={{ justifyContent: 'space-between' }}>
                              {i.documentType === 2 ? (
                                <div
                                  className={'d-flex ru-graph-w'}
                                  style={{
                                    border: '1px solid #226095',
                                    borderRadius: 5,
                                  }}>
                                  {renderRetentions(i)}
                                </div>
                              ) : (
                                <div />
                              )}
                              {getTotalComponent(i)}
                            </div>
                          </Col>
                        </Row>
                        {invoiceTotalText?.value === 'true' && (
                          <Row>
                            <Col
                              className={'d-flex'}
                              style={{ justifyContent: 'flex-end' }}>
                              <div className="invoice-total-words">
                                {formatNumberToWords(parseFloat(i.total))}
                              </div>
                            </Col>
                          </Row>
                        )}
                      </div>
                      <div className="invoice-m ">
                        <Row className="invoice-fel">
                          <Col xs={3} className={'invoice-subtitle-t'}>
                            Cantidad
                          </Col>
                          <Col xs={5} className={'invoice-subtitle-t'}>
                            Detalle
                          </Col>
                          <Col xs={4} className={'invoice-subtitle-t'}>
                            Total
                          </Col>
                        </Row>
                        {getItems(i.invoiceDetails, i.uuId, i.quote)?.map((d, index) => (
                          <Row key={index}>
                            <Col xs={3} className={'invoice-detail-t text-center'}>
                              {d.quantity}
                            </Col>
                            <Col xs={5} className={'invoice-detail-t'}>
                              {d.description}
                            </Col>
                            <Money
                              component={Col}
                              xs={4}
                              className={'invoice-detail-t text-right'}>
                              {parseFloat(d.total)}
                            </Money>
                          </Row>
                        ))}

                        <Row className={'mt-3'}>
                          <Col className={'pr-0 pl-0'}>
                            {i.documentType === 2 ? (
                              <div
                                className={'d-flex w-100'}
                                style={{ justifyContent: 'space-between' }}>
                                {renderRetentions(i)}
                              </div>
                            ) : (
                              <div />
                            )}
                            {getTotalComponent(i)}
                          </Col>
                        </Row>

                        {invoiceTotalText?.value === 'true' && (
                          <Row>
                            <Col
                              className={'d-flex'}
                              style={{ justifyContent: 'flex-end' }}>
                              <div className="invoice-total-words">
                                {formatNumberToWords(parseFloat(i.total))}
                              </div>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </Col>
                  </Row>

                  {cert && (
                    <Row className={'my-3'}>
                      <Col className={'center'}>
                        <div className={'invoice-text'}>
                          Certificador: {i.nameCert || 'Sin certificador'}
                        </div>
                        <div className={'invoice-text'}>
                          Nit: {i.nitCert || 'Sin certificador'}
                        </div>

                        <div className={'mt-2'}>
                          {phraseTypes &&
                            phraseTypes.map((p, ii) => (
                              <div className={'invoice-text'} key={ii}>
                                {getPhraseLabel(p, i)}
                              </div>
                            ))}
                        </div>

                        {/*<div className={'invoice-text mt-3'}>{i.phraseType && i.phraseType.phrase}</div>*/}
                      </Col>
                    </Row>
                  )}
                  {i.footer && i.footer !== 'null' && (
                    <Row className={'my-3'}>
                      <Col className={'center'}>
                        <div className={'invoice-text'}>{i.footer}</div>
                      </Col>
                    </Row>
                  )}
                </div>
              </p>
            )
          })}
        </div>
      </Container>

      {!props.displayNone && (
        <>
          <PrintInvoiceTicket invoices={invoices} reference={cNumber} />
        </>
      )}
    </div>
  )
}
export default Invoices
