export enum CashTransferStatus {
  PENDING_APPROVAL = 1,
  APPROVED = 2,
  CANCELED = 3,
  REJECTED = 4,
}

export const cashTransferStatusEnum = {
  [CashTransferStatus.PENDING_APPROVAL]: {
    name: 'Pendiente',
  },
  [CashTransferStatus.APPROVED]: {
    name: 'Aprobada',
  },
  [CashTransferStatus.CANCELED]: {
    name: 'Cancelada',
  },
  [CashTransferStatus.CANCELED]: {
    name: 'Rechazada',
  },
}
