export const style = {
  body: {
    paddingRight: '10px',
  },
  left: {
    textAlign: 'left',
  },
  right: {
    textAlign: 'right',
  },
  center: {
    textAlign: 'center',
  },
  title: {
    fontSize: '19px',
    letterSpacing: '-0.09px',
    lineHeight: '20px',
    textTransform: 'capitalize',
    wordBreak: 'break-word',
    color: 'black',
  },
  subtitle: {
    fontSize: '15px',
    letterSpacing: '-0.09px',
    lineHeight: '18px',
    wordBreak: 'break-word',
    color: 'black',
  },
  text: {
    fontSize: '12px',
    letterSpacing: '0',
    lineHeight: '18px',
    wordBreak: 'break-word',
    color: 'black',
  },
  textContent: {
    fontSize: '11px',
    letterSpacing: '0',
    lineHeight: '18px',
    wordBreak: 'break-word',
    color: 'black',
    marginLeft: '5px',
  },
  dFlex: {
    display: 'flex',
    flexDirection: 'row',
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  detail: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  bold: {
    fontWeight: 'bold',
  },
  cursive: {
    fontStyle: 'italic',
  },
}
