import './POS.scss'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Media } from 'react-breakpoints'
import { Spinner, Modal, Row, Col } from 'react-bootstrap'
import { Title, Empty, Select, FAB, QRScanner, CustomFilter, Card } from 'src/components'
import {
  faCog,
  faCogs,
  faEllipsisV,
  faFileInvoice,
  faList,
  faPlus,
  faQrcode,
} from '@fortawesome/free-solid-svg-icons'
import TerminalPlus from 'src/assets/icon-plus.svg'

import {
  actionTypes,
  getAllPOSByUser,
  getPosConfigurationByUser,
  getTables,
  getTipsAvailable,
  setItems,
  setLastPosId,
  setPOSSelected,
} from 'src/actions/restaurant.actions'
import {
  selectAllPOSUser,
  selectGetAllTables,
  selectLastPosId,
} from 'src/selectors/restaurant.selector'

import { selectCurrentUser } from 'src/selectors/user.selector'
import { isAllowed } from 'src/selectors/modules.selector'

import { loadingSelector } from 'src/selectors/loading.selector'

import POSTerminal from './POSTerminal'
import POSList from './POSList'
import POSConfig from './POSConfig'
import POSPanelConfig from 'src/content/Restaurant/POSPanelConfig'
import { posTypes, posTypesEnum } from 'src/enums/posTypes'
import { setModalManageConcepts } from 'src/actions/utilities.actions'
import { POSProductPrices } from 'src/content/Restaurant/POSProductPrices'
import POSTerminalContent from './POSTerminal/POSTerminalContent'
import POSConfigurations from 'src/content/Restaurant/POSConfigurations'

const POSPage = ({ history, match }) => {
  const dispatch = useDispatch()

  const user = useSelector(selectCurrentUser)

  const posUser = useSelector(selectAllPOSUser)
  const loadingPosUser = useSelector(state =>
    loadingSelector([actionTypes.GET_ALL_POS_USER])(state),
  )

  const terminals = useSelector(selectGetAllTables)
  const loadingTerminals = useSelector(state =>
    loadingSelector([actionTypes.GET_TABLES])(state),
  )

  // PERMISSIONS
  const createPOS = useSelector(state => isAllowed(state, [2201]))
  const deletePOS = useSelector(state => isAllowed(state, [2202]))
  const configPOS = useSelector(state => isAllowed(state, [2203]))
  const configPOSPanel = useSelector(state => isAllowed(state, [2211]))
  const addTerminal = useSelector(state => isAllowed(state, [2207]))
  const editTerminal = useSelector(state => isAllowed(state, [2208]))

  const lastPosId = useSelector(selectLastPosId)

  const [current, setCurrentDate] = useState(0)

  const [flags, setFlags] = useState({ posUser: false })
  const [initialLoading, setLoading] = useState(true)

  const [posSelected, setPos] = useState({
    value: null,
    label: 'Sin seleccionar',
  })
  const posType =
    posTypes.find(
      t => posSelected.type === t.type && posSelected.subType === t.subType,
    ) || {}

  const [terminal, setTerminal] = useState({})
  const [posList, setPosList] = useState(false)
  const [posConfig, setPosConfig] = useState({ pos: {} })
  const [posPanelConfig, setPosPanelConfig] = useState({ pos: {} })
  const [scanner, setScanner] = useState(false)
  const [search, setSearch] = useState(null)
  const [seeTerminalImg, setSeeTerminalImg] = useState({
    show: false,
    imgUrl: null,
  })
  const [filters, setFilters] = useState({
    state: { label: 'Todos', value: null },
    user: { label: 'Todos', value: null },
  })
  const [showPrices, setShowPrices] = useState(false)

  const configurePosList = () => {
    if (posUser.length >= 1) {
      const posIdSaved = localStorage.getItem('posId')

      if (
        posIdSaved &&
        Number(posIdSaved) > 0 &&
        posUser.some(p => p.id === Number(posIdSaved))
      ) {
        const posUserItem = posUser.find(pu => pu.id === Number(posIdSaved))
        if (posUserItem) setPos(posUserItem)
      } else setPos(posUser[0])
    }

    setLoading(false)
  }

  useEffect(() => {
    setTimeout(() => {
      const a = new Date().valueOf()
      setCurrentDate(a)
    }, 1000)
  }, [current])

  useEffect(() => {
    dispatch(getAllPOSByUser(user.id))
    configurePosList()
  }, [])

  useEffect(() => {
    if (loadingPosUser) setFlags({ ...flags, posUser: true })
    else if (flags.posUser) {
      setFlags({ ...flags, posUser: false })
      configurePosList()
    }
  }, [loadingPosUser])

  useEffect(() => {
    if (!posSelected?.value) return
    const posId = posSelected.value
    if (lastPosId !== posId) dispatch(getTipsAvailable(posId))
    dispatch(setPOSSelected(posSelected))
    dispatch(getTables({ posId }))
    dispatch(getPosConfigurationByUser(posId, user.id))
    dispatch(setItems())
    dispatch(setLastPosId(posId))
    localStorage.setItem('posId', String(posId))
  }, [posSelected?.value])

  const filterSearch = item => {
    if (!search || search === '') return true
    const object = {
      name: item.number,
      user: item.user ? item.user.name : '',
      description: item.description,
      tags: item.tags,
    }
    const exp = new RegExp(search.toUpperCase(), 'g')
    return Object.keys(object).some(i =>
      `${JSON.stringify(object[i])}`.toUpperCase().match(exp),
    )
  }

  const filter = item => {
    const { state, user } = filters
    return (
      (state.value === null || state.value === item.free) &&
      (user.value === null || (item.user && item.user.id === user.value))
    )
  }

  const getUsers = () => {
    const response = [{ label: 'Todos', value: null }]
    terminals.forEach(({ user }) => {
      if (user) {
        const { id, name } = user
        if (!response.some(r => r.value === id)) response.push({ label: name, value: id })
      }
    })
    return response
  }

  const customFilter = () => {
    return (
      <Card>
        <Row>
          <Col xl={6} lg={6} md={6} sm={12}>
            <Select
              label={'Por estado'}
              options={[
                { label: 'Todos', value: null },
                { label: 'Libre', value: true },
                { label: 'Ocupado', value: false },
              ]}
              value={filters.state}
              onChange={state => setFilters({ ...filters, state })}
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12}>
            <Select
              label={'Por usuario'}
              options={getUsers()}
              value={filters.user}
              onChange={user => setFilters({ ...filters, user })}
            />
          </Col>
        </Row>
      </Card>
    )
  }

  const getColorByCapacity = () => {
    const a = '#22B573FF',
      b = '#6CAFC7FF',
      c = '#B35796',
      d = '#ED462DFF'
    const r = terminals.filter(t => !t.free).length / (posSelected.capacity || 1)
    const min = 0.333333333,
      medium = 0.666666666,
      maximum = 1
    if (r >= 0 && r <= min) return a
    else if (r > min && r <= medium) return b
    else if (r > medium && r <= maximum) return c
    else return d
  }

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        const desktop = breakpoints[currentBreakpoint] >= breakpoints.tabletLandscape
        const tablet = breakpoints[currentBreakpoint] >= breakpoints.toMini
        const { seeUser, seeImg } = posSelected

        let col
        if (seeImg) col = desktop ? 'col-k-3-5' : tablet ? 'col-k-6' : 'col-k-12'
        else col = desktop ? 'col-k-2' : tablet ? 'col-k-3' : 'col-k-6'

        return (
          <div>
            <div className={'pos-title'}>
              <Title title={'Mapa del punto de venta'} />
              <div className="pos-select"></div>
            </div>

            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <CustomFilter
                  mainStyle={{ marginTop: '29px' }}
                  placeholder={'Terminal / descripción / encargado / tickets'}
                  search={search}
                  onChange={({ target }) => {
                    const { value } = target
                    setSearch(value)
                  }}
                  customFilter={customFilter()}
                  pageSelectLeft
                  pageSelect={
                    <Select
                      label={'Punto de venta actual'}
                      options={posUser}
                      value={posSelected}
                      onChange={s => {
                        setPos(s)
                      }}
                    />
                  }
                />
              </Col>

              <Col xl={8} lg={8} md={8} sm={12} xs={12}></Col>
            </Row>

            {initialLoading || loadingTerminals || loadingPosUser ? (
              <div className={'center'}>
                <Spinner animation={'grow'} />
                <Spinner animation={'grow'} />
                <Spinner animation={'grow'} />
              </div>
            ) : terminals.length <= 0 ? (
              <Empty
                loading={loadingPosUser}
                hideButton={!posSelected || !posSelected.value}
                onClick={() => {
                  if (posSelected.type === posTypesEnum.PARKING.type) {
                    const p = `/0/${posSelected.value}`
                    history.push(match.url + p)
                  } else dispatch(getAllPOSByUser(user.id))
                }}
                subtitleSecond={
                  !posSelected || !posSelected.value
                    ? 'Selecciona un Punto de venta'
                    : posSelected.type === posTypesEnum.PARKING.type
                    ? 'Haz click para agregar un nuevo vehículo'
                    : null
                }
                buttonTitle={
                  posSelected.type === posTypesEnum.PARKING.type ? 'Añadir' : 'Recargar'
                }
              />
            ) : (
              <div className={'column'}>
                <div
                  className={`d-flex b-item-title`}
                  style={{ color: getColorByCapacity() }}>
                  <div className={'mr-1'}>CAPACIDAD</div>
                  <div>
                    {terminals.filter(t => !t.free).length}/{posSelected.capacity}
                  </div>
                </div>

                <div className={'row center'}>
                  <div className={'col-k-12'}></div>
                </div>

                <div className={'row left'}>
                  {posSelected.type === posTypesEnum.PARKING.type && (
                    <div className={col.concat(' b-item pos-terminal border-free')}>
                      <div
                        className={'column center'}
                        onClick={() => {
                          const p = `/0/${posSelected.value}`
                          history.push(match.url + p)
                        }}>
                        <div>
                          <img
                            className={'pos-terminal-img'}
                            src={TerminalPlus}
                            alt="terminal"
                          />
                        </div>
                        <div className={'mt-1'} style={{ width: '100%' }}>
                          <div className={'b-user-name'}>
                            {posSelected.type === posTypesEnum.RESTAURANT.type
                              ? 'Agregar nuevo vehículo'
                              : 'Agregar nueva terminal'}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {terminals
                    .filter(filter)
                    .filter(filterSearch)
                    .map((terminal, index) => (
                      <POSTerminalContent
                        key={terminal.id}
                        index={index}
                        terminal={terminal}
                        col={col}
                        posType={posSelected.type}
                        seeImg={seeImg}
                        seeUser={seeUser}
                        posTerminalImg={posType.image}
                        current={current}
                        editTerminal={editTerminal}
                        onClick={() => history.push(`${match.url}/${terminal.id}`)}
                        onEdit={() => {
                          setTerminal({
                            ...terminal,
                            show: true,
                            title: 'Terminal: ' + terminal.number,
                            userId: terminal.waiter,
                            posType: posSelected.type,
                          })
                        }}
                      />
                    ))}
                </div>
              </div>
            )}
            <FAB
              show
              icon={faEllipsisV}
              items={[
                {
                  show:
                    addTerminal &&
                    posSelected.value &&
                    posSelected.type !== posTypesEnum.PARKING.type,
                  text: 'Añadir terminal',
                  icon: faPlus,
                  action: () => {
                    setTerminal({
                      show: true,
                      free: true,
                      title: 'Nueva terminal',
                      posId: posSelected.value,
                      posType: posSelected.type,
                    })
                  },
                },
                {
                  show: createPOS || deletePOS,
                  text: 'Puntos de venta',
                  icon: faList,
                  action: () => {
                    setPosList(true)
                  },
                },
                {
                  show: configPOSPanel && posSelected.value,
                  text: 'Conceptos de facturación',
                  icon: faFileInvoice,
                  next: true,
                  action: () => {
                    dispatch(
                      setModalManageConcepts({ show: true, posId: posSelected.id }),
                    )
                  },
                },
                {
                  show: configPOSPanel && posSelected.value,
                  text: 'Configuración del panel',
                  icon: faCogs,
                  action: () => {
                    setPosPanelConfig({ show: true, pos: posSelected })
                  },
                },
                {
                  show: configPOS && posSelected.value,
                  text: 'Configuraciones',
                  icon: faCog,
                  action: () => {
                    setPosConfig({ show: true, pos: posSelected })
                  },
                },
                {
                  show: configPOS && posSelected.value,
                  text: 'Configuraciones APP',
                  icon: faCog,
                  action: () => {
                    setPosConfig({ showAPP: true, pos: posSelected })
                  },
                },
                {
                  show: false,
                  text: 'Escanear QR',
                  next: true,
                  icon: faQrcode,
                  action: () => setScanner(true),
                },
                {
                  show: true,
                  next: true,
                  text: 'Precios específicos',
                  icon: faCogs,
                  action: () => {
                    setShowPrices(true)
                  },
                },
              ]}
            />

            <POSTerminal
              {...terminal}
              posType={posType}
              onHide={success => {
                setTerminal({})
                if (success) dispatch(getTables({ posId: posSelected.value }))
              }}
            />

            <POSList
              show={posList}
              onHide={success => {
                setPosList(false)
                if (success) dispatch(getAllPOSByUser(user.id, true))
              }}
            />

            <POSConfig
              {...posConfig}
              onHide={success => {
                setPosConfig({ pos: {} })
                if (success) dispatch(getAllPOSByUser(user.id, true))
              }}
            />

            <POSConfigurations
              show={posConfig.showAPP}
              pos={posConfig.pos}
              onHide={success => {
                setPosConfig({ pos: {} })
                if (success) dispatch(getAllPOSByUser(user.id, true))
              }}
            />

            <POSPanelConfig
              {...posPanelConfig}
              onHide={() => {
                setPosPanelConfig({ pos: {} })
                dispatch(getPosConfigurationByUser(posSelected.value, user.id, true))
              }}
            />

            <QRScanner
              show={scanner}
              onHide={() => {
                setScanner(false)
              }}
            />

            <POSProductPrices
              show={showPrices}
              posId={posSelected.value}
              onHide={() => setShowPrices(false)}
            />

            <Modal
              show={seeTerminalImg.show}
              centered
              size={'md'}
              onHide={() => setSeeTerminalImg({ show: false, imgUrl: null })}>
              <Modal.Header closeButton>
                <Modal.Title>{seeTerminalImg.number}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <img
                    src={seeTerminalImg.imgUrl}
                    alt={seeTerminalImg.number}
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>
              </Modal.Body>
            </Modal>
          </div>
        )
      }}
    </Media>
  )
}

export default POSPage
