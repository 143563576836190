export const selectPlans = state =>
  state.plans.plans.map(d => ({ ...d, total: d.total > 10 ? d.total : 10 }))
export const selectPlansToSelect = state =>
  state.plans.plans.map(d => ({
    ...d,
    total: d.total > 10 ? d.total : 10,
    label: d.name,
    value: d.id,
  }))
export const selectActivePlans = state => ({
  ...state.plans.plan,
  total: state.plans.plan.total > 10 ? state.plans.plan.total : 10,
})
export const selectCompanyActivePlans = state => ({
  ...state.plans.companyPlan,
  total: state.plans.companyPlan.total > 10 ? state.plans.companyPlan.total : 10,
})
export const selectAddUser = state => state.plans.userPlan
export const selectModuleUser = state => state.plans.userModules

export const selectPrincipalPlans = () => [
  { value: 8, label: 'FEL' },
  { value: 9, label: 'POS' },
  { value: 11, label: 'PRO' },
  { value: 10, label: 'ERP' },
]

export const selectPlanPendingPayment = state => state.plans.planPendingPayment
