import React, { Component } from 'react'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'
import { faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import './CustomSelect.scss'
import IconButton from '../../buttons/IconButton'

const selectStyle = {
  //indicatorsContainer: (provided, state) => ({height: 29}),
  //dropdownIndicator: (provided, state) => ({padding: 5}),
  menuPortal: base => ({ ...base, zIndex: 9990 }),
}

export default class CustomSelect extends Component {
  render() {
    const {
      subText,
      dontNbsp,
      error,
      required,
      label,
      style,
      className,
      withoutLabel,
      disabled,
      info,
      loading,
      defaultValue,
      isClearable,
      mt,
      isSearchable,
      alias,
      renderControl,
      renderOption,
      dataCy,
      noOptionsMessage,
      testId,
    } = this.props

    const components = {}
    if (renderControl) components.Control = renderControl
    if (renderOption) components.Option = renderOption

    return (
      <div
        className={`input-wrapper has-feedback ${className} column `.concat(
          mt !== undefined ? `mt-${mt}` : 'mt-3',
        )}
        data-cy={dataCy}
        data-testid={dataCy}
        style={style}>
        {withoutLabel ? (
          ''
        ) : (
          <label className={'ftf-form-label left'}>
            {required && <span style={{ color: 'red', marginRight: 2 }}>&nbsp;* </span>}
            {label} &nbsp;
            {info ? (
              <label>
                {' '}
                <IconButton
                  tooltip={info}
                  color={'rgba(34, 96, 149, 0.75)'}
                  icon={faInfoCircle}
                  size={'sm'}
                />{' '}
              </label>
            ) : (
              <label> {!dontNbsp && <>&nbsp;</>} </label>
            )}
          </label>
        )}
        <Select
          className="basic-single"
          styles={selectStyle}
          data-testid={testId}
          value={this.props.value}
          onChange={this.props.onChange}
          options={this.props.options?.filter(option => option.show !== false) || []}
          menuPortalTarget={document.body}
          placeholder={this.props.placeholder || 'Selecciona...'}
          isClearable={isClearable || false}
          isSearchable={isSearchable !== false && this.props?.options.length > 7}
          isDisabled={disabled || loading}
          isLoading={loading}
          defaultValue={defaultValue}
          components={components}
          noOptionsMessage={() => noOptionsMessage || 'No hay opciones'}
        />
        {error ? (
          <div style={{ textAlign: 'left' }} className="input-error">
            <FontAwesomeIcon icon={faExclamationCircle} /> {error}
          </div>
        ) : (
          <div />
        )}
        {alias ? (
          <div style={{ marginLeft: '5px' }} className="input-alias">
            {' '}
            {alias ? 'Alias: '.concat(alias) : ''}{' '}
          </div>
        ) : (
          <div />
        )}
        {subText && (
          <div style={{ marginLeft: '5px' }} className="input-alias">
            {subText}
          </div>
        )}
      </div>
    )
  }
}
