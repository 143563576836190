import { w3cwebsocket as W3CWebSocket } from 'websocket'
import { WSS_URL } from 'src/settings/app.settings'

let client
let pingInterval

const connectWebSocket = (url: string, callBack: (response: string) => void) => {
  if (client && client.close) {
    client.close()
    clearInterval(pingInterval)
  }

  client = new W3CWebSocket(`${WSS_URL}/${url}`)

  client.onopen = () => {
    pingInterval = setInterval(() => {
      if (client.readyState === client.OPEN) {
        client.send('ping')
      }
    }, 50000)
  }

  client.onclose = () => clearInterval(pingInterval)

  client.onmessage = e => {
    if (e.data !== 'pong') callBack(e.data)
  }
}

export { connectWebSocket }
