import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Modal, Row, Col, ProgressBar } from 'react-bootstrap'
import { Paragraph, SwitchV2, Button, FormText, Select } from 'src/components'
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  getCompanyAddons,
  onFinishDemoAccount,
} from 'src/actions/mentor.actions'
import { selectCompanyAddons } from 'src/selectors/mentor.selector'

import { actions, getCompanyActivePlan } from 'src/actions/plans.actions'
import { selectCompanyActivePlans } from 'src/selectors/plans.selector'

import { loadingSelector } from 'src/selectors/loading.selector'

import { showAlert } from 'src/actions/alert.actions'
import {
  handlerError,
  handlerInfoWithButtons,
  handlerSuccess,
  hasErrors,
} from 'src/selectors/error.selector'

import periodicityTypes from 'src/enums/periodicityTypes'

import PlanItem from 'src/content/Distrbution/Plans/PlanItem'
import DetailAddons from './Addon/DetailAddons'
import InvoiceConceptField from 'src/components/InvoiceConceptField/InvoiceConceptField'
import { getOrderInvoiceConcepts } from 'src/actions/restaurant.actions'
import { ResumePlan } from './Addon/ResumePlan'

interface Props {
  show: boolean
  id: number
  title: string
  onHide: (boolean) => void
  isRenewal?: boolean
  isDemo?: boolean
  plan?: any
  addons?: MCompanyAddons[]
  comparison?: boolean
  onSave?: () => void
  clientId?: number
}

/** Component that shows the active plan and company addons and allows to finish the demo account.
 * @component
 * @param {boolean} show Indicate whether the component should be initialized
 * @param {number} id Id of company to see information and finish demo account
 * @param {string} title Name of company
 * @param {Function} onHide Close the component
 * @param {boolean} isRenewal Indicate if the company is a renewal account
 * @param {boolean} isDemo Indicate if the company is a demo account
 * @param {any} plan Active plan of the company
 * @param {MCompanyAddons[]} addons List of addons of the company
 * @param {boolean} comparison If plan and addons going to compare with current configuration of company.
 * @param {Function} onSave Function to execute after the demo account is finished
 * */
const FinishDemoAccount = ({
  show,
  id,
  title,
  onHide,
  isRenewal,
  isDemo,
  plan,
  addons = [],
  comparison,
  onSave,
  clientId,
}: Props) => {
  const dispatch = useDispatch()
  const companyAddons: MCompanyAddons[] = useSelector(selectCompanyAddons)

  const loadingSeeAddons = useSelector(state =>
    loadingSelector([actionTypes.GET_COMPANY_ADDONS])(state),
  )

  const loadingFinish = useSelector(state =>
    loadingSelector([actionTypes.ON_FINISH_DEMO_ACCOUNT])(state),
  )
  const errorFinish = useSelector(state =>
    hasErrors([actionTypes.ON_FINISH_DEMO_ACCOUNT])(state),
  )

  const activePlan: MPlanItem = useSelector(selectCompanyActivePlans)
  const loadingActivePlan: boolean = useSelector(state =>
    loadingSelector([actions.GET_COMPANY_ACTIVE_PLAN])(state),
  )

  const loading: boolean = loadingActivePlan || loadingSeeAddons

  const [flags, setFlags] = useState({ finish: false })

  const [openWarning, setOpenWarning] = useState<boolean>(false)
  const planWithOrder =
    (plan !== null && plan.orderId !== null) || activePlan.orderId !== null
  const [checked, setChecked] = useState<boolean>(planWithOrder)
  const [planPrice, setPlanPrice] = useState<number>(0)
  const [periodicity, setPeriodicity] = useState<ISelect>({ value: 1, label: 'MENSUAL' })
  const [emissionSell, setEmissionSell] = useState<ISelect>({
    value: 1,
    label: 'Emitir venta',
  })
  const [emissionInvoice, setEmissionInvoice] = useState<boolean>(true)
  const [concept, setConcept] = useState(null)
  const [addonsPreview, setAddonsPreview] = useState<MCompanyAddons[]>([])
  const [showResume, setShowResume] = useState<boolean>(false)

  useEffect(() => {
    if (!show || !id) return
    setUp()
  }, [show])

  useEffect(() => {
    setChecked(planWithOrder)
    setAddonsPreview(addons ? JSON.parse(JSON.stringify(addons)) : [])
  }, [show, planWithOrder])

  useEffect(() => {
    if (loadingFinish) setFlags({ ...flags, finish: true })
    else if (flags.finish) {
      setFlags({ ...flags, finish: false })
      const alert = errorFinish
        ? {
            ...handlerError(errorFinish.message),
          }
        : {
            ...handlerSuccess(),
            onConfirm: () => onClose(true),
          }
      dispatch(showAlert(alert))
    }
  }, [loadingFinish])

  const onClearOptions = () => {
    setOpenWarning(false)
    setChecked(false)
    setPlanPrice(0)
    setEmissionSell({ value: 1, label: 'Emitir venta' })
    setEmissionInvoice(true)
    setConcept(null)
    setShowResume(false)
  }

  const setUp = () => {
    dispatch(getCompanyActivePlan(id))
    dispatch(getCompanyAddons(id))
    dispatch(getOrderInvoiceConcepts())
  }

  const onClose = (update: boolean) => {
    onClearOptions()
    onHide(update)
  }

  const onValidate = () => {
    const messageAddon: string = companyAddons
      .filter(addon => (isRenewal ? addon.planId !== 17 : true))
      .filter(ca => !ca.amount || ca.amount <= 0)
      .reduce((r, i) => r + `${i?.item?.code} ${i?.item?.name}, `, '')
    if (messageAddon && messageAddon.length)
      return dispatch(
        showAlert({
          ...handlerError(`Los siguientes Addons no tienen un costo: ${messageAddon}`),
        }),
      )

    let message =
      '¿Desea facturar el plan y los addons para está cuenta?. Se concluirá la cuenta DEMO y se se realizarán los siguientes ajustes: '
    if (emissionSell.value === 1)
      message += `creación de venta y ${
        emissionInvoice ? 'emisión FEL' : 'no emisión FEL'
      }.`
    else message += 'no creación de venta y se activará la cuenta de inmediato.'

    const request: IFinishDemoRequest = {
      active: checked,
      planPrice,
      periodicity: periodicity.value,
      emissionSell: !planWithOrder && emissionSell.value === 1,
      emissionInvoice: !planWithOrder && emissionInvoice,
      conceptId: concept !== null && emissionInvoice ? concept.value : null,
      orderId: planWithOrder ? plan?.orderId || activePlan?.orderId : null,
      addons: planWithOrder
        ? addonsPreview.filter(addon => addon.planId !== 17 && addon.quantity > 0)
        : null,
    }
    return dispatch(
      showAlert({
        ...handlerInfoWithButtons('Facturar', message),
        onConfirm: () => dispatch(onFinishDemoAccount(id, request)),
      }),
    )
  }

  const validateAddons = () => {
    if (isRenewal && plan !== null) {
      const quantityMap = companyAddons.reduce((acc, addon) => {
        if (addon.planId !== 17) {
          acc[addon.planId] = (acc[addon.planId] || 0) + addon.quantity
        }
        return acc
      }, {})

      let errors = ''
      Object.keys(quantityMap).forEach(value => {
        if (
          addons
            .filter(addon => addon.planId === Number(value))
            .reduce((total, obj) => total + obj.quantity, 0) < quantityMap[value]
        ) {
          errors +=
            companyAddons.find(addon => addon.planId === Number(value)).item.name + ' '
        } else {
          const addons = addonsPreview.filter(addon => addon.planId === Number(value))
          let quantity = quantityMap[value]

          addons.forEach(addon => {
            if (quantityMap[value] >= addon.quantity) {
              quantity -= addon.quantity
              addon.quantity = 0
            } else addon.quantity -= quantity
          })
        }
      })

      if (errors.length > 0) {
        return dispatch(
          showAlert({
            ...handlerError(
              `Para poder finalizar la renovación acorde a la factura seleccionada, es necesario disminuir las cantidades de los siguientes addons: ${errors}`,
            ),
          }),
        )
      }
    }
    setOpenWarning(true)
  }

  return (
    <div>
      <Modal
        show={show && !openWarning}
        size={'lg'}
        centered
        onHide={() => onClose(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{title}: Finalización DEMO</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={'custom-modal-body'}>
            <Row>
              <Col xl={12}>
                {loading ? (
                  <ProgressBar
                    label="Cargando"
                    animated
                    now={100}
                    style={{ marginBottom: 10 }}
                  />
                ) : (
                  <Row>
                    {id && (
                      <Col xl={comparison ? 6 : 12}>
                        {comparison && <h2> Configuraciones actuales </h2>}
                        {/*@ts-ignore*/}
                        <PlanItem item={activePlan} isActive={true} lite={comparison} />
                      </Col>
                    )}
                    {plan && (
                      <Col xl={comparison ? 6 : 12}>
                        {comparison && <h2> Configuraciones de factura </h2>}
                        {/*@ts-ignore*/}
                        <PlanItem item={plan} isActive={true} lite={true} />
                      </Col>
                    )}
                    {id && (
                      <Col xl={comparison ? 6 : 12}>
                        <DetailAddons
                          addons={companyAddons.filter(addon =>
                            isRenewal ? addon.planId !== 17 : true,
                          )}
                          onChange={() => undefined}
                        />
                      </Col>
                    )}
                    {addons && (
                      <Col xl={comparison ? 6 : 12}>
                        <DetailAddons
                          addons={addons.filter(addon =>
                            isRenewal ? addon.planId !== 17 : true,
                          )}
                          onChange={() => undefined}
                        />
                      </Col>
                    )}
                  </Row>
                )}
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              color={'accent'}
              onClick={() => (onSave && !isRenewal ? onSave() : validateAddons())}>
              {plan ? 'Continuar' : isRenewal ? 'Activar renovación' : 'Finalizar demo'}
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openWarning && !showResume}
        size={'lg'}
        centered
        onHide={() => undefined}>
        <Modal.Header>
          <Modal.Title>
            {title}: {isDemo ? 'Finalización DEMO' : 'Renovación'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={checked ? 'custom-modal-body' : ''}>
          <Row>
            <Col xl={12}>
              <Paragraph>
                Está acción finalizará el tiempo demostrativo de la cuenta y ya no será
                posible operar en la cuenta.
              </Paragraph>
            </Col>
            <Col xl={12}>
              <SwitchV2
                checked={checked}
                onChange={v => setChecked(v)}
                label={'Facturar'}
                info={
                  'Al marcar la opción de facturación se emitirá una venta facturada con los valores puesto en los addons y el coste del plan y la cuenta se activará nuevamente cuando dicha factura sea pagada o post puesta por el mentor.'
                }
                disabled={loadingFinish || planWithOrder}
              />
            </Col>
            {checked && (
              <Col xl={12}>
                <Row>
                  {!planWithOrder && (
                    <Col xl={12}>
                      <FormText
                        required
                        label={'Precio del plan principal'}
                        value={planPrice}
                        type={'number'}
                        changeValue={v => {
                          let value: number
                          if (isNaN(v)) value = 0
                          else if (!v || v < 0) value = 0
                          else value = Number(v)
                          setPlanPrice(value)
                        }}
                        prepend={'Q'}
                        disabled={loadingFinish}
                      />
                    </Col>
                  )}
                  <Col xl={12}>
                    <Select
                      label={
                        'Periodicidad: Todos los cobros se harán por el monto establecido sin importar el tipo de periodicidad.'
                      }
                      value={periodicity}
                      onChange={v => setPeriodicity(v)}
                      options={periodicityTypes}
                      disabled={loadingFinish}
                    />
                  </Col>
                  {!planWithOrder && (
                    <>
                      {' '}
                      <Col xl={12}>
                        <Select
                          label={
                            'Seleccione si desea emitir una venta para la activación de la cuenta.'
                          }
                          value={emissionSell}
                          onChange={setEmissionSell}
                          options={[
                            { value: 1, label: 'Emitir venta' },
                            { value: 2, label: 'No emitir venta' },
                          ]}
                          disabled={loadingFinish}
                        />
                      </Col>
                      {emissionSell.value === 1 && (
                        <Col xl={12}>
                          <SwitchV2
                            disabled={loadingFinish}
                            checked={emissionInvoice}
                            onChange={setEmissionInvoice}
                            label={'Emitir factura electrónica'}
                            info={
                              'Al marcar la opción de facturación se emitirá con FEL.'
                            }
                          />
                        </Col>
                      )}
                      {emissionInvoice && (
                        <Col xl={12}>
                          <InvoiceConceptField
                            value={concept}
                            onChange={newConcept => {
                              if (!newConcept) return
                              setConcept(newConcept)
                            }}
                          />
                        </Col>
                      )}
                    </>
                  )}
                </Row>
              </Col>
            )}
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              icon={faWindowClose}
              color={'secondary'}
              disabled={loadingFinish}
              onClick={() => {
                onClearOptions()
              }}>
              Cancelar
            </Button>

            <Button
              loading={loadingFinish}
              disabled={checked && !planWithOrder && (!planPrice || planPrice < 0)}
              onClick={() => setShowResume(true)}>
              {isDemo ? 'Finalizar Demo' : 'Activar renovación'}
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <ResumePlan
        show={showResume}
        onHide={() => setShowResume(false)}
        isRenewal={isRenewal}
        isDemo={isDemo}
        plan={comparison ? plan : activePlan}
        addons={comparison ? addons : companyAddons}
        onSave={() => onValidate()}
        amountPlan={comparison ? plan.price : planPrice}
        periodicity={Number(periodicity.value)}
        companyId={id}
        clientId={clientId}
      />
    </div>
  )
}
export default FinishDemoAccount
