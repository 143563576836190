import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { firebaseConfig } from '../settings/app.settings'
import { getRemoteConfig } from 'firebase/remote-config'
import { initializeFirestore } from 'firebase/firestore'

const firebaseApp = initializeApp(firebaseConfig)
const firebaseAuth = getAuth(firebaseApp)

const firebaseStorage = getStorage(firebaseApp)

const firestore = initializeFirestore(firebaseApp, { ignoreUndefinedProperties: true })

const firebaseRemoteConfig = getRemoteConfig(firebaseApp)

export { firebaseApp, firebaseAuth, firebaseStorage, firebaseRemoteConfig, firestore }
