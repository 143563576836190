import React, { Component } from 'react'
import './kolo.scss'
import { Col, ProgressBar, Row } from 'react-bootstrap'
import Card from 'src/components/cards/Card'

import {
  faSave,
  faGlobe,
  faPhone,
  faAddressBook,
  faEye,
} from '@fortawesome/free-solid-svg-icons'
import SweetAlert from 'sweetalert-react'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  selectGetCompanyFields,
  selectGetSingleCompany,
} from 'src/selectors/company.selector'
import {
  actionTypes,
  getEditableFields,
  getSingleCompany,
  setEditableFields,
} from 'src/actions/company.actions'
import {
  errorsSelector,
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'
import { connect } from 'react-redux'
import { $http } from '../../../services/http'
import IconButton from 'src/components/buttons/IconButton'

import { getPermission, selectCurrentUserRoles } from 'src/selectors/modules.selector'
import { selectSetCurrentCompany } from 'src/selectors/company.selector'
import { getCurrentUser } from 'src/actions/user.actions'
import {
  selectCurrentCompany,
  selectCurrentModule,
  selectCurrentUser,
} from 'src/selectors/user.selector'
import { getCurrentUserRolesAction } from 'src/actions/modules.actions'
import Gallery, { imageTypes } from 'src/components/gallery/Gallery'
import FormTextArea from 'src/components/inputs/FormTextArea/FormTextArea'
import TextField from 'src/components/inputs/FormTextField/FormText'
import CarouselGallery from 'src/components/CarouselGallery/CarouselGallery'
import { selectProductsByClient } from 'src/selectors/products.selector'
import {
  actionTypes as pAction,
  changeStateOnKoloProfile,
  getOwnOnlineProducts,
} from 'src/actions/products.actions'
import { formatNumberWithCommas } from 'src/utils/formatters'
import PlaceholderProduct from 'src/assets/images/newDesign/placeholder-product.svg'
import { FilePicker } from 'react-file-picker'
import Button from 'src/components/buttons/Button'
import CustomTabs from 'src/components/navigation/Tabs/CustomTabs'
import { getAllVideos } from 'src/actions/videos.actions'
import { selectProducerVideos, selectProductVideos } from 'src/selectors/videos.selector'

class KoloProfile extends Component {
  state = {
    comercialName: '',
    lastName: '',
    comercialAddress: '',
    lastAddress: '',
    description: '',
    lastDescription: '',
    phone: '',
    lastPhone: '',
    history: '',
    lastHistory: '',
    webSite: '',
    lastWebsite: '',
    url: '',
    lastUrl: '',
    code: this.props.company.alphanumericId,
    selectedOption: null,
    company: this.props.company,
    showSuccess: false,
    showError: false,
    errorText: '',
    uploadProgress: 0,
    uploadIsLoading: false,
    errors: {},
    alert: { title: 'default' },
    showConfirm: false,
    productId: 0,
    uploading: false,
  }

  componentDidMount() {
    const idCompany = $http.defaults.headers.common['COTIZAP-Company']
    this.props.getCurrentRoles(1000)
    this.props.getSingleCompany(idCompany)
    this.props.getEditableFields()
    this.props.getProducts(idCompany)
  }

  UNSAFE_componentWillReceiveProps(next) {
    const {
      loadingFields,
      changeStateLoading,
      products,
      handlerSuccess,
      handlerError,
      loadingGetFields,
      fieldsHasError,
    } = this.props
    const { productId } = this.state
    let { alert } = this.state
    const { fieldsError } = next

    if (loadingGetFields && !next.loadingGetFields) {
      let editable = next.editableFields
      this.setState({
        comercialName: editable.comercialName,
        email: editable.email,
        comercialAddress: editable.comercialAddress,
        phone: editable.phone,
        description: editable.description,
        history: editable.history,
        webSite: editable.webSite,
        url: editable.profileName,
      })
      this.setState({
        lastName: editable.comercialName,
        lastAddress: editable.comercialAddress,
        lastPhone: editable.phone,
        lastDescription: editable.description,
        lastHistory: editable.history,
        lastWebsite: editable.webSite,
        lastUrl: editable.profileName,
      })
    }

    if (loadingFields && !next.loadingFields) {
      console.log(next.fieldsHasError)
      if (!fieldsHasError && next.fieldsHasError) {
        alert = handlerError(
          fieldsError.response
            ? fieldsError.response.data.message
            : 'Ha ocurrido un error inesperado.',
        )
        alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
      } else {
        alert = handlerSuccess(`La información fue actualizada.`)
        alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
      }
      this.setState({ alert })
    }

    if (changeStateLoading && !next.changeStateLoading) {
      products.map(p => {
        if (productId === p.id) p.onProfile = !p.onProfile
      })
    }
  }

  handleInputChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  handleUrlChange = event => {
    const { value } = event.target
    let val = value.replace(/[\s]/g, '-')
    val = val.replace(/[\.]/g, '_')
    const exp = new RegExp('[A-Za-z0-9_-]+')
    this.setState({ url: `${val}`.match(exp)[0] })
  }

  handlePhoneChange = event => {
    const { value } = event.target
    let compare = value.substr(`${value}`.length - 1, `${value}`.length)
    const exp = new RegExp('[\\+]?[0-9 ]+')
    if (
      `${compare}`.match(exp) ||
      value == '' ||
      (`${value}`.length == 1 && value == '+')
    ) {
      this.setState({ phone: value })
    }
  }

  isUpdated = () => {
    const { comercialName, comercialAddress, phone, description, webSite, history, url } =
      this.state
    const {
      lastName,
      lastAddress,
      lastPhone,
      lastDescription,
      lastWebsite,
      lastHistory,
      lastUrl,
    } = this.state
    const { handlerError, company } = this.props
    let { alert } = this.state
    let needUpdate = false
    if (comercialName != lastName) needUpdate = true
    else if (comercialAddress != lastAddress) needUpdate = true
    else if (phone != lastPhone) needUpdate = true
    else if (description != lastDescription) needUpdate = true
    else if (webSite != lastWebsite) needUpdate = true
    else if (history != lastHistory) needUpdate = true
    else if (url != lastUrl) needUpdate = true
    if (needUpdate) {
      alert = handlerError('Necesita guardar los últimos cambios realizados')
      alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
      this.setState({ alert })
      return
    }
    if (!url || url === '') {
      let name = this.getDefaultName()
      window.open(`https://kolo-app.com/productor/${name}`, '_blank')
    } else window.open(`https://kolo-app.com/productor/${url}`, '_blank')
  }

  saveEdited = () => {
    let {
      email,
      comercialName,
      comercialAddress,
      phone,
      description,
      webSite,
      history,
      url,
    } = this.state
    if (!phone) phone = ''
    if (!description) description = ''
    if (!webSite) webSite = ''
    if (!history) history = ''
    if (!comercialAddress) comercialAddress = ''
    if (!url) url = ''
    this.setState({
      lastName: comercialName,
      lastAddress: comercialAddress,
      lastPhone: phone,
      lastDescription: description,
      lastHistory: history,
      lastWebsite: webSite,
      lastUrl: url,
    })
    this.props.setEditableFields(
      email,
      comercialName,
      comercialAddress,
      phone,
      description,
      history,
      webSite,
      url,
    )
  }

  getDefaultName = () => {
    const { company } = this.props
    let name = company.name.replace(/[\s]/g, '-')
    name = name.replace(/[\.]/g, '_')
    return name
  }

  uploadVideo = file => {
    this.setState({ uploading: true })

    const onProgress = progress => this.setState({ uploading: progress })

    const onError = error => {
      this.setState({
        progress: 0,
        showErrorVideo: true,
        errorTextVideo: 'Lo sentimos, no fué posible subir tu video en este momento',
      })
    }

    const onSuccess = url => {
      this.setState({ uploading: false, uploadProgress: 0, urlVideo: url })
    }

    this.props.uploadVideo(file, this.onProgress, this.onSuccess, this.onError)
  }

  render() {
    const { description, history, webSite, phone, comercialAddress, url, uploading } =
      this.state
    const { company, companyId, products, videos } = this.props
    return (
      <div style={{ margin: 10 }}>
        <Row>
          <Col>
            <Card>
              <Row>
                <Col className={'kolo-company kolo-url'} xs={12} sm={12} md={4}>
                  <div style={{ color: 'rgba(52,146,215,0.9)' }}>
                    <b>Personaliza tu dirección Kolo:</b>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={7} className={'kolo-text kolo-url-input'}>
                  <div>https://kolo-app.com/productor/</div>
                  <TextField
                    autocomplete={'off'}
                    style={{ maxWidth: 350 }}
                    placeholder={`${this.getDefaultName()}`}
                    name={'url'}
                    value={url}
                    onChange={this.handleUrlChange}
                    append={
                      <IconButton
                        icon={faEye}
                        tooltip={'Ver en Kolo'}
                        onClick={this.isUpdated}
                      />
                    }
                  />
                </Col>
                <Col xs={12} sm={12} md={1} className={'kolo-url'}>
                  <div className={'text-center'}>
                    <IconButton
                      icon={faSave}
                      size={'4x'}
                      color={'rgba(29,102,164,0.87)'}
                      tooltip={'Guardar todos los cambios'}
                      onClick={() => {
                        this.saveEdited()
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Card style={{ minHeight: 380, maxHeight: 480 }}>
              <Row style={{ justifyContent: 'center' }}>
                <div style={{ maxHeight: 300, overflow: 'scroll' }}>
                  <Gallery
                    company={company && company.id}
                    imageType={imageTypes.LOGO}
                    entityId={company && company.id}
                    showUploader={company.id === companyId}
                    limit={1}
                  />
                </div>
              </Row>
              <Row>
                <TextField
                  prepend={<IconButton icon={faAddressBook} tooltip={'Dirección'} />}
                  placeholder={'Dirección'}
                  name={'comercialAddress'}
                  value={comercialAddress}
                  onChange={this.handleInputChange}
                />
                <TextField
                  mt={0}
                  prepend={<IconButton icon={faPhone} tooltip={'Teléfono'} />}
                  placeholder={'+502 20000000'}
                  name={'phone'}
                  value={phone}
                  onChange={this.handlePhoneChange}
                />
              </Row>
            </Card>
            <Card className={'kolo-filter'}></Card>
          </Col>

          <Col md={9}>
            <div style={{ minHeight: 380 }}>
              <div>
                <h4 className={'kolo-company'}>{company.name}</h4>
              </div>
              {/*IMAGENES*/}
              <CarouselGallery
                company={company && company.id}
                imageType={imageTypes.COMPANY}
                entityId={company && company.id}
                showUploader={company.id === companyId}
                maxWidth={220}
                limit={15}
              />
              {/*<CustomTabs items={[
                                {
                                    title: 'Imagenes',
                                    info: 'Registro de movimientos bancarios',
                                    component:
                                      <CarouselGallery
                                        company={company && company.id}
                                        imageType={imageTypes.COMPANY}
                                        entityId={company && company.id}
                                        showUploader={company.id === companyId}
                                        maxWidth={250}
                                        limit={4}
                                      />
                                },
                                {
                                    title: 'Videos',
                                    info: 'Videos relacionados al producto',
                                    component:
                                      <div style={{maxHeight: 400, overflow: 'scroll'}}>
                                          {
                                              videos.map((item) =>
                                                <Row>
                                                    <video width="320" height="240" controls>
                                                        <source src={item.url+'#t=10'} type="video/mp4"/>
                                                    </video>
                                                </Row>
                                              )
                                          }
                                          <FilePicker
                                            maxSize={250}
                                            extensions={['mp4']}
                                            onChange={this.uploadVideo}
                                            onError={errMsg => this.setState({showErrorVideo: true, errorTextVideo: errMsg})}>
                                              <div>
                                                  <Button disabled={uploading}>Cargar Video</Button>
                                                  {uploading
                                                    ?<ProgressBar variant="success" style={{marginTop: 5}}
                                                                  now={this.state.uploadProgress}/>:''}
                                              </div>
                                          </FilePicker>
                                      </div>
                                }
                            ]}/>*/}
            </div>
            <Card>
              {/*ACERCA DE*/}
              <Row>
                <Col md={3}>
                  <h3 className={'kolo-title'}>Acerca de la Empresa</h3>
                </Col>
                <Col md={9}>
                  <FormTextArea
                    classTextArea={'kolo-text'}
                    value={description}
                    name={'description'}
                    id={'description'}
                    rows={'6'}
                    onChange={this.handleInputChange}
                  />
                </Col>
              </Row>
            </Card>
            <div>
              <div>
                <h4 className={'kolo-company'}>Productos de {company.name}</h4>
              </div>
              <Card>
                <div style={{ overflowX: 'scroll', display: 'flex' }}>
                  {products &&
                    products.map(item => (
                      <Col
                        sm={12}
                        md={3}
                        style={{ margin: '10px 10px' }}
                        className={
                          item.onProfile ? 'kolo-product-selected' : 'kolo-product'
                        }
                        onClick={() => {
                          this.props.changeState(item.id)
                          this.setState({ productId: item.id })
                        }}
                      >
                        <Row>
                          <img
                            className={'product-image'}
                            style={{ height: 200 }}
                            src={
                              item.images.length > 0
                                ? item.images[0].url
                                : PlaceholderProduct
                            }
                            alt=""
                          />
                        </Row>
                        <Row className={'no-margins center'}>
                          <label className={'product-title'} style={{ marginTop: 20 }}>
                            {item.name}
                          </label>
                        </Row>
                        <Row className={'no-margins center'}>
                          <label
                            className={'product-price'}
                            style={{
                              marginTop: 10,
                              marginBottom: 10,
                            }}
                          >
                            Q{formatNumberWithCommas(item.price)}
                          </label>
                        </Row>
                      </Col>
                    ))}
                </div>
              </Card>
            </div>
            <Card>
              {/*HISTORIA*/}
              <Row>
                <Col md={3}>
                  <h3 className={'kolo-title'}>Historia de la Empresa</h3>
                </Col>
                <Col md={9}>
                  <FormTextArea
                    classTextArea={'kolo-text'}
                    value={history}
                    name={'history'}
                    id={'history'}
                    rows={'10'}
                    onChange={this.handleInputChange}
                  />
                  <TextField
                    prepend={<IconButton icon={faGlobe} tooltip={'Descripción'} />}
                    placeholder={'https://www.ejemplo.com'}
                    name={'webSite'}
                    value={webSite}
                    onChange={this.handleInputChange}
                  />
                </Col>
              </Row>
            </Card>
            <SweetAlert
              show={this.state.showSuccess}
              title="Guardado"
              text="Cambios guardados"
              type={'success'}
              onConfirm={() => {
                this.setState({ showSuccess: false })
              }}
            />
            <SweetAlert
              show={this.state.showError}
              title="Error"
              text="Ocurrio un error al almacenar los datos"
              type={'success'}
              onConfirm={() => {
                this.setState({ showError: false })
              }}
            />
          </Col>
        </Row>
        <SweetAlert {...this.state.alert} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  company: selectGetSingleCompany(state),
  companyId: selectCurrentCompany(state),
  editableFields: selectGetCompanyFields(state),
  loadingFields: loadingSelector([actionTypes.SET_COMPANIES_EDITABLE_FIELDS])(state),
  fieldsHasError: hasErrorsSelector([actionTypes.SET_COMPANIES_EDITABLE_FIELDS])(state),
  fieldsError: singleErrorSelector([actionTypes.SET_COMPANIES_EDITABLE_FIELDS])(state),
  errors: errorsSelector([
    actionTypes.UPDATE_SINGLE_COMPANY,
    actionTypes.CURRENT_COMPANY_SUCCESS,
  ])(state),
  loadingGetFields: loadingSelector([actionTypes.GET_COMPANIES_EDITABLE_FIELDS])(state),
  getPermission: id => getPermission(state, id),
  handlerError: message => handlerError(message),
  handlerSuccess: message => handlerSuccess(message),
  currentCompany: selectSetCurrentCompany(state),
  user: selectCurrentUser(state),
  currentRoles: selectCurrentUserRoles(state),
  module: selectCurrentModule(state),
  products: selectProductsByClient(state),
  changeStateLoading: loadingSelector([pAction.CHANGE_STATE_ON_PROFILE])(state),
  videos: selectProducerVideos(state),
})

const mapDispatchToProps = dispatch => ({
  getSingleCompany: idCompany => dispatch(getSingleCompany(idCompany)),
  getEditableFields: () => dispatch(getEditableFields()),
  setEditableFields: (
    email,
    comercialName,
    comercialAddress,
    phone,
    description,
    history,
    webSite,
    url,
  ) =>
    dispatch(
      setEditableFields(
        email,
        comercialName,
        comercialAddress,
        phone,
        description,
        history,
        webSite,
        url,
      ),
    ),
  getCurrentUser: () => dispatch(getCurrentUser()),
  getCurrentRoles: module => dispatch(getCurrentUserRolesAction(module)),
  getProducts: id => dispatch(getOwnOnlineProducts(id)),
  changeState: id => dispatch(changeStateOnKoloProfile(id)),
  getAllVideos: () => dispatch(getAllVideos()),
})

export default connect(mapStateToProps, mapDispatchToProps)(KoloProfile)
