import './ProductDetail.scss'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Alert from 'sweetalert-react'
import { history } from '../../../App'
import { Col, Row, Modal, ProgressBar } from 'react-bootstrap'
import {
  FormText,
  Card,
  Button,
  Icon,
  Select,
  CustomDate,
  Switch,
  TableV2,
  NumberField,
  MoneyField,
} from '../../../components'
import FormTextArea from '../../inputs/FormTextArea/FormTextArea'
import {
  faBox,
  faEdit,
  faInfoCircle,
  faPlus,
  faPlusCircle,
  faSave,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes as productActionTypes,
  getExistenceExternalWarehouses,
  getProductPricesNames,
  getProductsSimple as getProducts,
  getSingleProduct,
  updateProduct,
  getMeasurementUnits,
} from '../../../actions/products.actions'
import {
  selectProductsSimple as selectAllProductsForSelect,
  selectExistenceConsignated,
  selectSingleProduct,
  selectPricesList,
  selectUpdateProduct,
  selectMeasurementUnits,
} from '../../../selectors/products.selector'

import {
  selectCurrentCompany,
  selectCurrentModule,
} from '../../../selectors/user.selector'

import { selectCategorizationAssignedForSelect } from '../../../selectors/categorizations.selector'
import {
  selectCompanyIsLimit,
  selectFelItemTypes,
} from '../../../selectors/company.selector'

import { isAllowed } from '../../../selectors/modules.selector'

import {
  handlerError,
  handlerSuccess,
  handlerInfoWithButtons,
  hasErrorsSelector,
  singleErrorSelector,
} from '../../../selectors/error.selector'
import { loadingSelector } from '../../../selectors/loading.selector'

import CustomCreate from '../../inputs/Creable/CustomCreate'
import { toMoney } from '../../../utils/utilities'
import Money from '../../Money/Money'
import Gallery, { imageTypes } from 'src/components/gallery/Gallery'

import { getCategorizationByType } from '../../../actions/categorization.actions'
import { ListVariations } from 'src/content/Production/Categorization/Variation/ListVariations'
import { selectCategorizationByType } from '../../../selectors/categorizations.selector'
import IconButton from 'src/components/buttons/IconButton'
import { showAlert } from 'src/actions/alert.actions'

const defaultOption = { value: null, label: 'Sin seleccionar' }

const ProductDetail = ({
  modal,
  title,
  show,
  id,
  onHide,
  isDisabled,
  canEdit,
  onAdd,
  onRemove,
}) => {
  const dispatch = useDispatch()

  const felItemTypes = useSelector(selectFelItemTypes)

  const measurementUnits = useSelector(selectMeasurementUnits)

  const measurementUnitsOptions = [defaultOption].concat(
    measurementUnits.map(item => ({
      label: `${item.name}${item.symbol ? ` (${item.symbol})` : ''}`,
      value: item.id,
    })),
  )

  const product = useSelector(selectSingleProduct)
  const loading = useSelector(state =>
    loadingSelector([productActionTypes.GET_SINGLE_PRODUCT])(state),
  )
  const module = useSelector(selectCurrentModule)
  const currentCompanyId = useSelector(selectCurrentCompany)
  const products = useSelector(selectAllProductsForSelect)
  const productCategorization = useSelector(selectCategorizationAssignedForSelect)
  const existenceConsignated = useSelector(selectExistenceConsignated)
  const isLimit = useSelector(selectCompanyIsLimit)
  const names = useSelector(selectPricesList)
  const phrases = useSelector(state =>
    Object.assign(
      [],
      state.resolutions.phrases.map(p => ({ ...p, label: p.phrase, value: p.id })),
    ),
  )
  const variations = useSelector(selectCategorizationByType)
  const productEdited = useSelector(selectUpdateProduct)

  const updateProductIsLoading = useSelector(state =>
    loadingSelector([productActionTypes.UPDATE_PRODUCT])(state),
  )
  const updateProductHasError = useSelector(state =>
    hasErrorsSelector([productActionTypes.UPDATE_PRODUCT])(state),
  )
  const updateProductErrors = useSelector(state =>
    singleErrorSelector([productActionTypes.UPDATE_PRODUCT])(state),
  )

  const [action, setAction] = useState({ get: false })
  const [item, setItem] = useState({})
  const [error, setError] = useState({})
  const [alert, setAlert] = useState({ title: '' })
  const [priceDetail, setDetails] = useState([])
  const [defaultPrice, setDefault] = useState({ label: null, value: null })
  const [modalPrices, setModalP] = useState({ show: false, price: 0 })
  const [get, setGet] = useState(false)
  const [create, setCreate] = useState({})

  const [disabled, setDisabled] = useState(isDisabled)
  const [edit, setEdit] = useState(canEdit)

  const setPrices = useSelector(state => isAllowed(state, [2058, 7108]))
  // const configIva = useSelector((state) => isAllowed(state, [7114]));
  const configIva = false

  const [showVariations, setShowVariations] = useState(false)
  const [listVariations, setListVariations] = useState([])
  const [updated, setUpdated] = useState(false)
  const [itemDecimals, setDecimals] = useState({
    useDecimals: false,
    value: null,
  })

  useEffect(() => {
    if (id) init()
    dispatch(getProductPricesNames())
  }, [])

  useEffect(() => {
    if (!names) return
    setCreate({ ...create, options: names })
  }, [names])

  useEffect(() => {
    if (show) {
      setUpdated(false)
      setListVariations([])
      if (id) {
        setEdit(canEdit)
        init()
      }
    } else {
      setDisabled(isDisabled)
      setEdit(canEdit)
    }
  }, [show])

  useEffect(() => {
    if (!id) return
    dispatch(getSingleProduct(id))
  }, [id])

  useEffect(() => {
    if (loading) setAction({ ...action, get: true })
    else if (action.get) {
      setAction({ ...action, get: false })
      setGet(true)
    }
  }, [loading])

  useEffect(() => {
    if (!get) return
    setGet(false)
    if (product.name) {
      if (phrases)
        product.phraseReference = phrases.find(d => product.phraseReference === d.value)

      let baseProduct = null,
        conversionFactor = null
      if (product.subProductId) {
        conversionFactor = product.subProductValue
        baseProduct = products.find(ps => Number(ps.id) === product.subProductId)
      }
      let label = toMoney(product.price)
      if (product.listPrices) {
        const list = product.listPrices.map(option => ({
          value: option.price,
          alias: option.alias,
          label: `${(option.alias && `${option.alias} - `) || ''}${toMoney(
            option.price,
          )}`,
        }))
        setDetails(list)

        const productPrice = product.listPrices.find(
          option => option.price === product.price,
        )
        if (productPrice?.alias)
          label = `${productPrice.alias} - ${toMoney(productPrice.price)}`
      }
      setDefault({
        label,
        value: product.price,
      })

      let offerPrice = null
      let offerQuantity = null
      if (product.offerPrice) {
        offerPrice = product.offerPrice.price
        offerQuantity = product.offerPrice.quantity
      }

      let productionPrice = product.productionPrice || null

      const felItemType =
        felItemTypes.find(item => item.value === product.felItemTypeId) || defaultOption

      const measurementUnit =
        measurementUnitsOptions.find(item => item.value === product.measurementUnitId) ||
        defaultOption

      const newItem = {
        ...product,
        felItemType,
        offerPrice,
        offerQuantity,
        baseProduct,
        conversionFactor,
        productionPrice,
        measurementUnit,
        archiveDate: product.archiveDate ? new Date(product.archiveDate) : null,
      }
      setItem(newItem)
      if (product.listCategories) {
        setListVariations(
          product.listCategories.map(x => {
            let val = productCategorization.find(y => y.value === x)
            return {
              id: parseInt(x),
              name: val ? val.label : '',
            }
          }),
        )
      }

      if (product.decimals && product.decimals !== null)
        setDecimals({
          useDecimals: true,
          value: product.decimals,
        })
    }
  }, [get])

  useEffect(() => {
    if (updateProductIsLoading) setAction({ ...action, update: true })
    else if (action.update) {
      setAction({ ...action, update: false })
      if (updateProductHasError)
        setAlert({
          ...handlerError(updateProductErrors.message),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            setDisabled(isDisabled)
            setEdit(canEdit)
          },
        })
      else
        setAlert({
          ...handlerSuccess('Item actualizado'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            setDisabled(isDisabled)
            setEdit(canEdit)
            setUpdated(true)
          },
        })
    }
  }, [updateProductIsLoading])

  useEffect(() => {
    if (productEdited !== null && productEdited) {
      setItem({ ...item, barcodes: productEdited.barcodes, autoBarcode: false })
    }
  }, [productEdited])

  useEffect(() => {
    if (defaultPrice) setItem({ ...item, price: defaultPrice.value })
    else setItem({ ...item, price: 0 })
  }, [defaultPrice])

  const init = () => {
    setGet(false)
    setItem({})
    dispatch(getMeasurementUnits())
    if (modal) dispatch(getProducts())
    if (modal && module === 2000) dispatch(getExistenceExternalWarehouses(id))
  }

  const onChange = event => {
    let { name, value, type } = event.target
    if (type === 'number') {
      if (!value || value < 0) value = 0
      if (!isNaN(value)) {
        if (name === 'discountLimitedNumber') {
          if (Number(value) > Number(item.price)) value = item.price
        }

        setItem({ ...item, [name]: value })
      }
    } else setItem({ ...item, [name]: value })
  }

  const onCheck = event => {
    const { name, checked } = event.target
    setItem({ ...item, [name]: checked })
  }

  // const onCurrencyChange = (event, maskValue, value) => {
  //   const { name } = event.target
  //   setItem({ ...item, [name]: value })
  // }

  const onPriceChange = item => {
    setDefault(item)
  }

  const deletePrice = (id, alias) => {
    let ids = priceDetail
    ids = ids.filter(p => id !== p.value || alias !== p.alias)
    if (defaultPrice && id === defaultPrice.value) {
      setDefault(null)
    }
    setDetails([...ids])
  }

  const save = () => {
    const {
      subPrice,
      baseProduct,
      conversionFactor,
      code,
      barCode,
      name,
      description,
      price,
      productionPrice,
      transformation,
      activeOnline,
      activeLink,
      activeArchive,
      activePurchase,
      useFactor,
      withoutInventory,
      discountLimited,
      discountLimitedNumber,
      activeExpenses,
      activeSell,
      activeWaste,
      activeProduction,
      teoricExistence,
      activeUpdatable,
      archiveAutomatic,
      archiveInDate,
      archiveInInventory,
      archiveDate,
      barcodes,
      phraseReference,
      offerPrice,
      offerQuantity,
      felItemType,
      measurementUnit,
      autoBarcode,
      decimals,
      subProductData,
    } = item

    let errors = {}
    // if(!includeIva && (!phraseReference || !phraseReference.value)) errors.phrase = 'Campo requerido'
    if (!code) errors.code = 'Campo requerido'
    if (!name) errors.name = 'Campo requerido'
    if (
      priceDetail.length > 0 &&
      (!defaultPrice ||
        defaultPrice.value === '' ||
        defaultPrice.value === null ||
        defaultPrice.value === undefined)
    )
      errors.price = 'Campo requerido'
    if (baseProduct && baseProduct.value && !conversionFactor)
      errors.conversionFactor = 'Campo requerido'

    if (discountLimited && !discountLimitedNumber)
      errors.discountLimitedNumber = 'Campo requerido'

    if (!felItemType || !felItemType.value) errors.felItemType = 'Campo Requerido'
    if (itemDecimals.useDecimals && itemDecimals.value === null)
      errors.decimals = 'Campo requerido'

    if (Object.keys(errors).length === 0) {
      let base = baseProduct ? baseProduct.value : null
      let factor = conversionFactor || null
      let subProductId = subProductData ? subProductData.id : null
      let subProductValue = conversionFactor || null
      let list = []
      let listAlias = []

      if (setPrices) {
        if (priceDetail.length > 0) {
          priceDetail.forEach(s => {
            list.push(s.value)
            listAlias.push(s.alias)
          })
        } else {
          list.push(0)
          listAlias.push('Precio por defecto')
        }
      } else {
        if (priceDetail.length > 0) {
          priceDetail.forEach(s => {
            list.push(s.value)
            listAlias.push(s.alias)
          })
        }
        list.push(price)
        listAlias.push('Precio por defecto')
      }

      let categorization = ''
      listVariations.forEach((c, i) => {
        categorization += c.id
        if (i < listVariations.length - 1) {
          categorization += ','
        }
      })
      dispatch(
        updateProduct(id, {
          name,
          code,
          barCode,
          description,
          price,
          productionPrice,
          list,
          baseProductId: base,
          transformationFactor: factor,
          subProductId,
          listAlias,
          subProductValue,
          transformation,
          activeOnline,
          subPrice: subPrice != null && subPrice > 0 ? subPrice : 0,
          activeLink,
          activePurchase,
          activeExpenses,
          activeSell,
          activeWaste,
          activeProduction,
          useFactor,
          withoutInventory,
          discountLimited,
          discountLimitedNumber,
          activeArchive: withoutInventory && activeArchive,
          includeIva: true,
          teoricExistence,
          activeUpdatable,
          archiveAutomatic,
          archiveInDate,
          archiveInInventory,
          editDate: archiveInDate ? archiveDate.valueOf() : null,
          barcodes,
          validateBarcodes: true,
          phraseReference: phraseReference ? phraseReference.value : null,
          offerPrice: { price: offerPrice, quantity: offerQuantity },
          felItemTypeId: felItemType.value,
          measurementUnitId: measurementUnit.value,
          categories: categorization,
          autoBarcode: autoBarcode,
          useDecimals: decimals !== null,
          decimals: itemDecimals.value !== null ? Number(itemDecimals.value) : null,
        }),
      )
    }
    setError({ ...errors })
  }

  const createOption = label => ({
    value: label,
    label: label,
  })

  const handleChange = newValue => {
    setCreate({ ...create, value: newValue })
    if (newValue) setModalP({ ...modalPrices, alias: newValue.value, valias: newValue })
    else setModalP({ ...modalPrices, alias: '', valias: null })
  }

  const handleCreate = inputValue => {
    setCreate({ ...create, isLoading: true })
    setTimeout(() => {
      const options = names
      const newOption = createOption(inputValue)
      setCreate({ isLoading: false, options: [...options, newOption], value: newOption })
      setModalP({ ...modalPrices, alias: inputValue })
    }, 250)
  }

  const renderCategories = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-start', flexFlow: 'wrap' }}>
        {productCategorization.map((c, index) => (
          <div key={index} className="chip mb-3">
            <div className="chip-head">{c.label.charAt(0)}</div>
            <div className="chip-content">{c.label}</div>
            <div
              className="chip-close"
              onClick={() => {
                if (!disabled) onRemove(c)
              }}>
              <svg
                className="chip-svg"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true">
                {!disabled && (
                  <path
                    d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7
                                  15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"
                  />
                )}
              </svg>
            </div>
          </div>
        ))}
        {!disabled && !loading && (
          <Button
            className={'big-button'}
            style={{ width: 210, height: 35, lineHeight: 1 }}
            onClick={() => onAdd()}>
            <Icon
              icon={faPlusCircle}
              color={'white'}
              size={'1x'}
              iconStyle={{ marginRight: 5 }}
            />
            Añadir Categorías
          </Button>
        )}
      </div>
    )
  }

  const inputValues = () => {
    return (
      <>
        <Col xl={modal ? 12 : 6} lg={modal ? 12 : 6} md={modal ? 12 : 6} sm={6} xs={12}>
          <FormText
            label={'SKU / Código'}
            type={'text'}
            name={'code'}
            value={item.code}
            onChange={onChange}
            disabled={disabled}
          />
        </Col>
        <Col xl={modal ? 12 : 6} lg={modal ? 12 : 6} md={modal ? 12 : 6} sm={6} xs={12}>
          <FormText
            label={'Nombre'}
            type={'text'}
            name={'name'}
            value={item.name}
            onChange={onChange}
            disabled={disabled}
            error={error.name}
          />
        </Col>
        <Col xl={modal ? 12 : 6} lg={modal ? 12 : 6} md={modal ? 12 : 6} sm={6} xs={12}>
          <Select
            disabled={disabled}
            label={'Bien o Servicio'}
            info={'La propiedad "Bien o Servicio" se usará para la factura electrónica'}
            required
            value={item.felItemType}
            options={felItemTypes}
            onChange={felItemType => setItem({ ...item, felItemType })}
            error={error.felItemType}
          />
        </Col>

        <Col xl={modal ? 12 : 6} lg={modal ? 12 : 6} md={modal ? 12 : 6} sm={6} xs={12}>
          <Select
            disabled={disabled}
            label={'Unidad de medida'}
            value={item.measurementUnit}
            options={measurementUnitsOptions}
            onChange={measurementUnit => setItem({ ...item, measurementUnit })}
            error={error.measurementUnit}
          />
        </Col>

        <Col xl={modal ? 12 : 6} lg={modal ? 12 : 6} md={modal ? 12 : 6} sm={6} xs={12}>
          <FormText
            prependMoneySymbol
            label={'Costo Unitario'}
            type={'number'}
            id={'productionPrice'}
            placeholder={'Costo Unitario'}
            name={'productionPrice'}
            value={item.productionPrice}
            onChange={onChange}
            error={error.productionPrice}
            info={'Ingrese Costo Unitario para Ingreso de Inventario'}
            disabled={disabled}
          />
        </Col>
        {setPrices && (
          <Col sm={12} md={modal ? 12 : 4}>
            <label className="ftf-form-label mt-3"> Precios de venta disponibles </label>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 10,
              }}>
              {priceDetail?.map((price, index) => (
                <div key={index} className={'c-item assigned centered'}>
                  <Money component="label" className={'label-user-tag'}>
                    {price.value}
                  </Money>
                  <IconButton
                    className={'c-item-button assigned centered'}
                    icon={faTimes}
                    tooltip={'Eliminar'}
                    color={'white'}
                    disabled={disabled}
                    onClick={() => deletePrice(price.value, price.alias)}
                  />
                </div>
              ))}
              {!disabled && (
                <div
                  className={'c-item add centered'}
                  onClick={() => {
                    setModalP({ show: true, price: 0 })
                    setCreate({ ...create, value: null })
                  }}>
                  Agregar
                  <IconButton
                    className={'c-item-button add centered'}
                    icon={faPlus}
                    disabled={disabled}
                    tooltip={'Crear precio'}
                    color={'white'}
                  />
                </div>
              )}
            </div>
          </Col>
        )}
        <Col xl={modal ? 6 : 4} lg={modal ? 6 : 4} md={modal ? 6 : 4} sm={6} xs={12}>
          {setPrices ? (
            <div className={'input-wrapper has-feedback mt-3'}>
              <Select
                label={'Precio de venta por defecto'}
                options={priceDetail}
                value={defaultPrice}
                disabled={disabled}
                onChange={onPriceChange}
                error={error.price}
                required
              />
            </div>
          ) : (
            <FormText
              label={'Precio de venta'}
              type={'number'}
              name={'price'}
              value={item.price}
              onChange={onChange}
              disabled={disabled}
              error={error.price}
            />
          )}
        </Col>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <Row>
            <Col xl={5} lg={5} md={5} sm={5} xs={12}>
              <Switch
                id={'useDecimals'}
                name={'useDecimals'}
                checked={itemDecimals.useDecimals}
                disabled={(item.decimals && item.decimals !== null) || disabled}
                label={'Usar decimales'}
                info={
                  'Al activar la opción el item podrá ser configurado para usar decimales'
                }
                topLabel
                onChange={({ target }) => {
                  const { checked } = target
                  if (checked)
                    dispatch(
                      showAlert({
                        ...handlerInfoWithButtons(
                          item.baseProduct !== null
                            ? 'Este item forma parte de un árbol de ítems.'
                            : 'Efectos en el item al activar decimales',
                          item.baseProduct !== null
                            ? 'Al activar decimales, este item ya no formará parte de un árbol de ítems.'
                            : 'Una vez el item sea configurado y guardado con decimales, esta acción no podrá ser revertida y tampoco podrá ser configurado para usar un árbol de ítems.',
                        ),
                        onConfirm: () => {
                          setDecimals({
                            value: checked ? 1 : null,
                            useDecimals: checked,
                          })
                          setItem({ ...item, baseProduct: null, conversionFactor: null })
                        },
                        showCancelButton: true,
                      }),
                    )
                  else
                    setDecimals({
                      value: checked ? 1 : null,
                      useDecimals: checked,
                    })
                }}
              />
            </Col>
            {itemDecimals.useDecimals && (
              <Col xl={7} lg={7} md={7} sm={7} xs={12}>
                <NumberField
                  mt={0}
                  disabled={disabled}
                  label={'Cantidad de decimales'}
                  name={'decimals'}
                  id={'decimals'}
                  value={itemDecimals.value}
                  min={1}
                  max={6}
                  onValueChange={value =>
                    setDecimals({ ...itemDecimals, value, error: null })
                  }
                  error={itemDecimals.error}
                  info={'De 1 a 6 decimales'}
                />
              </Col>
            )}
          </Row>
        </Col>
      </>
    )
  }

  const renderDetail = () => {
    return (
      <div>
        <Row>
          <Col sm={12} md={12}>
            <Row style={{ borderBottom: '1px solid lightgray', paddingBottom: 10 }}>
              {renderCategories()}
            </Row>
            <Row>
              {modal ? (
                <>
                  <Col
                    xl={6}
                    lg={6}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ position: 'relative', display: 'flow' }}>
                    <Gallery
                      imageType={imageTypes.PRODUCT}
                      //images={images}
                      company={product && product.companyId}
                      entityId={product && product.id}
                      // onUploadSuccess={this.uploadImage}
                      // onDelete={this.deleteImage}
                      disabled={disabled}
                      maxWidth={300}
                    />
                  </Col>
                  <Col>
                    <Row>{inputValues()}</Row>
                  </Col>
                </>
              ) : (
                inputValues()
              )}

              <Col xl={8}>
                <div className={'column'}>
                  <label className={'ftf-form-label left mt-3'}>Punto de ventas</label>
                  <p>
                    Define un precio de oferta al comandar cierta cantidad del mismo item
                  </p>
                  <Row>
                    <FormText
                      style={{ flex: 1 }}
                      label={'Precio'}
                      type={'number'}
                      id={'offerPrice'}
                      name={'offerPrice'}
                      value={item.offerPrice}
                      changeValue={value => {
                        if (value && value < 0) value = 0
                        setItem({ ...item, offerPrice: value })
                      }}
                    />
                    <FormText
                      style={{ flex: 1, marginLeft: 5 }}
                      label={'Cantidad'}
                      type={'number'}
                      id={'offerQuantity'}
                      name={'offerQuantity'}
                      value={item.offerQuantity}
                      changeValue={value => {
                        if (value && value < 0) value = 0
                        setItem({ ...item, offerQuantity: value })
                      }}
                    />
                  </Row>
                </div>
              </Col>

              <Col xl={10} lg={12} sm={12} xs={12}>
                <Row>
                  <Col>
                    {/*<ProductBarcodes
                      disabled={disabled || item.autoBarcode}
                      barcodes={item.autoBarcode && !disabled ? [] : item.barcodes || []}
                      onChange={barcodes => {
                        setItem({ ...item, barcodes })
                      }}
                    />*/}
                  </Col>
                  <Col>
                    <Switch
                      id={'autoBarcode'}
                      name={'autoBarcode'}
                      disabled={disabled}
                      checked={item.autoBarcode && !disabled}
                      label={'Codigo de barra automaticamente'}
                      info={
                        'Al activar la opción, se generara un codigo de barra de forma aleatoria, está acción eliminara todos los códigos de barra ya asignados al ítem'
                      }
                      topLabel
                      onChange={() =>
                        setItem({ ...item, autoBarcode: !item.autoBarcode })
                      }
                    />
                  </Col>
                </Row>
              </Col>

              <Col lg={12} sm={12} style={{ marginTop: '10px' }}>
                <div className={`form-label left input-wrapper has-feedback mt-3 `}>
                  <label>
                    Variaciones &nbsp;
                    <label>
                      {' '}
                      <IconButton
                        tooltip={
                          'Si el item pertenece a un árbol de ítems, las variaciones se asignaran al ítem base'
                        }
                        icon={faInfoCircle}
                        size={'0.25x'}
                        placement={'right'}
                      />{' '}
                    </label>
                  </label>
                </div>
                <Button
                  disabled={disabled}
                  className={'align-bottom'}
                  onClick={() => {
                    setShowVariations(true)
                    dispatch(getCategorizationByType(20))
                  }}>
                  Agregar Variaciones
                </Button>
                <Col>
                  <ListVariations
                    show={showVariations}
                    onHide={() => setShowVariations(false)}
                    onSave={list => {
                      setShowVariations(false)
                      setListVariations(list)
                    }}
                    data={variations}
                    selected={listVariations}
                  />
                </Col>
              </Col>
              {!itemDecimals.useDecimals && (
                <>
                  <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                    {disabled ? (
                      <FormText
                        label={'ítem base'}
                        type={'text'}
                        placeholder={'ítem base'}
                        value={item.subProductData ? item.subProductData.name : null}
                        disabled={disabled}
                      />
                    ) : (
                      <Select
                        label={'ítem base'}
                        options={[
                          { label: '- Ninguno -', value: null },
                          ...products.filter(d => d.value !== Number(id)),
                        ]}
                        value={item.baseProduct}
                        onChange={val => {
                          setItem({ ...item, baseProduct: val })
                          setListVariations([])
                        }}
                        error={error.baseProduct}
                      />
                    )}
                  </Col>
                  <Col xl={4} lg={4} md={4} sm={6} xs={12} style={{ marginTop: 18 }}>
                    <NumberField
                      label={'Factor de Transformación'}
                      name={'conversionFactor'}
                      onValueChange={value =>
                        setItem({ ...item, conversionFactor: value })
                      }
                      value={item.conversionFactor || 0}
                      disabled={disabled}
                      error={error.conversionFactor}
                    />
                  </Col>
                </>
              )}

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <Switch
                  disabled={disabled}
                  id={'useFactor'}
                  name={'useFactor'}
                  checked={item.useFactor}
                  label={
                    'Al activar esta opción el sistema tomara el precio del item actual dividido el factor de conversión. El item de esta operación será el precio definido para el item hijo siempre que se venda como subitem.'
                  }
                  topLabel
                  onChange={onCheck}
                />
              </Col>

              {!isLimit && (
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Switch
                    disabled={disabled}
                    id={'teoricExistence'}
                    name={'teoricExistence'}
                    checked={item.teoricExistence}
                    label={
                      'Al activar la configuración el item mostrara la existencia teorica segun la existencia de los insumos que lo componen en su receta.'
                    }
                    topLabel
                    onChange={() =>
                      setItem({ ...item, teoricExistence: !item.teoricExistence })
                    }
                  />
                </Col>
              )}

              {configIva && (
                <>
                  <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                    <Switch
                      disabled={disabled}
                      id={'includeIva'}
                      name={'includeIva'}
                      checked={item.includeIva}
                      info={
                        'Permite decidir si el precio establecido se factura con IVA o no'
                      }
                      label={'Vender con IVA'}
                      topLabel
                      onChange={() => setItem({ ...item, includeIva: !item.includeIva })}
                    />
                    {!item.includeIva && (
                      <Select
                        label={'Frase'}
                        value={item.phraseReference}
                        options={phrases}
                        onChange={value => setItem({ ...item, phraseReference: value })}
                        info={'Selecciona la frase con la que no cobrará iva este item'}
                        error={error.phrase}
                      />
                    )}
                  </Col>
                </>
              )}

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <Switch
                  disabled={disabled}
                  id={'discountLimited'}
                  name={'discountLimited'}
                  checked={item.discountLimited}
                  label={
                    'Al activar esta opción el sistema tomará el valor del limite de descuento para validar el descuento dado en ordenes de venta.'
                  }
                  topLabel
                  onChange={() =>
                    setItem({ ...item, discountLimited: !item.discountLimited })
                  }
                />
              </Col>

              {item.discountLimited && (
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FormText
                    disabled={!item.price || disabled}
                    label={'Limite de descuento'}
                    info={
                      'Determina la cantidad maxima posible de descuento al realizar ordenes de venta.'
                    }
                    type={'number'}
                    id={'discountLimitedNumber'}
                    placeholder={'0.00'}
                    name={'discountLimitedNumber'}
                    value={item.discountLimitedNumber}
                    onChange={onChange}
                    error={error.discountLimitedNumber}
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <FormTextArea
                  label={'Descripción'}
                  value={item.description}
                  name={'description'}
                  id={'description'}
                  rows={'6'}
                  disabled={disabled}
                  onChange={onChange}
                />
              </Col>
            </Row>

            {!isLimit && (
              <Row>
                <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                  <label className={'checkbox-label'}>
                    <br />
                    <h6 style={{ color: '#3394CE' }}>Transformación automática: </h6>
                    <input
                      type={'checkbox'}
                      name={'transformation'}
                      checked={item.transformation}
                      disabled={disabled}
                      onChange={onCheck}
                    />{' '}
                    {item.transformation
                      ? 'El item se agrupará de forma automática durante una transferencia de mercadería.'
                      : 'El item no se agrupará de forma automática durante una transferencia de mercadería.'}
                  </label>
                </Col>

                {/*<Col md={6} sm={6} xs={12}>
                <label className={'checkbox-label'}>
                  <br/>
                  <h6 style={{color: '#3394CE'}}>A utlidad bruta:  </h6>
                  <input type={'checkbox'} name={'activeUtility'} checked={item.activeUtility} disabled={disabled} onChange={onCheck}/>
                  Si durante una venta el inventario vendido no posee un precio de compra, se tomará el precio de produccion indicado en el item con
                  el switch activo, para calcular la utilidad bruta
                </label>
              </Col>*/}

                <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                  <label className={'checkbox-label'}>
                    <br />
                    <h6 style={{ color: '#3394CE' }}>
                      Activar para no usar inventario en ventas
                    </h6>
                    <input
                      type={'checkbox'}
                      name={'withoutInventory'}
                      checked={item.withoutInventory}
                      disabled={disabled}
                      onChange={onCheck}
                    />
                    Los items con el switch activo no requeriran inventario durante una
                    venta y durante una producción
                  </label>
                </Col>

                <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                  <label className={'checkbox-label'}>
                    <br />
                    <h6 style={{ color: '#3394CE' }}>
                      Archivar item de forma automática{' '}
                    </h6>
                    <input
                      type={'checkbox'}
                      name={'archiveAutomatic'}
                      checked={item.archiveAutomatic}
                      disabled={disabled}
                      onChange={({ target }) => {
                        if (target.checked === true) {
                          setItem({ ...item, archiveAutomatic: target.checked })
                        } else {
                          setItem({
                            ...item,
                            archiveAutomatic: target.checked,
                            activeArchive: false,
                            archiveInDate: false,
                            archiveInInventory: false,
                          })
                        }
                      }}
                    />{' '}
                    {item.archiveAutomatic
                      ? 'El item se almacenara de forma automática segun las configuraciones seleccionadas.'
                      : 'El item no se archivara de forma automática.'}
                  </label>
                </Col>

                {item.archiveAutomatic && (
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <br />
                    <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                      <Switch
                        disabled={disabled}
                        id={'archiveInDate'}
                        name={'archiveInDate'}
                        checked={item.archiveInDate}
                        label={'Archivar item en una fecha definida'}
                        info={
                          'Al tener la configuración activa, el item sera archivado de forma automatica en la fecha indicada.'
                        }
                        topLabel={window.innerWidth < 480}
                        onChange={onCheck}
                      />

                      {item.archiveInDate && (
                        <CustomDate
                          disabled={disabled}
                          label={'Fecha para archivar el item:'}
                          value={item.archiveDate}
                          disabledDays={{ before: new Date() }}
                          onDayChange={value => setItem({ ...item, archiveDate: value })}
                        />
                      )}
                    </Col>

                    {!item.withoutInventory ? (
                      <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Switch
                          disabled={disabled}
                          id={'archiveInInventory'}
                          name={'archiveInInventory'}
                          checked={item.archiveInInventory}
                          label={'Archivar item al acabarse el inventario total'}
                          info={
                            'Al tener la configuración activa, el item sera archivado de forma automatica cuando ya no haya disponible inventario del item.'
                          }
                          topLabel={window.innerWidth < 480}
                          onChange={onCheck}
                        />
                      </Col>
                    ) : (
                      <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Switch
                          disabled={disabled}
                          id={'activeArchive'}
                          name={'activeArchive'}
                          checked={item.activeArchive}
                          label={'Archivar item despues de confirmar una orden de venta'}
                          info={
                            'Al tener la configuración activa, el item sera archivado de forma automatica al confirmar una orden de venta donde se involucre el item'
                          }
                          topLabel={window.innerWidth < 480}
                          onChange={onCheck}
                        />
                      </Col>
                    )}
                  </Col>
                )}

                {module === 2000 && (
                  <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                    <label className={'checkbox-label'}>
                      <br />
                      <h6 style={{ color: '#3394CE' }}>
                        Activar para enlace KOLO: (
                        {item.activeLink ? 'Activo' : 'Inactivo'})
                      </h6>
                      <input
                        type={'checkbox'}
                        name={'activeLink'}
                        checked={item.activeLink}
                        disabled={disabled}
                        onChange={onCheck}
                      />{' '}
                      Active la opción para que el item sea listado para enlaces KOLO.
                    </label>
                  </Col>
                )}
                <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                  <label className={'checkbox-label'}>
                    <br />
                    <h6 style={{ color: '#3394CE' }}>Activar para modulo de compras</h6>
                    <input
                      type={'checkbox'}
                      name={'activePurchase'}
                      checked={item.activePurchase}
                      disabled={disabled}
                      onChange={onCheck}
                    />{' '}
                    Active la opción para que el item sea listado para el modulo de
                    compras.
                  </label>
                </Col>
                <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                  <label className={'checkbox-label'}>
                    <br />
                    <h6 style={{ color: '#3394CE' }}>Activar para modulo de gastos</h6>
                    <input
                      type={'checkbox'}
                      name={'activeExpenses'}
                      checked={item.activeExpenses}
                      disabled={disabled}
                      onChange={({ target }) =>
                        setItem({ ...item, activeExpenses: target.checked })
                      }
                    />{' '}
                    Active la opción para que el item sea listado para el modulo de
                    gastos.
                  </label>
                </Col>
                <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                  <label className={'checkbox-label'}>
                    <br />
                    <h6 style={{ color: '#3394CE' }}>Activar para modulo de ventas</h6>
                    <input
                      type={'checkbox'}
                      name={'activeSell'}
                      checked={item.activeSell}
                      disabled={disabled}
                      onChange={onCheck}
                    />{' '}
                    Active la opción para que el item sea listado para el modulo de
                    ventas.
                  </label>
                </Col>
                <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                  <label className={'checkbox-label'}>
                    <br />
                    <h6 style={{ color: '#3394CE' }}>
                      Activar para modulo de Reducción de inventario
                    </h6>
                    <input
                      type={'checkbox'}
                      name={'activeWaste'}
                      checked={item.activeWaste}
                      disabled={disabled}
                      onChange={onCheck}
                    />{' '}
                    Active la opción para que el item sea listado para el modulo de
                    Reducción de inventario.
                  </label>
                </Col>
                <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                  <label className={'checkbox-label'}>
                    <br />
                    <h6 style={{ color: '#3394CE' }}>
                      Activar para modulo de Ingreso de inventario
                    </h6>
                    <input
                      type={'checkbox'}
                      name={'activeProduction'}
                      checked={item.activeProduction}
                      disabled={disabled}
                      onChange={onCheck}
                    />{' '}
                    Active la opción para que el item sea listado para el modulo de
                    Ingreso de inventario.
                  </label>
                </Col>
                <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                  <label className={'checkbox-label'}>
                    <br />
                    <h6 style={{ color: '#3394CE' }}>
                      Actualizar precio durante la venta
                    </h6>
                    <input
                      type={'checkbox'}
                      name={'activeUpdatable'}
                      checked={item.activeUpdatable}
                      disabled={disabled}
                      onChange={onCheck}
                    />{' '}
                    Permite al usuario actualizar el precio del item durante la creación
                    de la orden de venta
                  </label>
                </Col>
              </Row>
            )}

            {!modal && !disabled && renderButtons()}
          </Col>
        </Row>

        <Modal
          show={modalPrices.show}
          centered
          size={'md'}
          onHide={() => setModalP({ ...modalPrices, show: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Agregar precio de venta</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CustomCreate
              label={'Alias para el precio'}
              onChange={handleChange}
              onCreateOption={handleCreate}
              options={create.options}
              value={create.value}
              placeholder={'Buscar o seleccionar'}
              textLabel={'Nuevo alias: '}
              required
            />
            <MoneyField
              value={modalPrices.price}
              onValueChange={price => setModalP({ ...modalPrices, price })}
            />
          </Modal.Body>
          <Modal.Footer>
            <Row className={'container-buttons'}>
              <Button
                disabled={!modalPrices.price && !modalPrices.alias}
                icon={faSave}
                color={'primary'}
                onClick={() => {
                  let alias = modalPrices.alias
                    ? modalPrices.alias
                    : 'Precio ' + (priceDetail.length + 1)
                  let val = {
                    label: alias + ' - ' + toMoney(modalPrices.price),
                    value: modalPrices.price,
                    alias: alias,
                  }
                  if (priceDetail.length === 0) setDefault(val)
                  setDetails([...priceDetail, val])
                  setModalP({ ...modalPrices, show: false })
                }}>
                Guardar
              </Button>
            </Row>
          </Modal.Footer>
        </Modal>
        <Alert {...alert} />
      </div>
    )
  }

  const renderButtons = () => {
    return (
      <Row>
        <Col style={{ alignSelf: 'flex-end', textAlign: 'right' }}>
          <Button
            className={'align-bottom'}
            color={'primary'}
            onClick={save}
            loading={updateProductIsLoading}>
            <Icon icon={faSave} tooltip={'Guardar'} /> Guardar
          </Button>
        </Col>
      </Row>
    )
  }
  const getHeaders = () => {
    return [
      { label: '#', show: true, index: true, select: true, className: 'center mini' },
      {
        label: 'Transportista',
        show: true,
        value: ['companyName', 'name'],
        type: 'text',
        className: 'medium',
      },
      {
        label: 'Bodega',
        show: true,
        value: ['warehouseName', 'name'],
        type: 'text',
        className: 'medium',
      },
      {
        label: 'Existencia',
        show: true,
        value: ['productExistence', 'name'],
        type: 'text',
        className: 'mini right',
        addText: ' unidades',
      },
    ]
  }

  const onClose = () => {
    setItem({})
    onHide(updated)
    setDecimals({})
  }

  return modal ? (
    <Modal
      show={show}
      size={'xl'}
      centered
      aria-labelledby={'contained-modal-title-vcenter'}
      onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={'container'}>
          {loading && <ProgressBar now={100} animated />}
          {renderDetail()}
          {module === 2000 && item.companyId === currentCompanyId && (
            <Card
              white
              title={
                'Existencia en consignación: ' +
                existenceConsignated.reduce(
                  (accumulator, value) => accumulator + value.productExistence,
                  0,
                ) +
                ' unidades'
              }>
              <TableV2
                customClass={'scrollX'}
                headers={getHeaders()}
                storageKey={'detail'}
                items={existenceConsignated}
                noItemsLegend={'No se encontro unidades en consignacion'}
              />
            </Card>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!disabled && renderButtons()}
        {edit && (
          <Row>
            <Col style={{ alignSelf: 'flex-end', textAlign: 'right' }}>
              <Button
                className={'align-bottom'}
                onClick={() => {
                  history.push(`productos/detalles/${id}`)
                }}>
                <Icon icon={faBox} tooltip={'Ver Item'} /> Ver Item
              </Button>
              <Button
                className={'align-bottom'}
                onClick={() => {
                  setDisabled(!disabled)
                  setEdit(false)
                }}>
                <Icon icon={faEdit} tooltip={'Ediitar'} /> Editar
              </Button>
            </Col>
          </Row>
        )}
      </Modal.Footer>
    </Modal>
  ) : (
    <Card>
      {loading && <ProgressBar now={100} animated />}
      {renderDetail()}
    </Card>
  )
}

export default ProductDetail
