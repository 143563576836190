import { executeAction } from './global.actions'
export const actions = {
  SHOW_ALERT: 'SHOW_ALERT',
  HIDE_ALERT: 'HIDE_ALERT',
}

/**
 * @function Activa la alerta global
 * @param {string} title Titulo de la alerta
 * @param {string} text Descripción de la alerta
 * @param {string} type Tipo de la alerta: success | error | info
 * @param {function} onConfirm Acción a realizar cuando se presione el botón principal
 * @param {function} onCancel Acción a realizar cuando se presiona el botón auxiliar
 * @param {boolean} showCancelButton Indica si la alerta tendrá un botón auxiliar
 * @param {string} cancelButtonText Texto del botón auxiliar
 * @param {string} confirmButtonText Texto del botón principal
 * @param {string} confirmButtonColor Color Hexadecimal del botón principal
 * @param {string} inputPlaceholder Texto del placeholder de alertas de tipo input
 * */
export const showAlert =
  ({
    title,
    text,
    type,
    onConfirm,
    onCancel,
    showCancelButton,
    cancelButtonText,
    cancelButtonColor,
    showConfirmButton,
    confirmButtonText,
    confirmButtonColor,
    inputPlaceholder,
  }: IAlert) =>
  dispatch => {
    const process = () => ({
      payload: {
        title,
        text,
        type,
        onConfirm,
        onCancel,
        showCancelButton,
        cancelButtonText,
        cancelButtonColor,
        showConfirmButton,
        confirmButtonText,
        confirmButtonColor,
        inputPlaceholder,
      },
    })
    dispatch(executeAction(actions.SHOW_ALERT, process))
  }

/**
 * @function Oculta la alerta global
 * */
export const hideAlert = () => dispatch => {
  const process = () => undefined
  dispatch(executeAction(actions.HIDE_ALERT, process))
}
