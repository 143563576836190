import { $http } from './http'

class TaxesService {
  getAllTaxes = async () => {
    const response = await $http.get('/taxes')
    return response.data
  }

  onCreate = async request => {
    const response = await $http.post('/taxes', request)
    return response.data
  }

  onUpdate = async (id, request) => {
    const response = await $http.put(`/taxes/${id}`, request)
    return response.data
  }

  onDelete = async id => {
    const response = await $http.delete(`/taxes/${id}`)
    return response.data
  }
}

export default new TaxesService()
