import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import GoogleMapReact from 'google-map-react'
import Marker from './Marker'
import { MAPS_API_KEY } from 'src/settings/app.settings'
import './MapCard.scss'
import CollapsibleCard from '../cards/collapsible-card/CollapsibleCard'

export default class MapCard extends Component {
  getMap = () => {
    const {
      zoom,
      children,
      address,
      latitude,
      longitude,
      height,
      markerList,
      showMarker,
      onMarkerClick,
    } = this.props

    return (
      <div className={'column'}>
        {address && <Row className={'map-address'}>{address}</Row>}
        <div style={{ width: '100%', height: height || 335 }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: MAPS_API_KEY }}
            defaultCenter={{ lat: latitude, lng: longitude }}
            defaultZoom={zoom || 14}
            {...this.props}
          >
            {children ? (
              children
            ) : showMarker !== undefined && showMarker !== null && showMarker ? (
              markerList !== undefined && markerList !== null ? (
                markerList.map((item, index) => (
                  <Marker
                    key={`${index}`}
                    lat={Number(item.latitude)}
                    lng={Number(item.longitude)}
                    onClick={onMarkerClick}
                    text={item.text}
                    type={item.type || 0}
                  />
                ))
              ) : (
                <Marker lat={latitude} lng={longitude} />
              )
            ) : (
              ''
            )}
          </GoogleMapReact>
        </div>
      </div>
    )
  }

  render() {
    const { noCollapse, header, title, button, startCollapsed } = this.props

    let collapsed = true
    if (startCollapsed !== undefined) collapsed = startCollapsed

    return noCollapse ? (
      this.getMap()
    ) : (
      <CollapsibleCard
        header={header}
        title={title || 'Ubicación'}
        bodyClass={'map-container'}
        button={button}
        startCollapsed={collapsed}
      >
        {this.getMap()}
      </CollapsibleCard>
    )
  }
}
