import { CookieEnum, CookieExpirationHours } from 'src/enums/cookies'
import { $http } from '../../services/http'

interface TokenV2 {
  tokenSigned: string
}

interface CompanyV2 {
  id: number
  email: string
  logo: string | null
  name: string
  shortName: string
}

/**
 * Get token from /api/v1-login
 * @param token
 */
async function getToken(token) {
  const response = await fetch('/api/v1-login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token }),
  })
  if (!response.ok) {
    throw new Error('Failed to get token')
  }
  const data: TokenV2 = await response.json()
  return data
}

/**
 * Fetch companies for V2 Format
 * @returns
 */
const fetchCompaniesForV2 = async () => {
  const response = await $http.get('mobile/companies')
  return response.data
}

/**
 * Set cookie by name and value with expiration time in hours from CookieExpirationHours
 * @param name
 * @param value
 */
async function setCookie(name: CookieEnum, value: string) {
  const hoursToExpire = CookieExpirationHours[name]
  const expirationTime = new Date(
    Date.now() + hoursToExpire * 60 * 60 * 1000,
  ).toUTCString()

  if (name === CookieEnum.userToken) {
    document.cookie = `authToken=${value}; domain=.kolo-app.com; expires=${expirationTime}; path=/;  Secure; SameSite=Strict`
    const tokenV2 = await getToken(value)
    const signedToken = tokenV2?.tokenSigned
    document.cookie = `userToken=${signedToken}; domain=.kolo-app.com; expires=${expirationTime}; path=/;  Secure; SameSite=Strict`
    return
  } else if (name === CookieEnum.company) {
    document.cookie = `company=${value}; domain=.kolo-app.com; expires=${expirationTime}; path=/;  Secure; SameSite=Strict`
    const companiesV2Format: CompanyV2[] = await fetchCompaniesForV2()
    const companyObject = companiesV2Format?.find(company => company.id === Number(value))
    const currentCompany = JSON.stringify(companyObject)
    document.cookie = `currentCompany=${currentCompany}; domain=.kolo-app.com; expires=${expirationTime}; path=/;  Secure; SameSite=Strict`
  }
  document.cookie = `${name}=${value}; domain=.kolo-app.com; expires=${expirationTime}; path=/;   Secure; SameSite=Strict`
}

export { setCookie }
