import React, {Component} from 'react';
import {connect} from "react-redux";

import {Row, Col, Modal, ButtonGroup, DropdownButton, Dropdown} from "react-bootstrap";
import {Td, Tr} from "react-super-responsive-table";
import {
    faCheckCircle,
    faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import Alert from "sweetalert-react";

import CustomFilter from "../../../../components/cards/FilterCard";
import Card from "../../../../components/cards/Card";
import IconButton from "../../../../components/buttons/IconButton";
import Select from "../../../../components/inputs/Select/CustomSelect";
import OrderSummary from "../../../../components/Summaries/OrderSummary";
import { TableV2 } from '../../../../components';
import {
    formatDateFromMillis,
    formatNumberWithCommas
} from "../../../../utils/formatters";

import {actionTypes as typesO, getGOrders, getSingleExternalOrder, onConfirmOrder} from "../../../../actions/orders.actions";
import {selectGOrders, selectGetSingleOrder} from "../../../../selectors/orders.selector";

import {getWarehouses} from "../../../../actions/warehouse.actions";
import {selectAllWarehousesForSelect} from "../../../../selectors/warehouse.selector";

import {selectSetCurrentCompany} from "../../../../selectors/company.selector";

import {loadingSelector} from "../../../../selectors/loading.selector";
import {hasErrorsSelector, singleErrorSelector, handlerError, handlerSuccess} from "../../../../selectors/error.selector";
import { selectCurrentCurrency } from '../../../../selectors/currencies.selector';

class AllOrders extends Component {

    state = {
        filters: {},
        confirm: {value: false, order: {}, warehouse: {value: 0, label: '- ninguno -'}},
        modal: {show: false, item: {}},
        alert: {title: 'default'}
    };

    componentDidMount() {
        const {getWarehouses} = this.props;
        this.setUp();
        getWarehouses();
    };

    setUp = () => {
        const {company, getOrders} = this.props;
        if (company.clientId) getOrders(company.clientId);
    }

    componentWillReceiveProps(next) {
        const {loadingO, hasErrorO, handlerError, handlerSuccess} = this.props;
        const {errorO, order} = next;
        if (loadingO && !next.loadingC) {
            if (!hasErrorO && next.hasErrorO) {
                let alert = handlerError(errorO.message || 'Ha ocurrido un error inesperado');
                alert.onConfirm = () => this.setState({alert: {title: 'default'}});
                this.setState({alert});
            } else this.goToResume(order);
        }


        const {loadingC, hasErrorC} = this.props;
        const {errorC} = next;
        if (loadingC && !next.loadingC) {
            let {alert, confirm} = this.state;
            if (!hasErrorC && next.hasErrorC) {
                alert = handlerError(errorC.message || 'Ha ocurrido un error inesperado');
                alert.onConfirm = () => this.setState({alert: {...alert, show: false}});
            } else {
                alert = handlerSuccess(`La orden: ${confirm.order.number} ha sido confirmada satisfactoriamente`);
                alert.onConfirm = () => this.setState({alert: {...alert, show: false}});
                this.setUp();
                this.setState({confirm: {value: false, order: {}, warehouse: {value: 0, label: '- ninguno -'}}});
            }
            this.setState({alert});
        }
    };

    customFilter = (data) => {
        let filters = this.state.filters;
        if (JSON.stringify(filters) != JSON.stringify(data)) {
            this.setState({filters: data})
        }
    };

    filter = (item) => {
        const {filters} = this.state;
        const {entered, confirmed, delivered, unfulfilled, pending} = filters;
        return ((entered && item.status === 1) || (confirmed && item.status === 2) || (delivered && item.status === 3) || (unfulfilled && item.status === 4) || (pending && item.status === 5));
    };

    goToResume = (order) => {
        let item = Object.assign({}, order);
        item.details = item.details.map((p) => ({
            companyName: p.product.companyData ? p.product.companyData.name : '',
            name: p.product.name,
            quantity: p.quantity,
            discount: p.discount,
            subTotal: p.subtotal,
            total: p.total
        }));

        item.clientName = item.client.companyName
        item.typeName = item.orderTypeData.name;
        item.statusName = item.statusData.name;
        this.setState({modal: {show: true, item}});
    };

    goToConfirm = (item) => {
        this.setState({confirm: {value: true, order: item, warehouse: {value: 0, label: '- ninguno -'}}});
    };

    validate = () => {
        const {confirm} = this.state;
        const {order, warehouse} = confirm;
        this.props.onConfirmOrder(order.id, warehouse.value);
    }

    getHeaders = (currentCurrency) => {
        return [
            {label: 'Código', show: true, value: ['number'], type: 'text' ,  className:'mini'},
            {label: 'Transporte', show: true, value: ['companyData','name'], type: 'text' ,  className:'mini'},
            {label: 'Fecha de entrega', show: true, value: ['enabledAt'], type: 'date' ,  className:'mini'},
            {label: 'Estado', show: true, value: ['statusData','name'], type: 'text' ,  className:'mini'},
            {label: `Total (${currentCurrency.symbol})`, show: true, value: ['total'], type: 'currency' ,  className:'mini'},
            {config:true, show: true, label:'', className:'mini', custom:(item) =>  <ButtonGroup>
            <DropdownButton drop={'left'} as={ButtonGroup} title={''} id={'bg-nested-dropdown'}>
                <Dropdown.Item onClick={() => this.props.getOrder(item.id)} eventKey={'1'}>Resumen</Dropdown.Item>
                {item.status === 3 &&
                <Dropdown.Item onClick={() => this.goToConfirm(item)} eventKey={'2'}>Confirmar de recibido</Dropdown.Item>
                }
            </DropdownButton>
        </ButtonGroup>}

        ]
    }

    render() {
        const {orders, loading, warehouses, currentCurrency} = this.props;
        const {alert, modal, confirm} = this.state;
        const {show, item} = modal;
        const {value, order, warehouse} = confirm;

        return (
            <div>
                <Row>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                        <CustomFilter
                            title={'Filtros'}
                            module={'client'}
                            submodule={'orders'}
                            customFilter={this.customFilter}
                        />
                    </Col>
                    <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                        <Card title={'Ordenes de compra'}>
                            <TableV2
                                headers = {this.getHeaders(currentCurrency)}
                                items={orders.filter(this.filter)}
                                loading={loading}
                                storageKey = {`allOrdersClient`}
                                mobileAuto
                            />

                        </Card>
                    </Col>
                </Row>

                <Modal
                    show={show}
                    size={'lg'}
                    centered
                    onHide={() => this.setState({modal: {show: false, item: null}})}

          >          <Modal.Header>
                        <Modal.Title>Detalle de Orden</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <OrderSummary order={item}/>
                    </Modal.Body>

                    <Modal.Footer>

                        <Row className={'container-buttons'}>
                            <IconButton style={{marginRight: '15px'}} tooltip={'Cerrar'} icon={faTimesCircle} size={'2x'}
                                        onClick={() => this.setState({modal: {show: false, item: null}})}/>
                        </Row>
                    </Modal.Footer>
                </Modal>

        <Modal
          show={value}
          size={'md'}
          centered
          onHide={() =>
            this.setState({
              confirm: {
                value: false,
                order: {},
                warehouse: { value: 0, label: '- ninguno -' },
              },
            })
          }
        >
          <Modal.Header>
            <Modal.Title>Confirmar orden: {order.number}</Modal.Title>
          </Modal.Header>

                    <Modal.Body>
                        <div className={'column'}>
                            <span>Confirma que ya ha recibido su producto, también puede cargar el producto a una bodega.</span>
                            <br/>
                            <Select
                                value={warehouse}
                                options={[{value: 0, label: '- ninguno -'}, ...warehouses]}
                                label={'Bodega'}
                                onChange={(data) => this.setState({confirm: {...confirm, warehouse: data}})}
                            />
                        </div>
                    </Modal.Body>


                    <Modal.Footer>

                        <Row className={'container-buttons'}>
                            <IconButton style={{marginRight: '15px'}} tooltip={'Confirmar'} icon={faCheckCircle} size={'2x'}
                                        onClick={() => this.validate()}/>
                        </Row>
                    </Modal.Footer>
                </Modal>

                <Alert {...alert}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    orders: selectGOrders(state),
    warehouses: selectAllWarehousesForSelect(state),
    company: selectSetCurrentCompany(state),
    order: selectGetSingleOrder(state),
    loading: loadingSelector([typesO.GET_G_ORDERS])(state),
    hasError: hasErrorsSelector([typesO.GET_G_ORDERS])(state),
    error: singleErrorSelector([typesO.GET_G_ORDERS])(state),
    loadingO: loadingSelector([typesO.GET_SINGLE_ORDER])(state),
    hasErrorO: hasErrorsSelector([typesO.GET_SINGLE_ORDER])(state),
    errorO: singleErrorSelector([typesO.GET_SINGLE_ORDER])(state),
    loadingC: loadingSelector([typesO.CONFIRM_ORDER])(state),
    hasErrorC: hasErrorsSelector([typesO.CONFIRM_ORDER])(state),
    errorC: singleErrorSelector([typesO.CONFIRM_ORDER])(state),
    handlerError: (message) => handlerError(message),
    handlerSuccess: (message) => handlerSuccess(message),
    currentCurrency: selectCurrentCurrency(state)
});

const mapDispatchToProps = dispatch => ({
    getOrders: (id) => dispatch(getGOrders(id)),
    getWarehouses: () => dispatch(getWarehouses()),
    getOrder: (id) => dispatch(getSingleExternalOrder(id)),
    onConfirmOrder: (orderId, warehouseId) => dispatch(onConfirmOrder(orderId, warehouseId))
});

export default connect(mapStateToProps, mapDispatchToProps)(AllOrders);
