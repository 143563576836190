import React, {Component} from 'react';
import {connect} from "react-redux"
import {Col, Row} from "react-bootstrap";
import Card from "../../../components/cards/Card";
import {
    actionTypes,
    getBIPaymentsHistoryCurrentUser,
    getSaleOrdersBalanceCurrentUser
} from "../../../actions/payments.actions";
import {
    selectBIPaymentsHistoryCurrentUser,
    selectSaleOrderBalanceCurrentUser
} from "../../../selectors/payments.selector";
import {loadingSelector} from "../../../selectors/loading.selector";
import {Link} from "react-router-relative-link";
import { selectCurrentCurrency } from '../../../selectors/currencies.selector';
import { TableV2 } from '../../../components';


class Payments extends Component {

    state = {
        selectedAgreement: null,
        startDate: new Date(),
        endDate: new Date(),
        error: {},
        saleOrdersBalanceCurrentUser: 0,
        biPaymentsHistoryCurrentUser: [],
    };

    componentDidMount() {
        this.refreshBalanceAndHistory(null, null, null);
    }

    onDateFiltersChanged = (from, to)=>{
        this.setState({startDate: from, endDate: to});
        this.refreshBalanceAndHistory(null, from, to);
    };

    refreshBalanceAndHistory(biAgreementId, startDate, endDate)
    {
        if(biAgreementId == null) biAgreementId = this.state.selectedAgreement == null ? '' : this.state.selectedAgreement.value;

        if(startDate == null) startDate = this.state.startDate;
        if(endDate == null) endDate = this.state.endDate;

        this.props.getSaleOrdersBalanceCurrentUser();
        this.props.getBIPaymentsHistoryCurrentUser(biAgreementId, startDate.getTime(), endDate.getTime());
    }

   

    getHeaders = () => {
        return [
            {label: 'Código Empresa', show: true, value: ['companyData','alphanumericId'], type: 'text' ,  className:'mini'},
            {label: 'Empresa', show: true, value: ['companyData','name'], type: 'text' ,  className:'mini'},
            {label: 'Código Usuario', show: true, value: ['userData','alphanumericId'], type: 'text' ,  className:'mini'},
            {label: 'Usuario', show: true, value: ['userData','name'], type: 'text' ,  className:'mini'},
            {label: 'Convenio', show: true, value: ['agreement'], type: 'text' ,  className:'mini'},
            {label: 'Autorización Banco', show: true, value: ['bankAuthorization'], type: 'text' ,  className:'mini'},
            {label: 'Autorización Cotizap', show: true, value: ['providerAuthorization'], type: 'text' ,  className:'mini'},
            {label: 'Canal', show: true, value: ['channelName'], type: 'text' ,  className:'mini'},
            {label: 'Operación', show: true, value: ['operationName'], type: 'text' ,  className:'mini'},
            {label: 'Total Pago', show: true, value: ['paymentTotal'], type: 'currency' ,  className:'mini'},
            {label: 'Fecha', show: true, value: ['createdAt'], type: 'date' ,  className:'mini'},
            {config:true, show: true, label:'', className:'mini center'}

        ]
    }

    render() {
        const {startDate, endDate, error} = this.state;
        const {biPaymentsHistoryCurrentUser, saleOrdersBalanceCurrentUser, biPaymentsHistoryCurrentUserIsLoading, currentCurrency} = this.props;

        return (

            <div>
                <h1 className={'dashboard-welcome'}>Pagos Usuario</h1>
                <Row>
                    <Col md={3}>
                        <Card title={'Balance Órdenes de Venta'} bodyStyle={{padding: 10}}>
                            <Row>
                                <Col>
                                    <div className={'center'}>Q. {saleOrdersBalanceCurrentUser}</div>
                                </Col>
                            </Row>
                        </Card>
                        <Link to={'boletas'} className={'big-button'}>Registrar Boleta</Link>
                    </Col>
                    <Col md={9}>
                        <Card title={'Historial Banco Industrial'} bodyStyle={{padding: 10}}>
                            <TableV2
                                headers={this.getHeaders()}
                                items={biPaymentsHistoryCurrentUser}
                                loading={biPaymentsHistoryCurrentUserIsLoading}
                                dateFilter={true}
                                onDateChange={this.onDateFiltersChanged}
                                noItemsLegend={'No hay movimientos para mostrar'}
                                mobileAuto
                                storageKey={`paymentsDist`}
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    saleOrdersBalanceCurrentUser: selectSaleOrderBalanceCurrentUser(state),
    biPaymentsHistoryCurrentUser: selectBIPaymentsHistoryCurrentUser(state),
    biPaymentsHistoryCurrentUserIsLoading: loadingSelector([actionTypes.GET_BI_PAYMENTS_HISTORY_CURRENT_USER])(state),
    currentCurrency: selectCurrentCurrency(state)
});

const mapDispatchToProps = dispatch => ({
    getSaleOrdersBalanceCurrentUser: () => dispatch(getSaleOrdersBalanceCurrentUser()),
    getBIPaymentsHistoryCurrentUser: (agreementId, startDate, endDate) => dispatch(getBIPaymentsHistoryCurrentUser(agreementId, startDate, endDate))

});

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
