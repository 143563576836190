import { $http } from './http'

class VideosServices {
  getAllVideos = async params => {
    const response = await $http.get(`/videos`, { params })
    return response.data
  }

  getVideos = async params => {
    const response = await $http.get(`/videos/paginated`, { params })
    return response.data
  }

  saveNewVideo = async video => {
    const response = await $http.post(`/videos`, video)
    return response.data
  }

  editVideo = async (video, videoId) => {
    const response = await $http.put(`/videos/${videoId}`, video)
    return response.data
  }

  removeVideo = async videoId => {
    const response = await $http.delete(`/videos/${videoId}`)
    return response.data
  }

  removeUrlVideo = async urlId => {
    const response = await $http.delete(`/videos/url/${urlId}`)
    return response.data
  }

  getManuals = async () => {
    const response = await $http.get('/videos/manuals')
    return response.data
  }

  getAppVideos = async appId => {
    const response = await $http.get(`/videos/manuals/${appId}`)
    return response.data
  }
}

export default new VideosServices()
