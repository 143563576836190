import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Alert from 'sweetalert-react'
import { geolocated } from 'react-geolocated'

import Card from 'src/components/cards/Card'
import TextField from 'src/components/inputs/FormTextField/FormTextField'
import Button from 'src/components/buttons/Button'
import Geocoding from 'src/components/maps/Geocoding/Geocoding'

import { getPermission } from 'src/selectors/modules.selector'

import { selectSetCurrentCompany } from 'src/selectors/company.selector'

import { getPhoneCompanies, getBusinessTypes } from 'src/actions/utilities.actions'
import {
  selectPhonesCompanies,
  selectBusinessTypes,
} from 'src/selectors/utilities.selector'

import { getAllLocalities } from 'src/actions/address.actions'
import { selectAllLocalitiesForSelect } from 'src/selectors/address.selector'

import {
  actionTypes as typesC,
  onCreateGlobalClient,
  getGlobalClient,
  onUpdateGlobalClient,
  onRequestInfo,
  getRequestInfo,
} from 'src/actions/clients.actions'
import { selectGlobalClient } from 'src/selectors/clients.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  singleErrorSelector,
  hasErrorsSelector,
  handlerError,
  handlerSuccess,
} from 'src/selectors/error.selector'
import { selectCurrentModule } from 'src/selectors/user.selector'

class Profile extends Component {
  state = {
    method: 'GET',
    client: {},
    errors: {},
    alert: { title: 'default' },
  }

  componentDidMount () {
    const {
      company,
      getGlobalClient,
      getPhoneCompanies,
      getBusinessTypes,
      getAllLocalities,
      match,
      getRequest,
    } = this.props
    getPhoneCompanies()
    getBusinessTypes()
    getAllLocalities()
    if (match.params && match.params.id) {
      getRequest(match.params.id)
      getGlobalClient(match.params.id)
    } else if (company.clientId) getGlobalClient(company.clientId)
  }

  UNSAFE_componentWillReceiveProps (next) {
    const { loading, hasError, handlerError, handlerSuccess } = this.props
    const { error, global } = next

    if (loading && !next.loading) {
      let { alert, method, client } = this.state
      if (!hasError && next.hasError) {
        alert = handlerError(error.message || 'Ha ocurrido un error inesperado')
        alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
      } else {
        if (method !== 'GET') {
          alert = handlerSuccess('Información actualizada satisfactoriamente')
          alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
        } else {
          const { businessTypeData, phoneCompanyData, localityData } = global

          const businessType = businessTypeData
            ? { value: businessTypeData.id, label: businessTypeData.name }
            : null
          const phoneCompany = phoneCompanyData
            ? { value: phoneCompanyData.id, label: phoneCompanyData.name }
            : null
          const town = localityData
            ? { value: localityData.id, label: localityData.name }
            : null

          client = Object.assign(
            {},
            {
              ...global,
              businessType,
              phoneCompany,
              town,
            },
          )
        }
      }
      this.setState({ alert, client })
    }

    const { loadingR, hasErrorR } = this.props
    const { errorR } = next

    if (loadingR && !next.loadingR) {
      let alert = { title: 'title' }
      if (!hasErrorR && next.hasErrorR) {
        alert = handlerError(errorR.message || 'Ha ocurrido un error inesperado')
        alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
      } else {
        alert = handlerSuccess(
          'La solicitud de información ha sido enviada satisfactoriamente',
        )
        alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
      }
      this.setState({ alert })
    }
  }

  onChangeText = (event, parammeter) => {
    const { value } = event.target
    let { client } = this.state
    client[parammeter] = value
    this.setState({ client })
  }

  onChange = (data, parammeter) => {
    let { client } = this.state
    client[parammeter] = data
    this.setState({ client })
  }

  validate = () => {
    const { company, onCreateGlobalClient, onUpdateGlobalClient } = this.props
    const { client } = this.state
    let errors = {}

    if (!client.nit) errors.nit = 'Este campo es requerido'
    if (!client.companyName) errors.companyName = 'Este campo es requerido'
    if (!client.address) errors.address = 'Este campo es requerido'
    /*
        if (!client.town) errors.town = 'Este campo es requerido';
        if (!client.businessType) errors.businessType = 'Este campo es requerido';
        if (!client.phoneCompany) errors.phoneCompany = 'Este campo es requerido';
        */
    if (!client.phone) errors.phone = 'Este campo es requerido'
    if (!client.latitude) errors.latitude = 'Este campo es requerido'
    if (!client.longitude) errors.longitude = 'Este campo es requerido'

    if (Object.keys(errors).length === 0) {
      let request = Object.assign(
        {},
        {
          ...client,
          companyId: company.id,
          businessType: 14,
          phoneCompany: 1,
          town: 1,
        },
      )

      let alert = {
        show: true,
        type: 'warning',
        title: '¡Atención!',
        text: '¿Desea actualizar la información publica del punto de venta?',
        showCancelButton: true,
        confirmButtonText: 'Si, actualizar',
        confirmButtonColor: 'green',
        cancelButtonText: 'Cancelar',
        onCancel: () => this.setState({ alert: { title: 'default', show: false } }),
      }
      if (company.clientId) {
        this.setState({ method: 'PUT' })
        alert.onConfirm = () => {
          this.setState({ alert: { title: 'default', show: false } })
          onUpdateGlobalClient(request, company.clientId)
        }
      } else {
        this.setState({ method: 'POST' })
        alert.onConfirm = () => {
          this.setState({ alert: { title: 'default', show: false } })
          onCreateGlobalClient(request)
        }
      }

      this.setState({ alert })
    }
    this.setState({ errors })
  }

  getData = data => {
    let { client } = this.state
    const { towns } = this.props
    client.address = data.address
    client.latitude = data.latLng.lat
    client.longitude = data.latLng.lng

    if (data.town) {
      const town = towns.find(t => t.label === data.town)
      if (town) client.town = town
    }

    this.setState({ client })
  }

  render () {
    const { getPermission, loading, company, coords, modules, match } = this.props
    const { client, errors, alert } = this.state
    const { id, loadingR } = match.params

    const disabled = !getPermission(3052)
    let lat = 14.55
    let lng = -90.55
    if (coords) {
      lat = coords.latitude || 0
      lng = coords.longitude || 0
    }

    return (
      <div>
        <h1 className={'dashboard-welcome'}>Información de punto de venta.</h1>
        <br />
        <Row>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <Geocoding
              lat={client.latitude || lat}
              lng={client.longitude || lng}
              auto={!company.clientId}
              defaultAddress={client.address}
              editable={!disabled}
              getData={this.getData}
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <Card title={'Información publica'}>
              <form>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={3}>
                    <br />
                    <span>Código</span>
                  </Col>
                  <Col>
                    <TextField
                      placeholder={'pj. abc125TT'}
                      type={'text'}
                      value={client.code}
                      error={errors.code}
                      disabled={loading || disabled}
                      onChange={data => this.onChangeText(data, 'code', 'client')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={3}>
                    <br />
                    <span>NIT</span>
                  </Col>
                  <Col>
                    <TextField
                      placeholder={'pj. CF'}
                      type={'text'}
                      value={client.nit}
                      error={errors.nit}
                      disabled={loading || disabled}
                      onChange={data => this.onChangeText(data, 'nit', 'client')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={3}>
                    <br />
                    <span>Nombre</span>
                  </Col>
                  <Col>
                    <TextField
                      placeholder={'Nombre'}
                      type={'text'}
                      value={client.companyName}
                      error={errors.companyName}
                      disabled={loading || disabled}
                      onChange={data => this.onChangeText(data, 'companyName', 'client')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={3}>
                    <br />
                    <span>Dirección</span>
                  </Col>
                  <Col>
                    <TextField
                      placeholder={'Dirección'}
                      type={'text'}
                      value={client.address}
                      error={errors.address}
                      disabled={loading || disabled}
                      onChange={data => this.onChangeText(data, 'address', 'client')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={3}>
                    <br />
                    <span>Referencia</span>
                  </Col>
                  <Col>
                    <TextField
                      placeholder={'Referencia'}
                      type={'text'}
                      value={client.reference}
                      error={errors.reference}
                      disabled={loading || disabled}
                      onChange={data => this.onChangeText(data, 'reference', 'client')}
                    />
                  </Col>
                </Row>
                {/*
                                <Row>
                                    <Col xl={4} lg={4} md={4} sm={3} xl={3}>
                                        <br/>
                                        <span>Municipio</span>
                                    </Col>
                                    <Col>
                                        <Select
                                            value={client.town}
                                            withoutLabel
                                            placeholder={'Municipio'}
                                            options={towns}
                                            error={errors.town}
                                            disabled={loading || disabled}
                                            onChange={(data) => this.onChange(data, 'town', 'client')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={4} lg={4} md={4} sm={3} xl={3}>
                                        <br/>
                                        <span>Tipo de Negocio</span>
                                    </Col>
                                    <Col>
                                        <Select
                                            value={client.businessType}
                                            withoutLabel
                                            placeholder={'Tipo de negocio'}
                                            options={businessTypes}
                                            disabled={loading || disabled}
                                            error={errors.businessType}
                                            onChange={(data) => this.onChange(data, 'businessType', 'client')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={4} lg={4} md={4} sm={3} xl={3}>
                                        <br/>
                                        <span>Compañía Telefónica</span>
                                    </Col>
                                    <Col>
                                        <Select
                                            value={client.phoneCompany}
                                            withoutLabel
                                            placeholder={'Compañía Telefónica'}
                                            options={phonesCompanies}
                                            disabled={loading || disabled}
                                            error={errors.phoneCompany}
                                            onChange={(data) => this.onChange(data, 'phoneCompany', 'client')}
                                        />
                                    </Col>
                                </Row>
                                */}
                <Row>
                  <Col xl={4} lg={4} md={4} sm={3}>
                    <br />
                    <span>Teléfono</span>
                  </Col>
                  <Col>
                    <TextField
                      placeholder={'Teléfono'}
                      type={'number'}
                      value={client.phone}
                      error={errors.phone}
                      disabled={loading || disabled}
                      onChange={data => this.onChangeText(data, 'phone', 'client')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={3}>
                    <br />
                    <span>Ubicación</span>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <TextField
                          placeholder={'Latitude'}
                          type={'number'}
                          value={client.latitude}
                          error={errors.latitude}
                          disabled={loading || disabled}
                          onChange={data => this.onChangeText(data, 'latitude', 'client')}
                        />
                      </Col>
                      <Col>
                        <TextField
                          placeholder={'Longitude'}
                          type={'number'}
                          value={client.longitude}
                          error={errors.longitude}
                          disabled={loading || disabled}
                          onChange={data =>
                            this.onChangeText(data, 'longitude', 'client')
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </form>
            </Card>
          </Col>
        </Row>

        {!disabled && (
          <Row className={'container-buttons'}>
            <Button loading={loading} onClick={() => this.validate()}>
              <FontAwesomeIcon icon={faPlus} /> Actualizar
            </Button>
          </Row>
        )}

        {modules === 2000 && (
          <Row className={'container-buttons'}>
            <Button loading={loadingR} onClick={() => this.props.sendRequest([id])}>
              <FontAwesomeIcon icon={faPlus} /> Enviaar solicitud de información
            </Button>
          </Row>
        )}

        <Alert {...alert} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  getPermission: id => getPermission(state, id),
  company: selectSetCurrentCompany(state),
  phonesCompanies: selectPhonesCompanies(state),
  businessTypes: selectBusinessTypes(state),
  towns: selectAllLocalitiesForSelect(state),
  global: selectGlobalClient(state),
  loading: loadingSelector([typesC.GLOBAL_CLIENT])(state),
  hasError: hasErrorsSelector([typesC.GLOBAL_CLIENT])(state),
  error: singleErrorSelector([typesC.GLOBAL_CLIENT])(state),
  handlerError: message => handlerError(message),
  handlerSuccess: message => handlerSuccess(message),
  modules: selectCurrentModule(state),
  loadingR: loadingSelector([typesC.ON_INFO_REQUEST])(state),
  hasErrorR: hasErrorsSelector([typesC.ON_INFO_REQUEST])(state),
  errorR: singleErrorSelector([typesC.ON_INFO_REQUEST])(state),
})
const mapDispatchToProps = dispatch => ({
  getPhoneCompanies: () => dispatch(getPhoneCompanies()),
  getBusinessTypes: () => dispatch(getBusinessTypes()),
  getAllLocalities: () => dispatch(getAllLocalities()),
  onCreateGlobalClient: request => dispatch(onCreateGlobalClient(request)),
  getGlobalClient: id => dispatch(getGlobalClient(id)),
  onUpdateGlobalClient: (request, id) => dispatch(onUpdateGlobalClient(request, id)),
  sendRequest: clients => dispatch(onRequestInfo(clients)),
  getRequest: clients => dispatch(getRequestInfo(clients)),
})

const ReduxComponent = connect(mapStateToProps, mapDispatchToProps)(Profile)
export default geolocated({
  positionOptions: { enableHighAccuracy: false },
  userDecisionTimeout: 500,
})(ReduxComponent)
