import PolygonsService from '../services/polygons.service'
import { executeAction } from './global.actions'

export const actionTypes = {
  GET_ALL_POLYGONS: 'GET_ALL_POLYGONS',
  GET_SINGLE_POLYGON: 'GET_SINGLE_POLYGON',
  CREATE_POLYGON: 'CREATE_POLYGON',
  UPDATE_POLYGON: 'UPDATE_POLYGON',
  POLYGON_CATEGORIES: 'POLYGON_CATEGORIES',
  GET_SUBSCRIPTIONS_POLYGONS: 'GET_SUBSCRIPTIONS_POLYGONS',
  POLYGON_BY_CATEGORY: 'POLYGON_BY_CATEGORY',
  ON_UPDATE_SETTING: 'ON_UPDATE_SETTING',
  CHANGE_HIERARCHY: 'CHANGE_HIERARCHY',
  CHANGE_SHIPPING: 'CHANGE_SHIPPING',
}

export const getAllPolygons = () => dispatch => {
  const process = async () => ({
    polygons: await PolygonsService.getAllPolygons(),
  })
  dispatch(executeAction(actionTypes.GET_ALL_POLYGONS, process))
}

export const getSinglePolygon = id => dispatch => {
  const process = async () => ({
    polygon: await PolygonsService.getSinglePolygons(id),
  })
  dispatch(executeAction(actionTypes.GET_SINGLE_POLYGON, process))
}

export const createPolygon = request => dispatch => {
  const process = async () => ({
    polygon: await PolygonsService.createPolygon(request),
  })
  dispatch(executeAction(actionTypes.CREATE_POLYGON, process))
}

export const updatePolygon = (id, request) => dispatch => {
  const process = async () => ({
    polygon: await PolygonsService.updatePolygon(id, request),
  })
  dispatch(executeAction(actionTypes.UPDATE_POLYGON, process))
}

export const getPolygonCategories = () => dispatch => {
  const process = async () => ({
    categories: await PolygonsService.getPolygonCategories(),
  })
  dispatch(executeAction(actionTypes.POLYGON_CATEGORIES, process))
}

export const getSubscriptionsPolygons = () => dispatch => {
  const process = async () => ({
    subscriptionsPolygons: await PolygonsService.getSubscriptionsPolygons(),
  })
  dispatch(executeAction(actionTypes.GET_SUBSCRIPTIONS_POLYGONS, process))
}

export const getPolygonByCategory = categoryId => dispatch => {
  const process = async () => ({
    polygons: await PolygonsService.getPolygonByCategory(categoryId),
  })
  dispatch(executeAction(actionTypes.GET_ALL_POLYGONS, process))
}

export const onUpdatePolygonSetting = (polygonId, request) => dispatch => {
  const process = async () => ({
    settingResponse: await PolygonsService.onUpdatePolygonSetting(polygonId, request),
  })
  dispatch(executeAction(actionTypes.ON_UPDATE_SETTING, process))
}

export const changeHierarchy = polygons => dispatch => {
  const process = async () => ({
    response: await PolygonsService.changeHierarchy(polygons),
  })
  dispatch(executeAction(actionTypes.CHANGE_HIERARCHY, process))
}

export const changeShipping = request => async dispatch => {
  const process = async () => {
    const promises = []

    request.forEach(r => {
      promises.push(PolygonsService.changeShipping(r.polygonId, r.shipping))
    })

    return { response: await Promise.all(promises) }
  }
  dispatch(executeAction(actionTypes.CHANGE_SHIPPING, process))
}
