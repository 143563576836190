import { formatDateFromMillis, formatNumberWithCommas } from 'src/utils/formatters'
import { haveAnyValue } from 'src/utils/utilitiesV2'

const epson = window.epson

export const disconnect = (ePosDev, deviceObj) => {
  try {
    ePosDev?.deleteDevice(deviceObj, () => {
      ePosDev.disconnect()
    })
  } catch (error) {
    console.log(error)
  }
}

export const connect = (ipAddress, callback, onPrint) => {
  const ePosDev = new epson.ePOSDevice()
  // '8043'
  ePosDev.connect(ipAddress, '8043', result => {
    const options = { crypto: false, buffer: false }
    if (result === 'OK' || result === 'SSL_CONNECT_OK') {
      ePosDev.createDevice(
        'local_printer',
        ePosDev.DEVICE_TYPE_PRINTER,
        options,
        (deviceObj, errorCode) => {
          if (deviceObj === null) {
            callback({ ok: false, error: errorCode })
          } else {
            deviceObj.onreceive = function (response) {
              if (!response.success) {
                console.log('epson: printError:', response)
              } else console.log('epson: Impresión exitosa', response)
              onPrint(response)
            }
            deviceObj.onstatuschange = function (status) {
              console.log('epson: Estado de la impresora: ', status)
            }

            deviceObj.startMonitor()
            callback({ ok: true, printer: deviceObj, ePosDev: ePosDev })
          }
        },
      )
    } else {
      callback({ ok: false, error: result })
    }
  })
}

const toAsciiBytes = (value = '') => {
  const text = value
    .replace(/ñ/g, 'n')
    .replace(/á/g, 'a')
    .replace(/é/g, 'e')
    .replace(/í/g, 'i')
    .replace(/ó/g, 'o')
    .replace(/ú/g, 'u')
    .replace(/Ñ/g, 'Ñ')
    .replace(/Á/g, 'A')
    .replace(/É/g, 'E')
    .replace(/Í/g, 'I')
    .replace(/Ó/g, 'O')
    .replace(/Ú/g, 'U')
  return new TextEncoder().encode(text)
}

const concatTypedArrays = (a, b) => {
  const c = new a.constructor(a.length + b.length)
  c.set(a, 0)
  c.set(b, a.length)
  return c
}

export const getEscPosCommands = (data: IDataPrint[], size = 'sm', callback) => {
  const characterSize = size !== 'lg' ? 48 : 24

  let commands = new Uint8Array([0x1b, 0x74, 19])

  data?.forEach(({ type, value, otherValue, min, reverse }) => {
    if (type === 1) {
      // Saltos de linea
      commands = concatTypedArrays(commands, new Uint8Array([0x1b, 0x64, Number(value)]))
    } else if (type === 2) {
      // Alineación
      let alignValue: number
      switch (value) {
        case 2:
          alignValue = 0x01
          break
        case 3:
          alignValue = 0x02
          break
        default:
          alignValue = 0x00
          break
      }
      commands = concatTypedArrays(commands, new Uint8Array([0x1b, 0x61, alignValue]))
    } else if (type === 3) {
      // BOLD
      const boldByte = value ? 0x01 : 0x00
      commands = concatTypedArrays(commands, new Uint8Array([0x1b, 0x45, boldByte]))
    } else if (type === 4) {
      // Text size
      const widthSize = size !== 'lg' ? 0 : 1
      const heightSizeNormal = size === 'sm' ? 0 : 1
      const heightSizeTitle = size === 'sm' ? 1 : 2

      const heightSize = value === 2 ? heightSizeNormal : heightSizeTitle
      const n = (widthSize << 4) | heightSize

      commands = concatTypedArrays(commands, new Uint8Array([0x1d, 0x21, n]))
    } else if (type === 5) {
      // Add text
      commands = concatTypedArrays(
        commands,
        toAsciiBytes(getText(String(value), min, reverse)),
      )
    } else if (type === 6) {
      // Set date
      commands = concatTypedArrays(
        commands,
        toAsciiBytes(getDateValue(characterSize, value)),
      )
    } else if (type === 7) {
      // Divide line
      commands = concatTypedArrays(
        commands,
        toAsciiBytes(getDivideLine(characterSize, value)),
      )
    } else if (type === 11) {
      // space between
      commands = concatTypedArrays(
        commands,
        toAsciiBytes(printSpaceBetween(characterSize, value, otherValue)),
      )
    } else if (type == 12) {
      // Open Cash Drawer
      const connectorPin = value === 1 ? 0x00 : 0x01
      const pulseDuration = 0x32
      commands = concatTypedArrays(
        commands,
        new Uint8Array([0x1b, 0x70, connectorPin, pulseDuration, pulseDuration]),
      )
    }
  })

  const blob = new Blob([commands], { type: 'application/octet-stream' })
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  reader.onloadend = function () {
    callback(String(reader.result).split(',')[1])
  }
}

export const sendToPrint = (printer, data, size = 'sm') => {
  const characterSize = size !== 'lg' ? 48 : 24

  data?.forEach(({ type, value, otherValue, min, reverse }) => {
    if (type === 1) {
      // skype
      printer.addFeedLine(value)
    } else if (type === 2) {
      // text align
      let alignValue: number
      switch (value) {
        case 1:
          alignValue = printer.ALIGN_LEFT
          break
        case 2:
          alignValue = printer.ALIGN_CENTER
          break
        case 3:
          alignValue = printer.ALIGN_RIGHT
          break
        default:
          alignValue = 0
          break
      }
      printer.addTextAlign(alignValue)
    } else if (type === 3) {
      // bold or normal
      printer.addTextStyle(false, false, value, printer.COLOR_1)
    } else if (type === 4) {
      // text size
      const widthSize = size !== 'lg' ? 1 : 2
      const heightSizeNormal = size === 'sm' ? 1 : 2
      const heightSizeTitle = size === 'sm' ? 2 : 3
      switch (value) {
        case 1:
          printer.addTextSize(widthSize, heightSizeTitle) //title
          break
        case 2:
          printer.addTextSize(widthSize, heightSizeNormal) //normal
          break
        default:
          break
      }
    } else if (type === 5) {
      // add text
      printer.addText(getText(value, min, reverse))
    } else if (type === 6) {
      // set date
      const dateValue = getDateValue(characterSize, value)
      printer.addText(dateValue + '\n')
    } else if (type === 11) {
      // space between
      const newValue = printSpaceBetween(characterSize, value, otherValue)
      printer.addText(newValue + '\n')
    } else if (type === 7) {
      // divide line
      const divide = getDivideLine(characterSize, value)
      printer.addText(divide)
    } else if (type === 8) {
      printer.addCut(printer.CUT_FEED)
    } else if (type === 9) {
      const { context, width, height } = value
      printer.addTextAlign(printer.ALIGN_CENTER)
      printer.addImage(context, 0, 0, width, height, printer.COLOR_1, printer.MODE_GRAY16)
      printer.addTextAlign(printer.ALIGN_LEFT)
      printer.addFeedLine(2)
    } else if (type === 10) {
      value.split('\n').forEach(v => {
        const listOfString = []
        while (listOfString.length !== 50) {
          listOfString.push('')
        }
        const words = v.split(' ')

        let characters = characterSize
        let line = 0
        words.forEach(w => {
          const length = w.length + 1
          characters -= length

          if (characters >= 0) listOfString[line] += ' ' + w
          else {
            characters = characterSize
            line++
            listOfString[line] += w
          }
        })

        listOfString
          .filter(l => l !== '')
          .forEach(l => {
            printer.addText(l + '\n')
          })
      })
    } else if (type === 12) {
      printer.addPulse(
        value === 1 ? printer.DRAWER_1 : printer.DRAWER_2,
        printer.PULSE_200,
      )
    }
  })

  printer.addCut(printer.CUT_FEED)
  printer.send()
}

const getText = (value = '', min: number, reverse?: boolean) => {
  if (min && value.length < min) {
    while (value.length < min) {
      if (reverse) value = ' ' + value
      else value += ' '
    }
  }
  return value
}

const getDateValue = (characterSize, value) => {
  const today = formatDateFromMillis(value)
  const date = today.split(' ')[0]
  const hour = today.split(' ')[1]

  return printSpaceBetween(characterSize, date, hour)
}

const getDivideLine = (characterSize, value) => {
  let divide = ''
  if (value) {
    const r = (characterSize - value.length) / 2
    while (divide.length < r) divide += '-'
    const divide2 = divide
    divide += value
    divide += divide2
  } else {
    while (divide.length !== characterSize) divide += '-'
  }

  divide += '\n'

  return divide
}

const printSpaceBetween = (characterSize, value, otherValue) => {
  let thirdValue = null
  if (haveAnyValue(otherValue) && value.length >= characterSize) {
    thirdValue = value.substring(characterSize - 4)
    value = value.substring(0, characterSize - 4)
  }
  const maxWidth = characterSize - (otherValue ? otherValue.length : 0)
  while (value.length < maxWidth) value += ' '
  value += otherValue
  if (haveAnyValue(thirdValue)) {
    value += `\n ${thirdValue}`
  }
  return `${value}\n`
}

export const parseToItemsToTickets = (items: IItemsToPrint[]): IDataPrint[] => {
  // Headers
  const minQuantity = 6
  const minDesc = 26
  const minQuantityAndDesc = minQuantity + minDesc
  const minMoney = 8
  const data: IDataPrint[] = [
    { type: 2, value: 1 },
    { type: 3, value: true },
    { type: 5, value: getText('UNI', minQuantity) },
    { type: 5, value: getText('DESCRIPCIÓN', minDesc) },
    { type: 5, value: getText('PRECIO', minMoney, true) },
    { type: 5, value: getText('TOTAL', minMoney, true) },
    { type: 3, value: false },
  ]

  items?.forEach(item => {
    const description = `${getText(`${item.quantity}`, minQuantity)}${getText(
      item.name,
      minDesc,
    )}`
    data.push({ type: 5, value: description })

    if (description.length > minQuantityAndDesc) {
      const maxCharacterPerLine = 48
      const lastChunks = splitStringByLength(description, maxCharacterPerLine).pop()
        .length
      if (lastChunks > minQuantityAndDesc) {
        data.push({ type: 1, value: 1 })
        data.push({ type: 5, value: '', min: minQuantity + minDesc })
      } else {
        data.push({ type: 5, value: '', min: minQuantityAndDesc - lastChunks })
      }
    }

    const itemPrice = item.subtotal / item.quantity
    data.push({
      type: 5,
      value: getText(formatNumberWithCommas(itemPrice), minMoney, true),
    })
    data.push({
      type: 5,
      value: getText(formatNumberWithCommas(item.subtotal), minMoney, true),
    })
    data.push({ type: 1, value: 1 })
  })

  return data
}

const splitStringByLength = (inputString: string, chunkSize: number): string[] => {
  const chunks: string[] = []
  for (let i = 0; i < inputString.length; i += chunkSize) {
    chunks.push(inputString.substring(i, i + chunkSize))
  }
  return chunks
}
