import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'

import RecipeDetail from 'src/components/custom/RecipeDetail/RecipeDetail'

class CreateRecipes extends Component {
  render() {
    return (
      <div>
        <h1 className={'dashboard-welcome'}>Creación de Recetas</h1>
        <Row>
          <Col>
            <Row>
              <Col>
                <RecipeDetail seeEnumerations={false} create show mode disabled csv />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }
}

export default CreateRecipes
