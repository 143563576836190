import { toMoney } from 'src/utils/utilities'

export const options = {
  scales: {
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
          callback: function (label, index, labels) {
            return label > 999999
              ? label / 100000000 + 'M'
              : label > 999
              ? label / 1000 + 'k'
              : label
          },
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || ''
        let formatCurrency = amount => {
          if (amount === null) return ''
          return toMoney(amount)
        }

        if (label) {
          label += ': '
        }
        label += formatCurrency(tooltipItem.xLabel)
        return label
      },
    },
  },
  legend: {
    align: 'start',
  },
}
