import React, { useEffect, useState } from 'react'
import {
  Button,
  FormControl,
  InputGroup,
  ListGroup,
  Modal,
  OverlayTrigger,
  ProgressBar,
  Row,
  Spinner,
  Tooltip,
} from 'react-bootstrap'
import { FilePicker } from 'react-file-picker'
import { faTrash, faUpload } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { uploadVideo } from 'src/actions/uploads.actions'
import {
  actionTypes as actionTypesVideos,
  editVideo,
  removeUrlVideo,
  saveNewVideo,
} from 'src/actions/videos.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'
import Alert from 'sweetalert-react'
import {
  actionTypes as actionCat,
  assignCategorizationToEntity,
} from 'src/actions/categorization.actions'
import Folder, { categoryType } from 'src/components/folders/Folder'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'

const ModalVideo = ({ show, onHide, urlsFromWS, categories, onAssign, data, manual }) => {
  const dispatch = useDispatch()
  const categorization = useSelector(selectAllCategorizations)

  const removeUrlVideoIsLoading = useSelector(state =>
    loadingSelector([actionTypesVideos.REMOVE_URL_VIDEO])(state),
  )
  const removeUrlVideoHasError = useSelector(state =>
    hasErrorsSelector([actionTypesVideos.REMOVE_URL_VIDEO])(state),
  )
  const removeUrlVideoError = useSelector(state =>
    singleErrorSelector([actionTypesVideos.REMOVE_URL_VIDEO])(state),
  )

  const newVideoIsLoading = useSelector(state =>
    loadingSelector([actionTypesVideos.SAVE_NEW_VIDEO])(state),
  )
  const newVideoHasError = useSelector(state =>
    hasErrorsSelector([actionTypesVideos.SAVE_NEW_VIDEO])(state),
  )
  const newVideoError = useSelector(state =>
    singleErrorSelector([actionTypesVideos.SAVE_NEW_VIDEO])(state),
  )

  const editVideoIsLoading = useSelector(state =>
    loadingSelector([actionTypesVideos.EDIT_VIDEO])(state),
  )
  const editVideoHasError = useSelector(state =>
    hasErrorsSelector([actionTypesVideos.EDIT_VIDEO])(state),
  )
  const editVideoError = useSelector(state =>
    singleErrorSelector([actionTypesVideos.EDIT_VIDEO])(state),
  )

  const [action, setAction] = useState({
    post: false,
    edit: false,
    delete: false,
    assign: false,
  })
  const [video, setVideo] = useState({})
  const [uploadIsLoading, setUploadIsLoading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [showErrorVideo, setShowErrorVideo] = useState(false)
  const [urlDescription, setUrlDescription] = useState('')
  const [urlVideo, setUrlVideo] = useState('')
  const [urlsToSave, setUrlsToSave] = useState([])

  const [alert, setAlert] = useState({ show: false })
  const [selected, setSelected] = useState([])
  const [showCategories, setShowCategories] = useState(false)

  useEffect(() => {
    if (data && data.id)
      setVideo({
        title: data.title,
        description: data.description,
        id: data.id,
      })
  }, [data])

  useEffect(() => {
    if (categories && categories.length > 0) {
      /*if(onAssign)*/ setSelected(categories)
      //else setSelected(categories.map(c => {return {...c, name: c.label, id: c.value}}))
    }
  }, [categories])

  useEffect(() => {
    if (show) {
      setUrlsToSave(urlsFromWS)
    } else {
      setVideo({})
      setUrlsToSave([])
      setSelected([])
    }
  }, [show])

  useEffect(() => {
    if (newVideoIsLoading) setAction({ ...action, post: true })
    else if (action.post) {
      setAction({ ...action, post: false })
      if (newVideoHasError) {
        setAlert({
          ...handlerError(newVideoError.message || 'Error Inesperado'),
          onConfirm: () => setAlert({ show: false }),
        })
      } else
        setAlert({
          ...handlerSuccess('Video almacenado exitosamente'),
          onConfirm: () => {
            setAlert({ show: false })
            if (onHide) onHide()
          },
        })
    }
  }, [newVideoIsLoading])

  useEffect(() => {
    if (editVideoIsLoading) setAction({ ...action, edit: true })
    else if (action.edit) {
      setAction({ ...action, edit: false })
      if (editVideoHasError) {
        setAlert({
          ...handlerError(editVideoError.message || 'Error Inesperado'),
          onConfirm: () => {
            setAlert({ show: false })
            if (onHide) onHide()
          },
        })
      } else
        setAlert({
          ...handlerSuccess('Video editado exitosamente'),
          onConfirm: () => setAlert({ show: false }),
        })
    }
  }, [editVideoIsLoading])

  useEffect(() => {
    if (removeUrlVideoIsLoading) setAction({ ...action, delete: true })
    else if (action.delete) {
      setAction({ ...action, delete: false })
      if (removeUrlVideoHasError) {
        setAlert({
          ...handlerError(removeUrlVideoError.message || 'Error Inesperado'),
          onConfirm: () => setAlert({ show: false }),
        })
      } else
        setAlert({
          ...handlerSuccess('Url removida exitosamente'),
          onConfirm: () => setAlert({ show: false }),
        })
    }
  }, [removeUrlVideoIsLoading])

  const deleteVideo = (item, index) => {
    if (item.id) dispatch(removeUrlVideo(item.id))

    const newUrlsToSave = [...urlsToSave]
    newUrlsToSave.splice(index, 1)
    setUrlsToSave(newUrlsToSave)
  }

  const addVideo = () => {
    if (urlsToSave.length)
      return setAlert({
        ...handlerError('Solo se puede agregar un video'),
        onConfirm: () => setAlert({ show: false }),
      })

    if (urlVideo) {
      const isValidUrl = urlVideo.startsWith('http://') || urlVideo.startsWith('https://')
      const url = {
        url: isValidUrl ? urlVideo : `https://${urlVideo}`,
        description: urlDescription,
      }
      setUrlsToSave([...urlsToSave, url])
      setUrlVideo('')
      setUrlDescription('')
    }
  }

  const upload = file => {
    setUploadIsLoading(true)
    dispatch(uploadVideo(file, onProgress, onSuccess, onError))
  }

  const onProgress = progress => setUploadProgress(progress)

  const onError = error => {
    setUploadProgress(0)
    setShowErrorVideo(showErrorVideo)
  }

  const onSuccess = url => {
    setUploadIsLoading(false)
    setUploadProgress(0)
    setUrlVideo(url)
  }

  const saveVideo = () => {
    if (!video.title)
      setAlert({
        ...handlerError('Ingresa un título para el video'),
        onConfirm: () => setAlert({ show: false }),
      })

    if (urlsToSave.length === 0)
      setAlert({
        ...handlerError('Sube un video'),
        onConfirm: () => setAlert({ show: false }),
      })

    if (selected.length === 0)
      setAlert({
        ...handlerError('Selecciona al menos una categoría para el video'),
        onConfirm: () => setAlert({ show: false }),
      })

    if (video.id) {
      const newVideo = {
        title: video.title,
        description: video.description,
        urls: urlsToSave.filter(u => !u.id),
        categories: selected.map(c => c.id),
      }
      dispatch(editVideo(newVideo, video.id))
    } else {
      const newVideo = video
      newVideo.urls = urlsToSave.filter(u => !u.id)
      newVideo.categories = selected.map(c => c.id)
      if (onAssign) {
        onAssign(newVideo)
      } else {
        dispatch(saveNewVideo(newVideo))
      }
    }
  }

  const assignCategorization = categorization => {
    if (selected.some(s => s.id === categorization.id))
      return setAlert({
        ...handlerError('La categoría ya está asignada'),
        onConfirm: () => setAlert({ show: false }),
      })

    const newSelected = selected.concat(categorization)
    setSelected(newSelected)
    setAlert({
      ...handlerSuccess('Categoría asignada exitosamente'),
      onConfirm: () => setAlert({ show: false }),
    })
  }

  const renderCategorizations = () => {
    return (
      <div>
        {selected.length === 0 && (
          <div>
            <h6>Añadir mínimo una categoría</h6>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexFlow: 'wrap',
          }}
        >
          {selected.map((c, index) => (
            <div className="chip" key={index}>
              <div className="chip-head">{c.name && c.name.charAt(0)}</div>
              <div className="chip-content">{c.name}</div>
              <div
                className="chip-close"
                onClick={() => {
                  if (video.id) {
                    dispatch(
                      assignCategorizationToEntity(
                        null,
                        c.id,
                        video.id,
                        true,
                        null,
                        19,
                        manual,
                      ),
                    )
                  }
                  const newSelected = selected.filter(s => s.id !== c.id)
                  setSelected(newSelected)
                }}
              >
                <svg
                  className="chip-svg"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7
                                  15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"
                  />
                </svg>
              </div>
            </div>
          ))}
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="tooltip">Agregar Categorías</Tooltip>}
          >
            <div className="chip-close" onClick={() => setShowCategories(true)}>
              <svg
                className="chip-svg chip-add"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7
                                  15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"
                />
              </svg>
            </div>
          </OverlayTrigger>
        </div>
      </div>
    )
  }

  return (
    <>
      <Modal size={'xl'} centered show={show} onHide={() => onHide()}>
        <Modal.Header closeButton>
          <Modal.Title>Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderCategorizations()}
          <hr />
          <form>
            <InputGroup className="mb-3">
              <InputGroup.Text>Título</InputGroup.Text>
              <FormControl
                aria-label="V."
                aria-describedby="basic-addon1"
                value={video.title}
                maxlength="100"
                onChange={({ target }) => setVideo({ ...video, title: target.value })}
                required={true}
              />
            </InputGroup>

            <InputGroup>
              <InputGroup.Text>Descripción</InputGroup.Text>
              <FormControl
                as="textarea"
                aria-label="Description"
                rows={'6'}
                value={video.description}
                onChange={({ target }) =>
                  setVideo({ ...video, description: target.value })
                }
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <FilePicker
                maxSize={250}
                extensions={['mp4']}
                onChange={upload}
                onError={errMsg => {
                  setShowErrorVideo(true)
                }}
              >
                <div>
                  <Button disabled={uploadIsLoading}>
                    <FontAwesomeIcon icon={faUpload} />
                  </Button>
                  {uploadIsLoading ? (
                    <ProgressBar
                      variant="success"
                      style={{ marginTop: 5 }}
                      now={uploadProgress}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </FilePicker>
              <FormControl
                aria-label="V."
                aria-describedby="basic-addon1"
                placeholder={'URL'}
                value={urlVideo}
                onChange={({ target }) => setUrlVideo(target.value)}
                required={true}
              />
              <FormControl
                aria-describedby="basic-addon1"
                placeholder={'Descripción'}
                value={urlDescription}
                maxlength="500"
                onChange={({ target }) => setUrlDescription(target.value)}
                required={true}
              />
              {!uploadIsLoading && (
                <Button variant={'secondary'} onClick={() => addVideo()}>
                  Añadir
                </Button>
              )}
            </InputGroup>
            <ListGroup style={{ marginBottom: 10 }}>
              {urlsToSave.map((item, index) => (
                <Row style={{ alignItems: 'center', marginBottom: '6px' }}>
                  <Button
                    style={{ marginLeft: 20, marginRight: 10 }}
                    variant={'outline-secondary'}
                    size="sm"
                    onClick={() => deleteVideo(item, index)}
                  >
                    <FontAwesomeIcon className={'name'} icon={faTrash} />
                  </Button>
                  <div>
                    <h6>{item.url}</h6>
                    {item.description}
                  </div>
                </Row>
              ))}
            </ListGroup>

            <Button
              disabled={
                !video.title ||
                urlsToSave.length === 0 ||
                newVideoIsLoading ||
                selected.length === 0
              }
              onClick={() => saveVideo()}
            >
              {newVideoIsLoading ||
                (editVideoIsLoading && (
                  <Spinner
                    style={{ marginRight: 10 }}
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ))}
              <strong>
                {newVideoIsLoading ? 'Guardando' : !video.id ? 'Agregar' : 'Actualizar'}
              </strong>
            </Button>
          </form>
        </Modal.Body>
      </Modal>

      <Folder
        onHide={() => setShowCategories(false)}
        onAssign={item => {
          assignCategorization(item)
        }}
        data1={
          categorization && categorization.children ? categorization.children[0] : {}
        }
        data2={
          manual && categorization && categorization.children
            ? categorization.children[1]
            : {}
        }
        show={showCategories}
        noMessage
        companyId={manual}
        type={manual ? categoryType.MANUALES : categoryType.VIDEO}
      />

      <Alert {...alert} />
    </>
  )
}

export default ModalVideo
