import { faPlusCircle, faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { actionTypes } from 'src/actions/categorization.actions'
import { Button, Commentary, Icon } from 'src/components'
import { VariationComponent } from 'src/content/Production/Categorization/Variation/VariationComponent'
import { loadingSelector } from 'src/selectors/loading.selector'

export const ListVariations = ({
  show,
  onHide,
  onSave,
  data,
  selected,
  loading,
  onlySee,
  childName = undefined,
  modeSelection = undefined,
  hideMessage,
  onClick = undefined,
  hideVariations,
  groupName,
  isFilter,
  isLocations,
  border,
}) => {
  const [list, setList] = useState([])

  const loadingC = useSelector(state =>
    loadingSelector([actionTypes.GET_CATEGORIZATION_BY_TYPE])(state),
  )
  useEffect(() => {
    if (selected) {
      selected.forEach(x => {
        if (x.name === '' || x.name === undefined) {
          x.name = findName(x.id)
        }
      })
    }
  }, [selected])

  const findName = id => {
    let name = ''
    if (data) {
      data[childName || 'children'] &&
        data[childName || 'children'].forEach(element => {
          let val = element.children.find(x => x.id === parseInt(id))
          if (val) {
            name = val.name
            return
          }
        })
    }
    return name
  }

  const renderVariations = () => {
    return (
      <div className={'mt-1'}>
        <Row>
          {groupName && selected && selected.length > 0 && (
            <div style={{ marginRight: 5, marginTop: 5 }}>{groupName} :</div>
          )}
          {selected && selected.length > 0
            ? selected.map((a, i) => (
                <Commentary
                  key={i}
                  value={a.name}
                  style={{
                    marginRight: '5px',
                    borderRadius: '10px',
                    border: `1px  ${border ? (a.color ? a.color : ' solid ') : ''} `,
                  }}
                  onClick={() => {
                    if (onClick) onClick()
                  }}
                />
              ))
            : !hideMessage && (
                <Commentary
                  value={isLocations ? 'Sin ubicaciones' : 'No hay variaciones asignadas'}
                  style={{ marginRight: '5px', borderRadius: '10px 20px 10px 20px' }}
                  onClick={() => {
                    if (onClick) onClick()
                  }}
                />
              )}
          {onClick && (
            <Icon
              style={{ marginLeft: 5, marginTop: 5 }}
              icon={faPlusCircle}
              tooltip={'Agregar'}
              onClick={() => {
                onClick()
              }}
            />
          )}
        </Row>
      </div>
    )
  }

  return (
    <>
      {!hideVariations && renderVariations()}

      <Modal show={show} size={'md'} centered onHide={() => onHide()}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Variaciones</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              {onlySee && selected && selected.length === 0 && !loading ? (
                <h2> No hay variaciones asignadas para este producto</h2>
              ) : (
                <VariationComponent
                  data={data ? (data !== null ? data : undefined) : undefined}
                  loading={loadingC || loading}
                  onSave={list => setList(list)}
                  selected={selected}
                  onlySee={onlySee}
                  childName={childName || 'children'}
                  modeSelection={modeSelection}
                />
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            {!onlySee && (
              <>
                {' '}
                <Button
                  //  disabled={!modalPrices.price}
                  icon={faTimes}
                  color={'secondary'}
                  onClick={() => onSave([])}>
                  {isFilter ? 'Cancelar' : 'Sin variaciones'}
                </Button>
                <Button
                  //  disabled={!modalPrices.price}
                  icon={faSave}
                  color={'primary'}
                  onClick={() => onSave(list)}>
                  {isFilter ? 'Filtrar' : 'Guardar'}
                </Button>
              </>
            )}
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  )
}
