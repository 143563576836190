import { useCallback, useRef } from 'react'

function useDebounce(callback, delay) {
  const timeout = useRef()

  return useCallback(
    (...args) => {
      const later = () => {
        clearTimeout(timeout.current)
        return callback?.(...args)
      }

      clearTimeout(timeout.current)
      timeout.current = setTimeout(later, delay)
    },
    [callback, delay],
  )
}

export default useDebounce
