import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";

import {
    Row, Col, Modal,
    ToggleButtonGroup,
    ToggleButton
} from "react-bootstrap";
import {Td, Tr} from "react-super-responsive-table";
import {
    faArrowLeft,
    faCalendarDay,
    faCheckDouble,
    faCommentSlash,
    faCut,
    faSave,
    faTint, faWindowClose
} from "@fortawesome/free-solid-svg-icons";
import Alert from "sweetalert-react";

import {
    Icon, Card, FormText as Input,  Button, Select, MapCard, TableV2
} from "../../../components";

import {
    actionTypes as type,
    onSendRequestLink,
    getSingleLink,
    onChangeOfferStatus,
    onUpdateSetting,
    setChangeLink, onCancelOffer
} from "../../../actions/offers.actions";
import {selectLinkSingle, selectChange, selectLinkCategory} from "../../../selectors/offers.selector";

import {loadingSelector} from "../../../selectors/loading.selector";
import {handlerError, handlerSuccess, singleErrorSelector, hasErrorsSelector} from "../../../selectors/error.selector";

import {selectCurrentModule, selectCurrentCompany} from "../../../selectors/user.selector";
import {selectGetAccountsForSelect} from "../../../selectors/banks.selector";

import {types as typeI, onReturnInventoryByOfferZone} from "../../../actions/inventory.actions";

import {getCenter} from "../../../selectors/utilities.selector";
import {formatNumberWithCommas} from "../../../utils/formatters";

import ParcelCEV from "../../Parcel/component/ParcelCEV";
import {getAccounts} from "../../../actions/banks.actions";
import { toMoney } from '../../../utils/utilities';
import { selectCurrentCurrency } from '../../../selectors/currencies.selector';

const days = [
    {id: 1, title: 'Lunes'},
    {id: 2, title: 'Martes'},
    {id: 3, title: 'Miercoles'},
    {id: 4, title: 'Jueves'},
    {id: 5, title: 'Viernes'},
    {id: 6, title: 'Sabado'},
    {id: 7, title: 'Domingo'}
];

const ContractResume = (props) => {
    const dispatch = useDispatch();

    const link = useSelector(selectLinkSingle);
    const company = useSelector(selectCurrentCompany);
    const changeStatus = useSelector(selectChange);
    const accounts = useSelector(selectGetAccountsForSelect);
    // const module = useSelector(selectCurrentModule);
    const [module, setModule] = useState(1000);
    const currentCurrency = useSelector(selectCurrentCurrency);

    const loading = useSelector((state) => loadingSelector([type.ON_SEND_REQUEST_LINK])(state));
    const hasError = useSelector((state) => hasErrorsSelector([type.ON_SEND_REQUEST_LINK])(state));
    //const error = useSelector((state) => singleErrorSelector([type.ON_SEND_REQUEST_LINK])(state));

    //const loadingC = useSelector((state) => loadingSelector([type.CHANGE_STATUS_OFFER])(state));
    const hasErrorC = useSelector((state) => hasErrorsSelector([type.CHANGE_STATUS_OFFER])(state));
    const errorC = useSelector((state) => singleErrorSelector([type.CHANGE_STATUS_OFFER])(state));

    const cancel = useSelector((state) => state.offers.cancel);
    const cancelLoading = useSelector((state) => loadingSelector([type.ON_CANCEL_OFFER])(state));
    const hasErrorCl = useSelector((state) => hasErrorsSelector([type.ON_CANCEL_OFFER])(state));
    const errorCl = useSelector((state) => singleErrorSelector([type.ON_CANCEL_OFFER])(state));

    const loadingR = useSelector((state) => loadingSelector([typeI.ON_RETURN])(state));
    const hasErrorR = useSelector((state) => hasErrorsSelector([typeI.ON_RETURN])(state));
    const errorR = useSelector((state) => singleErrorSelector([typeI.ON_RETURN])(state));

    const linkTypes = useSelector(selectLinkCategory);

    const [google, setGoogle] = useState({map: null, maps: null});
    const [alert, setAlert] = useState({title: 'd'});
    const [action, setAction] = useState({send: false, get: false, change: false, cancel: false, returnI: false});
    const [id, setId] = useState(0);
    const [delivery, setDelivery] = useState({show: false, deliveryType: {}, deliveryDays: null});
    const [cev, setCev] = useState({type: 3, show: false, id: null});
    const [info, setInfo] = useState(null);
    const [showCancelError, setError] = useState(false);

    useEffect(() => {
        if (props.match && props.match.params && props.match.params.moduleId)
            setModule(Number(props.match.params.moduleId));

        if (props.resume) {
            if (link.info)
                setInfo(link.info.info);
            return;
        }
        const {id} = props.match.params;
        if (id) {
            dispatch(getSingleLink(id, 1));
            setAction({...action, get: true});
            setId(id);
        }
    }, []);

    useEffect(() => {
        if (!action.send) return;
        if (hasError) return;
        setAlert({
            ...handlerSuccess('Enlace solicitado satisfactoriamente'),
            onConfirm: () => {
                setAlert({...alert, show: false});
                let url = '/distribucion';
                url += `/ofertas/${module}/detalles/${link.offerId}`;
                props.history.replace(url);
            }
        });
    }, [link]);

    useEffect(() => {
        if (!action.get) return;
        let type = linkTypes.find((l) => l.value === link.type);
        if (!type) {
            type = linkTypes.filter((l) => l.kolo === props.isKolo)[0];
        }
        setInfo(type.info);

        setTimeout(() => {
            setAction({...action, get: false});
            if (!google.map) return;
            setPolygon(google.map, google.maps);
        }, 300);
    }, [link]);

    useEffect(() => {
        if (!action.change) return;
        if (hasErrorC) return;
        setAction({...action, change: false});
        setAlert({...handlerSuccess('Enlace actualizado'), onConfirm: () => setAlert({...alert, show: false})});
    }, [changeStatus]);

    useEffect(() => {
        if (!action.change) return;
        if (!hasErrorC) return;
        setAction({...action, change: false, send: false});
        setAlert({...handlerError(errorC.message), onConfirm: () => setAlert({...alert, show: false})});
    }, [errorC]);

    useEffect(() => {
        if (cancelLoading)
            setAction({...action, cancel: true});
        else if (action.cancel) {
            setAction({...action, cancel: false});
            if (hasErrorCl)
                setAlert({
                    ...handlerError(errorCl.message),
                    onConfirm: () => setAlert({...alert, show: false})
                });
            else {
                let a = cancel.ok ? handlerSuccess(cancel.message) : handlerError(cancel.message);
                setAlert({
                    ...a,
                    onConfirm: () => {
                        setAlert({...alert, show: false});
                        if (!cancel.ok)
                            setError(true);
                        else {
                            dispatch(getSingleLink(id, 1));
                            setAction({...action, get: true});
                        }
                    }
                })
            }
        }
    }, [cancelLoading]);

    useEffect(() => {
        if (loadingR)
            setAction({...action, returnI: true});
        else if (action.returnI) {
            setAction({...action, returnI: false});
            if (hasErrorR)
                setAlert({
                    ...handlerError(errorR.message),
                    onConfirm: () => setAlert({...alert, show: false})
                });
            else
                setAlert({
                    ...handlerSuccess('Solicitud de devolución de inventario enviados satisfactoriamente'),
                    onConfirm: () => {setAlert({...alert, show: false}); setError(false)}
                });
        }
    }, [loadingR]);

    const setPolygon = (map, maps) => {
        let points = link.points.sort((a, b) => (a.index - b.index))
            .map((p) => ({lat: Number(p.latitude), lng: Number(p.longitude)}));

        const polygon = new maps.Polygon({
            path: points,
            strokeColor: `#24ecff`,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: `#24ecff`,
            fillOpacity: 0.35
        });

        const center = getCenter(points);

        map.setZoom(10);
        map.panTo(center.latLng);
        polygon.setMap(map);
    };

    const onSetAlert = (title, text, type, cancel, action) => {
        setAlert({
            show: true,
            title, text, type,
            showCancelButton: cancel,
            onCancel: () => setAlert({...alert, show: false}),
            onConfirm: () => {
                setAlert({...alert, show: false});
                if (action)
                    action();
            }
        });
    };

    const onSetDelivery = (confirm) => {
        let deliveryType;
        let deliveryDays;
        if (link.setting.deliveryType === 1) {
            deliveryType = {value: 1, label: 'Tiempo en dias', description: 'Cuanto escoges tiempo en dias adquieres el compromiso en entregar la orden en el tiempo establecido.'};
            deliveryDays = link.setting.deliveryDays;
        } else {
            deliveryType = {value: 2, label: 'Dias de entrega', description: 'Las entregas se programaran para el proximo dia seleccionado.'};
            deliveryDays = link.setting.deliveryDays.split(',').map((d) => (parseInt(d)));
        }
        setDelivery({show: true, deliveryType, deliveryDays, accept: confirm});
    };

    const getActions = () => {
        return (<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>{!props.resume && <>
            {(link.status === 1 && ((link.byCompany !== company) || (link.byCompany === company && link.shipperId === company))) && <>
                <Icon style={{marginRight: 3}} onClick={() => onSetAlert('Aceptar solicitud de transporte', null, 'info', true, () => {
                    if (link.type !== 1 && link.type !== 4 && link.type !== 5) {
                        dispatch(onChangeOfferStatus(id, 2, null, module));
                        setAction({...action, change: true});
                    }
                    else
                        onSetDelivery(true);
                })} icon={faCheckDouble} size={'lg'} tooltip={`Aceptar solicitud`}/>
                <Icon style={{marginRight: 3}} onClick={() => onSetAlert('rechazar solicitud de transporte', null, 'warning', true, () => {
                    dispatch(onChangeOfferStatus(id, 3, null, module));
                    setAction({...action, change: true});
                })} icon={faWindowClose} size={'lg'} tooltip={'Rechazar solicitud'}/>
            </>}
            {(link.producerId === company || link.shipperId === company) && <>
                {link.status === 2 && <>
                    {(link.type !== 2 && link.type !== 3) &&
                    <Icon style={{marginRight: 3}}
                          onClick={() => onSetAlert('Editar dias de entrega', null, 'info', true,
                              () => onSetDelivery(false))}
                          icon={faCalendarDay} size={'lg'}
                          tooltip={'Editar dias de entrega'}/>}

                    <Icon style={{marginRight: 3}}
                          onClick={() => onSetAlert('Desactivar enlace', null, 'warning', true, () => {
                              dispatch(onChangeOfferStatus(id, 4, null, module));
                              setAction({...action, change: true});
                          })} icon={faCommentSlash} size={'lg'} tooltip={'Desactivar enlace'}/>
                </>}
                {link.status === 4 && <>
                    <Icon style={{marginRight: 3}}
                        onClick={() => onSetAlert('Re activar enlace', null, 'warning', true, () => {
                        dispatch(onChangeOfferStatus(id, 6, null, module));
                        setAction({...action, change: true});
                    })} icon={faTint} size={'lg'} tooltip={'Re activar enlace'}
                />
                <Icon style={{marginRight: 3}}
                    onClick={() => onSetAlert('Cancelar enlace', null, 'warning', true, () => {
                        //dispatch(onChangeOfferStatus(id, 5));
                        dispatch(onCancelOffer(id, module));
                        // setAction({...action, change: true});
                    })} icon={faCut} size={'lg'} tooltip={'Cancelar enlace'}
                />
                </>}
            </>}
        </>}</div>);
    };

    const getDays = () => {
        let response = '';
        const {setting} = link;
        if (!setting) return response;

        const {deliveryType, deliveryDays} = setting;
        if (deliveryType === 1)
            response = deliveryDays + ' dias';
        else if (deliveryDays) {
            deliveryDays.split(',').map((key) => {
                const dayS = days[parseInt(key) - 1];
                if (dayS)
                    response += `${dayS.title}, `;
            });
        }

        return `Cada ${response} despues de la orden`;
    };

    const getHeader = () => {
        const header = ['Código', 'Producto'];
        if (link.type === 2)
            header.push(`PAgo (${currentCurrency.symbol})`);

        return header;
    };

    const getHeaders= () => {
        const header = [
            {label: 'Código', show: true, value: ['productCode'], type: 'text' ,  className:'mini'},
            {label: 'Producto', show: true, value: ['productName'], type: 'text' ,  className:'mini'}
        ]
        if(link.type === 2) header.push({label: `Pago (${currentCurrency.symbol})`, show: true, value: ['amount'], type: 'currency' ,  className:'mini'})
        header.push({config:true, show: true, label:'', className:'mini'})

        return header
    }

    const getAccount = (bankAccountId) => {
        let label = 'Sin seleccionar';
        if (bankAccountId) {
            const account = accounts.find((a) => a.id === bankAccountId);
            if (account)
                label = account.accountNumber + ' - ' + account.name;
        }
        return {label, value: bankAccountId};
    };

    return (<div className={'column'}>
        <Row>
            {!props.resume && <Col xl={12} lg={12} md={12}>
                <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}><Card>
                        <div className={'justify-center column'}>
                            <span>Transportista</span>
                            <h4>{link.shipperName ? link.shipperName : 'Desconocido'}</h4>
                        </div>
                    </Card></Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}><Card>
                        <div className={'justify-center column'}>
                            <span>Productor</span>
                            <h4>{link.producerName ? link.producerName : 'Desconocido'}</h4>
                        </div>
                    </Card></Col>
                </Row>
            </Col>}
            <Col xl={5} lg={5} md={6} sm={12} xs={12}><div className={'column'}>
                <Card><div className={'column'}>
                    {props.resume &&
                    <div className={'justify-center column'}>
                        <span>Transportista</span>
                        <h4>{link.shipperName ? link.shipperName : 'Desconocido'}</h4>
                    </div>}

                    {(link && link.type && (link.type === 1 || link.type === 4 || link.type === 5)) && <>
                        <Row>
                            <Col className={'left'}><b>Pago por porcentaje</b></Col>
                            <Col className={'description'}>{link.setting ? `%. ${formatNumberWithCommas(link.setting.paymentPerPercentage)}` : 'Desconocido'}</Col>
                        </Row>

                        <Row>
                            <Col className={'left'}><b>Días de entrega</b></Col>
                            <Col className={'description'}>{getDays()}</Col>
                        </Row>
                    </>}

                    {(link && link.type && link.type === 2) && <Row>
                        <Col className={'left'}><b>Pago por factura</b></Col>
                        <Col className={'description'}>{link.setting ? toMoney(link.setting.paymentPerInvoice) : 'Desconocido'}</Col>
                    </Row>}

                    {(link && link.type && link.type === 3) && <Row>
                        <Col className={'left'}><b>Pago por Cajilla</b></Col>
                        <Col className={'description'}>{link.setting ? toMoney(link.setting.paymentStandard) : 'Desconocido'}</Col>
                    </Row>}

                    {module === 2000 && <Select
                        disabled={!props.resume}
                        info={'Cuando se soliciten desembolsos desde KOLO Vitrina, los desembolsos serán dirigidos a la cuenta bancaria seleccionada a continuación'}
                        label={'Cuenta bancaria por defecto para desembolsos'}
                        value={getAccount(link.bankAccountId)}
                        options={accounts}
                        onChange={(ac) => dispatch(setChangeLink({...link, bankAccountId: ac.value}))}
                    />}
                </div></Card>
                <Card>
                    <div className={'justify-center column'}>
                        {(info) ? <div className={'column'}>
                            <h4>{info.title}</h4>
                            <p>{info.tDesc}</p>
                            <h5>{info.subTitle}</h5>
                            <p>{info.sDesc}</p>
                        </div> : ''}
                    </div>
                </Card>

                <MapCard
                    title={link.polygonName}
                    latitude={14.55}
                    longitude={-90.55}
                    height={450}
                    startCollapsed={false}
                    markerList={[]}
                    zoom={7}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({map, maps}) => {
                        if (props.resume) {
                            setTimeout(() => {
                                setPolygon(map, maps);
                            }, 300);
                        } else setGoogle({map, maps});
                    }}
                />
            </div></Col>
            <Col xl={7} lg={7} md={6} sm={12} xs={12}>
                <Card title={'Productos dentro del enlace'} button={getActions()}>
                    <TableV2
                        headers={getHeaders()}
                        items={link.products}
                        storageKey={`contratR2`}
                        pSize={0}
                        mobileAuto = {true}
                    />
                  </Card>
            </Col>
        </Row>

        {props.resume && <Row className={'container-buttons'}>
            <Button disabled={loading} left icon={faArrowLeft} onClick={() => props.jumpToStep(1)}>retroceder</Button>
            <Button loading={loading} variant={'info'} right icon={faSave} onClick={() => {
                let text;
                if (props.zoneType === 1)
                    text = {title: 'Actualización en el enlace', info: '¿Desea hacer cambios al enlace de transporte?'};
                else
                    text = {title: 'Creación de enlace', info: '¿Desea crear el enlace de transporte?'};

                setAlert({
                    show: true,
                    title: text.title,
                    text: text.info,
                    type: 'info',
                    showCancelButton: true,
                    onCancel: () => setAlert({...alert, show: false}),
                    onConfirm: () => {
                        setAlert({...alert, show: false});
                        const request = {
                            bankAccountId: link.bankAccountId,
                            polygon: link.polygonId,
                            setting: link.setting,
                            offerId: link.offerId,
                            products: link.products.map((p) => {
                                return {
                                    productId: p.productId,
                                    amount: p.amount
                                }
                            }),
                            shipperId: link.shipperId,
                            category: link.type
                        };
                        setAction({...action, send: true});
                        dispatch(onSendRequestLink(request, props.zoneType, module));
                    }
                });
            }}>Guardar</Button>
        </Row>}

        <Modal
            show={delivery.show}
            size={'lg'}
            centered
            onHide={() => setDelivery({...delivery, show: false})}>

            <Modal.Header><Modal.Title>Es necesario indicar los tiempos de entrega</Modal.Title></Modal.Header>

            <Modal.Body>
                <Row>
                    <Col md={6}><Select
                        label={'Tipo de entrega'}
                        options={
                            [{
                                value: 1,
                                label: 'Tiempo en dias',
                                description: 'Cuanto escoges tiempo en dias adquieres el compromiso en entregar la orden en el tiempo establecido.'
                            }, {
                                value: 2,
                                label: 'Dias de entrega',
                                description: 'Las entregas se programaran para el proximo dia seleccionado.'
                            }]
                        }
                        value={delivery.deliveryType}
                        onChange={(type) => {
                            let deliveryDays;
                            if (type.value === 1)
                                deliveryDays = '1';
                            else deliveryDays = [];
                            setDelivery({...delivery, deliveryType: type, deliveryDays})
                        }}
                        required
                    /></Col>
                    <Col md={6}>
                        <br/>
                        <br/>
                        <span><strong>{delivery.deliveryType.value ? delivery.deliveryType.description : 'Es necesario espesificar el tipo de entrega para determinar a los clientes que dia sera entregado su producto'}</strong></span>
                    </Col>
                </Row>
                {delivery.deliveryType.value && <Row>
                    {delivery.deliveryType.value === 2 && <Col>
                        <div>
                            <h6>Días de entrega</h6>
                            <ToggleButtonGroup type="checkbox" defaultValue={delivery.deliveryDays} onChange={(deliveryDays) => {
                                setDelivery({...delivery, deliveryDays});
                                console.log(deliveryDays)
                            }}>{
                                days.map((item, index) =>
                                    <ToggleButton value={item.id} key={index}>{item.title}</ToggleButton>
                                )
                            }
                            </ToggleButtonGroup>
                        </div>
                    </Col>}
                    {delivery.deliveryType.value === 1 && <Col md={6}>
                        <Input
                            label={'Tiempo en dias despues del pedido'}
                            prepend={'Dias'}
                            type={'number'}
                            value={delivery.deliveryDays}
                            onChange={({target}) => {
                                const {value} = target;
                                delivery.deliveryDays = value;
                                setDelivery({...delivery});
                            }}
                            required
                        />
                    </Col>}
                </Row>}
            </Modal.Body>
            <Modal.Footer><Row className={'container-buttons'}>
                <Button
                    variant={'info'}
                    icon={delivery.accept ? faCheckDouble : faCalendarDay}
                    disabled={!delivery.deliveryType.value || (delivery.deliveryType.value === 1 && !delivery.deliveryDays) || (delivery.deliveryType.value === 2 && (!delivery.deliveryDays || delivery.deliveryDays.length <= 0))}
                    right
                    onClick={() => {
                        let deliveryDays = '';
                        if (delivery.deliveryType.value === 2)
                            delivery.deliveryDays.map((d, index) => {
                                let space = ',';
                                if (delivery.deliveryDays.length === (index+1))
                                    space = '';
                                deliveryDays += (d + space) ;
                            });
                        else deliveryDays = delivery.deliveryDays;

                        const query = {
                            ...link.setting,
                            deliveryType: delivery.deliveryType.value,
                            deliveryDays: deliveryDays,
                            deliveryAmount: 0
                        };

                        onSetAlert(
                            delivery.accept ? 'Aceptar solicitud' : 'Actualizar entregas',
                            null,
                            'warning',
                            true,
                            () => {
                                if (delivery.accept) {
                                    dispatch(onChangeOfferStatus(id, 2, query, module));
                                    setAction({...action, send: true});
                                }
                                else
                                    dispatch(onUpdateSetting(id, query));
                                setDelivery({...delivery, show: false});
                            }
                        );
                    }}
                >{delivery.accept ? 'Aceptar solicitud' : 'Actualizar entregas'}</Button>
            </Row></Modal.Footer>
        </Modal>

        <Modal show={showCancelError} centered size={'lg'} onHide={() => setError(false)}>
            <Modal.Header closeButton><Modal.Title>{cancel.message}</Modal.Title></Modal.Header>
            <Modal.Body>
                <TableV2 
                    headers={[
                        {label:'Producto', show:true, className: 'medium', value:['column'], type:'text'}, 
                        {label:'Bodega', show:true, className: 'medium', value:['error'], type:'text'}, 
                        {label:'Cantidad', show:true, className: 'mini', value:['message'], type:'number'},
                        {config:true, show: true, label:'', className:'mini'}]}
                    items={cancel.errors}
                    itemsMobile={true}
                    storageKey={`contractR-1`}
                />
                </Modal.Body>
            <Modal.Footer><Row className="container-buttons">
                <Button
                    loading={loadingR}
                    color={'primary'}
                    onClick={() => {
                        dispatch(onReturnInventoryByOfferZone(id));
                    }}
                >Solicitar devoluciónn</Button>
            </Row></Modal.Footer>
        </Modal>

        <ParcelCEV {...cev} onHide={() => setCev({...cev, show: false})}/>

        <Alert {...alert}/>
    </div>);
};
export default ContractResume;
