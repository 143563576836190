import './FilterByCategory.scss'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Col, Row } from 'react-bootstrap'
import { Folder, Icon } from '../../index'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { actionTypes, onSetLocalCategories } from 'src/actions/categorization.actions'
import {
  selectAllCategorizations,
  selectCategorizationAssignedForSelect,
  selectCategorizationEntity,
} from 'src/selectors/categorizations.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const FilterByCategory = ({ toggle, get, initial, list, posCategories }) => {
  const dispatch = useDispatch()

  const categories = useSelector(selectCategorizationEntity)
  const categorization = useSelector(selectAllCategorizations)
  const selected = useSelector(selectCategorizationAssignedForSelect)
  const loading = useSelector(state => loadingSelector([actionTypes.GET_ALL])(state))

  const [show, setShow] = useState(false)

  useEffect(() => {
    if (!initial) return

    if (list) dispatch(onSetLocalCategories(list))
    else dispatch(onSetLocalCategories([]))
  }, [initial])

  const getInit = () => {
    if (categorization && categorization.children && categorization.children.length > 0) {
      return categorization.children[1]
      /*if (privates.children && privates.children.length > 0) {
                return privates.children[0];
            }*/
    }
    return {}
  }
  const getCategories = () => {
    const list = getInit().children
    if (list) {
      /*let array = [];
            list.map((i) => {array = [...array, ...getChildren(i, array)]});*/
      const firstBranch = []
      const cl = posCategories && posCategories.length > 0 ? posCategories : list

      cl.map(l => {
        const find = selected.find(s => s.value === l.id)
        const a = { ...l, assigned: find !== undefined }

        const secondBranch = []
        if (a.children) {
          a.children.map(c => {
            const findC = selected.find(s => s.value === c.id)
            secondBranch.push({
              ...c,
              assigned: findC !== undefined,
              child: true,
            })
          })
        }
        firstBranch.push({ ...a })
        firstBranch.push(...secondBranch)
      })
      return firstBranch
    } else return []
  }

  const getChildren = (categorization, list) => {
    if (categorization) {
      list.push(categorization)
      let { children } = categorization
      if (children) {
        children.map(cat => {
          list = [...getChildren(cat, list)]
        })
      }
    }
    return list
  }

  const addOrRemove = item => {
    const index = categories.find(pc => (pc.value || pc.id) === (item.id || item.value))
    let cpc = Object.assign([], toggle ? [] : categories)
    if (!index) {
      cpc.push(item)
    } else {
      cpc = categories.filter(pc => (pc.value || pc.id) !== (item.id || item.value))
    }
    dispatch(onSetLocalCategories(cpc))
    if (get) get(cpc)
  }

  return (
    <div>
      <Row>
        {/*<Col xl={12} md={12}><Button color={'accent'} onClick={() => {
                setShow(true);
            }}><Icon icon={faPlus} tooltip={'Filtrar por categorías'}/></Button>Filtrar por categorías</Col>*/}
        <Col xl={12} md={12}>
          <Row className={'pl-1'}>
            {getCategories() &&
              getCategories().map(c => (
                <div
                  onClick={() => addOrRemove(c)}
                  key={c.id}
                  style={
                    c.assigned ? { borderColor: '#226095' } : { borderColor: c.color }
                  }
                  className={`c-item ${c.child ? 'child' : 'father'} ${
                    c.assigned ? 'assigned' : 'removed'
                  } ml-2`}>
                  <label className={`c-item-tag ${c.child ? 'child' : 'father'}`}>
                    {c.name}
                  </label>
                  <Icon
                    className={`c-item-button ${c.child ? 'child' : 'father'} ${
                      c.assigned ? 'assigned' : 'removed'
                    }`}
                    style={
                      c.assigned
                        ? { backgroundColor: '#226095' }
                        : { backgroundColor: c.color }
                    }
                    icon={c.assigned ? faTimes : faPlus}
                    tooltip={c.assigned ? 'Remover' : 'Añadir'}
                    color={'white'}
                    onClick={() => {}}
                  />
                </div>
              ))}
          </Row>
        </Col>

        {/*<Col xl={12} md={12}><Row className={'pl-1'}>
                {selected && selected.map((s) => <div key={s.id} className={'user-tag product-t ml-2'}>
                    <label className={'label-user-tag'}>{s.label}</label>
                    <Icon
                        className={'delete-user-tag d-product-t'} icon={faTimes} tooltip={'Remover'} color={'white'}
                        onClick={() => addOrRemove(s)}
                    />
                </div>)}
            </Row></Col>*/}
      </Row>

      <Folder
        noMessage
        show={show}
        onHide={() => {
          setShow(false)
        }}
        onAssign={item => {
          addOrRemove(item)
        }}
        data1={getInit()}
        /*data1={categorization && categorization.children ? categorization.children[0] : {}}
            data2={categorization && categorization.children ? categorization.children[1] : {}}*/
        loading={loading}
      />
    </div>
  )
}
export default FilterByCategory
