import React, { useContext, useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { Card, Icon } from 'src/components'
import { formatNumberWithCommas } from 'src/utils/formatters'
import { Select, FormText, Button, CustomDate } from 'src/components'
import { BankContext, OperationsContext } from 'src/providers/BankContext'
import { useDispatch, useSelector } from 'react-redux'
import { selectGetAccounts, selectGetAllCurrencies } from 'src/selectors/banks.selector'
import { isAllowed, selectorGetUsersSubmodule } from 'src/selectors/modules.selector'
import { createBankOperation } from 'src/actions/banks.actions'

import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import { actionTypes as typeT } from 'src/actions/banks.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import { hideAlert, showAlert } from 'src/actions/alert.actions'
import SimplePurchase from 'src/components/custom/SimplePurchase/SimplePurchase'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import {
  bankAccountPermissionsEnum,
  defaultBankAccount,
  options,
} from 'src/enums/bankEnums'
import { transformAccounts } from 'src/utils/bankHelpers/bankHelpers'

const defaultMovementRequestData: IMovementRequestData = {
  amount: '',
  bankAccount: 0,
  currency: 0,
  date: '',
  description: '',
  operationType: 0,
  reference: '',
  registerDate: 0,
  secondAccountId: null,
  secondReference: '',
  user: { value: 0, label: '' },
  userId: 0,
  purchase: {},
  change: 0.0,
}

interface ModalOperationsProps {
  show: boolean
  onHide: () => void
  onShow?: () => void
  setKey?: () => void
}

const AccountToTransferInput = ({ options, handOperationsData }) => {
  return (
    <>
      <Select
        required
        label={'Seleccione cuenta a acreditar'}
        options={options}
        onChange={handOperationsData}
      />
    </>
  )
}
interface movementsLoaded {
  movements: boolean
}
const OperationsModal: React.FC<ModalOperationsProps> = ({ show, onHide, onShow }) => {
  const dispatch = useDispatch()
  const { accounnt } = useContext(BankContext) || {}
  const { operations, updateOperations } = useContext(OperationsContext) || {}

  const accounts = useSelector(selectGetAccounts)
  const currencies = useSelector(selectGetAllCurrencies)
  const usersSubmodule = useSelector(state => selectorGetUsersSubmodule(state))
  const loadingOp = useSelector(state => loadingSelector([typeT.CREATE_OPERATION])(state))
  const allowDuplicate = useSelector(state =>
    isAllowed(state, [bankAccountPermissionsEnum.DuplicateOperations]),
  )
  const hasErrorOp = useSelector(state => hasErrors([typeT.CREATE_OPERATION])(state))
  const [movementDataOperation, setMovementDataOperation] =
    useState<IMovementRequestData>(defaultMovementRequestData)
  const [showAssociatedOperation, setShowAssociatedOperation] = useState<boolean>(false)
  const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(true)
  const [movementsLoaded, setMovementsLoaded] = useState<movementsLoaded>({
    movements: false,
  })
  const [purchaseData, setPurchaseData] = useState({})
  const [accountToTranser, setAccountToTranser] =
    useState<IBankAccount>(defaultBankAccount)

  let purchaseConstant = {}
  const accountsTansformed = transformAccounts(accounts)

  const getCurrencySymbolItem = id => {
    let sym = ''

    sym = currencies?.find(c => c.id === id)?.symbol || {}

    return sym
  }

  useEffect(() => {
    setMovementDataOperation({
      ...movementDataOperation,
      currency: accounnt.currency.id,
    })
  }, [accounnt])
  useEffect(() => {
    if (movementDataOperation.reference && movementDataOperation.date)
      setButtonDisabled(false)
  }, [movementDataOperation])

  const accountsSelector: IBankAccount[] = accountsTansformed.reduce((acc, item) => {
    if (accounnt.id !== item.id) {
      const { id, name, accountNumber, currency, balance } = item
      acc.push({
        id,
        name,
        accountNumber,
        balance,
        value: id,
        label: `${name} - No. ${accountNumber} `,
        currency: currency,
      })
    }
    return acc
  }, [])

  const createMovement = (requestObj, duplicated) => {
    let error = false

    if (!requestObj.operationType || requestObj.reference === '' || !requestObj.date) {
      error = true
    } else {
      const { purchase, amount, reference, date, operationType, user } = requestObj

      if (!purchase?.nullify && (!amount || amount <= 0 || !reference || !date)) {
        error = true
      }

      if (
        operationType?.value === 103 &&
        (!accountToTranser?.id || !requestObj.secondReference)
      ) {
        error = true
      } else if (operationType?.value === 104 && !user) {
        error = true
      }
    }

    if (error) {
      dispatch(
        showAlert({
          type: 'warning',
          title: 'error',
          text: 'Revise los datos obligatorios del formulario',
          showConfirmButton: true,
        }),
      )
      return
    }

    dispatch(createBankOperation(requestObj, { duplicate: duplicated }))
  }

  useEffect(() => {
    if (loadingOp) setMovementsLoaded({ ...movementsLoaded, movements: true })
    else if (movementsLoaded.movements) {
      setMovementsLoaded({ ...movementsLoaded, movements: false })
      if (hasErrorOp && allowDuplicate) {
        const requestObject = {
          operationType: operations.value,
          reference: movementDataOperation.reference,
          date: movementDataOperation.date,
          amount: movementDataOperation.amount,
          description: movementDataOperation.description,
          bankAccount: accounnt.id,
          secondAccountId: operations.value === 103 ? accountToTranser.id : undefined, //requestObject.secondAccountId = accountToTranser.id
          secondReference:
            operations.value === 103 ? movementDataOperation.secondReference : undefined,
          currency: movementDataOperation.currency,
          registerDate: movementDataOperation.date.valueOf(),
          user: operations.value === 104 ? movementDataOperation.user : undefined,
          userId: operations.value === 104 ? movementDataOperation.userId : undefined,
          change: operations.value === 103 ? movementDataOperation.change : undefined,
          purchase: operations.value === 105 ? purchaseData : undefined,
        }

        dispatch(
          showAlert({
            ...handlerError(
              'El numero de referencia ' +
                movementDataOperation.reference +
                ' Ya existe' +
                '\n ¿Desea duplicarlo?',
            ),
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            onConfirm: () => createMovement(requestObject, true),

            onCancel: () => dispatch(hideAlert()),
          }),
        )
      } else {
        onHide()
        dispatch(
          showAlert({
            ...handlerSuccess('Operación bancaria agregada satisfactoriamente'),
          }),
        )

        if (showAssociatedOperation) setShowAssociatedOperation(false)
      }
    }
  }, [loadingOp])

  const handleOperation = () => {
    setMovementDataOperation({
      ...movementDataOperation,
      bankAccount: accounnt.accountNumber,
    })

    const requestObject = {
      operationType: operations.value,
      reference: movementDataOperation.reference,
      date: movementDataOperation.date,
      amount: movementDataOperation.amount,
      description: movementDataOperation.description,
      bankAccount: accounnt.id,
      secondAccountId: accountToTranser.id,
      secondReference: movementDataOperation.secondReference,
      currency: movementDataOperation.currency,
      registerDate: movementDataOperation.date.valueOf(),
      user: operations.value === 104 ? movementDataOperation.user : undefined,
      userId: operations.value === 104 ? movementDataOperation.userId : undefined,
      change: operations.value === 104 ? movementDataOperation.change : undefined,
      purchase: operations.value === 105 ? purchaseData : undefined,
    }

    createMovement(requestObject, false)
  }

  const handlePurchase = purchase => {
    setPurchaseData(purchase)
  }

  const handOperationsData = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { id, value } = e.currentTarget

    setMovementDataOperation(prevData => ({
      ...prevData,
      [id]: value,
    }))
  }

  const handleOperationsOptions = options => {
    setMovementDataOperation(prevData => ({
      ...prevData,
      operationType: operations.value,
    }))
    updateOperations(options)
  }

  const formatedTotal =
    getCurrencySymbolItem(accounnt.currency.id) +
    '.' +
    formatNumberWithCommas(accounnt.balance)
  const shouldRenderOtherFiels = opts => {
    if (opts.value === 104) {
      return (
        <AccountToTransferInput
          options={usersSubmodule}
          handOperationsData={data => {
            setMovementDataOperation({
              ...movementDataOperation,
              user: data,
              userId: data.value,
            })
          }}
        />
      )
    }
    if (opts.value === 103) {
      return (
        <>
          <Select
            required
            label={'Seleccione cuenta a acreditar'}
            options={accountsSelector}
            // value={accountToTransfer}
            onChange={s => setAccountToTranser(s)}
          />

          <FormText
            label={'No. documento crédito'}
            type={'text'}
            id={'reference'}
            onChange={e =>
              setMovementDataOperation({
                ...movementDataOperation,
                secondReference: e.target.value,
              })
            }
            placeholder={'pj. 1535455'}
            required
          />
        </>
      )
    }

    return <></>
  }

  return (
    <>
      <Modal size={'lg'} centered show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Operación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row data-testid={'opeartions-form'}>
            <span className={'pl-3'}>
              <b>
                Cuando agregas una transacción bancaria en este modal, estás indicando que
                has visto una transacción en el estado de cuenta bancario. Esta
                transacción no será acreditada a ningún usuario a menos que un
                administrador así lo autorice
              </b>
            </span>
          </Row>
          <Row>
            <Col md={5}>
              <Select
                required
                label={'Tipo'}
                options={options}
                value={operations}
                onChange={opt => handleOperationsOptions(opt)}
              />

              <Card className={'mt-2 text-center'}>
                <h5 className={'dashboard-currency'} style={{ color: '#226095' }}>
                  {accounnt.name}
                </h5>
                <h6 className={'dashboard-subtitle dashboard-f-color'}>
                  {formatedTotal}
                </h6>
              </Card>
              {operations.value && accountToTranser.name && operations.value === 103 && (
                <div style={{ textAlign: 'center' }}>
                  <Icon icon={faArrowDown} size={'1_5x'} />
                  <Card className={'mt-2'}>
                    <h5 className={'dashboard-currency'} style={{ color: '#226095' }}>
                      {accountToTranser?.name}
                    </h5>
                    <h6 className={'dashboard-subtitle dashboard-f-color'}>
                      {formatNumberWithCommas(accountToTranser.balance)}
                    </h6>
                  </Card>
                </div>
              )}
            </Col>

            <Col md={7}>
              <FormText
                label={'No. documento'}
                type={'text'}
                id={'reference'}
                placeholder={'pj. 1535455'}
                onChange={handOperationsData}
                required
              />
              <CustomDate
                label={'Fecha de registro '}
                onDayChange={data => {
                  setMovementDataOperation({ ...movementDataOperation, date: data })
                }}
                required
              />

              {operations.value !== 0 && shouldRenderOtherFiels(operations)}

              {operations.value === 103 &&
                accountToTranser.currency.id &&
                accounnt.currency.id !== accountToTranser.currency.id && (
                  <FormText
                    label={'Tasa de conversión'}
                    type={'number'}
                    id={'amount'}
                    placeholder={'0.00'}
                    name={'change'}
                    // value={movementRequest.change}
                    onChange={e => {
                      setMovementDataOperation({
                        ...movementDataOperation,
                        change: e.target.value,
                      })
                    }}
                    prepend={getCurrencySymbolItem(accounnt.currency.id)}
                    required
                  />
                )}

              <FormText
                label={'Monto'}
                type={'number'}
                id={'amount'}
                placeholder={'0.00'}
                name={'amount'}
                error={''}
                prepend={getCurrencySymbolItem(accounnt.currency.id)}
                required
                onChange={handOperationsData}
              />

              <FormText
                label={'Descripcion'}
                type={'text'}
                id={'description'}
                name={'description'}
                onChange={handOperationsData}
              />
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          {operations.value === 102 && (
            <Button
              onClick={() => {
                onHide()
                setShowAssociatedOperation(true)
              }}
              color={'secondary'}>
              Asociar un gasto
            </Button>
          )}
          <div>
            <Button
              onClick={handleOperation}
              disabled={buttonDisabled}
              loading={loadingOp}>
              Agregar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {showAssociatedOperation && (
        <SimplePurchase
          modal
          purchase={false}
          amount={movementDataOperation.amount}
          onHide={() => setShowAssociatedOperation(false)}
          onCancel={() => {
            setShowAssociatedOperation(false)
            onShow()
          }}
          show={showAssociatedOperation}
          load={loadingOp}
          bank
          onSave={request => {
            purchaseConstant = request

            handlePurchase(purchaseConstant)
            handleOperation()
          }}
          updated={undefined}
        />
      )}
    </>
  )
}
export default OperationsModal
