import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'src/components/buttons/Button'
import { default as TableV2 } from 'src/components/Pagination/PaginatedTableV2'
import { Modal } from 'react-bootstrap'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import {
  actionTypes,
  getInvoiceIssuedByDealer,
  getInvoiceIssuedByDealerExcel,
} from 'src/actions/invoice.actions'
import { selectInvoiceIssuedByDealer } from 'src/selectors/invoice.selector'
import { loadingSelector } from 'src/selectors/loading.selector'

interface IFilters {
  startDate: Date
  endDate: Date
}

interface IProps {
  show: boolean
  id: number
  modal?: boolean
  name: string
  onHide?: () => void
  filters: IFilters
}

/**
 * Component to show invoices issued by dealer
 * @component
 * @param {boolean} show Indicate whether the component should be initialized
 * @param {number} id Id cotizap client of company
 * @param {boolean} modal Indicates whether the component should be MODAL or not
 * @param {string} name Name of Company
 * @param {Function} onHide Close the component
 * @returns
 */
export const InvoicesIssued = ({ show, id, modal, name, onHide, filters }: IProps) => {
  const dispatch = useDispatch()

  const excelLoading: boolean = useSelector(state =>
    loadingSelector([actionTypes.GET_INVOICE_RECEIVED_BY_DEALER_EXCEL])(state),
  )

  const [open, setOpen] = useState<boolean>(false)

  const invoicesIssued: IInvoiceDealer[] = useSelector(selectInvoiceIssuedByDealer)

  useEffect(() => {
    if (!show) return
    setOpen(true)
    dispatch(
      getInvoiceIssuedByDealer({
        startDate: filters ? filters.startDate.valueOf() : null,
        endDate: filters ? filters.endDate.valueOf() : null,
        companyId: id,
      }),
    )
  }, [show])

  const render = () => {
    return (
      <div data-cy="content">
        <Button
          icon={faFileExcel}
          loading={excelLoading}
          color={'accent'}
          dataCy={'export-excel'}
          onClick={() =>
            dispatch(
              getInvoiceIssuedByDealerExcel({
                startDate: filters ? filters.startDate.valueOf() : null,
                endDate: filters ? filters.endDate.valueOf() : null,
                companyId: id,
              }),
            )
          }>
          Exportar excel
        </Button>
        <hr />
        <TableV2
          /* @ts-expect-error JS */
          storageKey={'invoicesIssued'}
          items={invoicesIssued}
          headers={[
            {
              show: true,
              label: 'Nombre comercial',
              type: 'text',
              value: ['name'],
            },
            {
              show: true,
              label: 'Nombre completo',
              type: 'text',
              value: ['largeName'],
            },
            {
              show: true,
              label: 'Total de facturas',
              type: 'text',
              value: ['invoices'],
            },
          ]}
        />
      </div>
    )
  }

  const onClose = () => {
    setOpen(false)
    onHide()
  }
  return (
    <div>
      {modal ? (
        <Modal show={open} size={'lg'} centered onHide={onClose}>
          <Modal.Header closeButton>
            <Modal.Title>Facturas emitidas: {name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{render()}</Modal.Body>
        </Modal>
      ) : (
        render()
      )}
    </div>
  )
}
