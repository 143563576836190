import moment from 'moment'
import { $http, $httpPublic } from './http'
import { saveAs } from 'file-saver'

class CompaniesService {
  getAllCompanies = async () => {
    const response = await $http.get(`/company/all`)
    return response.data
  }

  getPromotorCompanies = async () => {
    const response = await $http.get(`/company/promotor`)
    return response.data
  }

  getCompaniesByType = async (isProducer, isShipper, isClient, contractType) => {
    if (contractType === null) contractType = 0
    const response = await $http.get(
      `/company/type?isProducer=${isProducer}&isShipper=${isShipper}&isClient=${isClient}&contractType=${contractType}`,
    )
    return response.data
  }

  activateSingleCompany = async id => {
    const response = await $http.put(`/company/activate/${id}`)
    return response.data
  }

  getSingleCompany = async id => {
    const response = await $http.get(`/company/?id=${id}`)
    return response.data
  }

  deleteSingleCompany = async id => {
    const response = await $http.delete(`/company/?id=${id}`)
    return response.data
  }

  updateSingleCompany = async (id, request) => {
    const response = await $http.put(`/company`, request, { params: { id } })
    return response.data
  }

  createSingleCompanyAdmin = async request => {
    const response = await $http.post(`/company`, request)
    return response.data
  }

  getEditablesForCompany = async () => {
    const response = await $http.get(`/company/fields/editable`)
    return response.data
  }

  setEditablesForCompany = async request => {
    const response = await $http.put(`/company/fields/editable`, request)
    return response.data
  }

  getCompaniesByClient = async id => {
    const response = await $http.get(`/company/client/${id}`)
    return response.data
  }

  getCurrentCompany = async (): Promise<ICompany> => {
    const response = await $http.get(`/company/current`)
    return response.data
  }

  getCompany = async (companyId): Promise<ICompany> => {
    const response = await $http.get(`/company/${companyId}`)
    return response.data
  }

  getCompanyFields = async () => {
    const response = await $http.get(`/company/fields`)
    return response.data
  }

  getCompanyField = async (fieldId, params) => {
    const response = await $http.get(`/company/fields/${fieldId}`, { params })
    return response.data
  }

  setCompanyFields = async list => {
    const response = await $http.post(`/company/fields`, list)
    return response.data
  }

  // getCompanyNS = async (companyId) => {
  //     const response = await $httpns.get(`nscompanies/${companyId}/company`);
  //     return response.data;
  // };

  setCompanyField = async (id, value) => {
    const response = await $http.put(`/company/fields/${id}?value=${value}`)
    return response.data
  }

  getCompaniesByOrder = async orderId => {
    const response = await $http.get(`/companies/contracts/by/order/${orderId}`)
    return response.data
  }

  getCompaniesByOrders = async orders => {
    const response = await $http.put(`/companies/contracts/by/orders`, orders)
    return response.data
  }

  getPatrimonialReport = async (sDate, eDate, type) => {
    let params = ''
    if (sDate) params += `start=${sDate}`
    if (eDate) params += (sDate ? '&' : '').concat(`end=${eDate}`)
    const response = await $http.get(`/cotizap/reports/patrimonial/${type}?${params}`)
    return response.data
  }

  getAllBadges = async () => {
    const response = await $http.get(`/company/badge`)
    return response.data
  }

  getBadgesByCompany = async companyId => {
    const response = await $http.get(`/company/badge/${companyId}`)
    return response.data
  }

  assingBadges = async (id, companyId) => {
    const response = await $http.put(`/company/badge/assign/${id}/${companyId}`)
    return response.data
  }

  unassingBadges = async (id, companyId) => {
    const response = await $http.put(`/company/badge/unassign/${id}/${companyId}`)
    return response.data
  }

  createBadge = async request => {
    const response = await $http.post(`/company/badge`, request)
    return response.data
  }

  getCompanyFieldsForm = async () => {
    const response = await $http.get('/company/fields/forms')
    return response.data
  }

  getCorrelativeField = async () => {
    const response = await $http.get('/company/fields/correlative')
    return response.data
  }

  setCorrelativeField = async value => {
    const response = await $http.put(`/company/fields/correlative/${value}`)
    return response.data
  }

  getDTEReport = async params => {
    const response = await $http.get('/company/report/dte', { params })
    return response.data
  }

  getDTEReportExcel = async params => {
    const response = await $http.get('/company/report/dte/excel', {
      params,
      responseType: 'blob',
    })

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    const filename = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : moment().format('LL') + ' - Reporte de DTE.xlsx'

    saveAs(file, filename.replace('json', 'xlsx'))
  }

  getCompaniesMentor = async params => {
    const response = await $http.get('/company/mentor', { params })
    return response.data
  }

  setCompaniesMentor = async request => {
    const response = await $http.post('/company/mentor', request)
    return response.data
  }

  deleteCompaniesMentor = async id => {
    const response = await $http.delete(`/company/mentor/?id=${id}`)
    return response.data
  }

  getInvoicePackages = async () => {
    const response = await $http.get('/company/invoice_package')
    return response.data
  }

  buyInvoicePackages = async (invoicePackageId, request) => {
    const response = await $http.put(
      `/company/invoice_package/${invoicePackageId}`,
      request,
    )
    return response.data
  }

  getCompanyDashboard = async params => {
    const response = await $http.get('/company/dashboard', { params })
    return response.data
  }

  getCompanyData = async () => {
    const response = await $http.get('/company/data')
    return response.data
  }

  /**
   * Service for getting company balance
   * @returns {Promise<number>}
   */
  getCompanyBalance = async () => {
    const response = await $http.get('/company/balance')
    return response.data
  }

  activateFreeAccount = async token => {
    const response = await $httpPublic.post('/landing-page/activation?token=' + token)
    return response.data
  }
}

export default new CompaniesService()
