import React, { useState } from 'react'
import {
  FormControl,
  FormControlProps,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectCurrentCurrency } from 'src/selectors/currencies.selector'
import { selectMeasurementUnits } from 'src/selectors/products.selector'
import { faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconButton from '../buttons/IconButton'
import useDecimalNumber from 'src/hooks/useDecimalNumber'

export interface IProps extends FormControlProps {
  value: number
  label?: string | React.ReactElement
  name?: string
  onValueChange?: (value: number) => void
  decimals?: number
  measurementUnit?: number
  isMoney?: boolean
  append?: React.ReactNode
  appendBefore?: boolean
  min?: number
  max?: number
  minLength?: number
  maxLength?: number
  error?: string
  loading?: boolean
  info?: string
  noArrows?: boolean
  labelStyle?: React.CSSProperties
  containerStyle?: React.CSSProperties
  required?: boolean
}

/**
 * Number input field supporting decimals
 * @component
 * @prop {string} label - Label to show above the input
 * @prop {string} name - Input name
 * @prop {number} value - The value of the input
 * @prop {function} onValueChange - Callback function to update the value
 * @prop {number} decimals - Number of decimals to show (from 0 to 6)
 * @prop {number} measurementUnit - ID of the measurement unit to show after the input
 * @prop {boolean} isMoney - If true, the input will show the company currency symbol
 * @prop {boolean} append - Element to append
 * @prop {boolean} appendBefore - If true, the input will show the appended value before the input
 * @prop {number} min - Minimum value
 * @prop {number} max - Maximum value
 * @prop {number} minLength - Minimum value length
 * @prop {number} maxLength - Maximum value length
 * @prop {string} error - Error message to show below the input
 * @prop {string} loading - True if input is loading
 * @prop {string} info - Info message to show below the input
 * @prop {boolean} noArrows - If true, the input will not show the arrows to increase/decrease the value
 * @prop {React.CSSProperties} labelStyle - Extra label styling
 * @prop {React.CSSProperties} containerStyle - Extra container styling
 * @prop {boolean} required - If true, the input will be required
 */
const NumberField = ({
  label,
  name,
  value,
  onValueChange,
  decimals = 0,
  measurementUnit,
  isMoney,
  append,
  appendBefore,
  min,
  max,
  minLength,
  maxLength,
  error,
  loading,
  info,
  noArrows = false,
  labelStyle,
  containerStyle,
  required,
  ...props
}: IProps) => {
  const [writingNegative, setWritingNegative] = useState(false)
  const [writingDecimal, setWritingDecimal] = useState(false)
  const [zeroes, setZeroes] = useState(0)
  const getDecimalNumber = useDecimalNumber()

  const stringValue =
    (writingNegative ? '-' : '') +
    (value != null ? String(value) : '') +
    (writingDecimal || (!String(value).includes('.') && zeroes > 0) ? '.' : '') +
    '0'.repeat(zeroes)

  const currency = useSelector(selectCurrentCurrency)
  const measurementUnits = useSelector(selectMeasurementUnits)

  const handleChange = value => {
    const { parsedValue, writingDecimal, writingNegative, zeroes } = getDecimalNumber(
      value,
      {
        decimals,
        min,
        max,
        minLength,
        maxLength,
      },
    )

    onValueChange(parsedValue)
    setWritingDecimal(writingDecimal)
    setWritingNegative(writingNegative)
    setZeroes(zeroes)
  }

  const appendElement = isMoney
    ? currency.symbol
    : measurementUnit
    ? measurementUnits.find(item => item.id === measurementUnit)?.symbol
    : append

  const appendTooltip = isMoney
    ? currency.name
    : measurementUnit
    ? measurementUnits.find(item => item.id === measurementUnit)?.name
    : null

  const appendOverlay = (
    <InputGroup.Text style={{ userSelect: 'none' }}>{appendElement}</InputGroup.Text>
  )

  const getFTMValidation = () => {
    return minLength && (!value || String(value).length < minLength) ? 'error' : 'success'
  }

  return (
    <div style={containerStyle}>
      {label && (
        <label className={info ? 'ftf-form-label' : ''} style={labelStyle}>
          {label}
          {info && (
            <label>
              <IconButton
                tooltip={info}
                color={'rgba(34, 96, 149, 0.75)'}
                icon={faInfoCircle}
                size={'sm'}
                style={{ marginLeft: '0.5rem' }}
              />
            </label>
          )}
          {required && <span style={{ color: 'red', marginRight: 2 }}>&nbsp;* </span>}
        </label>
      )}
      <InputGroup style={{ margin: 0 }}>
        {appendElement && appendBefore && appendOverlay}
        <FormControl
          className={`${props.className} ${noArrows ? 'no-arrows' : ''}`}
          name={name}
          value={stringValue}
          onChange={e => {
            handleChange(e.target.value)
          }}
          onKeyDown={e => {
            if (e.key === 'ArrowUp') handleChange(String(value + 1))
            if (e.key === 'ArrowDown') handleChange(String(value - 1))
          }}
          onFocus={e => e.target.select()}
          disabled={props.disabled || loading}
          {...props}
        />
        {appendElement && !appendBefore && appendOverlay}
      </InputGroup>
      {maxLength && (
        <div className={'ft-max ft-max-'.concat(getFTMValidation())}>
          {value ? String(value).length : 0}/{maxLength}
        </div>
      )}
      {error && (
        <div className="input-error">
          {/* @ts-expect-error js */}
          <FontAwesomeIcon icon={faExclamationCircle} /> {error}
        </div>
      )}
    </div>
  )
}

export default NumberField
