import './SelectProducts.scss'
import React, { Component } from 'react'

import { Card, InputGroup, Button, FormControl, Row } from 'react-bootstrap'
import TextField from '../inputs/FormTextField/FormText'
import Icon from '../buttons/IconButton'
import InventoryReport from '../inventory/InventoryReport'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'
import {
  faComment,
  faExclamationCircle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'

import holder from 'src/assets/images/svg/food.svg'
import { formatNumberWithCommas } from 'src/utils/formatters'
import Money from '../Money/Money'
import { toMoney } from 'src/utils/utilities'

export default class SelectProducts extends Component {
  state = {
    report: { warehouse: null, product: null, productName: null },
  }

  seeOptions = event => {
    const { seeOptions, index, disabled } = this.props
    if (disabled) return
    const { checked } = event.target
    seeOptions(checked, index)
  }

  onChangeInput = value => {
    const { changeQuantity, index } = this.props
    let comp = `${value || 0}`
    if (comp.match(/[0-9]+/g)) {
      if (value < 0) value = 0
      changeQuantity(value || 0, index)
    }
  }

  onChangeSInput = value => {
    const { changeSQuantity, index } = this.props
    let comp = `${value || 0}`
    if (comp.match(/[0-9]+/g)) {
      if (value < 0) value = 0
      changeSQuantity(value || 0, index)
    }
  }

  onChangeDiscount = value => {
    const { changeDiscount, index } = this.props
    let comp = `${value || 0}`
    if (comp.match(/[0-9]+/g)) {
      if (value < 0) value = 0
      changeDiscount(value || 0, index)
    }
  }

  onChangePrice = value => {
    const { changePrice, index } = this.props
    let comp = `${value || 0}`
    if (comp.match(/[0-9]+/g)) {
      if (value < 0) value = 0
      changePrice(value || 0, index)
    }
  }

  render() {
    const {
      title,
      subtitle,
      description,
      checked,
      price,
      subtotal,
      quantity,
      solicited,
      sufficient,
    } = this.props
    const { sQuantity, sValue, sTitle } = this.props
    const { seeDiscount, seeSetPrice, discount, maxDiscount, existence, setPrice } =
      this.props
    const { ribbon, ribbonColor, ribbonClass, sPrice } = this.props

    const { disabled, error } = this.props
    const { warehouseId, index } = this.props
    const {
      titleQuantity,
      sku,
      titleUnitPrice,
      titleSubTotal,
      bodeStyle,
      seeCommentary,
      commentary,
      onChangeCommentary,
    } = this.props

    return (
      <div>
        {!checked ? (
          <Card
            bodeStyle={bodeStyle}
            className={'hide-overflow'}
            onClick={() => this.seeOptions({ target: { checked: true } })}
            border={sufficient ? 'success' : disabled ? 'danger' : 'light'}>
            {ribbon && (
              <div
                className={`corner-ribbon top-left shadow ribbon-position ${ribbonClass}`}
                style={ribbonColor ? { backgroundColor: ribbonColor } : {}}>
                {ribbon}
              </div>
            )}
            <Card.Img className={'img'} variant={'top'} src={holder} />
            <Card.ImgOverlay>
              {!disabled && !sufficient && (
                <input
                  className={'check'}
                  type={'checkbox'}
                  checked={checked}
                  onChange={this.seeOptions}
                />
              )}
            </Card.ImgOverlay>
            <Card.Body>
              <div className={'description step-progress'}>
                <Card.Title>
                  <div className={'column'}>
                    <h2>
                      <strong>{title || titleQuantity}</strong>
                    </h2>
                    <p>{sku || ''}</p>
                    <h4>{subtitle}</h4>
                    {existence !== undefined && <h4>Existencia: {existence}</h4>}
                    {solicited !== undefined && <h4>Cantidad solicitada: {solicited}</h4>}
                    {price && <Money component="h1">{price}</Money>}
                  </div>
                </Card.Title>
                <Card.Text>
                  <div>
                    <p>{description || 'Sin descripción'}</p>
                    {error ? (
                      <div className={'input-error'}>
                        <FontAwesomeIcon icon={faExclamationCircle} /> {error}
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                </Card.Text>
              </div>
            </Card.Body>
          </Card>
        ) : (
          <Card border={'success'}>
            <Card.Body>
              <Row className={'container-buttons'}>
                {warehouseId && index && (
                  <Icon
                    tooltip={'Detalle de inventario'}
                    icon={faInfoCircle}
                    onClick={() =>
                      this.setState({
                        report: {
                          warehouse: warehouseId,
                          product: index,
                          productName: title,
                        },
                      })
                    }
                  />
                )}
                <div style={{ margin: 2 }} />
                {!disabled && !sufficient && (
                  <input type={'checkbox'} checked={checked} onChange={this.seeOptions} />
                )}
              </Row>

              <div className={'column left'}>
                <span>
                  <strong>Productor: </strong>
                  {subtitle}
                </span>
                {sPrice === 0 && (
                  <Money component="span">
                    <strong>Precio:</strong> {price}
                  </Money>
                )}
                <Money component="span">
                  <strong>{titleSubTotal || 'Sub total'}: </strong>
                  {subtotal}
                </Money>
                {existence !== undefined && (
                  <Money component="span">
                    <strong>Existencia:</strong> {existence}
                  </Money>
                )}
                <br />
                <hr />
                <span>
                  <strong>
                    {titleQuantity || title} {!titleQuantity && `(${toMoney(price)})`}
                  </strong>
                </span>
                <InputGroup className={'mb-3'}>
                  <Button
                    onClick={() =>
                      this.onChangeInput((quantity ? parseInt(quantity) : 0) - 1)
                    }
                    variant={'outline-secondary'}>
                    -
                  </Button>
                  <FormControl
                    aria-describedby={'basic-addon1'}
                    value={quantity}
                    type={window.screen.width > 720 ? 'text' : 'number'}
                    name={'quantity'}
                    onChange={event => this.onChangeInput(event.target.value)}
                  />
                  <Button
                    onClick={() =>
                      this.onChangeInput((quantity ? parseInt(quantity) : 0) + 1)
                    }
                    variant={'outline-secondary'}>
                    +
                  </Button>
                </InputGroup>

                {sValue != null && sValue !== 0 && sPrice !== 0 && (
                  <div>
                    <span>
                      <strong>
                        {sTitle} {sPrice && `(${formatNumberWithCommas(sPrice)})`}
                      </strong>
                    </span>
                    <InputGroup className={'mb-3'}>
                      <Button
                        onClick={() =>
                          this.onChangeSInput((sQuantity ? parseInt(sQuantity) : 0) - 1)
                        }
                        variant={'outline-secondary'}>
                        -
                      </Button>
                      <FormControl
                        aria-describedby={'basic-addon1'}
                        value={sQuantity}
                        type={window.screen.width > 720 ? 'text' : 'number'}
                        name={'quantity'}
                        onChange={event => this.onChangeSInput(event.target.value)}
                      />
                      <Button
                        onClick={() =>
                          this.onChangeSInput((sQuantity ? parseInt(sQuantity) : 0) + 1)
                        }
                        variant={'outline-secondary'}>
                        +
                      </Button>
                    </InputGroup>
                  </div>
                )}

                {seeDiscount && (
                  <div>
                    <span>
                      <strong>Descuento</strong>({maxDiscount})
                    </span>
                    <InputGroup className={'mb-3'}>
                      <Button
                        onClick={() =>
                          this.onChangeDiscount((discount ? parseInt(discount) : 0) - 1)
                        }
                        variant={'outline-secondary'}>
                        -
                      </Button>
                      <FormControl
                        aria-describedby={'basic-addon1'}
                        value={discount}
                        type={window.screen.width > 720 ? 'text' : 'number'}
                        name={'quantity'}
                        onChange={event => this.onChangeDiscount(event.target.value)}
                      />
                      <Button
                        onClick={() =>
                          this.onChangeDiscount((discount ? parseInt(discount) : 0) + 1)
                        }
                        variant={'outline-secondary'}>
                        +
                      </Button>
                    </InputGroup>
                  </div>
                )}

                {seeSetPrice && (
                  <div>
                    <span>
                      <strong>{titleUnitPrice || 'Precio Unitario'}</strong>
                    </span>
                    <TextField
                      prepend={
                        <Button
                          onClick={() =>
                            this.onChangePrice((setPrice ? parseInt(setPrice) : 0) - 1)
                          }
                          variant={'outline-secondary'}>
                          -
                        </Button>
                      }
                      append={
                        <Button
                          onClick={() =>
                            this.onChangePrice((setPrice ? parseInt(setPrice) : 0) + 1)
                          }
                          variant={'outline-secondary'}>
                          +
                        </Button>
                      }
                      ptext={'true'}
                      atext={'true'}
                      type={window.screen.width > 720 ? 'text' : 'number'}
                      name={'price'}
                      value={setPrice}
                      onChange={event => this.onChangePrice(event.target.value)}
                    />
                  </div>
                )}

                {seeCommentary && (
                  <div>
                    <span>
                      <strong>Añadir descripción</strong>
                    </span>
                    <TextField
                      prepend={
                        <Icon icon={faComment} tooltip={'Agrega una descripción'} />
                      }
                      name={'commentary'}
                      value={commentary}
                      onChange={({ target }) => onChangeCommentary(target.value, index)}
                    />
                  </div>
                )}
              </div>
            </Card.Body>
          </Card>
        )}
        <InventoryReport
          showItem
          warehouse={this.state.report.warehouse}
          product={this.state.report.product}
          productName={this.state.report.productName}
          close={() =>
            this.setState({
              report: { warehouse: null, product: null, productName: null },
            })
          }
        />
      </div>
    )
  }
}
