import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Modal } from 'react-bootstrap'
import Alert from 'sweetalert-react'

import {
  Title,
  Card,
  Dropdown,
  Select,
  SelectedDates,
  Checkbox,
  Button,
  TableV2,
} from '../../../components'
import { formatNumberWithCommas } from '../../../utils/formatters'

import {
  actionTypes as TypeD,
  getDisburstmentRequestsByParameter,
  updateDisbursementAdmin,
} from '../../../actions/disbursements.actions'
import {
  selectDisbursementRequest,
  selectDisbursementRequestTotal,
} from '../../../selectors/disbursements.selector'

import { getAllCompanies } from '../../../actions/company.actions'
import { selectAllCompanies } from '../../../selectors/company.selector'

import { loadingSelector } from '../../../selectors/loading.selector'
import {
  hasErrorsSelector,
  singleErrorSelector,
  handlerSuccess,
  handlerError,
} from '../../../selectors/error.selector'

import {
  actionTypes as typeM,
  getMovementMatchDeposit,
} from '../../../actions/banks.actions'
import { selectMovementByDeposit } from '../../../selectors/banks.selector'

import { useLocation } from 'react-router-dom'

const status = [
  { label: 'Ingresada', value: 0 },
  { label: 'Solicitada', value: 1 },
  { label: 'En Proceso', value: 2 },
  { label: 'Problema', value: 3 },
  { label: 'Rechazada', value: 4 },
  { label: 'Aprobada', value: 5 },
]

const PaymentRequest = () => {
  const dispatch = useDispatch()

  const companies = useSelector(selectAllCompanies)
  const requests = useSelector(selectDisbursementRequest)
  const total = useSelector(selectDisbursementRequestTotal)
  const loading = useSelector(state =>
    loadingSelector([TypeD.GET_DISBURSEMENTS_REQUEST])(state),
  )

  const loadingU = useSelector(state =>
    loadingSelector([TypeD.UPDATE_SINGLE_DISBURSEMENT_ADMIN])(state),
  )
  const hasError = useSelector(state =>
    hasErrorsSelector([TypeD.UPDATE_SINGLE_DISBURSEMENT_ADMIN])(state),
  )
  const error = useSelector(state =>
    singleErrorSelector([TypeD.UPDATE_SINGLE_DISBURSEMENT_ADMIN])(state),
  )

  const movements = useSelector(selectMovementByDeposit)
  const loadingM = useSelector(state =>
    loadingSelector([typeM.GET_MOVEMENT_BY_DEPOSIT])(state),
  )

  const [isDisbursement] = useState(useLocation().pathname.includes('/desembolsos'))

  const [actions, setActions] = useState({ put: false })
  const [selected, setSelected] = useState(0)
  const [alert, setAlert] = useState({ title: '' })
  const [textFilter, setTextFilter] = useState('')
  const [filters, setFilters] = useState({
    company: { value: null, label: '- Todos -' },
    status: { value: null, label: '- Todos -' },
    user: { value: null, label: '- Todos -' },
    docId: null,
    docReference: null,
    start: null,
    end: null,
    skip: 0,
    size: 10,
  })
  const [modal, setModal] = useState({ show: false })
  const [mSelected, setMSelected] = useState(null)

  useEffect(() => {
    dispatch(getAllCompanies())
    setTextFilter(JSON.stringify(filters))
  }, [])

  useEffect(() => {
    if (textFilter === '' || textFilter === JSON.stringify(filters)) return
    setTextFilter(JSON.stringify(filters))
  }, [filters])

  useEffect(() => {
    if (textFilter !== JSON.stringify(filters)) return
    setUp(filters.start, filters.end)
  }, [textFilter])

  useEffect(() => {
    if (loadingU) setActions({ ...actions, put: true })
    else if (actions.put) {
      setActions({ ...actions, put: false })
      if (hasError)
        setAlert({
          ...handlerError(error.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else {
        setUp()
        setSelected(0)
        setAlert({
          ...handlerSuccess('Operación satisfactoria'),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      }
    }
  }, [loadingU])

  const setUp = () => {
    const { company, status, user, docId, docReference, start, end, skip, size } = filters

    dispatch(
      getDisburstmentRequestsByParameter({
        companyId: company.value,
        status: status.value,
        user: user.value,
        docId,
        docReference,
        start,
        end,
        type: isDisbursement ? 1 : 2,
        skip,
        size,
      }),
    )
  }

  const customFilter = () => {
    return (
      <div className={'column'}>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12}>
            <SelectedDates
              withOptionNull
              onDateChange={(start, end) => {
                if (textFilter !== '') setFilters({ ...filters, start, end })
              }}
            />
          </Col>
          <Col xl={4} lg={4} md={4}>
            <Select
              options={[{ label: '- Todos -', value: null }, ...companies]}
              label={'Por empresa'}
              info={'Filtra las solicitudes por la empresa seleccionada'}
              value={filters.company}
              onChange={company => setFilters({ ...filters, company })}
            />
          </Col>
          <Col xl={4} lg={4} md={4}>
            <Select
              options={[{ label: '- Todos -', value: null }, ...status]}
              label={'Por estado'}
              info={'Filtra las solicitudes por el estado seleccionado'}
              value={filters.status}
              onChange={status => setFilters({ ...filters, status })}
            />
          </Col>
        </Row>
        <br />
      </div>
    )
  }

  const validate = (status, request) => {
    setSelected(request)
    setAlert({
      show: true,
      type: status === 5 ? 'input' : 'info',
      title: status === 5 ? 'Aprobar solicitud' : 'Actualiza el estado de la solicitud',
      text:
        status === 5
          ? 'Ingrese el número de documento'
          : '¿Desea modificar la solicitud?',
      inputPlaceholder: 'número de documento',
      confirmButtonText: 'Guardar',
      confirmButtonColor: '#226095',
      showCancelButton: true,
      onConfirm: description => {
        if (status === 5 && !description) return
        setAlert({ ...alert, show: false })
        dispatch(updateDisbursementAdmin(request, status, description))
      },
      onCancel: () => {
        setAlert({ ...alert, show: false })
        setSelected(0)
      },
    })
  }

  const approve = request => {
    setSelected(request.id)
    setModal({ show: true, amount: request.amount })
    dispatch(getMovementMatchDeposit(request.id, 4))
  }

  const getHeaders = () => {
    const headers = [
      {
        label: 'Referencia',
        show: true,
        value: ['documentId'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Empresa',
        show: true,
        value: ['company', 'name'],
        type: 'text',
        className: 'mini',
        custom: item => (item.company ? item.company.name : 'Desconocido'),
      },
      {
        label: 'Responsable',
        show: true,
        value: ['user'],
        type: 'text',
        className: 'mini',
      },
    ]
    if (!isDisbursement)
      headers.push({
        label: 'Recargo',
        show: true,
        value: ['surcharge'],
        type: 'currency',
        className: 'mini',
      })
    return headers.concat([
      {
        label: 'Total',
        show: true,
        value: ['amount'],
        type: 'currency',
        className: 'mini',
      },
      {
        label: 'Estado',
        show: true,
        value: ['statusData', 'name'],
        type: 'text',
        className: 'mini',
      },
      {
        config: true,
        show: true,
        label: '',
        className: 'mini',
        custom: item =>
          item.status === 5 ? (
            ''
          ) : item.status === 0 ? (
            'Pendiente'
          ) : (
            <Dropdown
              items={[
                {
                  show: item.status === 1,
                  title: 'Procesar',
                  action: () => validate(2, item.id),
                },
                {
                  show: item.status === 1 || item.status === 2,
                  title: 'Problema',
                  action: () => validate(3, item.id),
                },
                {
                  show: item.status === 1 || item.status === 2,
                  title: 'Rechazar',
                  action: () => validate(4, item.id),
                },
                {
                  show: item.status === 2,
                  title: 'Aprobar',
                  action: () => approve(item),
                },
              ]}
              loading={loadingU && selected === item.id}
              disabled={loadingU && selected !== item.id}
            />
          ),
      },
    ])
  }

  return (
    <div>
      <Title title={isDisbursement ? 'Desembolsos' : 'Cobros con tarjeta'} />
      <Card white title={'Solicitudes'}>
        {/*<CustomSummary items={[{title: 'Total de recargos', value: `Q.${formatNumberWithCommas(10)}`}]}/>*/}
        <TableV2
          storageKey={`payRequest`}
          loading={loading}
          items={requests}
          customFilter={customFilter()}
          getPagination={(skip, size) => setFilters({ ...filters, skip, size })}
          total={total}
          noItemsLegend={'No hay solicitudes con los filtros de pago'}
          headers={getHeaders()}
          mobileAuto
        />
      </Card>

      <Modal
        show={modal.show}
        size={'lg'}
        centered
        onHide={() => {
          setModal({ ...modal, show: false })
          setMSelected(null)
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Aprobar solicitud de desembolso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12} lg={12} md={12}>
              <h6>
                Debe seleccionar un movimiento bancario igual al monto de la solicitud
              </h6>
            </Col>
            <Col xl={12} lg={12} md={12}>
              <TableV2
                storageKey={`approveRequest`}
                headers={[
                  {
                    label: '',
                    select: true,
                    show: true,
                    className: 'mini',
                    custom: item => <Checkbox checked={mSelected === item.id} />,
                  },
                  {
                    label: 'Fecha',
                    show: true,
                    value: ['createdAt'],
                    type: 'date',
                    className: 'mini',
                  },
                  {
                    label: 'Referencia',
                    show: true,
                    value: ['reference'],
                    type: 'text',
                    className: 'mini',
                  },
                  {
                    label: 'Cantidad',
                    show: true,
                    value: ['amount'],
                    type: 'number',
                    className: 'mini',
                    custom: item => formatNumberWithCommas(Math.abs(item.amount)),
                  },
                  {
                    label: 'Desc.',
                    show: true,
                    value: ['description'],
                    type: 'text',
                    className: 'medium',
                  },
                  { config: true, show: true, label: '', className: 'mini' },
                ]}
                items={movements.filter(
                  m => Math.abs(m.amount) === Math.abs(modal.amount || 0),
                )}
                loading={loadingM}
                noItemsLegend={
                  'No se encontraron movimientos bancarios que coinciden con la referencia'
                }
                onClickTr={(e, item) => {
                  if (mSelected === item.id) setMSelected(null)
                  else setMSelected(item.id)
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              disabled={!mSelected}
              color={'primary'}
              onClick={() => {
                dispatch(updateDisbursementAdmin(selected, 5, mSelected))
                setMSelected(null)
                setModal({ ...modal, show: false })
              }}>
              Aprobar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Alert {...alert} />
    </div>
  )
}
export default PaymentRequest
