import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCategorizations } from 'src/actions/categorization.actions'
import {
  Col,
  FormControl,
  InputGroup,
  ListGroup,
  Modal,
  ProgressBar,
  Row,
} from 'react-bootstrap'
import { Button, Card, FormTextField, Title } from 'src/components'
import IconButton from 'src/components/buttons/IconButton'
import ButtonIcon from 'src/components/buttons/IconButton'
import { faPlusCircle, faTimes, faUpload } from '@fortawesome/free-solid-svg-icons'
import ReactQuill from 'react-quill'
import {
  actionTypes as actionTypesVersions,
  saveNewVersion,
} from 'src/actions/versions.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'
import Folder, { categoryType } from 'src/components/folders/Folder'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'
import 'react-notifications-component/dist/scss/notification.scss'
import Alert from 'sweetalert-react'
import PermissionGroup from '../../Permissions/UpdatePermissions/PermissionGroup'
import { rolesSelect } from 'src/selectors/modules.selector'
import { history } from '../../../App'
import { FilePicker } from 'react-file-picker'
import { uploadVideo } from 'src/actions/uploads.actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  actionTypes as actionTypesVideos,
  saveNewVideo,
} from 'src/actions/videos.actions'

const CreateInformation = ({ modal, show, onHide }) => {
  const dispatch = useDispatch()

  const newVideoIsLoading = useSelector(state =>
    loadingSelector([actionTypesVideos.SAVE_NEW_VIDEO])(state),
  )
  const newVideoHasError = useSelector(state =>
    hasErrorsSelector([actionTypesVideos.SAVE_NEW_VIDEO])(state),
  )
  const newVideoError = useSelector(state =>
    singleErrorSelector([actionTypesVideos.SAVE_NEW_VIDEO])(state),
  )

  const newVersionIsLoading = useSelector(state =>
    loadingSelector([actionTypesVersions.SAVE_NEW_VERSION])(state),
  )
  const newVersionHasError = useSelector(state =>
    hasErrorsSelector([actionTypesVersions.SAVE_NEW_VERSION])(state),
  )
  const newVersionError = useSelector(state =>
    singleErrorSelector([actionTypesVersions.SAVE_NEW_VERSION])(state),
  )

  const categorizations = useSelector(selectAllCategorizations)

  const roles = useSelector(rolesSelect)

  const [action, setAction] = useState({ post: false })
  const [list, setList] = useState([
    {
      title: '',
      description: '',
      categorization: [],
      permission: [],
      videos: [],
    },
  ])
  const [version, setVersion] = useState({ show: false })
  const [categories, setCategories] = useState({
    show: false,
    index: 0,
    assign: [],
  })
  const [alert, setAlert] = useState({ show: false })
  const [group, setGroup] = useState({ show: false, index: 0, assign: [] })

  const [uploadIsLoading, setUploadIsLoading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [showErrorVideo, setShowErrorVideo] = useState(false)

  useEffect(() => {
    dispatch(getAllCategorizations(11))
  }, [])

  useEffect(() => {
    if (newVersionIsLoading) setAction({ ...action, post: true })
    else if (action.post) {
      setAction({ ...action, post: false })
      if (newVersionHasError) {
        setAlert({
          ...handlerError(newVersionError.message || 'Error al crear la versión'),
          onConfirm: () => setAlert({ show: false }),
        })
      } else {
        setAlert({
          ...handlerSuccess('Nueva versión registrada'),
          onConfirm: () => {
            setAlert({ show: false })
            history.goBack()
          },
        })
      }
    }
  }, [newVersionIsLoading])

  useEffect(() => {
    if (newVideoIsLoading) setAction({ ...action, post: true })
    else if (action.post) {
      setAction({ ...action, post: false })
      if (newVideoHasError) {
        setAlert({
          ...handlerError(newVideoError.message || 'Error Inesperado'),
          onConfirm: () => setAlert({ show: false }),
        })
      } else
        setAlert({
          ...handlerSuccess('Video almacenado exitosamente'),
          onConfirm: () => {
            setAlert({ show: false })
            if (onHide) onHide()
          },
        })
    }
  }, [newVideoIsLoading])

  const saveVersion = () => {
    version.details = list.map(l => ({
      title: l.title,
      description: l.description,
      categorization: l.categorization.length ? l.categorization.map(c => c.id) : [],
      permission: l.permission.length ? l.permission : [],
      videos: l.videos.length && l.videos[0].urls[0].url ? l.videos : [],
    }))

    console.log(version)
    dispatch(saveNewVersion(version))
  }

  const upload = (index, file) => {
    setUploadIsLoading(true)
    dispatch(uploadVideo(file, onProgress, url => onSuccess(index, url), onError))
  }

  const onProgress = progress => setUploadProgress(progress)

  const onError = error => {
    setUploadProgress(0)
    setShowErrorVideo(showErrorVideo)
  }

  const onSuccess = (index, url) => {
    setUploadIsLoading(false)
    setUploadProgress(0)

    if (!list[index].videos.length) list[index].videos = [{ urls: [{}] }]
    list[index].videos[0].urls[0].url = url

    setList([...list])
  }

  const renderContent = () => {
    return (
      <div>
        <Card>
          <Row>
            <Col sm={12} md={4}>
              <FormTextField
                label={'Nombre'}
                required
                type={'text'}
                value={version.versionName}
                onChange={event =>
                  setVersion({ ...version, versionName: event.target.value })
                }
                placeholder={'Nombre de la versión'}
              />
            </Col>
            <Col sm={12} md={8}>
              <FormTextField
                label={'Descripción'}
                required
                type={'text'}
                value={version.description}
                onChange={event =>
                  setVersion({ ...version, description: event.target.value })
                }
                placeholder={'Resumen de los cambios de la versión'}
              />
            </Col>
          </Row>
        </Card>
        <hr style={{ border: '1px solid lightgray' }} />
        {list.map((l, index) => (
          <div>
            <Card style={{ position: 'relative' }}>
              <h5>{`Sección: ${l.title || ''}`}</h5>
              <div className={'close-detail'}>
                <IconButton
                  icon={faTimes}
                  size={'1_5x'}
                  color={'white'}
                  onClick={() => {
                    setList(list.filter((s, i) => i !== index))
                  }}
                />
              </div>
              <hr />
              <Row style={{ marginTop: 10 }}>
                {l.categorization &&
                  l.categorization.map(p => (
                    <div className={'user-tag warehouse-t ml-2'}>
                      <label className={'label-user-tag'}>{p.name}</label>
                      <ButtonIcon
                        className={'delete-user-tag d-warehouse-t'}
                        icon={faTimes}
                        tooltip={'Quitar'}
                        color={'white'}
                        onClick={() => {
                          l.categorization = l.categorization.filter(d => d.id !== p.id)
                          setList([...list])
                        }}
                      />
                    </div>
                  ))}
                <Button
                  style={{ marginLeft: 10 }}
                  color={'primary'}
                  onClick={() => {
                    setCategories({ show: true, index, assign: [] })
                  }}
                >
                  Enlazar Categorías
                </Button>
              </Row>
              <hr />
              <Row>
                {l.permission &&
                  l.permission.map(p => (
                    <div className={'user-tag user-t ml-2'}>
                      <label className={'label-user-tag'}>{p.name}</label>
                      <ButtonIcon
                        className={'delete-user-tag d-user-t'}
                        icon={faTimes}
                        tooltip={'Quitar'}
                        color={'white'}
                        onClick={() => {
                          l.permission = l.permission.filter(d => d.id !== p.id)
                          setList([...list])
                        }}
                      />
                    </div>
                  ))}
                <Button
                  style={{ marginLeft: 10 }}
                  color={'primary'}
                  onClick={() => {
                    setGroup({ show: true, index, assign: [] })
                  }}
                >
                  Enlazar Permisos
                </Button>
              </Row>
              <hr />
              <Row>
                <Col>
                  <FormTextField
                    required
                    label={'Título'}
                    type={'text'}
                    value={l.title}
                    onChange={event => {
                      list[index].title = event.target.value
                      if (!list[index].videos.length)
                        list[index].videos = [{ urls: [{}] }]
                      list[index].videos[0].title = event.target.value

                      setList([...list])
                    }}
                    placeholder={'Título de la sección'}
                  />
                  <ReactQuill
                    theme="snow"
                    value={l.description}
                    onChange={value => {
                      list[index].description = value
                      if (!list[index].videos.length)
                        list[index].videos = [{ urls: [{}] }]
                      list[index].videos[0].description = value

                      setList([...list])
                    }}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 30 }}>
                <Col>
                  <InputGroup className="mb-3">
                    <FilePicker
                      maxSize={250}
                      extensions={['mp4']}
                      onChange={file => upload(index, file)}
                      onError={errMsg => {
                        setShowErrorVideo(true)
                      }}
                    >
                      <div>
                        <Button disabled={uploadIsLoading}>
                          <FontAwesomeIcon icon={faUpload} />
                        </Button>
                        {uploadIsLoading ? (
                          <ProgressBar
                            variant="success"
                            style={{ margin: 5 }}
                            now={uploadProgress}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </FilePicker>
                    <FormControl
                      aria-label="V."
                      aria-describedby="basic-addon1"
                      placeholder={'URL'}
                      value={
                        l.videos.length && l.videos[0].urls.length
                          ? l.videos[0].urls[0].url
                          : ''
                      }
                      onChange={({ target }) => {
                        if (!list[index].videos.length)
                          list[index].videos = [{ urls: [{}] }]
                        list[index].videos[0].urls[0].url = target.value

                        setList([...list])
                      }}
                      onBlur={() => {
                        if (!list[index].videos.length)
                          list[index].videos = [{ urls: [{}] }]

                        const url = list[index].videos[0].urls[0].url
                        if (!url) return

                        const isValidUrl =
                          url.startsWith('http://') || url.startsWith('https://')
                        list[index].videos[0].urls[0].url = isValidUrl
                          ? url
                          : `https://${url}`

                        setList([...list])
                      }}
                      required={true}
                    />
                    <FormControl
                      aria-describedby="basic-addon1"
                      placeholder={'Descripción'}
                      value={
                        l.videos.length && l.videos[0].urls.length
                          ? l.videos[0].urls[0].descriptions
                          : ''
                      }
                      maxlength="500"
                      onChange={({ target }) => {
                        if (!list[index].videos.length)
                          list[index].videos = [{ urls: [{}] }]
                        list[index].videos[0].urls[0].description = target.value

                        setList([...list])
                      }}
                      required={true}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Card>
            <hr style={{ border: '1px solid lightgray' }} />
          </div>
        ))}
        <Row style={{ marginTop: 10 }}>
          <Col>
            <IconButton
              style={{ textAlign: 'center' }}
              icon={faPlusCircle}
              tooltip={'Agregar sección'}
              size={'2x'}
              color={'green'}
              onClick={() => {
                list.push({
                  title: '',
                  description: '',
                  categorization: [],
                  permission: [],
                  videos: [],
                })
                setList([...list])
              }}
            />
          </Col>
        </Row>
        <div className={'d-flex'} style={{ justifyContent: 'flex-end' }}>
          {!modal && renderButton()}
        </div>
      </div>
    )
  }

  const renderButton = () => {
    return (
      <Button
        disabled={
          !version.versionName ||
          !version.description ||
          newVersionIsLoading ||
          list.filter(f => !(f.title || f.title !== '') || !f.description).length > 0
        }
        loading={newVersionIsLoading}
        onClick={() => saveVersion()}
      >
        <strong>{newVersionIsLoading ? 'Guardando' : 'Crear'}</strong>
      </Button>
    )
  }

  return (
    <>
      {modal ? (
        <Modal
          centered
          show={show && !categories.show}
          onHide={() => onHide()}
          size={'xl'}
        >
          <Modal.Header closeButton>
            <Modal.Title>Nueva Versión</Modal.Title>
          </Modal.Header>
          <Modal.Body>{renderContent()}</Modal.Body>
          <Modal.Footer>{renderButton()}</Modal.Footer>
        </Modal>
      ) : (
        <div>
          <Title title={'Nueva versión'} />
          {renderContent()}
        </div>
      )}
      <Folder
        onHide={() => setCategories({ show: false, index: 0, assign: [] })}
        onAssign={item => {
          setAlert({
            ...handlerSuccess('Categoría enlazada'),
            onConfirm: () => setAlert({ show: false }),
          })
          const array = list[categories.index].categorization || []

          list[categories.index].categorization = [...array, item]
          if (!list[categories.index].videos.length)
            list[categories.index].videos = [{ urls: [{}] }]
          list[categories.index].videos[0].urls[0].categories = [...array, item.id]

          setList([...list])
        }}
        data1={
          categorizations && categorizations.children ? categorizations.children[0] : {}
        }
        show={categories.show}
        noMessage
        type={categoryType.VIDEO}
      />
      <PermissionGroup
        roles={roles}
        show={group.show}
        select
        onAssign={item => {
          setAlert({
            ...handlerSuccess('Permiso enlazado'),
            onConfirm: () => setAlert({ show: false }),
          })
          const array = list[group.index].permission || []
          list[group.index].permission = [...array, item.id]
          setList([...list])
        }}
        onHide={() => setGroup({ show: false, index: 0, assign: [] })}
      />
      <Alert {...alert} />
    </>
  )
}

export default CreateInformation
