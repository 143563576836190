import React, { Component } from 'react'
import './LoginInput.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'

export default class LoginInput extends Component {
  validate = () => {
    const { value, validator } = this.props

    if (validator) {
      validator(value)
    }
  }

  render() {
    const { value, onChange, error, placeholder, type, name, id, icon, submit } =
      this.props

    return (
      <div className={'input-wrapper has-feedback mt-3'}>
        <input
          type={type}
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          className={'input form-control' + (error ? ' has-error' : '')}
          placeholder={placeholder}
          required={''}
          onKeyPress={e => (e.key === 'Enter' ? submit() : false)}
        />
        <span className="input-focus" />
        <span className={`input-symbol ${error ? 'has-error' : ''}`}>
          <FontAwesomeIcon icon={icon} />
        </span>
        {error ? <div className="input-error">{error}</div> : <div />}
      </div>
    )
  }
}
