import { actionTypes } from '../actions/logs.actions'

const initialState = {
  logsProducts: null,
  logsCompanies: null,
  currentCompany: null,
  logsWarehouses: null,
  logsShopping: null,
  generated: false,
}

const LogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LOG_PRODUCTS_SUCCESS:
      return {
        ...state,
        logsProducts: action.logs,
      }
    case actionTypes.GET_LOG_COMPANIES_SUCCESS:
      return {
        ...state,
        logsCompanies: action.logs,
      }
    case actionTypes.CURRENT_COMPANY_SUCCESS:
      return {
        ...state,
        currentCompany: action.logs,
      }
    case actionTypes.GET_LOG_WAREHOUSES_SUCCESS:
      return {
        ...state,
        logsWarehouses: action.logs,
      }
    case actionTypes.GET_LOG_SHOPPING_SUCCESS:
      return {
        ...state,
        logsShopping: action.logs,
      }
    case actionTypes.PDF_GENERATED_TRUE:
      return {
        ...state,
        generated: true,
      }
    case actionTypes.PDF_GENERATED_FALSE:
      return {
        logsProducts: null,
        logsCompanies: null,
        currentCompany: null,
        logsShopping: null,
        logsWarehouses: null,
        generated: false,
      }
    default:
      return state ? state : initialState
  }
}

export default LogsReducer
