import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Col, Modal, Row } from 'react-bootstrap'
import Alert from 'sweetalert-react'

import { Button, CustomDate, FormTextField, Select } from '../index'

import { selectGetAccountsForSelect } from 'src/selectors/banks.selector'
import {
  actionTypes as typeT,
  createBankOperation,
  getAccounts,
} from 'src/actions/banks.actions'

import { faMoneyBillWave, faSave } from '@fortawesome/free-solid-svg-icons'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'
import { selectCurrentCurrency } from 'src/selectors/currencies.selector'
import { formatNumberWithCommas } from 'src/utils/formatters'
import { getAllCurrencies } from 'src/actions/currencies.actions'

const Operation = props => {
  const dispatch = useDispatch()
  const accounts = useSelector(selectGetAccountsForSelect)

  const [actions, setActions] = useState({ movement: false })
  const [alertForm, setAlertForm] = useState({
    title: '',
    text: '',
    type: 'success',
    show: false,
  })
  const [show, setShow] = useState(false)
  const [movementRequest, setMovementRequest] = useState({})

  const allCurrencies = useSelector(state =>
    Object.assign(
      [],
      state.currencies.allCurrencies.map(c => ({ ...c, label: c.code, value: c.id })),
    ),
  )
  const [currency, setCurrency] = useState(1)
  const currencyItem = allCurrencies.find(c => c.id === currency) || {}

  const loadingOp = useSelector(state => loadingSelector([typeT.CREATE_OPERATION])(state))
  const hasErrorOp = useSelector(state =>
    hasErrorsSelector([typeT.CREATE_OPERATION])(state),
  )
  const errorOp = useSelector(state =>
    singleErrorSelector([typeT.CREATE_OPERATION])(state),
  )
  const currentCurrency = useSelector(selectCurrentCurrency)

  useEffect(() => {
    if (!props.show) return

    setShow(props.show)
  }, [props.show])

  useEffect(() => {
    if (!show) return

    if (props.deposit || props.purchase) {
      setMovementRequest({ ...movementRequest, amount: props.amount })
    }

    dispatch(getAccounts(false))
    dispatch(getAllCurrencies())
  }, [show])

  useEffect(() => {
    if (!show) return
    if (loadingOp) setActions({ ...actions, movement: true })
    else if (actions.movement) {
      setActions({ ...actions, movement: false })
      if (hasErrorOp)
        setAlertForm({
          ...handlerError(errorOp.message),
          onConfirm: () => setAlertForm({ ...alertForm, show: false }),
        })
      else
        setAlertForm({
          ...handlerSuccess('Operación bancaria agregada satisfactoriamente'),
          onConfirm: () => {
            setAlertForm({ ...alertForm, show: false })
            setShow(false)
            setMovementRequest({})
            props.onHide(true)
            if (props.refresh) props.refresh()
          },
        })
    }
  }, [loadingOp])

  const getAccountsItemsSelector = () => {
    let accountsSelector
    accountsSelector = accounts.map(item => {
      return {
        ...item,
        value: item.id,
        label: `${item.name} - No. ${item.accountNumber} (${
          item.currency === 1 ? currentCurrency.symbol : '$'
        })`,
      }
    })
    return accountsSelector
  }

  return (
    <div>
      {props.button && (
        <Button icon={faMoneyBillWave} onClick={() => setShow(true)}>
          Crear
        </Button>
      )}

      <Modal
        show={show}
        size={'md'}
        centered
        onHide={() => {
          setShow(false)
          props.onHide()
          setMovementRequest({})
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Operación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <b>
                Cuando agregas una transacción bancaria estás indicando que has visto una
                transacción en el estado de cuenta bancario. Esta transacción no será
                acreditada a ningún usuario a menos que un administrador así lo autorice.
              </b>
              <Select
                label={'Seleccione cuenta a debitar'}
                options={getAccountsItemsSelector()}
                value={movementRequest.bankAccount}
                onChange={e => {
                  setCurrency(e.currency)
                  setMovementRequest({ ...movementRequest, bankAccount: e })
                }}
                required
                subText={
                  movementRequest.bankAccount
                    ? `${currencyItem.symbol}.${formatNumberWithCommas(
                        movementRequest.bankAccount.balance,
                      )}`
                    : null
                }
              />
              <CustomDate
                label={'Fecha de registro '}
                required
                value={movementRequest.date}
                onDayChange={e => {
                  setMovementRequest({ ...movementRequest, date: e })
                }}
              />
              <FormTextField
                label={`No. documento`}
                type={'text'}
                id={'ref'}
                placeholder={'pj. 1535455'}
                name={'ref'}
                value={movementRequest.reference}
                onChange={e => {
                  setMovementRequest({
                    ...movementRequest,
                    reference: e.target.value,
                  })
                }}
                required
              />
              <FormTextField
                label={'Monto'}
                type={'number'}
                id={'amount'}
                placeholder={'0.00'}
                disabled={props.deposit || props.purchase}
                name={'amount'}
                value={movementRequest.amount}
                onChange={e => {
                  setMovementRequest({
                    ...movementRequest,
                    amount: e.target.value,
                  })
                }}
                error={''}
                inputClassName={'credit'}
                prepend={currency === 1 ? currentCurrency.symbol : '$'}
                required
              />

              <FormTextField
                label={'Descripción'}
                type={'text'}
                id={'desc'}
                name={'desc'}
                value={movementRequest.description}
                onChange={e => {
                  setMovementRequest({
                    ...movementRequest,
                    description: e.target.value,
                  })
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {props.purchase && (
            <Button
              color={'secondary'}
              loading={loadingOp}
              onClick={() => {
                setShow(false)
                props.onHide()
                setMovementRequest({})
              }}>
              Cerrar
            </Button>
          )}
          <Button
            icon={faSave}
            loading={loadingOp}
            disabled={
              !movementRequest.bankAccount ||
              !movementRequest.reference ||
              !movementRequest.date ||
              movementRequest.amount <= 0
            }
            onClick={() => {
              if (props.purchase) {
                let request = {
                  ...movementRequest,
                  registerDate: movementRequest.date.valueOf(),
                  bankAccount: movementRequest.bankAccount.value,
                  currency: 1,
                  operationType: 105,
                }
                props.onSave(request)
              } else {
                let request = {
                  ...movementRequest,
                  registerDate: movementRequest.date.valueOf(),
                  bankAccount: movementRequest.bankAccount.value,
                  currency: 1,
                  operationType: props.card || props.deposit ? 101 : 102,
                }
                dispatch(createBankOperation(request, { duplicate: false }))
              }
            }}>
            Crear
          </Button>
        </Modal.Footer>
      </Modal>

      <Alert {...alertForm} />
    </div>
  )
}
export default Operation
