import React, {Component} from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CollapsibleCard from "../../../../components/cards/collapsible-card/CollapsibleCard";
import Card from "../../../../components/cards/Card";
import {
    actionTypes,
    createDeposit, getCompanyDeposits,
    getDeposits,
    getDepositsAdmin,
    updateDeposit
} from "../../../../actions/deposits.actions";
import {loadingSelector} from "../../../../selectors/loading.selector";
import {selectGetAdminDeposits, selectGetDeposits} from "../../../../selectors/deposits.selector";
import {connect} from "react-redux";
import {Modal} from "react-bootstrap";
import FormTextField from "../../../../components/inputs/FormTextField/FormTextField";
import CustomSelect from "../../../../components/inputs/Select/CustomSelect";
import Button from "../../../../components/buttons/Button";
import SweetAlert from "sweetalert-react";
import {selectCurrentModule} from "../../../../selectors/user.selector";
import {getAccounts, getBanks} from "../../../../actions/banks.actions";
import {selectGetAccounts, selectGetBanks} from "../../../../selectors/banks.selector";
import {TableV2, Title} from "../../../../components";
import { selectCurrentCurrency } from '../../../../selectors/currencies.selector';
import IconButton from '../../../../components/buttons/IconButton';
import {faEye} from "@fortawesome/free-solid-svg-icons";

class DepositsAdmin extends Component {

    state = {
        banks:[{value:0,label:"Banco Prueba"}],
        alertForm:{
            title:"",
            text:"",
            type: "success",
            show: false
        },
        approved:false,
        submitted:true,
        rejected:false,
        showDetail:false,
        selectedDeposit:{},
        states:[
            {value:100,label:"Ingresada"},
            {value:101,label:"Aprobada"},
            {value:102,label:"Rechazada"},
        ],
        selectedState:{value:100,label:"Ingresada"},
        message:''
    };

    componentDidMount(){
        const {module} = this.props;
        this.props.getBanks();
        if(module===11000)
            this.props.getDepositsAdmin();
        else
            this.props.getCompanyDeposits(module);
        this.props.getAccounts();
    };

    componentWillReceiveProps(next) {
        const {updateIsLoading, module} = this.props;
        if (updateIsLoading && !next.updateIsLoading) {
            console.log("Deposit Updated");
            const{alertForm} = this.state;
            alertForm.title = 'Deposito Actualizado';
            alertForm.text = `El deposito ha sido Actualizado `;
            alertForm.type = 'success';
            alertForm.show = true;
            this.setState({alertForm:alertForm});
            if(module===11000)
                this.props.getDepositsAdmin();
            else
                this.props.getCompanyDeposits(module);
        }
    }

    filter = item =>{
        const {approved, submitted, rejected} = this.state;
        const today = new Date().getTime();
        return ( (approved && item.status==101) || (submitted && item.status==100) || (rejected && item.status==102));
    };

    renderHeaders = () => {
        const {module} = this.props
        return [
            {label: 'Fecha', show: true, value: ['createdAt'], type: 'date' ,  className:'mini'},
            {label: 'Banco', show: true, value: ['bankC'], type: 'text' ,  className:'mini',
                custom:(item)=> item.bankC =  module === 11000 ? this.getBank(item.bank) :this.getAccountName(item.bank) },
            {label: 'Numero', show: true, value: ['number'], type: 'wholeNumber' ,  className:'mini'},
            {label: 'Cantidad', show: true, value: ['amount'], type: 'number' ,  className:'mini'},
            {label: 'Estado', show: true, value: ['statusC'], type: 'text' ,  className:'mini', custom:(item)=> item.statusC=this.getState(item.status)},
            {label: 'Creado por', show: true, value: ['user'], type: 'text' ,  className:'mini'},
            {label: 'Autorizado por', show: true, value: ['auth'], type: 'text' ,  className:'mini'},
            {label: 'Referencia', show: true, value: ['amount'], type: 'text' ,  className:'mini', action:true, select:true,
            custom:(item)=> <IconButton tooltip={'Ver Referencia'} icon={faEye} onClick={(e)=>this.setState({showDetail: true,selectedDeposit:item})} color={'gray'}/>},
            {config:true, show: true, label:'', className:'mini center'}

        ]
    }


    getAccountName = (number) =>{
        const {accounts} = this.props
        let a = accounts.find(a => a.id === number)
        return a ? a.name : 'Sin nombre'
    }

    getState(state)
    {
        switch (state) {
            case 100:
                return "Ingresada";
            case 101:
                return "Aprobada";
            case 102:
                return "Rechazada";
            default:
                return "-----";
        }
    }

    getBank(id)
    {
        const {globalBanks} = this.props;
        if(id==0)
            return 'Banco de Pruebas';
        let found = globalBanks.find(f=>f.id===id);
        return found?found.name:id+'';
    }

    handleCheckBoxChange = (event) => {
        const {name, checked} = event.target;
        this.setState({[name]: checked, page: 1});
    };

    statusChanged = value => {
        this.setState({selectedState: value});
    };

    handleInputChange = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: value});
    };

    updateDeposit = () =>{
        const{selectedState,message,selectedDeposit} = this.state;
        this.props.updateDeposit(selectedDeposit.id,selectedState.value,message);
        this.setState({showDetail:false,selectedState:{},message: '',selectedDeposit: {}});
    };

    render() {
        const {deposits,depositsIsLoading, companyDeposits, module, currentCurrency} = this.props;
        const {alertForm,approved,submitted,rejected,showDetail,states,selectedState,message} = this.state;
        return (
            <div>
                <Title title={'Inventario'}/>
                <Row>
                    <Col lg={2}>
                        <CollapsibleCard title={'Filtros'}>
                            <Row>
                                <Col>
                                    <label className={'checkbox-label'}><input type="checkbox" name="approved" checked={approved} onChange={this.handleCheckBoxChange}/> Aprobadas</label>
                                </Col>
                                <Col>
                                    <label className={'checkbox-label'}><input type="checkbox" name="submitted" checked={submitted} onChange={this.handleCheckBoxChange}/> Ingresadas</label>
                                </Col>
                                <Col>
                                    <label className={'checkbox-label'}><input type="checkbox" name="rejected" checked={rejected} onChange={this.handleCheckBoxChange}/> Rechazadas</label>
                                </Col>
                            </Row>
                        </CollapsibleCard>
                    </Col>
                    <Col lg={10}>
                        <Card title={'Depositos ingresados'} white>
                            <TableV2
                                headers={this.renderHeaders()}
                                items={
                                    module === 11000 ?
                                        deposits.filter((x) => !x.own).filter(this.filter).sort(function (a, b) { return (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0); })
                                        :
                                        companyDeposits.filter(this.filter).sort(function (a, b) { return (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0); })
                                }
                                loading={depositsIsLoading}
                                noItemsLegend={'Sin depositos registrados'}
                                mobileAuto
                                storageKey={`depositsAdmin`}
                                onClickTr = {(e,item)=> this.setState({showDetail: true,selectedDeposit:item})}
                            />

                        </Card>
                    </Col>
                </Row>

                <Modal
                    show={showDetail}
                    size={'lg'}
                    centered
                    onHide={() => this.setState({showDetail:false})}
                  >  <Modal.Header closeButton>
                        <Modal.Title>Deposito</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Row>
                                <Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <CustomSelect
                                        label={'Estado'}
                                        options={[...states]}
                                        value={selectedState}
                                        onChange={this.statusChanged}
                                    />
                                </Col>
                                <Col>
                                    <FormTextField
                                        label={"Mensaje"}
                                        type={"text"}
                                        id={"message"}
                                        placeholder={"Mensaje"}
                                        name={"message"}
                                        value={message}
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Row className={'container-buttons'}>
                            <Button
                                onClick={() => this.setState({showDetail:false,selectedDeposit:{}})}
                              >  Cerrar
                            </Button>
                            <Button
                                onClick={() => this.updateDeposit()}
                            >
                                Guardar
                            </Button>
                        </Row>
                    </Modal.Footer>

                </Modal>

                <SweetAlert
                    show={alertForm.show}
                    title={alertForm.title}
                    text={alertForm.text}
                    type={alertForm.type}
                    onConfirm={() => {
                        const{alertForm} = this.state;
                        alertForm.show = false;
                        this.setState({alertForm: alertForm});
                    }}
                />

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    depositsIsLoading:loadingSelector([actionTypes.GET_DEPOSITS_ADMIN])(state),
    updateIsLoading: loadingSelector([actionTypes.UPDATE_DEPOSIT])(state),
    deposits:selectGetAdminDeposits(state),
    companyDeposits: selectGetDeposits(state),
    module: selectCurrentModule(state),
    accounts: selectGetAccounts(state),
    globalBanks: selectGetBanks(state),
    currentCurrency: selectCurrentCurrency(state)
});

const mapDispatchToProps = dispatch => ({
    getDepositsAdmin: ()=> dispatch(getDepositsAdmin()),
    updateDeposit: (depositId,status,message)=> dispatch(updateDeposit(depositId,status,message)),
    getCompanyDeposits: (module) => dispatch(getCompanyDeposits(module)),
    getAccounts: () => dispatch(getAccounts()),
    getBanks: () => dispatch(getBanks()),
});

export default connect(mapStateToProps,mapDispatchToProps)(DepositsAdmin);
