import { $http } from './http'

class CategorizationService {
  getCategorizationsByCompany = async () => {
    const response = await $http.get(`/categorizations`)
    return response.data
  }

  getCategorizationsByCompanyWithRoots = async () => {
    const response = await $http.get(`/categorizations/root/categorizations`)
    return response.data
  }

  getCategorizationsByCompanyAndParent = async parentId => {
    const response = await $http.get(`/categorizations/parent/${parentId}`)
    return response.data
  }

  getCategorizationsByProduct = async productId => {
    const response = await $http.get(`/categorizations/product/${productId}`)
    return response.data
  }

  getRootOrganizationsByCompany = async () => {
    const response = await $http.get(`/categorizations/allRoots`)
    return response.data
  }

  getCategorizationById = async categorizationId => {
    const response = await $http.get(`/categorizations/${categorizationId}`)
    return response.data
  }

  createCategorization = async (categorization, params) => {
    const response = await $http.post(`/categorizations`, categorization, {
      params,
    })
    return response.data
  }

  updateCategorization = async (categorizationId, categorization, params) => {
    const response = await $http.put(
      `/categorizations/${categorizationId}`,
      categorization,
      { params },
    )
    return response.data
  }

  deleteCategorization = async categorizationId => {
    const response = await $http.delete(`/categorizations/${categorizationId}`)
    return response.data
  }

  assignCustomEntity = async (
    categorizationId,
    entityId,
    remove,
    log,
    type,
    companyId,
  ) => {
    const response = await $http.put(
      `categorizations/${entityId}/${categorizationId}`,
      [],
      { params: { remove, log, type, companyId } },
    )
    return response.data
  }

  assignEntity = async (api, categorizationId, entityId, remove, log) => {
    const response = await $http.put(
      `/${api}/categorization/${entityId}/${categorizationId}`,
      [],
      { params: { remove, log } },
    )
    return response.data
  }

  removeEntity = async (api, categorizationId, entityId) => {
    const response = await $http.delete(
      `/${api}/categorization/${entityId}/${categorizationId}`,
    )
    return response.data
  }

  getCategorizationsByEntity = async (api, entity, log) => {
    const response = await $http.get(`/${api}/categorization/${entity}`, {
      params: { log },
    })
    return response.data
  }

  getCustomCategorizationsByEntity = async (entity, log, type) => {
    const response = await $http.get(`/categorizations/custom/${entity}`, {
      params: { log, type },
    })
    return response.data
  }

  getAllTypes = async () => {
    const response = await $http.get(`/categorizations/type`)
    return response.data
  }

  getPath = async id => {
    const response = await $http.get(`/categorizations/children/${id}`)
    return response.data
  }

  getCategorizationByType = async type => {
    const response = await $http.get(`/categorizations/products/${type}`)
    return response.data
  }

  copyByTree = async (request, parent) => {
    const response = await $http.post(`/categorizations/copy/tree/${parent}`, request)
    return response.data
  }

  copyByList = async (request, parent) => {
    const response = await $http.post(`/categorizations/copy/list/${parent}`, request)
    return response.data
  }

  moveByList = async (request, parent) => {
    const response = await $http.post(`/categorizations/move/list/${parent}`, request)
    return response.data
  }

  getCategorizationsWithPathByProduct = async productId => {
    const response = await $http.get(`/categorizations/children/assigned/${productId}`)
    return response.data
  }

  getProducts = async id => {
    const response = await $http.get(`/categorizations/products/categorization/${id}`)
    return response.data
  }

  getPublicRoots = async () => {
    const response = await $http.get(`/categorizations/root/public`)
    return response.data
  }

  getAllCategorizations = async type => {
    const response = await $http.get(`/categorizations/all/${type}`)
    return response.data
  }

  getAllPublicCategorizations = async (type, params) => {
    const response = await $http.get(`/nscategorization/all/${type}`, {
      params,
    })
    return response.data
  }

  getCategorizationWithProductsByRoot = async root => {
    const response = await $http.get(`/categorizations/root/${root}/products`)
    return response.data
  }

  copyProductsByRoot = async root => {
    const response = await $http.put(`/categorizations/product/copy/${root}`)
    return response.data
  }
}

export default new CategorizationService()
