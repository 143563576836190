import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import Card from 'src/components/cards/Card'
import FormTextField from 'src/components/inputs/FormTextField/FormTextField'
import CashBox from 'src/assets/images/svg/cashbox.svg'
import './RequestDocuments.scss'
import Button from 'src/components/buttons/Button'
import { actionTypes, createDocumentRequest } from 'src/actions/resolutions.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import { hasErrorsSelector, singleErrorSelector } from 'src/selectors/error.selector'
import { history } from '../../../App'
import SweetAlert from 'sweetalert-react'

class CreateDocumentRequest extends Component {
  state = {
    contactName: '',
    contactPhone: '',
    contactEmail: '',

    signInName: '',
    signInPhone: '',
    signInJob: '',

    errors: {},
    showError: false,
    showSuccess: false,
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { createIsLoading, createHasError } = this.props

    if (createIsLoading && !next.createIsLoading) {
      if (!createHasError && next.createHasError) {
        this.setState({ showError: true, showSuccess: false })
      } else {
        this.setState({ showError: false, showSuccess: true })
      }
    }
  }

  handleInputChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  save = () => {
    const {
      contactName,
      contactPhone,
      contactEmail,
      signInName,
      signInJob,
      signInPhone,
    } = this.state

    const errors = {}
    if (!contactName) {
      errors.contactName = 'Campo Obligatorio'
    }
    if (!contactPhone) {
      errors.contactPhone = 'Campo Obligatorio'
    }
    if (!contactEmail) {
      errors.contactEmail = 'Campo Obligatorio'
    }
    if (!signInName) {
      errors.signInName = 'Campo Obligatorio'
    }
    if (!signInJob) {
      errors.signInJob = 'Campo Obligatorio'
    }
    if (!signInPhone) {
      errors.signInPhone = 'Campo Obligatorio'
    }

    this.setState({ errors: errors })
    if (Object.keys(errors).length === 0) {
      this.props.createDocumentRequest(
        contactName,
        contactPhone,
        contactEmail,
        signInName,
        signInPhone,
        signInJob,
      )
    }
  }

  closeSuccess = () => {
    this.setState({ showSuccess: false }, () => setTimeout(history.goBack, 500))
  }

  render() {
    const {
      contactName,
      contactPhone,
      contactEmail,
      signInName,
      signInJob,
      signInPhone,
      errors,
      showError,
      showSuccess,
    } = this.state
    const { createIsLoading, createError } = this.props
    return (
      <div>
        <h1 className={'dashboard-welcome'}>Solicitud de documentos</h1>
        <Row>
          <Col md={4}>
            <Card ribbon={'Nueva'}>
              <img src={CashBox} alt="CashBox Icon" className={'request-cashBox-image'} />
              <Row style={{ justifyContent: 'center', marginTop: 25 }}>
                <span className={'cashBox-name'}>Habilitar maquina registradora</span>
              </Row>
              <div className={'document-request-instructions justify'}>
                Llene este formulario para solicitar la documentación necesaria para
                habilitar la impresión de facturas por medio de la plataforma de Cotizap.
              </div>
            </Card>
          </Col>
          <Col>
            <Card title={'Documentación para maquina registradora'}>
              <div className={'document-request-instructions'}>
                Ingrese un dato de contacto, esta persona deberá estar disponible para
                atender las dudas que la SAT tenga sobre ustedes.
                <br />
                Los valores serán impresos tal cual sean ingresados en este formulario.
              </div>
              <Row>
                <Col>
                  <FormTextField
                    label={'Nombre de Contacto'}
                    type={'text'}
                    id={'contactName'}
                    placeholder={'Nombre'}
                    name={'contactName'}
                    value={contactName}
                    onChange={this.handleInputChange}
                    error={errors.contactName || createError.data.contactName}
                  />
                </Col>
                <Col>
                  <FormTextField
                    label={'Teléfono de Contacto'}
                    type={'text'}
                    id={'contactPhone'}
                    placeholder={'1234-1234'}
                    name={'contactPhone'}
                    value={contactPhone}
                    onChange={this.handleInputChange}
                    error={errors.contactPhone || createError.data.contactPhone}
                  />
                </Col>
                <Col>
                  <FormTextField
                    label={'Email de Contacto'}
                    type={'email'}
                    id={'contactEmail'}
                    placeholder={'abc@email.com'}
                    name={'contactEmail'}
                    value={contactEmail}
                    onChange={this.handleInputChange}
                    error={errors.contactEmail || createError.data.contactEmail}
                  />
                </Col>
              </Row>

              <div className={'document-request-instructions'}>
                Ingrese los datos de la persona que firmará los documentos generados que
                serán presentados ante la SAT.
                <br />
                Los valores serán impresos tal cual sean ingresados en este formulario.
              </div>
              <Row>
                <Col>
                  <FormTextField
                    label={'Nombre'}
                    type={'text'}
                    id={'signInName'}
                    placeholder={'Nombre'}
                    name={'signInName'}
                    value={signInName}
                    onChange={this.handleInputChange}
                    error={errors.signInName || createError.data.signInName}
                  />
                </Col>
                <Col>
                  <FormTextField
                    label={'Teléfono'}
                    type={'text'}
                    id={'signInPhone'}
                    placeholder={'1234-1234'}
                    name={'signInPhone'}
                    value={signInPhone}
                    onChange={this.handleInputChange}
                    error={errors.signInPhone || createError.data.signInPhone}
                  />
                </Col>
                <Col>
                  <FormTextField
                    label={'Puesto'}
                    type={'email'}
                    id={'signInJob'}
                    placeholder={'Ej. Representante Legal'}
                    name={'signInJob'}
                    value={signInJob}
                    onChange={this.handleInputChange}
                    error={errors.signInJob || createError.data.signInJob}
                  />
                </Col>
              </Row>
              <br />
              <div style={{ textAlign: 'right' }}>
                <Button variant={'success'} loading={createIsLoading} onClick={this.save}>
                  Solicitar Documentos
                </Button>
              </div>
            </Card>
          </Col>
        </Row>

        <SweetAlert
          show={showSuccess}
          title={'!Exito!'}
          text={'Solicitud enviada exitosamente.'}
          type={'success'}
          onConfirm={this.closeSuccess}
        />
        <SweetAlert
          show={showError}
          title={'¡Uh-Oh!'}
          text={
            createError.message || 'Error, por favor revise e intente de nuevo más tarde.'
          }
          type={'error'}
          onConfirm={() => this.setState({ showError: false })}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  createIsLoading: loadingSelector([actionTypes.CREATE_DOCUMENT_REQUEST])(state),
  createHasError: hasErrorsSelector([actionTypes.CREATE_DOCUMENT_REQUEST])(state),
  createError: singleErrorSelector([actionTypes.CREATE_DOCUMENT_REQUEST])(state),
})

const mapDispatchToProps = dispatch => ({
  createDocumentRequest: (
    contactName,
    contactPhone,
    contactEmail,
    signInName,
    signInPhone,
    sigInJob,
  ) =>
    dispatch(
      createDocumentRequest(
        contactName,
        contactPhone,
        contactEmail,
        signInName,
        signInPhone,
        sigInJob,
      ),
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateDocumentRequest)
