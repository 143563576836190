import { $http } from './http'
import { saveAs } from 'file-saver'

class InvoiceService {
  getItemsToCreditNote = async (orderId: number) => {
    const response = await $http.get(`invoices/${orderId}/to/credit-note`)
    return response.data
  }

  onCreateCreditNoteBySells = async request => {
    const response = await $http.post(`invoices/credit-note`, request)
    return response.data
  }

  getInvoiceDealers = async request => {
    console.log(request)
    const response = await $http.get(`invoices/dealers/fel`, { params: request })
    return response.data
  }

  getInvoiceIssuedByDealer = async request => {
    const response = await $http.get(`invoices/dealers/fel/issued`, { params: request })
    return response.data
  }

  getInvoiceIssuedByDealerExcel = async request => {
    const response = await $http.get('invoices/dealers/fel/issued/excel', {
      params: request,
      responseType: 'blob',
    })

    const fileName = 'Distribuidores FEL.xlsx'

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    saveAs(file, fileName.replace('json', 'xlsx'))
  }

  getPrintInstructionsByDocumentData = async (
    documentId: number,
    documentType: number,
  ) => {
    const response = await $http.get(
      `invoices/ticket/${documentId}/instructions/${documentType}`,
    )
    return response.data
  }
}
export default new InvoiceService()
