export const chartOptions = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        offset: true,
        ticks: {
          beginAtZero: true,
          callback: function (label) {
            return label > 999999
              ? label / 100000000 + 'M'
              : label > 999
              ? label / 1000 + 'k'
              : label
          },
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || ''
        if (label) {
          label += ': '
        }
        label += tooltipItem.yLabel + ' unidades'
        return label
      },
    },
  },
  plugins: {
    labels: [],
  },
  legend: {
    display: false,
  },
}

export const chartOptionsMobile = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        offset: true,
        ticks: {
          beginAtZero: true,
          callback: function (label) {
            return label > 999999
              ? label / 100000000 + 'M'
              : label > 999
              ? label / 1000 + 'k'
              : label
          },
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || ''
        if (label) {
          label += ': '
        }
        label += tooltipItem.yLabel + ' unidades'
        return label
      },
    },
  },
  plugins: {
    labels: [],
  },
  legend: {
    display: false,
  },
}
