import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";

import {Row, Col} from "react-bootstrap";
import Alert from "sweetalert-react";

import Dropdown from "../../components/buttons/Dropdown";
import Card from "../../components/cards/Card";
import Icon from "../../components/buttons/IconButton";

import {types, getAllByCompany, getTypes} from "../../actions/parcel.actions";
import {selectParcels, selectParcelTypes} from "../../selectors/parcel.selector";

import {isAllowed} from "../../selectors/modules.selector";

import {loadingSelector} from "../../selectors/loading.selector";

import {selectCurrentCompany, selectCurrentModule} from "../../selectors/user.selector";

import {faBox, faInfoCircle, faTruckMoving, faWarehouse} from "@fortawesome/free-solid-svg-icons";

import ParcelCEV from "./component/ParcelCEV";
import {formatNumberWithCommas} from "../../utils/formatters";
import {Action, TableV2, Title} from "../../components";
import Switch from "../../components/inputs/Switch/Switch";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { selectCurrentCurrency } from '../../selectors/currencies.selector';

const Parcel = () => {
    const dispatch = useDispatch();

    const gLoading = useSelector((state) => loadingSelector([types.GET_ALL_PARCEL_BY_COMPANY])(state));
    const parcels = useSelector(selectParcels);
    const typesResponse = useSelector(selectParcelTypes);
    const currentCurrency = useSelector(selectCurrentCurrency);

    const company = useSelector(selectCurrentCompany);
    const module = useSelector(selectCurrentModule);

    /*Permissions*/
    const create = useSelector((state) => isAllowed(state, [16001, 16051, 16101]));

    const [alert] = useState({title: ''});
    const [cev, setCev] = useState({type: 0, show: false, id: null});
    const [parcelTypes, setTypes] = useState([]);

    useEffect(() => {
        dispatch(getTypes());
        setUp();
    }, []);

    useEffect(() => {
        if (typesResponse.length > 0 && parcelTypes.length <= 0)
            setTypes(typesResponse.map((t) => ({...t, checked: true})));
    }, [typesResponse]);

    const setUp = () => {
        dispatch(getAllByCompany());
    };

    const filter = (item) => {
        const parcelType = parcelTypes.find((p) => p.id === item.type)

        return ((parcelType && parcelType.checked));
    };

    const getTypeProperty = (type) => {
        let color, icon;
        switch (type) {
            case 1:
                icon = faWarehouse;
                color = 'blue';
                break
            case 2:
                icon = faBox;
                color = 'pink';
                break
            case 3:
                icon = faTruckMoving;
                color = 'green';
                break
            default:
                icon = faInfoCircle;
                color = 'gray';
                break
        }

        return {color, icon};
    };

    const getHeader = (seeAmount) => {
        const header = ['', 'Tipo', 'Nombre', 'Alto', 'Ancho', 'Profundidad'];
        if (seeAmount)
            header.push(`Costo (${currentCurrency.symbol})`);
        header.push('');
        return header;
    };

    const getHeaders = (seeAmount) => {
        const headers = [
            {
                label: '', value: [], show: true, classNameCustom: (item) => 'mini center t-' + (getTypeProperty(item.type).color), select: true,
                custom: (item) => <Icon tooltip={item.parcelType.description} icon={getTypeProperty(item.type).icon}/>
            },
            { label: 'Tipo', show: true, value: ['parcelType','name'], type: 'text', className: 'mini' },
            { label: 'Nombre', show: true, value: ['name'], type: 'text', className: 'medium' },
            { label: 'Alto', show: true, value: ['high'], type: 'text', className: 'mini' , custom:(item)=> item.high + ' cm'},
            { label: 'Ancho', show: true, value: ['width'], type: 'text', className: 'mini',custom:(item)=>  item.width + ' cm'},
            { label: 'Profundidad', show: true, value: ['length'], type: 'text' ,  className:'mini',custom:(item)=> item.length + ' cm'}
        ]
        if(seeAmount){
            headers.push({label: 'Costo', show: true, value: ['amount'], type: 'currency' ,  className:'mini'})
        }
        headers.push({
            config: true, show: true, label: '', className: 'mini center', custom: (item) => <Dropdown
                items={[
                    { title: 'Ver', action: () => setCev({ show: true, type: 3, id: item.id }) },
                    { title: 'Actualizar', action: () => setCev({ show: true, type: 2, id: item.id }) }
                ]}
            />
        })
        return headers
    }

    const customFilter = () => {
        return <div className={'column'}>
            <br/>
            <h6>Por Tipo</h6>
            <Row>{parcelTypes.map((parcelType, i) => <Col
                key={i} md={2} xs={12}>
                <div className={'d-flex'}>
                    <Switch
                        checked={parcelType.checked}
                        id={"revision"}
                        label={parcelType.name}
                        info={parcelType.name === 'Interno' ? 'Paquete para uso de empresas':
                        parcelType.name === 'Kolo' ? 'Paquetes para entrega de Kolo':
                        'Paquetes para recoleccion del transportista'}
                        onChange={(event) => {
                            let {value} = event.target;
                            value = !(value === 'true');
                            parcelTypes[i].checked = value;
                            setTypes([...parcelTypes]);
                        }}/>
                </div>
            </Col>)}</Row>
        </div>
    };

    return (<div className={'column'}>
        <Title
          title={'Administrador de Paquetes'}
          action={create}
          onClick={() => setCev({show: true, type: 1, id: null})}
          actionTitle={'Nuevo Paquete'}
        />
        <Row>
            <Col>
                <Action
                  onClick={() => setCev({show: true, type: 1, id: null})}
                  actionTitle={'Nuevo Paquete'}
                  action
                />
                <Card title={'Paquetes'} white>
                    <TableV2
                        items={parcels.filter(filter)}
                        noItemsLegend={'No se encontraron paquetes'}
                        loading={gLoading}
                        customFilter={customFilter()}
                        defaultActive
                        headers={getHeaders()}
                        mobileAuto
                        storageKey={`parcel`}
                    />
                </Card>
            </Col>
        </Row>

        <ParcelCEV {...cev} onHide={(update) => {
            setCev({...cev, show: false});
            if (update)
                setUp()
        }}/>

        <Alert {...alert}/>
    </div>);
};
export default Parcel;
