import '../../Permissions/UpdatePermissions/UpdatePermissions.scss'
import './ProductsByPosition.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Card from 'src/components/cards/Card'
import ECommerceCard from 'src/components/cards/e-commerce-card/ECommerceCard'
import TextField from 'src/components/inputs/FormTextField/FormTextField'
import ImageCarousels from 'src/components/ImageCarousels/ImageCarousels'

import { formatNumberWithCommas } from 'src/utils/formatters'

import {
  Row,
  Col,
  Modal,
  Container,
  InputGroup,
  Button,
  FormControl,
} from 'react-bootstrap'
import { Facebook as BulletList } from 'react-content-loader'
import Alert from 'sweetalert-react'

import { actionTypes as typesC, getCompaniesByClient } from 'src/actions/company.actions'
import {
  selectSetCurrentCompany,
  selectCompaniesByClient,
} from 'src/selectors/company.selector'

import {
  actionTypes as typesP,
  getAllProductsByClient,
} from 'src/actions/products.actions'
import { selectProductsByClient } from 'src/selectors/products.selector'

import { addItem } from 'src/actions/shopping.actions'
import { selectShopping } from 'src/selectors/shopping.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  hasErrorsSelector,
  singleErrorSelector,
  handlerError,
} from 'src/selectors/error.selector'
import CollapsibleCard from 'src/components/cards/collapsible-card/CollapsibleCard'
import moment from 'moment'
import { toMoney } from 'src/utils/utilities'
import Money from 'src/components/Money/Money'

class ProductsByPosition extends Component {
  state = {
    alert: { title: 'default' },
    companies: [],
    search: '',
    modal: { show: false, item: {}, quantity: 0, subTotal: 0 },
  }

  componentDidMount() {
    const { getCompanies, getProducts, company } = this.props
    if (company.clientId) {
      getCompanies(company.clientId)
      getProducts(company.clientId)
    }
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { loadingC, hasErrorC, loadingP, hasErrorP, handlerError } = this.props
    const { errorC, errorP, listCompanies } = next
    let alert = { title: 'default' }

    if (loadingC && !next.loadingC) {
      if (!hasErrorC && next.hasErrorC) {
        alert = handlerError(errorC.message || 'Ha ocurrido un error inesperado.')
        alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
      } else {
        let companies = []
        listCompanies.map(c => companies.push({ ...c, isSelected: true }))
        this.setState({ companies })
      }
    }

    if (loadingP && !next.loadingP) {
      if (!hasErrorP && next.hasErrorP) {
        alert = handlerError(errorP.message || 'Ha ocurrido un error inesperado.')
        alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
      }
    }

    this.setState({ alert })
  }

  handleItemsChange = event => {
    const { checked } = event.target
    const { companies } = this.state
    const list = companies.map(c => ({ ...c, isSelected: checked }))
    this.setState({ companies: list })
  }

  handlerCheckBox = (event, index) => {
    const { checked } = event.target
    const { companies } = this.state
    companies[index].isSelected = checked
    this.setState({ companies })
  }

  getProductsGroupByProducer = (companies, products) => {
    let groups = []
    let indexS = []
    products.map(p => {
      const companyId = p.productData.companyId
      if (indexS.indexOf(companyId) === -1) {
        indexS.push(companyId)

        let companyName = '---'

        const company = companies.find(c => c.id === companyId)
        if (company) companyName = `${company.alphanumericId} - ${company.name}`

        const companyProducts = products
          .filter(pbc => pbc.productData.companyId === companyId)
          .map(pbc => {
            let thumbnail = null
            pbc.deliveryDate = null
            if (pbc.images.length > 0) thumbnail = pbc.images[0].url
            let deliveryDate = moment()
            if (pbc.polygonRoute) {
              if (pbc.polygonRoute.deliveryDays && pbc.polygonRoute.deliveryDays >= 1) {
                deliveryDate = moment().add(pbc.polygonRoute.deliveryDays, 'days')
              } else deliveryDate = moment().add(1, 'days')
            } else deliveryDate = moment().add(1, 'days')

            pbc.deliveryDate = moment(deliveryDate).format('DD/MM/YYYY')

            return {
              ...pbc,
              companyName,
              thumbnail,
              name: pbc.productData.name,
              price: pbc.productData.price,
              description: pbc.productData.description,
            }
          })

        groups.push({
          company: p.productData.companyId,
          companyName,
          products: companyProducts,
        })
      }
    })
    return groups
  }

  filter = item => {
    const { companies } = this.state
    const company = companies.find(c => c.id === item.company)
    return company && company.isSelected
  }

  filterProducts = item => {
    const { search } = this.state
    const exp = new RegExp(search.toUpperCase(), 'g')
    return Object.keys(item).some(p =>
      `${JSON.stringify(item[p])}`.toUpperCase().match(exp),
    )
  }

  setDataToModal = (show, item, quantity, subTotal) => {
    this.setState({ modal: { show, item, quantity, subTotal: subTotal || 0 } })
  }

  onChangeInput = value => {
    let { show, item, subTotal } = this.state.modal
    let deliveryDate = moment()

    if (value >= 0) {
      subTotal = parseInt(value) * item.productData.price
    } else subTotal = 0

    if (value > item.existence) deliveryDate = moment().add(item.loadEstimateAt, 'days')
    else {
      if (item.polygonRoute) {
        if (item.polygonRoute.deliveryDays && item.polygonRoute.deliveryDays >= 1) {
          deliveryDate = moment().add(item.polygonRoute.deliveryDays, 'days')
        } else deliveryDate = moment().add(1, 'days')
      } else deliveryDate = moment().add(1, 'days')
    }

    item.deliveryDate = moment(deliveryDate).format('DD/MM/YYYY')

    this.setDataToModal(show, item, value, subTotal)
  }

  addItem = () => {
    const { modal } = this.state
    const item = {
      id: modal.item.productId,
      name: modal.item.productData.name,
      description: modal.item.productData.description,
      offerId: modal.item.offerId,
      companyName: modal.item.companyName,
      companyId: modal.item.productData.companyId,
      price: modal.item.productData.price,
      quantity: modal.quantity,
      subTotal: modal.subTotal,
      images: modal.item.images,
      thumbnail: modal.item.thumbnail,
      deliveryDate: modal.item.deliveryDate,
      polygonRoute: modal.item.polygonRoute,
    }
    this.props.addItem(item, this.props.shopping)
    this.setDataToModal(false, {}, 0, 0)
  }

  render() {
    const { loadingP, products } = this.props
    const { companies, search, modal } = this.state
    const { show, item, quantity, subTotal } = modal
    const { productData } = item

    const items = this.getProductsGroupByProducer(companies, products)

    return (
      <div style={{ marginBottom: 5 }}>
        <h1 className={'dashboard-welcome'}>Productos</h1>
        <Row>
          <Col xl={3} lg={3} md={3} sm={12} xs={12}>
            <CollapsibleCard title={'Filtrar por'} startCollapsed={true}>
              <div className={'column'}>
                <h6>
                  <strong>Productor</strong>
                </h6>
                <h2 className={'item-title'}>
                  <input
                    style={{ cursor: 'pointer', marginRight: 4 }}
                    type="checkbox"
                    name={'all'}
                    checked={
                      companies.filter(c => c.isSelected).length === companies.length
                    }
                    onChange={this.handleItemsChange}
                  />
                  {companies.filter(c => c.isSelected).length !== companies.length
                    ? 'Marcar todos'
                    : 'Desmarcar todos'}
                </h2>

                <form>
                  {companies.map((c, i) => (
                    <label key={c.id} className={'checkbox-label'}>
                      <input
                        type={'checkbox'}
                        name={`producer - ${i}`}
                        checked={c.isSelected}
                        onChange={event => this.handlerCheckBox(event, i)}
                      />{' '}
                      {`${c.alphanumericId || ''} - ${c.name}`}
                    </label>
                  ))}
                </form>
              </div>
            </CollapsibleCard>
          </Col>

          <Col xl={9} lg={9} md={9} sm={12} xs={12}>
            <div className={'column'}>
              <Card>
                <TextField
                  placeholder={'Buscar por Nombre / descripción '}
                  value={search}
                  onChange={e => this.setState({ search: e.target.value })}
                />
              </Card>

              {loadingP ? (
                <BulletList />
              ) : (
                <div>
                  {items.filter(this.filter).map(i => (
                    <div key={i.company}>
                      <h2 className={'item-title'}>{i.companyName}</h2>
                      <hr />
                      <Row>
                        {i.products.filter(this.filterProducts).map((p, index) => (
                          <Col xl={4} lg={4} md={6} sm={6} xs={12} key={index}>
                            <ECommerceCard
                              item={p}
                              title={p.name}
                              subTitle={i.companyName}
                              price={toMoney(p.price)}
                              description={p.description || 'Sin descripción'}
                              img={p.thumbnail}
                              onClick={() => this.setDataToModal(true, p, 0)}
                            />
                          </Col>
                        ))}
                      </Row>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </Col>
        </Row>

        <Modal
          show={show}
          size={'md'}
          centered
          onHide={() => this.setDataToModal(false, {}, 0)}
        >
          <Modal.Header>
            {show && item.images.length > 0 ? (
              <ImageCarousels images={item.images} />
            ) : (
              <Modal.Title>{productData && productData.name}</Modal.Title>
            )}
          </Modal.Header>

          <Modal.Body>
            <Container>
              <div className={'column'}>
                {show && item.images.length > 0 && (
                  <div align={'center'}>
                    <h3>{productData.name}</h3>
                  </div>
                )}
                <div align={'center'}>
                  <h5>{productData && productData.description}</h5>
                </div>

                <span>
                  <strong>Productor: </strong>
                  {item.companyName}
                </span>
                <Money component="span">
                  <strong>Precio: </strong>
                  {productData && productData.price}
                </Money>
                <Money component="span">
                  <strong>Subtotal: </strong>
                  {subTotal}
                </Money>
                {item.deliveryDate && (
                  <span>
                    <strong>Entrega estimada: </strong>
                    {item.deliveryDate}
                  </span>
                )}
              </div>
              <br />

              <div>
                <InputGroup className={'mb-3'}>
                  <Button
                    onClick={() =>
                      this.onChangeInput((quantity ? parseInt(quantity) : 0) - 1)
                    }
                    variant={'outline-secondary'}
                  >
                    -
                  </Button>
                  <FormControl
                    aria-describedby={'basic-addon1'}
                    value={quantity}
                    type={'number'}
                    name={'quantity'}
                    onChange={event => this.onChangeInput(event.target.value)}
                  />
                  <Button
                    onClick={() =>
                      this.onChangeInput((quantity ? parseInt(quantity) : 0) + 1)
                    }
                    variant={'outline-secondary'}
                  >
                    +
                  </Button>
                </InputGroup>
              </div>
            </Container>
          </Modal.Body>

          <Modal.Footer>
            <div style={{ textAlign: 'right' }}>
              <Button
                onClick={() => this.addItem()}
                disabled={quantity <= 0 || !quantity}
              >
                Agregar al carrito
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        <Alert {...this.state.alert} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  company: selectSetCurrentCompany(state),
  listCompanies: selectCompaniesByClient(state),
  loadingC: loadingSelector([typesC.GET_PRODUCER_BY_CLIENT])(state),
  hasErrorC: hasErrorsSelector([typesC.GET_PRODUCER_BY_CLIENT])(state),
  errorC: singleErrorSelector([typesC.GET_PRODUCER_BY_CLIENT])(state),
  products: selectProductsByClient(state),
  loadingP: loadingSelector([typesP.GET_ALL_PRODUCTS_BY_CLIENT])(state),
  hasErrorP: hasErrorsSelector([typesP.GET_ALL_PRODUCTS_BY_CLIENT])(state),
  errorP: singleErrorSelector([typesP.GET_ALL_PRODUCTS_BY_CLIENT])(state),
  shopping: selectShopping(state),
  handlerError: message => handlerError(message),
})
const mapDispatchToProps = dispatch => ({
  getCompanies: id => dispatch(getCompaniesByClient(id)),
  getProducts: id => dispatch(getAllProductsByClient(id)),
  addItem: (item, items) => dispatch(addItem(item, items)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ProductsByPosition)
