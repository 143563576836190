import ExpensesService from '../services/expenses.service'
import { executeAction } from './global.actions'

export const actionTypes = {
  GET_EXPENSE_TYPE: 'GET_EXPENSE_TYPE',
  GET_EXPENSES_PRODUCTS: 'GET_EXPENSES_PRODUCTS',
  GET_EXPENSES: 'GET_EXPENSES',
  GET_EXPENSES_ADMIN: 'GET_EXPENSES_ADMIN',
  CREATE_EXPENSE: 'CREATE_EXPENSE',
  UPDATE_EXPENSE: 'UPDATE_EXPENSE',
  CREDIT_EXPENSE: 'CREDIT_EXPENSE',
  GET_EXPENSE_LOG: 'GET_EXPENSE_LOG',
}

export const getExpenseType = () => dispatch => {
  const process = async () => ({
    expenseType: await ExpensesService.getExpenseType(),
  })
  dispatch(executeAction(actionTypes.GET_EXPENSE_TYPE, process))
}

export const getExpenses = () => dispatch => {
  const process = async () => ({
    expenses: await ExpensesService.getExpenses(),
  })
  dispatch(executeAction(actionTypes.GET_EXPENSES, process))
}

export const getExpensesProducts = () => dispatch => {
  const process = async () => ({
    expensesProducts: await ExpensesService.getExpensesProducts(),
  })
  dispatch(executeAction(actionTypes.GET_EXPENSES_PRODUCTS, process))
}

export const getExpensesAdmin = () => dispatch => {
  const process = async () => ({
    expenses: await ExpensesService.getExpensesAdmin(),
  })
  dispatch(executeAction(actionTypes.GET_EXPENSES_ADMIN, process))
}

export const createExpense =
  (number, series, nit, description, total, expenseType, products) => dispatch => {
    const process = async () => {
      const expense = await ExpensesService.createExpenseRequest(
        number,
        series,
        nit,
        description,
        total,
        expenseType,
        products,
      )
      dispatch(getExpenses())
      return { expense }
    }
    dispatch(executeAction(actionTypes.CREATE_EXPENSE, process))
  }

export const updateExpense = (state, toUpdate, message) => dispatch => {
  const process = async () => {
    const result = await ExpensesService.updateDepositStatus(state, toUpdate, message)
    dispatch(getExpensesAdmin())
    return { result }
  }
  dispatch(executeAction(actionTypes.UPDATE_EXPENSE, process))
}

export const creditExpense = (exId, amount, type, list) => dispatch => {
  const process = async () => {
    const expense = await ExpensesService.creditExpense(exId, amount, type, list)
    dispatch(getExpensesAdmin())
    return { expense }
  }
  dispatch(executeAction(actionTypes.CREDIT_EXPENSE, process))
}

export const getExpenseLog = expense => dispatch => {
  const process = async () => ({
    expenseLog: await ExpensesService.getExpenseLog(expense),
  })
  dispatch(executeAction(actionTypes.GET_EXPENSE_LOG, process))
}
