import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Row, Col } from 'react-bootstrap'
import {
  TableV2,
  Card,
  Title,
  Checkbox,
  CustomTabs,
  Icon,
  Select,
  SelectedDates,
  Button,
  ListCategories,
  FABV2,
} from 'src/components'
import { actionTypes as typeP, getProviders } from 'src/actions/producer.actions'
import { isAllowed } from 'src/selectors/modules.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { selectPurchases, selectTotalPurchases } from 'src/selectors/purchase.selector'
import {
  actionTypes as typesP,
  getAllPurchases,
  getSumCPP,
} from 'src/actions/purchase.actions'
import {
  faBook,
  faFileExcel,
  faPlusCircle,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'

import Folder, { categoryType } from 'src/components/folders/Folder'
import {
  actionTypes as typeC,
  getAllCategorizations,
} from 'src/actions/categorization.actions'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'
import { onRefresh, onSetModalPurchase } from 'src/actions/utilities.actions'
import Money from 'src/components/Money/Money'
import { toMoney } from 'src/utils/utilities'
import ProvidersAdvances from 'src/content/Providers/ProvidersAdvances'
import { selectRefresh } from 'src/selectors/utilities.selector'
import { selectWarehouseLite } from 'src/selectors/warehouse.selector'
import { getWarehouseLite } from 'src/actions/warehouse.actions'
import { actions as typeBooks, cppBookReportExcel } from 'src/actions/books.actions'

const CppPage = () => {
  const dispatch = useDispatch()

  const categorization = useSelector(selectAllCategorizations)
  const providers = useSelector(state => state.producer.providers)
  const purchases = useSelector(selectPurchases)
  const totalCPP = useSelector(selectTotalPurchases)
  const balance = useSelector(state => state.purchase.sum)
  const refresh = useSelector(selectRefresh)
  const warehouses = useSelector(selectWarehouseLite)

  const loading = useSelector(state => loadingSelector([typeP.GET_PROVIDERS])(state))
  const loadingCPP = useSelector(state =>
    loadingSelector([typesP.GET_ALL_PURCHASE])(state),
  )
  const loadingB = useSelector(state => loadingSelector([typesP.GET_SUM_CPP])(state))
  const loadingAssigned = useSelector(state =>
    loadingSelector([typeC.GET_ASSIGNED, typeC.GET_ALL])(state),
  )
  const loadingExcel = useSelector(state =>
    loadingSelector([typeBooks.GET_PURCHASE_BOOK_EXCEL])(state),
  )
  // PERMISSION
  const addBalance = useSelector(state => isAllowed(state, [1204]))

  const defaultValue = { label: '- Todos -', value: null }
  const overdueTypes = [
    { value: 1, label: '1 a 30 días' },
    { value: 2, label: '31 a 60 días' },
    { value: 3, label: '61 días o más' },
  ]
  const [tGFilter, setTGFilter] = useState('')
  const [gFilter, setGFilter] = useState({
    start: null,
    end: null,
    providerId: null,
  })
  const [textFilter, setTextFilter] = useState('')
  const [filters, setFilters] = useState({
    providerId: null,
    start: null,
    end: null,
    skip: 0,
    size: 10,
    search: null,
  })
  const [filtersData, setFiltersData] = useState({
    state: { label: 'Cuentas por pagar', value: 2 },
    warehouse: defaultValue,
    overdue: defaultValue,
    categories: null,
  })

  const [showCategorization, setShowCategorization] = useState({
    show: false,
    filter: false,
  })
  const [selectedCategories, setSelectedCategories] = useState([])
  const [advances, setAdvances] = useState({})

  useEffect(() => {
    dispatch(getProviders())
    setTGFilter(JSON.stringify(gFilter))
    setTextFilter(JSON.stringify(filters))
    dispatch(getAllCategorizations(18))
    dispatch(getWarehouseLite({ all: true }))
  }, [])

  useEffect(() => {
    if (refresh) {
      setUpPurchases()
      dispatch(getProviders())
      dispatch(getSumCPP({ ...gFilter, state: 2 }))
    }
    dispatch(onRefresh(false))
  }, [refresh])

  useEffect(() => {
    let string = ''
    selectedCategories.forEach(p => {
      string += p.id + ','
    })
    string = string.slice(0, string.length - 1)
    setFiltersData({ ...filtersData, categories: string })
  }, [selectedCategories])

  useEffect(() => {
    if (tGFilter === '' || tGFilter === JSON.stringify(gFilter)) return
    setTGFilter(JSON.stringify(gFilter))
  }, [gFilter])

  useEffect(() => {
    if (tGFilter !== JSON.stringify(gFilter)) return
    setFilters({ ...filters, ...gFilter })
    dispatch(getSumCPP({ ...gFilter, state: 2 }))
  }, [tGFilter])

  useEffect(() => {
    if (textFilter === '' || textFilter === JSON.stringify(filters)) return
    setTextFilter(JSON.stringify(filters))
  }, [filters])

  useEffect(() => {
    if (textFilter !== JSON.stringify(filters)) return
    setUpPurchases()
  }, [textFilter])

  const setUpPurchases = () => {
    const { providerId, end, start, search, size, skip } = filters
    const { state, warehouse, overdue, categories } = filtersData

    dispatch(
      getAllPurchases({
        providerId,
        start,
        end,
        state: state.value,
        skip,
        size,
        search,
        report: true,
        categories,
        warehouseId: warehouse.value,
        overdueType: overdue.value,
      }),
    )
  }

  const headers = [
    {
      label: 'Fecha de emisión',
      show: true,
      value: ['emissionAt'],
      type: 'date',
      className: 'mini',
    },
    {
      label: 'Fecha de registro',
      show: true,
      value: ['createdAt'],
      type: 'date',
      className: 'mini',
    },
    { label: 'NIT', show: true, value: ['nit'], type: 'text', className: 'mini' },
    {
      label: 'Proveedor',
      show: true,
      value: ['providerName'],
      type: 'text',
      className: 'medium',
    },
    {
      label: 'Factura',
      show: true,
      value: ['invoice'],
      type: 'text',
      className: 'mini',
      custom: item => item.invoice || 'Sin referencia',
    },
    {
      label: 'Descuento',
      show: true,
      value: ['discount'],
      type: 'currency',
      className: 'mini',
    },
    {
      label: 'Total',
      show: true,
      value: ['total'],
      type: 'currency',
      className: 'mini',
    },
    {
      label: 'Fecha de pago',
      show: true,
      value: ['paymentDate'],
      type: 'date',
      className: 'mini',
    },
    {
      label: 'Días de crédito',
      show: true,
      className: 'mini',
      value: ['creditDays'],
      type: 'wholeNumber',
    },
    {
      label: 'Días de morosidad',
      show: true,
      className: 'mini',
      value: ['overdueDays'],
      type: 'wholeNumber',
    },
    {
      label: 'Saldo pendiente',
      show: true,
      value: ['balance'],
      type: 'currency',
      className: 'mini',
    },
    {
      config: true,
      show: true,
      label: '',
      className: 'mini',
      custom: item => (
        <Icon
          tooltip={'Detalle'}
          icon={faBook}
          onClick={() => {
            dispatch(onSetModalPurchase(item.id))
          }}
        />
      ),
    },
  ]

  const renderTable = () => (
    <TableV2
      headers={headers}
      mobileAuto
      storageKey={`cpp`}
      items={purchases}
      total={totalCPP}
      loading={loadingCPP}
      customFilter={
        <>
          <Row>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              <Select
                label={'Por estado'}
                disabled={loadingCPP}
                value={filtersData.state}
                info={`Filtra las cuentas por pagar por estado`}
                options={[
                  { value: 10, label: '- Todos -' },
                  { value: 2, label: 'Cuentas por pagar' },
                  { value: 3, label: 'Completadas' },
                ]}
                onChange={value => setFiltersData({ ...filtersData, state: value })}
              />{' '}
              <br />
            </Col>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              <Select
                label={'Por bodega'}
                disabled={loadingCPP}
                info={'Filtra las ordenes por la bodega seleccionada'}
                options={[defaultValue, ...warehouses]}
                value={filtersData.warehouse}
                name={'warehouse'}
                onChange={warehouse => setFiltersData({ ...filtersData, warehouse })}
              />
            </Col>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              <Select
                label={'Por mora'}
                disabled={loadingCPP}
                info={'Filtra las ordenes por el tiempo de mora'}
                options={[defaultValue, ...overdueTypes]}
                value={filtersData.overdue}
                name={'warehouse'}
                onChange={overdue => setFiltersData({ ...filtersData, overdue })}
              />
            </Col>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              <Row className={'pl-1'}>
                <ListCategories
                  disabled={loadingCPP}
                  items={selectedCategories}
                  onRemove={item =>
                    setSelectedCategories(
                      selectedCategories.filter(f => f.id !== item.id),
                    )
                  }
                />
                <Button
                  disabled={loadingCPP}
                  style={{ marginLeft: 15 }}
                  color={'primary'}
                  onClick={() => {
                    setShowCategorization({ show: true, filter: true })
                    dispatch(getAllCategorizations(18))
                  }}>
                  Filtrar por categorías
                </Button>
              </Row>
            </Col>
          </Row>
          <Row style={{ float: 'right', marginBottom: 5 }}>
            <Button
              loading={loadingCPP}
              color={'accent'}
              icon={faSearch}
              onClick={() => {
                setUpPurchases()
                dispatch(
                  getSumCPP({
                    ...gFilter,
                    state: 2,
                    warehouseId: filtersData.warehouse.value,
                    overdueType: filtersData.overdue.value,
                  }),
                )
              }}>
              Aplicar Filtros
            </Button>
          </Row>
        </>
      }
      getPagination={(skip, size, search) =>
        setFilters({ ...filters, skip, size, search })
      }
      handleChange={search =>
        setTimeout(() => {
          setFilters({ ...filters, search })
        }, 1000)
      }
    />
  )

  const onClickProvider = item => {
    if (item.id === gFilter.providerId) setGFilter({ ...gFilter, providerId: null })
    else setGFilter({ ...gFilter, providerId: item.id })
  }

  return (
    <div>
      <Title title={'Cuentas por Pagar'} />
      <Row>
        <Col xl={4} lg={4} md={5} sm={12} xs={12}>
          <Card>
            <div className={'justify-center column'}>
              <br />
              <span>Cuentas por pagar</span>
              {loadingB ? <h5>Cargando...</h5> : <Money component="h4">{balance}</Money>}
              <br />
            </div>
          </Card>
        </Col>
        <Col xl={8} lg={8} md={7} sm={12} xs={12}>
          <Card>
            <SelectedDates
              withOptionNull
              onDateChange={(start, end) => setGFilter({ ...gFilter, start, end })}
            />
          </Card>
        </Col>

        <Col xl={4} lg={4} md={5} sm={12} xs={12}>
          <Card title={'Proveedores'} white>
            <TableV2
              loading={loading}
              noItemsLegend={'No se encontraron proveedores'}
              items={providers.filter(p => p.balanceCpp && p.balanceCpp > 0)}
              renderRow={(item, index) => (
                <div
                  className={`b-item ${item.id === gFilter.providerId ? 'active' : ''}`}
                  key={index}>
                  <div className={'column'} style={{ margin: 2 }}>
                    <div onClick={() => onClickProvider(item)}>
                      <div className={'space-between'}>
                        <div className={'d-flex'}>
                          {' '}
                          <Checkbox checked={item.id === gFilter.providerId} />
                          <div className={'b-user-name left'}>{item.name}</div>
                        </div>
                        {addBalance && (
                          <div className={'d-flex'}>
                            <div className={'ml-1'}>
                              <Icon
                                style={{ marginRight: 4 }}
                                icon={faPlusCircle}
                                tooltip={'Añadir Adelanto al proveedor'}
                                size={'1_5x'}
                                onClick={e => {
                                  e.stopPropagation()
                                  setAdvances({ ...item, show: true })
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={'column ml-5'}>
                      <div className={'column'} onClick={() => onClickProvider(item)}>
                        <div className={'b-user-email left'}>NIT: {item.nit}</div>
                        <div className={'space-between red'}>
                          <div>CPP</div>
                          <div>{toMoney(item.balanceCpp)}</div>
                        </div>
                      </div>
                      <div className={'space-between'}>
                        <div>Saldo a favor</div>
                        <div className={'d-flex'}>
                          <div>{toMoney(item.balance)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
          </Card>
        </Col>
        <Col xl={8} lg={8} md={7} sm={12} xs={12}>
          <CustomTabs
            setKey={() => {
              setFilters({ ...filters, ...gFilter })
            }}
            items={[{ title: 'Cuentas por pagar', component: renderTable() }]}
          />
        </Col>
      </Row>

      <Folder
        noMessage
        list={selectedCategories.map(p => p.id)}
        onHide={() => setShowCategorization({ show: false, filter: false })}
        onAssign={item => {
          if (showCategorization.filter) {
            let d = selectedCategories.find(d => d.id === item.id)
            if (!d) {
              selectedCategories.push(item)
              setSelectedCategories([...selectedCategories])
            }
          }
        }}
        data1={
          categorization && categorization.children ? categorization.children[0] : {}
        }
        data2={
          categorization && categorization.children ? categorization.children[1] : {}
        }
        show={showCategorization.show}
        type={categoryType.CPP}
        loading={loadingAssigned}
      />

      <ProvidersAdvances
        {...advances}
        title={`${advances.name}: Adelanto`}
        paymentCash
        paymentBank
        paymentCN
        canSelectAllUsers
        onClose={success => {
          setAdvances({})
          if (success) dispatch(getProviders())
        }}
      />

      <FABV2
        loading={loadingExcel}
        onClick={() => {
          const { start, end, providerId, search } = filters
          const { categories, overdue, state, warehouse } = filtersData
          dispatch(
            cppBookReportExcel({
              startDate: start,
              endDate: end,
              status: state.value,
              search,
              providerId,
              categories,
              warehouseId: warehouse.value,
              overdueType: overdue.value,
            }),
          )
        }}
        title={'Descargar reporte'}
        icon={faFileExcel}
        show
      />
    </div>
  )
}
export default CppPage
