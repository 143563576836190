import { actionTypes } from '../actions/offers.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  offers: [],
  contraOffers: [],
  offer: {
    productList: [],
    priceNegotiable: true,
    alias: '',
    notes: '',
    deliveryAmount: 0,
  },
  contraOffer: {
    productList: [],
  },
  createResponse: null,
  updateResponse: null,
  createContraResponse: null,
  producerAcceptedContraOffer: null,
  categories: [],
  stepsValues: [],
  responseUpdate: {},
  archived: [],
  onArchive: null,
  change: null,
  polygonContracted: [],
  linkContracted: [],
  linkAvailable: [],
  linkKoloAvailable: [],
  linksRequest: [],
  linkProfile: {},
  linkSingle: {},
  settingResponse: '',
  setParcelResponse: '',
  linkResponseU: '',
  typesAvailable: [],
  linkBalance: {},
  validate: {},
  dealer: {},
  existence: [],
  cancel: {},
  companyLinks: [],
}

const OffersReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_LINKS_BY_COMPANY):
      return { ...state, companyLinks: extract(action.companyLinks, []) }
    case successState(actionTypes.EXISTENCE):
      return { ...state, existence: extract(action.existence, []) }
    case successState(actionTypes.ON_CANCEL_OFFER):
      return { ...state, cancel: extract(action.cancel, {}) }
    case successState(actionTypes.GET_NEAREST_DEALER):
      return { ...state, dealer: extract(action.dealer, {}) }
    case successState(actionTypes.ON_VALIDATE_PRODUCT):
      return { ...state, validate: extract(action.validate, {}) }
    case successState(actionTypes.GET_LINK_BALANCE):
      return { ...state, linkBalance: extract(action.linkBalance, {}) }
    case successState(actionTypes.GET_LINK_TYPE_AVAILABLE):
      return { ...state, typesAvailable: extract(action.typesAvailable, []) }
    case successState(actionTypes.ON_UPDATE_LINK):
      return { ...state, linkResponseU: extract(action.linkResponseU, '') }
    case successState(actionTypes.ON_SET_PARCELS):
      return {
        ...state,
        setParcelResponse: extract(action.setParcelResponse, ''),
      }
    case successState(actionTypes.ON_SEND_REQUEST_SETTING):
      return { ...state, settingResponse: extract(action.settingResponse, '') }
    case successState(actionTypes.ON_SEND_REQUEST_LINK):
      return { ...state, linkSingle: extract(action.linkSingle, {}) }
    case successState(actionTypes.GET_LINK_SINGLE):
      return { ...state, linkSingle: extract(action.linkSingle, {}) }
    case successState(actionTypes.GET_LINK_PROFILE):
      return { ...state, linkProfile: extract(action.linkProfile, {}) }
    case successState(actionTypes.GET_LINK_REQUEST):
      return { ...state, linksRequest: extract(action.linksRequest, []) }
    case successState(actionTypes.GET_LINK_KOLO_AVAILABLE):
      return {
        ...state,
        linkKoloAvailable: extract(action.linkKoloAvailable, []),
      }
    case successState(actionTypes.GET_LINK_AVAILABLE):
      return { ...state, linkAvailable: extract(action.linkAvailable, []) }
    case successState(actionTypes.GET_LINK_CONTRACTED):
      return { ...state, linkContracted: extract(action.linkContracted, []) }
    case successState(actionTypes.GET_POLYGON_CONTRACTED):
      return {
        ...state,
        polygonContracted: extract(action.polygonContracted, []),
      }
    case successState(actionTypes.CHANGE_STATUS_OFFER):
      return {
        ...state,
        change: extract(action.change, null),
        linkSingle: extract(action.change, null),
      }
    case successState(actionTypes.ON_ARCHIVE):
      return { ...state, onArchive: extract(action.archived, null) }
    case successState(actionTypes.ARCHIVED):
      return { ...state, archived: extract(action.archived, []) }
    case successState(actionTypes.ON_UPDATE_OFFER):
      return { ...state, responseUpdate: extract(action.responseUpdate, {}) }
    case actionTypes.SET_STEPS:
      return { ...state, stepsValues: action.steps }
    case successState(actionTypes.OFFER_CATEGORIES):
      return { ...state, categories: extract(action.categories, []) }
    case actionTypes.ON_ACCEPT_PRODUCER_CONTRA_OFFER_SUCCESS:
      return { ...state, producerAcceptedContraOffer: action.response }
    case actionTypes.ON_CREATE_CONTRA_OFFER_SUCCESS:
      return { ...state, createContraResponse: action.response }
    case actionTypes.GET_ALL_CONTRA_OFFER_SUCCESS:
      return { ...state, contraOffers: action.response }
    case actionTypes.GET_SINGLE_CONTRA_OFFER_SUCCESS:
      return { ...state, contraOffer: action.response }
    case actionTypes.ON_UPDATE_OFFER_SUCCESS:
      return { ...state, responseUpdate: action.response }
    case actionTypes.ON_CREATE_OFFER_SUCCESS:
      return { ...state, createResponse: action.response }
    case actionTypes.GET_SINGLE_OFFER_SUCCESS:
      return { ...state, offer: action.offer }
    case actionTypes.GET_ALL_OFFERS_SUCCESS:
      return { ...state, offers: action.offers }
    default:
      return state ? state : initialState
  }
}

export default OffersReducer
