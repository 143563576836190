import SubscriptiosService from '../services/subscriptions.services'
import { executeAction } from './global.actions'

export const actionTypes = {
  GET_ALL_SUBSCRIPTIONS: 'GET_ALL_SUBSCRIPTIONS',
  GET_SUBSCRIPTIONS: 'GET_SUBSCRIPTIONS',
  GET_SUBSCRIPTIONS_CLIENT: 'GET_SUBSCRIPTIONS_CLIENT',

  GET_ACTIVE_SUBSCRIPTIONS_CLIENT: 'GET_ACTIVE_SUBSCRIPTIONS_CLIENT',
  GET_ACTIVE_SUBSCRIPTION_CLIENT: 'GET_ACTIVE_SUBSCRIPTION_CLIENT',

  GET_SUBSCRIPTIONS_COMPANY: 'GET_SUBSCRIPTIONS_COMPANY',
  GET_SUBSCRIPTIONS_SHIPPER: 'GET_SUBSCRIPTIONS_SHIPPER',
  GET_SUBSCRIPTIONS_POLYGONS: 'GET_SUBSCRIPTIONS_POLYGONS',
  GET_SUBSCRIPTIONS_POLYGONS_DISTRIBUTION: 'GET_SUBSCRIPTIONS_POLYGONS_DISTRIBUTION',
  GET_SUBSCRIPTION_POLYGON: 'GET_SUBSCRIPTION_POLYGON',
  SAVE_NEW_SUBSCRIPTION: 'SAVE_NEW_SUBSCRIPTION',
  BUY_SUBSCRIPTION: 'BUY_SUBSCRIPTION',
  ASSIGN_SHIPPER: 'ASSIGN_SHIPPER',
  ACCEPT_SUBSCRIPTION: 'ACCEPT_SUBSCRIPTION',
  UPDATE_PRODUCTS: 'UPDATE_PRODUCTS',
  GET_INVOICES: 'GET_INVOICES',
  SET_PARAMS: 'SET_PARAMS',
  GET_ORDERS: 'GET_ORDERS',
  UPDATE_SUBSCRIPTION: 'UPDATE_SUBSCRIPTION',
  CONCLUDE_SUBSCRIPTION: 'CONCLUDE_SUBSCRIPTION',
}

export const getAllSubscriptions = () => dispatch => {
  const process = async () => ({
    subscriptions: await SubscriptiosService.getAllSubscriptions(),
  })
  dispatch(executeAction(actionTypes.GET_ALL_SUBSCRIPTIONS, process))
}

export const getSubscriptionsClient = () => dispatch => {
  const process = async () => ({
    subscriptionsClient: await SubscriptiosService.getSubscriptionsClient(),
  })
  dispatch(executeAction(actionTypes.GET_SUBSCRIPTIONS_CLIENT, process))
}

export const getActiveSubscriptionsClient = () => dispatch => {
  const process = async () => ({
    subscriptionsActiveClient: await SubscriptiosService.getActiveSubscriptionsClient(),
  })
  dispatch(executeAction(actionTypes.GET_ACTIVE_SUBSCRIPTIONS_CLIENT, process))
}

export const getActiveSubscriptionClient = id => dispatch => {
  const process = async () => ({
    subscriptionActiveClient: await SubscriptiosService.getActiveSubscriptionClient(id),
  })
  dispatch(executeAction(actionTypes.GET_ACTIVE_SUBSCRIPTION_CLIENT, process))
}

export const getSubscriptionsCompany = () => dispatch => {
  const process = async () => ({
    subscriptionsCompany: await SubscriptiosService.getSubscriptionsCompany(),
  })
  dispatch(executeAction(actionTypes.GET_SUBSCRIPTIONS_COMPANY, process))
}

export const getSubscriptionsShipper = () => dispatch => {
  const process = async () => ({
    subscriptionsShipper: await SubscriptiosService.getSubscriptionsShipper(),
  })
  dispatch(executeAction(actionTypes.GET_SUBSCRIPTIONS_SHIPPER, process))
}

export const getSubscriptionsPolygons = () => dispatch => {
  const process = async () => ({
    subscriptionsPolygons: await SubscriptiosService.getSubscriptionsPolygons(),
  })
  dispatch(executeAction(actionTypes.GET_SUBSCRIPTIONS_POLYGONS, process))
}

export const getSubscriptionsPolygonsDistribution = () => dispatch => {
  const process = async () => ({
    subscriptionsPolygonsDistribution:
      await SubscriptiosService.getSubscriptionsPolygonsDistribution(),
  })
  dispatch(executeAction(actionTypes.GET_SUBSCRIPTIONS_POLYGONS_DISTRIBUTION, process))
}

export const getSubscriptionPolygon = id => dispatch => {
  const process = async () => ({
    subscriptionPolygon: await SubscriptiosService.getSubscriptionPolygon(id),
  })
  dispatch(executeAction(actionTypes.GET_SUBSCRIPTION_POLYGON, process))
}

export const saveNewSubscription = subscription => dispatch => {
  const process = async () => {
    const subscriptionResponse = await SubscriptiosService.saveNewSubscription(
      subscription,
    )
    dispatch(getSubscriptionsCompany())
    return { subscriptionResponse }
  }
  dispatch(executeAction(actionTypes.SAVE_NEW_SUBSCRIPTION, process))
}

export const buySubscription = request => dispatch => {
  const process = async () => {
    const response = await SubscriptiosService.buySubscription(request)
    dispatch(getActiveSubscriptionsClient())
    return { response }
  }
  dispatch(executeAction(actionTypes.BUY_SUBSCRIPTION, process))
}

export const assignShipper = subscriptionId => dispatch => {
  const process = async () => {
    const response = await SubscriptiosService.assignShipper(subscriptionId)
    dispatch(getAllSubscriptions())
    return { response }
  }
  dispatch(executeAction(actionTypes.ASSIGN_SHIPPER, process))
}

export const acceptSubscription = subscriptionId => dispatch => {
  const process = async () => {
    const response = await SubscriptiosService.acceptSubscription(subscriptionId)
    dispatch(getActiveSubscriptionClient(subscriptionId))
    return { response }
  }
  dispatch(executeAction(actionTypes.ACCEPT_SUBSCRIPTION, process))
}

export const updateProducts = (subscriptionId, products) => dispatch => {
  const process = async () => {
    const response = await SubscriptiosService.updateProducts(subscriptionId, products)
    return { response }
  }
  dispatch(executeAction(actionTypes.UPDATE_PRODUCTS, process))
}

export const getSubscriptions = params => dispatch => {
  const process = async () => ({
    subscriptions: await SubscriptiosService.getSubscriptions(params),
  })
  dispatch(executeAction(actionTypes.GET_SUBSCRIPTIONS, process))
}

export const getInvoices = () => dispatch => {
  const process = async () => ({
    invoices: await SubscriptiosService.getInvoices(),
  })
  dispatch(executeAction(actionTypes.GET_INVOICES, process))
}

export const setParams = params => dispatch => {
  window.localStorage.setItem('subscription-params', JSON.stringify(params))
  const process = async () => ({ params })
  dispatch(executeAction(actionTypes.SET_PARAMS, process()))
}

export const getOrders = subscriptionId => dispatch => {
  const process = async () => ({
    orders: await SubscriptiosService.getOrders(subscriptionId),
  })
  dispatch(executeAction(actionTypes.GET_ORDERS, process))
}

export const updateSubscription = (id, request) => dispatch => {
  const process = async () => await SubscriptiosService.updateSubscription(id, request)
  dispatch(executeAction(actionTypes.UPDATE_SUBSCRIPTION, process))
}

export const concludeSubscription = id => dispatch => {
  const process = async () => await SubscriptiosService.concludeSubscription(id)
  dispatch(executeAction(actionTypes.CONCLUDE_SUBSCRIPTION, process))
}
