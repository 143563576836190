import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import { Button, SwitchV2 } from 'src/components'
import { faCheck, faWindowClose } from '@fortawesome/free-solid-svg-icons'

import { posConfigurationParams } from 'src/enums/posPanelConfigurations'
import { actionTypes, updatePosStepItems } from 'src/actions/restaurant.actions'

import { haveAnyValue } from 'src/utils/utilitiesV2'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import { showAlert } from 'src/actions/alert.actions'

interface IPOSConfigurationsProps {
  show: boolean
  pos: IPOS
  onHide: (update: boolean) => void
}

const POSConfigurations = ({ show, pos, onHide }: IPOSConfigurationsProps) => {
  const dispatch = useDispatch()

  const loadingPosStep: boolean = useSelector(state =>
    loadingSelector([actionTypes.UPDATE_POS_STEP])(state),
  )

  const errorPosStep = useSelector(state =>
    hasErrors([actionTypes.UPDATE_POS_STEP])(state),
  )

  const disabled: boolean = loadingPosStep

  const configurationParams: IPOSParam[] = posConfigurationParams

  const [data, setData] = useState({})
  const [flag, setFlag] = useState<boolean>(false)

  useEffect(() => {
    if (!show) return
    if (loadingPosStep) setFlag(true)
    else if (flag) {
      setFlag(false)
      const alert = errorPosStep
        ? handlerError(errorPosStep.message)
        : {
            ...handlerSuccess(),
            onConfirm: () => onClose(true),
          }
      dispatch(showAlert(alert))
    }
  }, [loadingPosStep])

  const onClose = (update?: boolean) => {
    if (disabled) return
    onHide(update)
    setData({ footerPhrase: '-1' })
  }

  const findValue = (id: string): boolean => {
    if (!pos) return false
    if (!data) return false
    const valueByPOS = pos[id]
    const valueByData = data[id]
    if (haveAnyValue(valueByData)) return valueByData
    return valueByPOS
  }

  const onChange = (value: boolean, id: string) => {
    setData({ ...data, [id]: value })
  }

  const onSave = () => {
    dispatch(updatePosStepItems(pos.id, data))
  }

  return (
    <div>
      <Modal show={show} centered size={'lg'} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Configuraciones POS App</Modal.Title>
        </Modal.Header>
        <Modal.Body className={'custom-modal-body'}>
          <Row>
            {configurationParams.map(({ id, name, description }) => {
              return (
                <Col key={id} xl={6} lg={6} md={6} sm={6} xs={12}>
                  <SwitchV2
                    label={name}
                    checked={findValue(id)}
                    info={description}
                    onChange={value => onChange(value, id)}
                    disabled={disabled}
                  />
                </Col>
              )
            })}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              color={'secondary'}
              icon={faWindowClose}
              onClick={onClose}
              disabled={disabled}>
              Cerrar
            </Button>
            <Button icon={faCheck} onClick={onSave} loading={loadingPosStep}>
              Guardar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default POSConfigurations
