import { $http } from './http'

class TransformationServices {
  getTransformation = async (startDate, endDate) => {
    const response = await $http.get(
      `/inventory/transfers?onlyTransformations=true&startDate=${startDate}&endDate=${endDate}`,
    )
    return response.data
  }

  getBaseProducts = async id => {
    const response = await $http.get(`/products/base/${id}`)
    return response.data
  }

  getDerivedProducts = async id => {
    const response = await $http.get(`/products/derivate/${id}`)
    return response.data
  }

  onCreateTransformation = async request => {
    const response = await $http.post(`/inventory/transfers/transformation/free`, request)
    return response.data
  }

  onCreateRecipeTransformation = async request => {
    const response = await $http.post(
      `/inventory/transfers/transformation/enumeration`,
      request,
    )
    return response.data
  }

  getInventoryListByWarehouse = async (warehouseId, params) => {
    const response = await $http.get(`/inventory/history/${warehouseId}`, { params })
    return response.data
  }

  getInventoryOrigin = async (documentType, documentId) => {
    const response = await $http.get(`/inventory/origin/${documentType}/${documentId}`)
    return response.data
  }
}

export default new TransformationServices()
