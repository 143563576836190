import './Products.scss'
import * as React from 'react'
import { connect } from 'react-redux'

import { Col, ProgressBar, Row } from 'react-bootstrap'
import moment from 'moment'
import Alert from 'sweetalert-react'

import {
  Button,
  Card,
  CustomDate as CustomDatePicker,
  Folder,
  Icon as ButtonIcon,
  Select as CustomSelect,
  Title,
} from 'src/components'
import { graphs as colorArray, quantityOptions } from 'src/utils/graphs'
import { faFileExcel, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  getExcelProductsReport,
  getProductsReport,
  getWarehouseLite,
} from 'src/actions/warehouse.actions'
import {
  selectProductReport,
  selectWarehouseLite,
} from 'src/selectors/warehouse.selector'

import { loadingSelector } from 'src/selectors/loading.selector'

import { selectCurrentModule, selectCurrentUser } from 'src/selectors/user.selector'

import { companies } from 'src/selectors/contracts.selector'

import { getAllDeliverers, getAllSellers } from 'src/actions/clients.actions'

import {
  actionTypes as action,
  getAllCategorizations,
} from 'src/actions/categorization.actions'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'

import { getUsersChildrenByModule } from 'src/actions/modules.actions'
import { selectUsers } from 'src/selectors/modules.selector'

import { handlerError, hasErrorsSelector } from 'src/selectors/error.selector'
import { actionTypes as posTypes, getAllPOSByUser } from 'src/actions/restaurant.actions'
import { selectAllPOSUser } from 'src/selectors/restaurant.selector'
import { Chart } from 'src/components/Graph/Chart'
import { ChartType } from 'src/enums/ChartType'

export const typeOptions = [
  { value: 1, label: 'Ingresos' },
  { value: 2, label: 'Descuentos' },
  { value: 3, label: 'Ventas' },
]

export const sortOptions = [
  { value: 1, label: 'Con más' },
  { value: 2, label: 'Con menos' },
]

class Products extends React.Component {
  state = {
    // start of month
    dateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    dateTo: new Date(),
    selectedProducts: [],
    distribution: { value: 0, label: 'Todas las empresas' },
    seller: { value: 0, label: 'Todos' },
    deliverer: { value: 0, label: 'Todos' },
    quantityProducts: { value: 10, label: '10 Productos' },
    sortProducts: { value: 1, label: 'Con más' },
    typeProducts: { value: 1, label: 'Ingresos' },
    showCategorizations: false,
    selectedCategorization: [],
    alert: { show: false },
    selectedPos: [],
    selectedWarehouse: [],
  }

  componentDidMount() {
    const { user } = this.props

    this.props.getCategories()
    this.props.getPos(user.id)
    this.props.getWarehouseLite()

    this.props.getUsersChildrenByModule().then(() => {
      const { distribution, deliverer } = this.state

      const params = new URLSearchParams(this.props.location.search)
      const dateFrom = params.get('start')
        ? new Date(Number(params.get('start')))
        : this.state.dateFrom
      const dateTo = params.get('end')
        ? new Date(Number(params.get('end')))
        : this.state.dateTo

      const seller =
        this.props.sellers.find(s => s.id === Number(params.get('userId'))) ||
        this.state.seller

      this.setState({ dateFrom, dateTo, seller })
      this.getReport(dateFrom, dateTo, distribution, deliverer, seller)
    })
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { excelLoading, excelHasError, report } = this.props

    if (excelLoading && !next.excelLoading) {
      if (!excelHasError && next.excelHasError) {
        this.setState({
          alert: {
            ...handlerError('No se pudo generar el reporte'),
            onConfirm: () => this.setState({ alert: { show: false } }),
          },
          id: null,
        })
      }
    }

    if (report !== next.report) {
      this.setState(
        {
          fill: true,
        },
        this.fillData,
      )
    }
  }

  fillData = () => {
    const { report } = this.props
    let { quantityProducts, sortProducts, typeProducts } = this.state
    const data = [...report]

    let dataToRender

    switch (typeProducts.value) {
      case 1:
        dataToRender = data
          .filter(f => f.total > 0)
          .sort((a, b) =>
            sortProducts.value === 1 ? b.total - a.total : a.total - b.total,
          )
          .slice(0, quantityProducts.value)
        break
      case 2:
        dataToRender = data
          .filter(f => f.discount > 0)
          .sort((a, b) =>
            sortProducts.value === 1 ? b.discount - a.discount : a.discount - b.discount,
          )
          .slice(0, quantityProducts.value)
        break
      case 3:
        dataToRender = data
          .filter(f => f.quantity > 0)
          .sort((a, b) =>
            sortProducts.value === 1 ? b.quantity - a.quantity : a.quantity - b.quantity,
          )
          .slice(0, quantityProducts.value)
        break
      default:
        break
    }

    let selectedProducts = []
    for (let i = 0, len = dataToRender.length; i < len; i++) {
      dataToRender[i].selected = true
      dataToRender[i].color = colorArray[i]
      selectedProducts.push({ ...dataToRender[i] })
    }

    this.setState({ selectedProducts: selectedProducts })
  }

  changeQuantity = item => {
    this.setState({ quantityProducts: item }, this.fillData)
  }

  changeSort = item => {
    this.setState({ sortProducts: item }, this.fillData)
  }

  changeType = item => {
    this.setState({ typeProducts: item }, this.fillData)
  }

  getReport = (dateFrom, dateTo, company, deliverer, seller) => {
    const { selectedCategorization, selectedPos, selectedWarehouse } = this.state
    let sDate = moment(dateFrom).hour(0).minute(0).second(0).valueOf()
    let eDate = moment(dateTo).hour(23).minute(59).second(59).valueOf()
    let request = {
      categories: selectedCategorization.map(p => p.id),
      pos: selectedPos.map(p => p.id),
      warehouses: selectedWarehouse.map(p => p.id),
    }
    this.props.getTotalProductReport(
      sDate,
      eDate,
      company.value,
      deliverer.value,
      seller.value,
      request,
    )
  }

  getExcelReport = (dateFrom, dateTo, company, deliverer, seller) => {
    const { selectedCategorization, selectedPos, selectedWarehouse } = this.state
    let sDate = moment(dateFrom).hour(0).minute(0).second(0).valueOf()
    let eDate = moment(dateTo).hour(23).minute(59).second(59).valueOf()
    let request = {
      categorization: selectedCategorization.map(p => p.id),
      warehouses: selectedWarehouse.map(p => p.id),
    }
    this.props.getExcelTotalProductReport(
      sDate,
      eDate,
      company.value,
      deliverer.value,
      seller.value,
      selectedPos.map(p => p.id).join(','),
      request,
    )
  }

  getData = () => {
    const { quantityProducts, sortProducts, typeProducts, selectedProducts } = this.state
    const data = [...selectedProducts]

    let dataToRender
    switch (typeProducts.value) {
      case 1:
        dataToRender = data
          .filter(f => f.total > 0)
          .sort((a, b) =>
            sortProducts.value === 1 ? b.total - a.total : a.total - b.total,
          )
          .slice(0, quantityProducts.value)
        break
      case 2:
        dataToRender = data
          .filter(f => f.discount > 0)
          .sort((a, b) =>
            sortProducts.value === 1 ? b.discount - a.discount : a.discount - b.discount,
          )
          .slice(0, quantityProducts.value)
        break
      case 3:
        dataToRender = data
          .filter(f => f.quantity > 0)
          .sort((a, b) =>
            sortProducts.value === 1 ? b.quantity - a.quantity : a.quantity - b.quantity,
          )
          .slice(0, quantityProducts.value)
        break
      default:
        break
    }
    return dataToRender
  }

  getInfoChartBar = () => {
    const { selectedProducts, typeProducts } = this.state
    const reportFiltered = selectedProducts

    return {
      labels: reportFiltered.map(p => p.name),
      datasets: [
        {
          label: 'Productos',
          data: reportFiltered.map(p => {
            switch (typeProducts.value) {
              case 1:
                return Number(p.total).toFixed(2)
              case 2:
                return Number(p.discount).toFixed(2)
              case 3:
                return Number(p.quantity)
              default:
                return 0
            }
          }),
        },
      ],
    }
  }

  getTitleChart = () => {
    const { typeProducts } = this.state

    switch (typeProducts.value) {
      case 1:
        return 'Ingreso'
      case 2:
        return 'Descuento'
      case 3:
        return 'Ventas'
      default:
        return 'Cantidad'
    }
  }

  render() {
    const {
      distribution,
      dateFrom,
      dateTo,
      seller,
      deliverer,
      quantityProducts,
      sortProducts,
      typeProducts,
      selectedCategorization,
      showCategorizations,
      selectedPos,
      selectedWarehouse,
    } = this.state
    const { deliverers, sellers, reportLoading, categorization, allPOS, warehouses } =
      this.props

    return (
      <div className={'text-center'}>
        <Title title={'Reporte de ventas por ítems'} />
        <Card
          title={'filtros'}
          white
          button={
            <ButtonIcon
              disabled={reportLoading}
              spin={this.props.excelLoading}
              icon={faFileExcel}
              tooltip={'Descargar reporte'}
              size={'2x'}
              onClick={() =>
                this.getExcelReport(dateFrom, dateTo, distribution, deliverer, seller)
              }
            />
          }>
          <Row>
            <Col xl={6} lg={6} md={6} sm={12}>
              <CustomDatePicker
                disabled={reportLoading}
                label={'Desde:'}
                value={dateFrom}
                onDayChange={date => this.setState({ dateFrom: date })}
              />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12}>
              <CustomDatePicker
                disabled={reportLoading}
                label={'Hasta:'}
                value={dateTo}
                onDayChange={date => this.setState({ dateTo: date })}
              />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12}>
              <CustomSelect
                disabled={reportLoading}
                label={'Creado Por'}
                name={'seller'}
                value={seller}
                options={[{ value: 0, label: 'Todos' }, ...sellers]}
                onChange={item => this.setState({ seller: item })}
              />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12}>
              <CustomSelect
                disabled={reportLoading}
                label={'Entregado Por'}
                name={'deliverer'}
                value={deliverer}
                options={[{ value: 0, label: 'Todos' }, ...deliverers]}
                onChange={item => this.setState({ deliverer: item })}
              />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12}>
              <div className={'column'}>
                <CustomSelect
                  disabled={reportLoading}
                  label={'Puntos de venta'}
                  options={allPOS.filter(
                    x => selectedPos.findIndex(y => y.id === x.id) === -1,
                  )}
                  value={null}
                  placeholder={'Seleccione un punto de venta'}
                  onChange={e => {
                    selectedPos.push(e)
                    this.setState({ selectedPos })
                  }}
                />
                <Row>
                  {selectedPos.map((p, i) => (
                    <div className={'user-tag product-t ml-2'} key={i}>
                      <label className={'label-user-tag'}>{p.label}</label>
                      <ButtonIcon
                        className={'delete-user-tag d-product-t'}
                        icon={faTimes}
                        tooltip={'Quitar'}
                        color={'white'}
                        onClick={() =>
                          this.setState({
                            selectedPos: selectedPos.filter(f => f.id !== p.id),
                          })
                        }
                      />
                    </div>
                  ))}
                </Row>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={12}>
              <div className={'column'}>
                <CustomSelect
                  disabled={reportLoading}
                  label={'Bodegas'}
                  options={warehouses.filter(
                    x => selectedWarehouse.findIndex(y => y.id === x.id) === -1,
                  )}
                  placeholder={'Seleccione una bodega'}
                  value={null}
                  onChange={e => {
                    selectedWarehouse.push(e)
                    this.setState({ selectedWarehouse })
                  }}
                />
                <Row className={'pl-1'}>
                  {selectedWarehouse.map((p, i) => (
                    <div className={'user-tag warehouse-t ml-2'} key={i}>
                      <label className={'label-user-tag'}>{p.label}</label>
                      <ButtonIcon
                        className={'delete-user-tag d-warehouse-t'}
                        icon={faTimes}
                        tooltip={'Quitar'}
                        color={'white'}
                        onClick={() =>
                          this.setState({
                            selectedWarehouse: selectedWarehouse.filter(
                              f => f.id !== p.id,
                            ),
                          })
                        }
                      />
                    </div>
                  ))}
                </Row>
              </div>
            </Col>
            <Col xl={12}>
              <div className={'space-between'}>
                <Button
                  disabled={reportLoading}
                  color={'primary'}
                  onClick={() => this.setState({ showCategorizations: true })}>
                  Filtrar por categorías
                </Button>

                <Button
                  loading={reportLoading}
                  color={'accent'}
                  icon={faSearch}
                  onClick={() => {
                    this.getReport(dateFrom, dateTo, distribution, deliverer, seller)
                  }}>
                  Aplicar Filtros
                </Button>
              </div>
            </Col>
            <Col xl={12}>
              <Row className={'pl-1'}>
                {selectedCategorization.map((p, i) => (
                  <div className={'user-tag product-t ml-2'} key={i}>
                    <label className={'label-user-tag'}>{p.name}</label>
                    <ButtonIcon
                      className={'delete-user-tag d-product-t'}
                      icon={faTimes}
                      tooltip={'Quitar'}
                      color={'white'}
                      onClick={() =>
                        this.setState({
                          selectedCategorization: selectedCategorization.filter(
                            f => f.id !== p.id,
                          ),
                        })
                      }
                    />
                  </div>
                ))}
              </Row>
            </Col>
          </Row>
        </Card>

        <Card title={'Visualizar'} white>
          <Row>
            <Col>
              {reportLoading && (
                <Row>
                  <Col>
                    <div className={'pb-custom'}>
                      <ProgressBar
                        label="Cargando"
                        animated
                        now={100}
                        style={{ marginBottom: 10 }}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <CustomSelect
                label={'Cantidad de Productos'}
                name={'product_quantity'}
                value={quantityProducts}
                options={quantityOptions}
                onChange={this.changeQuantity}
              />
            </Col>
            <Col md={4}>
              <CustomSelect
                label={'Ordenar'}
                name={'sort'}
                value={sortProducts}
                options={sortOptions}
                onChange={this.changeSort}
              />
            </Col>
            <Col md={4}>
              <CustomSelect
                label={'Tipo'}
                name={'type'}
                value={typeProducts}
                options={typeOptions}
                onChange={this.changeType}
              />
            </Col>
          </Row>

          <Chart
            data={this.getInfoChartBar()}
            currencyValues={this.state.typeProducts.value !== 3}
            withTable
            title={this.getTitleChart()}
            type={ChartType.HORIZONTAL_BAR}
            yAxisName="Productos"
          />
        </Card>

        <Folder
          noMessage
          list={selectedCategorization.map(d => d.id)}
          onHide={() => this.setState({ showCategorizations: false })}
          onAssign={item => {
            if (selectedCategorization.findIndex(p => p.id === item.id) === -1) {
              selectedCategorization.push(item)
              this.setState({ selectedCategorization })
            }
          }}
          data1={
            categorization && categorization.children ? categorization.children[0] : {}
          }
          data2={
            categorization && categorization.children ? categorization.children[1] : {}
          }
          show={showCategorizations}
        />
        <Alert {...this.state.alert} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: selectCurrentUser(state),
  report: selectProductReport(state),
  reportLoading: loadingSelector([actionTypes.GET_TOTAL_PRODUCT_REPORT])(state),
  excelLoading: loadingSelector([actionTypes.GET_EXCEL_TOTAL_PRODUCT_REPORT])(state),
  excelHasError: hasErrorsSelector([actionTypes.GET_EXCEL_TOTAL_PRODUCT_REPORT])(state),
  totalReportFactor: selectProductReport(state),
  module: selectCurrentModule(state),
  shippers: companies(state),
  deliverers: selectUsers(state),
  sellers: selectUsers(state),
  categorization: selectAllCategorizations(state),
  categorizationLoading: loadingSelector([action.GET_ALL])(state),
  allPOS: selectAllPOSUser(state),
  loading: loadingSelector([posTypes.GET_ALL_POS_USER])(state),
  warehouses: selectWarehouseLite(state),
})

const mapDispatchToProps = dispatch => ({
  getTotalProductReport: (sDate, eDate, distributor, deliverer, seller, request) =>
    dispatch(getProductsReport(sDate, eDate, distributor, deliverer, seller, request)),
  getExcelTotalProductReport: (
    sDate,
    eDate,
    distributor,
    deliverer,
    seller,
    pos,
    request,
  ) =>
    dispatch(
      getExcelProductsReport(sDate, eDate, distributor, deliverer, seller, pos, request),
    ),
  getDeliverers: () => dispatch(getAllDeliverers()),
  getSellers: () => dispatch(getAllSellers()),
  getCategories: () => dispatch(getAllCategorizations(16)),
  getUsersChildrenByModule: () => dispatch(getUsersChildrenByModule(1000, true)),
  getPos: userId => dispatch(getAllPOSByUser(userId)),
  getWarehouseLite: () => dispatch(getWarehouseLite({ all: true })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Products)
