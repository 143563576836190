import React from 'react'
import { Pie as PieChart } from 'react-chartjs-2'

interface Props {
  data: IDataGraph
  tooltips?: ITooltipGraph
  height?: number
}

/**
 * Pie Chart component
 * @component
 * @param {IDataGraph} data List of items to display
 * @param {ITooltipGraph} tooltips Tooltip configuration
 * @param {number} height Height of the graph
 */
export const Pie = ({ data, tooltips, height = 150 }: Props) => {
  return <PieChart data={data} height={height} options={tooltips ? { tooltips } : {}} />
}
