import React, { useState } from 'react'

import { Modal, Row, Col } from 'react-bootstrap'
import { Button, FormText, ListCategories } from 'src/components'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

interface IProps {
  barcodes: ISelect[]
  disabled: boolean
  onChange: (barcodes: ISelect[]) => void
}
const ProductBarcodes = ({ barcodes = [], onChange, disabled = false }: IProps) => {
  const [modal, setModal] = useState({ show: false, value: null })

  const onAssignBarcode = (barcode: ISelect) => {
    const list: ISelect[] = Object.assign([], barcodes || [])
    const index = list.findIndex(p => p.value === barcode.value)
    if (index === -1) list.push(barcode)
    else list.splice(index, 1)
    onChange(list)
  }

  return (
    <div>
      <ListCategories
        label={'Códigos de barra'}
        items={barcodes}
        disabled={disabled}
        onRemove={onAssignBarcode}
        addItem={() => setModal({ show: true, value: null })}
        seeAddItem={!disabled}
      />
      <Modal
        show={modal.show}
        centered
        onHide={() => setModal({ show: false, value: null })}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar nuevo código de barra</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <FormText
                label={'Código de barra'}
                value={modal.value}
                changeValue={value => setModal({ ...modal, value })}
                error={
                  barcodes?.filter(b => b.value === modal.value)?.length > 0
                    ? 'No es posible agregar 2 códigos de barra iguales'
                    : null
                }
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              disabled={
                !modal.value || barcodes?.filter(b => b.value === modal.value)?.length > 0
              }
              onClick={() => {
                onAssignBarcode({ value: modal.value, label: modal.value })
                setModal({ show: false, value: null })
              }}
              icon={faPlus}>
              Agregar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default ProductBarcodes
