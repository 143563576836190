import React from 'react'
import { Modal } from 'react-bootstrap'
import CreateDynamicField from './CreateDynamicField'

interface CreateModalProps {
  show?: boolean
  onHide?: () => void
  mode: 'create' | 'edit'
  item?: DynamicField
}

const CreateDynamicFieldModal: React.FC<CreateModalProps> = ({
  show,
  onHide,
  mode,
  item,
}: CreateModalProps) => {
  return (
    <>
      <Modal show={show} onHide={onHide} centered size={'lg'}>
        <CreateDynamicField onHide={onHide} mode={mode} item={item} />
      </Modal>
    </>
  )
}
export default CreateDynamicFieldModal
