import 'src/content/Warehouse/Warehouse.scss'
import React from 'react'

import Action from './Action'

const Title = props => {
  return (
    <div className={`t-container-k ${props.tClassName || ''}`}>
      <div className={'title-k'}>{props.title}</div>
      <Action
        fClassName={'w-add-button'}
        className={'d-flex row align-items-center mr-3'}
        {...props}
      />
      {props.dontHide && <Action {...props} />}
    </div>
  )
}
export default Title
