import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";

import {Col, Row} from "react-bootstrap"
import {Td, Tr} from "react-super-responsive-table";

import {CustomTabs, Icon as IconButton, Button, TableV2} from "../../components";

import {actionTypes as actionO, getLinkContracted, getLinkAvailable, getLinkKoloAvailable, getLinkRequest} from "../../actions/offers.actions";
import {selectLinkContracted, selectLinkAvailable, selectLinkKoloAvailable, selectLinkRequest} from "../../selectors/offers.selector";

import {loadingSelector} from "../../selectors/loading.selector";

import {selectCurrentModule, selectCurrentCompany} from "../../selectors/user.selector";
import {faDrawPolygon, faExternalLinkSquareAlt, faEye} from "@fortawesome/free-solid-svg-icons";

const KoloLink = (props) => {
    const dispatch = useDispatch();

    const linkContracted = useSelector(selectLinkContracted);
    const linkAvailable = useSelector(selectLinkAvailable);
    const linkKoloAvailable = useSelector(selectLinkKoloAvailable);
    const linkRequest = useSelector(selectLinkRequest);
    const loading = useSelector((state) => loadingSelector([actionO.GET_LINK_KOLO_AVAILABLE, actionO.GET_LINK_AVAILABLE, actionO.GET_LINK_CONTRACTED, actionO.GET_LINK_REQUEST]) (state));

    // const module = useSelector(selectCurrentModule);
    const [module, setModule] = useState(1000);
    const [storageKey, setStorageKey ] = useState("")
    const company = useSelector(selectCurrentCompany);

    useEffect(() => {
        let m = 1000;
        if (props.match && props.match.params && props.match.params.moduleId)
            m = Number(props.match.params.moduleId);
        setModule(m);

        let type = 0;
        if (m === 1000) {
            type = 4;
        } else {
            type = 3;
            dispatch(getLinkAvailable(m));
            dispatch(getLinkKoloAvailable(m));
        }
        dispatch(getLinkRequest(m));
        dispatch(getLinkContracted(type, m));
    }, []);

    const goTo = (route) => {
        const {match} = props;
        let {url} = match;

        url += route;
        window.open(url, '_blank');
    };

    const getHeaders = (isProducer, type) => {
        const headers = [
            { label: '#', show: true, select:true, className: 'mini',  index:true },
            (isProducer) ? { label: 'Transportista', show: true, value: ['transportistName'], type: 'text', className: 'mini' } :
                { label: 'Productor', show: true, value: ['producerName'], type: 'text', className: 'mini' },
            { label: 'Zonas de transporte', show: true, value: ['zones'], type: 'text', className: 'mini' }
        ]
        if (!type || type !== 5) {
            setStorageKey('koloLink1')
            headers.push({
                config: true, show: true, label: '', className: 'mini center',
                custom: (item) => <IconButton tooltip={!type ? 'Ver contrato' : 'Ver perfil'} icon={faEye} onClick={() => {
                    const { history, match } = props;
                    let { url } = match;
                    url += `/perfil/${isProducer ? item.transportistId : item.producerId}/${type ? type : isProducer ? 1 : 2}`;
                    history.push(url);
                }} />
            });
        } else {
            setStorageKey('koloLink2')
            headers.push(...[
            { label: 'Estado', show: true, value: ['statusC'], type: 'text', className: 'mini', custom: (item) => item.statusC = item.status === 1 ? 'Solicitada' : 'Rechazada' },
            {
                config: true, show: true, label: '', className: 'mini center',
                custom: (item) => <IconButton
                    tooltip={'Ver solicitud'}
                    icon={faEye}
                    onClick={() => goTo(`/detalles/${item.id}`)}
                />
            }]);
        }
        return headers;
    }

    const renderComponent = (items, type) => {
        let isProducer = module === 2000;
        let store  = ''
        if (!type || type !== 5) store = 'koloLink1'
        else store = 'koloLink2'

        return (
            <TableV2
                items={items}
                headers = {getHeaders(isProducer, type)}
                mobileAuto
            />

        );
    };

    return (<div className={'column'}>
        <h1 className={'dashboard-welcome'}>Enlaces</h1>

        <Row>
            <Col xl={1} lg={1} md={1} sm={12} xs={12}/>
            <Col xl={10} lg={10} md={10} sm={12} xs={12}><div className={'column'}>
                {module === 1000 && <Row className={'container-buttons'}>
                    <Button onClick={() => goTo(`/perfil/${company}/3`)} variant={'secondary'} icon={faDrawPolygon}>Perfil</Button>
                    <Button onClick={() => goTo(`/perfil/${company}/4`)} variant={'info'} icon={faExternalLinkSquareAlt}>Perfil KOLO</Button>
                </Row>}
                <CustomTabs
                    loading={loading}
                    items={[
                        {title: `${module === 1000 ? 'Contratos' : 'Contratados'} (${linkContracted.length})`, info: `Contratos con ${module === 1000 ? 'Productores' : 'Transportistas'}`,
                            component: () => renderComponent(linkContracted)},
                        {title: `Solicitudes (${linkRequest.length})`, info: 'Solicitudes de enlaces y actualizaciones en contratos activos',
                            component: () => renderComponent(linkRequest, 5)},
                        {title: `Enlaces disponibles (${linkAvailable.length})`, info: 'Transportistas disponibles para enlaces de transporte', show: /*module === 2000*/ false,
                            component: () => renderComponent(linkAvailable, 3)},
                        {title: `Enlaces Kolo disponibles (${linkKoloAvailable.length})`, info: 'Transportistas disponibles para enlaces kolo', show: module === 2000,
                            component: () => renderComponent(linkKoloAvailable, 4)}
                    ]}/>
            </div></Col>
            <Col xl={1} lg={1} md={1} sm={12} xs={12}/>
        </Row>
    </div>);
};
export default KoloLink;
