import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useLocation } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { faDownload, faRoute } from '@fortawesome/free-solid-svg-icons'
import Alert from 'sweetalert-react'

import {
  Title,
  Card,
  Dropdown,
  Switch,
  Select,
  Icon,
  MapCard,
  Button,
  TableV2,
} from '../../../components'

import {
  actionTypes as typeO,
  getExcelByOrders,
  getRoute,
  setPositions,
} from '../../../actions/orders.actions'
import { selectOrderRoute } from '../../../selectors/orders.selector'

import { selectCurrentModule, selectCurrentUser } from '../../../selectors/user.selector'

import { loadingSelector } from '../../../selectors/loading.selector'
import {
  hasErrorsSelector,
  singleErrorSelector,
  handlerError,
  handlerSuccess,
} from '../../../selectors/error.selector'

import { arrayMove } from '../../../utils/utilities'

import { onSetModalOrder } from '../../../actions/utilities.actions'

import { getUsersChildrenByModule } from '../../../actions/modules.actions'
import { selectUsersToSelect } from '../../../selectors/modules.selector'

import { isAllowed } from '../../../selectors/modules.selector'
import Money from '../../../components/Money/Money'

const abc = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
]

const RoutePage = () => {
  const dispatch = useDispatch()

  const currentUser = useSelector(selectCurrentUser)
  const users = useSelector(selectUsersToSelect)
  const module = useSelector(selectCurrentModule)

  //const seeMyVisit = useSelector(state => isAllowed(state, [1605]))
  const seeAllVisit = useSelector(state => isAllowed(state, [1606]))

  const route = useSelector(selectOrderRoute)
  const loadingR = useSelector(state => loadingSelector([typeO.GET_ROUTE])(state))
  const hasError = useSelector(state => hasErrorsSelector([typeO.GET_ROUTE])(state))
  const error = useSelector(state => singleErrorSelector([typeO.GET_ROUTE])(state))
  const loadingPosition = useSelector(state =>
    loadingSelector([typeO.SET_POSITION])(state),
  )
  const PositionHasError = useSelector(state =>
    hasErrorsSelector([typeO.SET_POSITION])(state),
  )

  const [isKolo] = useState(useLocation().pathname.includes('/rutas/kolo'))

  const [actions, setActions] = useState({ get: false })
  const [alert, setAlert] = useState({ title: '' })
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({
    custom: false,
    deliveredBy: {
      value: null,
      label: 'Mi ruta',
    },
  })
  const [google, setGoogle] = useState({
    map: null,
    maps: null,
    directionsDisplay: null,
    directionsService: null,
  })
  const [items, setItems] = useState([])
  const [user, setUser] = useState({ label: '- Ninguno -', value: null })

  const onGoogleApiLoaded = (map, maps) => {
    const directionsService = new maps.DirectionsService()
    const directionsDisplay = new maps.DirectionsRenderer()
    directionsDisplay.setMap(map)
    setGoogle({ map, maps, directionsDisplay, directionsService })
    setLoading(false)
  }

  useEffect(() => {
    dispatch(getUsersChildrenByModule(module, true))
  }, [])

  useEffect(() => {
    if (!actions.get) setActions({ ...actions, get: true })
    else {
      setActions({ ...actions, get: false })
      if (PositionHasError)
        setAlert({
          ...handlerError('Error al ordenar las rutas'),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else {
        setAlert({
          ...handlerSuccess('Rutas ordenadas satisfactoriamente'),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      }
    }
  }, [loadingPosition])

  useEffect(() => {
    if (loading) return
    setUp()
    //setRoute(items);
  }, [loading])

  useEffect(() => {
    if (loadingR) setActions({ ...actions, get: true })
    else if (actions.get) {
      setActions({ ...actions, get: false })
      if (hasError)
        setAlert({
          ...handlerError(error.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else {
        const cItems = Object.assign([], route)
        console.log(cItems)
        setItems(cItems)
        setRoute(cItems)
      }
    }
  }, [loadingR])

  useEffect(() => {
    setUp()
  }, [user])

  const setUp = () => {
    dispatch(
      getRoute({
        size: null,
        skip: 0,
        type: !isKolo ? 0 : null,
        start: new Date().setHours(0, 0, 0, 0).valueOf(),
        end: new Date().setHours(23, 59, 59, 99).valueOf(),
        userid: seeAllVisit ? user.value : currentUser.id,
        all: false,
        isKolo,
      }),
    )
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return
    let cItems = Object.assign([], items)
    cItems = arrayMove(cItems, oldIndex, newIndex)
    setItems(cItems)
    setRoute(cItems)
  }

  const setRoute = o => {
    const { directionsDisplay, directionsService } = google
    let orders = Object.assign([], o)

    const waypoints = orders.map(o => ({
      location: { lat: parseFloat(o.latitude), lng: parseFloat(o.longitude) },
      stopover: true,
    }))
    let destination = null
    if (waypoints.length > 0) destination = waypoints.pop().location
    const origin = { lat: 14.55, lng: -90.55 }
    if (directionsService === null) return
    directionsService.route(
      {
        origin,
        destination: destination || origin,
        waypoints,
        optimizeWaypoints: false,
        travelMode: 'DRIVING',
      },
      (response, status) => {
        if (status === 'OK') {
          directionsDisplay.setDirections(response)
        }
      },
    )
  }

  const setNewPositions = () => {
    dispatch(setPositions(items.map(p => p.orderId)))
  }

  const customFilter = () => {
    return (
      <Row>
        <Col xl={6} md={6} sm={12} className={'borderRight mt-4'}>
          <Switch
            checked={filters.custom}
            label={'Ruta personalizada'}
            onChange={({ target }) => setFilters({ ...filters, custom: target.checked })}
          />
        </Col>
        {filters.custom && (
          <Col xl={6} md={6} sm={12} className={'borderRight mt-4'}>
            <Button
              icon={faRoute}
              color={'secondary'}
              right
              onClick={() => setNewPositions()}
              loading={loadingPosition}>
              Guardar
            </Button>
          </Col>
        )}
        {seeAllVisit && (
          <Col xl={6} md={6} sm={12}>
            <Select
              label={'Encargado'}
              options={[{ label: '- Ninguno -', value: null }, ...users]}
              value={user}
              onChange={item => setUser(item)}
            />
          </Col>
        )}
      </Row>
    )
  }

  return (
    <div>
      <Title title={'Rutas'} />

      <Row>
        <Col lg={4}>
          <Card
            title={'Ruta'}
            button={
              <Icon
                onClick={() => dispatch(getExcelByOrders(items.map(i => i.orderId)))}
                icon={faDownload}
                tooltip={'Descargar Ruta'}
              />
            }>
            <TableV2
              items={items || []}
              dragAndDrop={filters.custom}
              renderRow={(item, index) => (
                <div style={{ cursor: 'pointer' }} key={index} className={'b-user-item'}>
                  <div
                    className={'justify-content-start align-items-start bu-en'}
                    style={{ textAlign: 'left' }}>
                    <div className={'b-user-name'}>
                      ({abc[index + 1]}){item.companyName}
                    </div>
                    <div className={'b-user-email'}>
                      {item.number} - {item.reference}
                    </div>
                    <Money className={'b-user-balance-m'}>{item.totalOrder}</Money>
                  </div>
                  <div
                    className={'d-flex row align-items-center mr-2 bu-b'}
                    style={{ textAlign: 'right' }}>
                    {item.type !== 2 ? (
                      <Money className={'b-user-balance mr-2'}>{item.totalOrder}</Money>
                    ) : (
                      <div className={'b-user-balance mr-2'}>Visita</div>
                    )}
                    <Dropdown
                      items={[
                        {
                          title: 'Detalle Completo',
                          action: () => dispatch(onSetModalOrder(item.orderId)),
                        },
                        {
                          title: 'Abrir en Waze',
                          href: `https://www.waze.com/ul?ll=${item.latitude}%2C${item.longitude}&navigate=yes`,
                          target: '_none',
                        },
                      ]}
                    />
                  </div>
                </div>
              )}
              noItemsLegend={'No hay Órdenes en rutas con los filtros aplicados'}
              customFilter={customFilter()}
              loading={loading}
            />
            {/* <Table
                    items={items}
                    dragAndDrop={filters.custom}
                    onSortEnd={onSortEnd}
                    renderRow={(item, index) => <div style={{cursor: 'pointer'}}
                        key={index}
                        className={"b-user-item"}>
                        <div className={"justify-content-start align-items-start bu-en"} style={{textAlign: "left"}}>
                            <div className={"b-user-name"}>({abc[index + 1]}){item.companyName}</div>
                            <div className={"b-user-email"}>{item.number} - {item.reference}</div>
                            <Money className={"b-user-balance-m"}>{item.totalOrder}</Money>
                        </div>
                        <div className={"d-flex row align-items-center mr-2 bu-b"} style={{textAlign: "right"}}>
                            { item.type !== 2 ?
                                <Money className={"b-user-balance mr-2"}>{item.totalOrder}</Money> :
                                <div className={"b-user-balance mr-2"}>Visita</div>
                            }
                            <Dropdown items={[
                                {title: 'Detalle Completo', action: () => dispatch(onSetModalOrder(item.orderId))},
                                {title: 'Abrir en Waze', href: `https://www.waze.com/ul?ll=${item.latitude}%2C${item.longitude}&navigate=yes`, target: '_none'}
                            ]}/>
                        </div>
                    </div>}
                    noItemsLegend={'No hay Órdenes en rutas con los filtros aplicados'}
                    customFilter={customFilter()}
                    loading={loading}
                /> */}
          </Card>
        </Col>

        <Col lg={8}>
          <MapCard
            noCollapse
            latitude={14.55}
            longitude={-90.55}
            height={'80vh'}
            startCollapsed={false}
            markerList={[]}
            zoom={14}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => onGoogleApiLoaded(map, maps)}
          />
        </Col>
      </Row>

      <Alert {...alert} />
    </div>
  )
}
export default RoutePage
