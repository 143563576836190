import React from 'react'
import { Doughnut } from 'react-chartjs-2'

interface Props {
  data: IDataGraph
  tooltips?: ITooltipGraph
  height?: number
}

/**
 * Doughnut Chart component
 * @component
 * @param {IDataGraph} data List of items to display
 * @param {ITooltipGraph} tooltips Tooltip configuration
 * @param {number} height Height of the graph
 */
export const Donut = ({ data, tooltips, height = 150 }: Props) => {
  return (
    <Doughnut redraw data={data} height={height} options={tooltips ? { tooltips } : {}} />
  )
}
