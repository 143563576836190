import './Paragraph.scss'
import React from 'react'

const Paragraph = ({
  bold = false,
  className = '',
  children,
  dim = false,
  italic = false,
  size = 'medium',
  upper = false,
  ...props
}) => {
  className = `paragraph ${size} ${bold ? 'bold' : ''} ${dim ? 'dim' : ''} ${
    italic ? 'italic' : ''
  } ${className}`
  return (
    <p className={`paragraph ${className} ${upper ? 'upper-case' : ''}`} {...props}>
      {children}
    </p>
  )
}

export default Paragraph
