const orderStatusEnum = {
  ENTERED: 1,
  CONFIRMED: 2,
  COMPLETED: 3,
  REJECTED: 12,
  NULLIFY: 13,
  NULLIFY_PENDING: 19,
}

const orderStatsNames = {
  1: 'Ingresadas',
  2: 'Confirmadas',
  3: 'Confirmadas',
  12: 'Rechazada',
  13: 'Anulada',
  19: 'Anulación pendiente',
}

const getStatus = (status: number[]): ISelect[] => {
  return status.map(state => ({
    value: state,
    label: orderStatsNames[state],
  }))
}

const orderPaymentStatusEnum: number[] = [
  orderStatusEnum.ENTERED,
  orderStatusEnum.CONFIRMED,
  orderStatusEnum.REJECTED,
  orderStatusEnum.NULLIFY,
]

const getOrderPaymentStatus = (): ISelect[] => {
  return getStatus(orderPaymentStatusEnum)
}

const orderClientTransactionStatusEnum: number[] = [
  orderStatusEnum.COMPLETED,
  orderStatusEnum.NULLIFY,
  orderStatusEnum.NULLIFY_PENDING,
]

const getClientTransactionStatus = (): ISelect[] => {
  return getStatus(orderClientTransactionStatusEnum)
}

export { getOrderPaymentStatus, getClientTransactionStatus }
