import 'src/content/Warehouse/Warehouse.scss'

import React from 'react'
import { Link } from 'react-router-relative-link'
import IconMore from 'src/assets/v2/icon-more.svg'
import IconFilter from 'src/assets/v2/icon-filter.svg'

const Action = props => {
  const action = () => {
    return (
      <div className={props.action2 ? 'd-flex row' : ''}>
        <div
          onClick={() => (props.onClick ? props.onClick() : {})}
          className={
            props.className
              ? props.className
              : 'd-flex row align-items-center justify-content-end mt-2'
          }
          style={{ cursor: 'pointer' }}>
          {!props.hideIcon && (
            <img src={props.filter ? IconFilter : IconMore} alt={'+'} />
          )}
          <div className={'b-add-k'}>{props.actionTitle}</div>
        </div>
        {props.action2 && (
          <div
            onClick={() => props.action2.onClick()}
            className={
              props.className
                ? props.className
                : 'd-flex row align-items-center justify-content-end mt-2 ml-4'
            }
            style={{ cursor: 'pointer' }}>
            {!props.hideIcon && (
              <img src={props.filter ? IconFilter : IconMore} alt={'+'} />
            )}
            <div className={'b-add-k'}>{props.action2.title}</div>
          </div>
        )}
        {props.action3 && (
          <div
            onClick={() => props.action3.onClick()}
            className={
              props.className
                ? props.className
                : 'd-flex row align-items-center justify-content-end mt-2 ml-4'
            }
            style={{ cursor: 'pointer' }}>
            {!props.hideIcon && (
              <img src={props.filter ? IconFilter : IconMore} alt={'+'} />
            )}
            <div className={'b-add-k'}>{props.action3.title}</div>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={` ${props.fClassName ? props.fClassName : 'w-add-button-m'}`}>
      {props.action ? (
        props.to ? (
          <Link to={props.to || ''}>{action()}</Link>
        ) : (
          action()
        )
      ) : (
        ''
      )}
    </div>
  )
}
export default Action
