import { actionTypes } from '../actions/steps.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  client: {},
  order: {},
  products: [],
  warehouse: {},
}

const StepsReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.SET_WAREHOUSE_BY_STEPS):
      return {
        ...state,
        warehouse: extract(action.warehouse, {}),
      }
    case successState(actionTypes.SET_PRODUCTS_BY_STEPS):
      return {
        ...state,
        products: extract(action.products, []),
      }
    case successState(actionTypes.SET_ORDER_BY_STEPS):
      return {
        ...state,
        order: extract(action.order, {}),
      }
    case successState(actionTypes.SET_CLIENT_BY_STEPS):
      return {
        ...state,
        client: extract(action.client, {}),
      }
    default:
      return state ? state : initialState
  }
}

export default StepsReducer
