enum orderStatusEnum {
  ENTERED = 1,
  DELIVERED = 2,
  ANNULLED = 13,
}

enum orderCardLiquidationStatusEnum {
  PENDING = 1,
  COMPLETED = 2,
  WARNING = 3,
  NULLIFIED = 4,
}

const liquidationStatusEnum = {
  [orderCardLiquidationStatusEnum.PENDING]: 'En Proceso',
  [orderCardLiquidationStatusEnum.COMPLETED]: 'Completada',
  [orderCardLiquidationStatusEnum.WARNING]: 'Pendiente de revisión',
  [orderCardLiquidationStatusEnum.NULLIFIED]: 'Anulada',
}

export { orderStatusEnum, orderCardLiquidationStatusEnum, liquidationStatusEnum }
