import 'chartjs-plugin-labels'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Bar, Doughnut, HorizontalBar } from 'react-chartjs-2'
import { Col, Modal, ProgressBar, Row, Table } from 'react-bootstrap'
import {
  Button,
  CustomReference,
  Icon,
  Select,
  SelectedDates,
  TableV2,
  Title,
  Switch,
  Card,
  Folder,
  Money,
  Empty,
  ListCategories,
} from 'src/components'
import { faBook, faFilePdf, faSearch } from '@fortawesome/free-solid-svg-icons'

import { getWarehouseLite } from 'src/actions/warehouse.actions'
import { selectWarehouseLite } from 'src/selectors/warehouse.selector'

import {
  actionTypes as typesR,
  getWasteAndExpensesReportV2,
  getWastePdfReport,
} from 'src/actions/report.actions'
import {
  selectPurchaseWasteReport,
  selectQueriesReport,
} from 'src/selectors/report.selector'

import { types, getReport, getReportItem } from 'src/actions/inventory.actions'
import { selectInventoryReportItems } from 'src/selectors/inventory.selector'

import {
  actionTypes as action,
  getAllCategorizations,
} from 'src/actions/categorization.actions'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'

import { loadingSelector } from 'src/selectors/loading.selector'

import { selectCurrentModule, selectCurrentUser } from 'src/selectors/user.selector'

import { getUsersChildrenByModule } from 'src/actions/modules.actions'
import { isAllowed, selectUsers } from 'src/selectors/modules.selector'

import {
  formatDateFromMillis,
  formatNumberWithCommas,
  getItemValue,
} from 'src/utils/formatters'
import { onSetModalPurchase } from 'src/actions/utilities.actions'
import { toMoney } from 'src/utils/utilities'

import {
  optionsMobile,
  options,
  optionsMobileQuantity,
  optionsQuantity,
  chartOptionsMobile,
  chartOptions,
  chartOptionsQuantity,
} from '../options'
import { graphs as colorArray, quantityOptions } from 'src/utils/graphs'

const horizontalChartOptions = {
  maintainAspectRatio: true,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: true,
          color: '#EAECF2',
          drawBorder: false,
        },
        ticks: {
          fontColor: '#525252',
          fontFamily: 'Montserrat',
          beginAtZero: true,
        },
        barPercentage: 1.0,
        categoryPercentage: 0.5,
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
        barPercentage: 1.0,
        categoryPercentage: 0.5,
        scaleLabel: {
          display: true,
          labelString: 'Datos',
          fontColor: '#12A8BB',
        },
      },
    ],
  },
}

class ExpensesAndWaste extends Component {
  state = {
    advances: false,
    startDate: new Date(),
    endDate: new Date(),
    warehouses: [],
    users: [],
    key: 0,
    type: 1,
    quantities:
      (this.props.inPurchase && this.props.pSeeBalances) ||
      (this.props.eSeeBalances && !this.props.inPurchase)
        ? { value: 0, label: 'Montos' }
        : { value: 1, label: 'Cantidad' },
    quantityUsers: { value: 5, label: '5 Usuarios' },
    quantityProducts: { value: 10, label: '10 Ítems' },
    presentation: { value: 2, label: 'Barra Horizontal' },
    showCategorizations: false,
    selectedCategorization: [],
    typeG: { value: 2, label: 'Reporte por ítems' },
    item: {},
    modal: {},
    flagSetValues: false,
  }

  componentDidMount() {
    let type = window.location.href.includes('gastos')
      ? 2
      : window.location.href.includes('compras')
      ? 3
      : 1
    const { module } = this.props

    this.setState({ type }, () => this.getReport())
    this.props.getUsers(module)
    this.props.getAllWarehouses()
    this.props.getCategories(type === 1 ? 3 : type === 2 ? 2 : 6)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  UNSAFE_componentWillReceiveProps(next) {
    const { queries } = this.props

    if (queries?.params && !this.state.flagSetValues) {
      const { users } = this.state

      if (this.props.allUsers.length > 0) {
        this.setState({ flagSetValues: true })
        if (queries.params.userId) {
          let user = this.props.allUsers.find(user => user.id === queries.params.userId)
          users.push(user)
        }
        this.setState(
          {
            selectedUser: null,
            users,
            startDate: queries.params.start,
            endDate: queries.params.end,
          },
          () => this.getReport(),
        )
      }
    }
  }

  /*componentDidUpdate(prevProps) {
    const { users, warehouses } = this.state
    const { filterDates } = this.props
    let start = null
    let end = null

    if (filterDates !== prevProps.filterDates) {
      if (filterDates.start) start = new Date(filterDates.start)
      end = filterDates.end ? new Date(filterDates.end) : new Date()

      this.getReport({
        users,
        warehouses,
        startDate: start,
        endDate: end,
        advances: filterDates.advances,
      })
      this.setState({
        startDate: new Date(this.props.filterDates.start),
        endDate: new Date(this.props.filterDates.start),
        advances: filterDates.advances,
      })
    }
  }*/

  changeQuantity = item => {
    this.setState({ quantityUsers: item })
  }

  changeQuantityProducts = item => {
    this.setState({ quantityProducts: item })
  }

  getReport = auxiliar => {
    let {
      selectedCategorization,
      users,
      warehouses,
      startDate,
      endDate,
      type,
      id,
      itemType,
      advances,
    } = this.state

    if (auxiliar) {
      id = auxiliar.id
      itemType = auxiliar.itemType
    }

    const { inTab, filterDates } = this.props
    let start,
      end,
      products = []
    if (inTab) {
      start = filterDates.start ? new Date(filterDates.start) : new Date()
      end = filterDates.end ? new Date(filterDates.end) : new Date()
    } else {
      start = startDate ? new Date(startDate) : new Date(this.state.startDate)
      end = endDate ? new Date(endDate) : new Date(this.state.endDate)
    }
    let listU = users.map(p => {
      return p.value
    })
    let listW = warehouses.map(p => {
      return p.value
    })

    const ad = advances !== undefined ? advances : this.state.advances

    if (id) {
      let categories = selectedCategorization.map(p => p.id)
      if (itemType === 0) listW = [id]
      else if (itemType === 1) listU = [id]
      else if (itemType === 2) products = [id]
      else if (itemType === 3) categories = [id]
      this.props.getReportItem(
        start?.setHours(0, 0, 0, 0)?.valueOf(),
        end?.setHours(23, 59, 59, 59)?.valueOf(),
        listW,
        listU,
        categories,
        products,
        type || this.state.type,
        ad,
      )
    } else
      this.props.getReportV2(
        {
          warehouses: listW,
          users: listU,
          categories: selectedCategorization.map(p => p.id),
        },
        {
          start: start?.setHours(0, 0, 0, 0)?.valueOf(),
          end: end?.setHours(23, 59, 59, 59)?.valueOf(),
          type: this.state.type,
          segment: this.state.typeG?.value + 1,
          advances: ad,
        },
      )
  }

  getPdf = ({ users, warehouses, startDate, endDate, type }) => {
    const { selectedCategorization, advances } = this.state
    startDate = new Date(startDate || this.state.startDate)
    endDate = new Date(endDate || this.state.endDate)

    let listU = users.map(p => {
      return p.value
    })
    let listW = warehouses.map(p => {
      return p.value
    })
    let data = {
      warehouses: listW,
      users: listU,
      categories: selectedCategorization.map(p => p.id),
    }
    this.props.getPdfReport(
      startDate?.setHours(0, 0, 0, 0)?.valueOf(),
      endDate?.setHours(23, 59, 59, 59)?.valueOf(),
      type,
      data,
      type === 1
        ? 'Reporte de Merma'
        : type === 2
        ? 'Reporte de Gastos'
        : 'Reporte de Compras',
      advances,
    )
  }

  getHeaders = () => {
    return [
      { label: 'Descripción', show: true, value: ['name'], type: 'text' },
      { label: 'Fecha', show: true, value: ['date'], type: 'date' },
      { label: 'Responsable', show: true, value: ['referenceName'], type: 'text' },
      { label: 'Total', show: true, value: ['quantity'], type: 'currency' },
    ]
  }

  getItemsMobile = data => {
    const { user } = this.props
    let array = []
    let store = localStorage.getItem(`exp-${user.id}`)
    if (!store)
      store = {
        title: { value: ['name'], type: 'text' },
        subtitle: { value: ['date'], type: 'date' },
        complement1: { value: ['referenceName'], type: 'text' },
        complement2: { value: ['quantity'], type: 'currency' },
      }
    else store = JSON.parse(store)

    data.forEach(item => {
      let object = {
        id: item.id,
        title: getItemValue(item, store.title.value, store.title.type),
        subtitle: getItemValue(item, store.subtitle.value, store.subtitle.type),
        complement1: getItemValue(item, store.complement1.value, store.complement1.type),
        complement2: getItemValue(item, store.complement2.value, store.complement2.type),
        key: `exp-${item.id}`,
        action: (
          <Icon
            icon={faBook}
            size={'1_5x'}
            onClick={() => {
              if (this.state.type === 1)
                this.setState({ modal: { show: true, id: item.id } })
              else this.props.setPurchaseId(item.id)
            }}
          />
        ),
      }
      array.push(object)
    })
    return array
  }

  onSelectWarehouse = selectedWarehouse => {
    const { warehouses } = this.state
    warehouses.push(selectedWarehouse)
    this.setState({ warehouses })
  }

  onSelectUser = selectedUser => {
    const { users } = this.state
    users.push(selectedUser)
    this.setState({ users })
  }

  deleteWarehouse = id => {
    let { warehouses } = this.state
    warehouses = warehouses.filter(p => id !== p.value)
    this.setState({ warehouses })
  }

  deleteUser = id => {
    let { users } = this.state
    users = users.filter(p => id !== p.value)
    this.setState({ users })
  }

  getData = details => {
    const { quantities } = this.state
    let result = [0, 0, 0]
    if (!details) return result
    details.forEach(r => {
      result[1] += Number(quantities.value === 1 ? r.quantity : r.amount)
    })
    result[2] = result[1]

    /*details
      .filter(r => r.type === 1)
      .map(r => {
        result[0] += Number(quantities.value === 1 ? r.quantity : r.purchasePrice)
      })
    details
      .filter(r => r.type === 2)
      .map(r => {
        result[1] += Number(quantities.value === 1 ? r.quantity : r.purchasePrice)
      })
    details
      .filter(r => r.type === 3)
      .map(r => {
        result[2] += Number(quantities.value === 1 ? r.quantity : r.purchasePrice)
      })*/
    return result
  }

  getInfo = (type, filter) => {
    const { report } = this.props

    let data
    let dataValues = []

    report
      .filter(
        f =>
          f.type === filter &&
          f.products.length > 0 &&
          f.products.filter(p => p.type === type + 1).length > 0,
      )
      .forEach(p => {
        dataValues.push({
          label: p.label,
          value: Number(this.getData(p.products)[type]).toFixed(2),
        })
      })

    data = [...dataValues].sort((a, b) => b.value - a.value) //.slice(0, quantityUsers.value)

    return {
      labels: data.map(d => d.label),
      datasets: [
        {
          data: data.map(d => d.value),
          label: data.map(d => d.label),
          borderColor: data.map((d, i) => colorArray[i]),
          borderWidth: 2,
          backgroundColor: data.map((d, i) => colorArray[i] + '66'),
          //hoverBackgroundColor: data.map(this.createRandomColor),
        },
      ],
    }
  }

  getInfoChartBar = type => {
    const { report } = this.props
    const { quantities } = this.state

    const data = [...report]
    const reportFiltered = data.filter(
      f => f.type === 2 && f.products.filter(fp => fp.type === type).length > 0,
    )

    const products = []
    reportFiltered.forEach(itemU => {
      itemU.products
        .filter(
          f =>
            (quantities.value === 1 ? f.quantity : f.purchasePrice) > 0 &&
            f.type === type,
        )
        .forEach(itemP => {
          const product = products.find(p => p.id === itemP.id)
          if (!product) {
            products.push({ ...itemP })
          } else {
            if (quantities.value === 1)
              products[products.indexOf(product)].quantity += itemP.quantity
            else products[products.indexOf(product)].purchasePrice += itemP.purchasePrice
          }
        })
    })

    const productsData = [...products].sort((a, b) =>
      quantities.value === 1
        ? b.quantity - a.quantity
        : b.purchasePrice - a.purchasePrice,
    ) //.slice(0, quantityProducts.value)

    return {
      labels: productsData.map(p => p.name),
      datasets: [
        {
          label: productsData.map(p => p.name),
          data: productsData.map(p =>
            Number(quantities.value === 1 ? p.quantity : p.purchasePrice).toFixed(2),
          ),
          backgroundColor: productsData.map((p, i) => colorArray[i] + '66'),
          borderColor: productsData.map((p, i) => colorArray[i]),
          borderWidth: 2,
        },
      ],
    }
  }

  getChartHorizontalBar = type => {
    const { report } = this.props

    let dataValues = report.map(p => ({
      label: p.label,
      value: Number(this.getData(p.products)[type]).toFixed(2),
    }))
    const data = [...dataValues].sort((a, b) => b.value - a.value) //.slice(0, quantityUsers.value)

    return {
      labels: data.map(p => p.label),
      datasets: [
        {
          label: 'Valor',
          data: data.map(d => d.value),
          backgroundColor: dataValues.map((p, i) => colorArray[i] + '66'),
          borderColor: dataValues.map((p, i) => colorArray[i]),
          borderWidth: 2,
        },
      ],
    }
  }

  getChartBar = type => {
    const { report } = this.props

    let data
    let dataValues = []

    report
      /*.filter(
        f =>
          f.type === filter &&
          f.products.length > 0 &&
          f.products.filter(p => p.type === type + 1).length > 0,
      )*/
      .forEach(p => {
        dataValues.push({
          label: p.label,
          value: Number(this.getData(p.products)[type]).toFixed(2),
        })
      })

    data = [...dataValues].sort((a, b) => b.value - a.value) //.slice(0, quantityUsers.value)
    return {
      labels: ['Valor'],
      datasets: data.map((d, i) => ({
        data: [Number(d.value)],
        label: d.label,
        borderColor: colorArray[i],
        borderWidth: 2,
        backgroundColor: colorArray[i] + '66',
      })),
    }
  }

  getProductsInfo = reportFiltered => {
    const { quantities } = this.state

    const products = []
    reportFiltered
      // .filter(d => d.type === 2)
      .forEach(itemU => {
        itemU.products
          .filter(f => (quantities.value === 1 ? f.quantity : f.amount) > 0)
          .forEach(itemP => {
            const product = products.find(p => p.id === itemP.id)
            if (!product) {
              products.push({ ...itemP })
            } else {
              if (quantities.value === 1)
                products[products.indexOf(product)].quantity += itemP.quantity
              else products[products.indexOf(product)].purchasePrice += itemP.amount
            }
          })
      })

    return products
  }

  /**
   * Obtains the data to show in table.
   * @returns Array
   */
  getChartData = () => {
    const { report } = this.props

    let dataValues = report.map(p => ({
      label: p.label,
      value: Number(this.getData(p.products)[1]).toFixed(2),
      id: p.id,
    }))

    return [...dataValues].sort((a, b) => b.value - a.value)
  }

  getProductChartBar = type => {
    const { report } = this.props
    const { quantities } = this.state

    const data = [...report]

    let productsData = this.getProductsInfo(data, type)

    return {
      labels: ['Ítems'],
      datasets: productsData.map((p, i) => ({
        label: p.name,
        data: [Number(quantities.value === 1 ? p.quantity : p.purchasePrice).toFixed(2)],
        backgroundColor: colorArray[i] + '66',
        borderColor: colorArray[i],
        borderWidth: 2,
      })),
      /*[
                {
                    label: productsData.map(p => p.name),
                    data: productsData.map(p => Number(quantities.value === 1 ? p.quantity : p.purchasePrice).toFixed(2)),
                    backgroundColor: productsData.map((p, i) => colorArray[i] + '66'),
                    borderColor: productsData.map((p, i) => colorArray[i]),
                    borderWidth: 2,
                },
            ],*/
    }
  }

  getCategoriesInfo = (reportFiltered, type) => {
    const { quantities } = this.state

    const categories = []
    reportFiltered
      .filter(d => d.type === 2)
      .forEach(itemU => {
        itemU.products
          .filter(
            f =>
              (quantities.value === 1 ? f.quantity : f.purchasePrice) > 0 &&
              f.type === type,
          )
          .forEach(itemP => {
            itemP.assigned.forEach(cat => {
              const category = categories.find(c => c.id === cat.id)
              if (!category) {
                categories.push({
                  ...cat,
                  quantity: itemP.quantity,
                  purchasePrice: itemP.purchasePrice,
                })
              } else {
                if (quantities.value === 1)
                  categories[categories.indexOf(category)].quantity += itemP.quantity
                else
                  categories[categories.indexOf(category)].purchasePrice +=
                    itemP.purchasePrice
              }
            })
          })
      })

    return categories
  }

  getCategoriesChartBar = type => {
    const { report } = this.props
    const { quantities } = this.state

    const data = [...report]

    let categoriesData = this.getCategoriesInfo(data, type)

    return {
      labels: ['Ítems'],
      datasets: categoriesData.map((p, i) => ({
        label: p.name,
        data: [Number(quantities.value === 1 ? p.quantity : p.purchasePrice).toFixed(2)],
        backgroundColor: colorArray[i] + '66',
        borderColor: colorArray[i],
        borderWidth: 2,
      })),
      /*[
                {
                    label: productsData.map(p => p.name),
                    data: productsData.map(p => Number(quantities.value === 1 ? p.quantity : p.purchasePrice).toFixed(2)),
                    backgroundColor: productsData.map((p, i) => colorArray[i] + '66'),
                    borderColor: productsData.map((p, i) => colorArray[i]),
                    borderWidth: 2,
                },
            ],*/
    }
  }

  renderChart = filter => {
    const { report } = this.props
    const { type, quantities, presentation } = this.state
    let quantity1 = 0,
      quantity2 = 0,
      quantity3 = 0
    report
      // .filter(f => f.type === filter)
      .forEach(p => {
        quantity1 += this.getData(p.products)[1]
        quantity2 += this.getData(p.products)[1]
        quantity3 += this.getData(p.products)[2]
      })

    return (
      <div>
        {type === 1 ? (
          <Row>
            <Col>
              <Card>
                <div>
                  <div className="g-title">
                    {quantities.value === 1
                      ? `Merma (${formatNumberWithCommas(quantity1)} U.)`
                      : `Merma (${toMoney(quantity1)})`}
                  </div>
                  {quantity1 > 0 ? (
                    <>
                      <div className="ru-graph-w">
                        {presentation.value === 0 ? (
                          <Doughnut
                            data={this.getInfo(1, filter)}
                            height={200}
                            options={quantities.value === 1 ? optionsQuantity : options}
                          />
                        ) : presentation.value === 1 ? (
                          <Bar
                            data={this.getChartBar(1, filter)}
                            height={200}
                            options={
                              quantities.value === 1 ? chartOptionsQuantity : chartOptions
                            }
                          />
                        ) : (
                          <HorizontalBar
                            data={this.getChartHorizontalBar(1, filter)}
                            height={200}
                            options={
                              quantities.value === 1
                                ? chartOptionsQuantity
                                : horizontalChartOptions
                            }
                          />
                        )}
                      </div>
                      <div className="ru-graph-m">
                        {presentation.value === 0 ? (
                          <Doughnut
                            data={this.getInfo(1, filter)}
                            height={400}
                            options={
                              quantities.value === 1
                                ? optionsMobileQuantity
                                : optionsMobile
                            }
                          />
                        ) : presentation.value === 1 ? (
                          <Bar
                            data={this.getChartBar(1, filter)}
                            height={200}
                            options={
                              quantities.value === 1
                                ? chartOptionsQuantity
                                : chartOptionsMobile
                            }
                          />
                        ) : (
                          <HorizontalBar
                            data={this.getChartHorizontalBar(1, filter)}
                            height={200}
                            options={
                              quantities.value === 1
                                ? chartOptionsQuantity
                                : chartOptionsMobile
                            }
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <Empty onClick={() => this.getReport()} />
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        ) : type === 2 ? (
          <Row>
            <Col>
              <Card>
                <div>
                  <div className="g-title">
                    {quantities.value === 1
                      ? `Gastos (${formatNumberWithCommas(quantity2)} U.)`
                      : `Gastos (${toMoney(quantity2)})`}
                  </div>

                  {quantity2 > 0 ? (
                    <>
                      <div className="ru-graph-w">
                        {presentation.value === 0 ? (
                          <Doughnut
                            data={this.getInfo(1, filter)}
                            height={400}
                            options={quantities.value === 1 ? optionsQuantity : options}
                          />
                        ) : presentation.value === 1 ? (
                          <Bar
                            data={this.getChartBar(1, filter)}
                            height={200}
                            options={
                              quantities.value === 1 ? chartOptionsQuantity : chartOptions
                            }
                          />
                        ) : (
                          <HorizontalBar
                            data={this.getChartHorizontalBar(1, filter)}
                            height={200}
                            options={
                              quantities.value === 1
                                ? chartOptionsQuantity
                                : horizontalChartOptions
                            }
                          />
                        )}
                      </div>
                      <div className="ru-graph-m">
                        {presentation.value === 0 ? (
                          <Doughnut
                            data={this.getInfo(1, filter)}
                            height={400}
                            options={
                              quantities.value === 1
                                ? optionsMobileQuantity
                                : optionsMobile
                            }
                          />
                        ) : presentation.value === 1 ? (
                          <Bar
                            data={this.getChartBar(1, filter)}
                            height={200}
                            options={
                              quantities.value === 1
                                ? chartOptionsQuantity
                                : chartOptionsMobile
                            }
                          />
                        ) : (
                          <HorizontalBar
                            data={this.getChartHorizontalBar(1, filter)}
                            height={200}
                            options={
                              quantities.value === 1
                                ? chartOptionsQuantity
                                : chartOptionsMobile
                            }
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <Empty onClick={() => this.getReport()} />
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              <Card>
                <div>
                  <div className="g-title">
                    {quantities.value === 1
                      ? `Compras (${formatNumberWithCommas(quantity3)} U.)`
                      : `Compras (${toMoney(quantity3)})`}
                  </div>
                  {quantity3 > 0 ? (
                    <>
                      <div className="ru-graph-w">
                        {presentation.value === 0 ? (
                          <Doughnut
                            data={this.getInfo(2, filter)}
                            height={400}
                            options={quantities ? optionsQuantity : options}
                          />
                        ) : presentation.value === 1 ? (
                          <Bar
                            data={this.getChartBar(2, filter)}
                            height={200}
                            options={
                              quantities.value === 1 ? chartOptionsQuantity : chartOptions
                            }
                          />
                        ) : (
                          <HorizontalBar
                            data={this.getChartHorizontalBar(2, filter)}
                            height={200}
                            options={
                              quantities.value === 1
                                ? chartOptionsQuantity
                                : horizontalChartOptions
                            }
                          />
                        )}
                      </div>
                      <div className="ru-graph-m">
                        {presentation.value === 0 ? (
                          <Doughnut
                            data={this.getInfo(2, filter)}
                            height={400}
                            options={
                              quantities.value === 1
                                ? optionsMobileQuantity
                                : optionsMobile
                            }
                          />
                        ) : presentation.value === 1 ? (
                          <Bar
                            data={this.getChartBar(2, filter)}
                            height={200}
                            options={
                              quantities.value === 1
                                ? chartOptionsQuantity
                                : chartOptionsMobile
                            }
                          />
                        ) : (
                          <HorizontalBar
                            data={this.getChartHorizontalBar(2, filter)}
                            height={200}
                            options={
                              quantities.value === 1
                                ? chartOptionsQuantity
                                : chartOptionsMobile
                            }
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <Empty onClick={() => this.getReport()} />
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    )
  }

  renderProductChart = typeG => {
    const { report } = this.props
    const { type, quantities, presentation } = this.state
    let quantity1 = 0,
      quantity2 = 0,
      quantity3 = 0
    report
      .filter(f => f.type === 2 && f.products.length > 0)
      .forEach(p => {
        if (typeG === 3) {
          const prod = p.products.filter(d => d.assigned && d.assigned.length > 0)
          quantity1 += this.getData(prod)[0]
          quantity2 += this.getData(prod)[1]
          quantity3 += this.getData(prod)[2]
        } else {
          quantity1 += this.getData(p.products)[0]
          quantity2 += this.getData(p.products)[1]
          quantity3 += this.getData(p.products)[2]
        }
      })
    return (
      <div>
        {type === 1 ? (
          <Row>
            <Col>
              <Card>
                <div>
                  <div className="g-title">
                    {quantities.value === 1
                      ? `Merma (${formatNumberWithCommas(quantity1)} U.)`
                      : `Merma (${toMoney(quantity1)})`}
                  </div>
                  {quantity1 > 0 ? (
                    <>
                      <div className="ru-graph-w">
                        {presentation.value === 0 ? (
                          <Doughnut
                            data={this.getInfoChartBar(1)}
                            height={400}
                            options={quantities.value === 1 ? optionsQuantity : options}
                          />
                        ) : presentation.value === 1 ? (
                          <Bar
                            data={this.getProductChartBar(1)}
                            height={200}
                            options={
                              quantities.value === 1 ? chartOptionsQuantity : chartOptions
                            }
                          />
                        ) : (
                          <HorizontalBar
                            data={
                              typeG === 2
                                ? this.getProductChartBar(1)
                                : this.getCategoriesChartBar(1)
                            }
                            height={200}
                            options={
                              quantities.value === 1
                                ? chartOptionsQuantity
                                : horizontalChartOptions
                            }
                          />
                        )}
                      </div>
                      <div className="ru-graph-m">
                        {presentation.value === 0 ? (
                          <Doughnut
                            data={this.getInfoChartBar(1)}
                            height={400}
                            options={
                              quantities.value === 1
                                ? optionsMobileQuantity
                                : optionsMobile
                            }
                          />
                        ) : presentation.value === 1 ? (
                          <Bar
                            data={this.getProductChartBar(1)}
                            height={200}
                            options={
                              quantities.value === 1
                                ? chartOptionsQuantity
                                : chartOptionsMobile
                            }
                          />
                        ) : (
                          <HorizontalBar
                            data={
                              typeG === 2
                                ? this.getProductChartBar(1)
                                : this.getCategoriesChartBar(1)
                            }
                            height={200}
                            options={
                              quantities.value === 1
                                ? chartOptionsQuantity
                                : chartOptionsMobile
                            }
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <Empty onClick={() => this.getReport()} />
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        ) : type === 2 ? (
          <Row>
            <Col>
              <Card>
                <div>
                  <div className="g-title">
                    {quantities.value === 1
                      ? `Gastos (${formatNumberWithCommas(quantity2)} U.)`
                      : `Gastos (${toMoney(quantity2)})`}
                  </div>
                  {quantity2 > 0 ? (
                    <>
                      <div className="ru-graph-w">
                        {presentation.value === 0 ? (
                          <Doughnut
                            data={this.getInfoChartBar(2)}
                            height={400}
                            options={quantities.value === 1 ? optionsQuantity : options}
                          />
                        ) : presentation.value === 1 ? (
                          <Bar
                            data={this.getProductChartBar(2)}
                            height={200}
                            options={
                              quantities.value === 1 ? chartOptionsQuantity : chartOptions
                            }
                          />
                        ) : (
                          <HorizontalBar
                            data={
                              typeG === 2
                                ? this.getProductChartBar(2)
                                : this.getCategoriesChartBar(2)
                            }
                            height={200}
                            options={
                              quantities.value === 1
                                ? chartOptionsQuantity
                                : horizontalChartOptions
                            }
                          />
                        )}
                      </div>
                      <div className="ru-graph-m">
                        {presentation.value === 0 ? (
                          <Doughnut
                            data={this.getInfoChartBar(2)}
                            height={400}
                            options={
                              quantities.value === 1
                                ? optionsMobileQuantity
                                : optionsMobile
                            }
                          />
                        ) : presentation.value === 1 ? (
                          <Bar
                            data={this.getProductChartBar(2)}
                            height={200}
                            options={
                              quantities.value === 1
                                ? chartOptionsQuantity
                                : chartOptionsMobile
                            }
                          />
                        ) : (
                          <HorizontalBar
                            data={
                              typeG === 2
                                ? this.getProductChartBar(2)
                                : this.getCategoriesChartBar(2)
                            }
                            height={200}
                            options={
                              quantities.value === 1
                                ? chartOptionsQuantity
                                : chartOptionsMobile
                            }
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <Empty onClick={() => this.getReport()} />
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              <Card>
                <div>
                  <div className="g-title">
                    {quantities.value === 1
                      ? `Compras (${formatNumberWithCommas(quantity3)} U.)`
                      : `Compras (${toMoney(quantity3)})`}
                  </div>
                  {quantity3 > 0 ? (
                    <>
                      <div className="ru-graph-w">
                        {presentation.value === 0 ? (
                          <Doughnut
                            data={this.getInfoChartBar(3)}
                            height={400}
                            options={quantities.value === 1 ? optionsQuantity : options}
                          />
                        ) : presentation.value === 1 ? (
                          <Bar
                            data={this.getProductChartBar(3)}
                            height={200}
                            options={
                              quantities.value === 1 ? chartOptionsQuantity : chartOptions
                            }
                          />
                        ) : (
                          <HorizontalBar
                            data={
                              typeG === 2
                                ? this.getProductChartBar(3)
                                : this.getCategoriesChartBar(3)
                            }
                            height={200}
                            options={
                              quantities.value === 1
                                ? chartOptionsQuantity
                                : horizontalChartOptions
                            }
                          />
                        )}
                      </div>
                      <div className="ru-graph-m">
                        {presentation.value === 0 ? (
                          <Doughnut
                            data={this.getInfoChartBar(3)}
                            height={400}
                            options={
                              quantities.value === 1
                                ? optionsMobileQuantity
                                : optionsMobile
                            }
                          />
                        ) : presentation.value === 1 ? (
                          <Bar
                            data={this.getProductChartBar(3)}
                            height={200}
                            options={
                              quantities.value === 1
                                ? chartOptionsQuantity
                                : chartOptionsMobile
                            }
                          />
                        ) : (
                          <HorizontalBar
                            data={
                              typeG === 2
                                ? this.getProductChartBar(3)
                                : this.getCategoriesChartBar(3)
                            }
                            height={200}
                            options={
                              quantities.value === 1
                                ? chartOptionsQuantity
                                : chartOptionsMobile
                            }
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <Empty onClick={() => this.getReport()} />
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    )
  }
  getOptions = () => {
    const { inPurchase, pSeeBalances, eSeeBalances } = this.props
    const option = []
    if ((inPurchase && pSeeBalances) || (eSeeBalances && !inPurchase))
      option.push({ value: 0, label: 'Montos' })
    option.push({ value: 1, label: 'Cantidad' })
    return option
  }

  onAssignCategory = category => {
    const { selectedCategorization } = this.state
    const customSelected = Object.assign([], selectedCategorization)
    const index = customSelected.findIndex(c => c.id === category.id)
    if (index === -1) customSelected.push(category)
    else customSelected.splice(index, 1)
    this.setState({ selectedCategorization: customSelected })
  }

  filters = () => {
    const {
      typeG,
      quantities,
      quantityProducts,
      users,
      warehouses,
      type,
      selectedCategorization,
      advances,
    } = this.state
    const { allUsers, allWarehouses } = this.props
    return (
      <Row>
        <Col xl={4} lg={4} md={4} sm={6} xs={12}>
          <Select
            label={'Tipo de reporte'}
            options={[
              { value: 0, label: 'Reporte por bodegas' },
              { value: 1, label: 'Reporte por usuarios' },
              { value: 2, label: 'Reporte por ítems' },
              { value: 3, label: 'Reporte por categorías' },
            ]}
            value={typeG}
            onChange={value => this.setState({ typeG: value })}
          />
        </Col>
        {typeG.value === 2 && (
          <Col xl={4} lg={4} md={4} sm={6} xs={12}>
            <Select
              label={'Cantidad de ítems'}
              name={'products_quantity'}
              value={quantityProducts}
              options={quantityOptions}
              onChange={this.changeQuantityProducts}
            />
          </Col>
        )}

        <Col xl={4} lg={4} md={4} sm={6} xs={12}>
          <Select
            label={'Valor de presentación'}
            options={this.getOptions()}
            value={quantities}
            onChange={value => this.setState({ quantities: value })}
          />
        </Col>

        {type !== 1 && (
          <Col xl={4} lg={4} md={4} sm={6} xs={12}>
            <Switch
              mt={4}
              topLabel
              label={'Ver adelanto a proveedores'}
              checked={advances}
              changeValue={v => this.setState({ advances: v })}
            />
          </Col>
        )}

        <Col xl={12}>
          <Row>
            <Col xl={6} lg={6} md={6} sm={12}>
              <div className={'column'}>
                <Select
                  label={'Filtrar por bodegas'}
                  value={null}
                  options={allWarehouses.filter(s => {
                    return warehouses.filter(f => f.value === s.value).length === 0
                  })}
                  onChange={this.onSelectWarehouse}
                />

                <ListCategories
                  onRemove={w => this.deleteWarehouse(w.value)}
                  items={warehouses}
                />
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={12}>
              <div className={'column'}>
                <Select
                  label={'Filtrar por usuarios'}
                  value={null}
                  options={allUsers.filter(s => {
                    return users.filter(f => f.value === s.value).length === 0
                  })}
                  onChange={this.onSelectUser}
                />

                <ListCategories onRemove={w => this.deleteUser(w.value)} items={users} />
              </div>
            </Col>

            <Col xl={6} lg={6} md={6} sm={12}>
              <div className={'column mt-5'}>
                <Button
                  color={'primary'}
                  onClick={() => {
                    this.props.getCategories(type === 1 ? 3 : type === 2 ? 2 : 6)
                    this.setState({ showCategorizations: true })
                  }}>
                  Filtrar por Categorías
                </Button>

                <ListCategories
                  onRemove={this.onAssignCategory}
                  items={selectedCategorization}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col xl={12}>
          <Row className={'container-buttons'}>
            <Button
              loading={this.props.reportIsLoading}
              color={'accent'}
              onClick={() => this.getReport()}
              icon={faSearch}>
              Aplicar Filtros
            </Button>
          </Row>
        </Col>
      </Row>
    )
  }

  render() {
    const {
      users,
      warehouses,
      type,
      quantities,
      selectedCategorization,
      showCategorizations,
      typeG,
      item,
    } = this.state
    const { reportIsLoading, categorization, inTab, queries } = this.props

    return (
      <div>
        {!inTab && (
          <Title
            title={
              type === 1
                ? 'Reporte de Reducción de Inventario'
                : type === 2
                ? 'Reporte de Gastos'
                : 'Reporte de Compras'
            }
          />
        )}
        <Card
          title={'filtros'}
          white
          button={
            <Icon
              tooltip={'Descargar en PDF'}
              icon={faFilePdf}
              spin={this.props.reportPDF}
              onClick={() => this.getPdf({ users, warehouses, type })}></Icon>
          }>
          {!inTab ? (
            <SelectedDates
              dates={{
                dateFrom:
                  queries?.params?.start || this.state.startDate
                    ? new Date(queries?.params?.start || this.state.startDate)
                    : new Date(),
                dateTo:
                  queries?.params?.end || this.state.endDate
                    ? new Date(queries?.params?.end || this.state.endDate)
                    : new Date(),
              }}
              filter
              setValueDates
              onDateChange={(start, end) => {
                this.setState({ startDate: start, endDate: end }, () => this.getReport())
              }}
              customFilter={this.filters()}
              withOptionNull
            />
          ) : (
            this.filters()
          )}
        </Card>
        {
          <Card>
            {reportIsLoading && (
              <Row style={{ marginTop: '15px' }}>
                <Col>
                  <div className={'pb-custom'}>
                    <ProgressBar
                      label="Cargando"
                      animated
                      now={100}
                      style={{ marginBottom: 10 }}
                    />
                  </div>
                </Col>
              </Row>
            )}
            <Row style={{ marginTop: ''.concat(!reportIsLoading ? '15px' : '0px') }}>
              <Col>
                <Row>
                  <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                    {typeG.value !== 5
                      ? this.renderChart(1)
                      : typeG.value === 1
                      ? this.renderChart(2)
                      : this.renderProductChart(typeG.value)}
                  </Col>
                  <Col>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th style={{ width: 70 }} />
                          <th>Nombre</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        {typeG.value !== 5
                          ? this.getChartData().map((p, index) => (
                              <tr
                                key={index}
                                className={'g-row'}
                                onClick={() => {
                                  if (typeG.value !== 2) {
                                    this.getReport({
                                      id: p.id,
                                      itemType: typeG.value,
                                    })
                                    this.setState({ item: { show: true, name: p.label } })
                                  }
                                }}>
                                <td className={'index-container'}>
                                  {index + 1}
                                  <div
                                    className="g-color"
                                    style={{
                                      backgroundColor: colorArray[index] + '66',
                                      borderColor: colorArray[index],
                                      borderWidth: 2,
                                    }}
                                  />
                                </td>
                                <td className={'text-left'}>{p.label}</td>
                                {quantities.value === 1 ? (
                                  <td className={'text-right'}>
                                    {p.value}
                                    U.
                                  </td>
                                ) : (
                                  <Money component="td" className={'text-right'}>
                                    {p.value}
                                  </Money>
                                )}
                              </tr>
                            ))
                          : typeG.value === 2
                          ? this.getProductsInfo([...this.props.report], type).map(
                              (p, index) => (
                                <tr
                                  key={index}
                                  className={'g-row'}
                                  onClick={() => {
                                    this.getReport({
                                      id: p.id,
                                      itemType: typeG.value,
                                    })
                                    this.setState({ item: { show: true, name: p.name } })
                                  }}>
                                  <td className={'index-container'}>
                                    {index + 1}
                                    <div
                                      className="g-color"
                                      style={{
                                        backgroundColor: colorArray[index] + '66',
                                        borderColor: colorArray[index],
                                        borderWidth: 2,
                                      }}
                                    />
                                  </td>
                                  <td className={'text-left'}>{p.name}</td>
                                  {quantities.value === 1 ? (
                                    <td className={'text-right'}>
                                      {formatNumberWithCommas(p.quantity)} U.
                                    </td>
                                  ) : (
                                    <Money component="td" className={'text-right'}>
                                      {p.purchasePrice}
                                    </Money>
                                  )}
                                </tr>
                              ),
                            )
                          : this.getCategoriesInfo([...this.props.report], type).map(
                              (p, index) => (
                                <tr
                                  key={index}
                                  className={'g-row'}
                                  onClick={() => {
                                    this.getReport({
                                      id: p.id,
                                      itemType: typeG.value,
                                    })
                                    this.setState({ item: { show: true, name: p.name } })
                                  }}>
                                  <td className={'index-container'}>
                                    {index + 1}
                                    <div
                                      className="g-color"
                                      style={{
                                        backgroundColor: colorArray[index] + '66',
                                        borderColor: colorArray[index],
                                        borderWidth: 2,
                                      }}
                                    />
                                  </td>
                                  <td className={'text-left'}>{p.name}</td>
                                  {quantities.value === 1 ? (
                                    <Money component="td" className={'text-right'}>
                                      {p.quantity}
                                    </Money>
                                  ) : (
                                    <Money component="td" className={'text-right'}>
                                      {p.purchasePrice}
                                    </Money>
                                  )}
                                  <Money component="td" className={'text-right'}>
                                    {quantities.value === 1
                                      ? p.quantity
                                      : p.purchasePrice}
                                  </Money>
                                </tr>
                              ),
                            )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Folder
              noMessage
              list={selectedCategorization.map(p => p.id)}
              onHide={() => this.setState({ showCategorizations: false })}
              onAssign={this.onAssignCategory}
              data1={
                categorization && categorization.children
                  ? categorization.children[0]
                  : {}
              }
              data2={
                categorization && categorization.children
                  ? categorization.children[1]
                  : {}
              }
              show={showCategorizations}
            />
          </Card>
        }

        <Modal
          show={item.show}
          onHide={() => this.setState({ item: {} })}
          size={'md'}
          centered>
          <Modal.Header closeButton>
            <Modal.Title>{item.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TableV2
              loading={this.props.reportItemsIsLoading}
              headers={this.getHeaders()}
              items={this.props.reportItems}
              itemsMobile={this.getItemsMobile(this.props.reportItems)}
              renderRow={(item, index) => (
                <tr className={'data'} key={index}>
                  <td className={'text-left'}>{item.name}</td>
                  <td className={'text-left'}>{formatDateFromMillis(item.date)}</td>
                  <td className={'text-left'}>{item.referenceName}</td>
                  <td className={`text-left`}>{formatNumberWithCommas(item.quantity)}</td>
                  <td className={`text-left`}>
                    <Icon
                      icon={faBook}
                      size={'1_5x'}
                      onClick={() => {
                        if (type === 1)
                          this.setState({ modal: { show: true, id: item.id } })
                        else this.props.setPurchaseId(item.id)
                      }}
                    />
                  </td>
                </tr>
              )}
            />
          </Modal.Body>
        </Modal>

        <CustomReference
          show={this.state.modal.show}
          documentModule={5}
          documentType={2}
          documentId={this.state.modal.id}
          onHide={() => this.setState({ modal: {} })}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  queries: selectQueriesReport(state),
  user: selectCurrentUser(state),
  allWarehouses: selectWarehouseLite(state),
  reportIsLoading: loadingSelector([typesR.PURCHASE_WASTE_REPORT])(state), // loadingSelector([types.GET_REPORT])(state),
  reportPDF: loadingSelector([typesR.GET_PDF])(state),
  reportItems: selectInventoryReportItems(state),
  reportItemsIsLoading: loadingSelector([types.GET_REPORT_ITEM])(state),
  allUsers: selectUsers(state),
  module: selectCurrentModule(state),
  report: selectPurchaseWasteReport(state),
  categorization: selectAllCategorizations(state),
  categorizationLoading: loadingSelector([action.GET_ALL])(state),
  pSeeBalances: isAllowed(state, [1973, 6070]),
  eSeeBalances: isAllowed(state, [12417, 12465]),
})

const mapDispatchToProps = dispatch => ({
  setPurchaseId: id => dispatch(onSetModalPurchase(id)),
  getUsers: module => dispatch(getUsersChildrenByModule(module, true)),
  getAllWarehouses: () => dispatch(getWarehouseLite({ all: true })),
  getPdfReport: (start, end, type, data, name, advances) =>
    dispatch(getWastePdfReport(start, end, type, data, name, advances)),
  getReport: (start, end, warehouses, users, categories, type, advances) =>
    dispatch(getReport(start, end, warehouses, users, categories, type, advances)),
  getReportV2: (request, params) =>
    dispatch(getWasteAndExpensesReportV2(request, params)),
  getReportItem: (start, end, warehouses, users, categories, products, type, advances) =>
    dispatch(
      getReportItem(start, end, warehouses, users, categories, products, type, advances),
    ),
  getCategories: type => dispatch(getAllCategorizations(type)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ExpensesAndWaste)
