import './EditClient.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { geolocated } from 'react-geolocated'

import { Col, Modal, Row } from 'react-bootstrap'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'

import Geocoding from 'src/components/maps/Geocoding/Geocoding'
import Card from 'src/components/cards/Card'
import Button from 'src/components/buttons/Button'
import Gallery, { imageTypes } from 'src/components/gallery/Gallery'

import {
  selectSingleClient,
  selectSellers,
  selectDeliverersToSelect,
  selectGlobalClient,
  selectCompany,
} from 'src/selectors/clients.selector'
import {
  updateClient,
  getSingleClient,
  getAllDeliverers,
  getAllSellers,
  actionTypes as typesClients,
  onCreateCompanyWithClient,
  actionTypes,
  createClientEditRequest,
} from 'src/actions/clients.actions'
import { selectAllLocalitiesForSelect } from 'src/selectors/address.selector'
import { actionTypes as townTypes, getAllLocalities } from 'src/actions/address.actions'
import { deleteFile, uploadFile } from 'src/actions/uploads.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'

import {
  getAllDeliverersByProducer,
  getAllSellersByProducer,
} from 'src/actions/clients.actions'
import { selectCurrentModule } from 'src/selectors/user.selector'
import TextField from 'src/components/inputs/FormTextField/FormTextField'
import { history } from '../../../App'
import ClientDetail from 'src/components/custom/ClientDetail/ClientDetail'
import { Action, Folder, Title } from 'src/components'
import FAB from 'src/components/buttons/FAB'
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave'
import Alert from 'sweetalert-react'
import { isAllowed } from 'src/selectors/modules.selector'
import { categoryType } from 'src/components/folders/Folder'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'
import {
  actionTypes as actionC,
  assignCategorizationToEntity,
  assignCategorizationToMultipleEntities,
  getCategorizationsByEntity,
} from 'src/actions/categorization.actions'
import { clientPermissions } from 'src/enums/permissions'

class EditClient extends Component {
  state = {
    defaultAlertForm: {
      show: false,
      title: 'Default',
      text: 'Default',
      type: 'success',
    },
    alertForm: {
      show: false,
      title: 'Default',
      text: 'Default',
      type: 'success',
    },
    client: {
      code: '',
      companyName: '',
      email: '',
      nit: '',
      phoneCompany: 1,
      phone: '',
      businessType: 1,
      town: { id: 1, label: '' },
      area: { id: 1, label: '' },
      address: '',
      secondAddress: '',
      postalCode: '',
      reference: '',
      valid: false,
      selectedOption: null,
      latitude: 0,
      longitude: 0,
      creditLimit: 0,
      contactName: '',
      deliverer: 0,
      vendor: 0,
      vehicleType: 0,
      callDay: 1,
      visitDay: 1,
      identifications: [],
    },
    defaultClient: {
      code: '',
      companyName: '',
      email: '',
      nit: '',
      phoneCompany: 1,
      phone: '',
      businessType: 1,
      town: { id: 1, label: '' },
      area: { id: 1, label: '' },
      address: '',
      secondAddress: '',
      postalCode: '',
      reference: '',
      valid: false,
      selectedOption: null,
      latitude: 0,
      longitude: 0,
      creditLimit: 0,
      contactName: '',
      deliverer: 0,
      vendor: 0,
      vehicleType: 0,
      callDay: 1,
      visitDay: 1,
    },
    towns: [],
    areas: [],
    modal: { show: false, item: null, company: {} },
    errors: {},
    error: { show: false, type: null },
  }

  setDefaultState = () => {
    const alertForm = Object.assign({}, this.state.defaultAlertForm)
    this.setState({ alertForm })
  }

  onChangeText = (event, parammeter, object) => {
    const { value } = event.target
    let { modal } = this.state
    modal[object][parammeter] = value
    this.setState({ modal })
  }

  UNSAFE_componentWillReceiveProps(next) {
    let { loadingS, hasErrorS, loadingC, hasErrorC, addIsLoading, addHasError } =
      this.props
    let { alertForm, modal } = this.state
    const { errorS, global, errorC, password } = next

    if (loadingC && !next.loadingC) {
      if (!hasErrorC && next.hasErrorC) {
        alertForm = handlerError(errorC.message || 'Ha ocurrido un error inesperado')
        alertForm.onConfirm = () =>
          this.setState({ alertForm: { ...alertForm, show: false } })
      } else {
        alertForm = handlerSuccess(
          `Usuario: ${modal.company.email}, Contraseña: ${password}`,
        )
        this.setState({ modal: { show: false, item: null, company: {} } })
      }
      this.setState({ alertForm })
    }

    if (loadingS && !next.loadingS) {
      if (!hasErrorS && next.hasErrorS) {
        alertForm = handlerError(errorS.message || 'Ha ocurrido un error inesperado')
      } else {
        alertForm = handlerSuccess(
          `Client cotizap: ${global.companyName}. ¿Desea visualizar su información?`,
        )
        alertForm.onConfirm = () => {
          this.setState({ alertForm: { ...alertForm, show: false } })
          history.push(`perfil/${global.id}`)
        }
        alertForm.onCancel = () =>
          this.setState({ alertForm: { ...alertForm, show: false } })
      }
      this.setState({ alertForm })
    }

    if (addIsLoading && !next.addIsLoading) {
      if (!addHasError && next.addHasError) {
        alertForm = handlerError('ha ocurrido un error inesperado.')
        alertForm.onConfirm = () => {
          this.setState({ alertForm: { ...alertForm, show: false } })
        }
      } else {
        this.props.getCategorizationsByClient(this.props.client.id)

        alertForm = handlerSuccess('Categorías actualizadas')
        alertForm.onConfirm = () => {
          this.setState({ alertForm: { ...alertForm, show: false } })
        }
      }
      this.setState({ alertForm })
    }
  }

  save = () => {
    const { client } = this.state
    let error = false
    let type = null
    if (!client.companyName) error = true
    if (!client.address) error = true

    if (error) {
      this.setState({ error: { show: true, type } }, () =>
        this.setState({ error: { show: false, type: null } }),
      )
      return
    }

    let request = Object.assign({}, client)
    if (client.locality) request.localityId = client.locality.value
    if (client.phoneCompany) request.phoneCompany = client.phoneCompany.value
    if (client.businessType) request.businessType = client.businessType.value
    if (client.town) request.town = client.town.value
    if (client.area) request.area = client.area.value
    if (request.area === '') request.area = null
    if (client.callDay) request.callDay = client.callDay.value
    if (client.visitDay) request.visitDay = client.visitDay.value
    if (client.vehicleType) request.vehicleType = client.vehicleType.value
    if (client.deliverer) request.deliverer = client.deliverer.value
    if (client.vendor) request.vendor = client.vendor.value
    if (client.contactType) request.contactType = client.contactType.value
    if (client.date) request.date = client.date.valueOf()
    if (client.email)
      request.email = client.multiEmails ? client.email.value : client.email
    if (client.exportationCountry)
      request.exportationCountry = client.exportationCountry.value

    request.multiEmails = client.multiEmails ? true : null

    if (client.economicActivity) request.economicActivity = client.economicActivity.value
    request.identifications = Object.values(client.identifications)

    if (this.props.isAllowed([clientPermissions.update]))
      this.props.updateClient(request.id, request)
    else {
      request.clientId = request.id
      this.props.createClientEditRequest(request)
    }
  }

  validate = () => {
    const { item, company } = this.state.modal
    let errors = {}

    if (!company.name) errors.name = 'El campo es requerido'
    if (!company.address) errors.address = 'El campo es requerido'
    if (!company.nit) errors.nit = 'El campo es requerido'
    if (!company.email) errors.email = 'El campo es requerido'

    if (Object.keys(errors).length === 0) {
      company.isClient = true
      company.isDistributor = false
      company.isProducer = true
      company.isPromotor = false

      company.client = Object.assign(
        {},
        {
          ...item,
          creationClient: item.id,
          town: item.town ? item.town.value : 1,
          phoneCompany: item.phoneCompany ? item.phoneCompany.value : 1,
          businessType: item.businessType ? item.businessType.value : 1,
          callDay: 0,
          visitDay: 0,
          deliverer: item.deliverer ? item.deliverer.value : 0,
          vendor: item.vendor ? item.vendor.value : 0,
        },
      )

      this.props.onCreateCompanyWithClient(company)
    }
    this.setState({ errors })
  }

  getData = data => {
    let { client, alertForm } = this.state
    let { towns } = this.props
    alertForm.show = true
    alertForm.title = 'Actualizar cliente'
    alertForm.text = '¿Desea actualizar la ubicación del cliente?'
    alertForm.type = 'warning'
    alertForm.showCancelButton = true
    alertForm.onCancel = () => this.setState({ alertForm: { ...alertForm, show: false } })
    alertForm.onConfirm = () =>
      this.setState({ alertForm: { ...alertForm, show: false } }, () => {
        client.address = data.address
        client.latitude = data.latLng.lat
        client.longitude = data.latLng.lng

        if (data.town) {
          const town = towns.find(t => t.label === data.town)
          if (town) client.town = town
        }
        this.save()
      })
    this.setState({ client, alertForm })
  }

  assignCategorizationToClient(categorization) {
    let array = []
    array.push(this.props.client.id)

    this.props.assignCategorizationToMultipleClients(
      categorization,
      array,
      !!this.props.client.id,
    )
  }

  render() {
    const { client, modal, errors, error, alertForm } = this.state
    const { coords, loadingC, categorization } = this.props

    let lat = 14.55
    let lng = -90.55

    if (coords) {
      lat = coords.latitude || 14.55
      lng = coords.longitude || -90.55
    }

    return (
      <div>
        <Title
          title={this.props.client.companyName}
          action
          actionTitle={'Crear cuenta KOLO'}
          onClick={() =>
            this.setState({ modal: { show: true, item: client, company: {} } })
          }
        />
        <Row>
          <Col md={4}>
            <Card
              status={client.available ? 'Activo' : 'Inactivo'}
              statusColor={client.available ? 'green' : 'red'}>
              <Gallery
                company={this.props.client && this.props.client.company}
                imageType={imageTypes.CLIENT}
                entityId={this.props.client && this.props.client.id}
              />
            </Card>
            {this.props.client.latitude !== 0 && this.props.client.longitude !== 0 && (
              <Geocoding
                lat={client.latitude || lat}
                lng={client.longitude || lng}
                auto={false}
                defaultAddress={client.address}
                editable={true}
                getData={this.getData}
              />
            )}
          </Col>
          <Col>
            <Action
              action
              onClick={() =>
                this.setState({
                  modal: { show: true, item: client, company: {} },
                })
              }
              actionTitle={'Crear cuenta KOLO'}
            />
            <ClientDetail
              id={this.props.match.params.client}
              setError={error}
              onDataChange={item => {
                this.setState({ client: item })
              }}
            />
          </Col>
        </Row>

        <Modal
          show={modal.show}
          size={'md'}
          centered
          onHide={() =>
            this.setState({ modal: { show: false, item: null, company: {} } })
          }>
          <Modal.Header>
            <Modal.Title>Información de la empresa</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form>
              <Row>
                <Col xl={4} lg={4} md={4} sm={3}>
                  <br />
                  <span>Nombre</span>
                </Col>
                <Col>
                  <TextField
                    placeholder={'Nombre'}
                    type={'text'}
                    value={modal.company.name}
                    error={errors.name}
                    onChange={data => this.onChangeText(data, 'name', 'company')}
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={4} lg={4} md={4} sm={3}>
                  <br />
                  <span>Dirección</span>
                </Col>
                <Col>
                  <TextField
                    placeholder={'Dirección'}
                    type={'text'}
                    value={modal.company.address}
                    error={errors.address}
                    onChange={data => this.onChangeText(data, 'address', 'company')}
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={4} lg={4} md={4} sm={3}>
                  <br />
                  <span>NIT</span>
                </Col>
                <Col>
                  <TextField
                    placeholder={'NIT'}
                    type={'text'}
                    value={modal.company.nit}
                    error={errors.nit}
                    onChange={data => this.onChangeText(data, 'nit', 'company')}
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={4} lg={4} md={4} sm={3}>
                  <br />
                  <span>Correo Eléctronico</span>
                </Col>
                <Col>
                  <TextField
                    placeholder={'p.j. empresa@cotizap.com'}
                    type={'text'}
                    value={modal.company.email}
                    error={errors.email}
                    onChange={data => this.onChangeText(data, 'email', 'company')}
                  />
                </Col>
              </Row>
            </form>
          </Modal.Body>

          <Modal.Footer>
            <Row className={'container-buttons'}>
              <Button loading={loadingC} onClick={() => this.validate()}>
                <FontAwesomeIcon icon={faPlus} /> Crear empresa
              </Button>
            </Row>
          </Modal.Footer>
        </Modal>

        <Alert {...alertForm} />

        <FAB
          show
          onClick={() => {
            this.save()
          }}
          icon={faSave}
        />

        <Folder
          noMessage
          onHide={() =>
            this.setState({
              showCategorizations: false,
              isFilter: false,
              isDetail: false,
            })
          }
          onAssign={item => {
            this.assignCategorizationToClient(item.id)
          }}
          data1={
            categorization && categorization.children ? categorization.children[0] : {}
          }
          data2={
            categorization && categorization.children ? categorization.children[1] : {}
          }
          show={this.state.showCategorizations}
          //list={selectedCategorization.map(p => p.id)}
          type={categoryType.CLIENTS}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  client: selectSingleClient(state),
  towns: selectAllLocalitiesForSelect(state),
  deliverers: selectDeliverersToSelect(state),
  sellers: selectSellers(state),
  //updateResponse: selectUpdateClient(state),
  townsIsLoading: loadingSelector([townTypes.GET_ALL_LOCALITIES])(state),
  clientIsLoading: loadingSelector([typesClients.GET_SINGLE_CLIENTS])(state),
  currentModule: selectCurrentModule(state),
  loadingS: loadingSelector([actionTypes.GLOBAL_CLIENT])(state),
  hasErrorS: hasErrorsSelector([actionTypes.GLOBAL_CLIENT])(state),
  errorS: singleErrorSelector([actionTypes.GLOBAL_CLIENT])(state),
  global: selectGlobalClient(state),
  handlerError: message => handlerError(message),
  handlerSuccess: message => handlerSuccess(message),
  loadingC: loadingSelector([actionTypes.ON_CREATE_COTIZAP_COMPANY_BY_CLIENT])(state),
  hasErrorC: hasErrorsSelector([actionTypes.ON_CREATE_COTIZAP_COMPANY_BY_CLIENT])(state),
  errorC: singleErrorSelector([actionTypes.ON_CREATE_COTIZAP_COMPANY_BY_CLIENT])(state),
  password: selectCompany(state),
  isAllowed: permissions => isAllowed(state, permissions),
  categorization: selectAllCategorizations(state),
  addIsLoading: loadingSelector([actionC.ADD_ENTITY_CATEGORIZATION])(state),
  addHasError: hasErrorsSelector([actionC.ADD_ENTITY_CATEGORIZATION])(state),
})

const mapDispatchToProps = dispatch => ({
  getAllLocalities: () => dispatch(getAllLocalities()),
  getDeliverers: () => dispatch(getAllDeliverers()),
  getSellers: () => dispatch(getAllSellers()),
  getSingleClient: idClient => dispatch(getSingleClient(idClient)),
  updateClient: (id, client) => dispatch(updateClient(id, client)),
  uploadFile: (file, path, onProgress, onSuccess, onError) =>
    dispatch(uploadFile({ file, path, onProgress, onSuccess, onError })),
  deleteFile: (name, onSuccess) => dispatch(deleteFile({ name, onSuccess })),
  getAllDeliverersByProducer: () => dispatch(getAllDeliverersByProducer()),
  getAllSellersByProducer: () => dispatch(getAllSellersByProducer()),
  onCreateCompanyWithClient: company => dispatch(onCreateCompanyWithClient(company)),
  createClientEditRequest: request => dispatch(createClientEditRequest(request)),
  assignCategorizationToMultipleClients: (categorizationId, clients, remove) =>
    dispatch(
      assignCategorizationToMultipleEntities(
        'clients',
        categorizationId,
        clients,
        remove,
      ),
    ),

  getCategorizationsByClient: clientId =>
    dispatch(getCategorizationsByEntity('clients', clientId)),
  assignCategorizationToClient: (categorization, id) =>
    dispatch(assignCategorizationToEntity('clients', categorization, id, true)),
})

const ReduxComponent = connect(mapStateToProps, mapDispatchToProps)(EditClient)
export default geolocated({
  positionOptions: { enableHighAccuracy: false },
  userDecisionTimeout: 500,
})(ReduxComponent)
