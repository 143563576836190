import { $http } from './http'

class SubscriptionsServices {
  getAllSubscriptions = async () => {
    const response = await $http.get(`/subscriptions/all`)
    return response.data
  }

  getSubscriptionsClient = async () => {
    const response = await $http.get(`/subscriptions/client`)
    return response.data
  }

  getActiveSubscriptionsClient = async () => {
    const response = await $http.get(`/subscriptions/client/active`)
    return response.data
  }

  getActiveSubscriptionClient = async id => {
    const response = await $http.get(`/subscriptions/client/active/${id}`)
    return response.data
  }

  getSubscriptionsCompany = async () => {
    const response = await $http.get(`/subscriptions/own`)
    return response.data
  }

  getSubscriptionsShipper = async () => {
    const response = await $http.get(`/subscriptions/distribution/own`)
    return response.data
  }

  getSubscriptionsPolygons = async () => {
    const response = await $http.get(`/subscriptions/polygons`)
    return response.data
  }

  getSubscriptionsPolygonsDistribution = async () => {
    const response = await $http.get(`/subscriptions/distribution`)
    return response.data
  }

  getSubscriptionPolygon = async id => {
    const response = await $http.get(`/subscriptions/polygons/${id}`)
    return response.data
  }

  saveNewSubscription = async subscription => {
    const response = await $http.post(`/subscriptions`, subscription)
    return response.data
  }

  buySubscription = async request => {
    const response = await $http.post(`/subscriptions/buy`, request)
    return response.data
  }

  assignShipper = async subscriptionId => {
    const response = await $http.put(`/subscriptions/${subscriptionId}`)
    return response.data
  }

  acceptSubscription = async subscriptionId => {
    const response = await $http.put(`/subscriptions/accept/${subscriptionId}`)
    return response.data
  }

  updateProducts = async (subscriptionId, products) => {
    const response = await $http.put(
      `/subscriptions/client/${subscriptionId}/update`,
      products,
    )
    return response.data
  }

  /*2021*/

  getSubscriptions = async params => {
    const response = await $http.get(`/subscriptions`, { params })
    return response.data
  }

  getInvoices = async () => {
    const response = await $http.get('/subscriptions/invoices')
    return response.data
  }

  getOrders = async subscriptionId => {
    const response = await $http.get(`/subscriptions/orders/${subscriptionId}`)
    return response.data
  }

  updateSubscription = async (id, request) => {
    const response = await $http.put(`/subscriptions/${id}`, request)
    return response.data
  }

  concludeSubscription = async id => {
    const response = await $http.put(`/subscriptions/conclude/${id}`)
    return response.data
  }
}

export default new SubscriptionsServices()
