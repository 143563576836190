import React, { useState } from 'react'

import { useSelector } from 'react-redux'

import { AddAccountModal } from './AccountAddAction'

import { Button } from 'src/components'
import { isAllowed } from 'src/selectors/modules.selector'
import { bankAccountPermissionsEnum } from 'src/enums/bankEnums'
import ManageAccountModal from './AccountGetMovements'

const AccountButtons: React.FC = () => {
  const allowCreateAccount = useSelector(state =>
    isAllowed(state, [bankAccountPermissionsEnum.CreateAccount]),
  )

  const allowRequestMovements = useSelector(state =>
    isAllowed(state, [bankAccountPermissionsEnum.RequestMovementsTransactions]),
  )

  const [modalShow, setModalShow] = useState(false)
  const [modalRequestsShow, setModalRequestShow] = useState(false)

  return (
    <>
      <div className="flex flex-column">
        {allowCreateAccount && (
          <Button
            className={'mt-3 big-button'}
            onClick={() => setModalShow(true)}
            dataCy="account-add"
            style={{ maxWidth: '100%', marginBottom: '10px' }}>
            Agregar Cuenta
          </Button>
        )}
        {allowRequestMovements && (
          <Button
            dataCy="account-requestmovements"
            className={'mt-1 big-button'}
            onClick={() => setModalRequestShow(true)}
            style={{ maxWidth: '100%' }}>
            Administrar solicitudes
          </Button>
        )}

        <AddAccountModal show={modalShow} onHide={() => setModalShow(false)} />
        <ManageAccountModal
          show={modalRequestsShow}
          onHide={() => setModalRequestShow(false)}
        />
      </div>
    </>
  )
}

export default AccountButtons
