import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Col, Row, Modal, Accordion, ProgressBar } from 'react-bootstrap'
import {
  Card,
  FormTextField,
  Button,
  Icon as IconButton,
  CustomFilter,
  NumberField,
} from 'src/components/index'
import {
  faEdit,
  faPlusCircle,
  faReceipt,
  faSave,
  faTimes,
  faTrash,
  faWindowClose,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'

import { Media } from 'react-breakpoints'

import inventory from 'src/assets/v2/icon-inventory.svg'

import {
  actionTypes as productActionTypes,
  getProductsSimple,
} from 'src/actions/products.actions'
import { selectProductsSimple } from 'src/selectors/products.selector'

import { selectCurrentCompany } from 'src/selectors/user.selector'
import {
  types,
  onCreateEnumeration,
  updateEnumeration,
  onGetEnumerations,
  deleteEnumeration,
} from 'src/actions/enumeration.actions'
import { selectEnumerations } from 'src/selectors/enumeration.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'

import IconMore from 'src/assets/v2/icon-more.svg'
import { formatDateFromMillis } from 'src/utils/formatters'
import { isAllowed } from 'src/selectors/modules.selector'
import CustomDatePicker from 'src/components/CustomDatePicker/CustomDatePicker'
import Money from 'src/components/Money/Money'
import { showAlert } from 'src/actions/alert.actions'

import CreateOrUpdateRecipe from 'src/content/Conversions/RecipeV2/CreateOrUpdateRecipe'

const RecipeDetail = ({
  conversion,
  modal,
  title,
  show,
  data,
  onHide,
  isDisabled,
  seeEnumerations = true,
  onAdd,
  only,
  create,
  enumerationId,
}) => {
  const dispatch = useDispatch()
  const products = useSelector(selectProductsSimple)
  const enumerations = useSelector(selectEnumerations)
  const currentCompanyId = useSelector(selectCurrentCompany)
  const loading = useSelector(state =>
    loadingSelector([productActionTypes.GET_ALL_PRODUCTS])(state),
  )
  const loadingUpdateRecipe = useSelector(state =>
    loadingSelector([productActionTypes.UPDATE_PRODUCT])(state),
  )
  const hasErrorUpdateRecipe = useSelector(state =>
    hasErrorsSelector([productActionTypes.UPDATE_PRODUCT])(state),
  )
  const errorUpdateRecipe = useSelector(state =>
    singleErrorSelector([productActionTypes.UPDATE_PRODUCT])(state),
  )

  const loadingCreateRecipe = useSelector(state =>
    loadingSelector([types.ON_CREATE])(state),
  )
  const hasErrorCreateRecipe = useSelector(state =>
    hasErrorsSelector([types.ON_CREATE])(state),
  )
  const errorCreateRecipe = useSelector(state =>
    singleErrorSelector([types.ON_CREATE])(state),
  )

  const deleteLoading = useSelector(state => loadingSelector([types.ON_DELETE])(state))
  const deleteHasError = useSelector(state => hasErrorsSelector([types.ON_DELETE])(state))
  const deleteError = useSelector(state => singleErrorSelector([types.ON_DELETE])(state))

  const updateLoading = useSelector(state =>
    loadingSelector([types.ON_CREATE_OR_UPDATE])(state),
  )

  const canCreate = useSelector(state => isAllowed(state, [16301]))
  const canEdit = useSelector(state => isAllowed(state, [16302]))
  const canDelete = useSelector(state => isAllowed(state, [16303]))

  const [action, setAction] = useState({})
  const [item, setItem] = useState({})
  const [error, setError] = useState({})
  const [from, setFrom] = useState([])
  const [to, setTo] = useState([])
  const [list, setList] = useState([])
  const [enumList, setEnumList] = useState([])
  const [details, setDetails] = useState([])
  const [selected, setSelected] = useState({})
  const [search, setSearch] = useState('')
  const [searchEdit, setSearchEdit] = useState('')
  const [showCreate, setShowCreate] = useState({ show: false, recipe: undefined })

  const [disabled, setDisabled] = useState(isDisabled)
  const [mode, setMode] = useState(seeEnumerations)

  const [mobile, setModalMobile] = useState(false)
  const [isCreate, setCreate] = useState(true)
  const [isEdit, setEdit] = useState(false)
  const [showRecipes, setRecipes] = useState(false)

  useEffect(() => {
    setCreate(create)
  }, [create])

  useEffect(() => {
    if (mode) setEnumList(new Array(...enumerations))
    if (products && products.length === 0) dispatch(getProductsSimple())
    else setList(new Array(...list))
  }, [mode])

  useEffect(() => {
    if (data) {
      setItem({ name: data.name, description: data.description })
      if (data.detail) {
        setDetails(
          data.detail.map(d => {
            d.selected = false
            return d
          }),
        )
        setFrom(
          data.detail
            .filter(p => p.detailType === 1)
            .map(d => {
              d.selected = false
              return d
            }),
        )
        setTo(
          data.detail
            .filter(p => p.detailType === 0)
            .map(d => {
              d.selected = false
              return d
            }),
        )
      }
    }
  }, [data])

  useEffect(() => {
    if (show) {
      init()
      return
    }
    setDisabled(isDisabled)
    setDetails([])
    setItem({})
    setMode(true)
    dispatch(onGetEnumerations(false))
    setSelected({})
  }, [show])

  useEffect(() => {
    if (products && products.length > 0) {
      setList([...products])
    }
  }, [products])

  useEffect(() => {
    if (enumerations && enumerations.length > 0) {
      setEnumList([...enumerations])
    }
  }, [enumerations])

  useEffect(() => {
    if (loadingUpdateRecipe) setAction({ ...action, update: true })
    else if (action.update) {
      setAction({ ...action, update: false })
      if (hasErrorUpdateRecipe)
        dispatch(
          showAlert({
            ...handlerError(errorUpdateRecipe.message),
            onConfirm: () => {
              setDisabled(isDisabled)
            },
          }),
        )
      else
        dispatch(
          showAlert({
            ...handlerSuccess('Receta actualizada'),
            onConfirm: () => {
              setDisabled(isDisabled)
            },
          }),
        )
    }
  }, [loadingUpdateRecipe])

  useEffect(() => {
    if (loadingCreateRecipe) setAction({ ...action, update: true })
    else if (action.update) {
      setAction({ ...action, update: false })
      if (hasErrorCreateRecipe)
        dispatch(
          showAlert({
            ...handlerError(errorCreateRecipe.message),
            onConfirm: () => {
              setDisabled(isDisabled)
            },
          }),
        )
      else
        dispatch(
          showAlert({
            ...handlerSuccess('Receta creada'),
            onConfirm: () => {
              setDisabled(isDisabled)
              clean()
              if (mode) setList([...enumerations])
              else setList([...products])
              if (onHide) onHide()
            },
          }),
        )
    }
  }, [loadingCreateRecipe])

  useEffect(() => {
    if (deleteLoading) setAction({ ...action, delete: true })
    else if (action.delete) {
      setAction({ ...action, delete: false })
      if (deleteHasError)
        dispatch(
          showAlert({
            ...handlerError(deleteError.message),
          }),
        )
      else {
        dispatch(
          showAlert({
            ...handlerSuccess('Receta eliminada exitosamente'),
            onConfirm: () => {
              setCreate(false)
              setEdit(false)
              setRecipes(false)
              clean()
              dispatch(onGetEnumerations(false))
              setList([...enumerations])
              if (onHide) onHide(true)
            },
          }),
        )
      }
    }
  }, [deleteLoading])

  const filter = item => {
    if (search && search !== '') {
      const exp = new RegExp(search.toUpperCase(), 'g')
      return Object.keys(item).some(p => JSON.stringify(item[p]).toUpperCase().match(exp))
    }
    return true
  }

  const filterEdit = item => {
    if (searchEdit && searchEdit !== '') {
      const exp = new RegExp(searchEdit.toUpperCase(), 'g')
      return Object.keys(item).some(p => JSON.stringify(item[p]).toUpperCase().match(exp))
    }
    return true
  }

  const init = () => {
    if (mode) dispatch(onGetEnumerations(false))
    else dispatch(getProductsSimple())
  }

  const onChange = event => {
    const { name, value } = event.target
    setItem({ ...item, [name]: value })
  }

  const clean = () => {
    if (!mode) {
      const s = from.splice(0, from.length)
      s.forEach(p => {
        p.selected = false
        p.quantity = 0
      })
      const e = to.splice(0, to.length)
      e.forEach(p => {
        p.selected = false
        p.quantity = 0
      })
      setList([...list, ...s, ...e])
    }
    setDetails([])
    setItem({ ...item, name: '', description: '' })
  }

  const onChangeQuantity = (value, id) => {
    let index = details.findIndex(d => d.productId === Number(id))
    if (index !== -1) {
      details[index].quantity = value
      setDetails([...details])
    }
  }

  const onChangeDate = (value, id) => {
    let index = to.findIndex(d => d.productId === Number(id))

    if (index !== -1) {
      to[index].expireDate = value.valueOf()
      setTo([...to])
    }

    index = details.findIndex(d => d.productId === Number(id))
    if (index !== -1) {
      details[index].expireDate = value.valueOf()
      setDetails([...details])
    }
  }

  const onDropList = event => {
    event.preventDefault()
    let item = null
    let index
    const data = event.dataTransfer.getData('text')
    const id = data.split('-')[1]

    index = to.findIndex(d => d.productId === Number(id))
    if (index !== -1) item = to.splice(index, 1)

    index = from.findIndex(d => d.productId === Number(id))
    if (index !== -1) item = from.splice(index, 1)

    if (item) {
      list.forEach(d => {
        if (d.productId === Number(id)) d.selected = false
      })
      setList([
        ...list.map(d => {
          if (d.productId === Number(id)) d.selected = false
          return d
        }),
      ])
      index = details.findIndex(d => d.productId === Number(id))
      details.splice(index, 1)
    }
  }

  const onDropFrom = (event, pId) => {
    event.preventDefault()
    let item
    let index

    let id
    if (pId) id = pId
    else {
      const data = event.dataTransfer.getData('text')
      id = data.split('-')[1]
    }

    index = to.findIndex(d => d.productId === Number(id))
    if (index === -1) {
      index = list.findIndex(d => d.productId === Number(id))
      item = list.slice(index, index + 1)
    } else {
      item = to.splice(index, 1)
    }
    setDetails([...details, { ...item[0], detailType: 1, selected: true }])
    item[0].selected = true
    setFrom([...from, item[0]])
  }

  const onDropTo = event => {
    event.preventDefault()
    let item
    let index
    const data = event.dataTransfer.getData('text')
    const id = data.split('-')[1]
    index = from.findIndex(d => d.productId === Number(id))
    if (index === -1) {
      index = list.findIndex(d => d.productId === Number(id))
      item = list.slice(index, index + 1)
    } else {
      item = from.splice(index, 1)
    }
    setDetails([...details, { ...item[0], detailType: 0, selected: true }])
    item[0].selected = true
    setTo([...to, item[0]])
  }

  const onDrag = event => {
    event.dataTransfer.setData('text', event.target.id)
  }

  const save = edit => {
    const { name, description } = item
    const errors = {}
    if (!name) errors.name = 'Campo requerido'
    if (!description) errors.description = 'Campo requerido'
    if (details.filter(p => p.detailType === 1).length === 0)
      errors.from = 'Campo requerido'
    if (details.filter(p => p.detailType === 0).length === 0)
      errors.to = 'Campo requerido'

    if (Object.keys(errors).length === 0) {
      const input = details
        .filter(p => p.detailType === 1)
        .map(p => {
          return { quantity: p.quantity, product: p.productId }
        })
      const output = details
        .filter(p => p.detailType === 0)
        .map(p => {
          return { quantity: p.quantity, product: p.productId }
        })

      const request = { ...item, type: 1, input, output }
      if (!edit) dispatch(onCreateEnumeration(request))
      else dispatch(updateEnumeration(enumerationId ?? item.id, request))
    } else {
      dispatch(
        showAlert({
          ...handlerError('Llene los campos requeridos'),
        }),
      )
    }

    setError({ ...errors })
  }

  const renderProducts = (item, index, ic, hideQuantity) => {
    const disabled = ic === undefined ? !item.selected : !ic

    return (
      <li
        style={{ padding: 0 }}
        key={index}
        id={`p-${item.productId}`}
        draggable
        onDragStart={onDrag}>
        <div className={'b-user-item column'}>
          <div className={'justify-content-start align-items-start bu-en left'}>
            <div className={'b-user-name'}>{item.name || item.productName}</div>
            <div className={'b-user-email'}>{item.code || item.productCode}</div>
            {conversion && item.detailType === 0 && (
              <div>
                <CustomDatePicker
                  label={'Fecha de expiración:'}
                  value={item.expireDate}
                  textButton={
                    item.expireDate
                      ? formatDateFromMillis(item.expireDate, null, true)
                      : 'Fecha de expiración'
                  }
                  disabledDays={{ before: null, after: null }}
                  onDayChange={value => onChangeDate(value, item.productId)}
                  lite
                />
              </div>
            )}
          </div>

          {!hideQuantity && (
            <div className={'d-flex row align-items-center mr-2 bu-b right'}>
              <Row
                className={'container-buttons'}
                style={{ paddingBottom: 2, display: 'flex' }}>
                <NumberField
                  style={{ flex: 1, marginLeft: 5 }}
                  igStyle={{ margin: 0, padding: 0 }}
                  mt={0}
                  placeholder={'Cantidad de unidades'}
                  name={'quantity'}
                  value={item.quantity || 0}
                  onValueChange={event => onChangeQuantity(event, item.productId)}
                  disabled={disabled}
                  decimals={item.decimals}
                />
              </Row>
            </div>
          )}
        </div>
      </li>
    )
  }

  const renderDetail = () => {
    return (
      <div>
        {!mode && (
          <Row>
            <Col xl={6} lg={6} md={12} sm={12}>
              <FormTextField
                label={'Nombre'}
                type={'text'}
                name={'name'}
                value={item.name}
                onChange={onChange}
                error={error.name}
                required
              />
            </Col>
            <Col xl={6} lg={6} md={12} sm={12}>
              <FormTextField
                label={'Descripción'}
                type={'text'}
                name={'description'}
                value={item.description}
                onChange={onChange}
                error={error.description}
                required
              />
            </Col>
          </Row>
        )}
        {only ? (
          renderNewDetail()
        ) : (
          <Row>
            {(!data || !data.name) && (
              <Col>
                <FormTextField
                  label={'Buscar'}
                  type={'text'}
                  name={'search'}
                  value={search}
                  onChange={event => setSearch(event.target.value)}
                />
                {/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% PRODUCTOS CONVERSION LIBRE %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}
                <ul
                  className={'main-list'}
                  style={{ display: mode ? 'none' : 'inline-block' }}
                  onDrop={onDropList}
                  onDragOver={event => event.preventDefault()}
                  id={'main'}>
                  {list
                    ?.filter(
                      f => f.companyId === currentCompanyId && filter(f) && !f.selected,
                    )
                    .map((item, index) => {
                      return renderProducts(item, index, undefined, true)
                    })}
                </ul>
                {/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%ITEM DE CADA RECETA %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}
                <ul
                  className={'main-list'}
                  style={{
                    display: mode ? 'inline-block' : 'none',
                    width: '100%',
                  }}>
                  {enumList?.filter(filter).map((item, index) => (
                    <li
                      key={`e-${index}`}
                      style={{ padding: 0 }}
                      onClick={e => {
                        if (item === selected) {
                          e.stopPropagation()
                          setSelected({})
                          setDetails([])
                          return
                        }
                        e.stopPropagation()
                        setSelected(item)
                        setDetails(item.enumerationDetails)
                      }}>
                      <div
                        className={`b-user-item column ${
                          selected.id === item.id ? 'kolo_blue' : ''
                        }`}>
                        <div>
                          <img
                            style={{ width: 40, marginRight: 5 }}
                            src={inventory}
                            alt="enumeration"
                          />
                        </div>
                        <div
                          className={
                            'justify-content-start align-items-start bu-en left'
                          }>
                          <div className={'b-user-name'}>{item.name}</div>
                          <div className={'b-user-email'}>{item.description}</div>
                        </div>
                        <div className={'d-flex row align-items-center mr-2 bu-b right'}>
                          <Row
                            className={'container-buttons'}
                            style={{ paddingBottom: 2, display: 'flex' }}>
                            {canDelete && (
                              <IconButton
                                style={{
                                  paddingLeft: '2px',
                                  paddingRight: '2px',
                                }}
                                size={'lg'}
                                tooltip={'Eliminar receta'}
                                icon={faTrash}
                                color={'secondary'}
                                onClick={() => {
                                  dispatch(
                                    showAlert({
                                      title: `¿Eliminar esta receta?`,
                                      text: 'Esta acción no se puede revertir',
                                      type: 'info',
                                      show: true,
                                      showCancelButton: true,
                                      onConfirm: () => {
                                        dispatch(
                                          deleteEnumeration(enumerationId ?? item.id),
                                        )
                                      },
                                    }),
                                  )
                                }}
                              />
                            )}

                            {(canDelete || canEdit) && (
                              <IconButton
                                style={{
                                  paddingLeft: '2px',
                                  paddingRight: '2px',
                                }}
                                size={'lg'}
                                tooltip={'Editar receta'}
                                icon={faEdit}
                                onClick={() => {
                                  const recipe = {
                                    id: item.id,
                                    name: item.name,
                                    description: item.description,
                                    items: item.enumerationDetails.map(enumDetail => ({
                                      product: enumDetail.productId,
                                      name: enumDetail.productName,
                                      code: enumDetail.productCode,
                                      quantity: enumDetail.quantity,
                                      decimals: enumDetail.decimals,
                                      type: enumDetail.detailType,
                                    })),
                                  }

                                  setShowCreate({ show: true, recipe })
                                }}
                              />
                            )}
                          </Row>
                        </div>
                      </div>
                      {/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%ITEM DE CADA RECETA %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}
                    </li>
                  ))}
                </ul>
              </Col>
            )}
            <Col sm={12} md={data?.name ? 12 : 8}>
              <Row>
                <Col sm={12} md={6} lg={6}>
                  <Card title={'Insumos a convertir:'} white>
                    {!mode && (
                      <div>
                        <b>Arrastre los productos a convertir</b>
                      </div>
                    )}
                    {/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% INGREDIENTES DE CADA RECETA %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}
                    <ul
                      className={'main-list'}
                      id={'fromEdit'}
                      onDrop={onDropFrom}
                      onDragOver={event => event.preventDefault()}>
                      {details
                        .filter(p => p.detailType === 1)
                        .map((item, index) => renderProducts(item, index))}
                    </ul>
                  </Card>
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <Card title={'Se convierte en:'} white>
                    {!mode && (
                      <div>
                        <b>Arrastre los productos resultantes</b>
                      </div>
                    )}
                    <ul
                      className={'main-list'}
                      id={'toEdit'}
                      onDrop={onDropTo}
                      onDragOver={event => event.preventDefault()}>
                      {details
                        .filter(p => p.detailType === 0)
                        .map((item, index) => renderProducts(item, index))}
                    </ul>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        <Modal show={mobile} centered size={'md'} onHide={() => setModalMobile(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Productos para la selección</Modal.Title>
          </Modal.Header>
          <Modal.Body>{itemsToSelect(false)}</Modal.Body>
        </Modal>
      </div>
    )
  }

  const renderEdit = () => {
    return (
      <div>
        <Row>
          <Col xl={6} lg={6} md={12} sm={12}>
            <FormTextField
              label={'Nombre'}
              type={'text'}
              name={'name'}
              value={item.name}
              onChange={onChange}
              error={error.name}
              required
            />
          </Col>
          <Col xl={6} lg={6} md={12} sm={12}>
            <FormTextField
              label={'Descripción'}
              type={'text'}
              name={'description'}
              value={item.description}
              onChange={onChange}
              error={error.description}
              required
            />
          </Col>
        </Row>
        {only ? (
          renderNewDetail()
        ) : (
          <Row>
            {(!data || !data.name) && (
              <Col>
                <FormTextField
                  label={'Buscar'}
                  type={'text'}
                  name={'searchEdit'}
                  value={searchEdit}
                  onChange={event => setSearchEdit(event.target.value)}
                />
                <ul
                  className={'main-list'}
                  style={{ display: 'inline-block' }}
                  onDrop={onDropList}
                  onDragOver={event => event.preventDefault()}
                  id={'main'}>
                  {list
                    ?.filter(
                      f =>
                        f.companyId === currentCompanyId && filterEdit(f) && !f.selected,
                    )
                    .map((item, index) => {
                      return renderProducts(item, index, false)
                    })}
                </ul>
              </Col>
            )}
            <Col sm={12} md={data?.name ? 12 : 8}>
              <Row>
                <Col sm={12} md={6} lg={6}>
                  <Card title={'Insumos a convertir:'} white>
                    {!mode && (
                      <div>
                        <b>Arrastre los productos a convertir</b>
                      </div>
                    )}
                    <ul
                      className={'main-list'}
                      id={'edit-from'}
                      onDrop={onDropFrom}
                      onDragOver={event => event.preventDefault()}>
                      {details
                        .filter(p => p.detailType === 1)
                        .map((item, index) => renderProducts(item, index, true))}
                    </ul>
                  </Card>
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <Card title={'Se convierte en:'} white>
                    {!mode && (
                      <div>
                        <b>Arrastre los productos resultantes</b>
                      </div>
                    )}
                    <ul
                      className={'main-list'}
                      id={'edit-to'}
                      onDrop={onDropTo}
                      onDragOver={event => event.preventDefault()}>
                      {details
                        .filter(p => p.detailType === 0)
                        .map((item, index) => renderProducts(item, index, true))}
                    </ul>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
    )
  }

  const renderNewDetail = () => (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        const tablet = breakpoints[currentBreakpoint] > breakpoints.tablet

        return breakpoints[currentBreakpoint] >= breakpoints.mini ? (
          <Row>
            {tablet && isCreate && (
              <Col xl={5} lg={5} md={5}>
                {itemsToSelect(true)}
              </Col>
            )}
            <Col xl={tablet ? 7 : 12} lg={tablet ? 7 : 12} md={tablet ? 7 : 12}>
              <Card title={'Se compone de'} white>
                {tablet && isCreate && <h5>Arrastre los productos a convertir</h5>}

                <Accordion defaultActiveKey={'0'}>
                  <ul
                    id={'from'}
                    onDrop={onDropFrom}
                    onDragOver={event => event.preventDefault()}
                    style={{
                      border: '1px solid lightgray',
                      padding: 0,
                      margin: 0,
                      overflowY: 'auto',
                      minHeight: 300,
                      maxHeight: 350,
                    }}>
                    {details
                      .filter(p => p.detailType === 1)
                      .map((item, index) => renderProducts(item, index, isCreate))}
                  </ul>
                </Accordion>
              </Card>
            </Col>
            {!tablet && isCreate && (
              <Col xl={12} lg={12} md={12}>
                <Row
                  className={'container-buttons'}
                  style={{ paddingBottom: 2, display: 'flex' }}>
                  <Button
                    style={{ flex: 1 }}
                    icon={faPlus}
                    right
                    color={'primary'}
                    onClick={() => setModalMobile(true)}>
                    Agregar producto
                  </Button>
                </Row>
              </Col>
            )}
          </Row>
        ) : (
          <div />
        )
      }}
    </Media>
  )

  const itemsToSelect = isPc => (
    <div>
      {!isPc && <h5>Seleccione un producto</h5>}
      <CustomFilter
        search={search}
        onChange={({ target }) => setSearch(target.value)}
        customFilter={<div />}
      />

      <ul
        onDrop={onDropList}
        onDragOver={event => event.preventDefault()}
        style={{
          padding: 0,
          margin: 0,
          overflowY: 'auto',
          minHeight: 300,
          maxHeight: 350,
        }}
        id={'main'}>
        {list &&
          list
            .filter(f => f.companyId === currentCompanyId && filter(f) && !f.selected)
            .map((item, index) => (
              <li
                key={index}
                style={{ padding: 0, 'list-style': 'none' }}
                id={`p-${item.productId}`}
                draggable={isPc}
                onDragStart={onDrag}
                onClick={e => {
                  if (!isPc) {
                    setModalMobile(false)
                    onDropFrom(e, item.productId)
                  }
                }}>
                <div className={'b-user-item'}>
                  <div className={'justify-center-start align-items-start bu-en left'}>
                    <div className={'b-user-name'}>{item.name}</div>
                    <div className={'b-user-email'}>{item.code}</div>
                    <Money className={'b-user-balance-m'}>{item.productPrice}</Money>
                  </div>
                  <div className={'d-flex row align-items-center mr-2 bu-b right'}>
                    <Money className={'b-user-balance mr-2'}>{item.productPrice}</Money>
                  </div>
                </div>
              </li>
            ))}
      </ul>
    </div>
  )

  const renderButtons = () => (
    <Row className={'container-buttons'}>
      {mode ? (
        disabled ? (
          <>
            <Button
              color={'primary'}
              icon={faReceipt}
              onClick={() => {
                clean()
                setMode(false)
              }}>
              Conversión libre
            </Button>
            <Button
              color={'primary'}
              disabled={Object.keys(selected).length === 0}
              icon={faPlusCircle}
              onClick={() => onAdd(selected)}>
              Añadir
            </Button>
          </>
        ) : (
          <Button
            color={'secondary'}
            onClick={() => {
              setShowCreate({ show: true, recipe: undefined })
            }}
            icon={faPlusCircle}>
            Crear receta
          </Button>
        )
      ) : (
        <>
          {!create && (
            <Button
              color={'secondary'}
              icon={faTimes}
              onClick={() => {
                clean()
                setMode(true)
                setSelected({})
              }}>
              Cancelar
            </Button>
          )}
          <Button
            color={'primary'}
            icon={faSave}
            disabled={!item.name || !item.description}
            loading={loadingCreateRecipe}
            onClick={() =>
              disabled
                ? onAdd({
                    name: item.name,
                    description: item.description,
                    enumerationDetails: details,
                  })
                : save()
            }>
            Guardar
          </Button>
        </>
      )}
    </Row>
  )

  return modal ? (
    <Modal
      show={show}
      size={'xl'}
      centered
      aria-labelledby={'contained-modal-title-vcenter'}
      onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={'container'}>
          {loading && <ProgressBar now={100} animated />}
          {renderDetail()}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {(!data || !data.name) && !only && renderButtons()}
        {only && (
          <Row className={'container-buttons'}>
            <Button
              color={'secondary'}
              icon={faWindowClose}
              onClick={() => {
                onHide()
                setCreate(false)
                setEdit(false)
              }}>
              Cerrar
            </Button>
            {isCreate ? (
              <Button
                color={'primary'}
                icon={faSave}
                disabled={!item.name || !item.description}
                loading={loadingCreateRecipe || updateLoading}
                onClick={() =>
                  disabled
                    ? onAdd({
                        name: item.name,
                        description: item.description,
                        enumerationDetails: details,
                      })
                    : save(isEdit)
                }>
                Guardar
              </Button>
            ) : (
              <>
                <Button
                  loading={deleteLoading}
                  color={'secondary'}
                  icon={faTrash}
                  onClick={() => {
                    dispatch(deleteEnumeration(enumerationId))
                  }}>
                  Eliminar
                </Button>
                <Button
                  disabled={deleteLoading}
                  color={'primary'}
                  icon={faEdit}
                  onClick={() => {
                    setCreate(true)
                    setEdit(true)
                  }}>
                  Editar
                </Button>
              </>
            )}
          </Row>
        )}
      </Modal.Footer>
    </Modal>
  ) : (
    <div>
      {!create && (
        <div className={'t-container-k'}>
          <div className={'title-k'}>{title ? title : 'Recetas'}</div>
          {canCreate && (
            <div className="w-add-buttona">
              <div
                onClick={() => setShowCreate({ show: true, recipe: undefined })}
                className="d-flex row align-items-center mr-3"
                style={{ cursor: 'pointer' }}>
                <img src={IconMore} alt={'+'} />
                <div className={'b-add-k'}>Crear receta</div>
              </div>
            </div>
          )}
        </div>
      )}

      <Card white title={'Listado de Recetas'}>
        {loading && <ProgressBar now={100} animated />}
        {canCreate && renderDetail()}
        {create && renderButtons()}
      </Card>
      <Modal
        show={showRecipes}
        size={'xl'}
        centered
        aria-labelledby={'contained-modal-title-vcenter'}
        onHide={() => {
          setRecipes(false)
          clean()
          init()
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Modificar Receta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={'container'}>{renderEdit()}</div>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            {canDelete && (
              <Button
                loading={deleteLoading}
                color={'secondary'}
                icon={faTrash}
                onClick={() => {
                  setSelected({})
                  dispatch(
                    showAlert({
                      title: `¿Eliminar esta receta?`,
                      text: 'Esta acción no se puede revertir',
                      type: 'info',
                      show: true,
                      showCancelButton: true,
                      onConfirm: () => {
                        dispatch(deleteEnumeration(enumerationId ?? item.id))
                      },
                    }),
                  )
                }}>
                Eliminar
              </Button>
            )}
            {canEdit && (
              <Button
                disabled={deleteLoading}
                color={'primary'}
                icon={faEdit}
                onClick={() => {
                  save(isEdit)
                  setCreate(true)
                  setEdit(true)
                }}>
                Editar
              </Button>
            )}
          </Row>
        </Modal.Footer>
      </Modal>

      <CreateOrUpdateRecipe
        show={showCreate.show}
        title={'Crear Receta'}
        customRecipe={showCreate.recipe}
        onHide={update => {
          setShowCreate({ ...showCreate, show: false })
          if (update) dispatch(onGetEnumerations(false))
        }}
        createOrUpdate
      />
    </div>
  )
}

export default RecipeDetail
