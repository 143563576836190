import { Env, environment } from 'src/environment'

const {
  REACT_APP_API_URL,
  REACT_APP_MAPS_KEY,
  REACT_APP_MAPS_KEY_AUX,
  REACT_APP_API_URL_AUX,
} = process.env
export const API_URL = REACT_APP_API_URL || `https://dev.${REACT_APP_API_URL_AUX}/api`
export const WSS_URL = 'wss://'.concat(
  environment === Env.prod
    ? REACT_APP_API_URL_AUX
    : environment === Env.stg
    ? `stg.${REACT_APP_API_URL_AUX}`
    : `dev.${REACT_APP_API_URL_AUX}`,
)
export const LOG_ENABLED = true
export const MAPS_API_KEY = REACT_APP_MAPS_KEY || REACT_APP_MAPS_KEY_AUX
export const CHAT_BOX_KEY = process.env.REACT_APP_USER_LIKE_WIDGET_KEY

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH,
  databaseURL: process.env.REACT_APP_DB_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_BUCKET,
  messagingSenderId: process.env.REACT_APP_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
}
