import { executeAction } from './global.actions'
import PurchaseService from '../services/purchase.services'
import ResolutionsService from '../services/resolutions.service'
import { types } from './utilities.actions'

export const actionTypes = {
  SET_INFO: 'SET_INFO',
  GET_ALL_PURCHASE: 'GET_ALL_PURCHASE',
  CREATE: 'CREATE',
  GET_LOG: 'GET_LOG',
  GET_SINGLE: 'GET_SINGLE',
  SET_STEP: 'SET_STEP',
  GET_STATUS: 'GET_STATUS',
  UPDATE_STATUS: 'UPDATE_STATUS',
  ACCREDIT: 'ACCREDIT',
  NULLIFY_RESPONSE: 'NULLIFY_RESPONSE',
  GET_BY_AMOUNT: 'GET_BY_AMOUNT',
  ACCREDIT_MULTIPLE: 'ACCREDIT_MULTIPLE',
  ACCREDIT_MULTIPLE_V2: 'ACCREDIT_MULTIPLE_V2',
  TRANSACTIONS: 'TRANSACTIONS',
  DOCUMENTS: 'DOCUMENTS',
  SEE_AUTO: 'SEE_AUTO',
  PROVIDERS: 'PROVIDERS',
  PROVIDERS_PRODUCTS: 'PROVIDERS_PRODUCTS',
  PROVIDERS_PRODUCTS_USER: 'PROVIDERS_PRODUCTS_USER',
  SET_VIEWER: 'SET_VIEWER',
  PRINT_PURCHASE: 'PRINT_PURCHASE',
  GET_SUMMATIVE: 'GET_SUMMATIVE',
  EXPORT_CSV: 'EXPORT_CSV',
  GET_SUM_CPP: 'GET_SUM_CPP',
  GET_PURCHASES_SINGLE: 'GET_PURCHASES_SINGLE',
  LINK_EXPENSE: 'LINK_EXPENSE',
  CERTIFICATE: 'CERTIFICATE',
  GET_INVOICE: 'GET_INVOICE',
  PRINT_ORDER: 'PRINT_ORDER',
  LOAD_CSV: 'LOAD_CSV',
  CREATE_SIMPLE: 'CREATE_SIMPLE',
  UPDATE_SIMPLE: 'UPDATE_SIMPLE',
  CREATE_CSV: 'CREATE_CSV',
  ADD_INVENTORY_BY_PURCHASE: 'ADD_INVENTORY_BY_PURCHASE',
  SEE_PURCHASE_PAYMENTS: 'SEE_PURCHASE_PAYMENTS',
  CONFIRM_PURCHASE: 'CONFIRM_PURCHASE',
  LOAD_PRODUCTS_CSV: 'LOAD_PRODUCTS_CSV',
  UPDATE_PURCHASE: 'UPDATE_PURCHASE',
  UPDATE_PURCHASE_INFORMATION: 'UPDATE_PURCHASE_INFORMATION',
}

const setInfoPurchase = purchase => ({ type: actionTypes.SET_INFO, purchase })
const setStepPurchase = step => ({ type: actionTypes.SET_STEP, step })

export const setInfo = (purchase, customVoid) => dispatch => {
  dispatch(setInfoPurchase(purchase))
  if (customVoid) customVoid()
}

export const setStep = step => dispatch => {
  dispatch(setStepPurchase(step))
}

export const getAllPurchases = params => dispatch => {
  const process = async () => ({ purchases: await PurchaseService.getPurchases(params) })
  dispatch(executeAction(actionTypes.GET_ALL_PURCHASE, process))
}

export const getSummative = params => dispatch => {
  const process = async () => ({ summative: await PurchaseService.getSummative(params) })
  dispatch(executeAction(actionTypes.GET_SUMMATIVE, process))
}

export const getSinglePurchases = params => dispatch => {
  const process = async () => ({ purchases: await PurchaseService.getPurchases(params) })
  dispatch(executeAction(actionTypes.GET_PURCHASES_SINGLE, process))
}

export const onCreatePurchase = (request, commentary, confirmBy) => dispatch => {
  const process = async () => ({
    purchase: await PurchaseService.onCreatePurchase(request, commentary, confirmBy),
  })
  dispatch(executeAction(actionTypes.CREATE, process))
}

export const onCreateSimplePurchase = request => dispatch => {
  const process = async () => ({
    purchase: await PurchaseService.onCreateSimplePurchase(request),
  })
  dispatch(executeAction(actionTypes.CREATE_SIMPLE, process))
}

export const onCreateOrderPurchase = (request, commentary, confirmBy) => dispatch => {
  const process = async () => ({
    purchase: await PurchaseService.onCreateOrderPurchase(request, commentary, confirmBy),
  })
  dispatch(executeAction(actionTypes.CREATE, process))
}

export const onChangeOrderPurchase = (request, commentary, id, action) => dispatch => {
  const process = async () => ({
    purchase: await PurchaseService.onChangeOrderPurchase(
      request,
      commentary,
      id,
      action,
    ),
  })
  dispatch(executeAction(actionTypes.CREATE, process))
}

export const onUpdateOrderPurchase = (request, commentary, id, action) => dispatch => {
  const process = async () => ({
    purchase: await PurchaseService.onChangeOrderPurchaseV2(
      request,
      commentary,
      id,
      action,
    ),
  })
  dispatch(executeAction(actionTypes.UPDATE_PURCHASE_INFORMATION, process))
}

export const getPurchaseLog = id => dispatch => {
  const process = async () => ({ response: await PurchaseService.getLog(id) })
  dispatch(executeAction(actionTypes.GET_LOG, process))
}

export const getPurchase = id => dispatch => {
  const process = async () => ({ purchase: await PurchaseService.getPurchase(id) })
  dispatch(executeAction(actionTypes.GET_SINGLE, process))
}

export const updatePurchaseSimple = (id, request) => dispatch => {
  const process = async () => ({
    purchase: await PurchaseService.updateSimplePurchase(id, request),
  })
  dispatch(executeAction(actionTypes.UPDATE_SIMPLE, process))
}

export const onUpdateStatusPurchase = (purchaseId, status, request) => dispatch => {
  const process = async () => ({
    updated: await PurchaseService.onUpdateStatusPurchase(purchaseId, status, request),
  })
  dispatch(executeAction(actionTypes.UPDATE_STATUS, process))
}

export /**
 * Approve the purchase and upload items and make payments
 * @param {Number} purchaseId Id of purchase
 * @param {IPurchasePaymentRequest} request Request to enter purchase items and make payments
 */
const onConfirmPurchases = (purchaseId, request) => dispatch => {
  const process = async () => ({
    updated: await PurchaseService.onConfirmPurchase(purchaseId, request),
  })
  dispatch(executeAction(actionTypes.CONFIRM_PURCHASE, process))
}

export const accreditMovementByPurchase = (purchaseId, request) => dispatch => {
  const process = async () => ({
    purchase: await PurchaseService.accreditMovementByPurchase(purchaseId, request),
  })
  dispatch(executeAction(actionTypes.ACCREDIT, process))
}

export const onReserveCurrentPlaceInventory = (purchaseId, params) => dispatch => {
  const process = async () => ({
    nullifyResponse: await PurchaseService.onReserveCurrentPlaceInventory(
      purchaseId,
      params,
    ),
  })
  dispatch(executeAction(actionTypes.NULLIFY_RESPONSE, process))
}

/*
export const onUpdatePurchaseDetail = (purchaseId, request, params) => dispatch => {
  const process = async () => ({
    nullifyResponse: await PurchaseService.onUpdatePurchaseDetail(
      purchaseId,
      request,
      params,
    ),
  })
  dispatch(executeAction(actionTypes.NULLIFY_RESPONSE, process))
} */

export const onNullifyPurchase = (purchaseId, list) => dispatch => {
  const process = async () => ({
    nullifyResponse: await PurchaseService.onNullifyPurchase(purchaseId, list),
  })
  dispatch(executeAction(actionTypes.NULLIFY_RESPONSE, process))
}

export const onNullifyTransactionPurchase = (purchaseId, params) => dispatch => {
  const process = async () => ({
    nullifyResponse: await PurchaseService.onNullifyTransactionPurchase(
      purchaseId,
      params,
    ),
  })
  dispatch(executeAction(actionTypes.NULLIFY_RESPONSE, process))
}

export const getByAmount = amount => dispatch => {
  const process = async () => ({
    simplePurchases: await PurchaseService.getByAmount(amount),
  })
  dispatch(executeAction(actionTypes.GET_BY_AMOUNT, process))
}

export const accreditMultipleByMovement = (movement, purchases) => dispatch => {
  const process = async () => ({
    accreditMultiple: await PurchaseService.accreditMultipleByMovement(
      movement,
      purchases,
    ),
  })
  dispatch(executeAction(actionTypes.ACCREDIT_MULTIPLE, process))
}

export const accreditMultipleByMovementV2 = (movement, request) => dispatch => {
  const process = async () => ({
    accreditMultiple: await PurchaseService.accreditMultipleByMovementV2(
      movement,
      request,
    ),
  })
  dispatch(executeAction(actionTypes.ACCREDIT_MULTIPLE_V2, process))
}

export const getTransactions = (purchaseId, serie, productId) => dispatch => {
  const process = async () => ({
    transactions: await PurchaseService.getTransactions(purchaseId, serie, productId),
  })
  dispatch(executeAction(actionTypes.TRANSACTIONS, process))
}

export const getDocuments = purchaseId => dispatch => {
  const process = async () => ({
    documents: await PurchaseService.getDocuments(purchaseId),
  })
  dispatch(executeAction(actionTypes.DOCUMENTS, process))
}

export const updateInformation =
  (purchaseId, reference, conversion, detail) => dispatch => {
    const process = async () => ({
      purchase: await PurchaseService.updateInformation(
        purchaseId,
        reference,
        conversion,
        detail,
      ),
    })
    dispatch(executeAction(types.GET_MODAL_PURCHASE, process))
  }

export const getProviders = userId => dispatch => {
  const process = async () => ({ providers: await PurchaseService.getProviders(userId) })
  dispatch(executeAction(actionTypes.PROVIDERS, process))
}

export const getProvidersProducts = (companyId, userId) => dispatch => {
  const process = async () => ({
    providersProducts: await PurchaseService.getProvidersProducts(companyId, userId),
  })
  dispatch(executeAction(actionTypes.PROVIDERS_PRODUCTS, process))
}

export const getProviderProductByUser = (companyId, userId) => dispatch => {
  const process = async () => ({
    providersProductsUser: await PurchaseService.getProvidersProducts(companyId, userId),
  })
  dispatch(executeAction(actionTypes.PROVIDERS_PRODUCTS_USER, process))
}

export const setViewerProducts = (providerId, viewerId, products) => dispatch => {
  const process = async () => ({
    setViewer: await PurchaseService.setViewerProducts(providerId, viewerId, products),
  })
  dispatch(executeAction(actionTypes.SET_VIEWER, process))
}

export const seeAuto = (id, invoice) => dispatch => {
  const process = () => ({ auto: { id, invoice } })
  dispatch(executeAction(actionTypes.SEE_AUTO, process))
}

export const printPurchase = (id, defaultName) => dispatch => {
  const process = async () => await PurchaseService.printPurchase(id, defaultName)
  dispatch(executeAction(actionTypes.PRINT_PURCHASE, process))
}

export const linkExpense = (id, list) => dispatch => {
  const process = async () => await PurchaseService.linkExpense(id, list)
  dispatch(executeAction(actionTypes.LINK_EXPENSE, process))
}

export const getSumCPP = params => dispatch => {
  const process = async () => ({ sum: await PurchaseService.getSumCPP(params) })
  dispatch(executeAction(actionTypes.GET_SUM_CPP, process))
}

export const exportCSV = params => dispatch => {
  const process = async () => await PurchaseService.exportCSV(params)
  dispatch(executeAction(actionTypes.EXPORT_CSV, process))
}

export const certificatePurchase = purchaseId => dispatch => {
  const process = async () => await PurchaseService.certificatePurchase(purchaseId)
  dispatch(executeAction(actionTypes.CERTIFICATE, process))
}

export const getInvoiceByPurchase = id => dispatch => {
  const process = async () => ({
    invoice: await PurchaseService.getInvoiceByPurchase(id),
  })
  dispatch(executeAction(actionTypes.GET_INVOICE, process))
}

export const downloadPrintableInvoiceByPurchase = (id, defaultName) => dispatch => {
  const process = async () =>
    await ResolutionsService.downloadPrintableInvoiceByPurchase(id, defaultName)
  dispatch(executeAction(actionTypes.PRINT_PURCHASE, process))
}

export const loadProducts = (file, onSuccess, type) => dispatch => {
  let response
  const process = async () => {
    response = await PurchaseService.loadCSV(file, type)
    onSuccess(response, false)
    return { response }
  }
  dispatch(executeAction(actionTypes.LOAD_CSV, process))
}

export const loadSimples = (data, type, currentIndex, onSuccess) => async dispatch => {
  const response = await PurchaseService.loadSimpleCSV(data, type, currentIndex)
  const process = async () => ({
    response,
  })
  dispatch(executeAction(actionTypes.CREATE_CSV, process))
  onSuccess(response, false)
}

/**
 * Action para agregar inventario de una compra, este action recibe lista de items, ya que se hace por tuplas.
 * @param {number} id Id de la compra/gasto
 * @param {Object} request Contiene la lista de items, y un bandera si son los últimos
 * @param {ArrayList} request.details Lista de items de la compra/gasto
 * @param {boolean} request.lastDetails Si los items enviados son los últimos de la compra.
 * @returns None
 */
export const addInventoryByPurchase = (id, request) => dispatch => {
  const process = async () => await PurchaseService.addInventoryByPurchase(id, request)
  dispatch(executeAction(actionTypes.ADD_INVENTORY_BY_PURCHASE, process))
}

export const seePurchasePayments = paymentsPurchaseValues => dispatch => {
  const process = () => ({ paymentsPurchaseValues })
  dispatch(executeAction(actionTypes.SEE_PURCHASE_PAYMENTS, process))
}

export /**
 * Servicio de carga masiva de productos a Ingreso de inventario
 * @param {Array<Object>} products Lista de productos provenientes del CSV
 * @param {Number} warehouseId     Id de bodega
 * @param {Number} type tipo de operaciones. 0: compra, 1: importacion, 2: gasto
 * @returns Object Response service
 */
const massiveLoadProduct = (data, type, onSuccess) => async dispatch => {
  const response = await PurchaseService.loadProductsPurchase(data, type)
  const process = async () => ({
    response,
  })
  dispatch(executeAction(actionTypes.LOAD_PRODUCTS_CSV, process))
  onSuccess(response, false)
}

/**
 * Action to update purchase
 * @param {number} purchaseId Purchase Id
 * @param {Object} request Request of the purchase
 * @returns
 */
export const updatePurchase = (purchaseId, request) => dispatch => {
  const process = async () => ({
    purchase: await PurchaseService.updatePurchase(purchaseId, request),
  })
  dispatch(executeAction(actionTypes.CREATE, process))
}
