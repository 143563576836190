import React, { useState, useEffect } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Icon } from 'src/components'
import CustomFilter from 'src/components/CustomFilter/CustomFilter'
import Button from 'src/components/buttons/Button'
import { loadingSelector } from 'src/selectors/loading.selector'
import { actionTypes } from 'src/actions/warehouse.actions'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import Placeholder from 'src/assets/images/newDesign/placeholder.svg'
import { selectCompanyFieldProductRepeat } from 'src/selectors/company.selector'
import { itemTypes, toMoney } from 'src/utils/utilities'
import { ModalValueFill } from './ModalValueFill'

import { setGeneralConfig, setItemTable } from 'src/actions/transaction.action'
import {
  selectConfigTransactionTable,
  selectItemSelected,
} from 'src/selectors/transaction.selector'
import { ListVariations } from 'src/content/Production/Categorization/Variation/ListVariations'

interface Props {
  setUp: (search?: string) => void
  selected: IItemSelectTable[]
  itemsToSelect: IItemSelectTable[]
  type: number
  isEdit: boolean
  onChangePercentDiscount: (item: IItemSelectTable, value: ISelect) => void
  onChangeDiscount: (item: IItemSelectTable, discount: number) => void
  discounts: ISelect[]
  useLocations: boolean
  seeInputTax: boolean
}

let interval
/**
 *
 * @compoent
 * @param {Function} setUp Función para obtener los items cuando se busca por nombre.
 * @param {Array} selected Lista de items seleccionados
 * @param {Array} itemsToSelect Lista de items a seleccionar
 * @param {Number} type Tipo de transacción
 * @param {Boolean} isEdit Indica si es una edición
 * @param {Function} onChangeDiscount Función para cambiar el descuento
 * @param {Function} onChangePercentDiscount Función para cambiar el descuento por porcentaje
 * @param {Array} discounts Lista de descuentos
 * @param {Boolean} useLocations Indica si se usan ubicaciones
 * @param {Boolean} seeInputTax Indica si se ve el impuesto de entrada
 * @returns
 */
export const ModalItems = ({
  setUp,
  selected,
  itemsToSelect,
  type,
  isEdit,
  onChangeDiscount,
  onChangePercentDiscount,
  discounts,
  useLocations,
  seeInputTax,
}: Props) => {
  const dispatch = useDispatch()

  const fieldRepeat = useSelector(selectCompanyFieldProductRepeat)
  const generalConfigTable: IGeneralConfigTTable = useSelector(
    selectConfigTransactionTable,
  )
  const loadingItems = useSelector(state =>
    loadingSelector([actionTypes.GET_ITEMS_TO_SELECT])(state),
  )
  const transfersOut =
    type === itemTypes.SELL || type === itemTypes.TRANSFER || type === itemTypes.WASTE
  const transfersIn =
    type === itemTypes.PURCHASE ||
    type === itemTypes.PRODUCTION ||
    type === itemTypes.EXPENSE
  const [search, setSearch] = useState<string>('')
  const [showVariations, setShowVariations] = useState<boolean>(false)
  const itemTable: IItemTable = useSelector(selectItemSelected)

  useEffect(() => {
    if (!search) return
    clearInterval(interval)
    interval = setTimeout(() => {
      setUp(search)
    }, 700)
  }, [search])

  /**
   * Da formato a los items para seleccionar
   * @param {Array} data Datos de items
   * @returns
   */
  const formatItems = data => {
    if (!data) return []
    return data.map(d => ({
      ...d,
      value: d.id,
      label: d.code + ' - ' + d.name,
      includeIva: true,
      productPrice: d.price,
      productId: d.id,
      lineItems: d.value === 1 ? d.lineItems : [],
    }))
  }
  /**
   * Obtienen los items disponibles para seleccionar
   * @param {Array} data Lista de items a seleccionar
   * @returns array de items
   */
  const getItems = data => {
    if (fieldRepeat) return formatItems(data)
    const list = data.filter(d => selected.findIndex(s => s && s.id === d.id) === -1)
    return formatItems(list)
  }

  return (
    <>
      <Modal
        show={generalConfigTable.showModalItems}
        centered
        size={'xl'}
        onHide={() => {
          setSearch('')
          dispatch(setGeneralConfig({ showModalItems: false }))
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Selección de ítems</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <CustomFilter
                search={search}
                onChange={({ target }) => setSearch(target.value)}
                placeholder={'Buscar por sku/nombre'}
                loading={loadingItems}
              />
            </Col>
            <Col xl={12}>
              <div className={'column t-items-body'}>
                <div>
                  {getItems(itemsToSelect).map((item, index) => (
                    <div className={'d-flex b-item'} key={index}>
                      <div className={'d-flex'}>
                        <div className={'t-item-img'} style={{ width: 45, height: 45 }}>
                          <img src={item.url || Placeholder} alt={'img'} />
                        </div>
                      </div>
                      <div style={{ width: '100%' }}>
                        <div className={'column pl-1 ml-1 mr-1'}>
                          <div className={'column'}>
                            <div className={'b-user-name'}>{item.label}</div>
                            {item.id !== 1 && (
                              <div className={'b-user-email'}>{toMoney(item.price)}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div style={{ width: '10%', position: 'relative' }}>
                        <div
                          className={'p-absolute'}
                          style={{ top: '25%', position: 'absolute' }}>
                          <Icon
                            icon={faPlusCircle}
                            size={'2x'}
                            color={'accent'}
                            onClick={() => {
                              setSearch('')

                              const newValue =
                                item.id !== 1
                                  ? Object.assign({}, item)
                                  : JSON.parse(JSON.stringify(item))
                              dispatch(
                                setItemTable({
                                  item: newValue,
                                  inBatches: newValue.inBatches,
                                }),
                              )
                              if (item.listCategorization !== null) {
                                if (transfersIn) {
                                  setShowVariations(true)
                                }
                                dispatch(
                                  setGeneralConfig({
                                    value: item.value,
                                    getVariationsValue: transfersOut,
                                  }),
                                )
                              } else {
                                dispatch(
                                  setGeneralConfig({
                                    showDetails:
                                      transfersIn || (transfersOut && !useLocations),
                                    getItemValue: transfersOut ? !useLocations : true,
                                    value: item.value,
                                    getVariationsValue: transfersOut
                                      ? useLocations
                                      : false,
                                  }),
                                )
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              onClick={() => {
                dispatch(setGeneralConfig({ showModalItems: false }))
                dispatch(setItemTable({ item: {} }))
                setSearch('')
              }}
              color={'secondary'}>
              Cerrar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      {/* @ts-expect-error JS*/}
      <ListVariations
        show={showVariations}
        onSave={list => {
          setShowVariations(false)
          const listV: number[] = list.map(item => item.id)
          dispatch(
            setItemTable({
              ...itemTable,
              item: { ...itemTable.item, listCategorization: list },
            }),
          )
          dispatch(
            setGeneralConfig({
              showDetails: true,
              getItemValue: true,
              params: { categories: listV },
            }),
          )
        }}
        onHide={() => {
          setShowVariations(false)
        }}
        modeSelection
        selected={[]}
        data={itemTable.item.listCategorization}
        hideMessage
        hideVariations
      />

      <ModalValueFill
        setUp={setUp}
        discounts={discounts}
        isEdit={isEdit}
        onChangeDiscount={onChangeDiscount}
        onChangePercentDiscount={onChangePercentDiscount}
        type={type}
        seeInputTax={seeInputTax}
      />
    </>
  )
}
