import React from 'react'
import DashboardCard from 'src/components/cards/dashboard-card/DashboardCard'

import { connect } from 'react-redux'
import monitor from 'src/assets/images/svg/monitor.svg'
import clipboard from 'src/assets/images/svg/clipboard.svg'
import accounting from 'src/assets/images/svg/accounting.svg'

class RequestDashboard extends React.Component {
  render() {
    return (
      <div>
        <h1 className={'dashboard-welcome'}>Solicitudes</h1>
        <div className={'promotor-modules-list'}>
          <DashboardCard icon={clipboard} name={'Documentos'} link={'documentos'} />
          <DashboardCard icon={monitor} name={'Productor'} link={'productor'} />
          {/*
                    <DashboardCard
                        icon={accounting}
                        name={'Desembolsos'}
                        link={'desembolsos'}
                    />
                    */}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(RequestDashboard)
