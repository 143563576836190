/** @module actiion/transaction */

import { executeAction } from './global.actions'
import TransactionsServices from 'src/services/transactions.services'

export const actionTypes = {
  SET_GENERAL_ACTION: 'SET_GENERAL_ACTION',
  SET_ITEM_TABLE: 'SET_ITEM_TABLE',
  ON_SET_TRANSACTION_UUID: 'ON_SET_TRANSACTION_UUID',
  ON_CREATE_TRANSACTION_FLAG: 'ON_CREATE_TRANSACTION_FLAG',
}

/**
 * Setea las configuraciones generales de la tabla, como descuentos, iva, etc
 * @param {IGeneralConfigTTable} payload
 * @returns None
 */
export const setGeneralConfig = (payload: IGeneralConfigTTable) => dispatch => {
  const process = () => ({ payload })
  dispatch(executeAction(actionTypes.SET_GENERAL_ACTION, process))
}

/**
 * Setea los valores temporales del item cuando se configura en version movil
 * @param {IItemTable} payload
 * @returns None
 */
export const setItemTable = (payload: IItemTable) => dispatch => {
  const process = () => ({ payload })
  dispatch(executeAction(actionTypes.SET_ITEM_TABLE, process))
}

/** Save in storage the uuId auto generated
 * @param {string} uuId uuId auto generated
 * */
export const onSetTransactionUuId = (uuId: string) => dispatch => {
  const process = () => ({ payload: uuId })
  dispatch(executeAction(actionTypes.ON_SET_TRANSACTION_UUID, process))
}

/** Create a transaction flag.
 * It is to prevent the user from making the same transaction more than once.
 * @param {ITransactionFlag} request Transaction flag with necessary info.
 * */
export const onCreateTransactionFlag = (request: ITransactionFlag) => dispatch => {
  const process = async () => ({
    payload: await TransactionsServices.onCreateTransactionFlag(request),
  })
  dispatch(executeAction(actionTypes.ON_CREATE_TRANSACTION_FLAG, process))
}
