import { $http } from './http'

class CompanyConfigurationService {
  getCompanyConfigurationTypes = async () => {
    const response = await $http.get(`/company/configuration/types`)
    return response.data
  }

  getCompanyConfigurations = async () => {
    const response = await $http.get(`/company/configuration/`)
    return response.data
  }

  setCompanyConfiguration = async (type, value) => {
    const response = await $http.put(`/company/configuration/${type}`, value)
    return response.data
  }

  getFelConfiguration = async companyId => {
    const response = await $http.get('/company/configuration/FEL', {
      params: { companyId },
    })
    return response.data
  }

  updateFelConfiguration = async (companyId, request) => {
    const response = await $http.put('/company/configuration/FEL', request, {
      params: { companyId },
    })
    return response.data
  }
}
export default new CompanyConfigurationService()
