export const selectDocumentsTypes = state =>
  state.resolutions.documents.map(d => ({ value: d.id, label: d.name }))
export const selectResolutions = state => state.resolutions.resolutions
export const selectLimit = state => state.resolutions.limit
export const selectCreate = state => state.resolutions.createResponse
export const selectError = state => state.resolutions.error
export const selectBills = state => state.resolutions.bills
export const selectBill = state => state.resolutions.bill
export const selectGetFELData = state => state.resolutions.felData
export const selectSetFELData = state => state.resolutions.felData
export const selectGetAdminFELData = state => state.resolutions.adminFelData
export const selectToogleFELData = state => state.resolutions.changedFelData
export const selectGetSingleInvoice = state => state.resolutions.invoice

export const selectCompanyPhrases = state => state.resolutions.companyPhrases

export const selectFreeResolutionsForSelect = state =>
  state.resolutions.freeResolutions.map(item => ({
    value: item.id,
    label: `Serie: ${item.series}, Numero: ${item.number} del ${item.initialNumber} a ${item.finalNumber}`,
  }))

export const selectDocumentRequests = state => state.resolutions.documentRequests
export const selectDocumentRequest = state => state.resolutions.documentRequest

export const selectProfile = state => state.resolutions.profile
export const selectProfileOptions = state =>
  state.resolutions.profileOptions.map(p => ({
    ...p,
    value: p.id,
    label: p.name,
  }))
