import React from 'react'

import Paragraph from 'src/components/Paragraph/Paragraph'
import { formatDateFromMillis } from 'src/utils/formatters'
import { toMoney } from 'src/utils/utilities'

/** Component to see purchase information by field
 * @param {IPurchaseField} props Purchase field
 * @param {string} props.title Title of information
 * @param {string | number} props.value Value of information
 * @param {string} props.type Type of information
 * @param {boolean} props.separate Indicates whether to add a separator
 */
const PurchaseInformationDetail = ({
  title,
  value,
  type = 'text',
  separate = false,
}: IPurchaseField) => {
  let newValue: string | number
  switch (type) {
    case 'date':
      newValue = formatDateFromMillis(value)
      break
    case 'money':
      newValue = toMoney(value)
      break
    default:
      newValue = value
      break
  }

  return (
    <div className={`space-between ${separate ? 'mb-3' : ''}`}>
      <Paragraph bold>{title}</Paragraph>
      <Paragraph>{newValue}</Paragraph>
    </div>
  )
}
export default PurchaseInformationDetail
