import React, {Component} from 'react';
import {connect} from "react-redux"
import {Col, Row} from "react-bootstrap";
import Card from "../../../../components/cards/Card";
import CustomSelect from "../../../../components/inputs/Select/CustomSelect";
import {getCompaniesByType} from "../../../../actions/company.actions";
import {selectCompaniesByTypeWithAllForSelect} from "../../../../selectors/company.selector";
import {selectUsersByCompanyWithAllForSelect} from "../../../../selectors/modules.selector";
import {getUsersByCompany} from "../../../../actions/modules.actions";
import {actionTypes, getBIPaymentsHistory, getSaleOrdersBalance} from "../../../../actions/payments.actions";
import {selectBIPaymentsHistory, selectSaleOrderBalance} from "../../../../selectors/payments.selector";
import {loadingSelector} from "../../../../selectors/loading.selector";
import {Link} from "react-router-relative-link";
import {TableV2, Title} from "../../../../components";
import { selectCurrentCurrency } from '../../../../selectors/currencies.selector';
import Money from '../../../../components/Money/Money';


class BIPayments extends Component {

    state = {
        selectedCompany: {value: 0, label: 'Todas las Empresas'},
        selectedUser: {value: 0, label: 'Todos los Usuarios'},
        selectedAgreement: null,
        startDate: new Date(),
        endDate: new Date(),
        error: {},
        companiesByType: [],
        usersByCompany: [],
        saleOrdersBalance: 0,
        biPaymentsHistory: [],
    };

    componentDidMount() {
        this.props.getCompaniesByType();
        this.refreshBalanceAndHistory(null, null, null, null, null);
    }

    onSelectedCompanyChange = data => {
        this.setState({selectedCompany: data, selectedUser: {value: 0, label: 'Todos los Usuarios'}});
        this.props.getUsersByCompany(data.value, 1002);
        this.refreshBalanceAndHistory(data.value, null, null, null, null);
    };

    onSelectedUserChange = data => {
        this.setState({selectedUser: data});
        this.refreshBalanceAndHistory(null, data.value, null, null, null);
    };

    onDateFiltersChanged = (from, to)=>{
        this.setState({startDate: from, endDate: to});
        this.refreshBalanceAndHistory(null, null, null, from, to);
    };

    refreshBalanceAndHistory(companyId, userId, biAgreementId, startDate, endDate)
    {
        if(companyId == null) companyId = this.state.selectedCompany == null ? '' : this.state.selectedCompany.value;
        if(userId == null) userId = this.state.selectedUser == null ? '' : this.state.selectedUser.value;
        if(biAgreementId == null) biAgreementId = this.state.selectedAgreement == null ? '' : this.state.selectedAgreement.value;

        if(startDate == null) startDate = this.state.startDate;
        if(endDate == null) endDate = this.state.endDate;

        this.props.getSaleOrdersBalance(companyId, userId);
        this.props.getBIPaymentsHistory(companyId, userId, biAgreementId, startDate.getTime(), endDate.getTime());
    }

    getHeaders = () => {
        return [
            {label: 'Código Empresa', show: true, value: ['companyData','alphanumericId'], type: 'text' ,  className:'mini'},
            {label: 'Empresa', show: true, value: ['companyData','name'], type: 'text' ,  className:'mini'},
            {label: 'Código Usuario', show: true, value: ['userData','alphanumericId'], type: 'text' ,  className:'mini'},
            {label: 'Usuario', show: true, value: ['userData','name'], type: 'text' ,  className:'mini'},
            {label: 'Convenio', show: true, value: ['agreement'], type: 'text' ,  className:'mini'},
            {label: 'Autorización Banco', show: true, value: ['bankAuthorization'], type: 'text' ,  className:'mini'},
            {label: 'Autorización Cotizap', show: true, value: ['providerAuthorization'], type: 'text' ,  className:'mini'},
            {label: 'Canal', show: true, value: ['channelName'], type: 'text' ,  className:'mini'},
            {label: 'Operación', show: true, value: ['operationName'], type: 'text' ,  className:'mini'},
            {label: 'Total Pago', show: true, value: ['paymentTotal'], type: 'currency' ,  className:'mini'},
            {label: 'Fecha', show: true, value: ['createdAt'], type: 'date' ,  className:'mini'},
            {config:true, show: true, label:'', className:'mini center'}

        ]
    }

   render() {
       const {selectedCompany, selectedUser, error} = this.state;
       const {companiesByType, usersByCompany, biPaymentsHistory, saleOrdersBalance, biPaymentsHistoryIsLoading, currentCurrency} = this.props;

       return (

           <div>
               <Title title={'Pagos Banco Industrial'}/>
               <Row>
                   <Col md={3}>
                       <Card title={'Filtros'} bodyStyle={{padding: 10}}>
                           <Row>
                               <Col>
                                   <CustomSelect
                                       label={'Empresa'}
                                       value={selectedCompany}
                                       options={companiesByType}
                                       placeholder={'Selecciona...'}
                                       onChange={this.onSelectedCompanyChange}
                                       error={error.selectedCompany}
                                   />
                               </Col>
                           </Row>
                           <Row>
                               <Col>
                                   <CustomSelect
                                       label={'Usuario'}
                                       value={selectedUser}
                                       options={usersByCompany}
                                       placeholder={'Selecciona...'}
                                       onChange={this.onSelectedUserChange}
                                       error={error.selectedUser}
                                   />
                               </Col>
                           </Row>
                       </Card>
                       <Card title={'Balance Órdenes de Venta'} bodyStyle={{padding: 10}}>
                           <Row>
                               <Col>
                                   <Money className={'center'}>{saleOrdersBalance}</Money>
                               </Col>
                           </Row>
                       </Card>
                       <Link to={'depositos'} className={'big-button'}>Depósitos ingresados</Link>
                   </Col>
                   <Col md={9}>
                       <Card title={'Historial'} bodyStyle={{padding: 10}} white>
                           <TableV2
                                headers={this.getHeaders()}
                                items={biPaymentsHistory}
                                loading={biPaymentsHistoryIsLoading}
                                dateFilter={true}
                                onDateChange={this.onDateFiltersChanged}
                                noItemsLegend={'No hay movimientos para mostrar'}
                                mobileAuto
                                storageKey={`biPyaments`}
                           />
                       </Card>
                   </Col>
               </Row>
           </div>
       );
   }
}

const mapStateToProps = (state) => ({
    companiesByType: selectCompaniesByTypeWithAllForSelect(state),
    usersByCompany: selectUsersByCompanyWithAllForSelect(state),

    saleOrdersBalance: selectSaleOrderBalance(state),
    biPaymentsHistory: selectBIPaymentsHistory(state),
    biPaymentsHistoryIsLoading: loadingSelector([actionTypes.GET_BI_PAYMENTS_HISTORY])(state),
    currentCurrency: selectCurrentCurrency(state)
});

const mapDispatchToProps = dispatch => ({
    getCompaniesByType: () => dispatch(getCompaniesByType(false, true, false, 0)),
    getUsersByCompany: (companyId) => dispatch(getUsersByCompany(companyId)),

    getSaleOrdersBalance: (companyId, userId) => dispatch(getSaleOrdersBalance(companyId, userId)),
    getBIPaymentsHistory: (companyId, userId, agreementId, startDate, endDate) => dispatch(getBIPaymentsHistory(companyId, userId, agreementId, startDate, endDate))

});

export default connect(mapStateToProps, mapDispatchToProps)(BIPayments);
