export const selectDisbursements = state => state.disbursement.disbursements
export const selectDisbursement = state => state.disbursement.disbursement
export const selectDisbursementsAdmin = state => state.disbursement.disbursementsAdmin
export const selectDisbursementsBalance = state => state.disbursement.disbursementsBalance
export const selectDisbursementsBalanceCotizap = state =>
  state.disbursement.disbursementsBalanceCotizap
export const selectDisbursementsBalanceDetails = state =>
  state.disbursement.disbursementsDetails
export const selectCsvResponse = state => state.disbursement.csvResponse
export const selectDisbursementRequest = state => state.disbursement.requests
export const selectDisbursementRequestTotal = state => state.disbursement.requestsTotal
