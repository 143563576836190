import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";

import {Modal, Row, Col} from "react-bootstrap";
import {SelectedDates, Button, Icon, Select, Folder, Card} from "../../../components";
import {faEye, faFileExcel, faPlus, faShare} from "@fortawesome/free-solid-svg-icons";
import Alert from "sweetalert-react";

import {
    actionTypes,
    getReportSegmentation, getSegmentationData
} from "../../../actions/report.actions";

import {
    getAllCategorizations,
    onSetLocalCategories
} from "../../../actions/categorization.actions";
import {selectAllCategorizations} from "../../../selectors/categorizations.selector";

import {loadingSelector} from "../../../selectors/loading.selector";
import {hasErrors, handlerError, handlerSuccess} from "../../../selectors/error.selector";
import {getUsersChildrenByModule} from "../../../actions/modules.actions";
import {selectUsers} from "../../../selectors/modules.selector";
import {selectProductsSimple} from "../../../selectors/products.selector";
import {simpleClients} from "../../../selectors/clients.selector";
import {selectAllPOSUser} from "../../../selectors/restaurant.selector";
import {selectCurrentCompany, selectCurrentUser} from "../../../selectors/user.selector";
import {history} from "../../../App";
import {selectQueriesReport} from "../../../selectors/report.selector";

const ReportsDownloadPresentation = ({show, onClose, onFilter, customParams, modal, dates}) => {
    const dispatch = useDispatch();

    const loading = useSelector((state) => loadingSelector([actionTypes.GET_SEGMENTATION])(state));
    const error = useSelector((state) => hasErrors([actionTypes.GET_SEGMENTATION])(state));
    const loadingS = useSelector((state) => loadingSelector([actionTypes.GET_SEGMENTATION_DATA])(state));
    const errorS = useSelector((state) => hasErrors([actionTypes.GET_SEGMENTATION_DATA])(state));

    const categorization = useSelector(selectAllCategorizations);
    const user = useSelector(selectCurrentUser)
    const company = useSelector(selectCurrentCompany)
    const queries = useSelector(selectQueriesReport)

    const listUsers = useSelector(selectUsers)
    const listProduct = useSelector(selectProductsSimple)
    const allClients = useSelector(simpleClients)
    const listPos = useSelector(selectAllPOSUser)

    const [params, setParams] = useState({
        start: null, end: null, type: {value: null, label: 'Sin seleccionar'}
    });
    const [showCategories, setShowCategories] = useState({show: false});
    const [users, setUsers] = useState([]);
    const [products, setProducts] = useState([]);
    const [pos, setPos] = useState([]);
    const [clients, setClients] = useState([]);
    const [segClients, setSegClients] = useState({value: 0, label: 'Sin segmentar por cliente'});
    const [filters, setFilters] = useState([]);
    const [segmentations, setSegmentations] = useState([]);
    const [actions, setActions] = useState({get: false, report: false});
    const [alert, setAlert] = useState({title: ''});

    useEffect(() => {
      if(customParams && customParams.params){
        setClients(customParams.clients ? [...customParams.clients] : [])
        setPos(customParams.pos ? [...customParams.pos] : [])
        setFilters(customParams.filters ? [...customParams.filters] : [])
        setUsers(customParams.users ? [...customParams.users] : [])
        setSegmentations(customParams.segmentations ? [...customParams.segmentations] : [])
        setParams(customParams.params ? {...customParams.params} : params)
      }
    }, [customParams])

    useEffect(() => {
      setParams({...params, start: dates.start, end: dates.end})
    }, [dates])

    useEffect(() => {
        if (show) {
          dispatch(getUsersChildrenByModule(1000, true))
          // setParams({...params, type: {value: null, label: 'Sin seleccionar'}});
        }
    }, [show]);

    useEffect(() => {
        if (loading) setActions({...actions, get: true});
        else if (actions.get) {
            setActions({...actions, get: false});
            if (error)
                setAlert({...handlerError(error.message), onConfirm: () => setAlert({...alert, show: false})});
            else if(params.email)
                setAlert({...handlerSuccess('Reporte enviado por correo'), onConfirm: () => setAlert({...alert, show: false})})
        }
    }, [loading]);

    useEffect(() => {
      if (loadingS) setActions({...actions, report: true});
      else if (actions.report) {
        setActions({...actions, report: false});
        if (errorS)
          setAlert({...handlerError(errorS.message), onConfirm: () => setAlert({...alert, show: false})});
        else if(!customParams)
          history.push('segmentacion')
      }
    }, [loadingS]);

    useEffect(() => {
      if(queries.type){
        // setClients()
      }
    }, [queries])

    const assignOrRemoveItem = (item, list) => {
        let response = list;

        const i = list.find((l) => l.id === item.id);
        if (!i)
            response.push(item);
        else
            response = response.filter((l) => l.id !== item.id);

        return response;
    };

    const renderCategories = (list, type, onClick) => (<Row className={'ml-1'}>
        {
            type <= 1 &&
            <Button disabled={loading} onClick={() => {
                onClick();
            }}><Icon icon={faPlus} tooltip={'Agregar'}/></Button>
        }

        {
            list.map((c, index) =>
                <div className="chip mb-2" key={index}>
                    <div className="chip-head">{(c.name && c.name.charAt) && c.name.charAt(0)}</div>
                    <div className="chip-content">{c.name || c.label}</div>
                    <div className="chip-close" onClick={() => {
                        const response = assignOrRemoveItem(c, list);
                        if (type === 0) setFilters(response);
                        else if(type === 1) setSegmentations(response);
                        else if(type === 2) setUsers(response);
                        else if(type === 3) setProducts(response);
                        else if(type === 4) setClients(response);
                        else if(type === 5) setPos(response);
                        // else if(type === 6) setSegClients(response);
                    }}
                      >  <svg className="chip-svg" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                            <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7
                                  15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"/>
                        </svg>
                    </div>
                </div>
            )
        }
    </Row>);

    const isSupportsIt = () => {
      return [4,6,8].findIndex((a) => params?.type?.value === a) >= 0;
    }

    const renderBody = () => {
      return(
        <Row>
          {/*<Col xl={12}>*/}
          {/*  <SelectedDates dates={{dateFrom: (queries?.params?.start || params.start) ? new Date(queries?.params?.start || params.start) : new Date(),*/}
          {/*    dateTo: (queries?.params?.end || params.end) ? new Date(queries?.params?.end || params.end) : new Date()}}*/}
          {/*    setValueDates onDateChange={(start, end) => setParams({...params, start, end})}/>*/}
          {/*</Col>*/}
          <Col xl={12}>
            <Select className={'mb-2'}
               disabled={loading}
               label={'Reporte de ventas por'}
               value={params.type}
               options={[
                 {value: 1, label: 'Venta por orden de venta'},
                 {value: 2, label: 'Ventas a clientes'},
                 {value: 3, label: 'Pagos de cuentas por cobrar emitidas en el periodo'},
                 {value: 4, label: 'Por productos vendidos'},
                 {value: 5, label: 'Totales en monedas'},
                 {value: 6, label: 'Cuentas por cobrar'},
                 {value: 7, label: 'Total de ventas menos notas de crédito o vales'},
                 {value: 8, label: 'Total de ventas menos notas de crédito o vales detallado'}
               ]}
               required
               onChange={(type) => {
                 setParams({...params, type})
                 if(onFilter) onFilter({...params, type}, users, pos, clients, filters, segmentations, products)
               }}
            />
          </Col>
          <Col xl={12}><Card>
            <b>FILTROS</b>
            <div className={'column'}>
              <label className={'ftf-form-label left mt-3'}>Filtrar por categorias</label>
              {renderCategories(filters, 0,() => {
                dispatch(getAllCategorizations(16));
                setShowCategories({show: true, filter: true});
                dispatch(onSetLocalCategories(filters));
              })}
              <Select label={'Clientes'} className={'mb-2'} value={{value: null, label: 'Seleccione un cliente'}}
                      options={allClients.filter(d => !clients.find((c => c.value === d.value)))}
                      onChange={(value) => {
                        clients.push(value)
                        setClients([...clients])
                      }}/>
              {renderCategories(clients, 4,() => {})}
              <Select label={'Productos'} className={'mb-2'} value={{value: null, label: 'Seleccione un producto'}}
                      options={listProduct.filter(d => !products.find((c => c.value === d.value)))}
                      onChange={(value) => {
                        products.push(value)
                        setProducts([...products])
                      }}/>
              {renderCategories(products, 3,() => {})}
            </div>
          </Card></Col>
          <Col xl={12}><Card>
            <b>SEGMENTACIÓN</b>
            <div className={'column'}>
              {
                pos.length === 0 &&
                <>
                  <label className={'ftf-form-label left mt-3'}>Segmentar por categorias</label>
                  {renderCategories(segmentations, 1,() => {
                    dispatch(getAllCategorizations(5));
                    setShowCategories({show: true, filter: false});
                    dispatch(onSetLocalCategories(segmentations));
                  })}
                </>
              }
              {(isSupportsIt()) && <Select
                label={'Clientes'} className={'mb-2'}
                value={segClients}
                // options={allClients.filter(d => !clients.find((c => c.value === d.value)))}
                options={[
                  {value: 0, label: 'Sin segmentar por cliente'},
                  {value: 1, label: 'Segmentar por cliente'}
                ]}
                onChange={(value) => {
                  setSegClients(value);
                  /*
                  segClients.push(value)
                  setSegClients([...segClients])*/
                }}/>}
              {/*{renderCategories(segClients, 6,() => {})}*/}
              {/*{
                        segmentations.length === 0 &&
                          <>
                            <Select label={'Puntos de venta'} className={'mb-2'} value={{value: null, label: 'Seleccione un punto de venta'}}
                                    options={listPos.filter(d => !pos.find((c => c.value === d.value)))}
                                    onChange={(value) => {
                                      pos.push(value)
                                      setPos([...pos])
                                    }}/>
                            {renderCategories(pos, 5,() => {})}
                          </>
                      }*/}
              {/*}
              <Select label={'Usuarios'} className={'mb-2'} value={{value: null, label: 'Seleccione un usuario'}}
                      options={listUsers.filter(d => !users.find((c => c.value === d.value)))}
                      onChange={(value) => {
                        users.push(value)
                        setUsers([...users])
                      }}/>
              {renderCategories(users, 2,() => {})}
              */}
            </div>
          </Card></Col>

        </Row>
      )
    }

    const renderButtons = () => {
      return(
        <Row className={'container-buttons'}>
          <Button icon={faFileExcel} disabled={!params.type.value || (params.type.value !== 6 && (!params.start || !params.end))} onClick={() => {
            dispatch(getReportSegmentation(
              {...params, type: params.type.value},
              {
                categories: filters.map((f) => (f.id)),
                // segmentations: pos.length === 0 ? segClients.length > 0 ? segClients.map(d => (d.id)) : segmentations.map((f) => (f.id)) : [],
                segmentations: pos.length === 0 ? segmentations.map((f) => (f.id)) : [],
                posList: segmentations.length === 0 ? pos.map(d => d.value) : [],
                typeSegmentation: users.length > 0 && pos.length > 0 ? 4 : pos.length > 0 ? 3 :
                  users.length > 0 && segmentations.length > 0 ? 2 : segmentations.length > 0 ? 1 : users.length > 0 ? 2 :
                    params.type.value === 6 ? 5 : 0,
                users: users.map((d => d.id)),
                products: products.map(d => d.id),
                clients: clients.map(d => d.id),
                clientSegmentation: segClients.value
              }
            ));
          }} loading={loading}>Descargar</Button>
          <Button color={'accent'} icon={faEye} loading={loadingS} disabled={!params.type.value || (params.type.value !== 6 && (!params.start || !params.end))}
                  onClick={() => {
                    dispatch(getSegmentationData(
                      {...params, type: params.type.value},
                      {
                        categories: filters.map((f) => (f.id)),
                        segmentations: pos.length === 0 ? segmentations.map((f) => (f.id)) : [],
                        posList: segmentations.length === 0 ? pos.map(d => d.value) : [],
                        typeSegmentation: users.length > 0 && pos.length > 0 ? 4 : pos.length > 0 ? 3 :
                          users.length > 0 && segmentations.length > 0 ? 2 : segmentations.length > 0 ? 1 : users.length > 0 ? 2 :
                            params.type.value === 6 ? 5 : 0,
                        users: users.map((d => d.id)),
                        products: products.map(d => d.id),
                        clients: clients.map(d => d.id),
                        clientSegmentation: segClients.value
                      }
                    ));
                    if(onFilter)
                      onFilter(params, users, pos, clients, filters, segmentations, products)
                  }}>Visualizar</Button>
          <Button icon={faShare} disabled={!params.type.value || (params.type.value !== 6 && (!params.start || !params.end))} onClick={() => {
            let queries =
              {...params, type: params.type.value,
                categories: filters.map((f) => (f.id)).join(),
                categoriesName: filters.map((f) => (f.name)).join(),
                segmentations: pos.length === 0 ? segmentations.map((f) => (f.id)).join() : null,
                segmentationsName: pos.length === 0 ? segmentations.map((f) => (f.name)).join() : null,
                pos: segmentations.length === 0 ? pos.map(d => d.value).join() : null,
                posName: segmentations.length === 0 ? pos.map(d => d.label).join() : null,
                typeSegmentation: users.length > 0 && pos.length > 0 ? 4 : pos.length > 0 ? 3 :
                  users.length > 0 && segmentations.length > 0 ? 2 : segmentations.length > 0 ? 1 : users.length > 0 ? 2 :
                    params.type.value === 6 ? 5 : 0,
                users: users.map((d => d.id)).join(),
                usersName: users.map((d => d.label)).join(),
                products: products.map(d => d.id).join(),
                productsName: products.map(d => d.label).join(),
                clients: clients.map(d => d.id).join(),
                clientsName: clients.map(d => d.label).join(),
              }
            let string = 'https://empresas.kolo-app.com/distribucion/1000/redirect/report/' + company + '?'
            let queryParams = '';
            Object.keys(queries).filter((p) => queries[p] !== undefined && queries[p] !== null && queries[p] !== '').map((p, i) => {
              if (i > 0) queryParams += `&`;
              queryParams += `${p}=${encodeURIComponent(queries[p])}`;
            });
            string += queryParams
            navigator.clipboard.writeText(string).then(() =>
              setAlert({...handlerSuccess('La url ha sido copiada en el portapapeles'), onConfirm: () => setAlert({show: false})})
            );
          }} loading={loading}>Compartir</Button>
        </Row>
      )
    }

    return (<div>
        {
          modal ?
            <Modal show={show} centered size={'md'} onHide={onClose}>
              <Modal.Header closeButton><Modal.Title>Descarga de reportes segmentados</Modal.Title></Modal.Header>
              <Modal.Body>
                {renderBody()}
              </Modal.Body>
              <Modal.Footer>
                {renderButtons()}
              </Modal.Footer>
            </Modal>
            :
            <Card title={'Filtros'}>
              {renderBody()}
              {!modal && renderButtons()}
            </Card>
        }

        <Folder
            list={showCategories.filter ? filters : segmentations}
            show={showCategories.show}
            noMessage onHide={() => setShowCategories({show: false})}
            data1={categorization && categorization.children ? categorization.children[0] : {}}
            data2={categorization && categorization.children ? categorization.children[1] : {}}
            onAssign={(item) => {
                const response = assignOrRemoveItem(
                    item,
                    showCategories.filter ? filters : segmentations
                );
                if (showCategories.filter)
                    setFilters(response);
                else setSegmentations(response);

                dispatch(onSetLocalCategories(response));
            }}
        />

        <Alert {...alert}/>
    </div>
    );
};
export default ReportsDownloadPresentation;
