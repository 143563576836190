import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Row, Col } from 'react-bootstrap'

import Button from 'src/components/buttons/Button'
import FormTextField from 'src/components/inputs/FormTextField/FormTextField'
import CustomDatePicker from 'src/components/CustomDatePicker/CustomDatePicker'
import CustomSelect from 'src/components/inputs/Select/CustomSelect'

import { parseDate } from 'react-day-picker/moment'
import moment from 'moment'

import {
  actionTypes as types,
  getResolutionsLimit,
} from 'src/actions/resolutions.actions'
import { selectLimit } from 'src/selectors/resolutions.selector'

import { loadingSelector } from 'src/selectors/loading.selector'

class CreateResolutions extends Component {
  state = {
    initialNumber: 0,
    success: false,
    minDate: parseDate(moment()),
    resolution: {
      authDate: parseDate(moment()),
    },
    errors: {},
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { loadingLimit } = this.props
    let { resolution, initialNumber } = this.state
    let { limit } = nextProps
    if (loadingLimit && !nextProps.loadingLimit) {
      initialNumber = limit
      resolution.finalNumber = limit + 1
      this.setState({ initialNumber, resolution, success: true })
    }
  }

  changeSeries = event => {
    const { name, value } = event.target
    let { resolution } = this.state
    resolution[name] = value
    this.setState({ resolution, success: false })
  }

  change = event => {
    const { name, value } = event.target
    let { resolution } = this.state
    resolution[name] = value
    this.setState({ resolution })
  }

  onDateChange = date => {
    const { resolution } = this.state
    this.setState({ resolution: { ...resolution, authDate: date } })
  }

  changeDoc = doctype => {
    const { resolution } = this.state
    this.setState({ resolution: { ...resolution, doctype }, success: false })
  }

  validate = () => {
    const { resolution, initialNumber } = this.state
    let errors = {}

    if (!resolution.series) errors.series = 'Es necesario llenar este campo'
    if (!resolution.resolutionNumber)
      errors.resolutionNumber = 'Es necesario llenar este campo'
    if (!resolution.doctype || !resolution.doctype.value)
      errors.doctype = 'Se debe escoger el tipo de documento'
    if (!resolution.finalNumber) errors.finalNumber = 'Es necesario llenar este campo'
    if (resolution.finalNumber <= initialNumber)
      errors.finalNumber = 'Debe ser mayor al número inicial'

    if (Object.keys(errors).length === 0) {
      const request = {
        ...resolution,
        doctype: resolution.doctype.value,
        authDate: moment(resolution.authDate).valueOf(),
      }
      this.props.getRequest(request)
    }
    this.setState({ errors })
  }

  render() {
    const { resolution, errors, minDate, initialNumber, success } = this.state
    const { loading, loadingLimit, documentsTypes } = this.props

    return (
      <div>
        <Row>
          <Col lg={4} md={4} sm={4}>
            <FormTextField
              disabled={loadingLimit || loading}
              id={'series'}
              label={'Serie'}
              placeholder={'pj. AAA'}
              type={'text'}
              name={'series'}
              onChange={this.changeSeries}
              value={resolution.series}
              error={errors.series}
              required
            />
          </Col>
          <Col lg={4} md={4} sm={4}>
            <CustomSelect
              disabled={loadingLimit || loading}
              label={'Tipo de documento'}
              value={resolution.doctype}
              options={documentsTypes}
              onChange={this.changeDoc}
              error={errors.doctype}
              required
            />
          </Col>
          <Col lg={4} md={4} sm={4}>
            <br />
            <br />
            <Row className={'container-buttons'}>
              <Button
                disabled={!resolution.doctype || !resolution.series}
                className={'large'}
                loading={loadingLimit || loading}
                onClick={() =>
                  this.props.getLimit(resolution.doctype.value, resolution.series)
                }
              >
                Buscar
              </Button>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg={4} md={4} sm={4}>
            <FormTextField
              disabled={true}
              id={'initialNumber'}
              label={'Número inicial'}
              placeholder={'1'}
              type={'Text'}
              value={initialNumber}
            />
          </Col>
          <Col lg={4} md={4} sm={4}>
            <FormTextField
              disabled={loadingLimit || loading || !success}
              id={'finalNumber'}
              label={'Número final'}
              placeholder={'2'}
              type={'number'}
              name={'finalNumber'}
              onChange={this.change}
              value={resolution.finalNumber}
              error={errors.finalNumber}
              required
            />
          </Col>
          <Col lg={4} md={4} sm={4}>
            <CustomDatePicker
              disabled={loadingLimit || loading || !success}
              label={'Fecha de autorización'}
              value={resolution.authDate}
              format={'LL'}
              onDayChange={this.onDateChange}
              disabledDays={[{ before: minDate }]}
              required
            />
          </Col>
          <Col lg={4} md={4} sm={4}>
            <FormTextField
              disabled={loadingLimit || loading || !success}
              id={'resolutionNumber'}
              label={'Número de resoluión'}
              placeholder={'pj. 2018-125621'}
              type={'text'}
              name={'resolutionNumber'}
              onChange={this.change}
              value={resolution.resolutionNumber}
              error={errors.resolutionNumber}
              required
            />
          </Col>
        </Row>
        <br />
        <Row className={'container-buttons'}>
          <Button
            disabled={!success}
            loading={loadingLimit || loading}
            onClick={() => this.validate()}
          >
            Crear
          </Button>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  limit: selectLimit(state),
  loadingLimit: loadingSelector([types.GET_RESOLUTIONS_LIMIT])(state),
})
const mapDispatchToProps = dispatch => ({
  getLimit: (docType, series) => dispatch(getResolutionsLimit(docType, series)),
})
export default connect(mapStateToProps, mapDispatchToProps)(CreateResolutions)
