import ContractsServices from '../services/contracts.services'
import { executeAction } from './global.actions'
import { getCompaniesByType } from './company.actions'

export const actionTypes = {
  GET_CONTRACTED_COMPANIES: 'GET_CONTRACTED_COMPANIES',
  GET_CONTRACTED_BY_COMPANIES: 'GET_CONTRACTED_BY_COMPANIES',
  TOGGLE_PRODUCER_SHIPPER_CONTRACT: 'TOGGLE_PRODUCER_SHIPPER_CONTRACT',

  GET_PRODUCTS_CONTRACT_BY_PRODUCER: 'GET_PRODUCTS_CONTRACT_BY_PRODUCER',
}

export const getProductsByProducerContract = producer => dispatch => {
  const process = async () => ({
    response: await ContractsServices.getProductsByProducerContract(producer),
  })
  dispatch(executeAction(actionTypes.GET_PRODUCTS_CONTRACT_BY_PRODUCER, process))
}

export const getContractedCompanies = contractTypeId => dispatch => {
  const process = async () => ({
    response: await ContractsServices.getContractedCompanies(contractTypeId),
  })
  dispatch(executeAction(actionTypes.GET_CONTRACTED_COMPANIES, process))
}

export const getContractedByCompanies = contractTypeId => dispatch => {
  const process = async () => ({
    response: await ContractsServices.getContractedByCompanies(contractTypeId),
  })
  dispatch(executeAction(actionTypes.GET_CONTRACTED_COMPANIES, process))
}

export const toggleProducerShipperContract = shipperId => dispatch => {
  const process = async () => {
    const response = await ContractsServices.toggleProducerShipperContract(shipperId)
    dispatch(getCompaniesByType(false, true, false, 10))
    return { response }
  }
  dispatch(executeAction(actionTypes.TOGGLE_PRODUCER_SHIPPER_CONTRACT, process))
}
