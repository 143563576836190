import { $http } from './http'

class PlansService {
  getPlans = async params => {
    const response = await $http.get(`/plans`, { params })
    return response.data
  }

  getActivePlan = async () => {
    const response = await $http.get(`/plans/active`)
    return response.data
  }

  getCompanyActivePlan = async companyId => {
    const response = await $http.get(`/plans/active/company/${companyId}`)
    return response.data
  }

  updatePlan = async (id, request) => {
    const response = await $http.put(`/plans/${id}`, request)
    return response.data
  }

  updateCompanyPlan = async (id, companyId, request) => {
    const response = await $http.put(`/plans/${id}/company/${companyId}`, request)
    return response.data
  }

  addUser = async request => {
    const response = await $http.put('/plans/user', request)
    return response.data
  }

  addCompanyUser = async (companyId, request) => {
    const response = await $http.put(`/plans/user/${companyId}`, request)
    return response.data
  }

  deleteUser = async userId => {
    const response = await $http.delete(`/plans/${userId}`)
    return response.data
  }

  deleteCompanyUser = async (companyId, userId) => {
    const response = await $http.delete(`/plans/${companyId}/${userId}`)
    return response.data
  }

  getUserPlan = async id => {
    const response = await $http.get(`/plans/user/${id}`)
    return response.data
  }

  assignDLC = async id => {
    const response = await $http.put(`/plans/modules/${id}`)
    return response.data
  }

  deleteDLC = async id => {
    const response = await $http.delete(`/plans/modules/${id}`)
    return response.data
  }

  getHistory = async () => {
    const response = await $http.get(`/plans/history`)
    return response.data
  }

  buyUserSpace = async request => {
    const response = await $http.put('/plans/user_space', request)
    return response.data
  }

  buyCompanyUserSpace = async (companyId, request) => {
    const response = await $http.put(`/plans/company/user_space/${companyId}`, request)
    return response.data
  }

  removeUserSpace = async () => {
    const response = await $http.delete('/plans/user_space')
    return response.data
  }

  removeCompanyUserSpace = async companyId => {
    const response = await $http.delete(`/plans/company/user_space/${companyId}`)
    return response.data
  }

  planPendingPayment = async () => {
    const response = await $http.get(`/plans/pending/payment`)
    return response.data
  }
}

export default new PlansService()
