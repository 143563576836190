import React from 'react'

import { Col, Row } from 'react-bootstrap'

import DetailAddon from './DetailAddon'

interface Props {
  addons: MCompanyAddons[]
  demo?: boolean
  renewal?: boolean
  edit?: boolean
  loadingValidate?: boolean
  onChange: (addons: MCompanyAddons[]) => void
}

/** Component that renders a list of addons
 * @component
 * @param {MCompanyAddons[]} Addons list
 * @param {boolean} demo Indicate if the company is a DEMO account
 * @param {boolean} renewal Indicate if the company is in renewal process
 * @param {boolean} edit Indicate if can possibly edit quantity and amount values of addons
 * @param {boolean} loadingValidate Indicate if loading animation
 * @param {Function} onChange Update a list addons
 * */
const DetailAddons = ({
  addons,
  demo = false,
  renewal = false,
  edit = false,
  loadingValidate = false,
  onChange,
}: Props) => {
  return (
    <div>
      <Row>
        {addons
          .filter(a => !a.deleted)
          .map((addon: MCompanyAddons, key: number) => (
            <Col key={key} xl={12}>
              <DetailAddon
                addon={addon}
                demo={demo}
                renewal={renewal}
                separate={addons.length !== key + 1}
                disabled={loadingValidate}
                onDelete={() => {
                  const customAddons: MCompanyAddons[] = Object.assign([], addons)
                  const aKey = customAddons.findIndex(ca => ca.id === addon.id)
                  const customAddon: MCompanyAddons = customAddons[aKey]

                  customAddons[aKey] = { ...customAddon, deleted: true }
                  onChange(customAddons)
                }}
                onUpdate={(editAddon: MCompanyAddons) => {
                  const customAddons: MCompanyAddons[] = Object.assign([], addons)
                  const aKey = customAddons.findIndex(ca => ca.id === addon.id)
                  const customAddon: MCompanyAddons = customAddons[aKey]

                  customAddon.quantity = editAddon.quantity
                  customAddon.price = editAddon.amount

                  customAddons[aKey] = customAddon
                  onChange(customAddons)
                }}
                edit={edit}
              />
            </Col>
          ))}
      </Row>
    </div>
  )
}
export default DetailAddons
