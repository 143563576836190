import React, { Component } from 'react'

import { Button as BSButton, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export default class Button extends Component {
  getIcon = () => {
    const { icon, loading, spinColor, iconColor, iStyle, img, width, height, dataCy } =
      this.props
    return (
      <>
        {loading ? (
          <FontAwesomeIcon
            data-testid={`${dataCy}-loading`}
            style={iStyle}
            icon={faSpinner}
            color={spinColor}
            spin
          />
        ) : icon ? (
          <FontAwesomeIcon style={iStyle} icon={icon} color={iconColor || 'white'} />
        ) : img ? (
          <img src={img} width={width || 25} height={height || 25} alt={'img-button'} />
        ) : (
          ''
        )}
      </>
    )
  }

  render() {
    const {
      loading,
      disabled,
      children,
      onClick,
      className,
      variant,
      right,
      style,
      color,
      size,
      tooltip,
      placement,
      id,
      dataCy,
    } = this.props
    const buttonDisabled = disabled || loading

    const button = (
      <BSButton
        data-cy={dataCy}
        data-testid={dataCy}
        size={size}
        style={style}
        onClick={onClick}
        className={`${className} ${color ? 'color-'.concat(color) : 'color-primary'}`}
        disabled={buttonDisabled}
        variant={variant}
        id={id}>
        {!right && <>{this.getIcon()} </>}
        {children}
        {right && <> {this.getIcon()}</>}
      </BSButton>
    )

    return tooltip ? (
      <OverlayTrigger
        style={{ 'data-html': 'true' }}
        placement={placement ? placement : 'top'}
        overlay={tooltip ? <Tooltip id="tooltip"> {tooltip} </Tooltip> : <div />}>
        {button}
      </OverlayTrigger>
    ) : (
      button
    )
  }
}
