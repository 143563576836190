import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import { setMenu } from 'src/actions/dashboard.actions'

import { selectSetCurrentCompany } from 'src/selectors/company.selector'

import {
  getCurrentUserRolesAction,
  getSubmodules,
  setModuleId,
} from 'src/actions/modules.actions'
import { selectSubModules } from 'src/selectors/modules.selector'

import Dashboard from '../Dashboard/Dashboard'
import Permissions from '../Permissions/Dashboard/Permissions'
import UpdatePermissions from '../Permissions/UpdatePermissions/UpdatePermissions'
import FactoryIcon from 'src/assets/images/svg/factory.svg'

import ProductsList from '../Products/Items'
import ProductDetails from '../Products/ProductDetails'
import Transport from '../Products/Transport/Transport'
import TransportPayment from '../Products/Transport/TransportPayment/TransportPayment'
import TransportationPaymentDetail from '../Products/Transport/TransportationPaymentDetail/TransportationPaymentDetail'
import PublicCategorization from '../Products/Categorization/Categorization'

import Clients from '../Clients/Clients'
import EditClient from '../Clients/Edit/EditClient'
import CreateClient from '../Clients/Create/CreateClientIndex'
import ClientsMap from '../Clients/Map/ClientsMap'
import ClientOrderHistory from '../Clients/ClientOrderHistory/ClientOrderHistory'
import CPCDashboard from '../Clients/CPC/CPCDashboard'
import MapPolygon from '../Clients/MapPolygon/MapPolygon'

import { saveCurrentModule } from 'src/actions/user.actions'

import WareHouse from '../Warehouse/WarehouseV2'
import CreateWarehouse from '../Warehouse/CreateWarehouse/CreateWarehouse'

import EditPolygon from '../Polygons/NewPolygon/NewPolygon2'
import NewPolygon from '../Polygons/NewPolygon/NewPolygon2'
import Polygons from '../Polygons/Polygons'

import CSVLoader from '../CSVLoader/CSVLoader'

import SaleDetail from '../Orders/OrderDetail/OrdersDetails'

import GranularPermission from '../Permissions/Granular/GranularPermission'
import Profile from '../Client/Profile/Profile'

import BalanceEntries from '../Balance/BalanceEntries'
import BalanceDisbursements from '../Balance/BalanceDisbursements'
import ClientPayments from '../Clients/CPC/Payment/Payments'
import Payments from '../Distrbution/Payments/Payments'
import Deposits from '../Deposits/Deposits'
import Routes from '../Distrbution/Route/Route'
import KoloProfile from '../Company/Profile/Kolo'
class KoloRoutes extends Component {
  componentDidMount() {
    this.props.setModuleId({ id: 15000, title: 'KOLO' })
    this.props.getSubmodules(15000)
    this.props.saveCurrentModule()
    this.props.rolesAction()
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { submodules, setMenu, match, company } = this.props
    if (submodules !== next.submodules) {
      let menu = [
        {
          id: 15000,
          text: 'Menú KOLO',
          icon: FactoryIcon,
          link: `${match.url}/`,
        },
      ]
      next.submodules.forEach(subModule => {
        menu.push({
          id: subModule.id,
          text: subModule.name,
          icon: subModule.icon,
          link: subModule.route,
          show: subModule.showIcon,
        })
      })
      setMenu(menu)
    }
    this.props.setModuleId({
      id: 15000,
      title: `KOLO - ${company.name || ''}`,
    })
  }

  render() {
    const { match } = this.props
    return (
      <Switch>
        <Route
          path={`${match.url}/productos/detalles/:id/pagos`}
          component={TransportPayment}
        />
        <Route
          path={`${match.url}/productos/transporte/detalle/:paymentId`}
          component={TransportationPaymentDetail}
        />
        <Route
          path={`${match.url}/productos/transporte/:id/detalle/:paymentId`}
          component={TransportationPaymentDetail}
        />
        <Route
          path={`${match.url}/productos/transporte/:id/pagos/:paymentId`}
          component={TransportPayment}
        />
        <Route
          path={`${match.url}/productos/transporte/:id/pagos`}
          component={TransportPayment}
        />
        <Route path={`${match.url}/productos/transporte/:id`} component={Transport} />

        <Route
          path={`${match.url}/productos/categorizaciones`}
          component={PublicCategorization}
        />
        <Route path={`${match.url}/productos/detalles/:id`} component={ProductDetails} />
        <Route path={`${match.url}/productos`} component={ProductsList} />
        <Route path={`${match.url}/bodegas/crear`} component={CreateWarehouse} />
        <Route path={`${match.url}/bodegas/editar/:id`} component={CreateWarehouse} />
        <Route path={`${match.url}/bodegas`} component={WareHouse} />

        <Route
          path={`${match.url}/clientes/cpc/:id/orden/detalle/:id`}
          component={SaleDetail}
        />
        <Route path={`${match.url}/clientes/cpc/:id`} component={CPCDashboard} />

        <Route path={`${match.url}/clientes/editar/:client`} component={EditClient} />
        <Route path={`${match.url}/clientes/nuevo`} component={CreateClient} />
        <Route
          path={`${match.url}/clientes/historial/:client`}
          component={ClientOrderHistory}
        />

        <Route
          path={`${match.url}/clientes/mapa/poligono/admin/editar/:polygon`}
          component={EditPolygon}
        />
        <Route
          path={`${match.url}/clientes/mapa/poligono/admin/nuevo`}
          component={NewPolygon}
        />
        <Route path={`${match.url}/clientes/mapa/poligono/admin`} component={Polygons} />
        <Route
          path={`${match.url}/clientes/mapa/poligono/historial/:client`}
          component={ClientOrderHistory}
        />
        <Route
          path={`${match.url}/clientes/mapa/poligono/editar/:client`}
          component={EditClient}
        />
        <Route path={`${match.url}/clientes/mapa/poligono`} component={MapPolygon} />
        <Route path={`${match.url}/clientes/mapa`} component={ClientsMap} />

        <Route path={`${match.url}/clientes/perfil/:id`} component={Profile} />
        <Route path={`${match.url}/clientes/pagos/:client`} component={ClientPayments} />
        <Route path={`${match.url}/clientes/pagos`} component={ClientPayments} />
        <Route path={`${match.url}/clientes`} component={Clients} />

        <Route
          path={`${match.url}/permisos/:module/:user/:name`}
          component={UpdatePermissions}
        />
        <Route
          path={`${match.url}/permisos/:module/matriz`}
          component={GranularPermission}
        />
        <Route path={`${match.url}/permisos/:module`} component={Permissions} />

        <Route path={`${match.url}/csv`} component={CSVLoader} />

        <Route path={`${match.url}/desembolsos`} component={BalanceDisbursements} />

        <Route path={`${match.url}/balance`} component={BalanceEntries} />

        <Route path={`${match.url}/pagos/boletas/nueva`} component={Payments} />
        <Route path={`${match.url}/pagos/boletas`} component={Deposits} />
        {/*<Route path={`${match.url}/pagos`} component={Payments}/>*/}
        <Route path={`${match.url}/pagos/:type`} component={Deposits} />

        <Route path={`${match.url}/ordenes/:linked/detalle/:id`} component={SaleDetail} />

        <Route path={`${match.url}/rutas`} component={Routes} />

        <Route path={`${match.url}/perfil`} component={KoloProfile} />

        <Route path={``} component={Dashboard} />
      </Switch>
    )
  }
}

const mapStateToProps = state => ({
  submodules: selectSubModules(state),
  company: selectSetCurrentCompany(state),
})

const mapDispatchToProps = dispatch => ({
  setMenu: routes => dispatch(setMenu(routes)),
  setModuleId: module => dispatch(setModuleId(module)),
  getSubmodules: module => dispatch(getSubmodules(module)),
  saveCurrentModule: () => dispatch(saveCurrentModule(15000)),
  rolesAction: () => dispatch(getCurrentUserRolesAction(15000)),
})

export default connect(mapStateToProps, mapDispatchToProps)(KoloRoutes)
