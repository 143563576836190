import { getMeasurementSymbol } from 'src/utils/utilities'

export const segmentationReportTypeEnum = {
  BY_SELLS: { id: 1, name: 'Venta por ordenes de venta' },
  BY_CLIENTS: { id: 2, name: 'Ventas a clientes' },
  BY_ITEMS: { id: 3, name: 'Por productos vendidos' },
  BY_CPC: { id: 4, name: 'Por cuentas por cobrar' },
  BY_PAYMENTS: { id: 5, name: 'Pagos de cuentas por cobrar' },
  BY_INVOICED_ITEMS: { id: 6, name: 'Por ítems facturados' },
}

export const segmentationReportTypes: IReportSegmentationType[] = [
  {
    value: segmentationReportTypeEnum.BY_SELLS.id,
    label: segmentationReportTypeEnum.BY_SELLS.name,
    headers: [
      { show: true, label: 'CÓDIGO', type: 'text', value: ['code'] },
      { show: true, label: 'NOMBRE', type: 'text', value: ['clientName'] },
      { show: true, label: 'NIT', type: 'text', value: ['nit'] },
      { show: true, label: 'FECHA EMISIÓN', type: 'date', value: ['firstDate'] },
      { show: true, label: 'UTILIDAD BRUTA', type: 'currency', value: ['thirdValue'] },
      { show: true, label: 'DESCUENTO', type: 'currency', value: ['secondValue'] },
      { show: true, label: 'TOTAL', type: 'currency', value: ['firstValue'] },
    ],
  },
  {
    value: segmentationReportTypeEnum.BY_CLIENTS.id,
    label: segmentationReportTypeEnum.BY_CLIENTS.name,
    headers: [
      { show: true, label: 'CÓDIGO', type: 'text', value: ['code'] },
      { show: true, label: 'NOMBRE', type: 'text', value: ['name'] },
      { show: true, label: 'NIT', type: 'text', value: ['nit'] },
      { show: true, label: 'TOTAL', type: 'currency', value: ['firstValue'] },
    ],
  },
  {
    value: segmentationReportTypeEnum.BY_ITEMS.id,
    label: segmentationReportTypeEnum.BY_ITEMS.name,
    headers: [
      { show: true, label: 'CÓDIGO', type: 'text', value: ['code'] },
      { show: true, label: 'NOMBRE', type: 'text', value: ['name'] },
      {
        show: true,
        label: 'MEDIDA',
        type: 'text',
        value: ['measurementValue'],
        custom: item =>
          `${item.measurementValue} ${getMeasurementSymbol(item.measurementUnitId)}`,
      },
      { show: true, label: 'CANTIDAD', type: 'number', value: ['secondValue'] },
      { show: true, label: 'PRECIO PROMEDIO', type: 'currency', value: ['thirdValue'] },
      { show: true, label: 'ÚLTIMA VENTA', type: 'date', value: ['firstDate'] },
      {
        show: true,
        label: 'ÚLTIMO PRECIO DE VENTA',
        type: 'currency',
        value: ['fourthValue'],
      },
      { show: true, label: 'TOTAL', type: 'currency', value: ['firstValue'] },
    ],
  },
  {
    value: segmentationReportTypeEnum.BY_CPC.id,
    label: segmentationReportTypeEnum.BY_CPC.name,
    headers: [
      { show: true, label: 'CÓDIGO', type: 'text', value: ['code'] },
      { show: true, label: 'NOMBRE', type: 'text', value: ['clientName'] },
      { show: true, label: 'TOTAL', type: 'currency', value: ['fourthValue'] },
      { show: true, label: 'FECHA EMISIÓN', type: 'date', value: ['firstDate'] },
      { show: true, label: 'DÍAS DE CRÉDITO', type: 'number', value: ['secondValue'] },
      { show: true, label: 'FECHA DE VENCIMIENTO', type: 'date', value: ['secondDate'] },
      {
        show: true,
        label: 'DÍAS DE CRÉDITO DE VENCIMIENTO',
        type: 'number',
        value: ['thirdValue'],
      },
      { show: true, label: 'PENDIENTE', type: 'currency', value: ['firstValue'] },
    ],
  },
  {
    value: segmentationReportTypeEnum.BY_PAYMENTS.id,
    label: segmentationReportTypeEnum.BY_PAYMENTS.name,
    headers: [
      { show: true, label: 'CÓDIGO', type: 'text', value: ['code'] },
      { show: true, label: 'ESTADO', type: 'text', value: ['stateName'] },
      { show: true, label: 'NOMBRE', type: 'text', value: ['clientName'] },
      { show: true, label: 'NIT', type: 'text', value: ['nit'] },
      { show: true, label: 'FECHA EMISIÓN', type: 'date', value: ['firstDate'] },
      { show: true, label: 'TOTAL', type: 'currency', value: ['secondValue'] },
      { show: true, label: 'REFERENCIA', type: 'text', value: ['reference'] },
      { show: true, label: 'MONTO', type: 'currency', value: ['firstValue'] },
    ],
  },
  {
    value: segmentationReportTypeEnum.BY_INVOICED_ITEMS.id,
    label: segmentationReportTypeEnum.BY_INVOICED_ITEMS.name,
    headers: [
      { show: true, label: 'CÓDIGO', type: 'text', value: ['code'] },
      { show: true, label: 'FECHA EMISIÓN', type: 'date', value: ['firstDate'] },
      { show: true, label: 'AUTORIZACIÓN', type: 'text', value: ['reference'] },
      { show: true, label: 'ANULADA', type: 'text', value: ['stateName'] },
      { show: true, label: 'ÍTEM', type: 'text', value: ['name'] },
      { show: true, label: 'CANTIDAD', type: 'number', value: ['secondValue'] },
      { show: true, label: 'PRECIO', type: 'currency', value: ['thirdValue'] },
      { show: true, label: 'TOTAL', type: 'currency', value: ['firstValue'] },
    ],
  },
]
