import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Alert from 'sweetalert-react'
import { Modal, Row, Col } from 'react-bootstrap'
import {
  Paragraph,
  CustomTabsV2,
  Select,
  FormText,
  TableV2,
  Checkbox,
  Button,
  NumberField,
} from 'src/components'
import { faIcons, faMoneyBill, faStickyNote } from '@fortawesome/free-solid-svg-icons'

import { getBalanceInCompany } from 'src/actions/balance.actions'
import { selectBalanceInCompany } from 'src/selectors/balance.selector'

import {
  actionTypes as typeU,
  getUsersChildrenByModule,
} from 'src/actions/modules.actions'
import { selectUsers } from 'src/selectors/modules.selector'

import { actionTypes, onCreateSimplePurchase } from 'src/actions/purchase.actions'
import { selectCurrentUser } from 'src/selectors/user.selector'

import { getSingleProvider } from 'src/actions/producer.actions'

import { getMovementMatchDeposit } from 'src/actions/banks.actions'
import { selectMovementByDeposit } from 'src/selectors/banks.selector'

import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import { loadingSelector } from 'src/selectors/loading.selector'

import { formatNumberWithCommas } from 'src/utils/formatters'
import { getNumber } from 'src/utils/utilities'

import CreditNoteCreate from '../CreditNote/purchase/CreditNoteCreate'
import Operation from 'src/components/Banks/Operation'

const ProvidersAdvances = ({
  title,
  show,
  id,
  name,
  onClose,
  nit,
  defaultUser = undefined,
  canSelectMySelf = false,
  canSelectAllUsers = false,
  paymentCash,
  paymentBank,
  paymentCN = false,
  paymentProvider = false,
  max = undefined,
  dollar = false,
  skipPayments = false,
  getPayments,
}) => {
  const dispatch = useDispatch()

  const users = useSelector(selectUsers)
  const currentUser = useSelector(selectCurrentUser)
  const singleProvider = useSelector(state => state.producer.singleProvider)

  const balance = useSelector(selectBalanceInCompany)
  const movements = useSelector(selectMovementByDeposit)

  const loadingGetUsers = useSelector(state =>
    loadingSelector([typeU.GET_USERS_CHILDREN])(state),
  )

  const createLoading = useSelector(state =>
    loadingSelector([actionTypes.CREATE_SIMPLE])(state),
  )
  const createError = useSelector(state => hasErrors([actionTypes.CREATE_SIMPLE])(state))

  const [flags, setFlags] = useState({ create: false, getUsers: false })
  const [alert, setAlert] = useState({ title: '' })

  const [userBalance, setUserBalance] = useState({
    value: null,
    label: 'Sin seleccionar',
    balance: 0,
  })
  const [movementSelected, setMovementSelected] = useState([])
  const [creditNote, setCreditNote] = useState({})
  const [providerBalance, setProviderBalance] = useState(0)
  const [key, setKey] = useState(0)

  const headers = [
    {
      show: true,
      label: '',
      value: [],
      select: true,
      checked: true,
      custom: item => <Checkbox checked={isMovementSelected(item)} />,
      className: 'mini',
    },
    {
      show: true,
      label: 'Fecha',
      value: ['registredAt'],
      type: 'date',
      className: 'mini',
    },
    {
      show: true,
      label: 'Cuenta',
      value: ['accountLabel'],
      type: 'text',
      className: 'medium',
    },
    {
      show: true,
      label: 'Documento',
      value: ['reference'],
      type: 'text',
      className: 'mini',
    },
    {
      show: true,
      label: 'Descripción',
      value: ['description'],
      type: 'text',
      className: 'medium',
    },
    {
      show: true,
      label: 'Monto',
      value: ['amount'],
      type: 'currency',
      className: 'mini',
    },
  ]

  useEffect(() => {
    if (!show) return
    setKey(0)
    setMovementSelected([])
    setCreditNote({})
    setProviderBalance(0)

    dispatch(getUsersChildrenByModule(1000, true))
    dispatch(getMovementMatchDeposit(0, 6, dollar ? 2 : 1, 100000000000))
    if (paymentProvider) dispatch(getSingleProvider(id))
  }, [show])

  useEffect(() => {
    if (createLoading && show) setFlags({ ...flags, create: true })
    else if (flags.create) {
      setFlags({ ...flags, create: false })
      if (createError)
        setAlert({
          ...handlerError(createError.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Adelanto realizado satisfactoriamente'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            onHide(true)
          },
        })
    }
  }, [createLoading])

  useEffect(() => {
    if (loadingGetUsers && show) setFlags({ ...flags, getUsers: true })
    else if (flags.getUsers) {
      setFlags({ ...flags, getUsers: false })

      let userDefaultSelected

      if (defaultUser) userDefaultSelected = users.find(u => u.id === defaultUser)
      if (!userDefaultSelected)
        userDefaultSelected = {
          value: currentUser.id,
          label: currentUser.name,
          balance: 0,
        }

      setUserBalance(userDefaultSelected)
      dispatch(getBalanceInCompany(userDefaultSelected.value))
    }
  }, [loadingGetUsers])

  const onHide = success => {
    setCreditNote({})
    onClose(success)
  }

  const getUsers = () => {
    if (canSelectAllUsers) return users
    else
      return users.filter(
        u =>
          (canSelectMySelf && u.id === currentUser.id) ||
          (defaultUser && u.id === defaultUser),
      )
  }

  const getUserAmount = () => {
    return Number(balance.balanceTransportist) - Number(userBalance.balance)
  }

  const isMovementSelected = ({ id }) => {
    return movementSelected.findIndex(m => m === id) >= 0
  }

  const movementAmount = movementSelected.reduce(
    (acc, m) => acc + Math.abs(movements.find(ms => ms.id === m)?.amount || 0),
    0,
  )

  const total = getNumber(
    parseFloat(
      (Number(userBalance.balance) || 0) + movementAmount + providerBalance,
    ).toFixed(2),
  )

  const pending = max ? getNumber(total > max ? 0 : max - total) : 0
  const exceeded = max ? getNumber(total > max ? Math.abs(max - total) : 0) : 0

  const onCreate = note => {
    const request = {
      providerId: id,
      total: note ? note.total : total,
      paymentType: 3,
      advances: true,
      accreditationRequest: { note },
      onlyCreditNote: note !== undefined && note.total > 0,
    }

    if (note === undefined) {
      if (userBalance.balance > 0)
        request.accreditationRequest.userBalances = {
          id: userBalance.value,
          amount: userBalance.balance,
        }

      if (movementAmount > 0) {
        const movBanks = []
        movementSelected.forEach(m => {
          const mov = movements.find(ms => ms.id === m)
          if (mov) {
            movBanks.push({
              id: mov.id,
              amount: Math.abs(mov.amount),
            })
          }
        })
        request.accreditationRequest.movBanks = movBanks
      }
      if (providerBalance > 0)
        request.accreditationRequest.providerBalance = providerBalance
      if (exceeded > 0) request.accreditationRequest.exceeded = exceeded
    }

    if (getPayments) {
      getPayments(request.accreditationRequest)
      onHide(false)
    } else dispatch(onCreateSimplePurchase(request))
  }

  const renderBalance = () => {
    return (
      <Row>
        <Col xl={12} lg={12} md={12} sm={12}>
          <Select
            label={'Usuarios'}
            options={getUsers()}
            value={userBalance}
            onChange={u => {
              setUserBalance({ ...u, balance: 0 })
              dispatch(getBalanceInCompany(u.id))
            }}
          />
        </Col>
        <Col xl={4} lg={4} md={6} sm={12}>
          <FormText
            label={'Efectivo disponible'}
            disabled
            value={formatNumberWithCommas(getUserAmount())}
            error={
              balance.balanceTransportist <= 0 &&
              'El usuario seleccionado no tiene efectivo disponible'
            }
          />
        </Col>
        <Col xl={8} lg={8} md={6} sm={12}>
          <FormText
            label={'Saldo a utilizar'}
            type={'number'}
            value={userBalance.balance}
            disabled={balance.balanceTransportist <= 0}
            changeValue={value => {
              const b = Math.abs(balance.balanceTransportist)
              if (value < 0) value = 0
              if (value > b) value = b
              setUserBalance({ ...userBalance, balance: value })
            }}
          />
        </Col>
      </Row>
    )
  }

  const renderProviderBalance = () => {
    return (
      <Row>
        <Col xl={4} lg={4} md={6} sm={12}>
          <NumberField
            label={'Saldo del proveedor'}
            disabled
            value={singleProvider?.balance || 0}
            error={
              singleProvider?.balance <= 0 && 'El proveedor no tiene saldo disponible'
            }
            onValueChange={() => undefined}
          />
        </Col>
        <Col xl={8} lg={8} md={6} sm={12}>
          <NumberField
            label={'Saldo a utilizar'}
            decimals={2}
            value={providerBalance}
            disabled={singleProvider?.balance <= 0}
            onValueChange={value => {
              if (!value || value < 0) value = 0
              else if (value > singleProvider?.balance) value = singleProvider?.balance
              setProviderBalance(value)
            }}
          />
        </Col>
      </Row>
    )
  }

  const renderBank = () => {
    return (
      <Row>
        <Col xl={12}>
          <Row className={'container-buttons'}>
            <Operation
              button
              onHide={() => undefined}
              refresh={() => dispatch(getMovementMatchDeposit(0, 6, 1, 100000000000))}
            />
          </Row>
        </Col>
        <Col xl={12}>
          <TableV2
            customClass={'scroll-x-without-height'}
            headers={headers}
            items={movements.sort((a, b) => b.registredAt - a.registredAt)}
            onClickTr={(e, item) => {
              if (isMovementSelected(item))
                setMovementSelected([...movementSelected.filter(m => m !== item.id)])
              else setMovementSelected([...movementSelected, item.id])
            }}
            getItems={item => {
              if (item !== null) {
                if (isMovementSelected(item))
                  setMovementSelected([...movementSelected.filter(m => m !== item.id)])
                else setMovementSelected([...movementSelected, item.id])
              } else {
                setMovementSelected([])
              }
            }}
            mobileAuto
            storageKey={`providerAdvances`}
            footerText={
              <div className={'column'}>
                <div className={'b-user-name'}>Monto seleccionado</div>
                <div className={'b-user-email'}>
                  {formatNumberWithCommas(movementAmount)}
                </div>
              </div>
            }
          />
        </Col>
      </Row>
    )
  }

  const renderCreditNote = () => {
    return (
      <CreditNoteCreate
        noModal
        onClose={() => setCreditNote({})}
        onCreate={note => onCreate(note)}
        loading={createLoading}
        hideProvider
        show
        initialValues={{
          providerId: id,
          name,
          nit,
          amount: null,
        }}
      />
    )
  }

  return (
    <div>
      <Modal show={show && !creditNote.show} size={'xl'} centered onHide={() => onHide()}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <Paragraph>
                Esta acción aumentará el saldo del proveedor y reducirá saldo de las
                cuentas seleccionadas
              </Paragraph>
            </Col>
            <Col xl={12} className={'mt-3'}>
              <CustomTabsV2
                onSelect={key => setKey(Number(key))}
                options={[
                  {
                    key: 0,
                    show: paymentCash,
                    label: 'Efectivo Circulante',
                    icon: faMoneyBill,
                    render: () => renderBalance(),
                  },
                  {
                    key: 1,
                    show: paymentBank,
                    label: 'Cuentas bancarias',
                    icon: faIcons,
                    render: () => renderBank(),
                  },
                  {
                    key: 2,
                    show: paymentProvider,
                    label: 'Saldo del proveedor',
                    icon: faIcons,
                    render: () => renderProviderBalance(),
                  },
                  {
                    key: 3,
                    show: paymentCN,
                    label: 'Nota de acreditación',
                    icon: faStickyNote,
                    render: () => renderCreditNote(),
                  },
                ]}
              />
            </Col>
            {key !== 3 && (
              <Col xl={12}>
                <Row>
                  <Col xl={12}>
                    <div style={{ width: '100%' }}>
                      <hr />
                      <div className={'space-between'}>
                        <Paragraph size={'big'}>Efectivo</Paragraph>
                        <Paragraph size={'medium'}>
                          {formatNumberWithCommas(userBalance.balance || 0)}
                        </Paragraph>
                      </div>
                      <div className={'space-between'}>
                        <Paragraph size={'big'}>Cuentas</Paragraph>
                        <Paragraph size={'medium'}>
                          {formatNumberWithCommas(movementAmount)}
                        </Paragraph>
                      </div>
                      {paymentProvider && (
                        <div className={'space-between'}>
                          <Paragraph size={'big'}>Proveedor</Paragraph>
                          <Paragraph size={'medium'}>
                            {formatNumberWithCommas(providerBalance)}
                          </Paragraph>
                        </div>
                      )}
                      <div>
                        <hr />
                      </div>
                      <div className={'space-between'}>
                        <Paragraph size={'big'}>Total</Paragraph>
                        <Paragraph size={'medium'}>
                          {formatNumberWithCommas(total)}
                        </Paragraph>
                      </div>
                      {max && (
                        <div className={'column'}>
                          <div className={'space-between'}>
                            <Paragraph size={'big'}>Pendiente</Paragraph>
                            <Paragraph size={'medium'}>
                              {formatNumberWithCommas(pending)}
                            </Paragraph>
                          </div>

                          {exceeded > 0 && (
                            <div className={'column'}>
                              <div className={'space-between'}>
                                <Paragraph size={'big'}>Excedente</Paragraph>
                                <Paragraph size={'medium'}>
                                  {formatNumberWithCommas(exceeded)}
                                </Paragraph>
                              </div>
                              <Paragraph dim>
                                El saldo excedente se agregará como saldo a favor para el
                                proveedor
                              </Paragraph>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Modal.Body>
        {key !== 3 && (
          <Modal.Footer>
            <Row className={'container-buttons'}>
              {skipPayments && (
                <Button color={'accent'} onClick={() => getPayments({ skip: true })}>
                  Cuenta por pagar
                </Button>
              )}
              <Button
                disabled={total <= 0}
                onClick={() => {
                  onCreate()
                }}
                loading={createLoading}>
                Abonar
              </Button>
            </Row>
          </Modal.Footer>
        )}
      </Modal>

      <Alert {...alert} />
    </div>
  )
}
export default ProvidersAdvances
