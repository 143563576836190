import React from 'react'

import { Col, Row } from 'react-bootstrap'
import { SwitchV2 } from 'src/components'

interface IRadioGroupProps {
  label: string
  items: IItemToGroup[]
  onChange: (response: IGroupResponse) => void
  disabled?: boolean
}

const RadioGroup = ({ label, items, onChange, disabled = false }: IRadioGroupProps) => {
  const size: number = items?.length <= 4 ? 12 / items.length : 4

  return (
    <Row>
      <Col xl={12}>
        <label className="ftf-form-label left">{label}</label>
      </Col>
      {items?.map((item: IItemToGroup) => (
        <Col key={item.id} xl={size} lg={size} md={6} sm={12} xs={12}>
          <SwitchV2
            label={item.label}
            info={item.info}
            id={item.id}
            checked={item.checked}
            onChange={value => onChange({ id: item.id, value })}
            disabled={disabled}
          />
        </Col>
      ))}
    </Row>
  )
}
export default RadioGroup
