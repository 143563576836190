import CategorizationsService from 'src/services/categorizations.service'
import { executeAction } from './global.actions'

import UploadService from 'src/services/uploads.services'
import { imageTypes } from 'src/components/gallery/Gallery'
import { getState } from 'src/utils/utilities'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'

export const actionTypes = {
  CREATE_CATEGORIZATION: 'CREATE_CATEGORIZATION',
  CREATE_CATEGORIZATION_REQUEST: 'CREATE_CATEGORIZATION_REQUEST',
  CREATE_CATEGORIZATION_SUCCESS: 'CREATE_CATEGORIZATION_SUCCESS',
  CREATE_CATEGORIZATION_ERROR: 'CREATE_CATEGORIZATION_ERROR',

  GET_CATEGORIZATION: 'GET_CATEGORIZATION',
  GET_CATEGORIZATION_REQUEST: 'GET_CATEGORIZATION_REQUEST',
  GET_CATEGORIZATION_SUCCESS: 'GET_CATEGORIZATION_SUCCESS',
  GET_CATEGORIZATION_ERROR: 'GET_CATEGORIZATION_ERROR',

  GET_CATEGORIZATIONS: 'GET_CATEGORIZATIONS',
  GET_CATEGORIZATIONS_REQUEST: 'GET_CATEGORIZATIONS_REQUEST',
  GET_CATEGORIZATIONS_SUCCESS: 'GET_CATEGORIZATIONS_SUCCESS',
  GET_CATEGORIZATIONS_ERROR: 'GET_CATEGORIZATIONS_ERROR',

  GET_PUBLIC_ROOTS: 'GET_PUBLIC_ROOTS',
  GET_PUBLIC_ROOTS_REQUEST: 'GET_PUBLIC_ROOTS_REQUEST',
  GET_PUBLIC_ROOTS_SUCCESS: 'GET_PUBLIC_ROOTS_SUCCESS',
  GET_PUBLIC_ROOTS_ERROR: 'GET_PUBLIC_ROOTS_ERROR',

  UPDATE_CATEGORIZATION: 'UPDATE_CATEGORIZATION',
  UPDATE_CATEGORIZATION_REQUEST: 'UPDATE_CATEGORIZATION_REQUEST',
  UPDATE_CATEGORIZATION_SUCCESS: 'UPDATE_CATEGORIZATION_SUCCESS',
  UPDATE_CATEGORIZATION_ERROR: 'UPDATE_CATEGORIZATION_ERROR',

  GET_CATEGORIZATIONS_BY_PARENT: 'GET_CATEGORIZATIONS_BY_PARENT',
  GET_CATEGORIZATIONS_BY_PARENT_REQUEST: 'GET_CATEGORIZATIONS_BY_PARENT_REQUEST',
  GET_CATEGORIZATIONS_BY_PARENT_ERROR: 'GET_CATEGORIZATIONS_BY_PARENT_ERROR',
  GET_CATEGORIZATIONS_BY_PARENT_SUCCESS: 'GET_CATEGORIZATIONS_BY_PARENT_SUCCESS',

  GET_CATEGORIZATIONS_BY_PRODUCT: 'GET_CATEGORIZATIONS_BY_PRODUCT',
  GET_CATEGORIZATIONS_BY_PRODUCT_REQUEST: 'GET_CATEGORIZATIONS_BY_PRODUCT_REQUEST',
  GET_CATEGORIZATIONS_BY_PRODUCT_ERROR: 'GET_CATEGORIZATIONS_BY_PRODUCT_ERROR',
  GET_CATEGORIZATIONS_BY_PRODUCT_SUCCESS: 'GET_CATEGORIZATIONS_BY_PRODUCT_SUCCESS',

  GET_ALL_ROOTS: 'GET_ALL_ROOTS',
  GET_ALL_ROOTS_REQUEST: 'GET_ALL_ROOTS_REQUEST',
  GET_ALL_ROOTS_ERROR: 'GET_ALL_ROOTS_ERROR',
  GET_ALL_ROOTS_SUCCESS: 'GET_ALL_ROOTS_SUCCESS',

  DELETE_CATEGORIZATION: 'DELETE_CATEGORIZATION',
  DELETE_CATEGORIZATION_REQUEST: 'DELETE_CATEGORIZATION_REQUEST',
  DELETE_CATEGORIZATION_SUCCESS: 'DELETE_CATEGORIZATION_SUCCESS',
  DELETE_CATEGORIZATION_ERROR: 'DELETE_CATEGORIZATION_ERROR',

  ADD_ENTITY_CATEGORIZATION: 'ADD_ENTITY_CATEGORIZATION',
  ADD_ENTITY_CATEGORIZATION_REQUEST: 'ADD_ENTITY_CATEGORIZATION_REQUEST',
  ADD_ENTITY_CATEGORIZATION_SUCCESS: 'ADD_ENTITY_CATEGORIZATION_SUCCESS',
  ADD_ENTITY_CATEGORIZATION_ERROR: 'ADD_ENTITY_CATEGORIZATION_ERROR',

  REMOVE_PRODUCT_CATEGORIZATION: 'REMOVE_PRODUCT_CATEGORIZATION',
  REMOVE_PRODUCT_CATEGORIZATION_SUCCESS: 'REMOVE_PRODUCT_CATEGORIZATION_SUCCESS',
  REMOVE_PRODUCT_CATEGORIZATION_REQUEST: 'REMOVE_PRODUCT_CATEGORIZATION_REQUEST',
  REMOVE_PRODUCT_CATEGORIZATION_ERROR: 'REMOVE_PRODUCT_CATEGORIZATION_ERROR',

  GET_TYPES: 'GET_TYPES',
  GET_TYPES_REQUEST: 'GET_TYPES_REQUEST',
  GET_TYPES_SUCCESS: 'GET_TYPES_SUCCESS',
  GET_TYPES_ERROR: 'GET_TYPES_ERROR',

  GET_CATEGORIZATION_PATH: 'GET_CATEGORIZATION_PATH',
  GET_CATEGORIZATION_PATH_REQUEST: 'GET_CATEGORIZATION_PATH_REQUEST',
  GET_CATEGORIZATION_PATH_SUCCESS: 'GET_CATEGORIZATION_PATH_SUCCESS',
  GET_CATEGORIZATION_PATH_ERROR: 'GET_CATEGORIZATION_PATH_ERROR',

  GET_ALL_CATEGORIZATION_PRODUCTS: 'GET_ALL_CATEGORIZATION_PRODUCTS',
  GET_ALL_CATEGORIZATION_PRODUCTS_REQUEST: 'GET_ALL_CATEGORIZATION_PRODUCTS_REQUEST',
  GET_ALL_CATEGORIZATION_PRODUCTS_SUCCESS: 'GET_ALL_CATEGORIZATION_PRODUCTS_SUCCESS',
  GET_ALL_CATEGORIZATION_PRODUCTS_ERROR: 'GET_ALL_CATEGORIZATION_PRODUCTS_ERROR',

  COPY_BY_TREE: 'COPY_BY_TREE',
  COPY_BY_TREE_SUCCESS: 'COPY_BY_TREE_SUCCESS',
  COPY_BY_TREE_REQUEST: 'COPY_BY_TREE_REQUEST',
  COPY_BY_TREE_ERROR: 'COPY_BY_TREE_ERROR',

  COPY_BY_LIST: 'COPY_BY_LIST',
  COPY_BY_LIST_REQUEST: 'COPY_BY_LIST_REQUEST',
  COPY_BY_LIST_SUCCESS: 'COPY_BY_LIST_SUCCESS',
  COPY_BY_LIST_ERROR: 'COPY_BY_LIST_ERROR',

  MOVE_BY_LIST: 'MOVE_BY_LIST',
  MOVE_BY_LIST_REQUEST: 'MOVE_BY_LIST_REQUEST',
  MOVE_BY_LIST_SUCCESS: 'MOVE_BY_LIST_SUCCESS',
  MOVE_BY_LIST_ERROR: 'MOVE_BY_LIST_ERROR',

  COPY_PRODUCTS: 'COPY_PRODUCTS',
  COPY_PRODUCTS_REQUEST: 'COPY_PRODUCTS_REQUEST',
  COPY_PRODUCTS_SUCCESS: 'COPY_PRODUCTS_SUCCESS',
  COPY_PRODUCTS_ERROR: 'COPY_PRODUCTS_ERROR',

  GET_PRODUCTS_BY_PUBLIC_ROOT: 'GET_PRODUCTS_BY_PUBLIC_ROOT',
  GET_PRODUCTS_BY_PUBLIC_ROOT_REQUEST: 'GET_PRODUCTS_BY_PUBLIC_ROOT_REQUEST',
  GET_PRODUCTS_BY_PUBLIC_ROOT_SUCCESS: 'GET_PRODUCTS_BY_PUBLIC_ROOT_SUCCESS',
  GET_PRODUCTS_BY_PUBLIC_ROOT_ERROR: 'GET_PRODUCTS_BY_PUBLIC_ROOT_ERROR',

  GET_PRODUCTS: 'GET_PRODUCTS',
  GET_ALL: 'GET_ALL',
  GET_ASSIGNED: 'GET_ASSIGNED',
  SET_LOCAL_CATEGORIES: 'SET_LOCAL_CATEGORIES',

  GET_CATEGORIZATION_BY_TYPE: 'GET_CATEGORIZATION_BY_TYPE',
  GET_VARIONS_BY_PRODUCT: 'GET_VARIONS_BY_PRODUCT',
  DELETE_VARIATION: 'DELETE_VARIATION',
}

const createCategorizationRequest = () => ({
  type: actionTypes.CREATE_CATEGORIZATION_REQUEST,
})
const createCategorizationSuccess = categorization => ({
  type: actionTypes.CREATE_CATEGORIZATION_SUCCESS,
  categorization,
})
const createCategorizationError = error => ({
  type: actionTypes.CREATE_CATEGORIZATION_ERROR,
  error,
})

const getCategorizationRequest = () => ({
  type: actionTypes.GET_CATEGORIZATION_REQUEST,
})
const getCategorizationSuccess = categorization => ({
  type: actionTypes.GET_CATEGORIZATION_SUCCESS,
  categorization,
})
const getCategorizationError = error => ({
  type: actionTypes.GET_CATEGORIZATION_ERROR,
  error,
})

const getCategorizationsRequest = () => ({
  type: actionTypes.GET_CATEGORIZATIONS_REQUEST,
})
const getCategorizationsSuccess = categorizations => ({
  type: actionTypes.GET_CATEGORIZATIONS_SUCCESS,
  categorizations,
})
const getCategorizationsError = error => ({
  type: actionTypes.GET_CATEGORIZATIONS_ERROR,
  error,
})

const getPublicRootsRequest = () => ({
  type: actionTypes.GET_PUBLIC_ROOTS_REQUEST,
})
const getPublicRootsSuccess = response => ({
  type: actionTypes.GET_PUBLIC_ROOTS_SUCCESS,
  response,
})
const getPublicRootsError = error => ({
  type: actionTypes.GET_PUBLIC_ROOTS_ERROR,
  error,
})

const updateCategorizationRequest = () => ({
  type: actionTypes.UPDATE_CATEGORIZATION_REQUEST,
})
const updateCategorizationSuccess = categorization => ({
  type: actionTypes.UPDATE_CATEGORIZATION_SUCCESS,
  categorization,
})
const updateCategorizationError = error => ({
  type: actionTypes.UPDATE_CATEGORIZATION_ERROR,
  error,
})

const getCategorizationsByParentIdRequest = () => ({
  type: actionTypes.GET_CATEGORIZATIONS_BY_PARENT_REQUEST,
})
const getCategorizationsByParentIdSuccess = categorizations => ({
  type: actionTypes.GET_CATEGORIZATIONS_BY_PARENT_SUCCESS,
  categorizations,
})
const getCategorizationsByParentIdError = error => ({
  type: actionTypes.GET_CATEGORIZATIONS_BY_PARENT_ERROR,
  error,
})

const getCategorizationsByProductRequest = () => ({
  type: actionTypes.GET_CATEGORIZATIONS_BY_PRODUCT_REQUEST,
})
const getCategorizationsByProductSuccess = categorizations => ({
  type: actionTypes.GET_CATEGORIZATIONS_BY_PRODUCT_SUCCESS,
  categorizations,
})
const getCategorizationsByProductError = error => ({
  type: actionTypes.GET_CATEGORIZATIONS_BY_PRODUCT_ERROR,
  error,
})

const getAllRootsRequest = () => ({ type: actionTypes.GET_ALL_ROOTS_REQUEST })
const getAllRootsSuccess = roots => ({
  type: actionTypes.GET_ALL_ROOTS_SUCCESS,
  roots,
})
const getAllRootsError = error => ({
  type: actionTypes.GET_ALL_ROOTS_ERROR,
  error,
})

const deleteCategorizationRequest = () => ({
  type: actionTypes.DELETE_CATEGORIZATION_REQUEST,
})
const deleteCategorizationSuccess = categorization => ({
  type: actionTypes.DELETE_CATEGORIZATION_SUCCESS,
  categorization,
})
const deleteCategorizationError = error => ({
  type: actionTypes.DELETE_CATEGORIZATION_ERROR,
  error,
})

const removeCategorizationToProductRequest = () => ({
  type: actionTypes.REMOVE_PRODUCT_CATEGORIZATION_REQUEST,
})
const removeCategorizationToProductSuccess = success => ({
  type: actionTypes.REMOVE_PRODUCT_CATEGORIZATION_SUCCESS,
  success,
})
const removeCategorizationToProductError = error => ({
  type: actionTypes.REMOVE_PRODUCT_CATEGORIZATION_ERROR,
  error,
})

const assignCategorizationToEntityRequest = () => ({
  type: actionTypes.ADD_ENTITY_CATEGORIZATION_REQUEST,
})
const assignCategorizationToEntitySuccess = success => ({
  type: actionTypes.ADD_ENTITY_CATEGORIZATION_SUCCESS,
  success,
})
const assignCategorizationToEntityError = error => ({
  type: actionTypes.ADD_ENTITY_CATEGORIZATION_ERROR,
  error,
})

const getTypesRequest = () => ({ type: actionTypes.GET_TYPES_REQUEST })
const getTypesSuccess = types => ({
  type: actionTypes.GET_TYPES_SUCCESS,
  types,
})
const getTypesError = error => ({ type: actionTypes.GET_TYPES_ERROR, error })

const getCategorizationPathRequest = () => ({
  type: actionTypes.GET_CATEGORIZATION_REQUEST,
})
const getCategorizationPathSuccess = path => ({
  type: actionTypes.GET_CATEGORIZATION_REQUEST,
  path,
})
const getCategorizationPathError = error => ({
  type: actionTypes.GET_CATEGORIZATION_REQUEST,
  error,
})

const getCategorizationProductsRequest = () => ({
  type: actionTypes.GET_ALL_CATEGORIZATION_PRODUCTS_REQUEST,
})
const getCategorizationProductsSuccess = products => ({
  type: actionTypes.GET_ALL_CATEGORIZATION_PRODUCTS_SUCCESS,
  products,
})

const getCategorizationProductsError = error => ({
  type: actionTypes.GET_ALL_CATEGORIZATION_PRODUCTS_ERROR,
  error,
})

const copyByTreeRequest = () => ({ type: actionTypes.COPY_BY_TREE_REQUEST })
const copyByTreeSuccess = response => ({
  type: actionTypes.COPY_BY_TREE_SUCCESS,
  response,
})
const copyByTreeError = error => ({
  type: actionTypes.COPY_BY_TREE_ERROR,
  error,
})

const copyByListRequest = () => ({ type: actionTypes.COPY_BY_LIST_REQUEST })
const copyByListSuccess = response => ({
  type: actionTypes.COPY_BY_LIST_SUCCESS,
  response,
})
const copyByListError = error => ({
  type: actionTypes.COPY_BY_LIST_ERROR,
  error,
})

const copyProductsRequest = () => ({ type: actionTypes.COPY_PRODUCTS_REQUEST })
const copyProductsSuccess = success => ({
  type: actionTypes.COPY_PRODUCTS_SUCCESS,
  success,
})
const copyProductsError = error => ({
  type: actionTypes.COPY_PRODUCTS_ERROR,
  error,
})

const moveByListRequest = () => ({ type: actionTypes.MOVE_BY_LIST_REQUEST })
const moveByListSuccess = response => ({
  type: actionTypes.MOVE_BY_LIST_SUCCESS,
  response,
})
const moveByListError = error => ({
  type: actionTypes.MOVE_BY_LIST_ERROR,
  error,
})

const getProductsByRootRequest = () => ({
  type: actionTypes.GET_PRODUCTS_BY_PUBLIC_ROOT_REQUEST,
})
const getProductsByRootSuccess = response => ({
  type: actionTypes.GET_PRODUCTS_BY_PUBLIC_ROOT_SUCCESS,
  response,
})
const getProductsByRootError = error => ({
  type: actionTypes.GET_PRODUCTS_BY_PUBLIC_ROOT_ERROR,
  error,
})

export const createCategorization =
  (categorization, images, banner, icon, companyId) => async dispatch => {
    //if allPaths.split('%2F')[0] == 'thumbs' || request.auth != null
    dispatch(createCategorizationRequest())
    try {
      const createdCategorization = await CategorizationsService.createCategorization(
        categorization,
        {
          companyId,
        },
      )
      if (images && images.length > 0) {
        await UploadService.addImages(
          imageTypes.CATEGORY.id,
          createdCategorization.id,
          images,
        )
      }
      if (banner && banner.length > 0) {
        await UploadService.addImages(
          imageTypes.CATEGORY_BANNER.id,
          createdCategorization.id,
          banner,
        )
      }
      if (icon && icon.length > 0) {
        await UploadService.addImages(
          imageTypes.CATEGORY_ICON.id,
          createdCategorization.id,
          icon,
        )
      }
      dispatch(createCategorizationSuccess(createdCategorization))
    } catch (error) {
      console.error('action', error)
      dispatch(createCategorizationError(error))
    }
  }

export const updateCategorization =
  (categorizationId, categorization, companyId) => async dispatch => {
    dispatch(updateCategorizationRequest())
    try {
      const createdCategorization = await CategorizationsService.updateCategorization(
        categorizationId,
        categorization,
        { companyId },
      )
      dispatch(updateCategorizationSuccess(createdCategorization))
    } catch (error) {
      console.error('action', error)
      dispatch(updateCategorizationError(error))
    }
  }

export const getCategorization = categorizationId => async dispatch => {
  dispatch(getCategorizationRequest())
  try {
    const categorization = await CategorizationsService.getCategorizationById(
      categorizationId,
    )
    dispatch(getCategorizationSuccess(categorization))
  } catch (error) {
    console.error('action', error)
    dispatch(getCategorizationError(error))
  }
}

export const getCategorizations = withRoots => async dispatch => {
  dispatch(getCategorizationsRequest())
  try {
    if (withRoots) {
      const categorizations =
        await CategorizationsService.getCategorizationsByCompanyWithRoots()
      dispatch(getCategorizationsSuccess(categorizations))
    } else {
      const categorizations = await CategorizationsService.getCategorizationsByCompany()
      dispatch(getCategorizationsSuccess(categorizations))
    }
  } catch (error) {
    console.error('action', error)
    dispatch(getCategorizationsError(error))
  }
}

export const getCategorizationsByParentId = parentCategorizationId => async dispatch => {
  dispatch(getCategorizationsByParentIdRequest())
  try {
    const categorizations =
      await CategorizationsService.getCategorizationsByCompanyAndParent(
        parentCategorizationId,
      )
    dispatch(getCategorizationsByParentIdSuccess(categorizations))
  } catch (error) {
    console.error('action', error)
    dispatch(getCategorizationsByParentIdError(error))
  }
}

export const deleteCategorization = categorizationId => async dispatch => {
  dispatch(deleteCategorizationRequest(categorizationId))
  try {
    const categorizations = await CategorizationsService.deleteCategorization(
      categorizationId,
    )
    dispatch(deleteCategorizationSuccess(categorizations))
    dispatch(getAllRootCategorizations())
  } catch (error) {
    console.error('action', error)
    dispatch(deleteCategorizationError(error))
  }
}

export const deleteListCategorization = (categorizations, parent) => async dispatch => {
  dispatch(deleteCategorizationRequest())
  let promises = []
  categorizations.map(async c => {
    promises.push(CategorizationsService.deleteCategorization(c))
  })
  return Promise.all(promises)
    .then(() =>
      dispatch(
        deleteCategorizationSuccess('success'),
        dispatch(getCategorization(parent)),
      ),
    )
    .catch(e => dispatch(deleteCategorizationError(e)))
}

export const deleteListCategorizationType = (categorizations, type) => async dispatch => {
  dispatch(deleteCategorizationRequest())
  let promises = []
  categorizations.map(async c => {
    promises.push(CategorizationsService.deleteCategorization(c))
  })
  return Promise.all(promises)
    .then(() =>
      dispatch(
        deleteCategorizationSuccess('success'),
        dispatch(getCategorizationByType(type)),
      ),
    )
    .catch(e => dispatch(deleteCategorizationError(e)))
}

export const getCategorizationsByProduct = productId => async dispatch => {
  dispatch(getCategorizationsByProductRequest())
  try {
    const categorizations = await CategorizationsService.getCategorizationsByProduct(
      productId,
    )
    dispatch(getCategorizationsByProductSuccess(categorizations))
  } catch (error) {
    console.error('action', error)
    dispatch(getCategorizationsByProductError(error))
  }
}

export const getAllRootCategorizations = () => async dispatch => {
  dispatch(getAllRootsRequest())
  try {
    const categorizations = await CategorizationsService.getRootOrganizationsByCompany()
    dispatch(getAllRootsSuccess(categorizations))
  } catch (error) {
    console.error('action', error)
    dispatch(getAllRootsError(error))
  }
}

export const assignCategorizationToEntity =
  (api, categorizationId, entityId, remove, log, type, companyId) => async dispatch => {
    dispatch(assignCategorizationToEntityRequest())
    try {
      let response
      if (api)
        response = await CategorizationsService.assignEntity(
          api,
          categorizationId,
          entityId,
          remove,
          log,
        )
      else
        response = await CategorizationsService.assignCustomEntity(
          categorizationId,
          entityId,
          remove,
          log,
          type,
          companyId,
        )
      dispatch(assignCategorizationToEntitySuccess(response))
    } catch (error) {
      console.error('action', error)
      dispatch(assignCategorizationToEntityError(error))
    }
  }

export const assignCategorizationToMultipleEntities =
  (api, categorizationId, list, remove, log, type) => async dispatch => {
    dispatch(assignCategorizationToEntityRequest())
    let promises = []
    list.map(async c => {
      if (api)
        promises.push(
          CategorizationsService.assignEntity(api, categorizationId, c, remove, log),
        )
      else
        promises.push(
          CategorizationsService.assignCustomEntity(
            categorizationId,
            c,
            remove,
            log,
            type,
          ),
        )
    })
    return Promise.all(promises)
      .then(() => dispatch(assignCategorizationToEntitySuccess('success')))
      .catch(e => dispatch(assignCategorizationToEntityError(e)))
  }

export const removeCategorizationToEntity =
  (api, categorizationId, productId) => async dispatch => {
    dispatch(removeCategorizationToProductRequest())
    try {
      const response = await CategorizationsService.removeEntity(
        api,
        categorizationId,
        productId,
      )
      dispatch(removeCategorizationToProductSuccess(response))
    } catch (error) {
      console.error('action', error)
      dispatch(removeCategorizationToProductError(error))
    }
  }

export const getCategorizationsByEntity = (api, entity, log) => dispatch => {
  const process = async () => ({
    response: await CategorizationsService.getCategorizationsByEntity(api, entity, log),
  })
  dispatch(executeAction(actionTypes.GET_ASSIGNED, process))
}

export const getCustomCategorizationsByEntity = (entity, log, type) => dispatch => {
  const process = async () => ({
    response: await CategorizationsService.getCustomCategorizationsByEntity(
      entity,
      log,
      type,
    ),
  })
  dispatch(executeAction(actionTypes.GET_ASSIGNED, process))
}

export const getAllTypes = () => async dispatch => {
  dispatch(getTypesRequest())
  try {
    const response = await CategorizationsService.getAllTypes()
    dispatch(getTypesSuccess(response))
  } catch (error) {
    console.error('action', error)
    dispatch(getTypesError(error))
  }
}

export const getCategorizationPath = id => async dispatch => {
  dispatch(getCategorizationPathRequest())
  try {
    const response = await CategorizationsService.getPath(id)
    dispatch(getCategorizationPathSuccess(response))
  } catch (error) {
    console.error('action', error)
    dispatch(getCategorizationPathError(error))
  }
}

export const getCategorizationProducts = type => async dispatch => {
  dispatch(getCategorizationProductsRequest())
  try {
    const response = await CategorizationsService.getCategorizationByType(type)
    dispatch(getCategorizationProductsSuccess(response))
  } catch (error) {
    console.error('action', error)
    dispatch(getCategorizationProductsError(error))
  }
}

export const getCategorizationByType = type => dispatch => {
  const process = async () => ({
    response: await CategorizationsService.getCategorizationByType(type),
  })
  dispatch(executeAction(actionTypes.GET_CATEGORIZATION_BY_TYPE, process))
}

export const copyByTree = (list, parent) => async dispatch => {
  dispatch(copyByTreeRequest())
  try {
    const response = await CategorizationsService.copyByTree(list, parent)
    dispatch(copyByTreeSuccess(response))
  } catch (error) {
    console.error('action', error)
    dispatch(copyByTreeError(error))
  }
}

export const copyByList = (list, parent) => async dispatch => {
  dispatch(copyByListRequest())
  try {
    const response = await CategorizationsService.copyByList(list, parent)
    dispatch(copyByListSuccess(response))
  } catch (error) {
    console.error('action', error)
    dispatch(copyByListError(error))
  }
}

export const moveByList = (list, parent) => async dispatch => {
  dispatch(moveByListRequest())
  try {
    const response = await CategorizationsService.moveByList(list, parent)
    dispatch(moveByListSuccess(response))
  } catch (error) {
    console.error('action', error)
    dispatch(moveByListError(error))
  }
}

export const getCategorizationWithProductsByRoot = () => async dispatch => {
  dispatch(getPublicRootsRequest())
  try {
    const categorizations = await CategorizationsService.getPublicRoots()
    dispatch(getPublicRootsSuccess(categorizations))
  } catch (error) {
    console.error('action', error)
    dispatch(getPublicRootsError(error))
  }
}

export const copyProductsByRoot = root => async dispatch => {
  dispatch(copyProductsRequest())
  try {
    const categorizations = await CategorizationsService.copyProductsByRoot(root)
    dispatch(copyProductsSuccess(categorizations))
  } catch (error) {
    console.error('action', error)
    dispatch(copyProductsError(error))
  }
}

export const getProductsByRoot = root => async dispatch => {
  dispatch(getProductsByRootRequest())
  try {
    const categorizations =
      await CategorizationsService.getCategorizationWithProductsByRoot(root)
    dispatch(getProductsByRootSuccess(categorizations))
  } catch (error) {
    console.error('action', error)
    dispatch(getProductsByRootError(error))
  }
}

export const getProductsByCategorization = id => dispatch => {
  const process = async () => ({
    response: await CategorizationsService.getProducts(id),
  })
  dispatch(executeAction(actionTypes.GET_PRODUCTS, process))
}

export const getAllCategorizations = (type, force) => dispatch => {
  const categorizations = getState(selectAllCategorizations)
  if (categorizations?.type === type && categorizations?.children?.length > 0 && !force)
    return

  const process = async () => ({
    response: await CategorizationsService.getAllCategorizations(type),
  })
  dispatch(executeAction(actionTypes.GET_ALL, process))
}

export const getAllPublicCategorizations = (api, params) => dispatch => {
  const process = async () => ({
    response: await CategorizationsService.getAllPublicCategorizations(api, params),
  })
  dispatch(executeAction(actionTypes.GET_ALL, process))
}

export const onSetLocalCategories = item => dispatch => {
  const process = async () => ({ response: item })
  dispatch(executeAction(actionTypes.SET_LOCAL_CATEGORIES, process))
}
