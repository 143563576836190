import React from 'react'
import { ModalTable } from '../../index'

const ManageAccountModal: React.FC<ManageRequestsModalModalProps> = ({
  show,
  onHide,
}) => {
  return (
    <ModalTable
      show={show}
      title={'Solicitudes de movimientos'}
      onHide={onHide}
      size={'lg'}
    />
  )
}

export default ManageAccountModal
