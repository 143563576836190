import React, { Component } from 'react'
import { connect } from 'react-redux'

import Card from '../../../components/cards/Card'

import { Button, Col, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-relative-link'

import {
  actionTypes as typeS,
  getSubscriptions,
  getSubscriptionsCompany,
  getSubscriptionsPolygons,
  getSubscriptionsPolygonsDistribution,
  getSubscriptionsShipper,
} from '../../../actions/subscriptions.actions'
import {
  selectCompaniesByClient,
  selectSetCurrentCompany,
} from '../../../selectors/company.selector'
import { loadingSelector } from '../../../selectors/loading.selector'
import {
  handlerError,
  hasErrorsSelector,
  singleErrorSelector,
} from '../../../selectors/error.selector'

import {
  selectSubscriptions,
  selectSubscriptionsCompany,
  selectSubscriptionsPolygons,
  selectSubscriptionsPolygonsDistribution,
  selectSubscriptionsShipper,
} from '../../../selectors/subscriptions.selector'
import CollapsibleCard from '../../../components/cards/collapsible-card/CollapsibleCard'
import {
  faPencilAlt,
  faTrash,
  faCheckDouble,
  faCheckSquare,
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'
import { formatDateFromMillis, formatNumberWithCommas } from '../../../utils/formatters'

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import { selectCurrentCompany } from '../../../selectors/company.selector'
import Money from '../../../components/Money/Money'
import { TableV2 } from '../../../components'

const intervals = [
  { value: 0, label: 'Diariamente', singular: 'día', plural: 'días' },
  { value: 1, label: 'Semanalmente', singular: 'semana', plural: 'semanas' },
  { value: 2, label: 'Mensualmente', singular: 'mes', plural: 'meses' },
  { value: 3, label: 'Anualmente', singular: 'año', plural: 'años' },
]

class SubscriptionsOffer extends Component {
  state = {
    alert: { title: 'default' },

    subscription: false,
  }

  componentDidMount() {
    this.props.getSubscriptionsShipper()

    // this.props.getSubscriptions();
    // this.props.getSubscriptionsCompany();
    this.props.getSubscriptionsPolygonsDistribution()
  }

  componentWillReceiveProps(next) {
    const { loadingS, hasErrorS, loadingSH, hasErrorSH } = this.props
    if (loadingS && !next.loadingS) {
      if (!hasErrorS && !next.hasErrorS) {
        console.log('SUSCRIPTIONS::::::', this.props.subscriptions)
      } else {
        this.setState({ subscriptionsError: true })
      }
    }
    if (loadingSH && !next.loadingSH) {
      if (!hasErrorSH && !next.hasErrorSH) {
        console.log('SUSCRIPTIONS SHIPPER::::::', this.props.subscriptionsShipper)
      } else {
        this.setState({ subscriptionsError: true })
      }
    }
  }

  goTo = (item, route) => {
    const { history, match } = this.props
    const url = `${match.url}${route}/${item.id}`
    history.push(url, { polygonId: item.id })
  }

  getHeaders = () => {
    return [
      {
        label: 'Productor',
        show: true,
        value: ['subscription', 'companyName'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Suscripción',
        show: true,
        value: ['subscription', 'title'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Poligono',
        show: true,
        value: ['polygon', 'name'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Cajillas',
        show: true,
        value: ['subscription', 'drawers'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Repetir',
        show: true,
        value: ['repetirC'],
        type: 'text',
        className: 'mini',
        custom: item =>
          (item.repetirC =
            'Cada ' +
            item.subscription.step +
            ' ' +
            (item.subscription.step == 1
              ? intervals[item.subscription.intervalType].singular
              : intervals[item.subscription.intervalType].plural)),
      },
      {
        label: 'Entregar',
        show: true,
        value: ['entregarC'],
        type: 'text',
        className: 'mini',
        custom: item =>
          (item.entregarC =
            item.subscription.times + (item.subscription.times > 1 ? ' veces' : 'vez')),
      },
      {
        label: 'Precio',
        show: true,
        value: ['price'],
        type: 'currency',
        className: 'mini',
      },
      {
        config: true,
        show: true,
        label: '',
        className: 'mini center',
        custom: item => (
          <Row>
            <Col>
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="tooltip">Detalle</Tooltip>}>
                <Button
                  size={'sm'}
                  variant={'outline-secondary'}
                  onClick={() => this.goTo(item, '/detail')}>
                  <FontAwesomeIcon className={'name'} icon={faInfoCircle} />
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>
        ),
      },
    ]
  }

  getHeadersClient = () => {
    return [
      {
        label: 'Cliente',
        show: true,
        value: ['cotizapClient', 'companyName'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Suscripción',
        show: true,
        value: ['subscription', 'title'],
        type: 'text',
        className: 'mini',
      },
      {
        label: 'Fecha solicitud',
        show: true,
        value: ['createdAt'],
        type: 'date',
        className: 'mini',
      },
      {
        label: 'Próxima entrega',
        show: true,
        value: ['nextDate'],
        type: 'date',
        className: 'mini',
      },
      {
        label: 'Metodo de Pago',
        show: true,
        value: ['metodoPago'],
        type: 'text',
        className: 'mini',
        custom: item =>
          (item.metodoPago = item.paymentMethod == 1 ? 'Deposito' : 'Efectivo'),
      },
      {
        label: 'Estado',
        show: true,
        value: ['estadoC'],
        type: 'text',
        className: 'mini',
        custom: item => (item.estadoC = item.status ? 'En Curso' : 'En espera'),
      },
      {
        config: true,
        show: true,
        label: '',
        className: 'mini center',
        custom: item => (
          <Row>
            <Col>
              {item.status ? (
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip id="tooltip">Solicitud ya aceptada</Tooltip>}>
                  <FontAwesomeIcon
                    className={'name'}
                    style={{ color: 'green' }}
                    icon={faCheckDouble}
                  />
                </OverlayTrigger>
              ) : (
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip id="tooltip">Aceptar</Tooltip>}>
                  <Button
                    disabled={this.props.loadingAS}
                    size={'sm'}
                    variant={'danger'}
                    onClick={() => {
                      this.setState({ currentSubscription: item, showConfirmation: true })
                    }}>
                    {this.props.loadingAS && (
                      <Spinner
                        style={{ marginRight: 10, marginTop: 2 }}
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    <FontAwesomeIcon className={'name'} icon={faCheckSquare} />
                  </Button>
                </OverlayTrigger>
              )}
            </Col>
          </Row>
        ),
      },
    ]
  }

  render() {
    const {
      loadingS,
      subscriptions,
      subscriptionsCompany,
      loadingSC,
      subscriptionsPolygonsDistribution,
      currentCompany,
      subscriptionShipper,
    } = this.props
    return (
      <div>
        <h1 className={'dashboard-welcome'}>Agrupaciones de Productos</h1>
        <Row>
          <Col>
            <CollapsibleCard header={{ backgroundColor: '#1b4060' }} title={'Aceptadas'}>
              <TableV2
                headers={this.getHeaders()}
                items={subscriptionsPolygonsDistribution.filter(
                  s => s.shipperId == currentCompany.id,
                )}
                loading={loadingS}
                mobileAuto
                storageKey={`subscriptionsOffer`}
              />
            </CollapsibleCard>
          </Col>
        </Row>

        <Row>
          <Col>
            <CollapsibleCard header={{ backgroundColor: '#1b4060' }} title={'Clientes'}>
              <TableV2
                headers={this.getHeadersClient()}
                items={subscriptionShipper.filter(item => item.status)}
                loading={loadingSC}
                mobileAuto
                storageKey={`subscriptionsOfferClient`}
              />
            </CollapsibleCard>
          </Col>
        </Row>

        {/*<Row>*/}
        {/*    <Col>*/}
        {/*        <CollapsibleCard header={{backgroundColor: '#1b4060'}} title={'Pendientes'}>*/}
        {/*            <PaginatedTable*/}
        {/*                loading={loadingS}*/}
        {/*                headers={['Suscripción', 'Poligono', 'Cajillas', 'Repetir', 'Entregar', 'Precio', 'Acciones']}*/}
        {/*                renderRow={this.renderRow}*/}
        {/*                items={subscriptionsPolygons.filter((s) => !s.shipperId)}*/}
        {/*            />*/}
        {/*        </CollapsibleCard>*/}
        {/*    </Col>*/}
        {/*</Row>*/}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  subscriptions: selectSubscriptions(state),
  // subscriptionsCompany: selectSubscriptionsCompany(state),

  subscriptionsPolygonsDistribution: selectSubscriptionsPolygonsDistribution(state),

  loadingS: loadingSelector([typeS.GET_SUBSCRIPTIONS_POLYGONS_DISTRIBUTION])(state),
  hasErrorS: hasErrorsSelector([typeS.GET_SUBSCRIPTIONS_POLYGONS_DISTRIBUTION])(state),
  errorS: singleErrorSelector([typeS.GET_SUBSCRIPTIONS_POLYGONS_DISTRIBUTION])(state),

  subscriptionShipper: selectSubscriptionsShipper(state),

  loadingSH: loadingSelector([typeS.GET_SUBSCRIPTIONS_SHIPPER])(state),
  hasErrorSH: hasErrorsSelector([typeS.GET_SUBSCRIPTIONS_SHIPPER])(state),
  errorSH: singleErrorSelector([typeS.GET_SUBSCRIPTIONS_SHIPPER])(state),

  // loadingSC: loadingSelector([typeS.GET_SUBSCRIPTIONS_COMPANY])(state),
  // hasErrorSC: hasErrorsSelector([typeS.GET_SUBSCRIPTIONS_COMPANY])(state),
  // errorSC: singleErrorSelector([typeS.GET_SUBSCRIPTIONS_COMPANY])(state),

  currentCompany: selectSetCurrentCompany(state),

  handlerError: message => handlerError(message),
})
const mapDispatchToProps = dispatch => ({
  // getSubscriptions: () => dispatch(getSubscriptions()),
  // getSubscriptionsCompany: () => dispatch(getSubscriptionsCompany()),
  getSubscriptionsPolygonsDistribution: () =>
    dispatch(getSubscriptionsPolygonsDistribution()),
  getSubscriptionsShipper: () => dispatch(getSubscriptionsShipper()),
})
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionsOffer)
