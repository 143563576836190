import { $http } from './http'
import { request } from 'websocket'

class TransactionsServices {
  onCreateTransactionFlag = async (request: ITransactionFlag) => {
    const response = await $http.post('/transactions', request)
    return response.data
  }
}
export default new TransactionsServices()
