import './RecipeDetail.scss'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Alert from 'sweetalert-react'
import { Media } from 'react-breakpoints'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'
import { Modal, Row, Col, ProgressBar } from 'react-bootstrap'
import {
  FormText,
  CustomFilter,
  Button,
  Card,
  Icon,
  Select,
  Switch,
  TableV2,
  NumberField,
  Empty,
  CustomTabs,
  Money,
} from 'src/components'
import {
  faArrowDown,
  faArrowLeft,
  faArrowCircleRight as faArrowRight,
  faArrowUp,
  faBox,
  faExclamationCircle,
  faPlus,
  faSave,
  faShoppingBag,
  faTimes,
  faTrash,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes as productActionTypes,
  getProductsSimple,
} from 'src/actions/products.actions'
import { selectProductsSimple } from 'src/selectors/products.selector'

import {
  types,
  createEnumeration,
  updateEnumeration,
  getEnumerationReport,
  onGetEnumeration,
  deleteEnumeration,
  getVariablesRecipe,
} from 'src/actions/enumeration.actions'
import {
  selectEnumerationReport,
  selectEnumerationVariablesRecipe,
  selectRecipe,
} from 'src/selectors/enumeration.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  hasErrorsSelector,
  singleErrorSelector,
  handlerSuccess,
  handlerError,
} from 'src/selectors/error.selector'

import { isAllowed } from 'src/selectors/modules.selector'

const RecipeDetailV2 = ({
  isRecipe,
  title,
  show,
  recipeId,
  onHide,
  productId,
  type,
  productName,
  decimals,
  notVariations = false,
}) => {
  const dispatch = useDispatch()

  const variables = useSelector(selectEnumerationVariablesRecipe)

  const products = useSelector(selectProductsSimple)
  const loading = useSelector(state =>
    loadingSelector([productActionTypes.GET_ALL_PRODUCTS_SIMPLE])(state),
  )
  const report = useSelector(selectEnumerationReport)

  const loadingC = useSelector(state =>
    loadingSelector([types.ON_CREATE_OR_UPDATE])(state),
  )
  const hasErrorC = useSelector(state =>
    hasErrorsSelector([types.ON_CREATE_OR_UPDATE])(state),
  )
  const errorC = useSelector(state =>
    singleErrorSelector([types.ON_CREATE_OR_UPDATE])(state),
  )

  const enumeration = useSelector(selectRecipe)
  const loadingG = useSelector(state =>
    loadingSelector([types.ON_GET_ENUMERATION])(state),
  )
  const hasErrorG = useSelector(state =>
    hasErrorsSelector([types.ON_GET_ENUMERATION])(state),
  )
  const errorG = useSelector(state =>
    singleErrorSelector([types.ON_GET_ENUMERATION])(state),
  )

  const loadingD = useSelector(state => loadingSelector([types.ON_DELETE])(state))
  const hasErrorD = useSelector(state => hasErrorsSelector([types.ON_DELETE])(state))
  const errorD = useSelector(state => singleErrorSelector([types.ON_DELETE])(state))

  const [actions, setActions] = useState({})
  const [modal, setModal] = useState(false)
  const [errors, setErrors] = useState({})
  const [recipe, setRecipe] = useState({
    to: [],
    from: [],
    optional: [],
    finalQuantity: 1,
  })

  const [search, setSearch] = useState(null)
  const [isCreate, setCreate] = useState(false)
  const [mobile, setModalMobile] = useState(false)
  const [get, setGet] = useState(false)
  const [alert, setAlert] = useState({ title: '' })
  const [selected, setSelected] = useState({ label: 'Sin seleccionar', value: null })
  const [step, setStep] = useState(1)

  const { to, from, name, description, optional, finalQuantity } = recipe

  const variableTypes = [
    { value: 1, label: 'Personalizado' },
    { value: 2, label: 'Pre configurado' },
  ]

  const canDelete = useSelector(state => isAllowed(state, [16353, 16303]))

  useEffect(() => {
    if (!show) return
    setModal(true)
    if (products && products.length === 0) dispatch(getProductsSimple())
    if (!recipeId) {
      setCreate(true)
      if (productId) {
        let t = [],
          f = []
        if (type === 2) t = [{ quantity: 1, productId: productId }]
        else if (type === 3) f = [{ quantity: 1, productId: productId }]
        setRecipe({ to: t, from: f, optional: [], finalQuantity: 1 })
      }
      //setRecipe({to: (productId && !recipeId) ? [{quantity: 1, productId: productId}] : [], from: []});
    } else {
      setCreate(true)
      dispatch(getEnumerationReport(productId))
      dispatch(onGetEnumeration(recipeId))
    }

    dispatch(getVariablesRecipe())
  }, [show])

  useEffect(() => {
    if (loadingC) setActions({ ...actions, create: true })
    else if (actions.create) {
      setActions({ ...actions, create: false })
      if (hasErrorC) onAlert(true, errorC.message)
      else
        onAlert(false, recipeId ? 'Receta Actualizada' : 'Receta Creada', () => {
          hide(true)
        })
    }
  }, [loadingC])

  useEffect(() => {
    if (loadingG) setActions({ ...actions, get: true })
    else if (actions.get) {
      setActions({ ...actions, get: false })
      if (hasErrorG) onAlert(true, errorG.message)
      else setGet(true)
    }
  }, [loadingG])

  useEffect(() => {
    if (!get) return
    let fq = 1

    enumeration.enumerationDetails
      .filter(i => i.detailType === 0)
      .map(i => {
        if (i.productId === productId) fq = i.quantity
        return i
      })

    const toRecipe = {
      name: enumeration.name,
      description: enumeration.description,
      to: enumeration.enumerationDetails
        .filter(i => i.detailType === 0)
        .map(i => ({ ...i, name: i.productName, code: i.productCode })),
      from: enumeration.enumerationDetails
        .filter(i => i.detailType === 1)
        .map(i => ({ ...i, name: i.productName, code: i.productCode })),
      optional: enumeration.items.map(d => ({
        ...d,
        type: variableTypes[d.type - 1],
        variable: d.variable ? { value: d.variable, label: d.name } : null,
        products: d.products.map(p => ({
          ...p,
          value: p.productId,
          label: p.productName,
        })),
      })),
      finalQuantity: fq,
    }

    setRecipe(toRecipe)
    if (enumeration.type === 3) {
      const s = enumeration.enumerationDetails.find(e => e.selected)
      setSelected({ label: s.productName, value: s.productId })
    }
    setGet(false)
  }, [get])

  useEffect(() => {
    if (loadingD) setActions({ ...actions, delete: true })
    else if (actions.delete) {
      setActions({ ...actions, delete: false })
      if (hasErrorD) onAlert(true, errorD.message)
      else onAlert(false, 'Receta Eliminada', () => hide(true))
    }
  }, [loadingD])

  useEffect(() => {
    if (!selected.value) return
    if (!to.find(p => p.productId === selected.value)) onDropTo(null, selected.value, '1')
  }, [selected])

  const onAlert = (error, message, action, another) => {
    const init = another
      ? { ...another, text: message }
      : error
      ? { ...handlerError(message) }
      : { ...handlerSuccess(message) }
    setAlert({
      ...init,
      onConfirm: () => {
        setAlert({ ...alert, show: false })
        if (action) action()
      },
    })
  }

  const hide = update => {
    setModal(false)
    setRecipe({ to: [], from: [], optional: [] })
    setGet(false)
    setCreate(false)
    setSelected({ label: 'Sin seleccionar', value: null })
    onHide(update)
  }

  const filterList = item => {
    let i
    if (productId) i = from.find(f => f.productId === item.productId)

    return !i && item.productId !== productId
  }

  const getInformation = () => (
    <div className={'column'}>
      <h5>Conversión de producto para la venta</h5>
      <Row style={{ paddingBottom: 2, display: 'flex' }}>
        <h6 style={{ marginTop: 10 }}>
          Esta funcionalidad tiene como objetivo poder realizar ventas del producto:{' '}
        </h6>
        <Select
          mt={0}
          withoutLabel
          style={{ maxWidth: 150, marginLeft: 10, marginRight: 10 }}
          value={selected}
          onChange={s => setSelected(s)}
          error={selected.error}
          disabled={disabled()}
          options={Object.assign(
            [],
            products
              .filter(p => p.productId !== productId)
              .map(p => ({ ...p, label: p.productName, value: p.productId })),
          )}
        />
        <h6 style={{ marginTop: 10 }}> desde el producto: {productName}</h6>
      </Row>
    </div>
  )

  const renderReport = () => (
    <>
      <TableV2
        hideFilter
        items={report}
        renderRow={(item, index) => (
          <div key={index} className={'b-user-item'}>
            <div className={'justify-content-start align-items-start bu-en left'}>
              <div className={`b-user-name`}>{item.productName}</div>
              <Money className={`b-user-email`}>
                Último precio de compra o producción
                {item.purchasePrice}
              </Money>
            </div>
            <div className={'d-flex row align-items-center mr-2 bu-b right'}>
              <div className={`b-user-balance mr-2`}>
                {`${item.added}`}{' '}
                <Icon icon={faArrowUp} color={'green'} tooltip={'Cantidad Agregada'} />{' '}
              </div>
              <div className={`b-user-balance mr-2`}>
                {`${item.removed}`}{' '}
                <Icon icon={faArrowDown} color={'red'} tooltip={'Cantidad removida'} />{' '}
              </div>
            </div>
          </div>
        )}
      />
    </>
  )

  const renderRecipe = () => (
    <Row>
      {loading && (
        <Col xl={12} lg={12} md={12}>
          <ProgressBar now={100} animated />
        </Col>
      )}
      {type === 3 && (
        <Col xl={12} md={12} sm={12}>
          {getInformation()}
        </Col>
      )}
      <Col xl={8} lg={8} md={8} sm={12}>
        <FormText
          disabled={disabled()}
          label={'Descripción'}
          value={description}
          error={errors.description}
          onChange={({ target }) => setRecipe({ ...recipe, description: target.value })}
        />
      </Col>

      <Col xl={4} lg={4} md={4} sm={12}>
        <NumberField
          labelStyle={{ marginTop: 15 }}
          disabled={disabled()}
          label={'Rendimiento'}
          value={finalQuantity}
          error={errors.finalQuantity}
          required
          type={'number'}
          onValueChange={q => {
            if (!q) q = 0
            else if (q < 0) q = 0

            setRecipe({ ...recipe, finalQuantity: q })
          }}
          decimals={decimals}
        />
      </Col>

      <Col xl={12} lg={12} md={12} sm={12}>
        <Media>
          {({ breakpoints, currentBreakpoint }) => {
            const tablet = breakpoints[currentBreakpoint] > breakpoints.tablet
            return breakpoints[currentBreakpoint] >= breakpoints.mini ? (
              <Row>
                {isRecipe ? (
                  <Col>
                    <CustomTabs
                      items={[
                        {
                          title: 'Componentes',
                          component: (
                            <>
                              <Row>
                                {tablet && isCreate && (
                                  <Col xl={5} lg={5} md={5}>
                                    {itemsToSelect(true)}
                                  </Col>
                                )}
                                {!isCreate && recipeId && (
                                  <Col xl={5} lg={5} md={tablet ? 5 : 12} sm={12}>
                                    <Card title={'Reporte'} white>
                                      {renderReport()}
                                    </Card>
                                  </Col>
                                )}
                                <Col
                                  xl={tablet ? 7 : 12}
                                  lg={tablet ? 7 : 12}
                                  md={tablet ? 7 : 12}>
                                  <Card title={'Se compone de'} white>
                                    <div className={'column'}>
                                      {errors.from && (
                                        <div className="left input-error">
                                          <FontAwesomeIcon icon={faExclamationCircle} />{' '}
                                          {errors.from}
                                        </div>
                                      )}

                                      <ul
                                        className={'main-list'}
                                        onDrop={onDropFrom}
                                        onDragOver={event => event.preventDefault()}>
                                        {tablet && isCreate && from.length === 0 && (
                                          <Empty
                                            hideButton
                                            title={'Sin elementos'}
                                            subtitle={
                                              'Arrastre los elementos obligatorios aquí'
                                            }
                                          />
                                        )}

                                        {from.map((item, index) =>
                                          renderProducts(item, index, 2, true),
                                        )}
                                      </ul>
                                    </div>
                                  </Card>
                                </Col>
                              </Row>
                              {!tablet && isCreate && (
                                <Col xl={12} lg={12} md={12}>
                                  <Row
                                    className={'container-buttons'}
                                    style={{ paddingBottom: 2, display: 'flex' }}>
                                    <Button
                                      disabled={disabled()}
                                      style={{ flex: 1 }}
                                      icon={faPlus}
                                      right
                                      color={'primary'}
                                      onClick={() => setModalMobile(true)}>
                                      Agregar Productos
                                    </Button>
                                  </Row>
                                </Col>
                              )}
                            </>
                          ),
                        },
                        {
                          show: !notVariations,
                          title: 'Variaciones y modificaciones',
                          component: renderConfigurations(),
                        },
                        {
                          title: 'Costos',
                          component: renderReport(),
                          show: recipeId !== null && recipeId !== undefined,
                        },
                      ]}
                    />
                  </Col>
                ) : (
                  <>
                    {tablet && isCreate && (
                      <Col xl={5} lg={5} md={5}>
                        {itemsToSelect(true)}
                      </Col>
                    )}

                    {!isCreate && recipeId && (
                      <Col xl={5} lg={5} md={tablet ? 5 : 12} sm={12}>
                        <Card title={'Reporte'} white>
                          {renderReport()}
                        </Card>
                      </Col>
                    )}

                    {type === 3 && (
                      <Col xl={tablet ? 7 : 12} lg={tablet ? 7 : 12} md={tablet ? 7 : 12}>
                        <Card title={'A convertir'} white>
                          <div className={'column'}>
                            {errors.from && (
                              <div className="left input-error">
                                <FontAwesomeIcon icon={faExclamationCircle} /> {errors.to}
                              </div>
                            )}
                            <ul
                              className={'main-list'}
                              onDrop={onDropTo}
                              onDragOver={event => event.preventDefault()}>
                              {tablet && isCreate && to.length === 0 && (
                                <Empty
                                  hideButton
                                  title={'Sin elementos'}
                                  subtitle={'Arrastre los productos'}
                                />
                              )}
                              {to.map((item, index) =>
                                renderProducts(item, index, 1, false),
                              )}
                            </ul>
                          </div>
                        </Card>
                      </Col>
                    )}

                    {type === 2 && (
                      <Col xl={tablet ? 7 : 12} lg={tablet ? 7 : 12} md={tablet ? 7 : 12}>
                        <Card title={'Se compone de'} white>
                          <div className={'column'}>
                            {errors.from && (
                              <div className="left input-error">
                                <FontAwesomeIcon icon={faExclamationCircle} />{' '}
                                {errors.from}
                              </div>
                            )}
                            <ul
                              className={'main-list'}
                              onDrop={onDropFrom}
                              onDragOver={event => event.preventDefault()}>
                              {tablet && isCreate && from.length === 0 && (
                                <Empty
                                  hideButton
                                  title={'Sin elementos'}
                                  subtitle={'Arrastre los elementos obligatorios aquí'}
                                />
                              )}
                              {from.map((item, index) =>
                                renderProducts(item, index, 2, true),
                              )}
                            </ul>
                          </div>
                        </Card>
                      </Col>
                    )}

                    {!tablet && isCreate && (
                      <Col xl={12} lg={12} md={12}>
                        <Row
                          className={'container-buttons'}
                          style={{ paddingBottom: 2, display: 'flex' }}>
                          {' '}
                          <Button
                            disabled={disabled()}
                            style={{ flex: 1 }}
                            icon={faPlus}
                            right
                            color={'primary'}
                            onClick={() => setModalMobile(true)}>
                            Agregar Productos
                          </Button>
                        </Row>
                      </Col>
                    )}
                  </>
                )}
              </Row>
            ) : (
              <div />
            )
          }}
        </Media>
      </Col>
    </Row>
  )

  const renderConfigurations = () => {
    return (
      <Col>
        <hr />
        <Row>
          {optional.map((item, index) => renderOptionals(item, index))}
          {isCreate && optional.length === 0 ? (
            <Col>
              <Empty
                title={'No se han creado elementos adicionales'}
                subtitle={' '}
                subtitleSecond={' '}
                buttonTitle={'Añadir elementos'}
                onClick={() => {
                  optional.push({
                    required: false,
                    products: [],
                    multiple: false,
                    min: 0,
                    max: 1,
                    type: { value: 1, label: 'Personalizado' },
                  })
                  setRecipe({ ...recipe, optional })
                }}
              />
            </Col>
          ) : (
            <Col xs={12} md={6}>
              <div
                style={{
                  height: '223px',
                  cursor: 'pointer',
                  border: '1px solid #12A8BB',
                }}
                className={'d-flex justify-content-center w-100'}
                onClick={() => {
                  optional.push({
                    required: false,
                    products: [],
                    multiple: false,
                    min: 0,
                    max: 1,
                    type: { value: 1, label: 'Personalizado' },
                  })
                  setRecipe({ ...recipe, optional })
                }}>
                {' '}
                <Icon
                  style={{ alignSelf: 'center' }}
                  color={'accent'}
                  size={'3x'}
                  icon={faPlus}
                />
              </div>
            </Col>
          )}
        </Row>
      </Col>
    )
  }

  const disableOptionals = () => {
    return (
      optional.filter(d => {
        /*d.products.length === 0 ||
          (d.multiple && d.min > d.max) ||
          d.products.filter(s => s.price === null || s.price === undefined).length > 0*/
        if (!d.type) return true
        if (d.type.value === 1) {
          return (
            d.products.length === 0 ||
            (d.multiple && d.min > d.max) ||
            d.products.filter(s => s.price === null || s.price === undefined).length > 0
          )
        } else {
          return !d.variable || !d.variable.value
        }
      }).length > 0
    )
  }

  const renderOptionals = (item, index) => {
    return (
      <Col sm={12} md={6}>
        <Card
          titleStyle={{ width: '70%' }}
          title={'Variación'}
          accent
          button={
            <Icon
              size={'1_5x'}
              disabled={disabled()}
              style={{ alignSelf: 'center' }}
              icon={faTimes}
              onClick={() => {
                optional.splice(index, index + 1)
                setRecipe({ ...recipe, optional })
              }}
            />
          }>
          <Row>
            <Col xl={12}>
              <Select
                label={'Tipo de variación'}
                options={variableTypes}
                value={item.type}
                onChange={t => {
                  optional[index].type = t
                  setRecipe({ ...recipe, optional })
                }}
              />
            </Col>

            {item?.type?.value === 1 ? (
              <Col xl={12}>
                <Row>
                  <Col xl={12}>
                    <FormText
                      label={'Nombre'}
                      mt={1}
                      type={'text'}
                      name={'name'}
                      min={0}
                      value={item.name}
                      placeholder={'Nombre'}
                      required
                      disabled={disabled()}
                      onChange={({ target }) => {
                        optional[index].name = target.value
                        setRecipe({ ...recipe, optional })
                      }}
                    />
                  </Col>
                  <Col xl={6} md={6} sm={12} xs={12}>
                    <Switch
                      id={`required`}
                      name={`required`}
                      checked={item.required}
                      disabled={disabled()}
                      info={
                        'Marca como obligatoria la selección de este elemento al seleccionar el producto'
                      }
                      label={'Obligatorio'}
                      topLabel
                      onChange={() => {
                        optional[index].required = !optional[index].required
                        setRecipe({ ...recipe, optional: [...optional] })
                      }}
                    />
                  </Col>
                  <Col xl={6} md={6} sm={12} xs={12}>
                    <Switch
                      id={`multiple`}
                      name={`multiple`}
                      checked={item.multiple}
                      disabled={disabled()}
                      info={
                        'Se configura el elemento para poder seleccionar 2 o más productos'
                      }
                      label={'Varias selecciones'}
                      topLabel
                      onChange={() => {
                        optional[index].multiple = !optional[index].multiple
                        setRecipe({ ...recipe, optional: [...optional] })
                      }}
                    />
                  </Col>

                  {item.multiple && (
                    <Col xl={!2}>
                      <Row>
                        <Col xl={12}>
                          <Switch
                            id={`unlimited`}
                            name={`unlimited`}
                            checked={item.unlimited}
                            disabled={disabled()}
                            info={
                              'Indica que dicha variación podrá seleccionarse con cualquier cantidad'
                            }
                            label={'Selección Ilimitada'}
                            topLabel
                            onChange={() => {
                              optional[index].unlimited = !optional[index].unlimited
                              optional[index].variablePrice = false
                              optional[index].min = 0
                              optional[index].max = 0
                              setRecipe({ ...recipe, optional: [...optional] })
                            }}
                          />
                        </Col>

                        {!item.unlimited && (
                          <Col xl={12}>
                            <Row>
                              <Col xl={6} md={6} sm={12} xs={12}>
                                <FormText
                                  mt={0}
                                  type={'number'}
                                  label={'Cantidad mínima'}
                                  name={'min'}
                                  min={0}
                                  value={item.min}
                                  placeholder={'0'}
                                  disabled={disabled()}
                                  onChange={({ target }) => {
                                    optional[index].min = target.value
                                    setRecipe({ ...recipe, optional })
                                  }}
                                />
                              </Col>
                              <Col xl={6} md={6} sm={12} xs={12}>
                                <FormText
                                  mt={0}
                                  type={'number'}
                                  label={'Cantidad máxima'}
                                  name={'max'}
                                  min={item.min}
                                  value={item.max}
                                  placeholder={'10'}
                                  disabled={disabled()}
                                  onChange={({ target }) => {
                                    optional[index].max = target.value
                                    setRecipe({ ...recipe, optional })
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  )}

                  <Col xl={12}>
                    <Row>
                      <Select
                        label={'Seleccionar producto'}
                        disabled={disabled()}
                        value={{ value: null, label: 'Selecciona un producto' }}
                        options={products.filter(
                          d => item.products.findIndex(s => s.value === d.value) === -1,
                        )}
                        onChange={value => {
                          optional[index].products.push({
                            ...value,
                            price: 0,
                            quantity: 1,
                          })
                          setRecipe({ ...recipe, optional })
                        }}
                        info={'Agrega los productos a la configuración'}
                      />
                    </Row>
                  </Col>

                  <Col xl={12}>
                    {item.products.map((d, ind) => (
                      <div key={ind}>
                        <Row>
                          <Col md={12}>
                            <FormText
                              prepend={<Icon icon={faBox} disabled={disabled()} />}
                              disabled
                              value={d.label}
                              append={
                                <Icon
                                  color={'red'}
                                  icon={faTimes}
                                  disabled={disabled()}
                                  onClick={() => {
                                    optional[index].products.splice(ind, ind + 1)
                                    setRecipe({ ...recipe, optional })
                                  }}
                                />
                              }
                            />
                          </Col>
                          <Col md={6}>
                            <NumberField
                              placeholder={'Cantidad'}
                              value={d.quantity}
                              disabled={disabled()}
                              decimals={d.decimals}
                              onValueChange={value => {
                                optional[index].products[ind].quantity = value
                                setRecipe({ ...recipe, optional })
                              }}
                            />
                          </Col>
                          <Col md={6}>
                            <NumberField
                              isMoney
                              placeholder={'Precio extra'}
                              value={d.price}
                              disabled={disabled()}
                              min={0}
                              onValueChange={value => {
                                optional[index].products[ind].price = value
                                setRecipe({ ...recipe, optional })
                              }}
                            />
                          </Col>
                        </Row>
                        <hr style={{ marginTop: '10px', marginBottom: '0px' }} />
                      </div>
                    ))}
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col xl={12}>
                <Row>
                  <Col xl={12}>
                    <Select
                      label={'Escoge una variación pre configurada.'}
                      options={variables}
                      value={item.variable}
                      onChange={v => {
                        optional[index].variable = v
                        setRecipe({ ...recipe, optional })
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Card>
      </Col>
    )
  }

  const renderProducts = (item, index, type, isFrom) => {
    return (
      <li
        key={index}
        id={`p-${item.productId}-${type}`}
        draggable={!disabled()}
        onDragStart={onDrag}>
        <div className={'b-item'}>
          <div className={'column'}>
            <div className={'b-user-name d-flex'}>
              <Icon
                icon={faTrash}
                tooltip={'Remover de la receta base'}
                size={'2x'}
                onClick={() => {
                  const parameter = isFrom ? 'from' : 'to'
                  const index = recipe[parameter].findIndex(
                    d => d.productId === item.productId,
                  )

                  if (index >= 0) {
                    setRecipe({
                      ...recipe,
                      [parameter]: recipe[parameter].filter(
                        i => i.productId !== item.productId,
                      ),
                    })
                    if (item.productId === selected.value)
                      setSelected({ value: null, label: 'Sin seleccionar' })
                  }
                }}
              />
              <div className={'column ml-3'}>
                <div className={'b-user-name'}>{item.name}</div>
                <div className={'b-user-email'}> {item.code}</div>
              </div>
            </div>
            <div className={'d-flex'}>
              <div style={{ flex: 1 }} className={''}>
                <FormText
                  disabled={disabled()}
                  prependMoneySymbol
                  // style={{flex: 1, marginLeft: 5}} igStyle={{margin: 0, padding: 0}} mt={0}
                  type={'number'}
                  placeholder={'P. extra'}
                  name={'extra'}
                  value={item.extraPrice}
                  onChange={event => onChangeExtra(event, item, isFrom)}
                  error={item.error}
                />
              </div>

              <div style={{ flex: 1 }} className={''}>
                <NumberField
                  value={item.quantity || 0}
                  onValueChange={event => onChangeQuantity(event, item, isFrom)}
                  disabled={disabled()}
                  prepend={<Icon icon={faShoppingBag} tooltip={'Cantidad'} />}
                  style={{ marginTop: 15 }}
                  // igStyle={{margin: 0, padding: 0}} mt={0}
                  placeholder={'Cantidad de unidades'}
                  name={'quantity'}
                  decimals={item.decimals}
                  error={item.error}
                />
              </div>
            </div>
          </div>
        </div>
      </li>
    )
  }

  const onChangeQuantity = (value, item, isFrom) => {
    if (!value || value <= 0) value = 0

    item.quantity = value
    item.error = null
    const parameter = isFrom ? 'from' : 'to'
    let data = isFrom ? from : to
    const index = data.findIndex(i => i.productId === item.productId)
    data[index] = item
    setRecipe({ ...recipe, [parameter]: [...data] })
  }

  const onChangeExtra = (event, item, isFrom) => {
    let { value } = event.target
    if (!value || value <= 0) value = 0

    item.extraPrice = value
    item.error = null
    const parameter = isFrom ? 'from' : 'to'
    let data = isFrom ? from : to
    const index = data.findIndex(i => i.productId === item.productId)
    data[index] = item
    setRecipe({ ...recipe, [parameter]: [...data] })
  }

  const onDropList = event => {
    event.preventDefault()
    const data = event.dataTransfer.getData('text')
    const id = Number(data.split('-')[1])
    const isFrom = data.split('-')[2] === '2'

    const parameter = isFrom ? 'from' : 'to'
    const index = recipe[parameter].findIndex(d => d.productId === id)

    if (index >= 0) {
      setRecipe({
        ...recipe,
        [parameter]: recipe[parameter].filter(i => i.productId !== id),
      })
      if (id === selected.value) setSelected({ value: null, label: 'Sin seleccionar' })
    }
  }

  const onDrag = event => {
    event.dataTransfer.setData('text', event.target.id)
  }

  const onDropFrom = (event, pId, pType) => {
    let item, index, id, type

    if (pId) {
      id = pId
      type = pType
    } else {
      event.preventDefault()
      const data = event.dataTransfer.getData('text')
      id = data.split('-')[1]
      type = data.split('-')[2]
    }

    if (type === '1') {
      index = to.findIndex(d => d.productId === Number(id))
      if (index !== -1) item = to.splice(index, 1)
      else {
        index = products.findIndex(d => d.productId === Number(id))
        item = products.slice(index, index + 1)
      }
      setRecipe({ ...recipe, from: [...from, { ...item[0] }] })
    }
  }

  const onDropTo = (event, pId, pType) => {
    let item, index, id, type

    if (pId) {
      id = pId
      type = pType
    } else {
      event.preventDefault()
      const data = event.dataTransfer.getData('text')
      id = data.split('-')[1]
      type = data.split('-')[2]
    }

    if (type === '1') {
      index = from.findIndex(d => d.productId === Number(id))
      if (index !== -1) item = to.splice(index, 1)
      else {
        index = products.findIndex(d => d.productId === Number(id))
        item = products.slice(index, index + 1)
      }
      setRecipe({ ...recipe, to: [...to, { ...item[0] }] })
    }
  }

  const itemsToSelect = isPc => (
    <div>
      {!isPc && <h5>Seleccione un producto</h5>}
      <CustomFilter
        search={search}
        onChange={({ target }) => setSearch(target.value)}
        customFilter={<div />}
      />

      <ul
        className="main-list"
        onDrop={onDropList}
        onDragOver={event => event.preventDefault()}
        id={'main'}>
        {' '}
        {products &&
          products
            .filter(filter)
            .filter(filterList)
            .map((item, index) => (
              <li
                onClick={() => {
                  /*
                if (!isPc) {
                    setModalMobile(false);
                    onDropFrom(e, item.productId, '1');
                }
                */
                }}
                key={index}
                id={`p-${item.productId}-1`} // draggable={isPc} onDragStart={onDrag}
              >
                <div className={'b-user-item'}>
                  <div className={'justify-center-start align-items-start bu-en left'}>
                    <div className={'b-user-name'}>{item.name}</div>
                    <div className={'b-user-email'}>{item.code}</div>
                  </div>
                  <div className={'d-flex row align-items-center mr-2 bu-b right'}>
                    <Icon
                      icon={faArrowRight}
                      size={'2x'}
                      tooltip={'Agregar a la receta base'}
                      onClick={e => {
                        onDropFrom(e, item.productId, '1')
                      }}
                    />
                  </div>
                </div>
              </li>
            ))}
      </ul>
    </div>
  )

  const onSave = () => {
    let errors = {}
    if (optional.length === 0) {
      if (from.length === 0) errors.from = 'Es necesario asignar productos'
      if (to.length === 0) errors.to = 'Es necesario asignar productos'
      if (to.length === 0 && from.length === 0)
        errors.optional = 'Es necesario asignar productos'
    }
    if (type === 3 && !selected.value) {
      selected.error = 'Es necesario'
      errors.selected = 'Es necesario'
    }

    if (Object.keys(errors).length === 0) {
      let error =
        from.filter(i => !i.quantity || i.quantity <= 0).length > 0 ||
        to.filter(i => !i.quantity || i.quantity <= 0).length > 0

      if (error) {
        const rTo = to.map(i => ({
          ...i,
          error: !i.quantity || i.quantity <= 0 ? 'Requerido' : '',
        }))
        const rFrom = from.map(i => ({
          ...i,
          error: !i.quantity || i.quantity <= 0 ? 'Requerido' : '',
        }))
        setRecipe({ ...recipe, from: rFrom, to: rTo })
      } else {
        const input = from.map(p => {
          return {
            quantity: p.quantity,
            product: p.productId,
            selected: p.productId === selected.value,
            extraPrice: parseFloat(p.extraPrice),
          }
        })
        const output = to.map(p => {
          return {
            quantity: finalQuantity || 1,
            product: p.productId,
            selected: p.productId === selected.value,
          }
        })
        const options = optional.map(p => {
          return {
            multiple: p.multiple,
            required: p.required,
            unlimited: p.unlimited,
            min: p.min,
            max: p.max,
            remove: p.remove,
            name: p.name,
            products: p?.products?.map(d => ({
              price: d.price,
              productId: d.value,
              quantity: d.quantity || 1,
            })),
            type: p?.type?.value,
            variable: p?.variable?.value,
          }
        })
        const request = { name: productName, description, type, input, output, options }

        if (!recipeId) dispatch(createEnumeration(request))
        else dispatch(updateEnumeration(recipeId, request))
      }
    }
    setErrors(errors)
  }

  const filter = item => {
    if (search && search !== '') {
      const exp = new RegExp(search.toUpperCase(), 'g')
      return Object.keys(item).some(p =>
        `${JSON.stringify(item[p])}`.toUpperCase().match(exp),
      )
    }
    return true
  }

  const disabled = ignore => {
    return loadingD || loadingC || (!ignore && !isCreate)
  }

  return (
    <div>
      <Modal show={modal && !mobile} centered size={'xl'} onHide={() => hide()}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={'container'}>{renderRecipe()}</div>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'buttons-container'}>
            <Button
              disabled={disabled(true)}
              color={'secondary'}
              icon={faWindowClose}
              onClick={() => hide()}>
              Cerrar
            </Button>
            {isRecipe && step === 2 && (
              <Button
                loading={loadingC}
                color={'accent'}
                icon={faArrowLeft}
                onClick={() => setStep(1)}>
                Regresar
              </Button>
            )}

            {recipeId && canDelete && (
              <Button
                loading={loadingD}
                disabled={disabled(true)}
                color={'secondary'}
                icon={faTrash}
                onClick={() => {
                  onAlert(
                    null,
                    `¿Desea eliminar la receta: ${name}? Está acción no podra ser revertida.`,
                    () => {
                      dispatch(deleteEnumeration(recipeId))
                    },
                    {
                      show: true,
                      title: 'Eliminar Receta',
                      type: 'warning',
                      showCancelButton: true,
                      cancelButtonText: 'Cancelar',
                      confirmButtonColor: '#B35796',
                      confirmText: 'Borrar',
                      onCancel: () => setAlert({ ...alert, show: false }),
                    },
                  )
                }}
                alert={{}}>
                Eliminar
              </Button>
            )}

            {recipeId ? (
              <Button
                disabled={disabled(true)}
                color={'primary'}
                icon={faSave}
                onClick={() => onSave()}>
                Guardar
              </Button>
            ) : (
              <Button
                disabled={
                  (from.length === 0 && optional.length === 0) || disableOptionals()
                }
                loading={loadingC}
                color={'primary'}
                icon={faSave}
                onClick={() => onSave()}>
                Guardar
              </Button>
            )}
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal show={mobile} centered size={'mg'} onHide={() => setModalMobile(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Roles para la selección</Modal.Title>
        </Modal.Header>
        <Modal.Body>{itemsToSelect(false)}</Modal.Body>
      </Modal>

      <Alert {...alert} />
    </div>
  )
}
export default RecipeDetailV2
