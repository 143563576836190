import React, { useState, useEffect, ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import {
  TableV2,
  CustomTabsV2,
  SelectedDates,
  Select,
  Icon,
  Button,
  ListCategories,
} from 'src/components'
import { faBook, faFileExcel, faListOl } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  downloadProductsExcel,
  downloadTransactionsExcel,
  getProductsClient,
  getTransactionClient,
} from 'src/actions/clients.actions'
import { selectProducts, selectTransactions } from 'src/selectors/clients.selector'

import {
  actionTypes as oTypes,
  getPaymentOrders,
  onSetOrderDetailId,
} from 'src/actions/orders.actions'
import {
  selectOrdersPayment,
  selectTotalOrdersPayment,
} from 'src/selectors/orders.selector'

import { onSetModalOrder, setModalOrderPaymentsInfo } from 'src/actions/utilities.actions'

import { addOrRemoveStringToList, haveAnyValue } from 'src/utils/utilitiesV2'
import { toMoney } from 'src/utils/utilities'
import { getPaymentTypes } from 'src/enums/paymentTypes'
import { getClientTransactionStatus } from 'src/enums/OrderStatusEnum'

import { loadingSelector } from 'src/selectors/loading.selector'

import ClientTransactionsSummary from 'src/content/Clients/ClientTransactions/ClientTransactionsSummary'

const defaultOption: ISelect = {
  value: null,
  label: 'Todos',
}

const balanceTypes: ISelect[] = [
  defaultOption,
  { value: true, label: 'Con saldo pendiente' },
  { value: false, label: 'Sin saldo pendiente' },
]

const paymentTypes: ISelect[] = [defaultOption, ...getPaymentTypes()]
const orderStatus: ISelect[] = [defaultOption, ...getClientTransactionStatus()]

enum ClientTransactionType {
  SELLS = 0,
  PAYMENTS = 1,
  ITEMS = 2,
}

interface IClientTransactionsParams {
  start: number
  end: number
  skip: number
  size: number
  search: string
  statusId: number
  paymentTypes?: string
  paymentType?: number
  withBalance?: boolean
  transactionType: number
}

interface IFlags {
  payments: boolean
}

interface IClientTransactionsProps {
  onHide: () => void
  clientId: number
  name?: string
  balancePermission: boolean
  canDownload: boolean
}

const ClientTransactions = ({
  clientId,
  name,
  onHide,
  balancePermission,
  canDownload,
}: IClientTransactionsProps) => {
  const dispatch = useDispatch()

  const data: IClientTransactionData = useSelector(selectTransactions)
  const loadingTransactions: boolean = useSelector(state =>
    loadingSelector([actionTypes.GET_TRANSACTIONS])(state),
  )
  const loadingTransactionsDownload: boolean = useSelector(state =>
    loadingSelector([actionTypes.DOWNLOAD_EXCEL_TRANSACTIONS])(state),
  )

  const loadingPayments: boolean = useSelector(state =>
    loadingSelector([oTypes.GET_PAYMENT_ORDERS])(state),
  )

  const paymentOrders = useSelector(selectOrdersPayment)
  const totalOrders: number = useSelector(selectTotalOrdersPayment)

  const items = useSelector(selectProducts)
  const loadingItems: boolean = useSelector(state =>
    loadingSelector([actionTypes.GET_PRODUCTS_CLIENT])(state),
  )
  const loadingItemsDownload: boolean = useSelector(state =>
    loadingSelector([actionTypes.GET_EXCEL_PRODUCTS])(state),
  )

  const show = haveAnyValue(clientId)

  const loading = loadingTransactions || loadingPayments || loadingItems
  const loadingDownload = loadingTransactionsDownload || loadingItemsDownload

  const [flags, setFlags] = useState<IFlags>({ payments: false })

  const [payments, setPayments] = useState<IClientTransactionItem[]>([])

  const [paramsText, setParamsText] = useState<string>('')
  const [params, setParams] = useState<IClientTransactionsParams>({
    start: null,
    end: null,
    skip: 0,
    size: 10,
    search: null,
    paymentTypes: null,
    paymentType: null,
    withBalance: null,
    transactionType: 0,
    statusId: null,
  })

  const { transactionType, start, end } = params

  useEffect(() => {
    if (!show) {
      setParamsText('')
      return
    }
    setParamsText(JSON.stringify(params))
  }, [show])

  useEffect(() => {
    if (paramsText === '' || paramsText === JSON.stringify(params)) return
    setParamsText(JSON.stringify(params))
  }, [params])

  useEffect(() => {
    if (paramsText !== JSON.stringify(params)) return
    setUp()
  }, [paramsText])

  useEffect(() => {
    if (loadingPayments) setFlags({ ...flags, payments: true })
    else if (flags.payments) {
      setFlags({ ...flags, payments: false })

      setPayments(
        paymentOrders.map(p => ({
          id: p.id,
          code: p.number,
          paymentType: p.paymentTypeData.name,
          status: p.statusData.name,
          date: p.enabledAt,
          paymentAt: p.paymentAt,
          createdBy: p.responsible.name,
          total: p.balance,
        })),
      )
    }
  }, [loadingPayments])

  const setUp = () => {
    if (transactionType === ClientTransactionType.SELLS)
      dispatch(getTransactionClient(params, clientId))
    else if (transactionType === ClientTransactionType.PAYMENTS) {
      dispatch(
        getPaymentOrders({
          ...params,
          paymentType: params.paymentType,
          client: clientId,
          state: 2,
        }),
      )
    } else if (transactionType === ClientTransactionType.ITEMS)
      dispatch(getProductsClient(clientId, { start, end }))
  }

  const onDownloadFile = () => {
    if (transactionType !== ClientTransactionType.ITEMS)
      dispatch(downloadTransactionsExcel(clientId, params))
    else
      dispatch(
        downloadProductsExcel(clientId, {
          start: params.start,
          end: params.end,
        }),
      )
  }

  const findPaymentTypeSelected = (): ISelect => {
    if (transactionType === ClientTransactionType.PAYMENTS)
      return paymentTypes.find(item => item.value === params.paymentType)
    return { value: null, label: 'Selecciona una opción' }
  }

  const renderItemAction = (item: IClientTransactionItem): ReactElement => {
    return (
      <div className={'d-flex'}>
        <Icon
          className={'mr-2'}
          tooltip={'Ver detalle'}
          icon={faBook}
          onClick={() => {
            if (params.transactionType === ClientTransactionType.SELLS)
              dispatch(onSetModalOrder(item.id))
            else {
              const payment = paymentOrders.find(p => p.id === item.id)
              dispatch(onSetOrderDetailId(payment.id))
            }
          }}
        />

        {params.transactionType === ClientTransactionType.SELLS && item.withPayments && (
          <Icon
            tooltip={'Ver pagos'}
            icon={faListOl}
            onClick={() => {
              dispatch(
                setModalOrderPaymentsInfo({
                  show: true,
                  order: { id: item.id, number: item.code },
                }),
              )
            }}
          />
        )}
      </div>
    )
  }

  const headersItems: ITableHeader[] = [
    { label: 'SKU', show: true, type: 'text', value: ['code'] },
    { label: 'Ítem', show: true, type: 'text', value: ['name'] },
    { label: 'Cantidad', show: true, type: 'text', value: ['quantity'] },
    {
      label: 'Precio promedio',
      show: true,
      type: 'currency',
      value: [],
      custom: item => toMoney(item.total / item.quantity),
    },
    { label: 'Última precio', show: true, type: 'currency', value: ['price'] },
    { label: 'Última compra', show: true, type: 'date', value: ['date'] },
  ]

  const headers: ITableHeader[] = [
    { label: 'Código', show: true, type: 'text', value: ['code'] },
    { label: 'Tipo de pago', show: true, type: 'text', value: ['paymentType'] },
    { label: 'Estado', show: true, type: 'text', value: ['status'] },
    { label: 'Fecha', show: true, type: 'date', value: ['date'] },
    { label: 'Fecha de pago', show: true, type: 'date', value: ['paymentAt'] },
    { label: 'Encargado', show: true, type: 'text', value: ['createdBy'] },
    { label: 'Total', show: balancePermission, type: 'currency', value: ['total'] },
    {
      label: 'Pagos',
      show: params.transactionType === ClientTransactionType.SELLS && balancePermission,
      type: 'currency',
      value: ['payments'],
    },
    {
      label: 'Cuenta por cobrar',
      show: params.transactionType === ClientTransactionType.SELLS && balancePermission,
      type: 'currency',
      value: [],
      custom: (item: IClientTransactionItem) =>
        toMoney(item.total - (item.payments || 0)),
    },
    {
      label: '',
      show: true,
      config: true,
      type: 'config',
      value: [],
      custom: item => renderItemAction(item),
    },
  ]

  const renderTable = (
    total: number,
    items: IClientTransactionItem[],
    headers: ITableHeader[],
  ): ReactElement => {
    return (
      <TableV2
        // @ts-ignore
        loading={loading}
        headers={headers}
        items={items}
        total={transactionType === ClientTransactionType.ITEMS ? undefined : total}
        getPagination={
          transactionType === ClientTransactionType.ITEMS
            ? undefined
            : (skip: number, size: number, search: string) =>
                setParams({ ...params, skip, size, search })
        }
        handleChange={
          transactionType === ClientTransactionType.ITEMS
            ? undefined
            : (search: string) => setParams({ ...params, search })
        }
        balancePermission={balancePermission}
        customFilter={renderFilters}
      />
    )
  }

  const renderFilters: ReactElement = (
    <Row>
      <Col xl={12}>
        <SelectedDates
          withOptionNull
          onDateChange={(start: Date, end: Date) =>
            setParams({
              ...params,
              start: start?.valueOf(),
              end: end?.valueOf(),
            })
          }
        />
      </Col>
      {params.transactionType === ClientTransactionType.SELLS && (
        <Col xl={12}>
          <Row>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <Select
                label={'Por saldo'}
                value={balanceTypes.find(item => item.value === params.withBalance)}
                options={balanceTypes}
                onChange={item => setParams({ ...params, withBalance: item.value })}
              />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <Select
                label={'Por estado'}
                value={orderStatus.find(item => item.value === params.statusId)}
                options={orderStatus}
                onChange={(item: ISelect) =>
                  setParams({
                    ...params,
                    statusId: haveAnyValue(item.value) ? Number(item.value) : null,
                  })
                }
              />
            </Col>
          </Row>
        </Col>
      )}
      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
        <Row>
          <Col xl={12}>
            <Select
              label={'Por tipo de pago'}
              value={findPaymentTypeSelected()}
              options={paymentTypes}
              onChange={(item: ISelect) => {
                if (transactionType === ClientTransactionType.PAYMENTS)
                  setParams({ ...params, paymentType: Number(item.value) })
                else {
                  const paymentTypes = haveAnyValue(item.value)
                    ? addOrRemoveStringToList(params.paymentTypes, item.value?.toString())
                    : null
                  setParams({ ...params, paymentTypes })
                }
              }}
            />
          </Col>
          {transactionType === ClientTransactionType.SELLS && (
            <Col xl={12}>
              <ListCategories
                onRemove={(item: ISelect) =>
                  setParams({
                    ...params,
                    paymentTypes: addOrRemoveStringToList(
                      params.paymentTypes,
                      item.value?.toString(),
                    ),
                  })
                }
                items={paymentTypes.filter(item =>
                  params.paymentTypes?.includes(item.value?.toString()),
                )}
              />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )

  const render = (
    <Row>
      {balancePermission && (
        <Col xl={12}>
          <ClientTransactionsSummary modeSummary />
        </Col>
      )}
      <Col xl={12}>
        <CustomTabsV2
          options={[
            {
              key: ClientTransactionType.SELLS,
              label: 'Estado de cuenta',
              render: () => renderTable(data.total, data.list, headers),
            },
            {
              key: ClientTransactionType.PAYMENTS,
              label: 'Ordenes de pago',
              render: () => renderTable(totalOrders, payments, headers),
            },
            {
              key: ClientTransactionType.ITEMS,
              label: 'Ítems vendidos',
              render: () => renderTable(items?.length, items, headersItems),
            },
          ]}
          defaultIndex={ClientTransactionType.SELLS}
          onSelect={(transactionType: number) =>
            setParams({ ...params, transactionType })
          }
        />
      </Col>
    </Row>
  )

  return (
    <Modal show={show} size={'xl'} centered onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Estado de cuenta: {name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{render}</Modal.Body>
      {canDownload && (
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button icon={faFileExcel} onClick={onDownloadFile} loading={loadingDownload}>
              Exportar
            </Button>
          </Row>
        </Modal.Footer>
      )}
    </Modal>
  )
}
export default ClientTransactions
