import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";

import {Col, Row, Nav, ButtonGroup, DropdownButton, Dropdown} from "react-bootstrap"
import {Td, Tr} from "react-super-responsive-table";
import Alert from "sweetalert-react";

import ModalTable from "../../../components/ModalTable/ModalTable";
import Card from "../../../components/cards/Card";
import Button from "../../../components/buttons/Button";
import Icon from "../../../components/buttons/IconButton";
import MapCard from "../../../components/maps/MapCard";

import {actionTypes as types, getOfferCategories, getAllOffers, getOfferArchived, onArchiveOffer, getAvailableOffers, setArchiveOffer, onChangeOfferStatus} from "../../../actions/offers.actions";
import {selectOffers, selectaAchived, selectOfferCategories, selectAchive, selectChange} from "../../../selectors/offers.selector";

import {loadingSelector} from "../../../selectors/loading.selector";
import {hasErrorsSelector, singleErrorSelector, handlerSuccess, handlerError} from "../../../selectors/error.selector";

import {
    selectCurrentCompany,
    selectCurrentModule
} from "../../../selectors/user.selector";
import IconButton from "../../../components/buttons/IconButton";
import {
    faArchive, faBackspace,
    faCheck,
    faExclamationCircle,
    faInfo,
    faInfoCircle,
    faLink,
    faSignLanguage,
    faTrashRestore,
    faWindowMinimize,
} from "@fortawesome/free-solid-svg-icons";
import {formatDateFromMillis} from "../../../utils/formatters";
import {Link} from "react-router-relative-link";
import { TableV2 } from '../../../components';

const latitude = 14.589465440976774;
const longitude = -90.51898866891861;

const LinkList = (props) => {
    const dispatch = useDispatch();

    const module = useSelector((state) => selectCurrentModule(state));
    const company = useSelector((state) => selectCurrentCompany(state));
    const categories = useSelector((state) => selectOfferCategories(state));
    const offers = useSelector((state) => selectOffers(state));

    const loadingA = useSelector((state) => loadingSelector([types.ON_ARCHIVE])(state));
    const hasErrorA = useSelector((state) => hasErrorsSelector([types.ON_ARCHIVE])(state));
    const errorA = useSelector((state) => singleErrorSelector([types.ON_ARCHIVE])(state));
    const responseArchived = useSelector((state) => selectAchive(state));

    const loadingS = useSelector((state) => loadingSelector([types.CHANGE_STATUS_OFFER])(state));
    const hasErrorS = useSelector((state) => hasErrorsSelector([types.CHANGE_STATUS_OFFER])(state));
    const errorS = useSelector((state) => singleErrorSelector([types.CHANGE_STATUS_OFFER])(state));
    const responseStatus = useSelector((state) => selectChange(state));

    const loadingAA = useSelector((state) => loadingSelector([types.ARCHIVED])(state));
    const archived = useSelector((state) => selectaAchived(state))

    const [category, setCategory] = useState('1');
    const [alert, setAlert] = useState({title: 'title'});
    const [getArchived, setGetArchived] = useState({value: false, show: false, items: []});
    const [archiveId, seArchiveId] = useState(0);
    const [offer, setOffer] = useState({id: null});
    const [google, setGoogle] = useState({map: null, maps: null, polygon: null, polygons: []});
    const [action, setAction] = useState({archive: false, status: false});

    const setUp = () => {
        if (module === 2000)
            dispatch(getAllOffers());
        else if (module === 1000)
            dispatch(getAvailableOffers());
        else if (module === 11000)
            dispatch(getAvailableOffers(5));
        dispatch(getOfferCategories());
    }

    useEffect(() => {
        setUp();
    }, []);

    useEffect(() => {
        if (!responseArchived) return;
        dispatch(getAllOffers());
        setGetArchived({value: false, show: false, items: []});

        setTimeout(() => setAlert({
            ...handlerSuccess(responseArchived),
            onConfirm: () => {
                setAlert({...alert, show: false});
                dispatch(setArchiveOffer(null));
            }
        }), 100);
    }, [responseArchived]);

    useEffect(() => {
        if (!hasErrorA || !action.archive) return;
        setAction({...action, archive: false});
        setAlert({
            ...handlerError(errorA.message),
            onConfirm: () => setAlert({...alert, show: false})
        });
    }, [hasErrorA]);

    useEffect(() => {
        if (!getArchived.value) return;

        if (archived && archived.length > 0)
            setGetArchived({value: false, show: true, items: archived});
        else {
            setGetArchived({value: false, show: false, items: []});
            setAlert({
                ...handlerError('No hay enlaces archivados'),
                onConfirm: () => setAlert({...alert, show: false})
            });
        }
    }, [archived]);

    useEffect(() => {
        let {map, maps, polygons} = google;
        if (!map || !maps) return;

        if (polygons)
            polygons.map((p) => (p.setMap(null)));

        if (!offer.polygons || offer.polygons.latLng <= 0) return;

        const items = [];

        offer.polygons.map((p) => {
            let {pointList} = p;
            pointList = pointList.sort((a, b) => (a.index - b.index));

            const points = pointList.map((p) => ({lat: Number(p.latitude), lng: Number(p.longitude)}));

            const polygon = new maps.Polygon({
                path: points,
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35
            });

            const center = getCenter(points);
            if (center.latLng.lat > 0) map.panTo(center.latLng);
            items.push(polygon);
        });

        map.setZoom(8);

        items.map((p) => (p.setMap(map)))

        setGoogle({map, maps, polygon: null, polygons: items});
    }, [offer]);

    useEffect(() => {
        if (!responseStatus) return;
        if (!action.status) return;
        setUp();
        setAction({...action, status: false});
        setAlert({
            ...handlerSuccess('El estado del enlace ha sido actualizado.'),
            onConfirm: () => setAlert({...alert, show: false})
        });
    }, [responseStatus]);

    useEffect(() => {
        if (!hasErrorS) return;
        if (!action.status) return;
        setAction({...action, status: false});
        setAlert({
            ...handlerError(errorS.message),
            onConfirm: () => setAlert({...alert, show: false})
        });
    }, [hasErrorS]);

    const getCenter = (paths) => {
        const center = paths.map((p) => {
            return [p.lat, p.lng];
        }).reduce((x, y) => {
            return [x[0] + y[0] / paths.length, x[1] + y[1] / paths.length];
        }, [0, 0]);
        return {latLng: {lat: center[0], lng: center[1]}};
    };

    const getLength = (categoryId) => offers.filter((o) => o.categoryId === categoryId).length;

    const getHeaders2 = (module) => {

        if (module === 1000)
            return ['', 'Estado', 'Código', 'Compañia', 'Nombre', 'Descripción', 'Disponible hasta', ''];
        else
            return ['', 'Estado', 'Código', 'Compañia', 'Nombre', 'Transportista', 'Disponible hasta', ''];
    };

    const getHeaders = (module) => {
        const headers  = [
            {label: '', show: true, value: ['amount'], type: 'text' ,  classNameCustom:(item)=> 'mini t-'+getStatus(item.status).color, select:true,
                custom: (item) => {
                    let status = getStatus(item.status);
                    return (
                        <IconButton
                            tooltip={status.label}
                            icon={status.icon}
                        />
                    )
                }},
            {label: 'Estado', show: true, value: ['statusC'], type: 'text' ,  className:'mini', 
                custom:(item)=> {
                    let status = getStatus(item.status);
                    item.statusC = status.label
                    return(
                        item.statusC
                    )
                }},
            {label: 'Código', show: true, value: ['correlative'], type: 'text' ,  className:'mini'},
            {label: 'Compañia', show: true, value: ['companyName'], type: 'text' ,  className:'medium'},
            {label: 'Nombre', show: true, value: ['notes'], type: 'text' ,  className:'mini'}
        ]
        if (module === 1000){
            headers.push({label: 'Descripción', show: true, value: ['alias'], type: 'text' ,  className:'mini'})
        }else headers.push({label: 'Transportista', show: true, value: ['alias'], type: 'text' ,  className:'mini'})

        headers.push(...[
            {label: 'Disponible hasta', show: true, value: ['availableUntil'], type: 'date' ,  className:'mini'},
            {config:true, show: true, label:'', className:'mini center',
                custom:(item)=> {
                    return(
                        <ButtonGroup size={'sm'}>
                            {(archiveId === item.id && loadingA) ?
                                <div></div> :
                                <DropdownButton disabled={loadingA} drop={'left'} as={ButtonGroup} title={''} id={'bg-nested-dropdown'}>
                                    <Dropdown.Item onClick={() => goTo(item, '/detalles')}>Detalle</Dropdown.Item>
                                    {company === item.company && <Dropdown.Item onClick={() => actionArchive(item)}>Archivar</Dropdown.Item>}
                                    {(company === item.company || company === item.transportCompany) && <>
                                        {item.status === 2 && <Dropdown.Item onClick={() => {
                                            dispatch(onChangeOfferStatus(item.id, 4));
                                            setAction({ ...action, status: true });
                                        }}>Desactivar</Dropdown.Item>}
                                        {item.status === 4 && <>
                                            <Dropdown.Item onClick={() => {
                                                dispatch(onChangeOfferStatus(item.id, 6));
                                                setAction({ ...action, status: true });
                                            }}>Activar</Dropdown.Item>
                                            <Dropdown.Item onClick={() => {
                                                dispatch(onChangeOfferStatus(item.id, 5));
                                                setAction({ ...action, status: true });
                                            }}>Cancelar</Dropdown.Item>

                                        </>}
                                    </>}
                                </DropdownButton>
                            }
                        </ButtonGroup>
                    )
                }
            }
            
        ])
        return headers;
    }

    const goTo = (item, route) => {
        const {history, match} = props;
        let url = `${match.url}${route}`;
        if (item)
            url += `/${item.id}`;
        history.push(url);
    };

    const actionArchive = (item) => {
        setAction({...action, archive: false});
        dispatch(onArchiveOffer(item.id));
    };

    const getStatus = (status) => {
        let color = '';
        let label = '';
        let icon = faInfo;

        switch (status) {
            case 2:
                color = 'green';
                label = 'Aceptada';
                icon = faCheck;
                break;
            case 3:
                color = 'gray';
                label = 'Rechazada';
                icon = faWindowMinimize;
                break;
            case 4:
                color = 'pink';
                label = 'Desactivada';
                icon = faBackspace;
                break;
            case 5:
                color = 'red';
                label = 'Cancelada';
                icon = faSignLanguage;
                break;
            default:
                color = 'blue';
                label = 'Disponible';
                icon = faExclamationCircle;
                break
        }

        return {color, label, icon};
    };

    return (
        <div>
            <h1 className={'dashboard-welcome'}>Enlaces</h1>
            <Row>
                <Col xl={8} lg={8} md={12} sm={12} xs={12}>
                    <Card>
                        {module != 1000 && <Row className={'container-buttons'}>
                            <Link to={'balances'}>
                                <Button> Balances </Button>
                            </Link>
                            <Button icon={faLink} onClick={() => goTo(null, '/nueva')}> Nuevo enlace</Button>
                            <Button loading={loadingAA} variant={'info'} icon={faArchive} onClick={() => {
                                dispatch(getOfferArchived());
                                setGetArchived({value: true, show: false, items: []});
                            }}> Ver archivados</Button>
                        </Row>}
                        <Nav justify variant={'tabs'} defaultActiveKey={`${category}`} onSelect={(selectedKey) => setCategory(selectedKey)}>
                            {categories.map((c, index) =>
                                <Nav.Item key={index}>
                                    <Nav.Link eventKey={`${c.id}`}><Icon icon={faInfoCircle} tooltip={c.description}/> {c.name} ({getLength(c.id)})</Nav.Link>
                                </Nav.Item>
                            )}
                        </Nav>
                        <TableV2
                            headers={getHeaders(module)}
                            items={offers.filter((o) => o.categoryId === parseInt(category))}
                            mobileAuto
                            storageKey={`linkList`}
                            onClickTr={({target},item)=> {
                                if (target.value === undefined) {
                                    if (offer.id === item.id)
                                        setOffer({id: null})
                                    else setOffer(item);
                                }
                            }}
                            isSelected={(selected)=> (offer.id && selected.id === offer.id)}
                        />
                       
                    </Card>
                </Col>
                <Col>
                    <MapCard
                        title={'Zona de enlace'}
                        latitude={latitude}
                        longitude={longitude}
                        height={500}
                        startCollapsed={false}
                        markerList={[]}
                        zoom={7}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({map, maps}) => setGoogle({map, maps, polygon: null})}
                    />
                </Col>
            </Row>

            <ModalTable
                show={getArchived.show}
                title={'Enlaces archivados'}
                size={'lg'}
                onHide={() => setGetArchived({value: false, show: false, items: []})}
                items={getArchived.items}
                headers={['#', 'Alias', 'Categoria', 'Actualizada por', '']}
                renderRow={(item, index) =>
                    <Tr className={'data'} key={index}>
                        <Td className={'mini'}>{item.correlative}</Td>
                        <Td className={'medium'}>{item.alias}</Td>
                        <Td className={'mini'}>{item.category.name}</Td>
                        <Td className={'medium'}>{item.updatedData && item.updatedData.name}</Td>
                        <Td className={'mini'}><IconButton
                            icon={faTrashRestore}
                            tooltip={'Recuperar'}
                            onClick={() => actionArchive(item)}
                        /></Td>
                    </Tr>
                }
            />

            <Alert {...alert}/>
        </div>
    );
};
export default LinkList;
