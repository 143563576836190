import React from 'react'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const IconImg = ({
  placement = undefined,
  tooltip = undefined,
  style = undefined,
  imgStyle = undefined,
  className = undefined,
  icon,
  disabled = undefined,
  onClick = undefined,
  loading = undefined,
}) => {
  return (
    <div
      onClick={() => {
        if (!disabled && onClick && !loading) onClick()
      }}
      style={{ ...style }}>
      {tooltip ? (
        loading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          <OverlayTrigger
            placement={placement || 'left'}
            overlay={<Tooltip id={'tooltip'}>{tooltip || ''}</Tooltip>}>
            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 3,
              }}>
              <img
                className={'icon-tbaa '.concat(className)}
                style={imgStyle ? imgStyle : null}
                src={icon}
                alt={tooltip || ''}
              />
            </div>
          </OverlayTrigger>
        )
      ) : (
        <div
          style={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 3,
          }}>
          <img
            className={'icon-tbaa '.concat(className)}
            style={imgStyle ? imgStyle : null}
            src={icon}
            alt={tooltip || ''}
          />
        </div>
      )}
    </div>
  )
}
export default IconImg
