import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Title, FABV2 } from 'src/components'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  getResolutions,
  deleteResolution,
} from 'src/actions/resolutions.actions'

import { isAllowed } from 'src/selectors/modules.selector'
import { correlativePermissions } from 'src/enums/permissions'

import { getAllPOS } from 'src/actions/restaurant.actions'

import { showAlert } from 'src/actions/alert.actions'
import {
  handlerError,
  handlerInfoWithButtons,
  handlerSuccess,
  hasErrors,
} from 'src/selectors/error.selector'

import { loadingSelector } from 'src/selectors/loading.selector'

import CorrelativeList from './CorrelativeList'
import CorrelativeDetail from './CorrelativeDetail'

interface ICorrelativeDetailProps {
  show: boolean
  correlative: ICorrelative
}

const CorrelativePage = () => {
  const dispatch = useDispatch()

  // PERMISSIONS
  const canCreate: boolean = useSelector(state =>
    isAllowed(state, [correlativePermissions.create]),
  )

  const deleteLoading: boolean = useSelector(state =>
    loadingSelector([actionTypes.DELETE_RESOLUTION])(state),
  )
  const deleteError = useSelector(state =>
    hasErrors([actionTypes.DELETE_RESOLUTION])(state),
  )

  const [detail, setDetail] = useState<ICorrelativeDetailProps>({
    show: false,
    correlative: {},
  })

  const [flag, setFlag] = useState<boolean>(false)

  useEffect(() => {
    dispatch(getAllPOS())
    setUp()
  }, [])

  useEffect(() => {
    if (deleteLoading) setFlag(true)
    else if (flag) {
      setFlag(false)
      const alert = deleteError
        ? handlerError(deleteError.message)
        : {
            ...handlerSuccess(),
            onConfirm: () => setUp(),
          }
      dispatch(showAlert(alert))
    }
  }, [deleteLoading])

  const setUp = () => {
    dispatch(getResolutions())
  }

  const onDelete = (resolution: ICorrelative) => {
    dispatch(
      showAlert({
        ...handlerInfoWithButtons(
          'Eliminar Correlativo',
          `¿Desea eliminar el correlativo ${resolution.number}? Los documentos emitidos bajo este correlativo no se perderán.`,
        ),
        onConfirm: () => dispatch(deleteResolution(resolution.id)),
      }),
    )
  }

  return (
    <div>
      <Title title={'Sistema de Facturación Controlada'} />
      <CorrelativeList
        onUpdate={(correlative: ICorrelative) => setDetail({ show: true, correlative })}
        onDelete={onDelete}
      />

      <CorrelativeDetail
        {...detail}
        onClose={(update: boolean) => {
          setDetail({ show: false, correlative: {} })
          if (update) setUp()
        }}
      />

      <FABV2
        show={canCreate}
        title={'Crear'}
        icon={faPlus}
        onClick={() => setDetail({ show: true, correlative: {} })}
      />
    </div>
  )
}
export default CorrelativePage
