import { $http } from './http'
class RequestServices {
  getRequests = async type => {
    const response = await $http.get(`/requests/${type}`)
    return response.data
  }

  updateRequests = async (state, request, message) => {
    const response = await $http.put(`/requests/${state}/${request}`, message)
    return response.data
  }
}

export default new RequestServices()
