import { useRef, useEffect } from 'react'

type BeforeUnloadHandler = (evt?: BeforeUnloadEvent) => void

export function useBeforeUnload(handler: BeforeUnloadHandler) {
  const handlerRef = useRef(handler)

  useEffect(() => {
    handlerRef.current = handler
  }, [handler])

  useEffect(() => {
    const handleBeforeUnload: BeforeUnloadHandler = evt => {
      let returnValue

      if (typeof handlerRef.current === 'function') {
        returnValue = handlerRef.current(evt)
      }

      if (evt.defaultPrevented) {
        evt.returnValue = ''
      }

      if (typeof returnValue === 'string') {
        evt.returnValue = returnValue
        return returnValue
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])
}
export default useBeforeUnload
