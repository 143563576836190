import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import Placeholder from 'src/assets/images/newDesign/placeholder.svg'
import { Icon } from 'src/components'
import { ListVariations } from 'src/content/Production/Categorization/Variation/ListVariations'
import { toMoney } from 'src/utils/utilities'
import { setGeneralConfig, setItemTable } from 'src/actions/transaction.action'

interface Props {
  index: number
  item: IItemSelectTable
  onDiscardItem: (key: number) => void
}

/**
 * Componente para mostrar el item en la tabla de transacciones en version Movil
 * @component
 * @param {number} index - indice del item
 * @param {IItemSelectTable} item - item a mostrar
 * @param {function} onDiscardItem - función para descartar el item
 * @returns
 */
export const ItemTableMobile = ({ index, item, onDiscardItem }: Props) => {
  const dispatch = useDispatch()

  const { code, subTotal, id, commentary, url } = item

  /**
   * Retorna el listado de variaciones o ubicaciones
   * @param variations Lista de variaciones o ubicaciones
   * @param name Nombre del conjunto de categorías
   * @param hide Si queremos mostrar el mensaje de no hay categorías
   * @param isLocation Si son ubicaciones
   * @returns JSX.Element
   */
  const getSimpleListVariation = (
    variations: ICategorization[],
    name: string,
    hide = true,
    isLocation?: boolean,
  ) => {
    return (
      <>
        <div className={'b-categories-select'} style={{ marginLeft: '15px' }}>
          {/* @ts-expect-error Error por JS*/}
          <ListVariations
            selected={variations}
            groupName={name}
            hideMessage={hide}
            isLocations={isLocation}
          />
        </div>
      </>
    )
  }

  return (
    <div className={'b-item-select'} key={index}>
      <Row>
        <Col xs={2} style={{ alignItems: 'center', display: 'flex' }}>
          <Row>
            {' '}
            <div className={'t-item-select-img'}>
              <img src={url || Placeholder} alt={'img'} />
            </div>
          </Row>
        </Col>
        <Col xs={10}>
          <Row xs={12} className="mt-1">
            {' '}
            <div className={'b-user-email'}>
              {code}, {toMoney(subTotal)}{' '}
            </div>
          </Row>
          <Row xs={12}>
            {id === 1 ? (
              <div className={'column'}>
                <div className={'d-flex'}>
                  <div className={'b-user-name'}>{commentary}</div>
                </div>
                <div className={'b-user-email'}>{code}</div>
              </div>
            ) : (
              <div className={'column'} style={{ width: '100%' }}>
                {item?.line
                  ?.filter(y => y.quantity > 0)
                  .map((x, i) => (
                    <div key={i}>
                      <div
                        style={{ marginLeft: i === 0 ? 0 : 5 }}
                        className={
                          i === 0
                            ? 'b-user-name space-between'
                            : 'b-user-email space-between'
                        }>
                        {' '}
                        <div>{x.productData.name} </div>
                        <div
                          style={{
                            marginRight: 5,
                            width: 40,
                            textAlign: 'right',
                          }}>{`${x.quantity} u.`}</div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </Row>{' '}
          <Row>
            <Col>
              <div style={{ display: 'flex', marginTop: 10, float: 'right' }}>
                <Icon
                  style={{ marginRight: 15 }}
                  icon={faTrash}
                  color={'secondary'}
                  size={'1x'}
                  tooltip={'Descartar'}
                  onClick={() => {
                    onDiscardItem(index)
                  }}
                />
                <Icon
                  icon={faEdit}
                  size={'1x'}
                  color={'accent'}
                  tooltip={'Editar'}
                  onClick={() => {
                    dispatch(setGeneralConfig({ showDetails: true }))
                    dispatch(setItemTable({ item: item, index: index }))
                  }}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      {item.commentary && item.commentary !== null && (
        <div className={'b-categories-select'} style={{ marginLeft: '15px' }}>
          {item.commentary}
        </div>
      )}
      {getSimpleListVariation(item.listCategorization, 'Variaciones', true, false)}
      {getSimpleListVariation(item.listLocations, 'Ubicación', true, false)}
    </div>
  )
}
