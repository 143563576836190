import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Modal } from 'react-bootstrap'
import Loading from 'src/components/Loading/Loading'
import Paragraph from 'src/components/Paragraph/Paragraph'

import { actionTypes, onConfirmPurchases } from 'src/actions/purchase.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import { showAlert } from 'src/actions/alert.actions'

const tupleSize = 20

interface IProps {
  show: boolean
  isPurchase: boolean
  purchaseId: number
  invoice: string
  request: IPurchasePaymentRequest
  details: IPurchaseDetail[]
  onHide: () => void
}

/** Purchase confirm message process
 * @param {boolean} show Indicates whether the modal should be shown
 * @param {boolean} isPurchase Indicates whether it is a purchase or expense
 * @param {number} purchaseId ID of purchase
 * @param {string} invoice Invoice of purchase
 * @param {IPurchasePaymentRequest} request Payments
 * @param {IPurchaseDetail[]} details Items of purchase
 * @param {function} onHide Close modal
 * **/
const PurchaseConfirm: React.FC<IProps> = ({
  show,
  isPurchase,
  purchaseId,
  invoice,
  request,
  details = [],
  onHide,
}) => {
  const dispatch = useDispatch()

  const loadingConfirm = useSelector(state =>
    loadingSelector([actionTypes.CONFIRM_PURCHASE])(state),
  )

  const hasErrorConfirm = useSelector(state =>
    hasErrors([actionTypes.CONFIRM_PURCHASE])(state),
  )

  const [released, setReleased] = useState<number>(0)
  const [flag, setFlags] = useState<boolean>(false)
  const [last, setLast] = useState<boolean>(false)

  const percentage = (released / (details.length || 1)) * 90

  useEffect(() => {
    if (!show) return
    setReleased(0)
    setLast(false)
    onUpDetails(0)
  }, [show])

  useEffect(() => {
    if (loadingConfirm) setFlags(true)
    else if (flag) {
      setFlags(false)
      if (last || hasErrorConfirm) {
        const alert: IAlert = hasErrorConfirm
          ? {
              ...handlerError(hasErrorConfirm.message),
            }
          : {
              ...handlerSuccess('Operación realizada con éxito'),
            }
        alert.onConfirm = () => onHide()
        dispatch(showAlert(alert))
      } else onUpDetails(released)
    }
  }, [loadingConfirm])

  const onUpDetails = (currentReleased: number) => {
    const detailsToUp = details.slice(currentReleased, currentReleased + tupleSize)
    const lastElement = details.slice(-1)[0]
    const currentLastElement = detailsToUp.slice(-1)[0]
    const last = currentLastElement?.detailId === lastElement?.detailId
    setLast(last)
    setReleased(currentReleased + detailsToUp.length)

    const confirmRequest: IPurchasePaymentRequest = {
      ...request,
      details: detailsToUp,
      lastDetails: last,
    }

    delete confirmRequest.show

    Object.keys(confirmRequest).forEach(param => {
      if (confirmRequest[param] === undefined) delete confirmRequest[param]
    })

    dispatch(onConfirmPurchases(purchaseId, confirmRequest))
  }

  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header>
        <Modal.Title>
          {isPurchase ? 'Compra' : 'Gasto'}: {invoice}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={'column'}>
          <Paragraph>Confirmando {isPurchase ? 'la compra' : 'el gasto'}</Paragraph>
          <Paragraph>este proceso puede demorar unos segundos</Paragraph>
          <Loading show percentage={percentage || 1} label={`${percentage}%`} progress />
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default PurchaseConfirm
