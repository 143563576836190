import { actionTypes } from '../actions/restaurant.actions'
import { extract, successState } from '../actions/global.actions'
import { number } from 'prop-types'

const initialState = {
  table: {},
  allTables: [],
  tableOrder: {},
  tableStatus: [],
  reservation: [],
  chairs: [],
  reservationStatus: [],
  allOrders: [],
  responseTip: null,
  tipsAvailable: [],
  generate: {},
  posUser: [],
  posAdmins: [],
  allPOS: [],
  posResponse: '',
  items: [],
  total: 0,
  categoryItems: [],
  categoryTotal: 0,
  search: '',
  searchItems: [],
  searchTotal: 0,
  categories: [],
  item: {},
  billItems: [],
  printData: {},
  tags: [],
  seePreBill: false,
  categorySelected: {},
  billItemsSelected: [],
  billCorrelative: '',
  recipe: {},
  deletePOSResponse: {},
  posProfile: {},
  posItemsType: [],
  description: '',
  tokenVerified: false,
  payments: [],
  pendingPayments: [],
  selectedPendingPayment: null,
  discountSelected: {
    id: null,
    value: null,
    tipValue: null,
  },
  tabs: [
    {
      value: 0,
      label: 'Items',
      selected: true,
    },
    {
      value: 1,
      name: 'tabSplit',
      label: 'Cuentas separadas',
      selected: false,
      initialized: true,
      posTypes: [1, 3],
    },
    {
      value: 2,
      name: 'tabOrders',
      label: 'Comandas',
      selected: false,
      initialized: true,
      posTypes: [1, 3],
    },
  ],
  emptyTickets: [
    {
      tag: 'Ticket 1',
      items: [],
    },
  ],
  selectedTicket: 'Sin ticket',
  lastPosId: null,
  lastTerminalId: null,
  clearValues: false,
  allPosConfigurations: [],
  posConfiguration: {},
  orderConcepts: [],
  posConcepts: [],
  productPricesByPOS: [],
  clearRequest: {},
  posItemsExistence: {},
  posItemsExistenceSearch: {},
  posItemsExistenceCategory: {},
  posSelected: {},
  posConfigurationResponse: {},
}

const RestaurantReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.UPDATE_POS_STEP):
      return { ...state, posConfigurationResponse: extract(action.response, {}) }
    case successState(actionTypes.SET_POS_SELECTED):
      return { ...state, posSelected: extract(action.posSelected, {}) }
    case successState(actionTypes.SET_POS_ITEMS_EXISTENCE):
      return { ...state, ...action.payload }
    case successState(actionTypes.TABLE_ORDER_CLEAR_REQUEST):
      return { ...state, clearRequest: extract(action.clearRequest, {}) }
    case successState(actionTypes.SET_CLEAR_VALUE):
      return { ...state, clearValues: extract(action.value, false) }
    case successState(actionTypes.ON_SAVE_DESCRIPTION):
      return { ...state, description: extract(action.description, '') }
    case successState(actionTypes.POS_ITEM_TYPE):
      return { ...state, posItemsType: extract(action.items, []) }
    case successState(actionTypes.GET_POS_PROFILE):
      return { ...state, posProfile: extract(action.posProfile, {}) }
    case successState(actionTypes.DELETE_POS):
      return { ...state, deletePOSResponse: extract(action.response, {}) }
    case successState(actionTypes.GET_RECIPE):
      return { ...state, recipe: extract(action.recipe, {}) }
    case successState(actionTypes.GET_BILL_CORRELATIVE):
    case successState(actionTypes.CLEAR_BILL_CORRELATIVE):
      return { ...state, billCorrelative: extract(action.billCorrelative, '') }
    case successState(actionTypes.GET_TABLE_POS_CATEGORY):
      return { ...state, categories: extract(action.categories, []) }
    case successState(actionTypes.ON_SET_BILL_ITEMS_SELECTED):
      return { ...state, billItemsSelected: extract(action.items, []) }
    case successState(actionTypes.ON_SET_CATEGORY_SELECTED):
      return { ...state, categorySelected: extract(action.categorySelected, {}) }
    case successState(actionTypes.ON_SET_VIEW_PRE_BILL):
      return { ...state, seePreBill: extract(action.preBillValue, false) }
    case successState(actionTypes.ON_SET_TAG_SELECTED):
      return { ...state, tags: extract(action.tags, []) }
    case successState(actionTypes.ON_SET_PRINT_DATA):
      return { ...state, printData: extract(action.data, {}) }
    case successState(actionTypes.UPDATE_BILL_ITEMS):
      return { ...state, billItems: extract(action.billItems, []) }
    case successState(actionTypes.SHOW_ITEM_DETAIL):
      return { ...state, item: extract(action.item, {}) }

    case successState(actionTypes.GET_TABLE_ITEMS): {
      const response = extract(action.items, {})
      const { items, total } = response
      return { ...state, items: state.items.concat(items), total }
    }
    case successState(actionTypes.REPLACE_TABLE_ITEMS): {
      const response = extract(action.items, {})
      const { items, total } = response
      return { ...state, items, total }
    }

    case successState(actionTypes.GET_TABLE_CATEGORY_ITEMS): {
      const response = extract(action.items, {})
      const { items, total } = response
      return {
        ...state,
        categoryItems: state.categoryItems.concat(items),
        categoryTotal: total,
      }
    }
    case successState(actionTypes.REPLACE_TABLE_CATEGORY_ITEMS): {
      const response = extract(action.items, {})
      const { items, total } = response
      return { ...state, categoryItems: items, categoryTotal: total }
    }

    case successState(actionTypes.GET_TABLE_SEARCH_ITEMS): {
      const response = extract(action.items, {})
      const { items, total } = response
      return {
        ...state,
        searchItems: state.searchItems.concat(items),
        searchTotal: total,
      }
    }
    case successState(actionTypes.REPLACE_TABLE_SEARCH_ITEMS): {
      const response = extract(action.items, {})
      const { items, total } = response
      return { ...state, searchItems: items, searchTotal: total }
    }

    case successState(actionTypes.SET_TABLE_SEARCH): {
      return {
        ...state,
        search: extract(action.search, ''),
      }
    }
    case successState(actionTypes.TRANSFER_TABLE):
      return { ...state, tableOrder: extract(action.tableOrder, {}) }
    case successState(actionTypes.CREATE_OR_UPDATE_POS):
      return { ...state, posResponse: extract(action.posResponse, '') }
    case successState(actionTypes.GET_ALL_POS):
      return { ...state, allPOS: extract(action.allPOS, []) }
    case successState(actionTypes.GET_POS_ADMINS):
      return { ...state, posAdmins: extract(action.response, []) }
    case successState(actionTypes.IS_POS_ADMIN):
      return { ...state, isPosAdmin: extract(action.response, []) }
    case successState(actionTypes.ADD_POS_ADMIN):
      return { ...state, posAdmins: state.posAdmins.concat(action.admin) }
    case successState(actionTypes.REMOVE_POS_ADMIN): {
      return {
        ...state,
        posAdmins: state.posAdmins.filter(admin => admin.id !== action.id),
      }
    }
    case successState(actionTypes.GET_ALL_POS_USER):
      return { ...state, posUser: extract(action.posUser, []) }
    case successState(actionTypes.GENERATE_ORDER):
      return { ...state, generate: extract(action.response, {}) }
    case successState(actionTypes.GENERATE_ORDER_IMMEDIATELY):
      return { ...state, generate: extract(action.response, {}) }
    case successState(actionTypes.GET_TIPS_AVAILABLE):
      return { ...state, tipsAvailable: extract(action.response, []) }
    case successState(actionTypes.CREATE_OR_DELETE_TIP):
      return { ...state, responseTip: extract(action.response, null) }
    case successState(actionTypes.SET_PRODUCT_STATUS):
      return { ...state, tableOrder: action.response }
    case successState(actionTypes.DELETE_PRODUCT):
      return { ...state, tableOrder: extract(action.tableOrder, {}) }
    case successState(actionTypes.GET_TABLES):
      return { ...state, allTables: extract(action.tables, []) }
    case successState(actionTypes.CREATE_TABLE_ORDER): {
      return {
        ...state,
        tableOrder: action.tableOrder,
      }
    }
    case successState(actionTypes.GET_TABLE_ORDER): {
      return {
        ...state,
        tableOrder: action.tableOrder,
      }
    }

    case successState(actionTypes.CLEAR_TABLE_ORDER): {
      return {
        ...state,
        tableOrder: {},
      }
    }

    case successState(actionTypes.GET_TABLES_STATUS): {
      return {
        ...state,
        tableStatus: action.tableStatus,
      }
    }

    case successState(actionTypes.GET_RESERVATION_TABLE): {
      return {
        ...state,
        reservation: action.table,
      }
    }

    case successState(actionTypes.GET_CHAIRS): {
      return {
        ...state,
        chairs: action.request,
      }
    }

    case successState(actionTypes.GET_RESERVED_STATUS): {
      return {
        ...state,
        reservationStatus: action.table,
      }
    }

    case successState(actionTypes.GET_ALL_TABLE_ORDERS): {
      return {
        ...state,
        allOrders: action.response,
      }
    }

    case successState(actionTypes.GET_ORDER_PAYMENTS): {
      return {
        ...state,
        payments: extract(action.response, []),
      }
    }

    case successState(actionTypes.VERIFY_TOKEN): {
      return {
        ...state,
        tokenVerified: extract(action.response, false),
      }
    }

    case successState(actionTypes.SET_PENDING_PAYMENTS): {
      return {
        ...state,
        pendingPayments: extract(action.payload, []),
      }
    }

    case successState(actionTypes.RESET_PENDING_PAYMENTS): {
      return {
        ...state,
        pendingPayments: [{ amount: 0 }],
        selectedPendingPayment: 0,
      }
    }

    case successState(actionTypes.RESET_PAYMENTS): {
      return {
        ...state,
        payments: [],
      }
    }

    case successState(actionTypes.SET_SELECTED_PENDING_PAYMENT): {
      return {
        ...state,
        selectedPendingPayment: action.payload,
      }
    }

    case successState(actionTypes.SET_DISCOUNT_SELECTED): {
      return {
        ...state,
        discountSelected: extract(action.payload, {}),
      }
    }

    case successState(actionTypes.SET_TAB_SELECTED): {
      const tabs = state.tabs.map((tab, i) =>
        i === action.payload ? { ...tab, selected: true } : { ...tab, selected: false },
      )

      return {
        ...state,
        tabs,
      }
    }

    case successState(actionTypes.ADD_EMPTY_TICKETS): {
      return {
        ...state,
        emptyTickets: [...state.emptyTickets, { tag: action.payload, items: [] }],
      }
    }

    case successState(actionTypes.REMOVE_EMPTY_TICKET): {
      return {
        ...state,
        emptyTickets: state.emptyTickets.filter(ticket => ticket.tag !== action.payload),
      }
    }

    case successState(actionTypes.RESET_EMPTY_TICKETS): {
      return {
        ...state,
        emptyTickets: [
          {
            tag: 'Ticket 1',
            items: [],
          },
        ],
      }
    }

    case successState(actionTypes.SET_SELECTED_TICKET): {
      return {
        ...state,
        selectedTicket: action.payload,
      }
    }

    case successState(actionTypes.SET_LAST_POS): {
      return {
        ...state,
        lastPosId: action.payload,
      }
    }

    case successState(actionTypes.SET_LAST_TERMINAL): {
      return {
        ...state,
        lastTerminalId: action.payload,
      }
    }

    case successState(actionTypes.SET_TABLE_ORDER): {
      return {
        ...state,
        tableOrder: { ...state.tableOrder, ...action.payload },
      }
    }

    case successState(actionTypes.GET_POS_CONFIGURATIONS): {
      return {
        ...state,
        allPosConfigurations: extract(action.response, []),
      }
    }

    case successState(actionTypes.GET_POS_CONFIGURATION_BY_USER): {
      return {
        ...state,
        posConfiguration: extract(action.response, {}),
      }
    }

    case successState(actionTypes.GET_ORDER_INVOICE_CONCEPTS): {
      return {
        ...state,
        orderConcepts: extract(action.response, []),
      }
    }

    case successState(actionTypes.GET_POS_INVOICE_CONCEPTS): {
      return {
        ...state,
        posConcepts: extract(action.response, []),
      }
    }
    case successState(actionTypes.GET_PRODUCT_PRICES_BY_POS): {
      return {
        ...state,
        productPricesByPOS: extract(action.response, []),
      }
    }
    default:
      return state ? state : initialState
  }
}

export default RestaurantReducer
