import React, {Component} from 'react';
import {connect} from "react-redux";

import {ButtonGroup, Col, Dropdown, DropdownButton, Row} from "react-bootstrap";
import {Td, Tr} from "react-super-responsive-table";
import Alert from "sweetalert-react";

import {formatDateFromMillis} from "../../../utils/formatters";

import Card from "../../../components/cards/Card";
import CustomFilter from "../../../components/cards/FilterCard";

import {actionTypes as typesC, getRequestInfo, onRequestResponse} from "../../../actions/clients.actions";
import {selectAllRequestsInfo} from "../../../selectors/clients.selector";

import {selectSetCurrentCompany} from "../../../selectors/company.selector";

import {loadingSelector} from "../../../selectors/loading.selector";
import {hasErrorsSelector, singleErrorSelector, handlerSuccess, handlerError} from "../../../selectors/error.selector";
import { TableV2 } from '../../../components';

class InfoRequest extends Component {

    state = {
        filters: {},
        alert: {title: 'title'}
    };

    componentDidMount() {
        const {company, getRequestInfo} = this.props;
        if (company.clientId)
            getRequestInfo(company.clientId);

    };

    componentWillReceiveProps(next) {
        const {loadingR, hasError, company, getRequestInfo} = this.props;
        const {error} = next;

        if (loadingR && !next.loadingR) {
            let alert = {title: 'title'};
            if (!hasError && next.hasError) {
                alert = handlerError(error.message || 'Ha ocurrido un error inesperado');
                alert.onConfirm = () => this.setState({alert: {...alert, show: false}});
            } else {
                if (company.clientId)
                    getRequestInfo(company.clientId);
                alert = handlerSuccess('La operación fue realizada satisfactoriamente.');
                alert.onConfirm = () => this.setState({alert: {...alert, show: false}});
            }
            this.setState({alert});
        }
    };

    customFilter = (data) => {
        let filters = this.state.filters;
        if (JSON.stringify(filters) != JSON.stringify(data)) {
            this.setState({filters: data})
        }
    };

    filter = (item) => {
        const {filters} = this.state;
        const {requestSend, requestAccepted, requestRejected} = filters;
        return ((requestSend && item.status === 0) || (requestAccepted && item.status === 1) || (requestRejected && item.status === 2));
    };

    render() {
        const {alert} = this.state;
        const {requests, loading, company} = this.props;

        return (
            <div>
                <Row>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                        <CustomFilter
                            title={'Filtros'}
                            module={'client'}
                            submodule={'requestInfo'}
                            customFilter={this.customFilter}
                        />
                    </Col>
                    <Col>
                        <Card>
                            <TableV2
                                storageKey={`infoRequest`}
                                mobileAuto
                                headers={[
                                    { label: 'Estado', show: true, value: ['stateC'], type: 'text', className: 'mini', custom:(item)=> item.stateC = item.status ===0 ? 'Solicitada' : item.status === 1 ? 'Aceptada' : item.status ===2 ? 'Rechazada' :'--=' },
                                    { label: 'Solicitado por', show: true, value: ['companyData','name'], type: 'text', className: 'medium' },
                                    { label: 'Creado', show: true, value: ['createdAt'], type: 'date', className: 'medium' },
                                    { config: true, show: true, label: '', className: 'mini' ,custom:(item)=> item.status === 0 &&
                                    <ButtonGroup>
                                        <DropdownButton drop={'left'} as={ButtonGroup} title={''} id={'bg-nested-dropdown'}
                                          >  <Dropdown.Item onClick={() => this.props.onRequestResponse(company.clientId, 1, [item.id])} eventKey={'0'}>Aceptar</Dropdown.Item>
                                            <Dropdown.Item onClick={() => this.props.onRequestResponse(company.clientId, 2, [item.id])} eventKey={'1'}>Rechazar</Dropdown.Item>
                                        </DropdownButton>
                                    </ButtonGroup>}
                                ]}
                                items={requests.filter(this.filter)}
                                loading={loading}
                            />

                        </Card>
                    </Col>
                </Row>

                <Alert {...alert}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    company: selectSetCurrentCompany(state),
    requests: selectAllRequestsInfo(state),
    loading: loadingSelector([typesC.GET_INFO_REQUEST])(state),
    loadingR: loadingSelector([typesC.ON_INFO_REQUEST_RESPONSES])(state),
    hasError: hasErrorsSelector([typesC.ON_INFO_REQUEST_RESPONSES])(state),
    error: singleErrorSelector([typesC.ON_INFO_REQUEST_RESPONSES])(state)
});
const mapDispatchToProps = dispatch => ({
    getRequestInfo: (clientId) => dispatch(getRequestInfo(clientId)),
    onRequestResponse: (clientId, status, requestedId) => dispatch(onRequestResponse(clientId, status, requestedId))
});
export default connect(mapStateToProps, mapDispatchToProps)(InfoRequest);
