import React, { Component } from 'react'
import MarkerIcon from 'src/assets/images/svg/maps-and-flags.svg'
import MarkerIconTwo from 'src/assets/images/svg/house_marker.svg'

class Marker extends Component {
  render() {
    const { text, onClick, type } = this.props
    return (
      <div onClick={onClick}>
        <img
          src={Number(type) === 1 ? MarkerIconTwo : MarkerIcon}
          alt={'position'}
          width={25}
        />
        {text}
      </div>
    )
  }
}

export default Marker
