import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import { FormText, Select, Button, CustomDate } from 'src/components'
import { faEdit, faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons'

import { actionTypes, onCreateResolution } from 'src/actions/resolutions.actions'

import { selectCompanyCountry } from 'src/selectors/company.selector'

import { selectAllPOS } from 'src/selectors/restaurant.selector'

import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import { showAlert } from 'src/actions/alert.actions'
import { loadingSelector } from 'src/selectors/loading.selector'

import { haveAnyValue, isValidString } from 'src/utils/utilitiesV2'
import { getFelDocumentType } from 'src/enums/felDocumentTypes'

import { isAllowed } from 'src/selectors/modules.selector'
import { correlativePermissions } from 'src/enums/permissions'
import { createNumberFormat, getDocumentType } from './CorrelativeFunctions'

interface IDataErrors {
  documentType?: string
  date?: string
  number?: string
  series?: string
  initial?: string
  final?: string
  rangeCount?: string
}

interface Props {
  show: boolean
  onClose: (update: boolean) => void
  correlative?: ICorrelative
}

const defaultOption = { value: null, label: 'Sin seleccionar' }

const pattern = /^\d{3}-\d{3}-\d{2}-\d{1,}$/

const CorrelativeDetail = ({ show, onClose, correlative = {} }: Props) => {
  const dispatch = useDispatch()

  const country = useSelector(selectCompanyCountry)
  const pos = useSelector(selectAllPOS)

  const loading: boolean = useSelector(state =>
    loadingSelector([actionTypes.ON_CREATE_RESOLUTION])(state),
  )
  const hasError = useSelector(state =>
    hasErrors([actionTypes.ON_CREATE_RESOLUTION])(state),
  )

  const canUpdate: boolean = useSelector(state =>
    isAllowed(state, [correlativePermissions.update]),
  )

  const documentTypes: IFelDocumentType[] = getFelDocumentType(country.id)

  const [data, setData] = useState<ICorrelative>({})
  const [errors, setErrors] = useState<IDataErrors>({})
  const [flag, setFlag] = useState<boolean>(false)

  const isUpdate: boolean = haveAnyValue(correlative?.id)
  const disabled: boolean = loading || (isUpdate && !canUpdate)

  useEffect(() => {
    if (!show) return
    let rangeInitial: string, rangeFinal: string
    if (isUpdate) {
      rangeInitial = createNumberFormat(
        correlative.series,
        correlative.rangeCount,
        correlative.docType,
        correlative.initialNumber,
      )
      rangeFinal = createNumberFormat(
        correlative.series,
        correlative.rangeCount,
        correlative.docType,
        correlative.finalNumber,
      )
    } else {
      rangeInitial = null
      rangeFinal = null
    }

    setData({
      ...correlative,
      rangeInitial,
      rangeFinal,
    })
  }, [show])

  useEffect(() => {
    if (loading) setFlag(true)
    else if (flag) {
      setFlag(false)
      const alert = hasError
        ? {
            ...handlerError(hasError.message),
          }
        : {
            ...handlerSuccess(),
            onConfirm: () => {
              onHide(true)
            },
          }
      dispatch(showAlert(alert))
    }
  }, [loading])

  const onHide = (update = false) => {
    onClose(update)
    setData({})
    setErrors({})
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { id, value } = e.currentTarget
    setData({ ...data, [id]: value })
  }

  const onValidate = () => {
    const error: IDataErrors = {}
    const genericMessageError = 'Es requerido'
    const rangeError = "Es requerido con el formato 'XXX-XXX-XX-XXXXXXXX'"

    if (!haveAnyValue(data.expirationDate)) error.date = genericMessageError
    if (!haveAnyValue(data.number)) error.number = genericMessageError

    const initialError: boolean =
      !haveAnyValue(data.rangeInitial) || !isValidString(data.rangeInitial, pattern)
    const finalError: boolean =
      !haveAnyValue(data.rangeFinal) || !isValidString(data.rangeFinal, pattern)
    if (initialError) error.initial = rangeError
    if (finalError) error.final = rangeError

    if (!initialError && !finalError) {
      const initialParams: string[] = data.rangeInitial.split('-')
      const finalParams: string[] = data.rangeFinal.split('-')

      const initial: number = parseInt(initialParams[3])
      const final: number = parseInt(finalParams[3])
      if (initial >= final) {
        error.initial = 'El número inicial debe ser menor al número final'
        error.final = 'El número final debe ser mayor al número inicial'
      } else {
        initialParams.pop()
        finalParams.pop()
        if (initialParams.toString() !== finalParams.toString()) {
          error.initial =
            'Los complementos deben ser igual a los complementos del número final'
          error.final =
            'Los complementos deben ser igual a los complementos del número inicial'
        }
      }
    }

    setErrors(error)
    if (Object.keys(error).length === 0) {
      const paramsOfInitial: string[] = data.rangeInitial.split('-')
      const paramsOfFinal: string[] = data.rangeFinal.split('-')

      const request: IResolutionRequest = {
        resolutionNumber: data.number,
        endDate: data.expirationDate,
        posId: data?.pos?.id,
        documentType: data.documentType,
        series: paramsOfInitial[0],
        rangeCount: parseInt(paramsOfInitial[1]),
        docType: parseInt(paramsOfInitial[2]),
        initialNumber: parseInt(paramsOfInitial[3]),
        finalNumber: parseInt(paramsOfFinal[3]),
      }

      dispatch(onCreateResolution(correlative.id, request))
    }
  }

  return (
    <div>
      <Modal show={show} centered size={'xl'} onHide={() => onHide()}>
        <Modal.Header closeButton>
          <Modal.Title>{isUpdate ? correlative.number : 'Crear correlativo'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <Select
                required
                disabled={disabled}
                label={'Tipo de documento'}
                options={documentTypes}
                value={getDocumentType(data.documentType)}
                onChange={({ value }) => setData({ ...data, documentType: value })}
                error={errors.documentType}
                testId={'cd-doc-type'}
              />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <CustomDate
                disabled={disabled}
                required
                label={'Fecha limite de emisión'}
                value={
                  haveAnyValue(data.expirationDate) ? new Date(data.expirationDate) : null
                }
                onDayChange={(date: Date) =>
                  setData({ ...data, expirationDate: date?.valueOf() })
                }
                rangePicker={{}}
                error={errors.date}
              />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <Select
                disabled={disabled}
                label={'Punto de venta o sucursal'}
                value={
                  data.pos ? { value: data.pos.id, label: data.pos.name } : defaultOption
                }
                options={[defaultOption, ...pos]}
                onChange={(value: IPOS) => setData({ ...data, pos: value })}
                testId={'cd-pos'}
              />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <FormText
                disabled={disabled}
                required
                label={'Código de Autorización de Impresión'}
                value={data.number}
                id={'number'}
                onChange={handleChange}
                error={errors.number}
              />
            </Col>

            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <FormText
                disabled={disabled}
                required
                label={'Número inicial'}
                value={data.rangeInitial}
                id={'rangeInitial'}
                type={'text'}
                onChange={handleChange}
                error={errors.initial}
                data-testid={'cd-initial'}
              />
            </Col>

            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <FormText
                disabled={disabled}
                required
                label={'Número final'}
                value={data.rangeFinal}
                id={'rangeFinal'}
                type={'text'}
                onChange={handleChange}
                error={errors.final}
                data-testid={'cd-final'}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              dataCy={'cd-button-close'}
              disabled={loading}
              color={'secondary'}
              icon={faWindowClose}
              onClick={() => onHide()}>
              Cerrar
            </Button>
            <Button
              dataCy={'cd-button-confirm'}
              disabled={disabled}
              loading={loading}
              icon={isUpdate ? faEdit : faSave}
              onClick={() => onValidate()}>
              {isUpdate ? 'Actualizar' : 'Crear'}
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default CorrelativeDetail
