import { $http } from './http'

class ModulesService {
  getRolesByModule = async module => {
    const response = await $http.get(`/modules/${module}/roles`)
    return response.data
  }

  getUsersChildren = async (module, includeParent) => {
    const response = await $http.get(
      `/modules/${module}/users/children?includeParent=${includeParent}`,
    )
    return response.data
  }

  getRolesByUser = async (module, user) => {
    const response = await $http.get(`/modules/${module}/users/${user}/roles`)
    return response.data
  }

  getAllUsersRoles = async module => {
    const response = await $http.get(`/modules/${module}/users/roles`)
    return response.data
  }

  putPermissionsByUser = async (module, user, role) => {
    const response = await $http.put(`/modules/${module}/users/${user}/roles/${role}`)
    return response.data
  }

  deletePermissionsByUser = async (module, user, role) => {
    const response = await $http.delete(`/modules/${module}/users/${user}/roles/${role}`)
    return response.data
  }

  getSubmodules = async id => {
    const response = await $http.get(`/modules/${id}/submodules`)
    return response.data
  }

  getGroups = async id => {
    const response = await $http.get(`/modules/${id}/submodules/group`)
    return response.data
  }

  getSubmoduleRoles = async (module, subModule) => {
    const response = await $http.get(`/modules/${module}/submodules/${subModule}/roles`)
    return response.data
  }

  getUsersByCompanyAndRole = async (companyId, roleId) => {
    const response = await $http.get(`/modules/search/roles/${companyId}/${roleId}`)
    return response.data
  }

  getUsersByRole = async roleId => {
    const response = await $http.get(`/modules/search/roles/${roleId}`)
    return response.data
  }

  getCurrentCompanyUsers = async () => {
    const response = await $http.get(`/modules/company/users`)
    return response.data
  }

  getUsersByCompany = async companyId => {
    const response = await $http.get(`/modules/company/${companyId}/users`)
    return response.data
  }

  getCurrentUserRoles = async module => {
    const response = await $http.get(`/modules/${module}/roles/detailed`)
    return response.data
  }

  getModulesForAdmin = async () => {
    const response = await $http.get(`/modules/admin`)
    return response.data
  }

  toogleModuleForAdmin = async id => {
    const response = await $http.put(`/modules/admin/toogle/${id}`)
    return response.data
  }

  getUsersInSubmodule = async submodules => {
    const response = await $http.post(`/modules/submodule/users`, submodules)
    return response.data
  }

  getAllAppModules = async () => {
    const response = await $http.get('/modules')
    return response.data
  }

  addOrRevokeMultipleRoles = async (userId, permissions, add) => {
    const response = await $http.put(`/modules/user/${userId}`, permissions, {
      params: { add },
    })
    return response.data
  }

  getAllGroups = async () => {
    const response = await $http.get(`/modules/group/role`)
    return response.data
  }

  getGroupRoles = async id => {
    const response = await $http.get(`/modules/group/role/${id}`)
    return response.data
  }

  createGroup = async request => {
    const response = await $http.post(`/modules/group/role`, request)
    return response.data
  }

  updateGroup = async (id, request) => {
    const response = await $http.put(`/modules/group/role/${id}`, request)
    return response.data
  }

  deleteGroup = async id => {
    const response = await $http.delete(`/modules/group/role/${id}`)
    return response.data
  }

  setFavorite = async id => {
    const response = await $http.put(`/modules/favorite/${id}`)
    return response.data
  }

  revokeFavorite = async id => {
    const response = await $http.delete(`/modules/favorite/${id}`)
    return response.data
  }
}

export default new ModulesService()
