import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useLocation } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'

import { FAB, Title, Card, Icon, TableV2, Select, SelectedDates } from 'src/components'

import {
  faCheckDouble,
  faClock,
  faEye,
  faSync,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons'

import { getUsersChildrenByModule } from 'src/actions/modules.actions'
import {
  isAllowed,
  selectCurrentUserRoles,
  selectUsers,
} from 'src/selectors/modules.selector'

import {
  actionTypes as warehouseActions,
  getTransfersTransformations,
} from 'src/actions/warehouse.actions'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  selectAllTransfers,
  selectTotalTransfer,
  selectWarehouseLite,
} from 'src/selectors/warehouse.selector'

import {
  formatDateFromMillis,
  formatHourFromMillis,
  getItemValue,
} from 'src/utils/formatters'
import ModalTransferDetail from '../Transfer/DetailTransfers/ModalTransferDetail'
import { selectCurrentUser } from 'src/selectors/user.selector'

import { getProductsForSelector } from 'src/actions/products.actions'
import {
  actionTypes as typesT,
  onCreateRecipeTransformation,
} from 'src/actions/transformation.actions'
import {
  handlerError,
  handlerSuccess,
  hasErrors,
  singleErrorSelector,
} from 'src/selectors/error.selector'
import { showAlert } from 'src/actions/alert.actions'
import useFormatTimeAgo from 'src/hooks/useFormatTimeAgo'

import FreeConversion from './RecipeV2/FreeConversion'

const everythingOption = { value: null, label: '- Todos -' }

const stateDefaultOption = { value: 1, label: 'Nuevas' }

const stateOptions = [
  everythingOption,
  stateDefaultOption,
  { value: 2, label: 'Rechazadas' },
  { value: 3, label: 'Terminadas' },
  { value: 4, label: 'Canceladas' },
  { value: 7, label: 'Anuladas' },
]

const ConversionsPage = ({ history, match }) => {
  const dispatch = useDispatch()

  const formatTimeAgo = useFormatTimeAgo(true)

  const location = useLocation()
  const kolo = location.pathname.includes('/kolo')
  const isProduction = location.pathname.includes('/producciones')

  const roles = useSelector(selectCurrentUserRoles)

  const users = useSelector(selectUsers)
  const user = useSelector(selectCurrentUser)

  const warehouses = useSelector(selectWarehouseLite)

  const transfers = useSelector(selectAllTransfers)
  const totalTransfers = useSelector(selectTotalTransfer)

  const loadingTransfers = useSelector(state =>
    loadingSelector([warehouseActions.GET_TRANSFERS])(state),
  )

  const canCreate = useSelector(state => isAllowed(state, [12501]))

  const [showDetailConversion, setShowDetailConversion] = useState(false)

  const [loading, setLoading] = useState(true)
  const [textFilter, setTextFilter] = useState('')
  const [filters, setFilters] = useState({
    warehouse: everythingOption,
    state: stateDefaultOption,
    search: '',
    skip: 0,
    size: 10,
    recolect: false,
    user: everythingOption,
    start: null,
    end: null,
    getTotal: true,
  })

  const [resume, setResume] = useState({ show: false, id: null })

  const loadingCT = useSelector(state =>
    loadingSelector([typesT.ON_CREATE_TRANSFORMATION])(state),
  )
  const hasErrorCreate = useSelector(state =>
    hasErrors([typesT.ON_CREATE_TRANSFORMATION])(state),
  )
  const errorT = useSelector(state =>
    singleErrorSelector([typesT.ON_CREATE_TRANSFORMATION])(state),
  )

  const [flags, setFlags] = useState({ create: false })

  useEffect(() => {
    dispatch(getUsersChildrenByModule(1000, true))
    dispatch(getProductsForSelector())
  }, [])

  useEffect(() => {
    if (loadingCT) setFlags({ ...flags, create: true })
    else if (flags.create) {
      setFlags({ ...flags, create: false })
      if (hasErrorCreate) {
        dispatch(
          showAlert({
            ...handlerError(
              errorT.response
                ? errorT.response.data.message
                : 'Ha ocurrido un error inesperado',
            ),
          }),
        )
      } else {
        dispatch(
          showAlert({
            ...handlerSuccess('Conversión creada satisfactoriamente'),
            onConfirm: () => {
              setUp()
            },
          }),
        )
      }
    }
  }, [loadingCT])

  useEffect(() => {
    if (!roles || roles.length <= 0) return
    setTextFilter(JSON.stringify(filters))
    setLoading(false)
  }, [roles])

  useEffect(() => {
    if (textFilter === '' || textFilter === JSON.stringify(filters)) return

    const oldFilters = JSON.parse(textFilter)

    filters.getTotal =
      oldFilters.skip === filters.skip && oldFilters.size === filters.size

    setTextFilter(JSON.stringify(filters))
  }, [filters])

  useEffect(() => {
    if (textFilter !== JSON.stringify(filters)) return
    setUp()
  }, [textFilter])

  const setUp = () => {
    dispatch(
      getTransfersTransformations(
        filters.start,
        filters.end,
        filters.skip,
        filters.size,
        kolo,
        filters.recolect,
        filters.state.value,
        filters.search,
        filters.warehouse.value,
        false,
        true,
        filters.user.value,
        null,
        false,
        filters.getTotal,
        null,
      ),
    )
  }

  const customFilter = () => {
    const { warehouse, state, user } = filters

    return (
      <div>
        <Row>
          <Col xl={12} lg={12} md={12}>
            <SelectedDates
              withOptionNull
              onDateChange={(start, end) => setFilters({ ...filters, start, end })}
            />
          </Col>

          <Col lg={6} md={6} sm={12} xs={12}>
            <Select
              label={'Por estado'}
              value={state}
              info={'Filtra las transferencias por estado'}
              options={stateOptions}
              onChange={value => setFilters({ ...filters, state: value })}
            />
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <Select
              label={'Por bodega'}
              value={warehouse}
              info={'Filtra las transferencias por la bodega seleccionada'}
              options={[{ value: null, label: '- Todas -' }, ...warehouses]}
              onChange={value => setFilters({ ...filters, warehouse: value })}
            />
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <Select
              label={'Por usuario'}
              value={user}
              info={'Filtra las transferencias por el usuario encargado de las bodegas'}
              options={[{ value: null, label: '- Todos -' }, ...users]}
              onChange={value => setFilters({ ...filters, user: value })}
            />
          </Col>
        </Row>
      </div>
    )
  }

  const headers = [
    { label: '', show: true, value: ['time'], type: 'text' },
    {
      label: 'Descripción',
      show: true,
      value: ['description'],
      type: 'text',
    },
    { label: 'Fecha', show: true, value: ['createdAt'], type: 'date' },
    { label: 'Código', show: true, value: ['number'], type: 'text' },
    {
      label: 'Desde',
      show: true,
      value: ['fromWarehouseData', 'name'],
      type: 'text',
    },
    { label: 'Hacia', show: true, value: ['toWarehouseText'], type: 'text' },
    { label: 'Estado', show: true, value: ['stateName'], type: 'text' },
  ]

  let storedMobile = localStorage.getItem(`production-${user.id}`)
  storedMobile = storedMobile
    ? JSON.parse(storedMobile)
    : {
        title: { value: ['number'], type: 'code' },
        subtitle: { value: ['time'], type: 'text' },
        complement1: { value: ['toText'], type: 'text' },
        complement2: { value: ['fromText'], type: 'text' },
      }

  const formattedItems = transfers.map(item => {
    const {
      stateId,
      createdAt,
      enabledAt,
      fromWarehouseData,
      toWarehouseData,
      reference,
    } = item

    return {
      ...item,
      time:
        stateId === 1
          ? formatTimeAgo(item.createdAt)
          : formatHourFromMillis(enabledAt - createdAt),
      fromText: fromWarehouseData ? fromWarehouseData.name : 'Varias Bodegas',
      toText: reference || toWarehouseData.name,
    }
  })

  const mobileItems = formattedItems.map(item => {
    const { id, time, description, createdAt, fromText, toText, number, stateName } = item

    return {
      id,
      title: getItemValue(item, storedMobile.title.value, storedMobile.title.type),
      subtitle: getItemValue(
        item,
        storedMobile.subtitle.value,
        storedMobile.subtitle.type,
      ),
      complement1: getItemValue(
        item,
        storedMobile.complement1.value,
        storedMobile.complement1.type,
      ),
      complement2: getItemValue(
        item,
        storedMobile.complement2.value,
        storedMobile.complement2.type,
      ),
      key: `production-${user.id}`,
      action: (
        <Icon
          icon={faEye}
          tooltip={'Ver detalle'}
          onClick={() => setResume({ show: true, id, title: 'Producción' })}
        />
      ),
      more: [
        { title: 'Código', info: number },
        { title: 'Estado', info: stateName },
        { title: 'Tiempo', info: time },
        { title: 'Desde', info: fromText },
        { title: 'Hacia', info: toText },
        { title: 'Fecha', info: formatDateFromMillis(createdAt) },
        { title: 'Descripción', info: description },
      ],
    }
  })

  return (
    <div>
      <Title
        title={'Manufacturas'}
        action
        actionTitle={'Conversión Libre'}
        dontHide
        onClick={() => {
          setShowDetailConversion(true)
        }}
      />

      <Card
        white
        title={'Historial'}
        button={<Icon icon={faSync} tooltip={'Actualizar'} onClick={() => setUp()} />}>
        <TableV2
          storageKey={`production-${user.id}`}
          headers={headers}
          loading={loading || loadingTransfers}
          items={formattedItems}
          total={totalTransfers}
          getPagination={(skip, size, search) =>
            setFilters({ ...filters, skip, size, search })
          }
          customFilter={customFilter()}
          handleChange={search => setFilters({ ...filters, search })}
          itemsMobile={mobileItems}
          renderRow={(item, index) => {
            const {
              time,
              description,
              stateId,
              createdAt,
              id,
              fromText,
              toText,
              number,
              stateName,
            } = item

            return (
              <tr
                className={'data'}
                key={index}
                onClick={() => {
                  setResume({ show: true, id, title: 'Producción' })
                }}>
                <td className={'mini'}>
                  {stateId === 1 ? (
                    <div className={'d-flex'}>
                      <Icon size={'2x'} icon={faClock} />
                      <div className={'ml-1'}>
                        <h6>{time}</h6>
                      </div>
                    </div>
                  ) : (
                    <Icon
                      size={'2x'}
                      color={stateId === 3 ? 'green' : 'red'}
                      icon={stateId === 3 ? faCheckDouble : faWindowClose}
                    />
                  )}
                </td>
                <td className={'text-left'}>{description}</td>
                <td className={'text-left'}>{formatDateFromMillis(createdAt)}</td>
                <td className={'text-left'}>{number}</td>
                <td className={'text-left'}>{fromText}</td>
                <td className={'text-left'}>{toText}</td>
                <td className={'text-left'}>{stateName}</td>
              </tr>
            )
          }}
        />
      </Card>

      <ModalTransferDetail
        size={'lg'}
        show={resume.show}
        onHide={() => setResume({ show: false, id: null })}
        id={resume.id}
        title={resume.title}
        isProduction={isProduction}
        onRefresh={() => {
          setResume({ show: false, id: null })
          setUp()
        }}
      />

      <FreeConversion
        show={showDetailConversion}
        onHide={() => setShowDetailConversion(false)}
        onCallBack={response => {
          setShowDetailConversion(false)
          dispatch(onCreateRecipeTransformation(response))
        }}
        fast
      />

      <FAB
        show={canCreate}
        onClick={() => {
          const route = `${match.path}/crear`
          history.push(route)
        }}
      />
    </div>
  )
}

export default ConversionsPage
