import './ManualVideos.scss'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Paragraph, Title } from 'src/components'
import { actionTypes, getAppVideos, getManuals } from 'src/actions/videos.actions'
import {
  formatDurationFromSeconds,
  formatShortDateFromMillis,
} from 'src/utils/formatters'
import { loadingSelector } from 'src/selectors/loading.selector'
import { ProgressBar } from 'react-bootstrap'
import { selectAppVideos, selectManuals } from 'src/selectors/videos.selector'

import placeholderImage from 'src/assets/images/newDesign/placeholder.svg'

const ManualVideos = props => {
  const dispatch = useDispatch()

  const manuals = useSelector(selectManuals)
  const manualsLoading = useSelector(state =>
    loadingSelector([actionTypes.GET_MANUALS])(state),
  )

  const videos = useSelector(selectAppVideos)
  const videosLoading = useSelector(state =>
    loadingSelector([actionTypes.GET_MANUALS])(state),
  )

  const apps = manuals.map(manual => manual.apps).flat()
  const app = apps.find(app => app.id === Number(props.match.params.id))

  useEffect(() => {
    if (!manuals.length) dispatch(getManuals())
  }, [])

  useEffect(() => {
    if (!app) return
    dispatch(getAppVideos(app.id))
  }, [manuals])

  return manualsLoading ? (
    <ProgressBar now={100} animated />
  ) : (
    <div className="manual-videos">
      <Card bodyClass="manual-videos-info" className="manual-videos-info-card">
        <div
          className="manual-videos-header"
          style={{
            backgroundImage: `url(${app?.url || placeholderImage})`,
            backgroundColor: '#bbf',
          }}
        >
          <div className="manual-videos-title">
            <Title title={app?.name || 'Cargando...'} />
          </div>
        </div>
        <div className="manual-videos-info-body">
          <Paragraph>{app?.description || 'Sin descripción'}</Paragraph>
          <div className="space-between">
            <Paragraph bold>Última actualización</Paragraph>
            <Paragraph bold>{formatShortDateFromMillis(app?.updatedAt || 0)}</Paragraph>
          </div>
          <div className="space-between">
            <Paragraph bold>Duración total</Paragraph>
            <Paragraph bold>{formatDurationFromSeconds(app?.duration || 0)}</Paragraph>
          </div>
        </div>
      </Card>
      <div className="manual-videos-list">
        {videos.map(video => (
          <Card key={video.id} bodyClass="video" className="video-card">
            <div className="video-info">
              <Paragraph dim size="small" className="video-date">
                Creado el {formatShortDateFromMillis(video.createdAt || 0)}
              </Paragraph>
              <Paragraph bold size="big" className="video-title">
                {video.title || ''}
              </Paragraph>
              <Paragraph className="video-description">
                {video.description || ''}
              </Paragraph>
            </div>
            <iframe
              title={video.title}
              src={video.urls.length && video.urls[0].url}
              frameBorder="0"
              allowFullScreen
            />
          </Card>
        ))}
        {videos.length === 0 && (
          <div className="manual-videos-empty">
            <Paragraph>No hay videos para este manual</Paragraph>
          </div>
        )}
      </div>
    </div>
  )
}

export default ManualVideos
