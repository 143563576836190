import React, {Component} from 'react';
import {connect} from "react-redux"

import Card from "../../../../components/cards/Card";
import {formatDateFromMillis, formatNumberWithCommas} from "../../../../utils/formatters";

import {Col, Container, Modal, Row} from "react-bootstrap";

import {actionTypes as types, cancelBill, getBills, getDocumentsType} from "../../../../actions/resolutions.actions";
import {selectBills, selectDocumentsTypes} from "../../../../selectors/resolutions.selector";

import {loadingSelector} from "../../../../selectors/loading.selector";
import Button from "../../../../components/buttons/Button";
import CollapsibleCard from "../../../../components/cards/collapsible-card/CollapsibleCard";
import {faFileAlt, faBan} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../../components/buttons/IconButton";
import SweetAlert from "sweetalert-react";
import { TableV2 } from '../../../../components';

class BillsByResolutions extends Component {
    state = {
        showInvoice: false,
        showConfirmation : false,
        showSuccess : false,
        invoiceId:0,
        invoice:{
            companyDetail:{},
            resolutionDetails:{},
            companyDetails:{},
            invoiceDetails:[],
            total:0
        }
    };

    componentDidMount() {
        this.props.getDocumentsType();
        this.props.getBills();
    };

    cancelBill = (id) =>{
        this.props.cancelBills(id);
    };

    componentWillReceiveProps(props) {
        const {loadingCancel} = this.props;
        if (loadingCancel && !props.loadingCancel) {
            this.setState({showSuccess: true});
            this.props.getBills();
        }
    };

    getHeaders = () => {
        return [
            
            {label: 'NIT', show: true, value: ['nit'], type: 'text' ,  className:'mini'},
            {label: 'Serie', show: true, value: ['series'], type: 'text' ,  className:'mini'},
            {label: 'Número', show: true, value: ['number'], type: 'text' ,  className:'mini'},
            {label: 'Estado', show: true, value: ['estadoC'], type: 'text' ,  className:'mini', custom:(item)=> item.estadoC = item.cancelled?'Anulada':'Emitida'},
            {label: 'Emitida', show: true, value: ['createdAt'], type: 'date' ,  className:'mini'},
            {label: 'Anular', show: true, value: ['expirationDate'], type: 'date' ,  className:'mini',
                custom: (item => <>
                    <IconButton icon={faFileAlt} tooltip={'Detalles'} onClick={() => { this.setState({ invoice: item }); this.setState({ showInvoice: true }); }} />
                    {item.cancelled ? '' :
                        <IconButton icon={faBan} tooltip={'Anular'} onClick={() => { this.setState({ showConfirmation: true }); this.setState({ invoiceId: item.id }) }} />
                    }</>)
            },
            {config:true, show: true, label:'', className:'mini center' }
        ]
    }

    getDocument = (id) => {
        const {documents} = this.props;
        let document = 'N/A';
        documents.filter((d) => d.value === id).map((d) => document = d.label);
        return document;
    };

    filter = (item) => {
        const {params} = this.props.match;
        return (item.resolution === parseInt(params.id))
    };

    render() {
        const {loading, bills} = this.props;
        const{showInvoice,invoice} = this.state;
        return (
            <div>
                <Row>
                    <Col md={4}>
                        <CollapsibleCard title={'Filtros'} startCollapsed={true}></CollapsibleCard>
                    </Col>
                    <Col>
                        <Card title={'Facturas'}>
                            <TableV2
                                headers={this.getHeaders()}
                                //dateFilter
                                loading={loading}
                                items={bills.filter(this.filter)}
                                mobileAuto
                                storageKey={`bills`}
                            />
                            {/* <PaginatedTable
                                headers={['NIT', 'Serie', 'Número', 'Estado', 'Emitida','Anular']}
                                dateFilter={true}
                                loading={loading}
                                renderRow={this.renderRow}
                                items={bills.filter(this.filter)}
                            /> */}
                        </Card>
                    </Col>
                </Row>

                <Modal
                    show={showInvoice}//showCashBox
                    size={'lg'}
                    centered
                    aria-labelledby={'contained-modal-title-vcenter'}
                    onHide ={() => this.setState({showInvoice: false})}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Orden</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container className="invoice">
                            <Row>
                                <Col className="text-center">
                                    <h3>{invoice?invoice.companyDetail.name:''}</h3>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="text-center">
                                    <h2>{invoice.companyDetail.name}</h2>
                                </Col>
                                <Col className="text-center">
                                    <h2>NIT: {invoice.companyDetail.nit}</h2>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="text-center">
                                    {invoice.companyDetail.address}
                                </Col>
                            </Row>

                            <Row>
                                {invoice.uuid?
                                    <Col className="text-center">Codigo de Autorizacion{invoice.uuid}</Col>:
                                    <Col className="text-center">Resolucion No. {invoice && invoice.resolutionDetails.number} de fecha {formatDateFromMillis(invoice.resolutionDetails.authorizationDate)}</Col>
                                }

                            </Row>
                            {invoice.uuid?'':
                                <Row>
                                    <Col className="text-center">Serie {invoice.resolutionDetails.series} Del {invoice.resolutionDetails.initialNumber} al {invoice.resolutionDetails.finalNumber}</Col>
                                </Row>
                            }

                            <Row className="text-center">
                                <Col>
                                    <h2>Orden No.{invoice.number} - {formatDateFromMillis(invoice.createdAt)}</h2>
                                </Col>
                            </Row>

                            <Row className="text-center">
                                <Col>
                                    Nombre: <br/> {invoice.name}
                                </Col>
                                <Col>
                                    Nit: <br/> {invoice.nit?invoice.nit:"C/F"}
                                </Col>
                            </Row>
                            <hr/>
                            <div style={{paddingLeft:10, paddingRight:10}}>
                                <Row>
                                    <Col><b>Cantidad</b></Col>
                                    <Col><b>Producto</b></Col>
                                    <Col><b>Total</b></Col>
                                </Row>
                                <hr/>
                                {invoice.invoiceDetails.map((value, index) => {
                                    return  <Row key={value.id}>
                                        <Col>{value.quantity}</Col>
                                        <Col>{value.description}</Col>
                                        <Col>{formatNumberWithCommas(value.amount)}</Col>
                                    </Row>
                                })}
                                <hr/>

                                <Row>
                                    <Col><h2>Total</h2></Col>
                                    <Col></Col>
                                    <Col>{formatNumberWithCommas(invoice.total)}</Col>
                                </Row>

                            </div>


                            <Row> <Col className="text-center">(Impuesto de Ventas Incluido)</Col> </Row>


                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Row className={'container-buttons'}>
                            <Button
                                onClick={() => this.setState({showInvoice: false})}>
                                Cerrar
                            </Button>
                        </Row>
                    </Modal.Footer>
                </Modal>
                <SweetAlert
                    show={this.state.showConfirmation}
                    title="Anular"
                    text="Desea Anular esta factura?(Esta operacion no es reversible)."
                    type={"warning"}
                    showCancelButton
                    onConfirm={() => {
                        this.cancelBill(this.state.invoiceId);
                    }}
                    onCancel={() => {
                        this.setState({showConfirmation: false});
                    }}
                />
                <SweetAlert
                    show={this.state.showSuccess}
                    title={"!Exito!"}
                    text={"Factura Anulada."}
                    type={"success"}
                    onConfirm={() => {
                        this.setState({showSuccess: false});
                        this.setState({invoiceId: 0});
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    bills: selectBills(state),
    bill: cancelBill(state),
    loadingCancel: loadingSelector([types.CANCEL_BILL])(state),
    loading: loadingSelector([types.GET_BILLS])(state),
    documents: selectDocumentsTypes(state)
});
const mapDispatchToProps = dispatch => ({
    getBills: () => dispatch(getBills()),
    cancelBills: (id) => dispatch(cancelBill(id)),
    getDocumentsType: () => dispatch(getDocumentsType())
});
export default connect(mapStateToProps, mapDispatchToProps)(BillsByResolutions);
