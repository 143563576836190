import React from 'react'
import IconButton from '../../buttons/IconButton'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

interface IProps {
  checked: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void

  changeValue?: (value: boolean) => void
  style?: object
  disabled?: boolean
  name?: string
  label?: string
  info?: string
  radio?: boolean
  topLabel?: boolean
  className?: string
}

const Checkbox: React.FC<IProps> = ({
  checked,
  onChange,
  changeValue,
  style,
  disabled,
  name,
  label,
  info,
  radio,
  topLabel,
  className = '',
}: IProps) => {
  const getLabel = marginLeft => (
    <div className={'d-flex'}>
      {info && (
        <IconButton
          placement={'right'}
          tooltip={info}
          color={'rgba(34, 96, 149, 0.75)'}
          style={{ marginLeft: 5 }}
          icon={faInfoCircle}
          size={'sm'}
        />
      )}
      <div className={marginLeft}>{label}</div>
    </div>
  )

  return (
    <label
      className={`checkbox-label ${className}`}
      style={{ marginRight: 3, marginTop: 3, ...style, fontSize: 13 }}>
      {topLabel && getLabel('ml-3')}
      <div className={'d-flex'}>
        <input
          disabled={disabled}
          type={radio ? 'radio' : 'checkbox'}
          name={name}
          checked={checked}
          onChange={e => {
            if (onChange) onChange(e)
            if (changeValue) {
              const { checked } = e.target
              changeValue(checked)
            }
          }}
        />
        {!topLabel && getLabel('ml-4')}
      </div>
    </label>
  )
}

export default Checkbox
