import { types } from '../actions/inventory.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  requests: [],
  totalRequests: 0,
  request: {},
  requestCreate: {},
  report: [],
  inventoryReport: {},
  items: [],
}

const InventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(types.ON_CREATE_REQUEST):
      return { ...state, requestCreate: extract(action.requestCreate, {}) }
    case successState(types.GET_CHANGE_REQUEST):
      return { ...state, request: extract(action.request, {}) }
    case successState(types.GET_CHANGE_REQUESTS):
      return {
        ...state,
        requests: extract(action.requests.list, []),
        totalRequests: extract(action.requests.total, 0),
      }
    case successState(types.GET_REPORT):
      return { ...state, report: extract(action.report, []) }
    case successState(types.GET_REPORT_ITEM):
      return { ...state, items: extract(action.report, []) }
    case successState(types.GET_REPORT_REQUEST):
      return { ...state, inventoryReport: extract(action.report, {}) }
    default:
      return state ? state : initialState
  }
}
export default InventoryReducer
