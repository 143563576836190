import { ChartType } from 'src/enums/ChartType'

function getChartConfig(type: ChartType, yAxisName: string) {
  return {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    responsive: true,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: true,
            color: '#EAECF2',
            drawBorder: false,
          },
          ticks: {
            fontColor: '#525252',
            fontFamily: 'Montserrat',
            beginAtZero: true,
          },
          barPercentage: 1.0,
          categoryPercentage: 0.5,
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: type === ChartType.BAR,
            drawBorder: false,
          },
          ticks: {
            display: !yAxisName,
            beginAtZero: true,
            callback: function (label) {
              return label > 999999
                ? label / 100000000 + 'M'
                : label > 999
                ? label / 1000 + 'k'
                : label
            },
          },
          barPercentage: 1.0,
          categoryPercentage: 0.5,
          scaleLabel: {
            display: yAxisName,
            labelString: yAxisName,
            fontColor: '#12A8BB',
          },
        },
      ],
    },
    plugins: {
      labels: [],
    },
  }
}

function getLineChartConfig() {
  return {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (label) {
              return label > 999999
                ? label / 100000000 + 'M'
                : label > 999
                ? label / 1000 + 'k'
                : label
            },
          },
        },
      ],
    },
    tooltips: {},
  }
}

export { getChartConfig, getLineChartConfig }
