import { $http } from './http'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { firebaseAuth } from '../utils/firebase'
import axios from 'axios'
import { API_URL } from '../settings/app.settings'

class ClientsService {
  getDeliverers = async role => {
    if (!role) role = 1003
    const response = await $http.get(`/modules/search/roles/${role}`)
    return response.data
  }

  getSellers = async role => {
    if (!role) role = 1002
    const response = await $http.get(`/modules/search/roles/${role}`)
    return response.data
  }

  getDeliverersByProducer = async () => {
    const response = await $http.get(`/modules/search/roles/2003`)
    return response.data
  }

  getSellersByProducer = async () => {
    const response = await $http.get(`/modules/search/roles/2002`)
    return response.data
  }

  updateMultipleClients = async request => {
    const response = await $http.put(`/clients/massive`, request)
    return response.data
  }

  loadClientsCSV = async (file, global) => {
    let formData = new FormData()
    formData.append('file', file)
    const response = await $http.post(
      `/clients/csv/old?global=${global ? `true` : 'false'}`,
      formData,
      { headers: { 'content-type': 'multipart/form-data' } },
    )
    return response.data
  }

  loadClients = async (clients, currentIndex) => {
    const response = await $http.post(`/clients/csv?index=${currentIndex}`, clients)
    return response.data
  }

  loadClientsSv = async (clients, currentIndex) => {
    const response = await $http.post(`/clients/csv/sv?index=${currentIndex}`, clients)
    return response.data
  }

  getAllClients = async (params, clientsId) => {
    const response = await $http.put(`/clients`, clientsId, { params })
    return response.data
  }

  getSingleClient = async id => {
    const response = await $http.get(`/clients/${id}`)
    return response.data
  }

  createClient = async request => {
    const response = await $http.post(`/clients`, request)
    return response.data
  }

  updateClient = async (id, request) => {
    const response = await $http.put(`/clients/${id}`, request)
    return response.data
  }

  getAllCotizapClients = async () => {
    const response = await $http.get(`/clients/global`) // CAMBIAR POR REQUESTS
    return response.data
  }

  getSomeCotizapClients = async query => {
    const response = await $http.get(`/clients/global?clientList=${query}`) // CAMBIAR POR REQUESTS
    return response.data
  }

  onCreateGlobalClient = async request => {
    const response = await $http.post('/clients/global', request)
    return response.data
  }

  getGlobalClient = async id => {
    const response = await $http.get(`/clients/global/${id}`)
    return response.data
  }

  onUpdateGlobalClient = async (request, id) => {
    const response = await $http.put(`clients/global/${id}`, request)
    return response.data
  }

  onCreateCompanyWithClient = async company => {
    const response = await $http.post(`clients/cotizap`, company)
    return response.data
  }

  searchCotizapClient = async code => {
    const response = await $http.get(`clients/global/code/${code}`)
    return response.data
  }

  onRequestInfo = async clients => {
    const response = await $http.post('clients/info/request', clients)
    return response.data
  }

  getRequestInfo = async clientId => {
    const response = await $http.get(`clients/info/request/${clientId}`)
    return response.data
  }

  onRequestResponse = async (clientId, status, requestedId) => {
    const response = await $http.post(
      `clients/info/request/update/${clientId}/${status}`,
      requestedId,
    )
    return response.data
  }

  getClientsByPolygonTransport = async (deliverer, vendor, polygon) => {
    const response = await $http.get(
      `polygons/clients/${deliverer}/${vendor}?polygon=${polygon}`,
    )
    return response.data
  }

  getCaptchaResponse = async secretKey => {
    const response = await $http.post(
      `https://www.google.com/recaptcha/api/siteverify?secret=6LdNcuMUAAAAAL5x3W26ADdm849j1yWBGm8UUCVw&response=${secretKey}`,
    )
    return response.data
  }

  softDelete = async id => {
    const response = await $http.delete(`clients/${id}`)
    return response.data
  }

  getHistoricalPayments = async params => {
    const response = await $http.get(`clients/historical`, { params })
    return response.data
  }

  getHistoricalLog = async params => {
    const response = await $http.get(`clients/historical/log`, { params })
    return response.data
  }

  changeStatus = async id => {
    const response = await $http.put(`clients/status/${id}`)
    return response.data
  }

  getReport = async (id, start, end) => {
    const response = await $http.get(`clients/report/${id}`, {
      params: { start, end },
    })
    return response.data
  }

  onChangeDefaulterStatus = async clientId => {
    const response = await $http.put(`clients/defaulter/${clientId}`)
    return response.data
  }

  getClientsSimple = async params => {
    const response = await $http.get(`clients/simple`, { params })
    return response.data
  }

  setClientViewersUsers = async (request, params) => {
    const response = await $http.put('clients/viewers', request, { params })
    return response.data
  }

  getArchivedClients = async () => {
    const response = await $http.get('clients/archived')
    return response.data
  }

  onHideClients = async (type, clients) => {
    const response = await $http.put(`clients/hide/${type}`, clients)
    return response.data
  }

  getClientLog = async (clientId, logType, referenceType, noSecured) => {
    const response = await $http.get(
      `${noSecured ? '/nsmain/' : '/'}clients/log/${clientId}`,
      { params: { logType, referenceType } },
    )
    return response.data
  }

  getLogReport = async (request, start, end, skip, size, search, refType) => {
    const response = await $http.put(`clients/log/report`, request, {
      params: { start, end, skip, size, search, refType },
    })
    return response.data
  }

  getSimpleLog = async id => {
    const response = await $http.get(`clients/log/simple/${id}`)
    return response.data
  }

  onUpdateDirtyClient = async (clientIds, params) => {
    const response = await $http.put('clients/dirty', clientIds, { params })
    return response.data
  }

  createClientLog = async (clientId, description, logType, checked, categorizations) => {
    const response = await $http.post(`clients/log/${clientId}`, {
      description,
      logType,
      checked,
      categorizations,
    })
    return response.data
  }

  getReportLog = async (start, end) => {
    const response = await $http.get(`clients/report`, {
      params: { start, end },
    })
    return response.data
  }

  mergeClients = async (pivot, list) => {
    const response = await $http.post(`clients/merge/${pivot}`, list)
    return response.data
  }

  clientStatusReport = async requests => {
    const response = await $http.put(`clients/compare/status`, requests)
    return response.data
  }

  getBalanceReport = async params => {
    const response = await $http.get(`/cotizap/reports/client/balance`, {
      params,
    })
    return response.data
  }

  getAllRequests = async params => {
    const response = await $http.get('/clients/request', { params })
    return response.data
  }

  getClientRequest = async (id, params) => {
    const response = await $http.get(`/clients/request/${id}`, { params })
    return response.data
  }

  updateStatusRequest = async (id, status) => {
    const response = await $http.put(`/clients/request/${id}`, status)
    return response.data
  }

  createClientEditRequest = async request => {
    const response = await $http.post(`/clients/request`, request)
    return response.data
  }

  downloadExcel = async (params, clientsId) => {
    const response = await $http.put(`/clients/excel`, clientsId, {
      responseType: 'blob',
      params,
    })

    const fileName = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : moment().format('LL') + ' - Clientes - .xlsx'

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    saveAs(file, fileName.replace('json', 'xlsx'))
  }

  updateItemLog = async (id, status) => {
    const response = await $http.put(`/clients/log/${id}`, status)
    return response.data
  }

  getClientViewersByClientId = async clientId => {
    const response = await $http.get(`/clients/viewers/${clientId}`)
    return response.data
  }

  getVouchersClient = async params => {
    const response = await $http.get(`/clients/vouchers`, { params })
    return response.data
  }

  getClientsWithVouchers = async () => {
    const response = await $http.get(`/clients/vouchers/clients`)
    return response.data
  }

  sendEmail = async (request, all) => {
    const response = await $http.put(`/clients/send/email`, request, {
      params: { all },
    })
    return response.data
  }

  getTransactionsClient = async (params, clientId) => {
    const response = await $http.get(`/clients/transactions/${clientId}`, {
      params,
    })
    return response.data
  }

  downloadTransactionsExcel = async (request, params) => {
    const response = await $http.put(`/clients/transactions/excel`, request, {
      responseType: 'blob',
      params,
    })

    const fileName = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : moment().format('LL') + ' - Transacciones - .xlsx'

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    saveAs(file, fileName.replace('json', 'xlsx'))
  }

  getLogExcelReport = async (request, params) => {
    console.log(params)
    const response = await $http.put(`/clients/log/excel/report`, request, {
      responseType: 'blob',
      params,
    })

    const fileName = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : moment().format('LL') + ' - Bitácora de clientes - .xlsx'

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    saveAs(file, fileName.replace('json', 'xlsx'))
  }

  getProductsClient = async (clientId, params) => {
    const response = await $http.get(`/clients/products/${clientId}`, {
      params,
    })
    return response.data
  }

  downloadProductsExcel = async (clientId, params) => {
    const response = await $http.get(`/clients/products/excel/${clientId}`, {
      params,
      responseType: 'blob',
    })

    const fileName = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : moment().format('LL') + ' - Productos del cliente - .xlsx'

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    saveAs(file, fileName.replace('json', 'xlsx'))
  }

  getClientByIdentification = async (params, signal) => {
    const response = await $http.get('/clients/information', {
      params,
      signal,
    })
    return response.data
  }

  findClientByPolygonPoints = async points => {
    const response = await $http.put('/clients/polygon', points)
    return response.data
  }

  setCategorizationMultiple = async (request, params) => {
    const response = await $http.put('/clients/categorization/multiple', request, {
      params,
    })
    return response.data
  }

  deleteContact = async id => {
    const response = await $http.delete(`/clients/contact/${id}`)
    return response.data
  }

  getClientContacts = async id => {
    const response = await $http.get(`/clients/contacts/${id}`)
    return response.data
  }

  lockClient = async (id, message) => {
    const response = await $http.put(`/clients/lock/${id}`, null, { params: { message } })
    return response.data
  }

  unlockClient = async id => {
    const response = await $http.put(`/clients/unlock/${id}`)
    return response.data
  }

  creditNotesExcel = async params => {
    const response = await $http.get('/clients/vouchers/excel', {
      params,
      responseType: 'blob',
    })
    const name = 'Notas de credito.xlsx'
    const file = new Blob([response.data], { type: 'application/vnd.ms-excel' })
    saveAs(file, name)
  }
}

export default new ClientsService()
