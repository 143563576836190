import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Col, Row } from 'react-bootstrap'

import Card from '../../components/cards/Card'

import { getAllNotification } from '../../actions/notifications.actions'
import { selectAllNotifications } from '../../selectors/notifications.selector'
import { formatDateFromMillis } from '../../utils/formatters'
import { TableV2, Title } from '../../components'

class Notifications extends Component {
  state = {
    from: new Date(),
    to: new Date(),
    alert: {
      show: false,
      title: 'default',
      text: 'default',
      type: 'info',
      onConfirm: () => console.log('default'),
    },
  }

  componentDidMount() {
    const { from, to } = this.state
    this.props.getAllNotifications(
      from.setHours(0, 0, 0).valueOf(),
      to.setHours(23, 59, 59).valueOf(),
    )
  }

  onDateFiltersChanged = (from, to) => {
    this.props.getAllNotifications(
      from.setHours(0, 0, 0).valueOf(),
      to.setHours(23, 59, 59).valueOf(),
    )
  }

  handleCheckBoxChange = event => {
    const { name, checked } = event.target
    this.setState({ [name]: checked })
  }

  handleInputChange = event => {
    const { newRegister } = this.state
    newRegister.amount = event.target.value
    this.setState({ newRegister })
  }

  getHeaders = () => {
    return [
      {
        label: 'Responsable',
        show: true,
        type: 'text',
        className: 'medium',
        custom: item => {
          const values = item.body?.split(':')
          return values?.length > 1 ? values[0] : '---'
        },
      },
      {
        label: 'Compania',
        show: true,
        type: 'text',
        className: 'medium',
        custom: item => {
          const values = item.head?.split(':')
          return values.length > 1 ? values[0] : '---'
        },
      },
      { label: 'Fecha', show: true, value: ['date'], type: 'date', className: 'medium' },
      { label: 'Título', show: true, value: ['head'], type: 'text', className: 'medium' },
      { label: 'Cuerpo', show: true, value: ['body'], type: 'text', className: 'medium' },
      { config: true, show: true, label: '', className: 'mini center' },
    ]
  }
  render() {
    const { from, to } = this.state
    const { notifications } = this.props
    return (
      <div>
        <Title title={'Notificaciones'} />
        <Row>
          <Col md={12}>
            <Card title={'Historial de notificaciones'} white>
              <TableV2
                headers={this.getHeaders()}
                items={notifications.sort((a, b) => b.date - a.date)}
                dateFilter={true}
                onDateChange={this.onDateFiltersChanged}
                dateFrom={from}
                dateTo={to}
                mobileAuto
                storageKey={`notificacions`}
              />
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  notifications: selectAllNotifications(state),
})

const mapDispatchToProps = dispatch => ({
  getAllNotifications: (sDate, eDate) => dispatch(getAllNotification(sDate, eDate)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
