import React, {Component} from 'react';
import {connect} from "react-redux";

import {Row, Col, ButtonGroup, DropdownButton, Dropdown} from "react-bootstrap";
import Alert from "sweetalert-react";

import {
    formatDateFromMillis,
    formatNumberWithCommas,
    getItemValue
} from "../../../utils/formatters";


import { TableV2, Card, Select, Dropdown  as DropdownCustom } from '../../../components';

import {actionTypes as typesD, getDisbursementsAdmin, updateDisbursementAdmin, approveDisbursementAdmin} from "../../../actions/disbursements.actions";
import {selectDisbursementsAdmin} from "../../../selectors/disbursements.selector";

import {loadingSelector} from "../../../selectors/loading.selector";
import {hasErrorsSelector, singleErrorSelector, handlerError, handlerSuccess} from "../../../selectors/error.selector";

class DisbursementsAdmin extends Component {

    state = {
        company: {value: 0, label: 'Todos'},
        alert: {title: 'text'}
    };

    componentDidMount() {
        this.props.getDisbursements({});
    };

    componentWillReceiveProps(next) {
        const {loadingU, hasErrorU, getDisbursements} = this.props;
        const {errorU} = next;
        let alert = {title: 't'};

        if (loadingU && !next.loadingU) {
            if (!hasErrorU && next.hasErrorU) {
                alert = handlerError(errorU.message || 'Ha ocurrido un error inesperado.');
                alert.onConfirm = () => this.setState({alert: {...alert, show: false}});
            } else {
                getDisbursements({});
                alert = handlerSuccess('Solicitud actualizada satisfactoriamente');
                alert.onConfirm = () => this.setState({alert: {...alert, show: false}});
            }
            this.setState({alert});
        }

        const {loadingP, hasErrorP} = this.props;
        const {errorP} = next;

        if (loadingP && !next.loadingP) {
            if (!hasErrorP && next.hasErrorP) {
                alert = handlerError(errorP.message || 'Ha ocurrido un error inesperado');
                alert.onConfirm = () => this.setState({alert: {...alert, show: false}});
            } else {
                getDisbursements({});
                alert = handlerSuccess('Solicitud aprovada satisfactoriamente');
                alert.onConfirm = () => this.setState({alert: {...alert, show: false}});
            }
            this.setState({alert});
        }
    };

    validate = (status, request) => {
        let alert = {
            show: true,
            title: status === 5 ? 'Aprobar solicitud' :'Actualiza el estado de la solicitud',
            text: status === 5 ? 'Ingrese el número de documento.' :'Ingrese una descripción o concepto',
            type: 'input',
            inputPlaceholder: status === 5 ? 'pj. ABC1231' :'Estado de la solicitud',
            confirmButtonText: 'Guardar',
            confirmButtonColor: '#22B573',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            onConfirm: (description) => {
                if (!description) return;
                this.props.update(request, status, description);
                this.setState({alert: {title: 'title'}});
            },
            onCancel: () => this.setState({alert: {title: 'title'}})
        };
        this.setState({alert});
    };

    getHeaders = () => { 
       
        return [
            {label: 'Empresa', show: true, value: ['company','name'], type: 'text' ,  className:'mini'},
            {label: 'Fecha', show: true, value: ['createdAt'], type: 'date' ,  className:'mini'},
            {label: 'Cantidad', show: true, value: ['amount'], type: 'currency' ,  className:'mini'},
            {label: 'Estado', show: true, value: ['statusData','name'], type: 'text' ,  className:'medium'},
            {label: 'Descripción', show: true, value: ['statusDesc'], type: 'text' ,  className:'medium'},
            {show: true, label:  '', className:"mini", config:true, custom:(item) => item.status === 1 || item.status === 2 && <DropdownCustom
            items={[
                {
                    title: 'Procesar',
                    show: item.status === 1 ,
                    action: () => this.validate(2, item.id)
                },
                {
                    title: 'Problema',
                    show: (item.status === 1 || item.status === 2) ,
                    action: () => this.validate(3, item.id)
                },
                {
                    title: 'Rechazar',
                    show: item.status === 1 ,
                    action: () => this.validate(4, item.id)
                },
                {
                    title: 'Procesar',
                    show: item.status === 2,
                    action: () => this.validate(5, item.id)
                }
            ]}
        />}
        ]
    }


    getItemsMobile = (data) => {
        let array = []
        const headers = this.getHeaders()
        let store = localStorage.getItem(`disbursAdmin`)
        if(!store) store={title: {value: ['company','name'], type: 'text'}, subtitle: {value: ['amount'], type: 'currency'},
            complement1: {value:['statusData','name'], type: 'text'}, complement2: {value: ['createdAt'], type: 'date'}}
        else store = JSON.parse(store)
        data.map((item, i) => {
            let more = []
            let custom 
            headers.map((header) => {
                if (header.config) {custom = header.custom; return}
                if (header.custom && header.value) more.push({title: header.label , info: header.custom(item)})
                else more.push({title: header.label , info: getItemValue(item, header.value, header.type)})
            })

            let object = {id: item.id, title: getItemValue(item, store.title.value, store.title.type) ,
                subtitle: getItemValue(item, store.subtitle.value, store.subtitle.type),
                complement1: getItemValue(item, store.complement1.value, store.complement1.type),
                complement2: getItemValue(item, store.complement2.value, store.complement2.type),
                //selected: selected.filter(d => d.id === item.id).length > 0,
                key: `disbursAdmin-${item.id}`, action: custom(item), more
            }
            array.push(object)
        })
        return array;
    }

    render() {

        const {company, alert} = this.state;
        const {disbursements, loadingD} = this.props;

        return (
            <div>
                <h1 className={'dashboard-welcome'}>Solicitudes de Desembolsos</h1>
                <Row>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Card title={'Filtros'}>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Select
                                        label={'Empresa'}
                                        value={company}
                                        options={[{value: 0, label: 'Todos'}]}
                                        placeholder={'Selecciona...'}
                                        onChange={(d) => this.setState({company: d})}
                                    />
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}></Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col>
                        <Card title={'Historial Solicitudes realizadas'}
                              bodyStyle={{padding: 10}}>
                                  <TableV2
                                    customClass={'scrollX'}
                                    storageKey={'disbursAdmin'}
                                    headers={this.getHeaders()}
                                    items = {disbursements}
                                    noItemsLegend={'No hay movimientos para mostrar'}
                                    loading={loadingD}
                                    itemsMobile = {this.getItemsMobile(disbursements)}

                                  />
                           
                        </Card>
                    </Col>
                </Row>

                <Alert {...alert}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    disbursements: selectDisbursementsAdmin(state),
    loadingD: loadingSelector([typesD.GET_DISBURSEMENTS_ADMIN])(state),
    loadingU: loadingSelector([typesD.UPDATE_SINGLE_DISBURSEMENT_ADMIN])(state),
    hasErrorU: hasErrorsSelector([typesD.UPDATE_SINGLE_DISBURSEMENT_ADMIN])(state),
    errorU: singleErrorSelector([typesD.UPDATE_SINGLE_DISBURSEMENT_ADMIN])(state),
    loadingP: loadingSelector([typesD.APPROVE_SINGLE_DISBURSEMENT_ADMIN])(state),
    hasErrorP: hasErrorsSelector([typesD.APPROVE_SINGLE_DISBURSEMENT_ADMIN])(state),
    errorP: singleErrorSelector([typesD.APPROVE_SINGLE_DISBURSEMENT_ADMIN])(state)
});
const mapDispatchToProps = dispatch => ({
    getDisbursements: (filters) => dispatch(getDisbursementsAdmin(filters)),
    update: (request, status, description) => dispatch(updateDisbursementAdmin(request, status, description)),
    approve: (request, documentId) => dispatch(approveDisbursementAdmin(request, documentId))
});
export default connect(mapStateToProps, mapDispatchToProps)(DisbursementsAdmin);
