import './Permissions.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Card from '../../../components/cards/Card'
import Button from '../../../components/buttons/Button'
import FilterInput from '../../../components/inputs/FilterInput/FilterInput'

import NewUser from '../newUser/NewUser'

import SweetAlert from 'sweetalert-react'
import { Row, Col, Modal } from 'react-bootstrap'

import {
  actionTypes,
  getAllGroups,
  getRolesByUserChild,
  getUsersChildrenByModule,
} from '../../../actions/modules.actions'
import {
  isAllowed,
  selectorGroups,
  selectRolesByUser,
  selectUsers,
} from '../../../selectors/modules.selector'

import {
  actionTypes as types,
  createNewUser,
  searchUserByEmail,
  updateDescription,
} from '../../../actions/user.actions'
import {
  selectCurrentUser,
  selectCreateResponse,
  selectSearchResponse,
  selectCurrentModule,
} from '../../../selectors/user.selector'

import { getPermission } from '../../../selectors/modules.selector'

import { loadingSelector } from '../../../selectors/loading.selector'
import { hasErrorsSelector, singleErrorSelector } from '../../../selectors/error.selector'
import {
  Action,
  Title,
  Icon as IconButton,
  Dropdown,
  FAB,
  TableV2,
  FormText,
} from '../../../components'
import { faEllipsisV, faEye, faFileAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import { history } from '../../../App'

import PermissionGroup from '../UpdatePermissions/PermissionGroup'
import { userPermissions } from 'src/enums/permissions'

class index extends Component {
  state = {
    show: false,
    email: '',
    alert: {
      show: false,
      title: 'Default',
      text: 'Default',
      type: 'success',
    },
    resultUser: null,
    modalLog: { id: null, show: false },
    group: { show: false, groupId: null, roles: [] },
    modalDescription: { show: false, value: null, userId: null, name: null },
  }

  componentDidMount() {
    const { module } = this.props.match.params
    const { user } = this.props
    this.props.getUsersChildrenByModule(module, false)
    this.props.getAllGroups()
    this.props.getRolesByUserChild(module, user.id)
  }

  UNSAFE_componentWillReceiveProps(next) {
    const {
      createResponse,
      createIsLoading,
      createHasError,
      createError,
      searchResponse,
      searchIsLoading,
      searchHasError,
      searchError,
      loadingUpdate,
    } = this.props
    let { alert, show, resultUser } = this.state
    if (createIsLoading && !next.createIsLoading) {
      if (!createHasError && next.createHasError) {
        show = true
        alert.show = true
        alert.title = '¡Uh-Oh!'
        alert.text =
          createError.message ||
          'Error en los datos trasmitidos, por favor revise e intente de nuevo.'
        alert.type = 'error'
        resultUser = null
        this.setState({ alert, show, resultUser })
      } else if (createResponse !== next.createResponse) {
        const { isNewUser, user, password } = next.createResponse
        if (isNewUser) {
          show = false
          alert.show = true
          alert.title = 'Satisfactorio'
          alert.text = `El usuario ${user.email} fue creado satisfactoriamente. Su contraseña es: ${password}`
          alert.type = 'success'
          resultUser = user
        } else {
          show = true
          alert.show = true
          alert.title = `Ya registrado`
          alert.text = `La dirección de correo electrónico que ha ingresado ya está registrado.`
          alert.type = 'warning'
          resultUser = null
        }
        this.setState({ alert, show, resultUser })
      }
    }
    if (searchIsLoading && !next.searchIsLoading) {
      if (!searchHasError && next.searchHasError) {
        alert.show = true
        alert.title = '¡Uh-Oh!'
        alert.text =
          searchError.message ||
          'Error en los datos trasmitidos, por favor revise e intente de nuevo.'
        alert.type = 'error'
        resultUser = null
        this.setState({ alert, show, resultUser })
      } else if (searchResponse !== next.searchResponse) {
        if (next.searchResponse) {
          const { module } = this.props.match.params
          this.props.history.push(
            `${module}/${next.searchResponse.id}/${next.searchResponse.name}`,
          )
        } else {
          alert.show = true
          alert.title = `No registrado`
          alert.text = `La dirección de correo electrónico que ha ingresado no esta registrado.`
          alert.type = 'warning'
          resultUser = null
        }
        this.setState({ alert, resultUser })
      }
    }

    if (loadingUpdate && !next.loadingUpdate) {
      const { module } = this.props.match.params
      this.setState({ modalDescription: { show: false } })
      this.props.getUsersChildrenByModule(module, false)
    }
  }

  setDefaultState = () => {
    let { alert } = this.state
    alert.show = false
    this.setState({ alert })
  }

  handleClick = item => {
    const { module } = this.props.match.params
    this.props.history.push(`${module}/${item.id}/${item.name}`)
  }

  onChange = value => {
    this.setState({ email: value })
  }

  save = user => {
    return this.props.createNewUser(user)
  }

  search = () => {
    let { email, alert, resultUser } = this.state
    if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      alert.show = true
      alert.title = `Invalido`
      alert.text = `La dirección de correo electrónico que ha ingresado es invalida.`
      alert.type = 'warning'
      resultUser = null
      this.setState({ alert, resultUser })
    } else this.props.searchUser(email)
  }

  setSubModules = () => {
    this.setDefaultState()
    const { module } = this.props.match.params
    const { resultUser } = this.state
    this.props.history.push(`${module}/${resultUser.id}/${resultUser.name}`)
  }

  button = () => {
    const { module } = this.props
    return (
      <IconButton
        tooltip={'Ver como matriz'}
        icon={faEye}
        onClick={() => {
          history.push(`${module}/matriz`)
        }}
      />
    )
  }

  render() {
    const { users, getPermission, roles, groups } = this.props
    const { module } = this.props.match.params
    const { id, name } = this.props.currentUsers
    const { alert, group, modalDescription } = this.state

    const admin = getPermission(1001) || getPermission(2001)
    const createGroup = getPermission(1452) || getPermission(2352)
    const editGroup = getPermission(1453) || getPermission(2353)
    const deleteGroup = getPermission(1454) || getPermission(2354)

    const headers = [
      { label: 'ID', show: true, value: ['id'], type: 'text', className: 'mini' },
      { label: 'Nombre', show: true, value: ['name'], type: 'text', className: 'medium' },
      {
        label: 'Descripción',
        show: true,
        value: ['description'],
        type: 'text',
        className: 'medium',
      },
      { label: 'Email', show: true, value: ['email'], type: 'text', className: 'medium' },
    ]
    if (admin) {
      headers.push({
        label: 'Max cpc',
        show: true,
        value: ['maxCpc'],
        type: 'wholeNumber',
        className: 'mini',
      })
    }
    headers.push({
      config: true,
      show: true,
      label: '',
      className: 'mini center',
      custom: item => (
        <Dropdown
          items={[
            { title: 'Ver permisos', action: () => this.handleClick(item) },
            {
              show: this.props.canUpdateDescription,
              title: 'Actualizar descripción',
              action: () =>
                this.setState({
                  modalDescription: { show: true, userId: item.id, name: item.name },
                }),
            },
            {
              show: false,
              title: 'Ver bitácora del usuario',
              action: () => this.setState({ modalLog: { id: item.id, show: true } }),
            },
          ]}
        />
      ),
    })

    return (
      <div>
        <FAB
          show
          icon={createGroup || editGroup || deleteGroup ? faEllipsisV : faUser}
          tooltip={'Mis Permisos'}
          onClick={() =>
            createGroup || editGroup || deleteGroup
              ? ''
              : history.push(`${module}/${id}/${name}`)
          }
          items={[
            {
              style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
              text: 'Set de Permisos',
              icon: faFileAlt,
              action: () => {
                this.setState({ group: { menu: true } })
              },
              show: createGroup || editGroup || deleteGroup,
            },
            {
              style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
              text: 'Mis Permisos',
              icon: faUser,
              action: () => {
                history.push(`${module}/${id}/${name}`)
              },
              show: createGroup || editGroup || deleteGroup,
            },
          ]}
        />
        <Title title={'Administración de Permisos'} />
        <Row>
          {module === '11000' && (
            <Col md={4}>
              <Card title={'Filtro'}>
                <FilterInput onChange={this.onChange} />
                <Row className={'container-buttons'}>
                  <Button color={'primary'} onClick={() => this.search()}>
                    Buscar
                  </Button>
                </Row>
              </Card>
            </Col>
          )}

          <Col>
            <Action
              action
              onClick={() => this.setState({ show: true })}
              actionTitle={'Crear nuevo usuario'}
            />
            <Card title={'Usuarios'} button={this.button()} white>
              <TableV2
                loading={this.props.loadingGet}
                headers={headers}
                items={users}
                mobileAuto
                storageKey={`permissions`}
              />
            </Card>
          </Col>
        </Row>

        <Modal
          show={this.state.show}
          size={'lg'}
          centered
          onHide={() => this.setState({ show: false })}>
          {' '}
          <Modal.Header closeButton>
            <Modal.Title>Nuevo usuario</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <NewUser save={this.save} />
          </Modal.Body>
        </Modal>

        <SweetAlert
          show={alert.show}
          title={alert.title}
          text={alert.text}
          type={alert.type}
          onConfirm={() =>
            alert.type === 'success' ? this.setSubModules() : this.setDefaultState()
          }
        />

        <Modal
          show={group.menu}
          size={'lg'}
          centered
          onHide={() => this.setState({ group: { menu: false } })}>
          {' '}
          <Modal.Header closeButton>
            <Modal.Title>Set de permisos</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TableV2
              headers={[
                {
                  label: '',
                  show: true,
                  value: [],
                  type: 'text',
                  classNameCustom: item => `mini ${item.public ? 'kolo_blue' : ''}`,
                  select: true,
                  custom: () => '',
                },
                {
                  label: 'Nombre',
                  show: true,
                  value: ['name'],
                  type: 'text',
                  className: 'mini',
                },
                {
                  label: 'Descripción',
                  show: true,
                  value: ['description'],
                  type: 'text',
                  className: 'medium',
                },
                {
                  config: true,
                  show: true,
                  label: '',
                  className: 'mini center',
                  custom: item => (
                    <IconButton
                      icon={faEye}
                      tooltip={'Ver set de permisos'}
                      onClick={() =>
                        this.setState({ group: { show: true, groupId: item.id } })
                      }
                    />
                  ),
                },
              ]}
              items={groups}
              mobileAuto
              storageKey={`permissionsSet`}
            />
          </Modal.Body>
          <Modal.Footer>
            {createGroup && (
              <Button onClick={() => this.setState({ group: { show: true } })}>
                Crear
              </Button>
            )}
          </Modal.Footer>
        </Modal>

        <PermissionGroup
          roles={roles}
          show={group.show}
          groupId={group.groupId}
          edit={editGroup || (!group.groupId && createGroup)}
          canDelete={deleteGroup}
          onHide={() => {
            this.setState({ group: { show: false, groupId: null, menu: true } })
            this.props.getAllGroups()
          }}
        />

        <Modal
          show={modalDescription.show}
          size={'xs'}
          centered
          onHide={() => this.setState({ modalDescription: { show: false } })}>
          <Modal.Header closeButton>
            <Modal.Title>Actualizar descripción {modalDescription.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormText
              value={modalDescription.value}
              onChange={({ target }) => {
                let { value } = target
                this.setState({
                  modalDescription: { ...modalDescription, value },
                })
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              loading={this.props.loadingUpdate}
              onClick={() => this.setState({ modalDescription: { show: false } })}>
              Cancelar
            </Button>
            <Button
              loading={this.props.loadingUpdate}
              onClick={() => {
                this.props.updateDescription(
                  modalDescription.userId,
                  modalDescription.value,
                )
              }}>
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  users: selectUsers(state),
  roles: selectRolesByUser(state),
  currentUsers: selectCurrentUser(state),
  createResponse: selectCreateResponse(state),
  searchResponse: selectSearchResponse(state),
  loadingGet: loadingSelector([actionTypes.GET_USERS_CHILDREN])(state),
  createIsLoading: loadingSelector([types.CREATE_NEW_USER])(state),
  createHasError: hasErrorsSelector([types.CREATE_NEW_USER])(state),
  createError: singleErrorSelector([types.CREATE_NEW_USER])(state),
  searchIsLoading: loadingSelector([types.SEARCH_USER_BY_EMAIL])(state),
  searchHasError: loadingSelector([types.SEARCH_USER_BY_EMAIL])(state),
  searchError: loadingSelector([types.SEARCH_USER_BY_EMAIL])(state),
  getPermission: id => getPermission(state, id),
  module: selectCurrentModule(state),
  user: selectCurrentUser(state),
  groups: selectorGroups(state),
  loadingUpdate: loadingSelector([types.UPDATE_USER])(state),
  canUpdateDescription: isAllowed(state, [userPermissions.updateDescription]),
})
const mapDispatchToProps = dispatch => ({
  getUsersChildrenByModule: (module, includeParent) =>
    dispatch(getUsersChildrenByModule(module, includeParent, true)),
  getRolesByUserChild: (module, id) => dispatch(getRolesByUserChild(module, id)),
  createNewUser: user => dispatch(createNewUser(user)),
  searchUser: email => dispatch(searchUserByEmail(email)),
  getAllGroups: () => dispatch(getAllGroups()),
  updateDescription: (id, description) => dispatch(updateDescription(id, description)),
})
export default connect(mapStateToProps, mapDispatchToProps)(index)
