import { executeAction } from './global.actions'
import TokensService from '../services/tokens.service'

export const actionTypes = {
  GENERATE_TOKEN: 'GENERATE_TOKEN',
}

export const generateToken = type => dispatch => {
  const process = async () => ({
    token: await TokensService.generateToken(type),
  })
  dispatch(executeAction(actionTypes.GENERATE_TOKEN, process))
}
