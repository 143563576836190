import { formattedNumberToString, haveAnyValue } from 'src/utils/utilitiesV2'
import felDocumentTypes from 'src/enums/felDocumentTypes'

const getDocumentType = (docType: number): IFelDocumentType => {
  if (!haveAnyValue(docType)) return { label: 'Sin seleccionar', value: null }
  return felDocumentTypes.find((dt: IFelDocumentType) => dt.value === docType)
}

/**
 * Format correlative params to a long string
 * @param code
 * @param range
 * @param documentType
 * @param number
 * @returns string
 * */
const createNumberFormat = (
  code: string,
  range: number,
  documentType: number,
  number: number,
): string => {
  return `${code}-${formattedNumberToString(range, 3)}-${formattedNumberToString(
    documentType,
    2,
  )}-${formattedNumberToString(number, 8)}`
}

export { getDocumentType, createNumberFormat }
