import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setModalPendingPayments } from 'src/actions/utilities.actions'
import { TemplateNotification } from 'src/content/Notifications/TemplateNotification'
import { selectModalPendingPayment } from 'src/selectors/utilities.selector'
import Button from '../buttons/Button'

/**
 * Componente que muestra un aviso de pago pendiente
 */
const PaymentNotice = () => {
  const dispatch = useDispatch()
  const pendingPaymentNotification = useSelector(selectModalPendingPayment)
  const now = new Date()
  const firstText = now.getMinutes() >= 0 && now.getMinutes() <= 30
  return pendingPaymentNotification.active ? (
    <TemplateNotification
      onClose={() => {
        const key = pendingPaymentNotification.key
        localStorage.setItem(
          key,
          JSON.stringify({
            time: new Date().getTime(),
          }),
        )
        dispatch(setModalPendingPayments(false))
      }}
      title={firstText ? 'Recordatorio' : '¡Tu plan ha vencido!'}
      description={
        firstText
          ? `Recuerda que tu pago mensual debe realizarse del 01 al 05 de cada mes. Puedes efectuarlo ingresando los detalles de tu tarjeta en el sistema o a través del reporte del depósito
             correspondiente. Si ya completaste tu pago, por favor avísanos para que no te enviemos este recordatorio nuevamente. ¡Gracias por tu cooperación!`
          : `Debes realizar el pago de tu plan para seguir disfrutando de los beneficios de Kolo. Si ya efectuaste el pago, por favor comunícalo a uno de nuestros 
             asesores comerciales. ¡Gracias por tu atención!`
      }
      customButtons={[
        <Button key={1} color="secondary" href="/perfil/cuenta">
          Estado de cuenta
        </Button>,
      ]}
    />
  ) : (
    <></>
  )
}

export default PaymentNotice
