import { actionTypes } from '../actions/companyConfiguration.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  configurationTypes: [],
  configurations: [],
  configFEL: {
    commercial: {},
    config: {},
    regimen: {},
  },
}

const companyConfigurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_FEL_CONFIGURATIONS):
      return {
        ...state,
        configFEL: extract(action.response, {
          commercial: {},
          config: {},
          regimen: {},
        }),
      }
    case successState(actionTypes.GET_CONFIGURATIONS_TYPES):
      return {
        ...state,
        configurationTypes: extract(action.configurationTypes, []),
      }
    case successState(actionTypes.GET_CONFIGURATIONS):
      return {
        ...state,
        configurations: extract(action.configurations, []),
      }
    default:
      return state ? state : initialState
  }
}

export default companyConfigurationReducer
