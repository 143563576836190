import React, {Component} from 'react';
import {connect} from "react-redux";

import {actionTypes as typesO, getOrdersByClient} from "../../../actions/orders.actions";
import {selectOrdersByClient} from "../../../selectors/orders.selector";

import {loadingSelector} from "../../../selectors/loading.selector";
import {hasErrorsSelector, singleErrorSelector, handlerError} from "../../../selectors/error.selector";

import {Row, Col, Modal} from "react-bootstrap";
import {
    faBook,
    faFileAlt,
    faTimesCircle
} from "@fortawesome/free-solid-svg-icons";

import {formatNumberWithCommas, formatDateFromMillis} from "../../../utils/formatters";

import Filter from "../../../components/cards/FilterCard";
import Card from "../../../components/cards/Card";
import IconButton from "../../../components/buttons/IconButton";
import OrderSummary from "../../../components/Summaries/OrderSummary";
import {Link} from "react-router-relative-link";
import { selectCurrentCurrency } from '../../../selectors/currencies.selector';
import { TableV2 } from '../../../components';


class OrdersByClient extends Component {

    state = {
        modal: {show: false, item: null}
    };

    componentDidMount() {
        const {getOrders} = this.props;
        getOrders();
    };

    customFilter = (filters) => {
        // console.log(filters);
    };

    goToResume = (item) => {
        item.details = item.products.map((p) => ({
            companyName: p.product.companyData.name,
            name: p.product.name,
            quantity: p.quantity,
            discount: p.discount,
            subTotal: p.subtotal,
            total: p.total
        }));
        item.type = 1;
        item.typeName = 'Solicitud de compra.';

        item.statusName = 'OK';

        this.setState({modal: {show: true, item}});
    };

    goToDetails = (item) => {
        const {match, history} = this.props;
        const route = match.url + `/detalle/${item.id}`;
        history.push(route);
    };

    getHeaders = () => {
        return [
            {label: 'Orden', show: true, value: ['number'], type: 'text' ,  className:'mini'},
            {label: 'Fecha creacion', show: true, value: ['createdAt'], type: 'date' ,  className:'mini'},
            {label: 'Descuento', show: true, value: ['descuento'], type: 'currency' ,  className:'mini', custom:(item) => item.descuento =  formatNumberWithCommas(0) },
            {label: 'Total', show: true, value: ['total'], type: 'currency' ,  className:'medium'},
            {config:true, show: true, label:'', className:'mini center',
            custom:(item) =><div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
            <IconButton tooltip={'Resumen'} icon={faFileAlt} onClick={() => this.goToResume(item)}/>
            <IconButton style={{marginLeft: 5}} tooltip={'Detalle Completo'} icon={faBook} onClick={() => this.goToDetails(item)}/>
        </div>}
        ]
    }

    render() {

        const {requests, loading, hasError, error, currentCurrency} = this.props;
        const {modal} = this.state;
        const {show, item} = modal;

        let noItemsLegend = 'No hay solicitudes de compra.';
        if (hasError) {
            if (error.message) noItemsLegend = error.message;
            else noItemsLegend = 'Ha ocurrido un error inesperado.';
        };

        return (
            <div>
                <h1 className={'dashboard-welcome'}>Solicitudes</h1>
                <Row>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div className={'column'}>
                            <Filter
                                title={'Filtros'}
                                module={'client'}
                                submodule={'request'}
                                customFilter={this.customFilter}
                            />
                            <Link to={`all`} className={'big-button'}>Todas las ordenes</Link>
                        </div>
                    </Col>
                    <Col>
                        <Card title={'Solicitudes de compra'}>
                            <TableV2
                                items={requests.sort((a, b) => (b.createdAt - a.createdAt))}
                                loading={loading}
                                noItemsLegend={noItemsLegend}
                                headers = {this.getHeaders()}
                                mobileAuto
                                storageKey={`ordersByClient`}
                            />

                        </Card>
                    </Col>
                </Row>

                <Modal
                    show={show}
                    size={'lg'}
                    centered
                    onHide={() => this.setState({modal: {show: false, item: null}})}

          >          <Modal.Header>
                        <Modal.Title>Detalle de Orden</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <OrderSummary order={item}/>
                    </Modal.Body>

                    <Modal.Footer>

                        <Row className={'container-buttons'}>
                            <IconButton style={{marginRight: '15px'}} tooltip={'Cerrar'} icon={faTimesCircle} size={'2x'}
                                        onClick={() => this.setState({modal: {show: false, item: null}})}/>
                        </Row>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    requests: selectOrdersByClient(state),
    loading: loadingSelector([typesO.ORDERS_BY_CLIENT])(state),
    hasError: hasErrorsSelector([typesO.ORDERS_BY_CLIENT])(state),
    error: singleErrorSelector([typesO.ORDERS_BY_CLIENT])(state),
    handlerError: (message, info) => handlerError(message, info),
    currentCurrency: selectCurrentCurrency(state)
});
const mapDispatchToProps = dispatch => ({
    getOrders: () => dispatch(getOrdersByClient())
});
export default connect(mapStateToProps, mapDispatchToProps)(OrdersByClient);
