import { actionTypes } from '../actions/contracts.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  contractedCompanies: [],
  contractedByCompanies: [],
  toggleProducerShipperContract: null,
  products: [],
}

const ContractsReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_PRODUCTS_CONTRACT_BY_PRODUCER):
      return {
        ...state,
        products: extract(action.response, []),
      }
    case successState(actionTypes.GET_CONTRACTED_COMPANIES):
      return {
        ...state,
        contractedCompanies: extract(action.response, []),
      }
    case successState(actionTypes.GET_CONTRACTED_BY_COMPANIES):
      return {
        ...state,
        contractedByCompanies: extract(action.response, []),
      }
    case successState(actionTypes.TOGGLE_PRODUCER_SHIPPER_CONTRACT):
      return {
        ...state,
        toggleProducerShipperContract: extract(action.response, null),
      }
    default:
      return state ? state : initialState
  }
}

export default ContractsReducer
