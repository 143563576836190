import React, { useEffect, useState } from 'react'

// ================== Redux ====================
import { useSelector, useDispatch } from 'react-redux'
import { getCurrentCompanyUsers } from 'src/actions/modules.actions'

import { Media } from 'react-breakpoints'
import { Modal, Row, Col, ProgressBar } from 'react-bootstrap'
import { CustomFilter, Button, Icon } from 'src/components'
import CustomDatePicker from 'src/components/CustomDatePicker/CustomDatePicker'
import Alert from 'sweetalert-react'

import {
  faWindowClose,
  faPlus,
  faSave,
  faArrowRight,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'

import { actionTypes } from 'src/actions/company.actions'
import { getCompaniesMentor, setCompaniesMentor } from 'src/actions/company.actions'

import { mentorPermissions } from 'src/enums/permissions'

import {
  isAllowed,
  selectCurrentCompanyUsersForSelect,
} from 'src/selectors/modules.selector'
import { selectGetCompaniesMentor } from 'src/selectors/company.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerSuccess,
  handlerError,
  singleErrorSelector,
  hasErrorsSelector,
} from 'src/selectors/error.selector'

import { formatDateFromMillis } from 'src/utils/formatters'
const MentorConfiguration = ({ show, id, name, onHide, mentor, idCurrent }) => {
  const dispatch = useDispatch()

  const loading = useSelector(state =>
    loadingSelector([actionTypes.SET_COMPANIES_MENTORS])(state),
  )
  const hasError = useSelector(state =>
    hasErrorsSelector([actionTypes.SET_COMPANIES_MENTORS])(state),
  )
  const error = useSelector(state =>
    singleErrorSelector([actionTypes.SET_COMPANIES_MENTORS])(state),
  )

  const loadingV = useSelector(state =>
    loadingSelector([actionTypes.GET_COMPANIES_MENTORS])(state),
  )
  const hasErrorV = useSelector(state =>
    hasErrorsSelector([actionTypes.GET_COMPANIES_MENTORS])(state),
  )
  const errorV = useSelector(state =>
    singleErrorSelector([actionTypes.GET_COMPANIES_MENTORS])(state),
  )

  const usuarios = useSelector(selectCurrentCompanyUsersForSelect)
  const mentors = useSelector(selectGetCompaniesMentor)

  const canEdit = useSelector(state => isAllowed(state, [mentorPermissions.see]))

  const [mentorsToDelete, setMentorsToDelete] = useState([])
  const [mentorsToAdd, setMentorsToAdd] = useState([])
  const [save, setSave] = useState(false)
  const [get, setGet] = useState(false)
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState([])
  const [searchA, setSearchA] = useState(null)
  const [searchB, setSearchB] = useState(null)
  const [mobile, setMobile] = useState(false)
  const [alert, setAlert] = useState({ title: '' })

  useEffect(() => {
    if (show && id) {
      setOpen(true)
      if (usuarios.length === 0) dispatch(getCurrentCompanyUsers())
      // dispatch(getUsersChildrenByModule(1000, false))
      dispatch(getCompaniesMentor({ companyId: id }))
      // console.log(mentors)
    }
  }, [show])

  useEffect(() => {
    if (loadingV) setGet(true)
    else if (get) {
      setGet(false)
      if (hasErrorV)
        setAlert({
          ...handlerError(errorV.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else {
        setSelected(mentors)
        //setSelected(viewers);
      }
    }
  }, [loadingV])

  useEffect(() => {
    if (loading) setSave(true)
    else if (save) {
      setSave(false)
      let al
      if (hasError) al = { ...handlerError(error.message) }
      else al = { ...handlerSuccess('Cambios aplicados satisfactoriamente') }
      al.onConfirm = () => setAlert({ ...alert, show: false })
      setAlert({ ...al })
    }
  }, [loading])

  const hide = () => {
    setOpen(false)
    setSelected([])
    setMentorsToAdd([])
    setMentorsToDelete([])
    onHide()
  }

  const filterUser = item => {
    const select = selected.find(i => i.userId === item.value)
    let nombre = item.label.split('-')
    if (select !== undefined) {
      select.name = nombre[0]
      select.email = nombre[1]

      return false
    } else {
      item.name = nombre[0]
      item.email = nombre[1]
      item.id = item.value
    }

    return true
  }

  const filter = (item, search) => {
    if (search && search !== '') {
      const exp = new RegExp(search.toUpperCase(), 'g')
      const i = { name: item.name, email: item.email }
      return Object.keys(i).some(p => `${JSON.stringify(i[p])}`.toUpperCase().match(exp))
    }
    return true
  }

  const itemsToSelect = (toAssign, items, title, noItemsLegend, search, setSearch) => {
    const is =
      items && items.filter(i => filter(i, search)).length > 0
        ? items.filter(i => filter(i, search))
        : []

    return (
      <div>
        {title && <h5>{title}</h5>}

        <CustomFilter
          search={search}
          onChange={({ target }) => setSearch(target.value)}
          customFilter={<div />}
        />

        {is.length > 0 ? (
          <ul className="main-list">
            {is.map((item, index) => (
              <li key={index}>
                <div className="b-user-item">
                  <div className={'justify-center-start align-items-start bu-en left'}>
                    <div className={'b-user-name'}>{item.email}</div>
                    <div className={'b-user-email'}>{item.name}</div>
                    {item.createdAt && (
                      <CustomDatePicker
                        value={item.createdAt}
                        textButton={
                          item.createdAt
                            ? formatDateFromMillis(item.createdAt)
                            : 'Fecha de expiración'
                        }
                        disabledDays={{ before: null, after: null }}
                        disabled={true}
                        lite
                      />
                    )}
                  </div>
                  {canEdit && (
                    <div className={'d-flex row align-items-center mr-2 bu-b right'}>
                      <Button
                        disabled={loading || loadingV}
                        color={'accent'}
                        onClick={() => {
                          if (toAssign) {
                            let mentor = mentorsToDelete.find(m => m === item.id)
                            if (mentor !== undefined) {
                              setMentorsToDelete(
                                mentorsToDelete.filter(m => m !== item.id),
                              )
                            }
                            setMentorsToAdd([...mentorsToAdd, item.id])
                            setSelected([
                              ...selected,
                              {
                                userId: item.id,
                                name: item.name,
                                email: item.email,
                              },
                            ])
                          } else {
                            let mentor = mentorsToAdd.find(m => m === item.userId)
                            if (mentor !== undefined) {
                              setMentorsToAdd(mentorsToAdd.filter(m => m !== item.userId))
                            } else {
                              setMentorsToDelete([...mentorsToDelete, item.id])
                            }
                            setSelected(selected.filter(s => s.userId !== item.userId))
                          }
                        }}>
                        <Icon
                          tooltip={toAssign ? 'Agregar' : 'Descartar'}
                          icon={toAssign ? (mobile ? faPlus : faArrowRight) : faTrash}
                        />
                      </Button>
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <h6>
            {search && search !== ''
              ? 'No se encontraron usuarios con los filtros aplicados'
              : noItemsLegend}
          </h6>
        )}
      </div>
    )
  }

  return (
    <div>
      <Modal show={open && !mobile} centered size={'lg'} onHide={() => undefined}>
        <Modal.Header>
          <Modal.Title>Mentores de empresa: {name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Media>
            {({ breakpoints, currentBreakpoint }) => {
              const tablet = breakpoints[currentBreakpoint] > breakpoints.tablet
              const colValue = tablet && canEdit ? 6 : 12

              return breakpoints[currentBreakpoint] >= breakpoints.mini ? (
                <Row>
                  {tablet && canEdit && (
                    <Col xl={6} lg={6} md={12} sm={12}>
                      {itemsToSelect(
                        true,
                        usuarios.filter(filterUser),
                        'Usuarios disponibles para asignar',
                        'No hay usuarios disponibles con los filtros aplicados',
                        searchA,
                        setSearchA,
                      )}
                    </Col>
                  )}

                  <Col xl={colValue} lg={colValue} md={12} sm={12}>
                    {loadingV && (
                      <div className={'pb-custom'}>
                        <ProgressBar
                          label="Cargando"
                          animated
                          now={100}
                          style={{ marginBottom: 10 }}
                        />
                      </div>
                    )}

                    {itemsToSelect(
                      false,
                      selected,
                      'Usuarios Asignados',
                      'Sin usuarios asignados',
                      searchB,
                      setSearchB,
                    )}
                  </Col>
                  {!tablet && canEdit && (
                    <Col xl={12} lg={12} md={12}>
                      <Row
                        className={'container-buttons'}
                        style={{ paddingBottom: 2, display: 'flex' }}>
                        <Button
                          disabled={loading || loadingV}
                          style={{ flex: 1 }}
                          icon={faPlus}
                          right
                          color={'primary'}
                          onClick={() => setMobile(true)}>
                          Asignar usuarios
                        </Button>
                      </Row>
                    </Col>
                  )}
                </Row>
              ) : (
                <div />
              )
            }}
          </Media>
        </Modal.Body>
        <Modal.Footer>
          <Row className="container-buttons">
            <Button
              color={'secondary'}
              icon={faWindowClose}
              right
              onClick={() => hide()}
              disabled={loading || loadingV}>
              Cerrar
            </Button>
            {canEdit && (
              <Button
                disabled={loadingV}
                loading={loading}
                color={'primary'}
                icon={faSave}
                right
                onClick={() =>
                  setAlert({
                    show: true,
                    title: 'Asignación Mentor',
                    text: `¿Desea asignar como Mentor a los usuarios seleccionados ?`,
                    type: 'warning',
                    showCancelButton: true,
                    cancelButtonColor: '#B35796',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Asignar',
                    confirmButtonColor: '#226095',
                    onCancel: () => setAlert({ ...alert, show: false }),
                    onConfirm: () => {
                      setAlert({ ...alert, show: false })
                      if (mentor) {
                        let request = {
                          companyId: id,
                          parentId: idCurrent.id,
                          toAdd: mentorsToAdd,
                          toDelete: mentorsToDelete,
                        }
                        // let usuarios =  Object.assign([], selected.map((s) => (s.id)))
                        dispatch(setCompaniesMentor(request))
                      }
                      hide()
                      // else{
                      //     dispatch(setClientViewersUser({
                      //         type: 1, clients: [id], usuarios: Object.assign([], selected.map((s) => (s.id))),
                      //         hideOldViewers: true, hideCurrentViewers: false
                      //     }));
                      // }
                    },
                  })
                }>
                Guardar
              </Button>
            )}
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal show={mobile} centered size={'md'} onHide={() => setMobile(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Usuarios disponibles para asignar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {itemsToSelect(
            true,
            usuarios.filter(filterUser),
            null,
            'No hay usuarios disponibles con los filtros aplicados',
            searchA,
            setSearchA,
          )}
        </Modal.Body>
      </Modal>

      <Alert {...alert} />
    </div>
  )
}

export default MentorConfiguration
