import '../../Permissions/UpdatePermissions/UpdatePermissions.scss'
import './ProductsByPosition.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Card from 'src/components/cards/Card'

import { formatNumberWithCommas } from 'src/utils/formatters'

import { Row, Col, Badge, Accordion } from 'react-bootstrap'
import { Facebook as BulletList } from 'react-content-loader'
import Alert from 'sweetalert-react'

import { actionTypes as typesC, getCompaniesByClient } from 'src/actions/company.actions'
import {
  selectSetCurrentCompany,
  selectCompaniesByClient,
} from 'src/selectors/company.selector'

import {
  actionTypes as typesP,
  getAllProductsByClient,
} from 'src/actions/products.actions'
import { selectProductsByClient } from 'src/selectors/products.selector'

import { addItem } from 'src/actions/shopping.actions'
import { selectShopping } from 'src/selectors/shopping.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  hasErrorsSelector,
  singleErrorSelector,
  handlerError,
} from 'src/selectors/error.selector'
import moment from 'moment'

import IconSearch from 'src/assets/images/newDesign/icon-search.svg'
import IconMinus from 'src/assets/images/newDesign/icon-minus.svg'
import IconPlus from 'src/assets/images/newDesign/icon-plus.svg'
import IconCart from 'src/assets/images/newDesign/icon-cart.svg'
import ArrowDown from 'src/assets/images/newDesign/arrow-down.svg'
import PlaceholderProduct from 'src/assets/images/newDesign/placeholder-product.svg'
import Money from 'src/components/Money/Money'

class ProductsByPosition extends Component {
  state = {
    alert: { title: 'default' },
    companies: [],
    search: '',
    modal: { show: false, item: {}, quantity: 0, subTotal: 0 },
    products: [],
    itemsToRender: [],
  }

  componentDidMount() {
    const { getCompanies, getProducts, company } = this.props
    if (company.clientId) {
      // getCompanies(company.clientId);
      getProducts(company.clientId)
    }
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { loadingC, hasErrorC, loadingP, hasErrorP, handlerError, shopping } =
      this.props
    const { errorC, errorP, listCompanies } = next
    let alert = { title: 'default' }

    if (loadingC && !next.loadingC) {
      if (!hasErrorC && next.hasErrorC) {
        alert = handlerError(errorC.message || 'Ha ocurrido un error inesperado.')
        alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
      } else {
        let companies = []
        listCompanies.map(c => companies.push({ ...c, isSelected: true }))
        this.setState({ companies }, () => this.productsToRender())
      }
    }

    if (loadingP && !next.loadingP) {
      if (!hasErrorP && next.hasErrorP) {
        alert = handlerError(errorP.message || 'Ha ocurrido un error inesperado.')
        alert.onConfirm = () => this.setState({ alert: { ...alert, show: false } })
      } else {
        next.products.map(p => {
          let item = shopping.find(i => i.id === p.productId)
          if (item) p.quantityInCart = item.quantity
        })

        const companies = this.getCompanies(next.products)
        this.setState({ companies, products: next.products }, () =>
          this.productsToRender(),
        )
      }
    }

    this.setState({ alert })
  }

  getCompanies = products => {
    let companies = []
    products.map(p => {
      const { productData } = p
      const { companyData } = productData

      if (companyData && companyData.id) {
        const company = companies.find(c => c.id === companyData.id)
        if (!company) companies.push({ ...companyData, isSelected: true })
      }
    })
    return companies
  }

  handleItemsChange = event => {
    const { checked } = event.target
    const { companies } = this.state
    const list = companies.map(c => ({ ...c, isSelected: checked }))
    this.setState({ companies: list })
  }

  handlerCheckBox = (event, index) => {
    const { checked } = event.target
    const { companies } = this.state
    companies[index].isSelected = checked
    this.setState({ companies })
  }

  getProductsGroupByProducer = (companies, products) => {
    let groups = []
    let indexS = []
    products.map(p => {
      const companyId = p.productData.companyId
      if (indexS.indexOf(companyId) === -1) {
        indexS.push(companyId)

        let companyName = '---'

        const company = companies.find(c => c.id === companyId)
        if (company) companyName = company.name

        const companyProducts = products
          .filter(pbc => pbc.productData.companyId === companyId)
          .map(pbc => {
            let thumbnail = null
            pbc.deliveryDate = null
            if (pbc.images.length > 0) thumbnail = pbc.images[0].url
            let deliveryDate = moment()
            if (pbc.polygonRoute) {
              if (pbc.polygonRoute.deliveryDays && pbc.polygonRoute.deliveryDays >= 1) {
                deliveryDate = moment().add(pbc.polygonRoute.deliveryDays, 'days')
              } else deliveryDate = moment().add(1, 'days')
            } else deliveryDate = moment().add(1, 'days')

            pbc.deliveryDate = moment(deliveryDate).format('DD/MM/YYYY')

            return {
              ...pbc,
              companyName,
              thumbnail,
              name: pbc.productData.name,
              price: pbc.productData.price,
              description: pbc.productData.description,
            }
          })

        groups.push({
          company: p.productData.companyId,
          companyName,
          products: companyProducts,
        })
      }
    })
    return groups
  }

  filter = item => {
    const { companies } = this.state
    const company = companies.find(c => c.id === item.company)
    return company && company.isSelected
  }

  filterProducts = item => {
    const { search } = this.state
    const exp = new RegExp(search.toUpperCase(), 'g')
    return Object.keys(item).some(p =>
      `${JSON.stringify(item[p])}`.toUpperCase().match(exp),
    )
  }

  goTo = (item, route) => {
    const { history, match } = this.props
    const url = `${match.url}${route}/${item.productId}`
    history.push(url, { product: item })
  }

  addItem = product => {
    let alert = handlerError(product.productData.name + ' añadido al carrito!')
    alert.show = true
    alert.type = 'success'
    alert.title = 'Información'
    alert.confirmButtonText = 'Aceptar'

    alert.onConfirm = () =>
      this.setState({ alert: { ...alert, show: false } }, () => {
        const item = {
          id: product.productId,
          name: product.productData.name,
          description: product.productData.description,
          offerId: product.offerId,
          companyName: product.companyName,
          companyId: product.productData.companyId,
          price: product.productData.price,
          quantity: product.quantity,
          subTotal: product.quantity * parseFloat(product.productData.price),
          images: product.images,
          thumbnail: product.thumbnail,
          deliveryDate: product.deliveryDate,
          polygonRoute: product.polygonRoute,
        }

        this.resetQuantity(product)

        this.props.addItem(item, this.props.shopping)
      })
    this.setState({ alert })
  }

  resetQuantity = product => {
    const { products } = this.state
    let item = products.find(i => i.productId === product.productId)

    if (!item) return

    item.quantity = 0

    products.map(i => {
      if (i.productId === product.productId) {
        i = item
      }
    })
    this.setState({ products }, () => this.productsToRender())
  }

  upQuantity = product => {
    const { products } = this.state
    let item = products.find(i => i.productId === product.productId)

    if (!item) return
    let quantity = parseInt(item.quantity || 0)

    // if(quantity < (product.existence - (product.quantityInCart || 0))){
    item.quantity = quantity + 1
    // }

    products.map(i => {
      if (i.productId === product.productId) {
        i = item
      }
    })
    this.setState({ products }, () => this.productsToRender())
  }

  downQuantity = product => {
    const { products } = this.state
    let item = products.find(i => i.productId === product.productId)

    if (!item) return
    let quantity = parseInt(item.quantity || 0)

    if (quantity > 0) {
      item.quantity = quantity - 1
    }

    products.map(i => {
      if (i.productId === product.productId) {
        i = item
      }
    })
    this.setState({ products }, () => this.productsToRender())
  }

  productsToRender = () => {
    const { companies, products } = this.state
    if (companies && products) {
      const items = this.getProductsGroupByProducer(companies, products)
      this.setState({ itemsToRender: items })
    }
  }

  goTo = (item, route) => {
    const { history, match } = this.props

    if (item) {
      const url = `${match.url}${route}/${item.productId}`
      history.push(url, { product: item })
    } else {
      const url = `${match.url}${route}`
      history.push(url)
    }
  }

  isLandscape = () => {
    return window.innerHeight < window.innerWidth
  }

  render() {
    const { loadingP, shopping } = this.props
    const { itemsToRender, alert } = this.state
    const { companies, search } = this.state

    return (
      <div className="content">
        <label className={'title'}>Productos</label>

        {/*<div className={"button-cart"} onClick={()=> this.goTo(null, '/compra')}>*/}
        {/*    <img className={'icon-cart'} src={IconCart}/>*/}
        {/*    {*/}
        {/*        shopping.length > 0 &&*/}
        {/*        <Badge className={"badge"} pill variant="danger">{shopping.length}</Badge>*/}
        {/*    }*/}
        {/*</div>*/}

        <Row className={'content-body no-margins'}>
          <Col className={'col1'} md={6} lg={4} xl={2} style={{ margin: 0, padding: 0 }}>
            <div style={{ marginBottom: 67 }}>
              <Accordion style={{}} defaultActiveKey={this.isLandscape() ? '0' : '1'}>
                <Accordion.Item eventKey={'0'}>
                  <Accordion.Button
                    className={'filter-header'}
                    style={{}}
                    as={Card.Header}
                    eventKey="0">
                    <div>Filtros</div>
                    <img className={'arrow-down'} src={ArrowDown} />
                  </Accordion.Button>
                  <Accordion.Body className={'filter-content'} eventKey="0">
                    <div style={{ height: '100%' }}>
                      <label className="cb-container">
                        {companies.filter(c => c.isSelected).length !== companies.length
                          ? 'Marcar todos'
                          : 'Desmarcar todos'}
                        <input
                          type="checkbox"
                          name={'all'}
                          checked={
                            companies.filter(c => c.isSelected).length ===
                            companies.length
                          }
                          onChange={this.handleItemsChange}
                        />
                        <span className="checkmark" />
                      </label>

                      {companies.map((item, index) => {
                        return (
                          <label key={item.id} className="cb-container">
                            {item.name}
                            <input
                              type="checkbox"
                              name={`producer - ${index}`}
                              checked={item.isSelected}
                              onChange={event => this.handlerCheckBox(event, index)}
                            />
                            <span className="checkmark" />
                          </label>
                        )
                      })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Col>
          <Col className={'col2'} style={{ margin: 0, padding: 0, position: 'relative' }}>
            <Row
              style={{
                position: 'relative',
                marginTop: 4,
                height: 60,
                marginLeft: 25,
                marginRight: 25,
                marginBottom: 0,
                padding: 0,
              }}>
              <input
                className={'input-search'}
                id="search"
                name="search"
                placeholder="Buscar por nombre / Descripción"
                value={search}
                onChange={e => this.setState({ search: e.target.value })}
              />
              {/*<button className={'button-search'}>*/}
              {/*    <img className={'icon'} src={IconSearch}/>*/}
              {/*</button>*/}
            </Row>
            <Row className={'products-list'} style={{ marginBottom: 50 }}>
              {loadingP ? (
                <div style={{ width: '100%', margin: 50 }}>
                  <BulletList />
                </div>
              ) : (
                <div style={{ width: '100%' }}>
                  {itemsToRender.filter(this.filter).map((item, index) => {
                    return item.products.filter(this.filterProducts).length > 0 ? (
                      <div className={'p-container'} key={item.company}>
                        <label className={'company-title'}>{item.companyName}</label>
                        <Row className={'no-margins'}>
                          {item.products
                            .filter(this.filterProducts)
                            .map((product, index) => {
                              const url = product.thumbnail || PlaceholderProduct

                              let isNew =
                                moment().diff(
                                  moment(product.productData.createdAt),
                                  'days',
                                ) < 60

                              return (
                                <Col
                                  xl={3}
                                  lg={6}
                                  md={12}
                                  sm={12}
                                  style={{ margin: 0 }}
                                  key={
                                    product.productId.toString() +
                                    product.contractId.toString()
                                  }>
                                  <div className={'product-quantity-card'}>
                                    <Row className={'no-margins center'}>
                                      <img
                                        className={'product-image'}
                                        src={url}
                                        alt=""
                                        onClick={() => this.goTo(product, '/detail')}
                                      />
                                    </Row>

                                    {isNew && (
                                      <div className={'product-new'}>
                                        <label style={{ marginTop: 4 }}>NUEVO</label>
                                      </div>
                                    )}

                                    <Row className={'no-margins center'}>
                                      <label
                                        className={'product-title'}
                                        style={{ marginTop: 20 }}>
                                        {product.name}
                                      </label>
                                    </Row>

                                    <Row className={'no-margins center'}>
                                      <label
                                        className={'product-category'}
                                        style={{ marginTop: 5 }}>
                                        Categoría
                                      </label>
                                    </Row>

                                    <Row className={'no-margins center'}>
                                      <Money
                                        component="label"
                                        className={'product-price'}
                                        style={{
                                          marginTop: 10,
                                          marginBottom: 10,
                                        }}>
                                        {product.price}
                                      </Money>
                                    </Row>

                                    <div className={'divider'} />

                                    <Row className={'no-margins center'}>
                                      <label
                                        className={'product-delivery'}
                                        style={{ marginTop: 0 }}>
                                        Entrega estimada {product.deliveryDate}
                                      </label>
                                    </Row>

                                    {product.existence !== 0 ? (
                                      <Row
                                        className={'no-margins center'}
                                        style={{ marginTop: 8 }}>
                                        <div
                                          className={'button-quantity'}
                                          onClick={() => {
                                            this.downQuantity(product)
                                          }}>
                                          <img
                                            className={'icon-quantity'}
                                            src={IconMinus}
                                            alt={'-'}
                                          />
                                        </div>
                                        <div className={'product-quantity'}>
                                          {product.quantity || 0}
                                        </div>
                                        <div
                                          className={'button-quantity'}
                                          onClick={() => {
                                            this.upQuantity(product)
                                          }}>
                                          <img
                                            className={'icon-quantity'}
                                            src={IconPlus}
                                            alt={'+'}
                                          />
                                        </div>
                                      </Row>
                                    ) : (
                                      <Row className={'no-margins center'}>
                                        <label
                                          className={'product-category'}
                                          style={{
                                            marginTop: 20,
                                            marginBottom: 17,
                                          }}>
                                          No hay existencia
                                        </label>
                                      </Row>
                                    )}

                                    <button
                                      disabled={!product.quantity}
                                      className={'button'}
                                      style={{
                                        marginTop: 15,
                                        marginBottom: 40,
                                      }}
                                      onClick={() => {
                                        this.addItem(product)
                                      }}>
                                      Agregar a carrito
                                    </button>
                                  </div>
                                </Col>
                              )
                            })}
                        </Row>
                      </div>
                    ) : (
                      <div />
                    )
                  })}
                  <div className={'divider'} style={{ height: 50 }} />
                </div>
              )}
            </Row>
          </Col>
        </Row>

        <Alert {...alert} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  company: selectSetCurrentCompany(state),
  listCompanies: selectCompaniesByClient(state),
  loadingC: loadingSelector([typesC.GET_PRODUCER_BY_CLIENT])(state),
  hasErrorC: hasErrorsSelector([typesC.GET_PRODUCER_BY_CLIENT])(state),
  errorC: singleErrorSelector([typesC.GET_PRODUCER_BY_CLIENT])(state),
  products: selectProductsByClient(state),
  loadingP: loadingSelector([typesP.GET_ALL_PRODUCTS_BY_CLIENT])(state),
  hasErrorP: hasErrorsSelector([typesP.GET_ALL_PRODUCTS_BY_CLIENT])(state),
  errorP: singleErrorSelector([typesP.GET_ALL_PRODUCTS_BY_CLIENT])(state),
  shopping: selectShopping(state),
  handlerError: message => handlerError(message),
})
const mapDispatchToProps = dispatch => ({
  getCompanies: id => dispatch(getCompaniesByClient(id)),
  getProducts: id => dispatch(getAllProductsByClient(id)),
  addItem: (item, items) => dispatch(addItem(item, items)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ProductsByPosition)
