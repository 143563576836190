import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";

import {Row, Col, Modal, Form} from "react-bootstrap";
import {Td, Tr} from "react-super-responsive-table";

import {
    faBackspace,
    faCheck, faExclamationCircle,
    faSave, faSignLanguage, faWindowClose,
    faWindowMinimize, faEdit, faPlus, faEye, faTrash, faCheckDouble, faWarehouse
} from "@fortawesome/free-solid-svg-icons";
import Alert from "sweetalert-react";

import {
    Icon, Card,  Select, Button, MapCard, FormText, Dropdown, CustomTabs, FormTextField, TableV2
} from "../../../components";

import {
    actionTypes as typeO,
    getLinkProfile,
    onSendRequestSettingLInk,
    onSetMaxQuantity,
    onSetParcel,
    onUpdateLink,
    getTypesAvailable, getInventoryExistence
} from "../../../actions/offers.actions";
import {
    selectLinkProfile,
    selectOfferSettingResponse,
    selectOfferSetParcel,
    selectOfferUpdateLink,
    selectOfferLinkTypeAvailable
} from "../../../selectors/offers.selector";

import {actionTypes as typeP, onUpdatePolygonSetting, changeHierarchy, changeShipping} from "../../../actions/polygons.actions";
import {selectSettingResponse} from "../../../selectors/polygons.selector";

import {loadingSelector} from "../../../selectors/loading.selector";
import {singleErrorSelector, hasErrorsSelector, handlerSuccess, handlerError} from "../../../selectors/error.selector";

import {selectCurrentCompany, selectCurrentModule} from "../../../selectors/user.selector";

import {types, getAllByCompany} from "../../../actions/parcel.actions";
import {selectParcels} from "../../../selectors/parcel.selector";

import {getWarehouses} from "../../../actions/warehouse.actions";
import {selectAllWarehousesForSelect} from "../../../selectors/warehouse.selector";

import {getCenter} from "../../../selectors/utilities.selector";
import {formatDateFromMillis, formatNumberWithCommas} from "../../../utils/formatters";

import ParcelCEV from "../../Parcel/component/ParcelCEV";
import BalanceTable from "../../../components/Balance/BalanceTable";
import {arrayMove} from "../../../utils/utilities";
import {selectGetAccountsForSelect} from "../../../selectors/banks.selector";
import {getAccounts} from "../../../actions/banks.actions";
import { selectCurrentCurrency } from '../../../selectors/currencies.selector';

const latitude = 14.589465440976774;
const longitude = -90.51898866891861;

const KoloLinkProfile = (props) => {
    const dispatch = useDispatch();

    const company = useSelector(selectCurrentCompany);
    const currentCurrency = useSelector(selectCurrentCurrency);
    // const module = useSelector(selectCurrentModule);
    const [module, setModule] = useState(1000);

    const typesAvailable = useSelector(selectOfferLinkTypeAvailable);
    const loadingTA = useSelector((state) => loadingSelector([typeO.GET_LINK_TYPE_AVAILABLE])(state));
    const hasErrorTA = useSelector((state) => hasErrorsSelector([typeO.GET_LINK_TYPE_AVAILABLE])(state));

    const profile = useSelector(selectLinkProfile);
    const loading = useSelector((state) => loadingSelector([typeO.GET_LINK_PROFILE])(state));

    const loadingP = useSelector((state) => loadingSelector([typeP.ON_UPDATE_SETTING])(state));
    const hasErrorP = useSelector((state) => hasErrorsSelector([typeP.ON_UPDATE_SETTING])(state));
    const errorP = useSelector((state) => singleErrorSelector([typeP.ON_UPDATE_SETTING])(state));
    const settingResponse = useSelector(selectSettingResponse);

    const loadingO = useSelector((state) => loadingSelector([typeO.ON_SEND_REQUEST_SETTING])(state));
    const hasErrorO = useSelector((state) => hasErrorsSelector([typeO.ON_SEND_REQUEST_SETTING])(state));
    const errorO = useSelector((state) => singleErrorSelector([typeO.ON_SEND_REQUEST_SETTING])(state));
    const oSettingResponse = useSelector(selectOfferSettingResponse);

    const loadingC = useSelector((state) => loadingSelector([typeO.CHANGE_VALUES]) (state));
    const hasErrorC = useSelector((state) => hasErrorsSelector([typeO.CHANGE_VALUES]) (state));
    const errorC = useSelector((state) => singleErrorSelector([typeO.CHANGE_VALUES]) (state));

    const loadingSP = useSelector((state) => loadingSelector([typeO.ON_SET_PARCELS])(state));
    const hasErrorSP = useSelector((state) => hasErrorsSelector([typeO.ON_SET_PARCELS])(state));
    const errorSP = useSelector((state) => singleErrorSelector([typeO.ON_SET_PARCELS])(state));
    const spResponse = useSelector(selectOfferSetParcel);

    const loadingPa = useSelector((state) => loadingSelector([types.GET_ALL_PARCEL_BY_COMPANY])(state));
    const parcels = useSelector((state) => selectParcels(state));

    const warehouses = useSelector(selectAllWarehousesForSelect);
    const accounts = useSelector(selectGetAccountsForSelect);

    const responseUL = useSelector(selectOfferUpdateLink);
    const loadingUL = useSelector((state) => loadingSelector([typeO.ON_UPDATE_LINK])(state));
    const hasErrorUL = useSelector((state) => hasErrorsSelector([typeO.ON_UPDATE_LINK])(state));
    const errorUL = useSelector((state) => singleErrorSelector([typeO.ON_UPDATE_LINK])(state));

    const loadingH = useSelector((state) => loadingSelector([typeP.CHANGE_HIERARCHY])(state));
    const hasErrorH = useSelector((state) => hasErrorsSelector([typeP.CHANGE_HIERARCHY])(state));
    const errorH = useSelector((state) => singleErrorSelector([typeP.CHANGE_HIERARCHY])(state));

    const loadingS = useSelector((state) => loadingSelector([typeP.CHANGE_SHIPPING])(state));
    const hasErrorS = useSelector((state) => hasErrorsSelector([typeP.CHANGE_SHIPPING])(state));
    const errorS = useSelector((state) => singleErrorSelector([typeP.CHANGE_SHIPPING])(state));

    const loadingE = useSelector((state) => loadingSelector([typeO.EXISTENCE])(state));
    const existence = useSelector((state) => state.offers.existence);

    const [actions, setActions] = useState({changeShipping: false, hierarchy: false, types: false, uLink: false, change: false, setting: false, settingO: false, parcel: false, profile: false});

    const [count, setCount] = useState(0);
    const [linkType, setLType] = useState(0);
    const [linkCompany, setLCompany] = useState(0);
    const [isShow, setIsShow] = useState(false);
    const [isOwn, setIsOwn] = useState(false);
    const [title, setTitle] = useState('Perfil');
    const [google, setGoogle] = useState({map: null, maps: null, polygons: []});
    const [selected, setSelected] = useState([]);
    const [seePayments, setSeePayments] = useState({show: false, setting: {}, item: {}});
    const [typeL, setType] = useState(0);

    const [alert, setAlert] = useState({title: 'default'});
    const [products, setProducts] = useState([]);
    const [pParcels, setPParcels] = useState([]);
    const [pRParcels, setPRParcels] = useState([]);
    const [orderBy, setOrderBy] = useState(false);
    const [shipping, setShipping] = useState(false);
    const [modalE, setModalE] = useState({show: false});

    const [modalAddParcel, setModalAddParcel] = useState({show: false, item: {}, amount: 0});
    const [cev, setCEV] = useState({type: 0, show: false, id: null});
    const [toggleW, setToggle] = useState({
        edit: false,
        tWarehouse: null,
        pWarehouse: null,
        tuWarehouse: null,
        puWarehouse: null,
        tuBankAccount: null,
        puBankAccount: null
    });

    useEffect(() => {
        dispatch(getAccounts());
        const {params} = props.match;
        if (params) {
            let {type, id, moduleId} = params;
            setModule(Number(moduleId));
            type = parseInt(type);
            if (type === 1 || type === 2) {
                setIsShow(true);
                setTitle('Contrato de transporte');
            } else if (type === 3)
                setTitle('Perfil de transporte');
            else
                setTitle('Perfil de transporte KOLO');
            setType(type);
            setLCompany(parseInt(id));

            if (type !== 1 && type !== 2)
                setUp(2);
            else
                dispatch(getTypesAvailable(params.id, moduleId));
            //setUp();
        }
    }, []);

    useEffect(() => {
        if (loadingTA)
            setActions({...actions, types: true});
        else if (actions.types) {
            setActions({...actions, types: false});
            if (hasErrorTA) {
                //todo
            } else if (typesAvailable.length > 0){
                setUp(typesAvailable[0].id);
            }
        }
    }, [loadingTA]);

    useEffect(() => {
        if (loading)
            setActions({...actions, profile: true});
        else if (actions.profile) {
            setActions({...actions, profile: false});
            if (!profile.links) return;
            if (profile.transportistId === company)
                setIsOwn(true);
            setSelected(Object.assign([], profile.links.map((l) => ({...l, selected: true, shippingU: l.shipping}))));

            if (profile.parcels)
                setPParcels(Object.assign([], profile.parcels.map((p) => ({...p, selected: false, edit: false, deleted: false, uAmount: p.amount}))));

            if (profile.RParcels)
                setPRParcels(Object.assign([], profile.RParcels.map((p) => ({...p, selected: false, edit: false, deleted: false, uAmount: p.amount}))));

            if (profile.link) {
                setToggle({edit: false, pWarehouse: profile.link.PWarehouse, puWarehouse: profile.link.PWarehouse, tWarehouse: profile.link.TWarehouse, tuWarehouse: profile.link.TWarehouse});
            }
        }
    }, [loading]);

    useEffect(() => {
        if (!selected) return;
        if (selected.length <= 0) return;
        setPolygons(selected);
        setProducts(getProducts());
    }, [selected]);

    useEffect(() => {
        if (loadingC)
            setActions({...actions, change: true});
        else if (actions.change) {
            setActions({...actions, change: false});
            if (hasErrorC)
                setAlert({
                    ...handlerError(errorC.message),
                    onConfirm: () => setAlert({...alert, show: false})
                });
            else
                setAlert({
                    ...handlerSuccess('Cambios aplicados satisfactoriamente'),
                    onConfirm: () => {
                        setAlert({...alert, show: false});
                        setUp();
                    }
                });
        }
    }, [loadingC]);

    useEffect(() => {
        if (loadingP)
            setActions({...actions, setting: true});
        else if (actions.setting) {
            if (hasErrorP)
                setAlert({...handlerError(errorP.message), onConfirm: () => setAlert({...alert, show: false})});
            else
                setAlert({
                    ...handlerSuccess(settingResponse),
                    onConfirm: () => {
                        setUp();
                        setAlert({...alert, show: false});
                        setSeePayments({...seePayments, show: false});
                    }
                });
        }
    }, [loadingP]);

    useEffect(() => {
        if (loadingO)
            setActions({...actions, settingO: true});
        else if (actions.settingO) {
            if (hasErrorO)
                setAlert({...handlerError(errorO.message), onConfirm: () => setAlert({...alert, show: false})});
            else
                setAlert({...handlerSuccess(oSettingResponse), onConfirm: () => setAlert({...alert, show: false})});
        }
    }, [loadingO]);

    useEffect(() => {
        if (seePayments.show && seePayments.edit)
            if (!parcels || parcels.length <= 0)
                dispatch(getAllByCompany());
    }, [seePayments]);

    useEffect(() => {
        if (loadingSP)
            setActions({...actions, parcel: true});
        else if (actions.parcel) {
            setActions({...actions, parcel: false});
            if (hasErrorSP)
                setAlert({
                    ...handlerError(errorSP),
                    onConfirm: () => setAlert({...alert, show: false})
                });
            else {
                setUp();
                setModalAddParcel({...modalAddParcel, value: {}, show: false});
                setAlert({
                    ...handlerSuccess(spResponse),
                    onConfirm: () => setAlert({...alert, show: false})
                });
            }
        }
    }, [loadingSP]);

    useEffect(() => {
        if (loadingUL)
            setActions({...actions, uLink: true});
        else if (actions.uLink) {
            setActions({...actions, uLink: false});
            if (hasErrorUL)
                setAlert({
                    ...handlerError(errorUL.message),
                    onConfirm: () => setAlert({...alert, show: false})
                });
            else {
                setToggle({...toggleW, edit: false});
                setUp();
                setAlert({
                    ...handlerSuccess(responseUL),
                    onConfirm: () => setAlert({...alert, show: false})
                });
            }
        }
    }, [loadingUL]);

    useEffect(() => {
        if (loadingH)
            setActions({...actions, hierarchy: true});
        else if (actions.hierarchy) {
            setActions({...actions, hierarchy: false});
            if (hasErrorH)
                setAlert({
                    ...handlerError(errorH.message),
                    onConfirm: () => setAlert({...alert, show: false})
                });
            else
                setAlert({
                    ...handlerSuccess('La jerarquía de los polígonos fue actualizada satisfactoriamente.'),
                    onConfirm: () => {
                        setAlert({...alert, show: false});
                        setUp(4);
                    }
                });
        }
    }, [loadingH]);

    useEffect(() => {
        if (loadingS)
            setActions({...actions, changeShipping: true});
        else if (actions.changeShipping) {
            setActions({...actions, changeShipping: false});
            if (hasErrorS)
                setAlert({
                    ...handlerError(errorS.message),
                    onConfirm: () => setAlert({...alert, show: false})
                });
            else
                setAlert({
                    ...handlerSuccess('Se actualizó el precio del envío satisfactoriamente.'),
                    onConfirm: () => {
                        setAlert({...alert, show: false});
                        setUp(4);
                    }
                });
        }
    }, [loadingS]);

    const getProfile = (lt) => {
        if (lt)
            setLType(lt);
        else
            lt = linkType;

        const {params} = props.match;
        if (!params) return;
        let {id, type} = params;
        dispatch(getLinkProfile(id, type, lt));
    };

    const setUp = (linkType) => {
        getProfile(linkType);
    };

    const setPolygons = (links) => {
        const {map, maps, polygons} = google;

        if (!map) return;

        if (polygons)
            polygons.map((p) => (p.setMap(null)));

        const items = [];
        let center = {};

        links.filter((link) => link.selected && link.polygonId && link.points && link.points.length > 0).map((link, i) => {
            let {points} = link;
            points = points.sort((a, b) => (a.index - b.index))
                .map((p) => ({lat: Number(p.latitude), lng: Number(p.longitude)}));

            const polygon = new maps.Polygon({
                path: points,
                strokeColor: `#FF000${i}`,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: `#FF000${i}`,
                fillOpacity: 0.35
            });
            center = getCenter(points);
            items.push(polygon);
        });

        if (center.latLng && center.latLng.lat > 0) map.panTo(center.latLng);

        map.setZoom(8);
        items.map((p) => (p.setMap(map)));
        setGoogle({map, maps, polygons: items});
    };

    const getStatus = (status) => {
        let color;
        let label;
        let icon;

        switch (status) {
            case 2:
                color = 'green';
                label = 'Aceptada';
                icon = faCheck;
                break;
            case 3:
                color = 'gray';
                label = 'Rechazada';
                icon = faWindowMinimize;
                break;
            case 4:
                color = 'pink';
                label = 'Desactivada';
                icon = faBackspace;
                break;
            case 5:
                color = 'red';
                label = 'Cancelada';
                icon = faSignLanguage;
                break;
            default:
                color = 'blue';
                label = 'Disponible';
                icon = faExclamationCircle;
                break
        }

        return {color, label, icon};
    };

    const onSortEnd = ({oldIndex, newIndex}) => {
        if (oldIndex === newIndex) return;
        let cItems = Object.assign([], selected);
        cItems = arrayMove(cItems, oldIndex, newIndex);
        setSelected(cItems);
    };
    
    const getHeadersZones = () => {
        const headersAux = [
            {
                label: '', show: true,  classNameCustom: (item) => `mini t-${getStatus(item.status).color}`, select:true, value:[], simpleChecked:true,
                custom: (item) => <label className={'checkbox-label'}>
                    <input type={'checkbox'} name={'kolo'} checked={item.selected} onChange={(target) => {
                        if (target.value === undefined) {
                            const items = selected.map((s) => {
                                if (s.polygonId === item.polygonId)
                                    s.selected = !s.selected;
                                return s;
                            });
                            setSelected(items);
                            setPolygons(items);
                        }
                     }} />
                </label>
            }
        ]

        if (isShow) {
            headersAux.push({ label: 'Estado', show: true, value: ['status'], type: 'text', className: 'mini', custom: (item) => getStatus(item.status).label })
        }
        headersAux.push(...[{ label: 'Alias', show: true, value: ['polygonName'], type: 'text', className: 'medium' },
         { label: 'Fecha', show: true, value: ['date'], type: 'date', className: 'mini' }])

        typeL === 4 && linkCompany === company && headersAux.push({
                label: 'Envío', select: true, show: true, value: ['shipping'], type: 'text', classNameCustom: (item) => shipping ? 'short right' : '',
                custom: (item) => shipping ? <FormTextField
                    disabled={loadingP}
                    value={item.shippingU}
                    onMouseOver={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
            type={'number'}
            onChange={(event) => {
                event.stopPropagation();
                let {value} = event.target;
                if (!value || value < 0) value = 0;
                item.shippingU = value;
                setSelected(Object.assign([], selected.map((s) => {
                    if (s.polygonId === item.polygonId)
                        return item;
                    return s;
                })));
            }}
            className={'edit-input'}
        /> : formatNumberWithCommas(item.shipping)})
        headersAux.push(
            {config:true, show: true, label:'', className:'mini', custom:(item) => <Dropdown items={[
                {show: linkType === 2, title: 'Ver pagos', action: () => setSeePayments({show: true, setting: item.setting, item})},
                {show: !isShow && (typeL === 4 || !isOwn), title: 'Contratar', action: () => goTo(`/nuevo/${item.polygonId}`)},
                {show: isShow, title: 'Ver enlace', action: () => goTo(`/detalle/${item.offerId}`)},
                {show: isShow && profile.producerId === company, title: 'Editar enlace', action: () => goTo(`/editar/${item.offerId}`)},
                {show: (isOwn && linkType === 2), title: 'Editar pagos', action: () => setSeePayments({show: true, setting: item.setting, item, edit: true})}
            ]}/>})
        return headersAux

    }

    const getLinksZones = () => {
        // const headers = [''];
        // if (isShow) headers.push('Estado');
        // headers.push(...['Alias', 'Fecha']);
        // if (typeL === 4 && linkCompany === company)
        //     headers.push(`Envío (${currentCurrency.symbol})`);
        // headers.push('');

        return (<Card title={'Zonas de transporte'}>
            {(typeL === 4 && linkCompany === company) && <Row className={'container-buttons'}>
                {(!orderBy && !shipping) ? <>
                    <Button
                        loading={loadingS}
                        disabled={loadingH}
                        color={'accent'}
                        onClick={() => setShipping(true)}
                    >editar envíos</Button>
                    <Button
                        disabled={loadingS}
                        loading={loadingH}
                        color={'accent'}
                        onClick={() => setOrderBy(true)}
                    >Priorizar</Button>
                </> : <>
                        <Button
                            onClick={() => {setOrderBy(false);setShipping(false)}}
                            color={'secondary'}
                        >Cancelar</Button>
                        <Button
                            color={'primary'}
                            onClick={() => {
                                setOrderBy(false);
                                setShipping(false);
                                if (orderBy)
                                    dispatch(changeHierarchy(Object.assign([], selected.map((s) => (s.polygonId)))));
                                else
                                    dispatch(changeShipping(Object.assign([], selected.map((s) => ({polygonId: s.polygonId, shipping: s.shippingU})))));
                            }}
                        >Guardar</Button>
                    </>
                }
            </Row>}
            <TableV2
                dragAndDrop={orderBy}
                loading={loading}
                items={selected}
                onClickTr ={(target,item) => {
                    if (target.value === undefined) {
                        const items = selected.map((s) => {
                            if (s.polygonId === item.polygonId)
                                s.selected = !s.selected;
                            return s;
                        });
                        setSelected(items);
                        setPolygons(items);
                    }
                }}
                headers = {orderBy ? [] : getHeadersZones()}
                mobileAuto
               
            />
            {/* <Table
                onSortEnd={onSortEnd}
                dragAndDrop={orderBy}
                loading={loading}
                items={selected}
                headers={orderBy ? [] : headers}
                customClass={'scrollX'}
                renderRow={(item, i) => <Tr className={'data'} key={i} onClick={({target}) => {
                    if (target.value === undefined) {
                        const items = selected.map((s) => {
                            if (s.polygonId === item.polygonId)
                                s.selected = !s.selected;
                            return s;
                        });
                        setSelected(items);
                        setPolygons(items);
                    }
                }}>
                    <Td className={`mini t-${getStatus(item.status).color}`}><label className={'checkbox-label'}>
                        <input type={'checkbox'} name={'kolo'} checked={item.selected} onChange={() => {}}/>
                    </label></Td>
                    {isShow && <Td className={'mini'}>{getStatus(item.status).label}</Td>}
                    <Td className={'medium'}>{item.polygonName}</Td>
                    <Td className={'mini'}>{item.date && formatDateFromMillis(item.date)}</Td>
                    {(typeL === 4) && <td className={shipping ? 'short right' : ''}> {
                        shipping ? <FormTextField
                            disabled={loadingP}
                            value={item.shippingU}
                            onMouseOver={(e) => e.stopPropagation()}
                            onClick={(e) => e.stopPropagation()}
                            type={'number'}
                            onChange={(event) => {
                                event.stopPropagation();
                                let {value} = event.target;
                                if (!value || value < 0) value = 0;
                                item.shippingU = value;
                                setSelected(Object.assign([], selected.map((s) => {
                                    if (s.polygonId === item.polygonId)
                                        return item;
                                    return s;
                                })));
                            }}
                            className={'edit-input'}
                        /> : formatNumberWithCommas(item.shipping)
                    }</td>}
                    <Td className={'mini'} value={i}><Dropdown items={[
                        {show: linkType === 2, title: 'Ver pagos', action: () => setSeePayments({show: true, setting: item.setting, item})},
                        {show: !isShow && (typeL === 4 || !isOwn), title: 'Contratar', action: () => goTo(`/nuevo/${item.polygonId}`)},
                        {show: isShow, title: 'Ver enlace', action: () => goTo(`/detalle/${item.offerId}`)},
                        {show: isShow && profile.producerId === company, title: 'Editar enlace', action: () => goTo(`/editar/${item.offerId}`)},
                        {show: (isOwn && linkType === 2), title: 'Editar pagos', action: () => setSeePayments({show: true, setting: item.setting, item, edit: true})}
                    ]}/></Td>
                </Tr>}
            /> */}
        </Card>);
    };

    const getProducts = () => {
        const response = [];
        selected.filter((s) => s.selected && s.products && s.products.length > 0).map((s) => {
            const {products} = s;
            products.map((p) => {
                const index = response.findIndex((i) => i.productId === p.productId);
                if (index === -1) response.push({...p, eMax: p.max, eMin: p.min || 0, selected: false, editable: false});
            });
        });
        return response;
    };

    const goTo = (route) => {
        const {match} = props;
        let {url} = match;

        url += route;
        window.open(url, '_blank');
    };

    const onSetValueProduct = (item1, type, value, parametter) => {
        const index = products.findIndex((p) => p.productCode === item1.productCode);
        let item = products[index];
        switch (type) {
            case 4:
                item[parametter] = value;
                break;
            case 5:
                item.selected = !item.selected;
                item.edit = false;
                item.eMax = item.max;
                item.eMin = item.min;
                item.editable = false;
                break;
            case 6:
                item.edit = item.selected;
                item.editable = true;
                item.selected = true;
                break;
            default:
                item.selected = false;
                item.edit = false;
                item.editable = false;
                item.eMax = item.max;
                item.eMax = item.min;
                break;
        }

        products[index] = item;
        setProducts(Object.assign([], products));
        setCount(count+1);
    };

    const setSetting = (event) => {
        const {value, name} = event.target;
        setSeePayments({...seePayments, setting: {...seePayments.setting, [name]: value}});
    };

    const getParcelValue = (item) => {
        if (item.parcelId) {
            const parcel = pParcels.find((p) => p.id === item.parcelId);
            if (parcel)
                return {value: item.parcelId, label: parcel.name, amount: parcel.amount};
        }
        return {value: null, label: 'Sin paquete', amount: '--'};
    };

    const filterP = (item) => {
        let parcel;
        if (modalAddParcel.type === 2)
            parcel = pParcels.find((p) => p.parcelId === item.id);
        else
            parcel = pRParcels.find((p) => p.parcelId === item.id);

        return !parcel && item.type === modalAddParcel.type;
    };

    const onClickParcel = (type, item, items, typeAction, parameter, value) => {
        const index = items.findIndex((i) => i.id === item.id);
        switch (type) {
            case 1:
                item.selected = !item.selected;
                break;
            case 2:
                item.edit = true;
                break;
            case 3:
                item[parameter] = value;
                break;
            default:
                item.selected = false;
                item.edit = false;
                item.deleted = false;
                item.uAmount = item.amount;
                break;
        }

        items[index] = item;

        if (typeAction === 2)
            setPParcels([...items]);
        else
            setPRParcels([...items]);
    };

    const validateParcelTableButton = (type) => {
        let see = false;

        if (type === 3 && company === profile.transportistId)
            see = true;
        else if (type === 2 && (linkType === 3)) {
            if (module === 2000 && company === profile.producerId)
                see = true;
        } else if (type === 1) {
            if ((linkType === 1 || linkType === 4) && module === 1000 && company === profile.transportistId)
                see = true;
            else if ((linkType === 3 || linkType === 5) && module === 2000 && company === profile.producerId)
                see = true;
        }

        return see;
    };

    const parcelTable = (type, items) => {
        return (<div className={'column'}>
            {(validateParcelTableButton(type)) && <Row className={'container-buttons'}>
                {(items.filter((p) => p.selected).length <= 0) ?
                    <Button
                        variant={'info'}
                        icon={faPlus}
                        onClick={() => {
                            setModalAddParcel({show: true, item: {}, amount: 0, type});
                            if (parcels.length <= 0)
                                dispatch(getAllByCompany());
                        }}
                    >Agregar paquete</Button> : items.filter((p) => p.selected && !p.edit).length > 0 ?
                        <Button
                            variant={'info'}
                            icon={faEdit}
                            onClick={() => items.filter((p) => p.selected && !p.edit).map((p) => onClickParcel(2, p, items, type))}
                        >Editar</Button> : <>
                            <Button
                                disabled={loadingSP}
                                variant={'danger'}
                                icon={faWindowClose}
                                onClick={() => items.filter((p) => p.selected).map((p) => onClickParcel(10, p, items, type))}
                            >Cancelar</Button>
                            <Button
                                loading={loadingSP}
                                variant={'secondary'}
                                icon={faSave}
                                onClick={() => {
                                    let companyLinked = module === 1000 ? profile.producerId : profile.transportistId;
                                    const request = items.filter((p) => p.edit).map((p) => ({...p, amount: p.uAmount}));

                                    dispatch(onSetParcel(companyLinked, request, {linkType}, module));
                                }}
                            >Guardar</Button>
                        </>
                }
            </Row>}
            <TableV2
                items={items.filter((i) => !i.deleted)}
                noItemsLegend={'No se encontraron paquetes'}
                headers={[
                    {
                        label: '', show: true, classNameCustom: (item) => 'mini t-' + (item.selected ? 'green' : ''), select: true, value: [], simpleChecked: true,
                        custom: (item, index) => validateParcelTableButton(type) && <label className={'checkbox-label'}>
                            <input type={'checkbox'} name={index} checked={item.selected} onChange={() => { }} />
                        </label>
                    },
                    { label: 'Alias', show: true, value: ['name'], type: 'text', className: 'medium' },
                    {
                        label: 'Pago', show: true, value: ['amount'], type: 'text', classNameCustom: (item) => item.edit ? 'medium' : 'mini',
                        custom: (item) => item.edit ?
                            <FormTextField
                                disabled={loadingSP}
                                value={item.uAmount}
                                onMouseOver={(e) => e.stopPropagation()}
                                onClick={(e) => e.stopPropagation()}
                                type={'number'}
                                onChange={(event) => {
                                    event.stopPropagation();
                                    let { value } = event.target;
                                    if (!value || value < 0) value = 0.01;

                                    onClickParcel(3, item, items, type, 'uAmount', value);
                                }}
                                className={'edit-input'}
                            /> :
                            formatNumberWithCommas(item.amount)
                    },
                    {
                        config: true, show: true, label: '', className: 'mini center', custom: (item) => <Icon
                            icon={item.edit ? faTrash : faEye}
                            tooltip={item.edit ? 'Eliminar' : 'Ver paquete'}
                            onClick={() => {
                                if (item.edit)
                                    onClickParcel(3, item, items, type, 'deleted', true);
                                else
                                    setCEV({ type: 3, show: true, id: item.parcelId });
                            }}
                        />
                    }

                ]}
                mobileAuto
                onClickTr = {(target,item) => {
                    if (validateParcelTableButton(type) && items.filter((p) => p.selected && p.edit).length <= 0 && target.value === undefined && !loadingSP) {
                        onClickParcel(1, item, items, type);
                    }
                }}
                storageKey = {'koloPackage'}
            />
        </div>);
    };

    const warehouseCompany = (warehouse, moduleId, parameter) => {
        let cValue = {id: null, label: 'sin asignar'};
        if (toggleW[parameter])
            cValue = {...toggleW[parameter], label: toggleW[parameter].name};

        return (toggleW.edit && moduleId === module) ?
            <Select
                disabled={loadingUL}
                label={'Cambio de bodega enlazada'}
                options={warehouses}
                value={cValue}
                onChange={(value) => setToggle({...toggleW, [parameter]: value})}
            /> :
            <span>{warehouse ? `${warehouse.name}: ${warehouse.address}` : 'Sin bodega'}</span>;
    };

    const accountCompany = (account, moduleId, parameter) => {
        let cValue = {id: null, label: 'sin asignar'};
        if (toggleW[parameter])
            cValue = {...toggleW[parameter], label: toggleW[parameter].name};

        return (toggleW.edit && moduleId === module) ?
            <Select
                disabled={loadingUL}
                info={'Cuando se soliciten desembolsos desde KOLO Vitrina, los desembolsos serán dirigidos a la cuenta bancaria seleccionada a continuación'}
                label={'Cuenta bancaria por defecto para desembolsos'}
                options={accounts}
                value={cValue}
                onChange={(value) => setToggle({...toggleW, [parameter]: value})}
            /> :
            <span>{account ? account.label : 'Sin Cuenta Bancaria'}</span>;
    };

    const warehouseCompanyButton = () => {
        return (<div style={{position: 'absolute', top: 10, right: 15}}>{toggleW.edit ? <Row className={'container-buttons'}>
            <Icon
                color={'red'}
                size={'2x'}
                icon={faWindowClose}
                tooltip={'Cancelar'}
                disabled={loadingUL}
                onClick={() => setToggle({...toggleW, edit: false})}
            />
            <Icon
                style={{marginLeft: 5}}
                color={'green'}
                size={'2x'}
                icon={faCheckDouble}
                tooltip={'Guardar'}
                spin={loadingUL}
                onClick={() => {
                    let PWarehouseId, TWarehouseId, PBankAccountId;
                    if (module === 2000) {
                        PWarehouseId = toggleW.puWarehouse.id;
                        PBankAccountId = toggleW.puBankAccount ? toggleW.puBankAccount.id : null;
                    }
                    else TWarehouseId = toggleW.tuWarehouse && toggleW.tuWarehouse.id;

                    dispatch(onUpdateLink(profile.link.id, {PWarehouseId,TWarehouseId, PBankAccountId}));
                }}
            />
        </Row> : <Icon
            size={'2x'}
            icon={faEdit}
            tooltip={'Actualizar datos'}
            onClick={() => {
                setToggle({...toggleW, edit: true});
                dispatch(getWarehouses(false, true));
            }}
        />}
        </div>);
    };

    const getheadersProduct = () => {

        const headers = []

        if (linkType !== 2) {
            headers.push({
                label: '', show: true, select:true, simpleChecked:true, custom: (item) => validateParcelTableButton(1) &&
                    <label className={'checkbox-label'}>
                        <input type={'checkbox'} name={'kolo'} checked={item.selected} onChange={(target) => { 
                            if (validateParcelTableButton(1) && products.filter((p) => p.selected && p.edit).length <= 0&& target.value === undefined && !loadingP)
                            onSetValueProduct(item, 5, 0);
                        }} />
                    </label>, classNameCustom: (item) => `mini ` + (item.selected ? 'active' : '')
            })
        }
        headers.push(...[
            { label: 'Código', show: true, value: ['productCode'], type: 'text', className: 'mini' },
            { label: 'Producto', show: true, value: ['productName'], type: 'text', className: 'mini' }
        ])

        if(linkType !== 2){
            if(linkType === 1){
                headers.push(...[
                    {
                        label: 'Inv. Minimo', show: true, value: ['min'], type: 'text', className: 'short right',
                        custom: (item) => (item.selected && item.edit) ? <FormTextField
                            disabled={loadingP}
                            value={item.eMin}
                            onMouseOver={(e) => e.stopPropagation()}
                            onClick={(e) => e.stopPropagation()}
                            type={'number'}
                            onChange={(event) => {
                                event.stopPropagation();
                                const { value } = event.target;
                                onSetValueProduct(item, 4, !value ? 0 : value < 0 ? 0 : value, 'eMin');
                            }}
                            className={'edit-input'}
                        /> : item.min
                    },
                    {
                        label: 'Inv. Maximo', show: true, value: ['max'], type: 'text', className: 'mini', 
                        custom: (item) => (item.selected && item.edit) ? <FormTextField
                            disabled={loadingP}
                            value={item.eMax}
                            onMouseOver={(e) => e.stopPropagation()}
                            onClick={(e) => e.stopPropagation()}
                            type={'number'}
                            onChange={(event) => {
                                event.stopPropagation();
                                const { value } = event.target;
                                onSetValueProduct(item, 4, !value ? 0 : value < 0 ? 0 : value, 'eMax');
                            }}
                            className={'edit-input'}
                        /> : item.max
                    }
                ])
            }
            headers.push({
                label: 'Paquete', show: true, value: ['paqueteC'], type: 'text', className: 'medium',
                custom: (item) => (item.selected && item.edit) ? <Select
                    withoutLabel
                    options={Object.assign([], pParcels.map((p) => ({ ...p, value: p.id, label: p.name })))}
                    value={getParcelValue(item)}
                    onChange={(value) => onSetValueProduct(item, 4, value.id, 'parcelId')}
                /> : item.paqueteC  = getParcelValue(item).label
            })
            if(linkType !== 5){
                headers.push({ label: 'Pago', show: true, value: ['pagoC'], type: 'currency', className: 'mini', custom: (item) => item.pagoC = getParcelValue(item).amount })
            }
        }
        if(linkType === 2 ){
            headers.push({label: 'Pago', show: true, value: ['amount'], type: 'text' ,  className:'mini'})
        }

        headers.push({
            config: true, show: true, label: '', className: 'mini center', custom: (item) => <Icon
                icon={faWarehouse} tooltip={'ver inventario consignado en este enlace'}
                onClick={() => {
                    setModalE({ ...item, show: true });
                    dispatch(getInventoryExistence(profile.link.id, item.productId));
                }}
            />
        })
        return headers;
       
    }

   

    const getBalanceStructure = () => {
        let available = 0, ret = 0, dep = 0;

        if (profile && profile.balance) {
            const {balanceT, balanceTRet, balanceP, balancePRet, balancePDep, balanceTDep} = profile.balance;

            if (module === 2000) {
                available = balanceP;
                ret = balancePRet;
                dep = balancePDep;
            } else {
                available = balanceT;
                ret = balanceTRet;
                dep = balanceTDep;
            }
        }

        return {available, ret, dep};
    };

    const getLinkTypeSelected = () => {
        let value = 0;
        let label = 'No seleccioando';

        if (linkType) {
            const typeSelected = typesAvailable.find((ta) => ta.id === linkType);
            if (typeSelected) {
                value = typeSelected.id;
                label = typeSelected.name;
            }
        }

        return {value, label};
    };

    return (<div className={'column'}>
        <h1 className={'dashboard-welcome'}>{title}</h1>
        {!(typeL !== 1 && typeL !== 2) && <Row><Col xl={4} lg={4} md={4} sm={12} xs={12}>
            <Select
                options={Object.assign([], typesAvailable.map((ta) => ({...ta, value: ta.id, label: ta.name})))}
                value={getLinkTypeSelected()}
                label={'Enlace seleccionado'}
                onChange={(ta) => setUp(ta.id)}
            />
        </Col></Row>}
        {/*
        {!(typeL !== 1 && typeL !== 2) && <ButtonGroup aria-label={'Basic e'}>
            {
                typesAvailable.map((t, i) =>
                    <Button
                        key={t.id}
                        style={{backgroundColor: linkType ? linkType === t.id ? 'rgb(73 197 86)' :
                                'rgb(33 139 208)' : 'rgb(33 139 208)', border: '1px solid white'}}
                        onClick={() => setUp(t.id)}
                    >{t.name}</Button>
                )
            }
        </ButtonGroup>}
        */}
        <br/>

        <Row>
            <Col xl={isShow ? 12  : 4} lg={isShow ? 12  : 4} md={isShow ? 12  : 5} sm={isShow ? 12  : 5} xs={12}><Row>
                <Col xl={isShow ? 6 : 12} lg={isShow ? 6 : 12} md={isShow ? 6 : 12} sm={isShow ? 6 : 12} xs={12}><Card>
                    <div className={'justify-center column'}>
                        <span>Transportista</span>
                        <h4>{profile.transportistName}</h4>
                        {warehouseCompany(toggleW.tWarehouse, 1000, 'tuWarehouse')}
                    </div>
                    {(module === 1000 && profile.transportistId === company) && warehouseCompanyButton()}
                </Card>
                </Col>
                {!isShow && <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    {getLinksZones()}
                </Col>}
                {isShow && <Col xl={6} lg={6} md={6} sm={6} xs={12}><Card>
                    <div className={'justify-center column'}>
                        <span>Productor</span>
                        <h4>{profile.producerName}</h4>
                        {warehouseCompany(toggleW.pWarehouse, 2000, 'puWarehouse')}
                        {accountCompany(profile.link ? profile.link.PAccount : null, 2000, 'puBankAccount')}
                    </div>

                    {(module === 2000 && profile.producerId === company) &&
                        warehouseCompanyButton()}
                </Card>
                </Col>}
            </Row></Col>
            <Col><Row>
                {isShow && <Col xl={4} lg={4} md={4} sm={4} xs={12}>{getLinksZones()}</Col>}
                <Col><CustomTabs loading={loading} items={[
                    {title: 'Mapa', info: 'Selecciona zonas de transporte para poder visualizarlos en el mapa',
                    component: <MapCard
                        noCollapse
                        latitude={latitude}
                        longitude={longitude}
                        height={600}
                        startCollapsed={false}
                        markerList={[]}
                        zoom={7}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({map, maps}) => {
                            setGoogle({map, maps, polygons: []});
                            setPolygons(selected);
                        }}
                    />},
                    {title: 'Productos', info: 'Seleccionar zonas de transporte para poder visualizar los productos contratados',
                        show: isShow, component: () => {
                        return (<div className={'column'}>
                            {validateParcelTableButton(1) && <Row className={'container-buttons'}>
                                {products.filter((p) => p.selected && !p.edit).length > 0 ?
                                    <Button
                                        variant={'info'}
                                        icon={faEdit}
                                        onClick={() => {
                                            products.filter((p) => p.selected).map((p) => (onSetValueProduct(p, 6, 0, 'editable')));
                                            if (parcels.length <= 0)
                                                dispatch(getAllByCompany());
                                        }}
                                    >Editar</Button> : products.filter((p) => p.selected && p.edit).length > 0 && <>

                                    <Button
                                        disabled={loadingP}
                                        variant={'danger'}
                                        icon={faWindowClose}
                                        onClick={() => products.filter((p) => p.selected).map((p) => (onSetValueProduct(p, 5, 0)))}
                                    >Cancelar</Button>

                                    <Button
                                        loading={loadingP}
                                        variant={'info'}
                                        icon={faSave}
                                        onClick={() => {
                                            const request = products.filter((p) => p.selected).map((p) => ({
                                                offerProductId: p.productId,
                                                minimum: p.eMin || 0,
                                                maximum: p.eMax,
                                                parcelId: p.parcelId
                                            }));

                                            dispatch(onSetMaxQuantity(request, module === 1000 ? profile.producerId : profile.transportistId, {linkType}, module));
                                        }}
                                    >Guardar</Button>
                                </>
                                }
                            </Row>}
                            <TableV2
                                loading={loading}
                                items={products}
                                headers = {getheadersProduct()}
                                mobileAuto
                                storageKey={`productPagos`}
                                onClickTr = {(target,item) => {
                                    if (validateParcelTableButton(1) && products.filter((p) => p.selected && p.edit).length <= 0&& target.value === undefined && !loadingP)
                                        onSetValueProduct(item, 5, 0);
                                }}
                            />
                            
                        </div>);
                    }},
                    {title: 'Paquetes kolo', info: 'Paquetes kolo', show: (isShow && (linkType !== 2)),
                        component: () => parcelTable(2, pParcels)},
                    {title: linkType === 1 ?
                            'Paquetes de recolección' : 'Paquetes de Entregas', info: 'Paquetes de recolección', show: (isShow && (linkType !== 2 && linkType !== 4 && linkType !== 5)),
                        component: () => parcelTable(3, pRParcels)},
                    {show: (isShow || (typeL === 4 && linkCompany === company)), title: 'Balance', info: 'Balance generado por el enlace',
                        component: (<BalanceTable
                            principal={{xl: 12, lg: 12, md: 12, sm: 12, xs: 12}}
                            second={{xl: 12, lg: 12, md: 12, sm: 12, xs: 12}}
                            third={{xl: 4, lg: 4, md: 6, sm: 6, xs: 6}}
                            balance={getBalanceStructure()}
                            kolo={true}
                            balanceType={-1}
                            available ret dep
                            linkId={profile.link ? profile.link.id : 18888}/>)
                    },
                ]}/></Col>
            </Row></Col>
        </Row>

        <Modal
            show={seePayments.show}
            size={'md'}
            centered
            onHide={() => setSeePayments({...seePayments, show: false})}>

            <Modal.Header closeButton>
                <Modal.Title>Zona de transporte {seePayments.item.polygonName}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form><Row>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        {/*{(linkType === 1 || !isShow) && <FormText
                            disabled={isShow || !seePayments.edit || loadingP || loadingO}
                            prepend={'%'}
                            label={'Costo por gestion logística'}
                            name={'paymentPerPercentage'}
                            type={'number'}
                            value={seePayments.setting.paymentPerPercentage}
                            onChange={setSetting}
                            sub={'Pago a KOLO por porcentaje de la factura'}
                        />}*/}
                        <br/>
                        {(linkType === 2 || !isShow) && <FormText
                            disabled={!seePayments.edit || loadingP || loadingO}
                            prepend={'Q'}
                            label={'Pago por factura entregada'}
                            name={'paymentPerInvoice'}
                            type={'number'}
                            value={seePayments.setting.paymentPerInvoice}
                            onChange={setSetting}
                        />}
                    </Col>
                </Row></Form>
            </Modal.Body>
            {seePayments.edit && <Modal.Footer><Row className={'container-buttons'}>
                <Button loading={loadingP || loadingO} icon={faEdit} variant={'info'} right onClick={() => {
                    setAlert({
                        show: true,
                        type: 'warning',
                        title: 'Pagos de transporte',
                        text: `¿Desea actualizar los pagos de transporte de la zona seleccionada? ${(typeL === 1 || typeL === 2) ? 'Se enviara una solicitud con los cambios establecidos' : 'Está acción no modificara los pagos de transporte de los enlaces actuales'}`,
                        showCancelButton: true,
                        onCancel: () => setAlert({...alert, show: false}),
                        onConfirm: () => {
                            setAlert({...alert, show: false});
                            if (typeL === 1 || typeL === 2) {
                                dispatch(onSendRequestSettingLInk(seePayments.item.offerId, seePayments.setting, module));
                            } else
                                dispatch(onUpdatePolygonSetting(seePayments.item.polygonId, seePayments.setting));
                        }
                    });
                }}>Editar</Button>
            </Row></Modal.Footer>}
        </Modal>

        <Modal
            show={modalAddParcel.show}
            size={'md'}
            centered
            onHide={() => setModalAddParcel({...modalAddParcel, show: false, amount: 0})}>

            <Modal.Header><Modal.Title>Agregar paquete</Modal.Title></Modal.Header>
            <Modal.Body><div className={'column'}>
                <Select
                    disabled={loadingSP}
                    loading={loadingPa}
                    label={`Ingresa un paquete ${modalAddParcel.type === 2 ? 'kolo' : 'de recolección'}`}
                    options={parcels.filter(filterP)}
                    value={modalAddParcel.value}
                    required
                    onChange={(value) => setModalAddParcel({...modalAddParcel, value})}
                />
                {linkType === 1 && <FormText
                    disabled={loadingSP}
                    prepend={'Q'}
                    value={modalAddParcel.amount}
                    placeholder={'pj. 10'}
                    label={'Es necesario ingresar un monto para el pago del paquete'}
                    required
                    type={'number'}
                    onChange={({target}) => {
                        let {value} = target;
                        if (!value || value < 0)
                            value = 0;
                        setModalAddParcel({...modalAddParcel, amount: value});
                    }}
                />}
            </div></Modal.Body>
            <Modal.Footer><Row className={'container-buttons'}>
                <Button
                    disabled={(linkType === 1 && (!modalAddParcel.amount || modalAddParcel.amount <= 0)) || !modalAddParcel.value}
                    loading={loadingSP}
                    variant={'info'}
                    icon={faPlus}
                    onClick={() => {
                        /*
                        setSeePayments({
                            ...seePayments,
                            setting: {
                                ...seePayments.setting,
                                parcels: [...seePayments.setting.parcels, {
                                    ...modalAddParcel.item,
                                    parcelId: modalAddParcel.item.id,
                                    amount: modalAddParcel.amount
                                }]
                            }
                        });
                        */
                        //setModalAddParcel({...modalAddParcel, show: false, amount: 0, value: {}});
                        let companyLinked = module === 1000 ? profile.producerId : profile.transportistId;
                        const request = [{parcelId: modalAddParcel.value.id, amount: modalAddParcel.amount || 0, type: modalAddParcel.type - 1}];

                        dispatch(onSetParcel(companyLinked, request, {linkType}, module));
                    }}
                >Agregar paquete</Button>
            </Row></Modal.Footer>
        </Modal>

        <Modal show={modalE.show} size={'md'} centered onHide={() => setModalE({show: false})}>
            <Modal.Header closeButton><Modal.Title>Existencia del Producto: {modalE.productName}</Modal.Title></Modal.Header>
            <Modal.Body>
                <TableV2
                hideFilter
                loading={loadingE}
                items={existence}
                renderRow={(item, index) => <div key={index} className={'b-user-item'}>
                    <div className={'justify-content-start align-items-start bu-en'} style={{textAlign: "left"}}>
                        <div className={'b-user-name'}>{item.warehouseName}</div>
                        <div className={'b-user-email'}>{item.warehouseCode}</div>
                        <div className={'b-user-balance-m'}>{item.productExistence}</div>
                    </div>
                    <div className={"d-flex row align-items-center mr-2 bu-b"} style={{textAlign: "right"}}>
                        <div className={"b-user-balance mr-2"}>{item.productExistence}</div>
                    </div>
                </div>}
            /></Modal.Body>
        </Modal>

        <ParcelCEV {...cev} onHide={() => {
            setCEV({...cev, show: false});
        }}/>

        <Alert {...alert}/>
    </div>);
};
export default KoloLinkProfile;
