import React, { useState, useEffect } from 'react'
import { Row, Col, Modal } from 'react-bootstrap'

import './OrdersMap.scss'

import Button from 'src/components/buttons/Button'
import MapCard from 'src/components/maps/MapCard'

import { faTimesCircle, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import Marker from 'src/components/maps/Marker'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import IconButton from 'src/components/buttons/IconButton'

const latitude = 14.6426439
const longitude = -90.5131097

const OrdersMap = props => {
  const [show, setShow] = useState(false)
  const [orders, setOrders] = useState([])

  const [locations, setLocations] = useState([])
  const [mapInstance, setMapInstance] = useState(null)
  const [mapsApi, setMapsApi] = useState(null)

  function onGoogleApiLoaded(map, maps) {
    setMapInstance(map)
    setMapsApi(maps)
  }

  useEffect(() => {
    if (props.show) setShow(props.show)
    else setLocations([])
    setOrders([])
  }, [props])

  useEffect(() => {
    if (show) {
      if (props.orders) {
        setOrders(props.orders)
      }
    }
  }, [show])

  useEffect(() => {
    if (orders.length > 0) {
      console.log('ORDERS::', orders)

      setLocations(
        orders.map((item, index) => {
          return {
            latitude: item.latitude,
            longitude: item.longitude,
            order: item.number,
            clientName: item.clientName || item.client.name,
            statusName: item.statusName || item.statusData.name,
            address: item.client ? item.client.address : '',
          }
        }),
      )
    }
  }, [orders])

  const onHide = () => {
    setShow(false)
    props.onHide()
  }

  return (
    <div className={'column'}>
      <Modal show={show} centered size={'lg'} onHide={() => onHide([], 1)}>
        <Modal.Header>
          <Modal.Title>Órdenes</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className={'column'}>
            <MapCard
              noCollapse
              latitude={latitude}
              longitude={longitude}
              height={600}
              startCollapsed={false}
              zoom={12}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => onGoogleApiLoaded(map, maps)}
            >
              {locations.map((item, index) => (
                <div
                  key={`${index}`}
                  lat={Number(item.latitude)}
                  lng={Number(item.longitude)}
                  style={{ marginLeft: -24 }}
                >
                  <OverlayTrigger
                    trigger="click"
                    rootClose={true}
                    container={this}
                    placement="top"
                    overlay={
                      <Popover id="popover-basic" style={{ minWidth: 200 }}>
                        <IconButton
                          className="om-pop-close"
                          icon={faTimesCircle}
                          color={'#226095'}
                          onClick={() => document.body.click()}
                        />
                        <Popover.Title as="h3">Orden {item.order}</Popover.Title>
                        <Popover.Content>
                          <div className="om-name">{item.clientName}</div>
                          <div className="om-address">{item.address}</div>
                          <div className="om-status">{item.statusName}</div>
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <div>
                      <Marker />
                    </div>
                  </OverlayTrigger>
                </div>
              ))}
            </MapCard>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'button-container'}>
            <Button left icon={faWindowClose} onClick={() => onHide()}>
              Cerrar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default OrdersMap
