import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Row, Col } from 'react-bootstrap'
import { faBackspace, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'

import Select from 'src/components/inputs/Select/CustomSelect'
import MapCard from 'src/components/maps/MapCard'
import Button from 'src/components/buttons/Button'

import { getAllPolygons, getPolygonByCategory } from 'src/actions/polygons.actions'
import { selectAllPolygonsBySelect } from 'src/selectors/polygons.selector'

import { onSetTransportPayment } from 'src/actions/products.actions'
import { selectTransportPayment } from 'src/selectors/products.selector'

const latitude = 14.589465440976774
const longitude = -90.51898866891861

class TransportPaymentPolygon extends Component {
  state = {
    mapS: null,
    mapsS: null,
    manualPolygon: null,
  }

  onGoogleApiLoaded = (map, maps) => {
    setTimeout(() => {
      const { transportPayment } = this.props
      this.setState({ mapS: map, mapsS: maps })
      if (transportPayment.polygon && transportPayment.polygon.value) {
        this.setPolygon(transportPayment.polygon)
      }
    }, 1000)
  }

  setPolygon = polygon => {
    if (polygon.value) {
      let { mapS, mapsS, manualPolygon } = this.state
      if (manualPolygon) manualPolygon.setMap(null)
      if ((mapS, mapsS)) {
        const points = polygon.points.map(p => ({
          lat: Number(p.latitude),
          lng: Number(p.longitude),
        }))
        manualPolygon = new mapsS.Polygon({
          path: points,
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.35,
        })

        const center = this.getCenter(points)
        if (center.latLng.lat > 0) mapS.panTo(center.latLng)
        manualPolygon.setMap(mapS)
        this.setState({ manualPolygon })
      }
    }
  }

  getCenter = paths => {
    const center = paths
      .map(p => {
        return [p.lat, p.lng]
      })
      .reduce(
        (x, y) => {
          return [x[0] + y[0] / paths.length, x[1] + y[1] / paths.length]
        },
        [0, 0],
      )
    return { latLng: { lat: center[0], lng: center[1] } }
  }

  render() {
    const { polygons, transportPayment, setInfo } = this.props

    return (
      <div className={'column'}>
        <Row>
          <Col md={3}>
            <br />
            <br />
            <label className={'checkbox-label'}>
              <input
                type={'checkbox'}
                name={'kolo'}
                checked={transportPayment.kolo}
                onChange={({ target }) => {
                  setInfo({
                    ...transportPayment,
                    kolo: target.checked,
                    polygon: { value: null, label: 'No seleccionado' },
                  })
                  if (target.checked) this.props.getPolygonByCategory()
                  else this.props.getAllPolygons()
                }}
              />{' '}
              {transportPayment.kolo ? 'Activo' : 'Desactivado'}: Utilizar poligonos kolo
              para listar productos.
            </label>
          </Col>
          <Col>
            <Select
              label={'Poligonos de ofertas'}
              options={polygons}
              value={transportPayment.polygon}
              onChange={polygon => {
                setInfo({ ...transportPayment, polygon })
                this.setPolygon(polygon)
              }}
            />
          </Col>
        </Row>

        <MapCard
          title={transportPayment.polygon.label}
          latitude={latitude}
          longitude={longitude}
          height={500}
          startCollapsed={false}
          markerList={[]}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.onGoogleApiLoaded(map, maps)}
        />

        <Row className={'container-buttons'}>
          <Button onClick={() => window.history.back()} icon={faBackspace}>
            Salir
          </Button>
          <Button
            disabled={!transportPayment.polygon.value}
            onClick={() => this.props.jumpToStep(1)}
            icon={faLongArrowAltRight}
          >
            Siguiente
          </Button>
        </Row>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  polygons: selectAllPolygonsBySelect(state),
  transportPayment: selectTransportPayment(state),
})
const mapDispatchToProps = dispatch => ({
  setInfo: info => dispatch(onSetTransportPayment(info)),
  getPolygonByCategory: () => dispatch(getPolygonByCategory(5)),
  getAllPolygons: () => dispatch(getAllPolygons()),
})
export default connect(mapStateToProps, mapDispatchToProps)(TransportPaymentPolygon)
