import ResolutionsService from '../services/resolutions.service'
import { executeAction } from './global.actions'

export const actionTypes = {
  GET_DOCUMENTS_TYPES: 'GET_DOCUMENTS_TYPES',
  GET_RESOLUTIONS: 'GET_RESOLUTIONS',
  GET_RESOLUTIONS_LIMIT: 'GET_RESOLUTIONS_LIMIT',
  ON_CREATE_RESOLUTION: 'ON_CREATE_RESOLUTION',
  GET_BILLS: 'GET_BILLS',
  CANCEL_BILL: 'CANCEL_BILL',
  GET_FREE_RESOLUTIONS: 'GET_FREE_RESOLUTIONS',
  GET_COMPANY_DOCUMENT_REQUESTS: 'GET_COMPANY_DOCUMENT_REQUESTS',
  GET_ALL_DOCUMENT_REQUESTS: 'GET_ALL_DOCUMENT_REQUESTS',
  GET_DOCUMENT_REQUEST: 'GET_DOCUMENT_REQUEST',
  CANCEL_DOCUMENT_REQUEST: 'CANCEL_DOCUMENT_REQUEST',
  CREATE_DOCUMENT_REQUEST: 'CREATE_DOCUMENT_REQUEST',
  MARK_DOCUMENT_REQUEST_AS_DONE: 'MARK_DOCUMENT_REQUEST_AS_DONE',
  DOWNLOAD_DOCUMENTS: 'DOWNLOAD_DOCUMENTS',
  DOWNLOAD_PRINTABLE_INVOICE: 'DOWNLOAD_PRINTABLE_INVOICE',
  DOWNLOAD_PRINTABLE_INVOICE_BY_ORDER: 'DOWNLOAD_PRINTABLE_INVOICE_BY_ORDER',
  SEND_INVOICE_EMAIL: 'SEND_INVOICE_EMAIL',
  GET_FELDATA: 'GET_FELDATA',
  SET_FELDATA: 'SET_FELDATA',
  GET_ADMIN_FELDATA: 'GET_ADMIN_FELDATA',
  TOOGLE_ADMIN_FELDATA: 'TOOGLE_ADMIN_FELDATA',
  GET_INVOICE_PROFILE: 'GET_INVOICE_PROFILE',
  GET_INVOICE_PROFILE_OPTIONS: 'GET_INVOICE_PROFILE_OPTIONS',
  UPDATE_INVOICE_PROFILE: 'UPDATE_INVOICE_PROFILE',
  GET_SINGLE_INVOICE: 'GET_SINGLE_INVOICE',
  GET_PHRASES: 'GET_PHRASES',
  GET_COMPANY_PHRASES: 'GET_COMPANY_PHRASES',
  SET_COMPANY_PHRASES: 'SET_COMPANY_PHRASES',
  EXPORT_DTE: 'EXPORT_DTE',
  DELETE_RESOLUTION: 'DELETE_RESOLUTION',
}

export const getBills = () => dispatch => {
  const process = async () => ({
    bills: await ResolutionsService.getInvoices(),
  })
  dispatch(executeAction(actionTypes.GET_BILLS, process))
}

export const cancelBill = id => dispatch => {
  const process = async () => ({
    bill: await ResolutionsService.cancelInvoice(id),
  })
  dispatch(executeAction(actionTypes.CANCEL_BILL, process))
}

export const getDocumentsType = () => dispatch => {
  const process = async () => ({
    documents: await ResolutionsService.getDocumentsTypes(),
  })
  dispatch(executeAction(actionTypes.GET_DOCUMENTS_TYPES, process))
}

export const getResolutions = () => dispatch => {
  const process = async () => ({
    resolutions: await ResolutionsService.getResolutions(),
  })
  dispatch(executeAction(actionTypes.GET_RESOLUTIONS, process))
}

export const getFreeResolutions = () => dispatch => {
  const process = async () => ({
    freeResolutions: await ResolutionsService.getFreeResolutions(),
  })
  dispatch(executeAction(actionTypes.GET_FREE_RESOLUTIONS, process))
}

export const getResolutionsLimit = (docType, series) => dispatch => {
  const process = async () => ({
    limit: await ResolutionsService.getResolutionsLimit(docType, series),
  })
  dispatch(executeAction(actionTypes.GET_RESOLUTIONS_LIMIT, process))
}

export const onCreateResolution = (id, request) => dispatch => {
  const process = async () => ({
    createResponse: await ResolutionsService.onCreateResolution(id, request),
  })
  dispatch(executeAction(actionTypes.ON_CREATE_RESOLUTION, process))
}

export const getCompanyDocumentRequests = () => dispatch => {
  const process = async () => ({
    documentRequests: await ResolutionsService.getCompanyDocumentsRequests(),
  })
  dispatch(executeAction(actionTypes.GET_COMPANY_DOCUMENT_REQUESTS, process))
}

export const getAllDocumentRequests = () => dispatch => {
  const process = async () => ({
    documentRequests: await ResolutionsService.getAllDocumentsRequests(),
  })
  dispatch(executeAction(actionTypes.GET_ALL_DOCUMENT_REQUESTS, process))
}

export const getDocumentRequest = id => dispatch => {
  const process = async () => ({
    documentRequest: await ResolutionsService.getDocumentRequest(id),
  })
  dispatch(executeAction(actionTypes.GET_DOCUMENT_REQUEST, process))
}

export const cancelDocumentRequest = id => dispatch => {
  const process = async () => ({
    documentRequest: await ResolutionsService.cancelDocumentRequest(id),
  })
  dispatch(executeAction(actionTypes.CANCEL_DOCUMENT_REQUEST, process))
}

export const createDocumentRequest =
  (contactName, contactPhone, contactEmail, signInName, signInPhone, sigInJob) =>
  dispatch => {
    const process = async () => ({
      documentRequest: await ResolutionsService.createDocumentRequest(
        contactName,
        contactPhone,
        contactEmail,
        signInName,
        signInPhone,
        sigInJob,
      ),
    })
    dispatch(executeAction(actionTypes.CREATE_DOCUMENT_REQUEST, process))
  }

export const markDocumentRequestAsDone = id => dispatch => {
  const process = async () => ({
    documentRequest: await ResolutionsService.markDocumentRequestAsDone(id),
  })
  dispatch(executeAction(actionTypes.MARK_DOCUMENT_REQUEST_AS_DONE, process))
}

export const downloadRequestDocuments = id => dispatch => {
  const process = async () => await ResolutionsService.downloadDocuments(id)
  dispatch(executeAction(actionTypes.DOWNLOAD_DOCUMENTS, process))
}

export const downloadPrintableInvoice = (id, defaultName, module) => dispatch => {
  const process = async () =>
    await ResolutionsService.downloadPrintableInvoice(id, defaultName, module)
  dispatch(executeAction(actionTypes.DOWNLOAD_PRINTABLE_INVOICE, process))
}

export const downloadPrintableInvoiceByOrder =
  (id, defaultName, module, documentType, isCreditNote, save = true) =>
  dispatch => {
    const process = async () =>
      await ResolutionsService.downloadPrintableInvoiceByOrder(
        id,
        defaultName,
        module,
        documentType,
        isCreditNote,
        save,
      )

    return dispatch(
      executeAction(actionTypes.DOWNLOAD_PRINTABLE_INVOICE_BY_ORDER, process, true),
    )
  }

export const sendInvoiceEmail = (invoice, purchase) => dispatch => {
  const process = async () =>
    await ResolutionsService.sendInvoiceEmailRequest(invoice, purchase)
  dispatch(executeAction(actionTypes.SEND_INVOICE_EMAIL, process))
}

export const cancelInvoiceAndOrder =
  (invoice, order, returnProduct, account, balance, voucher, creditNote, request) =>
  dispatch => {
    const process = async () =>
      await ResolutionsService.cancelInvoiceAndOrder(
        invoice,
        order,
        returnProduct,
        account,
        balance,
        voucher,
        creditNote,
        request,
      )
    dispatch(executeAction(actionTypes.CANCEL_BILL, process))
  }

export const getFELData = () => dispatch => {
  const process = async () => ({
    felData: await ResolutionsService.getFELData(),
  })
  dispatch(executeAction(actionTypes.GET_FELDATA, process))
}

export const createFELData =
  (company, edxArea, edxPassword, g4sRequestor, g4sUserName, infileUser, infileKey) =>
  dispatch => {
    const process = async () => ({
      felData: await ResolutionsService.createFELData({
        company,
        edxArea,
        edxPassword,
        g4sRequestor,
        g4sUserName,
        infileUser,
        infileKey,
      }),
    })
    dispatch(executeAction(actionTypes.SET_FELDATA, process))
  }

export const getAdminFelData = () => dispatch => {
  const process = async () => ({
    adminFelData: await ResolutionsService.getAdminFELInfo(),
  })
  dispatch(executeAction(actionTypes.GET_ADMIN_FELDATA, process))
}

export const toogleAdminFelData = id => dispatch => {
  const process = async () => ({
    changedFelData: await ResolutionsService.toogleAdminFELInfo(id),
  })
  dispatch(executeAction(actionTypes.TOOGLE_ADMIN_FELDATA, process))
}

export const getInvoiceProfileOPtions = () => dispatch => {
  const process = async () => ({
    profileOptions: await ResolutionsService.getInvoiceProfileOPtions(),
  })
  dispatch(executeAction(actionTypes.GET_INVOICE_PROFILE_OPTIONS, process))
}

export const getInvoiceProfile = () => dispatch => {
  const process = async () => ({
    profile: await ResolutionsService.getInvoiceProfile(),
  })
  dispatch(executeAction(actionTypes.GET_INVOICE_PROFILE, process))
}

export const onUpdateInvoiceProfile = (profileId, sellOwnProduct) => dispatch => {
  const process = async () => ({
    profile: await ResolutionsService.onUpdateInvoiceProfile(profileId, sellOwnProduct),
  })
  dispatch(executeAction(actionTypes.UPDATE_INVOICE_PROFILE, process))
}

export const getSingleInvoice = id => dispatch => {
  const process = async () => ({
    invoice: await ResolutionsService.getSingleInvoice(id),
  })
  dispatch(executeAction(actionTypes.GET_SINGLE_INVOICE, process))
}

export const getPhaseTypes = type => dispatch => {
  const process = async () => ({
    phrases: await ResolutionsService.getPhaseTypes(type),
  })
  dispatch(executeAction(actionTypes.GET_PHRASES, process))
}

export const getCompanyPhrases = () => dispatch => {
  const process = async () => ({
    companyPhrases: await ResolutionsService.getCompanyPhrases(),
  })
  dispatch(executeAction(actionTypes.GET_COMPANY_PHRASES, process))
}

export const setCompanyPhrases = phrases => dispatch => {
  const process = async () => await ResolutionsService.setCompanyPhrases(phrases)
  dispatch(executeAction(actionTypes.SET_COMPANY_PHRASES, process))
}

export const exportDTE = request => dispatch => {
  const process = async () => await ResolutionsService.exportDTE(request)
  dispatch(executeAction(actionTypes.EXPORT_DTE, process))
}

export const exportDTEProducts = request => dispatch => {
  const process = async () => await ResolutionsService.exportDTEProducts(request)
  dispatch(executeAction(actionTypes.EXPORT_DTE, process))
}

/**
 * Action to nullify order and invoice
 * @param {number} orderId Order id to nullify
 * @param {boolean} returnProduct If the product should be returned
 * @returns
 */
export const nullifyInvoiceAndOrder = (orderId, params) => dispatch => {
  const process = async () =>
    await ResolutionsService.nullifyInvoiceAndOrder(orderId, params)
  dispatch(executeAction(actionTypes.CANCEL_BILL, process))
}

export const deleteResolution = resolutionId => dispatch => {
  const process = async () => await ResolutionsService.deleteResolution(resolutionId)
  dispatch(executeAction(actionTypes.DELETE_RESOLUTION, process))
}
