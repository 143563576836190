import { $http } from './http'

class UploadService {
  getImages = async (imageTypeId, parentId, companyId = null) => {
    companyId = companyId ? companyId : null
    const response = await $http.get(
      `/images/upload?imageTypeId=${imageTypeId}&parentId=${parentId}&companyId=${companyId}`,
    )
    return response.data
  }

  addImage = async (imageTypeId, parentId, url, name, description, video) => {
    const response = await $http.post('/images/upload/single', {
      imageTypeId,
      parentId,
      url,
      name,
      description,
      video,
    })
    return response.data
  }

  addImages = async (imageTypeId, parentId, images) => {
    let request = images.map(item => ({ ...item, imageTypeId, parentId }))
    const response = await $http.post('/images/upload/multiple', request)
    return response.data
  }

  deleteImage = async (imageTypeId, parentId, imageUploadId) => {
    const response = await $http.delete(
      `/images/upload/${imageUploadId}?imageTypeId=${imageTypeId}&parentId=${parentId}`,
    )
    return response.data
  }

  getDocuments = async () => {
    const response = await $http.get(`/files/upload/legaldocs`)
    return response.data
  }

  addDocument = async (legalDocumentType, url, name) => {
    const response = await $http.post('/files/upload', {
      legalDocumentType,
      url,
      name,
    })
    return response.data
  }
}

export default new UploadService()
