import { actions } from '../actions/taxes.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  taxes: [],
  tax: {},
}

const TaxesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case successState(actions.GET_ALL_TAXES):
      return { ...state, taxes: extract(payload, []) }
    case successState(actions.UPDATE_TAX_OR_CREATE_TAX):
      return { ...state, tax: extract(payload, {}) }
    default:
      return state ? state : initialState
  }
}
export default TaxesReducer
