import 'react-tabs/style/react-tabs.css'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Col, Modal, Row } from 'react-bootstrap'
import { faPlus, faSave, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import Alert from 'sweetalert-react'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Media } from 'react-breakpoints'

import {
  Button,
  Card,
  FormText,
  Icon,
  ProductItem,
  ProductItems,
  Select,
  TableV2,
} from '../../index'

import { onRefresh, setOrderProcessId } from '../../../actions/utilities.actions'
import { selectModalOrderProcessId } from '../../../selectors/utilities.selector'

import {
  actionTypes as typeO,
  changeDataOrder,
  getExistenceByOrder,
  getSingleOrder,
  onProcess,
} from '../../../actions/orders.actions'
import {
  selectGetSingleOrder,
  selectProductExistence,
} from '../../../selectors/orders.selector'

import {
  actionTypes as typeW,
  getWarehouseLite,
} from '../../../actions/warehouse.actions'
import { selectOwnWarehouseLite } from '../../../selectors/warehouse.selector'
import { selectCompanyField } from '../../../selectors/company.selector'

import { loadingSelector } from '../../../selectors/loading.selector'

import {
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
  singleErrorSelector,
} from '../../../selectors/error.selector'
import Gallery, { imageTypes } from '../../gallery/Gallery'
import IconPlus from '../../../assets/images/newDesign/icon-plus.svg'

const ModalOrderProcess = () => {
  const dispatch = useDispatch()

  const orderId = useSelector(selectModalOrderProcessId)
  const warehouses = useSelector(selectOwnWarehouseLite)
  const facType = useSelector(selectCompanyField)

  const order = useSelector(selectGetSingleOrder)
  const loadingO = useSelector(state => loadingSelector([typeO.ON_PROCESS_ORDER])(state))
  const hasErrorO = useSelector(state =>
    hasErrorsSelector([typeO.ON_PROCESS_ORDER])(state),
  )
  const errorO = useSelector(state =>
    singleErrorSelector([typeO.ON_PROCESS_ORDER])(state),
  )

  const allExistence = useSelector(selectProductExistence)
  const loadingE = useSelector(state => loadingSelector([typeO.GET_EXISTENCE])(state))
  const hasErrorE = useSelector(state => hasErrorsSelector([typeO.GET_EXISTENCE])(state))
  const errorE = useSelector(state => singleErrorSelector([typeO.GET_EXISTENCE])(state))

  const [actions, setActions] = useState({
    get: false,
    process: false,
    open: false,
    existence: false,
  })
  const [show, setShow] = useState(false)
  const [imgShow, setImgShow] = useState(false)
  const [imgS, setImgS] = useState([])
  const [selected, setSelected] = useState({ show: false })
  const [sParcel, setParcel] = useState({ show: false })
  const [alert, setAlert] = useState({ title: '' })
  const [eFlag, setEFlag] = useState(null)
  const [eList, setExistence] = useState([])
  const [toWarehouse, setToWarehouse] = useState({
    label: 'Sin seleccionar',
    value: null,
  })
  const [mobile, setMobile] = useState({ show: false, value: null })

  const [currentTab, setCurrentTab] = useState(0)
  const [disabledItems, setDisabled] = useState([])

  const loadingWarehouses = useSelector(state =>
    loadingSelector([typeW.CREATE_TRANSFER_BY_GROUP])(state),
  )

  useEffect(() => {
    if (orderId && !show) {
      setShow(true)
      dispatch(getSingleOrder(orderId))
      dispatch(getExistenceByOrder(orderId))
      dispatch(getWarehouseLite())
    }
  }, [orderId])

  useEffect(() => {
    if (!actions.get && order.orderId && orderId) {
      setActions({ ...actions, get: true })
      // OBTENER PRODUCTOS QUE NO MANEJAN INVENTARIO
      let products = order.details.filter(s => s.product.noInventory)
      setDisabled(
        products.map(i => {
          return i.product.id
        }),
      )
      if (!order.warehouse) {
        // AGREGAR LOS PRODUCTOS AL PRIMER CONTENEDOR
        dispatch(
          changeDataOrder({
            ...order,
            packages: [{ value: 1, label: 'Contenedor No.1', products }],
          }),
        )
      } else {
        dispatch(
          changeDataOrder({
            ...order,
            warehouseS: { label: order.warehouse.name, value: order.warehouse.id },
            packages: [{ value: 1, label: 'Contenedor No.1', products }],
          }),
        )
      }
    }
  }, [order])

  useEffect(() => {
    if (loadingO) setActions({ ...actions, process: true })
    else if (actions.process) {
      setActions({ ...actions, process: false })
      if (hasErrorO)
        setAlert({
          ...handlerError(errorO.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Orden procesada satisfactoriamente'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            dispatch(setOrderProcessId(null))
            setShow(false)
            dispatch(onRefresh(true))
          },
        })
    }
  }, [loadingO])

  useEffect(() => {
    if (loadingE) setActions({ ...actions, existence: true })
    else if (actions.existence) {
      setActions({ ...actions, existence: false })
      if (hasErrorE) {
        setExistence([])
        setAlert({
          ...handlerError(errorE.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      } else {
        setEFlag(true)
      }
    }
  }, [loadingE])

  useEffect(() => {
    if (!eFlag) return
    setExistence(allExistence.map(s => ({ ...s, limit: s.productExistence })))
  }, [allExistence, eFlag])

  /*const getParcels = (kolo) => {
        dispatch(getAllByCompany({kolo: kolo, type: kolo ? 2 : 1}));
    };*/

  const getMax = (item, pValue) => {
    let response = item.quantity || 0

    if (order.packages) {
      order.packages
        .filter(p => p.id !== pValue)
        .map(p => {
          p.products
            .filter(pd => pd.id === item.id)
            .map(pd => {
              response -= pd.quantity
            })
          /**/
        })
    }
    return Number.parseFloat(response)
  }

  const getWP = (value, item) => {
    let wP = { quantity: 0 }
    if (order.packages) {
      const parcel = order.packages.find(w => w.value === (value || 0))
      if (parcel) {
        if (item && item.product) {
          let p = parcel.products.find(p => p.id === item.id)
          if (p) return p
        }
        if (selected.product) {
          let p = parcel.products.find(p => p.id === selected.id)
          if (p) return p
        }
      }
    }
    return wP
  }

  const setCQuantity = (product, item) => {
    let wP = getWP(item.value, product)
    setSelected({ ...product, show: true, cQuantity: wP.quantity || 0, package: item })
    if (wP.warehouses) {
      wP.warehouses.map(w => {
        const index = eList.findIndex(
          i => i.warehouseId === w.warehouseId && w.baseId === i.baseId,
        )
        if (index !== -1) {
          eList[index] = Object.assign(
            {},
            {
              ...eList[index],
              quantity: w.quantity,
              saveExistence: eList[index].productExistence,
            },
          )
          setExistence(Object.assign([], eList))
        }
      })
    } else {
      eList.map((l, i) => {
        if (l.baseId === (product.product.subId || product.product.id))
          eList[i] = Object.assign(
            {},
            {
              ...eList[i],
              quantity: wP.quantity,
              saveExistence: eList[i].productExistence,
            },
          )
      })
      setExistence(Object.assign([], eList))
    }
  }

  const validateC = () =>
    !toWarehouse.value ||
    !order.warehouseS ||
    !order.details ||
    order.details.filter(p => !p.product.noInventory && getMax(p, 0)).length > 0

  const onChangeQuantity = (item, index, key, totalValue, value) => {
    const newIndex = eList.findIndex(e => e.baseId === item.baseId && e.id === item.id)

    let quantity = item.quantity || 0

    if (totalValue) quantity = +totalValue || 0
    else quantity += value

    let quantityUsed = 0
    order.packages.map((s, i) => {
      if (i === currentTab) {
        s.products
          .filter(
            p =>
              selected.product.id !== p.product.id &&
              selected.product.subId === (p.product.subId || p.product.id),
          )
          .map(p => {
            let factor = p.product.factor || 1
            p.warehouses
              .filter(w => w.id === item.warehouseId)
              .map(i => {
                quantityUsed += i.quantity * factor
              })
          })
      } else {
        s.products
          .filter(p => item.baseId === (p.product.subId || p.product.id))
          .map(p => {
            let factor = p.product.factor || 1
            p.warehouses
              .filter(w => w.id === item.warehouseId)
              .map(i => {
                quantityUsed += i.quantity * factor
              })
          })
      }
    })

    let limit = item.limit - quantityUsed
    let used = (quantity ? quantity : 0) * (selected.product.factor || 1)
    const newExistence = limit - used
    const newList = Object.assign(
      [],
      eList.map((e, i) => {
        if (i === newIndex) {
          e = { ...item, quantity, productExistence: newExistence }
        }
        return e
      }),
    )
    setExistence(newList)
  }

  const getWQuantity = (list, ignoreWarehouse, l) => {
    const { product } = selected
    if (l) console.log(product)

    let quantity = 0
    if (product) {
      list
        .filter(
          l =>
            l.warehouseId !== ignoreWarehouse &&
            l.baseId === (product.subId || product.id),
        )
        .map(l => (quantity += l.quantity || 0))
    }

    return quantity
  }

  const processOrder = () => {
    if (!toWarehouse || !toWarehouse.value) return

    const request = {
      toWarehouse: toWarehouse.value,
      fromWarehouse: order.warehouseS.value,
      orders: [orderId],
      packages: order.packages
        .filter(p => p.products && p.products.length > 0)
        .map(p => ({
          number: p.value,
          products: p.products.map(d => ({
            id: d.product.id,
            warehouses: d.warehouses.map(w => ({
              id: w.warehouseId,
              quantity: !d.product.noInventory ? w.quantity : d.quantity,
            })),
          })),
        })),
    }
    dispatch(
      onProcess(
        request,
        imgS.map(item => ({ url: item.original, name: item.name })),
        orderId,
      ),
    )
  }

  const itemsToSelect = value => {
    return (
      <Card>
        <ProductItems
          style={{ maxHeight: '400px' }}
          hideFilter
          hideSticky
          noItems={'Todos los productos han sido asignados'}
          customItems={
            (order.details && order.details.filter(p => !p.product.noInventory)) || []
          }
          filter={item => {
            return getMax(item, 0) > 0
          }}
          renderItem={(item, index) => (
            <ProductItem
              setSelect={() => {
                //dispatch(getExistenceAcrossWarehouses(item.product.id));
                //setSelected({...item, show: true});
                console.log('Valor escogido', item)
                console.log('Variable', value)
                setCQuantity(item, value)
              }}
              disabled={item.product.noInventory}
              eventKey={index}
              title={
                item.productData
                  ? item.productData.name
                  : item.product
                  ? item.product.name
                  : 'Desconocido'
              }
              subTitle={
                item.productData
                  ? item.productData.code
                  : item.product
                  ? item.product.code
                  : 'Desconocido'
              }
              titleSecond={`${getMax(item, 0)} u.`}
            />
          )}
        />
      </Card>
    )
  }

  const addNewContainer = () => {
    if (!order.details || !order.packages) return true
    // OBTENER LA CANTIDAD QUE ESTA EN LOS DETALLES
    let products = order.details.map(d => ({
      product: d.product.id,
      quantity: d.quantity,
    }))
    let process = []
    // OBTENER LOS PRODUCTOS POR PAQUETE
    products.map(pr => {
      let object = { product: pr.product, quantity: 0 }
      // RECORRER LOS PAQUETES
      order.packages.map(pkg => {
        // VERIFICAR SI EL PRODUCTO ES EL QUE QUEREMOS
        pkg.products.map(prkg => {
          // SI EL PRODUCTO ES IGUAL
          if (prkg.product.id === pr.product) {
            let qt = object.quantity
            object = { ...object, quantity: qt + prkg.quantity }
          }
        })
      })
      process.push(object)
    })
    // SE COMPARA EL ARREGLO DE PRODUCTOS DEL DETALLE, CON LA DE LOS PAQUETES
    return !(JSON.stringify(products) === JSON.stringify(process))
  }

  return (
    <div>
      <Modal
        show={show && !imgShow && !mobile.show}
        centered
        size={'lg'}
        onHide={() => undefined}>
        <Modal.Header>
          <Modal.Title>Orden: #{order.number}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12} md={12} sm={12}>
              <Select
                disabled={loadingWarehouses}
                required
                label={'Bodega destino'}
                options={warehouses}
                value={toWarehouse}
                onChange={w => setToWarehouse(w)}
              />
            </Col>
            <Col xl={12} md={12} sm={12}>
              <div style={{ marginTop: 10 }}>
                <Tabs selectedIndex={currentTab} onSelect={index => setCurrentTab(index)}>
                  <TabList>
                    {order.packages &&
                      order.packages.map((p, i) => (
                        <Tab key={i}>
                          <div style={{ display: 'flex' }}>
                            {p.products && (
                              <div className={'badge-tab'}>{p.products.length}</div>
                            )}
                            <div>Contenedor {i + 1}</div>
                            {order.packages.length > 1 && (
                              <Icon
                                onClick={() => {
                                  dispatch(
                                    changeDataOrder({
                                      ...order,
                                      packages: order.packages.filter(
                                        w => w.value !== p.value,
                                      ),
                                    }),
                                  )
                                }}
                                style={{ marginLeft: 5 }}
                                color={'red'}
                                icon={faWindowClose}
                                tooltip={'Eliminar contenedor'}
                              />
                            )}
                          </div>
                        </Tab>
                      ))}

                    {addNewContainer() && (
                      <Tab
                        onClick={() => {
                          const cPackages = Object.assign([], order.packages)
                          cPackages.push({
                            value: cPackages.length + 1,
                            label: 'Contenedor No.' + (cPackages.length + 1),
                            products: [],
                          })
                          dispatch(changeDataOrder({ ...order, packages: cPackages }))
                          setCurrentTab(cPackages.length - 1)
                        }}>
                        <div style={{ display: 'flex' }}>
                          <img className="tabs-img" src={IconPlus} alt={''} />
                        </div>
                      </Tab>
                    )}
                  </TabList>

                  <TabPanel key={0} />

                  {order.packages &&
                    order.packages.map((p, i) => (
                      <TabPanel key={i}>
                        <Media>
                          {({ breakpoints, currentBreakpoint }) => {
                            const tablet =
                              breakpoints[currentBreakpoint] > breakpoints.tablet
                            return breakpoints[currentBreakpoint] >= breakpoints.mini ? (
                              <Row>
                                {tablet && (
                                  <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                                    {itemsToSelect(p)}
                                  </Col>
                                )}
                                <Col
                                  xl={tablet ? 7 : 12}
                                  lg={tablet ? 7 : 12}
                                  md={tablet ? 7 : 12}
                                  sm={12}
                                  xs={12}>
                                  <ProductItems
                                    style={{ maxHeight: '350px' }}
                                    hideFilter
                                    hideSticky
                                    noItems={'Sin productos agregados'}
                                    customItems={p.products || []}
                                    filter={item => {
                                      return item
                                    }}
                                    disabledItems={disabledItems}
                                    renderItem={(item, index) => (
                                      <ProductItem
                                        eventKey={index}
                                        title={item.product.name}
                                        subTitle={item.product.code}
                                        titleSecond={`${item.quantity} u.`}
                                        subTitleSecond={
                                          item.product.noInventory &&
                                          'Este producto no maneja inventario.'
                                        }
                                        disabled={item.product.noInventory}
                                        actions={
                                          !item.product.noInventory && (
                                            <Icon
                                              color={'secondary'}
                                              icon={faWindowClose}
                                              tooltip={'Descartar'}
                                              size={'2x'}
                                              onClick={() => {
                                                const pr = p.products.filter(
                                                  p => p.id !== item.id,
                                                )
                                                const index = eList.findIndex(
                                                  f =>
                                                    f.baseId ===
                                                    (item.product.subId ||
                                                      item.product.id),
                                                )
                                                if (index !== 1) {
                                                  eList[index] = {
                                                    ...eList[index],
                                                    quantity: 0,
                                                    productExistence:
                                                      eList[index].productExistence +
                                                      item.quantity *
                                                        (item.product.factor || 1),
                                                  }
                                                }
                                                const wI = order.packages.findIndex(
                                                  w => w.value === p.value,
                                                )
                                                order.packages[wI] = {
                                                  ...p,
                                                  products: pr,
                                                }
                                                dispatch(changeDataOrder({ ...order }))
                                              }}
                                            />
                                          )
                                        }
                                      />
                                    )}
                                  />
                                </Col>
                                {!tablet && (
                                  <Col xl={12} lg={12} md={12} sm={12}>
                                    <Row
                                      className={'container-buttons'}
                                      style={{
                                        paddingBottom: 2,
                                        display: 'flex',
                                      }}>
                                      <Button
                                        style={{ flex: 1 }}
                                        icon={faPlus}
                                        right
                                        color={'primary'}
                                        onClick={() =>
                                          setMobile({ show: true, value: p })
                                        }>
                                        Agregar productos
                                      </Button>
                                    </Row>
                                  </Col>
                                )}
                              </Row>
                            ) : (
                              <div />
                            )
                          }}
                        </Media>
                      </TabPanel>
                    ))}
                </Tabs>
              </div>
            </Col>

            {/*<Col xl={12} md={12} sm={12} xs={12}><Select
                    disabled={order.warehouse}
                    value={order.warehouseS}
                    options={warehouses}
                    label={'¿En que bodega deseas almacenar la orden procesada?'}
                    onChange={(w) => dispatch(changeDataOrder({...order, warehouseS: {...w}}))}
                /></Col>*/}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              disabled={loadingO}
              color={'secondary'}
              icon={faWindowClose}
              left
              onClick={() => {
                dispatch(setOrderProcessId(null))
                setShow(false)
                setActions({ ...actions, get: false })
              }}>
              Cancelar
            </Button>
            <Button
              disabled={validateC()}
              loading={loadingO}
              icon={faSave}
              color={'primary'}
              right
              onClick={() => {
                if (facType && facType.value === '2') setImgShow(true)
                else processOrder()
              }}>
              Procesar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={mobile.show}
        centered
        size={'mg'}
        onHide={() => setMobile({ show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Seleccion de productos</Modal.Title>
        </Modal.Header>
        <Modal.Body>{itemsToSelect(mobile.value)}</Modal.Body>
      </Modal>

      <Modal show={imgShow} centered size={'lg'} onHide={() => setImgShow(false)}>
        <Modal.Header cloaseButton>
          <Modal.Title>Orden: #{order.number}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={'column'}>
            <p>Es necesario adjuntar imágenes de la orden</p>
            <Gallery
              imageType={imageTypes.INVOICES}
              images={imgS}
              onUploadSuccess={(url, name) =>
                setImgS([...imgS, { original: url, thumbnail: url, name: name }])
              }
              onDelete={(image, index) => {
                imgS.splice(index, 1)
                setImgS(imgS)
              }}
              maxWidth={450}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              disabled={imgS.length <= 0}
              icon={faSave}
              color={'primary'}
              right
              onClick={() => {
                setImgShow(false)
                processOrder()
              }}>
              Procesar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={selected.show}
        centered
        onHide={() => {
          // PONER LA EXISTENCIA ACTUAL
          eList.map((l, i) => {
            if (l.baseId === (selected.product.subId || selected.product.id))
              eList[i] = Object.assign(
                {},
                { ...eList[i], productExistence: eList[i].saveExistence },
              )
          })
          setExistence(Object.assign([], eList))
          setSelected({ show: false })
        }}
        size={'md'}>
        <Modal.Header closeButton>
          <Modal.Title>{selected.product && selected.product.name}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12}>
              <TableV2
                headerText={`Unidades restantes: ${
                  getMax(selected, null) +
                  getWP(selected.package ? selected.package.value : null).quantity -
                  getWQuantity(eList)
                }`}
                hideFilter
                loading={loadingE}
                items={
                  selected.package
                    ? eList.filter(
                        f => f.baseId === (selected.product.subId || selected.product.id),
                      )
                    : []
                }
                noItemsLegend={
                  selected.package
                    ? 'No se encontro existencia de este producto'
                    : 'Primero debe seleccionar un contenedor'
                }
                renderRow={(item, index) => {
                  let quantityUsed = 0
                  let lineQuantity = 0

                  order.packages.forEach((s, i) => {
                    if (i === currentTab) {
                      s.products
                        .filter(
                          p =>
                            selected.product.id !== p.product.id &&
                            selected.product.subId === (p.product.subId || p.product.id),
                        )
                        .forEach(p => {
                          let factor = p.product.factor || 1
                          p.warehouses
                            .filter(w => w.id === item.warehouseId)
                            .forEach(i => {
                              quantityUsed += i.quantity * factor
                            })
                        })
                    } else {
                      s.products
                        .filter(p => item.baseId === (p.product.subId || p.product.id))
                        .forEach(p => {
                          let factor = p.product.factor || 1
                          p.warehouses
                            .filter(w => w.id === item.warehouseId)
                            .forEach(i => {
                              quantityUsed += i.quantity * factor
                            })
                        })
                    }
                  })

                  let limit = item.limit - quantityUsed

                  return (
                    <div className={'b-user-item'} key={index}>
                      <div
                        className={'justify-content-start align-items-start bu-en'}
                        style={{ textAlign: 'left' }}>
                        <div className={'b-user-name'}>{item.warehouseName}</div>
                        <div className={'b-user-email'}>
                          {Math.trunc(
                            Number(
                              (item.productExistence - lineQuantity) /
                                (selected.product.factor || 1),
                            ),
                          )}
                        </div>
                      </div>
                      <div
                        className={'d-flex row align-items-center mr-2 bu-b'}
                        style={{ textAlign: 'right' }}>
                        <div className={'medium'}>
                          <FormText
                            igStyle={{ marginBottom: 0 }}
                            mt={0}
                            ptext
                            atext
                            prepend={
                              <Button
                                disabled={!item.quantity || item.quantity <= 0}
                                onClick={() =>
                                  onChangeQuantity(
                                    item,
                                    index,
                                    selected.product.subId || selected.product.id,
                                    null,
                                    -1,
                                  )
                                }
                                variant={'accent'}>
                                -
                              </Button>
                            }
                            append={
                              <Button
                                disabled={
                                  getMax(selected, null) +
                                    getWP(
                                      selected.package ? selected.package.value : null,
                                    ).quantity -
                                    getWQuantity(eList) ===
                                    0 || item.quantity >= item.productExistence
                                }
                                onClick={() =>
                                  onChangeQuantity(
                                    item,
                                    index,
                                    selected.product.subId || selected.product.id,
                                    null,
                                    1,
                                  )
                                }
                                variant={'accent'}>
                                +
                              </Button>
                            }
                            value={item.quantity || 0}
                            type={'number'}
                            onChange={({ target }) => {
                              let { value } = target
                              if (!value || value < 0) value = 0
                              value = Number.parseInt(value)

                              console.log('Valor maximo', getMax(selected, null))

                              // VALOR POR PROCESAR
                              let m =
                                getMax(selected, null) +
                                getWP(selected.package ? selected.package.value : null)
                                  .quantity -
                                getWQuantity(eList, item.warehouseId)

                              // VALOR DISPONIBLE
                              const a = Math.trunc(
                                Number(limit / (selected.product.factor || 1)),
                              )

                              console.log('Unidades restantes', m)
                              console.log('Unidades disponibles por bodega', a)
                              // SI LO QUE QUIERO PROCESAR ES MAYOR A MIS EXISTENCIAS
                              if (a < m) {
                                if (value >= a) value = a
                              } else if (a > m) {
                                if (value >= m) value = m
                              }
                              onChangeQuantity(
                                item,
                                index,
                                selected.product.subId || selected.product.id,
                                value,
                              )
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              disabled={
                !selected.package || eList.filter(el => el.quantity > 0).length <= 0
              }
              icon={faPlus}
              right
              color={'accent'}
              onClick={() => {
                // TODO
                const wI = order.packages.findIndex(
                  w => w.value === selected.package.value,
                )
                const w = order.packages[wI]
                const quantity = getWQuantity(eList, false, true)

                console.log(wI)
                console.log(w)
                console.log(quantity)

                const pI = w.products.findIndex(p => p.id === selected.id)
                const { subId, id } = selected.product
                console.log(eList)

                if (pI === -1)
                  w.products.push({
                    ...selected,
                    quantity: quantity,
                    package: null,
                    warehouses: [
                      ...eList.filter(l => l.quantity > 0 && l.baseId === (subId || id)),
                    ],
                  })
                else
                  w.products[pI] = {
                    ...selected,
                    quantity: quantity,
                    package: null,
                    warehouses: [
                      ...eList.filter(l => l.quantity > 0 && l.baseId === (subId || id)),
                    ],
                  }

                //w.products.push({...selected, quantity: quantity, package: null, warehouses: eList.filter((l) => l.quantity > 0)});
                order.packages[wI] = w
                dispatch(changeDataOrder({ ...order }))
                setSelected({ show: false })
              }}>
              Agregar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={sParcel.show}
        centered
        size={'md'}
        onHide={() => setParcel({ show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>{sParcel.name}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ProductItems
            style={{ maxHeight: '350px' }}
            hideFilter
            noItems={'Sin productos agregados'}
            customItems={sParcel.products || []}
            filter={item => {
              return item
            }}
            renderItem={(item, index) => (
              <ProductItem
                eventKey={index}
                title={item.product.name}
                subTitle={item.product.code}
                titleSecond={`${item.quantity} u.`}
                actions={
                  <Icon
                    color={'secondary'}
                    icon={faWindowClose}
                    tooltip={'Descartar'}
                    size={'2x'}
                    onClick={() => {
                      const p = sParcel.products.filter(
                        p => p.product.id !== item.product.id,
                      )
                      setParcel({ ...sParcel, products: p })

                      const wI = order.packages.findIndex(w => w.value === sParcel.value)
                      order.packages[wI] = { ...sParcel, products: p }
                      dispatch(changeDataOrder({ ...order }))
                    }}
                  />
                }
              />
            )}
          />
        </Modal.Body>

        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              color={'secondary'}
              icon={faTrash}
              right
              onClick={() => {
                setParcel({ show: false })

                dispatch(
                  changeDataOrder({
                    ...order,
                    packages: order.packages.filter(w => w.value !== sParcel.value),
                  }),
                )
              }}>
              Eliminar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Alert {...alert} />
    </div>
  )
}
export default ModalOrderProcess
