import { actionTypes } from '../actions/tokens.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  token: {},
}

const TokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GENERATE_TOKEN):
      return {
        ...state,
        token: extract(action.token, {}),
      }
    default:
      return state ? state : initialState
  }
}

export default TokenReducer
