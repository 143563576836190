import { executeAction } from './global.actions'
import PromotionService from 'src/services/promotion.service'
export const actions = {
  GET_ALL_PROMOTIONS: 'GET_ALL_PROMOTIONS',
  ON_CREATE_OR_UPDATE_PROMOTION: 'ON_CREATE_OR_UPDATE_PROMOTION',
  ON_DELETE_PROMOTION: 'ON_DELETE_PROMOTION',
  ON_SET_PROMOTION_ID: 'ON_SET_PROMOTION_ID',
  GET_PROMOTION: 'GET_PROMOTION',
}

export const getAllPromotions = (active?: boolean) => dispatch => {
  const process = async () => ({
    payload: await PromotionService.getAllPromotions(active),
  })
  dispatch(executeAction(actions.GET_ALL_PROMOTIONS, process))
}

export const onCreatePromotion = (request: IPromotionRequest) => dispatch => {
  const process = async () => ({
    payload: await PromotionService.createPromotion(request),
  })
  dispatch(executeAction(actions.ON_CREATE_OR_UPDATE_PROMOTION, process))
}

export const onUpdatePromotion = (id: number, request: IPromotionRequest) => dispatch => {
  const process = async () => ({
    payload: await PromotionService.updatePromotion(id, request),
  })
  dispatch(executeAction(actions.ON_CREATE_OR_UPDATE_PROMOTION, process))
}

export const onUpdatePromotionsList = (promotions: IPromotion[]) => dispatch => {
  const process = () => ({ payload: promotions })
  dispatch(executeAction(actions.GET_ALL_PROMOTIONS, process))
}

export const onDeletePromotion = (id: number) => async dispatch => {
  const process = async () => await PromotionService.deletePromotion(id)
  dispatch(executeAction(actions.ON_DELETE_PROMOTION, process))
}

export const onSetPromotionId = (id: number) => dispatch => {
  const process = () => ({ payload: id })
  dispatch(executeAction(actions.ON_SET_PROMOTION_ID, process))
}

export const getPromotionById = (id: number) => dispatch => {
  const process = async () => ({
    payload: await PromotionService.getPromotionById(id),
  })
  dispatch(executeAction(actions.GET_PROMOTION, process))
}
