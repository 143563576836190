import { Country } from 'src/enums/countries'
import { establishmentTypes } from 'src/enums/establishmentTypes'

const commercialFields: IDictionary<CommercialField> = {
  nit: { label: 'NIT', value: 'nit', type: 'text', countries: [Country.GT] },
  nrc: { label: 'NRC', value: 'nrc', type: 'text', countries: [Country.SV] },
  rtn: { label: 'RTN', value: 'nit', type: 'text', countries: [Country.HN] },
  businessName: { label: 'Razón social', value: 'name', type: 'text' },
  businessAddress: {
    label: 'Dirección',
    value: 'socialAddress',
    type: 'text',
    countries: [Country.HN],
  },
  commercialName: {
    label: 'Nombre comercial',
    value: 'commercialName',
    type: 'text',
  },
  commercialAddress: {
    label: 'Dirección',
    value: 'address',
    type: 'text',
    notCountries: [Country.HN],
  },
  department: {
    label: 'Departamento',
    value: 'department',
    type: 'text',
    countries: [Country.SV, Country.GT],
  },
  county: {
    label: 'Municipio',
    value: 'municipality',
    type: 'text',
    countries: [Country.SV, Country.GT],
  },
  comercialEstablishment: {
    label: 'Establecimiento comercial',
    value: 'comercialEstablishment',
    type: 'number',
    countries: [Country.SV, Country.GT],
  },
  establishmentType: {
    label: 'Tipo de establecimiento',
    value: 'establishmentType',
    type: 'select',
    options: establishmentTypes,
    countries: [Country.SV],
  },
  zip: {
    label: 'Código postal',
    value: 'postalCode',
    type: 'text',
    countries: [Country.GT],
  },
}

const getCommercialFields = (country: Country): CommercialField[] => {
  return Object.values(commercialFields).filter(cf => {
    let inCountry
    if (!cf.countries) inCountry = true
    else inCountry = cf.countries.some(c => c === country)

    let notCountry
    if (!cf.notCountries) notCountry = true
    else notCountry = !cf.notCountries.some(c => c === country)

    return inCountry && notCountry
  })
}

export { getCommercialFields }
