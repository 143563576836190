import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'

import { Col, ProgressBar, Row } from 'react-bootstrap'
import { Action, Card, SelectedDates, TableV2, Title, Empty } from 'src/components'

import {
  actionTypes,
  getSegmentationData,
  onSetQueryInfo,
} from 'src/actions/report.actions'
import {
  selectQueriesReport,
  selectSegmentationReport,
} from 'src/selectors/report.selector'

import { selectCurrentUser } from 'src/selectors/user.selector'
import { getAllPOSByUser } from 'src/actions/restaurant.actions'
import { getProductsSimple } from 'src/actions/products.actions'
import { getClientsSimple } from 'src/actions/clients.actions'
import { isAllowed } from 'src/selectors/modules.selector'
import { toMoney } from 'src/utils/utilities'
import Money from 'src/components/Money/Money'

import { loadingSelector } from 'src/selectors/loading.selector'
import { hasErrors } from 'src/selectors/error.selector'
import {
  formatDateFromMillis,
  formatNumberWithCommas,
  getItemValue,
} from 'src/utils/formatters'

import ReportsDownloadPresentation from './ReportsDownloadPresentation'

const SegmentationTable = () => {
  const dispatch = useDispatch()
  const admin = useSelector(state => isAllowed(state, [1001, 2001, 11651]))

  const report = useSelector(selectSegmentationReport)
  const user = useSelector(selectCurrentUser)

  const loadingS = useSelector(state =>
    loadingSelector([actionTypes.GET_SEGMENTATION_DATA])(state),
  )
  const errorS = useSelector(state =>
    hasErrors([actionTypes.GET_SEGMENTATION_DATA])(state),
  )
  const queries = useSelector(selectQueriesReport)

  const [filters, setFilters] = useState({})
  const [modal, setModal] = useState({ show: false })
  const [dates, setDates] = useState({ start: null, end: null })

  useEffect(() => {
    if (queries?.params?.type) {
      let options = [
        { value: 1, label: 'Venta por orden de venta' },
        { value: 2, label: 'Ventas a clientes' },
        { value: 3, label: 'Pagos de cuentas por cobrar emitidas en el periodo' },
        { value: 4, label: 'Por productos vendidos' },
        { value: 5, label: 'Totales en monedas' },
        { value: 6, label: 'Cuentas por cobrar' },
      ]
      setFilters({
        ...queries,
        params: { ...queries.params, type: options[queries.params.type - 1] },
      })

      const { filters, pos, segmentations, users, products, clients, typeSegmentation } =
        queries

      dispatch(
        getSegmentationData(
          { ...queries.params },
          {
            categories: filters.map(f => Number(f.id)),
            segmentations: segmentations.map(f => Number(f.id)),
            posList: pos.map(d => Number(d.value)),
            typeSegmentation,
            users: users.map(d => Number(d.id)),
            products: products.map(d => Number(d.id)),
            clients: clients.map(d => Number(d.id)),
          },
        ),
      )
      dispatch(onSetQueryInfo({}))
    }
  }, [queries])

  useEffect(() => {
    dispatch(getAllPOSByUser(user.id))
    dispatch(getProductsSimple())
    dispatch(getClientsSimple(admin ? null : user.id))
  }, [])

  const headers = [
    {
      label: 'Tipo',
      show: filters.params?.type?.value === 8,
      value: ['type'],
      type: 'text',
    },
    {
      label: 'Código',
      show: filters.params?.type?.value !== 7,
      value: ['code'],
      type: 'text',
    },
    {
      label: 'Estado',
      show: filters.params?.type?.value === 3,
      value: ['statusName'],
      type: 'text',
    },
    {
      label: 'Nombre',
      show: filters.params?.type?.value !== 7,
      value: ['name'],
      type: 'text',
    },
    {
      label: 'Fecha de creación',
      show: filters?.params?.type?.value === 1 || filters?.params?.type?.value === 3,
      value: ['createdAt'],
      type: 'date',
    },
    {
      label: 'Nit',
      show:
        filters?.params?.type?.value !== 6 &&
        filters.params?.type?.value !== 7 &&
        filters?.params?.type?.value !== 4,
      value: ['description'],
      type: 'text',
    },
    {
      label: 'Utilidad Bruta',
      show: filters.params?.type?.value === 1,
      value: ['utility'],
      type: 'currency',
    },
    {
      label: 'Cantidad',
      show: filters.params?.type?.value === 4,
      value: ['quantity'],
      type: 'number',
    },
    {
      label: 'Descuento',
      show: filters.params?.type?.value === 1,
      value: ['discount'],
      type: 'currency',
    },
    {
      label: 'Ventas',
      show: filters.params?.type?.value === 7,
      value: ['quantity'],
      type: 'currency',
    },
    {
      label: 'Vales',
      show: filters.params?.type?.value === 7,
      value: ['utility'],
      type: 'currency',
    },
    {
      label: 'Notas de crédito',
      show: filters.params?.type?.value === 7,
      value: ['average'],
      type: 'currency',
    },
    { label: 'Total', show: true, value: ['total'], type: 'currency' },
    {
      label: 'Precio promedio',
      show: filters?.params?.type?.value === 4,
      value: ['average'],
      type: 'currency',
    },
    {
      label: 'Fecha emisión',
      show: filters?.params?.type?.value === 6,
      value: ['createdAt'],
      type: 'date',
    },
    {
      label: 'Días de crédito',
      show: filters.params?.type?.value === 6,
      value: ['quantity'],
      type: 'number',
    },
    {
      label: 'Fecha de vencimiento',
      show: filters?.params?.type?.value === 6,
      value: ['paymentAt'],
      type: 'date',
    },
    {
      label: 'Días de crédito de vencimiento',
      show: filters?.params?.type?.value === 6,
      value: ['quantity'],
      type: 'number',
    },
    {
      label: 'Referencia',
      show: filters?.params?.type?.value === 3,
      value: ['reference'],
      type: 'number',
    },
    {
      label: 'Monto',
      show: filters?.params?.type?.value === 3,
      value: ['utility'],
      type: 'currency',
    },
    {
      label: 'Última venta',
      show: filters?.params?.type?.value === 4,
      value: ['date'],
      type: 'number',
    },
    {
      label: 'Último precio de venta',
      show: filters?.params?.type?.value === 4,
      value: ['utility'],
      type: 'currency',
    },
  ]

  const getItemsMobile = data => {
    let array = []
    let store = localStorage.getItem(`seg-${user.id}`)
    if (!store)
      store = {
        title: { value: ['name'], type: 'text' },
        subtitle: { value: ['code'], type: 'text' },
        complement1: { value: ['description'], type: 'text' },
        complement2: { value: ['total'], type: 'currency' },
      }
    else store = JSON.parse(store)

    data.map((item, i) => {
      let more = [
        { title: `Utilidad Bruta: `, info: toMoney(item.utility) },
        { title: `Descuento: `, info: toMoney(item.discount) },
      ]
      let object = {
        id: item.id,
        title: getItemValue(item, store.title.value, store.title.type),
        subtitle: getItemValue(item, store.subtitle.value, store.subtitle.type),
        complement1: getItemValue(item, store.complement1.value, store.complement1.type),
        complement2: getItemValue(item, store.complement2.value, store.complement2.type),
        key: `seg-${item.id}`,
        more,
      }
      array.push(object)
    })
    return array
  }

  const getInformation = (data, level) => {
    return data.map((r, i) => (
      <div key={i}>
        <Card
          title={
            filters.params?.type?.value !== 8 ? r.name : r.description + ': ' + r.name
          }
          white={level === 1}
          footer={
            <h2 style={{ textAlign: 'right', color: 'black' }}>{toMoney(r.total)}</h2>
          }
        >
          {r.children && r.children.length > 0
            ? getInformation(r.children, level + 1)
            : filters.params?.type?.value !== 5 && (
                <TableV2
                  loading={loadingS}
                  headers={headers}
                  items={r.segmentation}
                  itemsMobile={getItemsMobile(r.segmentation)}
                  customClass={'scroll-x-without-height'}
                  renderRow={(item, index) => {
                    const today = new Date().valueOf()
                    return filters.params?.type?.value !== 7 ? (
                      <tr className={'data'} key={index}>
                        {filters.params?.type?.value === 8 && (
                          <td className={'text-left'}>{item.type}</td>
                        )}
                        <td className={`text-left`}>{item.code || 'Desconocido'}</td>
                        {filters.params?.type?.value === 3 && (
                          <td className={`text-left`}>
                            {item.statusName || 'Desconocido'}
                          </td>
                        )}
                        <td className={'text-left'}>
                          {`${
                            (filters.params?.type?.value !== 6 ? item.code2 || '' : '') +
                            ' '
                          }${item.name}` || 'Desconocido'}
                        </td>
                        {(filters?.params?.type?.value === 1 ||
                          filters?.params?.type?.value === 3) && (
                          <td className={'text-left'}>
                            {formatDateFromMillis(item.createdAt)}
                          </td>
                        )}
                        {filters.params?.type?.value !== 6 &&
                          filters.params?.type?.value !== 4 && (
                            <td className={'text-left'}>{item.description || 'N/A'}</td>
                          )}
                        {filters.params?.type?.value === 4 && (
                          <td className={'text-left'}>
                            {formatNumberWithCommas(item.quantity)}
                          </td>
                        )}
                        {filters.params?.type?.value === 1 && (
                          <>
                            <Money component="td" className={'text-left'}>
                              {item.utility}
                            </Money>
                            <Money component="td" className={'text-left'}>
                              {item.discount}
                            </Money>
                          </>
                        )}
                        <Money component="td" className={'text-left'}>
                          {item.total}
                        </Money>
                        {filters?.params?.type?.value === 4 && (
                          <Money component="td" className={'text-left'}>
                            {item.average}
                          </Money>
                        )}
                        {filters?.params?.type?.value === 4 && (
                          <td className={'text-left'}>
                            {formatDateFromMillis(item.date)}
                          </td>
                        )}
                        {filters?.params?.type?.value === 4 && (
                          <Money component="td" className={'text-left'}>
                            {item.utility || 0}
                          </Money>
                        )}

                        {filters.params?.type?.value === 6 && (
                          <>
                            <td className={'text-left'}>
                              {formatDateFromMillis(item.createdAt)}
                            </td>
                            <td className={`text-left`}>
                              {formatNumberWithCommas(
                                today < item.paymentAt
                                  ? (today - item.createdAt) / 86400000
                                  : 0,
                                0,
                              )}
                            </td>
                            <td className={'text-left'}>
                              {formatDateFromMillis(item.paymentAt)}
                            </td>
                            <td className={`text-left`}>
                              {formatNumberWithCommas(
                                today > item.paymentAt
                                  ? (today - item.paymentAt) / 86400000
                                  : 0,
                                0,
                              )}
                            </td>
                          </>
                        )}
                        {filters.params?.type?.value === 3 && (
                          <>
                            <td className={'text-left'}>{item.reference}</td>
                            <Money component="td" className={`text-left`}>
                              {item.utility}
                            </Money>
                          </>
                        )}
                      </tr>
                    ) : (
                      <tr className={'data'} key={index}>
                        <td className={'text-left'}>
                          {formatNumberWithCommas(item.quantity)}
                        </td>
                        <td className={'text-left'}>
                          {formatNumberWithCommas(item.utility)}
                        </td>
                        <td className={'text-left'}>
                          {formatNumberWithCommas(item.average)}
                        </td>
                        <td className={`text-left`}>
                          {formatNumberWithCommas(item.total)}
                        </td>
                      </tr>
                    )
                  }}
                />
              )}
        </Card>
      </div>
    ))
  }

  const renderCategories = list => (
    <Row className={'ml-1'}>
      {list.map((c, index) => (
        <div className="chip" key={index}>
          <div className="chip-head">{c.name && c.name.charAt && c.name.charAt(0)}</div>
          <div className="chip-content">{c.name || c.label}</div>
        </div>
      ))}
    </Row>
  )

  const getTotal = data => {
    let total = 0
    data.map(d => {
      total += d.total
    })
    return total
  }

  return (
    <div>
      <Title title={'Reporte Segmentado'} />
      <hr />
      <Action
        filter
        action
        actionTitle={'Aplicar filtros'}
        onClick={() => {
          setModal({ show: true })
        }}
      />
      {/*<Row>*/}
      {/*  <Col>*/}
      {/*    <Card>*/}
      {/*      <b>FILTROS</b>*/}
      {/*      <div>*/}
      {/*        <label className={'mt-3'}>categorias filtradas:</label>*/}
      {/*        {renderCategories(filters.filters || [])}*/}
      {/*        <label className={'mt-3'}>productos filtrados:</label>*/}
      {/*        {renderCategories(filters.products || [])}*/}
      {/*      </div>*/}
      {/*    </Card>*/}
      {/*  </Col>*/}
      {/*  <Col>*/}
      {/*    <Card>*/}
      {/*      <b>SEGMENTACION</b>*/}
      {/*      <div>*/}
      {/*        <label className={'mt-3'}>categorias segmentadas:</label>*/}
      {/*        {renderCategories(filters.segmentations || [])}*/}
      {/*        <label className={'mt-3'}>usuarios segmentadas</label>*/}
      {/*        {renderCategories(filters.users || [])}*/}
      {/*        <label className={'mt-3'}>pos segmentadas</label>*/}
      {/*        {renderCategories(filters.pos || [])}*/}
      {/*        <label className={'mt-3'}>clientes segmentadas</label>*/}
      {/*        {renderCategories(filters.clients || [])}*/}
      {/*      </div>*/}
      {/*    </Card>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      <Row>
        <Col>
          <SelectedDates
            dates={{
              dateFrom:
                queries?.params?.start || dates.start
                  ? new Date(queries?.params?.start || dates.start)
                  : new Date(),
              dateTo:
                queries?.params?.end || dates.end
                  ? new Date(queries?.params?.end || dates.end)
                  : new Date(),
            }}
            setValueDates
            onDateChange={(start, end) => setDates({ start, end })}
            withOptionNull={filters?.params?.type?.value === 6}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={4} lg={4}>
          <ReportsDownloadPresentation
            modal={window.innerWidth < 480}
            customParams={filters}
            show={modal.show}
            onClose={() => setModal({ show: false })}
            onFilter={(params, users, pos, clients, filters, segmentations, products) => {
              setFilters({
                params,
                users,
                pos,
                clients,
                filters,
                segmentations,
                products,
              })
              setModal({ show: false })
            }}
            dates={dates}
          />
        </Col>
        <Col xs={12} sm={12} md={8} lg={8}>
          <Card>
            <div className={'d-flex'} style={{ justifyContent: 'space-between' }}>
              <h2 style={{ color: 'black' }}>
                <b>{filters?.params?.type?.label}</b>
              </h2>
              <Money component="h2" style={{ color: 'black' }}>
                <b>{getTotal(report)}</b>
              </Money>
            </div>
            <div style={{ overflowY: 'scroll', height: '85vh' }}>
              {report && report.length > 0 ? (
                getInformation(report, 1)
              ) : (
                <>
                  {loadingS && (
                    <Row>
                      <Col>
                        <div className={'pb-custom'}>
                          <ProgressBar
                            label="Cargando"
                            animated
                            now={100}
                            style={{ marginBottom: 10 }}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                  <div
                    style={{ height: window.innerHeight - 180 }}
                    className={'d-flex justify-content-center'}
                  >
                    {' '}
                    <Empty
                      className={'self-center'}
                      title={'No hay registros para mostrar'}
                      hideButton
                      subtitle={''}
                      buttonTitle={'Aplicar filtros'}
                      loading={loadingS}
                      subtitleSecond={'Presiona "Aplicar filtros" para buscar'}
                      onClick={() => setModal({ show: true })}
                    />
                  </div>
                </>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default SegmentationTable
