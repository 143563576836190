import React, { useContext, useEffect, useState } from 'react'

import { Dropdown, TableV2 } from '../../index'

import { useDispatch, useSelector } from 'react-redux'
import {
  acceptRetention,
  getAccountHistory,
  revertOperation,
} from 'src/actions/banks.actions'

import {
  selectGetAllCurrencies,
  selectRevertOperation,
} from 'src/selectors/banks.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { actionTypes as typeT } from 'src/actions/banks.actions'
import { AccountMovementsContext, BankContext } from 'src/providers/BankContext'

import { CustomReference } from 'src/components/index'

import { formatNumberWithCommas } from 'src/utils/formatters'

import { isAllowed, selectorGetUsersSubmodule } from 'src/selectors/modules.selector'

import { hideAlert, showAlert } from 'src/actions/alert.actions'

import { bankAccountPermissionsEnum, operations } from 'src/enums/bankEnums'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import { faCheck, faCheckDouble, faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import AccreditationMultiple from 'src/content/Company/Banks/AccreditationMultiple'
import DetailsFilters from '../Shared/DetailsFilters'

const findOperation = (id: number) => {
  const found = operations.find(f => f.id === id)
  return found ? found.label : ''
}

interface AccountMovementsProps {
  activeTable?: string
  parentRef?: React.Ref<HTMLDivElement>
  accountSelected?: IBankAccount
  historyMovementsAccount?: requestHistoryMovementData[]
  sizeList?: number
  setKey?: (key: number) => void
}

interface IMovementsFlags {
  create: boolean
  retention: boolean
}

const AccountMovementsTable: React.FC<AccountMovementsProps> = ({
  activeTable,
  historyMovementsAccount,
  sizeList,
}: AccountMovementsProps) => {
  // Dispatch
  const dispatch = useDispatch()

  // Selectors

  const currencies = useSelector(selectGetAllCurrencies)

  //Context
  const { accounnt } = useContext(BankContext) || {}
  const { params, updateParams } = useContext(AccountMovementsContext) || {}

  const [paramsTxt, setParamsTxt] = useState<string>(undefined)

  const revertSelector = useSelector(state => selectRevertOperation(state))
  const loadingOp = useSelector(state => loadingSelector([typeT.CREATE_OPERATION])(state))
  const errorOp = useSelector(state => hasErrors([typeT.CREATE_OPERATION])(state))
  const usersSubmodule = useSelector(state => selectorGetUsersSubmodule(state))
  const loadingAH = useSelector(state =>
    loadingSelector([typeT.GET_ACCOUNT_HISTORY])(state),
  )

  const loadingRetention = useSelector(state =>
    loadingSelector([typeT.ACCEPT_RETENTION])(state),
  )

  const hasErrorsRetention = useSelector(state =>
    hasErrors([typeT.ACCEPT_RETENTION])(state),
  )

  const [modalMultiple, setMultiple] = useState({
    show: false,
    item: {},
    amount: 0,
    purchase: true,
  })
  // Permissions
  const allowDeleteMovementTransaction = useSelector(state =>
    isAllowed(state, [bankAccountPermissionsEnum.DeleteMovementTransaction]),
  )

  // State

  const [customRef, setCustomRef] = useState({
    show: false,
    documentType: 0,
    documentId: 0,
  })

  const [flags, setFlags] = useState<IMovementsFlags>({
    create: false,
    retention: false,
  })

  // Const
  const getCurrencySymbolById = id => currencies?.find(c => c.id === id)?.symbol || {}
  const sortHistory = (a, b) => {
    return b.registredAt - a.registredAt
  }
  historyMovementsAccount?.sort(sortHistory)
  const userSel = [...usersSubmodule, { value: -1, label: 'Todos' }]

  const [movementSelected, setMovementSelected] = useState<number>(null)

  // Effect

  useEffect(() => {
    if (loadingOp) setFlags({ ...flags, create: true })
    else if (flags.create) {
      setFlags({ ...flags, create: false })
      if (errorOp) dispatch(showAlert(handlerError(errorOp.message)))
      else setUP()
    }
  }, [loadingOp])

  useEffect(() => {
    if (revertSelector.ok === undefined) return
    if (revertSelector.ok) {
      dispatch(showAlert(handlerSuccess('Movimiento Eliminado')))
      setUP()
    } else {
      dispatch(showAlert(handlerError(revertSelector.message)))
    }
  }, [revertSelector])

  useEffect(() => {
    if (loadingRetention) setFlags({ ...flags, retention: true })
    else if (flags.retention) {
      setFlags({ ...flags, retention: false })
      const alert = hasErrorsRetention
        ? handlerError(hasErrorsRetention.message)
        : {
            ...handlerSuccess('Operación exitosa'),
            onConfirm: () => setUP(),
          }
      dispatch(showAlert(alert))
      setMovementSelected(null)
    }
  }, [loadingRetention])

  useEffect(() => {
    if (!accounnt?.id) return
    const initialParams = { ...params, accountId: accounnt.id }
    updateParams(initialParams)
    setParamsTxt(JSON.stringify(initialParams))
  }, [accounnt?.id])

  useEffect(() => {
    if (paramsTxt === undefined || paramsTxt !== JSON.stringify(params)) return
    setUP()
  }, [paramsTxt])

  useEffect(() => {
    if (paramsTxt === undefined) return
    setParamsTxt(JSON.stringify(params))
  }, [params])

  useEffect(() => {
    if (paramsTxt === undefined) return
    updateParams({ ...updateParams, randomKey: new Date().valueOf() })
  }, [activeTable])

  const setUP = () => {
    dispatch(
      getAccountHistory(accounnt.id, { ...params, pending: activeTable === 'pending' }),
    )
  }

  // FUNCTIONS

  const deleteItemHandle = item => {
    dispatch(
      showAlert({
        type: 'warning',
        title: 'Advertencia',
        text: '¿Está seguro de eliminar la operación?',
        showCancelButton: true,
        show: true,
        onCancel: () => {
          dispatch(hideAlert())
        },
        onConfirm: () => {
          dispatch(revertOperation(item.id, 'Eliminar Movimiento'))
        },
      }),
    )
  }

  const getHeaders = (auth: boolean): ITableHeader[] => [
    {
      label: 'Fecha',
      show: true,
      type: 'date',
      value: ['registredAt'],
    },
    {
      label: 'Operacion',
      classNameCustom: item =>
        'mini ' + (item.operationType === 101 ? 'debit' : 'credit'),
      show: true,
      type: 'text',
      value: ['operationType'],
      custom: item => findOperation(item.operationType),
    },
    {
      label: 'Referencia',
      show: true,
      type: 'text',
      value: ['reference'],
      custom: item =>
        item.duplicate
          ? `${item.reference || '--'} - (DUPLICADO)`
          : item.reference || '--',
    },
    {
      label: 'Descripcion',
      show: true,
      type: 'text',
      value: ['description'],
      className: 'medium',
    },
    {
      label: 'Antes',
      show: auth,
      type: 'number',
      value: [`previousBalance`],
      custom: item =>
        `${getCurrencySymbolById(item.currency)}.${formatNumberWithCommas(
          item.previousBalance,
        )}`,
    },
    {
      label: 'Monto',
      show: true,
      type: 'number',
      value: [`amount`],
      custom: item =>
        `${getCurrencySymbolById(
          item.currency !== 0 ? item.currency : accounnt.currency.id,
        )}.${formatNumberWithCommas(item.amount)}`,
    },
    {
      label: 'Nuevo',
      show: auth,
      type: 'number',
      value: [`newBalance`],
      custom: item =>
        `${getCurrencySymbolById(item.currency)}.${formatNumberWithCommas(
          item.newBalance,
        )}`,
    },
    { label: 'Autorizado Por', show: auth, type: 'text', value: ['auth'] },
    { label: 'Acreditado a', show: true, type: 'text', value: ['user'] },

    {
      show: true,
      type: '',
      label: '',
      value: [],
      className: 'mini',
      config: true,
      custom: item => (
        <Dropdown
          disabled={movementSelected > 0}
          loading={movementSelected === item.id}
          items={[
            {
              show: auth && item.referenceType !== 39,
              icon: faEye,
              title: 'ver referencia',
              action: () => {
                setCustomRef({
                  show: true,
                  documentType: item.referenceType,
                  documentId:
                    item.referenceType === 5 ||
                    item.referenceType === 3 ||
                    item.referenceType === 12
                      ? item.id
                      : item.refDocument,
                })
              },
            },
            {
              icon: faCheckDouble,
              title: 'Acreditar',
              show: item.operationType === 102 && !item.refDocument && !auth,
              action: () => {
                setMultiple({
                  show: true,
                  item,
                  amount: 0,
                  purchase: true,
                })
              },
            },
            {
              show: !auth && allowDeleteMovementTransaction,
              title: 'Eliminar',
              action: () => deleteItemHandle(item),
              icon: faTrash,
            },
            {
              show: !auth && item.referenceType === 7,
              title: 'Aprobar retención',
              icon: faCheck,
              action: () => {
                setMovementSelected(item.id)
                dispatch(acceptRetention(item.id))
              },
            },
            {
              show: auth && item.referenceType === 7,
              title: 'Anular retención',
              icon: faTrash,
              action: () => {
                setMovementSelected(item.id)
                dispatch(acceptRetention(item.id, true))
              },
            },
          ]}
        />
      ),
    },
  ]

  const handleSearchOnChange = (start, end) => {
    start = start ? start.setHours(0, 0, 0, 0).valueOf() : null
    end = end ? end.setHours(23, 59, 59, 99).valueOf() : null

    updateParams({ ...updateParams, start: start, end: end })
  }

  const renderDetailsFilter = (
    users,
    activeTab,
    //                             handleDetailsFiltersChange
  ) => <DetailsFilters users={users} activeTab={activeTab} />

  // @ts-ignore

  // @ts-ignore
  return (
    <>
      <div style={{ display: 'contents' }} className={'column'}>
        <TableV2
          // @ts-ignore
          customClass={'scrollX'}
          headers={getHeaders(activeTable === 'authorized')}
          items={historyMovementsAccount || []}
          total={sizeList}
          loading={loadingAH}
          onDateChange={(start, end) => handleSearchOnChange(start, end)}
          dateFilter
          customFilter={renderDetailsFilter(userSel, activeTable)}
          nullDate
          getPagination={(skip, size, search) =>
            updateParams({ ...updateParams, skip: skip, size: size, search: search })
          }
          handleChange={search => updateParams({ ...updateParams, search: search })}
          mobileAuto
        />
        <CustomReference
          show={customRef.show}
          documentModule={1}
          documentType={customRef.documentType}
          documentId={customRef.documentId}
          onHide={() => setCustomRef({ ...customRef, show: false })}
        />

        <AccreditationMultiple
          {...modalMultiple}
          show={modalMultiple.show}
          onHide={(success: boolean) => {
            setMultiple({ ...modalMultiple, show: false })
            if (success) setUP()
          }}
        />
      </div>
    </>
  )
}
export default AccountMovementsTable
