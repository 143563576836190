import * as React from 'react'
import { Col, Modal, Row, Button, InputGroup, FormControl } from 'react-bootstrap'

import { connect } from 'react-redux'

import '../Subscriptions.scss'
import Icon from 'src/components/buttons/IconButton'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Placeholder from 'src/components/Placeholder/Placeholder'
import SelectProducts from 'src/components/SelectProducts/SelectProducts'

import { getPermission } from 'src/selectors/modules.selector'
import { actionTypes } from 'src/actions/warehouse.actions'
import { selectCurrentCompany } from 'src/selectors/user.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  selectAllProducts,
  selectAllProductsCompany,
} from 'src/selectors/products.selector'
import {
  actionTypes as types,
  getProducts,
  getProductsCompany,
} from 'src/actions/products.actions'

class ModalSelectProducts extends React.Component {
  state = {
    maxDiscount: 0,
    search: '',
    // inventory: [],
    products: [],
  }

  componentDidMount() {
    const {
      match,
      getOrder,
      module,
      getAllClients,
      setInfo,
      getWarehouses,
      getPermission,
      getWarehousesUser,
      setInventoryList,
    } = this.props

    // if(getPermission(1151)|getPermission(2101))
    // {
    //     getWarehouses();
    // }
    // else{
    //     getWarehousesUser();
    // }

    if (this.props.companyId) {
      this.props.getProductsCompany(this.props.companyId)
    } else {
      this.props.getProducts()
    }
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { loadingI, setInventoryList, loadingP, loadingPC, productsDefault } =
      this.props
    const { inventory, products, productsCompany } = next

    if (
      productsDefault.length !== next.productsDefault.length &&
      this.state.products.length > 0
    ) {
      this.fillProductsDefault(next.productsDefault)
    }

    // if (loadingI && !next.loadingI) {
    //     let items = inventory.map((i) => {
    //
    //         if (!i.error && !i.productActive) {
    //             i.error = 'El producto esta inactivo';
    //             i.disabled = true;
    //         }
    //         return {
    //             ...i,
    //             checked: false,
    //             realQuantity: 0,
    //             subTotal: 0,
    //             maxDiscount: 0,
    //             quantity: 0,
    //             discount: 0,
    //             sQuantity: 0
    //         };
    //     });
    //     this.setState({inventory: items})
    // }

    if (loadingP && !next.loadingP) {
      let items = products.map(i => {
        if (!i.error && !i.productActive) {
          i.error = 'El producto esta inactivo'
          i.disabled = true
        }
        return {
          ...i,
          checked: false,
          realQuantity: 0,
          subTotal: 0,
          maxDiscount: 0,
          quantity: 0,
          discount: 0,
          sQuantity: 0,
        }
      })
      // this.setState({inventory: items})
      this.setState({ products: items })
    }

    if (loadingPC && !next.loadingPC) {
      let items = productsCompany.map(i => {
        if (!i.error && !i.productActive) {
          i.error = 'El producto esta inactivo'
          i.disabled = true
        }
        return {
          ...i,
          checked: false,
          realQuantity: 0,
          subTotal: 0,
          maxDiscount: 0,
          quantity: 0,
          discount: 0,
          sQuantity: 0,
        }
      })
      // this.setState({inventory: items})
      this.setState({ products: items }, () => {
        if (this.props.productsDefault.length > 0) {
          this.fillProductsDefault(this.props.productsDefault)
        }
      })
    }
  }

  fillProductsDefault = productsDefault => {
    const { products } = this.state

    if (productsDefault.length > 0 && products.length > 0) {
      let items = productsDefault.map(productDefault => {
        let item = products.find(i => i.productId === productDefault.productId)

        if (item) {
          item.checked = true
          item.quantity = productDefault.quantity

          products.map(product => {
            if (product.productId === productDefault.id) {
              product = item
            }
          })
        }
      })
      this.setState({ products }, () => {
        this.selectProducts()
      })
    }
  }

  selectProducts = () => {
    // this.props.onSelectProducts(this.state.inventory, this.state.warehouse)
    this.props.onSelectProducts(this.state.products)
  }

  filterProducts = item => {
    const { search } = this.state
    const exp = new RegExp(search.toUpperCase(), 'g')
    return Object.keys(item).some(p =>
      `${JSON.stringify(item[p])}`.toUpperCase().match(exp),
    )
  }

  getTotal = () => {
    const { products } = this.state
    let total = 0
    products
      .filter(i => i.checked)
      .map(i => {
        total += parseFloat(i.realQuantity) * i.productPrice - i.discount
      })
    return total
  }

  getDiscounts = () => {
    const { products } = this.state
    let total = 0
    products
      .filter(i => i.checked)
      .map(i => {
        total += i.discount
      })
    return total
  }

  seeOptions = (checked, index) => {
    const { setInventoryList, products } = this.state
    products.map(i => {
      if (i.productId === index) {
        i.checked = checked
      }
    })
    this.setState({ products })
  }

  changeQuantity = (value, index) => {
    const { setInventoryList, products } = this.state
    let item = products.find(i => i.productId === index)
    if (!item) return
    let quantity = parseInt(value)

    if (quantity < 0) quantity = 0
    if (item.productExistence <= 0) {
    } else if (quantity > item.productExistence) quantity = item.productExistence

    let nowQuantity = (item.transformationFactor || 1) * quantity
    if (item.subProductId)
      nowQuantity +=
        (item.subProductData.transformationFactor || 1) * (item.sQuantity || 0)

    const maxDiscount = this.getMaxDiscount(
      nowQuantity / (item.transformationFactor || 1),
      item,
    )
    if (item.discount > maxDiscount) item.discount = maxDiscount

    item.realQuantity = nowQuantity / (item.transformationFactor || 1)
    item.quantity = quantity
    item.maxDiscount = maxDiscount

    products.map(i => {
      if (i.productId === index) {
        i = item
      }
    })
    this.setState({ products })
  }

  changeSQuantity = (value, index) => {
    const { setInventoryList, products } = this.state

    let item = products.find(i => i.productId === index)
    if (!item) return

    let quantity = parseInt(value)
    const maxQuantity = item.subProductValue || 0

    if (quantity > maxQuantity) quantity = maxQuantity
    if (quantity < 0) quantity = 0

    let nowQuantity = (item.subProductData.transformationFactor || 1) * quantity

    if (item.quantity) nowQuantity += item.transformationFactor * (item.quantity || 0)

    const maxDiscount = this.getMaxDiscount(
      (nowQuantity < 0 ? 0 : nowQuantity) / (item.transformationFactor || 0),
      item,
    )
    if (item.discount > maxDiscount) item.discount = maxDiscount

    item.realQuantity = nowQuantity / (item.transformationFactor || 1)
    item.sQuantity = quantity
    item.maxDiscount = maxDiscount

    products.map(i => {
      if (i.productId === index) {
        i = item
      }
    })

    this.setState({ products })
  }

  changeDiscount = (value, index) => {
    const { setInventoryList, products } = this.state
    let item = products.find(i => i.productId === index)
    if (!item) return

    let discount = parseFloat(value)
    if (discount < 0) discount = 0
    else if (discount > item.maxDiscount) discount = item.maxDiscount
    item.discount = discount

    products.map(i => {
      if (i.productId === index) {
        i = item
      }
    })
    this.setState({ products })
  }

  getMaxDiscount = (quantity, item) => {
    if (isNaN(quantity)) quantity = 0
    const { discountLimit } = this.props
    let discount = 0
    if (item.prices && discountLimit) {
      item.prices
        .filter(p => quantity >= p.minimum && quantity <= p.maximum)
        .map(p => (discount = p.price))
      discount -= discount * 0.05
    } else if (!discountLimit) discount = item.productPrice
    return parseFloat(discount) * parseFloat(quantity)
  }

  render() {
    const {
      loadingI,
      currentCompany,
      allWarehouses,
      order,
      getInventoryWithPrices,
      getInventory,
      discountLimit,
      client,
      loadingP,
    } = this.props
    const { warehouse, search, products } = this.state

    return (
      <Modal
        className={'sub-style'}
        dialogClassName="my-modal-subscription-products"
        centered
        show={this.props.show}
        onHide={this.props.onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>Selección de productos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {/*<Col xl={12} lg={12} md={12} sm={12} xs={12}>*/}
            {/*    <CustomSelect*/}
            {/*        loading={loadingI}*/}
            {/*        label={'Bodegas'}*/}
            {/*        options={allWarehouses}*/}
            {/*        value={warehouse}*/}
            {/*        onChange={(data) => {*/}
            {/*            this.setState({warehouse: data, products: []})*/}
            {/*            if (discountLimit) getInventoryWithPrices(data.value, client, currentCompany, true);*/}
            {/*            else getInventory(data.value, true);*/}
            {/*        }}*/}
            {/*        required*/}
            {/*    />*/}
            {/*</Col>*/}
            <Col>
              <div className={'column'}>
                <Row className={'container-buttons'}>
                  <Button variant={'danger'} onClick={() => this.selectProducts()}>
                    Agregar productos a suscripción
                  </Button>
                </Row>

                <br />
                {/*<Row>*/}
                {/*<Col>*/}
                {/*    <Card>*/}
                {/*        <div className={'justify-center column'}>*/}
                {/*            <span>Total</span>*/}
                {/*            <h4><strong>Q. {formatNumberWithCommas(this.getTotal())}</strong></h4>*/}
                {/*        </div>*/}
                {/*    </Card>*/}
                {/*</Col>*/}
                {/*<Col xl={6} lg={6} md={6} sm={12} xs={12}>*/}
                {/*    <Card>*/}
                {/*        <div className={'justify-center column'}>*/}
                {/*            <span>Descuentos</span>*/}
                {/*            <h4><strong>Q. {formatNumberWithCommas(this.getDiscounts())}</strong></h4>*/}
                {/*        </div>*/}
                {/*    </Card>*/}
                {/*</Col>*/}
                {/*</Row>*/}

                <InputGroup className={'mb-3'}>
                  <FormControl
                    placeholder={'Buscar por Nombre / descripción'}
                    value={search}
                    onChange={e => this.setState({ search: e.target.value })}
                  />
                  <InputGroup.Text>
                    <Icon tooltip={'Buscar'} icon={faSearch} />
                  </InputGroup.Text>
                </InputGroup>

                <Row className="my-modal-body">
                  {loadingP
                    ? [0, 0, 0].map((i, index) => (
                        <Col key={index}>
                          <Placeholder />
                        </Col>
                      ))
                    : products.filter(this.filterProducts).map((i, index) => (
                        <Col xl={3} lg={3} md={6} sm={6} xs={12} key={index}>
                          <SelectProducts
                            index={i.productId}
                            sku={i.productCode}
                            title={i.productName}
                            // subtitle={`${i.productCompanyData.alphanumeric ? i.productCompanyData.alphanumeric : ''} - ${i.productCompanyData.name}`}
                            subtitle={`${i.companyName}`}
                            description={i.productDescription}
                            price={i.productPrice}
                            discount={i.discount}
                            seeDiscount={false}
                            maxDiscount={i.maxDiscount}
                            subtotal={
                              parseFloat(i.realQuantity) * i.productPrice - i.discount
                            }
                            checked={i.checked}
                            quantity={i.quantity}
                            sQuantity={i.sQuantity}
                            sValue={i.subProductId}
                            sTitle={i.subProductData ? i.subProductData.name : ''}
                            seeOptions={this.seeOptions}
                            changeQuantity={this.changeQuantity}
                            changeSQuantity={this.changeSQuantity}
                            changeDiscount={this.changeDiscount}
                            error={i.error}
                            disabled={i.disabled}
                          />
                        </Col>
                      ))}
                </Row>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    )
  }
}

ModalSelectProducts.defaultProps = {
  productsDefault: [],
}

const mapStateToProps = state => ({
  getPermission: id => getPermission(state, id),
  // allWarehouses: selectAllWarehousesForSelect(state),
  // inventory: selectWarehouseInventory(state),

  products: selectAllProducts(state),

  currentCompany: selectCurrentCompany(state),
  // loadingI: loadingSelector([actionTypes.INVENTORY])(state),
  loadingP: loadingSelector([actionTypes.GET_ALL_PRODUCTS])(state),

  productsCompany: selectAllProductsCompany(state),
  loadingPC: loadingSelector([types.GET_ALL_PRODUCTS_COMPANY])(state),
})
const mapDispatchToProps = dispatch => ({
  // getInventoryWithPrices: (id, client, currentCompany, includeNoExistence) => dispatch(getExistenceSingleWarehouseWithPriceProducts(id, client, currentCompany, includeNoExistence)),
  // getInventory: (id, includeNoExistence) => dispatch(getInventory(id, includeNoExistence)),
  // setInventoryList: (list) => dispatch(setInventoryList(list)),
  // getWarehouses: () => dispatch(getWarehouses()),
  // getWarehousesUser: () => dispatch(getWarehousesUser()),

  getProducts: () => dispatch(getProducts()),
  getProductsCompany: companyId => dispatch(getProductsCompany(companyId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalSelectProducts)
