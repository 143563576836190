import { Timestamp } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useDebounce from 'src/hooks/useDebounce'
import {
  selectCurrentCompany,
  selectCurrentModule,
  selectCurrentUser,
} from 'src/selectors/user.selector'
import {
  clearSession,
  getPath,
  getSession,
  saveSession as updateSession,
} from 'src/services/firebase/session'

function useSession<T>(sessionKey: string) {
  const module = useSelector(selectCurrentModule)
  const user = useSelector(selectCurrentUser)
  const company = useSelector(selectCurrentCompany)

  const [session, setSession] = useState<T>()
  const path = getPath({
    userId: `${user.id}`,
    placement: `${sessionKey}-${company}-${module}`,
  })

  useEffect(() => {
    getSession(path).then(result => {
      if (result !== undefined) {
        const data = result.data()
        Object.keys(data).forEach(key => {
          if (data[key] instanceof Timestamp) {
            data[key] = new Timestamp(data[key].seconds, data[key].nanoseconds).toDate()
          }
        })
        setSession(data as T)
      }
    })
  }, [sessionKey])

  function removeSession() {
    setSession(undefined)
    void clearSession(path).catch(console.error)
  }

  const saveSession: (state: T) => void = useDebounce((state: T) => {
    void updateSession(path, state).catch(console.error)
  }, 1000)

  return [session, saveSession, removeSession] as const
}

export { useSession }
