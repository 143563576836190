import React, { useState } from 'react'
import { Container, Modal, ProgressBar } from 'react-bootstrap'
import Button from 'src/components/buttons/Button'
import MapCard from 'src/components/maps/MapCard'
import WarehouseService from '../../../services/warehouse.service'

export const WarehouseLocation = ({ show, warehouses, onHide }) => {
  const initialState = {
    coordinates: [],
    center: {
      latitude: null,
      longitude: null,
    },
  }

  const [isLoading, setIsLoading] = useState(false)
  const [state, setState] = useState(initialState)

  const fetchGps = async () => {
    try {
      setIsLoading(true)
      const gpsCoords = await WarehouseService.getGPSCoords(
        warehouses.map(warehouse => warehouse.id),
      )

      if (gpsCoords && gpsCoords.length > 0) {
        const coordinates = gpsCoords.map(coord => {
          const warehouse = warehouses.find(
            warehouse => warehouse.gpsDevice && warehouse.gpsDevice.wialonId === coord.id,
          )
          const text = coord.name ? coord.name : warehouse ? warehouse.name : ''
          return { ...coord, text }
        })
        const { latitude, longitude } = gpsCoords[0]
        setState({ ...state, center: { latitude, longitude }, coordinates })
      }
    } catch (exception) {
      console.error(exception)
      const { center, coordinates } = initialState
      setState({ ...state, isLoading: false, center, coordinates })
    }
    setIsLoading(false)
  }

  const handleHide = () => {
    const { gps, coordinates } = initialState
    setState({ ...state, gps, coordinates })
    onHide()
  }

  return (
    <>
      <Modal
        show={show}
        size={'md'}
        centered
        onHide={handleHide}
        onShow={() => fetchGps()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Ubicación de bodega</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {isLoading ? (
              <ProgressBar animated now={100} />
            ) : state.coordinates && state.coordinates.length > 0 ? (
              <MapCard
                noCollapse
                latitude={state.center.latitude}
                longitude={state.center.longitude}
                height={600}
                yesIWantToUseGoogleMapApiInternals
                showMarker={true}
                zoom={10}
                markerList={state.coordinates}
              />
            ) : (
              <span>No hay ubicación disponible para las bodegas seleccionadas</span>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={state.isLoading} onClick={fetchGps}>
            Actualizar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
