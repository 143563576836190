import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";

import {Row, Col} from "react-bootstrap";
import {Td, Tr} from "react-super-responsive-table";
import Alert from "sweetalert-react";

import {
    Card, Button, StatusStep, ProductItems, ProductItem, CustomTabs, GetLocation, MapCard, Icon, TableV2
} from "../../../components";
import ConfirmOrder from "../../../components/modal/Orders/ModalOrderConfirm";

import {
    actionTypes as typeO,
    deleteSingleOrder,
    getSingleOrder, printOrder
} from "../../../actions/orders.actions";
import {selectCreateNewOrder} from "../../../selectors/orders.selector";

import {
    actionTypes as typeT,
    acceptTransfer,
    rejectTransfer
} from "../../../actions/warehouse.actions";

import {
    onRefresh,
    setModalOrderTransferInfo,
    setOrderProcessId
} from "../../../actions/utilities.actions";
import {selectRefresh} from "../../../selectors/utilities.selector";

import {loadingSelector} from "../../../selectors/loading.selector";
import {hasErrorsSelector, singleErrorSelector, handlerError, handlerSuccess} from "../../../selectors/error.selector";
import {toMoney} from '../../../utils/utilities'
import {selectCurrentUser} from "../../../selectors/user.selector";
import {isAllowed} from "../../../selectors/modules.selector";

import {formatNumberWithCommas, getItemValue} from "../../../utils/formatters";
import {faWaze} from "@fortawesome/free-brands-svg-icons";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import Money from '../../../components/Money/Money';
import { selectCurrentCurrency } from '../../../selectors/currencies.selector';

const OrderDetailPage = ({match}) => {
    const dispatch = useDispatch();

    const order = useSelector(selectCreateNewOrder);
    const refresh = useSelector(selectRefresh);
    const user = useSelector(selectCurrentUser);
    const currentCurrency = useSelector(selectCurrentCurrency);

    const loadingR = useSelector((state) => loadingSelector([typeO.DELETE_SINGLE_ORDER])(state));
    const hasErrorR = useSelector((state) => hasErrorsSelector([typeO.DELETE_SINGLE_ORDER])(state));
    const errorR = useSelector((state) => singleErrorSelector([typeO.DELETE_SINGLE_ORDER])(state));

    const loadingT = useSelector((state) => loadingSelector([typeT.ACCEPT_TRANSFER])(state));
    const hasErrorT = useSelector((state) => hasErrorsSelector([typeT.ACCEPT_TRANSFER])(state));
    const errorT = useSelector((state) => singleErrorSelector([typeT.ACCEPT_TRANSFER])(state));

    const loadingTR = useSelector((state) => loadingSelector([typeT.REJECT_TRANSFER])(state));
    const hasErrorTR = useSelector((state) => hasErrorsSelector([typeT.REJECT_TRANSFER])(state));
    const errorTR = useSelector((state) => singleErrorSelector([typeT.REJECT_TRANSFER])(state));

    const admin = useSelector((state) => isAllowed(state, [1001, 2001]));
    const process = useSelector((state) => isAllowed(state, [1120, 2820]));
    const transfer = useSelector((state) => isAllowed(state, [1119]));

    const [actions, setActions] = useState({rejected: false, transfer: false, transferR: false});
    const [google, setGoogle] = useState({map: null, maps: null});
    const [location, setLocation] = useState(null);
    const [alert, setAlert] = useState({title: ''});
    const [modalConfirm, setConfirm] = useState({show: false});

    const setUp = () => {
        const {id} = match.params;
        dispatch(getSingleOrder(id));
    };

    useEffect(() => {
        setUp();
    }, []);

    useEffect(() => {
        const {map} = google;
        if (map)
            setRoute();
    }, [google]);

    useEffect(() => {
        if (refresh) {
            dispatch(onRefresh(false));
            setUp();
        }
    }, [refresh]);

    useEffect(() => {
        if (loadingR)
            setActions({...actions, rejected: true});
        else if (actions.rejected) {
            setActions({...actions, rejected: false});
            if (hasErrorR)
                setAlert({
                    ...handlerError(errorR.message),
                    onConfirm: () => setAlert({...alert, show: false})
                });
            else
                setAlert({
                    ...handlerSuccess('Orden de venta rechazada satisfactoriamente'),
                    onConfirm: () => {setAlert({...alert, show: false}); setUp();}
                });
        }
    }, [loadingR]);

    useEffect(() => {
        if (loadingT)
            setActions({...actions, transfer: true});
        else if (actions.transfer) {
            setActions({...actions, transfer: false});
            if (hasErrorT)
                setAlert({
                    ...handlerError(errorT.message),
                    onConfirm: () => setAlert({...alert, show: false})
                });
            else
                setAlert({
                    ...handlerSuccess('Transferencia aceptada satisfactoriamente'),
                    onConfirm: () => {setAlert({...alert, show: false}); setUp();}
                });
        }
    }, [loadingT]);

    useEffect(() => {
        if (loadingTR)
            setActions({...actions, transferR: true});
        else if (actions.transferR) {
            setActions({...actions, transferR: false});
            if (hasErrorTR)
                setAlert({
                    ...handlerError(errorTR.message),
                    onConfirm: () => setAlert({...alert, show: false})
                });
            else
                setAlert({
                    ...handlerSuccess('Transferencia rechazada satisfactoriamente'),
                    onConfirm: () => {setAlert({...alert, show: false}); setUp();}
                });
        }
    }, [loadingTR]);

    const getHeaders = (data) => {
        let a = ''
        if (data!==undefined && data.length > 0 ){
            console.log(data)
            a = data[0].productData ? ['productData','name']: data[0].product ? ['product','name']: ['Desconocido']
        }
        //let a  = data!==undefined || data !== [] ?  data[0].productData ? ['productData','name']: data[0].product ? ['product','name'] : ['Desconocido'] : ''

        return [
            {label: 'Cantidad', show: true,value: ['quantity'], type:'text',  className:'mini' }  ,
            {label: 'Producto', show: true, type:'text',  className:'product',value:a, custom:(item)=> item.productData ? item.productData.name : item.product ? item.product.name : 'Desconocido'},
            {label: `Precio (${currentCurrency.symbol})`, value:['price'],type:'text', show: true,  className:'mini' , type:'currency',custom:(item)=> toMoney(item.subtotal ? (item.subtotal / item.quantity) : 0)},
            {label: `Subtotal (${currentCurrency.symbol})`, show: true, value: ['subtotal'], type: 'currency' ,  className:'mini'},
            {label: `Descuento (${currentCurrency.symbol})`, show: true, value: ['discount'], type: 'currency' ,  className:'mini'},
            {label: `Total (${currentCurrency.symbol})`, show: true, value: ['total'], type: 'currency' ,  className:'mini'},
            {config:true, show: true, label:'', className:'mini'}]
    }

    const getItemsMobile = (data) => {
        let array = []
        let store = localStorage.getItem(`orderDet-`)
        if(!store) store={title: {value: ['quantity'], type: 'text'}, subtitle: {value: ['product', 'name'], type: 'text'},
            complement1: {value: ['discount', 'name'], type: 'currency'}, complement2: {value: ['total'], type: 'currency'}}
        else store = JSON.parse(store)

        data && data.map((item, i) => {
            item.price  =  item.subtotal ? (item.subtotal / item.quantity) : 0
            let more = [
                {title: 'Cantidad', show: true, info : item.quantity }  ,
            {title: 'Producto', info : item.productData ? item.productData.name : item.product ? item.product.name : 'Desconocido'},
            {title: `Precio (${currentCurrency.symbol})`, info : toMoney(item.subtotal ? (item.subtotal / item.quantity) : 0)},
            {title: `Subtotal (${currentCurrency.symbol})`, info : toMoney(item.subtotal)},
            {title: `Descuento (${currentCurrency.symbol})`, info : toMoney(item.discount)},
            {title: `Total (${currentCurrency.symbol})`, info : toMoney(item.total)},
            ]

            let object = {id: item.id, title: getItemValue(item, store.title.value, store.title.type) ,
                subtitle: getItemValue(item, store.subtitle.value, store.subtitle.type),
                complement1: getItemValue(item, store.complement1.value, store.complement1.type),
                complement2: getItemValue(item, store.complement2.value, store.complement2.type),
                //selected: selected.filter(d => d.id === item.id).length > 0,
                key: `orderDet-${item.id}`, action: '', more
            }
            array.push(object)
        })
        return array;
    }
    const renderTable = () => {
        return (<>
        <TableV2
            itemsMobile={getItemsMobile(order.details)}
            storageKey={`orderDet-`}
            headers={getHeaders(order.details)}
            items= {order.details}
            footerText={<div className={'column'}>
            {(order.linked && order.linkType !== 3 && order.linkType !== 2) && <>
                <Money component='h2'>Subtotal: {order.total - (order.shipping || 0)}</Money>
                <Money component='h2' style={{color: 'rgba(203,64,96,0.99)'}}>Costo de envio: {order.shipping}</Money>
            </>}
            <Money component='h2'>Total: {order.total}</Money>
        </div>}
        />

        </>
        );
    };

    const getItems = () => {
        const response = [];

        if (order.parcels) {
            order.parcels.map((p) => {
                p.products.map((pp) => {
                    response.push({
                        ...pp,
                        companyId: p.number,
                        companyName: p.packageName
                    });
                });
            });
        }
/*
        if(order.details){
            order.details.map((p) => {
                if(p.product){
                    let product = p.product
                    response.push({
                        ...product,
                        quantity: p.quantity,
                        companyId: `${p.id}`,
                        companyName: `Contenedor No.${p.number}`
                    });
                }
            });
        }
        */
        return response;
    };

    const setRoute = () => {
        if (order.status !== 3) {
            const {map, maps} = google;
            const {latitude, longitude} = location;

            const directionsService = new maps.DirectionsService;
            const directionsDisplay = new maps.DirectionsRenderer;
            directionsDisplay.setMap(map);

            const origin = {lat: latitude, lng: longitude};
            const destiny = {lat: Number(order.client.latitude), lng: Number(order.client.longitude)};


            const waypoints = [{location: origin, stopover: true}];
            if (order.WLatitude && order.WLongitude && order.status === 7)
                waypoints.push({location: {lat: Number(order.WLatitude), lng: Number(order.WLongitude)}, stopover: true});
            waypoints.push({location: destiny, stopover: true});

            directionsService.route({
                origin: order.status === 3 ? destiny : origin,
                destination: destiny || origin,
                waypoints,
                optimizeWaypoints: false,
                travelMode: 'DRIVING'
            }, (response, status) => {
                if (status === 'OK') {
                    directionsDisplay.setDirections(response);
                } else {

                }
            });
        }
    };

    const getCancelActions = () => {
        let show = false, action = () => {}, name = '';

        if (order.orderId) {
            const r = order.attendant || order.responsibleUser;
            if (r)
                show = r.id === user.id;
            else
                show = admin;

            //http://localhost:3000/distribucion/1000/redirect/order/27481/656

            switch (order.status) {
                case 1:
                    if (order.paymentType !== 3) {
                        action = () => setAlert({
                            type: 'input',
                            inputPlaceholder: 'Motivo del rechazo',
                            confirmButtonText: 'Rechazar',
                            confirmButtonColor: 'rgb(203,64,96)',
                            cancelButtonText: 'Cancelar',
                            show: true,
                            title: `¿Desea rechazar la orden de venta No.${order.number}?`,
                            text: 'Esta acción no podrá ser revertida.',
                            showCancelButton: true,
                            onCancel: () => {
                                setAlert({...alert, show: false})
                            },
                            onConfirm: (description) => {
                                setAlert({...alert, show: false});
                                dispatch(deleteSingleOrder(order.orderId, description));
                            }
                        });
                        name = 'Rechazar';
                    } else
                        show = false;
                    break;
                case 7:
                    if (order.attendant) {
                        if (order.attendant.id === user.id) {
                            name = "Rechazar transferencia";
                            action = () => {
                                dispatch(rejectTransfer(order.transferId));
                            };
                        }
                    } else
                        show = false;
                    break
                case 8:
                    show = transfer;
                    name = "Transferir Orden";
                    action = () => {
                        dispatch(setModalOrderTransferInfo({refresh: true, id: order.orderId, number: order.number, show: true, groupId: order.groupId}));
                    };
                    break
                default:
                    show = false;
                    break;
            }
        }

        return {show, action, name};
    };

    const getConfirmActions = () => {
        let show = false, action = () => {}, name = '';

        if (order.orderId) {
            const r = order.attendant || order.responsibleUser;
            if (r)
                show = r.id === user.id;
            else
                show = admin;
            switch (order.status) {
                case 1:
                    if (!show) show = process;
                    name = 'Procesar Orden';
                    action = () => dispatch(setOrderProcessId(order.orderId));
                    break;
                case 7:
                    if (order.attendant) {
                        if (order.attendant.id === user.id) {
                            show = true;
                            name = "Aceptar transferencia";
                            action = () => {
                                dispatch(acceptTransfer(order.transferId));
                            };
                        }
                    } else {
                        name = "Transferir Orden";
                        action = () => {
                            dispatch(setModalOrderTransferInfo({refresh: true, id: order.orderId, number: order.number, show: true, groupId: order.groupId}));
                        };
                    }
                    break;
                case 8:
                    name = "Confirmar orden";
                    action = () => setConfirm({...order, show: true});
                    break;
                case 3:
                    name = "Ver facturas";
                    action = () => goToInvoice();
                    break;
                default:
                    show = false;
                    break;
            }
        }

        return {show, action, name};
    };

    const goToInvoice = () => {
        let url = module === 1000 ? '/distribucion' : '/produccion';
        url += '/ordenes/false/invoices/' + order.orderId + `/${order.number}`;
        window.open(url, '_blank');
    };

    const renderButtons = () => {
        const cancel = getCancelActions();
        const confirm = getConfirmActions();

        return <Row className={'container-buttons'}>
            {cancel.show && <Button
                disabled={loadingT}
                loading={loadingR || loadingTR}
                color={'secondary'}
                onClick={() => cancel.action()}
          >{cancel.name}</Button>}
            {confirm.show && <Button
                loading={loadingT}
                disabled={loadingR || loadingTR}
                color={'primary'}
                onClick={() => confirm.action()}
            >{confirm.name}</Button>}
        </Row>
    };

    const goToWaze = () => {
        return <Icon size={'2x'} tooltip={'Ir con waze'} icon={faWaze} style={{color:'white'}} onClick={() => {
            const route = `https://www.waze.com/ul?ll=${order.client.latitude}%2C${order.client.longitude}&navigate=yes`;
            window.open(route, '_blank');
        }}/>;
    };

    const getMarkers = () => {
        const response = [];
        if (order && order.client) {
            if (order.status !== 3)
                response.push({latitude: order.client.latitude, longitude: order.client.longitude, text: ''});
            else if (order.longitude && order.latitude)
                response.push({latitude: order.latitude, longitude: order.longitude, text: ''});
        }
        if (order && order.WLatitude && order.WLongitude)
            response.push({latitude: order.WLatitude, longitude: order.WLongitude, text: 'Recolección', type: 1});

        return response;
    };

    return (<div>
        <Row>
            <Col xl={5} lg={5} md={5} sm={12} xs={12}><Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Card title={'Información'} button={<Icon icon={faPrint} tooltip={'Imprimir orden'} onClick={() => dispatch(printOrder(order.orderId, order.number))}/>}
                      >  <Row>
                            <Col><b>Empresa</b></Col>
                            <Col>{order.company ? order.company.name : 'Desconocido'}</Col>
                        </Row>
                        <Row>
                            <Col><b>Tipo de pago</b></Col>
                            <Col>{order.paymentTypeData  ? order.paymentTypeData.name : 'Desconocido'}</Col>
                        </Row>
                        <Row>
                            <Col><b>Tipo</b></Col>
                            <Col>{order.orderTypeData && order.orderTypeData.name}</Col>
                        </Row>
                        <Row>
                            <Col><b>Bodega</b></Col>
                            <Col>{order.warehouse ? order.warehouse.name : 'Sin Bodega'}</Col>
                        </Row>
                        {order.description ?
                            <Row>
                                <Col md={1}> </Col>
                                <Col md={10}><b>Descripcion</b><br/> {order.description}</Col>
                                <Col md={1}> </Col>
                            </Row>
                            : '' }
                        <Row>
                            <Col><b>Cliente</b></Col>
                            <Col>{order.client ? order.client.companyName : 'Desconocido'}</Col>
                        </Row>
                        <Row>
                            <Col><b>Dirección</b></Col>
                            <Col>{order.client ? order.client.secondAddress ?
                                order.client.secondAddress: order.client.address : 'Desconocido'}</Col>
                        </Row>
                        <Row>
                            <Col><b>Referencia</b></Col>
                            <Col>{(order.client && order.client.reference) ? order.client.reference : 'Sin referencia'}</Col>
                        </Row>
                        <Row>
                            <br/>
                            <Col className={'text-center'}>
                                {order.deleted ? <b className={'red-text'}>Orden Eliminada</b> : ''}
                            </Col>
                        </Row>
                        <Row>
                            <Col><b>Responsable:</b></Col>
                            <Col>{order.responsibleUser ? order.responsibleUser.name : 'Sin responsable'}</Col>
                        </Row>
                        {(order.orderId && order.status === 7) && <Row>
                            <Col xl={12} lg={12}><Row>
                                <Col><b>Transferido a:</b></Col>
                                <Col>{order.attendant ? order.attendant.name : 'Sin responsable'}</Col>
                            </Row></Col>
                            <Col xl={12} lg={12} className={'text-center'}>
                                <p>{order.transferId ? 'Transferencia de inventario pendiente de aprobar' : 'Esta orden aún no ha sido transferida'}</p>
                            </Col>
                        </Row>}
                        <Row>
                            <Col><b>Estado</b></Col>
                            {/*<Col>{order.statusData && order.statusData.name}</Col>*/}
                            {(order.statusHistories !== undefined) && <Col><StatusStep
                                status={order.statusHistories}
                                reverse
                                value={parseInt(order.status)}
                                current={order.statusData ? order.statusData.name : 'Ingresada'}
                            /></Col>}
                        </Row>

                        {renderButtons()}
                    </Card>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    {location && <Card title={'Ubicación del cliente'} button={goToWaze()}>
                        <MapCard
                            noCollapse
                            startCollapsed={false}
                            zoom={13}
                            address={order.client ? order.client.address : 'Desconocido'}
                            latitude={order.client ? Number(order.client.latitude) : 14.55}
                            longitude={order.client ? Number(order.client.longitude) : -90.55}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({map, maps}) => setGoogle({map, maps})}
                            showMarker={true}
                            markerList={getMarkers()}
                        />
                    </Card>}
                </Col>
            </Row></Col>
            <Col xl={7} lg={7} md={7} sm={12} xs={12}>{order.groupId ?
                <CustomTabs items={[
                    {title: 'Productos', component: renderTable()},
                    {title: 'Contenedores', component: <ProductItems
                            style={{maxHeight: '490px'}}
                            hideFilter
                            noItems={'No se encontraron contenedores'}
                            customItems={getItems()}
                            filter={() => {return true}}
                            renderItem={(item, index) => <ProductItem
                                eventKey={index}
                                title={item.productName}
                                titleSecond={`${item.quantity} u.`}
                                subTitle={item.productCode}
                                subTitleSecond={item.warehouseName}
                            />}
                        />}
                ]}/> :
                <Card title={'Productos'}>{renderTable()}</Card>
            }</Col>
        </Row>

        <ConfirmOrder
            ignoreGPS
            show={modalConfirm.show}
            order={modalConfirm}
            onHide={() => {
                setConfirm({show: false});
            }}
            onHideSuccess={(invoice) => {
                setConfirm({show: false});
                setUp();
                if (invoice)
                    goToInvoice();
            }}
        />

        {order.warehouse && <GetLocation
            start
            getGeo={(cc) => setLocation(cc)}
            warehouseId={order.warehouse.id}
        />}

        <Alert {...alert}/>
    </div>);
};
export default OrderDetailPage;
