import React, { Component } from 'react'
import { connect } from 'react-redux'
import ImageGallery from 'react-image-gallery'
import { FilePicker } from 'react-file-picker'
import { ProgressBar } from 'react-bootstrap'
import {
  actionTypes,
  deleteFile,
  getCarouselPhotos,
  updateFile,
  uploadFile,
  uploadVideo,
} from 'src/actions/uploads.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import SweetAlert from 'sweetalert-react'
import { selectFilesForCarousel, selectVideos } from 'src/selectors/uploads.selector'
import 'react-image-gallery/styles/css/image-gallery.css'
import '../gallery/Gallery.scss'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from '../cards/Card'
import IconButton from '../buttons/IconButton'
import { faImage, faVideo } from '@fortawesome/free-solid-svg-icons'
import CustomTabs from '../navigation/Tabs/CustomTabs'

class CarouselGallery extends Component {
  state = {
    uploadProgress: 0,
    showDelete: false,
    showError: false,
    errorText: '',
    index: 0,
    uploading: false,
    progress: 0,
  }

  componentDidMount() {
    const { imageType, entityId, company } = this.props
    if (imageType && entityId && company) {
      this.props.getImages(imageType, entityId, company)
    }
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { imageType, entityId, company } = this.props
    if (entityId !== next.entityId || company !== next.company) {
      if (imageType && next.entityId && next.company) {
        this.props.getImages(imageType, next.entityId, next.company)
      }
    }
  }

  deleteAction = () => {
    const { index } = this.state
    const { images, onDelete, imageType, entityId, company } = this.props
    const image = images[index]
    const onSuccess = () => {
      this.setState({ deleted: image })
      if (onDelete) {
        onDelete(image, index)
      }
    }
    this.props.deleteFile(image.id, image.name, imageType, entityId, company, onSuccess)
  }

  uploadImage = file => {
    const { imageType, entityId, company, onUploadSuccess } = this.props

    const onProgress = progress => this.setState({ uploadProgress: progress })
    const onError = () => {
      this.setState({
        progress: 0,
        showError: true,
        errorText: 'Lo sentimos, no fué posible subir tu imagen en este momento',
      })
    }

    const onSuccess = (url, name) => {
      if (onUploadSuccess) {
        onUploadSuccess(url, name)
      }
      this.setState({ uploadProgress: 0 })
    }

    this.props.uploadFile(
      file,
      imageType,
      entityId,
      company,
      onProgress,
      onSuccess,
      onError,
    )
  }

  uploadVideo = file => {
    const { company } = this.props
    this.setState({ uploading: true })

    const onProgress = progress => this.setState({ progress })

    const onError = () => {
      this.setState({
        progress: 0,
        showErrorVideo: true,
        errorTextVideo: 'Lo sentimos, no fué posible subir tu video en este momento',
      })
    }

    const onSuccess = (url, randomName) => {
      this.setState({ uploading: false, uploadProgress: 0, urlVideo: url })
      this.props.updateFile({
        imageType: imageTypes.COMPANY,
        entityId: company,
        url,
        randomName,
        company: company,
      })
    }

    this.props.uploadVideo(file, onProgress, onSuccess, onError, imageTypes.COMPANY.path)
  }

  render() {
    const { errorText, showError, showDelete, uploading } = this.state
    const {
      imageType,
      images,
      showUploader,
      uploadIsLoading,
      imagesIsLoading,
      maxWidth,
      limit,
      videos,
    } = this.props
    return (
      <Card style={{ height: 450 }}>
        <Row style={{ height: '100%' }}>
          <Col sm={12} md={11}>
            {imageType ? (
              <CustomTabs
                items={[
                  {
                    title: 'Imágenes',
                    info: 'Imágenes relacionadas al productor',
                    component: (
                      <div
                        style={{
                          overflowX: 'scroll',
                          height: '100%',
                          display: 'flex',
                        }}>
                        {images && images.length > 0 ? (
                          images.map((i, index) => (
                            <Col sm={12} md={5} key={index}>
                              <div>
                                <div
                                  style={{
                                    width: 20,
                                    backgroundColor: 'rgba(237,70,45,0.8)',
                                    borderRadius: 5,
                                  }}>
                                  {showUploader && (
                                    <h6
                                      className={'gallery-delete'}
                                      onClick={() =>
                                        this.setState({
                                          showDelete: true,
                                          index: index,
                                        })
                                      }>
                                      X
                                    </h6>
                                  )}
                                </div>
                                <div />
                              </div>
                              <div>
                                <div className={'gallery-container'}>
                                  {!imagesIsLoading && !uploadIsLoading ? (
                                    <div
                                      style={{
                                        width: maxWidth,
                                        maxHeight: '100%',
                                      }}>
                                      <ImageGallery
                                        items={[i]}
                                        showPlayButton={false}
                                        showFullscreenButton={false}
                                        disableArrowKeys
                                        showThumbnails={false}
                                      />
                                    </div>
                                  ) : (
                                    <h4
                                      style={{
                                        textAlign: 'center',
                                        marginTop: 50,
                                      }}>
                                      Cargando...
                                    </h4>
                                  )}
                                </div>
                              </div>
                            </Col>
                          ))
                        ) : (
                          <h4 style={{ display: 'flex', alignItems: 'center' }}>
                            Sin imágenes para mostrar
                          </h4>
                        )}
                      </div>
                    ),
                  },
                  {
                    title: 'Videos',
                    info: 'Videos relacionados al productor',
                    component: (
                      <div style={{ maxHeight: 400, overflow: 'scroll' }}>
                        {videos.map((item, key) => (
                          <Row key={key}>
                            <video width="320" height="240" controls>
                              <source src={item.url + '#t=10'} type="video/mp4" />
                            </video>
                          </Row>
                        ))}
                      </div>
                    ),
                  },
                ]}
              />
            ) : (
              <div />
            )}
          </Col>
          <Col sm={12} md={1}>
            {showUploader && (limit ? limit > images.length : true) && (
              <div className={'kolo-newImage'}>
                <FilePicker
                  maxSize={5}
                  extensions={['jpeg', 'jpg', 'png']}
                  onChange={this.uploadImage}
                  onError={errMsg =>
                    this.setState({ showError: true, errorText: errMsg })
                  }>
                  <div
                    style={{
                      height: `${maxWidth || '100%'}`,
                      width: `${maxWidth || '100%'}`,
                    }}>
                    <IconButton
                      size={'3x'}
                      color={'white'}
                      icon={faImage}
                      spin={uploadIsLoading}
                      onClick={this.uploadImage}
                    />
                  </div>
                </FilePicker>
              </div>
            )}
            {showUploader && (limit ? limit > images.length : true) && (
              <div
                className={'kolo-newImage'}
                style={{ marginTop: 15, backgroundColor: 'blue' }}>
                <FilePicker
                  maxSize={250}
                  extensions={['mp4']}
                  onChange={this.uploadVideo}
                  onError={errMsg =>
                    this.setState({
                      showErrorVideo: true,
                      errorTextVideo: errMsg,
                    })
                  }>
                  <div>
                    {uploading ? (
                      <ProgressBar
                        variant="success"
                        style={{ marginTop: 5 }}
                        now={this.state.progress}
                      />
                    ) : (
                      <div
                        style={{
                          height: `${maxWidth || '100%'}`,
                          width: `${maxWidth || '100%'}`,
                        }}>
                        <IconButton icon={faVideo} size={'3x'} color={'white'} />
                      </div>
                    )}
                  </div>
                </FilePicker>
              </div>
            )}
          </Col>
        </Row>
        <SweetAlert
          show={showError}
          title={'¡Uh-Oh!'}
          text={errorText}
          type={'error'}
          onConfirm={() => this.setState({ showError: false })}
        />

        <SweetAlert
          show={showDelete}
          title={'Advertencia'}
          showCancelButton
          text={'¿Desea eliminar esta imagen?'}
          type={'warning'}
          onConfirm={() => {
            this.deleteAction()
            this.setState({ showDelete: false })
          }}
          onCancel={() => this.setState({ showDelete: false })}
        />

        <SweetAlert
          show={this.state.showErrorVideo}
          title="Error"
          text="Ocurrio un error al almacenar los datos"
          type={'warning'}
          onConfirm={() => {
            this.setState({ showErrorVideo: false })
          }}
        />
      </Card>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  images: ownProps.images
    ? ownProps.images
    : ownProps.entityId
    ? selectFilesForCarousel(state)
    : [],
  uploadIsLoading: loadingSelector([actionTypes.UPLOAD])(state),
  imagesIsLoading: loadingSelector([actionTypes.GET_FILES])(state),
  videos: selectVideos(state),
})

const mapDispatchToProps = dispatch => ({
  getImages: (imageType, entityId, company) =>
    dispatch(getCarouselPhotos(imageType, entityId, company)),
  uploadFile: (file, imageType, entityId, company, onProgress, onSuccess, onError) =>
    dispatch(
      uploadFile({
        file,
        imageType,
        entityId,
        company,
        onProgress,
        onSuccess,
        onError,
        carousel: true,
      }),
    ),
  deleteFile: (id, name, imageType, entityId, company, onSuccess) =>
    dispatch(
      deleteFile({
        id,
        name,
        imageType,
        entityId,
        company,
        onSuccess,
        carousel: true,
      }),
    ),
  uploadVideo: (file, onProgress, onSuccess, onError, path) =>
    dispatch(uploadVideo(file, onProgress, onSuccess, onError, path)),
  updateFile: data => dispatch(updateFile(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CarouselGallery)

CarouselGallery.defaultProps = {
  showThumbnails: true,
  showUploader: true,
  maxWidth: 'none',
}

export const imageTypes = {
  COMPANY: { id: 1, path: '/companies' },
  PRODUCT: { id: 2, path: '/products' },
}
