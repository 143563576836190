import React, { ReactElement } from 'react'

import { Modal, Row, Col } from 'react-bootstrap'
import Paragraph from 'src/components/Paragraph/Paragraph'
import Icon from 'src/components/buttons/IconButton'

import { formatDateFromMillis } from 'src/utils/formatters'

import { patrimonialAccounts, getPatrimonialDifference } from './PatrimonialFunctions'
import { toMoney } from 'src/utils/utilities'

interface Props {
  show: boolean
  log: IPatrimonialLog
  onHide: () => void
}

/**
 * `PatrimonialResume` is a React component that displays all accounts and the adjustments made to them.
 *
 * @param {Props} props - The properties that define the component's behavior and rendering.
 * @param {boolean} props.show - A boolean indicating whether the component should be displayed.
 * @param {IPatrimonialLog} props.log - The log object that contains the patrimonial values for each account.
 * @param {() => void} props.onHide - A function that will be called when the component needs to be hidden.
 *
 * @returns {ReactElement} The rendered component.
 */
const PatrimonialResume = ({ show, log, onHide }: Props): ReactElement => {
  return (
    <Modal show={show} size={'lg'} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{log?.resume}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'custom-modal-body'}>
        <Row>
          <Col xl={6} lg={6} md={6} sm={4} xs={4}>
            <div className={'column'}>
              <Paragraph bold>Fecha</Paragraph>
              <Paragraph>{formatDateFromMillis(log?.date)}</Paragraph>
            </div>
          </Col>
          <Col xl={6} lg={6} md={6} sm={8} xs={8}>
            <div className={'column'}>
              <Paragraph bold>Responsable</Paragraph>
              <Paragraph>{log?.userName}</Paragraph>
            </div>
          </Col>
          <Col xl={12}>
            <hr />
          </Col>
          {log &&
            patrimonialAccounts.map(account => {
              const { value, prevValue, difference, color, icon } =
                getPatrimonialDifference(log, account.param, account.prevParam)

              return (
                <Col key={account.name} xl={12}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                      <Paragraph className={'center'} size={'extraBig'}>
                        {toMoney(prevValue)}
                      </Paragraph>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12} className={'column center'}>
                      <Paragraph size={'extraBig'}>{account.name}</Paragraph>
                      {difference !== 0 && (
                        <div className={'d-flex center'}>
                          <Paragraph size={'big'}>({toMoney(difference)})</Paragraph>
                          <Icon
                            style={{ marginLeft: 10 }}
                            size={'2x'}
                            icon={icon}
                            color={color}
                            tooltip={toMoney(difference)}
                          />
                        </div>
                      )}
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                      <Paragraph
                        className={'center'}
                        size={'extraBig'}
                        style={{ color: color }}>
                        {toMoney(value)}
                      </Paragraph>
                    </Col>
                    <Col xl={12}>
                      <hr />
                    </Col>
                  </Row>
                </Col>
              )
            })}
        </Row>
      </Modal.Body>
    </Modal>
  )
}
export default PatrimonialResume
