const itemTypes = [
  { posType: 2, label: 'Media hora', value: 6 },
  { posType: 2, label: 'Hora', value: 1 },
  { posType: 2, label: 'Día', value: 2 },
  { posType: 2, label: 'Semana', value: 3 },
  { posType: 2, label: 'Mes', value: 4 },
  { posType: 2, label: 'Multa por pérdida de ticket', value: 5 },
]

export default itemTypes
