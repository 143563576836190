import React, { useState } from 'react'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import './Card.scss'

import { Button, Icon, Paragraph } from '..'

import {
  formatShortDateFromMillis,
  formatDurationFromSeconds,
} from 'src/utils/formatters'
import { history } from '../../App'

import placeholderImage from 'src/assets/images/newDesign/placeholder.svg'

const AppCard = ({ id, name, url, updatedAt, duration }) => {
  const [isCopied, setIsCopied] = useState(false)

  const handleCopy = () => {
    let element = document.createElement('input')
    element.setAttribute('value', `${window.location.origin}/manuales/${id}`)
    document.body.appendChild(element)
    element.select()
    document.execCommand('copy')
    document.body.removeChild(element)
    setIsCopied(true)

    setTimeout(() => {
      setIsCopied(false)
    }, 2000)
  }

  return (
    <div className="appcard">
      <div
        className="appcard-header"
        style={{
          backgroundImage: `url(${url || placeholderImage})`,
          backgroundColor: '#bbf',
        }}
      >
        <div className="appcard-header-title">
          <h6>{name}</h6>
        </div>
        <div className="appcard-header-link">
          <Icon
            icon={faLink}
            color="white"
            tooltip={isCopied ? 'Link copiado al portapapeles' : 'Copiar link'}
            onClick={handleCopy}
          />
        </div>
      </div>
      <div className="appcard-body">
        <div className="space-between">
          <Paragraph>Última actualización</Paragraph>
          <Paragraph style={{ textAlign: 'end' }}>
            {formatShortDateFromMillis(updatedAt)}
          </Paragraph>
        </div>
        <div className="space-between">
          <Paragraph>Duración</Paragraph>
          <Paragraph style={{ textAlign: 'end' }}>
            {formatDurationFromSeconds(duration)}
          </Paragraph>
        </div>
        <Button onClick={() => history.push(`/manuales/${id}`)}>Ver videos</Button>
      </div>
    </div>
  )
}

export default AppCard
