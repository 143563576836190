import { Country } from 'src/enums/countries'
import { useSelector } from 'react-redux'
import { selectCompanyCountry } from 'src/selectors/company.selector'

interface IDisabledDays {
  after?: Date
  before?: Date
}

const addDays = (date: Date, days: number): Date => {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

const useDisabledDays = (useFEL: boolean): IDisabledDays => {
  const country = useSelector(selectCompanyCountry)

  const today: Date = new Date()

  if (!useFEL || country?.id !== Country.GT) return {}
  else return { before: addDays(today, -5), after: addDays(today, 5) }
}
export default useDisabledDays
