import {
  faExclamationCircle,
  faMoneyBill,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getPaymentMethods } from 'src/actions/utilities.actions'
import CustomTabsV2 from 'src/components/navigation/Tabs/CustomTabsV2'
import Icon from 'src/components/buttons/IconButton'
import NumberField from 'src/components/NumberField/NumberField'
import Paragraph from 'src/components/Paragraph/Paragraph'
import Select from 'src/components/inputs/Select/CustomSelect'
import Button from 'src/components/buttons/Button'
import { default as TableV2 } from 'src/components/Pagination/PaginatedTableV2'
import { isAllowed } from 'src/selectors/modules.selector'
import { selectMethodPayment } from 'src/selectors/utilities.selector'
import { toMoney } from 'src/utils/utilities'
import { PaymentType } from 'src/enums/paymentTypes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BankDetails } from 'src/content/Orders/OrderCreate/BankDetails'
import { orderPermissions } from 'src/enums/permissions'
import { CustomDate } from '../../index'

interface IProps {
  modal?: boolean
  show?: boolean
  onHide?: () => void
  save: (payments: IMultiplePayment[]) => void
  maxAmount: number
  paymentsOrder?: IMultiplePayment[]
  setPaymentDate?: (date: Date) => void
}

/**
 * Modal to create multiple payments
 * @param {boolean} show
 * @param {Function} onHide
 * @param {Function} onAdd
 * @param {number} maxAmount
 * @param {IMultiplePayment[]} paymentsOrder
 * @returns
 */
export const MultiplePayments = ({
  modal = false,
  show,
  onHide,
  setPaymentDate,
  save,
  maxAmount = 0,
  paymentsOrder = [],
}: IProps) => {
  const dispatch = useDispatch()

  const [payment, setPayment] = useState({
    paymentType: 1,
    amount: 0,
  })

  const [payments, setPayments] = useState<IMultiplePayment[]>(paymentsOrder)

  const paymentMethodPermissions = useSelector(state => [
    {
      id: PaymentType.CASH,
      enabled: isAllowed(state, [orderPermissions.createOrderCash]),
    },
    {
      id: PaymentType.CREDIT,
      enabled: isAllowed(state, [orderPermissions.createOrderCredit]),
    },
    {
      id: PaymentType.CARD,
      enabled: isAllowed(state, [orderPermissions.createOrderCard]),
    },
    {
      id: PaymentType.BANK_TRANSFER,
      enabled: isAllowed(state, [orderPermissions.createOrderTransfer]),
    },
  ])
  const paymentMethods = useSelector(selectMethodPayment).filter(
    payment =>
      (payment.link &&
        paymentMethodPermissions.every(permission => permission.id !== payment.id)) ||
      paymentMethodPermissions.find(permission => permission.id === payment.id)?.enabled,
  )

  const summative: number = payments?.reduce((a, b) => a + b.amount, 0) || 0

  useEffect(() => {
    if (!paymentMethods || paymentMethods.length <= 0) dispatch(getPaymentMethods())
  }, [])

  useEffect(() => {
    if (paymentMethods.length > 0 && !modal && payments.length <= 0)
      setPayments(
        paymentMethods.map(method => ({
          name: method.name,
          paymentType: method.id,
          amount: 0,
        })),
      )
  }, [paymentMethods])

  const renderCreditDateSelector = index => {
    return (
      <CustomDate
        onDayChange={date => {
          setPaymentDate(date)
          payments[index].paymentDate = date.valueOf()
          setPayments([...payments])
        }}
        label={'Fecha de pago'}
        required
        disabledDays={{ before: new Date() }}
      />
    )
  }

  const addPayment = () => {
    return (
      <div
        data-testid={'add-payments'}
        style={
          summative >= maxAmount
            ? {
                pointerEvents: 'none',
                opacity: 0.5,
              }
            : {}
        }>
        <Row>
          <Col xl={6} md={6} xs={6}>
            <Select
              mt={0}
              required
              label={'Método de pago'}
              value={paymentMethods.find(method => method.id === payment.paymentType)}
              placeholder={'Seleccione un método'}
              options={paymentMethods.filter(method => method.link && method.id !== 2)}
              onChange={type => setPayment({ ...payment, paymentType: type.value })}
            />
          </Col>
          <Col xl={6} md={6} xs={6}>
            <NumberField
              data-testid="quantity"
              label={'Cantidad'}
              value={payment.amount}
              placeholder={(maxAmount - summative).toFixed(2)}
              onValueChange={value => setPayment({ ...payment, amount: value })}
              max={Number((maxAmount - summative).toFixed(2))}
              decimals={2}
            />
          </Col>
        </Row>
        <Row className={'container-buttons'}>
          <Button
            dataCy={'add'}
            color={'accent'}
            disabled={!payment.paymentType || payment.amount <= 0}
            onClick={() => {
              setPayments([...payments, payment])
              setPayment({ ...payment, amount: 0 })
            }}>
            Agregar
          </Button>
        </Row>
      </div>
    )
  }

  return (
    <>
      {!modal && (
        <Col>
          <Row>
            {payments.map((payment, index) => (
              <Col key={index} xl={12} className={'mt-2'}>
                <div className={'space-between'}>
                  <Paragraph size={'big'}>{payment.name}</Paragraph>
                  <NumberField
                    data-testid={'quantity-' + payment.name}
                    placeholder={(maxAmount - summative).toFixed(2)}
                    disabled={summative >= maxAmount && payment.amount === 0}
                    value={payment.amount}
                    onValueChange={value => {
                      payments[index].amount = value

                      setPayments([...payments])

                      save(payments)
                    }}
                    decimals={2}
                    max={
                      maxAmount - summative === 0
                        ? undefined
                        : Number((maxAmount - summative + payment.amount).toFixed(2))
                    }
                  />
                </div>

                {payment.paymentType === PaymentType.CREDIT &&
                  renderCreditDateSelector(index)}

                {payment.paymentType === PaymentType.BANK_TRANSFER && (
                  <BankDetails
                    onUpdate={transferDetail => {
                      payments[index].transferDetail = transferDetail
                      setPayments([...payments])
                      save(payments)
                    }}
                  />
                )}
              </Col>
            ))}
          </Row>
          <hr />
          <div className={'space-between'}>
            <Paragraph size={'big'}>Cobros</Paragraph>
            <Paragraph size={'medium'} data-testid="payments">
              {toMoney(summative)}
            </Paragraph>
          </div>
          <div className={'space-between'}>
            <Paragraph size={'big'}>Total</Paragraph>
            <Paragraph size={'medium'} data-testid="total">
              {toMoney(maxAmount)}
            </Paragraph>
          </div>
          {summative < maxAmount && (
            <div
              className="center input-error"
              style={{ marginTop: 15 }}
              data-testid="alert">
              {/* @ts-expect-error js */}
              <FontAwesomeIcon icon={faExclamationCircle} />
              El total de cobros debe ser igual a la totalidad de la orden.
            </div>
          )}
        </Col>
      )}
      <Modal show={show} centered onHide={() => onHide()}>
        <Modal.Header closeButton>
          <Modal.Title>Pagos mixtos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <Paragraph>
                El monto restante de la totalidad de la orden se asignará como cuenta por
                cobrar
              </Paragraph>
            </Col>
            <Col xl={12} className={'mt-3'}>
              {/* @ts-expect-error JS */}
              <CustomTabsV2
                options={[
                  {
                    label: 'Pagos',
                    icon: faMoneyBill,
                    render: () => addPayment(),
                  },
                ]}
              />
            </Col>
            <Col xl={12}>
              <TableV2
                // @ts-expect-error JS
                hideFilter
                tableDivStyle={{
                  overflowX: 'auto',
                  minHeight: 300,
                }}
                hideEmpty
                items={payments}
                pSize={0}
                headers={[
                  {
                    label: 'Método de pago',
                    show: true,
                    custom: item =>
                      paymentMethods.find(f => f.id === item.paymentType)?.name,
                  },
                  {
                    label: 'Monto',
                    show: true,
                    type: 'currency',
                    value: ['amount'],
                  },
                  {
                    show: true,
                    config: true,
                    label: '',
                    custom: item => (
                      <Icon
                        icon={faTrash}
                        color={'danger'}
                        onClick={() =>
                          setPayments(payments.filter(payment => payment !== item))
                        }
                        tooltip={'Eliminar'}
                      />
                    ),
                  },
                ]}
              />
              <Row>
                <Col xl={12}>
                  <div style={{ width: '100%' }}>
                    <hr />
                    <div className={'space-between'}>
                      <Paragraph size={'big'}>Total</Paragraph>
                      <Paragraph size={'medium'} data-testid="total">
                        {toMoney(maxAmount)}
                      </Paragraph>
                    </div>
                    <div className={'space-between'}>
                      <Paragraph size={'big'}>Pagos</Paragraph>
                      <Paragraph size={'medium'} data-testid="payments">
                        {toMoney(summative)}
                      </Paragraph>
                    </div>
                    <div>
                      <hr />
                    </div>
                    <div className={'space-between'}>
                      <Paragraph size={'big'}>Crédito</Paragraph>
                      <Paragraph size={'medium'} data-testid="credit">
                        {toMoney(maxAmount - summative)}
                      </Paragraph>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              dataCy={'close'}
              color={'secondary'}
              onClick={() => {
                onHide()
              }}>
              Cerrar
            </Button>
            <Button
              dataCy={'save'}
              onClick={() => {
                save(payments)
              }}>
              Guardar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  )
}
