import React, { Component } from 'react'
import { connect } from 'react-redux'

import Card from '../../../components/cards/Card'
import IconButton from '../../../components/buttons/IconButton'

import { Td, Tr } from 'react-super-responsive-table'
import { Col, Row } from 'react-bootstrap'
import { faFileAlt as faPencilAlt } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes as types,
  getCompaniesByType,
} from '../../../actions/company.actions'
import { selectCompaniesByType } from '../../../selectors/company.selector'

import { loadingSelector } from '../../../selectors/loading.selector'
import { Link } from 'react-router-relative-link'
import CollapsibleCard from '../../../components/cards/collapsible-card/CollapsibleCard'
import { Action, TableV2, Title } from '../../../components'

class Producers extends Component {
  state = {
    contract: true,
    noContract: false,
  }

  componentDidMount() {
    this.props.getProducers()
  }

  filter = item => {
    const { contract, noContract } = this.state
    return (contract && item.hasContract) || (noContract && !item.hasContract)
  }

  goTo = route => {
    const { match } = this.props
    this.props.history.push(match.url + route)
  }

  renderRow = (item, index) => {
    return (
      <Tr className={'data'} key={index}>
        <Td className={'mini'}>{item.nit || 'C/F'}</Td>
        <Td className={'mini'}>{item.name}</Td>
        <Td className={'mini'}>{item.email || 'N/A'}</Td>
        <Td className={'short'}>{item.address}</Td>
        <Td className={'short center'}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
            }}
          >
            <IconButton
              tooltip={'Ver'}
              icon={faPencilAlt}
              onClick={() => this.goTo(`/${item.id}`)}
            />
          </div>
        </Td>
      </Tr>
    )
  }

  handleCheckBoxChange = event => {
    const { name, checked } = event.target
    this.setState({ [name]: checked })
  }

  render() {
    const { producers, loading } = this.props
    const { contract, noContract } = this.state

    return (
      <div style={{ paddingBottom: 50 }}>
        <Title
          title={'Productores'}
          action
          to={'ofertas'}
          actionTitle={'Ofertas de transporte'}
        />
        <Row>
          <Col md={4}>
            <Row>
              <Col md={12}>
                <CollapsibleCard title={'Filtros'} startCollapsed={true}>
                  <h6>Por contratos</h6>
                  <Row>
                    <Col md={6}>
                      <label className={'checkbox-label'}>
                        <input
                          type={'checkbox'}
                          name={'contract'}
                          checked={contract}
                          onChange={this.handleCheckBoxChange}
                        />
                        Con contrato
                      </label>
                    </Col>
                    <Col md={6}>
                      <label className={'checkbox-label'}>
                        <input
                          type={'checkbox'}
                          name={'noContract'}
                          checked={noContract}
                          onChange={this.handleCheckBoxChange}
                        />
                        Sin contrato
                      </label>
                    </Col>
                  </Row>
                </CollapsibleCard>
              </Col>

              <Col md={12}>
                <Link className={'big-button'} to={'ofertas'}>
                  Ofertas de transporte
                </Link>
              </Col>
            </Row>
          </Col>
          <Col>
            <Action to={'ofertas'} action actionTitle={'Ofertas de transporte'} />
            <Card title={'Productores'}>
              <TableV2
                headers={[
                  {
                    label: 'NIT',
                    show: true,
                    value: ['nitC'],
                    type: 'text',
                    className: 'mini',
                    custom: item => (item.nitC = item.nit || 'C/F'),
                  },
                  {
                    label: 'Nombre',
                    show: true,
                    value: ['name'],
                    type: 'text',
                    className: 'mini',
                  },
                  {
                    label: 'Email',
                    show: true,
                    value: ['email'],
                    type: 'text',
                    className: 'mini',
                  },
                  {
                    label: 'Dirección',
                    show: true,
                    value: ['address'],
                    type: 'text',
                    className: 'short',
                  },
                  {
                    config: true,
                    show: true,
                    label: '',
                    className: 'short center',
                    custom: item => (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-evenly',
                        }}
                      >
                        <IconButton
                          tooltip={'Ver'}
                          icon={faPencilAlt}
                          onClick={() => this.goTo(`/${item.id}`)}
                        />
                      </div>
                    ),
                  },
                ]}
                mobileAuto
                storageKey={`producers`}
                items={producers.filter(this.filter)}
                loading={loading}
              />
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  producers: selectCompaniesByType(state),
  loading: loadingSelector([types.GET_COMPANIES_BY_TYPE])(state),
})

const mapDispatchToProps = dispatch => ({
  getProducers: () => dispatch(getCompaniesByType(true, false, false, 10)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Producers)
