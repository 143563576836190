import '../../Permissions/UpdatePermissions/UpdatePermissions.scss'
import './ProductsByPosition.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Card from 'src/components/cards/Card'
import ECommerceCard from 'src/components/cards/e-commerce-card/ECommerceCard'
import TextField from 'src/components/inputs/FormTextField/FormTextField'
import ImageCarousels from 'src/components/ImageCarousels/ImageCarousels'

import { formatNumberWithCommas } from 'src/utils/formatters'

import {
  Row,
  Col,
  Modal,
  Container,
  InputGroup,
  Button,
  FormControl,
  Tab,
  Badge,
  Accordion,
} from 'react-bootstrap'
import { Facebook as BulletList } from 'react-content-loader'
import Alert from 'sweetalert-react'

import {
  actionTypes as typesP,
  getProductsByCompanyNS,
} from 'src/actions/products.actions'
import { selectProductsByCompanyNS } from 'src/selectors/products.selector'

import { actionTypes as typesC, getCompanyNS } from 'src/actions/company.actions'
import { selectCompanyNS } from 'src/selectors/company.selector'

import { addItem } from 'src/actions/shopping.actions'
import { selectShopping } from 'src/selectors/shopping.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  hasErrorsSelector,
  singleErrorSelector,
  handlerError,
} from 'src/selectors/error.selector'
import CollapsibleCard from 'src/components/cards/collapsible-card/CollapsibleCard'
import moment from 'moment'

import IconSearch from 'src/assets/images/newDesign/icon-search.svg'
import IconMinus from 'src/assets/images/newDesign/icon-minus.svg'
import IconPlus from 'src/assets/images/newDesign/icon-plus.svg'
import IconCart from 'src/assets/images/newDesign/icon-cart.svg'
import ArrowDown from 'src/assets/images/newDesign/arrow-down.svg'
import IconPhone from 'src/assets/images/newDesign/icon-phone.svg'
import IconBack from 'src/assets/images/newDesign/arrow-left-black.svg'
import LogoCotizap from 'src/assets/images/newDesign/logo-cotizap.svg'

import PlaceholderProduct from 'src/assets/images/newDesign/placeholder-product.svg'
import Money from 'src/components/Money/Money'

class ProductsByCompany extends Component {
  state = {
    search: '',
  }

  componentDidMount() {
    const { getProducts, getCompany } = this.props
    const companyId = window.location.pathname.substring(
      window.location.pathname.lastIndexOf('/') + 1,
    )
    getCompany(companyId)
    getProducts(companyId)
  }

  goTo = (item, route) => {
    const { history, match } = this.props
    const url = `${match.url}${route}/${item.productId}`
    history.push(url, { product: item })
  }

  render() {
    const { loadingP, products, company } = this.props
    const { search } = this.state
    return (
      <div className="content">
        <div className={'button-back'}>
          <img className={'icon-back'} src={IconBack} alt={'Back'} />
        </div>

        <label className={'title title-back'}>Perfil</label>

        <div className={'button-cart'}>
          <img className={'icon-cart'} src={IconCart} />
          <Badge className={'badge'} pill variant="danger">
            1
          </Badge>
        </div>

        <Row
          style={{
            position: 'absolute',
            top: 200,
            bottom: 67,
            left: 35,
            right: 0,
            margin: 0,
            padding: 0,
          }}
        >
          <Col xl={3} lg={4} md={5} className={'no-margins'}>
            <div className={'company-profile'}>
              <Row className={'no-margins center'}>
                <img
                  className={'product-image'}
                  src={
                    'https://images.pexels.com/photos/209251/pexels-photo-209251.jpeg?cs=srgb&dl=acero-adentro-almacen-camion-209251.jpg&fm=jpg'
                  }
                  alt=""
                />
              </Row>
              <Row className={'no-margins center'}>
                <label className={'product-title'} style={{ marginTop: 35 }}>
                  {company.name}
                </label>
              </Row>
              <Row className={'no-margins center'}>
                <label className={'company-category'}>Productor - Categoría</label>
              </Row>
              <Row className={'no-margins center'}>
                <label className={'company-address'}>{company.address}</label>
              </Row>
              <Row className={'no-margins center'}>
                <label className={'company-description'}>
                  {company.description ||
                    '“Una empresa dedicada a brindar productos 100% de calidad.”'}
                </label>
              </Row>
              <button
                className={'button call'}
                style={{ marginTop: 15, marginBottom: 40 }}
                disabled={!company.phone}
              >
                <img className={'icon-call'} src={IconPhone} />
                {company.phone || ''}
              </button>
            </div>
          </Col>
          <Col style={{ margin: 0, padding: 0, position: 'relative' }}>
            <Row
              style={{
                position: 'relative',
                marginTop: 4,
                height: 60,
                marginLeft: 25,
                marginRight: 50,
                marginBottom: 0,
                padding: 0,
              }}
            >
              <input
                className={'input-search'}
                id="search"
                name="search"
                placeholder="Buscar por nombre / Descripción"
                value={search}
                onChange={e => this.setState({ search: e.target.value })}
              />
              <button className={'button-search'}>
                <img className={'icon'} src={IconSearch} />
              </button>
            </Row>
            <Row className={'products-list'}>
              {products.map((item, index) => {
                // let url = "https://images.unsplash.com/photo-1506976785307-8732e854ad03?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2141&q=80";
                //
                // if (index%2 === 0)
                //     url = "https://images.unsplash.com/photo-1498654077810-12c21d4d6dc3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3450&q=80";
                //
                //
                // // let url = "https://images.pexels.com/photos/3255761/pexels-photo-3255761.jpeg?cs=srgb&dl=resfriado-frio-arte-modelo-3255761.jpg&fm=jpg"

                let url = null

                if (item.images.length > 0) {
                  url = item.images[0].url
                } else {
                  url = PlaceholderProduct
                }

                const product = item.productData
                return (
                  <Col xl={4} lg={6} md={12} sm={12} style={{ margin: 0, padding: 25 }}>
                    <div className={'product-quantity-card'}>
                      <Row className={'no-margins center'}>
                        <img
                          className={'product-image'}
                          src={url}
                          alt=""
                          onClick={() => this.goTo(item, '/detail')}
                        />
                      </Row>

                      {index === 0 && (
                        <div className={'product-new'}>
                          <label style={{ marginTop: 4 }}>NUEVO</label>
                        </div>
                      )}

                      <Row className={'no-margins center'}>
                        <label className={'product-title'} style={{ marginTop: 20 }}>
                          {product.name}
                        </label>
                      </Row>

                      <Row className={'no-margins center'}>
                        <label className={'product-category'} style={{ marginTop: 5 }}>
                          Productor - Categoría
                        </label>
                      </Row>

                      <Row className={'no-margins center'}>
                        <Money
                          component="label"
                          className={'product-price'}
                          style={{ marginTop: 10, marginBottom: 10 }}
                        >
                          {product.price}
                        </Money>
                      </Row>

                      <div className={'divider'} />

                      <Row className={'no-margins center'} style={{ marginTop: 10 }}>
                        <div className={'button-quantity'}>
                          <img className={'icon-quantity'} src={IconMinus} />
                        </div>
                        <div className={'product-quantity'}>2</div>
                        <div className={'button-quantity'}>
                          <img className={'icon-quantity'} src={IconPlus} />
                        </div>
                      </Row>

                      <button
                        className={'button'}
                        style={{ marginTop: 15, marginBottom: 40 }}
                      >
                        Agregar a carrito
                      </button>
                    </div>
                  </Col>
                )
              })}
            </Row>
          </Col>
        </Row>

        <div className={'footer'}>
          <label className={'footer-text'}>Cotizap © 2020</label>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  products: selectProductsByCompanyNS(state),
  loadingP: loadingSelector([typesP.GET_PRODUCTS_BY_COMPANY_NS])(state),
  hasErrorP: hasErrorsSelector([typesP.GET_PRODUCTS_BY_COMPANY_NS])(state),
  errorP: singleErrorSelector([typesP.GET_PRODUCTS_BY_COMPANY_NS])(state),

  company: selectCompanyNS(state),
  loadingC: loadingSelector([typesC.GET_COMPANY_NS])(state),
  hasErrorC: hasErrorsSelector([typesC.GET_COMPANY_NS])(state),
  errorC: singleErrorSelector([typesC.GET_COMPANY_NS])(state),

  shopping: selectShopping(state),
  handlerError: message => handlerError(message),
})
const mapDispatchToProps = dispatch => ({
  getProducts: id => dispatch(getProductsByCompanyNS(id)),
  getCompany: id => dispatch(getCompanyNS(id)),
  addItem: (item, items) => dispatch(addItem(item, items)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ProductsByCompany)
