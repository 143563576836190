import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import Card from 'src/components/cards/Card'
import FormTextField from 'src/components/inputs/FormTextField/FormTextField'
import CashBox from 'src/assets/images/svg/cashbox.svg'
import './RequestDocuments.scss'
import Button from 'src/components/buttons/Button'
import {
  actionTypes,
  downloadRequestDocuments,
  getDocumentRequest,
  markDocumentRequestAsDone,
} from 'src/actions/resolutions.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import SweetAlert from 'sweetalert-react'
import { selectDocumentRequest } from 'src/selectors/resolutions.selector'
import { formatDateFromMillis, formatHourFromMillis } from 'src/utils/formatters'

class CreateDocumentRequest extends Component {
  state = {
    showError: false,
    showSuccess: false,
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { createIsLoading, createHasError } = this.props

    if (createIsLoading && !next.createIsLoading) {
      if (!createHasError && next.createHasError) {
        this.setState({ showError: true, showSuccess: false })
      } else {
        this.setState({ showError: false, showSuccess: true })
      }
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params
    this.props.getDocumentRequest(id)
  }

  render() {
    const { showError, showSuccess } = this.state
    const { document, cancelIsLoading } = this.props

    const done = document && document.done
    const cancelled = document && document.cancelled
    return (
      <div>
        <h1 className={'dashboard-welcome'}>Solicitud de documentos</h1>
        <Row>
          <Col md={4}>
            <Card
              ribbon={cancelled ? 'Cancelada' : done ? 'Terminada' : 'En Proceso'}
              ribbonColor={cancelled ? 'red' : 'green'}
            >
              <img src={CashBox} alt="CashBox Icon" className={'request-cashBox-image'} />
              <div style={{ textAlign: 'center', marginTop: 25 }}>
                <span className={'cashBox-name'}>Habilitar maquina registradora</span>
                <br />
                <span className={'cashBox-name'}>
                  {document && document.companyData.name}
                </span>
              </div>
              <div className={'document-request-instructions justify'}>
                Llene este formulario para solicitar la documentación necesaria para
                habilitar la impresión de facturas por medio de la plataforma de Cotizap.
              </div>
            </Card>
          </Col>
          <Col>
            <Card title={'Documentación para maquina registradora'}>
              <div className={'document-request-instructions'}>
                Ingrese un dato de contacto, esta persona deberá estar disponible para
                atender las dudas que la SAT tenga sobre ustedes.
                <br />
                Los valores serán impresos tal cual sean ingresados en este formulario.
              </div>
              <Row>
                <Col>
                  <FormTextField
                    label={'Nombre de Contacto'}
                    type={'text'}
                    id={'contactName'}
                    placeholder={'Nombre'}
                    name={'contactName'}
                    value={document && document.contactName}
                    disabled
                  />
                </Col>
                <Col>
                  <FormTextField
                    label={'Teléfono de Contacto'}
                    type={'text'}
                    id={'contactPhone'}
                    placeholder={'1234-1234'}
                    name={'contactPhone'}
                    value={document && document.contactPhone}
                    disabled
                  />
                </Col>
                <Col>
                  <FormTextField
                    label={'Email de Contacto'}
                    type={'email'}
                    id={'contactEmail'}
                    placeholder={'abc@email.com'}
                    name={'contactEmail'}
                    value={document && document.contactEmail}
                    disabled
                  />
                </Col>
              </Row>

              <div className={'document-request-instructions'}>
                Ingrese los datos de la persona que firmará los documentos generados que
                serán presentados ante la SAT.
                <br />
                Los valores serán impresos tal cual sean ingresados en este formulario.
              </div>
              <Row>
                <Col>
                  <FormTextField
                    label={'Nombre'}
                    type={'text'}
                    id={'signInName'}
                    placeholder={'Nombre'}
                    name={'signInName'}
                    value={document && document.signInName}
                    disabled
                  />
                </Col>
                <Col>
                  <FormTextField
                    label={'Teléfono'}
                    type={'text'}
                    id={'signInPhone'}
                    placeholder={'1234-1234'}
                    name={'signInPhone'}
                    value={document && document.signInPhone}
                    disabled
                  />
                </Col>
                <Col>
                  <FormTextField
                    label={'Puesto'}
                    type={'email'}
                    id={'signInJob'}
                    placeholder={'Ej. Representante Legal'}
                    name={'signInJob'}
                    value={document && document.signInJob}
                    disabled
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  {document && (
                    <div className={'document-request-instructions'}>
                      Solicitud enviada el: {formatDateFromMillis(document.createdAt)} a
                      las {formatHourFromMillis(document.createdAt)}
                    </div>
                  )}
                </Col>
                <Col>
                  {document && !document.cancelled && (
                    <div style={{ textAlign: 'right' }}>
                      <Button
                        variant={'success'}
                        loading={cancelIsLoading}
                        onClick={() => this.props.downloadDocuments(document.id)}
                      >
                        Descargar Documentos
                      </Button>
                      {!document.done && (
                        <Button
                          variant={'success'}
                          loading={cancelIsLoading}
                          onClick={() => this.props.markAsDone(document.id)}
                        >
                          Terminar
                        </Button>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <SweetAlert
          show={showSuccess}
          title={'!Exito!'}
          text={'Solicitud enviada exitosamente.'}
          type={'success'}
          onConfirm={this.closeSuccess}
        />
        <SweetAlert
          show={showError}
          title={'¡Uh-Oh!'}
          text={'Error, por favor revise e intente de nuevo más tarde.'}
          type={'error'}
          onConfirm={() => this.setState({ showError: false })}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  document: selectDocumentRequest(state),
  cancelIsLoading: loadingSelector([actionTypes.CANCEL_DOCUMENT_REQUEST])(state),
})

const mapDispatchToProps = dispatch => ({
  getDocumentRequest: id => dispatch(getDocumentRequest(id)),
  markAsDone: id => dispatch(markDocumentRequestAsDone(id)),
  downloadDocuments: id => dispatch(downloadRequestDocuments(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateDocumentRequest)
