import { selectCurrentUser } from './user.selector'
import { getFormatProducts } from './products.selector'
import { getUniversalItem } from 'src/utils/getUniversalItem'

export const selectAllWarehouses = state => state.warehouse.allWarehouses
export const selectTotalWarehouses = state => state.warehouse.total
export const selectUserWarehouses = state =>
  state.warehouse.userWarehouses.map(item => ({
    value: item.id,
    label: item.name,
    ...item,
  }))
export const selectOwnWarehouses = state =>
  state.warehouse.ownWarehouses.map(item => ({
    value: item.id,
    label: item.name,
    ...item,
  }))
export const selectExternalActiveWarehouses = state =>
  state.warehouse.externalActiveWarehouses
export const selectAllWarehousesTypes = state => state.warehouse.warehouseTypes
export const selectSingleWarehouse = state => state.warehouse.warehouse
export const selectUpdateWarehouse = state => state.warehouse.editedWarehouse
export const selectCreateWarehouse = state => state.warehouse.newWarehouse
export const selectDeleteWarehouse = state => state.warehouse.deletedWarehouse
export const selectActiveWarehouse = state => state.warehouse.activeWarehouse
export const selectGetWarehouseExistence = state => state.warehouse.warehouseExistence
export const selectProcessedOrders = state => state.warehouse.processed
export const selectWarehouseOrders = state => state.warehouse.orders

export const selectAllWarehousesTypesForSelect = state =>
  state.warehouse.warehouseTypes.map(item => ({
    value: item.id,
    label: item.name,
  }))
export const selectAllWarehousesForSelect = state =>
  state.warehouse.allWarehouses.map(item => ({
    value: item.id,
    label: item.name,
    ...item,
  }))
export const selectAllWarehousesUserForSelect = state =>
  state.warehouse.userWarehouses.map(item => ({
    value: item.id,
    label: item.name,
    ...item,
  }))
export const selectAllProductsForSelect = state =>
  state.warehouse.products.map(item => ({
    value: item.productId,
    label: item.productCode + ' - ' + item.productName,
  }))
export const selectAllTransfers = state => state.warehouse.transfers
export const selectTransferDetail = state => state.warehouse.transfer
export const selectTotalTransfer = state => state.warehouse.totalTransfers

export const selectCategorizations = state => state.warehouse.categorizations

export const selectInventoryDetail = state => state.warehouse.inventoryDetail
export const selectInventoryDetails = state => state.warehouse.inventoryDetails
export const selectOrigin = state => state.warehouse.origin

export const selectProductReport = state => state.warehouse.reportTotal
export const selectTotal = state => getFormatProductsT(state.warehouse.reportTotal)

const formatListProducts = list => {
  return list.map(p => {
    if (p.product) {
      if (!p.product.base_product)
        p.product.base_product = p.product.baseProductId || p.product.id
      if (!p.product.subProductValue || p.product.subProductValue <= 0)
        p.product.subProductValue = 1
      if (!p.product.transformationFactor || p.product.transformationFactor <= 0)
        p.product.transformationFactor = 1
    }
    if (!p.total) p.total = 0
    if (!p.discount) p.discount = 0
    if (!p.quantity) p.quantity = 0
    return p
  })
}

export const getFormatProductsT = list => {
  list = formatListProducts(list)
  const productsWithTotalReport = []
  list.forEach(p => {
    if (p.product) {
      if (!list.find(l => l.productId === p.base_product))
        productsWithTotalReport.push({
          total: p.total,
          quantity: p.quantity,
          discount: p.discount,
          productLine: [p],
        })
      if (p.product.base_product === p.product.id) {
        let total = 0,
          discount = 0,
          quantity = 0

        const pl = list
          .filter(f => f.product.base_product === p.product.id)
          .map(f => {
            total += f.total
            discount += f.discount
            quantity += f.quantity * f.product.transformationFactor
            return f
          })
          .sort((a, b) => b.product.transformationFactor - a.product.transformationFactor)

        productsWithTotalReport.push({
          total,
          quantity,
          discount,
          productLine: pl,
        })
      }
    }
  })
  const products = []

  productsWithTotalReport.forEach(p => {
    let newTotal = p.total
    let newDiscount = p.discount
    let newQuantity = p.quantity
    p.productLine.forEach((pl, i) => {
      if (newTotal > 0) {
        let truncTotal = 0,
          truncQuantity = 0,
          truncDiscount = 0
        if (i === 0) {
          newQuantity /= pl.product.transformationFactor
          truncTotal = Number(newTotal)
          truncDiscount = Number(newDiscount)
          truncQuantity = Number(newQuantity)
        } else {
          const big = p.productLine[i - 1].product.transformationFactor
          const small = pl.product.transformationFactor
          const base = big / small
          newTotal = (newTotal % 1) * base
          truncTotal = Number(newTotal)
          truncDiscount = Number(newDiscount)
          truncQuantity = Number(newQuantity)
        }
        newTotal = parseFloat(newTotal)
        newQuantity = parseFloat(newQuantity > 1 ? newQuantity : 0)
        newDiscount = parseFloat(newDiscount)
        if (truncQuantity > 0 && truncTotal > 0)
          products.push({
            ...pl,
            total: truncTotal,
            discount: truncDiscount,
            quantity: truncQuantity,
          })
      }
    })
  })
  return products
}

export const selectExternalActiveWarehousesForSelect = state =>
  state.warehouse.externalActiveWarehouses.map(item => ({
    ...item,
    value: item.id,
    label: item.code + ' - ' + item.name,
  }))

export const selectWarehouseInventory = state => {
  const sort = (a, b) => {
    return a.productName < b.productName ? -1 : a.productName > b.productName ? 1 : 0
  }
  return state.warehouse.inventory.sort(sort)
}

export const selectFactorInventory = state => getFormatProducts(state.warehouse.inventory)

export const selectInventoryWithPrices = state => state.warehouse.inventoryWithPrices
export const selectTotalInventoryWithPrices = state => state.warehouse.totalInventory
export const selectInventoryValue = state => state.warehouse.value
export const selectInventorySale = state => state.warehouse.sale
export const selectInventoryTotalSale = state => state.warehouse.totalSale
export const selectInventoryPurchase = state => state.warehouse.purchase

export const selectAllOwnWarehousesForSelect = state => {
  const user = selectCurrentUser(state)
  const items = state.warehouse.allWarehouses
  if (!items) return []
  return items
    .filter(item => item.attendantsIds && item.attendantsIds.indexOf(user.id) >= 0)
    .map(item => ({ value: item.id, label: item.name, ...item }))
}

export const selectAllOthersWarehousesForSelect = state => {
  const user = selectCurrentUser(state)
  return state.warehouse.allWarehouses
    .filter(item => item.attendant !== user.id)
    .map(item => ({ value: item.id, label: item.name, ...item }))
}

export const selectAllOwnWarehousesWithAllForSelect = state => {
  const user = selectCurrentUser(state)
  return [{ value: 0, label: 'Todas las Bodegas' }].concat(
    state.warehouse.allWarehouses
      .filter(item => item.attendant === user.id)
      .map(item => ({ value: item.id, label: item.name, ...item })),
  )
}
export const selectAllWarehousesWithAllForSelect = state =>
  [{ value: 0, label: 'Todas las Bodegas' }].concat(
    state.warehouse.allWarehouses.map(item => ({
      value: item.id,
      label: item.name,
      ...item,
    })),
  )

export const selectAssociatedOrders = state => state.warehouse.associatedOrders
export const selectLastLogInventory = state => state.warehouse.lastLogInventory
export const selectPurchaseReportByWarehouse = state =>
  state.warehouse.purchasesByWarehouse
export const selectPurchaseReport = state => state.warehouse.purchases
export const selectHistorical = state => state.warehouse.historical
export const selectCsvResponse = state => state.warehouse.csv
export const selectResultBatch = state => state.warehouse.resultBatch
export const selectWarehouseLite = state =>
  state.warehouse.warehousesLite.map(wl => ({
    ...wl,
    value: wl.id,
    label: wl.name,
  }))
export const selectOwnWarehouseLite = state =>
  state.warehouse.warehousesLite
    .filter(wl => wl.owner)
    .map(wl => ({ ...wl, value: wl.id, label: wl.name }))

export const selectItemsToSelect = state =>
  state.warehouse.itemsToSelect.map(i => ({
    ...i,
    value: i.id,
    label: i.name,
  }))

export const selectItemsToSelectDetail = state =>
  state.warehouse.itemsToSelectDetail.map(i => ({
    ...i,
    value: i.id,
    label: i.name,
  }))

export const selectSellItemsToSelect = state => [
  getUniversalItem(),
  ...state.warehouse.itemsToSelect.map(i => ({
    ...i,
    value: i.id,
    label: i.name,
  })),
]
export const selectLineItemsToSelect = state => state.warehouse.lineItemsToSelect
export const selectWialonWarehouse = state => state.warehouse.gpsWarehouse
export const selectResponseMassive = state => state.warehouse.responseLoad
export const selectResponseValidate = state => state.warehouse.responseValidate
export const selectKardexReport = state => state.warehouse.kardexReport
// export const selectOwnWarehouseLite = state => state.warehouse.warehousesLite.filter((w) => w.owner).map((wl) => ({value: wl.id, label: wl.name}));
export const selectInventoryMissingResponse = state =>
  state.warehouse.inventoryMissingResponse
