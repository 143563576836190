import React, { Component } from 'react'
import EmployeeIcon from 'src/assets/images/svg/employee.svg'
import FactoryIcon from 'src/assets/images/svg/factory.svg'
import ClientIcon from 'src/assets/images/svg/client.svg'
import MoneyBagIcon from 'src/assets/images/svg/money-bag.svg'
import Monitor from 'src/assets/images/svg/monitor.svg'
import Pay from 'src/assets/images/svg/pay.svg'
import Accounting from 'src/assets/images/svg/accounting.svg'
import DashboardCard from 'src/components/cards/dashboard-card/DashboardCard'
import { selectMenu } from 'src/actions/dashboard.actions'
import { connect } from 'react-redux'
import {
  selectCurrentCompany,
  selectSetCurrentCompany,
} from 'src/selectors/company.selector'
import { getPermission } from 'src/selectors/modules.selector'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class DashboardAdmin extends Component {
  componentDidMount() {
    this.props.selectMenu()
  }

  render() {
    const { currentCompany, permission } = this.props
    return (
      <div>
        <h1 className={'dashboard-welcome'}>Balances</h1>
        <div className={'admin-modules-list'}>
          <DashboardCard name={'Balance en Empresa'} icon={Pay} link={'propios'} />
          <DashboardCard
            name={'Balance en Kolo'}
            icon={
              'https://firebasestorage.googleapis.com/v0/b/distribution-app-dev.appspot.com/o/assets%2FKOLO%20II-05.png?alt=media&token=c8b74cbd-e7e5-4ee0-93c1-88c29a865e58'
            }
            link={'kolo'}
          />
        </div>
        <br />
        {/*<Row>*/}
        {/*    <Col md={4}></Col>*/}
        {/*    <Col md={4}>{(permission(1000)|| permission(2000))?<div><DashboardCard name={'Balance de la empresa'} icon={Accounting} link={'general'}/></div>:''}</Col>*/}
        {/*    <Col md={4}></Col>*/}
        {/*</Row>*/}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentCompany: selectSetCurrentCompany(state),
  permission: id => getPermission(state, id),
})

const mapDispatchToProps = dispatch => ({
  selectMenu: () => dispatch(selectMenu(11000)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardAdmin)
