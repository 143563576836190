import { TreeState } from 'cp-react-tree-table'

export const selectReport = state => state.categorization.response
export const selectCategorizations = state => state.categorization.categorizations
export const selectCategorizationByType = state =>
  state.categorization.categorizationByType
export const selectCategorization = state => state.categorization.categorization
export const selectCategorizationTree = state =>
  state.categorization.categorization.children &&
  TreeState.create(state.categorization.categorization.children)
export const selectRoots = state => state.categorization.roots
export const selectCategorizationEntity = state =>
  state.categorization.categorizationEntity
export const selectCategorizationAssignedForSelect = (state, pre, a) => {
  if (a) console.log(pre)
  const c = pre && pre.length > 0 ? pre : state.categorization.categorizationEntity
  return c.map(r => ({ value: r.id, parents: r.assigned, label: r.name }))
}
export const selectRootsForSelect = state =>
  state.categorization.roots &&
  state.categorization.roots.map(r => ({
    value: r.id,
    data: r,
    label: r.name,
  }))
export const selectCategorizationTypeForSelect = state =>
  state.categorization.types &&
  state.categorization.types.map(t => ({ value: t.id, label: t.name }))
export const selectAllCategorizations = state => state.categorization.allCategorizations
export const selectAllCategorizationsByParents = state =>
  state.categorization.categorizationByParent &&
  state.categorization.categorizationByParent.map(t => ({
    value: t.id,
    label: t.name,
  }))

export const selectItemCategorizations = state => {
  let array = []
  if (
    state.categorization.allCategorizations &&
    state.categorization.allCategorizations.children
  ) {
    state.categorization.allCategorizations.children[0].children.forEach(s => {
      array.push({
        value: s.id,
        label: s.name,
        id: s.id,
        name: s.name,
        description: s.description,
        item: 'Categoria',
      })
      if (s.children && s.children.length > 0)
        s.children.forEach(f => {
          array.push({
            value: f.id,
            label: f.name,
            id: f.id,
            name: f.name,
            description: f.description,
            item: 'Sub-categoria',
          })
        })
    })
  }
  return array
}
