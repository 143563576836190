import {
  faArchive,
  faEdit,
  faPlusCircle,
  faSave,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Col, Container, Modal, ProgressBar, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  createNodeProductLine,
  deleteProductLine,
  getProductsSimple,
  seeProductLine,
  updateProductLine,
} from 'src/actions/products.actions'
import { Button, CustomCreate, NumberField } from 'src/components'
import { TreeGraph } from 'src/content/Products/Features/TreeGraph'
import { selectProductsSimple } from 'src/selectors/products.selector'
import { actionTypes } from 'src/actions/products.actions'
import {
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'
import Alert from 'sweetalert-react'
import { loadingSelector } from 'src/selectors/loading.selector'
import CreateProduct from 'src/content/Products/CreateProduct'

export const LineItemsModal = ({ show, onHide, productId }) => {
  const dispatch = useDispatch()

  const response = useSelector(state => state.products.productLine)

  const products = useSelector(state => selectProductsSimple(state))
  const loadingLine = useSelector(state =>
    loadingSelector([actionTypes.SEE_PRODUCT_LINE])(state),
  )

  const loadingUpdateLine = useSelector(state =>
    loadingSelector([actionTypes.UPDATE_PRODUCT_LINE])(state),
  )
  const hasErrorV = useSelector(state =>
    hasErrorsSelector([actionTypes.UPDATE_PRODUCT_LINE])(state),
  )
  const errorV = useSelector(state =>
    singleErrorSelector([actionTypes.UPDATE_PRODUCT_LINE])(state),
  )

  const [actions, setActions] = useState({ init: false, get: false, set: false })

  const [lineProduct, setLineProduct] = useState([])
  const [baseProduct, setBaseProduct] = useState({ label: '- Ninguno -', value: null })
  const [conversionFactor, setConversionFactor] = useState(null)

  const [showCreate, setShowCreate] = useState(false)
  const [name, setName] = useState('')
  const [showProductLine, setShowProductLine] = useState(false)
  const [nodes, setNodes] = useState([])
  const [links, setLinks] = useState([])
  const [data, setData] = useState({
    nodes: [],
    links: [],
  })
  const [showEdit, setShowEdit] = useState(false)
  const [itemSelected, setItemSelected] = useState({})
  const [productSelected, setProductSelected] = useState({})
  const [editTree, setEditTree] = useState(false)
  const [deleteTree, setDeleteTree] = useState(false)
  const [isEdit, setIsEdit] = useState(true)
  const [errors, setErrors] = useState({ base: null, factor: null })
  const [alert, setAlert] = useState({ title: '' })

  useEffect(() => {
    if (show) {
      dispatch(getProductsSimple())
      dispatch(seeProductLine(productId))
      setShowProductLine(true)
    }
  }, [show])

  useEffect(() => {
    if (loadingLine) setActions({ ...actions, init: true })
    else if (actions.init) {
      setActions({ ...actions, init: false })
      setLineProduct(response)
    }
  }, [loadingLine])

  useEffect(() => {
    if (lineProduct.length > 0) drawTree(response)
    setNodes([...nodes])
    setLinks([...links])
    setData({ ...data, nodes, links })
  }, [lineProduct])

  useEffect(() => {
    if (loadingUpdateLine) setActions({ ...actions, set: true })
    else if (actions.set) {
      setActions({ ...actions, set: false })
      if (hasErrorV)
        setAlert({
          ...handlerError(errorV.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Árbol actualizado exitosamente'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            dispatch(seeProductLine(productId))
            clean()
            setShowEdit(false)
            setItemSelected({})
          },
        })
    }
  }, [loadingUpdateLine])

  const onBaseProductChange = item => {
    setBaseProduct(item)
    setConversionFactor(null)
  }

  const clean = () => {
    setData({})
    setNodes([])
    setLinks([])
    setIsEdit(false)
    setDeleteTree(false)
    setEditTree(false)
  }

  const hideModal = () => {
    onHide()
    clean()
    setShowProductLine(false)
  }

  const drawTree = lineProduct => {
    clean()
    if (lineProduct.length > 0) {
      const root = lineProduct[0]

      nodes.push({
        id: root.idPresentation,
        name: <>{root.code + '-' + root.name}</>,
        x: 100,
        y: 160,
      })

      findChild(
        lineProduct.filter(item => item.subId === root.id),
        50,
        160,
      )
    }
  }
  const findChild = (list, distanceX, middleDistance) => {
    if (list.length === 0) return 0

    let windowsY = -((list.length * 80) / 2) + middleDistance

    list.length > 0 &&
      list.map((item, index) => {
        const space = findChild(
          lineProduct.filter(itemA => itemA.subId === item.id),
          distanceX + 300,
          windowsY + 40,
        )

        nodes.push({
          id: item.idPresentation,
          name: `${item.code}\n ${item.name}`,
          x: distanceX + 350,
          y: windowsY + 40,
        })

        if (item.subId) {
          let itemAux = lineProduct.find(x => x.id === item.subId)
          if (itemAux) {
            links.push({
              source: itemAux.idPresentation,
              target: item.idPresentation,
              label: item.factor,
            })
          }
        }
        if (index + 1 <= list.length) {
          windowsY += 80 + space / 2
        }

        return null
      })
    return list.length > 1 ? windowsY : 70
  }
  const handleCreate = inputValue => {
    setTimeout(() => {
      setShowEdit(false)
      setName(inputValue)
      setShowCreate(true)
    }, 100)
  }

  const filterItems = val => {
    return isEdit
      ? lineProduct.some(item => item.id === val.id && item.id !== itemSelected.id)
      : !lineProduct.some(item => item.id === val.id)
  }

  return (
    <div>
      <Modal
        show={showProductLine && !showCreate && !showEdit}
        size={'lg'}
        centered
        onHide={() => hideModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Árbol de Ítems</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="invoice">
            {loadingLine ? (
              <div className={'pb-custom'}>
                <ProgressBar
                  label="Cargando"
                  animated
                  now={100}
                  style={{ marginBottom: 10 }}
                />
              </div>
            ) : (
              <>
                {showProductLine && (
                  <TreeGraph
                    data={data}
                    editItem={item => {
                      const itemChoose = lineProduct.find(
                        i => i.idPresentation === parseInt(item),
                      )
                      const itemBase = lineProduct.find(
                        i => i.id === parseInt(itemChoose.subId),
                      )
                      setItemSelected(itemChoose)
                      if (itemBase && isEdit) {
                        setBaseProduct({
                          ...itemBase,
                          label: itemBase.largeName,
                          value: itemBase.id,
                          productId: itemBase.id,
                        })
                        setConversionFactor(itemChoose.factor)
                      } else {
                        setBaseProduct({ label: '- Ninguno -', value: null })
                        setConversionFactor(null)
                      }
                      setProductSelected(products.find(i => i.id === itemChoose.id))
                      if (editTree && !deleteTree) {
                        setShowEdit(true)
                      } else if (deleteTree) {
                        setAlert({
                          show: true,
                          title: '¡Advertencia!',
                          text: 'Al remover este ítem del árbol también se removerán los ítems que provengan de él',
                          showCancelButton: true,
                          cancelButtonText: 'Cancelar',
                          confirmButtonText: 'Continuar',
                          type: 'warning',
                          onCancel: () => setAlert({ show: false }),
                          onConfirm: () => {
                            dispatch(deleteProductLine(item))
                            setAlert({ ...alert, show: false })
                          },
                        })
                      }
                    }}
                  />
                )}
              </>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            {editTree && !isEdit && (
              <div style={{ marginRight: '15px' }}>
                Selecciona donde colocar el nuevo ítem
              </div>
            )}
            {deleteTree && (
              <div style={{ marginRight: '15px' }}>Selecciona el ítem a remover</div>
            )}
            {editTree && isEdit && (
              <div style={{ marginRight: '15px' }}>Selecciona el ítem a editar</div>
            )}
            {editTree === false && !deleteTree && (
              <>
                <Button
                  icon={faPlusCircle}
                  color={'accent'}
                  onClick={() => {
                    setEditTree(true)
                    setIsEdit(false)
                  }}>
                  Añadir Ítem
                </Button>
                <Button
                  icon={faArchive}
                  color={'secondary'}
                  onClick={() => {
                    setDeleteTree(true)
                  }}>
                  Remover Ítem
                </Button>
                <Button
                  icon={faEdit}
                  color={'primary'}
                  onClick={() => {
                    setEditTree(true)
                    setIsEdit(true)
                  }}>
                  Editar Árbol
                </Button>
              </>
            )}

            {(editTree || deleteTree) && (
              <Button
                icon={faWindowClose}
                color={'secondary'}
                onClick={() => {
                  setIsEdit(false)
                  setDeleteTree(false)
                  setEditTree(false)
                }}>
                Cancelar
              </Button>
            )}
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showEdit}
        size={'md'}
        centered
        onHide={() => {
          setShowEdit(false)
          setItemSelected({})
          setBaseProduct({})
        }}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEdit ? `Editar ${itemSelected.name}` : 'Agregar Presentación'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              {itemSelected.subId !== null || (!isEdit && !deleteTree) ? (
                <CustomCreate
                  label={isEdit ? 'Ítem base' : 'Presentación'}
                  info={
                    isEdit
                      ? 'Es el ítem base del ítem derivado'
                      : 'Es el ítem presentación del ítem seleccionado'
                  }
                  onChange={item => {
                    setErrors({ ...errors, base: null })
                    onBaseProductChange(item)
                    setShowCreate(false)
                  }}
                  onCreateOption={e => {
                    console.log(e)
                    !isEdit && handleCreate(e)
                  }}
                  isDisabled={!isEdit}
                  options={[
                    { label: '- Ninguno -', value: null },
                    ...products.filter(filterItems),
                  ]}
                  value={baseProduct}
                  placeholder={'Buscar o seleccionar'}
                  textLabel={isEdit ? '' : 'Nuevo ítem: '}
                  required
                  error={errors.base}
                />
              ) : (
                <h4 style={{ textAlign: 'center', marginTop: 50 }}>
                  No se puede editar el ítem {itemSelected.name} porque es base de un
                  árbol de ítems
                </h4>
              )}
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              {(itemSelected.subId !== null || !isEdit) && (
                <NumberField
                  disabled={!baseProduct?.value}
                  label={'Factor de conversión'}
                  info={
                    'Determina cuántas unidades del ítem base conforman el ítem presentación'
                  }
                  id={'conversionFactor'}
                  placeholder={'10'}
                  name={'conversionFactor'}
                  value={conversionFactor}
                  required
                  min={0}
                  onValueChange={value => {
                    setErrors({ ...errors, factor: null })
                    setConversionFactor(value)
                  }}
                  error={errors.factor}
                />
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            {baseProduct?.value != null && (
              <Button
                disabled={loadingUpdateLine}
                loading={loadingUpdateLine}
                icon={faSave}
                color={'primary'}
                onClick={() => {
                  if (baseProduct.value === null)
                    setErrors({ ...errors, base: 'Debe seleccionar un ítem válido' })
                  else if (conversionFactor === null || conversionFactor === 0)
                    setErrors({ ...errors, factor: 'No puede estar vacío' })
                  else {
                    if (isEdit) {
                      let params = {
                        subProductId: baseProduct.id,
                        subProductValue: conversionFactor,
                      }
                      dispatch(updateProductLine(itemSelected.idPresentation, params))
                    } else {
                      let params = {
                        productId: baseProduct.id,
                        subProductValue: conversionFactor,
                      }
                      dispatch(createNodeProductLine(itemSelected.idPresentation, params))
                    }
                  }
                }}>
                Guardar
              </Button>
            )}
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal show={showCreate} size={'xl'} centered onHide={() => setShowCreate(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Crear ítem</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateProduct
            modal
            onClose={() => {
              setShowCreate(false)
              dispatch(seeProductLine(productId))
            }}
            name={name}
            itemSelected={productSelected}
          />
        </Modal.Body>
      </Modal>

      <Alert {...alert} />
    </div>
  )
}
