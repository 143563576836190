import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import { Button, FormText, Icon, Select, Switch, SwitchV2 } from 'src/components'
import ImageInput, { imageTypes } from 'src/components/inputs/ImageInput/ImageInput'
import {
  faEdit,
  faInfoCircle,
  faPlus,
  faSave,
  faStar,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  addPosAdmin,
  createTip,
  deleteTip,
  getPosAdmins,
  getPOSItemsType,
  getTipsAvailable,
  onCreatePosItemType,
  onDeletePosItem,
  onUpdatePosItem,
  removePosAdmin,
  updatePosStepItems,
  updateTipFavorite,
} from 'src/actions/restaurant.actions'
import {
  selectDiscounts,
  selectItemsType,
  selectTips,
  selectAllPOSAdmins,
  selectPosConfigurationResponse,
} from 'src/selectors/restaurant.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  handlerSuccess,
  handlerWarningButtons,
  hasErrors,
} from 'src/selectors/error.selector'

import { toMoney } from 'src/utils/utilities'
import Money from 'src/components/Money/Money'
import { selectUsersByCompanyForSelect } from 'src/selectors/modules.selector'
import { getUsersByCompany } from 'src/actions/modules.actions'
import { selectCurrentCompany } from 'src/selectors/user.selector'
import IconButton from 'src/components/buttons/IconButton'
import parkingTypes from 'src/enums/parkingTypes'
import { establishmentTypes } from 'src/enums/establishmentTypes'
import useDebounce from 'src/hooks/useDebounce'
import { selectActivePlans } from 'src/selectors/plans.selector'
import { PlanEnum } from 'src/enums/planTypes'
import { selectNeedAddressComponents } from 'src/selectors/utilities.selector'
import { selectCompanyCountry } from 'src/selectors/company.selector'
import { Country } from 'src/enums/countries'
import { showAlert } from 'src/actions/alert.actions'

const steps = [
  {
    label: 'Comandera con proceso completo',
    value: 1,
    description: () => (
      <div>
        <br />
        <p>
          <b>Comandar</b> → <b>Aprobar</b> → <b>Entregar</b> → <b>Facturar</b>
        </p>
      </div>
    ),
  },
  {
    label: 'Comandera con proceso intermedio',
    value: 2,
    description: () => (
      <div>
        <br />
        <p>
          <b>Comandar</b> → <b>Entregado</b> → <b>Facturar</b>
        </p>
        <p>
          Al comandar uno o varios items, se creara una solicitud de producción y se
          aprobara automáticamente y el estado de los productos cambiara a Entregado
        </p>
      </div>
    ),
  },
  {
    label: 'default ignore',
    value: 3,
    description: () => (
      <div>
        <br />
        <p>
          <b>Facturación inmediata</b>
        </p>
        <p>
          Al dar click a Ordenar o Facturar, inmediatamente se generara el documento
          correspondiente con los ítems seleccionados en la terminal, el tipo de pago y el
          cliente seleccionado
        </p>
      </div>
    ),
    hide: true,
  },
  {
    label: 'Comandera con proceso ágil',
    value: 4,
    description: () => (
      <div>
        <br />
        <p>
          <b>Facturación inmediata</b>
        </p>
        <p>
          Al dar click a Ordenar o Facturar, inmediatamente se generara el documento
          correspondiente con los ítems seleccionados en la terminal, el tipo de pago y el
          cliente seleccionado
        </p>
      </div>
    ),
    hide: false,
  },
]

const defaultOption = { value: null, label: 'Sin seleccionar' }

const itemTypes = parkingTypes

const POSConfig = ({ show, onHide, pos }) => {
  const dispatch = useDispatch()

  const users = useSelector(selectUsersByCompanyForSelect)
  const company = useSelector(selectCurrentCompany)
  const country = useSelector(selectCompanyCountry)
  const plan = useSelector(selectActivePlans)
  const tips = useSelector(selectTips)
  const discounts = useSelector(selectDiscounts)
  const setAddressComponents = useSelector(selectNeedAddressComponents)

  const loadingTip = useSelector(state =>
    loadingSelector([actionTypes.CREATE_OR_DELETE_TIP])(state),
  )
  const hasErrorTip = useSelector(state =>
    hasErrors([actionTypes.CREATE_OR_DELETE_TIP])(state),
  )

  const configurationResponse = useSelector(selectPosConfigurationResponse)

  const loadingPosStep = useSelector(state =>
    loadingSelector([actionTypes.UPDATE_POS_STEP])(state),
  )

  const errorPosStep = useSelector(state =>
    hasErrors([actionTypes.UPDATE_POS_STEP])(state),
  )

  const loadingItem = useSelector(state =>
    loadingSelector([actionTypes.POS_ITEM_POST_PUT_DELETE])(state),
  )
  const hasErrorItem = useSelector(state =>
    hasErrors([actionTypes.POS_ITEM_POST_PUT_DELETE])(state),
  )

  const posItemsType = useSelector(selectItemsType)

  const [flags, setFlags] = useState({
    tip: false,
    success: false,
    putPosDelete: false,
    updateConfigs: false,
  })

  const [open, setOpen] = useState(false)
  const [modalTip, setModalTip] = useState({ show: false, percentage: 0 })
  const [stepValue, setStep] = useState(1)
  const [posItem, setPosItem] = useState({})
  const [value, setValue] = useState({})

  const selectedUsers = useSelector(selectAllPOSAdmins)
  const [selectedUser, setSelectedUser] = useState({})

  useEffect(() => {
    if (users.length) return
    dispatch(getUsersByCompany(company))
  }, [])

  useEffect(() => {
    if (!pos.id) return
    dispatch(getPosAdmins(pos.id))
  }, [pos])

  useEffect(() => {
    if (!show) return
    setOpen(true)
    dispatch(getTipsAvailable(pos.id))
    dispatch(getPOSItemsType(pos.id))
    setStep(pos.stepItems)
    setValue({ ...pos })
  }, [show])

  useEffect(() => {
    if (loadingTip) setFlags({ ...flags, tip: true })
    else if (flags.tip) {
      setFlags({ ...flags, tip: false })
      if (hasErrorTip) dispatch(showAlert(handlerError(hasErrorTip.message)))
      else {
        dispatch(getTipsAvailable(pos.id, true))
        setModalTip({ show: false, percentage: 0 })
        setFlags({ ...flags, success: true })
      }
    }
  }, [loadingTip])

  useEffect(() => {
    if (loadingItem) setFlags({ ...flags, putPosDelete: true })
    else if (flags.putPosDelete) {
      setFlags({ ...flags, putPosDelete: false })
      if (hasErrorItem) dispatch(showAlert(handlerError(hasErrorItem.message)))
      else
        dispatch(
          showAlert({
            ...handlerSuccess('Operación exitosa.'),
            onConfirm: () => {
              setPosItem({})
              dispatch(getPOSItemsType(pos.id))
            },
          }),
        )
    }
  }, [loadingItem])

  useEffect(() => {
    if (!show) return
    if (loadingPosStep) setFlags({ ...flags, updateConfigs: true })
    else if (flags.updateConfigs) {
      setFlags({ ...flags, updateConfigs: false, success: true })
      if (errorPosStep) dispatch(showAlert(handlerError(errorPosStep.message)))
      else {
        if (configurationResponse.footerPhrase === '-1')
          delete configurationResponse.footerPhrase

        if (configurationResponse.seeAlert) dispatch(showAlert(handlerSuccess()))

        delete configurationResponse.seeAlert
        setValue({ ...value, ...configurationResponse })
      }
    }
  }, [loadingPosStep])

  const onClose = () => {
    setOpen(false)
    onHide(flags.success)
    setFlags({ ...flags, success: false })
  }

  const updatePosConfig = useDebounce(params => {
    dispatch(updatePosStepItems(pos.id, params))
  }, 100)

  const commercialFieldsValidate = ['commercialName', 'commercialAddress'].concat(
    setAddressComponents
      ? [
          'commercialCode',
          'establishmentType',
          'commercialDepartment',
          'commercialMunicipality',
        ]
      : [],
  )

  const disabledCommercialSave = commercialFieldsValidate.some(key => !value[key])

  return (
    <div>
      <Modal
        show={open && !modalTip.show && !posItem.show}
        size={'xl'}
        centered
        onHide={() => onClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Configuración: {pos.name}</Modal.Title>
        </Modal.Header>

        <Modal.Body className={'custom-modal-body'}>
          <Row style={{ gap: '10px 0' }}>
            <Col xl={6} lg={6} md={6}>
              <div>
                Logo para tickets{' '}
                <label>
                  <IconButton
                    tooltip={
                      'Si no se agrega un logo, no se imprimirá nada en los tickets.'
                    }
                    color={'rgba(34, 96, 149, 0.75)'}
                    icon={faInfoCircle}
                    size={'sm'}
                  />
                </label>
              </div>
              <ImageInput
                imageType={imageTypes.TICKET_LOGO}
                src={{ url: value.ticketsLogo !== 'null' && value.ticketsLogo }}
                disabled={loadingPosStep}
                style={{ height: 100, width: 100 }}
                onSuccessFile={url => {
                  updatePosConfig({ ticketsLogo: url || 'null', footerPhrase: '-1' })
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} className={'mt-3'}>
              <SwitchV2
                label={'Imprimir logo en facturas'}
                info={'Si está activa la opción, se imprimirá el logo en las facturas.'}
                disabled={loadingPosStep}
                checked={value.activeInvoiceTicketsLogo}
                onChange={e => {
                  updatePosConfig({ activeInvoiceTicketsLogo: e, footerPhrase: '-1' })
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} className={'mt-3'}>
              <SwitchV2
                disabled={loadingPosStep}
                label={'Imprimir logo en comandas'}
                info={'Si está activa la opción, se imprimirá el logo en las comandas.'}
                checked={value.activeOrderTicketsLogo}
                onChange={e => {
                  updatePosConfig({ activeOrderTicketsLogo: e, footerPhrase: '-1' })
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} className={'mt-3'}>
              <SwitchV2
                disabled={loadingPosStep}
                label={'Imprimir logo en pre cuentas'}
                info={
                  'Si está activa la opción, se imprimirá el logo en las pre cuentas.'
                }
                checked={value.activePreTicketsLogo}
                onChange={e => {
                  updatePosConfig({ activePreTicketsLogo: e, footerPhrase: '-1' })
                }}
              />
            </Col>

            <Col xl={6} lg={6} md={12}>
              <div className={'column'}>
                <div className={'b-user-name'}>Propinas</div>
                <div className={'b-user-email'}>
                  Configurar las cantidades disponibles para asignar a una comanda (en
                  porcentaje)
                </div>

                <Row>
                  <Col xl={12} lg={12} md={12}>
                    <Button
                      color={'accent'}
                      onClick={() => setModalTip({ show: true, percentage: 0 })}>
                      <Icon icon={faPlus} tooltip={'Agregar nueva opcion'} />
                    </Button>
                    Agregar nueva opcion
                  </Col>
                  <Col xl={12} lg={12} md={12}>
                    <Row className={'pl-1'}>
                      {tips &&
                        tips.map((tip, index) => (
                          <div key={index} className={'c-item assigned ml-2'}>
                            <Icon
                              disabled={loadingTip}
                              className={'mt-1 mr-1'}
                              icon={faStar}
                              color={tip.favorite ? '#6ed76e' : 'gray'}
                              onClick={() => {
                                dispatch(
                                  updateTipFavorite(tip.id, false, !tip.favorite, pos.id),
                                )
                              }}
                            />
                            <label className={'label-user-tag'}>% {tip.percentage}</label>
                            <Icon
                              className={'c-item-button assigned pt-1'}
                              icon={faTimes}
                              tooltip={'Eliminar'}
                              color={'white'}
                              disabled={loadingTip}
                              spin={loadingTip && modalTip.id === tip.id}
                              onClick={() => {
                                setModalTip({
                                  id: tip.id,
                                  percentage: 0,
                                  show: false,
                                })
                                dispatch(deleteTip(tip.id))
                              }}
                            />
                          </div>
                        ))}
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col xl={6} lg={6} md={12}>
              <div className={'column mt-2'}>
                <div className={'b-user-name'}>Descuentos</div>
                <div className={'b-user-email'}>
                  Configurar las cantidades disponibles para asignar a una comanda (en
                  porcentaje)
                </div>
                <Row>
                  <Col xl={12} lg={12} md={12}>
                    <Button
                      color={'accent'}
                      onClick={() =>
                        setModalTip({
                          show: true,
                          percentage: 0,
                          discount: true,
                        })
                      }>
                      <Icon icon={faPlus} tooltip={'Agregar nueva opcion'} />
                    </Button>
                    Agregar nueva opcion
                  </Col>
                  <Col xl={12} lg={12} md={12}>
                    <Row className={'pl-1'}>
                      {discounts &&
                        discounts.map((tip, index) => (
                          <div key={index} className={'c-item assigned ml-2'}>
                            <label className={'label-user-tag'}>% {tip.percentage}</label>
                            <Icon
                              className={'c-item-button assigned'}
                              icon={faTimes}
                              tooltip={'Eliminar'}
                              color={'white'}
                              disabled={loadingTip}
                              spin={loadingTip && modalTip.id === tip.id}
                              onClick={() => {
                                setModalTip({
                                  id: tip.id,
                                  percentage: 0,
                                  show: false,
                                })
                                dispatch(deleteTip(tip.id))
                              }}
                            />
                          </div>
                        ))}
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>

            {pos?.type !== 2 && (
              <Col lg={6} md={6} sm={12} className={'mt-3'}>
                <SwitchV2
                  disabled={loadingPosStep}
                  label={'Selección ágil de items sin receta o variaciones'}
                  info={
                    'Si está activa la opción, se podrá seleccionar ágilmente los items que no tienen receta o variaciones.'
                  }
                  checked={value.agileSelection}
                  onChange={e => {
                    updatePosConfig({ agileSelection: e, footerPhrase: '-1' })
                  }}
                />
              </Col>
            )}

            <Col lg={6} md={6} sm={12} className={'mt-3'}>
              <SwitchV2
                disabled={loadingPosStep}
                label={`Ver el nombre del usuario quien ingreso ${
                  pos?.type === 2 ? 'el vehículo' : 'la comannda'
                }`}
                info={`Si está activa la opción, se visualizará el nombre del usuario quien ingreso ${
                  pos?.type === 2 ? 'el vehículo' : 'la comannda'
                }`}
                checked={value.seeUser}
                onChange={e => {
                  updatePosConfig({ seeUser: e, footerPhrase: '-1' })
                }}
              />
            </Col>

            <Col lg={6} md={6} sm={12} className={'mt-3'}>
              <SwitchV2
                disabled={loadingPosStep}
                label={`Ver imagen en el listado de ${
                  pos.type === 2 ? 'vehículos' : 'terminales'
                }`}
                info={`Si está activa la opción, se visualizará la imagen en el listado de ${
                  pos.type === 2 ? 'vehículos' : 'terminales'
                }`}
                checked={value.seeImg}
                onChange={e => {
                  updatePosConfig({ seeImg: e, footerPhrase: '-1' })
                }}
              />
            </Col>

            {pos.type && pos.type === 1 && (
              <Col lg={6} md={6} sm={12} className={'mt-3'}>
                <SwitchV2
                  disabled={loadingPosStep}
                  label={'Imprimir ticket de regalo'}
                  info={
                    'Si la opción está activa, se imprimira un ticket de regalo al momento de imprimir la factura.'
                  }
                  checked={value.printGift}
                  onChange={e => {
                    updatePosConfig({ printGift: e, footerPhrase: '-1' })
                  }}
                />
              </Col>
            )}

            {pos.type && pos.type === 2 && (
              <Col lg={6} md={6} sm={12} className={'mt-3'}>
                <SwitchV2
                  disabled={loadingPosStep}
                  label={'Requerir escaneo de QR'}
                  info={
                    'Si la opción está activa, se requerirá el escaneo del QR del ticket para la entrega de un vehículo.'
                  }
                  checked={!value.skipScanner}
                  onChange={e => {
                    updatePosConfig({ skipScanner: !e, footerPhrase: '-1' })
                  }}
                />
              </Col>
            )}

            <Col lg={6} md={6} sm={12} className={'mt-3'}>
              <SwitchV2
                disabled={loadingPosStep}
                label={'Ver detalle de la orden de venta'}
                info={
                  'Si la opción está activa, Se visualizara el detalle de la orden de venta.'
                }
                checked={value.seeOrderDetail}
                onChange={e => {
                  updatePosConfig({ seeOrderDetail: e, footerPhrase: '-1' })
                }}
              />
            </Col>

            <Col lg={6} md={6} sm={12} className={'mt-3'}>
              <SwitchV2
                disabled={loadingPosStep}
                label={'Seleccionar ágilmente items con receta base'}
                info={
                  'Si se activa esta opción, los items con solo receta base, serán seleccionados ágilmente sin necesidad de visualizar la receta.'
                }
                checked={value.noViewRecipe}
                onChange={e => {
                  updatePosConfig({ noViewRecipe: e, footerPhrase: '-1' })
                }}
              />
            </Col>

            <Col lg={6} md={6} sm={12} className={'mt-3'}>
              <SwitchV2
                disabled={loadingPosStep}
                topLabel
                label={'Ocultar receta base'}
                info={
                  'Si se activa esta opción, los items con receta base y variaciones solo verán las variaciones y la receta base se ocultará.'
                }
                checked={value.noViewRecipeInModalItem}
                onChange={e => {
                  updatePosConfig({ noViewRecipeInModalItem: e, footerPhrase: '-1' })
                }}
              />
            </Col>

            <Col lg={6} md={6} sm={12} className={'mt-3'}>
              <SwitchV2
                disabled={loadingPosStep}
                label={'Cambiar concepto de facturación'}
                info={
                  'Si se activa esta opción se permitirá cambiar el concepto de facturación en este punto de venta'
                }
                checked={value.changeConcept}
                onChange={e => {
                  updatePosConfig({ changeConcept: e, footerPhrase: '-1' })
                }}
              />
            </Col>

            <Col lg={6} md={6} sm={12} className={'mt-3'}>
              <SwitchV2
                disabled={loadingPosStep}
                label={'Mismo correlativo de ventas'}
                info={
                  'Si se activa esta opción el correlativo para comandas / manufacturas, pre cuenta y ventas serán el mismo.'
                }
                checked={value.sameCorrelative}
                onChange={e => {
                  updatePosConfig({ sameCorrelative: e, footerPhrase: '-1' })
                }}
              />
            </Col>

            <Col lg={6} md={6} sm={12} className={'mt-3'}>
              <SwitchV2
                disabled={loadingPosStep}
                label={'Requerir validación de manufactura / comanda'}
                info={
                  'Si se activa esta opción se requerirá la validación de manufactura o comanda, solo para control interno de la empresa.'
                }
                checked={value.requiredManufactureValidation}
                onChange={e => {
                  updatePosConfig({
                    requiredManufactureValidation: e,
                    footerPhrase: '-1',
                  })
                }}
              />
            </Col>

            <Col lg={12} md={12} sm={12}>
              <FormText
                disabled={loadingPosStep}
                label={
                  'Pie de tickets: Ingresa una descripción para mostrarse en la parte final de un ticket.'
                }
                value={value.footerPhrase}
                as={'textarea'}
                rows={5}
                max={450}
                changeValue={footerPhrase => setValue({ ...value, footerPhrase })}
                append={
                  <Icon
                    icon={faSave}
                    tooltip={'Guardar'}
                    onClick={() => {
                      updatePosConfig({ footerPhrase: value.footerPhrase })
                    }}
                  />
                }
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <FormText
                disabled={loadingPosStep}
                label={'Capacidad del Punto de venta'}
                value={value.capacity}
                type={'number'}
                changeValue={capacity => {
                  if (!capacity || capacity < 0) capacity = 0

                  setValue({ ...value, capacity })
                }}
                append={
                  <Icon
                    icon={faSave}
                    tooltip={'Guardar'}
                    onClick={() => {
                      updatePosConfig({
                        footerPhrase: '-1',
                        capacity: value.capacity,
                      })
                    }}
                  />
                }
                sub={
                  'Está configuración no afectara al ingreso de items, unicamente servira para informar.'
                }
              />
            </Col>

            {pos.type === 2 ? (
              <Col xl={12}>
                <div className={'column mt-2'}>
                  <div className={'b-user-name'}>Tarifas</div>
                  <div className={'b-user-email'}>
                    Configurar las tarifas del punto de venta
                  </div>
                  <div className={'column'}>
                    <div
                      className={'b-item'}
                      onClick={() => setPosItem({ show: true, title: 'Nueva tarifa' })}>
                      <div className={'b-user-name d-flex'}>
                        Agregar nueva tarifa <Icon icon={faPlus} />
                      </div>
                    </div>
                    <div>
                      <hr className={'m-0'} />
                    </div>
                    {posItemsType.map(pit => {
                      const type = itemTypes
                        .filter(it => it.posType === pos.type)
                        .find(it => it.value === pit.type)

                      return (
                        <div key={pit.id} className={'b-item'}>
                          <div className={'space-between'}>
                            <div className={'column'}>
                              <div className={'b-user-name'}>{pit.name}</div>
                              <Money className={'b-user-email'}>
                                Tarifa: {pit.price}
                              </Money>
                              <div className={'b-user-email'}>
                                Tipo: {type ? type.label : 'Desconocido'}
                              </div>
                            </div>
                            <div className={'d-flex'}>
                              <Icon
                                icon={faEdit}
                                style={{ marginRight: 5 }}
                                tooltip={'Editar la tarifa'}
                                onClick={() => {
                                  const type = itemTypes.find(
                                    it =>
                                      it.posType === pos.type && it.value === pit.type,
                                  )
                                  setPosItem({
                                    ...pit,
                                    type,
                                    show: true,
                                    title: 'Actulizar',
                                  })
                                }}
                              />
                              <Icon
                                spin={loadingItem && pit.id === posItem.id}
                                icon={faTrash}
                                tooltip={'Eliminar tarifa'}
                                onClick={() => {
                                  dispatch(
                                    showAlert({
                                      ...handlerWarningButtons(
                                        'Eliminar: ' +
                                          pit.name +
                                          ' ' +
                                          toMoney(pit.price),
                                        '¿Desea eliminar la tarifa seleccionada?',
                                      ),
                                      onConfirm: () => {
                                        setPosItem({ id: pit.id })
                                        dispatch(onDeletePosItem(pit.id))
                                      },
                                    }),
                                  )
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </Col>
            ) : (
              <>
                <Col xl={12}>
                  <SwitchV2
                    disabled={loadingPosStep}
                    label={'Token para limpiar terminal'}
                    info={
                      'Al activar la opción se solicita un token para limpiar las terminales del punto de venta'
                    }
                    checked={value.clearToken}
                    onChange={e => {
                      updatePosConfig({ clearToken: e, footerPhrase: '-1' })
                    }}
                  />
                </Col>
                {value.clearToken && (
                  <Col xl={12}>
                    <div className={'column mt-2'}>
                      <div className={'b-user-name'}>
                        Administradores del punto de venta
                      </div>
                      <div className={'b-user-email'}>
                        Los administradores del punto de venta recibirán un token al
                        intentar limpiar una terminal
                      </div>
                      <Select
                        loading={loadingPosStep}
                        style={{ margin: '15px 0' }}
                        withoutLabel
                        options={users.filter(
                          user =>
                            !selectedUsers.find(
                              selectedUser => selectedUser.id === user.value,
                            ),
                        )}
                        value={selectedUser}
                        onChange={user => {
                          setSelectedUser(user)
                        }}
                      />
                      <Button
                        disabled={!selectedUser.value}
                        icon={faSave}
                        color={'primary'}
                        onClick={() => {
                          dispatch(addPosAdmin(pos.id, selectedUser.value, selectedUser))
                          setSelectedUser({})
                        }}>
                        Añadir usuario
                      </Button>
                      <div>
                        {!selectedUsers.length && (
                          <div style={{ color: '#d33' }}>
                            <div className={'b-user-email'}>
                              Se requiere de mínimo un administrador para obtener los
                              token
                            </div>
                          </div>
                        )}
                        {selectedUsers.map(user => (
                          <div
                            key={user.name}
                            className="chip mb-3 mb-0 ml-1"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              dispatch(removePosAdmin(pos.id, user.value))
                            }}>
                            <div className="chip-head">
                              {user.name.length ? user.name[0] : ''}
                            </div>
                            <div className="chip-content">{user.name}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Col>
                )}
                <Col xl={12}>
                  <div className={'column mt-2'}>
                    <div className={'b-user-name'}>Pasos de productos</div>
                    <div className={'b-user-email'}>
                      Configurar los pasos a seguir para entregar uno o varios productos
                      de la comanda
                    </div>
                    <Select
                      loading={loadingPosStep}
                      style={{ margin: '15px 0 0' }}
                      withoutLabel
                      options={steps.filter(s =>
                        plan?.id === PlanEnum.FREE ? s.value === 4 : !s.hide,
                      )}
                      value={steps[stepValue - 1]}
                      onChange={({ value }) => {
                        setStep(value)

                        updatePosConfig({
                          stepValue: value,
                          footerPhrase: '-1',
                          seeExistence: false,
                          validateExistence: false,
                        })
                      }}
                      subText={steps[stepValue - 1].description()}
                      dataCy={'pos-step'}
                    />
                  </div>
                </Col>

                {stepValue === 4 && (
                  <Col xl={12}>
                    <Row>
                      <Col lg={6} md={6} sm={12} className={'mt-3'}>
                        <SwitchV2
                          disabled={loadingPosStep}
                          label={'Visualización de inventario'}
                          info={
                            'Al activar esta opción, será posible visualizar en los ítems que no requieren receta y sus variaciones, la cantidad disponible en existencias de la bodega asignada a la terminal.'
                          }
                          checked={value.seeExistence}
                          onChange={e => {
                            const validateExistence = !e ? false : value.validateExistence

                            updatePosConfig({
                              seeExistence: e,
                              validateExistence,
                              footerPhrase: '-1',
                            })
                          }}
                        />
                      </Col>
                      <Col lg={6} md={6} sm={12} className={'mt-3'}>
                        <SwitchV2
                          disabled={loadingPosStep || !value.seeExistence}
                          label={'Validación de inventario'}
                          info={
                            'Al activar esta opción, se restringirá la venta de ítems sin receta y de variaciones que no dispongan de existencias en el inventario.'
                          }
                          checked={value.validateExistence}
                          onChange={e => {
                            updatePosConfig({ validateExistence: e, footerPhrase: '-1' })
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                )}

                <Col xl={12}>
                  <SwitchV2
                    disabled={loadingPosStep}
                    label={'Activar como establecimiento comercial'}
                    info={
                      'Al activar la opción se extenderán facturas con la información del establecimiento'
                    }
                    checked={value.commercialEstablishment}
                    onChange={e => {
                      updatePosConfig({
                        commercialEstablishment: e,
                        footerPhrase: '-1',
                      })
                    }}
                  />
                </Col>
                {value.commercialEstablishment && (
                  <>
                    <Col lg={6} md={6} sm={12}>
                      <FormText
                        required
                        disabled={loadingPosStep}
                        label={
                          country.id === Country.HN
                            ? 'Nombre sucursal'
                            : 'Nombre comercial'
                        }
                        value={value.commercialName}
                        changeValue={commercialName => {
                          if (!commercialName) commercialName = ''
                          setValue({ ...value, commercialName })
                        }}
                      />
                    </Col>

                    <Col lg={6} md={6} sm={12}>
                      <FormText
                        required
                        disabled={loadingPosStep}
                        label={'Dirección'}
                        value={value.commercialAddress}
                        changeValue={commercialAddress => {
                          if (!commercialAddress) commercialAddress = ''
                          setValue({ ...value, commercialAddress })
                        }}
                      />
                    </Col>

                    {setAddressComponents && (
                      <Col xl={12}>
                        <Col lg={6} md={6} sm={12}>
                          <FormText
                            required
                            disabled={loadingPosStep}
                            label={'Código del establecimiento'}
                            value={value.commercialCode}
                            changeValue={commercialCode => {
                              if (!commercialCode) commercialCode = ''
                              setValue({ ...value, commercialCode })
                            }}
                          />
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                          <Select
                            required
                            disabled={loadingPosStep}
                            label={'Tipo de establecimiento'}
                            value={establishmentTypes.find(
                              establishmentType =>
                                establishmentType.value === value.establishmentType,
                            )}
                            options={[defaultOption, ...establishmentTypes]}
                            onChange={establishmentType => {
                              setValue({
                                ...value,
                                establishmentType: establishmentType.value,
                              })
                            }}
                          />
                        </Col>

                        <Row>
                          <Col lg={6} md={6} sm={12}>
                            <FormText
                              required
                              disabled={loadingPosStep}
                              label={'Departamento'}
                              value={value.commercialDepartment}
                              changeValue={commercialDepartment => {
                                if (!commercialDepartment) commercialDepartment = ''
                                setValue({ ...value, commercialDepartment })
                              }}
                            />
                          </Col>
                          <Col lg={6} md={6} sm={12}>
                            <FormText
                              required
                              disabled={loadingPosStep}
                              label={'Municipio'}
                              value={value.commercialMunicipality}
                              changeValue={commercialMunicipality => {
                                if (!commercialMunicipality) commercialMunicipality = ''
                                setValue({ ...value, commercialMunicipality })
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    )}
                    <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        icon={faSave}
                        disabled={disabledCommercialSave}
                        loading={loadingPosStep}
                        onClick={() =>
                          updatePosConfig({
                            footerPhrase: '-1',
                            commercialName: value.commercialName,
                            commercialCode: value.commercialCode,
                            establishmentType:
                              value.establishmentType !== null
                                ? value.establishmentType
                                : 0,
                            commercialAddress: value.commercialAddress,
                            commercialDepartment: value.commercialDepartment,
                            commercialMunicipality: value.commercialMunicipality,
                            seeAlert: true,
                          })
                        }>
                        Guardar
                      </Button>
                    </Col>
                  </>
                )}
              </>
            )}
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalTip.show}
        centered
        onHide={() => setModalTip({ show: false, percentage: 0 })}>
        <Modal.Header closeButton>
          <Modal.Title>
            Nueva opción para {modalTip.discount ? 'descuentos' : 'propinas'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <FormText
                prepend={'%'}
                value={modalTip.percentage}
                type={'number'}
                disabled={loadingTip}
                onChange={({ target }) => {
                  let { value } = target
                  if (!value || value < 0) value = 0
                  if (value > 100) value = 100
                  setModalTip({ ...modalTip, percentage: value })
                }}
              />
            </Col>
            {!modalTip.discount && (
              <Col xl={12}>
                <Switch
                  topLabel
                  checked={modalTip.favorite}
                  label={
                    'Pre definida: Si se marca esta opción, las nuevas comandas tendran este valor de propina pre cargado'
                  }
                  changeValue={value => {
                    setModalTip({ ...modalTip, favorite: value })
                  }}
                />
              </Col>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              loading={loadingTip}
              disabled={!modalTip.percentage}
              icon={faSave}
              color={'primary'}
              onClick={() => {
                dispatch(
                  createTip(
                    modalTip.percentage,
                    modalTip.discount,
                    modalTip.favorite,
                    pos.id,
                  ),
                )
              }}>
              Guardar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal show={posItem.show} centered onHide={() => setPosItem({})}>
        <Modal.Header closeButton>
          <Modal.Title>{posItem.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <div className={'b-user-name'}>
                {posItem.id
                  ? 'Actualiza la tarifa del item'
                  : 'Añade un nuevo item para las tarifas del punto de venta'}
              </div>
            </Col>
            <Col xl={12}>
              <Select
                label={'Tipo de tarifa'}
                disabled={posItem.id || loadingItem}
                options={itemTypes.filter(it => it.posType === pos.type)}
                value={posItem.type}
                onChange={type => setPosItem({ ...posItem, type })}
                required
              />
            </Col>
            <Col xl={12}>
              <FormText
                disabled={loadingItem}
                label={'Alias'}
                value={posItem.name}
                changeValue={name => {
                  setPosItem({ ...posItem, name })
                }}
                required
              />
            </Col>
            <Col xl={12}>
              <FormText
                disabled={loadingItem}
                label={'Tarifa'}
                prependMoneySymbol
                value={posItem.price}
                type={'number'}
                changeValue={price => {
                  if (price < 0) price = 0
                  setPosItem({ ...posItem, price })
                }}
                required
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              loading={loadingItem}
              disabled={
                !posItem.type || !posItem.price || posItem.price <= 0 || !posItem.name
              }
              onClick={() => {
                if (!posItem.id) {
                  const request = {
                    type: posItem.type.value,
                    price: posItem.price,
                    name: posItem.name,
                  }
                  dispatch(onCreatePosItemType(pos.id, request))
                } else {
                  dispatch(
                    onUpdatePosItem(posItem.id, {
                      price: posItem.price,
                      name: posItem.name,
                    }),
                  )
                }
              }}>
              Guardar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default POSConfig
