import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import { setMenu } from 'src/actions/dashboard.actions'

import {
  getCurrentUserRolesAction,
  getGroups,
  getSubmodules,
  setModuleId,
} from 'src/actions/modules.actions'
import { selectSubModules } from 'src/selectors/modules.selector'

import { selectSetCurrentCompany } from 'src/selectors/company.selector'

import Dashboard from '../Dashboard/Dashboard'

import CotizapCompanies from '../Admin/CotizapCompanies/CotizapCompanies'
import CotizapClients from '../Admin/GlobalClients/CotizapClients'
import EditCompany from '../Admin/EditCompany/EditCompany'

import Permissions from '../Permissions/Dashboard/Permissions'

import NewCompany from './CotizapCompanies/CreateCompany/CreateCompany'
import { saveCurrentModule } from 'src/actions/user.actions'
import BIPayments from './Payments/BI/BIPayments'
import PrintDocuments from './Documents/PrintDocuments'
import DocumentRequestDetails from './Documents/DocumentRequestDetails'
import UpdatePermissions from '../Permissions/UpdatePermissions/UpdatePermissions'
import DepositsAdmin from './Payments/Deposits/DepositsAdmin'
import GranularPermission from '../Permissions/Granular/GranularPermission'

import DisbursementsAdmin from './Disbursements/DisbursementsAdmin'
import ReportsDashboard from '../Reports/Dashboard/ReportsDashboard'
import Products from './Reports/Products/Products'
import Authorizations from './Authorizations/Authorizations'
import CategorizationCreate from '../Production/Categorization/Create/CategorizationCreate'
import CategorizationDetail from '../Production/Categorization/Detail/CategorizationDetail'
import Categorization from '../Production/Categorization/Categorization'
import ProductDetails from '../Products/ProductDetails'
import RequestDashboard from './RequestDashboard/RequestDashboard'
import CSVLoader from './CSVLoader/CSVLoader'
import Modules from './Modules/Modules'
import ProductsList from '../Products/Items'

import Bank from '../Company/Banks/BanksV2'

import Polygon from '../Polygons/Polygons'
import PolygonCreate from '../Polygons/NewPolygon/NewPolygon2'

import Offer from '../Production/Offers/Offers'
import OffersDetails from '../Production/Offers/OffersDetails/OffersDetails'
import KoloBalance from './KoloBalance/KoloBalance'

import KoloBalanceAdmin from '../Balance/new/BalanceKoloAdmin'
import Parcel from '../Parcel/Parcel'

import PaymentRequest from './PaymentRequest/PaymentRequest'
import KoloLinkAdmin from '../KoloLink/KoloLinkAdmin'
import Issues from './Issues/Issues'
import DelivererReport from './Reports/Deliverers/DelivererReport'
import OrderDetailPage from '../Orders/OrderDetail/OrderDetailPage'
import Invoices from '../Orders/Invoices/Invoices'
import OrdersPage from '../Orders/OrdersPage'
import Subscription from '../Subscription/Subscription'
import InformationV2 from './Information/InformationV2'
import CreateInformation from './Information/CreateInformation'

import Mentor from './Mentor/Mentor'
import PlanCheckout from 'src/content/Distrbution/Plans/PlanCheckout'
import UserSpaceCheckout from 'src/content/Distrbution/Plans/UserSpaceCheckout'
import { FelDealers } from 'src/content/Admin/FELDealers/FelDealers'

class AdminRoutes extends Component {
  componentDidMount() {
    this.props.setModuleId({ id: 11000, title: 'Administración Cotizap' })
    // this.props.getSubmodules(11000);
    this.props.getGroups(1000)
    this.props.saveCurrentModule()
    this.props.rolesAction()
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { submodules, setMenu, match, company } = this.props
    /*if (submodules !== next.submodules) {
            let menu = [{id: 11000, text: "Menu Administración", icon: ClipboardIcon, link: `${match.url}`}];
            next.submodules.forEach(subModule => {
                menu.push({id: subModule.id, text: subModule.name, icon: subModule.alterIcon, link: subModule.route, show: subModule.showIcon});
            });
            setMenu(menu);
        }*/
    this.props.setModuleId({
      id: 11000,
      title: `Administración Cotizap - ${company.name || ''}`,
    })
  }

  render() {
    const { match } = this.props
    return (
      <Switch>
        <Route path={`${match.url}/mentores`} component={Mentor} />

        <Route path={`${match.url}/soporte`} component={Issues} />

        <Route path={`${match.url}/enlaces`} component={KoloLinkAdmin} />

        <Route path={`${match.url}/cobros`} component={PaymentRequest} />

        <Route path={`${match.url}/empresas/editar/:company`} component={EditCompany} />
        <Route path={`${match.url}/empresas/nueva`} component={NewCompany} />
        <Route
          path={`${match.url}/empresas/suscripciones/detalle`}
          component={Subscription}
        />
        <Route path={`${match.url}/empresas`} component={CotizapCompanies} />

        <Route
          path={`${match.url}/permisos/:module/:user/:name`}
          component={UpdatePermissions}
        />
        <Route
          path={`${match.url}/permisos/:module/matriz`}
          component={GranularPermission}
        />
        <Route path={`${match.url}/permisos/:module`} component={Permissions} />

        <Route path={`${match.url}/pagos/bi/depositos`} component={DepositsAdmin} />
        <Route path={`${match.url}/pagos/bi`} component={BIPayments} />

        <Route
          path={`${match.url}/ordenes/:linked/detalle/:id`}
          component={OrderDetailPage}
        />
        <Route
          path={`${match.url}/ordenes/:linked/invoices/:orderId/:number`}
          component={Invoices}
        />
        <Route
          path={`${match.url}/ordenes/:linked/invoices/:orderId`}
          component={Invoices}
        />
        <Route path={`${match.url}/ordenes/:linked`} component={OrdersPage} />

        <Route path={`${match.url}/clientes`} component={CotizapClients} />

        <Route path={`${match.url}/information/nueva`} component={CreateInformation} />
        <Route path={`${match.url}/information`} component={InformationV2} />

        <Route path={`${match.url}/reports/products`} component={Products} />
        <Route path={`${match.url}/reports/entregas`} component={DelivererReport} />
        <Route path={`${match.url}/reports`} component={ReportsDashboard} />

        <Route
          path={`${match.url}/categorizaciones/nuevo`}
          component={CategorizationCreate}
        />
        <Route
          path={`${match.url}/categorizaciones/detalle/:id`}
          component={CategorizationDetail}
        />
        <Route path={`${match.url}/categorizaciones`} component={Categorization} />

        <Route path={`${match.url}/productos/detalles/:id`} component={ProductDetails} />
        <Route path={`${match.url}/productos`} component={ProductsList} />

        <Route path={`${match.url}/modules`} component={Modules} />

        <Route
          path={`${match.url}/solicitudes/documentos/detalles/:id`}
          component={DocumentRequestDetails}
        />
        <Route
          path={`${match.url}/solicitudes/desembolsos`}
          component={DisbursementsAdmin}
        />
        <Route path={`${match.url}/solicitudes/documentos`} component={PrintDocuments} />
        <Route path={`${match.url}/solicitudes/productor`} component={Authorizations} />
        <Route path={`${match.url}/solicitudes`} component={RequestDashboard} />

        <Route path={`${match.url}/csv`} component={CSVLoader} />

        <Route path={`${match.url}/bancos`} component={Bank} />

        <Route
          path={`${match.url}/poligonos/editar/:polygon`}
          component={PolygonCreate}
        />
        <Route path={`${match.url}/poligonos/nuevo`} component={PolygonCreate} />
        <Route path={`${match.url}/poligonos`} component={Polygon} />

        <Route path={`${match.url}/kolo/detalles/:id`} component={OffersDetails} />
        <Route path={`${match.url}/kolo/balances`} component={KoloBalance} />
        <Route path={`${match.url}/kolo`} component={Offer} />

        <Route path={`${match.url}/balance`} component={KoloBalanceAdmin} />

        <Route path={`${match.url}/desembolsos`} component={PaymentRequest} />

        <Route path={`${match.url}/paqueteria`} component={Parcel} />

        <Route
          path={`${match.url}/checkout/:planId/:companyId`}
          component={PlanCheckout}
        />
        <Route
          path={`${match.url}/user-space-checkout/:companyId`}
          component={UserSpaceCheckout}
        />
        <Route path={`${match.url}/distribuidores/fel`} component={FelDealers} />
        <Route path={``} component={Dashboard} />
      </Switch>
    )
  }
}

const mapStateToProps = state => ({
  submodules: selectSubModules(state),
  company: selectSetCurrentCompany(state),
})

const mapDispatchToProps = dispatch => ({
  setMenu: routes => dispatch(setMenu(routes)),
  getGroups: module => dispatch(getGroups(module)),
  setModuleId: module => dispatch(setModuleId(module)),
  getSubmodules: module => dispatch(getSubmodules(module)),
  saveCurrentModule: () => dispatch(saveCurrentModule(11000)),
  rolesAction: () => dispatch(getCurrentUserRolesAction(11000)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminRoutes)
