import './Plans.scss'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'

import { Col, ProgressBar, Row } from 'react-bootstrap'
import { history } from 'src/App'

import { loadingSelector } from 'src/selectors/loading.selector'
import { Button, Card, FormText, Icon, InfoNit, Select } from 'src/components'
import {
  handlerError,
  handlerInfo,
  handlerSuccess,
  hasErrors,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'
import Alert from 'sweetalert-react'
import { faAddressBook, faIdCard, faSave } from '@fortawesome/free-solid-svg-icons'

import { getGroups } from 'src/actions/modules.actions'

import { actions as typeA, getCards } from 'src/actions/account.actions'
import {
  selectSetCurrentCompany,
  selectInvoicePackages,
} from 'src/selectors/company.selector'

import { actionTypes as typesC, getInfoNIT } from 'src/actions/clients.actions'

import CreditCard from 'src/components/CreditCard/CreditCard'
import useDebounce from 'src/hooks/useDebounce'

import { valNit } from 'src/selectors/utilities.selector'
import { selectNITinfo } from 'src/selectors/clients.selector'
import { ReCaptcha } from 'src/components/ReCaptcha/ReCaptcha'
import { formatNumberWithCommas } from 'src/utils/formatters'
import { getCurrentCompany } from 'src/actions/company.actions'
import {
  actionTypes as companyActions,
  buyInvoicePackages,
  buyInvoicePackagesRequest,
} from 'src/actions/company.actions'
import { getAllRegions } from 'src/actions/address.actions'
import { selectAllRegionsForSelect } from 'src/selectors/address.selector'

const Option = props => {
  const { className, cx, isDisabled, isFocused, isSelected, innerRef, innerProps } = props
  const card = props.data

  return (
    <div
      ref={innerRef}
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected,
        },
        className,
      )}
      {...innerProps}>
      <CreditCard {...card} style={{ backgroundColor: 'transparent' }} noDelete />
    </div>
  )
}

const InvoicePackageCheckout = ({ match }) => {
  const invoicePackageId = Number(match.params?.invoicePackageId)

  const dispatch = useDispatch()

  const invoicePackages = useSelector(selectInvoicePackages)
  const invoicePackage = invoicePackages?.find(p => p.id === invoicePackageId) || {}
  const localities = useSelector(selectAllRegionsForSelect)
  // const invoicePackage = {
  //   id: 1,
  //   name: null,
  //   invoices: 200,
  //   price: 500,
  //   available: true,
  //   bestValue: false,
  // }

  const company = useSelector(selectSetCurrentCompany)
  const loadingCompany = useSelector(state =>
    loadingSelector([typesC.CURRENT_COMPANY])(state),
  )

  const cards = useSelector(state => state.account.cards).map(c => ({
    ...c,
    label: `${c.alias ? `${c.alias} - ` : ''}${c.number}`,
    value: c.id,
  }))
  const loadingCards = useSelector(state => loadingSelector([typeA.GET_CARDS])(state))

  const loadingNit = useSelector(state => loadingSelector([typesC.GET_NIT_INFO])(state))
  const hasErrorNit = useSelector(state =>
    hasErrorsSelector([typesC.GET_NIT_INFO])(state),
  )
  const infoNIT = useSelector(selectNITinfo)

  const loading = useSelector(state =>
    loadingSelector([companyActions.GET_INVOICE_PACKAGES])(state),
  )

  const buyLoading = useSelector(state =>
    loadingSelector([companyActions.BUY_INVOICE_PACKAGES])(state),
  )
  const buyHasError = useSelector(state =>
    hasErrors([companyActions.BUY_INVOICE_PACKAGES])(state),
  )

  const loadingCC = useSelector(state => loadingSelector([typeA.CREATE_CARD])(state))
  const hasErrorCC = useSelector(state => hasErrorsSelector([typeA.CREATE_CARD])(state))
  const errorCC = useSelector(state => singleErrorSelector([typeA.CREATE_CARD])(state))

  const loadingCD = useSelector(state => loadingSelector([typeA.DELETE_CARD])(state))
  const hasErrorCD = useSelector(state => hasErrorsSelector([typeA.DELETE_CARD])(state))
  const errorCD = useSelector(state => singleErrorSelector([typeA.DELETE_CARD])(state))

  const [action, setAction] = useState({
    change: false,
    cards: false,
  })
  const [card, setCard] = useState(cards.find(c => c.favorite) || {})
  const [name, setName] = useState(company.name)
  const [nit, setNit] = useState(company.nit)
  const [address, setAddress] = useState(company.address)
  const [postalCode, setPostalCode] = useState('')
  const [locality, setLocality] = useState({})
  const [valueCaptcha, setValueCaptcha] = useState(null)

  const [alert, setAlert] = useState({ show: false })

  useEffect(() => {
    dispatch(getCards())
    if (!localities.length) dispatch(getAllRegions())
  }, [])

  useEffect(() => {
    if (loadingCompany) setAction({ ...action, company: true })
    else if (action.company) {
      setAction({ ...action, company: false })
      setName(company.name)
      setNit(company.nit)
      setAddress(company.address)
    }
  }, [loadingCompany])

  useEffect(() => {
    if (loadingCards) setAction({ ...action, card: true })
    else if (action.card) {
      setAction({ ...action, card: false })
      setCard(cards.find(c => c.favorite) || {})
    }
  }, [loadingCards])

  useEffect(() => {
    if (buyLoading) setAction({ ...action, change: true })
    else if (action.change) {
      setAction({ ...action, change: false })
      if (buyHasError) {
        setAlert({
          ...handlerError(buyHasError.message || 'No se pudo realizar el pago'),
          onConfirm: () => setAlert({ show: false }),
        })
      } else {
        setAlert({
          ...handlerSuccess('Se ha comprado el paquete de facturas exitosamente'),
          onConfirm: () => {
            dispatch(getGroups(1000))
            dispatch(getCurrentCompany())
            setAlert({ show: false })
            history.push('/perfil')
          },
        })
      }
    }
  }, [buyLoading])

  useEffect(() => {
    if (loadingCC) setAction({ ...action, cardCreate: true })
    else if (action.cardCreate) {
      setAction({ ...action, cardCreate: false })
      if (hasErrorCC)
        setAlert({
          ...handlerError(errorCC.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Tarjeta de crédito registrada satisfactoriamente'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            dispatch(getCards())
          },
        })
    }
  }, [loadingCC])

  useEffect(() => {
    if (loadingCD) setAction({ ...action, cardDelete: true })
    else if (action.cardDelete) {
      setAction({ ...action, cardDelete: false })
      if (hasErrorCD)
        setAlert({
          ...handlerError(errorCD.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Tarjeta de crédito eliminada satisfactoriamente'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            dispatch(getCards())
          },
        })
    }
  }, [loadingCD])

  useEffect(() => {
    if (loadingNit) setAction({ ...action, search: true })
    else if (action.search) {
      if (hasErrorNit) {
        setAlert({
          ...handlerError('No se pudo obtener la información con el NIT indicado'),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      } else setName(infoNIT.clientName)
    }
  }, [loadingNit])

  const handleNitSearch = useDebounce(nit => {
    if (!nit || nit === 'CF' || !valNit(nit)) return
    dispatch(getInfoNIT(nit))
  }, 1000)

  const changeNIT = ({ target }) => {
    const { value } = target
    setNit(value)
    if (value.toUpperCase() !== 'CF' && valNit(value)) handleNitSearch(value)
  }

  return (
    <div className="checkout-base">
      <h1>Comprar paquete de facturas</h1>
      {loading ? (
        <Row>
          <Col>
            <div className={'pb-custom'}>
              <ProgressBar
                label="Cargando"
                animated
                now={100}
                style={{ marginBottom: 10 }}
              />
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <Row style={{ marginBottom: 20 }}>
            <Col xs={12} md={12} lg={12} xl={6} style={{ padding: 10 }}>
              <Card
                style={{
                  height: '100%',
                }}>
                <h2>
                  {invoicePackage.name || `Paquete de facturas #${invoicePackage.id}`}
                </h2>
                <Row className="space-between" style={{ margin: '20px 0' }}>
                  Documentos electrónicos incluidos:
                  <span style={{ fontWeight: 700 }}>{invoicePackage.invoices}</span>
                </Row>
                <Row className="space-between" style={{ margin: '20px 0 0' }}>
                  Precio:
                  <span style={{ fontWeight: 700 }}>
                    Q.{formatNumberWithCommas(invoicePackage.price)}
                  </span>
                </Row>
                <Row className="space-between" style={{ margin: '20px 0 0' }}>
                  Precio por DTE:
                  <span style={{ fontWeight: 700 }}>
                    Q.
                    {formatNumberWithCommas(
                      invoicePackage.price / invoicePackage.invoices,
                    )}
                  </span>
                </Row>
              </Card>
            </Col>
            <Col xs={12} md={12} lg={12} xl={6} style={{ padding: 10 }}>
              <Card
                style={{
                  height: '100%',
                }}>
                <h2>Método de pago</h2>
                <Row style={{ alignItems: 'center', marginBottom: 30 }}>
                  <Col xl={8} lg={8} md={6} sm={6} xs={12}>
                    <Select
                      withoutLabel
                      disabled={loadingCards}
                      options={cards}
                      renderOption={Option}
                      value={card}
                      onChange={value => setCard(value)}
                    />
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <FormText
                      label={'Código postal'}
                      placeholder={'Ingrese el código postal de la tarjeta'}
                      type={'number'}
                      name={'postalCode'}
                      value={postalCode}
                      onChange={ev => setPostalCode(ev.target.value)}
                      max={10}
                    />
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Select
                      label={'Departamento'}
                      placeholder={'Ingrese el departamento de la tarjeta'}
                      options={localities}
                      value={locality}
                      onChange={value => setLocality(value)}
                    />
                  </Col>
                </Row>
                <h2>Facturación</h2>
                <Row style={{ alignItems: 'flex-end', marginBottom: 40 }}>
                  <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                    <InfoNit
                      type={'text'}
                      name={'nit'}
                      prepend={<Icon icon={faIdCard} spin={loadingNit} />}
                      nit={nit}
                      disabled={loadingNit}
                      required={false}
                      noValidate
                      onChange={ev => {
                        changeNIT(ev)
                      }}
                    />
                  </Col>
                  <Col xl={8} lg={8} md={6} sm={6} xs={12}>
                    <FormText
                      label={'Nombre'}
                      placeholder={'Ingrese el nombre para la factura'}
                      type={'text'}
                      name={'name'}
                      value={name}
                      onChange={ev => setName(ev.target.value)}
                    />
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <FormText
                      label={'Dirección'}
                      placeholder={'Ingrese la dirección para la factura'}
                      type={'text'}
                      name={'address'}
                      value={address}
                      onChange={ev => setAddress(ev.target.value)}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row
            className={'container-buttons'}
            style={{
              alignItems: 'center',
              gap: 20,
            }}>
            <ReCaptcha onChange={value => setValueCaptcha(value)} />
            <Button
              color={'secondary'}
              disabled={valueCaptcha !== null}
              icon={faAddressBook}
              loading={buyLoading}
              onClick={() => {
                dispatch(buyInvoicePackagesRequest(invoicePackageId, { contact: true }))
                setAlert({
                  ...handlerInfo(
                    '',
                    'Hemos mandado tu solicitud a un asesor. Pronto te daremos respuesta.',
                  ),
                  confirmButtonText: 'Cerrar',
                  onConfirm: () => {
                    setAlert({ show: false })
                  },
                })
              }}>
              Contactar Mentor
            </Button>
            <Button
              color={'primary'}
              disabled={
                !card.id || !postalCode || !locality.value || valueCaptcha === null
              }
              icon={faSave}
              loading={buyLoading}
              onClick={() => {
                setAlert({
                  ...handlerInfo(
                    '¿Está seguro que desea adquirir este paquete de facturas?',
                    `Se cobrarán Q.${formatNumberWithCommas(
                      invoicePackage.price,
                    )} a tu método de pago.`,
                  ),
                  showCancelButton: true,
                  confirmButtonText: 'Comprar',
                  cancelButtonText: 'Cancelar',
                  onConfirm: () => {
                    setAlert({
                      ...handlerInfo('Realizando el cobro', 'Espera un momento'),
                      showCancelButton: false,
                      showConfirmButton: false,
                    })

                    const request = {
                      name,
                      nit,
                      address,
                      postalCode,
                      locality: locality.value,
                      cardId: card.id,
                    }

                    dispatch(buyInvoicePackages(invoicePackageId, request))
                  },
                  onCancel: () => {
                    setAlert({ show: false })
                  },
                })
              }}>
              Comprar paquete
            </Button>
          </Row>
        </>
      )}

      <Alert {...alert} />
    </div>
  )
}

export default InvoicePackageCheckout
