import React from 'react';
import {connect} from "react-redux";
import {Row, Col} from "react-bootstrap";
import Card from "../../../components/cards/Card";
import {acceptAuth, onGetAuthByState, rejectAuth, types} from "../../../actions/authorization.actions";
import {selectAllAuthorizations} from "../../../selectors/authorization.selector";
import IconButton from "../../../components/buttons/IconButton";
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {loadingSelector} from "../../../selectors/loading.selector";
import {hasErrorsSelector, singleErrorSelector} from "../../../selectors/error.selector";
import Alert from "sweetalert-react";
import { TableV2 } from '../../../components';

class Authorizations extends React.Component {

    state = {
        accepted: false,
        pending: true,
        rejected: false,
        canceled: false,
        alert: {
            show: false,
            title: 'Default',
            text: 'default',
            type: 'info'
        },
    }

    componentDidMount() {
        this.props.onGetAuthByState(4)
    }

    componentWillReceiveProps(next) {
        const {loadingAccept, loadingReject, acceptHasError, rejectHasError} = this.props
        const {rejectError, acceptError} = next
        let {alert} = this.state

        if(loadingAccept && !next.loadingAccept){
            if(!acceptHasError && next.acceptHasError){
                alert = this.handlerError(acceptError.response ? acceptError.response.data.message : 'Ha ocurrido un error inesperado.');
            }
            else{
                alert = this.handlerSuccess('Solicitud Aceptada');
                this.props.onGetAuthByState(4)
            }
            this.setState({alert});
        }

        if(loadingReject && !next.loadingReject){
            if(!rejectHasError && next.rejectHasError){
                alert = this.handlerError(rejectError.response ? rejectError.response.data.message : 'Ha ocurrido un error inesperado.');
            }
            else{
                alert = this.handlerSuccess('Solicitud Rechazada');
                this.props.onGetAuthByState(4)
            }
            this.setState({alert});
        }
    }

    handlerError  = (message) => {
        return {show: true, title: 'Uh-Oh..', text: message, type: 'warning'};
    };

    handlerSuccess = (message) => {
        return {show: true, title: 'Yeah!', text: message, type: 'success'};
    };

    acceptAuth = (id) =>{
        this.props.acceptAuth(id);
    }

    rejectAuth = (id) =>{
        this.props.rejectAuth(id);
    }

    getHeaders = () => {
        return [
            {label: 'Usuario', show: true, value: ['user','name'], type: 'text' ,  className:'medium'},
            {label: 'Compañía', show: true, value: ['company','name'], type: 'text' ,  className:'medium'},
            {label: 'Estado', show: true, value: ['statusAuth','name'], type: 'text' ,  className:'medium'},
            {label: 'Tipo de solicitud', show: true, value: ['typeAuth','name'], type: 'text' ,  className:'medium'},
            {config:true, show: true, label:'', className:'mini center',
            custom:(item)=>{
                const {pending} = this.state
                return(
                    pending &&
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <IconButton icon={faCheckCircle} color={'green'} tooltip={'Aceptar'} onClick={ () => this.acceptAuth(item.id)}/>
                        <IconButton icon={faTimesCircle} color={'red'} tooltip={'Rechazar'} onClick={() => this.rejectAuth(item.id)}/>
                    </div>
                )
            } }

        ]
    }
    handleRadioChange = (event, id) =>{
        const {name, checked} = event.target;
        this.setState({accepted: false, rejected: false, canceled: false, pending: false,[name]: checked});
        this.props.onGetAuthByState(id)
    }

    render() {
        const {accepted, canceled, rejected, pending} = this.state
        const {authorizations} = this.props
        return (
            <Row>
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Card title={'Filtros por estado'}>
                        <Row>
                            <Col>
                                <label className={'checkbox-label'}>
                                    <input name="accepted" checked={accepted} onChange={(e) => this.handleRadioChange(e, 1)} type={'radio'}/>Aceptadas
                                </label>
                            </Col>
                            <Col>
                                <label className={'checkbox-label'}>
                                    <input name="rejected" checked={rejected} onChange={(e) => this.handleRadioChange(e, 2)} type={'radio'}/>Rechazadas
                                </label>
                            </Col>
                            <Col>
                                <label className={'checkbox-label'}>
                                    <input name="pending" checked={pending} onChange={(e) => this.handleRadioChange(e, 4)} type={'radio'}/>Pendientes
                                </label>
                            </Col>
                            <Col>
                                <label className={'checkbox-label'} >
                                    <input name="canceled" checked={canceled} onChange={(e) => this.handleRadioChange(e, 3)} type={'radio'}/>Canceladas
                                </label>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Card title={'Autorizaciones'}>
                        <TableV2
                            headers={this.getHeaders()}
                            items={authorizations}
                            mobileAuto
                            storageKey={`authorization`}
                        />
                    </Card>
                </Col>

                <Alert {...alert} onConfirm={() => this.setState({alert: {...alert, show: false}})}/>
            </Row>
        );
    }
}


const mapStateToProps = (state) => ({
    authorizations: selectAllAuthorizations(state),
    loadingAccept: loadingSelector([types.ON_ACCEPT_AUTH])(state),
    acceptHasError: hasErrorsSelector([types.ON_ACCEPT_AUTH])(state),
    acceptError: singleErrorSelector([types.ON_ACCEPT_AUTH])(state),
    loadingReject: loadingSelector([types.ON_REJECT_AUTH])(state),
    rejectHasError: hasErrorsSelector([types.ON_REJECT_AUTH])(state),
    rejectError: singleErrorSelector([types.ON_REJECT_AUTH])(state),
});

const mapDispatchToProps = dispatch => ({
    onGetAuthByState: (status) => dispatch(onGetAuthByState(status)),
    acceptAuth: (id) => dispatch(acceptAuth(id)),
    rejectAuth: (id) => dispatch(rejectAuth(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Authorizations);
