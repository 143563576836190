import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { Col } from 'react-bootstrap'
import { FormText, Icon, Select, Switch } from 'src/components'
import { toMoney } from 'src/utils/utilities'

interface Props {
  item: IItemSelectTable
  discounts: ISelect[]
  disabled: boolean
  onChangePercentDiscount: (item: IItemSelectTable, value: ISelect) => void
  onChangeDiscount: (item: IItemSelectTable, discount: number) => void
  mobile?: boolean
}

/**
 *
 * @param {IItemSelectTable} item // Item de la tabla
 * @param {ISelect[]} discounts // Descuentos
 * @param {boolean} disabled // Deshabilitado
 * @param {Function} onChangePercentDiscount // Cambio de descuento por porcentaje
 * @param {Function} onChangeDiscount // Cambio de descuento normal
 * @param {boolean} mobile // Si es mobile
 * @returns
 */
export const ItemDiscount = ({
  item,
  discounts,
  disabled,
  onChangeDiscount,
  onChangePercentDiscount,
  mobile,
}: Props) => {
  return (
    <>
      {mobile && (
        <Col xl={12} xs={12} md={12}>
          <label className="ftf-form-label">Descuento</label>
        </Col>
      )}
      <div className={'d-flex justify-between'} style={{ width: '100%' }}>
        {mobile && (
          <Col xl={2} xs={2} md={2}>
            <Switch
              mt={0}
              disabled={disabled}
              id={'discountPercent'}
              cStyle={{ alignSelf: 'flex-end' }}
              name={'checkDiscount'}
              checked={item.percentDiscount}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                item.percentDiscount = e.target.checked
                if (e.target.checked)
                  onChangePercentDiscount(item, { label: '0%', value: 0 })
                else onChangeDiscount(item, 0)
              }}
              label={'%'}
              topLabel
            />
          </Col>
        )}
        <Col xl={10} xs={10} md={10}>
          {item.percentDiscount ? (
            <>
              <Select
                mt={0}
                style={{ width: mobile ? '' : 90, flex: 1 }}
                value={item.discountPercentSelected}
                withoutLabel
                onChange={value => {
                  onChangePercentDiscount(item, value)
                }}
                options={discounts}
                error={item.error}
                disabled={disabled}
              />
              <label>{toMoney(item.discount || 0)}</label>
            </>
          ) : (
            <FormText
              style={{ width: mobile ? '' : 90 }}
              mt={1}
              disabled={item.value === undefined || disabled}
              type={'number'}
              value={item.percentDiscount ? item.discountPercentSelected : item.discount}
              onChange={({ target }) => {
                onChangeDiscount(item, Number(target.value))
              }}
              error={item.error}
              prepend={
                mobile ? (
                  <Icon
                    icon={faMinus}
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      onChangeDiscount(item, (item.discount ? item.discount : 0) - 1)
                    }}
                  />
                ) : undefined
              }
              append={
                mobile ? (
                  <Icon
                    icon={faPlus}
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      onChangeDiscount(item, (item.discount ? item.discount : 0) + 1)
                    }}
                  />
                ) : undefined
              }
            />
          )}
        </Col>
        {!mobile && (
          <Col xl={2} xs={2} md={2}>
            <div className="column">
              <label>
                <b> %</b>
              </label>
              <input
                disabled={disabled}
                type="checkbox"
                id="idchk"
                name="chk"
                checked={item.percentDiscount}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  item.percentDiscount = e.target.checked
                  if (e.target.checked)
                    onChangePercentDiscount(item, { label: '0%', value: 0 })
                  else onChangeDiscount(item, 0)
                }}
              />
            </div>
          </Col>
        )}
      </div>
    </>
  )
}
