import MentorService from 'src/services/mentor.service'
import { executeAction } from './global.actions'

export const actionTypes = {
  GET_MENTOR_COMPANIES: 'GET_MENTOR_COMPANIES',
  GET_MENTOR_COMPANIES_EXCEL: 'GET_MENTOR_COMPANIES_EXCEL',
  UPDATE_MENTOR_PLAN: 'UPDATE_MENTOR_PLAN',
  GET_SINGLE_COMPANY_MENTOR: 'GET_SINGLE_COMPANY_MENTOR',
  CREATE_OR_UPDATE_COMPANY: 'CREATE_OR_UPDATE_COMPANY',
  UPDATE_FINISH_DATE_DEMO: 'UPDATE_FINISH_DATE_DEMO',
  GET_COMPANY_ADDONS: 'GET_COMPANY_ADDONS',
  ADD_ADDONS: 'ADD_ADDONS',
  ON_VALIDATE_UPDATE_ADDON: 'ON_VALIDATE_UPDATE_ADDON',
  ON_UPDATE_ADDON: 'ON_UPDATE_ADDON',
  ON_FINISH_DEMO_ACCOUNT: 'ON_FINISH_DEMO_ACCOUNT',
  ON_POSTPONE_PAYMENT_INVOICE: 'ON_POSTPONE_PAYMENT_INVOICE',
  GET_COMPANY_CONFIGURATION_DATA: 'GET_COMPANY_CONFIGURATION_DATA',
  UPDATE_COMPANY_CONFIGURATION_DATA: 'UPDATE_COMPANY_CONFIGURATION_DATA',
  GET_PLAN_ORDERS: 'GET_PLAN_ORDERS',
  GET_PLAN_ORDER_DETAILS: 'GET_PLAN_ORDER_DETAILS',
  GET_CLIENT_BY_COTIZAP_CLIENT: 'GET_CLIENT_BY_COTIZAP_CLIENT',
  GET_COMPANY_ACTIVITIES: 'GET_COMPANY_ACTIVITIES',
  GET_COMPANY_ACTIVITIES_EXCEL: 'GET_COMPANY_ACTIVITIES_EXCEL',
  GET_COMPANY_INVOICE_EXPIRED: 'GET_COMPANY_INVOICE_EXPIRED',
}

/**
 * @function Obtains a list of filtered companies
 * @param {MFilterParams} params Filter parameters
 * */
export const getCompanies = (params: MFilterParams) => dispatch => {
  const process = async () => ({
    payload: await MentorService.getCompanies(params),
  })
  dispatch(executeAction(actionTypes.GET_MENTOR_COMPANIES, process))
}

/**
 * @function Obtains a excel of filtered companies
 * @param {MFilterParams} params Filter parameters
 * */
export const getCompaniesExcel = (params: MFilterParams) => dispatch => {
  const process = async () => ({
    payload: await MentorService.getCompaniesExcel(params),
  })
  dispatch(executeAction(actionTypes.GET_MENTOR_COMPANIES_EXCEL, process))
}

/**
 * @function Upgrade account plan immediately
 * @param {number} companyId Id of the company to update plan
 * @param {number} planId New Plan
 * @param {number} planPrice Price of new plan
 * */
export const updatePlanCompany =
  (companyId: number, planId: number, planPrice?: number) => dispatch => {
    const process = async () => ({
      payload: await MentorService.updatePlanCompany(companyId, planId, planPrice),
    })
    dispatch(executeAction(actionTypes.UPDATE_MENTOR_PLAN, process))
  }

/**
 * @function Create a company with DEMO status
 * @param {MCompanyData} request Request with the information of the new company.
 * */
export const createDemoAccount = (request: MCompanyData) => dispatch => {
  const process = async () => ({
    payload: await MentorService.createDemoAccount(request),
  })
  dispatch(executeAction(actionTypes.CREATE_OR_UPDATE_COMPANY, process))
}

/**
 * @function Check basic company information
 * @param {number} companyId Id of the company to consult
 * */
export const getCompany = (companyId: number) => dispatch => {
  const process = async () => ({
    payload: await MentorService.getCompany(companyId),
  })
  dispatch(executeAction(actionTypes.GET_SINGLE_COMPANY_MENTOR, process))
}

/**
 * @function Update basic account information
 * @param {number} companyId Company ID to edit
 * @param {MCompanyData} request Request with the information to update
 * */
export const updateAccount = (companyId: number, request: MCompanyData) => dispatch => {
  const process = async () => ({
    payload: await MentorService.updateAccount(companyId, request),
  })
  dispatch(executeAction(actionTypes.CREATE_OR_UPDATE_COMPANY, process))
}

/**
 * @function Update account demo end date or plan start date
 * @param {number} companyId Id of the company to which the date will be updated
 * @param {number} date New Date
 * */
export const updateDateAccount = (companyId: number, date: number) => dispatch => {
  const process = async () => await MentorService.updateDateAccount(companyId, date)
  dispatch(executeAction(actionTypes.UPDATE_FINISH_DATE_DEMO, process))
}

/**
 * @function Obtains a list of the company's active addons
 * @param {number} companyId Id of the company to consult the addons
 * */
export const getCompanyAddons = (companyId: number) => dispatch => {
  const process = async () => ({
    payload: await MentorService.getCompanyAddons(companyId),
  })
  dispatch(executeAction(actionTypes.GET_COMPANY_ADDONS, process))
}

/**
 * @function Add new addons to the company or create a request and sales order for operating companies
 * @param {number} companyId Id of the company to add the new addons
 * @param {MCompanyAddons[]} request List of addons to add or update
 * */
export const addNewAddons =
  (companyId: number, request: MCompanyAddons[]) => dispatch => {
    const process = async () => await MentorService.addNewAddons(companyId, request)
    dispatch(executeAction(actionTypes.ADD_ADDONS, process))
  }

/** Valid if the company can reduce its addons without having to remove entities
 * @param {number} companyId Id of the company to validate
 * @param {MCompanyAddonsEntityResponse[]} request List of addons to validate
 * */
export const onValidateUpdateAddon =
  (companyId: number, request: MCompanyAddonsEntityResponse[]) => dispatch => {
    const process = async () => ({
      payload: await MentorService.onValidateUpdateAddon(companyId, request),
    })
    dispatch(executeAction(actionTypes.ON_VALIDATE_UPDATE_ADDON, process))
  }

/** Update addons to the company or create a request and sales order for operating companies
 * @param {number} companyId Id of the company to add the new addons
 * @param {MCompanyAddons[]} request List of addons to update
 * */
export const updateAddons =
  (companyId: number, request: MCompanyAddons[]) => dispatch => {
    const process = async () => await MentorService.updateAddons(companyId, request)
    dispatch(executeAction(actionTypes.ON_UPDATE_ADDON, process))
  }

// ON_FINISH_DEMO_ACCOUNT
/** The DEMO account ends and if the mentor indicated that it should be billed, then a sales order pending collection is created
 * @param {number} companyId Id of the account to finish the DEMO
 * @param {IFinishDemoRequest} request Request with the necessary information for the activation of an account
 * */
export const onFinishDemoAccount =
  (companyId: number, request: IFinishDemoRequest) => dispatch => {
    const process = async () =>
      await MentorService.onFinishDemoAccount(companyId, request)
    dispatch(executeAction(actionTypes.ON_FINISH_DEMO_ACCOUNT, process))
  }

export const onPostponePayment = (sellId: number) => dispatch => {
  const process = async () => await MentorService.onPostponePayment(sellId)
  dispatch(executeAction(actionTypes.ON_POSTPONE_PAYMENT_INVOICE, process))
}

/** Get fields configuration by company ID
 * @function
 * @param {number} companyId ID of company
 * */
export const getCompanyConfigurationData = (companyId: number) => dispatch => {
  const process = async () => ({
    payload: await MentorService.getCompanyConfigurationData(companyId),
  })
  dispatch(executeAction(actionTypes.GET_COMPANY_CONFIGURATION_DATA, process))
}

/** Update fields configuration by company ID
 * @function
 * @param {number} companyId ID of company
 * @param {FieldCompany[]} fields Fields to update
 * */
export const updateCompanyConfigurationData =
  (companyId: number, fields: FieldCompany[]) => dispatch => {
    const process = async () =>
      await MentorService.updateCompanyConfigurationData(companyId, fields)
    dispatch(executeAction(actionTypes.UPDATE_COMPANY_CONFIGURATION_DATA, process))
  }

/** Get plan orders
 * @function
 * */
export const getPlanOrders = () => dispatch => {
  const process = async () => ({
    payload: await MentorService.getPlanOrders(),
  })
  dispatch(executeAction(actionTypes.GET_PLAN_ORDERS, process))
}

/**
 * Retrieves the detail of plan and addons from order
 * @function
 * @param {number} orderId Order id selected
 */
export const getPlanOrderDetails = (orderId: number) => dispatch => {
  const process = async () => ({
    payload: await MentorService.getPlanOrderDetails(orderId),
  })
  dispatch(executeAction(actionTypes.GET_PLAN_ORDER_DETAILS, process))
}

/**
 * Retrieves the client by cotizap client id
 * @param {number} cotizapClientId Client cotizap
 * @param {number} companyId Company id
 * @returns
 */
export const getClientByCotizapClient =
  (cotizapClientId: number, companyId: number) => dispatch => {
    const process = async () => ({
      payload: await MentorService.getClientByCotizapClient(cotizapClientId, companyId),
    })
    dispatch(executeAction(actionTypes.GET_CLIENT_BY_COTIZAP_CLIENT, process))
  }

/**
 * Get the activity of companies in app
 * @param {MFilterParamsCompanyActivity} params Filter parameters
 * */
export const getCompanyActivities =
  (params: MFilterParamsCompanyActivity) => dispatch => {
    const process = async () => ({
      payload: await MentorService.getCompanyActivities(params),
    })
    dispatch(executeAction(actionTypes.GET_COMPANY_ACTIVITIES, process))
  }

/**
 * Get the activity of companies in app
 * @param {MFilterParamsCompanyActivity} params Filter parameters
 * */
export const getCompanyActivitiesExcel =
  (params: MFilterParamsCompanyActivity) => dispatch => {
    const process = async () => ({
      payload: await MentorService.getCompanyActivitiesExcel(params),
    })
    dispatch(executeAction(actionTypes.GET_COMPANY_ACTIVITIES_EXCEL, process))
  }

export const getExpiredInvoicesReport = () => dispatch => {
  const process = async () => ({
    payload: await MentorService.getExpiredInvoicesReport(),
  })
  dispatch(executeAction(actionTypes.GET_COMPANY_INVOICE_EXPIRED, process))
}
