import AuthorizationService from '../services/authorization.service'
import { executeAction } from './global.actions'

export const types = {
  GET_AUTH: 'GET_AUTH',
  GET_AUTH_REQUEST: 'GET_AUTH_REQUEST',
  GET_AUTH_SUCCESS: 'GET_AUTH_SUCCESS',
  GET_AUTH_ERROR: 'GET_AUTH_ERROR',

  ON_SET_AUTH: 'ON_SET_AUTH',
  ON_SET_AUTH_REQUEST: 'ON_SET_AUTH_REQUEST',
  ON_SET_AUTH_SUCCESS: 'ON_SET_AUTH_SUCCESS',
  ON_SET_AUTH_ERROR: 'ON_SET_AUTH_ERROR',

  ON_ACCEPT_AUTH: 'ON_ACCEPT_AUTH',
  ON_ACCEPT_AUTH_REQUEST: 'ON_ACCEPT_AUTH_REQUEST',
  ON_ACCEPT_AUTH_SUCCESS: 'ON_ACCEPT_AUTH_SUCCESS',
  ON_ACCEPT_AUTH_ERROR: 'ON_ACCEPT_AUTH_ERROR',

  ON_REJECT_AUTH: 'ON_REJECT_AUTH',
  ON_REJECT_AUTH_REQUEST: 'ON_REJECT_AUTH_REQUEST',
  ON_REJECT_AUTH_SUCCESS: 'ON_REJECT_AUTH_SUCCESS',
  ON_REJECT_AUTH_ERROR: 'ON_REJECT_AUTH_ERROR',

  GET_ACTIVE_AUTH: 'GET_ACTIVE_AUTH',
  GET_ACTIVE_AUTH_REQUEST: 'GET_ACTIVE_AUTH_REQUEST',
  GET_ACTIVE_AUTH_SUCCESS: 'GET_ACTIVE_AUTH_SUCCESS',
  GET_ACTIVE_AUTH_ERROR: 'GET_ACTIVE_AUTH_ERROR',

  GET_OAUTH_URL: 'GET_OAUTH_URL',
  CREATE_AND_STORE_CREDENTIAL: 'CREATE_AND_STORE_CREDENTIAL',
}

const getAuthRequest = () => ({ type: types.GET_AUTH_REQUEST })
const getAuthSuccess = response => ({
  type: types.GET_AUTH_SUCCESS,
  response,
})
const getAuthError = error => ({ type: types.GET_AUTH_ERROR, error })

const onSetAuthRequest = () => ({ type: types.ON_SET_AUTH_REQUEST })
const onSetAuthSuccess = response => ({
  type: types.ON_SET_AUTH_SUCCESS,
  response,
})
const onSetAuthError = error => ({ type: types.ON_SET_AUTH_ERROR, error })

const onAcceptAuthRequest = () => ({ type: types.ON_ACCEPT_AUTH_REQUEST })
const onAcceptAuthSuccess = response => ({
  type: types.ON_ACCEPT_AUTH_SUCCESS,
  response,
})
const onAcceptAuthError = error => ({
  type: types.ON_ACCEPT_AUTH_ERROR,
  error,
})

const onRejectAuthRequest = () => ({ type: types.ON_REJECT_AUTH_REQUEST })
const onRejectAuthSuccess = response => ({
  type: types.ON_REJECT_AUTH_SUCCESS,
  response,
})
const onRejectAuthError = error => ({
  type: types.ON_REJECT_AUTH_ERROR,
  error,
})

const getActiveAuthRequest = () => ({ type: types.GET_ACTIVE_AUTH_REQUEST })
const getActiveAuthSuccess = success => ({
  type: types.GET_ACTIVE_AUTH_SUCCESS,
  success,
})
const getActiveAuthError = () => ({ type: types.GET_ACTIVE_AUTH_ERROR })

export const onGetAuthByState = status => async dispatch => {
  dispatch(getAuthRequest())
  try {
    const response = await AuthorizationService.getAuthByState(status)
    dispatch(getAuthSuccess(response))
  } catch (e) {
    dispatch(getAuthError(e))
  }
}

export const onSetAuth = () => async dispatch => {
  dispatch(onSetAuthRequest())
  try {
    const response = await AuthorizationService.setAuth()
    dispatch(onSetAuthSuccess(response))
  } catch (e) {
    dispatch(onSetAuthError(e))
  }
}

export const acceptAuth = id => async dispatch => {
  dispatch(onAcceptAuthRequest())
  try {
    const response = await AuthorizationService.acceptAuth(id)
    dispatch(onAcceptAuthSuccess(response))
  } catch (e) {
    dispatch(onAcceptAuthError(e))
  }
}

export const rejectAuth = id => async dispatch => {
  dispatch(onRejectAuthRequest())
  try {
    const response = await AuthorizationService.rejectAuth(id)
    dispatch(onRejectAuthSuccess(response))
  } catch (e) {
    dispatch(onRejectAuthError(e))
  }
}

export const getActiveAuth = () => async dispatch => {
  dispatch(getActiveAuthRequest())
  try {
    const response = await AuthorizationService.getActiveAuth()
    dispatch(getActiveAuthSuccess(response))
  } catch (e) {
    dispatch(getActiveAuthError(e))
  }
}

export const getOAuthURL = () => dispatch => {
  const process = async () => ({ oauthURL: await AuthorizationService.getOAuthURL() })
  dispatch(executeAction(types.GET_OAUTH_URL, process))
}

export const createAndStoreCredential = (state, code) => dispatch => {
  const process = async () => await AuthorizationService.createAndStoreCredential(state, code)
  dispatch(executeAction(types.CREATE_AND_STORE_CREDENTIAL, process))
}
