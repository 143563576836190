import { actionTypes } from '../actions/subscriptions.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  subscriptions: [],
  subscriptionsCompany: [],
  subscriptionsShipper: [],
  subscriptionsPolygons: [],
  subscriptionsPolygonsDistribution: [],
  subscriptionPolygon: {},
  subscriptionsClient: [],
  subscriptionsActiveClient: [],
  subscriptionActiveClient: {},
  invoices: [],
  orders: [],
}

const SubscriptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_ORDERS):
      return { ...state, orders: extract(action.orders, []) }
    case successState(actionTypes.GET_INVOICES):
      return { ...state, invoices: extract(action.invoices, []) }
    case successState(actionTypes.GET_SUBSCRIPTIONS):
      return {
        ...state,
        subscriptions: extract(action.subscriptions, []),
      }
    case successState(actionTypes.GET_SUBSCRIPTIONS_CLIENT):
      return {
        ...state,
        subscriptionsClient: extract(action.subscriptionsClient, []),
      }
    case successState(actionTypes.GET_ACTIVE_SUBSCRIPTIONS_CLIENT):
      return {
        ...state,
        subscriptionsActiveClient: extract(action.subscriptionsActiveClient, []),
      }
    case successState(actionTypes.GET_ACTIVE_SUBSCRIPTION_CLIENT):
      return {
        ...state,
        subscriptionActiveClient: extract(action.subscriptionActiveClient, {}),
      }
    case successState(actionTypes.GET_SUBSCRIPTIONS_COMPANY):
      return {
        ...state,
        subscriptionsCompany: extract(action.subscriptionsCompany, []),
      }
    case successState(actionTypes.GET_SUBSCRIPTIONS_SHIPPER):
      return {
        ...state,
        subscriptionsShipper: extract(action.subscriptionsShipper, []),
      }
    case successState(actionTypes.GET_SUBSCRIPTIONS_POLYGONS):
      return {
        ...state,
        subscriptionsPolygons: extract(action.subscriptionsPolygons, []),
      }
    case successState(actionTypes.GET_SUBSCRIPTIONS_POLYGONS_DISTRIBUTION):
      return {
        ...state,
        subscriptionsPolygonsDistribution: extract(
          action.subscriptionsPolygonsDistribution,
          [],
        ),
      }
    case successState(actionTypes.GET_SUBSCRIPTION_POLYGON):
      return {
        ...state,
        subscriptionPolygon: extract(action.subscriptionPolygon, {}),
      }
    default:
      return state ? state : initialState
  }
}

export default SubscriptionsReducer
