import { actionTypes } from '../actions/dashboard.actions'

const initialState = {
  collapsed: true,
  show: true,
  searchShow: false,
  selected: 1,
  routes: [],
  group: undefined,
}

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GROUP_SELECTED:
      return { ...state, group: action.val, searchShow: false }
    case actionTypes.MENU_SHOW:
      return {
        ...state,
        show: action.val,
      }
    case actionTypes.MENU_EXPAND:
      return {
        ...state,
        collapsed: action.val,
        searchShow: false,
      }
    case actionTypes.MENU_SELECTED:
      return {
        ...state,
        selected: action.val,
        searchShow: false,
      }
    case actionTypes.MENU_SET:
      return {
        ...state,
        routes: action.val,
      }
    case actionTypes.SET_SEARCH:
      return {
        ...state,
        searchShow: action.payload,
      }
    default:
      return state
  }
}

export default dashboardReducer
