import React, {Component} from 'react';

import {Container, Row, Col} from "react-bootstrap";
import {Td, Tr} from "react-super-responsive-table";
import {
    formatDateFromMillis,
    formatNumberWithCommas
} from "../../utils/formatters";

import { TableV2 } from '..';
import {Decimal} from "decimal.js";
import Money from '../Money/Money';
import { selectCurrentCurrency } from '../../selectors/currencies.selector';
import { connect } from 'react-redux';

class OrderSummary extends Component {

    getOrderTotal = (details, type, balace) => {
        if (type === 3) return formatNumberWithCommas((balace * -1));
        if (!details)
            return formatNumberWithCommas(0);
        let total = 0;
        details.map((d) => total += parseFloat(d.total) - parseFloat(d.discount));
        return formatNumberWithCommas(total);
    };

    getHeaders = () => {
        return [
            {label: 'Producto', show: true, value: ['name'], type: 'text' ,  className:'mini'},
            {label: 'Productor', show: true, value: ['companyName'], type: 'text' ,  className:'mini'},
            {label: 'Cantidad', show: true, value: ['quantity'], type: 'text' ,  className:'mini'},
            {label: `Precio `, show: true, value: ['precio'], type: 'currency' ,  className:'mini', custom:(item) => item.precio = item.subTotal / item.quantity || 0 },
            {label: `Descuento `, show: true, value: ['discount'], type: 'currency' ,  className:'mini'},
            {label: 'Subtotal', show: true, value: ['subTotal'], type: 'currency' ,  className:'mini'},
            {label: 'Total', show: true, value: ['total'], type: 'currency' ,  className:'mini'},
            {config:true, show: true, label:'', className:'mini'}

        ]
    }

    render() {

        const {order, currentCurrency} = this.props;

        return (
            order ?
            <Container className={'invoice'}>
                <div className={'column'}>
                    <Row>
                        {order.clientName && <Col><h4><b>Cliente: </b>{order.clientName}</h4></Col>}
                        {order.warehouseName && <Col><h4><b>Bodega: </b>{order.warehouseName || 'Desconocido'}</h4></Col>}
                    </Row>
                    <br/>
                    <Row className={'text-center'}>
                        {order.typeName && <Col><b>Tipo: </b><br/>{order.typeName}</Col>}
                        {order.statusName && <Col><b>Estado: </b>{order.statusName}</Col>}
                        {order.paymentName && <Col><b>Tipo de pago: </b>{order.paymentName}</Col>}
                        {order.enabledAt && <Col><b>Se habilitará</b> <br/> {formatDateFromMillis(order.enabledAt)}</Col>}
                    </Row>
                    <br/>
                    <Row><Col className={'text-center'}>{order.deleted && <b className={'red-text'}>Orden Eliminada</b>}</Col></Row>
                    <hr/>

                    {order.type === 1 ?
                        <>
                            <TableV2
                                items={order.details}
                                headers = {this.getHeaders()}
                                storageKey={`orderSummary`}
                                mobileAuto
                            />
                            
                            <Row>
                                <Col></Col><Col></Col><Col></Col><Col></Col><Col></Col>
                                <Col xs='auto'><Money component='h2'>Total: {this.getOrderTotal(order.details, order.type, order.balance)}</Money></Col>
                            </Row>
                        </>
                        :
                        <Row>
                            <Col></Col><Col></Col><Col></Col><Col></Col><Col></Col>
                            <Col xs='auto'><Money component='h2'>Total: {this.getOrderTotal(order.details, order.type, order.balance)}</Money></Col>
                        </Row>
                    }
                </div>
            </Container>
            : <div><span>Sin información</span></div>
        )
    }
}

const mapStateToProps = (state) => ({
    currentCurrency: selectCurrentCurrency(state),
});

export default connect(mapStateToProps)(OrderSummary);

