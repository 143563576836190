import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal, Row } from 'react-bootstrap'
import { Paragraph, Checkbox, Button } from 'src/components'
import { faCheckDouble, faWindowClose } from '@fortawesome/free-solid-svg-icons'

import { actionTypes, onUpdateParams } from 'src/actions/products.actions'

import { loadingSelector } from 'src/selectors/loading.selector'
import { showAlert } from 'src/actions/alert.actions'
import { handlerError, hasErrors } from 'src/selectors/error.selector'
import { selectActivePlans } from 'src/selectors/plans.selector'
import { PlanEnum } from 'src/enums/planTypes'

interface Field {
  value: string
  label?: string
  description?: string
  show?: boolean
  disabled?: boolean
}

interface IItem {
  field: Field
  disabled: boolean
  checked: boolean
  onChange: (value: boolean) => void
}
const Item: React.FC<IItem> = ({ field, disabled, checked, onChange }) => (
  <div key={field.value}>
    <div className={'d-flex'}>
      <div className={'column'} style={{ width: '87%' }}>
        <Paragraph bold>{field.label}</Paragraph>
        <Paragraph dim size={'small'}>
          {field.description}
        </Paragraph>
      </div>
      <div style={{ width: '13%' }} className={'center'}>
        <Checkbox
          disabled={disabled}
          name={field.value}
          checked={checked}
          changeValue={onChange}
        />
      </div>
    </div>
    <div>
      <hr />
    </div>
  </div>
)

interface Props {
  show: boolean
  onHide: (IItemConfig, boolean) => void
  initialConfig: IItemConfig
  modal?: boolean
  itemId?: number
  disabled?: boolean
}

/**
 * Component to carry out a massive transfer of selected items and warehouses with stocks.
 * @component
 * @param {boolean} show Indicates if the component should be rendered or not
 * @param {boolean} modal Indicates if the component should be rendered in a modal or not
 * @param {function} onHide CallBack to close the component from the main app
 * @param {IItemConfig} initialConfig Set initial of selected item
 * @param {number} itemId id of the selected item
 * @param {boolean} disabled Disabled inputs
 * */
const ItemConfigs = ({
  modal = false,
  show,
  onHide,
  initialConfig,
  itemId,
  disabled = false,
}: Props) => {
  const dispatch = useDispatch()

  const loading = useSelector(state =>
    loadingSelector([actionTypes.UPDATE_PARAMS])(state),
  )
  const error = useSelector(state => hasErrors([actionTypes.UPDATE_PARAMS])(state))
  const plan = useSelector(selectActivePlans)

  const [flags, setFlags] = useState({ update: false })

  const [open, setOpen] = useState<boolean>(false)
  const [config, setConfig] = useState<IItemConfig>({})

  const fields: Field[] = [
    {
      value: 'active',
      label: 'Activo',
      description: 'Activa el item para poder realizar transacciones con el item.',
      show: itemId !== undefined && modal,
    },
    {
      value: 'online',
      label: 'En Linea',
      description: 'Activa el item para ser visualizado en KOLO.',
      show: true,
    },
    {
      value: 'purchase',
      label: 'Compras',
      description: 'Activa el item para ser visualizado en la APP de Compras.',
      show: true,
      disabled: plan?.id === PlanEnum.FREE,
    },
    {
      value: 'expense',
      label: 'Gastos',
      description: 'Activa el item para ser visualizado en la APP de Gastos.',
      show: true,
      disabled: plan?.id === PlanEnum.FREE,
    },
    {
      value: 'sell',
      label: 'Ventas',
      description: 'Activa el item para ser visualizado en la APP de Ventas.',
      show: true,
    },
    {
      value: 'increase',
      label: 'Ingreso de Inventario',
      description:
        'Activa el item para ser visualizado en la APP de Ingreso de Inventario.',
      show: true,
      disabled: plan?.id === PlanEnum.FREE,
    },
    {
      value: 'waste',
      label: 'Reducción de inventario',
      description:
        'Activa el item para ser visualizado en la APP de Reducción de inventario.',
      show: true,
      disabled: plan?.id === PlanEnum.FREE,
    },
    {
      value: 'withoutInventory',
      label: 'No usar Inventario',
      description: 'Indica si el item no usara inventario.',
      show: modal,
      disabled: plan?.id === PlanEnum.FREE,
    },
    {
      value: 'discountLimited',
      label: 'Limite de descuento',
      description:
        'Activa el item para que el limite de descuento se aplique en la App de ventas.',
      show: modal,
    },
    {
      value: 'activeInventoryRecipe',
      label: 'inventario por insumos',
      description:
        'Al activar esta opción, al confirmar una orden de venta con este item el sistema descontara el inventario de los insumos que conforman la receta o combo del item.',
      show: modal,
    },
    {
      value: 'notManufacture',
      label: 'Combo',
      description:
        'Al activar la opción, se aplicará el descuento de inventario directamente de los componentes que conforman el ítem. Como resultado, no se efectuará una manufactura adicional del ítem.',
      show: modal,
    },
    {
      value: 'theoreticExistence',
      label: 'Existencia Teórica',
      description:
        'Al activar la configuración el item mostrara la existencia Teórica según la existencia de los insumos que lo componen en su receta.',
      show: modal,
    },
    {
      value: 'updatePrice',
      label: 'Actualizar precio durante la venta',
      description:
        'Permite al usuario actualizar el precio del item durante la creación de la orden de venta.',
      show: modal,
    },
    {
      value: 'enableSubRecipes',
      label: 'Descuento de sub recetas',
      description:
        'Si un ítem o sus variaciones en la receta del item también tienen recetas, al activarlas, también se descontarán automáticamente los productos utilizados en esas recetas.',
      show: modal,
    },
    {
      value: 'inBatches',
      label: 'Descontar inventario por lotes',
      description:
        'Permite que al realizar transacciones de salida (ventas, reducción de inventario y transferencias) sea posible indicar de que compra/ingreso se desaa descontar el inventario.',
      show: modal,
    },
  ]

  useEffect(() => {
    if (!show) return
    setOpen(true)
    setConfig(initialConfig || {})
  }, [show])

  useEffect(() => {
    if (loading) setFlags({ ...flags, update: true })
    else if (flags.update) {
      setFlags({ ...flags, update: false })
      if (error) dispatch(showAlert({ ...handlerError(error.message) }))
      else onClose(true)
    }
  }, [loading])

  const onClose = (update: boolean) => {
    if (!loading) {
      setOpen(false)
      onHide(config, update)
    }
  }

  const getValue = (key: string): boolean => {
    return config[key] || false
  }

  const onSave = () => {
    if (!itemId) onClose(false)
    else {
      dispatch(onUpdateParams({ products: [itemId], ...config }))
    }
  }

  const settingsElements = (
    <div className={'column'}>
      {fields
        .filter((f: Field) => f.show)
        .map((f: Field) => (
          <Item
            key={f.value}
            field={f}
            disabled={loading || disabled || f.disabled}
            checked={getValue(f.value)}
            onChange={value => {
              const customConfig = Object.assign({}, { ...config, [f.value]: value })

              setConfig(customConfig)
              if (!modal) onHide(customConfig, true)
            }}
          />
        ))}
    </div>
  )

  return modal ? (
    <Modal show={open} centered size={'lg'} onHide={() => onClose(false)}>
      <Modal.Header closeButton={!loading}>
        <Modal.Title>Configuraciones</Modal.Title>
      </Modal.Header>

      <Modal.Body className={'custom-modal-body'}>{settingsElements}</Modal.Body>
      <Modal.Footer>
        <Row className={'container-buttons'}>
          <Button
            disabled={loading}
            color={'secondary'}
            icon={faWindowClose}
            onClick={() => onClose(false)}>
            Cancelar
          </Button>
          <Button icon={faCheckDouble} loading={loading} onClick={() => onSave()}>
            Guardar
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  ) : (
    settingsElements
  )
}
export default ItemConfigs
