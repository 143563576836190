import React from 'react'
import { useSelector } from 'react-redux'

import { Card, TableV2, Icon } from 'src/components'
import { faEye, faSync, faTrash } from '@fortawesome/free-solid-svg-icons'

import { actions } from 'src/actions/promotions.actions'
import { selectAllPromotions, selectPromotionId } from 'src/selectors/promotions.selector'

import { isAllowed } from 'src/selectors/modules.selector'
import { promotionPermissions } from 'src/enums/permissions'
import { loadingSelector } from 'src/selectors/loading.selector'

interface IPromotionListProps {
  onRefresh: () => void
  onUpdate: (item: IPromotion) => void
  onDelete: (item: IPromotion) => void
}

const PromotionList = ({ onRefresh, onUpdate, onDelete }: IPromotionListProps) => {
  const promotions: IPromotion[] = useSelector(selectAllPromotions)
  const promotionId: number = useSelector(selectPromotionId)

  const loading: boolean = useSelector(state =>
    loadingSelector([actions.GET_ALL_PROMOTIONS])(state),
  )

  const canDelete: boolean = useSelector(state =>
    isAllowed(state, [promotionPermissions.delete]),
  )

  const getActions = (item: IPromotion) => {
    const loading = promotionId === item.id

    return loading ? (
      <Icon tooltip={'cargando'} icon={faSync} spin />
    ) : (
      <div className={'d-flex'}>
        <Icon tooltip={'Ver detalle'} icon={faEye} onClick={() => onUpdate(item)} />
        {canDelete && (
          <Icon
            className={'ml-2'}
            tooltip={'Eliminar'}
            icon={faTrash}
            onClick={() => onDelete(item)}
          />
        )}
      </div>
    )
  }

  const headers: ITableHeader[] = [
    { label: 'Nombre', show: true, type: 'text', value: ['name'], className: 'medium' },
    {
      label: 'Descripción',
      show: true,
      type: 'text',
      value: ['description'],
      className: 'medium',
    },
    { label: 'Estado', show: true, type: 'text', value: ['active'], className: 'mini' },
    {
      label: 'Acciones',
      show: true,
      type: 'actions',
      value: [],
      config: true,
      className: 'mini',
      custom: getActions,
    },
  ]

  return (
    <Card
      title={'Listado'}
      white
      button={
        <Icon
          disabled={loading}
          onClick={onRefresh}
          tooltip={'Actualizar'}
          icon={faSync}
        />
      }>
      <TableV2
        // @ts-ignore
        loading={loading}
        items={promotions}
        headers={headers}
      />
    </Card>
  )
}
export default PromotionList
