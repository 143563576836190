import './ECommerceCard.scss'
import React, { Component } from 'react'

import Food from 'src/assets/images/svg/food.svg'

export default class ECommerceCard extends Component {
  render() {
    const { title, subTitle, price, description, onClick, img } = this.props

    return (
      <div className={'e-card'}>
        <div className={'photo'}>
          <img src={img || Food} />
        </div>
        <div className={'description'}>
          <h2>{title}</h2>
          <h4>{subTitle}</h4>
          <h1>{price}</h1>
          <p>{description}</p>
          <button onClick={onClick}>Agregar al carrito</button>
        </div>
      </div>
    )
  }
}
