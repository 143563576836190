import { types } from '../actions/authorization.actions'
import { successState } from '../actions/global.actions'

const initialState = {
  authorizations: [],
  active: {},
  oauthUrl: 'pending...',
}

const AuthorizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(types.GET_OAUTH_URL):
      return { ...state, oauthUrl: action.oauthURL }
    case successState(types.GET_AUTH):
      return {
        ...state,
        authorizations: action.response,
      }

    case successState(types.GET_ACTIVE_AUTH):
      return {
        ...state,
        active: action.success,
      }
    default:
      return state ? state : initialState
  }
}

export default AuthorizationReducer
