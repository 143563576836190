import { $http } from './http'
import { $httpns } from './httpns'
import qs from 'qs'
import moment from 'moment'
import { saveAs } from 'file-saver'

class ProductsService {
  getProducts = async (
    skip,
    size,
    search,
    active,
    available,
    contract,
    typeId,
    composed,
    categorization,
    sort,
    start,
    end,
    pos,
    activeId,
    providerId,
    getTotal,
  ) => {
    const response = await $http.get(`/products`, {
      params: {
        newSp: true,
        skip,
        size,
        search,
        active,
        available,
        contract,
        typeId,
        composed,
        categorization,
        sort,
        start,
        end,
        pos,
        activeId,
        providerId,
        getTotal,
      },
    })
    response.data.getTotal = getTotal
    return response.data
  }

  getProductsCompany = async companyId => {
    const response = await $http.get(`/products/${companyId}/company`)
    return response.data
  }

  getGlobalProducts = async () => {
    const response = await $http.get('/products/global')
    return response.data
  }

  getModelProducts = async () => {
    const response = await $http.get('/products/model')
    return response.data
  }

  getProductCategories = async () => {
    const response = await $http.get(`/products/categories`)
    return response.data
  }

  getProductSubCategories = async category => {
    const response = await $http.get(`/products/categories/${category}/sub`)
    return response.data
  }

  getSingleProduct = async id => {
    const response = await $http.get(`/products/${id}`)
    return response.data
  }

  createProduct = async request => {
    const response = await $http.post(`/products`, request)
    return response.data
  }

  getDataSheet = async id => {
    const response = await $http.get(`/products/datasheet/${id}`)
    return response.data
  }

  updateDataSheet = async (id, request) => {
    const response = await $http.put(`/products/datasheet/${id}`, request)
    return response.data
  }

  createDataSheet = async (id, request) => {
    const response = await $http.post(`/products/datasheet/${id}`, request)
    return response.data
  }

  createProductCategory = async request => {
    const response = await $http.post(`/products/category`, request)
    return response.data
  }

  updateProduct = async (id, request) => {
    const response = await $http.put(`/products/${id}`, request)
    return response.data
  }

  updateMultipleProducts = async products => {
    const response = await $http.put(`/products/batch`, { products })
    return response.data
  }

  deleteProductCategory = async id => {
    const response = await $http.delete(`/products/category/${id}`)
    return response.data
  }

  deleteProduct = async id => {
    const response = await $http.delete(`/products/${id}`)
    return response.data
  }

  addImage = async (product, url, name, description) => {
    const response = await $http.post(`/products/${product}/image`, {
      url,
      name,
      description,
    })
    return response.data
  }

  getImages = async product => {
    const response = await $http.get(`/products/${product}/images`)
    return response.data
  }

  deleteImage = async (product, image) => {
    const response = await $http.delete(`/products/${product}/images/${image}`)
    return response.data
  }

  toggleProductActive = async product => {
    const response = await $http.put(`/products/${product}/activate`)
    return response.data
  }

  existenceWithSubitemsAcrossWarehouses = async productId => {
    const response = await $http.get(`/inventory/existence/${productId}`)
    return response.data
  }

  existenceAcrossWarehouses = async (product, external) => {
    const response = await $http.get(`/inventory/existence/${product}/${external}`)
    return response.data
  }

  getReport = async (productId, start, end, type) => {
    const response = await $http.get(`products/chart/${productId}`, {
      params: { start, end, type },
    })
    return response.data
  }

  getChart = async (list, start, end, type) => {
    const response = await $http.put(`products/chart`, list, {
      params: { start, end, type },
    })
    return response.data
  }

  getProductsByClient = async clientId => {
    const response = await $http.get(`products/client/${clientId}`)
    return response.data
  }

  getProductsWithActiveContract = async () => {
    const response = await $http.get(`/producers/contract/products`)
    return response.data
  }

  getProductsInWarehouses = async () => {
    const response = await $http.get(`/products/warehouses`)
    return response.data
  }

  loadProductsCSV = async (file, model) => {
    let formData = new FormData()
    formData.append('file', file)
    const response = await $http.post(`/products/csv/old?model=${model}`, formData, {
      headers: { 'content-type': 'multipart/form-data' },
    })
    return response.data
  }

  loadProducts = async (products, currentIndex, model) => {
    const response = await $http.post(
      `/products/csv?index=${currentIndex}&model=${model}`,
      products,
    )

    // {
    //   data: {
    //     ok: true, items: products.length, errors: []
    //   }
    // }

    return response.data
  }
  updateLoadProducts = async (products, currentIndex, model) => {
    const response = await $http.post(
      `/products/csv/edit?index=${currentIndex}&model=${model}`,
      products,
    )
    return response.data
  }
  updateProductsCSV = async (file, model) => {
    let formData = new FormData()
    formData.append('file', file)
    const response = await $http.post(`/products/csv/edit?model=${model}`, formData, {
      headers: { 'content-type': 'multipart/form-data' },
    })
    return response.data
  }

  loadProductsTransportationPaymentCSV = async (file, polygonId) => {
    let formData = new FormData()
    formData.append('file', file)
    const response = await $http.post(
      `/products/transportation/csv/${polygonId}`,
      formData,
      { headers: { 'content-type': 'multipart/form-data' } },
    )
    return response.data
  }

  onCreateTransportationPayment = async request => {
    const response = await $http.post('products/transportation/payment', request)
    return response.data
  }

  getTransportationPayments = async (productId, polygonId) => {
    const response = await $http.get(
      `products/transportation/payment/${productId}/${polygonId || 0}`,
    )
    return response.data
  }

  getTransportationPayment = async transportationId => {
    const response = await $http.get(
      `products/transportation/payment/detail/${transportationId}`,
    )
    return response.data
  }

  getProductsWithTransportationPayment = async () => {
    const response = await $http.get('products/transportation/payment')
    return response.data
  }

  getAllProducts = async type => {
    const response = await $http.get('products/all?'.concat(`type=${type}`))
    return response.data
  }

  getProductsByCompanyNS = async companyId => {
    const response = await $httpns.get(`nsproducts/${companyId}/company`)
    return response.data
  }

  getOwnOnlineProducts = async companyId => {
    const response = await $httpns.get(`nsproducts?companyId=${companyId}`)
    return response.data
  }

  onUpdateParams = async request => {
    const response = await $http.put(`/products/edit/many`, request)
    return response.data
  }

  getCompaniesByModelProduct = async id => {
    const response = await $http.get(`/products/company/model/${id}`)
    return response.data
  }

  changeStateOnKoloProfile = async id => {
    const response = await $http.put(`/products/onProfile/${id}`)
    return response.data
  }

  getLog = async (id, params) => {
    const response = await $http.get(`/products/log/${id}`, { params })
    return response.data
  }

  exportCSV = async (ids, params) => {
    const response = await $http.put(`/products/export/csv`, ids, { params })

    const fileName = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : moment().format('LL') + 'update_product_file.csv'

    let text = response.data
      .split('\n')
      .map(line => line.split(/\s+/).join(' '))
      .join('\n')

    let csvContent = 'data:text/csv;charset=utf-8,'
    csvContent += text

    const uri = encodeURI(csvContent)
    let link = document.createElement('a')
    link.setAttribute('href', uri)
    link.setAttribute('download', 'products.csv')
    document.body.appendChild(link)
    link.click()

    /*saveAs(csvContent, fileName.replace('json','csv'));*/
  }

  deleteProducts = async (productIds, archive, all) => {
    const response = await $http.delete('/products', {
      params: { productIds, archive, all },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })
    return response.data
  }

  getPDFDatasheet = async id => {
    const response = await $httpns.get(`/nsproducts/datasheet/pdf/${id}`, {
      responseType: 'blob',
    })
    const file = new Blob([response.data], { type: 'application/pdf' })
    const fileName = 'Ficha Tecnica.pdf'
    saveAs(file, fileName)
  }

  seeProductLine = async id => {
    const response = await $http.get(`/products/line/${id}`)
    return response.data
  }

  updateProductLine = async (id, params) => {
    const response = await $http.put(`/products/line/${id}`, {}, { params })
    return response.data
  }
  createNodeProductLine = async (id, params) => {
    const response = await $http.post(`/products/line/${id}`, {}, { params })
    return response.data
  }

  deleteProductLine = async id => {
    const response = await $http.delete(`/products/line/${id}`)
    return response.data
  }
  getProductsSimple = async () => {
    const response = await $http.get('/products/simple')
    return response.data
  }

  assignEntity = async (categorizationId, list, remove, all) => {
    const response = await $http.put(
      `/products/categorization/0/${categorizationId}`,
      list,
      { params: { remove, all } },
    )
    return response.data
  }

  productWaste = async list => {
    const response = await $http.put(`/products/request`, list)
    return response.data
  }

  createTransfer = async request => {
    const response = await $http.post(`/products/transfer`, request)
    return response.data
  }

  historicExcel = async (params, id, name) => {
    const response = await $http.get(`/products/excel/${id}`, {
      params,
      responseType: 'blob',
    })

    const fileName = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : moment().format('LL') + ' - Historial de Precios - ' + name + '.xlsx'

    const file = new Blob([response.data], { type: 'application/vnd.ms-excel' })
    saveAs(file, fileName.replace('json', 'xlsx'))
    return response.data
  }

  productToChartReport = async params => {
    const response = await $http.get(`/products/chart/products`, { params })
    return response.data
  }

  getProductPricesNames = async () => {
    const response = await $http.get(`/products/nameProducts`)
    return response.data
  }

  updateProductPricesNames = async request => {
    const response = await $http.put(`/products/nameProducts`, request)
    return response.data
  }

  productToChartReportCategory = async list => {
    const response = await $http.put(`/products/chart/categories`, list)
    return response.data
  }

  getProductsLite = async params => {
    const response = await $http.get(`/products/lite`, { params })
    return response.data
  }

  getExcelByReport = async (request, list) => {
    const response = await $http.put(`/products/excel`, list, {
      responseType: 'blob',
      params: request,
    })
    const fileName = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : moment().format('LL') + ' - Historico de Precios - .xlsx'

    const file = new Blob([response.data], { type: 'application/vnd.ms-excel' })
    saveAs(file, fileName.replace('json', 'xlsx'))
  }

  getVariationsProduct = async productId => {
    const response = await $http.get(`/products/variation/${productId}`)
    return response.data
  }

  deleteProductVariation = async listId => {
    const response = await $http.put(`/products/variation`, listId)
    return response.data
  }

  getProductMainExistence = async listId => {
    const response = await $http.post(`/products/existence`, listId)
    return response.data
  }

  getExcelExistences = async listId => {
    const response = await $http.post(`/products/existence/excel`, listId, {
      responseType: 'blob',
    })
    const fileName = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : moment().format('LL') + ' - existencias - .xlsx'

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    saveAs(file, fileName.replace('json', 'xlsx'))
  }

  getMeasurementUnits = async () => {
    const response = await $http.get(`/products/measurement`)
    return response.data
  }

  getItemDetail = async itemId => {
    const response = await $http.get(`/products/${itemId}/detail`)
    return response.data
  }

  onCreateOrUpdateItem = async (itemId, request) => {
    const response = await $http.post('/products/createOrUpdate', request, {
      params: { itemId },
    })
    return response.data
  }
}

export default new ProductsService()
