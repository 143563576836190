import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal } from 'react-bootstrap'
import { Icon, TableV2, Dropdown } from 'src/components'
import {
    faCheck,
    faCheckDouble,
    faClock,
    faCreditCard,
    faFileInvoice,
} from '@fortawesome/free-solid-svg-icons'

import { actionTypes, onPostponePayment } from 'src/actions/mentor.actions'
import { actionTypes as actionPDF } from 'src/actions/resolutions.actions'

import {
  actionTypes as actionO,
  getOrderAccount,
  onPrintInvoice,
} from 'src/actions/orders.actions'
import { selectOrderAddons } from 'src/selectors/orders.selector'

import { loadingSelector } from 'src/selectors/loading.selector'

import { formatDateFromMillis } from 'src/utils/formatters'

import { showAlert } from 'src/actions/alert.actions'
import {
  handlerError,
  handlerInfoWithButtons,
  handlerSuccess,
  hasErrors,
} from 'src/selectors/error.selector'

interface Props {
  show: boolean
  id?: number
  setPayAddon?: (IOrder) => void
  onHide?: () => void
  canSkipPayment?: boolean
  mentorMode?: boolean
  modal?: boolean
  name?: string
}
/** Component that shows a list of invoices issued to the quoted customer
 * @component
 * @param {boolean} show Indicate whether the component should be initialized
 * @param {number} id Id of company to see information
 * @param {Function} setPayAddon Function that allows you to pay the bill
 * @param {Function} onHide Close the component
 * @param {boolean} canSkipPayment Indicates if the payment action can be hidden
 * @param {boolean} mentorMode Indicates that the component is being rendered from the mentors app
 * @param {boolean} modal Indicates whether the component should be MODAL or not
 * @param {string} name Name of Company
 * */
const HistoryPlan = ({
  show,
  id,
  name,
  setPayAddon,
  modal = false,
  canSkipPayment = false,
  mentorMode = false,
  onHide,
}: Props) => {
  const dispatch = useDispatch()

  const orders: IOrder[] = useSelector(selectOrderAddons)

  const loadingOrders = useSelector(state =>
    loadingSelector([actionO.GET_ORDERS_ADDONS])(state),
  )

  const loadingPay = useSelector(state =>
    loadingSelector([actionO.PAY_ORDER_ACCOUNT])(state),
  )

  const loadingPostpone = useSelector(state =>
    loadingSelector([actionTypes.ON_POSTPONE_PAYMENT_INVOICE])(state),
  )

  const errorPostpone = useSelector(state =>
    hasErrors([actionTypes.ON_POSTPONE_PAYMENT_INVOICE])(state),
  )

  const loadingPDF = useSelector(state =>
    loadingSelector([actionPDF.DOWNLOAD_PRINTABLE_INVOICE_BY_ORDER])(state),
  )

  const [flags, setFlags] = useState({ postpone: false })

  const [actionRow, setActionRow] = useState<number>(null)
  const [open, setOpen] = useState<boolean>(false)

  const loadingAction: boolean = loadingPostpone || loadingPay || loadingPDF

  useEffect(() => {
    if (!show) return
    setOpen(true)
    dispatch(getOrderAccount(id))
  }, [show])

  useEffect(() => {
    if (loadingPostpone) setFlags({ ...flags, postpone: true })
    else if (flags.postpone) {
      setFlags({ ...flags, postpone: false })
      const alert = errorPostpone
        ? { ...handlerError(errorPostpone.message) }
        : {
            ...handlerSuccess(),
              onConfirm: () => {
                  dispatch(getOrderAccount(id))
              }
          }
      dispatch(showAlert(alert))
    }
  }, [loadingPostpone])

  const onClose = () => {
    setOpen(false)
    onHide()
  }

  const render = () => (
    <TableV2
      /* @ts-expect-error js */
      customClass={'scroll-x-without-height'}
      pSize={0}
      mobileAuto
      storageKey={'paymentSubscription'}
      items={orders}
      loading={loadingOrders || loadingPay}
      noItemsLegend={'No hay facturas emitidas'}
      headers={[
        {
          label: '',
          select: true,
          show: true,
            custom: item => {
                return (
                    <Icon color={'white'}
                          icon={item.applied ? faCheck : item.balance > 0 ? faClock : faCheckDouble}
                          tooltip={item.applied ? "Aplicado con saldo pendiente" : item.balance > 0 ? "Con saldo pendiente" : "Finalizada"}
                    />
                )
            },
          classNameCustom: item => {
            return 'mini center  kolo_' + (item.balance > 0 && !item.applied ? 'gray' : 'blue')
          },
        },
        {
          label: 'Código',
          show: true,
          value: ['number'],
          type: 'text',
        },
        {
          label: 'Descripción',
          show: true,
          value: ['description'],
          type: 'text',
        },
        {
          label: 'Fecha',
          show: true,
          value: ['fechaC'],
          type: 'text',
          custom: item =>
            (item.fechaC = formatDateFromMillis(item.enabledAt || item.createdAt)),
        },
        {
          label: 'Total',
          show: true,
          value: ['total'],
          type: 'currency',
        },
        {
          label: 'Pendiente',
          show: true,
          value: ['balance'],
          type: 'currency',
        },
        {
          show: true,
          config: true,
          type: 'text',
          value: ['button'],
          label: 'Acción',
          custom: item => (
            <Dropdown
              onClick={() => setActionRow(item.id)}
              loading={loadingAction && actionRow === item.id}
              disabled={loadingAction && actionRow !== item.id}
              items={[
                {
                  icon: faFileInvoice,
                  show:
                    !item.deleted &&
                    (item.status === 3 ||
                      item.status === 15 ||
                      item.status === 13 ||
                      item.status === 5 ||
                      item.status === 17 ||
                      item.status === 18 ||
                      item.status === 100),
                  title: 'Ver facturas',
                  action: () =>
                    dispatch(
                      onPrintInvoice({
                        active: true,
                        orderId: item.id,
                        print: false,
                        documentType: 1,
                      }),
                    ),
                },
                {
                  icon: faCreditCard,
                  title: 'Abonar',
                  show: !mentorMode && item.balance > 0 && item.status === 3,
                  action: () => {
                    setPayAddon({
                      id: item.id,
                      amount: item.balance,
                      show: true,
                    })
                  },
                },
                {
                  icon: faCheckDouble,
                  title: 'Aplicar ajustes',
                  show: canSkipPayment && item.balance > 0 && item.status === 3 && !item.applied,
                  action: () =>
                    dispatch(
                      showAlert({
                        ...handlerInfoWithButtons(
                          'Aplicar ajustes',
                          '¿Desea aplicar los ajustes de la factura? Está acción no podrá ser revertida y el saldo pendiente de cobro quedará como cuenta por cobrar que puede ser abonada por el cliente desde su estado de cuenta y por un mentor desde la app de cuentas por cobrar de KOLO.',
                        ),
                        onConfirm: () => dispatch(onPostponePayment(item.id)),
                      }),
                    ),
                },
              ]}
            />
          ),
        },
      ]}
    />
  )

  return (
    <div>
      {modal ? (
        <Modal show={open} size={'xl'} centered onHide={() => onClose()}>
          <Modal.Header closeButton>
            <Modal.Title>Estado de cuenta: {name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{render()}</Modal.Body>
        </Modal>
      ) : (
        render()
      )}
    </div>
  )
}

export default HistoryPlan
