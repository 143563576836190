import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Col, Container, Modal, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import {
  FAB,
  Action,
  CustomTabs,
  Title,
  Card,
  Icon as IconButton,
  Select,
  SelectedDates,
  TableV2,
  Dropdown as Drop,
} from 'src/components'
import {
  faBox,
  faCheckCircle,
  faClock,
  faEllipsisV,
  faEye,
  faFileAlt,
  faPlusCircle,
  faTimes,
  faTruck,
  faWarehouse,
} from '@fortawesome/free-solid-svg-icons'

import { history } from 'src/App'

import {
  formatHourFromMillis,
  formatNumberWithCommas,
  formatUtcDateFromMillis,
} from 'src/utils/formatters'

import { toMoney } from 'src/utils/utilities'

import { isAllowed, selectUsers } from 'src/selectors/modules.selector'

import {
  acceptMultipleTransfer,
  actionTypes,
  getAssociatedOrders,
  getTransfersTransformations,
  getWarehouseLite,
  getTransferPDF,
} from 'src/actions/warehouse.actions'
import {
  selectAllTransfers,
  selectAssociatedOrders,
  selectOwnWarehouseLite,
  selectTotalTransfer,
  selectTransferDetail,
} from 'src/selectors/warehouse.selector'

import { selectCurrentUser } from 'src/selectors/user.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  handlerSuccess,
  hasErrors,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'

import { getUsersChildrenByModule } from 'src/actions/modules.actions'
import { onSetModalOrder } from 'src/actions/utilities.actions'

import Detail from 'src/content/Orders/OrderDetail/OrdersDetails'
import ModalTransferDetail from './DetailTransfers/ModalTransferDetail'
import { showAlert } from 'src/actions/alert.actions'
import { selectSetCurrentCompany } from 'src/selectors/company.selector'
import { onSetPrintData } from 'src/actions/restaurant.actions'
import warehouseService from 'src/services/warehouse.service'
import { transferTicket } from 'src/components/Printer/TicketEpson'
import { getItemsFormatted, getItemsQuantity } from './TransferFunctions'

const defaultValue = { value: null, label: '-- Todo --' }

const stateOptions = [
  defaultValue,
  { value: 1, label: 'Nueva' },
  { value: 2, label: 'Rechazada' },
  { value: 3, label: 'Terminada' },
  { value: 4, label: 'Cancelada' },
]

const Transfers = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const user = useSelector(selectCurrentUser)
  const ownWarehouses = useSelector(selectOwnWarehouseLite)
  const users = useSelector(selectUsers)
  const transfers = useSelector(selectAllTransfers)
  const total = useSelector(selectTotalTransfer)
  const transferDetail = useSelector(selectTransferDetail)
  const associatedOrders = useSelector(selectAssociatedOrders)
  const company = useSelector(selectSetCurrentCompany)

  const loadingPdf = useSelector(state =>
    loadingSelector([actionTypes.GET_TRANSFER_PDF])(state),
  )
  const errorPdf = useSelector(state => hasErrors([actionTypes.GET_TRANSFER_PDF])(state))

  const loadingTransfers = useSelector(state =>
    loadingSelector([actionTypes.GET_TRANSFERS])(state),
  )
  const loadingTransferDetail = useSelector(state =>
    loadingSelector([actionTypes.GET_TRANSFER_DETAIL])(state),
  )
  const loadingAcceptTransfer = useSelector(state =>
    loadingSelector([actionTypes.ACCEPT_TRANSFER])(state),
  )
  const hasErrorAccept = useSelector(state =>
    hasErrorsSelector([actionTypes.ACCEPT_TRANSFER])(state),
  )
  const errorAccept = useSelector(state =>
    singleErrorSelector([actionTypes.ACCEPT_TRANSFER])(state),
  )

  const loadingReject = useSelector(state =>
    loadingSelector([actionTypes.REJECT_TRANSFER])(state),
  )

  const hasErrorReject = useSelector(state =>
    hasErrorsSelector([actionTypes.REJECT_TRANSFER])(state),
  )
  const errorReject = useSelector(state =>
    singleErrorSelector([actionTypes.REJECT_TRANSFER])(state),
  )
  const loadingAssociatedOrders = useSelector(state =>
    loadingSelector([actionTypes.GET_ASSOCIATED_ORDERS])(state),
  )

  const kolo = location.pathname.includes('/kolo')
  const isTheConversion = location.pathname.includes('/conversiones')
  const isProduction = location.pathname.includes('/producciones')
  const isTransfer = location.pathname.includes('/transferencias')

  const [isConversion, setIsConversion] = useState(false)

  const canSeeOthers = useSelector(state => isAllowed(state, [1752]))
  const valueTransit = useSelector(state => isAllowed(state, [1753]))
  const seeMoneyBalances = useSelector(state => isAllowed(state, [1758]))

  const [action, setAction] = useState({
    accept: false,
    reject: false,
    transfers: false,
    detail: false,
    orders: false,
    pdf: false,
    transferId: null,
  })
  const [init, setInit] = useState(false)

  const [textFilter, setTextFilter] = useState('')
  const [filters, setFilters] = useState({
    warehouse: defaultValue,
    state: defaultValue,
    search: '',
    skip: 0,
    size: 10,
    recolect: false,
    user: defaultValue,
    documentType: 1,
    start: null,
    end: null,
  })

  const [showOrders, setShowOrders] = useState(false)
  const [modalDetail, setModalDetail] = useState({ value: false, products: [] })
  const [orderDetail, setOrderDetail] = useState({ value: false, item: {} })
  const [currentTransferNumber, setCurrentTransferNumber] = useState(0)
  const [load, setLoad] = useState(false)
  const [selected, setSelected] = useState([])
  const [resume, setResume] = useState({ show: false, id: null })
  const [transfer, setTransfer] = useState({})

  useEffect(() => {
    setInit(true)

    setIsConversion(isTheConversion || isProduction)
    dispatch(getUsersChildrenByModule(1000, true))
    dispatch(getWarehouseLite())

    if (isProduction)
      setFilters({ ...filters, state: { value: 1, label: 'Nueva' }, documentType: 16 })
  }, [])

  useEffect(() => {
    if (!init) return
    setTimeout(() => {
      setTextFilter(JSON.stringify(filters))
    }, 400)
  }, [init])

  useEffect(() => {
    if (textFilter === '' || textFilter === JSON.stringify(filters)) return
    setTextFilter(JSON.stringify(filters))
  }, [filters])

  useEffect(() => {
    if (textFilter !== JSON.stringify(filters)) return
    setUp(filters.getTotal)
  }, [textFilter])

  useEffect(() => {
    if (transferDetail?.transfer || selected.length > 0) {
      if (!action.accept) setAction({ ...action, accept: true })
      else {
        setAction({ ...action, accept: false })
        dispatch(
          showAlert(
            hasErrorAccept
              ? handlerError(
                  selected.length > 0
                    ? `No se pudieron confirmar todas las transferencias \n ${errorAccept.message}`
                    : errorAccept.message || 'No se ha podido confirmar la transferencia',
                )
              : {
                  ...handlerSuccess('Transferencia confirmada'),
                  onConfirm: () => {
                    setUp(false)
                  },
                },
          ),
        )
      }
    }
  }, [loadingAcceptTransfer])

  useEffect(() => {
    if (transferDetail?.transfer) {
      if (!action.reject) setAction({ ...action, reject: true })
      else {
        setAction({ ...action, reject: false })
        dispatch(
          showAlert(
            hasErrorReject
              ? handlerError(
                  errorReject.message || 'No se ha podido rechazar la transferencia',
                )
              : {
                  ...handlerSuccess('Transferencia Rechazada'),
                  onConfirm: () => {
                    setUp(false)
                  },
                },
          ),
        )
      }
    }
  }, [loadingReject])

  useEffect(() => {
    if (!action.transfers) setAction({ ...action, transfers: true })
    else {
      setLoad(true)
    }
  }, [transfers])

  useEffect(() => {
    if (load) {
      if (!action.detail) setAction({ ...action, detail: true })
      else {
        setAction({ ...action, detail: false })
      }
    }
  }, [loadingTransferDetail])

  useEffect(() => {
    if (load) {
      if (!action.orders) setAction({ ...action, orders: true })
      else {
        setShowOrders(true)
        setAction({ ...action, orders: false })
      }
    }
  }, [loadingAssociatedOrders])

  useEffect(() => {
    if (loadingPdf) setAction({ ...action, pdf: true })
    else if (action.pdf) {
      setAction({ ...action, pdf: false, transferId: null })
      dispatch(
        showAlert(
          errorPdf
            ? handlerError(errorPdf.message)
            : handlerSuccess('PDF de la transferencia descargada con éxito'),
        ),
      )
    }
  }, [loadingPdf])

  const setUp = (getTotal = true) => {
    const ic = isConversion || isProduction
    dispatch(
      getTransfersTransformations(
        filters.start,
        filters.end,
        filters.skip,
        filters.size,
        kolo,
        filters.recolect,
        filters.state.value,
        filters.search,
        filters.warehouse.value,
        !ic,
        ic,
        canSeeOthers ? filters.user.value : null,
        filters.documentType,
        false,
        getTotal,
      ),
    )
  }

  const seeOrders = item => {
    setCurrentTransferNumber(item.number)
    dispatch(getAssociatedOrders(item.id))
  }

  const filterElement = (
    <div>
      <Row>
        <Col xl={12} md={12} lg={12}>
          <SelectedDates
            withOptionNull
            onDateChange={(start, end) =>
              setFilters({ ...filters, start, end, getTotal: true })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={6} md={6} lg={6}>
          <Select
            label={'Por estado'}
            value={filters.state}
            info={'Filtra las transferencias por estado'}
            options={stateOptions}
            onChange={value => setFilters({ ...filters, state: value, getTotal: true })}
          />
        </Col>
        <Col xs={6} sm={6} md={6} lg={6}>
          <Select
            label={'Por bodega'}
            value={filters.warehouse}
            info={'Filtra las transferencias por la bodega seleccionada'}
            options={[defaultValue, ...ownWarehouses]}
            onChange={value =>
              setFilters({ ...filters, warehouse: value, getTotal: true })
            }
          />
        </Col>
        {canSeeOthers && (
          <Col xs={6} sm={6} md={6} lg={6}>
            <Select
              label={'Por usuario'}
              value={filters.user}
              info={'Filtra las transferencias por el usuario encargado de las bodegas'}
              options={[defaultValue, ...users]}
              onChange={value => setFilters({ ...filters, user: value, getTotal: true })}
            />
          </Col>
        )}
      </Row>
      <br />
      <hr />
    </div>
  )

  const renderIcon = item => {
    const { stateId, fromWarehouseData, toWarehouseData, requested, process } = item
    return stateId === 4 ? (
      <IconButton icon={faTimes} color={'red'} />
    ) : process ? (
      <IconButton icon={faBox} tooltip={'Orden procesada'} />
    ) : requested ? (
      <IconButton icon={faFileAlt} tooltip={'Solicitud de transferencia'} />
    ) : (fromWarehouseData ? fromWarehouseData.companyId : toWarehouseData.companyId) ===
      toWarehouseData.companyId ? (
      <IconButton icon={faWarehouse} tooltip={'Transferencia interna'} />
    ) : (
      <IconButton icon={faTruck} tooltip={'Transferencia externa'} />
    )
  }

  const acceptMultiple = () => {
    const list = selected.map(s => s.id)
    dispatch(acceptMultipleTransfer(list))
  }

  const isSelected = item => {
    const { toWarehouseData } = item
    const select = selected.find(
      s => item.id === s.id && toWarehouseData.attendant === user.id,
    )
    return select !== undefined
  }

  const addOrRemoveSelected = item => {
    const cs = Object.assign([], selected)
    if (isSelected(item)) {
      setSelected(cs.filter(s => s.id !== item.id))
    } else {
      const { toWarehouseData } = item
      if (toWarehouseData.attendant === user.id) {
        cs.push(item)
        setSelected(cs)
      }
    }
  }

  const getTime = item => {
    const now = new Date().getTime()
    return now - item.createdAt
  }

  const headers = [
    {
      label: '',
      show: true,
      value: ['amount'],
      type: 'text',
      select: true,
      classNameCustom: item =>
        `mini ${
          item.process
            ? 'data request'
            : item.requested
            ? 'data request'
            : (item.fromWarehouseData
                ? item.fromWarehouseData.companyId
                : item.toWarehouseData.companyId) === item.toWarehouseData.companyId
            ? ''
            : 'data external'
        }`,
      custom: item => {
        let time
        if (isProduction) {
          time = item.stateId === 1 ? getTime(item) : item.enabledAt - item.createdAt
        } else time = item.time
        return filters.documentType === 16 ? (
          <div className={'d-flex'} style={{ marginRight: 15 }}>
            <IconButton size={'2x'} icon={faClock} />{' '}
            <div style={{ marginLeft: 4 }}>
              {' '}
              <h6>{formatHourFromMillis(time, true)}</h6>
            </div>
          </div>
        ) : (
          renderIcon(item)
        )
      },
    },
    {
      label: 'Descripción',
      show: true,
      value: ['description'],
      type: 'text',
      className: 'medium',
    },
    {
      label: 'Fecha',
      show: true,
      value: ['enabledAt'],
      type: 'date',
      className: 'mini',
    },
    { label: 'Código', show: true, value: ['number'], type: 'text', className: 'mini' },
    {
      label: 'Recolectar',
      show: !isConversion,
      value: ['recolectarC'],
      type: 'text',
      className: 'mini',
      custom: item => (item.recolectarC = item.recolect ? 'Si' : 'No'),
    },
    {
      label: 'Lugar de recolección',
      show: !isConversion,
      value: ['lugarC'],
      type: 'text',
      className: 'mini',
      custom: item =>
        (item.lugarC = item.recolect
          ? item.fromWarehouseData
            ? item.fromWarehouseData.address
            : 'Ciudad'
          : '--'),
    },
    {
      label: 'Productor',
      show: !isConversion,
      value: ['productorC'],
      type: 'text',
      className: 'mini',
      custom: item =>
        (item.productorC = item.fromWarehouseData
          ? item.fromWarehouseData.companyName
          : item.toWarehouseData.companyName),
    },
    {
      label: 'Desde',
      show: true,
      value: ['desdeC'],
      type: 'text',
      className: 'mini',
      custom: item =>
        (item.desdeC = item.fromWarehouseData
          ? item.fromWarehouseData.name
          : 'Varias bodegas'),
    },
    {
      label: 'Hacia',
      show: true,
      value: ['haciaC'],
      type: 'text',
      className: 'mini',
      custom: item =>
        (item.haciaC =
          filters.documentType === 16 ? item.reference : item.toWarehouseData.name),
    },
    {
      label: 'Estado',
      show: true,
      value: ['stateName'],
      type: 'text',
      className: 'mini',
    },
    {
      label: 'Valor en tránsito',
      show: seeMoneyBalances && valueTransit && !isConversion,
      value: ['valueTransit'],
      type: 'currency',
      className: 'mini',
    },
    {
      label: 'Pago de transporte',
      show: false,
      value: ['rate'],
      type: 'currency',
      className: 'mini',
    },
    {
      config: true,
      show: isProduction,
      label: '',
      className: 'mini center',
    },
    {
      config: true,
      show: !isProduction,
      label: '',
      className: 'short center',
      custom: item => (
        <Drop
          loading={
            item.id === action.transferId || (loadingPdf && item.id === action.transferId)
          }
          disabled={item.id !== action.transferId && loadingPdf}
          items={[
            {
              show: false,
              title: 'Ver Órdenes',
              action: () => seeOrders(item),
            },
            {
              show: true,
              title: 'Detalle',
              action: () => {
                const title = item.recolect
                  ? 'Recolección'
                  : isConversion
                  ? 'Conversión'
                  : 'Transferencia'

                setResume({ show: true, id: item.id, title })
              },
            },
            {
              show: false,
              title: 'Detalle Completo',
              action: () => history.push(`conversiones/${item.id}`),
            },
            {
              show: isTransfer,
              title: 'Descargar PDF',
              action: () => {
                setAction({ ...action, transferId: item.id })
                dispatch(getTransferPDF(item.id, `Transferencia ${item.number}`))
              },
            },
            {
              show: true,
              title: 'Imprimir ticket',
              action: async () => {
                const transfer = await warehouseService.getTransferDetail(item.id, false)
                const items = getItemsFormatted(
                  transfer.detail?.filter(
                    d => d.movementTypeId === (transfer.isTransformation ? 2 : null),
                  ),
                )
                const itemTransfer = {
                  ...item,
                  details: items,
                  itemQuantity: getItemsQuantity(items),
                }
                setTransfer(itemTransfer)
                dispatch(
                  onSetPrintData({
                    show: true,
                    element: 'ticket',
                    customData: transferTicket(itemTransfer),
                  }),
                )
              },
            },
            {
              show: true,
              title: 'Imprimir transferencia',
              action: async () => {
                setAction({ ...action, transferId: item.id })
                const file = await warehouseService.getTransferPDF(item.id, '', false)
                setAction({ ...action, transferId: null })
                dispatch(
                  onSetPrintData({
                    show: true,
                    pdfBlob: file,
                  }),
                )
              },
            },
          ]}
        />
      ),
    },
  ]

  const tableComponent = (
    <TableV2
      headers={headers}
      items={transfers}
      loading={loadingTransfers}
      tableDivStyle={{ maxHeight: '600px' }}
      customFilter={filterElement}
      total={total}
      getPagination={(skip, size, search) =>
        setFilters({ ...filters, skip, size, search, getTotal: false })
      }
      handleChange={search =>
        setTimeout(() => {
          setFilters({ ...filters, search, getTotal: true })
        }, 1000)
      }
      mobileAuto
      storageKey={isTheConversion ? 'conversionTable' : `transfersTable`}
      onClickTr={(e, item) => {
        if (isProduction) {
          const title = 'Producción'
          setResume({ show: true, id: item.id, title })
        } else addOrRemoveSelected(item)
      }}
      isSelected={selected => isSelected(selected)}
    />
  )

  const orderHeaders = [
    {
      label: 'Orden',
      show: true,
      value: ['order', 'number'],
      type: 'text',
      className: 'medium',
    },
    {
      label: 'SKU',
      show: true,
      value: ['product', 'code'],
      type: 'text',
      className: 'short',
    },
    {
      label: 'Producto',
      show: true,
      value: ['product', 'name'],
      type: 'text',
      className: 'medium',
    },
    {
      label: 'Cantidad',
      show: true,
      value: ['inventory', 'originalQuantity'],
      type: 'text',
      className: 'medium',
    },
    {
      config: true,
      show: true,
      label: '',
      className: 'short center',
      custom: item => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
          }}>
          <IconButton
            tooltip={'Ir a la orden'}
            icon={faFileAlt}
            onClick={() => history.push(`ordenes/false/detalle/${item.orderId}`)}
          />
          <IconButton
            tooltip={'Detalle de la orden'}
            icon={faEye}
            onClick={() => {
              dispatch(onSetModalOrder(item.orderId))
            }}
            style={{ marginLeft: 3 }}
          />
        </div>
      ),
    },
  ]

  const summationHeaders = [
    {
      label: '#',
      show: true,
      value: ['code'],
      type: 'text',
      className: 'mini',
    },
    {
      label: 'Producto',
      show: true,
      value: ['label'],
      type: 'text',
      className: 'medium',
    },
    {
      label: 'Cantidad',
      show: true,
      value: ['quantity'],
      type: 'text',
      className: 'mini',
    },
    {
      label: 'Valor en tránsito',
      show: seeMoneyBalances,
      value: ['valorC'],
      type: 'text',
      className: 'medium',
      custom: item =>
        (item.valorC = toMoney(formatNumberWithCommas(item.quantity * item.price))),
    },
    { config: true, show: true, label: '', className: 'mini center' },
  ]

  return (
    <div>
      <FAB
        show={!kolo && !isConversion}
        items={[
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            icon: faFileAlt,
            text: 'Solicitar transferencia',
            action: () => history.push('transferencias/crear/solicitud'),
            show: !kolo && !isConversion,
          },
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            icon: faPlusCircle,
            text: 'Crear transferencia',
            action: () => history.push('transferencias/crear'),
            show: !kolo && !isConversion,
          },
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            icon: faCheckCircle,
            text: 'Aceptar transferencias seleccionadas',
            action: () => acceptMultiple(),
            show:
              !kolo &&
              !isConversion &&
              selected.filter(s => s.stateId === 1).length === selected.length &&
              selected.length > 0,
          },
        ]}
        icon={faEllipsisV}
      />
      {isConversion ? (
        <Title
          title={isProduction ? 'Producciones' : 'Conversiones'}
          action
          to={'receta'}
          actionTitle={'Nueva Conversión'}
        />
      ) : (
        <Title
          title={'Transferencias'}
          action={kolo}
          to={'crear'}
          actionTitle={'Crear transferencia externa'}
        />
      )}
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          {isConversion ? (
            <Action to={'receta'} action actionTitle={'Nueva Conversión'} />
          ) : (
            <Action
              action={kolo}
              to={'crear'}
              actionTitle={'Crear transferencia externa'}
            />
          )}
          <Card title={'Historial'} white>
            <div>
              {kolo ? (
                <CustomTabs
                  setKey={() =>
                    setFilters({
                      ...filters,
                      recolect: !filters.recolect,
                      getTotal: true,
                    })
                  }
                  items={[
                    {
                      title: 'Transferencias Externas',
                      info: 'Muestra las transferencias externas que posee la empresa',
                      component: tableComponent,
                    },
                    {
                      title: 'Solicitudes de Recolección',
                      info: 'Muestra las solicitudes de recolección que le hacen a la empresa',
                      component: tableComponent,
                    },
                  ]}
                />
              ) : (
                tableComponent
              )}
            </div>
          </Card>
        </Col>
      </Row>
      <Modal
        show={showOrders && !orderDetail.value}
        size={'lg'}
        centered
        aria-labelledby={'contained-modal-title-vcenter'}
        onHide={() => setShowOrders(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Órdenes de transferencia asociadas a {currentTransferNumber}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="invoice">
            <Row>
              <Col className={'text-center'}>
                <h3>Órdenes</h3>
                <br />
              </Col>
            </Row>

            <Card
              bodyStyle={{ padding: '0px 8px' }}
              style={{ backgroundColor: 'lightblue' }}>
              <ul className={'mdc-list mdc-list--two-line mdc-list--avatar-list'}>
                <TableV2
                  headers={orderHeaders}
                  items={associatedOrders}
                  loading={loadingAssociatedOrders}
                  mobileAuto
                  storageKey={`transferOrder`}
                />
              </ul>
            </Card>
          </Container>
        </Modal.Body>
      </Modal>
      <Modal
        show={orderDetail.value}
        size={'lg'}
        centered
        aria-labelledby={'contained-modal-title-vcenter'}
        onHide={() => setOrderDetail({ value: false, item: {} })}>
        <Modal.Header closeButton>
          <Modal.Title>
            {orderDetail.item.order
              ? `Detalle de la orden: #${orderDetail.item.order.number}`
              : 'Detalle de la orden'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Detail id={orderDetail.item.order ? orderDetail.item.order.id : null} />
        </Modal.Body>
      </Modal>
      <Modal
        show={modalDetail.value}
        size={'lg'}
        centered
        aria-labelledby={'contained-modal-title-vcenter'}
        onHide={() => setModalDetail({ value: false, products: [] })}>
        <Modal.Header closeButton>
          <Modal.Title>Sumatoria de productos</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <TableV2
            headers={summationHeaders}
            items={modalDetail.products}
            mobileAuto
            storageKey={`sumatoriaProductos`}
          />
        </Modal.Body>
      </Modal>

      <ModalTransferDetail
        size={isConversion ? 'lg' : 'md'}
        show={resume.show}
        onHide={() => setResume({ show: false, id: null })}
        id={resume.id}
        title={resume.title}
        isProduction={isProduction}
        isTransfer={isTransfer}
        onRefresh={() => setUp(filters.getTotal)}
      />

      <div style={{ display: 'none' }}>
        <div id={'ticket'}>
          <p className="center">
            <b>{company.commercialName || company.name}</b>
            <br />
            {company.id === 791 ? 'AE' : company.id === 860 ? 'IM' : company.name}
            <br />
            {company.id !== 791 && (company.id === 860 ? 'Ciudad' : company.address)}
            <br />
            Transferencia No. {transfer.number}
            {' - '}
            {formatUtcDateFromMillis(transfer.enabledAt || transfer.createdAt)}
          </p>

          <p className={'center'}>
            <b>Bodega saliente: </b>
            {transfer.fromWarehouseData?.name || 'Desconocido'} <br />
            <b>Bodega entrante: </b> {transfer.toWarehouseData?.name || 'Desconocido'}
            <br />
            <b>Estado: </b>
            {transfer.stateName || 'Desconocido'} <br />
            <b>Solicitado por: </b>
            {transfer.solicitedBy || 'Desconocido'}
            <br />
            <b>Aprobado por: </b>
            {transfer.reviewedBy || 'Desconocido'} <br />
            <b>Fecha de aprobación: </b>
            {transfer.reviewedAt || transfer.movedAt
              ? formatUtcDateFromMillis(transfer.reviewedAt || transfer.movedAt)
              : 'Sin aprobar'}
            <br />
            <b>Descripción: </b> {transfer.description || 'Sin descripción'} <br />
            <b>Cantidad de ítems transferidos: </b> {transfer.itemQuantity} <br />
          </p>

          <table className={'center'}>
            <thead>
              <tr>
                <th>Sku</th>
                <th>Item</th>
                <th>Cant.</th>
              </tr>
            </thead>
            <tbody>
              {transfer?.details?.map((item, index) => (
                <tr key={index}>
                  <td className={'center'} style={{ width: 50 }}>
                    {item.productData ? item.productData.code : 'Desconocido'}
                  </td>
                  <td className={'center'} style={{ width: 125 }}>
                    {item.productData ? item.productData.name : 'Desconocido'}
                    <br />
                    {item.listCategories?.length > 0 &&
                      item.listCategories.map(category => category.name + ' ')}

                    {item.commentary !== null && (
                      <>
                        <br /> {item.commentary}
                      </>
                    )}
                  </td>
                  <td className={'center'} style={{ width: 100 }}>
                    {item.realQuantity}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Transfers
