import { actionTypes } from '../actions/payments.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  saleOrderBalance: 0,
  saleOrderBalanceCurrentUser: 0,

  biPaymentsHistory: [],
  biPaymentsHistoryCurrentUser: [],
}

const PaymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_SALE_ORDERS_BALANCE):
      return {
        ...state,
        saleOrderBalance: extract(action.response, 0),
      }
    case successState(actionTypes.GET_SALE_ORDERS_BALANCE_CURRENT_USER):
      return {
        ...state,
        saleOrderBalanceCurrentUser: extract(action.response, 0),
      }
    case successState(actionTypes.GET_BI_PAYMENTS_HISTORY):
      return {
        ...state,
        biPaymentsHistory: extract(action.response, []),
      }
    case successState(actionTypes.GET_BI_PAYMENTS_HISTORY_CURRENT_USER):
      return {
        ...state,
        biPaymentsHistoryCurrentUser: extract(action.response, []),
      }
    default:
      return state ? state : initialState
  }
}

export default PaymentsReducer
