import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Col, Modal, Row } from 'react-bootstrap'

import { getAccounts } from 'src/actions/banks.actions'
import { getReference } from 'src/actions/deposits.actions'

import FormTextField from 'src/components/inputs/FormTextField/FormText'
import SwitchV2 from 'src/components/inputs/Switch/SwitchV2'
import Select from 'src/components/inputs/Select/CustomSelect'
import CustomDatePicker from 'src/components/CustomDatePicker/CustomDatePicker'
import IconButton from 'src/components/buttons/IconButton'
import Button from 'src/components/buttons/Button'

import { selectGetAccountsForSelect } from 'src/selectors/banks.selector'
import { faFileInvoice, faSave } from '@fortawesome/free-solid-svg-icons'
import { isAllowed } from 'src/selectors/modules.selector'
import { depositsPermissions } from 'src/enums/permissions'
import { selectReference } from 'src/selectors/deposits.selector'
import { haveAnyValue } from 'src/utils/utilitiesV2'

interface IProps {
  onActive?: (active: boolean) => void
  onUpdate: (details: IBankTransfer) => void
  active?: boolean
  bankDetails?: IBankTransfer
  modal?: boolean
  show?: boolean
  onHide?: () => void
  allFields?: boolean
}

let interval = null
/**
 * Component to set bank detail of deposit.
 * @component
 * @param {function} onActive - Callback function to set the active state of the component
 * @param {function} onUpdate - Callback function to set the details of the bank transfer
 * @param {boolean} active - Show bank details
 * @param {IBankTransfer} bankDetails - Bank details of the deposit
 * @param {boolean} modal - Show bank details in modal
 * @param {boolean} show - Show modal
 * @param {function} onHide - Callback function to hide modal
 * @param {boolean} allFields - Show all fields
 */
export const BankDetails = ({
  onActive,
  onUpdate,
  active = false,
  bankDetails,
  modal,
  show,
  onHide,
  allFields,
}: IProps) => {
  const dispatch = useDispatch()
  const [enterBankDetails, setEnterBankDetails] = useState(active)
  const accounts = useSelector(selectGetAccountsForSelect)
  const referenceValue = useSelector(selectReference)

  const numberReference = useSelector(state =>
    isAllowed(state, [depositsPermissions.canRepiteReference]),
  )

  const [details, setDetails] = useState<IBankTransfer>(
    bankDetails || {
      bank: null,
      registerDate: null,
      number: '',
      repeated: false,
      description: '',
      currency: null,
    },
  )

  useEffect(() => {
    if (accounts.length === 0) dispatch(getAccounts(false))
  }, [])

  useEffect(() => {
    setDetails({ ...details, repeated: referenceValue })
  }, [referenceValue])

  useEffect(() => {
    onUpdate(details.bank && details.number ? details : null)
  }, [details])

  const detailsTransfer = () => {
    return (
      <Row>
        {!allFields && (
          <Col xl={12} xs={12}>
            <SwitchV2
              id={'switchDetails'}
              label={`Agregar datos bancarios`}
              info={
                'Si no se ingresan datos se tomara la cuenta caja de efectivo de la empresa.'
              }
              checked={enterBankDetails}
              onChange={value => {
                setEnterBankDetails(value)
                if (onActive) onActive(value)
                if (!value)
                  setDetails({
                    bank: null,
                    registerDate: null,
                    number: null,
                    repeated: false,
                  })
              }}
            />
          </Col>
        )}
        {enterBankDetails && (
          <>
            <Col xl={12} md={12}>
              <Select
                mt={0}
                label={'Cuenta de Banco'}
                isSearchable={false}
                options={accounts}
                value={accounts?.find(account => account.id === details.bank)}
                required
                onChange={account =>
                  setDetails({
                    ...details,
                    bank: account.id,
                    currency: account.currency,
                  })
                }
              />
            </Col>
            <Col xl={6} md={6} xs={12}>
              <CustomDatePicker
                label={'Fecha de registro'}
                value={details.date}
                disabledDays={{ after: new Date() }}
                onDayChange={date =>
                  setDetails({
                    ...details,
                    registerDate: date.valueOf(),
                    date,
                  })
                }
              />
            </Col>
            <Col xl={6} md={6} xs={12}>
              <FormTextField
                id={'number'}
                label={'Número de referencia'}
                type={'number'}
                name={'number'}
                data-testid={'number'}
                value={details.number}
                disabled={!haveAnyValue(details.bank)}
                onChange={({ target }) => {
                  const { value } = target
                  setDetails({ ...details, number: value })
                  if (value !== null && value !== undefined && value !== '') {
                    clearTimeout(interval)
                    interval = setTimeout(() => {
                      dispatch(getReference(target.value, { accountId: details.bank }))
                    }, 750)
                  }
                }}
                required
                sub={
                  numberReference && details.number && details.repeated
                    ? 'Nota: El número de referencia ya fue ingresado anteriormente.'
                    : null
                }
                append={<IconButton icon={faFileInvoice} tooltip={'Referencia'} />}
              />
            </Col>{' '}
            {allFields && (
              <>
                <Col xl={12} md={12} lg={12} sm={12}>
                  <FormTextField
                    as={'textarea'}
                    rows={3}
                    label={'Descripción'}
                    placeholder={'opcional'}
                    name={'description'}
                    value={details.description}
                    onChange={({ target }) => {
                      setDetails({ ...details, description: target.value })
                    }}
                    max={300}
                  />
                </Col>
              </>
            )}
          </>
        )}
      </Row>
    )
  }

  return !modal ? (
    detailsTransfer()
  ) : (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Datos bancarios</Modal.Title>
      </Modal.Header>
      <Modal.Body>{detailsTransfer()}</Modal.Body>
      <Modal.Footer>
        <div className={'container-buttons'}>
          <Button
            icon={faSave}
            disabled={enterBankDetails && !(details.bank && details.number)}
            onClick={onHide}>
            Continuar
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
