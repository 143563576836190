import React from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getClientsSimple } from 'src/actions/clients.actions'
import { setModalCreateClient } from 'src/actions/utilities.actions'
import CreateClientIndex from 'src/content/Clients/Create/CreateClientIndex'
import { clientPermissions } from 'src/enums/permissions'
import { selectIdentificationTypes } from 'src/selectors/company.selector'
import { isAllowed } from 'src/selectors/modules.selector'
import { selectCurrentUser } from 'src/selectors/user.selector'
import { selectModalCreateClient } from 'src/selectors/utilities.selector'

/**
 * @component ModalCreateClient
 * @description Client creation modal
 */
const ModalCreateClient = () => {
  const dispatch = useDispatch()

  const user = useSelector(selectCurrentUser)
  const identificationTypes = useSelector(selectIdentificationTypes)
  const modalCreateClient = useSelector(selectModalCreateClient)

  const canSeeAllClients = useSelector(state =>
    isAllowed(state, [clientPermissions.viewAll]),
  )

  return (
    <Modal
      show={modalCreateClient.show}
      centered
      size={'xl'}
      onHide={() => dispatch(setModalCreateClient({ show: false }))}>
      <Modal.Header closeButton>
        <Modal.Title>Cliente nuevo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CreateClientIndex
          inModal
          onlyCloseModal={response => {
            if (canSeeAllClients) dispatch(getClientsSimple({ attendant: null }))
            else dispatch(getClientsSimple({ attendant: user.id }))

            const identifications = {}
            identificationTypes.forEach(
              identificationType =>
                (identifications[identificationType.id] = {
                  identificationTypeId: identificationType.id,
                  value: '',
                }),
            )
            response.identifications?.forEach(
              identification =>
                (identifications[identification.identificationTypeId] = identification),
            )

            dispatch(
              setModalCreateClient({
                show: false,
              }),
            )

            modalCreateClient.onCreateOption?.(response, identifications)
          }}
        />
      </Modal.Body>
    </Modal>
  )
}
export default ModalCreateClient
