import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Card, FormText, Paragraph, Switch } from 'src/components'
import NumberField from 'src/components/NumberField/NumberField'
import CustomSelect from 'src/components/inputs/Select/CustomSelect'
import { selectMeasurementUnits } from 'src/selectors/products.selector'

const defaultOption = { value: null, label: 'Sin seleccionar' }

/**
 * Playground for the NumberField component
 */
const NumberFieldPlayground = () => {
  const [decimals, setDecimals] = useState(0)
  const [value, setValue] = useState(0)
  const [label, setLabel] = useState('Componente')
  const [measurementUnit, setMeasurementUnit] = useState(defaultOption)
  const [isMoney, setIsMoney] = useState(false)
  const [isAppendBefore, setIsAppendBefore] = useState(false)

  const measurementUnits = useSelector(selectMeasurementUnits)

  const measurementUnitsOptions = [defaultOption].concat(
    measurementUnits.map(item => ({
      label: `${item.name}${item.symbol ? ` (${item.symbol})` : ''}`,
      value: item.id,
    })),
  )

  return (
    <Card white title="Componente de números">
      <Row style={{ alignItems: 'center' }}>
        <Col xs={12} md={6} style={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
          <Paragraph>Este componente es un input que solo acepta números.</Paragraph>
          <NumberField
            label="Decimales (0-6)"
            value={decimals}
            onValueChange={setDecimals}
            min={0}
            max={6}
          />
          <FormText label="Label" value={label} changeValue={setLabel} />
          <CustomSelect
            label={'Unidad de medida'}
            value={measurementUnit}
            options={measurementUnitsOptions}
            onChange={setMeasurementUnit}
            disabled={isMoney}
          />
          <Switch
            label={'Símbolo de divisa'}
            checked={isMoney}
            changeValue={setIsMoney}
          />
          <Switch
            label={'Símbolo antes del input'}
            checked={isAppendBefore}
            changeValue={setIsAppendBefore}
          />
        </Col>
        <Col xs={12} md={6}>
          <NumberField
            label={label}
            value={value}
            onValueChange={setValue}
            decimals={decimals}
            measurementUnit={measurementUnit.value}
            isMoney={isMoney}
            appendBefore={isAppendBefore}
          />
        </Col>
      </Row>
    </Card>
  )
}

export default NumberFieldPlayground
