import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Media } from 'react-breakpoints'

import './CompanyAccount.scss'
import { Col, Modal, ProgressBar, Row } from 'react-bootstrap'
import { Button, Card, Icon, Title } from 'src/components'
import {
  selectGetCompanyFields,
  selectGetSingleCompany,
} from 'src/selectors/company.selector'
import IconCompany from 'src/assets/v2/icon-company-card.svg'
import pdfIcon from 'src/assets/images/svg/pdf.svg'
import pdfEmptyIcon from 'src/assets/images/svg/pdf-empty.svg'
import AddImage from 'src/assets/v2/add-image.svg'
import DeleteImage from 'src/assets/v2/icon-delete.svg'
import {
  actionTypes as companyActions,
  getCurrentCompany,
  getEditableFields,
  setEditableFields,
} from 'src/actions/company.actions'
import { FilePicker } from 'react-file-picker'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  actionTypes as uploadActions,
  deleteFile,
  getDocuments,
  uploadDocument,
  uploadFile,
} from 'src/actions/uploads.actions'
import FormTextField from 'src/components/inputs/FormTextField/FormTextField'
import { Empty } from 'src/components/Empty/Empty'
import { history } from 'src/App'
import PlanItem from 'src/content/Distrbution/Plans/PlanItem'
import {
  actions as planActions,
  assignDLC,
  deleteDLC,
  setActivePlans,
} from 'src/actions/plans.actions'
import { selectActivePlans } from 'src/selectors/plans.selector'
import {
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'
import Alert from 'sweetalert-react'
import { formatDateToNameFromMillis } from 'src/utils/formatters'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { getGroups } from 'src/actions/modules.actions'
import { selectDocuments } from 'src/selectors/uploads.selector'
import cardPlaceHolder from 'src/assets/v2/card-placeholder.png'
import {
  setModalInvoicePackages,
  setModalManageCards,
  setModalUsers,
} from 'src/actions/utilities.actions'

const CompanyAccount = props => {
  const dispatch = useDispatch()

  const company = useSelector(selectGetSingleCompany)

  const documents = useSelector(selectDocuments)

  const editable = useSelector(selectGetCompanyFields)
  const editableLoading = useSelector(state =>
    loadingSelector([companyActions.GET_COMPANIES_EDITABLE_FIELDS])(state),
  )
  const uploadImageLoading = useSelector(state =>
    loadingSelector([uploadActions.UPLOAD])(state),
  )
  const loading = useSelector(state =>
    loadingSelector([companyActions.SET_COMPANIES_EDITABLE_FIELDS])(state),
  )
  const hasError = useSelector(state =>
    hasErrorsSelector([companyActions.SET_COMPANIES_EDITABLE_FIELDS])(state),
  )
  const error = useSelector(state =>
    singleErrorSelector([companyActions.SET_COMPANIES_EDITABLE_FIELDS])(state),
  )

  const plan = useSelector(selectActivePlans)
  const loadingPlan = useSelector(state =>
    loadingSelector([planActions.GET_ACTIVE_PLAN])(state),
  )

  const dlcLoading = useSelector(state => loadingSelector([planActions.ADD_DLC])(state))
  const dlcHasError = useSelector(state =>
    hasErrorsSelector([planActions.ADD_DLC])(state),
  )
  const dlcError = useSelector(state => singleErrorSelector([planActions.ADD_DLC])(state))

  const loadingDeleteFile = useSelector(state =>
    loadingSelector([uploadActions.DELETE_FILE])(state),
  )

  const [uploadProgress, setUploadProgress] = useState(0)
  const [alert, setAlert] = useState({ show: false })

  const [action, setAction] = useState({
    editable: false,
    upload: false,
    delete: false,
    dlc: false,
  })
  const [fields, setFields] = useState({})
  const [modalDlc, setModalDlc] = useState({ show: false, items: [] })
  const [uploadDoc, setUploadDoc] = useState({})

  const DocumentType = { DPI: 1, PATENTE: 2, RTU: 3 }

  const daysLeft =
    15 - Math.ceil((Date.now() - new Date(company.createdAt)) / (1000 * 60 * 60 * 24))

  useEffect(() => {
    dispatch(getEditableFields())
    dispatch(getDocuments())
  }, [])

  useEffect(() => {
    if (editableLoading) setAction({ ...action, editable: true })
    else if (action.editable) {
      setAction({ ...action, editable: false })
      setFields({ ...editable, editable: '' })
    }
  }, [editableLoading])

  useEffect(() => {
    if (dlcLoading) setAction({ ...action, dlc: true })
    else if (action.dlc) {
      setAction({ ...action, dlc: false })
      if (dlcHasError) {
        setAlert({
          ...handlerError(dlcError.message || 'No se ha podido cambiar de plan'),
          onConfirm: () => setAlert({ show: false }),
        })
      } else {
        setAlert({
          ...handlerSuccess('Plan actualizado exitosamente'),
          onConfirm: () => {
            setAlert({ show: false })
            dispatch(getGroups(1000))
          },
        })
        setModalDlc({ show: false, items: [] })
      }
    }
  }, [dlcLoading])

  useEffect(() => {
    if (loading) setAction({ ...action, upload: true })
    else if (action.upload) {
      setAction({ ...action, upload: false })
      if (hasError) {
        setAlert({
          ...handlerError(error || 'Error inesperado'),
          onConfirm: () => setAlert({ show: false }),
        })
      } else {
        setAlert({
          ...handlerSuccess('Se actualizaron los campos'),
          onConfirm: () => setAlert({ show: false }),
        })
        setFields({ ...fields, editable: '' })
        dispatch(getEditableFields())
      }
    }
  }, [loading])

  const saveEdited = () => {
    let {
      email,
      comercialName,
      comercialAddress,
      phone,
      description,
      webSite,
      history,
      shortName,
    } = fields
    if (!phone) phone = ''
    if (!description) description = ''
    if (!webSite) webSite = ''
    if (!history) history = ''
    if (!shortName) shortName = ''
    dispatch(
      setEditableFields(
        email,
        comercialName,
        comercialAddress,
        phone,
        description,
        history,
        webSite,
        shortName,
      ),
    )
  }

  const onProgress = progress => setUploadProgress(progress)

  const onError = () => {
    this.setState({
      progress: 0,
      showError: true,
      errorText: 'Lo sentimos, no fué posible subir tu imagen en este momento',
    })
  }

  const onSuccess = () => {
    dispatch(getDocuments())
    setUploadProgress(0)
    setUploadDoc({ uploadIsLoading: false, docUpload: null })
  }

  const uploadDpi = file => {
    setUploadDoc({ uploadIsLoading: true, docUpload: DocumentType.DPI })
    dispatch(uploadDocument(file, DocumentType.DPI, onProgress, onSuccess, onError))
  }

  const uploadPatente = file => {
    setUploadDoc({ uploadIsLoading: true, docUpload: DocumentType.PATENTE })
    dispatch(uploadDocument(file, DocumentType.PATENTE, onProgress, onSuccess, onError))
  }

  const uploadRTU = file => {
    setUploadDoc({ uploadIsLoading: true, docUpload: DocumentType.RTU })
    dispatch(uploadDocument(file, DocumentType.RTU, onProgress, onSuccess, onError))
  }

  const uploadImage = file => {
    const onProgress = progress => setUploadProgress(progress)
    const onError = () => {
      setUploadProgress(0)
      setAlert({
        ...handlerError('Lo sentimos, no fué posible subir tu imagen en este momento'),
        onConfirm: () => setAlert({ show: false }),
      })
    }

    const onSuccess = () => {
      setUploadProgress(0)
      dispatch(getCurrentCompany())
    }

    dispatch(
      uploadFile({
        file,
        imageType: { id: 7, path: '/logo' },
        entityId: company.id,
        company: company.id,
        onProgress,
        onSuccess,
        onError,
      }),
    )
  }

  const deleteAction = image => {
    const onSuccess = () => {
      dispatch(getCurrentCompany())
    }
    dispatch(
      deleteFile({
        id: image.id,
        name: image.name,
        imageType: { id: 7, path: '/logo' },
        entityId: company.id,
        company: company.id,
        onSuccess,
      }),
    )
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    setFields({ ...fields, [name]: value })
  }

  const buttons = () => {
    return (
      <div className={'d-flex'}>
        {!company.defaulter && (
          <div
            className={'account-action mr-3'}
            onClick={() => {
              dispatch(
                setModalUsers({
                  show: true,
                  users: [...(plan.userList || []), ...(plan.extraUsers || [])],
                }),
              )
            }}>
            Ver usuarios ({[...(plan.userList || []), ...(plan.extraUsers || [])].length})
          </div>
        )}
      </div>
    )
  }

  const addButton = item => {
    return dlcLoading ? (
      <div>
        <Icon icon={faSpinner} spin={dlcLoading} size={'1_5x'} />
      </div>
    ) : (
      <div
        className={'account-action'}
        onClick={() => {
          if (item.assigned) dispatch(deleteDLC(item.id))
          else dispatch(assignDLC(item.id))
        }}>
        {item.assigned ? 'Quitar' : 'Agregar'}
      </div>
    )
  }

  return (
    <>
      <Title title={'Mi Cuenta'} />
      {(editableLoading || loadingPlan) && (
        <Row>
          <Col>
            <div className={'pb-custom'}>
              <ProgressBar
                label="Cargando"
                animated
                now={100}
                style={{ marginBottom: 10 }}
              />
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Card white title={'Membresía y Facturación'}>
            <Row>
              <Col xs={12} md={3} lg={3} xl={2} style={{ textAlign: 'center' }}>
                {company.logo ? (
                  <div className={'account-add-logo'} style={{ position: 'relative' }}>
                    <img
                      style={{ objectFit: 'contain' }}
                      alt={'logo'}
                      src={company.logo ? company.logo.url : IconCompany}
                      height={205}
                      width={205}
                    />
                    <div
                      className="ik-delete a-in-opacity"
                      onClick={() => {
                        deleteAction(company.logo)
                      }}>
                      {!loadingDeleteFile ? (
                        <img src={DeleteImage} alt={'-'} />
                      ) : (
                        <Icon icon={faSpinner} spin={loadingDeleteFile} size={'1_5x'} />
                      )}
                    </div>
                  </div>
                ) : (
                  <div className={'account-add-logo'} style={{ background: '#797c8038' }}>
                    <FilePicker
                      maxSize={5}
                      extensions={['jpg', 'jpeg', 'png']}
                      onChange={uploadImage}
                      onError={errMsg => {
                        setAlert({
                          ...handlerError(errMsg),
                          onConfirm: () => setAlert({ show: false }),
                        })
                      }}>
                      <div>
                        <img
                          style={{ cursor: 'pointer' }}
                          alt={'logo'}
                          src={AddImage}
                          height={75}
                          width={75}
                          onClick={uploadImage}
                        />
                        <div className="img-kolo-text">agregar imagen</div>
                        {uploadImageLoading && (
                          <ProgressBar
                            style={{ marginTop: 5 }}
                            variant={'success'}
                            now={uploadProgress}
                            label={`${uploadProgress.toFixed(2)}%`}
                          />
                        )}
                      </div>
                    </FilePicker>
                  </div>
                )}
              </Col>
              <Col xs={12} md={9} lg={9} xl={10}>
                <Media>
                  {({ breakpoints, currentBreakpoint }) => {
                    const mobile = breakpoints[currentBreakpoint] < breakpoints.tablet
                    return !mobile ? (
                      <div className={'d-flex justify-content-between'}>
                        <h4
                          style={{ color: '#525252', fontWeight: 700 }}
                          className={'mt-1'}>
                          {editable.comercialName}
                        </h4>
                        {company && company.test && (
                          <div className={'account-legend'} style={{ color: '#a21e8c' }}>
                            LA CUENTA SE ENCUENTRA EN ESTADO DEMO
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        {company && company.test && (
                          <div
                            style={{
                              textAlign: 'center',
                              marginTop: 20,
                              marginBottom: '5px',
                              color: '#a21e8c',
                            }}
                            className={'account-legend'}>
                            LA CUENTA SE ENCUENTRA EN ESTADO DEMO
                          </div>
                        )}
                        <h4
                          style={{ color: '#525252', fontWeight: 700 }}
                          className={'mt-1'}>
                          {editable.comercialName}
                        </h4>
                      </div>
                    )
                  }}
                </Media>

                <div
                  className={'d-flex justify-content-between'}
                  style={{ padding: '6px 0' }}>
                  {fields.editable === 'email' ? (
                    <>
                      <FormTextField
                        withoutLabel
                        mt={0}
                        type={'text'}
                        parentStyle={{ width: '50%' }}
                        id={'email'}
                        placeholder={'Correo Electrónico'}
                        name={'email'}
                        value={fields.email}
                        onChange={handleInputChange}
                      />
                      <div className={'account-action'} onClick={saveEdited}>
                        Guardar Correo
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={'mt-1 account-content'}>
                        <span className={'account-title'}>Correo de contacto: </span>
                        {editable.email}
                      </div>
                      {fields.editable === '' && (
                        <div
                          className={'account-action'}
                          onClick={() => setFields({ ...fields, editable: 'email' })}>
                          Cambiar Correo
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div
                  className={'d-flex justify-content-between'}
                  style={{ padding: '6px 0' }}>
                  {fields.editable === 'phone' ? (
                    <>
                      <FormTextField
                        withoutLabel
                        mt={0}
                        type={'text'}
                        parentStyle={{ width: '50%' }}
                        id={'phone'}
                        placeholder={'Teléfono'}
                        name={'phone'}
                        value={fields.phone}
                        onChange={handleInputChange}
                      />
                      <div className={'account-action'} onClick={saveEdited}>
                        Guardar Teléfono
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={'mt-1 account-content'}>
                        <span className={'account-title'}>Teléfono: </span>
                        {editable.phone}
                      </div>
                      {fields.editable === '' && (
                        <div
                          className={'account-action'}
                          onClick={() => setFields({ ...fields, editable: 'phone' })}>
                          Cambiar Teléfono
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div
                  className={'d-flex justify-content-between'}
                  style={{ padding: '6px 0' }}>
                  {fields.editable === 'address' ? (
                    <>
                      <FormTextField
                        withoutLabel
                        mt={0}
                        type={'text'}
                        parentStyle={{ width: '50%' }}
                        id={'comercialAddress'}
                        placeholder={'Guardar Ciudad'}
                        name={'comercialAddress'}
                        value={fields.comercialAddress}
                        onChange={handleInputChange}
                      />
                      <div className={'account-action'} onClick={saveEdited}>
                        Guardar Ciudad
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={'mt-1 account-content'}>
                        <span className={'account-title'}>Dirección: </span>
                        {editable.comercialAddress}
                      </div>
                      {fields.editable === '' && (
                        <div
                          className={'account-action'}
                          onClick={() => setFields({ ...fields, editable: 'address' })}>
                          Cambiar Dirección
                        </div>
                      )}
                    </>
                  )}
                </div>
                {!company.test && (
                  <div
                    className={'d-flex justify-content-between'}
                    style={{ padding: '6px 0' }}>
                    <div className={'mt-1 account-content'}>
                      <span className={'account-title'}>Siguiente facturación: </span>
                      {formatDateToNameFromMillis(plan.endDate)}
                    </div>
                  </div>
                )}

                <div
                  className={'d-flex justify-content-between'}
                  style={{ padding: '6px 0' }}>
                  <div
                    className={'d-flex justify-content-between'}
                    style={{ padding: '6px 0' }}>
                    <div className={'mt-1 account-content'}>
                      <span className={'account-title'}>Facturas electrónicas:</span>
                      {company.externalEmissionFEL
                        ? 'Convenio directo con el certificador de facturas'
                        : `${company.features?.freeInvoices} usadas
                      de ${company.features?.invoices}`}
                    </div>
                  </div>
                  {!company.externalEmissionFEL && (
                    <div
                      className={'account-action'}
                      onClick={() => dispatch(setModalInvoicePackages({ show: true }))}>
                      Comprar Facturas Electrónicas
                    </div>
                  )}
                </div>
                <div
                  className={'d-flex justify-content-between'}
                  style={{ padding: '6px 0' }}>
                  <Button
                    color={'secondary'}
                    img={cardPlaceHolder}
                    onClick={() => {
                      dispatch(setModalManageCards({ show: true }))
                    }}>
                    Manejar tarjetas
                  </Button>
                  <div
                    className={'account-action'}
                    onClick={() => history.push('/perfil/cuenta')}>
                    Ver estado de cuenta
                  </div>
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col className={'text-center'} xs={6} md={2}>
                {documents &&
                documents.filter(f => f.legalDocumentType === DocumentType.DPI).length >
                  0 ? (
                  <div
                    style={{ paddingTop: 10, cursor: 'pointer' }}
                    onClick={() =>
                      window.open(
                        documents.filter(f => f.legalDocumentType === DocumentType.DPI)[0]
                          .url,
                        '_blank',
                      )
                    }>
                    <img
                      style={{
                        objectFit: 'contain',
                        border: '1px solid #e8e8e8',
                        borderRadius: 5,
                        padding: 5,
                      }}
                      alt={'archivo'}
                      src={pdfIcon}
                      height={100}
                      width={100}
                    />
                    <div>DPI representante legal</div>
                  </div>
                ) : (
                  <FilePicker
                    maxSize={5}
                    extensions={['pdf']}
                    onChange={uploadDpi}
                    onError={errMsg =>
                      this.setState({ showError: true, errorText: errMsg })
                    }>
                    <div style={{ paddingTop: 10, cursor: 'pointer' }}>
                      <div>
                        <img
                          style={{
                            objectFit: 'contain',
                            border: '1px solid #e8e8e8',
                            borderRadius: 5,
                            padding: 5,
                          }}
                          alt={'archivo'}
                          src={pdfEmptyIcon}
                          height={100}
                          width={100}
                        />
                        <div>DPI representante legal</div>
                      </div>
                      {uploadDoc.uploadIsLoading &&
                      uploadDoc.docUpload === DocumentType.DPI ? (
                        <ProgressBar
                          variant="success"
                          style={{ marginTop: 5 }}
                          now={uploadProgress}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </FilePicker>
                )}
              </Col>
              <Col className={'text-center'} xs={6} md={2}>
                {documents &&
                documents.filter(f => f.legalDocumentType === DocumentType.PATENTE)
                  .length > 0 ? (
                  <div
                    style={{ paddingTop: 10, cursor: 'pointer' }}
                    onClick={() =>
                      window.open(
                        documents.filter(
                          f => f.legalDocumentType === DocumentType.PATENTE,
                        )[0].url,
                        '_blank',
                      )
                    }>
                    <img
                      style={{
                        objectFit: 'contain',
                        border: '1px solid #e8e8e8',
                        borderRadius: 5,
                        padding: 5,
                      }}
                      alt={'archivo'}
                      src={pdfIcon}
                      height={100}
                      width={100}
                    />
                    <div>Patente de Comercio</div>
                  </div>
                ) : (
                  <FilePicker
                    maxSize={5}
                    extensions={['pdf']}
                    onChange={uploadPatente}
                    onError={errMsg =>
                      this.setState({ showError: true, errorText: errMsg })
                    }>
                    <div style={{ paddingTop: 10, cursor: 'pointer' }}>
                      <div>
                        <img
                          style={{
                            objectFit: 'contain',
                            border: '1px solid #e8e8e8',
                            borderRadius: 5,
                            padding: 5,
                          }}
                          alt={'archivo'}
                          src={pdfEmptyIcon}
                          height={100}
                          width={100}
                        />
                        <div>Patente de Comercio</div>
                      </div>
                      {uploadDoc.uploadIsLoading &&
                      uploadDoc.docUpload === DocumentType.PATENTE ? (
                        <ProgressBar
                          variant="success"
                          style={{ marginTop: 5 }}
                          now={uploadProgress}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </FilePicker>
                )}
              </Col>
              <Col className={'text-center'} xs={6} md={2}>
                {documents &&
                documents.filter(f => f.legalDocumentType === DocumentType.RTU).length >
                  0 ? (
                  <div
                    style={{ paddingTop: 10, cursor: 'pointer' }}
                    onClick={() =>
                      window.open(
                        documents.filter(f => f.legalDocumentType === DocumentType.RTU)[0]
                          .url,
                        '_blank',
                      )
                    }>
                    <img
                      style={{
                        objectFit: 'contain',
                        border: '1px solid #e8e8e8',
                        borderRadius: 5,
                        padding: 5,
                      }}
                      alt={'archivo'}
                      src={pdfIcon}
                      height={100}
                      width={100}
                    />
                    <div>Constancia de RTU</div>
                  </div>
                ) : (
                  <FilePicker
                    maxSize={5}
                    extensions={['pdf']}
                    onChange={uploadRTU}
                    onError={errMsg =>
                      this.setState({ showError: true, errorText: errMsg })
                    }>
                    <div style={{ paddingTop: 10, cursor: 'pointer' }}>
                      <div>
                        <img
                          style={{
                            objectFit: 'contain',
                            border: '1px solid #e8e8e8',
                            borderRadius: 5,
                            padding: 5,
                          }}
                          alt={'archivo'}
                          src={pdfEmptyIcon}
                          height={100}
                          width={100}
                        />
                        <div>Constancia de RTU</div>
                      </div>
                      {uploadDoc.uploadIsLoading &&
                      uploadDoc.docUpload === DocumentType.RTU ? (
                        <ProgressBar
                          variant="success"
                          style={{ marginTop: 5 }}
                          now={uploadProgress}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </FilePicker>
                )}
              </Col>
            </Row>
          </Card>
          {plan?.id ? (
            <PlanItem
              history={props.history}
              item={plan}
              button={buttons()}
              onUpgrade={modal => setModalDlc(modal)}
              onShow={value => {
                plan.total = value
                dispatch(setActivePlans(plan))
              }}
              isActive={!company.defaulter}
              userSpaces={company.features?.userSpaces}
              addons={company.features}
            />
          ) : (
            !loadingPlan && (
              <Card>
                <Empty
                  title={'No tienes un plan activo'}
                  buttonTitle={'Adquirir Plan'}
                  subtitle={`Quedan ${daysLeft} días para que tu cuenta demo expire.`}
                  subtitleSecond={
                    'Puedes llamar o mandar un WhatsApp al +502 4525 2182 para más información.'
                  }
                  onClick={() => {
                    history.push('/distribucion/planes')
                  }}
                />
              </Card>
            )
          )}
        </Col>
      </Row>

      <Modal
        show={modalDlc.show}
        centered
        size={'xl'}
        onHide={() => {
          setModalDlc({ show: false, items: [] })
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Aplicaciones Extras</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={'column'}>
            {modalDlc.items.map((p, index) => (
              <Row key={index}>
                <Col>
                  <PlanItem {...props} item={p} button={addButton(p)} />
                </Col>
              </Row>
            ))}
          </div>
        </Modal.Body>
      </Modal>

      <Alert {...alert} />
    </>
  )
}

export default CompanyAccount
