import React, { Component } from 'react'
import { connect } from 'react-redux'

import '../Subscriptions.scss'

import {
  Col,
  Row,
  Button,
  InputGroup,
  FormControl,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Alert,
  ToggleButtonGroup,
  ToggleButton,
} from 'react-bootstrap'

import ModalSelectPeriodicity from './ModalSelectPeriodicity'

import { Td, Tr } from 'react-super-responsive-table'
import { formatNumberWithCommas } from '../../../../utils/formatters'
import ModalSelectProducts from './ModalSelectProducts'
import CollapsibleCard from '../../../../components/cards/collapsible-card/CollapsibleCard'
import { saveNewSubscription } from '../../../../actions/subscriptions.actions'
import { loadingSelector } from '../../../../selectors/loading.selector'
import { actionTypes } from '../../../../actions/subscriptions.actions'
import {
  hasErrorsSelector,
  singleErrorSelector,
} from '../../../../selectors/error.selector'
import SweetAlert from 'sweetalert-react'
import ModalSelectPolygons from './ModalSelectPolygons'
import { faEye } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'
import { getAllLocalities } from '../../../../actions/address.actions'
import { selectAllLocalitiesForSelect } from '../../../../selectors/address.selector'
import Money from '../../../../components/Money/Money'
import { toMoney } from '../../../../utils/utilities'
import { selectCurrentCurrency } from '../../../../selectors/currencies.selector'
import { TableV2 } from '../../../../components'

const offColors = ['#dc3445', '#de773c', '#e0ba32', '#7db754', '#22B573']

class SubscriptionCreate extends Component {
  state = {
    showClientModal: false,
    showPeriodicityModal: false,
    showProductsModal: false,

    client: {},
    interval: {},

    periodicity: null,

    products: [],
    warehouse: null,
    generalDiscount: 0,

    title: '',
    subtitle: '',

    showSubscriptionSuccess: false,

    showPolygonsModal: false,
    polygons: [],

    drawers: 1,
    weight: 70,

    tWidth: 0,
    tHeight: 0,
    tLong: 0,

    latitude: 14.55,
    longitude: -90.55,

    totalSubscription: 0,

    seePC: false,
    showSameValue: false,
    errorMessage: '',

    showNewSubscriptionError: false,

    typeSubscription: 1,
  }

  componentDidMount () {}

  componentWillReceiveProps (next) {
    const { newSubscriptionIsLoading, newSubscriptionHasError } = this.props
    if (newSubscriptionIsLoading && !next.newSubscriptionIsLoading) {
      if (!newSubscriptionHasError && !next.newSubscriptionHasError) {
        this.setState({
          showSubscriptionSuccess: true,
        })
      } else {
        this.setState({ showNewSubscriptionError: true, showSubscriptionSuccess: false })
      }
    }
  }

  onHideClientModal = () => this.setState({ showClientModal: false })
  onHidePeriodicityModal = () => this.setState({ showPeriodicityModal: false })
  onHideProductsModal = () => this.setState({ showProductsModal: false })

  onHidePolygonsModal = () => this.setState({ showPolygonsModal: false })

  // onSelectClient = (client) => this.setState({client, showClientModal: false});
  onSelectPeriodicity = periodicity =>
    this.setState({ periodicity, showPeriodicityModal: false })
  onSelectProducts = (products, warehouse) =>
    this.setState({ products, warehouse, showProductsModal: false })

  onSelectPolygons = polygons => this.setState({ polygons, showPolygonsModal: false })

  totalProductDetail = () => {
    const { products } = this.state
    let total = 0
    products
      .filter(i => i.checked)
      .map(product => {
        total += product.productPrice * product.realQuantity - product.discount
      })
    return total
  }

  subtotalSubscription = () => {
    const { products, periodicity } = this.state
    let total = 0

    if (periodicity && periodicity.times) {
      products
        .filter(i => i.checked)
        .map(product => {
          total += product.productPrice * product.realQuantity - product.discount
        })
      total = total * periodicity.times
    }
    return formatNumberWithCommas(total)
  }

  totalSubscription = () => {
    const { products, generalDiscount, periodicity } = this.state
    let total = 0
    let discount = 0

    if (periodicity && periodicity.times) {
      products
        .filter(i => i.checked)
        .map(product => {
          total += product.productPrice * product.realQuantity - product.discount
        })
      discount = (total * generalDiscount) / 100

      return formatNumberWithCommas((total - discount) * periodicity.times)
    }
    return formatNumberWithCommas(0)
  }

  onChangeGeneralDiscount = value => {
    const { products } = this.state
    let subtotal = 0
    products
      .filter(i => i.checked)
      .map(product => {
        subtotal += product.productPrice * product.realQuantity - product.discount
      })

    if (value >= 0 && value < 100) {
      const total = subtotal - (subtotal * value) / 100

      this.setState({
        generalDiscount: value,
        totalSubscription: total,
      })
    }
  }

  onChangeDrawers = value => {
    const { weight } = this.state
    if (value > 0) {
      this.setState({ drawers: Math.floor(value) })
      let dw = Math.floor(value) * 70
      if (weight < dw || weight > dw + 69) {
        this.setState({ weight: dw })
      }
    }
  }

  onChangeWeight = value => {
    if (value > 0) {
      this.setState({ weight: value })
      let w = value / 70
      let d = Math.ceil(w)
      this.setState({ drawers: d })
    }
  }

  onChangeHeight = value => {
    if (value > 0) {
      this.setState({ tHeight: value })
    }
  }

  onChangeWidth = value => {
    if (value > 0) {
      this.setState({ tWidth: value })
    }
  }

  onChangeLong = value => {
    if (value > 0) {
      this.setState({ tLong: value })
    }
  }

  onChangeTotal = (value, item) => {
    if (value >= 0) {
      const { polygons } = this.state

      polygons.map(i => {
        if (i.id === item.id) {
          i.total = parseFloat(value)
        }
      })

      this.setState({ polygons })
    }
  }

  onChangeDiscount = (value, item) => {
    if (value >= 0 && value < 100) {
      const { polygons } = this.state

      polygons.map(i => {
        if (i.id === item.id) {
          i.discount = value
        }
      })

      this.setState({ polygons })
    }
  }

  onChangeTitle = value => {
    this.setState({ title: value })
  }

  onChangeSubtitle = value => {
    this.setState({ subtitle: value })
  }

  save = () => {
    const {
      products,
      periodicity,
      polygons,
      title,
      subtitle,
      generalDiscount,
      drawers,
      weight,
      tHeight,
      tWidth,
      tLong,
      typeSubscription,
    } = this.state

    let error = false
    for (let i = 0, len = polygons.length; i < len; i++) {
      if (polygons[i].total === 0) {
        error = true
        break
      }
    }

    if (error) {
      this.setState({
        showNewSubscriptionError: true,
        errorMessage: 'Existen suscripciones pendientes de definir su valor',
      })
    } else {
      let itemsPro = []
      products
        .filter(i => i.checked)
        .map(product => {
          const { productId, realQuantity, discount } = product
          itemsPro.push({
            product: productId,
            quantity: realQuantity,
            discount,
          })
        })

      let itemsPol = polygons.map(polygon => {
        const totalSubscription = polygon.total
        const price = polygon.returnable
          ? polygon.pickUpPoint.priceReturnable
          : polygon.pickUpPoint.priceNonReturnable
        const valueRaw = (totalSubscription - price) / 1.17
        const difference = 1 - valueRaw / this.totalProductDetail()

        return {
          polygon: polygon.id,
          price,
          shipperId: polygon.companyId,
          returnable: polygon.returnable !== 0,
          ppLatitude: polygon.pickUpPoint.latitude,
          ppLongitude: polygon.pickUpPoint.longitude,
          difference,
          totalSubscription,
          deliveryDays: polygon.pickUpPoint.deliveryDays,
          pickUpDays: polygon.pickUpPoint.pickUpDays,
          pickUpTimes: polygon.pickUpPoint.pickUpTimes,
        }
      })

      const subscription = {
        title,
        description: subtitle,
        intervalType: periodicity.interval.value,
        step: periodicity.step,
        times: periodicity.times,
        subscriptionDetail: itemsPro,
        polygons: itemsPol,
        generalDiscount,
        drawers,
        weight,
        volume: tHeight + ',' + tWidth + ',' + tLong,
        latitude: null,
        longitude: null,
        type: typeSubscription,
      }

      this.props.saveNewSubscription(subscription)
    }
  }

  renderRowPolygon = (item, index) => {
    // const {polygons} = this.state;
    //
    // let priceNonReturnable = 0;
    // let priceReturnable = 0;
    //
    // item.pickUpPoints.map((pickUpPoint) => {
    //     priceNonReturnable += pickUpPoint.priceNonReturnable;
    //     priceReturnable += pickUpPoint.priceReturnable;
    // });

    return (
      <Tr className={'data'} key={`${index}`}>
        <Td className={'short'}>{item.code}</Td>
        <Td className={'medium'}>{item.name}</Td>
        {/*<Money component={Td} className={'medium'}>{parseInt(item.price)}</Money>*/}
        <Money component={Td} className={'short'}>
          {item.returnable
            ? item.pickUpPoint.priceReturnable
            : item.pickUpPoint.priceNonReturnable}
        </Money>
        {/*<Money component={Td} className={'short'}>{item.pickUpPoint.priceReturnable}</Money>*/}
        {/*<Money component={Td} className={'short'}>{item.pickUpPoint.priceNonReturnable}</Money>*/}
        <Td className={'short'}>{item.returnable ? 'Si' : 'No'}</Td>
      </Tr>
    )
  }

  getData = data => {
    this.setState({
      latitude: data.latLng.lat,
      longitude: data.latLng.lng,
    })
  }

  getContent = subscriptionDetail => {
    let content = []
    subscriptionDetail
      .filter(i => i.checked)
      .filter((i, index) => index < 3)
      .map(product => {
        const { quantity, subtotal, discount, total, productName } = product
        content.push({
          name: productName,
          quantity: quantity,
          discount,
          subtotal,
          total,
        })
      })

    return content
  }

  getBackColor = discount => {
    if (discount > 40) {
      return offColors[0]
    } else if (discount > 30) {
      return offColors[1]
    } else if (discount > 20) {
      return offColors[2]
    } else if (discount > 10) {
      return offColors[3]
    } else {
      return offColors[4]
    }
  }

  test = () => {
    this.setState({ showSameValue: true })
  }

  down = () => {
    this.setState({ seePC: true })
  }

  up = () => {
    this.setState({ seePC: false })
  }

  setSameValue = inputValue => {
    if (inputValue > 0) {
      const { polygons } = this.state

      polygons.map(i => {
        i.total = parseFloat(inputValue)
      })

      this.setState({ polygons, showSameValue: false })
    }
  }

  handleType = value => {
    this.setState({ typeSubscription: value })
  }

  render () {
    const { newSubscriptionIsLoading, newSubscriptionError, currentCurrency } = this.props
    const {
      periodicity,
      products,
      title,
      subtitle,
      showSubscriptionSuccess,
      polygons,
      showNewSubscriptionError,
      drawers,
      seePC,
      showSameValue,
      errorMessage,
      typeSubscription,
    } = this.state

    let textPeriodicity = ''
    if (periodicity && periodicity.times)
      switch (periodicity.times) {
        case 1:
          textPeriodicity = '1 entrega el día programado'
          break
        case 2:
          textPeriodicity = '2 Entregas - 1 cada 15 días'
          break
        case 4:
          textPeriodicity = '4 Entregas - 1 cada semana'
          break
        default:
          textPeriodicity = ''
      }

    let drawerPrice = 0
    let quantityPickUpPoints = 0
    polygons
      .filter(d => d.checked)
      .map(polygon => {
        polygon.pickUpPoints.map(pickUpPoint => {
          quantityPickUpPoints++
          drawerPrice +=
            (pickUpPoint.priceNonReturnable + pickUpPoint.priceReturnable) / 2
        })
      })

    const averageCostTransport =
      quantityPickUpPoints > 0 ? (drawerPrice / quantityPickUpPoints) * drawers : 0

    const IVA = this.totalProductDetail() * 0.12
    const ISR = this.totalProductDetail() * 0.05

    let totalPreview = 0
    let valuePreview = 0
    let transportPreview = 0
    let discountPreview = 0

    // if (polygons.length > 0){
    //     transportPreview = polygons[0].returnable ? (polygons[0].pickUpPoint.priceReturnable * drawers) : (polygons[0].pickUpPoint.priceNonReturnable * drawers);
    //     totalPreview = polygons[0].total * (periodicity ? periodicity.times : 0);
    //     valueRawPreview = (totalPreview - transportPreview) / 1.17;
    //     discountPreview = 1 - (valueRawPreview / this.totalProductDetail());
    // }

    if (polygons.length > 0) {
      transportPreview = polygons[0].returnable
        ? polygons[0].pickUpPoint.priceReturnable * drawers
        : polygons[0].pickUpPoint.priceNonReturnable * drawers
      totalPreview = polygons[0].total * (periodicity ? periodicity.times : 0)
      valuePreview = this.totalProductDetail() * 1.17
      discountPreview = 1 - polygons[0].total / (valuePreview + transportPreview)
    }

    return (
      <div>
        {/*<Card title={'Nueva suscripción'}>*/}

        <div className={'back-subscription-card'}>
          <Row>
            <Col md={3} className={'sub-style'}>
              <h1 style={{ marginLeft: 10 }}>Vista previa</h1>

              <div className={'preview-subscription-card sub-style'}>
                <div className="header-subs">
                  <div
                    style={{
                      height: 100,
                      backgroundColor: '#1b4060',
                      borderTopLeftRadius: '5px',
                      borderTopRightRadius: '5px',
                      textAlign: 'center',
                      paddingTop: 10,
                    }}>
                    <h1 style={{ color: 'white' }}>{title || 'Titulo'}</h1>
                    <h6 style={{ color: 'white' }}>{subtitle || 'Subtitulo'}</h6>
                  </div>
                  {discountPreview > 0 && (
                    <div
                      className="arrow-right"
                      style={{
                        backgroundColor: this.getBackColor(discountPreview * 100),
                      }}>
                      <span className="arrow-text">
                        <b>
                          {discountPreview - Math.floor(discountPreview) === 0
                            ? discountPreview * 100
                            : (discountPreview * 100).toFixed(4)}
                          %
                        </b>
                      </span>
                    </div>
                  )}
                </div>
                <Row style={{ margin: 10 }}>
                  <Col>
                    <h4>Contenido</h4>
                    {this.getContent(products).length > 0 ? (
                      this.getContent(products).map((item, index) => (
                        <p>{item.quantity + ' ' + item.name}</p>
                      ))
                    ) : (
                      <div>
                        <p>0 Producto ...</p>
                        <p>0 Producto ...</p>
                        <p>0 Producto ...</p>
                      </div>
                    )}

                    <br />
                    <h2>Cantidad de entregas</h2>
                    <p>
                      {periodicity
                        ? periodicity.times + (periodicity.times > 1 ? ' veces' : ' vez')
                        : 'No definido'}
                    </p>

                    <br />
                    <h2>Entregas cada</h2>
                    <p>
                      {periodicity
                        ? periodicity.step +
                          ' ' +
                          (periodicity.step == 1
                            ? periodicity.interval.singular
                            : periodicity.interval.plural)
                        : 'No definido'}
                    </p>
                  </Col>
                </Row>

                <Row style={{ margin: 10 }}>
                  <Col style={{ textAlign: 'end' }}>
                    {discountPreview > 0 && (
                      <h6 style={{ paddingTop: 0, marginRight: 4 }}>
                        <Money component="del">
                          {totalPreview / (1 - discountPreview)}
                        </Money>
                      </h6>
                    )}
                    <Money component="h1" style={{ paddingTop: 0, marginRight: 4 }}>
                      {totalPreview}
                    </Money>
                    <Button
                      style={{ textAlign: 'end', marginBottom: 20 }}
                      variant="danger">
                      Suscribirme
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col md={9}>
              <Row>
                <Col md={6} style={{ paddingLeft: 16 }}>
                  <CollapsibleCard
                    className={'sub-style'}
                    header={{ backgroundColor: '#1b4060' }}
                    title={'Encabezado'}>
                    <Row style={{ marginRight: 16, marginLeft: 16, marginTop: 10 }}>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>Título</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          aria-label="Default"
                          type="name"
                          maxlength="20"
                          value={title}
                          aria-describedby="inputGroup-sizing-default"
                          onChange={({ target }) => this.onChangeTitle(target.value)}
                        />
                      </InputGroup>
                    </Row>

                    <Row style={{ marginRight: 16, marginLeft: 16, marginBottom: 5 }}>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>Subtitulo</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          aria-label="Default"
                          type="name"
                          maxlength="30"
                          value={subtitle}
                          aria-describedby="inputGroup-sizing-default"
                          onChange={({ target }) => this.onChangeSubtitle(target.value)}
                        />
                      </InputGroup>
                    </Row>

                    <Row style={{ marginLeft: 16, marginRight: 11, marginBottom: 5 }}>
                      <div style={{ width: '100%' }}>
                        <h6>Periodicidad</h6>
                        <InputGroup>
                          <FormControl
                            aria-label="Default"
                            type="name"
                            maxlength="30"
                            disabled={true}
                            value={textPeriodicity}
                            aria-describedby="inputGroup-sizing-default"
                          />
                          <InputGroup.Append>
                            <Button
                              variant={'info'}
                              onClick={() =>
                                this.setState({ showPeriodicityModal: true })
                              }>
                              Seleccionar
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </div>
                    </Row>

                    <Row style={{ marginLeft: 6, marginRight: 2 }}>
                      <Col md={5}>
                        <div>
                          <h6>Ingresa cantidad de cajillas</h6>
                          <InputGroup>
                            <InputGroup.Prepend>
                              <InputGroup.Text>#</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              style={{ textAlign: 'center' }}
                              aria-label="Default"
                              type="number"
                              step={1}
                              onFocus={event => event.target.select()}
                              value={drawers}
                              aria-describedby="inputGroup-sizing-default"
                              onChange={({ target }) =>
                                this.onChangeDrawers(target.value)
                              }
                            />
                          </InputGroup>
                        </div>
                      </Col>
                      <Col>
                        <Alert
                          style={{
                            backgroundColor: '#fff3cd',
                            color: '#8a6b11',
                            width: '100%',
                            textAlign: 'center',
                          }}>
                          <b>Nota:</b> Si el peso por cajilla excede las 70 libras, el
                          costo por cajilla sera el doble.
                        </Alert>
                      </Col>
                    </Row>

                    <Row style={{ marginRight: 16, marginLeft: 16, marginBottom: 16 }}>
                      <div>
                        <h6>Tipo de Agrupación:</h6>
                        <ToggleButtonGroup
                          type="radio"
                          name="options"
                          value={typeSubscription}
                          onChange={this.handleType}
                          style={{ width: '100%' }}>
                          <ToggleButton
                            className="Btn-active"
                            variant={'secondary'}
                            value={1}>
                            Cerrada
                          </ToggleButton>
                          <ToggleButton
                            className="Btn-active"
                            variant={'secondary'}
                            value={2}>
                            Abierta
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    </Row>

                    {/*<Row style={{marginRight: 16, marginLeft: 16}}>*/}
                    {/*   */}
                    {/*</Row>*/}

                    {true && (
                      <Row style={{ marginRight: 16, marginLeft: 16 }}>
                        <Alert
                          style={{
                            backgroundColor: '#f8d6da',
                            color: '#721b24',
                            width: '100%',
                            textAlign: 'center',
                          }}>
                          Se generara factura Especial Cotizap
                        </Alert>
                      </Row>
                    )}
                  </CollapsibleCard>
                </Col>

                {/*<Col md={6} style={{paddingRight: 16}}>*/}
                {/*    <CollapsibleCard className={'sub-style'} header={{backgroundColor: '#1b4060'}} title={'Periodicidad'}>*/}
                {/*        <Row style={{alignItems: 'center', marginTop: 4, marginLeft: 10}}>*/}
                {/*            <Col md={4}>*/}
                {/*                <h6 style={{ color: '#2F4F4F'}}>Recurrencia: </h6>*/}
                {/*            </Col>*/}
                {/*            <Col md={8}>*/}
                {/*                <h6><i style={{ marginBottom: 4 }}>{periodicity ? 'Cada ' + periodicity.step + ' ' + (periodicity.step == 1 ? periodicity.interval.singular : periodicity.interval.plural) : '-'}</i></h6>*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*        <Row style={{alignItems: 'center', marginTop: 4, marginLeft: 10}}>*/}
                {/*            <Col md={4}>*/}
                {/*                <h6 style={{ color: '#2F4F4F'}}>Repetir: </h6>*/}
                {/*            </Col>*/}
                {/*            <Col md={8}>*/}
                {/*                <h6><i style={{ marginBottom: 4 }}>{periodicity ? (periodicity.times + (periodicity.times > 1 ? ' veces' : ' vez')) : '-'}</i></h6>*/}
                {/*            </Col>*/}
                {/*        </Row>*/}

                {/*        <Row className={'container-buttons'}>*/}
                {/*            <Button variant={'secondary'} onClick={()=>*/}
                {/*                this.setState({showPeriodicityModal: true})*/}
                {/*            }>Seleccionar</Button>*/}
                {/*        </Row>*/}
                {/*    </CollapsibleCard>*/}
                {/*</Col>*/}

                <Col>
                  <CollapsibleCard
                    className={'sub-style'}
                    style={{ minHeight: 406 }}
                    header={{ backgroundColor: '#1b4060' }}
                    title={'Polígonos'}>
                    <Row>
                      <Col>
                        <Button
                          variant={'info'}
                          onClick={() => this.setState({ showPolygonsModal: true })}>
                          Agregar poligonos
                        </Button>
                      </Col>
                    </Row>
                    <TableV2
                      headers={[
                        {
                          label: 'Code',
                          show: true,
                          value: ['code'],
                          type: 'text',
                          className: 'short',
                        },
                        {
                          label: 'Nombre',
                          show: true,
                          value: ['name'],
                          type: 'text',
                          className: 'medium',
                        },
                        {
                          label: 'Precio Transporte',
                          show: true,
                          value: ['priceC'],
                          type: 'text',
                          className: 'medium',
                          custom: item =>
                            (item.priceC = toMoney(
                              item.returnable
                                ? item.pickUpPoint.priceReturnable
                                : item.pickUpPoint.priceNonReturnable,
                            )),
                        },
                        {
                          label: 'Retornar Cajilla',
                          show: true,
                          value: ['returnable'],
                          type: 'yesOrNo',
                          className: 'short',
                        },
                        { config: true, show: true, label: '', className: 'mini center' },
                      ]}
                      items={polygons}
                      // items={polygons.filter((d) => d.checked)}
                      mobileAuto
                      storageKey={`subscriptionCreate1`}
                    />

                    {/*<Row className={'container-buttons'}>*/}
                    {/*    <Button variant={'secondary'} onClick={()=>this.setState({showPolygonsModal: true})}>Seleccionar</Button>*/}
                    {/*</Row>*/}
                  </CollapsibleCard>
                </Col>
              </Row>

              <Row>
                <Col>
                  <CollapsibleCard
                    className={'sub-style'}
                    header={{ backgroundColor: '#1b4060' }}
                    title={'Productos'}>
                    <Row style={{ marginRight: 20, justifyContent: 'flex-end' }}>
                      <Col>
                        <Button
                          variant={'info'}
                          onClick={() => this.setState({ showProductsModal: true })}>
                          Agregar productos
                        </Button>
                      </Col>
                      {/*<Col>*/}
                      {/*    <Money><h4>Total: <b>{this.totalProductDetail()}</b></h4></Money>*/}
                      {/*</Col>*/}
                    </Row>
                    <TableV2
                      headers={[
                        {
                          label: 'ID',
                          show: true,
                          value: ['productCode'],
                          type: 'text',
                          className: 'short',
                        },
                        {
                          label: 'Nombre',
                          show: true,
                          value: ['productName'],
                          type: 'text',
                          className: 'medium',
                        },
                        {
                          label: 'Precio',
                          show: true,
                          value: ['productPrice'],
                          type: 'currency',
                          className: 'medium',
                        },
                        {
                          label: 'Cantidad',
                          show: true,
                          value: ['realQuantity'],
                          type: 'text',
                          className: 'short',
                        },
                        {
                          label: 'Total',
                          show: true,
                          value: ['realQuantityC'],
                          type: 'text',
                          className: 'short',
                          custom: item =>
                            (item.realQuantityC = toMoney(
                              item.productPrice * item.realQuantity - item.discount,
                            )),
                        },
                        { config: true, show: true, label: '', className: 'mini center' },
                      ]}
                      items={products.filter(d => d.checked)}
                      mobileAuto
                      storageKey={`subscriptionCreate2`}
                    />
                    <br />
                  </CollapsibleCard>
                </Col>
              </Row>
            </Col>

            {polygons.length > 0 && (
              <div className={'back-subscription-card sub-style'}>
                <Row style={{ marginBottom: 6 }}>
                  <h1>Listado:</h1>
                </Row>
                <Row>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="tooltip">Quiero ser Pequeño Contribuyente</Tooltip>
                    }>
                    <Button
                      size="sm"
                      variant="outline-info"
                      onMouseEnter={this.down}
                      onMouseLeave={this.up}
                      onClick={() => {
                        alert('Button pressed')
                      }}>
                      <FontAwesomeIcon className={'name'} icon={faEye} /> Pequeño
                      Contribuyente
                    </Button>
                  </OverlayTrigger>

                  <Button size="sm" variant="info" onClick={this.test}>
                    Asignar mismo valor a todas las suscripciones
                  </Button>
                </Row>

                <Row style={{ marginTop: 10 }}>
                  {/*<Col md={6}>*/}
                  {/*    <GeocodingPicker*/}
                  {/*        lat={latitude}*/}
                  {/*        lng={longitude}*/}
                  {/*        auto={false}*/}
                  {/*        editable={true}*/}
                  {/*        getData={this.getData}*/}
                  {/*    />*/}
                  {/*</Col>*/}

                  {/*<Col md={6}>*/}
                  {/*    <CollapsibleCard className={'sub-style'} header={{backgroundColor: '#1b4060'}} title={'Periodicidad'}>*/}
                  {/*        <Row style={{alignItems: 'center', marginTop: 4, marginLeft: 10}}>*/}
                  {/*            <Col md={4}>*/}
                  {/*                <h6 style={{ color: '#2F4F4F'}}>Recurrencia: </h6>*/}
                  {/*            </Col>*/}
                  {/*            <Col md={8}>*/}
                  {/*                <h6><i style={{ marginBottom: 4 }}>{periodicity ? 'Cada ' + periodicity.step + ' ' + (periodicity.step == 1 ? periodicity.interval.singular : periodicity.interval.plural) : '-'}</i></h6>*/}
                  {/*            </Col>*/}
                  {/*        </Row>*/}
                  {/*        <Row style={{alignItems: 'center', marginTop: 4, marginLeft: 10}}>*/}
                  {/*            <Col md={4}>*/}
                  {/*                <h6 style={{ color: '#2F4F4F'}}>Repetir: </h6>*/}
                  {/*            </Col>*/}
                  {/*            <Col md={8}>*/}
                  {/*                <h6><i style={{ marginBottom: 4 }}>{periodicity ? (periodicity.times + (periodicity.times > 1 ? ' veces' : ' vez')) : '-'}</i></h6>*/}
                  {/*            </Col>*/}
                  {/*        </Row>*/}

                  {/*        <Row className={'container-buttons'}>*/}
                  {/*            <Button variant={'secondary'} onClick={()=>*/}
                  {/*                this.setState({showPeriodicityModal: true})*/}
                  {/*            }>Seleccionar</Button>*/}
                  {/*        </Row>*/}
                  {/*    </CollapsibleCard>*/}
                  {/*</Col>*/}

                  <Col>
                    {/*    <CollapsibleCard className={'sub-style'} header={{backgroundColor: '#1b4060'}} title={'Transporte'}>*/}

                    {/*        <Row style={{marginLeft: 16, marginBottom: 9, justifyContent:'flex-start'}}>*/}
                    {/*            <div>*/}
                    {/*                <h6>Ingresa el peso</h6>*/}
                    {/*                <InputGroup style={{width: 300}}>*/}
                    {/*                    <FormControl*/}
                    {/*                        style={{textAlign: 'center'}}*/}
                    {/*                        aria-label="Default"*/}
                    {/*                        type="number"*/}
                    {/*                        step={1}*/}
                    {/*                        onFocus={(event)=>event.target.select()}*/}
                    {/*                        value={weight}*/}
                    {/*                        aria-describedby="inputGroup-sizing-default"*/}
                    {/*                        onChange={({target}) => this.onChangeWeight(target.value)}*/}
                    {/*                    />*/}
                    {/*                    <InputGroup.Append>*/}
                    {/*                        <InputGroup.Text>lbs</InputGroup.Text>*/}
                    {/*                    </InputGroup.Append>*/}
                    {/*                </InputGroup>*/}
                    {/*            </div>*/}
                    {/*        </Row>*/}

                    {/*        <Row style={{marginLeft: 16, marginBottom: 9, justifyContent:'flex-start'}}>*/}
                    {/*            <div>*/}
                    {/*                <h6>Ingresa el volumen<p>(Alto x Ancho x Largo)</p></h6>*/}

                    {/*                <InputGroup style={{width: 300}}>*/}
                    {/*                    <FormControl*/}
                    {/*                        style={{textAlign: 'center'}}*/}
                    {/*                        aria-label="Default"*/}
                    {/*                        type="number"*/}
                    {/*                        step={1}*/}
                    {/*                        onFocus={(event)=>event.target.select()}*/}
                    {/*                        value={tHeight}*/}
                    {/*                        aria-describedby="inputGroup-sizing-default"*/}
                    {/*                        onChange={({target}) => this.onChangeHeight(target.value)}*/}
                    {/*                    />*/}
                    {/*                    <FormControl*/}
                    {/*                        style={{textAlign: 'center'}}*/}
                    {/*                        aria-label="Default"*/}
                    {/*                        type="number"*/}
                    {/*                        step={1}*/}
                    {/*                        onFocus={(event)=>event.target.select()}*/}
                    {/*                        value={tWidth}*/}
                    {/*                        aria-describedby="inputGroup-sizing-default"*/}
                    {/*                        onChange={({target}) => this.onChangeWidth(target.value)}*/}
                    {/*                    />*/}
                    {/*                    <FormControl*/}
                    {/*                        style={{textAlign: 'center'}}*/}
                    {/*                        aria-label="Default"*/}
                    {/*                        type="number"*/}
                    {/*                        step={1}*/}
                    {/*                        onFocus={(event)=>event.target.select()}*/}
                    {/*                        value={tLong}*/}
                    {/*                        aria-describedby="inputGroup-sizing-default"*/}
                    {/*                        onChange={({target}) => this.onChangeLong(target.value)}*/}
                    {/*                    />*/}
                    {/*                    <InputGroup.Append>*/}
                    {/*                        <InputGroup.Text>cms</InputGroup.Text>*/}
                    {/*                    </InputGroup.Append>*/}
                    {/*                </InputGroup>*/}

                    {/*            </div>*/}
                    {/*        </Row>*/}

                    {/*<Row style={{marginLeft: 16, marginBottom: 9, justifyContent:'flex-start'}}>*/}
                    {/*    <div>*/}
                    {/*        <h6>Ingresa cantidad de cajillas</h6>*/}
                    {/*        <InputGroup style={{width: 300}}>*/}
                    {/*            <InputGroup.Prepend>*/}
                    {/*                <InputGroup.Text>#</InputGroup.Text>*/}
                    {/*            </InputGroup.Prepend>*/}
                    {/*            <FormControl*/}
                    {/*                style={{textAlign: 'center'}}*/}
                    {/*                aria-label="Default"*/}
                    {/*                type="number"*/}
                    {/*                step={1}*/}
                    {/*                onFocus={(event)=>event.target.select()}*/}
                    {/*                value={drawers}*/}
                    {/*                aria-describedby="inputGroup-sizing-default"*/}
                    {/*                onChange={({target}) => this.onChangeDrawers(target.value)}*/}
                    {/*            />*/}
                    {/*        </InputGroup>*/}
                    {/*    </div>*/}
                    {/*</Row>*/}

                    {/*    <Row style={{marginLeft: 16, marginRight: 16, marginBottom: 9, justifyContent:'flex-end'}}>*/}
                    {/*        <h2>Algún texto que describa alguna información</h2>*/}
                    {/*    </Row>*/}

                    {/*</CollapsibleCard>*/}

                    {/*<CollapsibleCard className={'sub-style'} header={{backgroundColor: '#1b4060'}} title={'Valor'}>*/}

                    {/*    <Row style={{marginLeft: 16, marginRight: 16, marginBottom: 9, justifyContent:'flex-start'}}>*/}
                    {/*        <h2>Puede agregar un descuento al total de la suscripción</h2>*/}
                    {/*    </Row>*/}

                    {/*    <Row style={{marginRight: 16, marginTop: 20, justifyContent:'flex-end'}}>*/}
                    {/*        <Money><h4>Subtotal: <b>{this.subtotalSubscription()}</b></h4></Money>*/}
                    {/*    </Row>*/}
                    {/*    <Row style={{marginRight: 16, justifyContent:'flex-end'}}>*/}
                    {/*        <InputGroup style={{width: 240}}>*/}
                    {/*            <InputGroup.Prepend>*/}
                    {/*                <InputGroup.Text>Descuento</InputGroup.Text>*/}
                    {/*            </InputGroup.Prepend>*/}
                    {/*            <FormControl*/}
                    {/*                style={{textAlign: 'center'}}*/}
                    {/*                aria-label="Default"*/}
                    {/*                type="number"*/}
                    {/*                step={1}*/}
                    {/*                onFocus={(event)=>event.target.select()}*/}
                    {/*                value={generalDiscount}*/}
                    {/*                aria-describedby="inputGroup-sizing-default"*/}
                    {/*                onChange={({target}) => this.onChangeGeneralDiscount(target.value)}*/}
                    {/*            />*/}
                    {/*            <InputGroup.Append>*/}
                    {/*                <InputGroup.Text>%</InputGroup.Text>*/}
                    {/*            </InputGroup.Append>*/}
                    {/*        </InputGroup>*/}
                    {/*    </Row>*/}
                    {/*    <Row style={{marginRight: 16, marginBottom: 5, justifyContent:'flex-end'}}>*/}
                    {/*        <Money><h4>Total: <b>{this.totalSubscription()}</b></h4></Money>*/}
                    {/*    </Row>*/}
                    {/*</CollapsibleCard>*/}

                    {polygons.map((item, index) => {
                      const products = this.totalProductDetail()
                      const total = item.total

                      const transport = item.returnable
                        ? item.pickUpPoint.priceReturnable * drawers
                        : item.pickUpPoint.priceNonReturnable * drawers

                      const valueRaw = (total - transport) / 1.17
                      const iva = valueRaw * (!seePC ? 0.12 : 0.05)
                      const isr = valueRaw * (!seePC ? 0.05 : 0)

                      const ivaP = products * 0.12
                      const isrP = products * 0.05
                      // const tp = products + transport + ivaP + isrP;
                      const tp = products + transport

                      // const total = subtotal + iva + isr + transport;

                      const diferencia = tp - total

                      return (
                        <Col md={4}>
                          <CollapsibleCard
                            className={'sub-style'}
                            header={{ backgroundColor: '#1b4060' }}
                            title={'Suscripción para ' + item.name}>
                            {/*<Alert variant={'dark'}>*/}
                            {/*    <Row style={{marginLeft: 16, marginRight: 16, justifyContent:'flex-start'}}>*/}
                            {/*        <div><b>Detalle</b></div>*/}
                            {/*    </Row>*/}
                            {/*    <Row style={{marginLeft: 16, marginRight: 16, marginTop: 20, justifyContent:'flex-end'}}>*/}
                            {/*        <Money><h6>Total de productos: <b>{products}</b></h6></Money>*/}
                            {/*    </Row>*/}
                            {/*    <Row style={{marginLeft: 16, marginRight: 16, marginTop: 20, justifyContent:'flex-end'}}>*/}
                            {/*        <Money><h6>IVAP: <b>{ivaP}</b></h6></Money>*/}
                            {/*    </Row>*/}
                            {/*    <Row style={{marginLeft: 16, marginRight: 16, marginTop: 20, justifyContent:'flex-end'}}>*/}
                            {/*        <Money><h6>ISRP: <b>{isrP}</b></h6></Money>*/}
                            {/*    </Row>*/}
                            {/*    <Row style={{marginLeft: 16, marginRight: 16, marginTop: 20, justifyContent:'flex-end'}}>*/}
                            {/*        <Money><h6>Costo por transporte: <b>{transport}</b></h6></Money>*/}
                            {/*    </Row>*/}
                            {/*    <hr/>*/}
                            {/*    <Row style={{marginLeft: 16, marginRight: 16, marginTop: 20, justifyContent:'flex-end'}}>*/}
                            {/*        <Money><h6>Total: <b>{tp}</b></h6></Money>*/}
                            {/*    </Row>*/}
                            {/*</Alert>*/}

                            <Row
                              style={{
                                marginLeft: 16,
                                marginRight: 16,
                                justifyContent: 'flex-start',
                              }}>
                              <div>
                                <h6>
                                  <b>Valor total de la Agrupación de Productos:</b>
                                </h6>
                              </div>
                              <InputGroup style={{ maxWidth: 400 }}>
                                <InputGroup.Prepend>
                                  <InputGroup.Text>
                                    {currentCurrency.symbol}
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                  style={{ textAlign: 'center' }}
                                  aria-label="Default"
                                  type="number"
                                  step={1}
                                  onFocus={event => event.target.select()}
                                  value={item.total}
                                  aria-describedby="inputGroup-sizing-default"
                                  onChange={({ target }) =>
                                    this.onChangeTotal(target.value, item)
                                  }
                                />
                              </InputGroup>
                            </Row>

                            <Alert
                              style={{
                                backgroundColor: '#ffffff',
                                color: '#0c5460',
                                width: '100%',
                              }}>
                              {/*<Row style={{marginLeft: 16, marginRight: 16, marginTop: 20, justifyContent:'flex-end'}}>*/}
                              {/*    <Money><h6 style={{color: '#0c5460'}}>Total de productos: <b>{products}</b></h6></Money>*/}
                              {/*</Row>*/}

                              <Row
                                style={{
                                  marginLeft: 16,
                                  marginRight: 16,
                                  justifyContent: 'flex-end',
                                }}>
                                <Money>
                                  <h6 style={{ color: '#0c5460' }}>
                                    IVA: <b>{iva}</b>
                                  </h6>
                                </Money>
                              </Row>

                              <Row
                                style={{
                                  marginLeft: 16,
                                  marginRight: 16,
                                  marginTop: 20,
                                  justifyContent: 'flex-end',
                                }}>
                                <div>
                                  <h6 style={{ color: '#0c5460' }}>
                                    ISR: <b>{seePC ? '-' : toMoney(isr)}</b>
                                  </h6>
                                </div>
                              </Row>

                              {/*<Row style={{marginLeft: 16, marginRight: 16, marginTop: 20, justifyContent:'flex-end'}}>*/}
                              {/*    <Money><h6 style={{color: '#0c5460'}}> Diferencia: <b>{valueRaw-  products}</b></h6></Money>*/}
                              {/*</Row>*/}
                              <hr />

                              <Row
                                style={{
                                  marginLeft: 16,
                                  marginRight: 16,
                                  marginTop: 20,
                                  justifyContent: 'flex-end',
                                }}>
                                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                  {/*<OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Quiero ser Pequeño Contribuyente</Tooltip>}>*/}
                                  {/*<Button size="sm" variant="link" style={{color: '#0c5460'}}  onClick={()=>{alert('Button pressed')}}>*/}
                                  {/*        <FontAwesomeIcon className={'name'} icon={faExternalLinkSquareAlt}/>*/}
                                  {/*    </Button>*/}
                                  {/*</OverlayTrigger>*/}
                                  <Money component="h6" style={{ color: '#0c5460' }}>
                                    {seePC ? 'Impuestos:' : 'Retención factura especial'}{' '}
                                    <b>{iva + isr}</b>
                                  </Money>
                                </div>
                              </Row>

                              {/*<Row style={{marginLeft: 16, marginRight: 16, marginTop: 20, justifyContent:'flex-end'}}>*/}
                              {/*    <Money><h6 style={{color: '#0c5460'}}>Subtotal: <b>{valueRaw}</b></h6></Money>*/}
                              {/*</Row>*/}

                              <Row
                                style={{
                                  marginLeft: 16,
                                  marginRight: 16,
                                  marginTop: 20,
                                  justifyContent: 'flex-end',
                                }}>
                                <Money component="h6">
                                  <h6 style={{ color: '#0c5460' }}>
                                    Costo por transporte: <b>{transport}</b>
                                  </h6>
                                </Money>
                              </Row>
                              <hr />
                              <Row
                                style={{
                                  marginLeft: 16,
                                  marginRight: 16,
                                  marginTop: 20,
                                  marginBottom: 20,
                                  justifyContent: 'flex-end',
                                }}>
                                <Money>
                                  <h6 style={{ color: '#0c5460' }}>
                                    Total: <b>{item.total}</b>
                                  </h6>
                                </Money>
                              </Row>
                              <hr />
                              <Row
                                style={{
                                  marginLeft: 16,
                                  marginRight: 16,
                                  marginTop: 20,
                                  justifyContent: 'flex-end',
                                }}>
                                <Money>
                                  <h6 style={{ color: '#0c5460' }}>
                                    Pago a percibir:{' '}
                                    <b>{item.total - transport - iva - isr}</b>
                                  </h6>
                                </Money>
                              </Row>
                            </Alert>

                            {/*<InputGroup style={{maxWidth: 400}}>*/}
                            {/*    <InputGroup.Prepend>*/}
                            {/*        <InputGroup.Text>Descuento</InputGroup.Text>*/}
                            {/*    </InputGroup.Prepend>*/}
                            {/*    <FormControl*/}
                            {/*        style={{textAlign: 'center'}}*/}
                            {/*        aria-label="Default"*/}
                            {/*        type="number"*/}
                            {/*        step={1}*/}
                            {/*        onFocus={(event)=>event.target.select()}*/}
                            {/*        value={generalDiscount}*/}
                            {/*        aria-describedby="inputGroup-sizing-default"*/}
                            {/*        onChange={({target}) => this.onChangeGeneralDiscount(target.value)}*/}
                            {/*    />*/}
                            {/*    <InputGroup.Append>*/}
                            {/*        <InputGroup.Text>%</InputGroup.Text>*/}
                            {/*    </InputGroup.Append>*/}
                            {/*</InputGroup>*/}

                            {/*<Row style={{marginLeft: 16, marginRight: 16, marginTop: 20, justifyContent:'flex-end'}}>*/}
                            {/*    <Money><h6>Diferencia: <b>{diferencia}</b></h6></Money>*/}
                            {/*</Row>*/}

                            {/*<Row style={{marginLeft: 16, marginRight: 16, marginTop: 20, justifyContent:'flex-end'}}>*/}
                            {/*    <Money><h6>IVA: <b>{iva}</b></h6></Money>*/}
                            {/*</Row>*/}
                            {/*<Row style={{marginLeft: 16, marginRight: 16, marginTop: 20, justifyContent:'flex-end'}}>*/}
                            {/*    <Money><h6>ISR: <b>{isr}</b></h6></Money>*/}
                            {/*</Row>*/}
                            {/*<Row style={{marginLeft: 16, marginRight: 16, marginTop: 20, justifyContent:'flex-end'}}>*/}
                            {/*    <Money><h6>Costo por transporte: <b>{transport}</b></h6></Money>*/}
                            {/*</Row>*/}
                            {/*<hr/>*/}
                            {/*<Row style={{marginLeft: 16, marginRight: 16, marginTop: 20, justifyContent:'flex-end'}}>*/}
                            {/*    <Money><h6>Total: <b>{item.total}</b></h6></Money>*/}
                            {/*</Row>*/}
                          </CollapsibleCard>
                        </Col>
                      )
                    })}

                    {/*<CollapsibleCard className={'sub-style'} header={{backgroundColor: '#1b4060'}} title={'Precio total de la suscripción'}>*/}
                    {/*    <Row style={{marginLeft: 16, marginRight: 16, marginTop: 20, justifyContent:'flex-end'}}>*/}
                    {/*        <Money><h4>Suma total de productos: <b>{this.totalProductDetail()}</b></h4></Money>*/}
                    {/*    </Row>*/}

                    {/*    /!*<Row style={{marginRight: 16, marginBottom: 5, justifyContent:'flex-end'}}>*!/*/}
                    {/*    /!*    <Money><h4>Total: <b>{this.totalSubscription()}</b></h4></Money>*!/*/}
                    {/*    /!*</Row>*!/*/}

                    {/*    <Row style={{marginLeft: 16, marginRight: 16, marginTop: 20, justifyContent:'flex-end'}}>*/}
                    {/*        <Money><h4>Costo promedio por cajillas: <b>{averageCostTransport}</b></h4></Money>*/}
                    {/*    </Row>*/}

                    {/*    {true &&*/}
                    {/*        <div>*/}
                    {/*            <Row style={{marginLeft: 16, marginRight: 16, marginTop: 20, justifyContent:'flex-end'}}>*/}
                    {/*                <Money><h4>IVA: <b>{IVA}</b></h4></Money>*/}
                    {/*            </Row>*/}
                    {/*            <Row style={{marginLeft: 16, marginRight: 16, marginTop: 20, justifyContent:'flex-end'}}>*/}
                    {/*                <div>  <h6>*/}
                    {/*                    <Button variant="outline-info" size="sm"><FontAwesomeIcon className={'name'} icon={faEdit}/></Button>*/}
                    {/*                    Descuento: <b>{33} </b>*/}
                    {/*                </h6></div>*/}
                    {/*            </Row>*/}
                    {/*            <Row style={{marginLeft: 16, marginRight: 16, marginTop: 20, justifyContent:'flex-end'}}>*/}
                    {/*                <div><h4>ISR: <b>{ISR}</b></h4></div>*/}
                    {/*            </Row>*/}
                    {/*        </div>*/}
                    {/*    }*/}

                    {/*    <Row style={{marginLeft: 16, marginRight: 16, justifyContent:'flex-end'}}>*/}
                    {/*        <InputGroup style={{maxWidth: 400}}>*/}
                    {/*            <InputGroup.Prepend>*/}
                    {/*                <InputGroup.Text>Total de la suscripción</InputGroup.Text>*/}
                    {/*            </InputGroup.Prepend>*/}
                    {/*            <FormControl*/}
                    {/*                style={{textAlign: 'center'}}*/}
                    {/*                aria-label="Default"*/}
                    {/*                type="number"*/}
                    {/*                step={1}*/}
                    {/*                onFocus={(event)=>event.target.select()}*/}
                    {/*                value={totalSubscription}*/}
                    {/*                aria-describedby="inputGroup-sizing-default"*/}
                    {/*                onChange={({target}) => this.onChangeTotal(target.value)}*/}
                    {/*            />*/}
                    {/*        </InputGroup>*/}
                    {/*    </Row>*/}

                    {/*    <Row style={{marginRight: 16, justifyContent:'flex-end'}}>*/}
                    {/*        <InputGroup style={{width: 400}}>*/}
                    {/*            <InputGroup.Prepend>*/}
                    {/*                <InputGroup.Text>Descuento</InputGroup.Text>*/}
                    {/*            </InputGroup.Prepend>*/}
                    {/*            <FormControl*/}
                    {/*                style={{textAlign: 'center'}}*/}
                    {/*                aria-label="Default"*/}
                    {/*                type="number"*/}
                    {/*                step={1}*/}
                    {/*                onFocus={(event)=>event.target.select()}*/}
                    {/*                value={generalDiscount}*/}
                    {/*                aria-describedby="inputGroup-sizing-default"*/}
                    {/*                onChange={({target}) => this.onChangeGeneralDiscount(target.value)}*/}
                    {/*            />*/}
                    {/*            <InputGroup.Append>*/}
                    {/*                <InputGroup.Text>%</InputGroup.Text>*/}
                    {/*            </InputGroup.Append>*/}
                    {/*        </InputGroup>*/}
                    {/*    </Row>*/}

                    {/*</CollapsibleCard>*/}
                  </Col>
                </Row>

                <Row className={'container-buttons'}>
                  <Button
                    disabled={
                      !periodicity ||
                      products.length == 0 ||
                      polygons.length == 0 ||
                      !title ||
                      !subtitle ||
                      newSubscriptionIsLoading
                    }
                    onClick={() => this.save()}
                    variant={'danger'}
                    block>
                    {newSubscriptionIsLoading && (
                      <Spinner
                        style={{ marginRight: 10, marginTop: 4 }}
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    <strong>
                      {newSubscriptionIsLoading ? 'Guardando' : 'Crear suscripción'}
                    </strong>
                  </Button>
                </Row>
              </div>
            )}
          </Row>

          {/*</Card>*/}
        </div>

        <ModalSelectPeriodicity
          show={this.state.showPeriodicityModal}
          onHide={this.onHidePeriodicityModal}
          onSelectPeriodicity={this.onSelectPeriodicity}
        />
        {/*<ModalSelectClient show={this.state.showClientModal} onHide={this.onHideClientModal} onSelectClient={this.onSelectClient}/>*/}
        <ModalSelectProducts
          client={this.state.client}
          show={this.state.showProductsModal}
          onHide={this.onHideProductsModal}
          onSelectProducts={this.onSelectProducts}
        />

        <ModalSelectPolygons
          show={this.state.showPolygonsModal}
          onHide={this.onHidePolygonsModal}
          onSelectPolygons={this.onSelectPolygons}
        />

        <SweetAlert
          show={showSubscriptionSuccess}
          title={'!Exito!'}
          text={'Subscripción guardada exitosamente.'}
          type={'success'}
          onConfirm={() => {
            this.setState({ showSubscriptionSuccess: false }, () => window.history.back())
          }}
        />

        <SweetAlert
          show={showNewSubscriptionError}
          title={'¡Uh-Oh!'}
          text={
            errorMessage ||
            newSubscriptionError.message ||
            'Error al guardar, intente mas tarde.'
          }
          type={'error'}
          onConfirm={() => this.setState({ showNewSubscriptionError: false })}
        />

        <SweetAlert
          show={showSameValue}
          title="Valor de la suscripción"
          text="Ingrese el valor a definir en todas las suscripciones"
          type="input"
          inputType="number"
          inputPlaceholder="0.00"
          showCancelButton
          confirmButtonText="Asignar"
          cancelButtonText="Cancelar"
          onConfirm={inputValue => {
            this.setSameValue(inputValue)
          }}
          onCancel={() => {
            this.setState({ showSameValue: false })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  newSubscriptionIsLoading: loadingSelector([actionTypes.SAVE_NEW_SUBSCRIPTION])(state),
  newSubscriptionHasError: hasErrorsSelector([actionTypes.SAVE_NEW_SUBSCRIPTION])(state),
  newSubscriptionError: singleErrorSelector([actionTypes.SAVE_NEW_SUBSCRIPTION])(state),
  towns: selectAllLocalitiesForSelect(state),
  currentCurrency: selectCurrentCurrency(state),
})
const mapDispatchToProps = dispatch => ({
  saveNewSubscription: subscription => dispatch(saveNewSubscription(subscription)),
  getAllLocalities: () => dispatch(getAllLocalities()),
})
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionCreate)
