import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'

import { Row, Col } from 'react-bootstrap'
import {
  TableV2,
  Card,
  Select,
  SelectedDates,
  ListCategories,
  Folder,
  Button,
  SwitchV2,
  Paragraph,
  Empty,
} from 'src/components'
import { faBroom, faDownload, faSearch } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  getSegmentationReportOpt,
  getSegmentationReportOptExcel,
} from 'src/actions/report.actions'
import { selectReportSegmented } from 'src/selectors/report.selector'

import { getProductsSimple } from 'src/actions/products.actions'
import { selectProductsSimple } from 'src/selectors/products.selector'

import { getClientsSimple } from 'src/actions/clients.actions'
import { simpleClients } from 'src/selectors/clients.selector'

import { getAllCategorizations } from 'src/actions/categorization.actions'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'

import { showAlert } from 'src/actions/alert.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import { hasErrors, handlerError } from 'src/selectors/error.selector'

import { toMoney } from 'src/utils/utilities'

import {
  segmentationReportTypes,
  segmentationReportTypeEnum,
} from 'src/enums/reportsEnum'
import {
  actionTypes as dteAction,
  exportDTE,
  exportDTEProducts,
} from 'src/actions/resolutions.actions'
import { isAllowed } from 'src/selectors/modules.selector'
import { orderPermissions } from 'src/enums/permissions'

const today = new Date()

/** App for the segmentation report **/
const SegmentationReport = () => {
  const dispatch = useDispatch()

  const clients: ISelect[] = useSelector(simpleClients)
  const items: ISelect[] = useSelector(selectProductsSimple)
  const categories = useSelector(selectAllCategorizations)

  const report: ISegmentation[] = useSelector(selectReportSegmented)
  const loadingGetReport: boolean = useSelector(state =>
    loadingSelector([actionTypes.GET_SEGMENTATION_REPORT, dteAction.EXPORT_DTE])(state),
  )
  const loadingExcelReport: boolean = useSelector(state =>
    loadingSelector([actionTypes.GET_SEGMENTATION_REPORT_EXCEL, dteAction.EXPORT_DTE])(
      state,
    ),
  )

  const hasErrorReport = useSelector(state =>
    hasErrors([
      actionTypes.GET_SEGMENTATION_REPORT,
      actionTypes.GET_SEGMENTATION_REPORT_EXCEL,
      dteAction.EXPORT_DTE,
    ])(state),
  )

  const disabled = loadingGetReport || loadingExcelReport

  const [flags, setFlags] = useState({ get: false })

  const [showCategories, setShowCategories] = useState({ show: false, filter: false })
  const [filters, setFilters] = useState<IReportSegmentationFilter>({
    reportType: segmentationReportTypes[0],
    start: null,
    end: null,
    categories: [],
    clients: [],
    items: [],
    segmentedCategories: [],
    segmentByClient: false,
  })

  const canDownloadReportSales = useSelector(state =>
    isAllowed(state, [orderPermissions.canDownloadReportSales]),
  )

  useEffect(() => {
    dispatch(getProductsSimple())
    dispatch(getClientsSimple())
    dispatch(getAllCategorizations(5))
    onClearFilters()
  }, [])

  useEffect(() => {
    if (disabled) setFlags({ ...flags, get: true })
    else if (flags.get) {
      setFlags({ ...flags, get: false })
      if (hasErrorReport) dispatch(showAlert(handlerError(hasErrorReport.message)))
    }
  }, [disabled])

  /** Get or Download report segmented
   * @function
   * @param {boolean} excel Indicates whether to download an EXCEL or obtain the data to render
   * **/
  const setUp = (excel: boolean) => {
    if (filters.reportType.value !== segmentationReportTypeEnum.BY_CLIENTS.id) {
      if (!filters.start || !filters.end)
        return dispatch(
          showAlert(
            handlerError(
              'El reporte seleccionado requiere que se indiquen fechas de búsqueda',
            ),
          ),
        )
    }

    const params = {
      reportType: filters.reportType.value,
      start: filters.start,
      end: filters.end,
      categories: filters.categories.map(c => c.id).join(),
      clients: filters.clients.map(c => c.id).join(),
      items: filters.items.map(c => c.id).join(),
      segmentedCategories: filters.segmentedCategories.map(c => c.id).join(),
      segmentByClient: filters.segmentByClient,
    }
    if (excel) dispatch(getSegmentationReportOptExcel(params, filters.reportType.label))
    else dispatch(getSegmentationReportOpt(params))
  }

  /**
   * Download the Invoiced Items excel (DTE)
   */
  const getInvoiceItemsExcel = (items: boolean) => {
    if (!(filters.start && filters.end)) {
      return dispatch(
        showAlert(
          handlerError(
            'El reporte seleccionado requiere que se indiquen fechas de búsqueda',
          ),
        ),
      )
    }

    const request = {
      start: filters.start,
      end: filters.end,
      categories: filters.categories.map(c => c.id).join(),
      client: filters.clients.map(c => c.id).join(),
      extraFields: true,
    }
    if (items) dispatch(exportDTEProducts(request))
    else dispatch(exportDTE(request))
  }

  /** Clear filters and segment params
   * @function
   * **/
  const onClearFilters = () => {
    const start = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0)
    setFilters({
      ...filters,
      start: start.valueOf(),
      end: today.setHours(23, 59, 59).valueOf(),
      categories: [],
      clients: [],
      items: [],
      segmentedCategories: [],
      segmentByClient: false,
    })
  }

  /** Return only the unselected options
   * @function
   * @return {ISelect[]} options filtered
   * **/
  const filterOptions = (options: ISelect[], values: ISelect[]): ISelect[] => {
    return options.filter(o => !values.some(v => v.value === o.value))
  }

  /** Add or remove category
   * @function
   * @param {ICategory} category category
   * @param {boolean} isToFilter Indicates if the categories are to filter or segment
   * **/
  const onAssignCategorization = (category: ICategory, isToFilter: boolean) => {
    const items = Object.assign(
      [],
      isToFilter ? filters.categories : filters.segmentedCategories,
    )
    const index = items.findIndex(is => is.id === category.id)
    if (index === -1) items.push(category)
    else items.splice(index, 1)
    if (isToFilter) setFilters({ ...filters, categories: items })
    else setFilters({ ...filters, segmentedCategories: items })
  }

  const customFilters = (
    <Row>
      <Col xl={12}>
        <hr />
      </Col>
      <Col xl={12}>
        <Paragraph size={'big'}>Filtros</Paragraph>
      </Col>
      <Col xl={6} lg={6} sm={12} xs={12}>
        <Row>
          <Col xl={12}>
            <Select
              disabled={disabled}
              label={'Por Clientes'}
              value={null}
              options={[
                { value: null, label: 'Sin clientes' },
                ...filterOptions(clients, filters.clients),
              ]}
              onChange={i => {
                let items
                if (i.value) {
                  items = Object.assign([], [...filters.clients, i])
                } else {
                  items = []
                }
                setFilters({ ...filters, clients: items })
              }}
            />
          </Col>
          <Col xl={12}>
            <ListCategories
              disabled={disabled}
              items={filters.clients}
              onRemove={i => {
                const items = Object.assign([], filters.clients)
                const index = items.findIndex(it => it.value === i.id)
                items.splice(index, 1)
                setFilters({ ...filters, clients: items })
              }}
            />
          </Col>
        </Row>
      </Col>

      <Col xl={6} lg={6} sm={12} xs={12}>
        <Row>
          <Col xl={12}>
            <Select
              disabled={disabled}
              label={'Por Ítems'}
              value={null}
              options={[
                { value: null, label: 'Sin ítems' },
                ...filterOptions(items, filters.items),
              ]}
              onChange={i => {
                let items
                if (i.value) {
                  items = Object.assign([], [...filters.items, i])
                } else {
                  items = []
                }
                setFilters({ ...filters, items })
              }}
            />
          </Col>
          <Col xl={12}>
            <ListCategories
              disabled={disabled}
              items={filters.items}
              onRemove={i => {
                const items = Object.assign([], filters.items)
                const index = items.findIndex(it => it.value === i.id)
                items.splice(index, 1)
                setFilters({ ...filters, items })
              }}
            />
          </Col>
        </Row>
      </Col>
      <Col xl={12} className={'mt-3'}>
        <Row>
          <Col xl={12}>
            <Button
              disabled={disabled}
              color={'accent'}
              onClick={() => setShowCategories({ show: true, filter: true })}>
              Por categorías
            </Button>
          </Col>
          <Col xl={12}>
            <ListCategories
              disabled={disabled}
              items={filters.categories}
              onRemove={item => onAssignCategorization(item, true)}
            />
          </Col>
        </Row>
      </Col>

      <Col xl={12}>
        <hr />
      </Col>

      <Col xl={12}>
        <Paragraph size={'big'}>Segmentación</Paragraph>
      </Col>

      <Col xl={6} lg={6} sm={12} xs={12} className={'mt-3'}>
        <Row>
          <Col xl={12}>
            <Button
              disabled={disabled}
              color={'accent'}
              onClick={() => {
                dispatch(
                  getAllCategorizations(
                    filters.reportType.value ===
                      segmentationReportTypeEnum.BY_INVOICED_ITEMS.id
                      ? 3
                      : 5,
                  ),
                )
                setShowCategories({ show: true, filter: false })
              }}>
              Por categorías
            </Button>
          </Col>
          <Col xl={12}>
            <ListCategories
              disabled={disabled}
              items={filters.segmentedCategories}
              onRemove={item => onAssignCategorization(item, false)}
            />
          </Col>
        </Row>
      </Col>
      <Col xl={6} lg={6} sm={12} xs={12}>
        <SwitchV2
          checked={filters.segmentByClient}
          label={'Por clientes'}
          info={'Realiza una segmentación por cliente asignado a las transacciones.'}
          onChange={segmentByClient => setFilters({ ...filters, segmentByClient })}
          disabled={
            disabled ||
            filters.reportType.value === segmentationReportTypeEnum.BY_CLIENTS.id
          }
        />
      </Col>
    </Row>
  )

  /** Recursive component that renders a Card if it is a segment or a TableV2 if it is data
   * @component
   * @param {ISegmentation[]} items List of data to render
   * @param {boolean} isSegment Indicates whether it is a segment or not
   * **/
  const renderContent = (items: ISegmentation[], isSegment: boolean) => {
    if (isSegment)
      return items.map(item => (
        <Col xl={12} key={item.id}>
          <Card
            title={item.name}
            white
            button={toMoney(item.total)}
            footer={toMoney(item.total)}>
            <Row>{renderContent(item.items, false)}</Row>
          </Card>
        </Col>
      ))
    else {
      const withSegment = items.some(i => i.segment)
      if (withSegment) return renderContent(items, true)
      else
        return (
          <Col xl={12}>
            <TableV2
              // @ts-ignore
              headers={filters.reportType.headers}
              items={items}
              mobileAuto
            />
          </Col>
        )
    }
  }

  return (
    <div>
      <Card title={'Parámetros de búsqueda y presentación'} white>
        <Row>
          <Col xl={12}>
            <Select
              mt={0}
              required
              label={'Tipo de Reporte'}
              value={filters.reportType}
              options={segmentationReportTypes}
              onChange={reportType => {
                let segmentByClient = filters.segmentByClient
                if (reportType.value === segmentationReportTypeEnum.BY_CLIENTS.id)
                  segmentByClient = false

                setFilters({ ...filters, reportType, segmentByClient })
              }}
            />
          </Col>

          <Col xl={12}>
            <SelectedDates
              withOptionNull
              onDateChange={(start, end) => setFilters({ ...filters, start, end })}
              initialPreset={{ value: 2, label: 'Este mes' }}
              filter
              customFilter={customFilters}
            />
          </Col>

          <Col xl={12}>
            <hr />
          </Col>
          <Col xl={12}>
            <Row className={'container-buttons'}>
              <Button
                loading={loadingGetReport}
                disabled={loadingExcelReport}
                icon={faSearch}
                onClick={() => setUp(false)}>
                Buscar
              </Button>
              {filters.reportType.value ===
                segmentationReportTypeEnum.BY_INVOICED_ITEMS.id &&
                canDownloadReportSales && (
                  <>
                    <Button
                      loading={loadingGetReport}
                      disabled={loadingExcelReport}
                      tooltip={
                        'Exporta un excel con las facturas electrónicas emitidas por producto'
                      }
                      color={'accent'}
                      icon={faDownload}
                      onClick={() => getInvoiceItemsExcel(true)}>
                      Ítems facturados
                    </Button>
                    <Button
                      loading={loadingGetReport}
                      disabled={loadingExcelReport}
                      tooltip={'Exporta un excel con las facturas electrónicas emitidas'}
                      color={'accent'}
                      icon={faDownload}
                      onClick={() => getInvoiceItemsExcel(false)}>
                      Facturas electrónicas
                    </Button>
                  </>
                )}
              {filters.reportType.value !==
                segmentationReportTypeEnum.BY_INVOICED_ITEMS.id && (
                <Button
                  loading={loadingExcelReport}
                  disabled={loadingGetReport}
                  color={'accent'}
                  icon={faDownload}
                  onClick={() => setUp(true)}>
                  Descargar
                </Button>
              )}
              <Button
                disabled={disabled}
                color={'secondary'}
                icon={faBroom}
                onClick={onClearFilters}>
                Limpiar parámetros
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>

      {!report || report.length === 0 ? (
        // @ts-ignore
        <Empty
          className={'self-center'}
          title={'No hay registros para mostrar'}
          hideButton
          subtitle={''}
          buttonTitle={'Aplicar filtros'}
          subtitleSecond={'Presiona "Buscar" para aplicar los filtros'}
        />
      ) : (
        <Row>
          <Col xl={12}>
            <div className={'space-between mb-3 px-3'}>
              <Paragraph bold size={'extraBig'}>
                TOTAL
              </Paragraph>
              <Paragraph bold size={'extraBig'}>
                {toMoney(report.reduce((total, i) => total + i.total, 0))}
              </Paragraph>
            </div>
          </Col>
          <Col xl={12}>
            <Row>{renderContent(report, true)}</Row>
          </Col>
        </Row>
      )}

      {/* @ts-ignore */}
      <Folder
        show={showCategories.show}
        list={(showCategories.filter
          ? filters.categories
          : filters.segmentedCategories
        ).map(c => c.id)}
        noMessage
        onHide={() => setShowCategories({ show: false, filter: false })}
        data1={categories && categories.children ? categories.children[0] : {}}
        data2={categories && categories.children ? categories.children[1] : {}}
        onAssign={item => onAssignCategorization(item, showCategories.filter)}
      />
    </div>
  )
}
export default SegmentationReport
