import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Row, Col, Modal} from 'react-bootstrap';
import {Td, Tr} from 'react-super-responsive-table';

import Card from '../../../components/cards/Card';
import Icon from '../../../components/buttons/IconButton';
import Tabs from '../../../components/navigation/Tabs/CustomTabs';
import CustomReference from '../../../components/modal/CustomReference';

import {actionTypes as typeB, getBalanceEntries, getKoloCompanyBalance, getKoloBalanceByCompanyAndModule} from "../../../actions/balance.actions";
import {selectBalanceCompanyEntries, selectKoloCompaniesBalance, selectKoloCompanyBalance} from "../../../selectors/balance.selector";

import {selectSetCurrentCompany} from "../../../selectors/company.selector";
import {
    formatDateFromMillis,
    formatNumberWithCommas
} from "../../../utils/formatters";

import {loadingSelector} from "../../../selectors/loading.selector";
import {
    faEye,
    faMinusCircle, faSignal
} from "@fortawesome/free-solid-svg-icons";
import { TableV2 } from '../../../components';

const BalanceKoloAdmin = () => {
    const dispatch = useDispatch();

    const company = useSelector(selectSetCurrentCompany);

    const balance = useSelector(selectBalanceCompanyEntries);
    const loadingB = useSelector((state) => loadingSelector([typeB.GET_BALANCE_ENTRIES])(state));

    const companies = useSelector(selectKoloCompaniesBalance);
    const loadingC = useSelector((state) => loadingSelector([typeB.GET_KOLO_COMPANY_BALANCE])(state));

    const balanceCompany = useSelector(selectKoloCompanyBalance);
    const loadingCB = useSelector((state) => loadingSelector([typeB.GET_KOLO_BY_COMPANY_BALANCE])(state));

    const [headers] = useState(['', 'Fecha', 'Accion', 'Monto', 'Balance anterior', 'Balance Nuevo', 'Referencia']);
    
    const [modal, setModal] = useState({show: false, company: {}, dates: {start: new Date(), end: new Date()}});
    const [reference, setRef] = useState({show: false, type: 1, documentId: 1});

    useEffect(() => {
        dispatch(getKoloCompanyBalance());
        setUp(new Date(), new Date());
    }, []);

    const setUp = (sDate, eDate) => {
        const start = sDate.setHours(0,0,0, 0).valueOf();
        const end = eDate.setHours(23,59,59, 59).valueOf();

        dispatch(getBalanceEntries(1, {start, end}));
    };

    const getColor = (item) => {
        let color = '';
        let icon = faMinusCircle;
        let flip = '';

        if (item.previousBalance > item.newBalance) {
            color = 'red';
            icon = faSignal;
            flip = 'horizontal';
        } else if (item.previousBalance < item.newBalance) {
            color = 'green';
            icon = faSignal;
        }

        return {color, icon, flip};
    };

    
    const setUpCompany = (companyId, moduleId, sDate, eDate, item) => {
        if (!item)
            item = modal.company;

        setModal({...modal, company: item, show: true, dates: {start: sDate, end: eDate}});

        const start = sDate.setHours(0,0,0, 0).valueOf();
        const end = eDate.setHours(23,59,59, 59).valueOf();

        dispatch(getKoloBalanceByCompanyAndModule(companyId, moduleId, {start, end}));
    };
    const getHeaders = () =>{
        return [
            {label: '',select:true, show: true,  custom: (item) => { let value = getColor(item); return (<Icon color={value.color}  flip={value.flip} icon={value.icon} tooltip={''}/>)},  className:'mini'}  ,
            {label: 'Fecha', show: true, value: ['createdAt'], type: 'date' ,  className:'mini'},
            {label: 'Acción', show: true, value: ['entryType', 'name'], type: 'text' ,  className:'short'},
            {label: 'Monto', show: true, value: ['amount'], type: 'currency' ,  className:'mini'},
            {label: 'Balance anterior', show: true, value: ['previousBalance'], type: 'currency' ,  className:'mini'},
            {label: 'Balance Nuevo', show: true, value: ['newBalance'], type: 'currency' ,  className:'mini'},
            {config:true, show: true, label:'', className:'mini', custom: (item) => <Icon icon={faEye} tooltip={'Ver referencia'} onClick={() => setRef({show: true, type: item.type, documentId: item.reference || item.id})}/>}]
    }
    return (<div className={'column text-center'}>
        <h1 className={'dashboard-welcome'}>Balance</h1>
        <Row>
            <Col xl={5} lg={5} md={5} sm={12} xs={12}><Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}><Card title={'Balance a la fecha'}>
                    <h2>{company.name}</h2>
                    <h3>Balance <b className={company.adminBalance > 0 ? 'green' :company.adminBalance <0 ? 'red' : ''}>
                        Q. {formatNumberWithCommas(company.adminBalance)}
                    </b></h3>
                </Card></Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}><Card title={'Empresas'}>
                    <TableV2
                        headers={[
                            {label: 'Nombre', show: true, value: ['name'], type: 'text' ,  className:'medium'},
                            {label: 'B. Poductor', show: true, value: ['prod'], type: 'currency' ,  className:'mini', 
                            custom:(item) =><>Real: Q.{formatNumberWithCommas(item.prod)}<br/>Retenido: Q.{formatNumberWithCommas(item.prodRet)}</>},
                            {label: 'B. Transportista', show: true, value: ['createdAt'], type: 'date' ,  className:'mini',
                            custom:(item) =><> Real: Q.{formatNumberWithCommas(item.transp)}<br/>Retenido: Q.{formatNumberWithCommas(item.transpRet)}</>},
                            {config:true, show: true, label:'', className:'mini', custom:(item) =><Icon onClick={() => {
                                setUpCompany(item.id, 1000, new Date(), new Date(), item);
                            }} icon={faEye} tooltip={'Ver Balance'}/> }
                        ]}
                        pSize={0}
                        items={companies}
                        loading={loadingC}
                        mobileAuto={true}
                    />
                </Card></Col>
            </Row></Col>
            <Col xl={7} lg={7} md={7} sm={12} xs={12}><Card title={'Balance'}>
                <TableV2
                    pSize={0}
                    headers = {getHeaders()}
                    items={balance}
                    dateFilter
                    onDateChange={(start, end) => setUp(start, end)}
                    loading={loadingB}
                    mobileAuto={true}
                    storageKey={`balanceKolo`}
                />
               
            </Card></Col>
        </Row>

        <Modal
            show={modal.show}
            centered
            size={'xl'}
            onHide={() => setModal({...modal, show: false})}>

            <Modal.Header><Modal.Title>Balances de {modal.company.name}</Modal.Title></Modal.Header>
            <Modal.Body><div className={'column'}>
                <Tabs
                    setKey={(key) => {
                        const {start, end} = modal.dates;
                        if (key === "0")
                            setUpCompany(modal.company.id, 1000, start, end);
                        else
                            setUpCompany(modal.company.id, 2000, start, end);
                    }}
                    items={[
                        {
                            title: 'Balance transportista',
                            info: 'Listado de entradas de balance de transporitsta',
                            component:
                                <TableV2
                                    pSize={0}
                                    headers={getHeaders()}
                                    items={balanceCompany}
                                    dateFilter
                                    onDateChange={(start, end) => setUpCompany(modal.company.id, 1000, start, end)}
                                    loading={loadingB}
                                    mobileAuto={true}
                                    storageKey={`balanceTransp`}
                                />
                        },
                        {
                            title: 'Balance productor',
                            info: 'Listado de entradas de balance de productor',
                            component:
                                <TableV2
                                    pSize={0}
                                    headers={getHeaders()}
                                    items={balanceCompany}
                                    dateFilter
                                    onDateChange={(start, end) => setUpCompany(modal.company.id, 2000, start, end)}
                                    loading={loadingB}
                                    mobileAuto={true}
                                    storageKey={`balanceProd`}
                                />
                        }
                    ]}
                />
            </div></Modal.Body>
        </Modal>

        <CustomReference
            show={reference.show}
            documentModule={5}
            documentType={reference.type}
            documentId={reference.documentId}
            onHide={() => setRef({...reference, show: false})}
        />
    </div>);
};
export default BalanceKoloAdmin;
