import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Col, Modal, Row } from 'react-bootstrap'
import SelectedDates from 'src/components/SelectedDates/SelectedDates'
import TableV2 from 'src/components/Pagination/PaginatedTableV2'
import Button from 'src/components/buttons/Button'
import { faFileExcel, faSearch } from '@fortawesome/free-solid-svg-icons'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  actionTypes,
  getCompanyActivities,
  getCompanyActivitiesExcel,
} from 'src/actions/mentor.actions'
import { selectCompanyActivities } from 'src/selectors/mentor.selector'

const today = new Date()

interface IProps {
  show: boolean
  onHide: () => void
  filters: MFilterParamsCompanyActivity
}

/**
 *  Modal to show the company activity report
 * @param {boolean} show Show the modal
 * @param {function} onHide Close the modal
 * @param {MFilterParamsCompanyActivity} filters Filters to apply
 * @returns
 */
export const CompanyActivity = ({ show, onHide, filters }: IProps) => {
  const dispatch = useDispatch()

  const loading: boolean = useSelector(state =>
    loadingSelector([actionTypes.GET_COMPANY_ACTIVITIES])(state),
  )
  const loadingExcel: boolean = useSelector(state =>
    loadingSelector([actionTypes.GET_COMPANY_ACTIVITIES_EXCEL])(state),
  )
  const companyActivity: ICompanyActivity = useSelector(selectCompanyActivities)

  const [dates, setDates] = useState({
    start: new Date(today.getFullYear(), today.getMonth(), 1),
    end: today,
  })

  useEffect(() => {
    if (!show) return

    setUp()
  }, [show])

  const setUp = () => {
    dispatch(
      getCompanyActivities({
        ...filters,
        skip: null,
        size: null,
        startDateTransaction: dates.start.valueOf(),
        endDateTransaction: dates.end.valueOf(),
      }),
    )
  }

  const onClose = () => {
    setDates({
      start: new Date(today.getFullYear(), today.getMonth(), 1),
      end: today,
    })
    onHide()
  }

  return (
    <Modal show={show} size={'lg'} centered onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Reporte de actividad de empresas en KOLO</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <SelectedDates
                nonExecute
                dates={{
                  dateFrom: dates.start,
                  dateTo: dates.end,
                }}
                onDateChange={(start, end) => setDates({ start, end })}
              />
            </Col>
            <Row style={{ float: 'right' }}>
              <Button
                loading={loading}
                color={'accent'}
                dataCy={'apply-filters'}
                icon={faSearch}
                onClick={() => {
                  setUp()
                }}>
                Aplicar Filtros
              </Button>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xl={12} md={12} sm={12}>
            <TableV2
              /* @ts-expect-error */
              loading={loading}
              items={companyActivity}
              mobileAuto
              storageKey={`company-activity`}
              headers={[
                {
                  label: 'Código',
                  show: true,
                  value: ['code'],
                  type: 'text',
                },
                {
                  label: 'Nombre',
                  show: true,
                  value: ['name'],
                  type: 'text',
                },
                {
                  label: 'Correo',
                  show: true,
                  value: ['email'],
                  type: 'text',
                },
                {
                  label: 'Transacciones',
                  show: true,
                  value: ['transactions'],
                  type: 'wholeNumber',
                },
              ]}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className={'container-buttons'}>
          <Button
            loading={loadingExcel}
            icon={faFileExcel}
            dataCy="export-button"
            onClick={() => {
              dispatch(
                getCompanyActivitiesExcel({
                  ...filters,
                  startDateTransaction: dates.start.valueOf(),
                  endDateTransaction: dates.end.valueOf(),
                }),
              )
            }}>
            Exportar
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}
