import ReportServices from '../services/report.services'
import { executeAction } from './global.actions'

export const actionTypes = {
  GET_PDF: 'GET_PDF',
  GET_EXCEL: 'GET_EXCEL',
  CREATE_SUBSCRIPTION: 'CREATE_SUBSCRIPTION',
  GET_REPORT_SUBSCRIPTION: 'GET_REPORT_SUBSCRIPTION',
  DELETE_REPORT_SUBSCRIPTION: 'DELETE_REPORT_SUBSCRIPTION',
  GET_SEGMENTATION: 'GET_SEGMENTATION',
  GET_SEGMENTATION_DATA: 'GET_SEGMENTATION_DATA',
  SET_SEGMENTATION_DATA: 'SET_SEGMENTATION_DATA',
  GET_SCHEDULED_REPORTS: 'GET_SCHEDULED_REPORTS',
  GET_SINGLE_SCHEDULED_REPORT: 'GET_SINGLE_SCHEDULED_REPORT',
  CREATE_SCHEDULED_REPORT: 'CREATE_SCHEDULED_REPORT',
  UPDATE_SCHEDULED_REPORT: 'UPDATE_SCHEDULED_REPORT',
  DELETE_SCHEDULED_REPORT: 'DELETE_SCHEDULED_REPORT',
  SEND_SCHEDULED_REPORT: 'SEND_SCHEDULED_REPORT',
  PURCHASE_WASTE_REPORT: 'PURCHASE_WASTE_REPORT',
  GET_BRANCH_REPORT: 'GET_BRANCH_REPORT',
  GET_BRANCH_REPORT_EXCEL: 'GET_BRANCH_REPORT_EXCEL',
  GET_SEGMENTATION_REPORT: 'GET_SEGMENTATION_REPORT',
  GET_SEGMENTATION_REPORT_EXCEL: 'GET_SEGMENTATION_REPORT_EXCEL',
  GET_SALE_REPORT_BY_ITEMS: 'GET_SALE_REPORT_BY_ITEMS',
  GET_SALE_REPORT_BY_ITEMS_EXCEL: 'GET_SALE_REPORT_BY_ITEMS_EXCEL',
  GET_TOTAL_SALE_REPORT: 'GET_TOTAL_SALE_REPORT',
  GET_VEHICLE_REPORT: 'GET_VEHICLE_REPORT',
}

const setQueryInfo = queries => ({
  type: actionTypes.SET_SEGMENTATION_DATA,
  queries,
})

export const getProductPdfReport =
  (start, end, distribuitor, deliverer, seller) => dispatch => {
    const process = async () => ({
      requests: await ReportServices.getPdfReport(
        { start, end, distribuitor, deliverer, seller },
        'Reporte de Producto',
        'products',
      ),
    })
    dispatch(executeAction(actionTypes.GET_PDF, process))
  }

export const getUserPdfReport = (start, end, categories) => dispatch => {
  const process = async () => ({
    request: await ReportServices.getPdfReport2(
      { start, end },
      'Reporte de Ventas por Usuarios',
      'users',
      categories,
    ),
  })
  dispatch(executeAction(actionTypes.GET_PDF, process))
}

/**
 * Action para obtener el reporte de ventas por usuarios en excel
 * @param {number} start Fecha de inicio
 * @param {number} end Fecha de fin
 * @param {Object} data Filters data
 * @returns None
 */
export const getUserExcelReport = (start, end, data) => dispatch => {
  const process = async () => ({
    request: await ReportServices.getExcelReport(
      { start, end },
      'Reporte de ventas por Usuarios',
      'users',
      data,
    ),
  })
  dispatch(executeAction(actionTypes.GET_EXCEL, process))
}

export const getClientsPdfReport = (start, end) => dispatch => {
  const process = async () => ({
    request: await ReportServices.getPdfReport(
      { start, end },
      'Reporte de Clientes',
      'clients',
    ),
  })
  dispatch(executeAction(actionTypes.GET_PDF, process))
}

/**
 * Action para obtener el reporte de clientes en excel
 * @param {number} start Fecha de inicio
 * @param {number} end Fecha de fin
 * @returns none
 */
export const getClientsExcelReport = (start, end) => dispatch => {
  const process = async () => ({
    request: await ReportServices.getExcelReport(
      { start, end },
      'Reporte de Clientes',
      'clients',
    ),
  })
  dispatch(executeAction(actionTypes.GET_EXCEL, process))
}

export const getSalesPdfReport = (start, end, type, data) => dispatch => {
  const process = async () => ({
    request: await ReportServices.getPdfReport2(
      { start, end, type },
      'Reporte de Ventas',
      'sales',
      data,
    ),
  })
  dispatch(executeAction(actionTypes.GET_PDF, process))
}

export const getWastePdfReport = (start, end, type, data, name, advances) => dispatch => {
  const process = async () => ({
    request: await ReportServices.getPdfReport2(
      { start, end, type, advances },
      name,
      'waste',
      data,
    ),
  })
  dispatch(executeAction(actionTypes.GET_PDF, process))
}

export const createSubscription = request => dispatch => {
  const process = async () => ({
    request: await ReportServices.createSubscription(request),
  })
  dispatch(executeAction(actionTypes.CREATE_SUBSCRIPTION, process))
}

export const getReportSubscription = () => dispatch => {
  const process = async () => ({
    response: await ReportServices.getReportSubscription(),
  })
  dispatch(executeAction(actionTypes.GET_REPORT_SUBSCRIPTION, process))
}

export const deleteReportSubscription = id => dispatch => {
  const process = async () => ({
    response: await ReportServices.deleteReportSubscription(id),
  })
  dispatch(executeAction(actionTypes.DELETE_REPORT_SUBSCRIPTION, process))
}

export const getReportSegmentation = (params, request) => dispatch => {
  const process = async () => await ReportServices.getReportSegmentation(params, request)
  dispatch(executeAction(actionTypes.GET_SEGMENTATION, process))
}

export const getSegmentationData = (params, request) => dispatch => {
  const process = async () => ({
    response: await ReportServices.getSegmentationData(params, request),
  })
  dispatch(executeAction(actionTypes.GET_SEGMENTATION_DATA, process))
}

export const onSetQueryInfo = queries => dispatch => {
  dispatch(setQueryInfo(queries))
}

/**
 * Creates a scheduled report
 * @param {Object} request Request
 */
export const createScheduleReport = request => dispatch => {
  const process = async () => ({
    response: await ReportServices.createScheduleReport(request),
  })
  dispatch(executeAction(actionTypes.CREATE_SCHEDULED_REPORT, process))
}

/**
 * Updates a scheduled report
 * @param {number} id Id of the scheduled report
 * @param {Object} request Request
 */
export const updateScheduleReport = (id, request) => dispatch => {
  const process = async () => ({
    response: await ReportServices.updateScheduleReport(id, request),
  })
  dispatch(executeAction(actionTypes.UPDATE_SCHEDULED_REPORT, process))
}

export const deleteScheduleReport = id => dispatch => {
  const process = async () => ({
    response: await ReportServices.deleteScheduleReport(id),
  })
  dispatch(executeAction(actionTypes.DELETE_SCHEDULED_REPORT, process))
}

export const getSingleScheduleReport = id => dispatch => {
  const process = async () => ({
    response: await ReportServices.getSingleScheduleReports(id),
  })
  dispatch(executeAction(actionTypes.GET_SINGLE_SCHEDULED_REPORT, process))
}

export const getScheduledReports = () => dispatch => {
  const process = async () => ({
    response: await ReportServices.getScheduleReports(),
  })
  dispatch(executeAction(actionTypes.GET_SCHEDULED_REPORTS, process))
}

export const sendScheduledReport = id => dispatch => {
  const process = async () => ({
    response: await ReportServices.sendScheduleReport(id),
  })
  dispatch(executeAction(actionTypes.SEND_SCHEDULED_REPORT, process))
}

export const getWasteAndExpensesReportV2 = (request, params) => dispatch => {
  const process = async () => ({
    response: await ReportServices.getWasteAndExpensesReportV2(request, params),
  })
  dispatch(executeAction(actionTypes.PURCHASE_WASTE_REPORT, process))
}

export const getBranchReport = params => dispatch => {
  const process = async () => ({
    response: await ReportServices.getBranchReport(params),
  })
  dispatch(executeAction(actionTypes.GET_BRANCH_REPORT, process))
}

export const getBranchReportExcel = params => dispatch => {
  const process = async () => ({
    response: await ReportServices.getBranchReportExcel(params),
  })
  dispatch(executeAction(actionTypes.GET_BRANCH_REPORT_EXCEL, process))
}

/** Get a reporte segmented by categories and client
 * @param {Object} params Parameters for filtering and segmenting the report.
 * @param {number} params.reportType Type report by SegmentationReportTypeEnum
 * @param {number} params.start Filter date
 * @param {number} params.end Filter date
 * @param {string} params.categories Filter by categories
 * @param {string} params.clients Filter by clients
 * @param {string} params.items Filter by items
 * @param {string} params.segmentedCategories Segmentation by categories
 * @param {boolean} params.segmentByClient Indicates if it is necessary to segment by customer
 * **/
export const getSegmentationReportOpt = params => dispatch => {
  const process = async () => ({
    response: await ReportServices.getSegmentationReportOpt(params),
  })
  dispatch(executeAction(actionTypes.GET_SEGMENTATION_REPORT, process))
}

/** Get a reporte segmented in EXCEL format by categories and client
 * @param {Object} params Parameters for filtering and segmenting the report.
 * @param {number} params.reportType Type report by SegmentationReportTypeEnum
 * @param {number} params.start Filter date
 * @param {number} params.end Filter date
 * @param {string} params.categories Filter by categories
 * @param {string} params.clients Filter by clients
 * @param {string} params.items Filter by items
 * @param {string} params.segmentedCategories Segmentation by categories
 * @param {boolean} params.segmentByClient Indicates if it is necessary to segment by customer
 * @param {string} reportName Name of report
 * **/
export const getSegmentationReportOptExcel = (params, reportName) => dispatch => {
  const process = async () =>
    await ReportServices.getSegmentationReportOptExcel(params, reportName)
  dispatch(executeAction(actionTypes.GET_SEGMENTATION_REPORT_EXCEL, process))
}

/**
 * Action to get the sale report by items
 * @param {Object} params Params of the request
 * @param {number} params.startDate Start date
 * @param {number} params.endDate End date
 * @param {string | number | boolean} params.deliverer Deliverer id
 * @param {string | number | boolean} params.seller Seller id
 * @param {Object} request Request
 * @param {Array} request.categories Categories filter
 * @param {Array} request.pos Points of sale filter
 * @param {Array} request.warehouses Warehouses filter
 * @returns none
 */
export const getSaleReportByItems = (params, request) => dispatch => {
  const process = async () => ({
    response: await ReportServices.getSaleReportByItems(params, request),
  })
  dispatch(executeAction(actionTypes.GET_SALE_REPORT_BY_ITEMS, process))
}

/**
 * Action to get the sale report by items in excel
 * @param {Object} params Params of the request
 * @param {number} params.startDate Start date
 * @param {number} params.endDate End date
 * @param {string | number | boolean} params.deliverer Deliverer id
 * @param {string | number | boolean} params.seller Seller id
 * @param {boolean} params.segmented Report segmented by deliver
 * @param {Object} request Request
 * @param {Array} request.categories Categories filter
 * @param {Array} request.pos Points of sale filter
 * @param {Array} request.warehouses Warehouses filter
 * @returns none
 */
export const getSaleReportByItemsExcel = (params, request) => dispatch => {
  const process = async () => ({
    response: await ReportServices.getSaleReportByItemsExcel(params, request),
  })
  dispatch(executeAction(actionTypes.GET_SALE_REPORT_BY_ITEMS_EXCEL, process))
}

/**
 * Action to get the sale report by items
 * @param {Object} params Params of the request
 * @param {number} params.startDate Start date
 * @param {number} params.endDate End date
 * @param {number} params.vendor Created by id
 * @param {number} params.deliverer Deliverer id
 * @param {number} params.warehouse Warehouse id
 * @param {number} params.dateType Type date, 1: Day, 2: Week, 3: Month
 * @param {number} params.type Type of data 1: orders, 4: cpc
 * @param {Object} request Request
 * @param {Array} request.categories Categories filter
 * @param {Array} request.pos Points of sale filter
 * @returns none
 */
export const getTotalSaleReport = (params, request) => dispatch => {
  const process = async () => ({
    response: await ReportServices.getTotalSaleReport(params, request),
  })
  dispatch(executeAction(actionTypes.GET_TOTAL_SALE_REPORT, process))
}

/**
 * Action to get the sale report by items
 * @param {Object} params Params of the request
 * @param {number} params.startDate Start date
 * @param {number} params.endDate End date
 * @param {number} params.rate Rate id
 * @param {number} params.enteredBy Deliverer id
 * @param {number} params.finishedBy Seller id
 * @param {number} params.pos Point of sale id
 * @param {number} params.dateType Type date to filter
 * @returns none
 */
export const getVehicleReport = params => dispatch => {
  const process = async () => ({
    response: await ReportServices.getVehicleReport(params),
  })
  dispatch(executeAction(actionTypes.GET_VEHICLE_REPORT, process))
}

/**
 * Action to get the sale report by items in excel
 * @param {Object} params Params of the request
 * @param {number} params.startDate Start date
 * @param {number} params.endDate End date
 * @param {number} params.rate Rate id
 * @param {number} params.enteredBy Deliverer id
 * @param {number} params.finishedBy Seller id
 * @param {number} params.pos Point of sale id
 * @param {number} params.dateType Type date to filter
 * @returns none
 */
export const getVehicleReportExcel = params => dispatch => {
  const process = async () => ({
    response: await ReportServices.getVehicleReportExcel(params),
  })
  dispatch(executeAction(actionTypes.GET_VEHICLE_REPORT, process))
}
