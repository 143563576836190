export const selectContractedCompanies = state => state.contracts.contractedCompanies

export const selectContractedCompaniesForSelect = state => {
  const { contracts, company } = state
  return [
    {
      value: company.company.id,
      label: `${company.company.alphanumericId} - ${company.company.name}`,
    },
  ].concat(
    contracts.contractedCompanies.map(item => ({
      value: item.id,
      label: item.alphanumericId + ' - ' + item.name,
    })),
  )
}

export const companies = state => {
  const { contracts } = state
  return contracts.contractedCompanies.map(item => ({
    value: item.id,
    label: item.alphanumericId + ' - ' + item.name,
  }))
}

export const selectContractedByCompanies = state => state.contracts.contractedByCompanies
export const selectContractedByCompaniesForSelect = state =>
  state.contracts.contractedByCompanies.map(item => ({
    value: item.id,
    label: item.alphanumericId + ' - ' + item.name,
  }))

export const selectToggleProducerShipperContract = state =>
  state.contracts.toggleProducerShipperContract

export const selectContractedProducts = state => state.contracts.products
