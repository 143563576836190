import React, { Component } from 'react'
import './AuthContainer.scss'
import logo from 'src/assets/images/kolo-logo1.png'
import { Card, Col, Container, Row } from 'react-bootstrap'

export default class AuthContainer extends Component {
  render() {
    return (
      <div className={'auth-container'}>
        <Container>
          <Row className={'align-items-center h-100'}>
            <Col className={'d-none d-sm-block'}>
              <img src={logo} alt="logo" className={'logo mx-auto d-block'} />
            </Col>
            <Col>
              <img
                src={logo}
                alt="logo"
                className={'logo small d-block d-sm-none mx-auto'}
              />
              <Card className={'login-card mx-auto'}>{this.props.children}</Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
