import './Tag.scss'
import React from 'react'

import Tag from './Tag'

const ListTags = ({
  tags,
  onChange,
  selected,
  toggle,
  editable,
  options,
  noDelete,
  isCommentary,
}) => {
  return (
    <div className={'ml-1 mb-3 list-tags'}>
      {tags &&
        tags.map((item, index) => {
          const { value, label } = item
          return (
            <div
              className={'ml-1 '.concat(
                isCommentary ? 'list-tag-child-comment' : 'list-tag-child',
              )}
              key={index}
            >
              <Tag
                isComment={isCommentary}
                noDelete={noDelete}
                options={options}
                editable={toggle ? value !== null && value !== -1 && editable : editable}
                selected={item.selected || selected === value}
                value={label}
                toggle={toggle}
                onChange={i => {
                  if (onChange) {
                    if (toggle) onChange({ ...item, index, newValue: i })
                    else onChange({ ...item, value: i, index })
                  }
                }}
              />
            </div>
          )
        })}
    </div>
  )
}
export default ListTags
