import CompanyConfigurations from '../services/companyConfiguration.service'
import { executeAction } from './global.actions'

export const actionTypes = {
  GET_CONFIGURATIONS_TYPES: 'GET_CONFIGURATIONS_TYPES',
  GET_CONFIGURATIONS: 'GET_CONFIGURATIONS',
  EDIT_CONFIGURATION: 'EDIT_CONFIGURATION',
  GET_FEL_CONFIGURATIONS: 'GET_FEL_CONFIGURATIONS',
  UPDATE_FEL_CONFIGURATIONS: 'UPDATE_FEL_CONFIGURATIONS',
}

export const getConfigurationTypes = () => dispatch => {
  console.log('inAction')
  const process = async () => ({
    configurationTypes: await CompanyConfigurations.getCompanyConfigurationTypes(),
  })
  dispatch(executeAction(actionTypes.GET_CONFIGURATIONS_TYPES, process))
}

export const getConfigurations = () => dispatch => {
  const process = async () => {
    const configurations = await CompanyConfigurations.getCompanyConfigurations()
    return configurations
  }
  dispatch(executeAction(actionTypes.GET_CONFIGURATIONS, process))
}

export const setConfiguration = () => dispatch => {
  const process = async () => {
    const configurations = await CompanyConfigurations.getCompanyConfigurations()
    dispatch(getConfigurations())
    return configurations
  }
  dispatch(executeAction(actionTypes.EDIT_CONFIGURATION, process))
}

export const getFelConfiguration = companyId => dispatch => {
  const process = async () => ({
    response: await CompanyConfigurations.getFelConfiguration(companyId),
  })
  dispatch(executeAction(actionTypes.GET_FEL_CONFIGURATIONS, process))
}

export const updateFelConfiguration = (companyId, request) => dispatch => {
  const process = async () => ({
    response: await CompanyConfigurations.updateFelConfiguration(companyId, request),
  })
  dispatch(executeAction(actionTypes.UPDATE_FEL_CONFIGURATIONS, process))
}
