import React, { useState, useEffect } from 'react'

import { Nav, ProgressBar } from 'react-bootstrap'

import Icon from '../../buttons/IconButton'
import Card from '../../cards/Card'

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const CustomTabs = props => {
  const [key, setKey] = useState(-1)
  const [component, setComponent] = useState(<div>Pendiente</div>)

  useEffect(() => {
    if (props.defaultKey) setKey(props.defaultKey)
    else {
      setKey(getKey())
      if (props.setValue) props.setValue(getKey())
    }
  }, [])

  useEffect(() => {
    if (props.defaultKey) setKey(props.defaultKey)
  }, [props.defaultKey])

  useEffect(() => {
    if (key === -1) return
    if (!props.items) return

    const cItems = props.items.filter(filters)

    if (cItems[key]) {
      setComponent(cItems[key].component)
    }
  }, [key])

  useEffect(() => {
    if (!props.items) return
    let refKey = key
    if (key === -1) refKey = props.defaultKey || 0

    const cItems = props.items.filter(filters)

    if (cItems[refKey]) {
      setComponent(cItems[refKey].component)
    }
  }, [props])

  const filters = item => {
    if (item.show === undefined || item.show === null) return true
    else return item.show
  }

  const getKey = () => {
    let dKey = 0

    if (props.items.filter(filters).length > 0) {
      dKey = props.items.filter(filters)[0].value || 0
    }

    return dKey
  }

  const getComponent = () => {
    return (
      <div className={''.concat(props.ctBank ? '' : '')}>
        <Nav
          style={{ ...props.style }}
          fill
          variant={'tabs'}
          defaultActiveKey={props.defaultKey ? props.defaultKey : getKey()}
          onSelect={selectedKey => {
            setKey(selectedKey)
            if (props.setKey) props.setKey(selectedKey)
            if (props.setValue) {
              const value = props.items.filter(filters)[selectedKey]
              props.setValue(value.value)
            }
          }}>
          {props.items.filter(filters).map((item, index) => (
            <Nav.Item key={index}>
              <Nav.Link eventKey={index} disabled={props.disabled || item.disabled}>
                <div className={'column'}>
                  {item.info && (
                    <Icon icon={item.icon || faInfoCircle} tooltip={item.info} />
                  )}
                  {item.title}
                </div>
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
        {props.loading ? (
          <div className={'pb-custom'}>
            <ProgressBar label={'cargando'} animated now={100} />
          </div>
        ) : (
          component
        )}
      </div>
    )
  }

  return props.noCard ? getComponent() : <Card>{getComponent()}</Card>
}
export default CustomTabs
