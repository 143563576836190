import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Col, Modal, ProgressBar, Row } from 'react-bootstrap'
import { Button, CustomDate, Paragraph, SwitchV2, TableV2 } from 'src/components'
import PlanItem from 'src/content/Distrbution/Plans/PlanItem'
import { DetailCard } from 'src/components/cards/details-card/DetailCard'

import { toMoney } from 'src/utils/utilities'
import { howMuchToPay } from 'src/utils/utilitiesV2'

import { actions, getCompanyActivePlan } from 'src/actions/plans.actions'
import { actionTypes, getClientByCotizapClient } from 'src/actions/mentor.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import { selectCompanyActivePlans } from 'src/selectors/plans.selector'
import { selectClientCompany } from 'src/selectors/mentor.selector'
import { setModalEditClient } from 'src/actions/utilities.actions'
import { formatDateFromMillis } from 'src/utils/formatters'
import { AddonPlanEnum } from 'src/enums/planTypes'

interface IProps {
  clientId?: number
  companyId?: number
  show: boolean
  onHide: () => void
  isRenewal: boolean
  isDemo: boolean
  plan?: MPlanItem
  addons: MCompanyAddons[]
  onSave: () => void
  periodicity?: number
  amountPlan?: number
}

const today = new Date()

/**
 * Shows the resume of the plan and addons to pay
 * @param {number} clientId - Id of the client
 * @param {number} companyId - Id of the
 * @param {boolean} show - Show the modal
 * @param {Function} onHide - Close the modal
 * @param {boolean} isRenewal - Indicate if the company is in renewal
 * @param {boolean} isDemo - Indicate if the company is in demo
 * @param {MPlanItem} plan - Plan to show
 * @param {MCompanyAddons[]} addons - Addons to show
 * @param {Function} onSave - Function to call when the user click on save
 * @param {number} periodicity - Periodicity of the plan
 * @param {number} amountPlan - Amount of the plan
 * @returns
 */
export const ResumePlan = ({
  clientId,
  companyId,
  show,
  onHide,
  isRenewal,
  isDemo,
  plan,
  addons = [],
  onSave,
  periodicity,
  amountPlan,
}: IProps) => {
  const dispatch = useDispatch()
  const activePlan: MPlanItem = useSelector(selectCompanyActivePlans)
  const client = useSelector(selectClientCompany)
  const [quote, setQuote] = useState({
    date: new Date(),
    active: false,
  })
  const [addonsToPay, setAddonsToPay] = useState<MCompanyAddons[]>([])
  const [addonInvoices, setAddonInvoices] = useState<MCompanyAddons[]>([])
  const totalAddons = addonsToPay.reduce((acc, item) => acc + item.toPay, 0)
  const [endDate, setEndDate] = useState<Date>(new Date())

  const loadingActivePlan: boolean = useSelector(state =>
    loadingSelector([actions.GET_COMPANY_ACTIVE_PLAN])(state),
  )
  const loadingClient: boolean = useSelector(state =>
    loadingSelector([actionTypes.GET_CLIENT_BY_COTIZAP_CLIENT])(state),
  )
  const loadingFinish = useSelector(state =>
    loadingSelector([actionTypes.ON_FINISH_DEMO_ACCOUNT, actionTypes.ADD_ADDONS])(state),
  )

  useEffect(() => {
    if (!show) return
    if (!plan) dispatch(getCompanyActivePlan(companyId))
    else calculateTotal()
    if (clientId && companyId) dispatch(getClientByCotizapClient(clientId, 1))
  }, [show])

  useEffect(() => {
    if (loadingActivePlan) return
    if (activePlan) calculateTotal()
  }, [loadingActivePlan])

  const calculateTotal = (date: Date = today) => {
    const renovationDate =
      isDemo || isRenewal
        ? (periodicity || activePlan.recurrence) === 1
          ? new Date(today.getFullYear(), today.getMonth() + 1, 1)
          : new Date(today.getFullYear() + 1, today.getMonth(), today.getDate())
        : new Date(activePlan?.nextDate || plan?.nextDate)

    setEndDate(renovationDate)

    const addonsToShow = addons
      .filter(
        item =>
          item.planId !== AddonPlanEnum.INVOICES && item?.item?.code !== 'P-FACTURA',
      )
      .map(item => {
        const pay = howMuchToPay(
          date,
          renovationDate,
          item.amount,
          periodicity || activePlan.recurrence,
        )
        return {
          ...item,
          toPay: pay.toPay,
          days: pay.days,
        }
      })

    if (plan) {
      const price = amountPlan > 0 ? amountPlan : plan?.price
      const pay = howMuchToPay(
        date,
        renovationDate,
        price,
        periodicity || activePlan.recurrence,
      )
      addonsToShow.push({
        ...pay,
        item: { name: plan?.name },
        quantity: 1,
        price,
      })
    }
    setAddonsToPay(addonsToShow)

    if (!isRenewal)
      setAddonInvoices(
        addons.filter(
          item =>
            item.planId === AddonPlanEnum.INVOICES || item.item.code === 'P-FACTURA',
        ),
      )
  }

  const onClose = () => {
    setQuote({
      date: new Date(),
      active: false,
    })
    setAddonsToPay([])
    onHide()
  }

  return (
    <>
      <Modal show={show} size={'lg'} centered onHide={() => onClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Resumen de totales a facturar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={'custom-modal-body'}>
            <Row>
              {(loadingActivePlan || loadingClient) && (
                <ProgressBar
                  label="Cargando"
                  animated
                  now={100}
                  style={{ marginBottom: 10 }}
                />
              )}
              {Object.entries(client).length > 0 && (
                <DetailCard
                  title={'Información del cliente'}
                  details={[
                    {
                      label: 'Razón social',
                      value: client.socialName,
                    },
                    { label: 'Nombre comercial', value: client.companyName || '---' },
                    { label: 'Dirección', value: client.address },
                    {
                      label: 'NIT',
                      value: client.nit,
                    },
                    {
                      label: 'Correo',
                      value: client.email || '---',
                    },
                    {
                      label: 'Teléfono',
                      value: client.phone || '---',
                    },
                  ]}
                  onClick={() => {
                    dispatch(
                      setModalEditClient({
                        show: true,
                        id: client.id,
                        onSave: () => {
                          dispatch(getClientByCotizapClient(clientId, companyId))
                        },
                      }),
                    )
                  }}
                />
              )}
              <Col xl={12}>
                <Row style={{ marginTop: 20 }}>
                  <Col xl={6}>
                    <SwitchV2
                      label={'Cotizar fecha'}
                      checked={quote.active}
                      onChange={() => {
                        setQuote({ ...quote, active: !quote.active })
                        calculateTotal()
                      }}
                      info={''}
                    />
                  </Col>
                  {quote.active && (
                    <Col>
                      <CustomDate
                        disabledDays={[{ before: today }]}
                        value={quote.date}
                        onDayChange={(date: Date) => {
                          setQuote({ ...quote, date })
                          calculateTotal(date)
                        }}
                      />
                    </Col>
                  )}
                </Row>
                <span>
                  <b>Fecha de renovación: {formatDateFromMillis(endDate)}</b>
                </span>
              </Col>
              <Col xl={12}>
                <Row>
                  {plan && (
                    <Col>
                      {/*@ts-ignore*/}
                      <PlanItem item={plan} isActive lite />
                    </Col>
                  )}
                  <Col>
                    <TableV2
                      /* @ts-expect-error */
                      hideFilter
                      items={addonsToPay}
                      headers={[
                        {
                          label: 'Addon',
                          show: true,
                          value: ['item', 'name'],
                          type: 'text',
                          className: 'name',
                        },
                        {
                          label: 'Cantidad',
                          show: true,
                          value: ['quantity'],
                          type: 'wholeNumber',
                          className: 'name',
                        },
                        {
                          label: 'Monto total',
                          show: true,
                          value: [''],
                          type: 'text',
                          className: 'name',
                          custom: (item: MCompanyAddons) => item.quantity * item.price,
                        },
                        {
                          label: 'Días de uso',
                          show: true,
                          value: ['days'],
                          type: 'text',
                          className: 'name',
                        },
                        {
                          label: 'Monto real',
                          show: true,
                          value: ['toPay'],
                          type: 'text',
                          className: 'name',
                        },
                      ]}
                    />
                    <div>
                      <div className={'space-between'}>
                        <div className={'column'}>
                          {addonInvoices.length > 0 && (
                            <Paragraph bold>Facturas electrónicas</Paragraph>
                          )}

                          <Paragraph bold>Total</Paragraph>
                        </div>

                        <div className={'column'}>
                          {addonInvoices.length > 0 && (
                            <Paragraph bold>
                              {toMoney(
                                addonInvoices.reduce((acc, item) => acc + item.amount, 0),
                              )}
                            </Paragraph>
                          )}
                          <Paragraph bold>
                            {toMoney(
                              (addonInvoices.length > 0
                                ? addonInvoices.reduce(
                                    (acc, item) => acc + item.amount,
                                    0,
                                  )
                                : 0) + totalAddons,
                            )}
                          </Paragraph>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              disabled={quote.active}
              loading={loadingFinish}
              color={'accent'}
              onClick={() => onSave()}>
              {isRenewal ? 'Activar renovación' : isDemo ? 'Finalizar' : 'Confirmar'}
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  )
}
