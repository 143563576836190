export enum PurchaseStatusEnum {
  INGRESADA = 1,
  CPP = 2,
  COMPLETADA = 3,
  CANCELADA = 4,
  ANULADA = 6,
  PENDIENTE_INVENTARIAR = 7,
}

export const status = {
  [PurchaseStatusEnum.INGRESADA]: 'Ingresada',
  [PurchaseStatusEnum.CPP]: 'Cuenta por pagar',
  [PurchaseStatusEnum.COMPLETADA]: 'Completada',
  [PurchaseStatusEnum.CANCELADA]: 'Cancelada',
  [PurchaseStatusEnum.ANULADA]: 'Anulada',
  [PurchaseStatusEnum.PENDIENTE_INVENTARIAR]: 'Pendiente de inventariar',
}

export enum PurchasePayments {
  COMPLETE = 1,
  CREDIT = 2,
  EXPENSE = 3,
}

export const payments = {
  [PurchasePayments.COMPLETE]: { label: 'Contado', value: PurchasePayments.COMPLETE },
  [PurchasePayments.CREDIT]: { label: 'Crédito', value: PurchasePayments.CREDIT },
  [PurchasePayments.EXPENSE]: { label: 'Gasto', value: PurchasePayments.EXPENSE },
}

export default status
