import { $http } from './http'
import moment from 'moment'
import { saveAs } from 'file-saver'

class UtilitiesServices {
  getPhoneCompanies = async () => {
    const response = await $http.get('/phonecompanies')
    return response.data
  }

  getBusinessTypes = async () => {
    const response = await $http.get('/businesstypes')
    return response.data
  }

  getArchivedData = async (service, kolo) => {
    const response = await $http.get(`/${service}/archived`, {
      params: { kolo },
    })
    return response.data
  }

  changeArchivedStatus = async (service, ids) => {
    const response = await $http.put(`/${service}/archived`, ids)
    return response.data
  }

  changeDeletedStatus = async (service, id) => {
    const response = await $http.delete(`/${service}/archived/${id}`)
    return response.data
  }

  getPaymentMethods = async () => {
    const response = await $http.get('/method/payment')
    return response.data
  }

  getSingleReport = async (id, type) => {
    const response = await $http.get(`/cotizap/reports/patrimonial/${id}/${type}`)
    return response.data
  }

  createExcel = async (start, end) => {
    const response = await $http.get(
      `/cotizap/reports/patrimonial/excel?start=${start}&end=${end}`,
      { responseType: 'blob' },
    )
    const fileName = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : moment().format('LL') + ' - reporte - .xlsx'

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    saveAs(file, fileName.replace('json', 'xlsx'))
  }

  getInfoReference = async (documentModule, documentType, documentId) => {
    const response = await $http.get(
      `/utility/reference/${documentModule}/${documentType}/${documentId}`,
    )
    return response.data
  }

  getProductByComponent = async params => {
    const response = await $http.get('/inventory/by/component', { params })
    return response.data
  }

  /**
   * Retrieves a CSV template as a byte array based on the specified type.
   * @param {number} type The type of CSV template to retrieve.
   * @param {string} name The name of CSV template to retrieve.
   * **/
  getCSVTemplate = async (type, name) => {
    const response = await $http.get(`/utility/csv/${type}/template`, {
      responseType: 'blob',
    })
    const file = new Blob([response.data], { type: 'text/csv' })
    saveAs(file, name)
  }

  /**
   * Updates the status confirmation in progress for a transaction.
   *
   * @param documentId   The ID of the transaction.
   * @param documentType The type of the transaction.
   */
  updateStatusConfirmationInProgress = async (documentType, documentId) => {
    const response = await $http.post(
      '/utility/inProgress',
      {},
      { params: { documentType, documentId, flag: true } },
    )

    const success = response?.data

    if (!success) {
      alert(
        'Hubo un error en la comprobación del estado de la transacción. Por favor, inténtelo nuevamente.',
      )
      window.location.reload()
    }

    return success
  }
}

export default new UtilitiesServices()
