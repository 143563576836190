import './GeocodingPicker.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Card from 'src/components/cards/Card'
import Icon from 'src/components/buttons/IconButton'

import { MAPS_API_KEY } from 'src/settings/app.settings'

import GoogleMapReact from 'google-map-react'
import { InputGroup, FormControl } from 'react-bootstrap'
import {
  faMapMarked,
  faAddressCard,
  faSearchLocation,
} from '@fortawesome/free-solid-svg-icons'

import { getAddressComponent } from 'src/selectors/utilities.selector'
import CollapsibleCard from 'src/components/cards/collapsible-card/CollapsibleCard'

class GeocodingPicker extends Component {
  state = {
    manual: true,
    values: {
      address: '',
      latLng: '',
    },
    map: null,
    maps: null,
    geocoder: null,
    infoWindow: null,
    marker: null,
  }

  button = () => {
    const { manual } = this.state
    const { editable } = this.props
    return editable ? (
      <label className={'checkbox-label'}>
        {manual ? 'Posición' : 'Dirección'}{' '}
        <input
          type={'checkbox'}
          checked={manual}
          onChange={({ target }) => this.setState({ manual: target.checked })}
        />
      </label>
    ) : (
      <div />
    )
  }

  onChange = (value, parammeter) => {
    let { values } = this.state
    values[parammeter] = value

    this.setState({ values })
  }

  onGoogleApiLoaded = (map, maps) => {
    setTimeout(() => {
      const { lat, lng, auto, defaultAddress, editable } = this.props
      const geocoder = new maps.Geocoder()
      const infoWindow = new maps.InfoWindow()

      editable &&
        map.addListener('click', e => {
          const { marker } = this.state
          this.setState({
            values: {
              ...this.state.values,
              latLng: `${e.latLng.lat()},${e.latLng.lng()}`,
            },
          })
          marker.setMap(null)
          this.geocodeLatLng()
        })

      this.setState({ map, maps, geocoder, infoWindow })

      if (lat && lng) {
        if (auto) {
          this.setState({
            values: { ...this.state.values, latLng: `${lat},${lng}` },
          })
          this.geocodeLatLng()
        } else {
          const marker = new maps.Marker({
            position: { lat: parseFloat(lat), lng: parseFloat(lng) },
            map,
            animation: maps.Animation.DROP,
          })
          marker.setMap(map)
          if (defaultAddress) {
            infoWindow.setContent(defaultAddress)
            infoWindow.open(map, marker)
          }
          this.setState({
            marker,
            values: { address: defaultAddress || '', latLng: `${lat},${lng}` },
          })
        }
      }
    }, 2000)
  }

  action = () => {
    const { manual, marker } = this.state
    if (marker) {
      marker.setMap(null)
    }

    if (manual) {
      this.geocodeLatLng()
    } else {
      this.geocodeAddress()
    }
  }

  geocodeAddress = () => {
    this.setState({ manual: false })
    const { geocoder, maps, map, infoWindow, values } = this.state
    geocoder.geocode({ address: values.address }, (results, status) => {
      if (status === 'OK') {
        const center = results[0].geometry.location

        setTimeout(() => {
          map.panTo(center)
        }, 500)

        const marker = new maps.Marker({
          position: center,
          map,
          animation: maps.Animation.DROP,
        })

        infoWindow.setContent(results[0].formatted_address)
        infoWindow.open(map, marker)
        this.setState({ marker })
        this.props.getData({
          address: values.address,
          latLng: { lat: center.lat(), lng: center.lng() },
          town: this.props.getAddress(results[0], 'administrative_area_level_2', true),
        })
      } else alert('No tuvo éxito por la siguiente razón: ' + status)
    })
  }

  getAddressB = components => {
    const { getAddress } = this.props
    return `${getAddress(components, 'country')}${getAddress(
      components,
      'administrative_area_level_1',
    )}${getAddress(components, 'administrative_area_level_2')}${getAddress(
      components,
      'locality',
    )}${getAddress(components, 'sublocality')}${getAddress(
      components,
      'route',
    )}${getAddress(components, 'street_number')}`
  }

  geocodeLatLng = () => {
    this.setState({ manual: true })
    const { geocoder, maps, map, infoWindow, values } = this.state

    const latLngStr = values.latLng.split(',', 2)
    const latLng = {
      lat: parseFloat(latLngStr[0]),
      lng: parseFloat(latLngStr[1]),
    }
    if (geocoder)
      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            const components = results[0]
            const address = `${this.getAddressB(components)}`
            const marker = new maps.Marker({
              position: latLng,
              map,
              animation: maps.Animation.DROP,
            })

            setTimeout(() => {
              map.panTo(latLng)
            }, 500)

            infoWindow.setContent(address)

            infoWindow.open(map, marker)

            this.setState({
              marker,
              values: { address, latLng: values.latLng },
            })
            this.props.getData({
              address,
              latLng,
              town: this.props.getAddress(
                components,
                'administrative_area_level_2',
                true,
              ),
            })
          } else {
            console.log('not found')
          }
        } else {
          console.log(status)
        }
      })
  }

  panToPoint() {
    const { geocoder, maps, map, infoWindow, values } = this.state
    const { latLng } = this.props.latLng

    setTimeout(() => {
      map.panTo(latLng)
    }, 500)
  }

  render() {
    const { manual, values } = this.state
    const { lat, lng, editable } = this.props

    const { address, latLng } = values

    return (
      <CollapsibleCard
        className={'sub-style'}
        header={{ backgroundColor: '#1b4060' }}
        title={'Punto de Recolección'}
      >
        <div>
          {this.button()}
          <InputGroup>
            <InputGroup.Text>
              <Icon
                icon={manual ? faMapMarked : faAddressCard}
                tooltip={manual ? 'Por coordenadas' : 'Por dirección'}
              />
            </InputGroup.Text>
            <FormControl
              disabled={!editable}
              type={'text'}
              value={manual ? latLng : address}
              onChange={({ target }) =>
                this.onChange(target.value, manual ? 'latLng' : 'address')
              }
              placeholder={`pj. ${
                manual ? '14.55, -90.55' : 'Guatemala, Guatemala, Zona 1'
              }`}
            />

            {editable && (
              <InputGroup.Text>
                <Icon
                  icon={faSearchLocation}
                  tooltip={'Buscar'}
                  onClick={() => this.action()}
                />
              </InputGroup.Text>
            )}
          </InputGroup>
        </div>
        <div className={'geocoding-style'}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: MAPS_API_KEY }}
            defaultCenter={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
            defaultZoom={14}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => this.onGoogleApiLoaded(map, maps)}
          ></GoogleMapReact>
        </div>
      </CollapsibleCard>
    )
  }
}

const mapStateToProps = state => ({
  getAddress: (components, component, space) =>
    getAddressComponent(components.address_components, component, space),
})
const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(GeocodingPicker)
