import React, { Component } from 'react'
import Pay from 'src/assets/images/svg/pay.svg'
import DashboardCard from 'src/components/cards/dashboard-card/DashboardCard'
import { selectMenu } from 'src/actions/dashboard.actions'
import { connect } from 'react-redux'
import { selectSetCurrentCompany } from 'src/selectors/company.selector'
import { getPermission } from 'src/selectors/modules.selector'

class BalanceDashboard extends Component {
  componentDidMount() {
    this.props.selectMenu()
  }

  render() {
    const { currentCompany, permission } = this.props
    return (
      <div>
        <h1 className={'dashboard-welcome'}>Cuadres</h1>
        <div className={'admin-modules-list'}>
          <DashboardCard
            name={'Cuadres en ' + currentCompany.name}
            icon={Pay}
            link={'propio'}
          />
          <DashboardCard
            name={'Cuadres en Kolo'}
            icon={
              'https://firebasestorage.googleapis.com/v0/b/distribution-app-dev.appspot.com/o/assets%2FKOLO%20II-05.png?alt=media&token=c8b74cbd-e7e5-4ee0-93c1-88c29a865e58'
            }
            link={'kolo'}
          />
        </div>
        <br />
        {/*<Row>*/}
        {/*    <Col md={4}></Col>*/}
        {/*    <Col md={4}>{(permission(1000)|| permission(2000))?<div><DashboardCard name={'Balance de la empresa'} icon={Accounting} link={'general'}/></div>:''}</Col>*/}
        {/*    <Col md={4}></Col>*/}
        {/*</Row>*/}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentCompany: selectSetCurrentCompany(state),
  permission: id => getPermission(state, id),
})

const mapDispatchToProps = dispatch => ({
  selectMenu: () => dispatch(selectMenu(11000)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BalanceDashboard)
