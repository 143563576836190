import React from 'react'
import { useSelector } from 'react-redux'
import { history } from 'src/App'
import DemoFinished from 'src/content/Demo/DemoFinished'
import Login from './authentication/Login/Login'
import { loadingSelector } from 'src/selectors/loading.selector'
import { actionTypes as companyActions } from 'src/actions/company.actions'
import { AccountDeactivated } from 'src/components/toolbar/AccountDeactivated'

const defaulterRoutes = [
  '/planes',
  '/distribucion/planes',
  '/perfil',
  '/informacion',
  '/checkout',
]

type Props = {
  isSignedIn: boolean
  isDefaulter: boolean
  isTest: boolean
  isOwner: boolean
  children?: React.ReactNode
  isActive: boolean
}
const SecuredContent: React.FC<Props> = ({
  isSignedIn,
  isDefaulter,
  isTest,
  isOwner,
  children,
  isActive,
}) => {
  const loading = useSelector(state =>
    loadingSelector([companyActions.CURRENT_COMPANY])(state),
  )

  return isSignedIn ? (
    <div role="main" style={{ flex: 1 }}>
      {defaulterRoutes.every(route => !history.location.pathname.startsWith(route)) && (
        <AccountDeactivated inactive={!isActive} />
      )}
      {!loading &&
      isDefaulter &&
      isActive &&
      defaulterRoutes.every(route => !history.location.pathname.startsWith(route)) ? (
        <div className={'main-content demo'}>
          <DemoFinished isOwner={isOwner} isTest={isTest} />
        </div>
      ) : (
        children
      )}
    </div>
  ) : (
    <Login />
  )
}

export default SecuredContent
