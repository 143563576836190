import { actionTypes } from '../actions/transformation.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  transformations: [],
  productWIthDerivedProducts: {},
  inventoryList: [],
}

const TransformationReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_INVENTORY_HISTORY):
      return { ...state, inventoryList: extract(action.response, []) }
    case actionTypes.GET_DERIVED_PRODUCTS_SUCCESS:
      return {
        ...state,
        productWIthDerivedProducts: action.response,
      }
    case actionTypes.GET_TRANSFORMATIONS_SUCCESS:
      return {
        ...state,
        transformations: action.response,
      }
    default:
      return state ? state : initialState
  }
}

export default TransformationReducer
