import React from 'react'
import { useSelector } from 'react-redux'

import { Row } from 'react-bootstrap'
import { Button } from 'src/components'
import { faCheck, faReceipt, faWindowClose } from '@fortawesome/free-solid-svg-icons'

import { haveAnyValue } from 'src/utils/utilitiesV2'
import { orderActionTypeEnum } from 'src/components/custom/OrderDetail/OrderActionTypeEnum'
import { orderStatusEnum } from 'src/enums/order'
import { isAllowed } from 'src/selectors/modules.selector'

interface IOrderDetailButtonsProps {
  stateId: number
  depositId: number
  onClick: (actionType: number) => void
  loading: boolean
  actionType?: number
}

const OrderDetailButtons = ({
  stateId,
  depositId,
  onClick,
  loading,
  actionType,
}: IOrderDetailButtonsProps) => {
  const canConfirmOthers = useSelector(state => isAllowed(state, [12065]))
  const canAccess = useSelector(state => isAllowed(state, [12011]))

  const getListActions = () => {
    const actions = []

    if (haveAnyValue(depositId))
      actions.push({
        label: 'Ver deposito',
        color: 'accent',
        icon: faReceipt,
        type: orderActionTypeEnum.SEE_DEPOSIT,
      })

    if (stateId === orderStatusEnum.ENTERED) {
      if (canAccess)
        actions.push({
          label: 'Rechazar',
          color: 'secondary',
          icon: faWindowClose,
          type: orderActionTypeEnum.REJECT,
        })

      if (canConfirmOthers)
        actions.push({
          label: 'Confirmar',
          color: 'primary',
          icon: faCheck,
          type: orderActionTypeEnum.CONFIRM,
        })
    }

    return actions
  }

  return (
    <Row className={'container-buttons'}>
      {getListActions().map(action => (
        <Button
          key={action.type}
          color={action.color}
          icon={action.icon}
          onClick={() => onClick(action.type)}
          disabled={loading}
          loading={loading && action.type === actionType}>
          {action.label}
        </Button>
      ))}
    </Row>
  )
}
export default OrderDetailButtons
