import { Country } from '../enums/countries'

export const selectAlert = state => state.utilities.alert
export const selectPhonesCompanies = state =>
  state.utilities.phonesCompanies.map(p => ({
    ...p,
    value: p.id,
    label: p.name,
  }))
export const selectBusinessTypes = state =>
  state.utilities.businessTypes.map(p => ({
    ...p,
    value: p.id,
    label: p.name,
  }))

export const getAddressComponent = (addressComponents, component, space) => {
  let longName = ''
  if (component === 'administrative_area_level_2') {
    if (addressComponents.filter(a => a.types.indexOf(component) > -1).length <= 0) {
      component = 'locality'
    }
  }
  addressComponents
    .filter(a => a.types.indexOf(component) > -1)
    .map(a => (longName = `${a.long_name}${!space ? ' ' : ''}`))

  return longName
}

export const selectArchivedData = state => state.utilities.archived
export const selectMethodPayment = state =>
  state.utilities.methods.map(m => ({ ...m, value: m.id, label: m.name }))
export const selectReportLog = state => state.utilities.report

export const getCenter = paths => {
  const center = paths
    .map(p => {
      return [p.lat, p.lng]
    })
    .reduce(
      (x, y) => {
        return [x[0] + y[0] / paths.length, x[1] + y[1] / paths.length]
      },
      [0, 0],
    )
  return { latLng: { lat: center[0], lng: center[1] } }
}
export const selectReference = state => state.utilities.reference
export const selectInventoryComponent = state => state.utilities.inventory
export const selectInventorySelected = state => state.utilities.inventorySelected

export const valNit = nit => {
  if (nit === null || nit === undefined || nit === '') return false
  if (nit.toLowerCase() === 'cf') {
    return nit.toLowerCase() === 'cf'
  } else {
    let nd,
      add = 0
    // eslint-disable-next-line no-useless-escape
    if ((nd = /^(\d+)\-?([\dk])$/i.exec(nit))) {
      nd[2] = nd[2].toLowerCase() == 'k' ? 10 : parseInt(nd[2])
      for (var i = 0; i < nd[1].length; i++) {
        add += ((i - nd[1].length) * -1 + 1) * nd[1][i]
      }
      return (11 - (add % 11)) % 11 == nd[2]
    } else {
      return false
    }
  }
}
export const selectModalOrderId = state => state.utilities.modalOrderId
export const selectModalOrderCurrency = state => state.utilities.modalOrderCurrency
export const selectModalOrderPreSale = state => state.utilities.presale
export const selectModalOrder = state => state.utilities.modalOrder
export const selectModalConfirm = state => state.utilities.modalConfirm
export const selectModalOrderPayment = state => state.utilities.modalOrderPayment || {}
export const selectRefresh = state => state.utilities.refresh
export const selectHideNav = state => state.utilities.hide
export const trim = txt => {
  return txt.trim()
}
export const selectModalOrderProcessId = state => state.utilities.processId
export const selectModalOrderTransfer = state => state.utilities.transferData
export const selectModalOrderPayments = state => state.utilities.paymentsData
export const selectModalOrderSummary = state => state.utilities.summary
export const selectModalPurchaseId = state => state.utilities.modalPurchaseId
export const selectModalPurchaseOnClose = state => state.utilities.onClosePurchase
export const selectModalPurchase = state => state.utilities.modalPurchase
export const selectModalUsers = state => state.utilities.modalUsers
export const selectModalAddUser = state => state.utilities.modalAddUser
export const selectModalInvoicePackages = state => state.utilities.modalInvoicePackages
export const selectModalCreateCard = state => state.utilities.modalCreateCard
export const selectModalManageCards = state => state.utilities.modalManageCards
export const selectModalManageConcepts = state => state.utilities.modalManageConcepts
export const selectModalCreateConcepts = state => state.utilities.modalCreateConcepts
export const selectModalCreateClient = state => ({ ...state.utilities.modalCreateClient })
export const selectModalEditClient = state => ({ ...state.utilities.modalEditClient })
export const selectModalCreateCashTransfer = state =>
  state.utilities.modalCreateCashTransfer
export const selectModalPendingPayment = state => state.utilities.modalPendingPayment
export const selectNeedAddressComponents = state => {
  const { company } = state
  return [Country.GT, Country.SV].some(countryId => countryId === company?.country?.id)
}
