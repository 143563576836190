import React, {Component} from 'react';
import {connect} from "react-redux"

import {getSingleCompany} from "../../../../actions/company.actions";
import {selectGetSingleCompany} from "../../../../selectors/company.selector";

import {Row, Col, Modal} from "react-bootstrap";
import {Tr, Td} from "react-super-responsive-table";

import Card from "../../../../components/cards/Card";
import { TableV2 } from '../../../../components';

class DetailsProducer extends Component {

    componentDidMount() {
        const {match, getProducer} = this.props;
        getProducer(match.params.id);
    };

    render() {
        const {producer, offers} = this.props;
        return (
            <div>
                <Row>
                    <Col md={4}>
                        <Row>
                            <Col>
                                <Card ribbon={producer.hasContract ? 'Contratado' : 'sin contrato'}>
                                    <Row style={{justifyContent: 'center'}}>
                                        <span className={'client-name'}>{producer.name}</span>
                                    </Row>
                                    <Row className={'client-data'}>
                                        <Col xs={3} className={'label'}>Código</Col>
                                        <Col className={'description'}>{producer.alphanumericId}</Col>
                                    </Row>
                                    <Row className={'client-data'}>
                                        <Col xs={3} className={'label'}>NIT</Col>
                                        <Col className={'description'}>{producer.nit || 'C/F'}</Col>
                                    </Row>
                                    <hr/>
                                    <Row className={'client-data'}>
                                        <Col xs={3} className={'label'}>Email</Col>
                                        <Col className={'description'}>{producer.email || 'N/A'}</Col>
                                    </Row>
                                    <Row className={'client-data'}>
                                        <Col xs={3} className={'label'}>Dirección</Col>
                                        <Col className={'description'}>{producer.address}</Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Card title={'Ofertas de transporte'}>
                            {/* <Table/> */}
                            <TableV2 />
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    producer: selectGetSingleCompany(state),
    offers: []
});
const mapDispatchToProps = dispatch => ({
    getProducer: (id) => dispatch(getSingleCompany(id))
});
export default connect(mapStateToProps, mapDispatchToProps)(DetailsProducer);
