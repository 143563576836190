import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Modal } from 'react-bootstrap'

import {
  Card,
  Icon,
  Select,
  ProductItems,
  ProductItem,
  FormText,
  Button,
} from 'src/components'

import { setInfo } from 'src/actions/purchase.actions'
import { selectPurchase } from 'src/selectors/purchase.selector'

import { selectAllWarehousesForSelect } from 'src/selectors/warehouse.selector'
import {
  faArrowLeft,
  faPlus,
  faSave,
  faTrash,
  faWarehouse,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons'

const PurchaseProcess = props => {
  const dispatch = useDispatch()

  const purchase = useSelector(selectPurchase)
  const warehouses = useSelector(selectAllWarehousesForSelect)

  const [add, setAdd] = useState({ show: false, w: null })
  const [selected, setSelected] = useState({ show: false })
  const [sWarehouse, setWarehouse] = useState({ show: false })

  const wFilter = w => {
    const wl = purchase.warehouses.find(wl => wl.value === w.value)
    return !wl
  }

  const setCQuantity = item => {
    const warehouse = purchase.warehouses.find(w => w.value === item.value)
    let wP = warehouse.products.find(p => p.productId === selected.productId)
    if (!wP) wP = Object.assign({}, { quantity: 0 })

    setSelected({ ...selected, cQuantity: wP.quantity || 0, warehouse: item })
  }

  const getMax = (item, wValue) => {
    let response = item.quantity || 0
    purchase.warehouses
      .filter(w => w.value !== wValue)
      .forEach(w => {
        w.products
          .filter(p => p.productId === item?.productData?.id)
          .forEach(p => {
            response -= p.quantity
          })
      })
    return Number.parseInt(response)
  }

  return (
    <div>
      <Row>
        <Col xl={12} md={12}>
          <Card>
            <Row>
              <Col xl={12} md={12}>
                <h6>Bodegas</h6>
              </Col>

              {purchase.warehouses.map(w => (
                <Col
                  key={w.value}
                  className={'text-center col-restaurant'}
                  xs={6}
                  sm={6}
                  md={3}
                  lg={3}
                  xl={2}
                  style={{ border: '1px solid white' }}
                  onClick={() => setWarehouse({ ...w, show: true })}>
                  <div
                    style={{
                      width: 50,
                      height: 70,
                      position: 'relative',
                      display: 'inline-block',
                    }}>
                    <Icon
                      icon={faWarehouse}
                      className={'new-table'}
                      tooltip={'Ver contenido'}
                      color={'rgba(101,54,5,0.53)'}
                      size={'3x'}
                    />
                    <div className={'unread'}>
                      <h6>{w.products.length}</h6>
                    </div>
                  </div>
                  <div>
                    <b>{w.label}</b>
                  </div>
                </Col>
              ))}

              {purchase.products.filter(p => getMax(p, 0) > 0).length > 0 && (
                <Col
                  className={'text-center col-restaurant'}
                  xs={6}
                  sm={6}
                  md={3}
                  lg={3}
                  xl={2}
                  style={{ border: '1px solid white' }}
                  onClick={() => setAdd({ show: true })}>
                  <div
                    style={{
                      width: 50,
                      height: 70,
                      position: 'relative',
                      display: 'inline-block',
                    }}>
                    <Icon
                      icon={faPlus}
                      className={'new-table'}
                      tooltip={'agregar'}
                      size={'3x'}
                      color={'rgba(136,135,194,0.32)'}
                    />
                  </div>
                </Col>
              )}

              <Col xl={12} md={12}>
                <ProductItems
                  style={{ maxHeight: '400px' }}
                  hideFilter
                  noItems={'Todos los productos han sido asignados'}
                  customItems={purchase.products}
                  filter={item => {
                    return getMax(item, 0) > 0
                  }}
                  renderItem={(item, index) => (
                    <ProductItem
                      key={index}
                      setSelect={() => setSelected({ ...item, show: true })}
                      eventKey={index}
                      title={item?.productData?.name}
                      subTitle={item?.productData?.code}
                      titleSecond={`${getMax(item, 0)} u.`}
                    />
                  )}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      {/*Agregar bodega*/}
      <Modal show={add.show} size={'sm'} centered onHide={() => setAdd({ show: false })}>
        <Modal.Header cloaseButton>
          <Modal.Title>Agregar Bodega</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Select
            options={warehouses.filter(wFilter)}
            value={add.w}
            onChange={w => {
              setAdd({ show: false })
              const cWarehouse = Object.assign([], purchase.warehouses)
              cWarehouse.push({ ...w, products: [] })
              dispatch(setInfo({ ...purchase, warehouses: cWarehouse }))
            }}
          />
        </Modal.Body>
      </Modal>

      {/*Asignar producto a una bodega*/}
      <Modal
        show={selected.show}
        centered
        onHide={() => setSelected({ show: false })}
        size={'md'}>
        <Modal.Header closeButton>
          <Modal.Title>{selected?.productData?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={6} md={6} sm={12}>
              <Select
                options={purchase.warehouses}
                value={selected.warehouse}
                label={'Bodega'}
                onChange={w => {
                  setCQuantity(w)
                }}
              />
            </Col>
            <Col xl={6} md={6} sm={12}>
              <FormText
                disabled={!selected.warehouse}
                append={'u'}
                label={`Unidades disponibles: ${getMax(selected, 0)}`}
                value={selected.cQuantity}
                type={'number'}
                onChange={({ target }) => {
                  let { value } = target
                  if (!value || value < 0) value = 0

                  let max = getMax(selected, selected.warehouse.value)
                  value = Number.parseInt(value)

                  if (value > max) value = max
                  setSelected({ ...selected, cQuantity: value })
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              icon={faPlus}
              right
              color={'accent'}
              onClick={() => {
                //quantity
                const wI = purchase.warehouses.findIndex(
                  w => w.value === selected.warehouse.value,
                )
                const w = purchase.warehouses[wI]
                const pI = w.products.findIndex(p => p.productId === selected.productId)
                if (pI === -1)
                  w.products.push({
                    ...selected,
                    quantity: selected.cQuantity,
                    warehouse: null,
                  })
                else
                  w.products[pI] = Object.assign(
                    {},
                    {
                      ...selected,
                      quantity: selected.cQuantity,
                      warehouse: null,
                    },
                  )
                purchase.warehouses[wI] = w
                dispatch(setInfo({ ...purchase }))
                setSelected({ show: false })
              }}>
              Agregar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      {/*Visualizar bodega*/}
      <Modal
        show={sWarehouse.show}
        centered
        size={'md'}
        onHide={() => setWarehouse({ show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>{sWarehouse.label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProductItems
            style={{ maxHeight: '350px' }}
            hideFilter
            noItems={'Sin productos agregados'}
            customItems={sWarehouse.products || []}
            filter={item => {
              return item
            }}
            renderItem={(item, index) => (
              <ProductItem
                eventKey={index}
                title={item?.productData?.name}
                subTitle={item?.productData?.code}
                titleSecond={`${item.quantity} u.`}
                actions={
                  <Icon
                    color={'secondary'}
                    icon={faWindowClose}
                    tooltip={'Descartar'}
                    size={'2x'}
                    onClick={() => {
                      const p = sWarehouse.products.filter(
                        p => p.productId !== item?.productData?.id,
                      )
                      setWarehouse({ ...sWarehouse, products: p })

                      const wI = purchase.warehouses.findIndex(
                        w => w.value === sWarehouse.value,
                      )
                      purchase.warehouses[wI] = { ...sWarehouse, products: p }
                      dispatch(setInfo({ ...purchase }))
                    }}
                  />
                }
              />
            )}
          />
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              color={'secondary'}
              icon={faTrash}
              right
              onClick={() => {
                setWarehouse({ show: false })

                dispatch(
                  setInfo({
                    ...purchase,
                    warehouses: purchase.warehouses.filter(
                      w => w.value !== sWarehouse.value,
                    ),
                  }),
                )
              }}>
              Eliminar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal.Footer>
        <Row className={'container-buttons'}>
          <Button
            disabled={props.loading}
            icon={faArrowLeft}
            color={'accent'}
            left
            onClick={() => {
              props.jumpToStep(0)
            }}>
            Retroceder
          </Button>
          <Button
            disabled={purchase.products.filter(p => getMax(p, 0)).length > 0}
            icon={faSave}
            color={'primary'}
            right
            loading={props.loading}
            onClick={() => {
              const products = []
              purchase.warehouses.forEach(w => {
                let p = w.products.map(p => ({
                  productId: p.productId,
                  quantity: p.quantity,
                  warehouseId: w.value,
                  price: p.price,
                }))
                products.push(...p)
              })
              props.save(products)
            }}>
            Guardar
          </Button>
        </Row>
      </Modal.Footer>
    </div>
  )
}
export default PurchaseProcess
