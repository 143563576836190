import { countries, Country } from 'src/enums/countries'
import { identificationTypesEnum, identifications } from 'src/enums/identificationTypes'
import { valNit } from 'src/selectors/utilities.selector'
import { felDocumentType } from 'src/enums/felDocumentTypes'
import { number } from 'prop-types'

/** Return taxes by amount and country
 * @param {number} amount to get Tax
 * @param {number} taxRate To get % taxes
 * @return {number}
 * **/
const getTaxes = (amount: number, taxRate: number): number => {
  return amount * taxRate
}

/** Validate that the identifiers are valid
 * @param {IdentificationOption[]} identifications List of identities to valid
 * @return {boolean}
 * **/
const validateMultipleIdentifications = (
  identifications: IdentificationOption[],
): boolean => {
  if (identifications === undefined || identifications === null) return false

  for (const identification of identifications) {
    if (
      identification.identificationTypeId === identificationTypesEnum.NIT_GT &&
      haveAnyValue(identification.value) &&
      valNit(identification.value)
    )
      return true
    else if (
      haveAnyValue(identification.value) &&
      identification.identificationTypeId !== identificationTypesEnum.NIT_GT
    )
      return true
  }

  return false
}

/** Verify that the parameter sent has a value
 * @param {T} value Any type of value that will be validated
 * @return {boolean}
 * **/
const haveAnyValue = <T>(value: T): boolean => {
  return value !== null && value !== undefined && value !== ''
}

/**
 * Get the rounded integer value of a number.
 * @param {number} value - The number to be rounded.
 * @returns {number} The rounded integer value.
 */
const getRoundedInteger = (value: number): number => {
  if (!haveAnyValue(value) || isNaN(value)) return 0
  if (value >= 0) return Math.ceil(value)
  else return Math.floor(value)
}

/**
 * Find the larger value between two numbers.
 * @param {number} value - The first number.
 * @param {number} otherValue - The second number to compare with the first.
 * @returns {number} The larger of the two numbers. If both are equal, returns either.
 */
const findLargerValue = (value: number, otherValue: number): number => {
  if (!haveAnyValue(value) || isNaN(value)) value = 0
  if (!haveAnyValue(otherValue) || isNaN(otherValue)) otherValue = 0
  return Math.max(value, otherValue)
}

/**
 * Generate a list of formatted identification options based on provided IDs.
 * @param {number} country - Numeric code representing the country.
 * @param {string} nit - Tax Identification Number.
 * @param {string} nrc - Commercial Registry Number.
 * @param {string} cui - Unique Identity Code.
 * @param {string} passport - Passport number.
 * @returns {IdentificationOption[]} An array of identification options, each with type, value, and label. The array is formed based on the provided IDs and the country code.
 */
const getFormattedIds = (
  country: number,
  nit: string,
  nrc: string,
  cui: string,
  passport: string,
): IdentificationOption[] => {
  const isSv: boolean = country === Country.SV

  const ids: IdentificationOption[] = []
  if (haveAnyValue(passport))
    ids.push({
      identificationTypeId: identificationTypesEnum.ID_EXTRANJERO,
      value: passport,
      label: identifications[identificationTypesEnum.ID_EXTRANJERO],
    })
  else if (haveAnyValue(cui))
    ids.push({
      identificationTypeId: isSv
        ? identificationTypesEnum.DUI
        : identificationTypesEnum.CUI,
      value: cui,
      label:
        identifications[isSv ? identificationTypesEnum.DUI : identificationTypesEnum.CUI],
    })
  else if (haveAnyValue(nit)) {
    const identificationType =
      country === Country.UNI
        ? identificationTypesEnum.IDE
        : isSv
        ? identificationTypesEnum.NIT_SV
        : identificationTypesEnum.NIT_GT

    ids.push({
      identificationTypeId: identificationType,
      value: nit,
      label: identifications[identificationType],
    })
    if (isSv && haveAnyValue(nrc))
      ids.push({
        identificationTypeId: identificationTypesEnum.NRC,
        value: nrc,
        label: identifications[identificationTypesEnum.NRC],
      })
  }

  return ids
}

/**
 * Capitalizes the first letter of a given word.
 *
 * @param {string} word - The word to be capitalized.
 * @returns {string} The input word with the first letter capitalized.
 */
const capitalizeWorld = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

/**
 * Calculate the proportional value of a given value with respect to the total and subtotal.
 *
 * @param {number} total - The total value from which the proportion is to be calculated.
 * @param {number} subtotal - The subtotal value that is used to calculate the proportion.
 * @param {number} value - The value for which the proportion is to be calculated.
 * @returns {number} The proportional value of the given value with respect to the total and subtotal.
 */
const calculateProportionalValue = (
  total: number,
  subtotal: number,
  value: number,
): number => {
  if (!total || !subtotal || !value) return 0
  return (value / total) * subtotal
}

/**
 * Calculates and assigns quantities to line items based on a total quantity, considering presentation factors and whether to use decimals.
 *
 * The function iterates through each line item in the provided array, adjusting its 'currentQuantity' based on the 'presentationFactor'
 * and the remaining quantity to be assigned. If 'useDecimals' is true, quantities are assigned using decimal values. Otherwise,
 * quantities are assigned using integer division, distributing the total quantity among the line items according to their
 * presentation factors. Any line item without a presentation factor, or when the total quantity is exhausted, is assigned the
 * remaining quantity or zero, respectively.
 *
 * @param {number} quantity - The total quantity to be distributed among the line items.
 * @param {boolean} useDecimals - Determines whether to assign quantities with decimal values.
 * @param {ILineItem[]} line - An array of line items, each potentially having a 'presentationFactor' which influences the distribution.
 * @param withExistence
 * @returns {ILineItem[]} An array of line items with 'currentQuantity' assigned based on the provided total quantity and distribution logic.
 */
const renderLineQuantities = (
  quantity: number,
  useDecimals: boolean,
  line: ILineItem[],
  withExistence: boolean,
): ILineItem[] => {
  let current = quantity
  const stock = []

  for (const p of line || []) {
    if (current <= 0) {
      p.currentQuantity = 0
    } else if (p.presentationFactor == null) {
      p.currentQuantity = current
      current = 0
    } else if (useDecimals) {
      p.currentQuantity = current / p.presentationFactor
    } else if (current < p.presentationFactor) {
      p.currentQuantity = 0
    } else {
      p.currentQuantity = Math.floor(current / p.presentationFactor)
      current %= p.presentationFactor
    }
    stock.push(p)
  }
  return withExistence ? stock.filter(p => p.currentQuantity > 0) : stock
}

const splitStringByLength = (inputString: string, chunkSize: number): string[] => {
  const chunks: string[] = []
  for (let i = 0; i < inputString.length; i += chunkSize) {
    chunks.push(inputString.substring(i, i + chunkSize))
  }
  return chunks
}

/**
 * Get the number of days in a given month of a year.
 */
function getDaysOfMonth(year, month) {
  return new Date(year, month + 1, 0).getDate()
}

/**
 * Retrieves the amount to pay depending the renovation date
 * @param {Date} initialDate Initial date of plan
 * @param {Date} nextDate Final date or renovation date
 * @param {number} amount Amount to calculate
 * @param {number} periodicity  Periodicity of the plan
 * @returns
 */
const howMuchToPay = (
  initialDate: Date,
  nextDate: Date,
  amount: number,
  periodicity: number,
) => {
  const days =
    periodicity === 1
      ? getDaysOfMonth(initialDate.getFullYear(), initialDate.getMonth())
      : 365
  initialDate.setHours(0, 0, 0, 0)
  const daysToUse = Math.round(
    Math.floor(nextDate.getTime() - initialDate.getTime()) / (1000 * 60 * 60 * 24),
  )
  return {
    days: daysToUse,
    toPay: Number(((daysToUse * amount) / days).toFixed(2)),
  }
}

const getTaxByCountry = (country: number): number => {
  if (country === undefined) return 0
  const countryIndicated = countries[country]
  if (countryIndicated) return countryIndicated.iva
  else return 0
}

const getAmountWithTax = (amount: number, country: number): number => {
  if (!haveAnyValue(amount)) amount = 0
  const taxRate = getTaxByCountry(country)
  return amount * (1 + taxRate)
}

const getAmountWithoutTax = (amount: number, country: number): number => {
  if (!haveAnyValue(amount)) amount = 0
  const taxRate = getTaxByCountry(country)
  return amount / (1 + taxRate)
}

const getTaxDetailed = (amount: number, taxRate: number): number => {
  if (!haveAnyValue(amount)) amount = 0
  return amount - amount / (1 + taxRate)
}

const isNecessaryDetailTax = (
  isTaxesIncluded: boolean,
  taxDetailedConfig: boolean,
  countryId: number,
  felDocumentTypeId?: number,
): boolean => {
  if (!haveAnyValue(felDocumentTypeId)) return taxDetailedConfig
  if (countryId === Country.SV) {
    const maybeNeedDetailed = [felDocumentType.INVOICE, felDocumentType.CREDIT_FISCAL]
    if (maybeNeedDetailed.includes(felDocumentTypeId)) return !isTaxesIncluded
    else return false
  } else return taxDetailedConfig
}

const countDecimals = (value: number): number => {
  if (Math.floor(value) === value) return 2
  const result: number = value.toString().split('.')[1].length || 2

  return result <= 1 ? 2 : result
}

/**
 * Verify if the value is "true"
 * @param {string} value Value to validate
 * @returns {boolean} True if the value is 'true', otherwise false
 * */
const isTruth = (value: string): boolean =>
  haveAnyValue(value) ? value === 'true' : false

/**
 * Verify if the navigator is Safari
 * @returns {boolean} True if the navigator is Safari, otherwise false
 * */
const isSafariNavigator = (): boolean => {
  const userAgent = navigator?.userAgent
  const isSafari: boolean = haveAnyValue(userAgent)
    ? /^((?!chrome|android).)*safari/i.test(userAgent)
    : false

  const isSafariCached = localStorage.getItem('isSafari')

  return haveAnyValue(isSafariCached) ? isTruth(isSafariCached) : isSafari
}

/**
 * Verify if the value is "true"
 * @param {string} input Value to validate
 * @param {RegExp} pattern Pattern to validate
 * @returns {boolean} True if the value is 'true', otherwise false
 * */
const isValidString = (input: string, pattern: RegExp): boolean => {
  if (!haveAnyValue(input)) return false
  if (!haveAnyValue(pattern)) return false
  return pattern.test(input)
}

/**
 * Format a number to a string with a fixed length
 * @param {number} value Number to format
 * @param {number} repeat Length of the string
 * @returns {string} String formatted
 * */
const formattedNumberToString = (value: number, repeat: number): string => {
  if (!haveAnyValue(value)) return ''
  if (!haveAnyValue(repeat)) return value.toString()
  return value.toString().padStart(repeat, '0')
}

/**
 * Add or remove a string from a list
 * @param {string} listString List of strings
 * @param {string} newString String to add or remove
 * @returns {string} List of strings updated
 * */
const addOrRemoveStringToList = (listString: string, newString: string): string => {
  const list: string[] = haveAnyValue(listString) ? listString.split(',') : []
  if (haveAnyValue(newString)) {
    const index = list.indexOf(newString)
    if (index > -1) list.splice(index, 1)
    else list.push(newString)
  }

  return list.join(',')
}

export {
  getTaxes,
  validateMultipleIdentifications,
  haveAnyValue,
  getRoundedInteger,
  findLargerValue,
  getFormattedIds,
  capitalizeWorld,
  calculateProportionalValue,
  renderLineQuantities,
  splitStringByLength,
  howMuchToPay,
  getAmountWithTax,
  getAmountWithoutTax,
  isNecessaryDetailTax,
  getTaxDetailed,
  countDecimals,
  isSafariNavigator,
  isValidString,
  formattedNumberToString,
  addOrRemoveStringToList,
}
