import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal } from 'react-bootstrap'
import NumberField from 'src/components/NumberField/NumberField'
import Icon from 'src/components/buttons/IconButton'
import Card from 'src/components/cards/Card'
import { default as TableV2 } from 'src/components/Pagination/PaginatedTableV2'
import CSVLoaderV2, { CATEGORY_UPLOAD } from 'src/components/CSVLoader/CSVLoaderV2'
import { faEdit, faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  deleteProductPriceByPOS,
  getProductPricesByPOS,
  updateProductPriceByPOS,
} from 'src/actions/restaurant.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import { selectProductPrices } from 'src/selectors/restaurant.selector'
import { toMoney } from 'src/utils/utilities'

interface IProps {
  show: boolean
  posId: number
  onHide: () => void
}

export interface IProductPrice {
  productId: number
  price: number
  posId: number
  name: string
  code: string
  id: number

  edit?: boolean
}

/**
 * @component Modal to configura the specific prices by POS
 * @param {boolean} show - Show or hide the modal
 * @param {number} posId - The id of the POS
 * @param {function} onHide - Function to hide the modal
 * @returns
 */
export const POSProductPrices = ({ show, posId, onHide }: IProps) => {
  const dispatch = useDispatch()
  const items: IProductPrice[] = useSelector(selectProductPrices)
  const loading: boolean = useSelector(state =>
    loadingSelector([actionTypes.GET_PRODUCT_PRICES_BY_POS])(state),
  )
  const loadingUpdate: boolean = useSelector(state =>
    loadingSelector([
      actionTypes.UPDATE_PRODUCT_PRICE_BY_POS,
      actionTypes.DELETE_PRODUCT_PRICE_BY_POS,
    ])(state),
  )

  const [editPrice, setEditPrice] = useState({ edit: false, id: null })
  const [productPrices, setProductPrices] = useState([])
  const [action, setAction] = useState(false)

  useEffect(() => {
    if (!show || !posId) return
    dispatch(getProductPricesByPOS(posId))
  }, [show])

  useEffect(() => {
    if (items.length > 0) setProductPrices([...JSON.parse(JSON.stringify(items))])
  }, [items])

  useEffect(() => {
    if (loadingUpdate) setAction(true)
    else if (action) {
      setAction(false)
      setEditPrice({ edit: false, id: null })
      dispatch(getProductPricesByPOS(posId))
    }
  }, [loadingUpdate])

  const actions = item => {
    return (
      (!editPrice.edit || editPrice.id === item.id) && (
        <div className={'d-flex'}>
          {!editPrice.edit && (
            <Icon
              size={'lg'}
              dataCy={'edit-price'}
              icon={faEdit}
              style={{ marginRight: 5 }}
              onClick={() => {
                setEditPrice({ edit: true, id: item.id })
              }}
            />
          )}
          {editPrice.edit && (
            <>
              <Icon
                dataCy={'save-price'}
                icon={faSave}
                spin={loadingUpdate}
                size={'lg'}
                style={{ marginRight: 5 }}
                onClick={() => {
                  if (item.id > 0 && !item.price)
                    dispatch(deleteProductPriceByPOS(posId, item.id))
                  else dispatch(updateProductPriceByPOS(posId, [item]))
                }}
              />
              <Icon
                dataCy={'cancel-price'}
                icon={faWindowClose}
                size={'lg'}
                tooltip={'Cancelar'}
                style={{ marginRight: 5 }}
                onClick={() => {
                  setEditPrice({ edit: false, id: null })
                  item.price = items.find(i => i.id === item.id).price
                  setProductPrices([...productPrices])
                }}
              />
            </>
          )}
        </div>
      )
    )
  }

  const onClose = () => {
    setEditPrice({ edit: false, id: null })
    onHide()
  }

  return (
    <Modal show={show} centered size={'lg'} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Precios específicos del punto de venta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          {/* @ts-expect-error JS */}
          <CSVLoaderV2
            title={'Cargar precios'}
            warehouseId={posId}
            category={CATEGORY_UPLOAD.PRODUCT_PRICES_POS}
          />
        </Card>
        <TableV2
          /*@ts-expect-error JS */
          headers={[
            { label: 'SKU', show: true, value: ['code'], type: 'text' },
            { label: 'Ítem', show: true, value: ['name'], type: 'text' },
            {
              label: 'Precio',
              show: true,
              className: 'medium',
              value: ['price'],
              type: 'text',
              custom: (item: IProductPrice) =>
                editPrice.id === item.id ? (
                  <NumberField
                    value={item.price}
                    onValueChange={val => {
                      item.price = val
                      setProductPrices([...productPrices])
                    }}
                  />
                ) : item.price !== null ? (
                  toMoney(item.price)
                ) : (
                  '---'
                ),
            },
            {
              label: 'Acciones',
              className: 'mini',
              show: true,
              value: [''],
              config: true,
              type: 'text',
              custom: item => actions(item),
            },
          ]}
          loading={loading}
          items={productPrices}
          mobileAuto
          storageKey={'product-prices-pos'}
        />
      </Modal.Body>
    </Modal>
  )
}
