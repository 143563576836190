import { extract, successState } from '../actions/global.actions'
import { actionTypes } from '../actions/turn.actions'

const initialState = {
  turns: [],
  total: 0,
  turn: {},
  turns_detail: [],
  segmentation: [],
  segmentationDeleted: {},
  turnReport: {},
}

const TurnReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_TURNS):
      return {
        ...state,
        turns: extract(action.response.list, []),
        total: extract(action.response.total, 0),
      }
    case successState(actionTypes.GET_TURN):
      return { ...state, turn: extract(action.response, {}) }
    case successState(actionTypes.CREATE_TURN):
      return { ...state, turn: extract(action.response, {}) }
    case successState(actionTypes.CREATE_TURN_MANUALLY):
      return { ...state, turn: extract(action.response, {}) }
    case successState(actionTypes.GET_MULTIPLE_TURN_DETAIL):
      return { ...state, turns_detail: extract(action.response, {}) }
    case successState(actionTypes.FINISH_TURN):
      return { ...state, turn: {} }
    case successState(actionTypes.GET_TURN_SEGMENTATION):
      return { ...state, segmentation: extract(action.response, []) }
    case successState(actionTypes.GET_TURN_SEGMENTATION_ITEMS_DELETED):
      return { ...state, segmentationDeleted: extract(action.response, {}) }
    case successState(actionTypes.GET_TURN_REPORT):
      return { ...state, turnReport: extract(action.response, {}) }
    default:
      return state ? state : initialState
  }
}

export default TurnReducer
