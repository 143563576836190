// @ts-nocheck
import React, { useState, useEffect } from 'react'

import { Row, Col, Modal } from 'react-bootstrap'
import { FormText, Icon, Select, Button } from 'src/components'
import { faEdit, faSave } from '@fortawesome/free-solid-svg-icons'

interface Props {
  show: boolean
  initialLocations: string[]
  onChange: (value: string[]) => void
  onUpdateInit: () => void
}

const CompanyConfigLocation = ({
  show,
  initialLocations,
  onChange,
  onUpdateInit,
}: Props) => {
  const [activeLocations, setActiveLocations] = useState({
    show: false,
    changeLocations: false,
    locations: [],
  })
  const [locationQuantity, setLocationQuantity] = useState({
    value: -1,
    label: 'Sin ubicaciones',
  })
  const [locations, setLocations] = useState([])
  const defaultValues = ['SubBodegas', 'Estantes', 'Niveles']

  useEffect(() => {
    if (!show) return
    if (initialLocations.length > 0) {
      setLocations(initialLocations)
      setLocationQuantity({
        value: initialLocations.length,
        label: initialLocations.length.toString(),
      })
      onUpdateInit()
    }
  }, [show])

  return (
    <div>
      <Row>
        <Col xl={12}>
          <Select
            label={'Cantidad de ubicaciones'}
            info={'Mínimo 1 ubicación y 3 como máximo'}
            options={[
              { value: -1, label: 'Sin ubicaciones' },
              { value: 1, label: '1' },
              { value: 2, label: '2' },
              { value: 3, label: '3' },
            ]}
            value={locationQuantity}
            onChange={value => {
              setLocationQuantity(value)
              let temporalLocation = Object.assign([], locations)

              defaultValues.forEach((v, i) => {
                const item = temporalLocation[i]
                if (!item || item === '') temporalLocation[i] = v
              })
              if (value.value === -1) temporalLocation = []
              else if (value.value === 1) temporalLocation = temporalLocation.splice(0, 1)
              else if (value.value === 2) temporalLocation = temporalLocation.splice(0, 2)

              setLocations(temporalLocation)
              onChange(temporalLocation)
            }}
            subText={
              <div>
                <br />
                <p>
                  <Col>
                    <Row>
                      {locations.map((val, i) => (
                        <div key={i}>
                          <b>{val}</b> {i < locations.length - 1 && '→'}
                        </div>
                      ))}
                      &nbsp;&nbsp;
                      {locations.length > 0 && (
                        <Icon
                          onClick={() => {
                            setActiveLocations({
                              ...activeLocations,
                              changeLocations: true,
                              locations: Object.assign([], locations),
                            })
                          }}
                          tooltip={'Personalizar ubicaciones'}
                          icon={faEdit}
                        />
                      )}
                    </Row>
                  </Col>
                </p>
              </div>
            }
          />
        </Col>
      </Row>

      <Modal
        show={activeLocations.changeLocations}
        size={'lg'}
        centered
        onHide={() => {
          setActiveLocations({ show: false, changeLocations: false, locations: [] })
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Ubicaciones</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12} lg={12} md={12}>
              <FormText
                label={'Ubicación Principal'}
                value={activeLocations.locations[0]}
                type={'text'}
                info={'Ingrese el nombre de la primera ubicación'}
                changeValue={value => {
                  activeLocations.locations[0] = value
                  setActiveLocations({ ...activeLocations })
                }}
              />
            </Col>
            {(locationQuantity.value === 2 || locationQuantity.value === 3) && (
              <Col xl={12} lg={12} md={12}>
                <FormText
                  label={'Ubicación secundaria'}
                  value={activeLocations.locations[1]}
                  type={'text'}
                  info={'Ingrese el nombre de la primera ubicación'}
                  changeValue={value => {
                    activeLocations.locations[1] = value
                    setActiveLocations({ ...activeLocations })
                  }}
                />
              </Col>
            )}
            {locationQuantity.value === 3 && (
              <Col xl={12} lg={12} md={12}>
                <FormText
                  label={'Ubicación final'}
                  value={activeLocations.locations[2]}
                  type={'text'}
                  info={'Ingrese el nombre de la primera ubicación'}
                  changeValue={value => {
                    activeLocations.locations[2] = value
                    setActiveLocations({ ...activeLocations })
                  }}
                />
              </Col>
            )}
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              icon={faSave}
              onClick={() => {
                const customLocations = activeLocations.locations
                activeLocations.locations.forEach((x, i) => {
                  if (x === '') {
                    if (i === 0) customLocations[i] = 'SubBodegas'
                    else if (i === 1) customLocations[i] = 'Estantes'
                    else if (i === 2) customLocations[i] = 'Niveles'
                  }
                })
                setLocations([...customLocations])
                setActiveLocations({ show: false, changeLocations: false, locations: [] })

                onChange(customLocations)
              }}>
              Guardar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default CompanyConfigLocation
