import EnumerationsService from '../services/Enumerations.services'
import { executeAction } from './global.actions'

export const types = {
  ON_CREATE: 'ON_CREATE',
  ON_CREATE_REQUEST: 'ON_CREATE_REQUEST',
  ON_CREATE_SUCCESS: 'ON_CREATE_SUCCESS',
  ON_CREATE_ERROR: 'ON_CREATE_ERROR',

  ON_GET_ENUMERATIONS: 'ON_GET_ENUMERATIONS',

  GET_PRODUCT_ENUMERATION: 'GET_PRODUCT_ENUMERATION',
  GET_ENUMERATION_REPORT: 'GET_ENUMERATION_REPORT',
  ON_EDIT: 'ON_EDIT',
  ON_DELETE: 'ON_DELETE',
  ON_CREATE_OR_UPDATE: 'ON_CREATE_OR_UPDATE',
  ON_GET_ENUMERATION: 'ON_GET_ENUMERATION',

  UPLOAD_CSV: 'UPLOAD_CSV',
  UPLOAD_CSV_REQUEST: 'UPLOAD_CSV_REQUEST',
  UPLOAD_CSV_SUCCESS: 'UPLOAD_CSV_SUCCESS',
  UPLOAD_CSV_ERROR: 'UPLOAD_CSV_ERROR',
  GET_CUSTOM_RECIPE: 'GET_CUSTOM_RECIPE',
  ON_CREATE_CUSTOM_VARIABLE: 'ON_CREATE_CUSTOM_VARIABLE',
  GET_RECIPE_VARIABLE: 'GET_RECIPE_VARIABLE',
}

const onCreateRequest = () => ({ type: types.ON_CREATE_REQUEST })
const onCreateSuccess = response => ({
  type: types.ON_CREATE_SUCCESS,
  enumeration: response,
})
const onCreateError = error => ({ type: types.ON_CREATE_ERROR, error })

const uploadCSVRequest = () => ({ type: types.UPLOAD_CSV_REQUEST })
const uploadCSVSuccess = response => ({
  type: types.UPLOAD_CSV_SUCCESS,
  response,
})
const uploadCSVError = error => ({ type: types.UPLOAD_CSV_ERROR, error })

export const onGetEnumerations = isPublic => async dispatch => {
  const process = async () => ({
    enumerations: await EnumerationsService.onGetEnumerations(isPublic),
  })
  dispatch(executeAction(types.ON_GET_ENUMERATIONS, process))
}

export const onCreateEnumeration = request => async dispatch => {
  dispatch(onCreateRequest())
  try {
    const response = await EnumerationsService.onCreateEnumeration(request)
    dispatch(onCreateSuccess(response))
  } catch (e) {
    dispatch(onCreateError(e))
  }
}

export const onGetProductEnumeration = id => async dispatch => {
  const process = async () => await EnumerationsService.onGetProductEnumeration(id)
  dispatch(executeAction(types.GET_PRODUCT_ENUMERATION, process))
}

export const getEnumerationReport = id => async dispatch => {
  const process = async () => ({
    response: await EnumerationsService.getEnumerationReport(id),
  })
  dispatch(executeAction(types.GET_ENUMERATION_REPORT, process))
}

export const createEnumeration = request => async dispatch => {
  const process = async () => ({
    response: await EnumerationsService.onCreateEnumeration(request),
  })
  dispatch(executeAction(types.ON_CREATE_OR_UPDATE, process))
}

export const updateEnumeration = (id, request) => async dispatch => {
  const process = async () => ({
    response: await EnumerationsService.updateEnumeration(id, request),
  })
  dispatch(executeAction(types.ON_CREATE_OR_UPDATE, process))
}

export const deleteEnumeration = id => async dispatch => {
  const process = async () => ({
    response: await EnumerationsService.deleteEnumeration(id),
  })
  dispatch(executeAction(types.ON_DELETE, process))
}

export const onGetEnumeration = id => async dispatch => {
  const process = async () => ({
    response: await EnumerationsService.onGetEnumeration(id),
  })
  dispatch(executeAction(types.ON_GET_ENUMERATION, process))
}

export const loadRecipesCSV = (file, global, onSuccess) => async dispatch => {
  dispatch(uploadCSVRequest())
  try {
    const response = await EnumerationsService.loadRecipesCSV(file, global)
    dispatch(uploadCSVSuccess(response))
    onSuccess(response, false)
  } catch (e) {
    console.log(e.toString())
    onSuccess(e, true)
    dispatch(uploadCSVError(e))
  }
}

export const getVariablesRecipe = () => dispatch => {
  const process = async () => ({
    customVariables: await EnumerationsService.getVariablesRecipe(),
  })
  dispatch(executeAction(types.GET_CUSTOM_RECIPE, process))
}

export const createVariableRecipe = request => dispatch => {
  const process = async () => await EnumerationsService.createVariableRecipe(request)
  dispatch(executeAction(types.ON_CREATE_CUSTOM_VARIABLE, process))
}

export const getVariableRecipe = id => dispatch => {
  const process = async () => ({
    customVariable: await EnumerationsService.getVariableRecipe(id),
  })
  dispatch(executeAction(types.GET_RECIPE_VARIABLE, process))
}

export const updateVariableRecipe = (id, request, params) => dispatch => {
  const process = async () =>
    await EnumerationsService.updateVariableRecipe(id, request, params)
  dispatch(executeAction(types.ON_CREATE_CUSTOM_VARIABLE, process))
}
