import React, {Component} from 'react';
import {connect} from "react-redux";

import Alert from "sweetalert-react";
import {Row, Col, Modal, ButtonGroup, DropdownButton, Dropdown} from "react-bootstrap";
import {
    faCheckCircle,
    faTimesCircle
} from "@fortawesome/free-solid-svg-icons";

import {formatNumberWithCommas, formatDateFromMillis} from "../../../../utils/formatters";

import Card from "../../../../components/cards/Card";
import CustomFilter from "../../../../components/cards/FilterCard";
import OrderSummary from "../../../../components/Summaries/OrderSummary";
import IconButton from "../../../../components/buttons/IconButton";
import Select from "../../../../components/inputs/Select/CustomSelect";

import {actionTypes as typesO, getOrderByClient, onConfirmOrder} from "../../../../actions/orders.actions";
import {selectOrderByClient} from "../../../../selectors/orders.selector";

import {getWarehouses} from "../../../../actions/warehouse.actions";
import {selectAllWarehousesForSelect} from "../../../../selectors/warehouse.selector";

import {loadingSelector} from "../../../../selectors/loading.selector";
import {hasErrorsSelector, singleErrorSelector, handlerError, handlerSuccess} from "../../../../selectors/error.selector";
import { toMoney } from '../../../../utils/utilities';
import { selectCurrentCurrency } from '../../../../selectors/currencies.selector';
import { TableV2 } from '../../../../components';

class OrdersByClientDetail extends Component {

    state = {
        alert: {title: 'default'},
        modal: {show: false, item: null},
        filters: {},
        confirm: {value: false, order: {}, warehouse: {value: 0, label: '- ninguno -'}},
        principal: true
    };

    componentDidMount() {
        const {getOrderByClient, match, getWarehouses} = this.props;
        getOrderByClient(match.params.id)
        getWarehouses();
    };

    componentWillReceiveProps(next) {
        const {loadingO, hasErrorO, handlerError, handlerSuccess} = this.props;
        const {errorO} = next;
        let alert = {title: 'default'};

        if (loadingO && !next.loadingO) {
            if (!hasErrorO && next.hasErrorO) {
                alert = handlerError(errorO.message || 'Ha ocurrido un error inesperado');
                alert.onConfirm = () => this.setState({alert: {...alert, show: false}});
            }
            this.setState({alert});
        }

        const {loadingC, hasErrorC, match} = this.props;
        const {errorC} = next;
        if (loadingC && !next.loadingC) {
            let {alert, confirm} = this.state;
            if (!hasErrorC && next.hasErrorC) {
                alert = handlerError(errorC.message || 'Ha ocurrido un error inesperado');
                alert.onConfirm = () => this.setState({alert: {...alert, show: false}});
            } else {
                alert = handlerSuccess(`La orden: ${confirm.order.number} ha sido confirmada satisfactoriamente`);
                alert.onConfirm = () => this.setState({alert: {...alert, show: false}});
                getOrderByClient(match.params.id)
                this.setState({confirm: {value: false, order: {}, warehouse: {value: 0, label: '- ninguno -'}}});
            }
            this.setState({alert});
        }
    };

    customFilter = (data) => {
        let filters = this.state.filters;
        if (JSON.stringify(filters) != JSON.stringify(data)) {
            this.setState({filters: data});
        }
    };

    filter = (item) => {
        const {filters} = this.state;
        const {entered, confirmed, delivered, unfulfilled, pending, processed, onWay} = filters;

        return ((entered && item.status === 1) || (confirmed && item.status === 2) || (delivered && item.status === 3) ||
            (unfulfilled && item.status === 4) || (pending && item.status === 5) || (processed && item.status === 7) ||
            (onWay && item.status === 8));
    };

    goToResume = (order) => {
        let item = Object.assign({}, order);
        item.details = item.details.map((p) => ({
            companyName: p.product.companyData.name,
            name: p.product.name,
            quantity: p.quantity,
            discount: p.discount,
            subTotal: p.subtotal,
            total: p.total
        }));

        item.clientName = item.client.companyName
        item.typeName = item.orderTypeData.name;
        item.statusName = item.statusData.name;

        this.setState({modal: {show: true, item}});
    };

    goToConfirm = (item) => {
        this.setState({confirm: {value: true, order: item, warehouse: {value: 0, label: '- ninguno -'}}});
    };

    validate = () => {
        const {confirm, principal} = this.state;
        const {order, warehouse} = confirm;
        this.props.onConfirmOrder(order.orderId, warehouse.value, principal);
    };
    getHeaders = () => {
        return [
            {label: 'Código', show: true, value: ['number'], type: 'text' ,  className:'mini'},
            {label: 'Transporte', show: true, value: ['company','name'], type: 'text' ,  className:'short'},
            {label: 'Fecha de entrega', show: true, value: ['enabledAt'], type: 'date' ,  className:'mini'},
            {label: 'Estado', show: true, value: ['statusData','name'], type: 'text' ,  className:'mini'},
            {label: 'Total', show: true, value: ['total'], type: 'text' ,  className:'medium'},
            {config:true, show: true, label:'', className:'mini center',
                custom: (item) => <ButtonGroup>
                    <DropdownButton drop={'left'} as={ButtonGroup} title={''} id={'bg-nested-dropdown'}>
                        <Dropdown.Item onClick={() => this.goToResume(item)} eventKey={'1'}>Resumen</Dropdown.Item>
                        {item.status === 3 &&
                            <Dropdown.Item onClick={() => this.goToConfirm(item)} eventKey={'2'}>Confirmar de recibido</Dropdown.Item>
                        }
                    </DropdownButton>
                </ButtonGroup>
            }

        ]
    }
    render() {

        const {alert, modal, confirm, principal} = this.state;
        const {request, loadingO, warehouses, currentCurrency} = this.props;
        const {orders} = request;
        const {show, item} = modal;
        const {value, order, warehouse} = confirm;

        return (
            <div>
                <Row>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div className={'column'}>
                            <Card title={'detalle'}>
                                <Row>
                                    <Col><b>Número de orden</b></Col>
                                    <Col>{request.number || 'Desconocido'}</Col>
                                </Row>
                                <Row>
                                    <Col><b>Fecha de creación</b></Col>
                                    <Col>{request.createdAt && formatDateFromMillis(request.createdAt)}</Col>
                                </Row>
                                <Row>
                                    <Col><b>Fecha de entrega</b></Col>
                                    <Col>{request.date ? formatDateFromMillis(request.date) : 'Pendiente'}</Col>
                                </Row>
                                <Row>
                                    <Col><b>Descuento</b></Col>
                                    <Col>{toMoney(request.discount)}</Col>
                                </Row>
                                <Row>
                                    <Col><b>Total</b></Col>
                                    <Col>{toMoney(request.total)}</Col>
                                </Row>
                            </Card>
                            <CustomFilter
                                title={'Filtros'}
                                module={'client'}
                                submodule={'orders'}
                                customFilter={this.customFilter}
                            />
                        </div>
                    </Col>
                    <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                        <Card title={'Ordenes de compra'}>
                            <TableV2
                                items={orders ? orders.filter(this.filter) : []}
                                loading={loadingO}
                                headers = {this.getHeaders()}
                                mobileAuto
                                storageKey={`orderbyclientdetail`}
                            />
                        </Card>
                    </Col>
                </Row>

                <Modal
                    show={show}
                    size={'lg'}
                    centered
                    onHide={() => this.setState({modal: {show: false, item: null}})}

          >          <Modal.Header>
                        <Modal.Title>Detalle de Orden</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <OrderSummary order={item}/>
                    </Modal.Body>

                    <Modal.Footer>

                        <Row className={'container-buttons'}>
                            <IconButton style={{marginRight: '15px'}} tooltip={'Cerrar'} icon={faTimesCircle} size={'2x'}
                                        onClick={() => this.setState({modal: {show: false, item: null}})}/>
                        </Row>
                    </Modal.Footer>
                </Modal>

        <Modal
          show={value}
          size={'md'}
          centered
          onHide={() =>
            this.setState({
              principal: true,
              confirm: {
                value: false,
                order: {},
                warehouse: { value: 0, label: '- ninguno -' },
              },
            })
          }
        >
          <Modal.Header>
            <Modal.Title>Confirmar orden: {order.number}</Modal.Title>
          </Modal.Header>

                    <Modal.Body>
                        {
                            principal ?
                                <div className={'column'}>
                                    <span>Confirme que ya ha recibido su producto,
                                        Desea que el producto se guarde en la bodega principal?</span>
                                    <br/>
                                    <Row>
                                        <Col>
                                            <div className={'big-button'} onClick={() => {this.setState({confirm: {...confirm, warehouse: 1}}); this.validate()}}>Sí</div>
                                        </Col>
                                        <Col>
                                            <div className={'big-button'} style={{backgroundColor: 'rgba(157,13,12,0.5)'}} onClick={() =>this.setState({principal: false})}>Elegir otra</div>
                                        </Col>
                                    </Row>
                                </div>
                                :
                                <div className={'column'}>
                                    <span>Seleccione otra bodega</span>
                                    <br/>
                                    <Select
                                        value={warehouse}
                                        options={[{value: 0, label: '- ninguno -'}, ...warehouses]}
                                        label={'Bodega'}
                                        onChange={(data) => this.setState({confirm: {...confirm, warehouse: data}})}
                                    />
                                </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            !principal &&
                                <Row className={'container-buttons'}>
                                    <IconButton style={{marginRight: '15px'}} tooltip={'Confirmar'} icon={faCheckCircle} size={'2x'}
                                                onClick={() => this.validate()}/>
                                </Row>
                        }
                    </Modal.Footer>
                </Modal>

                <Alert {...alert}/>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    request: selectOrderByClient(state),
    warehouses: selectAllWarehousesForSelect(state),
    loadingO: loadingSelector([typesO.ORDER_BY_CLIENT])(state),
    hasErrorO: hasErrorsSelector([typesO.ORDER_BY_CLIENT])(state),
    errorO: singleErrorSelector([typesO.ORDER_BY_CLIENT])(state),
    loadingC: loadingSelector([typesO.CONFIRM_ORDER])(state),
    hasErrorC: hasErrorsSelector([typesO.CONFIRM_ORDER])(state),
    errorC: singleErrorSelector([typesO.CONFIRM_ORDER])(state),
    handlerError: (message) => handlerError(message),
    handlerSuccess: (message) => handlerSuccess(message),
    currentCurrency: selectCurrentCurrency(state)
});

const mapDispatchToProps = dispatch => ({
    getOrderByClient: (id) => dispatch(getOrderByClient(id)),
    getWarehouses: () => dispatch(getWarehouses()),
    onConfirmOrder: (orderId, warehouseId, principal) => dispatch(onConfirmOrder(orderId, warehouseId, principal))
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersByClientDetail);
