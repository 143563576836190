export enum accountModuleEnum {
  Todos = 8000,
  Transportista = 1000,
  Productor = 2000,
}

export const editPermissions = [6151, 6157, 6201, 6207, 8003, 11750, 11754]
export const createPermissions = [6151, 6157, 6201, 6207, 8003, 11750, 11754]
export const operationPermissions = [6151, 6153, 6201, 6203, 8003, 11750, 11755]

export enum bankAccountPermissionsEnum {
  CreateAccount = 6161,
  EditAccount = 6167,
  DeleteAccount = 6162,
  ExportAccountMovementsCSV = 6163,
  CreateAccountMovementOperation,
  ImportAccountMovementsCSV = 6165,
  DeleteMovementTransaction = 6166,
  RequestMovementsTransactions = 6168,
  DuplicateOperations,
}
// export { accountModuleEnum, editPermissions, createPermissions, operationPermissions }
export {}

export const defaultBank: IBank = {
  countryId: 0,
  deleted: false,
  description: undefined,
  id: 37,
  label: '',
  name: '',
  value: 0,
}
export const defaultCurrency: Currency = {
  code: '',
  label: '',
  name: '',
  pluralName: '',
  symbolSpace: '',
  value: 0,
  id: 0,
  symbol: '',
}
export const defaultBankAccount: IBankAccount = {
  accountNumber: undefined,
  active: false,
  asigned: undefined,
  balance: 0.0,
  bank: defaultBank,
  company: 0,
  createdAt: undefined,
  createdBy: undefined,
  createdData: undefined,
  currency: defaultCurrency,
  deleted: false,
  deletedAt: undefined,
  deletedBy: 0,
  deletedData: undefined,
  disbursement: undefined,
  editable: undefined,
  id: undefined,
  kolo: false,
  module: 0,
  name: '',
  report: false,
  toName: '',
  transformation: undefined,
  type: undefined,
  updatedAt: undefined,
  updatedBy: undefined,
  updatedData: undefined,
}
export enum TableElementEnum {
  authorized = 'authorized',
  pending = 'pending',
  deposit = 'deposit',
}

export const accountModules = [
  { value: 8000, label: 'Todos' },
  { value: 1000, label: 'Transportista' },
  { value: 2000, label: 'Productor' },
]
export const types = [
  { id: 1, label: 'Monetaria' },
  { id: 2, label: 'Ahorro' },
]
export const bankEmpty: IBank = {
  countryId: 0,
  deleted: false,
  description: undefined,
  id: 0,
  label: '',
  name: '',
  value: 0,
}
export const typeEmpty: Type = { id: 0, label: '' }
export const currencyEmpty: Currency = {
  code: '',
  name: '',
  pluralName: '',
  symbol: '',
  symbolSpace: '',
  value: 0,
  id: 0,
  label: '',
}
export const defaultBankAccountForForm: IBankAccount = {
  accountNumber: 0,
  active: true,
  asigned: undefined,
  balance: 0,
  bank: bankEmpty,
  company: 0,
  createdAt: undefined,
  createdBy: undefined,
  createdData: undefined,
  currency: currencyEmpty,
  deleted: false,
  deletedAt: undefined,
  deletedBy: 0,
  deletedData: undefined,
  disbursement: undefined,
  editable: undefined,
  id: undefined,
  kolo: false,
  module: 0,
  name: '',
  report: false,
  toName: '',
  transformation: undefined,
  type: typeEmpty,
  updatedAt: undefined,
  updatedBy: undefined,
  updatedData: undefined,
}
export const operations = [
  { id: 100, label: 'Monto inicial' },
  { id: 101, label: 'Acreditacion' },
  { id: 102, label: 'Retiro' },
]

export const options = [
  { value: 101, label: 'Acreditar a la cuenta' },
  { value: 102, label: 'Retirar de la cuenta' },
  { value: 104, label: 'Transferir a efectivo circulante' },
  { value: 103, label: 'Transferencia entre cuentas' },
]
