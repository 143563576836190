import React, { Component, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectBalanceDocuments } from 'src/selectors/balance.selector'
import {
  selectorGetAdminModules,
  selectorToogleAdminModule,
} from 'src/selectors/modules.selector'
import Card from 'src/components/cards/Card'
import { getModulesAdmin, toogleModuleAdmin } from 'src/actions/modules.actions'
import Switch from 'src/components/inputs/Switch/Switch'
import { stringify } from 'query-string'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import LoadingIcon from 'src/assets/images/svg/loading.svg'
import { Title } from 'src/components'

const Modules = () => {
  const dispatch = useDispatch()
  const [distribution, setDistribution] = useState([])
  const [producer, setProducer] = useState([])
  const [company, setCompany] = useState([])
  const [client, setClient] = useState([])

  const documents = useSelector(selectorGetAdminModules)
  const edited = useSelector(selectorToogleAdminModule)

  useEffect(() => {
    dispatch(getModulesAdmin())
  }, [])

  useEffect(() => {
    if (!edited.id) return
    dispatch(getModulesAdmin())
  }, [edited])

  useEffect(() => {
    //console.log(documents);
    let dist = []
    let prod = []
    let comp = []
    let cli = []
    for (let i = 0; i < documents.length; i++) {
      let item = documents[i]
      if (item.parentModuleId) {
        if (item.parentModuleId === 1000) {
          dist.push(item)
        }
        if (item.parentModuleId === 2000) prod.push(item)
        if (item.parentModuleId === 3000) cli.push(item)
        if (item.parentModuleId === 8000) comp.push(item)
      }
    }
    setDistribution([...dist])
    setProducer([...prod])
    setCompany([...comp])
    setClient([...cli])
  }, [documents])

  let toogleModule = id => {
    dispatch(toogleModuleAdmin(id))
  }

  let renderList = list => {
    return list.map(item => {
      return (
        <Col>
          <div>
            <Row>
              <Col>
                <img
                  src={item.icon || LoadingIcon}
                  alt={item.name || 'cargando...'}
                  className={'module-icon'}
                  style={{ minHeight: '70px', maxHeight: '70px' }}
                />
              </Col>
            </Row>
            <br />
            <Row className={'text-center'}>
              <Switch
                style={{ marginLeft: '27%' }}
                value={item.showIcon}
                checked={item.showIcon}
                onChange={() => {
                  console.log('toogle', item.id)
                  toogleModule(item.id)
                }}
              />
            </Row>
            <Row style={{ marginBottom: '10px' }}>
              <Col className={'text-center'}>
                <span style={{ marginRight: 10 }}>{item.name}</span>
              </Col>
            </Row>
          </div>
        </Col>
      )
    })
  }

  return (
    <div>
      <Title title={'Administrador de Módulos'} />
      <div>
        <Row>
          <Col md={4}>
            <Card title={'Transportista'}>
              <Row>{distribution.length > 0 ? renderList(distribution) : ''}</Row>
            </Card>
          </Col>
          <Col md={4}>
            <Card title={'Productor'}>
              <Row>{producer.length > 0 ? renderList(producer) : ''}</Row>
            </Card>
          </Col>
          <Col md={4}>
            <Card title={'Cliente'}>
              <Row>{client.length > 0 ? renderList(client) : ''}</Row>
            </Card>
          </Col>
          <Col md={4}>
            <Card title={'Empresa'}>
              <Row>{company.length > 0 ? renderList(company) : ''}</Row>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Modules
