import { $http } from './http'
import { saveAs } from 'file-saver'
import moment from 'moment'

import UtilitiesServices from './utilities.services'

import { itemTypes } from 'src/utils/utilities'

class DepositsService {
  getDeposits = async () => {
    const response = await $http.get(`/deposit`)
    return response.data
  }

  getDepositsByBank = async bankId => {
    const response = await $http.get(`/deposit/bank/${bankId}`)
    return response.data
  }

  getDepositsAdmin = async () => {
    const response = await $http.get(`/deposit/admin`)
    return response.data
  }

  createDepositsRequest = async d => {
    const response = await $http.post(`/deposit`, d)
    return response.data
  }

  exportExcel = async params => {
    const response = await $http.get('/deposit/company/excel', {
      params,
      responseType: 'blob',
    })

    const fileName = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : moment().format('LL') + ' - Depósitos - .xlsx'

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    saveAs(file, fileName.replace('json', 'xlsx'))
  }

  getDepositsCompany = async params => {
    const response = await $http.get(`/deposit/company`, { params })
    return response.data
  }

  updateDepositStatus = async (deposit, status, params) => {
    await UtilitiesServices.updateStatusConfirmationInProgress(itemTypes.DEPOSIT, deposit)
    const response = await $http.post(`/deposit/admin/${deposit}`, status, {
      params,
    })
    return response.data
  }

  getAssociatedOrders = async deposit => {
    const response = await $http.get(`/deposit/associated/${deposit}`)
    return response.data
  }

  getCSVMovements = async params => {
    const response = await $http.get(`/deposit/invoices/csv`, { params })
    let text = response.data
      .split('\n')
      .map(line => line.split(/\s+/).join(' '))
      .join('\n')

    let csvContent = 'data:text/csv;charset=utf-8,'
    csvContent += text
    saveAs(csvContent, 'depositos.csv')
    return response.data
  }

  getReceipt = async receipt => {
    const response = await $http.get(`/deposit/${receipt}`)
    return response.data
  }

  getReference = async (reference, params) => {
    const response = await $http.get(`/deposit/reference/${reference}`, { params })
    return response.data
  }

  updateDeposit = async (id, request) => {
    const response = await $http.put(`/deposit/` + id, request)
    return response.data
  }

  getDeposit = async id => {
    const response = await $http.get(`/deposit/data/${id}`)
    return response.data
  }
}

export default new DepositsService()
