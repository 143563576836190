import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import { Button, FormText, NumberField, Select, SwitchV2 } from 'src/components'
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'

import { haveAnyValue } from 'src/utils/utilitiesV2'
import { loadingSelector } from 'src/selectors/loading.selector'

import { actions } from 'src/actions/taxes.actions'
import { selectAllTaxes } from 'src/selectors/taxes.selector'

interface ITaxFormProps {
  show: boolean
  tax: Tax
  onClose: () => void
  onResponse: (tax: Tax) => void
}

const getPriorities = (size: number): ISelectNumber[] => {
  const priorities: ISelectNumber[] = []
  if (size > 0) {
    for (let i = 1; i <= size; i++) {
      priorities.push({ value: i, label: i.toString() })
    }
  } else priorities.push({ value: 1, label: '1' })
  return priorities
}

const TaxForm = ({ show, tax, onClose, onResponse }: ITaxFormProps) => {
  const loading: boolean = useSelector(state =>
    loadingSelector([actions.UPDATE_TAX_OR_CREATE_TAX])(state),
  )

  const taxes: Tax[] = useSelector(selectAllTaxes)
  const priorities: ISelectNumber[] = getPriorities(taxes.length)

  const [data, setData] = useState<Tax>({})
  const [errors, setErrors] = useState<TaxError>({})

  useEffect(() => {
    if (!show) {
      onReset()
      return
    }
    setData({ ...tax, rate: tax.rate ? tax.rate * 100 : 0 })
  }, [show])

  const onReset = () => {
    setData({})
    setErrors({})
  }

  const onHide = () => {
    if (loading) return
    onReset()
    onClose()
  }

  const onValidate = () => {
    const errors: TaxError = {}

    if (!haveAnyValue(data.name)) errors.name = 'El nombre es requerido'
    if (!haveAnyValue(data.rate)) errors.rate = 'El valor es requerido'

    if (Object.keys(errors).length > 0) {
      setErrors(errors)
      return
    }

    onResponse(data)
  }

  const getPriority = (): ISelectNumber => {
    const priority: ISelectNumber = priorities.find(
      priority => priority.value === data.indexOrder,
    )
    return priority
      ? priority
      : { value: tax?.indexOrder, label: tax?.indexOrder?.toString() }
  }

  return (
    <div>
      <Modal show={show} centered size={'lg'} onHide={onHide}>
        <Modal.Header closeButton={!loading}>
          <Modal.Title>
            {tax.id ? `Impuesto: ${tax.name}` : 'Creación de impuesto'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={6} lg={6} md={12} sm={12}>
              <FormText
                required
                label={'Nombre'}
                info={'Nombre del impuesto que se mostrará en la factura'}
                value={data.name}
                changeValue={(value: string) => setData({ ...data, name: value })}
                error={errors.name}
                disabled={loading}
                max={20}
              />
            </Col>
            <Col xl={6} lg={6} md={12} sm={12}>
              <FormText
                label={'Nombre gravado'}
                info={'Nombre del monto gravado que se mostrará en la factura'}
                value={data.subName}
                changeValue={(value: string) => setData({ ...data, subName: value })}
                disabled={loading}
                max={25}
              />
            </Col>
            <Col xl={6} lg={6} md={12} sm={12}>
              <NumberField
                containerStyle={{ marginTop: '20px' }}
                required
                label={'Valor %'}
                value={data.rate}
                onValueChange={(value: number) => setData({ ...data, rate: value })}
                decimals={2}
                max={100}
                min={0}
                error={errors.rate}
                disabled={loading}
              />
            </Col>
            <Col xl={6} lg={6} md={12} sm={12}>
              <Select
                label={'Orden de prioridad'}
                info={
                  'La prioridad define el orden en que se aplicarán los impuestos en las transacciones de la aplicación'
                }
                options={priorities}
                value={getPriority()}
                onChange={({ value }) => setData({ ...data, indexOrder: value })}
              />
            </Col>

            <Col xl={12}>
              <Row>
                {haveAnyValue(tax.id) && (
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <SwitchV2
                      label={'Estado'}
                      checked={data.active}
                      onChange={(value: boolean) => setData({ ...data, active: value })}
                      info={
                        'Si se desactiva este impuesto no se tomará en cuenta en las transacciones de la aplicación'
                      }
                      disabled={loading}
                    />
                  </Col>
                )}
                <Col xl={6} lg={6} md={12} sm={12}>
                  <SwitchV2
                    label={'Activar como impuesto principal'}
                    checked={data.defaultValue}
                    onChange={(value: boolean) =>
                      setData({ ...data, defaultValue: value })
                    }
                    info={
                      'Si se selecciona este impuesto como principal se tomará como el impuesto por defecto en las transacciones de la aplicación para los ítems que no tengan asignado un impuesto'
                    }
                    disabled={loading}
                  />
                </Col>
                <Col xl={6} lg={6} md={12} sm={12}>
                  <SwitchV2
                    label={'Mostrar siempre el impuesto'}
                    checked={data.alwaysShowInDetail}
                    onChange={(value: boolean) =>
                      setData({ ...data, alwaysShowInDetail: value })
                    }
                    info={
                      'Activa para mostrar el impuesto en los PDF descargables de cotización y factura, incluso si no tiene valor.'
                    }
                    disabled={loading}
                  />
                </Col>
                <Col xl={6} lg={6} md={12} sm={12}>
                  <SwitchV2
                    label={'Mostrar siempre el monto gravado'}
                    checked={data.alwaysShowGravableInDetail}
                    onChange={(value: boolean) =>
                      setData({ ...data, alwaysShowGravableInDetail: value })
                    }
                    info={
                      'Activa para mostrar el monto grabado del impuesto en los PDF descargables de cotización y factura, incluso si no tiene valor.'
                    }
                    disabled={loading}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              color={'secondary'}
              icon={faWindowClose}
              onClick={onHide}
              disabled={loading}>
              Cerrar
            </Button>
            <Button color={'primary'} onClick={onValidate} loading={loading}>
              Guardar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default TaxForm
