import LogsServices from '../services/logs.service'
import CompaniesServices from '../services/companies.services'

export const actionTypes = {
  GET_LOG_PRODUCTS: 'GET_LOG_PRODUCTS',
  GET_LOG_PRODUCTS_REQUEST: 'GET_LOG_PRODUCTS_REQUEST',
  GET_LOG_PRODUCTS_SUCCESS: 'GET_LOG_PRODUCTS_SUCCESS',
  GET_LOG_PRODUCTS_ERROR: 'GET_LOG_PRODUCTS_ERROR',

  GET_LOG_COMPANIES: 'GET_LOG_COMPANIES',
  GET_LOG_COMPANIES_REQUEST: 'GET_LOG_COMPANIES_REQUEST',
  GET_LOG_COMPANIES_SUCCESS: 'GET_LOG_COMPANIES_SUCCESS',
  GET_LOG_COMPANIES_ERROR: 'GET_LOG_COMPANIES_ERROR',

  CURRENT_COMPANY: 'CURRENT_COMPANY',
  CURRENT_COMPANY_REQUEST: 'CURRENT_COMPANY_REQUEST',
  CURRENT_COMPANY_SUCCESS: 'CURRENT_COMPANY_SUCCESS',
  CURRENT_COMPANY_ERROR: 'CURRENT_COMPANY_ERROR',

  PDF_GENERATED_TRUE: 'PDF_GENERATED_TRUE',
  PDF_GENERATED_FALSE: 'PDF_GENERATED_FALSE',

  GET_LOG_WAREHOUSES: 'GET_LOG_WAREHOUSES',
  GET_LOG_WAREHOUSES_REQUEST: 'GET_LOG_WAREHOUSES_REQUEST',
  GET_LOG_WAREHOUSES_SUCCESS: 'GET_LOG_WAREHOUSES_SUCCESS',
  GET_LOG_WAREHOUSES_ERROR: 'GET_LOG_WAREHOUSES_ERROR',

  GET_LOG_SHOPPING: 'GET_LOG_SHOPPING',
  GET_LOG_SHOPPING_REQUEST: 'GET_LOG_SHOPPING_REQUEST',
  GET_LOG_SHOPPING_SUCCESS: 'GET_LOG_SHOPPING_SUCCESS',
  GET_LOG_SHOPPING_ERROR: 'GET_LOG_SHOPPING_ERROR',
}

const getLogProductsRequest = () => ({
  type: actionTypes.GET_LOG_PRODUCTS_REQUEST,
})
const getLogProductsSuccess = logs => ({
  type: actionTypes.GET_LOG_PRODUCTS_SUCCESS,
  logs,
})
const getLogProductsError = () => ({
  type: actionTypes.GET_LOG_PRODUCTS_ERROR,
})

const getLogCompaniesRequest = () => ({
  type: actionTypes.GET_LOG_COMPANIES_REQUEST,
})
const getLogCompaniesSuccess = logs => ({
  type: actionTypes.GET_LOG_COMPANIES_SUCCESS,
  logs,
})
const getLogCompaniesError = () => ({
  type: actionTypes.GET_LOG_COMPANIES_ERROR,
})

const getCurrentCompanyRequest = () => ({
  type: actionTypes.CURRENT_COMPANY_REQUEST,
})
const getCurrentCompanySuccess = logs => ({
  type: actionTypes.CURRENT_COMPANY_SUCCESS,
  logs,
})
const getCurrentCompanyError = () => ({
  type: actionTypes.CURRENT_COMPANY_ERROR,
})

const generatePdfTrue = () => ({ type: actionTypes.PDF_GENERATED_TRUE })
const generatePdfFalse = () => ({ type: actionTypes.PDF_GENERATED_FALSE })

const getLogWarehousesRequest = () => ({
  type: actionTypes.GET_LOG_WAREHOUSES_REQUEST,
})
const getLogWarehousesSuccess = logs => ({
  type: actionTypes.GET_LOG_WAREHOUSES_SUCCESS,
  logs,
})
const getLogWarehousesError = () => ({
  type: actionTypes.GET_LOG_WAREHOUSES_ERROR,
})

const getLogShoppingRequest = () => ({
  type: actionTypes.GET_LOG_SHOPPING_REQUEST,
})
const getLogShoppingSuccess = logs => ({
  type: actionTypes.GET_LOG_SHOPPING_SUCCESS,
  logs,
})
const getLogShoppingError = () => ({
  type: actionTypes.GET_LOG_SHOPPING_ERROR,
})

export const getLogProducts = () => async dispatch => {
  dispatch(getLogProductsRequest())
  try {
    const logs = await LogsServices.getLogProducts()
    dispatch(getLogProductsSuccess(logs))
  } catch (error) {
    dispatch(getLogProductsError(error))
  }
}

export const getLogCompanies = () => async dispatch => {
  dispatch(getLogCompaniesRequest())
  try {
    const logs = await LogsServices.getLogCompanies()
    dispatch(getLogCompaniesSuccess(logs))
  } catch (error) {
    dispatch(getLogCompaniesError(error))
  }
}

export const getCurrentCompany = () => async dispatch => {
  dispatch(getCurrentCompanyRequest())
  try {
    const response = await CompaniesServices.getCurrentCompany()
    dispatch(getCurrentCompanySuccess(response))
  } catch (error) {
    dispatch(getCurrentCompanyError(error))
  }
}

export const setGeneratePdfTrue = () => async dispatch => {
  dispatch(generatePdfTrue())
}

export const setGeneratePdfFalse = () => async dispatch => {
  dispatch(generatePdfFalse())
}

export const getLogWarehouses = () => async dispatch => {
  dispatch(getLogWarehousesRequest())
  try {
    const logs = await LogsServices.getLogWarehouses()
    dispatch(getLogWarehousesSuccess(logs))
  } catch (error) {
    dispatch(getLogWarehousesError(error))
  }
}

export const getLogShopping = () => async dispatch => {
  dispatch(getLogShoppingRequest())
  try {
    const logs = await LogsServices.getLogShopping()
    dispatch(getLogShoppingSuccess(logs))
  } catch (error) {
    console.log(error)
    dispatch(getLogShoppingError(error))
  }
}
