import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Row } from 'react-bootstrap'
import { CustomCreate, Icon } from 'src/components'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'

import { actionTypes, deleteContact } from 'src/actions/clients.actions'
import { loadingSelector } from 'src/selectors/loading.selector'

const ClientContacts = ({
  show,
  options,
  id,
  disabled,
  disabledSelect,
  onChange,
  optionMode,
  value,
}) => {
  const dispatch = useDispatch()

  const [list, setList] = useState([])

  const loadingDelete = useSelector(state =>
    loadingSelector([actionTypes.DELETE_CONTACT])(state),
  )

  const loadingGet = useSelector(state =>
    loadingSelector([actionTypes.GET_CONTACTS])(state),
  )
  const contacts = useSelector(state => state.clients.contacts)

  const [flags, setFlags] = useState({ del: false, get: false })
  const [itemIndex, setIndex] = useState(null)

  useEffect(() => {
    if (!show) return
    if (options !== null && options !== undefined) setList(options)
  }, [show])

  useEffect(() => {
    if (loadingDelete) setFlags({ ...flags, del: true })
    else if (flags.del) {
      setFlags({ ...flags, del: false })
      const newList = [...list.filter((s, index) => index !== itemIndex)]
      setList([...newList])
      onChange(optionMode ? newList.pop() : newList)
      setIndex(null)
    }
  }, [loadingDelete])

  useEffect(() => {
    if (loadingGet) setFlags({ ...flags, get: true })
    else if (flags.get) {
      setFlags({ ...flags, get: false })
      setList(contacts)
    }
  }, [loadingGet])

  return (
    <div>
      {!disabled ? (
        <CustomCreate
          label={'Añadir contactos'}
          onChange={value => {
            if (optionMode) onChange(value)
          }}
          disabled={disabledSelect || (optionMode ? !id : false)}
          isLoading={loadingGet}
          onCreateOption={value => {
            const newList = [...list, { name: value }]
            onChange(optionMode ? newList.pop() : newList)
            if (!optionMode) setList([...newList])
          }}
          options={
            optionMode ? [...list.map((l, i) => ({ ...l, label: l.name, value: i }))] : []
          }
          value={value}
          placeholder={'Escribe el nombre del contacto'}
          textLabel={'Nuevo contacto: '}
        />
      ) : (
        <label className={'ftf-form-label left mt-3'}>Contactos</label>
      )}

      {!optionMode && (
        <Row className={'pl-1'} style={{ width: '100%' }}>
          {list.map((l, i) => (
            <div className={'user-tag  ml-2 mt-2'} key={i}>
              <label className={'label-user-tag'}>{l.name}</label>
              {!disabled && (
                <Icon
                  className={'delete-user-tag'}
                  icon={faTimes}
                  tooltip={'Quitar'}
                  color={'white'}
                  spin={loadingDelete && i === itemIndex}
                  onClick={() => {
                    if (l.id && id) {
                      setIndex(i)
                      dispatch(deleteContact(l.id))
                    } else {
                      const newList = [...list.filter((s, index) => index !== i)]
                      setList([...newList])
                      onChange(optionMode ? newList.pop() : newList)
                    }
                  }}
                />
              )}
            </div>
          ))}
        </Row>
      )}
    </div>
  )
}
export default ClientContacts
