export const graphs = [
  '#c04b8e',
  '#10a8ba',
  '#ffa600',
  '#80B300',
  '#6b5b95',
  '#00b400',
  '#1ac4be',
  '#ff5122',
  '#5d6167',
  '#6c9cfa',
  '#ffd15f',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#3a49b9',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#c778b7',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
]

export const quantityOptions = [
  { value: 10, label: '10 Productos' },
  { value: 20, label: '20 Productos' },
  { value: 30, label: '30 Productos' },
  { value: 40, label: '40 Productos' },
  { value: 50, label: '50 Productos' },
]

export const quantityOptionsUsers = [
  { value: 5, label: '5 Usuarios' },
  { value: 10, label: '10 Usuarios' },
  { value: 15, label: '15 Usuarios' },
  { value: 20, label: '20 Usuarios' },
  { value: 25, label: '25 Usuarios' },
]

export const quantityOptionsClients = [
  { value: 10, label: '10 Clientes' },
  { value: 20, label: '20 Clientes' },
  { value: 30, label: '30 Clientes' },
  { value: 40, label: '40 Clientes' },
  { value: 50, label: '50 Clientes' },
]

export const sortOptions = [
  { value: 1, label: 'Menor a mayor' },
  { value: 2, label: 'Mayor a menor' },
]
