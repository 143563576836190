interface PlanType {
  value: number
  label: string
}

export enum PlanEnum {
  FEL = 8,
  POS = 9,
  PRO = 11,
  ERP = 10,
  FREE = 18,
}

export enum AddonPlanEnum {
  USER = 12,
  POS = 13,
  WAREHOUSE = 14,
  ITEM = 15,
  COMMERCIAL_ESTABLISHMENT = 16,
  INVOICES = 17,
}

export const principalPlans: PlanType[] = [
  { value: PlanEnum.FEL, label: 'FEL' },
  { value: PlanEnum.POS, label: 'POS' },
  { value: PlanEnum.PRO, label: 'PRO' },
  { value: PlanEnum.ERP, label: 'ERP' },
  { value: PlanEnum.FREE, label: 'GRATUITO' },
]
