import './Route.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { sortableContainer, sortableElement } from 'react-sortable-hoc'
import GoogleMapReact from 'google-map-react'
import { geolocated } from 'react-geolocated'
import { MAPS_API_KEY } from 'src/settings/app.settings'
import { BulletList } from 'react-content-loader'
import { Row, Col, Modal, Container } from 'react-bootstrap'
import moment from 'moment'
import Alert from 'sweetalert-react'

import Card from 'src/components/cards/Card'
import PictureUser from 'src/assets/images/svg/client.svg'
import Switch from 'src/components/inputs/Switch/Switch'
import Select from 'src/components/inputs/Select/CustomSelect'
import Icon from 'src/components/buttons/IconButton'

import {
  actionTypes as typesOder,
  actionTypes as orderTypes,
  actionTypes,
  confirmSingleOrder,
  confirmVisitNoSale,
  deleteSingleOrder,
  getAllOrders,
  getExcelByOrders,
  getOrdersLinked,
  getProductExistenceByOrder,
  getSingleOrder,
  printOrder,
} from 'src/actions/orders.actions'

import {
  selectGetAllOrders,
  selectGetInvoiceByOrder,
  selectGetSingleOrder,
  selectProductExistence,
} from 'src/selectors/orders.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  hasErrorsSelector,
  singleErrorSelector,
  handlerError,
} from 'src/selectors/error.selector'

import { getDiffBetweenPoints } from 'src/selectors/geolocation.selecteor'

import { selectCurrentUser } from 'src/selectors/user.selector'
import { formatDistance, arrayMove , toMoney} from 'src/utils/utilities'

import { actionTypes as types, getAllDeliverers } from 'src/actions/clients.actions'
import { selectDeliverersToSelect } from 'src/selectors/clients.selector'

import { getPermission } from 'src/selectors/modules.selector'
import IconButton from 'src/components/buttons/IconButton'
import { faWaze } from '@fortawesome/free-brands-svg-icons'

import {formatDateFromMillis, formatNumberWithCommas} from "src/utils/formatters";
import {
  faCheckCircle,
  faClipboardCheck,
  faDownload,
  faSync,
  faTimesCircle,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import Button from 'src/components/buttons/Button'
import { Td, Tr } from 'react-super-responsive-table'
import { Decimal } from 'decimal.js'
import SweetAlert from 'sweetalert-react'
import { getWarehouses } from 'src/actions/warehouse.actions'
import { selectAllWarehousesForSelect } from 'src/selectors/warehouse.selector'
import {
  downloadPrintableInvoice,
  downloadPrintableInvoiceByOrder,
  sendInvoiceEmail,
} from 'src/actions/resolutions.actions'
import { TableV2,Title } from 'src/components'

const lat = 14.589465440976774
const lng = -90.51898866891861

const SortableItem = sortableElement(item => {
  const { value, sortIndex, onClick } = item
  return (
    <Card style={{ margin: 10 }}>
      <Row>
        <Col sm={2} md={2}>
          <img
            className={'order-list-avatar'}
            src={value.client.picture || PictureUser}
            alt={value.client.name}
          />
        </Col>
        <Col sm={9} md={9}>
          <Row>
            <Col sm={4} md={4}>
              <span>{value.client.name}</span>
              <br />
              {value.client.contactName && `${value.client.contactName}`}
            </Col>
            <Col sm={6} md={6}>
              <small>{value.client.address}</small>
              <br />
              <small>Aprox. a: {formatDistance(value.distance)}</small>
            </Col>
            <Col sm={2} md={2}>
              <a
                href={`https://www.waze.com/ul?ll=${value.client.latitude}%2C${value.client.longitude}&navigate=yes`}
                target={'_none'}>
                <div>
                  <IconButton
                    tooltip={'Ir con waze'}
                    size={'2x'}
                    icon={faWaze}
                    style={{ color: '#25a5c185' }}
                  />
                </div>
              </a>
            </Col>
          </Row>
        </Col>
        <Col sm={1} md={1}>
          <div className={'order-list-action'}>
            <button onClick={() => onClick(value)} style={{ verticalAlign: 'middle' }}>
              <span>{'>'}</span>
            </button>
          </div>
        </Col>
      </Row>
    </Card>
  )
})

const SortableContainer = sortableContainer(({ children }) => {
  return <div className={'orders-container'}>{children}</div>
})

class Route extends Component {
  state = {
    toggle: false,
    orders: [],
    directionsService: {},
    directionsDisplay: null,
    deliverer: { value: null, label: '- todos -' },
    alert: { show: false, title: 'Default', text: 'default', type: 'info' },
    selectedWarehouse: {},
    showInventory: false,
    showDetail: false,
    details: [],
    showEmail: false,
    showDelete: false,
  }

  componentDidMount() {
    this.props.getAllDeliverers()
    this.props.getWarehouses()
  }

  UNSAFE_componentWillReceiveProps(next) {
    const {
      loadingOrders,
      hasErrorOrders,
      coords,
      user,
      loadingDeliverers,
      orderIsLoading,
      loadingExistence,
      confirmIsLoading,
      confirmHasError,
      deleteHasError,
      deleteIsLoading,
      loadingNoSaleVisit,
      noSaleVisitHasError,
      getPermission,
    } = this.props
    const { orders, order, existence, confirmError, deleteError } = next
    let { details, alert } = this.state

    if (loadingDeliverers && !next.loadingDeliverers) {
      this.init()
    }

    if (confirmIsLoading && !next.confirmIsLoading) {
      if (!confirmHasError && next.confirmHasError) {
        alert = this.handlerError(
          confirmError.response
            ? confirmError.response.data.message
            : 'Ha ocurrido un error inesperado.',
        )
      } else {
        alert = this.handlerSuccess(
          `La orden de ${
            order.orderTypeData && order.orderTypeData.name
          } fue confirmada satisfactoriamente.`,
        )
        this.setState({ showDetail: false })
        this.setState({ showInvoice: true })
      }
      this.setState({ alert })
    }

    if (loadingNoSaleVisit && !next.loadingNoSaleVisit) {
      if (!noSaleVisitHasError && next.noSaleVisitHasError) {
        alert = this.handlerError(
          confirmError.response
            ? confirmError.response.data.message
            : 'Ha ocurrido un error inesperado.',
        )
      } else {
        alert = this.handlerSuccess(
          `La orden de ${
            order.orderTypeData && order.orderTypeData.name
          } fue confirmada satisfactoriamente.`,
        )
        this.setUp()
      }
      this.setState({ alert })
    }

    if (deleteIsLoading && !next.deleteIsLoading) {
      if (!deleteHasError && next.deleteHasError) {
        alert = this.handlerError(
          deleteError.response
            ? deleteError.response.data.message
            : 'Ha ocurrido un error inesperado.',
        )
      } else {
        alert = this.handlerSuccess(
          `La orden de ${
            order.orderTypeData && order.orderTypeData.name
          } fue eliminada satisfactoriamente.`,
        )
        this.props.getSingleOrder(this.props.order.orderId)
      }
      this.setState({ alert })
    }

    if (loadingOrders && !next.loadingOrders) {
      if (!hasErrorOrders && next.hasErrorOrders) {
        // TODO: hay errores
      } else {
        if (!coords) return
        if (this.props.deliverers) {
          if (getPermission(1001)) {
            this.setNewOrders(orders)
          } else {
            let deliverer = this.props.deliverers.find(x => x.value === user.id)
            if (deliverer) {
              this.setState({ deliverer: deliverer })
              this.setNewOrders(this.props.orders, deliverer)
            } else this.setNewOrders(orders)
          }
        }
      }
    }

    if (orderIsLoading && !next.orderIsLoading) {
      console.log(order)
      this.setState({ showDetail: true, details: order.details })
    }

    if (loadingExistence && !next.loadingExistence) {
      details.map(d => {
        existence.map(e => {
          if (e.product === d.product.id) {
            d.existence = e.existence >= e.quantity ? true : false
          }
        })
      })
      this.setState({ details, showInventory: true }, () => console.log(details))
    }
  }

  setNewOrders = (orders, deliverer) => {
    const { getDistance, coords } = this.props
    if (!deliverer) deliverer = { value: null, label: '- todos -' }
    let newOrders = orders
      .map(o => {
        o.distance = getDistance(
          o.client.latitude,
          o.client.longitude,
          coords.latitude,
          coords.longitude,
        )
        o.client = o.client
        return o
      })
      .sort((a, b) => {
        return a.distance - b.distance
      })
    if (!deliverer.value) this.setUp(newOrders, true)
    else {
      newOrders = newOrders.filter(o => o.deliveredBy === deliverer.value)
      this.setUp(newOrders, true)
    }
  }

  filter = item => {
    const { getPermission, user } = this.props
    let bool = false

    if (
      item.client &&
      parseFloat(item.client.latitude) !== 0 &&
      parseFloat(item.client.longitude) !== 0
    ) {
      if (item.status != 2 || item.status != 3 || item.status != 4 || item.status != 6) {
        if (getPermission(1000) || getPermission(1352) || getPermission(15090)) {
          bool = true
        } else if (getPermission(1003) /* && item.client.deliverer === user.id*/) {
          bool = true
        }
      }
    }
    return bool
  }

  init = () => {
    const { getOrders, getLinked } = this.props
    const linked = this.props.location.pathname
    const from = moment().hour(0).minute(0).seconds(0).valueOf()
    const to = moment().hour(23).minute(59).seconds(59).valueOf()
    if (linked.includes('/kolo')) getLinked(from, to)
    else getOrders(from, to, 0, 0)
  }

  setUp = (array, optimizeWaypoints) => {
    const { coords } = this.props
    const { directionsDisplay, directionsService } = this.state
    let orders = Object.assign([], array)
    let recervedOrders = []
    if (!coords) return
    if (
      orders.filter(o => !o.deleted && (o.status !== 3 || o.status !== 7)).length === 0
    ) {
      this.setState({ orders: [] })
      return
    }
    if (orders.length > 23) {
      orders = orders.slice(0, 23)
      recervedOrders = array.slice(23, array.length)
    }
    const waypoints = orders.map(o => ({
      location: {
        lat: parseFloat(o.client.latitude),
        lng: parseFloat(o.client.longitude),
      },
      stopover: true,
    }))
    const destination = waypoints.pop().location
    const origin = { lat: coords.latitude, lng: coords.longitude }
    directionsService.route(
      {
        origin,
        destination,
        waypoints,
        optimizeWaypoints,
        travelMode: 'DRIVING',
      },
      (response, status) => {
        if (status === 'OK') {
          directionsDisplay.setDirections(response)
          this.setState({ orders: orders.concat(recervedOrders) })
        } else {
          this.setState({
            alert: {
              ...handlerError(status + ', Verifique su conexión a internet y su GPS.'),
              onConfirm: () =>
                this.setState({ alert: { ...this.state.alert, show: false } }),
            },
          })
        }
      },
    )
  }

  handlerError = message => {
    return { show: true, title: 'Uh-Oh..', text: message, type: 'warning' }
  }

  handlerSuccess = message => {
    return { show: true, title: 'Yeah!', text: message, type: 'success' }
  }

  onChildClickCallback = value => {
    console.log(value)
  }

  onGoogleApiLoaded = (map, maps) => {
    const directionsService = new maps.DirectionsService()
    const directionsDisplay = new maps.DirectionsRenderer()
    directionsDisplay.setMap(map)
    this.setState({ directionsService, directionsDisplay })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return
    let orders = Object.assign([], this.state.orders)
    orders = arrayMove(orders, oldIndex, newIndex)
    this.setState({ orders })
    this.setUp(orders)
  }

  clickItem = item => {
    this.props.getSingleOrder(item.id)
    //const {history} = this.props;
    //const route = `/distribucion/ordenes/true/detalle/${item.id}`;
    //history.push(route);
  }

  toggle = () => {
    const { toggle } = this.state
    return (
      <div
        style={{
          marginTop: 5,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <Icon
          onClick={() => this.downloadRoute()}
          tooltip={'Descarga de ruta'}
          icon={faDownload}
        />
        <span style={{ marginLeft: 10, marginRight: 10 }}>
          {toggle ? 'Personalizar' : 'Sugerido'}
        </span>
        <Switch
          value={toggle}
          checked={toggle}
          onChange={() => {
            if (toggle) this.init()
            this.setState({ toggle: !toggle })
          }}
        />
      </div>
    )
  }

  getOrderTotal = (details, type, balace) => {
    if (type === 3) return formatNumberWithCommas(balace * -1)
    if (!details) return formatNumberWithCommas(0)
    let total = 0
    details.map(d => (total += parseFloat(d.total) - parseFloat(d.discount)))
    return formatNumberWithCommas(total)
  }

  showPosition = position => {
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    })
  }

  refresh = () => {
    navigator.geolocation.getCurrentPosition(this.showPosition)
  }

  calculateDistance = (order, coords) => {
    return this.props.getDistance(
      order.client.latitude,
      order.client.longitude,
      this.state.latitude || coords.latitude,
      this.state.longitude || coords.longitude,
    )
  }

  deleteOrder = () => {
    this.setState({ showDelete: false })
    this.props.deleteSingleOrder(this.props.order.orderId)
  }



      getHeaders = (order) => {
        const headers = []
        if(order.status !== 8 && order.linked ){headers.push({label: 'Paquete', show: true, value: ['number'], type: 'code' ,  className:'mini', custom:(item)=> item.number !== 0 ? `No. ${item.number}`:''})}
        headers.push(...[
            {label: 'Cantidad', show: true, value: ['quantity'], type: 'wholeNumber' ,  className:'mini'},
        {label: 'Producto', show: true, value: ['productC'], type: 'text' ,  className:'mini',
             custom:(item)=>item.productC =item.productData
            ? item.productData.name
            : item.product
            ? item.product.name
            : 'N/A'}
        ,
        {label: 'Precio', show: true, value: ['precioC'], type: 'text' ,  className:'mini', custom:(item)=> item.precioC =  toMoney(new Decimal(item.total ? item.total / item.quantity : 0))},
        {label: 'Subtotal', show: true, value: ['total'], type: 'currency' ,  className:'mini'},
            {label: 'Descuento', show: true, value: ['discount'], type: 'currency' ,  className:'mini'},
        {label: 'Total', show: true, value: ['amount'], type: 'text' ,  className:'mini', custom:(item)=> item.totalC = toMoney(new Decimal(item.total ? item.total - item.discount : 0))},
      {config:true, show: true, label:'', className:'mini center'}
    ])
  }

  confirmOrderWithLocation = (orderId, mod, groupFrom, flag, bool) => {
    let {
      isGeolocationAvailable,
      isGeolocationEnabled,
      coords,
      getDistance,
      module,
      order,
      getPermission,
    } = this.props
    let { alert, longitude, latitude } = this.state
    if (!isGeolocationAvailable && (module === 1000 || module === 15000)) {
      alert = this.handlerError('Este dispositivo no es compatible con Geolocalizacion')
      setTimeout(() => {
        this.setState({ showDetail: false, alert })
      }, 500)
      return
    } else if (!isGeolocationEnabled && (module === 1000 || module === 15000)) {
      alert = this.handlerError('La geolocalizacion no esta activada')
      setTimeout(() => {
        this.setState({ showDetail: false, alert })
      }, 500)
      return
    } else if (coords || module == 2000) {
      if (order.type !== 2 && !order.warehouse && !order.warehouseId && !groupFrom) {
        this.setState({ showDetail: false })
        return this.setState({ showAlert: true, isPreSale: false })
      }
      let distance = 0
      if (order.client && order.client.code && latitude && longitude)
        distance = getDistance(
          order.client.latitude,
          order.client.longitude,
          latitude,
          longitude,
        )
      else if (order.client && order.client.code)
        distance = getDistance(
          order.client.latitude,
          order.client.longitude,
          coords.latitude,
          coords.longitude,
        )
      let permission =
        module == 1000
          ? getPermission(1159)
          : module === 15000
          ? getPermission(15067)
          : getPermission(2810)

      console.log('distancia', distance)
      if (permission || distance <= 300) {
        this.props.confirmSaleOrder(orderId, mod, groupFrom, flag, bool)
        this.setState({ showDetail: false })
      } else {
        alert = this.handlerError(
          'El usuario no se encuentra en el radio del cliente. Distancia actual: ' +
            Number(distance) +
            ' metros',
        )
        alert.onConfirm = () => {
          this.setState({ alert: { ...alert, show: false } })
        }
        setTimeout(() => {
          this.setState({ showDetail: false, alert })
        }, 500)
      }
    }
  }

  downloadRoute = () => {
    const { orders } = this.state
    const { getExcelByOrders } = this.props
    const ordersID = orders.map(o => o.id)
    getExcelByOrders(ordersID)
  }

  render() {
    const {
      orders,
      toggle,
      alert,
      showDetail,
      showInventory,
      selectedWarehouse,
      details,
      showInvoice,
      showEmail,
      showDelete,
    } = this.state
    const {
      loadingOrders,
      deliverers,
      getPermission,
      order,
      coords,
      confirmIsLoading,
      deleteIsLoading,
      warehouses,
      invoice,
    } = this.props

    return (
      <div className={'column'}>
        <Title title={'Rutas'} />
        <Row>
          <Col className={'style-map-route'}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: MAPS_API_KEY }}
              defaultZoom={10}
              defaultCenter={{ lat, lng }}
              onChildClick={value => this.onChildClickCallback(value)}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) =>
                this.onGoogleApiLoaded(map, maps)
              }></GoogleMapReact>
          </Col>
          <Col xl={5} lg={5} md={12} sm={12} xs={12}>
            {(getPermission(1352) || getPermission(15090)) && (
              <Row>
                <Col xl={5} lg={5} md={5} />
                <Col xl={7} lg={7} md={7}>
                  <Select
                    label={'Repartidor'}
                    options={[{ value: null, label: '- todos -' }, ...deliverers]}
                    value={this.state.deliverer}
                    onChange={data => {
                      this.setState({ deliverer: data })
                      this.setNewOrders(this.props.orders, data)
                    }}
                  />
                </Col>
              </Row>
            )}
            <Card title={'Listado de ordenes'} button={this.toggle()}>
              <div className={'orders-container'}>
                {loadingOrders ? (
                  <BulletList />
                ) : orders.filter(o => !o.deleted || o.status !== 3).length > 0 ? (
                  <SortableContainer hideSortableGhost={true} onSortEnd={this.onSortEnd}>
                    {orders
                      .filter(o => !o.deleted && (o.status !== 3 || o.status !== 7))
                      .map((value, index) => (
                        <SortableItem
                          key={`item-${index}`}
                          index={index}
                          value={value}
                          sortIndex={index}
                          onClick={this.clickItem}
                          disabled={!toggle}
                        />
                      ))}
                  </SortableContainer>
                ) : (
                  <span>No hay ordenes pendientes</span>
                )}
              </div>
            </Card>
          </Col>
        </Row>

        <Modal
          show={showInventory}
          size={'lg'}
          centered
          aria-labelledby={'contained-modal-title-vcenter'}
          onHide={() => this.setState({ showInventory: false })}>
          <Modal.Header closeButton>
            <Modal.Title>{`Orden: #${order.number}`}</Modal.Title>
          </Modal.Header>

                    <Modal.Body>
                        <div className={'column'}>
                            {
                                <Select
                                    disabled={order.warehouse}
                                    options={warehouses}
                                    label={'Bodega Saliente'}
                                    value={selectedWarehouse}
                                    onChange={(d) => {
                                        this.props.getProductExistenceByOrder(d.value, [order.orderId])
                                        this.setState({selectedWarehouse: d});
                                    }}
                                />
                            }
                        </div>
                        {
                            <Card title={'Productos en la orden'}>
                                <TableV2
                                    headers={[

                                        {
                                            label: 'Existencia', show: true, value: ['amount'], type: 'text', className: 'mini',
                                            custom: (item) => <IconButton icon={item.existence ? faCheckCircle : faTimesCircle}
                                                color={item.existence ? 'green' : 'red'}
                                                tooltip={item.existence ? 'Sí hay existencia' : 'No hay existencia'} />
                                        },
                                        { label: 'Orden', show: true, value: ['order'], type: 'text', className: 'mini' },
                                        { label: 'Producto', show: true, value: ['productoC'], type: 'text', className: 'mini' ,custom:(item)=> item.productoC = `${item.product.code}-${item.product.name}` },
                                        { label: 'Cantidad', show: true, value: ['quantity'], type: 'text', className: 'mini' },
                                        { config: true, show: true, label: '', className: 'mini center' }
                                    ]}
                                    items={details}
                                    mobileAuto
                                    storageKey={`routeInventory`}
                                />
                            </Card>
                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <Row className={'container-buttons'}>
                            <Button
                                disabled={details.filter(d => !d.existence && !d.selected).length > 0}
                                icon={faClipboardCheck}
                                loading={confirmIsLoading}
                                variant={'info'}
                                right
                                onClick={() => {
                                    this.setState({
                                        alert: {
                                            show: true,
                                            type: 'info',
                                            title: 'Confirmar orden',
                                            text: '¿Desea confirmar esta orden?',
                                            showCancelButton: true,
                                            onCancel: () => this.setState({alert: {...alert, show: false}}),
                                            onConfirm: () => {
                                                this.setState({alert: {...alert, show: false}}, () => {
                                                    this.setState({showInventory: false}, () => this.confirmOrderWithLocation(order.orderId, 1000, selectedWarehouse.value, 0, false));
                                                });
                                            }
                                        }
                                    });
                                }}>
                                Confirmar
                            </Button>
                        </Row>
                    </Modal.Footer>
                </Modal>

        <Modal
          show={showDetail} //showCashBox
          size={'lg'}
          centered
          aria-labelledby={'contained-modal-title-vcenter'}
          onHide={() => this.setState({ showDetail: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Detalle de Orden</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container className="invoice">
              <Row>
                <Col xs={8}>
                  <h4>
                    <b>Cliente: </b>
                    {order.client ? order.client.companyName : 'N/A'}
                  </h4>
                </Col>
                <Col xs={4} className={'text-center'}>
                  <b>Última fecha de actualización </b>
                  {order.date ? formatDateFromMillis(order.date) : 'N/A'}
                </Col>
              </Row>

              <Row>
                <Col>
                  <h4>
                    <b>Bodega: </b>
                    {order.warehouse ? order.warehouse.name : 'Sin Bodega'}
                  </h4>
                </Col>
              </Row>

              <Row>
                <Col>
                  <br />
                </Col>
              </Row>

                            <Row className="text-center">
                                <Col><b>Tipo</b><br/> {order.orderTypeData ? order.orderTypeData.name : 'N/A'}</Col>
                                <Col><b>Estado</b> <br/> {order.statusData ? order.statusData.name : 'N/A'}</Col>
                                <Col><b>Tipo de venta</b> <br/> {order.paymentType ? order.paymentType === 1 ? 'Contado' : 'Credito' : '---'}</Col>
                                {
                                    order.enabledAt ? <Col><b>Fecha estimada de entrega</b> <br/> {formatDateFromMillis(order.enabledAt)}</Col> : ''
                                }
                            </Row>
                            {order.description?
                                <Row>
                                    <Col><b>Descripcion</b><br/> {order.description}</Col>
                                </Row>
                                :''}
                            <Row><br/><Col className={'text-center'}>{order.deleted ? <b className={'red-text'}>Orden Eliminada</b> : ''}</Col></Row>
                            <Row><br/><Col className={'text-center'}>
                                {order.presaleDiscProduct ? <b style={{color: 'rgba(33,114,29,0.6)', fontSize: 15}}>Producto entregado</b> : ''}
                            </Col></Row>
                            <Row><br/><Col className={'text-center'}>
                                {order.presalePayed ? <b style={{color: 'rgba(33,114,29,0.6)', fontSize: 15}}>Orden ya cancelada</b> : ''}
                            </Col></Row>
                            <hr/>
                            {
                                order && showDetail && order.type === 1 ?
                                    <>
                                        <TableV2
                                            headers={this.getHeaders(order)}
                                            items={order.details}
                                            mobileAuto
                                            storageKey={`routeTable`}
                                        />
                                        {
                                            order.linked && !order.delivererCompany ?
                                                <>
                                                    <Row style={{display: 'flex', justifyContent: 'flex-end', textAlign: 'right'}}>
                                                        <Col xs={4}><h2>SubTotal: Q{formatNumberWithCommas(order.total-25)}</h2></Col>
                                                    </Row>
                                                    <Row style={{display: 'flex', justifyContent: 'flex-end', textAlign: 'right'}}>
                                                        <Col xs={4}><h2 style={{color: 'rgba(203,64,96,0.99)'}}>Costo de envío: Q 25.00</h2></Col>
                                                    </Row>
                                                    <Row style={{display: 'flex', justifyContent: 'flex-end', textAlign: 'right'}}>
                                                        <Col xs={4}><h2>Total: Q{formatNumberWithCommas(order.total)}</h2></Col>
                                                    </Row>
                                                </>
                                                :
                                                <Row style={{display: 'flex', justifyContent: 'flex-end', textAlign: 'right'}}>
                                                    <Col xs={4}><h2>Total: Q{this.getOrderTotal(order.details, order.type, order.balance)}</h2></Col>
                                                </Row>
                                        }
                                    </> :
                                    <Row>
                                        <Col xs={10}/>
                                        <Col xs='auto'><h2>Total: Q{this.getOrderTotal(order.details, order.type, order.balance)}</h2></Col>
                                    </Row>
                            }
                            {
                                (order.type === 1 && order.client && coords) &&
                                <Row>
                                    <Col style={{display: 'flex'}}>
                                        <IconButton icon={faSync} spin={this.state.spin} color={'rgb(80,170,167)'} onClick={this.refresh} size={'2x'} style={{marginRight: 10}}/>
                                        <h5 style={{color: this.calculateDistance(order, coords) > 300 ? 'red' : 'green'}}>
                                            Distancia: {Number(this.calculateDistance(order, coords)).toFixed(0)} m.</h5>
                                    </Col>
                                </Row>
                            }
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Row className={'container-buttons'}>
                            {
                                order.type === 1 && order.status === 1 &&
                                <IconButton icon={faDownload} size={'2x'} style={{marginRight: '15px'}}
                                            onClick={() => this.props.printOrder(order.orderId, order.number)} tooltip={'Descargar'}/>
                            }
                            {
                                (order.type === 2 && order.status === 1 && !order.deleted) &&
                                <Button className={"small-button"}
                                        onClick={() => this.props.confirmVisitNoSale(order.orderId)}>Confirmar Sin Venta</Button>
                            }
                            {
                                (order.status ===1 || order.status === 8) && (!order.deleted) &&
                                <IconButton style={{marginRight: '15px'}} tooltip={'Eliminar orden'} size={'2x'}
                                            icon={faTrashAlt} spin={deleteIsLoading}
                                            onClick={() => this.setState({showDelete: true})}/>
                            }
                            {((order.type === 1 && order.status === 1 || order.status === 5 || order.status === 8) && !order.deleted) &&
                            <Button
                                icon={faClipboardCheck}
                                variant={'info'}
                                loading={confirmIsLoading}
                                onClick={() => {
                                    if (order.status === 5 && order.warehouse) {
                                        this.setState({
                                            alert: {
                                                show: true,
                                                type: 'info',
                                                title: 'Confirmar orden',
                                                text: '¿Desea confirmar esta orden?',
                                                showCancelButton: true,
                                                onCancel: () => this.setState({alert: {...alert, show: false}}),
                                                onConfirm: () => {
                                                    this.setState({alert: {...alert, show: false}}, () =>
                                                        this.confirmOrderWithLocation(order.orderId, this.props.module, 0, 0, false));
                                                }
                                            }
                                        })
                                    } else {
                                        if(order.warehouse){
                                            this.props.getProductExistenceByOrder(order.warehouse.id, [order.orderId], order.status === 8);
                                            this.setState({selectedWarehouse: {value: order.warehouse.id, label: order.warehouse.name} })
                                        }
                                        else{
                                            this.setState({showInventory: true})
                                        }
                                    }
                                }}
                            >Confirmar orden</Button>
                            }
                        </Row>
                    </Modal.Footer>
                </Modal>

        <Modal
          show={showInvoice} //showCashBox
          size={'lg'}
          centered
          aria-labelledby={'contained-modal-title-vcenter'}
          onHide={() => this.setState({ showInvoice: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Orden</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {invoice &&
              invoice.length > 0 &&
              invoice.map((invoice, i) => {
                return (
                  <div>
                    <Container className="invoice">
                      <Row>
                        <Col className="text-center">
                          <h3>{invoice ? invoice.companyDetail.name : ''}</h3>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="text-center">
                          <h2>{invoice.companyDetail.name}</h2>
                        </Col>
                        <Col className="text-center">
                          <h2>NIT: {invoice.companyDetail.nit}</h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="text-center">{invoice.companyDetail.address}</Col>
                      </Row>

                      <Row>
                        {invoice.uuid ? (
                          <Col className="text-center">
                            Codigo de Autorizacion{invoice.uuid}
                          </Col>
                        ) : (
                          <Col className="text-center">
                            Resolucion No. {invoice && invoice.resolutionDetails.number}{' '}
                            de fecha{' '}
                            {formatDateFromMillis(
                              invoice.resolutionDetails.authorizationDate,
                            )}
                          </Col>
                        )}
                      </Row>
                      {invoice.uuid ? (
                        ''
                      ) : (
                        <Row>
                          <Col className="text-center">
                            Serie {invoice.resolutionDetails.series} Del{' '}
                            {invoice.resolutionDetails.initialNumber} al{' '}
                            {invoice.resolutionDetails.finalNumber}
                          </Col>
                        </Row>
                      )}

                      <Row className="text-center">
                        <Col>
                          <h2>
                            Orden No.{invoice.number} -{' '}
                            {formatDateFromMillis(invoice.createdAt)}
                          </h2>
                        </Col>
                      </Row>

                      <Row className="text-center">
                        <Col>
                          Nombre: <br /> {invoice.name}
                        </Col>
                        <Col>
                          Nit: <br /> {invoice.nit ? invoice.nit : 'C/F'}
                        </Col>
                      </Row>
                      {invoice.cancelled && (
                        <Row>
                          <Col className="text-center" style={{ color: 'red' }}>
                            FACTURA ANULADA
                          </Col>
                        </Row>
                      )}
                      <hr />
                      <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                        <Row>
                          <Col>
                            <b>Cantidad</b>
                          </Col>
                          <Col>
                            <b>Producto</b>
                          </Col>
                          <Col>
                            <b>Total</b>
                          </Col>
                        </Row>
                        <hr />
                        {invoice.invoiceDetails.map((value, index) => {
                          return (
                            <Row key={value.id}>
                              <Col>{value.quantity}</Col>
                              <Col>{value.description}</Col>
                              <Col>
                                {formatNumberWithCommas(parseFloat(value.amount))}
                              </Col>
                            </Row>
                          )
                        })}
                        <hr />

                        <Row>
                          <Col>
                            <h2>Total</h2>
                          </Col>
                          <Col></Col>
                          <Col>{formatNumberWithCommas(parseFloat(invoice.total))}</Col>
                        </Row>
                      </div>
                      <Row>
                        {' '}
                        <Col className="text-center">
                          (Impuesto de Ventas Incluido)
                        </Col>{' '}
                      </Row>
                    </Container>
                    <Row className={'container-buttons'}>
                      <Button
                        onClick={() =>
                          this.props.downloadPrintableInvoice(invoice.id, invoice.number)
                        }>
                        Descargar
                      </Button>
                    </Row>
                    <br />
                  </div>
                )
              })}
          </Modal.Body>
          <Modal.Footer>
            <Row className={'container-buttons'}>
              <Button
                onClick={() =>
                  this.props.downloadPrintableInvoiceByOrder(
                    invoice.length > 0 ? invoice[0].order : 0,
                    invoice.number,
                    1000,
                  )
                }>
                Descargar todas
              </Button>
              <Button
                onClick={() => this.setState({ showInvoice: false, showEmail: true })}>
                Enviar
              </Button>
            </Row>
          </Modal.Footer>
        </Modal>

        <SweetAlert
          show={showDelete}
          title={'Eliminar'}
          text={`Desea Eliminar esta orden de ${
            order.orderTypeData && order.orderTypeData.name
          }?`}
          type={'warning'}
          showCancelButton
          onConfirm={() => {
            this.deleteOrder()
          }}
          onCancel={() => {
            this.setState({ showDelete: false })
          }}
        />

        <SweetAlert
          show={showEmail}
          title="Factura Electrónica"
          text="Ingrese correo electrónico"
          type="input"
          inputType="email"
          inputPlaceholder="example@domain.com"
          showCancelButton
          onConfirm={inputValue => {
            this.props.sendInvoiceEmail({
              id: invoice.id,
              email: inputValue,
              module: 1000,
            })
            this.setState({ showEmail: false })
          }}
          onCancel={() => {
            this.setState({ showEmail: false })
          }}
        />

        <Alert
          onConfirm={() => this.setState({ alert: { ...alert, show: false } })}
          {...alert}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: selectCurrentUser(state),
  orders: selectGetAllOrders(state),
  loadingOrders: loadingSelector([
    actionTypes.GET_ALL_ORDERS,
    actionTypes.GET_ORDERS_LINKED,
  ])(state),
  hasErrorOrders: hasErrorsSelector([
    actionTypes.GET_ALL_ORDERS,
    actionTypes.GET_ORDERS_LINKED,
  ])(state),
  errorOrders: singleErrorSelector([
    actionTypes.GET_ALL_ORDERS,
    actionTypes.GET_ORDERS_LINKED,
  ])(state),
  loadingDeliverers: loadingSelector([types.GET_ALL_DELIVERERS])(state),
  getDistance: (lat1, lon1, lat2, lon2) => getDiffBetweenPoints(lat1, lon1, lat2, lon2),
  getPermission: permission => getPermission(state, permission),
  deliverers: selectDeliverersToSelect(state),

  //order
  deleteIsLoading: loadingSelector([orderTypes.DELETE_SINGLE_ORDER])(state),
  deleteHasError: hasErrorsSelector([orderTypes.DELETE_SINGLE_ORDER])(state),
  deleteError: singleErrorSelector([orderTypes.DELETE_SINGLE_ORDER])(state),
  confirmIsLoading: loadingSelector([orderTypes.CONFIRM_SINGLE_ORDER])(state),
  confirmHasError: hasErrorsSelector([orderTypes.CONFIRM_SINGLE_ORDER])(state),
  confirmError: singleErrorSelector([orderTypes.CONFIRM_SINGLE_ORDER])(state),
  order: selectGetSingleOrder(state),
  orderIsLoading: loadingSelector([typesOder.GET_SINGLE_ORDER])(state),
  existence: selectProductExistence(state),
  loadingExistence: loadingSelector([orderTypes.GET_EXISTENCE])(state),
  warehouses: selectAllWarehousesForSelect(state),
  loadingNoSaleVisit: loadingSelector([typesOder.CONFIRM_VISIT_ORDER_NO_SALE])(state),
  noSaleVisitHasError: hasErrorsSelector([orderTypes.CONFIRM_VISIT_ORDER_NO_SALE])(state),
  invoice: selectGetInvoiceByOrder(state),
  invoiceLoading: loadingSelector([typesOder.GET_INVOICE])(state),
})
const mapDispatchToProps = dispatch => ({
  getOrders: (from, to, deliverer, vendor) =>
    dispatch(getAllOrders(from, to, true, deliverer, vendor, null)),
  getLinked: (from, to) => dispatch(getOrdersLinked(from, to)),
  getAllDeliverers: () => dispatch(getAllDeliverers()),
  getSingleOrder: id => dispatch(getSingleOrder(id)),
  deleteSingleOrder: id => dispatch(deleteSingleOrder(id)),
  confirmSaleOrder: (id, module, warehouse, deliverer, remove) =>
    dispatch(confirmSingleOrder(id, module, warehouse, deliverer, remove)),
  confirmVisitNoSale: id => dispatch(confirmVisitNoSale(id)),
  getProductExistenceByOrder: (id, orders, reserved) =>
    dispatch(getProductExistenceByOrder(id, orders, reserved)),
  getWarehouses: () => dispatch(getWarehouses()),
  downloadPrintableInvoice: (id, defaultName, module) =>
    dispatch(downloadPrintableInvoice(id, defaultName, module)),
  downloadPrintableInvoiceByOrder: (id, defaultName, module) =>
    dispatch(downloadPrintableInvoiceByOrder(id, defaultName, module)),
  sendInvoiceEmail: invoice => dispatch(sendInvoiceEmail(invoice)),
  getExcelByOrders: orders => dispatch(getExcelByOrders(orders)),
  printOrder: (id, defaultName) => dispatch(printOrder(id, defaultName)),
})

const ReduxComponent = connect(mapStateToProps, mapDispatchToProps)(Route)
export default geolocated({
  positionOptions: { enableHighAccuracy: false },
  userDecisionTimeout: 500,
})(ReduxComponent)
