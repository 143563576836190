import './UpdatePermissions.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Button from 'src/components/buttons/Button'
import ButtonIcon from 'src/components/buttons/IconButton'
import FormText from 'src/components/inputs/FormTextField/FormText'

import {
  Row,
  Col,
  Modal,
  Accordion,
  Card,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap'
import {
  faCaretDown,
  faEye,
  faFolderPlus,
  faMoneyBill,
  faUser,
  faUserCog,
  faUserEdit,
} from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes as types,
  getRolesByUserChild,
  putRoleByUser,
  deleteRoleByUser,
  getRolesByModule,
  updateRolesByUser,
} from 'src/actions/modules.actions'
import {
  selectRolesByUser,
  loadingToggle,
  getPermission,
  selectModuleGroups,
} from 'src/selectors/modules.selector'

import { searchUserByEmail, getUserFields } from 'src/actions/user.actions'
import { selectUserFields } from 'src/selectors/user.selector'

import {
  actionTypes as typeB,
  getBalanceInCompany,
  onSetMaxCpcInUserBalance,
} from 'src/actions/balance.actions'
import { selectBalanceInCompany } from 'src/selectors/balance.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { faMoneyBillWave } from '@fortawesome/free-solid-svg-icons/faMoneyBillWave'
import IconButton from 'src/components/buttons/IconButton'
import { handlerError, handlerSuccess } from 'src/selectors/error.selector'
import SweetAlert from 'sweetalert-react'
import Switch from 'src/components/inputs/Switch/Switch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import PermissionGroup from './PermissionGroup'
import { getUserPlan } from 'src/actions/plans.actions'
import { selectModuleUser } from 'src/selectors/plans.selector'
import { haveAnyValue } from 'src/utils/utilitiesV2'

class UpdatePermissions extends Component {
  state = {
    loading: { value: false, id: null, all: false },
    loadingRole: { value: false, id: null },
    modalCPC: { show: false, maxCPC: 0 },
    userId: null,
    modalPayment: false,
    paymentAlert: {
      show: false,
      title: 'Default',
      text: 'default',
      type: 'info',
    },
    group: { show: false, groupId: null, roles: [] },
  }

  componentDidMount() {
    const { user, module } = this.props.match.params
    const { id } = this.props.user
    this.setState({ userId: user }, () => {
      this.props.getUserFields(user)
      this.props.getBalanceInCompany(user)
      if (parseInt(user) === id) this.props.getRolesByModule(module)
      else this.props.getRolesByUserChild(module, user)
      this.props.getUserPlan(user)
    })
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { loadingToggle, loadingB, loadingUpdate, loadingGroup } = this.props

    if (loadingGroup && !props.loadingGroup) {
      const { user, module } = this.props.match.params
      const { id } = this.props.user

      if (parseInt(user) === id) this.props.getRolesByModule(module)
      else this.props.getRolesByUserChild(module, user)
    }

    if (loadingToggle && !props.loadingToggle) {
      this.setState({ loading: { value: false, id: null } })
    }

    if (loadingB && !props.loadingB) {
      let { paymentAlert } = this.state
      if (this.state.modalCPC.show) {
        paymentAlert = handlerSuccess('Se actualizo el maximo de cuentas')
        paymentAlert.onConfirm = () =>
          this.setState({ paymentAlert: { ...paymentAlert, show: false } })
      }
      this.setState({
        modalCPC: { ...this.state.modalCPC, show: false },
        paymentAlert,
      })
    }

    if (loadingUpdate && !props.loadingUpdate) {
      this.setState({ loading: { value: false, id: null, all: false } })
    }
  }

  handleCheckBoxChange = event => {
    const { name, checked } = event.target
    const { module, user } = this.props.match.params
    const { deleteRoleByUser, putRoleByUser } = this.props
    this.setState({ loading: { value: true, id: parseInt(name) } })
    if (checked) putRoleByUser(module, user, name)
    else deleteRoleByUser(module, user, name)
  }

  getField = data => {
    const { user } = this.props
    const field = user.find(u => u.displayName === data)
    if (field) return field
    else return { value: 'Desconocido' }
  }

  handleItemChange = (item, role) => {
    const { module, user } = this.props.match.params
    const { deleteRoleByUser, putRoleByUser, userPlan, roles } = this.props
    let { paymentAlert } = this.state
    this.setState({ loading: { value: true, id: item.id } })

    if (!item.assigned) {
      putRoleByUser(module, user, item.id)

      /*
       TODO: resolve
      if(userPlan && userPlan.modules){
        putRoleByUser(module, user, item.id);
      }
      else if(userPlan){
        let array = roles.filter(d => d.roles.filter(r => r.assigned).length > 0)

        if(array.length < 5 || array.findIndex(d => d.id === role.id) !== -1){
          putRoleByUser(module, user, item.id);
        }
        else{
          paymentAlert = handlerError('No se pueden agregar más módulos');
          paymentAlert.onConfirm = () => this.setState({paymentAlert: {...paymentAlert, show: false}});
          this.setState({paymentAlert, loading: {value: false, id: null}})
        }
      }
      */
    } else deleteRoleByUser(module, user, item.id)
  }

  getCheck = role => {
    return role.roles.every(r => r.assigned)
  }

  handleRoleChange = event => {
    const { name, checked } = event.target
    const { module, user } = this.props.match.params
    const { updateRolesByUser } = this.props
    const { roles } = this.props

    const role = roles.find(r => r.id === parseInt(name))
    if (haveAnyValue(role)) {
      this.setState({ loading: { value: true, id: role.id } })

      updateRolesByUser(module, user, role.roles, checked)
    }
  }

  toggleAll = (event, allRoles) => {
    const { checked } = event.target
    const { module, user } = this.props.match.params
    const { updateRolesByUser } = this.props
    const { loading } = this.state
    this.setState({ loading: { ...loading, all: true } })
    updateRolesByUser(module, user, allRoles, checked)
  }

  getAllCheck = allRoles => {
    return allRoles.length === allRoles.filter(r => r.assigned).length
  }

  getAllRoles = roles => {
    let allRoles = []
    roles.map(r => r.roles.filter(f => f.id > 0).map(rl => allRoles.push(rl)))
    return allRoles
  }

  handlerError = message => {
    return {
      show: true,
      title: 'Actualizar informacion',
      text: message,
      type: 'warning',
    }
  }

  handlerSuccess = message => {
    return { show: true, title: 'Yeah!', text: message, type: 'success' }
  }

  hideAction = (type, message) => {
    let { paymentAlert, userId, modalCPC } = this.state
    let { onSetMaxCpcInUserBalance } = this.props

    paymentAlert = {
      ...this.handlerError(message, true),
      showCancelButton: true,
      onCancel: () => this.setState({ paymentAlert: { ...paymentAlert, show: false } }),
      onConfirm: () =>
        this.setState({ paymentAlert: { ...paymentAlert, show: false } }, () =>
          onSetMaxCpcInUserBalance(userId, modalCPC.maxCPC),
        ),
    }
    this.setState({ paymentAlert })
  }

  render() {
    const { roles, getPermission, balance, loadingB, loadingUpdate, userPlan, groups } =
      this.props
    const { loading, modalCPC, paymentAlert, group } = this.state
    const allRoles = this.getAllRoles(roles)

    const isAdmin = getPermission(1001) || getPermission(2001)
    const createGroup = getPermission(1452) || getPermission(2352)
    const editGroup = getPermission(1453) || getPermission(2353)
    const deleteGroup = getPermission(1454) || getPermission(2354)

    const allModules = groups.map(group => group.modules).flat()

    const otherGroups = []

    roles.forEach(app => {
      const module = allModules.find(m => m.id === app.id)

      if (!module) return otherGroups.push({ name: app.name, modules: [app] })
      module.roles = app.roles.sort((a, b) => (a.name > b.name ? 1 : -1))
    })

    const allGroups = otherGroups
      .concat(groups)
      .map(({ name, modules }) => ({
        name,
        modules: modules.filter(m => Boolean(m.roles)),
      }))
      .filter(({ modules }) => modules.length > 0)

    return (
      <div className={'align-center'}>
        <Col xl={8} lg={8} md={8} sm={12} xs={12}>
          <div>
            <h2 className={'item-title'}>Usuario</h2>
            <Card bodyStyle={{ padding: '0px 8px' }}>
              <ul className={'mdc-list mdc-list--two-line mdc-list--avatar-list'}>
                <li className={'mdc-list-item'}>
                  <span className={'mdc-list-item__graphic'} aria-hidden={true}>
                    <ButtonIcon icon={faUser} />
                  </span>
                  <span className={'mdc-list-item__text'}>
                    <span className={'mdc-list-item__primary-text'}>
                      {this.getField('Nombre').value}
                    </span>
                    <span className={'mdc-list-item__secondary-text'}>
                      {this.getField('Correo').value}
                    </span>
                  </span>
                  <span className={'mdc-list-item__meta'}>
                    <ButtonIcon icon={faUserEdit} tooltip={'Editar'} />
                  </span>
                </li>
              </ul>
            </Card>

            {isAdmin && (
              <Card bodyStyle={{ padding: '0px 8px' }}>
                <ul className={'mdc-list mdc-list--two-line mdc-list--avatar-list'}>
                  <li
                    className={'mdc-list-item'}
                    onClick={() =>
                      this.setState({
                        modalCPC: { show: true, maxCPC: balance.maxCpc },
                      })
                    }>
                    <span className={'mdc-list-item__graphic'} aria-hidden={true}>
                      <ButtonIcon icon={faMoneyBill} />
                    </span>
                    <span className={'mdc-list-item__text'}>
                      <span className={'mdc-list-item__primary-text'}>
                        Monto maximo al credito permitido
                      </span>
                      <span className={'mdc-list-item__secondary-text'}>
                        {balance && balance.maxCpc}
                      </span>
                    </span>
                  </li>
                </ul>
              </Card>
            )}

            {userPlan.modules && (
              <Row className={'container-buttons'}>
                {loadingUpdate && loading.all ? (
                  <h2 className={'d-flex'}>
                    <IconButton spin size={'2x'} iconStyle={{ marginRight: 15 }} />
                    Quitar / Asignar todos
                  </h2>
                ) : (
                  <h2 className={'item-title'}>
                    <input
                      style={{ cursor: 'pointer', marginRight: 4 }}
                      name={'all'}
                      checked={this.getAllCheck(allRoles)}
                      onChange={event => this.toggleAll(event, allRoles)}
                      type="checkbox"
                    />
                    Quitar / Asignar todos
                  </h2>
                )}
              </Row>
            )}
            <Accordion>
              {allGroups.map((g, j) => (
                <div key={j}>
                  <h2 className={'item-title'}>{g.name}</h2>
                  {g.modules.map((role, index) => (
                    <Accordion.Item key={index} eventKey={j * 100 + index}>
                      <Card>
                        <Accordion.Button
                          style={{ padding: '8px 6px', cursor: 'pointer' }}
                          as={Card.Header}
                          eventKey={j * 100 + index}>
                          <div
                            className={'d-flex'}
                            style={{
                              justifyContent: 'space-between',
                              width: '100%',
                            }}>
                            <div className={'d-flex justify-content-flex-start'}>
                              <IconButton
                                size={'1x'}
                                className={'name mr-2 mt-2'}
                                tooltip={
                                  this.getCheck(role)
                                    ? 'Todos los permisos asignados'
                                    : 'Faltan permisos por asignar'
                                }
                                icon={faInfoCircle}
                                style={{ color: 'white' }}
                              />
                              <Switch
                                onChange={e => this.handleRoleChange(e)}
                                loading={loading.id === role.id}
                                style={{ marginTop: 5 }}
                                checked={this.getCheck(role)}
                                name={role.id}
                                disabled={role.id === 0}
                              />
                              <div className={'mt-2 ml-3'}>{role.name}</div>
                            </div>
                            <div className={'d-flex'}>
                              <OverlayTrigger
                                placement={'top'}
                                overlay={
                                  <Tooltip id="tooltip">{`Permisos asignados (${
                                    role.roles.filter(f => f.assigned).length
                                  })`}</Tooltip>
                                }>
                                <div
                                  className={'quantity-orders'}
                                  style={{
                                    marginRight: 8,
                                    backgroundColor:
                                      role.roles.filter(f => f.assigned).length > 0
                                        ? 'green'
                                        : 'red',
                                  }}>
                                  {role.roles.filter(f => f.assigned).length > 0
                                    ? role.roles.filter(f => f.assigned).length
                                    : ''}
                                </div>
                              </OverlayTrigger>
                              <FontAwesomeIcon
                                size={'1x'}
                                className={'name'}
                                icon={faCaretDown}
                                style={{
                                  color: 'white',
                                  marginRight: 10,
                                  marginTop: 10,
                                }}
                              />
                            </div>
                          </div>
                        </Accordion.Button>
                        <Accordion.Body eventKey={j * 100 + index}>
                          <Card.Body>
                            {role.roles.map((item, i) => (
                              <div
                                key={i}
                                onClick={() => this.handleItemChange(item, role)}>
                                <div
                                  className={'d-flex'}
                                  style={{ justifyContent: 'space-between' }}>
                                  <div className={'d-flex'}>
                                    <FontAwesomeIcon
                                      size={'2x'}
                                      className={'name'}
                                      icon={faUserCog}
                                      style={{
                                        color: 'rgb(108,175,199)',
                                        marginRight: 10,
                                      }}
                                    />
                                    <div className={'mt-1'}>
                                      <b>{`${item.name}${
                                        item.public ? ' (Público) ' : ''
                                      }`}</b>
                                      {item.description ? `: ${item.description}` : ''}
                                    </div>
                                  </div>
                                  <div className={'d-flex'}>
                                    {item.id < 0 && (
                                      <FontAwesomeIcon
                                        size={'2x'}
                                        className={'name'}
                                        icon={faEye}
                                        style={{ marginRight: 10, marginTop: 5 }}
                                        onClick={e => {
                                          this.setState({
                                            group: {
                                              show: true,
                                              groupId: Math.abs(item.id),
                                            },
                                          })
                                          e.stopPropagation()
                                        }}
                                      />
                                    )}
                                    <Switch
                                      loading={
                                        loading.all ||
                                        loading.id === item.id ||
                                        loading.id === role.id
                                      }
                                      style={{ marginTop: 5 }}
                                      checked={item.assigned}
                                      id={'withoutCpc'}
                                      info={item.assigned ? 'Asignado' : 'No Asignado'}
                                    />
                                  </div>
                                </div>
                                <hr />
                              </div>
                            ))}
                            {role.id === 0 && createGroup && (
                              <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  this.setState({
                                    group: { ...group, show: true },
                                  })
                                }}>
                                <div
                                  className={'d-flex'}
                                  style={{ justifyContent: 'space-between' }}>
                                  <div className={'d-flex'}>
                                    <FontAwesomeIcon
                                      size={'2x'}
                                      icon={faFolderPlus}
                                      style={{
                                        color: 'rgb(114,199,108)',
                                        marginRight: 10,
                                      }}
                                    />
                                    <div className={'mt-1'}>
                                      <b>Agregar un nuevo grupo de permisos</b>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                              </div>
                            )}
                          </Card.Body>
                        </Accordion.Body>
                      </Card>
                    </Accordion.Item>
                  ))}
                </div>
              ))}
            </Accordion>
          </div>
        </Col>

        <Modal
          show={modalCPC.show}
          size={'md'}
          center
          onHide={() =>
            loadingB
              ? undefined
              : this.setState({ modalCPC: { ...modalCPC, show: false } })
          }>
          <Modal.Header>
            <Modal.Title>Monto maximo al credito permitido</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className={'column'}>
              <FormText
                prepend={<ButtonIcon icon={faMoneyBill} tooltip={'CPC'} />}
                label={'Cantidad maxima'}
                value={modalCPC.maxCPC}
                type={'number'}
                onChange={({ target }) => {
                  const { value } = target
                  this.setState({ modalCPC: { ...modalCPC, maxCPC: value } })
                }}
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Row className={'container-buttons'}>
              <Button
                disabled={loadingB}
                variant={'secondary'}
                onClick={() => this.setState({ modalCPC: { ...modalCPC, show: false } })}>
                Cerrar
              </Button>
              <Button
                disabled={!modalCPC.maxCPC || modalCPC.maxCPC < 0}
                loading={loadingB}
                variant={'info'}
                right
                onClick={() => {
                  this.hideAction(2, 'Monto maximo al credito permitido')
                }}
                icon={faMoneyBillWave}>
                Editar
              </Button>
            </Row>
          </Modal.Footer>
        </Modal>

        <PermissionGroup
          roles={roles}
          show={group.show}
          groupId={group.groupId}
          edit={editGroup || (!group.groupId && createGroup)}
          canDelete={deleteGroup}
          onHide={() => {
            this.setState({ group: { show: false, groupId: null } })
          }}
        />

        <SweetAlert {...paymentAlert} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: selectUserFields(state),
  roles: selectRolesByUser(state),
  loadingGroup: loadingSelector([types.CREATE_GROUP, types.DELETE_GROUP])(state),
  loading: loadingSelector([types.GET_ROLES_BY_USER])(state),
  loadingUpdate: loadingSelector([types.PUT_ROLE_BY_USER, types.UPDATE_ROLES_BY_USER])(
    state,
  ),
  loadingToggle: loadingToggle(state),
  balance: selectBalanceInCompany(state),
  getPermission: id => getPermission(state, id),
  loadingB: loadingSelector([typeB.GET_BALANCE_IN_COMPANY])(state),
  handlerError: message => handlerError(message),
  handlerSuccess: message => handlerSuccess(message),
  userPlan: selectModuleUser(state),
  groups: selectModuleGroups(state),
})

const mapDispatchToProps = dispatch => ({
  getRolesByUserChild: (module, id) => dispatch(getRolesByUserChild(module, id)),
  getRolesByModule: module => dispatch(getRolesByModule(module)),
  deleteRoleByUser: (module, user, role) =>
    dispatch(deleteRoleByUser(module, user, role, true)),
  putRoleByUser: (module, user, role) =>
    dispatch(putRoleByUser(module, user, role, true)),
  searchUserByEmail: email => dispatch(searchUserByEmail(email)),
  getUserFields: id => dispatch(getUserFields(id)),
  getUserPlan: id => dispatch(getUserPlan(id)),
  updateRolesByUser: (module, user, roles, value) =>
    dispatch(updateRolesByUser(module, user, roles, value)),
  getBalanceInCompany: userId => dispatch(getBalanceInCompany(userId)),
  onSetMaxCpcInUserBalance: (userId, maxCpc) => {
    dispatch(onSetMaxCpcInUserBalance(userId, maxCpc))
  },
})
export default connect(mapStateToProps, mapDispatchToProps)(UpdatePermissions)
