import { formatNumberWithCommas } from 'src/utils/formatters'
import { toMoney } from 'src/utils/utilities'

export const colorArray = [
  '#c04b8e',
  '#10a8ba',
  '#ffa600',
  '#80B300',
  '#6b5b95',
  '#00b400',
  '#1ac4be',
  '#ff5122',
  '#5d6167',
  '#6c9cfa',
  '#ffd15f',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#3a49b9',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#c778b7',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
]

export const optionsBar = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
          callback: function (label, index, labels) {
            return label > 999999
              ? toMoney(label / 100000000) + 'M'
              : label > 999
              ? toMoney(label / 1000) + 'k'
              : toMoney(label)
          },
        },
      },
    ],
  },
  legend: {
    display: false,
  },
  tooltips: {
    backgroundColor: '#FFFFFF',
    titleFontColor: '#525252',
    borderColor: '#E3E3E3',
    titleFontFamily: 'Montserrat',
    titleFontSize: 10,
    bodyFontFamily: 'Montserrat',
    bodyFontSize: 10,
    footerFontFamily: 'Montserrat',
    footerFontSize: 10,
    footerFontColor: '#2F3542',
    footerFontStyle: 500,
    borderWidth: 1,
    shadowOffsetX: 4,
    shadowOffsetY: 4,
    shadowBlur: 10,
    shadowColor: 'rgba(0, 0, 0, 0.2)',
    callbacks: {
      title: function (tooltipItems, data) {
        const indice = tooltipItems[0].index
        return data.labels[indice]
      },
      label: function (tooltipItem, data) {
        var indice = tooltipItem.index
        return toMoney(data.datasets[0].data[indice])
      },
      labelTextColor: function (tooltipItem, chart) {
        return '#525252'
      },
    },
  },
}

export const options = {
  maintainAspectRatio: false,
  legend: {
    display: true,
    align: 'end',
    position: 'left',
    labels: {
      boxWidth: 20,
      generateLabels: function (chart) {
        let data = chart.data
        if (data.labels.length && data.datasets.length) {
          return data.labels.map(function (label, i) {
            let meta = chart.getDatasetMeta(0)
            let ds = data.datasets[0]
            return {
              text: label.length > 25 ? label.substring(0, 25) + '...' : label,
              fillStyle: ds.backgroundColor[i],
              hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
              index: i,
            }
          })
        }
        return []
      },
    },
  },
  plugins: {
    labels: [
      {
        render: 'label',
        position: 'outside',
        fontSize: 12,
        fontStyle: 'bold',
        fontColor: '#282c34',
        fontFamily: 'Montserrat',
      },
      {
        render: function (args) {
          return toMoney(args.value)
        },
        fontColor: '#FFFFFF',
        precision: 2,
        fontFamily: 'Montserrat',
        fontStyle: 'bold',
        overlap: false,

        fontSize: 12,
      },
    ],
  },
  tooltips: {
    backgroundColor: '#FFFFFF',
    titleFontColor: '#525252',
    borderColor: '#E3E3E3',
    titleFontFamily: 'Montserrat',
    titleFontSize: 10,
    bodyFontFamily: 'Montserrat',
    bodyFontSize: 10,
    footerFontFamily: 'Montserrat',
    footerFontSize: 10,
    footerFontColor: '#2F3542',
    footerFontStyle: 500,
    borderWidth: 1,
    shadowOffsetX: 4,
    shadowOffsetY: 4,
    shadowBlur: 10,
    shadowColor: 'rgba(0, 0, 0, 0.2)',
    callbacks: {
      title: function (tooltipItems, data) {
        const indice = tooltipItems[0].index
        return data.labels[indice]
      },
      label: function (tooltipItem, data) {
        var indice = tooltipItem.index
        return toMoney(data.datasets[0].data[indice])
      },
      labelTextColor: function (tooltipItem, chart) {
        return '#525252'
      },
    },
  },
}

export const optionsMobile = {
  maintainAspectRatio: false,
  legend: {
    display: false,
    align: 'end',
    position: 'left',
    labels: {
      boxWidth: 20,
      generateLabels: function (chart) {
        let data = chart.data
        if (data.labels.length && data.datasets.length) {
          return data.labels.map(function (label, i) {
            let meta = chart.getDatasetMeta(0)
            let ds = data.datasets[0]
            return {
              text: label.length > 25 ? label.substring(0, 25) + '...' : label,
              fillStyle: ds.backgroundColor[i],
              hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
              index: i,
            }
          })
        }
        return []
      },
    },
  },
  plugins: {
    labels: [
      {
        render: 'label',
        position: 'outside',
        fontSize: 12,
        fontStyle: 'bold',
        fontColor: '#282c34',
        fontFamily: 'Montserrat',
      },
      {
        render: function (args) {
          return toMoney(args.value)
        },
        fontColor: '#FFFFFF',
        precision: 2,
        fontFamily: 'Montserrat',
        fontStyle: 'bold',
        overlap: false,
        fontSize: 12,
      },
    ],
  },
  tooltips: {
    backgroundColor: '#FFFFFF',
    titleFontColor: '#525252',
    borderColor: '#E3E3E3',
    titleFontFamily: 'Montserrat',
    titleFontSize: 10,
    bodyFontFamily: 'Montserrat',
    bodyFontSize: 10,
    footerFontFamily: 'Montserrat',
    footerFontSize: 10,
    footerFontColor: '#2F3542',
    footerFontStyle: 500,
    borderWidth: 1,
    shadowOffsetX: 4,
    shadowOffsetY: 4,
    shadowBlur: 10,
    shadowColor: 'rgba(0, 0, 0, 0.2)',
    callbacks: {
      title: function (tooltipItems, data) {
        const indice = tooltipItems[0].index
        return data.labels[indice]
      },
      label: function (tooltipItem, data) {
        var indice = tooltipItem.index
        return toMoney(data.datasets[0].data[indice])
      },
      labelTextColor: function (tooltipItem, chart) {
        return '#525252'
      },
    },
  },
}

export const optionsQuantity = {
  maintainAspectRatio: false,
  legend: {
    display: true,
    align: 'end',
    position: 'left',
    labels: {
      boxWidth: 20,
      generateLabels: function (chart) {
        let data = chart.data
        if (data.labels.length && data.datasets.length) {
          return data.labels.map(function (label, i) {
            let meta = chart.getDatasetMeta(0)
            let ds = data.datasets[0]
            return {
              text: label.length > 25 ? label.substring(0, 25) + '...' : label,
              fillStyle: ds.backgroundColor[i],
              hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
              index: i,
            }
          })
        }
        return []
      },
    },
  },
  plugins: {
    labels: [
      {
        render: 'label',
        position: 'outside',
        fontSize: 12,
        fontStyle: 'bold',
        fontColor: '#282c34',
        fontFamily: 'Montserrat',
      },
      {
        render: function (args) {
          return `${args.value} U.`
        },
        fontColor: '#FFFFFF',
        precision: 2,
        fontFamily: 'Montserrat',
        fontStyle: 'bold',
        overlap: false,

        fontSize: 12,
      },
    ],
  },
  tooltips: {
    backgroundColor: '#FFFFFF',
    titleFontColor: '#525252',
    borderColor: '#E3E3E3',
    titleFontFamily: 'Montserrat',
    titleFontSize: 10,
    bodyFontFamily: 'Montserrat',
    bodyFontSize: 10,
    footerFontFamily: 'Montserrat',
    footerFontSize: 10,
    footerFontColor: '#2F3542',
    footerFontStyle: 500,
    borderWidth: 1,
    shadowOffsetX: 4,
    shadowOffsetY: 4,
    shadowBlur: 10,
    shadowColor: 'rgba(0, 0, 0, 0.2)',
    callbacks: {
      title: function (tooltipItems, data) {
        const indice = tooltipItems[0].index
        return data.labels[indice]
      },
      label: function (tooltipItem, data) {
        var indice = tooltipItem.index
        return formatNumberWithCommas(data.datasets[0].data[indice]) + ' U.'
      },
      labelTextColor: function (tooltipItem, chart) {
        return '#525252'
      },
    },
  },
}

export const optionsMobileQuantity = {
  maintainAspectRatio: false,
  legend: {
    display: false,
    align: 'end',
    position: 'left',
    labels: {
      boxWidth: 20,
      generateLabels: function (chart) {
        let data = chart.data
        if (data.labels.length && data.datasets.length) {
          return data.labels.map(function (label, i) {
            let meta = chart.getDatasetMeta(0)
            let ds = data.datasets[0]
            return {
              text: label.length > 25 ? label.substring(0, 25) + '...' : label,
              fillStyle: ds.backgroundColor[i],
              hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
              index: i,
            }
          })
        }
        return []
      },
    },
  },
  plugins: {
    labels: [
      {
        render: 'label',
        position: 'outside',
        fontSize: 12,
        fontStyle: 'bold',
        fontColor: '#282c34',
        fontFamily: 'Montserrat',
        overlap: false,
      },
      {
        render: function (args) {
          return `${args.value} U.`
        },
        fontColor: '#FFFFFF',
        precision: 2,
        fontFamily: 'Montserrat',
        fontStyle: 'bold',
        overlap: false,
        fontSize: 12,
      },
    ],
  },
  tooltips: {
    backgroundColor: '#FFFFFF',
    titleFontColor: '#525252',
    borderColor: '#E3E3E3',
    titleFontFamily: 'Montserrat',
    titleFontSize: 10,
    bodyFontFamily: 'Montserrat',
    bodyFontSize: 10,
    footerFontFamily: 'Montserrat',
    footerFontSize: 10,
    footerFontColor: '#2F3542',
    footerFontStyle: 500,
    borderWidth: 1,
    shadowOffsetX: 4,
    shadowOffsetY: 4,
    shadowBlur: 10,
    shadowColor: 'rgba(0, 0, 0, 0.2)',
    callbacks: {
      title: function (tooltipItems, data) {
        const indice = tooltipItems[0].index
        return data.labels[indice]
      },
      label: function (tooltipItem, data) {
        var indice = tooltipItem.index
        return formatNumberWithCommas(data.datasets[0].data[indice]) + ' U.'
      },
      labelTextColor: function (tooltipItem, chart) {
        return '#525252'
      },
    },
  },
}

export const chartOptions = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          callback: function (label, index, labels) {
            return label > 999999
              ? label / 100000000 + 'M'
              : label > 999
              ? label / 1000 + 'k'
              : label
          },
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || ''
        let formatCurrency = amount => {
          if (amount === null) return ''
          return toMoney(amount)
        }

        if (label) {
          label += ': '
        }
        label += formatCurrency(tooltipItem.yLabel)
        return label
      },
    },
  },
  plugins: {
    labels: [],
  },
}

export const chartOptionsQuantity = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          callback: function (label, index, labels) {
            return label > 999999
              ? label / 100000000 + 'M'
              : label > 999
              ? label / 1000 + 'k'
              : label
          },
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || ''
        let formatCurrency = (amount, amount2) => {
          if (amount === null) return ''
          let value
          if (!isNaN(amount)) value = amount
          else if (!isNaN(amount2)) value = amount2

          return value
          /*              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'U.'*/
        }

        if (label) {
          label += ': '
        }
        label += formatCurrency(tooltipItem.yLabel, tooltipItem.xLabel)
        return label
      },
    },
  },
  plugins: {
    labels: [],
  },
}

export const chartOptionsMobile = {
  scales: {
    xAxes: [{ stacked: true, gridLines: { display: false } }],
    yAxes: [
      {
        stacked: true,
        type: 'linear',
        ticks: {
          beginAtZero: true,
          callback: function (label, index, labels) {
            return label > 999999
              ? label / 100000000 + 'M'
              : label > 999
              ? label / 1000 + 'k'
              : label
          },
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || ''
        if (label) {
          label += ': '
        }
        label += toMoney(tooltipItem.yLabel)
        return label
      },
    },
  },
  plugins: {
    labels: {
      render: function (args) {
        return ''
      },
      display: false,
      fontSize: 12,
      fontStyle: 'bold',
      fontColor: '#282c34',
      fontFamily: 'Montserrat',
    },
  },
  legend: { display: false },
}
