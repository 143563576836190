import './EditCompany.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import Card from 'src/components/cards/Card'
import Button from 'src/components/buttons/Button'
import {
  actionTypes,
  activateSingleCompany,
  deleteSingleCompany,
  getSingleCompany,
  updateSingleCompany,
} from 'src/actions/company.actions'
import { selectGetSingleCompany } from 'src/selectors/company.selector'
import ManIcon from 'src/assets/images/man.png'
import Switch from 'src/components/inputs/Switch/Switch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'
import { faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import SweetAlert from 'sweetalert-react'
import '../../../../node_modules/sweetalert/dist/sweetalert.css'
import FormTextField from 'src/components/inputs/FormTextField/FormTextField'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  errorsSelector,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'
import { resetBalanceForAdmin } from 'src/actions/balance.actions'
import TextField from 'src/components/inputs/FormTextField/FormTextField'

class EditCompany extends Component {
  state = {
    name: '',
    shortName: '',
    nit: '',
    address: '',
    email: this.props.company.email || '',
    description: '',
    phone: '',
    active: this.props.company.active | '',
    code: this.props.company.alphanumericId,
    selectedOption: null,
    company: this.props.company,
    isDistributor: false,
    isClient: false,
    isProducer: false,
    isPromotor: false,
    showSuccess: false,
    errors: {},
    showReset: false,
    isKolo: false,
    koloCertifier: false,
  }

  save = () => {
    const {
      name,
      email,
      address,
      code,
      nit,
      isDistributor,
      isClient,
      isProducer,
      isPromotor,
      isKolo,
      koloCertifier,
    } = this.state
    let errors = {}
    let { description, phone, shortName } = this.state

    if (!name) {
      errors.name = 'Es requerido'
    }
    if (!address) {
      errors.address = 'Es requerido'
    }
    if (!code) {
      errors.code = 'Es requerido'
    }
    if (!email) {
      errors.email = 'Es requerido'
    }
    if (!description) description = ''
    if (!phone) phone = ''
    if (!shortName) shortName = ''

    if (Object.keys(errors).length === 0) {
      const idCompany = this.props.match.params.company
      this.props.updateCompany(
        idCompany,
        isDistributor ? isDistributor : false,
        address,
        name,
        nit,
        description,
        phone,
        isClient ? isClient : false,
        email,
        isProducer ? isProducer : false,
        isPromotor ? isPromotor : false,
        isKolo,
        koloCertifier,
        shortName,
      )
    }
    this.setState({ errors: errors })
  }

  resetBalance = () => {
    const { company } = this.props
    this.props.resetCompanyBalance(company.id)
    this.setState({ showReset: false })
    this.setState({ showSuccess: true })
  }

  showBalanceReset = () => {
    this.setState({ showReset: true })
  }

  componentDidMount() {
    const idCompany = this.props.match.params.company
    this.props.getSingleCompany(idCompany)
    const { name, address, code } = this.state
    //this.setState({name, address,code},this.props.company);
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { loading, hasError, company } = this.props

    if (loading === true && next.loading === false) {
      if (hasError === false && next.hasError === true) {
        this.setState({ showSuccess: false, showError: true })
      } else {
        this.setState({ showSuccess: true, showError: false })
      }
    }

    if (company !== next.company) {
      const {
        name,
        email,
        address,
        description,
        phone,
        isDistributor,
        isClient,
        isProducer,
        nit,
        isPromotor,
        isKolo,
        koloCertifier,
        shortName,
      } = next.company
      this.setState({
        name: name,
        email: email,
        address: address,
        description: description,
        phone: phone,
        isDistributor: isDistributor,
        isClient: isClient,
        isProducer: isProducer,
        nit: nit,
        isPromotor: isPromotor,
        isKolo,
        koloCertifier,
        shortName,
      })
    }
  }

  handleInputChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  onChangeSwitch = (companyId, event) => {
    this.props.activateSingleCompany(companyId).then(() => {
      this.props.getSingleCompany(companyId)
    })
  }

  onDeleteCompany = () => {
    const idCompany = this.props.match.params.company
    this.props.deleteSingleCompany(idCompany)
    console.log('deleted', idCompany)
    this.setState({ showDelete: false })
    this.props.history.push('/admin/empresas')
  }

  onCheckboxChange = index => {
    switch (index) {
      case 0:
        this.setState(prevState => ({
          isDistributor: !prevState.isDistributor,
        }))
        break
      case 1:
        this.setState(prevState => ({ isClient: !prevState.isClient }))
        break
      case 2:
        this.setState(prevState => ({ isProducer: !prevState.isProducer }))
        break
      case 3:
        this.setState(prevState => ({ isPromotor: !prevState.isPromotor }))
        break
      case 4:
        this.setState(prevState => ({ isKolo: !prevState.isKolo }))
        break
      case 5:
        this.setState(prevState => ({
          koloCertifier: !prevState.koloCertifier,
        }))
        break
      default:
    }
  }

  validateEmail = value => {
    const emailValid = value && value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    return emailValid ? '' : 'Email invalido'
  }

  render() {
    const { selectedOption, errors, koloCertifier } = this.state
    const { company, loading } = this.props
    return (
      <div>
        <Row>
          <Col xs={4}>
            <Card ribbon={company.active ? 'Activo' : ''}>
              <Row style={{ justifyContent: 'center' }}>
                <img
                  src={ManIcon}
                  alt={'client profile'}
                  className={'client-profile-picture'}
                />
              </Row>
              <h2 className={'client-name'}>{company.name}</h2>
              <div className="space-between">
                <strong>Codigo:</strong>
                <span className={'description'}>{company.alphanumericId}</span>
              </div>
              <div className="space-between">
                <strong>NIT:</strong>
                <span>{company.nit}</span>
              </div>
              <div className="space-between">
                <strong>Direccion:</strong>
                <span>{company.address}</span>
              </div>
              <div className="space-between">
                <strong>Email:</strong>
                <span>{company.email}</span>
              </div>
              <hr />
              {/* <div className='space-between'>
                <Col xs={6} className={'label'}>
                  Roles:
                </Col>
                <span>{company.role}</span>
              </div> */}
              <div>
                <Button onClick={() => this.showBalanceReset()} loading={loading}>
                  Reiniciar balance
                </Button>
              </div>
            </Card>
          </Col>

          <Col xs={8}>
            <Card title={'Editar'}>
              <form>
                <Row style={{ justifyContent: 'center' }}>
                  <Col>
                    <FormTextField
                      label={'Nombre'}
                      type={'text'}
                      id={'name'}
                      placeholder={'Nombre'}
                      name={'name'}
                      value={this.state.name}
                      onChange={this.handleInputChange}
                      error={errors.name}
                    />
                  </Col>
                  <Col>
                    <TextField
                      label={'Nombre Corto'}
                      placeholder={'Nombre Corto'}
                      type={'text'}
                      name={'shortName'}
                      id={'shortName'}
                      value={this.state.shortName}
                      error={errors.shortName}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col>
                    <FormTextField
                      label={'Direccion'}
                      type={'text'}
                      id={'address'}
                      placeholder={'Direccion'}
                      name={'address'}
                      value={this.state.address}
                      onChange={this.handleInputChange}
                      error={errors.address}
                    />
                  </Col>

                  <Col>
                    <FormTextField
                      label={'Email'}
                      type={'email'}
                      id={'email'}
                      placeholder={'Email'}
                      name={'email'}
                      value={this.state.email}
                      onChange={this.handleInputChange}
                      error={this.validateEmail(this.state.email)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <FormTextField
                      label={'Descripción'}
                      type={'text'}
                      id={'description'}
                      placeholder={'Descripción'}
                      name={'description'}
                      value={this.state.description}
                      onChange={this.handleInputChange}
                      error={errors.description}
                    />
                  </Col>
                  <Col md={4}>
                    <FormTextField
                      label={'Teléfono'}
                      type={'text'}
                      id={'phone'}
                      placeholder={'Teléfono'}
                      name={'phone'}
                      value={this.state.phone}
                      onChange={this.handleInputChange}
                      error={errors.phone}
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={12} className={'mb-3'}>
                    <Row>
                      <Col>
                        <label className={'form-label'} htmlFor="nombre">
                          Distribuidor
                        </label>
                        <br />
                        <input
                          id={'isDistributor'}
                          name="isDistributor"
                          type="checkbox"
                          checked={this.state.isDistributor}
                          onChange={e => this.onCheckboxChange(0)}
                        />
                      </Col>

                      <Col>
                        <label className={'form-label'} htmlFor="nombre">
                          Cliente
                        </label>
                        <br />
                        <input
                          id={'isClient'}
                          name="isClient"
                          type="checkbox"
                          checked={this.state.isClient}
                          onChange={e => this.onCheckboxChange(1)}
                        />
                      </Col>

                      <Col>
                        <label className={'form-label'} htmlFor="nombre">
                          Productor
                        </label>
                        <br />
                        <input
                          id={'isProducer'}
                          name="isProducer"
                          type="checkbox"
                          checked={this.state.isProducer}
                          onChange={e => this.onCheckboxChange(2)}
                        />
                      </Col>

                      <Col>
                        <label className={'form-label'} htmlFor="nombre">
                          Promotor
                        </label>
                        <br />
                        <input
                          id={'isPromotor'}
                          name="isPromotor"
                          type="checkbox"
                          checked={this.state.isPromotor}
                          onChange={e => this.onCheckboxChange(3)}
                        />
                      </Col>

                      <Col>
                        <label className={'form-label'} htmlFor="nombre">
                          Vitrina kolo
                        </label>
                        <br />
                        <input
                          id={'isKolo'}
                          name="isKolo"
                          type="checkbox"
                          checked={this.state.isKolo}
                          onChange={e => this.onCheckboxChange(4)}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col md={3}>
                    <label className={'form-label'} htmlFor="nombre">
                      Activo
                    </label>
                    <Switch
                      disabled={false}
                      checked={company.active}
                      id={'active'}
                      onChange={e => this.onChangeSwitch(company.id, e)}
                    />
                  </Col>

                  <Col md={3} className={'mb-3'}>
                    <label className={'form-label'} htmlFor="nombre">
                      Usar certificador de KOLO
                    </label>
                    <Switch
                      disabled={false}
                      checked={koloCertifier}
                      id={'active'}
                      onChange={e => this.onCheckboxChange(5)}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={2}>
                    <Button icon={faSave} onClick={() => this.save()} loading={loading}>
                      Guardar
                    </Button>
                    <SweetAlert
                      show={this.state.showSuccess}
                      title="Guardado"
                      text="Cambios guardados"
                      type={'success'}
                      onConfirm={() => {
                        this.setState({ showSuccess: false })
                      }}
                    />
                  </Col>
                  <Col xs={2}>
                    <Button
                      color={'secondary'}
                      icon={faTrashAlt}
                      onClick={() => this.setState({ showDelete: true })}>
                      Eliminar
                    </Button>
                    <SweetAlert
                      show={this.state.showDelete}
                      title="Eliminar"
                      text="Desea eliminar esta empresa?"
                      type={'warning'}
                      showCancelButton
                      onConfirm={() => {
                        this.onDeleteCompany()
                      }}
                      onCancel={() => {
                        console.log('cancelar')
                        this.setState({ showDelete: false })
                      }}
                    />
                  </Col>
                </Row>
              </form>
            </Card>
          </Col>

          <Col xs={12}>
            <Card title={'Formulario IVE'}>
              <h2>Lugar de inicio</h2>
              <div>
                <FormTextField
                  label={'Lugar'}
                  type={'text'}
                  id={'lugarInicio'}
                  placeholder={'Lugar'}
                  name={'lugarInicio'}
                  value={this.state.lugarInicio}
                  onChange={this.handleInputChange}
                  error={errors.lugarInicio}
                />
              </div>
              <hr />
              <h2>Datos de la persona obligada</h2>
              <div>
                <Row>
                  <Col>
                    <FormTextField
                      label={'Razon social y nombre comercial'}
                      type={'text'}
                      id={'razonNombreObligada'}
                      placeholder={'Razon y nombre'}
                      name={'razonNombreObligada'}
                      value={this.state.razonNombreObligada}
                      onChange={this.handleInputChange}
                      error={''}
                    />
                  </Col>
                  <Col>
                    <FormTextField
                      label={
                        'Nombre de la central, sucursal o agencia donde se solicita el producto o servicio'
                      }
                      type={'text'}
                      id={'lugarSolicitud'}
                      placeholder={'Nombre'}
                      name={'lugarSolicitud'}
                      value={this.state.lugarSolicitud}
                      onChange={this.handleInputChange}
                      error={''}
                    />
                  </Col>
                  <Col>
                    <FormTextField
                      label={'Código de agencia o sucursal'}
                      type={'text'}
                      id={'codigoAgencia'}
                      placeholder={'Codigo'}
                      name={'codigoAgencia'}
                      value={this.state.codigoAgencia}
                      onChange={this.handleInputChange}
                      error={''}
                    />
                  </Col>
                </Row>
              </div>
              <hr />
              <h2>Datos de la entidad solicitante</h2>
              <div>
                <Row>
                  <Col>
                    <FormTextField
                      label={'Nombre, razón social o denominación completa:'}
                      type={'text'}
                      id={'razonNombreSolicita'}
                      placeholder={'Razon y nombre'}
                      name={'razonNombreSolicita'}
                      value={this.state.razonNombreSolicita}
                      onChange={this.handleInputChange}
                      error={''}
                    />
                  </Col>
                  <Col>
                    <FormTextField
                      label={'Nombre comercial'}
                      type={'text'}
                      id={'nombreComercial'}
                      placeholder={'Nombre'}
                      name={'nombreComercial'}
                      value={this.state.nombreComercial}
                      onChange={this.handleInputChange}
                      error={''}
                    />
                  </Col>
                  <Col>
                    <FormTextField
                      label={'Número de Identificación Tributaria (NIT)'}
                      type={'text'}
                      id={'actividadEconomica'}
                      placeholder={'nitSolicitante'}
                      name={'nitSolicitante'}
                      value={this.state.nitSolicitante}
                      onChange={this.handleInputChange}
                      error={''}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormTextField
                      label={'Escritura No.'}
                      type={'number'}
                      id={'escrituraNumero'}
                      placeholder={'Numero'}
                      name={'escrituraNumero'}
                      value={this.state.escrituraNumero}
                      onChange={this.handleInputChange}
                      error={''}
                    />
                  </Col>
                  <Col>
                    <FormTextField
                      label={'Notario que autorizo'}
                      type={'text'}
                      id={'escrituraNotario'}
                      placeholder={'Nombre'}
                      name={'escrituraNotario'}
                      value={this.state.escrituraNotario}
                      onChange={this.handleInputChange}
                      error={''}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormTextField
                      label={'Patente No.'}
                      type={'number'}
                      id={'patenteSocNumero'}
                      placeholder={'Numero'}
                      name={'escrituraNumero'}
                      value={this.state.patenteSocNumero}
                      onChange={this.handleInputChange}
                      error={''}
                    />
                  </Col>
                  <Col>
                    <FormTextField
                      label={'Patente folio'}
                      type={'number'}
                      id={'patenteSocFolio'}
                      placeholder={'Folio'}
                      name={'patenteSocFolio'}
                      value={this.state.patenteSocFolio}
                      onChange={this.handleInputChange}
                      error={''}
                    />
                  </Col>
                  <Col>
                    <FormTextField
                      label={'Patente libro'}
                      type={'number'}
                      id={'patenteSocLibro'}
                      placeholder={'Libro'}
                      name={'patenteSocLibro'}
                      value={this.state.patenteSocLibro}
                      onChange={this.handleInputChange}
                      error={''}
                    />
                  </Col>
                  <Col>
                    <FormTextField
                      label={'Patente Expediente'}
                      type={'text'}
                      id={'patenteSocExpe'}
                      placeholder={'Expediente'}
                      name={'patenteSocExpe'}
                      value={this.state.patenteSocExpe}
                      onChange={this.handleInputChange}
                      error={''}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormTextField
                      label={'Direccion'}
                      type={'text'}
                      id={'direccionSede'}
                      placeholder={'Direccion'}
                      name={'direccionSede'}
                      value={this.state.direccionSede}
                      onChange={this.handleInputChange}
                      error={''}
                    />
                  </Col>
                  <Col>
                    <FormTextField
                      label={'Página de Internet / Sitio Web'}
                      type={'text'}
                      id={'paginaInternet'}
                      placeholder={'Pagina'}
                      name={'paginaInternet'}
                      value={this.state.paginaInternet}
                      onChange={this.handleInputChange}
                      error={''}
                    />
                  </Col>
                  <Col>
                    <FormTextField
                      label={'Email'}
                      type={'email'}
                      id={'emailP'}
                      placeholder={'Email'}
                      name={'emailP'}
                      value={this.state.emailP}
                      onChange={this.handleInputChange}
                      error={''}
                    />
                  </Col>
                </Row>
              </div>
              <hr />
              <Row>
                <Col>
                  <Button icon={faSave}>Guardar</Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <SweetAlert
          show={this.state.showReset}
          title="Confirmacion"
          text="Deseas reiniciar el balance de esta empresa"
          type={'warning'}
          showCancelButton={true}
          onConfirm={() => {
            this.resetBalance()
          }}
          onCancel={() => this.setState({ showReset: false })}
        />
      </div>
    )
  }
}

EditCompany.defaultProps = {
  company: {},
  loading: false,
  hasError: false,
}

const mapStateToProps = state => ({
  company: selectGetSingleCompany(state),
  loading: loadingSelector([actionTypes.UPDATE_SINGLE_COMPANY])(state),
  hasError: hasErrorsSelector([actionTypes.UPDATE_SINGLE_COMPANY])(state),
  error: singleErrorSelector([actionTypes.UPDATE_SINGLE_COMPANY])(state),
  errors: errorsSelector([
    actionTypes.UPDATE_SINGLE_COMPANY,
    actionTypes.CURRENT_COMPANY_SUCCESS,
  ])(state),
})

const mapDispatchToProps = dispatch => ({
  getSingleCompany: idCompany => dispatch(getSingleCompany(idCompany)),
  deleteSingleCompany: idCompany => dispatch(deleteSingleCompany(idCompany)),
  activateSingleCompany: id => dispatch(activateSingleCompany(id)),
  updateCompany: (
    id,
    isDistributor,
    address,
    name,
    nit,
    description,
    phone,
    isClient,
    email,
    isProducer,
    isPromotor,
    isKolo,
    koloCertifier,
    shortName,
  ) =>
    dispatch(
      updateSingleCompany(id, {
        isDistributor,
        address,
        name,
        nit,
        description,
        phone,
        isClient,
        email,
        isProducer,
        isPromotor,
        isKolo,
        koloCertifier,
        shortName,
      }),
    ),
  //this.props.updateCompany(idCompany, isDistributor, address, name, nit, isClient,isProducer);
  resetCompanyBalance: idCompany => dispatch(resetBalanceForAdmin(idCompany)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditCompany)
