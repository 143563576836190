import React, { Component } from 'react'
import EmployeeIcon from 'src/assets/images/svg/employee.svg'
import FactoryIcon from 'src/assets/images/svg/factory.svg'
import ClientIcon from 'src/assets/images/svg/client.svg'
import MoneyBagIcon from 'src/assets/images/svg/money-bag.svg'
import DashboardCard from 'src/components/cards/dashboard-card/DashboardCard'
import './AdminDashboard.scss'
import { selectMenu } from 'src/actions/dashboard.actions'
import { connect } from 'react-redux'

class AdminDashboard extends Component {
  componentDidMount() {
    this.props.selectMenu()
  }

  render() {
    return (
      <div>
        <h1 className={'dashboard-welcome'}>Administración Cotizap</h1>
        <div className={'admin-modules-list'}>
          <DashboardCard name={'Empresas'} icon={FactoryIcon} link={'/admin/empresas'} />
          <DashboardCard
            name={'Usuarios y Permisos'}
            icon={EmployeeIcon}
            link={'/admin/permisos/11000'}
          />
          <DashboardCard name={'Clientes'} icon={ClientIcon} link={'/admin/clientes'} />
          <DashboardCard
            name={'Pagos Banco Industrial'}
            icon={MoneyBagIcon}
            link={'/admin/pagos/bi'}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  selectMenu: () => dispatch(selectMenu(11000)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard)
