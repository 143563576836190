import { $http } from './http'
import moment from 'moment'
import { saveAs } from 'file-saver'

class ProducersService {
  getAllProducer = async () => {
    const response = await $http.get(`/producers`)
    return response.data
  }

  getProducer = async id => {
    const response = await $http.get(`/producers/${id}`)
    return response.data
  }

  getProviderByNit = async nit => {
    const response = await $http.get('/producers/nit', { params: { nit } })
    return response.data
  }

  getProviders = async () => {
    const response = await $http.get('/producers/all')
    return response.data
  }

  getHistory = async (balance, start, end) => {
    const response = await $http.get(`/producers/balance/${balance}`, {
      params: { start, end },
    })
    return response.data
  }

  onCreateProvider = async params => {
    const response = await $http.post('/producers/provider', {}, { params })
    return response.data
  }

  onUpdateProvider = async (id, params) => {
    const response = await $http.put(`/producers/provider/${id}`, {}, { params })
    return response.data
  }

  getSingleProvider = async id => {
    const response = await $http.get(`/producers/${id}/single`)
    return response.data
  }

  exportProviders = async providerIds => {
    const response = await $http.get(`/producers/excel`, {
      responseType: 'blob',
      params: { providerIds },
    })

    const fileName = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : moment().format('LL') + ' - Proveedores - .xlsx'

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    saveAs(file, fileName.replace('json', 'xlsx'))
  }
}

export default new ProducersService()
