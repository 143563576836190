import React from 'react'
import { Col } from 'react-bootstrap'
import Money from '../Money/Money'

interface Props {
  label: string
  value: number
}

export const TotalInfo = ({ label, value }: Props) => {
  return (
    <Col style={{ alignSelf: 'flex-end' }}>
      <div className={'justify-center column'}>
        <span>{label}</span>
        <Money component="h4">
          <strong>{Number(value)}</strong>
        </Money>
      </div>
    </Col>
  )
}
