import React, {Component} from 'react';
import {connect} from "react-redux";

import {Row, Col} from "react-bootstrap";
import {Td, Tr} from "react-super-responsive-table";

import {formatNumberWithCommas} from "../../../../utils/formatters";

import MapCard from "../../../../components/maps/MapCard";
import Card from "../../../../components/cards/Card";

import {actionTypes as typesP, getTransportationPayment} from "../../../../actions/products.actions";
import {selectTransportPaymentS} from "../../../../selectors/products.selector";

import {loadingSelector} from "../../../../selectors/loading.selector";
import { selectCurrentCurrency } from '../../../../selectors/currencies.selector';
import { TableV2 } from '../../../../components';

const latitude = 14.589465440976774;
const longitude = -90.51898866891861;

class TransportationPaymentDetail extends Component {

    state = {
        mapS: null,
        mapsS: null,
        manualPolygon: null,
        alert: {title: 'title'}
    };

    componentDidMount() {
        const {match, getTransportationPayment, paymentId} = this.props;
        if (paymentId) getTransportationPayment(paymentId);
    };

    componentWillReceiveProps(next) {
        const {loading} = this.props;
        const {transportPayment} = next;

        if (loading && !next.loading) {
            if (transportPayment.id) {
                this.setPolygon(transportPayment.polygon);
            }
        }
    };

    onGoogleApiLoaded = (map, maps) => {
        this.setState({mapS: map, mapsS: maps});
    };

    setPolygon = (polygon) => {
        if (polygon.id) {
            setTimeout(() => {
                let {mapS, mapsS, manualPolygon} = this.state;
                if (manualPolygon) manualPolygon.setMap(null);
                if (mapS, mapsS) {
                    const points = polygon.pointList.map((p) => ({lat: Number(p.latitude), lng: Number(p.longitude)}));
                    manualPolygon = new mapsS.Polygon({
                        path: points,
                        strokeColor: "#FF0000",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: "#FF0000",
                        fillOpacity: 0.35
                    });

                    const center = this.getCenter(points);
                    if (center.latLng.lat > 0) mapS.panTo(center.latLng);
                    manualPolygon.setMap(mapS);
                    this.setState({manualPolygon});
                }
            }, 1000);
        };
    };

    getCenter = (paths) => {
        const center = paths.map(p => {
            return [p.lat, p.lng]
        }).reduce((x, y) => {
            return [x[0] + y[0] / paths.length, x[1] + y[1] / paths.length]
        }, [0, 0])
        return {latLng: {lat: center[0], lng: center[1]}};
    };

    render() {
        const {transportPayment, loading, collapse, currentCurrency} = this.props;
        const {polygon} = transportPayment;

        return (
            <div>
                <Row>
                    <Col xl={12} lg={12} md={12} xm={12} xs={12}>
                        <MapCard
                            title={polygon ? polygon.name : ''}
                            latitude={latitude}
                            longitude={longitude}
                            height={500}
                            zoom={7}
                            startCollapsed={collapse}
                            markerList={[]}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => this.onGoogleApiLoaded(map, maps)}
                        />
                    </Col>
                    <Col xl={12} lg={12} md={12} xm={12} xs={12}>
                        <Card title={'Pagos de transporte'}>
                            <TableV2
                                headers={[
                                    {label: 'Minima', show: true, value: ['minimum'], type: 'measure' ,  className:'mini'},
                                    {label: 'Maxima', show: true, value: ['maximum'], type: 'measure' ,  className:'mini'},
                                    {label: 'Pago', show: true, value: ['amount'], type: 'currency' ,  className:'mini'},
                                    {config:true, show: true, label:'', className:'mini center'}

                                ]}
                                loading={loading}
                                items={transportPayment.amounts || []}
                                mobileAuto
                                storageKey={`trans-payment-det`}
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    transportPayment: selectTransportPaymentS(state),
    loading: loadingSelector([typesP.TRANSPORT_PAYMENT_SINGLE])(state),
    currentCurrency: selectCurrentCurrency(state)
});

const mapDispatchToProps = dispatch => ({
    getTransportationPayment: (transportationId) => dispatch(getTransportationPayment(transportationId))
});

export default connect(mapStateToProps, mapDispatchToProps)(TransportationPaymentDetail);
